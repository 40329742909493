import axios from '../../../axios/index'
import { API_URL, BASE_URL } from "../../../axios/config";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import { GET_INSURANCE_DATA } from "../../constants/index";
import { getInsuranceDataError, getInsuranceDataSuccess } from '../../actions';


async function getInsuranceDataAPI() {
    return axios.request({
        method: "get",
        url: BASE_URL + API_URL.INSYNC_INSURANCE_WEBLINK,
    });
}

function* getInsuranceDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(getInsuranceDataAPI);
        // yield put(setLoaderComplete());
        if (response.status === 200) {
            if (response.data.success) {
                yield put(getInsuranceDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        // yield put(setLoaderComplete());
        yield put(getInsuranceDataError(error));
        if (reject) reject(error);
    }
}

export function* getInsuranceDataWatcher() {
    yield takeLatest(GET_INSURANCE_DATA, getInsuranceDataAction);
}
