import React, { useEffect, useState } from "react";
import insuranceCheckedLogo from "../../../images/thank-you/checked.png";

// import './insurance-thank-you.css'
import "./social-media-landing.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { webBaseUrl } from "../../../config";
import HeaderComponent from "../header-component/header-component";
import Cookies from "js-cookie";

import Sidebar from "../../sidebar";
import SidebarComponent from "../../sidebar";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import BusinessHeader from "../../businessHeader";
import BusinessFooter from "../../businessFooter";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { TfiMenu } from "react-icons/tfi";
import { PlansCarousel } from "./social-media-marketing-plans";
import { TestimonialsCarousel } from "./testimonials-carousle";
import SocialMediaMarketingUserImg from "../../../images/social-media-marketing/social-media-marketing-user-img.png";
import { TrackUserActivityForServicesAction } from "../../redux/actions/social-media-marketing/service-user-activity";
import getDashboardList from "../../redux/actions/dashboard/dashboardList";
import logo from "../../../images/icons/faces-logo-black.svg";
import closeIcon from "../../../images/Icon example.svg";

function SocialMediaMarketingLanding() {
  const { width, height } = useWindowDimensions();
  const [showSidebar, setShowSidebar] = useState(true);
  // const calendlyLink = "https://calendly.com/supportfaces";
  // const calendlyLink = "https://calendly.com/facesconsent";
  const [calendlyLink, setCalendlyLink] = useState("");
  let isMobile = false;
  if (width <= 991) {
    isMobile = true;
  }
  else {
    isMobile = false;
  }
  useEffect(() => {
    isMobile ? setShowSidebar(false) : setShowSidebar(true);
  }, [isMobile]);





  /*
    const [serviceUserActivity] = useMutation(SERVICE_USER_ACTIVITY_MUTATION);
    useEffect(() => {
      serviceUserActivity({ variables: { serviceId: 1, activityId: 1 } });
    }, []); */

  const [openReviewPopup, setOpenReviewPopup] = useState(false);
  // const dashboardListData = useSelector((state) => state.dashboardReducer.dashboardListData)
  // const { profileData } = useSelector(state => state?.GetProfileDataReducer)
  const BackToHome = () => {
    window.top.location.href = webBaseUrl + "user/dashboard";
  };
  const handleReviewModalClick = () => {
    setOpenReviewPopup(true);
  };

  // For managing service user activity
  const {
    trackUserActivityForServicesQueryData,
    initTrackUserActivityForServicesData,
  } = TrackUserActivityForServicesAction();
  useEffect(() => {
    initTrackUserActivityForServicesData({ serviceId: 1, activityId: 1 });
  }, []);

  const calendlyLinkClick = () => {
    initTrackUserActivityForServicesData({ serviceId: 1, activityId: 4 });
  };

  const packageClickUserActivity = (packageId) => {
    initTrackUserActivityForServicesData({
      serviceId: 1,
      activityId: 2,
      packageId: parseInt(packageId),
    });
  };

  const dashboardListData = useSelector((state) => state.dashboardReducer.dashboardListData)
  const { profileData } = useSelector(state => state?.GetProfileDataReducer)
  const dispatch = useDispatch();
  useEffect(() => {
    if (dashboardListData == null)
      dispatch(getDashboardList({
        "business-average-reviews": true,
        platform: "frontend"
      }))
  }, [])

  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
    Cookies.get("faces_access_token") ? setIsAuth(true) : setIsAuth(false);
    console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
  });
  
  const [eBookLinkObj, setEBookLinkObj] = useState({});
  return (
    <>
      <BusinessHeader
        openMobileMenu={() => {
          setShowSidebar(!showSidebar);
        }}
        isMobile={isMobile}
      />
      <div className="container-fluid">
        <div className="dashboard-parent-container sidebarDesignUpdateSprint6">      
          {isMobile ? (
            <div className={showSidebar ? " MobileSidebar ActiveSidebar" : "MobileSidebar"}>
              <div
                className="overlaySidebar"></div>
              <div className="sidebar">
                <div className="sidebarLogoWrapper">
                  <div className="logoSidebar">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="sidebarClose" onClick={() => { setShowSidebar(false) }} >
                    <img src={closeIcon} alt="closeIcon" />
                  </div>
                </div>
                {isAuth && <SidebarComponent />}
              </div>
            </div>
          ) : (
            isAuth &&
            <div className="sidebarPart">
              <div className="sidebar">
                <SidebarComponent />
              </div>
            </div>
          )}
          <div className={isAuth ? "MainContentPart" : "col-lg-12"}>
            <div className="socialMediaMarketingDiv" id="parentDiv">
              <div className="headerDiv">Social Media Marketing Agency</div>
              <div>
                <p className="customTitle">About Us</p>
                <span className="customHr"></span>
                {/* 
                <p className="customSubContent">
                  “Plump helped me reach 10k months in aesthetics income.”
                  With 7 years of aesthetics marketing and sales experience, Plump Marketing are the best networked experts to help you scale your business, reach your client and revenue milestones and brand yourself as a professional, giving you more time to inject, educate and enjoy life!
                </p>
                 */}
                <p className="customSubContent">
                  With over 12 years of experience in the aesthetics and
                  beauty sectors, Jess Hillier is a marketing powerhouse.
                </p>
                <p className="customSubContent">
                  She spent eight years refining her skills in aesthetics
                  before joining Kanvas Medical Group as a Marketing
                  Executive. Rapidly promoted to Marketing Director, her
                  influence was undeniable. Hungry for global impact, Jess
                  moved to Dubai to found Plump Marketing Agency.
                </p>
                <p className="customSubContent">
                  Today, she serves a diverse international clientele,
                  extending her expertise across multiple beauty sectors.
                  New clients at Plump enjoy the unique opportunity to
                  consult directly with Jess, ensuring that each project
                  benefits from her strategic insight and the ideal
                  allocation of resources to meet business goals.
                </p>
              </div>
              {/*               
              <div className="d-flex justify-content-center mb-3">
                <a
                  className="book-btn"
                  href={calendlyLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={calendlyLinkClick}
                  style={{ color: '#424242', textDecoration: 'none', textUnderlineOffset: '3px' }}
                >
                  Book free call
                </a>
              </div>
              */}
              <div>
                <p className="customTitle">Our Services</p>
                <span className="customHr"></span>
                <p className="customSubContent">
                  From only £19.99 our products and services can ramp up your sales INSTANTLY! In 24 hours we can ensure you are well branded, engaging and not missing a trick to ensure your clinic is high converting!
                </p>
              </div>
              <div className="plansCarousel" id="plans">
                <PlansCarousel prntFn={packageClickUserActivity} setEBookLinkObj={setEBookLinkObj} setCalendlyLink={setCalendlyLink}/>
              </div>
              <div className="">
                <p>
                  Once your payment is processed, look out for an email. This is
                  to arrange your exclusive strategy call with Jess, our CEO.
                  Let's get this exciting journey started!
                </p>
              </div>
              <div className="testimonialsDiv">
                <p className="testimonialsTitle">Testimonials</p>
                <span className="customHr"></span>
                <div className="testimonials">
                  <TestimonialsCarousel />
                </div>
              </div>
              {/* https://i.pinimg.com/736x/ee/ea/c7/eeeac723d4663de7b93329b6e96da1ef.jpg */}
              <div className="ownerDetails">
                <div className="ownerProfileWrapper">
                  <div className="">
                    <img
                      src={SocialMediaMarketingUserImg}
                      alt=""
                      className="ownerImg"
                    />
                  </div>
                  <div className="ownerContentWrapper d-sm-none">
                    <div className="ownerName">Jess Hillier</div>
                    <div className="ownerDetailsTitle">
                      CEO of Plump Marketing Agency
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="ownerDetailsContent">
                    <div className="ownerContentWrapper desk-block">
                      <div className="ownerName">Jess Hillier</div>
                      <div className="ownerDetailsTitle">
                        CEO of Plump Marketing Agency
                      </div>
                    </div>
                    <div>
                      With over 12 years of experience in the aesthetics and
                      beauty sectors, Jess Hillier is a marketing powerhouse.
                    </div>
                    <div>
                      She spent eight years refining her skills in aesthetics
                      before joining Kanvas Medical Group as a Marketing
                      Executive. Rapidly promoted to Marketing Director, her
                      influence was undeniable. Hungry for global impact, Jess
                      moved to Dubai to found Plump Marketing Agency.
                    </div>
                    <div>
                      Today, she serves a diverse international clientele,
                      extending her expertise across multiple beauty sectors.
                      New clients at Plump enjoy the unique opportunity to
                      consult directly with Jess, ensuring that each project
                      benefits from her strategic insight and the ideal
                      allocation of resources to meet business goals.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bookingDetailsDiv">
              <div>
                <div>Questions?</div>
                <div>Let's Chat! 📞 Book a 30-Min Call</div>
                <div>with Plump Marketing CEO Jess Hillier</div>
              </div>
              <div className="bookingDetailsSubDiv">
                <div className="justify-content-center">
                  {/* 📆 */}
                  <center>
                  {
                  eBookLinkObj && 
                  <a
                  className="book-btn"
                    href={eBookLinkObj?.url}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => packageClickUserActivity(eBookLinkObj.id)}
                    style={{color: '#424242', textDecoration: 'underline', textUnderlineOffset: '3px'}}
                  >
                    {`Purchase E-book (${eBookLinkObj?.amountText})`}
                  </a>
                  }
                  <br />
                  📆
                  <a
                  className="book-btn"
                    href={calendlyLink}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => calendlyLinkClick()}
                    style={{color: '#424242', textDecoration: 'underline', textUnderlineOffset: '3px'}}
                  >
                    Book Now
                  </a>
                  </center>
                </div>
                <div>
                  <a href="#plans" className="btn btnredsm rounded-pill px-4" style={{ textTransform: 'none' }}>
                    Purchase A Plan
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BusinessFooter />
    </>
  );
}

export default SocialMediaMarketingLanding;