import { GET_TREATMENT_LISTING_CONST } from "../../constants/treatment-list-const/TreatmentListConst";
import { useDispatch } from "react-redux";
import { gql, useLazyQuery } from "@apollo/client";

const GET_TREATMENTS_LIST = gql`
  query GetTreatmentsList {
    getTreatmentsList {
      success
      message
      data {
        items {
          treatment_id
          treatment_name
          treatment_image
          treatment_price
          treatment_description
          treatment_deposit
          treatment_slug
          treatment_clinics
          treatment_duration
        }
        total
      }
    }
  }
`;
var page = 1;
export const GetTreatmentsListAction = () => {
  const dispatch = useDispatch();
  const [GetTreatmentsListResponseFunction, getTreatmentsListResponseData] =
    useLazyQuery(GET_TREATMENTS_LIST, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getTreatmentsList: responseData }) => {
        dispatch({
          type: GET_TREATMENT_LISTING_CONST,
          payload: responseData,
        });
        if (responseData.success) {
          // toast.success(responseData?.message)
        } else {
          console.log(responseData?.message);
        }
      },
    });
  //this will be called from components
  const getTreatmentsListQueryData = getTreatmentsListResponseData; //variable

  const initGetTreatmentsListData = (data) => {
   
    GetTreatmentsListResponseFunction({
      variables: data,
    });
  };

  return { getTreatmentsListQueryData, initGetTreatmentsListData };
};
