import React, { useEffect, useRef, useState } from "react";
import "./websiteBuilder.scss";
import { FaArrowRight } from "react-icons/fa6";
import medium from "../../../assets/WebSiteBuilder/medium.png";
import customeDesign from "../../../assets/WebSiteBuilder/customeDesign.svg";
import booking from "../../../assets/WebSiteBuilder/booking.svg";
import project from "../../../assets/WebSiteBuilder/project.svg";
import startup from "../../../assets/WebSiteBuilder/start-up.svg";
import videoPlaceHolder from "../../../assets/WebSiteBuilder/videoPlaceHolder.png";
import Play from "../../../assets/WebSiteBuilder/Play.svg";
import process from "../../../assets/WebSiteBuilder/process.png";
import text from "../../../assets/WebSiteBuilder/text.svg";
import addimage from "../../../assets/WebSiteBuilder/add-image.svg";
import rocket from "../../../assets/WebSiteBuilder/rocket.svg";
import colorpalette from "../../../assets/WebSiteBuilder/color-palette.svg";
import mobiImage from "../../../assets/WebSiteBuilder/mobile.png";

import mobiImageOne from "../../../assets/WebSiteBuilder/Mobileone.png";
import mobiImageTwo from "../../../assets/WebSiteBuilder/Mobiletwo.png";
import mobiImageThree from "../../../assets/WebSiteBuilder/Mobilethree.png";

import appStore from "../../../assets/WebSiteBuilder/appStore.png";
import google from "../../../assets/WebSiteBuilder/google.png";
import star from "../../../assets/WebSiteBuilder/rating.svg";
import googlelogo from "../../../assets/WebSiteBuilder/GoogleLogo.webp";
import ClientImg from "../../../assets/WebSiteBuilder/Client.png";

import screenFirst from "../../../assets/WebSiteBuilder/banner/firstSlide/screen.png";
import screenTwo from "../../../assets/WebSiteBuilder/banner/secondslider/screen.png";
import screenThree from "../../../assets/WebSiteBuilder/banner/thirdSlider/screen.png";

import screenTwotextplacer from "../../../assets/WebSiteBuilder/banner/secondslider/text_placer.png";
import screenTwoNextbutton from "../../../assets/WebSiteBuilder/banner/secondslider/next_button.png";
import screenTwoTitleplacer from "../../../assets/WebSiteBuilder/banner/secondslider/title_placer.png";

import screenThreeImageplacer from "../../../assets/WebSiteBuilder/banner/thirdSlider/image_placer.png";
import screenThreeNextbutton from "../../../assets/WebSiteBuilder/banner/thirdSlider/next_button.png";
import screenThreeDragdrop from "../../../assets/WebSiteBuilder/banner/thirdSlider/drag&drop.png";

import colorSlider from "../../../assets/WebSiteBuilder/banner/firstSlide/picker.png";
import opcitySlider from "../../../assets/WebSiteBuilder/banner/firstSlide/slider.png";
import textplacer from "../../../assets/WebSiteBuilder/banner/firstSlide/text_placer.png";
import imageplacer from "../../../assets/WebSiteBuilder/banner/firstSlide/image_placer.png";

import Slider from "react-slick";
import Accordion from "react-bootstrap/Accordion";
import { YouTube } from "@material-ui/icons";
import CustomModal from "../../modal/CustomModal";
import BusinessHeader from "../../businessHeader";
import BusinessFooter from "../../businessFooter";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router";
import { config, frontendURL, isLiveSystem, notificationMode, webBaseUrl } from "../../../config";
import { useNavigate } from 'react-router-dom';
import greyRatingStar from "../../../assets/WebSiteBuilder/greyRatingStar.svg";
import { GetFacesGoogleReviewsAction } from "../../redux/actions/googleReviewsWebSiteBuilder/googleReviewsWebsiteBuilder";
import { useSelector } from "react-redux";
import Cookies from 'js-cookie';
import {createWebsite } from "../../../Routes";
import { Helmet } from 'react-helmet';
import customeBuild from "../../../assets/WebSiteBuilder/customeBuild.png";
import addBusinessHeadline from "../../../assets/WebSiteBuilder/addBusinessHeadline.png";
import aboutBusiness from "../../../assets/WebSiteBuilder/aboutBusiness.png";
import nextButton from "../../../assets/WebSiteBuilder/nextButton.png";



const WebsiteBuilder = () => {
  const [isyouTube, setIsyouTube] = useState(false);
  const { pathname } = useLocation();

  const navigate = useNavigate();

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
      <div className={className} onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
        >
          <path
            d="M0.294956 1.41L4.87496 6L0.294956 10.59L1.70496 12L7.70496 6L1.70496 -2.62268e-07L0.294956 1.41Z"
            fill="#373C35"
          />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        // style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
        >
          <path
            d="M7.88498 1.41L3.30498 6L7.88498 10.59L6.47498 12L0.474976 6L6.47498 -2.62268e-07L7.88498 1.41Z"
            fill="#373C35"
          />
        </svg>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderRef = useRef(null);

  useEffect(() => {
    const handleMouseWheel = (e) => {
      // Check if the mouse wheel event is coming from within the slider
      console.log(sliderRef.current.innerSlider, "e");
      if (
        sliderRef.current &&
        sliderRef.current.innerSlider &&
        sliderRef.current.innerSlider.list &&
        sliderRef.current.innerSlider.list.contains(e.target)
      ) {
        const totalSlides = sliderRef.current.innerSlider.list.children.length;
        const currentSlideIndex =
          sliderRef.current.innerSlider.state.currentSlide;

        if (e.deltaY > 0) {
          // Scroll down, go to the next slide
          sliderRef.current.slickNext();
          console.log(e.deltaY, "e.deltaY");
        } else {
          // Scroll up, go to the previous slide
          sliderRef.current.slickPrev();
        }

        // Prevent default to avoid scrolling to the next section
        if (currentSlideIndex < 2) {
          e.preventDefault();
        }
      }
    };

    // Attach event listener to the slider's inner container
    if (sliderRef.current && sliderRef.current.innerSlider) {
      sliderRef.current.innerSlider.list.addEventListener(
        "wheel",
        handleMouseWheel
      );
    }

    // Remove the event listener on component unmount
    return () => {
      if (sliderRef.current && sliderRef.current.innerSlider) {
        sliderRef.current.innerSlider.list.removeEventListener(
          "wheel",
          handleMouseWheel
        );
      }
    };
  }, [sliderRef]);

  const bannersettings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    navigator: true,
    speed: 1000,
    autoplaySpeed: 1000,
    vertical: true,
    touchMove: true,
    verticalSwiping: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      offset: 200,
      once: true, // Set once to true to trigger animations only once
    });
  }, []);

  const [testimonials, setTestimonials] = useState([]);
  const { getFacesGoogleReviewsQueryData, initGetFacesGoogleReviewsData } = GetFacesGoogleReviewsAction();
  const { websiteBuilderGoogleReviews } = useSelector((state) => state.GetWebsiteBuilderReducer);
  useEffect(() => {
    initGetFacesGoogleReviewsData();
  }, []);

  const [reviewViewMore, setreviewViewMore] = useState(false);
  const [reviewDataModel, setreviewDataModel] = useState();

  const openreviewModel = (e,data) => {
    setreviewDataModel(data)
    setreviewViewMore(!reviewViewMore)
    console.log(reviewDataModel, 'reviewDataModel')
  }


  const [isAuthHeader, setIsAuthHeader] = useState(false);
  console.log(isAuthHeader, 'isAuthHeader')
  const [websiteBuilderNavigationLink, setWebsiteBuilderNavigationLink] = useState(false);
  useEffect(() => {
    Cookies.get("faces_access_token")
      ? setIsAuthHeader(true)
      : setIsAuthHeader(false);
  }, []);

  useEffect(() => {
    if (isAuthHeader) {
      setWebsiteBuilderNavigationLink(
        webBaseUrl + "/" + "my-website"
      );
    } else {
      setWebsiteBuilderNavigationLink(webBaseUrl + "/" + "login");
    }
  }, [isAuthHeader]);


  useEffect(() => {
    return () => {

      let clarityScript = document.getElementById('clarity-script');

      if(clarityScript) {
        clarityScript.remove();
      }
    }
  }, []);


  return (
    <>
  
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        Build a Free Website | Faces Consent - Easy Website Builder
        </title>
        <meta
          name="description"
          content="Create a free, professional website for your beauty or aesthetic practice with Faces Consent. Our easy-to-use website builder helps you design and launch your site quickly, attracting more clients and showcasing your services online."
        />
        <meta
          name="keywords"
          content="build a free website, Faces Consent, website builder, free website creation, beauty practice website, aesthetic practice website, easy website builder, create a website, professional website design, launch your site"
        />

      {isLiveSystem  &&  
        <script type="text/javascript">
        {
          ` (function(c,l,a,r,i,t,y){ 
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; 
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            t.id = 'clarity-script';
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "mcftd5dhzu"); `
        }
        </script>
        }

      </Helmet>
      <div className={pathname === "/website-builder" && "websitebuilderHeader"}>
        <BusinessHeader />
      </div>
      <div className="MainWebsiteBuilder">
        <div className="bannerWebsiteBuilder" id="bannerWeb">
          <div className="container">
            <div className="bannerConSec">
              <h1 data-aos="fade-up" data-aos-duration="1000">
              Transform your business with our <br /> free website builder
              </h1>
              <p data-aos="fade-up" data-aos-duration="750">
                Build your online presence in under 5 minutes
              </p>
              <button
                className="btnSquare"
                data-aos="fade-up"
                data-aos-duration="800"
                onClick={()=> {isAuthHeader ?  navigate(createWebsite) :  window.location.href = webBaseUrl + "/" + "register" }}
              >
                Build My Website
                <span className="BtnArrowIcon">
                  <FaArrowRight />
                </span>
              </button>
            </div>
          </div>
          <div
            className="bannerAnimation"
            data-aos="fade-up"
            data-aos-duration="850"
          >
            <div className="container">
              <Slider {...bannersettings} ref={sliderRef}>
                {/* first Slide */}
                <div>
                  <div className="bannerAnimationBox">
                    <img src={screenFirst} className="img-fluid" />
                    <div className="colorSlider">
                      <img src={colorSlider} />
                    </div>
                    <div className="OpacitySlider">
                      <img src={opcitySlider} />
                    </div>
                    <div className="TermentText">
                      <img src={textplacer} />
                    </div>
                    <div className="imageplacer">
                      <img src={imageplacer} />
                    </div>
                  </div>
                </div>
                {/* Second Slide */}
                <div>
                  <div className="bannerAnimationBox">
                    <img src={screenTwo} className="img-fluid" />
                    <div className="screenTwotextplacer">
                      <img src={screenTwotextplacer} />
                    </div>
                    <div className="screenTwoNextbutton">
                      <img src={screenTwoNextbutton} />
                    </div>
                    <div className="screenTwoTitleplacer">
                      <img src={screenTwoTitleplacer} />
                    </div>
                  </div>
                </div>
                {/* Third Slide */}
                <div>
                  <div className="bannerAnimationBox">
                    <img src={screenThree} className="img-fluid" />
                    <div className="screenThreeImageplacer">
                      <img src={screenThreeImageplacer} />
                    </div>
                    <div className="screenThreeNextbutton">
                      <img src={screenThreeNextbutton} />
                    </div>
                    <div className="screenThreeDragdrop">
                      <img src={screenThreeDragdrop} />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className="container">
            <p className="text-center bottomItalicWord" 
                    data-aos="fade-right"
                    data-aos-offset="850"
                    data-aos-duration="850">
              <i>
                Must have Aesthetics and Beauty website builder for 2024 - Faces
                Consent
              </i>
            </p>
          </div>
        </div>

        {/* Build Website Sec */}
        <div className="BuildWebSec ptop-100 pbottom-100" id="BuildWebSec">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4 pb-sm-0 pb-md-5 pb-lg-0">
                <div
                  className="businessWebBox"
                  data-aos="fade-right"
                  data-aos-anchor="#BuildWebSec"
                  data-aos-offset="700"
                  data-aos-duration="700"
                >
                  <img src={medium} />
                  <div className="businessWebMainContent">
                    <div className="businessWebBoxContent">
                      <span data-aos="fade-down" data-aos-duration="750">
                        Build your Website
                      </span>
                      <h5 data-aos="fade-up" data-aos-duration="750">
                        Build your business’s website in under 5 minutes!
                      </h5>
                      <p data-aos="fade-up" data-aos-duration="750">
                        Our no-code solution means you can launch your branded
                        website in as little as 5 minutes - no complex
                        development costs, just your vision brought to life.
                      </p>
                      <button
                        className="btnSquare"
                        data-aos="fade-up"
                        data-aos-duration="800"
                        // onClick={()=> navigate('/my-website')}
                        onClick={()=> {isAuthHeader ?  navigate(createWebsite) :  window.location.href = webBaseUrl + "/" + "register" }}

                        // onClick={() => {
                        //   window.location.href = websiteBuilderNavigationLink;
                        // }}
                      >
                        Explore
                        <span className="BtnArrowIcon">
                          <FaArrowRight />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8">
                <div className="BoxesWrapper">
                  <div className="row" id="firsBoxRow">
                    <div className="col-sm-12 col-md-6 pt-4 pt-md-0">
                      <div
                        className="Box"
                        style={{ background: "#EFFAFF" }}
                        data-aos="fade-left"
                        data-aos-offset="750"
                        data-aos-duration="750"
                      >
                        <span className="BoxIcon">
                          <img src={customeDesign} />
                        </span>
                        <h5>Customisable Designs</h5>
                        <p>
                          Tailor your website to embody your brand's aesthetic.
                        </p>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 pt-4 pt-md-0">
                      <div
                        className="Box"
                        style={{ background: "#FCF0E3" }}
                        data-aos="fade-left"
                        data-aos-anchor="#firsBoxRow"
                        data-aos-offset="750"
                        data-aos-duration="750"
                      >
                        <span className="BoxIcon">
                          <img src={booking} />
                        </span>
                        <h5>
                          Integrated Booking System & <br /> Online payments
                        </h5>
                        <p>
                          Enable seamless client bookings with real-time
                          calendar syncing.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row" id="secondRow">
                    <div className="col-sm-12 col-md-6">
                      <div
                        className="Box"
                        style={{ background: "#F6F2FF" }}
                        data-aos="fade-left"
                        data-aos-offset="800"
                        data-aos-duration="800"
                      >
                        <span className="BoxIcon">
                          <img src={project} />
                        </span>
                        <h5>
                          Deposit & Consent <br /> Management
                        </h5>
                        <p>
                          Automate deposits and consent forms for client
                          convenience.
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 pt-4 pt-md-0">
                      <div
                        className="Box"
                        style={{ background: "#E9FCE3" }}
                        data-aos="fade-left"
                        data-aos-offset="800"
                        data-aos-duration="800"
                      >
                        <span className="BoxIcon">
                          <img src={startup} />
                        </span>
                        <h5>Google Listing Boost</h5>
                        <p>
                          Enhance your Google visibility, attracting more
                          clients to your practice.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

          {/* Custome Build Sec */}

          <div className="CustomeBuildSec" id="CustomeBuild">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6"   
              data-aos="fade-right"
              data-aos-offset="800"
              data-aos-anchor="#CustomeBuild"
              data-aos-duration="800">
                <div className="CustomeBuildImgSec">
                    <div className="FirstHeadLineSec">
                      <img src={addBusinessHeadline} alt={addBusinessHeadline} srcset={addBusinessHeadline} />
                    </div>
                    <div className="SecondAboutBusiness">
                      <img src={aboutBusiness} alt={aboutBusiness} srcset={aboutBusiness} />
                    </div>
                    <div className="ThirdNextButton">
                      <img src={nextButton} alt={nextButton} srcset={nextButton} />
                    </div>
                    <img src={customeBuild} alt={customeBuild} srcset={customeBuild} />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6" 
              data-aos="fade-left"
              data-aos-offset="800"
              data-aos-anchor="#CustomeBuild"
              data-aos-duration="800"> 
                <div className="CustomeBuildContent pt-3">
                    <h5>Custom Build from £299</h5>
                    <p>Want something bespoke, try our custom build with your very own tech team for £40/per month</p>
                    <button
                      className="btnSquare"
                      data-aos="fade-up"
                      data-aos-duration="800"
                      onClick={()=> {isAuthHeader ?  navigate(createWebsite) :  window.location.href = webBaseUrl + "/" + "register" }}
                    >
                      Let’s get started
                      <span className="BtnArrowIcon">
                        <FaArrowRight />
                      </span>
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Video Bg */}
        <div className="videoSec" id="VideoSec">
          <div className="container">
            <h5 className="secTitle text-center" data-aos="fade-up"
                data-aos-anchor="#VideoSec"
                data-aos-offset="700"
                data-aos-duration="700">
              Free Website in under 5 minutes
            </h5>
            <div className="VideoContent">
              <div
                className="VideoBox"
                data-aos="fade-up"
                data-aos-anchor="#VideoSec"
                data-aos-offset="750"
                data-aos-duration="750"
              >
                <img src={videoPlaceHolder} className="img-fluid" />
                <div
                  className="videoPlayIcon"
                  onClick={() => {
                    setIsyouTube(true);
                  }}
                >
                  <img src={Play} />
                </div>
              </div>

              <div className="VideoDesc">
                <p
                  data-aos="fade-up"
                  data-aos-anchor="#VideoSec"
                  data-aos-offset="800"
                  data-aos-duration="800"
                >
                  In the digital age, a strong online presence is vital for you
                  business. Our intuitive website builder is designed to cater
                  specifically to your needs. Create a <br /> stunning website
                  that reflects the professionalism and uniqueness of your
                  business.
                </p>
                <button
                  className="btnSquare"
                  data-aos="fade-up"
                  data-aos-duration="850"
                  data-aos-offset="850"
                  data-aos-anchor="#VideoSec"
                  // onClick={()=> navigate('/my-website')}
                  onClick={()=> {isAuthHeader ?  navigate(createWebsite) :  window.location.href = webBaseUrl + "/" + "register" }}

                  // onClick={() => {
                  //   window.location.href = websiteBuilderNavigationLink;
                  // }}
                >
                  Let’s get started
                  <span className="BtnArrowIcon">
                    <FaArrowRight />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* process Sec */}
        <div className="ProcessSec ptop-100 pbottom-100" id="ProcessSec">
          <div className="container">
            <div className="row">
              <div
                className="col-sm-12 col-md-6"
                data-aos="fade-right"
                data-aos-anchor="#ProcessSec"
                data-aos-offset="700"
                data-aos-duration="700"
              >
                <div className="processImg">
                  <img src={process} className="img-fluid" />
                </div>
              </div>
              <div
                className="col-sm-12 col-md-6 pb-sm-0 pb-5"
                data-aos="fade-left"
                data-aos-anchor="#ProcessSec"
                data-aos-offset="750"
                data-aos-duration="750"
              >
                <div className="processContent">
                  {/* <span className="tagLine">OUR PROCESS</span> */}
                  <h5 className="secTitle">
                    Launching your professional <br /> website has
                    never been simpler.
                  </h5>

                  <ul>
                    <li
                      data-aos="fade-up"
                      data-aos-anchor="#ProcessSec"
                      data-aos-offset="800"
                      data-aos-duration="800"
                    >
                      <span className="icon">
                        <img src={text}></img>
                      </span>
                      <span className="content mb-0">
                        <h4>Choose your site name</h4>
                        <p>Choose how people will find you on Google. </p>
                      </span>
                    </li>
                    <li
                      data-aos="fade-up"
                      data-aos-anchor="#ProcessSec"
                      data-aos-offset="850"
                      data-aos-duration="850"
                    >
                      <span className="icon">
                        <img src={addimage}></img>
                      </span>
                      <span className="content mb-0">
                        <h4>Upload your images</h4>
                        <p>Upload your logo, images and website background.</p>
                      </span>
                    </li>
                    <li
                      data-aos="fade-up"
                      data-aos-anchor="#ProcessSec"
                      data-aos-offset="900"
                      data-aos-duration="900"
                    >
                      <span className="icon">
                        <img src={colorpalette}></img>
                      </span>
                      <span className="content mb-0">
                        <h4>Customise your colours</h4>
                        <p>
                          Select and customise your colours through your
                          website.
                        </p>
                      </span>
                    </li>
                    <li
                      data-aos="fade-up"
                      data-aos-anchor="#ProcessSec"
                      data-aos-offset="950"
                      data-aos-duration="950"
                    >
                      <span className="icon">
                        <img src={rocket}></img>
                      </span>
                      <span className="content mb-0">
                        <h4>Launch your website</h4>
                        <p>
                          Launch your website and grow your business audience.
                        </p>
                      </span>
                    </li>
                  </ul>

                  <button
                    className="btnSquare"
                    data-aos="fade-up"
                    data-aos-anchor="#ProcessSec"
                    data-aos-offset="800"
                    data-aos-duration="800"
                    // onClick={()=> navigate('/my-website')}
                    onClick={()=> {isAuthHeader ?  navigate(createWebsite) :  window.location.href = webBaseUrl + "/" + "register" }}

                    // onClick={() => {
                    //   window.location.href = websiteBuilderNavigationLink;
                    // }}
                  >
                    Start building
                    <span className="BtnArrowIcon">
                      <FaArrowRight />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="TestimonialSec ptop-100 pbottom-100"
          id="TestimonialSec"
        >
          <div className="container">
            <div
              className="secHeaderTitle"
              data-aos="fade-right"
              data-aos-anchor="#TestimonialSec"
              data-aos-offset="700"
              data-aos-duration="700"
            >
              <h5 className="secTitle">This is why professionals love faces.</h5>
            </div>

            <Slider {...settings}>
              {websiteBuilderGoogleReviews.map((item, index) =>
                <div key={index}>
                  <div className="testiBox">
                    <div className="testiHeader">
                      <span className="RaingWrapper">
                        { [...Array(5)].map((i, indx) => <img src={indx < item?.userRating ? star : greyRatingStar} />) }
                      </span>
                      <span className="comLogo">
                        <img src={googlelogo} />
                      </span>
                    </div>
                    <div className="testiContent">
                      {/* <h5>Fascinating Experience</h5> */}
                      <p>
                      <p>{item?.userReview.slice(0, 250)}{item?.userReview.length >= 250 && 
                          <span onClick={(e) => {
                            openreviewModel(e, item)
                          }} className="viewMoreBtn">
                             View More
                           </span>}</p>
                      </p>
                    </div>

                    <div className="testiFooter">
                      <span className="ClientImage" onClick={()=> {window.open(item?.reviewUrl, 'blank')}}>
                        <img src={item?.userImage} />
                      </span>
                      <span className="ClientNameWrapper">
                        <span className="ClientName" onClick={()=> {window.open(item?.reviewUrl, 'blank')}}>{item?.userName}</span>
                        <span className="ClientDesti" onClick={()=> {window.open(item?.reviewUrl, 'blank')}}>{item?.reviewDate}</span>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </Slider>
          </div>
        </div>

        {/* ClinicSec */}
        <div className="ClinicSec ptop-100 pbottom-100" id="ClinicSec">
          <div className="container">
            <div className="row">
              <div
                className="col-sm-12 col-md-6"
                data-aos="fade-right"
                data-aos-anchor="#ClinicSec"
                data-aos-offset="700"
                data-aos-duration="700"
              >
                <div className="clinicContent">
                  {/* <span className="tagLine">
                    digital presence in aesthetics
                  </span> */}
                  <h5 className="secTitle">Website builder for your Aesthetic and Beauty business</h5>
                  <span className="secondNormalDesc">
                    Empower your business with an easy-to-use website <br />
                    builder software.
                  </span>
                  <p className="desc text-start pt-0 pb-0">
                    Start your digital journey with our aesthetics and beauty website builder service, showcase your work and professionalism online and attract more clients
                  </p>

                  <div className="appStoreWrapper">
                    <img src={appStore}  onClick={() => window.open(`${config.APP_STORE_LINK}`, '_blank')} />
                    <img src={google} onClick={() => window.open(`${config.PLAY_STORE_LINK}`, '_blank')}/>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="MobileWrapper">
                  <div
                    className="MobileImages MobileOne text-center"
                    data-aos="fade-up"
                    data-aos-anchor="#ClinicSec"
                    data-aos-offset="750"
                    data-aos-duration="750"
                  >
                    <img src={mobiImageTwo} className="img-fluid" />
                  </div>
                  <div className="MobileImages MobileTwo text-center">
                    <span
                      data-aos="fade-down"
                      data-aos-anchor="#ClinicSec"
                      data-aos-offset="800"
                      data-aos-duration="800"
                    >
                      <img src={mobiImageOne} className="img-fluid" />
                    </span>
                    <span
                      data-aos="fade-up"
                      data-aos-anchor="#ClinicSec"
                      data-aos-offset="850"
                      data-aos-duration="850"
                    >
                      <img src={mobiImageThree} className="img-fluid" />
                    </span>
                  </div>
                </div>
                {/* <div className="MobileImagesWrapper text-center">
                  <div className="MobileImgOne">
                    <img src={mobiImageOne} />
                  </div>
                  <div className="MobileImgTwo">
                    <img src={mobiImageTwo} />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* accordion sec */}
        {/* <div className="accordionSec ptop-100 pbottom-100" id="accordionSec">
          <div className="container">
            <div
              className="secHeaderTitle text-center"
              data-aos="fade-up"
              data-aos-anchor="#accordionSec"
              data-aos-offset="700"
              data-aos-duration="700"
            >
              <h5 className="secTitle text-center mb-0">
                Everything You Need to Know
              </h5>
              <p className="desc pt-0 pb-0">
                We know trying a new service can be scary, Thats why we build something so simple for you to use and help you get more bookings
              </p>
            </div>
            <div className="row">
              <Accordion defaultActiveKey={AccordionData?.[0]}>
                {AccordionData?.map((item, index) => {
                  return (
                    <Accordion.Item
                      key={item?.id}
                      eventKey={item?.id}
                      data-aos="fade-up"
                      data-aos-anchor="#accordionSec"
                      data-aos-offset={1000 + index * 100} // Adjust this value based on your preference
                      data-aos-duration={1000 + index * 100} // Adjust this value based on your preference
                    >
                      <Accordion.Header>
                        <span className="countAccordiyan">{item?.id}</span>
                        {item?.title}
                      </Accordion.Header>
                      <Accordion.Body>{item?.desc}</Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div> */}
      </div>

      <BusinessFooter />
      {/* Youtube Model */}
      <CustomModal
        modalOpen={isyouTube}
        setModalOpen={setIsyouTube}
        type={"review"}
        className="YouTubeModel"
        modalBody={
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/gkwx1v0uDWE?si=0hgjhxFry4hulsku"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        }
      />
       <CustomModal
        modalOpen={reviewViewMore}
        setModalOpen={setreviewViewMore}
        type={"review"}
        className="WebsiteBuilderReviewMoreDataModel"
        modalBody={
          <>
            <div className="reviewModelWrapper">
            <div className="reviewStarWrapper">
                <ul>
                  {[1, 2, 3, 4, 5].map((i, index) => (
                    <li>
                      <img
                        src={index < reviewDataModel?.userRating ? star : greyRatingStar}
                        alt=""
                      />
                    </li>
                  ))}
                </ul>
               
            </div>
              <div className="reviewDescWrapper">
                {/* <h5>Fascinating Experience</h5> */}
                <p>{reviewDataModel?.userReview}</p>
              </div>
              <div className="reviewLogoWrapper">
                <div className="reviewImgWrapper">
                  <div className="reviewImg" onClick={()=> {window.open(reviewDataModel?.reviewUrl, 'blank')}}>
                      <img src={reviewDataModel?.userImage} />
                  </div>
                  <div className="reviewContentWrapper">
                      <h5 onClick={()=> {window.open(reviewDataModel?.reviewUrl, 'blank')}}>{reviewDataModel?.userName}</h5>
                      <span onClick={()=> {window.open(reviewDataModel?.reviewUrl, 'blank')}}>{reviewDataModel?.reviewDate}</span>
                  </div>
                </div>
                  <span className="comLogo">
                    <img src={googlelogo} />
                  </span>
              </div>

            </div>
          </>
        }
      />
    </>
  );
};

export default WebsiteBuilder;
