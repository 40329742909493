import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getClientProfileRoute } from '../../Routes';
import NavHeader from '../common/NavHeader/NavHeader';
import SkeletonLoader from '../common/Skeleton/SkeletonLoader';
import CustomModal from '../modal/CustomModal';
import { GetClientAdditionalPhotos } from '../redux/actions/client/GetClientAdditionalPhotos';
import './client-additional-photos.scss';

const ClientAdditionalPhotos = ({
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isPreview, setIsPreview] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const clientId = useParams()?.clientId;

    const { initGetClientAdditionalPhotos, clientAdditionalPhotosQueryData } =
        GetClientAdditionalPhotos();

    const [photoData,setPhotoData]=useState([]);

    const renderPhotos = () => {
        return photoData.map((file, index) => (
            <div className="photoGridItem" key={index}>
                <div className=''>
                    {
                            <div className={`formDropzoneUploading`}>
                                <div className='add-more-label-container'>
                                    <img src={file?.imageUrl} alt='' onClick={() => {
                                        setPreviewImage(
                                            file?.imageUrl
                                        )
                                        setIsPreview(true)
                                    }} />
                                </div >
                            </div > 
                          
                    }
                </div >

            </div >
        ));
    };

    useEffect(() => {
        if (clientId) {
            initGetClientAdditionalPhotos(
                {
                    clientId: parseInt(clientId),
                },
                (res) => {
                    if (res) {
                        setPhotoData(res?.photos)
                    } else {
                        console.log('res', res.message);
                    }
                },
                (err) => {}
            );
        }
    }, [clientId]);

    const goBackClickHandler = () => {
        navigate(getClientProfileRoute(parseInt(clientId)))
    };

    return (
        <div className="client-additional-photos-container">
            <NavHeader
                isBackOnly={true}
                applyPadding={false}
                handleOnBack={() => goBackClickHandler()}
            />

            {clientAdditionalPhotosQueryData?.loading 
             ? <SkeletonLoader type="batch-number-photos" /> : 
            <div>
               <h1>Additional images</h1> 
               <p>This section is only used for historic images you previously saved. All new client images must be saved under the clients consent forms, or in the appointment overview
               </p>

                <div className='photoGrid'>
                    {renderPhotos()}
                </div>

               
            </div>}
            {/* For image preview Modal */}
            <CustomModal
                type={'common-width'}
                // className={"w-75"}
                modalBody={
                    <div className="preview-before-after-photos-document-modal">
                        <img
                            src={previewImage}
                            alt="preview"
                            style={{ objectFit: 'contain' }}
                        />
                       
                    </div>
                }
                setModalOpen={setIsPreview}
                modalOpen={isPreview}
                className={'previewImgModel'}
                setIsOpenMobileCalendar={setIsPreview}
            />
        </div>
    );
};

export default ClientAdditionalPhotos;
