import { GET_PRESCRIPTION_COUNT_SUCCESS } from '../../constants/prescription/prescription';

const initialState = {
    prescriptionCount: null,
}
export const GetPrescriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRESCRIPTION_COUNT_SUCCESS:
            return {
                ...state,
                prescriptionCount: action.payload.data,
            }
        default:
            return state
    }
}  