// ========================COMPLETE BOOKING PAYMENT========================

import { gql, useMutation } from "@apollo/client";
import { useSearchParams } from "react-router-dom";

const UPDATE_PLUM_MARKETING_PACKAGE_PAYMENT_MUTATION = gql`
mutation UpdatePlumpMarketingPackagePayment($packageId: Int, $orderId: Int, $intentId: String, $token: String, $stripeSavedCardId: String, $planUpgrade: Boolean, $usrName: String, $usrEmail: String, $usrPhoneNo: String, $facesUserClinicName: String, $instagramHandle: String) {
    updatePlumpMarketingPackagePayment(packageId: $packageId, orderId: $orderId, intentId: $intentId, token: $token, stripeSavedCardId: $stripeSavedCardId, planUpgrade: $planUpgrade, usr_name: $usrName, usr_email: $usrEmail, usr_phone_no: $usrPhoneNo, faces_user_clinic_name: $facesUserClinicName, instagram_handle: $instagramHandle) {
      success
      message
    }
  }
 `;


export const UpdatePlumMarketingPackagePaymentAction = () => {
    // const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [
        UpdatePlumpMarketingPackagePaymentResponseFunction,
        updatePlumpMarketingPackagePaymentResponseData,
    ] = useMutation(UPDATE_PLUM_MARKETING_PACKAGE_PAYMENT_MUTATION, {
        context: {
            headers: {
                ['authorization']: `${searchParams.get('usertoken')}`
            }
        },
    })

    //this will be called from components
    const updatePlumpMarketingPackagePaymentQueryData = updatePlumpMarketingPackagePaymentResponseData; //variable

    const initUpdatePlumpMarketingPackagePaymentData = (data, successCallback, errorCallback) => {
        UpdatePlumpMarketingPackagePaymentResponseFunction({
            variables: data,
        })
        // .then(({ data: { PurchasePlumpMarketingPackage: responseData } }) => {
        .then((responseData) => {
            console.log(responseData);
            if (responseData.data.updatePlumpMarketingPackagePayment.success) {
                // dispatch({
                //     type: SET_BOOKING_DETAILS,
                //     payload: responseData?.data
                // })
                successCallback(responseData)
                // toast.success(responseData.message)
            }
            else {
                errorCallback(responseData)
                // toast.error(responseData.message)
            }
        }).catch((err) => {
            errorCallback(err)
            // toast.error("something went wrong!!!")
        })
    }

    return { updatePlumpMarketingPackagePaymentQueryData, initUpdatePlumpMarketingPackagePaymentData };
};
