import { GET_WEBSITE_PACKAGE, CLEAR_GET_WEBSITE_PACKAGE } from "../../constants/createWebiste/getAllWebsitePackages";

const initialState = {
    packageDetails: null
}

export const GetWebsitePackageReducer = (state = initialState, action) => {
    switch (action?.type) {
        case GET_WEBSITE_PACKAGE: {
            return {
                ...state, packageDetails: action?.payload
            }
            break;
        }
        case CLEAR_GET_WEBSITE_PACKAGE: {
            return {
                ...state, packageDetails: null
            }
            break;
        }
        default: return state

    }
}