import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
// import { GET_USER_PROFILE_DATA } from "../../constants/user";
// import { GET_USER_PROFILE_DATA } from "../../constants/profile/UserProfile";
// import { GET_USER_PROFILE_DATA } from "../../constants";
// ==========================GET ACCOUNT DETAILS==========================

const GET_ACCOUNT_DETAILS_QUERY = gql`
  query GetAccountDetailsForManageAccount {
    getAccountDetailsForManageAccount {
      success
      message
      data {
        profileImage
        firstName
        lastName
        email
        businessName
        phone
        countryCode
        phoneCountry
        dateOfBirth
        occupation
        trainingAcademy {
          parent_id
          name
        }
        holiday
        affiliationLink
        requirePasswordForClientDirectory
        usrListOnSearchEngine
        isPrescriber
      }
    }
  }
`;

export const GetAccountDetailsAction = () => {
    const dispatch = useDispatch();
  const [
    getAccountDetailsResponseFunction,
    getAccountDetailsResponseData,
  ] = useLazyQuery(GET_ACCOUNT_DETAILS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getAccountDetailsForManageAccount: responseData }) => {
      if (responseData.success) {
        // dispatch({
        //     type: GET_USER_PROFILE_DATA,
        //     payload: responseData,
        //   });
      } else {
        // Handle error response
      }
    },
  });

  const getAccountDetailsQueryData = getAccountDetailsResponseData;

  const initGetAccountDetailsData = (data, successCallback, errorCallback) => {
    getAccountDetailsResponseFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.getAccountDetailsForManageAccount?.success) {
        successCallback(res);
      } else {
        errorCallback(res);
      }
    }).catch((err) => {
      errorCallback(err);
    });
  };
//   const initGetAccountDetailsData = () => {
//     getAccountDetailsResponseFunction();
//   };

  return { getAccountDetailsQueryData, initGetAccountDetailsData };
};
