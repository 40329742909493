import {useLazyQuery} from "@apollo/client"
import gql from "graphql-tag";

const GET_USER_INSURANCE_DETAIL=gql`
query GetUserInsuranceDetails {
    getUserInsuranceDetails {
      success
      message
      data {
        insurance_expire_date
        insurance_policy_number
        status
        reason
        insurance_company_name
        documents {
          id
          document_name
          document_url
          document_title
        }
      }
    }
  }
`;

export const GetUserInsuranceDetailAction=()=>{
    const [getUserInsuranceDetailsFunc,
    getUserInsuranceDetailsRespnoseData]=useLazyQuery(GET_USER_INSURANCE_DETAIL,{
        fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted:({getUserInsuranceDetails:responseData})=>{
        if(responseData.success){
            console.log("res",responseData)
        }else{
            console.log("error")
        }
    }
    })

    const getUserInsuranceDetailsQueryData=getUserInsuranceDetailsRespnoseData;

    const initGetUserInsuranceDetailData=(data)=>{
        getUserInsuranceDetailsFunc({
            variables:data,
        })
    }

    return {getUserInsuranceDetailsQueryData,initGetUserInsuranceDetailData}
}