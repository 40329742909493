import { GET_Hair_Products_Details, SET_ERROR_PAGE } from "../../constants/hair-prescription-product/HairProductConts";


const initialState = {
    hairProductsDetail: [],
    errorPage: false
}

export default function HairProductDetailsReducer(state = initialState, action) {

    switch (action.type) {

        case GET_Hair_Products_Details:
            return {
                ...state,
                hairProductsDetail: action.payload,
                errorPage: false // Reset errorPage to false when successful data is received
            };

        case SET_ERROR_PAGE:
            return {
                ...state,
                errorPage: action.payload
            };

        default:
            return state;
    }
    
}


