import React, { useEffect, useRef } from 'react'
import './email-client.scss'
import TextInput from '../common/TextInput'
import StepButton from '../common/StepButton'
import { SendEmailToClientMutation } from '../redux/actions/client/SendEmailToClient'
import { toast } from 'react-toastify'
const EmailClient = ({clientId,setIsEmailForm}) => {
  const [emailData, setEmailData] = React.useState({
    subject: '',
    message: ''
  })

//Send Email To The Client Mutation
const {initiateSendEmailToClient,sendEmailToClientMutationData} = SendEmailToClientMutation()

  const handleChange = (e) => {
    setEmailData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
  }

  const sendEmailToClient=()=>{
    initiateSendEmailToClient({
        clientId:clientId,
        emailSubject:emailData.subject,
        emailContent:emailData.message
     
    },(res)=>{
      console.log("res",res)
      setEmailData({
        subject: '',
        message: ''
      })
      if(res.success){
        setIsEmailForm(false)
        toast.success(res.message, {toastId: 'send-email-success-toast'})
      }else{
        setIsEmailForm(true)
        toast.error(res.message, {toastId: 'send-email-error-toast'});
      }
    },(err)=>{
      console.log("err",err)
      setIsEmailForm(true)
      toast.error(err.message)
    })
  }
  return (
    <div className='email-client-container'>
      {/* <h1>Email client</h1> */}
      <TextInput label="Subject" autoFocus={false} defaultValue={emailData.subject} value={emailData.subject} name={"subject"} onChange={(e)=>handleChange(e)} placeHolder="Add a subject line" />
      <TextInput multiline={true} autoFocus={false} label="Your message" defaultValue={emailData.message} value={emailData.message} name={"message"} onChange={(e)=>handleChange(e)} rows={40} placeHolder="Add email" />
      <StepButton label={"Send"} disabled={emailData?.subject.length === 0 || emailData?.message.length === 0 || sendEmailToClientMutationData.loading} blue={true} className={"mt-5"} isLoading={sendEmailToClientMutationData.loading} onClick={() => sendEmailToClient()}  />
    </div>
  )
}

export default EmailClient