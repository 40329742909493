import React, { useState, useEffect, useRef } from "react";
import SwitchButton from "../../common/SwitchButton";
import { Input } from "@mui/base";
import InputFieldsComponent from "../createWbsiteURL/commonComponents/FormComp";
import InputComponent from "../../common/InputComponent";
import StepButton from "../../common/StepButton";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import webURLimg from "../../../images/urlImage.svg";
import BusinessHours from "./businessHours";
import "../createWbsiteURL/steps.scss";
import SkipButtonComp from "../createWbsiteURL/commonComponents/SkipBtn";
import 'aos/dist/aos.css';
import AOS from 'aos';
import ImageTitleComponent from "../createWbsiteURL/commonComponents/ImgTitleComp";
import { UpdatePersonalDetails } from "../../redux/actions/createWebsite/updatePersonalDetails";
import { SaveBusinessHours } from "../../redux/actions/createWebsite/saveBusinessHours";
import { is } from "date-fns/locale";
import { set } from "lodash";
import { validEmail } from "../../../utils/Regex";
import toast from 'react-hot-toast';
import ToastbarComponent from "../../common/ToasterComponent";
import { GetWebsiteDetailsAction } from "../../redux/actions/createWebsite/getWebsiteDetails";

const PersonalDetailsStep = (props) => {
  const { style, isMobile, prevWebOnClick, stepEightData, setCount, count } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [clickedOnce, setClickedOnce] = useState(false);
  const businessHoursRef = useRef(null);
  const { initGetWebsiteDataResponseFunction } = GetWebsiteDetailsAction();

  // const [showBusinessHours, setShowBusinessHours] = useState(false);


  const [weekdays, setWeekdays] = useState([
    { day: "Monday", startTime: "09:00", endTime: "18:00", checked: true },
    { day: "Tuesday", startTime: "09:00", endTime: "18:00", checked: true },
    { day: "Wednesday", startTime: "09:00", endTime: "18:00", checked: true },
    { day: "Thursday", startTime: "09:00", endTime: "18:00", checked: true },
    { day: "Friday", startTime: "09:00", endTime: "18:00", checked: true },
    { day: "Saturday", startTime: "09:00", endTime: "18:00", checked: false },
    { day: "Sunday", startTime: "09:00", endTime: "18:00", checked: false },
  ]);

  const { saveBusinessHoursMutationData, initiatesaveBusinessHours } = SaveBusinessHours();

  const { initiateupdatePersonalDetails, updatePersonalDetailsMutationData: { loading: updateDetailsLoading } } = UpdatePersonalDetails();

  const [personalDetails, setPersonalDetails] = useState({
    pwcFname: "",
    pwcLname: "",
    pwcBusinessEmail: "",
    pwcBusinessContact: "",
    pwcDisplayBusinessHours: false,
  });

  const [tempSaveData, setTempSaveData] = useState({
    pwcFname: "",
    pwcLname: "",
    pwcBusinessEmail: "",
    pwcBusinessContact: "",
    pwcDisplayBusinessHours: false,
  });

  useEffect(() => {
    // props.setWebsiteData({...props.websiteData, personalDetails: {...personalDetails, pwcDisplayBusinessHours: isChecked}})
  }, [isChecked])

  useEffect(() => {
    setPersonalDetails(props?.websiteData?.personalDetails);
    if (props?.websiteData?.personalDetails?.pwcDisplayBusinessHours === true) {
      setIsChecked(true);
    }
  }, [props.websiteData?.personalDetails])


  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-in-out',
    });
    return () => {
      AOS.refresh();
    };
  }, []);

  useEffect(() => {
    if (clickedOnce) {
      if (isChecked) {
        setPersonalDetails({ ...personalDetails, pwcDisplayBusinessHours: true })
        // props.setWebsiteData({...props.websiteData, personalDetails: {...props.websiteData.personalDetails, pwcDisplayBusinessHours: true}})
        // if (!clickedOnce) {
        //   // setShowBusinessHours(true);
        //   props.setWebsiteData({...props.websiteData, showHoursOnPage: true})
        // }
      }
      else {
        setPersonalDetails({ ...personalDetails, pwcDisplayBusinessHours: false })
        // props.setWebsiteData({...props.websiteData, personalDetails: {...props.websiteData.personalDetails, pwcDisplayBusinessHours: false}})
      }
    }

  }, [isChecked, clickedOnce]);


  ;
  const handleSkipClick = () => {
    props.trackActivity({ activityStep: 12 });
    props.setWebsiteData({
      ...props.websiteData,
      personalDetails: tempSaveData,
    });
    props.setStep(props.step + 1);
  };
  const handleContainerScroll = () => {
    AOS.refresh();
  };

  useEffect(() => {
    if (props.websiteData.personalDetails?.pwcFname && props.websiteData.personalDetails?.pwcLname && props.websiteData.personalDetails?.pwcBusinessContact && count.step8 === 0) {
      setTempSaveData({
        pwcFname: props.websiteData.personalDetails?.pwcFname,
        pwcLname: props.websiteData.personalDetails?.pwcLname,
        pwcBusinessContact: props.websiteData.personalDetails?.pwcBusinessContact,
        pwcBusinessEmail: props.websiteData.personalDetails?.pwcBusinessEmail,
        pwcDisplayBusinessHours: props.websiteData.personalDetails?.pwcDisplayBusinessHours,
      });
      setCount({
        ...count,
        step8: 1,
      });
    }
  }, [props.websiteData.personalDetails?.pwcFname, props.websiteData.personalDetails?.pwcLname, props.websiteData.personalDetails?.pwcBusinessContact, props.websiteData.personalDetails?.pwcDisplayBusinessHours])

  console.log(personalDetails, "personalDetails")
  return (
    <>
      <div className="inputWrapper">
        <div className="formWrapper" onScroll={handleContainerScroll} >
          {
            // !props.websiteData.showHoursOnPage? (
            <div className="personal-detail-step text-start">
              <div className="formInnerHeight" style={style} id="example-anchor">
                <ImageTitleComponent {...stepEightData} />
                <InputComponent
                  type="fname"
                  label={"First Name"}
                  placeholder={""}
                  value={personalDetails?.pwcFname}
                  personalDetails={personalDetails}
                  setPersonalDetails={setPersonalDetails}
                  dataAos="fade-up" dataAosDuration={700}
                  dataAosAnchor="#example-anchor"
                />
                <InputComponent
                  type="lname"
                  label={"Last Name"}
                  placeholder={""}
                  value={personalDetails?.pwcLname}
                  personalDetails={personalDetails}
                  setPersonalDetails={setPersonalDetails}
                  dataAos="fade-up" dataAosDuration={800}
                  dataAosAnchor="#example-anchor"
                />
                <InputComponent
                  type="email"
                  label={"Business Email address"}
                  placeholder={""}
                  value={personalDetails?.pwcBusinessEmail}
                  personalDetails={personalDetails}
                  setPersonalDetails={setPersonalDetails}
                  dataAos="fade-up" dataAosDuration={900}
                  dataAosAnchor="#example-anchor"
                />
                <InputComponent
                  type="contact"
                  textType="number"
                  label={"Business Contact Number"}
                  placeholder={""}
                  value={personalDetails?.pwcBusinessContact}
                  personalDetails={personalDetails}
                  setPersonalDetails={setPersonalDetails}
                  dataAosAnchor="#example-anchor"
                  dataAos="fade-up" dataAosDuration={1000}
                />

                <div className="d-flex gap-2" dataAos="fade-up" dataAosDuration={1500}>
                  <SwitchButton
                    onChange={(e) => {
                      setClickedOnce(true);
                      if (!isChecked) {
                        setTimeout(() => {
                          businessHoursRef.current.scrollIntoView({
                            behavior: "smooth",
                          });
                        }, 200);
                      }
                      console.log(e.target.checked, "value checked");
                      setIsChecked(!isChecked);
                    }}
                    isChecked={isChecked}
                  />
                  <div className="button-desc">
                    <p>Business Hours</p>
                    <span>
                      Turn on if you want to display business hours in your
                      website
                    </span>
                  </div>
                </div>
                <div ref={businessHoursRef} style={{ display: personalDetails.pwcDisplayBusinessHours ? "block" : "none", height: props.websiteData.personalDetails.pwcDisplayBusinessHours ? "auto" : "0px" }} className="mt-5">
                  <BusinessHours weekdays={weekdays} setWeekdays={setWeekdays} />
                </div>

              </div>

              <div className="FormButtonWrapper">
                <StepButton
                  disabled={!personalDetails?.pwcFname?.length || !personalDetails?.pwcBusinessContact?.length || !personalDetails?.pwcLname?.length || !personalDetails?.pwcBusinessEmail?.length}
                  isLoading={updateDetailsLoading}
                  onClick={() => {
                    if (validEmail.test(personalDetails?.pwcBusinessEmail)) {

                      initiatesaveBusinessHours({
                        personalWebsiteBusinessHours: weekdays.map((item, index) => ({
                          pwbh_day: index,
                          pwbh_is_open: item.checked ? 1 : 0,
                          pwbh_start: item.startTime,
                          pwbh_end: item.endTime,
                          pwbh_id: item.id,
                        })),
                      });
                      initiateupdatePersonalDetails(
                        personalDetails,
                        (responseData) => {
                          props.trackActivity({ activityStep: 12 });
                          if (props?.editStep) {
                            props?.setStep(0);
                            props.setWebsiteData({
                              ...props.websiteData,
                              personalDetails: personalDetails,
                            });
                          } else {
                            props.setStep(props.step + 1);
                            props.setWebsiteData({
                              ...props.websiteData,
                              personalDetails: personalDetails,
                            });

                          }
                          initGetWebsiteDataResponseFunction();
                        },
                        (error) => {
                          console.log(error);
                        }
                      );

                    } else {
                      toast('Please enter valid email address', { icon: "" })
                    }

                  }}
                  blue
                  label={props?.editStep ? "Update Changes" : "Next"}
                  isLeftIcon={props?.editStep ? true : false}
                  icon={!props.editStep ? <FiChevronRight className="arrow_next" /> : ""}
                  leftIcon={props?.editStep ? <FiChevronLeft className="arrow_next" /> : ""}
                />
                {!props?.editStep && (
                  <SkipButtonComp
                    onClick={handleSkipClick}
                    prevWebOnClick={prevWebOnClick}
                    isMobile={isMobile}
                  />
                )}
              </div>
            </div>
            // ) 
            // : 
            // (
            //   <div>
            //     <div className="formInnerHeight" style={style}>
            //       <ImageTitleComponent {...stepEightData} />
            //       <BusinessHours weekdays={weekdays} setWeekdays={setWeekdays} />
            //     </div>
            //     <div className="FormButtonWrapper">
            //       <StepButton
            //         onClick={() => {
            //           setClickedOnce(true);
            //           props.setWebsiteData({...props.websiteData, showHoursOnPage: false})
            //           // props.setWebsiteData({
            //           //   ...props.websiteData,
            //           //   personalDetails: {
            //           //     ...personalDetails,
            //           //     pwcDisplayBusinessHours: true,
            //           //   },
            //           // });
            //         }}
            //         blue
            //         label={"Done"}
            //         icon={<FiChevronRight className="arrow_next" />}
            //       />
            //       {/* <SkipButtonComp /> */}
            //     </div>
            //   </div>
            // )
          }
        </div>
      </div>
      <ToastbarComponent toast={toast} />
    </>
  );
};

export default PersonalDetailsStep;
