import { CLEAR_VERIFIED_BUSINESS_BADGE, GET_VERIFIED_BUSINESS_BADGE } from "../../constants/verifiedBusinesBadge/verifiedBusinessBadge";


const initialState = {
  isVerified: null,
};

export default function verifiedBusinessBadgeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_VERIFIED_BUSINESS_BADGE:
      return {
        ...state,
        isVerified: action.payload,
      };
      case CLEAR_VERIFIED_BUSINESS_BADGE:
        return {
         ...state,
          isVerified: null,
        };

    default:
      return state;
  }
}
