import React, { useEffect, useState } from "react";
import "./steps.scss";
import InputFieldsComponent from "./commonComponents/FormComp";
import StepButton from "../../common/StepButton";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import SkipButtonComp from "./commonComponents/SkipBtn";
import "aos/dist/aos.css";
import AOS from "aos";
import ImageTitleComponent from "./commonComponents/ImgTitleComp";
import CustomeLoading from "../../customeSpiner";
import { SaveWebsiteDetails } from "../../redux/actions/createWebsite/saveWebsiteDetails";
import { CheckWebsiteDomainAction } from "../../redux/actions/createWebsite/checkWebsiteDomain";
import { UpdateWebsite } from "../../redux/actions/createWebsite/updateWebsite";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import { __InputValue } from "graphql";
import { useSelector } from "react-redux";
import { set } from "lodash";
import { te } from "date-fns/locale";
import ToastbarComponent from "../../common/ToasterComponent";
import { GetWebsiteDetailsAction } from "../../redux/actions/createWebsite/getWebsiteDetails";

const WebsiteURLStep1 = (props) => {
  const {
    style,
    isMobile,
    prevWebOnClick,
    stepOneData,
    count,
    setCount,
    webDetailsLoading,
    setTempSaveBusinessData,
    tempSaveBusinessData,
  } = props;
  const [inputCount, setInputCount] = useState(0);
  const [error, setError] = useState(false);
  const {
    initCheckWebsiteDateResponseFunction,
    checkWebsiteDomainResponseData: { loading: domainCheckloading },
  } = CheckWebsiteDomainAction();
  const {
    initiateupdateWebsite,
    updateWebsiteMutationData: {
      loading: updateWebsiteLoading,
      called: updateCalled,
    },
  } = UpdateWebsite();
  const inputFieldsData = {
    businessLabel: "Business Name",
    websiteLabel: "Faces website URL",
    businessNamePlaceholder: "Ex: Faces Consent",
    websiteURLPlaceholder: "yourwebsitename.faces.com",
  };
  const checkWebsiteDomainData = useSelector(
    (state) => state.WebsiteDetailsReducer.checkWebsiteDomainResponseData
  );
  const { initGetWebsiteDataResponseFunction } = GetWebsiteDetailsAction();

  useEffect(() => {
    initCheckWebsiteDateResponseFunction();
  }, [props?.editStep, props?.step]);

  const handleSkipClick = () => {
    console.log("in skip click", tempSaveBusinessData);
    props.trackActivity({ activityStep: props.step + 2 });
    setInputCount(0);
    // setCount({
    //   ...count,
    //   step1: 0,
    // });
    props.setWebsiteData({
      ...props.websiteData,
      businessName: tempSaveBusinessData.businessName,
      websiteUrl: tempSaveBusinessData.websiteUrl,
    });
    props.setStep(props.step + 1);
  };
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-in-out",
    });
    return () => {
      AOS.refresh();
    };
  }, []);

  useEffect(() => {
    if (props.websiteData.websiteUrl && props.editStep) {
      if (
        props.websiteData.websiteUrl.includes("www") ||
        props.websiteData.websiteUrl.includes("https://")
      ) {
        setError(true);
      } else {
        initCheckWebsiteDateResponseFunction({
          subdomainName: props.websiteData.websiteUrl,
        });
        setError(false);
      }
    }
  }, [props.websiteData.websiteUrl]);

  useEffect(() => {
    console.log(count.step1, "count.step1 in effect");
    if (props.websiteData.businessName && props.websiteData.websiteUrl) {
      console.log("in if");
      setTempSaveBusinessData({
        businessName: props.websiteData.businessName,
        websiteUrl: props.websiteData.websiteUrl,
      });
      // setCount({
      //   ...count,
      //   step1: 1,
      // });
    }
  }, [updateCalled, webDetailsLoading, props.step]);

  console.log("ek tha", tempSaveBusinessData);
  return (
    <>
      <div className="inputWrapper">
        <div className="formInnerHeight" style={style} id="example-anchor">
          <ImageTitleComponent {...stepOneData} />
          {props.getDetailsLoading ? (
            <CustomeLoading />
          ) : (
            <InputFieldsComponent
              {...inputFieldsData}
              checkDomain={initCheckWebsiteDateResponseFunction}
              webDetailsLoading={webDetailsLoading}
              loading={domainCheckloading}
              websiteData={props.websiteData}
              setWebsiteData={props.setWebsiteData}
              websiteURLInput={props.websiteData.websiteUrl}
              dataaos={"fade-up"}
              dataAosDuration={700}
              dataAosAnchor="#example-anchor"
              setError={setError}
              setInputCount={setInputCount}
              inputCount={inputCount}
              tempSaveBusinessData={tempSaveBusinessData}
              setTempSaveBusinessData={setTempSaveBusinessData}
              toast={toast}
              editStep={props.editStep}
            />
          )}
        </div>
        <div className="FormButtonWrapper">
          <StepButton
            isLoading={updateWebsiteLoading}
            disabled={props?.websiteData?.businessName?.length === 0 || error}
            onClick={() => {
              if (props?.editStep) {
                if (checkWebsiteDomainData === true) {
                  initiateupdateWebsite(
                    {
                      businessName: props.websiteData.businessName,
                      websiteUrlSlug: props.websiteData.websiteUrl,
                    },
                    (responseData) => {
                      if (responseData?.success) {
                        initGetWebsiteDataResponseFunction();
                        props.trackActivity({ activityStep: props.step + 2 });
                        setInputCount(0);
                        if (props?.editStep) {
                          if (checkWebsiteDomainData === false) {
                            toast("This Subdomain is not available", {
                              icon: "",
                            });
                            return;
                          } else {
                            if (error) {
                              toast(
                                "Please remove www or https:// from website url",
                                { icon: "" }
                              );
                              return;
                            }
                          }
                          console.log("in edit url");
                          props?.setStep(0);
                        } else {
                          if (checkWebsiteDomainData === false) {
                            toast("This Subdomain is not available", {
                              icon: "",
                            });
                            return;
                          } else if (error) {
                            toast(
                              "Please remove www or https:// from website url",
                              { icon: "" }
                            );
                            return;
                          }
                          props.setStep(props.step + 1);
                        }
                      }
                      console.log("in edit url shouldn't run");
                    },
                    (error) => {
                      toast(error, { icon: "" });
                    }
                  );
                } else {
                  toast("This Subdomain is not available", { icon: "" });
                  return;
                }
              } else {
                initiateupdateWebsite(
                  { businessName: props.websiteData.businessName },
                  (responseData) => {
                    if (responseData?.success) {
                      initGetWebsiteDataResponseFunction();
                      props.trackActivity({ activityStep: props.step + 2 });
                      setInputCount(0);
                      if (props?.editStep) {
                        console.log("in edit url");
                        props?.setStep(0);
                      } else {
                        if (checkWebsiteDomainData === false) {
                          toast("This Subdomain is not available", {
                            icon: "",
                          });
                          return;
                        } else if (error) {
                          toast(
                            "Please remove www or https:// from website url",
                            { icon: "" }
                          );
                          return;
                        }
                        props.setStep(props.step + 1);
                      }
                      console.log("in edit url shouldn't run");
                    }
                    // ,
                    // (error) => {
                    //   toast(error, { icon: "" });
                    //   console.log("in edit url shouldn't run");
                    // }
                  },
                  (error) => {
                    toast(error, { icon: "" });
                  }
                );
              }
            }}
            blue={true}
            label={props?.editStep ? "Update changes" : "Create website"}
            isLeftIcon={props?.editStep ? true : false}
            icon={
              !props.editStep ? <FiChevronRight className="arrow_next" /> : ""
            }
            leftIcon={
              props?.editStep ? <FiChevronLeft className="arrow_next" /> : ""
            }
          />
          {/* {!props?.editStep && (
            <SkipButtonComp
              onClick={handleSkipClick}
              prevWebOnClick={prevWebOnClick}
              isMobile={isMobile}
            />
          )} */}
        </div>
      </div>
      <ToastbarComponent toast={toast} />
    </>
  );
};

export default WebsiteURLStep1;
