import React, { useEffect, useRef, useState } from "react"
import FinaceHeader from "../../../finaceHeader";
import img from "../../../../assets/finance-page/noFinancePageImg.png"
import "../no-finance-requests/no-finance-request-page.scss"
import { toast } from "react-toastify";
import CustomModal from "../../../modal/CustomModal";
import { AddForwardFinanceDetail } from "../../../redux/actions/financeDashboard/addForwardFinanceDetailsGql";
import TextInput from "../../../common/TextInput";
import moment from "moment";
import { useNavigate } from "react-router";
import CustomDropdown from "../CustomDropdown/CustomDropdown";

const NoFinanceRequestsPage = () => {
  const [forwardFinanceModal, setForwardFinanceModal] = useState(false);
  const [dateApmtError, setDateApmtError] = useState(false);
  const [dateApmtValue, setDateApmtValue] = useState('');
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [treatmentFields, setTreatmentFields] = useState([{ id: 1 }]);

  const [applicationFormData, setApplicationFormData] = useState({
    clientName: '',
    email: '',
    dateAppointment: '',
    locationName: '',
    time: '',
    treatments: [],
    consentForms: '',
    amount: '',
  });
  const [validationErrors, setValidationErrors] = useState({
    clientName: '',
    email: '',
    dateAppointment: '',
    locationName: '',
    time: '',
    treatments: [''],
    consentForms: '',
    amount: '',
  });
  const [addVerifyToBePostError, setAddVerifyToBePostError] = useState('');
  const [trtFiledsIdCount, setTrtFiledsIdCount] = useState(2);

  const navigate = useNavigate();

  useEffect(() => {
    var date = moment(year + '-' + month + '-' + day).format('YYYY-MM-DD');
    if (
      day &&
      month &&
      year &&
      day?.length == 2 &&
      month.length == 2 &&
      year?.length == 4 &&
      date > moment().format('YYYY-MM-DD')
    ) {
      setDateApmtError(false);
      setValidationErrors((prev) => ({
        ...prev,
        dateAppointment: '',
      }));
      setApplicationFormData({
        ...applicationFormData,
        dateAppointment: date,
      });
      setDateApmtValue(date);
    } else if (day && month && year) {
      setDateApmtError(true);
      setValidationErrors((prev) => ({
        ...prev,
        dateAppointment: 'Invalid Date of Appointment',
      }));
    }
  }, [day, month, year]);

  const addTreatmentFiled = () => {
    console.log('length:', treatmentFields.length);
    setTreatmentFields([...treatmentFields, { id: trtFiledsIdCount }]);
    setTrtFiledsIdCount(trtFiledsIdCount + 1);
    // setTreatmentFields(treatmentFields.map((item, index) => item.id = parseInt(index) + 1));
    // setTreatmentFields(treatmentFields.map((item, index) => ({ id: parseInt(index) + 1 })));
  };

  const {
    addForwardFinanceDetailQueryData,
    initAddForwardFinanceDetailData,
  } = AddForwardFinanceDetail();

  const handleChange = (e, index = null) => {
    const { name, value } = e.target;
    if (name === 'treatments') {
      console.log('treatments', value);
      let val = [...applicationFormData.treatments];
      val[index] = value;
      setApplicationFormData((prev) => ({
        ...prev,
        [name]: val,
      }));
      validateInput(name, value, index);
      console.log('all:::', applicationFormData.treatments);
    } else {
      setApplicationFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      validateInput(name, value);
    }
  };


  function isValidEmail(email) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  }

  const validateInput = (name, value, index = null) => {
    switch (name) {
      case 'clientName':
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            clientName: 'Client name is required',
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            clientName: '',
          }));
        }
        break;
      case 'email':
        if (!value || !isValidEmail(value)) {
          setValidationErrors((prev) => ({
            ...prev,
            email: 'Valid email is required',
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            email: '',
          }));
        }
        break;
      case 'locationName':
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            locationName: 'Location name is required',
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            locationName: '',
          }));
        }
        break;
      case 'treatments':
        if (!value) {
          let errors = [...validationErrors.treatments];
          errors[index] = 'Treatment is required';
          setValidationErrors((prev) => ({
            ...prev,
            treatments: errors,
          }));
        } else {
          let errors = [...validationErrors.treatments];
          errors[index] = '';
          setValidationErrors((prev) => ({
            ...prev,
            treatments: errors,
          }));
        }
        break;
      case 'consentForms':
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            consentForms: 'consent form is required',
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            consentForms: '',
          }));
        }
        break;
      case 'amount':
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            amount: 'amount is required',
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            amount: '',
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleForwardApplication = () => {
    setForwardFinanceModal(true);
  };

  const removeTreatmentFiled = (id, index) => {
    console.log('length: remove', id, index);
    let errors = [...validationErrors.treatments];
    let err = errors.filter((item, i) => i !== index);
    errors[index] = '';
    setValidationErrors((prev) => ({
      ...prev,
      treatments: err,
    }));
    setTreatmentFields(treatmentFields.filter((item) => item.id !== id));
  };


  const validateForm = () => {
    // const newErrors = {};
    // for (const key in applicationFormData) {
    //   if (key !== 'treatments') {
    //     if (applicationFormData[key].trim() === '') {
    //       newErrors[key] = 'This field is required';
    //     }
    //   }
    // }
    // setDateApmtError(true);
    // setValidationErrors(newErrors);
    // return Object.keys(newErrors).length === 0;
    const newErrors = { ...validationErrors }; // Copy existing errors to update
    let hasErrors = false; // Flag to track if any errors are found

    // Validate other fields excluding 'treatments'
    for (const key in applicationFormData) {
      if (key !== 'treatments') {
        if (applicationFormData[key].trim() === '') {
          newErrors[key] = 'This field is required';
          console.log('err packed 328', key);
          hasErrors = true;
        } else {
          // Clear error message if the field is not empty
          newErrors[key] = '';
        }
      }
    }
    console.log('all::', applicationFormData.treatments);
    // Validate 'treatments' array
    if (!applicationFormData.treatments || applicationFormData.treatments.length === 0) {
      newErrors.treatments = ['Treatment is required'];
      hasErrors = true;
      console.log('err packed 341');
    } else {
      applicationFormData.treatments.forEach((treatment, index) => {
        if (!treatment) {
          newErrors.treatments[index] = 'Treatment is required';
          hasErrors = true;
          console.log('err packed 347', index);
        } else {
          // Clear error message if the treatment is not empty
          newErrors.treatments[index] = '';
        }
      });
    }

    setValidationErrors(newErrors); // Update validation errors state

    return !hasErrors; // Return true if no errors found
  };

  const handleFinanceApplicationSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateForm();
    console.log('isValid', isFormValid);
    if (isFormValid) {
      // Perform form submission logic here
      const reqData = {
        clientName: applicationFormData.clientName,
        clientEmail: applicationFormData.email,
        dateOfAppointment: dateApmtValue,
        locationName: applicationFormData.locationName,
        time: applicationFormData.time,
        treatments: applicationFormData.treatments,
        consentForms: applicationFormData.consentForms.split(','),
        amount: applicationFormData.amount,
        clinicName: applicationFormData.locationName,
      }
      initAddForwardFinanceDetailData(reqData, (res) => {
        if (res?.data?.addForwardFinanceDetail?.success) {
          navigate("/revenue-dashboard")
        }
        toast.success(res?.data?.addForwardFinanceDetail?.message);
        setForwardFinanceModal(false);
      }, (err) => {
        toast.error(err?.data?.addForwardFinanceDetail?.message ? err.data.addForwardFinanceDetail.message : 'Something went wrong!');
      });
    } else {
      console.log('Form validation failed');
    }
  };

  //CLEAR MODAL DATA
  const handleClearData = () => {
    if (applicationFormData) {
      setApplicationFormData({
        clientName: '',
        email: '',
        dateAppointment: '',
        locationName: '',
        time: '',
        treatments: [],
        consentForms: '',
        amount: '',
      })
      setDay('')
      setMonth('')
      setYear('')
    }

  }

  useEffect(() => {
    if (forwardFinanceModal) {
      handleClearData()
    }
  }, [forwardFinanceModal])

  const TIME = () => {
    const hours = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        hours.push(
          moment({
            hour,
            minute
          }).format('HH:mm')
        );

      }
    }
    return hours;
  };


  const handleTimeSlotChange = (selectedValue, id) => {
    console.log('time slot selection', selectedValue);
    setApplicationFormData((prev) => ({
      ...prev,
      time: selectedValue
    }));
    setValidationErrors((prev) => ({
      ...prev,
      time: ''
    }));
  }

  return (
    <>
      <div className="noFinancePage ">
        <FinaceHeader />
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-8 col-sm-12">
              <div className="noFinancePageWrapper my-5">
                <div className="noFileImg">
                  <img src={img} alt="img" />
                </div>
                <h5 className="noFileStepTitle">
                  Currently you have no data, <br /> Lets get you started in 2 simple steps.
                </h5>
                <div className="steps d-flex flex-column">
                  <div className="d-flex flex-column stepWrapper">
                    <h6 className="stepTitle">Step 1:</h6>
                    <p className="stepDesc">
                      Forward this link to clients or customers who have requested finance.
                    </p>
                  </div>
                  <div className="d-flex flex-column stepWrapper">
                    <h6 className="stepTitle">Step 2:</h6>
                    <p className="stepDesc">
                      We will then update your dashboard with the relevant data.
                    </p>
                  </div>
                </div>
                <button type="submit" onClick={handleForwardApplication} className="apply-btn  btn-primary">
                  Forward Finance Link
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        modalOpen={forwardFinanceModal}
        setModalOpen={setForwardFinanceModal}
        modaltitle={'Forward Finance Application.'}
        type={'forward-finance-application'}
        className={'forwardFinanceApplicationModel'}
        modalBody={
          <>
            <div className="review-card my-2">
              <div className="mt-3 d-flex justify-content-start">
                <p>
                  Please enter the clients details and the amount they would
                  like to finance. We will send them an email where they can
                  complete the application. You will be notified if there
                  application is approved.
                </p>
              </div>
            </div>
            <div className="inputAllWrapper">
              <div
                className={`d-flex flex-column ${validationErrors ? 'gap-30' : 'gap-4'
                  } error-section`}
              >
                <div className="inputErrorWrapper">
                  <TextInput
                    id="clientName"
                    label="Client Name"
                    placeholder="Client Name"
                    name="clientName"
                    value={applicationFormData?.clientName}
                    onChange={(e) => handleChange(e)}
                  />
                  {validationErrors.clientName && (
                    <span className="error">{validationErrors.clientName}</span>
                  )}
                </div>
                <div className="inputErrorWrapper">
                  <TextInput
                    label="Email"
                    placeholder="name@gmail.com"
                    value={applicationFormData?.email}
                    onChange={(e) => handleChange(e)}
                    name="email"
                  />
                  {validationErrors.email && (
                    <span className="error">{validationErrors.email}</span>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="row w-100 m-auto">
                  <div className="p-0">
                    <label className="form-label">Date of Appointment</label>
                    <div className="row d-flex">
                      <div className="col-md-4">
                        <TextInput
                          label=""
                          placeholder="DD"
                          maxLength={'2'}
                          id={'day'}
                          name="day"
                          type="number"
                          min="1"
                          ref={dayRef}
                          max="31"
                          value={day}
                          onChange={(e) => {
                            if (e.target.value.length >= 2) {
                              document.getElementById('month').focus();
                            }
                            const inputValue = e.target.value;
                            if (inputValue === '') {
                              setDateApmtError(true);
                              setValidationErrors((prev) => ({
                                ...prev,
                                dateAppointment: 'Invalid Date of Appointment',
                              }));
                            }
                            const regex = /^[0-9]{0,2}$/;
                            if (regex.test(inputValue)) {
                              setDay(inputValue);
                            }
                          }}
                        />
                      </div>
                      <div className="col-md-4 ">
                        <TextInput
                          label=""
                          id={'month'}
                          placeholder="MM"
                          name="month"
                          min="1"
                          max="12"
                          maxLength="2"
                          type="number"
                          ref={monthRef}
                          value={month}
                          onKeyDown={(e) => {
                            if (!e.target.value && e.key === 'Backspace') {
                              document.getElementById('day').focus();
                            }
                          }}
                          onChange={(e) => {
                            if (e.target.value >= 2) {
                              document.getElementById('year').focus();
                            }
                            const inpValue = e?.target?.value;
                            if (inpValue == '') {
                              setDateApmtError(true);
                              setValidationErrors((prev) => ({
                                ...prev,
                                dateAppointment: 'Invalid Date of Appointment',
                              }));
                            }
                            const regex = /^[0-9]{0,2}$/;
                            if (regex.test(inpValue)) {
                              setMonth(inpValue);
                            }
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextInput
                          label=""
                          placeholder="YYYY"
                          maxLength={4}
                          ref={yearRef}
                          type="number"
                          name="year"
                          id={'year'}
                          min="1900"
                          max="2024"
                          value={year}
                          onKeyDown={(e) => {
                            if (!e.target.value && e.key === 'Backspace') {
                              document.getElementById('month').focus();
                            }
                          }}
                          onChange={(e) => {
                            console.log('key pressed', e.target.value);
                            const inputValue = e?.target?.value;
                            const regex = /^[0-9]{0,4}$/;
                            if (inputValue == '') {
                              setDateApmtError(true);
                              setValidationErrors((prev) => ({
                                ...prev,
                                dateAppointment: 'Invalid Date of Appointment',
                              }));
                            }
                            if (regex.test(inputValue) || inputValue == '') {
                              setYear(inputValue);
                            }
                          }}
                        />
                      </div>
                      {dateApmtError && validationErrors.dateAppointment && (
                        <span className="error">
                          {validationErrors.dateAppointment}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`finance-application-form d-flex flex-column ${validationErrors ? 'gap-30' : 'gap-30'
                  } error-section`}
              >
                <div className="inputErrorWrapper">
                  {/* <TextInput
                                  id="time"
                                  type="time"
                                  label="Time"
                                  placeholder="Time"
                                  name="time"
                                  value={applicationFormData?.time}
                                  onChange={(e) => handleChange(e)}
                              /> */}
                  <CustomDropdown
                    labelName={"Time"}
                    dropdownId={"startTime"}
                    placeholdername={"Select Time"}
                    optionsArr={TIME()}
                    defaultValue={applicationFormData?.time}
                    handleSelectedValue={handleTimeSlotChange}
                  />
                  {validationErrors.time && (
                    <span className="error">{validationErrors.time}</span>
                  )}
                </div>
                <div className="inputErrorWrapper">
                  <TextInput
                    id="locationName"
                    label="Location Name"
                    placeholder="Location Name"
                    name="locationName"
                    value={applicationFormData?.locationName}
                    onChange={(e) => handleChange(e)}
                  />
                  {validationErrors.locationName && (
                    <span className="error">{validationErrors.locationName}</span>
                  )}
                </div>

                {treatmentFields.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-center mt-2" key={item.id}>
                    <div className="row w-100 m-auto">
                      <div className="p-0">
                        <label className="form-label">Treatments</label>
                        <div className="inputErrorWrapper">
                          <div className="row d-flex">
                            <div className="col-md-7">
                              <TextInput
                                id="treatments"
                                label=""
                                placeholder="Treatments"
                                name="treatments"
                                value={applicationFormData.treatments[index]}
                                onChange={(e) => handleChange(e, index)}
                              />
                              {validationErrors.treatments[index] && (
                                <span className="error">
                                  {validationErrors.treatments[index]}
                                </span>
                              )}
                            </div>
                            <div className="col-md-5">
                              {
                                index > 0 ? (
                                  <button
                                    type="button"
                                    className="btn btn-primary add-treatments"
                                    onClick={() => removeTreatmentFiled(item.id, index)}
                                  >
                                    Remove treatment
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-primary add-treatments"
                                    onClick={addTreatmentFiled}
                                  >
                                    Add treatment
                                  </button>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="inputErrorWrapper">
                  <TextInput
                    id="consentForms"
                    label="Consent Forms"
                    placeholder="Consent Forms"
                    name="consentForms"
                    value={applicationFormData?.consentForms}
                    onChange={(e) => handleChange(e)}
                  />
                  {validationErrors.consentForms && (
                    <span className="error">{validationErrors.consentForms}</span>
                  )}
                </div>
                <div className="inputErrorWrapper">
                  <TextInput
                    id="amount"
                    label="Amount"
                    placeholder="£ Amount"
                    type="number"
                    name="amount"
                    value={applicationFormData?.amount}
                    onChange={(e) => handleChange(e)}
                  />
                  {validationErrors.amount && (
                    <span className="error">{validationErrors.amount}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="justify-content-center rounded-circle-border">
              {addVerifyToBePostError && (
                <span className="text-danger">{addVerifyToBePostError}</span>
              )}
              <button
                type="button"
                className="btn btn-primary full-widht"
                onClick={handleFinanceApplicationSubmit}
              >
                Submit
              </button>
            </div>
          </>
        }
      />
    </>
  )
}

export default NoFinanceRequestsPage;