import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const COMPLETE_FORWARD_CONSENT_FORM_PAYMENT_BOOKING_MUTATION = gql`
mutation CompleteConsentFormDepositPayment($bookingId: Int, $cfrToken: String, $paymentId: String, $paymentType: Int, $selectedPayment: completeConsentFormDepositPaymentPaymentMethods) {
  completeConsentFormDepositPayment(booking_id: $bookingId, cfrToken: $cfrToken, payment_id: $paymentId, payment_type: $paymentType, selectedPayment: $selectedPayment) {
    success
    message
    data {
      clinic_name
      booking_date
      booking_time
      treatments
      readable_treatment
      capture_id
      transfer_id
      depositScheduledPayouts_id
      review_added
    }
  }
}
`;

export const CompleteForwardConsentFormPaymentBooking = () => {
  const [completeForwardConsentFormPaymentBookingRequest, completeForwardConsentFormPaymentBookingResponseData] =
    useMutation(COMPLETE_FORWARD_CONSENT_FORM_PAYMENT_BOOKING_MUTATION);
  const dispatch = useDispatch();
  const completeForwardConsentFormPaymentBookingMutationData = completeForwardConsentFormPaymentBookingResponseData;

  const initiateCompleteForwardConsentFormPaymentBooking = (
    data,
    successCallback,
    errorCallback
  ) => {
    completeForwardConsentFormPaymentBookingRequest({
      variables: data,
    })
      .then(({ data: { completeConsentFormDepositPayment: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateCompleteForwardConsentFormPaymentBooking, completeForwardConsentFormPaymentBookingMutationData };
};
