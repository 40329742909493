import { gql, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

const ADD_PRODUCT_TO_WISH_LIST_MUTATION = gql`
  mutation AddProductToWishList($productId: Int) {
    addProductToWishList(product_id: $productId) {
      success
      message
    }
  }
`;

export const AddProductToWishList = () => {
  // const dispatch = useDispatch();
  const [
    AddProductToWishListResponseFunction,
    addProductToWishListResponseData,
  ] = useMutation(ADD_PRODUCT_TO_WISH_LIST_MUTATION);

  //this will be called from components
  const addProductToWishListQueryData = addProductToWishListResponseData; //variable

  const initAddProductToWishListData = (
    data,
    successCallback,
    errorCallback
  ) => {
    AddProductToWishListResponseFunction({
      variables: data,
    })
      // .then(({ data: { AddProductToWishList: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        if (responseData?.data?.addProductToWishList?.success) {
          // dispatch(...)
          successCallback(responseData);
          // toast.success(responseData.message)
        } else {
          errorCallback(responseData);
          // toast.error(responseData.message)
        }
      })
      .catch((err) => {
        errorCallback(err);
        toast.error('something went wrong!!!');
      });
  };

  return {
    addProductToWishListQueryData,
    initAddProductToWishListData,
  };
};
