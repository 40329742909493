import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as BackArrow } from "../../../images/back_arrow.svg";
import "./finance-terms-and-condition.scss";
import { useNavigate } from "react-router";
import { FinanceGetPaidRoute } from "../../../Routes";
import EraseImg from "../../../images/new-appointment/erase.png";
import SignatureCanvas from "react-signature-canvas";
import FinanceHeader from "../../finance-registration/FinanceHomeComponent/FinanceHeaderComponent/FinanceHeaderComponent";
import FinanceBanner from "../../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner";
import StepButton from "../../common/StepButton";
import {SaveFinanceSigntureDetailAction} from "../../redux/actions/finance-registrtion/saveFinanceSignture"
import {GetFinanceSigntureDetailAction} from "../../redux/actions/finance-registrtion/getFinanceSignture"
import {GetFinanceRegistrationStatusAction} from "../../redux/actions/finance-registrtion/getFinanceRegistartionStatus"

export default function FinanceTemsAndCondition({onNextStep,afterSubmit,finish,setComeFromDashBord}) {
  const navigate = useNavigate();
  const sigPadRef = useRef();
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [signatureError, setSignatureError] = useState(null);
  const [getSigntureData,setGetSigntureData]=useState("")
 const [getSigntureDataCheck,setGetSigntureDataCheck]=useState(false)
 const [disabled,setDisabled]=useState(false)

  const {saveFinanceSigntureDetailQueryData,initSaveFinanceSigntureDetailData}=SaveFinanceSigntureDetailAction()
  const {getFinanceSigntureDetailQueryData,initGetFinanceSigntureDetailData}=GetFinanceSigntureDetailAction()
  const {getFinanceRegistrtionStatusQueryData,initGetFinanceRegistrationData} =GetFinanceRegistrationStatusAction()
  
  const {data}=getFinanceSigntureDetailQueryData;
  const StatusData=getFinanceRegistrtionStatusQueryData?.data

  const completedStep=getFinanceRegistrtionStatusQueryData?.data?.getUserRegistrationStatusForFinance?.data;
  
  useEffect(()=>{
   initGetFinanceSigntureDetailData()
  },[])

  useEffect(()=>{
    initGetFinanceRegistrationData()
  },[])

  useEffect(()=>{
    window.scrollTo(0, 0);
},[])

  useEffect(()=>{
  if(StatusData?.getUserRegistrationStatusForFinance?.data){
   const data=StatusData?.getUserRegistrationStatusForFinance?.data?.payl8r_request_approved
   if(data){
    setDisabled(true)
   }
  }
  },[StatusData?.getUserRegistrationStatusForFinance?.data])

  useEffect(()=>{
    const signatureUrl=data?.getUserSignatureForFinance?.data?.signature_url
      if(data?.getUserSignatureForFinance?.data){
          setGetSigntureData(signatureUrl)
          setGetSigntureDataCheck(true)
      }
  },[data?.getUserSignatureForFinance?.data])
console.log("getSigntureData",getSigntureData);

const handleContinue = () => {
    const signture=(trimmedDataURL?.split(",")[1]);
    
  if (!trimmedDataURL) {
      setSignatureError("Please add signature");
      if(getSigntureData){
        setSignatureError("");
        afterSubmit()
        onNextStep()
      }
    } 
    else {
      setSignatureError("");
      initSaveFinanceSigntureDetailData({
        signature:signture
      },(data)=>{
        afterSubmit()
        onNextStep()
        setComeFromDashBord(false)
      },(error)=>console.log(error))
    }
    
  };
  const clear = () => {
    if (trimmedDataURL) {
      sigPadRef?.current?.clear();
    setTrimmedDataURL(null);
    }else{
     setGetSigntureData("")
      setGetSigntureDataCheck(false)
    }
    
  };

  const selectArray = [
    { value: "", label: "Fillers direct" },
    { value: "one", label: "one" },
    { value: "two", label: "two" },
    { value: "three", label: "three" },
    { value: "four", label: "four" },
  ];


  return (
    <div className="Main-Terms-and-ConditionPage">
         {/* <FinanceHeader 
            onBackClick={()=>navigate(FinanceGetPaidRoute())}
         /> */}
      <div className="MainTermsandConditionPageSecWrapper">
          <FinanceBanner
            title="Terms & conditions"
            desc="Please agree to all the terms and conditions from payl8r and faces finance."
            isBorder={!completedStep?.registration_complete && completedStep?.registration_complete !== undefined}
            currentStep={finish}
            totalSteps={4}
        />

          <div className="first-flex-div">
          <div className='disclaimer-box'>
               
                <div className='disclaimer-wrap'>
                    <div className="accordion" id="accordionExample">
                       {/* {
                        forwardConsentFormQuestionsQueryData?.loading ?
                        <SkeletonLoader type="forward-consent-disclaimer"/>
                        :
                        disclaimersList?.map((disclaimer,i)=>(
                            <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${1}`} aria-expanded="false" aria-controls={`collapse${1}`}>
                                    {disclaimer?.consent_form_name} Disclaimer
                                </button>
                            </h2>
                            <div  className="accordion-collapse collapse" aria-labelledby={`heading${1}`} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>{disclaimer?.consent_form_name}</p>
                                    {disclaimer?.disclaimer}
                                </div>
                            </div>
                        </div>
                        ))
                       } */}
                        <div class="accordion-item TermsAndCondition">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                  Terms and Conditions
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                <>
                                  <h2
                                    style={{
                                      paddingTop: "2pt",
                                      paddingLeft: "5pt",
                                      textIndent: "0pt",
                                      textAlign: "left"
                                    }}
                                  >
                                    Definitions
                                  </h2>
                                  <p
                                    style={{
                                      paddingTop: "6pt",
                                      paddingLeft: "5pt",
                                      textIndent: "0pt",
                                      textAlign: "left"
                                    }}
                                  >
                                    In this agreement the terms below have the following meanings:
                                  </p>
                                  <ol id="l1">
                                    <li data-list-text={1}>
                                      <p
                                        style={{
                                          paddingTop: "6pt",
                                          paddingLeft: "45pt",
                                          textIndent: "-31pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        “You”, “Clinic“: the clinic described on the front page of this
                                        Agreement, who is a party to this Agreement.
                                      </p>
                                    </li>
                                    <li data-list-text={2}>
                                      <p
                                        style={{
                                          paddingTop: "6pt",
                                          paddingLeft: "45pt",
                                          textIndent: "-31pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        “Us”, “We”, “Lender”: Social Money Limited.
                                      </p>
                                    </li>
                                    <li data-list-text={3}>
                                      <p
                                        style={{
                                          paddingTop: "6pt",
                                          paddingLeft: "45pt",
                                          textIndent: "-31pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        “Credit Broking”: credit broking within the meaning of Financial
                                        Services Legislation.
                                      </p>
                                    </li>
                                    <li data-list-text={4}>
                                      <p
                                        style={{
                                          paddingTop: "6pt",
                                          paddingLeft: "45pt",
                                          textIndent: "-31pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        “Commission”: the commission as provided for in Schedule A.
                                      </p>
                                    </li>
                                    <li data-list-text={5}>
                                      <p
                                        style={{
                                          paddingTop: "6pt",
                                          paddingLeft: "45pt",
                                          textIndent: "-31pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        “Faces”: Faces Consent Limited (company number 1257819).
                                      </p>
                                    </li>
                                    <li data-list-text={6}>
                                      <p
                                        style={{
                                          paddingTop: "6pt",
                                          paddingLeft: "45pt",
                                          textIndent: "-31pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        “FCA”: the Financial Conduct Authority.
                                      </p>
                                    </li>
                                    <li data-list-text={7}>
                                      <p
                                        style={{
                                          paddingTop: "6pt",
                                          paddingLeft: "45pt",
                                          textIndent: "-31pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        “Financial Services Legislation”: the Financial Services and Markets Act
                                        2000 and any of its subsidiary legislation, the Consumer Credit Act 1974
                                        and any of its subsidiary legislation, and rules or guidance made by the
                                        FCA (including but not limited to rules or guidance in the FCA Handbook)
                                        and any other legal or regulatory obligation which applies to or because
                                        of the activities that are carried out under the scope of this
                                        Agreement.
                                      </p>
                                    </li>
                                    <li data-list-text={8}>
                                      <p
                                        style={{
                                          paddingTop: "6pt",
                                          paddingLeft: "45pt",
                                          textIndent: "-31pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        “Information”: information and/or records in any form, including
                                        documents, files, tapes, computer data, records, accounts, vouchers, and
                                        any other material.
                                      </p>
                                    </li>
                                    <li data-list-text={9}>
                                      <p
                                        style={{
                                          paddingTop: "6pt",
                                          paddingLeft: "45pt",
                                          textIndent: "-31pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        “Loss and Damage”: loss or damage of whatever nature, including without
                                        limitation losses, costs (including legal costs), charges, damages,
                                        expenses, loss of profits, loss of data, loss of income, loss or damage
                                        to property, loss of goodwill, damage to reputation, business losses,
                                        and loss of chance, including pure economic, compensatory, direct,
                                        indirect, or consequential loss and damage.
                                      </p>
                                    </li>
                                    <li data-list-text={10}>
                                      <p
                                        style={{
                                          paddingTop: "6pt",
                                          paddingLeft: "45pt",
                                          textIndent: "-31pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        “Systems”: any systems used to operate a business and/or store
                                        information, whether this be electronic, or document based or in any
                                        other form, and includes computer systems or filings systems, electronic
                                        tapes or other record systems.
                                      </p>
                                    </li>
                                  </ol>
                                  <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                  </p>
                                  <ol start={2} id="l2">
                                    <li data-list-text={2} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "left",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        Recitals
                                      </h2>
                                      <ol id="l3" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="2.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            The Lender is authorised by the FCA to provide finance.
                                          </p>
                                        </li>
                                        <li data-list-text="2.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            The Clinic is a seller of goods and services.
                                          </p>
                                        </li>
                                        <li data-list-text="2.3.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            The Clinic wishes the Lender to provide finance to the Clinic’s
                                            customers.
                                          </p>
                                        </li>
                                        <li data-list-text="2.4.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            The Clinic uses the services of Faces.
                                          </p>
                                        </li>
                                        <li data-list-text="2.5.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            Faces shall introduce customers of the Clinic to the Lender.
                                          </p>
                                        </li>
                                        <li data-list-text="2.6.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            In return for the provision of finance by the Lender to the Clinic’s
                                            customers the Clinic agrees to pay Commission to the Lender.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={3} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "left",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        Payment
                                      </h2>
                                      <ol id="l4" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="3.1.">
                                          <p
                                            style={{
                                              paddingTop: "2pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Lender shall lend money to a customer of the Clinic for a
                                            specific good(s) or service(s). The Lender shall pay the full amount
                                            of the goods or services direct to the Clinic minus the Commission
                                            owed to the Lender in accordance with Schedule A.
                                          </p>
                                        </li>
                                        <li data-list-text="3.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The payments to be made by the Clinic and the Commission due by the
                                            Clinic, and provisions as to deposits and refunds, are set out in
                                            Schedule A.
                                          </p>
                                        </li>
                                        <li data-list-text="3.3.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Commission is a percentage of the gross price of the goods or
                                            services due to be paid by the customer. The percentage amount is
                                            set out in Schedule A.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={4} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        The provision of finance
                                      </h2>
                                      <ol id="l5" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="4.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Lender shall provide finance to the Clinic’s customers in
                                            accordance with the terms of this Agreement.
                                          </p>
                                        </li>
                                        <li data-list-text="4.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            Whether, and on what terms, to provide finance shall be in the
                                            Lender’s absolute discretion. Without prejudice to the generality of
                                            this provision:
                                          </p>
                                          <ol id="l6">
                                            <li data-list-text="4.2.1.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                The Lender may refuse to provide credit to any customer or
                                                customers for whatever reason.
                                              </p>
                                            </li>
                                            <li data-list-text="4.2.2.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                The type of finance product that the Lender provides (for
                                                example interest bearing, buy now pay later) shall be at the
                                                Lender’s absolute discretion.
                                              </p>
                                            </li>
                                            <li data-list-text="4.2.3.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-35pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                The Lender may choose not to offer any finance product (although
                                                the Lender acknowledge that in these circumstances the Lender
                                                will have not have any entitlement to Commission).
                                              </p>
                                            </li>
                                            <li data-list-text="4.2.4.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                The Lender will not provide any credit if in the Lender’s
                                                opinion the provision of credit would be in breach of Financial
                                                Services Legislation.
                                              </p>
                                            </li>
                                            <li data-list-text="4.2.5.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                The Lender will only provide credit if:
                                              </p>
                                              <ol id="l7">
                                                <li data-list-text="4.2.5.1.">
                                                  <p
                                                    style={{
                                                      paddingTop: "6pt",
                                                      paddingLeft: "96pt",
                                                      textIndent: "-36pt",
                                                      textAlign: "justify"
                                                    }}
                                                  >
                                                    the customer has been introduced to the Lender via Faces, or
                                                  </p>
                                                </li>
                                                <li data-list-text="4.2.5.2.">
                                                  <p
                                                    style={{
                                                      paddingTop: "6pt",
                                                      paddingLeft: "92pt",
                                                      textIndent: "-32pt",
                                                      textAlign: "justify"
                                                    }}
                                                  >
                                                    the Clinic is authorised or permitted to carry on Credit
                                                    Broking, and the customer has been introduced directly by
                                                    the Clinic.
                                                  </p>
                                                </li>
                                              </ol>
                                            </li>
                                          </ol>
                                        </li>
                                        <li data-list-text="4.3.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a name="bookmark0">
                                              The Lender shall have no liability to the Clinic:
                                            </a>
                                          </p>
                                          <ol id="l8">
                                            <li data-list-text="4.3.1.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                For any decision not to provide finance.
                                              </p>
                                            </li>
                                            <li data-list-text="4.3.2.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                If the actions of the Clinic results in the Lender failing to
                                                comply with any Financial Services Legislation; and
                                              </p>
                                            </li>
                                            <li data-list-text="4.3.3.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                For any Loss or Damage that has caused by, resulted from or been
                                                contributed to, whether wholly or partly, the Clinic acting in a
                                                way that breaches this Agreement.
                                              </p>
                                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                              </p>
                                            </li>
                                          </ol>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={5} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        <a name="bookmark1">Introductions to the Lender</a>
                                      </h2>
                                      <ol id="l9" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="5.1.">
                                          <p
                                            style={{
                                              paddingTop: "2pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Clinic may not use the services of Faces to enable customers to
                                            obtain finance from any other finance provider other than the
                                            Lender, unless the Lender has declined to provide finance to that
                                            customer.
                                          </p>
                                        </li>
                                        <li data-list-text="5.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a name="bookmark2">
                                              The Clinic must not carry on any activity that would amount to
                                              Credit Broking unless it is authorised by the FCA to do so.
                                            </a>
                                          </p>
                                        </li>
                                        <li data-list-text="5.3.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            Where the Clinic is not authorised or permitted to carry on Credit
                                            Broking it will be a material breach of this Agreement if the Clinic
                                            introduces a Customer to the Lender directly. If the Clinic is not
                                            authorised or permitted to carry on Credit Broking all introductions
                                            of customers to the Lender must be made by Faces.
                                          </p>
                                        </li>
                                        <li data-list-text="5.4.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a href="#bookmark2" className="a">
                                              Without prejudice to the generality of clause{" "}
                                            </a>
                                            5.2 above the Clinic must comply with the Advertising Requirements
                                            and Restrictions set out in Schedule B in respect of its activities.
                                          </p>
                                        </li>
                                        <li data-list-text="5.5.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Lender may vary the Advertising Requirements and Restrictions
                                            from time to time by giving the Clinic notice. The Clinic must
                                            immediately comply with the revised Advertising Requirements and
                                            Restrictions.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={6} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        <a name="bookmark3">Compliance with legislation</a>
                                      </h2>
                                      <ol id="l10" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="6.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Clinic must observe and comply and ensure that any person who
                                            provides services to the Clinic under a contract of services or a
                                            contract of service, observes and complies with Financial Services
                                            Legislation and any requirements that may from time to time be
                                            specified by the FCA or the Lender.
                                          </p>
                                        </li>
                                        <li data-list-text="6.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a name="bookmark4">
                                              The Clinic must also ensure that the Clinic (or its servants or
                                              agents) do not act or fail to act in a way that would make the
                                              Lender in breach of Financial Services Legislation or any
                                              requirement of the FCA.
                                            </a>
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={7} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        <a name="bookmark5">Complaints and refunds</a>
                                      </h2>
                                      <ol id="l11" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="7.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            Where a customer requests a refund and the Clinic agrees to it, the
                                            Clinic must process the refund via its retailer account.
                                          </p>
                                        </li>
                                        <li data-list-text="7.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            Where there are instances of refund or a dispute of a refund, the
                                            Clinic shall use its resources, time, and administration to liaise
                                            with the customer to affect the refund, which shall be carried out
                                            in accordance with Schedule A.
                                          </p>
                                        </li>
                                        <li data-list-text="7.3.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Lender shall not suffer any Loss and Damage that arises out of a
                                            result of a disputed refund between the Clinic and the customer. In
                                            such circumstances where a refund is disputed, the Clinic will
                                            indemnify the Lender for any Loss and Damage suffered that arises as
                                            a result thereof.
                                          </p>
                                        </li>
                                        <li data-list-text="7.4.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            Where the customer requests a refund of the goods or services
                                            supplied, and where the Clinic agree to the refund (in part or in
                                            whole), the Lender will cancel the credit agreement between the
                                            Lender and the customer and the Clinic will pay the refund due to
                                            the customer directly to the Lender, (in a part or in whole), for
                                            the price paid or deposit paid.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                          <p
                                            style={{
                                              paddingTop: "2pt",
                                              paddingLeft: "45pt",
                                              textIndent: "0pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a name="bookmark7">
                                              The customer may still be bound to pay for the finance and
                                              interest for a period that has already transpired, and it is as
                                              the discretion of the Lender whether or not to charge the interest
                                              to the customer for that period.
                                            </a>
                                          </p>
                                        </li>
                                        <li data-list-text="7.5.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Lender will be responsible for complaints made by customers in
                                            respect of the Lender’s lending.
                                          </p>
                                        </li>
                                        <li data-list-text="7.6.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            In the event of the Clinic being contacted by a customer in respect
                                            of a complaint in relation to any lending by the Lender, the Clinic
                                            must immediately notify the Lender who will take responsibility for
                                            handling the complaint. The Clinic must cooperate with the Lender
                                            fully in investigating and resolving that complaint.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={8} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "left",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        Conduct of business
                                      </h2>
                                      <ol id="l12" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="8.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            The Clinic must keep accurate records of all its actions and
                                            communications with the customer.
                                          </p>
                                        </li>
                                        <li data-list-text="8.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            The Clinic will:
                                          </p>
                                          <ol id="l13">
                                            <li data-list-text="8.2.1.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                act in good faith and conscientiously;
                                              </p>
                                            </li>
                                            <li data-list-text="8.2.2.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                obey all reasonable lawful instructions of the Lender;
                                              </p>
                                            </li>
                                            <li data-list-text="8.2.3.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                avoid any conflict of interest with the Lender; and
                                              </p>
                                            </li>
                                            <li data-list-text="8.2.4.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                not make a secret profit or accept bribes.
                                              </p>
                                            </li>
                                          </ol>
                                        </li>
                                        <li data-list-text="8.3.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Clinic must not make any representation about the goods or
                                            services being supplied that is untrue.
                                          </p>
                                        </li>
                                        <li data-list-text="8.4.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a name="bookmark6">
                                              The Clinic must not do or say anything that would give any
                                              customer a claim against the Lender by virtue of sections 56, 75,
                                              75A or 140A-C of the Consumer Credit Act 1974. Without in any way
                                              limiting the forgoing obligation the Clinic must not:
                                            </a>
                                          </p>
                                          <ol id="l14">
                                            <li data-list-text="8.4.1.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                Say anything to a customer that is misleading or untrue, whether
                                                about the goods or services being financed or otherwise;
                                              </p>
                                            </li>
                                            <li data-list-text="8.4.2.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                Provide any goods to the customer that are not of satisfactory
                                                quality or fit for their purpose;
                                              </p>
                                            </li>
                                            <li data-list-text="8.4.3.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                Provide any services to the customer that are not provided with
                                                reasonable care and skill;
                                              </p>
                                            </li>
                                            <li data-list-text="8.4.4.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                Breach any legal provision applicable to the Clinic;
                                              </p>
                                            </li>
                                            <li data-list-text="8.4.5.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                Fail to provide a refund where it is due or otherwise fail to
                                                abide by the Clinic’s own returns and refunds policy.
                                              </p>
                                            </li>
                                          </ol>
                                        </li>
                                        <li data-list-text="8.5.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a href="#bookmark13" className="a">
                                              The Clinic must notify the Lender immediately of any matter that
                                              falls within clause{" "}
                                            </a>
                                            <a href="#bookmark14" className="a">
                                              15.3.1 to{" "}
                                            </a>
                                            <a href="#bookmark15" className="a">
                                              15.3.4 below or which might give rise to or otherwise influence
                                              the Lender’s reasonable belief within clauses{" "}
                                            </a>
                                            <a href="#bookmark16" className="a">
                                              15.3.5 to{" "}
                                            </a>
                                            15.3.7 below.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={9} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingTop: "2pt",
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        Audit
                                      </h2>
                                      <ol id="l15" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="9.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a name="bookmark8">
                                              In order to allow the Lender to audit the Clinic and/or to confirm
                                              compliance with this Agreement the Clinic must:
                                            </a>
                                          </p>
                                          <ol id="l16">
                                            <li data-list-text="9.1.1.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                provide the Lender with access to its premises, Systems and
                                                Information at any reasonable time, with or without notice;
                                              </p>
                                            </li>
                                            <li data-list-text="9.1.2.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                provide the Lender with any information reasonably requested by
                                                the Lender;
                                              </p>
                                            </li>
                                            <li data-list-text="9.1.3.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                print any Information that the Lender reasonably requests in a
                                                legible document or record;
                                              </p>
                                            </li>
                                            <li data-list-text="9.1.4.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                permit the Lender to copy information held by it, or to copy
                                                such information as the Lender reasonably requests, which in
                                                either case if done at its premises shall be at the Clinic’s
                                                reasonable expense;
                                              </p>
                                            </li>
                                            <li data-list-text="9.1.5.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                permit the Lender to remove any copies of information from its
                                                premises;
                                              </p>
                                            </li>
                                            <li data-list-text="9.1.6.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                make itself available for meetings with the Lender’s
                                                representative or appointees; and
                                              </p>
                                            </li>
                                            <li data-list-text="9.1.7.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                answer truthfully, fully and promptly all questions which the
                                                Lender reasonably puts to it.
                                              </p>
                                            </li>
                                          </ol>
                                        </li>
                                        <li data-list-text="9.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Clinic will provide the same rights to the Lender’s Auditors as
                                            are provided by section 341 of the Financial Services and Markets
                                            Act 2000.
                                          </p>
                                        </li>
                                        <li data-list-text="9.3.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a href="#bookmark8" className="a">
                                              In order to discharge any legal or regulatory functions that they
                                              may have, the Clinic will provide the same rights as set out at
                                              clause{" "}
                                            </a>
                                            9.1 above and as are provided by section 342 of the Financial
                                            Services and Markets Act 2000 to HM Customs and Excise, the FCA, and
                                            any other relevant taxation or regulatory body.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={10} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        Regulatory status
                                      </h2>
                                      <ol id="l17" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="10.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Clinic must notify the Lender immediately if there is any change
                                            in its regulatory status or the Clinic applies for any change in its
                                            regulatory status (for example the Clinic applies to become
                                            authorised).
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={11} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        <a name="bookmark9">Indemnity and liabilities</a>
                                      </h2>
                                      <ol id="l18" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="11.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            Save that the Clinic will not be liable for any Loss and Damage that
                                            results from a customer failing to make payments under a Credit
                                            Agreement that is not due to any fault on its part (i.e. the
                                            customer was merely a bad credit risk) the Clinic will indemnify the
                                            Lender for any Loss and Damage that is a result of the Clinic’s
                                            actions in connection with this Agreement or arises out of the
                                            connection to the Clinic’s goods or services supplied to the
                                            customer or disputed refunds in relation to thereof. Without
                                            limitation this shall include any award of redress that the Lender
                                            makes after a complaint or any award from or settlement of any legal
                                            claim made against the Lender or any liability that the Lender may
                                            have, or economic
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                          <p
                                            style={{
                                              paddingTop: "2pt",
                                              paddingLeft: "45pt",
                                              textIndent: "0pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            loss, damage, loss of good will or reputation or any other business
                                            loss (including legal costs) resulting from, any claim by a customer
                                            under sections 56, 75, 75A or 140A-C of the Consumer Credit Act
                                            1974.
                                          </p>
                                        </li>
                                        <li data-list-text="11.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            In the event of the customer making a successful claim against the
                                            Lender, including under any of sections 56, 75, 75A or 140A-C of the
                                            Consumer Credit Act 1974, or making a successful complaint against
                                            the Lender, the Clinic shall pay the amount required to settle that
                                            claim or complaint to the Lender (or if the Lender request that the
                                            Clinic do so the Clinic shall pay this sum or part of it directly to
                                            the customer). For these purposes a successful claim involves any
                                            form of claim by the customer and need not be a legal claim, and a
                                            successful claim involves the settlement of a claim.
                                          </p>
                                        </li>
                                        <li data-list-text="11.3.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a href="#bookmark1" className="a">
                                              The Clinic shall indemnify the Lender for any Loss and Damage
                                              suffered by the Lender as a result of the Clinic’s breach of
                                              clauses{" "}
                                            </a>
                                            <a href="#bookmark3" className="a">
                                              5 or{" "}
                                            </a>
                                            6 of this Agreement.
                                          </p>
                                        </li>
                                        <li data-list-text="11.4.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a name="bookmark10">
                                              Any indemnity or requirement to indemnify under this Agreement is
                                              provided on a ‘full indemnity basis’. This means that:
                                            </a>
                                          </p>
                                          <ol id="l19">
                                            <li data-list-text="11.4.1.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                The sum claimed shall be owed as a debt and there is no
                                                obligation to mitigate loss;
                                              </p>
                                            </li>
                                            <li data-list-text="11.4.2.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                Loss and Damage incurred shall be recoverable regardless of
                                                whether reasonably foreseeable at the time of the entry into of
                                                this Agreement;
                                              </p>
                                            </li>
                                            <li data-list-text="11.4.3.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                Loss and Damage need not be reasonably incurred to be
                                                recoverable.
                                              </p>
                                            </li>
                                          </ol>
                                        </li>
                                        <li data-list-text="11.5.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a href="#bookmark10" className="a">
                                              Notwithstanding clause{" "}
                                            </a>
                                            11.4 above nothing in this Agreement shall require a party to
                                            indemnity the other for Loss and Damage caused by the receiving
                                            party’s acts deliberately intended to incur that Loss and Damage
                                            (save where this was in an attempt to mitigate other Loss and Damage
                                            or was unavoidable or reasonable in the circumstances), or for its
                                            own fraud or criminal conduct.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={12} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        Lenders decision
                                      </h2>
                                      <ol id="l20" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="12.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            For the purpose of this Agreement the Lender’s decision as to
                                            whether or not to settle a claim or uphold a complaint shall be
                                            final and binding on the Clinic.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={13} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        Guarantee
                                      </h2>
                                      <ol id="l21" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="13.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            Unless the Clinic is a sole trader, or the Lender tells the Clinic
                                            otherwise in writing, the Clinic must ensure that a person
                                            acceptable to the Lender provides the Lender with a personal
                                            guarantee in the form which the Lender shall provide (or update from
                                            time to time ) to the Clinic guaranteeing all of its liabilities
                                            under this Agreement.
                                          </p>
                                        </li>
                                        <li data-list-text="13.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            If the Clinic is a subsidiary or franchise of another company then
                                            unless the Lender tells the Clinic otherwise in writing, the Clinic
                                            must ensure that the other company provides the Lender with a parent
                                            company guarantee in the form which the Lender shall provide (or
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                          <p
                                            style={{
                                              paddingTop: "2pt",
                                              paddingLeft: "45pt",
                                              textIndent: "0pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            update from time to time) to the Clinic guaranteeing all of its
                                            liabilities under this Agreement.
                                          </p>
                                        </li>
                                        <li data-list-text="13.3.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            Any guarantee provided under this clause must cover (without
                                            limitation) any failure to provide a refund, any failure to make
                                            payments as a result of insolvency, and any liability that the
                                            Clinic have to indemnify the Lender for any loss that the Lender
                                            suffers as a result of the goods or services being not in accordance
                                            with the Agreement.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={14} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        Insurance
                                      </h2>
                                      <ol id="l22" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="14.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Clinic must maintain adequate insurance with a reputable insurer
                                            to cover any liability that the Clinic may have to the Lender under
                                            this Agreement, including any liability to indemnify the Lender for
                                            any sums that the Lender have to pay the customer as a result of
                                            defective goods or services, up to a limit of such sum as the Lender
                                            may notify the Clinic of. The Lender may require the Clinic to show
                                            the Lender proof of such insurance on request. The Clinic will
                                            ensure that the Lender’s interest is noted on the insurance policy.
                                            The Lender will waive this requirement if the cost of such a policy
                                            is unacceptable, to be determined by the Lender acting reasonably.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={15} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        <a name="bookmark11">Duration and Termination</a>
                                      </h2>
                                      <ol id="l23" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="15.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            This Agreement shall last for an initial 12 months and be
                                            automatically renewed for a further 12 months at the end of that
                                            initial 12 months and every subsequent 12 month period unless
                                            terminated in accordance with the provisions below.
                                          </p>
                                        </li>
                                        <li data-list-text="15.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Agreement may be terminated by the Clinic by providing 30 days
                                            written notice.
                                          </p>
                                        </li>
                                        <li data-list-text="15.3.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a name="bookmark12">
                                              The Lender is entitled to terminate the Agreement at any time by
                                              providing to the Clinic at least 24 hours notice, or at any time
                                              without notice where:
                                            </a>
                                          </p>
                                          <ol id="l24">
                                            <li data-list-text="15.3.1.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                <a name="bookmark13">
                                                  The Clinic become insolvent or an order is made, or a
                                                  resolution passed for its liquidation, administration,
                                                  winding-up, bankruptcy or dissolution;
                                                </a>
                                              </p>
                                            </li>
                                            <li data-list-text="15.3.2.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                The Clinic is a partnership and any of the partners becomes
                                                insolvent or a resolution is passed for their bankruptcy;
                                              </p>
                                            </li>
                                            <li data-list-text="15.3.3.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                The Clinic or any partner or director of the Clinic is convicted
                                                of any criminal offence (other than a driving offence) or is
                                                alleged or reasonably suspected to have committed any act of
                                                fraud or dishonesty;
                                              </p>
                                            </li>
                                            <li data-list-text="15.3.4.">
                                              <p
                                                style={{
                                                  paddingLeft: "48pt",
                                                  textIndent: "0pt",
                                                  lineHeight: "21pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                <a name="bookmark14">
                                                  There is any material breach by the Clinic of any of the terms
                                                  of this Agreement; 15.3.5.The Lender reasonably believes that
                                                  your continued appointment would prevent
                                                </a>
                                                <a name="bookmark15">‌</a>
                                              </p>
                                              <p
                                                style={{
                                                  paddingLeft: "82pt",
                                                  textIndent: "0pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                the Lender from satisfying and continuing to satisfy the FCA’s
                                                threshold conditions or that it would result in a breach of
                                                Financial Services Legislation;
                                              </p>
                                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                              </p>
                                              <ol id="l25">
                                                <ol id="l26">
                                                  <ol id="l27">
                                                    <li data-list-text="15.3.6.">
                                                      <p
                                                        style={{
                                                          paddingTop: "2pt",
                                                          paddingLeft: "82pt",
                                                          textIndent: "-34pt",
                                                          textAlign: "justify"
                                                        }}
                                                      >
                                                        The Lender reasonably believes that the continuance of
                                                        this Agreement would put the Lender in breach of any
                                                        legal or regulatory requirement to which the Lender is
                                                        subject;
                                                      </p>
                                                    </li>
                                                    <li data-list-text="15.3.7.">
                                                      <p
                                                        style={{
                                                          paddingTop: "6pt",
                                                          paddingLeft: "82pt",
                                                          textIndent: "-34pt",
                                                          textAlign: "justify"
                                                        }}
                                                      >
                                                        <a name="bookmark16">
                                                          The Lender is dissatisfied with the level of business
                                                          provided to the Lender under this Agreement; or
                                                        </a>
                                                      </p>
                                                    </li>
                                                    <li data-list-text="15.3.8.">
                                                      <p
                                                        style={{
                                                          paddingTop: "6pt",
                                                          paddingLeft: "82pt",
                                                          textIndent: "-34pt",
                                                          textAlign: "justify"
                                                        }}
                                                      >
                                                        The Lender must do so in order to act in the best
                                                        interests of customers or the Lender’s business or
                                                        general convenience.
                                                      </p>
                                                    </li>
                                                  </ol>
                                                </ol>
                                              </ol>
                                            </li>
                                          </ol>
                                        </li>
                                        <li data-list-text="15.4.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a href="#bookmark12" className="a">
                                              In the event of termination under clause{" "}
                                            </a>
                                            15.3 the Lender shall notify the Clinic immediately upon the
                                            Agreement being terminated. This need not be in writing, but if the
                                            Lender does not notify the Clinic in writing the Lender shall
                                            confirm that termination in writing as soon as reasonably possible
                                            thereafter.
                                          </p>
                                        </li>
                                        <li data-list-text="15.5.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            In the event of termination of this Agreement, both parties will be
                                            responsible for ensuring that any outstanding activities are
                                            properly completed with all customers.
                                          </p>
                                        </li>
                                        <li data-list-text="15.6.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            In the event of termination, it is agreed that the Clinic will own
                                            all assets and intellectual property and data hosted on its website
                                            (if applicable), and the Lender will own all assets and intellectual
                                            property and data hosted on Payl8r.com / co.uk or derivative
                                            thereof.
                                          </p>
                                        </li>
                                        <li data-list-text="15.7.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            In the event of termination of this Agreement
                                          </p>
                                          <ol id="l28">
                                            <li data-list-text="15.7.1.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                The Clinic shall not be entitled to any refund of any fees or
                                                payments made under this Agreement.
                                              </p>
                                            </li>
                                            <li data-list-text="15.7.2.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                The Lender shall have no liability to the Clinic for any loss of
                                                business, reputation or goodwill.
                                              </p>
                                            </li>
                                            <li data-list-text="15.7.3.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                <a name="bookmark17">
                                                  Unless the Agreement was terminated by the Lender, the Clinic
                                                  shall not use another finance provider offering a similar
                                                  product to the Lender for a period of 60 days after
                                                  termination.
                                                </a>
                                              </p>
                                            </li>
                                          </ol>
                                        </li>
                                        <li data-list-text="15.8.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a name="bookmark18">Termination of this Agreement:</a>
                                          </p>
                                          <ol id="l29">
                                            <li data-list-text="15.8.1.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                Shall not affect Any finance agreement entered into between the
                                                Lender and a customer;
                                              </p>
                                            </li>
                                            <li data-list-text="15.8.2.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                Shall not preclude the Lender from offering finance to a
                                                customer if the customer has already been introduced to the
                                                Lender by Faces; and
                                              </p>
                                            </li>
                                            <li data-list-text="15.8.3.">
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "82pt",
                                                  textIndent: "-34pt",
                                                  textAlign: "left"
                                                }}
                                              >
                                                Shall not preclude the Lender from offering finance or otherwise
                                                marketing to any customer in the future.
                                              </p>
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "45pt",
                                                  textIndent: "-31pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                <a href="#bookmark7" className="a">
                                                  15.9. After termination the provisions in clauses{" "}
                                                </a>
                                                <a href="#bookmark0" className="a">
                                                  7.4,{" "}
                                                </a>
                                                <a href="#bookmark4" className="a">
                                                  4.3,{" "}
                                                </a>
                                                <a href="#bookmark5" className="a">
                                                  6.2,{" "}
                                                </a>
                                                <a href="#bookmark6" className="a">
                                                  7,{" "}
                                                </a>
                                                <a href="#bookmark9" className="a">
                                                  8.4,{" "}
                                                </a>
                                                <a href="#bookmark17" className="a">
                                                  11,{" "}
                                                </a>
                                                <a href="#bookmark18" className="a">
                                                  15.7.3,{" "}
                                                </a>
                                                <a href="#bookmark19" className="a">
                                                  15.8,{" "}
                                                </a>
                                                <a href="#bookmark20" className="a">
                                                  15.10,{" "}
                                                </a>
                                                <a href="#bookmark21" className="a">
                                                  16,{" "}
                                                </a>
                                                <a href="#bookmark23" className="a">
                                                  17 and{" "}
                                                </a>
                                                18.2, and any other clause which is expressed to apply after
                                                termination or on a proper construction was intended to apply
                                                post termination, shall continue to apply.
                                              </p>
                                              <p
                                                style={{
                                                  paddingTop: "6pt",
                                                  paddingLeft: "45pt",
                                                  textIndent: "-31pt",
                                                  textAlign: "justify"
                                                }}
                                              >
                                                <a name="bookmark19">
                                                  15.10.In the event this Agreement is terminated the Clinic
                                                  agrees that the Lender will assume full control of all
                                                  Activities under this Agreement.
                                                </a>
                                              </p>
                                              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                <br />
                                              </p>
                                            </li>
                                          </ol>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={16} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingTop: "2pt",
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        <a name="bookmark20">Restrictive Covenants.</a>
                                      </h2>
                                      <ol id="l30" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="16.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            During the term of this Agreement and for a period of 360 days
                                            following the termination of this Agreement the Clinic, its
                                            Directors, agents, contractors, customers, suppliers, consultants,
                                            partners or employees shall not approach, engage, contract with or
                                            employ any current or historic employees, Directors or consultants
                                            of the Lender.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={17} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        <a name="bookmark21">No damaging of reputation</a>
                                      </h2>
                                      <ol id="l31" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="17.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a name="bookmark22">
                                              During the terms of this Agreement and continuing after
                                              termination the Clinic must ensure that it, and ensure that its
                                              Directors, Partners, employees, agents or other person acting on
                                              its behalf, does not do anything that may reasonably be considered
                                              to damage the reputation of the Lender (save where required by law
                                              or regulation, or where the act constitutes of the bringing of
                                              legal proceedings). Without prejudice to the generality of the
                                              foregoing this includes saying or publishing anything defamatory,
                                              making adverse comments on internet forums, or communicating with
                                              other loan providers or sellers of goods or services in a manner
                                              that is disparaging about the Lender.
                                            </a>
                                          </p>
                                        </li>
                                        <li data-list-text="17.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            The Clinic acknowledges that quantifying the amount of damages for a
                                            breach of clause
                                          </p>
                                          <p
                                            style={{
                                              paddingLeft: "45pt",
                                              textIndent: "0pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a href="#bookmark22" className="a">
                                              17.1 is difficult and may be time consuming and costly. The Clinic
                                              agrees that a genuine and reasonable pre-estimate of the amount of
                                              that damage is the sum of £50,000 and agrees to pay that sum as
                                              liquidated damages for each and every breach of clause{" "}
                                            </a>
                                            17.1.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={18} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "left",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        Assignment
                                      </h2>
                                      <ol id="l32" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="18.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            The Clinic may not assign, transfer or delegate any of its rights or
                                            obligations under this Agreement or the benefit thereof, without the
                                            Lender’s prior written consent.
                                          </p>
                                        </li>
                                        <li data-list-text="18.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            <a name="bookmark23">
                                              The Lender may assign the Lender’s rights and transfer the
                                              Lender’s obligations under this Agreement without the Clinic’s
                                              consent.
                                            </a>
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={19} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        Amendments
                                      </h2>
                                      <ol id="l33" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="19.1.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            <a href="#bookmark11" className="a">
                                              The Lender may vary this Agreement by giving the Clinic 30 days
                                              notice in writing (which may include by email). If the Clinic is
                                              unhappy with the variation the Clinic may terminate this agreement
                                              in accordance with clause{" "}
                                            </a>
                                            15 within 30 days of the Lender giving the Clinic this notice.
                                          </p>
                                        </li>
                                        <li data-list-text="19.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            Otherwise, this Agreement may not be released, discharged,
                                            supplemented, interpreted, amended, varied or modified in any manner
                                            except by an instrument in writing signed by a duly authorised
                                            officer or representative of each Party.
                                          </p>
                                          <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                    <li data-list-text={20} style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                                      <h2
                                        style={{
                                          paddingLeft: "23pt",
                                          textIndent: "-18pt",
                                          textAlign: "left",
                                          fontSize: "1.5rem"
                                        }}
                                      >
                                        General
                                      </h2>
                                      <ol id="l34" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <li data-list-text="20.1.">
                                          <p
                                            style={{
                                              paddingTop: "2pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            The Lender reserves the right to refuse any business if the Lender
                                            is of the opinion that it is not in the Lender’s interest to
                                            proceed.
                                          </p>
                                        </li>
                                        <li data-list-text="20.2.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            No person who is not a party to this Agreement has any right under
                                            the Contracts (Rights of Third Parties) Act 1999 to enforce any term
                                            of this Agreement.
                                          </p>
                                        </li>
                                        <li data-list-text="20.3.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            This Agreement is governed by and shall be construed in accordance
                                            with English Law.
                                          </p>
                                        </li>
                                        <li data-list-text="20.4.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            This Agreement shall be subject to the exclusive jurisdiction of the
                                            Courts of England and Wales.
                                          </p>
                                        </li>
                                        <li data-list-text="20.5.">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "45pt",
                                              textIndent: "-31pt",
                                              textAlign: "justify"
                                            }}
                                          >
                                            If any provision of this Agreement is or becomes prohibited by law
                                            or is judged by a court to be unlawful, void or unenforceable, the
                                            provision shall, to the extent required, be severed from this
                                            Agreement and rendered ineffective as far as possible without
                                            modifying the remaining provisions of this Agreement, and shall not
                                            in any way affect any other circumstances of or the validity or
                                            enforcement of the remainder of this Agreement.
                                          </p>
                                        </li>
                                      </ol>
                                    </li>
                                  </ol>
                                  <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                  </p>
                                  <p
                                    style={{
                                      paddingTop: "10pt",
                                      paddingLeft: "5pt",
                                      textIndent: "0pt",
                                      lineHeight: "42pt",
                                      textAlign: "left"
                                    }}
                                  >
                                    Signed for and on behalf of the Lender Signature
                                  </p>
                                  <p
                                    style={{
                                      paddingTop: "6pt",
                                      paddingLeft: "5pt",
                                      textIndent: "0pt",
                                      lineHeight: "143%",
                                      textAlign: "left"
                                    }}
                                  >
                                    Print name Samantha Palmer Date
                                  </p>
                                  <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                  </p>
                                  <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                                    Signed for and on behalf of the Clinic
                                  </p>
                                  <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                  </p>
                                  <p
                                    style={{
                                      paddingLeft: "5pt",
                                      textIndent: "0pt",
                                      lineHeight: "143%",
                                      textAlign: "left"
                                    }}
                                  >
                                    Signature Print name Date
                                  </p>
                                  <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                  </p>
                                  <h2
                                    style={{
                                      paddingTop: "2pt",
                                      paddingLeft: "4pt",
                                      textIndent: "0pt",
                                      textAlign: "center"
                                    }}
                                  >
                                    Schedule A – Commission, Payments, Deposits and Refunds
                                  </h2>
                                  <h2
                                    style={{
                                      paddingTop: "6pt",
                                      paddingLeft: "4pt",
                                      textIndent: "0pt",
                                      textAlign: "center"
                                    }}
                                  >
                                    A1. Commission
                                  </h2>
                                  <ul id="l35">
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        For each loan to a Customer entered into by the Lender the Lender will
                                        pay to the Clinic the
                                      </p>
                                      <p style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}>
                                        full cost of the goods or services and minus
                                      </p>
                                      <p
                                        className="s1"
                                        style={{ paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}
                                      >
                                        &nbsp;&nbsp; <span className="s2">10 &nbsp;&nbsp;&nbsp; </span>
                                        <span className="p">% as Commission in return</span>
                                      </p>
                                      <p style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}>
                                        for the Lender supplying the customer with credit. Without payment of
                                        this fee no credit will be provided to the Customer.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        The % above shall be calculated by reference to the full cash price of
                                        the goods or services (and for the avoidance of doubt not merely by
                                        reference to the amount of credit).
                                      </p>
                                      <h2
                                        style={{
                                          paddingTop: "6pt",
                                          paddingLeft: "5pt",
                                          textIndent: "0pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        A2. Payment
                                      </h2>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        Where the Credit is for Goods, the Lender will make payments no later
                                        than <span className="s1">&nbsp;&nbsp; </span>
                                        <u>
                                          <b>48 </b>
                                        </u>
                                      </p>
                                      <p style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}>
                                        after the goods have been purchased by the Customer.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        Where the Credit is for Services, the Lender will make payments no later
                                        than <span className="s1">&nbsp; </span>
                                        <u>
                                          <b>48 </b>
                                        </u>
                                        <b> </b>after the Services have been received by the Customer, the
                                        Customer has confirmed receipt of Services by providing the Clinic with
                                        a One Time Pin, and the Clinic has confirmed receipt of the One Time Pin
                                        to the Lender.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        Where the Credit is for mix of both Goods and Services the Lender will
                                        make payments no later than <span className="s1">&nbsp;&nbsp; </span>
                                        <u>
                                          <b>48 </b>
                                        </u>
                                        <b> </b>after the later of (i) the goods being purchased, or (ii) the
                                        Customer has confirmed receipt of Services by providing the Clinic with
                                        a One Time Pin, and the Clinic has confirmed receipt of the One Time Pin
                                        to the Lender. The Lender may, in its discretion, choose to pro rata the
                                        amount of credit between the Goods and Service and provide some of the
                                        credit earlier.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        Where the Credit is for Training Courses, the Lender will make split
                                        payments pro rata the amount of Training courses and will make each
                                        split payment no later than <span className="s1">&nbsp;&nbsp; </span>
                                        <u>
                                          <b>48 hrs </b>
                                        </u>
                                        <b> </b>after each Training Module has been complete and after the
                                        Customer has confirmed receipt of Training module by providing the
                                        Clinic with a One Time Pin and the Clinic has confirmed receipt of the
                                        One time Pin to the Lender.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        If any payment under this Schedule should fall to be made on a non
                                        working day, the payment shall be made during the next working day
                                        thereafter.
                                      </p>
                                      <h2
                                        style={{
                                          paddingTop: "6pt",
                                          paddingLeft: "5pt",
                                          textIndent: "0pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        A3. Deposits
                                      </h2>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        The amount of the deposit required for each loan is at the full
                                        discretion of the Lender.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        The Lender shall take payment of the deposit from the customer.
                                      </p>
                                      <h2
                                        style={{
                                          paddingTop: "6pt",
                                          paddingLeft: "5pt",
                                          textIndent: "0pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        A4. Refunds
                                      </h2>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        When the customer is due a refund the Clinic will pay to the Lender the
                                        full cost for the goods or service to the Lender on the day that the
                                        refund is due (including any deposit and the amount of any transaction
                                        fees/retailer subsidiary).
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        The Lender may at its discretion cross ledger what it owes the Clinic on
                                        that day or time to make the refund process efficient.
                                      </p>
                                      <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "1pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        Commission is non-refundable.
                                      </p>
                                      <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                      </p>
                                      <h2
                                        style={{
                                          paddingTop: "2pt",
                                          paddingLeft: "4pt",
                                          textIndent: "0pt",
                                          textAlign: "center"
                                        }}
                                      >
                                        Schedule B – Advertising Requirements and Restrictions
                                      </h2>
                                      <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                      </p>
                                      <p
                                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                                      >
                                        The Clinic may only introduce customers to the Lender in accordance with
                                        these provisions.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        The Clinic may only refer customers to Faces and not to the Lender
                                        directly.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        The Clinic may not make any introduction or referral to the Lender
                                        directly.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        The Clinic must not tell its customers about the possibility of
                                        obtaining goods and services on credit via the Lender. Only Faces may
                                        refer to the possibility of obtaining credit from the Lender.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        If the Clinic tells a customer about the possibility of obtaining goods
                                        and services on credit via a different lender, the Clinic may no longer
                                        refer that customer to Faces.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        The Clinic may not reference the Lender or otherwise identify the Lender
                                        to its customers either generally or in respect of the provision of
                                        finance for any particulars goods and services, unless the customer has
                                        already selected (via Faces) to pay for those goods or services on
                                        credit.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        The Clinic must not use the Lender’s logo.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        The Clinic must not refer to the Lender or the possibility of obtaining
                                        goods or services on credit in any written promotion or marketing
                                        literature or written communication, unless the Lender has expressly
                                        approved that promotion literature or communication in writing or unless
                                        that advertising is on Faces.
                                      </p>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "justify"
                                        }}
                                      >
                                        The Clinic must not take any fee from a customer or a third party in
                                        return for:
                                      </p>
                                      <ul id="l36">
                                        <li data-list-text="o">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "77pt",
                                              textIndent: "-18pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            Any introduction of the customer to Faces or the Lender.
                                          </p>
                                        </li>
                                        <li data-list-text="o">
                                          <p
                                            style={{
                                              paddingTop: "5pt",
                                              paddingLeft: "77pt",
                                              textIndent: "-18pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            Any introduction of the customer by Faces to the Lender.
                                          </p>
                                        </li>
                                        <li data-list-text="o">
                                          <p
                                            style={{
                                              paddingTop: "5pt",
                                              paddingLeft: "77pt",
                                              textIndent: "-18pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            Helping to complete any application for finance.
                                          </p>
                                        </li>
                                        <li data-list-text="o">
                                          <p
                                            style={{
                                              paddingTop: "6pt",
                                              paddingLeft: "77pt",
                                              textIndent: "-18pt",
                                              textAlign: "left"
                                            }}
                                          >
                                            Presenting any finance agreement to a customer.
                                          </p>
                                        </li>
                                      </ul>
                                    </li>
                                    <li data-list-text="">
                                      <p
                                        style={{
                                          paddingTop: "4pt",
                                          paddingLeft: "41pt",
                                          textIndent: "-18pt",
                                          textAlign: "left"
                                        }}
                                      >
                                        The Clinic must not offer a customer any advice as to whether or not to
                                        pay for goods or services on credit, or on the terms of any credit
                                        agreement.
                                      </p>
                                    </li>
                                  </ul>
                                </>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         

            <div className="tagInput">
              <p>Electronic signature</p>
            </div>


            <div className="finance-input-container">
              <div className="EraseWrapper">
                <p className="form-label">
                  You have signed the terms and conditions.
                </p>
                {!disabled && (trimmedDataURL || getSigntureData) &&(
                      <>
                        <div
                          className="cursor-pointer erase-img"
                          onClick={() => clear()}
                        >
                          <img
                            src={EraseImg}
                            alt="EraseImg"
                            width={48}
                            height={48}
                            
                          />
                        </div>
                      </>
                  )
                }
                  {/* : */}
                  {/* (
                    getSigntureData && (
                      <>
                        <div
                          className="cursor-pointer erase-img"
                          onClick={() => clear()}
                        >
                          <img
                            src={EraseImg}
                            alt="EraseImg"
                            width={48}
                            height={48}
                            
                          />
                        </div>
                      </>
                    )
                  ) */}
                  
                  
              </div>
            

              <div className="card-section">
                <div
                  className="d-flex flex-column paylater-wrapper-canvas justify-content-center"
                  style={{
                    borderRadius: "20px",
                    border: "1px dashed #878787",
                    height: "199px",
                    color: "#878787",
                  }}
                >
                  {
                    trimmedDataURL || !getSigntureData ?
                    (<SignatureCanvas
                      clearOnResize={false}
                      ref={sigPadRef}
                      canvasProps={{
                        className: "paylater-signCanvas",
                      }}
                      penColor="black"
                      throttle={null}
                      onEnd={() => {
                        setSignatureError("");
                         setTrimmedDataURL(
                          sigPadRef?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png")
                        );
                      }}
                    />):
                    (
                     getSigntureData && (
                     <div className="apiSigntureData">
                        <img 
                          src={getSigntureData}
                          alt="signture_url"
                          className="signtureImg"
                        />
                      </div>
                     )
                  )
                  }
                </div>
              </div>
              {signatureError && <p className="error">{signatureError}</p>}
            
            </div>


            <div className="third-flex-div">
                  <StepButton 
                  label={" Save"} 
                  blue={true}
                  isLoading={saveFinanceSigntureDetailQueryData.loading} 
                  onClick={() => handleContinue()} />
              </div>
          </div>
        {/* <BackArrow  onBackClick={()=>navigate(FinanceGetPaidRoute())}/> */}
      </div>
      {/* <div>
        <div className="container">
          <div className="row flex-column align-items-center justify-content-center">
            <div className="col-lg-7 mb-5">
              <h1>Terms & conditions</h1>
              <p>
                Please agree to all the terms and conditions from Payl8r and
                Faces Finance
              </p>
            </div>
            <div className="col-lg-7">
              <p className="doc_label">Terms & conditions</p>
              <select
                className="form-select"
                aria-label="Large select example"
                name="selTerm1"
              >
                <option
                  className="d-none shadow-none"
                  disabled
                  selected
                  value=""
                >
                  Terms & conditions
                </option>
                {selectArray.map((item) => {
                  return <option value={item.value}> {item?.label}</option>;
                })}
              </select>
            </div>
            <div className="col-lg-7 mt-5">
              <p className="border-bottom pb-2">Electronic Signature</p>
            </div>
            <div className="col-lg-7 mt-4 mb-5">
              <p className="doc_label">
                You have signed the terms and conditions.
              </p>

              <div className="card-section">
                <div
                  className="d-flex flex-column paylater-wrapper-canvas"
                  style={{
                    borderRadius: "20px",
                    border: "1px dashed #878787",
                    height: "199px",
                    color: "#878787",
                  }}
                >
                  {
                    <SignatureCanvas
                      clearOnResize={false}
                      ref={sigPadRef}
                      canvasProps={{
                        className: "paylater-signCanvas",
                      }}
                      penColor="black"
                      throttle={null}
                      onEnd={() => {
                        setSignatureError("");
                        setTrimmedDataURL(
                          sigPadRef?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png")
                        );
                      }}
                    />
                  }
                </div>
                <div className="justify-content-between d-flex w-100 mt-2">
                  {trimmedDataURL && (
                    <>
                      <div
                        className="cursor-pointer mx-2 erase-img"
                        onClick={() => clear()}
                      >
                        <img
                          src={EraseImg}
                          alt="EraseImg"
                          width={48}
                          height={48}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {signatureError && <p className="text-danger mb-0 mt-2">{signatureError}</p>}
            
            </div>

            <div className="col-lg-7 mb-5">
              <button
                className="w-100 btn btn-primary savebtn shadow-none"
                style={{ borderRadius: "100px", backgroundColor: "#1B4FE7" }}
                onClick={() => handleContinue()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
