import React, { useEffect, useState } from "react";
import {ReactComponent as FacesBusinessLogo} from "../../images/facesBusiness.svg";
import './facesBusinessHeader.scss';
import { webBaseUrl } from "../../config";
const FacesBusinessHeader = () => {
     // Function to add class when scrolling up
// function handleScrollUp() {
//     var header = document.querySelector('.FacesBusinessHeaderMain');
//     header.classList.remove('scrolled-down');
//     header.classList.add('scrolled-up');
//   }
  
//   // Function to add class when scrolling down
//   function handleScrollDown() {
//     var header = document.querySelector('.FacesBusinessHeaderMain');
//     header.classList.remove('scrolled-up');
//     header.classList.add('scrolled-down');
//   }
  
//   window.addEventListener('wheel', function(event) {
//     if (event.deltaY < 0) {
//       handleScrollUp(); // Mouse wheel scrolled up
//     } else if (event.deltaY > 0) {
//       handleScrollDown(); // Mouse wheel scrolled down
//     }
//   });

let currentScroll;
const targetHeight = 100;
const [isSwitch, setIsSwitch] = useState(false);
window.addEventListener("scroll", function () {
  currentScroll = window.scrollY || window.pageYOffset;

  if (currentScroll >= targetHeight) {
    setIsSwitch(true);
  } else {
    setIsSwitch(false);
  }
});
  

    return(
       <div className={isSwitch ? "HeaderFixed FacesBusinessHeaderMain" : "FacesBusinessHeaderMain"}>
            <div className="container">
                <div className="HeaderMainWrapper">
                        <div className="HeaderLogo">
                            <FacesBusinessLogo />
                        </div>
                        <div className="HeaderUrl">
                            <ul>
                                <li onClick={() => { window.location.href = webBaseUrl + '/clinics' }}>Book a treatment</li>
                                <li onClick={() => { window.location.href = webBaseUrl + '/shop' }}>Purchase product</li>
                            </ul>
                        </div>
                </div>
            </div>
       </div>
    )
}

export default FacesBusinessHeader;
