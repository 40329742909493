import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const REGISTER_WITH_FINANCE_QUERY = gql`
mutation RegisterWithFinance($email: String!, $password: String!, $fname: String, $lname: String, $businessName: String, $phoneNumber: String, $phoneCountryCode: String) {
    registerWithFinance(email: $email, password: $password, fname: $fname, lname: $lname, businessName: $businessName, phoneNumber: $phoneNumber, phoneCountryCode: $phoneCountryCode) {
      success
      message
      data {
        user {
          usr_id
          usr_fname
          usr_lname
          usr_name
          usr_email
          usr_email_verified
          usr_country_name
          usr_country_code
          usr_phone_no
          usr_phone_country
          usr_phone_verified
          usr_dob
          usr_last_login
          usr_last_login_type
          usr_country
          usr_company_name
          usr_no_of_employees
          usr_vat_no
          userRoles
          image_url
        }
        email_exists
        token
        email_verified
        affiliation
      }
    }
  }
`;

export const RegisterWithFinance = () => {
    // const dispatch = useDispatch()
    const [registerWithFinanceFunc, registerWithFinance] = useMutation(REGISTER_WITH_FINANCE_QUERY);
    const registerWithFinanceData = registerWithFinance;

    const initiateRegisterWithFinance = (
        data,
        successCallback,
        errorCallback
    ) => {
        registerWithFinanceFunc({
            variables: data,
        })
            .then(({ data: { registerWithFinance: responseData } }) => {
                console.log("dataaaaaaaaaa", data)
                console.log("responseData>>>>>>>", responseData)
                if (responseData?.success) {
                    toast.success(responseData.message)
                    successCallback(responseData);
                } else {
                    console.log("elseeeeeeeee")
                    toast.error(responseData.message)
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                console.log("error",error)
                errorCallback(error);
            });
    };

    return { initiateRegisterWithFinance, registerWithFinanceData }
}