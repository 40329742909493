import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

const SAVE_TURN_OFF_TOOLTIP_MUTATION = gql`
mutation TurnOffTooltip($toolTipType: Int) {
  turnOffTooltip(toolTipType: $toolTipType) {
    success
    message
  }
}
`;


export const SaveTurnOffTooltipAction = () => {
    const [
        SaveTurnOffTooltipResponseFunction,
        saveTurnOffTooltipResponseData,
    ] = useMutation(SAVE_TURN_OFF_TOOLTIP_MUTATION)

    //this will be called from components
    const saveTurnOffTooltipQueryData = saveTurnOffTooltipResponseData; //variable

    const initSaveTurnOffTooltipData = (data, successCallback, errorCallback) => {
        SaveTurnOffTooltipResponseFunction({
            variables: data,
        }).then(({ data: { turnOffTooltip: responseData } }) => {
            if (responseData) {
                if (successCallback)
                    successCallback(responseData?.success)
            }
            else {
                if (errorCallback)
                    errorCallback()
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
        })
    }

    return { saveTurnOffTooltipQueryData, initSaveTurnOffTooltipData };
};