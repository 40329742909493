import React, { useEffect, useState } from "react";
import "./nurse-insurance-pricing-Three-landing.scss";

// image
import bannerimg from "../../../../src/assets/nurse-insurance-pricing-three/banner-img.png";
import BannerCircleAssets from "../../../../src/assets/nurse-insurance-pricing-three/bannerAssets.png";
import leftAssets from "../../../../src/assets/nurse-insurance-pricing-three/bannerMainAssets.png";
import newsone from "../../../../src/assets/nurse-insurance-pricing-three/newsOne.png";
import newsTwo from "../../../../src/assets/nurse-insurance-pricing-three/newsTwo.png";
import newsThree from "../../../../src/assets/nurse-insurance-pricing-three/newsThree.png";

import appoAssetsImg from "../../../../src/assets/nurse-insurance-pricing-three/appAssets.svg";
import FaqRightAssets from "../../../../src/assets/nurse-insurance-pricing-three/faqAssets.svg";
import Profile from "../../../../src/assets/nurse-insurance-pricing-three/clientProfile.png";

import nurse from "../../../../src/assets/nurse-insurance-pricing-three/nurse.png";

import BusinessHeader from "../../businessHeader";
import BusinessFooter from "../../businessFooter";
import Aos from "aos";
import "aos/dist/aos.css";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import getInsuranceData from "../../redux/actions/insurance/insurance";
import { useNavigate } from "react-router";
import { insuranceLandingPagePattern } from "../../../Routes";
import Cookies from "js-cookie";
import { isLiveSystem, notificationMode, webBaseUrl } from "../../../config";
import { Helmet } from 'react-helmet';

function NurseInsurancePricingThreeLanding() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const insuranceData = useSelector(
    (state) => state.GetInsuranceDataReducer.insuranceData
  );
  const [insuranceLandingLink, setInsuranceLandingLink] = useState("");
  const [renderIframe, setRenderIframe] = useState(false);
  const [renderMessage, setRenderMessage] = useState(false);

  const handleInsuranceLanding = () => {
    // dispatch(getInsuranceData());
    navigate(insuranceLandingPagePattern())
  };


  const [isAuthHeader, setIsAuthHeader] = useState(false);
  useEffect(() => {
    Cookies.get("faces_access_token")
      ? setIsAuthHeader(true)
      : setIsAuthHeader(false);
  }, []);

  useEffect(() => {
    if (insuranceData) {
      if (insuranceData && Object.keys(insuranceData.element).length !== 0) {
        setInsuranceLandingLink(insuranceData.element.insuranceLandingLink);
        setRenderIframe(true);
      } else {
        setRenderMessage(true);
      }
    }

    console.log(insuranceData, "insuranceData=>");
  }, [insuranceData]);

  useEffect(() => {
    Aos.init({
      duration: 800,
      easing: "ease-in-out",
      offset: 200,
      once: true, // Set once to true to trigger animations only once
    });
  }, []);

  useEffect(() => {
    return () => {

      let clarityScript = document.getElementById('clarity-script');

      if(clarityScript) {
        clarityScript.remove();
      }
    }
  }, []);

  return (
    <>
    {
      isLiveSystem ? 
      <Helmet>
        <script type="text/javascript">
            {
              `(function(c,l,a,r,i,t,y){ 
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; 
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                t.id = 'clarity-script';
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); 
              })(window, document, "clarity", "script", "mcfuhefszs");`
            }
        </script>
      </Helmet>
       : null
      }
      <div className="main-nurse-insurance-pricing-Three-landing">
        {insuranceData ? (
          <iframe
            className="editprofilestyle"
            src={insuranceLandingLink}
            style={{ height: "100vh", width: "100%" }}
          ></iframe>
        ) : (
          <>
            <BusinessHeader />

            {/* banner */}
            <section className="bannerNurse">
              <div className="leftAssets">
                <img src={leftAssets} alt="" />
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className="bannerNurseContent">
                      <div className="bannerCircleAssets">
                        <img src={BannerCircleAssets} />
                      </div>
                      <span
                        className="Tagline"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        Protecting Your Practice
                      </span>
                      <h1 data-aos="fade-up" data-aos-duration="1500">
                        Medical Malpractice Insurance
                      </h1>
                      <p data-aos="fade-up" data-aos-duration="2000">
                        Make a mistake or hurt a client unintentionally and it
                        could hurt your business too. Practice some self-care by
                        protecting yourself with medical malpractice insurance.
                      </p>
                      <button
                        data-aos="fade-up"
                        data-aos-duration="2500"
                        onClick={() => {
                          // isAuthHeader  ?  handleInsuranceLanding() : window.location.href = webBaseUrl + '/register'
                          handleInsuranceLanding() 
                        }}
                      >
                        Get Aesthetic Insurance
                        <span className="btn-Icon">
                          <IoIosArrowForward />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div
                      className="bannerNurseImageWrapper"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      <img src={bannerimg} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="pricingSec same-Space" id="pricingSec">
              <div className="container">
                <div className="pricingSecAssets">
                  <img src={appoAssetsImg} alt="" />
                </div>
                <div className="pricingBox">
                  <div
                    className="pricingHeaderBox"
                    data-aos="fade-up"
                    data-aos-anchor="#pricingSec"
                    data-aos-offset="500"
                    data-aos-duration="500"
                  >
                    <h5>Medically Qualified</h5>
                    <p>
                      If you're a surgeon, doctor, registered nurse, pharmacist,
                      paramedic, dentist, dental therapist, or dental hygienist,
                      this cover is for you.
                    </p>
                  </div>

                  <div
                    className="pricingCard"
                    data-aos="fade-up"
                    data-aos-anchor="#pricingSec"
                    data-aos-offset="650"
                    data-aos-duration="650"
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div className="pricingRupesWrapper">
                          <div className="pricing-box-title">
                            <span className="smallBlackTitle">
                              Medical Malpractice <br /> Insurance
                            </span>
                          </div>
                          <div className="pricingcontentWrapper">
                            <div className="pricingRupesBox">
                              <span>From</span>
                              <h2>£32.21</h2>
                              <p>
                                a month for <br /> £250,000 cover
                              </p>
                            </div>
                            <div className="pricingContentBox">
                              <h6 className="smallTitle">Malpractice</h6>
                              <p>
                                for bodily injury, illness, or death of clients
                                caused by your negligence
                              </p>
                              <h6 className="smallTitle">Malpractice</h6>
                              <p>
                                for bodily injury, illness, or death of clients
                                caused by your negligence
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <div className="pricingRupesWrapper">
                          <div className="pricing-box-title">
                            <span className="smallBlackTitle">
                              Public liability
                            </span>
                          </div>
                          <div className="pricingcontentWrapper">
                            <div className="pricingRupesBox">
                              <h2>£4.05</h2>
                              <p>
                                a month for <br /> £250,000 cover
                              </p>
                            </div>
                            <div className="pricingContentBox">
                              <h6 className="smallTitle">
                                <b>£1,000,000</b>
                              </h6>
                              <p>
                                for physical damage and injuries caused by your
                                business
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="bottom-Desc">
                      Based on an annual income of up to £50,000. All prices
                      include IPT at 12%.Quote online for turnovers up to
                      £250,000, or call and talk to an expert.
                    </p>
                  </div>
                </div>
                <div
                  className="pricingButton"
                  onClick={() => {
                    // isAuthHeader  ?  handleInsuranceLanding() : window.location.href = webBaseUrl + '/register'
                    handleInsuranceLanding() 
                  }}
                >
                  <button>
                    Get Insurance
                    <span className="btn-Icon">
                      <IoIosArrowForward />
                    </span>
                  </button>
                </div>
              </div>
            </section>

            {/* latestNews */}
            <section className="latestNews same-space" id="latestNews">
              <div className="container">
                <div className="secTitleWrapper">
                  <span
                    className="Tagline text-center mb-2"
                    data-aos="fade-up"
                    data-aos-anchor="#latestNews"
                    data-aos-offset="450"
                    data-aos-duration="450"
                  >
                    INSURANCE INSIGHTS
                  </span>
                  <h2
                    className="secTitle text-center mb-4"
                    data-aos="fade-up"
                    data-aos-anchor="#latestNews"
                    data-aos-offset="500"
                    data-aos-duration="500"
                  >
                    Latest News
                  </h2>
                  <p
                    className="secDesc text-center mb-5"
                    style={{ color: "#737373" }}
                    data-aos="fade-up"
                    data-aos-anchor="#latestNews"
                    data-aos-offset="550"
                    data-aos-duration="550"
                  >
                    Your In-Depth Resource Hub for Health Security. Explore
                    Expert Advice, Actionable Tips, and Comprehensive <br />{" "}
                    Guides to Strengthen Your Health Insurance Understanding.
                  </p>
                </div>

                <div className="row">
                  <div
                    className="col-sm-12 col-md-6 col-lg-4 mb-4"
                    data-aos="fade-up"
                    data-aos-anchor="#latestNews"
                    data-aos-offset="600"
                    data-aos-duration="600"
                  >
                    <div className="latestNewsBox">
                      <div className="latestNewsImg">
                        <a
                          href="https://facesconsent.com/blog/insurance-for-aesthetic-nurse-practitioners/"
                          target="_blank"
                        >
                          <img src={newsone} alt="" />
                        </a>
                      </div>
                      <div className="latestNewsContent">
                        <div className="latestcontentWrapper">
                          <h5>
                            <a
                              href="https://facesconsent.com/blog/insurance-for-aesthetic-nurse-practitioners/"
                              target="_blank"
                            >
                              Essential Insurance Guide for Aesthetic Nurse
                              Practitioners | Faces
                            </a>
                          </h5>
                          <p>
                            Professionals with a Professional Identification
                            Number (PIN) face unique challenges in today’s
                            fast-evolving aesthetics industry.
                          </p>
                        </div>
                        <div className="nameWrapper">
                          <div className="profileImg">
                            <img src={Profile} className="img-fluid" alt="" />
                          </div>
                          <div className="profileContent">
                            <span className="name">Ben O'brien </span>
                            <span className="desc">DEC 13, 2023 </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-6 col-lg-4 mb-4"
                    data-aos="fade-up"
                    data-aos-anchor="#latestNews"
                    data-aos-offset="650"
                    data-aos-duration="650"
                  >
                    <div className="latestNewsBox">
                      <div className="latestNewsImg">
                        <a
                          href="https://facesconsent.com/blog/how-to-choose-the-right-insurance-for-your-aesthetic-and-beauty-practice/"
                          target="_blank"
                        >
                          <img src={newsTwo} alt="" />
                        </a>
                      </div>
                      <div className="latestNewsContent">
                        <div className="latestcontentWrapper">
                          <h5>
                            <a
                              href="https://facesconsent.com/blog/how-to-choose-the-right-insurance-for-your-aesthetic-and-beauty-practice/"
                              target="_blank"
                            >
                              How to Find the Right Insurance for Aesthetic
                              Businesses
                            </a>
                          </h5>
                          <p>
                            Insurance is a fundamental safeguard for your
                            aesthetic and beauty practice. It ensures that
                            you’re protected from unforeseen events and legal
                            issues{" "}
                          </p>
                        </div>
                        <div className="nameWrapper">
                          <div className="profileImg">
                            <img src={Profile} className="img-fluid" alt="" />
                          </div>
                          <div className="profileContent">
                            <span className="name">Ben O'brien</span>
                            <span className="desc">OCT 17, 2023 </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-6 col-lg-4 mb-4"
                    data-aos="fade-up"
                    data-aos-anchor="#latestNews"
                    data-aos-offset="700"
                    data-aos-duration="700"
                  >
                    <div className="latestNewsBox">
                      <div className="latestNewsImg">
                        <a
                          href="https://facesconsent.com/blog/aesthetic-medical-professionals-insurance/"
                          target="_blank"
                        >
                          <img src={newsThree} alt="" />
                        </a>
                      </div>
                      <div className="latestNewsContent">
                        <div className="latestcontentWrapper">
                          <h5>
                            <a
                              href="https://facesconsent.com/blog/aesthetic-medical-professionals-insurance/"
                              target="_blank"
                            >
                              8 Reasons Aesthetic Medical Professionals Need
                              Insurance Today
                            </a>
                          </h5>
                          <p>
                            In 2022, 44% of UK businesses lacked insurance, a
                            risky move, especially for aesthetic medical
                            professionals{" "}
                          </p>
                        </div>
                        <div className="nameWrapper">
                          <div className="profileImg">
                            <img src={Profile} className="img-fluid" alt="" />
                          </div>
                          <div className="profileContent">
                            <span className="name">Ben O'brien </span>
                            <span className="desc">
                              DEC 13, 2023 • 5min read{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Faq */}
            {/* <section className="faqSec same-space" id="faqSec">
              <div className="container position-relative">
                <div className="faqSecAssets">
                  <img src={appoAssetsImg} alt="" />
                </div>
                <div className="faqSecAssetsRight">
                  <img src={FaqRightAssets} alt="" />
                </div>

                <div className="secTitleWrapper">
                  <h2
                    className="secTitle text-center mb-4"
                    data-aos="fade-up"
                    data-aos-anchor="#faqSec"
                    data-aos-offset="450"
                    data-aos-duration="450"
                  >
                    Frequently Asked Questions <br /> for Nurse Practitioners
                  </h2>
                  <p
                    className="secDesc text-center mb-5"
                    style={{ color: "#737373" }}
                    data-aos="fade-up"
                    data-aos-anchor="#faqSec"
                    data-aos-offset="750"
                    data-aos-duration="750"
                  >
                    Can’t able to find answers you’re looking for?
                    <br />
                    Reach out to our
                    <span style={{ color: "#3445D9", padding: "0px 6px" }}>
                      <strong style={{ fontWeight: "500" }}>
                        customer support
                      </strong>
                    </span>
                    team.
                  </p>
                </div>
                <div class="accordion" id="accordionExample">
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-anchor="#faqSec"
                        data-aos-offset="800"
                        data-aos-duration="800"
                      >
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button collpased"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            Why is insurance essential for nurse practitioners?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s.
                          </div>
                        </div>
                      </div>

                      <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-anchor="#faqSec"
                        data-aos-offset="850"
                        data-aos-duration="850"
                      >
                        <h2 class="accordion-header" id="headingSecond">
                          <button
                            class="accordion-button collpased"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSecond"
                            aria-expanded="false"
                            aria-controls="collapseSecond"
                          >
                            How can this platform assist me in finding the right
                            insurance?
                          </button>
                        </h2>
                        <div
                          id="collapseSecond"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingSecond"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s.
                          </div>
                        </div>
                      </div>

                      <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-anchor="#faqSec"
                        data-aos-offset="900"
                        data-aos-duration="900"
                      >
                        <h2 class="accordion-header" id="headingthree">
                          <button
                            class="accordion-button collpased"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsethree"
                            aria-expanded="false"
                            aria-controls="collapsethree"
                          >
                            Is the quote comparison tool free to use?
                          </button>
                        </h2>
                        <div
                          id="collapsethree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingthree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s.
                          </div>
                        </div>
                      </div>

                      <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-anchor="#faqSec"
                        data-aos-offset="900"
                        data-aos-duration="900"
                      >
                        <h2 class="accordion-header" id="headingfour">
                          <button
                            class="accordion-button collpased"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsefour"
                            aria-expanded="false"
                            aria-controls="collapsefour"
                          >
                            What types of insurance should a nurse practitioner
                            consider?
                          </button>
                        </h2>
                        <div
                          id="collapsefour"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingfour"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s.
                          </div>
                        </div>
                      </div>

                      <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-anchor="#faqSec"
                        data-aos-offset="1000"
                        data-aos-duration="1000"
                      >
                        <h2 class="accordion-header" id="headingfive">
                          <button
                            class="accordion-button collpased"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsefive"
                            aria-expanded="false"
                            aria-controls="collapsefive"
                          >
                            How soon can I get insured?
                          </button>
                        </h2>
                        <div
                          id="collapsefive"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingfive"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-anchor="#faqSec"
                        data-aos-offset="800"
                        data-aos-duration="800"
                      >
                        <h2 class="accordion-header" id="headingsix">
                          <button
                            class="accordion-button collpased"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsesix"
                            aria-expanded="false"
                            aria-controls="collapsesix"
                          >
                            Why is insurance essential for nurse practitioners?
                          </button>
                        </h2>
                        <div
                          id="collapsesix"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingsix"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s.
                          </div>
                        </div>
                      </div>

                      <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-anchor="#faqSec"
                        data-aos-offset="850"
                        data-aos-duration="850"
                      >
                        <h2 class="accordion-header" id="headingseven">
                          <button
                            class="accordion-button collpased"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseseven"
                            aria-expanded="false"
                            aria-controls="collapseseven"
                          >
                            How can this platform assist me in finding the right
                            insurance?
                          </button>
                        </h2>
                        <div
                          id="collapseseven"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingseven"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s.
                          </div>
                        </div>
                      </div>

                      <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-anchor="#faqSec"
                        data-aos-offset="900"
                        data-aos-duration="900"
                      >
                        <h2 class="accordion-header" id="headingeight">
                          <button
                            class="accordion-button collpased"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseeight"
                            aria-expanded="false"
                            aria-controls="collapseeight"
                          >
                            Is the quote comparison tool free to use?
                          </button>
                        </h2>
                        <div
                          id="collapseeight"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingeight"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s.
                          </div>
                        </div>
                      </div>

                      <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-anchor="#faqSec"
                        data-aos-offset="950"
                        data-aos-duration="950"
                      >
                        <h2 class="accordion-header" id="headingnine">
                          <button
                            class="accordion-button collpased"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsenine"
                            aria-expanded="false"
                            aria-controls="collapsenine"
                          >
                            What types of insurance should a nurse practitioner
                            consider?
                          </button>
                        </h2>
                        <div
                          id="collapsenine"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingnine"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s.
                          </div>
                        </div>
                      </div>

                      <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-anchor="#faqSec"
                        data-aos-offset="1000"
                        data-aos-duration="1000"
                      >
                        <h2 class="accordion-header" id="headingten">
                          <button
                            class="accordion-button collpased"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseten"
                            aria-expanded="false"
                            aria-controls="collapseten"
                          >
                            How soon can I get insured?
                          </button>
                        </h2>
                        <div
                          id="collapseten"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingten"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            {/* getQuate */}
            <section className="getQuateSec same-space" id="getQuateSec">
              <div className="container">
                <div
                  className="getQuateBox"
                  data-aos="fade-up"
                  data-aos-anchor="#getQuateSec"
                  data-aos-offset="400"
                  data-aos-duration="400"
                >
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <div
                        className="getQuateBoxContent"
                        data-aos="fade-right"
                        data-aos-anchor="#getQuateSec"
                        data-aos-offset="500"
                        data-aos-duration="500"
                      >
                        <h5>
                          {/* Get a Nurse Practitioner <br /> Insurance Quote Today */}
                          Get An Aesthetics <br/> Insurance Quote Today
                        </h5>
                        <p>
                        Below is a list of our partner insurance providers, ready to offer you tailored and competitive Insurance quotes.
                        </p>
                        <button
                          onClick={() => {
                            // window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/beauty-aesthetic-insurance-companies`;
                            navigate(insuranceLandingPagePattern())
                          }}
                        >
                          Get A Quote Now
                          <span className="btn-Icon">
                            <IoIosArrowForward />
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div
                        className="getQuateBoxImage"
                        data-aos="fade-up"
                        data-aos-anchor="#getQuateSec"
                        data-aos-offset="600"
                        data-aos-duration="600"
                      >
                        <img src={nurse} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <BusinessFooter />
          </>
        )}
      </div>
    </>
  );
}

export default NurseInsurancePricingThreeLanding;
