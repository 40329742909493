import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import showToast from "../../../common/CustomToast/CustomToast";
import { useDispatch } from "react-redux";

const SAVE_ONE_TIME_OFF_MUTATION = gql`
mutation SaveOneOffTime($clinicId: Int, $isOpen: Boolean, $date: String, $startTime: String, $endTime: String, $breaks: [saveOneOffTimeBreaks], $requestFrom: String) {
  saveOneOffTime(clinicId: $clinicId, isOpen: $isOpen, date: $date, startTime: $startTime, endTime: $endTime, breaks: $breaks, requestFrom: $requestFrom) {
    success
    message
  }
}
`;


export const SaveOneOffTimeAction = () => {
    // const dispatch = useDispatch();
    const [
        SaveOneOffTimeResponseFunction,
        saveOneOffTimeResponseData,
    ] = useMutation(SAVE_ONE_TIME_OFF_MUTATION)

    //this will be called from components
    const saveOneOffTimeQueryData = saveOneOffTimeResponseData; //variable

    const initSaveOneOffTimeData = (data, successCallback, errorCallback) => {
        SaveOneOffTimeResponseFunction({
            variables: data,
        }).then(({ data: { saveOneOffTime: responseData } }) => {
            console.log("api called", responseData)
            if (responseData.success) {
                if (successCallback)
                    successCallback()
                showToast("success", responseData.message, "", "")
            }
            else {
                if (errorCallback)
                    errorCallback()
                showToast("error", responseData.message, "", "")
            }
        }).catch((err) => {
            console.log(err, "errorCallback")
            if (errorCallback)
                errorCallback()
            showToast("error", "something went wrong!!!", "", "")
        })
    }

    return { saveOneOffTimeQueryData, initSaveOneOffTimeData };
};



const GET_ONE_TIME_OFF_QUERY = gql`
query GetOneOffTime($clinicId: Int, $date: String) {
    getOneOffTime(clinicId: $clinicId, date: $date) {
      success
      message
      data {
        isOpen
        startTime
        endTime
        breaks {
          breakStartTime
          breakEndTime
          breakId
        }
        isBlockOut
      }
    }
  }
`;

export const GetOneTimeOffAction = () => {
    const dispatch = useDispatch();
    const [
        GetOneTimeOffResponseFunction,
        getOneTimeOffResponseData,
    ] = useLazyQuery(GET_ONE_TIME_OFF_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getOneOffTime: responseData }) => {
            if (responseData.success) {
                // dispatch({
                //     type: GET_RATING_REVIEWS_CONST,
                //     payload: responseData
                // })
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getOneTimeOffQueryData = getOneTimeOffResponseData; //variable

    const initGetOneTimeOffData = (data, successCallback) => {
        GetOneTimeOffResponseFunction({
            variables: data,
        }).then((res) => {
            if (res?.data?.getOneOffTime?.success)
                successCallback(res.data.getOneOffTime)
        });
        ;
    };

    return { getOneTimeOffQueryData, initGetOneTimeOffData };
};