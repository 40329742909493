import { gql, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

const UPLOAD_TRANING_CERTIFICATE = gql`
  mutation UploadTrainingCertificate($trainingCertificate: Upload) {
    uploadTrainingCertificate(trainingCertificate: $trainingCertificate) {
      success
      message
    }
  }
`;

export const UploadTrainingCertificate = () => {
  // const dispatch = useDispatch();
  const [
    UploadTrainingCertificateResponseFunction,
    uploadTrainingCertificateResponseData,
  ] = useMutation(UPLOAD_TRANING_CERTIFICATE);

  //this will be called from components
  const uploadTrainingCertificateQueryData =
    uploadTrainingCertificateResponseData; //variable

  const initUploadTrainingCertificateData = (
    data,
    successCallback,
    errorCallback
  ) => {
    UploadTrainingCertificateResponseFunction({
      variables: data,
    })
      // .then(({ data: { UploadTrainingCertificate: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        if (responseData?.data?.uploadTrainingCertificate?.success) {
          // dispatch(...)
          successCallback(responseData);
          // toast.success(responseData.message)
        } else {
          errorCallback(responseData);
          // toast.error(responseData.message)
        }
      })
      .catch((err) => {
        // errorCallback(err);
        console.log('typeeee:', err);
        toast.error('something went wrong!!!');
      });
  };

  return {
    uploadTrainingCertificateQueryData,
    initUploadTrainingCertificateData,
  };
};
