import React from 'react'
import './clinic-time-table.scss'
import backArrow from '../../../images/back_arrow.svg'
import deocsIcon from '../../../images/client/event.svg'
import celenderIcon from '../../../images/client/calendar-plus.svg'
import { useNavigate } from 'react-router'

const ClinicTimeTypes = () => {
    const navigate = useNavigate()
    const handleGoToClinicFixTime = () => {
        navigate('/clinic-fixed-time')
    }
    const handleGoToClinicFlexibleTime = () => {
        navigate('/clinic-flexible-time')
    }
    const handleGoToHome = () => {
        navigate('/add-new-clinic')
    }

    return (
        <>
            <div className='container'>
                <div className='header_icon mt-5'>
                    <img src={backArrow} alt='backArrow' onClick={handleGoToHome} />
                </div>
                <div className='clinic_working_hours_main'>
                    <div className='clinic_working_hours_title'>
                        <h1>Set your hours</h1>
                        <p>Tell us about your preferred work schedule.</p>
                    </div>
                    <div className='clinic_working_hours_list'>
                        <div className='working_type' onClick={handleGoToClinicFixTime}>
                            <div className='working_type_left'>
                                <h5>Fixed hours</h5>
                                <p>Perfect for businesses with consistent opening times.</p>
                                <button className='btn'>Set up</button>
                            </div>
                            <div className='working_type_right'>
                                <img src={deocsIcon} alt='' />
                            </div>
                        </div>

                        <div className='working_type'>
                            <div className='working_type_left'>
                                <h5>Flexible hours</h5>
                                <p>Great for businesses with varying schedules.</p>
                                <button className='btn'>Set up</button>
                            </div>
                            <div className='working_type_right'>
                                <img src={celenderIcon} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClinicTimeTypes
