import { call, put, takeLatest } from "@redux-saga/core/effects";
import { toast } from 'react-toastify';
import { API_URL, BASE_URL } from "../../../axios/config";
import axios from '../../../axios/index';
import { GET_PAYLATER_STATUS_DATA } from '../../constants';
import {getPaylaterStatusSuccess} from '../../actions/pay-later/payLaterAction'


async function getPayLaterStatusDataAPI(data) {
    return axios.request({
        method: "post",
        url: BASE_URL + API_URL.PAYLATER_STATUS,
        data: data,
    });
}

function* getPayLaterStatusDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        let response = yield call(getPayLaterStatusDataAPI, payload);
        // yield put(setLoaderComplete());
        if (response.status === 200) {

            if (response.data.success) {
                yield put(getPaylaterStatusSuccess(response.data));
            }
            else {
                toast.error(response.data.message)
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        // yield put(setLoaderComplete());
        if (reject) reject(error);
    }
}

export function* getPaylaterStatusWatcher() {
    yield takeLatest(GET_PAYLATER_STATUS_DATA, getPayLaterStatusDataAction);
}

