import React from 'react';
import notFoundImage from '../../../assets/customPages/404-image.jpg';
import { webBaseUrl } from '../../../config';
import './notFoundPage.scss';
import BusinessHeader from '../../businessHeader';
import BannerHeader from '../../common/BannerHeader';


const NotFoundPage = () => {
  const errorBtnClass = {
    borderRadius: '30px',
    border: '2px solid #000',
    color: '#000',
    padding: '10px 20px',
    fontWeight: '500',
    display: 'inline-block',
    textDecoration: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
  };
  return (
    <>
      {/* <header class="mainheader">
        <div class="tophead">
          <div class="container-fluid">
            <div class="d-flex align-items-center">
              <div class="headshare"></div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="headlink"></div>
        </div>
      </header> */}
      <div className="notFoundPage">
        <section class="commonbanner errorpage">
          <div class="container-fluid">
            <div class="errorcenter">
              <div class="errorimg">
                <img src={notFoundImage} alt="not-found" className="imgClass" />
              </div>
              <div class="errormessage">404</div>
              <p>Oops! Page not Found</p>
              <a class="errorbtn" href={webBaseUrl}>
                Go Back Home
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NotFoundPage;