import React from 'react'
import Step1Img from "../../images/Pill.svg"
import RightArrow from "../../images/check.svg"
import LeftImg from "../../images/foot.svg"
import LeftImg1 from "../../images/icons/form.svg"
import Arrow from "../../images/right_arrow.svg"
import './consent-steps.scss'
import StepButton from '../common/StepButton'
import WalkInConsentPage from '../walk-in-consent-form/WalkInConsentPage'
import { webBaseUrl } from '../../config'
import { useNavigate } from 'react-router'
import { manageFormsRoute } from '../../Routes'

const AppointmentsConsentModal = () => {

  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/walk-in-consent");
  };


  return (
    <>
      <div className="appointment-card">
        <div className="appointment-card-main">
          <div className='appointment-card-img'>
            {/* <img src={Step1Img} alt='step1' /> */}
            <button>NEW</button>
            <p>🚀 10,998 today</p>
          </div>

          <div className='appointment-card-content'>
            <h3>Book & Consent together</h3>
            {/* <p>Create a new Appointment with a consent form.</p> */}
          </div>

          <div className='appointment-card-list'>
            <img src={RightArrow} alt='step1' />
            <p>Forward Consent</p>
          </div>
          <div className='appointment-card-list'>
            <img src={RightArrow} alt='step1' />
            <p>Synced with calendar</p>
          </div>
          <div className='appointment-card-list'>
            <img src={RightArrow} alt='step1' />
            <p>Collect payments</p>
          </div>
          <div className='appointment-card-list'>
            <img src={RightArrow} alt='step1' />
            <p>Client reminders</p>
          </div>
          <div className='appointment-card-list'>
            <img src={RightArrow} alt='step1' />
            <p>Additional features</p>
          </div>
          <div className='appointment-card-footer1'>
            <StepButton label={"Continue"} blue={true} />
          </div>
        </div>
        <div className='appointment-card-footer2'>
          {/* <div className='title'>
            <p>Need something else?</p>
          </div> */}
          <div className='options'>
            <div className='left-img mt-4'>
              <div className='d-flex'>
                <div className='img-foot'>
                  <img src={LeftImg} alt='step1' />
                </div>
                <div className='text-title' onClick={handleNext}>
                  <h4>Walk-in + Consent</h4>
                  <p>Collect your clients consent.</p>
                </div>
              </div>
              <img src={Arrow} width={20} height={20} alt='step1' />
            </div>

            <div className='left-img mt-4'>
              <div className='d-flex'>
                <div className='img-foot'>
                  <img src={LeftImg1} alt='step1' />
                </div>
                <div className='text-title' onClick={() => navigate(manageFormsRoute())}>
                  <h4>Manage forms</h4>
                  <p>Modify existing consent forms.</p>
                </div>
              </div>
              <div className='arrow-img'>
                <img src={Arrow} alt='step1' />
              </div>
            </div>

          </div>
        </div>



        {/* <div className='booking-card-btn'>
            <StepButton label={"Next"} blue={true} />
        </div> */}
      </div>
    </>
  )
}

export default AppointmentsConsentModal
