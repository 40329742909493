import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import FinaceHeader from '../finaceHeader';

const FinanceStepsThankYouPage = () => {
  return (
    <>
      <div className="">
        <FinaceHeader />
        <div className="container d-flex justify-content-center align-items-center vh-100">
          <div className="thankyouPageWrapper" style={{
            textAlign: 'center'
          }}>
            <h1 className="thankyouPageTitle">Thank You</h1>
            <p className="thankyouPageDesc">
              You have just completed your finance process. You will receive
              further instruction on your email!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceStepsThankYouPage;
