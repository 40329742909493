import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_DATE_SLOTS, GET_TIME_SLOTS, SET_AVAILABLE_AND_OUTSIDE_AVAIBILITY_TIME_SLOTS_CREATE_APPOINTMENT } from "../../constants/dateTimeslots/DateTimeSlots";
import Cookies from "js-cookie";
import { useState } from "react";

//1 date-api
const GET_DATES_QUERY = gql`
query GetClinicAvailableDates($clinicId: Int!, $startDate: String!, $endDate: String!) {
    getClinicAvailableDates(clinic_id: $clinicId, start_date: $startDate, end_date: $endDate) {
      success
      message
      data {
        items
        total
      }
    }
  
  }`

export const GetClinicAvailableDateAction = () => {
  const dispatch = useDispatch()
  const [getClinicsAvailableDateResponseFunction, clinicsAvalilableDateReponseData] = useLazyQuery(
    GET_DATES_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClinicAvailableDates: responseData }) => {
        if (responseData?.success)
          dispatch({
            type: GET_DATE_SLOTS,
            payload: responseData
          })
      }
    }
  )
  //this will be called from components
  const clinicsAvailableDateData = clinicsAvalilableDateReponseData //variable

  const initClinicsAvailableDateResponseFunction = (data, successCallback) => {

    getClinicsAvailableDateResponseFunction({
      variables: data
    }).then((res) => {
      if (successCallback && res.data?.getClinicAvailableDates?.success) {
        successCallback(res.data?.getClinicAvailableDates?.data?.items)
      }
    })
  }
  return { clinicsAvailableDateData, initClinicsAvailableDateResponseFunction }
}

//2- time api


const TIME_SLOT_QUERY = gql`
query GetClinicAvailableTimesForDate($clinicId: Int!, $date: String!, $sessionId: String!, $treatmentIds: [Int], $page: Int, $limit: Int, $removePastSlots: Boolean) {
  getClinicAvailableTimesForDate(clinic_id: $clinicId, date: $date, session_id: $sessionId, treatment_ids: $treatmentIds, page: $page, limit: $limit, removePastSlots: $removePastSlots) {
    success
    message
    data {
      items {
        time_slot
        isBooked
      }
      total
      businessHours {
        daysOfWeek
        endTime
        startTime
      }
    }
  }
}`

export const GetAvailableTimeSlotsAction = () => {
  const dispatch = useDispatch()
  // var isCreateAppointmentData;
  const [createAppointmentData, setCreateAppointmentData] = useState(null)

  const [getAvailableTimeSlotsFunction, availableTimeSlotsResponseData] = useLazyQuery(
    TIME_SLOT_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClinicAvailableTimesForDate: responseData }) => {
        console.log(createAppointmentData, "state check")

        if (responseData?.success) {
          if (createAppointmentData == null) {
            dispatch({
              type: GET_TIME_SLOTS,
              payload: responseData
            })
          }

          else {

            dispatch({
              type: SET_AVAILABLE_AND_OUTSIDE_AVAIBILITY_TIME_SLOTS_CREATE_APPOINTMENT,
              payload: { addTime: createAppointmentData, responseData: responseData }
            })
          }
        }

      }
    }
  )
  //this will be called from components
  const availableTimeSlotData = availableTimeSlotsResponseData //variable

  const initAvalableTimeSlotData = (data, successCallback) => {
    //checking guest or logged in user
    let checkTokenLength = Cookies.get('faces_login_token')?.length
    let sessionString
    if (checkTokenLength > 0) {
      sessionString = sessionStorage.getItem('sessionString')
    } else {
      sessionString = sessionStorage.getItem('sessionString')
    }
    // isCreateAppointmentData = data["isCreateAppointment"]
    // console.log("dataaa", data["isCreateAppointment"])
    if (data["isCreateAppointment"] != undefined) {
      setCreateAppointmentData(data["isCreateAppointment"])
      delete data["isCreateAppointment"];
    }
    console.log("delete", data)
    getAvailableTimeSlotsFunction({
      variables: {
        ...data,
        sessionId: sessionString
      }
    }).then((res) => {
      if (successCallback && res.data?.getClinicAvailableTimesForDate?.success) {
        successCallback(res.data?.getClinicAvailableTimesForDate?.data)
      }
    })
  }

  return { availableTimeSlotData, initAvalableTimeSlotData }
}

// ========================GET CALENDAR APPOINTMENT COUNT========================
const GET_CALENDAR_APPOINTMENT_COUNT_QUERY = gql`
query GetCalendarAppointmentCount($startDate: String, $endDate: String, $search: String, $locations: [Int], $status: [Int]) {
  getCalendarAppointmentCount(startDate: $startDate, endDate: $endDate, search: $search, locations: $locations, status: $status) {
    success
    data {
      appointmentCountData {
        appointmentDate
        appointmentCount
      }
    }
  }
}
`;

export const GetCalendarAppointmentCountAction = () => {
  const dispatch = useDispatch();
  const [
    GetCalendarAppointmentCountResponseFunction,
    getCalendarAppointmentCountResponseData,
  ] = useLazyQuery(GET_CALENDAR_APPOINTMENT_COUNT_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getCalendarAppointmentCount: responseData }) => {
      if (responseData.success) {
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getCalendarAppointmentCountQueryData = getCalendarAppointmentCountResponseData; //variable

  const initGetCalendarAppointmentCountData = (data, successCallBack, errorCallback) => {
    GetCalendarAppointmentCountResponseFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.GetCalendarAppointmentCount?.success)
        successCallBack(res.data.GetCalendarAppointmentCount.data)
    });
  };

  return { getCalendarAppointmentCountQueryData, initGetCalendarAppointmentCountData };
};

// ========================IS ALREADY BOOKING EXIST========================
const IS_ALREADY_BOOKING_EXIST_QUERY = gql`
query IsAlreadyBookingExist($date: String, $startTime: String, $clinicId: Int, $endTime: String, $treatmentIds: [Int], $appointmentId: Int) {
  isAlreadyBookingExist(date: $date, start_time: $startTime, clinic_id: $clinicId, end_time: $endTime, treatment_ids: $treatmentIds, appointment_id: $appointmentId) {
    success
    message
    data {
      isBookingExist
    }
  }
}
`;

export const IsAlreadyBookingExistAction = () => {
  const dispatch = useDispatch();
  const [
    IsAlreadyBookingExistResponseFunction,
    isAlreadyBookingExistResponseData,
  ] = useLazyQuery(IS_ALREADY_BOOKING_EXIST_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ isAlreadyBookingExist: responseData }) => {
      if (responseData.success) {
        // dispatch({
        //   type: GET_RATING_REVIEWS_CONST,
        //   payload: responseData
        // })
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const isAlreadyBookingExistQueryData = isAlreadyBookingExistResponseData; //variable

  const initIsAlreadyBookingExistData = (data, successCallBack, errorCallback) => {
    IsAlreadyBookingExistResponseFunction({
      variables: data,
    }).then((res) => {
      if (res.data.isAlreadyBookingExist.success)
        successCallBack(res.data.isAlreadyBookingExist)
      else
        errorCallback(res.data.isAlreadyBookingExist)
    }).catch((error) => {
      errorCallback(error)
    });
  };

  return { isAlreadyBookingExistQueryData, initIsAlreadyBookingExistData };
};



