import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { Rating } from 'react-simple-star-rating'
import CarouselComponent from '../../pages/carousel-component/carousel-component'

const DashboardBusinessHealth = ({ dashboardListData }) => {

    return (
        <div>
            <div className="business-health-container mb-5">
                <div className="mb-4">
                    <h2>Business health</h2>
                    <p>Ranked #{dashboardListData?.reviewRank} out of {dashboardListData?.totalPractitionersForReview} businesses.</p>
                </div>
                <CarouselComponent nav
                    loop={false}
                    data={
                        <>
                            {
                                dashboardListData?.latest_reviews?.map((item) => {

                                    let resultString = ""
                                    const currentDate = moment(); // This will give you the current date.
                                    const targetDate = moment(item?.created_at, 'YYYY-MM-DD HH:mm:ss');
                                    const daysDifference = currentDate.diff(targetDate, 'days');
                                    const hoursDifference = currentDate.diff(targetDate, 'hours');
                                    const minituesDifference = currentDate.diff(targetDate, 'minutes');
                                    if (daysDifference === 0) {
                                        if (hoursDifference === 0) {
                                            resultString = minituesDifference + " mins ago"
                                        } else {
                                            resultString = hoursDifference + " hours ago"
                                        }
                                    } else {
                                        resultString = daysDifference + " days ago"
                                    }

                                    return (
                                        <div className="business-review-card" >

                                            <div className="written-reviews-container">
                                                <p className="business-reviews">
                                                    {item?.review_feedback}
                                                </p>
                                            </div>
                                            <div className="d-flex gap-2 mb-2 rating1">
                                                <div>
                                                    <Rating
                                                        size={16}
                                                        disableFillHover={true}
                                                        allowHover={false}
                                                        initialValue={item?.review_rating}
                                                        readonly={true}
                                                        fillColor="#000000"
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mt-2">
                                                <div className="">
                                                    <p className="my-auto rating-name-date">{item?.client_fname?.charAt(0).toUpperCase() + item?.client_fname?.substring(1)} • {resultString}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                )
                            }
                        </>
                    } />

            </div>
        </div>
    )
}

export default DashboardBusinessHealth