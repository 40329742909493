import "./RegisterWithFinancePopup.scss"

const RegisterWithFinancePopup = ({ setShowPopup , initLoginFunction }) => {


  const handleAgree = () => {
    // appliedForFinance(true)
    // initLogin(true); 
    initLoginFunction(true); 
  }

  const handleClose = () => {
    setShowPopup(false)
  }

  return (
    <div className="popup-container container">
      <div className="popup-container">
        <h3>Register with payl8r</h3>
        <p>Would you like to register with payl8r now?</p>
        <div className="BtnWrapper">
          <button onClick={ handleClose} className="btn buttonfirst">No</button>
          <button onClick={handleAgree} className="btn secondbutton button">Yes</button>
        </div>
      </div>
    </div>
  )
}

export default RegisterWithFinancePopup;
