import React, { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from "react-loading-skeleton"
import moment from "moment"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import "react-loading-skeleton/dist/skeleton.css"
import './support-chat-widget.scss'
import { Tooltip } from 'react-tooltip'
import { Rating } from 'react-simple-star-rating'
import CustomModal from '../modal/CustomModal'
import { webBaseUrl, socketUrl, notificationMode, appBaseUrl } from "./../../../src/config";
import { socket } from './../../components/socket';
import getProfileData from './../../components/redux/actions/profile/Profile';
import Swal from 'sweetalert2';
import $ from 'jquery';
import InfiniteScroll from "react-infinite-scroll-component"
import SupportThreadListSkeleton from '../skeleton/support-skeleton/SupportThreadListSkeleton'
import Cookies from 'js-cookie';

import {
	getSupportChatData,
	getThreadChatData,
	getSupportChatCategoriesData,
	createSupportThreadsData,
	getSupportRecentOrdersData,
	getOrderTraceData,
	createSupportChatThreadsData,
	supportThreadsAddFeedbackData,
	supportThreadsChatUpdateData,
	supportThreadMessagesMarkRead,
	supportChatTotalUnreadCountData,
} from "../redux/actions"

import reOpenDoneImg from "../../../src/assets/cart/reopen-done.png"
import paperClip from "../../../src/assets/chat/paperclip.svg"
import sendPopup from "../../../src/assets/chat/send-popup.svg"
import myOrders from "../../../src/assets/chat/my-orders.png"
import others from "../../../src/assets/chat/other.png"
import singleTick from "../../../src/assets/chat/image_singletick.png"
import doubleTick from "../../../src/assets/chat/image_doubletick.png"
import { el, tr } from "date-fns/locale"

import createTicket from "../../../src/assets/support/create-ticket.png"
import expend from "../../../src/assets/support/expend.png"
import location from "../../../src/assets/support/location.png"
import missing from "../../../src/assets/support/missing.png"
import talk from "../../../src/assets/support/talk.png"
import otherImg from "../../../src/assets/support/other.png"
import SupportThreadElement from "./support-thread-element"
import axios from "axios";
import { success } from '../../constants/cart';

const SupportChatWidget = () => {
	const dispatch = useDispatch()
	const getSupportChatWidgetData = useSelector(state => state.GetSupportChatDataReducer.supportChatData)
	const getSupportChatWidgetMessage = useSelector(state => state.GetSupportChatDataReducer.supportChatMessage)

	const { supportChatDataTotalCount } = useSelector((state) => state.GetSupportChatDataReducer)
	const { supportChatDataCount } = useSelector((state) => state.GetSupportChatDataReducer)
	const { supportChatDataSkeletonFlag } = useSelector((state) => state.GetSupportChatDataReducer)
	const { supportChatPage } = useSelector((state) => state.GetReviewsDataReducer)

	const { supportChatMessageSkeletonFlag } = useSelector((state) => state.GetSupportChatDataReducer)
	const { supportNewChatSkeletonFlag } = useSelector((state) => state.GetSupportChatDataReducer)

	const getThreadChatResData = useSelector(state => state.GetSupportChatDataReducer.threadChatData)
	const getThreadChatResMessage = useSelector(state => state.GetSupportChatDataReducer.threadChatMessage)

	const supportCategoriesStateData = useSelector(state => state.GetSupportChatDataReducer.supportCategoriesData)
	const supportCategoriesStateMessage = useSelector(state => state.GetSupportChatDataReducer.supportCategoriesMessage)

	const createSupportThreadsStateData = useSelector(state => state.GetSupportChatDataReducer.createSupportThreadsData)
	const createSupportThreadsStateMessage = useSelector(state => state.GetSupportChatDataReducer.createSupportThreadsMessage)

	const supportRecentOrdersListData = useSelector(state => state.GetSupportChatDataReducer.supportRecentOrdersData)
	const supportRecentOrdersMessage = useSelector(state => state.GetSupportChatDataReducer.supportRecentOrdersMessage)

	const getUserOrderTraceData = useSelector(state => state.GetSupportChatDataReducer.getUserOrderTraceData)
	const getUserOrderTraceMessage = useSelector(state => state.GetSupportChatDataReducer.getUserOrderTraceMessage)

	const createSupportChatThreadsStateData = useSelector(state => state.GetSupportChatDataReducer.createSupportChatThreadsData)
	const createSupportChatThreadsStateMessage = useSelector(state => state.GetSupportChatDataReducer.createSupportChatThreadsMessage)

	const supportThreadsAddFeedbackSuccess = useSelector(state => state.GetSupportChatDataReducer.supportThreadsAddFeedbackSuccess)
	const supportThreadsAddFeedbackMessage = useSelector(state => state.GetSupportChatDataReducer.supportThreadsAddFeedbackMessage)

	const supportThreadsChatUpdateSuccess = useSelector(state => state.GetSupportChatDataReducer.supportThreadsChatUpdateSuccess)

	const { supportTotalUnreadCountData } = useSelector(state => state.GetSupportChatDataReducer)

	const listItemRefs = useRef({})
	const newMessageRef = useRef(null)
	const backThreadsButtonRef = useRef(null)
	const chatMessageMainRef = useRef(null)
	const mainTreadListRef = useRef(null)
	const msgListingElementsContainerRef = useRef(null)

	const [supportCategoriesData, setSupportCategoriesData] = useState(null)

	const [createNewChatMessage, setCreateNewChatMessage] = useState(null)
	const [supportChatMessages, setSupportChatMessages] = useState(null)
	const [selectedTreadUserId, setSelectedTreadUserId] = useState(null)
	const [isScrollingTop, setIsScrollingTop] = useState(false)
	const [threadUserId, setThreadUserId] = useState(0)

	const [supportChatTotalMessages, setSupportChatTotalMessages] = useState(0)
	const [pageScrollSet, setPageScrollSet] = useState(true)

	const [orderIdData, setOrderIdData] = useState(null)
	const [orderTypeData, setOrderTypeData] = useState(null)

	const [trackingOrderInfo, setTrackingOrderInfo] = useState(false)
	const [trackingOrderInfoMessage, setTrackingOrderInfoMessage] = useState("")
	const [trackingOrderInfoLink, setTrackingOrderInfoLink] = useState("")
	const [trackingOrderInfoNumber, setTrackingOrderInfoNumber] = useState("")
	// tracking_number

	const [messageValidate, setMessageValidate] = useState("Please enter a message")
	const [fileValidate, setFileValidate] = useState("You can not upload more then 3 files!")

	const [trackMyOrder, setTrackMyOrder] = useState(false)
	const [missingItems, setMissingItems] = useState(false)
	const [speakToTheSeller, setSpeakToTheSeller] = useState(false)
	const [otherTraceOrderItem, setOtherTraceOrderItem] = useState(false)
	const [missingItemsMessage, setMissingItemsMessage] = useState("")
	const [speakToSellerMessage, setSpeakToSellerMessage] = useState("")
	const [myOrdersMessageSection, setMyOrdersMessageSection] = useState(0)

	const [myRecentOrdersList, setMyRecentOrdersList] = useState(null)
	const [myOrdersSection, setMyOrdersSection] = useState(false)

	const [orderItemCardSelection, setOrderItemCardSelection] = useState(false)

	const [messageIds, setMessageIds] = useState([])
	const [hasMorePages, setHasMorePages] = useState(false)

	const [createChatTickets, setCreateChatTickets] = useState(false)
	const [isShowTreadsTickets, setShowTreadsTickets] = useState(false)
	const [isShowMessagesBack, setShowMessagesBack] = useState(false)
	const [myOrderStage, setMyOrderStage] = useState(1)

	const [chatSelectionContainer, setChatSelectionContainer] = useState(false)
	const [boxDashboredCategoryContainer, setBoxDashboredCategoryContainer] = useState(false)
	const [customChatPopupIconFooter, setCustomChatPopupIconFooter] = useState(false)
	const [messageBoxThreadShow, setMessageBoxThreadShow] = useState(false)
	const [selectedFile, setSelectedFile] = useState(null)
	const [chatSelectedFile, setChatSelectedFile] = useState([])
	const [customChatMessageBox, setCustomChatMessageBox] = useState("")
	const [customMessageBox, setCustomMessageBox] = useState("")

	const [clientDataPage, setClientDataPage] = useState(1)
	const [penndingChatCount, setPenndingChatCount] = useState(0)
	const [socketNewMessageData, setSocketNewMessageData] = useState([]);
	const [penndingChatShow, setpenndingChatShow] = useState(false)
	const [isBackThreadsVisible, setBackThreadsVisible] = useState(false)
	const [isCreateTicketVisible, setCreateTicketVisible] = useState(true)
	const [isChatSendBoxVisible, setChatSendBoxVisible] = useState(false)
	const [openTreadsChatContainer, setOpenTreadsChatContainer] = useState(true)
	const [chatMessageContainer, setChatMessageContainer] = useState(false)

	const [createSupportThreadsReturnData, setCreateSupportThreadsReturnData] = useState(null)
	const [chatHeight, setChatHeight] = useState('43.6vh') // Set your initial height value
	const [chatMarginBottom, setChatMarginBottom] = useState('40px')
	const [supportChatData, setsupportChatData] = useState(null)
	const [supportTotalThreads, setSupportTotalThreads] = useState(0)

	const [selectedLi, setSelectedLi] = useState(null)

	const [prevScrollPosition, setPrevScrollPosition] = useState(null)
	const [rating, setRating] = useState(0)
	const [addReplyModal, setAddReplyModal] = useState(false)
	const [addReplyToBePostData, setAddReplyToBePostData] = useState("")
	const [addReplyToBePostError, setAddReplyToBePostError] = useState("")

	const [feedbackThreadId, setFeedbackThreadId] = useState(null)
	const [feedbackProfileImg, setFeedbackProfileImg] = useState(null)
	const [chatSendMessagePreviews, setChatSendMessagePreviews] = useState(false)
	const [reopenButton, setReopenButton] = useState(false)
	const [submitFeedbackButton, setSubmitFeedbackButton] = useState(false)
	const [inThread, setInThread] = useState(false)
	const [resolvedThread, setResolvedThread] = useState(true)
	const [supportThreadChatUpdated, setSupportThreadChatUpdated] = useState(false)

	const [reOpenDoneModal, setReOpenDoneModal] = useState(false)
	const [currentThreadsPage, setCurrentThreadsPage] = useState(1)
	const [hasThreadMorePages, setHasThreadMorePages] = useState(false)
	const [isThreadsLoading, setIsThreadsLoading] = useState(false)
	const [threadsScrollHeight, setThreadsScrollHeight] = useState(0)
	const [disableScroll, setDisableScroll] = useState(false)
	const [toUserIds, setToUserIds] = useState([])
	const [currentUserId, setCurrentUserId] = useState(0)
	const [userProfileData, setUserProfileData] = useState(null)
	const { profileData } = useSelector(state => state?.GetProfileDataReducer)
	const formRef = useRef(null);
	const [selectedImage, setSelectedImage] = useState(null);
	const [imagePreviewModal, setImagePreviewModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const [returnTreadId, setReturnTreadId] = useState(0)
	const [chatOpenData, setChatOpenData] = useState(false)

	const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
	const [userName, setUserName] = useState('')
	const [email, setEmail] = useState('')
	const [errors, setErrors] = useState({
		userName: '',
		email: ''
	});

	const validateEmail = (email) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	};

	const supportChatUrl = '/support-chat'
	const goToSupportChat = () => {
		// let redirectThreadId = ""
		// let link = ""
		// if(threadUserId) {
		//     redirectThreadId = threadUserId
		//     link = webBaseUrl + supportChatUrl +"?thread_id="+redirectThreadId+""
		// } else {
		//     redirectThreadId = feedbackThreadId
		//     link = webBaseUrl + supportChatUrl
		// }
		// window.open(link, '_blank')
	}
	useEffect(() => {
		if (profileData) {
			setUserProfileData(profileData)
		}
	}, [profileData])

	useEffect(() => {
		if (userProfileData) {
			setCurrentUserId(userProfileData.user_id)
		}
	}, [userProfileData]);

	const handleImageClick = (index) => {
		// Set the selected image to be displayed in the popup
		setSelectedImage(URL.createObjectURL(chatSelectedFile[index]));
		setImagePreviewModal(true);
	};

	const handleClosePopup = () => {
		// Close the popup by resetting the selected image
		setSelectedImage(null);
		setImagePreviewModal(false);
	};

	useEffect(() => {
		if (profileData == null) {
			dispatch(getProfileData())
		}
		socket.on('new_message', (event) => {
			console.log('new_message 111', event)
			console.log(event.message)
		})
		const onDisconnect = (error) => {
			console.log('disconnected', error)
			socket.off('new_message')
		}
		return () => {
			socket.off('disconnect', onDisconnect)
			// socket.disconnect()
		}
	}, [])

	const reOpenSupportThread = (e) => {
		const fbThreadId = e.currentTarget.dataset.thread_id
		const elementId = e.currentTarget.dataset.in_thread ? e.currentTarget.dataset.in_thread : ''
		if (elementId) {
			setInThread(true)
		}
		setFeedbackThreadId(fbThreadId)
		setRating(0)
		setReOpenDoneModal(true)
		e.stopPropagation()
	}
	const handleReOpenDone = (e) => {
		e.preventDefault()
		setMessageIds([])
		setPrevScrollPosition(null)
		setSupportChatMessages(null)
		setChatSendMessagePreviews(true)
		setClientDataPage(1)
		dispatch(supportThreadsChatUpdateData({
			elementId: feedbackThreadId,
			action: 'reopen'
		}))
	}

	useEffect(() => {
		if (supportThreadsChatUpdateSuccess) {
			setSupportThreadChatUpdated(true)
		}
	}, [supportThreadsChatUpdateSuccess])

	useEffect(() => {
		if (supportThreadChatUpdated) {
			setReOpenDoneModal(false)
			setsupportChatData(null)
			setAddReplyModal(false)
			setPageScrollSet(false)
			setRating(0)
			if (inThread) {
				setReopenButton(false)
				setSubmitFeedbackButton(false)
				setResolvedThread(true)
			}
			setCurrentThreadsPage(1)
			getPopupSupportTreadsData()
			getChat(feedbackThreadId)
			setPrevScrollPosition(0)
		}
	}, [supportThreadChatUpdated])

	const handleRating = (rate) => {
		setRating(rate)
	}
	const handleChageTextAreaData = (e) => {
		setAddReplyToBePostData(e.target.value)
	}
	const handleAddReviewSubmit = (e) => {
		e.preventDefault()
		if (rating == 0) {
			setAddReplyToBePostError("please add Review!")
			return
		}
		// CALL API HERE
		dispatch(supportThreadsAddFeedbackData({
			threadId: feedbackThreadId,
			rating: rating
		}))
	}
	useEffect(() => {
		if (supportThreadsAddFeedbackSuccess) {
			setsupportChatData(null)
			setAddReplyModal(false)
			setRating(0)
			if (inThread) {
				setReopenButton(false)
				setSubmitFeedbackButton(false)
				setResolvedThread(false)
			}
			setCurrentThreadsPage(1)
			getPopupSupportTreadsData(true)
		}
	}, [supportThreadsAddFeedbackSuccess])
	const submitFeedback = (e) => {
		const fbThreadId = e.currentTarget.dataset.thread_id
		const fbProfileImg = e.currentTarget.dataset.profile_img
		const elementId = e.currentTarget.dataset.in_thread ? e.currentTarget.dataset.in_thread : ''
		if (elementId) {
			setInThread(true)
		}
		setFeedbackThreadId(fbThreadId)
		setFeedbackProfileImg(fbProfileImg)
		setRating(0)
		setAddReplyToBePostError("")
		setAddReplyModal(true)
		e.stopPropagation()
	}
	const getMessages = async (threadId) => {
		setThreadUserId(threadId)
		let newPage = clientDataPage
		console.log('pppppppppp', supportChatMessages)
		if (!supportChatMessages) {
			newPage = 1
		}
		// if (newPage === 1 && newMessageRef.current) {
		//   newMessageRef.current.dispatchEvent(new Event('keyup'))
		// }

		// backThreadsButtonRef.current.disabled = true
		// backThreadsButtonRef.current.style.filter = 'blur(1px)'
		// backThreadsButtonRef.current.style.cursor = 'not-allowed'

		// if (chatMessageMainRef.current) {
		//   chatMessageMainRef.current.classList.add('loading')
		// }
		// console.log('threadId', threadId, messageIds)
		try {
			setClientDataPage((prevPage) => prevPage + 1)
			setPrevScrollPosition(chatMessageMainRef.current.scrollHeight)
			dispatch(getThreadChatData(
				{
					page: newPage,
					platform: "app",
					threadId: threadId,
					messageIds: messageIds,
				}
			))

			// if (response.data.success) {
			// renderTooltips(true)

			// if (newPage === 1) {
			//   msgListingElementsContainerRef.current.innerHTML = ''
			//   messageIds = response.data.messageIds.

			// } else {
			//   response.data.messageIds.forEach(element => {
			//     messageIds.push(element)
			//   })
			// }

			// msgListingElementsContainerRef.current.insertAdjacentHTML('afterbegin', response.data.view)

			// document.querySelector('.scrolldown').style.display = 'none'

			// const existDate = []
			// if (msgListingElementsContainerRef.current.querySelectorAll('.created-item-date').length) {
			//   msgListingElementsContainerRef.current.querySelectorAll('.created-item-date').forEach(item => {
			//     if (!existDate.includes(item.dataset.listDate)) {
			//       existDate.push(item.dataset.listDate)
			//     } else {
			//       item.remove()
			//     }
			//   })
			// }

			// hasMorePages = response.data.hasMorePages

			// if (response.data.call_scheduled_on && !response.data.is_scheduled_date_passed) {
			//   document.querySelector('.scheduled_call_sticky').style.display = 'block'
			// } else {
			//   document.querySelector('.scheduled_call_sticky').style.display = 'none'
			// }

			// document.querySelector('.schedule_date_time').innerHTML = response.data.call_scheduled_on
			// call_scheduled_on = response.data.call_scheduled_on

			// console.log('chatMessageMainRef', newPage)
			// console.log(chatMessageMainRef.current.scrollHeight)
			// if (newPage === 1) {
			//     chatMessageMainRef.current.scrollTop = chatMessageMainRef.current.scrollHeight
			// }
			// } else {
			//     console.log('danger', response.data.message)
			// }
		} catch (error) {
			console.log('danger', 'Something went wrong!')
		} finally {
			//   chatMessageMainRef.current.classList.remove('loading')
			//   document.querySelector('.chat-container').classList.remove('loading')
			//   backThreadsButtonRef.current.disabled = false
			//   backThreadsButtonRef.current.style.filter = 'blur(0px)'
			//   backThreadsButtonRef.current.style.cursor = 'pointer'
		}
	}

	const handleScroll = (event) => {
		if (supportChatMessageSkeletonFlag) {
			event.preventDefault();
			return false;
		}
		const SCROLL_THRESHOLD = 10
		if (chatMessageMainRef.current) {
			const scrollTop = chatMessageMainRef.current.scrollTop
			if (scrollTop < SCROLL_THRESHOLD && supportChatMessages.length < supportChatTotalMessages && pageScrollSet) {
				// Fetch more data when scrolling near the top
				setPageScrollSet(false)
				getMessages(threadUserId)
			} else if (scrollTop >= SCROLL_THRESHOLD && !pageScrollSet) {
				// User has scrolled back down, allow another API call
				setPageScrollSet(true)
			}
		}
	}

	const handleThreadsScroll = (refreshList = false) => {
		let setPage = refreshList
		if (refreshList) {
			setCurrentThreadsPage(1)
		} else if (supportChatDataTotalCount <= supportChatData.length) {
			return;
		}
		getPopupSupportTreadsData(setPage)

		// alert('handleThreadsScroll')
		// setReturnTreadId(0)
		// // if (disableScroll) {
		// //     // If scrolling is disabled, prevent further handling
		// //     event.preventDefault()
		// //     return
		// // }
		// if (mainTreadListRef.current) {
		// //     alert('mainTreadListRef.current')
		//     const { scrollTop, clientHeight, scrollHeight } = mainTreadListRef.current
		//     const threshold = 2

		//     console.log('scroll', scrollTop, clientHeight, scrollHeight);
		//     if (parseInt(scrollTop) + parseInt(clientHeight) + parseInt(threshold) >= parseInt(scrollHeight) && hasThreadMorePages) {
		//         console.log('scroll111', scrollTop, clientHeight, scrollHeight)
		//         setThreadsScrollHeight(scrollTop - 200)
		//         getPopupSupportTreadsData()
		//     }
		// }
	}

	const OpenChatPopup = () => {
		$(formRef.current).slideToggle(1000, function () {
			// This function is called after the animation completes
			// Check if the form is visible after the animation
			const isOpen = $(formRef.current).is(':visible');

			// If the form is visible, do something
			if (isOpen) {
				console.log('Form is open!');
			} else {
				countUnreadMessages()
				console.log('Form is closed!');
			}
		});
		setReturnTreadId(0)
		if (selectedTreadUserId) {
			setChatOpenData(true)
		}

		setpenndingChatShow(!penndingChatShow)
		if (!supportChatData) {
			setCurrentThreadsPage(1)
			getPopupSupportTreadsData()
		}
	}
	const scrollTop = () => {
		if (mainTreadListRef.current) {
			mainTreadListRef.current.scrollTop = 0;
		}
	};
	const getPopupSupportTreadsData = (setPage = false) => {
		setIsThreadsLoading(false)
		setDisableScroll(true)
		if (setPage) {
			dispatch(getSupportChatData({
				page: 1,
				'request-from': "app",
			}))
		} else {
			dispatch(getSupportChatData({
				page: currentThreadsPage,
				'request-from': "app",
			}))
		}
	}

	const handleGetOrderTraceData = () => {
		const formData = {
			orderId: orderIdData,
			orderType: orderTypeData,
			'request-from': 'app',
		}
		dispatch(getOrderTraceData(formData))
	}

	const traceOrderItem = () => {
		setMyOrdersMessageSection(4)
		setMyOrderStage(3)
		handleGetOrderTraceData()
		setTrackMyOrder(true)
		setOtherTraceOrderItem(false)
		setSpeakToTheSeller(false)
		setMissingItems(false)
		setOrderItemCardSelection(false)
		setCustomMessageBox(`I would like to have an update regarding the delivery of ${orderTypeData.charAt(0).toUpperCase() + orderTypeData.slice(1)} of ${orderIdData}. Can you please help me with this?`)
		setTrackingOrderInfo(true)
		setCustomChatPopupIconFooter(false)
		setMessageBoxThreadShow(false)
	}

	useEffect(() => {
		if (selectedLi) {
			setCustomChatPopupIconFooter(true)
			setMessageBoxThreadShow(true)
		}
	}, [selectedLi])
	useEffect(() => {
		const iconElement = document.querySelector('.open-button i')
		if (iconElement) {
			if (penndingChatShow) {
				iconElement.classList.add('fa-close')
				iconElement.classList.remove('fa-comment')
			} else {
				iconElement.classList.add('fa-comment')
				iconElement.classList.remove('fa-close')
			}
		}
		if (getThreadChatResData && !chatOpenData) {
			//set prev scroll position to current scroll position
			setSupportChatTotalMessages(getThreadChatResData.total)
			if (!supportChatMessages) {
				setSupportChatMessages(getThreadChatResData.elements.slice().reverse())
			} else {
				setSupportChatMessages((prevData) => [
					...getThreadChatResData.elements.slice().reverse(),
					...prevData,
				])
			}
			if (getThreadChatResData.elements.length > 0) {
				setMessageIds((prevData) => [
					...getThreadChatResData.elements.map(item => item.scm_id),
					...prevData,
				])
			}
			// setHasMorePages(getThreadChatResData.hasMorePages)
		}
	}, [
		penndingChatShow,
		getThreadChatResData,
	])
	useEffect(() => {
		if (getSupportChatWidgetData) {
			try {
				setCurrentThreadsPage((prevPage) => prevPage + 1)
				// setInThread(false)
				if (currentThreadsPage === 1) {
					setsupportChatData(getSupportChatWidgetData)
					// setSupportTotalThreads(getSupportChatWidgetData.total)
				} else {
					setsupportChatData((prevData) => [
						...prevData,
						...getSupportChatWidgetData,
					])
				}
				setChatOpenData(false)
				setIsThreadsLoading(true)
				setDisableScroll(false)
			} catch (error) {
				setIsThreadsLoading(true)
			}
		}
	}, [getSupportChatWidgetData])
	useEffect(() => {
		if (supportCategoriesStateData) {
			if (!supportCategoriesData) {
				setSupportCategoriesData(supportCategoriesStateData.elements)
			}
		}
	}, [supportCategoriesStateData])
	useEffect(() => {
		if (supportChatData) {
			// if(supportChatData.length < supportTotalThreads) {
			//     setHasThreadMorePages(true)
			// } else {
			//     setHasThreadMorePages(false)
			// }
			if (returnTreadId) {
				const listItem = document.querySelector(`[data-enc_id="${returnTreadId}"]`)
				if (listItem) {
					handleShowTreadsTicketsClick()
					setSelectedLi(null)
					// Assign the found list item to the corresponding ref
					listItemRefs.current[`${returnTreadId}`] = listItem
					// Trigger a click event on the found list item
					listItemRefs.current[`${returnTreadId}`]?.click()
				}
			}
		}
	}, [supportChatData])

	useEffect(() => {
		const uniqueMessageIdsSet = new Set(messageIds)
		const uniqueMessageIdsArray = Array.from(uniqueMessageIdsSet)
		setMessageIds(uniqueMessageIdsArray)
		if (supportChatMessages) {
			scrollToBottom()
		}
	}, [supportChatMessages])

	const scrollToBottom = () => {
		console.log('first')
		if (chatMessageMainRef.current) {
			console.log('secound')
			if (prevScrollPosition) {
				console.log('third')
				chatMessageMainRef.current.scrollTop = chatMessageMainRef.current.scrollHeight - prevScrollPosition
			} else {
				console.log('fourth')
				chatMessageMainRef.current.scrollTop = chatMessageMainRef.current.scrollHeight
			}
		}
	}

	let preDate = ''
	let todayDate = false
	// createSupportThreadsStateData is exist then supportChatData is reagain call the dispach function and get the data in OpenChatPopup method
	useEffect(() => {
		if (createSupportThreadsStateData) {
			console.log('ffffffffffff', createSupportThreadsStateData)
			setShowMessagesBack(false)
			setSelectedFile(null)
			setCreateSupportThreadsReturnData(createSupportThreadsStateData)
			setCreateChatTickets(false)
			setCustomChatPopupIconFooter(false)
			setOpenTreadsChatContainer(false)
			setChatMessageContainer(true)
			setShowTreadsTickets(false)
			setCreateTicketVisible(true)
			setsupportChatData(null)
			dispatch(getSupportChatData())
			if (createSupportThreadsStateData?.element) {
				console.log('createSupportThreadsStateData.element', createSupportThreadsStateData?.element)
				let returnTreadId = createSupportThreadsStateData?.element.enc_id
				setReturnTreadId(returnTreadId)
			}
		}
	}, [createSupportThreadsStateData])

	useEffect(() => {
		if (supportRecentOrdersListData) {
			setMyRecentOrdersList(supportRecentOrdersListData)
		}
	}, [supportRecentOrdersListData])

	const openTreadChat = (e) => {
		setSocketNewMessageData([])
		setChatOpenData(false)
		setCustomMessageBox("")
		setChatHeight('43.6vh')
		setChatMarginBottom('40px')
		setToUserIds([])
		const data = e.currentTarget.dataset.dt
		const parsedData = JSON.parse(data)

		let sellerId = null
		let seller_name = null
		let seller_profile = null

		if (parsedData.sct_seller_id) {
			sellerId = parsedData.sct_seller_id
			seller_name = parsedData.seller_user_name
			seller_profile = parsedData.seller_profile
		}

		const adminId = parsedData.sct_ssa_usr_id
		const UserId = e.currentTarget.dataset.user_id
		const elementId = e.currentTarget.dataset.enc_id
		const status = e.currentTarget.dataset.status
		const feedback = e.currentTarget.dataset.feedback
		const fbThreadId = e.currentTarget.dataset.thread_id
		const fbProfileImg = e.currentTarget.dataset.profile_img

		setSupportChatMessages(null)
		dispatch(supportThreadMessagesMarkRead({
			thread_id: elementId,
		}))
		if (UserId === adminId) {
			setToUserIds((prevToUserIds) => [...prevToUserIds, "" + UserId])
		} else {
			setToUserIds((prevToUserIds) => [...prevToUserIds, "" + adminId])
		}
		if (sellerId !== "null" && sellerId !== "" && sellerId !== null) {
			setToUserIds((prevToUserIds) => [...prevToUserIds, "" + sellerId])
		}
		setSelectedTreadUserId(UserId)
		if (elementId) {
			showChatMessages(null)
			getChat(elementId)
			setReturnTreadId(0)

			setCustomChatPopupIconFooter(false)
			// Set other state values based on your logic
			setOpenTreadsChatContainer(false)
			setChatMessageContainer(true)
			setFeedbackThreadId(fbThreadId)
			setFeedbackProfileImg(fbProfileImg)

			if (status == '0') {
				setChatSendMessagePreviews(true)
				setReopenButton(false)
				setSubmitFeedbackButton(false)
				setResolvedThread(true)
			} else {
				if (!feedback) {
					setChatSendMessagePreviews(false)
					setReopenButton(true)
					setSubmitFeedbackButton(true)
					setResolvedThread(true)
				} else {
					setChatSendMessagePreviews(false)
					setReopenButton(false)
					setSubmitFeedbackButton(false)
					setResolvedThread(false)
				}
			}


			// ... other state updates

			// Hide or show elements based on state values
			// ...
		}
	}

	useEffect(() => {
		if (getUserOrderTraceData) {
			setTrackingOrderInfoMessage(getUserOrderTraceData.tracking_message)
			setTrackingOrderInfoLink(getUserOrderTraceData.tracking_link)
			setTrackingOrderInfoNumber(getUserOrderTraceData.tracking_number)

			setMissingItemsMessage(getUserOrderTraceData.missing_items_message)
			setSpeakToSellerMessage(getUserOrderTraceData.speak_to_seller)
			if (myOrdersMessageSection === 1) {
				setCustomMessageBox(getUserOrderTraceData.missing_items_message)
			} else if (myOrdersMessageSection === 2) {
				setCustomMessageBox(getUserOrderTraceData.speak_to_seller)
			} else if (myOrdersMessageSection === 3) {
				setCustomMessageBox("")
			}

		}
	}, [getUserOrderTraceData])

	const trackingCreateTreadCancel = () => {
		setOrderItemCardSelection(true)
		setChatSelectionContainer(false)
		setMyOrdersSection(false)
		setTrackingOrderInfo(false)
	}
	const orderItemCardData = (e) => {
		setMyOrderStage(2)
		setTrackMyOrder(false)
		setOtherTraceOrderItem(false)
		setSpeakToTheSeller(false)
		setMissingItems(false)
		setTrackingOrderInfoMessage("")
		setTrackingOrderInfoLink("")
		setTrackingOrderInfoNumber("")
		setOrderItemCardSelection(true)
		setChatSelectionContainer(false)
		setMyOrdersSection(false)
		const order_id = e.currentTarget.dataset.order_id
		const order_type = e.currentTarget.dataset.order_type
		setOrderIdData(order_id)
		setOrderTypeData(order_type)
	}

	const showChatMessages = () => {
		setCreateTicketVisible(false)
		setBackThreadsVisible(true)
		setChatSendBoxVisible(true)
	}
	const getChat = (elementId) => {
		getMessages(elementId)
	}
	const handleShowTicketsClick = () => {
		dispatch(supportThreadMessagesMarkRead({
			thread_id: threadUserId,
		}))
		setSocketNewMessageData([])
		setsupportChatData(null)
		scrollTop()
		handleThreadsScroll(true)
		// setsupportChatData(getSupportChatWidgetData)
		setInThread(false)
		setThreadUserId(0)
		setClientDataPage(1)
		setCustomChatMessageBox("")
		setChatSelectedFile([])
		setSupportChatMessages(null)
		setMessageIds([])
		setSupportChatTotalMessages(0)
		setChatMessageContainer(false)
		setBackThreadsVisible(false)
		setChatSendBoxVisible(false)
		setOpenTreadsChatContainer(true)
		setCreateTicketVisible(true)
	}

	const handleCreateTicketClick = () => {
		setCreateTicketVisible(false)
		setOrderItemCardSelection(false)
		setCustomChatPopupIconFooter(false)
		setTrackingOrderInfo(false)
		setMyOrdersSection(false)
		setChatSelectionContainer(true)
		setCreateChatTickets(true)
		setShowTreadsTickets(true)
		setOpenTreadsChatContainer(false)
		setCreateTicketVisible(false)
	}
	const handleShowTreadsTicketsClick = () => {
		setCustomMessageBox("")
		setTrackingOrderInfo(false)
		setBoxDashboredCategoryContainer(false)
		setCreateChatTickets(false)
		setShowTreadsTickets(false)
		setOpenTreadsChatContainer(true)
		setCreateTicketVisible(true)
	}
	const getSupportRecentOrdersList = () => {
		setShowTreadsTickets(false)
		setShowMessagesBack(true)
		setMyOrderStage(1)
		setCustomMessageBox("")
		setTrackingOrderInfoMessage("")
		setTrackingOrderInfoLink("")
		setTrackingOrderInfoNumber("")
		setCustomChatPopupIconFooter(false)
		setOrderItemCardSelection(false)
		setTrackingOrderInfo(false)
		setTrackingOrderInfo(false)
		setChatSelectionContainer(false)
		setMyOrdersSection(true)
		dispatch(getSupportRecentOrdersData({
			'request-from': 'app'
		}))
	}
	const getSupportCategoriesData = () => {
		setTrackMyOrder(false)
		setOrderIdData('')
		setOrderTypeData('')
		setChatSelectionContainer(false)
		setBoxDashboredCategoryContainer(true)
		setSupportCategoriesData(null)
		dispatch(getSupportChatCategoriesData({ limit: -1 }))
		// setCreateChatTickets(false)
		// setShowTreadsTickets(false)
		// setOpenTreadsChatContainer(true)
		// setCreateTicketVisible(true)
	}

	//redirction on click back one step before
	const handleShowMessagesBack = () => {
		setSelectedFile(null)
		setCustomMessageBox("")
		if (myOrderStage === 1) {
			handleCreateTicketClick()
			setShowMessagesBack(false)
		} else if (myOrderStage === 2) {
			setMyOrderStage(1)
			getSupportRecentOrdersList()
		} else if (myOrderStage === 3) {
			setMyOrderStage(2)
			trackingCreateTreadCancel()
		} else {
			handleCreateTicketClick()
			setShowMessagesBack(false)
		}
	}

	const handleFileChange = (e) => {
		setSelectedFile(e.target.files[0])
	}

	const handleChatFileChange = (e) => {
		const files = e.target.files;

		// Create an array to store the selected files
		const selectFiles = [];

		// Check if the total number of selected files exceeds the limit of 3
		if (files.length + chatSelectedFile.length > 3) {
			toast.error('You can not upload more than 3 files!');
			// return; // Prevent further execution if the limit is exceeded
		}

		// If the limit is not exceeded, add the selected files to the array
		selectFiles.push(...Array.from(files).slice(0, 3 - chatSelectedFile.length));

		// // Log the selected files (optional)
		// console.log("Selected Files:", selectFiles);

		// Update the state with the newly selected files
		setChatSelectedFile((prevFiles) => [...prevFiles, ...selectFiles]);
	}

	const handleChatFileButtonClick = () => {
		document.getElementById('chatFileInput').click()
	}

	const handleFileButtonClick = () => {
		document.getElementById('fileInput').click()
	}

	const handleRemove = () => {
		alert('here');
		setSelectedFile(null)
	}

	const handleChatRemove = (index) => {
		Swal.fire({
			text: 'Are you sure you want to remove this image?',
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			customClass: {
				confirmButton: 'btn-primary',
			}
		}).then((result) => {
			// If user clicks "Yes, remove it!", proceed with the removal
			if (result.isConfirmed) {
				const updatedFiles = [...chatSelectedFile];
				updatedFiles.splice(index, 1);
				setChatSelectedFile(updatedFiles);
			}
		});
	}

	const submitChatTreadButton = () => {
		if (customChatMessageBox !== '' || chatSelectedFile.length) {
			const formData = new FormData()
			formData.append('message', customChatMessageBox)
			formData.append('thread_id', threadUserId)
			if (chatSelectedFile.length) {
				if (chatSelectedFile.length <= 3 && chatSelectedFile.length > 0) {
					chatSelectedFile.forEach(file => {
						formData.append("uploadAttachments[]", file)
					})
					formData.append('type', 'upload-attachment')
					formData.delete('message')
					chatSelectedFile.forEach((element, index) => {
						if (index == 0) {
							formData.append('upload_attachment_text[]', customChatMessageBox)
						}
						formData.append('upload_attachment_text[]', [])
					})
					formData.append('request-from', 'app')
					setLoading(true);
					dispatch(createSupportChatThreadsData(formData))
				} else {
					toast.error(fileValidate)
				}
			} else {
				setLoading(true);
				dispatch(createSupportChatThreadsData(formData))
			}
			// setSupportChatMessages(null)
			// setMessageIds([])
			// setClientDataPage(1)
			setCustomChatMessageBox("")
			setChatSelectedFile([])
			setSupportCategoriesData(null)
		} else {
			toast.error(messageValidate)
		}
	}

	const scrollChatToBottom = () => {
		if (chatMessageMainRef.current) {
			chatMessageMainRef.current.scrollTop = chatMessageMainRef.current.scrollHeight
		}
	}

	useEffect(() => {
		scrollChatToBottom()
	}, [supportNewChatSkeletonFlag])
	// isShowMessagesBack
	useEffect(() => {
		if (createSupportChatThreadsStateData) {
			setLoading(false);
			setCreateNewChatMessage(createSupportChatThreadsStateData)
			if (createSupportChatThreadsStateData.length) {
				createSupportChatThreadsStateData.forEach((messageObj) => {
					setMessageIds((prevData) => [
						...prevData,
						messageObj.scm_id,
					])
					let message
					if (messageObj.scm_type === 1) {
						if (messageObj.scm_message !== '' && messageObj.scm_message !== null) {
							message = (
								<>
									<a target="_blank" href={messageObj.additional_data?.link}>
										<i className="fa fa-file-text-o" aria-hidden="true"></i>
									</a>
									<p>{messageObj.scm_message}</p>
								</>
							)
						} else {
							message = (
								<a target="_blank" href={messageObj.additional_data?.link}>
									<i className="fa fa-file-text-o" aria-hidden="true"></i>
								</a>
							)
						}
					} else {
						message = messageObj.scm_message
					}
					var socketMessageObj = {
						to_id: toUserIds,
						mode: notificationMode,
						message: message,
						from_id: currentUserId,
						messageObj: messageObj,
						type: 'new_support_message',
						thread: threadUserId,
					}
					socket.emit('message', socketMessageObj)
				})
			}
		}
	}, [
		createSupportChatThreadsStateData
	])

	const setMessage = (e) => {
		if (e.mode == notificationMode && e && e.thread == threadUserId && e.from_id != currentUserId && e.type == "new_support_message") {
			setSupportChatMessages((prevData) => [
				...prevData,
				...[e.messageObj],
			])
		} else if (e.type == "message_support_read" && e.thread == threadUserId) {
			setSupportChatMessages((prevMessages) => {
				if (prevMessages) {
					return prevMessages.map((message) =>
						e.thread == threadUserId
							? { ...message, is_read: true }
							: message
					);
				} else {
					// Handle the case when prevMessages is null or undefined
					return [];
				}
			});
		}
		console.log('new_support_message', e);
		if (e.thread != threadUserId && (e.type == "new_support_message" || e.type == "thread_recipient_added" || e.type == "thread_recipient_removed")) {
			if (e.messageObj) {
				setPenndingChatCount(0)
				countUnreadMessages()

				updateLastMessage(e, true)
			}
		} else if (e.thread != threadUserId && e.type == "thread_resolved") {
			setPenndingChatCount(0)
			countUnreadMessages()
			updateLastMessage(e, false)
		}
	}
	const updateLastMessage = (e, newMessage) => {
		// get li and remove from the list
		const liElement = document.querySelector(`li[data-enc_id="${e.thread}"]`);

		if (newMessage) {
			if (liElement) {
				const dataDtAttribute = liElement.getAttribute('data-dt');
				if (dataDtAttribute) {
					const dtValues = JSON.parse(dataDtAttribute);
					dtValues.scm_message = e.messageObj.scm_message
					dtValues.last_message.sender_name = e.messageObj.usr_name
					dtValues.unread_count = parseInt(dtValues.unread_count) + 1
					dtValues.lastMessage = e.message
					dtValues.lastMessageDate = e.messageObj.sent_date
					dtValues.last_message.sent_date = e.messageObj.sent_date
					dtValues.enc_id = e.thread

					setSocketNewMessageData(dtValues)
					console.log('updated dtValues', dtValues);
				}
			}
		} else {
			if (liElement) {
				const dataDtAttribute = liElement.getAttribute('data-dt');
				if (dataDtAttribute) {
					const dtValues = JSON.parse(dataDtAttribute);
					dtValues.sct_status = 1
					dtValues.feedback = null
					dtValues.enc_id = e.thread
					setSocketNewMessageData(dtValues)
					console.log('updated dtValues', dtValues);
				}
			}
		}
	}
	useEffect(() => {
		if (supportChatData && socketNewMessageData) {
			const indexToRemove = supportChatData.findIndex(item => item.enc_id === socketNewMessageData.enc_id);

			if (indexToRemove !== -1) {
				const updatedData = [...supportChatData];
				updatedData.splice(indexToRemove, 1);
				updatedData.unshift(socketNewMessageData); // Use unshift with the single element
				setsupportChatData(updatedData);
			}
		}
	}, [socketNewMessageData])

	useEffect(() => {
		const onConnect = () => {
			setPenndingChatCount(0)
			countUnreadMessages()
			socket.emit('online', {
				id: currentUserId
			})
		}

		const onDisconnect = () => {
			socket.off('new_message')
		}

		socket.on('connect', onConnect)

		socket.on('connect_error', (err) => {
			console.error('Connection error:', err)
			socket.off('disconnect', onDisconnect)
		})

		socket.on('new_message', setMessage)

		return () => {
			socket.off('new_message', setMessage)
		}
	}, [threadUserId])
	useEffect(() => {
		if (createNewChatMessage) {
			// getMessages(threadUserId)
			setSupportChatMessages((prevData) => [
				...prevData,
				...createNewChatMessage,
			])
			setMessageIds((prevData) => [
				...prevData,
				createNewChatMessage.scm_id,
			])
			setPrevScrollPosition(null)
		}
	}, [createNewChatMessage])

	const submitNewTreadButton = () => {
		if (customMessageBox !== '') {
			setCurrentThreadsPage(1)
			const formData = new FormData()
			formData.append('uploadAttachment', selectedFile)
			formData.append('message', customMessageBox)
			formData.append('thread_type', selectedLi)
			if (orderIdData) {
				formData.append('order_id', orderIdData)
			}
			if (orderTypeData) {
				formData.append('order_type', orderTypeData)
			}
			formData.append('request-from', 'app')
			for (var key of formData.entries()) {
				console.log(key[0] + ', ' + key[1])
			}
			dispatch(createSupportThreadsData(formData))
		} else {
			toast.error(messageValidate)
		}
	}

	// count the number of unread messages
	const countUnreadMessages = () => {
		dispatch(supportChatTotalUnreadCountData())
	}

	useEffect(() => {
		if (supportTotalUnreadCountData || typeof supportTotalUnreadCountData === 'number') {
			setPenndingChatCount(supportTotalUnreadCountData);
		}
	}, [supportTotalUnreadCountData])

	const trackingCreateTread = () => {
		setShowMessagesBack(false)
		submitNewTreadButton()
	}

	const missingTraceOrderItem = () => {
		setMyOrdersMessageSection(1)
		handleGetOrderTraceData()
		// setCustomMessageBox(missingItemsMessage)
		setMissingItems(true)
		setTrackMyOrder(false)
		setSpeakToTheSeller(false)
		setOtherTraceOrderItem(false)
		setCustomChatPopupIconFooter(true)
		setMessageBoxThreadShow(true)
	}
	const speakSellerTraceOrderItem = () => {
		setMyOrdersMessageSection(2)
		handleGetOrderTraceData()
		// setCustomMessageBox(speakToSellerMessage)
		setSpeakToTheSeller(true)
		setTrackMyOrder(false)
		setMissingItems(false)
		setOtherTraceOrderItem(false)
		setCustomChatPopupIconFooter(true)
		setMessageBoxThreadShow(true)
	}
	const otherTraceOrderItemSend = () => {
		setCustomMessageBox("")
		setMyOrdersMessageSection(3)
		setOtherTraceOrderItem(true)
		setTrackMyOrder(false)
		setSpeakToTheSeller(false)
		setMissingItems(false)
		setCustomChatPopupIconFooter(true)
		setMessageBoxThreadShow(true)
	}

	useEffect(() => {
		if (Cookies.get("faces_access_token")) {
			setIsUserLoggedIn(true)
		}
	}, [])
	const handleSend = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		// Reset previous errors
		let validationErrors = { userName: '', email: '' };
		let valid = true;

		// Check if userName is empty
		if (!userName.trim()) {
			validationErrors.userName = 'This field is required';
			valid = false;
		}

		// Check if email is empty or invalid
		if (!email.trim()) {
			validationErrors.email = 'This field is required';
			valid = false;
		} else if (!validateEmail(email)) {
			validationErrors.email = 'please enter a valid email format';
			valid = false;
		}

		// If validation fails, set errors and return
		if (!valid) {
			setErrors(validationErrors);
			return;
		}
		try {
			const response = await axios.post(`${appBaseUrl}/submit-guest-inquiry-check`, {
				name: userName,
				email: email
			});

			console.log('Response:', response.data);
			if (response.data.success) {
				console.log("hello world")
				setIsUserLoggedIn(true)
			}

			setUserName('');
			setEmail('');
			setErrors({ userName: '', email: '' });
		} catch (error) {
			console.error('Error submitting guest inquiry:', error);
		}
	};
	return (
		<div>
			<button type="button" className="open-button rounded-circle" onClick={() => OpenChatPopup()}>
				{/* <i className="fa fa-close" style={{fontSize: '2em'}}></i> */}
				<i className="fa fa-comment" style={{ fontSize: '2em' }}></i>
				{!penndingChatShow &&
					(penndingChatCount ? (
						<p className="chat-unread-count-support">
							<span className="chat-unread-count">{penndingChatCount}</span>
						</p>
					) : null)
				}
				{/* <span className="cus-message-count">2</span> */}
			</button>
			<div className={`chat-popup loggedin-chat-popup ${penndingChatShow ? 'open' : 'closed'}`} id="myForm" ref={formRef}>
				<div className="form-container chat-main-width">
					{isUserLoggedIn === false
						?

						<div className="row w-100 m-auto ">
							<div className="col-md-12">
								<div className="chat-popup-title">
									<h1>Need Help?</h1>
								</div>
							</div>
							<div className="customGuestUserInput">
								<form>
									<div>
										<input
											type="text"
											placeholder="Name"
											value={userName}
											name="userName"
											onChange={(e) => {
												const value = e.target.value;
												const containsInvalidChars = /[^a-zA-Z\s]/;
												if (containsInvalidChars.test(value)) {
													return;
												}
												setUserName(value);
												if (value === "") {
													setErrors((prev) => ({
														...prev,
														userName: 'This field is required'
													}));
													return;
												}
												setErrors((prev) => ({
													...prev,
													userName: ''
												}));
											}}
										/>
										{errors.userName && <p style={{ color: 'red' }}>{errors.userName}</p>}
									</div>

									<div>
										<input
											type="text"
											placeholder="Contact Email"
											name="email"
											value={email}
											onChange={(e) => {
												setEmail(e.target.value);
												if (e.target.value === "") {
													setErrors((prev) => ({
														...prev,
														email: 'This field is required'
													}));
													return;
												}
												const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
												if (!emailRegex.test(e.target.value)) {
													setErrors((prev) => ({
														...prev,
														email: 'Please enter a valid email address.'
													}));
													return;
												}
												setErrors({ ...errors, email: '' }); // Clear error on change
											}}
										/>
										{errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
									</div>

									<button type="submit" className="blueBtn" onClick={(e) => handleSend(e)}>
										SEND
									</button>
								</form>
							</div>
						</div>

						:
						<>
							<div className="row w-100 m-auto ">
								<div className="col-md-12">
									<div className="d-flex align-items-center justify-content-between pb-2 ">
										<button className="chat-header-btn back-selection back-selection-arrow back-button " id="backThreads" style={{ display: isBackThreadsVisible ? 'block' : 'none' }} onClick={handleShowTicketsClick}>
											<svg className="showtic-back-arrow" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M2.72751 6.59992L14.8479 6.59992C15.0208 6.59992 15.1867 6.53672 15.309 6.42421C15.4313 6.3117 15.5 6.15911 15.5 6C15.5 5.84089 15.4313 5.6883 15.309 5.57579C15.1867 5.46328 15.0208 5.40008 14.8479 5.40008L2.72751 5.40008L7.4841 1.02543C7.60655 0.912778 7.67535 0.759993 7.67535 0.600681C7.67535 0.441371 7.60655 0.288585 7.4841 0.175935C7.36165 0.0632849 7.19557 0 7.0224 0C6.84923 0 6.68315 0.0632849 6.56069 0.175935L0.691586 5.57525C0.630856 5.63098 0.582674 5.69718 0.549798 5.77007C0.516923 5.84295 0.500001 5.92109 0.500001 6C0.500001 6.07891 0.516923 6.15705 0.549798 6.22993C0.582674 6.30282 0.630856 6.36902 0.691586 6.42475L6.56069 11.8241C6.68315 11.9367 6.84923 12 7.0224 12C7.19557 12 7.36165 11.9367 7.4841 11.8241C7.60655 11.7114 7.67535 11.5586 7.67535 11.3993C7.67535 11.24 7.60655 11.0872 7.4841 10.9746L2.72751 6.59992Z" fill="#1658F7"></path>
											</svg>
										</button>
										<button className="btn chat-header-btn btn-outline-primary" id="showTickets" style={{ display: isShowTreadsTickets ? 'block' : 'none' }} onClick={handleShowTreadsTicketsClick}> My Tickets </button>
										<button className="chat-header-btn back-selection back-selection-arrow back-button " id="messagesBack" data-type="orders" style={{ display: isShowMessagesBack ? 'block' : 'none' }} onClick={handleShowMessagesBack}>
											<svg className="showtic-back-arrow" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M2.72751 6.59992L14.8479 6.59992C15.0208 6.59992 15.1867 6.53672 15.309 6.42421C15.4313 6.3117 15.5 6.15911 15.5 6C15.5 5.84089 15.4313 5.6883 15.309 5.57579C15.1867 5.46328 15.0208 5.40008 14.8479 5.40008L2.72751 5.40008L7.4841 1.02543C7.60655 0.912778 7.67535 0.759993 7.67535 0.600681C7.67535 0.441371 7.60655 0.288585 7.4841 0.175935C7.36165 0.0632849 7.19557 0 7.0224 0C6.84923 0 6.68315 0.0632849 6.56069 0.175935L0.691586 5.57525C0.630856 5.63098 0.582674 5.69718 0.549798 5.77007C0.516923 5.84295 0.500001 5.92109 0.500001 6C0.500001 6.07891 0.516923 6.15705 0.549798 6.22993C0.582674 6.30282 0.630856 6.36902 0.691586 6.42475L6.56069 11.8241C6.68315 11.9367 6.84923 12 7.0224 12C7.19557 12 7.36165 11.9367 7.4841 11.8241C7.60655 11.7114 7.67535 11.5586 7.67535 11.3993C7.67535 11.24 7.60655 11.0872 7.4841 10.9746L2.72751 6.59992Z" fill="black"></path>
											</svg>
										</button>
										{isCreateTicketVisible &&
											<button className="btn chat-header-btn btn-outline-primary font-inter d-flex justify-content-center" id="createTicket" onClick={handleCreateTicketClick} >
												<img className="expand-btn" src={createTicket} alt="Create Ticket" />
												Create Tickets
											</button>
										}
										<button
											className="btn chat-header-btn btn-outline-primary go-to-support-chat"
											onClick={goToSupportChat}
										>
											<img className="expand-btn" src={expend} alt="Expend Chat" />
										</button>
									</div>
									<div className="chat-popup-title">
										<h1>Customer Support</h1>
									</div>
								</div>
							</div>



							<div
								ref={mainTreadListRef}
								className="hm-custom-scrollbar row w-100 p-0 m-auto custom-threads-popup-contain-detils"
								style={{ display: openTreadsChatContainer ? 'block' : 'none' }}
								// onScroll={handleThreadsScroll}
								id="threadsContainer"
							>
								{supportChatData ? (
									<ul className="hm-custom-scrollbar list-group chat-threads-container">

										<InfiniteScroll
											style={{ overflowY: "auto", overflowX: 'hidden' }}
											className=""
											dataLength={parseInt(supportChatData.length)}
											hasMore={parseInt(supportChatDataTotalCount) > parseInt(supportChatData.length)}
											// hasMore={true}
											loader={supportChatDataSkeletonFlag && <SupportThreadListSkeleton />}
											next={() => {
												handleThreadsScroll();
											}}
											scrollableTarget="threadsContainer"
										>
											<SupportThreadElement
												socketNewMessageList={false}
												listItemRefs={listItemRefs}
												supportChatData={supportChatData}
												openTreadChat={openTreadChat}
												submitFeedback={submitFeedback}
											/>
										</InfiniteScroll>
									</ul>
								) : supportChatDataSkeletonFlag ? (<SupportThreadListSkeleton />) : null
								}


							</div>
							<ul className="hm-custom-scrollbar overflow-auto w-100 m-auto custom-chat-popup-contain-detils create-ticket-selection" style={{ display: createChatTickets ? 'block' : 'none' }}>
								<div id="create_ticket_window" className="create-ticket-selection">
									<div className="chat-selection-container" style={{ display: chatSelectionContainer ? 'block' : 'none' }}>
										<div className="row m-auto justify-content-center align-items-center chat-selection create-ticket-selection">
											<div className="col-md-12">
												<div className="gray-box text-black my-order-selection" onClick={getSupportRecentOrdersList}>
													<img src={myOrders} alt="My Orders Image" />
													<p>My orders</p>
												</div>
											</div>
											<div className="col-md-12">
												<div className="gray-box text-black other-selection" id="support-categories-list" onClick={getSupportCategoriesData}>
													<img src={others} alt="Something else Image" />
													<p>Something else?</p>
												</div>
											</div>
										</div>
									</div>

									<div className="chat-selection-window col-md-12" style={{ display: orderItemCardSelection ? 'block' : 'none' }}>
										<div className="row justify-content-center align-items-center chat-option track-order m-auto">
											<ul className="list-group">
												<div className="col-md-12 mb-2 tracking-order-category">
													<li
														className={trackMyOrder ? " trace-order-item text-black list-selected" : " trace-order-item text-black"}
														data-type="track" onClick={traceOrderItem}>
														<div className="d-flex align-items-center h-100 w-100">
															<div className="img-box-container d-flex h-100 justify-content-center align-items-center">
																<img src={location} className="" alt="" />
															</div>
															<div className="name-category-list">
																<span className="text-black font-weight-normal" style={{ fontSize: '12px' }}>
																	<p className="text-black" style={{ fontSize: '12px' }}> Track my order

																	</p>
																</span>
															</div>
														</div>
													</li>
												</div>
												<div className="col-md-12 mb-2 tracking-order-category">
													<li
														className={missingItems ? " trace-order-item text-black list-selected" : " trace-order-item text-black"}
														data-type="missing" onClick={missingTraceOrderItem}>
														<div className="d-flex align-items-center h-100 w-100">
															<div className="img-box-container d-flex h-100 justify-content-center align-items-center">
																<img src={missing} alt="" />
															</div>
															<div className="name-category-list">
																<span className="text-black font-weight-normal" style={{ fontSize: '12px' }}>
																	<p className="text-black" style={{ fontSize: '12px' }}> Missing Items

																	</p>
																</span>
															</div>
														</div>
													</li>
												</div>
												<div className="col-md-12 mb-2 tracking-order-category">
													<li
														className={speakToTheSeller ? " trace-order-item text-black list-selected" : " trace-order-item text-black"}
														data-type="speak" onClick={speakSellerTraceOrderItem}>
														<div className="d-flex align-items-center h-100 w-100">
															<div className="img-box-container d-flex h-100 justify-content-center align-items-center">
																<img src={talk} className="" alt="" />
															</div>
															<div className="name-category-list">
																<span className="text-black font-weight-normal" style={{ fontSize: '12px' }}>
																	<p className="text-black" style={{ fontSize: '12px' }}> Speak to the Seller/Pharmacy

																	</p>
																</span>
															</div>
														</div>
													</li>
												</div>
												<div className="col-md-12 mb-2 tracking-order-category">
													<li
														className={otherTraceOrderItem ? " trace-order-item text-black list-selected" : " trace-order-item text-black"}
														data-type="other" onClick={otherTraceOrderItemSend}>
														<div className="d-flex align-items-center h-100 w-100">
															<div className="img-box-container d-flex h-100 justify-content-center align-items-center">
																<img src={otherImg} className="" alt="" />
															</div>
															<div className="name-category-list">
																<span className="text-black font-weight-normal" style={{ fontSize: '12px' }}>
																	<p className="text-black" style={{ fontSize: '12px' }}> Other

																	</p>
																</span>
															</div>
														</div>
													</li>
												</div>
											</ul>
										</div>
										<div className="chat-option others-section" style={{ display: "none" }}>
											Other Section
										</div>
									</div>

									<div className="tracking-order-info" style={{ display: trackingOrderInfo ? 'block' : 'none' }}>
										<div className="new-div">
											{trackingOrderInfoMessage ? (
												<div className="chat-bubble chat-bubble-tracking">
													<p className="chat-bubble-message">{trackingOrderInfoMessage}</p>
													{trackingOrderInfoLink != "" && trackingOrderInfoNumber != "" ? (
														<a target="_blank" href={trackingOrderInfoLink}> Click Here </a>
													) : (
														<div className="tracking-buttons d-flex justify-content-end"><button className="btn btn-primary btn-yes tracking-yes-buttons" onClick={trackingCreateTread}>Yes</button>
															<button className="btn btn-secondary btn-no tracking-no-buttons" onClick={trackingCreateTreadCancel}>No</button></div>
													)}
												</div>
											) : (
												<div style={{ padding: '5px 10px' }}>
													<Skeleton height={70} />
												</div>
											)}
										</div>
									</div>

									<div className="row chat-option my-orders-section m-auto" style={{ display: myOrdersSection ? 'block' : 'none' }}>
										<div className="my-order-list col-md-12" >
											<ul className="list-group recent-order-list">
												{myRecentOrdersList ? (
													myRecentOrdersList.length > 0 ? (
														myRecentOrdersList.map((order, key) => (
															<li key={key} className="list-group-item w-100 listing-element-container text-black order-item-card mb-2 border-0 cursor-pointer" data-order_id={order.order_id} data-order_type={order.order_type} onClick={orderItemCardData}>
																<div className="row align-items-center hm-span-10">
																	<div className="col-11 list-view-order-date-time m-0">
																		<div className="ps-3 d-flex order-date-div trimmed-order-product justify-content-between">
																			<div className="col-auto">
																				<span className="order-tag d-block mb-1">Order id : <span className="text-black">{order.order_id}</span></span>
																			</div>
																			<div className="col-auto ml-auto text-right">
																				<span className="text-right date-tag d-block mb-1">Date : <span className="text-black">{order.time}</span></span>
																			</div>
																		</div>

																		{order.products.map((product, key) => (
																			<div key={key} className="ps-3 row trimmed-order-product justify-content-between">
																				<div className="col-auto">
																					<span className="trimmed-text order-product d-block mb-1">{product.product_name}</span>
																				</div>
																				<div className="col-auto ml-auto text-right">
																					<span className="order-product-price text-black d-block mb-1">£ {product.product_price}</span>
																				</div>
																			</div>
																		))}

																		{order.products.length > 1 && (
																			<a href="#" className="text-primary more-products-link trimmed-order-product">
																				({order.products.length} Products)
																			</a>
																		)}
																	</div>

																	<div className="col-1 text-right">
																		{/* Font Awesome icon */}
																		<i className="fa fa-chevron-right"></i>
																	</div>
																</div>
															</li>

														))
													) : (
														<figure className="text-center">
															<figcaption className="caption">No Orders Found</figcaption>
														</figure>
													)
												) : (
													// <div style={{padding : '0'}}>
													//     <Skeleton height={60} />
													//     <Skeleton height={60} />
													//     <Skeleton height={60} />
													//     <Skeleton height={60} />
													//     <Skeleton height={60} />
													// </div>
													<>
														{
															[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => (
																<div style={{ padding: '5px 10px' }}>
																	<div className="skeltenWrapper">
																		<div className="row">
																			<div className="ps-3 col-sm-12 ps-0">
																				<div className="row">
																					<div className="col-6">
																						<Skeleton height={19} />
																					</div>
																					<div className="col-6">
																						<Skeleton height={19} />
																					</div>
																				</div>
																				<div className="row">
																					<div className="col-6">
																						<Skeleton height={19} width={80} />
																					</div>
																					<div className="col-6 text-end">
																						<Skeleton height={19} width={80} />
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															))
														}
													</>
												)}
											</ul>
										</div>
									</div>
									<div
										className="custom-box-dashbored-category-container row m-auto"
										style={{
											display: boxDashboredCategoryContainer ? 'block' : 'none',
											paddingBottom: customChatPopupIconFooter ? '80px' : '0',
										}}
									>
										{supportCategoriesData ? (
											supportCategoriesData.length > 0 ? (
												supportCategoriesData.map(category => (
													<div className="col-md-12 mb-2 custom-box-dashbored-category" key={category.scc_id}>
														<li data-id="12" data-tooltip-id={`support-categories-${category.scc_id}`} data-tooltip-content={category.scc_name.length > 27 ? category.scc_name : undefined} className={selectedLi == category.scc_id ? " list-group-item cat-item text-black list-selected hm-border-left" : " list-group-item cat-item text-black hm-border-left"} onClick={(e) => {
															setSelectedLi(category.scc_id)
														}}>
															<div className="d-flex align-items-center h-100 w-100">
																<div className="img-box-container d-flex h-100 justify-content-center align-items-center">
																	<img className="" src={category.image_url} alt="" />
																</div>
																<div className="name-category-list">
																	<span className="text-black font-weight-normal" style={{ fontSize: '12px' }}>
																		<span className="text-black" style={{ fontSize: '12px' }}>{category.scc_name}</span>
																	</span>
																</div>
															</div>
														</li>
														<Tooltip id={`support-categories-${category.scc_id}`} style={{ fontSize: '12px' }} />
													</div>
												))
											) : (
												<figure className="text-center">
													<figcaption className="caption">No Categories Found</figcaption>
												</figure>
											)
										) : (
											// <div style={{padding : '5px 10px'}}>
											//     <Skeleton height={50} />
											//     <Skeleton height={50} />
											//     <Skeleton height={50} />
											//     <Skeleton height={50} />
											//     <Skeleton height={50} />
											//     <Skeleton height={50} />
											// </div>
											<>
												{
													[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => (
														<div style={{ padding: '5px 20px' }}>
															<div className="skeltenWrapper">
																<div className="row p-auto">
																	<div className="col-3 px-0 pe-2">
																		<Skeleton height={50} />
																	</div>
																	<div className="col-9 pe-0 ps-1">
																		<Skeleton height={50} />
																	</div>
																</div>
															</div>
														</div>
													))
												}
											</>
										)}
									</div>
								</div>
							</ul>
							<div className="chat-zone" style={{ display: chatMessageContainer ? 'block' : 'none' }}>
								<div className="custom-jump-latest-message" style={{ display: 'none' }}>
									<a href="" className="scrolldown" style={{ display: 'none' }}>
										<center> <span className="badge badge-dark custom-jump-latest-msg">jump to latest message</span></center>
									</a>
								</div>
								<div
									ref={chatMessageMainRef}
									className="hm-custom-scrollbar chat-message-main"
									id="msg-listing-elements-container-section"
									style={
										{
											height: '39vh',
											marginBottom: '40px',
											paddingBottom: resolvedThread ? (chatSelectedFile && chatSelectedFile.length > 0 ? '120px' : '50px') : '0px',
											transition: 'padding-bottom 0.5s ease-in-out 0s'
										}
									}
									onScroll={handleScroll}
								>   {supportChatMessageSkeletonFlag ? [0, 1].map((val, index) => (
									<div key={index} style={{ padding: '5px 10px' }}>
										<div className="skeltenWrapper">
											<div className="row align-items-start">
												<div className="col-12">
													<div className="col-sm-12">
														<Skeleton height={100} borderRadius="45px" />
													</div>
												</div>
											</div>
										</div>
									</div>
								)) : null}
									{supportChatMessages ? (
										supportChatMessages.length > 0 ? (
											supportChatMessages.map((item, key) => {
												const momentCurrentDate = moment()
												const currentDate = momentCurrentDate.format('DD-MM-YYYY')
												let created_date = (item.created_at != undefined ? item.created_at : currentDate)
												let formattedDate = moment(created_date, 'YYYY-MM-DD HH:mm:ss').format('Do MMMM')
												let messageDateComponent = null
												if (preDate !== '') {
													if (moment(item.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY') == currentDate && !todayDate) {
														messageDateComponent = (
															<div key={`today-${item.scm_id}`} className="messagelist created-item-date" data-list-date={formattedDate}>
																<p className="font-inter hm-font-size-14 fw-light">Today</p>
															</div>
														)
														todayDate = true
													} else if (moment(item.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY') != moment(preDate, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')) {
														messageDateComponent = (
															<div key={`date-${item.scm_id}`} className="messagelist created-item-date" data-list-date={formattedDate}>
																<p className="font-inter hm-font-size-14 fw-light">{formattedDate}</p>
															</div>
														)
													}
												} else {
													if (moment(item.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY') == currentDate && !todayDate) {
														messageDateComponent = (
															<div key={`today-${item.scm_id}`} className="messagelist created-item-date" data-list-date={currentDate}>
																<p className="font-inter hm-font-size-14 fw-light">Today</p>
															</div>
														)
														todayDate = true
													} else {
														messageDateComponent = (
															<div key={`date-${item.scm_id}`} className="messagelist created-item-date" data-list-date={currentDate}>
																<p className="font-inter hm-font-size-14 fw-light">{formattedDate}</p>
															</div>
														)
													}
												}

												preDate = item.created_at
												var ext = ""
												if (item.additional_data?.link) {
													ext = item.additional_data?.link.split('.').pop()
												}
												return (
													<React.Fragment key={key}>
														{messageDateComponent}
														{/* Render your message component here */}
														<div
															className={`messagelist ${parseInt(selectedTreadUserId) == parseInt(item.scm_from_id) ? "messagelist-right" : "messagelist-left"
																}`}
														>
															{item.scm_type === 0 || item.scm_type === 1 ? (
																<>
																	{parseInt(item.scm_from_id) != parseInt(selectedTreadUserId) && (
																		<div className="chatimg other-user-image-container">
																			{item !== null && (item.usr_profile || item.usr_profile_img) ? (
																				<img
																					className="user_profile_image"
																					src={item.usr_profile ? item.usr_profile : item.usr_profile_img}
																					alt="User Profile"
																				/>
																			) : (
																				<img
																					className="user_profile_image"
																					src={`${process.env.REACT_APP_WEB_BASE_URL}/images/default-user-image.jpg`}
																					alt="Default User Image"
																				/>
																			)}
																		</div>
																	)}

																	<div
																		className={`chatcontent ${parseInt(item.scm_from_id) == parseInt(selectedTreadUserId)
																				? "self-message-container"
																				: "other-user-message-container"
																			}`}
																	>
																		<div
																			className={`bubble-new-chat ${parseInt(selectedTreadUserId) == parseInt(item.scm_from_id) ? "btm-right" : "btm-left"
																				} ${item.scm_type !== 0 && item.scm_type !== 2 ? "link-message-container" : ""}`}
																			style={{
																				backgroundColor: parseInt(selectedTreadUserId) == parseInt(item.scm_from_id) ? "#5b64e3" : "#232b9a",
																			}}
																		>
																			{parseInt(item.scm_from_id) == parseInt(selectedTreadUserId) ? (
																				<p className="hm-font-size-14 font-inter message-sender-name ">You</p>
																			) : (
																				<p className="hm-font-size-14 font-inter message-sender-name ">{item.usr_name || ""}</p>
																			)}
																			<div
																				className="talktext p-3"
																				data-unread-users={item.unread_by ? item.unread_by.join(",") : ""}
																			>
																				{item.scm_type !== 0 && item.scm_type !== 2 ? (
																					<a href={item.additional_data?.link} target="_blank" className="messge-link">
																						{item.additional_data?.link && (
																							<>
																								{["png", "jpg", "jpeg"].includes(
																									ext
																								) ? (
																									<p>
																										<img
																											className="mx-2 chat-img-preview"
																											src={item.additional_data?.link}
																											alt=""
																											style={{ width: "50px", height: "50px" }}
																										/>
																									</p>
																								) : (
																									<p>
																										<i className="fa fa-file-text-o" aria-hidden="true"></i>
																									</p>
																								)}
																							</>
																						)}
																						<p>{item.scm_message}</p>
																					</a>
																				) : (
																					<p>{item.scm_message}</p>
																				)}
																				<p className="hm-cus-font-11 font-inter new-date-text date-text">{item.list_date}</p>
																				{parseInt(item.scm_from_id) == parseInt(selectedTreadUserId) && (
																					<div className="text-right text-white text-read" title="Read">
																						<img
																							className="fa-check-square"
																							src={
																								item.is_read
																									? `${doubleTick}`
																									: `${singleTick}`
																							}
																							alt=""
																						/>
																					</div>
																				)}
																			</div>
																		</div>
																	</div>

																	{parseInt(item.scm_from_id) == parseInt(selectedTreadUserId) && (
																		<div className="chatimg self-image-container">
																			{item !== null && (item.usr_profile || item.usr_profile_img) ? (
																				<img
																					className="user_profile_image"
																					src={item.usr_profile ? item.usr_profile : item.usr_profile_img}
																					alt="User Profile"
																				/>
																			) : (
																				<img
																					className="user_profile_image"
																					src={`${process.env.REACT_APP_WEB_BASE_URL}/images/default-user-image.jpg`}
																					alt="Default User Image"
																				/>
																			)}
																		</div>
																	)}
																</>
															) : item.scm_type === 2 ? (
																<p className="h6 d-flex justify-content-center w-100 text-primary support-common-message">
																	{item.scm_message}
																</p>
															) : null}
														</div>
													</React.Fragment>
												)
											})
										) : (
											<figure className="text-center">
												<figcaption className="caption">No Chat Messages</figcaption>
											</figure>
										)
									) : (
										// <div style={{padding : '5px 10px'}}>
										//     <Skeleton height={70} />
										//     <Skeleton height={70} />
										//     <Skeleton height={70} />
										//     <Skeleton height={70} />
										//     <Skeleton height={70} />
										// </div>
										<>
											{
												[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => (
													<div style={{ padding: '5px 10px' }}>
														<div className="skeltenWrapper">
															<div className="row align-items-start">
																{/* <div className="col-2 pe-0">
                                                            <div className="skelImage">
                                                            <Skeleton
                                                                circle
                                                                height="20px"
                                                                containerClassName="avatar-skeleton"
                                                            />
                                                            </div>
                                                        </div> */}
																<div className="col-12">
																	<div className="col-sm-12">
																		<Skeleton height={100} borderRadius="45px" />
																	</div>
																</div>
															</div>
														</div>
													</div>
												))
											}
										</>
									)}
									{supportNewChatSkeletonFlag ? (
										<div style={{ padding: '5px 10px' }}>
											<div className="skeltenWrapper">
												<div className="row align-items-start">
													<div className="col-12">
														<div className="col-sm-12">
															<Skeleton height={100} borderRadius="45px" />
														</div>
													</div>
												</div>
											</div>
										</div>
									) : null}
								</div>
								<div className="chatsend mt-3">
									<div
										id="fvg"
										className="row mx-0 align-items-center justify-content-center custom-chat-popup-icon-footer"
										style={{ display: chatSendMessagePreviews ? '' : 'none' }}
									>
										<div className="col-md-12 preview-thread-div form-group upload-preview">
											{chatSelectedFile && (
												<div className="previews custom-chat-message-previews" id="upload_thread_attachment_preview">
													{chatSelectedFile.map((file, index) => (
														<div key={index} id="" className="preview-div generic-preview upload_consent_form_preview_new">
															<img
																src={URL.createObjectURL(file)}
																alt={`File ${index + 1}`}
																style={{ maxWidth: '100px', maxHeight: '100px' }}
																width="100px"
																height="100px"
																className="ImgCssClass"
																onClick={() => handleImageClick(index)}
															/>
															<button className="remove-img-class" onClick={() => handleChatRemove(index)}>x</button>
														</div>
													))}
												</div>
											)}
										</div>

										<div className="col-md-8 col-8 message-box message-box-thread custom-message-box-thread">
											<textarea className="form-group" id="messageChatBox" placeholder="Write a message" style={{ overflowX: 'hidden', overflowWrap: 'break-word', display: 'block' }} value={customChatMessageBox} onChange={(e) => setCustomChatMessageBox(e.target.value)} />
										</div>
										<div className="col-auto  px-0 text-left message-box-thread ">
											<div className="dropup p-0 ">
												<input
													id="chatFileInput"
													type="file"
													accept="image/*"
													multiple
													style={{ display: 'none' }}
													onChange={handleChatFileChange}
												/>
												{!loading ? (
													// Render the button when not loading
													<button
														data-accepted-files="image/*"
														id="upload-thread-attachment"
														data-id="files"
														className="btn btnroundicon"
														type="button"
														onClick={handleChatFileButtonClick}
													>
														<img src={paperClip} alt="" width="22px" height="22px" />
													</button>
												) : (
													<div className="skelImage ms-2">
														<Skeleton
															circle
															width="32px"
															height="32px"
															padding="4px"
															containerClassName="avatar-skeleton"
														/>
													</div>
												)}
											</div>
										</div>
										<div className="col-auto  px-0 align-self-center  message-box-thread">
											<div className="dropup p-0 ">
												{!loading ? (
													<button id="threadChatCreate" className="btn btnroundicon" type="button"
														onClick={() => submitChatTreadButton()}>
														<img src={sendPopup} alt="" width="22px" height="22px" />
													</button>
												) : (
													<div className="skelImage ms-2">
														<Skeleton
															circle
															width="32px"
															height="32px"
															padding="4px"
															containerClassName="avatar-skeleton"
														/>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								<div class="row my-2 custom-chat-wight-feedback-btn" style={{ justifyContent: 'space-evenly', padding: submitFeedbackButton ? '25px 0' : '0' }}>
									<button
										class="col-4 btn btnredsm"
										id="re-open"
										data-thread_id={feedbackThreadId}
										data-in_thread='1'
										style={{ display: reopenButton ? 'block' : 'none' }}
										onClick={reOpenSupportThread}
									>Re-open</button>
									<button
										class="col-6 btn btnredsm"
										id="submit-feedback"
										data-thread_id={feedbackThreadId}
										data-profile_img={feedbackProfileImg}
										data-in_thread='1'
										style={{ display: submitFeedbackButton ? 'block' : 'none' }}
										onClick={submitFeedback}
									>Submit Feedback</button>
								</div>
							</div>

							<div id="fvg" className="row mx-0 align-items-center justify-content-center custom-chat-popup-icon-footer" style={{ display: customChatPopupIconFooter ? '' : 'none' }}>
								<div className="col-md-12 preview-thread-div form-group upload-preview">
									{selectedFile && (
										<div className="previews custom-chat-message-previews" id="upload_thread_attachment_preview">
											<div id="" className="preview-div generic-preview upload_consent_form_preview_new">
												<img
													src={URL.createObjectURL(selectedFile)}
													alt="Selected"
													width="100px"
													height="100px"
													className="ImgCssClass"
												/>
												<button className="remove-img-class" onClick={handleRemove}>x</button>
											</div>
										</div>
									)}
								</div>

								<div className="col-md-8 col-8 message-box message-box-thread custom-message-box-thread" style={{ display: messageBoxThreadShow ? 'block' : 'none' }}>
									<textarea className="form-group hm-outline-none hm-resize-none font-inter" id="messageBox" placeholder="Write a message" style={{ overflowX: 'hidden', overflowWrap: 'break-word', display: 'block' }} value={customMessageBox} onChange={(e) => setCustomMessageBox(e.target.value)} />
								</div>
								<div className="col-auto  px-0 text-left message-box-thread " style={{ display: messageBoxThreadShow ? 'block' : 'none' }}>
									<div className="dropup p-0 ">
										<input
											id="fileInput"
											type="file"
											accept="image/*"
											style={{ display: 'none' }}
											onChange={handleFileChange}
										/>
										<button data-accepted-files="image/*" id="upload-thread-attachment" data-id="files" className="btn btnroundicon" type="button" onClick={handleFileButtonClick}>
											<img src={paperClip} alt="" width="22px" height="22px" />
										</button>
									</div>
								</div>
								<div className="col-auto  px-0 align-self-center  message-box-thread" style={{ display: messageBoxThreadShow ? 'block' : 'none' }}>
									<div className="dropup p-0 ">
										<button id="chatCreate" className="btn btnroundicon" type="button"
											onClick={() => submitNewTreadButton()}>
											<img src={sendPopup} alt="" width="22px" height="22px" />
										</button>
									</div>
								</div>
							</div>

						</>}
				</div>
			</div>
			<CustomModal
				modalOpen={addReplyModal}
				setModalOpen={setAddReplyModal}
				modaltitle={"Share feedback to resolved ticket."}
				type={'review-supoort-ticket'}
				modalBody={
					<>
						<div className='review-card my-2'>
							<div className='card-content-one d-flex justify-content-center'>
								<div>
									<div>
										<img
											src={feedbackProfileImg}
											className="rounded-circle profile-pic thread-list-user-image"
											alt="User Profile"
											style={{ width: '50px', height: '50px' }}
										/>
									</div>
								</div>
							</div>
							<div className='mt-3 d-flex justify-content-center'>
								<h6>Submit Rating</h6>
							</div>
							<div className='mb-3 d-flex justify-content-center'>
								<Rating
									ratingValue={rating}
									size={30} // optional, default is 20
									transition // optional, default is `false`
									fillColor="gold" // optional, default is "rgb(203, 211, 227)"
									emptyColor="lightgray" // optional, default is "rgb(90, 90, 90)"
									onClick={handleRating}
								/>
							</div>
						</div>
						<div className="add-reply-div add-suport-reply-div d-flex justify-content-center">
							<form onSubmit={handleAddReviewSubmit} className='d-flex flex-column'>
								{addReplyToBePostError && <span className='text-danger'>{addReplyToBePostError}</span>}
								<button className="add-reply-submit-btn" type='submit'>
									<div className='d-flex justify-content-center align-items-center gap-2  '>
										Submit
									</div>
								</button>
							</form>
						</div>
					</>
				}
			/>
			<CustomModal
				modalOpen={reOpenDoneModal}
				setModalOpen={setReOpenDoneModal}
				modaltitle={""}
				type={'review-supoort-ticket'}
				modalBody={
					<>
						<div className='review-card my-2'>
							<div className='card-content-one d-flex justify-content-center'>
								<div>
									<div>
										<img
											src={reOpenDoneImg}
											className="rounded-circle profile-pic thread-list-user-image"
											alt="User Profile"
											style={{ width: '50px', height: '50px' }}
										/>
									</div>
								</div>
							</div>
							<div className='mt-3 d-flex justify-content-center'>
								<h6>Are you sure you want to Re-Open the Ticket?</h6>
							</div>
						</div>
						<div className="add-reply-div add-suport-reply-div d-flex justify-content-center">

							<button
								className="add-reply-submit-btn"
								type='button'
								onClick={handleReOpenDone}
							>
								<div className='d-flex justify-content-center align-items-center gap-2'>
									YES
								</div>
							</button>
							<button
								className="add-reply-submit-btn"
								type='button'
								onClick={() => setReOpenDoneModal(false)}
							>
								<div className='d-flex justify-content-center align-items-center gap-2'>
									CANCEL
								</div>
							</button>
						</div>
					</>
				}
			/>
			<CustomModal
				modalOpen={imagePreviewModal}
				setModalOpen={setImagePreviewModal}
				modaltitle={""}
				type={'review-supoort-ticket'}
				modalBody={
					<>
						<div className='review-card my-2'>
							<div className='card-content-one d-flex justify-content-center'>
								<div>
									<div>
										<img
											src={selectedImage}
											className="profile-pic thread-list-user-image"
											alt="User Profile"
											style={{ width: '100%' }}
										/>
									</div>
								</div>
							</div>
						</div>
					</>
				}
			/>
		</div>
	)
}

export default SupportChatWidget