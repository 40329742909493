import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { BUSINESS_HOURS_DATA, CHECK_WEBSITE_DOMAIN_RESPONSE, GET_WEBSITE_BusinessHours } from "../../constants";


//1 date-api
const GET_WEBSITE_BUSINESS_HOURS_QUERY = gql`
query GetPersonalWebsiteBusinessHours {
  getPersonalWebsiteBusinessHours {
    success
    message
    data {
      pwbh_id
      pwbh_pwc_id
      pwbh_day
      pwbh_start
      pwbh_end
      pwbh_is_open
    }
  }
}
`

export const GetWebsiteBusinessHoursAction = () => {
  const dispatch = useDispatch()
  const [getWebsiteBusinessHoursResponseFunction, getWebsiteBusinessHoursReponseData] = useLazyQuery(
    GET_WEBSITE_BUSINESS_HOURS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getPersonalWebsiteBusinessHours: responseData }) => {
        console.log("responseData", responseData, "data")
        if (responseData?.success)
          dispatch({
            type: BUSINESS_HOURS_DATA,
            payload: responseData
          })
      }
    }
  )
  //this will be called from components
  const GetWebsiteBusinessHoursResponseData = getWebsiteBusinessHoursReponseData //variable

  const initGetWebsiteDataResponseFunction = (data) => {

    getWebsiteBusinessHoursResponseFunction({
      variables: data
    })
  }
  return { initGetWebsiteDataResponseFunction, GetWebsiteBusinessHoursResponseData }
}

