import { debounce } from "lodash";
import React, { useEffect, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import consentFormIcon from "../../../images/consent-list.svg";
import aftercareFormIcon from "../../../images/aftercare-list.svg";
import medicalIcon from "../../../images/icons/Medical_icon.svg";
import MenuIcon from '../../../images/icons/verticalDots.svg';
import SkeletonLoader from '../../common/Skeleton/SkeletonLoader';
import { GetAfterCareFormList, GetConsentFormList } from '../../redux/actions/formsTemplate/getFormTemplateList';
import { CLEAR_FORMS_LIST } from '../../redux/constants/formsTemplate/FormTemplateConst';
import EmptyClient from "../../Client/EmptyClient";
import EmptyConsentImage from '../../../images/Consent form ico 1.png';
import EmptyAftercareImage from '../../../images/Aftercare form icon 2.png';
import useWindowDimension from "../../../utils/useWindowDimensions";

const FormsList = ({
    activeTab,
    popoverClick,
    searchText,
    setSelectedFormId,
    isSearchedTriggered,
    toggleAftercareSelected
}) => {

    const { totalFormsCount } = useSelector(state => state?.formsListReducer)
    const { formsDataListPage } = useSelector(state => state?.formsListReducer)
    const { loadedFormsDataCount } = useSelector(state => state?.formsListReducer)
    const { formsDataList } = useSelector(state => state?.formsListReducer)

    const { consentFormListQueryData, initGetConsentFormList } = GetConsentFormList()
    const { afterCareFormListQueryData, initGetAfterCareFormList } = GetAfterCareFormList()


    const dispatch = useDispatch();

    const initiateFormListCall = (refreshList = false) => {

        let newPage = formsDataListPage + 1;
        if (refreshList) {
            newPage = 1;

        } else if (totalFormsCount <= loadedFormsDataCount) {
            return;
        }

        if (activeTab == 1) {
            initGetConsentFormList({
                page: newPage,
                limit: 12,
                search: searchText,
                status: "inactive",
                manageActiveSection: activeTab,
            }, (res) => {

            })
        }
        else if (activeTab == 2) {
            initGetAfterCareFormList({
                page: newPage,
                limit: 12,
                search: searchText,
                status: "inactive",
                manageActiveSection: activeTab
            }, (res) => {

            })
        }
    };

    useEffect(() => {
        console.log('formsapicalled-tab');
        dispatch({
            type: CLEAR_FORMS_LIST
        })
        initiateFormListCall(true);

    }, [activeTab])

    const debouncedApiCall = debounce((query) => {
        // Make the API call using the search query
        // Replace this with your own API call logic
        dispatch({
            type: CLEAR_FORMS_LIST
        })
        initiateFormListCall(true)
    }, 500); // Debounce delay in milliseconds (e.g., 500ms)

    useEffect(() => {
        if ((searchText.length == 0 && isSearchedTriggered) || searchText.length >= 3) {
            // Call the debounced API function with the search query
            console.log('formsapicalled-search');
            debouncedApiCall(searchText);

            // Cleanup function to cancel any pending debounced calls
            return () => {
                debouncedApiCall.cancel();
            };
        }
    }, [searchText]);

    const dimensions = useWindowDimension();
    const [headerHeight, setHeaderHeight] = useState(0);
    const [formTabHeight, setFormTabHeight] = useState(0);
    const [secTitleHeight, setSecHeadingHeight] = useState(0);
    const [searchContainerHeight, setSearchContainerHeight] = useState(0);
  

    useEffect(() => {

        setHeaderHeight(
          document.getElementsByClassName("nav-header-without-padding-container")[0]?.offsetHeight
        );

        setSecHeadingHeight(
            document.getElementsByClassName("SectionHeadingWrapper")[0]?.offsetHeight
        );

        setFormTabHeight(
            document.getElementsByClassName("form-tab-div")[0]?.offsetHeight
        );


        setSearchContainerHeight(
            document.getElementsByClassName("CustomeSearchBar")[0]?.offsetHeight
        );


        window.addEventListener("resize", handleResize);
      }, [headerHeight, secTitleHeight, formTabHeight, searchContainerHeight ]);
    
      const handleResize = () => {

        setHeaderHeight(
          document.getElementsByClassName("nav-header-without-padding-container")[0]?.offsetHeight
        );

        setSecHeadingHeight(
            document.getElementsByClassName("SectionHeadingWrapper")[0]?.offsetHeight
        );

        setFormTabHeight(
            document.getElementsByClassName("form-tab-div")[0]?.offsetHeight
        );

        setSearchContainerHeight(
            document.getElementsByClassName("CustomeSearchBar")[0]?.offsetHeight
        );
      };
      
    
    const scrollableDivHeight = dimensions?.height - headerHeight - secTitleHeight - formTabHeight - searchContainerHeight - 30 + 'px';
  
    console.log(headerHeight , secTitleHeight , formTabHeight , searchContainerHeight, 'headerHeight - secTitleHeight - formTabHeight - searchContainerHeight')

    return (
        <>
            <div id="scrollableDiv-blockout" style={{ height: scrollableDivHeight, width: "100%", overflowY: "scroll" }}>
                <InfiniteScroll
                    dataLength={loadedFormsDataCount}
                    next={() => {
                        initiateFormListCall(false)
                    }}
                    hasMore={loadedFormsDataCount < totalFormsCount}
                    scrollableTarget="scrollableDiv-blockout"
                >
                    {formsDataList?.length > 0 ? formsDataList?.map((item, index) => {
                        return <div key={index} className='FormsListItem'>
                            <div className="FormsListItemContent">
                                <div className='heart-icon-image'>
                                    <img src={activeTab == 1 ? consentFormIcon : activeTab == 2 ? aftercareFormIcon : activeTab == 3 ? medicalIcon : consentFormIcon} />
                                </div>
                                <div className='ItemContent'>
                                    <h1>{item.name}</h1>
                                </div>
                            </div>
                            <OverlayTrigger rootClose trigger="click" placement="auto" overlay={popoverClick}>
                                <div 
                                    onClick={() => {
                                        setSelectedFormId(item.id)
                                        toggleAftercareSelected(activeTab === 2)
                                    }} 
                                    className='FormsListItem__menu'
                                >
                                    <img
                                        src={MenuIcon}
                                        alt='menu'
                                    />
                                </div>
                            </OverlayTrigger>
                        </div>
                    }) :
                        consentFormListQueryData?.loading || afterCareFormListQueryData?.loading ? null :
                            // <div className="no-data-availa-class">
                            <div className="noDataFound">
                                {/* <h2>No form found!</h2> */}
                                <EmptyClient
                                    type={'CreateMyForm'}
                                    title={activeTab === 1 ? 'No Consent found' : 'No Aftercare found'}
                                    image={activeTab === 1 ? EmptyConsentImage : EmptyAftercareImage}
                                    name={searchText}
                                    subTitle={searchText.length > 0 ? 'is not in your list.' : ''}
                                />
                            </div>
                    }
                    {
                        consentFormListQueryData?.loading || afterCareFormListQueryData?.loading ?
                            <SkeletonLoader type="form-list" />
                            : null
                    }
                </InfiniteScroll>
            </div>

        </>
    )
}

export default FormsList