import React from "react";
import Skeleton from "react-loading-skeleton";

const FinanceOfferedToClientSkeleton = () => {
  return (
    <div className="container">
      <div className="pt-4">
      <div className="col-12 row justify-content-center">
        <div className="col-lg-6 col-sm-12 col-md-8">
          <div className="finance-skelton-list">
            <div className="finance-status">
              <Skeleton height={20} width={100} />
            </div>
            <div className="client-name">
              <Skeleton
                height={20}
                width={230}
                style={{
                  minWidth: "100px !important",
                }}
              />
            </div>
            <div className="client-name">
              <Skeleton
                height={20}
                width={200}
                style={{
                  minWidth: "100px !important",
                }}
              />
            </div>
            <div className="client-name mb-0">
              <Skeleton
                height={20}
                width={300}
                style={{
                  minWidth: "100px !important",
                }}
              />
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceOfferedToClientSkeleton;
