import React from 'react'
import './HairProductWhatsAppButton.scss'
import { config } from "../../../../../config";

const HairProductWhatsAppButton = () => {
    const WhatsappLinkRedirect = config.HAIR_PRESCRIPTION_WHATSAPP_LINK;
    return (
        <div>
            <div className="whatsapp-container">
                <button onClick={() => { window.open(WhatsappLinkRedirect, "_blank"); }}>
                    <i className="fa fa-whatsapp" aria-hidden="true"></i>Whatsapp
                    us
                </button>
            </div>
        </div>
    )
}

export default HairProductWhatsAppButton