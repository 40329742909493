import React, { useEffect, useState } from "react";
import "./business-setting.scss";
import { ReactComponent as ManageAccountIcon } from "../../images/business-setting-icon/manage-account.svg";
import { ReactComponent as LocationIcon } from "../../images/business-setting-icon/location.svg";
import { ReactComponent as ServiceIcon } from "../../images/sprint6/Service.svg";
import { ReactComponent as TeamMemberIcon } from "../../images/business-setting-icon/team.svg";
import { ReactComponent as PaymentControlIcon } from "../../images/business-setting-icon/payment-control.svg";
import { ReactComponent as FormIcon } from "../../images/business-setting-icon/form.svg";
import { ReactComponent as SecurityIcon } from "../../images/business-setting-icon/security.svg";
import { ReactComponent as NavArrorw } from "../../images/business-setting-icon/nav-icon.svg";
import BusinessNavigation from "../common/businessNavigation/BusinessNavigation";
import ManageAccount from "./manageAccount/ManageAccount";
import { webBaseUrl } from "../../config";
import BusinessSettingHeader from "../common/businessSettingHeader/BusinessSettingHeader";
import BusinessSidebar from "../sidebar/BusinessSidebar";
import { useNavigate } from "react-router";
import { getFormSettingsPageRoute, getManageAccountPageRoute, getPaymentsControlPageRoute, getSecuritySettingsPageRoute, indexPage, manageFormsRoutePattern } from "../../Routes";
import SidebarComponent from "../sidebar";
import Cookies from "js-cookie";
import useWindowDimensions from "../../utils/useWindowDimensions";
import logo from "../../images/icons/faces-logo-black.svg";
import closeIcon from "../../images/Icon example.svg";
import BusinessHeader from "../businessHeader";

const BusinessSetting = ({ setCurrentPage, currentPage }) => {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const [activeSidebar, setActiveSidebar] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  let isMobile = false;
  if (width <= 991) {
    isMobile = true;
  }
  else {
    isMobile = false;
  }
  useEffect(() => {
    isMobile ? setShowSidebar(false) : setShowSidebar(true);
  }, [isMobile]);

  // useEffect(() => {
  //   console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
  //   Cookies.get("faces_access_token") ? setIsAuth(true) : setIsAuth(false);
  //   console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
  // });


  const tileData = [
    {
      title: "Manage account",
      subTitle: "Edit settings and account details.",
      icon: <ManageAccountIcon />,
      path: getManageAccountPageRoute,
    },
    {
      title: "Locations",
      subTitle: "Edit or add new business locations.",
      icon: <LocationIcon />,
      url: webBaseUrl + '/clinic/list',
    },
    {
      title: "Your treatments",
      subTitle: "Update or add the treatments you offer.",
      icon: <ServiceIcon />,
      url: webBaseUrl + '/treatment/list'
    },
    // {
    //   title: "Team members",
    //   subTitle: "Add or adjust team members & roles.",
    //   icon: <TeamMemberIcon />,
    // },
    {
      title: "Payments control",
      subTitle: "Receive payments, use card machines.",
      icon: <PaymentControlIcon />,
      path: getPaymentsControlPageRoute,
    },
    {
      title: "Forms",
      subTitle: "Create or modify client forms quickly.",
      icon: <FormIcon />,
      path: manageFormsRoutePattern

    },
    {
      title: "Security",
      subTitle: "Change password and improve safety.",
      icon: <SecurityIcon />,
      path: getSecuritySettingsPageRoute
    },
  ];

  return (
    <div className="MainBusinessProfile">
      <BusinessHeader
        // openReviewPopup={openReviewPopup}
        // setOpenReviewPopup={setOpenReviewPopup}
        openMobileMenu={() => {
          setShowSidebar(!showSidebar);
          setActiveSidebar(true);
          document.body.classList.add('sidebarOpen');
        }}
        isMobile={isMobile}
      />
      {/* <BusinessSettingHeader
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        openMobileMenu={() => {
          setShowSidebar(!showSidebar);
        }}
        isMobile={isMobile}
      /> */}
      <div className="Business-Profile-Main-Wrapper container-fluid">
        <div className="sidebarDesignUpdateSprint6">
          {isMobile ? (
            <div
              className={
                showSidebar ? " MobileSidebar  ActiveSidebar" : "MobileSidebar"
              }
            >
              {activeSidebar && (
                <>
                  <div className="overlaySidebar"></div>
                  <div className="sidebar">
                    <div className="sidebarLogoWrapper">
                      <div className="logoSidebar">
                        <img src={logo} alt="logo" />
                      </div>
                      <div
                        className="sidebarClose"
                        onClick={() => {
                          setShowSidebar(false);
                          document.body.classList.remove('sidebarOpen');
                        }}
                      >
                        <img src={closeIcon} alt="closeIcon" />
                      </div>
                    </div>
                    <SidebarComponent />
                    {/* <BusinessSidebar
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                      /> */}
                  </div>
                </>
              )}
            </div>
          ) : (

            // isAuth &&
            <div className="sidebarPart">
              <div className="sidebar">
                <SidebarComponent />
              </div>
            </div>
          )}
          <div className={"MainContentPart"}>
            <div className="mainSettingSecWrapper Main684WidthSet">
              <>
                <BusinessNavigation
                  isColumn={true}
                  title="Settings"
                  desc="Tailor your online presence with our profile settings. Personalize privacy, communication, and more."
                  onBackClick={() => navigate(indexPage)}
                />
                <div className="main-business-setting-container">
                  {tileData?.map((tileItem, i) => (
                    <div
                      className="sub-content-section"
                      onClick={() => {
                        if (tileItem.url && !tileItem.path) {
                          window.location.href = tileItem.url;
                        }
                        else {
                          if(tileItem.title === "Forms") {
                            navigate(tileItem.path, { state: { internalRoute: true } })
                          } else navigate(tileItem.path);
                        }
                      }}
                      key={i}
                    >
                      <div className="setting-left-section">
                        {tileItem?.icon}
                        <div className="sub-left-content">
                          <h1>{tileItem?.title}</h1>
                          <p>{tileItem?.subTitle}</p>
                        </div>
                      </div>
                      <div className="setting-right-section">
                        <NavArrorw />
                      </div>
                    </div>
                  ))}
                </div>
              </>

            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default BusinessSetting;
