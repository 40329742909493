import { CLEAR_FORMS_LIST, GET_FORMS_LIST } from "../../constants/formsTemplate/FormTemplateConst";

const initialState = {
    formsDataList: [],
    totalFormsCount: 0,
    formsDataListPage: 0,
    loadedFormsDataCount: 0,
}

//Get ConsentForm List reducerformsListReducer
export default function (state = initialState, action) {

    switch (action.type) {

        case GET_FORMS_LIST:
            console.log(action.payload, "dataload")
            let temp;

            if (action.payload.manageActiveSection == 1) {
                temp = (state?.formsDataList).concat(action.payload.formsData.consentForms).map((item) => {
                    return { id: item.consentFormId || item.id, name: item.consentFormName || item.name }
                })
            }
            else if (action.payload.manageActiveSection == 2) {
                temp = (state?.formsDataList).concat(action.payload.formsData.aftercareDocs).map((item) => {
                    return { id: item.aftercareDocId || item.id, name: item.aftercareDocName || item.name }
                })
            }

            console.log("temp", temp)
            return {
                ...state,
                formsDataList: temp,
                totalFormsCount: action.payload.formsData.total,
                formsDataListPage: state.formsDataListPage + 1,
                loadedFormsDataCount: temp.length,
            };

        case CLEAR_FORMS_LIST:

            return {
                ...state,
                formsDataList: [],
                totalFormsCount: 0,
                formsDataListPage: 0,
                loadedFormsDataCount: 0,
            };

        default:
            return state;
    }



}