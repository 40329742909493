import './forms-client.scss'
import React, { useEffect, useRef, useState } from 'react'
import MenuIcon from '../../images/icons/verticalDots.svg'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { ReactComponent as RightArrow } from '../../images/icons/chevron-left.svg'
import { ReactComponent as CopyIcon } from '../../images/icons/copy_icon.svg'
import { ReactComponent as DownloadIcon } from '../../images/icons/download_icon.svg'
import { ReactComponent as ReminderIcon } from '../../images/icons/reminder_icon.svg'
import { GetClientConsentFormListQuery } from '../redux/actions/client/GetClientConcentForm'
import { useDispatch, useSelector } from 'react-redux'
import SkeletonLoader from '../common/Skeleton/SkeletonLoader'
import { ResendBookingDetailsMutation } from '../redux/actions/sendReminder/ResendBookingDetails'
import { toast } from 'react-toastify'
import { GetConsentFormPdfUrlQuery } from '../redux/actions/downloadConsentForm/downloadConsentForm'
import showToast from '../common/CustomToast/CustomToast'
import moment from 'moment'
import { ReactComponent as EditIcon } from '../../images/icons/appointment-list/edit.svg'
import { frontendURL } from '../../config'
import NavHeader from '../common/NavHeader/NavHeader'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router'
import { getCalendarRoute, getClientProfileRoute, getNewAppointmentRoute, rebookAppointmentRoute } from '../../Routes'
import { ReactComponent as CloseIcon } from '../../images/close_icon.svg'
import StepButton from '../common/StepButton'
import { ReactComponent as AddClient } from "../../images/new-appointment/add-client.svg";
import { ReactComponent as SendToPresciberIcon } from '../../images/icons/SendtoPrescriber.svg'
import InfiniteScroll from 'react-infinite-scroll-component'
import { GetMedicalFormStatusQuery } from '../redux/actions/appointments/GetMedicalFormStatus'
import NoForms from './no-forms/NoForms'
import Skeleton from 'react-loading-skeleton'
import { ResendMedicalFormAction } from '../redux/actions/sendReminder/ResendMedicalForm'
import { ReactComponent as EyeIcon } from "../../images/icons/circleEyeIcon.svg"
import { GetMedicalFormPdfUrlQuery } from '../redux/actions/downloadMedicalForm/DownloadMedicalForm'
import CustomRadioButton from '../common/CustomRadioButton'
import CustomModal from '../modal/CustomModal'
import { ConnectedPrescriberListQuery } from '../redux/actions/consent-form-thankyou/getConnectedPrescriberList'
import { SendConsentFormOrMedicalFormToPrescriberAction } from '../redux/actions/send-consent-form-or-medicalform-to-prescriber/SendConsentFormOrMedicalFormToPrescriber'
import { CLEAR_CLIENT_CONSENT_FORM_LIST } from '../redux/constants'
import { CLEAR_PRESCRIBER_LIST } from '../redux/constants/consentFormThankyou/getPrescriberListConst'
import ClientFormsViewPopup from '../common/client-forms-view-popup/ClientFormsViewPopup'
import { saveSelectedClientData } from '../redux/actions/client/SaveSelectedClient'
import OffCanvasModal from '../modal/OffCanvasModal/OffCanvasModal'
import { PrescriberList } from './prescriber-list/PrescriberList'
import useWindowDimension from '../../utils/useWindowDimensions'
import { PrescriptionList } from './prescription-list/PrescriptionList'
import { CLEAR_PRESCRIPTIONS_LIST } from '../redux/constants/getPrescriptionsListConst'
import { UpdatePrescriptionAttachedFormAction } from '../redux/actions/update-prescription-attached-form/UpdatePrescriptionAttachedForm'
import { socket } from '../socket'
import {webBaseUrl} from "../../config"


const FormsClient = ({ fromDateWiseApppintment = false }) => {
    //Query for get client consent form list
    const { clientConsentQueryListData, initGetClientConsent } = GetClientConsentFormListQuery()
    //Reducer Data for client concent form
    const clientConsentFormList = useSelector(state => state.clientConsentFormListReducer?.clientConsentFormList)
    //For Dowload PDF
    const { initConsentFormPdfUrl } = GetConsentFormPdfUrlQuery()
    //Send Reminder
    const { initiateResendBookingDetailsData, resendBookingDetailsMutationData } = ResendBookingDetailsMutation()
    const navigate = useNavigate();
    const location = useLocation();
    const clientId = parseInt(useParams()?.clientId)
    const appointmentId = parseInt(location?.state?.appointmentId)
    const [active, setActive] = useState("Consent");
    const [checkConsentFormForLoading, setIsCheckConsentFormForLoading] = useState(true)
    const [medicalFormData, setMedicalFormData] = useState(null)
    const [isPrescriberModalOpen, setIsPrescriberModalOpen] = useState(false)
    const [tempShowSelectedPrescriber, setTempShowSelectedPrescriber] = useState(null)
    const [isNoPharmacyRegisterModalOpen, setIsNoPharmacyRegisterModalOpen] = useState(false)
    const [isPrescriptionModalOpen, setIsPrescriptionModalOpen] = useState(false)
   
    
   
    const clientData = useSelector(state => state.clientConsentFormListReducer?.clientData)
    const totalClientConsentFormListCount = useSelector(state => state.clientConsentFormListReducer?.totalClientConsentFormListCount)
    const loadedClientConsentFormListCount = useSelector(state => state.clientConsentFormListReducer?.loadedClientConsentFormListCount)
    const clientConsentFormListPage = useSelector(state => state.clientConsentFormListReducer?.clientConsentFormListPage)
    const { medicaFormStatusQueryData, initGetMedicalFormStatus } = GetMedicalFormStatusQuery()
    const { initResendMedicalFormData, resendMedicalFormQueryData } = ResendMedicalFormAction()
    const { initMedicalFormPdfUrl } = GetMedicalFormPdfUrlQuery()
    const { initSendConsentFormOrMedicalFormToPrescriberData, sendConsentFormOrMedicalFormToPrescriberQueryData } = SendConsentFormOrMedicalFormToPrescriberAction()
    const { initUpdatePrescriptionAttachedFormData, updatePrescriptionAttachedFormQueryData } = UpdatePrescriptionAttachedFormAction()
    
    //prescriber list
    const { prescriberList } = useSelector((state) => state.connectedPrescriberListReducer);
    const { totalPrescriberCount } = useSelector((state) => state.connectedPrescriberListReducer);
    const { loadedPrescriberCount } = useSelector((state) => state.connectedPrescriberListReducer);
    const { prescriberListPage } = useSelector((state) => state.connectedPrescriberListReducer);

    //GET CONNECTED PRESCRIBER LIST QUERY
    const { conntectedPrescriberListQueryData, initConnectedPrescriberList } = ConnectedPrescriberListQuery()
    const dispatch = useDispatch();
    const [isFormsMenuOptionsOpen, setIsFormsMenuOptionsOpen] = useState(false)

    useEffect(() => {
        // initGetClientConsent({
        //     clientId: clientId,
        //     appointmentId: appointmentId
        // })
        dispatch({
            type: CLEAR_CLIENT_CONSENT_FORM_LIST,
        })
        initGetClientConsentFormListCall(true)
        initGetMedicalFormStatus({
            clientId: clientId
        }, (res) => {
            if (res?.success) {
                console.log("medical data", res?.data)
                setMedicalFormData(res?.data)

            } else {
            }
        }, (err) => {
            console.log(err, "err medical form")
            // setIsFilledMedical(false)
        })
    }, [])
    const redirectUrl = `${frontendURL}/client-profile/${clientId}/forms`
    const encodedRedirectUrl = encodeURIComponent(redirectUrl);


    const handleSendReminder = (formId) => {
        initiateResendBookingDetailsData({
            "appointmentId": null,
            "consentFormRecordId": formId
        }, (res) => {
            if (res.success) {
                showToast("success", res.message, "", false)
                setIsFormsMenuOptionsOpen(false)
            } else {
                showToast("error", res.message, "", false)
            }
        }, (err) => {
            showToast("error", err, "", false)
        })
    }

    const downloadForm = (formId) => {
        initConsentFormPdfUrl({
            consentFormRecordId: formId
        }, (res) => {
            console.log(res?.data?.getConsentFormRecordPdfUrl, "download pdf")
            if (res?.data?.getConsentFormRecordPdfUrl?.success) {
                window.open(res?.data?.getConsentFormRecordPdfUrl?.data?.consentFormRecordPdfUrl, "_blank")
                setIsFormsMenuOptionsOpen(false)
            } else {
                showToast("error", res.message, "", false)
            }
        }, (err) => {
            showToast("error", err, "", false)

        })
    }

    const [consentFormRecordId, setConsentFormRecordId] = useState(null);
    const [isFilled, setIsFilled] = useState(null)
    const [prescriberId, setPrescriberId] = useState(null)
    const [appointmentIdData, setAppointmentIdData] = useState(null);

    const menuData = [
        // {
        //     title: "Duplicate form",
        //     icon: <CopyIcon />,
        // },

        // {
        //     title: "Edit",
        //     icon: <EditIcon />,
        // },
        {
            title: "Re-send to client",
            icon: <AddClient />
        },
        {
            title: "Send to client",
            icon: <AddClient />,
        },
        {
            title: "Send to prescriber",
            icon: <SendToPresciberIcon />
        },
        {
            title: "Download PDF",
            icon: <DownloadIcon />,
        },
        {
            title: "Edit consent form",
            icon: <EyeIcon />
        }
    ]

    const handleMenuClick = (title) => {
        if (active === "Consent") {
            switch (title) {
                case "Download PDF":
                    if (consentFormRecordId !== null) {
                        downloadForm(consentFormRecordId)
                    }
                    break;

                case "Send to client":
                    handleSendReminder(consentFormRecordId)
                    break;


                case "Re-send to client":
                    handleSendReminder(consentFormRecordId)
                    break;

                case "Send to prescriber":
                    dispatch({
                        type: CLEAR_PRESCRIBER_LIST
                    })
                    dispatch({
                        type: CLEAR_PRESCRIPTIONS_LIST
                    })
                    setPrescriberId(null);
                    setIsFormsMenuOptionsOpen(false)
                    if (clientData?.vendorIsPharmacy) {
                        // initiatePrescriberListCall(true)
                        setIsPrescriberModalOpen(true)
                    }
                    else {
                        setIsNoPharmacyRegisterModalOpen(true)
                    }
                    break;

                case "Edit consent form":
                    window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
                    break;

                case "Complete in person":
                    window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
                    break;

                // case 2:
                //     if (consentFormRecordId !== null) {
                //         window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
                //     }
                //     break;

                case "Repeat form":
                    // navigate(rebookAppointmentRoute(appointmentIdData?.appointmentId, clientId), { state: { isFromClientForm: true } })
                        // `${webBaseUrl}/filled-consent-form/duplicate/${consentFormRecordId}/?documents=true`)
                        window.location.href=webBaseUrl +"/filled-consent-form/duplicate/" + consentFormRecordId + "/?documents=true"
                    break;

                default:
                    console.log('Default')
                    break;
            }
        }
        else {
            switch (title) {
                case "Download PDF":
                    downloadMedicalForm()
                    break;

                case "Send to client":
                    handleResendMedicalForm()
                    break;

                case "Re-send to client":
                    handleResendMedicalForm()
                    break;

                case "Send to prescriber":
                    dispatch({
                        type: CLEAR_PRESCRIBER_LIST
                    })
                    dispatch({
                        type: CLEAR_PRESCRIPTIONS_LIST
                    })
                    setPrescriberId(null);
                    setIsFormsMenuOptionsOpen(false)
                    if (clientData?.vendorIsPharmacy) {
                        // initiatePrescriberListCall(true)
                        setIsPrescriberModalOpen(true)
                    }
                    else {
                        setIsNoPharmacyRegisterModalOpen(true)
                    }
                    break;

                case "Edit medical form":
                    window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`
                    break;

                case "Complete in person":
                    window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`
                    break;

                default:
                    console.log('Default')
                    break;
            }
        }

    }
    
    //Get Form name with count
    const getFormNames = (consentForms) => {
        if (consentForms.length === 0) {
            return 'No forms';
        }

        const firstFormName = consentForms[0].formName;
        const additionalFormCount = consentForms.length - 1;

        if (additionalFormCount === 0) {
            return firstFormName;
        }

        return `${firstFormName} (+${additionalFormCount})`;
    };

    //Get Treatment name with count
    const getTreatmentNames = (treatmentName) => {
        const filteredList = treatmentName.filter(item => item.treatmentName);
        if (filteredList.length === 0) {
            return 'N/A';
        }

        const firstTreatmentName = filteredList[0].treatmentName;
        const additionalTreatmentCount = filteredList.length - 1;

        if (additionalTreatmentCount === 0) {
            return firstTreatmentName;
        }

        return `${firstTreatmentName} (+${additionalTreatmentCount})`;
    };

    const handleCloseClick = () => {
        document.body.click()
        //popOverRef.onHide()
    }

    const popoverClick = (

        <Popover id="popover-trigger-click" className='popover-class' title="Popover bottom">
            <div className='popover-title-section'>
                <h1>Manage</h1>
                <CloseIcon className='close-icon-animation' onClick={handleCloseClick} />
            </div>
            {
                menuData.filter((items) => {
                    if (isFilled) {
                        // If isFilled is true, hide "Send reminder"
                        return items.title !== 'Re-send to client' && items.title !== "Edit consent form";
                    } else {
                        // If isFilled is false, hide this menu
                        return items.title === 'Re-send to client' || items.title === "Edit consent form"
                    }
                }).map((items, index) => (
                    <div className='form-option-container' key={index} onClick={() => { handleMenuClick(items.title); window.document.body.click() }}>
                        <div className='left-popover-content'>
                            {items.icon}
                            <p>{items.title}</p>
                        </div>
                        <div className='right-popover-content'>
                            <RightArrow />
                        </div>
                    </div>
                ))
            }
            {/* <div className='form-option-container'>
                <div className='left-popover-content'>
                    <DownloadIcon/>
                    <p>Download</p>
                </div>
                <div className='right-popover-content'>
                    <RightArrow />
                </div>
            </div>
            <div className='form-option-container'>
                <div className='left-popover-content'>
                    <ReminderIcon/>
                    <p>Send reminder</p>
                </div>
                <div className='right-popover-content'>
                    <RightArrow />
                </div>
            </div> */}
        </Popover>

    );
    // console.log("consentFormRecordId",consentFormRecordId)
    const goBackClientProfile = () => {
        if (location?.state?.fromAppointmentList) {
            navigate(getCalendarRoute(), { state: { fromAppointmentList: location?.state?.fromAppointmentList, clickdate: location?.state?.clickdate, calenderViewState: location?.state?.calenderViewState, calenderShowSelectedDate: location?.state?.calenderShowSelectedDate } })
        }
        else if (location?.state?.clientId) {
            navigate(getClientProfileRoute(location?.state?.clientId), { state: { getBackToClientAppointmentScreen: true, active: location?.state?.active } })
        }
        else {
            navigate(getClientProfileRoute(clientId))
        }
    }

    const initGetClientConsentFormListCall = (refreshList = false) => {
        let newPage = clientConsentFormListPage + 1;
        if (refreshList) {
            newPage = 1;

        } else if (totalClientConsentFormListCount <= loadedClientConsentFormListCount) {
            return;
        }
        setIsCheckConsentFormForLoading(false)
        initGetClientConsent({
            clientId: clientId,
            appointmentId: appointmentId,
            page: newPage,
            limit: 15
        })
    }

    const handleResendMedicalForm = () => {
        initResendMedicalFormData({
            clientId: clientId
        }, (res) => {
            if (res.success) {
                showToast("success", res.message, "", false)
                setIsFormsMenuOptionsOpen(false)
            } else {
                showToast("error", res.message, "", false)
            }
        }, (err) => {
            showToast("error", err, "", false)
        })
    }


    const downloadMedicalForm = (formId) => {
        initMedicalFormPdfUrl({
            clientId: clientId
        }, (res) => {
            if (res?.data?.getMedicalFormPdfUrl?.success) {
                window.open(res?.data?.getMedicalFormPdfUrl?.data?.medicalFormRecordPdfUrl, "_blank")
                setIsFormsMenuOptionsOpen(false)
            } else {
                showToast("error", res.message, "", false)
            }
        }, (err) => {
            showToast("error", err, "", false)

        })
    }

    const handleSendConsentFormorMedicalFormToPrescriber = (data) => {
        initSendConsentFormOrMedicalFormToPrescriberData(
            data
            , (res) => {
                if (res.success) {
                    showToast("success", res.message, "", false)
                    setIsPrescriberModalOpen(false)
                    setTempShowSelectedPrescriber(null)
                } else {
                    showToast("error", res.message, "", false)
                    setTempShowSelectedPrescriber(null)
                }
            }, (err) => {
                showToast("error", err, "", false)
            })
    }

    const closeModalHandler = () => {
        setIsFormsMenuOptionsOpen(true)
    }

    const onCreateAppointmentClickHandler = () => {
        let data = {
            clentId: clientId,
            clientName: clientData?.clientName,
            clientEmail: clientData?.clientEmail,
            clientProfileImage: clientData?.clientProfileImage
        }
        dispatch(saveSelectedClientData(data))
        navigate(getNewAppointmentRoute(), { state: { clientId: clientId, fromFormsScreen: true } })
    }

    const sendClickHandler = (selectedPrescriberId) => {
        // let data;
        // if (active === "Consent") {
        //     data = {
        //         consentFormId: consentFormRecordId,
        //         prescriberId: parseInt(selectedPrescriberId)
        //     }
        // }
        // else {
        //     data = {
        //         clientId: clientId,
        //         prescriberId: parseInt(selectedPrescriberId)
        //     }
        // }
        // handleSendConsentFormorMedicalFormToPrescriber(data)
        setIsPrescriberModalOpen(false)
        setPrescriberId(selectedPrescriberId)
        setIsPrescriptionModalOpen(true)
    }

    const sendtoPrescriberHandler = (dataTobePassed) => {

        let data;

        if (active === "Consent") {
            data = {
                prescriptionId: dataTobePassed?.prescriptionId,
                clientId: clientId,
                consentFormId: consentFormRecordId,
                attachMedicalForm: dataTobePassed?.attachMedicalForm
            }
        }
        else {
            data = {
                prescriptionId: dataTobePassed?.prescriptionId,
                clientId: clientId,
                consentFormId: 0,
                attachMedicalForm: true,
            }
        }
        initUpdatePrescriptionAttachedFormData(
            data
            , (res) => {
                if (res.success) {
                    showToast("success", res.message, "", false)
                    setIsPrescriptionModalOpen(false)
                    setTempShowSelectedPrescriber(null)
                } else {
                    showToast("error", res.message, "", false)
                    setTempShowSelectedPrescriber(null)
                }
            }, (err) => {
                showToast("error", err, "", false)
            })
    }

    const dimensions = useWindowDimension();
    const [headerNavbar, setHeaderNavbar] = useState(0);
    const [mainformpageHeader, setMainformpageHeader] = useState(0);

    useEffect(() => {
        setHeaderNavbar(
            document.getElementsByClassName('nav-header-without-padding-container')[0]?.offsetHeight
        );

        setMainformpageHeader(
            document.getElementsByClassName('MainformpageHeader')[0]?.offsetHeight
        );

        window.addEventListener('resize', handleResize);
    }, []);
    const handleResize = () => {
        setHeaderNavbar(
            document.getElementsByClassName('nav-header-without-padding-container')[0]?.offsetHeight
        );

        setMainformpageHeader(
            document.getElementsByClassName('MainformpageHeader')[0]?.offsetHeight
        );

    };


    return (
        <div className='MainFormPage'>
            <NavHeader applyPadding={false} isBackOnly={true} isGray={false} btnLabel={"Edit"}
                handleOnBack={() => goBackClientProfile()}
            // onClickCallback={() => handleEditNavigate()} 
            />
            <div className='Main-form-page'>
                <div className='forms-container CustomeFormWrapper'>
                    <div className='client_form_content_container'>
                        <div className='MainformpageHeader'>
                            {clientConsentQueryListData?.called && clientData?.clientName ? <h1>{clientData?.clientName}’s forms</h1> : <Skeleton width={240} height={15} />}
                            <p>Check out your client's form history</p>

                            <div className='forms-wrapper mb-3'>
                                <span className={(active === "Consent" ? "active me-2" : "not-active me-2")} onClick={() => { setActive("Consent") }}>Consent</span>
                                <span className={(active === "Medical" ? "active me-2" : "not-active me-2")} onClick={() => {
                                    setActive("Medical")
                                }}>Medical</span>
                            </div>
                        </div>
                        <div id="scrollableDiv" style={{ height: dimensions.height - headerNavbar - mainformpageHeader - 28 + 'px', width: "100%", overflowY: "auto" }}>
                            {
                                <InfiniteScroll
                                    dataLength={loadedClientConsentFormListCount}
                                    next={() => {
                                        initGetClientConsentFormListCall(false)
                                    }}
                                    hasMore={loadedClientConsentFormListCount < totalClientConsentFormListCount && active === "Consent"}
                                    scrollableTarget="scrollableDiv"
                                >
                                    {
                                        active === "Consent" && (clientConsentFormList?.length > 0 ?
                                            clientConsentFormList?.map((parentData, i) => {
                                                // console.log(process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/duplicate/" + parentData?.consentFormRecordId,"9881")

                                                // console.log(process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl} `)
                                                return (
                                                    <div className='client_form_card cursor-pointer' key={i} onClick={() => {
                                                        setIsFilled(parentData?.isFilled)
                                                        setConsentFormRecordId(parentData?.consentFormRecordId)
                                                        setIsFormsMenuOptionsOpen(true)
                                                        setAppointmentIdData({ appointmentId: parentData?.appointmentId, date: parentData?.treatmentDate })
                                                        // window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + parentData?.consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
                                                    }}>


                                                        <div className='client-form-data-wrapper'>
                                                            <div className='client_form_content'
                                                            >
                                                                <h1>Date</h1>
                                                                <p>{parentData?.treatmentDate ? moment(parentData?.treatmentDate).format('D MMM YYYY') : 'N/A'}</p>
                                                            </div>
                                                            <div className='client_form_content mt-2'>
                                                                <h1>Form</h1>
                                                                <p>{getFormNames(parentData?.consentForms)}</p>
                                                            </div>
                                                            <div className='client_form_content mt-2'>
                                                                <h1>Services</h1>
                                                                <p>{getTreatmentNames(parentData?.treatments)}</p>
                                                            </div>
                                                            <div className='client_form_content mt-2'>
                                                                <h1>Location</h1>
                                                                <p>{parentData?.clinicName || 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                        <div className='status-icon-wrapper'>
                                                            {parentData?.isFilled ?
                                                                <div className='status-badge-incomplete status-badge-complete'
                                                                // style={{ background: parentData?.deposit_status_color == "" ? "red" : parentData?.deposit_status_color }}
                                                                >
                                                                    <p>{"Completed"}</p>
                                                                </div> :
                                                                <div className='status-badge-incomplete'
                                                                // style={{ background: parentData?.deposit_status_color == "" ? "red" : parentData?.deposit_status_color }}
                                                                >
                                                                    <p>{"Incomplete"}</p>
                                                                </div>}

                                                            {/* <div onClick={(e) => {
                                                                e.stopPropagation();
                                                                setConsentFormRecordId(parentData?.consentFormRecordId)
                                                                setIsFilled(parentData?.isFilled)
                                                            }}>
                                                                <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popoverClick}>
                                                                    <img src={MenuIcon} className='p-2' />
                                                                </OverlayTrigger>
                                                            </div> */}
                                                        </div>
                                                    </div>

                                                )
                                            }) :
                                            clientConsentQueryListData.loading || checkConsentFormForLoading
                                                ?
                                                null :

                                                <NoForms onCreateAppointmentClickHandler={onCreateAppointmentClickHandler} />
                                        )

                                    }
                                    {
                                        active === "Medical" && (Object.keys(medicalFormData)?.length > 0 ?
                                            <div className='client_form_card cursor-pointer' onClick={() => {
                                                setIsFilled(medicalFormData?.isMedicalFormFilled)
                                                setIsFormsMenuOptionsOpen(true)
                                                // window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`
                                            }}>


                                                <div className='client-form-data-wrapper'>
                                                    <div className='client_form_content'>
                                                        <h1>Form</h1>
                                                        <p>Medical form</p>
                                                    </div>
                                                </div>
                                                <div className='status-icon-wrapper'>
                                                    {medicalFormData?.isMedicalFormFilled ?
                                                        <div className='status-badge-incomplete status-badge-complete'
                                                        // style={{ background: parentData?.deposit_status_color == "" ? "red" : parentData?.deposit_status_color }}
                                                        >
                                                            <p>{"Completed"}</p>
                                                        </div> :
                                                        <div className='status-badge-incomplete'
                                                        // style={{ background: parentData?.deposit_status_color == "" ? "red" : parentData?.deposit_status_color }}
                                                        >
                                                            <p>{"Incomplete"}</p>
                                                        </div>}

                                                    {/* <div onClick={(e) => {
                                                        e.stopPropagation();
                                                        setIsFilled(medicalFormData?.isMedicalFormFilled)
                                                    }}>
                                                        <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popoverClick}>
                                                            <img src={MenuIcon} className='p-2' />
                                                        </OverlayTrigger>
                                                    </div> */}
                                                </div>
                                            </div>
                                            :
                                            medicaFormStatusQueryData.loading || checkConsentFormForLoading
                                                ?
                                                null :

                                                <NoForms />
                                        )
                                    }
                                    {
                                        clientConsentQueryListData.loading ?
                                            <div className='SkeletonClient'>
                                                <SkeletonLoader isClient={true} type={"client-consent-form-list-skeleton"} />
                                            </div>
                                            : null
                                    }
                                </InfiniteScroll>
                            }
                        </div>

                        {/* select prescriber popup */}
                        {/* <CustomModal
                            modalOpen={isPrescriberModalOpen}
                            setModalOpen={setIsPrescriberModalOpen}
                            type={"common-width"}
                            modaltitle={"Select prescriber"}
                            modalCloseCallback={closeModalHandler}
                            className={'SelectPresciberModel'}

                            modalBody={
                                <>
                                    <div>
                                        <div className="w-100">
                                            <div className='select-presciber-container'>
                                                <div id="scrollableDivPrescriber" style={{ height: "50vh", width: "100%", overflowX: "scroll" }} className={`${prescriberList?.length === 0 ? "no-prescriber-wrap-text" : ""}`}>
                                                    <InfiniteScroll
                                                        dataLength={loadedPrescriberCount}
                                                        next={() => {
                                                            initiatePrescriberListCall(false)
                                                        }}
                                                        hasMore={loadedPrescriberCount < totalPrescriberCount}
                                                        scrollableTarget="scrollableDivPrescriber"
                                                    >
                                                        <div className="select-presciber-wrapper">
                                                            {

                                                                prescriberList?.length != 0 ? prescriberList.map((prescriber, index) => {
                                                                    return <div className={`select-presciber-slot-content ${tempShowSelectedPrescriber === prescriber?.prescriber_id ? 'selected' : ''}`} key={index}>

                                                                        <CustomRadioButton
                                                                            text={prescriber.prescriber_name} timeSlot={true} onChange={(e) => {
                                                                                setTempShowSelectedPrescriber(e.target.id)
                                                                            }} id={prescriber.prescriber_id} name="time" value={prescriber.prescriber_id} checked={tempShowSelectedPrescriber === prescriber.prescriber_id}
                                                                            isblacked={true}
                                                                        />
                                                                    </div>
                                                                })
                                                                    :
                                                                    conntectedPrescriberListQueryData?.loading ? null :
                                                                        <div className='no-prescriber-connected'>
                                                                            <p>You don't have any prescriber connected!</p>
                                                                        </div>
                                                            }
                                                            {
                                                                conntectedPrescriberListQueryData?.loading ?
                                                                    <SkeletonLoader type="common-list" />
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </InfiniteScroll>
                                                </div>

                                                <div className="sticky-footer-calender">
                                                    <StepButton label={"Send"} blue={true} onClick={() => {
                                                        // setShowSelectedTime(tempShowSelectedTime)
                                                        let data;
                                                        if (active === "Consent") {
                                                            data = {
                                                                consentFormId: consentFormRecordId,
                                                                prescriberId: parseInt(tempShowSelectedPrescriber)
                                                            }
                                                        }
                                                        else {
                                                            data = {
                                                                clientId: clientId,
                                                                prescriberId: parseInt(tempShowSelectedPrescriber)
                                                            }
                                                        }
                                                        handleSendConsentFormorMedicalFormToPrescriber(data)
                                                    }}
                                                        disabled={tempShowSelectedPrescriber ? false : true}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            }
                        /> */}


                        {/* no pharmacy registration popup */}
                        <CustomModal
                            modalOpen={isNoPharmacyRegisterModalOpen}
                            setModalOpen={setIsNoPharmacyRegisterModalOpen}
                            type={"common-width"}
                            className={'customeNoPharmacyRegisterModel'}
                            modalBody={
                                <>
                                    <div className="share-booking-link-all-clients-confimration">
                                        <p>
                                            Currently you are not register with any pharmacy. Would you like to connect?
                                        </p>
                                        <div className="btnWrapper">
                                            <StepButton label={"Yes"} onClick={() => {
                                                window.location.href = frontendURL + "/prescriber-registration";
                                                setIsNoPharmacyRegisterModalOpen(false)
                                            }} blue={true} />

                                            <StepButton gray={true} label={"No"} onClick={() => {
                                                setIsNoPharmacyRegisterModalOpen(false)
                                            }} />
                                        </div>
                                    </div>
                                </>
                            }
                        />

                        {/* forms menu options popup  */}

                        <CustomModal
                            modalOpen={isFormsMenuOptionsOpen}
                            // modalOpen={true}
                            modaltitle={"Manage forms"}
                            setModalOpen={setIsFormsMenuOptionsOpen}
                            className="width-30 customeWidth customeModelAppointment menudata-container"
                            modalBody={
                                <>
                                    <ClientFormsViewPopup handleMenuClick={handleMenuClick} isFilled={isFilled} active={active} />
                                </>

                            }
                        />

                        {/* prscriber list component */}
                        <OffCanvasModal
                            isOffCanvasHeader={false}
                            isOpen={isPrescriberModalOpen}
                            placement={"end"}
                            className={"maxCanvasWidth508 SelectPrescriberListModel"}
                            onHide={() => setIsPrescriberModalOpen(false)}
                            // onHide={() => { setIsClientPeofile(false); setIsClientAppointment(false); setActive("upcoming") }}
                            body={<PrescriberList sendClickHandler={sendClickHandler} setIsPrescriberModalOpen={setIsPrescriberModalOpen} sendConsentFormOrMedicalFormToPrescriberQueryData={sendConsentFormOrMedicalFormToPrescriberQueryData} prescriberId={prescriberId} />}
                        />


                        {/* prescription list component */}
                        <OffCanvasModal
                            isOffCanvasHeader={false}
                            isOpen={isPrescriptionModalOpen}
                            placement={"end"}
                            className={"maxCanvasWidth508 SelectPrescriptionModel"}
                            onHide={() => setIsPrescriptionModalOpen(false)}
                            // onHide={() => { setIsClientPeofile(false); setIsClientAppointment(false); setActive("upcoming") }}
                            body={<PrescriptionList sendtoPrescriberHandler={sendtoPrescriberHandler} setIsPrescriberModalOpen={setIsPrescriberModalOpen} updatePrescriptionAttachedFormQueryData={updatePrescriptionAttachedFormQueryData} prescriberId={prescriberId} clientId={clientId} setIsPrescriptionModalOpen={setIsPrescriptionModalOpen} active={active} />}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormsClient