import { useDispatch } from "react-redux"
import { GET_GENERATE_FEEDBACK } from "../../constants/feedback-generate-const/FeedBackGenerateConst"
import { gql, useLazyQuery } from "@apollo/client"

const GENERATE_FEEDBACK_QUERY = gql`
query GenerateBookingReviewContent($bookingId: Int!) {
    generateBookingReviewContent(booking_id: $bookingId) {
      success
      message
      data {
        reviewContent
      }
    }
  }`

export const GenerateFeedBack = () => {
  const dispatch = useDispatch()
  const [generateFeedBack, generateFeedBackResponseData] = useLazyQuery(
    GENERATE_FEEDBACK_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ generateBookingReviewContent: responseData }) => {
       
          
        if (responseData?.success){

    
            dispatch({
              type: GET_GENERATE_FEEDBACK,
              payload: responseData
            })
        }

      }
    }
  )
  //this will be called from components
  const generateFeedBackData = generateFeedBackResponseData //variable

  const initGenerateFeedBackData = (data,successCallback,errorCallback) => {

    generateFeedBack({
      variables: data 
    }).then((res)=>{
        successCallback(res?.data?.generateBookingReviewContent)
    }).catch((err)=>{
        errorCallback(err)
    })
  }

  return { generateFeedBackData, initGenerateFeedBackData }
}
