import axios from '../../../axios/index'
import { API_URL, BASE_URL } from "../../../axios/config";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import { 
    GET_PRODUCT_GET_CART_COUNT_DATA
} from '../../constants';
import { 
    getProductsGetCartCountDataError, 
    getProductsGetCartCountDataSuccess 
} from '../../actions';
import Cookies from 'js-cookie';

async function getProductsGetCartCountDataAPI(data) {

    return axios.request({
        method: "get",
        url: BASE_URL + API_URL.PRODUCT_GET_CART_COUNT,
        params: data,
        headers: { 
            "encrypted-authrorisation": Cookies.get('faces_access_token'),
        }
    });
}

function* getProductsGetCartCountDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        let response = yield call(getProductsGetCartCountDataAPI, payload);
        if (response.status === 200) {
            if (response.data.success) {
                yield put(getProductsGetCartCountDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        // yield put(setLoaderComplete());
        yield put(getProductsGetCartCountDataError(error));
        if (reject) reject(error);
    }
}

export function* getProductsGetCartCountDataWatcher() {
    yield takeLatest(GET_PRODUCT_GET_CART_COUNT_DATA, getProductsGetCartCountDataAction);
}
