import { gql, useMutation } from "@apollo/client";


const LOGOUT_USER_ACTIVE_SESSIONS_MUTATION = gql`
  mutation LogoutUserActiveSessions($sessionId: Int) {
    logoutUserActiveSessions(sessionId: $sessionId) {
      success
      message
    }
  }
`;

export const LogoutUserSessions = () => {
  const [logoutUserActiveSessionsMutation, logoutUserActiveSessionsResponse] =
    useMutation(LOGOUT_USER_ACTIVE_SESSIONS_MUTATION);

  const logoutUserSessionsData = logoutUserActiveSessionsResponse;

  const initiateLogoutUserSessions = (data, successCallback, errorCallback) => {
    logoutUserActiveSessionsMutation({
      variables: data,
    })
      .then(({ data: { logoutUserActiveSessions: responseData } }) => {
        if (responseData?.success) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  return { initiateLogoutUserSessions, logoutUserSessionsData };
};
