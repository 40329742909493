import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_CLIENTS_DATA, GET_CLIENTS_LIST_DATA } from "../../constants";

const GET_CLIENT_LIST_QUERY = gql`
query GetClientList_v3($page: Int, $limit: Int, $search: String, $deletedClients: Boolean, $requestFrom: String, $selectedClient: Int, $includeClientWithBlankName: Boolean, $searchByAlphabet: String) {
  getClientList_v3(page: $page, limit: $limit, search: $search, deletedClients: $deletedClients, requestFrom: $requestFrom, selectedClient: $selectedClient, includeClientWithBlankName: $includeClientWithBlankName, searchByAlphabet: $searchByAlphabet) {
    success
    message
    data {
      clients {
        clentId
        clientName
        clientEmail
        readableContact
        clientProfileImage
        client_initial
      }
      total
    }
  }
}
`;

var page;
export const GetClientList = () => {
  const dispatch = useDispatch();
  const [getClientListData, clientListData] = useLazyQuery(

    GET_CLIENT_LIST_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClientList_v3: responseData }) => {
        dispatch({
          type: GET_CLIENTS_LIST_DATA,
          payload: responseData.data,
          page,
        });
        if (responseData.success) {
        } else {
        }
      },
    }
  );
  const clientListQueryData = clientListData;
  const initGetClientList = (data) => {
    page = data?.page ? data?.page : 1;
    getClientListData({
      variables: data,
    });
  };
  return { initGetClientList, clientListQueryData };
};
