import { SET_NEW_CLIENT_LIST, SET_NEW_CLIENT_LIST_CLEAR, SET_NEW_CLINIC_LIST, SET_NEW_CLINIC_LIST_CLEAR } from "../../constants/newAppointment/NewAppointmentConst"




const initialState = {
    clientData: {},
    clinicData:{}
}
export const newClientDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NEW_CLIENT_LIST:
            return {
                ...state,
                clientData: action.payload,
            }
            case SET_NEW_CLIENT_LIST_CLEAR:
                return {
                    ...state,
                    clientData: {},
                }
        default:
            return state
    }
}  

export const newClinicDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NEW_CLINIC_LIST:
            return {
                ...state,
                clinicData: action.payload,
            }
            case SET_NEW_CLINIC_LIST_CLEAR:
                return {
                    ...state,
                    clinicData: {},
                }
        default:
            return state
    }
}