import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import mastercard from "../../../assets/card/Mastercard.png"
import visa from "../../../assets/card/Visa.png"
import "./DirectDeposit.scss"
import { CompleteDirectDepositPaymentBooking } from '../../redux/actions/direct-deposit/DirectDepositFormBooking';

export default function DirDepStripeComponent ({ clientSecret, depositId, token }) {

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [showLoader, setLoader] = useState(false);

  const {
    initiateCompleteDirectDepositPaymentBooking,
    completeDirectDepositPaymentBookingMutationData,
  } = CompleteDirectDepositPaymentBooking();

  const handleSubmitPayment = async (event) => {
    console.log('handleSubmitPayment :', handleSubmitPayment);
    event.preventDefault();

    // if (!stripe || !elements) {
    //   return;
    // }

    setErrorMessage("");
    setLoader(true);

    const { paymentIntent, error } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardElement),
        },

        return_url: window.location.href,
      }
    );

    console.log(paymentIntent, "paymentIntent", depositId)

    if (error) {
      setErrorMessage(error.message);
      setLoader(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      if (
        paymentIntent.status === "succeeded" ||
        paymentIntent.status === "requires_capture"
      ) {
        // setProcess("Complete Payment")
        stripe.createToken(elements.getElement(CardElement)).then(function (result) {
          if (result.error) {
            // Handle error
          } else {
            // var token
            // token = result.token;
            setLoader(true);
            initiateCompleteDirectDepositPaymentBooking({
              depositId: depositId,
              paymentId: paymentIntent.id,
              paymentType: 2,
              token: token,
            }, (res) => {
              if (res.success) {
                setLoader(false);
                console.log("STRIP payment done....", res)
                navigate('/direct-deposit-thank-you')
                return
              } else {
                setLoader(false);
                setErrorMessage(res.message);
                navigate('/direct-deposit-thank-you')
                return
              }
            }, (e) => {
              setLoader(false);
              setErrorMessage(e.message);
            })
            // Perform further processing with the token
            // e.g., save the token to the customer in your database
          }
        })
      }
    }
  };


  return (
    <form id='paymentForm' onSubmit={(e) => handleSubmitPayment(e)}>
      {/* <NewCard/> */}

      <CardElement
        className="checkoutcard-field mx-2"
        options={{
          hidePostalCode: true,
          style: {
            //your style
          },
          classes: {
            //your classname

          }

        }}
      />

      {/* <button disabled={!stripe}>Submit</button> */}
      {showLoader ? (
        <p></p>
      ) : (
        <>
          {/* <button type="submit" style={{ visibility: 'none' }} disabled={!stripe || showLoader}>
    Pay Now
  </button> */}
        </>
      )}
      <div className="form-group text-center mt-2">
        <div className="col-12">
          <img src={mastercard} alt='' width="40px" height="35px" />
          <img src={visa} alt='' width="40px" height="35px" />
        </div>
      <input className="btn btnredsm" type="submit" value="Make Payment" disabled={!stripe}></input>
      </div>


      {/* <StepButton type={"submit"} blue={true} label={"PAY NOW"}
        isLoading={showLoader}
      /> */}

      {/* Show error message to your customers */}
      {errorMessage && <div className="card-invalid-text">{errorMessage}</div>}
    </form>
  )
}
