import React, { useEffect, useState } from "react";
import userAddLogo from "../../../assets/createWebsite/group-chat.png";
import StepButton from "../../common/StepButton";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import AddTeamMemberModal from "./addTeamMemberModal";
import CustomModal from "../../modal/CustomModal";
import SkipButtonComp from "../createWbsiteURL/commonComponents/SkipBtn";
import { set } from "lodash";
import editIcon from "../../../images/editIcon.svg";
import deleteIcon from "../../../images/deleteIcon.svg";
import { MdPersonAddAlt1 } from "react-icons/md";
import "aos/dist/aos.css";
import AOS from "aos";
import ImageTitleComponent from "../createWbsiteURL/commonComponents/ImgTitleComp";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { GetWebsiteTeamMembersAction } from "../../redux/actions/createWebsite/getPersonalWebTeamMembersQuery";
import { useSelector } from "react-redux";
import { GetWebsiteTeamMemberDetailsAction } from "../../redux/actions/createWebsite/getTeamMembersDetails";
import { DeleteTeamMemberDetails } from "../../redux/actions/createWebsite/deleteTeamMemberDetails";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";
import { useDispatch } from "react-redux";
import { CLEAR_GENERATED_CONTENT } from "../../redux/constants";

const AddTeamMemberStep = (props) => {
    const {
        style,
        isMobile,
        prevWebOnClick,
        stepFiveData,
        isVisibleSkip,
        ButtonAddTeamMember,
    } = props;

    const [teamMembersLoaded, setTeamMembersLoaded] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isdelModelShow, setIsDelModelShow] = useState(false);
    let missingDataCheck = false;

    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedTeamMemberId, setSelectedTeamMemberId] = useState(null);
    const {
        initiatedeleteTeamMemberDetails,
        deleteTeamMemberDetailsMutationData,
    } = DeleteTeamMemberDetails();
    const {
        initGetTeamMembersResponseFunction,
        GetWebsiteTeamMembersResponseData: {
            loading: getTeamMembersLoading,
            called: getTeamMembersCalled,
        },
    } = GetWebsiteTeamMembersAction();
    const {
        initGetTeamMembersDetailsResponseFunction,
        GetWebsiteTeamMemberDetailsResponseData: {
            loading: teamMembersDetailsLoading,
        },
    } = GetWebsiteTeamMemberDetailsAction();
    const websitePrefilledData = useSelector(
        (state) => state.WebsiteDetailsReducer.websitePrefilledData
    );

    const [teamMember, setTeamMember] = useState({
        pwtm_full_name: "",
        pwtm_qualification: "",
        pwtm_job_role: "",
        pwtm_description: "",
        image: "",
    });

    const dispatch = useDispatch();

    const teamMembersData = useSelector(
        (state) => state.WebsiteDetailsReducer.teamMembersData
    );

    const handleSkipClick = () => {
        props.trackActivity({ activityStep: props.step + 2 });
        props.setStep(props.step + 1);
    };
    const [editIndex, setEditIndex] = useState(null);

    const handleDelete = (index) => {
        let temp = props.websiteData?.teamMembers.filter((item, i) => i !== index);
        props.setWebsiteData({ ...props.websiteData, teamMembers: temp });

        initiatedeleteTeamMemberDetails(
            {
                pwtmId: selectedTeamMemberId,
            },
            (success) => {
                console.log("success", success);
                initGetTeamMembersResponseFunction();
                setIsDelModelShow(false);
                // setTeamMembersLoaded(false);
            },
            (error) => {
                console.log("error", error);
                setIsDelModelShow(false);
            }
        );
    };

    const handleEdit = (id, index) => {
        setModalOpen(true);
        setEditIndex(index);

        initGetTeamMembersDetailsResponseFunction({
            getTeamMemberDetailsId: id,
        });

        // props.setWebsiteData({
        //   ...props.websiteData,
        //   teamMembers: {
        //     ...props.websiteData?.teamMembers,
        //     pwtm_id: id,
        //   }
        // });
    };

    useEffect(() => {
        console.log("initGetTeamMembersResponseFunction from add team member step");
        initGetTeamMembersResponseFunction();
        setTeamMembersLoaded(false);
    }, [props?.step, props?.editStep]);

    useEffect(() => {
        console.log(
            "prefill website data team member data from add team member step",
            teamMembersData,
            props.edit,
            getTeamMembersLoading,
            props.websiteData.businessName,
            teamMembersLoaded
        );

        console.log("prefill website data team member updating");
        setTeamMembersLoaded(true);
        props.setWebsiteData({
            ...props.websiteData,
            teamMembers: teamMembersData,
        });

    }, [teamMembersData, getTeamMembersLoading, props?.step, props.editStep]);

    console.log("teamMembersData", teamMembersData);

    useEffect(() => {
        teamMembersData && teamMembersData?.map((v, index) => {
            if (!v?.image || !v?.pwtm_description || !v?.pwtm_job_role || !v?.pwtm_full_name) {
                handleEdit(v?.pwtm_id,index)
            }
        })
    }, [teamMembersData])
    return (
        <>
            {
                <div className="inputWrapper">
                    <>
                        <div className="formInnerHeight" style={style}>
                            <ImageTitleComponent {...stepFiveData} />
                            {getTeamMembersLoading || teamMembersDetailsLoading ? (
                                <div>
                                    <SkeletonLoader type={"team-member-web"} />
                                    <SkeletonLoader type={"team-member-web"} />
                                    <SkeletonLoader type={"team-member-web"} />
                                </div>
                            ) : (
                                <div>
                                    {props.websiteData?.teamMembers?.map((teamMember, index) => {
                                        if (
                                            !teamMember?.pwtm_full_name ||
                                            !teamMember?.pwtm_job_role ||
                                            !teamMember?.pwtm_description ||
                                            !teamMember?.image
                                        ) {
                                            missingDataCheck = true;
                                        }
                                        return (
                                            <div className="team-member-container" key={index}>
                                                <div className="team-member-img-container">
                                                    {teamMember?.image ? (
                                                        <img
                                                            src={teamMember?.image}
                                                            alt="teamMemberProfileImage"
                                                        />
                                                    ) : (
                                                        <div className="team-member-usercircle text-center">
                                                            <p className="team-member-usercircle-text mb-0">
                                                                {teamMember?.pwtm_full_name?.split(" ")[1]
                                                                    ? teamMember?.pwtm_full_name?.split(
                                                                        " "
                                                                    )[0][0] +
                                                                    teamMember?.pwtm_full_name?.split(" ")[1][0]
                                                                    : teamMember?.pwtm_full_name?.split(
                                                                        " "
                                                                    )[0][0] +
                                                                    teamMember?.pwtm_full_name?.split(
                                                                        " "
                                                                    )[0][1]}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="team-member-details">
                                                    <div className="teamDetailsWrapper d-flex gap-2">
                                                        <p>
                                                            {/* Lara Steiner R.N */}
                                                            {teamMember?.pwtm_full_name}
                                                        </p>
                                                        <span className="my-auto p-0">
                                                            {/* Aesthetic Nurse */}
                                                            {teamMember?.pwtm_qualification}
                                                        </span>
                                                    </div>
                                                    <div className="teamDesc">
                                                        <p className="team-member-content my-auto p-0 text-start">
                                                            {teamMember?.pwtm_job_role}
                                                        </p>
                                                        <p className="team-member-content">
                                                            {/* Quis neque, eu et ipsum amet, vel et. Varius
                                  integer enim pellentesque ornare pharetra
                                  faucibs arcu... */}
                                                            {teamMember?.pwtm_description}
                                                        </p>
                                                    </div>
                                                    <div className="teamDetailsButton">
                                                        <div className="teamDetailsbutton">
                                                            <img className="my-auto" src={editIcon} alt="" />
                                                            <p
                                                                className="my-auto p-0"
                                                                onClick={() =>
                                                                    handleEdit(teamMember?.pwtm_id, index)
                                                                }
                                                            >
                                                                Edit details
                                                            </p>
                                                        </div>
                                                        {props.websiteData?.teamMembers.length > 1 && (
                                                            <div className="teamDetailsbutton">
                                                                <img
                                                                    className="my-auto"
                                                                    src={deleteIcon}
                                                                    alt=""
                                                                />
                                                                <p
                                                                    className="textRed my-auto"
                                                                    onClick={() => {
                                                                        // handleDelete(index);
                                                                        setSelectedTeamMemberId(
                                                                            teamMember?.pwtm_id
                                                                        );
                                                                        setSelectedIndex(index);
                                                                        setIsDelModelShow(true);
                                                                    }}
                                                                >
                                                                    Delete Member
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <hr className="mt-0 p-0" />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            <>
                                <div
                                    className="addMoreMemberBtn"
                                    onClick={() => {
                                        setEditIndex(null);
                                        dispatch({
                                            type: CLEAR_GENERATED_CONTENT,
                                        });
                                        setTeamMember({
                                            abc: "",
                                        });
                                        setModalOpen(true);
                                    }}
                                >
                                    <span className="Icon">
                                        <MdPersonAddAlt1 />
                                    </span>
                                    {/* <img src={addMemberIcon} alt="" />
                   */}
                                    <span>Add more team members</span>
                                </div>
                            </>
                        </div>
                        {ButtonAddTeamMember && (
                            <div className="FormButtonWrapper">
                                <StepButton
                                    blue={true}
                                    label={props?.edit ? "Go to Dashboard" : "Next"}
                                    disabled={props?.edit ? true : missingDataCheck}
                                    // isLeftIcon={props?.edit && true}
                                    // leftIcon={<FiChevronLeft className="arrow_next" />}
                                    icon={
                                        props?.edit ? "" : <FiChevronRight className="arrow_next" />
                                    }
                                    onClick={() => {
                                        props.trackActivity({ activityStep: props.step + 2 });
                                        if (props?.edit) {
                                            props?.setStep(10);
                                        } else {
                                            props.setStep(props.step + 1);
                                        }
                                    }}
                                />
                                {isVisibleSkip && <SkipButtonComp onClick={handleSkipClick} />}
                            </div>
                        )}
                    </>

                    {/* <>
              {

                getTeamMembersLoading
                  ?
                <div>
                  <div className="formInnerHeight" style={style}>
                    <ImageTitleComponent {...stepFiveData} />
                    <div
                      className="add-team-member-continer"
                      onClick={() => setModalOpen(true)}
                    >
                      <div>
                        <img src={userAddLogo} alt="userAddLogo" />
                      </div>
                      <p className="team-member-span">Click to add team member</p>
                    </div>
                  </div>
                  <div className="FormButtonWrapper">
                    <StepButton
                      onClick={() => {
                        if (props?.editStep) {
                          props?.setStep(0);
                        } else {
                          props.setStep(props.step + 1);
                        }
                      }}
                      blue
                      label={"Next"}
                      icon={<FiChevronRight className="arrow_next" />}
                    />
                    {!props?.editStep && (
                      <SkipButtonComp
                        onClick={handleSkipClick}
                        prevWebOnClick={prevWebOnClick}
                        isMobile={isMobile}
                      />
                    )}
                  </div>
                </div>
                :
                <div>
                  <SkeletonLoader type={"team-member-web"} />
                  <SkeletonLoader type={"team-member-web"} />
                  <SkeletonLoader type={"team-member-web"} />
              </div>
              }
            </> */}
                </div>
            }
            {/* Delete Model */}
            <CustomModal
                className="AddTeamMemberModal"
                modalBody={
                    <AddTeamMemberModal
                        teamMembersDetailsLoading={teamMembersDetailsLoading}
                        editIndex={editIndex}
                        setModalOpen={setModalOpen}
                        websiteData={props.websiteData}
                        setWebsiteData={props.setWebsiteData}
                        callTeamMemberApi={() => {
                            initGetTeamMembersResponseFunction();
                            setTeamMembersLoaded(false);
                        }}
                        teamMember={teamMember}
                        setTeamMember={setTeamMember}
                    />
                }
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
            />

            {/* Delete Model */}
            <CustomModal
                className="deleteModel"
                modalBody={
                    <div className="deleteModelWrapper">
                        <div className="deleteContentWrapper">
                            <img className="my-auto" src={deleteIcon} alt="" />
                            <p>
                                Are you sure you <br /> want to <b>“DELETE”</b> this member?
                            </p>
                        </div>
                        <div className="btnWrapperColom">
                            <button
                                onClick={() => {
                                    setIsDelModelShow(false);
                                }}
                            >
                                No
                            </button>
                            <button
                                onClick={() => {
                                    handleDelete(selectedIndex);
                                }}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                }
                modalOpen={isdelModelShow}
                setModalOpen={setIsDelModelShow}
            />
        </>
    );
};

export default AddTeamMemberStep;
