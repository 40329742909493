import React, { useEffect, useLayoutEffect, useState,useRef } from 'react'
import './HairProducts.scss'
import { useParams } from 'react-router-dom';
import HairProductHeader from './hair-product-common/hair-product-header/HairProductHeader'
import SubHeading from './hair-product-common/hair-product-sub-heading/SubHeading'
import HairProductCard from './hair-product-common/hair-product-card/HairProductCard'
import { ReactComponent as ProductImg1 } from '../../../assets/HairProduct/Produc-image.svg'
import { ReactComponent as ProductImg2 } from '../../../assets/HairProduct/Produc-image-2.svg'
import { ReactComponent as ProductImg3 } from '../../../assets/HairProduct/Produc-image-3.svg'
import StepButton from '../../common/StepButton'
import OrderSummary from './hair-product-common/hair-product-order-summary/OrderSummary'
import showToast from '../../common/CustomToast/CustomToast'
import HairProductsSecond from './hair-product-second/HairProductsSecond'
import HairProductButton from './hair-product-common/hair-product-whatsApp-btn/HairProductWhatsAppButton'
import HairProductsthird from './hair-product-third/HairProductsthird'
import HairProductsFour from './hair-product-four/HairProdutsFour'
import HairProductsFive from './hair-product-five/HairProductsFive'
import HairProductsSix from './hair-product-six/HairProductsSix'
import HairProductsSeven from './hair-product-seven/HairProductsSeven'
import { GetHairProductsDetails } from '../../redux/actions/hair-prescription-product/GetHairProductsDetails'
import SkeletonLoader from '../../common/Skeleton/SkeletonLoader'
import { useSelector } from 'react-redux'
import { GetHairProductsQuestions } from '../../redux/actions/hair-prescription-product/GetHairProductsQuestions'
import { SaveHaiPrescriptionRequestMutation, UpdateHairPrescriptionPaymentAction } from '../../redux/actions/hair-prescription-product/SaveHaiPrescriptionRequest';
import HairProductThankyouPage from './thank-you/HairProductThankyouPage';
import closeIcon from '../../../images/close_icon.svg'
import useWindowDimensions from "../../../utils/useWindowDimensions";
import HairProductIdentity from './hair-product-identity/HairProductIdentity';
import HairProductScalp from './hair-product-scalp/HairProductScalp';
import HairProductInfoPopup from './hair-product-info-popup/HairProductInfoPopup';
import HairProductCardNew from './hair-product-common/hair-product-card-new/HairProductCardNew';
import Slider from 'react-slick';

const HairProducts = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [gender, setGender] = useState("")
  const { width, height } = useWindowDimensions();
const scrollRef=useRef(null)
  const maleCheckboxData = [
    "I confirm I have read the disclaimer text and agree with the statements made.",
    "I confirm I am " + gender +" and live in the UK",
    "I confirm i am not currently trying to conceive and I am aware of the potential risks associated with using minoxidil and finasteride, including their effects on fertility.",
    "I will be the only user of any medication prescribed to me through this service.",
    "All answers are provided by me and are completely truthful.",
    'I will stop minoxidil straight away if I experience any symptoms of light-headedness or dizziness, and contact my healthcare professional.',
    'I am aware that minoxidil solution with finasteride, oral minoxidil and oral finasteride are prescribed as unlicensed products.',
    'I understand results may vary for each individual.',
    'I will not apply topical products to my scalp if the skin is dry, sore, broken or irritated.',
    'I understand  the  potential  side  effects,  risks  and  complications associated  with  the  medication  prescribed  to  me. I will seek a medical appointment or consultation and stop taking Finasteride if I experience any change in my mental state or if I experience suicidal thoughts.',
    'I will inform my medical professional if I think I am experiencing any side effects. And inform my GP of any changes to any medications.',
    'Do not allow individuals who are pregnant, breastfeeding, might become pregnant, or children handle this medication. These individuals should also avoid contact with the area of application (your scalp). If contact occurs, they must wash the site of contact on their skin immediately with soap and water.',
    'I will stop using the medication and contact my healthcare provider if there is no scalp hair regrowth after 6 months of use, chest pain, rapid heart beat, faintness or dizziness, sudden & unexplained weight gain, swelling of hands or feet, breast tenderness/enlargement, unwanted facial hair growth, sexual dysfunction (e.g., low libido, erectile dysfunction, decreased volume of ejaculate), symptoms of depression, severe scalp irritation.',
    'I understand that topical minoxidil and finasteride solution is an unlicensed medication.',
    'By ticking this box you confirm that all answers that have been provided are truthful and you consent to the treatment plan provided for you.',
  ]
  const femaleCheckboxData = [
    "I confirm I have read the disclaimer text and agree with the statements made.",
    "I confirm I am " + gender +" and live in the UK",
    "I confirm I am not pregnant, breastfeeding, trying to conceive or undergoing IVF.",
    "I will be the only user of any medication prescribed to me through this service.",
    "All answers are provided by me and are completely truthful.",
    'I will stop minoxidil straight away if I experience any symptoms of light-headedness or dizziness, and contact my healthcare professional.',
    'I am aware that minoxidil solution with finasteride, oral minoxidil and oral finasteride are prescribed as unlicensed products.',
    'I understand results may vary for each individual.',
    'I will not apply topical products to my scalp if the skin is dry, sore, broken or irritated.',
    'I understand  the  potential  side  effects,  risks  and  complications associated  with  the  medication  prescribed  to  me. I will seek a medical appointment or consultation and stop taking Finasteride if I experience any change in my mental state or if I experience suicidal thoughts.',
    'I will inform my medical professional if I think I am experiencing any side effects. And inform my GP of any changes to any medications.',
    'Do not allow individuals who are pregnant, breastfeeding, might become pregnant, or children handle this medication. These individuals should also avoid contact with the area of application (your scalp). If contact occurs, they must wash the site of contact on their skin immediately with soap and water.',
    'I will stop using the medication and contact my healthcare provider if there is no scalp hair regrowth after 6 months of use, chest pain, rapid heart beat, faintness or dizziness, sudden & unexplained weight gain, swelling of hands or feet, breast tenderness/enlargement, unwanted facial hair growth, symptoms of depression, severe scalp irritation.',
    'By ticking this box you confirm that all answers that have been provided are truthful and you consent to the treatment plan provided for you.',
  ]
  const [checkedItems, setCheckedItems] = useState(false);
  const [shippingDetails, setShippingDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    street: "",
    apartment: "",
    city: "",
    postcode: "",
    country: "",
    phone: "",
    phoneCountryCode: "+44",
    phoneCountry: ""
  })
  const [identityDoc, setIdentityDoc] = useState(null)
  const [scalpDoc, setScalpDoc] = useState([
    {
      image: null
    },
  ])
  const [dueAmount, setDueAmount] = useState(0)
  const [allAnswers, setAllAnswers] = useState([])
  const [intervalMonth, setIntevalMonth] = useState(1)
  
  const [active, setActive] = useState(1)
  const [activeMain, setActiveMain] = useState(1) // step 4 for THANK YOU PAGE
  const [currentEditStep, setCurrentEditStep] = useState(0)
  const [selectedInterval,setSelectedInterval]=useState(1)
  const HairProductData = useSelector((state) => state.HairProductDetailsReducer?.hairProductsDetail)
  const ErrorPage = useSelector((state) => state.HairProductDetailsReducer?.errorPage)
  const { initGetHairProductsDetails, hairProductDetailsQueryData } = GetHairProductsDetails()
  const [questions, setQuestions] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const HairProductQuestionsData = useSelector((state) => state?.GetHairProductQuestionsReducer?.allQuestions)
  const HairProductQuestionsCountData = useSelector((state) => state?.GetHairProductQuestionsReducer?.totalCount)
  const { slug } = useParams();
  const pagesize = 6
  const { initGetHairProductsQuestions, hairProductQuestionsQueryData } = GetHairProductsQuestions()
  const { initiateSaveHaiPrescriptionRequestResponseData, SaveHaiPrescriptionRequestMutationData } = SaveHaiPrescriptionRequestMutation()
  const { updateHairPrescriptionPaymentQueryData, initUpdateHairPrescriptionPaymentData } = UpdateHairPrescriptionPaymentAction()
  const [isIntroscreen , setIsIntroScreen] = useState(true)
  const [isoverView, setIsoverView] = useState(false);

  const { getPaymentIntent } = useSelector((state) => state?.GetHairPrescriptionProductPaymentDetailsReducer)


  console.log('selectedCard', selectedCard)
  useEffect(() => {
    
     initGetHairProductsDetails({slug:
        slug ? slug :""
     })
   }, [])

  useEffect(() => {
    
      if(scrollRef.current){
         window.scrollTo(0, 0);
      }
  
    
    // setTimeout(() => {
    //   window.scrollTo(0, 0);
    // }, 1);
  }, [active, activeMain, currentStep,scrollRef])

  useLayoutEffect(() => {
    setCurrentStep(currentEditStep !== 0 ? currentEditStep : 0);
  }, [currentEditStep]);

  useEffect(() => {
    // Preselect the first product if HairProductData is not empty
    if (HairProductData && HairProductData.length > 0) {
      setSelectedCard(HairProductData[0]);
    }
  }, [HairProductData]);

  const handleCardSelect = (cardData) => {
    setSelectedCard(cardData);
  };

  const errorCallbackFunc = () => {
    handleSaveHaiPrescriptionData({
      slug: slug,
      orderId: parseInt(getPaymentIntent.productReferenceId),
      generatePaymentIntent: true
    })
  }


  const handleContinuePayment = (token = null) => {
    if (token) {
      initUpdateHairPrescriptionPaymentData({
        orderId: parseInt(getPaymentIntent.productReferenceId),
        intentId: getPaymentIntent.intentId,
        token: token,
        stripeSavedCardId: null
      }, (data) => {
        setActiveMain(6)
      }, (err) => {
        errorCallbackFunc()
        console.log(err)
      })

      return
    }
  }

  const handleContinue = () => {
    if (selectedCard === null) {
      showToast("error", 'Please select a product')
    }
    else {
      setActive(active + 1);
    }
  }
console.log("intee",intervalMonth)
  const handleContinueSecond = (selectedGender) => {
    setGender(selectedGender);
    setActive(active + 1)
  }

  const handleContinueFour = (checkedItems) => {
    setCheckedItems(checkedItems);
    setActive(active + 1)
  }

  const handleContinueFive = (updatedShippingDetails) => {
    setShippingDetails(updatedShippingDetails);
    setActiveMain(activeMain + 1)
  }

  const handleContinueIdentity = (identityDoc) => {
    setIdentityDoc(identityDoc);
    setActiveMain(activeMain + 1)
  }

  const handleContinueScalpDoc = (scalpDoc) => {
    setScalpDoc(scalpDoc);
    setActiveMain(activeMain + 1)
  }


  // Transform the client answers data

  const clientAnswers = allAnswers?.flat()

  // const transformedClientAnswers = clientAnswers.map(answer => {
  //   // Swap the values of answer and comment
  //   const transformedAnswer = {
  //     ...answer,
  //     answer: answer.comment,
  //     comment: answer.answer
  //   };

  //   if (transformedAnswer.comment === "Yes") {
  //     transformedAnswer.comment = 1;
  //   } else if (transformedAnswer.comment === "No") {
  //     transformedAnswer.comment = 0;
  //   }

  //   return transformedAnswer;
  // });

  const genderAnswer = {
    answer: gender,
    comment: "",
    question_id: -1,
    question: "Gender?"
  };

  const processImageData = (identityDoc, scalpDoc) => {
    const filteredScalpDoc = scalpDoc.filter(doc => doc.image !== null && doc.image !== undefined);
    const imageData = [
      {
        image_type: 1,
        image: identityDoc
      },
      ...filteredScalpDoc.map((doc, index) => ({
        image_type: 2,
        image: doc.image,
        // Assuming image_type should be incremented by 1 for each scalpDoc image
        // If you have a different logic for image_type, adjust it accordingly
        // image_type: index + 2
      })),
    ];

    return imageData;
  };

  // Create a copy of the transformedClientAnswers array
  const updatedClientAnswers = [...clientAnswers];

  // Prepend the genderAnswer to the beginning of the array
  updatedClientAnswers.unshift(genderAnswer);

  const finaldata = {
    slug: slug,
    prdId: selectedCard?.id,
    interval: intervalMonth,
    clientFname: shippingDetails?.firstname,
    clientLname: shippingDetails?.lastname,
    clientEmail: shippingDetails?.email,
    clientPhone: shippingDetails?.phone,
    clientPhoneCountryCode: shippingDetails?.phoneCountryCode,
    clientPhoneCountry: shippingDetails?.phoneCountry,
    clientHouseNo: shippingDetails?.apartment,
    clientStreetName: shippingDetails?.street,
    clientCity: shippingDetails?.city,
    clientCountry: shippingDetails?.country,
    clientPostcode: shippingDetails?.postcode,
    clientAnswers: updatedClientAnswers,
    image: processImageData(identityDoc, scalpDoc),
    generatePaymentIntent: true
  }


  const handleContinueSix = (selectedInterval) => {
    setIntevalMonth(selectedInterval);
    const modifiedData = {
      ...finaldata,
      interval: selectedInterval
    };
    handleSaveHaiPrescriptionData(modifiedData);
  };
  console.log('finaldata', finaldata)

  const handleBackClick = () => {
    
    // Add the condition here to set activeMain to 4 when active is 6
    if (active === 6) {
      setActiveMain(4);
    }

    if (activeMain > 1) {
      setActiveMain(activeMain - 1);
    } else {
      if (active === 3) {
        if (currentStep > 0) {
          setCurrentStep(currentStep - 1);
          setCurrentEditStep(currentStep - 1);
        } else {
          setActive(active - 1);
        }
      } else if (active > 1) {
        setActive(active - 1);
      }
    }
  };

  const saveAnswers = (answers) => {
    setAllAnswers(answers); // Set the allAnswers state to the new answers array
  };

  const handleEditStep = (stepIndex) => {
    setActive(3); // Assuming steps are indexed from 1
    setCurrentEditStep(stepIndex)
    setCurrentStep(stepIndex)
  };

  const fetchQuestions = (currentStep) => {
    initGetHairProductsQuestions(currentStep + 1, pagesize)
  }

  useEffect(() => {
    const isDataPresent = questions[currentStep] && questions[currentStep]?.length > 0;

    if (!isDataPresent) {
      fetchQuestions(currentStep);
    }

  }, [currentStep, currentEditStep])

  useEffect(() => {
    setQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[currentStep] = HairProductQuestionsData;
      return updatedQuestions;
    });

  }, [HairProductQuestionsData, HairProductQuestionsCountData])


  const handleSaveHaiPrescriptionData = (data) => {
    initiateSaveHaiPrescriptionRequestResponseData(data, (res) => {
      if (res.success) {
        showToast("success", res.message, "", false)
        if (!data.orderId) {
         setActiveMain(1);
          setActive(6)
        }
      } else {
        showToast("error", res.message, "", false)
      }
    }, (err) => {
      showToast("error", err.message, "", false)
      console.log("hold duration", err)
    })
  }

      const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);

      useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 578,
            settings: {
              slidesToShow: 1.1,
              slidesToScroll: 1,
              arrows: false,
            }
          }
        ]
      };

      


  return (
    <div className='CustomeMainHairProduct'>
      {hairProductDetailsQueryData?.loading ?
        <SkeletonLoader type={"hair-product-main-page"} showCircle={false} />
        :
        <>
          {ErrorPage ?
            <>
              <div className="h-100 d-flex justify-content-center align-items-center text-center fw-bold fs-1 ">
                Invalid Link!
              </div>
            </> :
            <>
            {
              isIntroscreen ? 
              <HairProductInfoPopup setIsIntroScreen={setIsIntroScreen} />
              :
            <div className='main-hair-product-container'>
              {
                activeMain == 6 ? <>
                  <HairProductThankyouPage />
                </> :

                  <>
                  
                    <HairProductHeader onBackClick={handleBackClick} isBackBtn={active === 1 ? false : true} ViewOverviewBtn={activeMain === 1 && true} ViewOverviewBtnClick={() => { setIsoverView(true) }} />


                    <div className="hair-product-container cus-hairproduct-container mx-md-0" ref={scrollRef}>
                      {activeMain === 1 &&
                        <div className=" d-flex justify-content-between  flex-column flex-lg-row">
                          <div className="hair-product-container-left-content cus-hairproduct-left">
                            <div className={`hair-product-left-wrapper ${active === 1 ? "product-screen-wrapper" : ""}`}>
                              {active === 1 &&
                                <>
                                  <SubHeading
                                    title="Select Product"
                                    desc="Which product best suits your preference and lifestyle? Click to choose!"
                                  />
                                  <div className="hair-product-left-card-container flex-gap-normal">

                                  <div>
                                    {isSmallScreen ? (
                                      <>
                                        <div className="">
                                          {hairProductDetailsQueryData?.loading ?
                                            <SkeletonLoader type={"hair-product-card"} showCircle={false} />
                                            :
                                            <>
                                              <div className="slider-container">
                                                <Slider {...settings}>
                                                  {HairProductData?.map((product, index) => (
                                                    <div className='hair-product-padding'>
                                                      <HairProductCardNew
                                                        key={product.id}
                                                        icon={product.image}
                                                        title={product.name}
                                                        desc={product.description}
                                                        price={product?.lowPrice}
                                                        onemonthprice={product?.price}
                                                        threemonthprice={product?.threemonthPrice}
                                                        onSelect={() => handleCardSelect(product)}
                                                        // selected={selectedCard && selectedCard.id === product.id} // Assuming id is the unique identifier
                                                        selected={selectedCard && selectedCard.id === product.id}
                                                        className="cursor-pointer"
                                                        interval={intervalMonth} 
                                                        setIntevalMonth={setIntevalMonth}
                                                        
                                                        />
                                                      </div>
                                                      ))
                                                    }
                                                </Slider>
                                              </div>
                                            </>
                                          }
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="left-cards-div">
                                          {hairProductDetailsQueryData?.loading ?
                                            <SkeletonLoader type={"hair-product-card"} showCircle={false} />
                                            :
                                            <>
                                              {HairProductData?.map((product, index) => (
                                                <HairProductCardNew
                                                  key={product.id}
                                                  icon={product.image}
                                                  title={product.name}
                                                  desc={product.description}
                                                  price={product?.lowPrice}
                                                  onemonthprice={product?.price}
                                                  threemonthprice={product?.threemonthPrice}
                                                  onSelect={() => handleCardSelect(product)}
                                                  // selected={selectedCard && selectedCard.id === product.id} // Assuming id is the unique identifier
                                                  selected={selectedCard && selectedCard.id === product.id}
                                                  className="cursor-pointer"
                                                  interval={intervalMonth} 
                                                  setIntevalMonth={setIntevalMonth}
                                                  
                                                />
                                              ))
                                              }
                                            </>
                                          }
                                        </div>
                                      </>
                                    )}
                                  </div>
                                    
                                    <div className='product-screen-btn'>
                                      <div className="whatsapp-product-screen">
                                        <HairProductButton />
                                      </div>

                                      <div className="continue-btn-div-product-screen">
                                        <StepButton className="Continue-font" label={"Continue"} blue={true} onClick={() => handleContinue()} />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              }
                              {active === 2 &&
                                <>
                                  <HairProductsSecond genderprop={gender} OnhandleContinue={handleContinueSecond} />
                                </>
                              }
                              {active === 3 &&
                                <>
                                  <HairProductsthird HairProductQuestionsCountData={HairProductQuestionsCountData} hairProductQuestionsQueryData={hairProductQuestionsQueryData} onSaveAnswers={saveAnswers} OnhandleContinue={() => handleContinue()} allAnswers={allAnswers} currentEditStep={currentEditStep} setCurrentEditStep={setCurrentEditStep} questionData={questions} currentStep={currentStep} setCurrentStep={setCurrentStep} pagesize={pagesize} />
                                </>
                              }
                              {active === 4 &&
                                <>
                                  <HairProductsFour gender={gender} checkboxDataMain={gender == "male" ? maleCheckboxData : femaleCheckboxData} checkedItemsMain={checkedItems} OnhandleContinue={handleContinueFour} />
                                </>
                              }
                              {active === 5 &&
                                <>
                                  <HairProductsFive shippingDetails={shippingDetails} OnhandleContinue={handleContinueFive} />
                                </>
                              }
                              {active === 6 &&
                                <>
                                  <HairProductsSeven intervalMonth={intervalMonth} dueAmount={dueAmount} selectedCard={selectedCard} OnhandleContinue={handleContinuePayment} errorCallbackFunc={errorCallbackFunc} />
                                </>
                              }

                            </div>
                          </div>
                          <div className={isoverView ? "hair-product-container-right-content isoverViewActive" : "hair-product-container-right-content"} >
                            {isoverView && <div className='closeIconBtn' ><img src={closeIcon} alt={closeIcon} onClick={() => { setIsoverView(false) }} /></div>}
                            <OrderSummary active={active} intervalMonth={intervalMonth} dueAmount={dueAmount} selectedCard={selectedCard} allAnswers={allAnswers} onEditStep={handleEditStep} pagesize={pagesize} />
                          </div>
                        </div>
                      }

                      <div className="row justify-content-center">

                        {activeMain === 2 &&
                          <>
                            <HairProductIdentity activeMain={activeMain} identityDocProp={identityDoc} OnhandleContinue={handleContinueIdentity} />
                          </>
                        }
                        {activeMain === 3 &&
                          <>
                            <HairProductScalp activeMain={activeMain} scalpDocProp={scalpDoc} OnhandleContinue={handleContinueScalpDoc} />
                          </>
                        }
                        {activeMain === 4 &&
                          <>
                              <HairProductsSix activeMain={activeMain} setDueAmount={setDueAmount} selectedCard={selectedCard} OnhandleContinue={handleContinueSix} intervalMonth={intervalMonth} SaveHaiPrescriptionRequestMutationData={SaveHaiPrescriptionRequestMutationData} />
                          </>
                        }
                        {/* {activeMain === 5 &&
                          <>
                            <div className="col-5">
                              <HairProductsSeven intervalMonth={intervalMonth} dueAmount={dueAmount} selectedCard={selectedCard} OnhandleContinue={handleContinuePayment} errorCallbackFunc={errorCallbackFunc} />
                            </div>
                          </>
                        } */}
                      </div>
                    </div>
                  </>
              }


            </div>
            }
            </>
          }
        </>
      }
      {width < 991 &&
        <div className={isoverView ? "Overlay isActiveOverlay" : "Overlay"} onClick={() => { setIsoverView(false) }} />
      }
    </div>
  )
}

export default HairProducts