import React, { forwardRef } from 'react';
import './CustomeHeading.scss'
import CustomeSearchBar from '../customeSearchBar/customeSearchBar';

const CustomeHeading = forwardRef(({ Title, Desc, isSearch, onSearch, SearchPlaceholder, isSearchDisabled }, ref) => {
  return (
    <div className='SectionHeadingWrapper'>
      <div className="SectionHeading">
        <div className="Section-Title">{Title}</div>
        <div className="Section-Desc">{Desc}</div>
      </div>

      {isSearch &&
        <CustomeSearchBar isDisabled={isSearchDisabled} ref={ref} onSearch={onSearch} SearchPlaceholder={SearchPlaceholder} />
      }
    </div>
  )
})

export default CustomeHeading