import React from 'react'
import  "./SwitchButton.scss";

const SwitchButton = ({onChange,value,isChecked,isBlue=false}) => {
    return (
        <div>
            <label className={"switch"}>
                <input value={value} type="checkbox" className={"default"} onChange={onChange} checked={isChecked?true:false} />
                <span className={"slider"}></span>
            </label>
        </div>
    )
}

export default SwitchButton