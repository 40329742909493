export function extractFirstCharacters(str) {
    // Split the string into an array of words
    var words = str.split(' ');
  
    // Check if there are at least two words
    if (words.length >= 2) {
      // Take the first two words
      var firstTwoWords = words.slice(0, 2);
  
      // Extract the first character from each word
      var result = firstTwoWords.map(word => word[0]);
  
      // Join the characters into a single string
      console.log("result inital",firstTwoWords, result);
      return result.join('');
    } else {
      return null; // Or you can return a default value or an empty string
    }
  }

  export const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };