import React, { useEffect, useRef, useState } from 'react';
import QuestionsList from '../hair-product-common/questions/QuestionsList';
import SubHeading from '../hair-product-common/hair-product-sub-heading/SubHeading';
import StepButton from '../../../common/StepButton';
import HairProductWhatsAppButton from '../hair-product-common/hair-product-whatsApp-btn/HairProductWhatsAppButton';
import { useSelector } from 'react-redux';
import SkeletonLoader from '../../../common/Skeleton/SkeletonLoader';
import { capitalizeFirstLetter } from '../../../../utils/initials';
import Slider from "react-slick";

const HairProductsthird = ({ UpdateHairPrescriptionAmendAnswerMutationData, HairProductQuestionsCountData, hairProductQuestionsQueryData, onSaveAnswers, OnhandleContinue, allAnswers, currentEditStep, setCurrentEditStep, questionData, currentStep, setCurrentStep, pagesize, AmendAnswer = false }) => {
    const [stepOptions, setStepOptions] = useState(() => JSON.parse(JSON.stringify(allAnswers)));
    const [answerSetDone, setAnserSetDone] = useState(false)
    const [totalQuestions, setTotalQuestions] = useState(() => JSON.parse(JSON.stringify(HairProductQuestionsCountData)))
    const [showErrors, setShowErrors] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const sliderRef = useRef(null);
    const scrollRef=useRef(null)
    const [firstErrorIndex, setFirstErrorIndex] = useState(null);
    const [scrollerror, Setscrollerror] = useState(false)
    const ua = navigator.userAgent;
    const [shouldScroll, setshouldScroll] = useState(false);
    useEffect(() => {
        // Update stepOptions when allAnswers changes
        setStepOptions(JSON.parse(JSON.stringify(allAnswers)));
        setAnserSetDone(!answerSetDone)
    }, [currentStep, allAnswers]);


    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(0);
        }
    }, [currentStep]);

   

   
    // useEffect(()=>{
    //     window.scrollTo(0, 0);
    // },[])
    

    useEffect(() => {
        const initializeErrorMessages = () => {
            if (!stepOptions || !stepOptions[currentStep]?.length) {
                // If stepOptions is empty or doesn't have data for the current step,
                // initialize errorMessages with 'This field is required' messages for each question
                // return Array.from({ length: questionData[currentStep]?.length || 0 }, () => ({ answer: 'Please select an answer.', comment: '' }));
                const errorMessages = [];

                // Initialize error message for the first question
                errorMessages.push({
                    answer: "Please select an answer.",
                    comment: '',
                });

                // Initialize empty strings for the rest of the questions
                for (let index = 1; index < (questionData[currentStep]?.length || 0); index++) {
                    errorMessages.push({ answer: '', comment: '' });
                }

                return errorMessages;
            } else {
                // Otherwise, initialize errorMessages with empty strings for each question
                return Array.from({ length: questionData[currentStep]?.length || 0 }, () => ({ answer: '', comment: '' }));
            }
        };

        setErrorMessages(initializeErrorMessages());
    }, [currentEditStep, currentStep, questionData, answerSetDone, AmendAnswer ? allAnswers : null]);

   

    const errorElement = document.querySelector('.hair-product-error-message');
    useEffect(() => {
        // Scroll to the first error message when the firstErrorIndex changes
        if (firstErrorIndex !== null) {
            if (errorElement) {
                if (errorElement.innerHTML === "Please select an answer.") {
                    // errorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
                else {
                    const windowHeight = window.innerHeight;
                    const documentHeight = document.body.clientHeight;
                    const bottomOffset = 0; // Specify the distance from the bottom of the page where you want to scroll

                    // Calculate the scroll position from the bottom of the page
                    const scrollPosition = documentHeight - windowHeight - bottomOffset;
                    // errorElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
                    window.scroll({
                        top: scrollPosition,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
            }
            // const questionHeading = document.querySelector(`#question-heading-${firstErrorIndex}`);
            // if (questionHeading) {
            //     questionHeading.scrollIntoView({ behavior: 'smooth', block: 'center' });
            // }
        }
    }, [firstErrorIndex, scrollerror]);


    useEffect(() => {
        // setStepOptions(JSON.parse(JSON.stringify(allAnswers)));

        setTotalQuestions(HairProductQuestionsCountData)
    }, [HairProductQuestionsCountData]);

   
    const isFirefox = typeof navigator !== 'undefined' && navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const handleContinue = () => {

       
        setShowErrors(true);
        

        let isValid = true;
        let firstError = null;

        // Get the currently active slide
        const slider = document.querySelector('.slider-container');
        const currentSlide = slider.querySelector('.slick-active');

        // Get the index of the currently active slide
        const currentSlideIndex = currentSlide.getAttribute('data-index');


        const questiondataLength = questionData[currentStep].length
        const currentStepOptions = stepOptions[currentStep];

        // Check if stepOptions[currentStep] exists
        if (!currentStepOptions) {
            isValid = false;
            firstError = 0;
            setErrorMessages(prev => {
                // Make a copy of the previous error messages
                const newMessages = [...prev];

                // Update the error message for the current slide index
                newMessages[currentSlideIndex] = {
                    answer: 'Please select an answer.',
                    comment: '',
                };

                return newMessages;
            });
        } else {
            const option = currentStepOptions[currentSlideIndex];
            const errors = {};

            if (!option?.answer) {
                errors.answer = 'Please select an answer.';
                if (firstError === null) {
                    firstError = 0; // Set the index of the first error message
                }
            }

            if (!option?.comment && (capitalizeFirstLetter(option?.answer) === "Yes" || capitalizeFirstLetter(option?.answer) === "Other")) {
                errors.comment = 'Please provide a comment.';
                if (firstError === null) {
                    firstError = 0; // Set the index of the first error message
                }
            }

            if (Object.keys(errors).length > 0) {
                isValid = false;

                setErrorMessages(prevMessages => {
                    const newMessages = [...prevMessages];
                    newMessages[currentSlideIndex] = { ...newMessages[currentSlideIndex], ...errors };
                    return newMessages;
                });
            }
        }

        if (!isValid) {
            setFirstErrorIndex(firstError)
            Setscrollerror(!scrollerror)
            return;
        }

        if (parseInt(currentSlideIndex) === (questiondataLength - 1)) {
            onSaveAnswers(stepOptions);

            // if (parseInt(questionData.flat().length) === parseInt(totalQuestions)) {
            if (parseInt(currentStep + 1) >= (totalQuestions / pagesize)) {
                OnhandleContinue();
            } else {
                setCurrentStep(currentStep + 1);
                setCurrentEditStep(currentStep + 1);
                setShowErrors(false);
                if (sliderRef.current) {
                    sliderRef.current.slickGoTo(0);
                }
            }
        } else {
            if (sliderRef.current) {
                sliderRef.current.slickNext();
            }
            setshouldScroll(true)

       
            
           
        }
    };

    
    console.log("fireFox",isFirefox)
    
    const scrollToTop = () => {
        if (scrollRef.current) {
            const ua = navigator.userAgent;
            const headerHeight = 100;
            const elementPosition = scrollRef.current.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - headerHeight;
            const questionHeight = scrollRef.current.getBoundingClientRect().height;
            const viewportHeight = window.innerHeight;
            console.log("viewportHeight",questionHeight,viewportHeight)
                // if (isFirefox) {
                //     document.documentElement.scrollTop = offsetPosition;
                // }
                const questionBottomPosition = scrollRef.current.getBoundingClientRect().bottom + window.scrollY;
                const currentScrollY = window.scrollY;
                // const ensureQuestionVisible = () => {
                //     const currentScrollY = window.scrollY;
                //     const questionBottomPosition = scrollRef.current.getBoundingClientRect().bottom + window.scrollY;
                    
                //     if (questionBottomPosition > currentScrollY + viewportHeight) {
                //         window.scrollBy({
                //             top: questionBottomPosition - (currentScrollY + viewportHeight),
                //             behavior: 'smooth',
                //         });
                //     }
                // };
                if (isFirefox) {
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                    });
                    // scrollRef.current.scrollIntoView({
                    //     // top:offsetPosition,
                    //     behavior: 'smooth',
                    //     block: 'start',
                    // });
                    // document.documentElement.scrollTop = offsetPosition;

                    // Additional scroll to adjust for header height
                    const adjustScrollPosition = () => {
                        window.scrollBy({
                            top: questionBottomPosition - (currentScrollY + viewportHeight),
                            behavior: 'smooth',
                        });
                        // ensureQuestionVisible();
                    };
                    
                    requestAnimationFrame(() => {
                        setTimeout(adjustScrollPosition,350);
                    });
                    
                    // setTimeout(() => {
                    //     window.scrollBy({
                    //         top: -headerHeight,
                    //         behavior: 'smooth',
                    //     });
                    // }, 300);
                }
                 else {
                     window.scrollTo({
                           top: offsetPosition,
                           behavior: 'smooth',
                       });
                       
                   }
        }
    }; 

    useEffect(()=>{
        // if(shouldScroll){     
            scrollToTop()
          setshouldScroll(false)
     // }
    },[shouldScroll])
             

    
    const handleOptionChange = (index, option, comment, question) => {
        // setshouldScroll(false)
        const updatedOptions = [...stepOptions];
        if (!updatedOptions[currentStep]) {
            updatedOptions[currentStep] = [];
        }
        // updatedOptions[currentStep][index] = { answer: option, comment, question , id };
        AmendAnswer ? updatedOptions[currentStep][index] = { answer: option, comment, question: question?.que, id: question?.id } : updatedOptions[currentStep][index] = { answer: option, comment, question: question?.que, question_id: question?.id };
        setStepOptions(updatedOptions);

        // Clear error message for the answer if the option is not empty
        setErrorMessages(prevMessages => {
            const newMessages = [...prevMessages];
            if (option) {
                newMessages[index] = { ...newMessages[index], answer: '' };
            }
            return newMessages;
        });

        // Clear error message for the comment if the comment is not empty
        setErrorMessages(prevMessages => {
            const newMessages = [...prevMessages];
            // if (comment || option === 'No') {
            if (comment || (capitalizeFirstLetter(option?.answer) !== "Yes" || capitalizeFirstLetter(option?.answer) !== "Other")) {

                newMessages[index] = { ...newMessages[index], comment: '' };
            }
            return newMessages;
        });
    };

    var settings = {
        dots: false, // Disable dots navigation
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        swipe: false,
        adaptiveHeight: true
    }
    

    return (
        <>
            <div className='MainQuestionWrapper' >
                <SubHeading
                    title={`Questions: step ${currentStep + 1}`}
                    scrollRef={scrollRef}
                    desc="It is important you answer all questions truthfully, to ensure we have the correct medical history for you in order to create your bespoke treatment plan."
                />

                <div className='flex-gap-normal' >
                    <div className='flex-gap-35'>
                        {hairProductQuestionsQueryData?.loading ?
                            <div className='QuestionsSkelton'>
                                <div className='QuestionListPlaceholderWrapper'>
                                    <SkeletonLoader type={"QuestionList"} showCircle={false} />
                                    <SkeletonLoader type={"QuestionList"} showCircle={false} />
                                    <SkeletonLoader type={"QuestionList"} showCircle={false} />
                                    <SkeletonLoader type={"QuestionList"} showCircle={false} />
                                </div>
                            </div>
                            :
                            <>
                                <div className="slider-container">
                                    <Slider ref={sliderRef} {...settings}>
                                        {questionData[currentStep]?.map((question, index) => (
                                            <QuestionsList
                                                key={index}
                                                onChange={(option, comment) => handleOptionChange(index, option, comment, question)}
                                                selectedOption={stepOptions[currentStep] ? stepOptions[currentStep][index] : {}}
                                                label={question.que}
                                                // id={index}
                                                id={index + (currentStep * pagesize) + 1}
                                                answerErrorMessage={showErrors ? errorMessages[index]?.answer : ''}
                                                commentErrorMessage={showErrors ? errorMessages[index]?.comment : ''}
                                                question={question}
                                                scrollerror={`question-heading-${index}`}
                                                setshouldScroll={setshouldScroll}
                                            />
                                        ))}
                                    </Slider>
                                </div>
                            </>
                        }
                    </div>
                    <div className='bottomStickeyBtn'>
                        <div className="continue-btn-div">
                            {
                                AmendAnswer ?
                                    <StepButton label={"Continue"} blue={true}  onClick={() => {
                                        
                                                                                 handleContinue();
                                                                                //  handleScroll();
                                                                            }} isLoading={UpdateHairPrescriptionAmendAnswerMutationData?.loading} />
                                    :
                                    <StepButton label={"Continue"} blue={true}  onClick={() => {
                                       
                                                                             handleContinue();
                                                                            //  handleScroll();
                                                                             }} />
                            }
                        </div>

                        <div className="whatsapp-margin">
                            <HairProductWhatsAppButton />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HairProductsthird;
