import { gql, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

const UPLOAD_IDENTITY_PROOF_MUTATION = gql`
  mutation UploadIdentityProof($identityDocument: Upload) {
    uploadIdentityProof(identityDocument: $identityDocument) {
      success
      message
    }
  }
`;

export const UploadIdentityProof = () => {
  // const dispatch = useDispatch();
  const [
    UploadIdentityProofResponseFunction,
    uploadIdentityProofResponseData,
  ] = useMutation(UPLOAD_IDENTITY_PROOF_MUTATION);

  //this will be called from components
  const uploadIdentityProofQueryData =
    uploadIdentityProofResponseData; //variable

  const initUploadIdentityProofData = (
    data,
    successCallback,
    errorCallback
  ) => {
    UploadIdentityProofResponseFunction({
      variables: data,
    })
      // .then(({ data: { UploadIdentityProof: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        if (responseData?.data?.uploadIdentityProof?.success) {
          // dispatch(...)
          successCallback(responseData);
          // toast.success(responseData.message)
        } else {
          errorCallback(responseData);
          // toast.error(responseData.message)
        }
      })
      .catch((err) => {
        // errorCallback(err);
        console.log('typeeee:', err);
        toast.error('something went wrong!!!');
      });
  };

  return {
    uploadIdentityProofQueryData,
    initUploadIdentityProofData,
  };
};
