import React from 'react'
import './on-boarding-main.scss'
import closeIcon from '../../../images/close_icon.svg'
import businessIcon from '../../../images/on-boarding imgs/store.svg'
import serviceIcon from '../../../images/on-boarding imgs/event.svg'
import paymentIcon from '../../../images/on-boarding imgs/payment.svg'
import { useNavigate } from 'react-router'

const OnBoardingMain = () => {
    const navigate = useNavigate()
    const handleNavigate = () => {
        navigate('/get-started-onboarding/location')
    }
    const handleBackNavigate = () => {
        navigate('/business-dashboard')
    }
    return (
        <>
            <div className="onboarding-main">
                <div className="container">
                    <div className='onboarding__header mt-5' style={{ cursor: 'pointer' }} onClick={handleBackNavigate}>
                        <img src={closeIcon} alt='img' />
                    </div>
                    <div className="onboarding__content">
                        <h1>Almost Done!</h1>
                        <p>Finish setting up your profile to accept bookings.</p>
                    </div>
                    <div className='step_card_box'>
                        <div className='step_card' onClick={handleNavigate}>
                            <img src={businessIcon} alt='img' />
                            <span>Business & hours</span>
                            <p>Share your workspace location and operating hours with clients.</p>
                        </div>

                        <div className='step_card'>
                            <img src={serviceIcon} alt='img' />
                            <span>Add a Service</span>
                            <p>Start by listing your first service. You can easily add more once the first one is set up.</p>
                        </div>

                        <div className='step_card'>
                            <img src={paymentIcon} alt='img' />
                            <span>Get paid</span>
                            <p>Activate online payments to enable client online bookings.</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default OnBoardingMain
