import { CLEAR_FORWARD_CONSENT_FORM_QUESTIONS, GET_FORWARD_CONSENT_FORM_QUESTIONS } from "../../constants/forward-consent-form/ForwardConsentFormConst"



const initialState = {
    questionsList: [],
    disclaimersList:[]
}

export const forwardConsentFormQuestionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FORWARD_CONSENT_FORM_QUESTIONS:
            console.log("questions reducer",action.payload)
            return {
                ...state,
                questionsList: action.payload?.questions,
                disclaimersList: action.payload?.disclaimers,
            }
        case CLEAR_FORWARD_CONSENT_FORM_QUESTIONS:
            return {
                ...state,
                questionsList: [],
                disclaimersList: [],
            }
        default:
            return state
    }
}