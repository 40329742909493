import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";

const GET_AFTERCARE_DOCUMENTS_OF_APPOINTMENT = gql`
query GetAftercareDocumentsOfAppointment($appointmentId: Int) {
  getAftercareDocumentsOfAppointment(appointmentId: $appointmentId) {
    success
    message
    data {
      appointmentId
      consentFormRecordId
      aftercareDocuments {
        aftercareDocId
        aftercareDocName
      }
    }
  }
}
`;

export const GetAftercareDocumentsAppointmentAction = () => {
    const dispatch = useDispatch();
    const [
        GetAftercareDocumentsAppointmentResponseFunction,
        getAftercareDocumentsAppointmentResponseData,
    ] = useLazyQuery(GET_AFTERCARE_DOCUMENTS_OF_APPOINTMENT, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getAftercareDocumentsOfAppointment: responseData }) => {
            if (responseData.success) {

            } else {

            }
        },
    });
    //this will be called from components
    const getAftercareDocumentsAppointmentQueryData = getAftercareDocumentsAppointmentResponseData; //variable

    const initGetAftercareDocumentsAppointmentData = (data, successCallback, errorCallback) => {
        GetAftercareDocumentsAppointmentResponseFunction({
            variables: data,
        }).then((res) => {
            successCallback(res?.data?.getAftercareDocumentsOfAppointment);
        }).catch((err) => {
            errorCallback();
        });
    };

    return { getAftercareDocumentsAppointmentQueryData, initGetAftercareDocumentsAppointmentData };
};