// ========================COMPLETE BOOKING PAYMENT========================

import { gql, useMutation } from "@apollo/client";
import { ADD_REVIEWS_DATA_ERROR, ADD_REVIEWS_DATA_SUCCESS, CANCEL_APPOINTMENT_ERROR, CANCEL_APPOINTMENT_SUCCESS, UPDATE_OCCUPATIONS_ERROR, UPDATE_OCCUPATIONS_SUCCESS } from "../../constants";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const REPLY_ON_REVIEW_MUTATION = gql`
  mutation ReplyOnReview($elementId: Int, $reply: String) {
    replyOnReview(elementId: $elementId, reply: $reply) {
      success
      message
      data {
        reply_data {
          reply
          replied_on_time
        }
      }
    }
  }
`;


export const ReplyOnReviewMutationAction = () => {
  const dispatch = useDispatch();
  const [
    ReplyOnReviewResponseFunction,
    replyOnReviewResponseData,
  ] = useMutation(REPLY_ON_REVIEW_MUTATION)

  //this will be called from components
  const replyOnReviewQueryData = replyOnReviewResponseData; //variable

  const initReplyOnReviewData = (data) => {
    ReplyOnReviewResponseFunction({
      variables: data,
    })
      // .then(({ data: { ReplyOnReview: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        console.log('replyOnReview', responseData?.data?.replyOnReview?.success);
        if (responseData?.data?.replyOnReview?.success) {
          dispatch({
            type: ADD_REVIEWS_DATA_SUCCESS,
            payload: responseData
          })
          console.log('replyOnReview ', responseData?.data?.replyOnReview?.message);
          // successCallback(responseData)
          toast.success(responseData?.data?.replyOnReview?.message);
        }
        else {
          dispatch({
            type: ADD_REVIEWS_DATA_ERROR,
          })
          // errorCallback(responseData)
          // toast.error(responseData.message)
        }
      }).catch((err) => {
        console.log('myRes1', err);
        dispatch({
          type: ADD_REVIEWS_DATA_ERROR,
        })
        // errorCallback(err)
        // toast.error("something went wrong!!!")
      })
  }

  return { replyOnReviewQueryData, initReplyOnReviewData };
};
