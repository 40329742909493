import React, { useEffect, useState } from "react";
import Select from 'react-select';
import "./custom-dropdown.scss";
import CustomOption from "../custom-options/CustomOption";
import removeNewOption from '../../../images/icons/remove.svg'
import addNewOption from '../../../images/icons/add.svg'
const CustomDropdown = ({
  labelName,
  dropdownId,
  optionsArr,
  placeholdername,
  handleSelectedValue,
  isAddCourse,
  defaultValue,
  isNewOption,
  setIsNewOption,
  style,
  readOnly = true,
}) => {

  const colourStyles = {
    control: (styles, state) => ({
      ...styles, backgroundColor: '#f5f5f5', border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0
      }, height: "48px"
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        // backgroundColor: isDisabled ? 'red' : blue,
        // color: '#FFF',
        // cursor: isDisabled ? 'not-allowed' : 'default',
        border: isFocused ? 'none' : 'none'
      };
    },
  };

  const customStyle = {
    menuList: (provided, state) => {
      return { ...provided, zIndex: 9999 };
    },
    control: (styles, state) => ({
      ...styles, backgroundColor: '#FFFFFF', border: state.isFocused ? 0 : 0,
      outline: "none",
      appearance: "none",
      padding: "10px 10px",
      borderRadius: "10px",
      border: "1px solid #878787"
    })
  };
  const onMenuOpen = () => {
    setTimeout(() => {
      let selectedEl = document.getElementsByClassName("MyDropdown__option--is-selected")[0];
      if (selectedEl == undefined) {
        selectedEl = document.getElementById('react-select-3-option-43');
        if (selectedEl == undefined) {
          selectedEl = document.getElementById('react-select-5-option-43');
        }
      }
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }, 300);
  };

  return (
    <>
      <div className="custom-dropdown-div">
        <label
          htmlFor={dropdownId}
          className={isAddCourse ? "" : "dropdown-label mb-1"}
          style={{ visibility: labelName == "blank" && "hidden" }}
        >
          {labelName}
        </label>
        {
          dropdownId.includes("courseAccreditation") ?
            <>
              {
                isNewOption ?
                  <>
                    <div className="row">
                      <div className="col-md-10">
                        <input type="text" placeholder="Enter Here" onChange={(e) => handleSelectedValue(e.target.value)} />
                      </div>
                      <div className="col-md-1 cursor-pointer close-accreditor-icon" onClick={() => setIsNewOption(false)}>
                        <img src={removeNewOption} alt="close" />
                      </div>
                    </div>
                  </>
                  :
                  <Select
                    value={
                      defaultValue == null || defaultValue == '' ? null :
                        { value: defaultValue, label: <CustomOption label={optionsArr.find(e => e.id == defaultValue)?.title} logoUrl={optionsArr.find(e => e.id == defaultValue)?.logo_url} /> }
                    }
                    onChange={(e) => handleSelectedValue(e.value, dropdownId)}
                    options={(optionsArr.map(e => {
                      return ({
                        value: e.id,
                        label: <CustomOption label={e.title} logoUrl={e.logo_url} />,
                      })
                    }))
                      .concat([{ value: "", label: <button className="add-new-option d-flex justify-content-start align-items-center" onClick={() => setIsNewOption(true)}><div className="add-new-image"><img src={addNewOption} alt="" /></div> Add New</button> }])
                    }
                    isSearchable={false} // Add this line to disable the search functionality
                    styles={colourStyles}

                  />
              }
            </>
            :
            <>

              <Select
                name={dropdownId}
                id={dropdownId}
                menuPortalTarget={document.body}
                styles={style ? style : {
                  menuPortal: base => ({ ...base, zIndex: 9999 })
                }}

                onChange={(selectedOption) => handleSelectedValue(selectedOption.value, dropdownId)}
                value={defaultValue == null || defaultValue == '' ? null : { value: defaultValue, label: defaultValue }}
                options={[
                  placeholdername === "Select name" ? { value: '', label: placeholdername } : { value: '', label: placeholdername, isDisabled: true },
                  ...(optionsArr || []).map((item) => ({
                    value: item,
                    label: item
                  })),
                ]}
                isClearable={false}
                isSearchable={readOnly} // Add this line to disable the search functionality
                menuPlacement="bottom"
                menuShouldScrollIntoView={true}
                menuShouldBlockScroll={true}
                menuPosition="fixed"
                autoFocus
                onMenuOpen={() => onMenuOpen()}
                className={`MyDropdown ${defaultValue == undefined ? "default-value" : ""}`}
                classNamePrefix={"MyDropdown"}
              />
            </>
        }
      </div>
    </>
  );
};

export default CustomDropdown;
