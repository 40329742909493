import { 
    GET_PRODUCT_GET_CART_COUNT_DATA, 
    GET_PRODUCT_GET_CART_COUNT_DATA_ERROR, 
    GET_PRODUCT_GET_CART_COUNT_DATA_SUCCESS 
} from "../../constants"

export function getProductsGetCartCountData() {
    return {
        type: GET_PRODUCT_GET_CART_COUNT_DATA,
    }
}

export function getProductsGetCartCountDataSuccess(data) {
    return {
        type: GET_PRODUCT_GET_CART_COUNT_DATA_SUCCESS,
        payload: data,
    }
}

export function getProductsGetCartCountDataError(data) {
    return {
        type: GET_PRODUCT_GET_CART_COUNT_DATA_ERROR,
        payload: data,
    }
}