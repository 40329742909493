import { gql, useMutation } from "@apollo/client";

const SAVE_ONBOARDING_TREATMENT_MUTATION = gql`
mutation SaveTreatment($name: String, $duration: Int, $price: Float, $deposit: Float, $consentFormIds: [Int], $aftercareIds: [Int]) {
  saveTreatment(name: $name, duration: $duration, price: $price, deposit: $deposit, consentFormIds: $consentFormIds, aftercareIds: $aftercareIds) {
    success
    message
    data {
      elementId
    }
  }
}
`;


export const SaveOnBoardingTreatmentAction = () => {
    const [
        SaveOnBoardingTreatmentResponseFunction,
        saveOnBoardingTreatmentResponseData,
    ] = useMutation(SAVE_ONBOARDING_TREATMENT_MUTATION)

    //this will be called from components
    const saveOnBoardingTreatmentQueryData = saveOnBoardingTreatmentResponseData; //variable

    const initSaveOnBoardingTreatmentData = (data, successCallback, errorCallback) => {
        SaveOnBoardingTreatmentResponseFunction({
            variables: data,
        }).then(({ data: { saveTreatment: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback(responseData)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
        })
    }

    return { saveOnBoardingTreatmentQueryData, initSaveOnBoardingTreatmentData };
};