import axios from '../../../axios/index'
import { API_URL, BASE_URL } from "../../../axios/config";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import { 
    CREATE_SUPPORT_THREADS_DATA,
    GET_SUPPORT_CHAT_DATA, 
    GET_THREAD_CHAT_DATA, 
    SUPPORT_CHAT_CATEGORIES_DATA,
    SUPPORT_RECENT_ORDERS_DATA,
    GET_ORDER_TRACE_DATA,
    CREATE_SUPPOR_CHAT_THREADS_DATA,
    SUPPORT_THREADS_ADD_FEEDBACK_DATA,
    SUPPORT_THREADS_CHAT_UPDATE_DATA,
    SUPPORT_THREADS_MESSAGES_MARK_DATA,
    SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA,
} from "../../constants/index";
import { 
    getSupportChatDataError, 
    getSupportChatDataSuccess, 
    getThreadChatDataSuccess, 
    getThreadChatDataError, 
    getSupportChatCategoriesDataSuccess, 
    getSupportChatCategoriesDataError,
    createSupportThreadsDataSuccess,
    createSupportThreadsDataError,
    getSupportRecentOrdersDataSuccess,
    getSupportRecentOrdersDataError,
    getOrderTraceDataSuccess,
    getOrderTraceDataError,
    createSupportChatThreadsDataSuccess,
    createSupportChatThreadsDataError,
    supportThreadsAddFeedbackDataSuccess,
    supportThreadsAddFeedbackDataError,
    supportThreadsChatUpdateDataSuccess,
    supportThreadsChatUpdateDataError,
    supportThreadMessagesMarkReadSuccess,
    supportThreadMessagesMarkReadError,
    supportChatTotalUnreadCountDataSuccess,
    supportChatTotalUnreadCountDataError,
} from '../../actions';

async function getSupportChatDataAPI(data) {
    return axios.request({
        method: "POST",
        url: BASE_URL + API_URL.SUPPORT_CHAT_DATA_LINK,
        params: data,
    });
}

function* getSupportChatDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(getSupportChatDataAPI, payload);
        if (response.status === 200) {
            if (response.data.success) {
                yield put(getSupportChatDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        yield put(getSupportChatDataError(error));
        if (reject) reject(error);
    }
}

export function* getSupportChatDataWatcher() {
    yield takeLatest(GET_SUPPORT_CHAT_DATA, getSupportChatDataAction);
}

// ==================================================================

async function getThreadChatDataAPI(data) {
    return axios.request({
        method: "get",
        url: BASE_URL + API_URL.SUPPORT_CHAT_DATA_THREAD_MESSAGES,
        params: data
    });
}

function* getThreadChatDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(getThreadChatDataAPI, payload);
        if (response.status === 200) {
            if (response.data.success) {
                yield put(getThreadChatDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        yield put(getThreadChatDataError(error));
        if (reject) reject(error);
    }
}

export function* getThreadChatDataWatcher() {
    yield takeLatest(GET_THREAD_CHAT_DATA, getThreadChatDataAction);
}

// ==================================================================

async function getSupportChatCategoriesDataAPI(data) {
    return axios.request({
        method: "get",
        url: BASE_URL + API_URL.SUPPORT_CHAT_CATEGORIES_LIST,
        params: data
    });
}

function* getSupportChatCategoriesDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(getSupportChatCategoriesDataAPI, payload);
        if (response.status === 200) {
            if (response.data.success) {
                yield put(getSupportChatCategoriesDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        yield put(getSupportChatCategoriesDataError(error));
        if (reject) reject(error);
    }
}

export function* getSupportChatCategoriesDataWatcher() {
    yield takeLatest(SUPPORT_CHAT_CATEGORIES_DATA, getSupportChatCategoriesDataAction);
}

// ==================================================================

async function createSupportThreadsDataAPI(data) {
    return axios.request({
        method: "post",
        url: BASE_URL + API_URL.SUPPORT_THREADS_CREATE,
        data,
        headers: {
            "Accept": 'multipart/form-data',
            'Content-Type': 'multipart/form-data',
        },
    });
}

function* createSupportThreadsDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(createSupportThreadsDataAPI, payload);
        if (response.status === 200) {
            if (response.data.success) {
                yield put(createSupportThreadsDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        yield put(createSupportThreadsDataError(error));
        if (reject) reject(error);
    }
}

export function* createSupportThreadsDataWatcher() {
    yield takeLatest(CREATE_SUPPORT_THREADS_DATA, createSupportThreadsDataAction);
}

// ==================================================================

async function getSupportRecentOrdersDataAPI(data) {
    return axios.request({
        method: "get",
        url: BASE_URL + API_URL.SUPPORT_CHAT_RECENT_ORDERS_LIST,
        params: data
    });
}

function* getSupportRecentOrdersDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(getSupportRecentOrdersDataAPI, payload);
        if (response.status === 200) {
            if (response.data.success) {
                yield put(getSupportRecentOrdersDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        yield put(getSupportRecentOrdersDataError(error));
        if (reject) reject(error);
    }
}

export function* getSupportRecentOrdersDataWatcher() {
    yield takeLatest(SUPPORT_RECENT_ORDERS_DATA, getSupportRecentOrdersDataAction);
}

// ==================================================================

async function getOrderTraceDataAPI(data) {
    return axios.request({
        method: "get",
        url: BASE_URL + API_URL.GET_ORDER_TRACE_MESSAGE_DATA,
        params: data
    });
}

function* getOrderTraceDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(getOrderTraceDataAPI, payload);
        if (response.status === 200) {
            if (response.data.success) {
                yield put(getOrderTraceDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        yield put(getOrderTraceDataError(error));
        if (reject) reject(error);
    }
}

export function* getOrderTraceDataWatcher() {
    yield takeLatest(GET_ORDER_TRACE_DATA, getOrderTraceDataAction);
}

// ==================================================================

async function createSupportChatThreadsDataAPI(data) {
    return axios.request({
        method: "post",
        url: BASE_URL + API_URL.SUPPORT_CHAT_THREADS_CREATE,
        data,
        headers: {
            "Accept": 'multipart/form-data',
            'Content-Type': 'multipart/form-data',
        },
    });
}

function* createSupportChatThreadsDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(createSupportChatThreadsDataAPI, payload);
        if (response.status === 200) {
            if (response.data.success) {
                yield put(createSupportChatThreadsDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        yield put(createSupportChatThreadsDataError(error));
        if (reject) reject(error);
    }
}

export function* createSupportChatThreadsDataWatcher() {
    yield takeLatest(CREATE_SUPPOR_CHAT_THREADS_DATA, createSupportChatThreadsDataAction);
}

// ==================================================================

async function supportThreadsAddFeedbackDataAPI(data) {
    return axios.request({
        method: "post",
        url: BASE_URL + API_URL.SUPPORT_THREADS_ADD_FEEDBACK,
        data,
        headers: {
            "Accept": 'multipart/form-data',
            'Content-Type': 'multipart/form-data',
        },
    });
}

function* supportThreadsAddFeedbackDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(supportThreadsAddFeedbackDataAPI, payload);
        if (response.status === 200) {
            if (response.data.success) {
                yield put(supportThreadsAddFeedbackDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        yield put(supportThreadsAddFeedbackDataError(error));
        if (reject) reject(error);
    }
}

export function* supportThreadsAddFeedbackDataWatcher() {
    yield takeLatest(SUPPORT_THREADS_ADD_FEEDBACK_DATA, supportThreadsAddFeedbackDataAction);
}

// ==================================================================

async function supportThreadsChatUpdateDataAPI(data) {
    return axios.request({
        method: "post",
        url: BASE_URL + API_URL.SUPPORT_THREADS_CHAT_UPDATE,
        data,
    });
}

function* supportThreadsChatUpdateDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(supportThreadsChatUpdateDataAPI, payload);
        if (response.status === 200) {
            if (response.data.success) {
                yield put(supportThreadsChatUpdateDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        yield put(supportThreadsChatUpdateDataError(error));
        if (reject) reject(error);
    }
}

export function* supportThreadsChatUpdateDataWatcher() {
    yield takeLatest(SUPPORT_THREADS_CHAT_UPDATE_DATA, supportThreadsChatUpdateDataAction);
}

// ==================================================================

async function supportThreadMessagesMarkReadAPI(data) {
    return axios.request({
        method: "post",
        url: BASE_URL + API_URL.SUPPORT_THREADS_MESSAGES_MARK_READ,
        data,
    });
}

function* supportThreadMessagesMarkReadAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(supportThreadMessagesMarkReadAPI, payload);
        if (response.status === 200) {
            if (response.data.success) {
                yield put(supportThreadMessagesMarkReadSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        yield put(supportThreadMessagesMarkReadError(error));
        if (reject) reject(error);
    }
}

export function* supportThreadMessagesMarkReadWatcher() {
    yield takeLatest(SUPPORT_THREADS_MESSAGES_MARK_DATA, supportThreadMessagesMarkReadAction);
}

// ==================================================================

async function supportChatTotalUnreadCountDataAPI(data) {
    return axios.request({
        method: "post",
        url: BASE_URL + API_URL.SUPPORT_CHAT_TOTAL_UNREAD_COUNT,
        data,
    });
}

function* supportChatTotalUnreadCountDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(supportChatTotalUnreadCountDataAPI, payload);
        if (response.status === 200) {
            if (response.data.success) {
                yield put(supportChatTotalUnreadCountDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        yield put(supportChatTotalUnreadCountDataError(error));
        if (reject) reject(error);
    }
}

export function* supportChatTotalUnreadCountDataWatcher() {
    yield takeLatest(SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA, supportChatTotalUnreadCountDataAction);
}