import { CLEAR_TREATMENT_FOR_CLINIC, GET_TREATMENTS_FOR_CLINIC } from "../../constants/treatment-list-const/TreatmentListConst";



const initialState = {
  treatmentListForClinic: [],
  totalTreatmentCount: 0,
  loadedTreatmentCount: 0,
  treatmentListForClinicPage: 0,
  emptyTreatmentList: false
};


export default function treatmentsListForClinicReducer(state = initialState, action) {

  switch (action.type) {

    case GET_TREATMENTS_FOR_CLINIC:

      let temp = (state?.treatmentListForClinic).concat(action.payload.data?.treatments)

      return {
        ...state,
        treatmentListForClinic: temp,
        totalTreatmentCount: action.payload.data?.total,
        loadedTreatmentCount: temp.length,
        treatmentListForClinicPage: state.treatmentListForClinicPage + 1,
        emptyTreatmentList: action.payload.data?.treatments.length == 0 || action.payload?.data == null ? true : false
      };

    case CLEAR_TREATMENT_FOR_CLINIC:

      return {
        ...state,
        treatmentListForClinic: [],
        totalTreatmentCount: 0,
        loadedTreatmentCount: 0,
        treatmentListForClinicPage: 0,
        emptyTreatmentList: false
      };

    default:
      return state;
  }



}