import {
    GET_INSURANCE_COMPANY_DATA_SUCCESS,
    GET_INSURANCE_COMPANY_PAGE_DATA_SUCCESS
} from '../../constants/index'

const initialState = {
    insuranceCompanyData: null,
    insuranceCompanyPageData: null,
}
export const GetInsuranceCompanyDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INSURANCE_COMPANY_DATA_SUCCESS:
            return {
                ...state,
                insuranceCompanyData: action.payload.data,
            }
        case GET_INSURANCE_COMPANY_PAGE_DATA_SUCCESS:
            return {
                ...state,
                insuranceCompanyPageData: action.payload.data,
            }
        default:
            return state
    }
}  