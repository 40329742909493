import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import FinaceHeader from '../finaceHeader';

const FinanceStepsAbortPage = () => {
  return (
    <>
      <div className="">
        <FinaceHeader />
        <div className="container d-flex justify-content-center align-items-center vh-100">
          <div
            className="thankyouPageWrapper"
            style={{
              textAlign: 'center',
            }}
          >
            <h1 className="thankyouPageTitle">Sorry</h1>
            <p className="thankyouPageDesc">
              Unfortunately this time you were unsuccessful for finance.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceStepsAbortPage;
