import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { GetTreatmentwiseBeforeAfterPhotosCountAction } from '../../../redux/actions/appointments/GetTreatmentWiseBeforeAfterPhotosCount';
import BatchNumberPhoto from './BatchNumberPhoto';
import BatchNumberTreatmentListing from './BatchNumberTreatmentListing';
import { CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT } from '../../../redux/constants';

const BatchNumber = () => {

    const [step, setStep] = useState(1)
    const [isOtherPhotoUpload, setIsOtherUploadPhoto] = useState(false)
    const { initGetTreatmentwiseBeforeAfterPhotosCountData, getTreatmentwiseBeforeAfterPhotosCountQueryData } = GetTreatmentwiseBeforeAfterPhotosCountAction()
    const consentFormRecordId = useParams().consentFormRecordId;
    const [treatmentData, setTreatmentData] = useState([]);
    const appointmentDetails = useSelector((state) => state.appointmentReducer.treatmentWiseBeforeAfterCount);
    const [selectedTreatment, setSelectedTreatment] = useState(null)
    const [selectedTreatmentId, setSelectedTreatmentId] = useState(null)
    const dispatch = useDispatch();
    // const [consentFormRecordId, setConsentFormRecordId] = useState(null)


    useEffect(() => {
        dispatch({
            type: CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT,
            payload: null
        })
    }, [])

    useEffect(() => {
        if (appointmentDetails == null && step == 1)
            initGetTreatmentwiseBeforeAfterPhotosCountData({ consentFormRecordId: parseInt(consentFormRecordId), type: 2 })
    }, [step])

    useEffect(() => {
        if (appointmentDetails && step == 1) {
            setTreatmentData(appointmentDetails?.countDetails)
            // setConsentFormRecordId(appointmentDetails?.consentFormRecordId)
        }

    }, [appointmentDetails, step])

    return (
        <>
            {
                step == 1 &&
                <>
                    <BatchNumberTreatmentListing setIsOtherUploadPhoto={setIsOtherUploadPhoto} setStep={setStep} treatmentData={treatmentData} setSelectedTreatment={setSelectedTreatment} setSelectedTreatmentId={setSelectedTreatmentId} loading={getTreatmentwiseBeforeAfterPhotosCountQueryData?.loading} />
                </>
            }
            {
                step == 2 &&
                <>
                    <BatchNumberPhoto setStep={setStep} isOtherPhotoUpload={isOtherPhotoUpload} selectedTreatment={selectedTreatment} selectedTreatmentId={selectedTreatmentId} consentFormRecordId={consentFormRecordId} step={step} />
                </>
            }
        </>
    )
}

export default BatchNumber