import React, { useEffect, useRef, useState } from 'react'
import './time-section.scss'
import useDeviceDetect from '../../../common/useDeviceDetect';
import BannerHeader from '../../../common/BannerHeader';
import CustomRadioButton from '../../../common/CustomRadioButton';
import StepButton from '../../../common/StepButton';
import { ReactComponent as BackArrow } from '../../../../images/back_arrow.svg'
import SkeletonLoader from '../../../common/Skeleton/SkeletonLoader';
import HorizontalDateCalendar from './horizontal-date-calendar/HorizontalDateCalendar';
import { GetAvailableTimeSlotsAction } from '../../../redux/actions/dateTimeslots/DateTimeSlots';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useReactiveVar } from '@apollo/client';
import useWindowDimensions from "../../../../utils/useWindowDimensions";

const TimeSection = ({ timeData, showSelectedTime, setShowSelectedTime, handleSelectTimeSlot, setSubStep, setStep, showSelectedDate, setShowSelectedDate, setFormatedDate, clinicId, isOpenCalendar, setIsOpenCalendar, selectedTreatment }) => {
    const moment = require("moment");
    const { screenWidth } = useDeviceDetect();
    const morningTimeSlots = [];
    const afternoonTimeSlots = [];
    const eveningTimeSlots = [];
    const [loader, setLoader] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [flag, setFlag] = useState(false)

    // const [date,setDate]=useState
    console.log(showSelectedDate)
    const handleGetSelectedDay = (val) => {
        console.log(val)
    }

    const { initAvalableTimeSlotData, availableTimeSlotData } = GetAvailableTimeSlotsAction()
    const { isLastStep } = useSelector((state) => state.businessLocationListReducer);
    const timeslots = useSelector((state) => state?.GetDateTimeSlotsReducer?.timeslots)
    const totalTimeSlotsCount = useSelector((state) => state?.GetDateTimeSlotsReducer?.totalTimeSlotsCount)
    const timeSlotsPage = useSelector((state) => state?.GetDateTimeSlotsReducer?.timeSlotsPage)
    const loadedTimeSlotsCount = useSelector((state) => state?.GetDateTimeSlotsReducer?.loadedTimeSlotsCount)
    // useEffect(() => {
    //     setIsLoading(true)
    //     setTimeout(() => {
    //         setIsLoading(false)
    //     }, 2000)
    // }, [])
    const timingScrollRef = useRef()
    const handleNextClick = () => {
        setLoader(true)
        setTimeout(() => {
            handleSelectTimeSlot(showSelectedTime)
            setLoader(false)

            if (isLastStep) {
                setStep(5)
                return
            }
            setStep(4)
        }, 2000)
    }

    const initiateAvailableTimeSlotListCall = (refreshList = false) => {
        let newPage = timeSlotsPage + 1;
        if (refreshList) {
            newPage = 1;

        } else if (totalTimeSlotsCount <= loadedTimeSlotsCount) {
            return;
        }
        initAvalableTimeSlotData({
            clinicId: clinicId,
            date: moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD"),
            treatmentIds: selectedTreatment.map((item) => item.id),
            page: newPage,
            limit: 15,
            removePastSlots: true
        })
    }
    console.log(availableTimeSlotData?.loading, "availableTimeSlotData")



    const dimensions = useWindowDimensions();

    const [headerHeight, setHeaderHeight] = useState(0);
    const [BtnHeight, setBtnHeight] = useState(0);
    const [calenderHeaderHeight, setcalenderHeaderHeight] = useState(0);
  
      useEffect(() => {
  
          setHeaderHeight(
              document.getElementsByClassName("top-header")[0]?.offsetHeight || 0
          );
  
          setBtnHeight(
              document.getElementsByClassName("fixed-button")[0]?.offsetHeight
          );
          setcalenderHeaderHeight(
            document.getElementsByClassName("calenderWrapper")[0]?.offsetHeight
          );
  
  
          window.addEventListener("resize", handleResize);
      }, [headerHeight, BtnHeight, calenderHeaderHeight]);
  
      const handleResize = () => {
  
          setHeaderHeight(
              document.getElementsByClassName("top-header")[0]?.offsetHeight || 0
          );
  
          setBtnHeight(
              document.getElementsByClassName("fixed-button")[0]?.offsetHeight
          );
          setcalenderHeaderHeight(
            document.getElementsByClassName("calenderWrapper")[0]?.offsetHeight
        );
  
      };
  
    console.log(dimensions.height, headerHeight, 'dimensions', BtnHeight, calenderHeaderHeight);
  
    useEffect(()=>{
       if(showSelectedTime){ 
        const startIndex = timeslots.findIndex(slot => slot.time_slot === showSelectedTime);
        if(timeslots[startIndex]?.isBooked){
            setShowSelectedTime(null);
        }}
    },[showSelectedTime,timeslots])

    return (
        <>
            <div className="time-section CustomeHeightTimeSection">

                <div className='time-slot-mobile'>
                    <div className={screenWidth < 720 ? 'top-header' : "WidthFix"} style={isOpenCalendar ? { display: "none" } : {}}>
                        <BackArrow className='cursor-pointer' onClick={() => setSubStep(301)} />
                        <BannerHeader title={"What time?"} />
                    </div>

                    <div className={`time-div-section ${screenWidth < 720 ? "extra-top-div" : ""} `}>
                        <div className='calenderWrapper'>
                            <HorizontalDateCalendar
                                showSelectedDate={showSelectedDate}
                                setShowSelectedDate={setShowSelectedDate}
                                setFormatedDate={setFormatedDate}
                                clinicId={clinicId}
                                availableTimeSlotData={availableTimeSlotData}
                                initAvalableTimeSlotData={initiateAvailableTimeSlotListCall}
                                setShowSelectedTime={setShowSelectedTime}
                                setIsOpenMobileCalendar={setIsOpenCalendar}
                                isOpenMobileCalendar={isOpenCalendar}
                                selectedTreatment={selectedTreatment}
                                timingScrollRef={timingScrollRef}
                            />
                        </div>
                        <div className='wrappertimeslotcontent CustomeTimeSlotHeight' id="wrappertimeslotcontent" >
                            {
                                <div id="scrollableDiv" style={{ width: "100%",height: dimensions.height - headerHeight - BtnHeight - calenderHeaderHeight, overflowY: "scroll" }}>

                                    <InfiniteScroll
                                        dataLength={loadedTimeSlotsCount}
                                        next={() => {
                                            initiateAvailableTimeSlotListCall(false)
                                        }}
                                        hasMore={loadedTimeSlotsCount < totalTimeSlotsCount}
                                        scrollableTarget="scrollableDiv"
                                        ref={timingScrollRef}
                                    >
                                        {
                                            timeData?.length > 0 && timeData.map((timeSlot, index) => (

                                                <div className={`time-slot-content ${showSelectedTime === timeSlot.time_slot ? 'selected' : ''}`} key={index}>

                                                    <CustomRadioButton
                                                        text={timeSlot.time_slot} timeSlot={true} onChange={(e) => {
                                                            setShowSelectedTime(e.target.id)
                                                        }} id={timeSlot.time_slot} name="time" value={timeSlot.time_slot} checked={showSelectedTime === timeSlot.time_slot && timeSlot.isBooked === false }

                                                        disabled={timeSlot.isBooked}

                                                    />
                                                </div>
                                            ))
                                        }
                                        {
                                            availableTimeSlotData?.loading ?
                                                [...Array(10)].map((item, index) => <SkeletonLoader showCircle={false} type={"time"} />)
                                                : null
                                        }
                                    </InfiniteScroll>
                                </div>
                            }
                        </div>

                    </div>
                    {/* <div className='time-continue' onClick={()=>setSubStep(303)} >
                    Continue
                    </div> */}
                </div>


            </div>
            <div className={isOpenCalendar ? "d-none" : "fixed-button"}>{screenWidth < 720 && <StepButton blue={true} disabled={showSelectedTime == null} isLoading={loader} label={isLastStep ? `Continue to Checkout` : "Continue"} onClick={() => handleNextClick()} />}</div>
        </>
    )
}

export default TimeSection