
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const EXPORT_CLIENT_DETAILS_TO_CSV_QUERY = gql`
query ExportClientDataFromSidebar {
  exportClientDataFromSidebar {
    success
    message
    data
  }
}
`;

export const ExportClientCsvData = () => {
  const [
    exportClientCsvDataFunction,
    exportClientCsvDataResponseData,
  ] = useLazyQuery(EXPORT_CLIENT_DETAILS_TO_CSV_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ exportClientDataFromSidebar: responseData }) => {
      if (responseData.success) {
      } else {
      }
    },
  });
  
  const exportClientCsvDataQueryData = exportClientCsvDataResponseData; //variable

  const initexportClientCsvDataData = (data,successCallback,errorCallback) => {
    exportClientCsvDataFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.exportClientDataFromSidebar?.success) {
        successCallback(res);
      } else {
        errorCallback(res);
      }
    }).catch((err) => {
      errorCallback(err);
    });
  };

  return { exportClientCsvDataQueryData, initexportClientCsvDataData };
};
