// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_OTHER_BASIC_DETAILS_QUERY = gql`
query GetAccountancyOtherBasicDetails {
  getAccountancyOtherBasicDetails {
    success
    message
    data {
      country_code
      phone_no
    }
  }
}
`;

export const GetAccountancyOtherBasicDetailsAction = () => {
  // const dispatch = useDispatch();
  const [
    getAccountancyOtherBasicDetailsResponseFunction,
    getAccountancyOtherBasicDetailsResponseData,
  ] = useLazyQuery(GET_OTHER_BASIC_DETAILS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getAccountancyOtherBasicDetails: responseData }) => {
      if (responseData.success) {
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getAccountancyOtherBasicDetailsQueryData = getAccountancyOtherBasicDetailsResponseData; //variable

  const initGetAccountancyOtherBasicDetailsData = (data) => {
    getAccountancyOtherBasicDetailsResponseFunction({
      variables: data,
    });
  };

  return { getAccountancyOtherBasicDetailsQueryData, initGetAccountancyOtherBasicDetailsData };
};
