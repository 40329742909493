import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const INITIATE_FINANCE_LOAN_REQUEST_MUTATION = gql`
mutation InitiateFinanceApplicationFromLinksentInMail($financeLoanRequestId: Int, $redirectUrl: String) {
  initiateFinanceApplicationFromLinksentInMail(financeLoanRequestId: $financeLoanRequestId, redirectUrl: $redirectUrl) {
    success
    message
    data {
      encodedData
      item_data
      rid
      link
    }
  }
}
`;

export const InitiateFinanceLoanRequestGrl = () => {
  const [initiateFinanceLoanRequest, payLaterResponseData] =
    useMutation(INITIATE_FINANCE_LOAN_REQUEST_MUTATION);
  const dispatch = useDispatch();
  const initiateFinanceLoanRequestMutationData = payLaterResponseData;

  const initiateInitiateFinanceLoanRequest = (
    data,
    successCallback,
    errorCallback
  ) => {
    initiateFinanceLoanRequest({
      variables: data,
    })
      .then(({ data: { initiateFinanceApplicationFromLinksentInMail: responseData } }) => {
        if (responseData.success) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateInitiateFinanceLoanRequest, initiateFinanceLoanRequestMutationData};
};
