import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_BUSINESS_CLINIC_LIST } from "../../constants/business-location-const/BusinessLocationConst";
import { useSearchParams } from "react-router-dom";

const GET_BUSINESS_CLINIC_LIST_QUERY = gql`
query GetListOfBusinessClinics($userToken: String!, $page: Int, $limit: Int, $search: String, $encryptedClinicId: String) {
  getListOfBusinessClinics(user_token: $userToken, page: $page, limit: $limit, search: $search, encrypted_clinic_id: $encryptedClinicId) {
    success
    message
    data {
      company_name
      clinic_id
      is_finance_available
      is_stripe_setup
      is_ryft_setup
      clinic_DB_id
      clinic_url
      total
      items {
        clinic_id
        clinic_status
        next_step
        clinic_name
        address1
        address2
        image_url
        city
        postcode
        cln_average_rating
        total_reviews
        complete_address
        clinic_slug
      }
    }
  }
}
  `;

// var page;
export const BusinessClinicList = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [getBusinessClinicList, businessClinicListData] = useLazyQuery(
    GET_BUSINESS_CLINIC_LIST_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getListOfBusinessClinics: responseData }) => {

        dispatch({
          type: GET_BUSINESS_CLINIC_LIST,
          payload: responseData?.data,
          // page
        });
        if (responseData.success) {

        } else {

        }


      },
    }
  );
  const businessClinicListQueryData = businessClinicListData;
  const initBusinessClinicList = (data) => {
    // page = data.page

    let encryptedClinicId = null
    if (searchParams.get("clinic_id"))
      encryptedClinicId = searchParams.get("clinic_id");
    getBusinessClinicList({
      variables: { ...data, encryptedClinicId: encryptedClinicId },
    });
  };
  return { initBusinessClinicList, businessClinicListQueryData };
};



