import { gql, useMutation } from "@apollo/client";

const SAVE_LOCATION_DETAILS_MUTATION = gql`
mutation SaveLocationDetails($locationId: Int, $name: String, $houseNo: String, $streetName: String, $city: String, $postcode: String, $latitude: Float, $longitude: Float) {
  saveLocationDetails(locationId: $locationId, name: $name, houseNo: $houseNo, streetName: $streetName, city: $city, postcode: $postcode, latitude: $latitude, longitude: $longitude) {
    success
    message
  }
}
`;


export const SaveLocationDetailsAction = () => {
    const [
        SaveLocationDetailsResponseFunction,
        saveLocationDetailsResponseData,
    ] = useMutation(SAVE_LOCATION_DETAILS_MUTATION)

    //this will be called from components
    const saveLocationDetailsQueryData = saveLocationDetailsResponseData; //variable

    const initSaveLocationDetailsData = (data, successCallback, errorCallback) => {
        SaveLocationDetailsResponseFunction({
            variables: data,
        }).then(({ data: { saveLocationDetails: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback(responseData)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
        })
    }

    return { saveLocationDetailsQueryData, initSaveLocationDetailsData };
};