import React ,{useEffect, useState} from 'react'
import MainQueSection from './MainQueSection';
import "./product-question.scss"

import StepButton from '../../common/StepButton';
import { Immer, produce } from "immer";
import { toast } from "react-toastify"
import {CreateOrUpdateProductQuestionAction} from "../../redux/actions/product-questions/createOrUpdateProductQue"
import {GetProductQuestionsAction} from "../../redux/actions/product-questions/getProductQuestions"
import ConfirmationModal from '../finance-identity/finance-confirmationModal';
import {ReactComponent as BackArrow} from "../../../images/back_arrow.svg"
import {useParams} from "react-router"
import {webBaseUrl} from "../../../config"
  
const ProductQuestions = () => {
    const [questionType, setQuestionType] = useState(1); //1 : short 2 : mcq 3 : document
    const [disableTypeChange, setDisableTypeChange] = useState(false);
    const [createTest, setCreateTest] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModuleId, setDeleteModuleId] = useState(null);
    const [step,setStep]=useState(1)
    const [questionError,setQuestionError]=useState("")
    const [answerError,setAnswerError]=useState("")
    const [quesNumber,setQuesNumber]=useState(1)
  

    const {productId} =useParams()
   
    const [dummyQuestionIdentifier, setDummyQuestionIdentifier] = useState(1);
    const [queIndex,setQueIndex]=useState(0);
    const [questionsValue, setQuestionsValue] = useState(
    {
      id: 0,
      questionId: null,
      draftId:null,
      questionType:null,
      questionText: "",
      answerOptions:[
        {
          optionId: null,
          answerText: "",
          isCorrect: false,
        },
        {
          optionId: null,
          answerText: "",
          isCorrect: true,
        },
        
      ],

    },
  );

  


  const {createOrUpdateProductQuestionQueryData,initcreateOrUpdateProductQuestionData} =CreateOrUpdateProductQuestionAction()
  const {getProductQuestionQueryData,initGetProductQuestionData} =GetProductQuestionsAction()


  const handleDeleteModal = (index) => {
    setDeleteModuleId(index);
    setDeleteModal(true);
    
  };


  useEffect(()=>{
    initGetProductQuestionData({
      productId: parseInt(productId)
    })
},[])

console.log(`getProductQuestionQueryData`, getProductQuestionQueryData?.data);

useEffect(()=>{
    if(getProductQuestionQueryData?.data){
        const getProductdata=getProductQuestionQueryData?.data?.getProductQuestions?.data?.questions;
            console.log("getProductdata",getProductdata)
        if(getProductdata && getProductdata?.length && getProductdata !== null){
             if(getProductdata.length > queIndex){
              const answerOption=getProductdata[queIndex]?.options?.map((data,index)=>({
                optionId:data.id,
                answerText:data.option_text,
                isCorrect: data.isCorrect
                }))
                
                setQuestionType(getProductdata[queIndex]?.type)
                setQuestionsValue({
                   id:getProductdata[queIndex]?.id,
                  questionId: getProductdata[queIndex]?.id,
                  draftId:getProductdata[queIndex]?.draftId,
                  questionType:getProductdata[queIndex]?.type,
                  questionText: getProductdata[queIndex]?.question_text,
                  answerOptions:answerOption,
            
                },)
                setDisableTypeChange(true)
             }
             if(getProductdata.length <= queIndex){
              const newQuestion = {
               id: 0, 
               questionId: null,
               draftId:null,
               questionType: null,
               questionText: "",
               answerOptions: [
               {
                 optionId: null,
                 answerText: "",
                 isCorrect: false,
              },
              {
                optionId: null,
                answerText: "",
                isCorrect: true,
             },
             ],
              };
             setQuestionsValue(newQuestion);
            //  setQuestionType(1)
            setDisableTypeChange(false)
             }
            }
            // console.log("getProductdata[queIndex]?.question_text",getProductdata.questions[queIndex],queIndex)
    }

},[getProductQuestionQueryData?.data,queIndex,questionType])





  const nextQuestion=()=>{
    if (questionsValue.questionText === "") {
      setQuestionError(`Question  is required!`);
      return; // Prevent going to the next step if the question is empty
    }
    const hasEmptyAnswer = questionsValue.answerOptions.some(option => option.answerText === "");
    if (questionType === 2 && hasEmptyAnswer) {
      setAnswerError("Option is reqired!");
      return; 
    }
     setQuestionError("")
        setStep(step + 1);
        setQueIndex(queIndex + 1)
        setQuesNumber(quesNumber + 1 )
  }

  useEffect(()=>{
  },[step,quesNumber])

  const handleRemoveQuestion=()=>{
  
    if(questionsValue.id === deleteModuleId){
      setQuestionsValue({
        
        questionId: null,
        draftId:null,
        questionType:null,
        questionText: "",
        answerOptions:[
          {
            optionId: null,
            answerText: "",
            isCorrect: false,
          },
          {
            optionId: null,
            answerText: "",
            isCorrect: true,
          },
         ],
        })
    }
  setDisableTypeChange(false);
  setDeleteModal(false);
  setDeleteModuleId(null);
}

  const handleChange =(id, value)=>{
    
      let question = { ...questionsValue };
console.log("IIII",id,question.id)
      if (question.id === id) {
        question.questionText = value;
      }

      setQuestionsValue(question);
  }

  const handleOptionChange=(id, option_id, value)=>{
   

    let question = { ...questionsValue };

    if (question.id === id) {
      question.answerOptions = question.answerOptions.map((opEl, opInd) => {
        if (opInd === option_id) {
          return {
            ...opEl,
            answerText: value,
          };
        }
        return opEl;
      });
    }
    setQuestionsValue(question);
  }

const handleOptionRadioChange=(queIndex, quesId, option_id)=>{
  

  let question = { ...questionsValue };

  question.answerOptions = question.answerOptions.map((e, i) => ({
    ...e,
    isCorrect: i === option_id, 
  }));

  setQuestionsValue(question);
}

const handleSubmit=(e)=>{

  const getProductdata=getProductQuestionQueryData?.data?.getProductQuestions?.data?.questions;
  const answerOptions = questionsValue.answerOptions.map((op) => ({
      optionId: op.optionId,  
      answerText: op.answerText,
      isCorrect: op.isCorrect
    }));

    const questionObj = {
      questionId:getProductdata && getProductdata[queIndex]?.id ? getProductdata[queIndex]?.id: questionsValue.questionId,  // Assuming questionId will be null if it's a new question
      draftId:getProductdata && getProductdata[queIndex]?.draftId ? getProductdata[queIndex]?.draftId: questionsValue.draftId,
      questionType:questionType,
      questionText: questionsValue.questionText,
      answerOptions: answerOptions  // Attach the formatted answer options
    };
    
     if(questionsValue.questionText === ""){
      setQuestionError("Question is reqired!")
      return;
     }
     const hasEmptyAnswer = questionsValue.answerOptions.some(option => option.answerText === "");
    if (questionType === 2 && hasEmptyAnswer) {
      setAnswerError("Option is reqired!");
      return; 
    }
    setQuestionError("")
    setAnswerError("")

  initcreateOrUpdateProductQuestionData({
    productId:parseInt(productId),
    question: questionObj
   },(data)=>{
      if(data){
        console.log(`data1111`, data);
        toast.success("Question Saved !")
        initGetProductQuestionData({
          productId: parseInt(productId)
        })
      }
  },(error)=>{
      console.log(`error`, error);
  })
}



const handleBack=()=>{
  if(step === 1 && queIndex === 0 && quesNumber === 1){
    window.location.href = webBaseUrl + "/product/lists";

  }
  if(step > 0 && queIndex > 0 && quesNumber > 0){
    setStep(step-1)
    setQueIndex(queIndex - 1)
    setQuesNumber(quesNumber - 1 )
    
 }
  setQuestionError("")
  setAnswerError("")
 
  initGetProductQuestionData({
    productId: parseInt(productId)
  })
 
}

console.log("ste1111p",step,queIndex,quesNumber)

return (
    <>
      <div className='ProductQuestionsSec'>
        <div className='backArrowWrapper'>
          <BackArrow  className='backBtn' onClick={()=>handleBack()}/>
        </div>
        <div className='container'>
          <div className='main-area'>
              <div className='product-question-div'>
              
                      <MainQueSection 
                          queIndex={queIndex}
                          quesId={questionsValue.id}
                          question={questionsValue}
                          questionType={questionType}
                          setQuestionType={setQuestionType}
                          handleRemoveQuestion={handleDeleteModal}
                          handleChange={handleChange}
                          handleOptionChange={handleOptionChange}
                          handleOptionRadioChange={handleOptionRadioChange}
                          handleSubmit={handleSubmit}
                          disabled={disableTypeChange}
                          step={step}
                          setStep={setStep}
                          questionError={questionError}
                          quesNumber={quesNumber}
                          answerError={answerError}
                      />
                   
                

                <div className='add-question-section'>
                  <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div className="BtnBottom">
                        <button className="save-btn" type="submit" onClick={()=>{
                        nextQuestion()
                        handleSubmit()
                        }
                        
                        }>
                          Save and Next
                        </button>
                      </div>
                    </div>

                </div>

              </div>
            

          </div>
        </div>
          
        <ConfirmationModal
        open={deleteModal}
        setOpen={setDeleteModal}
        title="Are you sure?"
        className={"ConfirmationDeleteModal"}
        content="Are you sure you want to delete this Question?"
        onConfirm={() => {
                  handleRemoveQuestion();
        }}
        onCancel={() => setDeleteModal(false)}
      />
        
        
     </div>

    </>
  )
}

export default ProductQuestions