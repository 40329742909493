import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from 'react-redux';
import { useDropzone } from "react-dropzone";
import groupIcon from "../../../assets/createWebsite/user-add.svg";
// import InputFieldsComponent from '../createWbsiteURL/FormComp';
import InputComponent from "../../common/InputComponent";
import { Badge, FormGroup, Label } from "reactstrap";
import teamLogo from "../../../images/Left.svg";
import StepButton from "../../common/StepButton";
import "./addTeamMemberModal.scss";
import SkipButtonComp from "../createWbsiteURL/commonComponents/SkipBtn";
import { Button } from "reactstrap";
import { MdPersonAddAlt1 } from "react-icons/md";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { SaveTeamMemberDetails } from "../../redux/actions/createWebsite/saveTeamMemberDetails";
import { useSelector } from "react-redux";
import { GenerateBusinessContentAction } from "../../redux/actions/createWebsite/generateBusinessContent";
import loadingIcon from "../../../images/loading.gif";
import toast from 'react-hot-toast';
import { Toaster } from "react-hot-toast";
import ToastbarComponent from "../../common/ToasterComponent";
import CustomeLoading from "../../customeSpiner";
import { set } from "lodash";


const AddTeamMemberModal = (props) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const dimensions = useWindowDimensions();
  const fileInputRef = useRef(null);
  const [generateClicked, setGenerateClicked] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [validTeamMember, setValidTeamMember] = useState(false);

  const dispatch = useDispatch();

  const [addTimeFooterButton, setAddTimeFooterButton] = useState(0);

  const { initiateSaveTeamMemberDetails, saveTeamMemberDetailsMutationData } = SaveTeamMemberDetails();
  const { initGenerateBusinessContentResponseFunction, generateBusinessContentResponseData: { loading: generateContentLoading } } = GenerateBusinessContentAction();


  const teamMembersEditData = useSelector((state) => state.WebsiteDetailsReducer.teamMembersEditData);
  const teamMemberDescription = useSelector((state) => state.WebsiteDetailsReducer.teamMemberDescription);

  useEffect(() => {
    setAddTimeFooterButton(
      document.getElementsByClassName("FormButtonWrapper")[0]?.offsetHeight
    );

    window.addEventListener("resize", handleResize);
  }, [addTimeFooterButton]);

  const handleResize = () => {
    setAddTimeFooterButton(
      document.getElementsByClassName("FormButtonWrapper")[0]?.offsetHeight
    );

  };


  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    console.log("file", file, 'file chocho')
    setUploadedImage(file);
    reader.onload = () => {
      setShowLoader(true)
      props.setTeamMember({ ...props.teamMember, image: reader.result });
    };

    reader.readAsDataURL(file);
  };

  const onImageClick = () => {
    fileInputRef.current.click();
  };


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    }
  });


  useEffect(() => {
    if (showLoader) {
      setTimeout(() => {
        setShowLoader(false)
      }, 800);
    }
  }, [showLoader])

  useEffect(() => {
    props.setTeamMember(teamMembersEditData);
  }, [teamMembersEditData]);

  useEffect(() => {
    if (teamMemberDescription && generateClicked) {
      props.setTeamMember({
        ...props.teamMember,
        pwtm_description: teamMemberDescription,
      });
    }
  }
    , [teamMemberDescription]);

  useEffect(() => {
    console.log("setValidTeamMember effect", props.teamMember?.pwtm_full_name, props.teamMember?.pwtm_qualification, props.teamMember?.pwtm_job_role, props.teamMember?.pwtm_description, '---image---', props.teamMember?.pwtm_image, '---selected-image---', props?.teamMember?.image)
    if (props.teamMember?.pwtm_full_name && props.teamMember?.pwtm_job_role && props.teamMember?.pwtm_description && (props.teamMember?.pwtm_image || props.teamMember?.image)) {
      setValidTeamMember(true);
    }
    else {
      setValidTeamMember(false);
    }
  }, [props.teamMember?.pwtm_full_name, props.teamMember?.pwtm_qualification, props.teamMember?.pwtm_job_role, props.teamMember?.pwtm_description, props.teamMember?.pwtm_image, props?.teamMember?.image])

  console.log(dimensions.height, 'dimensions.height')

  return (
    <div className="add-team-member-modal">
      <div className="addteamInnerHeightSet" style={{ height: dimensions.height < 720 ? dimensions.height - addTimeFooterButton - 110 - 50 + 70 + "px" : "auto" }}>
        <h5 className="text-start">Picture of the team members</h5>
        {
          (props.teamMember?.image?.trim(" ").length > 0 ? (
            <div className="uploaded-image text-center my-4" onClick={onImageClick}>
              {showLoader ? <div style={{ marginTop: "60px" }}><CustomeLoading /></div> : <img src={props.teamMember?.image} alt="Uploaded" />}
              {/* Add an input element for re-uploading */}
              <input
                {...getInputProps()}
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(e) => onDrop(e.target.files)}
              />
            </div>
          ) : (
            <div
              {...getRootProps()}
              className={`drop-zone ${isDragActive ? "active" : ""}`}
            >
              <div className="text-center">
                <input {...getInputProps()} className="mx-auto" />
                <img src={groupIcon} alt="" className="mx-auto" />
                <h2 className="mx-auto">
                  {isDragActive
                    ? "Drop the file here"
                    : "Drag & drop file or browse"}
                </h2>
                <h3 className="mx-auto">Supported format: PNG, JPG, JPEG</h3>
                <h3 className="mx-auto">(Maximum upload file size: 5 MB)</h3>
              </div>
            </div>
          ))}
        <div>
          <div className="row">
            <div className="col-md-9">
              <InputComponent
                type="name"
                label={"Team member full name"}
                placeholder={"Ex; Chris do"}
                value={props.teamMembersDetailsLoading ? "" : props.teamMember?.pwtm_full_name}
                teamMember={props.teamMember}
                setTeamMember={props.setTeamMember}
              />
            </div>
            <div className="col-md-3">
              <InputComponent
                type="qualification"
                label={"Qualification"}
                placeholder={"Ex; RN"}
                value={props.teamMembersDetailsLoading ? "" : props.teamMember?.pwtm_qualification}
                teamMember={props.teamMember}
                setTeamMember={props.setTeamMember}
              />
            </div>
          </div>
          <InputComponent
            type="jobRole"
            label={"Job role"}
            placeholder={"Ex; CEO and  business manager"}
            value={props.teamMembersDetailsLoading ? "" : props.teamMember?.pwtm_job_role}
            teamMember={props.teamMember}
            setTeamMember={props.setTeamMember}
          />
          {/* <InputComponent
          type="jobRoleDesc"
          label={"Job Role Description"}
          placeholder={"Tell us a little bit about yourself"}
          value={teamMember?.pwtm_description}
          teamMember={teamMember}
          props.setTeamMember={props.setTeamMember}
        /> */}
          <FormGroup className="inpt_field">
            <Label className="lable">Job role description</Label>
            <div className="textAreaWrapper">
              <textarea
                disabled={generateContentLoading}
                className="textarea"
                type="jobRoleDesc"
                name="jobRoleDesc"
                id="jobRoleDesc"
                placeholder="Small bio about the services you offer..."
                maxlength="750"
                // minlength="185"
                value={props.teamMembersDetailsLoading ? "" : props.teamMember?.pwtm_description}
                onChange={(e) => (props.setTeamMember({ ...props.teamMember, pwtm_description: e.target.value }))}
              ></textarea>
              <p className="text-area-limit"> {props.teamMember?.pwtm_description?.length}/750</p>
            </div>
          </FormGroup>


          <div className={"budge-class"}>
            <Badge
              className={"tagline_budge"}
              onClick={() => {
                console.log(props.teamMember?.pwtm_full_name, props.teamMember?.pwtm_qualification, props.teamMember?.pwtm_job_role, "test here", props.teamMember?.pwtm_full_name === "" || props.teamMember?.pwtm_qualification === "" || props.teamMember?.pwtm_job_role === "")
                if (props.teamMember?.pwtm_full_name === "" || props.teamMember?.pwtm_job_role === "") {
                  toast(
                    "Name and job role is mandatory", { icon: '' }
                  )
                }
                else {
                  setGenerateClicked(true);
                  initGenerateBusinessContentResponseFunction({
                    type: 3,
                    name: props.teamMember?.pwtm_full_name,
                    qualification: props.teamMember?.pwtm_qualification,
                    jobRole: props.teamMember?.pwtm_job_role,
                  })

                }
              }
                // props.setTeamMember({
                //   ...teamMember,
                //   pwtm_description: "this is AI generated description",
                // })
              }
            >
              {
                (generateContentLoading) ? <div className="m-auto"><span className="pe-3">Generating</span><img src={loadingIcon} style={{ width: "20px", height: "20px" }} /></div> :
                  "Generate description"
              }
            </Badge>
          </div>
        </div>
      </div>
      <div className="FormButtonWrapper">
        <StepButton
          isLoading={saveTeamMemberDetailsMutationData?.loading}
          disabled={generateContentLoading || (!validTeamMember)}
          onClick={() => {
            initiateSaveTeamMemberDetails({
              saveTeamMemberdetailsId: props.teamMember?.pwtm_id,
              name: props.teamMember?.pwtm_full_name,
              qualification: props.teamMember?.pwtm_qualification,
              jobRole: props.teamMember?.pwtm_job_role,
              description: props.teamMember?.pwtm_description,
              image: uploadedImage,
            }, (responseData) => {
              props.callTeamMemberApi();
              props.setModalOpen(false);
              if (props.editIndex !== null) {
                const teamMembers = props.websiteData?.teamMembers;
                teamMembers[props.editIndex] = props.teamMember;
                props.setWebsiteData({
                  ...props.websiteData,
                  teamMembers: [...teamMembers],
                });
              } else {
                props.setWebsiteData({
                  ...props.websiteData,
                  teamMembers: [...props.websiteData?.teamMembers, props.teamMember],
                });
              }
            });

            // if (props.editIndex !== null) {
            //   const teamMembers = props.websiteData?.teamMembers;
            //   teamMembers[props.editIndex] = props.teamMember;
            //   props.setWebsiteData({
            //     ...props.websiteData,
            //     teamMembers: [...teamMembers],
            //   });
            // } else {
            //   props.setWebsiteData({
            //     ...props.websiteData,
            //     teamMembers: [...props.websiteData?.teamMembers, teamMember],
            //   });
            // }

          }}
          blue
          isLeftIcon={true}
          leftIcon={<MdPersonAddAlt1 />}
          label={props.editIndex !== null ? "Update" : "Add Team Member"}
        // icon={<MdPersonAddAlt1 />}
        />
        <div className="SkpButtonWrapper">
          <Button
            type="button"
            className="btn skpRadiusButton"
            onClick={() => {
              props.setModalOpen(false);
              dispatch({ type: "SET_TEAM_MEMBER_EDIT_DATA", payload: null });
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
      <ToastbarComponent toast={toast} />
    </div>
  );
};

export default AddTeamMemberModal;
