// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { GET_DASHBOARD_DATA } from "../../constants";

const GET_USER_DASHBOARD_DATA_QUERY = gql`
 query GetUserDashboardData {
  getUserDashboardData {
    success
    message
    data {
      averageRating
      total_onboarding_steps
      pin_expiry_date
      insurance_expiry_date
      totalPractitionersForReview
      reviewRank
      calender_details {
        cld_rc_title
        cld_rc_start
        color
        calender_date
      }
      latest_reviews {
        cln_rvw_cl_dt_id
        review_rating
        review_feedback
        created_at
        review_reply
        client_fname
        client_lname
        client_image
        review_date
      }
      bannerData {
        bannerType
        bannerShow
        bannerName
      }
    }
  }
}
`;

export const GetUserDashboardDataAction = () => {
  const dispatch = useDispatch();
  const [
    getUserDashboardDataResponseFunction,
    getUserDashboardDataResponseData,
  ] = useLazyQuery(GET_USER_DASHBOARD_DATA_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getUserDashboardData: responseData }) => {
      if (responseData.success) {
        // toast.success(responseData?.message)
        dispatch({
          type: GET_DASHBOARD_DATA,
          payload: responseData.data,
        });
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getUserDashboardDataQueryData = getUserDashboardDataResponseData; //variable

  const initGetUserDashboardDataData = (data) => {
    getUserDashboardDataResponseFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.getUserDashboardData?.success) {
        console.log('res success', res);
        // successCallback(res);
      } else {
        console.log('res error', res);
        // errorCallback(res);
      }
    }).catch((err) => {
      console.log('res err', err);
      // errorCallback(err);
    });
  };

  return { getUserDashboardDataQueryData, initGetUserDashboardDataData };
};
