import React, { useEffect, useState } from 'react'
import "./verifiedBannerScreen.scss"
import { MdOutlineImage } from "react-icons/md";
import action from "../../../images/icons/Actions.png"
import { IoIosArrowForward } from "react-icons/io";
import VerifiedBannerBox from './VerifiedBannerBox';
import profile from "../../../images/icons/profile.png"
import multiUser from "../../../images/icons/multiuser.png"
import letter from "../../../images/icons/letter.png"
import warn from "../../../images/icons/warning.png"
import StepButton from '../../common/StepButton';
import NavHeader from '../../common/NavHeader/NavHeader';
import { useNavigate } from "react-router-dom"
import { businessDashboardRoutePattern, getBusinessProfileSettingPageRoute, insuranceLandingPage } from "../../../Routes"
import TextInput from '../../socialMediaMarketing/TextInput';
import { UserInsuranceDetailsAction } from "../../redux/actions/userInsuranceDetails/userInsuranceDetails"
import { GetUserInsuranceDetailAction } from "../../redux/actions/GetUserInsuranceDetails/GetUserInsuranceDetail"
import moment from 'moment';
import closeIcon from "../../../images/close_icon.svg"
import Modal from 'react-modal';
import CustomModal from "../../modal/CustomModal"
import VerifiedModel from '../../modal/VerifiedModel/VerifiedModel';
import { PiWarningCircleBold } from "react-icons/pi";
import PenddingVerfiedModel from '../../modal/VerifiedModel/PenddingVerfiedModel';
// import { Document, Page } from 'react-pdf';
import { MdVerified } from "react-icons/md";
import CustomDropdown from "../../common/dropdown/CustomDropdown"
import { es } from 'date-fns/locale';
import { ReactComponent as Uploads } from "../../../images/Image.svg";
import Dropzone from "../../dropzone/dropzone"
import { DeleteFinanceInsurancedetailAction } from '../../redux/actions/finance-registrtion/deleteFinanceInsurancedetail';
import { toast } from 'react-toastify';
import { GetInsuranceOptionAction } from "../../redux/actions/getInsuranceOption/getInsuranceOption"
import Insurancebanner from '../../common/insurancebanner/Insurancebanner';

const VerifiedBannerScreen = () => {

  const [isDocPDF, setIsDocPDF] = useState(false)
  const [insuranceDoc, setInsuranceDoc] = useState([]);
  const [insuranceDocPDF, setInsuranceDocPDF] = useState([])
  const [insuranceExpireDate, setInsuranceExpireDate] = useState("")
  const [insuranceExpireDateError, setInsuranceExpireDateError] = useState(null)
  const [apiInsuranceExpiryError, setApiInsuranceExpiryError] = useState(null)
  const [insuranceExpireDateErrorText, setInsuranceExpireDateErrorText] = useState("")
  const [policyNumberError, setPolicyNumberError] = useState(false)
  const [insuranceDocError, setInsuranceDocError] = useState(false)
  const [getInsuranceData, setGetInsuranceData] = useState(null)
  const [insurancePolicyNumber, setInsurancePolicyNumber] = useState("")
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [modelIsOpen, setIsOpen] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [loader, setLoader] = useState(false)
  const [GetApiData, setGetApiData] = useState([])
  const [isValidDateError, setIsVaildDateError] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  const { getInsuranceOptionQueryData, initGetInsuranceOptionData } = GetInsuranceOptionAction();

  useEffect(() => {
    initGetInsuranceOptionData()
  }, [])

  const dropDownOption = getInsuranceOptionQueryData?.data?.getInsuranceOptions?.data
  console.log("gdfhfh", dropDownOption)
  const insuranceProvider = dropDownOption?.options
  const [insuranceProviderNameError, setInsuranceProviderNameError] = useState({
    insuranceCompnay: "",
    otherInsuranceCompnay: ""
  })
  const [insuranceProviderName, setInsuranceProviderName] = useState({
    insuranceCompnay: "",
    otherInsuranceCompnay: ""
  })

  console.log("dropDownOption", insuranceProvider)


  const handleFileUpload = (file) => {
    if (file.type === 'application/pdf') {
      setInsuranceDoc(file)
      // setIsInsuranceDoc(true)
    } else {
      setInsuranceDoc(file)
      //  setIsInsuranceDoc(true);
      setInsuranceDocError(false)
    }
  }
  const [otherDocs, setOtherDocs] = useState([
    {
      image: "",
      document_url: "",
      document_title: "",
      id: null
    },
    // {
    //   image: "",
    //   document_url: "",
    //   document_title: "",
    //   id:null
    // },
    // {
    //   image: "",
    //   document_url: "",
    //   document_title: "",
    //   id:null
    // },
  ]);
  const [isData, setIsData] = useState(false)

  // const handleFileUpload=(file)=>{
  //    if(file.type==='application/pdf'){
  //         setInsuranceDoc(file)
  //         setIsInsuranceDoc(true)
  //     }else{
  //         setInsuranceDoc(file)
  //        setIsInsuranceDoc(true);
  //         setInsuranceDocError(false)
  //     }
  // }

  const navigate = useNavigate();

  const handleOnBack = () => {
    navigate(businessDashboardRoutePattern)
    // navigate(getBusinessProfileSettingPageRoute)
  }
  const { userInsuranceDetailsQueryData, initUserInsuranceDetailData } = UserInsuranceDetailsAction();
  const { getUserInsuranceDetailsQueryData, initGetUserInsuranceDetailData } = GetUserInsuranceDetailAction();
  const { deleteFinanceInsuranceDetailQueryData, initDeleteFinanceInsuranceDetailData } = DeleteFinanceInsurancedetailAction()

  console.log("getUserInsuranceDetailsQueryData", getUserInsuranceDetailsQueryData)
  const { data } = getUserInsuranceDetailsQueryData;

  useEffect(() => {
    initGetUserInsuranceDetailData();
  }, [])


  //     useEffect(()=>{
  //         console.log("inVal",getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data)
  //        if(getUserInsuranceDetailsQueryData?.data && !getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data?.id){
  //           //  setIsInsuranceDoc(false)
  //            setInsuranceDoc([]);
  //         setInsuranceExpireDate("");
  //                  setDay("")
  //                  setMonth("")
  //                  setYear("")
  //                  setInsurancePolicyNumber("")

  //         }
  //         else if(getUserInsuranceDetailsQueryData?.data){
  //             setGetInsuranceData(getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data)
  //             setIsDocPDF(true)
  //           let data = getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data
  //            setInsurancePolicyNumber(data?.insurance_policy_number)
  //            const fullDate=moment(data?.insurance_expire_date)
  //            const date=fullDate.format('DD')
  //            console.log("date",date)
  //            const month=fullDate.format('MM')
  //            console.log("month",month)
  //            const year=fullDate.format('YYYY')
  //            console.log("year",year)
  //            setDay(date)
  //           setMonth(month)
  //           setYear(year)


  //         }
  //         // else{
  //         //     setIsInsuranceDoc(false)
  //         //     setInsuranceDoc([]);
  //         //     setInsuranceExpireDateError(false)
  //         //     setInsuranceExpireDate("");
  //         //    setDay("")
  //         //    setMonth("")
  //         //    setYear("")
  //         //    setInsurancePolicyNumber("")
  //         // }
  // },[getUserInsuranceDetailsQueryData?.data])

  console.log("getInsuranceData", getInsuranceData)

  const getInsurance = () => {
    navigate(insuranceLandingPage)
  }
  console.log('month', day, month, year)

  useEffect(() => {
    var date = moment(year + '-' + month + '-' + day, 'YYYY-MM-DD', true);
    // console.log("vaild",date.isSameOrAfter(moment().startOf('day')))
    if (day === "" && month === "" && year === "") {
      setInsuranceExpireDateError(false)
    }
    else if (day && month && year && day?.length === 2 && month?.length === 2 && year?.length === 4 && date.isValid() && date.isSameOrAfter(moment().startOf('day'))) {
      setInsuranceExpireDateError(false);
      setInsuranceExpireDate(date.format('YYYY-MM-DD'));
    } else if (day && month && year) {
      setInsuranceExpireDate("")
      setInsuranceExpireDateError(true)
    }
    //    if( !date.isValid()){
    //      setInsuranceExpireDateError(false)
    //     setIsVaildDateError("Please enter vaild date! ")
    // }
  }, [day, month, year])


  const handleInsuranceExpiry = () => {
    let certificate
    {
      certificate = otherDocs.filter((file) => {

        if (file.image || file.id) {
          return (true)
        }
      }).map(file => {
        return {
          photoId: file.id ? file.id : 0,
          photoTitle: file.document_title,
          photo: file.image,
        }
      })
    }

    console.log("insuranceDocppp", insuranceDoc)

    if (insuranceExpireDateError) {
      return;
    }
    // else if (isValidDateError){
    //     return;
    // }
    // else if(!isInsurnaceDoc){
    //     setInsuranceDocError(true);
    //     setDisabled(true)
    //     setLoader(false)
    //     return;
    // }
    // else if(!insurancePolicyNumber) {
    //     setPolicyNumberError(true)
    //     return;
    // }

    else if (insuranceProviderName.insuranceCompnay === "Other" && !insuranceProviderName.otherInsuranceCompnay) {
      setInsuranceProviderNameError({
        otherInsuranceCompnay: "Please enter the insurance company"
      })
    }
    else {

      initUserInsuranceDetailData({
        documentFiles: certificate,
        // saveUserInsuranceDetailsId:getInsuranceData && getInsuranceData?.id=== 0 ? null : getInsuranceData?.id,
        expireDate: insuranceExpireDate,
        policyNo: insurancePolicyNumber,
        insuranceCompanyName: insuranceProviderName?.insuranceCompnay === "Other" ? insuranceProviderName?.otherInsuranceCompnay : insuranceProviderName?.insuranceCompnay
      },
        (res) => {
          toast.success(res?.data?.saveUserInsuranceDetails?.message);
          navigate(businessDashboardRoutePattern);
        },
        (err) => {
          console.log("err", err);
        })

    }
  }

  const customStyle = {

    menuPortal: base => ({
      ...base,
      zIndex: 9999 // Adjust the z-index value according to your requirement
    }),
    control: (styles, state) => ({
      ...styles, backgroundColor: '#FFFFFF',
      outline: "none",
      appearance: "none",
      padding: "10px 10px",
      borderRadius: "10px",
      border: "1px solid #878787",
      height: "55px",
      width: "100%",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {

      return {
        ...styles,
      };
    },
  };


  const handleModel = () => {
    setIsOpen(true);
  }

  const closeModel = () => {
    setIsOpen(false)
  }

  const handleCustomDropDown = (field, value, nestedField = null) => {
    setInsuranceProviderName((prevData) => {
      if (nestedField) {
        return {
          ...prevData,
          [field]: {
            ...prevData[field],
            [nestedField]: value
          }
        };
      }
      else {
        return {
          ...prevData,
          [field]: value
        };
      }
    })
  }

  const deleteImage = () => {
    insuranceDoc.shift();
    setInsuranceDoc([])
    setIsOpen(false)
  }

  useEffect(() => {
    let res = getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data;
    if (insuranceDoc?.length !== 0 && insuranceExpireDate?.length !== 0 && insurancePolicyNumber?.length !== 0) {
      setDisabled(false)
      // setLoader(true)
    } else if (insuranceDoc?.length === 0 && insuranceExpireDate?.length === 0 && insurancePolicyNumber?.length === 0) {
      setDisabled(true)
      // setLoader(false)
    } else if (insuranceDocError || policyNumberError || insuranceExpireDateError) {
      setDisabled(true)
    } else if (res?.status === 0 && insuranceDoc?.length > 0 || insuranceExpireDate?.length > 0 || insurancePolicyNumber?.length > 0) {
      setDisabled(false)
      // setLoader(true)
    }
    else {
      setDisabled(true)
      setLoader(true)
    }
  }, [insuranceDoc, insuranceExpireDate, insurancePolicyNumber])

  const openPDF = () => {
    const blob = new Blob(insuranceDoc, { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  const openPDf = () => {
    window.open(getInsuranceData?.document_url)
  }

  const faceBannerBoxContant = [
    {
      id: 1,
      title: "What it means to become a verified business member to over 5 million customers ",
      contant: "learn more"
    },
  ]

  const handleTitleChange = (e, index) => {
    const { name, value } = e.target;
    setOtherDocs((prevOtherDocs) => {
      const updatedDocs = [...prevOtherDocs];
      updatedDocs[index] = { ...updatedDocs[index], document_title: value };
      return updatedDocs;
    });
  };

  const handleAddDropzone = () => {
    if (otherDocs.length < 10) {
      const nextDocIndex = otherDocs.length + 1;
      const newDocumentState = {
        image: "",
        document_url: "",
        document_title: ""
      };
      setOtherDocs((prevOtherDocs) => [...prevOtherDocs, newDocumentState]);
      setIsData(true)
    }
  };


  useEffect(() => {

    console.log("check", data?.getUserInsuranceDetails?.data?.documents)
    if (data?.getUserInsuranceDetails?.data && data?.getUserInsuranceDetails?.data?.status === 4) {

      setApiInsuranceExpiryError(true)
    }
    if (data?.getUserInsuranceDetails?.data && !data?.getUserInsuranceDetails?.data?.documents?.[0]?.id) {
      setInsuranceExpireDate("");
      setDay("")
      setMonth("")
      setYear("")
      setInsurancePolicyNumber("")
    }
    if (data?.getUserInsuranceDetails?.data) {

      const value = data?.getUserInsuranceDetails?.data
      console.log("oooo", otherDocs)
      if (value?.documents?.length && Array.isArray(value?.documents)) {
        setOtherDocs(value?.documents);
      }
      setInsurancePolicyNumber(data?.getUserInsuranceDetails?.data?.insurance_policy_number)

      if (insuranceProvider && (!insuranceProvider?.includes(getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data?.insurance_company_name)
        || getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data?.insurance_company_name === "Other")) {
        setInsuranceProviderName({
          otherInsuranceCompnay: getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data?.insurance_company_name,
          insuranceCompnay: "Other"
        })
      } else {
        setInsuranceProviderName({
          insuranceCompnay: getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data?.insurance_company_name,
        })
      }
      if (value?.insurance_expire_date) {
        const fullDate = moment(value?.insurance_expire_date)
        const date = fullDate.format('DD')
        console.log("date", date)
        const month = fullDate.format('MM')
        console.log("month", month)
        const year = fullDate.format('YYYY')
        console.log("year", year)
        setDay(date)
        setMonth(month)
        setYear(year)
      }

    }
  }, [data?.getUserInsuranceDetails?.data])

  return (
    <>

      <div className='faceVerified'>

        <div className='faceVerifiedContentWrapper'>

          <NavHeader isBackOnly={true} handleOnBack={handleOnBack} />

          <div className='faceVerifiedContant maxWidth540 paddintTop80'>

            {
              getUserInsuranceDetailsQueryData?.data &&
              getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data?.status === 2 && (
                <div className='verifiedModelSec'>
                  <VerifiedModel />
                </div>
              )}

            {
              getUserInsuranceDetailsQueryData?.data &&
              getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data?.documents?.[0]?.id &&
              getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data?.status !== 2 &&
              getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data?.status !== 1 &&
              (
                <div className='verifiedModelSec'>
                  <PenddingVerfiedModel />
                </div>
              )}



            <div className='facesHeader'>
         <Insurancebanner />
              <div className='facesTitle'>
                <div className='mainHeader'>

                  <h6>Become verified business</h6>
                  <MdVerified className='verifiedIcon' />
                </div>

                <p>Simply add your insurance details to have the badge of approval and be recognised on our marketplace to over 5 million potential customers.</p>
              </div>
            </div>

            {/* <div className='insuranceQuate' onClick={()=>getInsurance()}>
                <div className='quateContant'>
                    <img src={action}  alt='action'/>
                    <div className='quateHeader'>
                        <h6>Need Insurance ?</h6>
                        <p>Compare Insurance quotes here</p>
                    </div>
                </div>
                 <div className='quateArrow'>
                        <IoIosArrowForward/>
                </div>
            </div> */}

            <div className="InsuranceCard">
              <div className="InsuranceCardBanner">
                <div className="InsuranceCardIconContentWrapper">
                  <div className="InsuranceCardIcon">
                    <img src={action} alt="shieldIcon" />
                    {/* <p className="doc_label"></p> */}
                    <div className="ContentWrapper">
                      <h5>Need insurance ?</h5>
                      <p className="doc_Desc">Compare insurance quotes here</p>
                    </div>
                  </div>
                </div>
                <div className="insuranceBtn">
                  <StepButton type={"button"} blue={"blue"} className={"Bicon"} label={"Quote me"} onClick={() => getInsurance()} />
                </div>
              </div>
            </div>


            <div className="Certificate-docmentation-Wrapper">
              <div className="form-label mt-3">Insurance document</div>
              <div className="docmentationGrid-Wrapper">

                {
                  otherDocs?.map((doc, index) => (
                    <>
                      <div className="docmentationGridItem" key={index}>
                        <Dropzone
                          handleMulti={setOtherDocs}
                          selectedIndex={index}
                          urlFromDB={doc.document_url}
                          handleDelete={initDeleteFinanceInsuranceDetailData}
                          insuranceId={doc.id}
                          imageFromComponent={doc?.image}
                          otherDocs={otherDocs}
                        />
                        {/* <input
                        name={`doc_name${index + 1}`}
                        value={doc.document_title}
                        onChange={(e) => handleTitleChange(e, index)}
                        placeholder="Text Here"
                        className="inputTag"
                      
                      /> */}
                      </div>
                    </>
                  ))
                }
                <div className="docmentationGridItem">
                  {/* {
                    isData ? ( */}
                  <div
                    className="dropzone"
                    onClick={handleAddDropzone}
                  >
                    <Uploads />
                    <p>Add photo </p>
                  </div>
                  {/* ):(
                      <div
                      className="d-flex flex-column align-items-center justify-content-center add-container"
                      onClick={handleApiAddDropZone}
                    >
                      <Uploads />
                      <p>Add Photo </p>
                    </div>
                    )
                  } */}
                  {/* <input name="Add" placeholder="Text Here" /> */}
                </div>
              </div>
            </div>
            {/* <div className='uploadInsurance'>
                 <div className='uplaodContant'>
                     <h6>Insurance Document</h6>
                     <div className='fileDropzone' >
                   
                     <Dropzone
                                onDrop={files => handleFileUpload(files)}
                                accept={"image/*,application/pdf"}
                                noClick={isInsurnaceDoc ? true : false}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        <div className='fileDropZoneContant'>
                         {
                            isInsurnaceDoc  ?
                            <>
                             {   <div className='insuranceQuate' onClick={()=>getInsurance()}>
                <div className='quateContant'>
                    <img src={action}  alt='action'/>
                    <div className='quateHeader'>
                        <h6>Need Insurance ?</h6>
                        <p>Compare Insurance quotes here</p>
                    </div>
                </div>
                 <div className='quateArrow'>
                        <IoIosArrowForward/>
                </div>
            </div>
                                insuranceDoc?.length ?   
                               insuranceDoc.map((file)=>(
                                  <div className='previewSec'>
                                      <div className='imagePreview'>
                                    {file.type==='application/pdf'?
                                    <>
                                      <i class="fa-regular fa-file-pdf" onClick={()=>openPDF()}></i>
                                    <p onClick={()=>openPDF()}>{file.name}</p>
                                      </>
                                       :
                                       <img 
                                            src={URL.createObjectURL(file)}
                                            alt='preview'
                                            className='img'
                                           
                                          />
                                    }
                                          
                                      </div>
                                      <div className='closeIcon' onClick={(e)=>{
                                        e.stopPropagation()
                                        handleModel()}}>
                                         <img  src={closeIcon} alt='closeicon' style={{cursor:"pointer"}}/>
                                      </div>
                                  </div>
                                 ))

                              : 
                              
                                    <div className='previewSec'>
                                      <div className='imagePreview'>
                                     {
                                        getInsuranceData?.insurance_doc?.includes('.pdf') ? (
                                            <div>
                                            <i class="fa-regular fa-file-pdf" onClick={()=>openPDf()}></i>
                                          <p onClick={()=>openPDf()} className='pdfName'>{getInsuranceData?.insurance_doc}</p>
                                            </div>

                                        )
                                       :
                                      (
                                        getInsuranceData?.document_url ? (
                                            <img 
                                            src={getInsuranceData?.document_url}
                                            alt='preview'
                                            className='img'
                                           />
                                        ):""
                                    )
                                        
                                     }
                                </div>

                                <div className='closeIcon' onClick={(e)=>{
                                        e.stopPropagation()
                                        handleModel()}}>
                                         <img  src={closeIcon} alt='closeicon' style={{cursor:"pointer"}}/>
                                      </div>
                                </div>
                                
                               
                                }
                                   
                            <-100%/>
                            : <div>
                                 <MdOutlineImage className='dropImage'/>
                                 <p>Add Insurance Document</p>
                             </div> 
                            }
                         </div>
                         </div> 
                                   
                                )}
                            </Dropzone>
                    </div>
                  </div>
                  {
                    insuranceDocError && (
                        <div className='errorSec'>
                        <span className='error'>{"Please Select Image"}</span>
                        </div>
                    )
                  }
                  
             </div> */}


            <div className='expiryDateSec'>
              <div className='expiryDateContant'>
                <p>Insurance expiry date</p>
                <div className='inputGroup'>
                  <div className='inputField'>
                    <TextInput type={"number"} id={"date"} placeholder={"DD"} name={"day"} value={day} onChange={(e) => {
                      const inputVal = e.target.value;
                      setDay(inputVal);
                      if (inputVal.length >= 2) {
                        document.getElementById("month").focus();
                      }
                      if (inputVal === "") {
                        // setInsuranceExpireDateError(true)
                      }
                      setApiInsuranceExpiryError(false)
                    }} />
                  </div>
                  <div className='inputField'>
                    <TextInput type={"number"} id={"month"} placeholder={"MM"} name={"month"} value={month}

                      onChange={(e) => {
                        const inputVal = e.target.value;
                        setMonth(inputVal);
                        if (inputVal.length >= 2) {
                          document.getElementById("year").focus();
                        }
                        if (inputVal === "") {
                          // setInsuranceExpireDateError(true)
                        }
                        setApiInsuranceExpiryError(false)
                      }}
                      onKeyDown={(e) => {
                        if (!e.target.value && e.key === "Backspace") {
                          document.getElementById("date").focus()
                        }
                      }}
                    />
                  </div>
                  <div className='inputField'>
                    <TextInput type={"number"} id={"year"} placeholder={"YYYY"} name={"year"} value={year} onChange={(e) => {
                      const inputVal = e.target.value;
                      setYear(inputVal);
                      if (inputVal === "") {
                        // setInsuranceExpireDateError(true)
                      }
                      setApiInsuranceExpiryError(false)
                    }}
                      onKeyDown={(e) => {
                        if (!e.target.value && e.key === "Backspace") {
                          document.getElementById("month").focus()
                        }
                      }}
                    />
                  </div>

                </div>

                {
                  apiInsuranceExpiryError &&
                  (
                    <div className='errorSec'>
                      <span className='error'>{"Your insurance has been expired!"}</span>
                    </div>
                  )
                }
                {
                  !apiInsuranceExpiryError && insuranceExpireDateError && (
                    <div className='errorSec'>
                      <span className='error'>{"Please enter future insurance date!"}</span>
                    </div>
                  )
                }
                {/* {
                    isValidDateError && !insuranceExpireDateError && (
                        <div className='errorSec'>
                        <span className='error'>{isValidDateError}</span>
                        </div>
                    )
                    } */}


              </div>
            </div>

            <div className='policySection'>
              <div class="policyNumber">
                <label>Policy number (Optional)</label>
                <TextInput placeholder={"Policy number (Optional)"} value={insurancePolicyNumber} name={"policy"} onChange={(e) => {
                  const policyNumber = e.target.value;
                  setInsurancePolicyNumber(policyNumber)
                  // {!policyNumber? setPolicyNumberError(true):setPolicyNumberError(false)}
                }} />
              </div>
              {/* {
                policyNumberError && (
                <div className='errorSec'>
                   <span className='error'>{"Please enter policy number"}</span>
                </div>
                 )
            } */}

            </div>

            <div className='insuranceProviderDropdown'>
              <CustomDropdown
                labelName={"Insurance company"}
                placeholdername={"Insurance company"}
                defaultValue={insuranceProviderName.insuranceCompnay}
                dropdownId={"insurnaceProvider"}
                optionsArr={insuranceProvider}
                style={customStyle}
                handleSelectedValue={(value) => handleCustomDropDown("insuranceCompnay", value)}
                readOnly={false}
              />
            </div>

            {
              insuranceProviderName.insuranceCompnay === "Other" && (
                <div className='insuranceProviderSection'>
                  <div className='insuranceProviderName'>
                    <label>Insurance company </label>
                    <TextInput
                      placeholder={"Insurance company"}
                      value={insuranceProviderName.otherInsuranceCompnay}
                      onChange={(e) => handleCustomDropDown("otherInsuranceCompnay", e.target.value)}
                    />
                  </div>
                  {insuranceProviderNameError.otherInsuranceCompnay &&
                    <span className="error">{insuranceProviderNameError.otherInsuranceCompnay}</span>
                  }
                </div>
              )
            }

            {/* {
                 faceBannerBoxContant.map((element)=>{
                   return(
                       <div className='faceBannerBox'>
                         <div className='faceBannerContant'>
                          <VerifiedBannerBox 
                          image={<PiWarn   <div className='insuranceQuate' onClick={()=>getInsurance()}>
                <div className='quateContant'>
                    <img src={action}  alt='action'/>
                    <div className='quateHeader'>
                        <h6>Need Insurance ?</h6>
                        <p>Compare Insurance quotes here</p>
                    </div>
                </div>
                 <div className='quateArrow'>
                        <IoIosArrowForward/>
                </div>
            </div>ingCircleBold className='warningIcon'/>}
                           title={element.title}
                           contant={element.contant}
                           key={element.id}
                           closeIcon={closeIcon}
                           />
                          </div>
                        </div>
                      )
                 })
                 } */}

            <div className='faceBannerButton'>
              <StepButton
                label={"Done"}
                blue={"blue"}
                onClick={() => handleInsuranceExpiry()}
                disabled={disabled}
                isLoading={userInsuranceDetailsQueryData?.loading}
              />
            </div>
          </div>

          {/* <div className='insuranceQuate' onClick={()=>getInsurance()}>
                <div className='quateContant'>
                    <img src={action}  alt='action'/>
                    <div className='quateHeader'>
                        <h6>Need Insurance ?</h6>
                        <p>Compare Insurance quotes here</p>
                    </div>
                </div>
                 <div className='quateArrow'>
                        <IoIosArrowForward/>
                </div>
            </div> */}
        </div>


      </div>

      {/* ========================================== CUSTOME MODEL======================================================== */}

      <CustomModal
        modalOpen={modelIsOpen}
        setModalOpen={closeModel}
        modaltitle={""}
        type={"common-width"}
        modalBody={
          <div className='modelContent'>
            <div className='modelText'>
              <h1>Are you sure?</h1>
              <p>you want to delete this image? This action cannot be reversed.</p>
            </div>
            <div className='modelButtons'>
              <button className='deleteButton' onClick={() => deleteImage()}>Delete</button>
              <button className='cancelButton' onClick={() => closeModel()}>Cancel</button>
            </div>
          </div>
        }
      />

    </>
  )
}

export default VerifiedBannerScreen