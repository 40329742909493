import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { EDIT_BLOCK_OUT_TIME_DATA } from "../../constants/blockOutTime/BlockOutTimeConst";

const EDIT_BLOCK_OUT_TIME_QUERY = gql`
query GetBlockOutTime($blockOutId: Int, $blockOutDateId: Int) {
  getBlockOutTime(blockOutId: $blockOutId, blockOutDateId: $blockOutDateId) {
    success
    message
    data {
      blockOutId
      blockOutDateId
      startDate
      endDate
      readableStartDate
      readableEndDate
      startTime
      endTime
      readableDuration
      isAllDay
      title
      description
      repeatSetting
      repeatStatus
      repeatStatusString
      enableBooking
      clinicData {
        clinicId
        clinicName
      }
    }
  }
}
`;

export const EditBlockOutTimesQuery = () => {
  const dispatch = useDispatch();
  const [getEditBlockOutTimeData, editBlockOutTimeData] = useLazyQuery(
    EDIT_BLOCK_OUT_TIME_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getBlockOutTime: responseData }) => {
        dispatch({
          type: EDIT_BLOCK_OUT_TIME_DATA,
          payload: responseData.data,
        });
        if (responseData.success) {
        } else {
        }
      },
    }
  );
  const editBlockOutTimeQueryData = editBlockOutTimeData;
  const initEditBlockTimeOut = (data, successCallback) => {
    getEditBlockOutTimeData({
      variables: data,
    }).then(({ data: { getBlockOutTime: responseData } }) => {
      if (successCallback)
        successCallback(responseData?.data);

    });
  };
  return { initEditBlockTimeOut, editBlockOutTimeQueryData };
};

