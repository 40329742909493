import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_BUSINESS_CLINIC_LIST } from "../../constants/business-location-const/BusinessLocationConst";
import { useSearchParams } from "react-router-dom";
import { GET_INVOICE_PAGE_DETAILS } from "../../constants/invoice/Invoice";

const GET_INVOICE_DETAILS_QUERY = gql`
query GetInvoiceDetails($entityId: Int!, $displayVatIds: [Int], $generateInvoice: Boolean) {
    getInvoiceDetails(entityId: $entityId, displayVatIds: $displayVatIds, generateInvoice: $generateInvoice) {
      success
      message
      data {
        invoiceNumber
        invoiceDate
        businessAddress
        businessPhone
        businessEmail
        businessName
        clientAddress
        clientPhone
        clientEmail
        subtotal
        subtotal_readable
        totalVat
        totalVat_readable
        total
        total_readable
        total_discount
        total_discount_readable
        treatments {
          id
          name
          qty
          price
          price_readable
          vat
          vat_readable
          total
          total_readable
          isVatEnabled
        }
        invoiceLink
        invoiceBase64
        userProfileImage
      }
    }
  }
  `;



const GET_DOWNLOAD_INVOICE_DETAILS_QUERY = gql`
query GetVerifyTokenForInvoiceBase64($token: String, $appointmentId: Int) {
  getVerifyTokenForInvoiceBase64(token: $token, appointmentId: $appointmentId) {
    success
    message
    data {
      invoiceBase64
    }
  }
}`;

// var page;
export const GetInvoiceDetailsQuery = () => {
  const dispatch = useDispatch();
  const [getInvoiceDetails, invoiceDetailsData] = useLazyQuery(
    GET_INVOICE_DETAILS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getInvoiceDetails: responseData }) => {

        dispatch({
          type: GET_INVOICE_PAGE_DETAILS,
          payload: responseData?.data,
          // page
        });
        if (responseData.success) {

        } else {

        }


      },
    }
  );
  const invoiceDetailsQueryData = invoiceDetailsData;
  const initInvoiceDetails = (data, successResponse) => {
    getInvoiceDetails({
      variables: data,
    }).then((res) => {
      successResponse(res.data.getInvoiceDetails);
    }).catch((err) => {
      console.log(err);
    });
  };
  return { initInvoiceDetails, invoiceDetailsQueryData };
};


export const GetDownloadInvoiceDetailsQuery = () => {
  const dispatch = useDispatch();
  const [getDownloadInvoiceDetails, invoiceDetailsData] = useLazyQuery(
    GET_DOWNLOAD_INVOICE_DETAILS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getVerifyTokenForInvoiceBase64: responseData }) => {

        // dispatch({
        //   type: GET_INVOICE_PAGE_DETAILS,
        //   payload: responseData?.data,
        //   // page
        // });
        if (responseData.success) {

        } else {

        }

      },
    }
  );
  const invoiceDownloadDetailsQueryData = invoiceDetailsData;
  const initDownloadInvoiceDetails = (data, successResponse) => {
    getDownloadInvoiceDetails({
      variables: data,
    }).then((res) => {
      successResponse(res.data.getVerifyTokenForInvoiceBase64);
    }).catch((err) => {
      console.log(err);
    });
  };
  return { initDownloadInvoiceDetails, invoiceDownloadDetailsQueryData };
};



