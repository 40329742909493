import React, { useEffect, useRef } from "react";
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react'; // No need to import ClassicEditor here
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"; // Import ClassicEditor separately
import "./customeCKEditor.scss";

function CustomeCKEditor({ onChange, editorLoaded, name, value }) {
    const editorRef = useRef();
  
    useEffect(() => {
      editorRef.current = {
        CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
        ClassicEditor: require("@ckeditor/ckeditor5-build-classic")
      };
    }, []);
  
  return (
    <CKEditor
    type=""
    name={name}
    editor={ClassicEditor} // Use ClassicEditor directly here
    config={{
      ckfinder: {
        uploadUrl: "" // Enter your upload url
      }
    }}
    data={value}
    onChange={(event, editor) => {
      const data = editor.getData();
      onChange(data);
    }}
  />
  );
}

export default CustomeCKEditor;
