import React from 'react'
import Step1Img from "../../images/Vector.svg"
import './consent-steps.scss'
import StepButton from '../common/StepButton'

const BookingCard = () => {
  return (
    <>
      <div className="booking-card-main">
        <div className='img-div'>
            <img src={Step1Img} />
        </div>
        <div className='booking-card-content'>
            <h3>Bookings + Consent </h3>
            <p>It’s now even easier to book appointments and connect your consent forms.</p>
        </div>
        {/* <div className='booking-card-btn'>
            <StepButton label={"Next"} blue={true} />
        </div> */}
      </div>
    </>
  )
}

export default BookingCard
