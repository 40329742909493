import React, { useEffect, useRef, useState } from "react"
import "./registration-component.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import FinaceHeader from "../../finaceHeader"
import { RegisterWithFinance } from "../../redux/actions/pay-later/registerWithFinance";
// import * as Yup from "yup";
import { useNavigate } from 'react-router';
import { useSelector } from "react-redux";
import StepButton from "../../common/StepButton";

const RegistrationComponent = () => {
    const inputRef = useRef(null);
    const [formData, setFormData] = useState({
        firstName: '',
        surname: '',
        businessName: '',
        phoneNumber: '',
        emailAddress: '',
        password: '',
        confirmPassword: '',
    });
    const [errors, setErrors] = useState({});
    const email = useSelector(state => state?.financeLoanRequestEmailReducer?.userEmailForFinance)

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            emailAddress: email
        }));
    }, [email]);


    const { initiateRegisterWithFinance, registerWithFinanceData } = RegisterWithFinance();
    const navigate = useNavigate();

    const validateForm = () => {
        let formIsValid = true;
        let errors = {};
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
        // Check if fields are empty
        if (!formData.firstName.trim()) {
            errors.firstName = 'First Name is required';
            formIsValid = false;
        }
        if (!formData.surname.trim()) {
            errors.surname = 'Surname is required';
            formIsValid = false;
        }
        if (!formData.businessName.trim()) {
            errors.businessName = 'Business name is required';
            formIsValid = false;
        }
        if (!formData.phoneNumber.trim()) {
            errors.phoneNumber = 'Phone number is required';
            formIsValid = false;
        }
        if (!email && !formData.emailAddress.trim()) {
            errors.emailAddress = 'Email address is required';
            formIsValid = false;
        }

        if (!formData.password.trim()) {
            errors.password = 'Password is required';
            formIsValid = false;
        } else if (!passwordPattern.test(formData.password)) {
            errors.passwordValid = 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special character, and be at least 10 characters long';
            formIsValid = false;
        }

        if (!formData.confirmPassword.trim()) {
            errors.confirmPassword = 'Confirm password is required';
            formIsValid = false;
        }
        if (formData.password.trim() && formData.confirmPassword.trim() && formData.password !== formData.confirmPassword) {
            errors.confirmPassword = 'Password and Confirm password do not match';
            formIsValid = false;
        }
        // Add similar conditions for other fields

        setErrors(errors);
        return formIsValid;
    };

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (errors[name] && value.trim()) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }

        if (name === "password" && errors.passwordValid) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordValid: '',
            }));
        }
        // if (name === "password") {
        //     const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;

        //     setErrors((prevErrors) => {
        //         const newErrors = { ...prevErrors };
        //         if (!passwordPattern.test(value)) {
        //             newErrors.passwordValid = 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special character, and be at least 10 characters long';
        //         } else {
        //             delete newErrors.passwordValid; // Clear the passwordValid error if it exists and the criteria are met
        //         }
        //         return newErrors;
        //     });
        // }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            initiateRegisterWithFinance({
                fname: formData.firstName,
                lname: formData.surname,
                businessName: formData.businessName,
                phoneNumber: formData.phoneNumber,
                phoneCountryCode : "44",
                email: formData.emailAddress,
                password: formData.password
            }, (response) => {
                if (response?.success) {
                    navigate("/verify-email")
                }
            }, (error) => {
                if (error.data.email_exists === true && error.data.email_verified === false) {
                    navigate("/verify-email")
                }
            })
        }
        // Add your registration logic here
    };

    // const onSubmit = (data) => {
    //     console.log("Form Data======>>>>", data);
    //     // Your form submission logic here
    // };

    return (
        <>
            <div className="mainfinancelandingpage">
                <FinaceHeader />
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8 col-sm-12">
                            <div className="formTitleWrapper">
                                <h2 className="pagenewPageTitle">Registration</h2>
                                <p>You are now registering to offer finance for your business</p>
                            </div>
                            <form className="registration-form col-md-12" onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="firstName" className="py-2 form-label">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="firstName"
                                        name="firstName"
                                        placeholder="First Name"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        ref={inputRef}
                                    // {...register("firstName", { required: "First Name is required" })}
                                    // required
                                    />
                                    {errors.firstName && <p className="error text-danger">{errors.firstName}</p>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="surname" className="py-2 form-label">
                                        Surname
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="surname"
                                        name="surname"
                                        placeholder="Surname"
                                        value={formData.surname}
                                        onChange={handleChange}
                                    // required
                                    />
                                    {errors.surname && <p className="error text-danger">{errors.surname}</p>}

                                </div>
                                <div className="mb-4">
                                    <label htmlFor="businessName" className="py-2 form-label">
                                        Business name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="businessName"
                                        name="businessName"
                                        placeholder="Business name"
                                        value={formData.businessName}
                                        onChange={handleChange}
                                    // required
                                    />
                                    {errors.businessName && <p className="error text-danger">{errors.businessName}</p>}

                                </div>
                                <div className="mb-4">
                                    <label htmlFor="phoneNumber" className="py-2 form-label">
                                        Phone number
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        placeholder="Phone number"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                    // required
                                    />
                                    {errors.phoneNumber && <p className="error text-danger">{errors.phoneNumber}</p>}

                                </div>
                                <div className="mb-4">
                                    <label htmlFor="emailAddress" className="py-2 form-label">
                                        Email address
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="emailAddress"
                                        name="emailAddress"
                                        placeholder="Email address"
                                        value={formData.emailAddress}
                                        onChange={handleChange}
                                    // required
                                    />
                                    {errors.emailAddress && <p className="error text-danger">{errors.emailAddress}</p>}

                                </div>
                                <div className="mb-4">
                                    <label htmlFor="password" className="py-2 form-label">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        placeholder="Password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    // required
                                    />
                                    {errors.password && <p className="error text-danger">{errors.password}</p>}
                                    {errors.passwordValid && <p className="error-text text-danger" style={{ margin: "10px 0px", lineHeight: "normal" }}>{errors.passwordValid}</p>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="confirmPassword" className="py-2 form-label">
                                        Confirm password
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        placeholder="Confirm password"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                    // required
                                    />
                                    {errors.confirmPassword && <p className="error text-danger">{errors.confirmPassword}</p>}

                                </div>
                                {/* <button type="submit" className="my-4 col-md-12 btn btn-primary apply-btn">
                                    Apply now
                                </button> */}
                                <StepButton
                                    type="submit"
                                    blue={true}
                                    id={"ryft-pay-btn"}
                                    label={"Apply now"}
                                    isLoading={registerWithFinanceData.loading}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegistrationComponent