import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import CustomPopup from '../custom-components/custom-popup'
import close from "../../images/icons/Back.svg"
import "./review-popup.scss"
import { Rating } from 'react-simple-star-rating'
import dummyProfile from "../../images/icons/dummyProfileImage.png"
import ProgressBar from '@ramonak/react-progress-bar'
import Avatar from 'react-avatar'
import gsap from 'gsap'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_REVIEWS_DATA, CLEAR_REVEWS_DATA, GET_REVIEWS_DATA } from '../redux/constants'
import moment from 'moment'
import getReviewsData, { addReviewsData, getClientsData } from '../redux/actions/dashboard/Reviews'
import InfiniteScroll from 'react-infinite-scroll-component'
import CustomModal from '../modal/CustomModal'
import ReviewModalLeftSideSkeleton from '../skeleton/review-modal/ReviewModalLeftSideSkeleton'
import ReviewModalRightSideSkeleton from '../skeleton/review-modal/ReviewModalRightSideSkeleton'
import { domainName } from '../../styles'
import { GetBusinessReviewsAction } from '../redux/actions/dashboard/businessReviewsGql'
import { ReplyOnReviewMutationAction } from '../redux/actions/dashboard/replyOnReviewGql'

const ReviwewPopup = ({ setOpenReviewPopup, setClientsModal, setValue }) => {

    const tempArray = [5, 4, 3, 2, 1]
    const imgRef = useRef();

    const dispatch = useDispatch()
    const [reviewItems, setReviewItems] = useState([]);
    const [ratingFilter, setRatingFilter] = useState([])

    const [addReplyModal, setAddReplyModal] = useState(false)
    const [addReplyModalData, setaddReplyModalData] = useState(null)
    const [addReplyToBePostData, setAddReplyToBePostData] = useState("")
    const [addReplyToBePostError, setAddReplyToBePostError] = useState("")


    const { reviewsData } = useSelector((state) => state.GetReviewsDataReducer)
    const { ratingReviewsList } = useSelector((state) => state.GetReviewsDataReducer)
    const { totalRatingReviewsCount } = useSelector((state) => state.GetReviewsDataReducer)
    const { loadedRatingReviewsCount } = useSelector((state) => state.GetReviewsDataReducer)
    const { ratingReviewsPage } = useSelector((state) => state.GetReviewsDataReducer)




    // //SKELETON FLAG
    const { getReviewsLeftSkeletonFlag } = useSelector((state) => state.GetReviewsDataReducer)
    const { getReviewsRightSkeletonFlag } = useSelector((state) => state.GetReviewsDataReducer)

    // const getReviewsLeftSkeletonFlag = true, getReviewsRightSkeletonFlag = true

    const { addReplyFlag } = useSelector((state) => state.GetReviewsDataReducer)
    const { addReplyData } = useSelector((state) => state.GetReviewsDataReducer)



    // INFINITE SCROLL

    const { getBusinessReviewsQueryData, initGetBusinessReviewsData } = GetBusinessReviewsAction();
    const { replyOnReviewQueryData, initReplyOnReviewData } = ReplyOnReviewMutationAction();

    const initiateRatingReviewsCall = (refreshList = false) => {
        let newPage = ratingReviewsPage + 1;
        if (refreshList) {
            newPage = 1;
        } else if (totalRatingReviewsCount <= loadedRatingReviewsCount) {
            return;
        }

        // dispatch(getReviewsData({
        //     page: newPage,
        //     rating: ratingFilter,
        // }))


        dispatch({
            type: GET_REVIEWS_DATA,
            payload: {
                page: newPage,
                rating: ratingFilter
            }
        });
        initGetBusinessReviewsData({
            page: newPage,
            ratings: ratingFilter,
        })
        // here
    };

    const handleCategorySelection = (data) => {
        let tempArray = [...ratingFilter]
        let tempIndex = tempArray.findIndex(e => e == data)
        if (tempIndex == -1) {
            tempArray.push(parseInt(data))
        } else {
            tempArray.splice(tempIndex, 1)
        }
        setRatingFilter(tempArray)
    }

    const handleForwadClick = () => {
        // dispatch(getClientsData())
        setOpenReviewPopup(false);
        setClientsModal(true)
        setValue([])
    }

    // ========================ADD REVIEW========================

    const handleChageTextAreaData = (e) => {
        if (addReplyToBePostError && e.target.value) {
            setAddReplyToBePostError("")
        }
        setAddReplyToBePostData(e.target.value)
    }

    const handleAddReviewSubmit = (e) => {
        e.preventDefault()
        if (addReplyToBePostData == "") {
            setAddReplyToBePostError("please add Review!")
            return
        }
        // CALL API HERE
        // dispatch(addReviewsData({
        //     elementId: addReplyModalData?.cln_rvw_id,
        //     reply: addReplyToBePostData
        // }))

        dispatch({
            type: ADD_REVIEWS_DATA,
        })
        initReplyOnReviewData({
            elementId: addReplyModalData?.cln_rvw_id,
            reply: addReplyToBePostData
        });
        console.log('addReplyData', addReplyData);
        // here
    }

    useEffect(() => {
        if (addReplyData != null) {
            if (addReplyData) {
                setAddReplyModal(false)
                dispatch({
                    type: CLEAR_REVEWS_DATA
                })
                initiateRatingReviewsCall(true);
            }
        }

    }, [addReplyData])
    // ==========================================================
    useEffect(() => {
        dispatch({
            type: CLEAR_REVEWS_DATA
        })
        initiateRatingReviewsCall(true);
    }, [ratingFilter]);


    useEffect(() => {
        setReviewItems(ratingReviewsList)
    }, [ratingReviewsList])

    return (
        <>
            <div className='mt-4'>
                <div className='review-popup-container'>
                    <div className='review-content d-flex gap-3'>
                        <div className="row w-100 my-0 py-0 ">
                            <div className="col-md-6">
                                {
                                    getReviewsLeftSkeletonFlag ?

                                        <ReviewModalLeftSideSkeleton />
                                        :
                                        <div className='review-details'>
                                            <div className='mb-3'>
                                                <h6> My Reviews</h6>
                                                <p className='m-0'>Current Ranking <b>#{reviewsData?.reviewRank}/{reviewsData?.totalPractitionersForReview} professionals</b></p>
                                                <Rating initialValue={reviewsData?.average_review} fillColor={`${domainName=="faces"?"#175ffa":"#ff7300"}`} readonly size={16} />
                                            </div>

                                            <div className='rating-filter'>
                                                <h6>Filter by rating</h6>
                                                {
                                                    tempArray.map((item, index) =>
                                                        <div className='d-flex gap-2 align-items-center my-2' >
                                                            <input type='checkbox' name={`star-${item}`} id={item}
                                                                checked={ratingFilter.findIndex(e => e == item) != -1}
                                                                onChange={(e) => handleCategorySelection(e.target.id)} />
                                                            <Rating initialValue={item} fillColor={`${domainName=="faces"?"#175ffa":"#ff7300"}`} readonly size={18} />

                                                            <ProgressBar completed={reviewsData?.total_review_count ? ((reviewsData?.individual_rating_count?.find(e => e.rating == item))?.count / reviewsData?.total_review_count) * 100 : 0} bgColor={`${domainName=="faces"? "#175ffa":"#ff7300"}`} isLabelVisible={false} width='180px' height='8px' />
                                                            <span className='rating-text fw-lighter'>{(reviewsData?.individual_rating_count?.find(e => e.rating == item))?.count}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className='review-info text-center mt-3 cursor-pointer' onClick={() => handleForwadClick()}>
                                                <span>Want more reviews? Forward a review request to your client to boost your rankings</span>
                                            </div>
                                            {/* <div className='review-info1 text-center mt-3'>
                                        <span>Reviews <br />Reviews will go live on your Facesbooking profile. <b className='cursor-pointer'>Create or edit business profile</b></span>
                                    </div> */}
                                        </div>
                                }
                            </div>
                            {
                                // reviewsData &&
                                <div className="col-md-6">
                                    <div className='reviews' id="ratingsCard">
                                        <InfiniteScroll
                                            style={{ overflowY: "auto", overflowX: 'hidden' }}
                                            className=""
                                            dataLength={loadedRatingReviewsCount}
                                            hasMore={totalRatingReviewsCount > loadedRatingReviewsCount}
                                            // hasMore={true}
                                            loader={getReviewsRightSkeletonFlag && <ReviewModalRightSideSkeleton />}
                                            next={() => {
                                                initiateRatingReviewsCall();
                                            }}
                                            scrollableTarget="ratingsCard"
                                        >
                                            {


                                                ratingReviewsList?.length > 0 ?
                                                    reviewItems?.map((item, index) =>
                                                        <div className='review-card my-2' key={index}>
                                                            <div className='card-content-one d-flex justify-content-between'>
                                                                <div className='d-flex gap-2 align-items-center '>
                                                                    <Avatar src={item?.client?.usr_profile_img} name={item?.client?.cl_dt_name} round size="45" color="#EEEFF3" fgColor="#000" textSizeRatio={2} />

                                                                    {/* <div className='review-img-container'>
                                                        <img src={dummyProfile} alt="" />
                                                    </div> */}
                                                                    <div className='d-flex flex-column justify-content-center'>
                                                                        <h7 className="my-auto fw-bolder">{item?.client?.cl_dt_name}</h7>
                                                                        {
                                                                            item?.clinic &&
                                                                            <h8 className="my-auto fw-bold">{item?.clinic?.cln_name}, {item?.clinic?.cln_city}</h8>
                                                                        }
                                                                        <span className="my-auto treatment-list">{(item?.review_treatments?.map(e => e.cln_treat_name)).join(",")}</span>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <h9>{item?.review_date}</h9>
                                                                </div>
                                                            </div>
                                                            <div className='mb-2'>
                                                                <Rating initialValue={item?.cln_rvw_rating} fillColor={`${domainName=="faces"?"#175ffa":"#ff7300"}`} readonly size={16} />
                                                            </div>

                                                            <div className='review-content-container'>
                                                                <p>{item?.cln_rvw_message}</p>
                                                            </div>
                                                            {
                                                                item?.cln_rvw_reply == "" || item?.cln_rvw_reply == null ?
                                                                    <span className='cursor-pointer' onClick={() => { setaddReplyModalData(item); setAddReplyModal(true) }}>- Add Reply</span> :


                                                                    <>
                                                                        <span className="cursor-pointer" onClick={() => {
                                                                            var itemsList = [...reviewItems];
                                                                            itemsList[index] = { ...item, showReply: !item.showReply };
                                                                            setReviewItems(itemsList);
                                                                        }}>
                                                                            {item.showReply ? <>
                                                                                Hide Reply
                                                                            </> : <>
                                                                                Show Reply
                                                                            </>}
                                                                        </span>
                                                                        {
                                                                            item.showReply ?
                                                                                <div className=' ms-5'>

                                                                                    <div className='card-content-one d-flex justify-content-between'>
                                                                                        <div className=''>
                                                                                            <Avatar src={item?.client?.usr_profile_img} name={item?.client?.cl_dt_name} round size="45" color="#EEEFF3" fgColor="#000" textSizeRatio={2} />

                                                                                        </div>
                                                                                        <div>
                                                                                            <h9>{moment(item?.review_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')}</h9>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='review-content-container'>
                                                                                        <p>{item?.cln_rvw_reply}</p>
                                                                                    </div>
                                                                                </div> : null
                                                                        }
                                                                    </>
                                                            }
                                                        </div>
                                                    ) : getReviewsRightSkeletonFlag ?
                                                        [...Array(3)].map((item, index) => <ReviewModalRightSideSkeleton />) : null
                                                // :
                                                // <>
                                                //     <div className="nodata-text fw-bold fs-4 d-flex justify-content-center">No Reviews Available</div>
                                                // </>

                                            }


                                        </InfiniteScroll>

                                        {
                                            !getReviewsRightSkeletonFlag && ratingReviewsList?.length == 0 &&
                                            <>
                                                <div className="nodata-text fw-bold fs-4 d-flex justify-content-center h-100 align-items-center">No Reviews Available</div>
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>

            <CustomModal
                modalOpen={addReplyModal}
                setModalOpen={setAddReplyModal}
                modaltitle={"Write a Reply"}
                type={'review'}
                modalBody={
                    <>
                        <div className='review-card my-2'>
                            <div className='card-content-one d-flex justify-content-between'>
                                <div className='d-flex gap-2 align-items-center '>
                                    <Avatar src={addReplyModalData?.client?.usr_profile_img} name={addReplyModalData?.client?.cl_dt_name} round size="45" color="#EEEFF3" fgColor="#000" textSizeRatio={2} />

                                    <div className='d-flex flex-column justify-content-center'>
                                        <h7 className="my-auto fw-bolder">{addReplyModalData?.client?.cl_dt_name}</h7>
                                        {
                                            addReplyModalData?.clinic &&
                                            <h8 className="my-auto fw-bold">{addReplyModalData?.clinic?.cln_name}, {addReplyModalData?.clinic?.cln_city}</h8>
                                        }
                                        <span className="my-auto treatment-list">{(addReplyModalData?.review_treatments?.map(e => e?.cln_treat_name))?.join(",")}</span>
                                    </div>
                                </div>
                                <div>
                                    <h9>{addReplyModalData?.review_date}</h9>
                                </div>
                            </div>
                            <div className='mb-2'>
                                <Rating initialValue={addReplyModalData?.cln_rvw_rating} fillColor="#175ffa" readonly size={16} />
                            </div>

                            <div className='review-content-container'>
                                <p>{addReplyModalData?.cln_rvw_message}</p>
                            </div>
                        </div>

                        <div className="add-reply-div">

                            <form onSubmit={handleAddReviewSubmit} className='d-flex flex-column'>
                                <textarea name="addReply" id="" cols="30" rows="5" placeholder='Type Here...' value={addReplyToBePostData} onChange={(e) => handleChageTextAreaData(e)} />
                                {addReplyToBePostError && <span className='text-danger'>{addReplyToBePostError}</span>}
                                <button className="add-reply-submit-btn" type='submit'>
                                    <div className='d-flex justify-content-center align-items-center gap-2  '>
                                        {addReplyFlag &&
                                            <div class="payment-spinner-loader"></div>
                                        }
                                        Submit
                                    </div></button>
                            </form>
                        </div>

                    </>
                }
            />
        </>

    )
}

export default ReviwewPopup
