
import React, { useState, useEffect, useMemo } from 'react'
import "./newcustomeColorPicker.scss"
import dropdownup from "../../images/dropdownup.svg"
import dropdwondown from "../../images/dropdwondown.svg"
import colorPicker from "../../images/colorPicker.svg"
import Slider from "@mui/material/Slider";
import { debounce, set } from "lodash";
import tinycolor from "tinycolor2";
import CustomModal from "../modal/CustomModal";
import { ChromePicker, HuePicker, AlphaPicker } from "react-color";
import StepButton from "../common/StepButton";
import { FiChevronRight } from "react-icons/fi";



const NewCustomeColorPicker = (props) => {
    const [barColor, setBarColor] = useState("");
    const [modalOpen, setModalOpen] = useState(false)
    const [color, setColor] = useState(""); // Initial color
    const [optancityColor, setOptancityColor] = useState("")

    const {
        // color,
        customColors,
        customColorsSecondary,
        customColorsTertiary,
        centerColorIndex,
        setCenterColorIndex,
        centerSecondaryColorIndex,
        setCenterSecondaryColorIndex,
        centerTertiaryColorIndex,
        setCenterTertiaryColorIndex,
        count,
        setColorType,
        setCount,
        open,
        setOpen,
        // sliderTrackStyle,
    } = props;

    const [alpha, setAlpha] = useState(100);
    const [changeColor, setChangeColor] = useState(1);



    const handleAlphaChange = (event) => {
         const value = event.target.value
         setAlpha(value)
        if (props?.primary) {
            props.setWebsiteData({
                ...props?.websiteData,
                primaryColor: tinycolor(props.websiteData?.primaryColor)
                    .setAlpha(value / 100)
                    .toRgbString(),
            });
        } else if (props?.secondary) {
            props.setWebsiteData({
                ...props?.websiteData,
                secondaryColor: tinycolor(props.websiteData?.secondaryColor)
                    .setAlpha(value / 100)
                    .toRgbString(),
            });
        } else if (props?.tertiary) {
            props.setWebsiteData({
                ...props?.websiteData,
                tertiaryColor: tinycolor(props.websiteData?.tertiaryColor)
                    .setAlpha(value / 100)
                    .toRgbString(),
            });
        }
    };

    const updateColor = () => {
        setTimeout(() => {
            setChangeColor(changeColor + 1);
            console.log("Color changed");
        }, 10);
    };

    useEffect(() => {
        if (props?.primary) {
            setBarColor(props.websiteData?.primaryColor);
        } else if (props?.secondary) {
            setBarColor(props.websiteData?.secondaryColor);
        } else if (props?.tertiary) {
            setBarColor(props.websiteData?.tertiaryColor);
        }
    }, [props?.primary, props?.secondary, props?.tertiary]);

    useEffect(() => {
        setColorType("Primary")
    }, [])

    const handleColorChange = (item, index) => {
        setAlpha(100)
        console.log("itemindex", item, index);
        setBarColor(item);
        if (props?.primary) {
            props.setWebsiteData({ ...props?.websiteData, primaryColor: item });
            setCenterColorIndex(index);
        } else if (props?.secondary) {
            props.setWebsiteData({ ...props?.websiteData, secondaryColor: item });
            setCenterSecondaryColorIndex(index);
        } else if (props?.tertiary) {
            props.setWebsiteData({ ...props?.websiteData, tertiaryColor: item });
            setCenterTertiaryColorIndex(index);
        }

        // Calculate the number of items to move to the center
    };

    const handleHueColorChange = (newColor) => {
        setColor(newColor.hex);
        setBarColor(newColor.hex)
        setOptancityColor(newColor.rgb)
        setAlpha(100)
    };

    const onHandlerChangeOptancity = (newColor) => {
        setAlpha(newColor.rgb.a * 100)
        setColor(newColor.hex)
        setOptancityColor(newColor.rgb)
    }


    console.log("propspropsprops", props, props.websiteData, color)

    console.log("centerColorIndex", centerColorIndex, centerSecondaryColorIndex);


    return (
        <>
            <div className='dropdown-main'>
                {console.log("props primary", props?.primary, props?.secondary)}
                <div className='dropdown-body' onClick={() => setOpen((pre) => !pre)}>
                    <div className='dropdown-laber'>
                        <div className='colorPicker'
                            style={{ background: props?.primary ? props?.websiteData?.primaryColor : props?.secondary ? props.websiteData?.secondaryColor : "" }}
                        />
                        <div className='label'>{props?.label}</div>
                    </div>
                    {open ? <img src={dropdownup} alt='dropdownup' /> : <img src={dropdwondown} alt='dropdwondown' />}
                </div>
                {open &&
                    <>
                        <div class="dropdown-divider" />
                        <div className='custom-color-availabe'>
                            {props?.primary ?
                                customColors?.map((value, index) => {
                                    return (<div className={centerColorIndex === index ? "colorBox" : 'color-show'} onClick={() => handleColorChange(value, index)} key={index} style={{ background: value }} />)
                                }) : props.secondary
                                    ? customColorsSecondary.map((value, index) => {
                                        return (<div className={centerSecondaryColorIndex === index ? "colorBox" : 'color-show'} onClick={() => handleColorChange(value, index)} key={index} style={{ background: value }} />)
                                    }) : ""
                            }
                            <img alt='colorPicker' src={colorPicker} onClick={() => setModalOpen(true)} />
                        </div>
                        <div class="dropdown-divider" />
                        <div className='optancity-change'>
                            <div className="optancityTextWrapper">
                                <div>Opacity</div>
                                <div>{parseInt(alpha)}%</div>
                            </div>
                            <Slider
                                onChange={handleAlphaChange}
                                defaultValue={100}
                                style={{
                                    background: `linear-gradient(90deg, transparent 0%, ${barColor} )`,
                                    color: "transparent",
                                    padding: "4px 0px",
                                }}
                                value={alpha}
                                aria-label="Default"
                                valueLabelDisplay="auto"
                                min={0}
                                max={100}
                            />
                        </div>
                    </>}
                <CustomModal
                    className={"customHMcolorModal"}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    shouldCloseOnOverlayClick={true}
                    modalBody={
                        <div className='custom-colors-picker'>
                            <ChromePicker
                                color={color}
                                className='sketch-picker'
                                onChangeComplete={handleHueColorChange}
                            />
                            <HuePicker
                                onChangeComplete={handleHueColorChange}
                                color={color}
                            />
                            {/* <div class="opacity-wrap">
                                <div class="opacity-flex">
                                    <h6>Opacity</h6>
                                    <span>{parseInt(alpha)}</span>
                                </div>
                                <AlphaPicker
                                    onChangeComplete={onHandlerChangeOptancity}
                                    color={optancityColor}
                                />
                            </div> */}

                            <StepButton
                                onClick={() => {
                                    updateColor();

                                    if (props?.primary) {
                                        props.setWebsiteData({
                                            ...props?.websiteData,
                                            primaryColor: color,
                                        });
                                        if (props.customColors.includes(color)) {
                                            setCenterColorIndex(props.customColors.indexOf(color));
                                        } else {
                                            if (changeColor === 1) {
                                                props.customColors.push(color);
                                            } else {
                                                let temp = props.customColors;
                                                temp[(props.customColors.length - 1)] = color;
                                                props.setCustomColors(temp);
                                            }
                                            setCenterColorIndex((props.customColors.length - 1));
                                        }
                                    } else if (props?.secondary) {
                                        props.setWebsiteData({
                                            ...props?.websiteData,
                                            secondaryColor: color,
                                        });
                                        if (props.customColorsSecondary.includes(color)) {
                                            setCenterSecondaryColorIndex(
                                                props.customColorsSecondary.indexOf(color)
                                            );
                                        } else {
                                            if (changeColor === 1) {
                                                props.customColorsSecondary.push(color);
                                            } else {
                                                let temp = props.customColorsSecondary;
                                                temp[(props.customColorsSecondary.length - 1)] = color;
                                                props.setCustomColorsSecondary(temp);
                                            }
                                            setCenterSecondaryColorIndex((props.customColorsSecondary.length - 1));
                                        }
                                    }
                                    setAlpha(100)
                                    setBarColor(color);
                                    setModalOpen(false);
                                }}
                                label="Done"
                                blue={true}
                                icon={<FiChevronRight className="arrow_next" />}
                            />
                        </div>
                    }
                />
            </div >
        </>
    )
}

export default NewCustomeColorPicker