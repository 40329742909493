import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_CLINIC_LOCATION_CONST, GET_CLINIC_SERVICES_LIST, GET_WALK_IN_CLIENT_LIST } from "../../constants/walk-in-form-const/clientWalkInConst";
import { toast } from "react-toastify";
import { error } from "jquery";
import { useNavigate } from "react-router";


const GET_CLIENT_LIST = gql`
query GetClientList_v3($page: Int, $limit: Int, $search: String, $deletedClients: Boolean, $requestFrom: String) {
  getClientList_v3(page: $page, limit: $limit, search: $search, deletedClients: $deletedClients, requestFrom: $requestFrom) {
    success
    message
    data {
      clients {
        clentId
        clientName
        clientEmail
        readableContact
        clientProfileImage
      }
      total
    }
  }
}
`;

// var page;
export const WalkInClientList = () => {
  const dispatch = useDispatch();
  const [getClientList, clientListData] = useLazyQuery(
    GET_CLIENT_LIST,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClientList_v3: responseData }) => {

        dispatch({
          type: GET_WALK_IN_CLIENT_LIST,
          payload: responseData.data,
          // page
        });
        if (responseData.success) {

        } else {

        }


      },
    }
  );
  const clientListQueryData = clientListData;
  const initClientList = (data) => {
    // page = data.page
    getClientList({
      variables: data,
    });
  };
  return { initClientList, clientListQueryData };
};


// ========================GET CLINIC LOCATIONS========================
const GET_CLINIC_LOCATIONS_QUERY = gql`
query GetClinicLocations($page: Int, $limit: Int, $search: String, $requestFrom: String, $itemIds: [Int]) {
    getClinicLocations(page: $page, limit: $limit, search: $search, request_from: $requestFrom, itemIds: $itemIds) {
      success
      message
      data {
        companyName
        isFinanceAvailable
        isStripeSetup
        isRyftSetup
        total
        items {
          clinicId
          clinicName
          imageUrl
          completeAddress
        }
      }
    }
  }
`;

export const GetClinicLocationsAction = () => {
  const dispatch = useDispatch();
  const [
    GetClinicLocationsResponseFunction,
    getClinicLocationsResponseData,
  ] = useLazyQuery(GET_CLINIC_LOCATIONS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getClinicLocations: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: GET_CLINIC_LOCATION_CONST,
          payload: responseData.data,
        })
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getClinicLocationsQueryData = getClinicLocationsResponseData; //variable

  const initGetClinicLocationsData = (data) => {
    GetClinicLocationsResponseFunction({
      variables: data,
    });
  };

  return { getClinicLocationsQueryData, initGetClinicLocationsData };
};

// ========================GET CLINIC SERVICES LIST========================
const GET_CLINIC_SERVICE_LIST = gql`
query GetTreatmentsForClinic($clinicId: Int, $page: Int, $limit: Int, $search: String, $itemIds: [Int]) {
  getTreatmentsForClinic(clinicId: $clinicId, page: $page, limit: $limit, search: $search, itemIds: $itemIds) {
    success
    message
    data {
      treatments {
        treatmentId
        treatmentName
        treatmentPrice
        treatmentTime
        depositAmount
        consentFomrs
        aftercareDocs
      }
      total
      totalPages
      currentPage
    }
  }
}
`;

export const GetClinicServicesListAction = () => {
  const dispatch = useDispatch();
  const [
    GetClinicServicesListResponseFunction,
    getClinicServicesListResponseData,
  ] = useLazyQuery(GET_CLINIC_SERVICE_LIST, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getTreatmentsForClinic: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: GET_CLINIC_SERVICES_LIST,
          payload: responseData.data,
        })
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getClinicServicesListQueryData = getClinicServicesListResponseData; //variable

  const initGetClinicServicesListData = (data) => {
    GetClinicServicesListResponseFunction({
      variables: data,
    });
  };

  return { getClinicServicesListQueryData, initGetClinicServicesListData };
}

// ========================ADD FORM========================
const ADD_CLIENT_WALK_IN_FORM = gql`
mutation CreateOrUpdateWalkIn($clientId: Int!, $clinicId: Int!, $appointmentDate: String!, $appointmentStartTime: String!, $appointmentEndTime: String!, $treatmentIds: [Int]!, $appointmentId: Int, $consentFormIds: [Int], $aftercareDocumentIds: [Int], $signature: Upload, $signatureBase64: String) {
  createOrUpdateWalkIn(clientId: $clientId, clinicId: $clinicId, appointmentDate: $appointmentDate, appointmentStartTime: $appointmentStartTime, appointmentEndTime: $appointmentEndTime, treatmentIds: $treatmentIds, appointmentId: $appointmentId, consentFormIds: $consentFormIds, aftercareDocumentIds: $aftercareDocumentIds, signature: $signature, signatureBase64: $signatureBase64) {
    success
    message
    data {
      appointmentId
      consentFormRecordId
      userId
      clientId
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      treatmentIds
      signature
      consentForms
      aftercareDocs
    }
  }
}
`;

export const AddClientWalkInFormAction = () => {
  const navigate = useNavigate();
  const [
    AddClientWalkInFormResponseFunction,
    addClientWalkInFormResponseData,
  ] = useMutation(ADD_CLIENT_WALK_IN_FORM);

  const addClientWalkInFormQueryData = addClientWalkInFormResponseData;

  const successCallback = (responseData) => {
    const newData = responseData?.data?.createOrUpdateWalkIn?.data?.consentFormRecordId;
    navigate('/clinic-walk-in-form',{state: {responseData: newData}})
    console.log('SuccessData:', responseData?.data?.createOrUpdateWalkIn?.data?.consentFormRecordId);
    // Handle success logic here
  };

  const errorCallback = (error) => {
    console.error('Error:', error);
    // Handle error logic here
  };

  const initAddClientWalkInFormData = (data) => {
    AddClientWalkInFormResponseFunction({
      variables: data,
    })
      .then((response) => {
        console.log(response);
        if (response.data.createOrUpdateWalkIn.success) {
          successCallback(response);
        } else {
          errorCallback(response);
        }
      })
      .catch((err) => {
        errorCallback(err);
      });
  };

  return { addClientWalkInFormQueryData, initAddClientWalkInFormData };
};






