import { gql, useMutation } from "@apollo/client";

const SEND_CUSTOM_SMS_TO_CLIENT_MUTATION = gql`
mutation SendCustomSMSToClient($clientId: Int!, $smsContent: String!) {
  sendCustomSMSToClient(clientId: $clientId, SMSContent: $smsContent) {
    success
    message
  }
}
`;

export const SendCustomSmsToClientMutation = () => {
    const [sendSmsToClient, sendSmsToClientResponseData] =
        useMutation(SEND_CUSTOM_SMS_TO_CLIENT_MUTATION);
    const sendSmsToClientMutationData = sendSmsToClientResponseData;

    const initiateSendCustomSmsToClient = (
        data,
        successCallback,
        errorCallback
    ) => {
        sendSmsToClient({
            variables: data,
        })
            .then(({ data: { sendCustomSMSToClient: responseData } }) => {
                if (responseData) {
                    successCallback(responseData);
                } else {
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                errorCallback(error);
            });
    };
    return { initiateSendCustomSmsToClient, sendSmsToClientMutationData };
};
