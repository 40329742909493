import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonLoader from '../common/Skeleton/SkeletonLoader';
import { GetAppointmentListByDayType } from '../redux/actions/client/GetAppointmentListById';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_APPOINTMENT_LIST_BY_TYPE } from '../redux/constants/appointment/appointmentConst';
import { CLEAR_PRESCRIBER_LIST } from '../redux/constants/consentFormThankyou/getPrescriberListConst'
import { CLEAR_PRESCRIPTIONS_LIST } from '../redux/constants/getPrescriptionsListConst'
import calenderIcon from "../../images/solar_calendar-linear.svg"
import "./appointment-list-by-type.scss"
import { useNavigate } from 'react-router';
import { getAppointmentRoute, getBookinginvoiceRoute, getClientProfileFormsRoute, getEditAppointmentRoute, getNewAppointmentRoute, rebookAppointmentRoute } from '../../Routes';
import NoClient from './NoClient';
import { ReactComponent as PlusIcon } from '../../../src/images/add_event.svg'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import MenuIcon from '../../../src/images/icons/verticalDots.svg'
import { ReactComponent as CloseIcon } from '../../../src/images/close_icon.svg'
import { ReactComponent as RightArrow } from '../../../src/images/icons/chevron-left.svg'
import { ReactComponent as EditIcon } from '../../../src/images/icons/appointment-list/edit.svg'
import { ReactComponent as ResendIcon } from '../../../src/images/icons/appointment-list/resend.svg'
import { ReactComponent as DownloadIcon } from '../../../src/images/icons/appointment-list/download.svg'
import { ReactComponent as ViewIcon } from '../../../src/images/icons/appointment-list/view.svg'
import { ReactComponent as DeleteIcon } from '../../../src/images/icons/appointment-list/delete.svg'
import showToast from '../common/CustomToast/CustomToast';
import { ResendBookingDetailsMutation } from '../redux/actions/sendReminder/ResendBookingDetails';
import ConsentActionPopup from '../consent-action-popup';
import { capitalizeFirstLetter } from '../../utils/initials';
import AppointmentListCard from '../common/appointment-list-card/AppointmentListCard';
import CustomModal from '../modal/CustomModal';
import AppointmentViewPopup from '../common/appointment-view-popup/AppointmentViewPopup';
import { frontendURL } from '../../config';
import { categorizeByMonth } from '../../utils/general';
import StepButton from "../common/StepButton"
import  ClientFormsViewPopup from "../common/client-forms-view-popup/ClientFormsViewPopup"
import { GetConsentFormPdfUrlQuery } from '../redux/actions/downloadConsentForm/downloadConsentForm'
import { GetMedicalFormPdfUrlQuery } from '../redux/actions/downloadMedicalForm/DownloadMedicalForm'
import { ResendMedicalFormAction } from '../redux/actions/sendReminder/ResendMedicalForm'
import { PrescriberList } from './prescriber-list/PrescriberList'
import OffCanvasModal from '../modal/OffCanvasModal/OffCanvasModal'
import { SendConsentFormOrMedicalFormToPrescriberAction } from '../redux/actions/send-consent-form-or-medicalform-to-prescriber/SendConsentFormOrMedicalFormToPrescriber'
import { PrescriptionList } from './prescription-list/PrescriptionList'
import { UpdatePrescriptionAttachedFormAction } from '../redux/actions/update-prescription-attached-form/UpdatePrescriptionAttachedForm'


const AppointmentListByDay = ({ active, setActive, clientId, setIsClientAppointment }) => {
  const [clicked, setClicked] = useState(0);
  const [appointmentId, setAppointmentId] = useState(null)
  const [isFilled, setIsFilled] = useState(null)
  const [deleteDataByAppointmentId, setDeleteDataByAppointmentId] = useState();
  const [clickedDate, setClickedDate] = useState("")
  const [showConsentPopup, setShowConsentPopup] = useState()
  const [confirmationPopupShow, setConfirmationPopupShow] = useState(false);
  const [compliancePopupShow, setCompliancePopupShow] = useState(false);
  const [consentFormRecordId, setConsentFormRecordId] = useState(null)
  const [type, setType] = useState(null)
  const [isAppointmentMenuOpen, setIsAppointmentMenuOpen] = useState(false)
  const [paymentTagData, setPaymentTagData] = useState(null)
  const [consentFormTagData, setConsentFormTagData] = useState(null)
  const [isPaymentPopUpOpen,setIsPaymentPopUpOpen]=useState(false)
  const [paymentPopUpPrice,setPaymentPopUpPrice]=useState(null)
  const [paymentPopClientName,setPaymentPopClientName]=useState(null)
  const [isFormsMenuOptionsOpen, setIsFormsMenuOptionsOpen] = useState(false)
  const [prescriberId, setPrescriberId] = useState(null)
  const [isPrescriberModalOpen, setIsPrescriberModalOpen] = useState(false)
  const [isNoPharmacyRegisterModalOpen, setIsNoPharmacyRegisterModalOpen] = useState(false)
  const [appointmentIdData, setAppointmentIdData] = useState(null);
  const [isPrescriptionModalOpen, setIsPrescriptionModalOpen] = useState(false)
  const [tempShowSelectedPrescriber, setTempShowSelectedPrescriber] = useState(null)
  const popOverRef = useRef(null)
  const [consentActive,setConsentActive]=useState("Consent")


  const moment = require('moment')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  //get appointment list from reducer
  const appointmentList = useSelector(state => state.appointmentListByTypeReducer?.appointmentListByType)
  const totalAppointmentCount = useSelector((state) => state?.appointmentListByTypeReducer?.totalAppointmentCount)
  const appointmentListPage = useSelector((state) => state?.appointmentListByTypeReducer?.appointmentListPage)
  const loadedAppointmentCount = useSelector((state) => state?.appointmentListByTypeReducer?.loadedAppointmentCount)
  const clientData = useSelector(state => state.clientConsentFormListReducer?.clientData)


 console.log("appointmentList",appointmentList) 
  //Call the Api
  const { appointmentListQueryData, initGetAppointmentListByDayType } = GetAppointmentListByDayType()

  const { initiateResendBookingDetailsData,resendBookingDetailsMutationData } = ResendBookingDetailsMutation()
  const redirectUrl = `${frontendURL}/client-profile/${clientId}`
  const encodedRedirectUrl = encodeURIComponent(redirectUrl);
  const { initConsentFormPdfUrl } = GetConsentFormPdfUrlQuery()
  const { initMedicalFormPdfUrl } = GetMedicalFormPdfUrlQuery()
  const { initResendMedicalFormData, resendMedicalFormQueryData } = ResendMedicalFormAction()
  const { initSendConsentFormOrMedicalFormToPrescriberData, sendConsentFormOrMedicalFormToPrescriberQueryData } = SendConsentFormOrMedicalFormToPrescriberAction()
  const { initUpdatePrescriptionAttachedFormData, updatePrescriptionAttachedFormQueryData } = UpdatePrescriptionAttachedFormAction()
  
  
  useEffect(() => {
    //Clear the appointment list
    dispatch({
      type: CLEAR_APPOINTMENT_LIST_BY_TYPE
    })
    //Prefill the data
    // initGetAppointmentListByDayType({
    //   appointmentListDayType: active,
    //   clientId: parseInt(clientId)
    // })
    initiateAppointmentListCall(true)
  }, [active])

  const downloadForm = (formId) => {
    initConsentFormPdfUrl({
        consentFormRecordId: formId
    }, (res) => {
        console.log(res?.data?.getConsentFormRecordPdfUrl, "download pdf")
        if (res?.data?.getConsentFormRecordPdfUrl?.success) {
            window.open(res?.data?.getConsentFormRecordPdfUrl?.data?.consentFormRecordPdfUrl, "_blank")
            setIsFormsMenuOptionsOpen(false)
        } else {
            showToast("error", res.message, "", false)
        }
    }, (err) => {
        showToast("error", err, "", false)

    })
}

const handleSendConsentFormorMedicalFormToPrescriber = (data) => {
  initSendConsentFormOrMedicalFormToPrescriberData(
      data
      , (res) => {
          // if (res.success) {
          //     showToast("success", res.message, "", false)
          //     setIsPrescriberModalOpen(false)
          //     setTempShowSelectedPrescriber(null)
          // } else {
          //     showToast("error", res.message, "", false)
          //     setTempShowSelectedPrescriber(null)
          // }
      }, (err) => {
          // showToast("error", err, "", false)
      })
}
  const handleOpenOverviewScreen = (bookingId) => {
    if (bookingId !== undefined && bookingId !== null) {
      // navigate(getAppointmentRoute(parseInt(bookingId)))
      navigate(getAppointmentRoute(parseInt(bookingId)), { state: { clientProfile: "clientprofile", clientId: clientId, active: active } })
    }
  }
  const menuData = [
    {
      title: "Edit / reschedule",
      icon: <EditIcon width={40} />,
    },
    {
      title: "Resend booking details",
      icon: <ResendIcon width={40} />,
    },
    {
      title: "Download invoice",
      icon: <DownloadIcon width={40} />,

    },
    {
      title: "View consent",
      icon: <ViewIcon width={40} />,
    },
    {
      title: "Delete",
      icon: <DeleteIcon width={40} />,
    }
  ]
  const handleCloseClick = () => {
    document.body.click()
    //popOverRef.onHide()
  }

  const downloadMedicalForm = (formId) => {
    initMedicalFormPdfUrl({
        clientId: clientId
    }, (res) => {
        if (res?.data?.getMedicalFormPdfUrl?.success) {
            window.open(res?.data?.getMedicalFormPdfUrl?.data?.medicalFormRecordPdfUrl, "_blank")
            setIsFormsMenuOptionsOpen(false)
        } else {
            showToast("error", res.message, "", false)
        }
    }, (err) => {
        showToast("error", err, "", false)

    })
}
const handleResendMedicalForm = () => {
  initResendMedicalFormData({
      clientId: clientId
  }, (res) => {
      if (res.success) {
          showToast("success", res.message, "", false)
          setIsFormsMenuOptionsOpen(false)
      } else {
          showToast("error", res.message, "", false)
      }
  }, (err) => {
      showToast("error", err, "", false)
  })
}
const handleSendReminder = (formId) => {
  initiateResendBookingDetailsData({
      "appointmentId": null,
      "consentFormRecordId": formId
  }, (res) => {
      if (res.success) {
          showToast("success", res.message, "", false)
          setIsFormsMenuOptionsOpen(false)
      } else {
          showToast("error", res.message, "", false)
      }
  }, (err) => {
      showToast("error", err, "", false)
  })
}

const sendClickHandler = (selectedPrescriberId) => {
  // let data;
  // if (active === "Consent") {
  //     data = {
  //         consentFormId: consentFormRecordId,
  //         prescriberId: parseInt(selectedPrescriberId)
  //     }
  // }
  // else {
  //     data = {
  //         clientId: clientId,
  //         prescriberId: parseInt(selectedPrescriberId)
  //     }
  // }
  // handleSendConsentFormorMedicalFormToPrescriber(data)
  setIsPrescriberModalOpen(false)
  setPrescriberId(selectedPrescriberId)
  setIsPrescriptionModalOpen(true)
}



  const handleResendDetails = (formId) => {
    initiateResendBookingDetailsData({
      "appointmentId": formId,
      "consentFormRecordId": null
    }, (res) => {
      if (res.success) {
        // toast.success(res.message)
        showToast("success", res?.message, "", false)
        setIsAppointmentMenuOpen(false)
      } else {

        showToast("error", res?.message, "", false)
      }
    }, (err) => {
      showToast("error", err, "", false)
    })
  }

  const sendtoPrescriberHandler = (dataTobePassed) => {

    let data;

    if (consentActive === "Consent") {
        data = {
            prescriptionId: dataTobePassed?.prescriptionId,
            clientId: clientId,
            consentFormId: consentFormRecordId,
            attachMedicalForm: dataTobePassed?.attachMedicalForm
        }
    }
    else {
        data = {
            prescriptionId: dataTobePassed?.prescriptionId,
            clientId: clientId,
            consentFormId: 0,
            attachMedicalForm: true,
        }
    }
    initUpdatePrescriptionAttachedFormData(
        data
        , (res) => {
            if (res.success) {
                showToast("success", res.message, "", false)
                setIsPrescriptionModalOpen(false)
                setTempShowSelectedPrescriber(null)
            } else {
                showToast("error", res.message, "", false)
                setTempShowSelectedPrescriber(null)
            }
        }, (err) => {
            showToast("error", err, "", false)
        })
}
 
const handleConsentFormClick=(title)=>{
    switch (title) {
        case "Download PDF":
            if (consentFormRecordId !== null) {
                downloadForm(consentFormRecordId)
            }
            break;

        case "Send to client":
          handleSendReminder(consentFormRecordId)
            break;


        case "Re-send to client":
          handleSendReminder(consentFormRecordId)
            break;

        case "Send to prescriber":
            dispatch({
                type: CLEAR_PRESCRIBER_LIST
            })
            dispatch({
                type: CLEAR_PRESCRIPTIONS_LIST
            })
            setPrescriberId(null);
            setIsFormsMenuOptionsOpen(false)
            setIsAppointmentMenuOpen(false)
            if (appointmentListQueryData?.data?.getAppointmentList?.data?.vendorIsPharmacy) {
                // initiatePrescriberListCall(true)
                setIsPrescriberModalOpen(true)
            }
            else {
                setIsNoPharmacyRegisterModalOpen(true)
            }
            break;

        case "Edit consent form":
            window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
            break;

        case "Complete in person":
            window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
            break;

        

        case "Repeat form":
          window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/duplicate/" + consentFormRecordId + `?documents=true`
            // navigate(rebookAppointmentRoute(appointmentIdData?.appointmentId, clientId), { state: { isFromClientForm: true } })
            break;

        case "Download PDF":
          downloadMedicalForm()
          break;

      case "Send to client":
          handleResendMedicalForm()
          break;

      case "Re-send to client":
          handleResendMedicalForm()
          break;

      case "Send to prescriber":
          dispatch({
              type: CLEAR_PRESCRIBER_LIST
          })
          dispatch({
              type: CLEAR_PRESCRIPTIONS_LIST
          })
          setPrescriberId(null);
          setIsFormsMenuOptionsOpen(false)
          setIsAppointmentMenuOpen(false)
          if (appointmentListQueryData?.data?.getAppointmentList?.data?.vendorIsPharmacy) {
              // initiatePrescriberListCall(true)
              setIsPrescriberModalOpen(true)
          }
          else {
              setIsNoPharmacyRegisterModalOpen(true)
          }
          break;

      case "Edit medical form":
          window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`
          break;

      case "Complete in person":
          window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`
          break;

      default:
          console.log('Default')
          break;
    }

    

}
  // const handleMenuClick = (title) => {

  //   switch (title) {
  //     case "Edit / reschedule":
  //       console.log('Edit / reschedule')
  //       // navigate(getNewAppointmentRoute(), { state: { clientId: clientId, active: active } })
  //       setIsClientAppointment(false)
  //       navigate(getEditAppointmentRoute(appointmentId), { state: { clientId: clientId, active: active } })
  //       break;
  //     case "Resend booking details":
  //       console.log('Resend booking details')
  //       handleResendDetails(appointmentId)
  //       break;
  //     case "Download invoice":
  //       console.log('Download invoice', appointmentId)
  //       setIsClientAppointment(false)
  //       navigate(getBookinginvoiceRoute(), { state: { clientId: clientId, entityId: appointmentId, selectedDate: clickedDate, active: active } })
  //       break;
  //     case "View consent":
  //       document.body.click()
  //       // setIsRootClose(false)
  //       // setOpenClientForm(true)
  //       navigate(getClientProfileFormsRoute(clientId), { state: { clientId: clientId, appointmentId: appointmentId, clickdate: clickedDate, active: active } })

  //       break;
  //     case "Delete":

  //       setShowConsentPopup(true);
  //       setConfirmationPopupShow(true);
  //       console.log('Delete')
  //       break;
  //     default:
  //       console.log('Default')
  //       break;
  //   }
  // }

  const handlePaymentResend=()=>{
handleResendDetails(appointmentId);
setIsPaymentPopUpOpen(false)
  }

  const handleMenuClick = (title, isBlockTimeOut = false) => {

    switch (title) {
      // case "View or edit event":
      //   if (moment().isAfter(moment(blockoutEndDate, "YYYY-MM-DD"))) {
      //     showToast("error", "you can't edit this event", "", "")
      //   }
      //   else {
      //     navigate(getEditBlockOutTimeRoute(blockOutId, blockOutDateId), { state: { fromBlockoutCalendarList: true } })
      //   }
      //   break;

      // case "Delete":
      //   console.log('Delete')

      //   if (moment().isAfter(moment(blockoutEndDate, "YYYY-MM-DD"))) {
      //     showToast("error", "you can't delete this event", "", "")
      //   }
      //   else {
      //     setIsDelete(true)
      //     setIsAppointmentMenuOpen(false)
      //   }
      //   break;
      case "Payment":
        if(paymentTagData?.paymentTag === "Unpaid"){
          setIsPaymentPopUpOpen(true)
        }
        break;

      case "Edit or reschedule":
        console.log('Edit / reschedule')
        navigate(getEditAppointmentRoute(appointmentId), { state: { clientId: clientId, active: active } })
        break;

      case "Appointment overview":
        navigate(getAppointmentRoute(parseInt(appointmentId)), { state: { clientProfile: "clientprofile", clientId: clientId, active: active } })
        break;

      case "Resend booking & consent":
        console.log('Resend booking details')
        handleResendDetails(appointmentId)
        break;

      case "Download invoice":
        console.log('Download invoice', appointmentId)
        navigate(getBookinginvoiceRoute(), { state: { clientId: clientId, entityId: appointmentId, selectedDate: clickedDate, active: active } })
        break;

      case "Consent form":
        setIsFormsMenuOptionsOpen(true)
        
        console.log('View consent', clientId)
        document.body.click()
       
        // window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
        break;

      case "Cancel appointment":
        setShowConsentPopup(true);
        setConfirmationPopupShow(true);
        setIsAppointmentMenuOpen(false)
        console.log('Delete')
        break;

      default:
        console.log('Default')
        break;
    }

  }
  const getAppointmentList = () => {
    dispatch({
      type: CLEAR_APPOINTMENT_LIST_BY_TYPE
    })
    //Prefill the data
    // initGetAppointmentListByDayType({
    //   appointmentListDayType: active,
    //   clientId: parseInt(clientId)
    // })
    initiateAppointmentListCall(true)

  }
  const popoverClick = (
    <Popover id="popover-trigger-click" className='appointment-option-main p-3' >
      <div className='popover-title-section'>
        <h1>Manage</h1>
        <CloseIcon className='close-icon-animation' onClick={handleCloseClick} />
      </div>
      {

        menuData.filter((items) => {
          if (isFilled) {
            // If isFilled is true, hide "Resend booking details"
            return items.title !== 'Resend booking details';
          }
          else {
            // If isFilled is false, hide "View consent"
            return items.title !== 'View consent';
          }
        }).map((items, index) => (
          <div className='appointment-list-option-container' key={index} onClick={() => { handleMenuClick(items?.title); document.body.click() }}>
            <div className='appointment-list-left-popover-content'>
              {items.icon}
              <p>{items.title}</p>
            </div>
            <div className='appointment-list-right-popover-content'>
              <RightArrow />
            </div>
          </div>
        ))
      }
    </Popover>

  );
  console.log("appointment list by day", appointmentList)

  const onAppointmentClickHandler = (appointmentData) => {
    setAppointmentId(parseInt(appointmentData?.booking_slug));
    setIsFilled(appointmentData?.isFilled);
    setDeleteDataByAppointmentId(appointmentData.cld_rc_id)
    setClickedDate(appointmentData?.calender_date)
    setConsentFormRecordId(appointmentData?.consentFormRecordId)
    setType("Appointment")
    setPaymentTagData({
      paymentTag: appointmentData?.paymentTag,
      paymentTagColor: appointmentData?.paymentTagColor,
      isPaymentDone: appointmentData?.isPaymentDone,
    })
    setConsentFormTagData({
      consentFormTag: appointmentData?.consentFormTag,
      consentFormTagColor: appointmentData?.consentFormTagColor,
      consentFormCount: appointmentData?.consentFormCount
    })
    setPaymentPopUpPrice(appointmentData?.price);
    setPaymentPopClientName(appointmentData?.client_name)
    setIsAppointmentMenuOpen(true)
  }

  const rebookClickHandler = (booking_slug) => {
    navigate(rebookAppointmentRoute(booking_slug, clientId))

  }

  const initiateAppointmentListCall = (refreshList = false, search = null) => {
    let newPage = appointmentListPage + 1;
    if (refreshList) {
      newPage = 1;

    } else if (totalAppointmentCount <= loadedAppointmentCount) {
      return;
    }

    initGetAppointmentListByDayType({
      page: newPage,
      limit: 15,
      appointmentListDayType: active,
      clientId: parseInt(clientId)
    })
  }

  const appointmentListDataNew = categorizeByMonth(appointmentList, "calender_date", "DD-MM-YYYY")

  return (
    <div className='appointment-list-main-container CustomeUpcomingappointment container-fluid'>
      <div className='list-content'>
        <div className={`navigation  my-4 px-0`}>
          <h1 className='m-auto list-title'>Appointments</h1>
          <span className={(active === "upcoming" ? "active me-2" : "not-active me-2")} onClick={() => { setClicked(1); setActive("upcoming") }}>Upcoming</span>
          <span className={(active === "previous" ? "active me-2" : "not-active me-2")} onClick={() => { setClicked(2); setActive("previous") }}>Previous</span>
        </div>
        <div>
          <div id="scrollableDiv" style={{ height: "100vh", width: "100%", overflowX: "scroll" }}>
            <InfiniteScroll
              dataLength={loadedAppointmentCount}
              next={() => initiateAppointmentListCall(false)}
              hasMore={loadedAppointmentCount < totalAppointmentCount}
              // loader={<SkeletonLoader type="appointment" />}
              scrollableTarget="scrollableDiv"
            >
              <>
                {

                  (
                    appointmentList?.length ?
                      Object.entries(appointmentListDataNew).map(([key, items]) => {
                        return <>
                          <h4 className='me-auto mt-4'>{key}</h4>
                          {items?.map((item, index) =>
                            <>
                              {
                                <>
                                  <AppointmentListCard onClickCallback={onAppointmentClickHandler} data={item} type="Appointment" isRebook={active === "previous" ? true : false} rebookClickHandler={rebookClickHandler} />
                                </>
                              }
                            </>
                          )}
                        </>
                      }) :
                      <>
                        {
                          appointmentListQueryData?.loading ?
                            <div className='appointmentListSkeletonLoader'>
                              <SkeletonLoader type="appointment" isClient={true} />
                              <br />
                              <SkeletonLoader type="appointment" isClient={true} />
                              <br />
                              <SkeletonLoader type="appointment" isClient={true} />
                            </div>
                            :
                            <NoClient fromAppointment={true} />
                        }
                      </>
                  )
                }
              </>
            </InfiniteScroll>
          </div>
          {
            showConsentPopup ? <ConsentActionPopup confirmationPopupShow={confirmationPopupShow} height={"280px"} setConfirmationPopupShow={setConfirmationPopupShow} setShowConsentPopup={setShowConsentPopup} setCompliancePopupShow={setCompliancePopupShow} compliancePopupShow={compliancePopupShow} elementId={deleteDataByAppointmentId} fromAppointmentListNavigation={false} clickedDate={clickedDate}
              getAppointmentData={getAppointmentList}
            /> : null
          }

        </div>
      </div>
      <div className={`flot-button`}>
        <PlusIcon onClick={() => navigate(getNewAppointmentRoute(), { state: { clientId: clientId, active: active } })} className='cursor-pointer' />
      </div>
      {/* appointment overview popup */}

      <CustomModal
        modalOpen={isAppointmentMenuOpen}
        // modalOpen={true}
        modaltitle={"Manage appointment"}
        setModalOpen={setIsAppointmentMenuOpen}
        className="width-30 customeWidth customeModelAppointment menudata-container"
        modalBody={
          <>
            <AppointmentViewPopup handleMenuClick={handleMenuClick} isFilled={isFilled} type={type} paymentTagData={paymentTagData} consentFormTagData={consentFormTagData} />
          </>

        }
      />


      {/* payment option popup */}

    <CustomModal 
        type={"common-width"}
        className={"paymentpopup"}
        modalOpen={isPaymentPopUpOpen}
        setModalOpen={setIsPaymentPopUpOpen}
        modalBody={
          <>
            <div className='paymentPopUpWrapper'>
               <h1>Would you like to send a payment link for the value of
              
               £{paymentPopUpPrice} to { paymentPopClientName}?</h1>
            <div>
                  <div className='paymentPopBtn'> 
                    <StepButton blue={true}  label={"Yes"} onClick={()=>handlePaymentResend()} isLoading={resendBookingDetailsMutationData.loading} />
                    <StepButton gray={true}  label={"No"} onClick={()=>setIsPaymentPopUpOpen(false)} />
                  </div>
               </div>
            </div>
          </>
        }
      />


      {/* consentForm menu option */}
      <CustomModal
             modalOpen={isFormsMenuOptionsOpen}
            // modalOpen={true}
              modaltitle={"Manage forms"}
              setModalOpen={setIsFormsMenuOptionsOpen}
              className="width-30 customeWidth customeModelAppointment menudata-container"
              modalBody={
              <>
              <ClientFormsViewPopup handleMenuClick={handleConsentFormClick} isFilled={isFilled} active={consentActive} />
              </>
              }
             />


                       <OffCanvasModal
                            isOffCanvasHeader={false}
                            isOpen={isPrescriberModalOpen}
                            placement={"end"}
                            className={"maxCanvasWidth508 SelectPrescriberListModel"}
                            onHide={() => setIsPrescriberModalOpen(false)}
                            // onHide={() => { setIsClientPeofile(false); setIsClientAppointment(false); setActive("upcoming") }}
                            body={<PrescriberList sendClickHandler={sendClickHandler} setIsPrescriberModalOpen={setIsPrescriberModalOpen} sendConsentFormOrMedicalFormToPrescriberQueryData={sendConsentFormOrMedicalFormToPrescriberQueryData} prescriberId={prescriberId} />}
                        />


                        {/* prescription list component */}
                        <OffCanvasModal
                            isOffCanvasHeader={false}
                            isOpen={isPrescriptionModalOpen}
                            placement={"end"}
                            className={"maxCanvasWidth508 SelectPrescriptionModel"}
                            onHide={() => setIsPrescriptionModalOpen(false)}
                            // onHide={() => { setIsClientPeofile(false); setIsClientAppointment(false); setActive("upcoming") }}
                            body={<PrescriptionList sendtoPrescriberHandler={sendtoPrescriberHandler} setIsPrescriberModalOpen={setIsPrescriberModalOpen} updatePrescriptionAttachedFormQueryData={updatePrescriptionAttachedFormQueryData} prescriberId={prescriberId} clientId={clientId} setIsPrescriptionModalOpen={setIsPrescriptionModalOpen} active={active} />}
                        />



                        {/* no pharmacy registration popup */}
                        <CustomModal
                            modalOpen={isNoPharmacyRegisterModalOpen}
                            setModalOpen={setIsNoPharmacyRegisterModalOpen}
                            type={"common-width"}
                            className={'customeNoPharmacyRegisterModel'}
                            modalBody={
                                <>
                                    <div className="share-booking-link-all-clients-confimration">
                                        <p>
                                            Currently you are not register with any pharmacy. Would you like to connect?
                                        </p>
                                        <div className="btnWrapper">
                                            <StepButton label={"Yes"} onClick={() => {
                                                window.location.href = frontendURL + "/prescriber-registration";
                                                setIsNoPharmacyRegisterModalOpen(false)
                                            }} blue={true} />

                                            <StepButton gray={true} label={"No"} onClick={() => {
                                                setIsNoPharmacyRegisterModalOpen(false)
                                            }} />
                                        </div>
                                    </div>
                                </>
                            }
                        />
    </div>
  )
}

export default AppointmentListByDay