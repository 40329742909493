import { gql, useMutation } from '@apollo/client';

const SAVE_FINANCE_TRANING_CERTIFICATE = gql`
  mutation SaveTrainingCertificatesForFinance($certificateFiles: [saveTrainingCertificatesForFinanceTypeArgs]) {
    saveTrainingCertificatesForFinance(certificate_files: $certificateFiles) {
      success
      message
      data {
        images {
          id
          certificate_name
          certificate_url
          time
        }
      }
    }
  }
`;

export const SaveFinaceTraningCertificateAction = () => {
  const [
    saveFinaceTraningCertificateResponsefunction,
    saveFinaceTraningCertificateResponseData,
  ] = useMutation(SAVE_FINANCE_TRANING_CERTIFICATE);

  const saveFInanceTraningCertificateQueryData =
    saveFinaceTraningCertificateResponseData;

  const initSaveFinanceTraningCertificateData = (
    data,
    successCallback,
    errorCallback
  ) => {
    saveFinaceTraningCertificateResponsefunction({
      variables: data,
    })
      .then((responseData) => {
        console.log(responseData, 'respn');
        if (responseData.data.saveTrainingCertificatesForFinance.success) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };
  return {
    saveFInanceTraningCertificateQueryData,
    initSaveFinanceTraningCertificateData,
  };
};
