import React, { useEffect, useRef, useState } from 'react'
import './HairProductLink.scss'
import { ReactComponent as EmailIcon } from '../../../assets/HairProduct/emailIcon.svg'
import { ReactComponent as CopyIcon } from '../../../assets/HairProduct/copyIcon.svg'
import { ReactComponent as SMSIcon } from '../../../assets/HairProduct/smsIcon.svg'
import { ReactComponent as ShareIcon } from '../../../assets/HairProduct/share.svg'
import PrintIcon from '../../../assets/HairProduct/print.png'
import CircularProgress from '@mui/material/CircularProgress';


import { toast } from 'react-toastify'
import { GetClientList_v3Action } from '../../redux/actions/bookingsLink/GetBookingLink'

import { useDispatch, useSelector } from 'react-redux'
import CustomModal from '../../modal/CustomModal'
import CustomAutoComplete from '../../auto-complete/CustomAutoComplete'
import StepButton from '../../common/StepButton'
import { APPEND_CLIENT_LIST_WITH_SKELETON, CLEAR_CLINIC_LIST } from '../../redux/constants'
import { conforms, debounce, set } from 'lodash'
import NavHeader from '../../common/NavHeader/NavHeader'
import { useNavigate } from 'react-router'
import { businessDashboardRoute, getCalendarRoute } from '../../../Routes'
import QRCode from 'qrcode.react';
import Skeleton from 'react-loading-skeleton'

import showToast from '../../common/CustomToast/CustomToast'
import { GetHairPrescriptionLink, GetHairPrescriptionLinkQRPDF, SendHairPrescriptionLinkThroughEmailAction, SendHairPrescriptionLinkThroughSMSAction } from '../../redux/actions/hairPrescriptionLink/GetHairPrescriptionLink'
import { SaveHairPrescriptionLink } from '../../redux/actions/hairPrescriptionLink/SaveHairPrescriptionLink'
import SkeletonLoader from '../../common/Skeleton/SkeletonLoader'
import { StripeOnboardingMutationAction } from '../../redux/actions/dashboard/stripeOnboardingGql'
import HairPrescriptionPopup from './HairPrescriptionPopup'
// import { GetStripeAccountAndFinanceRequestStatusAction } from '../../redux/actions/newAppointment/BusinessBoosters'

const HairProductLink = ({ endpoint = "endpoint" }) => {
    const listRef = useRef();
    const navigate = useNavigate()

    // const prefix = 'https://staging.facesconsent.com/v1/bookings/'

    const [editableText, setEditableText] = useState("")
    const [copyText, setCopyText] = useState('')
    const textRef = React.useRef(null)
    const [isTextInputClicked, setIsTextInputClicked] = useState(false);
    const dispatch = useDispatch()
    const { slug, is_strip_connected } = useSelector((state) => state?.hairPrescriptionLinkReducer?.hairPrescriptionLink);
    const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)
    const [clientsModal, setClientsModal] = useState(false);
    const [type, setType] = useState("")
    const [prefix, setPrefix] = useState("")
    // const [isStripeConnect, setIsStripeConnect] = useState(false)
    const [allClientsModal, setAllClientsModal] = useState(false);
    const [isIntroscreen , setIsIntroScreen] = useState(true)
    const [introScreen,setIntroScreen]=useState(1)
    console.log('isIntroscreen' , isIntroscreen)
    const [qrCodeLinkValue, setQrCodeLinkValue] = useState("")

    //CLIENTS DATA SEARCH
    const [searchString, setSearchString] = useState(null);

    const [allClients, setAllclients] = useState(false);
    const [stripePopUpOpen, setStripePopUpOpen] = useState(false);
    const [loadingStripeExpressLink, setLoadingStripeExpressLink] = useState(false);
    const [value, setValue] = useState([]);
    const handleOptionChange = (event, newValue) => {
        // newValue = newValue.filter(e => e?.title !== "No Client Found")
        setValue(newValue);
    };


    const { clinicList } = useSelector(state => state?.GetShareBookingLinkReducer)
    const { totalClinicCount } = useSelector(state => state?.GetShareBookingLinkReducer)
    const { loadedClinicCount } = useSelector(state => state?.GetShareBookingLinkReducer)
    const { clinicListPage } = useSelector(state => state?.GetShareBookingLinkReducer)

    //ACTIONS
    //Save Booking Link Mutation
    const { initiateSaveHairPrescriptionLink, saveHairPrescriptionLinkMutationData } = SaveHairPrescriptionLink();
    const { initGetHairPrescriptionLink, hairPrescriptionLinkQueryData } = GetHairPrescriptionLink()
    // const { initGetStripeAccountAndFinanceRequestStatusData } = GetStripeAccountAndFinanceRequestStatusAction()
    const { initGetHairPrescriptionLinkQR, HairPrescriptionLinkQRQueryData } = GetHairPrescriptionLinkQRPDF()
    const { stripeOnboardingQueryData, initStripeOnboardingData } = StripeOnboardingMutationAction();



    //CLIENT LISTING
    const { getClientList_v3QueryData: {
        loading: getClientList_v3Loading,
    }, initGetClientList_v3Data } = GetClientList_v3Action()

    const { initSendHairPrescriptionLinkThroughEmailData, sendHairPrescriptionLinkThroughEmailQueryData } = SendHairPrescriptionLinkThroughEmailAction()
    const { initSendHairPrescriptionLinkThroughSMSData, sendHairPrescriptionLinkThroughSMSQueryData } = SendHairPrescriptionLinkThroughSMSAction()


    // useEffect(() => {
    //     if (UserProfileData)
    //         initGetStripeAccountAndFinanceRequestStatusData({
    //             userId: UserProfileData?.user?.usr_id
    //         }, (response) => {
    //             if (response.paymentAccountSetup) {
    //                 setIsStripeConnect(response.paymentAccountSetup)
    //             }
    //         })
    // }, [UserProfileData])
    console.log('UserProfileData2525', UserProfileData)

    useEffect(() => {
        if (getClientList_v3Loading && clinicListPage != 1) {
            dispatch({
                type: APPEND_CLIENT_LIST_WITH_SKELETON,
            })
        }
    }, [getClientList_v3Loading]);

    //INFINITE SCROLL FOR CLIENTS DATA

    const initClientList = (refreshList = false) => {
        let newPage = clinicListPage + 1;
        if (refreshList) {
            newPage = 1;
        } else if (totalClinicCount <= loadedClinicCount) {
            return;
        }

        initGetClientList_v3Data({
            page: newPage,
            limit: 10,
            search: searchString,
            deletedClients: false,
            requestFrom: type == "email" ? "searchEmails" : "searchPhoneNumbers",
            // requestFrom: "",
        })
    };

    console.log( "prefix",copyText)

    //DEBOUNCE FOR SEARCH
    // Debounced API call function
    const debouncedApiCall = debounce((query) => {
        // Make the API call using the search query
        // Replace this with your own API call logic
        dispatch({
            type: APPEND_CLIENT_LIST_WITH_SKELETON,
            page: 1,
        })
        initClientList(true)
    }, 500); // Debounce delay in milliseconds (e.g., 500ms)

    useEffect(() => {
        if (searchString != null) {
            // Call the debounced API function with the search query
            debouncedApiCall(searchString);

            // Cleanup function to cancel any pending debounced calls
            return () => {
                debouncedApiCall.cancel();
            };
        }
    }, [searchString]);

    const handleScrollCallback = () => {
        if (listRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listRef.current;

            if (
                scrollTop + clientHeight === scrollHeight &&
                totalClinicCount > loadedClinicCount
            ) {
                initClientList();
            }
        }
    };

    useEffect(() => {
        if (slug !== undefined) {
            let a = copyText + slug
            setCopyText(a)
            setEditableText(slug)
        }
    }, [])

    useEffect(() => {
    }, [editableText, qrCodeLinkValue])

    const handleKeyDown = (e) => {
        // Check if the Backspace key is pressed
        if (e.key === 'Backspace') {
            setIsTextInputClicked(true);
        } else {
            setIsTextInputClicked(true);
        }
    };

    //BUSINESS BOOKING LINK
    useEffect(() => {
        initGetHairPrescriptionLink({}, (success) => {
            if (success) {
                const segments = success.full_url.split('/');
                segments.pop();
                setPrefix(segments.join("/") + "/")
                let a = segments.join("/") + "/" + success.slug
                setCopyText(a)
                setEditableText(success.slug)
                setQrCodeLinkValue(a)
                setIsIntroScreen(success.is_hair_prescription_intro)
            }
        }, (error) => {
        })
    }, [])

    const handleSaveHairPrescriptionLink = () => {
        // Check if Stripe is connected
        if (!is_strip_connected) {
            setStripePopUpOpen(true)
            // showToast("error", "Stripe is not connected. Please connect your Stripe account.", "", "");
            return;
        }
        if (editableText.trim() == "") {
            showToast("error", 'Please enter valid link', "", "");
            setIsTextInputClicked(true);
        } else {

            initiateSaveHairPrescriptionLink(
                {
                    userId: UserProfileData?.user?.usr_id,
                    slug: editableText.trim()
                },
                (success) => {
                    if (success.success) {
                        showToast("success", success.message, "", "")
                        setIsTextInputClicked(false);
                        setQrCodeLinkValue(copyText)
                    }
                    else {
                        showToast("error", success.message, "", "")
                        setIsTextInputClicked(true);
                    }

                },
                (error) => {
                    showToast("error", error.message, "", "")
                    setIsTextInputClicked(false);
                }
            )
        }
    }

    const handleCopyHairPriscriptionLink = () => {

        // Check if Stripe is connected
        if (!is_strip_connected) {
            setStripePopUpOpen(true)
            return;
        }

        if (editableText?.trim() == "") {
            showToast("error", 'Please enter valid link', "", "")

        } else {
            navigator.clipboard.writeText(copyText)
            showToast("success", 'Hair Prescription Products link copied successfully', "", "")
        }
    }

    //handle Send Email
    const handleSendEmail = (shareType) => {
        // Check if Stripe is connected
        if (!is_strip_connected) {
            setStripePopUpOpen(true)
            return;
        }
        setValue([])
        setAllclients(false)
        dispatch({
            type: CLEAR_CLINIC_LIST
        })
        if (editableText.trim() == "") {
            showToast("error", 'Please enter valid link', "", "")
        } else {
            setType(shareType);
            setClientsModal(true);
        }
        // window.open(`mailto:email@example.com?subject=Subject&body=${copyText}`)
    }

    const handleClientsForm = (e) => {
        e.preventDefault();
        let temp = [];
        if (type == "email") {
            if (value.length > 0 || allClients) {
                temp = value.map((e) => e.clientEmail);
            } else {
                showToast("error", "Please select atleast one client", "", "")
                return
            }

            if (allClients) {
                setAllClientsModal(true)
                return
            }

            initSendHairPrescriptionLinkThroughEmailData({
                emails: temp,
                sendToAllClients: allClients
            },
                (success) => {
                    // toast.success(success.message)
                    showToast("success", success.message, "", "")
                    setValue([])
                    setClientsModal(false)
                },
                (error) => {
                    showToast("error", error.message, "", "")
                }
            )
        } else {
            if (value.length > 0 || allClients) {
                // temp = value.map((e) => e.readableContact);
                temp = value.map((e) => {
                    return e.readableContact.replace(/\s+/g, '');
                });
            } else {
                showToast("error", "Please select atleast one client", "", "")
                return
            }

            if (allClients) {
                setAllClientsModal(true)
                return
            }
            initSendHairPrescriptionLinkThroughSMSData({
                contacts: temp,
                sendToAllClients: allClients
            },
                (success) => {
                    showToast("success", success.message, "", "")
                    setValue([])
                    setClientsModal(false)
                },
                (error) => {
                    showToast("error", error.message, "", "")
                }
            )
        }

    };

    const handleAllclientsConfirmation = () => {
        setAllClientsModal(true)
        setClientsModal(false)
        if (type == "email") {

            initSendHairPrescriptionLinkThroughEmailData({
                emails: [],
                sendToAllClients: true
            },
                (success) => {
                    showToast("success", success.message, "", "")
                    setAllclients(false)
                    setAllClientsModal(false)
                    setClientsModal(false)
                },
                (error) => {
                    showToast("error", error.message, "", "")
                }
            )
        } else {
            initSendHairPrescriptionLinkThroughSMSData({
                contacts: [],
                sendToAllClients: true
            },
                (success) => {
                    showToast("success", success.message, "", "")
                    setAllclients(false)
                    setAllClientsModal(false)
                    setClientsModal(false)
                },
                (error) => {
                    showToast("error", error.message, "", "")
                }
            )
        }
    }

    const handlePrintQRCode = () => {
        // Check if Stripe is connected
        if (!is_strip_connected) {
            setStripePopUpOpen(true)
            return;
        }
        initGetHairPrescriptionLinkQR({},
            (success) => {
                // Check if the PDF data is available
                if (success.data && success.data.slug) {

                    // Convert base64 string to Blob
                    // const pdfData = atob(success.data.slug);
                    // const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });

                    const byteCharacters = atob(success.data.slug);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);

                    // Create Blob from Uint8Array
                    const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });

                    // Create a URL for the Blob
                    const pdfUrl = URL.createObjectURL(pdfBlob);

                    console.log('pdfData', atob(success.data.slug))
                    console.log('byteArray', byteArray)

                    // Open the PDF URL in a hidden iframe and initiate printing
                    const iframe = document.createElement('iframe');
                    iframe.style.display = 'none';
                    iframe.src = pdfUrl;
                    document.body.appendChild(iframe);

                    iframe.onload = function () {
                        setTimeout(function () {
                            iframe.contentWindow.print();
                        }, 1);
                    };

                } else {
                    // Handle the case where PDF data is not available
                    showToast("error", "PDF data not available", "", "");
                }
            },
            (error) => {
                showToast("error", "PDF data not available", "", "");
            }
        )

    }

    const handleStripeconnect = () => {
        setLoadingStripeExpressLink(true)
        initStripeOnboardingData({},
            (response) => {
                setLoadingStripeExpressLink(false)
                window.location.href = response?.data?.stripeOnboarding?.data?.account_link;
            },
            (error) => {
                setLoadingStripeExpressLink(false)
                toast.error("Something went wrong while creating your payment link, please try again after some time!");
            });
    }

    const handleClickinp = () => {
        const input = textRef.current;
        input.focus(); // Set focus on the input field
        const length = input.value.length;
        input.setSelectionRange(length, length); // Move cursor to the end of the text
        input.scrollLeft = input.scrollWidth; // Set scroll position to the end
    };

    return (
        <>
            {/* {hairPrescriptionLinkQueryData?.loading ?
                <SkeletonLoader type={"share-link-page"} showCircle={false} />
                : */}
                <>
                    {
                        introScreen===1 ?
                            <HairPrescriptionPopup  setIntroScreen={setIntroScreen} introScreen={introScreen} />
                            :
                            (hairPrescriptionLinkQueryData?.loading ?
                                <SkeletonLoader type={"share-link-page"} showCircle={false} />
                                :
                                <div className='hair-Product-link-container'>
                                       <NavHeader isClose={true} applyPadding={false} onClickCallback={() => {
                                         navigate(businessDashboardRoute())
                                        }} />
                            <div className="hair-product-section">
           <div className="container">
               <div className='d-flex justify-content-center'>
       
                   {
                       qrCodeLinkValue != "" ?
                           <QRCode value={qrCodeLinkValue} renderAs="canvas" size={150}
                               style={{ width: "150px", height: "170px", objectFit: "contain" }}
                           /> :
                           <Skeleton width={180} height={180} />
                   }
               </div>
               <div className='hair-product-link-main-div'>
                   <h4>
                       Share hair prescription link
                   </h4>
       
                   <p>
                       Refer clients via the link and earn 10% on all sales
                   </p>
                   <div className="hair-product-link-input-div">
                       <div className="row mx-0">
                           <div className="col-10 ps-0">
                               <input
                                   name="titre"
                                   id="exampleEmail"
                                   type="text"
                                   ref={textRef}
                                   value={copyText}
                                   onKeyDown={handleKeyDown}
                                   onChange={(e) => {
                                       const input = e.target.value
                                       let temp
                                       if (input.substr(prefix.length)?.charAt(0) == "/") {
                                           temp = input.substr(prefix.length).slice(1)
                                       } else {
                                           temp = input.substr(prefix.length)
                                       }
                                       setEditableText(temp)
                                       e.target.value = prefix + temp
                                       if (e?.target?.value)
                                           setCopyText(e?.target?.value)
                                   }}
                                   autoFocus={true}
                                   onClick={handleClickinp}
                               />
                           </div>
                           <div className="col-2">
                               <StepButton className={"hair-product-link-btn"} label={`${isTextInputClicked ? "Save" : "Copy"}`} blue={isTextInputClicked ? true : false} gray={isTextInputClicked ? false : true} onClick={() => isTextInputClicked ? handleSaveHairPrescriptionLink() : handleCopyHairPriscriptionLink()} isLoading={saveHairPrescriptionLinkMutationData?.loading} />
                               {/* <button className={isTextInputClicked ? "save-btn" : "copy-btn"} onClick={() => isTextInputClicked ? handleSaveHairPrescriptionLink() : handleCopyHairPriscriptionLink()} >{isTextInputClicked ? "Save" : "Copy"}</button> */}
                           </div>
                </div>
            </div>
            <div className="icon-images-div">
                <div className='icon-image' onClick={() => handleSendEmail("email")}>
                    <EmailIcon />
                    <p>Email</p>
                </div>
                <div className='icon-image' onClick={() => handleCopyHairPriscriptionLink()} >
                    <CopyIcon />
                    <p>Copy link</p>
                </div>
                <div className='icon-image' onClick={() => handleSendEmail("mobile")}>
                    <SMSIcon />
                    <p>SMS</p>
                </div>
                {/* <div className='icon-image' >
        <ShareIcon />
        <p>Share</p>
    </div> */}
                <div className='icon-image' >
                    {HairPrescriptionLinkQRQueryData?.loading ?
                        <div className='print-loader'>
                            <CircularProgress size={25} />
                        </div>

                        :

                        <img src={PrintIcon} alt="PrintIcon" height="50px" width="50px" onClick={() => handlePrintQRCode()} />
                    }
                    <p>Print</p>
                </div>
            </div>
        </div>
    </div>
                            </div>


{/* ========================SHARE hair Prescription LINK TO CLIENT MODAL======================== */}
<CustomModal
    modalOpen={clientsModal}
    // modalOpen={true}
    setModalOpen={setClientsModal}
    type={"common-width"}
    modaltitle={"Select Clients"}

    modalBody={
        <>
            {
                <form
                    className="d-flex flex-column mt-2"
                    onSubmit={handleClientsForm}
                >
                    <div>
                        <input
                            type="checkbox"
                            name="all"
                            id="all"
                            // checked={allClients}
                            onChange={(e) => setAllclients(e.target.checked)}
                            className='cursor-pointer'
                        />
                        <label htmlFor="all" className="ms-2 cursor-pointer">
                            Select All Clients
                        </label>
                    </div>
                    {!allClients && (
                        <CustomAutoComplete
                            multiple={true}
                            groupBy={false}
                            listRef={listRef}
                            setSearchString={setSearchString}
                            handleInputClick={() => {
                                dispatch({
                                    type: CLEAR_CLINIC_LIST
                                })
                                initClientList(true)
                            }}
                            loading={getClientList_v3Loading}
                            optionValue={clinicList}
                            value={value}
                            setValue={setValue}
                            handleOptionChange={handleOptionChange}
                            handleScrollCallback={handleScrollCallback}
                            disableCloseOnSelect={true}
                            className="mt-3"
                        />
                    )}
                    <StepButton className="mt-4" type={'submit'} label={"Share Hair Prescription Link"} blue={true} isLoading={sendHairPrescriptionLinkThroughEmailQueryData?.loading || sendHairPrescriptionLinkThroughSMSQueryData?.loading} />
                </form>
            }
        </>
    }
/>

{/* ALL CLIENTTS CONFIRMATION MODAL */}
<CustomModal
    modalOpen={allClientsModal}
    // modalOpen={true}
    setModalOpen={setAllClientsModal}
    type={"common-width"}

    modalBody={
        <>
            <div className="hair-product-link-all-clients-confimration">
                <p>
                    Are you sure you want to share Hair Prescription link to all clients?
                </p>
                <div className="row">

                    <div className='col-md-6'>
                        <StepButton red={true} label={"Cancel"} onClick={() => setAllClientsModal(false)} />
                    </div>
                    <div className='col-md-6'>
                        <StepButton label={"Yes"} onClick={() => {
                            handleAllclientsConfirmation()
                        }} blue={true} />
                    </div>
                </div>
            </div>

        </>
    }
/>

{/* stripe connection pop-up */}
{/* <CustomModal
modalOpen={stripePopUpOpen}
// modalOpen={true}
setModalOpen={setStripePopUpOpen}
type={"common-width"}

modalBody={
<>
<div className="hair-product-link-all-clients-confimration">
    <p>
        Sorry but you can not perform this action as you have not linked your bank account. Do you want to link your bank account right now?
    </p>
    <div className="row">

        <div className='col-md-6'>
            <StepButton label={"Yes"} onClick={() => { handleStripeconnect() }} blue={true} isLoading={loadingStripeExpressLink} />
        </div>
        <div className='col-md-6'>
            <StepButton label={"No"} onClick={() => setStripePopUpOpen(false)} red={true} />
        </div>
    </div>
</div>
</>
}
/> */}

<CustomModal
    modalOpen={stripePopUpOpen}
    // modalOpen={true}
    setModalOpen={setStripePopUpOpen}
    modalBody={
        <>
            <div className='link-yes-no-modal'>
                <p className='text-center link-yes-no-modal-desc'>Sorry but you can not perform this action as you have not linked your bank account. Do you want to link your bank account right now?</p>
                <div className='link-yes-no-btn-container'>
                    <button className='link-yes-no-item-btn me-3' onClick={() => { handleStripeconnect() }} > {loadingStripeExpressLink ? <CircularProgress size={15} color='inherit' /> : "Yes"}</button>
                    <button className='link-yes-no-outline-btn' onClick={() => setStripePopUpOpen(false)} >No</button>
                </div>
            </div>
        </>
    }
/>

                                </div>
                            )
                    }
                </>
           
        </>
    )
}

export default HairProductLink