import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";


const CREATE_NEXT_OF_KIN_MUTATION = gql`
mutation CreateOrUpdateNextOfKinDetails($clientId: Int!, $firstName: String!, $lastName: String!, $relation: String!, $phone: String!, $phoneCountry: String, $countryCode: String) {
    createOrUpdateNextOfKinDetails(clientId: $clientId, firstName: $firstName, lastName: $lastName, relation: $relation, phone: $phone, phoneCountry: $phoneCountry, countryCode: $countryCode) {
      success
      message
      data {
        clientId
        firstName
        lastName
        relation
        phoneCountry
        phone
        countryCode
      }
    }
  }
`;

export const CreateNextOfKin = () => {
  const [createNextOfKin, createNextOfKinResponseData] =
    useMutation(CREATE_NEXT_OF_KIN_MUTATION);
  const dispatch = useDispatch();
  const createNextOfKinMutationData = createNextOfKinResponseData;

  const initiateCreateNextOfKin = (
    data,
    successCallback,
    errorCallback
  ) => {
    createNextOfKin({
      variables: data,
    })
      .then(({ data: { createOrUpdateNextOfKinDetails: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateCreateNextOfKin, createNextOfKinMutationData };
};