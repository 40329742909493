import { domainName } from '../styles';
import React, { useEffect } from 'react';
import insync from "../favicon/insync-logo 2.png"

const DynamicTitleComponent = () => {

    //CHNAGE DOMAIN NAME USING URL
    useEffect(() => {
        domainName === "faces" ? document.title = 'Faces' : document.title = 'Insync';
    }, [domainName]); // 


    //CHNAGE FAVICON USING URL
    useEffect(() => {
       if(domainName=="insync"){
        const faviconLink = document.createElement('link');
        faviconLink.rel = 'icon';
        faviconLink.href =  insync; 
    
        const existingFavicons = document.head.querySelectorAll('link[rel="icon"]');
        existingFavicons.forEach((element) => element.remove());
    
        document.head.appendChild(faviconLink);
       }
      }, []); 

    return (
        <>
        </>
    );
};

export default DynamicTitleComponent;
