import { gql, useMutation } from "@apollo/client";



const SAVE_FINANCE_REISTATION_BASIC_DETAILS=gql`
mutation SaveFinanceBasicDetailsOfUser($email: String!, $phone: String!, $countryCode: String!, $phoneCountry: String!, $website: String!, $facebook: String, $instagram: String) {
    saveFinanceBasicDetailsOfUser(email: $email, phone: $phone, country_code: $countryCode, phone_country: $phoneCountry, website: $website, facebook: $facebook, instagram: $instagram) {
      success
      message
    }
  }
`

export const SaveFinanceReigistartionBasicDetailsAction=()=>{
    const [saveFinanceRegistrationBasicDetailsResponseFunction,
    saveFinanceregistationBasicDetailsResponseData]=useMutation(SAVE_FINANCE_REISTATION_BASIC_DETAILS)

const saveFinanceRegistrationBasicDetailsQueryData=saveFinanceregistationBasicDetailsResponseData;

 const initSaveFinanceRegistrationBasicDetailData=(data, successCallback, errorCallback)=>{
        saveFinanceRegistrationBasicDetailsResponseFunction({
            variables:data
        }).then((responseData)=>{
            console.log(responseData,"respn")
            if(responseData.data.saveFinanceBasicDetailsOfUser.success){
                successCallback(responseData)
            }else{
                errorCallback(responseData)
            }
        }).catch((error)=>{
            errorCallback(error)
        })
 }   

    return {saveFinanceRegistrationBasicDetailsQueryData,initSaveFinanceRegistrationBasicDetailData}
}