import axios from '../../../axios/index'
import { API_URL, BASE_URL } from "../../../axios/config";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import { RENEW_INSURANCE } from "../../constants/index";
import { renewInsuranceError, renewInsuranceSuccess } from '../../actions';


async function renewInsuranceAPI() {
    return axios.request({
        method: "post",
        url: BASE_URL + API_URL.INSURANCE_WEB_LINK_PAGE,
    });
}

function* renewInsuranceAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(renewInsuranceAPI);
        // yield put(setLoaderComplete());
        if (response.status === 200) {
            if (response.data.success) {
                yield put(renewInsuranceSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        // yield put(setLoaderComplete());
        yield put(renewInsuranceError(error));
        if (reject) reject(error);
    }
}

export function* renewInsuranceWatcher() {
    yield takeLatest(RENEW_INSURANCE, renewInsuranceAction);
}
