import React from 'react'
import BusinessHeader from '../../businessHeader'
import FinanceHome from '../../finance-registration/FinanceHome'
import './finance-registration.scss'

const FinanceRegistration = () => {
  return (
    <>
    <div className='prescriber-registration-container'>
      {/* <BusinessHeader /> */}
      <FinanceHome />
    </div>
    </>
  )
}

export default FinanceRegistration