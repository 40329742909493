import "./walk-in-main.scss";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
import heartIcon from "../../images/heart.svg";
import HandShake from "../../images/handshake.svg";
import React, { useEffect, useState } from "react";
import BackArrow from "../../images/back_arrow.svg";
import searcIcon from "../../images/search_icon.svg";
import rightArrow from "../../images/calander_next.svg"
import { useDispatch, useSelector } from "react-redux";
import LeftArrow from "../../images/calander_previous.svg"
import MedicalIcon from "../../images/icons/Medical_icon.svg";
import { AfterCareFormList, ConsentFormList } from "../redux/actions/walk-in-form/walkInAddFormsList";
import { SET_WALK_IN_AFTERCARE_FORM_DATA, SET_WALK_IN_CONSENT_FORM_DATA } from "../redux/constants/walk-in-form-const/clientWalkInConst";
import StepButton from "../common/StepButton";
import NavHeader from "../common/NavHeader/NavHeader";

const AddForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchConsentData, setSearchConsentData] = useState("");
  const [selectedOption, setSelectedOption] = useState("Consent");
  const [searchAfterCareData, setSearchAfterCareData] = useState("");
  const [selectedConsentForm, setSelectedConsentForm] = useState([]);
  const [currentPaginationPage, setCurrentPaginationPage] = useState(1);


  const [newConsentForm, setNewConsentForm] = useState([]);
  const [selectedAfterCareFormArray, setSelectedAfterCareFormArray] = useState([]);

  const consentFomrsListData = useSelector(state => state?.walkInClientListReducer?.consentFormList)
  const afterCareFomrsListData = useSelector(state => state?.walkInClientListReducer?.afterCareFormList)

  const { initConsentFormList, consentFomrListQueryData } = ConsentFormList();
  const { initAfterCareFormList, afterCareFomrListQueryData } = AfterCareFormList();
  

  useEffect(() => {
    switch (selectedOption) {
      case "Consent":
        initConsentFormList({
          page: currentPaginationPage,
          search: searchConsentData,
          limit: 10,
        });
        break;
      case "Aftercare":
        initAfterCareFormList({
          page: currentPaginationPage,
          search: searchAfterCareData,
          limit: 10,
        });
        break;
      default:
        break;
    }
  }, [selectedOption, currentPaginationPage, searchConsentData, searchAfterCareData]);

  const handleSearchConsentData = (e) => {
    setSearchConsentData(e.target.value);
    setCurrentPaginationPage(1);
  };

  const handleSearchAfterCareData = (e) => {
    setSearchAfterCareData(e.target.value);
    setCurrentPaginationPage(1);
  };

  const nextArrow = () => {
    return (<img src={rightArrow} width={25} height={25} alt="rightArrow" />)
  }

  const prevArrow = () => {
    return (<img src={LeftArrow} width={25} height={25} alt="LeftArrow" />)
  }

  const forms = [
    {
      id: 1,
      name: "Consent",
      category: "Consent",
    },
    {
      id: 2,
      name: "Aftercare",
      category: "Aftercare",
    },
    {
      id: 3,
      name: "Medical",
      category: "Medical",
    },
    {
      id: 4,
      name: "My forms",
      category: "MyForms",
    },
  ];

  const handleBackWalkInPage = () => {
    dispatch({
      type: SET_WALK_IN_CONSENT_FORM_DATA,
      payload: newConsentForm,
    });
    dispatch({
      type: SET_WALK_IN_AFTERCARE_FORM_DATA,
      payload: selectedAfterCareFormArray,
    });
    navigate("/walk-in-consent");
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleSelectForm = (type, formId, formName) => {
    if (type === "consent") {
      // CONSENT FORM //
      const updatedSelectedConsent = [...newConsentForm];
      const consentFormIndex = updatedSelectedConsent.findIndex((item) => item?.consentFormId === formId);
      if (consentFormIndex !== -1) {
        // If consentFormId is already in the selected array, remove it
        updatedSelectedConsent.splice(consentFormIndex, 1);
      } else {
        // If consentFormId is not in the selected array, add it
        updatedSelectedConsent.push({ consentFormId: formId, consentFormName: formName });
      }
      setNewConsentForm(updatedSelectedConsent);
      return
    }
    if (type === "aftercare") {

      // AFTER CARE FORM //
      const updatedSelectedAftercare = [...selectedAfterCareFormArray];
      const aftercareFormIndex = updatedSelectedAftercare.findIndex((item) => item?.aftercareDocId === formId);
      if (aftercareFormIndex !== -1) {
        // If aftercareDocId is already in the selected array, remove it
        updatedSelectedAftercare.splice(aftercareFormIndex, 1);
      } else {
        // If aftercareDocId is not in the selected array, add it
        updatedSelectedAftercare.push({ aftercareDocId: formId, aftercareDocName: formName });
      }
      setSelectedAfterCareFormArray(updatedSelectedAftercare);
      return
    };
  }

  console.log(newConsentForm, 'newConsentForm==');

  const isSelectFormData = (consentFormName) => {
    return consentFormName === selectedConsentForm;
  };

  const itemsPerPage = 10;
  const totalItems = consentFomrsListData?.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginationPages = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationPages.push(i);
  }

  // const isPaginationPage = (page) => {
  //   setCurrentPaginationPage(page);
  //   initConsentFormList({
  //     page: page,
  //     search: searchConsentData,
  //   });
  // }

  return (
    <div className="walk-in-consent-Main-form">
      <NavHeader isBackOnly={true} handleOnBack={handleBackWalkInPage} />

      <div className="container">
        <div className="walk-in-consent-page-main">
            <div className='SectionHeading'>
              <div className="Section-Title">Link forms</div>
              <div className="Section-Desc">Select consent and aftercare documents for your service.</div>
              <div className="form-sec">
                {forms.map((form) => (
                  <span
                    key={form.id}
                    onClick={() => handleOptionClick(form.category)}
                    className={selectedOption === form.category ? "selectedForm" : ""}
                  >
                    {form.name}
                  </span>
                ))}
              </div>
               <div className="search-tab AddFormSearch">
                {selectedOption === "Consent" && (
                  <>
                    <img src={searcIcon} alt="search" />
                    <input type="text" placeholder="Search name, email or phone" onChange={handleSearchConsentData} />
                  </>
                )}
                {selectedOption === "Aftercare" && (
                  <>
                    <img src={searcIcon} alt="search" />
                    <input type="text" placeholder="Search name, email or phone" onChange={handleSearchAfterCareData} />
                  </>
                )}
              </div>
            </div>
              
          {/* <div className="d-flex header-add-form"> */}
            {/* <div className="go-back-img" onClick={handleBackWalkInPage}>
              <img src={BackArrow} width={35} height={35} alt="zzz" />
            </div> */}
            {/* <div className="btn add-form-btn" onClick={handleBackWalkInPage}>
              <button>Done</button>
            </div> */}
          {/* </div> */}
          {/* ===== */}
          <div className="walk-in-consent-page">
            {/* <div className="walk-in-consent-page-header">
              <h3>Link forms</h3>
              <p>Select consent and aftercare documents for your service.</p>
              <div className="form-sec">
                {forms.map((form) => (
                  <span
                    key={form.id}
                    onClick={() => handleOptionClick(form.category)}
                    className={selectedOption === form.category ? "selectedForm" : ""}
                  >
                    {form.name}
                  </span>
                ))}
              </div>
              <div className="search-tab">
                <img src={searcIcon} alt="search" />
                {selectedOption === "Consent" && (
                  <input type="text" placeholder="Search name, email or phone" onChange={handleSearchConsentData} />
                )}
                {selectedOption === "Aftercare" && (
                  <input type="text" placeholder="Search name, email or phone" onChange={handleSearchAfterCareData} />
                )}
              </div>
            </div> */}

            {/* Consent form */}
            {selectedOption === "Consent" && (
              <>
                <div className="GridLocation AddGridFormList">
                  {consentFomrsListData?.consentForms?.map((item, index) => {
                    return (
                      <>
                        <div className="GridLocationItem">
                          <div className="GridLocationItemLeftContent">
                            <div className="location_profile_image">
                              <img src={HandShake} width={20} height={20} alt="step1" className="HandFormImg" />
                            </div>
                            <div className="user-details">
                              <p>{item?.consentFormName}</p>
                            </div>
                          </div>
                          <input
                            type="checkbox"
                            className="black-checkbox"
                            onChange={() => handleSelectForm("consent", item?.consentFormId, item?.consentFormName)}
                            checked={newConsentForm?.findIndex((form) => form?.consentFormId === item?.consentFormId) !== -1}
                          />
                        </div>
                      </>
                    )
                  }
                  )}

                  <div className="add-form-btn">
                      <StepButton label={"2 forms selected"} blue={true} onClick={handleBackWalkInPage} />
                  </div>
                  <ReactPaginate
                    previousLabel={prevArrow()}
                    nextLabel={nextArrow()}
                    pageCount={consentFomrsListData?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(page) =>
                      setCurrentPaginationPage(page.selected + 1)
                    }
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                    breakClassName={"pagination__break"}
                    breakLinkClassName={"pagination__break-link"}
                    pageClassName={"pagination__page"}
                    pageLinkClassName={"pagination__page-link"}
                  />
                </div>
              </>
            )}

            {/* Aftercare form */}
            {selectedOption === "Aftercare" && (
              <> 
                <div className="GridLocation AddGridFormList">
                  {afterCareFomrsListData?.aftercareDocs?.map((item, index) => {
                    return (
                      <>
                        <div className="GridLocationItem">
                          <div className="GridLocationItemLeftContent">
                            <div className="GridLocationItemLeftContent">
                              <div className="location_profile_image">
                                <img src={heartIcon} width={30} height={30} alt="step1" className="HandFormImg" />
                              </div>
                              <div className="user-details">
                                <p>{item?.aftercareDocName}</p>
                              </div>
                            </div>
                            <input
                              type="checkbox"
                              className="black-checkbox"
                              onChange={() => handleSelectForm("aftercare", item?.aftercareDocId, item?.aftercareDocName)}
                              checked={selectedAfterCareFormArray?.findIndex((form) => form?.aftercareDocId === item?.aftercareDocId) !== -1}
                            />
                          </div>
                        </div>
                      </>
                    )
                  }
                  )}
                <ReactPaginate
                  previousLabel={prevArrow()}
                  nextLabel={nextArrow()}
                  pageCount={afterCareFomrsListData?.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(page) =>
                    setCurrentPaginationPage(page.selected + 1)
                  }
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"}
                  breakClassName={"pagination__break"}
                  breakLinkClassName={"pagination__break-link"}
                  pageClassName={"pagination__page"}
                  pageLinkClassName={"pagination__page-link"}
                />
                </div>
              </>
            )}

            {/* Medical form */}
            {selectedOption === "Medical" && (
              <>
                <div className="Custome-Medical_Tab">
                  <div className="appointment-client-sec">
                    <div className="client-titel">
                      <p>You may also need</p>
                    </div>
                    <div className="left-img">
                      <div className="left_content">
                        <div className="img-foot">
                          <img src={MedicalIcon} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Client Medical form</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" />
                    </div>
                  </div>
                  <div className="appointment-client-sec">
                    <div className="client-titel">
                      <p>Recent</p>
                    </div>
                    <div className="left-img ">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={MedicalIcon} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={MedicalIcon} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                  </div>
                  <div className="appointment-client-sec">
                    <div className="client-titel">
                      <p>Discover</p>
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={MedicalIcon} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={MedicalIcon} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={MedicalIcon} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={MedicalIcon} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={MedicalIcon} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* My form */}
            {selectedOption === "MyForms" && (
              <>
                <div className="Custome-Medical_Tab">
                  <div className="appointment-client-sec">
                    <div className="client-titel">
                      <p>You may also need</p>
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={HandShake} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Client Medical form</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                  </div>
                  <div className="appointment-client-sec">
                    <div className="client-titel">
                      <p>Recent</p>
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={HandShake} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={HandShake} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                  </div>
                  <div className="appointment-client-sec">
                    <div className="client-titel">
                      <p>Discover</p>
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={HandShake} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={HandShake} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={HandShake} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={HandShake} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                    <div className="left-img">
                      <div className="d-flex left_content">
                        <div className="img-foot">
                          <img src={HandShake} width={30} height={30} alt="step1" />
                        </div>
                        <div className="user-details">
                          <h5>Form name</h5>
                        </div>
                      </div>
                      <input className="black-checkbox" type="checkbox" style={{ background: "red" }} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddForm;
