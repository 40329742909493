import { GET_DASHBOARD_USER_PROFILE_DATA, GET_PROFILE_DATA_SUCCESS } from "../../constants"

const initialState = {
    profileData: null
}
export const GetProfileDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                profileData: action.payload?.data?.profile_data[0],
            }
        case GET_DASHBOARD_USER_PROFILE_DATA:
            return {
                ...state,
                profileData: action.payload
            }
        default:
            return state
    }
}  