import React, { useEffect, useRef, useState } from "react"
import { VerifyFinanceInvoiceToken } from "../../../redux/actions/pay-later/invoice/generateInvoicePath"
import { useParams } from "react-router";


const DownloadFinanceInvoice = () => {
    const [invoiceData, setInvoiceData] = useState();
    const {slug} = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const {initverifyFinanceInvoiceTokenData ,verifyFinanceInvoiceTokenQueryData} = VerifyFinanceInvoiceToken()
    useEffect(() => {
        if (urlParams.get("token")) {

            initverifyFinanceInvoiceTokenData({
                financeLoanRequestId: parseInt(slug),
                token: urlParams.get("token")
            }, (res) => {
                if (res?.success) {
                    console.log("res", res)
                    setInvoiceData(res.data.invoiceBase64)
                }
            }, (err) => {
                console.log("errrrrrrrrr>>>>>>>>>>>", err);
            })
        }
    }, [])

    useEffect(() => {
        if (verifyFinanceInvoiceTokenQueryData) {
            setInvoiceData(verifyFinanceInvoiceTokenQueryData?.data?.verifyFinanceInvoiceToken?.data?.invoiceBase64)
        }
    }, [verifyFinanceInvoiceTokenQueryData])

    return (
        <>
          <>
            {
                verifyFinanceInvoiceTokenQueryData?.loading ? <div className="LoaderMain">
                    <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div> : !verifyFinanceInvoiceTokenQueryData?.data?.verifyFinanceInvoiceToken?.success && verifyFinanceInvoiceTokenQueryData?.called ?
                    <div className="h-100 d-flex justify-content-center align-items-center text-center fw-bold fs-1 ">
                        Invalid Link!
                    </div> :
                    invoiceData && (
                        <embed
                            src={`data:application/pdf;base64,${invoiceData}`}
                            type="application/pdf"
                            width="100%"
                            height="800px"
                        />
                    )}
        </>
        </>
    )
}

export default DownloadFinanceInvoice;