import { GET_TREATMENT_LISTING_CONST } from "../../constants/treatment-list-const/TreatmentListConst";

const initialState = {
  treatementList: [],
};

export default function treatmentsListReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TREATMENT_LISTING_CONST:
      return {
        ...state,
        treatementList: action.payload,
      };

    default:
      return state;
  }
}
