import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { DASHBOARD_ONBOARDING_DATA } from "../../constants";
import { useDispatch } from "react-redux";

const GET_ONBOARDING_STATUS_QUERY = gql`
query GetOnBoardingStatus {
  getOnBoardingStatus {
    success
    message
    data {
      statusData {
        title
        key
        subTitle
        button
        isAvailable
        isCompleted
        duration
      }
      dashBoardBannerFlag
      bannerData {
        bannerType
        bannerShow
        bannerName
      }
      locationId
      isFlexibleDateSet
    }
  }
}
`;

export const GetOnBoardingStatusAction = () => {
  const dispatch = useDispatch();
  const [
    GetOnBoardingStatusResponseFunction,
    getOnBoardingStatusResponseData,
  ] = useLazyQuery(GET_ONBOARDING_STATUS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getOnBoardingStatus: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: DASHBOARD_ONBOARDING_DATA,
          payload: responseData.data
        })
      }
    },
  });
  //this will be called from components
  const getOnBoardingStatusQueryData = getOnBoardingStatusResponseData; //variable

  const initGetOnBoardingStatusData = (data, successCallback, errorCallback) => {
    GetOnBoardingStatusResponseFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.getOnBoardingStatus?.success)
        if (successCallback)
          successCallback(res.data.getOnBoardingStatus)
    }).catch((err) => {
      if (errorCallback)
        errorCallback(err)
    });
  };

  return { getOnBoardingStatusQueryData, initGetOnBoardingStatusData };
};

const HIDE_WARNING_BANNER_MUTATION = gql`
  mutation HideWarningBanner($uhbtId: Int) {
    hideWarningBanner(uhbtId: $uhbtId) {
      success
      message
    }
  }
`;

export const HideWarningBannerAction = () => {
  const [
    mutate,
    hideWarningActionStatus,
  ] = useMutation(HIDE_WARNING_BANNER_MUTATION);

  const initHideWarningBannerAction = (data, successCallback, errorCallback) => {
    mutate({
      variables: data,
    }).then((res) => {
      if (res?.data?.hideWarningBanner?.success)
        if (successCallback)
          successCallback()
    }).catch((err) => {
      if (errorCallback)
        errorCallback(err)
    });
  };

  return { hideWarningActionStatus, initHideWarningBannerAction };
};