import React from 'react'
import './note-step.scss'
import StepButton from '../../../../../common/StepButton'

const NoteStep = ({ setStep, facialMappingNote, setFacialMappingNote }) => {
    return (
        <div className='noteStepMainDiv'>
            <h4>Add your note</h4>
            <p>Add a note that will be saved to your image</p>
            <textarea name="notes" id="notes" placeholder='Note...'
                value={facialMappingNote}
                onChange={(e) => setFacialMappingNote(e.target.value)}
            />
            <StepButton label={"Save"} className={"w-25"} blue={true}
                onClick={() => setStep(0)}
            />
        </div >
    )
}

export default NoteStep