import { forwardRef, useImperativeHandle, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../images/close_icon.svg';
import { ReactComponent as SearchIcon } from '../../images/client/search_icon.svg';
import './customeSearchBar.scss'

const CustomeSearchBar = forwardRef(({onSearch, SearchPlaceholder, isDisabled}, ref) => {
    const [searchText, setSearchText] = useState('');

    // clear search text from parent component
    useImperativeHandle(ref, () => ({
        clearSearch() {
            setSearchText('');
        }
    }))

    return (
        <div className="CustomeSearchBar">
            <input
                disabled={isDisabled}
                type="text"
                placeholder={SearchPlaceholder}
                value={searchText}
                onChange={(e) => {
                    const enteredText = e.target.value;
                    setSearchText(enteredText);
                    onSearch(enteredText);
                }}
            />
            <SearchIcon className="searchIcon" />
            {searchText?.length > 0 && (
                <CloseIcon
                    className="searchCloseIcon"
                    onClick={() => {
                        setSearchText('');
                        onSearch('');
                    }}
                />
            )}
        </div>
    );
})

export default CustomeSearchBar;
