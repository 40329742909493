import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const PUBLISH_WEBSITE_MUTATION = gql`
mutation PublishPersonalWebsite {
    publishPersonalWebsite {
      success
      message
    }
  }
`;

export const PublishPersonalWebsite = () => {
    const [publishPersonalWebsiteRequest, publishPersonalWebsiteResponseData] =
        useMutation(PUBLISH_WEBSITE_MUTATION);
    const dispatch = useDispatch();
    const publishPersonalWebsiteMutationData = publishPersonalWebsiteResponseData;

    const initiatePublishPersonalWebsite = (
        data,
        successCallback,
        errorCallback
    ) => {
        publishPersonalWebsiteRequest({
            variables: data,
        })
            .then(({ data: { publishPersonalWebsite: responseData } }) => {
                if (responseData) {
                    successCallback(responseData);
                } else {
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                errorCallback(error);
            });
    };
    return { initiatePublishPersonalWebsite, publishPersonalWebsiteMutationData };
};
