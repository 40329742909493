import React from "react";
import PropTypes from "prop-types";
import "./FinanceLeftCardComponent.scss";
import { ReactComponent as Checked } from "../../../../assets/finance-registration/completed_check.svg";

const FinanceCard = ({ icon: Icon, title, content, buttonText, onClick, checked, isDisabled = false, bluebutton = false, disabled, style, className, refProp }) => {
  const cardRef = React.useRef(null); // Always call useRef unconditionally

  React.useEffect(() => {
    // Update the ref if ref prop is provided
    if (refProp) {
      if (typeof refProp === "function") {
        refProp(cardRef.current);
      } else {
        refProp.current = cardRef.current;
      }
    }
  }, [refProp, cardRef]);

  return (
    <div className="prescriber-card" ref={cardRef}>
      {console.log(cardRef,"ref")}
      <div className="card-header">
        <div className="left-content">
          <Icon />
          <h1>{title}</h1>
        </div>
        {checked && <Checked />}
      </div>
      <div className="card-content">
        <p>{content}</p>
      </div>
      <div className="card-footer">
        <button disabled={isDisabled || disabled} style={{background:bluebutton ? "#1654f3" :'',color:bluebutton? "#fff":''}} className={className} onClick={onClick}>{buttonText}</button>
      </div>
    </div>
  );
};

FinanceCard.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  bluebutton: PropTypes.bool,
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]) // Defining the PropTypes for ref
};

export default FinanceCard;
