import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_FINANCE_SIGNTURE_DETAIL=gql`
query GetUserSignatureForFinance {
    getUserSignatureForFinance {
      success
      message
      data {
        signature_name
        signature_url
      }
    }
  }
`;

export const GetFinanceSigntureDetailAction=()=>{
    const [getFinanceSigntureDetailResponseFunction,
    getFinanceSigntureDetailResponseData]=useLazyQuery(GET_FINANCE_SIGNTURE_DETAIL,{
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted:({getUserSignatureForFinance:responseData})=>{
            if(responseData.success){
                console.log("res",responseData?.message)
            }else{
                console.log("error",responseData?.message)
            }
        }
    })

    const getFinanceSigntureDetailQueryData=getFinanceSigntureDetailResponseData;

    const initGetFinanceSigntureDetailData=(data)=>{
        getFinanceSigntureDetailResponseFunction({
            variables:data
        })
    }

    return { getFinanceSigntureDetailQueryData,initGetFinanceSigntureDetailData}
}