import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import beforeafterimage from "../../../../images/Image.svg";
import showToast from '../../../common/CustomToast/CustomToast';
import NavHeader from '../../../common/NavHeader/NavHeader';
import StepButton from '../../../common/StepButton';
import CustomModal from '../../../modal/CustomModal';
import { GetBeforeAfterPhotosAction } from '../../../redux/actions/appointments/GetBeforeAfterPhotos';
import { GetTreatmentwiseBeforeAfterPhotosCountAction } from '../../../redux/actions/appointments/GetTreatmentWiseBeforeAfterPhotosCount';
import { RemoveBeforeAfterPhotoAction } from '../../../redux/actions/appointments/RemoveBeforeAfterPhoto';
import { SaveBeforeAfterPhotosAction } from '../../../redux/actions/appointments/SaveBeforeAfterPhotos';
import { CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT } from '../../../redux/constants';
import "./before-after-add-photos.scss";
import SkeletonLoader from '../../../common/Skeleton/SkeletonLoader';
import Resizer from "react-image-file-resizer";


const BeforeAfterAddPhotos = ({ setStep, isOtherPhotoUpload, selectedTreatment, selectedTreatmentId, appointmentId, step, consentFormRecordId }) => {
    const location = useLocation()
    const navigate = useNavigate();
    const [uploadedFiles, setUploadedFiles] = useState([
        {
            beforeImage: {
                photo: "",
                photoType: "",
                photoId: null,
                enableDeleteApiCall: false

            },
            afterImage: {
                photo: "",
                photoType: "",
                photoId: null,
                enableDeleteApiCall: false
            }
        }
    ])
    const [isPreview, setIsPreview] = useState(false);
    const [previewImage, setPreviewImage] = useState(null)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)


    console.log("upload image", uploadedFiles)

    const { initBeforeAndAfterPhotosData, beforeAfterQueryData } = SaveBeforeAfterPhotosAction();
    const { initGetBeforeAfterPhotosData, getBeforeAfterPhotosQueryData } = GetBeforeAfterPhotosAction();
    const { initRemoveBeforeAfterPhotoData, removeBeforeAfterPhotoQueryData } = RemoveBeforeAfterPhotoAction();
    const { initGetTreatmentwiseBeforeAfterPhotosCountData, getTreatmentwiseBeforeAfterPhotosCountQueryData } = GetTreatmentwiseBeforeAfterPhotosCountAction()
    const dispatch = useDispatch();

    useEffect(() => {
        if (step == 2) {
            initGetBeforeAfterPhotosData({
                consentFormRecordId: parseInt(consentFormRecordId),
                treatmentId: selectedTreatmentId ? selectedTreatmentId : 0,
            }, (res) => {
                if (res.success) {

                } else {
                    console.log("res", res.message)
                }
            }, (err) => {
            })
        }
    }, [step])

    useEffect(() => {
        if (getBeforeAfterPhotosQueryData?.data?.getBeforeAfterPhotos?.data?.photos?.length > 0 && step == 2) {

            let resultArr = [];
            getBeforeAfterPhotosQueryData?.data?.getBeforeAfterPhotos?.data?.photos?.map((item) => {
                let tempObj = {
                    beforeImage: {
                        photo: "",
                        photoType: "",
                        photoId: null,
                        enableDeleteApiCall: false

                    },
                    afterImage: {
                        photo: "",
                        photoType: "",
                        photoId: null,
                        enableDeleteApiCall: false

                    }
                }
                if (item.beforePhoto) {

                    tempObj["beforeImage"] = {
                        photo: item?.beforePhoto,
                        photoType: 0,
                        photoId: item.beforePhotoId,
                        previewUrl: item?.beforePhoto,
                        enableDeleteApiCall: true

                    }
                }
                if (item.afterPhoto) {

                    tempObj["afterImage"] = {
                        photo: item.afterPhoto,
                        photoType: 1,
                        photoId: item.afterPhotoId,
                        previewUrl: item.afterPhoto,
                        enableDeleteApiCall: true
                    }
                }
                resultArr.push(tempObj)
            })
            setUploadedFiles(resultArr)
        }
        else {
            setUploadedFiles([
                {
                    beforeImage: {
                        photo: "",
                        photoType: "",
                        photoId: null,
                        enableDeleteApiCall: false

                    },
                    afterImage: {
                        photo: "",
                        photoType: "",
                        photoId: null,
                        enableDeleteApiCall: false
                    }
                }
            ])
        }
    }, [getBeforeAfterPhotosQueryData, step])


    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1280,
                720,
                file.type,
                100,
                0,
                (uri) => {
                    resolve(uri);
                    console.log("uri", uri)
                },
                "file"
            );
        });

    const handleFileUpload = async (acceptedFiles, index, key) => {
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

        // Check if there are any non-image files
        const nonImageFiles = acceptedFiles.filter(file => !file.type.startsWith('image/'));

        // If there are non-image files, display an error message
        if (nonImageFiles.length > 0) {
            toast.error("Please select only image files.....");
            return; // Stop further processing
        }
        const updatedImage = [...uploadedFiles];
        const image = await resizeFile(imageFiles[0]);
        // console.log("without compress", imageFiles)
        // console.log("image", image)
        updatedImage[index][key]["photo"] = [image];
        updatedImage[index][key]["photoType"] = key == "beforeImage" ? 0 : 1;
        updatedImage[index][key]["photoId"] = null;
        updatedImage[index][key]["previewUrl"] = URL?.createObjectURL(image);

        // Update the uploadedFiles state with the new files
        setUploadedFiles(updatedImage);
    };


    const addPhotos = () => {
        setUploadedFiles(prevBreaks => [...prevBreaks, {
            beforeImage: {
                photo: "",
                photoType: "",
                photoId: null,
                enableDeleteApiCall: false
            },
            afterImage: {
                photo: "",
                photoType: "",
                photoId: null,
                enableDeleteApiCall: false
            }
        }]);
    };

    const renderPhotos = () => {
        return uploadedFiles.map((file, index) => (
            <>
                <p>Photo {index + 1}</p>
                <div className='photoGrid' key={index}>
                    <div className='photoGridItem'>
                        {
                            file?.beforeImage?.photo ?
                                <div className={`formDropzoneUploading`}>
                                    <div className='add-more-label-container'>
                                        <img src={file?.beforeImage?.previewUrl} alt='' onClick={() => {
                                            setPreviewImage(
                                                {
                                                    photoType: "beforeImage",
                                                    photoIndex: index,
                                                    photoUrl: file?.beforeImage?.previewUrl,
                                                    photoId: file?.beforeImage?.photoId
                                                }
                                            )
                                            setIsPreview(true)
                                        }} />
                                    </div>
                                </div> :
                                <Dropzone
                                    onDrop={files => handleFileUpload(files, index, "beforeImage")}
                                    maxFiles={6}
                                    accept="image/*"
                                >
                                    {({ getRootProps, getInputProps, isDragActive }) => (
                                        <div {...getRootProps()} className={`formDropzoneUploading ${isDragActive ? 'formDropzoneUploading-active' : ''}`}>
                                            <input {...getInputProps()} />
                                            <div className='add-more-label-container'>
                                                {/* {file?.beforeImage && typeof file?.beforeImage === 'object' ? <img src={URL.createObjectURL(file?.beforeImage)} alt='' /> : <img src={beforeafterimage} alt='' />} */}
                                                {file?.beforeImage?.previewUrl ? <img src={file?.beforeImage?.previewUrl} alt='' /> : <img src={beforeafterimage} alt='' />}
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                        }
                    </div>
                    <div className='photoGridItem'>

                        {
                            file?.afterImage?.photo ?
                                <div className={`formDropzoneUploading`}>
                                    <div className='add-more-label-container'>
                                        <img src={file?.afterImage?.previewUrl} alt='' onClick={() => {
                                            setPreviewImage(
                                                {
                                                    photoType: "afterImage",
                                                    photoIndex: index,
                                                    photoUrl: file?.afterImage?.previewUrl,
                                                    photoId: file?.afterImage?.photoId
                                                }
                                            )
                                            setIsPreview(true)
                                        }} />
                                    </div>
                                </div> :
                                <Dropzone
                                    onDrop={files => handleFileUpload(files, index, "afterImage")}
                                    maxFiles={6}
                                    accept="image/*"
                                >
                                    {({ getRootProps, getInputProps, isDragActive }) => (
                                        <div {...getRootProps()} className={`formDropzoneUploading ${isDragActive ? 'formDropzoneUploading-active' : ''}`}>
                                            <input {...getInputProps()} />
                                            <div className='add-more-label-container'>
                                                {/* {file?.afterImage && typeof file?.afterImage === 'object' ? <img src={URL.createObjectURL(file?.afterImage?.image)} alt='' /> : <img src={beforeafterimage} alt='' />} */}
                                                {file?.afterImage?.previewUrl ? <img src={file?.afterImage?.previewUrl} alt='' /> : <img src={beforeafterimage} alt='' />}
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                        }
                    </div>
                </div>
            </>
        ));
    };

    const goBackClickHandler = () => {
        // navigate(appointmentOverviewBeforeAndAfterPhotosRoute(), { state: { fromCalendar: location?.state?.fromCalendar, AppointmentDate: location?.state?.appointmentId } })
        setStep(1)
    }


    const converToApiFormat = (obj) => {

        let resultArr = []
        let count = 1
        for (let item of obj) {
            if (item.beforeImage.photo !== '' && !item.beforeImage.enableDeleteApiCall)
                resultArr.push({ ...item.beforeImage, pairNumber: count, photo: item.beforeImage.enableDeleteApiCall ? item.beforeImage.photo : item.beforeImage.photo[0] })

            if (item.afterImage.photo !== '' && !item.afterImage.enableDeleteApiCall)
                resultArr.push({ ...item.afterImage, pairNumber: count, photo: item.afterImage.enableDeleteApiCall ? item.afterImage.photo : item.afterImage.photo[0] })
            count += 1
        }

        return resultArr
    }

    const onClickHandler = () => {

        const data = converToApiFormat(uploadedFiles)
        if (data?.length >= 1) {
            let formattedData = data.map((item) => {
                return {
                    photoType: item.photoType,
                    photoId: item.photoId,
                    pairNumber: item.pairNumber,
                    photo: item.photo
                }
            })
            initBeforeAndAfterPhotosData({
                consentFormRecordId: parseInt(consentFormRecordId),
                treatmentId: selectedTreatmentId ? selectedTreatmentId : 0,
                photos: formattedData
            }, (res) => {
                if (res.success) {
                    dispatch({
                        type: CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT,
                        payload: null
                    })
                    initGetTreatmentwiseBeforeAfterPhotosCountData({ consentFormRecordId: parseInt(consentFormRecordId), type: 1 })
                    showToast("success", res.message, false, "")
                    setStep(1);

                } else {

                }
            }, (err) => {
                console.log("error on create note", err)
            })
        }
        else {
            setStep(1)
        }
    }

    const deleteApiCall = () => {
        initRemoveBeforeAfterPhotoData({
            photoId: previewImage.photoId,
        }, (res) => {
            if (res.success) {
                dispatch({
                    type: CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT,
                    payload: null
                })
                initGetTreatmentwiseBeforeAfterPhotosCountData({ consentFormRecordId: parseInt(consentFormRecordId), type: 1 })
                showToast("success", res?.message, "", false)
            } else {
                console.log("res", res.message)
            }
        }, (err) => {

        })
    }

    const deleteImageHandler = () => {
        let resultArr = [];
        let tempObj = uploadedFiles[previewImage?.photoIndex];
        let subObj = { ...tempObj, [`${previewImage?.photoType}`]: { ...uploadedFiles[previewImage?.photoIndex][previewImage?.photoType], photo: "" } }

        if (uploadedFiles.length > 1) {
            for (let i = 0; i < uploadedFiles.length; i++) {
                if (i == previewImage.photoIndex) {

                    if (subObj[previewImage?.photoType].enableDeleteApiCall) {
                        deleteApiCall();
                        subObj[previewImage?.photoType].enableDeleteApiCall = false;

                    }
                    subObj[previewImage?.photoType].previewUrl = "";

                    if (subObj.afterImage.photo || subObj.beforeImage.photo) {
                        resultArr.push(subObj)
                    }
                    else {
                        continue;
                    }

                }
                else {
                    resultArr.push(uploadedFiles[i])
                }
            }
            setUploadedFiles(resultArr)
        }
        else {

            if (subObj[previewImage?.photoType].enableDeleteApiCall) {
                deleteApiCall();
                subObj[previewImage?.photoType].enableDeleteApiCall = false;

            }
            subObj[previewImage?.photoType].previewUrl = "";
            setUploadedFiles([subObj])
        }
        setIsDeleteOpen(false)
        setPreviewImage(null)
        setIsPreview(false)
    }
    return (

        <div className='add-photo-container'>
            <NavHeader isBackOnly={true} applyPadding={false}
                handleOnBack={() => goBackClickHandler()}
            />
            {getBeforeAfterPhotosQueryData?.loading ? <SkeletonLoader type="before-after-add-photos" /> :
                <div>
                    {isOtherPhotoUpload ? <h1>Other photos</h1> : <h1>{selectedTreatment}</h1>}
                    {isOtherPhotoUpload ? <p> Add other photos, you would like to attach to this booking. </p> : <p>Add before and after photos for clients to view the results. </p>}

                    {/* <div className='caption-container'>
                <label>Add a caption</label>
                <div className='add-caption-box'>
                    <textarea placeholder='Inform your clients about the service benefits.' />

                    <div className='smart_ai_text_gen'>
                        <span className='smart-text'>
                            <img src={smartImg} width={25} height={25} alt='img' />
                            <p>Smart text</p>
                        </span>
                    </div>
                </div>

            </div> */}
                    <div className='photoGridWrapper'>
                        {renderPhotos()}
                    </div>
                    {uploadedFiles?.length < 6 && <div className='add-more-photo-button'>
                        <StepButton blue={true} label={"Add more photos"} onClick={() => {
                            addPhotos()
                        }} />
                    </div>}

                    <StepButton className="mt-5" blue={true} label={"Done"}
                        // disabled={uploadedFiles?.length >= 1 ? false : true}
                        isLoading={beforeAfterQueryData?.loading}
                        onClick={() => {
                            // setStep(1);
                            onClickHandler();
                        }
                        } />
                </div>
            }
            {/* For image preview Modal */}
            <CustomModal
                type={"common-width"}
                modalBody={<div className="preview-before-after-photos-document-modal">
                    {/* <h1>{singleFile?.documentName?.slice(-25)}</h1> */}
                    <img src={previewImage?.photoUrl} alt="preview" style={{ objectFit: "contain" }} />
                    <div className="delete-photos-preview-button">  <StepButton label={"Delete"} redWithBorder={true} onClick={() => {
                        setIsDeleteOpen(true);
                    }} /></div>
                </div>}
                setModalOpen={setIsPreview}
                modalOpen={isPreview}
                setIsOpenMobileCalendar={setIsPreview}
                className={"previewImgModel"}

            />

            <CustomModal
                type={"common-width"}
                modalBody={
                    <>
                        <div className="delete-modal-body mt-2">
                            <h1>Are you sure?</h1>
                            <p>
                                Are you sure you want to delete this image?
                            </p>
                            <div className="w-100">
                                <div className="delete-button-modal">
                                    <StepButton red={true} label={"Delete"} onClick={() => {
                                        deleteImageHandler();
                                    }} />
                                </div>
                                <div className="cancle-button-modal">
                                    <StepButton gray={true} onClick={() => {
                                        setIsDeleteOpen(false)
                                    }} label={"Cancel"} />
                                </div>
                            </div>
                        </div>
                    </>
                }
                modalOpen={isDeleteOpen}
                setModalOpen={setIsDeleteOpen}
                setIsOpenMobileCalendar={setIsDeleteOpen}
                className={"ConfirmationDeleteModal"}
            />
        </div>
    )
}

export default BeforeAfterAddPhotos