import React, { useEffect, useState } from "react";
import BusinessSidebar from "../../sidebar/BusinessSidebar";
import BusinessSettingHeader from "../../common/businessSettingHeader/BusinessSettingHeader";
import ManageAccount from "../../businessSetting/manageAccount/ManageAccount";
import "./BusinessProfileSetting.scss";
import BusinessSetting from "../../businessSetting/BusinessSetting";
import Security from "./SecurityandPrivacy/Security";
import Home from "./Home";
import Marketing from "./Marketing/Marketing";
import { useLocation } from "react-router";
import Clients from "./Clients/Clients";
import Locations from "./Locations/Locations";
import { useNavigate } from "react-router";
import Form from "./SecurityandPrivacy/Form/Form";
import BusinessSupport from "./BusinessSupport/BusinessSupport";
import PaymentsControl from "./PaymentsControl/PaymentsControl";
import ComingSoon from "../../comingSoon/ComingSoon";
import { getBusinessMarketingPage } from "../../../Routes";
import BusinessFooter from "../../businessFooter";



const BusinessProfileSetting = () => {
  const [currentPage, setCurrentPage] = useState("Home");
  const location = useLocation()
  const navigate = useNavigate();


  // Function to handle changing the current page
  const changePage = (page) => {

    setCurrentPage(page);
  };

  console.log("currentPage", currentPage);
  // Function to render the current page component
  const renderPage = () => {
    switch (currentPage) {
      case "settings":
        return (
          <BusinessSetting
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        );

      case "support":
        return (
          <BusinessSupport
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        );

      case "Manage account":
        return (
          <ManageAccount
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        );

      case "Locations":
        return "Access In Sprint 4";

      case "Your services":
        return "Access In Sprint 4";

      case "Team members":
        return "Access In Sprint 4";


      case "Ryft account":
        return <ComingSoon setCurrentPage={setCurrentPage} />;

      case "Collect online payments":
        return <ComingSoon setCurrentPage={setCurrentPage} />;

      case "Transaction history":
        return <ComingSoon setCurrentPage={setCurrentPage} />;

      case "Payments control":
        return <PaymentsControl setCurrentPage={setCurrentPage} currentPage={currentPage} />;

      case "Forms":
        return (
          <Form setCurrentPage={setCurrentPage} currentPage={currentPage} />
        );

      case "Security":
        return (
          <Security setCurrentPage={setCurrentPage} currentPage={currentPage} />
        );

      case "Home":
        return (
          <Home setCurrentPage={setCurrentPage} currentPage={currentPage} />
        );

      case "Clients":
        return (
          <Clients setCurrentPage={setCurrentPage} currentPage={currentPage} />
        );

      // case "Marketing":
      //   return (
      //     <Marketing
      //       setCurrentPage={setCurrentPage}
      //       currentPage={currentPage}
      //     />
      //   );getBusinessMarketingPage
      case "Marketing":
        return (
          navigate(getBusinessMarketingPage())
        );
      default:
        return null;
    }
  };

  useEffect(() => {

    if (location?.state?.from === "Payments control") {

      setCurrentPage("Payments control");
    }
  }, [location])

  console.log('currentPage', location)

  return (
    <div className="MainBusinessProfile">
      <BusinessSettingHeader
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      <div className="Business-Profile-Main-Wrapper">
        <BusinessSidebar
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
        {renderPage()}

      </div>
      <BusinessFooter />
    </div>
  );
};

export default BusinessProfileSetting;
