import { gql, useMutation } from "@apollo/client";

// Define the GraphQL mutation
const VERIFY_AND_UPDATE_PASSWORD_MUTATION = gql`
  mutation VerifyAndUpdateCurrentPassword(
    $oldPassword: String, 
    $newPassword: String, 
    $confirmPassword: String
  ) {
    verifyAndUpdateCurrentPassword(
      oldPassword: $oldPassword, 
      newPassword: $newPassword, 
      confirmPassword: $confirmPassword
    ) {
      success
      message
    }
  }
`;

export const useVerifyAndUpdatePassword = () => {
  const [
    verifyAndUpdatePasswordFunction,
    verifyAndUpdatePasswordResponseData,
  ] = useMutation(VERIFY_AND_UPDATE_PASSWORD_MUTATION, {
    onCompleted: ({ verifyAndUpdateCurrentPassword }) => {
      if (verifyAndUpdateCurrentPassword.success) {
        // Handle success case, if needed
      } else {
        // Handle failure case, if needed
      }
    },
  });

  const initVerifyAndUpdatePassword = (data, successCallback, errorCallback) => {
    verifyAndUpdatePasswordFunction({
      variables: data,
    })
      .then((res) => {
        if (res?.data?.verifyAndUpdateCurrentPassword?.success) {
          successCallback(res);
        } else {
          errorCallback(res);
        }
      })
      .catch((err) => {
        errorCallback(err);
      });
  };

  return { verifyAndUpdatePasswordResponseData, initVerifyAndUpdatePassword };
};
