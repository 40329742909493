import { gql, useMutation } from "@apollo/client";
import { da } from "date-fns/locale";

const DELETE_FINANCE_TRANNING_CERTIFICATE=gql`
mutation DeleteTrainingCertificateForFinance($usrVrfId: Int) {
    deleteTrainingCertificateForFinance(usr_vrf_id: $usrVrfId) {
      success
      message
    }
  }
`;

export const DeleteFinanceTranningCertificateAction=()=>{
    const [deleteFinanceTranningCertificateResponseFunction,
    deleteFinanceTranningCertificateResponseData]=useMutation(DELETE_FINANCE_TRANNING_CERTIFICATE);

    const deleteFinanceTranningCertificateQueryData=deleteFinanceTranningCertificateResponseData;

    const initDeleteFinanceTranningCertificateData=(data, successCallback, errorCallback)=>{
      deleteFinanceTranningCertificateResponseFunction({
        variables:data
      }).then((responseData)=>{
        console.log(responseData,"res")
            if(responseData.data.deleteTrainingCertificateForFinance.success){
                successCallback(responseData)
            }else{
                errorCallback(responseData)
            }
      }).catch((error)=>{
        errorCallback(error)
      })
    }

    return {deleteFinanceTranningCertificateQueryData,initDeleteFinanceTranningCertificateData}
}