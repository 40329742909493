import React from 'react'
import Skeleton from 'react-loading-skeleton'

const CalendarSkeleton = () => {
    return (
        <div className='calendar-skeleton-main-div'>
            <Skeleton width={250} height={40} />
            <div className='w-100 my-4' style={{overflowX:"scroll"}}>
                <table className='table' >
                    <tr className=''>
                        {
                            // style={{ backgroundColor: "#eeeff3" }}
                            [...Array(7)].map(i => <td className=' text-center py-4 ' >
                                <div style={{ backgroundColor: "#f9f9f9" }}>
                                    <Skeleton height={20} width={50} />
                                </div>
                            </td>)
                        }
                    </tr>
                    <tr className=''>
                        {
                            [...Array(7)].map(i =>
                                <td className=' text-center py-4' >
                                    <div style={{ backgroundColor: "#f9f9f9" }}>

                                        <Skeleton height={20} width={50} />
                                        <Skeleton height={20} width={"80%"} />
                                        <Skeleton height={20} width={"80%"} />
                                        <Skeleton height={20} width={"80%"} />
                                    </div>
                                </td>)
                        }
                    </tr>
                    <tr className=''>
                        {
                            [...Array(7)].map(i =>

                                <td className=' text-center py-4' >
                                    <div style={{ backgroundColor: "#f9f9f9" }}>
                                        <Skeleton height={20} width={50} />
                                        <Skeleton height={20} width={"80%"} />
                                        <Skeleton height={20} width={"80%"} />
                                        <Skeleton height={20} width={"80%"} />
                                    </div>
                                </td>)
                        }
                    </tr>


                </table>
            </div>
        </div>
    )
}

export default CalendarSkeleton