import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_PRODUCT_ANSWER=gql`
query GetProductAnswer($questionId: Int, $productId: Int, $slug: String, $sessionId: String) {
  getProductAnswer(questionId: $questionId, productId: $productId, slug: $slug, sessionId: $sessionId) {
    success
    message
    data {
      answer
      doc_url
      backUrl
    }
  }
}
`;

export const GetProductAnswerAction=()=>{
    const [getProductAnswerResponseFunction,
    getProductAnswerResponseData]=useLazyQuery(GET_PRODUCT_ANSWER,{
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted:({getProductAnswer:responseData})=>{
            if(responseData.success){
             }else{

             }
        }
    })

    const getProductAnswerQueryData=getProductAnswerResponseData;

    const initGetProductAnswerData=(data)=>{
            getProductAnswerResponseFunction({
                variables:data
            })
    }
    return {getProductAnswerQueryData,initGetProductAnswerData}
}