import { CLEAR_CONSENT_FORM_PRODUCT_LIST, GET_CONSENT_FORM_PRODUCT_LIST } from "../../constants/consentFormThankyou/consentFormProductConst"

const initialState = {
    productList: [],
    emptyProductList: false,
    totalProductCount: 0,
    loadedProductCount: 0,
    productListPage: 0,
    isReviewPopup:null,
    prescriberDetails:null,
}

export const consentFormProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONSENT_FORM_PRODUCT_LIST:
            let temp = (state?.productList).concat(action?.payload?.items)
            console.log("data akash product payload", temp)
            return {
                ...state,
                productList: temp,
                totalProductCount: action.payload?.total,
                productListPage: state.productListPage + 1,
                loadedProductCount: temp.length,
                emptyProductList:action?.payload?.items?.length === 0 || action.payload == null ? true : false,
                isReviewPopup:action?.payload?.is_already_reviewed,
                prescriberDetails:action?.payload?.prescriber_details
            }
        case CLEAR_CONSENT_FORM_PRODUCT_LIST:
            return {
                ...state,
                productList: [],
                emptyProductList: false,
                totalProductCount:0,
                productListPage:0,
                loadedProductCount:0,
                isReviewPopup:null,
                prescriberDetails:null
            }
        default:
            return state
    }
}