import React, { useEffect, useRef, useState } from 'react'
import SubHeading from '../hair-product-common/hair-product-sub-heading/SubHeading'
import StepButton from '../../../common/StepButton'
import HairProductWhatsAppButton from '../hair-product-common/hair-product-whatsApp-btn/HairProductWhatsAppButton'
import CustomCheckbox from '../../../custom-components/custom-checkbox/CustomCheckbox'
import './HairProductsFour.scss'

const HairProdutsFour = ({ OnhandleContinue, checkboxDataMain, checkedItemsMain, gender }) => {

    const [isChecked, setIsChecked] = useState(checkedItemsMain);
    const [validationError, setValidationError] = useState("");
    const [scrollerror , setScrollerror] = useState(true)
    const errorRef = useRef(null);

    useEffect(() => {
        if (validationError && errorRef.current) {
            errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [validationError, scrollerror]);


    // Function to handle checkbox toggle
    const handleCheckboxToggle = () => {
        setIsChecked(!isChecked);
        setValidationError("");
    };

    // Validate if the last checkbox is selected
    const validateInputs = () => {
        if (!isChecked) {
            setValidationError("Please accept the terms and conditions.");
            return false;
        } else {
            setValidationError("");
        }
        return true;
    };


    const handleContinue = () => {
        setScrollerror(!scrollerror)
        const isValid = validateInputs();

        if (isValid) {
            OnhandleContinue(isChecked);
        }
    };

    useEffect(()=>{
        window.scrollTo(0, 0);
     },[])

    return (
        <>
            <div className='Main-CheckBox-Wrapper MainTermsCondition'>
                {/* <SubHeading title="Please read the disclaimer and confim" /> */}
                {/* <div className='term-main-heding'>Please read the disclaimer and confim</div> */}

                <div class="accordion term-condition-accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                <span className='accordion-term-header'>Disclaimer</span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            {gender == "male" ?
                                <div class="accordion-body">
                                    <div>
                                        Minoxidil and Finasteride are medications prescribed for the treatment of hair thinning and hair loss. Minoxidil can be prescribed for both males and females. Finasteride is generally prescribed for males. They are most commonly used to treat symptoms of:
                                        <ul>
                                            <li>Hair thinning</li>
                                            <li>Hair loss</li>
                                            <li>Androgenetic Alopecia (Male pattern hair loss) (Female pattern hair loss)</li>
                                        </ul>
                                    </div>
                                    <div>
                                        The mechanism by which minoxidil stimulates hair growth is not fully understood, but minoxidil can help improve hair growth by the following means:
                                        <ul>
                                            <li>Increases blood flow around follicles.</li>
                                            <li>Stimulates follicle movement to growth (anagen) phase.</li>
                                            <li>Extends each follicle growth (anagen) phase.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        Finasteride is a specific and competitive inhibitor of 5-alpha-reductase enzyme (type 2). It inhibits the conversion of testosterone to dihydrotestosterone (DHT). DHT is a primary androgen in prostate and hair follicles, therefore it is effective at treating male pattern hair loss.
                                    </div>
                                    <div>
                                        Minoxidil and Finasteride can be produced by compounding pharmacies. This means that the pharmacy is able to prepare custom doses and application methods to address the individual needs of a patient.
                                    </div>
                                    <div>
                                        How long does it take for Minoxidil and Finasteride to start working?
                                        <p>
                                            The amount of time it takes for Minoxidil and Finasteride to work varies for each person. Most of the time it takes around 3-4 months before you begin to see the effects of the medication and 6-9 months for optimal results.
                                        </p>
                                    </div>
                                    <div>
                                        Potential risks, side effects and adverse effects for Minoxidil:
                                        <p>
                                            Risk of reaction / allergic reaction, suboptimal response from medication, irritation, allergic contact dermatitis, itching or skin rash, acne at site of application, burning of scalp, hypertrichosis, facial hair growth, increased hair loss, inflammation or soreness at root of hair, erythema, swelling of face, change of hair texture, no improvement in hair growth, increased shedding of hair during first few weeks of starting treatment.
                                        </p>
                                    </div>
                                    <div>
                                        Signs and symptoms of too much medicine being absorbed into the body:
                                        <p>
                                            Blurred vision / changes in vision, chest pain, dizziness, faintness, lightheadedness, hypotension, fast or irregular heartbeat, flushing, headache, numbness or tingling of hands, feet, or face, swelling of face, hands, feet, or lower legs, weight gain (rapid).
                                        </p>
                                    </div>
                                    <div>
                                        Potential risks, side effects and adverse effects for Finasteride:
                                        <div>
                                            Less common:
                                            <p>
                                                Bloating or swelling of the face, arms, hands, lower legs, or feet, breast enlargement and tenderness, hives or welts, itching, skin rash, rapid weight gain, redness of the skin, swelling of the lips and face, tingling of the hands or feet, unusual weight gain or loss, runny or stuffy nose, sleepiness or drowsiness, sneezing, back pain, decreased amount of semen, diarrhoea, dizziness, headache, stomach pain, risk of reaction, suboptimal response from medication.
                                            </p>
                                        </div>

                                        <div>
                                            More common:
                                            <p>
                                                Scalp irritation, itching, swelling, erythema, dryness, decreased libido, erectile dysfunction, loss in sexual ability, desire, drive, or performance, chills, cold sweats, confusion, dizziness, faintness, or lightheadedness when getting up suddenly from a lying or sitting position.
                                            </p>
                                        </div>

                                        <div>
                                            Incidence not known:
                                            <p>
                                                Clear or bloody discharge from the nipple, dimpling of the breast skin, inverted nipple, lump in the breast or under the arm, persistent crusting or scaling of the nipple, redness or swelling of the breast (gynecomastia), sore on the skin of the breast that does not heal, testicular pain, breast cancer, high-grade prostate cancer.
                                            </p>
                                        </div>

                                    </div>

                                    <ul>
                                        <li>I understand that no treatment is an option.</li>
                                        <li>I understand that there is no guarantee on the effectiveness of the treatment Minoxidil or Finasteride.</li>
                                        <li>I understand that if I do not use the medication in the frequency as prescribed, or if I stop using the medication, the hair thinning and hair loss is likely to continue.</li>
                                    </ul>

                                    <p className='term-warning'>WARNING: I understand that Finasteride and/or Minoxidil can cause birth defects and therefore understand the importance of; using contraception, stopping taking the medication Finasteride and/or Minoxidil at least three months before trying to conceive and ensuring a person that is pregnant, breastfeeding, trying to conceive, undergoing IVF and children do NOT touch the medication Finasteride and/or Minoxidil. If they do come into contact with Finasteride and/or Minoxidil, the affected area should be washed immediately with soap and water.</p>

                                    <p className='term-warning'>WARNING: Giving inaccurate information on a clinical consultation, could put your health at risk. </p>

                                    <div>All fees are non-refundable.</div>
                                </div>
                                :
                                <div class="accordion-body">
                                    <div>
                                        Minoxidil is a medications prescribed for the treatment of hair thinning and hair loss. Minoxidil can be prescribed for both males and females. Minoxidil is most commonly used to treat symptoms of:
                                        <ul>
                                            <li>Hair thinning</li>
                                            <li>Hair loss</li>
                                            <li>Androgenetic Alopecia (Male pattern hair loss) (Female pattern hair loss)</li>
                                        </ul>
                                    </div>
                                    <div>
                                        The mechanism by which minoxidil stimulates hair growth is not fully understood, but minoxidil can help improve hair growth by the following means:
                                        <ul>
                                            <li>Increases blood flow around follicles.</li>
                                            <li>Stimulates follicle movement to growth (anagen) phase.</li>
                                            <li>Extends each follicle growth (anagen) phase.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        Minoxidil can be produced by compounding pharmacies. This means that the pharmacy is able to prepare custom doses and application methods to address the individual needs of a patient.
                                    </div>
                                    <div>
                                        How long does it take for Minoxidil to start working?
                                        <p>
                                            The amount of time it takes for Minoxidil to work varies for each person. Most of the time it takes around 3-4 months before you begin to see the effects of the medication and 6-9 months for optimal results.
                                        </p>
                                    </div>
                                    <div>
                                        Potential risks, side effects and adverse effects for Minoxidil:
                                        <p>
                                            Risk of reaction / allergic reaction, suboptimal response from medication, irritation, allergic contact dermatitis, itching or skin rash, acne at site of application, burning of scalp, hypertrichosis, facial hair growth, increased hair loss, inflammation or soreness at root of hair, erythema, swelling of face, change of hair texture, no improvement in hair growth, increased shedding of hair during first few weeks of starting treatment.
                                        </p>
                                    </div>
                                    <div>
                                        Increased shedding of hair when starting minoxidil treatment. In most cases, shedding will last around 8 weeks. (This is a normal, temporary phase and a sign that the treatment is working).
                                    </div>
                                    <div>
                                        Signs and symptoms of too much Minoxidil being absorbed into the body:
                                        <p>
                                            Blurred vision / changes in vision, chest pain, dizziness, faintness, lightheadedness, hypotension, fast or irregular heartbeat, flushing, headache, numbness or tingling of hands, feet, or face, swelling of face, hands, feet, or lower legs, weight gain (rapid).
                                        </p>
                                    </div>

                                    <ul>
                                        <li>I understand that no treatment is an option.</li>
                                        <li>I understand that there is no guarantee on the effectiveness of the treatment Minoxidil or Finasteride.</li>
                                        <li>I understand that if I do not use the medication in the frequency as prescribed, or if I stop using the medication, the hair thinning and hair loss is likely to continue.</li>
                                        <li>I understand that I must stop taking the medication Minoxidil at least three months before trying to conceive.</li>
                                        <li>I understand that individuals who are pregnant, breastfeeding, trying to concieve, undergoing IVF and children must not touch the medication.</li>
                                        <li>I understand I must contact my healthcare professional if I experience any mental health issues (including but not limited to anxiety, depression or suicidal thoughts).</li>
                                    </ul>

                                    <p className='term-warning'>WARNING: I understand that Minoxidil can cause birth defects and therefore understand the importance of; using contraception, stopping taking the medication Minoxidil at least three months before trying to conceive and ensuring a person that is pregnant, breastfeeding, trying to conceive, undergoing IVF and children do NOT touch the medication Minoxidil. If they do come into contact with this medicine, the affected area should be washed immediately with soap and water.</p>

                                    <p className='term-warning'>WARNING: Giving inaccurate information on a clinical consultation, could put your health at risk.</p>

                                    <div>All fees are non-refundable.</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="flex-gap-normal">
                    <div className='errowWrapper'>
                        <div className='hair-product-checkbox-main'>
                            {checkboxDataMain.slice(0, -1).map((statement, index) => (
                                // <div key={index} className="hair-product-checkbox-div-upper">
                                <div className='d-flex'>
                                    <p className='mb-0'>-</p>
                                    <p className='checkbox-title-hair-product mb-0'>{statement}</p>
                                </div>
                                // </div>
                            ))}
                            <div className="hair-product-checkbox-div cursor-pointer" onClick={handleCheckboxToggle}>
                                <CustomCheckbox
                                    className="hair-product-checkbox"
                                    id="lastCheckbox"
                                    name="lastCheckbox"
                                    checked={isChecked}
                                />
                                <p className='checkbox-title-hair-product-last'>{checkboxDataMain[checkboxDataMain.length - 1]}</p>
                            </div>
                           {validationError && <p ref={errorRef} className="hair-product-error-message">{validationError}</p>}
                        </div>
                        
                    </div>
                    <div className='bottomStickeyBtn'>
                        <div className="continue-btn-div">
                            <StepButton label={"Continue"} blue={true} onClick={handleContinue} />
                        </div>
                        <div className="whatsapp-margin">
                            <HairProductWhatsAppButton />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default HairProdutsFour