import React from 'react'
import './appointment-toast.scss'
import { ReactComponent as RightArrow } from '../../images/icons/toast_left_arrow.svg'
import { ReactComponent as ErroIcon } from '../../images/icons/toast/Error.svg'
import { ReactComponent as SuccessIcon } from '../../images/icons/toast/Success.svg'
import { ReactComponent as WarningIcon } from '../../images/icons/toast/Warning.svg'
import Avatar from 'react-avatar'

const AppointmentToast = ({ onCallBack, type = "", toastMsg = null, toastDesc = null, isClickable = false, initialData }) => {
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#EEEFF3",
        color: "#000",
        width: "48px",
        height: "48px",
        fonts: "Inter",
        fontSize: "12px"
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }
  return (
    <div className='appointment-toast-container' onClick={onCallBack}>
      {console.log("selected inital", initialData)}
      <div className='appointment-toast-content'>
        <div className={`appointment-toast-left-content`}>
          {
            type === 'error' ? <ErroIcon /> : type === 'success' ? <SuccessIcon /> : type === "warning" ? <WarningIcon /> : 
            initialData?.clientProfileImage ? <> <img src={initialData?.clientProfileImage} className='bookingTostImg' /> </> : <Avatar fgColor="#000" className="avtar-overview-profile" maxInitials={2} color="#EEEFF3" name={`${initialData?.clientName}`} src={initialData} size="61" round={true} />
            
          }
          <div className={isClickable ? `appointment-toast-text` : `appointment-toast-text-without-desc`} >
            <h1>{toastMsg !== null ? toastMsg : ""}</h1>
            <p>{toastDesc !== null ? toastDesc : ""}</p>
          </div>
        </div>
        <div className='appointment-toast-right-content'>
          {
            isClickable ? <RightArrow /> : null
          }
        </div>
      </div>
    </div>
  )
}

export default AppointmentToast