// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_TESTIMONIALS_QUERY = gql`
query GetTestimonialList {
  getTestimonialList {
    success
    message
    data {
      testimonial {
        id
        name
        field
        description
        image_url
        shortname
        rating
      }
    }
  }
}
`;

export const GetTestimonialListAction = () => {
  // const dispatch = useDispatch();
  const [
    getTestimonialListResponseFunction,
    getTestimonialListResponseData,
  ] = useLazyQuery(GET_TESTIMONIALS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getTestimonialList: responseData }) => {
      if (responseData.success) {
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getTestimonialListQueryData = getTestimonialListResponseData; //variable

  const initGetTestimonialListData = (data) => {
    getTestimonialListResponseFunction({
      variables: data,
    });
  };

  return { getTestimonialListQueryData, initGetTestimonialListData };
};
