import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const  GET_FINANCE_BUSINESS_DETAILS=gql`
query GetFinanceBusinessDetailsOfUser {
    getFinanceBusinessDetailsOfUser {
      success
      message
      data {
        business_owner_name
        company_registration_number
        business_trading_name
        business_status
        occupation
        healthcare_pin
        house_no
        street_name
        city
        postcode
      }
    }
  }
`;

export const GetFinanceBusinessDetailAction=()=>{
    const [getFinanceBusinessDetailResponseFunction,
    getFinanceBusinessDetailResponseData]=useLazyQuery(GET_FINANCE_BUSINESS_DETAILS,{
        fetchPolicy: "cache-and-network",
     nextFetchPolicy: "cache-and-network",
     onCompleted:({getFinanceBusinessDetailsOfUser:responseData})=>{
        if(responseData.success){
            console.log("res",responseData?.message)
        }else{
            console.log("error",responseData?.message)
        }
     }
    })

    const getFinanceBusinessDetailsQueryData=getFinanceBusinessDetailResponseData;

    const initGetFinanceBusinessDetailData=(data,successCallBack,errorCallBack)=>{
            getFinanceBusinessDetailResponseFunction({
                variables:data
            }).then((res)=>{
              successCallBack(res?.data?.getFinanceBusinessDetailsOfUser              )
          }).catch((err)=>{
              errorCallBack(err)
          })
    };

    return {getFinanceBusinessDetailsQueryData,initGetFinanceBusinessDetailData}
}