import { CLEAR_PRESCRIPTIONS_LIST, GET_PRESCRIPTIONS_LIST } from "../constants/getPrescriptionsListConst";

const initialState = {
    prescriptionsList: [],
    totalPrescriptionsCount: 0,
    loadedPrescriptionsCount: 0,
    prescriptionsListPage: 0,
}

export default function prescriptionsListReducer(state = initialState, action) {

    switch (action.type) {

        case GET_PRESCRIPTIONS_LIST:
            let temp = action?.payload?.prescriptions ? (state?.prescriptionsList).concat(action?.payload?.prescriptions?.map(prescription => ({
                ...prescription,
                viewMore: false
            }))) : []
            return {
                ...state,
                prescriptionsList: temp,
                totalPrescriptionsCount: action.payload?.totalCount,
                prescriptionsListPage: state.prescriptionsListPage + 1,
                loadedPrescriptionsCount: temp.length
            };

        case CLEAR_PRESCRIPTIONS_LIST:
            return {
                ...state,
                prescriptionsList: [],
                totalPrescriptionsCount: 0,
                loadedPrescriptionsCount: 0,
                prescriptionsListPage: 0,
            };

        default:
            return state;
    }

}