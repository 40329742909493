import { 
    GET_PRODUCT_GET_CART_COUNT_DATA_SUCCESS 
} from "../../constants"

const initialState = {
    dashboardGetCartCountData: null,
    dashboardGetCartCountMessage: null,
}
export const dashboardGetCartCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_GET_CART_COUNT_DATA_SUCCESS:
            return {
                ...state,
                dashboardgetCartCountData: action.payload.data.count,
            }
        default:
            return state
    }
}  