import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_APPOINTMENT_NOTE, GET_CLIENT_NOTES, GET_CLIENT_STATS } from "../../constants";

const GET_APPOINTMENT_NOTES_QUERY = gql`
query GetAppointmentNote($appointmentId: Int) {
  getAppointmentNote(appointmentId: $appointmentId) {
    success
    message
    data {
      appointmentId
      appointmentNote
    }
  }
}
`;

export const GetAppointmentNotes = () => {
    const dispatch = useDispatch();
    const [getAppointmentsData, appointmentNotesData] = useLazyQuery(
        GET_APPOINTMENT_NOTES_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getAppointmentNote: responseData }) => {
                console.log("client note", responseData)
                dispatch({
                    type: GET_APPOINTMENT_NOTE,
                    payload: responseData.data,
                });
            },
        }
    );
    const appointmentNotesQueryData = appointmentNotesData;
    const initGetAppointmentNotes = (data) => {
        getAppointmentsData({
            variables: data,
        });
    };
    return { initGetAppointmentNotes, appointmentNotesQueryData };
};
