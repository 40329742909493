import { gql, useLazyQuery } from "@apollo/client";

const GET_USER_ADDRESS_DETAILS = gql`
query GetUserAddressDetails($addressId: Int) {
  getUserAddressDetails(addressId: $addressId) {
    success
    message
    data {
      addressId
      firstName
      lastName
      houseNo
      streetName
      city
      postcode
      country
      phoneNo
      phoneCountry
      isBillingDefault
      isShippingDefault
    }
  }
}
`;

export const useGetUserAddressDetails = () => {
    const [
        fetchUserAddressDetails,
        { data: userAddressDetailsData, loading, error }
    ] = useLazyQuery(GET_USER_ADDRESS_DETAILS, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: (response) => {
            if (response.getUserAddressDetails.success) {
                // Handle success case
            } else {
                // Handle error case
            }
        },
    });

    const getUserAddressDetailsQueryData = userAddressDetailsData; // Renamed variable

    const initGetUserAddressDetailsData = (variables, successCallback, errorCallback) => {
        fetchUserAddressDetails({ variables })
            .then((res) => {
                successCallback(res?.data?.getUserAddressDetails);
            })
            .catch((err) => {
                errorCallback(err);
            });
    };

    return { getUserAddressDetailsQueryData, initGetUserAddressDetailsData, loading, error };
};
