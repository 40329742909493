import { ReactComponent as InfoIcon } from '../../../../images/info-blue.svg';
import { ReactComponent as CloseIcon } from '../../../../images/close-black.svg';
import { Fragment, useState } from 'react';

function DisclaimerBox({ updateLayout }) {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <Fragment>
            {isOpen && (
                <div id='edit-consent-form-info-box' className="question-info question-box">
                    <div className="question-info__content">
                        <InfoIcon />
                        <p className="question-box__text">
                            Keep your questions clear and concise, ideally requiring simple 'Yes' or
                            'No' responses.
                        </p>
                    </div>
                    <button 
                        onClick={() => {
                            updateLayout()
                            setIsOpen(false)
                        }} 
                        className="question-info__btnClose"
                    >
                        <CloseIcon />
                    </button>
                </div>
            )}
        </Fragment>
    );
}

export default DisclaimerBox;
