import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";

import CheckoutForm from "./CheckoutForm";

import stripePromise from "../../stripe";

import PaymentForm from "./PaymentForm";
import "./stripe.scss"
import ConsentFormCheckoutForm from "./ConsentFormCheckoutForm";

export const StripeComponent = ({
  clientSecret,
  successCallback,
  failureCallback,
  paymentType,
  bookingId,
  setLoading,
  isBalancePayment,
  setProcess,
  setIsOpenPayment,
  stripeDataObj,
  stripeHeaingText,
  isCloseIcon = true,
  stripeChildCustomCSS = "",
  isConsentFrom = false,
  cfrToken,
  noShowPolicy,
  setStep,
  isPayOnTheDay = false,
  selectedOption,
  note,
  
  
}) => {
  useEffect(() => {
    console.log("use effect started");
    let timeoutObj = setTimeout(() => {
      alert("Sorry but you have not completed your process in the predefined time. Please start the payment process again!");
      setIsOpenPayment(false)
    }, 25 * 60 * 1000);
    return () => {
      console.log("use effect return");
      clearTimeout(timeoutObj);
    }
  }, []);

  //===========================*********NOTE:PLEASE UNCOMMENT CLIENTSECRET===========================*********
  if (!clientSecret) {
    return;
  }

  const options = {
    // passing the client secret obtained in step 3
    //===========================*********NOTE:PLEASE UNCOMMENT CLIENTSECRET===========================*********

    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };



  return (
    <Elements stripe={stripePromise} options={options}>
      {paymentType == "card" && (

        isConsentFrom ?
          <ConsentFormCheckoutForm
            clientSecret={clientSecret}
            successCallback={successCallback}
            failureCallback={failureCallback}
            setProcess={setProcess}
            bookingId={bookingId}
            stripeDataObj={stripeDataObj}
            noShowPolicy={noShowPolicy}
            setStep={setStep}
            selectedOption={selectedOption}
            isPayOnTheDay={isPayOnTheDay}
            note={note}
            
        
            
          /> :
          <CheckoutForm
            clientSecret={clientSecret}
            successCallback={successCallback}
            failureCallback={failureCallback}
            setProcess={setProcess}
            bookingId={bookingId}
            setIsOpenPayment={setIsOpenPayment}
            stripeDataObj={stripeDataObj}
            stripeHeaingText={stripeHeaingText}
            isCloseIcon={isCloseIcon}
            stripeChildCustomCSS={stripeChildCustomCSS}
          />

      )}
    </Elements>
  );
};
