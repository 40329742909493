import React from 'react'
import { Rating } from 'react-simple-star-rating'
import ProgressBar from '@ramonak/react-progress-bar'
import Skeleton from 'react-loading-skeleton'

const ReviewModalLeftSideSkeleton = () => {
    return (
        <div className='review-modal-left-side-skeleton-main-div'>
            <div className='review-details'>
                <div className='mb-3'>
                    <h6> My Reviews</h6>
                    <Skeleton width={"100%"} height={18} />
                    <Rating initialValue={0} fillColor="#175ffa" readonly size={16} />
                </div>

                <div className='rating-filter'>
                    <h6>Filter by rating</h6>
                    {
                        // reviewsData?.individual_rating_count.map((item, index) =>
                        [...Array(5)].map((item, index) =>
                            <div className='d-flex gap-2 align-items-center my-2' >
                                <input type='checkbox' name={`star-${item}`} id={item}
                                    checked={false}
                                />
                                <Rating initialValue={item} fillColor="#175ffa" readonly size={18} />

                                <ProgressBar completed={0} bgColor="#175ffa" isLabelVisible={false} width='180px' height='8px' />
                                <span className='rating-text fw-lighter'>0</span>
                            </div>
                        )
                    }
                </div>
                <div className='mt-3'>
                    <Skeleton width={"100%"} height={45} />
                </div>
            </div>
        </div>
    )
}

export default ReviewModalLeftSideSkeleton