import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import dummyImageIcon from "../../../assets/createWebsite/dummy.svg";
import StepButton from "../../common/StepButton";
import uploadIcon from "../../../images/uploadCover.svg";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { FiChevronRight } from "react-icons/fi";
import SkipButtonComp from "../createWbsiteURL/commonComponents/SkipBtn";
import { FiRefreshCcw } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import "aos/dist/aos.css";
import AOS from "aos";
import ImageTitleComponent from "../createWbsiteURL/commonComponents/ImgTitleComp";
import UploadImage from "../createWbsiteURL/commonComponents/UploadImage";
import { UploadPersonalWebsiteImage } from "../../redux/actions/createWebsite/uploadPersonalWebsiteImage";
import { GetWebsiteImageAction } from "../../redux/actions/createWebsite/getPersonalWebsiteImage";
import { useSelector, useDispatch } from "react-redux";
import loadingIcon from "../../../images/loading.gif";
import CustomeLoading from "../../customeSpiner";
import { CLEAR_LOGO_IMAGE } from "../../redux/constants";
import { GetWebsiteDetailsAction } from "../../redux/actions/createWebsite/getWebsiteDetails";

const UploadLogoCover = (props) => {
  const fileInputRef = useRef(null);
  // const [uploadStep, setUploadStep] = useState(1);
  const {
    style,
    isMobile,
    prevWebOnClick,
    stepTwoData,
    stepThreeData,
    count,
    setCount,
    tempUploadSaveData,
    setTempUploadSaveData,
  } = props;

  const dispatch = useDispatch();

  const [logoImage, setLogoImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  const [uploadImageDetails, setUploadImageDetails] = useState({
    imageSize: "",
    fileName: "",
  });

  const [loader, showLoader] = useState(false);
  const { initGetWebsiteDataResponseFunction } = GetWebsiteDetailsAction();

  const websiteLogoImage = useSelector(
    (state) => state.WebsiteDetailsReducer.websiteLogoImage
  );
  const websiteCoverImage = useSelector(
    (state) => state.WebsiteDetailsReducer.websiteCoverImage
  );

  const imageType = useSelector(
    (state) => state.WebsiteDetailsReducer.imageType
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = () => {
        showLoader(true);
        if (props.step === 2) {
          setLogoImage(file);
          props.setWebsiteData({ ...props.websiteData, logo: reader.result });
        } else {
          setCoverImage(file);
          props.setWebsiteData({
            ...props.websiteData,
            coverPhoto: reader.result,
          });
        }
      };

      reader.readAsDataURL(file);
    },
    [props.step]
  );

  const resetDropzone = () => {
    props.setWebsiteData({ ...props.websiteData, coverPhoto: null });
  };

  const handleImageClick = () => {
    // Trigger the file input click when an image is clicked
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setUploadImageDetails({
        imageSize: file.size,
        fileName: file.name,
      });
      const reader = new FileReader();

      reader.onload = () => {
        showLoader(true);
        if (props.step === 2) {
          setLogoImage(file);
          props.setWebsiteData({ ...props.websiteData, logo: reader.result });
        } else {
          setCoverImage(file);
          props.setWebsiteData({
            ...props.websiteData,
            coverPhoto: reader.result,
          });
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
    },
  });

  const handleSkipClick = () => {
    console.log("tempUpload", tempUploadSaveData);
    props.trackActivity({ activityStep: props.step + 2 });
    if (props.step === 2) {
      setCount({
        ...count,
        step2: 0,
      });
      props.setWebsiteData({
        ...props.websiteData,
        logo: tempUploadSaveData.logo,
      });
    } else if (props.step === 3) {
      props.setWebsiteData({
        ...props.websiteData,
        coverPhoto: tempUploadSaveData.coverPhoto,
      });
    }
    props.setStep(props.step + 1);
  };

  const {
    initiateuploadPersonalWebsiteImage,
    uploadPersonalWebsiteImageMutationData: {
      loading: imageUploadLoading,
      called: uploadApiCalled,
    },
  } = UploadPersonalWebsiteImage();

  const {
    initGetImageResponseFunction,
    GetWebsiteImageResponseData: {
      loading: getWebImageLoading,
      called: apiCalled,
    },
  } = GetWebsiteImageAction();

  useEffect(() => {
    if (loader) {
      setTimeout(() => {
        showLoader(false);
      }, 800);
    }
  }, [loader]);

  useEffect(() => {
    if (props.step === 2) {
      initGetImageResponseFunction({ imageType: 1 });
    } else if (props.step === 3) {
      initGetImageResponseFunction({ imageType: 2 });
    }
  }, []);
  useEffect(() => {
    console.log("websiteLogoImage1", websiteLogoImage);
    if (websiteLogoImage && !getWebImageLoading) {
      console.log("websiteLogoImage3", websiteLogoImage);

      props.setWebsiteData({ ...props.websiteData, logo: websiteLogoImage });
    }
  }, [websiteLogoImage, getWebImageLoading]);

  useEffect(() => {
    if (websiteCoverImage && !getWebImageLoading) {
      props.setWebsiteData({
        ...props.websiteData,
        coverImage: websiteCoverImage,
      });
    }
  }, [websiteCoverImage, getWebImageLoading]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-in-out",
    });
    return () => {
      AOS.refresh();
    };
  }, []);

  useEffect(() => {
    if (props.websiteData.logo && props.websiteData.coverPhoto && apiCalled) {
      console.log("ineffect");

      setTempUploadSaveData({
        ...tempUploadSaveData,
        logo: props.websiteData.logo,
        coverPhoto: props.websiteData.coverPhoto,
      });

      // setCount({
      //   ...count,
      //   step2: 1,
      // });
    }
  }, [apiCalled, uploadApiCalled, props.step]);

  return (
    <div className="inputWrapper">
      <div
        className="formInnerHeight"
        style={style}
        data-aos="fade-up"
        data-aos-duration={700}
      >
        {props.step === 2 ? (
          <ImageTitleComponent {...stepTwoData} />
        ) : (
          <ImageTitleComponent {...stepThreeData} />
        )}
        {props.step === 2 ? (
          props.websiteData.logo ||
          websiteLogoImage ||
          !props.getImageLoading ? (
            <div className="uploaded-image-container my-2">
              <div className="uploadedWrapper" onClick={handleImageClick}>
                <>
                  {console.log(
                    "website images check",
                    websiteLogoImage,
                    websiteCoverImage,
                    "DATA"
                  )}
                </>
                {loader ? (
                  <div style={{ marginTop: "80px" }}>
                    <CustomeLoading />
                  </div>
                ) : (
                  <img
                    src={
                      props.websiteData.logo
                        ? props.websiteData.logo
                        : dummyImageIcon
                    }
                    alt="Logo"
                    className="uploaded-image"
                  />
                )}
                <div className="resetLogo">
                  <span className="iconReset">
                    {!loader && <FiRefreshCcw />}
                  </span>
                  {!loader &&
                    (props.websiteData.logo ? (
                      <p>Replace Logo</p>
                    ) : (
                      <p>Upload Logo</p>
                    ))}
                </div>
              </div>
              <div className="imgDetails">
                {logoImage && (
                  <>
                    <div className="imgDetailsWrapper">
                      <span className="imgIcon">
                        <img src={dummyImageIcon} alt="placeholder"></img>
                      </span>
                      <span className="imgName">
                        {uploadImageDetails?.fileName}{" "}
                      </span>
                      <span className="imgSize">
                        •{" "}
                        {uploadImageDetails?.imageSize / 1000000 < 0.1
                          ? 0.09
                          : (uploadImageDetails?.imageSize / 1000000).toFixed(
                              1
                            )}{" "}
                        mb
                      </span>
                    </div>
                    <div className="imgDetailsStatus">
                      {/* {loader && (imageUploadLoading ? <span className="imgStatus">Uploading...</span> : <span className="imgStatusCompleted">Completed</span>)}
                       */}
                      <span
                        className="imgCancel"
                        onClick={() => {
                          setLogoImage(null);
                          props.setWebsiteData({
                            ...props.websiteData,
                            logo: "",
                          });
                        }}
                      >
                        <IoIosClose />
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div
              {...getRootProps()}
              className={`drop-zone ${isDragActive ? "active" : ""}`}
            >
              <input {...getInputProps()} />
              <img src={uploadIcon} alt="" />
              <h2>
                {isDragActive
                  ? "Drop the file here"
                  : "Drag & Drop file or Browse"}
              </h2>
              <h3>Supported format: PNG, JPG, JPEG</h3>
              <h3>(Maximum Upload File Size: 5 MB)</h3>
            </div>
          )
        ) : props.websiteData.coverPhoto ||
          websiteCoverImage ||
          !props.getImageLoading ? (
          <div className="uploaded-image-container my-2">
            {getWebImageLoading ? (
              <CustomeLoading />
            ) : (
              <div onClick={handleImageClick} className="uploadedWrapper">
                <>
                  {console.log(
                    "website images check2",
                    websiteLogoImage,
                    websiteCoverImage,
                    "DATA"
                  )}
                </>
                {loader ? (
                  <div style={{ marginTop: "80px" }}>
                    <CustomeLoading />
                  </div>
                ) : (
                  <img
                    src={
                      props.websiteData.coverPhoto
                        ? props.websiteData.coverPhoto
                        : dummyImageIcon
                    }
                    alt="Cover"
                    className="uploaded-image"
                  />
                )}
                <div className="resetLogo">
                  <span className="iconReset">
                    {!loader && <FiRefreshCcw />}
                  </span>
                  {!loader &&
                    (coverImage ? <p>Replace Logo</p> : <p>Upload Logo</p>)}
                </div>
              </div>
            )}
            <div className="imgDetails">
              {coverImage && (
                <>
                  <div className="imgDetailsWrapper">
                    <span className="imgIcon">
                      <img src={dummyImageIcon} alt="placeholder"></img>
                    </span>
                    <span className="imgName">
                      {uploadImageDetails?.fileName}{" "}
                    </span>
                    <span className="imgSize">
                      •{" "}
                      {uploadImageDetails?.imageSize / 1000000 < 0.1
                        ? 0.09
                        : (uploadImageDetails?.imageSize / 1000000).toFixed(
                            1
                          )}{" "}
                      mb
                    </span>
                  </div>
                  <div className="imgDetailsStatus">
                    {/* {loader && (imageUploadLoading ? <span className="imgStatus">Uploading...</span> : <span className="imgStatusCompleted">Completed</span>)}
                    <span className="imgCancel">
                      <IoIosClose />
                    </span> */}
                    <span
                      className="imgCancel"
                      onClick={() => {
                        setCoverImage(null);
                        props.setWebsiteData({
                          ...props.websiteData,
                          coverPhoto: "",
                        });
                      }}
                    >
                      <IoIosClose />
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div
            {...getRootProps()}
            className={`drop-zone ${isDragActive ? "active" : ""}`}
          >
            <input {...getInputProps()} />
            <img src={uploadIcon} alt="" />
            <h2>
              {isDragActive
                ? "Drop the file here"
                : "Drag & Drop file or Browse"}
            </h2>
            <h3>Supported format: PNG, JPG, JPEG</h3>
            <h3>(Maximum Upload File Size: 5 MB)</h3>
          </div>
        )}
      </div>
      {
        <div className="FormButtonWrapper">
          <StepButton
            isLoading={imageUploadLoading}
            disabled={
              props.step === 2
                ? logoImage || props.websiteData.logo
                  ? false
                  : true
                : coverImage || props.websiteData.coverPhoto
                ? false
                : true
            }
            onClick={() => {
              props.trackActivity({ activityStep: props.step + 2 });
              if (props.step === 2) {
                setCount({
                  ...count,
                  step2: 0,
                });
                dispatch({
                  type: CLEAR_LOGO_IMAGE,
                });
              }
              showLoader(true);
              logoImage || coverImage
                ? initiateuploadPersonalWebsiteImage(
                    {
                      image: props.step === 2 ? logoImage : coverImage,
                      imageType: props.step === 2 ? 1 : 2,
                    },
                    (responseData) => {
                      showLoader(false);
                      // props.trackActivity({ activityStep: props.step + 2 });

                      initGetWebsiteDataResponseFunction();
                      if (props?.editStep) {
                        props?.setStep(0);
                      } else {
                        !props.imageUploadLoading &&
                          props.setStep(props.step + 1);
                      }
                    },
                    (error) => {
                      console.log(error);
                    }
                  )
                : props?.editStep
                ? props?.setStep(0)
                : props.setStep(props.step + 1) && showLoader(false);
            }}
            blue
            label={props?.editStep ? "Update Changes" : "Next"}
            icon={<FiChevronRight className="arrow_next" />}
          />
          {!props?.editStep && (
            <SkipButtonComp
              style={{
                cursor: props.getDetailsLoading ? "not-allowed" : "pointer",
              }}
              onClick={handleSkipClick}
              prevWebOnClick={prevWebOnClick}
              isMobile={isMobile}
            />
          )}
        </div>
      }
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileInputChange}
      />
    </div>
  );
};

export default UploadLogoCover;
