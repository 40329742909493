import {  CLEAR_APPOINTMENT_DETAILS_V3_DATA, GET_APPOINTMENT_DETAILS_V3_DATA } from "../../constants/appointment/appointmentConst";


const initialState = {
  appointmentDetails: []
}

export default function appointmentDetailsV3Reducer(state = initialState, action) {

  switch (action.type) {

    case GET_APPOINTMENT_DETAILS_V3_DATA:
        console.log("reducer v3 akash",action.payload)
      return {
        ...state,
        appointmentDetails: action.payload,
      };

    case CLEAR_APPOINTMENT_DETAILS_V3_DATA:

      return {
        ...state,
        appointmentDetails: []
      }

    default:
      return state;
  }



}