import { GET_REVIEWS_WEB_DATA } from "../../constants/dashboard/Reviews"

const initialState = {
    total_reviews: "0",
    average_rating: "0",
    reviews: []
}
export const GetReviewsListDataReducer = (state = initialState, action) => {
    console.log("GET_REVIEWS_WEB_DATA", action, state)
    switch (action.type) {
        case GET_REVIEWS_WEB_DATA:
            return {
                ...state,
                total_reviews: action.payload.total_reviews,
                average_rating: action.payload.average_rating,
                reviews: action.payload.reviews,
            }
        default:
            return state
    }
}  