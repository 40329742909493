import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const UPDATE_WEBSITE_MUTATION = gql`
mutation CreateOrUpdateWebsite($businessName: String, $websiteUrlSlug: String) {
  createOrUpdateWebsite(business_name: $businessName, website_url_slug: $websiteUrlSlug) {
    success
    message
    data {
      pwc_id
    }
  }
}
`;

export const UpdateWebsite = () => {
  const [updateWebsiteRequest, updateWebsiteResponseData] =
    useMutation(UPDATE_WEBSITE_MUTATION);
  const dispatch = useDispatch();
  const updateWebsiteMutationData = updateWebsiteResponseData;

  const initiateupdateWebsite = (
    data,
    successCallback,
    errorCallback
  ) => {
    updateWebsiteRequest({
      variables: data,
    })
      .then(({ data: { createOrUpdateWebsite: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateupdateWebsite, updateWebsiteMutationData };
};
