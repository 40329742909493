import React, { useState } from 'react'
import './payment-main.scss'
import backArrow from '../../../../images/close_icon.svg'
import cardIcon from '../../../../images/pay_credit.svg'
import rightArrow from '../../../../images/right_arrow.svg'
import CustomModal from '../../../modal/CustomModal'
import ConsentFormCustomModal from '../../../modal/ConsentFormModal/ConsentFormModal'
import StepButton from '../../../common/StepButton'
import SelectPaymentOption from './SelectPaymentOption'

const TakePaymentStep1 = ({ moveToNextStep }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    const openModal = () => {
        scrollToTop();
        setIsModalOpen(true);
    };

    const handleCurrentStep = () => {
        setCurrentStep(currentStep + 1);
    };
    const handleStepTransition = () => {
        setCurrentStep(3);
    };
    return (
        <>
            <div className='container'>
                <div className="payment-header-main">
                    <div></div>
                    <img src={backArrow} style={{ cursor: 'pointer' }} alt="" />
                </div>
                <div className='payment-main'>
                    <h2 className='text-center'>Take payment</h2>
                    <div className='payment-main-content row'>
                        <div className='row'>
                            <div className='card-box'>
                                <p className='fw-bold'>No tip</p>
                                <p>£0.00</p>
                            </div>
                            <div className='card-box'>
                                <p className='fw-bold'>5%</p>
                                <p>£8.00</p>
                            </div>
                            <div className='card-box'>
                                <p className='fw-bold'>10%</p>
                                <p>£16.00</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='card-box'>
                                <p className='fw-bold'>15%</p>
                                <p>£24.00</p>
                            </div>
                            <div className='card-box'>
                                <p className='fw-bold'>20%</p>
                                <p>£32.00</p>
                            </div>
                            <div className='card-box' onClick={openModal}>
                                <p className='fw-bold'>Custom</p>
                                <p>...</p>
                            </div>
                        </div>
                    </div>

                    {/* ==== */}
                    <div className='payment-summary'>
                        <p> Payment summary </p>
                        <hr />
                        <div className='payment-detail'>
                            <div className='d-flex justify-content-between mb-3'>
                                <span>Subtotal</span>
                                <span>£200.00</span>
                            </div>

                            <div className='d-flex justify-content-between mb-3'>
                                <span>Promo code?</span>
                                <span>-£10.00</span>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mb-3'>
                            <span> Tip </span>
                            <span>£40.00</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span className='fw-bold'> Total  </span>
                            <span>£230.00</span>
                        </div>
                        <hr />
                        <div className='d-flex justify-content-between mb-3'>
                            <span className='fw-bold'> Deposit paid  </span>
                            <span>£50.00</span>
                        </div>
                        <div className='d-flex justify-content-between mb-5'>
                            <span className='fw-bold' style={{ color: "#C83A25" }}>
                                Balance due
                            </span>
                            <span>£180.00</span>
                        </div>
                        <p> Payment type </p>
                        <hr />
                        <div className='d-flex justify-content-between mb-3'>
                            <div className='payment-type d-flex'>
                                <img src={cardIcon} alt="" />
                                <span>Cash</span>
                            </div>
                            <div className='mt-2'>
                                <img src={rightArrow} alt="" />
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            {/* //===custom modal...===// */}
            {currentStep === 1 && (
                <ConsentFormCustomModal
                    modalOpen={isModalOpen}
                    setModalOpen={setIsModalOpen}
                    modalBody={
                        <>
                            <div className='modal-content'>
                                <h3>Custom tip</h3>
                                <p>Express gratitude with a custom amount.</p>
                                <div className='modal-input'>
                                    <span>£</span>
                                    <input type="text" placeholder="100.00" />
                                </div>
                            </div>
                            <div>
                                <StepButton label={"Save"} blue={true} onClick={handleCurrentStep} />
                            </div>
                        </>
                    }
                />
            )}

            {currentStep === 2 && (
                <ConsentFormCustomModal
                    modalOpen={isModalOpen}
                    setModalOpen={setIsModalOpen}
                    modalBody={
                        <>
                            <div className='modal-content'>
                                <h3>Select payment</h3>
                                <SelectPaymentOption />
                            </div>
                        </>
                    }
                />
            )}

        </>
    )
}

export default TakePaymentStep1
