import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import beforeafterimage from "../../../../images/Image.svg";
import showToast from '../../../common/CustomToast/CustomToast';
import NavHeader from '../../../common/NavHeader/NavHeader';
import StepButton from '../../../common/StepButton';
import CustomModal from '../../../modal/CustomModal';
import { GetBatchNumbersAction } from '../../../redux/actions/appointments/GetBatchNumbers';
import { RemoveBatchNumberPhotoAction } from '../../../redux/actions/appointments/RemoveBatchNumber';
import { SaveBatchNumbersAction } from '../../../redux/actions/appointments/SaveBatchNumbers';
import "./batch-number-photo.scss";
import { GetTreatmentwiseBeforeAfterPhotosCountAction } from '../../../redux/actions/appointments/GetTreatmentWiseBeforeAfterPhotosCount';
import { CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT } from '../../../redux/constants';
import { useDispatch } from 'react-redux';
import SkeletonLoader from '../../../common/Skeleton/SkeletonLoader';
import Resizer from "react-image-file-resizer";


const BatchNumberPhoto = ({ setStep, isOtherPhotoUpload, selectedTreatment, selectedTreatmentId, step, consentFormRecordId }) => {
    const location = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [uploadedFiles, setUploadedFiles] = useState([
        {
            image: "",
            previewUrl: "",
            enableDeleteApiCall: false
        },
    ])
    const [isPreview, setIsPreview] = useState(false);
    const [previewImage, setPreviewImage] = useState(null)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)


    console.log("upload image", uploadedFiles)

    const { initSaveBatchNumbersData, saveBatchNumberQueryData } = SaveBatchNumbersAction();
    const { initGetBatchNumberData, getBatchNumbersQueryData } = GetBatchNumbersAction();
    const { initRemoveBatchNumberPhotoData, removeBatchNumberPhotoQueryData } = RemoveBatchNumberPhotoAction();
    const { initGetTreatmentwiseBeforeAfterPhotosCountData, getTreatmentwiseBeforeAfterPhotosCountQueryData } = GetTreatmentwiseBeforeAfterPhotosCountAction()


    useEffect(() => {
        if (step == 2) {
            initGetBatchNumberData({
                consentFormRecordId: parseInt(consentFormRecordId),
                treatmentId: selectedTreatmentId ? selectedTreatmentId : 0,
            }, (res) => {
                if (res.success) {

                } else {
                    console.log("res", res.message)
                }
            }, (err) => {
            })
        }
    }, [step])

    useEffect(() => {
        if (getBatchNumbersQueryData?.data?.getBatchNumbers?.data?.batchNumbers?.length > 0 && step == 2) {

            const newData = getBatchNumbersQueryData?.data?.getBatchNumbers?.data?.batchNumbers?.map((item) => {
                return { id: item.id, image: item.batchNumber, previewUrl: item.batchNumber, enableDeleteApiCall: true }
            })
            if (newData?.length < 6) {
                setUploadedFiles([...newData, {
                    image: "",
                    previewUrl: "",
                    enableDeleteApiCall: false
                }]);
            }
            else {
                setUploadedFiles(newData)
            }
        }
        else {
            setUploadedFiles([
                {
                    image: "",
                    previewUrl: "",
                    enableDeleteApiCall: false
                }
            ])
        }
    }, [getBatchNumbersQueryData])


    const resizeFile = (file) => {
        const outputType = file.type.slice(file.type.lastIndexOf('/') + 1)
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                outputType,
                100,
                0,
                (uri) => {
                    resolve(uri);
                    console.log("uri", uri)
                },
                "file"
            );
        });
    }

    const handleFileUpload = async (acceptedFiles, index, key) => {
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

        // Check if there are any non-image files
        const nonImageFiles = acceptedFiles.filter(file => !file.type.startsWith('image/'));

        // If there are non-image files, display an error message
        if (nonImageFiles.length > 0) {
            toast.error("Please select only image files.....");
            return; // Stop further processing
        }
        const updatedImage = [...uploadedFiles];
        const image = await resizeFile(imageFiles[0]);
        updatedImage[index]["image"] = [image];
        updatedImage[index]["previewUrl"] = URL?.createObjectURL(image);
        console.log("image", image)

        // Update the uploadedFiles state with the new files
        setUploadedFiles(updatedImage);
        if (updatedImage?.length < 6) {
            addPhotos();
        }
    };


    const addPhotos = () => {
        setUploadedFiles(prevBreaks => [...prevBreaks,
        {
            image: "",
            previewUrl: "",
            enableDeleteApiCall: false

        }
        ]);
    };
    const renderPhotos = () => {
        return uploadedFiles.map((file, index) => (
            <div className="photoGridItem" key={index}>
                {/* <p>Photo {index + 1}</p> */}
                <div className=''>
                    {
                        file?.image ?
                            <div className={`formDropzoneUploading`}>
                                <div className='add-more-label-container'>
                                    <img src={file?.previewUrl} alt='' onClick={() => {
                                        setPreviewImage(
                                            {
                                                photoIndex: index,
                                                photoUrl: file?.previewUrl,
                                                photoId: file?.id
                                            }
                                        )
                                        setIsPreview(true)
                                    }} />
                                </div >
                            </div > :
                            <Dropzone
                                onDrop={files => handleFileUpload(files, index)}
                                maxFiles={6}
                                accept="image/*"
                            >
                                {({ getRootProps, getInputProps, isDragActive }) => (
                                    <div {...getRootProps()} className={`formDropzoneUploading ${isDragActive ? 'formDropzoneUploading-active' : ''}`}>
                                        <input {...getInputProps()} />
                                        <div className='add-more-label-container'>
                                            {/* {file?.beforeImage && typeof file?.beforeImage === 'object' ? <img src={URL.createObjectURL(file?.beforeImage)} alt='' /> : <img src={beforeafterimage} alt='' />} */}
                                            {file?.previewUrl ? <img src={file?.previewUrl} alt='' /> : <img src={beforeafterimage} alt='' />}
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                    }
                </div >


            </div >
        ));
    };

    const goBackClickHandler = () => {
        // navigate(appointmentOverviewBeforeAndAfterPhotosRoute(), { state: { fromCalendar: location?.state?.fromCalendar, AppointmentDate: location?.state?.appointmentId } })
        setStep(1)
    }

    const onClickHandler = () => {
        let formattedData = uploadedFiles.filter(item => item.image && !item.enableDeleteApiCall).map((item) => {
            return item.image[0]

        })

        if (formattedData?.length > 0) {
            initSaveBatchNumbersData({
                consentFormRecordId: parseInt(consentFormRecordId),
                treatmentId: selectedTreatmentId ? selectedTreatmentId : 0,
                batchNumbers: formattedData
            }, (res) => {
                if (res.success) {
                    dispatch({
                        type: CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT,
                        payload: null
                    })
                    initGetTreatmentwiseBeforeAfterPhotosCountData({ consentFormRecordId: parseInt(consentFormRecordId), type: 2 })
                    showToast("success", res.message, false, "")
                    setStep(1);
                } else {

                }
            }, (err) => {
                console.log("error on create note", err)
            })
        }
        else {
            setStep(1);
        }
    }

    const deleteApiCall = () => {
        initRemoveBatchNumberPhotoData({
            batchNumberId: previewImage.photoId,
        }, (res) => {
            if (res.success) {
                dispatch({
                    type: CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT,
                    payload: null
                })
                initGetTreatmentwiseBeforeAfterPhotosCountData({ consentFormRecordId: parseInt(consentFormRecordId), type: 2 })
                showToast("success", res?.message, "", false)
            } else {
                console.log("res", res.message)
            }
        }, (err) => {

        })
    }

    const deleteImageHandler = () => {
        const remainingData = uploadedFiles.splice(previewImage?.photoIndex, 1)
        if (remainingData[0].enableDeleteApiCall) {
            deleteApiCall();
        }
        if (uploadedFiles?.length == 5 && uploadedFiles[4].image != "") {
            addPhotos();
        }

        setIsDeleteOpen(false)
        setPreviewImage(null)
        setIsPreview(false)
    }
    return (

        <div className='add-batch-number-photo-container'>
            <NavHeader isBackOnly={true} applyPadding={false}
                handleOnBack={() => goBackClickHandler()}
            />

            {getBatchNumbersQueryData?.loading ? <SkeletonLoader type="batch-number-photos" /> : <div>
                {isOtherPhotoUpload ? <h1>Other photos</h1> : <h1>{selectedTreatment}</h1>}
                {isOtherPhotoUpload ? <p> Add other photos, you would like to attach to this booking. </p> : <p>Add batch number photos for clients to view the results. </p>}

                <div className='photoGrid'>
                    {renderPhotos()}
                </div>

                <StepButton className="mt-5" blue={true} label={"Done"}
                    // disabled={uploadedFiles?.length >= 1 ? false : true}
                    isLoading={saveBatchNumberQueryData?.loading}
                    onClick={() => {
                        // setStep(1);
                        onClickHandler();
                    }
                    } />
            </div>}
            {/* For image preview Modal */}
            <CustomModal
                type={"common-width"}
                // className={"w-75"}
                modalBody={<div className="preview-before-after-photos-document-modal">
                    {/* <h1>{singleFile?.documentName?.slice(-25)}</h1> */}
                    <img src={previewImage?.photoUrl} alt="preview" style={{ objectFit: "contain" }} />
                    <div className="delete-photos-preview-button">  <StepButton label={"Delete"} redWithBorder={true} onClick={() => {
                        setIsDeleteOpen(true);
                    }} /></div>
                </div>}
                setModalOpen={setIsPreview}
                modalOpen={isPreview}
                className={"previewImgModel"}
                setIsOpenMobileCalendar={setIsPreview}

            />
            <CustomModal
                type={"common-width"}
                modalBody={
                    <>
                        <div className="delete-modal-body mt-2">
                            <h1>Are you sure?</h1>
                            <p>
                                Are you sure you want to delete this image?
                            </p>
                            <div className="w-100">
                                <div className="delete-button-modal">
                                    <StepButton red={true} label={"Delete"} onClick={() => {
                                        deleteImageHandler();
                                    }} />
                                </div>
                                <div className="cancle-button-modal">
                                    <StepButton gray={true} onClick={() => {
                                        setIsDeleteOpen(false)
                                    }} label={"Cancel"} />
                                </div>
                            </div>
                        </div>
                    </>
                }
                modalOpen={isDeleteOpen}
                setModalOpen={setIsDeleteOpen}
                setIsOpenMobileCalendar={setIsDeleteOpen}
                className={"ConfirmationDeleteModal"}
            />

        </div>
    )
}

export default BatchNumberPhoto