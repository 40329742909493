import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_FINANCE_REGISTARTION_BASIC_DETAILS=gql`
query GetFinanceBasicDetailsOfUser {
    getFinanceBasicDetailsOfUser {
      success
      message
      data {
        email
        phone
        country_code
        phone_country
        website
        facebook
        instagram
      }
    }
  }
`;

export const GetFinanceRegistrationBasicDetailAction=()=>{
    const [getFinanceRegistrationBasicDetailResponseFunction,
   getFinanceRegistartionBasicDetailResponseData ]=useLazyQuery(GET_FINANCE_REGISTARTION_BASIC_DETAILS,{
     fetchPolicy: "cache-and-network",
     nextFetchPolicy: "cache-and-network",
     onCompleted:({getFinanceBasicDetailsOfUser:responseData})=>{
        if(responseData.success){
            console.log("res",responseData?.message)
        }else{
            console.log("error",responseData?.message)
        }
     }
    })

    const getFinanceRegistrationBasicDetailQueryData=getFinanceRegistartionBasicDetailResponseData;

    const initGetFinanceRegistrationBasicDetailData=(data)=>{
            getFinanceRegistrationBasicDetailResponseFunction({
                variables:data
            });
    };

    return {getFinanceRegistrationBasicDetailQueryData,initGetFinanceRegistrationBasicDetailData}
}