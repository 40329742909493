import { gql, useMutation } from "@apollo/client";

const SAVE_FINANCE_BANK_DETAIL=gql`
mutation SaveBankDetailsOfUserForFinance($bankAccountNumber: String!, $bankSortCode: String!) {
    saveBankDetailsOfUserForFinance(bank_account_number: $bankAccountNumber, bank_sort_code: $bankSortCode) {
      success
      message
    }
  }
  `;

  export const SaveFinanceBankDetailsAction=()=>{
    const [saveFinanceBankDetailResponseFunction,
    saveFinanceBankDetailsResponseData]=useMutation(SAVE_FINANCE_BANK_DETAIL)


    const saveFinanceBankDetailsQueryData=saveFinanceBankDetailsResponseData;

    const initSaveFinanceBankDetailData=(data, successCallback, errorCallback)=>{
        saveFinanceBankDetailResponseFunction({
            variables:data
        }).then((responseData)=>{
            console.log(responseData,"respn")
            if(responseData.data.saveBankDetailsOfUserForFinance.success){
                successCallback(responseData)
            }else{
                errorCallback(responseData)
            }
        }).catch((error)=>{
           errorCallback(error)
        })
    }

    return {saveFinanceBankDetailsQueryData,initSaveFinanceBankDetailData}
  }