import React ,{useEffect, useState} from 'react'
import { IoMdClose } from "react-icons/io";
import "./VerifiedModel.scss"
import CustomModal from "../CustomModal"
import {GetUserInsuranceDetailAction} from "../../redux/actions/GetUserInsuranceDetails/GetUserInsuranceDetail"

const VerifiedModel = () => {

    const [modelIsOpen,setIsOpen]=useState(false)
    const {getUserInsuranceDetailsQueryData,initGetUserInsuranceDetailData} =GetUserInsuranceDetailAction();
    const [isPopupVisible, setIsPopupVisible] = useState(true);


    useEffect(()=>{
        initGetUserInsuranceDetailData()
    },[])

    const handleApproveRequest = () => {
      setIsPopupVisible(false); 
    };

    const reason=getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data
    const handleReasonModel=()=>{
          setIsOpen(true)
    }

    const closeModel=()=>{
        setIsOpen(false)
    }

  return (
    <>
    {
      isPopupVisible && 
      <div className='verifiedModel'>
            <div className='modelConent'>
               <div className='content'> 
                <div className='rejectedText'>
                    <h5>Your verified appliction request is rejected</h5>
                 </div>
                 {/* <div className='viewReason' onClick={()=>handleReasonModel()}>
                    <p>View reason</p>
                 </div> */}
               </div>
                <div className='closeIcon'>
                    <IoMdClose className='closeI' onClick={()=>handleApproveRequest()}/>
                </div>

            </div>
        </div>
    }
    
        



        {/* =====================================VIEW REASON MODEL================================================= */}
        <CustomModal 
          modalOpen={modelIsOpen}
          type={"common-width"}
          setModalOpen={closeModel}
          modalBody={
            <div>
                <p>{reason?.reason}</p>
            </div>
          }
        />
        
    </>
  )
}

export default VerifiedModel