// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";

const VERIFY_FINANCE_INVOICE_TOKEN_QUERY = gql`
query VerifyFinanceInvoiceToken($financeLoanRequestId: Int, $token: String) {
    verifyFinanceInvoiceToken(financeLoanRequestId: $financeLoanRequestId, token: $token) {
      success
      message
      data {
        invoiceBase64
      }
    }
  }
`;

export const VerifyFinanceInvoiceToken = () => {
  const [
    verifyFinanceInvoiceTokenFunction,
    verifyFinanceInvoiceTokenResponseData,
  ] = useLazyQuery(VERIFY_FINANCE_INVOICE_TOKEN_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ verifyFinanceInvoiceToken: responseData }) => {
      if (responseData.success) {
        console.log("res============",responseData)
      } else {
       console.log("else-----------------------")
        
      }
    },
  });
  //this will be called from components
  const verifyFinanceInvoiceTokenQueryData = verifyFinanceInvoiceTokenResponseData; //variable

  const initverifyFinanceInvoiceTokenData = (data,successCallback,errorCallback) => {
    verifyFinanceInvoiceTokenFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.verifyFinanceInvoiceToken?.success) {
        successCallback(res);
      } else {
        console.log('res error', res);
        errorCallback(res);
      }
    }).catch((err) => {
      console.log('res err', err);
      errorCallback(err);
    });
  };

  return { verifyFinanceInvoiceTokenQueryData, initverifyFinanceInvoiceTokenData };
};
