import React, { useEffect,useState } from "react";
import Lottie from "react-lottie";
import "./thankyou-registration.scss";
import thankyou from "../../../images/thank-you/success.svg";
import { config } from "../../../config";
import thankAnimation from "../../../assets/lottieanimation/Animation - 1712039535881.json";
import { frontendURL } from "../../../config";
import loadingIcon from "../../../images/loading.gif";
import StepButton from "../../common/StepButton";

const ThankYou = ({ onNextStep, afterSubmit,fromPharmacy = true,description, addbtn=false, btnText, onClickCallback }) => {
  const WhatsappLinkRedirect = config.PHARMACY_WHATSAPP_LINK;
  const [isLoading,setIsLoading] = useState(false)
const [wtsappLoading,setWtsappLoading] = useState(false)
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: thankAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const BackToHome = () => {
    window.location.href = frontendURL + "/business-dashboard";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-thankyou">
      {/* <div className='TopHeader'>
        <div className='header-thankyou'>
          <img src={backIcon} alt='backIcon' onClick={() => setCurrentStep(4)} />
        </div>
      </div> */}

      <div className="main-thakyou-body">
        <div className="main-body">
          <div className={`congration-wrapper-thankyou ${!fromPharmacy ? "w-100": ""}`}>
            <Lottie options={defaultOptions} width={"388px"} height={"100%"} />
          </div>
          <div className={`thankyou-body ${fromPharmacy?'': 'thank' }`}>
            <div>
              <img src={thankyou} alt="thankyou" />
            </div>
            <div className="header-text-thankyou">Thank you</div>

         { fromPharmacy ? (
          <>
            <div className="context-thankyou">
              Your registration has now been sent to the pharmacy approval team.
              You will be notified about the status of your account within 48
              hours.
            </div>
            <div className="whatsapp-btn-container col-12 mt-0 mt-md-4 mt-lg4 text-center">
              <button
                onClick={() => {
                  setWtsappLoading(true);
                  window.open(WhatsappLinkRedirect, "_blank");
                  setWtsappLoading(false);
                }}
                role="button"
                style={{
                  position: "relative",
                  zIndex: "9999",
                }}
              >
              {wtsappLoading ? (
                  <>
                    <img src={loadingIcon} className={"loadingIcon"} />
                  </>
                ) : (
                "Whatsapp us")}
              </button>
            </div>
            <div className="col-12 mt-0 text-center">
              <div
                className="btn back-thank-you"
                onClick={() => {
                  setIsLoading(true);
                  BackToHome();
                  setIsLoading(false);
                }}
              >
               {isLoading ? (
                  <>
                    <img src={loadingIcon} className={"loadingIcon"} />
                  </>
                ) : ( "Dashboard")}
              </div>
            </div>
            </>)
            : 
           ( <>
            {description &&(<p className="description mt-5">{description}</p>)}
            {addbtn && (
              <StepButton
                className="w-25 mt-3 thankyou-done"
                label={btnText}
                blue={true}
                onClick={()=>onClickCallback()}
              />
            )}
            </>)
          }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
