import React, { useEffect, useState } from 'react'
import './confirm-rebooking.scss'
import Avatar from 'react-avatar';
import CustomRadioButton from '../../../../common/CustomRadioButton';

import RequestDeposit from "../../../../../../src/assets/rebook/desposit.svg";
import closeIcon from '../../../../../../src/assets/rebook/close_icon.svg'

import { ReactComponent as SendPaymentIcon } from "../../../../../../src/assets/rebook/sendPayment.svg";
import { ReactComponent as LeftIcon } from "../../../../../../src/assets/rebook/left.svg";

import { ReactComponent as BankTransferIcon } from "../../../../../../src/assets/rebook/bankTransfer.svg";
import { ReactComponent as CashIcon } from "../../../../../../src/assets/rebook/cashIcon.svg";
import { ReactComponent as PayMonthlyIcon } from "../../../../../../src/assets/rebook/payMonthly.svg";
import CustomModal from '../../../../modal/CustomModal';
import { GetAppointmentDetailForRebookAction } from '../../../../redux/actions/rebook-appointment/RebookAppointment';
// import { useParams } from 'react-router';
import Suffix from "../../../../../images/new-appointment/sufix.svg";
import Percent from "../../../../../images/new-appointment/percent.svg";
import StepButton from '../../../../common/StepButton';
import { CreateNewAppointmentMutation } from '../../../../redux/actions/appointments/createNewAppointment';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { getAppointmentRoute, getCalendarRoute } from '../../../../../Routes';
import { useSelector } from 'react-redux';
import { GetFinanceStatusAction, GetStripeAccountAndFinanceRequestStatusAction } from '../../../../redux/actions/newAppointment/BusinessBoosters';
import { RebookAppointmentAction } from '../../../../redux/actions/appointments/GetAppointmentDetails';
import { webBaseUrl } from '../../../../../config';
import showToast from '../../../../common/CustomToast/CustomToast';
import { pounds } from '../../../../../utils/general';
import { IsAlreadyBookingExistAction } from '../../../../redux/actions/dateTimeslots/DateTimeSlots';
import useWindowDimension from '../../../../../utils/useWindowDimensions';

export let confirmRebook

const ConfirmRebooking = ({ rebookingObj, setSaveLoading, isAvailableForWorking }) => {

    const moment = require('moment');
    // const { appointmentId } = useParams()
    const navigate = useNavigate()

    //====================================STATES====================================
    const [isPaymentOptionChangeState, setIsPaymentOptionChangeState] = useState(1)
    const [selectedPaymentOption, setSelectedPaymentOption] = useState(null)
    const [stripeFinceStatusObj, setStripeFinceStatusObj] = useState(null)




    //PAYMENT TYPE MODAL
    const [isPaymentTypeModalOpen, setIsPaymentTypeModalOpen] = useState(false)

    const [appointmentDetailForRebook, setAppointmentDetailForRebook] = useState(null)
    const [endTime, setEndTime] = useState(null)

    //PAYMENT SUMMARY STATES
    const [subtotalSave, setSubtotalSave] = useState(0);
    const [dueAmount, setDueAmount] = useState(0);

    //EDIT DISCOUNT MODAL
    const [active, setActive] = useState("percent");
    const [discount, setDiscount] = useState(0);
    const [discountCodeType, setDiscountCodeType] = useState("percentage") //percentage or pound
    const [isDiscountPriceOpen, setIsDiscountPriceOpen] = useState(false);
    const [isDiscountError, setIsDiscountError] = useState("")
    const [percentDiscount, setPrecentDiscount] = useState(0)
    const [poundDiscount, setPoundDiscount] = useState(0)




    //EDIT DEPOSIT MODAL
    const [isEditPriceOpen, setIsEditPriceOpen] = useState(false);
    const [depositPrice, setDepositPrice] = useState(0)
    const [isDepositError, setIsDepositError] = useState("")
    const [deposit, setDeposit] = useState(0);

    //EDIT TREATMENT PRICE MODAL
    const [isEditTreatmentPriceOpen, setIsEditTreatmentPriceOpen] = useState(false);
    const [isTreatmentPriceEditError, setIsTreatmentPriceEditError] = useState("")
    const [treatmentPriceTemp, setTreatmentPriceTemp] = useState(0)
    const [treatmentEditPriceIndex, setTreatmentEditPriceIndex] = useState(null)
    const [treatmentEditPriceAmount, setTreatmentEditPriceAmount] = useState(null)
    const [selectedTreatmentData, setSelectedTreatmentData] = useState([])
    const [isAvailableForWorkingTemp, setIsAvailableForWorkingTemp] = useState(false);







    //OFFER PAY MONTHLY LEARN MODAL
    const [isOfferPayMonthlyLearnOpen, setIsOfferPayMonthlyLearnOpen] = useState(false);

    //=============================ACTIONS=============================
    const { initGetAppointmentDetailForRebookData } = GetAppointmentDetailForRebookAction()
    const { initGetStripeAccountAndFinanceRequestStatusData } = GetStripeAccountAndFinanceRequestStatusAction()
    const { initGetFinanceStatusData } = GetFinanceStatusAction()
    const { initRebookAppointmentData, } = RebookAppointmentAction()
    const { isAlreadyBookingExistQueryData, initIsAlreadyBookingExistData } = IsAlreadyBookingExistAction()


    //SELECTOR
    const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)

    const checkOfferPayMonthly = () => {
        if (stripeFinceStatusObj?.financeRequestStatus == 0) {
            initGetFinanceStatusData({
                userId: UserProfileData?.user?.usr_id
            }, (response) => {
                if (response?.financeStatus == 1) {
                    setIsOfferPayMonthlyLearnOpen(true)
                    return false
                }
            })
        } else if (stripeFinceStatusObj?.financeRequestStatus == 1) {
            setIsOfferPayMonthlyLearnOpen(true)
            return false
        } else if (stripeFinceStatusObj?.financeRequestStatus == 2) {
            return true
        }
    }


    const paymentTypeOptions = [
        {
            icon: <CashIcon />,
            label: "Cash",
            value: 2,
            isSelectable: () => { return true }
        },
        {
            icon: <SendPaymentIcon />,
            label: "Card machine",
            value: 6,
            isSelectable: () => { return true }
        },
        {
            icon: <PayMonthlyIcon />,
            label: "Pay monthly",
            value: 5,
            isSelectable: () => { return checkOfferPayMonthly() }

        },
        {
            icon: <BankTransferIcon />,
            label: "Bank transfer",
            value: 3,
            isSelectable: () => { return true }
        },
        {
            icon: <SendPaymentIcon />,
            label: "Send a payment link",
            value: 7,
            isSelectable: () => { return true }
        },
    ]




    //=================================FUNCTIONS=================================

    //CALCULATE DUE AMOUNT
    const calculateDueAmount = (funcSubtotal = false) => {
        // let subtotal = 0;
        let tempSubtotal
        if (funcSubtotal == false) {
            tempSubtotal = subtotalSave
        } else {
            tempSubtotal = funcSubtotal
        }
        let tempDiscount;
        if (discountCodeType == "percentage") {
            tempDiscount = percentageToValueConverter(discount, tempSubtotal)
        }
        else {
            tempDiscount = discount;
        }
        let finalDueAmount = tempSubtotal - tempDiscount - deposit

        return finalDueAmount

    }

    //CHECK STRING CONTAIN ONLY NUMBER OR NOT
    const isNumber = (value) => {
        return /^(\d+|\d+\.\d+)$/.test(value);
    }

    //PAYMENT SUMMARY VALUE CHANGE
    const checkIsValidValue = (inputPrice, remainingParameterPrice) => {
        console.log(subtotalSave, inputPrice, remainingParameterPrice, "remainingParameterPrice")
        return (subtotalSave - inputPrice - remainingParameterPrice) >= 0;
    }

    //CONVERT PERCENTAGE TO VALUE
    const percentageToValueConverter = (value) => {
        console.log("sub totall", subtotalSave * (value / 100))
        return (subtotalSave * (value / 100)).toFixed(2);
    }

    //UPDATE TREATMENT PRICE
    const updateTreatmentPrice = (treatmentId, newPrice) => {
        setSubtotalSave(subtotalSave + (newPrice - treatmentEditPriceAmount))
        setSelectedTreatmentData(prevTreatments => {
            return prevTreatments.map(treatment => {
                if (treatment.treatmentId === treatmentId) {
                    return { ...treatment, treatmentPrice: newPrice };
                }
                return treatment;
            });
        });
    };

    //HANDLE EDIT TREATMENT PRICE
    const handleEditTreatmentPrice = (treatmentPrice) => {
        //setIsOpen(false);
        setTreatmentPriceTemp(treatmentPrice)
        setIsTreatmentPriceEditError("")
        setIsEditTreatmentPriceOpen(true);
    };


    const handleChangeActive = (name) => {
        setActive(name);

    };

    const confirmRebookingApiCall = () => {
        setSaveLoading(true)
        initRebookAppointmentData({
            appointmentId: parseInt(rebookingObj?.appointmentId),
            appointmentDate: rebookingObj?.date,
            startTime: rebookingObj?.timeSlot,
            endTime: endTime,
            treatmentData: selectedTreatmentData?.map((item) => {
                return {
                    treatmentId: item.treatmentId,
                    treatmentPrice: +item.treatmentPrice,
                }
            }),
            subTotal: parseFloat(parseFloat(subtotalSave).toFixed(2)),
            discountCodeType: discountCodeType == "percentage" ? "PERCENTAGE" : "FIXED",
            discountValue: parseFloat(parseFloat(discount).toFixed(2)),
            depositAmount: parseFloat(parseFloat(deposit).toFixed(2)),
            totalAmount: parseFloat(parseFloat(dueAmount).toFixed(2)),
            depositRequired: isPaymentOptionChangeState == 1 ? true : false,
            paymentType: selectedPaymentOption?.value
        }, (res) => {
            setSaveLoading(false)
            showToast('success', res?.message);
            navigate(getAppointmentRoute(res?.data?.appointmentId))

        }, (err) => {
            setSaveLoading(false)
            toast.error(err?.message)
        }
        )
    }

    // const confirmRebookingHandler = () => {
    //     console.log("confirm", rebookingObj?.date)
    //     initIsAlreadyBookingExistData({
    //         date: moment(rebookingObj?.date, "ddd DD MMM YYYY").format("YYYY-MM-DD"),
    //         clinicId: rebookingObj?.clinicId,
    //         startTime: rebookingObj?.timeSlot,
    //         endTime: moment(rebookingObj?.timeSlot, 'HH:mm').add(30, 'minutes').format('HH:mm'),
    //         treatmentIds: rebookingObj?.treatmentIds,
    //         appointmentId: parseInt(rebookingObj?.appointmentId)
    //     }, (responseData) => {
    //         setIsAvailableForWorkingTemp(false)
    //         if (responseData?.data?.isBookingExist) {
    //             setOverLappingMessage(responseData?.message)
    //             setIsAppointmentOverlappingOpen(true)
    //         } else {
    //             confirmRebookingApiCall()
    //         }
    //     }, (error) => {
    //         showToast("error", error?.message, "", false)
    //     })

    // }

    confirmRebook = () => {
        console.log("isAvailableForWorkingasha", isAvailableForWorking)
        if (selectedPaymentOption == null && isPaymentOptionChangeState !== 2) {
            showToast("error", "Please select a payment type", "", false, undefined, undefined, { toastId: 'select-payment-type-error-toast' })
            return
        }
        if (isAvailableForWorking) {
            setIsAvailableForWorkingTemp(true);
        }
        else {
            setIsAvailableForWorkingTemp(false)
            confirmRebookingApiCall();
        }

    }

    //=============================EFFECTS=============================


    //GET STRIPE ACCOUNT AND FINANCE REQUEST STATUS
    useEffect(() => {
        if (UserProfileData)
            initGetStripeAccountAndFinanceRequestStatusData({}, (response) => { setStripeFinceStatusObj(response) })
    }, [UserProfileData])

    useEffect(() => {
        window.scrollTo(0, 0)
        initGetAppointmentDetailForRebookData({
            appointmentId: parseInt(rebookingObj?.appointmentId),
            allDetails: true
        }, (res) => {
            setAppointmentDetailForRebook(res)
            if (res.discountCodeType == "percentage") {
                setPrecentDiscount(res?.discountAmount)
                setDiscountCodeType("percentage")
                setActive("percent")
            } else {
                setPoundDiscount(res?.discountAmount)
                setDiscountCodeType("pound")
                setActive("pound")
            }
            setSubtotalSave(res?.subTotal)
            setDueAmount(res?.totalAmount)
            setDiscount(res?.discountAmount)
            setDeposit(res?.depositAmount)
            setDepositPrice(res?.depositAmount)
            setDueAmount(res?.totalAmount)
            let minutesToAdd
            if (res.treatmentDetails?.length > 0) {
                minutesToAdd = res.treatmentDetails.reduce((acc, item) => {
                    return acc + item.treatmentDuration
                }, 0)
            } else {
                const startTime = moment(res.appointmentStartTime, 'HH:mm');
                const endTime = moment(res.appointmentEndTime, 'HH:mm');
                minutesToAdd = moment.duration(endTime.diff(startTime)).asMinutes();
            }
            setSelectedTreatmentData(res?.treatmentDetails)
            setEndTime(moment(rebookingObj?.timeSlot, 'HH:mm').add(minutesToAdd, 'minutes').format('h:mm A'))
            if ((res?.canRequestDeposit || !res?.canRequestDeposit && res?.depositAmount > 0)) {
                setSelectedPaymentOption({
                    icon: <SendPaymentIcon />,
                    label: "Send a payment link",
                    value: 7,
                    isSelectable: () => { return true }
                })
            }
            else {
                setSelectedPaymentOption({
                    icon: <CashIcon />,
                    label: "Cash",
                    value: 2,
                    isSelectable: () => { return true }
                })
            }
        })

    }, [])

    useEffect(() => {
        if (appointmentDetailForRebook?.canRequestDeposit == false && deposit == 0) {
            setIsPaymentOptionChangeState(2)
            document.getElementById(2).checked = true
            // setIsPaymentOptionChangeState(2))
        }
    }, [appointmentDetailForRebook?.canRequestDeposit, deposit])

    useEffect(() => {

        if (selectedPaymentOption?.label && !(appointmentDetailForRebook?.canRequestDeposit == false && deposit == 0)) {
            if (selectedPaymentOption?.label == "Pay monthly") {
                document.getElementById(1).checked = false
                setIsPaymentOptionChangeState(2);
            }
            else if (selectedPaymentOption?.label == "Cash") {
                document.getElementById(1).checked = false
                setIsPaymentOptionChangeState(2);
            }
            else if (selectedPaymentOption?.label == "Bank transfer") {
                document.getElementById(1).checked = false
                setIsPaymentOptionChangeState(2);
            }
            else if (selectedPaymentOption?.label == "Card machine") {
                document.getElementById(1).checked = false
                setIsPaymentOptionChangeState(2);
            }
            else {
                document.getElementById(1).checked = true
                setIsPaymentOptionChangeState(1);
            }
        }
    }, [selectedPaymentOption])

    const dimensions = useWindowDimension();
    const [confirmHeaderHeight, setConfirmHeaderHeight] = useState(0);
    const [confirmSecHeaderHeight, setConfirmSecHeaderHeight] = useState(0);
    const [confirmFooterHeight, setConfirmFooterHeight] = useState(0);
  
  
  const updateHeights = () => {
    setConfirmHeaderHeight( document.getElementsByClassName('RebookHeader')[0]?.offsetHeight || 0);
    setConfirmSecHeaderHeight(document.getElementsByClassName('sectionTitleWrapper')[0]?.offsetHeight || 0);
    setConfirmFooterHeight(document.getElementsByClassName('BtnWrapper')[0]?.offsetHeight || 0);
  };
  
  useEffect(() => {
    updateHeights();
    window.addEventListener("resize", updateHeights);
    return () => {
      window.removeEventListener("resize", updateHeights);
    };
  }, [dimensions]); // Run effect when dimensions change
  
  const scrollableDivHeight = dimensions?.height - (confirmHeaderHeight || 0) - (confirmSecHeaderHeight || 0) - (confirmFooterHeight || 0) ;
  

    return (
        <>
            <div className="confirm-rebooking-main-div">
                <div className='sectionTitleWrapper'>
                    <h2>Confirm & pay</h2>
                    <p>Ask your client to review and select a payment method for the treatment.</p>
                </div>
                <div className='rebookingInputWrapper' style={{height: scrollableDivHeight, overflowY: 'auto'}}>
                    <div className='rebooking-common-div'>
                        <span>
                            Appointment details
                        </span>
                        <hr />
                        {/* DATE */}
                        <div className='my-2 d-flex justify-content-between'>
                            <span>
                                Date
                            </span>
                            <h6>
                                {/* convert date in 15th March 2023 format */}
                                {moment(rebookingObj?.date, "ddd DD MMM YYYY").format('dddd, DD MMMM YYYY')}
                            </h6>
                        </div>
                        {/* TIME */}
                        <div className='my-2 d-flex justify-content-between'>
                            <span>
                                Time
                            </span>
                            <h6>
                                {moment(rebookingObj?.timeSlot, 'HH:mm').format('hh:mm A')} - {endTime}
                            </h6>
                        </div>
                        {/* LOCATION */}
                        {

                            <div className='my-2 d-flex justify-content-between'>
                                <span>
                                    Location
                                </span>
                                <h6>
                                    {appointmentDetailForRebook?.clinicName ? appointmentDetailForRebook?.clinicName : "N/A"}
                                </h6>
                            </div>
                        }
                    </div>

                    {
                        selectedTreatmentData?.length > 0 &&
                        <div className="services-main-div rebooking-common-div">
                            <span>
                                Treatments
                            </span>
                            <hr />
                            <div className="row">
                                {
                                    selectedTreatmentData?.map((item, index) =>
                                        <>
                                            <div className='my-2 d-flex justify-content-between'>
                                                <h5>
                                                    {item.treatmentName}
                                                </h5>
                                                <span>
                                                    <span className='text-decoration-underline cursor-pointer'
                                                        onClick={() => {
                                                            handleEditTreatmentPrice(item?.treatmentPrice)
                                                            setTreatmentEditPriceIndex(item.treatmentId)
                                                            setTreatmentEditPriceAmount(item.treatmentPrice)
                                                        }}
                                                    >{pounds.format(item?.treatmentPrice)}</span>
                                                </span>
                                            </div>

                                        </>
                                    )
                                }
                            </div>
                        </div>
                    }
                    <div className='rebooking-common-div'>
                        <span>
                            Payment summary
                        </span>
                        <hr />
                        <div className='my-2'>
                            <div className='d-flex justify-content-between my-2'>
                                <span>
                                    Subtotal
                                </span>
                                <span>
                                    {pounds.format(parseFloat(subtotalSave).toFixed(2))}
                                </span>
                            </div>
                            <div className='d-flex justify-content-between my-2'>
                                <span className='cursor-pointer text-decoration-underline' onClick={() => setIsDiscountPriceOpen(true)}>
                                    Edit Discount
                                </span>
                                <span>
                                    {/* {active == "percentage" ? `£${discount * }%` : `£${appointmentDetailForRebook?.discountAmount}`} */}
                                    {
                                        discountCodeType == "percentage" ? pounds.format(parseFloat((subtotalSave * discount) / 100).toFixed(2)) : pounds.format(parseFloat(discount).toFixed(2))
                                    }
                                </span>
                            </div>
                            <div className='d-flex justify-content-between my-2'>
                                <span>
                                    Deposit amount
                                </span>
                                <span>
                                    {pounds.format(parseFloat(deposit).toFixed(2))}
                                </span>
                            </div>
                            <div className='d-flex justify-content-between my-2'>
                                <h6>
                                    Due amount
                                </h6>
                                <h6>
                                    {pounds.format(parseFloat(dueAmount).toFixed(2))}

                                </h6>
                            </div>
                            <div className='d-flex justify-content-between my-2'>
                                <h6>
                                    Total
                                </h6>
                                <h6>
                                    {pounds.format(parseFloat(subtotalSave).toFixed(2))}
                                </h6>
                            </div>
                        </div>
                    </div>

                    <div className='payment-option-main-div rebooking-common-div'>
                        <span>
                            Payment options
                        </span>
                        <hr />
                        {(appointmentDetailForRebook?.canRequestDeposit || !appointmentDetailForRebook?.canRequestDeposit && deposit > 0) && <div className='my-2'>
                            <div className={`radio-button-common-div-content ${selectedPaymentOption?.label == "Pay monthly" || selectedPaymentOption?.label == "Cash" ||
                                selectedPaymentOption?.label == "Bank transfer" ||
                                selectedPaymentOption?.label == "Card machine" ? "opacity-50 pe-none" : ""}`}
                                onClick={() => (document.getElementById(1)).addEventListener(('change'),
                                    setIsPaymentOptionChangeState(1))}
                            >
                                <div className="w-100">
                                    <CustomRadioButton
                                        id={1}
                                        name="payment-options"
                                        value={'request-deposit'}
                                        isFilterCanvas={true}
                                        filterImgIcon={RequestDeposit}
                                        filterLabel="Request deposit"
                                        isblacked={true}
                                        filterLabel2Component={<>
                                            <span>{pounds.format(parseFloat(deposit).toFixed(2))} now, {pounds.format(parseFloat(dueAmount).toFixed(2))} on the day. <span className='edit-tag' onClick={() => setIsEditPriceOpen(true)}>Edit</span></span>
                                        </>}
                                        onChange={() => { setIsPaymentOptionChangeState(1) }}
                                        checked={isPaymentOptionChangeState == 1}
                                    />
                                </div>
                            </div>
                        </div>}

                        {(!appointmentDetailForRebook?.canRequestDeposit && deposit == 0) && < div className='my-2'>
                            <div className="radio-button-common-div-content"
                                onClick={() => (document.getElementById(2)).addEventListener(('change'),
                                    setIsPaymentOptionChangeState(2))}
                            >
                                <div className="w-100">
                                    <CustomRadioButton
                                        id={2}
                                        name="payment-options"
                                        value={'no-deposit-required'}
                                        isFilterCanvas={true}
                                        filterImgIcon={closeIcon}
                                        filterLabel="No deposit required"
                                        filterLabel2Component={<>
                                            <span>Waiver the deposit amount</span>
                                        </>}
                                        onChange={() => { setIsPaymentOptionChangeState(2) }}
                                        checked={isPaymentOptionChangeState == 2}
                                    />
                                </div>
                            </div>
                        </div>}
                    </div>

                    {
                        <div className='rebooking-common-div'>
                            <span>
                                Payment type
                            </span>
                            <hr />

                            <div className='d-flex justify-content-between align-items-center cursor-pointer' onClick={() => setIsPaymentTypeModalOpen(true)}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    {
                                        selectedPaymentOption &&
                                        <div className='IconContentWrapper'>
                                            <div className='IconWrapper'>
                                                {selectedPaymentOption.icon}
                                            </div>
                                            <h6>
                                                {selectedPaymentOption.label}
                                            </h6>
                                        </div>
                                        // :
                                        // <>
                                        //     <div className='me-2'>
                                        //         <SendPaymentIcon />
                                        //     </div>
                                        //     <h6>
                                        //         Send a payment link
                                        //     </h6>
                                        // </>
                                    }

                                </div>
                                <LeftIcon />

                            </div>
                        </div>
                    }
                </div>
            </div >

            <CustomModal
                modalOpen={isPaymentTypeModalOpen}
                setModalOpen={setIsPaymentTypeModalOpen}
                type={"common-width"}
                modaltitle={"Select payment"}
                modaltitleCSS={"discount-title"}
                className={'Custome-Select-Payment-Model'}
                modalBody={
                    <>
                        <div className='payment-type-modal-body'>
                            <div className=''>
                                {/* <span>
                                    Select payment
                                </span>
                                <hr className='my-2' /> */}
                                <div className='SelectPaymentGrid'>
                                    {
                                        paymentTypeOptions.map((item, index) =>
                                            <div className={`SelectPaymentItem`} onClick={() => {
                                                if (item.isSelectable()) {
                                                    setSelectedPaymentOption(item);
                                                    setIsPaymentTypeModalOpen(false)
                                                }
                                            }}>
                                                <div className='SelectPaymentImgContent'>
                                                    <div className='IconImg'>
                                                        {item.icon}
                                                    </div>
                                                    <h6>
                                                        {item.label}
                                                    </h6>
                                                </div>
                                                <LeftIcon />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className='mt-3'>
                                <span>
                                    Unavailable options
                                </span>
                                <hr className='my-2' />
                                <div className='SelectPaymentGrid disabled-tab' onClick={() => alert("")}>
                                    <div className='SelectPaymentItem'>
                                        <div className='SelectPaymentImgContent'>
                                            <div className='IconImg'>
                                                <SendPaymentIcon />
                                            </div>
                                            <h6>
                                                Card on file
                                            </h6>
                                        </div>
                                    </div>
                                    {/* <LeftIcon /> */}

                                </div>
                            </div>
                        </div>
                    </>
                }
            />

            {/* EDIT DISCOUNT MODAL */}
            <CustomModal
                modalOpen={isDiscountPriceOpen}
                setIsOpenMobileCalendar={() => setIsDiscountPriceOpen(false)}
                setModalOpen={setIsDiscountPriceOpen}
                type={"common-width"}
                modaltitle={"Discount"}
                modaltitleCSS={"discount-title"}
                className={'customeDiscount'}
                modalBody={
                    <div className="discount-price-container">
                        <div className="discount-price-content">
                            {/* <h1>Discount</h1> */}
                        </div>
                        <div className="tab-container">
                            <div
                                className={active === "percent" ? "active" : "inactive"}
                                onClick={() => {
                                    handleChangeActive("percent")
                                    setIsDiscountError("")
                                    setPoundDiscount(0)
                                }}
                            >
                                <h1>Percent</h1>
                            </div>
                            <div
                                className={active === "pound" ? "active" : "inactive"}
                                onClick={() => {
                                    handleChangeActive("pound")
                                    setIsDiscountError("")
                                    setPrecentDiscount(0)
                                }}
                            >
                                <h1>Fixed</h1>
                            </div>
                        </div>
                        {active === "percent" && (
                            <div className="discount-percent-input-container">
                                <img src={Percent} alt="suffix" />
                                <input type="text" placeholder="0.00" value={percentDiscount}
                                    onFocus={() => {
                                        if (percentDiscount === 0) {
                                            setPrecentDiscount("")
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (!isNumber(e.target.value))
                                            setIsDiscountError("Invalid value");
                                        else if (checkIsValidValue(parseFloat(percentageToValueConverter(e.target.value)).toFixed(2), deposit)) {
                                            setIsDiscountError("");
                                        }
                                        else {
                                            setIsDiscountError("Discount amount can not be greater than total amount");
                                        }
                                        setPrecentDiscount(e.target.value)

                                    }}
                                />
                            </div>
                        )}
                        <div className='errorWrapper'>
                            {active === "pound" && (
                                <div className="discount-pound-input-container">
                                    <img src={Suffix} alt="suffix" />
                                    <input type="text" placeholder="0.00" value={poundDiscount}
                                        onFocus={() => {
                                            if (poundDiscount === 0) {
                                                setPoundDiscount("")
                                            }
                                        }}
                                        onChange={(e) => {
                                            // setPoundDiscount(e.target.value)
                                            if (!isNumber(e.target.value))
                                                setIsDiscountError("Invalid value");
                                            else if (checkIsValidValue(e.target.value, deposit)) {
                                                setIsDiscountError("");
                                            }
                                            else {
                                                setIsDiscountError("Discount amount can not be greater than total amount");
                                            }
                                            setPoundDiscount(e.target.value)
                                        }
                                        } />
                                </div>
                            )}
                            <span className='error-class'>
                                {isDiscountError && isDiscountError}
                            </span>
                        </div>

                        <div className="mt-4">
                            <StepButton label={"Save"} blue={true}
                                disabled={isDiscountError !== "" || (active == "percent" && percentDiscount === "") || (active == "pound" && poundDiscount === "")}
                                onClick={() => {
                                    if (active == "percent") {
                                        setDiscount(percentDiscount);
                                        setDiscountCodeType("percentage")
                                        setDueAmount(subtotalSave - (subtotalSave * percentDiscount) / 100)
                                    } else {
                                        setDiscount(poundDiscount);
                                        setDiscountCodeType("pound")
                                        setDueAmount(subtotalSave - poundDiscount)
                                    }
                                    setIsDiscountPriceOpen(false)
                                }} />
                        </div>
                    </div>
                }
            />

            {/* EDIT DEPOSIT MODAL */}
            <CustomModal
                modalOpen={isEditPriceOpen}
                setIsOpenMobileCalendar={() => setIsEditPriceOpen(false)}
                setModalOpen={setIsEditPriceOpen}
                type={"common-width"}
                modaltitle={"Edit Deposit"}
                className={'customeDiscount'}
                modaltitleCSS={"discount-title"}
                modalBody={
                    <div className="edit-price-container">
                        <div className="edit-price-content">
                        </div>
                        <div className="edit-price-input-container">
                            <img src={Suffix} alt="suffix" />
                            <input type="text" placeholder="0.00" onFocus={() => {
                                if (depositPrice === 0) {
                                    setDepositPrice("")
                                }
                            }} onChange={(e) => {
                                if (!isNumber(e.target.value))
                                    setIsDepositError("Invalid value")
                                else if (parseFloat(e.target.value) < 1.20) {
                                    setIsDepositError("Deposit must be greater than 1.20 in order to request it online")
                                }
                                else if (checkIsValidValue(e.target.value, discountCodeType == "percentage" ? parseFloat(subtotalSave) * parseFloat(discount) / 100 : discount)) {
                                    setIsDepositError("")
                                }
                                else {
                                    setIsDepositError("Deposit amount can not be greater than total amount")
                                }
                                setDepositPrice(e.target.value)
                            }

                            } value={depositPrice} />
                        </div>
                        <span className='error-class'>
                            {isDepositError && isDepositError}
                        </span>
                        <div className="mt-4">
                            <StepButton label={"Save"} disabled={isDepositError !== "" || depositPrice == ""} blue={true} onClick={() => {
                                setDeposit(parseFloat(depositPrice));
                                setIsEditPriceOpen(false)
                                setDueAmount(subtotalSave - (discountCodeType == "percentage" ? ((subtotalSave * discount) / 100) : discount) - parseFloat(depositPrice))
                            }} />
                        </div>
                    </div>
                }
            />

            {/* EDIT PTREATMENT PRICE */}
            <CustomModal

                modalOpen={isEditTreatmentPriceOpen}
                setIsOpenMobileCalendar={() => { setIsEditTreatmentPriceOpen(false) }}
                setModalOpen={setIsEditTreatmentPriceOpen}
                type={"common-width"}
                modaltitle={"Edit Price"}
                className={'customeDiscount'}
                modaltitleCSS={"discount-title"}
                modalBody={
                    <div className="edit-price-container">
                        <div className="edit-price-content">
                            {/* <h1>Edit Price</h1> */}
                            <p>
                                Set a unique price for a specific session. For regular service
                                pricing <span
                                    onClick={() => { window.location.href = webBaseUrl + "/treatment/edit/" + treatmentEditPriceIndex }}
                                >click here.</span>{" "}
                            </p>
                        </div>
                        <div className="edit-price-input-container">
                            <img src={Suffix} alt="suffix" />
                            <input type="text" placeholder="0"
                                onFocus={() => {
                                    if (treatmentPriceTemp === 0) {
                                        setTreatmentPriceTemp("")
                                    }
                                }}
                                onChange={(e) => {
                                    if (!isNumber(e.target.value))
                                        setIsTreatmentPriceEditError("Invalid value")
                                    else if (Number(e.target.value) < 0) {
                                        setIsTreatmentPriceEditError("Price can not be negative")
                                    }
                                    else if (calculateDueAmount(subtotalSave + (Number(e.target.value) - treatmentEditPriceAmount)) < 0) {
                                        setIsTreatmentPriceEditError("price amount is not valid as it is affecting due amount for it's negative value")
                                    } else {
                                        setIsTreatmentPriceEditError("")
                                    }
                                    setTreatmentPriceTemp(e.target.value)
                                }

                                } value={treatmentPriceTemp}
                            // onKeyPress={handleKeyPress}
                            />
                        </div>
                        <span className='error-class'>
                            {isTreatmentPriceEditError && isTreatmentPriceEditError}
                        </span>
                        <div className="mt-4">
                            <StepButton label={"Save"} disabled={isTreatmentPriceEditError !== ""} blue={true} onClick={() => {
                                if (isTreatmentPriceEditError == "") {
                                    updateTreatmentPrice(treatmentEditPriceIndex, treatmentPriceTemp);
                                    setIsEditTreatmentPriceOpen(false)
                                }
                            }} />
                        </div>
                    </div>
                }
            />

            {/* OFFER PAY MONTHLY LEARN MODAL */}
            <CustomModal

                modalOpen={isOfferPayMonthlyLearnOpen}
                setModalOpen={setIsOfferPayMonthlyLearnOpen}
                type={"no-header"}
                modaltitleCSS={"discount-title"}
                className="CustomeThankyouModel"
                modalBody={
                    <>
                        <div className="offer-pay-monthly-learn-modal-main-div">
                            <div className="info">
                                <h1>
                                    i
                                </h1>
                            </div>
                            <p>
                                Thank you for your interest in offering the 'Buy Now, Pay Later'. Yours is currently not active. However your request is currently under review by our team. We will reach out to you as soon as the decision is made, so you can begin monetising this amazing service.
                            </p>
                            <StepButton label={"OK"} blue={true} className={"w-50 m-auto"}
                                onClick={() => setIsOfferPayMonthlyLearnOpen(false)} />
                        </div>
                    </>
                }
            />


            {/* popup when creating appointment for unavailable dates */}
            <CustomModal
                modalOpen={isAvailableForWorkingTemp}
                setModalOpen={setIsAvailableForWorkingTemp}
                type={"common-width"}
                className={'customeAvailableModel'}

                modalBody={
                    <>
                        <div className="share-booking-link-all-clients-confimration">
                            <p>
                                You are not working on selected date, are you sure you want to create this appointment?
                            </p>
                            <div className="btnWrapper">
                                <StepButton label={"Yes"} onClick={() => {
                                    // handleAllclientsConfirmation()
                                    confirmRebookingApiCall()
                                }} blue={true} />
                                <StepButton gray={true} label={"No"} onClick={() => setIsAvailableForWorkingTemp(false)} />
                            </div>
                        </div>

                    </>
                }
            />
        </>
    )
}

export default ConfirmRebooking