import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const CANCLE_APPOINTMENT_MUTATION = gql`
mutation CancelAppointment($appointmentId: Int!) {
    cancelAppointment(appointmentId: $appointmentId) {
      success
      message
    }
  }
`;

export const CancleAppointmentMutation = () => {
  const [cancleAppointmentRequest, cancleAppointmentResponseData] =
    useMutation(CANCLE_APPOINTMENT_MUTATION);
  const dispatch = useDispatch();
  const cancleAppointmentMutationData = cancleAppointmentResponseData;

  const initiateCancleAppointmentResponseData = (
    data,
    successCallback,
    errorCallback
  ) => {
    cancleAppointmentRequest({
      variables: data,
    })
      .then(({ data: { cancelAppointment: responseData } }) => {
        if (responseData) {
          console.log("hold duration", responseData.success)
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateCancleAppointmentResponseData, cancleAppointmentMutationData };
};
