import React, { useEffect, useState } from "react";
import Password from "./Password/Password";
import Notification from "./notification/Notification";
import ActiveSessions from "./ActiveSessions/ActiveSessions";
import BusinessNavigation from "../../../common/businessNavigation/BusinessNavigation";
import { ReactComponent as LockIcon } from "../../../../images/sprint6/lock.svg";
import { ReactComponent as SecurityIcon } from "../../../../images/sprint6/security.svg";
import { ReactComponent as NotifyIcon } from "../../../../images/sprint6/notify.svg";
import { ReactComponent as FlagIcon } from "../../../../images/sprint6/flag.svg";
import { ReactComponent as RightArrowIcon } from "../../../../images/sprint6/rightIcon.svg";
import { ReactComponent as GoogleIcon } from "../../../../images/sprint6/google.svg";
import { ReactComponent as AppleIcon } from "../../../../images/sprint6/apple.svg";
import { ReactComponent as FacebookIcon } from "../../../../images/sprint6/facebook.svg";
import { ManageNotificationSettings } from "../../../redux/actions/customiseNotification/postcustomiseNotification";
import "./Security.scss";
import Clientpassword from "./Clientpassword/Clientpassword";

import { toast } from "react-toastify";
import BusinessSettingHeader from "../../../common/businessSettingHeader/BusinessSettingHeader";
import BusinessSidebar from "../../../sidebar/BusinessSidebar";
import { useNavigate } from "react-router";
import { getBusinessSettingPageRoute } from "../../../../Routes";
import { useVerifyAndUpdatePassword } from "../../../redux/actions/verifypassword/verifypassword";
import { GetUpdatedStatusClientDirectory } from "../../../redux/actions/passwordprotection/UpdateStatusOfAccessibilityOfCientDirectory";
import SidebarComponent from "../../../sidebar";
import BusinessHeader from "../../../businessHeader";
import Cookies from "js-cookie";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import logo from "../../../../images/icons/faces-logo-black.svg";
import closeIcon from "../../../../images/Icon example.svg";



const Security = ({ setCurrentPage, currentPage }) => {
  const { verifyAndUpdatePasswordResponseData, initVerifyAndUpdatePassword } = useVerifyAndUpdatePassword();
  const [step, setStep] = useState(0);
  const { initiateManageNotificationSettings, manageNotificationSettingsMutationData } = ManageNotificationSettings();
  const { width, height } = useWindowDimensions();
  const [showSidebar, setShowSidebar] = useState(true);
  const [activeSidebar, setActiveSidebar] = useState(false);
  // const [isAuth, setIsAuth] = useState(false);
  let isMobile = false;
  if (width <= 991) {
      isMobile = true;
  }
  else {
      isMobile = false;
  }
  useEffect(() => {
      isMobile ? setShowSidebar(false) : setShowSidebar(true);
  }, [isMobile]);

  // useEffect(() => {
  //     console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
  //     Cookies.get("faces_access_token") ? setIsAuth(true) : setIsAuth(false);
  //     console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
  // });


  const {
    getAccessOfUpdatedClientDirectoryData,
    initGetUpdatedAccessOfClientDirectoryData,
  } = GetUpdatedStatusClientDirectory()
  const navigate = useNavigate();

  const renderTabContent = () => {
    if (step == "1") {
      return (
        // toast.error(err?.data?.verifyAndUpdateCurrentPassword?.message)
        <Password
          onClickSubmit={(props) => {
            console.log(props, "props")
            initVerifyAndUpdatePassword({
              oldPassword: props.Oldpassword,
              newPassword: props.Newpassword,
              confirmPassword: props.ConfirmNewPassword
            }, (res) => {

              toast.success(res?.data?.verifyAndUpdateCurrentPassword?.message)
              window.location.reload();
            }, (err) => {

              toast.error(err?.data?.verifyAndUpdateCurrentPassword?.message)
            })

            // setStep(2);


          }}
          setStep={setStep}
        />
      );
    }
    if (step == "2") {
      return (
        <Clientpassword
          onClickSubmit={onSubmit}
          setStep={setStep}
        />
      );
    }
    if (step == "3") {
      return (
        <Notification
          // onClickSubmit={(emailData, prescriptionemailData, socialMediaData) => {
          //   console.log(emailData, prescriptionemailData, socialMediaData, "inside notification clicked")
          //   // setStep(4);
          //   console.log(socialMediaData[0]?.select, "clicked")

          //   initiateManageNotificationSettings({

          //     settings: [
          //       // for email
          //       {
          //         "value": emailData[0]?.select ? emailData[0]?.select : false,
          //         "name": "send_insurance_request_email"
          //       },
          //       {
          //         "value": emailData[1]?.select ? emailData[1]?.select : false,
          //         "name": "deposit_complete_email"
          //       },
          //       {
          //         "value": emailData[2]?.select ? emailData[2]?.select : false,
          //         "name": "consent_form_filled_by_client"
          //       },
          //       {
          //         "value": emailData[3]?.select ? emailData[3]?.select : false,
          //         "name": "personal_event_reminder_email"
          //       },
          //       {
          //         "value": emailData[4]?.select ? emailData[4]?.select : false,
          //         "name": "client_booking_email"
          //       },
          //       {
          //         "value": emailData[5]?.select ? emailData[5]?.select : false,
          //         "name": "finance_requests_from_clients"
          //       },
          //       // for social media
          //       {
          //         "value": socialMediaData[0]?.select ? socialMediaData[0]?.select : false,
          //         "name": "sms"
          //       },
          //       {
          //         "value": socialMediaData[1]?.select ? socialMediaData[1]?.select : false,
          //         "name": "whats_app"
          //       },
          //       {
          //         "value": socialMediaData[2]?.select ? socialMediaData[2]?.select : false,
          //         "name": "email"
          //       },
          //       {
          //         "value": socialMediaData[3]?.select ? socialMediaData[3]?.select : false,
          //         "name": "phone"
          //       },
          //       // prescriber email
          //       {
          //         "value": prescriptionemailData[0]?.select ? prescriptionemailData[0]?.select : false,
          //         "name": "new_connect_request"
          //       },
          //       {
          //         "value": prescriptionemailData[1]?.select ? prescriptionemailData[1]?.select : false,
          //         "name": "new_prescription_request"
          //       },
          //       {
          //         "value": prescriptionemailData[2]?.select ? prescriptionemailData[2]?.select : false,
          //         "name": "prescription_accepted_email"
          //       },
          //       {
          //         "value": prescriptionemailData[3]?.select ? prescriptionemailData[3]?.select : false,
          //         "name": "prescription_rejected_email"
          //       },
          //       {
          //         "value": prescriptionemailData[4]?.select ? prescriptionemailData[4]?.select : false,
          //         "name": "prescription_fees_paid_email"
          //       },
          //       {
          //         "value": prescriptionemailData[5]?.select ? prescriptionemailData[5]?.select : false,
          //         "name": "new_practitioner_connected"
          //       },
          //       {
          //         "value": prescriptionemailData[6]?.select ? prescriptionemailData[6]?.select : false,
          //         "name": "prescriber_review_submitted"
          //       },
          //       {
          //         "value": prescriptionemailData[7]?.select ? prescriptionemailData[7]?.select : false,
          //         "name": "help_email"
          //       }
          //     ]


          //   }, (res) => { console.log(res) }, (err) => { console.log(err) })
          // }}
          setStep={setStep}
          onClickSubmit={(emailData, prescriptionemailData, socialMediaData) => {
            console.log(emailData, prescriptionemailData, socialMediaData, "inside notification clicked");

            // Define settings categories and corresponding data
            const emailSettings = [
              { name: "send_insurance_request_email", data: emailData[0] },
              { name: "deposit_complete_email", data: emailData[1] },
              { name: "consent_form_filled_by_client", data: emailData[2] },
              { name: "personal_event_reminder_email", data: emailData[3] },
              { name: "client_booking_email", data: emailData[4] },
              { name: "finance_requests_from_clients", data: emailData[5] },
            ];

            const socialMediaSettings = [
              { name: "sms", data: socialMediaData[0] },
              { name: "whats_app", data: socialMediaData[1] },
              { name: "email", data: socialMediaData[2] },
              { name: "phone", data: socialMediaData[3] },
            ];

            const prescriberEmailSettings = [
              { name: "new_connect_request", data: prescriptionemailData[0] },
              { name: "new_prescription_request", data: prescriptionemailData[1] },
              { name: "prescription_accepted_email", data: prescriptionemailData[2] },
              { name: "prescription_rejected_email", data: prescriptionemailData[3] },
              { name: "prescription_fees_paid_email", data: prescriptionemailData[4] },
              { name: "new_practitioner_connected", data: prescriptionemailData[5] },
              { name: "prescriber_review_submitted", data: prescriptionemailData[6] },
              { name: "help_email", data: prescriptionemailData[7] },
            ];

            // Combine and map all settings
            const settings = [
              ...emailSettings,
              ...socialMediaSettings,
              ...prescriberEmailSettings,
            ].map(({ name, data }) => ({
              value: data?.select || false,
              name,
            }));

            console.log(socialMediaData[0]?.select, "clicked");

            initiateManageNotificationSettings(
              { settings },
              (res) => { toast.success(res?.message); setStep(0); },
              (err) => { console.log(err); }
            );
          }}

        // setStep={setStep}
        />
      );
    }
    if (step == "4") {
      return (
        <ActiveSessions
          onClickSubmit={() => {
            setStep(0);
          }}
          setStep={setStep}
        />
      );
    }
  };

  const socialConnect = [
    {
      id: 1,
      name: "Google",
      Icon: <GoogleIcon />,
      status: "Not connected",
    },
    {
      id: 2,
      name: "Apple",
      Icon: <AppleIcon />,
      status: "Connected",
    },
    {
      id: 3,
      name: "Facebook",
      Icon: <FacebookIcon />,
      status: "Not connected",
    },
  ];
  const onSubmit = (props) => {
    console.log(props, "submitclicked")
    initGetUpdatedAccessOfClientDirectoryData({ accessStatus: props ? true : false }, (res) => { setStep(0); }, (err) => { })

    // setStep(3);

  }
  return (
    <div className="MainBusinessProfile">
      <BusinessHeader
        // openReveviewPopup={setOpenReviewPopup}
        openMobileMenu={() => {
            setShowSidebar(!showSidebar);
            setActiveSidebar(true);
            document.body.classList.add('sidebarOpen');
        }}
        isMobile={isMobile}
      />
      <div className="Business-Profile-Main-Wrapper container-fluid">
        <div className="sidebarDesignUpdateSprint6">
          {isMobile ? (
            <div
              className={
                showSidebar ? " MobileSidebar  ActiveSidebar" : "MobileSidebar"
              }
            >
              {activeSidebar && (
                <>
                  <div className="overlaySidebar"></div>
                  <div className="sidebar">
                    <div className="sidebarLogoWrapper">
                      <div className="logoSidebar">
                        <img src={logo} alt="logo" />
                      </div>
                      <div
                        className="sidebarClose"
                        onClick={() => {
                          setShowSidebar(false);
                          document.body.classList.remove('sidebarOpen');
                        }}
                      >
                        <img src={closeIcon} alt="closeIcon" />
                      </div>
                    </div>
                    <SidebarComponent />
                  </div>
                </>
              )}
            </div>
          ) : (
            // isAuth &&
            <div className="sidebarPart">
              <div className="sidebar">
                <SidebarComponent />
              </div>
            </div>
          )}
          <div className={"MainContentPart"}>
            <div className="MainSecurityPages">
              {step > 0 ? (
                renderTabContent()
              ) : (
                <div className="Main684WidthSet">
                  <BusinessNavigation
                    isColumn={true}
                    title={"Security and privacy"}
                    desc={"updated your privacy settings"}
                    onBackClick={() => {
                      navigate(getBusinessSettingPageRoute);
                    }}
                  />
                  <div className="linkGrid">
                    <div
                      className="linkItem"
                      onClick={() => {
                        setStep(1);
                      }}
                    >
                      <div className="linkImg">
                        <LockIcon />
                      </div>
                      <div className="linkContent">
                        <h3>Update password</h3>
                        <p>Change your account password.</p>
                      </div>
                      <div className="RightIcon">
                        <RightArrowIcon />
                      </div>
                    </div>

                    <div
                      className="linkItem"
                      onClick={() => {
                        setStep(2);
                      }}
                    >
                      <div className="linkImg">
                        <SecurityIcon />
                      </div>
                      <div className="linkContent">
                        <h3>Client directory password</h3>
                        <p>Activate to access client details.</p>
                      </div>
                      <div className="RightIcon">
                        <RightArrowIcon />
                      </div>
                    </div>

                    <div
                      className="linkItem"
                      onClick={() => {
                        setStep(3);
                      }}
                    >
                      <div className="linkImg">
                        <NotifyIcon />
                      </div>
                      <div className="linkContent">
                        <h3>Notifications</h3>
                        <p>Keep clients updated via email.</p>
                      </div>
                      <div className="RightIcon">
                        <RightArrowIcon />
                      </div>
                    </div>

                    <div
                      className="linkItem"
                      onClick={() => {
                        setStep(4);
                      }}
                    >
                      <div className="linkImg">
                        <FlagIcon />
                      </div>
                      <div className="linkContent">
                        <h3>Login history</h3>
                        <p>Check your session records.</p>
                      </div>
                      <div className="RightIcon">
                        <RightArrowIcon />
                      </div>
                    </div>
                  </div>

                  {/* // Social Media */}
                  {/* <div className="socialWrapper">
            <div className="TagLine">
              <p>Social accounts</p>
            </div>
            <div className="socialGrid">
              {socialConnect.map((item, index) => {
                return (
                  <div className="socialItem" key={index}>
                    <div className="socialImg">{item?.Icon}</div>
                    <div className="socialContent">
                      <h3>{item?.name}</h3>
                      <p>{item?.status}</p>
                    </div>
                    <div className="socialConnect">
                      <button type="btn" className="ConnectBtn">
                        Connect
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}
                </div>
              )}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Security;
