import { RESEND_FORWARD_CONSENT_FORM, RESEND_FORWARD_CONSENT_FORM_SUCCESS, RESEND_FORWARD_CONSENT_FORM_ERROR, CLEAR_FORM_API_CALLED } from "../../constants"

export default function resendForwardConsentForm(data) {

    return {
        type: RESEND_FORWARD_CONSENT_FORM,
        payload: data
    }
}

export function resendForwardConsentFormSuccess(data) {

    return {
        type: RESEND_FORWARD_CONSENT_FORM_SUCCESS,
        payload: data
    }
}

export function resendForwardConsentFormError(data) {

    return {
        type: RESEND_FORWARD_CONSENT_FORM_ERROR,
        payload: data
    }
}

export function clearFormApiCalled() {

    return {
        type: CLEAR_FORM_API_CALLED,
    }
}
