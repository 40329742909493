import { CLEAR_CLIENT_LIST, CLEAR_CLIENT_LIST_DATA, GET_CLIENTS_DATA, GET_CLIENTS_LIST_DATA } from "../../constants";

const initialState = {
  clientList: [],
  totalClientCount: 0,
  loadedClientCount: 0,
  clientListPage: 0,
  emptyClientList: false
}

export default function clientListReducer(state = initialState, action) {
  console.log("acction",action)
  switch (action.type) {

    case GET_CLIENTS_LIST_DATA:

      let temp = (state?.clientList).concat(action?.payload?.clients)
      console.log("data akash temp", temp.length)
      return {
        ...state,
        emptyClientList: action.payload?.clients?.length === 0 || action.payload == null ? true : false,
        clientList: temp,
        totalClientCount: action.payload?.total,
        clientListPage: state.clientListPage + 1,
        loadedClientCount: temp.length
      };

    case CLEAR_CLIENT_LIST_DATA:
      return {
        ...state,
        emptyClientList: false,
        clientList: [],
        totalClientCount: 0,
        clientListPage: 0,
        loadedClientCount: 0
      };

    default:
      return state;
  }



}