import React, { useEffect, useState } from "react";
import "./SendTextSms.scss";
import StepButton from "../../../../../common/StepButton";
import { ReactComponent as RightIcon } from "../../../../../../images/calander_next.svg";
import OffCanvasModal from "../../../../../modal/OffCanvasModal/OffCanvasModal";
import CustomRadioButton from "../../../../../common/CustomRadioButton";
// import { ReactComponent as ClientIcon } from "../../../../../../images/client.svg";
import { toast } from "react-toastify";
import {ReactComponent as LocationIcon} from "../../../../../../images/business-setting-icon/location.svg"
import {ReactComponent as Defaultavatar} from "../../../../../../../src/assets/prescriber-registration/Circle_Button.svg"
import BusinessNavigation from "../../../../../common/businessNavigation/BusinessNavigation";
import { GetClientListAction } from "../../../../../redux/actions/client-text-sms/getclientList";
import { GetClinicLocationsAction } from "../../../../../redux/actions/client-text-sms/getclinicList";
import { SendMarketingSMS } from "../../../../../redux/actions/client-text-sms/SendMessageMarketingSMS";
const CheckboxComponent = ({ label, isChecked, onChange }) => (
  <div>
    <label className="radio-label input-checkbox">
      <div className="client-name-container">
        <div className="leftRadioIcon">
          <Defaultavatar />
        </div>
        <h3>{label}</h3>
      </div>
      <input type="checkbox" checked={isChecked} onChange={onChange} />
    </label>
  </div>
);

function SendTextSms({ setStep }) {
  const [isclinics, setIsclinics] = useState(false);
  const [isselectClient, setIsselectClient] = useState(false);
  const [isviewSelectClient, setIsviewSelectClient] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [messageText, setMessageText] = useState();
  const [clientList, setclientList] = useState([]);
  const [clinicLocation, setclinicLocation] = useState([])
  const [clinicName, setClinicname] = useState()
  const [CheckedClients, setCheckedClients] = useState([]);
  const [totalSelctedclients, settotalSelectedclients] = useState(0)
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [clientTitlename, setclientTitlename] = useState()
  const [error, seterror] = useState(false)
  const { initGetClientList } = GetClientListAction();
  const { initGetClinicLocations } = GetClinicLocationsAction();
  const { initiateSendMarketingSMS } = SendMarketingSMS();


  useEffect(() => {
    initGetClientList({
      "selectedClinicForClient": [],
      "requestFrom": "marketing-sms"
    }, (res) => { setclientList(res?.data?.clients) }, (err) => { console.log(err) })
    initGetClinicLocations({}, (res) => { setclinicLocation(res?.data?.items) }, (err) => { console.log(err) })
  }, [])

  // console.log(messageText.length,"messageText")
  // console.log(clientTitlename,">>.>>>>")
  const [checkboxes, setCheckboxes] = useState([]);

  // const handleCheckboxChange = (id) => {
  //   const updatedCheckboxes = clientList?.map((checkbox) =>
  //     checkbox?.clentId === id
  //       ? { ...checkbox, isChecked: !checkbox.isChecked }
  //       : checkbox
  //   );
  //   const newCheckedClients = updatedCheckboxes.filter((client) => client.isChecked);
  //   setCheckedClients(newCheckedClients);
  //   // console.log(newCheckedClients,"1010")
  //   // setCheckboxes(updatedCheckboxes);
  //   setSelectAllChecked(
  //     updatedCheckboxes.every((checkbox) => checkbox.isChecked)
  //   );
  // };
  // CheckedClients?.length?CheckedClients?.length:0

  console.log(CheckedClients, "selectedIndex")
  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = clientList?.map((client) =>
      client.clentId === id
        ? { ...client, isChecked: !client.isChecked }
        : client
    );

    setclientList(updatedCheckboxes);

    const newCheckedClients = updatedCheckboxes.filter((client) => client?.isChecked);
    setCheckedClients(newCheckedClients);
    setSelectAllChecked(
      updatedCheckboxes?.every((client) => client?.isChecked)
    );
  };
  useEffect(() => { settotalSelectedclients(CheckedClients?.length ? CheckedClients?.length : 0) }, [CheckedClients])

  console.log(clinicLocation, "clinicLocation")
  const saveclientData = () => {
    // setIsclinics(false);
    // const ids = clinicLocation?.map((client) => client?.clentId);



    // selectedIndex !== -1 ? 
    // initGetClientList(
    //   {
    //     selectedClinicForClient: [selectedIndex],
    //     requestFrom: "marketing-sms"
    //   },
    //   (res) => {
    //     setclientList(res?.data?.clients);
    //     setIsclinics(false);
    //   },
    //   (err) => {
    //     // handle error
    //   }
    // ),
    // const clinicName =   clinicLocation?.filter(client => client?.clientId === selectedIndex)
    // :setIsclinics(false);

    if (selectedIndex !== -1) {
      initGetClientList(
        {
          selectedClinicForClient: [selectedIndex],
          requestFrom: "marketing-sms"
        },
        (res) => {
          setclientList(res?.data?.clients);
          setIsclinics(false);
        },
        (err) => {
          // handle error
          console.log(err)
        }
      );

      const clinic = clinicLocation?.find(client => client?.clinicId === selectedIndex);
      setClinicname(clinic?.clinicName)
      // clinicName = clinic ? clinic.name : '';
      // console.log(clinic?.clinicName,"1010")
    } else {
      setIsclinics(false);
    }


    // const clientIds = selectedIndex !== -1 
    // ? (const clinicname =   clinicLocation?.filter(client => client?.clientId === selectedIndex))



    // : "";

  }

  const sendMarketingMessage = () => {

    if (!messageText) {
      seterror(true)
    }
    else {

      const filteredClientIds = CheckedClients?.filter((ele) => ele?.clentId)?.map((ele) => ele.clentId);
      initiateSendMarketingSMS({
        "smsContent": messageText,
        "clientIds": filteredClientIds
      }, (res) => { res?.success ? ((toast.success(res?.message))(setStep(0))) : toast.error(res?.message) }, (err) => { console.log(err, "err") })
    }
  }



  // useEffect(() => {console.log(CheckedClients,"CheckedClientsCheckedClients")},[CheckedClients])


  const handleSelectAllChange = () => {
    const updatedCheckboxes = clientList?.map((checkbox) => ({
      ...checkbox,
      isChecked: !selectAllChecked,
    }));


    // setCheckboxes(updatedCheckboxes);
    const newCheckedClients = updatedCheckboxes?.filter((client) => client?.isChecked);
    setclientList(updatedCheckboxes);
    setCheckedClients(newCheckedClients);
    setSelectAllChecked(!selectAllChecked);
  };




  return (
    <>
      <div className="Main-Send-Message ">
        <div className="rightSidebarWrapper">
          <div className="Main684WidthSet">
            <BusinessNavigation
              isColumn={true}
              title={"Send a text sms"}
              desc={"Send a marketing text message to all your clients and get a 30% better conversion rate than email"}
              onBackClick={() => {
                setStep(0);
              }}
            />
            <div className="TopButtonWrapper">
              <div
                className="selectButton"
                onClick={() => {
                  setIsclinics(true);
                }}
              >
                <h6>   {clinicName === undefined ? "Select clinic" : clinicName}</h6>
                <RightIcon />
              </div>
              <div
                className="selectButton"
                onClick={() => {
                  setIsselectClient(true);
                }}
              >
                <h6>   {!clientTitlename ? "Select client" : (CheckedClients.length > 1 ? `${clientTitlename} +${CheckedClients.length-1}`    : clientTitlename)}</h6>
                <RightIcon />
              </div>
              <div className="MessageBox">
                <p className="form-label">Message</p>
                <textarea
                  value={messageText}
                  onChange={(e) => {
                    setMessageText(e.target.value);
                    seterror(false);
                  }}
                  placeholder="Add a description..."
                />
                {error && <span className="sms_error"> Cant send empty message</span>}
                <div className="sendButton">
                  <StepButton label={"Send"} onClick={sendMarketingMessage} />
                </div>
              </div>
            </div>
          </div>

          <div className="RightOverView">
            <div className="overHeading">
              <h5>Overview</h5>
            </div>
            <div className="Content">
              <p>
                Sending text reminders and any other messages to your clients is
                simple. Just purchase the amount of texts you need, and select
                the clients you want to text.{" "}
              </p>
              <p>
                In case the message character length is greater than 160, the
                message will be sent in multiple parts.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Select clinics */}
      <OffCanvasModal
        isOpen={isclinics}
        className={"SmsCanvas"}
        isBack={true}
        placement={"end"}
        isCloseRight={true}
        onHide={() => setIsclinics(false)}
        // title=""
        body={
          <div className="SmsCanvasBody">
            <h5>Select clinics</h5>

            <div className="smsGrid">
              <div className="smsItem">
                <div className=""></div>
                {clinicLocation?.map((item, index) => (

                  <CustomRadioButton
                    name="client"
                    id={item?.clinicId}
                    value={item?.clinicName}
                    checked={item?.clinicId === selectedIndex}
                    onChange={() => setSelectedIndex(item?.clinicId)}
                    isblacked={true}
                    customLabel={
                      <>
                        <div
                          className="client-left-container cursor-pointer"
                        // onClick={() => setSelectedIndex(item?.clentId)}
                        >
                          <div className="client-name-container">
                            <div className="leftRadioIcon">
                              <LocationIcon />
                            </div>
                            <h3>{item?.clinicName}</h3>
                          </div>
                        </div>
                      </>
                    }
                  />
                ))}
              </div>

              <StepButton
                label="Save"
                onClick={() => {
                  saveclientData();
                  // setIsclinics(false);
                }}
              />
            </div>
          </div>
        }
      />

      {/* Select client */}
      <OffCanvasModal
        isOpen={isselectClient}
        className={"SmsCanvas selectClientCanvas "}
        isBack={true}
        placement={"end"}
        isCloseRight={true}
        onHide={() => setIsselectClient(false)}
        body={
          <div className="SmsCanvasBody">
            <h5>Select client</h5>
            <div className="smsGrid">
              <div
                className="ViewSelected"
                onClick={() => {
                  setIsviewSelectClient(true);
                }}
              >
                <p>View selected clients ({totalSelctedclients})</p>
              </div>
              <div className="selectAllCheckBox">
                <label>
                  <p>Select All Clients</p>
                  <input
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                  />
                </label>
              </div>
              <div className="smsItem">
                {clientList?.map((checkbox) => (
                  <CheckboxComponent
                    key={checkbox?.clentId}
                    label={checkbox?.clientName}
                    isChecked={checkbox.isChecked}
                    onChange={() => handleCheckboxChange(checkbox?.clentId)}
                  />
                ))}
              </div>
              <StepButton
                label="Save"
                onClick={() => {
                  console.log(CheckedClients?.[0]?.clientName, "10000000")
                  setclientTitlename(CheckedClients?.[0]?.clientName)
                  setIsselectClient(false);
                  //  saveclientData()
                  // initGetClientList({},(res)=> {  setIsselectClient(false);},(err)=>{ console.log(err,"looo")})

                }}
              />
            </div>
          </div>
        }
      />

      {/* View Select client */}
      <OffCanvasModal
        isOpen={isviewSelectClient}
        className={"SmsCanvas selectClientCanvas viewSelectClientCanvas  "}
        isBack={true}
        placement={"end"}
        isCloseRight={true}
        onHide={() => setIsviewSelectClient(false)}
        body={
          <div className="SmsCanvasBody">
            <h5>{totalSelctedclients} Selected clients</h5>
            <div className="smsGrid">
              <div className="selectAllCheckBox">
                <label>
                  <p>{totalSelctedclients} clients select</p>
                  <input
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                  />
                </label>
              </div>
              <div className="smsItem">
                {checkboxes.length > 0 ? (
                  checkboxes.map((checkbox) => (
                    <CheckboxComponent
                      key={checkbox?.clentId}
                      label={checkbox?.clientName}
                      isChecked={checkbox.isChecked}
                      onChange={() => handleCheckboxChange(checkbox?.clentId)}
                    />
                  ))
                ) : (
                  CheckedClients?.map((checkbox) => (
                    <CheckboxComponent
                      key={checkbox?.clentId}
                      label={checkbox?.clientName}
                      isChecked={checkbox.isChecked}
                      onChange={() => handleCheckboxChange(checkbox?.clentId)}
                    />
                  ))
                )}

              </div>
              <StepButton
                label="Save"
                onClick={() => {
                  setIsviewSelectClient(false);
                }}
              />
            </div>
          </div>
        }
      />
    </>
  );
}

export default SendTextSms;
