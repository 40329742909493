import { gql, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

const UPLOAD_INSURANCE_CERTIFICATE = gql`
  mutation UploadInsurenceDocument($insurenceDocument: Upload) {
    uploadInsurenceDocument(insurenceDocument: $insurenceDocument) {
      success
      message
    }
  }
`;

export const UploadInsurenceDocument = () => {
  // const dispatch = useDispatch();
  const [
    UploadInsurenceDocumentResponseFunction,
    uploadInsurenceDocumentResponseData,
  ] = useMutation(UPLOAD_INSURANCE_CERTIFICATE);

  //this will be called from components
  const uploadInsurenceDocumentQueryData = uploadInsurenceDocumentResponseData; //variable

  const initUploadInsurenceDocumentData = (
    data,
    successCallback,
    errorCallback
  ) => {
    UploadInsurenceDocumentResponseFunction({
      variables: data,
    })
      // .then(({ data: { UploadInsurenceDocument: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        if (responseData?.data?.uploadInsurenceDocument?.success) {
          // dispatch(...)
          successCallback(responseData);
          // toast.success(responseData.message)
        } else {
          errorCallback(responseData);
          // toast.error(responseData.message)
        }
      })
      .catch((err) => {
        // errorCallback(err);
        console.log('typeeee:', err);
        toast.error('something went wrong!!!');
      });
  };

  return {
    uploadInsurenceDocumentQueryData,
    initUploadInsurenceDocumentData,
  };
};
