import isSameDay from "date-fns/isSameDay"; // Import isSameDay function from date-fns/isSameDay
import moment from "moment";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import StepButton from "../../../common/StepButton";
import useDeviceDetect from "../../../common/useDeviceDetect";
import "./custom-calendar.scss";
import { set } from "lodash";
import CustomDropdown from "../../../common/dropdown/CustomDropdown";
import CustomModal from "../../../modal/CustomModal";
import CustomRadioButton from "../../../common/CustomRadioButton";
import { weeklyViewDaySevenDateChange } from "../../../../utils/general";
// import CustomDropdown from "../../../auth/revenue-dashboard/CustomDropdown/CustomDropdown";


const CustomCalendar = ({
  setStartDate,
  setEndDate,
  selectedMonth,
  selectedYear,
  setSelectedMonth,
  setSelectedYear,
  dateData,
  showSelectedDate,
  setShowSelectedDate,
  onSelectDate,
  setFormatedDate,
  setStep,
  setShowSelectedTime,
  isOpenMobileCalendar = false,
  setIsOpenMobileCalendar,
  ignoreSelectedDate = false,
  setCalendarStartDate,
  setIsOpenCalendar,
  setViewCalendarState,
  setSelectedWeek = false,
  setSelectedDateForTime = false,
  onChangeCallback = false,
  isPassedDateEnabled = false,
  calendarStartDate,
  viewCalendarState,
  appointmentDatesCount = [],
  fixedPassedDate = false,
  isMonthlyDailyViewCalender = false,
  isStrikeClick = false,
  isStrikeAllow = true,
  isCalendarPreviousDateEnable = false,

  allowMonthYearChange = false,

  setShowCalendar = null,
  isShowMobileButton = true,
  isThreeDaysView = false
}) => {
  // const moment = require("moment");
  const { screenWidth } = useDeviceDetect();
  const [value, setValue] = useState(null);
  const [loader, setLoader] = useState(false);

  const [view, setView] = useState("month");
  const [key, setKey] = useState(0); // Key to force re-render
  const [isOpenYearSelection, setIsOpenYearSelection] = useState(false);
  const [selectedMonthInWords, setSelectedMonthInWords] = useState(
    moment(showSelectedDate, "YYYY-MM-DD").format("MMMM")
  );
  const [shouldRender, setShouldRender] = useState(false);
  const [newActiveStartDate, setNewActiveStartDate] = useState();

  function getStartAndEndDates(month, year) {
    // Create a new Date object with the provided year and month (month is 0-indexed, so we subtract 1)
    const startDate = new Date(year, month - 1, 1);

    // Calculate the last day of the next month (month + 1) and then go back one day (date - 1) to get the end date of the current month
    const endDate = new Date(year, month, 0);

    return {
      startDate,
      endDate,
    };
  }

  // Custom dates for which you want to apply custom color
  const customDates = dateData?.map((date) => new Date(date));
  let appointmentDates = [];
  if (appointmentDatesCount?.length > 0) {
    appointmentDates = appointmentDatesCount.map(
      (date) => date.appointmentDate
    );
  }
  console.log(appointmentDates, "appointmentDates");

  // Function to determine if a date has a custom class
  const tileClassName = ({ date }) => {

    let appointmentCount = false;
    //CHECK IF APPOINTSMENTS ARE AVAILABLE
    if (appointmentDates?.length && appointmentDates.includes(moment(date).format('YYYY-MM-DD'))) {
      appointmentCount = true
    }

    //CHECK IF DATE IS PASSED DATE FROM TODAY OR NOT
    // let strikeNotShow = moment(date).isBefore(moment(new Date()), 'day')

    let strikeNotShow = false

    // Check if the date is in the customDates array
    let startOfWeek;
    let endOfWeek;
    if (viewCalendarState == "timeGridWeek") {
      if (showSelectedDate == null) {
        // startOfWeek = moment(calendarStartDate).clone().startOf('week').add(1, "days");
        // endOfWeek = moment(calendarStartDate).clone().endOf('week').add(1, "days");
        startOfWeek = isThreeDaysView ?
          moment(calendarStartDate) :
          // moment(calendarStartDate).clone().startOf('week').add(1, "days");
          weeklyViewDaySevenDateChange(moment(calendarStartDate), "start")


        endOfWeek = isThreeDaysView ?
          moment(calendarStartDate).clone().add(2, "days") :
          // moment(calendarStartDate).clone().endOf('week').add(1, "days");
          weeklyViewDaySevenDateChange(moment(calendarStartDate), "end")
      }
      else {
        // startOfWeek = moment(showSelectedDate, "dddd, Do MMMM YYYY")
        // endOfWeek = moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().add(6, "days")
        // moment(showSelectedDate, "dddd, Do MMMM YYYY").subtract(1, "year").format("dddd, Do MMMM YYYY")
        startOfWeek = isThreeDaysView ?
          moment(showSelectedDate, "dddd, Do MMMM YYYY") :
          weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "start")
        // moment(showSelectedDate, "dddd, Do MMMM YYYY").isoWeekday() == 7 ? moment(showSelectedDate, "dddd, Do MMMM YYYY").subtract(1, "days").clone().startOf('week').add(1, "days") : moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().startOf('week').add(1, "days");

        endOfWeek = isThreeDaysView ?
          moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().add(2, "days") :
          // moment(showSelectedDate, "dddd, Do MMMM YYYY").isoWeekday() == 7 ? moment(showSelectedDate, "dddd, Do MMMM YYYY").subtract(1, "days").clone().endOf('week').add(1, "days") : moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().endOf('week').add(1, "days")
          weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "end")

      }
      if (moment(date).isSameOrAfter(startOfWeek) && moment(date).isSameOrBefore(endOfWeek)) {
        // console.log("moment(date).isSame(endOfWeek)", (moment(date).isSame(startOfWeek) || moment(date).isSame(endOfWeek)), moment(date).format("DD-MM-YYYY"), moment(endOfWeek).format("DD-MM-YYYY"))
        return `highlighted-week  ${(moment(date).isSame(startOfWeek) || moment(date).format("DD-MM-YYYY") == moment(endOfWeek).format("DD-MM-YYYY")) ? `${moment(date).format("DD-MM-YYYY") == moment(endOfWeek).format("DD-MM-YYYY")} && endOfWeek,  selected-tile ${appointmentCount ? "appoinment-dot" : ""}` : `${appointmentCount ? "appoinment-dot" : ""}`}`;
      }
    }


    // console.log("endOfWeek", endOfWeek)



    if (customDates?.some((customDate) => isSameDay(customDate, date))) {
      if (showSelectedDate) {
        if (JSON.stringify(date) === JSON.stringify(new Date(moment(showSelectedDate, "dddd, Do MMMM YYYY")))) {
          return `custom-tile custom-tile-selected ${appointmentCount ? "appoinment-dot" : ""} `;
        }
      }
      else {
        if (viewCalendarState == "timeGridDay")
          if (JSON.stringify(moment(date).format("DD-MM-YYYY")) === JSON.stringify(moment().format("DD-MM-YYYY"))) {
            return `custom-tile custom-tile-selected ${appointmentCount ? "appoinment-dot" : ""} `;

          }
      }
      return `custom-tile  ${appointmentCount ? "appoinment-dot" : ""} ${isMonthlyDailyViewCalender ? "remove-tile-border" : ""} `;
    }
    // If the date is not in the customDates array, return null to apply default styles
    return `custom-tile-disable ${appointmentCount ? "appoinment-dot" : ""} ${isCalendarPreviousDateEnable ? "enable-past-date" : ""} ${showSelectedDate && JSON.stringify(date) === JSON.stringify(new Date(moment(showSelectedDate, "dddd, Do MMMM YYYY"))) ? "custom-tile-selected" : ""}  ${!strikeNotShow && isStrikeAllow ? `${isStrikeClick ? "click-enable" : ""} add-strike` : ""} `;
  };

  // Function to handle the change of active start date (when month/year changes)
  const handleMonthChange = (date) => {
    console.log(date, "hello viewProps");

    if (date?.action === "drillUp") {
      // setNewActiveStartDate(moment(showSelectedDate, "dddd, Do MMMM YYYY").toDate())
      setNewActiveStartDate(new Date(selectedYear, selectedMonth - 1, 1));
      console.log("hello viewProps success");
      return;
    } else {
      setNewActiveStartDate(new Date(date.activeStartDate));
      const date1 = new Date(date.activeStartDate);
      const monthIndex = date1.getMonth();
      const month = monthIndex + 1; // Convert zero-based index to one-based index
      const year = date1.getFullYear(); // Convert zero-based index to one-based index
      setSelectedMonth(month);
      setSelectedYear(year);
    }
  };
  const handleDateClick = (date) => {
    // setShowSelectedDate(date);
    if (setFormatedDate) setFormatedDate(moment(date).format("Do MMMM YYYY"));
    console.log('hihi date', date);
    if (setCalendarStartDate)
      setCalendarStartDate(moment(date, 'Do MMMM YYYY').format('YYYY-MM-DD'))

    onSelectDate(date);
  };

  const check = (month, year) => {
    const current = new Date();
    if (fixedPassedDate)
      if (
        moment(fixedPassedDate).format("M") >= month &&
        moment(fixedPassedDate).format("YYYY") >= year
      ) {
        return true;
      } else {
        return false;
      }
    if (current.getMonth() >= month - 1 && current.getFullYear() >= year) {
      return true;
    } else {
      return false;
    }
  };

  const handleNextClick = () => {
    setLoader(true);
    setTimeout(() => {
      handleDateClick(showSelectedDate);
      setLoader(false);
      setStep(302);
    }, 2000);
  };

  useEffect(() => {
    const { startDate, endDate } = getStartAndEndDates(
      selectedMonth,
      selectedYear
    );
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    console.log(
      selectedMonth,
      selectedYear,
      "selectedMonth, aksauy selectedYear",
      moment(fixedPassedDate).format("YYYY-MM-DD")
    );
    if (
      moment(fixedPassedDate).format("M") == selectedMonth &&
      moment(fixedPassedDate).format("YYYY") == selectedYear
    ) {
      setStartDate(moment(fixedPassedDate).format("YYYY-MM-DD"));
    } else {
      setStartDate(formattedStartDate);
    }
    setEndDate(formattedEndDate);
  }, [selectedMonth, selectedYear, setStartDate, setEndDate]);

  useEffect(() => {
    const prevButton = document.querySelector(
      ".react-calendar__navigation__prev-button"
    );
    if (check(selectedMonth, selectedYear) && !isPassedDateEnabled) {
      // Add the "pe-none" class (pointer-events: none) to disable the button
      prevButton?.classList.add("pe-none");
    } else {
      prevButton?.classList.remove("pe-none");
    }
  }, [selectedMonth]);

  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  console.log(
    "customcalendar showSelectedDate",
    showSelectedDate,
    selectedYear,
    selectedMonth,
    selectedMonthInWords
  );

  useEffect(() => {
    setSelectedMonthInWords(
      moment(showSelectedDate, "dddd, Do MMMM YYYY").format("MMMM")
    );
  }, [showSelectedDate]);

  const currentYear = moment().year();
  const futureYears = 5;
  const yearsArray = Array.from(
    { length: futureYears + 1 },
    (_, index) => currentYear + index
  );
  const handleYearChange = (value) => {
    setSelectedYear(value);
  };


  const MonthSelection = ({ selectedYear }) => {
    const monthsArray = moment.months();
    const currentYear = moment().year();
    const currentMonth = moment().month();

    const handleRadioChange = (selectedValue) => {
      setSelectedMonthInWords(selectedValue);
    };

    return (
      <div className="my-4">
        {monthsArray.map((month, index) => {
          const monthValue = moment().month(month).format("MMMM"); // Use 'MMMM' to get the full month name
          const monthIndex = index;
          let isDisabled = false;

          if (selectedYear === currentYear) {
            // If the selected year is the current year
            if (monthIndex < currentMonth) {
              // Disable past months
              isDisabled = true;
            }
          }

          return (
            <div key={index}>
              <CustomRadioButton
                type="basic"
                text={`${month},  ${selectedYear}`}
                value={selectedMonthInWords}
                checked={selectedMonthInWords === monthValue && !isDisabled}
                onChange={(e) => handleRadioChange(monthValue)}
                disabled={isDisabled}
                opacity={isDisabled ? 0.4 : 1}
              />
            </div>
          );
        })}
      </div>
    );
  };
  const handleModalClose = () => {
    setIsOpenYearSelection(false);
    // const momentDate = moment(showSelectedDate, "dddd, Do MMMM YYYY");
    // setSelectedMonth(momentDate.month() + 1);
    // setSelectedYear(momentDate.year());
    setNewActiveStartDate(new Date(selectedYear, selectedMonth - 1, 1));
    setShouldRender(true);
  };

  useEffect(() => {
    if (shouldRender) {
      console.log("called render");
      setKey((prevKey) => prevKey + 1);
      setShouldRender(false);
    }
  }, [shouldRender]);


  return (
    <>
      <div className="calendar-main-div customeCalenderWidth" key={key}>
        {/* <div className={screenWidth < 720 ? "top-header" : ""}>
                    <BackArrow onClick={onBack} />
                    <BannerHeader title={"When are you free?"} />
                </div> */}
        <div className={` ${allowMonthYearChange ? "parent_calander" : ""}`}>
          {
            <Calendar
              // value={showSelectedDate ? new Date(moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY"), moment(showSelectedDate, "dddd, Do MMMM YYYY").format("M") - 1, moment(showSelectedDate, "dddd, Do MMMM YYYY").format("D")) : new Date(selectedYear, selectedMonth - 1, 1)}
              value={
                showSelectedDate && !ignoreSelectedDate
                  ? new Date(
                    selectedYear,
                    selectedMonth - 1,
                    new Date(
                      moment(showSelectedDate, "dddd, Do MMMM YYYY")
                    )?.getDate()
                  )
                  : new Date(selectedYear, selectedMonth - 1, 1)
              }
              prev2Label={null}
              next2Label={null}
              onChange={(e) => {
                if (setShowSelectedTime) setShowSelectedTime(null);
                setShowSelectedDate(moment(e).format("dddd, Do MMMM YYYY"));
                if (setFormatedDate)
                  setFormatedDate(moment(e).format("Do MMMM YYYY"));
                if (setIsOpenMobileCalendar) setIsOpenMobileCalendar(false);
                // if (setViewCalendarState)
                //     setViewCalendarState("timeGridDay")
                if (setCalendarStartDate)
                  setCalendarStartDate(moment(e).format("YYYY-MM-DD"));
                if (setSelectedWeek) setSelectedWeek(null);

                if (setIsOpenCalendar) setIsOpenCalendar(false);

                if (setShowCalendar) {
                  setShowCalendar(false);
                }

                if (onChangeCallback)
                  onChangeCallback(moment(e).format("ddd DD MMM YYYY"));
              }}
              className={
                isOpenMobileCalendar
                  ? "calendar-mobile-container border-0 w-100 pb-5 m-auto"
                  : !isOpenMobileCalendar
                    ? "w-100 m-auto border-0 calendar-container"
                    : "w-100 m-auto border-0"
              }
              tileClassName={tileClassName}
              onActiveStartDateChange={handleMonthChange}
              view={view}
              activeStartDate={newActiveStartDate}
              onViewChange={(viewProps) => {
                if (allowMonthYearChange) {
                  setIsOpenYearSelection(true);
                }
                return;
              }}
              formatShortWeekday={(locale, date) => {
                // Format the short weekday name
                return date.toLocaleDateString(locale, { weekday: 'short' })[0];
              }}
            />
          }
        </div>
      </div>
      {
        isShowMobileButton &&
        <div className={isOpenMobileCalendar ? "d-none" : " fixed-button"}>
          {screenWidth < 720 && (
            <StepButton
              isLoading={loader}
              disabled={showSelectedDate === null}
              blue={true}
              label={"Continue"}
              onClick={() => handleNextClick()}
            />
          )}
        </div>
      }

      <CustomModal
        modalOpen={isOpenYearSelection}
        // modalOpen={true}
        setModalOpen={handleModalClose}
        className="CustomeCalenderModel"
        modalBody={
          <>
            <div className="calender-monthly-container" id={"time-picker"}>
              <div className="calender-monthly-data">
                <CustomDropdown
                  labelName={"Select year"}
                  defaultValue={selectedYear}
                  dropdownId={"year"}
                  placeholdername={"Select Year"}
                  optionsArr={yearsArray}
                  className={"select-year"}
                  handleSelectedValue={handleYearChange}
                />
                <div className="selected-month-div ">
                  <MonthSelection selectedYear={selectedYear} />
                </div>
                <div className="sticky-footer-calender">
                  <StepButton
                    label={"Save"}
                    blue={true}
                    onClick={() => {
                      console.log(
                        "save clic",
                        selectedYear,
                        selectedMonthInWords
                      );
                      const monthInNumber =
                        moment(selectedMonthInWords, "MMMM").month() + 1;

                      if (!Number.isNaN(monthInNumber)) {
                        setSelectedMonth(monthInNumber);
                        // const newDate = moment(
                        //   `${selectedYear}-${monthInNumber}-01`
                        // ).format("dddd, Do MMMM YYYY");
                        // setShowSelectedDate(newDate);

                        setNewActiveStartDate(
                          new Date(selectedYear, monthInNumber - 1, 1)
                        );

                      } else {
                        setNewActiveStartDate(
                          new Date(selectedYear, selectedMonth - 1, 1)
                        );
                      }

                      setShouldRender(true);
                      setIsOpenYearSelection(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default CustomCalendar;
