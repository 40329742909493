import React from "react";
import "../steps.scss";
import { Button } from "reactstrap";

const SkipButtonComp = ({ onClick, prevWebOnClick, isMobile }) => {
  return (
    <>
      <div className="SkpButtonWrapper">
        {/* {isMobile && (
          <Button
            type="button"
            className="btn prevWebButton"
            onClick={prevWebOnClick}
          >
            Preview Website
          </Button>
        )} */}
        <Button type="button" className="btn skpRadiusButton" onClick={onClick}>
          Skip for now
        </Button>
      </div>
    </>
  );
};

export default SkipButtonComp;
