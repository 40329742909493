import axios from '../../../axios/index'
import { API_URL, BASE_URL } from "../../../axios/config";
import { getInvoicePageDataSuccess } from "../../actions/downloadInvoice/DownloadInvoice";
import {GET_INVOICE_PAGE_DATA } from "../../constants/invoice/Invoice";
import { call, put, takeLatest } from "@redux-saga/core/effects";

async function getInvoicePageAPI(data) {
    return axios.request({
            method: "post",
            url: BASE_URL + API_URL.DOWNLOAD_INVOICE_PAGE,
            params: data
    });
}

function* getInvoicePageAction(action) {
    let { payload, resolve, reject } = action;
    try {
        let response = yield call(getInvoicePageAPI,payload);
        if (response.status === 200) {
            if (response.data.success) {
                yield put(getInvoicePageDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        // yield put(setLoaderComplete());
        if (reject) reject(error);
    }
}

export function* invoiceDownloadPageWatcher() {
    yield takeLatest(GET_INVOICE_PAGE_DATA, getInvoicePageAction);
}