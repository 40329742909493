import React, { useState, useEffect } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { GetAccountancyFaqListAction } from '../../redux/actions/accountancy-service/faqs';

// Add the icon to the Font Awesome library
library.add(faAngleUp);
library.add(faAngleDown);

export const Faqs = () => {
  // const [plans, setPlans] = useState([]);


  // useEffect(() => {
  //     setPlans([
  //         {
  //             id: 1,
  //             title: 'test',
  //             description: 'test'
  //         },
  //         {
  //             id: 2,
  //             title: 'test',
  //             description: 'test'
  //         },
  //         {
  //             id: 3,
  //             title: 'test',
  //             description: 'test'
  //         },
  //         {
  //             id: 4,
  //             title: 'test',
  //             description: 'test'
  //         },
  //         {
  //             id: 5,
  //             title: 'test',
  //             description: 'test'
  //         }
  //     ]);
  // }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = (index) => {
    setIsOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  /*
  const faqs = [
    {
      question: 'What Does an Accountant for Beauty and Aesthetics Do in the UK?',
      answer: 'An accountant specialised in the UK\'s Beauty and Aesthetics industry offers bespoke financial services, including tax planning, payroll, and financial forecasting, tailored to beauty salons, spas, and aesthetic clinics.',
    },
    {
      question: 'How is Beauty and Aesthetics Accounting Different from Standard Accounting Services?',
      answer: 'Beauty and Aesthetics Accountants focus on the unique financial aspects of beauty, skincare, and aesthetic treatments, such as aesthetic service pricing and inventory management, that are not typically covered in general accounting services.',
    },
    {
      question: 'What Types of Financial Services Do Beauty Salons and Spas Need in the UK?',
      answer: 'Beauty salons and spas in the United Kingdom commonly require services like payroll management, VAT returns, and inventory accounting, in addition to financial forecasting tailored to their specific business model.',
    },
    {
      question: 'Are There Tax Benefits for UK Aesthetics and Beauty Businesses?',
      answer: 'Yes, UK-based beauty and aesthetics businesses may qualify for research and development (R&D) tax credits and can also claim capital allowances on certain types of equipment.',
    },
    {
      question: 'Why is Cash Flow Management Important for Aesthetic Clinics?',
      answer: 'Effective cash flow management is vital for aesthetic clinics in the UK, especially considering the industry\'s seasonal income and the upfront costs of high-quality products and advanced equipment.',
    },
    {
      question: 'Do Beauty Businesses Need Inventory Accounting Services?',
      answer: 'Absolutely, inventory accounting is often essential for UK beauty businesses, as it helps to keep track of various products, both for use in treatments and for resale, ultimately aiding in cost management and reducing waste.',
    },
    {
      question: 'What Are the Financial Challenges for UK Beauty and Aesthetics Businesses?',
      answer: 'Key financial challenges in the UK Beauty and Aesthetics sector include managing high overheads, adjusting to seasonal income fluctuations, and staying compliant with industry-specific tax laws and benefits.',
    },
    {
      question: 'Which Accounting Software is Recommended for UK Beauty and Aesthetics Businesses?',
      answer: 'Popular accounting software choices for the UK market include QuickBooks, Xero, and Sage, depending on the scale and unique needs of the beauty or aesthetics business.',
    },
    {
      question: 'How Frequently Should UK Beauty Businesses Undergo Financial Audits?',
      answer: 'The frequency of financial audits for UK-based beauty and aesthetics businesses varies. Smaller enterprises may opt for annual reviews, while larger companies may benefit from quarterly or bi-annual audits.',
    },
    {
      question: 'What Qualifications Should a UK-Based Beauty and Aesthetics Accountant Have?',
      answer: 'In addition to standard accounting qualifications like ACCA or CIMA, a UK-based accountant specialising in the Beauty and Aesthetics sector should ideally be familiar with the industry\'s specific tax laws and recommended accounting software.',
    },
  ];
  */

  const [faqs, setFaqs] = useState([])
  const {
    getAccountancyFaqListQueryData,
    initGetAccountancyFaqListData,
  } = GetAccountancyFaqListAction();

  useEffect(() => {
    initGetAccountancyFaqListData();
  }, []);
  useEffect(() => {
    if (getAccountancyFaqListQueryData?.data) {
      setFaqs(getAccountancyFaqListQueryData.data.getAccountancyFaqList.data.faq)
    }
  }, [getAccountancyFaqListQueryData?.data])

  return (
    <div className="accountancyFaqsDiv" style={{ justifyContent: 'center' }}>
      <div className="headerDiv">FAQs</div>
      <div className="faqsDiv">
        {
          faqs.map((faq, index) => (
            <div className="faqDiv" key={index}>
              <div className="question" onClick={() => toggleDropdown(index)}>
                <span>{faq.question}</span>
                <span><FontAwesomeIcon icon={isOpen === index ? faChevronUp : faChevronDown} /></span>
              </div>
              {isOpen === index && <div className="answer">{faq.answer}</div>}
            </div>
          ))
        }
      </div>
    </div>
  );
};
