import React, { useState } from 'react'
import closeIcon from '../../../../images/close_icon.svg'
import SwitchButton from '../../../common/switchButton/SwitchButton'
import StepButton from '../../../common/StepButton'
import { useNavigate } from 'react-router'

const ClinicFixedTiminig = () => {
    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedDay, setSelectedDay] = useState('');

    // Added state variables to track whether each day is active or not
    const [activeDays, setActiveDays] = useState({
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true,
        Sunday: false,
    });

    const handleNextStep = (day) => {
        setCurrentStep((prevStep) => prevStep + 1);
        setSelectedDay(day);
    };

    const handleBackStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleGoToHome = () => {
        navigate('/clinic-working-hours')
    }

    const handleToggleChange = (toggleName) => {
        // Update the activeDays state when the toggle button is changed
        setActiveDays((prevActiveDays) => ({
            ...prevActiveDays,
            [toggleName]: !prevActiveDays[toggleName],
        }));
    };

    return (
        <>
            {currentStep === 1 && (
                <div className='container'>
                    <div className='header_icon mt-5'>
                        <img src={closeIcon} alt='closeIcon' onClick={handleGoToHome} />
                    </div>
                    <div className='clinic_working_hours_main'>
                        <div className='clinic_working_hours_title mt-5'>
                            <h1>Set fixed hours</h1>
                        </div>
                        <div className='working_hours_schedual mt-5'>
                            <div className='working_hours_schedual_left' onClick={() => handleNextStep("Monday")}>
                                <h5>Monday</h5>
                                <p>9:00 - 17:30</p>
                            </div>
                            <div className='working_hours_schedual_right'>
                                <SwitchButton
                                    onChange={() => handleToggleChange("Monday")}
                                    isChecked={activeDays.Monday}
                                />
                            </div>
                        </div>
                        <div className='working_hours_schedual'>
                            <div className='working_hours_schedual_left' onClick={() => handleNextStep("Tuesday")}>
                                <h5>Tuesday</h5>
                                <p>9:00 - 17:30</p>
                            </div>
                            <div className='working_hours_schedual_right'>
                                <SwitchButton
                                    onChange={() => handleToggleChange("Tuesday")}
                                    isChecked={activeDays.Tuesday}
                                />
                            </div>
                        </div>
                        <div className='working_hours_schedual'>
                            <div className='working_hours_schedual_left' onClick={() => handleNextStep("Wednesday")}>
                                <h5>Wednesday</h5>
                                <p>9:00 - 17:30</p>
                            </div>
                            <div className='working_hours_schedual_right'>
                                <SwitchButton
                                    onChange={() => handleToggleChange("Wednesday")}
                                    isChecked={activeDays.Wednesday}
                                />
                            </div>
                        </div>
                        <div className='working_hours_schedual'>
                            <div className='working_hours_schedual_left' onClick={() => handleNextStep("Thursday")}>
                                <h5>Thursday</h5>
                                <p>9:00 - 17:30</p>
                            </div>
                            <div className='working_hours_schedual_right'>
                                <SwitchButton
                                    onChange={() => handleToggleChange("Thursday")}
                                    isChecked={activeDays.Thursday}
                                />
                            </div>
                        </div>
                        <div className='working_hours_schedual'>
                            <div className='working_hours_schedual_left' onClick={() => handleNextStep("Friday")}>
                                <h5>Friday</h5>
                                <p>9:00 - 17:30</p>
                            </div>
                            <div className='working_hours_schedual_right'>
                                <SwitchButton
                                    onChange={() => handleToggleChange("Friday")}
                                    isChecked={activeDays.Friday}
                                />
                            </div>
                        </div>
                        <div className='working_hours_schedual'>
                            <div className='working_hours_schedual_left' onClick={() => handleNextStep("Saturday")}>
                                <h5>Saturday</h5>
                                <p>9:00 - 17:30</p>
                            </div>
                            <div className='working_hours_schedual_right'>
                                <SwitchButton
                                    onChange={() => handleToggleChange("Saturday")}
                                    isChecked={activeDays.Saturday}
                                />
                            </div>
                        </div>
                        <div className='working_hours_schedual' onClick={() => handleNextStep("Sunday")}>
                            <div className='working_hours_schedual_left'>
                                <h5>Sunday</h5>
                                <p>Closed</p>
                            </div>
                            <div className='working_hours_schedual_right'>
                                <SwitchButton
                                    onChange={() => handleToggleChange("Sunday")}
                                    isChecked={activeDays.Sunday}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {currentStep === 2 && (
                <div className='container'>
                    <div className='daily_time_slot'>
                        <h1>{selectedDay}</h1>
                        <div className='toggle_active'>
                            <p>Open for business</p>
                            <SwitchButton
                                onChange={() => handleToggleChange(selectedDay)}
                                isChecked={activeDays[selectedDay]}
                            />
                        </div>
                        <div className='daily_time_slot_table'>
                            <div className='open_timing'>
                                <p>Opens</p>
                                <input type='text' placeholder='9:00' defaultValue={'9:00'} disabled={!activeDays[selectedDay]} />
                            </div>
                            <div className='add_break'>
                                <span onClick={() => handleToggleChange("AddBreak")}>Add break</span>
                                {activeDays.AddBreak && (
                                    <div className='break_timing'>
                                        <div className='start_break'>
                                            <p>Break start</p>
                                            <input type='text' placeholder='11:00' />
                                        </div>
                                        <div className='start_break' style={{ marginLeft: '20px' }}>
                                            <p>Break end</p>
                                            <input type='text' placeholder='12:00' />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='close_timing'>
                                <p>Closes</p>
                                <input type='text' placeholder='17:30' defaultValue={'17:30'} disabled={!activeDays[selectedDay]} />
                            </div>
                        </div>
                        <div className='step_btn'>
                            <StepButton label={'Save'} blue={true} onClick={handleBackStep} />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ClinicFixedTiminig
