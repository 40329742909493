import React, { useState, useEffect } from "react";
import "./card-details.scss";
import backIcon from "../../../../images/back_arrow.svg";
import { FaWhatsapp } from "react-icons/fa6";
import StepButton from "../../../common/StepButton";
import { FiChevronRight } from "react-icons/fi";
import checkMarkIcon from "../../../../assets/createWebsite/check-mark.svg";
import TextInput from "../../../socialMediaMarketing/TextInput";
import { StripeComponent } from "../../stripe-payment/stripe-component";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { PurchaseWebsiteBuilderPackageAction } from "../../../redux/actions/createWebsite/purchaseWebsiteBuilderPackege";
import { UpdateWebsitePackagePaymentAction } from "../../../redux/actions/createWebsite/updateWebsiteBuilderPackagePayment"
import { GetWebsitePackageDataAction } from "../../../redux/actions/createWebsite/getWebsitePackage";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { createWebsiteRoute, getCustomBuildThankYouRoutePage } from "../../../../Routes";
import closeIcon from "../../../../images/icons/Back.svg";
import WelcomeScreenThankYou from "./welcomeScreenThankYou";
import useWindowDimensions from '../../../../utils/useWindowDimensions';

const CardDetails = (props) => {
  const { slug } = useParams();
  const navigate = useNavigate()
  const { width, height } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false)

  const [searchParams] = useSearchParams();
  const usertoken = searchParams.get('usertoken')
  const {
    purchaseWebsiteBuilderPackageQueryData,
    initPurchaseWebsiteBuilderPackageData,
  } = PurchaseWebsiteBuilderPackageAction();

  //PurchaseWebsitePackage mutaion
  const { initUpdateWebsitePackageData, updateWebsitePackagePaymentQueryData } = UpdateWebsitePackagePaymentAction()
  //UpdateWebsitePackagePayment mutation 
  const { initGetWebsitePackageFunction, GetWebsitePackageResponseData: { loading: singalPackagLoading } } = GetWebsitePackageDataAction()

  const [intentData, setIntentData] = useState(null);
  const [packageId, setPackageId] = useState(1)
  const [showPaymentError, updatePaymentError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentErrorData, setPaymentErrorData] = useState(null);
  const [showCustomerLoader, setShowCustomerLoader] = useState(false);
  const [isOverviewOpen, setIsOverviewOpen] = useState(false)

  useEffect(() => {
    initGetWebsitePackageFunction({ packageSlug: slug, userToken: usertoken })
  }, [slug])

  console.log("slug", slug)

  const { packageDetails } = useSelector((state) => state?.GetWebsitePackageReducer)

  useEffect(() => {
    if (width < 767) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [width])

  console.log("packageDetails", packageDetails)

  const submitStripeBtn = document.getElementById(
    "socialMediaMarketingMakePaymentBtn"
  );
  console.log("singalPackagLoading", singalPackagLoading)
  const paymentIntentSetProcess = () => {
    console.log("payment intent set process");
    if (submitStripeBtn) {
      submitStripeBtn.disabled = true;
    }
  };

  const changePaymentType = (event) => {
  };

  const paymentIntentSuccessCallback = (intentId, paymentMethodId, addTrack = true) => {
    console.log("intentId tmp:", intentId);
    console.log("token id:", paymentMethodId);
    initPurchaseWebsiteBuilderPackageData({
      packageId: packageDetails?.id,
      generatePaymentIntent: false,
      userToken: usertoken,
      packageSlug: packageDetails?.package_name,
      canUpgragePlan: true,
    }, (resIntData) => {
      setShowCustomerLoader(true);
      console.log("resIntDataresIntData", resIntData);
      setIntentData(resIntData?.data?.purchaseWebsitePackage?.data)
      initUpdateWebsitePackageData({
        token: paymentMethodId,
        stripeSavedCardId: null,
        intentId: intentId,
        packageId: resIntData?.data?.purchaseWebsitePackage?.data?.packageId,
        orderId: parseInt(
          resIntData?.data?.purchaseWebsitePackage?.data?.packageReferenceId
        ),
        userToken: usertoken
      }, (successResponse) => {
        toast.success(successResponse?.data?.updateWebsitePackagePayment?.message);
        navigate(getCustomBuildThankYouRoutePage())
      }, (errResponse) => {
        console.log("errResponse:", errResponse);
        paymentIntentErrorCallback();
      })
    },
      (err) => {
        if (
          err?.data?.purchaseWebsitePackage?.message === "un-authorized" ||
          typeof err?.data === "undefined"
        ) {
          console.log('line 356', err?.data?.purchaseWebsitePackage?.message, isLoading);
          setIsLoading(false);
          // window.location.href = webBaseUrl + "/login";
        } else {
          updatePaymentError(true);
          setPaymentErrorData(err);
          console.log('line 362 setFalse', isLoading);
          setIsLoading(false);
        }
      })
  }

  useEffect(() => {
    if (packageDetails) {
      generateIntnt();
    }
  }, [packageDetails])


  const generateIntnt = async () => {
    setIsLoading(true);
    initPurchaseWebsiteBuilderPackageData({
      packageId: packageDetails?.id,
      generatePaymentIntent: true,
      userToken: usertoken,
      packageSlug: packageDetails?.package_name,
      canUpgragePlan: true,
    }, (resIntData) => {
      console.log("resIntDataresIntData", resIntData);
      setIntentData(resIntData?.data?.purchaseWebsitePackage?.data)
      setIsLoading(false);
    },
      (err) => {
        if (
          err?.data?.purchaseWebsitePackage?.message === "un-authorized" ||
          typeof err?.data === "undefined"
        ) {
          console.log('line 356', err?.data?.purchaseWebsitePackage?.message, isLoading);
          setIsLoading(false);
          // window.location.href = webBaseUrl + "/login";
        } else {
          updatePaymentError(true);
          setPaymentErrorData(err);
          console.log('line 362 setFalse', isLoading);
          setIsLoading(false);
        }
      })

  };


  const paymentIntentErrorCallback = () => {
    setShowCustomerLoader(false);
    toast.error("Unable to complete payment process!");
    console.log("payment intent call back error");
  };

  return (
    <>
      {singalPackagLoading || packageDetails === null ? <div className="LoaderMain">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div> : packageDetails?.is_package_purchase === false ?
        <div className="mainCardDetails">
          <div className="cardDetailsSEcWrapper">
            <div className="CardContent">
              <div className="TopHeader">
                <div className="BackButtonDashBoard">
                  {!isMobile && width > 767 && <img src={backIcon} alt="" onClick={() =>
                    navigate(createWebsiteRoute())
                  } />}
                </div>
                <div className="overViewBtn">
                  <button onClick={() => { setIsOverviewOpen(true) }}>View Overview</button>
                </div>
                {/* <div className="WhatSappBtn">
              <button className="whatsappBtn">
                <span className="WpIcon">
                  <FaWhatsapp />
                </span>
                Contact the tech team
              </button>
            </div> */}
              </div>
              <div className="CardContentWrapper">
                <div className="SecTitle">
                  <h5>Card details</h5>
                  <p>
                    Enter your card details here. We will charge this card for
                    initial payments and monthly payments here after please note we
                    will not charge your monthly subscriptions until the website has
                    been launched live
                  </p>
                </div>
                <div className="payment-data-div">
                  {singalPackagLoading ?
                    [...Array(2)].map(i => <Skeleton width={"100%"} height={15} />)
                    : packageDetails?.amount && packageDetails?.duration_text && <>
                      <div>£{packageDetails?.amount} one-off payment.</div>
                      <div>Pay £{packageDetails?.package_amount}, after 60 days once your website is ready.</div>
                    </>}
                </div>
                <div className='treatment-payment-section'>
                  <div className='payment-card'>
                    <b>Payable amount</b>
                    <p>£299</p>
                  </div>
                </div>

                <div className="BodyCard">
                  {showPaymentError ? (
                    <h4 className="text-center" style={{ fontSize: "16px" }}>
                      {" "}
                      {paymentErrorData?.data?.purchaseAccountancyPackage?.message}{" "}
                    </h4>
                  ) : null}

                  {!showCustomerLoader &&
                    <StripeComponent
                      // key={clearStripeForm ? "clearForm" : "normalForm"}
                      clientSecret={intentData?.intentId ? intentData?.intentId : null}
                      successCallback={paymentIntentSuccessCallback}
                      failureCallback={paymentIntentErrorCallback}
                      setProcess={paymentIntentSetProcess}
                      paymentType="card"
                      changePaymentType={changePaymentType}
                      isCardPaymentType={false}
                      savedCardId={null}
                      // callbackForSavedCardPayment={callbackForSavedCardPayment}
                      useSetupIntentFlow={true}
                      // preCallbackFuncton={()=>{
                      //   var res  = validateForm();
                      //   return res;
                      // }}
                      LateIntentFunction={() => {
                        //generate intent and process payment after entering card detail
                        return new Promise((resolve, reject) => {
                          initPurchaseWebsiteBuilderPackageData({
                            packageId: packageDetails?.id,
                            generatePaymentIntent: true,
                            userToken: usertoken,
                            packageSlug: packageDetails?.package_name,
                            canUpgragePlan: true,
                          }, (resIntData) => {
                            console.log("resIntDataresIntData", resIntData);
                            setIntentData(resIntData?.data?.purchaseWebsitePackage?.data)
                            setIsLoading(false);
                          },
                            (err) => {
                              if (
                                err?.data?.purchaseWebsitePackage?.message === "un-authorized" ||
                                typeof err?.data === "undefined"
                              ) {
                                console.log('line 356', err?.data?.purchaseWebsitePackage?.message, isLoading);
                                setIsLoading(false);
                                // window.location.href = webBaseUrl + "/login";
                              } else {
                                updatePaymentError(true);
                                setPaymentErrorData(err);
                                console.log('line 362 setFalse', isLoading);
                                setIsLoading(false);
                              }
                            })
                        })
                      }}
                      customButton={
                        <div className="subscribeBtnWrap">
                          <button className="customSubscibeBtn">Submit</button>
                        </div>
                      }
                    />}
                  {/* <div className="PriseBoxBottomButton">
                <StepButton blue={true} label="Submit" onClick={() => setCurrentStep(5)} />
              </div> */}
                </div>
                <div className="WhatSappBtn">
                  <button className="whatsappBtn" onClick={() => window.open(process.env.REACT_APP_WHATSAPP_LINK, '_blank')}>
                    <span className="WpIcon">
                      <FaWhatsapp />
                    </span>
                    contact support
                  </button>
                </div>
              </div>
            </div>
            <div className={isOverviewOpen ? "CardContentPlan isOverviewOpen" : "CardContentPlan"}>
              <div className="PriseBox">
                <div className="CloseIcon" onClick={() => { setIsOverviewOpen(false) }}>
                  <img src={closeIcon} alt={closeIcon} />
                </div>
                <div className="PriseBoxHeader">
                  <div className="PriseBoxHeaderTop">
                    <h5>Overview</h5>
                  </div>
                  <div className="PriseBoxHeaderContent">
                    <div className="PriseBoxHeaderBoxWrapper">
                      <h5>Standard plan</h5>
                      <button className="popularBtn">
                        Selected plan
                      </button>
                    </div>
                    <h6>
                      {singalPackagLoading ? <Skeleton width={"100%"} height={40} /> : packageDetails?.amountText ? <> {packageDetails?.amountText} </> : ""}
                    </h6>
                    <p className="mt-2">
                      {singalPackagLoading ? <Skeleton width={"100%"} height={20} /> : packageDetails?.duration_text ? <> {packageDetails?.duration_text} </> : ""}
                    </p>
                  </div>
                </div>
                <div className="PriseBoxUl">
                  <ul>
                    {singalPackagLoading ? [...Array(10)].map(i => <Skeleton width={"100%"} height={40} />) : packageDetails?.package_points?.map((item, index) => {
                      return (
                        <li key={index}>
                          <span className="IconLi">
                            <img
                              src={checkMarkIcon}
                              alt={checkMarkIcon}
                              srcset={checkMarkIcon}
                            />
                          </span>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={isOverviewOpen ? "overlayOverView isoverlayOpen" : "overlayOverView"} onClick={() => { setIsOverviewOpen(false) }}></div>
        </div > : <WelcomeScreenThankYou />
      }
    </>
  );
}

export default CardDetails;
