import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_USER_PROFILE_DATA_QUERY = gql`
query GetUserProfileToUpdate {
  getUserProfileToUpdate {
    success
    message
    data {
      user {
        usr_id
        usr_fname
        usr_lname
        usr_email
        usr_phone_country
        usr_phone_no
        usr_company_name
        identity_doc_url
        insurance_doc_url
        certificate_doc_url
        cardLinked
        buildingNo
        streetName
        city
        postcode
        ownerName
        website
        facebookPage
        instagramName
        businessType
        companyHouseRegNo
        insurenceExpireDate
        identitiDocId
      }
    }
  }
}
`;

export const GetUserProfileToUpdateAction = () => {
  // const dispatch = useDispatch();
  const [
    getUserProfileToUpdateResponseFunction,
    getUserProfileToUpdateResponseData,
  ] = useLazyQuery(GET_USER_PROFILE_DATA_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: ({ getUserProfileToUpdate: responseData }) => {
      if (responseData.success) {
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  const getUserProfileToUpdateQueryData = getUserProfileToUpdateResponseData;

  const initGetUserProfileToUpdateData = (data) => {
    getUserProfileToUpdateResponseFunction({
      variables: data,
    });
  };

  return { getUserProfileToUpdateQueryData, initGetUserProfileToUpdateData };
};
