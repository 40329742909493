import React, { useState } from 'react'
import "./client-sms-popup.scss"
import TextInput from '../../common/TextInput'
import StepButton from '../../common/StepButton';
import { SendCustomSmsToClientMutation } from '../../redux/actions/client/SendSmsToClient';
import showToast from '../../common/CustomToast/CustomToast';
export const ClientSmsPopup = ({ clientDetails, setIsSMSOpen }) => {
    const [smsData, setSmsData] = useState();
    const { initiateSendCustomSmsToClient, sendSmsToClientMutationData } = SendCustomSmsToClientMutation()

    const SendSmsToClient = () => {
        initiateSendCustomSmsToClient(
            {
                clientId: clientDetails?.clentId,
                smsContent: smsData
            }
            , (res) => {
                if (res.success) {
                    setSmsData(null);
                    setIsSMSOpen(false)
                    showToast("success", res.message, "", "", undefined, undefined, { toastId: 'send-sms-success-toast' });
                } else {
                    setIsSMSOpen(true)
                    showToast("error", res.message, "", "", undefined, undefined, { toastId: 'send-sms-error-toast' })
                }
            }, (err) => {
                setIsSMSOpen(true)
                showToast("error", err.message, "", "", undefined, undefined, { toastId: 'send-sms-err-toast' })
            })
    }
    return (
        <form
            className="sms-popup d-flex flex-column mt-2"
        // onSubmit={handleClientsForm}
        >
            <div>
                {/* <h6>{`${clientDetails?.clientFirstName} ${clientDetails?.clientLastName}(${clientDetails?.mobile})`}</h6> */}
                <TextInput label="To" autoFocus={false}
                    value={`${clientDetails?.clientFirstName} ${clientDetails?.clientLastName}(${clientDetails?.mobile})`}
                    name={"to"}
                    disabled
                    placeHolder="Add a subject line" />

                <TextInput multiline={true} autoFocus={false} label="Your message"
                    // defaultValue={emailData.message}
                    value={smsData}
                    name={"message"}
                    onChange={(e) =>
                        setSmsData(e.target.value)
                    }
                    rows={40} placeHolder="Add message" />
            </div>
            <StepButton isLoading={sendSmsToClientMutationData.loading} type={'submit'} label={"Send Message"} blue={true} disabled={!smsData || sendSmsToClientMutationData.loading} onClick={(e) => {
                e.preventDefault()
                SendSmsToClient()
            }
            } />
        </form>
    )
}
