import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const TRACK_USER_ACTIVITY_MUTATION = gql`
mutation TrackUserActivityForPersonalWebsite($activityStep: Int!) {
  trackUserActivityForPersonalWebsite(activityStep: $activityStep) {
    success
    message
  }
}
`;

export const TrackUserActivity = () => {
  const [trackUserActivityRequest, trackUserActivityResponseData] =
    useMutation(TRACK_USER_ACTIVITY_MUTATION);
  const dispatch = useDispatch();
  const trackUserActivityMutationData = trackUserActivityResponseData;

  const initiatetrackUserActivity = (
    data,
    successCallback,
    errorCallback
  ) => {
    trackUserActivityRequest({
      variables: data,
    })
      .then(({ data: { trackUserActivityForPersonalWebsite: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        // errorCallback(error);
      });
  };
  return { initiatetrackUserActivity, trackUserActivityMutationData };
};
