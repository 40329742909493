// ========================COMPLETE BOOKING PAYMENT========================

import { gql, useMutation } from "@apollo/client";
import { UPDATE_OCCUPATIONS_ERROR, UPDATE_OCCUPATIONS_SUCCESS } from "../../constants";
import { useDispatch } from "react-redux";

const UPDATE_OCCUPATION_MUTATION = gql`
  mutation UpdateOccupation($countryCode: String, $phoneNumber: String, $occupation: [Int], $isoCode: String, $companyName: String) {
    updateOccupation(country_code: $countryCode, phone_number: $phoneNumber, occupation: $occupation, iso_code: $isoCode, company_name: $companyName) {
      success
      message
    }
  }
`;


export const UpdateOccupationMutationAction = () => {
  const dispatch = useDispatch();
  const [
    UpdateOccupationResponseFunction,
    updateOccupationResponseData,
  ] = useMutation(UPDATE_OCCUPATION_MUTATION)

  //this will be called from components
  const updateOccupationQueryData = updateOccupationResponseData; //variable

  const initUpdateOccupationData = (data) => {
    UpdateOccupationResponseFunction({
      variables: data,
    })
      // .then(({ data: { UpdateOccupation: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        if (responseData.data.updateOccupation.success) {
          dispatch({
            type: UPDATE_OCCUPATIONS_SUCCESS,
            payload: responseData?.data
          })
          // successCallback(responseData)
          // toast.success(responseData.message)
        }
        else {
          dispatch({
            type: UPDATE_OCCUPATIONS_ERROR,
          })
          // errorCallback(responseData)
          // toast.error(responseData.message)
        }
      }).catch((err) => {
        dispatch({
          type: UPDATE_OCCUPATIONS_ERROR,
        })
        // errorCallback(err)
        // toast.error("something went wrong!!!")
      })
  }

  return { updateOccupationQueryData, initUpdateOccupationData };
};
