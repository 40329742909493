import React, { useEffect, useState } from "react";
import "./booking-invoice.scss";
import Header from "../../common/Header/Header";
import SwitchButton from "../../common/switchButton/SwitchButton";
import { useDispatch, useSelector } from "react-redux";
import getInvoicePageData, { downloadInvoice } from "../../redux/actions/downloadInvoice/DownloadInvoice";
import { useLocation, useNavigate } from "react-router";
import { GetInvoiceDetailsQuery } from "../../redux/actions/invoice/GetInVoiceDetails";
import NavHeader from "../../common/NavHeader/NavHeader";
import { businessDashboardRoute, getAppointmentListRoute, getBookingInvoiceDownloadRoute, getCalendarRoute, getClientProfileRoute } from "../../../Routes";
import CustomModal from "../../modal/CustomModal";
import moment from "moment";


const Bookinginvoice = () => {
  const [isChecked, setIsChecked] = useState(false);
  const location = useLocation()
  //Invoice Details Query
  const { initInvoiceDetails, invoiceDetailsQueryData } = GetInvoiceDetailsQuery()
  const entityId = location?.state?.entityId
  const selectedDate = location?.state?.selectedDate
  // const invoiceUrl= useSelector(state=>state?.DownloadInvoiceReducer?.invoiceUrl)
  const invoiceDetailsData = useSelector(state => state?.GetInvoiceDetailsReducer?.invoiceDetails)
  const [enabledVatId, setEnableVatId] = useState([]);
  const [enabledVatIdPrice, setEnableVatIdPrice] = useState(null);
  const [isLoadMoreOpen, setIsLoadMoreOpen] = useState(false);

  const navigate = useNavigate()
  var isVatEnabled = false;
  let vatAmount = 0;
  const displayedPrice = 0

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInvoicePageData({
      "elementId": 8494
    }))
    dispatch(downloadInvoice({
      "elementId": 8494
    }))

    if (entityId !== null) {
      initInvoiceDetails({
        entityId: entityId,
        generateInvoice: false
      })
    }

  }, [])

  useEffect(() => {
    console.log("isVatEnable", isVatEnabled)
  }, [isVatEnabled])

  useEffect(() => {
    if (invoiceDetailsData) {
      const itemsWithVatEnabled = invoiceDetailsData?.treatments?.filter(item => item.isVatEnabled);

      // Extract IDs from filtered items
      const idsWithVatEnabled = itemsWithVatEnabled.map(item => item.id);

      console.log(idsWithVatEnabled);
      setEnableVatId(idsWithVatEnabled)
    }
  }, [invoiceDetailsData])

  console.log("invoiceUrl", "invoiceDetailsData", invoiceDetailsData)
  const [myState, setMyState] = useState("initialState");
  const handleDownloadInvoice = () => {
    //  window.open(invoiceUrl, '_blank');
    initInvoiceDetails({
      entityId: entityId,
      displayVatIds: enabledVatId,
      generateInvoice: true
    }, (res) => {
      if (res?.success) {
        // let pdfWindow = window.open("")
        // console.log(res?.data?.invoiceLink)
        // pdfWindow.document.write(
        //   "<iframe width='100%' height='100%' src='data:application/pdf;base64," +
        //   encodeURI(res?.data?.invoiceBase64) + "'></iframe>"
        // )
        // navigate(getBookingInvoiceDownloadRoute(entityId))
        // navigate(`/${res?.data?.invoiceLink}`)
        window.location.href = res?.data?.invoiceLink;
      }
    })

  }
  let subtotal = invoiceDetailsData?.treatments.reduce((acc, item) => {
    return acc + (isVatEnabled ? parseFloat(displayedPrice) : parseFloat(item?.total));
  }, 0);


  useEffect(() => {
    // Access the global variables within the component
    console.log(vatAmount, "vatAmount");
    console.log(isVatEnabled, "ids", enabledVatId);

    // Access and update state
    console.log(myState);
    setMyState("newState");
  }, []);

  console.log(vatAmount, "vatAmount out");


  const handleToggleVAT = (treatmentId) => {
    setEnableVatId((prevIds) => {
      const updatedIds = prevIds.includes(treatmentId)
        ? prevIds.filter((id) => id !== treatmentId)
        : [...prevIds, treatmentId]

      return updatedIds;
    });

  };
  useEffect(() => {
    let enabledVatIdData = invoiceDetailsData?.treatments.filter((item) => {
      return enabledVatId.includes(item.id);
    });
    let enabledVatIdPrice = enabledVatIdData?.reduce((acc, item) => {
      return acc + (parseFloat(item?.total * 20) / 120);
    }, 0);

    if (enabledVatIdPrice)
      setEnableVatIdPrice(enabledVatIdPrice)
    else
      setEnableVatIdPrice(null)


    console.log(enabledVatIdData, "enabledVatIdPrice", enabledVatIdPrice);
  }, [enabledVatId])

  const data = [
    {
      title: 'Are You Managing Your Finances Correctly? 🤔',
      content: "It's easier than you think to overlook key financial strategies that could boost your aesthetics business"
    },
    {
      title: 'Thinking of Going Limited? 🏢',
      content: 'Setting up as a Limited Company could be a game-changer for your business, offering both legal and financial benefits. Are you fully aware of how it could affect you?'
    },
    {
      title: 'Cosmetic Vs. Medical VAT - Do You Know the Difference? 💡',
      content: 'Believe it or not, understanding the VAT differences between cosmetic and medical treatments could save you a fortune! Are you taking advantage of this crucial financial knowledge?'
    },
    {
      title: 'Want an Accountant Specialised in Aesthetics? 💼',
      content: 'Imagine having an accountant who knows the aesthetics business inside out. From optimising your tax to enhancing your profits, a specialised accountant can make a significant difference.'
    }
  ];

  return (
    <div className="InvoicePage">
      <Header />
      <NavHeader isBackOnly={true} isSticky={true} handleOnBack={() => {

        if (location?.state?.fromDashboardAppointmentList) {
          navigate(getAppointmentListRoute(), { state: { fromDashboardAppointmentList: location?.state?.fromDashboardAppointmentList, clickdate: location?.state?.clickdate, active: location?.state?.active } })
        }
        else if (location?.state?.clientId) {
          navigate(getClientProfileRoute(location?.state?.clientId), { state: { getBackToClientAppointmentScreen: true, active: location?.state?.active } })
        }
        else if (location?.state?.fromDashboard) {
          navigate(businessDashboardRoute())
        }
        else {
          navigate(getCalendarRoute(), { state: { fromAppointmentList: location?.state?.fromAppointmentList, clickedDate: selectedDate, calenderViewState: location?.state?.calenderViewState, calenderShowSelectedDate: location?.state?.calenderShowSelectedDate } })
        }
      }

      } />
      <div className="MainInvoicePage">
        <div className="ContentWrapper">
          <p>
            Managing your accounts can make or break your aesthetics business.
            Consult our specialised aesthetics accountancy firm today and
            discover How an Aesthetics Accountant Can Transform Your Business
            Today and increase your profit margins !
          </p>

          <button className="LoadMoreBtn" onClick={() => setIsLoadMoreOpen(true)}>Learn More</button>
        </div>
        <div className="InvoiceBox">
          <div className="InvoiceHeader">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="204"
              height="48"
              viewBox="0 0 204 48"
              fill="none"
            >
              <path
                d="M5.71872 0.638782V47.3612H0V0.638782H5.71872Z"
                fill="black"
              />
              <path
                d="M44.6393 0.638782V47.3612H39.3833L20.1173 13.0951H19.7657V47.3612H14.0469V0.638782H19.34L38.6245 34.9506H38.9761V0.638782H44.6393Z"
                fill="black"
              />
              <path
                d="M56.2942 0.638782L66.8433 38.692H67.269L77.8181 0.638782H83.9995L70.3597 47.3612H63.7526L50.1128 0.638782H56.2942Z"
                fill="black"
              />
              <path
                d="M120.514 24C120.514 28.9886 119.774 33.2776 118.293 36.8669C116.813 40.4411 114.783 43.1939 112.204 45.1255C109.638 47.0418 106.72 48 103.45 48C100.169 48 97.2383 47.0418 94.6596 45.1255C92.0933 43.1939 90.0698 40.4335 88.5892 36.8441C87.1087 33.2548 86.3684 28.9734 86.3684 24C86.3684 19.0114 87.1087 14.73 88.5892 11.1559C90.0698 7.56654 92.0933 4.81369 94.6596 2.89734C97.2383 0.965778 100.169 0 103.45 0C106.72 0 109.638 0.965778 112.204 2.89734C114.783 4.81369 116.813 7.56654 118.293 11.1559C119.774 14.73 120.514 19.0114 120.514 24ZM114.851 24C114.851 20.1977 114.351 16.9962 113.352 14.3954C112.365 11.7795 111.008 9.80228 109.28 8.46388C107.565 7.11027 105.622 6.43346 103.45 6.43346C101.267 6.43346 99.3172 7.11027 97.6022 8.46388C95.8872 9.80228 94.53 11.7795 93.5306 14.3954C92.5436 16.9962 92.0501 20.1977 92.0501 24C92.0501 27.8023 92.5436 31.0114 93.5306 33.6274C94.53 36.2281 95.8872 38.2053 97.6022 39.5589C99.3172 40.8973 101.267 41.5665 103.45 41.5665C105.622 41.5665 107.565 40.8973 109.28 39.5589C111.008 38.2053 112.365 36.2281 113.352 33.6274C114.351 31.0114 114.851 27.8023 114.851 24Z"
                fill="black"
              />
              <path
                d="M133.363 0.638782V47.3612H127.644V0.638782H133.363Z"
                fill="black"
              />
              <path
                d="M173.172 15.8327H167.397C167.175 14.3118 166.762 12.9734 166.157 11.8175C165.553 10.6616 164.813 9.68061 163.937 8.87452C163.061 8.06844 162.08 7.46007 160.994 7.04943C159.921 6.63878 158.779 6.43346 157.57 6.43346C155.386 6.43346 153.431 7.11027 151.703 8.46388C149.988 9.81749 148.631 11.8023 147.632 14.4183C146.645 17.0342 146.151 20.2281 146.151 24C146.151 27.8023 146.645 31.0114 147.632 33.6274C148.631 36.2433 149.994 38.2205 151.722 39.5589C153.449 40.8973 155.392 41.5665 157.552 41.5665C158.748 41.5665 159.883 41.3688 160.957 40.9734C162.043 40.5627 163.024 39.962 163.9 39.1711C164.776 38.3802 165.516 37.4144 166.12 36.2738C166.737 35.1179 167.163 33.7947 167.397 32.3042L173.172 32.327C172.863 34.6236 172.265 36.7376 171.376 38.6692C170.5 40.5856 169.372 42.2433 167.99 43.6426C166.62 45.0266 165.053 46.0989 163.289 46.8593C161.524 47.6198 159.6 48 157.515 48C154.233 48 151.309 47.0418 148.742 45.1255C146.176 43.1939 144.152 40.4335 142.672 36.8441C141.204 33.2548 140.469 28.9734 140.469 24C140.469 19.0114 141.21 14.73 142.69 11.1559C144.171 7.56654 146.194 4.81369 148.761 2.89734C151.327 0.965778 154.245 0 157.515 0C159.526 0 161.401 0.357415 163.141 1.07224C164.893 1.77186 166.466 2.80608 167.86 4.1749C169.254 5.52852 170.408 7.18631 171.321 9.14829C172.234 11.0951 172.851 13.3232 173.172 15.8327Z"
                fill="black"
              />
              <path
                d="M180.015 47.3612V0.638782H203.778V6.70723H185.733V20.943H202.538V26.9886H185.733V41.2928H204V47.3612H180.015Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="InvoiceContainer">
            <div className="number w-40">
              <h2>Invoice</h2>
              <p>{invoiceDetailsData?.invoiceNumber}</p>
            </div>
            <div className="number w-40">
              <h2>Date</h2>
              <p className="date">{invoiceDetailsData?.invoiceDate}</p>
            </div>

            <div className="detailBusiness">
              <div className="Business">
                <h2>Business Address</h2>
                <p>{invoiceDetailsData?.businessAddress}</p>
                <p>
                  <b>Phone:</b> {invoiceDetailsData?.businessPhone}
                </p>
                <p>
                  <b>Email:</b> {invoiceDetailsData?.businessEmail}
                </p>
              </div>
              <div className="Details">
                <h2>Client Details</h2>
                <p>{invoiceDetailsData?.clientAddress}</p>
                <p>
                  <b>Phone:</b> {invoiceDetailsData?.clientPhone}
                </p>
                <p>
                  <b>Email:</b> {invoiceDetailsData?.clientEmail}
                </p>
              </div>
            </div>

            <div className="TableInVoice">
              <table class="table">
                <thead>
                  <tr>
                    <td></td>
                    <td>Treatment</td>
                    <td>Qty</td>
                    <td>Price</td>
                    <td>VAT (20%)</td>
                    <td>Total</td>
                  </tr>
                </thead>
                <tbody>
                  {invoiceDetailsData?.treatments?.map((item, index) => {
                    isVatEnabled = enabledVatId.includes(item.id)
                    const vatRate = 20;
                    vatAmount = isVatEnabled ? item?.isVatEnabled ? item?.vat : (parseFloat(item?.price) * vatRate) / 120 : 0;
                    const displayedPrice = isVatEnabled ? (item?.isVatEnabled ? item?.price.toFixed(2) : (parseFloat(item?.price) - vatAmount).toFixed(2)) : item?.isVatEnabled ? (item?.total) : item?.price.toFixed(2);
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="desc">{item?.name}</td>
                        <td>{item?.qty}</td>
                        <td>£{displayedPrice}</td>
                        <td className="vat">
                          <SwitchButton
                            isChecked={isVatEnabled}
                            onChange={() => {
                              handleToggleVAT(item?.id, isVatEnabled)
                              // setInvoiceData((prevData) => {
                              //   const newData = prevData.map((prevItem, i) => {
                              //     if (i === index) {
                              //       return {
                              //         ...prevItem,
                              //         status: !prevItem.status,
                              //       };
                              //     }
                              //     return prevItem;
                              //   });
                              //   return newData;
                              // });
                            }}
                          />
                          {isVatEnabled && (
                            <span className="vatRupies">£{vatAmount.toFixed(2)}</span>
                          )}
                        </td>
                        <td>£{item.total}</td>
                      </tr>
                    );
                  })}

                  {/* <tr>
                    <td>2</td>
                    <td className="desc">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.1500
                    </td>
                    <td>1</td>
                    <td>£100</td>
                    <td className="vat">
                      <SwitchButton
                        onChange={(e) => {
                          console.log(e.target.checked, "value checked");
                          setIsChecked(!isChecked);
                        }}
                        isChecked={isChecked}
                      />
                      <span className="vatRupies">£20</span>
                    </td>
                    <td>£ 120</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td className="desc">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.1500
                    </td>
                    <td>1</td>
                    <td>£100</td>
                    <td className="vat">
                      <SwitchButton
                        onChange={(e) => {
                          console.log(e.target.checked, "value checked");
                          setIsChecked(!isChecked);
                        }}
                        isChecked={isChecked}
                      />
                      <span className="vatRupies">£20</span>
                    </td>
                    <td>£ 120</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          <div className="InVoiceTotal">
            <div className="Thankyoumessage">
              <p>Thank you for shopping at {invoiceDetailsData?.businessName}</p>
            </div>
            <div className="TotalWrapper w-40">
              <div className="number">
                <h2>Subtotal</h2>
                <p>£{(parseFloat(subtotal) - (enabledVatIdPrice ? enabledVatIdPrice : 0))?.toFixed(2)} </p>

              </div>
              {invoiceDetailsData?.total_discount > 0 && <div className="number">
                <h2>Discount</h2>
                <p>{invoiceDetailsData?.total_discount_readable}</p>
              </div>}
              {enabledVatIdPrice ? <div className="number">
                <h2>VAT</h2>
                <p>£{enabledVatIdPrice.toFixed(2)}</p>

              </div>
                : null
              }
              <div className="TotalBtn">
                <h2>Total amount</h2>
                {/* <p>£{(isVatEnabled ? (subtotal + vatAmount) : subtotal)?.toFixed(2)}</p> */}
                <p>{invoiceDetailsData?.total_readable}</p>
                {/* <p>£{parseFloat(subtotal).toFixed(2)}</p> */}

              </div>
            </div>
          </div>
        </div>
        <div className="DownLoadInvoice">
          <button onClick={() => handleDownloadInvoice()}>
            Download Invoice</button>
          {console.log(isVatEnabled, "ids akash", enabledVatId)}
        </div>
      </div>
      <CustomModal
        type={"common-width"}
        modaltitle={"Find an Aesthetics Accounts Specialist"}
        className={'loadmore-model'}
        modalBody={
          <>
            <div className="load-more-container">
              <h2>Unlock Your Financial Potential in the Aesthetics Business</h2>
              {data.map((item, index) => (
                <div className="load-more-model-data" key={index}>
                  <h4>{item.title}</h4>
                  <p>{item.content}</p>
                </div>
              ))}
            </div>
          </>
        }
        modalOpen={isLoadMoreOpen}
        setModalOpen={setIsLoadMoreOpen}
        setIsOpenMobileCalendar={setIsLoadMoreOpen}
      />
    </div>
  );
};

export default Bookinginvoice;
