import React, { useState } from 'react'
import './payment-main.scss'
import backArrow from '../../../../images/close_icon.svg'
import cardIcon from '../../../../images/pay_credit.svg'
import rightArrow from '../../../../images/right_arrow.svg'
import visaIcon from '../../../../images/icons/visa.png'
import PaymentComplete from './PaymentComplete'

const PaymentPreviewPage = () => {
    const [currentStep, setCurrentStep] = useState(1)

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1)
    }

    return (
        <>
        {currentStep ===1 && (  
            <div className='container'>
                <div className="payment-header-main">
                    <div></div>
                    <img src={backArrow} style={{ cursor: 'pointer' }} alt="" />
                </div>
                <div className='payment-main'>
                    <h2 className='text-center'>Take payment</h2>
                    <div className='payment-main-content row'>
                        <div className='row'>
                            <div className='card-box'>
                                <p className='fw-bold'>No tip</p>
                                <p>£0.00</p>
                            </div>
                            <div className='card-box'>
                                <p className='fw-bold'>5%</p>
                                <p>£8.00</p>
                            </div>
                            <div className='card-box'>
                                <p className='fw-bold'>10%</p>
                                <p>£16.00</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='card-box'>
                                <p className='fw-bold'>15%</p>
                                <p>£24.00</p>
                            </div>
                            <div className='card-box'>
                                <p className='fw-bold'>20%</p>
                                <p>£32.00</p>
                            </div>
                            <div className='card-box'>
                                <p className='fw-bold'>Custom</p>
                                <p>...</p>
                            </div>
                        </div>
                    </div>

                    {/* ==== */}
                    <div className='payment-summary'>
                        <p> Payment summary </p>
                        <hr />
                        <div className='payment-detail'>
                            <div className='d-flex justify-content-between mb-3'>
                                <span>Subtotal</span>
                                <span>£200.00</span>
                            </div>

                            <div className='d-flex justify-content-between mb-3'>
                                <span>Promo code?</span>
                                <span>-£10.00</span>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mb-3'>
                            <span> Tip </span>
                            <span>£40.00</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span className='fw-bold'> Total  </span>
                            <span>£230.00</span>
                        </div>
                        <hr />
                        <div className='d-flex justify-content-between mb-3'>
                            <span className='fw-bold'> Deposit paid  </span>
                            <span>£50.00</span>
                        </div>
                        <div className='d-flex justify-content-between mb-5'>
                            <span className='fw-bold' style={{ color: "#C83A25" }}>
                                Balance due
                            </span>
                            <span>£180.00</span>
                        </div>
                        <p> Payment type </p>
                        <hr />
                        <div className='d-flex justify-content-between mb-3' onClick={handleNextStep}>
                            <div className='payment-type d-flex'>
                                <img src={cardIcon} alt="" />
                                <span>Card on file</span>
                            </div>
                            <div className='mt-2'>
                                <img src={rightArrow} alt="" />
                            </div>
                        </div>

                        <p> Cards on file </p>
                        <hr />

                        <div className='d-flex justify-content-between mb-3'>
                            <div className='payment-type d-flex'>
                                <img src={visaIcon} alt="" />
                                <span>••••• 0000</span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )}
        {currentStep ===2 && (
            <PaymentComplete />
            )}
        </>
    )
}

export default PaymentPreviewPage
