import React from 'react'
import Step1Img from "../../images/Vector1.svg"
import './consent-steps.scss'
import StepButton from '../common/StepButton'

const ConsentWalkInCard = () => {
  return (
    <>
      <div className="booking-card-main">
        <div className='img-div'>
            <img src={Step1Img} />
        </div>
        <div className='booking-card-content'>
            <h3>Walk-in + Consent</h3>
            <p>Send your client a consent form or fill it in together in person.</p>
        </div>
        {/* <div className='booking-card-btn'>
            <StepButton label={"Done"} blue={true} />
        </div> */}
      </div>
    </>
  )
}

export default ConsentWalkInCard
