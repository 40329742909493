import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_FORWARD_CONSENT_FORM_QUESTIONS } from "../../constants/forward-consent-form/ForwardConsentFormConst";

const GET_FORWARD_CONSENT_FORM_QUESTIONS_QUERY = gql`
query GetForwardConsentFormQuestion($bookingId: Int, $token: String) {
  getForwardConsentFormQuestion(booking_id: $bookingId, token: $token) {
    success
    message
    data {
      questions {
        question
        answerNew
        comment
        identifier
      }
      disclaimers {
        consent_form_id
        consent_form_name
        disclaimer
      }
    }
  }
}
  `;

export const ForwardConsentFormQuestionsQuery = () => {
  const dispatch = useDispatch();

  const [getForwardConsentFormQuestions, forwardConsentFormQuestionsData] = useLazyQuery(
    GET_FORWARD_CONSENT_FORM_QUESTIONS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getForwardConsentFormQuestion: responseData }) => {
        dispatch({
          type: GET_FORWARD_CONSENT_FORM_QUESTIONS,
          payload: responseData?.data,
        });
        if (responseData.success) {

        } else {

        }


      },
    }
  );
  const forwardConsentFormQuestionsQueryData = forwardConsentFormQuestionsData;
  const initForwardConsentFormQuestions = (data) => {
    getForwardConsentFormQuestions({
        variables: data,
    });
  };
  return { initForwardConsentFormQuestions, forwardConsentFormQuestionsQueryData };
};



