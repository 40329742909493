import React from 'react'
import backArrow from '../../../images/back_arrow.svg'
import checkIcon from '../../../images/on-boarding imgs/check.svg'
import '../add-new-clinic-main.scss'
import { useNavigate } from 'react-router'

const CompleteClinicAddress = () => {
    const navigate = useNavigate();

    const handleBackNavigate = () => {
        navigate('/add-new-clinic')
    }

    return (
        <>
            <div className='onboarding-location-main'>
                <div className='container'>
                    <div className='onboarding__header mt-5' style={{cursor:'pointer'}} onClick={handleBackNavigate}>
                        <img src={backArrow} alt='img' />
                    </div>
                    <div className="location__content">
                        <h1>Almost Done! </h1>
                        <p>Finish setting up your profile to accept bookings.</p>
                        {/*  */}
                        <div className='location__list' style={{flexDirection:'column'}}>
                            <div className='complete_profile_card mt-3'>
                                <div className='complete_profile_check__img'>
                                    <img src={checkIcon} width={30} height={30} alt='img' />
                                </div>
                                <h1>Location & hours</h1>
                                <p>Share your workspace location and operating hours with clients.</p>
                                <button className='btn'>Edit</button>
                            </div>

                            <div className='complete_profile_card  mt-5'>
                                <div className='complete_profile_check__img'>
                                    <img src={checkIcon} width={30} height={30} alt='img' />
                                </div>
                                <h1>Add a Service</h1>
                                <p>Start by listing your first service. You can easily add more once the first one is set up.</p>
                                <button className='btn'>Edit</button>
                            </div>


                            <div className='complete_profile_card mt-5'>
                                <div className='complete_profile_check__img'>
                                    <img src={checkIcon} width={30} height={30} alt='img' />
                                </div>
                                <h1>Get paid</h1>
                                <p>Activate online payments to enable client online bookings.</p>
                                <button className='btn'>Edit</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div >
        </>
    )
}

export default CompleteClinicAddress
