import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import "./date-time.scss";


// import checked from "../../../assets/cart/Checked.png";
// import calendarIcon from "../../../assets/cart/calendar.png";
// import locationIcon from "../../../assets/cart/location.png";
// import maleIcon from "../../../assets/cart/maleIcon.png";
// import placeholder from "../../../assets/cart/placeholder.png";
// import timeIcon from "../../../assets/cart/time.png";
// import footericon from "../../../images/Group 1000001993.png";
// import goback from "../../../assets/cart/GoBack.svg"
import { ReactComponent as BackArrow } from '../../../images/back_arrow.svg';



// import BasicDetailsModal from "../../modal/BasicModal/BasicDetailsModal";
import CustomCalendar from "./steps/CustomCalendar";
import TimeSection from "./steps/TimeSection";


import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ConfirmPayWeb from "../../ConfirmAndPay/ConfirmPayWeb";
import TeamMember from "../../TeamMember/TeamMember";
import { CustomCalendarSkeleton } from "../../clininc-cart-skeleton/calenadar-skeleton/CustomCalendarSkeleton";
import BannerHeader from "../../common/BannerHeader";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";
import useDeviceDetect from "../../common/useDeviceDetect";
import { GetAvailableTimeSlotsAction, GetClinicAvailableDateAction } from "../../redux/actions/dateTimeslots/DateTimeSlots";
import { CLEAR_TIME_SLOTS } from "../../redux/constants/dateTimeslots/DateTimeSlots";
import { GetNextAvailableDateForBookingsAction } from "../../redux/actions/getNextAvailableDateForBookings/GetNextAvailableDateForBookings";
import TodaysDateCalender from "../todaysDateCalender/TodaysDateCalender";

const DateTime = ({
    step,
    setStep,
    selectedTreatmentId,

    isMonthlySelected,
    setIsMonthlySelected,
    showSelectedDate,
    setShowSelectedDate,
    showSelectedTime,
    setShowSelectedTime,
    setFormatedDate,
    formatedDate,
    substep,
    setSubstep,
    clinicId,
    setClinicID,
    setSelectedTreatmentId,
    selectedTreatment,
    setSelectedTreatment,
    uniqueTeamMemberWeb,
    name,
    firstName,
    stringAvatar,
    teamMemberData,
    setIsRight,
    payableAmount,
    setSelectedTeamMember,
    selectedLocation,
    setSelectedLocation,
    selectedTeamMember,
    setShowSelectedTeamMember,
    selectedTreatments,
    setSelectedTreatments,
    modalTitle,
    selectedTimes,
    setSelectedTimes,
    payl8rData,
    isRight,
    setIsRightSectionOnDate,
    setSelectedPaymentOption,
    setTotalPrice,
    setTotalDeposit,
    totalDeposit,
    selectedPaymentOption,
    totalPrice,
    setLoader,
    loader,
    setYourDetails,
    yourDetails,
    setValidationErrors,
    validationErrors,
    setIsOpenPayment,
    isOpenPayment,
    setOpenPayLater,
    openPayLater,
    payLaterData,
    setPayLaterData,
    setBookingId,
    bookingId
}) => {
    // const moment = require("moment");
    const confirmPayRef = useRef();
    const [selectedDate, setSelectedDate] = useState(new Date());
    // State to store the selected month and year from the calendar
    const [selectedMonth, setSelectedMonth] = useState(
        selectedDate.getMonth() + 1
    );
    const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(null)
    const [isAvailableInCurrent, setIsAvailbleInCurrent] = useState(null);

    const [isOpenCalendar, setIsOpenCalendar] = useState(false) //for mobile
    const dispatch = useDispatch()
    const { clinicsAvailableDateData, initClinicsAvailableDateResponseFunction } = GetClinicAvailableDateAction();
    const { availableTimeSlotData, initAvalableTimeSlotData } = GetAvailableTimeSlotsAction()
    const { getNextAvailableDateForBookingsData, initGetNextAvailableDateForBookings } = GetNextAvailableDateForBookingsAction();

    const timeslots = useSelector(
        (state) => state?.GetDateTimeSlotsReducer?.timeslots
    );

    const { isLastStep } = useSelector((state) => state.businessLocationListReducer);

    const { screenWidth } = useDeviceDetect()

    const handleSelectedDate = (selectedDate) => {
        dispatch({
            type: CLEAR_TIME_SLOTS,
        });
        if (screenWidth < 720) {
            initAvalableTimeSlotData({
                clinicId: clinicId,
                date: moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD"),
                treatmentIds: selectedTreatment.map((item) => item.id),
            })
        }

        setShowSelectedDate(selectedDate)

    };
    const handleDateSelection = (selectedDate) => {

        // if (substep !== 303 && substep !== 304)
        // setSubstep(substep + 1);
        handleSelectedDate(selectedDate);
        // if (substep == 304) createBookingApiCall()
    };
    const handleSelectTimeSlot = (index, selectedTime) => {
        // dispatch({
        //     type: CREATE_BOOKING_ID,
        //     payload: {
        //         clinicId: selectedLocation?.clinic_id,
        //         date: moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD"),
        //         time: moment(selectedTime, "h:mm A").format("HH:mm:ss"),
        //     },
        // });
        // setSelectedTimeIndex(index);
        setSubstep(substep + 1);
    };
    // Your code for handling selected date and time slots would go here.
    const [registerData, setRegisterData] = useState({
        fname: "",
        lname: "",
        email: "",
        phone_number: "",
    });

    const handleNextButton = () => {

    };

    useEffect(() => {
        if (showSelectedDate) {
            console.log(showSelectedDate, "showSelectedDate")
            setSelectedYear(moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY"))
            setSelectedMonth(moment(showSelectedDate, "dddd, Do MMMM YYYY").format("MM"))
        }
    }
        , [showSelectedDate])
    useEffect(() => {
        if (substep === 301) {
            if (endDate) {
                if (moment(startDate).format("YYYY-MM") == moment().format("YYYY-MM")) {
                    initClinicsAvailableDateResponseFunction({
                        clinicId: clinicId,
                        startDate: moment().format("YYYY-MM-DD"),
                        endDate: endDate,
                    });
                } else {
                    initClinicsAvailableDateResponseFunction({
                        clinicId: clinicId,
                        startDate: startDate,
                        endDate: endDate,
                    });
                }
            }
        }
    }, [startDate, substep, endDate]);

    useEffect(() => {
        if (clinicId) {

            initGetNextAvailableDateForBookings({
                clinicId: clinicId,
                tokenSug: ""
            });
        }
    }, [clinicId])


    useEffect(() => {
        setIsAvailbleInCurrent(getNextAvailableDateForBookingsData?.data?.getNextAvailableDateForBookings?.success)
        // setIsRightSectionOnDate(getNextAvailableDateForBookingsData?.data?.getNextAvailableDateForBookings?.success)
        setSelectedMonth(moment(getNextAvailableDateForBookingsData?.data?.getNextAvailableDateForBookings?.data?.date).format('MM'))
    }, [getNextAvailableDateForBookingsData])


    return (
        <>
            {(isAvailableInCurrent == null) ? null : isAvailableInCurrent ? <div className={`${isRight==false ? " CustomeScrollWidth" : "date-time-main-div Main-Treatments-Date" }`}>
                {substep === 301 && (
                    <div className="calendar-main-div">
                        <div className={screenWidth < 720 ? "top-header" : ""}>
                            {
                                // !isLastStep &&
                                <BackArrow className={`cursor-pointer ${isLastStep ? "invisible" : ""}`} onClick={() => {
                                    // setIsRightSectionOnDate(true);
                                    setStep(2)
                                }} />
                            }
                            <BannerHeader title={"When are you free?"} />
                        </div>
                        {

                            clinicsAvailableDateData?.loading ?
                                <>
                                    <div>
                                        {screenWidth < 576 ? <SkeletonLoader type={"calendar"} /> : <CustomCalendarSkeleton />}
                                    </div>
                                </>
                                :
                                <CustomCalendar
                                    setStep={setSubstep}
                                    setShowSelectedDate={setShowSelectedDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    selectedMonth={selectedMonth}
                                    selectedYear={selectedYear}
                                    setSelectedMonth={setSelectedMonth}
                                    setSelectedYear={setSelectedYear}
                                    showSelectedDate={showSelectedDate}
                                    onSelectDate={handleDateSelection}
                                    setFormatedDate={setFormatedDate}
                                    setShowSelectedTime={setShowSelectedTime}
                                    dateData={
                                        clinicsAvailableDateData?.data?.getClinicAvailableDates
                                            ?.data?.items?.length > 0
                                            ? clinicsAvailableDateData?.data?.getClinicAvailableDates
                                                ?.data?.items
                                            : []
                                    }
                                    setIsOpenMobileCalendar={setIsOpenCalendar}

                                />
                        }
                    </div>

                )}
                {
                    substep === 302 && (
                        <TimeSection
                            timeData={timeslots}
                            showSelectedDate={showSelectedDate}
                            showSelectedTime={showSelectedTime}
                            setShowSelectedTime={setShowSelectedTime}
                            handleSelectTimeSlot={handleSelectTimeSlot}
                            setSubStep={setSubstep}
                            setStep={setStep}
                            setShowSelectedDate={setShowSelectedDate}
                            setFormatedDate={setFormatedDate}
                            clinicId={clinicId}
                            setIsOpenCalendar={setIsOpenCalendar}
                            isOpenCalendar={isOpenCalendar}

                            selectedTreatment={selectedTreatment}
                        />
                    )
                }
                {
                    substep === 303 &&
                    <TeamMember setSubStep={setSubstep}
                        selectedTeamMember={selectedTeamMember}
                        selectedTreatment={selectedTreatment}
                        setSelectedTreatment={setSelectedTreatment}
                        setSelectedTreatmentId={setSelectedTreatmentId}
                        // setShowSelectedTeamMember={setShowSelectedTeamMember}
                        setSelectedTeamMember={setSelectedTeamMember}
                        setTeamMemberSelectFun={(e) => {
                            setSelectedTeamMember(e)
                        }}
                    // data={uniqueTeamMemberWeb}

                    />
                }
                {
                    substep === 304 &&
                    <ConfirmPayWeb
                        step={step}
                        setStep={setStep}
                        selectedTreatment={selectedTreatment}
                        selectedTreatments={selectedTreatments}
                        setSelectedTreatments={setSelectedTreatments}
                        showSelectedDate={showSelectedDate}
                        setShowSelectedDate={setShowSelectedDate}
                        showSelectedTime={showSelectedTime}
                        setShowSelectedTime={setShowSelectedTime}
                        substep={substep}
                        setSubstep={setSubstep}
                        selectedTeamMember={selectedTeamMember}
                        setSelectedTeamMember={setSelectedTeamMember}
                        modalTitle={modalTitle}
                        setSubStep={setSubstep}
                        name={name}
                        payableAmount={payableAmount}
                        selectedTreatmentId={selectedTreatmentId}
                        setIsRight={setIsRight}
                        // firstName={teamMemberData.data.getAvailableTeamMembers.data.team_members[0].name.split(' ')[0]} // Extract the first name here
                        stringAvatar={stringAvatar}
                        clinicId={clinicId}
                        formatedDate={formatedDate}
                        selectedTimes={selectedTimes}
                        setSelectedTimes={setSelectedTimes}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        payl8rData={payl8rData}
                        totalPrice={totalPrice} 
                        setTotalPrice={setTotalPrice}
                        setTotalDeposit={setTotalDeposit} 
                        totalDeposit={totalDeposit}
                        setSelectedPaymentOption={setSelectedPaymentOption} 
                        selectedPaymentOption={selectedPaymentOption} 
                        loader={loader}
                        setLoader={setLoader}
                        setYourDetails={setYourDetails}
                        yourDetails={yourDetails}
                        setValidationErrors={setValidationErrors}
                        validationErrors={validationErrors}
                        setIsOpenPayment={setIsOpenPayment}
                      isOpenPayment={isOpenPayment}
                      setOpenPayLater={setOpenPayLater}
                      openPayLater={openPayLater}
                      payLaterData={payLaterData}
                      setPayLaterData={setPayLaterData}
                      setBookingId={setBookingId}
                      bookingId={bookingId}
                    />}



            </div> : <TodaysDateCalender setIsRightSectionOnDate={setIsRightSectionOnDate} setIsAvailbleInCurrent={setIsAvailbleInCurrent} NextAvailableDate={getNextAvailableDateForBookingsData?.data?.getNextAvailableDateForBookings?.data} setStep={setStep} />}
        </>
    );
};

export default DateTime;