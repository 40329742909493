import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_NEXTOFKIN_DETAILS } from "../../constants/client/NextOfKinConst";


const GET_NEXTOFKIN_DETAILS_QUERY = gql`
query GetNextOfKinDetails($clientId: Int!) {
    getNextOfKinDetails(clientId: $clientId) {
      success
      message
      data {
        clientId
        firstName
        lastName
        relation
        phoneCountry
        phone
        countryCode
      }
    }
  }
`;


export const GetNextOfKinDetails = () => {
  const dispatch = useDispatch();
  const [getNextOfKinDetailsData, nextOfKinDetailsData] = useLazyQuery(
    GET_NEXTOFKIN_DETAILS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getNextOfKinDetails: responseData }) => {
        dispatch({
          type: GET_NEXTOFKIN_DETAILS,
          payload: responseData.data,
        });
      },
    }
  );
  const nextOfKinDetailsQueryData = nextOfKinDetailsData;
  const initGetNextOfKinDetails = (data, successCallback, errorCallback) => {
    getNextOfKinDetailsData({
      variables: data,
    }).then((res) => {
      if(successCallback)
      successCallback(res.data.getNextOfKinDetails)
    }).catch((err) => {
      if(errorCallback)
      errorCallback(err);
    })
  };
  return { initGetNextOfKinDetails, nextOfKinDetailsQueryData };
};
