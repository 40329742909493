import { CLEAR_PRESCRIBER_LIST, GET_PRESCRIBER_LIST } from "../../constants/consentFormThankyou/getPrescriberListConst";

const initialState = {
  prescriberList: [],
  totalPrescriberCount: 0,
  loadedPrescriberCount: 0,
  prescriberListPage: 0,
  emptyPrescriberList: false
}

export default function connectedPrescriberListReducer(state = initialState, action) {

  switch (action.type) {

    case GET_PRESCRIBER_LIST:

      let temp = action?.payload?.items ? (state?.prescriberList).concat(action?.payload?.items) : []
      console.log("data akash prescriber", temp)
      return {
        ...state,
        emptyPrescriberList: action.payload?.items?.length === 0 || action.payload == null ? true : false,
        prescriberList: temp,
        totalPrescriberCount: action.payload?.total,
        prescriberListPage: state.prescriberListPage + 1,
        loadedPrescriberCount: temp.length
      };

    case CLEAR_PRESCRIBER_LIST:
      return {
        ...state,
        emptyPrescriberList: false,
        prescriberList: [],
        totalPrescriberCount: 0,
        prescriberListPage: 0,
        loadedPrescriberCount: 0
      };

    default:
      return state;
  }



}