import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { config } from '../../config';
import { ReactComponent as CloseIcon } from '../../images/close_icon.svg';
import StepButton from '../common/StepButton';
import { toast } from 'react-toastify';
import './ryft-style.scss';
import visaImage from '../../assets/cart/visa.png';
import masterCardImage from '../../assets/cart/mastercard-logo.png';
import { CompleteForwardConsentFormPaymentBooking } from '../redux/actions/forward-consent-form/ForwardConsentFormBooking';
import { SaveForwardConsentFormMutation } from '../redux/actions/forward-consent-form/saveForwardConsentForm';
import { useSelector } from 'react-redux';
import moment from 'moment';

const RyftComponent = ({
    clientSecret,
    successCallback,
    failureCallback,
    paymentType,
    isOpenPayment,
    setIsOpenPayment,
    accountId,
    ryftDataObj,
    isForwardConsentFormPayment = false,
    setStep,
    noShowPolicy = false,
    bookingId,
    selectedOption,
    note,
}) => {
    const ryftErrorRef = useRef();
    const [ryftIframeLoaded, setRyftIframeLoaded] = useState(false);
    const [paymentStateTime, setPaymentStartTime] = useState(null);

    const [ryftErrorMessage, setRyftErrorMessage] = React.useState('');

    const [loader, setLoader] = useState(false);

    const { appointmentDetails } = useSelector((state) => state?.forwardConsentFormOverviewReducer);
    const [appointmentDateShow, setAppointmentDateShow] = useState('');
    const [appointmentTimeShow, setAppointmentTimeShow] = useState('');
    const { paymentSummary } = useSelector((state) => state?.forwardConsentFormOverviewReducer);
    useEffect(() => {
        if(!appointmentDetails || !appointmentDetails?.appointmentDateOriginal) {
            setAppointmentDateShow("");
            setAppointmentTimeShow("");
        }
        else {
            let formattedAppointmentDate = moment(appointmentDetails?.appointmentDateOriginal).format("dddd, DD MMMM"), daysDiff = moment(appointmentDetails?.appointmentDateOriginal).startOf("day").diff(moment().startOf("day"), 'days');
            if(daysDiff == 0) {
                setAppointmentDateShow("");
                setAppointmentTimeShow("");
            }
            else {
                setAppointmentDateShow(formattedAppointmentDate);
                
                if(appointmentDetails?.appointmentTimeEnd) {
                    setAppointmentTimeShow(appointmentDetails?.appointmentTimeEnd);

                }
            }            
        }
    }, [appointmentDetails]);

    const {
        initiateCompleteForwardConsentFormPaymentBooking,
        completeForwardConsentFormPaymentBookingMutationData,
    } = CompleteForwardConsentFormPaymentBooking();
    const searchParams = new URLSearchParams(window.location.search);
    const { initiateSaveForwardConsentForm } = SaveForwardConsentFormMutation();

    //===========================*********NOTE:PLEASE UNCOMMENT CLIENTSECRET===========================*********
    // if (!clientSecret) {
    //     return;
    // }

    const scrollToError = () => {
        // window.scrollTo({
        //     top: ryftErrorRef?.current?.offsetTop,
        //     behavior: "smooth"
        // });
        ryftErrorRef?.current?.scrollIntoView({
            behavior: 'smooth'
        })
    }

    useLayoutEffect(() => {
        if(ryftErrorMessage) {
            scrollToError();
        }        
    }, [ryftErrorMessage]);

    const handlePaymentSubmit = async (e) => {
        // e.preventDefault()
        // try {
        //     let response = await window.paymentResponseCallback()
        //     successCallback(response)
        //    // alert("succeess")

        // } catch (e) {
        //     //alert("error")
        // }
        let Ryft = window.Ryft;
        const payButton = document.getElementById('ryft-pay-btn');
        payButton.disabled = true;
        setLoader(true);
        setRyftErrorMessage('');
        let errorMessage =
            "<div style='color:red;'>There seems to be an issue with the payment, if using through the app, please try through the faces website within your account. <br><br>If still having issues, please contact <a href='mailto:ben@facesconsent.com'>Ben@facesconsent.com</a> or call <a href='tel:01785558018'>01785 5580 18</a>.</div>";
        console.log('ryft payment attempt started');
        Ryft.attemptPayment()
            .then((paymentSession) => {
                console.log("attemptPayment test", paymentSession);
                if (paymentSession.status === 'Approved' || paymentSession.status === 'Captured') {
                    var redirectUrl = paymentSession.returnUrl,
                        paymentSessionId = paymentSession.id;
                    redirectUrl += '&ps=' + paymentSessionId;
                    if (isForwardConsentFormPayment) {
                        initiateCompleteForwardConsentFormPaymentBooking(
                            {
                                bookingId: bookingId,
                                paymentId: paymentSession?.id,
                                paymentType: 1,
                                cfrToken: searchParams?.get('token'),
                                selectedPayment:
                                    selectedOption === 'Pay Deposit'
                                        ? 'PAY_DEPOSIT'
                                        : selectedOption === 'Pay in full'
                                        ? 'PAY_In_FULL'
                                        : null,
                            },
                            (res) => {
                                if (res.success) {
                                    setLoader(false);
                                    setStep(4);
                                    // initiateSaveForwardConsentForm(
                                    //     {
                                    //         cfrId: parseInt(bookingId),
                                    //         token: searchParams.get('token'),
                                    //         notes: note,
                                    //     },
                                    //     (re) => {},
                                    //     (err) => {}
                                    // );

                                    //  navigate(`/bookings/${slug}/success?bookingId=${bookingId}&ps=${paymentIntent.id}`)
                                } else {
                                    setStep(3);
                                    setLoader(false);
                                    setRyftErrorMessage(res.message);
                                    //navigate(`/bookings/${slug}/fail?bookingId=${bookingId}&ps=${paymentIntent.id}`)
                                }
                            },
                            (e) => {
                                setStep(3);
                                setLoader(false);
                                setRyftErrorMessage(e.message);
                            }
                        );
                    } else {
                        window.location.href = redirectUrl;
                    }
                    // Payment successful - show the customer your order success page
                    return;
                }
                if (paymentSession.lastError) {
                    payButton.disabled = false;
                    setLoader(false);
                    const userFacingError = Ryft.getUserFacingErrorMessage(
                        paymentSession.lastError
                    );
                    console.log("userFacingError",userFacingError, paymentSession.lastError);
                    // Show userFacingError to customer
                    setRyftErrorMessage(errorMessage);                    
                }
                payButton.disabled = false;
                setLoader(false);
            })
            .catch((error) => {
                payButton.disabled = false;
                setLoader(false);                
                console.log("userFacingError test",error);
                if (error && error == 'Invalid card details') {
                    setRyftErrorMessage("<div style='color:red;'>"+error +"</div>");
                } else {
                    setRyftErrorMessage(errorMessage);
                }
                // Show error to customer
            });
        e.preventDefault();
    };

    useEffect(() => {
        if (clientSecret) {
            let Ryft = window.Ryft;
            let initializationValues = {
                publicKey: config.RYFT_PUBLIC_KEY,
                clientSecret: clientSecret,
                accountId: accountId,
                applePay: {
                    merchantName: 'Faces',
                    merchantCountryCode: 'GB',
                },
            };
            console.log('initializationValues', initializationValues);
            Ryft.init(initializationValues);

            Ryft.addEventHandler('walletPaymentSessionResult', (e) => {
                const paymentSession = e.paymentSession;
                setRyftErrorMessage('');
                if (paymentSession.status === 'Approved' || paymentSession === 'Captured') {
                    // payment successful, show the customer your order success page
                    return;
                }
                if (paymentSession.lastError) {
                    const userFacingError = Ryft.getUserFacingErrorMessage(
                        paymentSession.lastError
                    );
                    // Show userFacingError to customer
                }
            });

            Ryft.addEventHandler('cardValidationChanged', (e) => {
                const payButton = document.getElementById('ryft-pay-btn');
                setRyftErrorMessage('');
                payButton.disabled = !e.isValid;
            });

            Ryft.addEventHandler('paymentMethodSelectionChanged', (e) => {
                const payButton = document.getElementById('ryft-pay-btn');
                setRyftErrorMessage('');
                payButton.disabled = !e.paymentMethod;
            });
        }
    }, [clientSecret]);

    useEffect(() => {
        let timeoutObj = null;
        if(!isForwardConsentFormPayment) {
            // setPaymentStartTime(moment());
            console.log('use effect started');
            timeoutObj = setTimeout(() => {
                alert(
                    'Sorry but you have not completed your process in the predefined time. Please start the payment process again!'
                );
                // console.log("show toast error");
                setIsOpenPayment(false);
            }, 25 * 60 * 1000);
        }

        const checkElementLoaded = () => {
            const element = document.getElementById('ryft-pay-iframe');
            if (element) {
                setTimeout(() => {
                    setRyftIframeLoaded(true);
                },100);
            } else {
              // Element not yet loaded, check again after a delay
              setTimeout(checkElementLoaded, 100); // Adjust the delay as needed
            }
        };
      
        checkElementLoaded(); 

        return () => {
            if(!isForwardConsentFormPayment && timeoutObj) {
                console.log('use effect return');
                clearTimeout(timeoutObj);
            }            
        };
    }, []);

    return (
        <div className="container">
            {!isForwardConsentFormPayment && (
                <CloseIcon
                    className="cursor-pointer mb-5"
                    onClick={() => setIsOpenPayment(false)}
                />
            )}
            {isForwardConsentFormPayment && (
                console.log(ryftDataObj,"ryftDataObj"),
                <div className="consent-form-ryft-payment-card-section">
                    <div className="treatment-payment-section">

                        {paymentSummary?.processingFeeBearer  ? (<>
                            <div className="payment-card" style={{paddingBottom:"0px"}}>
                                <b style={{fontSize:"15px", fontWeight:500}}>Booking fees</b>
                                <p style={{fontSize:"15px", fontWeight:500}}>{ryftDataObj?.bookingfees}</p>
                            </div>
                            <div className="payment-card">
                            <b>Total to pay</b>
                            <p style={{fontSize:"16px", fontWeight:600}}>{ryftDataObj?.grandTotal}</p>
                        </div>
                        </>
                        ):<div className="payment-card">
                        <b>Total to pay</b>
                        <p>{ryftDataObj?.payableAmount}</p>
                    </div>}
                    </div>
                </div>
            )}
            <div
                className={`d-flex align-items-center justify-content-center flex-column ${
                    isForwardConsentFormPayment
                        ? 'ryft-checkout-main-consent-form'
                        : 'ryft-checkout-main'
                }`}
            >
                {!isForwardConsentFormPayment && (
                    <div className="ryft-header-section pb-3">
                        <div className="detail-section">
                            <h1>Date :</h1>
                            <p>{ryftDataObj?.date}</p>
                        </div>
                        <div className="detail-section">
                            <h1> Treatment price:</h1>
                            <p>{ryftDataObj?.treatmentPrice}</p>
                        </div>
                        <div className="detail-section">
                            <h1>Payable Amount:</h1>
                            <p>{ryftDataObj?.totalPrice}</p>
                        </div>
                    </div>
                )}

                <form
                    id="ryft-pay-form"
                    className="ryft-pay-form"
                    style={{}}
                    onSubmit={handlePaymentSubmit}
                >
                    <div className="statics-logos">
                        <div className="statics-logos__item">
                            <img src={visaImage} alt="visa" />
                        </div>
                        <div className="statics-logos__item">
                            <img src={masterCardImage} alt="mastercard" />
                        </div>
                    </div>

                                        
                    <div
                        id="ryft-pay-error"
                        dangerouslySetInnerHTML={{ __html: ryftErrorMessage }}
                        ref={ryftErrorRef}
                    ></div>

                    {noShowPolicy && isForwardConsentFormPayment && (
                        <div className={"no-show-policy-section "}>
                            {/* <h1>No show and cancellation policy</h1>
                            <p>
                                Card details are securely stored. No upfront charge is made. Details
                                could be billed depending on the businesses no show policy.
                            </p> */}

                            <h1>Payment and cancellations</h1>
                            <p>You won't be charged until after your treatment unless you cancel or reschedule {appointmentDateShow ? (appointmentTimeShow ? "after " + appointmentTimeShow +" " : "") + "on " + appointmentDateShow : ""}, in which case a fee will apply.</p>
                        </div>
                    )}

                    <div className='btnStickeyWrapper'>
                        <StepButton
                            id="ryft-pay-btn"
                            blue={true}
                            label={'PAY NOW'}
                            isLoading={loader}
                            disabled={!ryftIframeLoaded}
                        />
                    </div>                    
                </form>
                <div className="card-icon-"></div>
            </div>
        </div>
    );
};

export default RyftComponent;
