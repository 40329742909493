import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const PROCESS_FORGOT_PASSWORD = gql`
mutation ProcessForgotPassword($email: String, $code: String, $changePassword: Boolean, $password: String) {
    processForgotPassword(email: $email, code: $code, changePassword: $changePassword, password: $password) {
      success
      message
    }
  }
`;

export const ProcessForgotePassword = () => {
    // const dispatch = useDispatch()
    const [processForgotePasswordFunc, processForgotePassword] = useMutation(PROCESS_FORGOT_PASSWORD);
    const processForgotePasswordData = processForgotePassword;

    const initiateprocessForgotePassword = (
        data,
        successCallback,
        errorCallback
    ) => {
        processForgotePasswordFunc({
            variables: data,
        })
            .then(({ data: { processForgotPassword: responseData } }) => {
                console.log("dataaaaaaaaaa", data)
                console.log("responseData::+++===>>>", responseData)
                if (responseData?.success) {
                    toast.success(responseData.message)
                    successCallback(responseData);
                } else {
                    console.log("elseeeeeeeee")
                    toast.error(responseData.message)
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                console.log("error",error)
                errorCallback(error);
            });
    };

    return { initiateprocessForgotePassword, processForgotePasswordData }
}