import React, { useEffect, useRef, useState } from "react"
// import { Rating } from 'react-simple-star-rating'
// import ProgressBar from '@ramonak/react-progress-bar'
import Skeleton from 'react-loading-skeleton'

const SupportThreadListSkeleton = () => {
    return (
        <>
            {
                [0,1,2,3,4].map((val, index) => (
                    <div key={index} style={{padding : '5px 10px'}}>
                        <div className="skeltenWrapper">
                            <div className="row">
                                <div className="col-2 pe-0">
                                    <div className="skelImage">
                                    <Skeleton
                                        circle
                                        height="25px"
                                        containerClassName="avatar-skeleton"
                                    />
                                    </div>
                                </div>
                                <div className="col-10 ps-0">
                                    <div className="col-sm-12">
                                    <Skeleton height={19} />    
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <Skeleton height={19} />            
                                        </div>
                                        <div className="col-6">
                                            <Skeleton height={19} />            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default SupportThreadListSkeleton