import { CLEAR_HAIR_PRESCRIPTION_LINK_DATA, GET_HAIR_PRESCRIPTION_LINK_DATA } from "../../constants/hairPrescriptionLink/HairPrescriptionLinkConst";

const initialState = {
    hairPrescriptionLink: {
        full_url: "",
        slug: "",
        is_strip_connected: false
    },
};

export default function hairPrescriptionLinkReducer(state = initialState, action) {
    switch (action.type) {
        case GET_HAIR_PRESCRIPTION_LINK_DATA:
            return {
                ...state,
                hairPrescriptionLink: action.payload,
            };

        case CLEAR_HAIR_PRESCRIPTION_LINK_DATA:
            return {
                ...state,
                hairPrescriptionLink: {
                    full_url: "",
                    slug: "",
                    is_strip_connected: false
                },
            };

        default:
            return state;
    }
}
