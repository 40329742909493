import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_USER_INSURANCE_VERIFIED_STATUS_QUERY = gql`
 query GetUserProfile {
  getUserProfile {
    success
    message
    data {
      pendingDetails
      image_url
      user {
        usr_id
        usr_fname
        usr_lname
        usr_name
        usr_email
        usr_email_verified
        usr_country_name
        usr_country_code
        usr_phone_no
        usr_phone_country
        usr_phone_verified
        usr_dob
        usr_last_login
        usr_last_login_type
        usr_country
        usr_company_name
        usr_no_of_employees
        usr_vat_no
        userRoles
        image_url
        house_no
        street_name
        city
        post_code
        user_country_code
        booking_link
        occupations
      }
      usr_use_password_for_directory
      is_verified
      is_hide_banner
    }
  }
}
`;

export const GetUserInsuranceVerifiedStatusAction = () => {
  const [
    getUserInsuranceVerifiedResponseFunction,
    getUserInsuranceVerifiedResponseData,
  ] = useLazyQuery(GET_USER_INSURANCE_VERIFIED_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });

  const getUserInsuranceVerifiedData = getUserInsuranceVerifiedResponseData;

  const GetUserInsuranceVerifiedStatus = (
    data,
    successCallback,
    errorCallback
  ) => {
    getUserInsuranceVerifiedResponseFunction({
      variables: data,
    })
      .then((responseData) => {
        console.log(responseData,"reesss")
        if (responseData?.data?.getUserProfile?.success) {
          successCallback(responseData?.data?.getUserProfile );
        } else {
          errorCallback(responseData?.data?.getUserProfile );
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  return {
    getUserInsuranceVerifiedData,
    GetUserInsuranceVerifiedStatus,
  };
};
