import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const SAVE_FORWARD_CONSENT_FORM_MUTATION = gql`
mutation SaveForwardConsentForm($cfrId: Int, $allowForAdvertisement: Int, $token: String, $treatmentsData: [TreatmentDataInput], $questionsAndAnswer: [QuestionInput], $notes: String, $signatureBase64: String, $ignore: Boolean) {
  saveForwardConsentForm(cfrId: $cfrId, allowForAdvertisement: $allowForAdvertisement, token: $token, treatmentsData: $treatmentsData, questionsAndAnswer: $questionsAndAnswer, notes: $notes, signatureBase64: $signatureBase64, ignore: $ignore) {
    success
    message
    data {
      questionsAndAnswer {
        cfrqId
        question
        answer
        comment
      }
      treatmentsData {
        treatmentId
        imageName
      }
      clientIdImg
    }
  }
}
`;

export const SaveForwardConsentFormMutation = () => {
  const [saveForwardConsentFormRequest, saveForwardConsentFormResponseData] =
    useMutation(SAVE_FORWARD_CONSENT_FORM_MUTATION);
  const dispatch = useDispatch();
  const saveForwardConsentFormData = saveForwardConsentFormResponseData;

  const initiateSaveForwardConsentForm = (
    data,
    successCallback,
    errorCallback
  ) => {
    saveForwardConsentFormRequest({
      variables: data,
    })
      .then(({ data: { saveForwardConsentForm: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateSaveForwardConsentForm, saveForwardConsentFormData };
};
