import moment from "moment";

export const upperCaseWordsOfSentent = (str) => {
    return str?.split(" ")
        ?.map((word) => {
            return word[0]?.toUpperCase() + word?.substring(1);
        })
        ?.join(" ");
};


export const formatCount = (count) => {
    console.log("COUNT", count);
    if (count >= 1000000000) {
        const formatted = (count / 1000000000).toFixed(count % 1000000 === 0 ? 0 : 1);
        return formatted.replace(/\.0$/, '') + 'B';
    } else if (count >= 1000000) {
        const formatted = (count / 1000000).toFixed(count % 1000000 === 0 ? 0 : 1);
        return formatted.replace(/\.0$/, '') + 'M';
    } else if (count >= 1000) {
        const formatted = (count / 1000).toFixed(count % 1000 === 0 ? 0 : 1);
        return formatted.replace(/\.0$/, '') + 'k';
    } else {
        return count
    }
}

export const TIME = (start = "00:00:00", end = "24:00:00") => {
    const hours = [];
    const moment = require('moment');

    console.log(start, "asha time", end)

    const startTime = moment(start, 'HH:mm:ss');
    const endTime = moment(end, 'HH:mm:ss');


    let currentTime = startTime.clone();

    while (currentTime.isBefore(endTime)) {
        hours.push(currentTime.format('HH:mm'));
        currentTime.add(15, 'minutes');
    }

    console.log(hours);
    return hours;
};


export let pounds = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
});

export const generateCustomDates = (startDate) => {
    const startDateStr = moment(startDate);

    // Get the first day of the month
    const firstDayOfMonth = startDateStr.clone().startOf('month');

    // Get the last day of the month
    const lastDayOfMonth = startDateStr.clone().endOf('month');

    // Generate an array of dates for the month
    const datesArray = [];
    let currentDate = firstDayOfMonth.clone();

    // initGetCalendarAppointmentCountData({
    //   startDate: firstDayOfMonth.format('YYYY-MM-DD'),
    //   endDate: lastDayOfMonth.format('YYYY-MM-DD'),
    // })

    while (currentDate.isSameOrBefore(lastDayOfMonth)) {
        // Check if the current date is not in the past
        datesArray.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'day');
    }
    return datesArray;
}

export const weeklyViewDaySevenDateChange = (date, type) => {

    if (type == "start") {
        return date.isoWeekday() == 7 ? date.subtract(1, "days").clone().startOf('week').add(1, "days") : date.clone().startOf('week').add(1, "days")
    }
    else {
        return date.isoWeekday() == 7 ? date.subtract(1, "days").clone().endOf('week').add(1, "days") : date.clone().endOf('week').add(1, "days")
    }
}

export const convertMinutesToHours = (totalMinutes) => {
    if (totalMinutes <= 0) {
        return `${0}m`;
    }
    if (totalMinutes < 60) {
        return `${totalMinutes}m`;
    }
    const hours = totalMinutes / 60;
    return `${Math?.round(hours * 100) / 100}h`;
};

export const categorizeByMonth = (events, title, format) => {
    // Create an object to hold events grouped by month
    let eventsByMonth = {};

    // Iterate through each event
    if (events?.length > 0) {
        events.forEach(event => {

            // Formulate a key like "2024-06" for grouping
            // const monthKey = `${year}-${month}`;
            const monthKey = moment(event[`${title}`], format).format('ddd DD MMM,YYYY');

            // If the key doesn't exist in eventsByMonth, create it as an empty array
            if (!eventsByMonth[monthKey]) {
                eventsByMonth[monthKey] = [];
            }

            // Add the event to the respective month's array
            eventsByMonth[monthKey].push(event);
        });
    }

    return eventsByMonth;
}

export const isUnderAge = (dob) => {
    const ageDifference = moment().diff(moment(dob), 'years');
    console.log('ageDifference',ageDifference, dob);
    return ageDifference < 18;
}