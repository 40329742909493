import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import NavHeader from '../../common/NavHeader/NavHeader';
import { ReactComponent as CloseIcon } from '../../../images/close_icon.svg';
import SearchIcon from '../../../images/client/search_icon.svg';
import { useState } from 'react';
import MedicalFormList from './MedicalFormList';
import DisclaimerBox from '../edit-form/common-component/DisclaimerBox';
import '../edit-form/edit-main.scss';
import MedicalQuestionItem from './MedicalQuestionItem';
import {
    GetMedicalFormQuestionsQuery,
    SaveMedicalFormQuestionsMutation,
} from '../../redux/actions/formsTemplate/getFormTemplateList';
import showToast from '../../common/CustomToast/CustomToast';
import { manageFormsRoutePattern, previewMedicalFormRoute } from '../../../Routes';
import StepButton from '../../common/StepButton';
import { useSearchParams } from 'react-router-dom';
import CustomeHeading from '../../customeHeading/customeHeading';
import useWindowDimension from '../../../utils/useWindowDimensions';

function EditMedicalForm() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const headingRef = useRef();

    const initialActiveTab = searchParams.get('tab');
    const [searchText, setSearchText] = useState('');
    const [questionList, setQuestionList] = useState(null);
    const [activeQuestionList, setActiveQuestionList] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editQuestionData, setEditQuestionData] = useState({
        question: null,
        id: null,
    });
    const [selectedParentQuestion, setSelectedParentQuestion] = useState(null);

    const { initGetMedicalFormQuestions, getMedicalFormQuestionsStatus } =
        GetMedicalFormQuestionsQuery();
    const { initSaveMedicalFormQuestions, saveMedicalFormQuestionsStatus } =
        SaveMedicalFormQuestionsMutation();

    const getMedicalFormQuestionsHelper = (shallRefresh = false) => {
        if (shallRefresh) {
            setQuestionList(null);
            setActiveQuestionList(null);
        }
        initGetMedicalFormQuestions((data) => {
            console.log('data-ixcvlj', data);
            setQuestionList(data);
            let activeList = data;
            if (selectedParentQuestion) {
                const parentQuestion = data.find((item) => item.id === selectedParentQuestion.id);
                activeList = parentQuestion.subQuestions;
            }
            setActiveQuestionList(activeList);
        });
    };

    useEffect(() => {
        getMedicalFormQuestionsHelper();
    }, []);

    const handleSave = () => {
        showToast('success', 'Form saved successfully!');
        // navigate(manageFormsRoutePattern);
        navigate(initialActiveTab ? `/manage-forms?tab=${initialActiveTab}` : "/manage-forms");
    };

    const clearSearch = () => {
        setSearchText('');
        headingRef.current.clearSearch();
    }

    const handleToggleEditAction = (questionData) => {
        if (questionData) {
            console.log('question-lkjkdflkjf', questionData);
            setEditQuestionData({
                question: questionData.question,
                id: questionData.id,
            });
        }
        setIsEditing(true);
        clearSearch();
    };

    const toggleEditLabel = () => {
        if(selectedParentQuestion) return 'Add sub question';
        return 'Add question';
    };

    const handleEditQuestion = (text) => {
        // on click of close icon - don't edit question
        if(!text) {
            setIsEditing(false);
            setEditQuestionData({
                question: null,
                id: null,
            });
            if (selectedParentQuestion) {
                const parentQuestion = questionList.find((item) => item.id === selectedParentQuestion.id);
                const activeList = parentQuestion.subQuestions;
                setActiveQuestionList(activeList);
            }
            return;
        }

        const payload = {
            pmqId: editQuestionData.id,
            question: text,
            parentId: selectedParentQuestion?.id || 0,
        };
        initSaveMedicalFormQuestions(payload, () => {
            setIsEditing(false);
            setEditQuestionData({
                question: null,
                id: null,
            });
            getMedicalFormQuestionsHelper(true);
        });
    };

    const handleAddSubQuestion = (questionData) => {
        clearSearch();
        setSelectedParentQuestion({
            question: questionData.question,
            id: questionData.id,
        });
        const parentQuestion = questionList.find((item) => item.id === questionData.id);
        const activeList = parentQuestion.subQuestions;
        setActiveQuestionList(activeList);
        // setIsEditing(true);
    };

    const handleBackNavigation = () => {
        if(!isEditing && !selectedParentQuestion) {
            navigate(initialActiveTab ? `/manage-forms?tab=${initialActiveTab}` : "/manage-forms");
            return;

        }

        clearSearch();

        if(isEditing) {
            setIsEditing(false);
            setEditQuestionData({
                question: null,
                id: null
            });
        }
        if(selectedParentQuestion && !isEditing) {
            setSelectedParentQuestion(null);
            setActiveQuestionList(questionList);
        }
    }

    const handleSearchText = (data) => {
        // const newText = e.target.value;
        setSearchText(data);
    };

    const dimensions = useWindowDimension();
    const [headerIndicatorHeight, setHeaderIndicatorHeight] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [secTitleHeight, setSecTitleHeight] = useState(0);
    const [btnWrapperHeight, setBtnWrapperHeight] = useState(0);
    const [infoboxHeight, setInfoboxHeight] = useState(0);

    console.log(
        headerIndicatorHeight,
        'headerIndicatorHeight',
        headerHeight,
        'headerHeight',
        secTitleHeight,
        'secTitleHeight',
        btnWrapperHeight,
        'btnWrapperHeight'
    );

    const updateHeights = (excludeInfoBox = false) => {
        setHeaderIndicatorHeight(
            document.getElementsByClassName('form-mode-indicator')[0]?.offsetHeight || 0
        );
        setHeaderHeight(
            document.getElementsByClassName('nav-header-without-padding-container')[0]
                ?.offsetHeight || 0
        );
        setSecTitleHeight(
            document.getElementsByClassName('SectionHeadingWrapper')[0]?.offsetHeight || 0
        );
        setBtnWrapperHeight(document.getElementsByClassName('BtnWrapper')[0]?.offsetHeight || 0);

        if (excludeInfoBox) {
            setInfoboxHeight(0);
        } else {
            setInfoboxHeight(
                (document.getElementById('edit-consent-form-info-box')?.offsetHeight + 20) || 0
            );
        }
    };

    useEffect(() => {
        // if(consentFormPreviewQueryData){
        //   setTimeout(()=>{
        //     updateHeights();
        //   }, 100)
        // }
        updateHeights();

        window.addEventListener('resize', updateHeights);
        return () => {
            window.removeEventListener('resize', updateHeights);
        };
    }, []);

    const scrollableDivHeight =
        dimensions?.height -
        headerIndicatorHeight -
        headerHeight -
        secTitleHeight -
        infoboxHeight -
        btnWrapperHeight +
        'px';


    return (
        <div className="Main-Edit-Manage-Forms-page edit-medical-form-page">
            <div className="form-mode-indicator">
                <p>You're in editing mode</p>
            </div>
            <div className="CustomeSearchClient">
                <NavHeader
                    applyPadding={false}
                    handleOnBack={handleBackNavigation}
                    btnLabel="Save"
                    disabled={isEditing}
                    isBackWithButton={true}
                    onClickCallback={handleSave}
                />
                <div className="maxWidth540 paddintTop80">
                    <CustomeHeading
                        Title={'Medical form'}
                        Desc={''}
                        isSearchDisabled={isEditing}
                        ref={headingRef}
                        isSearch={true}
                        // isSearch={!isEditing}
                        onSearch={handleSearchText}
                        SearchPlaceholder={'Search questions'}
                    />

                    <div className="consent-edit-form w-100">
                        <DisclaimerBox updateLayout={() => updateHeights(true)} />
                    </div>
                    
                    <div style={{height:scrollableDivHeight, overflowY: 'auto'}}>

                        {selectedParentQuestion && (
                            <div className="main-question-wrapper">
                                <p>{selectedParentQuestion.question}</p>
                            </div>
                        )}
                        {/* {isEditing ? (
                            <div className="consent-edit-form w-100">
                                <DisclaimerBox />
                                <MedicalQuestionItem
                                    defaultQuestion={editQuestionData.question}
                                    isLoading={saveMedicalFormQuestionsStatus.loading}
                                    onEditQuestion={handleEditQuestion}
                                />
                            </div>
                        ) : (
                            <MedicalFormList
                                search={searchText}
                                isSubQuestionList={!!selectedParentQuestion}
                                onAddSubQuestion={handleAddSubQuestion}
                                postDeleteAction={() => getMedicalFormQuestionsHelper(true)}
                                onEditTrigger={handleToggleEditAction}
                                isLoading={getMedicalFormQuestionsStatus.loading || !questionList}
                                questionList={activeQuestionList}
                            />
                        )} */}
                        <MedicalFormList
                            editComponent={
                                <div className="consent-edit-form w-100">
                                    <MedicalQuestionItem
                                        isEditList
                                        defaultQuestion={editQuestionData.question}
                                        isLoading={saveMedicalFormQuestionsStatus.loading}
                                        onEditQuestion={handleEditQuestion}
                                    />
                                </div>
                            }
                            editQuestionData={editQuestionData}
                            isEditing={isEditing}
                            search={searchText}
                            isSubQuestionList={!!selectedParentQuestion}
                            onAddSubQuestion={handleAddSubQuestion}
                            postDeleteAction={() => getMedicalFormQuestionsHelper(true)}
                            onEditTrigger={handleToggleEditAction}
                            isLoading={getMedicalFormQuestionsStatus.loading || !questionList}
                            questionList={activeQuestionList}
                        />

                        {isEditing && !editQuestionData.id && (
                            <div className="consent-edit-form w-100">
                                <MedicalQuestionItem
                                    defaultQuestion={editQuestionData.question}
                                    isLoading={saveMedicalFormQuestionsStatus.loading}
                                    onEditQuestion={handleEditQuestion}
                                />
                            </div>
                        )}

                    </div>

                    <div className="BtnWrapper BtnWrapper--editConsent">
                        <StepButton
                            disabled={isEditing}
                            onClick={() => navigate(previewMedicalFormRoute + '?tab=3')}
                            gray
                            label='Preview'
                        />
                        <StepButton
                            blue
                            disabled={isEditing}
                            onClick={() => handleToggleEditAction()}
                            label={toggleEditLabel()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditMedicalForm;
