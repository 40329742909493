import React, { useState } from "react";
import "./no-client.scss";
import StepButton from "../common/StepButton";
import NoClientImage from "../../images/client/no-client.png";
import NewClient from "./NewClient";
import OffCanvasModal from "../modal/OffCanvasModal/OffCanvasModal";
import NoAppointmentImage from "../../images/client/no-identity.png";
import BusinessSettingHeader from "../common/businessSettingHeader/BusinessSettingHeader";
import BusinessSidebar from "../sidebar/BusinessSidebar";


const NoClient = ({ fromOffCanvas, labelText, fromAppointment = false, setCurrentPage, currentPage }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    fromAppointment ? (
      <div className="no-client-container">
        {/* <BusinessSettingHeader
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
        <BusinessSidebar
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        /> */}
        <div className="no-client-content">
          <img src={NoAppointmentImage} alt="no-client" />
          <h1>No appointments</h1>
          <p>Your client's appointment history is currently empty.</p>
        </div>
      </div>
    ) : (
      <div className="MainBusinessProfile">
        {/* <BusinessSettingHeader
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        /> */}
        <div className="Business-Profile-Main-Wrapper">
          {/* <BusinessSidebar
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          /> */}
          <div className="no-client-container">
            <div className="no-client-content">
              <img src={NoClientImage} alt="no-client" />
              <h1>
                {labelText || "No clients yet"}
              </h1>
              {!labelText && (
                <p>Begin by adding your first client.</p>
              )}
              {!fromOffCanvas && (
                <StepButton
                  blue={true}
                  label="Add a client"
                  onClick={() => setIsOpen(true)}
                />
              )}
            </div>
          </div>
        </div>
        <NewClient
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          fromCalendar={true}
        />
      </div>
    )
  );
  
};

export default NoClient;
