import React, { useEffect, useState } from "react";
import "./HairProductIdentity.scss";
import { useDropzone } from 'react-dropzone'
import CustomModal from "../../../modal/CustomModal";
import { MdOutlineImage } from "react-icons/md";
import SubHeading from "../hair-product-common/hair-product-sub-heading/SubHeading";
import HairProductIdentityModal from "./HairProductIdentityModal";
import StepButton from "../../../common/StepButton";
import HairProductWhatsAppButton from "../hair-product-common/hair-product-whatsApp-btn/HairProductWhatsAppButton";
import closeIcon from "../../../../images/close_icon.svg";
import showToast from "../../../common/CustomToast/CustomToast";

export default function HairProductIdentity({ activeMain, identityDocProp, OnhandleContinue }) {
    const [open, setOpen] = useState(false);
    const [previewImg, setPreviewImg] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);
    // const [imagename, setImageName] = useState("")
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setUploadedImage(identityDocProp);
    }, [activeMain]);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/png, application/pdf",
        // noClick: (uploadedImage || getApiData?.id ? true : false),
        noClick: (uploadedImage ? true : false),
        onDrop: (acceptedFiles) => {
            if (acceptedFiles) {
                const uploadfile = acceptedFiles[0];

                if (uploadfile.type === "application/pdf" || uploadfile.type.startsWith("image/")) {
                    setUploadedImage(uploadfile);
                } else {
                    showToast("error", "You can only upload image or PDF files.", "", false)
                }
            } else {
                showToast("error", "You can only upload image or PDF files.", "", false)
            }
        },
    });

    const removeImage = () => {

        setUploadedImage(null);
        setOpen(false);
        setPreviewImg(false)

    };

    const handleContinue = () => {
        if (!uploadedImage) {
            setErrorMessage("Please add driving license or passport.");
        } else {
            OnhandleContinue(uploadedImage);
        }
    };

    const openPDF = () => {
        if (uploadedImage) {
            const url = URL.createObjectURL(uploadedImage);
            window.open(url);
        }
    };

    useEffect(()=>{
        window.scrollTo(0, 0);
     },[])

    return (
        <div className="hair-product-main-identity">
            <div className="Identity-FormWrapper flex-gap-normal">
                <SubHeading
                    title={`Identity`}
                    desc="Please confirm your Identity, to help us verify you."

                />
                <p className="form-label">Driving License or Passport</p>

                <div className="customeDropDownWrapper">
                    <div className="customeDropDown" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {uploadedImage ? (
                            <div className="preview">
                                <div className="image-preview">
                                    {uploadedImage.type === "application/pdf" ? (
                                        // <img src={imgUrl} alt="Uploaded document" onClick={openPdf} />
                                        <span className="pdfSpan" onClick={openPDF}>
                                            <i class="fa-regular fa-file-pdf  pdfIcon"></i>
                                            {/* Uploaded document */}
                                        </span>
                                    ) : (
                                        <img src={URL.createObjectURL(uploadedImage)} alt="Uploaded document" onClick={() => { setPreviewImg(true) }} />
                                    )}
                                    <div className="btnClose1">
                                        <img
                                            className="closeBtn"
                                            src={closeIcon}
                                            alt="Delete"
                                            onClick={() => setOpen(true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                            : (
                                <div>
                                    <MdOutlineImage className='dropImage' />
                                    <p className="upload">Upload</p>
                                </div>
                            )}
                    </div>
                </div>
                {errorMessage && <p className="error">{errorMessage}</p>}
                <div className='bottomStickeyBtn'>          
                    <div className="continue-btn-div">
                        <StepButton
                            label={"Continue"}
                            blue={true}
                            // isLoading={saveFinanceIdentityQueryData.loading}
                            onClick={() => handleContinue()} />
                    </div>
                    <div className="whatsapp-margin">
                        <HairProductWhatsAppButton />
                    </div>
                </div>
            </div>

            {/* Modle Previw*/}
            <CustomModal
                modalOpen={previewImg}
                setModalOpen={setPreviewImg}
                type="review"
                className={"previewImgModel"}
                modalBody={
                    <>
                        <div className='previewImgModelWrapper'>
                            {uploadedImage && (
                                <img
                                    src={URL.createObjectURL(uploadedImage)}
                                    alt="Uploaded"
                                />
                            )}
                        </div>
                        <div className="RemovePreviewImg">
                            <button className="OutLineBtn" onClick={() => { setOpen(true) }}>Remove</button>
                        </div>
                    </>
                }
                modaltitle="Are you sure?"
            />

            <HairProductIdentityModal
                open={open}
                setOpen={setOpen}
                title="Are you sure?"
                className={"ConfirmationDeleteModal"}
                content="Are you sure you want to remove this document'?"
                onConfirm={() => {
                    removeImage();
                    setOpen(false);
                }}
                onCancel={() => setOpen(false)}
            />
        </div>
    );
}