import { GET_DASHBOARD_DATA, GET_DASHBOARD_LIST, GET_DASHBOARD_LIST_SUCCESS, GET_DASHBOARD_USER_PROFILE_DATA } from "../../constants"


const initialState = {
    dashboardListData: null,
    insurancePage: null,

    dashboardSkeletonFlag: false
}
export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_LIST:
            return {
                ...state,
                dashboardListData: null,
                dashboardSkeletonFlag: true,
                insurancePage: null
            }
        case GET_DASHBOARD_LIST_SUCCESS:
            return {
                ...state,
                dashboardListData: action.payload.data,
                dashboardSkeletonFlag: false,
                insurancePage: action.payload.insurancePage
            }
        case GET_DASHBOARD_DATA:
            return {
                ...state,
                dashboardListData: action.payload,
                insurancePage: null,
                dashboardSkeletonFlag: false
            }
        default:
            return state
    }
}  