import React, { useState } from 'react'
import essImg1 from "../../../images/icons/Blue.svg"
import essImg2 from "../../../images/icons/Blue1.svg"
import essImg3 from "../../../images/icons/Blue2.svg"
import essImg4 from "../../../images/icons/Blue3.svg"
import essImg5 from "../../../images/icons/Blue4.svg"
import essImg10 from "../../../images/icons/Actions.svg"

import essImg6 from "../../../images/icons/user-org.svg"
import essImg7 from "../../../images/icons/pres-org.svg"
import essImg8 from "../../../images/icons/shop-org.svg"
import essImg9 from "../../../images/icons/finance-org.svg"
import { webBaseUrl } from '../../../config'
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { USER_ROLES } from '../../../utils/constants'
import { domainName } from '../../../styles'
import { getPrescriberRegistrationPage, getSearchClientRoute } from '../../../Routes'
import AppointmentConsentModal from '../../modal/ConsentFormModal/AppointmentConsentModal'
import ConsentFormCustomModal from '../../modal/ConsentFormModal/ConsentFormModal'
import DottedSlider from '../../ConsentForm-Steps/ModalSlider'
import ConsentWalkInCard from '../../ConsentForm-Steps/ConsentWalkInCard'
import AppointmentsConsentModal from '../../ConsentForm-Steps/AppointmentConsentCard'
import StepButton from '../../common/StepButton'
import BookingCard from '../../ConsentForm-Steps/BookingCard'

const EssentialsContainer = ({ EssentialsViewOnClick }) => {
  const navigate = useNavigate()
  const { profileData } = useSelector(state => state?.GetProfileDataReducer)
  const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)

  let rolesList = profileData?.roles ? profileData?.roles : [];
  let prescriptionRedirectionLink = webBaseUrl + '/prescriber/register';
  let { prescriber, non_prescriber, non_registered_prescriber } = USER_ROLES
  if (rolesList.indexOf(non_registered_prescriber) > -1) {
    prescriptionRedirectionLink = webBaseUrl + '/pharmacy/list';
  }
  else if (rolesList.indexOf(non_prescriber) > -1) {
    prescriptionRedirectionLink = webBaseUrl + '/prescription/request-prescription';
  }
  else if (rolesList.indexOf(prescriber) > -1) {
    prescriptionRedirectionLink = webBaseUrl + '/prescription/create';
  }

  const essentialsData = [
    {
      title: "Consent",
      imageIcon: domainName == "faces" ? essImg10 : essImg6,
      redirectUrl: webBaseUrl + "/appointment-form",
      // reactPath: true
      // onclick: () => setIsModalOpen(true)

    },
    {
      title: "Clients",
      imageIcon: domainName == "faces" ? essImg5 : essImg6,
      // redirectUrl: getSearchClientRoute(),
      onclick: () => navigate(getSearchClientRoute())
    },
    {
      title: "Finance hub",
      imageIcon: domainName == "faces" ? essImg4 : essImg9,
      // redirectUrl: frontendURL + '/finance-registration'
      onclick: () => navigate('/finance-registration')

    },
    // {
    //   title: "Medical form",
    //   imageIcon: domainName == "faces" ? essImg2 : essImg8,
    //   redirectUrl: webBaseUrl + '/medical-questions/edit'

    //     },
    {
      title: "Prescriptions",
      imageIcon: domainName == "faces" ? essImg1 : essImg7,
      redirectUrl: webBaseUrl + '/prescription/request-prescription'
      // redirectUrl: prescriptionRedirectionLink
    },


  ]


  // ===
  const [currentStep, setCurrentStep] = useState(3);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const openModal = () => {
    scrollToTop();
    setCurrentStep(3);
    setIsModalOpen(true);
  };

  // Function to handle moving to the next step in the modal
  const handleCurrentStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  return (
    <>
      <div className='essentials-container-main-div Main-Essentials-DashBoard'>
        <div className="essentials-container mb-5 col-md-12">
          <div className="d-flex justify-content-between align-items-center mb-2 col-md-12">
            <h2 className="align-items-center">Essentials</h2>
            <p className="text-decoration-underline  mb-0 cursor-pointer" onClick={EssentialsViewOnClick}>View all</p>
          </div>
          <div className="Essentials-Grid">
            {
              essentialsData.map((item, index) =>
                <div className='Essentials-Item' key={index}>
                  {
                    item.redirectUrl ? (<div className="cursor-pointer" onClick={() => {
                      if (item.title === "Prescriptions") {
                        console.log("clicked inside", item.title)
                        UserProfileData?.user?.userRoles.forEach((role) => {
                          console.log(role, "role");
                          if (role === 4) {
                            // for practitioner
                            window.location.href = webBaseUrl + "/prescription/request-prescription";
                          }
                          else if (role === 3) {
                            // for prescriber
                            window.location.href = webBaseUrl + "/prescription/create";
                          }
                        });
                  
                        // If neither 3 nor 4 exists, navigate to the default page.
                        if (!UserProfileData?.user?.userRoles.includes(3) && !UserProfileData?.user?.userRoles.includes(4)) {
                          navigate(getPrescriberRegistrationPage(), { state: { from: 'plus_icon_header_bar' } });
                        }
                      }
                      else {
                        window.location.href = item.redirectUrl
                      }
                    }





                    }>
                      <div class="essential-card">
                        <p style={{ 'padding-right': 'min(10px, 10%)' }}>{item.title}</p>
                        <div>
                          <img src={item.imageIcon} alt='' />
                        </div>
                      </div>
                    </div>) : (
                      <div className="cursor-pointer" onClick={item.onclick ? item.onclick : null}>

                        <div class="essential-card">
                          <p style={{ 'padding-right': 'min(10px, 10%)' }}>{item.title}</p>
                          <div>
                            <img src={item.imageIcon} alt='' />
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>


              )
            }
          </div>
        </div>
      </div >

      {/* ========================CONSENT FORM MODAL======================== */}
      {
        currentStep === 1 && (
          <ConsentFormCustomModal
            modalOpen={isModalOpen}
            setModalOpen={setIsModalOpen}
            modalBody={
              <>
                <div>
                  <BookingCard />
                  <DottedSlider
                    totalSteps={2}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep} />
                  <StepButton label={"Next"} blue={true} onClick={handleCurrentStep} />
                </div>
              </>
            }
          />
        )
      }

      {
        currentStep === 2 && (
          <ConsentFormCustomModal
            modalOpen={isModalOpen}
            setModalOpen={setIsModalOpen}
            modalBody={
              <>
                <div>
                  <ConsentWalkInCard />
                  <DottedSlider
                    totalSteps={2}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep} />
                  <StepButton label={"Done"} blue={true} onClick={handleNextStep} />
                </div>
              </>
            }
          />
        )
      }

      {
        currentStep === 3 && (
          <AppointmentConsentModal
            modalOpen={isModalOpen}
            setModalOpen={setIsModalOpen}
            modalBody={
              <>
                <div>
                  <AppointmentsConsentModal />
                  {/* <StepButton label={"Complete"} blue={true} onClick={handleLastStep} /> */}
                </div>
              </>
            }
          />
        )
      }

    </>
  )
}

export default EssentialsContainer