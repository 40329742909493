import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { EditAfterCareFormRoute, PreviewAfterCareFormRoute, businessDashboardRoute, createNewAfterCareFormRoute, createNewFormRoute, editFormRoute, editMedicalFormRoute, getBusinessSettingPageRoute, getEditAftercareFormRoute, previewFormRoute, previewMedicalFormRoute } from '../../../Routes';
import { ReactComponent as PlusIcon } from '../../../images/add_event.svg';
import { ReactComponent as RightArrow } from '../../../images/client/right_arrow.svg';
import SearchIcon from "../../../images/client/search_icon.svg";
import { ReactComponent as EditAftercareIcon } from '../../../images/icons/edit-form-icon.svg';
import { ReactComponent as EditConsentIcon } from '../../../images/icons/edit-consent-form-icon.svg';
import { ReactComponent as EditMedicalIcon } from '../../../images/icons/edit-medical-form-icon.svg';
import { ReactComponent as EyeIcon } from "../../../images/icons/circleEyeIcon.svg";
import EmptyClient from '../../Client/EmptyClient';
import NavHeader from '../../common/NavHeader/NavHeader';
import StepButton from '../../common/StepButton';
import CustomModal from '../../modal/CustomModal';
import { CLEAR_CONSENT_FORM_DETAILS } from '../../redux/constants/walk-in-form-const/clientWalkInConst';
import FormsList from './FormsList';
import './manage-consent-form.scss';
import { ReactComponent as CloseIcon } from "../../../images/close_icon.svg";
import CreateNewFormPopup from '../edit-form/common-component/CreateNewFormPopup';
import MyformsList from '../myforms/MyformsList';
import { ReactComponent as ConcentFormIcon } from "../../../images/consent-new-icon.svg";
import { ReactComponent as AfterCareIcon } from "../../../images/aftercare-new-icon.svg";
import MenuIcon from '../../../images/icons/verticalDots.svg';
import MedicalIcon from '../../../images/medical-icon-list.svg';
import { useSearchParams } from 'react-router-dom';
import CustomeHeading from '../../customeHeading/customeHeading';
import CustomeSearchBar from '../../customeSearchBar/customeSearchBar';
import { AddAndEditUserConsentForm } from '../../redux/actions/walk-in-form/walkInAddFormsList';
import showToast from '../../common/CustomToast/CustomToast';
import { CircularProgress } from '@material-ui/core';
import { webBaseUrl } from '../../../config';

let isInternalRoute = false;


const tabConst = [
    {
        id: 0,
        name: "Consent",
        editIcon: <EditConsentIcon />,
    },
    {
        id: 1,
        name: "Aftercare",
        editIcon: <EditAftercareIcon />,
    },
    {
        id: 2,
        name: "Medical",
        editIcon: <EditMedicalIcon />,
    },
    {
        id: 3,
        name: "My forms",
    },
]

const ManageForms = ({ currentStep, setCurrentStep, setIsModalOpen }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const isInternalRouteState = !!state?.internalRoute;
    console.log('isInternalRoute----State', isInternalRouteState);
    const [searchParams, setSearchParams] = useSearchParams();

    const initialActiveTab = searchParams.get('tab');

    const [formName, setFormName] = useState('');
    const [activeTab, setActiveTab] = useState(initialActiveTab ? +initialActiveTab : 1);
    const [searchText, setSearchText] = useState('');
    const [activeTabFormTitle, setActiveTabFormTitle] = useState(initialActiveTab ? tabConst[+initialActiveTab - 1].name : 'Consent');
    const [isSearchedTriggered, setIsSearchedTriggered] = useState(false);
    const [selectedFormId, setSelectedFormId] = useState('');


    //CREATE FROM BLANK FORM MODAL
    const [createNewFormModal, setCreateNewFormModal] = useState(false);
    const [afterCareFormModal, setAfterCareFormModal] = useState(false);
    const [openEmptyClient, setOpenEmptyClient] = useState(false);
    const [isSelectFormTypeModalOpen, setIsSelectFormTypeModalOpen] = useState(false);
    const [isAftercareSelected, setIsAftercareSelected] = useState(false);
    const searchRef = useRef();
    const { initAddAndEditUserConsentFormData, addUserConsentFormQueryData } =
        AddAndEditUserConsentForm();
    const { formsDataList } = useSelector(state => state?.formsListReducer)

    useEffect(() => {
        if(isInternalRouteState) {
            isInternalRoute = true;
        }
    },[isInternalRouteState])

    console.log('isInternalRoute', isInternalRoute);

    const menuData = [
        {
            title: `${activeTab === 1 ? 'Customise' : 'Edit'} ${tabConst[activeTab - 1].name.toLowerCase()} form`,
            icon: tabConst[activeTab - 1].editIcon,
            action: "edit",
        },
        {
            title: "Preview",
            icon: <EyeIcon />,
            action: "preview",
        }
    ];

    console.log('formsDataList', formsDataList);

    const createConsentFormHelper = () => {
        const selectedForm = formsDataList.find(item => item.id === selectedFormId);
        initAddAndEditUserConsentFormData(
            {
                createConsentFormId: selectedFormId,
                consentFormName: selectedForm.name,
            },
            (data) => {
                showToast('success', 'Consent form customised successfully');
                const newConsentId = data.data.createConsentForm.data.con_frm_id;
                navigate(editFormRoute(newConsentId) + '?tab=4');
            },
            (err) => {
                showToast('error', err.message);
            }
        );
    }

    const handleMenuAction = (action) => {
        if(addUserConsentFormQueryData.loading) {
            return;
        }
        // document.body.click();
        if (activeTab == 1) {
            if (action === "edit" && selectedFormId) {
                setIsAftercareSelected(false);
                // setCreateNewFormModal(true)
                // navigate(editFormRoute(selectedFormId));
                createConsentFormHelper();
            } else if (action === "preview" && selectedFormId) {
                dispatch({ type: CLEAR_CONSENT_FORM_DETAILS })
                navigate(previewFormRoute(selectedFormId) + '?tab=1');
            }
        }
        if (activeTab == 2) {
            if (action === "edit" && selectedFormId) {
                navigate(getEditAftercareFormRoute(selectedFormId) + '?tab=2');
                // setCreateNewFormModal(true);
                setIsAftercareSelected(true);
                // setAfterCareFormModal(true)
                // navigate(EditAfterCareFormRoute(selectedFormId));
            } else if (action === "preview" && selectedFormId) {
                // dispatch({ type: CLEAR_CONSENT_FORM_DETAILS })
                navigate(PreviewAfterCareFormRoute(selectedFormId) + '?tab=2');
            }
        }
        if(activeTab === 3) {
            if(action === 'edit') {
                navigate(editMedicalFormRoute + '?tab=3');
            }
            if(action === 'preview') {
                navigate(previewMedicalFormRoute + '?tab=3');
            }
        }
    };



    const popoverClick = (

        <Popover  id="popover-trigger-click" className='appointment-option-main Custome-Popover-Design-Set p-3'>
            {
                menuData.map((items, index) => (
                    <div className='appointment-list-option-container' key={index} onClick={() => handleMenuAction(items.action)}>
                        <div className='appointment-list-left-popover-content'>
                            {items.icon}
                            <p>{items.title}</p>
                        </div>
                        <div style={{ color: '#000' }} className='appointment-list-right-popover-content'>
                            {items.action === 'edit' && addUserConsentFormQueryData.loading ? <CircularProgress size={20} color='inherit' /> : <RightArrow />}
                        </div>
                    </div>
                ))
            }
        </Popover>

    );

    const handlePlusClick = () => {
        if (activeTab == 1) {
            setActiveTab(4)
            setOpenEmptyClient(true)
        }
        if (activeTab == 4) {
            setOpenEmptyClient(true)
            setSelectedFormId(null);
            // setCreateNewFormModal(true);
            setIsSelectFormTypeModalOpen(true);
        }
    }

    const toggleAftercareSelected = (state) => {
        setIsAftercareSelected(state);
    }

    const handleSearchText = (data) => {
        // const newText = e.target.value;
        setIsSearchedTriggered(true);
        setSearchText(data);
    
      };

    return (
        <>
            <div className='Main-Manage-Forms-page'>
                <div className='CustomeSearchClient'>
                    <NavHeader isBackOnly={true} applyPadding={false} handleOnBack={() => {
                        if(isInternalRoute) {
                            navigate(getBusinessSettingPageRoute);
                        }
                        else {
                            window.location.href = webBaseUrl + '/appointment-form';
                        }
                        // navigate(businessDashboardRoute())
                    }} />
                    <div className='search-client-container maxWidth540 paddintTop80'>
                        <CustomeHeading
                            Title={activeTab == 4 ? "My forms" : `${activeTabFormTitle} form${activeTab !== 3 ? 's' : ''}`}
                            Desc={activeTab == 1 ? "View, edit, and make new forms or additions to existing consent information" : activeTab == 2 ? "View, edit, and make new forms or additions to existing aftercare information" : activeTab == 3 ? "View and edit medical form as needed." : "Customise your own forms or edit and amend some of the forms we have done for you"}
                            isSearch={false}
                        />
                        <div className='form-tab-div'>
                            {
                                tabConst.map((item, index) => 
                                    <p 
                                        className={`${activeTab == index + 1 ? "active" : ""}`} 
                                        onClick={() => { 
                                            setSearchText(""); 
                                            searchRef?.current?.clearSearch();
                                            setIsSearchedTriggered(false);
                                            setActiveTab(index + 1); 
                                            setActiveTabFormTitle(item.name); 
                                            setSearchParams({tab: (index + 1).toString()}) 
                                        }}
                                    >
                                        {item.name}
                                    </p>
                                )
                            }
                        </div>
                        <CustomeSearchBar ref={searchRef} onSearch={handleSearchText} SearchPlaceholder={'Search form'} />

                        <div className="client-tile-container customMyFormListGrid">
                            {activeTab === 4 && formName == "" ?

                                <MyformsList 
                                    searchText={searchText} 
                                    isSearchedTriggered={isSearchedTriggered && searchText.length > 2}
                                    onOpenSelectFormTypeModal={handlePlusClick}
                                />
                                :
                                activeTab === 3
                                ?
                                <div className="FormsListItem w-100">
                                    <div className="FormsListItemContent">
                                        <div className="heart-icon-image">
                                            <img
                                                src={MedicalIcon}
                                                alt='medical icon'
                                            />
                                        </div>
                                        <div className="ItemContent">
                                            <h1>Medical form</h1>
                                        </div>
                                    </div>
                                    <OverlayTrigger rootClose trigger="click" placement="auto" overlay={popoverClick}>
                                        <div className='FormsListItem__menu'>
                                            <img
                                                style={{ cursor: 'pointer' }}
                                                src={MenuIcon}
                                                alt="menu"
                                            />
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                :

                                (
                                    <div className='left-content d-flex flex-column'>
                                        {
                                            <FormsList toggleAftercareSelected={toggleAftercareSelected} activeTab={activeTab} popoverClick={popoverClick} searchText={searchText} isSearchedTriggered={isSearchedTriggered} setSelectedFormId={setSelectedFormId} />
                                        }
                                    </div>
                                )
                            }
                        </div>
                        {/* {activeTab !== 2 && activeTab !== 3 && (
                            <div className='flot-button'>
                                <PlusIcon
                                    onClick={() => {
                                        handlePlusClick();
                                    }}
                                />
                            </div>
                        )} */}

                    </div>
                </div>
            </div>

            {/* CREATE AFTER CARE FORM */}
            <CustomModal
                modalOpen={afterCareFormModal}
                setModalOpen={setAfterCareFormModal}
                modaltitle={"Name your form"}
                type={"common-width"}
                className={'customeNameFormModel'}
                isCloseImage={true}
                
                modalBody={
                    <>
                        <div className="create-form-div">
                            <div className="input-form-div">
                                <input type="text" name='form-name' placeholder='Name form' onChange={(e) => setFormName(e.target.value)} />
                                <StepButton blue={true} label={"Create"} disabled={formName.trim() == ""} onClick={() => navigate(createNewAfterCareFormRoute(formName))} />
                            </div>
                        </div>
                    </>
                }
            />


            {/* CREATE FROM BLANK FORM MODAL */}
            <CustomModal
                modalOpen={createNewFormModal}
                setModalOpen={setCreateNewFormModal}
                className={'customeNameFormModel ModelMaxWidth540'}
                modaltitle={"Name your new form"}
                type={"common-width"}
                isCloseImage={true}
                modalBody={<CreateNewFormPopup isEditMyform={!selectedFormId} isAftercare={isAftercareSelected} formId={selectedFormId} />}
            />

            <CustomModal
                modalOpen={isSelectFormTypeModalOpen}
                setIsOpenMobileCalendar={() => setIsSelectFormTypeModalOpen(false)}
                setModalOpen={setIsSelectFormTypeModalOpen}
                modaltitle={"Create new form"}
                modaltitleCSS={"discount-title"}
                className={"CustomeAddform"}
                modalBody={
                    <div className="discount-price-container">
                    <div 
                        className="d-flex justify-content-between align-items-center cursor-pointer" 
                        onClick={() => { 
                            dispatch({ type: CLEAR_CONSENT_FORM_DETAILS });
                            navigate(editFormRoute('new') + '?tab=4');
                            // setCreateNewFormModal(true); 
                            setIsSelectFormTypeModalOpen(false);
                            setIsAftercareSelected(false);
                        }}>
                        <div className="d-flex align-items-center left-content gap-3 mt-3">
                        <ConcentFormIcon />
                        <h1>Consent form</h1>
                        </div>
                        <RightArrow />
                    </div>
                    <div 
                        className="d-flex justify-content-between align-items-center cursor-pointer" 
                        onClick={() => { 
                            navigate(getEditAftercareFormRoute('new'));
                            // setCreateNewFormModal(true); 
                            setIsSelectFormTypeModalOpen(false);
                            setIsAftercareSelected(true);
                        }}
                    >
                        <div className="d-flex align-items-center left-content gap-3 mt-3">
                        <AfterCareIcon />
                        <h1>Aftercare form</h1>
                        </div>
                        <RightArrow />
                    </div>
                    </div>
                }
            />
        </>
    )
}

export default ManageForms