import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_USER_PROFILE_DATA } from "../../constants";
import Cookies from "js-cookie";


// ========================GET USER PROFILE========================
const GET_USER_PROFILE_QUERY = gql`
query GetUserProfile {
  getUserProfile {
    success
    message
    data {
      pendingDetails
      image_url
      user {
        usr_id
        usr_fname
        usr_lname
        usr_name
        usr_email
        usr_email_verified
        usr_country_name
        usr_country_code
        usr_phone_no
        usr_phone_country
        usr_phone_verified
        usr_dob
        usr_last_login
        usr_last_login_type
        usr_country
        usr_company_name
        usr_no_of_employees
        usr_vat_no
        userRoles
        image_url
        occupations
      }
      usr_use_password_for_directory
      is_verified
      is_hide_banner
    }
  }
}
`;

export const GetUserProfileAction = () => {
  const dispatch = useDispatch();
  const [
    GetUserProfileResponseFunction,
    getUserProfileResponseData,
  ] = useLazyQuery(GET_USER_PROFILE_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getUserProfile: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: GET_USER_PROFILE_DATA,
          payload: responseData?.data
        })
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
        Cookies.remove("faces_access_token");
        window.location.href = process.env.REACT_APP_WEB_BASE_URL + '/login';

      }
    },
  });
  //this will be called from components
  const getUserProfileQueryData = getUserProfileResponseData; //variable

  const initGetUserProfileData = (data) => {
    GetUserProfileResponseFunction({
      variables: data,
    });
  };

  return { getUserProfileQueryData, initGetUserProfileData };
};

