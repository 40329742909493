import React from 'react'
import "./noappointmentavailable.scss"
// import NoAppointmentCalender from "../../../src/images/noappointment.svg"
import NoAppointmentCalender from "../../../src/images/noappointmentcalender.png"

import StepButton from '../common/StepButton'
const NoAppointmentAvailable = () => {
  return (
    <div className="no-appointment-main-container">
      <div className='no-appointment-container'>
        <div className='hm-cus-w73'>
          <img src={NoAppointmentCalender} alt="not found" />
        </div>
        <h1>No Available Appointments</h1>
      </div>
    </div>
  )
}

export default NoAppointmentAvailable