import React from 'react'
import CarouselComponent from '../../pages/carousel-component/carousel-component';
import Skeleton from 'react-loading-skeleton';

const DashboardAppointmentsSkeleton = () => {
    return (
        <div className='dashboard-appointments-skeleton-main-div'>
            <div className='appointments-container-main-div'>
                <div className="appointments-container mb-5">
                    <div className="d-flex justify-content-between mb-4">
                        <div className="d-flex align-items-end gap-2">
                            <h2 className="my-auto">Appointments</h2>
                            <span className="text-decoration-underline cursor-pointer pb-1">View all</span>
                        </div>
                    </div>
                    <CarouselComponent nav
                        data={
                            [...Array(3)].map((item, index) =>
                                <div className="appointment-card">
                                    <Skeleton height={22} width={100} />

                                    <div className="">
                                        <Skeleton height={20} width={"100%"} />
                                    </div>
                                    <Skeleton height={20} width={"90%"} />
                                    <Skeleton height={20} width={"80%"} />
                                </div>
                            )
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default DashboardAppointmentsSkeleton