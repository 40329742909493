import { CLEAR_CLIENT_DETAILS, GET_CLIENT_DETAILS, GET_CLIENT_TILE_STATUS, SAVE_CLIENT_DETAILS_FOR_APPOINTMENT, UPDATE_CLIENT_TILE_STATUS } from "../../constants";
import { SAVE_CHECK_PASSWORD_DATA } from "../../constants/client/ClientCheckPassword";

const initialState = {
  clientDetails: [],
  clientIdentityStatus: false,
  clientNoteStatus: false,
  clientNextOfKinStatus: false,
  clientMedicalDetailsStatus: false,
  clientCheckPasswordData: null,
  selectedClient: null
};

export default function clientDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_DETAILS:
      return {
        ...state,
        clientDetails: action.payload,
      };
    case GET_CLIENT_TILE_STATUS:
      console.log("client tile status", action)
      return {
        ...state,
        clientIdentityStatus: action.payload?.identityAdded,
        clientNoteStatus: action.payload?.notesAdded,
        clientNextOfKinStatus: action.payload?.nextOfKinAdded,
        clientMedicalDetailsStatus: action?.payload?.medicalDetailsAdded
      };
    case CLEAR_CLIENT_DETAILS:
      return {
        ...state,
        clientDetails: [],
      };

    case UPDATE_CLIENT_TILE_STATUS:
      return {
        ...state,
        clientIdentityStatus: action.payload,
      }

    case SAVE_CHECK_PASSWORD_DATA:
      return {
        ...state,
        clientCheckPasswordData: action.payload,
      }

    case SAVE_CLIENT_DETAILS_FOR_APPOINTMENT:
      return {
        ...state,
        selectedClient: action.payload,
      }

    default:
      return state;
  }
}
