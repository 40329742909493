import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const SAVE_STRIPE_CARD_DETAILS_MUTATION = gql`
mutation SaveCardInStripe($cfrId: Int, $cardToken: String, $cfrToken: String, $type: String) {
    saveCardInStripe(cfrId: $cfrId, cardToken: $cardToken, cfrToken: $cfrToken, type: $type) {
      success
      message
    }
  }
`;

export const SaveStripeCardDetailsMutation = () => {
    const [saveStripeCardDetailsRequest, saveStripeCardDetailsResponseData] =
        useMutation(SAVE_STRIPE_CARD_DETAILS_MUTATION);
    const dispatch = useDispatch();
    const saveStripeCardDetailsData = saveStripeCardDetailsResponseData;

    const initiateSaveStripeCardDetails = (
        data,
        successCallback,
        errorCallback
    ) => {
        saveStripeCardDetailsRequest({
            variables: data,
        })
            .then(({ data: { saveCardInStripe: responseData } }) => {
                if (responseData?.success) {
                    successCallback(responseData);
                } else {
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                errorCallback(error);
            });
    };
    return { initiateSaveStripeCardDetails, saveStripeCardDetailsData };
};
