import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_PRESCRIPTION_SERVICE=gql`
query GetPrescriptiondashboardcounts {
    getPrescriptiondashboardcounts {
      success
      message
      data {
        myPharmacy
        prescriptionRequests
        unusedPrescriptions
        chat
        totalPrescriptionService
      }
    }
  }

`;


export const GetPrescriptionServiceAction=()=>{
    const [getPrescriptionServiceResponseFunction,
           getPrescriptionServiceResponseData]=useLazyQuery(GET_PRESCRIPTION_SERVICE,{
            fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted:({getPrescriptiondashboardcounts:responseData})=>{
            if (responseData.success) {
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        }
           })

           const getPrescriptionServiceQueryData=getPrescriptionServiceResponseData;
           const initGetPrescriptionServiceData=(data)=>{
               getPrescriptionServiceResponseFunction({
                variables:data
               })
           };

           return {getPrescriptionServiceQueryData,initGetPrescriptionServiceData}
}