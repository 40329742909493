import './appointment-form-main.scss'
import React, { useState } from 'react'
import starIcon from '../../../../images/star.svg'
import shopIcon from '../../../../images/headers/bag.svg'
import UserNameIcon from '../../../../images/Actions.svg'
import downArrow from '../../../../images/header_arrow.svg'
import homeIcon from '../../../../images/headers/house.svg'
import manageIcon from '../../../../images/headers/dots.svg'
import noUser from '../../../../images/client/no-identity.svg'
import newIcon from '../../../../images/headers/plus-stroke.svg'
import calenderIcon from '../../../../images/headers/calendar.svg'
import serviceIcon from '../../../../images/icons/basket_icon.svg'
import bellIcon from '../../../../images/icons/notification_icon.svg'
import rightArrow from '../../../../images/new-appointment/right_arrow.svg'
import leftIcon from '../../../../images/new-appointment/appointmenticon.svg'
import StepButton from '../../../common/StepButton'
import CreateAfterCareForm from './CreateAfterCareForm'
import EditAfterCareForm from './AfterCareFormEdit'
// import BatchNumberList from './BatchNumberList'



const AfterCareDashboard = () => {
    const [currentStep, setCurrentStep] = useState(1);

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    }
    return (
        <>
            {currentStep === 1 && (
                <div className='after-care-main container'>
                    <div className='after-care-header'>
                        <div className='after-care-header-left-content'>
                            <h1>Faces</h1>
                        </div>
                        <div className='after-care-header-right-content'>
                            <img src={bellIcon} alt='bell icon' />
                            <img src={serviceIcon} alt='bell icon' />
                            <span className='d-flex rating-cls'>
                                <img src={starIcon} width={25} height={25} alt='star icon' />
                                <p>4.71</p>
                            </span>
                            <img src={UserNameIcon} alt='User Name Icon' />
                            <span>Company name</span>
                            <img src={downArrow} width={35} height={35} alt='arrow Icon' />
                        </div>
                    </div>
                    <div className='after-care-section'>
                        <div className='after-care-section-left-content'>
                            <div className='d-flex mb-4'>
                                <img src={homeIcon} />
                                <p>Home</p>
                            </div>
                            <div className='d-flex mb-4'>
                                <img src={calenderIcon} />
                                <p>Calendar</p>
                            </div>
                            <div className='d-flex mb-4'>
                                <img src={shopIcon} />
                                <p>Shop</p>
                            </div>
                            <div className='d-flex mb-4'>
                                <img src={newIcon} />
                                <p>New</p>
                            </div>
                            <div className='d-flex mb-4'>
                                <img src={manageIcon} />
                                <p>Manage</p>
                            </div>
                        </div>
                        <div className='after-care-section-right-content mt-1'>
                            <h1>Forms</h1>
                            <div className='after-care-form mt-4'>
                                <span className='btn form-btn mx-2'>Consent</span>
                                <span className='btn form-btn mx-2'>Aftercare</span>
                                <span className='btn form-btn mx-2'>Medical</span>
                                <span className='btn form-btn active mx-2'>My forms</span>
                            </div>
                            <div className='after-care-form-details'>
                                <div className='after_care_content'>
                                    <img src={noUser} alt='no user' />
                                </div>
                                <div className='after_care_content_sec'>
                                    <h1>No forms yet</h1>
                                    <p>You haven't customised any forms. Start by editing an existing form or create a new one from scratch.</p>
                                </div>
                                <div className='after_care_content_btn'>
                                    <StepButton label={"Get started"} blue={true} onClick={handleNextStep} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {currentStep === 2 && (
                <CreateAfterCareForm currentStep={currentStep} setCurrentStep={setCurrentStep} />
            )}
            {currentStep === 3 && (
                <EditAfterCareForm currentStep={currentStep} setCurrentStep={setCurrentStep} />
            )}
            
        </>
    )
}

export default AfterCareDashboard
