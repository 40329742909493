import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const DELETE_TEAM_MEMBER_DETAILS_MUTATION = gql`
mutation DeleteTeamMemberdetails($pwtmId: Int) {
    deleteTeamMemberdetails(pwtm_id: $pwtmId) {
      success
      message
    }
  }
`;

export const DeleteTeamMemberDetails = () => {
  const [deleteTeamMemberDetailsRequest, deleteTeamMemberDetailsResponseData] =
    useMutation(DELETE_TEAM_MEMBER_DETAILS_MUTATION);
  const dispatch = useDispatch();
  const deleteTeamMemberDetailsMutationData = deleteTeamMemberDetailsResponseData;

  const initiatedeleteTeamMemberDetails = (
    data,
    successCallback,
    errorCallback
  ) => {
    deleteTeamMemberDetailsRequest({
      variables: data,
    })
      .then(({ data: { deleteTeamMemberdetails: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiatedeleteTeamMemberDetails, deleteTeamMemberDetailsMutationData };
};
