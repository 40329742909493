import React, { useEffect, useRef, useState } from "react"
import moment from "moment";
import noDataImg from "../../../src/assets/cart/no-data.png"

const SupportThreadElement = ({ socketNewMessageList, listItemRefs, supportChatData, openTreadChat, submitFeedback }) => {
    // Helper function to format a date without the time component
    const formatToCustomFormat = (date) => {
        var format = moment(date).creationData().format;
        if(format){
            return moment(date,format);
        }else{
            return moment(date,'DD-MM-YYYY hh:mm A');
        }
    };
    return (
        supportChatData != null ? (
            supportChatData?.length > 0 ? (
                supportChatData?.map((item, key) => (
                <li
                    ref={(el) => (listItemRefs.current[`${item.enc_id}`] = el)}
                    key={key}
                    data-element-id={item.sct_id}
                    data-status={item.sct_status}
                    data-feedback={item.feedback}
                    data-thread_id={item.enc_id}
                    data-profile_img={item.usr_profile}
                    data-enc_id={item.enc_id}
                    data-cat_image={!item.category ? '' : item.category.image_url}
                    data-dt={JSON.stringify(item)}
                    data-user_id={item.sct_usr_id}
                    data-date={new Date(item.created_at).toLocaleDateString('en-GB')}
                    className="list-group-item w-100 listing-element-container"
                    onClick={openTreadChat}
                >
                    <a className="profile-all-thread-list">
                        <div className="d-flex align-items-center profile-all-thread w-100">
                            <div>
                                <img
                                    src={item.last_message_sender_profile}
                                    className="rounded-circle profile-pic thread-list-user-image"
                                    alt="User Profile"
                                    style={{ width: '50px', height: '50px' }}
                                />
                            </div>
                            <div className="row px-2 w-100">
                            <div className="col-md-12 h6 text-primary">
                                <span className="font-inter last_msg_sender_name text-primary">
                                {!item.last_message ? '' : item.last_message.sender_name}
                                </span>
                                <span
                                className={`ms-2 px-2 rounded p-1 m-1 hm-custom-primary-bg text-white thread-unread-count ${
                                    !item.unread_count || item.unread_count === 0 ? 'hidden-element' : ''
                                }`}
                                >
                                {item.unread_count > 99 ? '99+' : item.unread_count}
                                </span>
                                {item.sct_status !== 0 && (
                                <span className="font-weight-normal bg-success text-white">
                                    {' '}
                                    <i className="fa fa-check-circle"> </i> Resolved{' '}
                                </span>
                                )}
                            </div>
                            <p className="font-inter last-message col-6 text-black hm-margin-0">
                                {item.lastMessage && item.lastMessage.length > 15
                                ? `${item.lastMessage.substring(0, 15)}...`
                                : item.lastMessage}
                            </p>
                            <p className="last-message-time col-6 text-end text-black hm-margin-0">
                                {item.lastMessageDate && (
                                <>
                                    {
                                        moment().isSame(formatToCustomFormat(item.lastMessageDate), 'day')
                                        ? formatToCustomFormat(item.lastMessageDate).format('hh:mm A')
                                        : formatToCustomFormat(item.lastMessageDate).format('DD-MM-YYYY')
                                    }
                                </>
                                )}
                            </p>
                            <div className="col-md-12">
                                <span
                                    className="font-weight-normal bg-primary text-white submit-feedback-option"
                                    data-thread_id={item.enc_id}
                                    data-profile_img={item.usr_profile}
                                    style={{
                                        display:
                                        item.sct_status === 1 && !item.feedback ? 'inline-block' : 'none',
                                    }}
                                    onClick={ submitFeedback } 
                                >
                                <i className="fa fa-comment"></i> Submit Feedback
                                </span>
                            </div>
                            </div>
                            <div className="text-right" style={{ float: 'right' }}>
                            {item.admin_unread_message && (
                                <p className="unread-message-badge">{item.admin_unread_message}</p>
                            )}
                            </div>
                        </div>
                    </a>
                </li>
                ))
            ) : (
                !socketNewMessageList ? (
                <>
                    <figure className="text-center">
                        <img class="no-tickets" src={noDataImg} alt="No Tickets Found" />
                        <figcaption className="caption">No Tickets Found</figcaption>
                    </figure>
                </>
                ) : null
            )
        ) : null
    )
}

export default SupportThreadElement;