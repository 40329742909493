import React, { useState } from "react";
import { ReactComponent as SupportIcon } from "../../../../images/sprint6/support.svg";
import { ReactComponent as ExploreIcon } from "../../../../images/sprint6/Explore.svg";
import { ReactComponent as FreecallIcon } from "../../../../images/sprint6/Freecall.svg";
import { ReactComponent as FeedbackIcon } from "../../../../images/sprint6/Feedback.svg";
import { ReactComponent as VideoIcon } from "../../../../images/sprint6/Video.svg";
import { ReactComponent as RightArrowIcon } from "../../../../images/sprint6/rightIcon.svg";
import BusinessNavigation from "../../../common/businessNavigation/BusinessNavigation";
import { webBaseUrl } from "../../../../config";
import "./BusinessSupport.scss";
import BusinesFeedBack from "./BusinesFeedBack/BusinesFeedBack";

const BusinessSupport = ({ setCurrentPage, currentPage }) => {
    const [step, setStep] = useState(0);

    const renderTabContent = () => {
        if (step == 1) {
            return '';
        }
        if (step == 2) {
            return '';
        }
    };

    const supportoptiontRedirection = ({title}) => {

 if (title ==="Chat with us") {
 window.location.href = webBaseUrl + "/support-chat"
 }
 else if (title === "Explore FAQs") {
 window.location.href = webBaseUrl + "/shop-faqs"
 }
else if (title === "Free call") {
window.location.href = "tel:+01785558018"
}
else if (title === "Feedback") {

}
else if  (title === "Demo call") {
window.location.href = "https://calendly.com/facesconsent"
}

    }

    const tileData = [
        {
            title: "Chat with us",
            subTitle: "Connect with our supportive team.",
            icon: <SupportIcon />,
            step: 1
        },
        {
            title: "Explore FAQs",
            subTitle: "Find answers to common questions.",
            icon: <ExploreIcon />,
            step: 2
        },
        {
            title: "Free call",
            subTitle: "Reach out to us at on 01785 558018.",
            icon: <FreecallIcon />,
            step: 3
        },
        {
            title: "Feedback",
            subTitle: "Give a feedback to serve you better.",
            icon: <FeedbackIcon />,
            step: 4
        },
        {
            title: "Demo call",
            subTitle: "Schedule a demo call",
            icon: <VideoIcon />,
            step: 5
        }
    ];

    return (
        <div className="MainSupportPages">
            {step > 0 ?
                renderTabContent()
                :
                <div className="Main684WidthSet">
                    <BusinessNavigation
                        isColumn={true}
                        title={"How can we help?"}
                        desc={"Tailor your online presence with our profile settings. Personalize privacy, communication, and more."}
                        onBackClick={() => { setCurrentPage("Home") }}
                    />
                    <div className="linkGrid">
                        {tileData?.map((item, index) => {
                            return (
                                <div className="linkItem" onClick={() => {
                                    supportoptiontRedirection(item)
                                }}>
                                    <div className="linkImg">
                                        {item?.icon}
                                    </div>
                                    <div className="linkContent">
                                        <h3>{item?.title}</h3>
                                        <p>{item?.subTitle}</p>
                                    </div>
                                    <div className="RightIcon">
                                        <RightArrowIcon />
                                    </div>
                                </div>
                            )
                        })}


                        {/* <div className="linkItem" onClick={()=>{setStep(2)}}>
                <div className="linkImg">
                    <ShopSettingsIcon />
                </div>
                <div className="linkContent">
                    <h3>Shop settings</h3>
                    <p>Edit or add new business locations.</p>
                </div>
                <div className="RightIcon">
                    <RightArrowIcon />
                </div>
                </div>

                <div className="linkItem">
                <div className="linkImg">
                    <FinanceSettingsIcon />
                </div>
                <div className="linkContent">
                    <h3>Finance settings</h3>
                    <p>Add or adjust team members & roles.</p>
                </div>
                <div className="RightIcon">
                    <RightArrowIcon />
                </div>
                </div> */}
                    </div>
                </div>
            }
        </div>


    );
};

export default BusinessSupport;
