import React, { useCallback, useState } from "react";
import "./BusinessTreatmentDetails.scss";
import NavHeader from "../../../../common/NavHeader/NavHeader";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import PropTypes from "prop-types";
import TextInput from "../../../../common/TextInput";
import Dropzone, { useDropzone } from "react-dropzone";
import beforeafterimage from "../../../../../images/beforeUpload.svg";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router"
import { getClientFlexibleHoursRoutePage, getClientTimeRoutePage } from "../../../../../Routes";
import monthPay from "../../../../../images/month_pay.svg"
import Right from "../../../../../images/right_arrow.svg"
import flexiblaHours from "../../../../../images/flexibalHours.svg"
import StepButton from "../../../../common/StepButton";
import Select, { StylesConfig } from 'react-select';


function BusinessTreatmentDetails() {
   const navigate = useNavigate()

  function isValidEmail(email) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  }

  const [files, setFiles] = useState(null);
  
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFiles(URL.createObjectURL(file));
    },
  });

  const [selectedCountryLabel, setSelectedCountryLabel] = useState("");

  const [contactDetailsData, setContactDetailsData] = useState({
    email: "",
    phoneNumber: "",
    countryCode: "",
    address: "",
    doNotSendMarketingToggle: false,
  });

  const [basicInfoData, setBasicInfoData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    birthday: "",
    title: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    phonePrefix: "",
    phoneNumber: "",
    email: "",
    countryCode: "",
    address: "",
    nextkinPhoneNumber: "",
    nextkinPhonePrefix: "",
    nextkinFirstName: "",
    nextkinLastName: "",
    nextkinRelationship: "",
    title: "",
    gender: "",
  });
  const [countryCode, setCountryCode] = useState("GB");

  const handleCountryChange = (country) => {
    setCountryCode(country);
    console.log("selected country", country);
    // Get the label based on the selected country
    const selectedLabel = getCountries().includes(country)
      ? `${getCountryCallingCode(country)}`
      : "";

    setSelectedCountryLabel(selectedLabel);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBasicInfoData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setContactDetailsData((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(name, value);
  };

  const CountrySelect = ({
    value,
    onChange,
    selectedLabel,
    labels,
    ...rest
  }) => (
    <div className="country-selection-container">
      <select
        {...rest}
        value={value}
        onChange={(event) => onChange(event.target.value || undefined)}
      >
        <option value="">{labels["ZZ"]}</option>
        {getCountries().map(
          (country, i) =>
            country !== "International" && (
              <option key={country} value={country}>
                {`+${getCountryCallingCode(country)}`}
              </option>
            )
        )}
      </select>
      <span className="selected-label">{selectedLabel}</span>
    </div>
  );

  const validateInput = (name, value) => {
    switch (name) {
      case "firstName":
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            firstName: "First name is required",
          }));
        } else {
          if (/\d/.test(value)) {
            setValidationErrors((prev) => ({
              ...prev,
              firstName: "Invalid first name",
            }));
          } else {
            setValidationErrors((prev) => ({
              ...prev,
              firstName: "",
            }));
          }
        }
        break;
      case "lastName":
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            lastName: "Last name is required",
          }));
        } else {
          if (/\d/.test(value)) {
            setValidationErrors((prev) => ({
              ...prev,
              lastName: "Invalid last name",
            }));
          } else {
            setValidationErrors((prev) => ({
              ...prev,
              lastName: "",
            }));
          }
        }
        break;
      case "email":
        if (!value || !isValidEmail(value)) {
          setValidationErrors((prev) => ({
            ...prev,
            email: "Valid email is required",
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            email: "",
          }));
        }
        break;
      case "phoneNumber":
        if (name === "phoneNumber") {
          const numericValue = value.replace(/[^0-9]/g, "");
          setValidationErrors((prev) => ({
            ...prev,
            phoneNumber:
              numericValue.length < 10 || numericValue.length > 15
                ? "Valid phone number is required (10-15 digits)"
                : "",
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            phoneNumber: "",
          }));
        }
        break;

      case "title":
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            title: "Title is required",
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            title: "",
          }));
        }

      default:
        break;
    }
  };

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
    selectedLabel: PropTypes.string,
  };

  const [otherDocs, setOtherDocs] = useState([
    {
      image: "",
      image_url: "",
      title: "",
    },
    {
      image: "",
      image_url: "",
      title: "",
    },
    {
      image: "",
      image_url: "",
      title: "",
    },
    {
      image: "",
      image_url: "",
      title: "",
    }
  ]);

  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ];

  const handleChangeSelect = selectedOption => {
    setSelectedOption(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };
  
  return (
    <div className="Main-BusinessTreatmentDetails-page">
      <NavHeader isBackOnly={true} applyPadding={false} />

      <div className="SectionHeading">
        <div className="Section-Title">Service details</div>
        <div className="Section-Desc">
           Add details about the services you offer this will help clients when booking
        </div>
      </div>

      <div className="gridField">
        <p className="TagLine">Basic details</p>
        <div className="FieldWrapper">
          <p className="form-label">Treatment name</p>
          <input type="text" placeholder="e.g. 1 ml Botox" />
        </div>

        <div className="DescriptionWrapper">
          <p className="form-label">Description</p>

          <div className="textareaWrapper">
            <textarea placeholder="Add a description..."></textarea>
            <p className="budge">Generate</p>
          </div>
          <span className="countNumber">0 / 500</span>
        </div>
      </div>

      <div className="gridField">
        <p className="TagLine">Settings</p>
        <div className="FieldWrapper">
          <p className="form-label">Category</p>
          <Select
          className="custom-select-class" 
          placeholder="Select a category"
          value={selectedOption}
          onChange={handleChangeSelect}
          options={options}
      />
        </div>
      </div>
    
      <div className="gridField">
        <p className="TagLine">Photos</p>
        <div className="DropzoneGrid">
          {otherDocs.map((doc, index) =>
            files ? (
              <div key={index} className="DropzoneStyle">
                <div className="removeImg">
                  <IoCloseOutline />
                </div>
                {index == 4 && <div className="moreImg">{index}+ more</div>}
                <img src={files} alt="Uploaded Image" />
              </div>
            ) : (
              <div key={index} {...getRootProps()} className="DropzoneStyle">
                <input {...getInputProps()} />
                <div className="uploadWrapper">
                  <img src={beforeafterimage} alt="Placeholder" />
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <div className="gridField">
        <p className="TagLine">Appointment details</p>
        <div className="FieldWrapper">
          <p className="form-label">Select Clinics</p>
          <Select
          className="custom-select-class" 
          placeholder="Select Clinics"
          value={selectedOption}
          onChange={handleChangeSelect}
          options={options}
          />
        </div>
        <div className="FieldWrapper">
          <p className="form-label">Treatment length</p>
          <div className="inputFieldWrapper">
            <input type="text" placeholder="e.g. 60" />
            <p className="HoursText">Hours</p>
          </div>
        </div>
      </div>

      <div className="gridField">
        <p className="TagLine">Forms</p>
        <div className="FieldWrapper">
          <p className="form-label">Consent form</p>
          <Select
          className="custom-select-class" 
          placeholder="Form name"
          value={selectedOption}
          onChange={handleChangeSelect}
          options={options}
          />
        </div>
        <div className="FieldWrapper">
          <p className="form-label">Aftercare form</p>
          <Select
          className="custom-select-class" 
          placeholder="Form name"
          value={selectedOption}
          onChange={handleChangeSelect}
          options={options}
          />
        </div>
      </div>

      <div className="gridField">
        <p className="TagLine">Payments</p>
          <div className="FieldWrapper">
            <p className="form-label">Treatment price</p>
            <input type="text" placeholder="£ 20" />
          </div>
          <div className="FieldWrapper">
            <p className="form-label">Deposit Amount</p>
            <input type="text" placeholder="£ 20" />
          </div>
      </div>

      <div className="gridField">
        <p className="TagLine">Product Protection</p>
          <div className="FieldWrapper">
            <p className="fieldSubDesc">Linking products to treatments provides an extra layer of protection. A reminder of client product history.</p>
            <p className="form-label">Search products</p>
            <input type="text" placeholder="Search products" />
          </div>
      </div>

   
          <div className="BtnWrapper">
                  <StepButton label={'Next'} blue={false} />
          </div>
    </div>
  );
}

export default BusinessTreatmentDetails;
