import { gql, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

const ADD_BANK_DETAILS_MUTATION = gql`
mutation UpdateBankDetails($bankName: String, $accountNumber: String, $sortCode: String, $signatureBase: String) {
  updateBankDetails(bankName: $bankName, accountNumber: $accountNumber, sortCode: $sortCode, signatureBase: $signatureBase) {
    success
    message
  }
}
`;

export const UpdateBankDetails = () => {
  // const dispatch = useDispatch();
  const [UpdateBankDetailsResponseFunction, updateBankDetailsResponseData] =
    useMutation(ADD_BANK_DETAILS_MUTATION);

  //this will be called from components
  const updateBankDetailsQueryData = updateBankDetailsResponseData; //variable

  const initUpdateBankDetailsData = (data, successCallback, errorCallback) => {
    UpdateBankDetailsResponseFunction({
      variables: data,
    })
      // .then(({ data: { UpdateBankDetails: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        if (responseData?.data?.updateBankDetails?.success) {
          // dispatch(...)
          successCallback(responseData);
          // toast.success(responseData.message)
        } else {
          errorCallback(responseData);
          // toast.error(responseData.message)
        }
      })
      .catch((err) => {
        // errorCallback(err);
        toast.error('something went wrong!!!');
      });
  };

  return {
    updateBankDetailsQueryData,
    initUpdateBankDetailsData,
  };
};
