import {GET_NOTIFICATION_LIST_DATA,CLEAR_NOTITFICATION_LIST_DATA} from "../../constants/headerNotification/getNotificationConst"

const initalState={
    notificationListData:[],
    totalNotificationCount:0,
    loadedNotificationCount:0,
    notificationListPage:0,
    emptyNotificationList:false
}

export default function getNotificationListreducer(state=initalState , action){
   
    switch (action.type) {
        case GET_NOTIFICATION_LIST_DATA:
           const temp=(state.notificationListData).concat(action?.payload?.notifications);
           console.log("temppppp",temp)
           return {
            ...state,
            notificationListData:temp,
            totalNotificationCount:action?.payload?.totalRecords,
            notificationListPage:state.notificationListPage + 1 ,
            loadedNotificationCount:temp?.length
           };
          
        case CLEAR_NOTITFICATION_LIST_DATA:
           return {
                ...state,
                notificationListData:[],
                totalNotificationCount:0,
                notificationListPage:0,
                loadedAppointmentCount:0
            }
        default:
           return state
    }
}