import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const CREATE_FOLLOW_UP_MUTATION = gql`
mutation SaveAppointmentFollowupDetails($appointmentId: Int, $followup: Int, $followupTpye: Int) {
  saveAppointmentFollowupDetails(appointmentId: $appointmentId, followup: $followup, followupTpye: $followupTpye) {
    success
    message
    data {
      followup
      followupTpye
    }
  }
}
`;

export const CreateFollowUpMutation = () => {
  const [createFollowUpRequest, createFollowUpResponseData] =
    useMutation(CREATE_FOLLOW_UP_MUTATION);
  const dispatch = useDispatch();
  const createFollowUpMutationData = createFollowUpResponseData;

  const initiateCreateFollowUpResponseData = (
    data,
    successCallback,
    errorCallback
  ) => {
    createFollowUpRequest({
      variables: data,
    })
      .then(({ data: { saveAppointmentFollowupDetails: responseData } }) => {
        if (responseData) {
          console.log("hold duration", responseData.success)
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateCreateFollowUpResponseData, createFollowUpMutationData };
};
