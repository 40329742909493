import { CLEAR_RENEW_INSURANCE_STATUS, RENEW_INSURANCE, RENEW_INSURANCE_ERROR, RENEW_INSURANCE_SUCCESS } from "../../constants"

export function renewInsurance(data) {
    return {
        type: RENEW_INSURANCE,
        payload: data

    }
}

export function renewInsuranceSuccess(data) {
    return {
        type: RENEW_INSURANCE_SUCCESS,
        payload: data

    }
}

export function renewInsuranceError(data) {
    return {
        type: RENEW_INSURANCE_ERROR,
        payload: data

    }
}

export function clearRenewInsuranceStatus() {
    return {
        type: CLEAR_RENEW_INSURANCE_STATUS,

    }
}