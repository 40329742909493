import { GET_INSURANCE_DATA, GET_INSURANCE_DATA_ERROR, GET_INSURANCE_DATA_SUCCESS } from "../../constants/insurance/insurance"


export default function getInsuranceData() {

    return {
        type: GET_INSURANCE_DATA,
    }
}

export function getInsuranceDataSuccess(data) {
    return {
        type: GET_INSURANCE_DATA_SUCCESS,
        payload: data
    }
}
export function getInsuranceDataError(data) {
    return {
        type: GET_INSURANCE_DATA_ERROR,
        payload: data

    }
}
