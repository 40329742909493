import React, { useEffect, useState } from "react";
import insuranceCheckedLogo from "../../../images/thank-you/checked.png";

// import './insurance-thank-you.css'
import "bootstrap/dist/css/bootstrap.min.css";
import { frontendURL, webBaseUrl } from "../../../config";
import HeaderComponent from "../header-component/header-component";
import Cookies from "js-cookie";
import { GiCheckMark } from "react-icons/gi";
import { FaArrowLeftLong } from "react-icons/fa6";




// import Sidebar from "../../sidebar";
// import SidebarComponent from "../../sidebar";
// import useWindowDimensions from "../../../utils/useWindowDimensions";
// import BusinessHeader from "../../businessHeader";
// import BusinessFooter from "../../businessFooter";
import { useDispatch, useSelector } from "react-redux";
// import Avatar from "react-avatar";
// import { TfiMenu } from 'react-icons/tfi'
import { TrackUserActivityForServicesAction } from "../../redux/actions/social-media-marketing/service-user-activity";
import { PurchasePlumMarketingAction } from "../../redux/actions/social-media-marketing/purchase-social-media-marketing-package";
import { StripeComponent } from "../../pages/stripe-payment/stripe-component";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { GetSavedCardsOfUserAction } from "../../redux/actions/social-media-marketing/purchase-socail-media-marketing-saved-cards";
import { StripeSavedCardsComponent } from "./stripe-saved-cards";
import { UpdatePlumMarketingPackagePaymentAction } from "../../redux/actions/social-media-marketing/update-plum-marketing-package-payment-action";
import { PurchaseAccountancyServicesAction } from "../../redux/actions/accountancy-service/purchase-accountancy-services";
import { UpdateAccountancyServicesPackagePaymentAction } from "../../redux/actions/accountancy-service/update-accountancy-services-payment-action";
import { useNavigate } from "react-router";
import {
  accountancyServicePage,
  accountancyServicePagePattern,
  socialMediaMarketingPagePattern,
} from "../../../Routes";
import TextInput from '../../socialMediaMarketing/TextInput'
import { useParams } from "react-router-dom";
import { GetProfileAction } from "../../redux/actions/dashboard/getProfileDataGql";
import { GetAccountancyPackageAction } from '../../redux/actions/accountancy-service/package';

function AccountancyStripePaymentPage() {
  const { slug } = useParams();
  useEffect(() => {
    console.log("slug:", slug);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showPaymentSuccess, updatePaymentSuccess] = useState(false);
  const [showPaymentError, updatePaymentError] = useState(false);
  const [paymentErrorData, setPaymentErrorData] = useState(null);
  const [LoggedInUser, setLoggedInUser] = useState(false);
  const [packageData,setPackageData] = useState(null);
  const [invalidLink,setInvalidLink] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);
  const { profileData } = useSelector(state => state?.GetProfileDataReducer)
  const {
    getAccountancyPackageQueryData, initGetAccountancyPackageData, } = GetAccountancyPackageAction();


  const { initGetProfileData } = GetProfileAction();
  useEffect(() => {
    initGetProfileData();
  }, [])


    useEffect(() => {
        initGetAccountancyPackageData({
          packageSlug: slug,
        });
    }, [])
    
    useEffect(() => {
        if (getAccountancyPackageQueryData?.data){
          console.log("package data:", getAccountancyPackageQueryData.data)
            //setPlans(getAccountancyPackageQueryData.data.getAccountancyPackages.data.packages)
            if(getAccountancyPackageQueryData.data?.getAccountancyPackage?.data){
              setPackageData(getAccountancyPackageQueryData.data?.getAccountancyPackage?.data)
              setInvalidLink(false);
              setApiLoaded(true);
            }else{
              setInvalidLink(true);
              setApiLoaded(true);
            }
        }
    }, [getAccountancyPackageQueryData?.data])

  useEffect(()=>{
    if(profileData){
      setLoggedInUser(true);
      generateIntnt();
      getSavedCards();
    }
  },[profileData])

  const generateIntnt = async () => {
    setIsLoading(true);
    // if (packageIdParam && typeof packageIdParam == 'number') {
    initPurchaseAccountancyPackageData(
      {
        packageSlug: slug,
        sessionId: null,
        generatePaymentIntent: true,
        // "canUpgragePlan": isUpgradableParam === "true" ? true : false
        userFname: userPaymentInfoData.userInfoFname,
        userLname: userPaymentInfoData.userInfoLname,
        userEmail: userPaymentInfoData.userInfoEmail,
        userPhoneNumber: userPaymentInfoData.userInfoContactNumber,
      },
      (resIntData) => {
        console.log("line purchaseAccountancyPackage res:", resIntData);
        setIntentData(resIntData.data.purchaseAccountancyPackage.data);
        stripeIntentData = resIntData;
        console.log(intentData);
        console.log('line 348 setFalse', isLoading);
        var userData = resIntData.data.purchaseAccountancyPackage.data.userInfo;
        setUserPaymentInfoData({
          userInfoFname: userData.user_fname,
          userInfoLname: userData.user_lname,
          userInfoEmail: userData.user_email,
          userInfoContactNumber: userData.user_phone_number,
        });
        setIsLoading(false);
      },
      (err) => {
        console.log("line error:", err?.data, isLoading);
        // paymentIntentErrorCallback();
        if (
          err?.data?.purchaseAccountancyPackage?.message === "un-authorized" ||
          typeof err?.data === "undefined"
        ) {
          console.log('line 356', err?.data?.purchaseAccountancyPackage?.message, isLoading);
          setIsLoading(false);
         // window.location.href = webBaseUrl + "/login";
        } else {
          updatePaymentError(true);
          setPaymentErrorData(err);
          console.log('line 362 setFalse', isLoading);
          setIsLoading(false);
        }
      }
    );
    // }
  };

  const getSavedCards = async () => {
      initGetSavedCardsOfUserData(
      {},
      (res) => {
        setSavedCardsData(res.data.getSavedCardsOfUser.data.cards);
        console.log("saved cards of user:", res.data.getSavedCardsOfUser.data);
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  useEffect(() => {
    console.log("paymentErrorData", paymentErrorData);
  }, [paymentErrorData]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // const { width, height } = useWindowDimensions();
  const [showSidebar, setShowSidebar] = useState(true);

  const navigate = useNavigate();

  /*
  const [serviceUserActivity] = useMutation(SERVICE_USER_ACTIVITY_MUTATION);
  useEffect(() => {
      serviceUserActivity({ variables: { serviceId: 1, activityId: 1 } });
  }, []); */

  const [openReviewPopup, setOpenReviewPopup] = useState(false);
  // const dashboardListData = useSelector((state) => state.dashboardReducer.dashboardListData)
  // const { profileData } = useSelector(state => state?.GetProfileDataReducer)
  const BackToHome = () => {
    window.top.location.href = webBaseUrl + "user/dashboard";
  };
  // const backUrl = webBaseUrl + '/';

  // For intent generation
  const [intentData, setIntentData] = useState(null);

  /*
  {

      1: {
    
        name: "plump_marketing_agency",
    
        activities: {
    
          1: "CLICK_ON_SERVICE",
    
          2: "CLICK_ON_PACKAGE",
    
          3: "PURCHASE_PACKAGE",
    
          4: "CLICK_ON_CALENDLY"
    
        },
    
      },
    
      2: {
    
        name: "Accountancy_service",
    
        activities: {
    
          1: "CLICK_ON_SERVICE",
    
          2: "CLICK_ON_PACKAGE",
    
          3: "PURCHASE_PACKAGE",
    
          4: "CLICK_ON_CALENDLY"
    
        },
    
      }
    
    }
  */

  const {
    trackUserActivityForServicesQueryData,
    initTrackUserActivityForServicesData,
  } = TrackUserActivityForServicesAction();
  let stripeIntentData = false;
  let strTokenId = false;
  const [strToken, setStrToken] = useState(null);
  const [showCustomerLoader, setShowCustomerLoader] = useState(false);
  const {
    updatePlumpMarketingPackagePaymentQueryData,
    initUpdatePlumpMarketingPackagePaymentData,
  } = UpdatePlumMarketingPackagePaymentAction();
  const {
    updateAccountancyPackagePaymentQueryData,
    initUpdateAccountancyPackagePaymentData,
  } = UpdateAccountancyServicesPackagePaymentAction();

  const submitStripeBtn = document.getElementById(
    "socialMediaMarketingMakePaymentBtn"
  );
  const paymentIntentSuccessCallback = (intentId, paymentMethodId ,addTrack = true) => {
    // for calling service user activity api for adding purchase flag
    console.log("intentId tmp:", intentId);
    console.log("token id:", paymentMethodId);
    setStrToken(paymentMethodId);
    // console.log('package id', packageIdParam)
    // here
    /*
    if (serviceTypeParam === 'plumpMarketingAgency') {
      initPurchasePlumpMarketingPackageData({
        "packageId": packageIdParam,
        "sessionId": null,
        "generatePaymentIntent": false,
        "canUpgragePlan": isUpgradableParam === "true" ? true : false
      }, (resIntData) => {
        setShowCustomerLoader(true);
        console.log('purchasePlumpMarketingPackage res:', resIntData);
        setIntentData(resIntData.data.purchasePlumpMarketingPackage.data);
        stripeIntentData = resIntData;
        console.log('intent data', resIntData.data.purchasePlumpMarketingPackage.data);
        console.log('order id', resIntData.data.purchasePlumpMarketingPackage.data.packageReferenceId);
        initUpdatePlumpMarketingPackagePaymentData({
          packageId: packageIdParam,
          orderId: parseInt(resIntData.data.purchasePlumpMarketingPackage.data.packageReferenceId),
          intentId: intentData.intentId.substr(0, intentData.intentId.indexOf('_secret')),
          token: paymentMethodId,
          stripeSavedCardId: null,
          planUpgrade: isUpgradableParam === "true" ? true : false
        },
          (successResponse) => {
            initTrackUserActivityForServicesData({ serviceId: 1, activityId: 3, packageId: packageIdParam });
            if (!isMobile) {
              toast.success(successResponse.data.updatePlumpMarketingPackagePayment.message);
              navigate(socialMediaMarketingPagePattern());
            }
            else {
              updatePaymentSuccess(true);
            }
            console.log('payment intent call back success!!!');
            console.log('successResponse:', successResponse);
          },
          (errResponse) => {
            console.log('errResponse:', errResponse);
            paymentIntentErrorCallback();
          });

      }, (err) => {
        console.log('error:', err);
      });
    } else {
    */
    initPurchaseAccountancyPackageData(
      {
        packageSlug: slug,
        sessionId: null,
        generatePaymentIntent: false,
        userFname: userPaymentInfoData.userInfoFname,
        userLname: userPaymentInfoData.userInfoLname,
        userEmail: userPaymentInfoData.userInfoEmail,
        userPhoneNumber: userPaymentInfoData.userInfoContactNumber,
        // "canUpgragePlan": isUpgradableParam === "true" ? true : false
      },
      (resIntData) => {
        setShowCustomerLoader(true);
        console.log("purchaseAccountancyPackage res:", resIntData);
        setIntentData(resIntData.data.purchaseAccountancyPackage.data);
        stripeIntentData = resIntData;
        console.log(
          "intent data",
          resIntData.data.purchaseAccountancyPackage.data
        );
        console.log(
          "order id",
          resIntData.data.purchaseAccountancyPackage.data.packageReferenceId
        );
        initUpdateAccountancyPackagePaymentData(
          {
            packageSlug: slug,
            orderId: parseInt(
              resIntData.data.purchaseAccountancyPackage.data.packageReferenceId
            ),
            intentId: intentId.substr(
              0,
              intentId.indexOf("_secret")
            ),
            token: paymentMethodId,
            stripeSavedCardId: null,
            userFname: userPaymentInfoData.userInfoFname,
            userLname: userPaymentInfoData.userInfoLname,
            userEmail: userPaymentInfoData.userInfoEmail,
            userPhoneNumber: userPaymentInfoData.userInfoContactNumber,
            // planUpgrade: isUpgradableParam === "true" ? true : false
          },
          (successResponse) => {
            if(addTrack){
              // initTrackUserActivityForServicesData({ serviceId: 2, activityId: 3, packageId: packageIdParam });
              initTrackUserActivityForServicesData({
                serviceId: 2,
                activityId: 3,
                packageSlug: slug,
              });
            }
            if (!isMobile) {
              toast.success(
                successResponse.data.updateAccountancyPackagePayment.message
              );
              // navigate(socialMediaMarketingPagePattern());
              navigate(accountancyServicePagePattern());
            } else {
              updatePaymentSuccess(true);
            }
            console.log("payment intent call back success!!!");
            console.log("successResponse:", successResponse);
          },
          (errResponse) => {
            console.log("errResponse:", errResponse);
            paymentIntentErrorCallback();
          }
        );
        console.log('line 256 setFalse', isLoading);
        setIsLoading(false);
      },
      (err) => {
        console.log("error:", err);
        // paymentIntentErrorCallback();
        if (
          err?.data?.purchaseAccountancyPackage?.message === "un-authorized" ||
          typeof err?.data === "undefined"
        ) {
          console.log('line 264');
          window.location.href = webBaseUrl + "/login";
        } else {
          updatePaymentError(true);
          setPaymentErrorData(err);
          console.log('line 270 setFalse', isLoading);
          setIsLoading(false);
        }
      }
    );
    // }
  };
  const paymentIntentErrorCallback = () => {
    setShowCustomerLoader(false);
    toast.error("Unable to complete payment process!");
    console.log("payment intent call back error");
  };
  const paymentIntentSetProcess = () => {
    console.log("payment intent set process");
    if (submitStripeBtn) {
      submitStripeBtn.disabled = true;
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  // const packageIdParam = parseInt(urlParams.get('packageId'));
  // const serviceTypeParam = urlParams.get('serviceType').toString();
  const isUpgradableParam = urlParams.get("isUpgradable")?.toString();

  // const backUrl = serviceTypeParam === 'plumpMarketingAgency' ? frontendURL + '/social-media-marketing' : frontendURL + '/accountancy-service';
  const backUrl = frontendURL + "/accountancy-service";

  // console.log('packageIdParam', packageIdParam);
  // console.log('serviceTypeParam', serviceTypeParam);
  console.log("isUpgradableParam", isUpgradableParam);

  const {
    purchasePlumpMarketingPackageQueryData,
    initPurchasePlumpMarketingPackageData,
  } = PurchasePlumMarketingAction();

  const {
    purchaseAccountancyPackageQueryData,
    initPurchaseAccountancyPackageData,
  } = PurchaseAccountancyServicesAction();

  const [savedCards, setSavedCardsData] = useState(null);

  const { getSavedCardsOfUserQueryData, initGetSavedCardsOfUserData } =
    GetSavedCardsOfUserAction();

  const [savedCardId, setSavedCardId] = useState(null);

  const performStripeWithCardId = (cardId) => {
    setSavedCardId(cardId);

    console.log("savedCardId:", savedCardId);
    // initPurchasePlumpMarketingPackageData({
    //     "packageId": packageIdParam,
    //     "sessionId": null,
    //     "generatePaymentIntent": true
    // }, (resIntData) => {
    //     console.log('purchasePlumpMarketingPackage res:', resIntData);
    //     setIntentData(resIntData.data.purchasePlumpMarketingPackage.data);

    //     initPurchasePlumpMarketingPackageData({
    //         "packageId": packageIdParam,
    //         "sessionId": null,
    //         "generatePaymentIntent": false
    //     }, (resIntData2) => {
    //         console.log('resIntData2:', resIntData2);
    //         initUpdatePlumpMarketingPackagePaymentData({
    //             packageId: packageIdParam,
    //             orderId: parseInt(resIntData2.data.purchasePlumpMarketingPackage.data.packageReferenceId),
    //             intentId: intentData.intentId.substr(0, intentData.intentId.indexOf('_secret')),
    //             token: strToken,
    //             stripeSavedCardId: cardId
    //         }, (successResponse) => {
    //             initTrackUserActivityForServicesData({ serviceId: 1, activityId: 3, packageId: packageIdParam });
    //             toast.success(successResponse.data.updatePlumpMarketingPackagePayment.message);
    //             console.log('payment intent call back success!!!');
    //             console.log('successResponse:', successResponse);
    //         }, (errResponse) => {
    //             console.log('errResponse:', errResponse);
    //             paymentIntentErrorCallback();
    //         });
    //     }, (err) => {
    //         console.log('error1:', err);
    //     });

    //     stripeIntentData = resIntData;
    //     console.log(intentData);
    // }, (err) => {
    //     console.log('error:', err);
    // });
  };

  const callbackForSavedCardPayment = () => {
    // here
    /*
    if (serviceTypeParam === 'plumpMarketingAgency') {
      initPurchasePlumpMarketingPackageData({
        "packageId": packageIdParam,
        "sessionId": null,
        "generatePaymentIntent": false,
        "canUpgragePlan": isUpgradableParam === "true" ? true : false
      }, (resIntData2) => {
        setShowCustomerLoader(true);
        console.log('resIntData2:', resIntData2);
        initUpdatePlumpMarketingPackagePaymentData({
          packageId: packageIdParam,
          orderId: parseInt(resIntData2.data.purchasePlumpMarketingPackage.data.packageReferenceId),
          intentId: intentData.intentId.substr(0, intentData.intentId.indexOf('_secret')),
          token: savedCardId,
          stripeSavedCardId: savedCardId,
          planUpgrade: isUpgradableParam === "true" ? true : false
        }, (successResponse) => {
          initTrackUserActivityForServicesData({ serviceId: 1, activityId: 3, packageId: packageIdParam });
          if (!isMobile) {
            toast.success(successResponse.data.updatePlumpMarketingPackagePayment.message);
            navigate(socialMediaMarketingPagePattern());
          }
          else {
            updatePaymentSuccess(true);
          }
          console.log('payment intent call back success!!!');
          console.log('successResponse:', successResponse);
        }, (errResponse) => {
          console.log('errResponse:', errResponse);
          paymentIntentErrorCallback();
        });

        // stripeIntentData = resIntData;
        console.log(intentData);
      }, (err) => {
        console.log('error:', err);
      });
    } else {
    */
    initPurchaseAccountancyPackageData(
      {
        packageSlug: slug,
        sessionId: null,
        generatePaymentIntent: true,
        // "canUpgragePlan": isUpgradableParam === "true" ? true : false
      },
      (resIntData) => {
        console.log('line 487 setFalse', isLoading);
        setIsLoading(false);
        setShowCustomerLoader(true);
        console.log("purchaseAccountancyPackage res:", resIntData);
        setIntentData(resIntData.data.purchaseAccountancyPackage.data);

        initPurchaseAccountancyPackageData(
          {
            packageSlug: slug,
            sessionId: null,
            generatePaymentIntent: false,
            // "canUpgragePlan": isUpgradableParam === "true" ? true : false
          },
          (resIntData2) => {
            console.log("resIntData2:", resIntData2);
            initUpdateAccountancyPackagePaymentData(
              {
                packageSlug: slug,
                orderId: parseInt(
                  resIntData2.data.purchaseAccountancyPackage.data
                    .packageReferenceId
                ),
                intentId: intentData.intentId.substr(
                  0,
                  intentData.intentId.indexOf("_secret")
                ),
                token: savedCardId,
                stripeSavedCardId: savedCardId,
                userFname: userPaymentInfoData.userInfoFname,
                userLname: userPaymentInfoData.userInfoLname,
                userEmail: userPaymentInfoData.userInfoEmail,
                userPhoneNumber: userPaymentInfoData.userInfoContactNumber,
                // planUpgrade: isUpgradableParam === "true" ? true : false
              },
              (successResponse) => {
                initTrackUserActivityForServicesData({ serviceId: 2, activityId: 3, packageSlug: slug });
                if (!isMobile) {
                  toast.success(
                    successResponse.data.updateAccountancyPackagePayment.message
                  );
                  // navigate(socialMediaMarketingPagePattern());
                  navigate(accountancyServicePagePattern());
                } else {
                  updatePaymentSuccess(true);
                }
                console.log("payment intent call back success!!!");
                console.log("successResponse:", successResponse);
              },
              (errResponse) => {
                console.log("errResponse:", errResponse);
                paymentIntentErrorCallback();
              }
            );
          },
          (err) => {
            console.log("error1:", err);
            // paymentIntentErrorCallback();
            if (
              err?.data?.purchaseAccountancyPackage?.message === "un-authorized" ||
              typeof err?.data === "undefined"
            ) {
              console.log('line 540');
              // window.location.href = webBaseUrl + "/login";
            }
            updatePaymentError(true);
            setPaymentErrorData(err);
          }
        );

        // stripeIntentData = resIntData;
        console.log(intentData);
      },
      (err) => {
        console.log("error:", err);
        // paymentIntentErrorCallback();
        if (
          err?.data?.purchaseAccountancyPackage?.message === "un-authorized" ||
          typeof err?.data === "undefined"
        ) {
          console.log('line 557');
          window.location.href = webBaseUrl + "/login";
        } else {
          console.log('line 563 setFalse', isLoading);
          setIsLoading(false);
          updatePaymentError(true);
          setPaymentErrorData(err);
        }
      }
    );
    // }
  };

  const [isCardPaymentType, setIsCardPaymentType] = useState(false);

  const changePaymentType = (event) => {
    setIsCardPaymentType(false);
    handleRadioChange(null);
    console.log("isCardPaymentType:", isCardPaymentType);
  };

  const [selectedCardValue, setSelectedCardValue] = useState(null);
  const [enteredCardValue, setenteredCardValue] = useState(null);
  const [clearStripeForm, setClearStripeForm] = useState(false);

  const [userPaymentInfoData, setUserPaymentInfoData] = useState({
    userInfoFname: "",
    userInfoLname: "",
    userInfoEmail: "",
    userInfoContactNumber: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    userInfoFname: "",
    userInfoLname: "",
    userInfoEmail: "",
    userInfoContactNumber: "",
  });

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (userPaymentInfoData.userInfoFname === "") {
      errors.userInfoFname = "First Name is required";
      isValid = false;
    }
    if (userPaymentInfoData.userInfoLname === "") {
      errors.userInfoLname = "Last Name is required";
      isValid = false;
    }
    if (userPaymentInfoData.userInfoEmail === "") {
      errors.userInfoEmail = "Email is required";
      isValid = false;
    }else{
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userPaymentInfoData.userInfoEmail)) {
        errors.userInfoEmail = 'Invalid email address';
        isValid = false;
      }
    }
    
    if (userPaymentInfoData.userInfoContactNumber === "") {
      errors.userInfoContactNumber = "Contact Number is required";
      isValid = false;
    }else{
      if (!/^\+?\d+$/.test(userPaymentInfoData.userInfoContactNumber)) {
        errors.userInfoContactNumber = 'Invalid contact number';
        isValid = false;
      }else if(userPaymentInfoData.userInfoContactNumber.length < 10){
        errors.userInfoContactNumber = 'Contact number should be atleast 10 digits';
        isValid = false;
      }
    }


    setValidationErrors(errors);

    return isValid;
  }

  const handleRadioChange = (event) => {
    const newValue = event ? event.target.value : null;
    console.log(newValue, "newValue");
    if (newValue === null) {
      setSelectedCardValue(null);
      setClearStripeForm(false);
    } else {
      setClearStripeForm(true);
      setSelectedCardValue(newValue);
      setTimeout(() => {
        setClearStripeForm(false);
      }, 100);
    }
    // newValue === null ? setSelectedCardValue(null) : setSelectedCardValue(newValue);
  };

  return (
    <>
      {/* <BusinessHeader /> */}
      {/* <div className="container-fluid">
                <div className="dashboard-parent-container row"> */}
      {isLoading === false && apiLoaded ? (
        <div className="row mx-0">
          {invalidLink ? (
           <div className="h-50 d-flex justify-content-center align-items-center fw-bold fs-1 ">
             Invalid Link!
          </div>
          ) : (
            <>
          <div className="col-md-8 checkout-page-left">
            <div className="cus-container-checkout">
              <div
              className={"editprofile justify-content-end checkOutPageDesign"}
              style={{ maxWidth: "700px", marginLeft: "auto", marginRight: "auto" }}
            >
              <ToastContainer
                position="top-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                theme="dark"
                onClose={() => {
                  // Redirect to the desired page
                  setTimeout(() => {
                    console.log("toast container hidden");
                    // window.location.href = '/services/social-media-marketing';
                  }, 3000);
                }}
              />
              {/* <a href={backUrl} className='cutom-btn btnredsm' style={{ maxWidth: '45px', minWidth: '45px', width: '45px', padding: '10px', margin: '10px' }}><i className='fa fa-arrow-left'></i></a> */}
              
                <div className="shopinnertit custom-shopinnertit">
                  <a
                    data-tooltip-id="go-to-back"
                    data-tooltip-content="Go Back"
                    data-placement="bottom"
                    className="btn btnredsm cus-back-btn-hm"
                   // href={backUrl}
                    data-original-title="Go back"
                    style={{ left: 0, outline: "none"}}
                    onClick={() => {
                      navigate(accountancyServicePagePattern());
                    }}
                  >
                    {/* <i className="fa fa-arrow-left"></i> */}
                    {/* <IoArrowBackCircle style={{fontSize:"40px",color:"#EEEFF3"}} /> */}
                    <FaArrowLeftLong style={{fontSize:"40px",color:"#000",background:"#EEEFF3",padding: "10px",borderRadius:"50%"}} />
                  </a>
                </div>
              {showPaymentSuccess ? (
                <h2 className="text-center" style={{ marginBottom: "25px" }}>
                  Thank you!
                </h2>
              ) : null}
              {showPaymentSuccess ? (
                <h4 className="text-center" style={{ fontSize: "16px" }}>
                  Your payment has been successful. You can go back to app by
                  clicking on back button.
                </h4>
              ) : null}
              {showPaymentError ? (
                <h2 className="text-center" style={{ marginBottom: "25px" }}>
                  Warning!
                </h2>
              ) : null}
              {showPaymentError ? (
                <h4 className="text-center" style={{ fontSize: "16px" }}>
                  {" "}
                  {paymentErrorData?.data?.purchaseAccountancyPackage?.message}{" "}
                </h4>
              ) : null}
              {!showPaymentSuccess && !showPaymentError ? (
                <>
                  <div className="checkoutPageTitle">
                    <h2 className="checkoutHeading">
                        Subscribe to your plan
                    </h2>
                    <p>
                    We are the leading accountancy service for the beauty and aesthetics industry
                    </p>
                  </div>

                  {packageData && (
                    <div className="mobileCheckoutPlan d-md-none">
                      <div className="box">
                        <p>{packageData.package_name}</p>
                        <h4>{packageData.amountText}{packageData.duration_text}</h4>
                        <ul>
                          {packageData?.package_points?.map((point, index) => (
                            <li><GiCheckMark style={{marginRight:"12px",color:"#1658F7"}}/>{point}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}

                  <div className="row form-group mb-5">
                    <div className="yourDetails">
                      <p>Your details</p>
                    </div>
                    <div className="mb-4">
                    <TextInput
                      label="First Name"
                      placeholder="First Name"
                      value={userPaymentInfoData?.userInfoFname}
                      onChange={ (e) => {
                        console.log("hi");
                        setUserPaymentInfoData({
                          ...userPaymentInfoData,
                          userInfoFname: e.target.value,
                        });
                        setValidationErrors({
                          ...validationErrors,
                          userInfoFname: "",
                        });
                      }}
                      name="first_name"
                    />
                    {validationErrors.userInfoFname && (
                      <span className="text-danger mt-2 d-inline-block">
                        {validationErrors.userInfoFname}
                      </span>
                    )}
                    </div>

                    <div className="mb-4">
                    <TextInput
                      label="Last Name"
                      placeholder="Last Name"
                      value={userPaymentInfoData?.userInfoLname}
                      onChange={(e) => {
                        setUserPaymentInfoData({
                          ...userPaymentInfoData,
                          userInfoLname: e.target.value,
                        });
                        setValidationErrors({
                          ...validationErrors,
                          userInfoLname: "",
                        });
                      
                      }}
                      name="last_name"
                    />
                    {validationErrors.userInfoLname && (
                      <span className="text-danger mt-2 d-inline-block">
                        {validationErrors.userInfoLname}
                      </span>
                    )}
                    </div>

                    <div className="mb-4">
                    <TextInput
                      label="Email address"
                      placeholder="name@gmail.com"
                      value={userPaymentInfoData?.userInfoEmail}
                      onChange={ (e) => {
                        setUserPaymentInfoData({
                          ...userPaymentInfoData,
                          userInfoEmail: e.target.value,
                        });
                        setValidationErrors({
                          ...validationErrors,
                          userInfoEmail: "",
                        });
                      }}
                      name="userInfoEmail"
                    />
                    {validationErrors.userInfoEmail && (
                      <span className="text-danger mt-2 d-inline-block">
                        {validationErrors.userInfoEmail}
                      </span>
                    )}
                    </div>

                    <div className="mb-4">
                    <TextInput
                      label="Contact Number"
                      placeholder="+44 123456789"
                      value={userPaymentInfoData?.userInfoContactNumber}
                      onChange={ (e) => {
                        setUserPaymentInfoData({
                          ...userPaymentInfoData,
                          userInfoContactNumber: e.target.value,
                        });
                        setValidationErrors({
                          ...validationErrors,
                          userInfoContactNumber: "",
                        });
                      }}
                      name="userInfoContactNumber"
                    />
                    {validationErrors.userInfoContactNumber && (
                      <span className="text-danger mt-2 d-inline-block">
                        {validationErrors.userInfoContactNumber}
                      </span>
                    )}
                    </div>

                  </div>
                  {savedCards &&  savedCards.length ?(
                    <h4 className="text-center" style={{ fontSize: "16px" }}>
                      Below are the list of cards you have previously saved.
                    </h4>
                  ) : null}
                  {savedCards && savedCards.length ? (
                    <div className="selectedSaveText">
                      <p style={{ textAlign: "center" }}>
                        <i>Select a saved card</i>
                      </p>
                    </div>
                  ) : null}
                  {savedCards && (
                    <StripeSavedCardsComponent
                      cards={savedCards}
                      performStripeWithCardId={performStripeWithCardId}
                      changeCardPaymentType={setIsCardPaymentType}
                      handleRadioChange={handleRadioChange}
                      selectedValue={selectedCardValue}
                    />
                  )}
                  {!showCustomerLoader && (
                  <div className="selectedSaveText">
                    <p style={{ textAlign: "center" }}>
                      <i>Enter a new card</i>
                    </p>
                  </div>
                  )}
                  {/* <p style={{textAlign: 'center'}}>Or </p> */}
                  {console.log("showCustomerLoader", showCustomerLoader)}
                  {showCustomerLoader === true && (
                    <center>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <div style={{ marginRight: "5px" }}>
                          <div
                            className="spinner-border text-primary"
                            role="status"
                            style={{ height: "20px", width: "20px" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div>Processing...</div>
                      </div>
                    </center>
                  )}
                  {!showCustomerLoader  && (
                    <StripeComponent
                    key={clearStripeForm ? "clearForm" : "normalForm"}
                    clientSecret={LoggedInUser ? intentData?.intentId : null}
                    successCallback={paymentIntentSuccessCallback}
                    failureCallback={paymentIntentErrorCallback}
                    setProcess={paymentIntentSetProcess}
                    paymentType="card"
                    changePaymentType={changePaymentType}
                    isCardPaymentType={isCardPaymentType}
                    savedCardId={savedCardId}
                    callbackForSavedCardPayment={callbackForSavedCardPayment}
                    useSetupIntentFlow={true}
                    preCallbackFuncton={()=>{
                      var res  = validateForm();
                      return res;
                    }}
                    LateIntentFunction={()=>{
                      //generate intent and process payment after entering card details
                      return new Promise((resolve, reject) => {
                          initPurchaseAccountancyPackageData(
                            {
                              packageSlug: slug,
                              sessionId: null,
                              generatePaymentIntent: true,
                              // "canUpgragePlan": isUpgradableParam === "true" ? true : false
                              userFname: userPaymentInfoData.userInfoFname,
                              userLname: userPaymentInfoData.userInfoLname,
                              userEmail: userPaymentInfoData.userInfoEmail,
                              userPhoneNumber: userPaymentInfoData.userInfoContactNumber,
                            },
                            (resIntData) => {
                              //return resIntData.data.purchaseAccountancyPackage.data;
                              resolve(resIntData.data.purchaseAccountancyPackage.data)
                            },
                            (err) => {
                              if(err?.data?.purchaseAccountancyPackage?.message){
                                toast.error(err.data.purchaseAccountancyPackage.message)
                              }
                              reject(err.data.purchaseAccountancyPackage.message);
                              //return false;
                            }
                          );
                      })
  
                    }}
                    customButton={
                      <div className="subscribeBtnWrap">
                      <button className="customSubscibeBtn">Subscribe</button>
                      </div>
                    }
                  />
                  )}
                </>
              ) : null}
              <br />
              {/* <div className="text-center">
                                <button type="submit" className='btn btnredsm justify-content-center' style={{maxWidth: '190px', minWidth: '190px'}} form="paymentForm">Make Payment</button>
                            </div> */}
               
              </div>
            </div>
          </div>
          {packageData && (
            <div className="col-md-4 checkout-page-right d-none d-md-block">
              <h2>Preview and Pay</h2>

              <div className="checkoutPackageName">
                <p>{packageData.package_name}</p>
                <h4>{packageData.amountText}{packageData.duration_text}</h4>
                <ul>
                  {packageData.package_points?.map((point, index) => (
                   <li><GiCheckMark style={{marginRight:"12px",color:"#1658F7"}}/>{point}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          </>
          )}
        </div>
      ) : null}
      {/* </div>
            </div> */}
      {/* <BusinessFooter /> */}
    </>
  );
}

export default AccountancyStripePaymentPage;
