import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_CONSENT_FORM_MORE_PRODUCT_LIST } from "../../constants/consentFormThankyou/consentFormProductConst";

const GET_CONSENT_FORM_MORE_PRODUCT_QUERY = gql`
query GetListOfProducts($page: Int, $limit: Int, $search: String, $vendorId: [Int], $displayOnlyPharmacyProducts: Boolean) {
  getListOfProducts(page: $page, limit: $limit, search: $search, vendorId: $vendorId, display_only_pharmacy_products: $displayOnlyPharmacyProducts) {
    success
    message
    data {
      isPrescriber
      prescriberDetails {
        pres_id
        pres_fee
        pres_currency
        pres_name
        pres_email
        pres_profile
        pres_profession
      }
      total
      productList {
        id
        product_name
        vendor_id
        vendor_name
        vendor_is_pharmacy
        currency
        price
        image_url
        shop_page_url
        is_prescription_required
        quantity
        allow_buy_directly
        is_retail_product
        is_user_registered_with_vendor
        is_approved_by_admin
        is_approved_by_pharmacy
        pharmacy_request_sent
        registration_message
      }
    }
  }
}
  `;

// var page;
export const ConsentFormMoreProductListQuery = () => {
    const dispatch = useDispatch();
    const [getConsentFormMoreProductList, consentFormMoreProductListData] = useLazyQuery(
        GET_CONSENT_FORM_MORE_PRODUCT_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getListOfProducts: responseData }) => {

                dispatch({
                    type: GET_CONSENT_FORM_MORE_PRODUCT_LIST,
                    payload: responseData?.data,
                    // page
                });
                if (responseData.success) {

                } else {

                }


            },
        }
    );
    const consentFormMoreProductListQueryData = consentFormMoreProductListData;
    const initConsentFormMoreProductcList = (data,successCallBack,errorCallBack) => {
        // page = data.page
        getConsentFormMoreProductList({
            variables: data,
        }).then((res)=>{
            successCallBack(res?.data?.getConsentFormProducts)
            console.log("first",res?.data?.getConsentFormProducts)
        }).catch((err)=>{
            errorCallBack(err)
        })
    };
    return { initConsentFormMoreProductcList, consentFormMoreProductListQueryData };
};



