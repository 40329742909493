import { GET_PRESCRIPTION_COUNT, GET_PRESCRIPTION_COUNT_ERROR, GET_PRESCRIPTION_COUNT_SUCCESS } from "../../constants/prescription/prescription"

export default function getPrescriptionCount(data) {

    return {
        type: GET_PRESCRIPTION_COUNT,
        payload: data
    }
}

export function getPrescriptionCountSuccess(data) {
    return {
        type: GET_PRESCRIPTION_COUNT_SUCCESS,
        payload: data
    }
}

export function getPrescriptionCountError(data) {

    return {
        type: GET_PRESCRIPTION_COUNT_ERROR,
        payload: data
    }
}
