import React, { useEffect, useState } from "react";
import { GetTreatmentsListAction } from "../../redux/actions/treatmentList/getTreatmentsList";
import { GetReviewListAction } from "../../redux/actions/dashboard/webReviewsGql"

// img
import slideone from "../../../assets/createWebsite/firstSlide.png";
import slidetwo from "../../../assets/createWebsite/secondSlide.png";
import slidethree from "../../../assets/createWebsite/thirdSlide.png";

// component
import WelcomeScreen from "./welcomeScreen";
import ReEntryDashboard from "./reEntryDashboard";
import NotFoundPage from "../../../components/pages/customPages/NotFoundPage"

// scss
import "./createWebsite.scss";
import StepsLayout from "./stepsLayout";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { GetWebsiteDetailsAction } from "../../redux/actions/createWebsite/getWebsiteDetails";
import getReviewsData from "../../redux/actions/dashboard/Reviews";
import { set } from "lodash";
import { TrackUserActivity } from "../../redux/actions/createWebsite/trackUserActivity";
import SelectProcess from "./selectProcess";
import SelectPlans from "./selectPlans";
import { GetProfileAction } from "../../../components/redux/actions/dashboard/getProfileDataGql"
import { useNavigate } from "react-router";
import { businessDashboardRoute } from "../../../Routes"

const CreateWebsite = ({ }) => {
    const { getProfileQueryData, initGetProfileData } = GetProfileAction();
    const navigate = useNavigate()
    useEffect(() => {
        initGetProfileData();
    }, [])
    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const [welcomeScreen, setWelcomeScreen] = useState(false);
    const [isLoader, setIsLoader] = useState(true);
    const { initGetTreatmentsListData, getTreatmentsListQueryData } =
        GetTreatmentsListAction();
    const { profileData } = useSelector((state) => state?.GetProfileDataReducer);

    const [isEdit, setIsEdit] = useState(false);

    const [currentStepAvailable, setCurrentStateAvailable] = useState(false);
    const websitePrefilledData = useSelector(
        (state) => state.WebsiteDetailsReducer.websitePrefilledData
    );

    useEffect(() => {
        if (profileData?.is_hide_website === true ) {
            navigate(businessDashboardRoute())
        }
    }, [profileData?.is_hide_website])
    
    console.log("profileData",profileData)
    const { initiatetrackUserActivity, trackUserActivityMutationData } =
        TrackUserActivity();

    const {
        initGetWebsiteDataResponseFunction,
        GetWebsiteDetailsResponseData: { loading: detailsLoading },
    } = GetWebsiteDetailsAction();

    const { getReviewListQueryData, initGetReviewListData } = GetReviewListAction()

    useEffect(() => {
        initGetWebsiteDataResponseFunction();


        initGetReviewListData({
            reviewType: "faces",
        })


        // dispatch(
        //   getReviewsData({
        //     reviewType: "google",
        //   })
        // );

        initGetTreatmentsListData();
    }, []);

    const WelcomeScreenData = [
        {
            id: 1,
            text: "Boost your business and get seen by Google!",
            desc: "We're excited to help you build your website. Let's get started.",
            sliderimg: slideone,
        },
        {
            id: 2,
            text: "Personalise your space",
            desc: "Choose from various templates and customise them to match your style.",
            sliderimg: slidetwo,
        },
        {
            id: 3,
            text: "Integrated booking system",
            desc: "Allowing you to capture potential client information through contact forms and enquiries.",
            sliderimg: slidethree,
        },
    ];

    useEffect(() => {
        if (!currentStepAvailable) {
            if (websitePrefilledData?.current_step) {
                if (websitePrefilledData?.current_step != 0) {
                    setWelcomeScreen(false);
                } else {
                    setWelcomeScreen(true);
                    setStep(1);
                }
                setCurrentStateAvailable(true);
            } else {
                setWelcomeScreen(true);
                setStep(1);
            }
        }
    }, [websitePrefilledData?.current_step]);

    useEffect(() => {
        if (websitePrefilledData) {
            setIsLoader(false);
            if (websitePrefilledData.is_active) {
                setWelcomeScreen(false);
                if (step !== 11) setStep(12); // go to edit screen if congratulation screen is not there
            } else {
                if (
                    websitePrefilledData.current_step == 1 ||
                    websitePrefilledData.current_step == ""
                ) {
                    setWelcomeScreen(true);
                } else {
                    // setStep(parseInt(websitePrefilledData?.current_step)); // go to edit screen
                    // go to particular step received from API
                }
            }
        }
    }, [websitePrefilledData?.is_active]);

    console.log("welcomeScreen", welcomeScreen, websitePrefilledData, isEdit);

    const [currentStep, setCurrentStep] = useState(1)

    useEffect(() => {
        if (websitePrefilledData?.website_build_type == 2) {
            setWelcomeScreen(true)
            setCurrentStep(2)
        }
        if (websitePrefilledData?.website_build_type == 1) {
            setWelcomeScreen(false)
        }

    }, [websitePrefilledData?.website_build_type])

    return (
        <>
            {isLoader ? (
                <div className="LoaderMain">
                    <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            ) : profileData?.is_hide_website === true ?
                <NotFoundPage /> : websitePrefilledData &&
                    websitePrefilledData.is_active === true &&
                    isEdit === false ? (
                    <ReEntryDashboard
                        setStep={setStep}
                        step={step}
                        trackActivity={initiatetrackUserActivity}
                        setIsEdit={setIsEdit}
                    />
                ) : (
                    <div className="mainCreateWebSite">
                        {welcomeScreen ? (
                            <>
                                {currentStep == 1 && <WelcomeScreen
                                    items={1}
                                    loop={false}
                                    nav={false}
                                    data={WelcomeScreenData}
                                    welcomeScreen={welcomeScreen}
                                    setWelcomeScreen={setWelcomeScreen}
                                    setStep={setStep}
                                    step={step}
                                    setCurrentStep={setCurrentStep}
                                    callDetailsApi={initGetWebsiteDataResponseFunction}
                                    detailsLoading={detailsLoading}
                                />}
                                {
                                    currentStep == 2 &&
                                    <SelectProcess
                                        setCurrentStep={setCurrentStep}
                                        detailsLoading={detailsLoading}
                                        callDetailsApi={initGetWebsiteDataResponseFunction}
                                        setWelcomeScreen={setWelcomeScreen}
                                    />
                                }
                                {
                                    currentStep == 3 &&
                                    <SelectPlans setCurrentStep={setCurrentStep} />
                                }
                            </>
                        ) : (
                            <StepsLayout
                                welcomeScreen={welcomeScreen}
                                setWelcomeScreen={setWelcomeScreen}
                                setStep={setStep}
                                step={step}
                                setIsEdit={setIsEdit}
                            />
                        )}
                    </div>
                )}
        </>
    );
};

export default CreateWebsite;
