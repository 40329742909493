import { gql, useMutation } from "@apollo/client";

const HIDE_WARNING_BANNER_MUTATION = gql`
mutation HideWarningBanner($uhbtId: Int) {
  hideWarningBanner(uhbtId: $uhbtId) {
    success
    message
  }
}
`;

export const useHideWarningBannerMutation = () => {
  const [hideWarningBanner, { data, loading, error }] = useMutation(HIDE_WARNING_BANNER_MUTATION);

  const initiateHideWarningBanner = (variables, successCallback, errorCallback) => {
    hideWarningBanner({ variables })
      .then(({ data: { hideWarningBanner: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  return { initiateHideWarningBanner, data, loading, error };
};
