import React, { useEffect, useState } from 'react';
import downArrow from '../../images/icons/cav-down-gray.svg'
import "./sidebar.scss"
import dashboardIcon from ".././../images/icons/dashboard.svg";
import directoryIcon from "../../images/icons/forms (1).svg";
import servicesIcon from "../../images/icons/services.svg";
import formsIcon from "../../images/icons/forms.svg";
import orderIcon from "../../images/icons/orders.svg";
import financeIcon from "../../images/icons/finance.svg";
import medicalIcon from "../../images/icons/medical.svg"
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { webBaseUrl, frontendURL, goHighLevelUrl } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { logoutProfile } from '../redux/actions/profile/Profile';
import getPrescriptionCount from '../redux/actions/prescription/prescription';
import { USER_ROLES } from '../../utils/constants';
import { accountancyServicePagePattern, addNewClinicPageRoute, businessDashboardRoute, createWebsiteRoute, getCalendarRoute, getSearchClientRoute, getHairProductLinkRoute, insuranceLandingPage, insuranceLandingPagePattern, socialMediaMarketingPagePattern, directDepositeLinkRoute, comingsoonpage } from '../../Routes';
import { useNavigate } from 'react-router';
import newFeature from '../../images/newFeature.jpg';
import websiteIcon from '../../../src/assets/website.svg'
import { ExportClientCsvData } from '../redux/actions/client/exportClientCsvData';

import { ReactComponent as HomeIcon } from '../../images/sidebarIcon/Home.svg';
import { ReactComponent as CalendarIcon } from '../../images/sidebarIcon/Calendar.svg';
import { ReactComponent as ShopIcon } from '../../images/sidebarIcon/Shop.svg';
import { ReactComponent as ClientsIcon } from '../../images/sidebarIcon/Clients.svg';
import { ReactComponent as InsuranceIcon } from '../../images/sidebarIcon/Insurance.svg';
import { ReactComponent as DiscoverIcon } from '../../images/sidebarIcon/Discover.svg';
import { ReactComponent as DepositsIcon } from '../../images/sidebarIcon/Deposits.svg';
import { ReactComponent as LocationsIcon } from '../../images/sidebarIcon/Locations.svg';
import { ReactComponent as FinanceIcon } from '../../images/sidebarIcon/Finance.svg';
import { ReactComponent as OrdersIcon } from '../../images/sidebarIcon/Orders.svg';
import { ReactComponent as MakeMoneyIcon } from '../../images/sidebarIcon/MakeMoneyFromFaces.svg';
import { ReactComponent as MarketingIcon } from '../../images/sidebarIcon/Marketing.svg';
import { ReactComponent as SupportIcon } from '../../images/sidebarIcon/Support.svg';

import { ReactComponent as TrainingIcon } from '../../images/sidebarIcon/Training.svg';
import { ReactComponent as EnquiriesIcon } from '../../images/sidebarIcon/Trainingenquiries.svg';
import { ReactComponent as StudentsIcon } from '../../images/sidebarIcon/TrainingStudents.svg';
import { ReactComponent as PrescriberSearchIcon } from '../../images/sidebarIcon/PrescriberSearch.svg';
import { ReactComponent as PrescriptionIcon } from '../../images/sidebarIcon/Prescription.svg';
import { ReactComponent as HairPrescriptionIcon } from '../../images/sidebarIcon/HairPrescription.svg';
import { ReactComponent as ExportClientIcon } from '../../images/sidebarIcon/ExportClient.svg';
import { ReactComponent as PaymentsIcon } from '../../images/sidebarIcon/Payments.svg';
import { ReactComponent as ProfileSettingsIcon } from '../../images/sidebarIcon/Profilesettings.svg';
import { ReactComponent as LogoutIcon } from '../../images/sidebarIcon/Logout.svg';
import { ReactComponent as ConsentformIcon } from '../../images/sidebarIcon/Consentform.svg';
import { ReactComponent as AftercareformIcon } from '../../images/sidebarIcon/Aftercareform.svg';
import { ReactComponent as MYLocationsIcon } from '../../images/sidebarIcon/MYLocations.svg';
import { ReactComponent as MyservicesIcon } from '../../images/sidebarIcon/Myservices.svg';
import { ReactComponent as CategoriesIcon } from '../../images/sidebarIcon/Categories.svg';
import { ReactComponent as DiscountcodesIcon } from '../../images/sidebarIcon/Discountcodes.svg';

import { ReactComponent as SendTextMessagesIcon } from '../../images/sidebarIcon/SendTextMessages.svg';

import { ReactComponent as SendEmailIcon } from '../../images/sidebarIcon/SendEmail.svg';

import { ReactComponent as TrainingCoursesIcon } from '../../images/sidebarIcon/Trainingcourses.svg';
import { ReactComponent as MystockistsIcon } from '../../images/sidebarIcon/Mystockists.svg';
import { ReactComponent as PrescriptionRequestsIcon } from '../../images/sidebarIcon/PrescriptionRequests.svg';

import { ReactComponent as CreateprescriptionIcon } from '../../images/sidebarIcon/Createprescription.svg';

import { ReactComponent as PrescriptionAwaitingApprovalIcon } from '../../images/sidebarIcon/PrescriptionAwaitingApproval.svg';
import { ReactComponent as UnusedprescriptionsIcon } from '../../images/sidebarIcon/Unusedprescriptions.svg';

import { ReactComponent as UsedprescriptionsIcon } from '../../images/sidebarIcon/Usedprescriptions.svg';

import { ReactComponent as ChatIcon } from '../../images/sidebarIcon/Chat.svg';
import { ReactComponent as DirectoryIcon } from '../../images/sidebarIcon/Directory.svg';
import { ReactComponent as UsergroupsIcon } from '../../images/sidebarIcon/Usergroups.svg';
import { ReactComponent as GroupemailIcon } from '../../images/sidebarIcon/Groupemail.svg';
import { ReactComponent as GroupmessageIcon } from '../../images/sidebarIcon/Groupmessage.svg';
import { ReactComponent as VerificationIcon } from '../../images/sidebarIcon/Verification.svg';
import { ReactComponent as PrescriptionpaymentreportIcon } from '../../images/sidebarIcon/Prescriptionpaymentreport.svg';
import { ReactComponent as PrescriptionfeesIcon } from '../../images/sidebarIcon/Prescriptionfees.svg';

import { ReactComponent as DirectionIcon } from '../../images/sidebarIcon/Direction.svg';


import { ReactComponent as TakepaymentIcon } from '../../images/sidebarIcon/Takepayment.svg';
import { ReactComponent as PaymentlistIcon } from '../../images/sidebarIcon/Paymentlist.svg';

import { ReactComponent as WeeklylistIcon } from '../../images/sidebarIcon/Weeklylist.svg';
import { ReactComponent as MonthlylistIcon } from '../../images/sidebarIcon/Monthlylist.svg';
import { ReactComponent as TransactionsIcon } from '../../images/sidebarIcon/Transactions.svg';

import { ReactComponent as PayoutsIcon } from '../../images/sidebarIcon/Payouts.svg';
import { ReactComponent as BrandboostIcon } from '../../images/sidebarIcon/Brandboost.svg';
import useWindowDimension from '../../utils/useWindowDimensions'
import url from 'socket.io-client/lib/url'; import { GetPrescriptionServiceAction } from "../../../src/components/redux/actions/prescription/prescriptionServiceCount"

const SidebarComponent = () => {
	const navigate = useNavigate();

	const { profileData } = useSelector((state) => state?.GetProfileDataReducer);

	console.log(profileData, 'profileData')
	const dispatch = useDispatch();
	const NON_PRESCRIBER_ROLE = 4;
	const PRESCRIBER_ROLE = 3;
	const PHARMACY_REQEUEST_REJECTED_ROLE = 6;
	const USER_ROLE = 0;
	const TRAINING_PROVIDER_ROLE = 2;
	let roles = profileData ? profileData.roles : [];
	const isNonPrescriber = () => {
		return roles.includes(NON_PRESCRIBER_ROLE);
	};
	const isPrescriber = () => {
		return roles.includes(PRESCRIBER_ROLE);
	};
	const isNormalUser = () => {
		return roles.includes(USER_ROLE);
	};
	const isTrainingProvider = () => {
		return roles.includes(TRAINING_PROVIDER_ROLE);
	};
	const isPharmacyRequestRejected = () => {
		return roles.includes(PHARMACY_REQEUEST_REJECTED_ROLE);
	};

	let rolesList = profileData?.roles ? profileData?.roles : [];
	let prescriptionRedirectionLink = "/prescriber/register";
	let { prescriber, non_prescriber, non_registered_prescriber } = USER_ROLES;
	if (rolesList.indexOf(non_registered_prescriber) > -1) {
		prescriptionRedirectionLink = "/pharmacy/list";
	} else if (rolesList.indexOf(non_prescriber) > -1) {
		prescriptionRedirectionLink = "/prescription/request-prescription";
	} else if (rolesList.indexOf(prescriber) > -1) {
		prescriptionRedirectionLink = "/prescription/create";
	}

	const [divIndex, setDivIndex] = useState(0)

	const { initGetPrescriptionServiceData, getPrescriptionServiceQueryData } = GetPrescriptionServiceAction()

	const prescriptionCount = useSelector(
		(state) =>
			state?.GetPrescriptionReducer?.prescriptionCount
				?.totalPrescriptionBadgeCount
	);
	const { faces_onboarding_status } = useSelector(
		(state) => state?.FacesAiReducer
	);

	useEffect(() => {
		initGetPrescriptionServiceData()
	}, [])

	console.log("getPrescriptionServiceQueryData", getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts)
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);
	const { initexportClientCsvDataData } = ExportClientCsvData();

	const downloadCSVFromBase64 = (base64String, filename = 'download.csv') => {
		const base64GlobalPrefix = 'data:text/csv;base64,';
		const link = document.createElement('a');

		link.href = `${base64GlobalPrefix}${base64String}`;
		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	let currentTimeStamp = Date.now();

	const exportClientData = async () => {
		initexportClientCsvDataData({},
			(res) => {
				if (res?.data?.exportClientDataFromSidebar?.success) {
					downloadCSVFromBase64(res?.data?.exportClientDataFromSidebar?.data, `export-clients-data-${currentTimeStamp}.csv`);
				}
			},
			(err) => {

			}
		)
	}

	let sideMenu = [
		{
			option: "Home",
			image: <HomeIcon />,
			submenu: "",
			// fullUrl: frontendURL + "/business-dashboard"
			onclick: () => navigate(businessDashboardRoute()),
		},
		// faces_onboarding_status?.is_onboarded
		//   ? {
		//     option: "Ai-Assistant",
		//     image: <i class="fa fa-comments"></i>,

		//     sideImage: (
		//       <img
		//         src={newFeature}
		//         alt=""
		//       //   style={{ width: "50px" }}
		//       />
		//     ),
		//     submenu: [
		//       {
		//         option: "Chats",
		//         image: <i class="fa fa-message"></i>,
		//         onclick: () =>
		//           window.open(
		//             faces_onboarding_status?.faces_ai_conversation_link,
		//             "_blank"
		//           ),
		//       },
		//       {
		//         option: "Connect Accounts",
		//         image: <i class="fa fa-user"></i>,
		//         onclick: () =>
		//           window.open(
		//             faces_onboarding_status?.faces_ai_integration_link,
		//             "_blank"
		//           ),
		//       },
		//     ],
		//   }
		//   : [],
		{
			option: "Calendar",
			image: <CalendarIcon />,
			submenu: "",
			// url: "/calendar/records"
			url: "",
			onclick: () => navigate(getCalendarRoute()),
		},
		{
			option: "Clients",
			image: <ClientsIcon />,
			submenu: "",
			onclick: () => navigate(getSearchClientRoute()),
		},
		{
			option: "Shop",
			image: <ShopIcon />,
			submenu: "",
			// url: "/calendar/records"
			url: '/shop',
			// onclick: () => navigate(getCalendarRoute()),
		},
		isPrescriber()
			? {
				option:
					"Prescription service" +
					(prescriptionCount > 0 ? " (" + prescriptionCount + ")" : ""),
				image: <PrescriptionIcon />,
				submenu: [
					{
						option: "Prescriber search",
						image: <PrescriberSearchIcon />,
						submenu: "",
						url: "/prescriber/search",
					},
					{
						option: "My stockists",
						image: <MystockistsIcon />,
						url: "/pharmacy/list",
					},
					{
						option: "Directory",
						image: <DirectoryIcon />,
						url: "/prescriber/directory",
					},
					{
						option: "User groups",
						image: <UsergroupsIcon />,
						url: "/user-group",
					},
					{
						option: "Group email",
						image: <GroupemailIcon />,
						url: "/user-group/send-emails",
					},
					{
						option: "Group message",
						image: <GroupmessageIcon />,
						url: "/user-group/send-message",
					},
					{
						option: "Verification",
						image: <VerificationIcon />,
						url: "/prescriber/verification",
					},
					{
						option: "Prescription requests",
						image: <PrescriptionRequestsIcon />,
						url: "/prescription/requests",
					},
					{
						option: "Create prescription",
						image: <CreateprescriptionIcon />,
						url: prescriptionRedirectionLink,
					},
					{
						option: "Prescription Awaiting Approval",
						image: <PrescriptionAwaitingApprovalIcon />,
						url: "/prescription/list",
					},
					{
						option: "Unused prescriptions",
						image: <UnusedprescriptionsIcon />,
						url: "/prescription/unfulfilled",
					},
					{
						option: "Used prescriptions",
						image: <UsedprescriptionsIcon />,
						url: "/prescription/filled",
					},
					{
						option: "Prescription payment report",
						image: <PrescriptionpaymentreportIcon />,
						url: "/prescription/fees",
					},
					{
						option: "Prescription fees",
						image: <PrescriptionfeesIcon />,
						url: "/prescription/paid-fees",
					},
					{
						option: "Direction",
						image: <DirectionIcon />,
						url: "/prescription/directions",
					},
					{
						option: "Chat",
						image: <ChatIcon />,
						url: "/chat",
					},
					profileData?.is_show_hair_prescription_request ? {
						option: "Hair prescription requests",
						// image: <i class="fa fa-envelope-open"></i>,
						image: <HairPrescriptionIcon />,
						submenu: "",
						url: "/hair-prescription/list",
					} : {},
				],
			}
			: [],

		isNonPrescriber()
			? {
				option:
					"Prescription service" +
					(prescriptionCount > 0 ? " (" + prescriptionCount + ")" : ""),
				image: <PrescriptionIcon />,
				submenu: [
					{
						option: "Prescriber search",
						image: <PrescriberSearchIcon />,
						submenu: "",
						url: "/prescriber/search",
					},
					{
						option: "Create prescription",
						image: <CreateprescriptionIcon />,
						url: prescriptionRedirectionLink,
					},
					{
						option: "My stockists",
						image: <MystockistsIcon />,
						url: "/pharmacy/list",
					},
					{
						option: "Prescription requests",
						image: <PrescriptionRequestsIcon />,
						url: "/prescription/requests",
					},
					{
						option: "Prescription Awaiting Approval",
						image: <PrescriptionAwaitingApprovalIcon />,
						url: "/prescription/list",
					},
					{
						option: "Unused prescriptions",
						image: <UnusedprescriptionsIcon />,
						url: "/prescription/unfulfilled",
					},
					{
						option: "Used prescriptions",
						image: <UsedprescriptionsIcon />,
						url: "/prescription/filled",
					},
					{
						option: "Chat",
						image: <ChatIcon />,
						url: "/chat",
					},
				],
			}
			: [],

		isPharmacyRequestRejected()
			? {
				option:
					"Prescription service" +
					(prescriptionCount > 0 ? " (" + prescriptionCount + ")" : ""),
				image: <PrescriptionIcon />,
				submenu: [
					{
						option: "Prescriber search",
						image: <PrescriberSearchIcon />,
						submenu: "",
						url: "/prescriber/search",
					},
					{
						option: "My stockists",
						image: <MystockistsIcon />,
						url: "/pharmacy/list",
					},

				],
			}
			: [],
		{
			option: "Marketing",
			image: <MarketingIcon />,
			submenu: [

				{
					option: "Discount codes",
					image: <DiscountcodesIcon />,
					submenu: "",
					url: "/user/discount-code",
				},
				{
					option: "Brand boost",
					image: <BrandboostIcon />,
					submenu: "",
					// url: "/social-media-marketing",
					onclick: () => navigate(socialMediaMarketingPagePattern()),
				},
				{
					option: "Send text messages",
					image: <SendTextMessagesIcon />,
					submenu: "",
					url: "/client/promotional-message",
				},
				{
					option: "Email campaigns",
					image: <SendEmailIcon />,
					submenu: "",
					url: "/client/promotional-email",
				},
				{
					option: "Make money from faces",
					// image: <i class="fa fa-shopping-cart"></i>,
					image: <MakeMoneyIcon />,
					submenu: "",
					url: "/affiliation-order/list",
				},

			]
		},
		{
			option: "Support",
			image: <SupportIcon />,
			submenu: "",
			// onclick: () => navigate(comingsoonpage()),
			url: "/support-chat",
		},

		// {
		//   option: "Clients",
		//   // image: <i class="fa fa-group"></i>,
		//   image: <ClientsIcon />,
		//   submenu: [
		//     {
		//       option: "My Clients",
		//       image: <i class="fa fa-folder"></i>,
		//       // url: "/client-directory/list",
		//       url: "",
		//       onclick: () => navigate(getSearchClientRoute()),
		//     },
		//     // {
		//     //   option: "Groups",
		//     //   image: <i class="fa fa-group"></i>,
		//     //   url: "/client-group/list",
		//     // },
		//     // {
		//     //     option: "Client Subscription",
		//     //     image: "",
		//     //     url: "/client-directory/client-subscription"
		//     // },
		//     // {
		//     //   option: "Deleted Clients",
		//     //   image: <i class="fa fa-user-times"></i>,
		//     //   url: "/client-directory/inactive-list",
		//     // },
		//   ],
		// },

		// profileData?.is_hide_website === false ? {
		//   option: "My Website",
		//   sideImage: (
		//     <img
		//       src={newFeature}
		//       alt=""
		//     />
		//   ),
		//   image: <img
		//     src={websiteIcon}
		//     alt=""
		//     style={{ width: "20px" }}
		//   />,
		//   submenu: "",
		//   onclick: () => navigate(createWebsiteRoute())
		// } : {},
		// {
		//   option: "Insurance",
		//   // image: <i class="fa fa-shield"></i>,
		//   image: <InsuranceIcon />,
		//   submenu: "",
		//   // fullUrl: frontendURL + insuranceLandingPage
		//   onclick: () => navigate(insuranceLandingPagePattern()),
		// },
		// {
		//   option: "Services",
		//   image: <i class="fa fa-file"></i>,
		//   submenu: [
		//     {
		//       option: "Prescriber Search",
		//       image: <i class="fa fa-file-text-o"></i>,
		//       url: "/prescriber/search",
		//     },
		//     {
		//       option: "Finance Lenders",
		//       image: <i class="fa fa-file-text-o"></i>,
		//       url: "/finance-lenders/details",
		//     },
		//     {
		//       option: "Card Machine Companies",
		//       image: <i class="fa fa-file-text-o"></i>,
		//       url: "/card-reader/company",
		//     },

		//     {
		//       option: "Waste Management Services",
		//       image: <i class="fa fa-file-text-o"></i>,
		//       url: "/sharp-collections",
		//     },
		//     {
		//       option: "CPD Services",
		//       image: <i class="fa fa-file-text-o"></i>,
		//       url: "/cpd-services",
		//     },
		//     {
		//       option: "Social Media Marketing",
		//       image: <i class="fa fa-file-text-o"></i>,
		//       onclick: () => navigate(socialMediaMarketingPagePattern()),
		//     },
		//     {
		//       option: "Accountancy",
		//       image: <i class="fa fa-file-text-o"></i>,
		//       onclick: () => navigate(accountancyServicePagePattern()),
		//     },
		//     {
		//       option: "Complications Consultant",
		//       image: <i class="fa fa-file-text-o"></i>,
		//       url: "/aesthetic-help-line",
		//     },
		//   ],
		// },
		// {
		//   option: "Consent Forms",
		//   image: <i class="fa fa-file"></i>,
		//   submenu: [
		//     {
		//       option: "Consent and Appointment Forms",
		//       image: <i class="fa fa-list-alt"></i>,
		//       url: "/appointment-form",
		//     },
		//     {
		//       option: "Client Consent Forms",
		//       image: <i class="fa fa-check-square-o"></i>,
		//       url: "/filled-consent-form/list",
		//     },
		//   ],
		// },
		// {
		//   option: "Medical Forms",
		//   image: <i class="fa fa-file-text"></i>,
		//   submenu: [
		//     {
		//       option: "Medical Questions",
		//       image: <i class="fa fa-medkit"></i>,
		//       url: "/medical-questions/edit",
		//     },
		//     {
		//       option: "Forward Medical Form",
		//       image: <i class="fa fa-share"></i>,
		//       url: "/medical-forms/forward",
		//     },
		//   ],
		// },
		// {
		//   option: "Manage forms",
		//   image: <DiscoverIcon />,

		//   submenu: [
		//     {
		//       option: "Consent form",
		//       image: <ConsentformIcon />,
		//       url: "/consent-form/list",
		//     },
		//     {
		//       option: "Aftercare form",
		//       image: <AftercareformIcon />,
		//       url: "/after-care-template/list",
		//     },
		//   ],
		// },
		// {
		//   option: "Discount Codes",
		//   image: <i class="fa fa-gift"></i>,
		//   submenu: "",
		//   url: "/user/discount-code",
		// },
		// isNonPrescriber() || isPrescriber() || isPharmacyRequestRejected()
		{
			option: "Deposits",
			// image: <i class="fa fa-money"></i>,
			image: <DepositsIcon />,
			submenu: "",
			// url: "/direct-deposit/list",
			onclick: () => navigate(directDepositeLinkRoute()),
		},

		// {
		//   option: "Locations & services",
		//   image: <LocationsIcon />,
		//   submenu: [
		//     {
		//       option: "My locations",
		//       image: <MYLocationsIcon />,
		//       url: "/clinic/list",
		//     },
		//     {
		//       option: "My services",
		//       image: <MyservicesIcon />,
		//       url: "/treatment/list",
		//     },
		//     {
		//       option: "Service categories",
		//       image: <CategoriesIcon />,
		//       url: "/treatment-category/list",
		//     },
		//   ],
		// },
		// {
		//   option: "Finance",
		//   // image: <i class="fa fa-money"></i>,
		//   image: <FinanceIcon />,
		//   submenu: [
		//     {
		//       option: "Finance Lender",
		//       image: <i class="fa fa-money"></i>,
		//       url: "/finance-lenders/details"
		//     },
		//     profileData?.finance_approved === false ? {
		//       option: "Finance orders",
		//       image: <i class="fa fa-money"></i>,
		//       url: "/finance-order/details"
		//     } : null,
		//   ].filter(Boolean)
		// },
		// {
		//   option: "Orders",
		//   image: <OrdersIcon />,
		//   submenu: "",
		//   url: "/order/list",
		// },

		// {
		//   option: "Make money from faces",
		//   // image: <i class="fa fa-shopping-cart"></i>,
		//   image: <MakeMoneyIcon />,
		//   submenu: "",
		//   url: "/affiliation-order/list",
		// },

		// {
		//   option: "Training school",
		//   // image: <i class="fa fa-money"></i>,
		//   image: <TrainingIcon />,
		//   submenu: [
		//     {
		//       option: "Training courses",
		//       // image: <i class="fa fa-graduation-cap"></i>,
		//       image: <TrainingCoursesIcon />,
		//       submenu: "",
		//       url: "/training/frontend-list",
		//     },
		//     {
		//       option: "Training enquiries",
		//       // image: <i class="fa fa-question-circle-o"></i>,
		//       image: <EnquiriesIcon />,
		//       submenu: "",
		//       url: "/training-inquiries/list",
		//     },
		//     isTrainingProvider()
		//     ? {
		//       option: "Students",
		//       // image: <i class="fa fa-group"></i>,
		//       image: <StudentsIcon />,
		//       submenu: "",
		//       url: "/training-institute-students/list",
		//     }
		//     : []
		//   ]
		// },

		{
			option: isTrainingProvider() ? "My training academy" : "Training academy",
			image: <TrainingIcon />,
			submenu: [

				...(isTrainingProvider()
					? [
						{
							option: "Dashboard",
							image: <i class="fa fa-circle"></i>,
							submenu: "",
							url: "/training-dashboard/dashboard",
						},
						{
							option: "Calendar",
							image: <i class="fa fa-circle"></i>,
							submenu: "",
							url: "/training-dashboard/calendar",
						},
						{
							option: "Student Section",
							image: <i class="fa fa-circle"></i>,
							submenu: "",
							url: "/training-dashboard/student-section",
						},
						{
							option: "Courses",
							image: <i class="fa fa-circle"></i>,
							submenu: "",
							url: "/training-dashboard/my-courses",
						},
						{
							option: "Assignments",
							image: <i class="fa fa-circle"></i>,
							submenu: "",
							url: "/training-dashboard/assignments",
						},
						{
							option: "Inbox",
							image: <i class="fa fa-circle"></i>,
							submenu: "",
							url: "/training-dashboard/chat",
						},
						{
							option: "Reviews",
							image: <i class="fa fa-circle"></i>,
							submenu: "",
							url: "/training-dashboard/reviews",
						},
						{
							option: "Settings",
							image: <i class="fa fa-circle"></i>,
							submenu: "",
							url: "/training-dashboard/settings",
						},
					]
					: [
						{
							option: "Training courses",
							image: <TrainingCoursesIcon />,
							submenu: "",
							url: "/training-dashboard/student-portal-dashboard",
						},
						{
							option: "Training enquiries",
							image: <EnquiriesIcon />,
							submenu: "",
							url: "/training-inquiries/list",
						},
					])
			]
		},




		// {
		//   option: "Send Text Messages",
		//   image: <i class="fa fa-comment-o"></i>,
		//   submenu: "",
		//   url: "/client/promotional-message",
		// },

		// {
		//   option: "Send Email",
		//   image: <i class="fa fa-envelope-open"></i>,
		//   submenu: "",
		//   url: "/client/promotional-email",
		// },

		// (isNonPrescriber() || isPrescriber()) ?
		// {
		//     option: "Prescription Services",
		//     image: <i class="fa fa-file"></i>,
		//     submenu: [
		//         {
		//             option: "Create Prescription",
		//             image: <i class="fa fa-edit"></i>,
		//             url: "/prescription/request-prescription"
		//         },
		//         isNonPrescriber() ? {
		//             option: "My Pharmacies",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/pharmacy/list"
		//         } : [],
		//        {
		//             option: "My Prescribers",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/prescriber/directory"
		//         },
		//         isNonPrescriber() ? {
		//             option: "Prescription Requests",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/prescription/requests"
		//         } : [],
		//         {
		//             option: "Prescription Awaiting Approval",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/prescription/list"
		//         },
		//         {
		//             option: "Unused Prescriptions",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/prescription/unfulfilled"
		//         },
		//         {
		//             option: "Used Prescription",
		//             image:<i class="fa fa-file"></i>,
		//             url: "/prescription/filled"
		//         },
		//         isPrescriber() ? {
		//             option: "Prescription Payment Report",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/prescription/fees"
		//         } : [],
		//         isPrescriber() ? {
		//             option: "Prescription Fees",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/prescription/paid-fees"
		//         } : [],
		//         isPrescriber() ? {
		//             option: "Prescription Payment Report",
		//             image:<i class="fa fa-file"></i>,
		//             url: "/prescription/directions"
		//         } : [],
		//         {
		//             option: "Chat",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/chat"
		//         },
		//     ]
		// }:[],
		// isPrescriber()
		//   ? {
		//     option:
		//       "Prescription Services" +
		//       (prescriptionCount > 0 ? " (" + prescriptionCount + ")" : ""),
		//     image: <i class="fa fa-file"></i>,
		//     submenu: [
		//       {
		//         option: "My Pharmacies",
		//         image: <i class="fa fa-medkit"></i>,
		//         url: "/pharmacy/list",
		//       },
		//       {
		//         option: "Directory",
		//         image: <i class="fa fa-address-book-o"></i>,
		//         url: "/prescriber/directory",
		//       },
		//       {
		//         option: "User Groups",
		//         image: <i class="fa fa-user-circle"></i>,
		//         url: "/user-group",
		//       },
		//       {
		//         option: "Group Email",
		//         image: <i class="fa fa-envelope-open"></i>,
		//         url: "/user-group/send-emails",
		//       },
		//       {
		//         option: "Group Message",
		//         image: <i class="fa fa-comment-o"></i>,
		//         url: "/user-group/send-message",
		//       },
		//       {
		//         option: "Verification",
		//         image: <i class="fa fa-shield"></i>,
		//         url: "/prescriber/verification",
		//       },
		//       {
		//         option: "Prescription Requests",
		//         image: <i class="fa fa-level-down"></i>,
		//         url: "/prescription/requests",
		//       },
		//       {
		//         option: "Create Prescription",
		//         image: <i class="fa fa-edit"></i>,
		//         url: prescriptionRedirectionLink,
		//       },
		//       {
		//         option: "Prescription Awaiting Approval",
		//         image: <i class="fa fa-clock-o"></i>,
		//         url: "/prescription/list",
		//       },
		//       {
		//         option: "Unused Prescriptions",
		//         image: <i class="fa fa-times-rectangle"></i>,
		//         url: "/prescription/unfulfilled",
		//       },
		//       {
		//         option: "Used Prescription",
		//         image: <i class="fa fa-file-text"></i>,
		//         url: "/prescription/filled",
		//       },
		//       {
		//         option: "Prescription Payment Report",
		//         image: <i class="fa fa-money"></i>,
		//         url: "/prescription/fees",
		//       },
		//       {
		//         option: "Prescription Fees",
		//         image: <i class="fa fa-money"></i>,
		//         url: "/prescription/paid-fees",
		//       },
		//       {
		//         option: "Direction",
		//         image: <i class="fa fa-arrow-right"></i>,
		//         url: "/prescription/directions",
		//       },
		//       {
		//         option: "Chat",
		//         image: <i class="fa fa-comments"></i>,
		//         url: "/chat",
		//       },
		//     ],
		//   }
		//   : [],
		// (isNonPrescriber() || isPrescriber()) ?
		// {
		//     option: "Prescription Services",
		//     image: <i class="fa fa-file"></i>,
		//     submenu: [
		//         {
		//             option: "Create Prescription",
		//             image: <i class="fa fa-edit"></i>,
		//             url: "/prescription/request-prescription"
		//         },
		//         isNonPrescriber() ? {
		//             option: "My Pharmacies",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/pharmacy/list"
		//         } : [],
		//        {
		//             option: "My Prescribers",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/prescriber/directory"
		//         },
		//         isNonPrescriber() ? {
		//             option: "Prescription Requests",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/prescription/requests"
		//         } : [],
		//         {
		//             option: "Prescription Awaiting Approval",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/prescription/list"
		//         },
		//         {
		//             option: "Unused Prescriptions",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/prescription/unfulfilled"
		//         },
		//         {
		//             option: "Used Prescription",
		//             image:<i class="fa fa-file"></i>,
		//             url: "/prescription/filled"
		//         },
		//         isPrescriber() ? {
		//             option: "Prescription Payment Report",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/prescription/fees"
		//         } : [],
		//         isPrescriber() ? {
		//             option: "Prescription Fees",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/prescription/paid-fees"
		//         } : [],
		//         isPrescriber() ? {
		//             option: "Prescription Payment Report",
		//             image:<i class="fa fa-file"></i>,
		//             url: "/prescription/directions"
		//         } : [],
		//         {
		//             option: "Chat",
		//             image: <i class="fa fa-file"></i>,
		//             url: "/chat"
		//         },
		//     ]
		// }:[],

		isPrescriber()
			? {
				option:
					"Prescription service" +
					(getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.totalPrescriptionService > 0 ? " (" + getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.totalPrescriptionService + ")" : ""),
				image: <PrescriptionIcon />,
				submenu: [
					{
						option: "Prescriber search",
						// image: <i class="fa fa-search"></i>,
						image: <PrescriberSearchIcon />,
						submenu: "",
						url: "/prescriber/search",
					},
					{
						option: "My Pharmacies" +
							(getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.myPharmacy > 0 ? " (" + getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.myPharmacy + ")" : ""),
						image: <MystockistsIcon />,
						url: "/pharmacy/list",
					},
					{
						option: "Directory",
						image: <DirectoryIcon />,
						url: "/prescriber/directory",
					},
					{
						option: "User groups",
						image: <UsergroupsIcon />,
						url: "/user-group",
					},
					{
						option: "Group email",
						image: <GroupemailIcon />,
						url: "/user-group/send-emails",
					},
					{
						option: "Group message",
						image: <GroupmessageIcon />,
						url: "/user-group/send-message",
					},
					{
						option: "Verification",
						image: <VerificationIcon />,
						url: "/prescriber/verification",
					},
					{
						option: "Prescription requests" +
							(getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.prescriptionRequests > 0 ? " (" + getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.prescriptionRequests + ")" : ""),
						image: <PrescriptionRequestsIcon />,
						url: "/prescription/requests",
					},
					{
						option: "Create prescrription",
						image: <CreateprescriptionIcon />,
						url: prescriptionRedirectionLink,
					},
					{
						option: "Prescription Awaiting Approval",
						image: <PrescriptionAwaitingApprovalIcon />,
						url: "/prescription/list",
					},
					{
						option: "Unused prescriptions" +
							(getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.unusedPrescriptions > 0 ? " (" + getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.unusedPrescriptions + ")" : ""),
						image: <UnusedprescriptionsIcon />,
						url: "/prescription/unfulfilled",
					},
					{
						option: "Used prescriptions",
						image: <UsedprescriptionsIcon />,
						url: "/prescription/filled",
					},
					{
						option: "Prescription payment report",
						image: <PrescriptionpaymentreportIcon />,
						url: "/prescription/fees",
					},
					{
						option: "Prescription fees",
						image: <PrescriptionfeesIcon />,
						url: "/prescription/paid-fees",
					},
					{
						option: "Direction",
						image: <DirectionIcon />,
						url: "/prescription/directions",
					},
					{
						option: "Chat" +
							(getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.chat > 0 ? " (" + getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.chat + ")" : ""),
						image: <ChatIcon />,
						url: "/chat",
					},
				],
			}
			: [],

		isNonPrescriber()
			? {
				option:
					"Prescription service" +
					(getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.totalPrescriptionService > 0 ? " (" + getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.totalPrescriptionService + ")" : ""),
				// (prescriptionCount > 0 ? " (" + prescriptionCount + ")" : ""),
				image: <PrescriptionIcon />,
				submenu: [
					{
						option: "Prescriber search",
						image: <PrescriberSearchIcon />,
						submenu: "",
						url: "/prescriber/search",
					},
					{
						option: "Create prescription",
						image: <CreateprescriptionIcon />,
						url: prescriptionRedirectionLink,
					},
					{
						option: "My stockists" +
							(getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.myPharmacy > 0 ? " (" + getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.myPharmacy + ")" : ""),
						image: <MystockistsIcon />,
						url: "/pharmacy/list",
					},
					{
						option: "Prescription requests" +
							(getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.prescriptionRequests > 0 ? " (" + getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.prescriptionRequests + ")" : ""),
						image: <MystockistsIcon />,
						url: "/prescription/requests",
					},
					{
						option: "Prescription Awaiting Approval",
						image: <PrescriptionAwaitingApprovalIcon />,
						url: "/prescription/list",
					},
					{
						option: "Unused prescriptions" +
							(getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.unusedPrescriptions > 0 ? " (" + getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.unusedPrescriptions + ")" : ""),
						image: <UnusedprescriptionsIcon />,
						url: "/prescription/unfulfilled",
					},
					{
						option: "Used prescriptions",
						image: <UsedprescriptionsIcon />,
						url: "/prescription/filled",
					},
					{
						option: "Chat" +
							(getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.chat > 0 ? " (" + getPrescriptionServiceQueryData?.data?.getPrescriptiondashboardcounts?.data?.chat + ")" : ""),
						image: <ChatIcon />,
						url: "/chat",
					},
				],
			}
			: [],

		isPharmacyRequestRejected()
			? {
				option:
					"Prescription service" +
					(prescriptionCount > 0 ? " (" + prescriptionCount + ")" : ""),
				// image: <i class="fa fa-file"></i>,
				image: <PrescriptionIcon />,
				submenu: [
					{
						option: "Prescriber search",
						image: <PrescriberSearchIcon />,
						submenu: "",
						url: "/prescriber/search",
					},
					{
						option: "My stockists",
						image: <MystockistsIcon />,
						url: "/pharmacy/list",
					},

				],
			}
			: [],


		// isPrescriber()
		//   ? {
		//     option: "Payouts",
		//     image: <PayoutsIcon />,
		//     submenu: [
		//       {
		//         option: "Weekly list",
		//         image: <WeeklylistIcon />,
		//         url: "/payout/weekly-list",
		//       },
		//       {
		//         option: "Monthly list",
		//         image: <MonthlylistIcon />,
		//         url: "/payout/monthly-list",
		//       },
		//       {
		//         option: "Transactions",
		//         image: <TransactionsIcon />,
		//         url: "/transactions/transactions",
		//       },
		//     ],
		//   }
		//   : {},
		{
			option: "Export Client Data",
			image: <i class="fa fa-download"></i>,
			submenu: "",
			url: "",
			onclick: () => exportClientData(),
		},

		profileData?.is_show_hair_prescription_request ? {
			option: "Hair prescription requests",
			// image: <i class="fa fa-envelope-open"></i>,
			image: <HairPrescriptionIcon />,
			submenu: "",
			url: "/hair-prescription/list",
		} : {},

		{
			option: "Payments",
			// image: <i class="fa fa-money"></i>,
			image: <PaymentsIcon />,
			submenu: [
				{
					option: "Take payment",
					image: <TakepaymentIcon />,
					url: "/yotta/take-payment",
				},
				{
					option: "Payment list",
					image: <PaymentlistIcon />,
					url: "/yotta/yotta-payment-list",
				},
			],
		},
		{
			option: "Profile settings",
			// image: <i class="fa fa-user"></i>,
			image: <ProfileSettingsIcon />,
			submenu: "",
			url: "/user/update-profile",
		},
		// {
		//   option: "Logout",
		//   // image: <i class="fa fa-power-off"></i>,
		//   image: <LogoutIcon />,
		//   submenu: "",
		//   url: "",
		//   onclick: () => dispatch(logoutProfile()),
		// },
	];

	sideMenu.forEach((menu) => {
		if (menu.submenu) {
			menu.submenu = menu.submenu.filter((submenuElement) => {
				return (
					submenuElement !== null &&
					(!Array.isArray(submenuElement) || submenuElement.length > 0) &&
					(typeof submenuElement !== "object" ||
						Object.keys(submenuElement).length > 0)
				);
			});
		} else {
			return menu;
		}
	});

	sideMenu = sideMenu.filter(
		(menu) =>
			menu !== null &&
			(!Array.isArray(menu) || menu.length > 0) &&
			(typeof menu !== "object" || Object.keys(menu).length > 0)
	);

	useEffect(() => {
		dispatch(getPrescriptionCount({
			'request-from': 'frontend-sidebar',
		}));
	}, []);

	return (
		<div className="main-sidebar-container NewSideBarChanges" >
			<Sidebar toggled={true}>
				<Menu>
					{sideMenu?.map((item, index) => (
						<>
							{item?.submenu?.length === 0 ? (
								<MenuItem>
									{item?.url == "" && !item?.fullUrl ? (
										<div onClick={() => {
											setDivIndex(index)
											item.onclick()
										}}>
											<div className={`d-flex gap-2 wrapperLink ${index === divIndex ? 'Active' : ''}`}>
												{/* <img src={item?.image} alt="" /> */}
												<div>{item?.image}</div>
												<span>{item?.option}</span>
												{item?.sideImage && (
													<div className="TagNewAI">
														{item?.sideImage}
														<span>New</span>
													</div>
												)}

											</div>
										</div>
									) : (
										<a
											href={item?.fullUrl ? item?.fullUrl : item?.url ? webBaseUrl + item?.url : "javascript:void(0)"}
											onClick={() => {
												setDivIndex(index)
												if (item?.onclick) {
													item.onclick()
												}
											}}
										>
											<div className={`d-flex gap-2 wrapperLink ${index === divIndex ? 'Active' : ''}`}>
												<div>{item?.image}</div>
												<span>{item?.option}</span>
												{item?.sideImage && (
													<div className="TagNewAI">
														{item?.sideImage}
														<span>New</span>
													</div>
												)}

											</div>
										</a>
									)}
								</MenuItem>
							) : (
								<SubMenu
									label={
										<div className={`d-flex gap-2 wrapperLink ${index === divIndex ? 'Active' : ''}`}>
											<div>{item?.image}</div>
											<span>{item?.option}</span>
											{item?.sideImage && (
												<div className="TagNewAI">
													{item?.sideImage}
													<span>New</span>
												</div>
											)}
										</div>
									}
								>
									{item?.submenu?.map((item) => (
										<MenuItem>
											<a
												href={
													item?.fullUrl
														? item?.fullUrl
														: item?.url
															? webBaseUrl + item?.url
															: "javascript:void(0);"
												}
												onClick={() => {
													setDivIndex(index)
													if (item?.onclick) {
														item.onclick()
													}
												}}
											>
												<div className={`d-flex gap-2 wrapperLink ${index === divIndex ? 'Active' : ''}`}>
													<div>{item?.image}</div>
													<span>{item.option}</span>
												</div>
											</a>
										</MenuItem>
									))}
								</SubMenu>
							)}
						</>
					))}
				</Menu>
			</Sidebar>
		</div>
	);
};

export default SidebarComponent;
