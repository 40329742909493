import React from 'react';
import "../SearchModal/search-modal.scss";

const TreatmentDescriptionRenderer = ({ description }) => {
  if (!description) return null;

  const lines = description.split(/\r\n|\r|\n/);

  return (
    <div style={{ maxHeight: '52vh', overflowY: 'auto', }} className='desc_div'>
      {lines.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </div>
  );
};

export default TreatmentDescriptionRenderer;
