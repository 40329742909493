import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_USER_BANK_DETAILS_QUERY = gql`
  query GetBankDetailOfUser {
    getBankDetailOfUser {
      success
      message
      data {
        bankName
        accountNumber
        sortCode
        sign
      }
    }
  }
`;

export const GetBankDetailOfUserAction = () => {
  // const dispatch = useDispatch();
  const [getBankDetailOfUserResponseFunction, getBankDetailOfUserResponseData] =
    useLazyQuery(GET_USER_BANK_DETAILS_QUERY, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
      onCompleted: ({ getBankDetailOfUser: responseData }) => {
        if (responseData.success) {
          // toast.success(responseData?.message)
        } else {
          // toast.error(responseData?.message)
        }
      },
    });
  const getBankDetailOfUserQueryData = getBankDetailOfUserResponseData;

  const initGetBankDetailOfUserData = (data, successCallback, errorCallback) => {
    getBankDetailOfUserResponseFunction({
      variables: data,
    }).then((responseData) => {
      if (responseData?.data?.getBankDetailOfUser?.success) {
        successCallback(responseData);
      } else {
        errorCallback(responseData);
      }
    }).catch((error) => {
      errorCallback(error);
    });
  };

  return { getBankDetailOfUserQueryData, initGetBankDetailOfUserData };
};
