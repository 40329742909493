import React, { useEffect, useRef, useState } from "react"
import "../login/login-component.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import FinaceHeader from "../../finaceHeader"
import { CheckUserStatusForFirstStape } from "../../redux/actions/pay-later/checkFirstStepForFinanceSection";
import { useNavigate } from 'react-router';
// import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { STORE_USER_EMAIL } from "../../redux/constants";
import StepButton from "../../common/StepButton";


const FinanceFirstStapPage = () => {
    const inputRef = useRef(null);

    const [formData, setFormData] = useState({
        usr_email: '',
    });
    const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

    const navigate = useNavigate();
    // const email = useSelector(state => state?.financeLoanRequestEmailReducer?.userEmailForFinance)
    // console.log("email>>>>>>>>>>>>>>>>>",email);

    const { getUserDetailsForFirstStepdDataQueryData, initcheckUserStatusForFirstStapeData } = CheckUserStatusForFirstStape();
   
    const validateForm = () => {
        let formIsValid = true;
        let errors = {};

        // if (!formData.emailId.trim()) {
        //     errors.emailId = 'Email Address is required';
        //     formIsValid = false;
        // }
        if (!formData.usr_email.trim()) {
            errors.usr_email = 'Email is required';
            formIsValid = false;
        }
        // Add similar conditions for other fields

        setErrors(errors);
        return formIsValid;
    };

    useEffect(() => {
        inputRef.current.focus();
    }, []); 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (errors[name] && value.trim()) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {

        initcheckUserStatusForFirstStapeData({ email: formData.usr_email },
            (res) => {
                console.log("res?.data?.checkStatusesForFinanceRegistration-====",res?.data?.checkStatusesForFinanceRegistration)
                if (res?.data?.checkStatusesForFinanceRegistration?.data?.user_finance_request_status || res?.data?.checkStatusesForFinanceRegistration?.data?.email_exist) {
                    navigate("/login")
                }
                dispatch({
                    type: STORE_USER_EMAIL,
                    payload: formData.usr_email,
                  });
                // else if (res?.data?.checkStatusesForFinanceRegistration?.data?.email_exist) {

                //     toast.error(res?.data?.checkStatusesForFinanceRegistration?.message)
                // }
            },
            (err) => {
                if (err?.data?.checkStatusesForFinanceRegistration?.data?.email_exist === false) {
                    navigate("/registration")
                }
                dispatch({
                    type: STORE_USER_EMAIL,
                    payload: formData.usr_email,
                  });
            }
        )
        }
        // console.log("getUserDetailsForFirstStepdDataQueryData.data.checkStatusesForFinanceRegistration.data:user_finance_request_status::::::::::::::",getUserDetailsForFirstStepdDataQueryData?.data?.checkStatusesForFinanceRegistration.data.user_finance_request_status)
        // if (getUserDetailsForFirstStepdDataQueryData?.data?.checkStatusesForFinanceRegistration?.data?.user_finance_request_status) {
        //     navigate("/login")
        // }
    };
    return (
        <>
            <div className="mainfinancelandingpage">
                <FinaceHeader />
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8 col-sm-12">
                            <h2 className="mt-5 pagenewPageTitle">Welcome</h2>
                            <form className="login-form col-md-12" onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="emailId" className="py-2 form-label">
                                    Email address
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="emailId"
                                        name="usr_email"
                                        placeholder="Email address"
                                        value={formData.usr_email}
                                        onChange={handleChange}
                                        ref={inputRef}
                                    />
                                    {errors.usr_email && <p className="error text-danger">{errors.usr_email}</p>}
                                </div>
                                {/* <button type="submit" className="my-4 col-md-12 btn btn-primary apply-btn">
                                    Continue
                                </button> */}
                                  <StepButton
                                    type="submit"
                                    blue={true}
                                    id={"ryft-pay-btn"}
                                    label={"Continue"}
                                    isLoading={getUserDetailsForFirstStepdDataQueryData.loading}
                                    />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FinanceFirstStapPage