
import CustomModal from "../../modal/CustomModal/CustomModal"
import React, { useState } from "react"
import "./onboarding-online-payment.scss"
import paymentChecke from "../../../images/paymentChecke.svg";
import paymentSetup from "../../../images/paymentSetup.png"
import { useNavigate } from "react-router";

const OnboardingOnlinePayment = () => {
    const [open, setOpen] = useState(true)
    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState(1)
    const status = ["Receive online payments from clients.", "Links to your bank account.", "Set up easily in just minutes."]
    return (
        <>
            {currentStep == 1 && <CustomModal modalOpen={open}
                setModalOpen={setOpen}
                modalBody={
                    <>
                        <div className="paymet-main-conatainer">
                            <div className="mayment-header-container">
                                <div className="payment-header">Online payments</div>
                                <div className="paymet-text">Let clients pay for services with ease online.</div>
                            </div>
                            <div className="paymet-body-conatiner">
                                {status?.map((v, i) => {
                                    return (
                                        <div key={i} className="payment-body">
                                            <img src={paymentChecke} alt="paymentChecke" />
                                            <div className="payment-body-context">{v}</div>
                                        </div>)
                                })}
                            </div>
                            <div className="payment-footer-container">
                                <div className="payment-footer-header">We have partnered with Stripe</div>
                                <div className="payment-footer-context">Stripe is a popular payment platform used by millions of businesses. It helps companies accept payment and manage transactions.</div>
                            </div>
                            <div className="btn-container-payment">
                                <button className="btn-button-payment" onClick={() => { setCurrentStep(2) }}>
                                    Link my account to Stripe
                                </button>
                            </div>
                        </div>
                    </>
                } />}
            {
                currentStep == 2 &&
                <>
                    <div className="main-success-payment">
                        <div className="sub-div-payment">
                            <img src={paymentSetup} alt="paymentSetup" />
                            <div className="header-payment">Setup Complete </div>
                            <div className="sub-header-payment">Your payment method is now linked to your Faces account.</div>
                        </div>

                    </div>
                    <div className="btn-12">
                        <button className="btn-button" onClick={() => { navigate(-1) }}>Continue</button>
                    </div>
                </>
            }
        </>)
}


export default OnboardingOnlinePayment


