import React, { useEffect, useState } from "react";
import BannerHeader from "../../common/BannerHeader";
import { ReactComponent as LocationIcon } from "../../../images/location_icon.svg";
import { ReactComponent as RightArrow } from "../../../images/right_arrow.svg";
import "./location-mobile.scss";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";
import { useParams } from "react-router";
import NotFoundImg from '../../../images/no_data_found.svg'
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { CLEAR_TREATMENT_DETAILS } from "../../redux/constants/treatment-list-const/TreatmentListConst";
import NoAppointmentAvailable from "../../noAppointmentAvailable/NoAppointmentAvailable";
import { Helmet } from 'react-helmet';
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../../utils/useWindowDimensions";
const LocationMobile = ({ setStep, locationData, isLoading, setClinicId, clinicId, businessName, isCalled, selectedLocation, setSelectedLocation, setSelectedTreatment, setShowSelectedDate, setFormatedDate, setShowSelectedTime, isAppointmentDataAvailable, isLoadingNoAppointment, isCalledLoadingNoAppointment, initiateBusinessClinicListApiCall }) => {
  const { slug } = useParams()

  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams();

  const { businessClinicList } = useSelector((state) => state?.businessLocationListReducer)
  const totalClinics = useSelector(
    (state) => state?.businessLocationListReducer?.totalClinics
  );
  const loadedClinicsCount = useSelector(
    (state) => state?.businessLocationListReducer?.loadedClinicsCount
  );

  const dimensions = useWindowDimensions();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [BtnHeight, setBtnHeight] = useState(0);

  useEffect(() => {

    setHeaderHeight(
      document.getElementsByClassName("heading-box")[0]?.offsetHeight || 0
    );

    setBtnHeight(
      document.getElementsByClassName("footer-powered")[0]?.offsetHeight
    );


    window.addEventListener("resize", handleResize);
  }, [headerHeight, BtnHeight, document.getElementsByClassName("heading-box")?.length, document.getElementsByClassName("footer-powered")?.length]);

  const handleResize = () => {

    setHeaderHeight(
      document.getElementsByClassName("heading-box")[0]?.offsetHeight || 0
    );

    setBtnHeight(
      document.getElementsByClassName("footer-powered")[0]?.offsetHeight
    );

  };
  // const measureHeights = () => {
  //   const headerElement = document.querySelector(".heading-box");
  //   const btnElement = document.querySelector(".footer-powered");

  //   if (headerElement) {
  //     setHeaderHeight(headerElement.offsetHeight);
  //   } else {
  //     console.log("Header element not found");
  //   }

  //   if (btnElement) {
  //     setBtnHeight(btnElement.offsetHeight);
  //   } else {
  //     console.log("Button element not found");
  //   }
  // };

  // useLayoutEffect(() => {
  //   measureHeights(); // Initial measurement

  //   window.addEventListener("resize", measureHeights);

  //   return () => {
  //     window.removeEventListener("resize", measureHeights);
  //   };
  // }, []);
  // console.log(dimensions, headerHeight, BtnHeight, "hihihihh", document.getElementsByClassName("footer-powered"), "footer-powered", document.getElementsByClassName("heading-box"), document.getElementsByClassName("heading-box").value)

  const handleNextStep = (id, item) => {
    setSelectedTreatment([])
    setShowSelectedDate(null)
    setFormatedDate(null)
    setShowSelectedTime(null)
    // setClinicId(id);
    searchParams.delete("isNotBypass")
    setSearchParams(searchParams)
    setSelectedLocation(item)
    setFormatedDate(null)
    setSelectedLocation(item)
    setStep(2);
    dispatch({ type: CLEAR_TREATMENT_DETAILS })
    selectedLocation === item.clinic_id ? setClinicId(null) : setClinicId(item.clinic_id);

  }

  useEffect(() => {
    if (searchParams.get("clinic_id")) {
      locationData?.map((item, index) => {
        if (item.clinic_id == businessClinicList?.clinic_DB_id) {
          setSelectedLocation(item)
          setClinicId(item.clinic_id)
          dispatch({ type: CLEAR_TREATMENT_DETAILS })
          searchParams.delete("clinic_id")
          setSearchParams(searchParams)
          setStep(2)
        }
      })
    }
    if (locationData?.length == 1 && searchParams.get("clinic_id") == null && searchParams.get("isNotBypass") == null && isAppointmentDataAvailable) {
      setClinicId(locationData[0].clinic_id)
      setSelectedLocation(locationData[0])
      dispatch({ type: CLEAR_TREATMENT_DETAILS })
      setStep(2)
    }
  }, [locationData])


  useEffect(() => {
    // Handle the back button click event
    window.onpopstate = function (event) {
      if (window.location.search !== "") {
        window.history.go(-2)
      }

    };
  }, [])

  return (

    <div className="container-fluid">
      <Helmet>
        <meta name="X-Robots-Tag" content="noindex,nofollow" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {
        (!isLoading && !isLoadingNoAppointment && locationData == undefined && isCalled) ?
          <>
            <div className="h-100 d-flex justify-content-center align-items-center text-center fw-bold fs-1 ">
              Invalid Link!
            </div>
          </>
          :
          (<div className="location-mobile-container">
            <div>
              {

                isLoading && isLoadingNoAppointment ?
                  <>
                    <div className="w-100 mt-4 me-auto">
                      <div className="w-100 me-auto">
                        <Skeleton width={"70%"} height={40} />
                        <Skeleton className="mt-2" width={"50%"} height={20} />
                      </div>
                      <SkeletonLoader type={"list"} showCircle={true} />
                    </div>
                  </>

                  :
                  isAppointmentDataAvailable && <div className="heading-box">
                    <BannerHeader companyName={`${businessName}`} subTitle={"What location would you like to visit?"} />
                  </div>
              }
            </div>
            {isAppointmentDataAvailable && <div className={"scroll-container cus-scroll-container py-0"}>
              <div id="scrollableDiv" style={{ height: dimensions.height - headerHeight - BtnHeight, width: "100%", overflowY: "scroll" }}>
                <InfiniteScroll
                  dataLength={loadedClinicsCount}
                  next={() => {
                    initiateBusinessClinicListApiCall(false)
                  }}
                  hasMore={loadedClinicsCount < totalClinics}
                  scrollableTarget="scrollableDiv"
                >
                  {
                    locationData?.map((item, index) => (

                      <div key={index} className="list-mobile-location-container" onClick={() => { handleNextStep(item?.clinic_id, item) }}>
                        <div className="list-mobile-right">
                          <LocationIcon style={{ minWidth: "48px", maxHeight: "48px", width: "48px", height: "48px" }} />
                          <div>
                            <h1>{item?.clinic_name}</h1>
                            <p style={{ overflow: 'hidden', }}>{item?.complete_address}</p>
                          </div>
                        </div>
                        <RightArrow />
                      </div>
                    ))
                  }
                </InfiniteScroll>
              </div>
              <div className="footer-powered"><p>Powered by Faces</p></div>
            </div>}
            {
              !isLoadingNoAppointment && !isAppointmentDataAvailable && isCalledLoadingNoAppointment && <NoAppointmentAvailable />
            }
          </div>)
      }
    </div>

  );
};

export default LocationMobile;
