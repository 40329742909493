import { GET_HAIR_PRESCRRIPTION_AMEND_ANSWER, SET_HAIR_PRESCRRIPTION_AMEND_ANSWER_ERROR_PAGE } from "../../constants/hair-prescription-amend-answer/HairPrescriptionAmendAnswerConst";


const initialState = {
    allAmendAnswers: [],
    genderAnswer : {},
    productData : {},
    amendStatus: null,
    totalCount: null,
    errorPage: false
}

export default function GetHairPrescriptionAmendAnsersReducer(state = initialState, action) {

    switch (action.type) {

        case GET_HAIR_PRESCRRIPTION_AMEND_ANSWER:
            return {
                ...state,
                allAmendAnswers: action?.payload?.allAmendAnswers,
                genderAnswer: action?.payload?.genderAnswer,
                productData: action?.payload?.productData,
                amendStatus: action?.payload?.amendStatus,
                totalCount: action?.payload?.totalCount,
                errorPage: false // Reset errorPage to false when successful data is received
            };

        case SET_HAIR_PRESCRRIPTION_AMEND_ANSWER_ERROR_PAGE:
            return {
                ...state,
                errorPage: action.payload
            };

        default:
            return state;
    }

}