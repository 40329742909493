import React, { useEffect, useState } from "react";
import "./Notification.scss";
import { select } from "@redux-saga/core/effects";
import StepButton from "../../../../common/StepButton";
// import { ManageNotificationSettings } from "../../../../redux/actions/customiseNotification/postcustomiseNotification";
import BusinessNavigation from "../../../../common/businessNavigation/BusinessNavigation";
import SwitchButton from "../../../../common/switchButton/SwitchButton";
import { useGetNotificationAccessList } from "../../../../redux/actions/customiseNotification/getcustomiseNotification";
function Notification({ onClickSubmit, setStep }) {
  const [selectedTab, setSelectedTab] = useState("Email");
  const { notificationAccessListData, initializeGetNotificationAccessList } = useGetNotificationAccessList()
  useEffect(() => {
    initializeGetNotificationAccessList({},
      (res) => {
        setemail(res?.data?.getListOfNotificationAccess?.settings?.email)
          ; setprescriptionEmail(res?.data?.getListOfNotificationAccess?.settings?.prescriptionEmail);
        setsocialMedia(res?.data?.getListOfNotificationAccess?.settings?.socialMedia)
      }, (err) => { })
  }, [])
  const [email, setemail] = useState([]);
  const [prescriptionEmail, setprescriptionEmail] = useState([]);
  const [socialMedia, setsocialMedia] = useState([])
  const tabData = [
    { title: "Email" },
    ...(prescriptionEmail.length > 0 ? [{ title: "Prescription emails" }] : []),
    { title: "Social media" },
  ];

  console.log(prescriptionEmail.length, "prescriptionEmail")



  // const initialEmailData = [
  //   {
  //     id: "01",
  //     SettingName: "Send insurance request email",
  //     select: email[0]?.value ? email[0]?.value : false,
  //   },
  //   {
  //     id: "02",
  //     SettingName: "Client deposit received",
  //     select: email[1]?.value ? email[1]?.value : false,
  //   },
  //   {
  //     id: "03",
  //     SettingName: "Client consent form completed",
  //     select: email[2]?.value ? email[2]?.value : false,
  //   },
  //   {
  //     id: "04",
  //     SettingName: "Calendar event reminder",
  //     select: email[3]?.value ? email[3]?.value : false,
  //   },
  //   {
  //     id: "05",
  //     SettingName: "New client booking",
  //     select: email[4]?.value ? email[4]?.value : false,
  //   },
  //   {
  //     id: "06",
  //     SettingName: "Finance requests from clients",
  //     select: email[5]?.value ? email[5]?.value : false,
  //   },
  // ];
  const settingNames = [
    "Send insurance request email",
    "Client deposit received",
    "Client consent form completed",
    "Calendar event reminder",
    "New client booking",
    "Finance requests from clients",
  ];

  const initialEmailData = settingNames.map((name, index) => ({
    id: String(index + 1).padStart(2, '0'), // Ensuring the id is two digits with leading zero
    SettingName: name,
    select: email[index]?.value || false, // Using || for default value
  }));
  useEffect(() => {
    setEmailData(initialEmailData)
  }, [email])

  const [emailData, setEmailData] = useState(initialEmailData);
  const handleToggle = (index) => {

    const updatedEmailData = emailData.map((item, idx) =>
      idx === index ? { ...item, select: !item.select } : item
    );

    setEmailData(updatedEmailData);
  };

  console.log(emailData, "emailData")

  const Email = () => {
    return (
      <div className="TabContentWrapper">
        <p>Manage your email settings</p>
        <div className="settingGrid">
          {emailData?.map((item, index) => (
            <div className="settingItem" key={item.id}>
              <p>{item.SettingName}</p>
              <SwitchButton
                isChecked={item.select}
                onChange={() => handleToggle(index)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  // const initialPrescriptionEmailData = [
  //   {
  //     id: "01",
  //     SettingName: "Practitioner connection request",
  //     select: prescriptionEmail[0]?.value ? prescriptionEmail[0]?.value : false,
  //   },
  //   {
  //     id: "02",
  //     SettingName: "Prescription needed",
  //     select: prescriptionEmail[1]?.value ? prescriptionEmail[0]?.value : false,
  //   },
  //   {
  //     id: "03",
  //     SettingName: "Prescription accepted by pharmacy",
  //     select: prescriptionEmail[2]?.value ? prescriptionEmail[2]?.value : false,
  //   },

  //   {
  //     id: "04",
  //     SettingName: "Prescription rejected by pharmacy",
  //     select: prescriptionEmail[3]?.value ? prescriptionEmail[3]?.value : false,
  //   },
  //   {
  //     id: "05",
  //     SettingName: "Prescription fees paid",
  //     select: prescriptionEmail[4]?.value ? prescriptionEmail[4]?.value : false,
  //   },
  //   {
  //     id: "06",
  //     SettingName: "New practitioner connected",
  //     select: prescriptionEmail[5]?.value ? prescriptionEmail[5]?.value : false,
  //   },
  //   {
  //     id: "07",
  //     SettingName: "New practitioner review",
  //     select: prescriptionEmail[6]?.value ? prescriptionEmail[6]?.value : false,
  //   },
  //   {
  //     id: "08",
  //     SettingName: "Help emails",
  //     select: prescriptionEmail[7]?.value ? prescriptionEmail[7]?.value : false,
  //   }
  // ];
  const prescriptionSettingNames = [
    "Practitioner connection request",
    "Prescription needed",
    "Prescription accepted by pharmacy",
    "Prescription rejected by pharmacy",
    "Prescription fees paid",
    "New practitioner connected",
    "New practitioner review",
    "Help emails"
  ];

  const initialPrescriptionEmailData = prescriptionSettingNames.map((name, index) => ({
    id: String(index + 1).padStart(2, '0'), // Ensure id is two digits
    SettingName: name,
    select: prescriptionEmail[index]?.value || false, // Default to false if value is undefined
  }));

  useEffect(() => {
    setPrescriptionEmailData(initialPrescriptionEmailData)
  }, [prescriptionEmail])

  const [prescriptionemailData, setPrescriptionEmailData] = useState(initialPrescriptionEmailData);

  const PrescriptionhandleToggle = (index) => {
    const updatedEmailData = prescriptionemailData.map((item, idx) =>
      idx === index ? { ...item, select: !item.select } : item
    );
    setPrescriptionEmailData(updatedEmailData);
  };

  const PrescriptionEmails = () => {
    return (
      <div className="TabContentWrapper">
        <p>Manage your prescription related emails</p>
        <div className="settingGrid">
          {prescriptionemailData.map((item, index) => (
            <div className="settingItem" key={item.id}>
              <p>{item.SettingName}</p>
              <SwitchButton
                isChecked={item.select}
                onChange={() => PrescriptionhandleToggle(index)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };


  // const initialSocialMediaData = [
  //   {
  //     id: "01",
  //     SettingName: "SMS",
  //     select: socialMedia[0]?.value ? socialMedia[0]?.value : false,
  //   },
  //   {
  //     id: "02",
  //     SettingName: "Whats-App",
  //     select: socialMedia[1]?.value ? socialMedia[1]?.value : false,
  //   },
  //   {
  //     id: "03",
  //     SettingName: "Email",
  //     select: socialMedia[2]?.value ? socialMedia[2]?.value : false,
  //   },
  //   {
  //     id: "03",
  //     SettingName: "Phone",
  //     select: socialMedia[3]?.value ? socialMedia[3]?.value : false,
  //   }
  // ];
  const socialMediaSettingNames = [
    "SMS",
    "Whats-App",
    "Email",
    "Phone"
  ];

  const initialSocialMediaData = socialMediaSettingNames.map((name, index) => ({
    id: String(index + 1).padStart(2, '0'), // Ensure id is two digits
    SettingName: name,
    select: socialMedia[index]?.value || false, // Default to false if value is undefined
  }));

  useEffect(() => {
    setSocialMediaData(initialSocialMediaData)
  }, [socialMedia])
  const [socialMediaData, setSocialMediaData] = useState(initialSocialMediaData);

  const SocialMediaDatahandleToggle = (index) => {
    const updatedEmailData = socialMediaData.map((item, idx) =>
      idx === index ? { ...item, select: !item.select } : item
    );
    setSocialMediaData(updatedEmailData);
  };

  const SocialMedia = () => {
    return (
      <div className="TabContentWrapper">
        <p>Faces Communication</p>
        <div className="settingGrid">
          {socialMediaData.map((item, index) => (
            <div className="settingItem" key={item.id}>
              <p>{item.SettingName}</p>
              <SwitchButton
                isChecked={item.select}
                onChange={() => SocialMediaDatahandleToggle(index)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case "Email":
        return <Email />;

      case "Prescription emails":
        if (prescriptionEmail.length > 0) {
          return <PrescriptionEmails />;
        }
        break; // Optional, but clarifies that the case ends here

      case "Social media":
        return <SocialMedia />;

      default:
        return <div>Select a tab to see the content</div>;
    }
  };

  console.log(socialMedia[0]?.value, "socialMedia[0]?.value")
  // const CommunicationSetting = [
  //   {
  //     id: "01",
  //     SettingName: "SMS",
  //     select:socialMedia[0]?.value ?socialMedia[0]?.value : false,
  //   },
  //   {
  //     id: "02",
  //     SettingName: "Whats-App",
  //     select: socialMedia[1]?.value ? socialMedia[1]?.value : false,
  //   },
  //   {
  //     id: "03",
  //     SettingName: "Email",
  //     select: socialMedia[2]?.value ? socialMedia[2]?.value : false,
  //   },
  //   {
  //     id: "04",
  //     SettingName: "Phone",
  //     select: socialMedia[3]?.value ? socialMedia[3]?.value : false,
  //   },
  // ];

  return (
    <div className="Main-Notification Main684WidthSet">
      <BusinessNavigation
        isColumn={true}
        title={"Customise notifications"}
        desc={"Tell us how you prefer to receive app notifications."}
        onBackClick={() => { setStep(0) }}
        isTabShow={true}
        tabData={tabData}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        renderTabContent={renderTabContent}
      />

      {/* <div className="NotificationGrid">
        <div className="NotificationLeftGrid">
          <p>Manage your email settings</p>
          <ul>
            {emailSetting?.map((item, index) => {
              return (
                <li key={index} className={item?.select && "Select"}>
                  {item?.SettingName}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="NotificationRightGrid">
          <p>Faces Communication</p>
          <ul>
            {CommunicationSetting?.map((item, index) => {
              return (
                <li key={index} className={item?.select && "Select"}>
                  {item?.SettingName}
                </li>
              );
            })}
          </ul>
        </div>
      </div> */}
      <div className="bottomBtn">
        <StepButton label={"Save"} onClick={() => { onClickSubmit(emailData, prescriptionemailData, socialMediaData) }} />
      </div>
    </div>
  );
}

export default Notification;
