import { gql, useLazyQuery } from "@apollo/client"
import { useDispatch } from "react-redux";
import { GET_HAIR_PRESCRRIPTION_AMEND_ANSWER, SET_HAIR_PRESCRRIPTION_AMEND_ANSWER_ERROR_PAGE } from "../../constants/hair-prescription-amend-answer/HairPrescriptionAmendAnswerConst";


const GET_HAIR_PRESCRIPTION_AMEND_ANSWERS_QUERY = gql`
query GetHairPrescriptionAnswers($page: Int!, $limit: Int!, $orderId: Int!) {
    getHairPrescriptionAnswers(page: $page, limit: $limit, orderId: $orderId) {
      success
      message
      data {
        answers {
          id
          question
          answer
          comment
          options
          multiple
          is_comment
          yes_no
        }
        total_count
        gender_answer {
          id
          question
          answer
          comment
          options
          multiple
          is_comment
        }
        product_data {
          id
          name
          image
          description
          price
          interval
        }
        hair_prescription_request_approval_status
      }
    }
  }
`;

export const GetHairPrescriptionAmendAnswers = () => {
    const dispatch = useDispatch();
    const [getHairPrescriptionAmendAnswers, hairPrescriptionAmendAnswersData] = useLazyQuery(
        GET_HAIR_PRESCRIPTION_AMEND_ANSWERS_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getHairPrescriptionAnswers: responseData }) => {
                console.log('called1111', responseData?.success)
                if (responseData.success) {
                    dispatch({
                        type: GET_HAIR_PRESCRRIPTION_AMEND_ANSWER,
                        // payload: responseData.data
                        payload: {
                            allAmendAnswers: responseData?.data?.answers,
                            genderAnswer: responseData?.data?.gender_answer,
                            productData: responseData?.data?.product_data,
                            amendStatus: responseData?.data?.hair_prescription_request_approval_status,
                            totalCount: responseData?.data?.total_count
                        }
                    })
                } else {
                    dispatch({
                        type: SET_HAIR_PRESCRRIPTION_AMEND_ANSWER_ERROR_PAGE,
                        payload: true
                    });
                }
            }
        }
    )

    const hairPrescriptionAmendAnswersQueryData = hairPrescriptionAmendAnswersData;

    const initGetPrescriptionAmendAnswers = (page, limit, orderId) => {
        getHairPrescriptionAmendAnswers({
            variables: { page, limit, orderId },
        })
    }

    return { initGetPrescriptionAmendAnswers, hairPrescriptionAmendAnswersQueryData };
}