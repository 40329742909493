import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_BUSINESS_CLINIC_LIST } from "../../constants/business-location-const/BusinessLocationConst";
import { GET_TEAM_MEMBER } from "../../constants/team-member-list-const/TeamMemberListConst";

const GET_TEAM_MEMBER_QUERY = gql`
query GetAvailableTeamMembers($treatmentId: [Int]!) {
    getAvailableTeamMembers(treatment_id: $treatmentId) {
      success
      message
      data {
        team_members {
          id
          name
          image
          initials
          occupations

        }
      }
    }
  }
`;

// var page;
export const TeamMemberList = () => {
  const dispatch = useDispatch();
  const [getTeamMemberList, teamMemberListData] = useLazyQuery(
    GET_TEAM_MEMBER_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getAvailableTeamMembers: responseData }) => {

        dispatch({
          type: GET_TEAM_MEMBER,
          payload: responseData.data,
          // page
        });
        if (responseData.success) {

        } else {

        }


      },
    }
  );
  const teamMemberListQueryData = teamMemberListData;
  const initTeamMemberList = (data) => {
    // page = data.page
    getTeamMemberList({
      variables: data,
    });
  };
  return { initTeamMemberList, teamMemberListQueryData };
};



