import React from 'react'
import './custom-checkbox.scss'

const CustomCheckbox = ({
    checked,
    name,
    id,
    className,
    onchange,
    isCircle = false,

}) => {
    return (
        <div className='custom-checkbox-main-div'>
            <label class={`${isCircle ? 'input-checkbox-circle' : 'input-checkbox'}`} name={name}>
                {/* One */}
                <input type="checkbox"
                    className={className}
                    id={id}
                    name={name}
                    checked={checked}
                />
            </label>
        </div>
    )
}

export default CustomCheckbox