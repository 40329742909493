import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const SAVE_SOCIAL_LINKS_MUTATION = gql`
mutation SaveSocialLinks($pwcWhatsapp: String, $pwcInstagram: String, $pwcFacebook: String) {
  saveSocialLinks(pwc_whatsapp: $pwcWhatsapp, pwc_instagram: $pwcInstagram, pwc_facebook: $pwcFacebook) {
    success
    message
  }
}
`;

export const SaveSocialLinks = () => {
  const [saveSocialLinksRequest, saveSocialLinksResponseData] =
    useMutation(SAVE_SOCIAL_LINKS_MUTATION);
  const dispatch = useDispatch();
  const saveSocialLinksMutationData = saveSocialLinksResponseData;

  const initiateSaveSocialLinks = (
    data,
    successCallback,
    errorCallback
  ) => {
    saveSocialLinksRequest({
      variables: data,
    })
      .then(({ data: { saveSocialLinks: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateSaveSocialLinks, saveSocialLinksMutationData };
};
