import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_PAYMENT_METHOD_AVAILABLE } from "../../constants/forward-consent-form/ForwardConsentFormConst";

const GET_PAYMENT_METHOD_AVAILABLE_QUERY = gql`
query GetPaymentMethodAvailable($cfrId: Int, $token: String) {
    getPaymentMethodAvailable(cfrId: $cfrId, token: $token) {
      success
      message
      data {
        payment_data {
          pay_in_full
          pay_deposit
          pay_on_the_day
          pay_monthly
        }
      }
    }
  }
  `;

export const AvailalbePaymentMethodQuery = () => {
  const dispatch = useDispatch();

  const [getPaymentMethodAvailable,paymentMethodAvailableData] = useLazyQuery(
    GET_PAYMENT_METHOD_AVAILABLE_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getPaymentMethodAvailable: responseData }) => {
        dispatch({
          type: GET_PAYMENT_METHOD_AVAILABLE,
          payload: responseData?.data,
        });
        if (responseData.success) {

        } else {

        }


      },
    }
  );
  const paymentMethodAvailableQueryData = paymentMethodAvailableData;
  const initPaymentMethodAvailable = (data,successCallback,errorCallback) => {
    getPaymentMethodAvailable({
        variables: data,
    }).then(res=>{
        successCallback(res?.data?.getPaymentMethodAvailable)
    }).catch(err=>{
        errorCallback(err)
    });
  };
  return { initPaymentMethodAvailable, paymentMethodAvailableQueryData };
};



