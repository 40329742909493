import { gql, useMutation } from "@apollo/client";

const DELETE_USER_INFO_MUTATION = gql`
mutation DeactiveUserAccount {
  deactiveUserAccount {
    success
    message
    
  }
}
`;

export const InputInfoDeleteUserAction = () => {
  const [deleteUserFunction, deleteUserFunctionResponseData] = useMutation(DELETE_USER_INFO_MUTATION);

  const initDeleteInfoResponse = (data, successCallback, errorCallback) => {

    deleteUserFunction({ variables: data })
      .then(response => {
        console.log(response,"20....")
        const { success, message } = response.data.deactiveUserAccount;
        if (success) {
          successCallback(response);
        } else {
          errorCallback(response);
        }
      })
      .catch(error => {
        console.error(error);
        errorCallback(error);
      });
  };

  return { deleteUserFunctionResponseData, initDeleteInfoResponse };
};
