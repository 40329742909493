import { useState } from "react";
import CardDetails from "./welcomeScreen/card-details";
import WelcomeScreenThankYou from "./welcomeScreen/welcomeScreenThankYou";

const PurchaseWebsitePackage = () => {
    return (
        <CardDetails />
    )
}

export default PurchaseWebsitePackage