import React, { useState, useEffect, useRef } from "react";
import OwlCarousel from 'react-owl-carousel';
import CheckMarkedPng from "../../../images/social-media-marketing/check-mark.png";
import CheckMarkedOrange from "../../../images/social-media-marketing/check-mark-orange.png";
import PurchaseBtnImg from "../../../images/social-media-marketing/purchase-arrow-btn.png";
import { GetPlumpMarketingPackagesAction } from '../../redux/actions/social-media-marketing';
import { GetAccountancyPackagesAction } from '../../redux/actions/accountancy-service/packages';
import { useDispatch } from 'react-redux';
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { domainName } from '../../../styles';
import { webBaseUrl, facesWebBaseUrl } from "../../../config";
import loadingIcon from "../../../images/loading.gif";

export const PlansCarousel = ({ prntFn, inquireNowLink }) => {
    const dimensions = useWindowDimensions();
    console.log(dimensions.width, "dimensions");
    const dispatch = useDispatch()
    const {
        getAccountancyPackagesQueryData, initGetAccountancyPackagesData, } = GetAccountancyPackagesAction();
    const [plans, setPlans] = useState([])
    console.log(plans, "plans");

    const [cardTopBorder, setCardTopBorder] = useState(0);
    const [cardupper, setCardupper] = useState(0);
    const [commitmentHeight, setCommitmentHeight] = useState(0);
    console.log(commitmentHeight, "commitmentHeight");
    const [tailoredFooterHeight, setTailoredFooterHeight] = useState(0);
    const [ulHeight, setUlHeight] = useState([]);
    const [commitmentNewHeight, setCommitmentNewHeight] = useState([]);
    const commentRef = useRef(null);

    useEffect(() => {

    }, [inquireNowLink])

    const setUlHeightFunction = () => {
        let ulHeighttmp = [...ulHeight];

        for (let i = 1; i <= 5; i++) {
            let el = document.getElementById(`plan_${i}`);
            if (el) {
                let ind = ulHeighttmp.findIndex((e) => e.id == `plan_${i}`);
                if (ind == -1)
                    ulHeighttmp.push({ id: `plan_${i}`, height: el.offsetHeight });
            }
        }
        setUlHeight(ulHeighttmp);
    };

    const setCommentHeightFunction = () => {
        let commitmentNewtemp = [...commitmentNewHeight];

        for (let i = 1; i <= 5; i++) {
            let el = document.getElementById(`comment_${i}`);
            console.log(el, "comment");
            if (el) {
                let ind = commitmentNewtemp.findIndex((e) => e.id == `comment_${i}`);
                if (ind == -1)
                    commitmentNewtemp.push({
                        id: `comment_${i}`,
                        height: el.offsetHeight,
                    });
            }
        }

        setCommitmentNewHeight(commitmentNewtemp);
    };

    useEffect(() => {
        setCardTopBorder(
            document.getElementsByClassName("social-tailored-card-head")[0]
                ?.offsetHeight
        );
        setCardupper(
            document.getElementsByClassName("card-upper-section")[0]?.offsetHeight
        );

        setTailoredFooterHeight(
            document.getElementsByClassName("tailored-card-footer")[0]?.offsetHeight
        );

        setUlHeightFunction();
        setCommentHeightFunction();

        window.addEventListener("resize", handleResize);
    }, [cardTopBorder, cardupper, commitmentHeight, tailoredFooterHeight, plans]);

    const handleResize = () => {
        setCardTopBorder(
            document.getElementsByClassName("social-tailored-card-head")[0]
                ?.offsetHeight
        );
        setCardupper(
            document.getElementsByClassName("card-upper-section")[0]?.offsetHeight
        );
        setCommitmentHeight(
            document.getElementsByClassName("commitment")[0]?.offsetHeight
        );
        setTailoredFooterHeight(
            document.getElementsByClassName("tailored-card-footer")[0]?.offsetHeight
        );
        setUlHeightFunction();
        setCommentHeightFunction();
    };

    useEffect(() => {
        initGetAccountancyPackagesData({
            baseUrl: webBaseUrl + facesWebBaseUrl,
        });
    }, [])
    useEffect(() => {
        if (getAccountancyPackagesQueryData?.data)
            setPlans(getAccountancyPackagesQueryData.data.getAccountancyPackages.data.packages)
    }, [getAccountancyPackagesQueryData?.data])
    console.log(
        getAccountancyPackagesQueryData,
        "getAccountancyPackagesQueryData data"
    );


    const responsiveObj = {
        0: {
            items: 1.2
        },
        577: {
            items: 2.2
        },
        700: {
            items: 2.2
        },
        950: {
            items: 3.2
        },
        1100: {
            items: 3.2,
        }
    };

    const isLinkDisabled = false;

    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [loaderIndex, setLoaderIndex] = useState(null);

    return (
        <>
            {
                plans?.length > 0 &&
                <OwlCarousel items={plans}
                    className='owl-theme'
                    loop={true}
                    margin={20}
                    nav={true}
                    dots={false}
                    responsiveClass={true}
                    responsive={responsiveObj}>
                    {plans.map((plan, index) => (
                        <div key={plan.id}>
                            {plan.is_currentplan && (
                                <div className="currentPlan">
                                    <h6 className="text-white text-center">
                                        Your Current Plan
                                    </h6>
                                </div>
                            )}
                            <div className="tailored-card accountacyCarousel">
                                <div className="social-tailored-card-head">
                                    <h6 className="text-white py-2 text-center">
                                        {plan.is_popular && 'Most Popular'}
                                    </h6>
                                </div>
                                <div className="tailored-card-body">
                                    <div className='card-upper-section px-3'>
                                        <div className="text-center">
                                            <h6 className="m-0 p-0">{plan.package_name}</h6>
                                            <hr className="hrline mb-1 mt-2" />
                                            <h3 className="vatprice mb-0">{plan.amountText}</h3>
                                            <p className="mb-4 vettext">
                                                {(plan.duration_text !== '') &&
                                                    '(' + plan.duration_text + ')'}
                                            </p>
                                            {/* {plan.duration_text2 !== '' &&
                                                (<p style={{ color: '#3D3D3D', fontSize: '10px', fontWeight: 'regular' }}> ({plan.duration_text2})</p>)} */}
                                            <hr className="hrline mt-0" />
                                        </div>
                                    </div>
                                    <div className="card-lower-section">
                                        {/* <div className="commitment mt-2 px-3">
                                            <h6>Commitment</h6>
                                            <p className="mb-2 paragraph">{plan.commitment_text}</p>
                                            <b className="minmobths">{plan.commitment_text2}</b>
                                        </div> */}
                                        <div className="listview"
                                            style={{
                                                height:
                                                    ulHeight.find((e) => e.id == `plan_${plan.id}`) &&
                                                        ulHeight.find((e) => e.id == `plan_${plan.id}`)
                                                            .height >= 347
                                                        ? 301 : 314 - tailoredFooterHeight 
                                            }}
                                        >
                                            {/* <div className='heading-points px-3 pt-0'>
                                                {plan.package_points.map(point => (
                                                    <strong>{point}</strong>
                                                ))}
                                            </div> */}
                                            <ul className="m-0 p-0 listitems ps-3">
                                                {plan.image_url && <li style={{ padding: '0px 5px', marginBottom: '10px' }}><img src={plan.image_url} className='' alt="" style={{ borderRadius: '5px' }} /></li>}

                                                {plan.package_points.map(packagePoint => (
                                                    <li className="d-flex">
                                                        <img src={domainName == "faces" ? CheckMarkedPng : CheckMarkedOrange} className=" img-fluid col-3" style={{ height: "15px", width: "15px" }} />
                                                        <p className="col-9 categoty mb-1">{packagePoint}</p>
                                                    </li>
                                                ))}

                                                {/* <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid col-3" style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li>
                                            <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid " style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li>
                                            <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid " style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li>
                                            <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid " style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li>
                                            <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid " style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li>
                                            <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid " style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li>
                                            <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid " style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li> */}
                                            </ul>
                                        </div>
                                        {/* className={plan.is_upgradable ? "tailored-card-footer p-3" : plans.some(plan => plan.is_currentplan) ? "tailored-card-footer p-3 disabledButton" : "tailored-card-footer p-3"} */}
                                        <div className="tailored-card-footer p-3">
                                            <a
                                                href={inquireNowLink}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginBottom: '55px',
                                                    textDecoration: 'underline',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Enquire Now
                                            </a>
                                        </div>
                                        <div className={isLinkDisabled ? 'tailored-card-footer p-3 disabledButton' : plan.is_upgradable ? "tailored-card-footer p-3" : plans.some(plan => plan.is_currentplan) ? "tailored-card-footer p-3 disabledButton" : "tailored-card-footer p-3"}>
                                            {/* <div className="tailored-card-footer p-3"> */}
                                            <a
                                                // href={plan.url}
                                                // target='_blank'
                                                href="javascript:void(0)"
                                                // href={isLinkDisabled ? null : plan.url} target={isLinkDisabled ? '' : '_blank'} 
                                                // onClick={isLinkDisabled ? null : () => clickOnPackage(plan.id)}
                                                // className="button d-flex justify-content-between align-items-center rounded-pill ps-3 step-button-loading"
                                                className={isBtnLoading && loaderIndex === index ? 'button d-flex justify-content-center align-items-center rounded-pill ps-3 step-button-loading' : 'button d-flex justify-content-between align-items-center rounded-pill ps-3 step-button-loading'}
                                                onClick={() => {
                                                    setIsBtnLoading(true);
                                                    setLoaderIndex(index);
                                                    prntFn(plan?.slug,
                                                        () => {
                                                            console.log('packageSlug 1');
                                                            window.location.href = plan.url + '&isUpgradable=' + plan.is_upgradable.toString();
                                                        },
                                                        () => window.location.href = plan.url + '&isUpgradable=' + plan.is_upgradable.toString());
                                                }}
                                            // style={
                                            //     cursor: isLinkDisabled ? 'not-allowed' : 'pointer',
                                            //     backgroundColor: isLinkDisabled ? 'grey' : '#1658F7',
                                            //   }}
                                            >
                                                {
                                                    isBtnLoading && loaderIndex === index ? (
                                                        <>
                                                            <h6 className="m-0 p-0 text-white"> 
                                                                <div className="d-flex justify-content-center p-0">
                                                                    <img src={loadingIcon} className={"loadingIcon"} />
                                                                </div>
                                                            </h6>
                                                            <button className="btn">
                                                                <div
                                                                    className=""
                                                                    style={{
                                                                        height: "27px",
                                                                        width: "27px",
                                                                        display: 'flex',
                                                                        'align-items': 'center',
                                                                        'justify-content': 'center'
                                                                    }}
                                                                >
                                                                    {/* <img
                                                                        src={PurchaseBtnImg}
                                                                        className="m-2"
                                                                        style={{
                                                                            width: '3.84px',
                                                                            height: '7.92px',
                                                                            top: '2.04px',
                                                                            left: '4.83px',
                                                                            border: '1.5px',
                                                                            angle: '-0 deg'
                                                                        }}
                                                                    /> */}
                                                                </div>
                                                            </button>
                                                        </>
                                                        // <div className="d-flex justify-content-center p-0">
                                                        //     <img src={loadingIcon} className={"loadingIcon"} />
                                                        // </div>
                                                    ) : (
                                                        <>
                                                            <h6 className="m-0 p-0 text-white"> {plans.length - 1 === index ? 'Enroll Now' : 'Buy Now'}</h6>
                                                            <button className="btn">
                                                                <div
                                                                    className="btn-icon"
                                                                    style={{
                                                                        height: "27px",
                                                                        width: "27px",
                                                                        display: 'flex',
                                                                        'align-items': 'center',
                                                                        'justify-content': 'center'
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={PurchaseBtnImg}
                                                                        className="m-2"
                                                                        style={{
                                                                            width: '3.84px',
                                                                            height: '7.92px',
                                                                            top: '2.04px',
                                                                            left: '4.83px',
                                                                            border: '1.5px',
                                                                            angle: '-0 deg'
                                                                        }}
                                                                    />
                                                                </div>
                                                            </button>
                                                        </>
                                                    )

                                                }
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            }
        </>
    );
};