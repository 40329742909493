import { CLEAR_ONBOARDING_DATA, DASHBOARD_ONBOARDING_DATA } from "../../constants"

const initialState = {
    dashboardOnboardingData: null
}
export const DashboardOnboardingReducer = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_ONBOARDING_DATA:
            return {
                ...state,
                dashboardOnboardingData: action.payload
            }
    
        case CLEAR_ONBOARDING_DATA:
            return {
                ...state,
                dashboardOnboardingData: null
            }

        default:
            return state
    }
}  