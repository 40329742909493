import { gql, useMutation } from "@apollo/client";


const LOGOUT_USER_ACTIVE_SESSIONS_MUTATION = gql`
  mutation LogoutUserActiveSessions($isAllLogout: Boolean) {
    logoutUserActiveSessions(isAllLogout: $isAllLogout) {
      success
      message
    }
  }
`;

export const useLogoutUserActiveSessions = () => {
    const [logoutUserActiveSessions, logoutUserActiveSessionsData] =
        useMutation(LOGOUT_USER_ACTIVE_SESSIONS_MUTATION);


    const handleLogoutUserActiveSessions = (data, successCallback, errorCallback) => {
        logoutUserActiveSessions({
            variables: data,
        })
            .then(({ data: { logoutUserActiveSessions: responseData } }) => {
                if (responseData?.success) {
                    successCallback(responseData);
                } else {
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                errorCallback(error);
            });
    };

    return { handleLogoutUserActiveSessions, logoutUserActiveSessionsData };
};
