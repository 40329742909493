import React, { useEffect, useState } from 'react'
import RadialSeparators from '../../radialSeparators'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import CustomButton from '../../custom-components/custom-button'
import { useNavigate } from 'react-router'
import { createWebsiteRoute, getStartedRoutePattern, onBoardingPagesRoutePattern } from '../../../Routes'
import { useDispatch } from 'react-redux'
import { connectStripeExpress } from '../../redux/actions/dashboard/onboarding'
import { toast } from 'react-toastify'
import closeIcon from "../../../images/closeBanner.svg"
import { StripeOnboardingMutationAction } from '../../redux/actions/dashboard/stripeOnboardingGql'
import { CONNECT_STRIPE_EXPRESS } from '../../redux/constants'

const OccupationContainer = ({ dashboardListData, showBanner }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [loadingStripeExpressLink, setLoadingStripeExpressLink] = useState(false);
    const [bannerClosed, setBannerClosed] = useState(false);

    const { stripeOnboardingQueryData, initStripeOnboardingData } = StripeOnboardingMutationAction();

    return (
        <div>
            {
                showBanner ?

                    <div className="booking-notification-container col-md-12 mb-5" style={{ cursor: 'pointer' }} onClick={() => {
                        if (dashboardListData?.total_onboarding_steps == 3) {
                            setLoadingStripeExpressLink(true);
                            // dispatch(connectStripeExpress({}, (response) => {
                            //     setLoadingStripeExpressLink(false)
                            //     if (response?.success) {
                            //         window.location.href = response?.data?.link;
                            //     }
                            //     else {
                            //         toast.error("Something went wrong while creating your payment link, please try again after some time!");
                            //     }
                            // }, (error) => {
                            //     setLoadingStripeExpressLink(false)
                            //     toast.error("Something went wrong while creating your payment link, please try again after some time!");
                            // }))
                            initStripeOnboardingData({},
                                (response) => {
                                    setLoadingStripeExpressLink(false)
                                    window.location.href = response?.data?.stripeOnboarding?.data?.account_link;
                                },
                                (error) => {
                                    setLoadingStripeExpressLink(false)
                                    toast.error("Something went wrong while creating your payment link, please try again after some time!");
                                });
                        }
                        else {
                            navigate(getStartedRoutePattern)
                            // navigate(onBoardingPagesRoutePattern)
                        }
                    }}>
                        <div className="row">
                            <div className="col-md-10 leftnotification">
                                {
                                    dashboardListData?.total_onboarding_steps == 3 ? <h5>Ready to get paid?</h5> : <h5>👋 Online booking made easy!</h5>
                                }
                                {
                                    dashboardListData?.total_onboarding_steps == 3 ? <p>Plug in your bank details, and watch Faces streamline your deposits.</p> : <p>Attract thousands of new clients while seamlessly handling bookings and consents.</p>
                                }

                                <CustomButton label={loadingStripeExpressLink ? "Loading ..." : dashboardListData?.total_onboarding_steps == 3 ? "Link bank account" : "Get Started"} onClick={() => {
                                    if (dashboardListData?.total_onboarding_steps == 3) {
                                        setLoadingStripeExpressLink(true);
                                        // dispatch(connectStripeExpress({}, (response) => {
                                        //     setLoadingStripeExpressLink(false)
                                        //     if (response?.success) {
                                        //         window.location.href = response?.data?.link;
                                        //     }
                                        //     else {
                                        //         toast.error("Something went wrong while creating your payment link, please try again after some time!");
                                        //     }
                                        // }, (error) => {
                                        //     setLoadingStripeExpressLink(false)
                                        //     toast.error("Something went wrong while creating your payment link, please try again after some time!");
                                        // }))
                                        initStripeOnboardingData({},
                                            (response) => {
                                                setLoadingStripeExpressLink(false)
                                                window.location.href = response?.data?.stripeOnboarding?.data?.account_link;
                                            },
                                            (error) => {
                                                setLoadingStripeExpressLink(false)
                                                toast.error("Something went wrong while creating your payment link, please try again after some time!");
                                            });
                                    }
                                    else {
                                        navigate(getStartedRoutePattern)
                                    }
                                }} className="whitebutton" />
                            </div>
                            {
                                <div className="col-md-2" style={{ width: 100, height: 100, padding: 15 }}>
                                    <CircularProgressbarWithChildren
                                        value={(dashboardListData?.total_onboarding_steps / 4) * 100}
                                        text={`${dashboardListData?.total_onboarding_steps}/4`}
                                        styles={buildStyles({
                                            strokeLinecap: 'butt',
                                            pathTransitionDuration: 0.5,

                                            // Colors
                                            pathColor: '#ffffff',
                                            textColor: '#ffffff',
                                            trailColor: '#2d2e30',
                                            backgroundColor: '#3e98c7',
                                        })}
                                    >
                                        <RadialSeparators
                                            count={4}
                                            style={{
                                                background: "#000000",
                                                width: "3px",
                                                rotation: 0.92,
                                                // This needs to be equal to props.strokeWidth
                                                height: `${10}%`
                                            }}
                                        />
                                    </CircularProgressbarWithChildren>
                                </div>
                            }
                        </div>
                    </div>
                    :
                    <>
                        {
                            (dashboardListData?.personal_website_show_banner && (!(bannerClosed || localStorage.getItem("bannerClosed") === "true"))) &&
                            <div className="booking-notification-container col-md-12 mb-5" onClick={() => { navigate(createWebsiteRoute()) }} style={{ cursor: 'pointer' }}>
                                <div className="row">
                                    <div className="col-md-12 leftnotification">
                                        <div className='d-flex justify-content-between'>
                                            <h5>Get a FREE Website</h5>
                                            <div className='cursor-pointer'>
                                                <img src={closeIcon} alt="" onClick={() => { setBannerClosed(true); localStorage.setItem("bannerClosed", "true") }} />
                                            </div>
                                        </div>

                                        <p>Need a website? Get yours free with faces in under 15 minutes </p>


                                        <CustomButton label={"Start Now"} onClick={() => {

                                            navigate(createWebsiteRoute())

                                        }} className="whitebutton" />
                                    </div>
                                </div>
                            </div>
                        }
                    </>
            }
        </div>
    )
}

export default OccupationContainer