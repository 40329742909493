import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { BOOKING_FEEDBACK_MUTATION, CANCEL_BOOKING_MUTATION, GET_RYFT_PAYMENT_TOKEN } from "../../constants/treatmentBooking/treatmentBooking";
import { error } from "jquery";

//1
const CREATE_TREATMENT_BOOKING_MUTATION = gql`
mutation CreateSprintTreatBooking($clinicId: Int!, $date: String!, $time: String!, $clientFname: String!, $clientLname: String!, $clientEmail: String!, $treatmentIds: [Int], $practitionerId: Int, $createdFrom: String, $payFull: Boolean, $couponCode: String) {
  createSprintTreatBooking(clinic_id: $clinicId, date: $date, time: $time, client_fname: $clientFname, client_lname: $clientLname, client_email: $clientEmail, treatmentIds: $treatmentIds, practitioner_id: $practitionerId, created_from: $createdFrom, pay_full: $payFull, coupon_code: $couponCode) {
    success
    message
    data {
      booking_id
      treatmentDetails {
        clinic_name
        booking_date
        booking_time
        treatments
        readable_treatment
      }
      saved_cards {
        card_number
        card_exp_month
        card_exp_year
        saved_card_id
      }
    }
  }
}
`;

export const CreateTreatmentBooking = () => {
  const [createTreatmentBooking, createTreatmentBookingResponseData] =
    useMutation(CREATE_TREATMENT_BOOKING_MUTATION);
  const dispatch = useDispatch();
  const createTreatmentBookingMutationData = createTreatmentBookingResponseData;

  const initiateCreateTreatmentBooking = (
    data,
    successCallback,
    errorCallback
  ) => {
    createTreatmentBooking({
      variables: data,
    })
      .then(({ data: { createSprintTreatBooking: responseData } }) => {
        if (responseData.success) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateCreateTreatmentBooking, createTreatmentBookingMutationData };
};

//2 ryft token
const GET_RYFT_PAYMENT_TOKEN_QUERY = gql`
query GetRyftPaymentToken($bookingId: Int, $cfrId: Int, $cfrToken: String, $paymentType: Int, $redirectUrl: String) {
  getRyftPaymentToken(booking_id: $bookingId, cfrId: $cfrId, cfrToken: $cfrToken, payment_type: $paymentType, redirectURL: $redirectUrl) {
    success
    message
    data {
      payment_session
      accountId
    }
  }
}
`;
export const GetRyftPaymentToken = () => {
  const dispatch = useDispatch();
  const [GetRyftPayment, getRyftPaymentResponseData] =
    useLazyQuery(GET_RYFT_PAYMENT_TOKEN_QUERY, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getRyftPaymentToken: responseData }) => {
        dispatch({
          type: GET_RYFT_PAYMENT_TOKEN,
          payload: responseData.data
        })
        if (responseData.success) {
          // toast.success(responseData?.message)
        } else {
          // toast.error(responseData?.message)
        }
      },
    });
  //this will be called from components
  const getRyftPaymentQueryData = getRyftPaymentResponseData; //variable

  const initRyftPaymentToken = (data, successCallback, errorCallback) => {
    GetRyftPayment({
      variables: data,
    }).then((res) => {
      successCallback(res)
    }).catch((e) => {
      errorCallback(e)
    });
  };

  return { getRyftPaymentQueryData, initRyftPaymentToken };
};

const CANCEL_BOOKING = gql`
mutation CancelBooking($bookingId: Int!) {
  cancelBooking(bookingId: $bookingId) {
    success
    message
  }
}
`;
export const CancelTreatmentBooking = () => {
  const [cancelTreatmentBooking, cancelTreatmentBookingResponseData] =
    useMutation(CANCEL_BOOKING);
  const cancelTreatmentBookingMutationData = cancelTreatmentBookingResponseData;
  const dispatch = useDispatch();

  const initiateCancelTreatmentBooking = (
    data,
    successCallback

  ) => {
    cancelTreatmentBooking({
      variables: data,
    })
      .then(({ data: { cancelBooking: responseData } }) => {
        if (responseData.success) {
          dispatch({
            type: CANCEL_BOOKING_MUTATION,


          })
          if (successCallback) {
            successCallback(responseData);
          }
        }
        else {
          if (successCallback) {
            successCallback(responseData);
          }
          Swal.fire("Oops!", responseData.message, "error");
        }
      })

  };
  return { initiateCancelTreatmentBooking, cancelTreatmentBookingMutationData }
}
const BOOKING_FEEDBACK = gql`
mutation BookingFeedback($booking: Int!, $rating: Float!, $review: String) {
  bookingFeedback(booking: $booking, rating: $rating, review: $review) {
    success
    message
    data {
      booking
      rating
      review
    }
  }
}
`;
export const BookingFeedback = () => {
  const [bookingFeedback, bookingFeedbackResponseData] = useMutation(BOOKING_FEEDBACK);
  const bookingFeedbackMutationData = bookingFeedbackResponseData
  const dispatch = useDispatch();
  const initiateBookingFeedBack = (
    data,
    successCallback
  ) => {
    bookingFeedback({
      variables: data,
    })
      .then(({ data: { bookingFeedback: responseData } }) => {
        if (responseData.success) {
          dispatch({
            type: BOOKING_FEEDBACK_MUTATION,
          })

          if (successCallback) {
            successCallback(responseData);
          }

        }
        else {
          if (successCallback) {
            successCallback(responseData);
          }
          Swal.fire("Oops!", responseData.message, "error");
        }
      })
  }
  return { initiateBookingFeedBack, bookingFeedbackMutationData }
}

// ========================GENERATE BOOKING REVIEW CONTENT========================
const GENERATE_BOOKING_REVIEW_CONTENT_QUERY = gql`
query GenerateBookingReviewContent($bookingId: Int!, $rating: Float!) {
  generateBookingReviewContent(booking_id: $bookingId, rating: $rating) {
    success
    message
    data {
      reviewContent
    }
  }
}
`;

export const GenerateBookingReviewContentAction = () => {
  const dispatch = useDispatch();
  const [
    GenerateBookingReviewContentResponseFunction,
    generateBookingReviewContentResponseData,
  ] = useLazyQuery(GENERATE_BOOKING_REVIEW_CONTENT_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ generateBookingReviewContent: responseData }) => {
      if (responseData.success) {
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const generateBookingReviewContentQueryData = generateBookingReviewContentResponseData; //variable

  const initGenerateBookingReviewContentData = (data) => {
    GenerateBookingReviewContentResponseFunction({
      variables: data,
    });
  };

  return { generateBookingReviewContentQueryData, initGenerateBookingReviewContentData };
};




