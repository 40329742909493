import React from 'react'
import './how-it-works.scss'
import SMSExample from "../../../images/new-appointment/smsexample.png";
import Slider from 'react-slick';
import StepButton from '../../common/StepButton';


const HowItWorksModal = ({ setHowItWorksModal, howItWorksData }) => {

    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
    };

    const staticArrayData = [
        {
            mainHeading: "Confirmation Text",
            heading: "Kanvas Medical Group",
            content: `
            <p>Hi <b> ${howItWorksData?.clientName}</b>, Here Is Your Confirmation Appointment,<b>${howItWorksData?.date}, ${howItWorksData?.time}.</b>Can't Wait To See You! Mark It Your Calendar.</p>`,
        },
        {
            mainHeading: "7 Day Reminder Text",
            heading: "Kanvas Medical Group",
            content: `<p><b>${howItWorksData?.clientName}</b>, Reminder Of Your
            Appointment On
            <b>${howItWorksData?.date}, ${howItWorksData?.time}.</b>
            For Queries/ Reschedules,
            Contact Your Practitioner.
            <a>[Link]</a></p>`,
        },
        {
            mainHeading: "48 Hour Reminder Text",
            heading: "Kanvas Medical Group",
            content: `<p>
            <b>${howItWorksData?.clientName}</b>, Your
            Appointment Is In 2 Days At
            <b>${howItWorksData?.time} On ${howItWorksData?.date}</b>.
            Notify Practitioner If There
            Are Any Changes.
            <a>[Link]</a></p>`,
        },
        {
            mainHeading: "24 Hour Reminder Text",
            heading: "Kanvas Medical Group",
            content: `<p><b>${howItWorksData?.clientName}</b>, Are You Excited?
            Your Appointment Is Tomorrow
            At <b>${howItWorksData?.time}</b>. We Can't Wait!
            Let Us Know Of Any Issues.
            Don't Reply Here.
            <a>[Link]</a></p>`
        }]
    return (
        <>
            <div className='how-it-works-main-container text-center'>
                <Slider {...settings}>
                    {
                        staticArrayData.map((item, index) =>
                            <>
                                <h4>
                                    {item.mainHeading}
                                </h4>
                                <div className="mobile-content-patrent-div text-center">
                                    <div className="mobile-image">
                                        <img src={SMSExample} alt="" />
                                    </div>
                                    <div className="mobile-header">
                                        <h6>
                                            {item.heading}
                                        </h6>
                                    </div>
                                    <div className="mobile-content"
                                        dangerouslySetInnerHTML={{ __html: item?.content }}>

                                    </div>
                                </div>
                            </>)
                    }
                </Slider>
                <StepButton label={'Close'} blue={true} className={'w-50 m-auto'} onClick={() => setHowItWorksModal(false)} />
            </div>
        </>
    )
}

export default HowItWorksModal