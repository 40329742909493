import React from 'react';
import PlaceholderImage from '../../../images/insurance/default-insurance-logo.jpg';
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonImage = ({ skeletonHeight = 50, skeletonWidth = 50, skeletonClassName = "" }) => {
  return (
    <div className="col-12 main-logo">
        {/* <Skeleton className='logo' style={{ backgroundColor: '#ccc' }}> */}
            <img
                className={skeletonClassName}
                src={PlaceholderImage} // URL to a placeholder image
                alt="Placeholder" 
            />
        {/* </Skeleton> */}
    </div>
  );
}

export default SkeletonImage;