import React, { useEffect, useState } from "react";
import "./financeOnboardingSteps.scss";
import FinaceHeader from "../../finaceHeader";
import { OnboardingStepsForFinance } from "../../redux/actions/pay-later/onboardingStepsForFinance";
import { useNavigate } from "react-router";
import tikmark from "../../../assets/finance-page/tikmark.svg";
import chevronleft from "../../../assets/finance-page/chevron-left.png";



const FinanceOnboardingSteps = () => {
  const { initonboardingStepsForFinanceData } = OnboardingStepsForFinance();
  const [onboardingData, setOnboardingData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    initonboardingStepsForFinanceData(
      {},
      (res) => {
        console.log("Onboarding Data:", res);
        setOnboardingData(res.data.getOnboardingInfoForFinance.data);
      },
      (err) => {
        console.log("Error fetching onboarding data:", err);
      }
    );
  }, [navigate]);

  useEffect(() => {
    if (onboardingData?.basic_information_completed && onboardingData?.bank_details_added && onboardingData?.documents_uploaded && onboardingData?.card_linked) {
      navigate("/revenue-dashboard")
    }
  },[onboardingData])

  const redirectTo = (path, event) => {
    event.stopPropagation();
    console.log("Redirecting...");
    navigate(path);
  };

  return (
    <>
      <div>
        <FinaceHeader />
        <div className="YourBookingLinkWrapper mainfinancelandingpage ">
          <div className="container mt-5">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-sm-12 col-md-8">
                <div className="YourBookingHeaderWrapper">
                  <h2 className="pt-5 mb-0 pagenewPageTitle">Your booking link</h2>
                  <p className="YourBookingLinkDesc">Follow simple steps for your unique link.</p>
                </div>
                <ul className="StepProgress">
                  <li
                    className={`StepProgress-item ${onboardingData?.basic_information_completed
                      ? "is-done"
                      : ""
                      }`}
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={(e) => redirectTo("/edit-profile", e)}
                  >
                    <button
                      className="step-button"
                    >
                      {onboardingData?.basic_information_completed &&
                        <img src={tikmark} />
                      }
                    </button>
                    <div className="StepProgressArrowWrapper">
                      <div className="StepProgressWrapper">
                        <span className="time">1 min</span>
                        <div className="StepBottomContent">
                          <strong>Basic Information</strong>
                          <span className="description">
                            Input your location details first.
                          </span>
                        </div>
                      </div>
                      <div className="arrowCustome">
                        <img src={chevronleft} />
                      </div>
                    </div>
                  </li>
                  <li
                    className={`StepProgress-item ${onboardingData?.bank_details_added ? "is-done" : ""
                      }`}
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={(e) => redirectTo("/edit-profile", e)}
                  >
                    <button
                      className="step-button"
                      onClick={(e) => redirectTo("/edit-profile", e)}
                    >
                      {onboardingData?.bank_details_added &&
                        <img src={tikmark} />
                      }
                    </button>
                    <div className="StepProgressArrowWrapper">
                      <div className="StepProgressWrapper">
                        <span className="time">3 min</span>
                        <div className="StepBottomContent">
                          <strong>Bank Details</strong>
                          <span className="description">
                            Take online payments for your services.
                          </span>
                        </div>
                      </div>
                      <div className="arrowCustome">
                        <img src={chevronleft} />
                      </div>
                    </div>
                  </li>
                  <li
                    className={`StepProgress-item ${onboardingData?.documents_uploaded ? "is-done" : ""
                      }`}
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={(e) => redirectTo("/edit-profile", e)}
                  >
                    <button
                      className="step-button"
                      onClick={(e) => redirectTo("/edit-profile", e)}
                    >
                      {onboardingData?.documents_uploaded &&
                        <img src={tikmark} />
                      }
                    </button>
                    <div className="StepProgressArrowWrapper">
                      <div className="StepProgressWrapper">
                        <span className="time">2 min</span>
                        <div className="StepBottomContent">
                          <strong>Upload Documents</strong>
                          <span className="description">
                            Begin with your primary service.
                          </span>
                        </div>
                      </div>
                      <div className="arrowCustome">
                        <img src={chevronleft} />
                      </div>
                    </div>
                  </li>
                  <li
                    className={`StepProgress-item ${onboardingData?.card_linked ? "is-done" : ""
                      }`}
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={(e) => redirectTo("/edit-profile", e)}
                  >
                    <button
                      className="step-button"
                      onClick={(e) => redirectTo("/edit-profile", e)}
                    >
                      {onboardingData?.card_linked &&
                        <img src={tikmark} />
                      }
                    </button>
                    <div className="StepProgressArrowWrapper">
                      <div className="StepProgressWrapper">
                        <span className="time">4 min</span>
                        <div className="StepBottomContent">
                          <strong>Link Card</strong>
                          <span className="description">Link your cards.</span>
                        </div>
                      </div>
                      <div className="arrowCustome">
                        <img src={chevronleft} />
                      </div>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceOnboardingSteps;
