import React from "react";
import { Form, FormGroup, Label } from "reactstrap";
import "./inputComponent.scss";
import { validAlphaField, validName, validNumber } from "../../../utils/Regex";

const InputComponent = (props) => {
  const { dataAos, dataAosDuration, dataAosAnchor, textType } = props;
  return (
    <div className="input-group mb-3">
      <Form>
        <FormGroup className="inpt_field" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-anchor={dataAosAnchor}>
          <Label className="lable">{props.label}</Label>
          <input
            className="input"
            type={textType || "text"}
            name="businessName"
            id="businessName"
            placeholder={props.placeholder}
            value={props.value}
            onChange={(e) => {
              if (props.type === "name") {
                validName.test(e.target.value) && props.setTeamMember({ ...props.teamMember, pwtm_full_name: e.target.value });
              } else if (props.type === "qualification") {
                validName.test(e.target.value) && props.setTeamMember({ ...props.teamMember, pwtm_qualification: e.target.value });
              } else if (props.type === "jobRole") {
                validAlphaField.test(e.target.value) && props.setTeamMember({ ...props.teamMember, pwtm_job_role: e.target.value });
              } else if (props.type === "jobRoleDesc") {
                props.setTeamMember({ ...props.teamMember, pwtm_description: e.target.value });
              } else if (props.type === "contact") {
                e.target.value.length < 15 && props.setPersonalDetails({ ...props.personalDetails, pwcBusinessContact: e.target.value });
              } else if (props.type === "fname") {
                validName.test(e.target.value) && props.setPersonalDetails({ ...props.personalDetails, pwcFname: e.target.value });
              } else if (props.type === "lname") {
                validName.test(e.target.value) && props.setPersonalDetails({ ...props.personalDetails, pwcLname: e.target.value });
              } else if (props.type === "email") {
                props.setPersonalDetails({ ...props.personalDetails, pwcBusinessEmail: e.target.value });
              } else if (props.type === "welcomeText") {
                props.setWebsiteData({ ...props.websiteData, welcomeText: e.target.value, })
              }


            }}
          />
        </FormGroup>
      </Form>
    </div>
  );
};

export default InputComponent;
