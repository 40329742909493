import React, { useEffect, useState } from "react";
import downArrow from "../../../images/icons/cav-down-gray.svg";
import { GetWebsiteBusinessHoursAction } from "../../redux/actions/createWebsite/getWebsiteBusinessHours";
import { useSelector } from "react-redux";
// import "./businessHours.scss"; // Import your CSS file

const BusinessHours = (props) => {
  const { dataAos, dataAosDuration } = props;

  const { initGetWebsiteDataResponseFunction, GetWebsiteBusinessHoursResponseData } = GetWebsiteBusinessHoursAction();

  const businessHoursData = useSelector((state) => state.WebsiteDetailsReducer.businessHoursData);

  console.log("businessHoursData", businessHoursData, "data")

  const dayArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];


  const handleCheckboxChange = (index, checked) => {
    props.setWeekdays((prevWeekdays) => {
      const temp = [...prevWeekdays];
      temp[index].checked = checked;
      return temp;
    });
  };

  const handleTimeChange = (index, field, value) => {
    props.setWeekdays((prevWeekdays) => {
      const temp = [...prevWeekdays];
      temp[index][field] = value;
      return temp;
    });
  };

  useEffect(() => {
    if (businessHoursData) {
      console.log("businessHoursData", businessHoursData)
      props.setWeekdays(
        businessHoursData?.map((item, index) => ({
          day: dayArray[item.pwbh_day],
          checked: item.pwbh_is_open === 1 ? true : false,
          startTime: item.pwbh_start,
          endTime: item.pwbh_end,
          id: item.pwbh_id,
        }))
      );
    }
  }, [businessHoursData]);

  useEffect(() => {
    initGetWebsiteDataResponseFunction();
  }, []);

  console.log("weekdays", props.weekdays)

  return (
    <div className="BusinessMainWrapper">
      {
        props.weekdays?.map((item, index) => (
          <div className="BusinessWrapper" key={index} data-aos={dataAos} data-aos-duration={dataAosDuration}>
            <div className={item.checked ? "CheckBoxWrapper" : "UnCheckBoxWrapper"}>
              <div className="input-checkbox">
                <input
                  type="checkbox"
                  checked={item.checked}
                  onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                  className="round-checkbox"
                />
              </div>
              <span className="dayTitle">{item.day}</span>
            </div>
            <div className="selectWrapper">
              <input
                type="time"
                value={item.startTime}
                onChange={(e) =>
                  handleTimeChange(index, "startTime", e.target.value)
                }
              />
              <span>to</span>
              <input
                type="time"
                value={item.endTime}
                onChange={(e) =>
                  handleTimeChange(index, "endTime", e.target.value)
                }
              />
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default BusinessHours;
