import { GET_ONBOARDING_STATUS, GET_ONBOARDING_STATUS_ERROR, GET_ONBOARDING_STATUS_SUCCESS, UPDATE_OCCUPATIONS, UPDATE_OCCUPATIONS_ERROR, UPDATE_OCCUPATIONS_SUCCESS, CONNECT_STRIPE_EXPRESS, CONNECT_STRIPE_EXPRESS_SUCCESS, CONNECT_STRIPE_EXPRESS_ERROR } from "../../constants"

export default function getOnboardingStatus(data) {

    return {
        type: GET_ONBOARDING_STATUS,
        payload: data
    }
}

export function getOnboardingStatusSuccess(data) {

    return {
        type: GET_ONBOARDING_STATUS_SUCCESS,
        payload: data,
    }
}

export function getOnboardingStatusError(data) {

    return {
        type: GET_ONBOARDING_STATUS_ERROR,
        payload: data,
    }
}

// ==============================================================================

export function updateOccupations(data) {

    return {
        type: UPDATE_OCCUPATIONS,
        payload: data
    }
}

export function updateOccupationsSuccess(data) {

    return {
        type: UPDATE_OCCUPATIONS_SUCCESS,
        payload: data,
    }
}

export function updateOccupationsError(data) {

    return {
        type: UPDATE_OCCUPATIONS_ERROR,
        payload: data,
    }
}

export function connectStripeExpress(data, resolve, reject) {

    return {
        type: CONNECT_STRIPE_EXPRESS,
        payload: data,
        resolve, reject
    }
}

export function connectStripeExpressSuccess(data) {

    return {
        type: CONNECT_STRIPE_EXPRESS_SUCCESS,
        payload: data,
    }
}

export function connectStripeExpressError(data) {

    return {
        type: CONNECT_STRIPE_EXPRESS_ERROR,
        payload: data,
    }
}