import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_TREATMENT_DETAILS } from "../../constants/treatment-list-const/TreatmentListConst";


const GET_TREATMENT_DETAILS_QUERY = gql`
query GetTreatmentDetails($slug: String!, $id: Int) {
  getTreatmentDetails(slug: $slug, id: $id) {
    success
    message
    data {
      treatment_details {
        treatment_id
        treatment_name
        treatment_images {
          image_id
          image_url
        }
        treatment_price
        treatment_description
        treatment_duration
        treatment_deposit
      }
    }
  }
}
`;

var page;
export const GetTreatmentsDetails = () => {
    const dispatch = useDispatch();
    const [getClinicTreatmentDetails, userTreatmentDetailsData] = useLazyQuery(
        GET_TREATMENT_DETAILS_QUERY,
        {
          fetchPolicy: "cache-and-network",
          nextFetchPolicy: "cache-and-network",
          onCompleted: ({  getTreatmentDetails: responseData }) => {

            
            dispatch({
              type: GET_TREATMENT_DETAILS,
              payload: responseData.data,page
            });
            if (responseData.success) {
               
            } else {
              
            }
    
    
          },
        }
      );
      const userTreatmentDetailsQueryData = userTreatmentDetailsData;
  const initGetTreatmentDetailsForUser = (data) => {
    page = data?.page ? data?.page : 1
    getClinicTreatmentDetails({
      variables: data,
    });
  };
  return {  initGetTreatmentDetailsForUser, userTreatmentDetailsQueryData };
};

// export function setUserBusinessReg(data) {
//     return {
//       type: GET_TREATMENTS_FOR_USER,
//       payload: data,
//     };
//   }