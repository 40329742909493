import React from "react";
import leftLcon from "../../../../images/Icon-left.svg";
import "../steps.scss";

const SocialMedUrlIcn = () => {
  return (
    <>
    <div className="icon-left">
      <img
        src={leftLcon}
        width={30}
        height={30}
        className="icon"
      />
      </div>
    </>
  );
};

export default SocialMedUrlIcn;
