import { gql, useLazyQuery } from "@apollo/client";
import { GET_ALL_WEBSITE_PACKAGE_DATA } from "../../constants/createWebiste/getAllWebsitePackages";
import { useDispatch } from "react-redux";



const GET_ALL_WEBSITE_PACKAGES = gql`query GetWebsitePackages {
    getWebsitePackages {
      success
      message
      data {
        packages {
          id
          package_name
          amount
          amountText
          duration_text
          package_points
          can_subscribe
          url
          is_popular
          is_currentplan
          calendly_url
          is_upgradable
          slug
        }
      }
    }
  }
`

export const GetAllWebsitePackageAction = () => {
  const dispatch = useDispatch()
  const [GetAllWebsitePackageFunctionResponse, getAllWebsitePackageResponseData] = useLazyQuery(GET_ALL_WEBSITE_PACKAGES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: ({ getWebsitePackages: responseData }) => {
      if (responseData?.success) {
         dispatch({ type: GET_ALL_WEBSITE_PACKAGE_DATA, payload: responseData?.data?.packages })
      }
    }
  })

  const GetAllWebsitePackageResponseData = getAllWebsitePackageResponseData

  const initAllWebsitePackageFunction = (data, successCallBack, errorCallBack) => {
    return GetAllWebsitePackageFunctionResponse({
      variables: data
    }).then(res => {
      successCallBack(res)
    }).catch(err => {
      errorCallBack(err)
    })
  }
  return { initAllWebsitePackageFunction, GetAllWebsitePackageResponseData }
}
