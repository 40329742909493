import React, { useEffect, useState } from 'react';
import './clientBooking.scss';
import location from '../../../images/icon/location.png';
import placeholder from '../../../images/placeholder.png';
import useDeviceDetect from '../../common/useDeviceDetect';
import LocationMobile from './LocationMobile';
import { BusinessClinicList } from '../../redux/actions/locationList/getBusinessClinicList';
import { useSelector } from 'react-redux';
import LocationWeb from './LocationWeb';
import { useParams } from 'react-router';
import { GetNoAppointmentAVailableAction } from '../../redux/actions/checkNoAppointmentAvailable/CheckNoAppointmentAvailable';

const Location = ({ setStep, setClinicId, clinicId, setSelectedLocation, selectedLocation, setSelectedTreatment, setShowSelectedDate, setFormatedDate, setShowSelectedTime }) => {
  const { slug } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const { deviceType, screenWidth } = useDeviceDetect();
  const { initBusinessClinicList, businessClinicListQueryData } = BusinessClinicList();
  const clinicListData = useSelector(
    (state) => state?.businessLocationListReducer?.businessClinicList?.items
  );
  const totalClinics = useSelector(
    (state) => state?.businessLocationListReducer?.totalClinics
  );
  const loadedClinicsCount = useSelector(
    (state) => state?.businessLocationListReducer?.loadedClinicsCount
  );
  const clinicListPage = useSelector(
    (state) => state?.businessLocationListReducer?.clinicListPage
  );

  const businessName = useSelector((state) => state?.businessLocationListReducer?.business_name)

  const { businessClinicList } = useSelector((state) => state?.businessLocationListReducer)
  const { checkNoAppointMentAvailableData, initCheckNoAppointmentAvailable } = GetNoAppointmentAVailableAction();



  useEffect(() => {

    initCheckNoAppointmentAvailable({
      tokenSlug: slug,
    });
  }, [])



  useEffect(() => {
    if (businessClinicList == null)
    // initBusinessClinicList({
    //   userToken: slug
    // });
    {
      initiateBusinessClinicListApiCall(true);
    }
  }, [])

  const initiateBusinessClinicListApiCall = (refreshList = false) => {
    let newPage = clinicListPage + 1;
    if (refreshList) {
      newPage = 1;

    } else if (totalClinics <= loadedClinicsCount) {
      return;
    }

    initBusinessClinicList({
      userToken: slug,
      page: newPage,
      limit: 15,
    });
  }

  return (
    screenWidth < 768 ?
      <LocationMobile setStep={setStep} setClinicId={setClinicId} clinicId={clinicId} businessName={businessName} locationData={clinicListData} isLoading={businessClinicListQueryData?.loading}
        isCalled={businessClinicListQueryData?.called}
        setSelectedLocation={setSelectedLocation}
        selectedLocation={selectedLocation}
        setSelectedTreatment={setSelectedTreatment}
        setShowSelectedDate={setShowSelectedDate}
        setFormatedDate={setFormatedDate}
        setShowSelectedTime={setShowSelectedTime}
        isAppointmentDataAvailable={checkNoAppointMentAvailableData?.data?.checkNoAppointmentAvailable?.success}
        isLoadingNoAppointment={checkNoAppointMentAvailableData?.loading}
        isCalledLoadingNoAppointment={checkNoAppointMentAvailableData?.called}
        initiateBusinessClinicListApiCall={initiateBusinessClinicListApiCall}
      /> :
      <LocationWeb
        setStep={setStep}
        setClinicId={setClinicId}
        clinicId={clinicId}
        businessName={businessName}
        isCalled={businessClinicListQueryData?.called}
        locationData={clinicListData} isLoading={businessClinicListQueryData?.loading} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation}
        setSelectedTreatment={setSelectedTreatment}
        setShowSelectedDate={setShowSelectedDate}
        setFormatedDate={setFormatedDate}
        setShowSelectedTime={setShowSelectedTime}
        isAppointmentDataAvailable={checkNoAppointMentAvailableData?.data?.checkNoAppointmentAvailable?.success}
        isLoadingNoAppointment={checkNoAppointMentAvailableData?.loading}
        isCalledLoadingNoAppointment={checkNoAppointMentAvailableData?.called}
        initiateBusinessClinicListApiCall={initiateBusinessClinicListApiCall}
      />

  );
};

export default Location;
