import { gql, useMutation } from "@apollo/client";

const DELETE_USER_PROFILE_PIC_MUTATION = gql`
mutation DeletePrescriberProfilePic {
  deletePrescriberProfilePic {
    success
    message
  }
}
`;

export const InputInfoDeleteProfilePicUserAction = () => {
  const [deleteUserpicFunction, deleteUserProfilePicResponseData] = useMutation(DELETE_USER_PROFILE_PIC_MUTATION);

  const initDeleteProfileResponsefunction = (data, successCallback, errorCallback) => {

    deleteUserpicFunction({ variables: data })
      .then(response => {
        console.log(response,"20....")
        const { success, message } = response.data.deletePrescriberProfilePic;
        if (success) {
          successCallback(response);
        } else {
          errorCallback(response);
        }
      })
      .catch(error => {
        console.error(error);
        errorCallback(error);
      });
  };

  return { deleteUserProfilePicResponseData, initDeleteProfileResponsefunction };
};
