import React, { useState } from "react";
import "./AddDiscountcodes.scss";
import TextInput from "../../../../../common/TextInput";
import CustomDropdown from "../../../../../common/dropdown/CustomDropdown";
import StepButton from "../../../../../common/StepButton";
import BusinessNavigation from "../../../../../common/businessNavigation/BusinessNavigation";

function AddDiscountcodes({ setStep }) {
  const [discountDetails, setDiscountDetails] = useState({
    DiscountCode: "",
    AmountPercentage: "",
    DiscountType: { id: 0, value: "" },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDiscountDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [validationErrors, setValidationErrors] = useState({
    DiscountCode: "",
    AmountPercentage: "",
    DiscountType: "",
  });

  const discountTypeArray = ["Sole trader", "Limited Company"];

  const customStyle = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Adjust the z-index value according to your requirement
    }),
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "#FFFFFF",
      outline: "none",
      appearance: "none",
      padding: "10px 10px",
      borderRadius: "10px",
      border: "1px solid #878787",
      height: "55px",
      width: "100%",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
      };
    },
  };

  const handleDiscountTypeChange = (value) => {
    const valueMap = {
      "Sole trader": 2,
      "Limited Company": 1,
    };
    if (value in valueMap) {
      const intValue = valueMap[value];
      setDiscountDetails((prevData) => ({
        ...prevData,
        DiscountType: { id: intValue, value: value },
      }));
      setValidationErrors((prevData) => ({
        ...prevData,
        DiscountType: "",
      }));
    }
  };

  return (
    <div className="MainAddDiscountcodes">
      <div className="rightSidebarWrapper">
        <div className="Main684WidthSet">
          <BusinessNavigation
            isColumn={true}
            title={"Add discount code"}
            desc={
              "Do you ever like to offer client discounts from time to time? From here you can create a set percentage or a set amount to be discounted at the checkout when your client is booking. The discount will calculated and shown on your booking information"
            }
            onBackClick={() => {
              setStep(0);
            }}
          />
          <div className="InputFieldWrapper">
          <div className="finance-input-container">
            <TextInput
              id="DiscountCode"
              label="Discount Code"
              placeholder="Discount Code"
              name="DiscountCode"
              value={discountDetails?.Subject}
              onChange={(e) => handleChange(e)}
            />
            {validationErrors.DiscountCode && (
              <span className="error">{validationErrors.DiscountCode}</span>
            )}
          </div>
          <div className="finance-input-container">
            <TextInput
              id="AmountPercentage"
              label="Amount/Percentage"
              placeholder="Amount/Percentage"
              name="AmountPercentage"
              value={discountDetails?.Subject}
              onChange={(e) => handleChange(e)}
            />
            {validationErrors.AmountPercentage && (
              <span className="error">{validationErrors.AmountPercentage}</span>
            )}
          </div>
          <div className="finance-input-container">
            <CustomDropdown
              labelName={"Discount Type"}
              defaultValue={discountDetails?.DiscountType?.value}
              dropdownId={"DiscountType"}
              placeholdername={"Discount Type"}
              optionsArr={discountTypeArray}
              style={customStyle}
              handleSelectedValue={handleDiscountTypeChange}
              readOnly={false}
            />
            {validationErrors.DiscountType && (
              <span className="error">{validationErrors.DiscountType}</span>
            )}
          </div>
          </div>
          <div className="bottomBtn">
            <StepButton label={"Submit"} />
          </div>
        </div>
        <div className="RightOverView">
            <div className="overHeading">
              <h5>Overview</h5>
            </div>
            <div className="Content">
              <p>
                Sending text reminders and any other messages to your clients is
                simple. Just purchase the amount of texts you need, and select
                the clients you want to text.{" "}
              </p>
              <p>
                In case the message character length is greater than 160, the
                message will be sent in multiple parts.
              </p>
            </div>
          </div>
      </div>
    </div>
  );
}

export default AddDiscountcodes;
