import React, { useEffect, useRef, useState } from 'react'
import "./question-list.scss"
import CustomRadioButton from '../../common/CustomRadioButton'

const QuestionsList = ({ label,onChange, selectedOption ,id}) => {
  const [comment, setComment] = useState(""); // State to hold textarea value

  const handleTextareaChange = (event) => {
    setComment(event.target.value); // Update the comment state when textarea changes
  };

  const textareaRef = useRef(null);

  useEffect(() => {
    setHeight(textareaRef.current);
  }, []);

  const setHeight = (elem) => {
    const style = window.getComputedStyle(elem);
    const verticalBorders = Math.round(
      parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth)
    );
    const maxHeight = parseFloat(style.maxHeight) || 150;

    elem.style.height = "auto";

    const newHeight = elem.scrollHeight + verticalBorders;

    elem.style.overflowY = newHeight > maxHeight ? "auto" : "hidden";
    elem.style.height = Math.min(newHeight, maxHeight) + "px";
  };

  const handleInputChange = (e) => {
    setHeight(e.target);
  };

  const removeSpacialcharacterAndEmojies=(character)=>{

     let removed=character?.replace(/[\u{1F600}-\u{1F64F}]/gu, '') // Remove emoticons
                  .replace(/[\u{1F300}-\u{1F5FF}]/gu, '') // Remove symbols & pictographs
                  .replace(/[\u{1F680}-\u{1F6FF}]/gu, '') // Remove transport & map symbols
                  .replace(/[\u{1F700}-\u{1F77F}]/gu, '') // Remove alchemical symbols
                  .replace(/[\u{1F780}-\u{1F7FF}]/gu, '') // Remove Geometric Shapes Extended
                  .replace(/[\u{1F800}-\u{1F8FF}]/gu, '') // Remove Supplemental Arrows-C
                  .replace(/[\u{1F900}-\u{1F9FF}]/gu, '') // Remove Supplemental Symbols and Pictographs
                  .replace(/[\u{1FA00}-\u{1FA6F}]/gu, '') // Remove Chess Symbols
                  .replace(/[\u{1FA70}-\u{1FAFF}]/gu, '') // Remove Symbols and Pictographs Extended-A
                  .replace(/[\u{2300}-\u{23FF}]/gu, '') // Remove Miscellaneous Technical
                  .replace(/[\u{0080}-\u{00FF}]/gu, '') // Remove Latin-1 Supplement
                  .replace(/[\u{0100}-\u{017F}]/gu, '') // Remove Latin Extended-A
                  .replace(/[\u{0180}-\u{024F}]/gu, '') // Remove Latin Extended-B
                  .replace(/[\u{0250}-\u{02AF}]/gu, '') // Remove IPA Extensions
                  .replace(/[\u{02B0}-\u{02FF}]/gu, '') // Remove Spacing Modifier Letters
                  .replace(/[\u{0300}-\u{036F}]/gu, '') // Remove Combining Diacritical Marks
                  .replace(/[\u{0370}-\u{03FF}]/gu, '') // Greek and Coptic
                  .replace(/[\u{0400}-\u{04FF}]/gu, '') // Cyrillic
                  .replace(/[\u{3040}-\u{309F}]/gu, '') // Hiragana
                  .replace(/[\u{30A0}-\u{30FF}]/gu, '') // Katakana
                  .replace(/[\u{AC00}-\u{D7AF}]/gu, '') // Hangul Syllables
                  .replace(/[\u{4E00}-\u{9FFF}]/gu, '') // CJK Unified Ideographs
                  .replace(/[^\x00-\x7F]/g, ''); // Remove any remaining non-ASCII characters

                  
          removed=removed?.trim()?.replace(/\s+/g, ' ');
          return removed;

  }
  console.log("selectedOption object", selectedOption, selectedOption?.answerNew);
  return (
    <div className='questions-list-section'>
      <div className='question-container'>
        <div className='row mx-0'>
          <div className='col-md-12 px-0'>
            <div className='question'>
              <h3>{label}</h3>
              <div className='row'>
                <div className='col-md-6'>
                  <CustomRadioButton className={"question-radio-btn"} text={"Yes"} isblacked={true} onChange={() => onChange(1,selectedOption?.comment,label)}  id={`Yes-${id}`}
                      name={`question-${id}`}
                      checked={selectedOption?.answerNew == 1} preselect />
                </div>
                <div className='col-md-6'>
                  <CustomRadioButton text={"No"} className={"question-radio-btn"} onChange={() => onChange(0,selectedOption?.comment,label)} isblacked={true} id={`No-${id}`} name={`question-${id}`} checked={selectedOption?.answerNew == 0} preselect />
                </div>
              </div>
             
             <div className='row'>
             <div className='forward-question-comment col-md-12'>
                <textarea placeholder="Comments" rows={"1"}
                  ref={textareaRef}
                  id="textarea"
                  value={removeSpacialcharacterAndEmojies(selectedOption?.comment)}
                  onChange={(e) => onChange(selectedOption?.answerNew, e.target.value, label)} // Call handleTextareaChange on textarea change
                  onInput={handleInputChange}
                />
                {
                  console.log("selectedOption?.comment",selectedOption?.comment)
                }
              </div>
             </div>
            </div>
            
          </div>
          
        </div>

      </div>
    </div>
  )
}

export default QuestionsList