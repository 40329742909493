import React, { useEffect, useState } from "react";
import "../edit-main.scss";
import CustomRadioButton from "../../../common/CustomRadioButton";
import emptySignLogo from "../../../../images/signature pop-icon/empty_sign.svg";
import closeIcon from "../../../../images/close_icon.svg";
import editHeaderImg from "../../../../images/IMage.svg";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import deleteIcon from "../../../../images/icons/delele_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { AddAndEditUserConsentForm, ConsentFormPreview, UserConsentFormList } from "../../../redux/actions/walk-in-form/walkInAddFormsList";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { CLEAR_CONSENT_FORM_DETAILS } from "../../../redux/constants/walk-in-form-const/clientWalkInConst";
import { ReactComponent as InfoIcon } from '../../../../images/info-blue.svg';
import { ReactComponent as CloseIcon } from '../../../../images/close-black.svg';
import { ReactComponent as DeleteIcon } from '../../../../images/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../../../images/edit-icon.svg';
import StepButton from '../../../common/StepButton';
import showToast from "../../../common/CustomToast/CustomToast";
import QuestionItem from "./QuestionItem";
import { v4 as uuid } from "uuid";
import DisclaimerBox from "./DisclaimerBox";
import EditDisclaimer from "./EditDisclaimer";
import SkeletonLoader from "../../../common/Skeleton/SkeletonLoader";
import { useSearchParams } from "react-router-dom";
import NavHeader from "../../../common/NavHeader/NavHeader";
import CustomeHeading from "../../../customeHeading/customeHeading";
import useWindowDimension from "../../../../utils/useWindowDimensions";
import { editFormRoute, previewFormRoute } from "../../../../Routes";
import EditableTitle from "./EditableTitle";

const EditConsentForm = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const initialActiveTab = searchParams.get('tab');
  const { consentId } = useParams();
  const parsedConsentId = (!consentId || consentId === 'new') ? null : +consentId;
  const [questionList, setQuestionList] = useState([]);
  const [formName, setFormName] = useState("");
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);
  const [isEditingFormName, setIsEditingFormName] = useState(false);
  const [disclaimer, setDisclaimer] = useState('');
  // const [dupFormId, setDupFormId] = useState(parsedConsentId && initialActiveTab === '4' ? parsedConsentId : null); // for managing duplicated form id

  // console.log('dupFormId', dupFormId);

  const consentFormDetails = useSelector(state => state?.walkInClientListReducer?.consentFormDetails);

  const { initConsentFormPreview, consentFormPreviewQueryData } = ConsentFormPreview();
  // const { initUserConsentFormList, userConsentFomrListQueryData } = UserConsentFormList();
  const { initAddAndEditUserConsentFormData, addUserConsentFormQueryData } = AddAndEditUserConsentForm();

  console.log('consentFormDetails', consentFormDetails);

  console.log('questionList', questionList);
  console.log('isEditingFormName', isEditingFormName);

  useEffect(() => {
    if(!parsedConsentId && formName.length === 0) {
      setIsEditingFormName(true);
      return;
    }

    if (parsedConsentId) {
      dispatch({ type: CLEAR_CONSENT_FORM_DETAILS })
      initConsentFormPreview({
        consentId: parsedConsentId,
      },(data) => {
        setFormName(data.con_frm_name)
      }, 
      (err) => {
        showToast('error', err.message);
      });
      // initUserConsentFormList();
    }
  }, []);

  useEffect(() => {
    if(consentFormDetails?.questions) {
      setQuestionList([...consentFormDetails?.questions]);
    }
    if(consentFormDetails?.disclaimer) {
      setDisclaimer(consentFormDetails?.disclaimer);
    }
  }, [consentFormDetails?.questions, consentFormDetails?.disclaimer])

  const handleSubmit = () => {
    showToast('success', 'Form saved successfully!');

    // don't redirect if no questions added
    if(questionList.length === 0) {
      return
    }
    dispatch({ type: CLEAR_CONSENT_FORM_DETAILS })
    navigate(`/manage-forms?tab=${4}`);
  };

  const handleBackToHome = () => {
    dispatch({ type: CLEAR_CONSENT_FORM_DETAILS })
    // if(isAddDisabled) return;
    navigate(`/manage-forms?tab=${4}`);
  };

  const saveConsentFormHelper = () => {

    const payload = {
      createConsentFormId: parsedConsentId,
      consentFormName: formName,
      disclaimer: disclaimer
    }
    const onApiSuccess = (data) => {
      if(parsedConsentId) {
        handleSubmit();
      } else {
        showToast('success', 'Form saved successfully!');
        const newConsentId = data.data.createConsentForm.data.con_frm_id;
        navigate(editFormRoute(newConsentId) + '?tab=4');
      }
    }
    const onApiError = (err) => {
      showToast('error', err.message);
    }
    initAddAndEditUserConsentFormData(payload, onApiSuccess, onApiError)
  }

  const addQuestionHandler = () => {
    if(!parsedConsentId) {
      showToast('error', 'Please save your form first', undefined, undefined, undefined, undefined, {toastId: 'save-form-error'})
      return;
    }
    const uid = uuid();

    // new question will be added with _id field. So in create question api request id is not passed.
    setQuestionList(prev => {
      return [...prev, 
        {
          question: '',
          _id: uid
        }
      ]
    })
    setTimeout(() => {
      document.getElementById(`question-edit-${uid}`).focus();
    }, 0);
    setIsAddDisabled(true);
    handleEditMode(true);
  }

  // change question text
  const handleChange = (text, questionId) => {
    setQuestionList(prev => {
      return prev.map(i => {
        if(i.id === questionId || i._id === questionId) {
          return {...i, question: text}
        }
        else return i;
      })
    })
  }

  const handleDelete = (questionId) => {
    setQuestionList(prev => prev.filter(i => {
      if(i._id === questionId) {
        setIsAddDisabled(false);
      }
      return i.id !== questionId && i._id !== questionId;
    }))
  }

  // after create question api call we get actual id, so we add that id on question
  const addQuestionId = (tempId, finalId) => {
    setQuestionList(prev => {
      return prev.map(i => {
        if(i._id === tempId) {
          const newObj = {...i, id: finalId};
          delete newObj._id;
          return newObj;
        } else {
          return i
        }
      })
    })
    setIsAddDisabled(false);
  }

  const handleEditMode = (state) => {
    setIsEditingQuestion(state);
    setIsAddDisabled(state);
  }

  const dimensions = useWindowDimension();
  const [headerIndicatorHeight, setHeaderIndicatorHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [secTitleHeight, setSecTitleHeight] = useState(0);
  const [btnWrapperHeight, setBtnWrapperHeight] = useState(0);
  const [infoBoxHeight, setInfoBoxHeight] = useState(0);

  console.log(headerIndicatorHeight, 'headerIndicatorHeight', headerHeight, 'headerHeight', secTitleHeight, 'secTitleHeight', btnWrapperHeight, 'btnWrapperHeight')

  const updateHeights = (excludeInfoBox = false) => {
    setHeaderIndicatorHeight(
      document.getElementsByClassName("form-mode-indicator")[0]?.offsetHeight || 0
    );
    setHeaderHeight(
      document.getElementsByClassName("nav-header-without-padding-container")[0]?.offsetHeight || 0
    );
    setSecTitleHeight(
      (document.getElementsByClassName("form-title-editable")[0]?.offsetHeight + 8) || 0
    );
    // setSecTitleHeight(
    //   document.getElementsByClassName("SectionHeadingWrapper")[0]?.offsetHeight || 0
    // );
    setBtnWrapperHeight(
      document.getElementsByClassName("BtnWrapper")[0]?.offsetHeight || 0
    );
    if(excludeInfoBox) {
      setInfoBoxHeight(0);
    } else {
      setInfoBoxHeight(
        (document.getElementById("edit-consent-form-info-box")?.offsetHeight + 20) || 0
      );
    }
  };

  useEffect(() => {
    if(consentFormPreviewQueryData){
      setTimeout(()=>{
        updateHeights();
      }, 100)
    }
    
    window.addEventListener('resize', updateHeights);
    return () => {
      window.removeEventListener('resize', updateHeights);
    };
  }, [consentFormPreviewQueryData]);

  const scrollableDivHeight = dimensions?.height - headerIndicatorHeight - headerHeight - secTitleHeight - btnWrapperHeight - infoBoxHeight + 'px';

  return (
    <div className="Main-Edit-Manage-Forms-page">
      <div className="form-mode-indicator">
        <p>You're in editing mode</p>
      </div>
      <NavHeader
        applyPadding={false}
        handleOnBack={handleBackToHome}
        btnLabel="Save"
        isLoading={addUserConsentFormQueryData.loading}
        disabled={isAddDisabled || !formName.length || !disclaimer.length || addUserConsentFormQueryData.loading}
        isBackWithButton={true}
        onClickCallback={saveConsentFormHelper}
      />
      <div style={{ paddingTop: '83px' }} className="maxWidth540 paddintTop80">
        {(consentFormPreviewQueryData.loading || (parsedConsentId && formName?.length === 0 && disclaimer.length === 0))
        // {(consentFormPreviewQueryData.loading || (parsedConsentId && !consentFormPreviewQueryData.called))
          ? 
            <SkeletonLoader type='edit-form-skeleton' /> 
          : 
          <div className="consent-edit-form consent-edit-form--consent">
            {/* <CustomeHeading
              Title={formName}
              Desc={''}
              isSearch={false}
            /> */}
            <EditableTitle 
              text={formName}
              onTextChange={(text) => setFormName(text)}
              isEditing={isEditingFormName}
              toggleEditing={(state) => setIsEditingFormName(state)}
            />

            <DisclaimerBox updateLayout={() => updateHeights(true)} />
            <div className="form-edit-options pb-1" style={{height: scrollableDivHeight, overflowY: 'auto' }}>
              <div className="edit-consent-category">
                <p>Disclaimer</p>
              </div>
              
              <EditDisclaimer
                cnfId={consentId}
                data={disclaimer}
                defaultEdit={false}
                canEdit={!isEditingQuestion && !isEditingFormName}
                handleEditMode={handleEditMode}
                onChange={(text) => setDisclaimer(text)}
              />
              <div className="edit-consent-category edit-consent-category--questions">
                <p>Questions</p>
              </div>
              <div className="question-wrapper">
                {questionList?.length > 0 && questionList.map((item, index) => (
                  <QuestionItem
                    canEdit={!isEditingQuestion && !isEditingFormName}
                    handleEditMode={handleEditMode}
                    addQuestionId={addQuestionId} 
                    defaultEdit={!!item._id && !item.id} 
                    onDelete={handleDelete} 
                    cnfId={consentId} 
                    key={item.id || item._id} 
                    data={item} 
                    onQuestionChange={handleChange} 
                  />
                ))}
              </div>
            </div>
            <div className="BtnWrapper BtnWrapper--editConsent">
              <StepButton 
                disabled={isAddDisabled || !parsedConsentId} 
                onClick={() => {
                  dispatch({ type: CLEAR_CONSENT_FORM_DETAILS })
                  navigate(previewFormRoute(consentId) + `?tab=${4}`);
                }} 
                gray 
                label='Preview' 
              />
              <StepButton disabled={isAddDisabled || isEditingFormName} onClick={addQuestionHandler} blue label='Add question' />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default EditConsentForm;
