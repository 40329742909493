import React, { useEffect, useMemo, useRef, useState } from "react";
import OffCanvasModal from "../modal/OffCanvasModal/OffCanvasModal";
import "./add-client.scss";
import TextInput from "../common/TextInput";
import locationIcon from "../../images/location_input.svg";
import timeIcon from "../../images/icons/ComplianceTime.svg";
import timeIcon1 from "../../images/icons/ComplianceTime1.svg";
import timeIcon2 from "../../images/icons/ComplianceTime2.svg";
import loaderIcon from "../../images/icons/loader/loader.gif"
import personIcon from "../../images/icons/new-client/next_of_kin.svg";
import identityIcon from "../../images/icons/new-client/Identity.svg";
import medicalIcon from "../../images/icons/new-client/medical.svg";
import noteIcon from "../../images/icons/new-client/Notes.svg";
import { ReactComponent as RightArrow } from "../../images/icons/chevron-left.svg";
import CustomModal from "../modal/CustomModal";
import AddNotes from "../common/AddNotes";
import IdentityOverview from "./IdentityOverview";
import NextOfKin from "./NextOfKin";
import Autocomplete from "react-google-autocomplete";
import { googleApiKey } from "../../config";
import CustomRadioButton from "../common/CustomRadioButton";
import NavHeader from "../common/NavHeader/NavHeader";
import { useLocation, useNavigate, useParams } from "react-router";
import { EditClientRoutePattern, getClientProfileRoute, getEditClientRoute, getSearchClientRoute } from "../../Routes";
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input/input'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';

import PropTypes from 'prop-types'
// import en from 'react-phone-number-input/locale/en'
import { CreateClient } from "../redux/actions/client/CreateClient";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { GetClientDetails } from "../redux/actions/client/GetClientDetails";

import { CLEAR_CLIENT_DETAILS, CLEAR_CLIENT_LIST_DATA, GET_CLIENT_TILE_STATUS } from "../redux/constants";
import { toast } from "react-toastify";
import { CreateIdentityDocument } from "../redux/actions/client/CreateIdentity";
import StepButton from "../common/StepButton";
import { DeleteIdentityDocument } from "../redux/actions/client/DeleteIdentity";
import { ReactComponent as CloseIcon } from "../../images/close_icon.svg";
import gsap from "gsap";
import { alphaNumericRegex, alphaNumericRegexAp } from "../../utils/validationRegx";
import { GetNextOfKinDetails } from "../redux/actions/client/GetNextOfKinDetails";
import { GetIdentityDocumentDataQuery } from "../redux/actions/client/GetIdentityData";
import SkeletonLoader from "../common/Skeleton/SkeletonLoader";
import { updateClientIdentityStatus } from "../redux/actions/client/ClientIdentityStatus";
import AppointmentToast from "../new-appointment/AppointmentToast";
import showToast from "../common/CustomToast/CustomToast";
import CustomDropdown from "../common/dropdown/CustomDropdown";
import SwitchButton from "../common/switchButton/SwitchButton";
import Avatar from "react-avatar";
import { event } from "jquery";
import { DeleteProfileImage } from "../redux/actions/client/DeleteClientProfileImage";
import DeleteActionIcon from "../../images/DeleteActionIcon.svg"
import ProfileIcon from "../../images/person.svg";
import Resizer from "react-image-file-resizer";
import trackGoogleAPI from "../../utils/googleApiTrack";
import PatientDobWarningModal from "../modal/PatientDobWarningModal";
import { isUnderAge } from "../../utils/general";
import { debounce } from "lodash";



const AddClient = ({ isHide, fromOffCanvas, setIsRes, setSelectedClient, setIsCloseFromCanvas }) => {
	const locationState = useLocation()
	const bookingDetails = {
		next_fin_status: true,
	};

	const patientDobModalRef = useRef(null);

	const [isNoteModal, setIsNoteModal] = useState(false);
	const [isNextOfKin, setIsNextOfKin] = useState(false);
	const [isIdentityOpen, setIsIdentityOpen] = useState(false)
	const [files, setFiles] = useState([])
	const [countryCode, setCountryCode] = useState("GB")
	const navigate = useNavigate()
	const [value, setValue] = useState('');
	const fileInputRef = useRef(null);
	const profilePicInputRef = useRef(null);
	const [updateTrackType, setUpdateTrackType] = useState(null);

	const [isPreview, setIsPreview] = useState(false);
	const [fileExist, setFileExist] = useState(false)
	const [singleFile, setSingleFile] = useState({
		clientId: null,
		documentId: "",
		documentUrl: "",
		documentName: "",
		time: ""
	})
	const [newClientRes, setNewClientRes] = useState(false)
	const [notes, setNotes] = useState('')
	const [commonLoder, setCommonLoder] = useState(null)
	const [basicInfoData, setBasicInfoData] = useState({
		firstName: "",
		lastName: "",
		gender: "",
		birthday: "",
		title: ""
	})

	const [contactDetailsData, setContactDetailsData] = useState({
		email: "",
		phoneNumber: "",
		countryCode: "",
		address: "",
		doNotSendMarketingToggle: false
	})
	const [searchLocation, setSearchLocation] = useState("");
	const [showManualAddress, setShowManualAddress] = useState(false);
	const [longitude, setLongitude] = useState(0.0);
	const [latitude, setLatitude] = useState(0.0);
	//const [clientId,setClientId]=useState(null);
	const { clientDetailsQueryData, initGetClientDetails } = GetClientDetails()
	const [isNextofKinAdded, setIsNextOfKinAdded] = useState(false)
	const [isNoteAdded, setIsNoteAdded] = useState(false)
	const [selectedCountryLabel, setSelectedCountryLabel] = useState("");
	const clientDetails = useSelector(state => state?.clientDetailsReducer?.clientDetails)
	const clientIdentityStatus = useSelector((state) => state?.clientDetailsReducer?.clientIdentityStatus)
	const clientNoteStatus = useSelector((state) => state?.clientDetailsReducer?.clientNoteStatus)
	const clientNextOfKinStatus = useSelector((state) => state?.clientDetailsReducer?.clientNextOfKinStatus)
	const clientMedicalDetails = useSelector((state) => state.clientDetailsReducer?.clientMedicalDetailsStatus)
	const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)
	const [selectedLocation, setSelectedLocation] = useState("");
	const { clientId } = useParams()
	//for error purpose
	const [validationErrors, setValidationErrors] = useState({
		firstName: "",
		lastName: "",
		phonePrefix: "",
		phoneNumber: "",
		email: "",
		countryCode: "",
		address: "",
		nextkinPhoneNumber: "",
		nextkinPhonePrefix: "",
		nextkinFirstName: "",
		nextkinLastName: "",
		nextkinRelationship: "",
		title: "",
		gender: ""
	});

	//Set Client Location state
	const [location, setLocation] = useState({
		address1: "",
		address2: "",
		city: "",
		postcode: "",
		country: "",
		street: "",
		house_no: "",
		latitude: 0.0,
		longitude: 0.0,
		state: ""
	})
	const [isDeleteIconClick, setIsDeleteIconClick] = useState(false);

	const handleMenualAddress = () => {
		setShowManualAddress(false)
		setSearchLocation(location?.country == "" ? location?.address1 + "," + location?.address2 + "," + location?.city + "," + location.postcode : location?.address1 + "," + location?.address2 + "," + location?.city + "," + location?.country + "," + location.postcode)
	}

	//State for nextofKin
	const [nextOfKinData, setNextOfKinData] = useState({
		firstName: "",
		lastName: "",
		relation: "",
		phoneCountry: "",
		phone: "",
	})
	//Date of Birth
	const dayRef = useRef(null);
	const monthRef = useRef(null);
	const yearRef = useRef(null);

	const [clientOriginalDob, setClientOriginalDob] = useState(null);

	const [day, setDay] = useState("");
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");
	const [dobError, setDobError] = useState(false);
	const [dobValue, setDobValue] = useState('');
	const [singleClick, setSingleClick] = useState(null)
	const imgRef = useRef(null);
	const [isClose, setIsClose] = useState(false)
	const [isMenualAddress, setIsMenualAddress] = useState(0)
	const [diffAdd, setDiffAdd] = useState("")
	const [enterAddress, setEnterAddress] = useState(false);
	const [isDeleteIdentity, setIsDeleteIdentity] = useState(false);
	const [profilePreviewUrl, setProfilePreviewUrl] = useState();
	const [isProfilePicPresent, setIsProfilePicPresent] = useState(false);
	const [isProfilePreview, setIsProfilePreview] = useState(false);
	const [isDeleteProfileConfirm, setIsDeleteProfileConfirm] = useState(false);


	const [profileImageData, setProfileImageData] = useState({
		profilePreviewUrl: "",
		isProfilePicPresent: false,
		profilePreviewTitle: "",
		profileImage: null,
	})

	const [formInvalid, setFormInvalid] = useState(false);

	//Handle Phonenumber selection
	const handleCountryChange = (country) => {
		setCountryCode(country);
		console.log("selected country", country)
		// Get the label based on the selected country
		const selectedLabel = getCountries().includes(country)
			? `${getCountryCallingCode(country)}`
			: "";

		setSelectedCountryLabel(selectedLabel);
	};

	console.log("first akash", clientIdentityStatus, singleFile)
	const dispatch = useDispatch()

	useEffect(() => {
		var date = moment(year + '-' + month + '-' + day).format("YYYY-MM-DD")

		if (day == "" && month == "" && year == "") {
			setDobError(false);
		}
		else if (day && month && year && day?.length == 2 && month.length == 2 && year?.length == 4 && date < moment().format("YYYY-MM-DD")) {
			setDobError(false);
			setDobValue(date);
		} else if (day && month && year) {
			setDobValue('')
			setDobError(true);
		}
	}, [day, month, year])

	//Create Client Mutation
	const { initiateCreateClient, createClientMutationData } = CreateClient();
	//Add Identity Document Mutation
	const { createIdentityMutationData, initiateCreateIdentity } = CreateIdentityDocument();
	//Delete Identity Document Mutation
	const { initiateDeleteIdentity, deleteIdentityMutationData } = DeleteIdentityDocument()
	//Get Next Of KinData
	const { initGetNextOfKinDetails } = GetNextOfKinDetails()
	//Get Identity Data

	const { initiateDeleteClientProfile, deleteClientProfileMutationData } = DeleteProfileImage()

	const { identityQueryData, initGetIdentity } = GetIdentityDocumentDataQuery()

	//reducer for get Client ID
	// const clientId=useSelector(state=>state?.createClientReducer?.clientId)
	console.log("client id akash", clientId, commonLoder)
	const countZeroReg = /^0+$/


	useEffect(() => {
		if (clientId === undefined) {
			dispatch({
				type: CLEAR_CLIENT_DETAILS,
			})
		}
	}, [clientId])

	function isValidEmail(email) {
		const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		return emailPattern.test(email);
	}

	useEffect(() => {
		if (clientId !== null && clientId !== undefined && clientId) {
			dispatch({
				type: CLEAR_CLIENT_DETAILS,
			})
			initGetClientDetails({
				clientId: parseInt(clientId)
			})
			initGetNextOfKinDetails({
				clientId: parseInt(clientId)
			}, (res) => {
				if (res.success) {
					setNextOfKinData(res.data)
				} else {
					setNextOfKinData(null)
				}
			})

			initGetIdentity({
				clientId: parseInt(clientId)
			}, (res) => {
				if (res.success) {
					if (res.data === null) {
						setFileExist(false)
						setSingleFile(null)
					} else {
						setFileExist(true)
						console.log("identity data", res?.data?.documents[0])
						setSingleFile({
							documentId: res?.data?.documents[0]?.documentId,
							documentUrl: res?.data?.documents[0]?.document,
							documentName: res?.data?.documents[0]?.documentName,
						})
					}
				} else {
					console.log("identity error", res.message)
				}
			}, (err) => {
				console.log("identity error", err)
			})
		}

	}, [clientId])

	// useEffect(() => {
	//   alert("second")
	//   initGetClientDetails({
	//     clientId: parseInt(clientId)
	//   })
	// }, [])



	//When Come from any Screen to Edit Client
	useEffect(() => {
		if (clientId !== undefined && clientId !== null && clientDetails !== undefined && clientDetails !== null) {
			setBasicInfoData({
				firstName: clientDetails?.clientFirstName,
				lastName: clientDetails?.clientLastName,
				gender: clientDetails?.clientGender,
				title: clientDetails?.clientTitle ? clientDetails?.clientTitle?.charAt(0).toUpperCase() + clientDetails?.clientTitle?.slice(1) : ""
			})


			if (clientDetails?.clientProfileImage) {

				const parts = clientDetails?.clientProfileImage.split("/");
				setProfileImageData({
					["profilePreviewUrl"]: clientDetails?.clientProfileImage,
					["isProfilePicPresent"]: true,
					["profilePreviewTitle"]: parts[parts.length - 1],
				})
			}

			// clientProfileImage
			setClientOriginalDob(clientDetails?.dob);

			if (clientDetails?.dob !== null && clientDetails?.dob !== undefined) {
				let dob = clientDetails?.dob?.split("-")
				setDay(dob[2])
				setMonth(dob[1])
				setYear(dob[0])
			}
			setContactDetailsData({
				email: clientDetails?.clientEmail,
				phoneNumber: clientDetails?.mobile,
				doNotSendMarketingToggle: clientDetails?.marketingCommunication == 1 ? true : false
			})
			// setLocation({
			//   longitude: clientDetails?.longitude,
			//   latitude: clientDetails?.latitude,
			//   address2: clientDetails?.street_name,
			//   city: clientDetails?.city,
			//   address1: clientDetails?.address,
			//   postcode: clientDetails?.postcode
			// })
			setLocation({
				...location,
				postcode: clientDetails?.postCode,
				latitude: clientDetails?.latitude,
				longitude: clientDetails?.longitude,
				address2: clientDetails?.street,
				address1: clientDetails?.houseNo,
				city: clientDetails?.city,
			})
			setCountryCode(clientDetails?.countryCode?.toUpperCase())
			setSelectedGender(clientDetails?.gender)
			setSearchLocation(
				clientDetails?.readableAddress)
			console.log("prefill Address", clientDetails?.street + "," + clientDetails?.address + "," + clientDetails?.city + "," + clientDetails?.postcode)
		}

	}, [clientId, clientDetails])
	const handleClientAdd = () => {
		const validationErrors = {};
		if (!basicInfoData.firstName.trim()) {
			validationErrors.firstName = "First name is required";
		}
		else {
			if (/\d/.test(basicInfoData.firstName)) {
				validationErrors.firstName = "Invalid first name";
			}
			else {
				validationErrors.firstName = "";
			}
		}
		if (!basicInfoData.lastName.trim()) {
			validationErrors.lastName = "Last name is required";
		}
		else {
			if (/\d/.test(basicInfoData.lastName)) {
				validationErrors.lastName = "Invalid last name";
			}
			else {
				validationErrors.lastName = "";
			}
		}
		if (!basicInfoData.title) {
			validationErrors.title = "Title is required";
		}
		if (!contactDetailsData.email || !isValidEmail(contactDetailsData.email)) {
			validationErrors.email = "Valid email is required";
		}
		if (contactDetailsData.phoneNumber) {
			const reg = /^\d{8,15}$/;
			let value = contactDetailsData.phoneNumber
			if (!reg.test(value.split(" ").join(""))
				|| value.split(" ").length > 2
				|| (value.split(" ").length == 2 && !(value.split(" ").length == 2 && (value.split(" ")?.[0].length == 4 || value.split(" ")?.[0].length == 5)))
				|| countZeroReg.test(value)
			)
				validationErrors.phoneNumber = "Please enter valid phone number"
		}


		// if (!selectedGender) {
		//   validationErrors.gender = "Gender is required";
		// }


		setValidationErrors(validationErrors);

		if (Object.values(validationErrors).some((error) => error) || dobError) {
			//toast.error("Please fix the validation errors.");
			// setLoader(false);
			return;
		}

		if ((!clientOriginalDob || clientOriginalDob != dobValue) && dobValue && isUnderAge(dobValue)) {
			patientDobModalRef.current.showDobWarning();
		}
		else {
			initSaveClient();
		}
	}

	const initSaveClient = () => {
		// ====== FOR EDIT CLIENT =========
		if (clientId !== undefined && clientId !== null) {

			console.log("toggle edit", contactDetailsData?.doNotSendMarketingToggle)
			console.log("data", location.street, location.address1, location.house_no)
			initiateCreateClient({
				clientId: parseInt(clientId),
				clinicId: 0,
				clientProfile: profileImageData.profileImage,
				firstName: basicInfoData.firstName,
				lastName: basicInfoData.lastName,
				gender: selectedGender == "Male" ? 1 : selectedGender == "Female" ? 2 : selectedGender == "Other" ? 3 : 0,
				email: contactDetailsData.email,
				phoneCountry: getCountryCallingCode(countryCode),
				country: countryCode.toLowerCase(),
				phone: contactDetailsData.phoneNumber,
				street: location.street || location.address2,
				houseNumber: location.house_no || location.address1,
				city: location.city,
				dob: dobValue,
				postCode: location?.postcode?.toString(),
				longitude: location?.longitude,
				latitude: location.latitude,
				title: basicInfoData?.title?.toUpperCase(),
				marketingCommunication: contactDetailsData?.doNotSendMarketingToggle ? 1 : 0,

			}, (res) => {
				if (res?.success) {
					setClientOriginalDob(dobValue);

					if (locationState?.state?.fromAppointment)
						navigate(-1)
					if (locationState.state)
						navigate(getClientProfileRoute(clientId))
					//showToast("success",res?.message,"",false)
					const initialData = {
						clientName: res?.data?.firstName + " " + res?.data?.lastName,
					}
					showToast("", res?.message, "", false, () => { }, res?.data?.clientProfile ? res?.data?.clientProfile : initialData)
				} else {
					setFormInvalid(true);
					showToast("error", res?.message, "", false, undefined, undefined, { toastId: 'client-add-error-toast-' + res?.message })
				}
			}, (e) => {
				console.log("error on create client", e)
			})
		} else {
			// ======== FOR NEW CLIENT =========

			initiateCreateClient({
				clientId: 0,
				clinicId: 0,
				clientProfile: profileImageData.profileImage,
				firstName: basicInfoData.firstName,
				lastName: basicInfoData.lastName,
				gender: selectedGender == "Male" ? 1 : selectedGender == "Female" ? 2 : selectedGender == "Other" ? 3 : 0,
				email: contactDetailsData.email,
				phoneCountry: getCountryCallingCode(countryCode),
				country: countryCode.toLowerCase(),
				phone: contactDetailsData.phoneNumber,
				street: location.street || location.address2,
				houseNumber: location.house_no || location.address1,
				city: location.city,
				dob: dobValue,
				postCode: location?.postcode?.toString(),
				longitude: longitude,
				latitude: latitude,
				title: basicInfoData?.title?.toUpperCase(),
				marketingCommunication: contactDetailsData?.doNotSendMarketingToggle ? 1 : 0,
			}, (res) => {
				// setNewClientRes(res?.success)
				// setIsRes(res?.success)
				if (res?.success) {
					setClientOriginalDob(dobValue);

					if (fromOffCanvas) {
						console.log("fromOffCanvas", res?.data)
						setSelectedClient({
							clentId: res?.data?.clientId,
							clientName: res?.data?.firstName + " " + res?.data?.lastName,
							clientEmail: res?.data?.email,
							readableContact: res?.data?.phone,
							clientProfileImage: res?.data?.clientProfile
						})
						dispatch({
							type: CLEAR_CLIENT_LIST_DATA
						})
						setIsCloseFromCanvas(false)
						isHide(false);

					} else {
						navigate(getEditClientRoute(res?.data?.clientId))
						document.getElementById("firstName").focus()

						initGetClientDetails({
							clientId: res?.data?.clientId
						})

					}
					const initialData = {
						clientName: res?.data?.firstName + " " + res?.data?.lastName,
					}
					showToast("", res?.message, "", false, () => { }, res?.data?.clientProfile ? res?.data?.clientProfile : initialData)
				} else {
					setFormInvalid(true);
					showToast("error", res?.message, "", false, undefined, undefined, { toastId: 'client-add-error-toast-' + res?.message })
				}
			}, (e) => {
				console.log("error on create client", e)
			})
		}
	}

	///Delete Identity Document
	const handleDeleteIdentity = (documentId, clientId) => {
		initiateDeleteIdentity({
			clientId: parseInt(clientId),
			documentId: documentId
		}, (res) => {
			console.log("delete res akash", res)
			if (res?.success) {
				setIsPreview(false);
				setIsDeleteIdentity(false);
				setSingleFile(null);
				setFileExist(false)
				initGetClientDetails({
					clientId: parseInt(clientId)
				})
				dispatch({
					type: CLEAR_CLIENT_DETAILS,
				})
				showToast("success", res?.message, "", false)
			} else {
				showToast("error", res?.message, "", false)
			}
		}, (err) => {
			console.log("error on delete identity", err)
			toast.error(err)
		})
	}

	useEffect(() => {
		if (clientDetailsQueryData?.loading) {
			setCommonLoder(true)
			dispatch({
				type: CLEAR_CLIENT_DETAILS,
			})
			initGetClientDetails({
				clientId: parseInt(clientId)
			})
		} else {
			setCommonLoder(false)

		}
	}, [clientDetailsQueryData?.loading])

	// Function to perform validation when input fields change
	const validateInput = (name, value) => {
		switch (name) {
			case "firstName":
				if (!value) {
					setValidationErrors((prev) => ({
						...prev,
						firstName: "First name is required",
					}));
				} else {
					if (!(/^[a-zA-Z'’\s-]+$/.test(value))) {
						setValidationErrors((prev) => ({
							...prev,
							firstName: "Invalid first name",
						}));
					}
					else {
						setValidationErrors((prev) => ({
							...prev,
							firstName: "",
						}));
					}
				}
				break;
			case "lastName":
				if (!value) {
					setValidationErrors((prev) => ({
						...prev,
						lastName: "Last name is required",
					}));
				} else {
					if (!(/^[a-zA-Z'’\s-]+$/.test(value))) {
						setValidationErrors((prev) => ({
							...prev,
							lastName: "Invalid last name",
						}));
					}
					else {
						setValidationErrors((prev) => ({
							...prev,
							lastName: "",
						}));
					}
				}
				break;
			case "email":
				if (!value || !isValidEmail(value)) {
					setValidationErrors((prev) => ({
						...prev,
						email: "Valid email is required",
					}));
				} else {
					setValidationErrors((prev) => ({
						...prev,
						email: "",
					}));
				}
				break;
			case "phoneNumber":
				if (name === 'phoneNumber') {
					const reg = /^\d{8,15}$/;

					setValidationErrors((prev) => ({
						...prev,
						phoneNumber: !reg.test(value.split(" ").join(""))
							|| value.split(" ").length > 2
							|| (value.split(" ").length == 2 && !(value.split(" ").length == 2 && (value.split(" ")?.[0].length == 4 || value.split(" ")?.[0].length == 5)))
							|| countZeroReg.test(value)
							? "Please enter valid phone number"
							: "",
					}));
				} else {
					setValidationErrors((prev) => ({
						...prev,
						phoneNumber: "",
					}));
				}
				break;

			case "title":
				if (!value) {
					setValidationErrors((prev) => ({
						...prev,
						title: "Title is required",
					}));
				} else {
					setValidationErrors((prev) => ({
						...prev,
						title: "",
					}));
				}

			// case "gender":
			//   if (!value) {
			//     setValidationErrors((prev) => ({
			//       ...prev,
			//       gender: "Gender is required",
			//     }));
			//   } else {
			//     setValidationErrors((prev) => ({
			//       ...prev,
			//       gender: "",
			//     }));
			//   }

			default:
				break;
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		const trimmedValue = value.trim()
		setFormInvalid(false);
		setBasicInfoData((prev) => ({
			...prev,
			[name]: trimmedValue,
		}));
		setContactDetailsData((prev) => ({
			...prev,
			[name]: trimmedValue,
		}));
		validateInput(name, trimmedValue);
	}

	const handleSaveOnClick = () => {
		alert("save");
	};

	//console.log("kin data",nextOfKinData)
	const handleHideAddClient = () => {
		// setIsOpen(false);
		setIsNoteModal(true);
	};
	const handleAddCloseClient = () => {
		// setIsOpen(true)
		setIsNoteModal(false)
	};

	//Google PlacePicker
	const googleRef = useRef(null);

	const handleFilePicker = () => {
		if (fileInputRef.current !== null) {
			fileInputRef.current.click()
		}
	}

	const handleProfilePicker = () => {
		if (profilePicInputRef.current !== null) {
			profilePicInputRef.current.click()
		}
	}


	const handleProfileImageUpload = (event) => {
		const file = event.target.files[0]

		if (file) {
			const imageUrl = URL.createObjectURL(file);
			setProfileImageData((prevData) => ({
				...prevData,
				["profilePreviewUrl"]: imageUrl,
				["isProfilePicPresent"]: true,
				["profilePreviewTitle"]: file?.name,
				["profileImage"]: file
			}))
		}
		console.log("File", file)
		// setProfileFileData(file)
	}

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				1280,
				720,
				file.type,
				100,
				0,
				(uri) => {
					resolve(uri);
					console.log("uri", uri)
				},
				"file"
			);
		});

	const handleFileUpload = async (event) => {
		const selectedFiles = event.target.files;

		// Check if any file is selected
		if (!selectedFiles || selectedFiles.length === 0) {
			// User canceled file selection
			return;
		}

		const file = event.target.files[0]
		// const newFiles = [...files];
		const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"]

		if (!allowedFileTypes.includes(file.type)) {
			showToast("error", "Only Selected Valid Document", "", false, undefined, undefined, { toastId: 'invalid-file-type-error-toast' })
			return

		}
		const image = await resizeFile(selectedFiles[0]);
		console.log("imagecompress", selectedFiles, image)
		initiateCreateIdentity({
			clientId: parseInt(clientId),
			identityDocuments: [image]
		}, (res) => {
			if (res?.success) {

				setFileExist(true)
				res?.data?.documents?.map((item) => {
					setSingleFile({
						clientId: parseInt(item?.clientId),
						documentId: item?.documentId,
						documentUrl: item?.documentUrl,
						documentName: item?.documentName,
						time: item?.time
					})
				})
				if (clientDetailsQueryData?.loading) {
					setCommonLoder(false)
					initGetClientDetails({
						clientId: parseInt(clientId)
					})
					dispatch(updateClientIdentityStatus(true))
					dispatch({
						type: CLEAR_CLIENT_DETAILS,
					})

				}
				console.log("client details data", clientDetailsQueryData)
				showToast("success", res?.message, "", false)
			} else {
				showToast("error", res?.message, "", false)
				setFileExist(false)
			}
			console.log("identity res", res)
		}, (err) => {
			setFileExist(false)
			console.log("identity error", err)

		})
	}

	//for countrySelection
	const CountrySelect = ({ value, onChange, selectedLabel, labels, ...rest }) => (
		<div className="country-selection-container">
			<select
				{...rest}
				value={value}
				onChange={event => onChange(event.target.value || undefined)}>
				<option value="">
					{labels['ZZ']}

				</option>
				{getCountries().map((country, i) => (
					country !== 'International' &&
					<option key={country} value={country}>
						{`+${getCountryCallingCode(country)}`}
					</option>


				))}
			</select>
			<span className="selected-label">{selectedLabel}</span>
		</div>
	)

	CountrySelect.propTypes = {
		value: PropTypes.string,
		onChange: PropTypes.func.isRequired,
		labels: PropTypes.objectOf(PropTypes.string).isRequired,
		selectedLabel: PropTypes.string,
	}

	//Text input for country phonenumber
	const CustomInput = React.forwardRef(({ value, onChange, ...rest }, ref) => {
		const inputRef = useRef(null);

		useEffect(() => {
			// Set the focus to the input element whenever the value changes
			// inputRef.current.focus();
		}, [value]);
		// Ensure that onChange is called with the event
		const handleChange = (newValue, event) => {
			if (newValue !== value && (newValue || value)) {
				onChange(newValue, event);
				setContactDetailsData((prev) => ({
					...prev,
					phoneNumber: newValue.target.value,
				}))
			}
		};

		return (
			<TextInput
				value={value}
				ref={(input) => {
					ref(input);
					inputRef.current = input;
				}}
				onChange={handleChange}
				{...rest}
			/>
		);
	});
	const [selectedGender, setSelectedGender] = useState();

	const handleGenderChange = (e) => {
		setSelectedGender(e.target.value);
		validateInput("gender", e.target.value)
	};

	const handleBack = () => {
		// navigate(getSearchClientRoute())
		if (locationState?.state?.fromAppointment)
			navigate(-1)
		if (clientId) {
			navigate(getClientProfileRoute(clientId))
		}
		else {
			navigate(getSearchClientRoute())
		}
	}

	//Address
	const handleLocationChange = (e) => {
		setLocation({
			...location,
			[e.target.name]: e.target.value
		})
	}

	//Goto Medical Details Page
	const redirectMedicalDetails = () => {
		window.open(`${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`, "_blank")
	}

	// useEffect(()=>{
	//   if(clientDetailsQueryData?.loading){
	//     setCommonLoder(true)
	//   }else if(identityQueryData?.loading){
	//     setCommonLoder(false)
	//   }
	//   else{
	//     setCommonLoder(false)
	//   }

	// },[clientDetailsQueryData,identityQueryData])

	const titleArray = ["Mr", "Mrs", "Miss", "Ms", "Mx"]

	const customStyle = {

		menuPortal: base => ({
			...base,
			zIndex: 9999 // Adjust the z-index value according to your requirement
		}),
		control: (styles, state) => ({
			...styles, backgroundColor: '#FFFFFF',
			// border: state.isFocused ? 0 : 0,
			'&:hover': {
				boxShadow: 'none'
			},
			boxShadow: 'none',
			outline: "none",
			appearance: "none",
			padding: "10px 10px",
			borderRadius: "10px",
			border: "1px solid #878787",
			height: "55px"
		}),
		option: (styles, { isDisabled, isFocused, isSelected }) => {

			return {
				...styles,
				// border: isFocused ? 'none' : 'none'
			};
		},
	};

	const handleTitleChange = (value) => {
		setBasicInfoData((prevData) => ({
			...prevData,
			title: value
		}));
		validateInput("title", value)
	}

	const handleToggleChange = () => {
		setContactDetailsData((prevData) => ({
			...prevData,
			["doNotSendMarketingToggle"]: !prevData["doNotSendMarketingToggle"],
		}))
	}

	const handleDeleteProfile = () => {
		console.log("click")

		if (clientId) {
			initiateDeleteClientProfile({
				clientId: Number(clientId)
			}, (res) => {
				console.log("delete resposeclient", res)
				if (res.success) {
					showToast("success", res.message, "", "")
				}
				setIsDeleteProfileConfirm(false)
				setIsProfilePreview(false)
				setProfileImageData({
					profilePreviewUrl: "",
					isProfilePicPresent: false,
					profilePreviewTitle: ""
				})
			}, (err) => {
				setIsDeleteProfileConfirm(false)
				setIsProfilePreview(false)
				setProfileImageData({
					profilePreviewUrl: "",
					isProfilePicPresent: false,
					profilePreviewTitle: ""
				})
			})
		}
		else {
			setIsDeleteProfileConfirm(false)
			setIsProfilePreview(false)
			setProfileImageData({
				profilePreviewUrl: "",
				isProfilePicPresent: false,
				profilePreviewTitle: ""
			})
		}

	}

	const handleTrackGoogleAPI = async (callType) => {
		try {
			const currentUrl = window.location.href;
			const userId = UserProfileData?.user?.usr_id || 0;

			// No need to manually parse JSON; Axios handles it
			const data = await trackGoogleAPI(userId, currentUrl, callType);

			console.log("trackdata", data);
		} catch (error) {
			console.error('Error:', error.message || error);
		}
	};

	// // Debounced version of the handleTrackGoogleAPI function
	// const debouncedHandleTrackGoogleAPI = debounce((callType) => handleTrackGoogleAPI(callType), 300); // 300 ms delay to match Google Autocomplete

	// useEffect(() => {
	//   if (searchLocation != null) {
	//     // Call the debounced API function with the appropriate type
	//     if (updateTrackType !== null) {
	//       debouncedHandleTrackGoogleAPI(updateTrackType);
	//     }

	//     // Cleanup function to cancel any pending debounced calls
	//     return () => {
	//       debouncedHandleTrackGoogleAPI.cancel();
	//     };
	//   }
	// }, [searchLocation, updateTrackType]);

	console.log("birthday", dobValue)
	return (
		commonLoder && clientId !== undefined ?
			<div className="ClientDetailsSkeletonLoader">
				<SkeletonLoader type={"edit-client"} />
			</div>
			:
			<div className="Main-addclient-container">
				<div class="CustomeNewClientMain custom-add-client-container">
					<div className="sticky-header">
						{fromOffCanvas && <div className="close-btn">
							<CloseIcon className='close-icon-animation' ref={imgRef} onClick={() => { setIsClose(false); isHide(false); }} />
						</div>}
					</div>
					{!fromOffCanvas && <NavHeader btnLabel={"Save"} disabled={formInvalid || createClientMutationData?.loading} isLoading={createClientMutationData?.loading} applyPadding={false} isBackWithButton={true} handleOnBack={() => handleBack()} onClickCallback={() => handleClientAdd()} />}
					<div className="new-add-client-container w-100">
						<div className={`container ${clientId !== undefined ? "w-100 custom-container-client" : fromOffCanvas ? "w-100 mt-5" : "custom-sidebar-width-30 custom-margin-120"}`} style={clientId !== undefined ? {} : {}}>
							<div className={`row justify-content-between align-items-start`}>
								<div className={`${clientId !== undefined ? "col-lg-6 col-md-6 vh-100-box-bar" : "col-lg-12 col-md-12"}`}>
									<div className="left-content custom-padding-120">
										{fromOffCanvas ? <h1>New client</h1> : clientId !== undefined ? <h1>Edit client</h1> : <h1>New client</h1>}
										<div className="title-with-content">
											<h1>Basic info</h1>
											<hr />
											{/* {basic info start} */}
											<div className={`d-flex flex-column ${validationErrors ? "gap-2" : "gap-4"} error-section`}>

												<div className="client-profile-image-container">
													<div className="client-profile-image" onClick={() => {
														profileImageData.isProfilePicPresent ? setIsProfilePreview(true)
															:
															handleProfilePicker()
													}}>
														{((!profileImageData?.profilePreviewUrl) && (!basicInfoData?.firstName && !basicInfoData?.lastName)) && <Avatar
															src={ProfileIcon}
															className="avtar-overview-profile" maxInitials={2} color="#EEEFF3" name={""} size="80" round={true} />}
														{((basicInfoData?.firstName || basicInfoData?.lastName) && !profileImageData?.profilePreviewUrl) && <Avatar
															src={profileImageData?.profilePreviewUrl}
															className="avtar-overview-profile" maxInitials={2} color="#EEEFF3" name={`${basicInfoData?.firstName ? basicInfoData?.firstName : ""} ${basicInfoData?.lastName ? basicInfoData?.lastName : ""}`} size="80" round={true} />}
														{profileImageData?.profilePreviewUrl && <Avatar
															src={profileImageData?.profilePreviewUrl}
															className="avtar-overview-profile" maxInitials={2} color="#EEEFF3" name={""} size="80" round={true} />}

														{profileImageData?.profilePreviewUrl && <div className="delete-client-image-profile" onClick={(e) => {
															e.stopPropagation(); setIsDeleteProfileConfirm(true); setIsDeleteIconClick(true)
														}}>
															<img src={DeleteActionIcon} />
														</div>}
														<p className="upload-text">Upload new picture</p>
													</div>
												</div>

												<div className="client-title">
													<CustomDropdown
														labelName={"Title"}
														defaultValue={basicInfoData?.title}
														dropdownId={"title"}
														placeholdername={"Title"}
														optionsArr={titleArray}
														style={customStyle}
														handleSelectedValue={handleTitleChange}
													/>
													{validationErrors.title && <span className="error">{validationErrors.title}</span>}
												</div>
												<TextInput
													id="firstName"
													label="First name"
													placeholder="First name"
													name="firstName"
													value={basicInfoData?.firstName}
													onChange={(e) => handleChange(e)}
												/>
												{validationErrors.firstName && <span className="error">{validationErrors.firstName}</span>}
												<TextInput
													label="Last name"
													placeholder="Last name"
													name="lastName"
													value={basicInfoData?.lastName}
													onChange={(e) => handleChange(e)}
												/>
												{validationErrors.lastName && <span className="error">{validationErrors.lastName}</span>}
											</div>

											<div className="d-flex justify-content-center align-items-center m-0">
												<div className="row justify-content-between m-0 w-100 ">
													<div className="title-with-content">
														<label className="form-label"> Gender </label>
													</div>
													<div className="main-gender-container px-0">

														<div className="col-md-4 d-flex   justify-content-center align-items-center gender-container">
															<div className="d-flex flex-column align-items-center gender-radio">
																<CustomRadioButton type="radio" name="gender" value="Male" isblacked={true} onChange={handleGenderChange} checked={selectedGender === "Male"} />
																<p>Male</p>
															</div>
														</div>
														<div className="col-md-4 d-flex  justify-content-center align-items-center gender-container">
															<div className="d-flex flex-column align-items-center gender-radio">
																<CustomRadioButton type="radio" name="gender" value="Female" isblacked={true} onChange={handleGenderChange} checked={selectedGender === "Female"} />
																<p>Female</p>
															</div>
														</div>
														<div className="col-md-4 d-flex  justify-content-center align-items-center gender-container">
															<div className="d-flex flex-column align-items-center gender-radio">
																<CustomRadioButton type="radio" name="gender" value="Other" isblacked={true} onChange={handleGenderChange} checked={selectedGender === "Other"} />
																<p>Other</p>
															</div>
														</div>
													</div>
												</div>
											</div>
											{validationErrors.gender && <span className="error mt-2">{validationErrors.gender}</span>}


											<div className="d-flex justify-content-between align-items-center mt-4">
												<div className="row w-100 m-auto">
													<div className="BirthdayWrapper p-0">
														<label className="form-label">Birthday</label>

														<div className="BirthdayRow">

															<div className="col-md-4">
																<TextInput
																	label="&nbsp;"
																	placeholder="DD"
																	maxLength={"2"}
																	id={"day"}
																	name="day"
																	type="text"
																	min="1"
																	ref={dayRef}
																	max="31"
																	value={day}
																	onChange={(e) => {
																		if (e.target.value.length >= 2) {
																			document.getElementById("month").focus()
																		}
																		const inputValue = e.target.value;
																		if (inputValue === "") {
																			setDobError(true)
																		}
																		const regex = /^[0-9]{0,2}$/;
																		if (regex.test(inputValue)) {
																			setDay(inputValue);
																		}
																	}}
																/>

															</div>
															<div className="col-md-4 ">
																<TextInput
																	label='&nbsp;'
																	id={"month"}
																	placeholder="MM"
																	name="month"
																	min="1"
																	max="12"
																	maxLength="2"
																	type="text"
																	ref={monthRef}
																	value={month}
																	onKeyDown={(e) => {
																		if (!e.target.value && e.key === "Backspace") {
																			document.getElementById("day").focus()
																		}
																	}}
																	onChange={(e) => {
																		if (e.target.value >= 2) { document.getElementById("year").focus() };
																		const inpValue = e?.target?.value;
																		if (inpValue == "") {
																			setDobError(true);
																		}
																		const regex = /^[0-9]{0,2}$/;
																		if (regex.test(inpValue)) {
																			setMonth(inpValue);
																		}
																	}}
																/>
															</div>
															<div className="col-md-4">
																<TextInput
																	label="&nbsp;"
																	placeholder="YYYY"
																	maxLength={4}
																	ref={yearRef}
																	type="text"
																	name="year"
																	id={"year"}
																	min="1900"
																	max="2024"
																	value={year}
																	onKeyDown={(e) => {
																		if (!e.target.value && e.key === "Backspace") {
																			document.getElementById("month").focus()
																		}
																	}}
																	onChange={(e) => {
																		console.log("key pressed", e.target.value); const inputValue = e?.target?.value;; const regex = /^[0-9]{0,4}$/;
																		if (inputValue == "") {
																			setDobError(true);
																		}
																		if (regex.test(inputValue) || inputValue == "") {
																			setYear(inputValue);
																		}
																	}}
																/>
															</div>
															{dobError && <span className="error">{"Invalid DOB"}</span>}
														</div>

													</div>

												</div>
											</div>

											{/* {basic info end} */}

											<div className="title-with-content">
												<h1>Contact details</h1>
												<hr />
												<div className={`d-flex flex-column ${validationErrors ? "gap-2" : "gap-4"} error-section`}>
													<TextInput
														label="Email address"
														placeholder="name@gmail.com"
														value={contactDetailsData?.email}
														onChange={(e) => handleChange(e)}
														name="email"
													/>
													{validationErrors.email && <span className="error">{validationErrors.email}</span>}
												</div>
												<div className="row email-section mt-4">
													<p className="m-0">Your phone number</p>
													<div className="col-5 col-sm-4 mt-2 ps-0 ps-md-3">
														<CountrySelect labels={{ 'ZZ': 'Select Country' }} value={countryCode} onChange={(e) => handleCountryChange(e)} />
													</div>
													<div className="col-7 col-sm-8 mt-2 pe-0 pe-md-3">
														<TextInput
															name="phoneNumber"
															value={contactDetailsData?.phoneNumber}
															onChange={(e) => handleChange(e)}
															placeholder="Enter phone number"
														/>
														{validationErrors.phoneNumber && <span className="error">{validationErrors.phoneNumber}</span>}
													</div>
												</div>
												<div className="mt-3 mt-sm-4 location-container pb-3">
													<div className="location-icon">
														<img src={locationIcon} alt="location-icon" />
													</div>
													<p className="m-0">Address</p>
													<Autocomplete
														icon={false}
														apiKey={googleApiKey}
														defaultValue={searchLocation}
														onPlaceSelected={(place) => {
															setSearchLocation(place.formatted_address);
															const location = place.address_components.reduce(
																(seed, { long_name, types }) => (
																	types.forEach((t) => (seed[t] = long_name)), seed
																),
																{}
															);
															setLocation({
																...location,
																street: location.route,
																address1: place.address_components[0].long_name,
																address2: place.address_components[1].long_name,
																postcode:
																	location.postal_code === undefined
																		? ""
																		: location.postal_code,
																city:
																	location.locality === undefined
																		? location.postal_town === undefined
																			? ""
																			: location.postal_town
																		: location.locality,
																country: location.country,
																state:
																	location.administrative_area_level_1 === undefined
																		? location.administrative_area_level_2
																		: location.administrative_area_level_1,
																latitude: place.geometry.location.lat(),
																longitude: place.geometry.location.lng(),
															});
															console.log(place, "i am getplace data");
															handleTrackGoogleAPI(2);
															// setUpdateTrackType(2)
														}}
														placeholder="Search for postcode or address..."
														className={"form-control"}
														value={searchLocation}
														onChange={(e) => {
															setSearchLocation(e.target.value);
															// Call the debounced tracking API with callType 1 on input change
															// setUpdateTrackType(1)
															handleTrackGoogleAPI(1);
														}}
														options={{ types: ["establishment"], componentRestrictions: { country: "uk" }, }}
													/>
													<div className="w-100">
														<h1 onClick={() => { setShowManualAddress(true); setIsMenualAddress(1); setEnterAddress(true) }}>Add manually</h1>
													</div>
												</div>
												<div className="marketingContentWrapper">
													<div className="do-not-send-text-section">
														<p>Do not send marketing communications</p>
													</div>
													<div className="do-not-send-toggle">
														<SwitchButton
															onChange={(e) =>
																handleToggleChange()
															}
															isChecked={contactDetailsData.doNotSendMarketingToggle}
														/>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
								{clientId !== undefined &&
									(
										<div className="col-lg-5 col-md-5 custom-position-sticky">
											<div className="right-content">
												{/* <h1>&nbsp;</h1> */}
												<div className="title-with-content">
													<h1>Personal info</h1>
													<hr />
													{/* clientId !== undefined to fincen */}
													<div className="d-flex  treatment-main-action justify-content-between align-items-center gap-3 mt-4" onClick={() => setIsNextOfKin(true)}>
														<div className="treatment-actions-content d-flex gap-2">
															<div className="compliance-img-container">
																<img src={personIcon} alt="" />
																<img
																	className="time-icon"
																	src={
																		clientNextOfKinStatus
																			? timeIcon2
																			: timeIcon
																	}
																/>
															</div>
															<div className="tile-text-section">
																<span className="my-auto">Next of kin</span>
															</div>
														</div>
														<RightArrow />
													</div>
													{/* Identity Tiles */}
													<div className="d-flex  justify-content-between treatment-main-action align-items-center gap-3 mt-4" onClick={() => fileExist ? setIsPreview(true) : handleFilePicker()}>
														<div className="treatment-actions-content d-flex gap-2">
															<div className="compliance-img-container">
																<img

																	src={identityIcon}
																	alt=""
																/>
																{
																	createIdentityMutationData?.loading ?
																		<img style={{ borderRadius: "50%" }} className="time-icon" width={20} src={loaderIcon} />
																		:
																		<img
																			className="time-icon"
																			src={
																				singleFile
																					? timeIcon2
																					: timeIcon
																			}
																		/>
																}
															</div>
															<div className="tile-text-section">
																<span className="my-auto">Identity</span>
															</div>
														</div>
														<RightArrow />
													</div>
													{/* Medical Tiles */}
													<div className="d-flex  justify-content-between treatment-main-action align-items-center gap-3 mt-4" onClick={() => redirectMedicalDetails()}>
														<div className="treatment-actions-content d-flex gap-2">
															<div className="compliance-img-container">
																<img


																	src={medicalIcon}
																	alt=""
																/>
																<img
																	className="time-icon"
																	src={
																		clientMedicalDetails
																			? timeIcon2
																			: timeIcon
																	}
																/>
															</div>
															<div className="tile-text-section">
																<span className="my-auto">Medical details</span>
															</div>
														</div>
														<RightArrow />
													</div>
													{/* Notes Tiles */}
													<div
														className="d-flex  justify-content-between treatment-main-action align-items-center gap-3 mt-4"
														onClick={() => handleHideAddClient()}
													>
														<div className="treatment-actions-content d-flex gap-2">
															<div className="compliance-img-container">
																<img src={noteIcon} alt="" />
																<img
																	className="time-icon"
																	src={
																		clientNoteStatus
																			? timeIcon2
																			: timeIcon
																	}
																/>
															</div>
															<div className="tile-text-section">
																<span className="my-auto">Notes</span>
															</div>
														</div>
														<RightArrow />
													</div>
												</div>
											</div>
										</div>
									)
								}
							</div>

						</div>

						{
							fromOffCanvas && <div className="done-btn">
								<StepButton blue={true} isLoading={createClientMutationData?.loading} label={"Done"} onClick={() => handleClientAdd()} />
							</div>
						}
					</div>

					<CustomModal
						modalOpen={isNoteModal}
						setModalOpen={setIsNoteModal}
						modaltitle={"Add a note"}
						modaltitleCSS={"discount-title"}
						type={"common-width"}
						className={'CustomeAddNoteModel'}
						modalBody={
							<AddNotes notes={notes} setNotes={setNotes} setIsNoteAdded={setIsNoteAdded} setIsNoteModal={setIsNoteModal} clientId={parseInt(clientId)} />
						}
						setIsOpenMobileCalendar={handleAddCloseClient}
					/>
					<OffCanvasModal
						isOpen={isNextOfKin}
						className={fromOffCanvas ? "w-75" : 'CustomeNextOfKin'}
						isBack={true}
						placement={fromOffCanvas ? "end" : "end"}
						isCloseRight={true}
						onHide={() => setIsNextOfKin(false)}
						body={
							<NextOfKin fromOffCanvas={fromOffCanvas} setIsNextOfKinAdded={setIsNextOfKinAdded} clientDetailsQueryData={commonLoder} clientId={clientId} setNextOfKinData={setNextOfKinData} nextOfKinData={nextOfKinData} setIsNextOfKin={setIsNextOfKin} />
						}
					/>
					<OffCanvasModal
						isOpen={showManualAddress}
						isBack={true}
						isCloseRight={true}
						placement={"end"}
						className={fromOffCanvas ? "custom-sidebar-width-30 CustomeAddClientCanvas" : "custom-sidebar-width-25 CustomeAddClientCanvas"}
						onHide={() => setShowManualAddress(false)}
						body={<>
							<div className="manual-add-container container">
								<h1>Address</h1>
								<div className="address-field-container">
									<TextInput
										name="address1"
										label="House No"
										placeholder="House No"
										value={location?.address1}
										onChange={handleLocationChange}
										pattern="/^[A-Za-z]+$/"

									/>
									<TextInput
										name="address2"
										label="Street Name"
										placeholder="Street Name"
										value={location?.address2}
										onChange={handleLocationChange}

									/>
									<TextInput
										name="city"
										label="City"
										placeholder="City"
										value={location?.city}
										onChange={handleLocationChange}

									/>
									<TextInput
										name="postcode"
										label="Postcode"
										placeholder="Postcode"
										pattern={alphaNumericRegexAp.test(location?.postcode)}
										value={location?.postcode}
										onChange={handleLocationChange}

									/>
									<StepButton label={"Done"} blue={true} onClick={() => { handleMenualAddress() }} disabled={
										location?.address1?.length >= 1 &&
											location?.address2?.length >= 1 &&
											location?.city?.length >= 1 &&
											location?.postcode?.length >= 1

											?
											false
											: true
									} />
								</div>
							</div>
						</>}

					/>
					{console.log("menual address", location)}
					{/* For Identity preview Modal */}
					<CustomModal
						type={"common-width"}
						className={"previewImgModel"}
						modalBody={<div className="preview-document-modal">
							<h1>{singleFile?.documentName?.slice(-25)}</h1>
							<img src={singleFile?.documentUrl} alt="preview" style={{ objectFit: "contain" }} />
							<div className="delete-preview-button">  <StepButton label={"Delete"} isLoading={deleteIdentityMutationData?.loading} redWithBorder={true} onClick={() => {
								// handleDeleteIdentity(singleFile?.documentId, clientId)
								setIsPreview(false)
								setIsDeleteIdentity(true)
							}} /></div>
						</div>}
						setModalOpen={setIsPreview}
						modalOpen={isPreview}
						setIsOpenMobileCalendar={setIsPreview}

					/>
					{/* delete identity confirmation popup */}
					<CustomModal
						type={"common-width"}
						className={"ConfirmationDeleteModal"}
						modalBody={
							<>
								<div className="delete-modal-body">
									<h1>Are you sure?</h1>
									<p>you want to delete this identity? This action cannot be reversed.</p>
									<div className="w-100">
										<div className="delete-button-modal">
											<StepButton red={true} isLoading={deleteIdentityMutationData?.loading} disabled={deleteIdentityMutationData?.loading} label={"Delete"} onClick={() => handleDeleteIdentity(singleFile?.documentId, clientId)} />
										</div>
										<div className="cancle-button-modal">
											<StepButton gray={true} onClick={() => {
												setIsDeleteIdentity(false)
												setIsPreview(true)
											}
											} label={"Cancel"} />
										</div>
									</div>
								</div>
							</>
						}
						modalOpen={isDeleteIdentity}
						setModalOpen={setIsDeleteIdentity}
						setIsOpenMobileCalendar={setIsDeleteIdentity}
					/>

					{/* profile picture preview model */}
					<CustomModal
						type={"common-width"}
						className={"previewImgModel"}
						modalBody={<div className="preview-document-modal">
							<h1>{profileImageData?.profilePreviewTitle}</h1>
							<img src={profileImageData?.profilePreviewUrl} alt="preview" style={{ objectFit: "contain" }} />
							<div className="delete-preview-button">  <StepButton label={"Delete"} redWithBorder={true} onClick={() => {
								// handleDeleteIdentity(singleFile?.documentId, clientId)
								// setIsProfilePreview(false)
								setIsDeleteProfileConfirm(true)
								setIsDeleteIconClick(false)
							}} /></div>
						</div>}
						setModalOpen={setIsProfilePreview}
						modalOpen={isProfilePreview}
						setIsOpenMobileCalendar={setIsProfilePreview}
					/>
					{/* profile delete confirmation model */}
					<CustomModal
						type={"common-width"}
						className={"ConfirmationDeleteModal"}
						modalBody={
							<>
								<div className="delete-modal-body">
									<h1>Are you sure?</h1>
									<p>you want to delete this Profile Image? This action cannot be reversed.</p>
									<div className="w-100">
										<div className="delete-button-modal">
											<StepButton red={true} label={"Delete"} onClick={handleDeleteProfile} />
										</div>
										<div className="cancle-button-modal">
											<StepButton gray={true} onClick={() => {
												setIsDeleteProfileConfirm(false)
												if (isDeleteIconClick) {
													setIsProfilePreview(false)
												}
												else {
													setIsProfilePreview(true)
												}
											}
											} label={"Cancel"} />
										</div>
									</div>
								</div>
							</>
						}
						modalOpen={isDeleteProfileConfirm}
						setModalOpen={setIsDeleteProfileConfirm}
						setIsOpenMobileCalendar={setIsDeleteProfileConfirm}
					/>

					<PatientDobWarningModal onAgreeWarning={() => {
						initSaveClient();
					}} ref={patientDobModalRef} isForPractitioner />

					<input
						ref={fileInputRef}
						type="file"
						accept=".pdf, .doc, .docx, .jpg, .jpeg, .png" // Add acceptable file formats
						onChange={handleFileUpload}
						style={{ display: "none" }} // Hide the default file input style
						id="fileInput" // Use a label with htmlFor to trigger file input
					/>

					<input
						ref={profilePicInputRef}
						type="file"
						accept=".jpg, .jpeg, .png, .svg" // Add acceptable file formats
						onChange={handleProfileImageUpload}
						style={{ display: "none" }} // Hide the default file input style
						id="profilePicInputRef" // Use a label with htmlFor to trigger file input
					/>
				</div>
			</div>
	);
};

export default AddClient;