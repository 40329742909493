import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_CONSENT_FORM_LINK } from "../../constants/downloadConsent/downloadConsentFormConst";

const DOWNLOAD_CONSENT_PDF_QUERY = gql`
query GetConsentFormRecordPdfUrl($consentFormRecordId: Int!) {
    getConsentFormRecordPdfUrl(consentFormRecordId: $consentFormRecordId) {
      success
      message
      data {
        consentFormRecordId
        consentFormRecordPdfUrl
      }
    }
  }
`;

var page;
export const GetConsentFormPdfUrlQuery = () => {
  const dispatch = useDispatch();

  const [getConsentFormPdfUrl, consentFormPdfUrlData] = useLazyQuery(
    DOWNLOAD_CONSENT_PDF_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getConsentFormRecordPdfUrl: responseData }) => {
          if(responseData.success){
            dispatch({
              type: GET_CONSENT_FORM_LINK,
              payload: responseData.data, page
            });
          }
      },
    }
  );
  const consentFormPdfUrlQueryData = consentFormPdfUrlData;
  const initConsentFormPdfUrl = (data,successCallback,errorCallback) => {
    page = data?.page ? data?.page : 1
    getConsentFormPdfUrl({
      variables: data,
    }).then((res)=>{
        successCallback(res)
    }).catch((err)=>{
        errorCallback(err)
    });
  };
  return { initConsentFormPdfUrl, consentFormPdfUrlQueryData };
};

