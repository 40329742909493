import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_APPOINTMENT_FOLLOW_UPS } from "../../constants/appointment/appointmentConst";


const GET_APPOITMENT_FOLLOW_UPS_QUERY = gql`
query GetAppointmentFollowupDetails($appointmentId: Int) {
  getAppointmentFollowupDetails(appointmentId: $appointmentId) {
    success
    message
    data {
      followup
      followupTpye
    }
  }
}
`;

var page;
export const GetAppointmentFollowUpsQuery = () => {
  const dispatch = useDispatch();
  const [getAppointmentFollowUpsData, appointmentFollowUpsData] = useLazyQuery(
    GET_APPOITMENT_FOLLOW_UPS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getAppointmentFollowupDetails: responseData }) => {
        if (responseData.success) {
          // dispatch({
          //   type: GET_APPOINTMENT_FOLLOW_UPS,
          //   payload: responseData.data,
          //   page,
          // });
        } else {
        }
      },
    }
  );
  const appointmentFollowUpsQueryData = appointmentFollowUpsData;
  const initGetAppointmentFollowUps = (data) => {
    page = data?.page ? data?.page : 1;
    getAppointmentFollowUpsData({
      variables: data,
    })
  };
  return { initGetAppointmentFollowUps, appointmentFollowUpsQueryData };
};
