import { CLEAR_PHARMACY_LIST, GET_PHARMACY_LIST } from "../../constants/consentFormThankyou/pharmacyListConst"


const initialState = {
    pharmacyList: [],
    emptyPharmacyList: false
}

export const pharmacyListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PHARMACY_LIST:
            return {
                ...state,
                pharmacyList: action.payload.items,
                emptyPharmacyList:action?.payload?.items?.length === 0 || action.payload == null ? true : false
            }
        case CLEAR_PHARMACY_LIST:
            return {
                ...state,
                pharmacyList: [],
                emptyPharmacyList: false
            }
        default:
            return state
    }
}