export const GET_PROFILE_DATA = 'GET_PROFILE_DATA'
export const GET_PROFILE_DATA_SUCCESS = 'GET_PROFILE_DATA_SUCCESS'
export const GET_DASHBOARD_USER_PROFILE_DATA = 'GET_DASHBOARD_USER_PROFILE_DATA';


export const LOGOUT_PROFILE = 'LOGOUT_PROFILE'
export const LOGOUT_PROFILE_SUCCESS = 'GET_PROFILE_DATA_SUCCESS'

export const GET_USER_PROFILE_DATA = 'GET_USER_PROFILE_DATA'
export const SAVE_CALENDAR_VIEW_FOR_USER_PROFILE_DATA = 'SAVE_CALENDAR_VIEW_FOR_USER_PROFILE_DATA'
