import React from 'react';
import './custom-option.scss'

const CustomOption = ({ logoUrl, label }) => {
    return <div className="custom-option">
        <img src={logoUrl} alt={label} className="option-image" />
        {label}
    </div>
};

export default CustomOption;