import { CLEAR_APPOINTMENT_LIST_BY_TYPE, GET_APPOINTMENT_LIST_BY_TYPE } from "../../constants/appointment/appointmentConst";



const initialState = {
  appointmentListByType: [],
  totalAppointmentCount: 0,
  loadedAppointmentCount: 0,
  appointmentListPage: 0,
  emptyAppointmentList: false
}

export default function appointmentListByTypeReducer(state = initialState, action) {

  switch (action.type) {

    case GET_APPOINTMENT_LIST_BY_TYPE:
      const temp = (state?.appointmentListByType).concat(action?.payload?.appointment_list)
      return {
        ...state,
        appointmentListByType: temp,
        totalAppointmentCount: action.payload?.total_appointments,
        appointmentListPage: state.appointmentListPage + 1,
        loadedAppointmentCount: temp.length
      };

    case CLEAR_APPOINTMENT_LIST_BY_TYPE:

      return {
        ...state,
        appointmentListByType: [],
        totalAppointmentCount: 0,
        appointmentListPage: 0,
        loadedAppointmentCount: 0
      }

    default:
      return state;
  }



}