// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { GET_REVIEWS_DATA_ERROR, GET_REVIEWS_DATA_SUCCESS } from "../../constants";

const GET_BUSINESS_REVIEWS_QUERY = gql`
  query GetBusinessReviews($page: Int, $limit: Int, $ratings: [Int]) {
    getBusinessReviews(page: $page, limit: $limit, ratings: $ratings) {
      success
      message
      data {
        total
        elementList {
          cln_rvw_id
          cln_rvw_usr_id
          cln_rvw_cln_id
          cln_rvw_ref_id
          cln_rvw_cfr_id
          cln_rvw_title
          cln_rvw_message
          cln_rvw_rating
          cln_rvw_cl_dt_id
          cln_rvw_reply
          cln_rvw_replied_on
          createdAt
          updatedAt
          deleted_at
          review_treatments {
            cln_treat_id
            cln_treat_name
            enc_id
            image_url
          }
          review_date
          client {
            cl_dt_id
            cl_dt_name
            cl_dt_profile
            additional_data
            client_lname
            client_fname
            usr_profile_img
            usr_profile_img_new
            readable_dob
            readable_medical_date
            formatted_phone_no
            medical_form_img
            client_id_img
            medical_signature
            medical_qas
            practitioner_signature
            prescriber_signature
            medical_form_pdf
            marketing_communications
            client_id_image
          }
          clinic {
            cln_id
            cln_name
            cln_city
            formatted_phone_no
            image_url
          }
        }
        average_review
        total_review_count
        individual_rating_count {
          rating
          count
        }
        totalPractitionersForReview
        reviewRank
        isOnboarded
      }
    }
  }
`;

export const GetBusinessReviewsAction = () => {
  const dispatch = useDispatch();
  const [
    getBusinessReviewsResponseFunction,
    getBusinessReviewsResponseData,
  ] = useLazyQuery(GET_BUSINESS_REVIEWS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getBusinessReviews: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: GET_REVIEWS_DATA_SUCCESS,
          payload: responseData,
        });
        console.log('dispatched GetBusinessReviewsAction');
        // toast.success(responseData?.message)
        // GET_ONBOARDING_STATUS_SUCCESS
      } else {
        dispatch({
          type: GET_REVIEWS_DATA_ERROR,
          payload: responseData,
        })
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getBusinessReviewsQueryData = getBusinessReviewsResponseData; //variable

  const initGetBusinessReviewsData = (data) => {
    getBusinessReviewsResponseFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.getBusinessReviews?.success) {
        console.log('res success', res);
        // successCallback(res);
      } else {
        console.log('res error', res);
        // errorCallback(res);
      }
    }).catch((err) => {
      console.log('res err', err);
      // errorCallback(err);
    });
  };

  return { getBusinessReviewsQueryData, initGetBusinessReviewsData };
};
