// ========================COMPLETE BOOKING PAYMENT========================

import { gql, useMutation } from "@apollo/client";
import { useSearchParams } from "react-router-dom";

const PURCHASE_PLUM_MARKETING_PACKAGE_MUTATION = gql`
    mutation PurchasePlumpMarketingPackage($packageId: Int, $sessionId: String, $generatePaymentIntent: Boolean, $canUpgragePlan: Boolean) {
        purchasePlumpMarketingPackage(packageId: $packageId, sessionId: $sessionId, generatePaymentIntent: $generatePaymentIntent, canUpgragePlan: $canUpgragePlan) {
          success
          message
          data {
            packageReferenceId
            intentId
            packageId
            app_payment_url
            setupIntentId
            planUpgrade
            userInfo {
              user_name
              user_email
              user_phone_number
              user_clinic_name
            }
          }
        }
      }
 `;


export const PurchasePlumMarketingAction = () => {
    // const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [
        PurchasePlumpMarketingPackageResponseFunction,
        purchasePlumpMarketingPackageResponseData,
    ] = useMutation(PURCHASE_PLUM_MARKETING_PACKAGE_MUTATION, {
        context: {
            headers: {
                ['authorization']: `${searchParams.get('usertoken')}`
            }
        },
    })

    //this will be called from components
    const purchasePlumpMarketingPackageQueryData = purchasePlumpMarketingPackageResponseData; //variable

    const initPurchasePlumpMarketingPackageData = (data, successCallback, errorCallback) => {
        PurchasePlumpMarketingPackageResponseFunction({
            variables: data,
        })
        // .then(({ data: { PurchasePlumpMarketingPackage: responseData } }) => {
        .then((responseData) => {
            console.log(responseData);
            if (responseData.data.purchasePlumpMarketingPackage.success) {
                // dispatch({
                //     type: SET_BOOKING_DETAILS,
                //     payload: responseData?.data
                // })
                successCallback(responseData)
                // toast.success(responseData.message)
            }
            else {
                errorCallback(responseData)
                // toast.error(responseData.message)
            }
        }).catch((err) => {
            errorCallback(err)
            // toast.error("something went wrong!!!")
        })
    }

    return { purchasePlumpMarketingPackageQueryData, initPurchasePlumpMarketingPackageData };
};
