import "../edit-main.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import CustomModal from "../../../modal/CustomModal";
import SignatureCanvas from "react-signature-canvas";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as UndoIcon } from "../../../../images/signature pop-icon/undo.svg";
import { AddAndEditUserConsentForm, ConsentFormPreview, UserConsentFormList } from "../../../redux/actions/walk-in-form/walkInAddFormsList";
import { ReactComponent as EnableCheck } from "../../../../images/signature pop-icon/enable_check.svg";
import { ReactComponent as EmptySignIcon } from "../../../../images/signature pop-icon/empty_sign.svg";
import { ReactComponent as UndoEnableIcon } from "../../../../images/signature pop-icon/undo_enable.svg";
import { ReactComponent as DisableCheck } from "../../../../images/signature pop-icon/disable_check.svg";
import { ReactComponent as EnableSignIcon } from "../../../../images/signature pop-icon/active_sign.svg";
import NavHeader from "../../../common/NavHeader/NavHeader";
import PreviewQuestionItem from "./PreviewQuestionItem";
import CreateNewFormPopup from "./CreateNewFormPopup";
import { useSearchParams } from "react-router-dom";
import CustomeHeading from "../../../customeHeading/customeHeading";
import { CLEAR_CONSENT_FORM_DETAILS } from "../../../redux/constants/walk-in-form-const/clientWalkInConst";
import useWindowDimension from "../../../../utils/useWindowDimensions";
import { editFormRoute } from "../../../../Routes";
import showToast from "../../../common/CustomToast/CustomToast";

const PreviewConsentForm = ({ step, setStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { consentId } = useParams();
  const [searchParams] = useSearchParams();

  const initialActiveTab = searchParams.get('tab');

  const [isSigned, setIsSigned] = useState(false);
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [isSignatureOpen, setIsSignatureOpen] = useState(false);
  const [trimmedBusniessDataURL, setTrimmedBusniessDataURL] = useState(null);
  const [isBusniessSignatureOpen, setIsBusniessSignatureOpen] = useState(false);
  const [createNewFormModal, setCreateNewFormModal] = useState(false);

  const sigPadRef = useRef();
  const clear = () => {
    sigPadRef.current.clear();
    setIsSigned(false);
  };

  const { initConsentFormPreview, consentFormPreviewQueryData } = ConsentFormPreview();
  const { initAddAndEditUserConsentFormData, addUserConsentFormQueryData } =
        AddAndEditUserConsentForm();
  // const consentFormDetails = useSelector(state => state?.walkInClientListReducer?.consentFormDetails);

  useEffect(() => {
    dispatch({ type: CLEAR_CONSENT_FORM_DETAILS });
    initConsentFormPreview({
      consentId: parseInt(consentId),
    }, () => {
      console.log('consent-form-details-success')
    }, () => {
      console.log('consent-form-details-error');
    });
  }, [consentId]);

  const handleHide = () => {
    //setIsOpen(false);
    setIsSignatureOpen(true);
  };

  const handleClientHide = () => {
    //setIsOpen(false);
    setIsBusniessSignatureOpen(true);
  };

  const handleClientSignature = () => {
    setTrimmedBusniessDataURL(
      sigPadRef.current?.getSignaturePad()?.toDataURL("image/png")
    );
  };

  const handleSignatureModalClose = () => {
    setIsSignatureOpen(false); // Close the SignatureModal
    // setIsOpen(true); // Close the NewAppointment
  };

  console.log("isSignatureOpen: ", trimmedDataURL, "isBusniessSignatureOpen: ", trimmedBusniessDataURL);

  const handleSignature = () => {
    setTrimmedDataURL(
      sigPadRef.current?.getSignaturePad()?.toDataURL("image/png")
    );
  };

  // const { initUserConsentFormList } = UserConsentFormList();

  const consentFormDetails = useSelector(state => state?.walkInClientListReducer?.consentFormDetails);
  console.log(consentFormDetails, 'consentFormDetails==');

  const handleBackToHome = () => {
    // navigate("/manage-forms");
    navigate(initialActiveTab ? `/manage-forms?tab=${initialActiveTab}` : "/manage-forms");
  };

  // const dimensions = useWindowDimension();
  // const [headerIndicatorHeight, setHeaderIndicatorHeight] = useState(0);
  // const [headerHeight, setHeaderHeight] = useState(0);
  // const [secTitleHeight, setSecTitleHeight] = useState(0);

  // console.log(headerIndicatorHeight, 'headerIndicatorHeight', headerHeight, 'headerHeight', secTitleHeight, 'secTitleHeight', )

  // const updateHeights = () => {
  //   setHeaderIndicatorHeight(
  //     document.getElementsByClassName("form-mode-indicator")[0]?.offsetHeight || 0
  //   );
  //   setHeaderHeight(
  //     document.getElementsByClassName("nav-header-without-padding-container")[0]?.offsetHeight || 0
  //   );
  //   setSecTitleHeight(
  //     document.getElementsByClassName("Section-Title")[0]?.offsetHeight || 0
  //   );
  // };

  // useEffect(() => {
  //   if(consentFormPreviewQueryData){
  //     setTimeout(()=>{
  //       updateHeights();
  //     }, 100)
  //   }
    
  //   window.addEventListener('resize', updateHeights);
  //   return () => {
  //     window.removeEventListener('resize', updateHeights);
  //   };
  // }, [consentFormPreviewQueryData]);

  // const scrollableDivHeight = dimensions?.height - headerIndicatorHeight - headerHeight - secTitleHeight + 'px';

  const createConsentFormHelper = () => {
    initAddAndEditUserConsentFormData(
        {
            createConsentFormId: parseInt(consentId),
            consentFormName: consentFormDetails?.con_frm_name,
        },
        (data) => {
            showToast('success', 'Consent form customised successfully');
            const newConsentId = data.data.createConsentForm.data.con_frm_id;
            navigate(editFormRoute(newConsentId) + '?tab=4');
        },
        (err) => {
            showToast('error', err.message);
        }
    );
}


  return (
    <div className="Main-Edit-Manage-Forms-page" >
       <div className="form-mode-indicator">
        <p>View as a client</p>
      </div>
      <NavHeader
        btnLabel={initialActiveTab === '4' ? "Edit" : "Customise form"}
        isGrayColorButton={true}
        applyPadding={false}
        isBackWithButton={true}
        isLoading={addUserConsentFormQueryData.loading}
        onClickCallback={() => {
          if(initialActiveTab === '4') {
            // from my forms tab just redirect
            navigate(editFormRoute(consentId) + '?tab=4')
          } else {
            // from consent tab create new form and then redirect
            createConsentFormHelper();
          }
        }}
        handleOnBack={handleBackToHome}
      />
      <div className="maxWidth540 paddintTop80 preview-consent-form">
        <div className="pageTitle">
          <div class="Section-Title">{consentFormDetails?.con_frm_name}</div>
        </div>
        <CustomeHeading
          Desc=''
          isSearch={false}
        />
        
      {(consentFormDetails?.length > 0 || (!Array.isArray(consentFormDetails) && consentFormDetails)) && 
        <div className="form-edit-options form-edit-options--previewConsent pb-5">

          <div className="accordion previewConsentDisclaimer" id="accordionConsentFormExample">
            <div
              className="accordion-item"
              // id="accordion-remove-border"
            >
              <h2
                className="accordion-header"
                id='preview-consent-disclaimer-heading'
              >
                <button
                  className="accordion-button collapsed previewConsentDisclaimer__title"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse-disclaimer`}
                  aria-expanded="false"
                  aria-controls={`collapse-disclaimer`}
                  >
                    Disclaimer
                </button>
              </h2>
              <div
                id={`collapse-disclaimer`}
                className="accordion-collapse collapse"
                aria-labelledby='preview-consent-disclaimer-heading'
                data-bs-parent="#accordionConsentFormExample"
              >
                <div className="accordion-body previewConsentDisclaimer__body">
                  <p className="previewConsentDisclaimer__desc">
                    {consentFormDetails?.disclaimer}
                  </p>
                </div>
            </div>
            </div>
          </div>

          <div style={{ marginBottom: '-10px' }} className="edit-consent-category edit-consent-category--questions">
            <p>Questions</p>
          </div>

          {consentFormDetails?.questions?.map((item, index) => (
            <PreviewQuestionItem noOtherOption queId={item.id} question={item.question} key={item.id} />
          ))}

          <div className="sign-right-section">
            <div className="card-section">
              <div
                style={{cursor: 'auto'}}
                className="d-flex flex-column align-items-center gap-2 sign-section"
                // onClick={handleHide}
              >
                {trimmedDataURL ? <EnableSignIcon /> : <EmptySignIcon />}
                <h1>Client signature</h1>
              </div>
            </div>
            {/* <div className="card-section">
              <div
                className="d-flex flex-column align-items-center gap-2 sign-section"
                style={{cursor: 'auto'}}
                // onClick={handleClientHide}
              >
                {trimmedBusniessDataURL ? <EnableSignIcon /> : <EmptySignIcon />}
                <h1>Business signature</h1>
              </div>
            </div> */}
          </div>
        </div>
      }

      {isSignatureOpen && (
        <CustomModal
          className={"CustomeSignModel"}
          modalOpen={isSignatureOpen}
          setIsOpenMobileCalendar={handleSignatureModalClose}
          setModalOpen={setIsSignatureOpen}
          type="common-width"
          modalBody={
            <div className="d-flex flex-column wrapper-canvas">
              <SignatureCanvas
                ref={sigPadRef}
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "signCanvas",
                }}
                throttle={null}
                onEnd={() => {
                  setIsSigned(true);
                }}
              />
              <div className="justify-content-between d-flex w-100">
                {isSigned ? (
                  <UndoEnableIcon
                    style={{ cursor: "pointer" }}
                    onClick={clear}
                  />
                ) : (
                  <UndoIcon style={{ cursor: "not-allowed" }} />
                )}
                {isSigned ? (
                  <EnableCheck
                    onClick={() => {
                      handleSignatureModalClose();
                      setIsSignatureOpen(false);
                      handleSignature();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <DisableCheck style={{ cursor: "not-allowed" }} />
                )}
              </div>
            </div>
          }
        />
      )}

      {isBusniessSignatureOpen && (
        <CustomModal
          className={"CustomeSignModel"}
          modalOpen={isBusniessSignatureOpen}
          setIsOpenMobileCalendar={handleSignatureModalClose}
          setModalOpen={setIsBusniessSignatureOpen}
          type="common-width"
          modalBody={
            <div className="d-flex flex-column wrapper-canvas">
              <SignatureCanvas
                ref={sigPadRef}
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "signCanvas",
                }}
                throttle={null}
                onEnd={() => {
                  setIsSigned(true);
                }}
              />
              <div className="justify-content-between d-flex w-100">
                {isSigned ? (
                  <UndoEnableIcon
                    style={{ cursor: "pointer" }}
                    onClick={clear}
                  />
                ) : (
                  <UndoIcon style={{ cursor: "not-allowed" }} />
                )}
                {isSigned ? (
                  <EnableCheck
                    onClick={() => {
                      handleSignatureModalClose();
                      setIsBusniessSignatureOpen(false);
                      handleClientSignature();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <DisableCheck style={{ cursor: "not-allowed" }} />
                )}
              </div>
            </div>
          }
        />
      )}

</div>


       {/* CREATE FROM BLANK FORM MODAL */}
       <CustomModal
                modalOpen={createNewFormModal}
                setModalOpen={setCreateNewFormModal}
                className={'customeNameFormModel'}
                modaltitle={'Update form name'}
                type={"common-width"}
                isCloseImage={true}
                modalBody={<CreateNewFormPopup isEditMyform formId={+consentId} />}
            />
    </div>
  );
};

export default PreviewConsentForm;

