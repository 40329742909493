import React from "react";
import CustomModal from "../../modal/CustomModal";
import "./finance-identity.scss";

const ConfirmationModal = ({ open, setOpen, onConfirm, onCancel, title, content, className }) => {
  return (
    <CustomModal
      modalOpen={open}
      setModalOpen={setOpen}
      modaltitle={title}
      className={className}
      modalBody={
        <div>
          <div className="mb-5">
            <p>{content}</p>
          </div>
          <button className="text-white border-0 w-100 mb-3 shadow-none deletebtn emailmodal" onClick={onConfirm}>
            Confirm
          </button>
          <button
            className="w-100 shadow-none cancelbtn border-0 w-100"
            onClick={() => {
              setOpen(false);
              if (onCancel) onCancel();
            }}
          >
            Cancel
          </button>
        </div>
      }
    />
  );
};

export default ConfirmationModal;