import React, { useEffect, useRef, useState } from "react";
import "./edit-client-details.scss";
import TextInput from "../../common/TextInput";
import { ReactComponent as CloseIcon } from "../../../images/close_icon.svg";
import moment from "moment";
import { googleApiKey } from "../../../config";
import Autocomplete from "react-google-autocomplete";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import PropTypes from "prop-types";
import StepButton from "../../common/StepButton";
import { useSelector } from "react-redux";
import OffCanvasModal from "../../modal/OffCanvasModal/OffCanvasModal";
import { alphaNumericRegexAp } from "../../../utils/validationRegx";
import useWindowDimension from "../../../utils/useWindowDimensions";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { el } from "date-fns/locale";
import trackGoogleAPI from "../../../utils/googleApiTrack";
import { debounce } from "lodash"; 



const EditClientDetails = ({
  setClientDetails,
  clientDetails,
  setOpenClientDetailsModal,
  setLocation,
  location,
  setSelectedLocation,
  selectedLocation,
  setSearchLocation,
  searchLocation,
  setValidationErrors,
  validationErrors,
  setDobError,
  dobError,
  validateInput,
  handleUpdate,
  setDobValue,
  dobValue,
  setMonth,
  setYear,
  setDay,
  month,
  year,
  day,
  setCountryCode,
  countryCode,
  isLoading,
  clientSubmitInitiated,
  setIsManuallyModal,
  isManuallyModal,
  isSearchNewUpdate,
  setIsSearchNewUpdate,
  submitClientDetails,
  submitClientAfterAddress,
  setPrevSearchLocation,
  prevSearchLocation,
  setIsDone,
  isDone
}) => {
  // ================== STATE DECLARATION ==================
  //Date of Birth
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const dimensions = useWindowDimension();
  const [updateTrackType, setUpdateTrackType] = useState(null);



  console.log(location, 'location=>>')

  const autocompleteRef = useRef(null);


  // const [day, setDay] = useState("");
  // const [month, setMonth] = useState("");
  // const [year, setYear] = useState("");

  //const [dobValue, setDobValue] = useState('');

  const [appointmentDateShow, setAppointmentDateShow] = useState("");
  const [appointmentDaysDiff, setAppointmentDaysDiff] = useState("");
  const [appointmentTimeShow, setAppointmentTimeShow] = useState("");

  const [extradivShow, setExtradivShow] = useState(false);
  const [height, setHeight] = useState(62);

  console.log(extradivShow, "extradivShow==>");

  const [selectedCountryLabel, setSelectedCountryLabel] = useState("");
  const [isFormValid, setIsFormValid] = useState(false); // State to track form validity
  // const history = useHistory();  // const [isManuallyModal, setIsManuallyModal] = useState(false);
  const fullDivRef = useRef(null)

  function isMobileDevice() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }

  function isDesktopDevice() {
    return !isMobileDevice()

  }


  const element = autocompleteRef.current;


  useEffect(() => {
    const openExtraDiv = () => {
      setExtradivShow(true)
    }
    const closeExtraDiv = () => {
      setExtradivShow(false)
    }
    // if (element) {
    //   element.addEventListener("click", openExtraDiv)
    // }

    if (element && isDesktopDevice()) {
      element.addEventListener("focus", openExtraDiv)
    }

    if (element) {
      element.addEventListener("blur", closeExtraDiv)
    }
    // if (element) {
    //   element.addEventListener('touchstart', openExtraDiv)
    // }

    return () => {
      if (element) {
        // element.removeEventListener("click", openExtraDiv);
        element.removeEventListener("focus", openExtraDiv);
        element.removeEventListener("blur", closeExtraDiv);
        // element.removeEventListener("touchstart",openExtraDiv);
      }
    }
  }, [element])



  //for countrySelection
  const CountrySelect = ({
    value,
    onChange,
    selectedLabel,
    labels,
    ...rest
  }) => (
    <div className="client-country-selection-container">
      <select
        {...rest}
        value={value}
        onChange={(event) => onChange(event.target.value || undefined)}
      >
        <option value="">{labels["ZZ"]}</option>
        {getCountries().map(
          (country, i) =>
            country !== "International" && (
              <option key={country} value={country}>
                {`+${getCountryCallingCode(country)}`}
              </option>
            )
        )}
      </select>
      <span className="selected-label">{selectedLabel}</span>
    </div>
  );

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
    selectedLabel: PropTypes.string,
  };

  //HANDLE PHONE NUMBER CHANGE
  const handleCountryChange = (country) => {
    setCountryCode(country);
    console.log("selected country", country);
    // Get the label based on the selected country
    const selectedLabel = getCountries().includes(country)
      ? `${getCountryCallingCode(country)}`
      : "";
    setSelectedCountryLabel(selectedLabel);
    if (country == undefined) {
      setValidationErrors((prev) => ({
        ...prev,
        phoneCountry: "Country is required",
      }));
      return;
    } else {
      setValidationErrors((prev) => ({
        ...prev,
        phoneCountry: "",
      }));
    }
  };
  console.log("@@@@@@@@@@@@@@", location)
  //Handle Text Input Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientDetails((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateInput(name, value);
  };

  //  useEffect(()=>{
  //   if (autocompleteRef.current) {
  //     autocompleteRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //   }
  //  },[autocompleteRef.current])



  //Function to check form validity
  const checkFormValidity = () => {
    // Check all required fields are filled
    if (
      clientDetails.clientName &&
      clientDetails.email &&
      day &&
      month &&
      year &&
      clientDetails.phoneNumber &&
      searchLocation &&
      clientDetails?.phoneCountry
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };


  //================== USE EFFECT DECLARATION START ==================

  //Date of Birth
  useEffect(() => {
    console.log("client details day month year", day, month, year);
    var date = moment(year + "-" + month + "-" + day).format("YYYY-MM-DD");
    if (day == "" && month == "" && year == "") {
      setDobValue("");
      if (clientSubmitInitiated) {
        setDobError(true);
      } else {
        setDobError(false);
      }
    } else if (
      day &&
      month &&
      year &&
      day?.length == 2 &&
      month.length == 2 &&
      year?.length == 4 &&
      date < moment().format("YYYY-MM-DD")
    ) {
      console.log("client details updated date", date);
      setDobError(false);
      setDobValue(date);
    } else if (day && month && year) {
      setDobValue("");
      setDobError(true);
    }
    checkFormValidity();
  }, [day, month, year, clientSubmitInitiated]);

  useEffect(() => {
    if (clientDetails.birthDay) {
      var date = moment(clientDetails.birthDay).format("YYYY-MM-DD");
      var [year, month, day] = date.split("-");
      setDay(day);
      setMonth(month);
      setYear(year);
    }
  }, [clientDetails?.birthDay]);

  // Update form validity when location changes
  useEffect(() => {
    checkFormValidity();
  }, [searchLocation]);
  console.log("search", searchLocation, location)
  const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)
  const { appointmentDetails } = useSelector(
    (state) => state?.forwardConsentFormOverviewReducer
  );
  const { treatmentDetails } = useSelector((state) => state?.forwardConsentFormOverviewReducer);
  useEffect(() => {
    if (!appointmentDetails || !appointmentDetails?.appointmentDateOriginal) {
      setAppointmentDateShow("");
      setAppointmentDaysDiff("");
      setAppointmentTimeShow("");
    } else {
      let formattedAppointmentDate = moment(
        appointmentDetails?.appointmentDateOriginal
      ).format("dddd, DD MMMM"),
        daysDiff = moment(appointmentDetails?.appointmentDateOriginal)
          .startOf("day")
          .diff(moment().startOf("day"), "days");
      // if (daysDiff == 0) {
      //   setAppointmentDateShow("");
      //   setAppointmentDaysDiff("");
      //   setAppointmentTimeShow("");
      // } else {
      setAppointmentDateShow(formattedAppointmentDate);
      setAppointmentDaysDiff(
        daysDiff > 0
          ? "in " + daysDiff + " " + (daysDiff > 1 ? "days" : "day")
          : "Today"
      );

      if (
        appointmentDetails?.appointmentTime &&
        appointmentDetails?.appointmentTime != "N/A"
      ) {
        let appointmentStartTime = moment(
          appointmentDetails?.appointmentTime,
          "HH:mm"
        );
        let appointmentEndTime = moment(
          appointmentDetails?.appointmentTimeEnd,
          "HH:mm"
        );

        if (appointmentEndTime.isBefore(appointmentStartTime)) {
          appointmentEndTime.add(1, "day");
        }

        const diffInMinutes = appointmentEndTime.diff(
          appointmentStartTime,
          "minutes"
        );
        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;
        let differenceString = "";
        if (hours > 0) {
          differenceString += "" + hours + (hours > 1 ? " hours" : " hour");
        }
        if (minutes > 0) {
          differenceString +=
            " " + minutes + (minutes > 1 ? " minutes" : " minute");
        }
        differenceString = differenceString.trim();

        setAppointmentTimeShow(appointmentDetails?.appointmentTime);
      }
      // }
    }
  }, [appointmentDetails]);


  useEffect(() => {
    if (fullDivRef?.current) {
      fullDivRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

  }, [fullDivRef.current])

  // =============== USEEFFECT DECLARATION ENDS HERE ==================

  const AutofocusHeightRef = useRef(null);




  useEffect(() => {
    if (searchLocation?.length > 0 && extradivShow) {
      // if (AutofocusHeightRef.current) {
      //   const currentHeight = AutofocusHeightRef.current.clientHeight;
      //   if (document.querySelectorAll(".pac-container .pac-item ").length === 1) {
      //     setHeight(50);
      //   }
      //   else if (document.querySelectorAll(".pac-container .pac-item ").length > 1) {
      //     setHeight(150);
      //   }

      // }
      setHeight(150)
    }
    else {
      setHeight(0);
    }
  }, [searchLocation, extradivShow, AutofocusHeightRef]);


  console.log("heigth", height)

  useEffect(() => {
    if (height && isDesktopDevice()) {

      AutofocusHeightRef?.current?.scrollIntoView({
        behavior: "smooth",

      });
    }
  }, [height]);


  // console.log(searchLocation?.length,extradivShow, 'searchLocation.length')
  const handleLocationChange = (e) => {
    setLocation({
      ...location,
      [e.target.name]: e.target.value
    })
  }

  const handleMenualAddress = () => {
    setIsManuallyModal(false)
    setSearchLocation(location?.country == "" ? location?.address1 + "," + location?.address2 + "," + location?.city + "," + location.postcode : location?.address1 + "," + location?.address2 + "," + location?.city + "," + location?.country + "," + location.postcode)

    if (submitClientAfterAddress) {
      submitClientDetails();
    }
  }

  const handleTrackGoogleAPI = async (callType) => {
    try {
      const currentUrl = window.location.href;
      const userId = UserProfileData?.user?.usr_id || 0;
  
      // No need to manually parse JSON; Axios handles it
      const data = await trackGoogleAPI(userId, currentUrl, callType);
  
      console.log( "trackdata", data);
    } catch (error) {
      console.error('Error:', error.message || error);
    }
  };

  // // Debounced version of the handleTrackGoogleAPI function
  // const debouncedHandleTrackGoogleAPI = debounce((callType) => handleTrackGoogleAPI(callType), 300); // 300 ms delay to match Google Autocomplete

  // useEffect(() => {
  //   if (searchLocation != null) {
  //     // Call the debounced API function with the appropriate type
  //     if (updateTrackType !== null) {
  //       debouncedHandleTrackGoogleAPI(updateTrackType);
  //     }

  //     // Cleanup function to cancel any pending debounced calls
  //     return () => {
  //       debouncedHandleTrackGoogleAPI.cancel();
  //     };
  //   }
  // }, [searchLocation, updateTrackType]);

  return (
    <>

      <div className="edit-client-details-container CustomeConsentForm" >
        {/* <CloseIcon className='close-icon-animation cursor-pointer mb-4' onClick={() => setOpenClientDetailsModal(false)} /> */}
        {/* <NavHeader  isBackInvisible={true} applyPadding={false} isBackWithCloseAndProgressBar={true} handleOnBack={() => {}} onClickCallback={() => {}} isBorder={true}/> */}
        <div className="secConsTitle" ref={fullDivRef}>
          <h1>Confirm your details</h1>
          <p>Please ensure your personal information is up to date.</p>
        </div>
        {(appointmentDateShow || appointmentDetails || treatmentDetails?.length) && (
          <div className="CustomeAppoimentBox">
            {
              treatmentDetails ?

                <p>
                  You have an appointment for {" "}
                  {

                    (<>
                      {/* <b>{treatmentDetails[0]?.treatmentName}</b> */}
                      {treatmentDetails?.map((item, index) => {
                        const isLast = index === treatmentDetails.length - 1;
                        const isSecondLast = index === treatmentDetails.length - 2;
                        return (
                          <span >
                            <b>{item?.treatmentName}</b>
                            {isSecondLast ? ' and ' : isLast ? '' : ', '}
                          </span>

                        )
                      })}
                      {" "}
                      with <b>{appointmentDetails?.businessName}</b>
                    </>)
                  }
                  {appointmentDateShow && appointmentDetails && (
                    <>
                      {" "}
                      on <b>{appointmentDateShow}</b>
                    </>
                  )}
                  {appointmentTimeShow && appointmentDetails && (
                    <>
                      {" "}
                      at <b>{appointmentTimeShow}</b>
                    </>
                  )}
                </p> :

                <p>
                  You have an appointment with {" "}
                  {
                    appointmentDetails?.businessName &&
                    (<>
                      <b>{appointmentDetails?.businessName}</b>
                    </>)
                  }
                  {appointmentDateShow && (
                    <>
                      {" "}
                      on <b>{appointmentDateShow}</b>
                    </>
                  )}
                  {appointmentTimeShow && (
                    <>
                      {" "}
                      at <b>{appointmentTimeShow}</b>
                    </>
                  )}
                </p>

            }

          </div>
        )}
        {/* <p>Client details</p> */}
        {/* <hr /> */}
        <div className="edit-client-details-form-section">
          {/* FIRST NAME */}
          <div className="client-edit-detail-container">
            <TextInput
              id="clientName"
              label="Name"
              placeholder="Name"
              name="clientName"
              value={clientDetails?.clientName}
              onChange={(e) => handleChange(e)}
            // disabled
            />
            {validationErrors.clientName && (
              <span className="error">{validationErrors.clientName}</span>
            )}
          </div>
          {/* BIRTHDAY */}
          <div className="row w-100 m-auto">
            <div className="BirthdayWrapper p-0">
              <label className="form-label">Date of birth</label>
              <div className="ErrorWrapper">
                <div className="BirthdayRow client-edit-detail-container">
                  <div className="col-md-4">
                    <TextInput
                      label="&nbsp;"
                      placeholder="DD"
                      maxLength={"2"}
                      id={"day"}
                      name="day"
                      type="text"
                      min="1"
                      ref={dayRef}
                      max="31"
                      value={day}
                      onChange={(e) => {
                        if (e.target.value.length >= 2) {
                          document.getElementById("month").focus();
                        }
                        const inputValue = e.target.value;
                        if (inputValue === "") {
                          setDobError(true);
                        }
                        const regex = /^[0-9]{0,2}$/;
                        if (regex.test(inputValue)) {
                          setDay(inputValue);
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-4 ">
                    <TextInput
                      label="&nbsp;"
                      id={"month"}
                      placeholder="MM"
                      name="month"
                      min="1"
                      max="12"
                      maxLength="2"
                      type="text"
                      ref={monthRef}
                      value={month}
                      onKeyDown={(e) => {
                        if (!e.target.value && e.key === "Backspace") {
                          document.getElementById("day").focus();
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value >= 2) {
                          document.getElementById("year").focus();
                        }
                        const inpValue = e?.target?.value;
                        if (inpValue == "") {
                          setDobError(true);
                        }
                        const regex = /^[0-9]{0,2}$/;
                        if (regex.test(inpValue)) {
                          setMonth(inpValue);
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <TextInput
                      label="&nbsp;"
                      placeholder="YYYY"
                      maxLength={4}
                      ref={yearRef}
                      type="text"
                      name="year"
                      id={"year"}
                      min="1900"
                      max="2024"
                      value={year}
                      onKeyDown={(e) => {
                        if (!e.target.value && e.key === "Backspace") {
                          document.getElementById("month").focus();
                        }
                      }}
                      onChange={(e) => {
                        console.log("key pressed", e.target.value);
                        const inputValue = e?.target?.value;
                        const regex = /^[0-9]{0,4}$/;
                        if (inputValue == "") {
                          setDobError(true);
                        }
                        if (regex.test(inputValue) || inputValue == "") {
                          setYear(inputValue);
                        }
                      }}
                    />
                  </div>
                </div>
                {dobError && <span className="error">{"Invalid DOB"}</span>}
              </div>
            </div>
          </div>
          {/* EMAIL */}
          <div className="client-edit-detail-container">
            <TextInput
              label="Email address"
              placeholder="name@gmail.com"
              value={clientDetails?.email}
              onChange={(e) => handleChange(e)}
              name="email"
            />
            {validationErrors.email && (
              <span className="error">{validationErrors.email}</span>
            )}
          </div>

          {/* PHONE NUMBER */}
          <div className="PhoneNumberWrapper">
            <label class="form-label">Phone Number</label>
            <div className="ErrorPhoneNumberInputWrapper">
              <div className="PhoneNumberInputWrapper">
                <CountrySelect
                  labels={{ ZZ: "Select Country" }}
                  value={countryCode}
                  onChange={(e) => handleCountryChange(e)}
                />
                {validationErrors.phoneCountry && (
                  <span className="error">{validationErrors.phoneCountry}</span>
                )}
                <TextInput
                  name="phoneNumber"
                  value={clientDetails?.phoneNumber}
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter phone number"
                />
              </div>
              {validationErrors.phoneNumber && (
                <span className="error">{validationErrors?.phoneNumber}</span>
              )}
            </div>
          </div>
          {/* ADDRESS */}
          <div className="client-edit-detail-container" >
            <div className=" w-100" >
              <p className="m-0">Address</p>
              <Autocomplete
                icon={false}
                ref={autocompleteRef}
                apiKey={googleApiKey}
                id="AutoComplete"
                defaultValue={searchLocation}
                onPlaceSelected={(place) => {
                  if (!place || !place?.formatted_address) {
                    return place;
                  }
                  setExtradivShow(false)
                  setSearchLocation(place?.formatted_address);



                  console.log(place, 'place')

                  const location = place?.address_components?.reduce(
                    (seed, { long_name, types }) => (
                      types.forEach((t) => (seed[t] = long_name)), seed
                    ),
                    {}
                  );
                  let houseNumber = '', streetName = '';
                  const firstAddressComp = place?.address_components?.find(item => item.types.includes('street_number'));
                  const secondAddressComp = place?.address_components?.find(item => item.types.includes('route') || item.types.includes('premise'));
                  if (firstAddressComp) {
                    houseNumber = firstAddressComp.long_name;
                  }
                  if (secondAddressComp) {
                    streetName = secondAddressComp.long_name;
                  }
                  console.log(houseNumber, streetName, 'firstAddressComp')

                  setLocation({
                    ...location,
                    street: place.route,

                    // address1: place.address_components[0].types.includes("street_number") ? place.address_components[0].long_name : '',
                    // address2: place.address_components[0].types.includes("route") || place.address_components[0].types.includes("premise") ? place.address_components[0].long_name : '',
                    address1: houseNumber,
                    address2: streetName,
                    postcode:
                      location?.postal_code === undefined
                        ? ""
                        : location?.postal_code,
                    city:
                      location?.locality === undefined
                        ? location?.postal_town === undefined
                          ? ""
                          : location?.postal_town
                        : location?.locality,
                    country: location?.country,
                    state:
                      location?.administrative_area_level_1 === undefined
                        ? location?.administrative_area_level_2
                        : location?.administrative_area_level_1,
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng(),
                  });
                  console.log(place, "i am getplace data");
                  handleTrackGoogleAPI(2);
                  // setUpdateTrackType(2)
                }}
                placeholder="Search for postcode or address..."
                className={"form-control"}
                value={searchLocation}
                onChange={(e) => {
                  setSearchLocation(e.target.value);
                  setExtradivShow(true);
                  // Call the debounced tracking API with callType 1 on input change
                  // setUpdateTrackType(1)
                  handleTrackGoogleAPI(1);
                  // setIsSearchNewUpdate(true)
                }}

                // onFocus={(e)=>{

                //    setExtradivShow(true);


                // }}
                // onBlur={()=>{
                //   setExtradivShow(false);
                // }}
                // onClick={()=>{

                //  setExtradivShow(true);

                //  }}

                options={{
                  types: [],
                  componentRestrictions: { country: "uk" },
                }}



              />
            </div>
            <div className="CustomeManually">
              <span
                onClick={() => {
                  setIsManuallyModal(true);

                }}
              >
                Add manually
              </span>
            </div>
          </div>
          <div style={{ height: height }} className="heigthRef" ref={AutofocusHeightRef}></div>
          {/* {
                        appointmentDateShow ?
                        
                        <div className='AppoimentDetailsCard'>
                            <div className='TagAppoiment'>
                                <p>Appointment details</p>
                            </div>

                            <div className='AppoimentDetailsContentGrid'>
                                <div className='AppoimentDetailsContentItem'>
                                    <p>Date</p>
                                    <h5>
                                        {appointmentDateShow} ({appointmentDaysDiff})
                                    </h5>
                                </div>
                                {
                                    appointmentTimeShow ? <div className='AppoimentDetailsContentItem'>
                                        <p>Time</p>
                                        <h5>{appointmentTimeShow}</h5>
                                    </div> : null
                                }
                            </div>
                        </div> : null
                    }                 */}

          <div className="btnWrapper">
            <StepButton
              label={"Confirm"}
              isLoading={isLoading}
              blue={true}
              disabled={
                !isFormValid &&
                (validationErrors.clientName ||
                  validationErrors?.email ||
                  validationErrors?.phoneNumber ||
                  validationErrors?.phoneCountry)
              }

              onClick={() => {
                handleUpdate();
              }}


            />
          </div>
        </div>
      </div>

      <OffCanvasModal
        isOpen={isManuallyModal}
        isBack={true}
        isCloseRight={true}
        placement={"end"}
        className={"custom-sidebar-width-35 CustomeManuallyCanvas"}
        onHide={() => setIsManuallyModal(false)}
        body={
          <>
            <div className="manual-add-container container">
              <h1>Confirm your address</h1>
              <div className="address-field-container">
                <TextInput
                  name="address1"
                  label="House No."
                  placeholder="House No"
                  value={location?.address1}
                  onChange={handleLocationChange}
                  pattern="/^[A-Za-z]+$/"
                />
                <TextInput
                  name="address2"
                  label="Street line"
                  placeholder="Street Name"
                  value={location?.address2}
                  onChange={handleLocationChange}
                />
                <TextInput
                  name="city"
                  label="City"
                  placeholder="City"
                  value={location?.city}
                  onChange={handleLocationChange}
                />
                <TextInput
                  name="postcode"
                  label="Postcode"
                  placeholder="Postcode"
                  pattern={alphaNumericRegexAp.test(location?.postcode)}
                  value={location?.postcode}
                  onChange={handleLocationChange}
                />
              </div>
              <div className="BtnModelWrapper">
                <StepButton
                  label={"Done"}
                  blue={true}
                  onClick={() => {
                    handleMenualAddress();
                    setIsDone(true)
                  }}
                  disabled={
                    !location?.address1 ||
                      !location?.address2 ||
                      !location?.city ||
                      !location?.postcode
                      ? true : false
                  }
                />
              </div>

            </div>
          </>
        }
      />
    </>
  );
};

export default EditClientDetails;
