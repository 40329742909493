import { CHECK_START_DATE_FOR_LOADING, CLEAR_APPOINTMENT_LIST_CLINICS, CLEAR_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION, GET_APPOINTMENT_LIST_CLINICS, GET_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION, UPDATE_CLINIC_LIST_DATA } from "../../constants/appointment/appointmentConst";

const initialState = {
    appointmentClinicsList: [],
    totalAppointmentClinicsListCount: 0,
    loadedAppointmentClinicsListCount: 0,
    appointmentClinicsListPage: 0,
    totalAppointments: 0,

    appointmentClinicsListAddLocation: [],
    totalAppointmentClinicsListCountAddLocation: 0,
    appointmentClinicsListPageAddLocation: 0,
    loadedAppointmentClinicsListCountAddLocation: 0,

    checkStartDateForLoading: false
}

export default function appointmentListClinicsReducer(state = initialState, action) {

    switch (action.type) {

        case GET_APPOINTMENT_LIST_CLINICS:
            const temp = (state?.appointmentClinicsList).concat(action?.payload?.clinics)
            return {
                ...state,
                appointmentClinicsList: temp,
                totalAppointmentClinicsListCount: action.payload?.totalClinics,
                appointmentClinicsListPage: state.appointmentClinicsListPage + 1,
                loadedAppointmentClinicsListCount: temp.length,
                totalAppointments: action?.payload?.totalAppointments
            };

        case CLEAR_APPOINTMENT_LIST_CLINICS:
            return {
                ...state,
                appointmentClinicsList: [],
                totalAppointmentClinicsListCount: 0,
                loadedAppointmentClinicsListCount: 0,
                appointmentClinicsListPage: 0,
                checkStartDateForLoading: true
            }

        case GET_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION:
            const tempData = (state?.appointmentClinicsListAddLocation).concat(action?.payload?.clinics)
            return {
                ...state,
                appointmentClinicsListAddLocation: tempData,
                totalAppointmentClinicsListCountAddLocation: action.payload?.totalClinics,
                appointmentClinicsListPageAddLocation: state.appointmentClinicsListPageAddLocation + 1,
                loadedAppointmentClinicsListCountAddLocation: tempData.length
            };

        case CLEAR_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION:
            return {
                ...state,
                appointmentClinicsListAddLocation: [],
                totalAppointmentClinicsListCountAddLocation: 0,
                appointmentClinicsListPageAddLocation: 0,
                loadedAppointmentClinicsListCountAddLocation: 0
            }

        case UPDATE_CLINIC_LIST_DATA:

            const sortByDate = (array) => {
                console.log("array", array)
                // Convert date strings to dates and sort
                return array.sort((a, b) => {
                    // Convert date strings to Date objects (assuming date format is YYYY-MM-DD)
                    let dateA = new Date(a.availableDate);
                    let dateB = new Date(b.availableDate);

                    // Compare dates
                    return dateA - dateB;
                });
            };

            const updatedAppointmentClinicsListAddLocation = state.appointmentClinicsListAddLocation?.map((item) => {
                if (item?.clinicId == action?.payload?.data?.clinicId) {
                    return { ...item, startTime: action?.payload?.data?.startTime, endTime: action?.payload?.data?.endTime, availableDate: action?.payload?.data?.availableDate, isAvailable: action?.payload?.data?.isAvailable }
                }
                else {
                    return item
                }
            })

            let updateAppointmentClinicsList = state.appointmentClinicsList;

            let tempClinicData = state.appointmentClinicsList?.findIndex((item) => item?.clinicId == action?.payload?.data?.clinicId && item?.availableDate === action?.payload?.data?.availableDate)
            if (action?.payload?.data?.isAvailable) {
                if (tempClinicData == -1) {
                    updateAppointmentClinicsList.unshift(action?.payload?.data)
                    updateAppointmentClinicsList = sortByDate(updateAppointmentClinicsList)
                }
                else {
                    updateAppointmentClinicsList = state.appointmentClinicsList?.map((item) => {
                        if (item?.clinicId == action?.payload?.data?.clinicId && item?.availableDate === action?.payload?.data?.availableDate) {
                            return { ...item, startTime: action?.payload?.data?.startTime, endTime: action?.payload?.data?.endTime, availableDate: action?.payload?.data?.availableDate, isAvailable: action?.payload?.data?.isAvailable }
                        }
                        else {
                            return item
                        }
                    })
                }
            }
            else {
                updateAppointmentClinicsList?.splice(tempClinicData, 1)
            }

            return {
                ...state,
                appointmentClinicsListAddLocation: updatedAppointmentClinicsListAddLocation,
                appointmentClinicsList: updateAppointmentClinicsList,
            }

        case CHECK_START_DATE_FOR_LOADING:
            return {
                ...state,
                checkStartDateForLoading: action.payload,
            }

        default:
            return state;
    }



}