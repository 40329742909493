import React, { useEffect, useRef, useState } from "react";
import "./LandingPagebusinessHeader.scss";
import logo from "../../images/icons/faces-logo-black.svg";
import logoInsync from "../../images/icons/insync-logo.png";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import getProfileData, {
  logoutProfile,
} from "../redux/actions/profile/Profile";
import moment from "moment";
import bellIcon from "../../images/icons/bell.svg";
import { useNavigate } from "react-router";
import {
  businessDashboardRoutePattern,
  insuranceLandingPage,
  editProfileLandingPagePattern,
} from "../../Routes";
import Avatar from "react-avatar";
import CustomModal from "../modal/CustomModal";
import ReviwewPopup from "../review-popup";
import CustomAutoComplete from "../auto-complete/CustomAutoComplete";
import { 
  forwardSendMailData, 
  getClientsData,
  getProductsGetCartCountData,
} from "../redux/actions";
import getDashboardList from "../redux/actions/dashboard/dashboardList";
import getdashboardAppointmentListData from "../redux/actions/dashboard/appointments";
import { webBaseUrl,frontendURL } from "../../config";
import { TfiMenu } from "react-icons/tfi";
import { ScheduleReviewEmailAction } from "../redux/actions/dashboard/scheduleReviewEmailGql";
import { FORWARD_SENDMAIL, GET_CLIENTS_DATA } from "../redux/constants";
import { GetClientListsAction } from "../redux/actions/dashboard/getClientListGql";
import basketImg from "../../images/basket.png";
import AiIcon from "../../images/ai-icon.png";
import { MdVerified } from "react-icons/md";
import verifyIcon from "../../images/icons/Vector.png"
import { debounce } from "lodash";
import { GetProfileAction } from "../redux/actions/dashboard/getProfileDataGql";
import { GetUserDashboardDataAction } from "../redux/actions/dashboard/dashboardGql";
import {GetUserInsuranceDetailAction} from "../redux/actions/GetUserInsuranceDetails/GetUserInsuranceDetail"
// import {GetUserInsuranceVerifiedAction} from "../redux/actions/faces-verified/faces-verified"

const LandingPagebusinessHeader = (props) => {


  const { openMobileMenu, isMobile } = props;
  let currentScroll;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const domain = window.location.host

  const listRef = useRef();
  const targetHeight = 100;
  const [isSwitch, setIsSwitch] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [renewClicked, setRenewClicked] = useState(false);
  const [renewLoaderActive, setRenewLoaderActive] = useState(false);
  const [openReviewPopup, setOpenReviewPopup] = useState(false);
  const [isVerified,setIsVerified]=useState(false)

  const [clientsModal, setClientsModal] = useState(false);

  //CLIENTS DATA SEARCH
  const [searchString, setSearchString] = useState(null);
  const [cartProductCount, setCartProductCount] = useState(0);

  const [allClients, setAllclients] = useState(false);
  const [value, setValue] = useState([]);
  const handleOptionChange = (event, newValue) => {
    // newValue = newValue.filter(e => e?.title !== "No Client Found")
    setValue(newValue);
  };

  // //CLIENTS DATA
  const { clientDataList } = useSelector(
    (state) => state.GetReviewsDataReducer
  );
  const { totalclientDataCount } = useSelector(
    (state) => state.GetReviewsDataReducer
  );
  const { loadedclientDataCount } = useSelector(
    (state) => state.GetReviewsDataReducer
  );
  const { clientDataPage } = useSelector(
    (state) => state.GetReviewsDataReducer
  );

  const { dashboardListData } = useSelector((state) => state.dashboardReducer);
  const { dashboardgetCartCountData } = useSelector((state) => state.dashboardGetCartCountReducer);
  const { profileData } = useSelector((state) => state?.GetProfileDataReducer);
  const { sendMailData } = useSelector((state) => state.GetReviewsDataReducer);

  useEffect(() => {
    // if dashboardgetCartCountData type of number so set setCartProductCount the state
    if (dashboardgetCartCountData && dashboardgetCartCountData != 0) {
      console.log('dashboardgetCartCountData', dashboardgetCartCountData);
      setCartProductCount(dashboardgetCartCountData);
    }
  }, [dashboardgetCartCountData]);
  const { forwardMailFlag } = useSelector(
    (state) => state.GetReviewsDataReducer
  );
  const { faces_onboarding_status } = useSelector(
    (state) => state?.FacesAiReducer
  );

  const handleReviewModalClick = () => {
    setOpenReviewPopup(true);
  };

  const { scheduleReviewEmailQueryData, initScheduleReviewEmailData } = ScheduleReviewEmailAction();
  const { getClientListsQueryData, initGetClientListsData } = GetClientListsAction();
  const {getUserInsuranceDetailsQueryData,initGetUserInsuranceDetailData}=GetUserInsuranceDetailAction();
  // const {getInsuranceVerifiedQueryData,initGetInsuranceverifiedData} =GetUserInsuranceVerifiedAction();

useEffect(()=>{
    initGetUserInsuranceDetailData();
},[])

  // useEffect(()=>{
  //       initGetInsuranceverifiedData();
  // },[])

  // useEffect(()=>{
  //     if(getInsuranceVerifiedQueryData?.data){
  //        setIsVerified(getInsuranceVerifiedQueryData?.data?.getUserInsuranceVerifiedStatus?.data?.is_verified)
  //     }
  // },[getInsuranceVerifiedQueryData?.data])

  const handleClientsForm = (e) => {
    e.preventDefault();
    let temp = [];
    if (value.length > 0) {
      temp = value.map((e) => e.cl_dt_id);
    }

    // dispatch(
    //   forwardSendMailData({
    //     elementId: temp,
    //     selectAllClients: allClients ? 1 : 0,
    //   })
    // );

    dispatch({
      type: FORWARD_SENDMAIL
    });
    initScheduleReviewEmailData({
      clientIds: temp
    })
    // here
  };
  //INFINITE SCROLL FOR CLIENTS DATA

  const initClientList = (refreshList = false) => {
    let newPage = clientDataPage + 1;
    if (refreshList) {
      newPage = 1;
    } else if (totalclientDataCount <= loadedclientDataCount) {
      return;
    }
    dispatch(
      getClientsData({ page: newPage, limit: 10, search: searchString })
    );

    // dispatch({
    //   type: GET_CLIENTS_DATA,
    //   payload: {
    //     page: newPage
    //   }
    // });
    initGetClientListsData({
      "page": newPage,
      "limit": 10,
      "search": searchString
    });
    // here
  };

  const handleScrollCallback = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;

      if (
        scrollTop + clientHeight === scrollHeight &&
        totalclientDataCount > loadedclientDataCount
      ) {
        initClientList();
      }
    }
  };

  console.log(dashboardListData, "dashboardListData");
  console.log(profileData, "profileData");

  useEffect(() => {
    if (sendMailData) {
      // setOptionValue([])
      setClientsModal(false);
    }
  }, [sendMailData]);


  useEffect(() => {

    if (localStorage.getItem('admin_id')?.length) {
      setIsAdmin(true);
    }

    if (dashboardListData == null) {
      dispatch(getProductsGetCartCountData());
      dispatch(
        getDashboardList({
          "business-average-reviews": true,
          platform: "frontend",
          insurancePage: true,
        })
      );
    }
  }, []);

  window.addEventListener("scroll", function () {
    currentScroll = window.scrollY || window.pageYOffset;

    if (currentScroll >= targetHeight) {
      setIsSwitch(true);
    } else {
      setIsSwitch(false);
    }
  });

  // const pharmacyRegistrationLink = webBaseUrl + "/user/update-profile?active-tab=prescriber";
  // const pharmacyRegistrationLink = webBaseUrl + "/prescriber/register";
  const pharmacyRegistrationLink = frontendURL + "/prescriber-registration?insuranceexpired=true";

  const redirectToPharmacyRegistration = () => {
    window.location.href = pharmacyRegistrationLink;
  }

  const redirectToPharmacyRegistrationWithQueryParams = () => {
    window.location.href = frontendURL + "/prescriber-registration?insuranceexpired=true";
  }

  console.log("search string", searchString);

  const debouncedApiCall = debounce((query) => {
    // Make the API call using the search query
    // Replace this with your own API call logic
    initClientList(true)
  }, 500); // Debounce delay in milliseconds (e.g., 500ms)


  useEffect(() => {
    if (searchString != null) {
      // Call the debounced API function with the search query
      debouncedApiCall(searchString);

      // Cleanup function to cancel any pending debounced calls
      return () => {
        debouncedApiCall.cancel();
      };
    }
  }, [searchString]);

  const { getProfileQueryData, initGetProfileData } = GetProfileAction();

  console.log("profileData",profileData)
  
  useEffect(() => {
    initGetProfileData();
  }, [])

  const { getUserDashboardDataQueryData, initGetUserDashboardDataData } = GetUserDashboardDataAction();
  useEffect(() => {
    initGetUserDashboardDataData({
        businessAverageReviews: true,
        appointmentListDayType: "",
        limit: null,
        platform: null
    });
  }, [])
  
  const [isAuthHeader, setIsAuthHeader] = useState(false);
  useEffect(() => {
    console.log('isAuth Header', isAuthHeader, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
    Cookies.get("faces_access_token") ? setIsAuthHeader(true) : setIsAuthHeader(false);
    console.log('isAuth Header', isAuthHeader, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
  });

  return (
    <>
      <div className="container-fluid p-0">

        {/* {isSwitch ? (
          <div className="new-logo-header text-start p-3">
            <div className="img-container">
              <img
                src={logo}
                alt=""
                onClick={() => {
                  navigate(businessDashboardRoutePattern);
                  window.scrollTo(0, 0);
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <div className={isSwitch ? "HeaderFixed" : "HeaderStickey"}>
              <div className="main-business-header">
                <div className="content-header d-flex justify-content-end p-4 gap-3">
                  <h6>WALLET</h6>
                  <h6
                    onClick={() =>
                      (window.location.href =
                        process.env.REACT_APP_WEB_BASE_URL + "/shop")
                    }
                  >
                    SHOP
                  </h6>
                  <h6
                    onClick={() =>
                      (window.location.href =
                        process.env.REACT_APP_WEB_BASE_URL +
                        "/user/update-profile")
                    }
                  >
                    MY ACCOUNT
                  </h6>
                  <h6
                    onClick={() => {
                      dispatch(logoutProfile());
                    }}
                  >
                    LOGOUT
                  </h6>
                  <h6
                    onClick={() =>
                      (window.location.href =
                        process.env.REACT_APP_WEB_BASE_URL + "/shop/cart")
                    }
                  >
                    BASKET
                    {dashboardListData?.cart_total_count &&
                      "(" + dashboardListData?.cart_total_count + ")"}
                  </h6>
                </div>
              </div>
              <div className="container-fluid">
                <div className="dashboard-parent-container row">
                  <div className="dashboard-header d-flex justify-content-between align-items-center my-3 col-md-12">
                    <div className="logo-image">
                      <img
                        src={logo}
                        alt=""
                        onClick={() => navigate(businessDashboardRoutePattern)}
                      />
                    </div>
                    <div className="notificationCustome">
                      {dashboardListData?.pin_expiry_date <
                        moment().format("YYYY-MM-DD") &&
                      dashboardListData?.insurance_expiry_date <
                        moment().format("YYYY-MM-DD") ? (
                        <div className="insurance-warining mx-auto d-flex gap-3">
                          <div>
                            <img src={bellIcon} width={"150%"} alt="" />
                          </div>
                          <p className="my-auto">
                            Your PIN and insurance has expired!{" "}
                            <u
                              className="cursor-pointer"
                              onClick={() => {
                                navigate(insuranceLandingPage);
                              }}
                            >
                              Click here to update
                            </u>{" "}
                            or{" "}
                            <u
                              className="cursor-pointer"
                              onClick={() => {
                                setRenewClicked(true);
                                setRenewLoaderActive(true);
                                navigate(insuranceLandingPage);
                              }}
                            >
                              renew the insurance
                            </u>
                            .
                          </p>
                        </div>
                      ) : (
                        <>
                          {dashboardListData?.pin_expiry_date?.length ? (
                            <div>
                              {dashboardListData?.pin_expiry_date <
                                moment().format("YYYY-MM-DD") && (
                                <div className="insurance-warining mx-auto d-flex gap-3">
                                  <div>
                                    <img src={bellIcon} width={"150%"} alt="" />
                                  </div>
                                  <p className="my-auto">
                                    Your PIN has expired! Click to{" "}
                                    <u
                                      className="cursor-pointer"
                                      onClick={() => {
                                        window.location.href =
                                          process.env.REACT_APP_WEB_BASE_URL +
                                          "/user/update-profile?active-tab=prescriber";
                                      }}
                                    >
                                      update the PIN
                                    </u>
                                    .
                                  </p>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div></div>
                          )}
                          {dashboardListData?.insurance_expiry_date?.length ? (
                            <div>
                              {dashboardListData?.insurance_expiry_date <
                                moment().format("YYYY-MM-DD") && (
                                <div className="insurance-warining mx-auto d-flex gap-3">
                                  <div>
                                    <img src={bellIcon} width={"150%"} alt="" />
                                  </div>
                                  <p className="my-auto">
                                    Your insurance has expired! Click to{" "}
                                    <u
                                      className="cursor-pointer"
                                      onClick={() => {
                                        navigate(insuranceLandingPage);
                                      }}
                                    >
                                      upload Insurance certificate
                                    </u>{" "}
                                    or{" "}
                                    <u
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setRenewClicked(true);
                                        setRenewLoaderActive(true);
                                        navigate(insuranceLandingPage);
                                      }}
                                    >
                                      renew
                                    </u>
                                    .
                                  </p>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="d-flex gap-3 profileCustome">
                      <p
                        className="rating my-auto cursor-pointer"
                        onClick={() => handleReviewModalClick()}
                      >
                        <i className="fa fa-star checked me-1" />
                        {dashboardListData?.averageRating
                          ? dashboardListData?.averageRating?.toFixed(2)
                          : 0}
                      </p>
                      <Avatar
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.location.href =
                            webBaseUrl + "/user/update-profile";
                        }}
                        name={
                          profileData?.first_name + " " + profileData?.last_name
                        }
                        round
                        size="40"
                        color="#EEEFF3"
                        fgColor="#000"
                        textSizeRatio={2}
                      />
                      <p className="company-name my-auto">
                        {profileData?.company_name}
                      </p>
                      {isMobile && (
                        <div
                          className="w-125 text-end me-5 menu-mobile"
                          onClick={openMobileMenu}
                        >
                          <TfiMenu />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )} */}
        <div className={isSwitch ? "HeaderFixed" : "HeaderStickey"}>
          <div className="main-business-header">
            {isAdmin ? (
              <div className="content-header d-flex justify-content-between align-items-center px-4">
                <div>
                  <h5>
                    You are logged in as Admin.{" "}
                    <u
                      onClick={() => {
                        console.log("exit action called");
                        dispatch(logoutProfile());
                      }}
                    >
                      Exit
                    </u>
                  </h5>
                </div>
                {/* <div className="d-flex justify-content-end py-4 gap-3">
                  <h6>WALLET</h6>
                  <h6
                    onClick={() => (window.location.href =
                      process.env.REACT_APP_WEB_BASE_URL + "/shop")}
                  >
                    SHOP
                  </h6><h6
                    onClick={() => (window.location.href =
                      process.env.REACT_APP_WEB_BASE_URL +
                      "/user/update-profile")}
                  >
                    MY ACCOUNT
                  </h6><h6
                    onClick={() => {
                      dispatch(logoutProfile());
                    }}
                  >
                    LOGOUT
                  </h6><h6
                    onClick={() => (window.location.href =
                      process.env.REACT_APP_WEB_BASE_URL + "/shop/cart")}
                  >
                    BASKET
                    {dashboardListData?.cart_total_count &&
                      "(" + dashboardListData?.cart_total_count + ")"}
                  </h6>
                </div> */}
              </div>
            ) : (
              <div className="content-header d-flex justify-content-end p-4 gap-3">

                {
                  isAuthHeader ?
                    <>
                      {/* <div className="messageIcon">
                    <span
                      onClick={() =>
                        window.open(
                          faces_onboarding_status?.faces_ai_integration_link,
                          "_blank"
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="27"
                        viewBox="0 0 23 27"
                        fill="none"
                      >
                        <circle
                          cx="11.1749"
                          cy="11.0703"
                          r="11.0703"
                          fill="white"
                        />
                        <path
                          d="M4.32383 19.2341C4.3045 19.0813 4.45777 18.965 4.59971 19.0246L9.85351 21.2318C9.99545 21.2914 10.0196 21.4823 9.897 21.5754L5.35863 25.0218C5.23602 25.1149 5.05859 25.0403 5.03926 24.8876L4.32383 19.2341Z"
                          fill="white"
                        />
                        <path
                          d="M8.12961 8.70189L5.67807 13.7712C5.48195 14.7852 6.08666 14.8272 6.41353 14.7215L9.11023 12.1868C9.30636 11.6799 9.68226 11.7644 9.8457 11.87L11.8069 14.0878C12.7876 14.8482 13.523 14.4046 13.7682 14.0878L16.71 8.06809C16.71 7.3077 16.2197 7.32882 15.9746 7.43443L13.523 9.96907C12.9346 10.476 12.4607 10.1803 12.2972 9.96907L10.336 8.06823C9.15926 7.30784 8.37477 8.17384 8.12961 8.70189Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </div> */}
                      {faces_onboarding_status?.is_onboarded ? (
                        <div className="messageIcon">
                          <span
                            onClick={() =>
                              window.open(
                                faces_onboarding_status?.faces_ai_integration_link,
                                "_blank"
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23"
                              height="27"
                              viewBox="0 0 23 27"
                              fill="none"
                            >
                              <circle
                                cx="11.1749"
                                cy="11.0703"
                                r="11.0703"
                                fill="white"
                              />
                              <path
                                d="M4.32383 19.2341C4.3045 19.0813 4.45777 18.965 4.59971 19.0246L9.85351 21.2318C9.99545 21.2914 10.0196 21.4823 9.897 21.5754L5.35863 25.0218C5.23602 25.1149 5.05859 25.0403 5.03926 24.8876L4.32383 19.2341Z"
                                fill="white"
                              />
                              <path
                                d="M8.12961 8.70189L5.67807 13.7712C5.48195 14.7852 6.08666 14.8272 6.41353 14.7215L9.11023 12.1868C9.30636 11.6799 9.68226 11.7644 9.8457 11.87L11.8069 14.0878C12.7876 14.8482 13.523 14.4046 13.7682 14.0878L16.71 8.06809C16.71 7.3077 16.2197 7.32882 15.9746 7.43443L13.523 9.96907C12.9346 10.476 12.4607 10.1803 12.2972 9.96907L10.336 8.06823C9.15926 7.30784 8.37477 8.17384 8.12961 8.70189Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      <a
                        // onClick={() =>
                        //   (window.location.href =
                        //     process.env.REACT_APP_WEB_BASE_URL + "/shop/cart")
                        // }
                        href={process.env.REACT_APP_WEB_BASE_URL + "/shop/cart"}
                        className="bucketIconWrapper"
                      >

                        <span className="bucketIcon">
                        <span className="notification">{cartProductCount}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="19"
                            viewBox="0 0 23 19"
                            fill="none"
                          >
                            <path
                              d="M1.40621 8.71074C1.36792 8.55722 1.3651 8.39699 1.39798 8.24222C1.43086 8.08744 1.49856 7.94219 1.59595 7.81749C1.69334 7.69279 1.81786 7.59191 1.96006 7.52252C2.10226 7.45312 2.2584 7.41704 2.41662 7.41699H20.5833C20.7415 7.41704 20.8977 7.45312 21.0399 7.52252C21.182 7.59191 21.3066 7.69279 21.404 7.81749C21.5014 7.94219 21.5691 8.08744 21.6019 8.24222C21.6348 8.39699 21.632 8.55722 21.5937 8.71074L19.7072 16.2555C19.5946 16.7062 19.3345 17.1064 18.9684 17.3923C18.6022 17.6782 18.151 17.8336 17.6864 17.8337H5.3135C4.84892 17.8336 4.3977 17.6782 4.03155 17.3923C3.6654 17.1064 3.40532 16.7062 3.29266 16.2555L1.40621 8.71178V8.71074Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.375 11.5837V13.667M14.625 11.5837V13.667M5.25 7.41699L9.41667 1.16699M17.75 7.41699L13.5833 1.16699"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                          </svg>
                        </span>

                        {/* <img src={basketImg} alt="" /> */}
                        {/* {dashboardListData?.cart_total_count &&
                    "(" + dashboardListData?.cart_total_count + ")"} */}
                      </a>
                      <a
                        href={process.env.REACT_APP_WEB_BASE_URL + "/shop"}
                        className="header-link"
                      >
                        SHOP
                      </a>
                      {/* <h6
                  onClick={() =>
                    (window.location.href =
                      process.env.REACT_APP_WEB_BASE_URL + "/user/update-profile")
                  }
                >
                  MY ACCOUNT
                </h6> */}
                      <a
                        href={process.env.REACT_APP_WEB_BASE_URL + "/user/update-profile"}
                        className="header-link"
                      >
                        MY ACCOUNT
                      </a>
                      <span
                        className="header-link"
                        onClick={() => {
                          dispatch(logoutProfile());
                        }}
                      >
                        LOGOUT
                      </span>
                    </> : (
                      <>
                        <a
                          href={process.env.REACT_APP_WEB_BASE_URL + "/login"}
                          className="header-link"
                        >
                          LOGIN
                        </a>
                        <a
                          href={process.env.REACT_APP_WEB_BASE_URL + "/register"}
                          className="header-link"
                        >
                          REGISTER
                        </a>
                      </>
                    )}
              </div>
            )}
          </div>
          <div className="container-fluid">
            <div className="dashboard-parent-container row">
              <div className="dashboard-header d-flex justify-content-between align-items-center my-3 col-md-12">
                {!isMobile && (
                  <div className="logo-image">
                    <img
                      src={
                        domain == "staging.insyncinsurance.co.uk"
                          ? logoInsync
                          : logo
                      }
                      alt=""
                      onClick={() => {isAuthHeader ?  navigate(businessDashboardRoutePattern) :  window.location.href = webBaseUrl}}
                    />
                  </div>
                )}
                {/* <div className="notificationCustome">
                  {dashboardListData?.pin_expiry_date && dashboardListData?.insurance_expiry_date &&
                    dashboardListData?.pin_expiry_date <
                    moment().format("YYYY-MM-DD") &&
                    dashboardListData?.insurance_expiry_date <
                    moment().format("YYYY-MM-DD") ? (
                    <div className="insurance-warining mx-auto d-flex gap-3">
                      <div>
                        <img src={bellIcon} width={"150%"} alt="" />
                      </div>
                      <p className="my-auto">
                        Your insurance has expired! Click to{" "}
                        <u
                          className="cursor-pointer"
                          onClick={redirectToPharmacyRegistration}
                        >
                          upload Insurance certificate
                        </u>{" "}
                        or{" "}
                        <u
                          className="cursor-pointer"
                          onClick={() => {
                            setRenewClicked(true);
                            setRenewLoaderActive(true);
                            navigate(insuranceLandingPage);
                          }}
                        >
                          renew
                        </u>
                        .
                      </p>
                    </div>
                  ) : (
                    <>
                      {dashboardListData?.pin_expiry_date?.length ? (
                        <div>
                          {dashboardListData?.pin_expiry_date && dashboardListData?.pin_expiry_date <
                            moment().format("YYYY-MM-DD") && (
                              <div className="insurance-warining mx-auto d-flex gap-3">
                                <div>
                                  <img src={bellIcon} width={"150%"} alt="" />
                                </div>
                                <p className="my-auto">
                                  Your PIN has expired! Click to{" "}
                                  <u
                                    className="cursor-pointer"
                                    onClick={() => {
                                      window.location.href =
                                      frontendURL +
                                        "/prescriber-registration?pinexpired=true";
                                    }}
                                  >
                                    update the PIN
                                  </u>
                                  .
                                </p>
                              </div>
                            )}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {dashboardListData?.insurance_expiry_date?.length ? (
                        <div>
                          {dashboardListData?.insurance_expiry_date && dashboardListData?.insurance_expiry_date <
                            moment().format("YYYY-MM-DD") && (
                              <div className="insurance-warining mx-auto d-flex gap-3">
                                <div>
                                  <img src={bellIcon} width={"150%"} alt="" />
                                </div>
                                <p className="my-auto">
                                  Your insurance has expired! Click to{" "}
                                  <u
                                    className="cursor-pointer"
                                    // onClick={() => {
                                    //   navigate(insuranceLandingPage);
                                    // }}
                                    onClick={redirectToPharmacyRegistrationWithQueryParams}
                                  >
                                    upload Insurance certificate
                                  </u>{" "}
                                  or{" "}
                                  <u
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setRenewClicked(true);
                                      setRenewLoaderActive(true);
                                      navigate(insuranceLandingPage);
                                    }}
                                  >
                                    renew
                                  </u>
                                  .
                                </p>
                              </div>
                            )}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </>
                  )}
                </div> */}

<div className="notificationCustome">
  {dashboardListData?.pin_expiry_date && moment(dashboardListData.pin_expiry_date, "YYYY-MM-DD").isBefore(moment(), 'day') && (
    <div className="insurance-warining mx-auto d-flex gap-3 mb-2">
      <div>
        <img src={bellIcon} width={"150%"} alt="" />
      </div>
      <p className="my-auto">
        Your PIN has expired! Click to{" "}
        <u
          className="cursor-pointer"
          onClick={() => {
            window.location.href = frontendURL + "/prescriber-registration?pinexpired=true";
          }}
        >
          update the PIN
        </u>
        .
      </p>
    </div>
  )}

  {dashboardListData?.insurance_expiry_date && moment(dashboardListData.insurance_expiry_date, "YYYY-MM-DD").isBefore(moment(), 'day') && (
    <div className="insurance-warining mx-auto d-flex gap-3">
      <div>
        <img src={bellIcon} width={"150%"} alt="" />
      </div>
      <p className="my-auto">
        Your insurance has expired! Click to{" "}
        <u
          className="cursor-pointer"
          onClick={redirectToPharmacyRegistrationWithQueryParams}
        >
          upload Insurance certificate
        </u>{" "}
        or{" "}
        <u
          className="cursor-pointer"
          onClick={() => {
            setRenewClicked(true);
            setRenewLoaderActive(true);
            navigate(insuranceLandingPage);
          }}
        >
          renew
        </u>
        .
      </p>
    </div>
  )}
</div>


                <div className="d-flex gap-3 profileCustome">
                  {
                    isAuthHeader &&
                    (
                      <>
                        {isMobile &&
                          <div className="logo-image">
                            <img
                              src={logo}
                              alt=""
                              onClick={() => {isAuthHeader ?  navigate(businessDashboardRoutePattern) :  window.location.href = webBaseUrl}}
                              />
                          </div>
                        }


                        {/* ===========================FACES VERIFIED============================== */}


                        {

                         profileData && profileData?.is_verified ===true && getUserInsuranceDetailsQueryData?.data &&
                          getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data?.status===1 ? (
                            <div className="mobileProfile">
                          <p
                            className="rating my-auto cursor-pointer"
                            onClick={() => handleReviewModalClick()}
                          >
                            <i className="fa fa-star  me-1 checked" />
                            {dashboardListData?.averageRating
                              ? dashboardListData?.averageRating?.toFixed(2)
                              : 0}
                          </p>
                          <div className="verifiedTag ">
                            <Avatar
                             style={{ cursor: "pointer" }}
                            onClick={() => {
                              window.location.href =
                                webBaseUrl + "/user/update-profile";
                            }}
                            src={profileData?.profile_pic}
                            name={
                              profileData?.first_name?.at(0) + " " + profileData?.last_name?.at(0)
                            }
                            
                            round
                            size="40"
                            color="#EEEFF3"
                            fgColor="#000"
                            textSizeRatio={2}
                          />
                          <img src={verifyIcon}  alt="icon" className="verifyIcon"/>
                       
                          </div>

                          
                          <p className="company-name my-auto">
                            {profileData?.company_name}
                          </p>
                          {isMobile && (
                            <div
                              className="w-125 text-end me-5 menu-mobile"
                              onClick={openMobileMenu}
                            >
                              <TfiMenu />
                            </div>
                          )}
                        </div>
                          )
                          : 
                          <div className="mobileProfile">
                          <p
                            className="rating my-auto cursor-pointer"
                            onClick={() => handleReviewModalClick()}
                          >
                            <i className="fa fa-star  me-1 checked" />
                            {dashboardListData?.averageRating
                              ? dashboardListData?.averageRating?.toFixed(2)
                              : 0}
                          </p>
                          <Avatar
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              // navigate(editProfileLandingPagePattern());
                              window.location.href =
                                webBaseUrl + "/user/update-profile";
                            }}
                            src={profileData?.profile_pic}
                            name={
                              profileData?.first_name?.at(0) + " " + profileData?.last_name?.at(0)
                            }
                            round
                            size="40"
                            color="#EEEFF3"
                            fgColor="#000"
                            textSizeRatio={2}
                          />
                          <p className="company-name my-auto">
                            {profileData?.company_name}
                          </p>
                          {isMobile && (
                            <div
                              className="w-125 text-end me-5 menu-mobile"
                              onClick={openMobileMenu}
                            >
                              <TfiMenu />
                            </div>
                          )}
                        </div>
                        }
                       
                      </>
                    )
                  }                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ========================REVIEW MODAL======================== */}

      <CustomModal
        modalOpen={openReviewPopup}
        setModalOpen={setOpenReviewPopup}
        type={"review"}
        modalBody={
          <>
            <ReviwewPopup
              setOpenReviewPopup={setOpenReviewPopup}
              setClientsModal={setClientsModal}
              setValue={setValue}
            />
          </>
        }
      />

      {/* ========================FORWARD MAIL TO CLIENT MODAL======================== */}
      <CustomModal
        modalOpen={clientsModal}
        // modalOpen={true}
        setModalOpen={setClientsModal}
        modaltitle={"Select Clients"}
        modalBody={
          <>
            {
              <form
                className="d-flex flex-column mt-2"
                onSubmit={handleClientsForm}
              >
                <div>
                  <input
                    type="checkbox"
                    name="all"
                    id="all"
                    checked={allClients}
                    onChange={(e) => { setAllclients(e.target.checked) }}
                  />
                  <label htmlFor="all" className="ms-2">
                    Select All Clients
                  </label>
                </div>
                {!allClients && (
                  <CustomAutoComplete
                    multiple={true}
                    groupBy={false}
                    listRef={listRef}
                    setSearchString={setSearchString}
                    handleInputClick={() => initClientList(true)}
                    optionValue={clientDataList}
                    value={value}
                    setValue={setValue}
                    handleOptionChange={handleOptionChange}
                    handleScrollCallback={handleScrollCallback}
                  />
                )}
                <button className="client-submit-btn">
                  <div className="d-flex justify-content-center align-items-center gap-2 ">
                    {forwardMailFlag && (
                      <div class="payment-spinner-loader"></div>
                    )}
                    SEND REVIEW REQUEST TO CLIENT
                  </div>
                </button>
              </form>
            }
          </>
        }
      />
    </>
  );
};

export default LandingPagebusinessHeader;
