import { CLEAR_CONSENT_FORM_LIST, GET_CONSENT_FORM_LIST } from "../../constants/formsTemplate/FormTemplateConst";

const initialState = {
    consentFormList: [],
    constentFormListOther: [],
    totalConsentFormCount: 0,
    consentFormListPage: 0,
    loadedConsentFormCount: 0,
  }

//Get ConsentForm List reducer
export default function consentFormListReducer(state = initialState, action) {

    switch (action.type) {
  
      case GET_CONSENT_FORM_LIST:
        console.log(action.payload,"firstTime")
      let temp=(state?.consentFormList).concat(action.payload.consentForms)
        return {
          ...state,
          consentFormList: temp,
          constentFormListOther: action.payload,
          totalConsentFormCount: action.payload.total,
          consentFormListPage: state.consentFormListPage + 1,
          loadedConsentFormCount: temp.length,  
        };
  
      case CLEAR_CONSENT_FORM_LIST:
  
        return {
          ...state,
          consentFormList: [],
          constentFormListOther: [],
          totalConsentFormCount: 0,
          consentFormListPage: 0,
          loadedConsentFormCount: 0,
        };
  
      default:
        return state;
    }
  
  
  
  }