import React, { useState } from "react";
import "./EmailsTemplate.scss";
import { useEffect } from "react";
import StepButton from "../../../../../common/StepButton";
import { ReactComponent as RightIcon } from "../../../../../../images/calander_next.svg";
import OffCanvasModal from "../../../../../modal/OffCanvasModal/OffCanvasModal";
import CustomRadioButton from "../../../../../common/CustomRadioButton";
import { ReactComponent as LocationIcon } from "../../../../../../images/business-setting-icon/location.svg"
import { toast } from "react-toastify";
import { ReactComponent as Defaultavatar } from "../../../../../../../src/assets/prescriber-registration/Circle_Button.svg"
import BusinessNavigation from "../../../../../common/businessNavigation/BusinessNavigation";
import { GetClinicLocationsAction } from "../../../../../redux/actions/client-text-sms/getclinicList";
import { GetClientListAction } from "../../../../../redux/actions/client-text-sms/getclientList";
import TextInput from "../../../../../common/TextInput";
import { useSendMarketingEmailsMutation } from "../../../../../redux/actions/sms-plans-and-payment/SendMarketingEmails";
import CustomeCKEditor from "../../../../../customeCKEditor";
import Dropzone from "../../../../../../components/dropzone/dropzone";
import UploadsImg from "../../../../../../images/Image.svg";


const CheckboxComponent = ({ label, isChecked, onChange }) => (
  <div>
    <label className="radio-label input-checkbox">
      <div className="client-name-container">
        <div className="leftRadioIcon">
          <Defaultavatar />
        </div>
        <h3>{label}</h3>
      </div>
      <input type="checkbox" checked={isChecked} onChange={onChange} />
    </label>
  </div>
);

function EmailsTemplate({ setStep }) {
  const [isData, setIsData] = useState(false);
  const { initiateSendMarketingEmails } = useSendMarketingEmailsMutation();
  const [isclinics, setIsclinics] = useState(false);
  const [clientTitlename, setclientTitlename] = useState()
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { initGetClientList } = GetClientListAction();
  const [isselectClient, setIsselectClient] = useState(false);
  const [clientList, setclientList] = useState([]);
  const [clinicName, setClinicname] = useState()
  const [clinicLocation, setclinicLocation] = useState([])
  const [CheckedClients, setCheckedClients] = useState([]);
  const [message, setMessage] = useState()
  const [totalSelctedclients, settotalSelectedclients] = useState(0)
  const [isviewSelectClient, setIsviewSelectClient] = useState(false);
  const { initGetClinicLocations } = GetClinicLocationsAction();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {



    initGetClientList({
      "selectedClinicForClient": [],
      requestFrom: "searchEmails"
    }, (res) => { setclientList(res?.data?.clients) }, (err) => { console.log(err) })
    initGetClinicLocations({}, (res) => { setclinicLocation(res?.data?.items) }, (err) => { console.log(err) })
  }, [])

  const sendMarketingEmail = () => {
    if (!aboutDetails?.Subject) {
      setValidationErrors((prev) => ({
        ...prev,
        Subject: "Subject cannot be empty. Please provide a subject.",
      }));
    }
    else if (!message) {
      setValidationErrors((prev) => ({
        ...prev,
        message: "Message cannot be empty. Please enter a message before sending.",
      }));
    }
    // else if (otherDocs[0]?.image === "") {
    //   setValidationErrors((prev) => ({
    //     ...prev,
    //     image: "Please upload an image before sending",
    //   }));
    // }
    else {

      const images = otherDocs?.map((doc, index) => doc?.image);
      const filteredClientIds = CheckedClients?.filter((ele) => ele?.clentId)?.map((ele) => ele.clentId);
      initiateSendMarketingEmails({
        message: message,
        subject: aboutDetails?.Subject,
        attachmentFiles: images,
        clientIds: filteredClientIds
      }, (res) => { res?.success ? ((toast.success(res?.message))(setStep(0))) : toast.error(res?.message) }, (errr) => { console.log(errr, "errrrr") })
    }

  }


  const [aboutDetails, setAboutDetails] = useState({
    title: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    Subject: "",
    message: "",
    image: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAboutDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    setValidationErrors((prev) => ({
      ...prev,
      Subject: "",
    }));
  };


  const handleEditorChange = (data) => {
    const plainText = data?.replace(/<\/?[^>]+(>|$)/g, "");
    setMessage(plainText)
    setValidationErrors((prev) => ({
      ...prev,
      message: "",
    }));
  };


  const [otherDocs, setOtherDocs] = useState([
    {
      image: "",
      certificate_title: "",
      certificate_url: "",
    },
  ]);

  const handleAddDropzone = () => {

    if (otherDocs?.length < 5) {
      const nextDocIndex = otherDocs?.length + 1;



      const newDocumentState = {
        image: "",
        certificate_url: "",
        certificate_title: "",
      };
      if (otherDocs?.length == 3) {
        setOtherDocs((prevOtherDocs) => [...prevOtherDocs, newDocumentState, newDocumentState]);

      }
      else {
        setOtherDocs((prevOtherDocs) => [...prevOtherDocs, newDocumentState]);

      }

      setIsData(true);

    }

  };


  // const handleSelectAllChange = () => {
  //   const updatedCheckboxes = checkboxes.map((checkbox) => ({
  //     ...checkbox,
  //     isChecked: !selectAllChecked,
  //   }));
  //   setCheckboxes(updatedCheckboxes);
  //   setSelectAllChecked(!selectAllChecked);
  // };


  const handleSelectAllChange = () => {
    const updatedCheckboxes = clientList?.map((checkbox) => ({
      ...checkbox,
      isChecked: !selectAllChecked,
    }));


    // setCheckboxes(updatedCheckboxes);
    const newCheckedClients = updatedCheckboxes?.filter((client) => client?.isChecked);
    setclientList(updatedCheckboxes);
    setCheckedClients(newCheckedClients);
    setSelectAllChecked(!selectAllChecked);
  };

  // const handleCheckboxChange = (id) => {
  //   const updatedCheckboxes = checkboxes.map((checkbox) =>
  //     checkbox.id === id
  //       ? { ...checkbox, isChecked: !checkbox.isChecked }
  //       : checkbox
  //   );
  //   setCheckboxes(updatedCheckboxes);
  //   setSelectAllChecked(
  //     updatedCheckboxes.every((checkbox) => checkbox.isChecked)
  //   );
  // };
  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = clientList?.map((client) =>
      client.clentId === id
        ? { ...client, isChecked: !client.isChecked }
        : client
    );

    setclientList(updatedCheckboxes);

    const newCheckedClients = updatedCheckboxes.filter((client) => client?.isChecked);
    setCheckedClients(newCheckedClients);
    setSelectAllChecked(
      updatedCheckboxes?.every((client) => client?.isChecked)
    );
  };
  useEffect(() => { settotalSelectedclients(CheckedClients?.length ? CheckedClients?.length : 0) }, [CheckedClients])
  const saveclientData = () => {
    // setIsclinics(false);
    // const ids = clinicLocation?.map((client) => client?.clentId);



    // selectedIndex !== -1 ? 
    // initGetClientList(
    //   {
    //     selectedClinicForClient: [selectedIndex],
    //     requestFrom: "marketing-sms"
    //   },
    //   (res) => {
    //     setclientList(res?.data?.clients);
    //     setIsclinics(false);
    //   },
    //   (err) => {
    //     // handle error
    //   }
    // ),
    // const clinicName =   clinicLocation?.filter(client => client?.clientId === selectedIndex)
    // :setIsclinics(false);

    if (selectedIndex !== -1) {
      initGetClientList(
        {
          selectedClinicForClient: [selectedIndex],
          requestFrom: "searchEmails"
        },
        (res) => {
          setclientList(res?.data?.clients);
          setIsclinics(false);
        },
        (err) => {
          // handle error
          console.log(err)
        }
      );

      const clinic = clinicLocation?.find(client => client?.clinicId === selectedIndex);
      setClinicname(clinic?.clinicName)
    } else {
      setIsclinics(false);
    }


    // const clientIds = selectedIndex !== -1 
    // ? (const clinicname =   clinicLocation?.filter(client => client?.clientId === selectedIndex))



    // : "";

  }
  return (
    <>
      <div className="MainEmailsTemplate ">
        <div className="rightSidebarWrapper">
          <div className="Main684WidthSet">
            <BusinessNavigation
              isColumn={true}
              title={"Email"}
              desc={"create email templates and send mass emails to your clients, these emails can be reminders or promotional messages you need to get across."}
              onBackClick={() => {
                setStep(0);
              }}
            />
            <div className="TopButtonWrapper">
              <div
                className="selectButton"
                onClick={() => {
                  setIsclinics(true);
                }}
              >
                <h6>{clinicName === undefined ? "Select clinic" : clinicName}</h6>
                <RightIcon />
              </div>
              <div
                className="selectButton"
                onClick={() => {
                  setIsselectClient(true);
                }}
              >
                <h6> {!clientTitlename ? "Select client" : (CheckedClients.length > 1 ? `${clientTitlename} +${CheckedClients.length - 1}` : clientTitlename)}</h6>
                <RightIcon />
              </div>
            </div>

            <div className="finance-input-container">
              <TextInput
                id="Subject"
                label="Subject"
                placeholder="create a catchy subject!"
                name="Subject"
                value={aboutDetails?.Subject}
                onChange={(e) => handleChange(e)}
              />
              {validationErrors.Subject && (
                <span className="error">{validationErrors.Subject}</span>
              )}
            </div>

            <div className="CustmeEditor">
              <CustomeCKEditor
                onChange={handleEditorChange}
                editorLoaded={true}
                name="editor"
              // value={editorValue}
              />
              {validationErrors.message && (
                <span className="error">{validationErrors.message}</span>
              )}
            </div>

            <div className="Certificate-docmentation-Wrapper">
              <div className="form-label">Attachment file</div>
              <div className="docmentationGrid-Wrapper">
                {otherDocs.map((doc, index) => (
                  // console.log(doc, index, "20320230")
                  <>
                    <div className="docmentationGridItem" key={index}>
                      {console.log(doc, index, "20320230")}
                      <Dropzone
                        handleMulti={setOtherDocs}
                        selectedIndex={index}
                        urlFromDB={doc.certificate_url}
                        // handleDelete={initDeleteFinanceTranningCertificateData}
                        usrVrfId={doc.id}
                        otherDocs={otherDocs}
                        imageFromComponent={doc.image}
                      // validation={validationErrors}

                      />
                    </div>
                  </>
                ))}

                {console.log(otherDocs?.length, "otherDocs?.length")}
                {otherDocs?.length === 5 ? null : <div className="docmentationGridItem">
                  <div className="dropzone" onClick={handleAddDropzone}>
                    <img src={UploadsImg} alt={UploadsImg} />
                    <p>Add Photo </p>
                  </div>
                </div>}
                {/* <div className="docmentationGridItem">
                  <div className="dropzone" onClick={handleAddDropzone}>
                    <img src={UploadsImg} alt={UploadsImg} />
                    <p>Add Photo </p>
                  </div>
                </div> */}
              </div>

              <p className="sizeText">
                Number of file 5, maximum size 10 MB ( per image 2 MB )
              </p>
              {validationErrors?.image && (
                <span className="error">{validationErrors?.image}</span>
              )}
            </div>

            <div className="btnWrapper">
              {/* <StepButton className={"outline"} label={"Preview"} /> */}
              <StepButton label={"Send"} onClick={sendMarketingEmail} />
            </div>

          </div>
        </div>
      </div >

      {/* Select clinics */}
      < OffCanvasModal
        isOpen={isclinics}
        className={"SmsCanvas"}
        isBack={true}
        placement={"end"}
        isCloseRight={true}
        onHide={() => setIsclinics(false)
        }
        // title=""
        body={
          < div className="SmsCanvasBody" >
            <h5>Select clinics</h5>

            <div className="smsGrid">
              <div className="smsItem">
                <div className=""></div>
                {clinicLocation?.map((item, index) => (

                  <CustomRadioButton
                    name="client"
                    id={item?.clinicId}
                    value={item?.clinicName}
                    checked={item?.clinicId === selectedIndex}
                    onChange={() => setSelectedIndex(item?.clinicId)}
                    isblacked={true}
                    customLabel={
                      <>
                        <div
                          className="client-left-container cursor-pointer"
                        // onClick={() => setSelectedIndex(item?.clentId)}
                        >
                          <div className="client-name-container">
                            <div className="leftRadioIcon">
                              <LocationIcon />
                            </div>
                            <h3>{item?.clinicName}</h3>
                          </div>
                        </div>
                      </>
                    }
                  />
                ))}
              </div>

              <StepButton
                label="Save"
                onClick={() => {
                  saveclientData()
                  // setIsclinics(false);
                }}
              />
            </div>
          </div >
        }
      />

      {/* Select client */}
      <OffCanvasModal
        isOpen={isselectClient}
        className={"SmsCanvas selectClientCanvas "}
        isBack={true}
        placement={"end"}
        isCloseRight={true}
        onHide={() => setIsselectClient(false)}
        body={
          <div className="SmsCanvasBody">
            <h5>Select client</h5>
            <div className="smsGrid">
              <div
                className="ViewSelected"
                onClick={() => {
                  setIsviewSelectClient(true);
                }}
              >
                <p>View selected clients ({totalSelctedclients})</p>
              </div>
              <div className="selectAllCheckBox">
                <label>
                  <p>Select All Clients</p>
                  <input
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                  />
                </label>
              </div>
              <div className="smsItem">
                {clientList?.map((checkbox) => (
                  <CheckboxComponent
                    key={checkbox?.clentId}
                    label={checkbox?.clientName}
                    isChecked={checkbox.isChecked}
                    onChange={() => handleCheckboxChange(checkbox?.clentId)}
                  />
                ))}
              </div>
              <StepButton
                label="Save"
                onClick={() => {
                  setclientTitlename(CheckedClients?.[0]?.clientName)
                  setIsselectClient(false);
                }}
              />
            </div>
          </div>
        }
      />

      {/* View Select client */}
      <OffCanvasModal
        isOpen={isviewSelectClient}
        className={"SmsCanvas selectClientCanvas viewSelectClientCanvas  "}
        isBack={true}
        placement={"end"}
        isCloseRight={true}
        onHide={() => setIsviewSelectClient(false)}
        body={
          <div className="SmsCanvasBody">
            <h5>{totalSelctedclients} Selected clients</h5>
            <div className="smsGrid">
              <div className="selectAllCheckBox">
                <label>
                  <p>{totalSelctedclients} Selected clients</p>
                  <input
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                  />
                </label>
              </div>
              <div className="smsItem">
                {checkboxes.length > 0 ? (
                  checkboxes.map((checkbox) => (
                    <CheckboxComponent
                      key={checkbox?.clentId}
                      label={checkbox?.clientName}
                      isChecked={checkbox.isChecked}
                      onChange={() => handleCheckboxChange(checkbox?.clentId)}
                    />
                  ))
                ) : (
                  CheckedClients?.map((checkbox) => (
                    <CheckboxComponent
                      key={checkbox?.clentId}
                      label={checkbox?.clientName}
                      isChecked={checkbox.isChecked}
                      onChange={() => handleCheckboxChange(checkbox?.clentId)}
                    />
                  ))
                )}

              </div>
              <StepButton
                label="Save"
                onClick={() => {
                  setIsviewSelectClient(false);
                }}
              />
            </div>
          </div>
        }
      />
    </>
  );
}

export default EmailsTemplate;
