import {
    GET_WALK_IN_CLIENT_LIST,
    GET_CLINIC_LOCATION_CONST,
    GET_CLINIC_SERVICES_LIST,
    GET_CONSANT_FORM_DATA_LIST,
    GET_AFTERCARE_FORM_DATA_LIST,
    GET_CONSENT_FORM_DETAILS,
    GET_USER_CONSANT_FORM_DATA_LIST,
    CLEAR_USER_CONSANT_FORM_DATA_LIST,
    CLEAR_CONSENT_FORM_DETAILS,
    GET_USER_AFTERCARE_FORM_DATA_LIST,
    GET_AFTERCARE_FORM_DETAILS,
    GET_WALK_IN_QUESTION_ANSWER_DATA
} from "../../constants/walk-in-form-const/clientWalkInConst";


const initialState = {
    consentFormList: [],
    walkInClientList: [],
    afterCareFormList: [],
    clinicLocationList: [],
    clinicServicesList: [],
    consentFormDetails: [],
    questionAnswerData: [],
    userConsentFormList: [],
    afterCareFormDetails: [],
    userAfterCareFormList: [],
};

export default function walkInClientListReducer(state = initialState, action) {
    switch (action.type) {

        case GET_WALK_IN_CLIENT_LIST:
            return {
                ...state,
                walkInClientList: action.payload,
            };
        case GET_CLINIC_LOCATION_CONST:
            return {
                ...state,
                clinicLocationList: action.payload,
            };
        case GET_CLINIC_SERVICES_LIST:
            return {
                ...state,
                clinicServicesList: action.payload,
            };
        case GET_CONSANT_FORM_DATA_LIST:
            return {
                ...state,
                consentFormList: action.payload,
            };
        case GET_AFTERCARE_FORM_DATA_LIST:
            return {
                ...state,
                afterCareFormList: action.payload,
            };
        case GET_CONSENT_FORM_DETAILS:
            return {
                ...state,
                consentFormDetails: action.payload,
            };

        case CLEAR_CONSENT_FORM_DETAILS:
            return {
                ...state,
                consentFormDetails: [],
            };
        case GET_USER_CONSANT_FORM_DATA_LIST:
            return {
                ...state,
                userConsentFormList: action.payload,
            };
        case CLEAR_USER_CONSANT_FORM_DATA_LIST:
            return {
                ...state,
                userConsentFormList: [],
            };
        case GET_USER_AFTERCARE_FORM_DATA_LIST:
            return {
                ...state,
                userAfterCareFormList: action.payload,
            };
        case GET_AFTERCARE_FORM_DETAILS:
            return {
                ...state,
                afterCareFormDetails: action.payload,
            };
        case GET_WALK_IN_QUESTION_ANSWER_DATA:
            return {
                ...state,
                questionAnswerData: action.payload,
            };


        default:
            return state;
    }

}




