import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import {GET_CLIENT_STATS} from "../../constants";

const GET_CLIENT_STATS_QUERY = gql`
query GetClientStats($clientId: Int!) {
    getClientStats(clientId: $clientId) {
      success
      message
      data {
        appointments
        treatments
        totalSpent
        noShows
      }
    }
  }
`;

var page;
export const GetClientStats = () => {
  const dispatch = useDispatch();
  const [getClientStatsData, clientStatsData] = useLazyQuery(
    GET_CLIENT_STATS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClientStats: responseData }) => {
        dispatch({
          type: GET_CLIENT_STATS,
          payload: responseData.data,
        });
      },
    }
  );
  const clientStatsQueryData = clientStatsData;
  const initGetClientStats = (data) => {
    getClientStatsData({
      variables: data,
    });
  };
  return { initGetClientStats, clientStatsQueryData };
};
