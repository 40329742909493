import { GET_RYFT_PAYMENT_TOKEN_FORWARD_CONSENT_FORM } from "../../constants/forward-consent-form/ForwardConsentFormConst";


const initialState={
    forwardConsentBookingToken:"",
    forwardConsentBookingAccountId:""
}

export default function getForwardConsentFormBookingTokenReducer(state=initialState,action){
  switch(action.type){
    case GET_RYFT_PAYMENT_TOKEN_FORWARD_CONSENT_FORM:
      return{
        ...state,
        forwardConsentBookingToken:action.payload.payment_session,
        forwardConsentBookingAccountId:action.payload.accountId,
      };
      default:
        return state
  }
}