import { GET_INVOICE_DATA_SUCCESS, GET_INVOICE_DOWNLOAD_DATA, GET_INVOICE_PAGE_DETAILS } from "../../constants/invoice/Invoice"

const initialState = {
    invoiceUrl: null,
    invoiceDetails: null,
}

export const DownloadInvoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVOICE_DATA_SUCCESS:
            return {
                ...state,
                invoiceUrl: action.payload.data.invoice_url,
            }

        default:
            return state
    }
}  

export const GetInvoiceDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
            case GET_INVOICE_PAGE_DETAILS:
                console.log(action.payload, "GET_INVOICE_PAGE_DETAILS")
                return{
                    ...state,
                    invoiceDetails: action.payload
                }
       
        default:
            return state
    }
}  