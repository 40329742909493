import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import showToast from "../../../common/CustomToast/CustomToast";

const UPDATE_HAIR_PRESCRIPTION_AMEND_ANSWERS = gql`
mutation UpdateHairPrescriptionAswers($clientAnswers: [clientUpdatedAnswersObject], $orderId: Int) {
    updateHairPrescriptionAswers(client_answers: $clientAnswers, orderId: $orderId) {
      success
      message
    }
  }
`;

export const UpdateHairPrescriptionAmendAnswerMutation = () => {
    const [updateHairPrescriptionAmendAnswer, UpdateHairPrescriptionAmendAnswerResponseData] = useMutation(UPDATE_HAIR_PRESCRIPTION_AMEND_ANSWERS);
    const dispatch = useDispatch();
    const UpdateHairPrescriptionAmendAnswerMutationData = UpdateHairPrescriptionAmendAnswerResponseData;

    const initiateUpdateHairPrescriptionAmendAnswerResponseData = (
        data,
        successCallback,
        errorCallback
    ) => {
        updateHairPrescriptionAmendAnswer({
            variables: data,
        })
            .then(({ data: { updateHairPrescriptionAswers: responseData } }) => {
                if (responseData.success) {
                    successCallback(responseData);
                } else {
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                errorCallback(error);
            });
    };
    return { initiateUpdateHairPrescriptionAmendAnswerResponseData, UpdateHairPrescriptionAmendAnswerMutationData };
};
