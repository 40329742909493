import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import {
    CHECK_WEBSITE_DOMAIN_RESPONSE,
    GET_WEBSITE_TeamMembers,
    TEAM_MEMBERS_DATA,
} from "../../constants";

//1 date-api
const GET_WEBISTE_BUILDER_DASHBOARD_QUERY = gql`
query GetWebsiteBuilderDashboard($type: Int) {
    getWebsiteBuilderDashboard(type: $type) {
      success
      message
      data {
        total_page_views_count
        total_page_view_percentage
        total_no_click_count
        total_no_click_percentage
        unique_visitors_count
        unique_visitors_percentage
        popular_treatments {
          title
          views
        }
        top_pages {
          title
          views
        }
        website_url_slug
        website_url
        update_date
        business_name
        logo_image_url
        is_treatment
      }
    }
  }
`;

export const GetWebsiteBuilderDashboardAction = () => {
    const dispatch = useDispatch();
    const [
        getWebsiteBuilderDashboardResponseFunction,
        getWebsiteBuilderDashboardReponseData,
    ] = useLazyQuery(GET_WEBISTE_BUILDER_DASHBOARD_QUERY, {
        // fetchPolicy: "cache-and-network",
        // nextFetchPolicy: "cache-and-network",
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
        onCompleted: ({ getWebsiteBuilderDashboard: responseData }) => {
            console.log("responseData", responseData, "data");
            // if (responseData?.success)
            //     dispatch({
            //         type: TEAM_MEMBERS_DATA,
            //         payload: responseData
            //     })
        },
    });
    //this will be called from components
    const getWebsiteBuilderDashboardResponseData =
        getWebsiteBuilderDashboardReponseData; //variable

    const initGetWebsiteBuilderResponseFunction = (data) => {
        getWebsiteBuilderDashboardResponseFunction({
            variables: data,
        });
    };
    return {
        initGetWebsiteBuilderResponseFunction,
        getWebsiteBuilderDashboardResponseData,
    };
};
