import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_FINANCE_TRANNING_CERTIFICATE = gql`
  query GetTrainingCertificatesForFinance {
    getTrainingCertificatesForFinance {
      success
      message
      data {
        certificates {
          id
          certificate_name
          certificate_url
          certificate_title
        }
      }
    }
  }
`;

export const GetFinanceTraningCertificateAction = () => {
  const [
    getFinanceTranningCertificateResponseFunction,
    getFiannceTranningCertificatereasponsedata,
  ] = useLazyQuery(GET_FINANCE_TRANNING_CERTIFICATE, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: ({ getTrainingCertificatesForFinance: responseData }) => {
      if (responseData.success) {
        console.log('res', responseData?.message);
      } else {
        console.log('error', responseData?.message);
      }
    },
  });

  const getFinanceTranningCertificateQueryData =
    getFiannceTranningCertificatereasponsedata;

  const initGetFinanceTranningCertificateData = (data) => {
    getFinanceTranningCertificateResponseFunction({
      variables: data,
    });
  };

  return {
    getFinanceTranningCertificateQueryData,
    initGetFinanceTranningCertificateData,
  };
};
