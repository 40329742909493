import React, { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from "../../../images/close_icon.svg";
import consentFormFilledIcon from "../../../images/icons/appointment-list/consentFormFilledIcon.svg";
import { ReactComponent as PrescriptionIcon } from "../../../images/icons/client-profile/pre_icon.svg";
import SkeletonLoader from '../../common/Skeleton/SkeletonLoader';
import StepButton from '../../common/StepButton';
import SwitchButton from '../../common/switchButton/SwitchButton';
import { ConnectedPrescriberListQuery } from '../../redux/actions/consent-form-thankyou/getConnectedPrescriberList';
import { CLEAR_PRESCRIBER_LIST } from '../../redux/constants/consentFormThankyou/getPrescriberListConst';
import "./prescription-list.scss";
import { GetListOfPendingPrescriptionsAction } from '../../redux/actions/prescription-list/getPrescriptionList';
import moment from 'moment';
import { CLEAR_PRESCRIPTIONS_LIST } from '../../redux/constants/getPrescriptionsListConst';
import medicalFormFilledIcon from "../../../images/icons/appointment-list/medicalFormFilledIcon.svg";
import CustomModal from '../../modal/CustomModal';
import noAppointmentList from '../../../images/icons/appointment-list/noAppointmentList.svg'
import viewMore from "../../../images/icons/client-profile/viewmoreproduct.svg";
import viewless from "../../../images/icons/client-profile/viewlessproduct.svg";
import useWindowDimension from '../../../utils/useWindowDimensions';
// import AnimateHeight from 'react-animate-height';


export const PrescriptionList = ({ sendtoPrescriberHandler, setIsPrescriberModalOpen, updatePrescriptionAttachedFormQueryData, prescriberId, clientId, setIsPrescriptionModalOpen, active }) => {

    //prescriber list
    const { prescriptionsList } = useSelector((state) => state.prescriptionsListReducer);
    const { totalPrescriptionsCount } = useSelector((state) => state.prescriptionsListReducer);
    const { loadedPrescriptionsCount } = useSelector((state) => state.prescriptionsListReducer);
    const { prescriptionsListPage } = useSelector((state) => state.prescriptionsListReducer);

    //GET CONNECTED PRESCRIBER LIST QUERY
    const { getListOfPendingPrescriptionsQueryData, initGetListOfPendingPrescriptionsData } = GetListOfPendingPrescriptionsAction()
    const [tempShowSelectedPrescription, setTempShowSelectedPrescription] = useState(null)
    const [checkPrescriberListForLoading, setCheckPresciberListForLoading] = useState(true)

    const [isMedicalAddForPrescription, setIsMedicalAddForPrescription] = useState(false)
    const [prescriptionsListData, setPrescriptionListData] = useState([])
    const [isMedicalFormAttachModalOpen, setIsMedicalFormAttachModalOpen] = useState(false)



    const dispatch = useDispatch()

    const initiatePrescriptionsListCall = (refreshList = false) => {
        let newPage = prescriptionsListPage + 1;
        if (refreshList) {
            newPage = 1;

        } else if (totalPrescriptionsCount <= loadedPrescriptionsCount) {
            return;
        }
        setCheckPresciberListForLoading(false)
        initGetListOfPendingPrescriptionsData({
            limit: 15,
            page: newPage,
            prescriberId: prescriberId,
            clientId: clientId
        }, (res) => {
            if (res.success) {

            } else {
            }
        }, (err) => {
        })
    }

    useEffect(() => {
        dispatch({
            type: CLEAR_PRESCRIPTIONS_LIST
        })
        initiatePrescriptionsListCall(true)
    }, [])

    useEffect(() => {
        if (prescriptionsList) {
            let temp = []
            prescriptionsList.map((mapItem) => {
                temp.push({ ...mapItem, maxValue: 0 })
            })
            setPrescriptionListData([...temp])
        }
    }, [prescriptionsList])

    const dimensions = useWindowDimension();
    const [headerStickyHeight, setHeaderStickyHeight] = useState(0);
    const [footerButton, setFooterButton] = useState(0);


    useEffect(() => {
        setHeaderStickyHeight(
            document.getElementsByClassName('Prescription-sticky-header')[0]?.offsetHeight
        );

        setFooterButton(document.getElementsByClassName('sticky-footer-calender')[0]?.offsetHeight)

        window.addEventListener('resize', handleResize);
    }, []);

    const handleResize = () => {
        setHeaderStickyHeight(
            document.getElementsByClassName('Prescription-sticky-header')[0]?.offsetHeight
        );

        setFooterButton(document.getElementsByClassName('sticky-footer-calender')[0]?.offsetHeight)
    };

    const truncateString = (str) => {
        return str?.length > 22 ? str?.substring(0, 22) + "..." : str;
    }
    const truncateProductNameString = (str) => {
        return str?.length > 22 ? str?.substring(0, 22) + "..." : str;
    }
    return (
        <div className='PrescriptionListMainWrapper'>
            <div className="Prescription-sticky-header">
                <div className="close-btn">
                    <CloseIcon className="close-icon-animation cursor-pointer" onClick={() => {
                        setIsPrescriptionModalOpen(false)
                        setIsPrescriberModalOpen(true)
                    }} />
                </div>
                <div className='secHeading'>
                    <h1>Select prescription</h1>
                </div>
            </div>
            <div className='select-presciber-container'>
                <div id="scrollableDivPrescriber" style={{ height: dimensions?.height - headerStickyHeight - footerButton - 30 + 'px', width: "100%", overflowY: "scroll" }} className={`${prescriptionsList?.length === 0 ? "no-prescriber-wrap-text" : ""}`}>
                    <InfiniteScroll
                        dataLength={loadedPrescriptionsCount}
                        next={() => {
                            initiatePrescriptionsListCall(false)
                        }}
                        hasMore={loadedPrescriptionsCount < totalPrescriptionsCount}
                        scrollableTarget="scrollableDivPrescriber"
                    >
                        <div className="select-presciber-wrapper">
                            {

                                prescriptionsListData?.length != 0 ? prescriptionsListData.map((prescription, index) => {
                                    return <>
                                        <div className='prescriber-list-wrapper' onClick={() => {
                                            setTempShowSelectedPrescription(prescription.prescriptionId);
                                            setIsMedicalAddForPrescription(prescription?.isMedicalFormAttached)
                                        }}>
                                            <label className={tempShowSelectedPrescription === prescription?.prescriptionId ? 'prescription-list-container selectedList' : 'prescription-list-container'} style={tempShowSelectedPrescription === prescription?.prescriptionId ? { border: "1px solid #000", boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.12)" } : {}} key={index}>
                                                <div className='left-list-content'>
                                                    <div className='IconWrapper'>
                                                        <PrescriptionIcon />
                                                    </div>
                                                    <div className='content-section'>
                                                        <div className='DropdownWrapper'>
                                                            <h4>
                                                                <span className='clampCss'>{truncateProductNameString(prescription?.productDetails[0]?.productName)} </span>
                                                                <span className='Quantity'>X {prescription?.productDetails[0]?.productQuantity}</span>
                                                            </h4> {prescription?.productDetails?.length > 1 && <img src={prescription?.viewMore ? viewless : viewMore}
                                                                onClick={() => {
                                                                    var itemsList = [...prescriptionsListData];
                                                                    itemsList[index] = {
                                                                        ...prescription, viewMore: !prescription.viewMore,
                                                                        maxValue: !prescription?.viewMore ? (25 * (prescription?.productDetails?.length - 1)) : 0
                                                                    };
                                                                    setPrescriptionListData(itemsList);

                                                                    // if(prescription?.viewMore){
                                                                    //     setMaxHeight(prescription?.productDetails?.length * 20)
                                                                    // }
                                                                }} alt="view" />}
                                                        </div>
                                                        {/* <AnimateHeight
                                                            id="example-panel"
                                                            duration={500}
                                                            height={prescription?.maxValue} // see props documentation below
                                                        >
                                                            <span className={'moreData'} >
                                                                {
                                                                    (prescription?.viewMore) &&
                                                                    prescription?.productDetails?.slice(1).map((item, index) => {
                                                                        console.log("prescription?.productDetail", prescription?.productDetails?.length)
                                                                        return <p className='view-more-product-list'>{`${truncateString(item?.productName)} X ${item?.productQuantity} `}</p>
                                                                    })
                                                                }
                                                            </span>
                                                        </AnimateHeight> */}


                                                        <p>{moment(prescription?.date, "YYYY-MM-DD").format("DD MMM YYYY")}</p>
                                                        <p>{`${prescription?.pharmacyName} ${prescription?.pharmacyCount > 0 ? `(+${prescription?.pharmacyCount})` : ""}`}</p>
                                                    </div>
                                                </div>
                                                <div className='RightRatingWrapper'>
                                                    {prescription?.isMedicalFormAttached && <img src={medicalFormFilledIcon} alt="consentform" />}
                                                    {prescription?.isConsentFormAvailable && <img src={consentFormFilledIcon} alt="consentform" />}
                                                </div>
                                            </label>
                                        </div>
                                    </>
                                })
                                    :
                                    getListOfPendingPrescriptionsQueryData?.loading || checkPrescriberListForLoading ? null :
                                        <div className='noDataFound'>
                                            <img src={noAppointmentList} alt="no appointment" />
                                            <p>No prescription found</p>
                                        </div>
                            }
                            {
                                getListOfPendingPrescriptionsQueryData?.loading ?
                                    <SkeletonLoader type="prescription-List" />
                                    :
                                    null
                            }
                        </div>
                    </InfiniteScroll>
                </div>

                <div className="sticky-footer-calender">
                    <StepButton label={"Done"} blue={true} onClick={() => {

                        if (active === "Consent" && !isMedicalAddForPrescription) {
                            setIsMedicalFormAttachModalOpen(true)
                        }
                        else {
                            sendtoPrescriberHandler({
                                prescriptionId: tempShowSelectedPrescription,
                                attachMedicalForm: isMedicalAddForPrescription
                            });
                        }

                    }}
                        disabled={tempShowSelectedPrescription ? false : true}
                        isLoading={updatePrescriptionAttachedFormQueryData?.loading}
                    />
                </div>
            </div>

            {/* no pharmacy registration popup */}
            <CustomModal
                modalOpen={isMedicalFormAttachModalOpen}
                setModalOpen={setIsMedicalFormAttachModalOpen}
                type={"common-width"}
                className={'customeNoPharmacyRegisterModel'}
                modalBody={
                    <>
                        <div className="share-booking-link-all-clients-confimration">
                            <p>
                                Do you want to add clients medical form?
                            </p>
                            <div className="btnWrapper">
                                <StepButton label={"Yes"} onClick={() => {
                                    setIsMedicalFormAttachModalOpen(false)
                                    sendtoPrescriberHandler({
                                        prescriptionId: tempShowSelectedPrescription,
                                        attachMedicalForm: true,
                                    });

                                }} blue={true}
                                // isLoading={updatePrescriptionAttachedFormQueryData?.loading}
                                />

                                <StepButton gray={true} label={"No"} onClick={() => {
                                    setIsMedicalFormAttachModalOpen(false)
                                    sendtoPrescriberHandler({
                                        prescriptionId: tempShowSelectedPrescription,
                                        attachMedicalForm: false,
                                    });
                                }}
                                // isLoading={updatePrescriptionAttachedFormQueryData?.loading}
                                />
                            </div>
                        </div>
                    </>
                }
            />
        </div>
    )
}
