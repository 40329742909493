import React from 'react'
import './HairProductInfoPopup.scss'
import checked from '../../../../assets/HairProduct/check_normal.svg'
import Hairloss1 from '../../../../assets/HairProduct/female-hair-loss 1.svg'
import Hairloss2 from '../../../../assets/HairProduct/female-hair-loss 2.svg'
import { useNavigate } from 'react-router'
import { hairCareLandingPageRouteRequest } from '../../../../Routes'

const HairProductInfoPopup = ({setIsIntroScreen}) => {
    const navigate = useNavigate()

    return (
        <>
            <div className='hair-product-info-popup-main-container'>
                <div className='hair-product-info-popup-container row align-items-center gx-5'>
                    <div className="hair-popup-left-content col-md-6">
                        <h2>Suffering hair loss?</h2>
                        <div className="sub-content-pop-up">
                            <p>Over 14 million people in the UK suffer from hair loss or thinning. Choose from topical or oral prescription treatments, clinically proven to restore hair and reduce loss.</p>
                        </div>
                        <div className="checked-content">
                            <div className="d-flex gap-1">
                                <img src={checked} alt="checked" />
                                <p className='m-0'>Prescribed by medical professionals</p>
                            </div>
                            <div className="d-flex gap-1">
                                <img src={checked} alt="checked" />
                                <p className='m-0'>Pharmaceutical grade treatments</p>
                            </div>
                            <div className="d-flex gap-1">
                                <img src={checked} alt="checked" />
                                <p className='m-0'>Discreet and delivered to your door</p>
                            </div>
                        </div>
                        <div className='d-flex align-items-center mt-4 gap-4 offer-btn-wrapper'>
                            <button className='offer-btn' onClick={() => setIsIntroScreen(false)} >Take online assesment</button>
                            <p className='learn-link' onClick={() =>{ navigate(hairCareLandingPageRouteRequest) }}>Need to know more</p>
                        </div>
                    </div>


                    <div className="hair-popup-right-content col-md-6">
                        <div className="hair-loss-img-div row">
                            <div className="hair-loss-img col-6 gap-3">
                                <img src={Hairloss1} alt="Before treatment" />
                                <p className='text-center'>Before treatment</p>
                            </div>
                            <div className="hair-loss-img col-6 gap-3">
                                <img src={Hairloss2} alt="After treatment" />
                                <p className='text-center'>After treatment</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HairProductInfoPopup