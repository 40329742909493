// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { STORE_USER_EMAIL} from "../../constants";

const ONBOARDING_STEPS_QUERY = gql`
query GetOnboardingInfoForFinance {
  getOnboardingInfoForFinance {
    success
    message
    data {
      basic_information_completed
      documents_uploaded
      bank_details_added
      card_linked
      admin_approved
      admin_rejected
      rejection_reason
      finance_loan_request_status {
        flr_id
        finance_loan_request_status
      }
    }
  }
}
`;

export const OnboardingStepsForFinance = () => {
  const dispatch = useDispatch();

  const [
    onboardingStepsForFinanceFunction,
    onboardingStepsForFinanceResponseData,
  ] = useLazyQuery(ONBOARDING_STEPS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getOnboardingInfoForFinance: responseData }) => {
      if (responseData.success) {
     console.log("responseData=============",responseData)
      } else {
       
      }
    },
  });
  //this will be called from components
  const getUserDetailsForFirstStepdDataQueryData = onboardingStepsForFinanceResponseData; //variable

  const initonboardingStepsForFinanceData = (data,successCallback,errorCallback) => {
    onboardingStepsForFinanceFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.getOnboardingInfoForFinance?.success) {
        successCallback(res);
      } else {
        console.log('res error', res);
        errorCallback(res);
      }
    }).catch((err) => {
      console.log('res err', err);
      errorCallback(err);
    });
  };

  return { getUserDetailsForFirstStepdDataQueryData, initonboardingStepsForFinanceData };
};
