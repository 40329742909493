import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const ImageUploader = (props) => {
  const { divStyle, imgStyle, onImageUpload, imgUrl } = props;
  console.log('imgUrl:', imgUrl, 'divStyle:', divStyle, 'imgStyle:', imgStyle);
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  useEffect(() => {
    console.log('imgUrl:', imgUrl);
  }, []);

  const onDrop = useCallback((file) => {
    console.log(file);
    setAcceptedFiles(file);
    onImageUpload(file);
  }, []);

  const sstyle = {
    width: '100%',
    height: '100%',
    minHeight: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  console.log('hihi', {...getInputProps()});
  return (
    <div {...getRootProps()} style={divStyle} className={props.className}>
      <input {...getInputProps()} id="tmpClickElement" />
      {!imgUrl && acceptedFiles.length < 1 ? (
        <p>Drag & drop or click to choose files</p>
      ) : null}
      {acceptedFiles.length < 1 && imgUrl ? (
        imgUrl.includes('png') || imgUrl.includes('jpg') || imgUrl.includes('jpeg') ?
          <img src={imgUrl} style={imgStyle} /> : (
            <>
              <img
                key={imgUrl}
                src="https://img.icons8.com/ios/452/file.png"
                style={imgStyle}
              />
              <p style={imgStyle}>
                {imgUrl.length > 10
                  ? imgUrl?.substring(0, 10) + '...'
                  : imgUrl}
              </p>
            </>
          )
      ) : (
        acceptedFiles.map((file) => (
          file.type.includes('image') ? (
            <img
              key={file.name}
              src={URL.createObjectURL(file)}
              style={imgStyle}
            />
          ) : (
            <>
              <img
                key={file.name}
                src="https://img.icons8.com/ios/452/file.png"
                style={imgStyle}
                width={100}
                height={100}
              />
              <p>
                {file.name.length > 10
                  ? file.name.substring(0, 10) + '...'
                  : file.name}
              </p>
            </>
          )
        ))
      )}
    </div>
  );
};

export default ImageUploader;
