import React from 'react'
import Modal from 'react-modal'
import './cancle-modal.scss'
import { ReactComponent as CloseIcon } from '../../../images/close_icon.svg'
import StepButton from '../../common/StepButton'
import useDeviceDetect from '../../common/useDeviceDetect'
import { CancelTreatmentBooking } from '../../redux/actions/treatmentBooking/createTreatmentBooking'
import { useLocation, useNavigate, useParams } from 'react-router'


const CancleModal = ({ isOpen, setIsOpen, bookingId }) => {
  const { slug, status } = useParams()

  const { screenWidth } = useDeviceDetect()
  const navigate = useNavigate()
  const { cancelTreatmentBookingMutationData: { loading: cancleLoading }, initiateCancelTreatmentBooking } = CancelTreatmentBooking()
  const location = useLocation()
  const data = location.state
  const handleCancleBooking = () => {

    initiateCancelTreatmentBooking({
      bookingId: bookingId ? bookingId : data?.bookingId,
    }, (res) => {
      if (res.success) {
        setIsOpen(false)
        navigate(`/bookings/${slug}`)
      } else {
        setIsOpen(false)
      }
    }, (e) => {
    })
  }
  return (
    <>
      {
        screenWidth < 768
          ?
          <Modal className={"cancel-modal"}
            isOpen={isOpen}
            style={{
              overlay: {
                background: "rgba(0, 0, 0, 0.5)"
              },
              content: {
                borderRadius: "30px",
                height: "fit-content",
                width: "fit-content",
                position: "absolute",
                transform: "translate(-50%, -50%)",
                top: "50%",
                left: "50%"
              }
            }}
          >
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                <CloseIcon className="cursor-pointer" onClick={() => { setIsOpen(false) }} />
              </div>
              <div className='cancle-popup-container mt-5'>
                <h1>Are you sure?</h1>
                <p>Do you really want to bin this ID? Once it's gone, it's gone for good!</p>
                <StepButton red={true} label={"Cancel"} onClick={handleCancleBooking} isLoading={cancleLoading} />
              </div>
            </>
          </Modal>
          :
          <Modal className={"cancel-modal"}
            isOpen={isOpen}
            style={{
              overlay: {
                background: "rgba(0, 0, 0, 0.5)"
              },
              content: {
                borderRadius: "30px",
                height: "fit-content",
                width: "fit-content",
                position: "absolute",
                transform: "translate(-50%, -50%)",
                top: "50%",
                left: "50%"
              }
            }}
          >
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                <CloseIcon className="cursor-pointer" onClick={() => { setIsOpen(false) }} />
              </div>
              <div className='cancle-popup-container mt-2'>
                <h1>Are you sure?</h1>
                <p>Do you really want to bin this ID? Once it's gone, it's gone for good!</p>
                <StepButton red={true} label={"Cancel"} onClick={handleCancleBooking} />
              </div>
            </>
          </Modal>

      }

    </>
  )
}

export default CancleModal