import React, { createRef, useEffect, useState } from "react";
import "./treatment-listing.scss";
import "./treatment-tabs.scss";
import { Helmet } from "react-helmet";

import { useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import useDeviceDetect from "../../common/useDeviceDetect";
import { GetTreatmentsForUser } from "../../redux/actions/treatmentList/getTreatmentsForUser";
import TreatmentListMobile from "./TreatmentListMobile";
import TreatmentListWeb from "./TreatmentListWeb";
import { webBaseUrl } from "../../../config";
const TreatmentListing = ({ setStep, step, setSelectedTreatment, selectedTreatment, data, setClinicId, clinicId, setSelectedChecked, selectedChecked, setSelectedTotal, selectedTotal ,setSubStep,subStep,selectedLocation, setSelectedLocation }) => {
  var isMobile;
  console.log(data,selectedLocation,"dbdbjhdbj");
  const { deviceType, screenWidth } = useDeviceDetect();
  const { initGetTreatmentsForUser, userTreatmentsQueryData } = GetTreatmentsForUser();
  const [isLoading, setIsLoading] = useState(false)

  //Get data from API
  const treatmentData = useSelector((state) => state.businessTreatmentsListReducer?.treatmentList?.items)
  const { treatmentList } = useSelector((state) => state.businessTreatmentsListReducer)


  const [isDevice, setIsDevice] = useState(false)
  const handleStepChange = () => {
    if (data?.type == 'clinic')
      setStep(1)

    if (data?.type == 'treatment')
      setStep(2)

    if (data?.type == 'date')
      setStep(301)

    if (data?.type == 'time')
      setStep(302)

    if (data?.type == 'client')
      setStep(303)

  }


  var settings1 = {
    slidesToShow: 5,
    slidesToScroll: 3,
    infinite: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          className: "center",
          // dots: true
        },
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          className: "center",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };



  useEffect(() => {
    if (deviceType === "Mobile") {
      setIsDevice(true)
    }
  }, [deviceType, screenWidth])

  useEffect(() => {
    if (treatmentList?.length == 0)
      initGetTreatmentsForUser({
        clinicId: clinicId
      })
  }, [])
  const [sectionRef, setSectionRef] = useState([])
  useEffect(() => {
    if (treatmentData?.length > 0) {
      const newSectionRef = treatmentData?.map(() => createRef())
      setSectionRef(newSectionRef)
    }
  }, [treatmentData])

  console.log(data,selectedLocation,"jkhkhjk")
  return (

    <>
     <Helmet>
     <meta name="robots" content="noindex, nofollow" />
     {/* <meta name="X-Robots-Tag" content="noindex, nofollow" /> */}
        <link
          rel="canonical"
          href={`${webBaseUrl}/clinics/${data?.slug}`}
        />
      </Helmet>

      {
        screenWidth < 720 ?
          <TreatmentListMobile setStep={setStep} setSubStep={setSubStep} subStep={subStep} step={step} setSelectedTotal={setSelectedTotal} selectedTotal={selectedTotal} setSelectedChecked={setSelectedChecked} selectedChecked={selectedChecked} setSelectedTreatment={setSelectedTreatment} selectedTreatment={selectedTreatment} sectionRefs={sectionRef} treatmentData={treatmentData} isLoading={userTreatmentsQueryData?.loading} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} />
          :
          <TreatmentListWeb setStep={setStep} step={step} setSelectedTreatment={setSelectedTreatment} selectedTreatment={selectedTreatment} sectionRefs={sectionRef} treatmentData={treatmentData} isLoading={userTreatmentsQueryData?.loading} selectedLocation={selectedLocation} />
      }

    </>
  );
};

export default TreatmentListing;
