import React, { useState } from "react";
import "./Password.scss";
import TextInput from "../../../../common/TextInput";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
 import StepButton from "../../../../common/StepButton";
import BusinessNavigation from "../../../../common/businessNavigation/BusinessNavigation";

function Password({  onClickSubmit, setStep  }) {

  const [passwordDetails, setPasswordDetails] = useState({
    Oldpassword: "",
    Newpassword: "",
    ConfirmNewPassword: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    Oldpassword: "",
    Newpassword: "",
    ConfirmNewPassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    Oldpassword: false,
    Newpassword: false,
    ConfirmNewPassword: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordDetails((prev) => ({
      ...prev,
      [name]: value,

    }));

    if (name === "Oldpassword" && value.length > 0) {
      setValidationErrors((prev) => ({
        ...prev,
        Oldpassword: "",
      }));
    }
    else if (name === "Newpassword" && value.length > 0) {
      setValidationErrors((prev) => ({
        ...prev,
        Newpassword: "",
      }));
    }
    else if (name === "ConfirmNewPassword" && value.length > 0) {
      setValidationErrors((prev) => ({
        ...prev,
        ConfirmNewPassword: "",
      }));
    }
  };

  const togglePasswordVisibility = (name) => {
    setShowPassword((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };


  return (
    <div className="Main-Password Main684WidthSet">
      <BusinessNavigation
        isColumn={true}
        title={"Password"}
        desc={'Update your password from here.'}
        onBackClick={() => { setStep(0) }}
      />

      <div className="fieldWrapper">
        <p>Update your password</p>
        <div className="finance-input-container">
          <div className="InputIconWrapper">
            <TextInput
              id="Oldpassword"
              label="Old password"
              type={showPassword.Oldpassword ? "text" : "password"}
              placeholder="Old password"
              name="Oldpassword"
              value={passwordDetails.Oldpassword}
              onChange={(e) => handleChange(e)}
              endIcon={false}
            />
            <button
              className="password-toggle-btn"
              onClick={() => togglePasswordVisibility("Oldpassword")}
            >
              {showPassword.Oldpassword ? <FaRegEye /> : <FaRegEyeSlash />}
            </button>
          </div>
          {validationErrors.Oldpassword && (
            <span className="error">{validationErrors.Oldpassword}</span>
          )}
        </div>
        <div className="finance-input-container">
          <div className="InputIconWrapper">
            <TextInput
              id="Newpassword"
              label="New password"
              type={showPassword.Newpassword ? "text" : "password"}
              placeholder="New password"
              name="Newpassword"
              value={passwordDetails.Newpassword}

              onChange={(e) => handleChange(e)}
            />
            <button
              className="password-toggle-btn"
              onClick={() => togglePasswordVisibility("Newpassword")}
            >
              {showPassword.Newpassword ? <FaRegEye /> : <FaRegEyeSlash />}
            </button>
          </div>
          {validationErrors.Newpassword && (
            <span className="error">{validationErrors.Newpassword}</span>
          )}
        </div>
        <div className="finance-input-container">
          <div className="InputIconWrapper">
            <TextInput
              id="ConfirmNewPassword"
              label="Confirm new password"
              type={showPassword.ConfirmNewPassword ? "text" : "password"}
              placeholder="Confirm new password"
              name="ConfirmNewPassword"
              value={passwordDetails.ConfirmNewPassword}
              onChange={(e) => handleChange(e)}
            />
            <button
              className="password-toggle-btn"
              onClick={() => togglePasswordVisibility("ConfirmNewPassword")}
            >
              {showPassword.ConfirmNewPassword ? <FaRegEye /> : <FaRegEyeSlash />}
            </button>
          </div>
          {validationErrors.ConfirmNewPassword && (
            <span className="error">{validationErrors.ConfirmNewPassword}</span>
          )}
        </div>

        <StepButton
          label={'Update'}
          // onClick={onClickSubmit}
          onClick={() => {

            if (passwordDetails.ConfirmNewPassword === "" || passwordDetails.Newpassword === "" || passwordDetails.Oldpassword === "") {
              if (passwordDetails.ConfirmNewPassword === "" && passwordDetails.Newpassword === "" && passwordDetails.Oldpassword === "") {
                setValidationErrors((prev) => ({
                  ...prev,
                  Oldpassword: "Old password is required",
                  Newpassword: "New password is required",
                  ConfirmNewPassword: "Confirm NewPassword is required",
                }));
              }

              else if (passwordDetails.Oldpassword === "") {
                setValidationErrors((prev) => ({
                  ...prev,
                  Oldpassword: "Old password is required",
                }));
              }
              else if (passwordDetails.Newpassword === "") {
                setValidationErrors((prev) => ({
                  ...prev,
                  Newpassword: "New password is required",
                }));
              }
              else if (passwordDetails.ConfirmNewPassword === "") {
                setValidationErrors((prev) => ({
                  ...prev,
                  ConfirmNewPassword: "Confirm NewPassword is required",
                }));
              }

            }
            else {
              onClickSubmit(passwordDetails)
            }
          }

          }
        />
      </div>
    </div>
  );
}

export default Password;
