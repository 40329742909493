import React, { useState } from 'react'
import './forward-cosent-form-main.scss'
import closeIcon from '../../images/close_icon.svg'
import docsIcon from '../../images/client/document.svg'
import StepButton from '../common/StepButton'
import ClientConsentBookingComplete from './ClientConsentFormComplete'

const ClientConsentFormPrew = () => {
    const [currentStep, setCurrentStep] = useState(1);

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };
    return (
        <>
            {currentStep === 1 && (
                <div className='container'>
                    <div className='mt-5 close-icon'>
                        <img src={closeIcon} alt="backArrow" className="back-arrow" />
                    </div>
                    <div className='consent_booking_main'>
                        <div className='complete-img'>
                            <img src={docsIcon} alt="complete" />
                            <div className='complete-text'>
                                <h3>Consent complete</h3>
                                <p>Your consent form is complete. The clinic will reach out if more information is required.</p>
                            </div>
                        </div>
                        <div className='complete-booking-process'>
                            <span>Booking process</span>
                            <hr />
                            <div className='booking-complete'>
                                <div className='content-details'>
                                    <h3>Booked with online deposit</h3>
                                    <p>Today 12:50</p>
                                </div>
                            </div>

                            <div className='booking-complete mt-4'>
                                <div className='content-details'>
                                    <h3>Consent completed</h3>
                                    <p>Today, 12:50</p>
                                </div>
                            </div>

                            <div className='booking-due mt-4'>
                                <div className='content-details'>
                                    <h3>Appointment on 10th November</h3>
                                    <p>Outstanding balance of £180.00.</p>
                                </div>
                            </div>
                        </div>
                        <div className='step-btn'>
                            <StepButton label={'Done'} blue={true} onClick={handleNextStep} />
                        </div>

                    </div>

                </div>
            )}
            {currentStep === 2 && <ClientConsentBookingComplete currentStep={currentStep} setCurrentStep={setCurrentStep} />}

        </>
    )
}

export default ClientConsentFormPrew
