import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { CHECK_WEBSITE_DOMAIN_RESPONSE, GET_WEBSITE_IMAGE, GET_WEBSITE_IMAGE_COVER_PHOTO, GET_WEBSITE_IMAGE_LOGO, GET_WEBSITE_Image, TEAM_MEMBERS_DATA } from "../../constants";


//1 date-api
const GET_WEB_IMAGE_QUERY = gql`
query GetPersonalWebsiteImage($imageType: Int) {
    getPersonalWebsiteImage(image_type: $imageType) {
      success
      message
      data {
        image {
          name
          image_url
          type
        }
      }
      
    }
  }
`

export const GetWebsiteImageAction = () => {
  const dispatch = useDispatch()
  const [getWebsiteImageResponseFunction, getWebsiteImageReponseData] = useLazyQuery(
    GET_WEB_IMAGE_QUERY,
    {
      // fetchPolicy: "cache-and-network",
      // nextFetchPolicy: "cache-and-network",
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted: ({ getPersonalWebsiteImage: responseData }) => {
        console.log("responseData website image", responseData, "data")
        if (responseData?.success)
          if (responseData?.data?.image?.type === "logo")
            dispatch({
              type: GET_WEBSITE_IMAGE_LOGO,
              payload: responseData
            })
          else if (responseData?.data?.image?.type === "cover_photo") {
            dispatch({
              type: GET_WEBSITE_IMAGE_COVER_PHOTO,
              payload: responseData
            })
          }
      }
    }
  )
  //this will be called from components
  const GetWebsiteImageResponseData = getWebsiteImageReponseData //variable

  const initGetImageResponseFunction = (data) => {

    getWebsiteImageResponseFunction({
      variables: data
    })
  }
  return { initGetImageResponseFunction, GetWebsiteImageResponseData }
}

