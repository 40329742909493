import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { GET_RYFT_PAYMENT_TOKEN } from "../../constants/treatmentBooking/treatmentBooking";
import { error } from "jquery";

//Direct Deposit
const GET_DIRECT_DEPOSIT_RYFT_PAYMENT_TOKEN_QUERY = gql`
query GetDepositRyftPaymentToken($depositId: Int, $paymentType: Int,$token : String ,$redirectUrl: String) {
  getDepositRyftPaymentToken(deposit_id: $depositId, payment_type: $paymentType,token:$token, redirectURL: $redirectUrl) {
    success
    message
    data {
      payment_session
      accountId
    }
  }
}`

export const GetDirectDepositRyftPaymentToken = () => {
  const dispatch = useDispatch();
  const [GetDirDepositRyftPayment, getDirDepositRyftPaymentResponseData] =
    useLazyQuery(GET_DIRECT_DEPOSIT_RYFT_PAYMENT_TOKEN_QUERY, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getDepositRyftPaymentToken: responseData }) => {
        dispatch({
          type: GET_RYFT_PAYMENT_TOKEN,
          payload: responseData.data
        })
        if (responseData.success) {
          // toast.success(responseData?.message)
        } else {
          // toast.error(responseData?.message)
        }
      },
    });
  //this will be called from components
  const getDirDepositRyftPaymentQueryData = getDirDepositRyftPaymentResponseData; //variable

  const initDirDipositRyftPaymentToken = (data, successCallback, errorCallback) => {
    GetDirDepositRyftPayment({
      variables: data,
    }).then((res) => {
      successCallback(res)
    }).catch((e) => {
      errorCallback(e)
    });
  };

  return { getDirDepositRyftPaymentQueryData, initDirDipositRyftPaymentToken };
};


//getVerifyLinkForDirectDiposit
const GET_VERIFY_LINK_FOR_DIRECT_DEPOSIT_QUERY = gql`
query GetVerifyLinkForDirectDiposit($depositId: Int, $token: String) {
  getVerifyLinkForDirectDiposit(deposit_id: $depositId, token: $token) {
    success
    message
    data {
      dir_dep_total_amount
      dir_dep_deposit_status
      is_ryft_setup
      is_stripe_setup
    }
  }
}`

// dir_dep_id
// dir_dep_usr_id
// clientData {
//   cl_dt_name
//   cl_dt_email
// }

export const GetVerifyLinkForDirectDiposit = () => {
  const dispatch = useDispatch();
  const [GetVerifyLinkForDirDiposit, getVerifyLinkForDirDepositResData] =
    useLazyQuery(GET_VERIFY_LINK_FOR_DIRECT_DEPOSIT_QUERY, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getDepositRyftPaymentToken: responseData }) => {
        dispatch({
          type: GET_RYFT_PAYMENT_TOKEN,
          payload: responseData.data
        })
        if (responseData.success) {
          // toast.success(responseData?.message)
        } else {
          // toast.error(responseData?.message)
        }
      },
    });
  //this will be called from components
  const getVerifyLinkForDirDepositQueryData = getVerifyLinkForDirDepositResData; //variable

  const initVerifyTokenForDirDeposit = (data, successCallback, errorCallback) => {
    GetVerifyLinkForDirDiposit({
      variables: data,
    }).then((res) => {
      successCallback(res)
    }).catch((e) => {
      errorCallback(e)
    });
  };

  return { getVerifyLinkForDirDepositQueryData, initVerifyTokenForDirDeposit };
};