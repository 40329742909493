import { CLEAR_TREATMENT_DETAILS, GET_TREATMENTS_FOR_USER } from "../../constants/treatment-list-const/TreatmentListConst";



// const initialState = {
//   businessClinicList: null,
//   treatmentsForUser: [],
//   deleteStatus: false,
//   totalTreatments: 0,
//   loadedTreatmentsCount: 0,
//   clinicListPract:[],
//   totalClinics: 0,
//   loadedClinicsCount: 0,
//   newTreatmentList: null
// };

const initialState = {
  treatmentList: []
}

export default function businessTreatmentsListReducer(state = initialState, action) {

  switch (action.type) {

    case GET_TREATMENTS_FOR_USER:

      return {
        ...state,
        treatmentList: action.payload,
      };

    case CLEAR_TREATMENT_DETAILS:

      return {
        ...state,
        treatmentList: [],
      };

    default:
      return state;
  }



}