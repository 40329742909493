import React, { useEffect, useState } from 'react'
import "./onetimechangecalender.scss"
import moment from 'moment'
import CustomCalendar from '../../pages/dateTime/steps/CustomCalendar';
import StepButton from '../../common/StepButton';
import { GetCalendarAppointmentCountAction, GetClinicAvailableDateAction } from '../../redux/actions/dateTimeslots/DateTimeSlots';
import timeclock from "../../../../src/images/time.png"

const OneTimeChangeCalender = ({
    setStep,
    setCalenderDisplayDate,
    setShowSelectedDate,
    setStartDate,
    setEndDate,
    selectedMonth,
    selectedYear,
    setSelectedMonth,
    setSelectedYear,
    showSelectedDate,
    calendarStartDate,
    setCalendarStartDate,
    dateData,
    setDateData,
    startDate,
    endDate,
    setPreviousSelectedDate,
    selectedLocationData,
    workingHoursAvability
}) => {
    const { clinicsAvailableDateData, initClinicsAvailableDateResponseFunction } = GetClinicAvailableDateAction();
    // const { getCalendarAppointmentCountQueryData, initGetCalendarAppointmentCountData } = GetCalendarAppointmentCountAction()


    useEffect(() => {
        if (selectedLocationData?.clinicId && endDate) {
            // if (moment(startDate).format("YYYY-MM") == moment().format("YYYY-MM")) {
            //     setDateData([])
            //     initClinicsAvailableDateResponseFunction({
            //         clinicId: selectedLocationData?.clinicId,
            //         startDate: moment().format("YYYY-MM-DD"),
            //         endDate: endDate,
            //     });
            // } else {
            //     initClinicsAvailableDateResponseFunction({
            //         clinicId: selectedLocationData?.clinicId,
            //         startDate: startDate,
            //         endDate: endDate,
            //     });
            // }
            initClinicsAvailableDateResponseFunction({
                clinicId: selectedLocationData?.clinicId,
                startDate: moment(startDate, "YYYY-MM-DD").clone().startOf('month').format("YYYY-MM-DD"),
                endDate: moment(endDate, "YYYY-MM-DD").clone().endOf('month').format("YYYY-MM-DD"),
            });
        }

        // initGetCalendarAppointmentCountData({
        //     startDate: moment(startDate, "YYYY-MM-DD").clone().startOf('month').format("YYYY-MM-DD"),
        //     endDate: moment(endDate, "YYYY-MM-DD").clone().endOf('month').format("YYYY-MM-DD"),
        //     locations: selectedLocationData?.length == 0 ? [] : [selectedLocationData?.clinicId]

        // })

        console.log("endDate", endDate)
    }, [startDate, endDate, selectedLocationData?.clinicId])



    const oneTimeOffHandler = () => {
        setCalenderDisplayDate(calendarStartDate);
        setStep(2);
        setPreviousSelectedDate(calendarStartDate)
    }

    //GENERATE CUSTOM DATES
    const generateCustomDates = () => {
        const startDateStr = moment(startDate);

        // Get the first day of the month
        const firstDayOfMonth = startDateStr.clone().startOf('month');

        // Get the last day of the month
        const lastDayOfMonth = startDateStr.clone().endOf('month');

        // Generate an array of dates for the month
        const datesArray = [];
        let currentDate = firstDayOfMonth.clone();

        while (currentDate.isSameOrBefore(lastDayOfMonth)) {
            // Check if the current date is not in the past
            // if (currentDate.isSameOrAfter(moment(), 'day')) {
            datesArray.push(currentDate.format('YYYY-MM-DD'));
            // }

            // Move to the next day
            currentDate.add(1, 'day');
        }

        return datesArray;
    }
    console.log("oneoff", workingHoursAvability)

    return (
        <div className="one-time-change-calender-container">
            <div className='header-title-one-time-change'>
                <h1>One-off change</h1>
                <p>Select a date to change the working hours.</p>
                <div className='w-100'>
                    <CustomCalendar
                        setShowSelectedDate={setShowSelectedDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                        setSelectedMonth={setSelectedMonth}
                        setSelectedYear={setSelectedYear}
                        showSelectedDate={showSelectedDate}
                        calendarStartDate={calendarStartDate}
                        setCalendarStartDate={setCalendarStartDate}
                        dateData={
                            (selectedLocationData?.clinicId == undefined) ? generateCustomDates() :
                                clinicsAvailableDateData?.data?.getClinicAvailableDates?.data?.items
                        }
                        ignoreSelectedDate={true}
                        isPassedDateEnabled={true}
                        // isMonthlyDailyViewCalender={true}
                        viewCalendarState={"timeGridDay"}
                        onChangeCallback={(date) => {
                            if (moment(date, "ddd DD MMM YYYY").format("dddd, Do MMMM YYYY") == showSelectedDate) {
                                setCalenderDisplayDate(calendarStartDate); setStep(2); setPreviousSelectedDate(calendarStartDate)
                            }
                        }}
                        // appointmentDatesCount={
                        //     getCalendarAppointmentCountQueryData?.data?.getCalendarAppointmentCount?.data?.appointmentCountData
                        // }
                        isStrikeClick={true}
                        isCalendarPreviousDateEnable={true}
                        isMonthlyDailyViewCalender={selectedLocationData?.clinicId ? false : true}
                        isShowMobileButton = {false}

                    />
                </div>
                <div className='working-hours-container'>
                    <div className='working-hours-img-text'>
                        <div className='time-clock-container'>
                            <img src={timeclock} />
                        </div>
                        {workingHoursAvability?.isBlock ? <p>You have blocked out {moment(calendarStartDate).format('DD MMM, YYYY')} for entire day</p> :
                            !workingHoursAvability?.isOpen ? <p>You're not working on {moment(calendarStartDate).format('DD MMM, YYYY')}</p> : <p>Working hours</p>}
                    </div>
                    <div className="working-hour-time pe-none">
                        {/* <p>12:00 – 11:00</p> */}
                        {workingHoursAvability?.isOpen && <p>{`${workingHoursAvability?.opens} – ${workingHoursAvability?.closes}`}</p>}
                    </div>
                </div>
                <StepButton blue={true} label={`Edit ${moment(calendarStartDate).format('DD MMM, YYYY')}`} className={"w-100"} onClick={oneTimeOffHandler} />
            </div>
        </div>
    )
}

export default OneTimeChangeCalender