import React from 'react'
import "./HairProductHeader.scss"
import PropTypes from "prop-types"
import { ReactComponent as BackArrow } from '../../../../../assets/HairProduct/Back.svg'
import { ReactComponent as FacesLogo } from '../../../../../assets/HairProduct/Faces.svg'
import { ReactComponent as HairProduct } from '../../../../../assets/HairProduct/Hair-care-logo.svg'

const HairProductHeader = ({isBackBtn, onBackClick, ViewOverviewBtn, ViewOverviewBtnClick }) => {
    return (
        <div className="header-border">
            <div className='hair-product-header container'>
                <div className="left-content">
                {
                    isBackBtn && 
                    <BackArrow className='cursor-pointer' onClick={onBackClick} />
                }
                    <FacesLogo className='Logo-desktop' />
                </div>
                <div className="right-content">
                    <HairProduct className='' />
                    {ViewOverviewBtn && 
                        <button className='ViewOverviewBtn' onClick={ViewOverviewBtnClick}>View Overview</button>
                    }
                </div>
            </div>
        </div>
    )
}

HairProductHeader.propTypes = {
    onBackClick: PropTypes.func.isRequired
}

export default HairProductHeader