import moment from "moment";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CalendarRoutePattern, getShareBookingLinkRoute } from "../../../../Routes";
import ConfirmedIcon from "../../../../assets/images/calendar/ConfirmedIcon.png";
import PaymentDueIcon from "../../../../assets/images/calendar/PaymentDueIcon.png";
import UnconfirmedIcon from "../../../../assets/images/calendar/UnconfirmedIcon.png";
import AppointmentsIcon from "../../../../assets/images/calendar/appointmentsIcon.png";
import LocationsIcon from "../../../../assets/images/calendar/location-icon.svg";
import CustomRadioButton from "../../../common/CustomRadioButton";
import FilterCanvasSection from "./FilterCanvasSection";
import CustomCheckbox from "../../../custom-components/custom-checkbox/CustomCheckbox";
import { GetClinicLocationsAction } from "../../../redux/actions/main-calendar/MainCalendarAction";
import { CLEAR_CLINIC_LOCATIONS, SAVE_CALENDAR_VIEW_FOR_USER_PROFILE_DATA } from "../../../redux/constants";
import { GetCalendarAppointmentCountAction, GetClinicAvailableDateAction } from "../../../redux/actions/dateTimeslots/DateTimeSlots";
import CustomCalendar from "../../dateTime/steps/CustomCalendar";
import AllLocationsIcon from "../../../../assets/images/calendar/all-location-icon.svg";
import shareIcon from "../../../../assets/images/calendar/clock.svg";
import DateTitleIcon from "../../../assets/images/calendar/dateTitleArrow.svg";
import { CustomCalendarSkeleton } from "../../../clininc-cart-skeleton/calenadar-skeleton/CustomCalendarSkeleton";
import "./filter-canvas.scss";
import { SAVE_CALENDAR_DAILYVIEW_DATA } from "../../../redux/constants/appointment/appointmentConst";
import CustomModal from "../../../modal/CustomModal";
import StepButton from "../../../common/StepButton";
import { frontendURL, webBaseUrl } from "../../../../config";

const FilterCanvas = ({
    setFilterCanvas,
    isOnTimeChange,
    setIsOnTimeChange,
    viewCalendarState,
    setViewCalendarState,
    setIsisShareBookingLinkOpen,
    checkedLocationId,
    setCheckedLocationId,
    checkedBookingId,
    setCheckedBookingId,
    allLocationFlag,
    setAllLocationFlag,
    allBookingFlag,
    setAllBookingFlag,
    checkedLocationData,
    setCheckedLocationData,
    showSelectedDate,
    setShowSelectedDate,
    calendarStartDate,
    setCalendarStartDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedDate,
    setSelectedDate,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    selectedYearMonthlyView,
    setSelectedYearMonthlyView,
    selectedMonthMonthlyView,
    setSelectedMonthMonthlyView,
    dateData,
    setDateData,
    listActive,
    setListActive,
    filterCanvas,
    isThreeDaysView,
    setIsThreeDaysView,
    setIsView
}) => {

    const dispatch = useDispatch();

    const navigate = useNavigate()


    const allLocationArr = {
        id: "Alllocations",
        name: "Alllocations",
        label: "All locations",
        label2: "Address line here",
        img: AllLocationsIcon
    }

    const allBookingArr = {
        id: "Allappointments",
        name: "Allappointments",
        label: "All appointments",
        img: AppointmentsIcon
    }


    const BookingArr = [
        {
            id: 1,
            name: "Confirmed",
            label: "Confirmed",
            img: ConfirmedIcon
        },
        {
            id: 2,
            name: "Paymentdue",
            label: "Payment due",
            img: PaymentDueIcon
        },
        {
            id: 3,
            name: "Unconfirmed",
            label: "Unconfirmed",
            img: UnconfirmedIcon
        },
    ]


    const [checkedLocationIdState, setCheckedLocationIdState] = useState(checkedLocationId);
    const [checkedBookingIdState, setCheckedBookingIdState] = useState(checkedBookingId);
    const [locationTreatementModalState, setLocationTreatmentModalState] = useState(false)


    const { getClinicLocationsQueryData, initGetClinicLocationsData } = GetClinicLocationsAction()
    const { getCalendarAppointmentCountQueryData, initGetCalendarAppointmentCountData } = GetCalendarAppointmentCountAction()
    const { clinicsAvailableDateData, initClinicsAvailableDateResponseFunction } = GetClinicAvailableDateAction();

    //GET LOCATION DATA

    const { clinicLocationList } = useSelector(state => state?.GetClinicLocationReducer)
    const { totalClinicLocationCount } = useSelector(state => state?.GetClinicLocationReducer)
    const { loadedClinicLocationCount } = useSelector(state => state?.GetClinicLocationReducer)
    const { clinicLocationListPage } = useSelector(state => state?.GetClinicLocationReducer)

    const [checkedLocationDataState, setCheckedLocationDataState] = useState(checkedLocationData);
    const { dailyViewData } = useSelector((state) => state.appointmentListReducer);

    console.log("testchecking", checkedLocationId, checkedLocationData, checkedLocationDataState, checkedLocationIdState)
    //API STRUCTURE
    const initiateClinicLocationsCall = (refreshList = false, searchText) => {
        let newPage = clinicLocationListPage + 1;
        if (refreshList) {
            newPage = 1;

        } else if (totalClinicLocationCount <= loadedClinicLocationCount) {
            return;
        }

        initGetClinicLocationsData({
            page: newPage,
            limit: 10,
            search: null
        }
        )
    };


    const handleCheckboxChangeLocation = (id, locationData) => {
        if (dailyViewData != null) {
            dispatch({
                type: SAVE_CALENDAR_DAILYVIEW_DATA,
                payload: null,
            });
        }
        if (clinicLocationList.length === 1) return;

        if (id == allLocationArr.id) {
            if (!allLocationFlag) {
                setAllLocationFlag(!allLocationFlag)
                setCheckedLocationIdState([]);
                setCheckedLocationDataState([])
                setCheckedLocationId([]);
                setCheckedLocationData([])
            }
            return;
        } else {
            setAllLocationFlag(false)
            const existingId = checkedLocationIdState.includes(id);
            if (existingId) {
                setCheckedLocationIdState(checkedLocationIdState.filter((item) => item !== id));
                setCheckedLocationDataState(checkedLocationDataState.filter((item) => item.clinicId !== id));
                if (checkedLocationIdState.length == 1) {
                    setAllLocationFlag(true)
                }
                setCheckedLocationId(checkedLocationIdState.filter((item) => item !== id));
                setCheckedLocationData(checkedLocationDataState.filter((item) => item.clinicId !== id))

            } else {
                setCheckedLocationIdState([...checkedLocationIdState, id]);
                setCheckedLocationDataState([...checkedLocationDataState, locationData]);
                setCheckedLocationId([...checkedLocationIdState, id]);
                setCheckedLocationData([...checkedLocationDataState, locationData])
            }
        }
    }
    console.log("id select", checkedLocationIdState)
    console.log("checkedLocationDataState", checkedLocationDataState, checkedLocationData)

    const handleCheckboxChangeBooking = (id) => {
        if (id == allBookingArr.id) {
            setAllBookingFlag(!allBookingFlag)
            if (!allBookingFlag)
                setCheckedBookingIdState([]);
            return;
        } else {
            setAllBookingFlag(false)
            const existingId = checkedBookingIdState.includes(id);
            if (existingId) {
                setCheckedBookingIdState(checkedBookingIdState.filter((item) => item !== id));
                if (checkedBookingIdState.length == 1) {
                    setAllBookingFlag(true)
                }
            } else {
                setCheckedBookingIdState([...checkedBookingIdState, id]);
            }
        }
    }

    // if there is only one location, check all locations checkbox
    useEffect(() => {
        if (clinicLocationList.length === 1) {
            setCheckedLocationIdState([clinicLocationList[0]?.clinicId]);
            setAllLocationFlag(false);
            setCheckedLocationData([clinicLocationList[0]]);
            setCheckedLocationDataState([clinicLocationList[0]])
            setCheckedLocationId([clinicLocationList[0]?.clinicId])
        }
    }, [clinicLocationList])

    useEffect(() => {
        if (dailyViewData != null) {
            console.log("filter click", dailyViewData?.locationData)
            setCheckedLocationDataState(dailyViewData?.locationData?.clinicId ? [dailyViewData?.locationData] : [])
            setCheckedLocationIdState(dailyViewData?.locationData?.clinicId ? [dailyViewData?.locationData?.clinicId] : [])
        }
    }, [dailyViewData])

    const handleSelectFilterClick = () => {
        // setCheckedLocationId(checkedLocationIdState);
        // setCheckedBookingId(checkedBookingIdState);
        // setViewCalendarState(isOnTimeChangeState);
        // setFilterCanvas(false)
        // setCheckedLocationData(checkedLocationDataState)

        // setShowSelectedDate(showSelectedDateTemp)
        // setCalendarStartDate(calendarStartDateTemp)
        // setStartDate(startDateTemp)
        // setEndDate(setEndDateTemp)
        // setSelectedDate(selectedDateTemp)
        // setSelectedMonth(selectedMonthTemp)
        // setSelectedYear(selectedYearTemp)
        // setSelectedMonthMonthlyView(selectedMonthMonthlyViewTemp);
        // setSelectedYearMonthlyView(selectedYearMonthlyViewTemp);
    }



    useEffect(() => {
        if (filterCanvas) {
            dispatch({ type: CLEAR_CLINIC_LOCATIONS });
            initiateClinicLocationsCall(true);
        }
    }, [filterCanvas])

    useEffect(() => {
        console.log("chekingtest", checkedLocationDataState)
        let a = {
            clinicId: checkedLocationDataState[0]?.clinicId,
            startDate: moment(startDate, "YYYY-MM-DD").clone().startOf('month').format("YYYY-MM-DD"),
            endDate: moment(endDate, "YYYY-MM-DD").clone().endOf('month').format("YYYY-MM-DD"),
        }
        if (checkedLocationDataState?.length == 1 && checkedLocationDataState[0]?.clinicId && endDate) {
            if (!(JSON.stringify(clinicsAvailableDateData?.variables) == JSON.stringify(a)) || !clinicsAvailableDateData.called)
                initClinicsAvailableDateResponseFunction(a);
        }

        // initGetCalendarAppointmentCountData({
        //     startDate: moment(startDate, 'YYYY-MM-DD').clone().subtract(10, 'days').format('YYYY-MM-DD'),
        //     endDate: moment(endDate, 'YYYY-MM-DD').clone().add(10, 'days').format('YYYY-MM-DD'),
        //     locations: checkedLocationIdState?.length == 0 ? [] : checkedLocationIdState
        // })
        let apiPayloadObj = {
            startDate: moment(startDate, 'YYYY-MM-DD').clone().startOf('month').format('YYYY-MM-DD'),
            endDate: moment(endDate, 'YYYY-MM-DD').clone().endOf('month').format('YYYY-MM-DD'),
            locations: checkedLocationIdState?.length == 0 ? [] : checkedLocationIdState
        }

        console.log(getCalendarAppointmentCountQueryData?.variables, "tecstas", JSON.stringify(getCalendarAppointmentCountQueryData?.variables) == JSON.stringify(apiPayloadObj), apiPayloadObj)
        if (startDate && endDate)
            if (!(JSON.stringify(getCalendarAppointmentCountQueryData?.variables) == JSON.stringify(apiPayloadObj)) || !getCalendarAppointmentCountQueryData.called) {
                initGetCalendarAppointmentCountData(apiPayloadObj)
            }
    }, [startDate, endDate, checkedLocationDataState, checkedLocationIdState])

    console.log("show", showSelectedDate, calendarStartDate, startDate, selectedDate)
    const MonthSelection = () => {
        const monthsArray = moment.months();

        return (
            <div className="my-2">
                <div className="filter-year-wrapper">
                    <div className="leftarrowWrapper">
                        <img src={DateTitleIcon} alt="DateTitleIcon" className="leftarrow" onClick={() => {
                            setSelectedYearMonthlyView(parseInt(selectedYearMonthlyView) - 1)
                            setSelectedYear(selectedYear - 1)
                            setShowSelectedDate(showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").subtract(1, "year").format("dddd, Do MMMM YYYY") : moment(startDate, 'YYYY-MM-DD').subtract(1, "year").format("dddd, Do MMMM YYYY"))
                        }} />
                    </div>
                    <div className="date-title">
                        <h6 className="date-title-text m-0" onClick={() => {
                        }}>
                            {selectedYearMonthlyView}
                        </h6>
                    </div>
                    <div className="rightarrowWrapper">
                        <img src={DateTitleIcon} alt="DateTitleIcon" className="rightarrow" onClick={() => {
                            setSelectedYearMonthlyView(parseInt(selectedYearMonthlyView) + 1)
                            setSelectedYear(selectedYear + 1)
                            setShowSelectedDate(showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").add(1, "year").format("dddd, Do MMMM YYYY") : moment(startDate, 'YYYY-MM-DD').add(1, "year").format("dddd, Do MMMM YYYY"))
                        }} />
                    </div>

                </div>
                <div className="filter-months-wrapper">
                    {monthsArray.map((month, index) => {
                        return <>
                            <div key={index} className={selectedMonthMonthlyView == month ? "monthdata-container selected" : "monthdata-container"}
                                onClick={() => {
                                    let selectedDateData = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("DD") : moment(calendarStartDate, 'YYYY-MM-DD').format("DD")
                                    setSelectedMonthMonthlyView(month);
                                    setSelectedMonth(index + 1);
                                    setShowSelectedDate(showSelectedDate ? moment(`${selectedYear} ${month} ${selectedDateData}`, "YYYY MMMM DD").format("dddd, Do MMMM YYYY") : moment(`${selectedYear} ${month} ${selectedDateData}`, 'YYYY MMMM DD').format("dddd, Do MMMM YYYY"))
                                }}>
                                <p>{moment().month(month).format('MMM')}</p>
                            </div>
                        </>
                    })}
                </div>
            </div>
        );
    }
    const generateCustomDates = () => {
        const startDateStr = moment(startDate);

        // Get the first day of the month
        const firstDayOfMonth = startDateStr.clone().startOf('month');

        // Get the last day of the month
        const lastDayOfMonth = startDateStr.clone().endOf('month');

        // Generate an array of dates for the month
        const datesArray = [];
        let currentDate = firstDayOfMonth.clone();

        // initGetCalendarAppointmentCountData({
        //   startDate: firstDayOfMonth.format('YYYY-MM-DD'),
        //   endDate: lastDayOfMonth.format('YYYY-MM-DD'),
        // })

        while (currentDate.isSameOrBefore(lastDayOfMonth)) {
            // Check if the current date is not in the past
            datesArray.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'day');
        }
        return datesArray;
    }
    const resetFilterHandler = () => {
        setAllLocationFlag(true)
        setCheckedLocationData([])
        setCheckedLocationDataState([])
        setCheckedLocationId([])
        setCheckedLocationIdState([])
    }
    const saveCalendarView = (type) => {
        dispatch({
            type: SAVE_CALENDAR_VIEW_FOR_USER_PROFILE_DATA,
            payload: type,
        });
    }
    return (
        <div className="filter-canvas-container" id="scrollableDivLocation">
            {/* TOP SECTION */}
            <div className="top-section">
                <div className="d-flex justify-content-between align-items-center">

                    <div className="left-section">
                        {/* <CloseIcon
                            className="cursor-pointer"
                            width={50}
                            onClick={() => {
                                if (checkedLocationId.length == 0) {
                                    setAllLocationFlag(true)
                                }
                                if (checkedBookingId.length == 0) {
                                    setAllBookingFlag(true)
                                }
                                setFilterCanvas(false)
                            }}
                        /> */}
                    </div>
                    <div className="right-section">
                        {/* <div
                            className="gray-btn"
                            onClick={() => {
                                setIsOnTimeChange(true);
                            }}
                        >
                            <span>Opening times</span>
                        </div> */}
                        <div className="gray-btn" onClick={() => navigate(getShareBookingLinkRoute())}>
                            <span>Share</span>
                        </div>
                    </div>
                </div>

                <div className="opening-times-div" onClick={() => {
                    if (clinicLocationList?.length > 0) {
                        setIsOnTimeChange(true)
                    }
                    else {
                        setLocationTreatmentModalState(true)
                    }

                }}>
                    <p className="mb-0">
                        Adjust your opening hours for a specific day
                    </p>
                    <div className="img-icon ms-3">
                        <img src={shareIcon} alt="share" />
                    </div>
                </div>
            </div>


            {/* MIDDLE SECTION */}
            <div className="middle-section">

                <div className="calender-filter-wrapper">

                    {viewCalendarState == "dayGridMonth" ? <MonthSelection /> :
                        getCalendarAppointmentCountQueryData?.loading || clinicsAvailableDateData?.loading ? <CustomCalendarSkeleton fromFilterCanvas={true} /> : <CustomCalendar
                            setShowSelectedDate={setShowSelectedDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                            setSelectedMonth={setSelectedMonth}
                            setSelectedYear={setSelectedYear}
                            showSelectedDate={showSelectedDate}
                            calendarStartDate={calendarStartDate}
                            setCalendarStartDate={setCalendarStartDate}
                            setViewCalendarState={setViewCalendarState}
                            viewCalendarState={viewCalendarState}
                            dateData={
                                checkedLocationDataState?.length == 1 ? clinicsAvailableDateData?.data?.getClinicAvailableDates?.data?.items
                                    :
                                    generateCustomDates()
                                // dateData
                            }
                            ignoreSelectedDate={true}
                            isPassedDateEnabled={true}
                            appointmentDatesCount={
                                getCalendarAppointmentCountQueryData?.data?.getCalendarAppointmentCount?.data?.appointmentCountData
                            }
                            isMonthlyDailyViewCalender={checkedLocationDataState?.length == 1 ? false : true}
                            // isMonthlyDailyViewCalender={true}
                            isStrikeAllow={true}
                            isCalendarPreviousDateEnable={true}
                            isThreeDaysView={isThreeDaysView}
                        />
                    }
                </div>

                <div className={`calender-view-change-wrapper col-lg-4 offset-lg-4 my-4 w-100 d-flex m-auto`}>
                    <span className={`${(!isThreeDaysView && viewCalendarState === "timeGridDay" ? "active me-2" : "not-active me-2")} col-md-2`} onClick={() => {
                        setViewCalendarState("timeGridDay")
                        setListActive(false);
                        setIsThreeDaysView(false)
                        saveCalendarView("Daily")
                        setIsView(true)

                    }}>Daily </span>
                    <span className={(isThreeDaysView && viewCalendarState === "timeGridWeek" ? "active me-2" : "not-active me-2")} onClick={() => {
                        setViewCalendarState("timeGridWeek")
                        setListActive(false);
                        setIsThreeDaysView(true)
                        saveCalendarView("Threeday")
                        setIsView(true)
                    }}>3 Days</span>
                    <span className={(!isThreeDaysView && viewCalendarState === "timeGridWeek" ? "active me-2" : "not-active me-2")} onClick={() => {
                        setViewCalendarState("timeGridWeek")
                        setListActive(false);
                        setIsThreeDaysView(false)
                        saveCalendarView("Weekly")
                        setIsView(true)
                    }}>Weekly</span>
                    <span className={(!isThreeDaysView && viewCalendarState === "dayGridMonth" ? "active me-2" : "not-active me-2")} onClick={() => {
                        setViewCalendarState("dayGridMonth")
                        setListActive(false);
                        setSelectedYearMonthlyView(showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY") : moment(startDate, 'YYYY-MM-DD').format("YYYY"))
                        setSelectedMonthMonthlyView(showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("MMMM") : moment(startDate, 'YYYY-MM-DD').format("MMMM"))
                        setIsThreeDaysView(false)
                        saveCalendarView("Monthly")
                        setIsView(true)
                    }}>Monthly </span>
                    {/* <span className={(listActive ? "active me-2" : "not-active me-2")} onClick={() => {
                        setListActive(true);
                        setIsView(false)
                        // saveCalendarView("Daily")
                        if (viewCalendarState != "timeGridDay") {
                            let setDefaultDate = moment().format("YYYY-MM-DD")
                            setStartDate(setDefaultDate)
                            setSelectedMonth(moment(setDefaultDate, 'YYYY-MM-DD').format("M"))
                            setSelectedYear(moment(setDefaultDate, 'YYYY-MM-DD').format("YYYY"))
                            setCalendarStartDate(setDefaultDate)
                            setViewCalendarState("timeGridDay")
                            setShowSelectedDate(moment(setDefaultDate, 'YYYY-MM-DD').format("dddd, Do MMMM YYYY"))
                        }

                    }}>List </span> */}
                </div >

                {/* LOCATIONS SECTION */}
                <div className={clinicLocationList.length === 1 ? "view-section-div middle-section-common-div location-fixed-height-div singleLocation" : "view-section-div middle-section-common-div location-fixed-height-div"}>
                    <div className="extra-location-div">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Location</span>
                            {clinicLocationList.length > 1 && <p onClick={resetFilterHandler}>Reset</p>}
                        </div>
                        <hr className="mb-0 mt-1" />
                    </div>
                    {clinicLocationList.length > 1 && <div className="common-div-content" onClick={() => (document.getElementById(allLocationArr.id)).addEventListener(('change'), handleCheckboxChangeLocation(allLocationArr.id))}>
                        <FilterCanvasSection
                            imgIcon={allLocationArr.img}
                            imgLabel={allLocationArr.label}
                        />
                        <div>
                            <CustomCheckbox id={allLocationArr.id} name={allLocationArr.name} checked={allLocationFlag == true} />
                        </div>
                    </div>}
                    {/* <div id="scrollableDiv" style={{ height: "70vh", width: "100%", overflowX: "scroll" }}> */}
                    <InfiniteScroll
                        className=""
                        dataLength={loadedClinicLocationCount}
                        hasMore={totalClinicLocationCount > loadedClinicLocationCount}
                        next={() => {
                            initiateClinicLocationsCall();
                        }}
                        scrollableTarget="scrollableDivLocation"
                    >
                        <>
                            {
                                clinicLocationList?.length > 0 ? (
                                    clinicLocationList.map((item, index) => {
                                        return (
                                            <>
                                                <div className="common-div-content" onClick={() => handleCheckboxChangeLocation(item.clinicId, item)}>
                                                    {/* <div className="common-div-content" onClick={() => (document.getElementById(item.clinicId)).addEventListener(('change'), handleCheckboxChangeLocation(item.clinicId, item))}> */}
                                                    <FilterCanvasSection
                                                        // imgIcon={item.imageUrl}
                                                        imgIcon={LocationsIcon}
                                                        imgLabel={item?.clinicName}
                                                        imgLabel2={item?.completeAddress}
                                                    />
                                                    <div>
                                                        <CustomCheckbox id={item?.clinicId} name={item?.clinicName} checked={checkedLocationIdState.includes(item?.clinicId)} />
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                ) : (
                                    getClinicLocationsQueryData?.loading ? null :
                                        <>
                                            <div className='empty-message mt-5'>No Location Available!</div>
                                        </>
                                )}
                            {
                                getClinicLocationsQueryData?.loading ? (
                                    [...Array(4)].map((item, index) => {
                                        return (
                                            <>
                                                <FilterCanvasSection
                                                    isLoading={true}
                                                />
                                            </>
                                        );
                                    })
                                ) : null
                            }
                        </>
                    </InfiniteScroll>
                    {/* </div> */}
                </div>
            </div>

            {/* BOTTOM SECTION */}
            {/* <div className="bottom-section">
                <StepButton blue={true} label={"Done"} onClick={() => { handleSelectFilterClick() }} />
            </div> */}

            {/* LOCATION TREATMENT ADDITION MODAL */}
            <CustomModal
                modalOpen={locationTreatementModalState}
                setModalOpen={setLocationTreatmentModalState}
                type={"common-width"}
                className={'customeAvailableModel'}
                modalBody={
                    <>
                        {/* <div className="share-booking-link-all-clients-confimration"> */}
                        <div className="share-booking-link-all-clients-confimration">
                            <p>
                                There are currently no location added. Would you like to add one?
                            </p>
                            <div className="btnWrapper">
                                <StepButton label={"Yes"} onClick={() => {
                                    window.location.href = webBaseUrl + `/clinic/create?redirect-url=${frontendURL + CalendarRoutePattern}`
                                    setLocationTreatmentModalState(false)
                                }} blue={true} />
                                <StepButton gray={true} label={"No"} onClick={() => {
                                    setLocationTreatmentModalState(false)
                                }} />
                            </div>
                        </div>

                    </>
                }
            />

        </div >
    );
};

export default FilterCanvas;
