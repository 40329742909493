import React from 'react'
import './warning-modall-content.scss'
import StepButton from '../../../../common/StepButton'

const WarningModalContent = ({ warningMessage, successCallback, cancelCallback }) => {
    return (
        <div className='WarningModalContentMainDiv'>
            <div className="warnIcon">
                <span>
                    !
                </span>
            </div>
            <p>
                {warningMessage}
            </p>
            <div className="button-div row">
                <div className="col-md-6">
                    <StepButton label={"OKAY"} blue={true} onClick={() => successCallback()} />
                </div>
                <div className="col-md-6">
                    <StepButton label={"CANCEL"} gray={true} className={"w-100"} onClick={() => cancelCallback()} />
                </div>
            </div>
        </div>
    )
}

export default WarningModalContent