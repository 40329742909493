import React, { useEffect, useRef, useState } from 'react'
import './facial-mapping-modal-content.scss'
import { ReactComponent as UndoIcon } from "../../../../images/new-appointment/undo.svg";
import PhotoIcon from '../../../../assets/images/facial-mapping/imageIcon.png'
import EditNoteIcon from '../../../../assets/images/facial-mapping/noteIcon.png'
import MarkerIcon from '../../../../assets/images/facial-mapping/markerIcon.png'
import StepButton from '../../../common/StepButton';
import PhotoStep from './steps/photo-step/PhotoStep';
import NoteStep from './steps/note-step/NoteStep';
import { ChromePicker } from 'react-color'
import CustomModal from '../../../modal/CustomModal';
import WarningModalContent from './warninng-modal-content/WarningModalContent';
import { GetFacialMappingDataAction, SaveFacialMappingDataAction } from '../../../redux/actions/facial-mapping/FacialMappingAction';
import showToast from '../../../common/CustomToast/CustomToast';
import FacialMappingMulticolor from '../../../../assets/images/facial-mapping/Facial_Mapping_multicolor.png'
import Skeleton from 'react-loading-skeleton';
import ImageDrawingCanvas from './image-drawing-canvas/ImageDrawingCanvas';
import { useSelector } from 'react-redux';




const FacialMappingModalContent = ({ appointmentId, setFacialMappingModal, setFacialMappingStatus, step, setStep }) => {
    const COLORPICKER = ["#000", "#A7DCA2", "#A2CEDC", "#AAA2DC", "#DCA2A5", "#CEE295"]
    const sigPadRef = useRef();
    const fabricRef = React.useRef(null);

    const colorPickerref = useRef();


    const [uploadedImage, setUploadedImage] = useState("")
    const [color, setColor] = useState("#000")
    const [tempColor, setTempColor] = useState("")
    const [facialMappingNote, setFacialMappingNote] = useState("")
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)

    const [facialMappingUploadedImage, setFacialMappingUploadedImage] = useState("")

    const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)
    const appointmentDetails = useSelector((state) => state.appointmentReducer.appointmentDetails);

    console.log(appointmentDetails, "appointmentDetails akshay")


    const [canvasImage, setCanvasImge] = useState("")


    //UNDO MODAL
    const [undoModal, setUndoModal] = useState(false)

    //======================ACTIONS======================
    const { saveFacialMappingDataQueryData, initSaveFacialMappingDataData } = SaveFacialMappingDataAction()
    const { getFacialMappingDataQueryData, initGetFacialMappingDataData } = GetFacialMappingDataAction()

    const handleHueColorChange = (newColor) => {
        setTempColor(newColor.hex);
    };

    const fetchOnlyBase64String = (string) => {
        return string.split("base64,")[1]
    }

    const getBase64Image = async (img) => {
        const base64 = await fetch(img)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                console.log("TEST HERE");
                return new Promise((res) => {
                    reader.onloadend = () => {
                        res(reader.result);
                    }
                })
            })
        console.log("Base 64 object", base64)
        return base64
    }

    const fetchImage = async (imageUrl, forReset = false) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            console.log("base6 4 need", blobUrl)
            setCanvasImge(blobUrl)
            if (forReset)
                setFacialMappingUploadedImage(blobUrl)
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };


    const getFacialMappingData = async () => {
        initGetFacialMappingDataData({
            appointmentId: appointmentId
        },
            (responseData) => {
                getBase64Image(responseData.image).then(res => setUploadedImage(res))
                setFacialMappingNote(responseData.canvasNote)

                if (responseData.canvasImage) {

                    getBase64Image(responseData.canvasImage).then(res => {
                        setFacialMappingUploadedImage(res)

                    });
                    (fetchImage(responseData.canvasImage))
                }
                else {

                    getBase64Image(responseData.image).then(res => {
                        setFacialMappingUploadedImage(res)
                    });
                    (fetchImage(responseData.image))
                }
            },
            (error) => {
                showToast("error", error.message, "", false)
            })
    }

    const handleSubmitData = () => {
        let objToBePassed = {
            appointmentId: appointmentId,
            image: fetchOnlyBase64String(uploadedImage),
            canvasImage: fetchOnlyBase64String(fabricRef.current.toDataURL()),
            canvasNote: facialMappingNote
        }

        initSaveFacialMappingDataData(
            objToBePassed,
            (res) => {
                showToast("", res.message, "", false, () => { }, { clientProfileImage: appointmentDetails.client_image == "not present" ? "" : appointmentDetails.client_image, clientName: appointmentDetails.client_fname + ' ' + appointmentDetails.client_lname })
                setFacialMappingStatus(true)
                setFacialMappingModal(false)
            },
            ((error) => {
                showToast("error", error.message, "", false)
            })
        )
    }

    const onGoBack = () => {
        setStep(0)
    }
    useEffect(() => {
        getFacialMappingData()
    }, [])

    // TEMP
    useEffect(() => {
        function checkIfClickedOutside(event) {
            if (colorPickerref?.current && !colorPickerref.current.contains(event.target)) {
                setColor(tempColor)
                setIsColorPickerOpen(false)
            }
        }
        document.addEventListener("mouseup", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mouseup", checkIfClickedOutside);

        };
    }, [tempColor]);

    useEffect(() => {
        if (canvasImage == "" && facialMappingUploadedImage !== "")
            setCanvasImge(facialMappingUploadedImage)
    }, [canvasImage]);


    return (
        <>
            <div className="container">
                {

                    <div className='facialMappingModalContentModalMainDiv' style={{
                        display: !(step == 0 || step == 3) ? 'none' : "block"
                    }}>
                        <p>
                            Pinpoint the areas on the face where you'll be performing the treatments.
                        </p>
                        <div className="imageCanvasPreviewDiv">
                            {!getFacialMappingDataQueryData?.loading && <div className='undoIcon'>
                                <UndoIcon onClick={() => setUndoModal(true)} />
                            </div>
                            }
                            <div className="drawCanvasPreview">
                                {
                                    getFacialMappingDataQueryData?.loading ?
                                        <Skeleton height={250} width={350} /> :
                                        <div>
                                            <ImageDrawingCanvas
                                                canvasRef={sigPadRef}
                                                imageUrl={canvasImage}
                                                penColor={color}
                                                fabricRef={fabricRef}
                                            />


                                        </div>
                                }

                            </div>
                        </div>
                        {
                            step == 3 &&
                            <div>
                                <div className='color-picker-container'>
                                    {
                                        COLORPICKER.map((item, index) => {
                                            return (
                                                <>
                                                    <div style={{ background: item }} className={`${item == color ? "color-data active" : "color-data"}`} onClick={() => setColor(item)}>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }

                                    {
                                        COLORPICKER.includes(color) ?
                                            <img className='cursor-pointer' src={FacialMappingMulticolor} alt=""
                                                onClick={() => {
                                                    setTempColor(color)
                                                    setIsColorPickerOpen(true)
                                                }
                                                }
                                            />
                                            :
                                            <>
                                                <div style={{ background: color }} className={`color-data active`}

                                                    onClick={() => {
                                                        setTempColor(color)
                                                        setIsColorPickerOpen(true)
                                                    }} />

                                            </>
                                    }
                                    {
                                        isColorPickerOpen &&
                                        <div ref={colorPickerref} className='chromeColorPicker'>
                                            <ChromePicker
                                                color={tempColor}
                                                className='sketch-picker'
                                                onChangeComplete={handleHueColorChange}
                                            />
                                        </div>
                                    }
                                </div>

                            </div>
                        }


                        <div className="stepsOptionDiv">
                            <div className="stepsDiv" onClick={() => setStep(1)}>
                                <img src={PhotoIcon} alt="PhotoIcon" />
                                <span>Photo</span>
                            </div>
                            <div className="stepsDiv" onClick={() => setStep(2)}>
                                <img src={EditNoteIcon} alt="EditNoteIcon" />
                                <span>{facialMappingNote?.trim() == "" || facialMappingNote == null ? "Add" : "Edit"} Note</span>
                            </div>
                            <div className="stepsDiv" onClick={() => {
                                if (step == 3)
                                    setStep(0)
                                else
                                    setStep(3)
                            }}>
                                <img src={MarkerIcon} alt="MarkerIcon" />
                                <span>Marker</span>
                            </div>

                        </div>

                        <StepButton label={"Save"} blue={true} className={"w-25"}
                            isLoading={saveFacialMappingDataQueryData.loading}
                            onClick={() => handleSubmitData()}
                        // onClick={() => {
                        //     console.log("canvasRef", sigPadRef.current.toDataURL())
                        // }}
                        />
                    </div>
                }


            </div >
            {
                step == 1 &&
                <PhotoStep
                    onFileSelect={(imgUrl) => {
                        if (imgUrl) {
                            //set the uploaded image into canvas
                            setCanvasImge(imgUrl)
                            setFacialMappingUploadedImage(imgUrl)
                        } else {
                            //reset the image
                            fetchImage(uploadedImage, true)
                            // setCanvasImge(uploadedImage)
                        }
                        onGoBack()
                    }}
                    onGoBack={onGoBack}
                />
            }
            {
                step == 2 &&
                <NoteStep
                    setStep={setStep}
                    facialMappingNote={facialMappingNote}
                    setFacialMappingNote={setFacialMappingNote}
                />
            }

            {/* UNDO MODAL */}
            <CustomModal
                modalOpen={undoModal}
                setModalOpen={setUndoModal}
                className={'CustomeAddModel'}
                modaltitle={"Facial Mapping"}
                modaltitleCSS={"discount-title"}
                type={"common-width"}
                modalBody={< WarningModalContent
                    warningMessage={"Are you sure you want to reset all your drawing?"}
                    successCallback={() => {
                        setCanvasImge("")
                        setUndoModal(false)
                    }}
                    cancelCallback={() => setUndoModal(false)}
                />}
            />
        </>
    )
}

export default FacialMappingModalContent