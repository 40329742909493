import { domainName } from "./styles";

export const apiConfigUrl = process.env.REACT_APP_GRAPHQL_URL;
export const restApiConfigUrl = process.env.REACT_APP_REST_API_URL;


export const appBaseUrl = domainName == "faces" ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL_INSYNC;
export const webBaseUrl = domainName == "faces" ? process.env.REACT_APP_WEB_BASE_URL : process.env.REACT_APP_WEB_BASE_URL_INSYNC;

export const loginWebBaseUrl = domainName == "faces" ? process.env.REACT_APP_WEB_BASE_LOGIN_URL : process.env.REACT_APP_WEB_BASE_LOGIN_URL_INSYNC;


export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const notificationMode = process.env.REACT_APP_SOCKET_NOTIFICATION_MODE;

// export const STRIPE_PUBLIC_KEY = 'pk_test_YPiSiNJUExw1riBRNvvW51ve00ZHDtnRIQ';
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const frontendURL = domainName == "faces" ? process.env.REACT_APP_FRONTEND_URL : process.env.REACT_APP_FRONTEND_URL_INSYNC;

export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

export const config = {
    API_URL: apiConfigUrl,
    RYFT_PUBLIC_KEY: process.env.REACT_APP_RYFT_PUBLIC_KEY,
    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    STRIPE_SECRET_KEY: process.env.REACT_APP_STRIPE_SECRET_KEY,

    APP_STORE_LINK: process.env.REACT_APP_APP_STORE_LINK,
    PLAY_STORE_LINK: process.env.REACT_APP_PLAY_STORE_LINK,
    WHATSAPP_LINK: process.env.REACT_APP_WHATSAPP_LINK,

    TRANNING_WHATSAPP_LINK: process.env.REACT_APP_TRANNING_WHATSAPP_LINK,
    PHARMACY_WHATSAPP_LINK: process.env.REACT_APP_PHARMACY_WHATSAPP_LINK,

    FINANCE_WHATSAPP_LINK: process.env.REACT_APP_FINANCE_WHATSAPP_LINK,

    HAIR_PRESCRIPTION_WHATSAPP_LINK: process.env.REACT_APP_HAIR_PRESCRIPTION_WHATSAPP_LINK,
}

export const facesWebBaseUrl = domainName == "faces" ? process.env.REACT_APP_FACES_WEB_BASE_URL : process.env.REACT_APP_FACES_WEB_BASE_URL_INSYNC;


export const isLiveSystem = typeof window != 'undefined' && window.location.host == 'facesconsent.com' ? true : false