import { gql, useMutation } from "@apollo/client";

const SAVE_FINANCE_BUSINESS_DETAILS=gql`
mutation SaveFinanceBusinessDetailsOfUser($businessOwnerName: String, $companyHouseRegNo: String, $businessTradingName: String, $businessStatus: String, $occupation: [Int], $healthcarePin: String, $houseNo: String, $streetName: String, $city: String, $zipcode: String) {
    saveFinanceBusinessDetailsOfUser(business_owner_name: $businessOwnerName, company_house_reg_no: $companyHouseRegNo, business_trading_name: $businessTradingName, business_status: $businessStatus, occupation: $occupation, healthcare_pin: $healthcarePin, house_no: $houseNo, street_name: $streetName, city: $city, zipcode: $zipcode) {
      success
      message
    }
  }
`;

export const SaveFinanceBusinessDetailAction=()=>{
    const [saveFinanceBusinessDetailResponseFunction,
    saveFinanceBusinessDetailResponseData]=useMutation(SAVE_FINANCE_BUSINESS_DETAILS)

    const saveFinanceBusinessQueryData=saveFinanceBusinessDetailResponseData;

    const initSaveFinanceBusinessDetailData=(data, successCallback, errorCallback)=>{
            saveFinanceBusinessDetailResponseFunction({
                variables:data
            }).then((responseData)=>{
                console.log(responseData,"respn")
                if(responseData.data.saveFinanceBusinessDetailsOfUser.success){
                    successCallback(responseData)
                }else{
                    errorCallback(responseData)
                }
            }).catch((error)=>{
                errorCallback(error)
            })
    }

    return {saveFinanceBusinessQueryData,initSaveFinanceBusinessDetailData}
}