import React from 'react'
import Skeleton from 'react-loading-skeleton';
import { Rating } from 'react-simple-star-rating';

const ReviewModalRightSideSkeleton = () => {
    return (
        <div className='review-right-side-skeleton-main-div'>
            <div className='review-card my-2'>
                <div className='card-content-one d-flex justify-content-between'>
                    <div className='d-flex gap-2 align-items-center'>
                        <Skeleton circle height={45} width={45} />
                        <div className='d-flex flex-column justify-content-center'>
                            <Skeleton height={12} width={120} />
                            <Skeleton height={12} width={"80%"} />
                            <Skeleton height={12} width={"70%"} />
                        </div>
                    </div>
                    <div>
                        <Skeleton height={13} width={80} />
                    </div>
                </div>
                <div className='mb-2'>
                    <Rating initialValue={0} fillColor="#175ffa" readonly size={16} />
                </div>

                <div className='review-content-container'>
                    <Skeleton height={60} width={"100%"} />
                </div>
                <Skeleton height={12} width={70} />

            </div>
        </div>
    )
}

export default ReviewModalRightSideSkeleton