import React, { useRef, useState } from 'react'
import './forward-cosent-form-main.scss'
import closeIcon from '../../images/close_icon.svg'
import SignatureCanvas from "react-signature-canvas";
import CustomRadioButton from '../common/CustomRadioButton';
import { ReactComponent as UndoIcon } from "../../images/signature pop-icon/undo.svg";
import { ReactComponent as EnableCheck } from "../../images/signature pop-icon/enable_check.svg";
import { ReactComponent as EmptySignIcon } from "../../images/signature pop-icon/empty_sign.svg";
import { ReactComponent as UndoEnableIcon } from "../../images/signature pop-icon/undo_enable.svg";
import { ReactComponent as DisableCheck } from "../../images/signature pop-icon/disable_check.svg";
import { ReactComponent as EnableSignIcon } from "../../images/signature pop-icon/active_sign.svg";
import CustomModal from '../modal/CustomModal';
import StepButton from '../common/StepButton';
import { useNavigate } from 'react-router';

const ClientForwConsentForm = () => {
    const navigate = useNavigate();
    const [isSigned, setIsSigned] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const [isSignatureOpen, setIsSignatureOpen] = useState(false);

    const sigPadRef = useRef();
    const clear = () => {
        sigPadRef.current.clear();
        setIsSigned(false);
    };

    const handleHide = () => {
        //setIsOpen(false);
        setIsSignatureOpen(true);
    };

    const handleSignatureModalClose = () => {
        setIsSignatureOpen(false); // Close the SignatureModal
        // setIsOpen(true); // Close the NewAppointment
    };

    const handleSignature = () => {
        setTrimmedDataURL(
            sigPadRef.current?.getSignaturePad()?.toDataURL("image/png")
        );
    };

    const handleCompletePage = () => {
        navigate('/client-forward-consent-form/preview')
    }

    const options = [
        { text2: "Yes", checked: true },
        { text2: "No", checked: false },
        { text2: "Other", checked: false },
    ];

    const handleRadioButtonChange = (event) => {
        // Handle the change event for the radio button here
        console.log("Radio button changed:", event.target.value);
        // You can perform other actions based on the radio button change event
    };
    return (
        <div>
            <div className="container">
                <div className='mt-5'>
                    <img src={closeIcon} alt="backArrow" className="back-arrow" />
                </div>
                <div className="forward-consent-form-content">
                    <div className="row forward-consent-form-title">
                        <p>Consent form name</p>
                    </div>

                    <div className="forward-consent-form-edit-options">
                        <div className="row one">
                            <p>This is a question that requires a yes or no answer?</p>
                            {options.map((option, index) => (
                                <div className="time-slot-content" key={index}>
                                    <CustomRadioButton
                                        isblacked={true}
                                        text2={option.text2}
                                        name="timeSlotGroupOne"
                                        checked={option.checked}
                                        onChange={handleRadioButtonChange}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="row one mt-5">
                            <p>This is a question that requires a yes or no answer?</p>
                            {options.map((option, index) => (
                                <div className="time-slot-content" key={index}>
                                    <CustomRadioButton
                                        isblacked={true}
                                        text2={option.text2}
                                        name="timeSlotGroupTwo"
                                        checked={option.checked}
                                        onChange={handleRadioButtonChange}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="row one  mt-5">
                            <p>This is a question that requires a yes or no answer?</p>
                            {options.map((option, index) => (
                                <div className="time-slot-content" key={index}>
                                    <CustomRadioButton
                                        isblacked={true}
                                        text2={option.text2}
                                        name="timeSlotGroupThree"
                                        checked={option.checked}
                                        onChange={handleRadioButtonChange}
                                    />
                                </div>
                            ))}
                        </div>
                        {isSigned && (
                            <div className='add-note'>
                                <textarea className='form-control' placeholder='Add a note'></textarea>
                            </div>
                        )}

                        <div className="right-section my-5">
                            <div className="card-section">
                                <div className="d-flex flex-column align-items-center gap-2 sign-section" onClick={handleHide}>
                                    {trimmedDataURL ? <EnableSignIcon /> : <EmptySignIcon />}
                                    <h1>{isSigned ? "Added" : "Your signature"}</h1>
                                </div>
                            </div>
                        </div>

                        <div className="row one mt-5">
                            {isSigned ?
                                <StepButton label={"Save"} blue={true} onClick={handleCompletePage} />
                                : <StepButton label={"Save"} gray={true} />
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* signature modal */}
            {
                isSignatureOpen && (
                    <CustomModal
                        className={"CustomeSignModel"}
                        modalOpen={isSignatureOpen}
                        setIsOpenMobileCalendar={handleSignatureModalClose}
                        setModalOpen={setIsSignatureOpen}
                        type="common-width"
                        modalBody={
                            <div className="d-flex flex-column wrapper-canvas">
                                <SignatureCanvas
                                    ref={sigPadRef}
                                    canvasProps={{
                                        width: 500,
                                        height: 200,
                                        className: "signCanvas",
                                    }}
                                    throttle={null}
                                    onEnd={() => {
                                        setIsSigned(true);
                                    }}
                                />
                                <div className="justify-content-between d-flex w-100">
                                    {isSigned ? (
                                        <UndoEnableIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={clear}
                                        />
                                    ) : (
                                        <UndoIcon style={{ cursor: "not-allowed" }} />
                                    )}
                                    {isSigned ? (
                                        <EnableCheck
                                            onClick={() => {
                                                handleSignatureModalClose();
                                                setIsSignatureOpen(false);
                                                handleSignature();
                                            }}
                                            style={{ cursor: "pointer" }}
                                        />
                                    ) : (
                                        <DisableCheck style={{ cursor: "not-allowed" }} />
                                    )}
                                </div>
                            </div>
                        }
                    />
                )
            }
        </div>
    )
}

export default ClientForwConsentForm
