import React from 'react';
import { toast as reactToast } from 'react-toastify';
import AppointmentToast from '../../new-appointment/AppointmentToast';

const showToast = (type, message, description, isClicked, onCallBack, initialData, options = {}) => {
  const toastComponent = (
    <AppointmentToast type={type} toastMsg={message} toastDesc={description} isClickable={isClicked} initialData={initialData} onCallBack={onCallBack} />
  );

  reactToast(toastComponent, {
    position: "top-right", 
    autoClose: 3000,
    className: "new-appointment-toast",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
    ...options, // Allow overriding default options
  });
};

export default showToast;

