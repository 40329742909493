import React, { useState } from 'react';

import './text-input.scss';

const TextInput = React.forwardRef(
  (
    {
      className,
      label,
      error,
      placeholder,
      rounded,
      multiline,
      labelColor,
      icon,
      endIcon,
      endIcon1,
      endBadge,
      endIconClick,
      setEyeOpen,
      eyeOpen,
      rows,
      options,
      place,
      maxLength,
      value,
      defaultValue,
      name,
      autoFocus,
      onFocus,
      pattern,
      onKeyDown,
      handleKeyPress,
      onChange,
      isToggled,
      endBadgeClick,
      disabled,
      onBlur,
      ...props
    },
    ref
  ) => {
    const [type, setType] = useState(props.type);

    // const customHandleKeyPress = (event) => {
    //   const keyCode = event.keyCode || event.which;
    //   const keyValue = String.fromCharCode(keyCode);

    //   if (!/^[0-9]*$/.test(keyValue)) {
    //     event.preventDefault();
    //   }
    // };

    return (
      <div className={'inputContainer'}>
        {label ? (
          <label style={{ color: `${labelColor}` }} className="form-label">
            {label}
          </label>
        ) : null}

        <div
          className={
            rounded
              ? 'rounded'
              : icon
              ? 'iconed'
              : endIcon
              ? 'iconedEnd'
              : endBadge
              ? 'badgeEnd'
              : null
          }
          style={endIcon ? { paddingLeft: '0', paddingRight: '20px' } : null}
        >
          {icon && icon}

          {multiline ? (
            <>
              <textarea
                value={value}
                defaultValue={defaultValue}
                className={
                  'form-control form-control-textarea' + '' + className
                }
                maxLength={maxLength}
                placeholder={placeholder ? placeholder : ''}
                {...props}
                ref={ref}
                rows={rows}
                name={name}
                autoFocus={autoFocus}
                onChange={onChange}
                disabled={disabled}
                onBlur={onBlur}
                style={disabled ? { cursor: 'not-allowed' } : null}
              ></textarea>
            </>
          ) : (
            <input
              className={'form-control' + ' ' + className}
              placeholder={placeholder ? placeholder : ''}
              {...props}
              defaultValue={defaultValue}
              maxLength={maxLength}
              type={type}
              ref={ref}
              value={value}
              name={name}
              onBlur={onBlur}
              pattern={pattern}
              onKeyDown={onKeyDown}
              onKeyPress={handleKeyPress ? handleKeyPress : null}
              autoFocus={autoFocus}
              onFocus={onFocus}
              onChange={onChange}
              disabled={disabled}
              style={disabled ? { cursor: 'not-allowed' } : null}
            />
          )}

          {options && (
            <select className="form-control-select" {...props} ref={ref}>
              <option value="">{place}</option>
              {options.map((val, index) => {
                return (
                  <option key={val.value} value={val.value}>
                    {val.title}
                  </option>
                );
              })}
            </select>
          )}
          {endIcon && (
            <small
              className={'show-password'}
              onClick={() => {
                console.log('text input eyeOpen', eyeOpen);
                setEyeOpen(!eyeOpen);
                type === 'text' ? setType('password') : setType('text');
                if (endIconClick) {
                  endIconClick();
                }
              }}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: '5%',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              {endIcon}
            </small>
          )}
          {endBadge && (
            <small
              className={'show-password'}
              onClick={(e) => {
                if (endBadgeClick) {
                  endBadgeClick(endBadge);
                }
              }}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: '5%',
                bottom: '18px',
                background: '#EEF0F1',
                borderRadius: '100px',
                padding: '5px',
                fontSize: '10px',
                width: '60px',
                textAlign: 'center',
              }}
            >
              {endBadge}
            </small>
          )}
        </div>
        {error && <div className={'error'}>{error}</div>}
      </div>
    );
  }
);

export default TextInput;
