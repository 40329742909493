import React from 'react'
import cn from "classnames";
// import "../../common/SwitchButton.scss";
import "../switchButton/switchButton.scss"

const SwitchButton = ({ onChange, value, isChecked, isBlue = false }) => {
    return (
        <div >
            <label className={"switch-common"}>
                <input value={value} type="checkbox" className={"default"} onChange={onChange} checked={isChecked ? true : false} />
                <span className={isBlue ? "slider-blue" : "slider-black"}></span>
            </label>
        </div>
    )
}

export default SwitchButton