import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_BOOKING_LINK_DATA } from "../../constants/bookingLink/BookingLinkConst";
import { GET_CLINIC_LISTING_CONST } from "../../constants";

const GET_BUSINESS_BOOKING_LINK_QUERY = gql`
query GetBusinessBookingLink {
    getBusinessBookingLink {
      success
      message
      data {
        bookingLink
        baseLink
      }
    }
  }
`;

var page;
export const GetBusinessBookingLink = () => {
  const dispatch = useDispatch();
  const [getBusinessBookingLinkData, businessBookingLinkData] = useLazyQuery(
    GET_BUSINESS_BOOKING_LINK_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getBusinessBookingLink: responseData }) => {
        dispatch({
          type: GET_BOOKING_LINK_DATA,
          payload: responseData.data,
        });
        if (responseData.success) {
        } else {
        }
      },
    }
  );
  const businessBookingLinkQueryData = businessBookingLinkData;
  const initGetBusinessBookingLink = (data, successCallback) => {
    getBusinessBookingLinkData({
      variables: data,
    }).then(({ data: { getBusinessBookingLink: responseData } }) => {
      if (responseData.success) {
        if (successCallback)
          successCallback(responseData?.data);
      }
    });
  };
  return { initGetBusinessBookingLink, businessBookingLinkQueryData };
};

// ========================GET CLIENT LIST_V3========================
const GET_CLIENT_LIST_V3_QUERY = gql`
query GetClientList_v3($page: Int, $limit: Int, $search: String, $deletedClients: Boolean, $requestFrom: String) {
  getClientList_v3(page: $page, limit: $limit, search: $search, deletedClients: $deletedClients, requestFrom: $requestFrom) {
    success
    message
    data {
      clients {
        clentId
        clientName
        clientEmail
        readableContact
        clientProfileImage
      }
      total
    }
  }
}
`;
var type = ""

export const GetClientList_v3Action = () => {
  const dispatch = useDispatch();
  const [
    GetClientList_v3ResponseFunction,
    getClientList_v3ResponseData,
  ] = useLazyQuery(GET_CLIENT_LIST_V3_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getClientList_v3: responseData }) => {

      console.log("responseData", responseData)
      if (responseData.success) {
        dispatch({
          type: GET_CLINIC_LISTING_CONST,
          payload: responseData,
          listingType: type
        })
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });

  //this will be called from components
  const getClientList_v3QueryData = getClientList_v3ResponseData; //variable


  const initGetClientList_v3Data = (data) => {
    console.log(data, "action.listing data")
    type = data.requestFrom
    GetClientList_v3ResponseFunction({
      variables: data,
    })
  };

  return { getClientList_v3QueryData, initGetClientList_v3Data };
};

// ========================SEND BOOKING LINK THROUGH EMAIL========================

const SEND_BOOKING_LINK_THROUGH_EMAIL_MUTATION = gql`
mutation SendBookingLinkThroughEmail($emails: [String], $sendToAllClients: Boolean) {
  sendBookingLinkThroughEmail(emails: $emails, sendToAllClients: $sendToAllClients) {
    success
    message
  }
}
`;


export const SendBookingLinkThroughEmailAction = () => {
  const dispatch = useDispatch();
  const [
    SendBookingLinkThroughEmailResponseFunction,
    sendBookingLinkThroughEmailResponseData,
  ] = useMutation(SEND_BOOKING_LINK_THROUGH_EMAIL_MUTATION)

  //this will be called from components
  const sendBookingLinkThroughEmailQueryData = sendBookingLinkThroughEmailResponseData; //variable

  const initSendBookingLinkThroughEmailData = (data, successCallback, errorCallback) => {
    SendBookingLinkThroughEmailResponseFunction({
      variables: data,
    }).then(({ data: { sendBookingLinkThroughEmail: responseData } }) => {
      if (responseData.success) {
        if (successCallback)
          successCallback(responseData)
        //  toast.success(responseData.message)
      }
      else {
        if (errorCallback)
          errorCallback()
        // toast.error(responseData.message)
      }
    }).catch(() => {
      if (errorCallback)
        errorCallback()
      // toast.error("something went wrong!!!")
    })
  }

  return { sendBookingLinkThroughEmailQueryData, initSendBookingLinkThroughEmailData };
};

// ========================SEND BOOKING LINK THROUGH SMS========================

const SEND_BOOKING_LINK_THROUGH_SMS_MUTATION = gql`
mutation SendBookingLinkThroughSMS($contacts: [String], $sendToAllClients: Boolean) {
  sendBookingLinkThroughSMS(contacts: $contacts, sendToAllClients: $sendToAllClients) {
    success
    message
  }
}
`;


export const SendBookingLinkThroughSMSAction = () => {
  const dispatch = useDispatch();
  const [
    SendBookingLinkThroughSMSResponseFunction,
    sendBookingLinkThroughSMSResponseData,
  ] = useMutation(SEND_BOOKING_LINK_THROUGH_SMS_MUTATION)

  //this will be called from components
  const sendBookingLinkThroughSMSQueryData = sendBookingLinkThroughSMSResponseData; //variable

  const initSendBookingLinkThroughSMSData = (data, successCallback, errorCallback) => {
    SendBookingLinkThroughSMSResponseFunction({
      variables: data,
    }).then(({ data: { sendBookingLinkThroughSMS: responseData } }) => {
      if (responseData.success) {
        if (successCallback)
          successCallback(responseData)
        //  toast.success(responseData.message)
      }
      else {
        if (errorCallback)
          errorCallback()
        // toast.error(responseData.message)
      }
    }).catch(() => {
      if (errorCallback)
        errorCallback()
      // toast.error("something went wrong!!!")
    })
  }

  return { sendBookingLinkThroughSMSQueryData, initSendBookingLinkThroughSMSData };
};






