import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const DELETE_IDENTITY_FORWARD_CONSENT_FORM_MUTATION = gql`
mutation DeleteClientId($cfrId: Int, $token: String) {
    deleteClientId(cfrId: $cfrId, token: $token) {
      success
      message
    }
  }
`;

export const DeleteIdentityForwardConsentFormMutation = () => {
    const [deleteIdentityForwardConsentFormRequest, deleteIdentityForwardConsentFormResponseData] =
        useMutation(DELETE_IDENTITY_FORWARD_CONSENT_FORM_MUTATION);
    const dispatch = useDispatch();
    const deleteIdentityForwardConsentFormData = deleteIdentityForwardConsentFormResponseData;

    const initiateDeleteIdentityForwardConsentForm = (
        data,
        successCallback,
        errorCallback
    ) => {
        deleteIdentityForwardConsentFormRequest({
            variables: data,
        })
            .then(({ data: { deleteClientId: responseData } }) => {
                if (responseData) {
                    successCallback(responseData);
                } else {
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                errorCallback(error);
            });
    };
    return { initiateDeleteIdentityForwardConsentForm, deleteIdentityForwardConsentFormData };
};
