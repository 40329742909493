import React, { useState } from 'react';
import './create-after-care-form.scss';
import NavHeader from '../../common/NavHeader/NavHeader';
import StepButton from '../../common/StepButton';
import { useNavigate, useParams } from 'react-router';
import { CreateAndEditAfterCareForm } from '../../redux/actions/walk-in-form/walkInAddFormsList';

const CreateAfterCareForm = () => {
    const { formName } = useParams();
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);

    const { createOrUpdateAfterCareFormQueryData, initCreateOrUpdateAfterCareFormData } = CreateAndEditAfterCareForm();

    const handleSubmitAfterCareForm = () => {
        if (selectedFile) {
            initCreateOrUpdateAfterCareFormData({
                aftercareFormName: formName,
                file: selectedFile,
            });
            navigate('/manage-forms');
        }

    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
    };

    const isImage = (file) => {
        return file.type.startsWith('image/');
    };

    const isPDF = (file) => {
        return file.type === 'application/pdf';
    };

    return (
        <>
            <div className='create-new-form-container' onSubmit={handleSubmitAfterCareForm}>
                {/* <NavHeader type="submit" btnLabel={"Save"}  onSubmit={handleSubmitAfterCareForm} applyPadding={false} isBackWithButton={true} /> */}
                <div className="form-section">
                    <div className="form-section-title ">
                        <h3>{formName}</h3>
                    </div>

                    <div className='notify-container'>
                        <p><b>Quick Tip:</b> Keep your questions clear and concise, ideally requiring simple 'Yes' or 'No' responses.</p>
                    </div>

                    <div className="form-file-upload-section">
                        {selectedFile && (isImage(selectedFile) || isPDF(selectedFile)) ? (
                            <div className='file-preview'>
                                {isImage(selectedFile) && <img src={URL.createObjectURL(selectedFile)} width={500} alt="File Preview" />}
                                {isPDF(selectedFile) && <p>PDF File: {selectedFile.name}</p>}
                                <button onClick={handleRemoveFile}>Remove</button>
                            </div>
                        ) : (
                            <div className='after_care_file_upload'>
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <i className="fa fa-cloud-upload" style={{ fontSize: '40px' }}></i>
                                    <p>Upload File</p>
                                </label>
                                <input id="file-upload" type="file" accept=".pdf, image/*" onChange={handleFileChange} />
                            </div>
                        )}
                    </div>
                    <StepButton blue={true} type="submit" onClick={handleSubmitAfterCareForm} label={"Save"} />
                </div>
            </div>
        </>
    );
}

export default CreateAfterCareForm;
