import React, { useEffect, useRef, useState } from 'react'
import './consentmodal.scss'
import Modal from "react-modal";
import modalClose from "../../../images/icons/Back.svg"


import moment from 'moment';
import { click } from '@testing-library/user-event/dist/click';
import gsap from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { clearRenewInsuranceStatus } from '../../redux/actions';
const ConsentFormCustomModal = ({ modalOpen, setOpenRenewPopup, setModalOpen, setShowResendAlert, modaltitle, modalBody, removePadding, addButton, type, clickDate, setIsOpenMobileCalendar, hideCloseButton, className }) => {
    const imgRef = useRef();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const closeModal = () => {
        setModalOpen(false);
        setOpen(false);
        if (setIsOpenMobileCalendar)
            setIsOpenMobileCalendar(false)
    };

    const animateCloseIcon = (reverse = false) => {
        if (reverse) {
            gsap.to(imgRef.current, {
                rotation: "-=90"
            });
        } else
            gsap.to(imgRef.current, {
                rotation: "+=90"
            });
    }

    useEffect(() => {
        setOpen(modalOpen)
    }, [modalOpen])


    return (
        <>
            <Modal
                className={`consent-forms-modal-main-div ${className} ${(type == "resend" || type === "renew") && "w-25"} ${type == "review" && "w-75"} ${removePadding && "remove-padding"} `}
                // isOpen={modalOpen}
                isOpen={open}
                shouldCloseOnOverlayClick={false}
                onRequestClose={() => {
                    closeModal();
                }}
                closeTimeoutMS={200}
                ariaHideApp={false}
                style={{
                    overlay: { zIndex: 1000 },
                }}
            >
                <div className={`modal-body-container-main-div container ${removePadding && "px-0"}`}>
                    <div className={``}>
                        <div className={`modal-header-div ${(type === "resend" || type === "renew") ? "text-center" : ""}`}>
                            <h5 className={`modal-title ${(type === "resend" || type === "renew") ? "m-auto" : ""}`}>
                                {modaltitle}
                            </h5>
                            {!hideCloseButton && !(type === "resend" || type === "renew") && (
                                <div className='d-flex justify-content-between gap-2'>
                                    <button className={`modal-close-icon `} ref={imgRef} onMouseOver={() => animateCloseIcon()} onMouseOut={() => animateCloseIcon(true)} onClick={() => closeModal()}>
                                        <img
                                            src={modalClose}
                                            alt="modalClose"
                                            width={30}
                                            height={30}
                                        />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`dynamic-content`}>
                        {modalBody}
                    </div>
                    {
                        (type === "resend" || type === "renew") &&
                        <div className='ok-button mt-4 text-center'>
                            <button onClick={() => { if (type === "resend") { setShowResendAlert(false) } else { setOpenRenewPopup(false); dispatch(clearRenewInsuranceStatus()) } }}>Ok</button>
                        </div>
                    }
                </div>
            </Modal >
        </>
    )
}

export default ConsentFormCustomModal