import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_TREATMENTS_FOR_CLINIC } from "../../constants/treatment-list-const/TreatmentListConst";
import { useSearchParams } from "react-router-dom";
import { GET_AFTERCARE_FORM_LIST, GET_CONSENT_FORM_LIST, GET_FORMS_LIST } from "../../constants/formsTemplate/FormTemplateConst";
import { useState } from "react";
import { useId } from "react";
import showToast from "../../../common/CustomToast/CustomToast";

// This is Use For get Consent Form List
const GET_CONSENT_FORM_LIST_QUERY = gql`
query GetConsentFormTemplateList($search: String, $page: Int, $limit: Int, $itemIds: [Int], $status: String) {
  getConsentFormTemplateList(search: $search, page: $page, limit: $limit, itemIds: $itemIds, status: $status) {
    success
    message
    data {
      consentForms {
        consentFormId
        consentFormName
        consentFormStatus
      }
      total
      totalPages
    }
  }
}
`;

var page;
export const GetConsentFormList = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fromManageFormsSection, setFromManageFormsSection] = useState(null);


  const [getConsentFormList, consentFormListData] = useLazyQuery(
    GET_CONSENT_FORM_LIST_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getConsentFormTemplateList: responseData }) => {
        if (responseData.success) {

          if (fromManageFormsSection == null) {
            dispatch({
              type: GET_CONSENT_FORM_LIST,
              payload: responseData.data, page
            });
          }
          else {
            console.log("else")
            dispatch({
              type: GET_FORMS_LIST,
              payload: { formsData: responseData.data, manageActiveSection: fromManageFormsSection }
            });
          }
        }
      },
    }
  );
  const consentFormListQueryData = consentFormListData;
  const initGetConsentFormList = (data, successCallBack) => {
    page = data?.page ? data?.page : 1

    console.log("check", data?.manageActiveSection)
    if (data?.manageActiveSection) {
      setFromManageFormsSection(data?.manageActiveSection)
      delete data["manageActiveSection"]
    }
    getConsentFormList({
      variables: data,
    }).then((res) => {
      if (res.data) {
        successCallBack(res.data.getConsentFormTemplateList)
      }
    });
  };
  return { initGetConsentFormList, consentFormListQueryData };
};


// This is Use For get Aftercare Form List
const GET_AFTERCARE_FORM_LIST_QUERY = gql`
query GetAftercareDocumentTemplateList($search: String, $page: Int, $limit: Int, $itemIds: [Int], $status: String) {
  getAftercareDocumentTemplateList(search: $search, page: $page, limit: $limit, itemIds: $itemIds, status: $status) {
    success
    message
    data {
      aftercareDocs {
        aftercareDocId
        aftercareDocName
        aftercareDocStatus
      }
      total
      totalPages
    }
  }
}
`;

var page;
export const GetAfterCareFormList = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fromManageFormsSection, setFromManageFormsSection] = useState(null);


  const [getAfterCareFormList, afterCareFormListData] = useLazyQuery(
    GET_AFTERCARE_FORM_LIST_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getAftercareDocumentTemplateList: responseData }) => {
        if (responseData.success) {

          if (fromManageFormsSection == null) {
            dispatch({
              type: GET_AFTERCARE_FORM_LIST,
              payload: responseData.data, page
            });
          }
          else {
            dispatch({
              type: GET_FORMS_LIST,
              payload: { formsData: responseData.data, manageActiveSection: fromManageFormsSection }
            });
          }
        }
      },
    }
  );
  const afterCareFormListQueryData = afterCareFormListData;
  const initGetAfterCareFormList = (data, successCallBack) => {
    page = data?.page ? data?.page : 1

    if (data?.manageActiveSection) {
      setFromManageFormsSection(data?.manageActiveSection)
      delete data["manageActiveSection"]
    }
    getAfterCareFormList({
      variables: data,
    }).then((res) => {
      if (res.data) {
        successCallBack(res.data.getAftercareDocumentTemplateList)
      }
    });
  };
  return { initGetAfterCareFormList, afterCareFormListQueryData };
};

const GET_MYFORMS_LIST_QUERY = gql`
  query GetCombinedConsentFormAndAftercareDocList($search: String, $page: Int, $limit: Int) {
    getCombinedConsentFormAndAftercareDocList(search: $search, page: $page, limit: $limit) {
      success
      message
      data {
        items {
          id
          name
          templateType
          formStatus
        }
        total
        totalPages
        currentPage
      }
    }
  }
`;

export const GetMyformsList = () => {
  const [ getMyformsRequest, getMyformsStatus ] = useLazyQuery(GET_MYFORMS_LIST_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });

  const getMyformsList = (data, successCallBack, errorCallback) => {
    getMyformsRequest({variables: data})
      .then(res => {
        const resData = res.data.getCombinedConsentFormAndAftercareDocList;
        if(resData.success) {
          successCallBack && successCallBack(resData.data);
        } else {
          errorCallback && errorCallback(resData);
        }
      })
      .catch(err => {
        errorCallback && errorCallback(err);
      })
  }

  return { getMyformsList, getMyformsStatus };
}

const CHANGE_FORM_ACTIVE_STATUS = gql`
  mutation ChangeConsentFormAndAftercareTemplateStatus($formId: Int, $templateType: String, $status: Boolean) {
    changeConsentFormAndAftercareTemplateStatus(formId: $formId, templateType: $templateType, status: $status) {
      success
      message
    }
  }
`;

export const ChangeFormActiveStatusMutation = () => {
  const [mutate, changeStatusQueryStatus] = useMutation(CHANGE_FORM_ACTIVE_STATUS);
  const toastId = useId();

  const changeStatusRequest = async (data, successCallback, errorCallback) => {
    try {
      const response = await mutate({ variables: data });
      const resData = response.data.changeConsentFormAndAftercareTemplateStatus;
      if (resData.success) {
        successCallback && successCallback(resData);
      } else {
        showToast('error', resData.message, undefined, undefined, undefined, undefined, {
            toastId,
        });
        errorCallback && errorCallback(resData);
      }
    } catch (error) {
      showToast('error', error.message, undefined, undefined, undefined, undefined, {
        toastId,
      });
      errorCallback && errorCallback(error);
    }
  };

  return { changeStatusRequest, changeStatusQueryStatus };
};

const GET_MEDICAL_FORM_QUESTION_QUERY = gql`
  query GetMedicalFormQuestions {
    getMedicalFormQuestions {
      success
      message
      data {
        questionData {
          id
          question
          subQuestions {
            id
            question
          }
        }
      }
    }
  }
`

export const GetMedicalFormQuestionsQuery = () => {
  const [ fetchFn, getMedicalFormQuestionsStatus ] = useLazyQuery(GET_MEDICAL_FORM_QUESTION_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });

  const initGetMedicalFormQuestions = (successCallBack, errorCallback) => {
    fetchFn()
      .then(res => {
        const resData = res.data.getMedicalFormQuestions;
        if(resData.success) {
          successCallBack && successCallBack(resData.data.questionData);
        } else {
          errorCallback && errorCallback(resData);
        }
      })
      .catch(err => {
        errorCallback && errorCallback(err);
      })
  }

  return { initGetMedicalFormQuestions, getMedicalFormQuestionsStatus };
}

const SAVE_MEDICAL_FORM_QUESTION_MUTATION = gql`
  mutation SaveMedicalFormQuestions($pmqId: Int, $question: String, $parentId: Int) {
    saveMedicalFormQuestions(pmqId: $pmqId, question: $question, parentId: $parentId) {
      success
      message
      data {
        pmq_id
      }
    }
  }
`;

export const SaveMedicalFormQuestionsMutation = () => {
  const [mutate, saveMedicalFormQuestionsStatus] = useMutation(SAVE_MEDICAL_FORM_QUESTION_MUTATION);
  const toastId = useId();

  const initSaveMedicalFormQuestions = async (data, successCallback, errorCallback) => {
    try {
      const response = await mutate({ variables: data });
      const resData = response.data.saveMedicalFormQuestions;
      if (resData.success) {
        showToast('success', resData.message);
        successCallback && successCallback(resData);
      } else {
        showToast('error', resData.message, undefined, undefined, undefined, undefined, {
            toastId,
        });
        errorCallback && errorCallback(resData);
      }
    } catch (error) {
      showToast('error', error.message, undefined, undefined, undefined, undefined, {
        toastId,
      });
      errorCallback && errorCallback(error);
    }
  };

  return { initSaveMedicalFormQuestions, saveMedicalFormQuestionsStatus };
};

const REMOVE_MEDICAL_FORM_QUESTION_MUTATION = gql`
  mutation RemoveMedicalFormQuestion($pmqId: Int) {
    removeMedicalFormQuestion(pmq_id: $pmqId) {
      success
      message
    }
  }
`;

export const RemoveMedicalFormQuestionMutation = () => {
  const [mutate, removeMedicalFormQuestionStatus] = useMutation(REMOVE_MEDICAL_FORM_QUESTION_MUTATION);
  const toastId = useId();

  const initRemoveMedicalFormQuestion = async (data, successCallback, errorCallback) => {
    try {
      const response = await mutate({ variables: data });
      const resData = response.data.removeMedicalFormQuestion;
      if (resData.success) {
        showToast('success', resData.message);
        successCallback && successCallback(resData);
      } else {
        showToast('error', resData.message, undefined, undefined, undefined, undefined, {
            toastId,
        });
        errorCallback && errorCallback(resData);
      }
    } catch (error) {
      showToast('error', error.message, undefined, undefined, undefined, undefined, {
        toastId,
      });
      errorCallback && errorCallback(error);
    }
  };

  return { initRemoveMedicalFormQuestion, removeMedicalFormQuestionStatus };
};