import React, { useEffect, useState } from "react";
import {
    useStripe,
    useElements,
    PaymentElement,
    CardElement,
    AddressElement,
    PaymentMethodMessagingElement,
    ElementsConsumer,
} from "@stripe/react-stripe-js";
import StepButton from "../common/StepButton";
import { CompletePaymentBooking } from "../redux/actions/completePaymentBooking/CompletePaymentBooking";
import { useNavigate, useParams } from "react-router";
import { ReactComponent as CloseIcon } from '../../images/close_icon.svg';
import { useSelector } from 'react-redux'
import visaImage from '../../assets/cart/visa.png';
import masterCardImage from '../../assets/cart/mastercard-logo.png';
import { CompleteForwardConsentFormPaymentBooking } from "../redux/actions/forward-consent-form/ForwardConsentFormBooking";
import { SaveStripeCardDetailsMutation } from "../redux/actions/forward-consent-form/saveStripeCardDetails";
import SkeletonLoader from "../common/Skeleton/SkeletonLoader";
import { SaveForwardConsentFormMutation } from "../redux/actions/forward-consent-form/saveForwardConsentForm";

const ConsentFormCheckoutForm = ({ clientSecret, successCallback, failureCallback, setProcess,setStep, bookingId, setIsOpenPayment, stripeDataObj, cfrToken ,noShowPolicy,isPayOnTheDay,selectedOption,note}) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const { slug } = useParams()
    const searchParams = new URLSearchParams(window.location.search)

    const [errorMessage, setErrorMessage] = useState(null);
    const [showLoader, setLoader] = useState(false);
    const [skeltonLoader,setSkeletonLoader]=useState(true)

    // GET SELECTED PAYMENT OPTION
    const { selectedPaymentOptionData } = useSelector((state) => state?.forwardConsentFormOverviewReducer)
    const { initiateCompleteForwardConsentFormPaymentBooking,completeForwardConsentFormPaymentBookingMutationData } = CompleteForwardConsentFormPaymentBooking()
    //SAVE STRIPE CARD DETAILS
    const {saveStripeCardDetailsData,initiateSaveStripeCardDetails} = SaveStripeCardDetailsMutation()
    const { initiateSaveForwardConsentForm } = SaveForwardConsentFormMutation()
    console.log("stripe component 2",noShowPolicy,selectedOption)

    useEffect(()=>{
       if(noShowPolicy){
        setTimeout(()=>{
            setSkeletonLoader(false)
        },2000)
       }
    },[noShowPolicy])

    const handleSubmitPayment = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.

        event.preventDefault();

        // if (!stripe || !elements) {
        //   // Stripe.js has not yet loaded.
        //   // Make sure to disable form submission until Stripe.js has loaded.
        //   return;
        // }

        setErrorMessage("");

        setLoader(true);
        // setProcess("Processing...")

        // if(selectedPaymentOptionData?.payOnTheDay){
        //           const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
        //             payment_method: {
        //                 card: elements.getElement(CardElement),
        //             },
        //             return_url: window.location.href
        //           });
    
        //           if (error) {
        //             // This point will only be reached if there is an immediate error when
        //             // confirming the payment. Show error to your customer (for example, payment
        //             // details incomplete)
        //             //   setErrorMessage(error.message);
        //             setErrorMessage(error.message);
        //             setProcess("Complete Payment")
        //             setLoader(false);
        //             failureCallback();
        //           } else {
        //             // Your customer will be redirected to your `return_url`. For some payment
        //             // methods like iDEAL, your customer will be redirected to an intermediate
        //             // site first to authorize the payment, then redirected to the `return_url`.
        //             console.log("setup intent success",setupIntent)
        //             if (
        //               setupIntent.status === "succeeded" ||
        //               setupIntent.status === "requires_capture"
        //             ) {
        //                 stripe.createToken(elements.getElement(CardElement)).then(function (result) {
        //                     if (result.error) {
        //                         // Handle error
        //                     } else {
        //                         var token
        //                         // Send the token to your server
        //                         token = result.token;
        //                         initiateSaveStripeCardDetails({
        //                             cfrId: bookingId,
        //                             cardToken: token?.id,
        //                             cfrToken: searchParams?.get('token'),
        //                             type:"FORWARD_CONSENT_FORM"
        //                         },(res=>{
        //                             console.log("res data akash=====",res)
        //                           if(res?.success){
        //                             // setStep(3)
        //                             setLoader(false);
        //                             // alert(res)
        //                           }
        //                         },err=>{
        //                            if(err?.success){
        //                              setStep(3)
        //                              setLoader(false);
        //                             // alert(err)
        //                            }else{
        //                             setStep(2)
        //                             setLoader(false)
        //                            }
        //                         }))
        //                     }
                        
        //             })}
        //           }
                
        //         return;
        // }
        //else {
        const { paymentIntent, error } = await stripe.confirmCardPayment(
            clientSecret,
            {
                //`Elements` instance that was used to create the Payment Element
                // elements,
                // confirmParams: {
                //   return_url: window.location.href,
                // },
                payment_method: {
                    card: elements.getElement(CardElement),
                },

                return_url: window.location.href,
            }
        );

        console.log(paymentIntent, "paymentIntent", bookingId)

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            //   setErrorMessage(error.message);
            setErrorMessage(error.message);
            // setProcess("Complete Payment")
            setLoader(false);
        //     console.log("client.complete",clientSecret)
           if(!clientSecret){
            const { error, setupIntent } = await stripe.confirmCardSetup(true,{
                payment_method: {
                    card: elements.getElement(CardElement),
                },
                return_url: window.location.href
              });
              console.log("clientSecret: " + clientSecret,error,setupIntent);
           }
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
           if (
                paymentIntent.status === "succeeded" ||
                paymentIntent.status === "requires_capture"
            ) {
                // setProcess("Complete Payment")
                stripe.createToken(elements.getElement(CardElement)).then(function (result) {
                    if (result.error) {
                        // Handle error
                    } else {
                        var token
                        // Send the token to your server
                        token = result.token;
                        // successCallback(paymentIntent.id, token.id);
                        setLoader(true);
                        initiateCompleteForwardConsentFormPaymentBooking({
                            bookingId: bookingId,
                            paymentId: paymentIntent.id,
                            paymentType: 2,
                            cfrToken:searchParams?.get('token'),
                            selectedPayment:selectedOption === "Pay Deposit" ? "PAY_DEPOSIT" : selectedOption === "Pay in full" ? "PAY_In_FULL" : null
                        }, (res) => {
                            if (res.success) {
                                setLoader(false);
                                setStep(3)
                                initiateSaveForwardConsentForm({
                                    cfrId:parseInt(bookingId),
                                    notes:note,
                                    token: searchParams.get('token'),
                                },(re)=>{},(err)=>{})
                              //  navigate(`/bookings/${slug}/success?bookingId=${bookingId}&ps=${paymentIntent.id}`)
                            } else {
                                setStep(2)
                                setLoader(false);
                                setErrorMessage(res.message);
                                //navigate(`/bookings/${slug}/fail?bookingId=${bookingId}&ps=${paymentIntent.id}`)
                            }
                        }, (e) => {
                            setStep(2)
                            setLoader(false);
                            setErrorMessage(e.message);
                        })
                        // Perform further processing with the token
                        // e.g., save the token to the customer in your database
                    }
                })
            }
        }
    
    };

    console.log("stripe object",selectedOption)

    return (
        <>
            <div className="container" >
                {console.log("stripe data", stripeDataObj)}
               
                <div className={"w-100 consent-form-payment-card-section"}>
                    {
                        stripeDataObj !== undefined ?
                            <div className='treatment-payment-section'>
                                {/* <div className='payment-card'>
                                    <b>Treatment name:</b>
                                    <p>{stripeDataObj?.treatmentNames}</p>
                                </div>
                                <hr /> */}
                                {/* <div className='payment-card'>
                                    <b>Date:</b>
                                    <p>{stripeDataObj?.date}</p>
                                </div> */}
                                {/* <hr /> */}
                                {console.log(stripeDataObj,"stripeDataObj")}
                               {stripeDataObj?.treatmentPrice && <div className='payment-card'>
                                    <b>Total amount</b>
                                    <p>{stripeDataObj?.treatmentPrice}</p>

                                    {console.log(stripeDataObj?.treatmentPrice, 'stripeDataObj?.treatmentPrice')}
                                </div>}
                               {stripeDataObj?.treatmentPrice && <hr />}
                                {stripeDataObj?.totalDeposit && <div className='payment-card'>
                                    <b>Deposit</b>
                                    <p>{stripeDataObj?.totalDeposit}</p>
                                </div>}
                                {stripeDataObj?.totalDeposit && <hr/>}
                                {stripeDataObj?.payableAmount && <div className='payment-card'>
                                    <b>Total to pay</b>
                                    <p>{stripeDataObj?.payableAmount}</p>
                                </div>}
                              
                               {stripeDataObj?.totalDue && <div className='payment-card'>
                                    <b>Due amount</b>
                                    <p>{stripeDataObj?.totalDue}</p>
                                </div>}
                            </div>
                            : null
                    }
                   { selectedOption != null && <form id='paymentForm' className="mt-4" onSubmit={(e) => handleSubmitPayment(e)}>
                        {/* <NewCard/> */}

                        <CardElement
                            className="checkoutcard-field mx-2"
                            options={{
                                hidePostalCode: true,
                                style: {
                                    //your style
                                },
                                classes: {
                                    //your classname

                                },
                              
                            }}
                        />
                         {/* Show error message to your customers */}
                         {errorMessage && <div className="card-invalid-text">{errorMessage}</div>}
                        {/* <button disabled={!stripe}>Submit</button> */}
                        {showLoader ? (
                            <p></p>
                        ) : (
                            <>
                                {/* <button type="submit" style={{ visibility: 'none' }} disabled={!stripe || showLoader}>
            Pay Now
          </button> */}
                            </>
                        )}

                       <div className="statics-logos">
                            <div className="statics-logos__item">
                                <img src={visaImage} alt="visa" />
                            </div>
                            <div className="statics-logos__item">
                                <img src={masterCardImage} alt="mastercard" />
                            </div>

                        </div>

                        {
                            noShowPolicy &&
                            <div className='no-show-policy-section'>
                            <h1>No show and cancellation policy</h1>
                            <p>Card details are securely stored. No upfront charge is made. Details could be billed depending on the businesses no show policy.</p>
                            </div>
                        }
                        <StepButton type={"submit"} blue={true} label={isPayOnTheDay ? "Confirm Booking" :"PAY NOW"}
                            isLoading={showLoader}
                        />

                       
                    </form>}

                </div>
         
                
            </div >
        </>
    );
};

export default ConsentFormCheckoutForm;