import React from 'react';
import insuranceCheckedLogo from '../../../images/thank-you/checked.png';

// import './insurance-thank-you.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { webBaseUrl } from '../../../config';
import './insurance-thank-you.scss';

function InsuranceThankyou() {
    const BackToHome= ()=>{
        window.top.location.href = webBaseUrl + 'user/dashboard';
    }
    return (
        <div>
            <div className="editprofilethank" id="parentDiv">
                <div className="row">
                    <div className="col-12"></div>
                    <div className="col-12">
                        <div className="mt-3">
                            <h2 className="first-text mb-3 text-center">
                                Thank you!
                            </h2>
                        </div>
                    </div>
                    <div className="col-12">
                        <img src={insuranceCheckedLogo} className="rightlogo img-fluid" alt="insurance-logo" />
                    </div>
                    <div className="col-12 paragraphs">
                        <div className="mt-3">
                            <div className="second-text mt-4 text-center">
                                <p>Thank you for your purchase. We will email you about the further steps.</p>
                            </div>
{/* 
                            <div className="third-text mt-4 text-center">
                                <p>To make change:</p>
                            </div>  
                            <div className="forth-text mt-4 text-center">
                                <p>1. Go to Settings.</p>
                                <p>2. 'My Treatments.'</p>
                                <p>3. Add or remove treatments.</p>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-12 mt-0 mt-md-4 mt-lg4 text-center">
                        <a href="javascript:void(0);" className="btn back-thank-you" id="" onClick={()=>BackToHome()}>Back To Home</a>
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default InsuranceThankyou;