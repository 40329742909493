import React, { useState } from 'react'
import "./product-card.scss"
import ProductImage from '../../images/thank-you/product/product.png'
import {default as ThankyouPageProductSkeleton} from '../common/Skeleton/SkeletonLoader'
import { toast } from 'react-toastify'
import StokiestModal from '../pages/forward-consent-thankyou/StokiestModal'
import { ProductTrackingThankyouPageMutation } from '../redux/actions/consent-form-thankyou/productTracking'

const ProductCard = ({ data, setSelectedProduct, selectedProduct, setIsPrescriptionRegisterModal,consentFormId }) => {
    const [checkedItems, setCheckedItems] = useState({});
    const [stokiestModal,setStokiestModal]=useState(false)
    const [stokiestMessage,setStokiestMessage]=useState("")
    const [redirectUrl,setRedirectUrl]=useState("")

    //FOR TRACKING PRODUCT MUATAION
    const {initiateProductTrackingThankyouPage,productTrackingThankyouPageMutationData} = ProductTrackingThankyouPageMutation()

    const handleRedirectShopPage=(shopUrl)=>{
        window.open(shopUrl, "_blank")
    }
   // const handleCheckboxChange = (e, item) => {
        // const value = e.target.value;
      //  const isChecked = e.target.checked;
        // if (isChecked && !item.is_user_registered_with_vendor) {
        //     // If the checkbox is being unchecked and the item is not registered with the vendor,
        //     // perform the necessary action (e.g., show modal)
        //     setIsPrescriptionRegisterModal(true);
        // }else if (isChecked && item.is_user_registered_with_vendor) {
        //     const updatedCart = [...selectedProduct, { ...item, quantity: 1, totalPrice: item.price }]
        //     setSelectedProduct(updatedCart);
        // } else if(isChecked && item?.vendor_is_pharmacy){
        //     const updatedCart = [...selectedProduct, { ...item, quantity: 1, totalPrice: item.price }]
        //     setSelectedProduct(updatedCart);
        // }else {
        //     // Prompt the user for confirmation before removing non-pharmacy items
        // const confirmed = window.confirm("Are you sure you want to remove non-pharmacy items?");
        // if (confirmed) {
        //     // Remove items that are not pharmacies from selected products
        //     const updatedCart = selectedProduct?.filter(selectedItem => !selectedItem.vendor_is_pharmacy);
        //     setSelectedProduct(updatedCart);
        // } else {
        //     // If the user cancels, do nothing
        //     // You can add any additional logic here if needed
        //     const updatedCart = selectedProduct?.filter(selectedItem => selectedItem.id !== item.id);
        //     setSelectedProduct(updatedCart);
        // }
           
        // }

        // if (isChecked && !item.is_user_registered_with_vendor) {
        //     // If the checkbox is being unchecked and the item is not registered with the vendor,
        //     // perform the necessary action (e.g., show modal)
        //     setIsPrescriptionRegisterModal(true);
        // } else {
        //     // Remove the item that is being unchecked
        //     const updatedCart = selectedProduct.filter(selectedItem => selectedItem.id !== item.id);
        //     setSelectedProduct(updatedCart);
        // }
   // };

  const productTrackingAPICall = (consentId,productId)=>{
    initiateProductTrackingThankyouPage({
        consentFormId: parseInt(consentId),
        productId:parseInt(productId)
    },(res=>{
        console.log("res",res)
    }),(err=>{
        console.log("error", err)
    }))
  }

   const handleCheckboxChange = (e, item) => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    if(isChecked && (item?.allow_buy_directly || item.is_retail_product)){
        productTrackingAPICall(consentFormId,item?.id)
       // setIsPrescriptionRegisterModal(false)
        const updatedCart = [...selectedProduct, { ...item, quantity: 1, totalPrice: item.price }]
        setSelectedProduct(updatedCart);
    } 
    else if (isChecked && !item.is_user_registered_with_vendor) {
        console.log("handleCheckboxChange", item);
        if(!item.pharmacy_request_sent) {
            // If the checkbox is being unchecked and the item is not registered with the vendor,
            // perform the necessary action (e.g., show modal)
            setIsPrescriptionRegisterModal(true);
        }        
        else {
            if(item?.registration_message){
                setStokiestMessage(item?.registration_message)
                setStokiestModal(true)
            }else{
                toast.error("Your request is under review.")
            }

            //toast.error(item.registration_message ? item.registration_message : "Your request is under review.")
        }
    }else if (isChecked && item.is_user_registered_with_vendor) {
        productTrackingAPICall(consentFormId,item?.id)
        const updatedCart = [...selectedProduct, { ...item, quantity: 1, totalPrice: item.price }]
        setSelectedProduct(updatedCart);
    } else {
        const updatedCart = selectedProduct?.filter(selectedItem => selectedItem.id !== item.id);
        setSelectedProduct(updatedCart);
    }
};

    return (
        <div className='product-card-container'>
            <h3>Products you may need</h3>
            <div className="row">
                {
                    data?.map((item, index) => (    
                        <div className="col-6 col-sm-6 col-md-4 col-lg-4" key={index}>
                            <label className={`card-main-section ${selectedProduct.some(selectedItem => selectedItem.id === item.id) ? 'selected-card': 'unselected-card'}`}>
                                <div className='card-img-section'>
                                    <div className='card-checkbox'>
                                        <input type="checkbox"
                                            className='d-none'
                                            value={item?.product_name}
                                            checked={selectedProduct.some(selectedItem => selectedItem.id === item.id)}
                                            onChange={(e) => handleCheckboxChange(e, item)}
                                        />
                                    </div>
                                    <img src={item?.image_url !== null ? item?.image_url : ProductImage } alt="" />
                                </div>
                                <div className='card-content-section'>
                                    <h4 onClick={()=> handleRedirectShopPage(item?.shop_page_url) }>{item?.product_name}</h4>
                                    <p>{item?.vendor_name}</p>
                                    <h5>{item?.currency}{item?.price}</h5>
                                </div>
                            </label>
                        </div>
                    ))
                }
            </div>
            <StokiestModal isOpen={stokiestModal} setIsOpen={setStokiestModal} message={stokiestMessage}/>
        </div>
    )
}

export default ProductCard