import { GET_CLIENT_ID } from "../../constants/client/CreateClient";

const initialState={
  clientId:""
}

export default function createClientReducer(state=initialState,action){
  switch(action.type){
    case GET_CLIENT_ID:
      return{
        ...state,
        clientId:action.payload,
      };
      default:
        return state
  }
}