import React, { useEffect, useState } from "react";
import "./client-profile.scss";
import OffCanvasModal from "../modal/OffCanvasModal/OffCanvasModal";
// import { Avatar } from "@mui/material";
import StepButton from "../common/StepButton";
import { ReactComponent as CallIcon } from "../../images/new-appointment/call.svg";
import { ReactComponent as WhtasappIcon } from "../../images/new-appointment/whatspp.svg";
import { ReactComponent as MsgIcon } from "../../images/new-appointment/text_reminder.svg";
import { ReactComponent as EmailIcon } from "../../images/new-appointment/email_reminder.svg";
import { ReactComponent as ProfileIcon } from "../../images/icons/client-profile/profile_icon.svg";
import { ReactComponent as RightArrow } from "../../images/icons/chevron-left.svg";
import { ReactComponent as AppointmentIcon } from "../../images/icons/client-profile/appointment_icon.svg";
import { ReactComponent as NoteIcon } from "../../images/icons/client-profile/note_icon.svg";
import { ReactComponent as FormIcon } from "../../images/icons/client-profile/form_icon.svg";
import { ReactComponent as SubscriptionIcon } from "../../images/icons/client-profile/sub_icon.svg";
import { ReactComponent as PrescriptionIcon } from "../../images/icons/client-profile/pre_icon.svg";
import FormsClient from "./FormsClient";
import CustomModal from "../modal/CustomModal";
import EmailClient from "./EmailClient";
import AddNotes from "../common/AddNotes";
import RebookAppointment from "../pages/appointment/rebook-appointment/RebookAppointment";
import NavHeader from "../common/NavHeader/NavHeader";
import { useLocation, useNavigate, useParams } from "react-router";
import { businessDashboardRoute, clientAdditionalPhotosPattern, getClientProfileFormsRoute, getEditClientRoute, getSearchClientRoute, getViewClientProfileRoute } from "../../Routes";
import { GetClientDetails } from "../redux/actions/client/GetClientDetails";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../utils/initials";
import Avatar from "react-avatar";
import { CLEAR_CLIENT_DETAILS } from "../redux/constants";
import AppointmentListByDay from "./AppointmentListByDay";
import SkeletonLoader from "../common/Skeleton/SkeletonLoader";
import { ClientSmsPopup } from "./smspopup/ClientSmsPopup";
import ClientPasswordPopup from "./passwordpopup/ClientPasswordPopup";
import { saveSelectedClientData } from "../redux/actions/client/SaveSelectedClient";
import photo from "../../images/icons/photo.svg"


const ClientProfile = () => {
  //state for open the client profile modal
  const [isClientProfile, setIsClientPeofile] = useState(false);
  const [isFormModal, setIsFormModal] = useState(false)
  const [isEmailForm, setIsEmailForm] = useState(false)
  const [isNoteForm, setIsNoteForm] = useState(false)
  const [isRebookAppointment, setIsRebookAppointment] = useState(false)
  const [isClientAppointment, setIsClientAppointment] = useState(false)
  const [notes, setNotes] = useState("")

  const [active, setActive] = useState("upcoming")
  const [isSMSOpen, setIsSMSOpen] = useState(false);
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);
  const [clickTitle, setClickTitle] = useState("")


  //Query
  const { clientDetailsQueryData, initGetClientDetails } = GetClientDetails()
  //Reducer
  const clientDetails = useSelector(state => state.clientDetailsReducer?.clientDetails)
  const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)

  //client id form router
  const location = useLocation()
  const slugClientId = useParams()?.clientId;

  const clientId = location?.state?.clientId || parseInt(slugClientId)
  const fromRebookScreen = location?.state?.fromRebookScreen
  const fromAppointmentScreen = location?.state?.fromAppointmentScreen
  const getBackToClientAppointmentScreen = location?.state?.getBackToClientAppointmentScreen



  //Get Client Details
  console.log("params", clientId)

  // useEffect(() => {
  //   dispatch(saveSelectedClientData({}))
  // }, [])

  useEffect(() => {
    if (clientId === undefined) {
      // navigate(getSearchClientRoute())

    } else {
      initGetClientDetails({
        clientId: clientId
      })
    }
    if (fromRebookScreen) {
      setActive('previous')
      setIsClientAppointment(true)
    }
    if (getBackToClientAppointmentScreen) {
      setActive(location?.state?.active)
      setIsClientAppointment(true)
    }
    if (fromAppointmentScreen) {
      setActive(location?.state?.active)
      setIsClientAppointment(true)
    }

  }, [clientId, fromRebookScreen, fromAppointmentScreen])

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#EEEFF3",
        color: "#000",
        width: "100px",
        height: "100px",
        fonts: "Inter",
        fontSize: "30px"
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`.toLocaleUpperCase(),
    };
  }

  const navigate = useNavigate();
  const redirectAppointment = () => {
    //navigate(getAppointmentListRoute())
    console.log("hii data", clientDetails)
    let data = {
      clentId: clientDetails?.clentId,
      clientName: clientDetails?.clientFirstName + " " + clientDetails?.clientLastName,
      clientEmail: clientDetails?.clientEmail,
      clientProfileImage: clientDetails?.clientProfileImage
    }
    dispatch(saveSelectedClientData(data))
    setIsClientAppointment(true)
  }

  const dispatch = useDispatch();

  const tileData = [
    {
      title: "Profile",
      icon: <ProfileIcon />
    },

    {
      title: "Appointments",
      icon: <AppointmentIcon />
    },
    {
      title: "Notes",
      icon: <NoteIcon />
    },
    {
      title: "Forms",
      icon: <FormIcon />
    },
    {
      title: "Subscriptions",
      icon: <SubscriptionIcon />
    },
    {
      title: "Prescriptions",
      icon: <PrescriptionIcon />,
      isNotShow: !(UserProfileData?.user?.userRoles?.includes(3) || UserProfileData?.user?.userRoles?.includes(4))
    },
    {
      title: "Additional photos",
      // icon: <Photo />,
      isNotShow: !clientDetails?.hasAdditionalPhotos,
      useCss: true,
    }

  ]

  const handleWhatsapp = () => {
    window.open('https://wa.me/15551234567')
  }

  const handleEmailModal = () => {
    setIsEmailForm(true)
    // setIsOpen(false)
  }

  const goBackSearchClient = () => {
    navigate(getSearchClientRoute())
    dispatch({
      type: CLEAR_CLIENT_DETAILS,
      payload: null
    })
  }

  //=== GOTO EDIT CLIENT PROFILE === 
  const handleEditNavigate = () => {
    if (UserProfileData?.usr_use_password_for_directory) {
      setIsPasswordOpen(true)
      setClickTitle("EditClick")
    }
    else {
      navigate(getEditClientRoute(clientId))
    }
  }

  //=== GOTO PRESCRIPTION ===
  const handleOpenPrescription = () => {
    window.open(`${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=client_prescriptions`, "_blank")
  }

  //=== GOTO SUBSCRIPTION ===
  const handleOpenSubscription = () => {
    window.open(`${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=subscriptions`, "_blank")
  }

  const noteClickHandler = () => {
    if (UserProfileData?.usr_use_password_for_directory) {
      setIsPasswordOpen(true)
      setClickTitle("NoteClick")
    }
    else {
      setIsNoteForm(true)
    }

  }

  const redirectCallback = () => {
    if (clickTitle == "EditClick") {
      navigate(getEditClientRoute(clientId))
    }
    else if (clickTitle == "NoteClick") {
      setIsNoteForm(true)
    }
  }

  const redirectClientProfile = () => {
    navigate(getViewClientProfileRoute(clientId))
  }

  const handleAdditionalPhotos = () => {
    navigate(clientAdditionalPhotosPattern(parseInt(clientId)))
  }

  const displayName = () => {
    const firstName = clientDetails?.clientFirstName ? capitalizeFirstLetter(clientDetails.clientFirstName) : '';
    const lastName = clientDetails?.clientLastName ? capitalizeFirstLetter(clientDetails.clientLastName) : '';
    return `${firstName} ${lastName}`.trim();
  };

  return (
    <div className="Main-client-Profile-page" style={clientDetailsQueryData?.loading ? { paddingTop: "0px" } : {}}>

      {/* Client profile Section start */}
      {
        clientDetailsQueryData?.loading ?
          <div className="clientProfileSkeletonLoader">
            <SkeletonLoader type={"profile-overview"} />
          </div>
          :
          <div className="Main-client-profile-inner-page">
            <div className="client-profile-container customeClientWrapper MainAppoimentPage">
              <div className="container-fluid bg-header m-0 p-0">
                <NavHeader applyPadding={false} isBackWithButton={true} isGray={true} btnLabel={"Edit"} handleOnBack={() => goBackSearchClient()} onClickCallback={() => handleEditNavigate()} />
                <div className="client-profile-header customeClientProfileWrapper">
                  <div className="avatar-icon">
                    <Avatar src={clientDetails?.clientProfileImage} fgColor="#000" className="avtar-overview-profile custom-object-fit-border" maxInitials={2} color="#EEEFF3" name={`${clientDetails?.clientFirstName} ${clientDetails?.clientLastName}`} size="80" round={true} />
                    {/* <img src={BlueTick} alt="blue-tick" /> */}
                  </div>
                  <h1>{displayName()} </h1>
                  <p>Client for {clientDetails?.clientFrom}</p>
                  {/* <StepButton blue={true} label={"Book appointment"} className={"w-25"} onClick={()=>setIsRebookAppointment(true)}/> */}
                  <div className="contact-container">
                    {clientDetails?.readableContact && <CallIcon onClick={() => window.open(`tel:${clientDetails?.readableContact?.split(" ").join("")}`)} />}
                    {clientDetails?.readableContact && <MsgIcon onClick={() => setIsSMSOpen(true)} />}
                    <EmailIcon onClick={() => handleEmailModal()} />
                    {clientDetails?.readableContact && <WhtasappIcon onClick={() => window.open(`https://wa.me/${clientDetails?.readableContact?.split(" ").join("")}`)} />}
                  </div>
                </div>
              </div>
              <div className="tile-client-container customeClientContainer m-3">
                {
                  tileData.map((item, index) => (
                    !item.isNotShow && <div className="tile-list-container mb-3" key={index} onClick={() => index === 0 ? redirectClientProfile() : index === 3 ? navigate(getClientProfileFormsRoute(clientId)) : index === 2 ? noteClickHandler() : index === 1 ? redirectAppointment() : index == 4 ? handleOpenSubscription() : item?.title === "Prescriptions" ? handleOpenPrescription() : item?.title === "Additional photos" ? handleAdditionalPhotos() : null}>
                      <div className="left-client-content">
                        {
                          !item.useCss ? item.icon :
                            <div className='treatment-actions-content d-flex gap-2'>
                              <div className='client-compliance-img-container6'>
                                <img className='main-img' src={photo} alt="" />
                                {/* <img className='time-icon' src={!docTabData?.photosStatus ? timeIcon1 : timeIcon2} /> */}
                              </div>

                            </div>
                        }
                        <h1>{item.title}</h1>
                      </div>
                      <div className="right-client-content">
                        <RightArrow />
                      </div>
                    </div>

                  )
                  )
                }
              </div>

            </div>
          </div>
      }
      {/* Client Profile Section End */}

      {/* SMS Popup */}
      <CustomModal
        modalOpen={isSMSOpen}
        setModalOpen={setIsSMSOpen}
        type={"common-width"}
        modaltitle={"Send message"}
        modaltitleCSS={"discount-title"}
        className={'CustomeSMSModel'}
        modalBody={
          <>
            <ClientSmsPopup clientDetails={clientDetails} setIsSMSOpen={setIsSMSOpen} />
          </>
        }
      />

      {/* password popup */}
      <CustomModal
        modalOpen={isPasswordOpen}
        setModalOpen={setIsPasswordOpen}
        type={"common-width"}
        modaltitle={"Please enter password"}
        modaltitleCSS={"discount-title"}
        className={'CustomePasswordModel'}
        modalBody={
          <>
            <ClientPasswordPopup redirectCallback={redirectCallback} setIsPasswordOpen={setIsPasswordOpen} />
          </>
        }
      />

      {/* this modal use for Forms modal*/}

      {/* <OffCanvasModal
        isOpen={isFormModal}
        isBack={true}
        customHeader={true}
        isBackOnly={true}
        className={'CustomeFormCanvas'}
        onHide={() => { setIsClientPeofile(false); setIsFormModal(false) }}
        body={<FormsClient clientId={clientId} />}
      /> */}


      <CustomModal
        type={"common-width"}
        className={'CustomeEmailModel'}
        modaltitle={"Email client"}
        modaltitleCSS={"discount-title"}
        modalBody={<EmailClient clientId={clientId} setIsEmailForm={setIsEmailForm} />}
        modalOpen={isEmailForm}
        setModalOpen={() => setIsEmailForm(false)}
        setIsOpenMobileCalendar={() => setIsEmailForm(false)}
      />

      <CustomModal
        modaltitle={"Add a note"}
        modaltitleCSS={"discount-title"}
        type={"common-width"}
        modalBody={<AddNotes clientId={clientId} notes={notes} setIsNoteAdded={setIsNoteForm} setIsNoteModal={setIsNoteForm} setNotes={setNotes} />}
        modalOpen={isNoteForm}
        className={'CustomeAddNoteModel'}
        setModalOpen={() => setIsNoteForm(false)}
        setIsOpenMobileCalendar={() => setIsNoteForm(false)}
      />


      <OffCanvasModal
        isOpen={isRebookAppointment}
        isBack={true}
        isSave={false}
        isIconBtn={true}
        isCloseRight={false}
        onHide={() => setIsRebookAppointment(false)}
        body={<RebookAppointment />}
      />

      {/* this modal use for Appointment list modal*/}
      <OffCanvasModal
        isOpen={isClientAppointment}
        isBack={true}
        customHeader={true}
        isBackOnly={true}
        className={'CustomeFormCanvas'}
        onHide={() => { setIsClientPeofile(false); setIsClientAppointment(false); setActive("upcoming") }}
        body={<AppointmentListByDay clientId={clientId} active={active} setActive={setActive} setIsClientAppointment={setIsClientAppointment} />}
      />
    </div>
  );
};

export default ClientProfile;
