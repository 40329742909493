import { useEffect, useState } from 'react';
import "./business-footer.scss"
import { useNavigate } from 'react-router';
import { frontendURL, webBaseUrl } from '../../config';
import { accountancyServicePagePattern, insuranceLandingPagePattern, socialMediaMarketingPagePattern } from '../../Routes';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

const BusinessFooter = () => {

  const [trainingAcademyUrl, setTrainingAcademyUrl] = useState();
  const { profileData } = useSelector((state) => state?.GetProfileDataReducer);

  let roles = profileData ? profileData.roles : [];
  const TRAINING_PROVIDER_ROLE = 2;
  const isTrainingProvider = () => {
    return roles.includes(TRAINING_PROVIDER_ROLE);
  };

  useEffect(() => {
    let trainingPortalLink = webBaseUrl + "/training-courses";

    if(roles && roles.length) {
      trainingPortalLink = isTrainingProvider() ? webBaseUrl + "/training-dashboard/dashboard" : webBaseUrl + "/training-dashboard/student-portal-dashboard"
    }

    setTrainingAcademyUrl(trainingPortalLink);
  }, [roles]);

  const handleCall = () => {
    window.open('tel:01785 558018', "_blank");
  }
  const handleEmail = () => {
    window.open('mailto:support@facesconsent.com', "_blank");
  }

  const navigate = useNavigate();

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  const [isGuest, setIsGuest] = useState(false);

  useEffect(() => {
    if (!Cookies.get("faces_access_token")) {
      setIsGuest(true);
    }
  }, []);



  return (
    <div className='business-footer-container'>
      <div className='top-footer py-5 container-fluid'>
        <div className='d-flex links justify-content-evenly row '>
          <div className='d-flex flex-column align-items-center col-md-3'>
            <h2>Sell</h2>
            {/* <span onClick={() => { window.location.href = webBaseUrl + "/say-hello" }}>Sell on Faces</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/seller-register-request" }}>Seller Registration</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/shop/sellers" }}>Seller List</span> */}
            <a className='footer-link' href={webBaseUrl + "/say-hello"}>Sell on Faces</a>
            <a className='footer-link' href={webBaseUrl + "/seller-register-request"}>Seller Registration</a>
            <a className='footer-link' href={webBaseUrl + "/shop/sellers"}>Seller List</a>
          </div>
          <div className='d-flex flex-column align-items-center col-md-3'>
            <h2>About Us</h2>
            {/* <span onClick={() => { window.location.href = webBaseUrl + "/consent-forms" }}>Consent Forms</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/aftercare" }}>Aftercare Forms</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/gdpr" }}>GDPR</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/hippa-privacy-policy" }}>HIPAA Compliance</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/blog" }}>Advice, Tips and News</span> */}
            <a className='footer-link' href={webBaseUrl + "/consent-forms" }>Consent Forms</a>
            <a className='footer-link' href={webBaseUrl + "/aftercare" }>Aftercare Forms</a>
            <a className='footer-link' href={webBaseUrl + "/gdpr" }>GDPR</a>
            <a className='footer-link' href={webBaseUrl + "/hippa-privacy-policy" }>HIPAA Compliance</a>
            <a className='footer-link' href={webBaseUrl + "/blog" }>Advice, Tips and News</a>
          </div>
          <div className='d-flex flex-column align-items-center col-md-3'>
            <h2>Services</h2>
            {/* <span onClick={() => { window.location.href = webBaseUrl + "/clinics" }}>Search Clinics</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/locations" }}>Search Treatments</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/prescriber/search" }}>Prescriber Search</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/finance-lenders/details" }}>Finance Lenders</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/training/frontend-list" }}>Training Courses</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/card-reader/company" }}>Card Machine Companies</span> */}
            <a className='footer-link' href={webBaseUrl + "/clinics" }>Search Clinics</a>
            {/* <a className='footer-link' href={webBaseUrl + "/locations" }>Search Treatments</a> */}
            {/* <a className='footer-link' href={webBaseUrl + "/prescriber/search" }>Prescriber Search</a> */}
            {/* <a className='footer-link' href={webBaseUrl + "/finance-lenders/details" }>Finance Lenders</a> */}
            <a className='footer-link' href={trainingAcademyUrl }>Training Courses</a>
            <a className='footer-link' href={webBaseUrl + "/card-reader/company" }>Card Machine Companies</a>
            {/* <span onClick={() => {
              // window.location.href = frontendURL + "/beauty-aesthetic-insurance-companies"
              navigate(insuranceLandingPagePattern())
            }}>Insurance Companies</span> */}
            <a className='footer-link' href={frontendURL + "/beauty-aesthetic-insurance-companies"}>Insurance Companies</a>
            {/* <span onClick={() => { window.location.href = webBaseUrl + "/sharp-collections" }}>Waste Management Services</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/cpd-services" }}>CPD Services</span> */}
            <a className='footer-link' href={webBaseUrl + "/sharp-collections" }>Waste Management Services</a>
            <a className='footer-link' href={webBaseUrl + "/cpd-services" }>CPD Services</a>
            {/* <span onClick={() => {
              // window.location.href = frontendURL + "/social-media-marketing"
              navigate(socialMediaMarketingPagePattern());
              handleScroll()
            }}>Social Media Marketing</span> */}
            <a className='footer-link' href={frontendURL + "/social-media-marketing"}>Social Media Marketing</a>
            {/* <span onClick={() => {
              // window.location.href = frontendURL + "/social-media-marketing"
              navigate(accountancyServicePagePattern());
              handleScroll()
            }}>Accountancy</span> */}
            {/* <a className='footer-link' href={frontendURL + "/accountancy-service"}>Accountancy</a> */}
            {/* <span onClick={() => { window.location.href = webBaseUrl + "/Aesthetic-Help-Line" }}>Complications Consultant</span> */}
            <a className='footer-link' href={webBaseUrl + "/aesthetic-help-line" }>Complications Consultant</a>

            <a className='footer-link' href={frontendURL + "/website-builder"}>Free Website Builder</a>
            <a className='footer-link' href={frontendURL + "/finance" }>Finance Options</a>
            <a className='footer-link' href={frontendURL + "/aesthetics-insurance" }>Get Aesthetics Insurance</a>
            <a className='footer-link' href={frontendURL + "/medical-malpractice-insurance" }>Get medical malpractice insurance</a>
            <a className='footer-link' href={frontendURL + "/accountancy-services" }>Accountancy Services</a>
            <a className='footer-link' href={frontendURL + "/find-a-prescriber-near-me" }>Find a prescriber</a>
            <a className='footer-link' href={frontendURL + "/prescription-haircare" }>Prescription hair care</a>
            <a className='footer-link' href={frontendURL + "/Treatment-page" }>Treatment page</a>
            <a className='footer-link' href={frontendURL + "/Clinic-page" }>Clinic page</a>
            <a className='footer-link' href={frontendURL + "/Location-Treatment-page" }>Location Treatment page</a>

            

          </div>
          <div className='d-flex flex-column align-items-center col-md-3'>
            <h2>Help</h2>
            {/* <span onClick={() => { window.location.href = webBaseUrl + "/privacy-terms" }}>Privacy Terms</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/terms-and-conditions" }}>Terms and Conditions</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/pages/shipping-and-return" }}>Shipping and returns</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/faqs" }}>Help and support</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/shop-faqs" }}>General FAQ’s</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/seller-faqs" }}>Seller’s FAQ’s</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/say-hello" }}>Say Hello</span>
            <span onClick={() => { window.location.href = webBaseUrl + "/contact-us" }}>Contact Us</span> */}
            <a className='footer-link' href={webBaseUrl + "/privacy-terms" }>Privacy Terms</a>
            <a className='footer-link' href={webBaseUrl + "/terms-and-conditions" }>Terms and Conditions</a>
            <a className='footer-link' href={webBaseUrl + "/pages/shipping-and-return" }>Shipping and returns</a>
            <a className='footer-link' href={webBaseUrl + "/faqs" }>Help and support</a>
            <a className='footer-link' href={webBaseUrl + "/shop-faqs" }>General FAQ’s</a>
            <a className='footer-link' href={webBaseUrl + "/seller-faqs" }>Seller’s FAQ’s</a>
            <a className='footer-link' href={webBaseUrl + "/say-hello" }>Say Hello</a>
            { !isGuest && <a className='footer-link' href={webBaseUrl + "/contact-us" }>Contact Us</a> }
          </div>
        </div>
      </div>
      <div className='bottom-footer text-center py-5'>
        <p>COPYRIGHT © {(new Date()).getFullYear()}. ALL RIGHTS RESERVED.</p>
        <p>Faces Consent Ltd, Staffordshire Technology Park, Centurion House, 27 Anson Ct, Stafford ST18 0GB, UK</p>
        <p>Faces Consent is an MHRA Licensed Broker</p>
        <span onClick={() => handleEmail()}>Email: support@facesconsent.com, </span><span onClick={() => handleCall()}>Phone: 01785 558018</span>
      </div>
    </div>
  )
}

export default BusinessFooter
