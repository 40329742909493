import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_PHARMACY_LIST } from "../../constants/consentFormThankyou/pharmacyListConst";


const GET_PHARMACY_LIST_QUERY = gql`
query GetPharmacyList($consentFormId: Int) {
    getPharmacyList(consent_form_id: $consentFormId) {
      success
      message
      data {
        items {
          name
          id
          is_pharmacy
        }
      }
    }
  }
  `;

// var page;
export const PharmacyListQuery = () => {
    const dispatch = useDispatch();
    const [getPharmacyList, pharmacyListData] = useLazyQuery(
        GET_PHARMACY_LIST_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getPharmacyList: responseData }) => {

                dispatch({
                    type: GET_PHARMACY_LIST,
                    payload: responseData?.data,
                    // page
                });
                if (responseData.success) {

                } else {

                }


            },
        }
    );
    const pharmacyListQueryData = pharmacyListData;
    const initPharmacyList = (data,successCallBack,errorCallBack) => {
        // page = data.page
        getPharmacyList({
            variables: data,
        }).then((res)=>{
            successCallBack(res?.data?.getPharmacyList)
        }).catch((err)=>{
            errorCallBack(err)
        })
    };
    return { initPharmacyList, pharmacyListQueryData };
};



