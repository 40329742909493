// import { GET_Hair_Products_Questions } from "../../constants/hair-prescription-product/HairProductConts";
import { GET_Hair_Products_Questions } from "../../constants/hair-prescription-product/HairProductConts";



const initialState = {
    allQuestions: [],
    totalCount: null
}

export default function GetHairProductQuestionsReducer(state = initialState, action) {

    switch (action.type) {

        case GET_Hair_Products_Questions:
            return {
                ...state,
                allQuestions: action?.payload?.allQuestions,
                totalCount: action?.payload?.totalCount
            };

        default:
            return state;
    }

}