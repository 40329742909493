import React, { useEffect } from "react";
import BusinessFooter from "../../businessFooter";
import BusinessHeader from "../../businessHeader";
import "./treatment-landing-page.scss";
import BannerImg from "../../../assets/Treatment/bannerImg.png";
import whatsappIcon from "../../../assets/Treatment/whatsapp-icon.svg";
import clinicImg from "../../../assets/Treatment/clinicImg.png";
import placeholderCourse from "../../../assets/Treatment/placeholder-course.png";
import servicesImg from "../../../assets/Treatment/servicesImg.png";
import avtarIcon from "../../../assets/Treatment/avtarIcon.png";
import starIcon from "../../../assets/Treatment/star.svg";
import placeholderTreIcon from "../../../assets/Treatment/placeholderTre.png";
import Slider from "react-slick";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router";
import { webBaseUrl } from "../../../config";
import { FaStar } from "react-icons/fa";

import "aos/dist/aos.css";
import AOS from "aos";

function Treatmentlandingpage() {
  const navigate = useNavigate();
  const ClicnicData = [
    {
      id: "01",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "02",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "03",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "04",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "05",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "06",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "07",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "08",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
  ];

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-next-arrow slick-next" onClick={onClick}>
        <BsArrowRight />
      </div>
    );
  };

  // Custom arrow component
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-prev-arrow slick-prev" onClick={onClick}>
        <BsArrowLeft />
      </div>
    );
  };

  const TestimonialSlider = {
    slidesToShow: 3,
    dots: false,
    swipeToSlide: true,
    focusOnSelect: false,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const relatedTreatment = [
    {
      id: '01',
      TreatmentName: "Consultation",
    },
    {
      id: '02',
      TreatmentName: "Filler Top Up",
    },
    {
      id: '03',
      TreatmentName: "Hair Line Regeneration",
    },
    {
      id: '04',
      TreatmentName: "Jaw and Chin Definition",
    },
    {
      id: '05',
      TreatmentName: "Consultation",
    },
    {
      id: '06',
      TreatmentName: "Filler Top Up",
    },
    {
      id: '07',
      TreatmentName: "Hair Line Regeneration",
    },
    {
      id: '08',
      TreatmentName: "Jaw and Chin Definition",
    },
    {
      id: '09',
      TreatmentName: "Consultation",
    },
    {
      id: '10',
      TreatmentName: "Filler Top Up",
    },
    {
      id: '11',
      TreatmentName: "Hair Line Regeneration",
    },
    {
      id: '12',
      TreatmentName: "Jaw and Chin Definition",
    }
  ]

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-in-out",
    });
    return () => {
      AOS.refresh();
    };
  }, []);

  return (
    <div className="MainTreatmentPage">
      <BusinessHeader />
      <section className="TreatmentBannerSec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6">
              <div className="BannerContent">
                <h1 data-aos="fade-up">Laser Hair Removal</h1>
                <p data-aos="fade-up">
                    Laser Hair Removal offers long-lasting smoothness by targeting hair follicles with precision, providing a convenient and effective solution for unwanted hair.
                </p>
                <div className="btnWrapper" data-aos="fade-up">
                  <button className="FillBtn" onClick={()=> {window.location.href=(webBaseUrl + '/clinics')}}>Book now</button>
                  <button className="WhatBtn" onClick={() => {window.open("https://api.whatsapp.com/send/?phone=%2B447398592729&text=Hi&type=phone_number&app_absent=0","_blank");}}>
                    <span className="btnIcon">
                      <img src={whatsappIcon} alt={whatsappIcon} />
                    </span>
                    Learn more
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 px-0">
              <div className="BannerImg" data-aos="fade-left" data-aos-duration="700">
                <img src={BannerImg} alt={BannerImg} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="TopClinicSec sameSpace" >
        <div className="container">
          <div className="secTitle"  
             id="TopClinicSec"
             data-aos="fade-up"
             data-aos-anchor="#TopClinic"
             data-aos-offset={700}
             data-aos-duration={700}
              >
            <div className="LeftContent">
              <h2>Laser Hair Removal</h2>
              <p>
              Search for a professional that specialises in Laser Hair Removal in your area
              </p>
            </div>
            {/* <div className="rightContent">
              <button className="ViewAll">View All</button>
            </div> */}
          </div>

          <div className="row">
            {ClicnicData?.map((item, index) => {
              return (
                <div className="col-sm-12 col-md-4 col-lg-3 mb-4"   
                id="TopClinicSec"
                data-aos="fade-up"
                data-aos-anchor="#TopClinic"
                data-aos-offset={800 + index * 100}
                data-aos-duration={800 + index * 100}
                
                >
                  <div className="TopClinicBox" key={index} onClick={()=>{window.location.href = "#"}}>
                    <div className="TopClinicBoxImg">
                      <img src={item?.treatmentImg} alt={item?.treatmentImg} />
                    </div>
                    <div className="TopClinicContent">
                      <h5>{item?.treatmentName}</h5>
                      <p className="ReviewPrise">5.0<span className="starIcon"><FaStar /></span> (5)</p>
                      <p className="priceText">{item?.treatmentPrice}</p>
                      <p className="LocationText">North Yorkshire, Liverpool</p>
                      {/* <button className="FillBtn">View More</button> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="bookingProcess bgGrey sameSpace" id="booking">
        <div className="container">
          <div className="secTitle"    
                  data-aos="fade-up"
                  data-aos-duration={700}
                  data-aos-offset={700}
                  data-aos-anchor="#booking">
            <div className="LeftContent">
              <h2 className="text-center">Booking process</h2>
              <p className="text-center">
              Follow these simple steps to book ‘Treatment’ at a time and date convenient to you.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-4"  
                  data-aos="fade-up"
                  data-aos-duration={800}
                  data-aos-offset={800}
                  data-aos-anchor="#booking">
              <div className="stepBox">
                <img src={placeholderCourse} alt={placeholderCourse} />
                <h5>Step 1</h5>
              </div>
            </div>
            <div className="col-sm-12 col-md-4"  
                  data-aos="fade-up"
                  data-aos-duration={850}
                  data-aos-offset={850}
                  data-aos-anchor="#booking">
              <div className="stepBox">
                <img src={placeholderCourse} alt={placeholderCourse} />
                <h5>Step 2</h5>
              </div>
            </div>
            <div className="col-sm-12 col-md-4"  
                  data-aos="fade-up"
                  data-aos-duration={900}
                  data-aos-offset={900}
                  data-aos-anchor="#booking">
              <div className="stepBox">
                <img src={placeholderCourse} alt={placeholderCourse} />
                <h5>Step 3</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="TestimonialSec sameSpace" id="Testimonial">
        <div className="container">
          <div className="secTitle"  
              data-aos="fade-up"  
              data-aos-duration={700}
              data-aos-offset={700}
              data-aos-anchor="#Testimonial">
            <div className="LeftContent">
              <h2 className="text-center">Top reviews of professionals</h2>
              <p className="text-center">
              A range of positive 5 star reviews left by clients to professionals listed on faces
              </p>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-sm-12 col-md-4"> */}
          <Slider {...TestimonialSlider} >
            <div>
              <div className="TestimonialBox">
                <div className="TestimonialFirstContent">
                  <div className="ProfileWrapper">
                    <div className="LeftProfile">
                      <img src={avtarIcon} alt={avtarIcon} />
                      <div className="ContnetWrapper">
                        <h5>Connie M.</h5>
                        <p>6 March 2024</p>
                      </div>
                    </div>
                    <div className="RightProfile">
                      <ul>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    dolor maximus non varius porta leo. sit ultrices placerat
                    facilisis sodales. vitae sollicitudin. Nam sed faucibus Nunc
                  </p>
                </div>
                <div className="TestimonialBottomContent">
                  <div className="BottomSecWrapper">
                    <div className="LeftSec">
                      <h5>Seven Star Salon</h5>
                      <div className="reviewWrapper">
                        <span className="reviewCount">5.0</span>
                        <span className="reviewStar">5.0</span>
                        <span className="reviewContent">208 reviews</span>
                      </div>
                      <p>UK, 45 Paisley Road West, Glasgow, G5...</p>
                    </div>
                    <div className="RightSec">
                      <img src={servicesImg} alt={servicesImg} />
                    </div>
                  </div>
                  <p>View all services</p>
                </div>
              </div>
            </div>
            <div>
              <div className="TestimonialBox">
                <div className="TestimonialFirstContent">
                  <div className="ProfileWrapper">
                    <div className="LeftProfile">
                      <img src={avtarIcon} alt={avtarIcon} />
                      <div className="ContnetWrapper">
                        <h5>Connie M.</h5>
                        <p>6 March 2024</p>
                      </div>
                    </div>
                    <div className="RightProfile">
                      <ul>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    dolor maximus non varius porta leo. sit ultrices placerat
                    facilisis sodales. vitae sollicitudin. Nam sed faucibus Nunc
                  </p>
                </div>
                <div className="TestimonialBottomContent">
                  <div className="BottomSecWrapper">
                    <div className="LeftSec">
                      <h5>Seven Star Salon</h5>
                      <div className="reviewWrapper">
                        <span className="reviewCount">5.0</span>
                        <span className="reviewStar">5.0</span>
                        <span className="reviewContent">208 reviews</span>
                      </div>
                      <p>UK, 45 Paisley Road West, Glasgow, G5...</p>
                    </div>
                    <div className="RightSec">
                      <img src={servicesImg} alt={servicesImg} />
                    </div>
                  </div>
                  <p>View all services</p>
                </div>
              </div>
            </div>
            <div>
              <div className="TestimonialBox">
                <div className="TestimonialFirstContent">
                  <div className="ProfileWrapper">
                    <div className="LeftProfile">
                      <img src={avtarIcon} alt={avtarIcon} />
                      <div className="ContnetWrapper">
                        <h5>Connie M.</h5>
                        <p>6 March 2024</p>
                      </div>
                    </div>
                    <div className="RightProfile">
                      <ul>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    dolor maximus non varius porta leo. sit ultrices placerat
                    facilisis sodales. vitae sollicitudin. Nam sed faucibus Nunc
                  </p>
                </div>
                <div className="TestimonialBottomContent">
                  <div className="BottomSecWrapper">
                    <div className="LeftSec">
                      <h5>Seven Star Salon</h5>
                      <div className="reviewWrapper">
                        <span className="reviewCount">5.0</span>
                        <span className="reviewStar">5.0</span>
                        <span className="reviewContent">208 reviews</span>
                      </div>
                      <p>UK, 45 Paisley Road West, Glasgow, G5...</p>
                    </div>
                    <div className="RightSec">
                      <img src={servicesImg} alt={servicesImg} />
                    </div>
                  </div>
                  <p>View all services</p>
                </div>
              </div>
            </div>
            <div>
              <div className="TestimonialBox">
                <div className="TestimonialFirstContent">
                  <div className="ProfileWrapper">
                    <div className="LeftProfile">
                      <img src={avtarIcon} alt={avtarIcon} />
                      <div className="ContnetWrapper">
                        <h5>Connie M.</h5>
                        <p>6 March 2024</p>
                      </div>
                    </div>
                    <div className="RightProfile">
                      <ul>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    dolor maximus non varius porta leo. sit ultrices placerat
                    facilisis sodales. vitae sollicitudin. Nam sed faucibus Nunc
                  </p>
                </div>
                <div className="TestimonialBottomContent">
                  <div className="BottomSecWrapper">
                    <div className="LeftSec">
                      <h5>Seven Star Salon</h5>
                      <div className="reviewWrapper">
                        <span className="reviewCount">5.0</span>
                        <span className="reviewStar">5.0</span>
                        <span className="reviewContent">208 reviews</span>
                      </div>
                      <p>UK, 45 Paisley Road West, Glasgow, G5...</p>
                    </div>
                    <div className="RightSec">
                      <img src={servicesImg} alt={servicesImg} />
                    </div>
                  </div>
                  <p>View all services</p>
                </div>
              </div>
            </div>
          </Slider>
          {/* </div>
          </div> */}
        </div>
      </section>

      <section className="whyFaces bgGrey sameSpace" id="whyFacesSec">
        <div className="container">
          <div className="secTitle" data-aos="fade-up"  
              data-aos-duration={700}
              data-aos-offset={700}
              data-aos-anchor="#whyFacesSec">
            <div className="LeftContent">
              <h2 className="text-center">
                Why <span className="BlueColor">Faces?</span>
              </h2>
              <p className="text-center">
              Over 100,000 professionals use faces to manage their client bookings, you can easily <br/> find a professional in your area and book a treatment that suits your needs.
              </p>
            </div>
          </div>

          <div className="SearchClicnic"  
              data-aos="fade-up"  
              data-aos-duration={750}
              data-aos-offset={750}
              data-aos-anchor="#whyFacesSec">
            <button className="FillBtn">Search clinics</button>
          </div>
        </div>
      </section>

      <section className="FaQSec sameSpace" id="FaQ">
        <div className="container">
          <div className="secTitle"   
          data-aos="fade-up"  
          data-aos-duration={750}
          data-aos-offset={750}
          data-aos-anchor="#FaQ">
            <div className="LeftContent">
              <h3 className="text-center">Questions about ‘Treatment’</h3>
              <p className="text-center">
              Find below the most common asked questions about ‘Treatment’
              </p>
            </div>
          </div>
          <div class="accordion" id="accordionExample">
            <div class="accordion-item"    
          data-aos="fade-up"  
          data-aos-duration={800}
          data-aos-offset={800}
          data-aos-anchor="#FaQ">
              <h3 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  Lorim Ipsum dummy text here
                </button>
              </h3>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="accordion-item"   
            data-aos="fade-up"  
          data-aos-duration={850}
          data-aos-offset={850}
          data-aos-anchor="#FaQ">
              <h3 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Lorim Ipsum dummy text here
                </button>
              </h3>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="accordion-item"   
          data-aos="fade-up"  
          data-aos-duration={900}
          data-aos-offset={900}
          data-aos-anchor="#FaQ">
              <h3 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Lorim Ipsum dummy text here
                </button>
              </h3>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="accordion-item"  
          data-aos="fade-up"  
          data-aos-duration={950}
          data-aos-offset={950}
          data-aos-anchor="#FaQ">
              <h3 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Lorim Ipsum dummy text here
                </button>
              </h3>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="accordion-item"  
          data-aos="fade-up"  
          data-aos-duration={1000}
          data-aos-offset={1000}
          data-aos-anchor="#FaQ">
              <h3 class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Lorim Ipsum dummy text here
                </button>
              </h3>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="accordion-item"  
          data-aos="fade-up"  
          data-aos-duration={1050}
          data-aos-offset={1050}
          data-aos-anchor="#FaQ">
              <h3 class="accordion-header" id="headingSix">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Lorim Ipsum dummy text here
                </button>
              </h3>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="treatmentsSec bgGrey sameSpace" id="treatments">
        <div className="container">
          <div className="secTitle"  data-aos="fade-up"  
          data-aos-duration={700}
          data-aos-offset={700}
          data-aos-anchor="#treatments">
            <div className="LeftContent">
              <h2 className="text-center">Related treatments</h2>
            </div>
          </div>
          <div className="treatmentsGridWrapper">
            {relatedTreatment.map((item, index) =>{
              return(
              <div className="treatmentsGridItem" key={index}>
                <p
              data-aos="fade-up"
              data-aos-anchor="#treatments"
              data-aos-offset={300 + index * 100}
              data-aos-duration={300 + index * 100}>{item?.TreatmentName}</p>
              </div>
              )
            })}
            
          </div>
        </div>
      </section>

      <section className="boxTreatment pb-0 sameSpace" id="boxTreatmentSec">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-8 px-0">
                <div className="boxLeftTreatment" 
              data-aos="fade-right"
              data-aos-anchor="#boxTreatmentSec"
              data-aos-offset={700}
              data-aos-duration={700}>
                    <h5>Laser Hair Removal treatment</h5>
                    <p>Laser Hair Removal treatment utilizes concentrated light energy to target and destroy hair follicles, inhibiting future hair growth. This safe and effective procedure offers long-lasting results and can be tailored to individual skin and hair types for optimal outcomes.</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 px-0">
                <div className="boxRightTreatment"
              data-aos="fade-left"
              data-aos-anchor="#boxTreatmentSec"
              data-aos-offset={700}
              data-aos-duration={700}>
                   <h5>How it works?</h5>
                    <p>Laser Hair Removal treatment targets hair follicles with concentrated light energy, disabling them to inhibit future hair growth. This safe and effective procedure selectively destroys hair while preserving surrounding skin, providing long-lasting smoothness. Multiple sessions may be needed for optimal results, tailored to individual skin and hair types.</p>
                </div>
              </div>
            </div>
          </div>
      </section>

      <section className="locationSec sameSpace" id="location">
        <div className="container">
          <div className="secTitle"  
              data-aos="fade-up"
              data-aos-anchor="#location"
              data-aos-offset={700}
              data-aos-duration={700}>
            <div className="LeftContent">
              <h2 className="text-center">Search by location</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-4"  
              data-aos="fade-up"
              data-aos-anchor="#location"
              data-aos-offset={950}
              data-aos-duration={950}>
              <div className="locationBox">
                <div className="locationHeader">
                    <h5>Popular towns</h5>
                </div>
                <ul>
                  <li>Stevenage</li>
                  <li>Solihull</li>
                  <li>Stourbridge</li>
                  <li>Kenilworth and Southam</li>
                  <li>Blackburn</li>
                  <li>Stevenage</li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-4"  
              data-aos="fade-up"
              data-aos-anchor="#location"
              data-aos-offset={850}
              data-aos-duration={850}>
              <div className="locationBox">
                <div className="locationHeader">
                    <h5>Popular cities</h5>
                </div>
                <ul>
                  <li>Liverpool</li>
                  <li>Manchester</li>
                  <li>Newcastle</li>
                  <li>Southampton</li>
                  <li>Portsmouth</li>
                  <li>Liverpool</li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-4"  
              data-aos="fade-up"
              data-aos-anchor="#location"
              data-aos-offset={750}
              data-aos-duration={750}>
              <div className="locationBox">
                <div className="locationHeader">
                    <h5>Popular countries</h5>
                </div>
                <ul>
                  <li onClick={()=>{window.location.href = "#"}}>North Yorkshire</li>
                  <li onClick={()=>{window.location.href = "#"}}>Greater London</li>
                  <li onClick={()=>{window.location.href = "#"}}>Northumberland</li>
                  <li onClick={()=>{window.location.href = "#"}}>Mid Glamorgan</li>
                  <li onClick={()=>{window.location.href = "#"}}>Lincolnshire</li>
                  <li onClick={()=>{window.location.href = "#"}}>North Yorkshire</li>
                </ul>
              </div>
            </div>
       
          </div>
        </div>
      </section>
      <BusinessFooter />
    </div>
  );
}

export default Treatmentlandingpage;
