import React from 'react'
import './customeNotiTost.scss'
import { ReactComponent as NotiLogo } from '../../images/notiLogo.svg'

function CustomeNotiTost({ Title, Desc, clickOnCallback }) {
  return (
    <div className='CusnotificaionTost' onClick={clickOnCallback}>
      <div className='NotiLogo'>
        <NotiLogo />
      </div>
      <div className='NotiContent'>
        <h5>{Title}</h5>
        <p>{Desc}</p>
      </div>
    </div>
  )
}

export default CustomeNotiTost