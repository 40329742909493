import React, { useState,useEffect } from 'react'
import SubHeading from '../hair-product-common/hair-product-sub-heading/SubHeading'
import { ReactComponent as PaymentImg } from '../../../../assets/HairProduct/payment-image.svg'
import { ReactComponent as Lock } from '../../../../assets/HairProduct/Group.svg'
import { ReactComponent as Visacard } from '../../../../assets/HairProduct/Frame 119.svg'
import { ReactComponent as Mastercard } from '../../../../assets/HairProduct/Frame 120.svg'
import './HairProductsSeven.scss'
import StepButton from '../../../common/StepButton'
import { StripeComponent } from "../../stripe-payment/stripe-component";
import { useSelector } from 'react-redux'

const HairProductsSeven = ({ intervalMonth, dueAmount, OnhandleContinue, errorCallbackFunc }) => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const [process, setProcess] = useState("Place Order")

  const { getPaymentIntent } = useSelector((state) => state?.GetHairPrescriptionProductPaymentDetailsReducer)

  return (
    <>
    <div className='Main-Pay-with-credit'>
      <SubHeading
        title={`Pay with credit or debit card `}
        // desc="varius Morbi lobortis, ex dui. lacus lorem. eget est. at orci eu odio tempor tortor. orci venenatis ex Cras varius eget Ut "
      />
      
      <div className='CardDivWrapper'>
        <label className='form-label'>Card number</label>
        <StripeComponent
          clientSecret={getPaymentIntent.intentId}
          paymentType="card"
          isCloseIcon={false}
          stripeChildCustomCSS={"w-100"}
          setProcess={setProcess}
          useSetupIntentFlow={true}
          successCallback={(token, paymentMethod) => OnhandleContinue(paymentMethod)}
          failureCallback={errorCallbackFunc}
          customButton={
            <div className="continue-btn-div-payment">
              <StepButton label={process} blue={true} />
            </div>
          }
          cardLogo={true}
        />

        {/* <div className="continue-btn-div">
          <StepButton label={"Place Order"} blue={true} onClick={OnhandleContinue} />
        </div> */}

        <div className="important-notice">
          <div className='important-notice-tag-content'>
            <p><b style={{ color: "#000" }}> Important : </b> By clicking “Place order”, you agree that:</p>
            <p>If prescribed, you are purchasing a continuous subscription and will be charged £{dueAmount} every {intervalMonth} months until you cancel or your prescription expires.</p>
          </div>
          <div className='bottomCardWrapper'>
            <div className='leftContent'>
              <Lock/>
              <p className='important-notice-dark-line'>Payments are processed securely</p>
            </div>
            <div className='leftContent'>
              <Visacard />
              <Mastercard />
            </div>
          </div>
        </div>

      </div>
      </div>

    </>
  )
}

export default HairProductsSeven