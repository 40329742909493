// ========================COMPLETE BOOKING PAYMENT========================

import { gql, useMutation } from "@apollo/client";

const PURCHASE_ACCOUNTANCY_SERVICES_PACKAGE_MUTATION = gql`
mutation PurchaseAccountancyPackage($packageId: Int, $packageSlug: String, $sessionId: String, $generatePaymentIntent: Boolean, $canUpgragePlan: Boolean, $userFname: String, $userLname: String, $userEmail: String, $userPhoneNumber: String) {
    purchaseAccountancyPackage(packageId: $packageId, packageSlug: $packageSlug, sessionId: $sessionId, generatePaymentIntent: $generatePaymentIntent, canUpgragePlan: $canUpgragePlan, user_fname: $userFname, user_lname: $userLname, user_email: $userEmail, user_phone_number: $userPhoneNumber) {
      success
      message
      data {
        packageReferenceId
        intentId
        packageId
        app_payment_url
        setupIntentId
        planUpgrade
        userInfo {
          user_fname
          user_lname
          user_email
          user_phone_number
        }
      }
    }
  }
 `;


export const PurchaseAccountancyServicesAction = () => {
    // const dispatch = useDispatch();
    const [
        PurchaseAccountancyPackageResponseFunction,
        purchaseAccountancyPackageResponseData,
    ] = useMutation(PURCHASE_ACCOUNTANCY_SERVICES_PACKAGE_MUTATION)

    //this will be called from components
    const purchaseAccountancyPackageQueryData = purchaseAccountancyPackageResponseData; //variable

    const initPurchaseAccountancyPackageData = (data, successCallback, errorCallback) => {
        PurchaseAccountancyPackageResponseFunction({
            variables: data,
        })
        // .then(({ data: { PurchaseAccountancyPackage: responseData } }) => {
        .then((responseData) => {
            console.log(responseData);
            if (responseData.data.purchaseAccountancyPackage.success) {
                // dispatch({
                //     type: SET_BOOKING_DETAILS,
                //     payload: responseData?.data
                // })
                successCallback(responseData)
                // toast.success(responseData.message)
            }
            else {
                errorCallback(responseData)
                // toast.error(responseData.message)
            }
        }).catch((err) => {
            errorCallback(err)
            // toast.error("something went wrong!!!")
        })
    }

    return { purchaseAccountancyPackageQueryData, initPurchaseAccountancyPackageData };
};
