import React, { useEffect, useState } from "react";
import "./Clientpassword.scss";
import TextInput from "../../../../common/TextInput";
import StepButton from "../../../../common/StepButton";
import BusinessNavigation from "../../../../common/businessNavigation/BusinessNavigation";
import SwitchButton from "../../../../common/switchButton/SwitchButton";
import { GetAccessClientDirectoryOrNot } from "../../../../redux/actions/passwordprotection/passwordprotection";
import CustomModal from "../../../../modal/CustomModal";
import { CheckPassword } from "../../../../redux/actions/check-password/CheckPassword";
import { toast } from "react-toastify";
import { FaRegEye } from "react-icons/fa6";
import { FaRegEyeSlash } from "react-icons/fa6";

function Clientpassword({ onClickSubmit, setStep, }) {
  const [clientDirectoryToggle, setClientDirectoryToggle] = useState(false);
  const [isPasswordProtection, setIsPasswordProtection] = useState(false);
  const { initiateCheckPassword, checkPasswordMutationData } = CheckPassword();
  const [basicInfoData, setBasicInfoData] = useState({
    firstName: "",
    email: "",
    phoneNumber: "",
    Password: ""
  });

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    email: "",
    phoneNumber: "",
    Password: ""
  });
  const togglePasswordVisibility = (name) => {
    setShowPassword((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };
  const [showPassword, setShowPassword] = useState({
    Oldpassword: false,

  });

  console.log(basicInfoData?.Password, "showPassword")
  console.log(clientDirectoryToggle, "clientDirectoryToggle")
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBasicInfoData((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(name, value);

    setValidationErrors((prev) => ({
      ...prev,
      Password: "",
    }));
  };
  // this use effect is for making password field empty while click on cross or outerwindow
  useEffect(() => { basicInfoData.Password = ""; }, [isPasswordProtection])


  const handlePasswordsubmit = () => {
    if (basicInfoData?.Password) {
      initiateCheckPassword(
        { password: basicInfoData.Password },  // No need for optional chaining here
        (res) => {
          if (res?.data?.passwordValid) {
            toast.success(res?.message);
            setIsPasswordProtection(false);
            setClientDirectoryToggle(!clientDirectoryToggle);
            basicInfoData.Password = "";  // Remove optional chaining
          } else {
            toast.error(res?.message);
            basicInfoData.Password = "";

            // Remove optional chaining
          }
        }
      );
    }

    else {
      setValidationErrors((prev) => ({
        ...prev,
        Password: "Password is required",
      }));
    }
  }
  console.log(basicInfoData?.Password, "basicInfoData?.PasswordbasicInfoData?.Password")
  //VALIDATION CHECK
  const validateInput = (name, value) => {
    switch (name) {
      case "firstName":
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            firstName: "First name is required",
          }));
        } else {
          if (/\d/.test(value)) {
            setValidationErrors((prev) => ({
              ...prev,
              firstName: "Invalid first name",
            }));
          } else {
            setValidationErrors((prev) => ({
              ...prev,
              firstName: "",
            }));
          }
        }
        break;
      default:
        break;
    }
  };

  const {
    getAccessOfClientDirectoryData,
    initGetAccessOfClientDirectoryData,
  } = GetAccessClientDirectoryOrNot()
  useEffect(() => {

    initGetAccessOfClientDirectoryData({}, (res) => { res?.data?.canAccessClientDetial ? setClientDirectoryToggle(true) : setClientDirectoryToggle(false) }, (err) => { console.log(err, "err000") })
  }, [])
  return (
    <div className="MainClientPassword Main684WidthSet">
      <BusinessNavigation
        isColumn={true}
        title={"Password protection"}
        desc={
          "Enable this to use the account password when updating the client information."
        }
        onBackClick={() => {
          setStep(0);
        }}
      />
      <div className="fieldWrapper">
        <div className="RequiredPasswordWrapper">
          <p>Require password for client directory</p>
          <SwitchButton
            isChecked={clientDirectoryToggle}
            onChange={() => {
              // setClientDirectoryToggle(!clientDirectoryToggle);
              setIsPasswordProtection(!isPasswordProtection)
            }}
          />
        </div>
        <StepButton
          label={"Update"}
          onClick={() => {
            onClickSubmit(clientDirectoryToggle);
            window.location.reload()

          }}
        />
        {/* <StepButton label={"Update"} onClick={() => { return  onClickSubmit(clientDirectoryToggle) ; setStep(0); }} /> */}
        {/* <StepButton label={"Update"} onClick={() => { return setStep(0) }} /> */}

      </div>

      <CustomModal
        modalOpen={isPasswordProtection}
        // modalOpen={true}
        modaltitle={"Password protection"}
        setModalOpen={setIsPasswordProtection}
        className="customePasswordprotectionModel"
        modalBody={
          <>
            <div className='ModelDetailsContent'>
              <div className="finance-input-container">
                <div className="InputIconWrapper">
                  <TextInput
                    id="Password"
                    label="Password"
                    placeholder="Password"
                    name="Password"
                    type={showPassword.Oldpassword ? "text" : "password"}
                    value={basicInfoData?.Password}
                    onChange={(e) => handleChange(e)}
                  />
                  <button
                    className="password-toggle-btn"
                    onClick={() => togglePasswordVisibility("Oldpassword")}
                  >
                    {showPassword.Oldpassword ? <FaRegEye /> : <FaRegEyeSlash />}
                  </button>
                </div>
                {validationErrors.Password && (
                  <span className="error">{validationErrors.Password}</span>
                )}
              </div>
              <div className="sendButton">
                <StepButton label={"Submit"} onClick={handlePasswordsubmit} />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
}

export default Clientpassword;