import React from 'react'
import backArrow from '../../../../images/back_arrow.svg'
import rightArrow from '../../../../images/right_arrow.svg'
import locationIcon from '../../../../images/Icon example (1).svg'
import photoIcon from '../../../../images/icons/photo.svg'
import emailIcon from '../../../../images/on-boarding imgs/emal.svg'
import homeIcon from '../../../../images/on-boarding imgs/store.svg'
import '../on-boarding-main.scss'
import { useNavigate } from 'react-router'

const LocationOnBoardingNextStp = ({setCurrentStep}) => {

    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/get-started-onboarding/profile')
    }
    const handleBackStep = () => { 
        setCurrentStep(1)
    }

    return (
        <>
            <div className='onboarding-location-main'>
                <div className='container'>
                    <div className='onboarding__header mt-5' style={{cursor:'pointer'}} onClick={handleBackStep}>
                        <img src={backArrow} alt='img' />
                    </div>
                    <div className="location__content">
                        <h1>Location </h1>
                        <p>Let’s set up your first location. You can add more later.</p>
                        {/*  */}
                        <div className='location__list' onClick={handleNavigate}>
                            <div className='d-flex'>
                                <div className='location__list__item__left'>
                                    <div className='location__list__img__circle'>
                                        <img src={homeIcon} width={30} height={30} alt='img' />
                                    </div>
                                    <span>Name location</span>
                                </div>
                            </div>
                            <div className='location__list__item__left'>
                                <img src={rightArrow} alt='img' />
                            </div>
                        </div>

                        <div className='location__list'>
                            <div className='d-flex'>
                                <div className='location__list__item__left'>
                                    <div className='location__list__img__circle'>
                                        <img src={locationIcon} width={30} height={30} alt='img' />
                                    </div>
                                    <span>Address</span>
                                </div>
                            </div>
                            <div className='location__list__item__left'>
                                <div className='btn'>Added</div>
                                <img src={rightArrow} alt='img' />
                            </div>
                        </div>

                        <div className='location__list'>
                            <div className='d-flex'>
                                <div className='location__list__item__left'>
                                    <div className='location__list__img__circle'>
                                        <img src={emailIcon} width={30} height={30} alt='img' />
                                    </div>
                                    <span>Contact details</span>
                                </div>
                            </div>
                            <div className='location__list__item__left'>
                                <img src={rightArrow} alt='img' />
                            </div>
                        </div>

                        <div className='location__list'>
                            <div className='d-flex'>
                                <div className='location__list__item__left'>
                                    <div className='location__list__img__circle'>
                                        <img src={photoIcon} width={30} height={30} alt='img' />
                                    </div>
                                    <span>Images</span>
                                </div>
                            </div>
                            <div className='location__list__item__left'>
                                <img src={rightArrow} alt='img' />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default LocationOnBoardingNextStp
