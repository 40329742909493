import React from 'react'
import StepButton from '../../common/StepButton';

const ListViewToolTipContent = ({ toolTipTitle, btnLabel, btnClassName, tooltipRef, onClickCallback, isLoading, className }) => {
    return (
        <div className={className}>
            <h1>{toolTipTitle}</h1>
            <StepButton
                type="submit"
                label={btnLabel}
                className={btnClassName}
                onClick={() => {
                    console.log("ReactTooltip", tooltipRef?.current)
                    if (onClickCallback) {
                        tooltipRef?.current?.close();
                        onClickCallback();
                    }
                }}
                isLoading={isLoading}
            />
        </div>
    )
}

export default ListViewToolTipContent
