import { Link } from "react-router-dom";
import CustomModal from "../modal/CustomModal"
import "./payLaterPopup.scss"
import payl8rImage from "../assets/images/payl8r/payl8r-image.png"
import { useForm, SubmitHandler } from "react-hook-form"
import { AddFinancialDetialsFormPayl8trPopup } from "../redux/actions/pay-later/addDetailsOfFinanceFromPayl8trPopup";
import { useEffect, useRef, useState } from "react";
import EraseImg from '../../../src/images/new-appointment/erase.png'
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import loadingIcon from "../../images/loading.gif";


const PayLaterPopup = ({ setShowPopup }) => {
  const [checked, setChecked] = useState(false);
  const inputRefs = [useRef(null), useRef(null), useRef(null)];
  const [bSortCodeinp, setBSortCodeinp] = useState(["", "", ""]);
  const [errorForCode, setErrorForCode] = useState("")
  const { register, handleSubmit, reset, setError,
    clearErrors, setValue, formState: { errors },
  } = useForm()
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [signatureError, setSignatureError] = useState(null);
  const sigPadRef = useRef();

  const { addFinancialDetailFromPayl8trpopupData, initiateaddFinancialDetailFromPayl8trPopup } = AddFinancialDetialsFormPayl8trPopup()

  const onSubmit = (data) => {
    const { accountNumber, bankName, bSortCode } = data;
    const accountNumberLength = accountNumber.toString().length;
    const alphabeticRegex = /^[A-Za-z\s]+$/;

    let hasError = false;

    // if (!alphabeticRegex.test(bankName.trim())) {
    //   setError('bankName', {
    //     type: 'manual',
    //     message: 'Only valid name is allowed.',
    //   });
    //   hasError = true;
    // } else {
    //   clearErrors('bankName');
    // }
    if (accountNumberLength < 8 || accountNumberLength > 11) {

      setError('accountNumber', {
        type: 'manual',
        message: 'Account number must be between 8 to 11 digits.',
      });
      hasError = true;
    } else {
      clearErrors('accountNumber');
    }

    if (bSortCodeinp.some((code) => code.trim() === "")) {
      setErrorForCode("Please enter Business sort code.")
      setError('bSortCode', {
        type: 'manual',
        message: 'Please enter Business sort code.',
      });
      hasError = true;
    } else {
      setErrorForCode("")
      clearErrors('bSortCode');
    }

    if (!trimmedDataURL) {
      setSignatureError("Please add signature")
      hasError = true;
    } else {
      setSignatureError("")
    }

    if (hasError) {
      return;
    } else {
      // alert("api call")
      const formattedBSortCode = bSortCodeinp.join('');
      const objTobeAdded = {
        bankAcNumber: data.accountNumber,
        businessSortCode: formattedBSortCode,
        signatureBase: trimmedDataURL
      };
      initiateaddFinancialDetailFromPayl8trPopup({ accountDetail: objTobeAdded }, (res) => {
        setShowPopup(false);
        console.log("res", res)
      }, (err) => {
        console.log("err", err)
      })
    }
  }

  const handleCheckbox = (e) => {
    setChecked(e.target.checked)
  }

  const handleInputChange = (index, value) => {
    const maxLength = 2;

    if (!isNaN(value) && value.length <= maxLength) {
      const updatedBSortCode = [...bSortCodeinp];
      updatedBSortCode[index] = value;
      setBSortCodeinp(updatedBSortCode);
      clearErrors("bSortCode");

      if (value.length === maxLength && index < 2) {
        document.getElementById(`inp${index + 1}`).focus();
      }
      setErrorForCode("");
    }
  };

  const clear = () => {
    sigPadRef?.current?.clear();
    setTrimmedDataURL(null);
  };

  return (
    <div className="popup-container container">
      <div className="popup-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-8">
              <h1 style={{ fontWeight: 800, letterSpacing: "0px" }}>
                Payl8r
              </h1>
              <h5 className="freedom">
                Give your clients the freedom of buy now payl8r
              </h5>
              <p className="know">
                Did you know you can now offer your clients buy now <strong>payl8r</strong> on your all <strong>treatments</strong>
              </p>
            </div>
            <div className="col-4">
              <img src={payl8rImage} width={"100%"} alt="nnnn" />
            </div>
          </div>
          <div className="terms-conditions row align-items-center" style={{ margin: "0px" }}>
            <div className="col-9">
              <p style={{ fontWeight: 500, fontSize: "14px" }}>Helping spread the cost for your clients is risk free and can inncrease their overall spend by 30%</p>
              <p style={{ fontSize: "14px" }}>Simply accept the <Link to='https://facesconsent.com/pages/terms-and-conditions'>terms and conditions</Link> and experience the benefits of buy now <strong>payl8r</strong></p>
            </div>
            <div className="col-3">
              <svg width="60" height="40" viewBox="0 0 84 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M81.7003 1.89959L58.2003 0.499587C56.8003 0.399587 56.0004 1.99959 56.9004 3.09959L62.8004 10.3996C63.4004 11.0996 63.2003 12.0996 62.6003 12.5996L45.3004 26.3996C44.6003 26.8996 43.7003 26.8996 43.1003 26.1996L35.8004 17.7996L33.6003 15.2996C33.0004 14.5996 32.0004 14.5996 31.4004 15.0996L29.1003 16.9996L1.40035 40.7996C0.800351 41.2996 0.700352 42.1996 1.10035 42.8996C1.60035 43.5996 2.50035 43.7996 3.20035 43.3996L29.8004 28.1996C30.5004 27.7996 31.3004 27.9996 31.8004 28.4996L39.8004 37.5996L42.0004 40.0996C42.6003 40.6996 43.5004 40.7996 44.2004 40.2996L46.8004 38.1996L68.9004 20.2996C69.6003 19.6996 70.6003 19.8996 71.1003 20.4996L77.0004 27.7996C77.9004 28.8996 79.6004 28.3996 79.8004 26.9996L83.3004 3.69959C83.3004 2.79959 82.6003 1.89959 81.7003 1.89959Z" fill="#7E62D1" />
              </svg>
            </div>
          </div>
          <div className="benefits my-3" style={{ marginTop: "15px" }}>
            <div className="">
              <h4 className="b-head">
                Why Offer Buy now Payl8r
              </h4>
              <ul className="listing-outer">
                <li>
                  There is <strong>0% joining fee</strong> for the next <strong>30 days</strong>
                </li>
                <li>
                  <strong>10% commission</strong> on all approved sales
                </li>
                <li>
                  <strong>Increase </strong>booking value by 30%
                </li>
                <li>
                  Payment within <strong>24 hours</strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex bank-detail row">
            {/* <div className="input-holder">
              <label className="label">Business Bank Name</label>
              <input type="text" className="inp" placeholder="Business Bank Name" {...register("bankName", { required: "Please enter Bank name" })} />
              {errors.bankName && <p role="alert" className="error">{errors.bankName.message}</p>}
            </div> */}
            <div className="col-md-6">
              <div className="input-holder">
                <label className="label">Business Account Number</label>
                <input type="number" className="inp" placeholder="Business Account Number" {...register("accountNumber", { required: "Please enter Account Number" })} />
                {errors.accountNumber && <p role="alert" className="error">{errors.accountNumber.message}</p>}

              </div>
              <div className="input-holder my-2">
                <label className="label">Business Sort Code</label>
                <div className="d-flex">

                  {[0, 1, 2].map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      className="inp2"
                      placeholder="00"
                      value={bSortCodeinp[index]}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      id={`inp${index}`}
                    />
                  ))}

                </div>
                {errorForCode && <span role="alert" className="errorTwo">{errorForCode}</span>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="paylater-signature-section">
                <label className="label">Signature</label>
                <div className="card-section">
                  <div className="d-flex flex-column paylater-wrapper-canvas">
                    {
                      // !previewDataURL ?
                      <SignatureCanvas
                        clearOnResize={false}
                        ref={sigPadRef}
                        canvasProps={{
                          className: "paylater-signCanvas",
                        }}

                        penColor="black"
                        throttle={null}
                        onEnd={() => {
                          setSignatureError("")
                          setTrimmedDataURL(sigPadRef?.current?.getTrimmedCanvas().toDataURL("image/png"))
                        }}
                      />
                      // :
                      // <img src={previewDataURL} style={{ objectFit: 'none', height: 200, width: 500 }} />
                    }
                    <div className="justify-content-between d-flex w-100">
                      {trimmedDataURL &&
                        <>
                          <div className="cursor-pointer mx-2 erase-img" onClick={() => clear()}>
                            <img src={EraseImg} alt="EraseImg" width={48} height={48} />
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {signatureError && <p role="alert" className="errorTwo mt-2">{signatureError}</p>}
            </div>
          </div>
          <div className="d-flex my-3">
            <div className="checkbox">
              <input type="checkbox" value={checked} onChange={handleCheckbox} />
            </div>
            <p className="terms-check">Accept the <Link to='https://facesconsent.com/pages/terms-and-conditions'>terms and conditions</Link> and continue.</p>
          </div>
          <button className={checked ? 'button ' : "disabledButton"} disabled={!checked} type="submit" style={{ background: "#1658F7" }}>
            {
              addFinancialDetailFromPayl8trpopupData?.loading ?
                <div className="d-flex justify-content-center p-0">
                  <img src={loadingIcon} className={"loadingIcon"} />
                </div>
                :
                <>
                  Okay
                </>
            }
          </button>
        </form>
      </div>
    </div>
  )
}

export default PayLaterPopup;
