import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../../../images/back_arrow.svg";

import "./finance-get-paid.scss";

import { useNavigate } from "react-router";
import { FinancetermsRoute,FinanceInsuranceRoute } from "../../../Routes";
import FinanceBanner from "../../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner";
import FinanceHeader from "../../finance-registration/FinanceHomeComponent/FinanceHeaderComponent/FinanceHeaderComponent";
import StepButton from "../../common/StepButton";
import {SaveFinanceBankDetailsAction} from "../../redux/actions/finance-registrtion/saveFinanceBankDetails"
import {GetFinanceBankDetailAction} from "../../redux/actions/finance-registrtion/getFinanceBankDetails"
import {GetFinanceRegistrationStatusAction} from "../../redux/actions/finance-registrtion/getFinanceRegistartionStatus"
import {validPincode} from "../../../utils/Regex"

export default function FinanceGetPaid({onNextStep,afterSubmit,finish,setComeFromDashBord}) {
  const navigate = useNavigate();
  const [accountNumber, setAccountNumber] = useState("");
  const [bankSortCode, setBankSortCode] = useState(["", "", ""]);
  const [sortCodeDigit,setSortCodeDigit]=useState("")
  const [errors, setErrors] = useState({});
  const [disabled,setDisabled]=useState(false)
const {saveFinanceBankDetailsQueryData,initSaveFinanceBankDetailData} =SaveFinanceBankDetailsAction()
const {getfinanceBankDetailQueryData,initGetFinanceBankDetailData}=GetFinanceBankDetailAction()
const {getFinanceRegistrtionStatusQueryData,initGetFinanceRegistrationData} =GetFinanceRegistrationStatusAction()

const {data}=getfinanceBankDetailQueryData;

const StatusData=getFinanceRegistrtionStatusQueryData?.data

console.log("data",data?.getBankDetailsOfUserForFinance?.data)

console.log("loggg",StatusData?.getUserRegistrationStatusForFinance?.data)

const completedStep=getFinanceRegistrtionStatusQueryData?.data?.getUserRegistrationStatusForFinance?.data;

useEffect(()=>{
  initGetFinanceRegistrationData()
},[])

useEffect(()=>{
  window.scrollTo(0, 0);
},[])

useEffect(()=>{
if(StatusData?.getUserRegistrationStatusForFinance?.data){
 const data=StatusData?.getUserRegistrationStatusForFinance?.data?.payl8r_request_approved
 if(data){
  setDisabled(true)
 }
}
},[StatusData?.getUserRegistrationStatusForFinance?.data])

useEffect(()=>{
    initGetFinanceBankDetailData({},(res)=>{
      console.log(res,"response data")
      setSortCodeDigit(res?.bank_sort_code)
    },(err)=>{})
},[])



console.log("bankSortCode",bankSortCode)

  const handleInputChange = (index, value) => {
    const maxLength = 2;

    if (!isNaN(value) && value.length <= maxLength) {
      const updatedBSortCode = [...bankSortCode];
      updatedBSortCode[index] = value;
      setBankSortCode(updatedBSortCode);
      setErrors(prevErrors => {
        return { ...prevErrors, sortCode: "" };
      });

      if (value.length === maxLength && index < 2) {
        document.getElementById(`inp${index + 1}`).focus(); 
      }
    }
  };

 
  const handleContinue = () => {
    const errors = {};

   

    if (accountNumber.trim().length < 8 || accountNumber.trim().length > 11) {
      errors.accountNumber = "Account number must be between 8 and 11 digits.";
    }

    // if (bankSortCode.some((code) => code.trim().length !== 2)) {
    //   errors.sortCodeDigit = "Sort code is required.";
    // }
    const sortCode = bankSortCode.join("");
    console.log("sortcode1111",sortCode)
    setSortCodeDigit(sortCode)
  

    if (sortCode.length !== 6) {
      errors.sortCode= "Sort code is required and must be 6 digits.";
    } 
    else if(!validPincode.test(sortCode)){
      errors.sortCode="please enter valid sort code."
    }
  //   else if(!validPincode.test(bankSortCode[1])){
  //   errors.sortCode="please enter valid sort code."
  //   }
  //   else if(!validPincode.test(bankSortCode[2])){
  //   errors.sortCode="please enter valid sort code."
  //  }
    
    
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      setErrors({});
      initSaveFinanceBankDetailData({
        bankAccountNumber:accountNumber,
        bankSortCode:sortCode
      },(data)=>{
        afterSubmit();
        onNextStep()
        setComeFromDashBord(false)
      },(error)=>console.log(error))
    }
   
  };

  useEffect(()=>{
    if(data?.getBankDetailsOfUserForFinance?.data){
      const bankDetail=data?.getBankDetailsOfUserForFinance?.data
      setAccountNumber(bankDetail?.bank_account_number)
      let arr  =[]
      for(let i =0; i<6; i = i+2){
        arr.push(bankDetail?.bank_sort_code.slice(i, i+2))
      }
     setBankSortCode(arr)
    }
  },[data?.getBankDetailsOfUserForFinance?.data])

  
  return (
    <div className="Main-get-paid">
    
      <div className="MainGetPaidPageSecWrapper">
        <FinanceBanner
            title="How to get paid"
            desc="Please enter the bank details you would like to be paid into."
            isBorder={!completedStep?.registration_complete && completedStep?.registration_complete !== undefined}
            currentStep={finish}
            totalSteps={4}
        />

        <div className="first-flex-div">
            <div className="finance-input-container">
              <span className="form-label">Business account number</span>
              <input
                class="form-control"
                type="number"
                placeholder="Business account number"
                value={accountNumber}
                onChange={(e) => {setAccountNumber(e.target.value); setErrors(prevErrors => {
                  return { ...prevErrors, accountNumber: "" };
                });}}
                style={{ border: "1px solid var(--Form, #878787)" }}
                readOnly={disabled}
              />
               {errors.accountNumber && (
                <p className="error">{errors.accountNumber}</p>
              )}
            </div>

            <div className="finance-input-container">
              <span className="form-label">Business sort code</span>
              <div className="d-flex gap-2">
              {[0, 1, 2].map((_, index) => (
              <input
                class="form-control"
                type="text"
                style={{ border: "1px solid var(--Form, #878787)" }}
                value={bankSortCode[index]}
                onChange={(e) => handleInputChange(index, e.target.value)}
                id={`inp${index}`}
                readOnly={disabled}
              />
              ))}
              </div>
           
              {errors.sortCode && (
                <p className="error">{errors.sortCode}</p>
              )}
            </div>

            <div className="third-flex-div">
                  <StepButton 
                  label={"Continue"}
                   blue={true} 
                   isLoading={saveFinanceBankDetailsQueryData.loading}
                   onClick={() => handleContinue()} />
              </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  );
}
