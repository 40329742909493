// ========================COMPLETE BOOKING PAYMENT========================

import { gql, useMutation } from "@apollo/client";
import { CONNECT_STRIPE_EXPRESS_ERROR, CONNECT_STRIPE_EXPRESS_SUCCESS } from "../../constants";
import { useDispatch } from "react-redux";

const STRIPE_ONBOARDING_MUTATION = gql`
  mutation StripeOnboarding {
    stripeOnboarding {
      success
      message
      data {
        stripe_acc_id
        account_link
      }
    }
  }
`;


export const StripeOnboardingMutationAction = () => {
  const dispatch = useDispatch();
  const [
    StripeOnboardingResponseFunction,
    stripeOnboardingResponseData,
  ] = useMutation(STRIPE_ONBOARDING_MUTATION)

  //this will be called from components
  const stripeOnboardingQueryData = stripeOnboardingResponseData; //variable

  const initStripeOnboardingData = (data, successCallback, errorCallback) => {
    StripeOnboardingResponseFunction({
      variables: data,
    })
      // .then(({ data: { StripeOnboarding: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        if (responseData?.data?.stripeOnboarding?.success) {
          dispatch({
            type: CONNECT_STRIPE_EXPRESS_SUCCESS,
            payload: responseData?.data
          })
          successCallback(responseData)
          // toast.success(responseData.message)
        }
        else {
          dispatch({
            type: CONNECT_STRIPE_EXPRESS_ERROR,
          })
          errorCallback(responseData)
          // toast.error(responseData.message)
        }
      }).catch((err) => {
        dispatch({
          type: CONNECT_STRIPE_EXPRESS_ERROR,
        })
        errorCallback(err)
        // toast.error("something went wrong!!!")
      })
  }

  return { stripeOnboardingQueryData, initStripeOnboardingData };
};
