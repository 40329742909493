import './walk-in-main.scss'
import { toast } from 'react-toastify'
import Pill from '../../images/Pill.svg'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Trash from "../../images/trash.svg";
import StepButton from '../common/StepButton'
import AdminIcon from '../../images/admin.svg'
import CustomModal from '../modal/CustomModal'
import heartIcon from "../../images/heart.svg";
import React, { useRef, useState } from 'react'
import Arrow from '../../images/right_arrow.svg'
import FormIcon from '../../images/formicon.svg'
import clearBtn from '../../images/close_icon.svg'
import HandShake from "../../images/handshake.svg";
import Date from '../../images/Icon example (2).svg'
import Time from '../../images/Icon example (3).svg'
import Service from '../../images/services-icon.svg'
import PassTheClientPopUp from './PassTheClientPopUp'
import SignatureCanvas from "react-signature-canvas";
import crossArrow from '../../images/Icon example.svg'
import Location from '../../images/Icon example (1).svg'
import ConsentFormCustomModal from '../modal/ConsentFormModal/ConsentFormModal';
import { ReactComponent as UndoIcon } from "../../images/signature pop-icon/undo.svg";
import { AddClientWalkInFormAction } from '../redux/actions/walk-in-form/getClientList';
import { ReactComponent as EmptySignIcon } from "../../images/signature pop-icon/empty_sign.svg";
import { ReactComponent as EnableCheck } from "../../images/signature pop-icon/enable_check.svg";
import { ReactComponent as UndoEnableIcon } from "../../images/signature pop-icon/undo_enable.svg";
import { ReactComponent as DisableCheck } from "../../images/signature pop-icon/disable_check.svg";
import { ReactComponent as EnableSignIcon } from "../../images/signature pop-icon/active_sign.svg";
import NavHeader from '../common/NavHeader/NavHeader'

const WalkInConsentPage = () => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false);
  const [isSigned, setIsSigned] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [isSignatureOpen, setIsSignatureOpen] = useState(false);
  const selectedData = useSelector((state) => state?.walkInClientNameReducer);

  const { initAddClientWalkInFormData, addClientWalkInFormQueryData } = AddClientWalkInFormAction();

  // const clientId = selectedData?.treatmentId;
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const openModal = () => {
    scrollToTop();
    setIsModalOpen(true);
  };

  // const handleSaveWalkInForm = () => {
  //   setLoader(true);
  //   const data = {
  //     clientId: selectedData?.selectedClientId,
  //     clinicId: selectedData?.clinicLocationClinicId,
  //     treatmentIds: selectedData?.clinicServicesList.map(item => item?.treatmentId),
  //     appointmentDate: '',
  //     appointmentStartTime: '',
  //     appointmentEndTime: '',
  //     consentFormIds: selectedData?.consentFormList.map(item => item?.consentFormId),
  //     aftercareDocumentIds: selectedData?.afterCareFormList.map(item => item?.aftercareDocId),
  //     // consentFormIds: id,
  //   };
  //   initAddClientWalkInFormData(data);
  //   setLoader(false);
  //   setIsModalOpen(false);
  // }


  const handleClientOverViewPage = () => {
    navigate('/client-overview')
  }
  const handleNextAddFormPage = () => {
    navigate('/add-form')
  }
  const handleServicesPage = () => {
    navigate('/services')
  }
  const handleLocationPage = () => {
    navigate('/locations')
  }
  const handleGoToQAPage = () => {
    navigate('/clinic-walk-in-form')
  }
  const handleGotBackToHome = () => {
    navigate('/')
  }
  const handleGoToQApage = () => {
    navigate('/clinic-walk-in-form')
  }
  const checkCooseService = () => {
    toast.error("Please select a location!")
  }
  const checkFormData = () => {
    toast.error("Please select form data!")
  }

  // =================== Signature ===================
  const sigPadRef = useRef();
  const clear = () => {
    sigPadRef.current.clear();
    setIsSigned(false);
  };
  const handleHide = () => {
    //setIsOpen(false);
    setIsSignatureOpen(true);
  };
  const handleSignatureModalClose = () => {
    setIsSignatureOpen(false); // Close the SignatureModal
    // setIsOpen(true); // Close the NewAppointment
  };
  const handleSignature = () => {
    setTrimmedDataURL(
      sigPadRef.current?.getSignaturePad()?.toDataURL("image/png")
    );
  };
  return (
    <div className='walk-in-consent-Main-form'>
      <NavHeader isBackOnly={true} handleOnBack={handleGotBackToHome} />
      <div className='container'>
        <div className='walk-in-consent-page-main'>
          {/* <div className='header-img mt-5' onClick={handleGotBackToHome}>
            <img src={clearBtn} alt='zzz' />
          </div> */}
          <div className='walk-in-consent-page'>
            <div className='SectionHeading'>
              <div className="Section-Title">Walk-in Consent</div>
              <div className="Section-Desc">Add in client information to get their consent.</div>
            </div>
            {/* <div className='walk-in-consent-page-header'>
              <h3></h3>
              <p></p>
            </div> */}
            <div className='appointment-info-card'>
              <div className='appointment-info-card-icon'>
                <span className='walk-in-title'>
                  <h3>Book & Consent together</h3>
                </span>
                <div className='rightAppointment'>
                  <button>NEW</button>
                  <img src={crossArrow} alt='crossArrow' />
                </div>
              </div>
              <div className='appointment-info-card-info'>
                <p>Schedule clients and collect consent all at once.</p>
                <button className='btn'>Get started</button>
              </div>
            </div>
            <div className='appointment-client-sec'>
              <div className='client-titel'>
                <p>Client</p>
              </div>
              <div className='left-img' onClick={handleClientOverViewPage}>
                <div className='d-flex left_content'>
                  <div className='img-foot'>
                    <img src={AdminIcon} alt='step1' />
                  </div>
                  <div className="user-details">
                    {selectedData?.walkInClientName ? <p>{selectedData?.walkInClientName}</p> : <p>Add a client</p>}
                  </div>
                </div>
                <img src={Arrow} width={20} height={20} alt='step1' />
              </div>
            </div>

            <div className='appointment-client-sec'>
              <div className='client-titel'>
                <p>Appointment details</p>
              </div>
              <div className='left-img' onClick={handleLocationPage}>
                <div className='d-flex left_content'>
                  <div className='img-foot'>
                    <img src={Location} alt='step1' />
                  </div>
                  <div className="user-details">
                    {selectedData?.clinicLocation ? <p>{selectedData?.clinicLocation}</p> : <p>Choose location</p>}
                  </div>
                </div>
                <img src={Arrow} width={20} height={20} alt='step1' />
              </div>
              <div className='left-img'>
                <div className='d-flex left_content'>
                  <div className='img-foot'>
                    <img src={Date} alt='step1' />
                  </div>
                  <div className="user-details">
                    <p>Pick date</p>
                  </div>
                </div>
                <img src={Arrow} width={20} height={20} alt='step1' />
              </div>
              <div className='left-img'>
                <div className='d-flex left_content'>
                  <div className='img-foot'>
                    <img src={Time} alt='step1' />
                  </div>
                  <div className="user-details">
                    <p>Choose time</p>
                  </div>
                </div>
                <img src={Arrow} width={20} height={20} alt='step1' />
              </div>
            </div>

            <div className='appointment-client-sec'>
              <div className="client-titel d-flex services servicesWrapper">
                <p>Services</p>
                {selectedData?.clinicServicesList?.length > 0 ? <h5 onClick={handleServicesPage}>Add</h5> : null}
              </div>
              {selectedData?.clinicServicesList?.length > 0 ?
                selectedData?.clinicServicesList.map((service, index) => (
                  <div className='left-img' key={index}>
                    <div className='d-flex left_content'>
                      <div className='img-foot'>
                        <img src={Service} alt='step1' width={5} height={5} />
                      </div>
                      <div className="user-details">
                        <p>{service?.treatmentName}</p>
                      </div>
                    </div>
                    <div className="d-flex services_sec services-Rupes-data">
                      <h5>£{service?.treatmentPrice}</h5>
                      <img src={Trash} width={20} height={20} alt="step1" />
                    </div>
                  </div>
                ))
                :
                <div className='left-img' onClick={selectedData?.clinicLocation ? handleServicesPage : checkCooseService}>
                  <div className='d-flex left_content'>
                    <div className='img-foot'>
                      <img src={Service} alt='step1' width={5} height={5} />
                    </div>
                    <div className="user-details">
                      <p>Choose Service</p>
                    </div>
                  </div>
                  <img src={Arrow} width={20} height={20} alt='step1' />
                </div>
              }
            </div>


            <div className='appointment-client-sec'>
              <div className="client-titel d-flex services">
                <p>Forms</p>
                {(selectedData?.consentFormList?.length > 0 || selectedData?.afterCareFormList?.length > 0 != []) && (
                  <h5 onClick={handleNextAddFormPage}>Add</h5>
                )}
              </div>
              {(selectedData?.consentFormList?.length > 0 || selectedData?.afterCareFormList?.length > 0) ? (
                <>
                  {selectedData?.consentFormList && selectedData?.consentFormList
                    .filter(item => item !== undefined)
                    .map((item, index) => (
                      <div className='left-img' onClick={handleNextAddFormPage} key={index}>
                        <div className='d-flex left_content'>
                          <div className='img-foot'>
                            <img src={HandShake} alt="step1" />
                          </div>
                          <div className="user-details">
                            {item?.consentFormName}
                          </div>
                        </div>
                        <img src={Arrow} width={20} height={20} alt='step1' />
                      </div>
                    ))}
                  {selectedData?.afterCareFormList && selectedData?.afterCareFormList
                    .filter(item => item !== undefined)
                    .map((item, index) => (
                      <div className='left-img' onClick={handleNextAddFormPage} key={index}>
                        <div className='d-flex left_content'>
                          <div className='img-foot'>
                            <img src={heartIcon} alt="step1" />
                          </div>
                          <div className="user-details">
                            {item?.aftercareDocName}
                          </div>
                        </div>
                        <img src={Arrow} width={20} height={20} alt='step1' />
                      </div>
                    ))}
                </>
              ) : (
                <div className='left-img' onClick={handleNextAddFormPage}>
                  <div className='d-flex left_content'>
                    <div className='img-foot'>
                      <img src={FormIcon} alt='step1' />
                    </div>
                    <div className="user-details">
                      <p>Add a form</p>
                    </div>
                  </div>
                  <img src={Arrow} width={20} height={20} alt='step1' />
                </div>
              )}
            </div>


            {/* {selectedData?.clinicServicesList && selectedData?.walkInClientName && selectedData?.clinicLocation ? */}
              <div className="right-section cardSignature">
                <div className="card-section">
                  <div
                    className="d-flex flex-column align-items-center gap-2 sign-section"
                    onClick={handleHide}
                  >
                    {trimmedDataURL ? <EnableSignIcon /> : <EmptySignIcon />}
                    <h1>Your signature</h1>
                  </div>
                </div>
              </div>
               {/* : null} */}

            <div className="form-btn">
              <StepButton label={"Pass to client "} blue={true} onClick={selectedData?.clinicServicesList && selectedData?.walkInClientName && selectedData?.consentFormList ? openModal : checkFormData} />
            </div>

            {/* <div className="form-btn mt-4">
              {selectedData?.clinicServicesList && selectedData?.walkInClientName && selectedData?.consentFormList ?
                <StepButton label={"View consent form"} blue={true} onClick={selectedData?.clinicServicesList && selectedData?.walkInClientName && selectedData?.consentFormList ? openModal : checkFormData} />
                : <StepButton label={"Save"} gray={true} disabled={true} />}
            </div> */}
          </div>
        </div>
      </div>

      {/* Modal */}
      <ConsentFormCustomModal
        modalOpen={isModalOpen}
        setModalOpen={setIsModalOpen}
        // hideCloseButton={true}
        modalBody={
          <>
            <div>
              <PassTheClientPopUp />
              <StepButton label={"Ok, got it"} blue={true} onClick={handleGoToQApage} />
            </div>
          </>
        }
      />

      {/* signature */}
      {isSignatureOpen && (
        <CustomModal
          className={"CustomeSignModel"}
          modalOpen={isSignatureOpen}
          setIsOpenMobileCalendar={handleSignatureModalClose}
          setModalOpen={setIsSignatureOpen}
          type="common-width"
          modalBody={
            <div className="d-flex flex-column wrapper-canvas">
              <SignatureCanvas
                ref={sigPadRef}
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "signCanvas",
                }}
                throttle={null}
                onEnd={() => {
                  setIsSigned(true);
                }}
              />
              <div className="justify-content-between d-flex w-100">
                {isSigned ? (
                  <UndoEnableIcon
                    style={{ cursor: "pointer" }}
                    onClick={clear}
                  />
                ) : (
                  <UndoIcon style={{ cursor: "not-allowed" }} />
                )}
                {isSigned ? (
                  <EnableCheck
                    onClick={() => {
                      handleSignatureModalClose();
                      setIsSignatureOpen(false);
                      handleSignature();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <DisableCheck style={{ cursor: "not-allowed" }} />
                )}
              </div>
            </div>
          }
        />
      )
      }
    </div>
  )
}

export default WalkInConsentPage
