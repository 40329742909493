import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const PAY_LATER_REQUEST_MUTATION = gql`
mutation PayL8rRequest($bookingId: Int!, $redirectUrl: String, $typeData: String, $cfrToken: String) {
  payL8rRequest(booking_id: $bookingId, redirect_url: $redirectUrl, typeData: $typeData, cfrToken: $cfrToken) {
    success
    message
    data {
      encodedData
      item_data
      rid
      consent_form_link
    }
  }
}
`;

export const PayLaterRequest = () => {
  const [payLaterRequest, payLaterResponseData] =
    useMutation(PAY_LATER_REQUEST_MUTATION);
  const dispatch = useDispatch();
  const payLaterMutationData = payLaterResponseData;

  const initiatePayLaterRequest = (
    data,
    successCallback,
    errorCallback
  ) => {
    payLaterRequest({
      variables: data,
    })
      .then(({ data: { payL8rRequest: responseData } }) => {
        if (responseData.success) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiatePayLaterRequest, payLaterMutationData};
};
