import React, { useEffect, useState } from "react";
import { ReactComponent as ConsentFormIcon } from "../../../../../images/sprint6/Consentform.svg";
import { ReactComponent as AfterCareFormIcon } from "../../../../../images/sprint6/Aftercareform.svg";
import { ReactComponent as MedicalFormIcon } from "../../../../../images/sprint6/Medicalform.svg";
import { ReactComponent as RightArrowIcon } from "../../../../../images/sprint6/rightIcon.svg";
import BusinessNavigation from "../../../../common/businessNavigation/BusinessNavigation";

import "./Form.scss";
import BusinessSettingHeader from "../../../../common/businessSettingHeader/BusinessSettingHeader";
import BusinessSidebar from "../../../../sidebar/BusinessSidebar";
import { useNavigate } from "react-router";
import { getBusinessSettingPageRoute } from "../../../../../Routes";
import { webBaseUrl } from "../../../../../config";
import SidebarComponent from "../../../../sidebar";
import Cookies from "js-cookie";
import useWindowDimensions from "../../../../../utils/useWindowDimensions";
import logo from "../../../../../images/icons/faces-logo-black.svg";
import closeIcon from "../../../../../images/Icon example.svg";
import BusinessHeader from "../../../../businessHeader";


const Form = ({ setCurrentPage, currentPage }) => {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const [showSidebar, setShowSidebar] = useState(true);
  const [activeSidebar, setActiveSidebar] = useState(false);
  // const [isAuth, setIsAuth] = useState(false);
  let isMobile = false;
  if (width <= 991) {
      isMobile = true;
  }
  else {
      isMobile = false;
  }
  useEffect(() => {
      isMobile ? setShowSidebar(false) : setShowSidebar(true);
  }, [isMobile]);

  // useEffect(() => {
  //     console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
  //     Cookies.get("faces_access_token") ? setIsAuth(true) : setIsAuth(false);
  //     console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
  // });
  return (
    <div className="MainBusinessProfile">
        <BusinessHeader
        // openReveviewPopup={setOpenReviewPopup}
        openMobileMenu={() => {
            setShowSidebar(!showSidebar);
            setActiveSidebar(true);
            document.body.classList.add('sidebarOpen');
        }}
        isMobile={isMobile}
      />
      <div className="Business-Profile-Main-Wrapper container-fluid">
      <div className="sidebarDesignUpdateSprint6">
          {isMobile ? (
            <div
              className={
                showSidebar ? " MobileSidebar  ActiveSidebar" : "MobileSidebar"
              }
            >
              {activeSidebar && (
                <>
                  <div className="overlaySidebar"></div>
                  <div className="sidebar">
                    <div className="sidebarLogoWrapper">
                      <div className="logoSidebar">
                        <img src={logo} alt="logo" />
                      </div>
                      <div
                        className="sidebarClose"
                        onClick={() => {
                          setShowSidebar(false);
                          document.body.classList.remove('sidebarOpen');
                        }}
                      >
                        <img src={closeIcon} alt="closeIcon" />
                      </div>
                    </div>
                    <SidebarComponent />
                  </div>
                </>
              )}
            </div>
          ) : (
            // isAuth &&
            <div className="sidebarPart">
              <div className="sidebar">
                <SidebarComponent />
              </div>
            </div>
          )}
          <div className={"MainContentPart"}>
            <div className="MainFormPages">
              <div className="Main684WidthSet">
                <BusinessNavigation
                  isColumn={true}
                  title={"Forms"}
                  desc={"Create or modify client forms quickly."}
                  onBackClick={() => { navigate(getBusinessSettingPageRoute) }}
                />
                <div className="linkGrid">
                  <div
                    className="linkItem"

                    onClick={() => { window.location.href = webBaseUrl + "/consent-form/list" }}


                  >
                    <div className="linkImg">
                      <ConsentFormIcon />
                    </div>
                    <div className="linkContent">
                      <h3>Consent forms</h3>
                      <p>View, edit, and make new forms or additions to existing consent information</p>
                    </div>
                    <div className="RightIcon">
                      <RightArrowIcon />
                    </div>
                  </div>

                  <div
                    className="linkItem"
                    onClick={() => { window.location.href = webBaseUrl + "/after-care-template/list" }}
                  >
                    <div className="linkImg">
                      <AfterCareFormIcon />
                    </div>
                    <div className="linkContent">
                      <h3>Aftercare forms</h3>
                      <p>View, edit, and make new forms or additions to existing aftercare information</p>
                    </div>
                    <div className="RightIcon">
                      <RightArrowIcon />
                    </div>
                  </div>

                  <div
                    className="linkItem"
                    onClick={() => { window.location.href = webBaseUrl + "/medical-questions/edit" }}
                  >
                    <div className="linkImg">
                      <MedicalFormIcon />
                    </div>
                    <div className="linkContent">
                      <h3>Medical forms</h3>
                      <p>View and edit medical form as needed.</p>
                    </div>
                    <div className="RightIcon">
                      <RightArrowIcon />
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
