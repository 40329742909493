import { gql, useMutation } from "@apollo/client";

const CheckTokenAvailable = gql`
mutation WebLoginTokens($token: String) {
    webLoginTokens(token: $token) {
      success
      message
    }
  }
`;


export const CheckTokenAvailableAction = () => {

    const [
        CheckTokenAvailableResponseFunction,
        CheckTokenAvailableResponseData,
    ] = useMutation(CheckTokenAvailable)

    const CheckTokenAvailableQueryData = CheckTokenAvailableResponseData;

    const initCheckTokenAvailableData = (data, successCallback, errorCallback) => {
        CheckTokenAvailableResponseFunction({
            variables: data,
        }).then(({ data: { webLoginTokens: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback(responseData)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
        })
    }

    return { CheckTokenAvailableQueryData, initCheckTokenAvailableData };
};