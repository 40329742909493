export const GET_TREATMENTS_FOR_USER = "GET_TREATMENTS_FOR_USER";
export const GET_TREATMENT_BY_CATEGORY = "GET_TREATMENT_BY_CATEGORY";
export const GET_TREATMENT_CATEGORIES_LIST = "GET_TREATMENT_CATEGORIES_LIST";
export const GET_TREATMENT_DETAILS = "GET_TREATMENT_DETAILS";
export const GET_TREATMENT_LISTING_CONST = "GET_TREATMENT_LISTING_CONST";

export const GET_TREATMENT_LIST_DATA_ERROR = "GET_TREATMENT_LIST_DATA_ERROR";
export const GET_TREATMENTS_FOR_CLINIC = "GET_TREATMENTS_FOR_CLINIC"


export const CLEAR_TREATMENT_DETAILS = "CLEAR_TREATMENT_DETAILS";
export const CLEAR_TREATMENT_FOR_CLINIC = "CLEAR_TREATMENT_FOR_CLINIC"


export const SET_LAST_STEP = "SET_LAST_STEP";
