import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import { config, frontendURL, webBaseUrl } from '../../../../src/config';
import { GetDirectDepositRyftPaymentToken, GetVerifyLinkForDirectDiposit } from '../../redux/actions/direct-deposit/directDepositRyftPayment';
import { CompleteDirectDepositPaymentBooking } from '../../redux/actions/direct-deposit/DirectDepositFormBooking';
import { ReactComponent as FacesLogo } from "../../../images/Faces_logo.svg";
import mastercard from "../../../assets/card/Mastercard.png"
import visa from "../../../assets/card/Visa.png"
import "./DirectDeposit.scss"
import { toast } from "react-toastify";
import SkeletonLoader from '../../common/Skeleton/SkeletonLoader';
import { Elements } from '@stripe/react-stripe-js';
import stripePromise from '../../../stripe';
import DirDepStripeComponent from './DirDepStripeComponent';

export default function DirectDepositeLink () {


    let id = parseInt(useParams()?.id);
    const location = useLocation();
    const navigate = useNavigate()

    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    const [loader, setLoader] = useState(false);
    const [ryftErrorMessage, setRyftErrorMessage] = React.useState('');
    const { getDirDepositRyftPaymentQueryData, initDirDipositRyftPaymentToken } = GetDirectDepositRyftPaymentToken();
    const { getVerifyLinkForDirDepositQueryData, initVerifyTokenForDirDeposit } = GetVerifyLinkForDirectDiposit();

    const {
        initiateCompleteDirectDepositPaymentBooking,
        completeDirectDepositPaymentBookingMutationData,
    } = CompleteDirectDepositPaymentBooking();

    const [isDepositLinkValid, setIsDepositLinkValid] = useState(false);
    const [depositStatus, setDepositStatus] = useState('');
    const [verifyLinkData, setVerifyLinkData] = useState({});

    const [stripeOption, setStripeOption] = useState({});
    const [paymentClientSecret, setPaymentClientSecret] = useState('')

    useEffect(() => {
        if (!token || !id) {
            return navigate("/business-dashboard")
        }

        initVerifyTokenForDirDeposit({
            depositId: id,
            token: token,
        }, (res) => {
            if (res?.data?.getVerifyLinkForDirectDiposit?.success) {

                const directDepositStatus = res?.data?.getVerifyLinkForDirectDiposit?.data?.dir_dep_deposit_status;
                console.log('res?.data?.getVerifyLinkForDirectDiposit?.data :', res?.data?.getVerifyLinkForDirectDiposit?.data);
                setDepositStatus(directDepositStatus)
                if (directDepositStatus !== 'pending') {
                    setIsDepositLinkValid(false)
                } else {
                    setIsDepositLinkValid(true)
                }
                setVerifyLinkData({
                    ...res?.data?.getVerifyLinkForDirectDiposit?.data
                })
            } else {
                console.log("error in verify Token")
            }
        }, (e) => {
            toast.error(e.message);
            console.log('e.message :', e.message);
            // setLoader(false)
        });
    }, [id, token]);


    useEffect(() => {
        if (isDepositLinkValid && verifyLinkData) {

            if (Object.keys(verifyLinkData).length > 0 && verifyLinkData?.dir_dep_total_amount > 0) {

                if (verifyLinkData?.is_ryft_setup) {
                    initDirDipositRyftPaymentToken({
                        depositId: id,
                        token: token,
                        redirectURL: `${frontendURL}/direct-deposit-thank-you`,
                        paymentType: 1,
                    }, (res) => {
                        if (res?.data?.getDepositRyftPaymentToken?.success) {

                            const clientSecret = res?.data?.getDepositRyftPaymentToken?.data?.payment_session;
                            const accountId = res?.data?.getDepositRyftPaymentToken?.data?.accountId;
                            if (clientSecret) {

                                let Ryft = window.Ryft;
                                let initializationValues = {
                                    publicKey: config.RYFT_PUBLIC_KEY,
                                    clientSecret: clientSecret,
                                    accountId: accountId,
                                    applePay: {
                                        merchantName: 'Faces',
                                        merchantCountryCode: 'GB',
                                    },
                                };

                                Ryft.init(initializationValues);

                                Ryft.addEventHandler('walletPaymentSessionResult', (e) => {
                                    const paymentSession = e.paymentSession;
                                    setRyftErrorMessage('');
                                    if (paymentSession.status === 'Approved' || paymentSession === 'Captured') {
                                        // payment successful, show the customer your order success page
                                        return;
                                    }
                                    if (paymentSession.lastError) {
                                        const userFacingError = Ryft.getUserFacingErrorMessage(
                                            paymentSession.lastError
                                        );
                                        // Show userFacingError to customer
                                    }
                                });

                                Ryft.addEventHandler('cardValidationChanged', (e) => {
                                    const payButton = document.getElementById('ryft-pay-btn');
                                    setRyftErrorMessage('');
                                    payButton.disabled = !e.isValid;
                                    console.log('payButton.disabled :', payButton.disabled);
                                });

                                Ryft.addEventHandler('paymentMethodSelectionChanged', (e) => {
                                    const payButton = document.getElementById('ryft-pay-btn');
                                    setRyftErrorMessage('');
                                    payButton.disabled = !e.paymentMethod;
                                    console.log('payButton.disabled :', payButton.disabled);
                                });
                            }
                            // setLoader(false)
                        } else {
                            console.log("error in initDirDipositRyftPaymentToken")
                            // setLoader(false)
                        }
                    }, (e) => {
                        toast.error(e.message);
                        console.log('e.message :', e.message);
                        // setLoader(false)
                    })
                } else if (verifyLinkData?.is_stripe_setup) {
                    initDirDipositRyftPaymentToken({
                        depositId: id,
                        token: token,
                        redirectURL: `${frontendURL}/direct-deposit-thank-you`,
                        paymentType: 2,
                    }, (res) => {
                        if (res?.data?.getDepositRyftPaymentToken?.success) {

                            const clientSecret = res?.data?.getDepositRyftPaymentToken?.data?.payment_session;
                            if (clientSecret) {
                                setStripeOption({
                                    clientSecret: clientSecret,
                                    appearance: {
                                        /*...*/
                                    },
                                })
                                setPaymentClientSecret(clientSecret);
                            }
                            // setLoader(false)
                        } else {
                            console.log("error in initDirDipositRyftPaymentToken")
                            // setLoader(false)
                        }
                    }, (e) => {
                        toast.error(e.message);
                        console.log('e.message :', e.message);
                        // setLoader(false)
                    })
                }
            } else {
                console.log("payment data is not valid")
                return
            }


        }
    }, [isDepositLinkValid, verifyLinkData])

    const handlePaymentSubmit = async (e) => {

        let Ryft = window.Ryft;
        const payButton = document.getElementById('ryft-pay-btn');
        payButton.disabled = true;
        setLoader(true);
        setRyftErrorMessage('');
        let errorMessage =
            "<div style='color:red;'>There seems to be an issue with the payment, if using through the app, please try through the faces website within your account. <br><br>If still having issues, please contact <a href='mailto:ben@facesconsent.com'>Ben@facesconsent.com</a> or call <a href='tel:01785558018'>01785 5580 18</a>.</div>";


        Ryft.attemptPayment()
            .then((paymentSession) => {

                console.log('paymentSession :', paymentSession);
                if (paymentSession.status === 'Approved' || paymentSession.status === 'Captured') {
                    var redirectUrl = paymentSession.returnUrl,
                        paymentSessionId = paymentSession.id;
                    redirectUrl += '&ps=' + paymentSessionId;
                    if (paymentSession?.id) {
                        initiateCompleteDirectDepositPaymentBooking(
                            {
                                depositId: id,
                                paymentId: paymentSession?.id,
                                paymentType: 1,
                                token: token,
                            },
                            (res) => {
                                console.log("RYFT payment done....", res)
                                if (res?.success) {
                                    navigate('/direct-deposit-thank-you')
                                    return
                                }
                            },
                            (e) => {
                                setLoader(false);
                                setRyftErrorMessage(e.message);
                            }
                        );
                    } else {
                        window.location.href = redirectUrl;
                    }
                    // Payment successful - show the customer your order success page
                    return;
                }
                if (paymentSession.lastError) {
                    payButton.disabled = false;
                    setLoader(false);
                    const userFacingError = Ryft.getUserFacingErrorMessage(
                        paymentSession.lastError
                    );
                    console.log('userFacingError :', userFacingError);
                    // Show userFacingError to customer
                    setRyftErrorMessage(errorMessage);
                }
            })
            .catch((error) => {
                payButton.disabled = false;
                setLoader(false);
                if (error) {
                    setRyftErrorMessage(errorMessage);
                } else {
                    setRyftErrorMessage(errorMessage);
                }
                // Show error to customer
            });
        e.preventDefault();
    };

    return (
        <div className="">
            <div className="customeloginHeader">
                <div className="container">
                    <div className="headerlogoBtnWrapper">
                        <div
                            className="logoWrapper"
                            onClick={() => { window.location.href = webBaseUrl }}
                        >
                            <FacesLogo />
                        </div>
                        <div className="loginButton d-flex align-items-center">
                        </div>
                    </div>
                </div>
            </div>
            <div className="container payment-container justify-content-center align-items-center vh-100">
                <div className="ryft-payment-form-container">
                    <h1 className="enter-card-title">Deposit Payment</h1>
                    {/* <p className="thankyouPageDesc"> */}
                    {
                        getVerifyLinkForDirDepositQueryData?.loading ?
                            <SkeletonLoader type="appointment" />
                            : depositStatus !== 'pending' ?
                                <p className="thankyouPageDesc">
                                    Sorry,Deposit Link is either invalid or has expired
                                </p> :
                                <>
                                    <div className="card-element-info-container">
                                        <div className="card-element-info">
                                            <span className="card-number-info">Card Number</span>
                                            <span className="cvv-info">CVV</span>
                                            <span className="exp-date-info">Exp. Date</span>

                                        </div>
                                    </div>

                                    {
                                        verifyLinkData?.is_ryft_setup ?
                                            <form
                                                id="ryft-pay-form"
                                                className="ryft-pay-form"
                                                onSubmit={handlePaymentSubmit}
                                            >
                                                {/* <button id="ryft-pay-btn" className='btnredsm'>PAY NOW</button> */}
                                                <div
                                                    id="ryft-pay-error"
                                                    dangerouslySetInnerHTML={{ __html: ryftErrorMessage }}
                                                ></div>
                                                <div className="form-group text-center">
                                                    <div className="col-12">
                                                        <img src={mastercard} alt='' width="40px" height="35px" />
                                                        <img src={visa} alt='' width="40px" height="35px" />
                                                    </div>
                                                    {/* <div className="payment-loading-text m-2 hidden-element">
                                    Please wait while we work on completing your payment.
                                </div> */}
                                                    <input className="btn btnredsm" id="ryft-pay-btn" type="submit" value="Make Payment" disabled="true"></input>
                                                </div>
                                            </form>
                                            :
                                            stripeOption &&

                                            <Elements stripe={stripePromise} options={stripeOption}>
                                                <DirDepStripeComponent
                                                    clientSecret={paymentClientSecret}
                                                    depositId={id}
                                                    token={token}
                                                />
                                            </Elements>
                                    }
                                </>
                    }
                    {/* </p> */}
                </div>
            </div>
        </div>
    )
}