import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_BLOCK_OUT_TIME_DATA } from "../../constants/blockOutTime/BlockOutTimeConst";

const LIST_BLOCK_OUT_TIME_QUERY = gql`
query ListBlockOutTimes($page: Int, $limit: Int, $endDate: String, $startDate: String) {
  listBlockOutTimes(page: $page, limit: $limit, endDate: $endDate, startDate: $startDate) {
    success
    message
    data {
      elements {
        blockOutId
        blockOutDateId
        startDate
        endDate
        readableStartDate
        readableEndDate
        startTime
        endTime
        isAllDay
        title
        enableBooking
        clinicName
      }
      total
    }
    
  }
}
`;

var page;
export const ListBlockOutTimesQuery = () => {
  const dispatch = useDispatch();
  const [getListBlockOutTimeData, listBlockOutTimeData] = useLazyQuery(
    LIST_BLOCK_OUT_TIME_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ listBlockOutTimes: responseData }) => {
        dispatch({
          type: GET_BLOCK_OUT_TIME_DATA,
          payload: responseData.data,
        });
        if (responseData.success) {
        } else {
        }
      },
    }
  );
  const listBlockOutTimeQueryData = listBlockOutTimeData;
  const initListBlockTimeOut = (data, successCallback) => {
    getListBlockOutTimeData({
      variables: data,
    }).then(({ data: { getBusinessBookingLink: responseData } }) => {
        if (successCallback)
          successCallback(responseData?.data);
      
    });
  };
  return { initListBlockTimeOut, listBlockOutTimeQueryData };
};

