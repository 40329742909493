import React from 'react'
import { createGlobalStyle } from 'styled-components';



const facesColors = createGlobalStyle`
  html {
    --white: #fff;
    --black-color: #000;
    --primary-color: #1658F7;
    --secondary-color: #3333ff;
    --button-color:  #5b64e3;
  }
`;
const insyncColors = createGlobalStyle`
  html {
    --white: #fff;
    --black-color:#000;
    --primary-color: #ff7300;
    --secondary-color: #9a4d0d;
    --button-color:  #ff7300;
    
}
`;

// export const domainName = window.location.host == "staging2.facesconsent.com"  ? 'faces': 'insync';

export const domainName = window.location.host == "insyncconsent.co.uk"  ? 'insync': 'faces';
// export const domainName = 'faces';
// export const domainName = window.location.host == "localhost:3000"  ? 'faces': 'insync';
const GlobalData = domainName == "faces" ? facesColors : insyncColors;


const GlobalStyle = () => {
  return (
    <>
        <GlobalData/>
    </>
  )
}

export default GlobalStyle