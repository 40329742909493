import React, { useEffect, useState } from "react";
import { ReactComponent as OnlinePaymentsIcon } from "../../../../images/sprint6/OnlinePayments.svg";
import { ReactComponent as StipeAccountIcon } from "../../../../images/sprint6/StipeAccount.svg";
import { ReactComponent as RyftAccountIcon } from "../../../../images/sprint6/RyftAccount.svg";
import { ReactComponent as CardMachineIcon } from "../../../../images/sprint6/CardMachine.svg";
// images/sprint6/FinanceSettings.svg
import { ReactComponent as FinanceSettingsIcon } from "../../../../images/sprint6/FinanceSettings.svg";

// /home/techerudite-509/projects/faces-react/src/images/business-setting-icon/payment-control.svg
import { ReactComponent as Paymentcontrol } from "../../../../images/business-setting-icon/payment-control.svg";
import { ReactComponent as RightArrowIcon } from "../../../../images/sprint6/rightIcon.svg";
import { ReactComponent as TranscitionHistory } from "../../../../images/business-setting-icon/transactionHistory.svg";
import { comingsoonpage, FinanceRegistrationRoute, getFinanceRegistrationRoute, getManageAccountPage } from "../../../../Routes";
import "./PaymentsControl.scss";
import BusinessNavigation from "../../../common/businessNavigation/BusinessNavigation";
import { webBaseUrl } from "../../../../config";
import { Navigate, useNavigate } from "react-router";
import BusinessSettingHeader from "../../../common/businessSettingHeader/BusinessSettingHeader";
import BusinessSidebar from "../../../sidebar/BusinessSidebar";
import { getBusinessSettingPageRoute } from "../../../../Routes";
import SidebarComponent from "../../../sidebar";
import BusinessHeader from "../../../businessHeader";
import Cookies from "js-cookie";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import logo from "../../../../images/icons/faces-logo-black.svg";
import closeIcon from "../../../../images/Icon example.svg";
import axios from "axios";
import Skeleton from "react-loading-skeleton";

const PaymentsControl = ({ setCurrentPage, currentPage }) => {
    const navigate = useNavigate();
    const { width, height } = useWindowDimensions();
    const [showSidebar, setShowSidebar] = useState(true);
    const [activeSidebar, setActiveSidebar] = useState(false);
    // const [isAuth, setIsAuth] = useState(false);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    let isMobile = false;
    if (width <= 991) {
        isMobile = true;
    }
    else {
        isMobile = false;
    }
    useEffect(() => {
        isMobile ? setShowSidebar(false) : setShowSidebar(true);
    }, [isMobile]);

    // useEffect(() => {
    //     console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
    //     Cookies.get("faces_access_token") ? setIsAuth(true) : setIsAuth(false);
    //     console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
    // });

    useEffect(() => {
        // Define the async function to fetch data
        const fetchData = async () => {
            try {
                const response = await axios.request({
                    method: "get",
                    url: webBaseUrl + '/api/get-profile',
                    headers: {
                        "encrypted-authrorisation": Cookies.get('faces_access_token'),
                    }
                });
                // const response = await axios.get(webBaseUrl+'/api/get-profile'); // Replace with your API endpoint
                setData(response?.data?.data?.user?.wallet_balance);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []); // Empty dependency array to call only on mount

    console.log(data, "userprofile_data")
    console.log(loading, "userprofile_data")

    const paymentControlredirection = ({ title }) => {
        //  console.log(title
        //     ,"102102")
        // if (title === "Collect online payments") {
        // //   window.location.href = webBaseUrl +"/yotta/take-payment"
        // }

        console.log(title, "title === ")
        if (title === "Finance settings") {
            // navigate(getManageAccountPage(), { state: { from: 'Finance_settings_paymentcontrol' } });
            //  navigation(FinanceRegistrationRoute())
            navigate(getFinanceRegistrationRoute, { state: { from: 'Finance_settings_paymentcontrol' } })


        }
        else if (title === "Card machine application") {
            window.location.href = webBaseUrl + "/card-reader/company"
        }
        else if (title === "Stripe account") {
            window.location.href = webBaseUrl + "/user/update-profile?active-tab=payment_gateways"
        }
        else if (title === "Ryft account") {
            window.location.href = webBaseUrl + "/user/update-profile?active-tab=payment_gateways"

        }



        else {
            navigate(comingsoonpage(), { state: { from: 'Finance_settings_paymentcontrol_comingsoon' } })
        }

    }

    const tileData = [
        {
            title: "Collect online payments",
            subTitle: "You choose who covers the platform fees?",
            icon: <OnlinePaymentsIcon />,
        },
        {
            title: "Stripe account",
            subTitle: "Link your stripe account.",
            icon: <StipeAccountIcon />,
        },

        {
            title: "Ryft account",
            subTitle: "Link your ryft account.",
            icon: <RyftAccountIcon />,
        },
        {
            title: "Card machine application",
            subTitle: "Partner with top card terminals.",
            icon: <CardMachineIcon />,
        },
        {
            title: "Finance settings",
            subTitle: "Control your finances and payments.",
            icon: <FinanceSettingsIcon />,
        },

        // {
        //     title: "Finance settings",
        //     subTitle: "Control your finances and payments.",
        //     icon: <Paymentcontrol />,
        // },
        {
            title: "Transaction history",
            subTitle: "Track all your past transactions.",
            icon: <TranscitionHistory />,
        }
    ];

    return (
        <div className="MainBusinessProfile">
            <BusinessHeader
                // openReveviewPopup={setOpenReviewPopup}
                openMobileMenu={() => {
                    setShowSidebar(!showSidebar);
                    setActiveSidebar(true);
                    document.body.classList.add('sidebarOpen');
                }}
                isMobile={isMobile}
            />
            <div className="Business-Profile-Main-Wrapper container-fluid">
                <div className="sidebarDesignUpdateSprint6">
                    {isMobile ? (
                        <div
                            className={
                                showSidebar ? " MobileSidebar  ActiveSidebar" : "MobileSidebar"
                            }
                        >
                            {activeSidebar && (
                                <>
                                    <div className="overlaySidebar"></div>
                                    <div className="sidebar">
                                        <div className="sidebarLogoWrapper">
                                            <div className="logoSidebar">
                                                <img src={logo} alt="logo" />
                                            </div>
                                            <div
                                                className="sidebarClose"
                                                onClick={() => {
                                                    setShowSidebar(false);
                                                    document.body.classList.remove('sidebarOpen');
                                                }}
                                            >
                                                <img src={closeIcon} alt="closeIcon" />
                                            </div>
                                        </div>
                                        <SidebarComponent />
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        // isAuth &&
                        <div className="sidebarPart">
                            <div className="sidebar">
                                <SidebarComponent />
                            </div>
                        </div>
                    )}
                    <div className={"MainContentPart"}>
                        <div className="MainFormPages">
                            <div className="Main684WidthSet">
                                <BusinessNavigation
                                    isColumn={true}
                                    title={"Payments control"}
                                    desc={"Receive payments, use card machines."}
                                    onBackClick={() => { navigate(getBusinessSettingPageRoute) }}
                                />
                                {loading ? <Skeleton className="credit_wallet_wrapper" /> : <div className="credit_wallet_wrapper">   <p >Wallet credit: £{data}</p> </div>}

                                <div className="linkGrid">
                                    {tileData?.map((item, index) => {
                                        return (
                                            <div className="linkItem" onClick={() => { paymentControlredirection(item) }}>
                                                <div className="linkImg">
                                                    {item?.icon}
                                                </div>
                                                <div className="linkContent">
                                                    <h3>{item?.title}</h3>
                                                    <p>{item?.subTitle}</p>
                                                </div>
                                                <div className="RightIcon">
                                                    <RightArrowIcon />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentsControl;