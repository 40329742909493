export const GET_DATE_SLOTS = 'GET_DATE_SLOTS'
export const GET_TIME_SLOTS = 'GET_TIME_SLOTS'



export const CLEAR_DATE_SLOTS = 'CLEAR_DATE_SLOTS'
export const CLEAR_TIME_SLOTS = 'CLEAR_TIME_SLOTS'

export const SET_AVAILABLE_AND_OUTSIDE_AVAIBILITY_TIME_SLOTS_CREATE_APPOINTMENT = 'SET_AVAILABLE_AND_OUTSIDE_AVAIBILITY_TIME_SLOTS_CREATE_APPOINTMENT'
export const SET_TIME_SLOTS_FOR_CREATE_APPOINTMENT = 'SET_TIME_SLOTS_FOR_CREATE_APPOINTMENT'
export const CLEAR_TIME_SLOTS_FOR_CREATE_APPOINTMENT = 'CLEAR_TIME_SLOTS_FOR_CREATE_APPOINTMENT'


