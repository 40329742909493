import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const CREATE_PRESCRIPTION_MUTATION = gql`
mutation CreatePrescription($productIds: [productIdInputType], $prescriberId: Int, $clientId: Int, $isMedicalFormEnabled: Boolean, $clientName: String, $clientEmail: String, $clientPhone: String, $clientDob: String, $clientAddress: String, $clientHouseNo: String, $clientStreetName: String, $clientCity: String, $clientPhoneCountry: String, $clientCountryCode: String, $clientPostcode: String, $clientLatitude: Float, $clientLongitude: Float, $notes: String, $consentFormId: Int, $medicalForm: Int) {
  createPrescription(product_ids: $productIds, prescriber_id: $prescriberId, client_id: $clientId, is_medical_form_enabled: $isMedicalFormEnabled, client_name: $clientName, client_email: $clientEmail, client_phone: $clientPhone, client_dob: $clientDob, client_address: $clientAddress, client_house_no: $clientHouseNo, client_street_name: $clientStreetName, client_city: $clientCity, client_phone_country: $clientPhoneCountry, client_country_code: $clientCountryCode, client_postcode: $clientPostcode, client_latitude: $clientLatitude, client_longitude: $clientLongitude, notes: $notes, consent_form_id: $consentFormId, medical_form: $medicalForm) {
    success
    message
    data {
      identifier
      redirection_url
    }
  }
}
`;

export const CreatePrescriptionMutation = () => {
  const [createPrescriptionRequest, createPrescriptionResponseData] =
    useMutation(CREATE_PRESCRIPTION_MUTATION);
  const dispatch = useDispatch();
  const createPrescriptionMutationData = createPrescriptionResponseData;

  const initiateCreatePrescription = (
    data,
    successCallback,
    errorCallback
  ) => {
    createPrescriptionRequest({
      variables: data,
    })
      .then(({ data: { createPrescription: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateCreatePrescription, createPrescriptionMutationData };
};
