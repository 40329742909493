import React, { useState, useEffect } from "react";
import bgImage from "../../../images/webstepbg.svg";
import backIcon from "../../../images/back_arrow.svg";
import closeIcon from "../../../images/close_icon.svg";
import "./createWebsite.scss";
import ProgressBar from "@ramonak/react-progress-bar";
import CreateWebsite from "./createWebsite";
import WebsiteURLStep1 from "../createWbsiteURL/CreateWebsiteStep1";
import UploadLogoCover from "./uploadLogoCover";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import imgUploadLogo from "../../../assets/createWebsite/TitleIcon.png";
import ImageTitleComponent from "../createWbsiteURL/commonComponents/ImgTitleComp";
import webURLimg from "../../../assets/createWebsite/text.png";
import teamMemberLogo from "../../../assets/createWebsite/teamMemberLogo.png";
import AddTeamMemberStep from "./addTeamMemberStep";
import PersonalDetailsStep from "./personalDetailsStep";
import SocialMediaPage from "../createWbsiteURL/SocialMediaPage";
import WebsiteBussTagline from "../createWbsiteURL/WebsiteBussTagline";
import { useLocation, useNavigate } from "react-router";
import { businessDashboardRoutePattern } from "../../../Routes";
import webPrev from "../../../assets/createWebsite/Art.png";

import WebsitePrev from "./websitePrev";
import TermsAndCondition from "../createWbsiteURL/Terms&Condition";
import CongratulationPage from "../createWbsiteURL/CongratulationPage";
import StepButton from "../../common/StepButton";
import { FiChevronRight } from "react-icons/fi";
import SkipButtonComp from "../createWbsiteURL/commonComponents/SkipBtn";
import CustomModal from "../../modal/CustomModal";
import EditWebsite from "./editWebsite";
import { SaveWebsiteDetails } from "../../redux/actions/createWebsite/saveWebsiteDetails";
import { useSelector, useDispatch } from "react-redux";
import { GetWebsiteDetailsAction } from "../../redux/actions/createWebsite/getWebsiteDetails";
import { TrackUserActivity } from "../../redux/actions/createWebsite/trackUserActivity";
import { GetWebsiteImageAction } from "../../redux/actions/createWebsite/getPersonalWebsiteImage";
import { CLEAR_LOGO_IMAGE } from "../../redux/constants";
import { GetWebsiteTeamMembersAction } from "../../redux/actions/createWebsite/getPersonalWebTeamMembersQuery";
import { PublishPersonalWebsite } from "../../redux/actions/createWebsite/publishWebiste";
import { set } from "lodash";
import ReEntryDashboard from "./reEntryDashboard";
import CustomeLoading from "../../customeSpiner";

const StepsLayout = (props) => {
  const { step, setStep ,setIsEdit} = props;
  const [currentStepAvailable, setCurrentStateAvailable] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  const [editStep, setEditStep] = useState(0);
  const dimensions = useWindowDimensions();
  const navigate = useNavigate();
  const [headerCreateWebsite, setHeaderCreateWebsite] = useState(0);
  const [headerWrapperHeight, setHeaderWrapperHeight] = useState(0);
  const [formButtonWrapperHeight, setFormButtonWrapperHeight] = useState(0);
  const [responsiveButtonHeight, setResponsiveButtonHeight] = useState(0);
  const [buildSiteButtonHeight, setBuildSiteButtonHeight] = useState(0);
  const [selectedEditTypeStep, setSelectedEditTypeStep] = useState(0);

  const [responsivePrev, setResponsivePrev] = useState(false);
  const [publishInformationModel, setPublishInformationModel] = useState(false);
  const [proceedModel, setProceedModel] = useState(false);
  const [colorType, setColorType] = useState("Primary");
  const formInnerHeight = formButtonWrapperHeight;
  const [count, setCount] = useState({
    step1: 0,
    step2: 0,
    step3: 0,
    step4: 0,
  });
  const [tempUploadSaveData, setTempUploadSaveData] = useState({
    logo: "",
    coverPhoto: "",
  });
  const [tempSaveTaglineData, setTempSaveTaglineData] = useState({
    headline: "",
    description: "",
  });

  const [tempSaveColorData, setTempSaveColorData] = useState({
    primaryColor: "",
    secondaryColor: "",
    tertiaryColor: "",
  });

  const [tempSaveBusinessData, setTempSaveBusinessData] = useState({
    businessName: "",
    websiteUrl: "",
  });

  const [peddingPopUp,setPeddingPopUp]=useState(false)
  const [canPublish,setCanPublish] = useState(false);
  // console.log( formInnerHeight , 'formButtonWrapperHeight')
  const {
    initiateSaveWebsiteDetails,
    saveWebsiteDetailsMutationData: { loading: saveWebsiteDetailsLoading },
  } = SaveWebsiteDetails();

  const {
    initiatePublishPersonalWebsite,
    publishPersonalWebsiteMutationData: { loading: publishLoader },
  } = PublishPersonalWebsite();

  const {
    initGetTeamMembersResponseFunction,
    GetWebsiteTeamMembersResponseData,
  } = GetWebsiteTeamMembersAction();

  const {
    initGetWebsiteDataResponseFunction,
    GetWebsiteDetailsResponseData: {
      loading: getDetailsLoading,
      called: getDetailsCalled,
    },
  } = GetWebsiteDetailsAction();

  const { initiatetrackUserActivity, trackUserActivityMutationData } =
    TrackUserActivity();

  const {
    initGetImageResponseFunction,
    GetWebsiteImageResponseData: { loading: getImageLoading },
  } = GetWebsiteImageAction();

  const saveWebsiteDataStatus = useSelector(
    (state) => state.WebsiteDetailsReducer.saveWebsiteDataStatus
  );
  const websitePrefilledData = useSelector(
    (state) => state.WebsiteDetailsReducer.websitePrefilledData
  );

  console.log(websitePrefilledData, "websitePrefilledData11");

  useEffect(() => {
    setHeaderCreateWebsite(
      document.getElementsByClassName("step-header")[0]?.offsetHeight
    );

    setHeaderWrapperHeight(
      document.getElementsByClassName("headerWrapper")[0]?.offsetHeight
    );

    setFormButtonWrapperHeight(
      document.getElementsByClassName("FormButtonWrapper")[0]?.offsetHeight
    );

    setResponsiveButtonHeight(
      document.getElementsByClassName("responsiveButton")[0]?.offsetHeight
    );

    setBuildSiteButtonHeight(
      document.getElementsByClassName("BuildSiteWrapper")[0]?.offsetHeight
    );

    window.addEventListener("resize", handleResize);
  }, [
    headerCreateWebsite,
    responsiveButtonHeight,
    headerWrapperHeight,
    formButtonWrapperHeight,
    buildSiteButtonHeight,
  ]);

  const handleResize = () => {
    setHeaderCreateWebsite(
      document.getElementsByClassName("step-header")[0]?.offsetHeight
    );

    setHeaderWrapperHeight(
      document.getElementsByClassName("headerWrapper")[0]?.offsetHeight
    );

    setFormButtonWrapperHeight(
      document.getElementsByClassName("FormButtonWrapper")[0]?.offsetHeight
    );

    setResponsiveButtonHeight(
      document.getElementsByClassName("responsiveButton")[0]?.offsetHeight
    );

    setBuildSiteButtonHeight(
      document.getElementsByClassName("BuildSiteWrapper")[0]?.offsetHeight
    );
  };

  var isMobile;

  if (dimensions?.width <= 1200) {
    isMobile = true;
  } else {
    isMobile = false;
  }

  useEffect(() => {}, [isMobile]);

  const dispatch = useDispatch();

  // const [colour, setColor] = useState({ hex: "#ffffff", alpha: 1 });
  // console.log(colour?.hex, "colour");

  // const handleColorChange = (newColor) => {
  //   console.log(newColor,  'newColor')
  //   setColor(newColor);
  // };

  // const handleAlphaChange = (event) => {
  //   const newAlpha = parseFloat(event.target.value);
  //   console.log(newAlpha, "newAlpha");
  //   setAlpha(newAlpha);
  // };

  // const sliderTrackStyle = {
  //   background: `linear-gradient(90deg, transparent 0%, ${colour?.hex} )`,
  // };

  // const defaultTeamMember = {
  //   pwtm_full_name: "Lara Steiner  R.N",
  //   pwtm_qualification: "Nurse",
  //   pwtm_job_role: "",
  //   pwtm_description: "Leading aesthetic nurse ready to help you in looking your best!",
  //   image: "",
  // };

  const [websiteData, updateWebsiteData] = useState({
    welcomeText : "",
    businessName: "",
    websiteUrl: "",
    logo: "",
    coverPhoto: "",
    headline: "",
    description: "",
    teamMembers: [],
    primaryColor: "#001125",
    secondaryColor: "#ffffff",
    tertiaryColor: "#000000",
    socialLinks: {},
    businessHours: [],
    personalDetails: {},
    termsAndConditions: "",
    showHoursOnPage: false,
    can_publish: false,
    stripe_onboarding: false,
    has_added_all_information: false,
    is_active: false,
    current_step: 2,
    stripe_onboarding_url: "",
  });

  const setWebsiteData = (websiteDataUpdated) => {
    console.log(
      "prefill website data setWebsiteData called ",
      websiteDataUpdated
    );
    updateWebsiteData(websiteDataUpdated);
  };

  const stepOneData = {
    imageUrl: webURLimg,
    title: "My website",
    description:
      "Build, create, customise your very own online presence with our website builder",
  };

  const stepTwoData = {
    imageUrl: imgUploadLogo,
    title: "Upload your brand logo",
    description: "We suggest using PNG or transparent background for your logo",
  };
  const stepThreeData = {
    imageUrl: imgUploadLogo,
    title: "Upload your website cover photo",
    description:
      "This image will be the first thing clients see on your homepage. If you change your mind, you can always update it later. For the best fit, use an image that's 1920 x 1080 pixels.",
  };
  const stepFourData = {
    imageUrl: webURLimg,
    title: "Add your business headline",
    description:
      "A headline is a short and catchy phrase that sums up your brand. It's key for brand presence.",
  };
  const stepFiveData = {
    imageUrl: teamMemberLogo,
    title: "Add your team to your website",
    description:
      "We recommend uploading high-quality photos of you and your team. Start with a great picture of yourself and then add others. Remember, you can always add more later.",
  };
  const stepSixPrimaryData = {
    imageUrl: webPrev,
    title: "Select your website colours",
    description:
      "Pick a colours to set the tone for your website. You'll be able to see how they look and make changes in the preview section",
  };
  const stepSixSecondaryData = {
    imageUrl: webPrev,
    title: "Select your website colours",
    description:
      "Pick a colours to set the tone for your website. You'll be able to see how they look and make changes in the preview section",
  };
  const stepSixTertiaryData = {
    imageUrl: webPrev,
    title: "Select your website second text colour",
    description:
      "Pick a colour to set the tone for your website. You'll be able to see how they look and make changes in the preview section.",
  };
  const stepSevenData = {
    imageUrl: webURLimg,
    title: "Socials and contact number",
    description: "Boost your brand by linking your socials and business number",
  };
  const stepEightData = {
    imageUrl: webURLimg,
    title: !websiteData.showHoursOnPage
      ? "Personal details"
      : "Update your Business Hours",
    description:
      "Please update any business details if they've changed. These contact details will be displayed on your website",
  };
  const stepNineData = {
    imageUrl: webURLimg,
    title: "Update your terms & conditions",
    description:
      "Enter your terms and conditions below. You can utilise our standard template or draft your own. If you already have a document prepared, you can easily upload it from your saved documents.",
  };

  const stepTenData = {
    // imageUrl: FacesLogoIcon,
    // imageUrl: webURLimg,
    // title: "Update your Terms & Conditions",
    // description:
    //   "Enter your terms and conditions below. You can utilise our standard template or draft your own. If you already have a document prepared, you can easily upload it from your saved documents.",
  };

  useEffect(() => {
    console.log("prefill website data component mounted");
  }, []);

  useEffect(() => {
    if (getDetailsCalled && step != 6 && !websiteData?.is_active) {
      console.log("FETCH TEAM MEMBERS");
      initGetTeamMembersResponseFunction();
    }
    // console.log("initGetTeamMembersResponseFunction from stepsLayout")
  }, [getDetailsCalled]);

  useEffect(() => {
    console.log("step...", step);
    if (step != 11 || step != 12 || step != 1) {
      initGetWebsiteDataResponseFunction();
    }
  }, [step, editStep]);

  // useEffect(() => {
  //   if (step === 2) {
  //     initGetImageResponseFunction({ imageType: 1 });
  //   } else if (step === 3) {
  //     initGetImageResponseFunction({ imageType: 2 });
  //   }
  // }, [step]);

  // useEffect(() => {
  //   console.log("step................." , step)
  //   if (step === 12) {
  //     setIsEdit(true);
  //   } else {
  //     setIsEdit(false);
  //   }
  // }, [step]);

  useEffect(() => {
    if (websitePrefilledData) {
      console.log("prefill website data from api", websitePrefilledData);
      setWebsiteData({
        ...websiteData,
        welcomeText: websitePrefilledData?.welcome_text,
        websiteUrl: websitePrefilledData?.website_url_slug,
        businessName: websitePrefilledData?.business_name,
        logo: websitePrefilledData?.logo_image_url,
        coverPhoto: websitePrefilledData?.cover_photo_image_url,
        headline: websitePrefilledData?.headline,
        description: websitePrefilledData?.about,
        primaryColor:
          websitePrefilledData?.primary_color.length > 0
            ? websitePrefilledData?.primary_color
            : "#001125",
        secondaryColor:
          websitePrefilledData?.secondary_color.length > 0
            ? websitePrefilledData?.secondary_color
            : "#ffffff",
        tertiaryColor:
          websitePrefilledData?.tertiary_color.length > 0
            ? websitePrefilledData?.tertiary_color
            : "#000000",
        // showHoursOnPage: websitePrefilledData?.display_business_hours,
        socialLinks: {
          pwcFacebook: websitePrefilledData?.facebook,
          pwcInstagram: websitePrefilledData?.instagram,
          pwcWhatsapp: websitePrefilledData?.whatsapp,
        },

        personalDetails: {
          // pwcBusinessContact: websitePrefilledData?.business_email,
          pwcBusinessContact: websitePrefilledData?.business_contact,
          pwcFname: websitePrefilledData?.first_name,
          pwcLname: websitePrefilledData?.last_name,
          pwcBusinessEmail: websitePrefilledData?.business_email,
          pwcDisplayBusinessHours: websitePrefilledData?.business_hours_display,
          // businessHours toggle needs to be added
        },
        termsAndConditions: websitePrefilledData?.terms_and_conditions,
        can_publish: websitePrefilledData?.can_publish,
        stripe_onboarding: websitePrefilledData?.stripe_onboarding,
        stripe_onboarding_url: websitePrefilledData?.stripe_onboarding_url,
        is_active: websitePrefilledData?.is_active,
        has_added_all_information:
          websitePrefilledData?.has_added_all_information,
      });
      console.log(
        "websitePrefilledData?.current_step",
        parseInt(websitePrefilledData?.current_step, currentStepAvailable)
      );
      if (websitePrefilledData?.current_step && !currentStepAvailable) {
        switch (parseInt(websitePrefilledData?.current_step)) {
          case 1:
            props.setWelcomeScreen(true);
            break;
          case 2:
            setStep(1);
            break;
          case 3:
            setStep(2);
            break;
          case 4:
            setStep(3);
            break;
          case 5:
            setStep(4);
            break;
          case 6:
            setStep(5);
            break;
          case 7:
            setStep(6);
            setColorType("Primary");
            break;
          case 8:
            setStep(6);
            setColorType("Secondary");
            break;
          case 9:
            setStep(6);
            // setColorType("Tertiary");
            break;
          case 10:
            setStep(7);
            break;
          case 11:
            setStep(8);
            break;
          case 12:
            setStep(9);
            break;
          case 13:
            setStep(10);
            break;
          case 14:
            // setStep(11);
            // setStep(12);
            break;
          case 15:
            // setStep(11);
            setStep(12);
            break;
          case 15:
            setStep(13);
            break;
          default:
            setStep(1);
            break;
        }
        setCurrentStateAvailable(true);
      }
    }
  }, [websitePrefilledData, step, editStep,getDetailsLoading]);

  console.log(
    "websitePrefilledData",websitePrefilledData,
    "editStep",editStep,
    "websiteData",websiteData
  );

  useEffect(() => {
    setCount({
      step1: 0,
      step2: 0,
      step3: 0,
      step4: 0,
      step6: 0,
      step7: 0,
      step8: 0,
      step9: 0,
    });
  }, [step]);

  const BackHandleFun = () => {
    initGetWebsiteDataResponseFunction();
    if (step === 12 && websitePrefilledData?.is_active) {
      setWebsiteData({
        ...websiteData,
        logo: tempUploadSaveData.logo
          ? tempUploadSaveData.logo
          : websiteData.logo,
        coverPhoto: tempUploadSaveData.coverPhoto
          ? tempUploadSaveData.coverPhoto
          : websiteData.coverPhoto,
        headline: tempSaveTaglineData.headline
          ? tempSaveTaglineData.headline
          : websiteData.headline,
        description: tempSaveTaglineData.description
          ? tempSaveTaglineData.description
          : websiteData.description,
        businessName: tempSaveBusinessData.businessName
          ? tempSaveBusinessData.businessName
          : websiteData.businessName,
        websiteUrl: tempSaveBusinessData.websiteUrl
          ? tempSaveBusinessData.websiteUrl
          : websiteData.websiteUrl,
      });
      if (selectedEditTypeStep > 0) {
        if (editStep !== 0) {
          setEditStep(0);
        } else {
          setSelectedEditTypeStep(selectedEditTypeStep - 1);
        }
      } else {
        navigate(businessDashboardRoutePattern);
      }
    } else {
      setCount({
        step1: 0,
        step2: 0,
        step3: 0,
        step4: 0,
        step6: 0,
        step7: 0,
        step8: 0,
        step9: 0,
      });
      if (step === 1) {
        navigate(businessDashboardRoutePattern);
      } else if (step === 2 || step === 3) {
        backFromUploadStep();
        initiatetrackUserActivity({ activityStep: step });
        setStep(step - 1);
      } else if (step === 4) {
        initiatetrackUserActivity({ activityStep: step });
        setWebsiteData({
          ...websiteData,
          headline: tempSaveTaglineData.headline,
          description: tempSaveTaglineData.description,
        });
        setStep(step - 1);
      } else if (step === 5) {
        initiatetrackUserActivity({ activityStep: step });
        setStep(step - 1);
      } else if (step === 6) {
        if (colorType === "Primary") {
          initiatetrackUserActivity({ activityStep: step });
          setStep(5);
          setWebsiteData({
            ...websiteData,
            primaryColor: tempSaveColorData.primaryColor,
          });
        } else if (colorType === "Secondary") {
          initiatetrackUserActivity({ activityStep: step + 1 });
          setCount({
            ...count,
            step6: 0,
          });
          setWebsiteData({
            ...websiteData,
            secondaryColor: tempSaveColorData.secondaryColor,
          });
          setColorType("Primary");
        }
        // else if (colorType === "Tertiary") {
        //   initiatetrackUserActivity({ activityStep: step + 2 });
        //   setCount({
        //     ...count,
        //     step6: 0,
        //   });
        //   setWebsiteData({
        //     ...websiteData,
        //     tertiaryColor: tempSaveColorData.tertiaryColor,
        //   });
        //   setColorType("Secondary");
        // }
      } else if (step === 7) {
        initiatetrackUserActivity({ activityStep: step });
        setColorType("Secondary");
        setStep(6);
      } else if (step === 8) {
        initiatetrackUserActivity({ activityStep: step + 2 });
        setStep(step - 1);
      } else if (step === 9) {
        initiatetrackUserActivity({ activityStep: step + 2 });
        setStep(step - 1);
      } else if (step === 10) {
        initiatetrackUserActivity({ activityStep: step + 2 });
        setStep(step - 1);
      } else {
        setStep(step - 1);
      }
    }
  };

  const backFromUploadStep = () => {
    initiatetrackUserActivity({ activityStep: step + 1 });
    if (step === 2) {
      setCount({
        ...count,
        step2: 0,
      });
      setWebsiteData({
        ...websiteData,
        logo: tempUploadSaveData.logo,
      });
      setTempUploadSaveData({
        ...tempUploadSaveData,
        logo: "",
      });
    } else if (step === 3) {
      setWebsiteData({
        ...websiteData,
        coverPhoto: tempUploadSaveData.coverPhoto,
      });
      setTempUploadSaveData({
        ...tempUploadSaveData,
        coverPhoto: "",
      });
    }
    setStep(step - 1);
  };

  console.log("mainstep", step);
  useEffect(()=>{
console.log(websiteData, "websiteData check")
  },[websiteData])
 console.log(websiteData, "websiteData check outside")
  return (
    <>
      <div
        className={step === 12 ? "step-header MainEditWebsite" : "step-header"}
      >
        <div className="customeContainer">
          <div className="row">
            <div className="back_icon col-md-3 text-center">
              <img src={backIcon} onClick={() => BackHandleFun()} />
            </div>
            <div className="progress-bar col-md-6 text-center">
              <ProgressBar
                margin="auto"
                bgColor="#175FFA"
                customLabel=" "
                completed={(step * 100) / 9}
                isLabelVisible="true"
                height="5px"
                width="50%"
              />
            </div>
            <div className="close_icon col-md-3 text-center">
              <img
                src={closeIcon}
                onClick={() => {
                  navigate(businessDashboardRoutePattern);
                  // if (step === 12) {
                  //   setStep(10);
                  // } else {
                  //   navigate(businessDashboardRoutePattern);
                  // }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {isMobile && (
        <div className="responsiveButton">
          <button
            className={!responsivePrev ? "activeButton" : ""}
            onClick={() => {
              setResponsivePrev(false);
            }}
          >
            Details
          </button>
          <button
            className={responsivePrev ? "activeButton" : ""}
            onClick={() => {
              setResponsivePrev(true);
            }}
          >
            Preview
          </button>
        </div>
      )}

      <div className="customeContainer">
        {!responsivePrev ? (
          <div className="row">
            <div
              className={
                step === 10
                  ? `mainContainerPrev`
                  : step === 12
                  ? `col-md-12 col-xl-4 text-center EditWebsiteWrapper`
                  : ` ${
                      step === 13
                        ? "col-md-12 col-xl-12 text-center"
                        : " col-md-12 col-xl-6 text-center"
                    }`
              }
            >
              {step === 1 && (
                <div className="step1 MainStepWrapper">
                  <div className="MainContentWrapper">
                    {/* <ImageTitleComponent {...stepOneData} /> */}
                    <WebsiteURLStep1
                      setTempSaveBusinessData={setTempSaveBusinessData}
                      tempSaveBusinessData={tempSaveBusinessData}
                      step={step}
                      setStep={setStep}
                      websiteData={websiteData}
                      setWebsiteData={setWebsiteData}
                      stepOneData={stepOneData}
                      loading={saveWebsiteDetailsLoading}
                      trackActivity={initiatetrackUserActivity}
                      websitePrefilledData={websitePrefilledData}
                      saveApiStatus={saveWebsiteDataStatus}
                      count={count}
                      setCount={setCount}
                      getDetailsLoading={getDetailsLoading}
                      // style={{
                      //   height:
                      //     dimensions.height -
                      //     formInnerHeight -
                      //     55 -
                      //     40 -
                      //     10 +
                      //     "px",
                      // }}
                      style={{
                        height:
                          dimensions.width < 1200
                            ? dimensions.height -
                              headerCreateWebsite -
                              responsiveButtonHeight -
                              formButtonWrapperHeight -
                              20 -
                              50 +
                              "px"
                            : dimensions.height -
                              formInnerHeight -
                              55 -
                              40 -
                              10 +
                              "px",
                      }}

                      // isMobile={isMobile}
                      // prevWebOnClick={() => {
                      //   setResponsivePrev(true);
                      // }}
                    />
                  </div>
                  {/* <webPrev /> */}
                </div>
              )}
              {step === 2 && (
                <div
                  className="step2 MainStepWrapper"
                  // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
                >
                  <div className="MainContentWrapper">
                    {/* <ImageTitleComponent {...stepTwoData} /> */}
                    <UploadLogoCover
                      backFromUploadStep={backFromUploadStep}
                      tempUploadSaveData={tempUploadSaveData}
                      setTempUploadSaveData={setTempUploadSaveData}
                      step={step}
                      setStep={setStep}
                      websiteData={websiteData}
                      loading={saveWebsiteDetailsLoading}
                      trackActivity={initiatetrackUserActivity}
                      websitePrefilledData={websitePrefilledData}
                      saveApiStatus={saveWebsiteDataStatus}
                      count={count}
                      setCount={setCount}
                      setWebsiteData={setWebsiteData}
                      getImageLoading={getImageLoading}
                      stepTwoData={stepTwoData}
                      style={{
                        height:
                          dimensions.width < 1200
                            ? dimensions.height -
                              headerCreateWebsite -
                              responsiveButtonHeight -
                              formButtonWrapperHeight -
                              20 -
                              50 +
                              "px"
                            : dimensions.height -
                              formInnerHeight -
                              55 -
                              40 -
                              10 +
                              "px",
                      }}
                      // isMobile={isMobile}
                      // prevWebOnClick={() => {
                      //   setResponsivePrev(true);
                      // }}
                    />
                  </div>
                </div>
              )}
              {step === 3 && (
                <div
                  className="step3 MainStepWrapper"
                  // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
                >
                  <div className="MainContentWrapper">
                    <UploadLogoCover
                      tempUploadSaveData={tempUploadSaveData}
                      setTempUploadSaveData={setTempUploadSaveData}
                      backFromUploadStep={backFromUploadStep}
                      step={step}
                      setStep={setStep}
                      websiteData={websiteData}
                      loading={saveWebsiteDetailsLoading}
                      trackActivity={initiatetrackUserActivity}
                      websitePrefilledData={websitePrefilledData}
                      saveApiStatus={saveWebsiteDataStatus}
                      count={count}
                      setCount={setCount}
                      setWebsiteData={setWebsiteData}
                      stepThreeData={stepThreeData}
                      getImageLoading={getImageLoading}
                      style={{
                        height:
                          dimensions.width < 1200
                            ? dimensions.height -
                              headerCreateWebsite -
                              responsiveButtonHeight -
                              formButtonWrapperHeight -
                              20 -
                              50 +
                              "px"
                            : dimensions.height -
                              formInnerHeight -
                              55 -
                              40 -
                              10 +
                              "px",
                      }}
                      // isMobile={isMobile}
                      // prevWebOnClick={() => {
                      //   setResponsivePrev(true);
                      // }}
                    />
                  </div>
                </div>
              )}
              {step === 4 && (
                <div
                  className="step4 MainStepWrapper"
                  // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
                >
                  <div className="MainContentWrapper">
                    <WebsiteBussTagline
                      websiteData={websiteData}
                      setWebsiteData={setWebsiteData}
                      setTempSaveTaglineData={setTempSaveTaglineData}
                      tempSaveTaglineData={tempSaveTaglineData}
                      step={step}
                      stepFourData={stepFourData}
                      setStep={setStep}
                      loading={saveWebsiteDetailsLoading}
                      trackActivity={initiatetrackUserActivity}
                      websitePrefilledData={websitePrefilledData}
                      saveApiStatus={saveWebsiteDataStatus}
                      count={count}
                      setCount={setCount}
                      style={{
                        height:
                          dimensions.width < 1200
                            ? dimensions.height -
                              headerCreateWebsite -
                              responsiveButtonHeight -
                              formButtonWrapperHeight -
                              20 -
                              50 +
                              "px"
                            : dimensions.height -
                              formInnerHeight -
                              55 -
                              40 -
                              10 +
                              "px",
                      }}
                      // isMobile={isMobile}
                      // prevWebOnClick={() => {
                      //   setResponsivePrev(true);
                      // }}
                    />
                  </div>
                </div>
              )}
              {step === 5 && (
                <div
                  className="step5 MainStepWrapper"
                  // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
                >
                  <div className="MainContentWrapper">
                    <AddTeamMemberStep
                      step={step}
                      setStep={setStep}
                      websiteData={websiteData}
                      setWebsiteData={setWebsiteData}
                      stepFiveData={stepFiveData}
                      isVisibleSkip={true}
                      saveWebsiteDetails={initiateSaveWebsiteDetails}
                      loading={saveWebsiteDetailsLoading}
                      trackActivity={initiatetrackUserActivity}
                      websitePrefilledData={websitePrefilledData}
                      saveApiStatus={saveWebsiteDataStatus}
                      count={count}
                      setCount={setCount}
                      ButtonAddTeamMember={true}
                      style={{
                        height:
                          dimensions.width < 1200
                            ? dimensions.height -
                              headerCreateWebsite -
                              responsiveButtonHeight -
                              formButtonWrapperHeight -
                              20 -
                              50 +
                              "px"
                            : dimensions.height -
                              formInnerHeight -
                              55 -
                              40 -
                              10 +
                              "px",
                      }}
                      // isMobile={isMobile}
                      // prevWebOnClick={() => {
                      //   setResponsivePrev(true);
                      // }}
                    />
                  </div>
                </div>
              )}
              {step === 6 && (
                <div
                  className="step6 MainStepWrapper WebsitePrevMain CustomeColorMain"
                  // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
                >
                  <div className=" MainContentWrapper WebsitePrevWrapper">
                    {/* <ImageTitleComponent {...stepSixData} /> */}
                    <WebsitePrev
                      setTempSaveColorData={setTempSaveColorData}
                      tempSaveColorData={tempSaveColorData}
                      isVisiblePrev={false}
                      isVisibleColorPicker={true}
                      step={step}
                      setStep={setStep}
                      // colour={websiteData?.colour}
                      websiteData={websiteData}
                      stepSixPrimaryData={stepSixPrimaryData}
                      stepSixSecondaryData={stepSixSecondaryData}
                      stepSixTertiaryData={stepSixTertiaryData}
                      setWebsiteData={setWebsiteData}
                      saveWebsiteDetails={initiateSaveWebsiteDetails}
                      loading={saveWebsiteDetailsLoading}
                      trackActivity={initiatetrackUserActivity}
                      websitePrefilledData={websitePrefilledData}
                      saveApiStatus={saveWebsiteDataStatus}
                      count={count}
                      setCount={setCount}
                      colorType={colorType}
                      setColorType={setColorType}
                      // handleColorChange={handleColorChange}
                      // handleAlphaChange={handleAlphaChange}
                      // sliderTrackStyle={sliderTrackStyle}
                      style={{
                        height:
                          dimensions.width < 1200
                            ? dimensions.height -
                              headerCreateWebsite -
                              responsiveButtonHeight -
                              formButtonWrapperHeight -
                              20 -
                              50 +
                              "px"
                            : dimensions.height -
                              formInnerHeight -
                              55 -
                              40 -
                              10 +
                              "px",
                      }}
                    />
                    {/* <div className="inputWrapper">
                    <div className="formWrapper">
                      <div className="FormButtonWrapper">
                        <StepButton
                          onClick={() => {
                            setStep(step + 1);
                          }}
                          blue={true}
                          label={"Next"}
                          icon={<FiChevronRight className="arrow_next" />}
                        />
                        <SkipButtonComp
                          onClick={() => {
                            setStep(step + 1);
                          }}
                        
                        />
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              )}
              {step === 7 && (
                <div
                  className="step7 MainStepWrapper"
                  // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
                >
                  <div className="MainContentWrapper">
                    {/* <ImageTitleComponent {...stepSevenData} /> */}
                    <SocialMediaPage
                      step={step}
                      setStep={setStep}
                      websiteData={websiteData}
                      setWebsiteData={setWebsiteData}
                      stepSevenData={stepSevenData}
                      style={{
                        height:
                          dimensions.width < 1200
                            ? dimensions.height -
                              headerCreateWebsite -
                              responsiveButtonHeight -
                              formButtonWrapperHeight -
                              20 -
                              50 +
                              "px"
                            : dimensions.height -
                              formInnerHeight -
                              55 -
                              40 -
                              10 +
                              "px",
                      }}
                      loading={saveWebsiteDetailsLoading}
                      trackActivity={initiatetrackUserActivity}
                      websitePrefilledData={websitePrefilledData}
                      saveApiStatus={saveWebsiteDataStatus}
                      count={count}
                      setCount={setCount}
                      // isMobile={isMobile}
                      // prevWebOnClick={() => {
                      //   setResponsivePrev(true);
                      // }}
                    />
                  </div>
                </div>
              )}
              {step === 8 && (
                <div
                  className="step8 MainStepWrapper"
                  // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
                >
                  <div className="MainContentWrapper">
                    <PersonalDetailsStep
                      step={step}
                      setStep={setStep}
                      websiteData={websiteData}
                      setWebsiteData={setWebsiteData}
                      stepEightData={stepEightData}
                      loading={saveWebsiteDetailsLoading}
                      trackActivity={initiatetrackUserActivity}
                      websitePrefilledData={websitePrefilledData}
                      saveApiStatus={saveWebsiteDataStatus}
                      count={count}
                      setCount={setCount}
                      style={{
                        height:
                          dimensions.width < 1200
                            ? dimensions.height -
                              headerCreateWebsite -
                              responsiveButtonHeight -
                              formButtonWrapperHeight -
                              20 -
                              50 +
                              "px"
                            : dimensions.height -
                              formInnerHeight -
                              55 -
                              40 -
                              10 +
                              "px",
                      }}
                      // isMobile={isMobile}
                      // prevWebOnClick={() => {
                      //   setResponsivePrev(true);
                      // }}
                    />
                  </div>
                </div>
              )}
              {step === 9 && (
                <div
                  className="step9 MainStepWrapper"
                  // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
                >
                  <div className="MainContentWrapper">
                    <TermsAndCondition
                      step={step}
                      setStep={setStep}
                      websiteData={websiteData}
                      setWebsiteData={setWebsiteData}
                      stepNineData={stepNineData}
                      loading={saveWebsiteDetailsLoading}
                      trackActivity={initiatetrackUserActivity}
                      websitePrefilledData={websitePrefilledData}
                      saveApiStatus={saveWebsiteDataStatus}
                      count={count}
                      setCount={setCount}
                      style={{
                        height:
                          dimensions.width < 1200
                            ? dimensions.height -
                              headerCreateWebsite -
                              responsiveButtonHeight -
                              formButtonWrapperHeight -
                              20 -
                              50 +
                              "px"
                            : dimensions.height -
                              formInnerHeight -
                              55 -
                              40 -
                              10 +
                              "px",
                      }}
                      // isMobile={isMobile}
                      // prevWebOnClick={() => {
                      //   setResponsivePrev(true);
                      // }}
                    />
                  </div>
                </div>
              )}
              {step === 10 && (
                <>
                  <div
                    className="step11 stepElevenMainPrevSite WebsitePrevMain"
                    // style={{
                    //   height: dimensions.height - headerCreateWebsite - responsiveButtonHeight - 20 + "px",
                    // }}

                    style={{
                      height:
                        dimensions.width < 1200
                          ? dimensions.height -
                            headerCreateWebsite -
                            responsiveButtonHeight -
                            buildSiteButtonHeight -
                            20 +
                            "px"
                          : dimensions.height -
                            headerCreateWebsite -
                            buildSiteButtonHeight +
                            "px",
                    }}
                  >
                    <div className="MainContentWrapper WebsitePrevWrapper">
                      <WebsitePrev
                        websiteData={websiteData}
                        setWebsiteData={setWebsiteData}
                        setTempSaveColorData={setTempSaveColorData}
                        tempSaveColorData={tempSaveColorData}
                        step={step}
                        setStep={setStep}
                        isVisiblePrev={true}
                        saveWebsiteDetails={initiateSaveWebsiteDetails}
                        loading={saveWebsiteDetailsLoading}
                        trackActivity={initiatetrackUserActivity}
                        websitePrefilledData={websitePrefilledData}
                        saveApiStatus={saveWebsiteDataStatus}
                        count={count}
                        setCount={setCount}
                        colorType={colorType}
                        setColorType={setColorType}
                      />
                    </div>
                  </div>
                  <div className="BuildSiteWrapper">
                    {publishLoader && <CustomeLoading />}
                    <p>Welcome to faces builder tool</p>
                    <div className="SiteButtonWrapper">
                      <button
                        className="whiteBtn"
                        onClick={() => {
                          // initiatetrackUserActivity({
                          //   activityStep: 14,
                          // });
                          websitePrefilledData?.is_active
                            ? setStep(12)
                            : setStep(9);
                        }}
                      >
                        {websitePrefilledData?.is_active
                          ? "Edit Your Website"
                          : "Back"}
                      </button>
                      <button
                        // disabled={websiteData?.can_publish ? false : true}
                        // className={
                        //   websiteData?.can_publish
                        //     ? "whiteBorder"
                        //     : "whiteBorderDisabled"
                        // }
                        className="whiteBorder"
                        onClick={() => {
                          console.log(
                            websiteData?.has_added_all_information,
                            "infowebsiteData"
                          );
                          // if(peddingPopUp){
                          //   setProceedModel(true)
                          // }
                          // else{
                            if (websiteData?.has_added_all_information || canPublish) {
                            if (websiteData?.stripe_onboarding) {
                              initiatePublishPersonalWebsite({}, (res) => {
                                if (res?.success) {
                                  setStep(11);
                                  initiatetrackUserActivity({
                                    activityStep: 14,
                                  });
                                }
                              });
                            } else {
                              setProceedModel(true);
                            }
                          } else {
                            setPublishInformationModel(true);
                          }
                        // }
                          // if (websiteData?.can_publish) {
                          //   initiatePublishPersonalWebsite({}, (res) => {
                          //     if (res?.success) {
                          //       setStep(11);
                          //       initiatetrackUserActivity({
                          //         activityStep: 14,
                          //       });
                          //     }
                          //   });
                          // } else {
                          //   if (websiteData?.stripe_onboarding) {
                          //     setProceedModel(true);
                          //   } else {
                          //     setPublishInformationModel(true);
                          //   }
                          // }
                        }}
                      >
                        Publish
                      </button>
                    </div>
                  </div>
                </>
              )}
              {step === 11 && (
                <div
                  className="step9 MainStepWrapper"
                  // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
                >
                  <div className="MainContentWrapper">
                    {/* <ImageTitleComponent/> */}
                    <CongratulationPage
                      {...stepTenData}
                      websiteData={websiteData}
                      setIsEdit={setIsEdit}
                      setWebsiteData={setWebsiteData}
                      saveWebsiteDetails={initiateSaveWebsiteDetails}
                      loading={saveWebsiteDetailsLoading}
                      trackActivity={initiatetrackUserActivity}
                      websitePrefilledData={websitePrefilledData}
                      saveApiStatus={saveWebsiteDataStatus}
                      count={count}
                      setCount={setCount}
                      step={step}
                      setStep={setStep}
                      style={{
                        height:
                          dimensions.width < 1200
                            ? dimensions.height -
                              headerCreateWebsite -
                              responsiveButtonHeight -
                              formButtonWrapperHeight -
                              20 -
                              50 +
                              "px"
                            : dimensions.height -
                              formInnerHeight -
                              55 -
                              40 -
                              10 +
                              "px",
                      }}
                      // isMobile={isMobile}
                      // prevWebOnClick={() => {
                      //   setResponsivePrev(true);
                      // }}
                    />
                  </div>
                </div>
              )}
              {step === 12 && (
                <EditWebsite
                setIsEdit={setIsEdit}
                  tempSaveColorData={tempSaveColorData}
                  setTempSaveColorData={setTempSaveColorData}
                  tempSaveTaglineData={tempSaveTaglineData}
                  setTempSaveTaglineData={setTempSaveTaglineData}
                  tempUploadSaveData={tempUploadSaveData}
                  setTempUploadSaveData={setTempUploadSaveData}
                  tempSaveBusinessData={tempSaveBusinessData}
                  setTempSaveBusinessData={setTempSaveBusinessData}
                  editStep={editStep}
                  setEditStep={setEditStep}
                  websiteData={websiteData}
                  setWebsiteData={setWebsiteData}
                  step={step}
                  setStep={setStep}
                  selectedEditTypeStep={selectedEditTypeStep}
                  setSelectedEditTypeStep={setSelectedEditTypeStep}
                  stepOneData={stepOneData}
                  stepTwoData={stepTwoData}
                  stepThreeData={stepThreeData}
                  stepFourData={stepFourData}
                  stepFiveData={stepFiveData}
                  stepSixPrimaryData={stepSixPrimaryData}
                  stepSixSecondaryData={stepSixSecondaryData}
                  stepSixTertiaryData={stepSixTertiaryData}
                  stepSevenData={stepSevenData}
                  stepEightData={stepEightData}
                  stepNineData={stepNineData}
                  stepTenData={stepTenData}
                  saveWebsiteDetails={initiateSaveWebsiteDetails}
                  loading={saveWebsiteDetailsLoading}
                  trackActivity={initiatetrackUserActivity}
                  websitePrefilledData={websitePrefilledData}
                  saveApiStatus={saveWebsiteDataStatus}
                  count={count}
                  setCount={setCount}
                  colorType={colorType}
                  setColorType={setColorType}

                  // style={{
                  //   height: dimensions.height - formInnerHeight - 149 + "px",
                  // }}
                />
              )}
            </div>
            {/* {step === 13 &&  */}
            {!isMobile && step !== 10 && (
              <div
                className={
                  step === 12
                    ? `EditPrevWebsite img_container col-md-12 col-xl-8`
                    : `${
                        step === 13
                          ? "d-none"
                          : "img_container col-md-12 col-xl-6"
                      } `
                }
              >
                <div className="WebsitePrevMain">
                  <div
                    className="WebsitePrevWrapper"
                    style={{
                      height: dimensions.height - headerCreateWebsite + "px",
                    }}
                  >
                    <WebsitePrev
                      websiteData={websiteData}
                      setWebsiteData={setWebsiteData}
                      setTempSaveColorData={setTempSaveColorData}
                      tempSaveColorData={tempSaveColorData}
                      step={step}
                      setStep={setStep}
                      saveWebsiteDetails={initiateSaveWebsiteDetails}
                      loading={saveWebsiteDetailsLoading}
                      trackActivity={initiatetrackUserActivity}
                      websitePrefilledData={websitePrefilledData}
                      saveApiStatus={saveWebsiteDataStatus}
                      count={count}
                      setCount={setCount}
                      isVisiblePrev={true}
                      colorType={colorType}
                      setColorType={setColorType}
                      // style={{
                      //   height: dimensions.height - 55 + "px",
                      // }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="WebsitePrevMain">
            <div
              className="WebsitePrevWrapper"
              style={{
                height: dimensions.height - headerCreateWebsite + "px",
              }}
            >
              <WebsitePrev
                websiteData={websiteData}
                setWebsiteData={setWebsiteData}
                setTempSaveColorData={setTempSaveColorData}
                tempSaveColorData={tempSaveColorData}
                saveWebsiteDetails={initiateSaveWebsiteDetails}
                loading={saveWebsiteDetailsLoading}
                trackActivity={initiatetrackUserActivity}
                websitePrefilledData={websitePrefilledData}
                saveApiStatus={saveWebsiteDataStatus}
                count={count}
                setCount={setCount}
                colorType={colorType}
                setColorType={setColorType}
                step={step}
                setStep={setStep}
                isVisiblePrev={true}
              />
            </div>
          </div>
        )}
      </div>
      <CustomModal
        className="deleteModel publichInformationModel"
        modalBody={
          <div className="deleteModelWrapper">
            {/* <div className="CloseWrapper">
              <img
                src={closeIcon}
                alt="modalClose"
                onClick={() => {
                  setPublishInformationModel(false);
                }}
              />
            </div> */}
            <div className="deleteContentWrapper">
              {/* <img className="my-auto" src={editWebsiteIcon} alt="" /> */}

              <p>Your details are still pending</p>
              <div>
                Your website has now been published however you have NOT
                completed all your details please remember the more professional
                your website looks the more success you will have booking and
                attracting new clients.
              </div>
            </div>
            {/* <div className="btnWrapper onlyOne">
              <button
                onClick={() => {
                  setPublishInformationModel(false);
                }}
              >
                Ok
              </button>
              <button
                onClick={() => {
                  setPublishInformationModel(false);
                  setTimeout(() => {
                    setStep(12);
                  }, 500);
                }}
              >
                Edit Your WebSite
              </button>
            </div> */}
            <div className="btn">
                <button className="btn-1" onClick={()=>{
                    setCanPublish(true);
                    setPeddingPopUp(true); 
                    setPublishInformationModel(false) }}>Okay</button>
            </div>
          </div>
        }
        modalOpen={publishInformationModel}
        setModalOpen={setPublishInformationModel}
      />

      {/* proceedModel */}
      <CustomModal
        className="deleteModel publichInformationModel"
        modalBody={
          <div className="deleteModelWrapper">
            <div className="CloseWrapper">
              <img
                src={closeIcon}
                alt="modalClose"
                onClick={() => {
                  setProceedModel(false);
                }}
              />
            </div>
            <div className="deleteContentWrapper">
              {/* <img className="my-auto" src={editWebsiteIcon} alt="" /> */}

              <p>
                You have not connected your bank account therefore you can not
                take payments online and people can book in without paying a
                deposit
              </p>
            </div>
            <div className="btnWrapper1">
              <button
                onClick={() => {
                  initiatePublishPersonalWebsite({}, (res) => {
                    if (res?.success) {
                      setStep(11);
                      initiatetrackUserActivity({
                        activityStep: 14,
                      });
                    }
                  });
                  setProceedModel(false);
                }}
              >
                No I DO NOT take deposits
              </button>
              <button
                onClick={() => {
                  window.location.href = websiteData?.stripe_onboarding_url;
                  setProceedModel(false);
                }}
              >
                Yes I will take online payments
              </button>
            </div>
          </div>
        }
        modalOpen={proceedModel}
        setModalOpen={setProceedModel}
      />
    </>
  );
};

export default StepsLayout;
