import React, { useEffect, useState } from 'react'
import "./pharmacylist.scss"
import StepButton from '../../common/StepButton'
import { PharmacyListQuery } from '../../redux/actions/consent-form-thankyou/getPharmacyList'
import Skeleton from 'react-loading-skeleton'

const PharmacyList = ({ consentFormId, setIsOpenFilter, isOpenFilter, selectedPharmacy, setSelectedPharmacy,onFilterClick ,setPharmacyList,pharmacyList,setSelectAllPharmacies,selectAllPharmacies}) => {
    const { initPharmacyList, pharmacyListQueryData } = PharmacyListQuery()
    
 

    useEffect(() => {
        console.log("response pharmacy", selectedPharmacy)
        // if (selectedPharmacy.length != 0) {
        //     setSelectAllPharmacies(false);
        // }
        /// if(selectedPharmacy.length === pharmacyList.length ){
        //     setSelectAllPharmacies(true)
            
        // }
        console.log("pharmacyList", pharmacyListQueryData?.data?.getPharmacyList?.data?.items)        
            if(pharmacyList.length === 0){
                setSelectAllPharmacies(true)
                initPharmacyList({ consentFormId: parseInt(consentFormId) }, (res) => {
               
                    if (res?.success) {
                        // const items = res?.data?.items?.map(item => Object.assign({}, item, { selected: false }))
                        const items = res?.data?.items?.map(item => ({
                            ...item,
                            selected: selectedPharmacy.includes(item.id) // Preselect based on selectedPharmacy array
                        }));
                        setPharmacyList(items)
                    } else {
                        setPharmacyList([])
                    }
                }, (err) => {
                    setPharmacyList([])
                })
            
            }
    }, [pharmacyList])

    ///CLEAR ALL
    const handleClearAll = () => {
        setPharmacyList(prevPharmacyList => prevPharmacyList.map(pharmacy => ({ ...pharmacy, selected: false })));
        setSelectAllPharmacies(true);
    };

    //SET ALL CHECKED CHECK BOX
    const handleSelectAllPharmacies = (event) => {
        const checked = event.target.checked;
        setSelectAllPharmacies(checked);
        setPharmacyList(prevPharmacyList => prevPharmacyList.map(pharmacy => ({
            ...pharmacy,
            selected: false
        })));

    };

    //SET INDIVIDUAL CHECK BOX
    const handlePharmacyCheckboxChange = (id, event) => {
        const checked = event.target.checked;

        setPharmacyList(prevPharmacyList => prevPharmacyList.map(pharmacy => {
            if (pharmacy.id === id) {
                return { ...pharmacy, selected: checked };
            }
            return pharmacy;
        }));
        const allSelected = pharmacyList.every(pharmacy => pharmacy.selected);
        console.log(allSelected, "allSelected")
        setSelectAllPharmacies(allSelected);
    };

    //HANDLE FILTER BUTTON CLICK
    const handleFilterClick = () => {
       
        // Do whatever you want with selectedPharmaciesData
    };

    return (
        <div className='pharmacy-list-container'>

            <div className="pharmacy-list">
                <div className='filter-section'>
                    <h1>Filter</h1>
                    <p onClick={handleClearAll}>Clear all</p>
                </div>
                <hr />
                {pharmacyList.length !== 0 && <label className="pharmacy-item">
                    <b>All Pharmacies</b>
                    <input type='checkbox' checked={selectAllPharmacies} onChange={handleSelectAllPharmacies} />
                </label>}
                {
                    pharmacyListQueryData?.loading ?
                        <Skeleton count={10} height={20} style={{ marginTop: '10px' }} />
                        :
                        pharmacyList.length === 0  && pharmacyListQueryData.loading === undefined?
                        <h1>No pharmacies found</h1>:
                        pharmacyList?.map((item, index) => (
                            <label className="pharmacy-item" key={index}>
                                <b>{item?.name}</b>
                                <input type='checkbox' checked={item?.selected}  onChange={(e) => handlePharmacyCheckboxChange(item?.id, e)} />
                            </label>
                        ))
                        

                }

            </div>
            <div className='pharmacy-button thankyou-page-button-style'>
                <StepButton blue={true} label={"Filter"} disabled={pharmacyList?.length === 0} onClick={() => onFilterClick()} />
            </div>
        </div>
    )
}

export default PharmacyList