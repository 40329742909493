import './walk-in-main.scss'
import { useSelector } from 'react-redux';
import StepButton from '../common/StepButton';
import CustomModal from '../modal/CustomModal';
import backArrow from '../../images/back_arrow.svg'
import SignatureCanvas from "react-signature-canvas";
import { useLocation, useNavigate } from 'react-router';
import React, { useEffect, useRef, useState } from 'react'
import FormCompletePopUp from './ConsentFormCompletePopUp';
import CustomRadioButton from '../common/CustomRadioButton';
import ConsentFormCustomModal from '../modal/ConsentFormModal/ConsentFormModal';
import { ReactComponent as UndoIcon } from "../../images/signature pop-icon/undo.svg";
import { ReactComponent as EmptySignIcon } from "../../images/signature pop-icon/empty_sign.svg";
import { ReactComponent as EnableCheck } from "../../images/signature pop-icon/enable_check.svg";
import { ReactComponent as UndoEnableIcon } from "../../images/signature pop-icon/undo_enable.svg";
import { ReactComponent as DisableCheck } from "../../images/signature pop-icon/disable_check.svg";
import { ReactComponent as EnableSignIcon } from "../../images/signature pop-icon/active_sign.svg";
import { CreateOrUpdateWalkInQuestionAnswer, GetWalkInQuestionAnswer } from '../redux/actions/walk-in-form/walkInAddFormsList';
import NavHeader from '../common/NavHeader/NavHeader';

const ConsentFormQA = ({ currentStep, setCurrentStep }) => {
    const navigate = useNavigate();

    const [comment, setComment] = useState("");
    const [isSigned, setIsSigned] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [questionAnswers, setQuestionAnswers] = useState([]);
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const [isSignatureOpen, setIsSignatureOpen] = useState(false);
    const [isOpenCommentBox, setIsOpenCommentBox] = useState(false);


    const walkInQuestionAnswerData = useSelector(state => state?.walkInClientListReducer?.questionAnswerData);

    const { initWalkInQuestionAnswer, walkInQuestionAnswerQueryData } = GetWalkInQuestionAnswer();
    const { createOrUpdateWalkInQuestionAnswerQueryData, initCreateOrUpdateWalkInQuestionAnswerData } = CreateOrUpdateWalkInQuestionAnswer();

    const consentFormRecordId = useLocation().state?.responseData;
    useEffect(() => {
        initWalkInQuestionAnswer({
            consentFormRecId: consentFormRecordId,
        });
    }, []);

    const [questionAnswerData, setQuestionAnswerData] = useState([]);

    const [options, setOptions] = useState([
        { text1: "Yes", checked: false },
        { text2: "No", checked: false },
        { text3: "Other", checked: false },
    ]);

    useEffect(() => {
        const initialAnswers = walkInQuestionAnswerData.map((queData) => ({
            question: queData?.question,
            answer: null,
            comment: "",
        }));
        setQuestionAnswers(initialAnswers);

        const hasNonNullAnswer = walkInQuestionAnswerData.some((questionData) => questionData.answer !== null);
        if (!hasNonNullAnswer) {
            const updatedOptions = options.map((option) => ({ ...option, checked: false }));
            setOptions(updatedOptions);
        }
    }, [walkInQuestionAnswerData]);

    const handleRadioButtonChange = (event, questionIndex) => {
        const updatedAnswers = [...questionAnswers];

        // Update the answer state
        updatedAnswers[questionIndex].answer = event.target.value;

        // Reset comment when a different option is selected
        updatedAnswers[questionIndex].comment = "";

        // Update the options state
        const updatedOptions = options.map((option) => ({
            ...option,
            checked: option.text1 === event.target.value,
        }));

        setOptions(updatedOptions);
        if (event.target.value === "Other") {
            let temp = [...questionAnswerData];
            temp[questionIndex].commentBoxShow = true;
            setQuestionAnswerData(temp);
        }
        else {
            let temp = [...questionAnswerData];
            temp[questionIndex].commentBoxShow = false;
            setQuestionAnswerData(temp);
        }

        setQuestionAnswers(updatedAnswers);
    };

    const handleCommentChange = (e, questionIndex) => {
        const updatedAnswers = [...questionAnswers];
        const commentValue = e.target.value;
        updatedAnswers[questionIndex].comment = commentValue;

        if (commentValue.length > 0) {
            setIsOpenCommentBox(true);
        } else {
            setIsOpenCommentBox(false);
        }

        setQuestionAnswers(updatedAnswers);
    };
    const con_que_id = walkInQuestionAnswerData.map((questionData, index) => {
        return questionData.con_frm_id
    })
    const handleSave = () => {
        const quesAnsData = {
            questionAns: questionAnswers.map((q) => ({
                answer: q.answer === "Yes" ? 1 : q.answer === "No" ? 0 : q.answer === "Other" ? 3 : -1,
                comment: isOpenCommentBox ? q.comment : "",
                con_frm_rec_id: consentFormRecordId,
                question: q.question,
                is_ignore: false && true || null,
            })),
            // consentFormRecQesId: parseInt(con_que_id),
        };
        initCreateOrUpdateWalkInQuestionAnswerData(quesAnsData);
        openModal();

    };

    const sigPadRef = useRef();
    const clear = () => {
        sigPadRef.current.clear();
        setIsSigned(false);
    };
    const handleHide = () => {
        //setIsOpen(false);
        setIsSignatureOpen(true);
    };
    const handleSignatureModalClose = () => {
        setIsSignatureOpen(false); // Close the SignatureModal
        // setIsOpen(true); // Close the NewAppointment
    };
    const handleSignature = () => {
        setTrimmedDataURL(
            sigPadRef.current?.getSignaturePad()?.toDataURL("image/png")
        );
    };
    const handleNext = () => {
        navigate("/walk-in-consent");
    };
    const handleBack = () => {
        navigate("/walk-in-consent");
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    const openModal = () => {
        scrollToTop();
        // setCurrentStep(1);
        setIsModalOpen(true);
    };


    useEffect(() => {
        if (walkInQuestionAnswerData) {
            let temp = [];
            walkInQuestionAnswerData.forEach((element) => {
                temp.push({
                    ...element,
                    commentBoxShow: false,
                });
            });
            setQuestionAnswerData(temp);
        }
    }, [walkInQuestionAnswerData]);

    return (
        <div className='Main-consent-QA-content'>
            <NavHeader isBackOnly={true} handleOnBack={handleBack} />
            <div className="container">
                <div className="consent-QA-content">
                    <div className='SectionHeading'>
                        <div className="Section-Title">Consent form name 1</div>
                        <div className="Section-Desc">Please complete as thoroughly as possible. Feel free to ask any questions.</div>
                    </div>

                    <div className='consent-QA-edit-options-Content'>
                        <div className='SubDesc'>
                            <h6>Disclaimer title 1</h6>
                            <p>The disclaimer text for the consent form may be lengthy but will be truncated to three lines for conciseness. Provide an additiona...</p>
                        </div>

                        <div className='optionWrapper'>
                            <p className="option-lable">{"This is a question that requires a yes or no answer?"}</p>
                            <div className='option-slot-wrapper'>
                                {options.map((option, optionIndex, index) => (
                                    <div className="time-slot-content" key={optionIndex}>
                                        <CustomRadioButton
                                            isblacked={true}
                                            text2={option.text1 || option.text2 || option.text3}
                                            name={`timeSlotGroup_${index}`}
                                            checked={questionAnswers[index]?.answer === option.text1}
                                            value={option.text1 || option.text2 || option.text3}
                                            onChange={(event) => handleRadioButtonChange(event, index)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="time-add-comment">
                            <textarea
                                className="form-control"
                                // id={`commentTextarea_${index}`}
                                placeholder="Add a note"
                                rows="3"
                                name="addComment"
                                // value={questionAnswers[index]?.comment || ""}
                                onChange={(e) => handleCommentChange(e)}
                            ></textarea>
                        </div>

                        <div className='SubDesc'>
                            <h6>Disclaimer text</h6>
                            <p>Here is some disclaimer text template for the form.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu eros eget neque malesuada pellentesque. Proin sit amet tincidunt enim. Vestibulum at enim elementum, interdum lig</p>
                        </div>

                        <div className="card-section">
                                <div className="d-flex flex-column align-items-center gap-2 sign-section" onClick={handleHide}>
                                    {trimmedDataURL ? <EnableSignIcon /> : <EmptySignIcon />}
                                    <h6>Your signature</h6>
                                </div>
                            </div>

                            <div className="BottomBtn">
                                <StepButton label={"Save"} blue={true} onClick={handleSave} />
                            </div>
                    </div>
                  
                </div>
            </div>

            {/* pop up */}
            <ConsentFormCustomModal
                modalOpen={isModalOpen}
                setModalOpen={setIsModalOpen}
                hideCloseButton={true}
                modalBody={
                    <>
                        <div>
                            <FormCompletePopUp />
                            <StepButton label={"Ok, got it"} blue={true} onClick={handleNext} />
                        </div>
                    </>
                }
            />

            {/* signature modal */}
            {
                isSignatureOpen && (
                    <CustomModal
                        className={"CustomeSignModel"}
                        modalOpen={isSignatureOpen}
                        setIsOpenMobileCalendar={handleSignatureModalClose}
                        setModalOpen={setIsSignatureOpen}
                        type="common-width"
                        modalBody={
                            <div className="d-flex flex-column wrapper-canvas">
                                <SignatureCanvas
                                    ref={sigPadRef}
                                    canvasProps={{
                                        width: 500,
                                        height: 200,
                                        className: "signCanvas",
                                    }}
                                    throttle={null}
                                    onEnd={() => {
                                        setIsSigned(true);
                                    }}
                                />
                                <div className="justify-content-between d-flex w-100">
                                    {isSigned ? (
                                        <UndoEnableIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={clear}
                                        />
                                    ) : (
                                        <UndoIcon style={{ cursor: "not-allowed" }} />
                                    )}
                                    {isSigned ? (
                                        <EnableCheck
                                            onClick={() => {
                                                handleSignatureModalClose();
                                                setIsSignatureOpen(false);
                                                handleSignature();
                                            }}
                                            style={{ cursor: "pointer" }}
                                        />
                                    ) : (
                                        <DisableCheck style={{ cursor: "not-allowed" }} />
                                    )}
                                </div>
                            </div>
                        }
                    />
                )
            }
        </div>
    )
}

export default ConsentFormQA
