import React, { useEffect, useRef, useState } from "react";
import './facesBusinessHome.scss';
import FacesBusinessHeader from "../../facesBusinessHeader/facesBusinessHeader";
import FacesBusinessFooter from "../../facesBusinessFooter/facesBusinessFooter";
import ReviewImage from "../../../images/businessHome/Review.png";
import ManageImage from "../../../images/businessHome/manageImage.png";
import PaperlessImage from "../../../images/businessHome/Paperless.png";
import CreatePImage from "../../../images/businessHome/create-p.png";
import OneAccountImage from "../../../images/businessHome/One-Account.png";
import MarketingImage from "../../../images/businessHome/marketing.png";
import DiscountMockImage from "../../../images/businessHome/discount-mock.png";
import Av1 from "../../../images/businessHome/av1.png";
import TabDesktopImage from "../../../images/businessHome/TabDesktop.png";
import { ReactComponent as StarIcon } from "../../../images/businessHome/star.svg";
import { ReactComponent as OrangeStarIcon } from "../../../images/businessHome/orangeStar.svg";
import { ReactComponent as TrustPilotLogo } from "../../../images/businessHome/trustpilot-logo.svg";
import googleLogo from "../../../images/businessHome/google.png";
import { ReactComponent as CheckIcon } from "../../../images/businessHome/Check.svg";
import { ReactComponent as ConsentIcon } from "../../../images/businessHome/Consent.svg";
import { ReactComponent as BookingIcon } from "../../../images/businessHome/booking.svg";
import { ReactComponent as WebIcon } from "../../../images/businessHome/Web.svg";
import { ReactComponent as TeamIcon } from "../../../images/businessHome/Team.svg";
import { ReactComponent as VerifiedIcon } from "../../../images/businessHome/verified.svg";
import { ReactComponent as PlayIcon } from "../../../images/businessHome/Play.svg";
import { ReactComponent as AppleIcon } from "../../../images/businessHome/Apple.svg";
import { ReactComponent as AppStoreIcon } from "../../../images/Businessfooter/AppStore.svg";
import { ReactComponent as GooglePlayIcon } from "../../../images/Businessfooter/googlePlay.svg";
import { ReactComponent as MHRAIcon } from "../../../images/businessHome/MHRA.svg";
import { ReactComponent as GoogleIcon } from "../../../images/businessHome/Google.svg";
import { ReactComponent as DesignedIcon } from "../../../images/businessHome/Designed.svg";

import LineAssets from "../../../images/businessHome/LineAssets.png";
import EtaImage from "../../../images/businessHome/etaImage.png";
import PlayStoreIcon from "../../../images/businessHome/Playstore.png";
import CountUp from 'react-countup';
import BackImage from "../../../images/businessHome/backImage.png";
import LogoPharmacyImg from "../../../images/businessHome/logo-pharmacy.png";
import LogoInsyncImg from "../../../images/businessHome/Insync-Logo-2020.png";
import LogoPayl8rImg from "../../../images/businessHome/Payl8r-logo.png";
import LogoPolicyBeeImg from "../../../images/businessHome/PolicyBee-logo.png";
import LogoAestheticImg from "../../../images/businessHome/Aesthetic-Medicine-Logo.png";
import Thumbnail1 from "../../../images/businessHome/Thumbnail1.png";
import Thumbnail2 from "../../../images/businessHome/Thumbnail2.png";
import placeholderThumbnail from "../../../images/businessHome/placeholder.png";

import Marquee from "react-fast-marquee";
import Slider from "react-slick";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { Twitter } from "@material-ui/icons";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { ReactComponent as YoutubeIcon } from "../../../images/businessHome/youtube.svg";
import { Accordion } from "react-bootstrap";
import { FaChevronUp } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import { config, webBaseUrl } from "../../../config";
import CustomModal from "../../modal/CustomModal";
import { GetHomeCountAction } from "../../redux/actions/homepage/getHomeCount";
import { GetFaqListAction } from "../../redux/actions/homepage/getFaqList";
import { GetTestimonialsAction } from "../../redux/actions/homepage/getTestimonials";
import { FaInstagram } from "react-icons/fa";
import { socket } from '../../../components/socket';
import { Helmet } from "react-helmet";
import { GetRatingsAction } from "../../redux/actions/homepage/getRating";
import SkeletonLoader from '../../common/Skeleton/SkeletonLoader';
import Skeleton from "react-loading-skeleton";

const FacesBusinessHome = () => {
    const [isyouTube, setIsyouTube] = useState(false);
    const [millionVideo, setMillionVideo] = useState(false);
    const [videoLink,setVideoLink] = useState("");
    const [consentFormsData,setConsentFormsData] = useState({})
    const [professionalsData,setProfessionalsData] = useState({})
    const [treatmentsData,setTreatmentsData] = useState({})
    const [previousProfessionalData,setPreviousProfessionalData] = useState({numericValue:0})
    const [previousConsentFormsData, setPreviousConsentFormsData] = useState({numericValue:0});
    const [previousTreatmentsData, setPreviousTreatmentsData] = useState({numericValue:0});
    const [professionalCount,setProfessionalCount] = useState(0)
    const {getHomeCountQueryData, initGetHomeCountData}=GetHomeCountAction()
    const {getFaqListQueryData, initGetFaqListData} =GetFaqListAction()
    const {getTestimonialsQueryData, initGetTestimonialsData} = GetTestimonialsAction()
    const {getRatingsQueryData, initFetchRatings, loading, error}= GetRatingsAction()


    const getNumericValueAndSuffix = (value) => {
        const suffixes = ['K','M','Billion'];
        const suffix = suffixes.find((s) => value?.includes(s)) || '';
        const numericValue = parseFloat(value?.replace(suffix, ''));
        return { numericValue, suffix };
    };

    // const formatCount = (count) => {
    //     count = Number(count);
    //     if (count >= 1_000_000_000) {
    //       return Math.floor(count / 1_000_000_000) + "Billion";
    //     } else if (count >= 1_000_000) {
    //       return Math.floor(count / 1_000_000) + "M";
    //     } else if (count >= 1_000) {
    //       return Math.floor(count / 1_000) + "K";
    //     }
    //     return count?.toString();
    //   };
    
    const formatCount = (count) => {
        count = Number(count);
        
        const formatWithDecimals = (value) => value % 1 === 0 ? value.toString() : value.toFixed(1);
      
        if (count >= 1_000_000_000) {
          return formatWithDecimals(count / 1_000_000_000) + " Billion";
        } else if (count >= 1_000_000) {
          return formatWithDecimals(count / 1_000_000) + " M";
        } else if (count >= 1_000) {
          return formatWithDecimals(count / 1_000) + " K";
        }
        
        return formatWithDecimals(count); // No formatting needed if less than 1,000
      };

      
    const parseFormattedCount = (formattedCount) => {
        if (formattedCount.includes("Billion")) {
          return parseFloat(formattedCount) * 1_000_000_000;
        } else if (formattedCount.includes("M")) {
          return parseFloat(formattedCount) * 1_000_000;
        } else if (formattedCount.includes("K")) {
          return parseFloat(formattedCount) * 1_000;
        }
        return parseFloat(formattedCount);
      };

    useEffect(() => {
        // Check the initial connection status
        console.log('Socket connected:', socket.connected);

        const onConsent = (event) => {
            console.log('Socket connected after event:', socket.connected,event);
            if(event.type === "broadcast-notice"){
                console.log("socket event match",event.dataObj.total_appointment_treatments)
                if(event?.dataObj?.total_appointment_treatments){
                    let treat = event?.dataObj?.total_appointment_treatments
                    setTreatmentsData((prev) => {
                        setPreviousTreatmentsData(prev); // Use the current state
                        return getNumericValueAndSuffix(formatCount(treat));
                    });
                }
                if(event?.dataObj?.total_appointments){
                    let consent = event?.dataObj?.total_appointments
                    setConsentFormsData((prev) => {
                        setPreviousConsentFormsData(prev); // Use the current state
                        console.log("previous:", prev, "end:", formatCount(consent), "appointment socket called");
                        return getNumericValueAndSuffix(formatCount(consent));
                    });
                }
            }
            if(event.type==="new_user_registered"){
                if(event?.message){
                    let count = event?.message

                    setProfessionalsData((prev) => {
                        setPreviousProfessionalData(prev); // Use the current state
                        console.log("previous:", prev, "end:", formatCount(count), "user socket called");
                        return getNumericValueAndSuffix(formatCount(count));
                    });
                    setProfessionalCount(count);
                }
            }
        };
        const onDisconnect = (error) => {
            console.log('Socket disconnected:', socket.connected, error);
        };

        // Set up the event listeners
		socket.on('broadcast-notice', onConsent);
        socket.on('disconnect', onDisconnect);

        // Cleanup function to remove listeners
        return () => {
            socket.off('broadcast-notice', onConsent);
            socket.off('disconnect', onDisconnect);
        };
    }, []);

    const AppointmentsPointsData = [
        {
            id: '1',
            title: 'FREE Consent & Aftercare Forms',
        },
        {
            id: '2',
            title: 'Fully insured and safety-compliant for digital consent. ',
        },
        {
            id: '3',
            title: 'Pre-Appointment Consent',
        },
        {
            id: '4',
            title: 'Clients can: Book, Pay & Consent  ',
        },
        {
            id: '5',
            title: 'Free Training, Hints & Tips ',
        },
        {
            id: '6',
            title: 'Technology designed to Protect the Business & Practitioners',
        },
    ];



    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: "ease-in-out",
            offset: 200,
            once: true,
        });

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                threshold: 0.1,
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, [getHomeCountQueryData]);

    const ReviewOptions = {
        loop: true,
        items: 1,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        smartSpeed:450,
        margin: 10,
        nav: false,
        // animateOut: 'fadeOut',
      };

      const ReviewCardOptions = {
        loop: true,          
        items: 2.4,          
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        margin: 30,
        smartSpeed:450,
        nav: true,      
        responsive: {
          0: {
            items: 1.2, 
            nav: false,
            margin: 15
          },
          991: {
            items: 2.2, 
            nav: false,
            margin: 15
          },
        }
      };

      useEffect(()=>{
        initGetHomeCountData()
        initGetFaqListData()
        initGetTestimonialsData()
        initFetchRatings()
      },[])

   

      useEffect(() => {
        if (getHomeCountQueryData?.data) {
            const professionalsCount = getHomeCountQueryData.data.getHomeCount.data.Professionals || '0';
            const consentFormsCount = getHomeCountQueryData.data.getHomeCount.data.ConsentForms || '0';
            const treatmentsCount = getHomeCountQueryData.data.getHomeCount.data.Treatments || '0';
    
            setPreviousProfessionalData({ numericValue: 0, suffix: '' }); // Start from 0 initially
            setPreviousConsentFormsData({ numericValue: 0, suffix: '' });
            setPreviousTreatmentsData({ numericValue: 0, suffix: '' });
    
            setProfessionalsData(getNumericValueAndSuffix(formatCount(professionalsCount)));
            setConsentFormsData(getNumericValueAndSuffix(formatCount(consentFormsCount)));
            setTreatmentsData(getNumericValueAndSuffix(formatCount(treatmentsCount)));

            setProfessionalCount(professionalsCount)
        }
    }, [getHomeCountQueryData?.data]);
    

   console.log(isVisible,"isVisible",professionalsData,consentFormsData,treatmentsData)

   useEffect(() => {
    const currentTitle = document.title;
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (currentTitle !== "faces - Booking system and Consent forms for Aesthetic Practitioners") {
      document.title = "faces - Booking system and Consent forms for Aesthetic Practitioners";
    }
    if (descriptionMetaTag) {
        const currentDesc = descriptionMetaTag.getAttribute('content');
  
        if (currentDesc !== "Free booking system and consent form templates. Designed for aesthetic practitioners. The best booking system for salons and aesthetic clinics") {
          // Update the meta description content
          descriptionMetaTag.setAttribute('content', "Free booking system and consent form templates.Designed for aesthetic practitioners. The best booking system for salons and aesthetic clinics");
        }
      }
  }, []);
console.log("socket previous",previousProfessionalData,previousConsentFormsData.numericValue,previousTreatmentsData)
    
useEffect(() => {
    const reviewCards = document.querySelectorAll('.ReviewCard');
    let maxHeight = 0;

    // Find the max height
    reviewCards.forEach(card => {
      const cardHeight = card.offsetHeight;
      if (cardHeight > maxHeight) {
        maxHeight = cardHeight;
      }
    });

    // Apply the max height to all cards
    reviewCards.forEach(card => {
      card.style.height = `${maxHeight}px`;
    });
  }, [getTestimonialsQueryData]);

    return (
        <>
         <Helmet>
        <meta charSet="utf-8" />
        <title>faces - Booking system and Consent forms for Aesthetic Practitioners</title>
        <meta
          name="description"
          content="Free booking system and consent form templates.  Designed for aesthetic practitioners. The best booking system for salons and aesthetic clinics"
        />
      </Helmet>
        <div className="MainHomePageWrapper">
            <FacesBusinessHeader />

            <section className="bannerSec sameSpace pb-0">
                <div className="container">
                    <div className="bannerContent">
                        <h1 data-aos="fade-up" data-aos-duration="700">All-in-one <br /> Aesthetics solution</h1>
                        <p data-aos="fade-up" data-aos-duration="750">Faces helps you attract more clients, streamline bookings, manage consent <br /> forms, and purchase products to supercharge your business.</p>

                        <button className="blueBtn" data-aos="fade-up" data-aos-duration="800" onClick={() => { window.location.href = webBaseUrl + '/home' }}>Get Faces for Free</button>
                        <span data-aos="fade-up" data-aos-duration="850"> 
                            <span className="Animation">✌️</span> ️No credit card needed. Completely free! <br/>
                            Start using Faces today and receive £50 to spend in the marketplace.
                        </span>
                    </div>
                </div>
            </section>

            <section className="ReviewSec sameSpace pb-0" data-aos-anchor="#ReviewSec">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6"
                            data-aos="fade-right"
                            data-aos-anchor="#ReviewSec"
                            data-aos-offset="200"
                            data-aos-duration="200"
                        >
                            <div className="ContentWrapper centerDiv">
                                <h2 className="smallTitle">Join {professionalCount}+ professionals managing their business with Faces. </h2>
                                     <OwlCarousel className='owl-theme' {...ReviewOptions}>
                                    {getRatingsQueryData?.loading === false && getRatingsQueryData
                                        ?
                                        <>
                                        {/* <div>
                                            <div className="ReviewSecWrapper">
                                                <div className="ReviewWrapper">
                                                    <div className="leftSide">
                                                        <ul>
                                                            <li><img src={Av1} alt={Av1} /></li>
                                                            <li><img src={Av1} alt={Av1} /></li>
                                                            <li><img src={Av1} alt={Av1} /></li>
                                                            <li><img src={Av1} alt={Av1} /></li>
                                                            <li><img src={Av1} alt={Av1} /></li>
                                                        </ul>
                                                    </div>
                                                    <div className="RightSide">
                                                        <ul className="StarWrapper">
                                                        {Array.from({ length: 5 }, (_, index) => (
                                                        <li key={index}>
                                                            {index < getRatingsQueryData?.data?.getRating?.data?.trustpilot_rating ? <StarIcon /> : ""} 
                                                        </li>
                                                        ))}
                                                        </ul>
                                                        <span>{getRatingsQueryData?.data?.getRating?.data?.trustpilot_rating}</span>

                                                        <span className="Link" onClick={()=>window.open('https://uk.trustpilot.com/review/facesconsent.com',"_blank")}>“Excellent”</span>
                                                    </div>
                                                </div>

                                                <div className="ReviewContent">
                                                    <p>{getRatingsQueryData?.data?.getRating?.data?.total_trustpilot_users} customer reviews on their experiences on</p>

                                                    <TrustPilotLogo />
                                                </div>

                                            </div>
                                        </div>  */}
                                        <div>
                                            <div className="ReviewSecWrapper">
                                                <div className="ReviewWrapper">
                                                    <div className="leftSide">
                                                    <ul>
                                                        {getRatingsQueryData?.data?.getRating?.data?.google_profile_pics?.map((val, i) => (
                                                            <li key={i}>
                                                            <img src={val} alt="profile" />
                                                            </li>
                                                        ))}
                                                    </ul>

                                                    </div>
                                                    <div className="RightSide">
                                                        <ul className="StarWrapper">
                                                        {Array.from({ length: 5 }, (_, index) => (
                                                        <li key={index}>
                                                            {index < getRatingsQueryData?.data?.getRating?.data?.google_rating ? <StarIcon /> : ""} 
                                                        </li>
                                                        ))}
                                                        </ul>
                                                        <span>{getRatingsQueryData?.data?.getRating?.data?.google_rating}</span>

                                                        <span className="Link"onClick={()=>window.open("https://www.google.com/search?q=facesconsent&oq=faces","_blank")}>“Excellent”</span>
                                                    </div>
                                                </div>

                                                <div className="ReviewContent">
                                                    <p>{getRatingsQueryData?.data?.getRating?.data?.total_google_users} customer reviews on their experiences on</p>

                                                    <img src={googleLogo} alt={googleLogo} />
                                                </div>

                                            </div>
                                        </div>
                                        <div>
                                            <div className="ReviewSecWrapper">
                                                <div className="ReviewWrapper">
                                                    <div className="leftSide">
                                                        <ul>
                                                            {getRatingsQueryData?.data?.getRating?.data?.google_profile_pics?.map((val, i) => (
                                                                <li key={i}>
                                                                <img src={val} alt="profile" />
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className="RightSide">
                                                        <ul className="StarWrapper">
                                                        {Array.from({ length: 5 }, (_, index) => (
                                                        <li key={index}>
                                                            {index < getRatingsQueryData?.data?.getRating?.data?.google_rating ? <StarIcon /> : ""} 
                                                        </li>
                                                        ))}
                                                        </ul>
                                                        <span>{getRatingsQueryData?.data?.getRating?.data?.google_rating}</span>

                                                        <span className="Link"onClick={()=>window.open("https://www.google.com/search?q=facesconsent&oq=faces","_blank")}>“Excellent”</span>
                                                    </div>
                                                </div>

                                                <div className="ReviewContent">
                                                    <p>{getRatingsQueryData?.data?.getRating?.data?.total_google_users} customer reviews on their experiences on</p>

                                                    <img src={googleLogo} alt={googleLogo} />
                                                </div>

                                            </div>
                                        </div>
                                        </>
                                        :
                                        <SkeletonLoader type="google-Rating-Skeleton"/>
                                       
                                    }
                                    </OwlCarousel>
                            </div>
                           
                        </div>
                        <div className="col-sm-12 col-md-6"
                            data-aos="fade-left"
                            data-aos-anchor="#ReviewSec"
                            data-aos-offset="250"
                            data-aos-duration="250"
                        >
                            <div className="ImageShape">
                                <img src={ReviewImage} alt={ReviewImage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

         
            <section className="counterSec pb-5 sameSpace" id="counterSec" ref={sectionRef}>
                <div className="container">
                { getHomeCountQueryData?.loading === false && getHomeCountQueryData
                    ?
                    <div className="counterGridWrapper">
                    <div
                        className="counterItem"
                        data-aos="fade-up"
                        data-aos-anchor="#counterSec"
                        data-aos-offset="300"
                        data-aos-duration="300"
                    >
                        <h5> {isVisible && <><CountUp start={previousProfessionalData.numericValue} end={professionalsData.numericValue} duration={5} decimals={professionalsData.numericValue % 1 === 0 ? 0 : 1}  />{professionalsData.suffix}</>}</h5>
                        <p>Professionals trust Faces</p>
                    </div>
                    <div
                        className="counterItem"
                        data-aos="fade-up"
                        data-aos-anchor="#counterSec"
                        data-aos-offset="350"
                        data-aos-duration="350"
                    >
                        <h5> {isVisible && <><CountUp start={previousConsentFormsData.numericValue} end={consentFormsData.numericValue} duration={5}  decimals={consentFormsData.numericValue % 1 === 0 ? 0 : 1}  />{consentFormsData.suffix}</>}</h5>
                        <p>Consent forms created</p>
                    </div>
                    <div
                        className="counterItem"
                        data-aos="fade-up"
                        data-aos-anchor="#counterSec"
                        data-aos-offset="200"
                        data-aos-duration="250"
                    >
                       <h5> {isVisible && <><CountUp start={previousConsentFormsData.numericValue} end={treatmentsData.numericValue} duration={5} decimals={treatmentsData.numericValue % 1 === 0 ? 0 : 1} />{treatmentsData.suffix} </> }</h5>
                        <p>Treatments processed</p>
                    </div>
                    </div>
                    :
                    <Skeleton className="counterGridWrapper" width={922} height={151}/>
                   
                } 
                </div>
            </section>
          
            <section className="FormsSec pt-0 sameSpace" id="FormsSec">
                <div className="container">
                    <div className="FormsWrapper">
                        <div className="FormsContentWrapper">
                            <h2
                                data-aos="fade-up"
                                data-aos-anchor="#FormsSec"
                                data-aos-offset="200"
                                data-aos-duration="200">10 Million Consent Forms Completed</h2>
                            <p
                                data-aos="fade-up"
                                data-aos-anchor="#FormsSec"
                                data-aos-offset="250"
                                data-aos-duration="250">Faces has supported businesses in completing 10 million consent forms, increasing bookings and driving business growth.</p>
                        </div>
                        <div className="FormsContentBottom">
                            <div className="leftSide"  
                                data-aos="fade-up"
                                data-aos-anchor="#FormsSec"
                                data-aos-offset="300"
                                data-aos-duration="300">
                                <div className="iconPlay" onClick={(e)=>{setMillionVideo(true)}}>
                                    <PlayIcon />
                                </div>
                                <span>
                                    See a demo
                                </span>
                            </div>
                            <div className="RightSide"  
                                data-aos="fade-up"
                                data-aos-anchor="#FormsSec"
                                data-aos-offset="350"
                                data-aos-duration="350">
                                <p> <span className="firstLatter">1</span><span className="SecondLatter">0</span>m!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           

            <section className="logoSecWrapper pt-0 sameSpace" id="logoSecWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4">
                            <div className="logoContentWrapper"
                                data-aos="fade-right"
                                data-aos-anchor="#logoSecWrapper"
                                data-aos-offset="200"
                                data-aos-duration="200">
                                <h2>Featured in &
                                    trusted by</h2>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <Marquee
                                direction={"left"}
                                delay={3}
                            >
                                <div className="logoGridWrapper">
                                    <div className="logoItem"
                                        data-aos="fade-up"
                                        data-aos-anchor="#logoSecWrapper"
                                        data-aos-offset="250"
                                        data-aos-duration="250"
                                    >
                                        <img src={LogoPharmacyImg} alt={LogoPharmacyImg} />
                                    </div>
                                    <div className="logoItem"
                                        data-aos="fade-up"
                                        data-aos-anchor="#logoSecWrapper"
                                        data-aos-offset="300"
                                        data-aos-duration="300"
                                    >
                                        <img src={LogoInsyncImg} alt={LogoInsyncImg} />
                                    </div>
                                    <div className="logoItem"
                                        data-aos="fade-up"
                                        data-aos-anchor="#logoSecWrapper"
                                        data-aos-offset="310"
                                        data-aos-duration="310"
                                    >
                                        <img src={LogoPayl8rImg} alt={LogoPayl8rImg} />
                                    </div>
                                    <div className="logoItem"
                                        data-aos="fade-up"
                                        data-aos-anchor="#logoSecWrapper"
                                        data-aos-offset="320"
                                        data-aos-duration="320"
                                    >
                                        <img src={LogoPolicyBeeImg} alt={LogoPolicyBeeImg} />
                                    </div>
                                    <div className="logoItem"
                                        data-aos="fade-up"
                                        data-aos-anchor="#logoSecWrapper"
                                        data-aos-offset="330"
                                        data-aos-duration="330"
                                    >
                                        <img src={LogoAestheticImg} alt={LogoAestheticImg} />
                                    </div>
                                </div>
                            </Marquee>

                        </div>
                    </div>
                </div>
            </section>

            <section className="LeftRightSecWrapper pt-0 sameSpace" id="LeftRightOne">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6"
                            data-aos="fade-right"
                            data-aos-anchor="#LeftRightOne"
                            data-aos-offset="200"
                            data-aos-duration="200">
                            <div className="ImageShape">
                                <img src={ManageImage} alt={ManageImage} />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6"
                            data-aos="fade-left"
                            data-aos-anchor="#LeftRightOne"
                            data-aos-offset="250"
                            data-aos-duration="250">
                            <div className="ContentWrapper centerDiv">
                                <h2 className="smallTitle">Manage Your Appointments</h2>
                                <p>Manage, Track & Charge Clients, all bookings in one place, freeing up more time for your clients.</p>

                                <button className="whiteBtn" onClick={() => { window.location.href = webBaseUrl + '/login' }}>Manage Bookings</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="LeftRightSecWrapper pt-0 sameSpace" id="LeftRightTwo">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6"
                            data-aos="fade-right"
                            data-aos-anchor="#LeftRightTwo"
                            data-aos-offset="200"
                            data-aos-duration="200">
                            <div className="ContentWrapper centerDiv">
                                <h2 className="smallTitle">Paperless Consent Forms</h2>
                                <p>Over 200+ available insurance-verified customisable consent forms integrated within faces booking system</p>

                                <button className="whiteBtn" onClick={() => { window.location.href = webBaseUrl + '/consent-forms' }}>View Consent Forms</button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6" data-aos="fade-left"
                            data-aos-anchor="#LeftRightTwo"
                            data-aos-offset="250"
                            data-aos-duration="250">
                            <div className="ImageShape">
                                <img src={PaperlessImage} alt={PaperlessImage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="LeftRightSecWrapper pt-0 sameSpace" id="LeftRightThree">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6"
                            data-aos="fade-right"
                            data-aos-anchor="#LeftRightThree"
                            data-aos-offset="200"
                            data-aos-duration="200">
                            <div className="ImageShape">
                                <img src={CreatePImage} alt={CreatePImage} />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6"
                            data-aos="fade-left"
                            data-aos-anchor="#LeftRightThree"
                            data-aos-offset="250"
                            data-aos-duration="250"
                        >
                            <div className="ContentWrapper centerDiv">
                                <h2 className="smallTitle">Create Prescriptions & Shop Products</h2>
                                <p>Find prescribers and shop from 100+ pharmacies and beauty wholesalers for the best products at the best prices.</p>

                                <button className="whiteBtn" onClick={() => { window.location.href = webBaseUrl + '/shop' }}>Purchase Products</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="marqueeSecWrapper pt-0 sameSpace">
                <Marquee
                    direction={"left"}
                    delay={2}
                >
                    <div className="MarqueeGrid">
                        <ul>
                            <li>
                                <div className="icon"><ConsentIcon /></div>
                                CONSENT FORMS
                            </li>
                            <li>
                                <div className="icon"><BookingIcon /></div>
                                BOOKING SYSTEM
                            </li>
                            <li>
                                <div className="icon"><WebIcon /></div>
                                TEAM MEMBERS
                            </li>
                            <li>
                                <div className="icon"><TeamIcon /></div>
                                BUSINESS STARS
                            </li>
                        </ul>
                    </div>
                </Marquee>
                <Marquee
                    direction={"right"}
                    delay={2}
                >
                    <div className="MarqueeGrid">
                        <ul>
                            <li>
                                <div className="icon"><ConsentIcon /></div>
                                CONSENT FORMS
                            </li>
                            <li>
                                <div className="icon"><BookingIcon /></div>
                                FREE WEBSITE
                            </li>
                            <li>
                                <div className="icon"><WebIcon /></div>
                                TEAM MEMBERS
                            </li>
                            <li>
                                <div className="icon"><TeamIcon /></div>
                                BUSINESS STARS
                            </li>
                        </ul>
                    </div>
                </Marquee>
            </section>

            <section className="LeftRightSecWrapper pt-0 sameSpace" id="LeftRightFour">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6"
                            data-aos="fade-right"
                            data-aos-anchor="#LeftRightFour"
                            data-aos-offset="200"
                            data-aos-duration="200">
                            <div className="ImageShape">
                                <img src={OneAccountImage} alt={OneAccountImage} />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6"
                            data-aos="fade-left"
                            data-aos-anchor="#LeftRightFour"
                            data-aos-offset="250"
                            data-aos-duration="250"
                        >
                            <div className="ContentWrapper centerDiv">
                                <h2 className="smallTitle">Join & Manage Teams </h2>
                                <p>Join teams, work anywhere, and collaborate while staying in control. Your business! Your way!</p>

                                <button className="whiteBtn" onClick={() => { window.location.href = webBaseUrl + '/login' }}>Manage Teams</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="EffortlesslySec pt-0 sameSpace" id="EffortlesslySec">
                <div className="container">
                    <div className="EffortWrapper">
                        <div className="EffortWrapperContent">
                            <h2
                                data-aos="fade-up"
                                data-aos-anchor="#EffortlesslySec"
                                data-aos-offset="200"
                                data-aos-duration="200"
                            >Manage APPOINTMENTS & Consent Forms  </h2>
                            <p
                                data-aos="fade-up"
                                data-aos-anchor="#EffortlesslySec"
                                data-aos-offset="250"
                                data-aos-duration="250">Store unlimited forms, oversee multiple clinics, manage real-time bookings, and access industry services—all in one platform to streamline your business.</p>
                        </div>

                        <ul>
                            {AppointmentsPointsData?.map((item, index) => {
                                return (
                                    <li
                                        data-aos="fade-up"
                                        data-aos-anchor="#EffortlesslySec"
                                        data-aos-offset={250 + index * 100}
                                        data-aos-duration={250 + index * 100}
                                    ><span className="IconLi"><CheckIcon /></span> {item?.title} </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </section>

            <section className="LeftRightSecWrapper pt-0 sameSpace" id="LeftRightFive">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6"
                            data-aos="fade-right"
                            data-aos-anchor="#LeftRightFive"
                            data-aos-offset="200"
                            data-aos-duration="200">
                            <div className="ContentWrapper centerDiv">
                                <h2 className="smallTitle">Marketing Tools </h2>
                                <p>Expand and retain your clients with free marketing tools designed to grow your business</p>

                                <button className="whiteBtn" onClick={() => { window.location.href = webBaseUrl + '/login' }}>Boost Your Business</button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6"
                            data-aos="fade-left"
                            data-aos-anchor="#LeftRightFive"
                            data-aos-offset="250"
                            data-aos-duration="250"
                        >
                            <div className="ImageShape">
                                <img src={MarketingImage} alt={MarketingImage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="LeftRightSecWrapper pt-0 sameSpace" id="LeftRightSix">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6"
                            data-aos="fade-right"
                            data-aos-anchor="#LeftRightSix"
                            data-aos-offset="200"
                            data-aos-duration="200">
                            <div className="ImageShape">
                                <img src={DiscountMockImage} alt={DiscountMockImage} />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6"
                            data-aos="fade-left"
                            data-aos-anchor="#LeftRightSix"
                            data-aos-offset="250"
                            data-aos-duration="250"
                        >
                            <div className="ContentWrapper centerDiv">
                                <h2 className="smallTitle">Offer Flexible Payments </h2>
                                <p>Let clients pay in installments while you get paid upfront—no extra cost to you.</p>

                                <button className="whiteBtn" onClick={() => { window.location.href = webBaseUrl + '/login' }}>Offer Finance Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="PointsWrapper pt-0 sameSpace" id="PointsWrapper">
                <div className="container">

                    <div className="PointsGridWrapper">
                        <div className="PointsItem"
                            data-aos="fade-up"
                            data-aos-anchor="#PointsWrapper"
                            data-aos-offset="200"
                            data-aos-duration="200">
                            <div className="PointsIcon">
                                <MHRAIcon />
                            </div>
                            <p>Regulated by MHRA and a verified licensed broker</p>
                        </div>
                        <div className="PointsItem"
                            data-aos="fade-up"
                            data-aos-anchor="#PointsWrapper"
                            data-aos-offset="250"
                            data-aos-duration="250">
                            <div className="PointsIcon">
                                <GoogleIcon />
                            </div>
                            <p>Rated 5.0 stars on Google and 4.9 on Trustpilot</p>
                        </div>
                        <div className="PointsItem"
                            data-aos="fade-up"
                            data-aos-anchor="#PointsWrapper"
                            data-aos-offset="300"
                            data-aos-duration="300"
                        >
                            <div className="PointsIcon">
                                <DesignedIcon />
                            </div>
                            <p>Designed to help you run your aesthetics business efficiently</p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="TabImageSec pt-0 sameSpace" id="TabImageSec">
                <div className="container">
                    <div className="TabDesktopWrapper" style={{ backgroundImage: `url(${BackImage})` }}
                        data-aos="fade-up"
                        data-aos-anchor="#TabImageSec"
                        data-aos-offset="200"
                        data-aos-duration="200"
                    >
                        <img src={TabDesktopImage} alt={TabDesktopImage} />
                    </div>
                </div>
            </section>

        

            { getTestimonialsQueryData?.data &&
            <section className="ReviewSliderSec pt-0 sameSpace">
                <OwlCarousel className='owl-theme' {...ReviewCardOptions}
                   
                >
                    {getTestimonialsQueryData?.data?.getListOfTestimonials?.data?.TestimonialsList?.map((item, index) => {
                        return (
                            <div>
                                <div className="ReviewCard">
                                    <div className="ReviewCardImage">
                                        <div className="ReviewImage">
                                            <div className="StaticImg">
                                                <img src={placeholderThumbnail} alt={placeholderThumbnail} />
                                            </div>
                                            <div className="DynamicImg">
                                                <img src={item?.image} alt={item?.image} />
                                            </div>
                                        </div>
                                        <div className="youtubeTextWrap">
                                            <div className="youtubeText" onClick={(e)=>{ setVideoLink(item?.video);setIsyouTube(true)}}>
                                                <YoutubeIcon />
                                                <span> Play Video </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ReviewCardContent">
                                        <div className="ReviewCardFirstContent">
                                            <div className="startWrapper">
                                                <ul>
                                                {Array.from({ length: 5 }, (_, index) => (
                                                        <li key={index}>
                                                            {index < item?.rating ? <OrangeStarIcon /> : ""} 
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <h5>{item?.review}</h5>
                                        </div>

                                        <div className="nameWrapper">
                                            <h6>{item?.name}</h6>

                                            <div className="socialWrapper">
                                                <div className="socialItem" onClick={() => window.open(item?.igUrl, '_blank')}>
                                                    <FaInstagram />
                                                </div>
                                                {/* <div className="socialItem" onClick={() => window.open(item?.linkedinUrl,'_blank')}>
                                                    <FaLinkedinIn />
                                                </div> */}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </OwlCarousel>
            </section>
            }

            <section className="FaqSec sameSpace" id="FaqSec">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="faqContent">
                                <h2 className="smallTitle"
                                    data-aos="fade-up"
                                    data-aos-anchor="#FaqSec"
                                    data-aos-offset="200"
                                    data-aos-duration="200">FAQs</h2>
                                <p
                                    data-aos="fade-up"
                                    data-aos-anchor="#FaqSec"
                                    data-aos-offset="250"
                                    data-aos-duration="250">All your questions answered.</p>
                                <button className="whiteBtn"
                                    data-aos="fade-up"
                                    data-aos-anchor="#FaqSec"
                                    data-aos-offset="300"
                                    data-aos-duration="300"
                                    onClick={() => { window.location.href = webBaseUrl + '/shop-faqs' }}
                                    >View all FAQs</button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="faqWrapper"  
                            data-aos="fade-up"
                            data-aos-anchor="#FaqSec"
                            data-aos-offset={200} 
                            data-aos-duration={250} >
                                <Accordion defaultActiveKey={getFaqListQueryData?.data?.getListOfFaq?.data?.FaqList?.[0]}>
                                    {getFaqListQueryData?.data?.getListOfFaq?.data?.FaqList?.map((item, index) => {
                                        return (
                                            <Accordion.Item
                                                key={item?.id}
                                                eventKey={item?.id}                                               
                                            >
                                                <Accordion.Header>
                                                    {item?.question}

                                                    <span className="ArrowIcon"><FaChevronUp /></span>
                                                </Accordion.Header>
                                                <Accordion.Body>{item?.answer}</Accordion.Body>
                                            </Accordion.Item>
                                        );
                                    })}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ManageSec" id="ManageSec">
                <div className="container">
                    <div className="ManageContentWrapper">
                        <h5 className="smallTitle"
                            data-aos="fade-up"
                            data-aos-anchor="#ManageSec"
                            data-aos-offset={200}
                            data-aos-duration={250}
                        >Manage Your Aesthetics Business on the Go</h5>
                        <p
                            data-aos="fade-up"
                            data-aos-anchor="#ManageSec"
                            data-aos-offset={250}
                            data-aos-duration={300} >Never miss an appointment and enjoy seamless booking management. Download Faces from the App Store and Google Play for free, and keep your business running smoothly from anywhere.</p>

                        <div className="playStoreBtnWrapper">
                            <button className="playStoreBtn whiteBtn"
                                data-aos="fade-up"
                                data-aos-anchor="#ManageSec"
                                data-aos-offset={300}
                                data-aos-duration={350}
                                onClick={() => {
                                    window.open(`${config.APP_STORE_LINK}`, '_blank');
                                }}
                            >
                                <AppleIcon /> Apple store
                            </button>
                            <button className="playStoreBtn whiteBtn"
                                data-aos="fade-up"
                                data-aos-anchor="#ManageSec"
                                data-aos-offset={200}
                                data-aos-duration={250}
                                onClick={() => {
                                    window.open(`${config.PLAY_STORE_LINK}`, '_blank');
                                }}
                            >
                                <img src={PlayStoreIcon} alt={PlayStoreIcon} /> Play Store
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <div className="etaSec">
                <div className="container">
                    <div className="etaSecWrapper">
                        <div className="rightAssets">
                            <img src={LineAssets} alt={LineAssets} />
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="etaContent">
                                    <div className="etaContentWrapper">
                                        <h5 className="smallTitle">Spend time where it matters. Start using Faces today.</h5>
                                        <p>Start using Faces today and <b>receive £50</b> to spend in the marketplace.</p>
                                    </div>

                                    <div className="playStoreBtnWrapper">
                                        <button className="playStoreBtn" onClick={() => {
                                            window.open(`${config.APP_STORE_LINK}`, '_blank');
                                        }}>
                                            <AppStoreIcon />
                                        </button>
                                        <button className="playStoreBtn" onClick={() => {
                                            window.open(`${config.PLAY_STORE_LINK}`, '_blank');
                                        }}>
                                            <GooglePlayIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="etaImage">
                                    <img src={EtaImage} alt={EtaImage} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <FacesBusinessFooter />
        </div>

            <CustomModal
            modalOpen={isyouTube}
            setModalOpen={setIsyouTube}
            type={"review"}
            className="YouTubeModel"
            modalBody={
            <iframe
                width="560"
                height="315"
                src= {videoLink}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            ></iframe>
            }
            />
            <CustomModal
            modalOpen={millionVideo}
            setModalOpen={setMillionVideo}
            type={"review"}
            className="YouTubeModel"
            modalBody={
            <iframe
                width="560"
                height="315"
                src= "https://www.youtube.com/embed/dPJrIT4VIkw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            ></iframe>
            }
            />
        </>
    )
}

export default FacesBusinessHome;
