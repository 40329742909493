import React , {useEffect, useState} from 'react'
import CustomRadioButton from '../../common/CustomRadioButton'
import removeIcon from "../../../images/product-question/remove.svg"
import disabledRemoveIcon from "../../../images/product-question/disabled-remove.svg"
import uploadPlus from "../../../images/product-question/upload-plus.svg"

const MultiQuestions = ({
  queNo,
  quesId,
  question,
  handleRemoveQuestion,
  handleChange,
  handleOptionChange,
  handleOptionRadioChange,
  handleSubmit,
  error,
  answerEor
}) => {

  const [flag, setFlag] = useState(false);

  const handleAddOption = ()=>{
     const a ={
          optionId:null,
          answerText:"",
          isCorrect:false
     }
     question.answerOptions.push(a)
     setFlag(!flag)
  }
  console.log("ques2222",question)

  const handleRemoveOption = (index)=>{
    question.answerOptions.splice(index, 1)
    setFlag(!flag)
  }
  useEffect(()=>{
   },[flag])

   
 
  return (
    <>
        <div className='questionWrapper'> 

            <div className="InputWrapper">
        <label>{queNo} Question : </label>
        <div class="InputField">
          <input
            type="text"
            className="form-control"
            name={`questions[${quesId}]`}
            value={question.questionText}
            onChange={(e) => {
              handleChange(quesId, e.target.value);
            }}
          />
          <p className="error">{error}</p>
        </div>
           </div>

           <div className='InputWrapper'>  
              <label>Ans : </label>
                <div className='AnsInputGridWrapper'>
                {
                  console.log("question?.answerOptions",question?.answerOptions)
                }
                {
                  question?.answerOptions?.map((op, index)=>(
                    
                    <div className="AnsInputGrid">
                   <div className="AnsInputItem">
                   <div>( {String.fromCharCode(65 + index)} )</div>
              </div>
              <div className="AnsInputErrorWrapper">
                <div className="AnsInnerInputGridWrapper">
                  <div class="InputField">
                    <input
                      type="text"
                      className="form-control"
                      name={`option1${quesId}`}
                      value={op.answerText}
                      onChange={(e) => {
                        handleOptionChange(quesId, index, e.target.value);
                      }}
                    />
                  </div>
                  <div className="AnsInputItem">
                    <div className="form-check">
                      <label>
                        <CustomRadioButton
                          type="radio"
                          name={"multiOp"}
                          value={1}
                          checked={op.isCorrect == true ? true : false}
                          onChange={() => {
                            handleOptionRadioChange(queNo - 1, quesId, index);
                          }}
                        />
                      </label>
                    </div>
                  </div>

                  <div className="AnsInputItem" >
                      <i class={`fa-regular fa-circle-xmark ${
                        question.answerOptions?.length == 2
                          ? "pe-none"
                          : "cursor-pointer"
                      }`} onClick={() => handleRemoveOption(index)} />
                    {/* <img
                      src={
                        question.answerOptions?.length == 2
                          ? disabledRemoveIcon
                          : removeIcon
                      }
                      className={`fa fa-circle-plus remove-icon-image ${
                        question.answerOptions?.length == 2
                          ? "pe-none"
                          : "cursor-pointer"
                      }`}
                      onClick={() => handleRemoveOption(index)}
                    /> */}
                  </div>
                </div>
                <p className="error">{answerEor}</p>
              </div>
                </div>
                  ))
                
                }
          
                </div>
           </div>

           <div className="add-question">
        <p
          className={`${question?.answerOptions?.length == 4 && "disabled"}`}
          onClick={() => handleAddOption()}
        >
          + Add Option
        </p>
            </div>

            {/* <div className="remove-new-module-btn">
          <p
            onClick={() => {
              handleRemoveQuestion(quesId);
            }}
          >
            -  Update Question 
          </p>
             </div> */}
        </div>
    </>
  )
}

export default MultiQuestions