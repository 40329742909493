import React from 'react'
import Skeleton from 'react-loading-skeleton'
import "./treatment-detail-skeleton.scss"

const TreatmentDetailsSkeleton = () => {
    return (
        <div className='treatment-details-skeleton-main-div '>
            <div className="row flex-768-column">
                <div className="col-md-6">

                    <div className="treatment-photo-web-section">
                        <div className="slider-web-container">
                            <Skeleton height={"500px"} width={"100%"} />
                        </div>

                    </div>
                </div>
                <div className="col-md-6">

                    <div className="treatment-web-content-section">
                        <Skeleton className="mb-2" height={26} />
                        <Skeleton className="mb-2" height={22} />
                        <Skeleton className="mb-3" height={120} />
                    </div>
                    <div>
                        <Skeleton className="mt-5" height={40} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TreatmentDetailsSkeleton