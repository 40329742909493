// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_SOCIAL_MEDIA_MARKETING_PLANS_QUERY = gql`
query GetPlumpMarketingPackages($baseUrl: String) {
    getPlumpMarketingPackages(baseUrl: $baseUrl) {
      success
      message
      data {
        packages {
          id
          package_name
          amount
          amountText
          duration_text
          commitment_text
          package_main_points
          package_points
          can_subscribe
          url
          is_popular
          commitment_text2
          image_url
          is_currentplan
          is_upgradable
          is_book_call
        }
        calandlyLink
      }
    }
  }
`;

export const GetPlumpMarketingPackagesAction = () => {
    // const dispatch = useDispatch();
    const [
        getPlumpMarketingPackagesResponseFunction,
        getPlumpMarketingPackagesResponseData,
    ] = useLazyQuery(GET_SOCIAL_MEDIA_MARKETING_PLANS_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getPlumpMarketingPackages: responseData }) => {
            if (responseData.success) {
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getPlumpMarketingPackagesQueryData = getPlumpMarketingPackagesResponseData; //variable

    const initGetPlumpMarketingPackagesData = (data) => {
        getPlumpMarketingPackagesResponseFunction({
            variables: data,
        });
    };

    return { getPlumpMarketingPackagesQueryData, initGetPlumpMarketingPackagesData };
};
