import React, { useEffect } from "react";
import 'aos/dist/aos.css';
import AOS from 'aos';

const ImageTitleComponent = ({ imageUrl, title, description }) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease',
    });
    return () => {
      AOS.refresh();
    };
  }, []); 

  return (
    <div className="headerWrapper" data-aos="fade-up" data-aos-duration={500}>
      <img src={imageUrl}/>
      <h1 className="stepH1" >{title}</h1>
      <p className="stepDesc">{description}</p>
    </div>
  );
};

export default ImageTitleComponent;
