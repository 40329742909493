import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import CustomModal from "../CustomModal";
import StepButton from "../../common/StepButton";
import "./PatientDobWarningModal.scss";

const PatientDobWarningModal = forwardRef(({onAgreeWarning, isForPractitioner}, ref) => {   
  const [showModal, setShowModal] = useState(false);

  const unsuitableTreatments = [
    "Botox and Dermal Fillers",
    "Tattooing",
    "Certain Body Piercing",
    "Laser Hair Removal",
    "Chemical Peels",
    "Microdermabrasion",
    "Permanent Makeup (Micro-pigmentation)",
    "Surgical Cosmetic Procedures",
    "Tanning Beds",
    "Teeth Whitening Treatments",
  ];

  const showDobWarning = () => {
    setShowModal(true);
  }

  // augment the ref by adding the clearText function
  useImperativeHandle(ref, () => ({
    showDobWarning
  }));

  return (
    <>
      {/* Patient DOB Warning popup */}
      <CustomModal
        type={"common-width"}
        className={"PatientDobWarningModal"}
        portalClassName={`PatientDobWarningModalPortal`}
        modalBody={
          <>
            <div className="WarningModalBody">
              <h1>Please note</h1>
              <p>Following list of treatments are not suitable for under 18 years old, and {isForPractitioner ? "you should refuse to carry out such treatment" : "practitioner will refuse to carry out such treatment"}</p>
              <ul>
                  {unsuitableTreatments.map((item) => <li>{item}</li>)}
              </ul>
              <div className="WarningBtnWrapper">
                  <StepButton
                    blue={true}
                    label={"Continue"}
                    onClick={() => {
                        setShowModal(false);
                        onAgreeWarning();
                    }}
                  />
                  <StepButton
                    gray={true}
                    onClick={() => {
                      setShowModal(false);
                    }}
                    label={"Cancel"}
                  />
              </div>
            </div>
          </>
        }
        modalOpen={showModal}
        setModalOpen={(updatedModalStatus) => {
            setShowModal(updatedModalStatus);
        }}
      />
    </>
  );
});

export default PatientDobWarningModal;
