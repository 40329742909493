import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import {useDispatch} from "react-redux"
import {GET_NOTIFICATION_LIST_DATA} from "../../constants"


const GET_NOTIFICATION_DATA_QUERY=gql`
query GetNotifications($page: Int, $limit: Int) {
    getNotifications(page: $page, limit: $limit) {
      notifications {
        id
        profile_picture
        title
        description
        name
        type
        is_btn
        btn_text
        btn_url
        btn_screen
        time
        is_read
      }
      totalRecords
      totalPages
      currentPage
    }
  }
`;

export const GetNotificationDataAction=()=>{
    const dispatch=useDispatch();
    const [getNotificationResponseFunction,
           getNotificationResponseData]=useLazyQuery(GET_NOTIFICATION_DATA_QUERY,{
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted:({getNotifications:responseData})=>{
                console.log(responseData,"5555698")
                 if(responseData){
                    console.log("444444",responseData)
                     dispatch({
                        type:GET_NOTIFICATION_LIST_DATA,
                        payload:responseData
                     })
                 }else{

                 }
            }
           });

    const getNotificationQueryData=getNotificationResponseData;

    const initGetNotificationActionData=(data, successCallback, errorCallback)=>{
        getNotificationResponseFunction({
            variables:data
        }).then((res)=>{
            console.log("res768964",res)
            if(data?.getNotifications?.notifications){
                successCallback(res)
            }else{
                errorCallback(res)
            }
        }).catch((error)=>{
            errorCallback(error)
        })
    };

    return {getNotificationQueryData,initGetNotificationActionData}
}