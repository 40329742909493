import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { GET_PRESCRIPTIONS_LIST } from "../../constants/getPrescriptionsListConst";

const GET_LIST_OF_PENDING_PRESCRIPTIONS_QUERY = gql`
query GetPendingPrescriptionList($page: Int, $limit: Int, $prescriberId: Int, $clientId: Int) {
  getPendingPrescriptionList(page: $page, limit: $limit, prescriberId: $prescriberId, clientId: $clientId) {
    success
    message
    data {
      prescriptions {
        prescriptionId
        pharmacyName
        pharmacyCount
        productDetails {
          productName
          productQuantity
        }
        date
        isConsentFormAvailable
        isMedicalFormAttached
      }
      totalCount
    }
  }
}
`;

export const GetListOfPendingPrescriptionsAction = () => {
  const dispatch = useDispatch();
  const [
    GetListOfPendingPrescriptionsResponseFunction,
    getListOfPendingPrescriptionsResponseData,
  ] = useLazyQuery(GET_LIST_OF_PENDING_PRESCRIPTIONS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getPendingPrescriptionList: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: GET_PRESCRIPTIONS_LIST,
          payload: responseData?.data,
          // page
        });
      } else {
      }
    },
  });
  //this will be called from components
  const getListOfPendingPrescriptionsQueryData = getListOfPendingPrescriptionsResponseData; //variable

  const initGetListOfPendingPrescriptionsData = (data) => {
    GetListOfPendingPrescriptionsResponseFunction({
      variables: data,
    });
  };

  return { getListOfPendingPrescriptionsQueryData, initGetListOfPendingPrescriptionsData };
};