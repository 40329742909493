// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_FAQS_QUERY = gql`
query GetAccountancyFaqList {
  getAccountancyFaqList {
    success
    message
    data {
      faq {
        id
        question
        answer
      }
    }
  }
}
`;

export const GetAccountancyFaqListAction = () => {
  // const dispatch = useDispatch();
  const [
    getAccountancyFaqListResponseFunction,
    getAccountancyFaqListResponseData,
  ] = useLazyQuery(GET_FAQS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getAccountancyFaqList: responseData }) => {
      if (responseData.success) {
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getAccountancyFaqListQueryData = getAccountancyFaqListResponseData; //variable

  const initGetAccountancyFaqListData = (data) => {
    getAccountancyFaqListResponseFunction({
      variables: data,
    });
  };

  return { getAccountancyFaqListQueryData, initGetAccountancyFaqListData };
};
