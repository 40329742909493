import React, { useEffect, useRef, useState } from 'react';
// import { ReactComponent as BackArrow } from '../../../images/back_arrow.svg';
// import { ReactComponent as Uploads } from '../../../../images/icons/Image.svg';
import { ReactComponent as Uploads } from '../../../../images/finance/docDropzoneIcon.svg';
import './finance-insurance.scss';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router';
// import shieldIcon from '../../../images/starinshield.svg'; need
import shieldIcon from '../../../../images/finance/actions.svg';
import calander from '../../../../images/calander-plus.svg';
import nextIcon from '../../../../images/calander_next.svg';
import CustomCalendar from '../../../pages/dateTime/steps/CustomCalendar';
// import CustomCalendar2 from '../dateTime/steps/CustomCalendar';
import moment from 'moment';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
// import CustomModal2 from '../../modal/CustomModal';
import CustomModal from '../../../modal/CustomModal';
import ConfirmationModal from './finance-confirmationModal';
import FinanceBanner from './FinanceBanner';
// import FinanceHeader from '../../finance-registration/FinanceHomeComponent/FinanceHeaderComponent/FinanceHeaderComponent';
import { IoEyeSharp } from 'react-icons/io5';
import StepButton from './StepButton';
// import { SaveFinanceInsuranceDetailsAction } from '../../redux/actions/finance-registrtion/saveFinanceInsurancedetails';
// import { GetFinanceInsuranceDetailAction } from '../../redux/actions/finance-registrtion/getFinanceInsurancedetail';
// import { DeleteFinanceInsurancedetailAction } from '../../redux/actions/finance-registrtion/deleteFinanceInsurancedetail';
import { SaveFinanceInsuranceDetailsAction } from '../../../redux/actions/financeRegistrationAction/saveInsuranceDetailsForFinance';
import { GetFinanceInsuranceDetailAction } from '../../../redux/actions/financeRegistrationAction/getInsuranceDetailsForFinance';
import { DeleteFinanceInsurancedetailAction } from '../../../redux/actions/financeRegistrationAction/deleteInsuranceDocumentForFinanceId';
import Dropzone from './DropZone';
import TextInput from './TextInput';
import { insuranceLandingPage } from '../../../../Routes';
import CustomDropdown from '../../revenue-dashboard/CustomDropdown/CustomDropdown';
import { toast } from 'react-toastify';
import { GetInsuranceProviderList } from '../../../redux/actions/pharmecy-registration/insuranceProviderList';

export default function FinanceInsurance({
  onNextStep,
  afterSubmit,
  type = 'insurance',
  parentModal,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const insuranceProvider = ['Sole trader', 'Limited Company', 'Others'];
  const [insuranceProviderName, setInsuranceProviderName] = useState({
    insuranceCompnay: '',
    otherInsuranceCompnay: '',
  });
  const [previewImg, setPreviewImg] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const {data:insuranceOptionsData, initGetInsuranceProviderList} = GetInsuranceProviderList();
  const [insuranceOption,setInsuranceOption] = useState(null);
  const [otherDocs, setOtherDocs] = useState([
    { image: '', document_url: '', document_title: '' },
    // { image: '', document_url: '', document_title: '' },
    // { image: '', document_url: '', document_title: '' },
  ]);
  const [insurancePolicyNumber, setInsurancePolicyNumber] = useState('');
  const [getApiData, setGetApiData] = useState([]);
  const [isDocError, setIsDocError] = useState(false);
  const [isData, setIsData] = useState(false);
  const [apiFormattedDate, setapiFormattedDate] = useState('');
  const {
    saveFinanceInsuranceDetailQueryData,
    initsaveFinanceInsuranceDetailData,
  } = SaveFinanceInsuranceDetailsAction();
  const {
    getFinanceInsuranceDetailQueryData,
    initGetFinanceInsuranceDetailData,
  } = GetFinanceInsuranceDetailAction();
  const {
    deleteFinanceInsuranceDetailQueryData,
    initDeleteFinanceInsuranceDetailData,
  } = DeleteFinanceInsurancedetailAction();
  const [isFormatedDate, setIsFormatedDate] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState('');
  // const { data } = getFinanceInsuranceDetailQueryData;
  const [data, setData] = useState({});
  const [expiryDateErrorMsg, setExpiryDateErrorMsg] = useState("");
  useEffect(() => {
    initGetFinanceInsuranceDetailData();
  }, []);

  useEffect(() => {
    if (getFinanceInsuranceDetailQueryData.called) {
      console.log('here from api call', getFinanceInsuranceDetailQueryData?.data);
      setData(getFinanceInsuranceDetailQueryData?.data);
    }
  }, [getFinanceInsuranceDetailQueryData])

  useEffect(() => {
    initGetInsuranceProviderList();
    setInsuranceOption(
      insuranceOptionsData?.getInsuranceOptions?.data?.options
    );
  }, [insuranceOptionsData]);

  const [showCalendar, setShowCalendar] = useState(false);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const removeImage = () => {
    setUploadedImage(null);
  };

  const [textHereError, setTextHereError] = useState(['', '', '']);

  const oneDocumentErrMessage = 'Please upload at least one Certificate!';
  const handleContinue = () => {
    // if (!insuranceName) {
    //   setErrorInsuranceName("This field is required.");
    //   return;
    // }
    let certificate;
    {
      certificate = otherDocs
        .filter((file) => {
          if (file.image || file.id) {
            return true;
          }
        })
        .map((file) => {
          if (type == 'insurance') {
            return {
              photoId: file.id ? file.id : 0,
              photoTitle: file.document_title,
              photo: file.image,
            };
          } else {
            return file.image;
          }
        });
    }

    if (insuranceProviderName?.insuranceCompnay === 'Other') {
      if (!insuranceProviderName?.otherInsuranceCompnay) {
        setInsuranceProviderErr((prev) => ({...prev, otherInsuranceCompnay: 'Please enter insurance provider company name'}))
        return false;
      } else {
        setInsuranceProviderErr((prev) => ({...prev, otherInsuranceCompnay: ''}))
      }
    } else if (!insuranceProviderName?.insuranceCompnay) {
      setInsuranceProviderErr((prev) => ({...prev, insuranceCompnay: 'Please select insurance provider company name'}))
      return false;
    } else if (insuranceProviderName?.insuranceCompnay) {
      setInsuranceProviderErr((prev) => ({...prev, insuranceCompnay: ''}))
    }
    if (!calendarStartDate && !isFormatedDate) {
      setExpiryDateErrorMsg("Please select expiry date.")
      return false;
    }
    // if (otherDocs?.[0]?.image === '') {
    //   // setErrorMsg('Please Add Insurance Certificate!');
    //   toast.error(oneDocumentErrMessage);
    // } else {
      setErrorMsg('');
      let canProcessFurther = true;
      let isCertificate = false;
      otherDocs.forEach((element, index) => {
        console.log('here kaka', element, index);
        if (element.image !== '' || element.document_url !== '') {
          if (element.document_title === '') {
            // canProcessFurther = false;
            // setTextHereError(prevState => {
            //   const newState = [...prevState];
            //   newState[index] = 'Please add title';
            //   return newState;
            // });
            // toast.error(titleErrorMessage);
          }
        }

        console.log('see here', element);
        if (element.image) {
          if (!element.document_title) {
            // canProcessFurther = false;
          }
          isCertificate = true;
        } else if (element.document_url) {
          if (!element.document_title) {
            // canProcessFurther = false;
          }
          isCertificate = true;
        }
      });
      if (!isCertificate) {
        toast.error(oneDocumentErrMessage);
        return false;
      }
      if (canProcessFurther) {
        initsaveFinanceInsuranceDetailData(
          {
            expireDate: calendarStartDate,
            policyNo: insurancePolicyNumber,
            documentFiles: certificate,
            insuranceCompanyName:
              insuranceProviderName?.insuranceCompnay === 'Other'
                ? insuranceProviderName?.otherInsuranceCompnay
                : insuranceProviderName?.insuranceCompnay,
          },
          (data) => {
            toast.success(data?.data?.saveInsuranceDetailsForFinance?.message)
            afterSubmit();
            parentModal(false);
            // onNextStep();
          },
          (error) => {
            toast.success('Something went wrong!');
            console.log('initsaveFinanceInsuranceDetailData err', error);
          }
        );
      } else {
        // toast.error(titleErrorMessage);
      }
    // }
  };

  const titleErrorMessage = 'Please Add Title for All Certificate!'
  const handleTitleChange = (e, index) => {
    const { name, value } = e.target;
    setOtherDocs((prevOtherDocs) => {
      const updatedDocs = [...prevOtherDocs];
      updatedDocs[index] = { ...updatedDocs[index], document_title: value };
      return updatedDocs;
    });
    if (value === '' && (otherDocs?.[index]?.image !== '' || otherDocs?.[index]?.document_url !== '')) {
      // setTextHereError(prevState => {
      //   const newState = [...prevState];
      //   newState[index] = 'Please add title';
      //   return newState;
      // });
      // toast.error(titleErrorMessage);
    } else {
      setTextHereError(prevState => {
        const newState = [...prevState];
        newState[index] = '';
        return newState;
      });
    }
  };

  const handleAddDropzone = () => {
    if (otherDocs.length < 10) {
      const nextDocIndex = otherDocs.length + 1;
      const newDocumentState = {
        image: '',
        document_url: '',
        document_title: '',
      };
      setOtherDocs((prevOtherDocs) => [...prevOtherDocs, newDocumentState]);
      setTextHereError((prev) => [...prev, '']);
      setIsData(true);
    }
  };

  const customStyle = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Adjust the z-index value according to your requirement
    }),
    control: (styles, state) => ({
      ...styles,
      backgroundColor: '#FFFFFF',
      outline: 'none',
      appearance: 'none',
      padding: '10px 10px',
      borderRadius: '10px',
      border: '1px solid #878787',
      height: '55px',
      width: '100%',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
      };
    },
  };

  const handleCustomDropDown = (field, value, nestedField = null) => {
    setInsuranceProviderName((prevData) => {
      if (nestedField) {
        return {
          ...prevData,
          [field]: {
            ...prevData[field],
            [nestedField]: value,
          },
        };
      } else {
        return {
          ...prevData,
          [field]: value,
        };
      }
    });
  };

  console.log('insuranceProviderName', insuranceProviderName);
  const [showSelectedDate, setShowSelectedDate] = useState(null);

  const [calendarStartDate, setCalendarStartDate] = useState("");
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(
    selectedDate.getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
  const [formatedDate, setFormatedDate] = useState('');
  const [step, setStep] = useState('');
  const [showSelectedTime, setShowSelectedTime] = useState('');
  const [isOpenMobileCalendar, setIsOpenMobileCalendar] = useState(false);
  const [errorInsuranceName ,setErrorInsuranceName] = useState(false);
  const [insuranceName,setInsuranceName] = useState("");
  const [otherSelected, setOtherSelected] = useState(false);

  const insuranceCompanyRef = useRef(null);
  const handleInsurancepage = () => {
    navigate(insuranceLandingPage);
  };

  const handleSelectedDate = (selectedDate) => {
    setShowSelectedDate(selectedDate);
    setFormatedDate(moment(selectedDate).format('dddd, Do MMMM YYYY'));
    // setShowSelectedDate(selectedDate);
  };
  useEffect(() => {
    const value = data?.getInsuranceDetailsForFinance?.data;
    console.log('getVal', value);
    if (data?.getInsuranceDetailsForFinance?.data) {
      // if (value?.documents?.length  && Array.isArray(value?.documents)) {
        if ( value?.documents?.length < 1) {
          let tmpLength = 1 - value?.documents?.length;
          let tmpArray = new Array(tmpLength).fill({
            image: null,
            document_url: '',
            document_title: '',
          });
          setOtherDocs([...value?.documents, ...tmpArray]);
        } else {
          setOtherDocs(value?.documents);
        }
      // }
      setInsurancePolicyNumber(
        data?.getInsuranceDetailsForFinance?.data?.policy_no
      );
      // if (data?.getInsuranceDetailsForFinance?.data) {
        const retrievedDate = data?.getInsuranceDetailsForFinance?.data?.expire_date;
        const currentYear = moment().year();

        const maxValidYear = moment().year() + 25; // Limiting to the next 25 years
        
        const pinExpiryDate = moment(retrievedDate, "YYYY-MM-DD", true);
        
        if (
          pinExpiryDate.isValid() &&
          pinExpiryDate.year() >= currentYear &&
          pinExpiryDate.year() <= maxValidYear
        ) {
                setFormatedDate(moment(retrievedDate, 'YYYY-MM-DD').format('dddd Do MMMM YYYY'));
                setShowSelectedDate(moment(retrievedDate, 'YYYY-MM-DD').format('dddd, Do MMMM YYYY'));
                setIsFormatedDate(true);
                // setShowSelectedDate(ExpiryDate.format("dddd, Do MMMM YYYY"))
                setSelectedMonth(pinExpiryDate.month()+1)
                setSelectedYear(pinExpiryDate.year())
            }

        // setFormatedDate(
        //   moment(
        //     data?.getInsuranceDetailsForFinance?.data?.expire_date,
        //     'YYYY-MM-DD'
        //   ).format('D MMMM YYYY')
        // );
        // setShowSelectedDate(
        //   moment(
        //     data?.getInsuranceDetailsForFinance?.data?.expire_date,
        //     'YYYY-MM-DD'
        //   ).format('dddd, Do MMMM')
        // );
      // }

      // insuranceProvider.some((user) => {
      //   console.log('users', user);
      // });
      if (insuranceOption && !insuranceOption?.includes(value.insurance_company_name) && value?.insurance_company_name != "") {
        setInsuranceProviderName({
          otherInsuranceCompnay: value.insurance_company_name,
          insuranceCompnay: "Other",
        });
      } else {
        setInsuranceProviderName({
          insuranceCompnay:
            data?.getInsuranceDetailsForFinance?.data?.insurance_company_name,
        });
      }

      // if ( !insuranceOption?.includes(value.insurance_company_name) && value.insurance_company_name == 'Other') {
      //   console.log("iffffffffffffffffffffffff")
      //   setInsuranceProviderName({
      //     otherInsuranceCompnay: value.insurance_company_name,
      //     insuranceCompnay: "Other",
      //   });
      // } else {
      //   console.log("elseeeeeeeeeeeeeeeeeee")
      //   setInsuranceProviderName({
      //     insuranceCompnay:
      //       data?.getInsuranceDetailsForFinance?.data?.insurance_company_name,
      //   });
      // }
    }
    console.log('DDDDDD', otherDocs);
  }, [data?.getInsuranceDetailsForFinance?.data,insuranceOption]);
  // Function to generate an array of dates for a specified range
  function generateDateData(startDate, endDate) {
    const dates = [];
    const currentDate = new Date(startDate);
    // Loop to generate dates until the endDate is reached
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().slice(0, 10));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const today = new Date();
  const endDatee = new Date(today);
  endDatee.setFullYear(today.getFullYear() + 3);
  const dateData = generateDateData(today, endDatee);
  const formattedDate = moment(showSelectedDate, 'dddd, Do MMMM YYYY').format(
    'D MMMM YYYY'
  );

  console.log('formattedDate', formattedDate);
  console.log('?????', showSelectedDate);

  const [insuranceProviderErr, setInsuranceProviderErr] = useState({
    otherInsuranceCompnay: '',
    insuranceCompnay: '',
  });

  const handleSendDataFromChildDropzone = (data) => {

  }
  useEffect(() => {
    if (showSelectedDate) {
      setIsFormatedDate(true);
    }
  },[showSelectedDate])

  useEffect(() => {
    if (otherDocs?.length < 1) {
      const emptyObjectsToAdd = 1 - otherDocs?.length;
      const emptyObjectsArray = Array.from(
        { length: emptyObjectsToAdd },
        () => ({})
      );
      setOtherDocs((prevOtherDocs) => [...prevOtherDocs, ...emptyObjectsArray]);
    }
  }, [otherDocs]);

  useEffect(() => {
    if (insuranceProviderErr?.otherInsuranceCompnay) {
      insuranceCompanyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [insuranceProviderErr]);

  useEffect(() => {
    initGetInsuranceProviderList();
    setInsuranceOption(
      insuranceOptionsData?.getInsuranceOptions?.data?.options
    );
  }, [insuranceOptionsData]);


    const getInsurance = () => {
      navigate(insuranceLandingPage)
    }
  return (
    <div className="Main_Insurance_Page">
      <div className="Main_Insurance_PagesecWrapper">
        <FinanceBanner
          title="Insurance provider company"
          desc="Adding your insurance details will also make you a verified business user. This sets a president as the industry standard for your clients."
          isBorder={false}
          facesVarified={true}
        />

            <div className="InsuranceCard">
              <div className="InsuranceCardBanner">
                <div className="InsuranceCardIconContentWrapper">
                  <div className="InsuranceCardIcon">
                    <img src={shieldIcon} alt="shieldIcon" />
                    {/* <p className="doc_label"></p> */}
                    <div className="ContentWrapper">
                      <h5>Need insurance ?</h5>
                      <p className="doc_Desc">Compare insurance quotes</p>
                    </div>
                  </div>
                </div>
                <div className="insuranceBtn">
                  <StepButton type={"button"} blue={"blue"} className={"btn"} label={"Quote me"} 
                  onClick={() => getInsurance()}
                   />
                </div>
              </div>
            </div>

        <div className="insurance-input-container Custome-insurance-provider">
          {/* insurance provider */}
          {console.log("insuranceProviderName?.insuranceCompnay--------",insuranceProviderName?.insuranceCompnay)}
          <CustomDropdown
            labelName={'Insurance company'}
            // placeholdername={'Insurance company'}
            defaultValue={ 
               insuranceOption?.includes(insuranceProviderName?.insuranceCompnay)
                      ? insuranceProviderName?.insuranceCompnay
                      : !insuranceProviderName?.insuranceCompnay
                      ? ""
                      : "Other"
              
              // insuranceProviderName?.insuranceCompnay
            }
            dropdownId={'insuranceprovider'}
            optionsArr={insuranceOption}
            style={customStyle}
            handleSelectedValue={(value) =>
              handleCustomDropDown('insuranceCompnay', value)
            }
            readOnly={false}
          />
          {!insuranceProviderName?.insuranceCompnay && insuranceProviderErr?.insuranceCompnay && <span className="error">{insuranceProviderErr?.insuranceCompnay}</span>}
        </div>

        {(insuranceProviderName?.insuranceCompnay == "Other") && (
          <div className="insurance-input-container" style={{
            marginTop: '-20px'
          }}>
            {/* add provider compnay mannually */}
            <TextInput
              label={'Insurance company'}
              placeholder={'Insurance company'}
              value={insuranceProviderName?.otherInsuranceCompnay}
              onChange={(e) => {
                handleCustomDropDown('otherInsuranceCompnay', e.target.value)
                if (!e.target.value) {
                  setInsuranceProviderErr((prev) => ({...prev, otherInsuranceCompnay: 'Please enter insurance provider company name'}))
                } else {
                  setInsuranceProviderErr((prev) => ({...prev, otherInsuranceCompnay: ''}))
                }
              }}
              ref={insuranceCompanyRef}
            />
            {insuranceProviderErr?.otherInsuranceCompnay && <span className="error">{insuranceProviderErr?.otherInsuranceCompnay}</span>}
          </div>
        )}
        <div className='errorWrapper'> 
        <div
          className="calenderIconWrapper"
          onClick={() => {
            toggleCalendar();
          }}
        >
          <div className="calenderIconAndDateWrapper">
            <div className="calenderIcon">
              <img src={calander} alt="calander" />
            </div>
            {isFormatedDate ? (
              <div className="DateContent">
                <h5>{formattedDate}</h5>
              </div>
            ) : (
              <div className="DateContent">
                <h5>Expiry date</h5>
              </div>
            )}
          </div>
          <div className="calenderRightIcon">
            <img src={nextIcon} alt="next" />
          </div>
        </div>
        {expiryDateErrorMsg && !isFormatedDate &&<span className="error">{expiryDateErrorMsg}</span>}
        </div>

        <div className="Certificate-docmentation-Wrapper">
          <div className="form-label">Insurance certificate</div>
          <div className="docmentationGrid-Wrapper">
            {otherDocs.map((doc, index) => (
              <>
                <div className="docmentationGridItem" key={index}>
                  <Dropzone
                    handleMulti={setOtherDocs}
                    selectedIndex={index}
                    urlFromDB={doc.document_url}
                    handleDelete={initDeleteFinanceInsuranceDetailData}
                    insuranceId={doc.id}
                    otherDocs={otherDocs}
                    imageFromComponent={doc.image}
                    removeTextHereError={setTextHereError}
                    sendDataToParent={handleSendDataFromChildDropzone}
                    dropzoneType='insurance'
                    afterSubmitAction={afterSubmit}
                  />
                  <input
                    name={`doc_name${index + 1}`}
                    value={doc.document_title}
                    onChange={(e) => handleTitleChange(e, index)}
                    placeholder="Description"
                    className="inputTag"
                  />
                  {textHereError[index] && <span className="error">{textHereError[index]}</span>}
                </div>
              </>
            ))}
           {otherDocs.length < 10 &&  <div className="docmentationGridItem">
              {/* {
                    isData ? ( */}
              <div className="dropzone" onClick={handleAddDropzone}>
                <Uploads />
                <p>Add Photo </p>
              </div>
              {/* ):(
                      <div
                      className="d-flex flex-column align-items-center justify-content-center add-container"
                      onClick={handleApiAddDropZone}
                    >
                      <Uploads />
                      <p>Add Photo </p>
                    </div>
                    )
                  } */}
              {/* <input name="Add" placeholder="Text Here" /> */}
            </div>}
          </div>
        </div>
        {ErrorMsg && <span className="error">{ErrorMsg}</span>}

        {/* <div className="Insurance-UploaderWrapper">
              <span className="form-label">Insurance Document</span>
              <div className="customeDropDownPreviewWrapper">
                <div className="customeDropDownWrapper" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {uploadedImage && (
                    <>
                      <img
                        src={uploadedImage}
                        alt="Uploaded"
                      />
                    </>
                  )}
                  {!uploadedImage && (
                    <div className="UploadIcon">
                      <Uploads />
                      <p>Upload</p>
                    </div>
                  )}
                </div>
                {uploadedImage && 
                    <div className="PreviewImg">
                          <IoEyeSharp onClick={()=> {setPreviewImg(true)}} />
                    </div>
                  }
              </div>
              {errorMessage && (
                <p className="error">{errorMessage}</p>
              )}
            </div> */}

        <div className="finance-input-container">
          <span className="form-label">Policy number (Optional)</span>
          <TextInput
            placeholder={'Policy number (Optional)'}
            name={'policy'}
            value={insurancePolicyNumber}
            onChange={(e) => {
              const policyNumber = e.target.value;
              setInsurancePolicyNumber(policyNumber);
            }}
          />
        </div>
        {/* {
              ErrorMsg && (
                <span className="error">{ErrorMsg}</span>
              )
            } */}

        {/* <CustomDropdown
                  labelName={"Insurance Company"}
                  defaultValue={
                    insuranceOption?.includes(insuranceName)
                      ? insuranceName
                      : !insuranceName
                      ? ""
                      : "Other"
                  }
                  dropdownId={"Insurance Company"}
                  placeholdername={"Insurance Company"}
                  optionsArr={insuranceOption}
                  style={customStyle}
                  handleSelectedValue={(value) => {
                    setInsuranceName(value);
                    setOtherSelected(value === "Other");
                  }}
                  readOnly={false}
                /> */}

              {/* {(otherSelected ||
                    (insuranceName !== "" &&
                      !insuranceOption?.includes(insuranceName))) && (
                    <input
                      type="text"
                      class="form-control mt-3"
                      name="iname"
                      value={insuranceName}
                      onChange={(e) => {
                        setInsuranceName(e.target.value);
                        setErrorInsuranceName("");
                      }}
                      placeholder="Insurance Company"
                    />
                )}
              {errorInsuranceName && <div className="text-danger" id="error-insurance-name">{errorInsuranceName} </div>} */}
        <div className="third-flex-div cus-third-flex-padding">
          <StepButton
            label={' Save & Next'}
            blue={true}
            isLoading={saveFinanceInsuranceDetailQueryData.loading}
            onClick={() => handleContinue()}
          />
        </div>

      
        {/* <div className="InsuranceCard">
          <div className="InsuranceCardIconContentWrapper">
            <div className="InsuranceCardIcon">
              <img src={shieldIcon} alt="shieldIcon" />
            </div>
            <div className="ContentWrapper">
              <p className="doc_label">Need Insurance ?</p>
              <p className="doc_Desc">Compare Insurance quotes here</p>
            </div>
          </div>
          <FiChevronRight className="next_icon" />
        </div> */}
      </div>

      <CustomModal
        modalOpen={showCalendar}
        setModalOpen={setShowCalendar}
        
        modalBody={
          <CustomCalendar
            setCalendarStartDate={setCalendarStartDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            dateData={dateData}
            showSelectedDate={showSelectedDate}
            setShowSelectedDate={setShowSelectedDate}
            onSelectDate={handleSelectedDate}
            setFormatedDate={setFormatedDate}
            setStep={setStep}
            setShowSelectedTime={setShowSelectedTime}
            isOpenMobileCalendar={isOpenMobileCalendar}
            setIsOpenMobileCalendar={setIsOpenMobileCalendar}
            setShowCalendar={setShowCalendar}
            setIsOpenCalendar={setShowCalendar}
            allowMonthYearChange={true}
          />
        }
        modalTitle={'calander'}
      />

      {/* <CustomModal 
   modalOpen={open}
   setModalOpen={setOpen}
  type={"common-width"}
  modaltitle={"Are you sure"}
  modalBody={
    <div className='modelButtons'>
    <button className='deleteButton' onClick={()=>{
       removeImage()
       setOpen(false);
      }}>Delete</button>
    <button className='cancelButton' onClick={()=>setOpen(false)}>Cancel</button>
    </div>
  }
 /> */}

      {/* <ConfirmationModal
        open={open}
        setOpen={setOpen}
        title="Are you sure?"
        content="Here is some content to complement the title"
        onConfirm={() => {
          removeImage();
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
      /> */}
    </div>
  );
}
