import loadingIcon from '../../../../images/loading.gif';
import './stepButton.scss';
import cn from 'classnames';

const StepButton = ({
  onClick,
  className,
  label,
  blue,
  icon,
  red,
  fontSize,
  social,
  disabled,
  type,
  dark,
  id,
  gray,
  redWithBorder,
  isLeftIcon,
  leftIcon,
  isLoading = false,
  image,
  afterlabelimage,

  labelClassName = false,
}) => {
  // console.log(labelClassName, 'labelClassName');
  return (
    <>
      <div
        className={
          cn(
            [
              blue
                ? 'step-button-blue mt-4'
                : gray
                ? 'step-button-gray'
                : dark
                ? 'step-button-dark'
                : red
                ? 'step-button-red'
                : redWithBorder
                ? 'step-button-with-border-red'
                : social
                ? 'step-button-social'
                : 'step-button-dark',
            ],
            disabled ? ['step-disabled-button'] : ['step-enabled-button'],
            isLoading ? ['step-button-loading'] : null
          ) +
          ' ' +
          labelClassName
        }
      >
        {/* <button disabled={disabled} type={type} onClick={() => {
            if(!isLoading) {
              onClick();
              light ? "step-button-light" : gray ? "step-button-gray" : red ? "step-button-red" : social ? "step-button-social" : "step-button-dark"
            }
          }} className={className}> */}
        <button
          disabled={disabled}
          type={type}
          id={id}
          onClick={onClick}
          // style={disabled && {cursor:"not-allowed"}}
          className={className}
        >
          {isLoading && false ? (
            <div className="d-flex justify-content-center p-0">
              <img src={loadingIcon} className={'loadingIcon'} />
              {/* <div className="dots-5 "></div> */}
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              {image && (
                <img src={image} alt="icon" className={'image-icon me-2'} />
              )}{' '}
              {/* New line for the image */}
              <div className={fontSize ? 'button-font d-flex' : 'd-flex'}>
                {isLoading ? (
                  <img src={loadingIcon} className={'loadingIcon'} />
                ) : (
                  <>
                    {isLeftIcon && (
                      <div className="leftIcon my-auto">{leftIcon}</div>
                    )}
                    <div className="btnTitle my-auto">{label}</div>
                  </>
                )}
              </div>
              {afterlabelimage && (
                <img
                  src={afterlabelimage}
                  alt="icon"
                  className={'image-icon mx-1'}
                />
              )}
              {/* &nbsp; */}
              <div
                className={
                  social
                    ? 'social-icon'
                    : 'd-flex justify-content-center align-items-center'
                }
              >
                {icon}
              </div>
              {/* &nbsp; */}
            </div>
          )}
        </button>
      </div>
    </>
  );
};

export default StepButton;
