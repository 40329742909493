import React from 'react'
import { ToastBar, Toaster } from 'react-hot-toast'

const ToastbarComponent = (props) => {
  const {toast} = props;
  return (
    <div   className="TostContainer">
      <Toaster
        position="top-center"
        reverseOrder={false}
      >
        {(t) => (
          <ToastBar toast={t} className="TostMain">
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== 'loading' && (
                  <button className='closeTostIcon' onClick={() => toast.dismiss(t.id)}><span>x</span></button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </div>
  )
}

export default ToastbarComponent
