import React, { useEffect, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { businessDashboardRoute, getCalendarRoute, getClientProfileRoute } from '../../Routes'
import { frontendURL } from "../../config"
import closeIcon from "../../images/icons/Back.svg"
import bellIcon from "../../images/icons/bellIcon.svg"
import eyeIcon from "../../images/icons/circleEyeIcon.svg"
import downLoadIcon from "../../images/icons/appointment-list/download.svg"
import editIcon from "../../images/icons/edit-icon.svg"
import rightArrow from "../../images/icons/rightArrow.svg"
import showToast from '../common/CustomToast/CustomToast'
import StepButton from '../common/StepButton'
import CustomPopup from '../custom-components/custom-popup'
import CustomModal from '../modal/CustomModal'
import { CancleAppointmentMutation } from '../redux/actions/appointments/CancleAppointment'
import { CancelAppointmentMutationAction } from '../redux/actions/dashboard/cancleAppointmentGql'
import { ResendBookingDetailsMutation } from '../redux/actions/sendReminder/ResendBookingDetails'
import { RESEND_FORWARD_CONSENT_FORM, RESEND_FORWARD_CONSENT_FORM_ERROR, RESEND_FORWARD_CONSENT_FORM_SUCCESS } from '../redux/constants'
import "./consent-action.scss"

import { GetViewConsentFormPdfUrlQuery } from '../redux/actions/downloadViewConsentForm/downloadViewConsentForm'
import ResendBookingAndConsentIcon from '../../images/icons/appointment-list/ResendBookingAndConsent.svg'
import ConsentformIcon from '../../images/icons/appointment-list/ConsentFormIcon.svg'
import ConsentFormEditIcon from '../../images/icons/appointment-list/ConsentFormEditIcon.svg'



const ConsentActionPopup = (props) => {

    const dispatch = useDispatch();
    const resendFormApiCalled = useSelector((state) => state.formsReducer.resendFormApiCalled);
    const resendFormSuccessStatus = useSelector((state) => state.formsReducer.resendFormSuccessStatus);
    const [clicked, setCLicked] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const navigate = useNavigate()
    const redirectUrl = `${frontendURL}/appointment/${props.bookingSlug}`
    const encodedRedirectUrl = encodeURIComponent(redirectUrl);


    const appointmentDetails = useSelector((state) => state.appointmentReducer.appointmentDetails);

    console.log("detailsss", appointmentDetails)
    //For Dowload PDF
    const { initViewConsentFormPdfUrl } = GetViewConsentFormPdfUrlQuery()


    //Cancle Appointment
    const { cancleAppointmentMutationData, initiateCancleAppointmentResponseData } = CancleAppointmentMutation()
    const handleCancleAppointment = (elementId) => {
        initiateCancleAppointmentResponseData({
            appointmentId: elementId,
        }, (res) => {
            if (res.success) {

                props.setConfirmationPopupShow(false);
                props.setCompliancePopupShow(false);
                props.setShowConsentPopup(false);
                // toast.success(res.message)
                showToast("success", res.message, "", false)
                if (props.setIsAppointmentDetailsOpen) {
                    props.setIsAppointmentDetailsOpen(false);
                    props.setIsCancleAppointmentResponse(res.success)
                } else {
                    if (props.locationData) {
                        // navigate(businessDashboardRoute())
                        if (props.locationData?.clientProfile == "clientprofile") {
                            navigate(getClientProfileRoute(props.locationData.clientId), { state: { fromAppointmentScreen: true, active: props.locationData.active } })
                        }
                        else if (props.locationData?.fromCalenderAppointmentList === "calender") {
                            navigate(getCalendarRoute(), { state: { AppointmentDate: props?.appointmentDate } })
                        }
                        else {
                            navigate(businessDashboardRoute())
                        }
                    }

                    else {
                        // getAppointmentList();
                        if (props.getAppointmentData) {
                            props.getAppointmentData()
                        }
                    }
                }
            }
        }, (error) => {
            // toast.error(error.message)
            showToast("error", error.message, "", false)
            console.log("error", error)
            props.setConfirmationPopupShow(false);
            props.setCompliancePopupShow(false);
            props.setShowConsentPopup(false);
            if (props.setIsAppointmentDetailsOpen)
                props.setIsAppointmentDetailsOpen(false);
        })
    }

    useEffect(() => {
        if (resendFormSuccessStatus) {
            props.setShowConsentPopup(false);
            props.setCompliancePopupShow(false);
            props.setConfirmationPopupShow(false);
            setCLicked(false);
            // dispatch(clearFormApiCalled());
        }
    }, [resendFormSuccessStatus])

    const onClickReminder = () => {

        setConfirmModal(true);
    }

    const viewConsentForm = (formId) => {
        initViewConsentFormPdfUrl({
            consentFormRecordId: formId
        }, (res) => {
            if (res?.data?.getConsentFormPdfForDashboard?.success) {
                window.open(res?.data?.getConsentFormPdfForDashboard?.data?.consentFormRecordPdfUrl, "_blank")
            } else {
                showToast('error', res.message, undefined, undefined, undefined, undefined, { toastId: 'get-pdf-error-toast' });
            }
        }, (err) => {
            showToast('error', err.message, undefined, undefined, undefined, undefined, { toastId: 'get-pdf-error-toast' });

        })
    }

    const { updateOccupationQueryData, initCancelAppointmentData } = CancelAppointmentMutationAction();
    // const { resendConsentFormLinkQueryData, initResendConsentFormLinkData } = ResendConsentFormLinkAction();
    const { initiateResendBookingDetailsData, resendBookingDetailsMutationData } = ResendBookingDetailsMutation()

    return (
        <>
            <div>
                <CustomPopup modalWidth="388px" modalHeight={props.height} className={'confirmationPopupShow'}>
                    <OutsideClickHandler
                        onOutsideClick={() => {
                            if (!confirmModal) {
                                props.setConfirmationPopupShow(false); setCLicked(false); props.setShowConsentPopup(false); props.setCompliancePopupShow(false)
                            }
                        }
                        }
                    >
                        {!props.confirmationPopupShow ?
                            (props.compliancePopupShow ?
                                <div className='compliance-popup-container'>
                                    <div className='text-end'>
                                        <img src={closeIcon} style={{ cursor: "pointer" }} onClick={() => { props.setConfirmationPopupShow(false); props.setShowConsentPopup(false); props.setCompliancePopupShow(false); }} />
                                    </div>
                                    <div className='text-start'>
                                        <h4>Compliance essentials</h4>
                                        <p>
                                            Maintain business standards and regulations by gathering all necessary client information.
                                        </p>
                                        <div className='text-center'>
                                            <button onClick={() => { props.setConfirmationPopupShow(false); props.setShowConsentPopup(false); props.setCompliancePopupShow(false) }}>
                                                Got it
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                :
                                <div className='consent-popup-container'>
                                    {
                                        !props.consentStatus ?
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div>
                                                        <h4 className='my-auto'> <span>{appointmentDetails?.client_fname?.charAt(0)?.toUpperCase() + appointmentDetails?.client_fname?.substring(1)} {appointmentDetails?.client_lname?.charAt(0)?.toUpperCase() + appointmentDetails?.client_lname?.substring(1)}</span> Consent Form</h4>
                                                    </div>
                                                    <div className='text-end'>
                                                        <img src={closeIcon} style={{ cursor: "pointer" }} onClick={() => { setCLicked(false); props.setConfirmationPopupShow(false); props.setCompliancePopupShow(false); props.setShowConsentPopup(false); }} />
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between flex-start mt-4' style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setCLicked(false); props.setConfirmationPopupShow(false); props.setCompliancePopupShow(false); props.setShowConsentPopup(false);
                                                        const redirectURL = encodeURIComponent(redirectUrl);
                                                        const finalURL = `${process.env.REACT_APP_WEB_BASE_URL}/filled-consent-form/edit/${props.consent_form_id}?scroll-to=consent-form-redirect&redirect-url=${redirectURL}`;
                                                        window.location.href = finalURL;
                                                    }}>
                                                    <div className='d-flex gap-3'>
                                                        <div>
                                                            <img src={ConsentFormEditIcon} />
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <p className='my-auto data inPerson' >Complete in person</p>
                                                            <p className='my-auto subHeading'>Complete or amend consent</p>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div className='d-flex justify-content-between flex-start mt-4'
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => { onClickReminder() }}
                                                >
                                                    <div className='d-flex gap-3'>
                                                        <div>
                                                            <img src={ResendBookingAndConsentIcon} />
                                                        </div>
                                                        {
                                                            (clicked && !resendFormSuccessStatus) ?
                                                                <div className='my-auto d-flex'>
                                                                    <p className='my-auto data'>Sending reminder</p> <p className='dots'></p>
                                                                </div>
                                                                :
                                                                <div className=' d-flex flex-column '>
                                                                    <p className=' my-auto data remainClient'>Resend client</p>
                                                                    <p className='my-auto subHeading'>Resend booking & consent forms</p>
                                                                </div>
                                                        }
                                                    </div>

                                                </div>

                                            </div> :
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div>
                                                        <h4 className='my-auto'> <span>{appointmentDetails?.client_fname?.charAt(0)?.toUpperCase() + appointmentDetails?.client_fname?.substring(1)} {appointmentDetails?.client_lname?.charAt(0)?.toUpperCase() + appointmentDetails?.client_lname?.substring(1)}</span>'s Consent Form</h4>
                                                    </div>
                                                    <div className='text-end'>
                                                        <img src={closeIcon} style={{ cursor: "pointer" }} onClick={() => { setCLicked(false); props.setConfirmationPopupShow(false); props.setCompliancePopupShow(false); props.setShowConsentPopup(false); }} />
                                                    </div>
                                                </div>
                                                <div
                                                    className='d-flex justify-content-between flex-start mt-2'
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        const redirectURL = encodeURIComponent(redirectUrl);
                                                        const finalURL = `${process.env.REACT_APP_WEB_BASE_URL}/filled-consent-form/edit/${props.consent_form_id}?scroll-to=consent-form-redirect&redirect-url=${redirectURL}`;
                                                        window.location.href = finalURL;
                                                    }}>
                                                    <div className='d-flex gap-3'>
                                                        <div>
                                                            <img src={ConsentFormEditIcon} width="40" />
                                                        </div>
                                                        <div className='my-auto data'>
                                                            View & edit consent form
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className='d-flex justify-content-between flex-start mt-3'
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        viewConsentForm(props.consent_form_id)
                                                    }}>
                                                    <div className='d-flex gap-3'>
                                                        <div>
                                                            <img src={downLoadIcon} width="40" />
                                                        </div>
                                                        <div className='my-auto data'>
                                                            Download PDF
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                    }
                                </div>)
                            :
                            <div className='confirmation-popup-container'>
                                <div className='text-end'>
                                    <img src={closeIcon} style={{ cursor: "pointer" }} onClick={() => {
                                        props.setConfirmationPopupShow(false); props.setCompliancePopupShow(false); props.setShowConsentPopup(false);
                                        if (props.onCloseCallback) {
                                            props.onCloseCallback()
                                        }
                                    }} />
                                </div>
                                <h4 className='text-start fw-bold'>Are you sure?</h4>
                                <p className='ms-0 text-start'>Do you really want to cancel the appointment? Once done, you cannot undo it!</p>
                                <div className='appointment-cancel-button '>
                                    <StepButton isLoading={cancleAppointmentMutationData?.loading} className='w-100 py-2 mx-2' onClick={() => handleCancleAppointment(props.elementId)} label={"Cancel"} red={true} />
                                </div>
                            </div>
                        }
                    </OutsideClickHandler>
                </CustomPopup>
            </div>
            <CustomModal
                modalOpen={confirmModal}
                setModalOpen={setConfirmModal}
                className={'confirmModal'}
                modalBody={
                    <>
                        <div className='cart-delete-modal'>
                            <p className='text-center delete-modal-desc'>Are you sure you want to remind client?</p>

                            <div className='delete-btn-container'>
                                <button className='delete-item-btn me-3' onClick={() => {
                                    setConfirmModal(false);
                                    setCLicked(true);
                                    console.log('props.consent_form_id', props.consent_form_id);
                                    // dispatch(resendForwardConsentForm({ elementId: props.consent_form_id }));

                                    dispatch({
                                        type: RESEND_FORWARD_CONSENT_FORM
                                    });
                                    initiateResendBookingDetailsData({
                                        // bookingId: props.consent_form_id,
                                        appointmentId: props.elementId,
                                        consentFormRecordId: props.consent_form_id
                                    }, (res) => {
                                        if (res.success) {
                                            showToast("success", res.message, "", false)
                                            dispatch({
                                                type: RESEND_FORWARD_CONSENT_FORM_SUCCESS,
                                                payload: res,
                                            });
                                        } else {
                                            showToast("error", res.message, "", false)
                                            dispatch({
                                                type: RESEND_FORWARD_CONSENT_FORM_ERROR,
                                                payload: res,
                                            })
                                        }
                                    }, (err) => {
                                        showToast("error", err, "", false)
                                    })
                                    // here
                                }}>Yes</button>
                                <button className='delete-outline-btn'
                                    onClick={() => setConfirmModal(false)}>No</button>
                            </div>
                        </div>
                    </>
                }
            />
        </>
    )
}

export default ConsentActionPopup
