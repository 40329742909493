import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const REMOVE_BEFORE_AFTER_PHOTOS = gql`
mutation RemoveBeforeAfterPhoto($photoId: Int) {
  removeBeforeAfterPhoto(photoId: $photoId) {
    success
    message
  }
}
`;


export const RemoveBeforeAfterPhotoAction = () => {
    const dispatch = useDispatch();
    const [
        RemoveBeforeAfterPhotoResponseFunction,
        removeBeforeAfterPhotoResponseData,
    ] = useMutation(REMOVE_BEFORE_AFTER_PHOTOS)

    //this will be called from components
    const removeBeforeAfterPhotoQueryData = removeBeforeAfterPhotoResponseData; //variable

    const initRemoveBeforeAfterPhotoData = (data, successCallback, errorCallback) => {
        RemoveBeforeAfterPhotoResponseFunction({
            variables: data,
        }).then(({ data: { removeBeforeAfterPhoto: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback(responseData)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
            // toast.error("something went wrong!!!")
        })
    }

    return { removeBeforeAfterPhotoQueryData, initRemoveBeforeAfterPhotoData };
};