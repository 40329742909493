import React, { useEffect, useState } from "react";

import "./team-member-mobile.scss";
import { Avatar } from "@material-ui/core";
import { ReactComponent as BackArrow } from "../../images/back_arrow.svg";
import BannerHeader from "../common/BannerHeader";
import StepButton from "../common/StepButton";
import SkeletonLoader from "../common/Skeleton/SkeletonLoader";
import { useDispatch, useSelector } from "react-redux";
import { SET_LAST_STEP } from "../redux/constants/treatment-list-const/TreatmentListConst";

const TeamMemberMobile = ({
  setStep,
  step,
  selectedTeamMember,
  teamMemberData,
  setSelectedTeamMember,
  selectedTreatment,
  setSelectedTreatment,
  setSubStep,
  substep,
  isLoading
}) => {
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#EEEFF3",
        color: "#000",
      },
      children: `${name?.split(" ")}`.toUpperCase(),
    };
  }
  // const spaceIndex = inputString.indexOf(" ");

  const dispatch = useDispatch();

  const { isLastStep } = useSelector((state) => state.businessLocationListReducer);
  const [name, setName] = useState({
    firstName: "",
    id: 0, image_url: "",
    occupations: [],
    initials: ""
  })
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])
  const handleSelectTeamMember = () => {


    setLoader(true)
    setTimeout(() => {
      setLoader(false)
      setSelectedTeamMember(name)
      if (!isLastStep)
        dispatch({ type: SET_LAST_STEP, payload: true })
      setStep(5)
    }, 2000)
  }

  useEffect(() => {
    teamMemberData?.map((item) => {
      const spaceIndex = item?.name?.indexOf(" ");
      if (spaceIndex !== -1) {
        //  const firstWord = 
        // const restOfString = item?.name?.slice(spaceIndex + 1);
        setName({
          firstName: item?.name,
          id: item?.id,
          occupations: item?.occupations,
          image_url: item?.image,
          initials: item?.initials
        })

      } else {
        // Handle the case where there is no space in the string
      }

    })
  }, [teamMemberData])
  useEffect(() => {

  }, [isLoading])
  return (
    <div className="container">
      <div className="team-member-section">
        <div className="top-header">
          {<BackArrow clas onClick={() => { setSubStep(302); setStep(3) }} style={{ marginBottom: "20px" }} className={`mt-3 ${isLastStep ? "invisible" : ""}`} />}
          <BannerHeader title={"Select team member"} />
        </div>
        {
          isLoading ? <SkeletonLoader type={"team-member"} /> :

            <div className="right-content mt-3 teamWrapperBox">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "20px",
                  textAlign: "justify",
                }}
                className="AvtarWrapper"
              >

                <Avatar className="string-color" src={teamMemberData[0]?.image} {...stringAvatar(name?.initials)} />
                <div className="team-member-content">
                  <b>{`${teamMemberData[0]?.name}`}</b>
                  {
                    <p>
                      {teamMemberData[0]?.occupations.join(", ")}
                    </p>
                  }
                </div>

              </div>
              {
                isLoading === false && <input type="radio" checked={true} />
              }
            </div>

        }
        <div>
          <div className=" fixed-button">
            <StepButton
              blue={true}
              label={isLastStep ? `Continue to Checkout` : "Continue"}
              isLoading={loader}
              onClick={() => handleSelectTeamMember()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberMobile;
