import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

// Define the GraphQL query
const GET_STATIC_AMOUNT_PAYMENT_TOKEN_QUERY = gql`
  query GetStaticAmountPaymentToken($selectedPlan: getStaticAmountPaymentTokenTypePlans) {
    getStaticAmountPaymentToken(selectedPlan: $selectedPlan) {
      success
      message
      data {
        payment_session
      }
    }
  }
`;

// Create the custom hook
export const useGetStaticAmountPaymentTokenAction = () => {
  const [
    fetchStaticAmountPaymentToken,
    staticAmountPaymentTokenResponseData,
  ] = useLazyQuery(GET_STATIC_AMOUNT_PAYMENT_TOKEN_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getStaticAmountPaymentToken: responseData }) => {
      if (responseData.success) {
        // Handle successful response
        // toast.success(responseData?.message);
      } else {
        // Handle unsuccessful response
        // toast.error(responseData?.message);
      }
    },
  });

  // This will be called from components
  const staticAmountPaymentTokenQueryData = staticAmountPaymentTokenResponseData; // variable

  const initStaticAmountPaymentTokenData = (data,successCallBack, errorCallback) => {
    fetchStaticAmountPaymentToken({
      variables: data,
    }).then((res) => {
        if (res?.data?.getStaticAmountPaymentToken?.success)
          successCallBack(res.data.getStaticAmountPaymentToken.data)
      });
  };

  return { staticAmountPaymentTokenQueryData, initStaticAmountPaymentTokenData };
};
