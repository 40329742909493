
import OwlCarousel from 'react-owl-carousel';
import React, { useState, useEffect } from 'react';
import StarVecotrPng from "../../../images/social-media-marketing/star-vector.png";
import { GetTestimonialListAction } from '../../redux/actions/accountancy-service/testimonials-list';

export const TestimonialsCarousel = () => {
    // const [plans, setPlans] = useState([]);


    // useEffect(() => {
    //     setPlans([
    //         {
    //             id: 1,
    //             title: 'test',
    //             description: 'test'
    //         },
    //         {
    //             id: 2,
    //             title: 'test',
    //             description: 'test'
    //         },
    //         {
    //             id: 3,
    //             title: 'test',
    //             description: 'test'
    //         },
    //         {
    //             id: 4,
    //             title: 'test',
    //             description: 'test'
    //         },
    //         {
    //             id: 5,
    //             title: 'test',
    //             description: 'test'
    //         }
    //     ]);
    // }, []);

    /*
    const testimonials = [
        {
            id: 1,
            rating: '10/10',
            content: 'Understanding medical VAT was a maze we couldn\'t navigate. This team made it not just understandable but advantageous for us. They\'ve saved us thousands. Best decision our company ever made!',
            initial: 'SW',
            userName: 'Sarah Williams',
            userLocation: 'CEO of MedBeauty Group, UK',
        },
        {
            id: 2,
            rating: '10/10',
            content: 'I was completely lost when it came to organising my books. They took over and sorted everything out for me. You are truly life-savers!',
            initial: 'EC',
            userName: 'Emily Clark',
            userLocation: 'Registered Nurse Practitioner, LA',
        },
        {
            id: 3,
            rating: '10/10',
            content: 'Running a salon with a high staff turnover, covering everything from lashes to aesthetics, made my books a nightmare! Having professionals manage my finances, including profit and loss statements, and helping strategise for the next quarter has been a game-changer.',
            initial: 'DT',
            userName: 'David Thompson',
            userLocation: 'Owner of Glamour Salons, London',
        },
        {
            id: 4,
            rating: '10/10',
            content: 'I work on the go, and keeping track of what I could claim was always a hassle. Thomas helped me save on all my fuel expenses and educated me on expenses I didn\'t even know I could claim.',
            initial: 'SJ',
            userName: 'Sophia Johnson',
            userLocation: 'Freelance Mobile Make-up Artist, UK',
        },
        {
            id: 5,
            rating: '10/10',
            content: 'I knew I could include my makeup as an expense, but I had no idea just how much I was missing out on until now. The savings have been incredible!',
            initial: 'AS',
            userName: 'Alexandra Smith',
            userLocation: 'Professional Makeup Artist, UK',
        },
     
    ];
    */

    const [testimonials, setTestimonials] = useState([]);
    const { getTestimonialListQueryData, initGetTestimonialListData } = GetTestimonialListAction();
    useEffect(() => {
        initGetTestimonialListData();
    }, []);
    useEffect(() => {
        if (getTestimonialListQueryData?.data?.getTestimonialList?.success && getTestimonialListQueryData?.data?.getTestimonialList?.data?.testimonial) {
            setTestimonials(getTestimonialListQueryData?.data?.getTestimonialList?.data?.testimonial);
        }
    }, [getTestimonialListQueryData]);

    const responsiveObj = {
        0:{
            items:1.2
        },
        500:{
            items:2.2
        },
        700:{
            items:2.2
        },
        950:{
            items:3.2
        },
        1100:{
            items:3.2
        }
    };

    return (
        <OwlCarousel items={testimonials}
            className='owl-theme'
            loop={true}
            margin={20}
            nav={true}
            dots={false}
            responsiveClass={true}
            responsive={responsiveObj}>
            {testimonials.map(plan => (
                <div key={plan.id} className='testimonialRatingElement'>
                    <div className="testimonialRatingDiv"><i class="fa fa-star  me-1 checked "></i> <span className='ratingSpan'>{plan.rating}</span></div>
                    <div className='testimonialRatingContent'>{plan.description}</div>
                    <div className='testimonialRatingUserDiv'>
                      {
                        plan.image_url !== '' ? <img src={plan.image_url} className='testimonialRatingUserInitial' alt={plan.name} /> : <div className='testimonialRatingUserInitial'>{plan.shortname}</div>
                      }
                        <div className='testimonialRatingUserDetails'>
                            <div className='testimonialRatingUserDetailsName'>{plan.name}</div>
                            <div className='testimonialRatingUserDetailsLocation'>{plan.field}</div>
                        </div>
                    </div>
                </div>
            ))}
        </OwlCarousel>
    );
};
