import React, { useEffect, useState } from 'react';
import "font-awesome/css/font-awesome.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css'
import "./insurance-landing.scss";

import { Tooltip } from 'react-tooltip'
import SkeletonImage from '../skeleton-image/skeleton-image';
import Skeleton from 'react-loading-skeleton';
import CarouselComponent from '../carousel-component/carousel-component';
import { useDispatch, useSelector } from 'react-redux';
import getInsuranceData from '../../redux/actions/insurance/insurance';
import getInsuranceCompanyData, { getInsuranceCompanyPageData } from '../../redux/actions/insurance-company/insurance-company';
import HeaderComponent from '../header-component/header-component';
import Cookies from 'js-cookie';
import { webBaseUrl } from '../../../config';

import { ToastContainer, toast } from 'react-toastify';
import BusinessHeader from '../../businessHeader';
import { useNavigate } from 'react-router';
import { businessDashboardRoute } from '../../../Routes';
import { domainName } from '../../../styles';
import BusinessFooter from '../../businessFooter';
import { Helmet } from 'react-helmet';

function InsuranceComponent() {
    // if (!(Cookies.get('faces_access_token'))) {
    //     window.location.href = webBaseUrl + 'login'
    // }

    const navigate = useNavigate();

    const dispatch = useDispatch()
    const insuranceData = useSelector(state => state.GetInsuranceDataReducer.insuranceData);
    const insuranceMessage = useSelector(state => state.GetInsuranceDataReducer.insuranceMessage);
    const insuranceCompanyData = useSelector(state => state.GetInsuranceCompanyDataReducer.insuranceCompanyData);
    const insuranceCompanyPageDataContent = useSelector(state => state.GetInsuranceCompanyDataReducer.insuranceCompanyPageData);

    const [renderIframe, setRenderIframe] = useState(false);
    const [insuranceCompanyPageData, setInsuranceCompanyPageData] = useState([]);
    const [insuranceCompanyPageList, setInsuranceCompanyPageList] = useState([]);
    const [insuranceLandingLink, setInsuranceLandingLink] = useState("");
    const [renderMessage, setRenderMessage] = useState(false);
    const [isAuthInsurancePage, setIsAuthInsurancePage] = useState(false);
    const [isAuthHeader, setIsAuthHeader] = useState(false);
    useEffect(() => {
      Cookies.get("faces_access_token") ? setIsAuthHeader(true) : setIsAuthHeader(false);
    });
    useEffect(() => {
        if (Cookies.get('faces_access_token')) {
            setIsAuthInsurancePage(true)
        }
    }, []);
    const handleInsuranceLanding = () => {
        if (!isAuthInsurancePage) {
            window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/login`;
        }
        dispatch(getInsuranceData())
        // setRenderIframe(true);
    }

    // const goBackToHome = () => {
    //     // window.location.href = webBaseUrl
    //     navigate(businessDashboardRoute())

    // }
    const goBackToHome = () => {
        if (window.history.length > 1) {
            window.history.back();
        } else {
            navigate(businessDashboardRoute());
        }
    }

    const goBackToPrevPage = () => {
        // console.log('prev length', navigate.length, window.history.length);
        // if (window.history.length > 2) {
        navigate(-1);
        // } else {
        //     navigate(businessDashboardRoute());
        // }
    }

    useEffect(() => {
        if (insuranceData) {
            if (insuranceData && Object.keys(insuranceData.element).length !== 0) {
                setInsuranceLandingLink(insuranceData.element.insuranceLandingLink);
                setRenderIframe(true);
            } else {
                setRenderMessage(true);
            }
        }
        if (insuranceCompanyData) {
            setInsuranceCompanyPageList(insuranceCompanyData.elements)

            console.log(insuranceCompanyData, "insuranceCompanyData")
            // insuranceCompanyData.elements.map(element => {
            //     let additionalData = JSON.parse(element.insr_additional_data);
            //     if (additionalData && additionalData['insurance_landing']) {
            //         setInsuranceCompanyPageData(element)
            //     } 
            //     else {
            //         setInsuranceCompanyPageList(prevList => [...prevList, element])
            //     }
            // })
        }
        if (insuranceCompanyPageDataContent) {
            setInsuranceCompanyPageData(insuranceCompanyPageDataContent.elements)
        }

    }, [insuranceData, insuranceCompanyData, insuranceCompanyPageDataContent])

    useEffect(() => {

    }, [insuranceCompanyPageData])
    useEffect(() => {
        const currentTitle = document.title;
        if (currentTitle !== "Beauty & Aesthetic Insurance Companies | Faces Consent - Comprehensive Coverage") {
          document.title = "Beauty & Aesthetic Insurance Companies | Faces Consent - Comprehensive Coverage";
        }
      }, []);

    useEffect(() => {
        dispatch(getInsuranceCompanyData({ "request-from": "app" }));
    }, [dispatch]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    useEffect(() => {
        dispatch(getInsuranceCompanyPageData({ "request-from": "app" }))
    }, [getInsuranceCompanyPageData])

    if (renderMessage === true) {
        toast.error(insuranceMessage)
        setRenderMessage(false)
    }
    if (renderIframe === true) {
        return (<>
            {/* <BusinessHeader /> */}
            <div className="editprofile parentIframe" id="parentDiv">
                <iframe className="editprofilestyle" src={insuranceLandingLink} style={{ height: "100vh", width: "100%" }}></iframe>
            </div>
        </>)
    }
    let formatPhoneNumber = (insrPhoneCty = "", insrPhoneNo = "") => {
        let cleaned = insrPhoneNo.replace(/-/g, '');
        cleaned = cleaned.replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{1,})$/);

        let numStr = insrPhoneCty.toString();
        if (numStr.length === 1) {
            if (match) {
                return '0' + numStr + match[1] + ' ' + match[2] + ' ' + match[3];
            } else {
                return '0' + numStr + insrPhoneNo;
            }
        } else {
            if (match) {
                return '+' + numStr + match[1] + ' ' + match[2] + ' ' + match[3];
            } else {
                return '+' + numStr + insrPhoneNo;
            }
        }
    }

  
  


    return (
        <>
 <Helmet>
     <meta charSet="utf-8" />
        <title>
        Beauty & Aesthetic Insurance Companies | Faces Consent - Comprehensive Coverage
        </title>
        <meta
          name="description"
          content="Find the best beauty and aesthetic insurance companies with Faces Consent. Compare coverage options to protect your practice and ensure peace of mind. Discover comprehensive insurance solutions tailored for beauty and aesthetic professionals."
        />
        <meta
          name="keywords"
          content="beauty insurance, aesthetic insurance, Faces Consent, insurance coverage, beauty practice insurance, aesthetic practice insurance, comprehensive insurance, insurance solutions, professional liability insurance, beauty industry insurance"
        />
      </Helmet> 



            {/* <BusinessHeader /> */}
            <div className="" id="parentDiv">
                {/* <HeaderComponent /> */}
                <div className="editprofile">
                    <ToastContainer
                        position="top-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />
                    <div className="shopinnertit">
                        {isAuthHeader ? 
                        <>
                            <a data-tooltip-id="go-to-back" data-tooltip-content="Go Back" data-placement="bottom" className={`btn btnredsm`} href="javascript:void(0)" onClick={() => goBackToHome()} data-original-title="Go back" onclick="history.back()" style={{ minWidth: '37px', padding: '12px 15px 12px 10px', margin: "0px 2px" }}>
                                <i className="fa fa-arrow-left"></i>
                            </a>
                            <Tooltip id="go-to-back" style={{ fontSize: '12px' }} />
                        </>
                        : <>
                            <a data-tooltip-id="go-to-Home" data-tooltip-content="Go Home" data-placement="bottom" className={`btn btnredsm`} href="javascript:void(0)" onClick={() => goBackToPrevPage()} data-original-title="Go Home" style={{ minWidth: '37px', padding: '12px 15px 12px 10px', margin: "0px 2px" }}>
                                <i class="fa fa-home" aria-hidden="true"></i>

                            </a>
                            <Tooltip id="go-to-Home" style={{ fontSize: '12px' }} /></>
                    }
                    </div>
                    <form>
                        <div className="row editprofile-box">
                            <div className="col-12"></div>
                            <div className="col-12 main-logo">
                                {insuranceCompanyPageData.logo_url ? (
                                    <img src={insuranceCompanyPageData.logo_url} className="logo img-fluid" alt="insurance-logo" />
                                ) : (
                                    <SkeletonImage skeletonHeight="50" skeletonWidth="50" skeletonClassName="logo" />
                                )}
                            </div>
                            <div className="col-12">
                                <div className="">
                                    <h2 className="big-text text-center">
                                        {insuranceCompanyPageData.insr_name ? (
                                            insuranceCompanyPageData.insr_name
                                        ) : (
                                            <Skeleton count={1} />
                                        )}
                                    </h2>
                                    {/* <div className="small-text mt-2 text-center">
                                <p>Insurance coverage helps practitioners mitigate potential risks and protects their business reputation, and financial well-being</p>
                            </div> */}

                                    <div className="small-text mt-2 text-center">
                                        {insuranceCompanyPageData.insr_desc ? (
                                            <p>{insuranceCompanyPageData.insr_desc}</p>
                                        ) : (
                                            <Skeleton count={3} />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="social-media-packages col-12 mt-2 text-center getmore-text">
                                {insuranceCompanyPageData.insr_phone_country && insuranceCompanyPageData.insr_phone_no ? (
                                    <u className='mobile-number text-decoration-none d-flex justify-content-center'>
                                        <i className="fa fa-phone me-2 fs-3"></i>
                                        <a href={`tel:${formatPhoneNumber(insuranceCompanyPageData.insr_phone_country)}${insuranceCompanyPageData.insr_phone_no}`} className="align-items-center">
                                            {insuranceCompanyPageData.insr_phone_no}
                                        </a>
                                    </u>
                                ) : (
                                    <Skeleton count={1} />
                                )}

                                {/* {insuranceCompanyPageData.insr_phone_country && insuranceCompanyPageData.insr_phone_no ? (
                                    <u className='mobile-number text-decoration-none d-flex justify-content-center'><i className="fa fa-phone me-2 fs-3"></i> <a href="javascript:void(0)" className="align-items-center">{formatPhoneNumber(insuranceCompanyPageData.insr_phone_country, insuranceCompanyPageData.insr_phone_no)}</a></u>
                                ) : (
                                    <Skeleton count={1} />
                                )} */}
                            </div>

                            <div className="col-12 text-center px-0 get-btn">
                                <a href="javascript:void(0);" className={`cutom-btn btn btnredsm`} id="QuoteAdd" onClick={() => handleInsuranceLanding()}> Get quote!</a>
                            </div>
                        </div>
                        <div className={`insurance-slider-wrapper px-2 px-sm-3 px-md-4 px-lg-5`}>
                            <div className="mt-4">
                                <h4 className="big-text">All insurers</h4>
                            </div>
                            <div className="row insurance-provider">
                                {/* Mapping through insurance companies and displaying them */}
                                {insuranceCompanyPageList.map((insuranceCompany) => (
                                    <div key={insuranceCompany.insr_id} className="col-lg-3 col-md-4 col-sm-6 mb-4 card-box">
                                        <div className="card" style={{ height: '100%' }}>
                                            <a href={webBaseUrl + "/insurance/company/" + insuranceCompany.insr_id} style={{ textDecoration: 'none', color: 'black' }}>
                                                <div className="card-body text-center">
                                                    {insuranceCompany.logo_url ? (
                                                        <div style={{ height: '120px', marginTop: '10px' }}>
                                                            <img src={insuranceCompany.logo_url} width={120} className="slider-logo" alt={insuranceCompany.insr_name} />
                                                        </div>
                                                    ) : (
                                                        <SkeletonImage skeletonHeight="50" skeletonWidth="50" skeletonClassName="slider-logo" />
                                                    )}
                                                    <p className="card-title">
                                                        <span data-tooltip-id={"full-name-show-" + insuranceCompany.insr_id} data-tooltip-content={insuranceCompany.insr_name} data-placement="bottom">
                                                            {insuranceCompany.insr_name}
                                                            <Tooltip id={"full-name-show-" + insuranceCompany.insr_id} style={{ fontSize: '12px' }} />
                                                        </span>
                                                    </p>
                                                    <div className={`d-flex justify-content-center get-quote-btn`}>
                                                        <span>Get Quote</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* {insuranceCompanyPageList.length > 0 ? (
                                <CarouselComponent CarouselComponentList={insuranceCompanyPageList} isManualItem ={4} />
                            ) : (
                                <Skeleton width={"100%"} height={150} />
                            )} */}
                            {/* {insuranceCompanyPageList && (
                                <CarouselComponent CarouselComponentList={insuranceCompanyPageList} />
                            )} */}
                        </div>

                    </form>
                </div>
            </div>
            <BusinessFooter/>

        </>
    );
}

export default InsuranceComponent;