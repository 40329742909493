import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const UPDATE_PERSONAL_DETAILS_MUTATION = gql`
mutation UpdatePersonalDetails($pwcFname: String, $pwcLname: String, $pwcBusinessContact: String, $pwcDisplayBusinessHours: Boolean, $pwcBusinessEmail: String) {
  updatePersonalDetails(pwc_fname: $pwcFname, pwc_lname: $pwcLname, pwc_business_contact: $pwcBusinessContact, pwc_display_business_hours: $pwcDisplayBusinessHours, pwc_business_email: $pwcBusinessEmail) {
    success
    message
  }
}
`;

export const UpdatePersonalDetails = () => {
  const [updatePersonalDetailsRequest, updatePersonalDetailsResponseData] =
    useMutation(UPDATE_PERSONAL_DETAILS_MUTATION);
  const dispatch = useDispatch();
  const updatePersonalDetailsMutationData = updatePersonalDetailsResponseData;

  const initiateupdatePersonalDetails = (
    data,
    successCallback,
    errorCallback
  ) => {
    updatePersonalDetailsRequest({
      variables: data,
    })
      .then(({ data: { updatePersonalDetails: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateupdatePersonalDetails, updatePersonalDetailsMutationData };
};
