import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const SAVE_WEBSITE_DETAILS_MUTATION = gql`mutation SaveWebsiteDetails($buildType: Int, $popularTreatmentsDescription: String, $whatsapp: String, $instagram: String, $facebook: String, $currentStep: String, $termsAndConditions: String, $tertiaryColor: String, $secondaryColor: String, $primaryColor: String, $businessHoursDisplay: Boolean, $businessEmail: String, $businessContact: String, $about: String, $headline: String, $websiteUrlSlug: String, $businessName: String, $lastName: String, $firstName: String) {
  saveWebsiteDetails(build_type: $buildType, popular_treatments_description: $popularTreatmentsDescription, whatsapp: $whatsapp, instagram: $instagram, facebook: $facebook, current_step: $currentStep, terms_and_conditions: $termsAndConditions, tertiary_color: $tertiaryColor, secondary_color: $secondaryColor, primary_color: $primaryColor, business_hours_display: $businessHoursDisplay, business_email: $businessEmail, business_contact: $businessContact, about: $about, headline: $headline, website_url_slug: $websiteUrlSlug, business_name: $businessName, last_name: $lastName, first_name: $firstName) {
    message
    success
  }
}
`;

export const SaveWebsiteDetails = () => {
  const [saveWebsiteDetailsRequest, saveWebsiteDetailsResponseData] =
    useMutation(SAVE_WEBSITE_DETAILS_MUTATION);
  const dispatch = useDispatch();
  const saveWebsiteDetailsMutationData = saveWebsiteDetailsResponseData;

  const initiateSaveWebsiteDetails = (
    data,
    successCallback,
    errorCallback
  ) => {
    saveWebsiteDetailsRequest({
      variables: data,
    })
      .then(({ data: { saveWebsiteDetails: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateSaveWebsiteDetails, saveWebsiteDetailsMutationData };
};
