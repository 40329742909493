import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import './FinanceHeaderComponent.scss'
import { ReactComponent as BackArrow } from "../../../../assets/finance-registration/Back.svg";
import {config} from "../../../../config"
import { propTypes } from "react-bootstrap/esm/Image";

const FinanceHeader = ({ knowmore, onBackClick,isTranningLink,fromPharmacy=false }) => {
    const [scroll, setScroll] = useState(false);
    const WhatsappLinkRedirect=config.FINANCE_WHATSAPP_LINK
    
    const TranningWhatsAppLink= config.TRANNING_WHATSAPP_LINK
    
    const pharmacyWhatsappLink = config.PHARMACY_WHATSAPP_LINK
    useEffect(() => {
        window.addEventListener("scroll", () => {
          // console.log(window.scrollY, 'window.scrollY')
          setScroll(window.scrollY > 50);
        });
      }, []);
    return (
        <div className={scroll ? "main-back-arrow stickeyHeader" : "main-back-arrow"}>
            <div className="container">
              <div className="backArrowWrapper">
              
                <BackArrow className="cursor-pointer" onClick={onBackClick} />
                {knowmore &&  
                <button
                onClick={() => {
                  
                  fromPharmacy ? window.open(pharmacyWhatsappLink, "_blank") :
                    window.open(WhatsappLinkRedirect, "_blank");
                  }}
                >Support
                </button> 
                }
                {/* {
                  isTranningLink && 
                  <button
                onClick={() => {
                    window.open(TranningWhatsAppLink, "_blank");
                  }}
                >Support
                </button>
                } */}

                </div>
            </div>
        </div>
    )
}

FinanceHeader.propTypes = {
    knowmore: PropTypes.bool,
    onBackClick: PropTypes.func.isRequired,
    isTranningLink:propTypes
}

export default FinanceHeader