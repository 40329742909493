import React from 'react'
import '../add-new-clinic-main.scss'
import { Progress } from 'reactstrap'
import backArrow from '../../../images/back_arrow.svg'
import closeArrow from '../../../images/close_icon.svg'
import StepButton from '../../common/StepButton'

const AddClinicName = ({ addClinicNameStep, setAddClinicNameStep }) => {
    const handleGoToNameAndAddrPage = () => {
        setAddClinicNameStep(1);
    }
    return (
        <div>
            <div className='container'>
                <div className='header_icon_content mt-4'>
                    <img src={backArrow} alt='backArrow' />
                    <Progress className="pregress_bar" value={25} />
                    <img src={closeArrow} width={36} height={36} alt='backArrow' />
                </div>
                <div className='add_new_clinic_main'>
                    <div className='add_new_clinic_location'>
                        <h1>Name</h1>
                        <p>Add a name for your location, this is for your reference only. </p>
                        <div className='add_clinic_name'>

                            <span className='input_div px-2'>
                                <input type='text' placeholder='Name' />
                                <img src={closeArrow} alt='location' />
                            </span>
                            <div className='mt-5 step_btn'>
                                <StepButton label={'Save'} blue={true} onClick={handleGoToNameAndAddrPage} />
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddClinicName
