
import { CLEAR_BLOCK_OUT_TIME_DATA, GET_BLOCK_OUT_TIME_DATA } from "../../constants/blockOutTime/BlockOutTimeConst";

const initialState = {
  blockTimeOutList: [],
  totalBlockTimeOutListCount: 0,
  loadedBlockTimeOutListCount: 0,
  blockTimeOutListPage: 0,
  emptyBlockTimeOutList: false
};

export default function blockTimeOutListReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BLOCK_OUT_TIME_DATA:
      const temp= (state?.blockTimeOutList).concat(action?.payload?.elements)
      return {
        ...state,
        blockTimeOutList: temp,
        totalBlockTimeOutListCount: action.payload?.total,
        blockTimeOutListPage: state.blockTimeOutListPage + 1,
        loadedBlockTimeOutListCount: temp.length
      };

    case CLEAR_BLOCK_OUT_TIME_DATA:
      return {
        ...state,
        blockTimeOutList: [],
        totalBlockTimeOutListCount: 0,
        blockTimeOutListPage: 0,
        loadedBlockTimeOutListCount: 0
      };

    default:
      return state;
  }
}
