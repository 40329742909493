import { gql, useMutation } from "@apollo/client";

const SAVE_FINANCE_INDENTITY=gql`
mutation SaveIdentityDocumentForFinance($identityDoc: Upload!) {
    saveIdentityDocumentForFinance(identity_doc: $identityDoc) {
      success
      message
      data {
        id
        certificate_name
        certificate_url
        time
      }
    }
  }
`;

export const SaveFinanceIdentityAction=()=>{
    const [saveFinanceIdentityResponseFunction,
    saveFinanceIdentityResponseData]=useMutation(SAVE_FINANCE_INDENTITY)

    const saveFinanceIdentityQueryData=saveFinanceIdentityResponseData;

    const initSaveFinanceIdentityData=(data, successCallback, errorCallback)=>{
        saveFinanceIdentityResponseFunction({
            variables:data,
        }).then((responseData)=>{
            console.log(responseData,"respn")
            if(responseData.data.saveIdentityDocumentForFinance.success){
                successCallback(responseData)
            }else{
                errorCallback(responseData)
            }
        }).catch((error)=>{
            errorCallback(error)
        })
    }

    return {saveFinanceIdentityQueryData,initSaveFinanceIdentityData}
}
