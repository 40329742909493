import React, { useEffect, useState } from 'react';
import { fabric } from 'fabric';
// import drawIcon from '../../../../assets/images/calendar/copyIcon.svg'


const ImageDrawingCanvas = ({
    imageUrl,
    canvasRef,
    fabricRef,
    penColor,
}) => {
    const canvasWidth = 600; // Fixed canvas width
    const canvasHeight = 240;

    useEffect(() => {
        fabricRef.current = new fabric.Canvas(canvasRef.current, {
            width: canvasWidth,
            height: canvasHeight,
            isDrawingMode: true, // Enable free drawing mode
            // freeDrawingCursor: `url(${'http://icons.iconarchive.com/icons/designcontest/vintage/32/Patent-Pen-icon.png'}), auto` // Custom cursor icon
        });

        fabricRef.current.freeDrawingBrush.color = `${penColor}`; // Brush color
        fabricRef.current.freeDrawingBrush.width = 2; // Brush width

        fabric.Image.fromURL(imageUrl, (img) => {
            let scaleFactor;
            if (img.width > canvasWidth || img.height > canvasHeight) {
                scaleFactor = Math.min(canvasWidth / img.width, canvasHeight / img.height);
                img.scale(scaleFactor);
            }

            if (img.width > canvasWidth || img.height > canvasHeight) {
                img.set({
                    left: (canvasWidth - img.width * scaleFactor) / 2,
                    top: (canvasHeight - img.height * scaleFactor) / 2,
                });
            } else {
                img.set({
                    left: (fabricRef.current.width - img.width) / 2,
                    top: (fabricRef.current.height - img.height) / 2
                });
            }
            fabricRef.current.add(img);
            fabricRef.current.centerObject(img)
            fabricRef.current.renderAll();
        });



        return () => {
            // Cleanup code if necessary
            if (fabricRef.current) {
                fabricRef.current.dispose();
            }
        };
    }, [imageUrl]);

    useEffect(() => {
        fabricRef.current.freeDrawingBrush.color = `${penColor}`; // Brush color
    }, [penColor]);


    return <>
        <canvas ref={canvasRef} />
    </>
};

export default ImageDrawingCanvas;