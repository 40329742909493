import React from 'react'
import StepButton from "../common/StepButton"
import "./custom-notification-model.scss"

const CustomNotificationModel = ({
  img,
  title,
  description,
  hours,
  buttonText,
  isbutton,
  id,
  name,
  isRead
}) => {
  console.log("@@@@",isRead)
  return (
    <>
    <div className='CusnotificaionTostNew' key={id} style={isRead ? {backgroundColor:"#FFF"} : {backgroundColor:"#F9F9F9"}}>
       <div className='notiImgwrappersec'>
          <div className='imgTitleWapper'>
            <div className='notiImgWrapper'>
             {
              img !== null ? 
               <img src={img}  />  :
               <div className='letterWrap'>
                 <h5 className='initLetter'>{name[0] + name.split(" ")[1]}</h5>
               </div>
             }
              
                 
            </div>
              <h5>{title}</h5>
         </div>
             <p className='hours'>{hours}</p>
      </div>
      <div className='notiDesc'>
          <p className='description'>{description}</p>
      </div>
     {
       isbutton && (
          <div className='notiBtn'>
               <StepButton label={buttonText} blue={true} />
          </div>
       )
     }
    </div>
    </>
  )
}

export default CustomNotificationModel