import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_HAIR_PRESCRIPTION_LINK_DATA } from "../../constants/hairPrescriptionLink/HairPrescriptionLinkConst";

const GET_HAIR_PRESCRIPTION_LINK_QUERY = gql`
query GetHaiPrescriptionSlug {
  getHaiPrescriptionSlug {
    success
    message
    data {
      full_url
      slug
      is_strip_connected
      is_hair_prescription_intro
    }
  }
}
`;

var page;
export const GetHairPrescriptionLink = () => {
  const dispatch = useDispatch();
  const [getHairPrescriptionLinkData, hairPrescriptionLinkData] = useLazyQuery(
    GET_HAIR_PRESCRIPTION_LINK_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getHaiPrescriptionSlug: responseData }) => {
        dispatch({
          type: GET_HAIR_PRESCRIPTION_LINK_DATA,
          payload: responseData.data,
        });
        if (responseData.success) {
        } else {
        }
      },
    }
  );
  const hairPrescriptionLinkQueryData = hairPrescriptionLinkData;
  const initGetHairPrescriptionLink = (data, successCallback) => {
    getHairPrescriptionLinkData({
      variables: data,
    }).then(({ data: { getHaiPrescriptionSlug: responseData } }) => {
      if (responseData.success) {
        if (successCallback)
          successCallback(responseData?.data);
      }
    });
  };
  return { initGetHairPrescriptionLink, hairPrescriptionLinkQueryData };
};

// ========================SEND HAIR PRESCRIPTION LINK THROUGH EMAIL========================

const SEND_HAIR_PRESCRIPTION_LINK_THROUGH_EMAIL_MUTATION = gql`
mutation SendHairPrescriptionLinkThroughtEmail($emails: [String], $sendToAllClients: Boolean) {
    sendHairPrescriptionLinkThroughtEmail(emails: $emails, sendToAllClients: $sendToAllClients) {
      success
      message
    }
  }
`;


export const SendHairPrescriptionLinkThroughEmailAction = () => {
  const dispatch = useDispatch();
  const [
    SendHairPrescriptionLinkThroughEmailResponseFunction,
    sendHairPrescriptionLinkThroughEmailResponseData,
  ] = useMutation(SEND_HAIR_PRESCRIPTION_LINK_THROUGH_EMAIL_MUTATION)

  //this will be called from components
  const sendHairPrescriptionLinkThroughEmailQueryData = sendHairPrescriptionLinkThroughEmailResponseData; //variable

  const initSendHairPrescriptionLinkThroughEmailData = (data, successCallback, errorCallback) => {
    SendHairPrescriptionLinkThroughEmailResponseFunction({
      variables: data,
    }).then(({ data: { sendHairPrescriptionLinkThroughtEmail: responseData } }) => {
      if (responseData.success) {
        if (successCallback)
          successCallback(responseData)
        //  toast.success(responseData.message)
      }
      else {
        if (errorCallback)
          errorCallback()
        // toast.error(responseData.message)
      }
    }).catch(() => {
      if (errorCallback)
        errorCallback()
      // toast.error("something went wrong!!!")
    })
  }

  return { sendHairPrescriptionLinkThroughEmailQueryData, initSendHairPrescriptionLinkThroughEmailData };
};

// ========================SEND HAIR PRESCRIPTION THROUGH SMS========================

const SEND_HAIR_PRESCRIPTION_LINK_THROUGH_SMS_MUTATION = gql`
mutation SendHairPrescriptionLinkThroughSms($contacts: [String], $sendToAllClients: Boolean) {
    sendHairPrescriptionLinkThroughSms(contacts: $contacts, sendToAllClients: $sendToAllClients) {
      success
      message
    }
  }
`;


export const SendHairPrescriptionLinkThroughSMSAction = () => {
  const dispatch = useDispatch();
  const [
    SendHairPrescriptionLinkThroughSMSResponseFunction,
    sendHairPrescriptionLinkThroughSMSResponseData,
  ] = useMutation(SEND_HAIR_PRESCRIPTION_LINK_THROUGH_SMS_MUTATION)

  //this will be called from components
  const sendHairPrescriptionLinkThroughSMSQueryData = sendHairPrescriptionLinkThroughSMSResponseData; //variable

  const initSendHairPrescriptionLinkThroughSMSData = (data, successCallback, errorCallback) => {
    SendHairPrescriptionLinkThroughSMSResponseFunction({
      variables: data,
    }).then(({ data: { sendHairPrescriptionLinkThroughSms: responseData } }) => {
      if (responseData.success) {
        if (successCallback)
          successCallback(responseData)
        //  toast.success(responseData.message)
      }
      else {
        if (errorCallback)
          errorCallback()
        // toast.error(responseData.message)
      }
    }).catch(() => {
      if (errorCallback)
        errorCallback()
      // toast.error("something went wrong!!!")
    })
  }

  return { sendHairPrescriptionLinkThroughSMSQueryData, initSendHairPrescriptionLinkThroughSMSData };
};



// ======================== PRINT HAIR PRESCRIPTION QR ========================

const GET_HAIR_PRESCRIPTION_LINK_QR_QUERY = gql`
mutation GenrateHairPrescriptionQrPdf {
  genrateHairPrescriptionQrPdf {
    success
    message
    data {
      full_url
      slug
    }
  }
}
`;

export const GetHairPrescriptionLinkQRPDF = () => {
  const dispatch = useDispatch();
  const [getHairPrescriptionLinkQRData, HairPrescriptionLinkQRData] = useMutation(GET_HAIR_PRESCRIPTION_LINK_QR_QUERY);
  const HairPrescriptionLinkQRQueryData = HairPrescriptionLinkQRData;
  const initGetHairPrescriptionLinkQR = (data,successCallback, errorCallback) => {
    getHairPrescriptionLinkQRData().then(({ data: { genrateHairPrescriptionQrPdf: responseData } }) => {
      if (responseData.success) {
        if (successCallback)
          successCallback(responseData)
        //  toast.success(responseData.message)
      }
      else {
        if (errorCallback)
          errorCallback()
        // toast.error(responseData.message)
      }
    }).catch(() => {
      if (errorCallback)
        errorCallback()
      // toast.error("something went wrong!!!")
    })
  };
  return { initGetHairPrescriptionLinkQR, HairPrescriptionLinkQRQueryData };
};