import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";

const GET_BATCH_NUMBERS = gql`
query GetBatchNumbers($consentFormRecordId: Int, $treatmentId: Int) {
  getBatchNumbers(consentFormRecordId: $consentFormRecordId, treatmentId: $treatmentId) {
    success
    message
    data {
      treatmentId
      consentFormRecordId
      batchNumbers {
        id
        batchNumber
      }
    }
  }
}
`;

export const GetBatchNumbersAction = () => {
    const dispatch = useDispatch();
    const [
        GetBatchNumbersResponseFunction,
        getBatchNumbersResponseData,
    ] = useLazyQuery(GET_BATCH_NUMBERS, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getBatchNumbers: responseData }) => {
            if (responseData.success) {
                // dispatch({
                //     type: GET_RATING_REVIEWS_CONST,
                //     payload: responseData
                // })
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getBatchNumbersQueryData = getBatchNumbersResponseData; //variable

    const initGetBatchNumberData = (data, successCallback, errorCallback) => {
        GetBatchNumbersResponseFunction({
            variables: data,
        }).then((res) => {
            successCallback(res?.data?.getBatchNumbers);
        }).catch((err) => {
            errorCallback();
        });
    };

    return { getBatchNumbersQueryData, initGetBatchNumberData };
};