import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_APPOINTMENT_DETAILS_DATA } from "../../constants/appointment/appointmentConst";
import { GET_APPOINTMENT_DETAILS, GET_APPOINTMENT_DETAILS_SUCCESS, SET_APPOINTMENT_DOCUMENT_TAB_DATA, SET_APPOINTMENT_MANAGE_TAB_DATA } from "../../constants";

const GET_APPOITMENT_DETAILS_QUERY = gql`
query GetAppointmentDetails($bookingId: Int!) {
  getAppointmentDetails(booking_id: $bookingId) {
    success
    message
    data {
      appointment_details {
        booking_id
        consent_form_id
        client_image
        deposit_status
        deposit_status_color
        auto_cancel_in_days
        auto_cancel_in_days_and_hours_and_minutes
        treatment_date
        start_time
        end_time
        treatment_duration
        client_fname
        client_lname
        client_email
        client_id
        mobile_number
        country_code
        clininc_address
        location_name
        practitioner_name
        discount
        tip
        sub_total
        order_total
        photos_count
        treatments_list {
          id
          name
          price
        }
        booked_by_fname
        booked_by_lname
        deposit_amount
        due_amount
        deposit_request_status
        followUpHours
        followUpDate
      }
      booking_status_details {
        consent_form_date
        consent_form_status
        consent_form_color
        payment_deposit_date
        payment_deposit_status
        payment_deposit_color
        payment_deposit_status_label
        appointment_creation_date
        batch_status
        after_care_status
        notes_status
        facial_mapping_status
        photos_status
        medical_status
        payment_status_data {
          type
          type_label
          status
          label
          color
          date
        }
        consentFormAvailable
        displayPaymentStatus
        isFullyPaidStatus
        fullyPaidOnDate
        isDepositPaidStatus
      }
      pay_monthly
      collect_payment
      SMS_reminder
      email_reminder
      depositRequestable
    }
  }
}
`;

var page;
export const GetAppointmentDetailsQuery = () => {
  const dispatch = useDispatch();
  const [getAppointmentDetailsData, appointmentDetailsData] = useLazyQuery(
    GET_APPOITMENT_DETAILS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getAppointmentDetails: responseData }) => {
        if (responseData.success) {
          console.log("responseData.data", responseData.data)
          dispatch({
            type: GET_APPOINTMENT_DETAILS_SUCCESS,
            payload: responseData.data,
            page,
          });
        } else {
        }
      },
    }
  );
  const appointmentDetailsQueryData = appointmentDetailsData;
  const initGetAppointmentDetails = (data) => {
    page = data?.page ? data?.page : 1;
    getAppointmentDetailsData({
      variables: data,
    })
  };
  return { initGetAppointmentDetails, appointmentDetailsQueryData };
};


// ========================REBOOK APPOINTMENT========================

const REBOOK_APPOINTMENT_MUTATION = gql`
mutation RebookAppointment($appointmentId: Int, $appointmentDate: String, $startTime: String, $endTime: String, $treatmentData: [rebookTreatmentData], $subTotal: Float, $discountCodeType: rebookDiscountCodeType, $discountValue: Float, $depositAmount: Float, $totalAmount: Float, $depositRequired: Boolean, $paymentType: Int) {
  rebookAppointment(appointmentId: $appointmentId, appointmentDate: $appointmentDate, startTime: $startTime, endTime: $endTime, treatmentData: $treatmentData, subTotal: $subTotal, discountCodeType: $discountCodeType, discountValue: $discountValue, depositAmount: $depositAmount, totalAmount: $totalAmount, depositRequired: $depositRequired, paymentType: $paymentType) {
    success
    message
    data {
      appointmentId
    }
  }
}`;


export const RebookAppointmentAction = () => {
  const dispatch = useDispatch();
  const [
    RebookAppointmentResponseFunction,
    rebookAppointmentResponseData,
  ] = useMutation(REBOOK_APPOINTMENT_MUTATION)

  //this will be called from components
  const rebookAppointmentQueryData = rebookAppointmentResponseData; //variable

  const initRebookAppointmentData = (data, successCallback, errorCallback) => {
    RebookAppointmentResponseFunction({
      variables: data,
    }).then(({ data: { rebookAppointment: responseData } }) => {
      if (responseData.success) {
        if (successCallback)
          successCallback(responseData)
        //  toast.success(responseData.message)
      }
      else {
        if (errorCallback)
          errorCallback()
        // toast.error(responseData.message)
      }
    }).catch(() => {
      if (errorCallback)
        errorCallback()
      // toast.error("something went wrong!!!")
    })
  }

  return { rebookAppointmentQueryData, initRebookAppointmentData };
};


// ========================GET APPOINTMENT DETAILS FOR DOCUMENT TAB========================

const GET_APPOINTMENT_DETAILS_FOR_DOCUMENT_TAB_QUERY = gql`
query GetAppointmentDetailsForDocumentTab($appointmentId: Int) {
  getAppointmentDetailsForDocumentTab(appointmentId: $appointmentId) {
    success
    message
    data {
      medicalStatus
      consentFormStatus
      batchStatus
      aftercareStatus
      facialMappingStatus
      photosStatus
      notesStatus
    }
  }
}
`;

export const GetAppointmentDetailsForDocumentTabAction = () => {
  const dispatch = useDispatch();
  const [
    GetAppointmentDetailsForDocumentTabResponseFunction,
    getAppointmentDetailsForDocumentTabResponseData,
  ] = useLazyQuery(GET_APPOINTMENT_DETAILS_FOR_DOCUMENT_TAB_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getAppointmentDetailsForDocumentTab: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: SET_APPOINTMENT_DOCUMENT_TAB_DATA,
          payload: responseData
        })
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getAppointmentDetailsForDocumentTabQueryData = getAppointmentDetailsForDocumentTabResponseData; //variable

  const initGetAppointmentDetailsForDocumentTabData = (data) => {
    GetAppointmentDetailsForDocumentTabResponseFunction({
      variables: data,
    });
  };

  return { getAppointmentDetailsForDocumentTabQueryData, initGetAppointmentDetailsForDocumentTabData };
};

// ========================GET APPOINTMENT DETAILS FOR MANAGE TAB========================

const GET_APPOINTMENT_DETAILS_FOR_MANAGE_TAB_QUERY = gql`
query GetAppointmentDetailsForManageTab($appointmentId: Int) {
  getAppointmentDetailsForManageTab(appointmentId: $appointmentId) {
    success
    message
    data {
      holdOn
      followup
      followupType
      payMonthly
      collectPayment
      SMSReminder
      emailReminder
      depositRequestable
    }
  }
}
`;

export const GetAppointmentDetailsForManageTabAction = () => {
  const dispatch = useDispatch();
  const [
    GetAppointmentDetailsForManageTabResponseFunction,
    getAppointmentDetailsForManageTabResponseData,
  ] = useLazyQuery(GET_APPOINTMENT_DETAILS_FOR_MANAGE_TAB_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getAppointmentDetailsForManageTab: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: SET_APPOINTMENT_MANAGE_TAB_DATA,
          payload: responseData
        })
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getAppointmentDetailsForManageTabQueryData = getAppointmentDetailsForManageTabResponseData; //variable

  const initGetAppointmentDetailsForManageTabData = (data) => {
    GetAppointmentDetailsForManageTabResponseFunction({
      variables: data,
    });
  };

  return { getAppointmentDetailsForManageTabQueryData, initGetAppointmentDetailsForManageTabData };
};


// ========================REQUEST DEPOSIT FOR APPOINTMENT========================

const REQUEST_DEPOSIT_FOR_APPOINTMENT_MUTATION = gql`
mutation RequestDepositForAppointment($appointmentId: Int, $depositAmount: Float) {
  requestDepositForAppointment(appointmentId: $appointmentId, depositAmount: $depositAmount) {
    success
    message
  }
}
`;


export const RequestDepositForAppointmentAction = () => {
  const dispatch = useDispatch();
  const [
    RequestDepositForAppointmentResponseFunction,
    requestDepositForAppointmentResponseData,
  ] = useMutation(REQUEST_DEPOSIT_FOR_APPOINTMENT_MUTATION)

  //this will be called from components
  const requestDepositForAppointmentQueryData = requestDepositForAppointmentResponseData; //variable

  const initRequestDepositForAppointmentData = (data, successCallback, errorCallback) => {
    RequestDepositForAppointmentResponseFunction({
      variables: data,
    }).then(({ data: { requestDepositForAppointment: responseData } }) => {
      if (responseData.success) {
        if (successCallback)
          successCallback(responseData)
        //  toast.success(responseData.message)
      }
      else {
        if (errorCallback)
          errorCallback()
        // toast.error(responseData.message)
      }
    }).catch(() => {
      if (errorCallback)
        errorCallback()
      // toast.error("something went wrong!!!")
    })
  }

  return { requestDepositForAppointmentQueryData, initRequestDepositForAppointmentData };
};


