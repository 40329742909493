import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
  AddressElement,
  PaymentMethodMessagingElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import visa from "../../../assets/card/visa-payment.png"
import mastercard from "../../../assets/card/Mastercard-Logo.png"
import { config } from "../../../config";

// import NewCard from "../cart/clinic/payment/NewCard";


const CheckoutForm = ({ clientSecret, successCallback, failureCallback, setProcess, changePaymentType, isCardPaymentType, savedCardId, callbackForSavedCardPayment, useSetupIntentFlow, isStripeFormFiledDisabled ,validationErrors, isFormDataIsValidForCard,preCallbackFuncton,LateIntentFunction,customButton, cardLogo }) => {
  const stripe = useStripe();
  const elements = useElements();
console.log("validationErrors=======",validationErrors)

  const [errorMessage, setErrorMessage] = useState(null);
  const [showLoader, setLoader] = useState(false);

  const handleSubmitPayment = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    console.log('is Card payment type form stripe payment page: ', isCardPaymentType);

    event.preventDefault();

    console.log(preCallbackFuncton);
    if(preCallbackFuncton){
      var res = preCallbackFuncton();
      if(res == false){
        return;
      }else{

        if (isCardPaymentType) {
          setProcess("Processing...")
        }else{
          const cardElement = elements.getElement(CardElement);
          console.log('cardElement: ', cardElement);
          if (cardElement) {
            const result = await stripe.createToken(cardElement);
            console.log('result: ', result);
            if (result.error) {
              console.log('result.error: ', result.error);
              setErrorMessage(result.error.message);
              return;
            }
          }
  
          setErrorMessage("");
  
          setLoader(true);
          setProcess("Processing...")
  
          if (typeof isFormDataIsValidForCard === 'function') {
            console.log("isFormDataIsValidForCard--",isFormDataIsValidForCard() ,typeof isFormDataIsValidForCard())
            if (!isFormDataIsValidForCard()) {
              return;
            }
          }
          if(!clientSecret){
            LateIntentFunction().then(async res=>{
              console.log("res1",res);
              const { error, setupIntent } = await stripe.confirmCardSetup(res.intentId, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
                return_url: window.location.href
              });

              if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                //   setErrorMessage(error.message);
                setErrorMessage(error.message);
                setProcess("Complete Payment")
                setLoader(false);
                failureCallback();
              } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
                if (
                  setupIntent.status === "succeeded" ||
                  setupIntent.status === "requires_capture"
                ) {
                  if (successCallback) {
                    setProcess("Complete Payment")
                    
                    console.log('successCallback:', setupIntent);
                    successCallback(setupIntent.id, setupIntent.payment_method,false);
                    // Perform further processing with the token
                    // e.g., save the token to the customer in your database              
                  }
                }
              }
            
            }).catch(err=>{
              console.log("errtest",err);
              setErrorMessage(err);
              setProcess("Complete Payment")
              setLoader(false);
            });
            return;
          }
        }
      }
    }


    if (typeof isFormDataIsValidForCard === 'function') {
      console.log("isFormDataIsValidForCard--",isFormDataIsValidForCard() ,typeof isFormDataIsValidForCard())
      if (!isFormDataIsValidForCard()) {
        return;
      }
    }

    if (!isCardPaymentType) {
      console.log('preform action for new card payment');
      // if (!stripe || !elements) {
      //   // Stripe.js has not yet loaded.
      //   // Make sure to disable form submission until Stripe.js has loaded.
      //   return;
      // }

      // if (isStripeFormFiledDisabled) {
      //   return;
      // }

      setErrorMessage("");

      setLoader(true);
      setProcess("Processing...")

      if(useSetupIntentFlow) {
        const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
              card: elements.getElement(CardElement),
          },
          return_url: window.location.href
        });

        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          //   setErrorMessage(error.message);
          setErrorMessage(error.message);
          setProcess("Complete Payment")
          setLoader(false);
          failureCallback();
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
          if (
            setupIntent.status === "succeeded" ||
            setupIntent.status === "requires_capture"
          ) {
            if (successCallback) {
              setProcess("Complete Payment")
              
              console.log('successCallback:', setupIntent);
              successCallback(setupIntent.id, setupIntent.payment_method);
              // Perform further processing with the token
              // e.g., save the token to the customer in your database              
            }
          }
        }
      }
      else {
        console.log('here clientSecret: ', clientSecret);
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          clientSecret,
          {
            //`Elements` instance that was used to create the Payment Element
            // elements,
            // confirmParams: {
            //   return_url: window.location.href,
            // },
            payment_method: {
              card: elements.getElement(CardElement),
            },
  
            return_url: window.location.href,
          }
        );
  
        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          //   setErrorMessage(error.message);
          setErrorMessage(error.message);
          setProcess("Complete Payment")
          setLoader(false);
          failureCallback();
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
          if (
            paymentIntent.status === "succeeded" ||
            paymentIntent.status === "requires_capture"
          ) {
            if (successCallback) {
              setProcess("Complete Payment")
              stripe.createToken(elements.getElement(CardElement)).then(function (result) {
                if (result.error) {
                  // Handle error
                  console.error(result.error);
                } else {
                  var token
                  // Send the token to your server
                  token = result.token;
                  console.log('successCallback:', paymentIntent);
                  console.log('successCallback token:', token);
                  successCallback(paymentIntent.id, token.id);
                  // Perform further processing with the token
                  // e.g., save the token to the customer in your database
                }
              })
            }
          }
        }
      }

      
    } else {
      console.log('preform action for saved card payment for cardId: ', savedCardId, isStripeFormFiledDisabled, typeof isStripeFormFiledDisabled);
      // if (isStripeFormFiledDisabled) {
      //   return;
      // }
      setErrorMessage("");
      setLoader(true);
      setProcess("Processing...")
      callbackForSavedCardPayment();
    }
  };

  return (
    <form id='paymentForm' onSubmit={(e) => handleSubmitPayment(e)}>
      {/* <NewCard/> */}

      <CardElement
        className="checkoutcard-field mx-2"
        options={{
          hidePostalCode: true,
          style: {
            //your style
          },
          classes: {
            //your classname

          }

        }}
        onChange={changePaymentType}
      />

      {/* Show error message to your customers */}
      {errorMessage && <div className="card-invalid-text">{errorMessage}</div>}

      {/* <button disabled={!stripe}>Submit</button> */}
      {showLoader ? (
        <center>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px', marginTop: '10px' }}>
            <div style={{ marginRight: '5px' }}>
              <div className="spinner-border text-primary" role="status" style={{ height: '20px', width: '20px' }}>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            <div>
              Processing...
            </div>
          </div>
        </center>
      ) : (
        <>
          {/* <button type="submit" style={{ visibility: 'none' }} disabled={!stripe || showLoader}>
            Pay Now
          </button> */}
          {cardLogo ? "" :
            <div className="CardLogo">
              <img src={mastercard}></img>
              <img src={visa}></img>
            </div>
          }
          <div className="text-center mt-3">
            {customButton ? customButton : (
                  <button type="submit" id="socialMediaMarketingMakePaymentBtn" className='btn custom-btn btnredsm justify-content-center' style={{ maxWidth: '190px', minWidth: '190px' }} form="paymentForm" >Make Payment</button>
            )}

          </div>
        </>
      )}

      {/* Show error message to your customers */}
      {/* {errorMessage && <div className="card-invalid-text">{errorMessage}</div>} */}
    </form>
  );
};

export default CheckoutForm;