import { gql, useMutation } from "@apollo/client";

const SAVE_LOCATION_FIXED_HOURS_AVAILABILITY_MUTATION = gql`
mutation SetLocationFixedHoursAvailability($availability: [inputAvailability], $locationId: Int) {
  setLocationFixedHoursAvailability(availability: $availability, locationId: $locationId) {
    success
    message
  }
}
`;


export const SaveLocationFixedHoursAvailabilityAction = () => {
    const [
        SaveLocationFixedHoursAvailabilityResponseFunction,
        saveLocationFixedHoursAvailabilityResponseData,
    ] = useMutation(SAVE_LOCATION_FIXED_HOURS_AVAILABILITY_MUTATION)

    //this will be called from components
    const saveLocationFixedHoursAvailabilityQueryData = saveLocationFixedHoursAvailabilityResponseData; //variable

    const initSaveLocationFixedHoursAvailabilityData = (data, successCallback, errorCallback) => {
        SaveLocationFixedHoursAvailabilityResponseFunction({
            variables: data,
        }).then(({ data: { setLocationFixedHoursAvailability: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback(responseData)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
        })
    }

    return { saveLocationFixedHoursAvailabilityQueryData, initSaveLocationFixedHoursAvailabilityData };
};