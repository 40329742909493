import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";

import { ReactComponent as CloseIcon } from "../../images/modal/Close.svg";
import "./custom-autocomplete.scss";
import { useDispatch, useSelector } from "react-redux";
import { getClientsData } from "../redux/actions/dashboard/Reviews";
import Skeleton from "react-loading-skeleton";

// const icon = <UnCheckedIcon fontSize="small" />;
// const checkedIcon = <CheckedIcon fontSize="small" />;


export default function CustomAutoComplete({ optionValue, value, setValue, handleOptionChange, setSearchString, groupBy, handleInputClick, handleScrollCallback, listRef, loading = "currentPage", disableCloseOnSelect = false, searchStringTxt, className, style, placeholder }) {

    const { getClientsDataSkeletonFlag } = useSelector((state) => state.GetReviewsDataReducer)

    const autocompleteref = React.useRef(null);

    let autocompleteProps = {};

    if (loading == 'currentPage') {
        if (getClientsDataSkeletonFlag) {
            autocompleteProps['renderOption'] = (params, option) => {
                return (
                    <>
                        {
                            getClientsDataSkeletonFlag ?

                                [...Array(6)].map(i => <Skeleton width={200} height={20} />)
                                :
                                <div className="p-2">
                                    <li key={params.key}>
                                        <span>{option.title}</span>
                                    </li>
                                </div>
                        }
                    </>
                )
            };
        }
    } else {
        if (loading) {
            autocompleteProps['renderOption'] = (params, option) => {
                return (
                    <>
                        {
                            loading ?

                                [...Array(6)].map(i => <Skeleton width={200} height={20} />)
                                :
                                <div className="p-2">
                                    <li key={params.key}>
                                        <span>{option.title}</span>
                                    </li>
                                </div>
                        }
                    </>
                )
            };
        }
    }



    return (
        <div className="autocomplete-BG-heght">
            <div className={`${className}`}>
                {
                    groupBy ?
                        <Autocomplete
                            style={style}
                            multiple
                            // defaultValue={{id:1,label:"hi"}}
                            id="grouped-demo"
                            options={optionValue?.length ? optionValue : [{ title: "loader" }]}
                            groupBy={groupBy ? (option) => option.category : null}
                            getOptionLabel={(option) => option?.title}
                            sx={{ width: 300 }}
                            value={value} // Set the default value(s) here
                            onChange={handleOptionChange}
                            renderInput={(params) => {
                                return (
                                    <TextField placeholder={placeholder}  {...params} label="" onClick={() => handleInputClick ? handleInputClick() : null} />
                                )
                            }
                            }
                            renderGroup={(params) => {
                                return (
                                    <>
                                        <div className="p-2">
                                            <li key={params.key}>
                                                {
                                                    groupBy &&
                                                    <h6 className="fw-bold">{params.group}</h6>
                                                }
                                                <span>{params.children}</span>
                                            </li>
                                        </div>
                                    </>
                                )
                            }}
                            {...autocompleteProps}
                        />
                        :
                        <Autocomplete
                            multiple
                            onClose={() => setSearchString(null)}
                            ref={autocompleteref}
                            id="grouped-demo"
                            options={optionValue?.length ? optionValue : [{ title: `${loading = "currentPage" ? getClientsDataSkeletonFlag ? "loader" : "No Client Found" : loading ? "loader" : "No Client Found"}` }]}
                            groupBy={groupBy ? (option) => option.category : null}
                            getOptionLabel={(option) => option?.title}
                            sx={{ width: 300 }}
                            value={value} // Set the default value(s) here
                            filterOptions={(x) => x} // No need to filter options
                            onChange={handleOptionChange}
                            disableCloseOnSelect={disableCloseOnSelect}
                            ListboxProps={{
                                onScroll: handleScrollCallback,
                                ref: listRef,
                            }}
                            renderInput={(params) => {
                                console.log("pppp", params)
                                return (
                                    <TextField {...params} label="" onClick={() => handleInputClick ? handleInputClick() : null} onChange={(e) => { console.log("search on change", e.target.value); setSearchString(e.target.value) }} />
                                )
                            }
                            }
                            renderOption={(params, option) => {
                                return (
                                    <>
                                        <div className="p-2" {...params} role="list-box">
                                            {
                                                // getClientsDataSkeletonFlag ? [...Array(6)].map(i => <li><Skeleton width={320} height={20} /></li>) :
                                                // option == undefined ?
                                                option == undefined ?
                                                    <li>
                                                        <Skeleton width={320} height={20} />
                                                    </li> :
                                                    <li key={params.key}>
                                                        <span>{option.title}</span>
                                                    </li>
                                            }
                                        </div>
                                    </>
                                )
                            }}
                            renderGroup={(params) => {
                                return (
                                    <>
                                        <div className="p-2">
                                            <li key={params.key}>
                                                {
                                                    groupBy &&
                                                    <h6 className="fw-bold">{params.group}</h6>
                                                }
                                                <span>{params.children}</span>
                                            </li>
                                        </div>
                                    </>
                                )
                            }}
                        />
                }
            </div >
        </div >
    );
}

