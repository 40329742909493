import { useRef, useState } from 'react';
import { ReactComponent as EditIcon } from '../../../../images/edit-form-edit.svg';
import {
    SaveConsentFormDetailsAction,
} from '../../../redux/actions/walk-in-form/editConsentFormQuery';
import showToast from '../../../common/CustomToast/CustomToast';

function EditDisclaimer({ data, cnfId, defaultEdit, canEdit, handleEditMode, onChange }) {
    const [isEditing, setIsEditing] = useState(defaultEdit);
    const inputRef = useRef(null);
    // const [disclaimer, setDisclaimer] = useState(data);

    const { initSaveConsentFormDetails, saveConsentFormDetailsStatus } =
        SaveConsentFormDetailsAction();

    const switchonEditMode = () => {
        if(!canEdit) {
            return;
        }
        handleEditMode(true);
        setIsEditing(true);
        setTimeout(() => {
            inputRef.current.focus();
        }, 0);
    };

    const handleEditComplete = () => {
        if(data?.length === 0) {
            showToast('error', 'Please enter disclaimer');
            return;
        }
        setIsEditing(false);
        handleEditMode(false);

        // const payload = {
        //     consentFormId: +cnfId,
        //     disclaimer: disclaimer,
        // };
     
        // const onError = () => {
        //     console.log('que-edit-res: failed to update question');
        //     setDisclaimer(data);
        // };
        // initSaveConsentFormDetails(payload, undefined, onError);
    };

    return (
        <div>
            <div className={`question-box question-box--disclaimer ${isEditing ? 'question-box--edit' : ''}`}>
                <textarea
                    // type="text"
                    ref={inputRef}
                    value={data}
                    onChange={(e) => onChange(e.target.value)}
                    disabled={!isEditing}
                    placeholder='Enter disclaimer text'
                    id={'disclaimer-text'}
                    className="question-box__text question-box__input"
                    style={{ border: 'none', background: 'none', width: '100%' }}
                />
                <div className="question-box__actions">
                    {/* {isEditing && (
                        <button
                            onClick={handleEditComplete}
                            className="question-box__btn question-box__btn--done"
                        >
                            Done
                        </button>
                    )} */}
                    {!isEditing && (
                        <button disabled={!canEdit} onClick={switchonEditMode} className="question-box__btn question-box__btn--done">
                            Edit
                            {/* <EditIcon /> */}
                        </button>
                    )}
                </div>
            </div>
            <div className="question-box__actions question-box__actions--edit">
                {isEditing && (
                    <button
                        disabled={data?.length === 0}
                        onClick={handleEditComplete}
                        className="question-box__btn question-box__btn--done"
                    >
                        Done
                    </button>
                )}
            </div>
        </div>
    );
}

export default EditDisclaimer;
