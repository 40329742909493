import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";


const SEND_EMAIL_TO_CLIENT_MUTATION = gql`
mutation SendCustomEmailToClient($clientId: Int!, $emailSubject: String!, $emailContent: String!) {
    sendCustomEmailToClient(clientId: $clientId, emailSubject: $emailSubject, emailContent: $emailContent) {
      success
      message
    }
  }
`;

export const SendEmailToClientMutation = () => {
  const [sendEmailToClient, sendEmailToClientResponseData] =
    useMutation(SEND_EMAIL_TO_CLIENT_MUTATION);
  const dispatch = useDispatch();
  const sendEmailToClientMutationData = sendEmailToClientResponseData;

  const initiateSendEmailToClient= (
    data,
    successCallback,
    errorCallback
  ) => {
    sendEmailToClient({
      variables: data,
    })
      .then(({ data: { sendCustomEmailToClient: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };
  return { initiateSendEmailToClient, sendEmailToClientMutationData };
};