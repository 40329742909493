import React, { useEffect, useRef, useState } from "react";
import "./rebook-appointment.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EmptyClient from "../../../Client/EmptyClient";
import CustomRadioButton from "../../../common/CustomRadioButton";
import { ReactComponent as MorningIcon } from "../../../../images/icons/morning_icon.svg";
import { ReactComponent as AfterNoonIcon } from "../../../../images/icons/after_noon_icon.svg";
import { ReactComponent as EveningIcon } from "../../../../images/icons/evening_icon.svg";
import { ReactComponent as PrevIcon } from "../../../../images/icons/slick_next.svg";
import { ReactComponent as NextIcon } from "../../../../images/icons/slick_next.svg";
import StepButton from "../../../common/StepButton";
import moment from "moment";
import CustomModal from "../../../modal/CustomModal";
import CustomCalendar from "../../dateTime/steps/CustomCalendar";

import { ReactComponent as CloseIcon } from '../../../../images/back_arrow.svg'
import { ReactComponent as CalenderIcon } from '../../../../images/icons/calender_mini_icon.svg'
import ConfirmRebooking, { confirmRebook } from "./confirm-rebooking/ConfirmRebooking";
import { useLocation, useNavigate, useParams } from "react-router";
import { GetAppointmentDetailForRebookAction } from "../../../redux/actions/rebook-appointment/RebookAppointment";
import { GetAvailableTimeSlotsAction, GetCalendarAppointmentCountAction, GetClinicAvailableDateAction, IsAlreadyBookingExistAction } from "../../../redux/actions/dateTimeslots/DateTimeSlots";
import Skeleton from "react-loading-skeleton";
import { getClientProfileFormsRoute, getClientProfileRoute } from "../../../../Routes";
import { TIME, generateCustomDates } from "../../../../utils/general";
import { CustomCalendarSkeleton } from "../../../clininc-cart-skeleton/calenadar-skeleton/CustomCalendarSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { SET_TIME_SLOTS_FOR_CREATE_APPOINTMENT } from "../../../redux/constants/dateTimeslots/DateTimeSlots";
import showToast from "../../../common/CustomToast/CustomToast";
import SkeletonLoader from "../../../common/Skeleton/SkeletonLoader";
import useWindowDimension from "../../../../utils/useWindowDimensions";
import CustomeHeading from '../../../customeHeading/customeHeading'


const RebookAppointment = () => {

  //=================================STATIC VARIABLES=================================

  const { appointmentId, clientId } = useParams()
  const sliderRef = useRef(null);

  const navigate = useNavigate()


  //Slider Setting for Week Slots
  var settingsWeek = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          // dots: true
        },
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 576,

        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 414,

        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 390,

        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  var settingsDate = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    draggable: false,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          // dots: true
        },
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 576,

        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 414,

        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 390,

        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  var weekArray = [
    "1 week",
    "2 week",
    "3 week",
    "4 week",
    "5 week",
    "6 week",
    "7 week",
  ];

  // Time slots for morning, afternoon, and evening
  // const morningSlots = ["9:00 AM", "10:00 AM", "11:00 AM"];
  // const afternoonSlots = ["1:00 PM", "2:00 PM", "3:00 PM"];
  // const eveningSlots = ["5:00 PM", "6:00 PM", "7:00 PM"];

  // ==================================================================


  //=================================STATES=================================
  const [step, setStep] = useState(1)

  //CALENDAR MODAL
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  // const [showSelectedDate, setShowSelectedDate] = useState(moment().clone().add(7, 'days').format("dddd, Do MMMM YYYY"));
  const [showSelectedDate, setShowSelectedDate] = useState(null);
  // const [calendarStartDate, setCalendarStartDate] = useState(moment().clone().add(7, 'days').format("YYYY-MM-DD"));
  const [calendarStartDate, setCalendarStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(moment().clone().add(7, 'days').format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [dateData, setDateData] = useState([]) //modal calendar dates
  const [selectedMonth, setSelectedMonth] = useState(
    selectedDate.getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
  const [selectedWeek, setSelectedWeek] = useState("1 week");
  // const [selectedWeek, setSelectedWeek] = useState(null);
  // const [selectedDateForTime, setSelectedDateForTime] = useState(moment().clone().add(7, 'days').format("ddd DD MMM YYYY"));
  const [selectedDateForTime, setSelectedDateForTime] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [twoWeekDates, setTwoWeekDates] = useState([]);
  //handle navigation
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  //clinic id and trements info object
  const [clinicIdAndTreatmentsInfo, setClinicIdAndTreatmentsInfo] = useState(null)

  const [apiDatesData, setApiDatesData] = useState([]) //api dates data

  //TIME SLOTS
  const [morningSlots, setMorningSlots] = useState([])
  const [afternoonSlots, setAfternoonSlots] = useState([])
  const [eveningSlots, setEveningSlots] = useState([])

  //SAVE LOADING
  const [saveLoading, setSaveLoading] = useState(false)
  const [isAvailableForWorking, setIsAvailableForWorking] = useState(false);
  const [isOutsideAvaibility, setIsOutsideAvaibility] = useState(false)

  const [isFromClientForm, setIsFromClientForm] = useState(false);

  const dispatch = useDispatch();
  const availableAndOutsideAvaibilityTimeSlots = useSelector(
    (state) => state?.GetDateTimeSlotsReducer?.availableAndOutsideAvaibilityTimeSlots
  );

  const timeslots = useSelector(
    (state) => state?.GetDateTimeSlotsReducer?.timeslots
  );
  const timeslotsData = useSelector(
    (state) => state?.GetDateTimeSlotsReducer?.timeslotsData
  );
  // ==================================================================



  //=================================ACTIONS=================================

  const { initGetAppointmentDetailForRebookData ,getAppointmentDetailForRebookQueryData} = GetAppointmentDetailForRebookAction()
  const { clinicsAvailableDateData, initClinicsAvailableDateResponseFunction } = GetClinicAvailableDateAction();
  const { clinicsAvailableDateData: clinicsAvailableDateData1, initClinicsAvailableDateResponseFunction: initClinicsAvailableDateResponseFunction1 } = GetClinicAvailableDateAction();
  const { initAvalableTimeSlotData, availableTimeSlotData } = GetAvailableTimeSlotsAction()

  const { getCalendarAppointmentCountQueryData, initGetCalendarAppointmentCountData } = GetCalendarAppointmentCountAction()
  const { isAlreadyBookingExistQueryData, initIsAlreadyBookingExistData } = IsAlreadyBookingExistAction()

  //APPOINTMENT OVERLAPPING MODAL STATE
  const [isAppointmentOverlappingOpen, setIsAppointmentOverlappingOpen] = useState(false)
  const [overLappingMessage, setOverLappingMessage] = useState("")
  const location = useLocation()

  // ==================================================================
  //=================================SELECTORS=================================

  // ==================================================================


  //=================================FUNCTIONS=================================

  const handleChangeWeek = (week) => {
    const weekWithoutWord = week.replace("week", "").trim();
    setSelectedWeek(week);
  };
  const handleTimeSlotsData = () => {
    const timingData = TIME();
    timingData.forEach((timeSlot) => {
      const time = moment(timeSlot, 'h:mm A');
      const morningBoundary = moment('12:00 PM', 'h:mm A');
      const afternoonBoundary = moment('6:00 PM', 'h:mm A');

      if (time.isBefore(morningBoundary)) {
        setMorningSlots((morningSlots) => [...morningSlots, { time_slot: timeSlot, isBooked: false }]);
      } else if (time.isBefore(afternoonBoundary)) {
        setAfternoonSlots((afternoonSlots) => [...afternoonSlots, { time_slot: timeSlot, isBooked: false }]);
      } else {
        setEveningSlots((eveningSlots) => [...eveningSlots, { time_slot: timeSlot, isBooked: false }]);
      }

      
    });
  }

  

  const handleDateChange = (date) => {
   
    let momentObj = moment(date, "ddd DD MMM YYYY");

    setSelectedMonth(momentObj.month() + 1);
    setSelectedYear(momentObj.year());
    // setCalendarStartDate(momentObj.format("YYYY-MM-DD"));
    setShowSelectedDate(momentObj.format("dddd, Do MMMM YYYY"));

    setSelectedDateForTime(date);
    // setSelectedDate(Date(date));
    setSelectedTimeSlot("");
    setMorningSlots([])
    setAfternoonSlots([])
    setEveningSlots([])
    setIsOutsideAvaibility(false)
    if (moment(date, "ddd DD MMM YYYY").isSameOrAfter(moment(), 'day')) {
     
      let isAvailable = clinicsAvailableDateData?.data?.getClinicAvailableDates?.data?.items?.includes(moment(date, "ddd DD MMM YYYY").format("YYYY-MM-DD")) || clinicsAvailableDateData1?.data?.getClinicAvailableDates?.data?.items?.includes(moment(date, "ddd DD MMM YYYY").format("YYYY-MM-DD"))
      console.log(`isAvailable`, isAvailable);
      if (!isAvailable) {
        setIsAvailableForWorking(true)
        handleTimeSlotsData();
        return;
      }
      else {

        setIsAvailableForWorking(false)

        initAvalableTimeSlotData({
          clinicId: clinicIdAndTreatmentsInfo?.clinicId,
          date: moment(date, "ddd DD MMM YYYY").format("YYYY-MM-DD"),
          treatmentIds: clinicIdAndTreatmentsInfo?.treatmentIds,
          isCreateAppointment: ""
        }, (response) => {
          // response.forEach((timeSlot) => {
          //   const time = moment(timeSlot.time_slot, 'h:mm A');
          //   const morningBoundary = moment('12:00 PM', 'h:mm A');
          //   const afternoonBoundary = moment('6:00 PM', 'h:mm A');

          //   if (time.isBefore(morningBoundary)) {
          //     setMorningSlots((morningSlots) => [...morningSlots, { time_slot: timeSlot.time_slot, isBooked: timeSlot.isBooked }]);
          //   } else if (time.isBefore(afternoonBoundary)) {
          //     setAfternoonSlots((afternoonSlots) => [...afternoonSlots, { time_slot: timeSlot.time_slot, isBooked: timeSlot.isBooked }]);
          //   } else {
          //     setEveningSlots((eveningSlots) => [...eveningSlots, { time_slot: timeSlot.time_slot, isBooked: timeSlot.isBooked }]);
          //   }
          // });


        })
      }
    }
    else {
      setIsAvailableForWorking(false)
      handleTimeSlotsData();
    }
  };

  const totalSlides = twoWeekDates?.length;
  const handleNext = () => {
    if (sliderRef.current) {
      const newSlideIndex = currentSlideIndex + 1;
      // Check if the new slide index is within bounds
      if (newSlideIndex >= 0 && newSlideIndex < totalSlides) {
        sliderRef.current.slickNext();
        setCurrentSlideIndex(newSlideIndex);
      }
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      //sliderRef.current.slickPrev();
      const newSlideIndex = currentSlideIndex - 1;

      // Check if the new slide index is within bounds
      if (newSlideIndex >= 0 && newSlideIndex < twoWeekDates?.length) {
        sliderRef.current.slickPrev();
        setCurrentSlideIndex(newSlideIndex);
      }
    }
  };

  const handleTimeSlotChange = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };


  // ==================================================================

  //=================================EFFECTS=================================

  //GET DETAILS FOR DATE AND TIME SLOTS

  useEffect(() => {
    if (appointmentId) {
      
      initGetAppointmentDetailForRebookData({
        appointmentId: parseInt(appointmentId),
        allDetails: false
      }, (response) => {
        setClinicIdAndTreatmentsInfo({
          clinicId: response?.clinicId,
          treatmentIds: response?.treatmentDetails?.map((item) => {
            return item?.treatmentId
          })
        })
      })
    }
  }, [appointmentId])

  const getClinicAvailableDatesHandler = (clinicId, date) => {
    initClinicsAvailableDateResponseFunction({
      clinicId: clinicId,
      startDate: moment(date, "YYYY-MM-DD").clone().startOf('month').format("YYYY-MM-DD"),
      endDate: moment(date, "YYYY-MM-DD").clone().endOf('month').format("YYYY-MM-DD"),
    });
  }
  //DATES FOR MODAL CALEDAR 
  useEffect(() => {
    const moment = require('moment');


    // // Your start date in string format

    // // Parse the start date
    // const startDateStr = moment(startDate);

    // // Get the first day of the month
    // const firstDayOfMonth = startDateStr.clone().startOf('month');

    // // Get the last day of the month
    // const lastDayOfMonth = startDateStr.clone().endOf('month');

    // // Generate an array of dates for the month
    // const datesArray = [];
    // let currentDate = firstDayOfMonth.clone();

    // while (currentDate.isSameOrBefore(lastDayOfMonth)) {
    //   if (currentDate.isSameOrAfter(moment().clone().add(7, 'days'), 'day'))
    //     datesArray.push(currentDate.format('YYYY-MM-DD'));
    //   currentDate.add(1, 'day');
    // }
    let datesArray = []
    if (clinicIdAndTreatmentsInfo && endDate) {
      if (clinicIdAndTreatmentsInfo?.clinicId)
        getClinicAvailableDatesHandler(clinicIdAndTreatmentsInfo?.clinicId, startDate)
    }
{
  console.log("clinicIdAndTreatmentsInfo?.clinicId",clinicIdAndTreatmentsInfo?.clinicId)
}

if(clinicIdAndTreatmentsInfo?.clinicId){
  initGetCalendarAppointmentCountData({
    startDate: moment(startDate, "YYYY-MM-DD").clone().startOf('month').format("YYYY-MM-DD"),
    endDate: moment(startDate, "YYYY-MM-DD").clone().endOf('month').format("YYYY-MM-DD"),
    locations: clinicIdAndTreatmentsInfo?.length == 0 ? [] : [clinicIdAndTreatmentsInfo?.clinicId]

  })
}
}, [startDate, endDate, clinicIdAndTreatmentsInfo])

  // const currentDateLabel = moment().format("DD MMMM YYYY");
  useEffect(() => {
    // Get the current date
    // const currentDate = moment();
    const currentDate = moment(calendarStartDate)

    // Calculate the start date based on the selected week
    let startDate;

    switch (selectedWeek) {
      case "1 week":
        startDate = currentDate.clone().add(7, "days");
        break;
      case "2 week":
        startDate = currentDate.clone().add(14, "days");
        break;
      case "3 week":
        startDate = currentDate.clone().add(21, "days");
        break;
      case "4 week":
        startDate = currentDate.clone().add(28, "days");
        break;
      case "5 week":
        startDate = currentDate.clone().add(35, "days");
        break;
      case "6 week":
        startDate = currentDate.clone().add(42, "days");
        break;
      case "7 week":
        startDate = currentDate.clone().add(49, "days");
        break;
      default:
        startDate = moment(calendarStartDate, "YYYY-MM-DD");

    }
    //call api for screen
    if (clinicIdAndTreatmentsInfo) {
      if (clinicIdAndTreatmentsInfo?.clinicId == 0) {
        //GENERATE 1 WEEK CUSTOM DATE AND STORE IN STATE
        // Assuming startDate is a Moment.js object
        let startDateForThisFun = startDate;
        let endDateForThisFun = startDate.clone().add(6, "days");

        // Iterate through the date range
        let currentDate = startDateForThisFun.clone();
        let dateArr = []
        while (currentDate.isSameOrBefore(endDateForThisFun)) {
          dateArr.push(currentDate.format("ddd DD MMM YYYY"));
          currentDate.add(1, "day");
        }
        setApiDatesData(dateArr)


      } 
      else {
        console.log("clinicIdRes",{
          clinicId: clinicIdAndTreatmentsInfo?.clinicId,
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: startDate.clone().add(6, "days").format("YYYY-MM-DD")
        })
        initClinicsAvailableDateResponseFunction1({
          clinicId: clinicIdAndTreatmentsInfo?.clinicId,
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: startDate.clone().add(6, "days").format("YYYY-MM-DD"),
        

        }, (response) => {
          setApiDatesData(response.map((item) => moment(item, "YYYY-MM-DD").format("ddd DD MMM YYYY")))
        })
      }
    }


    // Calculate dates for the next two weeks
    const nextTwoWeekDates = [];
    // for (let i = 1; i < 7 * selectedWeek.replace("week", "").trim(); i++) {
    for (let i = 0; i <= 7; i++) {
      const date = startDate.clone().add(i, "days");
      nextTwoWeekDates.push(date.format("ddd DD MMM YYYY"));
    }

    // Update state with the two weeks' dates
    // setTwoWeekDates([]);
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
    setTwoWeekDates(nextTwoWeekDates);
  }, [selectedWeek, calendarStartDate, clinicIdAndTreatmentsInfo]);

  // ==================================================================
  useEffect(() => {

    if (timeslotsData) {
      console.log("timeslotsData", timeslotsData)
      // const timingData = TIME(response?.businessHours?.startTime, response?.businessHours?.endTime);
      timeslotsData.forEach((timeSlot) => {
        const time = moment(timeSlot?.time_slot, 'h:mm A');
        const morningBoundary = moment('12:00 PM', 'h:mm A');
        const afternoonBoundary = moment('6:00 PM', 'h:mm A');

        if (time.isBefore(morningBoundary)) {
          setMorningSlots((morningSlots) => [...morningSlots, timeSlot]);
        } else if (time.isBefore(afternoonBoundary)) {
          setAfternoonSlots((afternoonSlots) => [...afternoonSlots, timeSlot]);
        } else {
          setEveningSlots((eveningSlots) => [...eveningSlots, timeSlot]);
        }
      });
    }
  }, [timeslotsData])

  const selectedTimeSlotClickHandler = () => {
    initIsAlreadyBookingExistData({
      date: moment(selectedDateForTime, "ddd DD MMM YYYY").format("YYYY-MM-DD"),
      clinicId: clinicIdAndTreatmentsInfo?.clinicId,
      startTime: selectedTimeSlot,
      endTime: moment(selectedTimeSlot, 'HH:mm').add(30, 'minutes').format('HH:mm'),
      treatmentIds: clinicIdAndTreatmentsInfo?.treatmentIds,
      appointmentId: parseInt(appointmentId)
    }, (responseData) => {
      if (responseData?.data?.isBookingExist) {
        setOverLappingMessage(responseData?.message)
        setIsAppointmentOverlappingOpen(true)
      } else {
        setOverLappingMessage(null)
        setIsAppointmentOverlappingOpen(false)
        setStep(2)
      }
    }, (error) => {
      showToast("error", error?.message, "", false)
    })
  }
  // useEffect(() => {
  //   if (clinicsAvailableDateData1?.data?.getClinicAvailableDates?.data && location?.state?.selectedDate) {
  //     handleDateChange(moment(location?.state?.selectedDate, "YYYY-MM-DD").format("ddd DD MMM YYYY"))
  //     setSelectedWeek(null);
  //     setIsFromClientForm(true)
  //     navigate(".", { replace: true });
  //   }
  // }, [clinicsAvailableDateData1?.data?.getClinicAvailableDates?.data, location])
  // useEffect(() => {
  //   if (location?.state?.selectedDate) {
  //     setShowSelectedDate(moment(location?.state?.selectedDate, "YYYY-MM-DD").format("dddd, Do MMMM YYYY"))
  //     setStartDate(moment(location?.state?.selectedDate, "YYYY-MM-DD").format("YYYY-MM-DD"))
  //     setSelectedMonth(moment(location?.state?.selectedDate, "YYYY-MM-DD").format("M"))
  //     setSelectedYear(moment(location?.state?.selectedDate, "YYYY-MM-DD").format("YYYY"))
  //     setCalendarStartDate(moment(location?.state?.selectedDate, "YYYY-MM-DD").format("YYYY-MM-DD"))
  //     // handleDateChange(moment(location?.state?.selectedDate, "YYYY-MM-DD").format("ddd DD MMM YYYY"))
  //     setSelectedWeek(null);
  //   }
  // }, [location])

  const dimensions = useWindowDimension();
  const [rebookHeaderHeight, setRebookHeaderHeight] = useState(0);
  const [rebookSecHeaderHeight, setRebookSecHeaderHeight] = useState(0);
  // const [rebookCalenderHeight, setRebookCalenderHeight] = useState(0);
  const [rebookFooterHeight, setRebookFooterHeight] = useState(0);


const updateHeights = () => {
  setRebookHeaderHeight( document.getElementsByClassName('RebookHeader')[0]?.offsetHeight || 0);
  setRebookSecHeaderHeight(document.getElementsByClassName('SectionHeadingWrapper')[0]?.offsetHeight || 0);
  // setRebookCalenderHeight(document.getElementsByClassName('headerWrapper')[0]?.offsetHeight || 0);
  setRebookFooterHeight(document.getElementsByClassName('BtnWrapper')[0]?.offsetHeight || 0);
};

useEffect(() => {
  updateHeights();
  window.addEventListener("resize", updateHeights);
  return () => {
    window.removeEventListener("resize", updateHeights);
  };
}, [dimensions, apiDatesData]); // Run effect when dimensions change

const scrollableDivHeight = dimensions?.height - (rebookHeaderHeight || 0) - (rebookSecHeaderHeight || 0)  - (rebookFooterHeight || 0) - 20;

console.log("morningSlots",morningSlots)
  return (
    <div className="Main-Rebooking-Page">
      <div className="RebookHeader">
          <CloseIcon className="cursor-pointer" onClick={() => {
            if (step == 2) {
              setStep(1)
            }
            else if (location?.state?.isFromClientForm) {
              navigate(getClientProfileFormsRoute(clientId))
            }
            else {
              navigate(getClientProfileRoute(clientId), { state: { fromRebookScreen: true } })
            }
          }
          } />
          {/* {
            step === 1 &&
            <div className="calenderBtn" onClick={() => setIsOpenCalendar(true)} >
              <CalenderIcon />
              Calendar
            </div>
          } */}
      </div>
      <div className="rebook-main-container maxCanvasWidth540">
        {
          step === 1 ?
            <div className="rebook-container">
              <CustomeHeading
                  Title={selectedDateForTime ? `Rebook for ${moment(selectedDateForTime, "ddd DD MMM YYYY").format('DD MMMM YYYY')}` : 'Rebook'}
                  Desc=''
                  isSearch={false}
                />
                <div className="innerHeightSet" style={{height: scrollableDivHeight + 'px', overflowY: 'auto'}}>
                <div className="headerWrapper">
                {
                  getAppointmentDetailForRebookQueryData?.loading || getCalendarAppointmentCountQueryData?.loading || clinicsAvailableDateData?.loading ? <CustomCalendarSkeleton /> :
                  <CustomCalendar
                    setShowSelectedDate={setShowSelectedDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    setSelectedMonth={setSelectedMonth}
                    setSelectedYear={setSelectedYear}
                    setSelectedWeek={setSelectedWeek}
                    setSelectedDateForTime={setSelectedDateForTime}
                    onChangeCallback={
                      (selectedDate) => {
                        handleDateChange(selectedDate)
                      }
                    }
                    showSelectedDate={showSelectedDate}
                    setCalendarStartDate={(selectedDate) => {
                      setCalendarStartDate(selectedDate)
                      setSelectedDateForTime(moment(selectedDate).format("ddd DD MMM YYYY"))
                    }}
                    setIsOpenCalendar={setIsOpenCalendar}
                    // setViewCalendarState={setViewCalendarState}
                    dateData={
                      clinicIdAndTreatmentsInfo?.clinicId ?
                        clinicsAvailableDateData?.data?.getClinicAvailableDates?.data?.items :
                        // generateCustomDates()
                        generateCustomDates(startDate)
                    }
                    appointmentDatesCount={
                      getCalendarAppointmentCountQueryData?.data?.getCalendarAppointmentCount?.data?.appointmentCountData
                    }
                    ignoreSelectedDate={true}
                    isPassedDateEnabled={true}
                    isCalendarPreviousDateEnable={true}
                    isMonthlyDailyViewCalender={clinicIdAndTreatmentsInfo?.clinicId ? false : true}
                  />
                }
                
                  
                
                
                  {/* {
                    clinicsAvailableDateData?.loading || clinicsAvailableDateData1?.loading ?
                      <>
                        <div className="slick-parent-container">
                          <Slider
                            arrows={true}
                            {...settingsDate}
                            className="tab-container-date"
                            ref={sliderRef}
                          >
                            {[...Array(5)]?.map((item, index) => {
                              return (
                                <div
                                  className={`date-slider text-center`}
                                  // onClick={() => handleDateChange(item)}
                                  key={index}
                                >
                                  <div
                                    className={` ${item === selectedDateForTime ? "date-active-tab" : "date-inactive-tab"}`
                                    }
                                  >
                                    <Skeleton height={20} width={50} />
                                    <Skeleton height={20} width={80} />
                                  </div>
                                </div>
                              );
                            })}
                          </Slider>
                        </div>
                      </>
                      : twoWeekDates?.length > 0 && apiDatesData?.length > 0 && (
                        <>
                          <div className="slick-parent-container">
                            <Slider
                              arrows={true}
                              {...settingsDate}
                              className="tab-container-date"
                              ref={sliderRef}
                              afterChange={(index) => setCurrentSlideIndex(index)}
                            >
                              {twoWeekDates?.map((item, index) => {
                                return (
                                  <div
                                    className={`date-slider text-center`}
                                    onClick={() => handleDateChange(item)}
                                    key={index}
                                  >

                                    
                                    <div
                                      className={` ${item === selectedDateForTime ? "date-active-tab" : "date-inactive-tab"}
                                  ${(apiDatesData.includes(item)) ? "" : "date-disable-tab"}`
                                      }
                                    >
                                      <h1>{moment(item, "ddd DD MMM YYYY").format("ddd")}</h1>
                                      <p>{moment(item).format("DD MMM")}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </Slider>

                            <PrevIcon
                              onClick={handlePrev}
                              className="previous-slick-button"
                              style={currentSlideIndex === 0 ? { display: "none" } : {}}
                            /> 
                            <NextIcon onClick={handleNext} className="next-slick-button" style={currentSlideIndex + settingsDate.slidesToShow === totalSlides - 1 ? { display: "none" } : {}} />
                          </div>
                        </>
                      )
                  } */}
                </div>
                <div className="TimeListing" >
                  {selectedDateForTime && (
                    <div className="TimeListingItem">
                      <div className="time-slots">
                        {
                          availableTimeSlotData?.loading ?
                            <>
                                <SkeletonLoader type={'RebookListing-Title'} />
                            </>
                            :
                            !availableTimeSlotData?.loading && morningSlots?.length > 0 &&
                            <>
                              <div className="time-slot-header">
                                <div className="timeSlotIconWrapper">
                                  <MorningIcon />
                                  <h1 className="timeSlotContent">Morning</h1>
                                </div>
                                {!!clinicIdAndTreatmentsInfo?.clinicId
                                  &&
                                  !!clinicsAvailableDateData1?.data?.getClinicAvailableDates?.data?.items?.includes(moment(showSelectedDate, "dddd, Do MMMM").format("YYYY-MM-DD")) &&
                                  <div>
                                    {isOutsideAvaibility ? <h6
                                      onClick={() => {
                                        setIsOutsideAvaibility(false)
                                        setMorningSlots([]);
                                        setAfternoonSlots([]);
                                        setEveningSlots([]);
                                        dispatch({
                                          type: SET_TIME_SLOTS_FOR_CREATE_APPOINTMENT,
                                          payload: timeslots
                                        })
                                      }}
                                    >Select from clinic availability</h6> : <h6 onClick={() => {
                                      setIsOutsideAvaibility(true)
                                      setMorningSlots([]);
                                      setAfternoonSlots([]);
                                      setEveningSlots([]);
                                      console.log("click outside", availableAndOutsideAvaibilityTimeSlots)
                                      dispatch({
                                        type: SET_TIME_SLOTS_FOR_CREATE_APPOINTMENT,
                                        payload: availableAndOutsideAvaibilityTimeSlots
                                      })
                                    }}>Select outside availability</h6>}
                                  </div>}
                              </div>
                              <div className="timeSlotContentWrapper">
                              {
                                console.log("availableTimeSlotData",availableTimeSlotData?.data)
                              }
                                {
                                  availableTimeSlotData?.loading ?
                                    <SkeletonLoader type={'RebookListing-Title'} />
                                    :
                                  morningSlots?.length > 0 ? morningSlots.map((slot) =>
                                    
                                    (
                                      <div className="time-slot-content">
                                        <CustomRadioButton
                                          isblacked={true}
                                          text2={slot.time_slot}
                                          name="timeSlotGroup"
                                          id={slot.time_slot}
                                          checked={slot.time_slot === selectedTimeSlot}
                                          onChange={() => handleTimeSlotChange(slot.time_slot)}
                                          preselect={true}
                                        />
                                      </div>
                                    )
                                    )
                                    :
                                      <h5 className="no-time-slots mt-2">
                                      No time slots available!
                                    </h5>
                                }
                              </div>
                            </>
                        }
                        {
                          !availableTimeSlotData?.loading && afternoonSlots?.length > 0 &&

                          <>
                            <div className="time-slot-header">
                              <div className="timeSlotIconWrapper">
                                  <AfterNoonIcon />
                                  <h1>Afternoon</h1>
                              </div>
                            </div>
                            <div className="timeSlotContentWrapper">
                            {
                              availableTimeSlotData?.loading ?
                                [...Array(3)].map((item) =>
                                  <Skeleton height={50} width={"100%"} />
                                )
                                :
                                afternoonSlots?.length > 0 ? afternoonSlots.map((slot) => (
                                  <div className="time-slot-content">
                                    <CustomRadioButton
                                      isblacked={true}
                                      text2={slot.time_slot}
                                      name="timeSlotGroup"
                                      id={slot.time_slot}
                                      checked={slot.time_slot === selectedTimeSlot}
                                      onChange={() => handleTimeSlotChange(slot.time_slot)}
                                      preselect={true}
                                    />
                                  </div>
                                )) : <h5 className="no-time-slots mt-2">
                                  No time slots available!
                                </h5>
                            }
                            </div>
                          </>
                        }
                        {
                          !availableTimeSlotData?.loading && eveningSlots?.length > 0 &&
                          <>
                            <div className="time-slot-header">
                              <div className="timeSlotIconWrapper">
                                <EveningIcon />
                                <h1>Evening</h1>
                              </div>
                            </div>
                            <div className="timeSlotContentWrapper">
                              {eveningSlots?.length > 0 ? eveningSlots.map((slot) => (
                                <div className="time-slot-content ">
                                  <CustomRadioButton
                                    isblacked={true}
                                    text2={slot.time_slot}
                                    name="timeSlotGroup"
                                    id={slot.time_slot}
                                    checked={slot.time_slot === selectedTimeSlot}
                                    onChange={() => handleTimeSlotChange(slot.time_slot)}
                                    preselect={true}
                                  />
                                </div>
                              )) : <h5 className="no-time-slots mt-2">
                                No time slots available!
                              </h5>}
                            </div>
                          </>
                        }
                      </div>
                    </div>
                  )}
                  {!selectedDateForTime && (
                    <EmptyClient
                      name={"Sorry,"}
                      subTitle={"please select a date to see available times."}
                      title={"No times"}
                    />
                  )}
                </div>
              </div>
            </div> :
            <>
              <ConfirmRebooking setSaveLoading={setSaveLoading} rebookingObj={
                {
                  appointmentId: appointmentId,
                  date: selectedDateForTime,
                  timeSlot: selectedTimeSlot,
                  clinicId: clinicIdAndTreatmentsInfo?.clinicId,
                  treatmentIds: clinicIdAndTreatmentsInfo?.treatmentIds
                }
              }
                isAvailableForWorking={isAvailableForWorking}
              />
            </>
        }
        <div className="BtnWrapper">
          <StepButton
            blue={true}
            className={"w-100"}
            disabled={!selectedTimeSlot || saveLoading}
            label={`Confirm ${step == 1 ? selectedTimeSlot : "Rebook"}`}
            isLoading={saveLoading}
            onClick={() => {
              if (step == 1) {
                // setStep(2)
                selectedTimeSlotClickHandler();
              } else {
                confirmRebook()
              }
            }}
          />
        </div>
      </div>
{
  step == 1 && 
  (
    <CustomModal
        modalOpen={isOpenCalendar}
        // modalOpen={true}
        setModalOpen={setIsOpenCalendar}
        className={'CustomeCalenderModelDesign'}
        modalBody={
          getCalendarAppointmentCountQueryData?.loading || clinicsAvailableDateData?.loading ? <CustomCalendarSkeleton /> :
            <CustomCalendar
              setShowSelectedDate={setShowSelectedDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              setSelectedMonth={setSelectedMonth}
              setSelectedYear={setSelectedYear}
              setSelectedWeek={setSelectedWeek}
              setSelectedDateForTime={setSelectedDateForTime}
              onChangeCallback={
                (selectedDate) => {
                  handleDateChange(selectedDate)
                }
              }
              showSelectedDate={showSelectedDate}
              setCalendarStartDate={(selectedDate) => {
                setCalendarStartDate(selectedDate)
                setSelectedDateForTime(moment(selectedDate).format("ddd DD MMM YYYY"))
              }}
              setIsOpenCalendar={setIsOpenCalendar}
              // setViewCalendarState={setViewCalendarState}
              dateData={
                clinicIdAndTreatmentsInfo?.clinicId ?
                  clinicsAvailableDateData?.data?.getClinicAvailableDates?.data?.items :
                  // generateCustomDates()
                  generateCustomDates(startDate)
              }
              appointmentDatesCount={
                getCalendarAppointmentCountQueryData?.data?.getCalendarAppointmentCount?.data?.appointmentCountData
              }
              ignoreSelectedDate={true}
              isPassedDateEnabled={true}
              isCalendarPreviousDateEnable={true}
              isMonthlyDailyViewCalender={clinicIdAndTreatmentsInfo?.clinicId ? false : true}
            />
        }
      />
  )
}
     

      {/* APPOINTMENT OVERLAPPING CONFIRMATION MODAL */}
      <CustomModal
        modalOpen={isAppointmentOverlappingOpen}
        setModalOpen={setIsAppointmentOverlappingOpen}
        type={"common-width"}
        className={'customeAvailableModel'}
        modalBody={
          <>
            <div className="share-booking-link-all-clients-confimration">
              <p>
                {overLappingMessage}
              </p>
              <div className="btnWrapper">
                <StepButton blue={true} label={"Yes"} onClick={() => {
                  setStep(2)
                  setIsAppointmentOverlappingOpen(false)
                  // setOverLappingMessage("")
                }} />
                <StepButton gray={true} onClick={() => {
                  // setOverLappingMessage("")
                  setIsAppointmentOverlappingOpen(false)
                }} label={"No"} />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default RebookAppointment;
