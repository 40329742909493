import React, { useEffect } from 'react'
import "./selectPlans.scss";
import backIcon from "../../../images/back_arrow.svg";
import { FaWhatsapp } from "react-icons/fa6";
import StepButton from "../../common/StepButton";
import { FiChevronRight } from 'react-icons/fi';
import checkMarkIcon from "../../../assets/createWebsite/check-mark.svg";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { GetAllWebsitePackageAction } from "../../../components/redux/actions/createWebsite/getAllWebsitePackages"
import { GET_ALL_WEBSITE_PACKAGE_DATA } from "../../../components/redux/constants/createWebiste/getAllWebsitePackages"
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";



function SelectPlans({ setCurrentStep }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { initAllWebsitePackageFunction, GetAllWebsitePackageResponseData: { loading: packageLoading } } = GetAllWebsitePackageAction()

  useEffect(() => {

    initAllWebsitePackageFunction({
    }, res => {
      console.log("response data", res)
      if (res?.data?.getWebsitePackages?.success) {
        // window.open(res?.data?.getWebsitePackages?.data?.packages[0]?.url, '_self')
        dispatch({
          type: GET_ALL_WEBSITE_PACKAGE_DATA,
          payload: res?.data?.getWebsitePackages?.data?.packages
        })
        console.log("res?.data?.getWebsitePackages", res?.data?.getWebsitePackages.data.packages[0])
      }
    }, err => {
      console.log("error", err)
    })
  }, [])



  const { allPackage } = useSelector((state) => state?.GetAllWebsitePackageReducer)
  console.log("allPackage", allPackage, packageLoading)

  return (
    <div className='mainSelectPlans'>
      <div className="TopHeader">
        <div className="BackButtonDashBoard">
          <img src={backIcon} alt="" onClick={() => setCurrentStep(2)} />
        </div>
        <div className="WhatSappBtn">
          <button className="whatsappBtn" onClick={() => window.open(process.env.REACT_APP_WHATSAPP_LINK, '_blank')}>
            <span className="WpIcon">
              <FaWhatsapp />
            </span>{" "}
            contact support
          </button>
        </div>
      </div>

      <div className='planWrapper'>
        <div className="container">
          <div className='secTitle'>
            <h2>Unlock Your Potential Build Your <span className='blueText'>Dream Website</span> with Ease</h2>
            <p>Start building your dream website today with our easy-to-use plans and intuitive features</p>
          </div>

          {packageLoading ?
            <div className='PriseBox' >
              {[...Array(10)].map(i => <Skeleton width={"100%"} height={25} />)}
            </div> :
            allPackage?.map((value, index) => {
              return (
                <div className='PriseBox' key={index}>
                  <div className="PriseBoxHeader">
                    <div className="PriseBoxHeaderTop">
                      <h5>{value?.package_name}</h5>
                      <span className='Tag'>Popular</span>
                    </div>
                    <div className='PriseBoxHeaderContent'>
                      <h6>{value?.amountText}</h6>
                      <p>{value?.duration_text}</p>
                    </div>
                  </div>
                  <div className='PriseBoxUl'>
                    <ul>
                      {value?.package_points?.map((item, index) => {
                        return (
                          <li key={index}>
                            <span className='IconLi'>
                              <img src={checkMarkIcon} alt={checkMarkIcon} srcset={checkMarkIcon} />
                            </span>
                            {item}
                          </li>
                        )
                      })}

                    </ul>
                  </div>
                  <div className='PriseBoxBottomButton'>
                    <StepButton
                      blue={true}
                      label="Get Now"
                      onClick={() => {
                        window.open(value?.url, '_self')
                      }}
                    />
                  </div>
                </div>)
            })}
        </div>
      </div>
    </div>
  )
}

export default SelectPlans