import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { GET_TRAINING_ACCOUNT_DETAILS } from "../../constants";
// ==========================GET ACCOUNT DETAILS==========================

const GET_TRAINING_DETAILS_QUERY = gql`
  query TrainingList {
  getTrainingAcademyList {
    data {
      trainingList {
        parentId
        name
      }
    }
  }
}
`;

export const GetTrainingAction = () => {
    const dispatch = useDispatch();
  const [
    // getAccountDetailsResponseFunction,
    // getAccountDetailsResponseData,
     getTrainingResponseFunction,
    getTrainingResponseData,
  ] = useLazyQuery(GET_TRAINING_DETAILS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getTrainingAcademyList: responseData }) => {
      if (responseData.success) {
        dispatch({
            type: GET_TRAINING_ACCOUNT_DETAILS,
            payload: responseData,
          });
      } else {
        // Handle error response
      }
    },
  });

  const getTrainingQueryData = getTrainingResponseData;

  const initGettrainingData = (data, successCallback, errorCallback) => {
   
    getTrainingResponseFunction({
      variables: data,
    }).then((res) => {
    console.log(res,"likkkkkk")
      if (res.data.getTrainingAcademyList.data) {
        successCallback(res);
      } else {
        errorCallback(res);
      }
    }).catch((err) => {
      errorCallback(err);
    });
  };
//   const initGetAccountDetailsData = () => {
//     getAccountDetailsResponseFunction();
//   };

  return { getTrainingQueryData, initGettrainingData };
};
