import { generatePath, matchPath } from "react-router"

export const isPartOfRoute = (pathname, referenceRoutesList) => {
	let routeMatched = false;
	if (typeof referenceRoutesList == "string") {
		referenceRoutesList = [referenceRoutesList];
	}

	for (let routePattern of referenceRoutesList) {
		if (
			matchPath(routePattern, pathname)
			// &&      matchPath(pathname, routePattern).isExact
		) {
			routeMatched = true;
			break;
		}
	}

	return routeMatched;
};

//remove calenda route from here after testing
export const isGuestAllowed = (pathname) => {
	return isPartOfRoute(pathname, [clinicCart, clinicCartStatus, stripePaymentPage, insuranceLandingPage, accountancyServicePage, stripeAccountancyPaymentPage, getFinanceLandingRoute, getWebsiteBuilderPage, getNurseInsurancetwoPricingLandingRoute, getNurseInsurancethreePricingLandingRoute, getAcountancyLandingPageRoute, getFindAPrescriberLandingPageRoute, socialMediaMarketingPage, getPurchaseWebsitePackageRoutePattern, getCustomBuildThankYouRoutePattern, HairProductsRoutePattern, loginLandingPage, financeFirstStapPage, forgotEmailUpdateLandingPage, verifyForgotPasswordLandingPage, forgotEmailLandingPage, verifyEmailLandingPage, registrationLandingPage, financeStepsThankYouPage, financeStepsAbortPage, financeStepsFailPage, initiateFinanceLoanRequest, hairCareLandingPageRouteRequest, getForwardConsentFormPageRoute, getForwardConsentFormStatus, BookingInvoiceDownloadRoutePattern, HairPrescriptionProductsRoutePattern, getTreatmentPageRoute, getClinicPageRoute, getTreatmentLocationPageRoute, bookingsCoreRoutePattern, bookingsCoreRoutePattern, productQuestionAnswerRoute, facesBusinessRoute
	]);
}

export const isShowSupportChatWidget = (pathname) => {
	let array = [CalendarRoutePattern, NewAppointmentRoutePattern, SearchClientRoutePattern, NewClientRoutePattern, ClientProfileRoutePattern, EditClientRoutePattern, EditAppointmentRoutePattern, ShareBookingLinkPattern, BookinginvoiceRoutePattern, blockOutTimePattern, rebookAppointmentPattern, HairPrescriptionProductsRoutePattern, bookingsCoreRoutePattern]
	if (array.findIndex((i) => i.split("/")[1] == pathname.split("/")[1]) == -1) {
		return true
	} else {
		return false
	}
}

export const indexPage = "/"
export const indexPagePattern = () => {
	return generatePath(indexPage);
}

export const insuranceLandingPageOld = "/insurance-landing"
export const insuranceLandingPage = "/beauty-aesthetic-insurance-companies"
export const insuranceLandingPagePattern = () => {
	return generatePath(insuranceLandingPage);
}

export const insuranceThankyouPage = "/insurance-thank-you"
export const insuranceThankyouPagePattern = () => {
	return generatePath(insuranceThankyouPage);
}

export const socialMediaMarketingPage = "/social-media-marketing"
export const socialMediaMarketingPagePattern = () => {
	return generatePath(socialMediaMarketingPage);
}


export const stripePaymentPage = "/stripe-payment"
export const stripePaymentPagePattern = () => {
	return generatePath(stripePaymentPage);
}

export const accountancyServicePage = "/accountancy-service"
export const accountancyServicePagePattern = () => {
	return generatePath(accountancyServicePage);
}

export const stripeAccountancyPaymentPage = "/purchase-accountancy-package/:slug"
export const stripeAccountancyPaymentPagePattern = (slug) => {
	return generatePath(stripeAccountancyPaymentPage, { slug });
}

export const clientBookingPage = "client-booking"
export const clientBookingPagePattern = () => {
	return generatePath(clientBookingPage);
}
export const mainClientPage = "main-client-booking"
export const mainClientPagePattern = () => {
	return generatePath(mainClientPage);
}
export const treatmentListing = "treatment-listing"
export const treatmentListingPattern = () => {
	return generatePath(treatmentListing);
}
export const clinicCart = "bookings/:slug"
export const clinicCartPattern = (slug) => {
	return generatePath(clinicCart, { slug })
}

export const clinicCartStatus = "bookings/:slug/:status"
export const clinicCartStatusPattern = (slug, status) => {
	return generatePath(clinicCartStatus, { slug, status })
}

export const businessDashboardRoutePattern = "/business-dashboard"
export const businessDashboardRoute = () => {
	return generatePath(businessDashboardRoutePattern);
}

export const verifiedBannerRoutePattern = "/faces-verified"
export const verifiedBannerRoute = () => {
	return generatePath(verifiedBannerRoutePattern);
}

export const getStartedRoutePattern = "/get-started"
export const getStartedRoute = () => {
	return generatePath(getStartedRoutePattern);
}

export const getAppointmentRoutePattern = "/appointment/:slug"
export const getAppointmentRoute = (slug) => {
	return generatePath(getAppointmentRoutePattern, { slug });
}

export const getAppointmentListRoutePattern = "/appointment-list"
export const getAppointmentListRoute = () => {
	return generatePath(getAppointmentListRoutePattern);
}


export const createWebsite = "/my-website"
export const createWebsiteRoute = () => {
	return generatePath(createWebsite);
}

export const getFacesAiAssistantRoute = "/get-faces-ai-assistant"
export const getFacesAiAssistant = () => {
	return generatePath(getFacesAiAssistantRoute);
}

export const getPersonalWebsiteDashboardRoute = "/my-website/dashboard"
export const getPersonalWebsiteDashboard = () => {
	return generatePath(getPersonalWebsiteDashboardRoute);
}

export const getWebsiteBuilderPage = "/website-builder"
export const getWebsiteBuilder = () => {
	return generatePath(getWebsiteBuilderPage);
}

export const getNurseInsurancetwoPricingLandingRoute = "/aesthetics-insurance"
export const NurseInsuranceTwoPricingLandingRoute = () => {
	return generatePath(getNurseInsurancetwoPricingLandingRoute);
}
export const getFinanceIdentityRoute = "/finance-identity"
export const FinanceIdentityRoute = () => {
	return generatePath(getFinanceIdentityRoute);
}

export const getNurseInsurancethreePricingLandingRoute = "/medical-malpractice-insurance"
export const NurseInsuranceThreePricingLandingRoute = () => {
	return generatePath(getNurseInsurancethreePricingLandingRoute);
}

export const getFinanceLandingRoute = "/finance"
export const FinanceLandingRoute = () => {
	return generatePath(getFinanceLandingRoute);


}

export const getAcountancyLandingPageRoute = "/accountancy-services"
export const getAcountancyLandingPage = () => {
	return generatePath(getAcountancyLandingPageRoute);
}

export const walkInConsentRoutePattern = "/walk-in-consent"
export const walkInConsentRoute = () => {
	return generatePath(walkInConsentRoutePattern);
}

export const clientOverViewRoutePattern = "/client-overview"
export const clientOverViewRoute = () => {
	return generatePath(clientOverViewRoutePattern);
}

export const addFormsRoutePattern = "/add-form"
export const addFormsRoute = () => {
	return generatePath(addFormsRoutePattern);
}

export const chooseServicesRoutePattern = "/services"
export const chooseServicesRoute = () => {
	return generatePath(chooseServicesRoutePattern);
}

export const chooseLocationRoutePattern = "/locations"
export const chooseLocationRoute = () => {
	return generatePath(chooseLocationRoutePattern);
}

export const walkInConsentFillRoutePattern = "/walk-in-consent-fill"
export const walkInConsentFillRoute = () => {
	return generatePath(walkInConsentFillRoutePattern);
}

export const CalendarRoutePattern = "/calendar"
export const getCalendarRoute = () => {
	return generatePath(CalendarRoutePattern);
}

export const NewAppointmentRoutePattern = "/new-appointment"
export const getNewAppointmentRoute = () => {
	return generatePath(NewAppointmentRoutePattern);
}

// export const EditAppointmentRoutePattern = "/new-appointment/:bookingId"
// export const getEditAppointmentRoute = (bookingId) => {
//   return generatePath(EditAppointmentRoutePattern,{bookingId});
// }

export const NewClientRoutePattern = "/new-client"
export const getNewClientRoute = () => {
	return generatePath(NewClientRoutePattern);
}

export const SearchClientRoutePattern = "/search-client"
export const getSearchClientRoute = () => {
	return generatePath(SearchClientRoutePattern);
}

export const ClientProfileRoutePattern = "/client-profile/:clientId"
export const getClientProfileRoute = (clientId) => {
	return generatePath(ClientProfileRoutePattern, { clientId });
}

export const BookinginvoiceRoutePattern = "/booking-invoice"
export const getBookinginvoiceRoute = () => {
	return generatePath(BookinginvoiceRoutePattern);
}

export const BookingInvoiceDownloadRoutePattern = "/booking-invoice/:appointmentId"
export const getBookingInvoiceDownloadRoute = (appointmentId) => {
	return generatePath(BookingInvoiceDownloadRoutePattern, { appointmentId });
}


export const rebookAppointmentPattern = "/rebook-appointment/:appointmentId/:clientId"
export const rebookAppointmentRoute = (appointmentId, clientId) => {
	return generatePath(rebookAppointmentPattern, { appointmentId, clientId });
}

export const SearchLocationRoutePattern = "/search-location"
export const getSearchLocationRoute = () => {
	return generatePath(SearchLocationRoutePattern);
}


export const ShareBookingLinkPattern = "/share-booking-link"
export const getShareBookingLinkRoute = () => {
	return generatePath(ShareBookingLinkPattern);
}

export const EditClientRoutePattern = "/edit-client/:clientId"
export const getEditClientRoute = (clientId) => {
	return generatePath(EditClientRoutePattern, { clientId });
}

export const EditAppointmentRoutePattern = "/edit-appointment/:bookingId"
export const getEditAppointmentRoute = (bookingId) => {
	return generatePath(EditAppointmentRoutePattern, { bookingId });
}

export const blockOutTimePattern = "/block-out-time"
export const getBlockOutTimeRoute = () => {
	return generatePath(blockOutTimePattern);
}

export const EditBlockOutTimePattern = "/edit-block-out-time/:blockOutId/:blockOutDateId"
export const getEditBlockOutTimeRoute = (blockOutId, blockOutDateId) => {
	return generatePath(EditBlockOutTimePattern, { blockOutId, blockOutDateId });
}

export const viewBlockoutTimeRoutePattern = "/view-block-out-time/:blockOutId/:blockOutDateId"
export const getViewBlockoutTimeRoute = (blockOutId, blockOutDateId) => {
	return generatePath(viewBlockoutTimeRoutePattern, { blockOutId, blockOutDateId });
}

export const ViewClienProfileRoutePattern = "/view-client-profile/:clientId"
export const getViewClientProfileRoute = (clientId) => {
	return generatePath(ViewClienProfileRoutePattern, { clientId });
}

export const getFindAPrescriberLandingPageRoute = "/find-a-prescriber-near-me"
export const getFindAPrescriberLandingPage = () => {
	return generatePath(getFindAPrescriberLandingPageRoute);
}


export const ClientProfileFormsRoutePattern = "/client-profile/:clientId/forms"
export const getClientProfileFormsRoute = (clientId) => {
	return generatePath(ClientProfileFormsRoutePattern, { clientId });
}



export const manageFormsRoutePattern = "/manage-forms"
export const manageFormsRoute = () => {
	return generatePath(manageFormsRoutePattern);
}

export const createNewFormRoutePattern = "/create-new-form/:formName"
export const createNewFormRoute = (formName) => {
	return generatePath(createNewFormRoutePattern, { formName });
}

export const editFormRoutePattern = "/edit-form/:consentFormId"
export const editFormRoute = (consentFormId) => {
	return generatePath(editFormRoutePattern, { consentFormId });
}
export const editAftercareFormRoutePath = "/edit-form-aftercare/:aftercareFormId"
export const getEditAftercareFormRoute = (aftercareFormId) => {
	return generatePath(editAftercareFormRoutePath, { aftercareFormId });
}

// export const previewFormRoutePattern = "/preview-form/:consentFormId"
// export const previewFormRoute = (consentFormId) => {
//   return generatePath(previewFormRoutePattern, { consentFormId });
// }

export const previewFormRoutePattern = "/preview-form/:consentFormId"
export const previewFormRoute = (consentFormId) => {
	return generatePath(previewFormRoutePattern, { consentFormId });
}

export const takePaymentRoutePattern = "/appointment/take-payment"
export const paymentRoute = () => {
	return generatePath(takePaymentRoutePattern);
}

export const getPaymentPreviewRoutePattern = "/appointment/payment-preview"
export const paymentPreviewRoute = () => {
	return generatePath(getPaymentPreviewRoutePattern);
}

export const productDetailsRoutePattern = "/appointment/product-details"
export const batchNumberRoute = () => {
	return generatePath(productDetailsRoutePattern);
}

export const appointmentPhotosRoutePattern = "/appointment/photos"
export const appointmentPhotosRoute = () => {
	return generatePath(appointmentPhotosRoutePattern);
}

export const afterCareDashboardRoutePattern = "/appointment/after-care-form"
export const afterCareDashboardRoute = () => {
	return generatePath(afterCareDashboardRoutePattern);
}

export const afterCareAddQARoutePattern = "/appointment/after-care-form/edit"
export const afterCareFormEditRoute = () => {
	return generatePath(afterCareAddQARoutePattern);
}

export const onBoardingPagesRoutePattern = "/get-started-onboarding"
export const onBoardingPagesRoute = () => {
	return generatePath(onBoardingPagesRoutePattern);
}

export const onBoardingLocationsRoutePattern = "/get-started-onboarding/location"
export const onBoardingLocationRoute = () => {
	return generatePath(onBoardingLocationsRoutePattern);
}

export const completeonBoardingRoutePattern = "/get-started-onboarding/profile"
export const completeonBoardingRoute = () => {
	return generatePath(completeonBoardingRoutePattern);
}

export const clientForwardConsetFormRoutePattern = "/client-forward-consent-form"
export const clientForwardConsetFormRoute = () => {
	return generatePath(clientForwardConsetFormRoutePattern);
}

export const completeClientForwardConsetFormRoutePattern = "/client-forward-consent-form/preview"
export const clientForwardConsetFormPrewRoute = () => {
	return generatePath(completeClientForwardConsetFormRoutePattern);
}

export const addNewClinicRoutePattern = "/add-new-clinic"
export const addNewClinicPageRoute = () => {
	return generatePath(addNewClinicRoutePattern);
}

export const addNameAndAddressRoutePattern = "/add-new-clinic/add-clinic-name"
export const addNameAndAddrRoute = () => {
	return generatePath(addNameAndAddressRoutePattern);
}

export const completeAddNewClinicRoutePattern = "/new-clinic-address-name"
export const comnpleteAddClinicAddressRoute = () => {
	return generatePath(completeAddNewClinicRoutePattern);
}

export const addDescriptionForNewClinicRoutePattern = "/add-description"
export const addDescriptionRoute = () => {
	return generatePath(addDescriptionForNewClinicRoutePattern);
}

export const clinicContactDetailsRoutePattern = '/clinic-contact-details'
export const clinicContactDetailsRoute = () => {
	return generatePath(clinicContactDetailsRoutePattern);
}

export const clinicPhotoUploadRoutePattern = '/clinic-photo-upload'
export const clinicUploadPhotoRoute = () => {
	return generatePath(clinicPhotoUploadRoutePattern);
}

export const clinicWorkingHoursRoutePattern = '/clinic-working-hours'
export const clinicWorkingHoursRoute = () => {
	return generatePath(clinicWorkingHoursRoutePattern);
}

export const clinicQandARoutePattern = '/clinic-walk-in-form'
export const clinicWorkingHoursEditRoute = () => {
	return generatePath(clinicQandARoutePattern);
}


export const clinicFixTimeRoutePattern = '/clinic-fixed-time'
export const clinicTimeTypeRoute = () => {
	return generatePath(clinicFixTimeRoutePattern);
}


export const createNewAfterCareFormRoutePattern = "/create-after-care-form/:formName"
export const createNewAfterCareFormRoute = (formName) => {
	return generatePath(createNewAfterCareFormRoutePattern, { formName });
}


export const PrewAfterCareFormRoutePattern = "/prew-after-care-form/:aftercareDocId"
export const PreviewAfterCareFormRoute = (aftercareDocId) => {
	return generatePath(PrewAfterCareFormRoutePattern, { aftercareDocId });
}

export const editAfterCareFormRoutePattern = "/edit-after-care-form/:aftercareDocId"
export const EditAfterCareFormRoute = (aftercareDocId) => {
	return generatePath(editAfterCareFormRoutePattern, { aftercareDocId });
}

export const getAvailabilityClientPattern = "/availability-client"
export const getAvailabilityClientRoutePage = () => {
	return generatePath(getAvailabilityClientPattern)
}

export const getClientTimePattern = "/client-time"
export const getClientTimeRoutePage = () => {
	return generatePath(getClientTimePattern)
}

export const getClientFlexibleHoursPattern = "/client-flexible-hours"
export const getClientFlexibleHoursRoutePage = () => {
	return generatePath(getClientFlexibleHoursPattern)
}

export const getOnboardingTimeAddServicePattern = "/onboarding-time-add-service"
export const getOnboardingTimeAddServiceRoutePage = () => {
	return generatePath(getOnboardingTimeAddServicePattern)
}


export const getOnboardingBookingLinkPattern = "/onboarding-booking-link"
export const getOnboardingBookingLinkRoutePage = () => {
	return generatePath(getOnboardingBookingLinkPattern)
}

export const getOnboardingOnlinePaymentPattern = "/onboarding-online-payment"
export const getOnboardingOnlinePaymetRoutePage = () => {
	return generatePath(getOnboardingOnlinePaymentPattern)
}


export const getOnboardingAddLocationRoutePattern = "/onboarding-add-location"
export const getOnboardingAddLocationRoutePage = () => {
	return generatePath(getOnboardingAddLocationRoutePattern)
}

export const appointmentOverviewTakePaymentRoutePattern = '/take-payment'
export const appointmentOverviewTakePaymentRoute = () => {
	return generatePath(appointmentOverviewTakePaymentRoutePattern);
}

export const appointmentOverviewNoShowRoutePattern = '/no-show'
export const appointmentOverviewNoShowRoute = () => {
	return generatePath(appointmentOverviewNoShowRoutePattern);
}

export const appointmentOverviewBeforeAndAfterAddPhotosRoutePattern = '/before-and-after-add-photos'
export const appointmentOverviewBeforeAndAfterAddPhotosRoute = () => {
	return generatePath(appointmentOverviewBeforeAndAfterAddPhotosRoutePattern);
}

// export const appointmentOverviewBatchNumberRoutePattern = '/batchnumber-select-service'
// export const appointmentOverviewBatchNumberRoute = () => {
//     return generatePath(appointmentOverviewBatchNumberRoutePattern);
// }


export const appointmentOverviewBeforeAndAfterPhotosRoutePattern = '/before-and-after-photos/:consentFormRecordId'
export const appointmentOverviewBeforeAndAfterPhotosRoute = (consentFormRecordId) => {
	return generatePath(appointmentOverviewBeforeAndAfterPhotosRoutePattern, { consentFormRecordId });
}


export const appointmentOverviewBatchNumberRoutePattern = '/batch-number/:consentFormRecordId'
export const appointmentOverviewBatchNumberRoute = (consentFormRecordId) => {
	return generatePath(appointmentOverviewBatchNumberRoutePattern, { consentFormRecordId });
}


export const getForwardConsentFormPageRoute = "/forward-consent-form/:bookingId"
export const getForwardConsentFormPage = (bookingId) => {
	return generatePath(getForwardConsentFormPageRoute, { bookingId });
}

export const getThankyouPageRoute = "/consent-form-thank-you/:consentFormId"
export const getThankyouPage = (consentFormId) => {
	return generatePath(getThankyouPageRoute, { consentFormId });
}

export const getFinanceRegistrationRoute = "/finance-registration"
export const FinanceRegistrationRoute = () => {
	return generatePath(getFinanceRegistrationRoute);
}

export const getshopSettingRoute = "/shop-setting"
export const shopSettingRoute = () => {
	return generatePath(getshopSettingRoute);
}

export const getPrescriberRegistrationRoute = "/prescriber-registration"
export const getPrescriberRegistrationPage = () => {
	return generatePath(getPrescriberRegistrationRoute);
}

export const getPrescriberAboutYouRoute = "/prescriber-about-you"
export const getPrescriberAboutYouPage = () => {
	return generatePath(getPrescriberAboutYouRoute);
}

export const getPrescriberBusinessDetailsRoute = "/prescriber-business-details"
export const getPrescriberBusinessDetailsPage = () => {
	return generatePath(getPrescriberBusinessDetailsRoute);
}

export const getFinanceTrainingCertificateRoute = "/finance-training-certificate"
export const FinanceTrainingCertificateRoute = () => {
	return generatePath(getFinanceTrainingCertificateRoute);
}
export const getFinanceInsuranceRoute = "/finance-insurance"
export const FinanceInsuranceRoute = () => {
	return generatePath(getFinanceInsuranceRoute);
}
export const getDocumnetPageRoutePattern = "/document/upload"
export const getDocumentPage = () => {
	return generatePath(getDocumnetPageRoutePattern)
}

export const getPrescriberPreferencesRoutePattern = "/prescriber-preferences"
export const getPrescriberPreferencesPage = () => {
	return generatePath(getPrescriberPreferencesRoutePattern)
}

export const getFinanceGetPaidRoute = "/finance-get-paid"
export const FinanceGetPaidRoute = () => {
	return generatePath(getFinanceGetPaidRoute);
}

export const getFinancetermsRoute = "/finance-terms&conditions"
export const FinancetermsRoute = () => {
	return generatePath(getFinancetermsRoute);
}

export const getPurchaseWebsitePackageRoutePattern = "/purchase-website-package/:slug"
export const getPurchaseWebsitePackageRoutePage = (slug) => {
	return generatePath(getPurchaseWebsitePackageRoutePattern, { slug })
}

export const getCustomBuildThankYouRoutePattern = "/custom-biuld-thank-you"
export const getCustomBuildThankYouRoutePage = () => {
	return generatePath(getCustomBuildThankYouRoutePattern)
}


export const HairProductLinkRoutePattern = "/share-hair-prescription-link"
export const getHairProductLinkRoute = () => {
	return generatePath(HairProductLinkRoutePattern);
}

export const HairProductsRoutePattern = "/hair-prescription-products/:slug"
export const getHairProductsRoute = (slug) => {
	return generatePath(HairProductsRoutePattern, { slug });
}

export const HairPrescriptionProductsRoutePattern = "/hair-prescription-products"
export const getHairPrescriptionProductsRoute = () => {
	return generatePath(HairPrescriptionProductsRoutePattern)
}


export const HairProductsAmendAnswersRoutePattern = "/hair-prescription-amend-answers/:orderId"
export const getHairProductsAmendAnswersRoute = (orderId) => {
	return generatePath(HairProductsAmendAnswersRoutePattern, { orderId });
}


export const registrationLandingPage = "/registration"
export const registrationLandingPagePattern = () => {
	return generatePath(registrationLandingPage);
}

export const loginLandingPage = "/login"
export const loginLandingPagePattern = () => {
	return generatePath(loginLandingPage);
}

export const revenueDashboardLandingPage = "/revenue-dashboard"
export const revenueDashboardLandingPagePattern = () => {
	return generatePath(revenueDashboardLandingPage);
}

export const verifyEmailLandingPage = "/verify-email"
export const verifyEmailLandingPagePattern = () => {
	return generatePath(verifyEmailLandingPage);
}

export const forgotEmailLandingPage = "/forgot-password"
export const forgotEmailLandingPagePattern = () => {
	return generatePath(forgotEmailLandingPage);
}

export const verifyForgotPasswordLandingPage = "/verify-forgot-password"
export const verifyForgotPasswordLandingPagePattern = () => {
	return generatePath(verifyForgotPasswordLandingPage);
}

export const forgotEmailUpdateLandingPage = "/forgot-password-update"
export const forgotEmailUpdateLandingPagePattern = () => {
	return generatePath(forgotEmailUpdateLandingPage);
}

export const editProfileLandingPage = "/edit-profile"
export const editProfileLandingPagePattern = () => {
	return generatePath(editProfileLandingPage);
}


export const comingSoon = "/comingsoon"
export const comingsoonpage = () => {
	return generatePath(comingSoon);
}


export const financeFirstStapPage = "/check-user"
export const financeFirstStapPagePattern = () => {
	return generatePath(financeFirstStapPage);
}

export const downloadFinanceInvoicePage = "/finance-invoice/:slug"
export const downloadFinanceInvoicePagePattern = () => {
	return generatePath(downloadFinanceInvoicePage);
}


export const noFinanceRequests = "/fowrard-finance-step"
export const noFinanceRequestsPattern = () => {
	return generatePath(noFinanceRequests);

}

export const welcomeBack = "/welcome-back"
export const welcomeBackPattern = () => {
	return generatePath(welcomeBack);
}

export const financeOnboardingSteps = "/onboarding-steps"
export const financeOnboardingStepsPattern = () => {
	return generatePath(financeOnboardingSteps);
}

export const financeStepsThankYouPage = '/finance-application/success';
export const financeStepsThankYouPagePattern = () => {
	return generatePath(financeStepsThankYouPage, {});
}

export const financeStepsAbortPage = '/finance-application/abort';
export const financeStepsAbortPagePattern = () => {
	return generatePath(financeStepsAbortPage, {});
}

export const financeStepsFailPage = '/finance-application/fail';
export const financeStepsFailPagePattern = () => {
	return generatePath(financeStepsFailPage, {});
}

export const financeOnboardingStepsNewDesign = "/onboarding-steps-new"
export const financeOnboardingStepsNewPattern = () => {
	return generatePath(financeOnboardingStepsNewDesign);
}

export const initiateFinanceLoanRequest = "/complete-finance-application/:slug"
export const initiateFinanceLoanRequestRoute = () => {
	return generatePath(initiateFinanceLoanRequestRoute);
}

export const hairCareLandingPageRouteRequest = "/prescription-haircare"
export const hairCareLandingPageRoute = () => {
	return generatePath(hairCareLandingPageRouteRequest);


}
export const getForwardConsentFormStatus = "forward-consent-form/:bookingId/:status"
export const forwardConsentFormStatusPattern = (bookingId, status) => {
	return generatePath(getForwardConsentFormStatus, { bookingId, status })
}

export const businesslocationRoutePattern = '/business-location'
export const businesslocationRoute = () => {
	return generatePath(businesslocationRoutePattern);
}

export const clientAdditionalPhotos = "/client-additional-photos/:clientId"
export const clientAdditionalPhotosPattern = (clientId) => {
	return generatePath(clientAdditionalPhotos, { clientId })
}

export const businessLocationDetailsRoutePattern = '/business-location-details'
export const businessLocationDetailsRoute = () => {
	return generatePath(businessLocationDetailsRoutePattern);
}


export const directDepositeLinkRoutePattern = "/direct-deposit-link/:id"
export const directDepositeLinkRoute = () => {
	return generatePath(directDepositeLinkRoutePattern);
}

export const directDepositeThankyouRoutePattern = "/direct-deposit-thank-you"
export const directDepositeThankyouRoute = () => {
	return generatePath(directDepositeThankyouRoutePattern);
}
export const businessSettingRoute = "/businessSetting"
export const businessSettingRoutePattern = (clientId) => {
	return generatePath(businessSettingRoute)
}

export const getTreatmentPageRoute = "/Treatment-page"
export const getTreatmentPage = () => {
	return generatePath(getTreatmentPageRoute);
}

export const productQuestionAnswerRoute = "/product-question-answer/:productId"
export const productQuestionAnswerRoutePattern = () => {
	return generatePath(productQuestionAnswerRoute)
}

export const getClinicPageRoute = "/Clinic-page"
export const getClinicPage = () => {
	return generatePath(getClinicPageRoute);
}

export const getTreatmentLocationPageRoute = "/Location-Treatment-page"
export const getTreatmentLocationPage = () => {
	return generatePath(getTreatmentLocationPageRoute);
}

export const bookingsCoreRoutePattern = "/bookings";

export const productQuestionsRoute = "/product-question/:productId"
export const productQuestionsRoutePattern = () => {
	return generatePath(productQuestionsRoute)
}

export const getImportClient = "/import-client"
export const importClientRoute = () => {
	return generatePath(getImportClient);
}

export const businessTreatmentRoutePattern = '/business-Treatment'
export const businessTreatmentRoute = () => {
	return generatePath(businessTreatmentRoutePattern);
}

export const businessTreatmentDetailsRoutePattern = '/business-Treatment-details'
export const businessTreatmentDetailsRoute = () => {
	return generatePath(businessTreatmentDetailsRoutePattern);
}

export const editMedicalFormRoute = '/edit-medical-form';
export const previewMedicalFormRoute = '/preview-medical-form';
export const getBusinessProfileSettingPageRoute = "/business-profile-setting"
export const getBusinessProfileSettingPage = () => {
	return generatePath(getBusinessProfileSettingPageRoute);
}

export const getBusinessProfileMarketingPageRoute = "/marketing"
export const getBusinessMarketingPage = () => {
	return generatePath(getBusinessProfileMarketingPageRoute);
}
export const getNoclientPageRoute = "/client"
export const getNoclientPage = () => {
	return generatePath(getNoclientPageRoute);
}
// export const getBusinessProfileMarketingPageRoute = "/marketing"
// export const getBusinessMarketingPage = () => {
//   return generatePath(getBusinessProfileMarketingPageRoute);
// }

export const getBusinessSettingPageRoute = "/business-setting"
export const getBusinessSettingPage = () => {
	return generatePath(getBusinessSettingPageRoute);
}

export const getManageAccountPageRoute = "/manage-account"
export const getManageAccountPage = () => {
	return generatePath(getManageAccountPageRoute);
}

export const getPaymentsControlPageRoute = "/payments-control"
export const getPaymentsControlPage = () => {
	return generatePath(getPaymentsControlPageRoute);
}

export const getFormSettingsPageRoute = "/form-settings"
export const getFormSettingsPage = () => {
	return generatePath(getFormSettingsPageRoute);
}

export const getSecuritySettingsPageRoute = "/security-settings"
export const getSecuritySettingsPage = () => {
	return generatePath(getSecuritySettingsPageRoute);
}


export const facesBusinessRoute = "/"
export const facesBusinessRoutePattern = (facesBusinessRoute) => {
	return generatePath(facesBusinessRoute)
}

