import { CLEAR_CLIENT_STATS, GET_CLIENT_STATS } from "../../constants";

const initialState = {
  clientStats: null,
};

export default function clientStatsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_STATS:
      return {
        ...state,
        clientStats: action.payload,
      };
    case CLEAR_CLIENT_STATS:
      return {
        ...state,
        clientStats: [],
      };
      
    default:
      return state;
  }
}
