export const GET_FORWARD_CONSENT_FORM_BOOKING_OVERVIEW = "GET_FORWARD_CONSENT_FORM_BOOKING_OVERVIEW"
export const CLEAR_FORWARD_CONSENT_FORM_BOOKING_OVERVIEW = "CLEAR_FORWARD_CONSENT_FORM_BOOKING_OVERVIEW"

export const FORWARDED_CONSENT_FORM_CLIENT_STEP_COMPLETED = "FORWARDED_CONSENT_FORM_CLIENT_STEP_COMPLETED"
export const FORWARDED_CONSENT_FORM_CONSENT_STEP_COMPLETED = "FORWARDED_CONSENT_FORM_CONSENT_STEP_COMPLETED"
export const FORWARDED_CONSENT_FORM_PAYMENT_STEP_COMPLETED = "FORWARDED_CONSENT_FORM_PAYMENT_STEP_COMPLETED"

export const GET_PAYMENT_METHOD_AVAILABLE = "GET_PAYMENT_METHOD_AVAILABLE"
export const CLEAR_PAYMENT_METHOD_AVAILABLE = "CLEAR_PAYMENT_METHOD_AVAILABLE"

export const GET_FORWARD_CONSENT_FORM_QUESTIONS = "GET_FORWARD_CONSENT_FORM_QUESTIONS"
export const CLEAR_FORWARD_CONSENT_FORM_QUESTIONS = "CLEAR_FORWARD_CONSENT_FORM_QUESTIONS"

export const GET_CLIENT_SECRET_SETUP_INTENT_DATA="GET_CLIENT_SECRET_SETUP_INTENT_DATA"
export const CLEAR_CLIENT_SECRET_SETUP_INTENT_DATA="CLEAR_CLIENT_SECRET_SETUP_INTENT_DATA"

export const GET_RYFT_PAYMENT_TOKEN_FORWARD_CONSENT_FORM  = 'GET_RYFT_PAYMENT_TOKEN_FORWARD_CONSENT_FORM ';
export const CLEAR_RYFT_PAYMENT_TOKEN_FORWARD_CONSENT_FORM  = 'CLEAR_RYFT_PAYMENT_TOKEN_FORWARD_CONSENT_FORM ';

export const GET_SELECTED_PAYMENT_OPTION  = 'GET_SELECTED_PAYMENT_OPTION ';
export const CLEAR_SELECTED_PAYMENT_OPTION  = 'CLEAR_SELECTED_PAYMENT_OPTION ';
