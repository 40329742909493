import { gql, useLazyQuery } from "@apollo/client";

const GET_CLINIC_LOCATIONS = gql`
 query GetClinicLocations($businessIds: [Int]) {
  getClinicLocations(businessIds: $businessIds) {
    success
    message
    data {
      companyName
      isFinanceAvailable
      isStripeSetup
      isRyftSetup
      total
      items {
        clinicId
        clinicName
        imageUrl
        completeAddress
      }
    }
  }
}
`;

export const GetClinicLocationsAction = () => {
  const [getClinicLocationsFunction, { data, loading, error }] = useLazyQuery(
    GET_CLINIC_LOCATIONS,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClinicLocations: responseData }) => {
        console.log(responseData, "nnn");
        if (responseData?.success) {
          // dispatch({
          //     type: GET_CLINIC_LOCATIONS_SUCCESS,
          //     payload: responseData
          // })
        }
      },
    }
  );

  const initGetClinicLocations = (variables, successCallback, errorCallback) => {
    console.log(variables, "///");
    getClinicLocationsFunction({
      variables: {
        ...variables,
      },
    })
      .then((res) => {
        successCallback(res?.data?.getClinicLocations);
      })
      .catch((err) => {
        errorCallback(err);
      });
  };

  return { data, loading, error, initGetClinicLocations };
};
