import React from 'react'
import './batch-number-main.scss'
import backArrow from '../../../../images/icons/BackArrow.svg'
import searchIcon from '../../../../images/search_icon.svg'
import CustomeImageUpload from './image-upload/CustomeImageUpload'
import imageUpload from '../../../../images/new-appointment/file-upload.svg'
import traceIcon from '../../../../images/trash.svg'


const BatchNumberList = ({setCurrentStep}) => {
    const handleBackStep = () => { 
        setCurrentStep(1);
    }
    return (
        <>
            <div className='batch-number-list-main container'>
                <div className='batch-number-list-header  mt-5'>
                    <img src={backArrow} alt='img' onClick={handleBackStep} />
                </div>
                <div className='batch-number-list-title'>
                    <h1>Batch number</h1>
                    <p>Add store items along with their batch numbers.</p>
                    <div className='search-product-tab'>
                        <img src={searchIcon} alt='img' />
                        <input type='text' placeholder='Add the product you used' />
                    </div>
                    <div className='batch-number-item-list'>
                        <p>Items added</p>
                        <hr />
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <span className='d-flex'>
                                <div className='img-content'>
                                    <img src={imageUpload} alt='img' />
                                </div>
                                <span className='list-item'>
                                    <p className='pb-1'>This is a product item</p>
                                    <p>£100.00</p>
                                </span>
                            </span>
                            <div>
                                <img src={traceIcon} />
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <span className='d-flex'>
                                <div className='img-content'>
                                    <img src={imageUpload} alt='img' />
                                </div>
                                <span className='list-item'>
                                    <p className='pb-1'>This is a product item</p>
                                    <p>£100.00</p>
                                </span>
                            </span>
                            <div>
                                <img src={traceIcon} />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex add-batch-no-option'>
                        <span className='me-3'>Upload Batch number</span>
                        <span>Add manually</span>
                    </div>
                    <div className='file-upload-sec mt-2'>
                        <CustomeImageUpload />
                        <CustomeImageUpload />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BatchNumberList
