import { gql, useMutation } from "@apollo/client";

const UPDATE_PRESCRIPTION_ATTACHED_FORM_MUTATION = gql`
mutation UpdatePrescriptionAttachedForm($prescriptionId: Int, $clientId: Int, $consentFormId: Int, $attachMedicalForm: Boolean) {
  updatePrescriptionAttachedForm(prescriptionId: $prescriptionId, clientId: $clientId, consentFormId: $consentFormId, attachMedicalForm: $attachMedicalForm) {
    success
    message
  }
}
`;


export const UpdatePrescriptionAttachedFormAction = () => {
    const [
        UpdatePrescriptionAttachedFormResponseFunction,
        updatePrescriptionAttachedFormResponseData,
    ] = useMutation(UPDATE_PRESCRIPTION_ATTACHED_FORM_MUTATION)

    const updatePrescriptionAttachedFormQueryData = updatePrescriptionAttachedFormResponseData; //variable

    const initUpdatePrescriptionAttachedFormData = (data, successCallback, errorCallback) => {
        UpdatePrescriptionAttachedFormResponseFunction({
            variables: data,
        }).then(({ data: { updatePrescriptionAttachedForm: responseData } }) => {
            if (responseData) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback()
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
        })
    }

    return { updatePrescriptionAttachedFormQueryData, initUpdatePrescriptionAttachedFormData };
};
