import { 
    GET_INSURANCE_COMPANY_DATA, 
    GET_INSURANCE_COMPANY_DATA_ERROR, 
    GET_INSURANCE_COMPANY_DATA_SUCCESS,
    GET_INSURANCE_COMPANY_PAGE_DATA, 
    GET_INSURANCE_COMPANY_PAGE_DATA_ERROR, 
    GET_INSURANCE_COMPANY_PAGE_DATA_SUCCESS
} from "../../constants/insurance-company/insurance-company"



export default function getInsuranceCompanyData(data) {

    return {
        type: GET_INSURANCE_COMPANY_DATA,
        payload: data
    }
}

export function getInsuranceCompanyDataSuccess(data) {
    return {
        type: GET_INSURANCE_COMPANY_DATA_SUCCESS,
        payload: data
    }
}
export function getInsuranceCompanyDataError(data) {
    return {
        type: GET_INSURANCE_COMPANY_DATA_ERROR,
        payload: data

    }
}

export function getInsuranceCompanyPageData(data) {

    return {
        type: GET_INSURANCE_COMPANY_PAGE_DATA,
        payload: data
    }
}

export function getInsuranceCompanyPageDataSuccess(data) {
    return {
        type: GET_INSURANCE_COMPANY_PAGE_DATA_SUCCESS,
        payload: data
    }
}
export function getInsuranceCompanyPageDataError(data) {
    return {
        type: GET_INSURANCE_COMPANY_PAGE_DATA_ERROR,
        payload: data

    }
}
