import { gql, useMutation } from "@apollo/client";

const SEND_CONSENT_FORM_OR_MEDICAL_FORM_TO_PRESCRIBER_MUTATION = gql`
mutation SendConsentFormOrMedicalFormToPrescriber($consentFormId: Int, $prescriberId: Int, $clientId: Int) {
  sendConsentFormOrMedicalFormToPrescriber(consentFormId: $consentFormId, prescriberId: $prescriberId, clientId: $clientId) {
    success
    message
  }
}
`;


export const SendConsentFormOrMedicalFormToPrescriberAction = () => {
    const [
        SendConsentFormOrMedicalFormToPrescriberResponseFunction,
        sendConsentFormOrMedicalFormToPrescriberResponseData,
    ] = useMutation(SEND_CONSENT_FORM_OR_MEDICAL_FORM_TO_PRESCRIBER_MUTATION)

    const sendConsentFormOrMedicalFormToPrescriberQueryData = sendConsentFormOrMedicalFormToPrescriberResponseData; //variable

    const initSendConsentFormOrMedicalFormToPrescriberData = (data, successCallback, errorCallback) => {
        SendConsentFormOrMedicalFormToPrescriberResponseFunction({
            variables: data,
        }).then(({ data: { sendConsentFormOrMedicalFormToPrescriber: responseData } }) => {
            if (responseData) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback()
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
        })
    }

    return { sendConsentFormOrMedicalFormToPrescriberQueryData, initSendConsentFormOrMedicalFormToPrescriberData };
};
