import React ,{useState,useEffect} from "react";
import "./FinanceBanner.scss";
import { LinearProgress } from "@mui/material";
import verifiedStart from "../../../../images/verifiedStart.svg";
import {GetFinanceRegistrationStatusAction} from "../../../redux/actions/finance-registrtion/getFinanceRegistartionStatus"
import {config} from "../../../../config"
import image from "../../../../images/analysis 1.png"
import whatsappIcon from "../../../../images/whatsappwhite.svg"
import {insuranceLandingPage} from "../../../../Routes"
import { useNavigate } from "react-router";
import shieldIcon from "../../../../images/starinshield.svg"
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import StepButton from "../../../common/StepButton";


const FinanceBanner = ({isTranningBanner,isInsuranceBanner, title, desc, isBorder = false, isAdd = false, icon: Icon, uppercontent, lowercontent, currentStep, totalSteps, facesVarified }) => {
  console.log("currentStep",currentStep,"????",totalSteps)
  // const progress = (currentStep / totalSteps) * 100;
  const progress = currentStep
  
  const remain = 100 - progress

const navigate=useNavigate()
 const WhatsappLinkRedirect = config.FINANCE_WHATSAPP_LINK;
  const TranningWhatsAppLink=config.TRANNING_WHATSAPP_LINK
 const handleInsurancepage = () => {
    navigate(insuranceLandingPage)
  }

  return (
    <>
      <div className="finance-banner-container cus-top-margin-5">
        <div className="finance-content-container">
        {isBorder  &&  (
            <div className="border-container">
               {/* <LinearProgress variant="determinate" value={progress} /> */}
              <div style={{ width : `${progress}%`}} className={`blue-border  ${!(progress==100) ? "fill-border" : "normal-border"} `} />
              <div style={{ width : `${remain}%`}} className={`gray-border  ${!(remain == 100) ? "remain-border" : "normal-border"} `} />
            </div>
          )}
          <h1>{title}</h1>
          <p>{desc}{facesVarified && <span className="ms-2 FacesVarified"><span className="facesTag"><img src={verifiedStart} alt="verifiedStart" /></span>VERIFIED BUSINESS</span>}</p>
         
          {
            isTranningBanner && (
              <div className="TrainingCardWrapper d-block ">
              <div className="tranningCardGrid">
              <div className="cardIcon">
               <div className="iconTxt">
              <img src={image} className="me-1" />Need more training ?

               </div>
              <p>
                  Speak to our partnering training academy to upskill. Get 50% off
                  with code <b>‘Faces50’</b>
                </p>
              </div>
              <div className="CardContent">
                
                <button
                  className="w-100 btn border-0 text-white shadow-none"
                  style={{
                    borderRadius: "100px",
                    background:
                      "linear-gradient(180deg, #70FD78 -34.31%, #3FB42E 100%)",
                  }}
                  onClick={() => {
                    window.open(TranningWhatsAppLink, "_blank");
                  }}
                >
                  <img src={whatsappIcon} width={20} className="me-1" />
                  Whatsapp us
                </button>
              </div>
              </div>
              
              {/* <div className="p-4">
                      <h3>
                        
                      </h3>
                    
                      <button
                        className="w-100 btn border-0 text-white shadow-none"
                        style={{
                          borderRadius: "100px",
                          background:
                            "linear-gradient(180deg, #70FD78 -34.31%, #3FB42E 100%)",
                        }}
                        onClick={() => {
                          window.open(WhatsappLinkRedirect, "_blank");
                        }}
                      >
                        <img src={whatsappIcon} width={20} className="me-1"/>
                        Whatsapp us
                      </button>
                  </div> */}

              {/* <div className="container">
                    <div className="row flex-column align-items-center justify-content-center">
                      <div className="col-lg-7 mb-5">
                        <h1>Training Certificate</h1>
                        <p>
                          Evidence of your certifications and training, can authenticate
                          your account.
                        </p>
                      </div>
                      <div className="col-lg-7">
                        <p className="doc_label">Training Certificate</p>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            borderRadius: "20px",
                            border: "1px dashed #878787",
                            height: "160px",
                            color: "#878787",
                            position: "relative",
                          }}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          {uploadedImage && (
                            <>
                              <img
                                src={uploadedImage}
                                alt="Uploaded"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  objectFit: "cover",
                                  borderRadius: "20px",
                                  padding: "20px",
                                }}
                              />
                            </>
                          )}
                          {!uploadedImage && (
                            <div className="d-flex flex-column align-items-center">
                              <Uploads />
                              <p>Upload</p>
                            </div>
                          )}
                        </div>
                        {uploadedImage && (
                          <p
                            className="mb-0 mt-2"
                            onClick={()=>setOpen(true)}
                            style={{ cursor: "pointer" }}
                          >
                            Remove
                          </p>
                        )}
                        {errorMessage && (
                          <p className="text-danger mb-0 mt-2">{errorMessage}</p>
                        )}
                      </div>
                      <div className="col-lg-7 mt-5">
                        <button
                          className="w-100 btn btn-primary shadow-none continuebtn"
                          style={{ borderRadius: "100px", backgroundColor: "#1B4FE7" }}
                          onClick={handleContinue} 
                        >
                          Continue
                        </button>
                      </div>
                      <div className="col-lg-7 mb-5">
                      <div
                        className="p-4"
                        style={{
                          boxShadow: "0px 0px 0px 1px #DDD inset",
                          borderRadius: "20px",
                        }}
                      >
                        <h3>
                          <img src={image} className="me-1"/>Need more Training ?
                        </h3>
                        <p style={{ width: "90%" }}>
                          Speak to our partnering training academy to upskill. Get 50% off
                          with code <span style={{ fontWeight: "700" }}>‘Faces50’</span> .
                        </p>
                        <button
                          className="w-100 btn border-0 text-white shadow-none"
                          style={{
                            borderRadius: "100px",
                            background:
                              "linear-gradient(180deg, #70FD78 -34.31%, #3FB42E 100%)",
                          }}
                          onClick={() => {
                            window.open(WhatsappLinkRedirect, "_blank");
                          }}
                        >
                          <img src={whatsappIcon} width={20} className="me-1"/>
                          Whatsapp us
                        </button>
                      </div>
                      </div>
                      
                    </div>
                  </div> */}
            </div>
            )
          } 


         {
          isInsuranceBanner && (
            <div className="InsuranceCard ">
              <div className="InsuranceCardBanner">
              <div className="InsuranceCardIconContentWrapper">
                <div className="InsuranceCardIcon">
                  <img src={shieldIcon} alt="shieldIcon" />
                  {/* <p className="doc_label"></p> */}
                <div className="ContentWrapper">
                <h5>Need insurance ?</h5>
                 <p className="doc_Desc">Compare insurance quotes</p>
                </div>
                </div>
              </div>
              <div className="insuranceBtn">
                 <StepButton type={"button"} blue={"blue"} className={"Ibtn"}  label={"Quote me"} onClick={() => handleInsurancepage()}/>
              </div>
              </div>
           </div>
          )
         }
      
          
        </div>
      </div>
      {isAdd && (
      <div className="join-price">
        <div className="join-price-div">
          <div className="left-content">
            <Icon />
          </div>
          <div className="right-content">
            <div className="upper-content">
              {uppercontent}
            </div>
            <div className="lower-content">
              {lowercontent}
            </div>
          </div>
        </div>
      </div>
      )}

    </>
  );
};

export default FinanceBanner;
