import { gql, useMutation } from "@apollo/client";
// import { GET_PROFILE_DATA } from "../../constants";
// import { useDispatch } from "react-redux";

const INPUT_USER_PROFILE_MUTATION = gql`
mutation SavePrescriberProfilePic($image: Upload) {
  savePrescriberProfilePic(image: $image) {
    success
    message
  }
}
 `;


export const SAVEPROFILEAction = () => {
    // const dispatch = useDispatch();
    const [
        InputuserInfoProfileFunction,
        InputuserprofileData,
    ] = useMutation(INPUT_USER_PROFILE_MUTATION)

    //this will be called from components
    const InputuserInfoProfileData = InputuserprofileData; //variable

    const initInputuserProfilefunction = (data, successCallback, errorCallback) => {

        InputuserInfoProfileFunction({
            variables: data,

        })
            .then((responseData) => {
                console.log(responseData, "follish", responseData?.data?.savePrescriberProfilePic?.success)
                if (responseData?.data?.savePrescriberProfilePic?.success) {

                    console.log(responseData, "122222")
                    // dispatch({
                    //     type: GET_PROFILE_DATA,
                    //     payload: responseData?.data
                    // })
                    successCallback(responseData)
                    // toast.success(responseData.message)
                }
                else {
                    errorCallback(responseData)
                    // toast.error(responseData.message)
                }
            }).catch((err) => {
                console.log(err)
                // errorCallback(err)
                // toast.error("something went wrong!!!")
            })
    }

    return { InputuserInfoProfileData, initInputuserProfilefunction };
};