import { GET_PROFILE_DATA, GET_PROFILE_DATA_SUCCESS, LOGOUT_PROFILE, LOGOUT_PROFILE_SUCCESS } from "../../constants"


export default function getProfileData() {

    return {
        type: GET_PROFILE_DATA,
    }
}

export function getProfileDataSuccess(data) {
    return {
        type: GET_PROFILE_DATA_SUCCESS,
        payload: data
    }
}

// ====================================================================


export function logoutProfile() {
console.log("logoutProfile action called")
    return {
        type: LOGOUT_PROFILE,
    }
}

export function logoutProfileSuccess(data) {
    return {
        type: LOGOUT_PROFILE_SUCCESS,
        payload: data
    }
}
