import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import SubHeading from '../hair-product-common/hair-product-sub-heading/SubHeading'
import HairProductCard from '../hair-product-common/hair-product-card/HairProductCard'
import StepButton from '../../../common/StepButton'
import HairProductWhatsAppButton from '../hair-product-common/hair-product-whatsApp-btn/HairProductWhatsAppButton'
import './HairProductSix.scss'
import CustomCheckbox from '../../../custom-components/custom-checkbox/CustomCheckbox'

const HairProductsSix = ({ activeMain, setDueAmount, selectedCard, OnhandleContinue, intervalMonth, SaveHaiPrescriptionRequestMutationData }) => {
    console.log('selectedCard', selectedCard)
    const [interval, setInterval] = useState(() => JSON.parse(JSON.stringify(intervalMonth)))
    const [intervalError, setIntervalError] = useState("")
    const [multiMonthSaving, setMultiMonthSaving] = useState(0);
    const [savingPercentage, setSavingPercentage] = useState(0);
    const [dueAmount, setDueAmountState] = useState(0);
    const Shipping = 4.99
    const [scrollerror, Setscrollerror] = useState(false)

    useEffect(()=>{
        window.scrollTo(0, 0);
     },[])

    useEffect(() => {
        // Calculate and set the due amount whenever interval changes
        let newDueAmount = 0;
        if (interval === 1) {
            newDueAmount = (Number(selectedCard.price) + Shipping).toFixed(2);
        } else if (interval === 3) {
            newDueAmount = (Number(selectedCard.threemonthPrice) + Shipping).toFixed(2);
        }
        setDueAmountState(newDueAmount);
    }, [interval, selectedCard.price, selectedCard.threemonthPrice]);

    useEffect(() => {
        // Scroll to the first error message when the firstErrorIndex changes
            // Scroll to the interval error message
            const intervalErrorElement = document.querySelector('.hair-product-error-message');
            if (intervalErrorElement) {
                intervalErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
    }, [scrollerror]);

    const handleCheckboxChange = (value) => {
        setInterval(value);
        setIntervalError("");
    }
    const handleContinue = () => {
        if (!interval) {
            setIntervalError("Please Select Interval")
            Setscrollerror(!scrollerror)

        } else {
            
            setDueAmount(dueAmount);
            OnhandleContinue(interval)
        }
    }


    const calculateMultiMonthSaving = () => {
        // Calculate multi-month saving based on the interval
        if (interval === 1) {
            // No multi-month saving for monthly subscription
            return 0;
        } else if (interval === 3) {
            // Calculate multi-month saving for 3-month subscription
            return (selectedCard.price * 3) - (selectedCard.threemonthPrice);
        } else {
            // Add more conditions for other intervals if needed
            return 0;
        }
    }

    const threemonthsaving = () => {
        return (selectedCard.price * 3) - (selectedCard.threemonthPrice);
    }

    const calculatePercentageSaving = () => {
        // Calculate multi-month saving based on the interval
        if (interval === 1) {
            // No multi-month saving for monthly subscription
            return 0;
        } else if (interval === 3) {
            // Calculate multi-month saving for 3-month subscription
            return ((selectedCard.price * 3) + Shipping) - ((Number(selectedCard.threemonthPrice) + Shipping).toFixed(2));
        } else {
            // Add more conditions for other intervals if needed
            return 0;
        }
    }

    const calculateSavingPercentage = () => {
        if (interval === 3) {
            const saving = calculatePercentageSaving();
            const percentage = (saving / ((selectedCard.price * 3) + Shipping)) * 100;
            return percentage;
        } else {
            return 0;
        }
    }

    useEffect(() => {
        // Calculate and set multi-month saving whenever interval changes
        const saving = calculateMultiMonthSaving();
        setMultiMonthSaving(saving);

        // Calculate and set saving percentage whenever interval changes
        const percentage = calculateSavingPercentage();
        setSavingPercentage(percentage);
    }, [interval]);

    return (
        <>
        <div className='Main-Hair-Payment-Wrapper'>
            <SubHeading
                title={`Payment`}
                desc="Here is a summary of your payment plan.  Please review and click ‘continue’ to proceed."
            />
            <div className='flex-gap-normal'>
                <div className="hair-product-payment-container">
                    <HairProductCard
                        icon={selectedCard.image}
                        title={selectedCard.name}
                        desc={selectedCard.description}
                        price={selectedCard.lowPrice}
                        selected={true}
                        activeMain={activeMain}
                    />
                    
                    <div className='innerTextWrapper'>
                        <h4>Payment Plan</h4>
                        {/* <p>Hair regrowth typically takes 3-6 months. Save on a longer plan for better regrowth.</p> */}
                        <p>Select your payment plan and shipping frequency.</p>
                    </div>

                    <div className='hair-product-payment-checkboxes'>
                        <div className="hair-product-payment-checkbox-div cursor-pointer " id="check1" onClick={() => handleCheckboxChange(1)} >
                            <div className='checkbox-title-div'>
                                <div className="right-content">
                                    <p className='original-price'>£{selectedCard.price} <span>Every month</span>  </p>
                                    {/* /month */}
                                </div>
                                <p className='left-title'>
                                   
                                </p>
                            </div>
                            {/* <p className='checkbox-title-hair-product'>varius Morbi lobortis, ex dui. lacus lorem. eget est. at orci eu </p> */}
                            <CustomCheckbox
                                className="hair-product-checkbox"
                                id={"check1"}
                                name={"check1"}
                                checked={interval === 1} // Check if the interval is 1
                                isCircle={true}
                            />
                        </div>
                        <div className="hair-product-payment-checkbox-div cursor-pointer " id="check2" onClick={() => handleCheckboxChange(3)} >
                            <div className='checkbox-title-div'>
                                <div className="right-content">
                                    {/* <p className='remove-price'>£{selectedCard.price}</p> */}
                                    <p className='original-price'>£{selectedCard.threemonthPrice}  <span>Every 3 months</span> 
                                    <div className='savings'>
                                    
                                        Save <div className='savingsPer'>£{threemonthsaving().toFixed(2)}</div>
                                    </div>
                                    {/* <span>-£{threemonthsaving().toFixed(2)}</span>  */}
                                    </p>
                                    {/* /3 month */}
                                </div>
                                {/* <p className='left-title'>
                                     
                                </p> */}
                            </div>
                            {/* <p className='checkbox-title-hair-product'>varius Morbi lobortis, ex dui. lacus lorem. eget est. at orci eu </p> */}
                            <CustomCheckbox
                                className="hair-product-checkbox"
                                id={"check2"}
                                name={"check2"}
                                checked={interval === 3} // Check if the interval is 3
                                isCircle={true}
                            />
                        </div>
                        {intervalError && <span className="hair-product-error-message">{intervalError}</span>}
                    </div>

                    <div className="hair-product-payment-summary">
                        <p className='payment-summary-title'>Payment summary</p>
                        <div className='hair-product-payment-summary-sub-details'>
                            <div className="sub-details-content">
                                <p>Prescriber evaluation</p>
                                <p>Included</p>
                            </div>
                            <div className="sub-details-content">
                                <p>Medication adjustments</p>
                                <p>Included</p>
                            </div>
                            <div className="sub-details-content">
                                <p>Ongoing check-ins</p>
                                <p>Included</p>
                            </div><div className="sub-details-content">
                                <p>Shipping</p>
                                <p>£{Shipping}</p>
                            </div>
                            <div className="sub-details-content">
                                <p>Due amount</p>
                                {/* <p>£{(Number(selectedCard.price * interval) + Shipping).toFixed(2)}</p> */}
                                {interval === 1 ? (
                                        <p >£{(Number(selectedCard.price) + Shipping).toFixed(2)}</p>
                                    ) : interval === 3 ? (
                                        <>
                                            <p className='original-price'>£{(Number(selectedCard.threemonthPrice) + Shipping).toFixed(2)}</p>
                                        </>
                                    ) : (
                                        <p>£0</p>
                                    )}
                            </div>
                        </div>


                        <div className='save-payments-content'>
                            <p>Multi-month saving</p>
                            {/* <p>-£88.0</p> */}
                            <p>-£{multiMonthSaving.toFixed(2)}</p>
                        </div>


                        <div className='due-now'>


                            <div className='due-now-checkbox-title-div '>
                                <p className='left-title'>
                                    Due-now
                                </p>
                                <div className="right-content">
                                    {interval === 1 ? (
                                        <></>
                                        // <p className='original-price'>£{(Number(selectedCard.price) + Shipping).toFixed(2)}</p>
                                    ) : interval === 3 ? (
                                        <>
                                            <p className='remove-price'>£{((Number(selectedCard.price) * 3) + Shipping).toFixed(2)}</p>
                                            {/* <p className='original-price'>£{(Number(selectedCard.threemonthPrice) + Shipping).toFixed(2)}</p> */}
                                        </>
                                    ) : (
                                        <></>
                                        // <p className='original-price'>£0</p>
                                    )}
                                    <p className='original-price'>£{dueAmount}</p>
                                </div>
                            </div>
                            {interval === 3 && 
                                <div className='save-price-div'>
                                    {/* <span className='save-price-span'>You save {savingPercentage.toFixed(2)}%</span> */}
                                    <span className='save-price-span'>You save £{multiMonthSaving.toFixed(2)}</span>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                <div className='bottomStickeyBtn'>          
                    <div className="continue-btn-div">
                        <StepButton label={"Continue"} blue={true} onClick={handleContinue} isLoading={SaveHaiPrescriptionRequestMutationData?.loading} />
                    </div>

                    <div className="whatsapp-margin">
                        <HairProductWhatsAppButton />
                    </div>
                </div>

            </div>
        </div>


        </>
    )
}

export default HairProductsSix