export const GET_ONBOARDING_STATUS = "GET_ONBOARDING_STATUS";
export const GET_ONBOARDING_STATUS_SUCCESS = "GET_ONBOARDING_STATUS_SUCCESS";
export const GET_ONBOARDING_STATUS_ERROR = "GET_ONBOARDING_STATUS_ERROR";

export const UPDATE_OCCUPATIONS = "UPDATE_OCCUPATIONS";
export const UPDATE_OCCUPATIONS_SUCCESS = "UPDATE_OCCUPATIONS_SUCCESS";
export const UPDATE_OCCUPATIONS_ERROR = "UPDATE_OCCUPATIONS_ERROR";

export const CLEAR_UPDATE_OCCUPATIONS = "CLEAR_UPDATE_OCCUPATIONS";

export const CONNECT_STRIPE_EXPRESS = "CONNECT_STRIPE_EXPRESS";
export const CONNECT_STRIPE_EXPRESS_SUCCESS = "CONNECT_STRIPE_EXPRESS_SUCCESS";
export const CONNECT_STRIPE_EXPRESS_ERROR = "CONNECT_STRIPE_EXPRESS_ERROR";


export const DASHBOARD_ONBOARDING_DATA = "DASHBOARD_ONBOARDING_DATA";
export const CLEAR_ONBOARDING_DATA = "CLEAR_ONBOARDING_DATA";