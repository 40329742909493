import lottiAnimation from "../../../assets/lotti-animation/Animation - 1711023509404.json"
import Lottie from 'react-lottie';
import "./onboarding-booking-link.scss"
import { Avatar } from "@material-ui/core";
import NavHeader from '../../common/NavHeader/NavHeader'
import { useNavigate } from 'react-router'
import { businessDashboardRoute } from "../../../Routes"
import React, { useState, useRef } from "react"
import QRCode from 'qrcode.react';
import Skeleton from 'react-loading-skeleton'
import showToast from '../../common/CustomToast/CustomToast'
import StepButton from '../../common/StepButton'
import CustomModal from '../../modal/CustomModal'
import CustomAutoComplete from '../../auto-complete/CustomAutoComplete'
import { ReactComponent as EmailIcon } from '../../../components/assets/images/calendar/emailIcon.svg'
import { ReactComponent as CopyIcon } from '../../../components/assets/images/calendar/copyIcon.svg'
import { ReactComponent as SMSIcon } from '../../../components/assets/images/calendar/smsIcon.svg'



const OnboardingBookingLink = () => {
    const navigate = useNavigate()
    const listRef = useRef();
    const [searchString, setSearchString] = useState(null);

    const [value, setValue] = useState([]);
    const [qrCodeLinkValue, setQrCodeLinkValue] = useState("")
    const textRef = React.useRef(null)
    const [copyText, setCopyText] = useState('')
    const [isTextInputClicked, setIsTextInputClicked] = useState(false);
    const [prefix, setPrefix] = useState("")
    const [editableText, setEditableText] = useState("")
    const [clientsModal, setClientsModal] = useState(false);
    const [type, setType] = useState("")
    const [allClients, setAllclients] = useState(false);
    const [allClientsModal, setAllClientsModal] = useState(false);


    const clinicList = []

    const handleKeyDown = (e) => {
        // Check if the Backspace key is pressed
        if (e.key === 'Backspace') {
            setIsTextInputClicked(true);
        } else {
            setIsTextInputClicked(true);
        }
    };

    const handleSaveBookingLink = () => {
        if (editableText.trim() == "") {
            showToast("error", 'Please enter valid link', "", "");
            setIsTextInputClicked(true);
        } else {

            // initiateSaveBookingLink(
            //     {
            //         bookingLink: editableText.trim()
            //     },
            //     (success) => {
            //         console.log("success", success)
            //         if (success.success) {
            //             showToast("success", success.message, "", "")
            //             setIsTextInputClicked(false);
            //             setQrCodeLinkValue(copyText)
            //         }
            //         else {
            //             showToast("error", success.message, "", "")
            //             setIsTextInputClicked(true);
            //         }

            //     },
            //     (error) => {
            //         showToast("error", error.message, "", "")
            //         setIsTextInputClicked(false);
            //     }
            // )
        }
    }

    const handleSendEmail = (shareType) => {
        // dispatch({
        //     type: CLEAR_CLINIC_LIST
        // })
        if (editableText.trim() == "") {
            showToast("error", 'Please enter valid link', "", "")
        } else {
            setType(shareType);
            setClientsModal(true);
        }
        // window.open(`mailto:email@example.com?subject=Subject&body=${copyText}`)
    }

    const handleClientsForm = (e) => {
        e.preventDefault();
        let temp = [];
        if (type == "email") {
            if (value.length > 0 || allClients) {
                temp = value.map((e) => e.clientEmail);
            } else {
                showToast("error", "Please select atleast one client", "", "")
                return
            }

            if (allClients) {
                setAllClientsModal(true)
                return
            }

            // initSendBookingLinkThroughEmailData({
            //     emails: temp,
            //     sendToAllClients: allClients
            // },
            //     (success) => {
            //         // toast.success(success.message)
            //         showToast("success", success.message, "", "")
            //         setValue([])
            //         setClientsModal(false)
            //     },
            //     (error) => {
            //         showToast("error", error.message, "", "")
            //     }
            // )
        } else {
            if (value.length > 0 || allClients) {
                temp = value.map((e) => e.readableContact);
            } else {
                showToast("error", "Please select atleast one client", "", "")
                return
            }

            if (allClients) {
                setAllClientsModal(true)
                return
            }
            // initSendBookingLinkThroughSMSData({
            //     contacts: temp,
            //     sendToAllClients: allClients
            // },
            //     (success) => {
            //         showToast("success", success.message, "", "")
            //         setValue([])
            //         setClientsModal(false)
            //     },
            //     (error) => {
            //         showToast("error", error.message, "", "")
            //     }
            // )
        }

    };

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: lottiAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    // const name = ele?.userName?.split(" ").length === 1 ? `${ele?.userName?.split(" ")[0][0]
    //     }` : `${ele?.userName?.split(" ")[0][0]
    // }${ele?.userName?.split(" ")[1][0]}`;

    const handleOptionChange = (event, newValue) => {
        // newValue = newValue.filter(e => e?.title !== "No Client Found")
        setValue(newValue);
    };

    const handleCopyBookingLink = () => {
        // editableText.trim() == "" ? toast.error('please enter endpoint!') : 
        // toast.success(copyText + ' copied successfully!')
        if (editableText?.trim() == "") {
            showToast("error", 'Please enter valid link', "", "")

        } else {
            navigator.clipboard.writeText(copyText)
            showToast("success", 'Booking link copied successfully', "", "")
        }
    }
    const handleScrollCallback = () => {
        // if (listRef.current) {
        //     const { scrollTop, scrollHeight, clientHeight } = listRef.current;

        //     if (
        //         scrollTop + clientHeight === scrollHeight &&
        //         totalClinicCount > loadedClinicCount
        //     ) {
        //         // initClientList();
        //     }
        // }
    };
    const handleAllclientsConfirmation = () => {
        setAllClientsModal(true)
        setClientsModal(false)
        if (type == "email") {

            // initSendBookingLinkThroughEmailData({
            //     emails: [],
            //     sendToAllClients: true
            // },
            //     (success) => {
            //         showToast("success", success.message, "", "")
            //         setAllclients(false)
            //         setAllClientsModal(false)
            //         setClientsModal(false)
            //     },
            //     (error) => {
            //         showToast("error", error.message, "", "")
            //     }
            // )
        } else {
            // initSendBookingLinkThroughSMSData({
            //     contacts: [],
            //     sendToAllClients: true
            // },
            //     (success) => {
            //         showToast("success", success.message, "", "")
            //         setAllclients(false)
            //         setAllClientsModal(false)
            //         setClientsModal(false)
            //     },
            //     (error) => {
            //         showToast("error", error.message, "", "")
            //     }
            // )
        }
    }

    return (
        <>
            <div className="div-main">
                <div className="main-div-container">
                    <div className="sub-div">
                        <div className="reviewProfile">
                            <Avatar
                                className="userreviewprofile"
                            >
                                P
                            </Avatar>
                        </div>
                        <div className="div-header">Preparing your link</div>
                        <div className="div-text">We’re setting up your booking link. Hang tight!</div>
                    </div>

                </div>
                <div className="congration-wrapper">
                    <Lottie options={defaultOptions}
                        height={"50vw"}
                        width={"50vw"}
                    />
                </div>
            </div>
            <>
                <NavHeader isClose={true} applyPadding={false} onClickCallback={() => {
                    navigate(businessDashboardRoute())
                }} />
                <div className="share-booking-section">
                    <div className="container">
                        <div className='d-flex justify-content-center'>

                            {
                                qrCodeLinkValue != "" ?
                                    <QRCode value={qrCodeLinkValue} renderAs="canvas" size={150}
                                        style={{ width: "150px", height: "170px", objectFit: "contain" }}
                                    /> :
                                    <Skeleton width={180} height={180} />
                            }
                        </div>
                        <div className='share-booking-link-main-div'>
                            <h4>
                                Share booking link
                            </h4>

                            <p>
                                Provide your clients with a link to view available slots and schedule an appointment directly.
                            </p>
                            <div className="share-booking-link-input-div">
                                <div className="row mx-0">
                                    <div className="col-10 ps-0">
                                        <input
                                            name="titre"
                                            id="exampleEmail"
                                            type="text"
                                            ref={textRef}
                                            value={copyText}
                                            onKeyDown={handleKeyDown}
                                            onChange={(e) => {
                                                const input = e.target.value
                                                let temp
                                                if (input.substr(prefix.length)?.charAt(0) == "/") {
                                                    temp = input.substr(prefix.length).slice(1)
                                                } else {
                                                    temp = input.substr(prefix.length)
                                                }
                                                setEditableText(temp)
                                                e.target.value = prefix + temp
                                                if (e?.target?.value)
                                                    setCopyText(e?.target?.value)
                                            }}

                                        />
                                    </div>
                                    <div className="col-2">
                                        <button className={isTextInputClicked ? "save-btn" : "copy-btn"} onClick={() => isTextInputClicked ? handleSaveBookingLink() : handleCopyBookingLink()} >{isTextInputClicked ? "Save" : "Copy"}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="icon-images-div">
                                <div className='icon-image' onClick={() => handleSendEmail("email")}>
                                    <EmailIcon />
                                    <p>Email</p>
                                </div>
                                <div className='icon-image' onClick={() => handleCopyBookingLink()} >
                                    <CopyIcon />
                                    <p>Copy</p>
                                </div>
                                <div className='icon-image' onClick={() => handleSendEmail("mobile")}>
                                    <SMSIcon />
                                    <p>SMS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* ========================SHARE BOOKING LINK TO CLIENT MODAL======================== */}
                <CustomModal
                    modalOpen={clientsModal}
                    // modalOpen={true}
                    setModalOpen={setClientsModal}
                    type={"common-width"}
                    modaltitle={"Select Clients"}

                    modalBody={
                        <>
                            {
                                <form
                                    className="d-flex flex-column mt-2"
                                    onSubmit={handleClientsForm}
                                >
                                    <div>
                                        <input
                                            type="checkbox"
                                            name="all"
                                            id="all"
                                            // checked={allClients}
                                            onChange={(e) => setAllclients(e.target.checked)}
                                            className='cursor-pointer'
                                        />
                                        <label htmlFor="all" className="ms-2 cursor-pointer">
                                            Select All Clients
                                        </label>
                                    </div>
                                    {!allClients && (
                                        <CustomAutoComplete
                                            multiple={true}
                                            groupBy={false}
                                            listRef={listRef}
                                            setSearchString={setSearchString}
                                            handleInputClick={() => {
                                                // dispatch({
                                                //     type: CLEAR_CLINIC_LIST
                                                // })
                                                // initClientList(true)
                                            }}
                                            // loading={getClientList_v3Loading}
                                            optionValue={clinicList}
                                            value={value}
                                            setValue={setValue}
                                            handleOptionChange={handleOptionChange}
                                            handleScrollCallback={handleScrollCallback}
                                            disableCloseOnSelect={true}
                                        />
                                    )}
                                    <StepButton type={'submit'} label={"Share Booking Link"} blue={true} />
                                </form>
                            }
                        </>
                    }
                />

                {/* ALL CLIENTTS CONFIRMATION MODAL */}
                <CustomModal
                    modalOpen={allClientsModal}
                    // modalOpen={true}
                    setModalOpen={setAllClientsModal}
                    type={"common-width"}

                    modalBody={
                        <>
                            <div className="share-booking-link-all-clients-confimration">
                                <p>
                                    Are you sure you want to share booking link to all clients?
                                </p>
                                <div className="row">

                                    <div className='col-md-6'>
                                        <StepButton red={true} label={"Cancel"} onClick={() => setAllClientsModal(false)} />
                                    </div>
                                    <div className='col-md-6'>
                                        <StepButton label={"Yes"} onClick={() => {
                                            handleAllclientsConfirmation()
                                        }} blue={true} />
                                    </div>
                                </div>
                            </div>

                        </>
                    }
                />

            </>
        </>
    )
}


export default OnboardingBookingLink;