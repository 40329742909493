import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./search-web-modal.scss";
import closeIcon from "../../../images/close_icon.svg";
import searchIcon from "../../../images/search_icon.svg";
import findIcon from "../../../images/find_treatment.svg";
import notFoundIcon from "../../../images/not_found.svg";
import placeHolder from "../../../images/placeholder.svg";
import arrow from "../../../images/right_arrow.svg";
import placeHolder1 from '../../../images/beach.jpg'
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { GetTreatmentsDetails } from "../../redux/actions/treatmentList/getTreatmentsDetails";
import StepButton from "../../common/StepButton";
import TreatmentDetailsSkeleton from "../../clininc-cart-skeleton/TreatmentDetailsSkeleton";
import TreatmentDescriptionRenderer from "./TreatmentDesc";

const SearchWebModal = ({ isOpen, setIsOpen, setStep, step, treatmentDetails, isAdded, selectedTreatment, treatmentDataToBeOPenInModal, setSelectedTreatment }) => {
  const [empty, setEmpty] = useState(false);
  const [found, setFound] = useState(true);
  const [search, setSearch] = useState("");
  const [currentSlide, setCurrentSlide] = useState(1)
  const [showDetails, setShowDetails] = useState(false);

  const treatmentDetailsData = useSelector((state) => state.treatmentsDetailReducer?.treatementDetail?.treatment_details
  )
  const { initGetTreatmentDetailsForUser, userTreatmentDetailsQueryData } = GetTreatmentsDetails()
  const sliderData = [placeHolder1]
  const dummyData = [
    {
      title: "Product item here 1",
    },
    {
      title: "Product item here 2",
    },
    {
      title: "Product item here 3",
    },
    {
      title: "Product item here 4",
    },
    {
      title: "Product item here 5",
    },
  ];


  const handleClickShowDetails = () => {
    setShowDetails(true)
  }

  useEffect(() => {
    if (!isOpen) {
      setShowDetails(false)
    }
  }, [!isOpen])

  useEffect(() => {
    if (isOpen) {
      initGetTreatmentDetailsForUser({
        // slug:treatmentDetails?.slug
        slug: "",
        id: treatmentDetails?.treatmentId
      })
    }
  }, [isOpen])
  const checkScreenWidth = () => {
    if (window.innerWidth >= 769) {
      return "modal-popup-desktop";
    }
    return ""; // Empty string for mobile view
  };

  const popupClassName = checkScreenWidth();
  const [filterList, setFilterList] = useState(dummyData);
  const handleSearch = (e) => {
    let result = [];
    setSearch(e.target.value);
    result = dummyData.filter((data) => {
      setFound(data.title.toLowerCase().search(e.target.value) != -1)
      return data.title.toLowerCase().search(e.target.value) != -1;
    });
    setFilterList(result);
  };

  useEffect(() => {
    if (search.trim().length === 0) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  }, [search]);
  useEffect(() => {
  }, [treatmentDetails?.slug])
  const handleClear = () => {
    setSearch("");
  };
  var settings1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    initialSlide: 0,
    arrows: true,
    beforeChange: (prev, next) => {
      setCurrentSlide(next + 1);
    },
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          className: "center",
          // dots: true
        },
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          className: "center",
        },
      },
      {
        breakpoint: 576,
        settings: {
          beforeChange: (prev, next) => {
            setCurrentSlide(next + 1);
          },
          initialSlide: currentSlide,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Modal
      isOpen={isOpen}
      className="your-modal-class treatment-web-modal" // Add your modal class for styling
      style={{
        overlay: {
          background: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
        },
        content: {
          border: 0,
          padding: 0,
          inset: 0, // Adjust the position as needed (e.g., "absolute", "fixed")
          background: "white", // Background color for modal content
          zIndex: 1000, // Ensure a higher z-index than overlay
        },
      }}
    >
      <>
        {
          <div className="custom-web-modal">
            <div className="col-md-12">
              <div className="close-img">
                <img src={closeIcon} alt="close" onClick={() => { setIsOpen(false); setCurrentSlide(1); document.getElementById("search").blur() }} />
              </div>
            </div>
            <div className="treatment-details-web-modal " style={{ position: 'relative' }}>
              {
                userTreatmentDetailsQueryData?.loading ?
                  <>
                    <TreatmentDetailsSkeleton />
                  </> :

                  <>
                    <div className="row flex-768-column">

                      <div className="col-md-6">

                        <div className="treatment-photo-web-section">
                          <div className="slider-web-container">
                            <Slider {...settings1}>
                              {
                                treatmentDetailsData?.treatment_images?.length === 0 ?
                                  sliderData.map((item) => (
                                    <div className="sliderImgWrapper">
                                      <img src={item} alt="item" />
                                    </div>
                                  ))

                                  :
                                  treatmentDetailsData?.treatment_images?.map((item) => (
                                    <div className="sliderImgWrapper">
                                      <img src={item?.image_url} alt="item" />
                                    </div>
                                  ))
                              }
                            </Slider>

                            <div className="counter-web-div">
                              <p>{`${currentSlide} / ${treatmentDetailsData?.treatment_images?.length !== 0 ? treatmentDetailsData?.treatment_images?.length : sliderData.length}`}</p>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="col-md-6">

                        <div className="treatment-web-content-section">
                          <h1>{treatmentDetailsData?.treatment_name}</h1>
                          <h2>{`£${treatmentDetailsData?.treatment_price} • ${treatmentDetailsData?.treatment_duration} mins`}</h2>
                          <TreatmentDescriptionRenderer description={treatmentDetailsData?.treatment_description} style={{ overlay: 'scroll' }} />
                          {/* <p>{treatmentDetailsData?.treatment_description}</p> */}
                        </div>
                        {/* <div style={{ position: 'absolute', bottom: 10, width: '100%', display: 'flex', alignItems: 'right', justifyContent: 'center', marginTop: 80 }}> */}
                        <div className="treatment-web-content-section-btn-div">
                          <StepButton blue={true} label={
                            `
                          ${!isAdded ? "Add to order" : "Added"
                            }
                           • £${treatmentDetailsData?.treatment_price}`}
                            onClick={() => {
                              !isAdded && (setSelectedTreatment((prevSelectedTreatments) => {
                                return [...prevSelectedTreatments, treatmentDataToBeOPenInModal];
                              }));
                              setIsOpen(false);
                              setCurrentSlide(1)
                            }} />
                        </div>
                      </div>
                    </div>
                  </>
              }
            </div>
          </div>
        }
      </>
    </Modal>
  );
};

export default SearchWebModal;
