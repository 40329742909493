import {useLazyQuery} from "@apollo/client"
import gql from "graphql-tag";

const INSURANCE_VERIFIED_STATUS=gql`
query GetUserInsuranceVerifiedStatus {
    getUserInsuranceVerifiedStatus {
      success
      message
      data {
        is_verified
        status
        reason
        is_banner_show
      }
     }
  }
`;


export const GetUserInsuranceVerifiedAction =()=>{

     const [getUserInsuranceVerifiedResonseFun,
    getUserInsuranceVerifiedResponseData]
    =useLazyQuery(INSURANCE_VERIFIED_STATUS,{
        fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted:({getUserInsuranceVerifiedStatus:responseData})=>{
        if(responseData.success){
            console.log("res",responseData)
        }else{
            console.log("error")
        }
    }
    })

    const getInsuranceVerifiedQueryData= getUserInsuranceVerifiedResponseData;

    const initGetInsuranceverifiedData=(data)=>{
        getUserInsuranceVerifiedResonseFun({
            variables: data,
        });
    };
 
     return { getInsuranceVerifiedQueryData,initGetInsuranceverifiedData}
}

