import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_VIEW_CONSENT_FORM_LINK } from "../../constants/downloadConsent/downloadViewConsentFormConst";


const DOWNLOAD_VIEW_CONSENT_PDF_QUERY = gql`
query GetConsentFormPdfForDashboard($consentFormRecordId: Int!) {
    getConsentFormPdfForDashboard(consentFormRecordId: $consentFormRecordId) {
      success
      message
      data {
        consentFormRecordId
        consentFormRecordPdfUrl
      }
    }
  }
`;

var page;
export const GetViewConsentFormPdfUrlQuery = () => {
  const dispatch = useDispatch();

  const [getViewConsentFormPdfUrl, viewConsentFormPdfUrlData] = useLazyQuery(
    DOWNLOAD_VIEW_CONSENT_PDF_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getConsentFormPdfForDashboard: responseData }) => {
        if (responseData.success) {
          dispatch({
            type: GET_VIEW_CONSENT_FORM_LINK,
            payload: responseData.data, page
          });
        }
      },
    }
  );
  const viewConsentFormPdfUrlQueryData = viewConsentFormPdfUrlData;
  const initViewConsentFormPdfUrl = (data, successCallback, errorCallback) => {
    page = data?.page ? data?.page : 1
    getViewConsentFormPdfUrl({
      variables: data,
    }).then((res) => {
      successCallback(res)
    }).catch((err) => {
      errorCallback(err)
    });
  };
  return { initViewConsentFormPdfUrl, viewConsentFormPdfUrlQueryData };
};
