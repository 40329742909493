import { gql, useMutation } from "@apollo/client";


const UPDATE_WEBSITE_BUILDER_PACKAGE_PAYMENT = gql`
mutation UpdateWebsitePackagePayment($packageId: Int, $packageSlug: String, $orderId: Int, $intentId: String, $token: String, $stripeSavedCardId: String, $userToken: String, $planUpgrade: Boolean) {
    updateWebsitePackagePayment(packageId: $packageId, packageSlug: $packageSlug, orderId: $orderId, intentId: $intentId, token: $token, stripeSavedCardId: $stripeSavedCardId, userToken: $userToken, planUpgrade: $planUpgrade) {
      success
      message
    }
  }
`

export const UpdateWebsitePackagePaymentAction = () => {
    const [UpdateWebsitePackagePaymentFunctionResponse, UpdateWebsitePackagePaymentResponseData] = useMutation(UPDATE_WEBSITE_BUILDER_PACKAGE_PAYMENT)

    const updateWebsitePackagePaymentQueryData = UpdateWebsitePackagePaymentResponseData
    const initUpdateWebsitePackageData = (data, successCallback, errorCallback) => {
        UpdateWebsitePackagePaymentFunctionResponse({ variables: data })
            .then((responseData) => {
                if (responseData?.data?.updateWebsitePackagePayment?.success) {
                    // dispatch({
                    //     type: SET_BOOKING_DETAILS,
                    //     payload: responseData?.data
                    // })
                    successCallback(responseData)
                    // toast.success(responseData.message)
                }
                else {
                    errorCallback(responseData)
                    // toast.error(responseData.message)
                }
            })
            .catch((err) => {
                errorCallback(err)
                // toast.error("something went wrong!!!")
            })
    }

    return { initUpdateWebsitePackageData, updateWebsitePackagePaymentQueryData }
}