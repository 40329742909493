export const SET_SELECTED_CLIENT_ID = "SET_SELECTED_CLIENT_ID";
export const GET_WALK_IN_CLIENT_LIST = "GET_WALK_IN_CLIENT_LIST";
export const SET_WALK_IN_CLIENT_NAME = "SET_WALK_IN_CLIENT_NAME";
export const GET_CONSENT_FORM_DETAILS = "GET_CONSENT_FORM_DETAILS";
export const GET_CLINIC_SERVICES_LIST = "GET_CLINIC_SERVICES_LIST";
export const SET_WALK_IN_TREATMENT_ID = "SET_WALK_IN_TREATMENT_ID";
export const GET_CLINIC_LOCATION_CONST = "GET_CLINIC_LOCATION_CONST";
export const SET_WALK_IN_SERVICES_DATA = "SET_WALK_IN_SERVICES_DATA";
export const SET_WALK_IN_SERVICES_PRICE = "SET_WALK_IN_SERVICES_PRICE";
export const GET_AFTERCARE_FORM_DETAILS = "GET_AFTERCARE_FORM_DETAILS";
export const GET_CONSANT_FORM_DATA_LIST = "GET_CONSANT_FORM_DATA_LIST";
export const SET_WALK_IN_CONSENT_FORM_ID = "SET_WALK_IN_CONSENT_FORM_ID";
export const SET_WALK_IN_CLINIC_LOCATION = "SET_WALK_IN_CLINIC_LOCATION";
export const GET_AFTERCARE_FORM_DATA_LIST = "GET_AFTERCARE_FORM_DATA_LIST";
export const SET_WALK_IN_CONSENT_FORM_DATA = "SET_WALK_IN_CONSENT_FORM_DATA";
export const SET_WALK_IN_LOCATION_CLINIC_ID = "SET_WALK_IN_LOCATION_CLINIC_ID";
export const GET_USER_CONSANT_FORM_DATA_LIST = "GET_USER_CONSANT_FORM_DATA_LIST";
export const SET_WALK_IN_AFTERCARE_FORM_DATA = "SET_WALK_IN_AFTERCARE_FORM_DATA";
export const SET_WALK_IN_AFTERCARE_FORM_ID = "SET_WALK_IN_AFTERCARE_FORM_ID";
export const GET_USER_AFTERCARE_FORM_DATA_LIST = "GET_USER_AFTERCARE_FORM_DATA_LIST";
export const GET_WALK_IN_QUESTION_ANSWER_DATA = "GET_WALK_IN_QUESTION_ANSWER_DATA";

//CLEAR REDUCER DATA
export const CLEAR_USER_CONSANT_FORM_DATA_LIST="CLEAR_USER_CONSANT_FORM_DATA_LIST";
export const CLEAR_CONSENT_FORM_DETAILS = "CLEAR_CONSENT_FORM_DETAILS";
