import axios from '../../../axios/index'
import { API_URL, BASE_URL } from "../../../axios/config";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import { GET_PROFILE_DATA, LOGOUT_PROFILE } from '../../constants';
import { getProfileDataSuccess, logoutProfileSuccess } from '../../actions';
import { webBaseUrl } from '../../../../config';
import Cookies from 'js-cookie';


async function getProfileDataAPI() {
    return axios.request({
        method: "get",
        url: BASE_URL + API_URL.GET_NEW_PROFILE,
    });
}

function* getProfileDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        let response = yield call(getProfileDataAPI);
        // yield put(setLoaderComplete());
        if (response.status === 200) {
            if (response.data.success) {
                yield put(getProfileDataSuccess(response.data));
            }
            else {
                // window.location.href = "https://staging.facesconsent.com/login";
                // window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/login`;
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        // yield put(setLoaderComplete());
        if (reject) reject(error);
    }
}

export function* getProfileDataWatcher() {
    yield takeLatest(GET_PROFILE_DATA, getProfileDataAction);
}

// ===================================================================
async function logoutProfileAPI() {
    return axios.request({
        method: "post",
        url: BASE_URL + API_URL.LOGOUT,
        data: {
            // "request-from": "frontend",
        }
    });
}

function* logoutProfileAction(action) {
    let { payload, resolve, reject } = action;
    try {
        let response = yield call(logoutProfileAPI);
        // return;
        // yield put(setLoaderComplete());
        if (response.status === 200) {
            if (response.data.success) {
                // yield put(logoutProfileSuccess(response.data));
                if(response.data.data && response.data.data.redirect_url){
                    console.log(response.data.data.redirect_url,"response.data.data.redirect_url")
                    window.location.href = response.data.data.redirect_url;
                }
                else{
                    console.log(webBaseUrl,"inside else webBaseUrl")
                    window.location.href = webBaseUrl + '/login';
                    
                }
                Cookies.remove("faces_access_token");
            }
            else {
                // window.location.href = "https://staging.facesconsent.com/login";
            }
            if (resolve) resolve(response);

        } else {

            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        // yield put(setLoaderComplete());
        if (reject) reject(error);
    }
}

export function* logoutProfileWatcher() {
    yield takeLatest(LOGOUT_PROFILE, logoutProfileAction);
}
