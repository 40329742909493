import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_APPOINTMENT_HOLD_DURATION } from "../../constants/appointment/appointmentConst";


const GET_APPOITMENT_HOLD_DURATION_QUERY = gql`
query GetAppointmentHoldonDetails($appointmentId: Int) {
  getAppointmentHoldonDetails(appointmentId: $appointmentId) {
    success
    message
    data {
      holdOn
    }
  }
}
`;

var page;
export const GetAppointmentHoldDurationQuery = () => {
  const dispatch = useDispatch();
  const [getAppointmentHoldDurationData, appointmentHoldDurationData] = useLazyQuery(
    GET_APPOITMENT_HOLD_DURATION_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getAppointmentHoldonDetails: responseData }) => {
        if (responseData.success) {
          // dispatch({
          //   type: GET_APPOINTMENT_HOLD_DURATION,
          //   payload: responseData.data,
          //   page,
          // });
        } else {
        }
      },
    }
  );
  const appointmentHoldDurationQueryData = appointmentHoldDurationData;
  const initGetAppointmentHoldDuration = (data) => {
    page = data?.page ? data?.page : 1;
    getAppointmentHoldDurationData({
      variables: data,
    })
  };
  return { initGetAppointmentHoldDuration, appointmentHoldDurationQueryData };
};
