import { gql, useMutation } from "@apollo/client";

const SAVE_LOCATION_FIXED_HOURS_FOR_DAY_MUTATION = gql`
mutation SetLocationFixedHoursForDay($locationId: Int, $day: Int, $isAvailable: Boolean, $startTime: String, $endTime: String, $breaks: [setLocationFixedHoursForDayBreaks]) {
  setLocationFixedHoursForDay(locationId: $locationId, day: $day, isAvailable: $isAvailable, startTime: $startTime, endTime: $endTime, breaks: $breaks) {
    success
    message
    data {
      locationId
      day
      isAvailable
      startTime
      endTime
      noOfBreaks
    }
  }
}
`;


export const SaveLocationFixedHoursForDayAction = () => {
    const [
        SaveLocationFixedHoursForDayResponseFunction,
        saveLocationFixedHoursForDayResponseData,
    ] = useMutation(SAVE_LOCATION_FIXED_HOURS_FOR_DAY_MUTATION)

    //this will be called from components
    const saveLocationFixedHoursForDayQueryData = saveLocationFixedHoursForDayResponseData; //variable

    const initSaveLocationFixedHoursForDayData = (data, successCallback, errorCallback) => {
        SaveLocationFixedHoursForDayResponseFunction({
            variables: data,
        }).then(({ data: { setLocationFixedHoursForDay: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback(responseData)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
        })
    }

    return { saveLocationFixedHoursForDayQueryData, initSaveLocationFixedHoursForDayData };
};