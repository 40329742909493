import React, { useEffect, useState } from "react";
import StepButton from "../../common/StepButton";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import ImageTitleComponent from "./commonComponents/ImgTitleComp";
import fbImg from "../../../images/fb.svg";
import insta from "../../../images/insta.svg";
import whatsapp from "../../../images/whatsapp.svg";
import icon from "../../../images/website-url.svg";
import { Form, FormGroup, Label } from "reactstrap";
import SkipButtonComp from "./commonComponents/SkipBtn";
// import { FaHashtag } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import "aos/dist/aos.css";
import AOS from "aos";

import SocialMedUrlIcn from "./commonComponents/SocialMedUrlIcn";
import { SaveSocialLinks } from "../../redux/actions/createWebsite/saveSocialLinks";
import { validNumber, validPhone } from "../../../utils/Regex";
import toast from "react-hot-toast";
import ToastbarComponent from "../../common/ToasterComponent";
import { GetWebsiteDetailsAction } from "../../redux/actions/createWebsite/getWebsiteDetails";

const SocialMediaPage = (props) => {
  const { style, isMobile, prevWebOnClick, stepSevenData, count, setCount } =
    props;
  const { initGetWebsiteDataResponseFunction } = GetWebsiteDetailsAction();

  const [tempSaveData, setTempSaveData] = useState({
    pwcFacebook: "",
    pwcInstagram: "",
    pwcWhatsapp: "",
  });
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-in-out",
    });
    return () => {
      AOS.refresh();
    };
  }, []);

  const inputFieldsData = {
    businessLabel: "Website",
    websiteLabel: "Faces Website URL",
    businessNamePlaceholder: "Ex: Faces Consent",
    websiteURLPlaceholder: "yourwebsitename.faces.com",
  };
  const [socialData, setSocialData] = useState({
    pwcFacebook: "",
    pwcInstagram: "",
    pwcWhatsapp: "",
  });

  const {
    initiateSaveSocialLinks,
    saveSocialLinksMutationData: { loading: saveLinksLoading },
  } = SaveSocialLinks();

  const handleSkipClick = () => {
    props.trackActivity({ activityStep: 11 });
    props.setWebsiteData({
      ...props.websiteData,
      socialLinks: tempSaveData,
    });
    props.setStep(props.step + 1);
  };

  useEffect(() => {
    if (Object.keys(props?.websiteData?.socialLinks).length) {
      setSocialData(props?.websiteData?.socialLinks);
    }
  }, [Object.keys(props?.websiteData?.socialLinks).length]);
  console.log("socialData", socialData, props?.websiteData?.socialLinks)

  useEffect(() => {
    if (
      props.websiteData.socialLinks?.pwcFacebook &&
      props.websiteData.socialLinks?.pwcInstagram &&
      props.websiteData.socialLinks?.pwcWhatsapp &&
      count.step7 === 0
    ) {
      setTempSaveData({
        pwcFacebook: props.websiteData.socialLinks?.pwcFacebook,
        pwcInstagram: props.websiteData.socialLinks?.pwcInstagram,
        pwcWhatsapp: props.websiteData.socialLinks?.pwcWhatsapp,
      });
    }
  }, [
    props.websiteData.socialLinks?.pwcFacebook,
    props.websiteData.socialLinks?.pwcInstagram,
    props.websiteData.socialLinks?.pwcWhatsapp,
  ]);

  return (
    <>
      <div className="inputWrapper">
        <div className="formWrapper">
          <div className="formInnerHeight" style={style} id="example-anchor">
            <ImageTitleComponent {...stepSevenData} />
            <Form className="social-media-icon">
              {/* <FormGroup className="inpt_field">
                <Label className="lable">Website</Label>
                <div className="w-100 d-flex">
                  <input
                    className="input"
                    type="text"
                    name="businessName"
                    id="businessName"
                    placeholder="www.yoursite.com"
                    value={socialData.website}
                    onChange={(e)=>setSocialData({...socialData,website:e.target.value})}
                  />
                  <img
                    src={icon}
                    width={30}
                    height={30}
                    className="icon"
                  />
                </div>
              </FormGroup> */}
              <FormGroup
                className="inpt_field"
                data-aos="fade-up"
                data-aos-duration={700}
                data-aos-anchor="#example-anchor"
              >
                <Label className="lable">Facebook</Label>
                <div className="iconInputField">
                  <SocialMedUrlIcn />
                  <input
                    className="input"
                    type="text"
                    name="businessName"
                    id="businessName"
                    placeholder="profilelink"
                    value={socialData.pwcFacebook}
                    onChange={(e) =>
                      setSocialData({
                        ...socialData,
                        pwcFacebook: e.target.value,
                      })
                    }
                  />
                  <div className="rightIcon">
                    <img src={fbImg} className="icon" />
                  </div>
                </div>
              </FormGroup>
              <FormGroup
                className="inpt_field"
                data-aos="fade-up"
                data-aos-duration={1000}
                data-aos-anchor="#example-anchor"
              >
                <Label className="lable">Instagram</Label>
                <div className="iconInputField">
                  <SocialMedUrlIcn />
                  <input
                    className="input"
                    type="text"
                    name="businessName"
                    id="businessName"
                    placeholder="profilelink"
                    value={socialData.pwcInstagram}
                    onChange={(e) =>
                      setSocialData({
                        ...socialData,
                        pwcInstagram: e.target.value,
                      })
                    }
                  />
                  <div className="rightIcon">
                    <img src={insta} className="icon" />
                  </div>
                </div>
              </FormGroup>
              <FormGroup
                className="inpt_field"
                data-aos="fade-up"
                data-aos-duration={1300}
                data-aos-anchor="#example-anchor"
              >
                <Label className="lable">Whatsapp</Label>
                <div className="iconInputField">
                  <div className="icon-left">
                    <FaPlus size={14} color="black" />
                  </div>
                  <input
                    className="input"
                    type="number"
                    name="businessName"
                    id="businessName"
                    placeholder="Phone Number"
                    value={socialData.pwcWhatsapp}
                    onChange={(e) =>
                      setSocialData({
                        ...socialData,
                        pwcWhatsapp: e.target.value,
                      })
                    }
                  />
                  <div className="rightIcon">
                    <img src={whatsapp} className="icon" />
                  </div>
                </div>
              </FormGroup>
            </Form>
          </div>
          <div className="FormButtonWrapper">
            <StepButton
              disabled={
                socialData.pwcFacebook.length === 0 ||
                socialData.pwcInstagram.length === 0 ||
                socialData.pwcWhatsapp.length === 0
              }
              isLoading={saveLinksLoading}
              blue={true}
              label={props?.editStep ? "Update Changes" : "Next"}
              isLeftIcon={props?.editStep ? true : false}
              icon={
                !props.editStep ? <FiChevronRight className="arrow_next" /> : ""
              }
              leftIcon={
                props?.editStep ? <FiChevronLeft className="arrow_next" /> : ""
              }
              onClick={() => {
                if (
                  socialData.pwcWhatsapp.length > 7 &&
                  socialData.pwcWhatsapp.length < 15
                ) {
                  initiateSaveSocialLinks(
                    socialData,
                    (responseData) => {
                      props.trackActivity({ activityStep: 11 });
                      if (props?.editStep) {
                        props?.setStep(0);
                      } else {
                        props.setStep(props.step + 1);
                        props.setWebsiteData({
                          ...props.websiteData,
                          socialLinks: socialData,
                        });
                      }
                      initGetWebsiteDataResponseFunction();
                    },
                    (error) => {
                      toast(error);
                    }
                  );
                } else {
                  toast("Please enter valid whatsapp number(8-15 characters)", {
                    icon: "",
                  });
                }
              }}
            />
            {!props?.editStep && (
              <SkipButtonComp
                onClick={handleSkipClick}
                prevWebOnClick={prevWebOnClick}
                isMobile={isMobile}
              />
            )}
          </div>
        </div>
      </div>
      <ToastbarComponent toast={toast} />
    </>
  );
};

export default SocialMediaPage;
