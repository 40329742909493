import React, { useEffect, useRef, useState } from "react";
import FacesLogoIcon from "../../../images/App Icon.svg";
import FacesNameIcon from "../../../images/faces logo.svg";
import FaceBook from "../../../images/fb.svg";
import Instagram from "../../../images/inst.svg";
import Youtube from "../../../images/yt.svg";
import Twitter from "../../../images/twt.svg";
import Whatssapp from "../../../images/watap.svg";
import CopyImage from "../../../images/Icon.svg";
import Gmail from "../../../images/mail.svg";
import SMS from "../../../images/sms.svg";
import "./steps.scss";
import { Form, FormGroup, Label } from "reactstrap";
import StepButton from "../../common/StepButton";
import { FiChevronRight } from "react-icons/fi";
import "aos/dist/aos.css";
import AOS from "aos";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import { businessDashboardRoutePattern, createWebsite } from "../../../Routes";
import AutosizeInput from "react-input-autosize";
import { validName, validPersonalWebsiteUrl } from "../../../utils/Regex";
import { CheckWebsiteDomainAction } from "../../redux/actions/createWebsite/checkWebsiteDomain";
import { useSelector } from "react-redux";
import CustomeLoading from "../../customeSpiner";
import successIcon from "../../../images/success.svg";
import failureIcon from "../../../images/Icon example.svg";
import { UpdateWebsite } from "../../redux/actions/createWebsite/updateWebsite";
import { GetWebsiteDetailsAction } from "../../redux/actions/createWebsite/getWebsiteDetails";
import { GetWebsiteTeamMembersAction } from "../../redux/actions/createWebsite/getPersonalWebTeamMembersQuery";



const CongratulationPage = (props) => {
  const { style, step, setIsEdit } = props;
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const navigate = useNavigate();

  const [doneClicked, setDoneClicked] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      props.websiteData.websiteUrl + ".facesconsent.com"
    );
    setIsLinkCopied(true);

    toast.success("Link copied to clipboard!", {
      duration: 3000,
    });

    setTimeout(() => {
      setIsLinkCopied(false);
    }, 3000);
  };

  const { initiateupdateWebsite, updateWebsiteMutationData: { loading: updateWebsiteLoading } } = UpdateWebsite();
  const { initCheckWebsiteDateResponseFunction, checkWebsiteDomainResponseData: { loading: domainCheckloading } } = CheckWebsiteDomainAction();
  const { initGetWebsiteDataResponseFunction, GetWebsiteDetailsResponseData } = GetWebsiteDetailsAction();
  const { initGetTeamMembersResponseFunction } = GetWebsiteTeamMembersAction();
  const checkWebsiteDomainResponseData = useSelector(
    (state) => state.WebsiteDetailsReducer.checkWebsiteDomainResponseData
  );

  const shareOnSocialMedia = (platform) => {
    const urlToShare = props.websiteData.websiteUrl + ".facesconsent.com";

    switch (platform) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            urlToShare
          )}`,
          "_blank"
        );
        break;
      case "instagram":
        window.open(
          `https://www.instagram.com/sharing/url/?url=${encodeURIComponent(
            urlToShare
          )}`,
          "_blank"
        );
        break;
      case "youtube":
        window.open(
          `https://www.youtube.com/share?url=${encodeURIComponent(urlToShare)}`,
          "_blank"
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            urlToShare
          )}`,
          "_blank"
        );
        break;
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(
            urlToShare
          )}`,
          "_blank"
        );
        break;
      case "gmail":
        window.open(
          `mailto:?subject=Check%20out%20my%20website&body=${encodeURIComponent(
            urlToShare
          )}`
        );
        break;
      case "sms":
        window.open(`sms:?&body=${encodeURIComponent(urlToShare)}`);
      default:
        break;
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-in-out",
    });
    return () => {
      AOS.init();
    };
  }, []);

  const displayConfetty = () => {
    const duration = 1.5 * 1000,
      animationEnd = Date.now() + duration,
      defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(function () {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);

      // since particles fall down, start a bit higher than random
      window.confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      window.confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.3, 0.6), y: Math.random() - 0.2 },
        })
      );
      window.confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
  };

    useEffect(() => {
        if (step === 11) {
            displayConfetty();
        }
        setIsEdit(true)
    }, []);

  const spaceRegex = /^\S*$/;
  const inputRef = useRef(null);


  const handleKeyDown = (e) => {
    if (e.key === "Spacebar") {
      e.preventDefault(); // Prevent deletion if the input is just the postfix
    }
  };


  const handleChange = (e) => {
    if (spaceRegex.test(e.target.value) && (validPersonalWebsiteUrl.test(e.target.value) || e.target.value === "")) {
      props.setWebsiteData({ ...props.websiteData, websiteUrl: e.target.value.toLowerCase() });
      initCheckWebsiteDateResponseFunction({ subdomainName: e.target.value.toLowerCase() });
    }
  };

  useEffect(() => {
    if (props.websiteData?.is_active && doneClicked) {
      props.setStep(12);
    }
  }, [props.websiteData.is_active, doneClicked])

  useEffect(() => {
    initGetWebsiteDataResponseFunction();
  }, [])


  return (
    <>
      <div className="CongratulationsWrapper inputWrapper">
        <div
          className="share_option"
          data-aos="fade-up"
          data-aos-duration={700}
        >
          <div className="other_option">
            <Form
              className="social-media-icon"
              data-aos="fade-up"
              data-aos-duration={1000}
            >
              <div className="formInnerHeight" style={style}>
                <div
                  className="headerWrapper"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  {/* <div className="faces_logo">
                <img src={FacesLogoIcon} alt="faces_logo" />
              </div> */}
                  <img src={FacesNameIcon} alt="faces_logo" />
                  <h1 className="stepH1">Congratulations!</h1>
                  <p className="stepDesc">Website successfuly set up!</p>
                </div>
                <p>Share</p>
                <div className="share_icons">
                  <img
                    src={FaceBook}
                    alt="faces_logo"
                    data-aos="fade-up"
                    data-aos-duration={500}
                    onClick={() => shareOnSocialMedia("facebook")}
                  />
                  <img
                    src={Instagram}
                    alt="faces_logo"
                    data-aos="fade-up"
                    data-aos-duration={600}
                    onClick={() => shareOnSocialMedia("instagram")}
                  />
                  <img
                    src={Youtube}
                    alt="faces_logo"
                    data-aos="fade-up"
                    data-aos-duration={700}
                    onClick={() => shareOnSocialMedia("youtube")}
                  />
                  <img
                    src={Twitter}
                    alt="faces_logo"
                    data-aos="fade-up"
                    data-aos-duration={800}
                    onClick={() => shareOnSocialMedia("twitter")}
                  />
                  <img
                    src={Whatssapp}
                    alt="faces_logo"
                    data-aos="fade-up"
                    data-aos-duration={900}
                    onClick={() => shareOnSocialMedia("whatsapp")}
                  />
                  <img
                    src={Gmail}
                    alt="faces_logo"
                    className="icon-bg"
                    style={{ background: "#14B8A6" }}
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    onClick={() => shareOnSocialMedia("gmail")}
                  />
                  <img
                    src={SMS}
                    alt="faces_logo"
                    className="icon-bg"
                    style={{ background: "#6366F1" }}
                    data-aos="fade-up"
                    data-aos-duration={1100}
                    onClick={() => shareOnSocialMedia("sms")}
                  />
                </div>
                <p data-aos="fade-up" data-aos-duration={700}>
                  Or copy link
                </p>
                <FormGroup className="inpt_field">
                  <div className="comInputWrapper copyRightIcon">
                    <AutosizeInput
                      type="text"
                      value={`${props.websiteData.websiteUrl}`}
                      autoComplete="off"
                      placeholder={'yourname'}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      name="websiteURL"
                      id="websiteURL"
                      maxWidth={318}
                    />
                    {props.websiteData?.websiteUrl?.length > 2 && (
                      <div className="loaderSpiner">
                        {domainCheckloading ? (
                          <CustomeLoading />
                        ) : checkWebsiteDomainResponseData === false ? (
                          <img
                            onClick={() => {
                              props.setWebsiteData({
                                ...props.websiteData,
                                websiteUrl: "",
                              });
                            }}
                            src={failureIcon}
                          />
                        ) : (
                          <img src={successIcon} />
                        )}
                      </div>
                    )}
                    <div className="rightIcon cursor-pointer" onClick={handleCopyLink}>
                      <img src={CopyImage} width={25} height={25} className="icon" />
                    </div>
                    <label>.facesconsent.com</label>
                  </div>

                  {/* <div className="iconInputField">
                    <input
                      className="input"
                      type="text"
                      name="businessName"
                      id="businessName"
                      value={props.websiteData.websiteUrl + ".facesconsent.com"}
                    />
                    <div className="rightIcon cursor-pointer" onClick={handleCopyLink}>
                      <img src={CopyImage} width={25} height={25} className="icon" />
                    </div>
                  </div> */}
                </FormGroup>
              </div>
              {/* <div> */}
              <div className="FormButtonWrapper">
                <StepButton
                  onClick={(e) => {
                    e.preventDefault();
                    initiateupdateWebsite({
                      businessName: props.websiteData.businessName,
                      websiteUrlSlug: props.websiteData.websiteUrl
                    },
                      (responseData) => {
                        if (responseData?.success) {
                          props.trackActivity({ activityStep: 15 }
                            , (res) => {
                              if (res?.success) {
                                initGetWebsiteDataResponseFunction();
                                 props.setStep(12);
                              }
                            });


                        }
                        setIsEdit(false)
                      });
                  }}
                  blue={true}
                  label={"Done"}
                  loading={updateWebsiteLoading}
                  icon={<FiChevronRight className="arrow_next" />}
                  disabled={props.websiteData?.websiteUrl?.length < 0 || !(props.websiteData?.websiteUrl?.length > 0 || checkWebsiteDomainResponseData)}
                />
              </div>
              {/* </div> */}
            </Form>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default CongratulationPage;
