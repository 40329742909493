import BusinessHeader from "../../businessHeader/index";
import "./prescriberPreferences.scss";
import { useState, useEffect, useRef } from "react";
import calander from "../../../images/calander.svg";
import calanderBlack from "../../../images/calanderBlack.svg";
import copyIcon from "../../../images/copyicon.svg";
import diary from "../../../images/diary.svg";
import diaryBlack from "../../../images/diaryBlack.svg";
import { validName } from "../../../utils/Regex";
import OtpInput from "react-otp-input";
import noIcon from "../../../images/no.svg";
import clientIcon from "../../../images/clientincirclearrows.svg";
import dateIcon from "../../../images/date.svg";
import noMoney from "../../../images/noMoney.svg";
import CustomModal from "../../modal/CustomModal";
import { Link } from "react-router-dom";
import logo from "../../../images/logo (1) 1.png";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { ReactComponent as BackArrow } from "../../../images/back_arrow.svg";
import ThankYou from "../thankyou-registration";
import FinanceHeader from "../../finance-registration/FinanceHomeComponent/FinanceHeaderComponent/FinanceHeaderComponent";
import FinanceBanner from "../../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner";
import StepButton from "../../common/StepButton";
import { ReactComponent as Logo } from "../../../assets/finance-registration/logo1.svg";
import { toast } from "react-toastify";
import {
  GetPrescribeOnFaces,
  SavePrescribeOnFacesOption,
  GetPrescribingPreference,
  SavePrescribingPreference,
  GetVerificationCodeForPrescribers,
  SaveVerificationCodeForPrescribers,
  ConfirmVerificationCodeForPrescribers,
} from "../../redux/actions/pharmacy-registration/prescribeOnFaces";
import { StripeOnboardingMutationAction } from "../../redux/actions/dashboard/stripeOnboardingGql";
import { webBaseUrl } from "../../../config";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";

const PrescriberPreferences = ({
  isOpen,
  setIsOpen,
  onNextStep,
  afterSubmit,
  role,
  isEdit,
  finish,
  isRequestSentToAdmin,
}) => {
  console.log("Prescriber", isEdit);
  const { initGetPrescribeOnFaces, data: prescribeOnFacesData } =
    GetPrescribeOnFaces();
  const { initiateSavePrescribeOnFacesOption } = SavePrescribeOnFacesOption();
  const { initGetPrescribingPreference, data: prescribingPreferenceData } =
    GetPrescribingPreference();
  const { initiateSavePrescribingPreference } = SavePrescribingPreference();
  const { initGetVerificationCodeForPrescribers, data: verificationCodeData } =
    GetVerificationCodeForPrescribers();
  const { initiateSaveVerificationCodeForPrescribers } =
    SaveVerificationCodeForPrescribers();
  const { initiateConfirmVerificationCodeForPrescribers } =
    ConfirmVerificationCodeForPrescribers();
  const { stripeOnboardingQueryData, initStripeOnboardingData } =
    StripeOnboardingMutationAction();

  const priceErrorRef = new useRef();
const nameErrorRef = new useRef();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [planModalOpen, setPlanModalOpen] = useState(false);
  const [bankModalOpen, setBankModalOpen] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [bSortCodeinp, setBSortCodeinp] = useState(["", "", ""]);
  const [errors, setErrors] = useState({});

  const [active, setActive] = useState("Yes");
  const onHandleChange = (e) => {
    setActive(e.target.value);
  };

  const [selectedProfessions, setSelectedProfessions] = useState(1);
  const [autoConnectPractitioner, setAutoConnectPractitioner] = useState(0);
  const [medicalFormMandatory, setMedicalFormMandatory] = useState(0);
  const [profileLink, setProfileLink] = useState("");
  const [isCard, setIsCard] = useState("calander");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [confirmOtp, setConfirmOtp] = useState("");
  const [confirmOtpError, setConfirmOtpError] = useState(false);

  const [data, setData] = useState({ displayName: "", price: "" });
  const [priceError, setPriceError] = useState(false);
  const [nameError, setNameError] = useState(false);

  const [conditon, setCondition] = useState({
    termcon1: false,
    selTerm1: "",
    termcon2: false,
    selTerm2: "",
    termcon3: false,
    selTerm3: "",
    termcon4: false,
    selTerm4: "",
    allCondition: false,
  });
  const [conditionError, setConditionError] = useState(false);

  const copyProfileLink = () => {
    navigator.clipboard.writeText(profileLink);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isOpen === 1) {
      afterSubmit();
      initGetPrescribeOnFaces();
    } else if (isOpen === 2) {
      afterSubmit();
      initGetPrescribingPreference();
    } else if (isOpen === 3) {
      afterSubmit();
      initGetVerificationCodeForPrescribers();
    }
    // initGetPrescribeOnFaces();
    // initGetPrescribingPreference();
    // initGetVerificationCodeForPrescribers();
  }, [isOpen]);
  useEffect(() => {
    if (priceErrorRef.current) {
      priceErrorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }else if (nameErrorRef.current) {
      nameErrorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [priceError,nameError]);
  useEffect(() => {
    if (prescribeOnFacesData?.getPrescribeOnFacesOption) {
      setActive(
        prescribeOnFacesData?.getPrescribeOnFacesOption.data.prescribeOption ===
          1
          ? "Yes"
          : "No"
      );
    }
  }, [prescribeOnFacesData]);
  useEffect(() => {
    if (prescribingPreferenceData?.getPrescribingPreferences?.data) {
      const selectedProfessions =
        prescribingPreferenceData?.getPrescribingPreferences?.data
          ?.selectedProfessions;
      if (selectedProfessions === 0) {
        setSelectedProfessions(1);
      } else {
        setSelectedProfessions(selectedProfessions);
      }

      setMedicalFormMandatory(
        prescribingPreferenceData?.getPrescribingPreferences?.data
          ?.medicalFormMandatory
      );
      setAutoConnectPractitioner(
        prescribingPreferenceData?.getPrescribingPreferences?.data
          ?.autoConnectPractitioner
      );
      setProfileLink(
        prescribingPreferenceData?.getPrescribingPreferences?.data?.profileLink
      );
      setData({
        displayName:
          prescribingPreferenceData?.getPrescribingPreferences?.data
            ?.displayName,
        price:
          prescribingPreferenceData?.getPrescribingPreferences?.data
            ?.PrescriptionFee,
      });
    }
  }, [prescribingPreferenceData]);
  useEffect(() => {
    if (verificationCodeData?.getVerificationCodeForPrescribers?.data) {
      setOtp(
        verificationCodeData?.getVerificationCodeForPrescribers?.data
          ?.verificationCode
      );
    }
  }, [verificationCodeData]);

  const onHandlePrecriber = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const onPrescriber = () => {
    if (data?.displayName.length && data?.price?.toString().length) {
      if (!priceError || !nameError) {
        const price = parseFloat(data?.price);
        if (!isNaN(price) && data?.price !== 0) {
          setLoading(true);
          initiateSavePrescribingPreference(
            {
              selectedProfessions: selectedProfessions,
              displayName: data?.displayName,
              prescriptionFee: parseFloat(price.toFixed(2)) || 0.0,
              medicalFormMandatory: medicalFormMandatory,
              autoConnectPractitioner: autoConnectPractitioner,
            },
            (res) => {
              setLoading(false);
              toast.success(res.message);
              setIsOpen(3);
            },
            (error) => {
              setLoading(false);
              toast.error(error.message);
            }
          );
        } else {
          setPriceError(true);
        }
      }
    } else {
      if (data?.displayName.length > 0 && validName.test(data?.displayName)) {
        setNameError(false);
      } else {
        setNameError(true);
      }
      if (data?.price.toString().length > 0) {
        setPriceError(false);
      } else {
        setPriceError(true);
      }
    }

    if (priceErrorRef.current) {
      priceErrorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }else if (nameErrorRef.current) {
      nameErrorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  console.log(
    profileLink,
    autoConnectPractitioner,
    medicalFormMandatory,
    prescribingPreferenceData,
    "Prescriber"
  );
  const onSubmit = () => {
    setLoading(true);
    initiateSavePrescribeOnFacesOption(
      {
        prescribeOption: active === "Yes" ? 1 : 0,
      },
      (res) => {
        setLoading(false);
        toast.success(res.message);
        if (active === "Yes") {
          setIsOpen(2);
        } else {
          setIsOpen(3);
        }
      },
      (error) => {
        setLoading(false);
        toast.error(error.message);
      }
    );
  };
  useEffect(() => {
    if (active !== "Yes") {
      setIsOpen(1);
    }
  }, [isOpen === 2]);

  const onOtpSubmit = () => {
    if (otp) {
      setOtpError(false);
      setLoading(true);
      initiateSaveVerificationCodeForPrescribers(
        {
          verificationCode: parseInt(otp),
        },
        (res) => {
          setLoading(false);
          toast.success(res.message);
          // if (res?.data?.is_verification) {
          //   setIsOpen(4);
          // } else {
          //   afterSubmit();
          //   onNextStep();
          // }
          afterSubmit();
          onNextStep();
        },
        (error) => {
          setLoading(false);
          toast.error(error.message);
        }
      );
      // setIsOpen(4);
      // setIsOpen(7);
    } else {
      setOtpError(true);
    }
  };
  const onConfirmOtpSubmit = () => {
    if (confirmOtp) {
      setConfirmOtpError(false);
      initiateConfirmVerificationCodeForPrescribers(
        {
          verificationCode: parseInt(confirmOtp),
        },
        (res) => {
          toast.success(res.message);
          afterSubmit();
          onNextStep();
        },
        (error) => {
          toast.error(error.message);
        }
      );
      // setIsOpen(4);
      // setIsOpen(7);
    } else {
      setConfirmOtpError(true);
    }
  };

  const selectArray = [
    { value: "", label: "Fillers direct" },
    { value: "one", label: "one" },
    { value: "two", label: "two" },
    { value: "three", label: "three" },
    { value: "four", label: "four" },
  ];

  console.log("otpError", otp, otpError);
  const onConditions = () => {
    if (conditon?.allCondition) {
      setConditionError(false);
      setIsOpen(5);
    } else {
      setConditionError(true);
    }
  };

  const onHandleTerm = (e) => {
    const { name, value } = e.target;

    setCondition({ ...conditon, [name]: value });
  };

  const onHandleCheckBox = (e) => {
    const { name, value } = e.target;
    if (value === "false") {
      setCondition({ ...conditon, [name]: true });
    } else {
      setCondition({ ...conditon, [name]: false });
    }
  };

  console.log("condition", conditon);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleInputChange = (index, value) => {
    const maxLength = 2;

    if (!isNaN(value) && value.length <= maxLength) {
      const updatedBSortCode = [...bSortCodeinp];
      updatedBSortCode[index] = value;
      setBSortCodeinp(updatedBSortCode);
      setErrors((prevErrors) => {
        return { ...prevErrors, sortCode: "" };
      });

      if (value.length === maxLength && index < 2) {
        document.getElementById(`inp${index + 1}`).focus();
      }
    }
  };

  const handleBankSubmit = () => {
    const errors = {};

    if (accountNumber.trim().length < 8 || accountNumber.trim().length > 11) {
      errors.accountNumber = "Account number must be between 8 and 11 digits.";
    }

    const sortCode = bSortCodeinp.join("");
    if (sortCode.trim().length !== 6) {
      errors.sortCode = "Sort code is required and must be 6 digits.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      setErrors({});
      setBankModalOpen(false);
      setAccountNumber("");
      setBSortCodeinp(["", "", ""]);
      setIsOpen(6);
    }
  };

  useEffect(() => {
    if (isOpen === 6) {
      console.log("here");
      const timer = setTimeout(() => {
        setIsOpen(7);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <div className="Main-Prescribe-on-Faces">
      {/* {isOpen <= 7  &&  
          <FinanceHeader 
            onBackClick={() => setIsOpen(isOpen === 7 ? isOpen - 2 : isOpen - 1)}
            knowmore={isOpen === 7 && true}
          />
       } */}
      {/* {(isOpen >= 5)  && (
      <div className="d-flex align-items-center header_with_back justify-content-between">
        <BackArrow role="button" onClick={() => setIsOpen(isOpen === 7 ? isOpen - 2 : isOpen - 1)}/>
       { isOpen === 7 && ( <div className="know_more" role="button" style={{margin:'0px 150px 0px 0px'}}> Want to know more? </div>)}
      </div>
      )} */}
      <div className="main-div">
        <div className="sub-div">
          {isOpen === 1 && (
            <div className="Main-Faces-Sec">
              <FinanceBanner
                title={"Earn money through Faces"}
                desc={
                  "As a prescriber you are able to offer your prescribing services and support to other professionals in the industry. you are able to charge for your prescribing service through Faces."
                }
                isBorder={!isRequestSentToAdmin}
                currentStep={finish}
                totalSteps={4}
              />
              <div className={`btn-radio ${!isRequestSentToAdmin ? "" : ""}`}>
                <div
                  className={
                    active === "Yes"
                      ? "active-radio-button form-check"
                      : "form-check"
                  }
                  onClick={() => setActive("Yes")}
                >
                  <label class="form-check-label" for="flexRadioDefault1">
                    Yes
                  </label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name="active"
                    id="flexRadioDefault1"
                    value="Yes"
                    checked={active === "Yes" ? true : false}
                    onChange={onHandleChange}
                  />
                </div>
                <div
                  className={
                    active === "No"
                      ? "active-radio-button form-check"
                      : "form-check"
                  }
                  onClick={() => setActive("No")}
                >
                  <label class="form-check-label" for="flexRadioDefault1">
                    No
                  </label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name="active"
                    id="flexRadioDefault1"
                    checked={active === "No" ? true : false}
                    value="No"
                    onChange={onHandleChange}
                  />
                </div>
              </div>
              <div className="BtnButton">
                <StepButton
                  blue={true}
                  label={"Save & next"}
                  onClick={() => onSubmit()}
                  // disabled={isContinue}
                  isLoading={loading}
                />
              </div>
            </div>
          )}
          {isOpen === 2 && (
            <div className="Main-preferences-Sec">
              <FinanceBanner
                title={"Prescribing preferences"}
                desc={
                  "As a prescirbing professional you can prescribe for other memebers within the faces community."
                }
                isBorder={!isRequestSentToAdmin}
                currentStep={finish}
                totalSteps={4}
              />
              <div
                className={`cardBoxWrapper ${
                  selectedProfessions === 1 ? "cardBoxactive" : ""
                } ${!isRequestSentToAdmin ? "" : ""}`}
                onClick={() => {
                  setSelectedProfessions(1);
                }}
              >
                <div className="card-image">
                  <img
                    src={selectedProfessions === 2 ? calanderBlack : calander}
                    alt="calander"
                    className="text-danger"
                  />
                </div>
                <div className="card-Content">
                  <h5>All Professionals</h5>
                  <p>
                    Purchase prescription items and prescribe for other
                    professionals
                  </p>
                </div>
              </div>
              <div
                className={`cardBoxWrapper ${
                  selectedProfessions === 2 ? "cardBoxactive" : ""
                }`}
                onClick={() => {
                  setSelectedProfessions(2);
                }}
              >
                <div className="card-image">
                  <img
                    src={selectedProfessions === 2 ? diary : diaryBlack}
                    alt="diary"
                  />
                </div>
                <div className="card-Content">
                  <h5>Healthcare Professionals ONLY</h5>
                  <p>You don't want to offer your services and prescribe</p>
                </div>
              </div>

              <form className="form-data">
                <div className="first-flex-div">
                  {!prescribingPreferenceData ? (
                    <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
                  ) : (
                    <div class="finance-input-container">
                      <label for="traningAcademy" className="form-label">
                        Display name / alias
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="displayName"
                        value={data?.displayName}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue.length >= 3 &&
                            validName.test(inputValue)
                          ) {
                            setNameError(false);
                          } else {
                            setNameError(true);
                          }
                          onHandlePrecriber(e);
                        }}
                        placeholder="Display name"
                      />
                      {nameError && (
                        <div className="error" ref={nameErrorRef}>
                          {data?.displayName
                            ? "Please enter valid display name."
                            : "Display name is required."}
                        </div>
                      )}
                    </div>
                  )}
                  {!prescribingPreferenceData ? (
                    <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
                  ) : (
                    <div class="finance-input-container">
                      <label for="traningAcademy" className="form-label">
                        Prescription fee per script
                      </label>
                      <input
                        className="price"
                        type="number"
                        class="form-control"
                        name="price"
                        value={data?.price}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (!isNaN(inputValue) && inputValue[0] !== "0") {
                            setPriceError(false);
                            // If the input is a valid number, update the state with the new value
                            onHandlePrecriber(e);
                          } else {
                            setPriceError(true);
                          }
                        }}
                        placeholder="£ 00.0"
                      />
                      {priceError && (
                        <div className="error" ref={priceErrorRef}>
                          {data?.price ? "Invalid Price" : "Field is required"}
                        </div>
                      )}
                    </div>
                  )}
                  {isEdit && (
                    <>
                      {!prescribingPreferenceData ? (
                        <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
                      ) : (
                        <div className="finance-input-container CopyURL-Input">
                          <label
                            htmlFor="Public Profile URL"
                            className="form-label"
                          >
                            Public Profile URL
                          </label>
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={profileLink}
                              disabled={true}
                            />
                            <span
                              class="input-group-text"
                              id="basic-addon1"
                              onClick={copyProfileLink}
                              role="button"
                            >
                              <img
                                src={copyIcon}
                                alt="copy"
                                style={{ color: "blue" }}
                              />
                            </span>
                          </div>
                        </div>
                      )}
                      {!prescribingPreferenceData ? (
                        <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
                      ) : (
                        <div className="finance-input-container toggle-container">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault1"
                              checked={autoConnectPractitioner === 1}
                              onChange={() => {
                                if (autoConnectPractitioner === 0) {
                                  setAutoConnectPractitioner(1);
                                } else {
                                  setAutoConnectPractitioner(0);
                                }
                              }}
                            />
                            <label
                              class="form-check-label"
                              htmlFor="flexSwitchCheckDefault1"
                            >
                              Auto connect me to a Practitioner Request
                            </label>
                          </div>
                        </div>
                      )}
                      {!prescribingPreferenceData ? (
                        <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
                      ) : (
                        <div className="finance-input-container toggle-container">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault2"
                              checked={medicalFormMandatory === 1}
                              onChange={() => {
                                if (medicalFormMandatory === 0) {
                                  setMedicalFormMandatory(1);
                                } else {
                                  setMedicalFormMandatory(0);
                                }
                              }}
                            />
                            <label
                              class="form-check-label"
                              htmlFor="flexSwitchCheckDefault2"
                            >
                              Patient Medical form with prescription is
                              mandatory
                            </label>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </form>
              <div className="BtnButton">
                {!prescribingPreferenceData ? (
                  <SkeletonLoader type="PharmacyBusinessStepButtonSkeleton" />
                ) : (
                  <StepButton
                    blue={true}
                    label={"Save & next"}
                    onClick={() => onPrescriber()}
                    // disabled={isContinue}
                    isLoading={loading}
                  />
                )}
              </div>
            </div>
          )}
          {isOpen === 3 && (
            <div className="Main-Verification-Sec">
              <FinanceBanner
                title={"Verification code"}
                desc={
                  "To approve or create prescriptions. You're required to create a four-digit PIN that is exclusive to you."
                }
                isBorder={!isRequestSentToAdmin}
                currentStep={finish}
                totalSteps={4}
              />
              <div
                className={`otp-container ${!isRequestSentToAdmin ? "" : ""}`}
              >
                <div className="form-label"> Enter the verification code</div>
                <div className="finance-input-container OtpWrapper">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    isInputNum
                    inputType="password"
                    renderSeparator={
                      <span style={{ paddingLeft: "10px" }}></span>
                    }
                    renderInput={(props) => (
                      <input
                        placeholder="0"
                        {...props}
                        style={{
                          height: "63px",
                          width: "100%",
                          textAlign: "center",
                          padding: "20px",
                          borderRadius: "10px",
                          border: otpError
                            ? otp.length === 4
                              ? "1px solid #008000"
                              : "1px solid  #ff0000"
                            : otp.length === 4
                            ? "1px solid #008000"
                            : "1px solid #878787",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="BtnButton">
                <StepButton
                  blue={true}
                  label={"Save & next"}
                  onClick={() => onOtpSubmit()}
                  // disabled={isContinue}
                  isLoading={loading}
                />
              </div>
            </div>
          )}
          {isOpen === 4 && (
            <div className="Main-Verification-Sec">
              <FinanceBanner
                title={"Confirm verification code"}
                desc={
                  "To approve or create prescriptions. You're required to create a four-digit PIN that is exclusive to you."
                }
                isBorder={!isRequestSentToAdmin}
                currentStep={finish}
                totalSteps={4}
              />
              <div
                className={`otp-container ${!isRequestSentToAdmin ? "" : ""}`}
              >
                <div className="form-label"> Enter the Verification Code</div>
                <div className="finance-input-container OtpWrapper">
                  <OtpInput
                    value={confirmOtp}
                    onChange={setConfirmOtp}
                    numInputs={4}
                    inputType="password"
                    isInputNum
                    renderSeparator={
                      <span style={{ paddingLeft: "10px" }}></span>
                    }
                    renderInput={(props) => (
                      <input
                        placeholder="0"
                        type="password"
                        {...props}
                        style={{
                          height: "63px",
                          width: "100%",
                          textAlign: "center",
                          padding: "20px",
                          borderRadius: "10px",
                          border: confirmOtpError
                            ? confirmOtp.length === 4
                              ? "1px solid #008000"
                              : "1px solid  #ff0000"
                            : confirmOtp.length === 4
                            ? "1px solid #008000"
                            : "1px solid #878787",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="BtnButton">
                <StepButton
                  blue={true}
                  label={"Save & next"}
                  onClick={() => onConfirmOtpSubmit()}
                  // disabled={isContinue}
                />
              </div>
            </div>
          )}
          {/* {isOpen === 4 && (
            <div className="Main-Registration-Terms-Conditions">
              <FinanceBanner
                title={"Registration Terms & Conditions"}
                desc={
                  "Please agree to all the terms and conditions of our verified sellers in order to purchase product from the pharmacy"
                }
              />
              <div className="conditon-main">
                <div className="check-box">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      checked={conditon?.termcon1 ? true : false}
                      name="termcon1"
                      value={conditon?.termcon1}
                      onChange={onHandleCheckBox}
                    />
                    <label class="form-label" for="gridCheck">
                      Agree all terms and conditions
                    </label>
                  </div>
                  <div className="select-cont">
                    <select
                      class="form-select"
                      aria-label="Large select example"
                      name="selTerm1"
                      value={conditon?.selTerm1}
                      onChange={(e) => onHandleTerm(e)}
                    >
                      {selectArray.map((item) => {
                        return (
                          <option value={item.value}> {item?.label}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="check-box">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      checked={conditon?.termcon2 ? true : false}
                      name="termcon2"
                      value={conditon?.termcon2}
                      onChange={onHandleCheckBox}
                    />
                    <label class="form-label" for="gridCheck">
                      I accept the terms and coditions
                    </label>
                  </div>
                  <div className="select-cont">
                    <select
                      class="form-select"
                      aria-label="Large select example"
                      name="selTerm2"
                      value={conditon?.selTerm2}
                      onChange={(e) => onHandleTerm(e)}
                    >
                      {selectArray.map((item) => {
                        return (
                          <option value={item.value}> {item?.label}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="check-box">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      checked={conditon?.termcon3 ? true : false}
                      name="termcon3"
                      value={conditon?.termcon3}
                      onChange={onHandleCheckBox}
                    />
                    <label class="form-label" for="gridCheck">
                      I accept the terms and coditions
                    </label>
                  </div>
                  <div className="select-cont">
                    <select
                      class="form-select"
                      aria-label="Large select example"
                      name="selTerm3"
                      value={conditon?.selTerm3}
                      onChange={(e) => onHandleTerm(e)}
                    >
                      {selectArray.map((item) => {
                        return (
                          <option value={item.value}> {item?.label}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="check-box">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={conditon?.termcon4 ? true : false}
                      name="termcon4"
                      value={conditon?.termcon4}
                      onChange={onHandleCheckBox}
                    />
                    <label class="form-label">
                      I accept the terms and coditions
                    </label>
                  </div>
                  <div className="select-cont">
                    <select
                      class="form-select"
                      aria-label="Large select example"
                      name="selTerm4"
                      value={conditon?.selTerm4}
                      onChange={(e) => onHandleTerm(e)}
                    >
                      {selectArray.map((item) => {
                        return (
                          <option value={item.value}> {item?.label}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="active-container">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={conditon.allCondition ? true : false}
                      name="allCondition"
                      value={conditon?.allCondition}
                      onChange={onHandleCheckBox}
                    />
                    <label class="form-label">
                      I agree to share my information with buy now payl8r.
                    </label>
                  </div>
                </div>
              </div>
              {conditionError ? (
                <div className="error">Please accept term and condition</div>
              ) : (
                ""
              )}
              <div className="BtnButton">
                <StepButton
                  blue={true}
                  label={"Apply For Approval"}
                  onClick={() => onConditions()}
                  // disabled={isContinue}
                />
              </div>
            </div>
          )} */}
          {/* {isOpen === 5 && (
            <>
              <div className="Main-congrats-Sec">
                <FinanceBanner
                  title={"Congratulations"}
                  desc={
                    "You have registered for the prescription service and pharmacy marketplace. we have received your details and will review approve your account within 48hours "
                  }
                />
                <div className="congrats-Content">
                  <h5>Did you know ?</h5>
                  <p>
                    Did you know when registering for the pharmacy marketplace,
                    the details you have provided also allow your business to
                    register and offer buy now Payl8r in your clinic allowing
                    clients to purchase your services via finance or monthly
                    payments.
                  </p>
                </div>

                <div className="PointsWrapper">
                  <div className="PointsItem">
                    <div className="PointsItemHeader">
                      <div className="PointsItemImg">
                        <img src={noIcon} alt="icon" />
                      </div>
                      <h6 className="point-header">No Risk </h6>
                    </div>
                    <div className="PointsItemContent">
                      <p>
                        {" "}
                        absolutely risk free, never worry about client
                        repayments we take that risk so you don’t have to
                      </p>
                    </div>
                  </div>

                  <div className="PointsItem">
                    <div className="PointsItemHeader">
                      <div className="PointsItemImg">
                        <img src={dateIcon} alt="dateIcon" />
                      </div>
                      <h6 className="point-header">Payout in 24-48 hours </h6>
                    </div>
                    <div className="PointsItemContent">
                      <p>
                        {" "}
                        Receive payment within 48hours from the day of treatment
                      </p>
                    </div>
                  </div>

                  <div className="PointsItem">
                    <div className="PointsItemHeader">
                      <div className="PointsItemImg">
                        <img src={noMoney} alt="noMoney" />
                      </div>
                      <h6 className="point-header">Fees as little as 3% </h6>
                    </div>
                    <div className="PointsItemContent">
                      <p> Pick a model to suit you</p>
                    </div>
                  </div>

                  <div className="PointsItem">
                    <div className="PointsItemHeader">
                      <div className="PointsItemImg">
                        <img src={clientIcon} alt="clientIcon" />
                      </div>
                      <h6 className="point-header">
                        Increased Client Retention
                      </h6>
                    </div>
                    <div className="PointsItemContent">
                      <p>
                        {" "}
                        Increase client retention and spend with convenient
                        monthly payments. one treatment leads to the repeat
                        treatment.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="MainStepsSecWrapper">
                  <div className="stepsTitleWrapper">
                    <h6>3 Simple steps</h6>
                    <p onClick={() => setModalOpen(true)}>how it works</p>
                  </div>
                  <div className="MainStepsSecBox">
                    <p>
                      1 . Chose the Finance provider model to suit you and your
                      business
                    </p>
                    <p>2 . The bank details you would like to be paid into</p>
                    <p>
                      3 . Accept the T&C from Payl8r Accept the T&C from Payl8r
                    </p>
                  </div>
                </div>

                <div className="active-container bg-white">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={conditon.allCondition ? true : false}
                      name="allCondition"
                      value={conditon?.allCondition}
                      onChange={onHandleCheckBox}
                    />
                    <label class="form-label">
                      I agree to share my information with buy now payl8r.
                    </label>
                  </div>
                </div>

                <div className="BtnButton">
                  <StepButton
                    blue={true}
                    label={"Next"}
                    onClick={() => {
                      setPlanModalOpen(true);
                    }}
                    // disabled={isContinue}
                  />

                  <span className="MaybeLink" onClick={() => setIsOpen(6)}>
                    Maybe Later
                  </span>
                </div>
              </div>
            </>
          )} */}
          {/* {
            isOpen === 6 && (<ThankYou/>)
          } */}
          {/* {isOpen === 4 && (
            <>
              <div className="Main-paid-Screen">
                <FinanceBanner
                  title={"Get Paid"}
                  desc={
                    "We need to connect your Faces account to your bank account in order to get you paid for your prescriber services"
                  }
                />
                <div className="BtnButton">
                  <StepButton
                    blue={true}
                    label={"Connect"}
                    onClick={() => {
                      // initStripeOnboardingData(
                      //   {},
                      //   (response) => {
                      //     window.location.href =
                      //       response?.data?.stripeOnboarding?.data?.account_link;
                      //   },
                      //   (error) => {
                      //     toast.error(
                      //       "Something went wrong while creating your payment link, please try again after some time!"
                      //     );
                      //   }
                      // );
                      window.location.href =
                      webBaseUrl + "/user/update-profile?active-tab=payment_gateways"
                      // afterSubmit();
                      // onNextStep();
                    }}
                    // disabled={isContinue}
                  />
                  <StepButton
                    className="OutLineBtn"
                    blue={true}
                    label={"Will do it later"}
                    onClick={() => {
                      afterSubmit();
                      onNextStep();
                    }}
                    // disabled={isContinue}
                  />
                </div>
              </div>
            </>
          )} */}
        </div>
        {/* {isOpen === 6 && <ThankYou />} */}
      </div>

      <CustomModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        type="review"
        className={"CustomeHowItWorkModel"}
        modalBody={
          <div className="finance-about-modal-body">
            <Logo />
            <h3>Buy Now Pay Later up to 14 days no interest. Now available.</h3>
            <div className="sub-body-content">
              <p>
                You can now buy stock and pay later with this new buy now, Pay
                later feature.
              </p>
              <p>
                Once you complete the registration and unlock finance, the Buy
                Now, Pay later option will appear at the checkout within the
                pharmacy.
              </p>
              <p>
                {" "}
                Once your application has been approved (only takes a few
                minutes) your order will be processed as usual and you will only
                need to pay back within 14 days. Giving you time to treat your
                client first.
              </p>
              <p>
                Interest-free feature, you won’t be charged any interest for
                using Buy Now, Pay Later.
              </p>
              <p>
                You can have up to three applications live at any one point.
              </p>
              <Link>View FAQs</Link>
            </div>
            <p>Please complete registration to use this feature.</p>
            <div className="BtnButton">
              <StepButton
                blue={true}
                label={"Ok"}
                onClick={closeModal}
                // disabled={isContinue}
              />
            </div>
          </div>
        }
        modaltitle="How does it work?"
      />

      <CustomModal
        modalOpen={planModalOpen}
        setModalOpen={setPlanModalOpen}
        // type="review"
        modaltitle="Select plan"
        className={"selectPlanModel"}
        modalBody={
          <div className="plan-modal">
            <p>Lorim ipsum dummy text here</p>
            <div className="d-flex flex-column plan-container">
              <div
                role="button"
                className="d-flex justify-content-between align-items-center plan-section"
                onClick={() => {
                  setPlanModalOpen(false);
                  setBankModalOpen(true);
                }}
              >
                <p>3% and £50 per month</p>
                <FiChevronRight className="arrow_next" />
              </div>

              <div
                className="d-flex justify-content-between align-items-center plan-section"
                role="button"
                onClick={() => {
                  setPlanModalOpen(false);
                  setBankModalOpen(true);
                }}
              >
                <p>10% and £0 per month</p>
                <FiChevronRight className="arrow_next" />
              </div>
            </div>
            <div className="BtnButton">
              <StepButton
                blue={true}
                label={"Submit"}
                onClick={() => {
                  setPlanModalOpen(false);
                }}
                // disabled={isContinue}
                isLoading={loading}
              />
            </div>
          </div>
        }
      />

      <CustomModal
        modalOpen={bankModalOpen}
        setModalOpen={setBankModalOpen}
        // type="review"
        modaltitle="Bank details"
        className={"CustomeBankModal"}
        modalBody={
          <div className="plan-modal">
            <p>Lorim ipsum dummy text here</p>
            <div className="plan-container">
              <div className="finance-input-container">
                <span className="form-label">Business Account Number</span>
                <input
                  class="form-control"
                  type="text"
                  value={accountNumber}
                  onChange={(e) => {
                    setAccountNumber(e.target.value);
                    setErrors((prevErrors) => {
                      return { ...prevErrors, accountNumber: "" };
                    });
                  }}
                />
                {errors.accountNumber && (
                  <p className="error">{errors.accountNumber}</p>
                )}
              </div>

              <div className="finance-input-container">
                <span className="form-label">Business Sort Code</span>
                <div className="SortInputWrapper">
                  {[0, 1, 2].map((_, index) => (
                    <input
                      class="form-control"
                      type="text"
                      style={{ border: "1px solid var(--Form, #878787)" }}
                      value={bSortCodeinp[index]}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      id={`inp${index}`}
                    ></input>
                  ))}
                </div>

                {errors.sortCode && <p className="error">{errors.sortCode}</p>}
              </div>
            </div>
            <div className="BtnButton">
              <StepButton
                blue={true}
                label={"Submit"}
                onClick={handleBankSubmit}
                // disabled={isContinue}
                isLoading={loading}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default PrescriberPreferences;
