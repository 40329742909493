
import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './revenue-dashboard.scss';
import { IoIosMore } from 'react-icons/io';
import CustomModal from '../../modal/CustomModal';
import FinaceHeader from '../../finaceHeader';
import logo from '../../../images/icons/faces-logo-black.svg';
import moment from 'moment';
import TextInput from '../../common/TextInput';
import { frontendURL, webBaseUrl } from '../../../config';
import { useDispatch, useSelector } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { BiExport } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';
import img from "../../../assets/finance-page/noFinancePageImg.png";
import downloadcsvIcon from "../../../assets/finance-page/download-csv.svg";

import DeleteTreatment from '../../../images/finance/DeleteTreatment.svg';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2'
import { faker } from '@faker-js/faker';
import WeekDayMonthCalenderHeader from "../header/WeekDayMonthCalenderHeader";
import { isGuestAllowed } from "../../../Routes";
import getProfileData from "../../redux/actions/profile/Profile";
import Cookies from "js-cookie";
import { OnboardingStepsForFinance } from "../../redux/actions/pay-later/onboardingStepsForFinance";
import { useNavigate } from "react-router";
import { GetFinanceRevenueForChartAction } from '../../redux/actions/financeDashboard/chartDataForRevenueGql';
import { AddForwardFinanceDetail } from '../../redux/actions/financeDashboard/addForwardFinanceDetailsGql';
import { toast } from 'react-toastify';
import FinanceOfferedToClientSkeleton from './financeOfferedToClientSkeleton';
import { GetAllFinanceOfferedToClientFromDBAction } from '../../redux/actions/financeDashboard/getAllFinanceOfferedToClientFromDBGql';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GenerateInvoicePath, VerifyFinanceInvoiceToken } from '../../redux/actions/pay-later/invoice/generateInvoicePath';
import { ApproveFinanceApplication } from '../../redux/actions/profile/approveFinanceApplicationGql';
import Swal from 'sweetalert2';
import { GetOnboardingInfoForFinanceAction } from '../../redux/actions/financeDashboard/getOnboardingInfoForFinanceGql';
import { ExportFinanceListToCsv } from '../../redux/actions/pay-later/exportFinanceListToCsv';
import { useRefundFinanceApplication } from '../../redux/actions/profile/refundFinance';

import CustomDropdown from './CustomDropdown/CustomDropdown';
import FinanceChartSkeleton from './financeChartSkeleton';
import loadingIcon from '../../../images/loading.gif';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  // maintainAspectRatio: false,
  aspectRatio: 3,
  plugins: {
    title: {
      display: true,
      text: '',
    },
    legend: {
      display: false, // Set display to false to remove the legend
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        drawBorder: false, // Hide y-axis border
        borderDash: [3], // Set the borderDash property for y-axis (horizontal lines)
      },
      // ticks: {
      //   callback: (value) => {
      //     if (value >= 1000) {
      //       const formattedNumber = (value / 1000).toFixed(2);
      //       return `${formattedNumber}K`;
      //     }
      //     return `${value}`;
      //   },
      //   // maxTicksLimit: 6,
      //   // min: 1250,
      // },
    },
    y: {
      beginAtZero: true,
      ticks: {
        callback: function (value) {
          // Convert the value to the desired format (e.g., 1.25K)
          const formattedValue = value / 1000; // Assuming the value is in the range of thousands
          return formattedValue.toFixed(2) + 'K';
        },
      },
    },
  },
  barThickness: 21,
};
const defaultLabels = Array.from({ length: 7 }, (_, i) => `Day ${i + 1}`);

const RevenueDashboardComponent = () => {
  const [labels, setLabels] = useState(defaultLabels);
  const [addVerifyModal, setAddVerifyModal] = useState(false);
  const [howItWorkModel, setHowItWorkModel] = useState(false);
  const [forwardFinanceModal, setForwardFinanceModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState(['', '', '', '']);
  const [applicationFormData, setApplicationFormData] = useState({
    clientName: '',
    email: '',
    dateAppointment: '',
    locationName: '',
    time: '09:00',
    treatments: [],
    consentForms: '',
    amount: '',
  });
  const [validationErrors, setValidationErrors] = useState({
    clientName: '',
    email: '',
    dateAppointment: '',
    locationName: '',
    time: '',
    treatments: [''],
    consentForms: '',
    amount: '',
  });
  const [chartColor, setChartColor] = useState('#175FFA');
  const [dateApmtError, setDateApmtError] = useState(false);
  const [dateApmtValue, setDateApmtValue] = useState('');
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const navigate = useNavigate();
  const { verifyFinanceInvoiceTokenQueryData, initverifyFinanceInvoiceTokenData } = VerifyFinanceInvoiceToken()
  const { exportFinanceListToCsvQueryData, initexportFinanceListToCsvData } = ExportFinanceListToCsv();

  const [isFinanceOfferedLoad, setIsFinanceOfferedLoad] = useState(false);
  const [financeOfferedToClient, setFinanceOfferedToClient] = useState([]);
  const [financeOfferedToClientPagination, setFinanceOfferedToClientPagination] = useState({
    totalRecords: 0,
    currentPage: 0,
    totalPages: null,
  });
  const [isVerifyCodeButtonLoading, setIsVerifyCodeButtonLoading] = useState(false);


  useEffect(() => {
    var date = moment(year + '-' + month + '-' + day).format('YYYY-MM-DD');
    console.log('hihi', date, typeof date);
    if (day &&
      month &&
      year &&
      day?.length == 2 &&
      month.length == 2 &&
      year?.length == 4 &&
      date >= moment().format('YYYY-MM-DD')
    ) {
      setDateApmtError(false);
      setValidationErrors((prev) => ({
        ...prev,
        dateAppointment: '',
      }));
      setApplicationFormData({
        ...applicationFormData,
        dateAppointment: date,
      });
      setDateApmtValue(date);
    } else if (day && month && year) {
      setDateApmtError(true);
      setValidationErrors((prev) => ({
        ...prev,
        dateAppointment: 'Invalid Date of Appointment',
      }));
    }
    else if (
      day &&
      month &&
      year &&
      day?.length >= 1 &&
      month.length >= 1 &&
      year?.length == 4 &&
      date >= moment().format('YYYY-MM-DD')
    ) {
      setDateApmtError(false);
      setApplicationFormData({
        ...applicationFormData,
        dateAppointment: date,
      });
      setValidationErrors((prev) => ({
        ...prev,
        dateAppointment: '',
        locationName: '',
        time: '',
        treatments: '',
        consentForms: '',
        amount: '',
      }));
    }
  }, [day, month, year]);

  const [showWarning, setShowWarning] = useState(false);
  const dispatch = useDispatch()
  const { profileData } = useSelector(state => state?.GetProfileDataReducer)

  const [addVerifyToBePostError, setAddVerifyToBePostError] = useState('');
  const [chartData, setChartData] = useState(null);
  const [showNoFinancePage, setShowNoFinancePage] = useState(false);

  const { getUserDetailsForFirstStepdDataQueryData, initonboardingStepsForFinanceData } = OnboardingStepsForFinance();



  useEffect(() => {
    var date = moment(year + '-' + month + '-' + day).format("YYYY-MM-DD")
    if (day && month && year && day?.length == 2 && month.length == 2 && year?.length == 4 && date >= moment().format("YYYY-MM-DD")) {
      setDateApmtError(false);
      setValidationErrors((prev) => ({
        ...prev,
        dateAppointment: "",
      }));
      setDateApmtValue(date);
    } else if (day && month && year) {
      setDateApmtError(true);
      setValidationErrors((prev) => ({
        ...prev,
        dateAppointment: "Invalid Date of Appointment",
      }));
    }
  }, [day, month, year])

  const isUserLoggedIn = () => {
    const token = Cookies.get('faces_access_token');
    return !!token; // Returns true if the token exists, false otherwise
  };

  // useEffect(() => {
  //     let pathnameToBeChecked = window.location.pathname;

  //     let cleanPathname =
  //       pathnameToBeChecked.indexOf(process.env.PUBLIC_URL) === 0
  //         ? pathnameToBeChecked.replace(process.env.PUBLIC_URL, '')
  //         : pathnameToBeChecked;

  //     if (cleanPathname.indexOf(process.env.PUBLIC_URL) == 0) {
  //       cleanPathname = cleanPathname.replace(process.env.PUBLIC_URL, '');
  //     }

  //     // if(isGuestAllowed(cleanPathname)) {
  //     //   setShowSupportChatWidget(false)
  //     // }
  //     if (profileData == null && !isGuestAllowed(cleanPathname))
  //       dispatch(getProfileData())

  //     console.log("INSURANCE_TEST is guest allowed ",cleanPathname, isGuestAllowed(cleanPathname), pathnameToBeChecked);

  //     if (!(Cookies.get("faces_access_token") || Cookies.get("faces_session")) && !isGuestAllowed(cleanPathname)) {
  //       console.log("INSURANCE_TEST - redirecting user to login from app.js, as token is not present");
  //       window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/login`;

  //       // Cookies.set("faces_access_token","eyJpdiI6IkliaTF3bVRJWkR2Q3IwcE1sU1Z1VUE9PSIsInZhbHVlIjoicHZGejVoM1N4K3Z1cVpoUFk5UlR2RGs0M0h2R2JJdXp5YnFvRkkweEpJQzY1dDJRcERJUDJDckM4eEJBMkZGOUJyT3JoVFMzXC9KNzNnNm13dFZueFc0UzQzSVBHc3g2ZFRlVmtiV1F5ZHNZPSIsIm1hYyI6IjAxMmVlMjQwYmIxNzg0NWJjOTkzZDg2NWY4ZjJhNjUwODkwYmZhYWY2MDkxYjM3NDdhZTRlNzllMjFkYjFhZmYifQ==");
  //     }
  //   }, [window.location.pathname])


  // const [chartData, setChartData] = useState({
  //   labels: labels,
  //   datasets: [
  //     {
  //       label: labels,
  //       data: labels?.map(() =>
  //         faker.datatype.number({ min: 1250, max: 2750 })
  //       ),
  //       backgroundColor: '#175FFA',
  //     },
  //   ],
  // });

  const redirectToFaqPage = () => {
    const faqPageUrl = webBaseUrl + '/shop-faqs';
    window.open(faqPageUrl, '_blank'); // '_blank' opens the link in a new tab
  };

  const verificationCodeRef = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const submitBtnOtp = useRef(null);
  const handleInputChange = (index, value) => {
    const updatedCode = [...verificationCode];
    updatedCode[index] = value;
    setVerificationCode(updatedCode);
    if (value) {
      if (index < verificationCodeRef.length - 1) {
        console.log('index', index);
        verificationCodeRef[index + 1].current.focus();
      } else if (index === verificationCode.length - 1) {
        setTimeout(() => {
          console.log('i index', verificationCode.join('').toString(), verificationCode.join(''));
          submitBtnOtp.current.click();
          console.log('index', verificationCode.join(''));
        }, 0);
      }
    }
  };

  const handleHowToWork = () => {
    setHowItWorkModel(true);
  };
  const handleForwardApplication = () => {
    if (!showForwordFinanceButton) {
      setShowNoAdminApprovedPopup(true);
    } else {
      setForwardFinanceModal(true);
    }
  };

  const handleChange = (e, index = null) => {
    const { name, value } = e.target;
    if (name === 'treatments') {
      console.log('treatments', value);
      let val = [...applicationFormData.treatments];
      val[index] = value;
      setApplicationFormData((prev) => ({
        ...prev,
        [name]: val,
      }));
      validateInput(name, value, index);
      console.log('all:::', applicationFormData.treatments);
    } else {
      setApplicationFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      validateInput(name, value);
    }
  };

  const validateInput = (name, value, index = null) => {
    switch (name) {
      case 'clientName':
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            clientName: 'Client name is required',
          }));
        } else if (!isValidName(value)) {
          setValidationErrors((prev) => ({
            ...prev,
            clientName: 'Please enter valid Client name',
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            clientName: '',
          }));
        }
        break;
      case 'email':
        if (!value || !isValidEmail(value)) {
          setValidationErrors((prev) => ({
            ...prev,
            email: 'Valid email is required',
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            email: '',
          }));
        }
        break;
      case 'locationName':
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            locationName: 'Location name is required',
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            locationName: '',
          }));
        }
        break;
      case 'treatments':
        if (!value) {
          let errors = [...validationErrors.treatments];
          errors[index] = 'Treatment is required';
          setValidationErrors((prev) => ({
            ...prev,
            treatments: errors,
          }));
        } else {
          let errors = [...validationErrors.treatments];
          errors[index] = '';
          setValidationErrors((prev) => ({
            ...prev,
            treatments: errors,
          }));
        }
        break;
      case 'consentForms':
        // if (!value) {
        //   setValidationErrors((prev) => ({
        //     ...prev,
        //     consentForms: 'Consent form is required',
        //   }));
        // } else {
        //   setValidationErrors((prev) => ({
        //     ...prev,
        //     consentForms: '',
        //   }));
        // }
        break;
      case 'amount':
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            amount: 'amount is required',
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            amount: '',
          }));
        }
        break;
      case 'dateAppointment':
        if (!value) {
          setDateApmtError(true);
          setValidationErrors((prev) => ({
            ...prev,
            dateAppointment: 'Date of appointment is required',
          }));
        } else {
          setDateApmtError(false);
          setValidationErrors((prev) => ({
            ...prev,
            dateAppointment: '',
          }));
        }
        break;
      default:
        break;
    }
  };

  function isValidEmail(email) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  }

  const isValidName = (name) => {
    // eslint-disable-next-line no-useless-escape
    const nameRegex = /^([a-zA-Z0-9\s]*)$/;
    return nameRegex.test(name);
  }

  const {
    approveFinanceApplicationQueryData,
    initApproveFinanceApplicationData,
  } = ApproveFinanceApplication();
  console.log(financeOfferedToClient, "financeOfferedToClient")
  const handleAddVerifySubmit = (e) => {
    e.preventDefault();
    setIsVerifyCodeButtonLoading(true);
    if (verificationCode.every((digit) => digit.trim() !== '')) {
      // Perform submission logic here
      // API call
      initApproveFinanceApplicationData({
        confirmCode: parseInt(verificationCode.join('')),
        orderId: financeRetailerId,
        financeLoanRequestId: parseInt(financeOrderId),
      }, (res) => {
        console.log('res', res);
        toast.success(res?.data?.approveFinanceApplication?.message);
        // here
        console.log('chartType', chartType)
        handleButtonClick(chartType);
        setAddVerifyModal(false);
        setVerificationCode(['', '', '', '']);
        setIsVerifyCodeButtonLoading(false);
      }, (err) => {
        toast.error(err?.data?.approveFinanceApplication?.message ? err.data.approveFinanceApplication.message : 'Something went wrong!');
        setIsVerifyCodeButtonLoading(false);
      });
    } else {
      setAddVerifyToBePostError('please add Verification Code!');
      setIsVerifyCodeButtonLoading(false);
      return;
    }
  };

  useEffect(() => {
    if (!addVerifyModal) {
      setVerificationCode(['', '', '', '']);
    }
  }, [addVerifyModal]);

  const validateForm = () => {
    // const newErrors = {};
    // for (const key in applicationFormData) {
    //   if (key !== 'treatments') {
    //     if (applicationFormData[key].trim() === '') {
    //       newErrors[key] = 'This field is required';
    //     }
    //   }
    // }
    // setDateApmtError(true);
    // setValidationErrors(newErrors);
    // return Object.keys(newErrors).length === 0;
    const newErrors = { ...validationErrors }; // Copy existing errors to update
    let hasErrors = false; // Flag to track if any errors are found

    // Validate other fields excluding 'treatments'
    for (const key in applicationFormData) {
      if (key !== 'treatments') {
        if (applicationFormData[key].trim() === '') {
          newErrors[key] = 'This field is required';
          console.log('err packed 328', key);
          hasErrors = true;
          if (key === 'dateAppointment') {
            setDateApmtError(true);
          }
          break;
        } else {
          // Clear error message if the field is not empty
          newErrors[key] = '';
          if (key === 'dateAppointment') {
            let date = moment(year + '-' + month + '-' + day).format('YYYY-MM-DD');
            if (day &&
              month &&
              year && date === 'Invalid date') {
              setDateApmtError(true);
              // setValidationErrors((prev) => ({
              //   ...prev,
              //   dateAppointment: 'Invalid Date of Appointment',
              // }));
              newErrors[key] = 'Invalid Date of Appointment';
              hasErrors = true;
              break;
            }
            else if (
              day &&
              month &&
              year &&
              day?.length >= 1 &&
              month.length >= 1 &&
              year?.length == 4 &&
              date >= moment().format('YYYY-MM-DD')
            ) {
              setDateApmtError(false);
              // setValidationErrors((prev) => ({
              //   ...prev,
              //   dateAppointment: '',
              // }));
              // setApplicationFormData({
              //   ...applicationFormData,
              //   dateAppointment: date,
              // });
              // setDateApmtValue(date);
              newErrors[key] = '';
              hasErrors = false;
              break;
            } else if (day && month && year) {
              setDateApmtError(true);
              // setValidationErrors((prev) => ({
              //   ...prev,
              //   dateAppointment: 'Invalid Date of Appointment',
              // }));
              newErrors[key] = 'Invalid Date of Appointment';
              hasErrors = true;
              break;
            }
          } else if (key === 'clientName') {
            if (!isValidName(applicationFormData[key])) {
              newErrors[key] = 'Please enter valid Client name';
              hasErrors = true;
              break;
            }
          } else if (key === 'email') {
            if (!isValidEmail(applicationFormData[key])) {
              newErrors[key] = 'Valid email is required';
              hasErrors = true;
              break;
            }
          }
        }
      }
    }
    console.log('all::', applicationFormData.treatments);
    // Validate 'treatments' array
    if (!applicationFormData.treatments || applicationFormData.treatments.length === 0) {
      newErrors.treatments = ['Treatment is required'];
      hasErrors = true;
      console.log('err packed 341');
    } else {
      applicationFormData.treatments.forEach((treatment, index) => {
        if (!treatment) {
          newErrors.treatments[index] = 'Treatment is required';
          hasErrors = true;
          console.log('err packed 347', index);
        } else {
          // Clear error message if the treatment is not empty
          newErrors.treatments[index] = '';
        }
      });
    }

    console.log(newErrors);
    setValidationErrors(newErrors); // Update validation errors state

    return !hasErrors; // Return true if no errors found
  };

  const {
    addForwardFinanceDetailQueryData,
    initAddForwardFinanceDetailData,
  } = AddForwardFinanceDetail();

  const handleFinanceApplicationSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateForm();

    console.log('isFormValid', isFormValid);
    if (isFormValid) {
      const formattedDateOfAppointment = moment(dateApmtValue).format('DD-MM-YYYY');
      // Perform form submission logic here
      const reqData = {
        clientName: applicationFormData.clientName,
        clientEmail: applicationFormData.email,
        dateOfAppointment: formattedDateOfAppointment,
        locationName: applicationFormData.locationName,
        time: applicationFormData.time,
        treatments: applicationFormData.treatments,
        consentForms: applicationFormData.consentForms ? applicationFormData.consentForms.split(',') : [],
        amount: applicationFormData.amount,
        clinicName: applicationFormData.locationName,
      }
      initAddForwardFinanceDetailData(reqData, (res) => {
        console.log('res', res);
        toast.success(res?.data?.addForwardFinanceDetail?.message);
        setForwardFinanceModal(false);
        // here
        handleButtonClick(chartType);
        console.log('chartType', chartType)
        setShowNoFinancePage(false);
      }, (err) => {
        toast.error(err?.data?.addForwardFinanceDetail?.message ? err.data.addForwardFinanceDetail.message : 'Something went wrong!');
      });
      console.log('Form submitted:', applicationFormData);
    } else {
      console.log('Form validation failed');
    }
  };

  const [financeOrderId, setFinanceOrderId] = useState(null);
  const [financeRetailerId, setFinanceRetailerId] = useState(null);

  const handleVerification = (id, retailerId) => {
    setAddVerifyModal(true);
    setFinanceOrderId(id);
    setFinanceRetailerId(retailerId);
    console.log(id, "financeRetailerId=", retailerId);
  };
  console.log(financeRetailerId, "financeRetailerId=", financeOrderId)
  const {
    getFinanceRevenueForChartQueryData,
    initGetFinanceRevenueForChartData,
  } = GetFinanceRevenueForChartAction();

  const [timeFrameType, setTimeFrameType] = useState('day');
  const [timeFrameDateRange, setTimeFrameDateRange] = useState({
    startDateFromUser: null,
    endDateFromUser: null,
  });
  const [chartType, setChartType] = useState('D');
  const [latestChartTypeSelection, setLatestChartTypeSelection] = useState('D');
  const handleButtonClick = (timeRange) => {
    console.log('timeRange', timeRange, dateRange);
    setChartType(timeRange);
    let timeFrame = 'day';
    let timeFr = 'day';
    let startDateFromUser = null;
    let endDateFromUser = null;
    timeRange === 'Custom-submit' || timeRange === 'Custom'
      ? setLatestChartTypeSelection('Custom')
      : setLatestChartTypeSelection(timeRange)
    setFinanceOfferedToClientPagination({
      totalRecords: 0,
      currentPage: 0,
      totalPages: null,
    });

    switch (timeRange) {
      case 'D':
        timeFrame = 'day';
        setIsFinanceOfferedLoad(true);
        setTimeFrameType(timeFrame);
        setTimeFrameDateRange({
          startDateFromUser,
          endDateFromUser,
        });
        setFinanceOfferedToClientPagination({
          totalRecords: 0,
          currentPage: 0,
          totalPages: null,
        });
        setFinanceOfferedToClient([]);
        setShowDateRange(false);
        setDateRange('');
        break;
      case 'W':
        timeFrame = 'week';
        timeFr = 'week';
        setIsFinanceOfferedLoad(true);
        setTimeFrameType('week');
        setTimeFrameDateRange({
          startDateFromUser,
          endDateFromUser,
        });
        setFinanceOfferedToClientPagination({
          totalRecords: 0,
          currentPage: 0,
          totalPages: null,
        });
        setFinanceOfferedToClient([]);
        setShowDateRange(false);
        setDateRange('');
        break;
      case 'M':
        timeFrame = 'month';
        timeFr = 'month';
        setIsFinanceOfferedLoad(true);
        setTimeFrameType('month');
        setTimeFrameDateRange({
          startDateFromUser,
          endDateFromUser,
        });
        setFinanceOfferedToClientPagination({
          totalRecords: 0,
          currentPage: 0,
          totalPages: null,
        });
        setFinanceOfferedToClient([]);
        setShowDateRange(false);
        setDateRange('');
        break;
      case 'Custom':
        console.log('Custom');
        setShowDateRange(true);
        return;
        break;
      case 'Custom-submit':
        timeFrame = '';
        timeFr = 'custom-submit';
        setIsFinanceOfferedLoad(true);
        setTimeFrameType('');
        console.log('dateRangeArray', dateRange);
        if (dateRange) {
          let dateRangeArray = dateRange.split(' - ');
          console.log('dateRangeArray', dateRangeArray);
          startDateFromUser = dateRangeArray[0];
          endDateFromUser = dateRangeArray[1];
        }
        setFinanceOfferedToClient([]);
        setFinanceOfferedToClientPagination({
          totalRecords: 0,
          currentPage: 0,
          totalPages: null,
        });
        break;
      default:
    }
    initGetFinanceRevenueForChartData(
      {
        timeFrame,
        startDateFromUser,
        endDateFromUser,
      },
      (res) => {
        getFinanceOfferedToClient(timeFr, startDateFromUser, endDateFromUser);
        let data = res?.data;
        let labels = data?.map((date) => {
          console.log('date', date);
          if (timeFrame === 'week') {
            return `${date.period.charAt(0).toUpperCase()}${date.period.slice(
              1
            )}`;
          } else if (timeFrame === 'month') {
            return `${date.period.charAt(0).toUpperCase()}${date.period.slice(
              1
            )}`;
          } else {
            return moment(date.period).format('DD MMMM YYYY');
          }
        });
        let dataRevenue = data?.map((date) => date.totalRevenue);
        let datasets = [
          {
            label: 'Revenue',
            data: labels.map((value, index) => dataRevenue[index]),
            backgroundColor: chartColor,
          },
        ];
        setLabels(labels);
        setChartData({ labels, datasets });
      },
      (err) => {
        console.log('date', err);
      }
    );
  };

  useEffect(() => {
    handleButtonClick('D');
  }, []);

  const [showDateRange, setShowDateRange] = useState(false);
  const [dateRange, setDateRange] = useState('');
  const [pickerDateRange, setPickerDateRange] = useState('');
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [navigateToForwordFinanceStep, setNavigateToForwordFinanceStep] = useState(false);
  const handleDateRangeChange = (event, picker) => {
    console.log(
      'picker',
      picker,
      picker.startDate.format('YYYY/MM/DD'),
      picker.endDate.format('YYYY/MM/DD')
    );
    setStartDate(picker.startDate);
    setEndDate(picker.endDate);
    setPickerDateRange(
      `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
        'DD/MM/YYYY'
      )}`
    );
    setDateRange(
      picker.startDate.format('YYYY/MM/DD').toString() +
      ' - ' +
      picker.endDate.format('YYYY/MM/DD').toString()
    );
  };
  useEffect(() => {
    setFinanceOfferedToClient([]);

    setTimeFrameDateRange({
      startDateFromUser: dateRange.split(' - ')[0],
      endDateFromUser: dateRange.split(' - ')[1],
    });
    if (dateRange) {
      handleButtonClick('Custom-submit');
    }
  }, [dateRange]);

  const [trtFiledsIdCount, setTrtFiledsIdCount] = useState(2);
  const [treatmentFields, setTreatmentFields] = useState([{ id: 1 }]);
  const addTreatmentFiled = () => {
    console.log('length:', treatmentFields.length);
    setTreatmentFields([...treatmentFields, { id: trtFiledsIdCount }]);
    setTrtFiledsIdCount(trtFiledsIdCount + 1);
    // setTreatmentFields(treatmentFields.map((item, index) => item.id = parseInt(index) + 1));
    // setTreatmentFields(treatmentFields.map((item, index) => ({ id: parseInt(index) + 1 })));
  };
  const removeTreatmentFiled = (id, index) => {
    Swal.fire({
      text: 'Are you sure you want to remove this treatment?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        confirmButton: 'btn-primary',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('length: remove', id, index);
        let errors = [...validationErrors.treatments];
        let err = errors.filter((item, i) => i !== index);
        errors[index] = '';
        setValidationErrors((prev) => ({
          ...prev,
          treatments: err,
        }));
        console.log(id, index);
        let fileds = [...treatmentFields];
        const updatedFileds = fileds.filter(item => item.id !== id);
        let trts = [...applicationFormData.treatments];
        trts = trts.filter((item, i) => i !== index);
        setApplicationFormData((prev) => ({
          ...prev,
          treatments: trts,
        }));
        setTreatmentFields([...updatedFileds]);
        // setTreatmentFields(treatmentFields.filter((item) => item.id !== id));
        // setTreatmentFields((prev) => prev.filter((item) => item.id !== id));
      }
    });
  };

  const {
    getAllFinanceOfferedToClientFromDBQueryData,
    initGetAllFinanceOfferedToClientFromDBData,
  } = GetAllFinanceOfferedToClientFromDBAction();

  useEffect(() => {
    getFinanceOfferedToClient();
  }, []);
  const getFinanceOfferedToClient = (timeFr = 0, startDateFromUser = null, endDateFromUser = null) => {
    if (!getAllFinanceOfferedToClientFromDBQueryData.loading) {
      initGetAllFinanceOfferedToClientFromDBData(
        {
          // currentPage: timeFr === 0
          //   ? financeOfferedToClientPagination?.totalPages !== null
          //     ? financeOfferedToClientPagination?.totalPages >=
          //       financeOfferedToClientPagination?.currentPage + 1
          //       ? financeOfferedToClientPagination?.currentPage + 1
          //       : financeOfferedToClientPagination?.currentPage
          //     : 1
          //   : 1,
          currentPage: timeFr === 0
            ? financeOfferedToClientPagination?.totalPages !== null
              ? financeOfferedToClientPagination?.totalPages >=
                financeOfferedToClientPagination?.currentPage
                ? financeOfferedToClientPagination?.currentPage
                : financeOfferedToClientPagination?.currentPage
              : 1
            : 1,
          ordersPerPage: 10,
          timeFrame: "",
          // timeFrame: "",
          startDateFromUser: timeFr !== 0 ? startDateFromUser : timeFrameDateRange.startDateFromUser,
          endDateFromUser: timeFr !== 0 ? endDateFromUser : timeFrameDateRange.endDateFromUser,
        },
        (res) => {
          setIsFinanceOfferedLoad(false);
          if (timeFr === 0) {
            setFinanceOfferedToClient([...financeOfferedToClient, ...res?.data]);
          } else {
            // if (!res?.data.length) {
            //   navigate("/fowrard-finance-step")
            // }
            setFinanceOfferedToClient(res?.data);
          }
          let paginationData = { ...res?.pagination };
          setFinanceOfferedToClientPagination({
            ...paginationData,
            totalRecords: res?.pagination?.totalRecords,
            currentPage: res?.pagination?.currentPage + 1,
            totalPages: res?.pagination?.totalPages,
          });
        },
        (err) => {
          setIsFinanceOfferedLoad(false);
          console.log('err on get all finance offered to client', err);
        }
      );
    }
  }

  const handleDownloadInvoice = (finance) => {
    // window.location.href = finance.invoiceLink;
    window.open(finance.invoiceLink, '_blank');
  }

  const { refundFinanceApplication, loading } = useRefundFinanceApplication();


  const handleRefundOrder = (finance) => {
    Swal.fire({
      text: 'Are you sure you want to refund this transaction?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        confirmButton: 'btn-primary',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        refundFinanceApplication({
          variables: {
            orderId: finance.retailerOrderId,
            refundAmount: finance.amount,
            loanRequestId: finance.payl8r_order_id,
            refundType: 'full',
          }
        })
      }
    });
  }

  useEffect(() => {
    initonboardingStepsForFinanceData({},
      (res) => {
        let statuses = res.data.getOnboardingInfoForFinance.data;
        if (statuses && (statuses.bank_details_added === false || statuses.basic_information_completed === false || statuses.documents_uploaded === false)) {
          console.log("innnnnnnnnnnnn")
          if (window.location.pathname !== "/onboarding-steps-new") {
            navigate("/onboarding-steps-new");
          }
        } else if (navigateToForwordFinanceStep === true) {
          navigate("/fowrard-finance-step")
        }
        if (statuses) {
          statuses.finance_loan_request_status.map((item) => {
            if (item.finance_loan_request_status !== 'approved') {
              setShowWarning(true);
            }
          })
        }
      },
      (err) => {
        console.log("Error fetching onboarding data:", err);
      }
    );
  }, [navigateToForwordFinanceStep, navigate]);

  /* For verifying user status */
  const [isAdminApprove, setIsAdminApprove] = useState(false);
  const [isAdminRejected, setIsAdminRejected] = useState(false);
  const [rejectedReason, setRejectedReason] = useState('');
  const [rejectedReasonModal, setRejectedReasonModal] = useState(false);
  const {
    getOnboardingInfoForFinanceQueryData,
    initGetOnboardingInfoForFinanceData,
  } = GetOnboardingInfoForFinanceAction();
  useEffect(() => {
    initGetOnboardingInfoForFinanceData({}, (res) => {
      res?.data?.admin_approved
        ? setIsAdminApprove(true)
        : res?.data?.admin_rejected
          ? setIsAdminRejected(true)
          : setIsAdminRejected(false);
      setIsShowStatusLabel(true);
      setRejectedReason(res?.data?.rejection_reason);
    }, (err) => {
      console.log(err);
    });
  }, [])
  const [isShowStatusLabel, setIsShowStatusLabel] = useState(false);
  const downloadCSVFromBase64 = (base64String, filename = 'download.csv') => {
    const base64GlobalPrefix = 'data:text/csv;base64,';
    const link = document.createElement('a');

    link.href = `${base64GlobalPrefix}${base64String}`;
    link.download = filename;
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link);
  };

  const downloadCSV = async () => {
    initexportFinanceListToCsvData({
      startDateFromUser: timeFrameDateRange.startDateFromUser,
      endDateFromUser: timeFrameDateRange.endDateFromUser,
    },
      (res) => {
        if (res.data.downloadFetchedFinanceOfferedChartDataInCsv.success) {
          downloadCSVFromBase64(res.data.downloadFetchedFinanceOfferedChartDataInCsv.data, 'financeRequests.csv');
        }
      },
      (err) => {
        // console.log("errrrrrrrrrrrr",err)
      }
    )
  }

  useEffect(() => {
    console.log('picker 1', pickerDateRange);
  }, [pickerDateRange]);

  const TIME = () => {
    const hours = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        hours.push(
          moment({
            hour,
            minute
          }).format('HH:mm')
        );

      }
    }
    return hours;
  };

  const handleTimeSlotChange = (selectedValue, id) => {
    console.log('time slot selection', selectedValue);
    setApplicationFormData((prev) => ({
      ...prev,
      time: selectedValue
    }));
    setValidationErrors((prev) => ({
      ...prev,
      time: ''
    }));
  }

  useEffect(() => {
    if (forwardFinanceModal === false) {
      setApplicationFormData((prev) => ({
        ...prev,
        clientName: '',
        email: '',
        dateAppointment: '',
        locationName: '',
        time: '09:00',
        treatments: [],
        consentForms: '',
        amount: '',
      }));
      setValidationErrors((prev) => ({
        clientName: '',
        email: '',
        dateAppointment: '',
        locationName: '',
        time: '',
        treatments: [''],
        consentForms: '',
        amount: '',
      }));
      setTrtFiledsIdCount(2);
      setTreatmentFields([{ id: 1 }]);
      setDay('');
      setMonth('');
      setYear('');
    }
  }, [forwardFinanceModal]);

  useEffect(() => {
    initGetAllFinanceOfferedToClientFromDBData({
      timeFrame: "",
      startDateFromUser: "",
      endDateFromUser: "",
    },
      (res) => {
        if (!res?.data?.length) {
          setShowNoFinancePage(true)
        }
      },
      (err) => {

      }
    )
  }, [showNoFinancePage])
  const [showNoAdminApprovedPopup, setShowNoAdminApprovedPopup] = useState(false);
  const [showForwordFinanceButton, setShowForwordFinanceButton] = useState(false);
  useEffect(() => {
    initonboardingStepsForFinanceData({},
      (res) => {
        if (res?.data) {
          if (res?.data?.getOnboardingInfoForFinance?.data?.admin_approved) {
            setShowForwordFinanceButton(true);
          }
        }
      },
      (err) => { }
    )
  }, [showForwordFinanceButton]);

  return (
    <>
      {
        // getAllFinanceOfferedToClientFromDBQueryData.loading
        //  ? <FinanceOfferedToClientSkeleton/>
        //  :
        <div className="mainfinancelandingpage mainRevenulandingpage">
          <FinaceHeader />
          {getAllFinanceOfferedToClientFromDBQueryData.loading
            ? <FinanceChartSkeleton />
            :
            <div className="container mt-5">
              <div className="pt-4 col-12 row justify-content-center">
                <div className="col-lg-6 col-sm-12 col-md-8">

                  {
                    isShowStatusLabel ?
                      !isAdminApprove && !isAdminRejected
                        ? (<div className='statusAlert pendingStatus'>
                          <span className='statusAlertMessage'>Your finance application request is pending </span> <span><IoCloseSharp onClick={() => setIsShowStatusLabel(false)} /></span>
                        </div>)
                        : isAdminRejected
                          ? (<div className='statusAlert rejectStatus'>
                            <span className='statusAlertMessage'>Your finance application request is reject <Link to="#" onClick={() => setRejectedReasonModal(true)}>View reason</Link></span> <span><IoCloseSharp onClick={() => setIsShowStatusLabel(false)} /></span>
                          </div>)
                          : (<div className='statusAlert approvedStatus'>
                            <span className='statusAlertMessage'>Your finance application request is approved </span> <span><IoCloseSharp onClick={() => setIsShowStatusLabel(false)} /></span>
                          </div>)
                      : null
                  }
                  {
                    showNoFinancePage ?
                      <div className="row justify-content-center">
                        <div className="col-lg-10">
                          <div className="noFinancePageWrapper my-5">
                            <div className="noFileImg">
                              <img src={img} alt="img" />
                            </div>
                            <h5 className="noFileStepTitle">
                              Currently you have no data, <br /> Lets get you started in 2 simple steps.
                            </h5>
                            <div className="steps d-flex flex-column">
                              <div className="d-flex flex-column stepWrapper">
                                <h6 className="stepTitle">Step 1:</h6>
                                <p className="stepDesc">
                                  Forward this link to clients or customers who have requested finance.
                                </p>
                              </div>
                              <div className="d-flex flex-column stepWrapper">
                                <h6 className="stepTitle">Step 2:</h6>
                                <p className="stepDesc">
                                  We will then update your dashboard with the relevant data.
                                </p>
                              </div>
                            </div>
                            {/* {showForwordFinanceButton && */}
                            <button type="submit" onClick={handleForwardApplication} className="apply-btn  btn-primary">
                              Forward Finance Link
                            </button>
                            {/* } */}
                          </div>
                        </div>
                      </div>
                      :



                      <div className="ReevenueHeaderWrapper">
                        <div className='leftPart'>
                          <h2 className='pagenewPageTitle pt-5'>Dashboard</h2>
                          <a href="#" className="btn btn-link" onClick={handleHowToWork}>
                            How it works ?
                          </a>
                        </div>
                        <div className='DowmloadWrapper'>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleForwardApplication}
                          >
                            Forward Finance Link
                          </button>
                          <button className='downloadCsv' title='Download Csv' onClick={downloadCSV}>
                            {/* <BiExport /> */}
                            <img src={downloadcsvIcon} alt={downloadcsvIcon} />
                          </button>
                        </div>
                      </div>}
                </div >
              </div >
              {
                !showNoFinancePage &&

                <div className="col-12 row justify-content-center">
                  <div className="col-lg-6 col-sm-12 col-md-8">
                    <div className='chartDesign mb-4'>
                      <WeekDayMonthCalenderHeader
                        classes="day-center-space"
                        onButtonClick={handleButtonClick}
                        type={latestChartTypeSelection}
                      />
                      {showDateRange && (
                        <div className="selectDateRangeWrapper align-items-center">
                          <h5 style={{ marginRight: '5px' }}>Select Date Range</h5>
                          {/* <DateRangePicker onApply={handleDateRangeChange}>
                      <input type="text" value={pickerDateRange} readOnly/>
                  </DateRangePicker> */}
                          <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            onApply={handleDateRangeChange}
                            locale={{
                              format: 'DD/MM/YYYY',
                            }}
                          >
                            <button className="btn" style={{
                              backgroundColor: '#FFFFFF',
                              color: '#000',
                              borderRadius: '0px',
                              border: '1px solid #000',
                              fontWeight: '500',
                              padding: '5px 10px'
                            }}>
                              {startDate && endDate ? `${startDate.format('DD/MM/YYYY')} - ${endDate.format('DD/MM/YYYY')}` : 'Select Date Range'}
                            </button>
                          </DateRangePicker>
                        </div>
                      )}
                      {chartData && <Bar data={chartData} options={options} />}
                    </div>
                  </div>
                </div>}
            </div >}
          {/* <div className="container">
          <div className="row justify-content-center">
          
          </div>
        </div> */}

          < InfiniteScroll
            dataLength={financeOfferedToClient ? financeOfferedToClient?.length : 10}
            next={() => getFinanceOfferedToClient()}
            hasMore={financeOfferedToClientPagination.currentPage <= financeOfferedToClientPagination.totalPages}
            loader={< FinanceOfferedToClientSkeleton />}
          // totalAppointmentCount > loadedAppointmentCount
          // scrollableTarget="scrollableDiv"
          >
            {
              isFinanceOfferedLoad ? (
                <>
                  <FinanceOfferedToClientSkeleton />
                  <FinanceOfferedToClientSkeleton />
                  <FinanceOfferedToClientSkeleton />
                  <FinanceOfferedToClientSkeleton />
                </>
              ) : (
                <>
                  <div className="container">
                    <div className='pt-4 col-12 row col-12 justify-content-center'>
                      <div className="col-lg-6 col-sm-12 col-md-8">
                        {financeOfferedToClient?.map((finance, index) => (
                          <div className="finance-list" style={{
                            height: '450px !importnat',
                          }}>
                            {console.log(finance, "finance")}
                            <div className="BoxFinacesList">
                              <div className="BoxFinacesListHeader">
                                <div className="finance-status">
                                  {finance?.application_status_data.map((item, index) => {
                                    return (
                                      item?.status === 'Pre-approved' ? (
                                        <button type="button" class="btn btn-warning" style={{ backgroundColor: item?.color }}>
                                          Pre-approved
                                        </button>
                                      ) : (
                                        item?.status === 'Paid' ? (
                                          <button type="button" class="btn btn-success" style={{ backgroundColor: item?.color }}>
                                            Paid
                                          </button>
                                        ) : (
                                          item?.status === 'Abandoned' ? (
                                            <button type="button" class="btn btn-danger" style={{ backgroundColor: item?.color }}>
                                              Abandoned
                                            </button>
                                          ) : (
                                            item?.status === 'Refunded' ? (
                                              <button type="button" class="btn btn-danger" style={{ backgroundColor: item?.color }}>
                                                Refunded
                                              </button>
                                            ) : (
                                              <button type="button" class="btn btn-danger" style={{ backgroundColor: item?.color }}>
                                                Pending
                                              </button>
                                            )
                                          )
                                        )
                                      )
                                    )
                                  }
                                  )}
                                  {/* <button type="button" class="btn btn-success">
                            Paid
                          </button> */}
                                </div>
                                {finance?.application_status_data.map((item, index) => {
                                  return (
                                    item?.status !== 'Pending' && item?.status !== 'Abandoned' && (

                                      <div class="dropdown">
                                        <button
                                          class="btn bg-transparent text-dark dropdown-toggle cus-dropdown-btn-hm"
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          disabled={!(item?.status === 'Pre-approved' || item?.status === 'Paid')}
                                        >
                                          <IoIosMore
                                            style={{
                                              fontSize: '20px',
                                              marginRight: '5px',
                                              transform: 'rotate(90deg)',
                                            }}
                                          />
                                        </button>
                                        <ul
                                          class="dropdown-menu lh-base"
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          {item?.status === 'Pre-approved' && (
                                            <li>
                                              <a
                                                class="dropdown-item"
                                                href="javascript:void(0)"
                                                onClick={() => handleVerification(finance?.payl8r_order_id, finance?.retailerOrderId)}
                                              >
                                                Approve Now
                                              </a>
                                            </li>
                                          )}
                                          {item?.status === 'Paid' && (
                                            <>
                                              <li onClick={() => handleDownloadInvoice(finance)}>
                                                <a class="dropdown-item" href="javascript:void(0)">
                                                  Invoice download
                                                </a>
                                              </li>
                                              <li onClick={() => handleRefundOrder(finance)}>
                                                <a class="dropdown-item" href="javascript:void(0)">
                                                  Refund
                                                </a>
                                              </li>
                                            </>
                                          )}
                                        </ul>
                                      </div>
                                    )
                                  )
                                })}
                              </div>
                              <div className="client-name">
                                <p>{finance?.clientName} @ {finance?.clinicName}</p>
                              </div>
                              <div className="date">
                                <p> {finance?.date && moment(finance?.date).format('Do MMM YYYY')}</p>
                              </div>
                              <div className="treatment-name">
                                {
                                  finance?.treatments?.map((treatment, index) => (
                                    <p>{treatment}</p>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                </>
              )}
          </InfiniteScroll >

          {/* {isFinanceOfferedLoad ? (
          <>
            <FinanceOfferedToClientSkeleton />
            <FinanceOfferedToClientSkeleton />
            <FinanceOfferedToClientSkeleton />
            <FinanceOfferedToClientSkeleton />{' '}
          </>
        ) : (
          <>
            {financeOfferedToClient?.map((finance, index) => (
              <div className="container finance-list">
                <div className="col-12 row justify-content-center">
                  <div className="col-md-6 chat-container pb-3 pt-2">
                    <div className="d-flex justify-content-between mt-3 mb-1">
                      <div className="finance-status">
                        <button type="button" class="btn btn-success">
                          Paid
                        </button>
                      </div>
                      <div class="dropdown">
                        <button
                          class="btn bg-transparent text-dark dropdown-toggle cus-dropdown-btn-hm"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <IoIosMore
                            style={{
                              fontSize: '20px',
                              marginRight: '5px',
                              transform: 'rotate(90deg)',
                            }}
                          />
                        </button>
                        <ul
                          class="dropdown-menu lh-base"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={handleVerification}
                            >
                              Verify
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              Invoice download
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="client-name">
                      <p>Client name @ Location name</p>
                    </div>
                    <div className="date">
                      <p>15th March 2023</p>
                    </div>
                    <div className="treatment-name">
                      <p>Treatment name here.</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )} */}
          {/* <div className="container finance-list">
          <div className="col-12 row justify-content-center">
            <div className="col-md-6 chat-container pb-3 pt-2">
              <div className="d-flex justify-content-between mt-3 mb-1">
                <div className="finance-status">
                  <button type="button" class="btn btn-success">
                    Paid
                  </button>
                </div>
                <div class="dropdown">
                  <button
                    class="btn bg-transparent text-dark dropdown-toggle cus-dropdown-btn-hm"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <IoIosMore
                      style={{
                        fontSize: '20px',
                        marginRight: '5px',
                        transform: 'rotate(90deg)',
                      }}
                    />
                  </button>
                  <ul
                    class="dropdown-menu lh-base"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={handleVerification}
                      >
                        Verify
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Invoice download
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="client-name">
                <p>Client name @ Location name</p>
              </div>
              <div className="date">
                <p>15th March 2023</p>
              </div>
              <div className="treatment-name">
                <p>Treatment name here.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container finance-list">
          <div className="col-12 row justify-content-center">
            <div className="col-md-6 chat-container pb-3 pt-2">
              <div className="d-flex justify-content-between mt-3 mb-1">
                <div className="finance-status">
                  <button type="button" class="btn btn-warning">
                    Pre-approved
                  </button>
                </div>
                <div class="dropdown">
                  <button
                    class="btn bg-transparent text-dark dropdown-toggle cus-dropdown-btn-hm"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <IoIosMore
                      style={{
                        fontSize: '20px',
                        marginRight: '5px',
                        transform: 'rotate(90deg)',
                      }}
                    />
                  </button>
                  <ul
                    class="dropdown-menu lh-base"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a class="dropdown-item" href="#">
                        Verify
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Invoice download
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="client-name">
                <p>Client name @ Location name</p>
              </div>
              <div className="date">
                <p>15th March 2023</p>
              </div>
              <div className="treatment-name">
                <p>Treatment name here.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container finance-list">
          <div className="col-12 row justify-content-center">
            <div className="col-md-6 chat-container pb-3 pt-2">
              <div className="d-flex justify-content-between mt-3 mb-1">
                <div className="finance-status">
                  <button type="button" class="btn btn-danger">
                    Refunded
                  </button>
                </div>
                <div class="dropdown">
                  <button
                    class="btn bg-transparent text-dark dropdown-toggle cus-dropdown-btn-hm"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <IoIosMore
                      style={{
                        fontSize: '20px',
                        marginRight: '5px',
                        transform: 'rotate(90deg)',
                      }}
                    />
                  </button>
                  <ul
                    class="dropdown-menu lh-base"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a class="dropdown-item" href="#">
                        Verify
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Invoice download
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="client-name">
                <p>Client name @ Location name</p>
              </div>
              <div className="date">
                <p>15th March 2023</p>
              </div>
              <div className="treatment-name">
                <p>Treatment name here.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container finance-list">
          <div className="col-12 row justify-content-center">
            <div className="col-md-6 chat-container pb-3 pt-2">
              <div className="d-flex justify-content-between mt-3 mb-1">
                <div className="finance-status">
                  <button type="button" class="btn btn-success">
                    Paid
                  </button>
                </div>
                <div class="dropdown">
                  <button
                    class="btn bg-transparent text-dark dropdown-toggle cus-dropdown-btn-hm"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <IoIosMore
                      style={{
                        fontSize: '20px',
                        marginRight: '5px',
                        transform: 'rotate(90deg)',
                      }}
                    />
                  </button>
                  <ul
                    class="dropdown-menu lh-base"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={handleVerification}
                      >
                        Verify
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Invoice download
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="client-name">
                <p>Client name @ Location name</p>
              </div>
              <div className="date">
                <p>15th March 2023</p>
              </div>
              <div className="treatment-name">
                <p>Treatment name here.</p>
              </div>
            </div>
          </div>
        </div> */}
        </div >
      }
      <CustomModal
        modalOpen={addVerifyModal}
        setModalOpen={setAddVerifyModal}
        modaltitle={'Code verification.'}
        type={'review-supoort-ticket'}
        className={'CustomecodeVerification'}
        modalBody={
          <>
            <div className="review-card my-2">
              <div className="mt-3 d-flex justify-content-start">
                <p>
                  Enter the "Buy Now, Payl8r" code from your client's email.
                  After the treatment, submit the code for payment within 48
                  hours.
                </p>
              </div>
            </div>
            <div className='otpWrapper'>
              <div className="forgotWrapper">
                <label htmlFor="password" className="form-label">
                  Enter code
                </label>
                <div className='otpInputWrapper'>
                  {verificationCode.map((digit, index) => (
                    <div key={index} className="otpInput">
                      <input
                        type="text"
                        maxLength="1"
                        className="form-control"
                        value={digit}
                        placeholder=''
                        id={`otp${index}`}
                        ref={verificationCodeRef[index]}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                      />
                    </div>
                  ))}
                </div>
              </div>

              {addVerifyToBePostError && (
                <span className="text-danger">{addVerifyToBePostError}</span>
              )}
            </div>

            <div className="justify-content-center rounded-circle-border">

              {isVerifyCodeButtonLoading
                ? (<div className='btn btn-primary full-widht'><img src={loadingIcon} className={"loadingIcon"} /></div>)
                : (
                  <button
                    type="button"
                    className="btn btn-primary full-widht"
                    onClick={handleAddVerifySubmit}
                    id='submitBtn'
                    ref={submitBtnOtp}
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Submit
                  </button>
                )}
            </div>
          </>
        }
      />
      <CustomModal
        modalOpen={howItWorkModel}
        setModalOpen={setHowItWorkModel}
        modaltitle={'How it works?'}
        type={'review-supoort-ticket'}
        className={'HowitworksModel'}
        modalBody={
          <>
            <div className="my-2 how-it-works">
              <div className="modelLogo">
                <img
                  src={logo}
                  className=""
                  alt="User Profile"
                  style={{ width: '50px', height: '50px' }}
                />
              </div>
              <div className="modelContent">
                <h6>
                  Buy Now Pay Later up to 14 days no interest. Now available.
                </h6>
                <p style={{ fontWeight: '500', color: '#000000' }}>How does it work?</p>
                <p>
                  You can now buy stock and pay later with this new buy now, Pay later feature.
                </p>
                <p>
                  Once you complete the registration and unlock finance, the Buy Now, Pay later option will appear at the checkout within the pharmacy.
                </p>
                <p>
                  Once your application has been approved (only takes a few minutes) your order will be processed as usual and you will only need to pay back within 14 days. Giving you time to treat your client first.
                </p>
                <p>
                  Interest-free feature, you won’t be charged any interest for using Buy Now, Pay Later.
                </p>
                <p>
                  You can have up to three applications live at any one point.
                </p>
              </div>

              <a className="view-faq" onClick={redirectToFaqPage}>
                View FAQs
              </a>
              {/* <p>Please complete registration to use this feature.</p> */}
            </div>
            <div className="d-flex justify-content-start rounded-circle-border">
              <button type="button" className="px-3 btn btn-primary" onClick={() => { setHowItWorkModel(false) }}>
                Ok
              </button>
            </div>
          </>
        }
      />
      <CustomModal
        modalOpen={forwardFinanceModal}
        setModalOpen={setForwardFinanceModal}
        modaltitle={'Forward Finance Application.'}
        type={'forward-finance-application'}
        className={'forwardFinanceApplicationModel'}
        modalBody={
          <>
            <div className="review-card my-2">
              <div className="mt-3 d-flex justify-content-start">
                <p>
                  Please enter the clients details and the amount they would
                  like to finance. We will send them an email where they can
                  complete the application. You will be notified if there
                  application is approved.
                </p>
              </div>
            </div>
            <div className="inputAllWrapper">
              <div
                className={`d-flex flex-column ${validationErrors ? 'gap-30' : 'gap-30'
                  } error-section`}
              >
                <div className="inputErrorWrapper">
                  <TextInput
                    id="clientName"
                    label="Client Name"
                    placeholder="Client Name"
                    name="clientName"
                    value={applicationFormData?.clientName}
                    onChange={(e) => handleChange(e)}
                  />
                  {validationErrors.clientName && (
                    <span className="error">{validationErrors.clientName}</span>
                  )}
                </div>
                <div className="inputErrorWrapper">
                  <TextInput
                    label="Email"
                    placeholder="name@gmail.com"
                    value={applicationFormData?.email}
                    onChange={(e) => handleChange(e)}
                    name="email"
                  />
                  {validationErrors.email && (
                    <span className="error">{validationErrors.email}</span>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="row w-100 m-auto">
                  <div className="p-0">
                    <label className="form-label">Date of Appointment</label>
                    <div className="inputErrorWrapper">

                      <div className='DateofAppointmentErrorWrapper'>
                        <div className="DateofAppointmentWrapper">
                          <div className="DateofAppointmentGrid">
                            <TextInput
                              label=""
                              placeholder="DD"
                              maxLength={'2'}
                              id={'day'}
                              name="day"
                              type="number"
                              min="1"
                              ref={dayRef}
                              max="31"
                              value={day}
                              onChange={(e) => {
                                if (e.target.value == '00') {
                                  return false;
                                }
                                if (e.target.value > 31) {
                                  return false;
                                }
                                if (e.target.value.length >= 2) {
                                  document.getElementById('month').focus();
                                }
                                const inputValue = e.target.value;
                                if (inputValue === '') {
                                  setDateApmtError(true);
                                  setValidationErrors((prev) => ({
                                    ...prev,
                                    dateAppointment: 'Invalid Date of Appointment',
                                  }));
                                }
                                const regex = /^[0-9]{0,2}$/;
                                if (regex.test(inputValue)) {
                                  setDay(inputValue);
                                }
                              }}
                            />
                          </div>
                          <div className="DateofAppointmentGrid">
                            <TextInput
                              label=""
                              id={'month'}
                              placeholder="MM"
                              name="month"
                              min="1"
                              max="12"
                              maxLength="2"
                              type="number"
                              ref={monthRef}
                              value={month}
                              onKeyDown={(e) => {
                                if (!e.target.value && e.key === 'Backspace') {
                                  document.getElementById('day').focus();
                                }
                              }}
                              onChange={(e) => {
                                console.log('hihi', typeof e.target.value);
                                if (e.target.value == '00') {
                                  return false;
                                }
                                if (e.target.value > 12) {
                                  return false;
                                }
                                if (e.target.value >= 2) {
                                  document.getElementById('year').focus();
                                }
                                const inpValue = e?.target?.value;
                                if (inpValue == '') {
                                  setDateApmtError(true);
                                  setValidationErrors((prev) => ({
                                    ...prev,
                                    dateAppointment: 'Invalid Date of Appointment',
                                  }));
                                }
                                const regex = /^[0-9]{0,2}$/;
                                if (regex.test(inpValue)) {
                                  setMonth(inpValue);
                                }
                              }}
                            />
                          </div>
                          <div className="DateofAppointmentGrid">
                            <TextInput
                              label=""
                              placeholder="YYYY"
                              maxLength={4}
                              ref={yearRef}
                              type="number"
                              name="year"
                              id={'year'}
                              min="1900"
                              max="2024"
                              value={year}
                              onKeyDown={(e) => {
                                if (!e.target.value && e.key === 'Backspace') {
                                  document.getElementById('month').focus();
                                }
                              }}
                              onChange={(e) => {
                                console.log('key pressed', e.target.value);
                                const inputValue = e?.target?.value;
                                const regex = /^[0-9]{0,4}$/;
                                if (e.target.value == '0' || e.target.value == '1') {
                                  return false;
                                }
                                if (inputValue == '') {
                                  setDateApmtError(true);
                                  setValidationErrors((prev) => ({
                                    ...prev,
                                    dateAppointment: 'Invalid Date of Appointment',
                                  }));
                                }
                                if (regex.test(inputValue) || inputValue == '') {
                                  setYear(inputValue);
                                }
                              }}
                            />
                          </div>
                        </div>
                        {dateApmtError && validationErrors.dateAppointment && (
                          <span className="error">
                            {validationErrors.dateAppointment}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`finance-application-form d-flex flex-column ${validationErrors ? 'gap-30' : 'gap-30'
                  } error-section`}
              >
                <div className="inputErrorWrapper">
                  {/* <TextInput
                    id="time"
                    type="time"
                    label="Time"
                    placeholder="Time"
                    name="time"
                    value={applicationFormData?.time}
                    onChange={(e) => handleChange(e)}
                  /> */}
                  <CustomDropdown
                    labelName={"Time"}
                    dropdownId={"startTime"}
                    placeholdername={"Select Time"}
                    optionsArr={TIME()}
                    defaultValue={applicationFormData?.time}
                    handleSelectedValue={handleTimeSlotChange}
                  />
                  {validationErrors.time && (
                    <span className="error">{validationErrors.time}</span>
                  )}
                </div>
                <div className="inputErrorWrapper">
                  <TextInput
                    id="locationName"
                    label="Location Name"
                    placeholder="Location Name"
                    name="locationName"
                    value={applicationFormData?.locationName}
                    onChange={(e) => handleChange(e)}
                  />
                  {validationErrors.locationName && (
                    <span className="error">{validationErrors.locationName}</span>
                  )}
                </div>

                {treatmentFields.map((item, index) => (
                  <div className="d-flex justify-content-between align-items-center" key={item.id}>

                    <div className="row w-100 m-auto">
                      <div className="p-0">
                        <label className="form-label">Treatments</label>
                        <div className="inputErrorWrapper">
                          <div className="row d-flex">
                            <div className="col-md-7 col-7">
                              <TextInput
                                id="treatments"
                                label=""
                                placeholder="Treatments"
                                name="treatments"
                                value={applicationFormData.treatments[index]}
                                onChange={(e) => handleChange(e, index)}
                              />
                              {validationErrors.treatments[index] && (
                                <span className="error">
                                  {validationErrors.treatments[index]}
                                </span>
                              )}
                            </div>
                            <div className="col-md-5 col-5">
                              {
                                index > 0 ? (
                                  <button
                                    type="button"
                                    className="btn btn-primary add-treatments"
                                    onClick={() => removeTreatmentFiled(item.id, index)}
                                  >
                                    <img src={DeleteTreatment} />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-primary add-treatments"
                                    onClick={addTreatmentFiled}
                                  >
                                    Add treatment
                                  </button>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                ))}
                <div className="inputErrorWrapper">
                  <TextInput
                    id="consentForms"
                    label="Consent Forms"
                    placeholder="Consent Forms"
                    name="consentForms"
                    value={applicationFormData?.consentForms}
                    onChange={(e) => handleChange(e)}
                  />
                  {validationErrors.consentForms && (
                    <span className="error">{validationErrors.consentForms}</span>
                  )}
                </div>
                <div className="inputErrorWrapper">
                  <TextInput
                    id="amount"
                    label="Amount"
                    placeholder="£ Amount"
                    type="number"
                    name="amount"
                    value={applicationFormData?.amount}
                    onChange={(e) => handleChange(e)}
                  />
                  {validationErrors.amount && (
                    <span className="error">{validationErrors.amount}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="justify-content-center rounded-circle-border">
              {addVerifyToBePostError && (
                <span className="text-danger">{addVerifyToBePostError}</span>
              )}
              <button
                type="button"
                className="btn btn-primary full-widht"
                onClick={handleFinanceApplicationSubmit}
              >
                Submit
              </button>
            </div>
          </>
        }
      />
      <CustomModal
        modalOpen={rejectedReasonModal}
        setModalOpen={setRejectedReasonModal}
        modaltitle={'Rejection reason.'}
        type={'review-supoort-ticket'}
        className={'CustomecodeVerification'}
        modalBody={
          <>
            <div className="review-card my-2">
              <div className="mt-3 d-flex justify-content-start">
                <p>
                  {rejectedReason}
                </p>
              </div>
            </div>
          </>
        }
      />
      {
        <CustomModal
          modalOpen={showNoAdminApprovedPopup}
          setModalOpen={setShowNoAdminApprovedPopup}
          modaltitle={'Warning'}
          type={'forward-finance-application'}
          className={'forwardFinanceApplicationModel'}
          modalBody={
            <>
              Your request has not been approved by admin yet!
            </>
          }
        />
      }
    </>
  );
};

export default RevenueDashboardComponent;
