import React, { useState } from 'react'
import "./WeekDayMonthCalenderHeader.scss"

const WeekDayMonthCalenderHeader = ({ classes, onButtonClick, dataDayType, type }) => {
    const [selectedItem, setSelectedItem] = useState(dataDayType || type || 'D'); // Default selection


    const handleClick = (item) => {
        setSelectedItem(item);
        onButtonClick(item)
    };

    const DateData = ["Custom", "D", "W", "M"]
    return (
        <>
            <div className={`day-container ${classes}`}>
                {
                    DateData.map((item) => {
                        return (
                            <>
                                <div className={` xyz `}
                                >
                                    <h2 key={item}
                                        className={selectedItem === item ? 'selected' : ''}
                                        onClick={() => handleClick(item)}>{item}</h2>
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </>
    )
}

export default WeekDayMonthCalenderHeader