
import "./client-profile-details.scss";
import { ReactComponent as NextOfKinIcon } from "../../images/icons/Person_circle.svg";
import { ReactComponent as MedicalIcon } from "../../images/icons/Medical_circle.svg";
import { ReactComponent as IdentityIcon } from "../../images/icons/Identity_circle.svg";
import GreenTickIcon from "../../images/icons/ComplianceTime2.svg";
import ErrorTickIcon from "../../images/icons/ComplianceTime.svg";
import { ReactComponent as RightArrow } from "../../images/icons/chevron-left.svg";
import timeIcon from "../../images/icons/ComplianceTime.svg";
import timeIcon2 from "../../images/icons/ComplianceTime2.svg";
import React, { useEffect, useRef, useState } from "react";
import StepButton from "../common/StepButton";
import CustomModal from "../modal/CustomModal";
import OffCanvasModal from "../modal/OffCanvasModal/OffCanvasModal";
import NextOfKin from "./NextOfKin";
import SkeletonLoader from "../common/Skeleton/SkeletonLoader";
import loaderIcon from "../../images/icons/loader/loader.gif"
import { useDispatch, useSelector } from "react-redux";
import { GetClientDetails } from "../redux/actions/client/GetClientDetails";
import { GetClientStats } from "../redux/actions/client/GetClientStat";
import moment from "moment";
import { capitalizeFirstLetter } from "../../utils/initials";
import Avatar from "react-avatar";
import { DeleteClientMutation } from "../redux/actions/client/DeleteClient";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { getClientProfileRoute, getEditClientRoute, getSearchClientRoute } from "../../Routes";
import { GetNextOfKinDetails } from "../redux/actions/client/GetNextOfKinDetails";
import { GetIdentityDocumentDataQuery } from "../redux/actions/client/GetIdentityData";
import NavHeader from "../common/NavHeader/NavHeader";
import { CLEAR_CLIENT_DETAILS, CLEAR_CLIENT_LIST_DATA } from "../redux/constants";
import showToast from "../common/CustomToast/CustomToast";
import { CreateIdentityDocument } from "../redux/actions/client/CreateIdentity";
import { DeleteIdentityDocument } from "../redux/actions/client/DeleteIdentity";
import { updateClientIdentityStatus } from "../redux/actions/client/ClientIdentityStatus";
import ClientPasswordPopup from "./passwordpopup/ClientPasswordPopup";
import "./client-profile-details.scss";
import { pounds } from "../../utils/general";


const ViewClientProfile = ({ setIsClientPeofile }) => {
    //Navigate 
    const navigate = useNavigate()
    //Set NextofKin Data State
    const [nextOfKinData, setNextOfKinData] = useState(null);
    //get client id state from  router
    const [isDelete, setIsDelete] = React.useState(false);
    const [isNextOfKin, setIsNextOfKin] = React.useState(false);
    const [isNextOfKinAdded, setIsNextOfKinAdded] = React.useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [commonLoder, setCommonLoder] = useState(null)
    //Query
    const { clientStatsQueryData, initGetClientStats } = GetClientStats()
    const { clientDetailsQueryData, initGetClientDetails } = GetClientDetails()
    const { identityQueryData, initGetIdentity } = GetIdentityDocumentDataQuery()
    //Mutation
    const { deleteClientMutationData, initiateDeleteClient } = DeleteClientMutation()
    const { initGetNextOfKinDetails, nextOfKinDetailsQueryData } = GetNextOfKinDetails()
    //Reducer
    const clientDetails = useSelector(state => state.clientDetailsReducer?.clientDetails)
    const clientStats = useSelector(state => state.clientStatsReducer?.clientStats)
    const clientIdentityStatus = useSelector((state) => state?.clientDetailsReducer?.clientIdentityStatus)
    //Add Identity Document Mutation
    const { createIdentityMutationData, initiateCreateIdentity } = CreateIdentityDocument();
    //Delete Identity Document Mutation
    const { initiateDeleteIdentity, deleteIdentityMutationData } = DeleteIdentityDocument()
    const fileInputRef = useRef(null);
    const [fileExist, setFileExist] = useState(false)
    const [singleFile, setSingleFile] = useState({
        clientId: null,
        documentId: "",
        documentUrl: "",
        documentName: "",
        time: ""
    })
    const [isDeleteIdentity, setIsDeleteIdentity] = React.useState(false);
    const [clickTitle, setClickTitle] = useState("")
    const [isPasswordOpen, setIsPasswordOpen] = useState(false);

    const dispatch = useDispatch()
    const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)
    const { clientId } = useParams();


    //CallThe Api
    useEffect(() => {
        //client profile details API
        initGetClientDetails({
            clientId: parseInt(clientId)
        })
        //Client State AP
        initGetClientStats({
            clientId: parseInt(clientId)
        })
        //Client Next of Kin Details API
        initGetNextOfKinDetails({
            clientId: parseInt(clientId)
        }, (res) => {
            if (res.success) {
                setNextOfKinData(res.data)
            } else {
                setNextOfKinData(null)
            }
        }, (err) => {
            setNextOfKinData(null)
            toast.error(err.message)
        })

        initGetIdentity({
            clientId: parseInt(clientId)
        }, (res) => {
            if (res.success) {
                console.log("identity data", res?.data?.documents[0])
                if (res?.data !== null) {
                    setFileExist(true)
                    setSingleFile({
                        documentId: res?.data?.documents[0]?.documentId,
                        documentUrl: res?.data?.documents[0]?.document,
                        documentName: res?.data?.documents[0]?.documentName,
                    })
                }
            } else {
                setFileExist(false)
                setSingleFile(null)
                showToast("error", res.message, "", false)
            }
        }, (err) => {
            console.log("identity error", err)
        })

    }, [clientId])


    const openIdentityDocument = () => {
        if (fileInputRef.current !== null) {
            fileInputRef.current.click()
        }
        // //For Identity Preview
        // if (clientDetails?.identityAdded) {
        //   setIsPreview(true)
        //   initGetIdentity({
        //     clientId: parseInt(clientId)
        //   }, (res) => {
        //     if (res.success) {
        //       console.log("identity data", res?.data?.documents[0])
        //       setSingleFile({
        //         documentId: res?.data?.documents[0]?.documentId,
        //         documentUrl: res?.data?.documents[0]?.document,
        //         documentName: res?.data?.documents[0]?.documentName,
        //       })
        //     } else {
        //       showToast("error", res.message, "", false)
        //     }
        //   }, (err) => {
        //     console.log("identity error", err)
        //   })
        // }

    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: "#EEEFF3",
                color: "#000",
                width: "48px",
                height: "48px",
                fonts: "Inter",
                fontSize: "16px",
            },
            children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`.toLocaleUpperCase(),
        };
    }

    //Delete Client handle Click
    const handleDeleteClient = () => {
        initiateDeleteClient({
            clientId: parseInt(clientId)
        }, (resDeleteClient) => {
            if (resDeleteClient.success) {
                showToast("success", resDeleteClient.message, "", false)
                setIsDelete(false)
                dispatch({
                    type: CLEAR_CLIENT_LIST_DATA
                })
                navigate(getSearchClientRoute())
            } else {
                showToast("error", resDeleteClient.message, "", false)
                setIsDelete(true)
            }
        })
    }

    const goBackSearchClient = () => {
        // setIsClientPeofile(false)
        navigate(getClientProfileRoute(clientId))
    }

    const handleEditNavigate = () => {
        if (UserProfileData?.usr_use_password_for_directory) {
            setIsPasswordOpen(true)
            setClickTitle("EditClick")
        }
        else {
            navigate(getEditClientRoute(clientId))
        }
    }


    //Goto Medical Details Page
    const redirectMedicalDetails = () => {

        if (UserProfileData?.usr_use_password_for_directory) {
            setIsPasswordOpen(true)
            setClickTitle("MedicalClick")
        }
        else {
            window.open(`${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`, "_blank")
        }
    }

    console.log("client detail", clientDetails, clientStats)

    const handleFileUpload = (event) => {
        const selectedFiles = event.target.files;

        // Check if any file is selected
        if (!selectedFiles || selectedFiles.length === 0) {
            // User canceled file selection
            return;
        }

        const file = event.target.files[0]
        // const newFiles = [...files];
        const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"]

        if (!allowedFileTypes.includes(file.type)) {
            showToast("error", "Only Selected Valid Document", "", false)
            return

        }
        initiateCreateIdentity({
            clientId: parseInt(clientId),
            identityDocuments: selectedFiles
        }, (res) => {
            if (res?.success) {

                setFileExist(true)
                res?.data?.documents?.map((item) => {
                    setSingleFile({
                        clientId: parseInt(item?.clientId),
                        documentId: item?.documentId,
                        documentUrl: item?.documentUrl,
                        documentName: item?.documentName,
                        time: item?.time
                    })
                })
                if (clientDetailsQueryData?.loading) {
                    setCommonLoder(false)
                    initGetClientDetails({
                        clientId: parseInt(clientId)
                    })
                    dispatch(updateClientIdentityStatus(true))
                    dispatch({
                        type: CLEAR_CLIENT_DETAILS,
                    })

                }
                console.log("client details data", clientDetailsQueryData)
                showToast("success", res?.message, "", false)
            } else {
                showToast("error", res?.message, "", false)
                setFileExist(false)
            }
            console.log("identity res", res)
        }, (err) => {
            setFileExist(false)
            console.log("identity error", err)

        })
    }

    const handleDeleteIdentity = (documentId, clientId) => {
        initiateDeleteIdentity({
            clientId: parseInt(clientId),
            documentId: documentId
        }, (res) => {
            console.log("delete res akash", res)
            if (res?.success) {
                setIsPreview(false);
                setIsDeleteIdentity(false);
                setSingleFile(null);
                setFileExist(false)
                initGetClientDetails({
                    clientId: parseInt(clientId)
                })
                dispatch({
                    type: CLEAR_CLIENT_DETAILS,
                })
                showToast("success", res?.message, "", false)
            } else {
                showToast("error", res?.message, "", false)
            }
        }, (err) => {
            console.log("error on delete identity", err)
            toast.error(err)
        })
    }

    const nextOfKinHandler = () => {

        if (UserProfileData?.usr_use_password_for_directory) {
            setIsPasswordOpen(true)
            setClickTitle("NextOfKinClick")
        }
        else {
            setIsNextOfKin(true)
        }
    }


    const identityClickHandler = () => {
        if (UserProfileData?.usr_use_password_for_directory == 1) {
            setIsPasswordOpen(true)
            setClickTitle("IdentityClick")
        }
        else {
            fileExist ? setIsPreview(true) : openIdentityDocument()
        }
    }

    const redirectCallback = () => {
        if (clickTitle == "EditClick") {
            navigate(getEditClientRoute(clientId))
        }
        else if (clickTitle == "NextOfKinClick") {
            setIsNextOfKin(true)
        }
        else if (clickTitle == "IdentityClick") {
            fileExist ? setIsPreview(true) : openIdentityDocument()
        }
        else if (clickTitle == "MedicalClick") {
            window.open(`${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`, "_blank")
        }
    }

    return (
            <div className="Main-clientDetails">
               {clientDetails && clientDetailsQueryData.loading ? <SkeletonLoader type={"client-profile"} /> :
                    <div className="client-main-details-container customeDetailsWrappper MainClientProfile">
                        <NavHeader applyPadding={false} isBackWithButton={true} isGray={false} btnLabel={"Edit"} handleOnBack={() => goBackSearchClient()} onClickCallback={() => handleEditNavigate()} />
                            <div className="client-name-section">
                                <div className="left-name-section">
                                    <h1>{`${capitalizeFirstLetter(clientDetails?.clientFirstName)} ${capitalizeFirstLetter(clientDetails?.clientLastName)}`}</h1>
                                    <p>Client for {clientDetails?.clientFrom}</p>
                                </div>
                                <div className="right-name-section">
                                    <div className="avatar-icon">
                                        <Avatar src={clientDetails?.clientProfileImage} fgColor="#000" className="avtar-profile" maxInitials={2} color="#EEEFF3" name={`${clientDetails?.clientFirstName} ${clientDetails?.clientLastName}`} size="48" round={true} />
                                        {/* <img src={BlueTick} alt="blue-tick" /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="client-content-section">
                                <div className="label-header">
                                    <h1>Status</h1>
                                    <hr />
                                    <div className="text-content">
                                        <h1>Appointments</h1>
                                        <p>{clientStats?.appointments}</p>
                                    </div>
                                    <div className="text-content">
                                        <h1>Treatments</h1>
                                        <p>{clientStats?.treatments}</p>
                                    </div>
                                    <div className="text-content">
                                        <h1> Total spent</h1>
                                        <p>{pounds.format(clientStats?.totalSpent)}</p>
                                    </div>
                                    <div className="text-content">
                                        <h1> No shows</h1>
                                        <p>{clientStats?.noShows}</p>
                                    </div>
                                </div>
                                {/* about section */}
                                <div className="label-header">
                                    <h1>About</h1>
                                    <hr />
                                    <div className="text-content-about">
                                        <h1>Birthday</h1>
                                        <p>{clientDetails?.dob ? moment(clientDetails?.dob).format('DD MMM YYYY') : "N/A"}</p>
                                    </div>
                                    <div className="text-content-about">
                                        <h1>Gender</h1>
                                        <p>{clientDetails?.gender != "" ? clientDetails?.gender : "N/A"}</p>
                                    </div>
                                </div>
                                {/* contact section */}
                                <div className="label-header">
                                    <h1>Contact</h1>
                                    <hr />
                                    <div className="text-content-about">
                                        <h1>Email address</h1>
                                        <p>{clientDetails?.clientEmail}</p>
                                    </div>
                                    <div className="text-content-about">
                                        <h1>Phone number</h1>
                                        <p>{clientDetails?.readableContact ? clientDetails?.readableContact : "N/A"}</p>
                                    </div>
                                    <div className="text-content-about">
                                        <h1>Home address</h1>
                                        <p style={{ margin: 0 }}>{clientDetails?.houseNo}</p>
                                        <p style={{ margin: 0 }}>{clientDetails?.street}</p>
                                        <p style={{ margin: 0 }}>{clientDetails?.city}</p>
                                        <p style={{ margin: 0 }}>{clientDetails?.postCode}</p>
                                    </div>
                                </div>
                                {/* Personal info section */}
                                <div className="label-header">
                                    <h1>Personal info</h1>
                                    <hr />
                                    <div className="text-content-personal" onClick={() => nextOfKinHandler()}>
                                        <div className="left-personal-section">
                                            <div className="status-icon">
                                                <NextOfKinIcon />
                                                <img src={clientDetails?.nextOfKinAdded ? GreenTickIcon : ErrorTickIcon} alt="" />
                                            </div>
                                            <h1>Next of kin</h1>
                                        </div>
                                        <div className="right-personal-section">
                                            <RightArrow />
                                        </div>
                                    </div>
                                    <div className="text-content-personal" onClick={() => identityClickHandler()}>
                                        <div className="left-personal-section">
                                            <div className="status-icon">
                                                <IdentityIcon />
                                                {
                                                    createIdentityMutationData?.loading ?
                                                        <img style={{ borderRadius: "50%" }} className="time-icon" width={20} src={loaderIcon} />
                                                        :
                                                        <img
                                                            className="time-icon"
                                                            src={
                                                                singleFile?.documentId
                                                                    ? timeIcon2
                                                                    : timeIcon
                                                            }
                                                        />

                                                }
                                                {console.log("sibglee", singleFile)}
                                                {/* <img src={clientIdentityStatus? GreenTickIcon : ErrorTickIcon} alt="" /> */}
                                            </div>
                                            <h1>Identity</h1>
                                        </div>
                                        <div className="right-personal-section">
                                            <RightArrow />
                                        </div>
                                    </div>
                                    <div className="text-content-personal" onClick={redirectMedicalDetails}>
                                        <div className="left-personal-section">
                                            <div className="status-icon">
                                                <MedicalIcon />
                                                <img src={clientDetails?.medicalDetailsAdded ? GreenTickIcon : ErrorTickIcon} alt="" />
                                            </div>
                                            <h1>Medical details</h1>
                                        </div>
                                        <div className="right-personal-section">
                                            <RightArrow />
                                        </div>
                                    </div>
                                    <div className="delete-button">
                                        <StepButton onClick={() => setIsDelete(true)} label={"Delete"} />
                                    </div>
                                </div>

                            </div>
                        <CustomModal
                            type={"common-width"}
                            className={"ConfirmationDeleteModal"}
                            modalBody={
                                <>
                                    <div className="delete-modal-body">
                                        <h1>Are you sure?</h1>
                                        <p>Are you sure you want to delete this client? This action cannot be reversed.</p>
                                        <div className="w-100">
                                            <div className="delete-button-modal">
                                                <StepButton red={true} isLoading={deleteClientMutationData?.loading} label={"Delete"} onClick={handleDeleteClient} />
                                            </div>
                                            <div className="cancle-button-modal">
                                                <StepButton gray={true} onClick={() => setIsDelete(false)} label={"Cancel"} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            modalOpen={isDelete}
                            setModalOpen={setIsDelete}
                            setIsOpenMobileCalendar={setIsDelete}
                        />

                        {/* delete identity confirmation popup */}
                        <CustomModal
                            type={"common-width"}
                            className={"ConfirmationDeleteModal"}
                            modalBody={
                                <>
                                    <div className="delete-modal-body">
                                        <h1>Are you sure?</h1>
                                        <p>you want to delete this identity? This action cannot be reversed.</p>
                                        <div className="w-100">
                                            <div className="delete-button-modal">
                                                <StepButton red={true} isLoading={deleteClientMutationData?.loading} label={"Delete"} onClick={() => handleDeleteIdentity(singleFile?.documentId, clientId)} />
                                            </div>
                                            <div className="cancle-button-modal">
                                                <StepButton gray={true} onClick={() => {
                                                    setIsDeleteIdentity(false)
                                                    setIsPreview(true)
                                                }
                                                } label={"Cancel"} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            modalOpen={isDeleteIdentity}
                            setModalOpen={setIsDeleteIdentity}
                            setIsOpenMobileCalendar={setIsDeleteIdentity}
                        />
                        <OffCanvasModal
                            isOpen={isNextOfKin}
                            customHeader={true}
                            isCloseRight={false}
                            isBackOnly={true}
                            className={'CanvasNextOfKin'}
                            onHide={() => { setIsNextOfKin(false) }}
                            body={<NextOfKin clientId={clientId} setNextOfKinData={setNextOfKinData} nextOfKinData={nextOfKinData} fromProfile={true} setIsNextOfKin={setIsNextOfKin} setIsNextOfKinAdded={setIsNextOfKinAdded} />}
                        />
                        {/* For Identity Preview */}
                        <CustomModal
                            type={"common-width"}
                            className={"previewImgModel"}
                            modalBody={<div className="preview-document-modal">
                                <h1>{singleFile?.documentName?.slice(-25)}</h1>
                                <img src={singleFile?.documentUrl} alt="preview" style={{ width: "500", height: "300", objectFit: "cover" }} />
                                <div className="delete-preview-button" >  <StepButton label={"Delete"} onClick={() => {
                                    setIsPreview(false)
                                    setIsDeleteIdentity(true)
                                }} redWithBorder={true} /></div>
                            </div>}
                            setModalOpen={setIsPreview}
                            modalOpen={isPreview}
                            setIsOpenMobileCalendar={setIsPreview}

                        />

                        {/* password popup */}
                        <CustomModal
                            modalOpen={isPasswordOpen}
                            setModalOpen={setIsPasswordOpen}
                            type={"common-width"}
                            modaltitle={"Please enter password"}
                            className={'CustomePasswordModel'}
                            modaltitleCSS={"discount-title"}
                            modalBody={
                                <>
                                    <ClientPasswordPopup redirectCallback={redirectCallback} setIsPasswordOpen={setIsPasswordOpen} />
                                </>
                            }
                        />

                        <input
                            ref={fileInputRef}
                            type="file"
                            accept=".pdf, .doc, .docx, .jpg, .jpeg, .png" // Add acceptable file formats
                            onChange={handleFileUpload}
                            style={{ display: "none" }} // Hide the default file input style
                            id="fileInput" // Use a label with htmlFor to trigger file input
                        />


                    </div>
                }
            </div>    
            );
};

export default ViewClientProfile;
