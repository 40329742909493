import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


// ========================GET TEXT MESSAGE BALANCE DETAILS========================
const GET_TEXT_MESSAGE_BALANCE_DETAILS_QUERY = gql`
query GetTextMessageBalanceDetails( $appointmentDate: String!) {
    getTextMessageBalanceDetails( appointmentDate: $appointmentDate) {
      success
      message
      data {
        userId
        requiredBalance
        availableBalance
        creditAvailable
      }
    }
  }
`;

export const GetTextMessageBalanceDetailsAction = () => {
    const dispatch = useDispatch();
    const [
        GetTextMessageBalanceDetailsResponseFunction,
        getTextMessageBalanceDetailsResponseData,
    ] = useLazyQuery(GET_TEXT_MESSAGE_BALANCE_DETAILS_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getTextMessageBalanceDetails: responseData }) => {
            if (responseData.success) {
                // dispatch({
                //     type: GET_RATING_REVIEWS_CONST,
                //     payload: responseData
                // })
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getTextMessageBalanceDetailsQueryData = getTextMessageBalanceDetailsResponseData; //variable

    const initGetTextMessageBalanceDetailsData = (data, successCallBack, errorCallback) => {
        GetTextMessageBalanceDetailsResponseFunction({
            variables: data,
        }).then((res) => {
            if (res?.data?.getTextMessageBalanceDetails?.success)
                successCallBack(res.data.getTextMessageBalanceDetails.data)
        });
    };

    return { getTextMessageBalanceDetailsQueryData, initGetTextMessageBalanceDetailsData };
};


// ========================GET STRIPE ACCOUNT AND FINANCE REQUEST STATUS========================
const GET_STRIPE_ACCOUNT_AND_FINANCE_REQUEST_STATUS_QUERY = gql`
query GetStripeAccountAndFinanceRequestStatus {
    getStripeAccountAndFinanceRequestStatus {
      success
      message
      data {
        userId
        financeRequestCompleted
        financeRequestStatus
        clinicId
        clinicName
        clinicAvailableDates
        userHasDisabledFinanceOption
        locationNotCreated
        treatmentNotCreated
        paymentAccountSetup
      }
    }
  }
`;

export const GetStripeAccountAndFinanceRequestStatusAction = () => {
    const dispatch = useDispatch();
    const [
        GetStripeAccountAndFinanceRequestStatusResponseFunction,
        getStripeAccountAndFinanceRequestStatusResponseData,
    ] = useLazyQuery(GET_STRIPE_ACCOUNT_AND_FINANCE_REQUEST_STATUS_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getStripeAccountAndFinanceRequestStatus: responseData }) => {
            if (responseData.success) {
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getStripeAccountAndFinanceRequestStatusQueryData = getStripeAccountAndFinanceRequestStatusResponseData; //variable

    const initGetStripeAccountAndFinanceRequestStatusData = (data, successCallback) => {
        GetStripeAccountAndFinanceRequestStatusResponseFunction({
            variables: data,
        }).then((res) => {
            if (res?.data?.getStripeAccountAndFinanceRequestStatus?.success)
                successCallback(res.data.getStripeAccountAndFinanceRequestStatus.data)
        });
    };

    return { getStripeAccountAndFinanceRequestStatusQueryData, initGetStripeAccountAndFinanceRequestStatusData };
};


// ========================GET FINANCE STATUS========================
const GET_FINANCE_STATUS_QUERY = gql`
query GetFinanceStatus($userId: Int!) {
    getFinanceStatus(userId: $userId) {
      success
      message
      data {
        userId
        financeStatus
        infoText
      }
    }
  }
`;

export const GetFinanceStatusAction = () => {
    const dispatch = useDispatch();
    const [
        GetFinanceStatusResponseFunction,
        getFinanceStatusResponseData,
    ] = useLazyQuery(GET_FINANCE_STATUS_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getFinanceStatus: responseData }) => {
            if (responseData.success) {
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getFinanceStatusQueryData = getFinanceStatusResponseData; //variable

    const initGetFinanceStatusData = (data, successCallback) => {
        GetFinanceStatusResponseFunction({
            variables: data,
        }).then((res) => {
            if (res?.data?.getFinanceStatus?.success)
                successCallback(res.data.getFinanceStatus.data)
        });
    };

    return { getFinanceStatusQueryData, initGetFinanceStatusData };
};

// ========================PURCHASE SMS BALANCE========================

const PURCHASE_SMS_BALANCE_MUTATION = gql`
mutation PurchaseSMSBalance($amount: Int) {
    purchaseSMSBalance(amount: $amount) {
      success
      message
      data {
        intentId
      }
    }
  }
 `;


export const PurchaseSMSBalanceAction = () => {
    const dispatch = useDispatch();
    const [
        PurchaseSMSBalanceResponseFunction,
        purchaseSMSBalanceResponseData,
    ] = useMutation(PURCHASE_SMS_BALANCE_MUTATION)

    //this will be called from components
    const purchaseSMSBalanceQueryData = purchaseSMSBalanceResponseData; //variable

    const initPurchaseSMSBalanceData = (data, successCallback, errorCallback) => {
        PurchaseSMSBalanceResponseFunction({
            variables: data,
        }).then(({ data: { purchaseSMSBalance: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
                //  toast.success(responseData.message)
            }
            else {
                if (errorCallback)
                    errorCallback()
                toast.error(responseData.message)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
            toast.error("something went wrong!!!")
        })
    }

    return { purchaseSMSBalanceQueryData, initPurchaseSMSBalanceData };
};


// ========================UPDATE SMS BALANCE PURCHASE PAYMENT========================

const UPDATE_SMS_BALANCE_PURCHASE_PAYMENT_MUTATION = gql`
mutation UpdateSMSBalancePurchasePayment($amount: Int, $intentId: String) {
    updateSMSBalancePurchasePayment(amount: $amount, intentId: $intentId) {
      success
      message
    }
  }
 `;


export const UpdateSMSBalancePurchasePaymentAction = () => {
    const dispatch = useDispatch();
    const [
        UpdateSMSBalancePurchasePaymentResponseFunction,
        updateSMSBalancePurchasePaymentResponseData,
    ] = useMutation(UPDATE_SMS_BALANCE_PURCHASE_PAYMENT_MUTATION)

    //this will be called from components
    const updateSMSBalancePurchasePaymentQueryData = updateSMSBalancePurchasePaymentResponseData; //variable

    const initUpdateSMSBalancePurchasePaymentData = (data, successCallback, errorCallback) => {
        UpdateSMSBalancePurchasePaymentResponseFunction({
            variables: data,
        }).then(({ data: { updateSMSBalancePurchasePayment: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
                //  toast.success(responseData.message)
            }
            else {
                if (errorCallback)
                    errorCallback()
                toast.error(responseData.message)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
            toast.error("something went wrong!!!")
        })
    }

    return { updateSMSBalancePurchasePaymentQueryData, initUpdateSMSBalancePurchasePaymentData };
};




