import React, { useEffect, useState } from "react";
// import AccountDetails from "./AccountDetails/AccountDetails";
import "./shopSettings.scss";
// import AddressBook from "./AddressBook/AddressBook";
// import BusinessShop from "./Shop/BusinessShop";
import { ReactComponent as OrdersIcon } from "../../../../images/sprint6/Orders.svg";
import { ReactComponent as AddressbookIcon } from "../../../../images/sprint6/Addressbook.svg";
import { ReactComponent as PharmaciessettingsIcon } from "../../../../images/sprint6/shop.svg";
import { ReactComponent as RightArrowIcon } from "../../../../images/sprint6/rightIcon.svg";
import BusinessNavigation from "../../../common/businessNavigation/BusinessNavigation";
import OrderShop from "./orderShop/orderShop";
import AddressBook from "../AddressBook/AddressBook";
import { getManageAccountPage, getPrescriberRegistrationPage } from "../../../../Routes";
// import BusinessShopDetails from "./Shop/BusinessShopDetail/BusinessShopDetails";
import { useNavigate } from "react-router";
import { webBaseUrl } from "../../../../config";
import SidebarComponent from "../../../sidebar";
import BusinessHeader from "../../../businessHeader";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import logo from "../../../../images/icons/faces-logo-black.svg";
import closeIcon from "../../../../images/Icon example.svg";
import Cookies from "js-cookie";


const ShopSettings = () => {
  const [selectedTab, setSelectedTab] = useState("Account details");
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const [showSidebar, setShowSidebar] = useState(true);
  const [activeSidebar, setActiveSidebar] = useState(false);
  // const [isAuth, setIsAuth] = useState(false);
  let isMobile = false;
  if (width <= 991) {
    isMobile = true;
  }
  else {
    isMobile = false;
  }
  useEffect(() => {
    isMobile ? setShowSidebar(false) : setShowSidebar(true);
  }, [isMobile]);

  // useEffect(() => {
  //   console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
  //   Cookies.get("faces_access_token") ? setIsAuth(true) : setIsAuth(false);
  //   console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
  // });
  // const location = useLocation();

  // const renderTabContent = () => {
  //   if (step == 1) {
  //     return <OrderShop setStep={setStep} />;
  //   }
  //   if (step == 2) {
  //     return <AddressBook setParentStep={setStep} />;
  //   }
  // };

  return (
    <div className="MainBusinessProfile">
      <BusinessHeader
        // openReveviewPopup={setOpenReviewPopup}
        openMobileMenu={() => {
          setShowSidebar(!showSidebar);
          setActiveSidebar(true);
          document.body.classList.add('sidebarOpen');
        }}
        isMobile={isMobile}
      />
      <div className="container-fluid">

        {/* {step > 0 ?
          renderTabContent()
          :
        
        } */}
        <div className="sidebarDesignUpdateSprint6">
          {isMobile ? (
            <div
              className={
                showSidebar ? " MobileSidebar  ActiveSidebar" : "MobileSidebar"
              }
            >
              {activeSidebar && (
                <>
                  <div className="overlaySidebar"></div>
                  <div className="sidebar">
                    <div className="sidebarLogoWrapper">
                      <div className="logoSidebar">
                        <img src={logo} alt="logo" />
                      </div>
                      <div
                        className="sidebarClose"
                        onClick={() => {
                          setShowSidebar(false);
                          document.body.classList.remove('sidebarOpen');
                        }}
                      >
                        <img src={closeIcon} alt="closeIcon" />
                      </div>
                    </div>
                    <SidebarComponent />
                  </div>
                </>
              )}
            </div>
          ) : (

            <div className="sidebarPart">
              <div className="sidebar">
                <SidebarComponent />
              </div>
            </div>
          )}
          <div className="MainContentPart">
              <div className="MainShopSettingsPages">
                <div className="Main684WidthSet">
                  <BusinessNavigation
                    isColumn={true}
                    title={"Shop settings"}
                    desc={"Personalise your experience below"}
                    onBackClick={() => { navigate(getManageAccountPage()) }}
                  />

                  <div className="linkGrid">
                    {/* setStep(1) */}
                    <div className="linkItem" onClick={() => { window.location.href = webBaseUrl + "/order/list" }}>
                      <div className="linkImg">
                        <OrdersIcon />
                      </div>
                      <div className="linkContent">
                        <h3>Orders</h3>
                      </div>
                      <div className="RightIcon">
                        <RightArrowIcon />
                      </div>
                    </div>

                    <div className="linkItem" onClick={() => {
                      // setStep(2) 
                      window.location.href = webBaseUrl + "/user/update-profile?active-tab=address"
                    }}>
                      <div className="linkImg">
                        <AddressbookIcon />
                      </div>
                      <div className="linkContent">
                        <h3>My Addresses</h3>
                      </div>
                      <div className="RightIcon">
                        <RightArrowIcon />
                      </div>
                    </div>

                    <div className="linkItem" onClick={() => { navigate(getPrescriberRegistrationPage(), { state: { from: 'shop_Pharmacies_settings' } }) }}>
                      <div className="linkImg">
                        <PharmaciessettingsIcon />
                      </div>
                      <div className="linkContent">
                        <h3>Pharmacies settings</h3>
                      </div>
                      <div className="RightIcon">
                        <RightArrowIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      );
};

      export default ShopSettings;
