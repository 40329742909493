import { useDispatch } from "react-redux";
import { GET_RYFT_PAYMENT_TOKEN_FORWARD_CONSENT_FORM } from "../../constants/forward-consent-form/ForwardConsentFormConst";
import { gql, useLazyQuery } from "@apollo/client";

//2 ryft token
const GET_RYFT_PAYMENT_TOKEN_FORWARD_CONSENT_FORM_QUERY = gql`
query GetRyftPaymentTokenForwardConsentForm($cfrId: Int, $cfrToken: String, $paymentType: Int, $redirectUrl: String, $selectedPayment: getRyftPaymentTokenForwardConsentFormPaymentMethods) {
    getRyftPaymentTokenForwardConsentForm(cfrId: $cfrId, cfrToken: $cfrToken, payment_type: $paymentType, redirectURL: $redirectUrl, selectedPayment: $selectedPayment) {
      success
      message
      data {
        payment_session
        accountId
      }
    }
  }
`;
export const GetRyftPaymentTokenForwardConsentFormQuery = () => {
  const dispatch = useDispatch();
  const [GetRyftTokenForwardConsentForm, getRyftTokenForwardConsentFormResponseData] =
    useLazyQuery(GET_RYFT_PAYMENT_TOKEN_FORWARD_CONSENT_FORM_QUERY, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getRyftPaymentTokenForwardConsentForm: responseData }) => {
        dispatch({
          type: GET_RYFT_PAYMENT_TOKEN_FORWARD_CONSENT_FORM,
          payload: responseData.data
        })
        if (responseData.success) {
          // toast.success(responseData?.message)
        } else {
          // toast.error(responseData?.message)
        }
      },
    });
  //this will be called from components
  const getRyftTokenForwardConsentFormQueryData = getRyftTokenForwardConsentFormResponseData; //variable

  const initRyftTokenForwardConsentFormToken = (data, successCallback, errorCallback) => {
    GetRyftTokenForwardConsentForm({
      variables: data,
    }).then((res) => {
      successCallback(res)
    }).catch((e) => {
      errorCallback(e)
    });
  };

  return { getRyftTokenForwardConsentFormQueryData, initRyftTokenForwardConsentFormToken };
};