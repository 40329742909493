import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_ACCESS_OF_CLIENT_DIRECTORY_QUERY = gql`
 query CheckAbleToAccessClientDirectoryOrNot {
  checkAbleToAccessClientDirectoryOrNot {
    success
    message
    data {
      canAccessClientDetial
    }
  }
}
`;

export const GetAccessClientDirectoryOrNot = () => {
  const [
    getAccessOfClientDirectoryResponseFunction,
    getAccessOfClientDirectoryResponseData,
  ] = useLazyQuery(GET_ACCESS_OF_CLIENT_DIRECTORY_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });

  const getAccessOfClientDirectoryData = getAccessOfClientDirectoryResponseData;

  const initGetAccessOfClientDirectoryData = (
    data,
    successCallback,
    errorCallback
  ) => {
    getAccessOfClientDirectoryResponseFunction({
      variables: data,
    })
      .then((responseData) => {
        console.log(responseData,"reesss")
        if (responseData?.data?.checkAbleToAccessClientDirectoryOrNot?.success) {
          successCallback(responseData?.data?.checkAbleToAccessClientDirectoryOrNot );
        } else {
          errorCallback(responseData?.data?.checkAbleToAccessClientDirectoryOrNot );
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  return {
    getAccessOfClientDirectoryData,
    initGetAccessOfClientDirectoryData,
  };
};
