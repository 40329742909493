import React from "react";
import './facesBusinessFooter.scss';
import {ReactComponent as PhoneIcon } from '../../images/Businessfooter/PhoneIcon.svg';
import {ReactComponent as WhatsappIcon } from '../../images/Businessfooter/whatsapp.svg';
import {ReactComponent as EmailIcon } from '../../images/Businessfooter/EmailIcon.svg';
import {ReactComponent as AddressIcon }  from '../../images/Businessfooter/AddressIcon.svg';
import {ReactComponent as FacesLogo }  from '../../images/facesBusiness.svg';
import {ReactComponent as AppStore }  from '../../images/Businessfooter/AppStore.svg';
import {ReactComponent as GooglePlay }  from '../../images/Businessfooter/googlePlay.svg';
import { useNavigate } from "react-router";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import { config, frontendURL, webBaseUrl } from "../../config";
import SupportChatWidget from "../support-chat-widget/SupportChatWidget";

const FacesBusinessFooter = () => {

    const navigate = useNavigate();

    const ServicesData = [
        {
            id: '01',
            linkName: 'Search Clinics',
            url: webBaseUrl + "/clinics"
        },
        {
            id: '02',
            linkName: 'Search Treatments',
            url: webBaseUrl + "/locations"
        },
        {
            id: '03',
            linkName: 'Training Courses',
            url: webBaseUrl + "/training/frontend-list"
        },
        {
            id: '04',
            linkName: 'Card Machine Companies',
            url: webBaseUrl + "/card-reader/company"
        },
        {
            id: '05',
            linkName: 'Insurance Companies',
            url: frontendURL + "/beauty-aesthetic-insurance-companies"
        },

         {
            id: '06',
            linkName: 'Waste Management Services',
            url: webBaseUrl + "/sharp-collections"
        },

        {
            id: '07',
            linkName: 'CPD Services',
            url: webBaseUrl + "/cpd-services"
        },
        
        {
            id: '08',
            linkName: 'Complications Consultant',
            url: webBaseUrl + "/aesthetic-help-line"
        },

        {
            id: '09',
            linkName: 'Free Website Builder',
            url: frontendURL + "/website-builder"
        },
        
        {
            id: '10',
            linkName: 'Finance Options',
            url: frontendURL + "/finance"
        },

        {
            id: '11',
            linkName: 'Get Aesthetics Insurance',
            url: frontendURL + "/aesthetics-insurance"
        },

        {
            id: '12',
            linkName: 'Get Medical Malpractice Insurance',
            url: frontendURL + "/medical-malpractice-insurance" 
        },

        {
            id: '13',
            linkName: 'Accountancy Services',
            url: frontendURL + "/accountancy-services"
        },

        {
            id: '14',
            linkName: 'Find A Prescriber',
            url: frontendURL + "/find-a-prescriber-near-me"
        },
        {
            id: '15',
            linkName: 'Prescription hair care',
            url: frontendURL + "/prescription-haircare"
        },

        
    ]

    const aboutUsData = [
        {
            id: '01',
            linkName: 'Consent Forms',
            url: webBaseUrl + "/consent-forms"
        },
        {
            id: '02',
            linkName: 'Aftercare Forms',
            url: webBaseUrl + "/aftercare"
        },
        {
            id: '03',
            linkName: 'GDPR',
            url: webBaseUrl + "/gdpr"
        },
        {
            id: '04',
            linkName: 'HIPAA Compliance',
            url: webBaseUrl + "/hippa-privacy-policy"
        },
        {
            id: '05',
            linkName: 'Advice, Tips & News',
            url: webBaseUrl + "/blog"
        },
    ]

    const helpData = [
        {
            id: '01',
            linkName: ' Shipping & Returns',
            url: webBaseUrl + "/pages/shipping-and-return" 
        },
        {
            id: '02',
            linkName: 'Help & Support',
            url: webBaseUrl + "/faqs"
        },
        {
            id: '03',
            linkName: 'General FAQs',
            url: webBaseUrl + "/shop-faqs"
        },
        {
            id: '04',
            linkName: 'Seller’s FAQs',
            url: webBaseUrl + "/seller-faqs"
        },
        {
            id: '05',
            linkName: 'Say Hello',
            url: webBaseUrl + "/say-hello"
        },
    ]

    const sellData = [
        {
            id: '01',
            linkName: ' Sell on Faces',
            url: webBaseUrl + "/say-hello"
        },
        {
            id: '02',
            linkName: 'Seller Registration',
            url: webBaseUrl + "/seller-register-request"
        },
        {
            id: '03',
            linkName: 'Seller List',
            url: webBaseUrl + "/shop/sellers"
        },
    ]

    return(
        <>
            <div className="FacesBusinessFooterMain">
                <div className="BottomAssets" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3 col-sm-12">
                            <div className="FooterAboutContent">
                                <div className="FooterLogo">
                                    <FacesLogo />
                                </div>
                                <p>We’re an MHRA Licensed Broker</p>
                            </div>

                            <div className="FooterSocialWrapper">
                                <h5>Contact</h5>
                                <ul>
                                    <li onClick={() => { window.location.href = `tel:+01785558018`; }}>
                                        <span className="FooterIcon"><PhoneIcon /></span> 01785 558018
                                    </li>
                                    <li onClick={() => {window.open(`https://api.whatsapp.com/send?phone=07999875249&text=Hello%20there!`)}}> 
                                        <span className="FooterIcon"><WhatsappIcon /></span> 07999 875249
                                    </li>
                                    <li onClick={() => { window.location.href = `mailto:+support@facesconsent.com`; }}><span className="FooterIcon"><EmailIcon /></span>     support@facesconsent.com</li>
                                    <li><span className="FooterIcon"><AddressIcon /></span> Faces Consent Ltd, <br /> Staffordshire Technology Park, <br /> Centurion House, 27 Anson Ct, <br /> Stafford ST18 0GB, UK</li>
                                </ul>
                            </div>

                        
                        </div>
                        <div className="col-md-6 col-lg-3 col-sm-12">
                            <div className="FooterLinks">
                                <h5>Services</h5>
                                <ul>
                                    {ServicesData?.map((item, index)=>{
                                        return(
                                            <li key={index} onClick={()=>{
                                                window.location.href = `${item?.url}`
                                            }}>{item?.linkName}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-sm-12">
                            <div className="FooterLinks">
                                <h5>About Us</h5>
                                <ul>
                                    {aboutUsData?.map((item, index)=>{
                                        return(
                                            <li key={index} onClick={()=>{
                                                window.location.href = `${item?.url}`
                                            }}>{item?.linkName}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="FooterLinks mt-26">
                                <h5>Help</h5>
                                <ul>
                                    {helpData?.map((item, index)=>{
                                        return(
                                            <li key={index} onClick={()=>{
                                                window.location.href = `${item?.url}`
                                            }}>{item?.linkName}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-sm-12">
                        <div className="FooterLinks">
                                <h5>Sell</h5>
                                <ul>
                                    {sellData?.map((item, index)=>{
                                        return(
                                            <li key={index} onClick={()=>{
                                                window.location.href = `${item?.url}`
                                            }}>{item?.linkName}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="newsLatterSecWrapper">
                        <div className="row">
                            <div className="col-md-6 col-lg-3 col-sm-12">
                                <div className="newsLatterWrapper">
                                    <div className="newsLatterSecTitleWrapper">
                                        <h5>Get 7 Clients in 7 days!</h5>
                                        <p>Sign-up to our hints and tips of how to get more clients</p>
                                    </div>

                                    <form>
                                        <input className="newslatterInput" type='email' placeholder="Email" />
                                            <button type="submit" className="newslatterSubmit">Count me in!</button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-9 col-sm-12">
                                <div className="AppstoreLogo">
                                    <div className="AppstoreItem" 
                                    onClick={()=>{
                                        window.open(`${config.APP_STORE_LINK}`, '_blank');}}>
                                        <AppStore />
                                    </div>
                                    <div className="AppstoreItem" 
                                    onClick={()=>{window.open(`${config.PLAY_STORE_LINK}`, '_blank');}}>
                                        <GooglePlay />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="copyright">
                        <div className="leftSide">
                            <ul>
                                <li onClick={() => {window.open(`https://www.facebook.com/facesconsent`, '_blank')}}><FaFacebookF /></li>
                                <li onClick={() => {window.open(`https://x.com/facesconsent`, '_blank')}}><FaXTwitter /></li>
                                {/* <li onClick={() => {window.open(`https://www.instagram.com/facesconsentapp/?hl=en`, '_blank')}}><FaInstagram /></li> */}
                                <li onClick={() => {window.open(`https://www.youtube.com/channel/UCtEDJqm7T9YBVBtsd6OhCyA`, '_blank')}}><FaYoutube /></li>
                                <li onClick={() => {window.open(`https://www.linkedin.com/company/facesconsent`, '_blank')}}><FaLinkedinIn /></li>
                            </ul>
                            <p>© {(new Date()).getFullYear()} Faces Consent. All rights reserved.</p>
                        </div>

                        <div className="RightSide">
                            <ul>
                                <li onClick={()=>{window.location.href = webBaseUrl + "/privacy-terms"}}>Privacy Policy</li>
                                <li onClick={()=>{window.location.href = webBaseUrl + "/terms-and-conditions"}}>Terms & Conditions</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <SupportChatWidget />
        </>
    )
}

export default FacesBusinessFooter;
