
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const SAVE_PRESCRIBER_BUSINESS_DETAILS_MUTATION = gql`
mutation SavePrescriberBusinessDetails($businessType: Int, $occupations: String, $address: String, $houseNo: String, $streetName: String, $address2: String, $city: String, $state: String, $county: String, $zipcode: String, $latitude: String, $longitude: String, $businessName: String, $vatNumber: String, $isTrainingAcademy: Boolean, $trainingAcademyName: String, $professionalPin: String, $pinExpiry: String) {
  savePrescriberBusinessDetails(business_type: $businessType, occupations: $occupations, address: $address, house_no: $houseNo, street_name: $streetName, address2: $address2, city: $city, state: $state, county: $county, zipcode: $zipcode, latitude: $latitude, longitude: $longitude, business_name: $businessName, vat_number: $vatNumber, is_training_academy: $isTrainingAcademy, training_academy_name: $trainingAcademyName, professional_pin: $professionalPin, pin_expiry: $pinExpiry) {
    success
    message
  }
}
  
`;
export const SavePrescriberBusinessDetails = () => {
    const [savePrescriberBusinessDetails, { data: savePrescriberBusinessDetailsResponseData }] =
      useMutation(SAVE_PRESCRIBER_BUSINESS_DETAILS_MUTATION);
    const dispatch = useDispatch();
  
    const initiateSavePrescriberBusinessDetails = (variables, successCallback, errorCallback) => {
      savePrescriberBusinessDetails({ variables })
        .then(({ data }) => {
          if (data.savePrescriberBusinessDetails.success) {
            successCallback(data.savePrescriberBusinessDetails);
          } else {
            errorCallback(data.savePrescriberBusinessDetails);
          }
        })
        .catch((error) => {
          // Handle error
          errorCallback(error);
        });
    };
  
    return { initiateSavePrescriberBusinessDetails, savePrescriberBusinessDetailsResponseData };
  };

  const GET_PRESCRIBER_BUSINESS_DETAILS_QUERY = gql`
  query GetPrescriberBusinessDetails {
    getPrescriberBusinessDetails {
      success
      message
      data {
        business_type
        occupations
        address
        house_no
        street_name
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        business_name
        vat_number
        training_academy_name
        professional_pin
        pin_expiry
        is_training_academy
      }
    }
  }

  
`;

export const GetPrescriberBusinessDetails = () => {
    const dispatch = useDispatch();
    const [getPrescriberBusinessResponseFunction, { loading, error, data }] = useLazyQuery(GET_PRESCRIBER_BUSINESS_DETAILS_QUERY, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: (response) => {
        // Handle completed response here if needed
      },
    });
  
    const initGetPrescriberBusinessDetails = () => {
      getPrescriberBusinessResponseFunction();
    };
  
    return { loading, error, data, initGetPrescriberBusinessDetails };
  };
  
 



