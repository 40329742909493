import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_FINANCE_IDENTITY=gql`
query GetIdentityDocumentForFinance {
    getIdentityDocumentForFinance {
      success
      message
      data {
        id
        identity_name
        identity_url
      }
    }
  }
`;

  export const GetFinanceIdentityAction=()=>{
    const [getFinanceIdentityResponseFunction,
    getFinanceIdentityResponseData]=useLazyQuery(GET_FINANCE_IDENTITY,{
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted:({getIdentityDocumentForFinance:responseData})=>{
            if(responseData.success){
                console.log("res",responseData?.message)
            }else{
                console.log("error",responseData?.message)

            }
        }
    })

    const getFinanceIdentityQueryData=getFinanceIdentityResponseData;
    const initGetFinanceIdentityData=(data)=>{
        getFinanceIdentityResponseFunction({
            variables:data
        })
    };

    return { getFinanceIdentityQueryData, initGetFinanceIdentityData}
  }