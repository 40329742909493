import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_Hair_Products_Details, SET_ERROR_PAGE } from "../../constants/hair-prescription-product/HairProductConts";


const GET_Hair_Product_DETAILS_QUERY = gql`
query GetHairProducts($slug: String!) {
    getHairProducts(slug: $slug) {
      success
      message
      data {
        id
        name
        image
        description
        price
        threemonthPrice
        lowPrice
      }
    }
  }
`;

export const GetHairProductsDetails = () => {
    const dispatch = useDispatch();
    const [getHairProductsDetails, hairProductsDetailsData] = useLazyQuery(
        GET_Hair_Product_DETAILS_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getHairProducts: responseData }) => {
                if (responseData.success) {
                    dispatch({
                        type: GET_Hair_Products_Details,
                        payload: responseData.data
                    });
                } else {
                    dispatch({
                        type: SET_ERROR_PAGE,
                        payload: true
                    });
                }
            },
        }
    );

    const hairProductDetailsQueryData = hairProductsDetailsData;

    const initGetHairProductsDetails = (slug) => {
        getHairProductsDetails({
            variables: slug,
        });
    };

    return { initGetHairProductsDetails, hairProductDetailsQueryData };
};
