import { gql, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

const ADD_FORWARD_FINANCE_DETAILS_MUTATION = gql`
  mutation AddForwardFinanceDetail(
    $clientName: String
    $clientEmail: String
    $dateOfAppointment: String
    $time: String
    $locationName: String
    $consentForms: [String]
    $amount: String
    $clinicName: String
    $treatments: [String]
  ) {
    addForwardFinanceDetail(
      clientName: $clientName
      clientEmail: $clientEmail
      dateOfAppointment: $dateOfAppointment
      time: $time
      locationName: $locationName
      consentForms: $consentForms
      amount: $amount
      clinicName: $clinicName
      treatments: $treatments
    ) {
      success
      message
    }
  }
`;

export const AddForwardFinanceDetail = () => {
  // const dispatch = useDispatch();
  const [
    AddForwardFinanceDetailResponseFunction,
    addForwardFinanceDetailResponseData,
  ] = useMutation(ADD_FORWARD_FINANCE_DETAILS_MUTATION);

  //this will be called from components
  const addForwardFinanceDetailQueryData = addForwardFinanceDetailResponseData; //variable

  const initAddForwardFinanceDetailData = (
    data,
    successCallback,
    errorCallback
  ) => {
    AddForwardFinanceDetailResponseFunction({
      variables: data,
    })
      // .then(({ data: { AddForwardFinanceDetail: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        if (responseData?.data?.addForwardFinanceDetail?.success) {
          // dispatch(...)
          successCallback(responseData);
          // toast.success(responseData.message)
        } else {
          errorCallback(responseData);
          // toast.error(responseData.message)
        }
      })
      .catch((err) => {
        errorCallback(err);
        toast.error('something went wrong!!!');
      });
  };

  return {
    addForwardFinanceDetailQueryData,
    initAddForwardFinanceDetailData,
  };
};
