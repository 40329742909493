import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import { config, frontendURL, webBaseUrl } from '../../../config';
import { Button } from 'bootstrap';
import StepButton from '../../common/StepButton';
import { GetDirectDepositRyftPaymentToken, getRyftPaymentToken, GetVerifyLinkForDirectDiposit } from '../../redux/actions/direct-deposit/directDepositRyftPayment';
import { CompleteDirectDepositPaymentBooking } from '../../redux/actions/direct-deposit/DirectDepositFormBooking';
import { ReactComponent as FacesLogo } from "../../../images/Faces_logo.svg";

export default function DirectDepositeThankYou () {

    return (
        <div className="">
        <div className="customeloginHeader">
        <div className="container">
          <div className="headerlogoBtnWrapper">
            <div
              className="logoWrapper"
              onClick={()=>{ window.location.href = webBaseUrl}}
            >
              <FacesLogo />
            </div>
            <div className="loginButton d-flex align-items-center">
            </div>
          </div>
        </div>
      </div>
        <div className="container d-flex justify-content-center align-items-center vh-100">
          <div className="thankyouPageWrapper" style={{
            textAlign: 'center'
          }}>
            <h1 className="thankyouPageTitle">Thank You</h1>
            <p className="thankyouPageDesc">
            Thank you for your payment! The practitioner has been notified.
            </p>
          </div>
        </div>
      </div>
    )
}