import React, { useEffect, useRef, useState } from "react";
import "./treatment-list.scss";
import SearchIcon from "../../../images/client/search_icon.svg";
import { ReactComponent as CloseIcon } from "../../../images/close_icon.svg";
import StepButton from "../../common/StepButton";
import gsap from "gsap";
import { GetTreatmentsForClinic } from "../../redux/actions/treatmentList/getTreatmentsForClinic";
import { useDispatch, useSelector } from "react-redux";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";
import CustomCheckbox from "../../custom-components/custom-checkbox/CustomCheckbox";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce, set } from "lodash";
import { CLEAR_TREATMENT_FOR_CLINIC } from "../../redux/constants/treatment-list-const/TreatmentListConst";
import { capitalizeFirstLetter } from "../../../utils/initials";
import { GetAfterCareFormList, GetConsentFormList } from "../../redux/actions/formsTemplate/getFormTemplateList";

const TreatmentList = ({ deposit, setDeposit, setDiscount, setSubtotalSave, setIsClose,
  setSelectedTreatments,
  selectedTreatments,
  clinicId,
  setIsTreatmentList,
  setTimeRange, timeRange,
  selectedAfterCareData,
  selectedConsentFormData,
  setSelectedAfterCareData,
  setSelectedConsentFormData,
  depositPaid
}) => {
  const [searchText, setSearchText] = useState("");
  const [newTreatment, setNewTreatment] = useState([]);
  const [isSearchedTriggered, setIsSearchedTriggered] = useState(false);
  const dispatch = useDispatch()
  const [tempDeposit, setTempDeposit] = useState(deposit)
  const [tempDiscount, setTempDiscount] = useState(false)
  //Reducer for get treatment Data from Selected Clinic
  // const treatmentListData = useSelector(state => state?.treatmentsListForClinicReducer?.treatmentListForClinic?.treatments)
  const { clinicTreatmentsQueryData, initGetTreatmentsForClinic } = GetTreatmentsForClinic()



  // console.log("treatment list reducer", treatmentListData)
  // Set initial state when component mounts or when selectedTreatments prop changes

  //Get Client List from Reducer
  const { treatmentListForClinic } = useSelector(state => state?.treatmentsListForClinicReducer)
  const { totalTreatmentCount } = useSelector(state => state?.treatmentsListForClinicReducer)
  const { loadedTreatmentCount } = useSelector(state => state?.treatmentsListForClinicReducer)
  const { treatmentListForClinicPage } = useSelector(state => state?.treatmentsListForClinicReducer)
  const { emptyTreatmentList } = useSelector(state => state?.treatmentsListForClinicReducer)

  //Consent and AfterCare Query Funcation
  const { consentFormListQueryData, initGetConsentFormList } = GetConsentFormList()
  const { afterCareFormListQueryData, initGetAfterCareFormList } = GetAfterCareFormList()


  //Call TreatmentListing Api
  useEffect(() => {
    console.log("liss", deposit)
  }, [deposit])

  const initiateTreatmentListCall = (refreshList = false) => {
    let newPage = treatmentListForClinicPage + 1;
    if (refreshList) {
      newPage = 1;

    } else if (totalTreatmentCount <= loadedTreatmentCount) {
      return;
    }
    initGetTreatmentsForClinic({
      clinicId: clinicId,
      search: searchText,
      limit: 10,
      page: newPage
    })
  };

  useEffect(() => {
    if (clinicId)
      if (selectedTreatments.length == 0 || treatmentListForClinic?.length == 0) {
        dispatch({
          type: CLEAR_TREATMENT_FOR_CLINIC
        })
        initiateTreatmentListCall(true)

      } else {
        // initiateTreatmentListCall(false)
      }
  }, [clinicId])

  useEffect(() => {
    setNewTreatment(selectedTreatments);
  }, [selectedTreatments])


  const handleSearchText = (e) => {
    setSearchText(e.target.value);
    setIsSearchedTriggered(true);

  };

  //Handle Treatment Change
  //SET DESPOSIT AMOUNT
  const handleCheckboxChange = (treatmentId, treatmentData) => {
    // Update selectedTreatments based on checkbox changes

    let isChecked = newTreatment.findIndex((data) => data.treatmentId === treatmentId);


    let tempDepositTotal = newTreatment.reduce((a, b) => a + (parseFloat(b.depositAmount) || 0), 0)
    let tempPriceTotal = newTreatment.reduce((a, b) => a + (parseFloat(b.treatmentPrice) || 0), 0) + parseFloat(treatmentData?.treatmentPrice)

    console.log("ischecked", isChecked)
    if (isChecked == -1) {
      let tobeaddedTreatmentDeposit = tempDepositTotal + treatmentData?.depositAmount
      if (selectedTreatments.length == 0) {
        setTempDeposit(tobeaddedTreatmentDeposit)
      } else {
        if ((tempPriceTotal - (treatmentData?.depositAmount + tempDeposit)) < 0) {
          setTempDeposit(treatmentData?.depositAmount + tempDeposit - (deposit - selectedTreatments.reduce((a, b) => a + (parseFloat(b.depositAmount) || 0), 0)))
        } else {
          setTempDeposit(treatmentData?.depositAmount + tempDeposit)
        }
        console.log(tempPriceTotal - (treatmentData?.depositAmount + tempDeposit), "calculateDueAmount")
      }
      setNewTreatment([...newTreatment, treatmentData]);
    } else {
      console.log(tempDeposit, "tempDeposit", treatmentData?.depositAmount)
      if ((tempDeposit - treatmentData?.depositAmount) < 0) {

        setTempDeposit(tempDepositTotal - treatmentData?.depositAmount)
      } else {
        setTempDeposit(tempDeposit - treatmentData?.depositAmount)
      }
      setTempDiscount(true)
      setNewTreatment(newTreatment.filter((data) => data.treatmentId !== treatmentId));
    }
  };

  const handleSelectButtonClick = () => {

    setSubtotalSave(newTreatment.reduce((a, b) => a + (parseFloat(b.treatmentPrice) || 0), 0))
    let timeDuration = newTreatment.reduce((a, b) => a + (parseFloat(b.treatmentTime) || 0), 0)
    console.log(timeDuration, 'timeDuration', newTreatment)
    if (timeRange.start != "") {
      const moment = require('moment');


      const parsedTime = moment(timeRange.start, 'HH:mm');

      const newTime = parsedTime.add(timeDuration, 'minutes');

      // console.log(newTime.format('HH:mm'));

      setTimeRange({ ...timeRange, end: newTime.format('HH:mm') })
    }
    if (depositPaid) {
      let treatmentSubtotal = newTreatment.reduce((a, b) => a + (parseFloat(b.treatmentPrice) || 0), 0)
      if (treatmentSubtotal - deposit < 0) {
        setSubtotalSave(deposit)
      }
      setDeposit(deposit)
    } else {
      setDeposit(tempDeposit)
    }
    if (tempDiscount) {
      setDiscount(0)
    }
    setSelectedTreatments(newTreatment)
    setIsTreatmentList(true)
    //CONSENT FORM AND AFTERCARE FORM

    //CONSENT FORM
    let oldConsentIds = selectedConsentFormData.map(i => i.consentFormId).flat()
    let tempConsentIds = newTreatment.map(i => i.consentFomrs).flat()
    let oldTreatmentConsentIds = selectedTreatments.map(i => i.consentFomrs).flat()
    let deletedTreatmentConsentIds = oldTreatmentConsentIds.filter(i => !tempConsentIds.includes(i))
    let idsWhichShouldDeleteAtTheEnd = deletedTreatmentConsentIds.filter(i => oldConsentIds.includes(i))

    console.log(oldConsentIds, "ohooooo", tempConsentIds, idsWhichShouldDeleteAtTheEnd, "oldTreatmentConsentIds", oldTreatmentConsentIds)

    let selectedConsentIds = selectedConsentFormData.map(i => i.consentFormId)
    let tobeSendInpayloadConsentIds = tempConsentIds.flat().filter(i => !selectedConsentIds.includes(i))
    console.log(tempConsentIds.flat(), "tempConsentIds", selectedConsentIds, tobeSendInpayloadConsentIds)
    if (tobeSendInpayloadConsentIds?.length != 0) {
      let consentForm = [].concat(selectedConsentFormData)
      initGetConsentFormList({
        itemIds: tobeSendInpayloadConsentIds
      }, (res) => {
        if (res.success) {
          res?.data?.consentForms?.map((item) => {
            consentForm.push({
              consentFormId: item?.consentFormId,
              consentFormName: item?.consentFormName,
            })
          })
        }
        let finalArray = consentForm.filter((item) => !idsWhichShouldDeleteAtTheEnd.includes(item.consentFormId))
        setSelectedConsentFormData(finalArray)
      })
    } else {
      let finalArray = selectedConsentFormData.filter((item) => !idsWhichShouldDeleteAtTheEnd.includes(item.consentFormId))
      console.log(finalArray, "finalArray")
      setSelectedConsentFormData(finalArray)
    }

    //AFTERCARE FORM
    let oldAfterCareIds = selectedAfterCareData.map(i => i.aftercareDocId).flat()
    let tempAfterCareIds = newTreatment.map(i => i.aftercareDocs).flat()
    let oldTreatmentAfterCareIds = selectedTreatments.map(i => i.aftercareDocs).flat()
    let deletedTreatmentAfterCareIds = oldTreatmentAfterCareIds.filter(i => !tempAfterCareIds.includes(i))
    let idsWhichShouldDeleteAtTheEndAfterCare = deletedTreatmentAfterCareIds.filter(i => oldAfterCareIds.includes(i))

    // console.log(oldAfterCareIds, "ohooooo AKSHAY", tempAfterCareIds, idsWhichShouldDeleteAtTheEndAfterCare)

    let selectedAfterCareIds = selectedAfterCareData.map(i => i.aftercareDocId)
    let tobeSendInpayloadAfterCareIds = tempAfterCareIds.flat().filter(i => !selectedAfterCareIds.includes(i))
    console.log(tempAfterCareIds.flat(), "tempAfterCareIds", selectedAfterCareIds, tobeSendInpayloadAfterCareIds)
    if (tobeSendInpayloadAfterCareIds?.length != 0) {
      let afterCareForm = [].concat(selectedAfterCareData)
      initGetAfterCareFormList({
        itemIds: tobeSendInpayloadAfterCareIds
      }, (res) => {
        if (res.success) {
          res?.data?.aftercareDocs?.map((item) => {
            afterCareForm.push({
              aftercareDocId: item?.aftercareDocId,
              aftercareDocName: item?.aftercareDocName,
            })
          })
        }
        let finalArray = afterCareForm.filter((item) => !idsWhichShouldDeleteAtTheEndAfterCare.includes(item.aftercareDocId))
        setSelectedAfterCareData(finalArray)
      })
    }
    else {
      let finalArray = selectedAfterCareData.filter((item) => !idsWhichShouldDeleteAtTheEndAfterCare.includes(item.aftercareDocId))
      console.log(finalArray, "finalArray")
      setSelectedAfterCareData(finalArray)
    }




    if (searchText) {
      setSearchText("");
      dispatch({
        type: CLEAR_TREATMENT_FOR_CLINIC
      })
    }

    setIsClose(false)
  }

  //DEBOUNCE FOR SEARCH
  // Debounced API call function
  const debouncedApiCall = debounce((query) => {
    // Make the API call using the search query
    // Replace this with your own API call logic
    dispatch({
      type: CLEAR_TREATMENT_FOR_CLINIC
    })
    initiateTreatmentListCall(true)
  }, 500); // Debounce delay in milliseconds (e.g., 500ms)

  useEffect(() => {
    if ((searchText.length == 0 && isSearchedTriggered) || searchText.length >= 3) {
      // Call the debounced API function with the search query
      debouncedApiCall(searchText);

      // Cleanup function to cancel any pending debounced calls
      return () => {
        debouncedApiCall.cancel();
      };
    }
  }, [searchText]);
  return (
    <div className="treatment-list-main-container">
      <div className="sticky-header">
        <div className="close-btn text-end">
          <CloseIcon className="close-icon-animation" onClick={() => {
            if (selectedTreatments.length == 0 || searchText.length != 0) {
              dispatch({
                type: CLEAR_TREATMENT_FOR_CLINIC
              })
            }
            setIsClose(false)
          }} />
        </div>
        <h1>Choose a service</h1>
        <div className="search-container">
          <input type="text" placeholder="Search service" value={searchText} onChange={handleSearchText} />
          <img src={SearchIcon} alt="search-icon" className="search-icon-img" />
          {searchText?.length > 0 &&
            <CloseIcon className="cursor-pointer search-close-icon" onClick={() => { setSearchText("") }} />
          }
        </div>
      </div>
      {/* PAGINATION */}

      <div style={{ height: "70vh", width: "100%", overflowX: "scroll" }} id="scrollable-div">
        <InfiniteScroll
          dataLength={loadedTreatmentCount}
          hasMore={totalTreatmentCount > loadedTreatmentCount}
          next={() => initiateTreatmentListCall()}
          scrollableTarget="scrollable-div"
        >
          <div className="treatments-list-main-container">

            {
              (treatmentListForClinic?.length != 0 && !(emptyTreatmentList)) ?
                treatmentListForClinic?.map((item, index) => (
                  <div className="treatment-list-container m-2 cursor-pointer" key={index}
                    onClick={() => (document.getElementById(item.treatmentId)).addEventListener(('change'), handleCheckboxChange(item?.treatmentId, item))}
                  >
                    <p>{capitalizeFirstLetter(item?.treatmentName)}</p>
                    <CustomCheckbox
                      className="treatment-list-checkbox"
                      id={item.treatmentId}
                      name={item?.treatmentName}
                      checked={newTreatment.some((data) => data.treatmentId === item?.treatmentId)} />
                  </div>
                )) : clinicTreatmentsQueryData?.loading ? null :

                  <div className="no-data-availa-class">
                    <p>No Services Available!</p>
                  </div>
            }
            {
              clinicTreatmentsQueryData?.loading ?
                <SkeletonLoader type="common-list" />
                : null
            }
          </div>

        </InfiniteScroll>
      </div>

      <div className="sticky-footer">
        <StepButton disabled={newTreatment.length === 0} labelClassName={"mb-0"} className={"mt-0"} label={"Select"} blue={true} onClick={() => handleSelectButtonClick()} />
      </div>
    </div>
  );
};

export default TreatmentList;
