import React, { useEffect, useState } from 'react';
import '../../../components/pages/appointment/after-care-form/appointment-form-main.scss';
import closeIcon from '../../../images/close_icon.svg';
import { useNavigate, useParams } from 'react-router';
import beforeafterimage from '../../../images/beforeUpload.svg';
import CustomModal from '../../modal/CustomModal';
import showToast from '../../common/CustomToast/CustomToast';
import { AfterCareFormDetails } from '../../redux/actions/walk-in-form/walkInAddFormsList';
import Skeleton from 'react-loading-skeleton';
import PdfImage from '../../../images/pdf-image.svg';
import SkeletonLoader from '../../common/Skeleton/SkeletonLoader';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useSearchParams } from 'react-router-dom';

const PreviewAfterCareForm = () => {
    const { aftercareDocId } = useParams();
    const parsedFromId = +aftercareDocId;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

  const initialActiveTab = searchParams.get('tab');
    const [uploadedImg, setUploadedImg] = useState(null);
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
    const [formName, setFormName] = useState('');

    const { initAfterCareFormDetails, aftercareFormDetailsQueryData } = AfterCareFormDetails();

    useEffect(() => {
        initAfterCareFormDetails(
            { aftercareId: parsedFromId },
            (data) => {
                console.log('alsdfjaivaladfkj', data);
                setFormName(data.aftercareFormName);
                const fetchedFileurl = data.fileUrl;
                if (fetchedFileurl && fetchedFileurl.length > 0) {
                    const fileName = fetchedFileurl.slice(fetchedFileurl.lastIndexOf('/') + 1);
                    const fileExt = fileName.slice(fileName.lastIndexOf('.') + 1);

                    let uploadedSrc = fetchedFileurl,
                        imageType = 'image';
                    if (fileExt.includes('pdf') || fileExt.includes('doc')) {
                        uploadedSrc = PdfImage;
                        if(fileExt.includes('doc')) {
                            imageType = 'doc';
                        }
                        else imageType = 'pdf';
                    }
                    setUploadedImg({
                        src: uploadedSrc,
                        name: fileName,
                        type: imageType,
                        pdfUrl: data.fileUrl,
                    });
                }
            },
            (err) => {
                showToast('error', err.message);
            }
        );
    }, []);

    const handleOnClose = () => {
        // navigate('/manage-forms');
        navigate(initialActiveTab ? `/manage-forms?tab=${initialActiveTab}` : "/manage-forms");
    };

    return (
        <>
            <div className="form-mode-indicator">
                <p>View as a client</p>
            </div>
                <div className="header-buttn custom-header-padding">
                    <img
                        className="cursor-pointer"
                        onClick={handleOnClose}
                        src={closeIcon}
                        width={40}
                        height={40}
                        alt="img"
                    />
                </div>
                {aftercareFormDetailsQueryData.loading || formName.length === 0 
                ?
                <div className="form-content maxWidth540">
                    <SkeletonLoader type='preview-aftercare-form-skeleton' /> 
                </div>
                :
                <div className="form-content maxWidth540">
                    <div className="row form-title">
                        <p style={{ marginBottom: '0', padding: '0px' }}>
                            {formName}
                        </p>
                        {/* <p className="form-title__subtitle">
                            Upload aftercare document
                        </p> */}
                    </div>

                    <div className="form-edit-options">
                        {uploadedImg ? (
                            <div
                                onClick={() => {
                                    if (uploadedImg.type === 'doc') {
                                        window.open(uploadedImg.pdfUrl);
                                    } else setIsImagePreviewOpen(true);
                                    // setIsImagePreviewOpen(true);
                                }}
                                className={`DropzoneStyle ${
                                    uploadedImg.type === 'image' ? 'no-border' : ''
                                }`}
                            >
                                
                                <img
                                    className={`uploaded-image ${
                                        ['pdf', 'doc'].includes(uploadedImg.type) ? 'uploaded-image--pdf' : ''
                                    }`}
                                    src={uploadedImg.src}
                                    alt={uploadedImg.name}
                                />
                                {['pdf', 'doc'].includes(uploadedImg.type) && 
                                    <p className='DropzoneStyle__title'>
                                        {uploadedImg.name}
                                    </p>
                                }
                            </div>
                        ) : (
                            <div style={{ cursor: 'default' }} className="DropzoneStyle">
                                {/* <input id="upload-img" /> */}
                                <div className="uploadWrapper">
                                    <img src={beforeafterimage} alt="Placeholder Image" />
                                    <p className="add-more">Upload</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>}
                <CustomModal
                    modalOpen={isImagePreviewOpen}
                    setModalOpen={setIsImagePreviewOpen}
                    modaltitle={uploadedImg?.name}
                    className="aftercare-image-preview-modal"
                    modalBody={
                        <>
                            {uploadedImg?.type === 'pdf' ? 
                                <iframe 
                                    className='aftercare-image-preview-iframe' 
                                    title='pdf viewer' 
                                    src={uploadedImg?.pdfUrl} 
                                />
                                :
                                <div className="DropzoneStyle preview-img">
                                    <TransformWrapper>
                                        <TransformComponent>
                                            <img
                                                className="uploaded-image"
                                                src={uploadedImg?.src}
                                                alt={uploadedImg?.name}
                                            />
                                        </TransformComponent>
                                    </TransformWrapper>
                                    {/* <img
                                        className="uploaded-image"
                                        src={uploadedImg?.src}
                                        alt={uploadedImg?.name}
                                    /> */}
                                </div>
                            }
                        </>
                    }
                />
        </>
    );
};

export default PreviewAfterCareForm;
