import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";




const SAVE_BANK_DETAILS_MUTATION = gql`
mutation SaveBankDetailsOfUserForPharmacy($bankAccountNumber: String!, $bankSortCode: String!) {
    saveBankDetailsOfUserForPharmacy(bank_account_number: $bankAccountNumber, bank_sort_code: $bankSortCode) {
      success
      message
      data {
        showPaidCard
        is_request_sent_to_admin
      }
    }
  }
  
`;

export const SavePrescriberBankDetails = () => {
    const [saveBankDetails, { data: saveBankDetailsResponseData }] =
      useMutation(SAVE_BANK_DETAILS_MUTATION);
    const dispatch = useDispatch();
  
    const initiateSaveBankDetails = (variables, successCallback, errorCallback) => {
      saveBankDetails({ variables })
        .then(({ data }) => {
          if (data.saveBankDetailsOfUserForPharmacy.success) {
            successCallback(data.saveBankDetailsOfUserForPharmacy);
          } else {
            errorCallback(data.saveBankDetailsOfUserForPharmacy);
          }
        })
        .catch((error) => {
          // Handle error
          errorCallback(error);
        });
    };
  
    return { initiateSaveBankDetails, saveBankDetailsResponseData };
  };