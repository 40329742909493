import React from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import './Tooltip.scss'


const Tooltip = ({ targetId, place, style, className, toolTipContent, tooltipRef }) => {
    return (
        <ReactTooltip anchorSelect={targetId} clickable 
            className={className} effect="solid" place={place} style={style} ref={tooltipRef}>
            {toolTipContent}
        </ReactTooltip >
    )
}

export default Tooltip
