import { GET_PAY_LATER_DATA, SET_PAY_LATER_DATA,GET_PAYLATER_STATUS_DATA_SUCCESS } from "../../constants/payLater/payLaterConts"



const initialState = {
    payLaterData: [],
    payLaterStatus : null
}
export const payLaterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAY_LATER_DATA:
            return {
                ...state,
                payLaterData: action.payload,
            }
        case GET_PAYLATER_STATUS_DATA_SUCCESS :
            return {
                ...state,
                payLaterStatus: action.payload?.data?.payl8r_enabled,
                payLaterEnable : action.payload?.data?.hide_toggle
            }
        default:
            return state
    }
}  