import React, { useEffect, useRef, useState } from "react"
import "./forgot-password-update-component.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import FinaceHeader from "../../finaceHeader"
import { useSelector } from "react-redux"
import { ProcessForgotePassword } from "../../redux/actions/pay-later/processForgotePassword"
import { toast } from "react-toastify"
import { useNavigate } from "react-router"
import StepButton from "../../common/StepButton"

const ForgotPasswordUpdateComponent = () => {
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
    });
    const navigate = useNavigate();
    const email = useSelector(state => state?.financeLoanRequestEmailReducer?.userEmailForFinance)
    const code = useSelector(state => state?.financeLoanRequestVerificationCodeReducer?.userVerificationCode)
    const { initiateprocessForgotePassword, processForgotePasswordData } = ProcessForgotePassword();
    const [errors, setErrors] = useState({});
    const inputRef = useRef(null);

    useEffect(() => {
        if (!email || email == "") {
            navigate("/forgot-password")
        }
        inputRef.current.focus();

    }, [email])
    const validateForm = () => {
        let formIsValid = true;
        let errors = {};
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;

        if (!formData.password.trim()) {
            errors.password = 'Password is required';
            formIsValid = false;
        } else if (!passwordPattern.test(formData.password)) {
            errors.passwordValid = 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special character, and be at least 10 characters long';
            formIsValid = false;
        }
        if (!formData.confirmPassword.trim()) {
            errors.confirmPassword = 'Confirm password is required';
            formIsValid = false;
        }
        if (formData.password.trim() && formData.confirmPassword.trim() && formData.password !== formData.confirmPassword) {
            errors.confirmPassword = 'Password and Confirm password do not match';
            formIsValid = false;
        }
        // Add similar conditions for other fields

        setErrors(errors);
        return formIsValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === "password" && errors.passwordValid) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordValid: '',
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {

            initiateprocessForgotePassword({
                email: email,
                code: code,
                changePassword: email ? true : false,
                password: formData.password
            },
                (res) => {
                    if (res.success) {
                        navigate("/login");
                    }
                },
                (err) => {
                    console.log("Error", err);
                });
        }
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     initiateprocessForgotePassword({
    //         email : email,
    //         code : code,
    //         changePassword :true,
    //         password : formData.password
    //     },
    //     (res) => {
    //         if (res.success) {
    //             // Cookies.set('faces_access_token', res.data.token, { expires: 7 });

    //             // toast.success(res.message)
    //             // dispatch({
    //             //     type: STORE_VERIFICATION_CODE,
    //             //     payload: verificationCodeString,
    //             //   });
    //             navigate("/welcome-back")
    //         }
    //     },
    //     (err) => {
    //         console.log("errr999999999",err)
    //     })
    //     // Add your registration logic here
    // };
    return (
        <>
            <div className="mainfinancelandingpage">
                <FinaceHeader />
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8 col-sm-12">
                            <h2 className="pt-5 pagenewPageTitle">New password</h2>
                            <form className="login-form col-md-12" onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="password" className="form-label">
                                        Enter new password
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        placeholder="Enter new password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        ref={inputRef}
                                    />
                                    {errors.password && <p className="error text-danger">{errors.password}</p>}
                                    {errors.passwordValid && <p className="error-text text-danger error" style={{ margin: "10px 0px", lineHeight: "normal" }}>{errors.passwordValid}</p>}

                                </div>
                                <div className="mb-4">
                                    <label htmlFor="confirmPassword" className="form-label">
                                        Confirm password
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        placeholder="Confirm password"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                    />
                                    {errors.confirmPassword && <p className="error text-danger">{errors.confirmPassword}</p>}

                                </div>
                                {/* {errors.password && <p className="error text-danger">{errors.password}</p>} */}

                                {/* <button type="submit" className="col-md-12 btn btn-primary apply-btn">
                                Save
                                </button> */}
                                <StepButton
                                    type="submit"
                                    blue={true}
                                    id={"ryft-pay-btn"}
                                    label={"Save"}
                                    isLoading={processForgotePasswordData.loading}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPasswordUpdateComponent
