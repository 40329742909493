import React from 'react'
import './payment-main.scss'
import completeArrow from '../../../../images/client/tick.svg'
import celenderBtn from '../../../../images/month_pay.svg'
import addBtn from '../../../../images/icon/Icon example.svg'
import location_clock from '../../../../images/icons/Icon example.svg'
import StepButton from '../../../common/StepButton'
import backArrow from '../../../../images/close_icon.svg'


const PaymentComplete = () => {
    return (
        <div className='container payment-complete-main'>
            <div className="payment-header-main m-0">
                <div></div>
                <img src={backArrow} style={{ cursor: 'pointer' }} alt="" />
            </div>
            <img src={completeArrow} alt='' />
            <div className='payment-complete-content'>
                <h1>Payment complete</h1>
                <p>The payment has been processed, and a receipt has been sent to your client's email.</p>
                <div className='payment-complete-img'>
                    <span>
                        <img className='icon-bg' width={50} height={50} src={location_clock} alt='' />
                        <p>Rebook</p>
                    </span>
                    <span>
                        <img className='icon-bg' width={50} height={50} src={addBtn} alt='' />
                        <p>New</p>
                    </span>
                    <span>
                        <img src={celenderBtn} alt='' />
                        <p>Calendar</p>
                    </span>
                </div>
                <div className='step-btn'>
                    <StepButton label={"Done"} blue={true} />
                </div>

            </div>
        </div>
    )
}

export default PaymentComplete
