import { TIME } from "../../../../utils/general"
import { CLEAR_DATE_SLOTS, CLEAR_TIME_SLOTS, CLEAR_TIME_SLOTS_FOR_CREATE_APPOINTMENT, GET_DATE_SLOTS, GET_TIME_SLOTS, SET_AVAILABLE_AND_OUTSIDE_AVAIBILITY_TIME_SLOTS_CREATE_APPOINTMENT, SET_TIME_SLOTS_FOR_CREATE_APPOINTMENT } from "../../constants/dateTimeslots/DateTimeSlots"

const initialState = {
    dateSlots: [],
    timeslots: [],
    availableAndOutsideAvaibilityTimeSlots: [],
    timeslotsData: [],
    totalTimeSlotsCount: 0,
    loadedTimeSlotsCount: 0,
    timeSlotsPage: 0,
}

export const GetDateTimeSlotsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DATE_SLOTS:
            let tempDate = state?.dateSlots.concat(action.payload?.data?.items)
            return {
                ...state,
                dateSlots: tempDate
            }
        case GET_TIME_SLOTS:

            // let tempTime = state?.timeslots.concat(action.payload?.data?.items)
            // let tempTime = action.payload?.data?.items
            // tempTime = tempTime.filter((item) => item.isBooked === false)
            // return {
            //     ...state,
            //     timeslots: tempTime
            // }
            console.log("checktest", action.payload?.data?.items
            )
            const temp = (state?.timeslots).concat(action.payload?.data?.items)
            console.log("tempdata", temp)
            return {
                ...state,
                timeslots: temp,
                totalTimeSlotsCount: action.payload?.data?.total,
                timeSlotsPage: state.timeSlotsPage + 1,
                loadedTimeSlotsCount: temp.length
            };
        case CLEAR_TIME_SLOTS:
            return {
                ...state,
                timeslots: [],
                totalTimeSlotsCount: 0,
                timeSlotsPage: 0,
                loadedTimeSlotsCount: 0

            }

        case CLEAR_DATE_SLOTS:
            return {
                ...state,
                dateSlots: [],
            }

        case SET_AVAILABLE_AND_OUTSIDE_AVAIBILITY_TIME_SLOTS_CREATE_APPOINTMENT:
            let outsideAvaibilityTime = action.payload?.responseData?.data?.businessHours
            let availableClinicTime = TIME(outsideAvaibilityTime?.startTime, outsideAvaibilityTime?.endTime).map(item => {
                return { time_slot: item, isBooked: false };
            });
            // outsideAvaibilityTime = outsideAvaibilityTime.filter((item) => item.isBooked === true).map((val) => val.time_slot)

            console.log("filter", outsideAvaibilityTime)



            const timeData = TIME();

            const uniqueTime = timeData?.map(item => {
                // const isBooked = outsideAvaibilityTime.includes(item);
                return { time_slot: item, isBooked: false };
            });

            if (action.payload?.addTime) {
                const isAvailable = availableClinicTime.find((item) => item.time_slot == action.payload?.addTime)
                if (isAvailable == undefined) {
                    availableClinicTime.unshift({ time_slot: action.payload?.addTime, isBooked: true })
                }

            }
            console.log("isavaialble", availableClinicTime)
            return {
                ...state,
                availableAndOutsideAvaibilityTimeSlots: uniqueTime,
                timeslotsData: availableClinicTime,
                timeslots: availableClinicTime
            }

        case SET_TIME_SLOTS_FOR_CREATE_APPOINTMENT:
            return {
                ...state,
                timeslotsData: action.payload,
            }

        case CLEAR_TIME_SLOTS_FOR_CREATE_APPOINTMENT:
            return {
                ...state,
                timeslotsData: [],
                timeslots: [],
                availableAndOutsideAvaibilityTimeSlots: []
            }

        default:
            return state

    }
}