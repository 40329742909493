import {useMutation,gql} from "@apollo/client"

const HIDE_BECOME_FACES_VERIFIED_BANNER=gql`
mutation HideBecomeFacesVerifiedBanner($isHideBanner: Int) {
    hideBecomeFacesVerifiedBanner(is_hide_banner: $isHideBanner) {
      success
      message
    }
  }
`;

export const HideBecomeFacesVerifiedBannerAction=()=>{
    const [hideBecomeFacesVerifiedBannerFunction,hideBecomeFacesVerifiedBannerResponseData,
    ]=useMutation(HIDE_BECOME_FACES_VERIFIED_BANNER)

    const hideBecomeFacesVerifiedBannerQuerydata=hideBecomeFacesVerifiedBannerResponseData;

    const initHideBecomeFacesVerifiedBannerData=(data,successCallback,errorCallback)=>{
        hideBecomeFacesVerifiedBannerFunction({
            variables:data,
        }).then((responseData)=>{
            console.log("resData",responseData)
            if(responseData.data.hideBecomeFacesVerifiedBanner.success){
                successCallback(responseData)
            }else{
              errorCallback(responseData)
            }
        }).catch((error)=>{
            errorCallback(error)
        })

    }

    return {hideBecomeFacesVerifiedBannerQuerydata,initHideBecomeFacesVerifiedBannerData}
}