import React from "react";
import OffCanvasModal from "../modal/OffCanvasModal/OffCanvasModal";
import NoClient from "./NoClient";
import SearchClient from "./SearchClient";

const NewClient = ({ isOpen, setIsOpen, fromCalendar }) => {
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div className="client-container container">
      {/* <OffCanvasModal
        isOpen={isOpen}
        onHide={handleClose}
        isBack={false}
        body={
          <div>
           {
            fromCalendar ? <SearchClient /> : <NoClient />
           }
          </div>
        }
      /> */}
    </div>
  );
};

export default NewClient;
