import React from "react";
import CustomModal from "../modal/CustomModal";
import './CustomeDeleteModel.scss'
import StepButton from "../common/StepButton";

function CustomeDeleteModel({modalOpen, setModalOpen, isDeleteLoading, isDeleteDisabled, onDeleteClick, onCancleClick, DeleteModelTitle, DeleteModelDesc, setIsOpenMobileCalendar}) {
  return (
    <CustomModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      className={'CustomeComDeleteModel'}
      setIsOpenMobileCalendar={setIsOpenMobileCalendar}
      modalBody={
        <>
          <div className="DeleteModelContent">
            <div className="DeleteModelContentWrapper">
                <h5>{DeleteModelTitle}</h5>
                <p>{DeleteModelDesc}</p>
            </div>
            <div className="DeleteModelBtnWrapper">
                <StepButton
                  red={true}
                  label={"Delete"}
                  isLoading={isDeleteLoading}
                  disabled={isDeleteDisabled}
                  onClick={onDeleteClick}                  
                />
                <StepButton
                  label={"Cancel"}
                  onClick={onCancleClick}
                  gray={true}
                />
            </div>
          </div>
        </>
      }
    />
  );
}

export default CustomeDeleteModel;
