import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { BUSINESS_CONTENT_DATA, CHECK_WEBSITE_DOMAIN_RESPONSE } from "../../constants";


//1 date-api
const GENERATE_BUSINESS_CONTENT_QUERY = gql`
query GenerateBusinessContent($type: Int!, $name: String, $qualification: String, $jobRole: String) {
  generateBusinessContent(type: $type, name: $name, qualification: $qualification, job_role: $jobRole) {
    success
    message
    data {
      content
      type
    }
  }
}
`

export const GenerateBusinessContentAction = () => {
  const dispatch = useDispatch()
  const [generateBusinessContentResponseFunction, generateBusinessContentReponseData] = useLazyQuery(
    GENERATE_BUSINESS_CONTENT_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ generateBusinessContent: responseData }) => {
        console.log("responseData", responseData, "data")
        if (responseData?.success)
          dispatch({
            type: BUSINESS_CONTENT_DATA,
            payload: responseData
          })
      }
    }
  )
  //this will be called from components
  const generateBusinessContentResponseData = generateBusinessContentReponseData //variable

  const initGenerateBusinessContentResponseFunction = (data) => {

    generateBusinessContentResponseFunction({
      variables: data
    })
  }
  return { initGenerateBusinessContentResponseFunction, generateBusinessContentResponseData }
}

