import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";


const DELETE_ClIENT_PROFILE_MUTATION = gql`
mutation DeleteClientProfileImage($clientId: Int!) {
  deleteClientProfileImage(clientId: $clientId) {
    success
    message
  }
}
`;

export const DeleteProfileImage = () => {
    const [deleteClientProfilePicture, deleteClientProfileResponseData] =
        useMutation(DELETE_ClIENT_PROFILE_MUTATION);
    const dispatch = useDispatch();
    const deleteClientProfileMutationData = deleteClientProfileResponseData;

    const initiateDeleteClientProfile = (
        data,
        successCallback,
        errorCallback
    ) => {
        deleteClientProfilePicture({
            variables: data,
        })
            .then(({ data: { deleteClientProfileImage: responseData } }) => {
                if (responseData) {
                    successCallback(responseData);
                } else {
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                errorCallback(error);
            });
    };
    return { initiateDeleteClientProfile, deleteClientProfileMutationData };
};