import React, { useEffect, useRef, useState } from "react";
import "./new-client-list.scss";
import SearchIcon from "../../images/client/search_icon.svg";
import { ReactComponent as CloseIcon } from "../../images/close_icon.svg";
// import { ReactComponent as AddClientIcon } from "../../images/add_client.svg";
import AddClientIcon from "../../images/new-appointment/add-client.png";

import StepButton from "../common/StepButton";
import gsap from "gsap";
import CustomRadioButton from "../common/CustomRadioButton";
import { GetClientList } from "../redux/actions/client/ClientList";
import Avatar from "react-avatar";
import SkeletonLoader from "../common/Skeleton/SkeletonLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_CLIENT_LIST, CLEAR_CLIENT_LIST_DATA } from "../redux/constants";
import OffCanvasModal from "../modal/OffCanvasModal/OffCanvasModal";
import AddClient from "../Client/AddClient";
import NoClient from "../Client/NoClient";
import { debounce, set } from "lodash";
import closeIcon from "../../images/close_event_icon.svg"
import { GetMedicalFormStatusQuery } from "../redux/actions/appointments/GetMedicalFormStatus";

const NewClientList = ({ setIsClose, setSelectedClient, selectedClient, setMedicalFormSave, medicalFormSave, clearDependentStates }) => {
  //Client list Query
  const { clientListQueryData, initGetClientList } = GetClientList()
  const [searchText, setSearchText] = useState("");
  const [isSearchedTriggered, setIsSearchedTriggered] = useState(false);
  const [newClient, setNewClient] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  console.log(selectedClient, "selectedClient")

  const dispatch = useDispatch()
  //Get Client List from Reducer
  const { clientList } = useSelector(state => state?.clientListReducer)
  const { totalClientCount } = useSelector(state => state?.clientListReducer)
  const { clientListPage } = useSelector(state => state?.clientListReducer)
  const { loadedClientCount } = useSelector(state => state?.clientListReducer)
  const { emptyClientList } = useSelector(state => state?.clientListReducer)
  const [isNewClient, setIsNewClient] = useState(false)
  const [isRes, setIsRes] = useState(false)

  const { initGetMedicalFormStatus } = GetMedicalFormStatusQuery()


  const handleSearchText = (e) => {
    const newText = e.target.value;
    setIsSearchedTriggered(true);
    setSearchText(newText);

    // initiateClientListCall(true, newText);
  };

  const initiateClientListCall = (refreshList = false) => {
    let newPage = clientListPage + 1;
    if (refreshList) {
      newPage = 1;

    } else if (totalClientCount <= loadedClientCount) {
      return;
    }
    initGetClientList({
      search: searchText,
      limit: 20,
      page: newPage
    })
  };
  // Function to handle button click and pass selected client data
  const handleSelectButtonClick = () => {
    // Find the selected client based on the selectedIndex
    const selectedClientData = clientList?.find(
      (item) => item?.clentId === selectedIndex
    );

    // Pass the selected client data to the parent component
    setSelectedClient(selectedClientData);
    if (selectedClient?.clentId !== selectedClientData?.clentId) {


      if (medicalFormSave)
        initGetMedicalFormStatus({
          clientId: selectedClientData?.clentId
        }, (res) => {
          if (res?.success) {
            // (res?.data?.medicalFormFilledByClient)
            setMedicalFormSave(!(res?.data?.medicalFormFilledByClient))
          } else {
            setMedicalFormSave(false)
          }
        }, (err) => {
          setMedicalFormSave(false)
        })

      clearDependentStates()
    }

    if (searchText) {
      setSearchText("");
      dispatch({
        type: CLEAR_CLIENT_LIST_DATA
      })
    }
    // Close the client list modal or perform other actions as needed
    setIsClose(false);
    setSelectedIndex(-1);
  };
  useEffect(() => {
    // Preselect a client when the component mounts
    if (selectedClient && selectedClient.clentId) {
      setSelectedIndex(selectedClient.clentId);
    }
  }, [selectedClient]);

  console.log(selectedIndex, "selectedIndex")

  useEffect(() => {
    console.log(selectedClient, "selectedClient")
    if (selectedClient.length == 0 || clientList?.length == 0) {
      dispatch({
        type: CLEAR_CLIENT_LIST_DATA
      })
      initiateClientListCall(true)

    } else {
      initiateClientListCall(false)

    }
  }, [])

  //Add New Client Route
  const handleAddClientClick = () => {
    setIsNewClient(true)
  }

  //DEBOUNCE FOR SEARCH
  // Debounced API call function
  const debouncedApiCall = debounce((query) => {
    // Make the API call using the search query
    // Replace this with your own API call logic
    dispatch({
      type: CLEAR_CLIENT_LIST_DATA
    })
    initiateClientListCall(true)
  }, 500); // Debounce delay in milliseconds (e.g., 500ms)

  useEffect(() => {
    if (isSearchedTriggered) {
      // Call the debounced API function with the search query
      debouncedApiCall(searchText);

      // Cleanup function to cancel any pending debounced calls
      return () => {
        debouncedApiCall.cancel();
      };
    }
  }, [searchText]);

  console.log(clientList, "emptyClientList", emptyClientList)


  return (
    <div className="client-list-main-container">
      <div className="sticky-header">
        <div className="close-btn text-end">
          <CloseIcon className="close-icon-animation" onClick={() => {
            if (selectedClient.length == 0 || searchText.length != 0) {
              dispatch({
                type: CLEAR_CLIENT_LIST_DATA
              })
              setIsClose(false);
            }
            else {
              setIsClose(false);
            }
          }} />
        </div>
        <h1>Select client</h1>
        {clientListQueryData?.data?.getClientList_v3?.data === null && searchText?.length == 0 ? null
          : <div className="search-container">
            <input type="text" placeholder="Search Client" value={searchText} onChange={handleSearchText} />
            <img src={SearchIcon} alt="search-icon" className="search-icon-img" />
            {searchText?.length > 0 &&
              <CloseIcon className="cursor-pointer search-close-icon" onClick={() => { setSearchText("") }} />
            }
          </div>
        }
        {
          (searchText.length == 0) &&
          <div className="add-client-section" onClick={() => handleAddClientClick()}>
            {/* <AddClientIcon /> */}
            <img src={AddClientIcon} alt="AddClientIcon" width={48} height={48} />
            <p style={{ margin: 0 }}>Add client</p>
          </div>
        }
      </div>

      <div style={{ height: "70vh", width: "100%", overflowX: "scroll" }} id="scrollable-div" className={clientListQueryData?.data?.getClientList_v3?.data === null && 'emptyClientList'}>
        <InfiniteScroll
          dataLength={loadedClientCount}
          next={() => initiateClientListCall()}
          hasMore={totalClientCount > loadedClientCount}
          scrollableTarget="scrollable-div"
        >
          {
            ((clientListQueryData?.data?.getClientList_v3?.data && !(clientListQueryData?.loading) == null) || emptyClientList) ?
              <NoClient fromOffCanvas={true} labelText={searchText.length > 0 || isSearchedTriggered ? "No clients found" : "No clients yet"} />
              :
              clientList?.map((item, index) => (
                <div className="client-list-container m-2 " key={index} >
                  {/* <div className="client-left-container cursor-pointer"
                  // onClick={() => setSelectedIndex(item?.clentId)}
                  >
                    <Avatar size={49} round={true} src={item?.clientProfileImage} color='#EEEFF3' maxInitials={2} fgColor='black' name={item?.clientName} />
                    <div className="client-name-container">
                      <h3>{item?.clientName}</h3>
                      <p>{item?.clientEmail}</p>
                    </div>
                  </div> */}
                  <div className="client-right-container">
                    <CustomRadioButton
                      name="client"
                      id={item?.clentId}
                      value={item?.clientName}
                      checked={item?.clentId === selectedIndex || selectedClient?.clentId === item?.clentId}
                      onChange={() => setSelectedIndex(item?.clentId)}
                      isblacked={true}
                      customLabel={
                        <>
                          <div className="client-left-container cursor-pointer"
                          // onClick={() => setSelectedIndex(item?.clentId)}
                          >
                            <Avatar size={49} round={true} src={item?.clientProfileImage} color='#EEEFF3' maxInitials={2} fgColor='black' name={item?.clientName} />
                            <div className="client-name-container">
                              <h3>{item?.clientName}</h3>
                              <p>{item?.clientEmail}</p>
                            </div>
                          </div>
                        </>
                      }
                    />
                  </div>
                </div>
              ))
          }
          {
            clientListQueryData.loading ?
              <>
                <div className="ps-4 pb-4 pe-2">

                  <SkeletonLoader type={"client-list"} showCircle={true} />
                </div>
              </>
              : null
          }

        </InfiniteScroll>
      </div>
      <div className="sticky-footer">
        <StepButton label={"Select"} labelClassName={"mb-0"} className={"mt-0"} disabled={selectedIndex == -1} blue={true} onClick={handleSelectButtonClick} />
      </div>
      {console.log("treatment list", selectedClient)}
      <OffCanvasModal
        isOpen={isNewClient}
        className={isRes ? "w-75" : "custom-sidebar-width-30"}
        placement={"end"}
        isBackOnly={true}
        isOffCanvasHeader={false}
        onHide={() => setIsNewClient(false)}
        body={<AddClient isHide={setIsNewClient} fromOffCanvas={true} setIsRes={setIsRes} setSelectedClient={setSelectedClient} setIsCloseFromCanvas={setIsClose} />}
      />
    </div>
  );
};

export default NewClientList;
