import { gql, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

const DELETE_IDENTITY_PROOF_DOCUMENT_MUTATION = gql`
mutation DeleteIdentityDocumentForFinance($usrVrfId: Int!) {
  deleteIdentityDocumentForFinance(usr_vrf_id: $usrVrfId) {
    success
    message
  }
}
`;

export const DeleteIdentityProofDocumentType = () => {
  // const dispatch = useDispatch();
  const [
    DeleteIdentityProofDocumentTypeResponseFunction,
    deleteIdentityProofDocumentTypeResponseData,
  ] = useMutation(DELETE_IDENTITY_PROOF_DOCUMENT_MUTATION);

  //this will be called from components
  const deleteIdentityProofDocumentTypeQueryData =
    deleteIdentityProofDocumentTypeResponseData; //variable

  const initDeleteIdentityProofDocumentTypeData = (
    data,
    successCallback,
    errorCallback
  ) => {
    DeleteIdentityProofDocumentTypeResponseFunction({
      variables: data,
    })
      // .then(({ data: { DeleteIdentityProofDocumentType: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        if (responseData?.data?.deleteIdentityDocumentForFinance?.success) {
          // dispatch(...)
          successCallback(responseData);
          // toast.success(responseData.message)
        } else {
          errorCallback(responseData);
          // toast.error(responseData.message)
        }
      })
      .catch((err) => {
        // errorCallback(err);
        console.log('typeeee:', err);
        toast.error('something went wrong!!!');
      });
  };

  return {
    deleteIdentityProofDocumentTypeQueryData,
    initDeleteIdentityProofDocumentTypeData,
  };
};
