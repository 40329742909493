import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const COMPLETE_DIRECT_DEPOSIT_PAYMENT_BOOKING_MUTATION = gql`
mutation CompleteDirectDepositPayment($depositId: Int, $token: String, $paymentId: String, $paymentType: Int) {
  completeDirectDepositPayment(deposit_id: $depositId, token: $token, payment_id: $paymentId, payment_type: $paymentType) {
    success
    message
    data {
      capture_id
      transfer_id
      depositScheduledPayouts_id
      
    }
  }
}
`;

export const CompleteDirectDepositPaymentBooking = () => {
  const [completeDirectDepositPaymentBookingRequest, completeDirectDepositPaymentBookingResponseData] =
    useMutation(COMPLETE_DIRECT_DEPOSIT_PAYMENT_BOOKING_MUTATION);
  const dispatch = useDispatch();
  const completeDirectDepositPaymentBookingMutationData = completeDirectDepositPaymentBookingResponseData;

  const initiateCompleteDirectDepositPaymentBooking = (
    data,
    successCallback,
    errorCallback
  ) => {
    completeDirectDepositPaymentBookingRequest({
      variables: data,
    })
      .then(({ data: { completeDirectDepositPayment: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateCompleteDirectDepositPaymentBooking, completeDirectDepositPaymentBookingMutationData };
};
