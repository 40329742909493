import React, { useState } from 'react'
import CustomButton from '../../custom-components/custom-button'
import { useNavigate } from 'react-router'
import {getFacesAiAssistantRoute} from '../../../Routes'
import './aiassistant.scss'
import CustomModal from '../../modal/CustomModal'

const AiAssistantContainer = ({isDisableButton = false, isShowLock = false}) => {

  return;
  // const navigate = useNavigate();
  // const [openFacesAiInfoPopup, setOpenFacesAiInfoPopup] = useState(false);

  // const handleGetStartedClick = () => {
  //   if(isShowLock){
  //     setOpenFacesAiInfoPopup(true);
  //   }else{
  //     navigate(getFacesAiAssistantRoute); 
  //   }
  // };
  
  // return (
  //   <>
  //   <div onClick={handleGetStartedClick} style={{cursor: 'pointer'}}>
  //     <div className="aiassistant-container col-md-12 mb-5" >
  //           <div className="row">
  //               <div className="col-md-12">
  //                 <div className="aiassistantHeaderWrapper">

  //                   <div className="aiassistantHeader">
  //                     <h2 className="aiassistant-main-heading">AI Assistant</h2>
  //                       {isShowLock && (
  //                         <i className="fa-solid fa-lock" onClick={handleGetStartedClick} ></i>
  //                       )}
  //                   </div>
  //                   <span className="aiassistant-sub-heading">Automate: Bookings, Payments, Consent, Sales and Customer Service </span>  
  //                 </div>
  //               </div>
  //               <div className="col-md-12"> 
  //                 <CustomButton label="Get Started" className={isDisableButton ? 'disabledBtn' : 'whitebutton'} onClick={handleGetStartedClick} />
  //               </div>
  //           </div>
  //       </div>
  //   </div>
  //   <CustomModal
  //       modalOpen={openFacesAiInfoPopup}
  //       setModalOpen={setOpenFacesAiInfoPopup}
  //       type={""}
  //       modalBody={
  //         <>
  //           <div className="mt-3">
  //             Unlock this premium feature for free, simply onboard.
  //           </div>
  //         </>
  //       }
  //       modaltitle={"Faces AI Assistant"}
  //     />
  //   </>
  // )
}

export default AiAssistantContainer