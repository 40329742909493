import {CLEAR_RENEW_INSURANCE_STATUS, GET_INSURANCE_DATA_SUCCESS, RENEW_INSURANCE_SUCCESS} from '../../constants/index'

const initialState = {
    insuranceData: null,
    insuranceLandingLink: null,
    renewInsuranceStatus: false,
    renewInsuranceMessage: null,
}
export const GetInsuranceDataReducer = (state = initialState, action) => {
    console.log(action, 'action=>')
    switch (action.type) {
        case GET_INSURANCE_DATA_SUCCESS:
            return {
                ...state,
                insuranceData: action.payload.data,
                insuranceMessage: action.payload.message,
            }

        case RENEW_INSURANCE_SUCCESS:
            console.log(action.payload,"reducer insurance");
            return {
                ...state,
                renewInsuranceStatus: action.payload.success,
                renewInsuranceMessage: action.payload.message,
                    insuranceLandingLink: action.payload.data.element.insuranceLandingLink,
                }
        case CLEAR_RENEW_INSURANCE_STATUS:
            return {
                ...state,
                renewInsuranceStatus: false,
            }
        default:
            return state
    }
}  