import { useEffect, useRef, useState } from 'react';
import showToast from '../../common/CustomToast/CustomToast';
import { CircularProgress } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../images/close-icon-edit-form.svg';

function MedicalQuestionItem({ defaultQuestion, onEditQuestion, isLoading, isEditList }) {
    const [question, setQuestion] = useState(defaultQuestion || '');
    const queRef = useRef();

    useEffect(() => {
        // if() {
            queRef.current.scrollIntoView({ block: 'center'});
        // }
    }, [])

    const handleEditComplete = () => {
        if (question.length === 0) {
            showToast(
                'error',
                'Please enter question',
                undefined,
                undefined,
                undefined,
                undefined,
                { toastId: 'enter-que-error' }
            );
            return;
        }

        onEditQuestion(question);
    };

    return (
        <div ref={queRef}>
            <div style={{ marginTop: isEditList ? 0 : '30px' }} className="question-box question-box--edit">
                <textarea
                    // type="text"
                    value={question}
                    autoFocus
                    onChange={(e) => setQuestion(e.target.value)}
                    id="question-edit"
                    className="question-box__text question-box__input"
                    style={{ border: 'none', background: 'none', width: '100%' }}
                />
                {/* <div style={{ justifyContent: 'flex-end' }} className="question-box__actions">
                    <button
                        style={{ minWidth: '59px', minHeight: '31px' }}
                        disabled={isLoading}
                        onClick={handleEditComplete}
                        className="question-box__btn question-box__btn--done"
                    >
                        {isLoading ? <CircularProgress size={21} color="inherit" /> : 'Done'}
                    </button>
                    <button
                        onClick={() => onEditQuestion(null)}
                        className="question-box__btn"
                    >
                        <CloseIcon />
                    </button>
                </div> */}
            </div>
            <div style={{ justifyContent: 'flex-end' }} className="question-box__actions question-box__actions--edit">
                <button
                    style={{ minWidth: '59px', minHeight: '31px' }}
                    disabled={isLoading}
                    onClick={handleEditComplete}
                    className="question-box__btn question-box__btn--done"
                >
                    {isLoading ? <CircularProgress size={21} color="inherit" /> : 'Done'}
                </button>
                <button
                    onClick={() => onEditQuestion(null)}
                    className="question-box__btn question-box__btn--done"
                >
                    {/* <CloseIcon /> */}
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default MedicalQuestionItem;
