import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const REMOVE_BATCH_NUMBER_PHOTO = gql`
mutation RemoveBatchNumber($batchNumberId: Int) {
  removeBatchNumber(batchNumberId: $batchNumberId) {
    success
    message
  }
}
`;


export const RemoveBatchNumberPhotoAction = () => {
    const dispatch = useDispatch();
    const [
        RemoveBatchNumberPhotoResponseFunction,
        removeBatchNumberPhotoResponseData,
    ] = useMutation(REMOVE_BATCH_NUMBER_PHOTO)

    //this will be called from components
    const removeBatchNumberPhotoQueryData = removeBatchNumberPhotoResponseData; //variable

    const initRemoveBatchNumberPhotoData = (data, successCallback, errorCallback) => {
        RemoveBatchNumberPhotoResponseFunction({
            variables: data,
        }).then(({ data: { removeBatchNumber: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback(responseData)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
            // toast.error("something went wrong!!!")
        })
    }

    return { removeBatchNumberPhotoQueryData, initRemoveBatchNumberPhotoData };
};