import React, { useEffect, useState } from 'react';
import "./insuranceBanner.scss";
import { GetUserInsuranceVerifiedAction } from '../../redux/actions/faces-verified/faces-verified';
import { webBaseUrl } from '../../../config';

const Insurancebanner = () => {
    // const [insuranceStatus, setInsurancestatus] = useState()
    const { getInsuranceVerifiedQueryData, initGetInsuranceverifiedData } = GetUserInsuranceVerifiedAction();
    useEffect(() => {

        initGetInsuranceverifiedData()
    }, [])

    const insuranceStatus = getInsuranceVerifiedQueryData?.data?.getUserInsuranceVerifiedStatus?.data?.status


    console.log(insuranceStatus, "insuranceStatusinsuranceStatus")
    return (
        (  insuranceStatus === 4) && <section className='insurance_update_section'>

            <div className='insurance_update_desc_text'>
                <p>Your order may be delayed if your insurance is not updated. <span className='insurance_update_span_text' onClick={() => window.location.href = webBaseUrl + "/v1/prescriber-registration?insuranceexpired=true"}>Update now.</span></p>
            </div>
        </section>

    )
}

export default Insurancebanner