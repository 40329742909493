import { ADD_REVIEWS_DATA, ADD_REVIEWS_DATA_ERROR, ADD_REVIEWS_DATA_SUCCESS, FORWARD_SENDMAIL, FORWARD_SENDMAIL_ERROR, FORWARD_SENDMAIL_SUCCESS, GET_CLIENTS_DATA, GET_CLIENTS_DATA_SUCCESS, GET_REVIEWS_DATA, GET_REVIEWS_DATA_SUCCESS } from "../../constants"


export default function getReviewsData(data) {

    return {
        type: GET_REVIEWS_DATA,
        payload: data
    }
}

export function getReviewsDataSuccess(data) {
    return {
        type: GET_REVIEWS_DATA_SUCCESS,
        payload: data
    }
}

// ===============================================================

export function getClientsData(data) {

    return {
        type: GET_CLIENTS_DATA,
        payload: data
    }
}

export function getClientsDataSuccess(data) {
    return {
        type: GET_CLIENTS_DATA_SUCCESS,
        payload: data
    }
}


// ===============================================================

export function forwardSendMailData(data) {

    return {
        type: FORWARD_SENDMAIL,
        payload: data
    }
}

export function forwardSendMailDataSuccess(data) {
    return {
        type: FORWARD_SENDMAIL_SUCCESS,
        payload: data
    }
}

export function forwardSendMailDataError(data) {
    return {
        type: FORWARD_SENDMAIL_ERROR,
        payload: data
    }
}

// ===============================================================

export function addReviewsData(data) {

    return {
        type: ADD_REVIEWS_DATA,
        payload: data
    }
}

export function addReviewsDataSuccess(data) {
    return {
        type: ADD_REVIEWS_DATA_SUCCESS,
        payload: data
    }
}

export function addReviewsDataError(data) {
    return {
        type: ADD_REVIEWS_DATA_ERROR,
        payload: data
    }
}

