import { gql, useMutation } from "@apollo/client";


const DELETE_BLOCK_OUT_TIME_MUTATION = gql`
mutation DeleteBlockOutTime($blockOutId: Int, $blockOutDateId: Int) {
    deleteBlockOutTime(blockOutId: $blockOutId, blockOutDateId: $blockOutDateId) {
      success
      message
    }
  }
`;

export const DeleteBlockOutTimeMutation = () => {
  const [deleteBlockoutTime, deleteBlockTimeResponseData] =
    useMutation(DELETE_BLOCK_OUT_TIME_MUTATION);
  const deleteBlockTimeMutationData = deleteBlockTimeResponseData;

  const initiateDeleteBlockOutTime= (
    data,
    successCallback,
    errorCallback
  ) => {
    deleteBlockoutTime({
      variables: data,
    })
      .then(({ data: { deleteBlockOutTime: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateDeleteBlockOutTime, deleteBlockTimeMutationData };
};