import { CLEAR_NEXTOFKIN_DETAILS, GET_NEXTOFKIN_DETAILS } from "../../constants/client/NextOfKinConst";

const initialState = {
  nextOfKinDetails: [],
};

export default function nextOfKinDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NEXTOFKIN_DETAILS:
      return {
        ...state,
        nextOfKinDetails: action.payload,
      };
    case CLEAR_NEXTOFKIN_DETAILS:
      return {
        ...state,
        nextOfKinDetails: [],
      };
      
    default:
      return state;
  }
}
