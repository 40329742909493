import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const SAVE_BEFORE_AFTER_PHOTOS = gql`
mutation SaveBeforeAfterPhotos($consentFormRecordId: Int, $treatmentId: Int, $photos: [saveBeforeAfterPhotosArgsPhotos]) {
  saveBeforeAfterPhotos(consentFormRecordId: $consentFormRecordId, treatmentId: $treatmentId, photos: $photos) {
    success
    message
  }
}
`;


export const SaveBeforeAfterPhotosAction = () => {
    const dispatch = useDispatch();
    const [
        BeforeAndAfterPhotosResponseFunction,
        beforeAndAfterResponseData,
    ] = useMutation(SAVE_BEFORE_AFTER_PHOTOS)

    //this will be called from components
    const beforeAfterQueryData = beforeAndAfterResponseData; //variable

    const initBeforeAndAfterPhotosData = (data, successCallback, errorCallback) => {
        BeforeAndAfterPhotosResponseFunction({
            variables: data,
        }).then(({ data: { saveBeforeAfterPhotos: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback(responseData)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
            // toast.error("something went wrong!!!")
        })
    }

    return { beforeAfterQueryData, initBeforeAndAfterPhotosData };
};