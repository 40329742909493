import moment from 'moment'
import React from 'react'
import "./appointment-list-card.scss"
import { convertMinutesToHours, pounds } from '../../../utils/general'
import consentFormFilledIcon from "../../../images/icons/appointment-list/consentFormFilledIcon.svg"
import consentFormIncompleteIcon from "../../../images/icons/appointment-list/consentFormIncompleteIcon.svg"
import Avatar from 'react-avatar'
import { useSelector } from 'react-redux'


const AppointmentListCard = ({ onClickCallback, data, type, isRebook = false, rebookClickHandler, isDateDisplay }) => {
    const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)

    const getDayForGivenDate = (dateStr) => {

        // Parse the date string into a Moment object
        const givenDate = moment(dateStr, 'DD-MM-YYYY')

        // Get the current date as a Moment object
        const today = moment();

        // Calculate tomorrow's date as a Moment object
        const tomorrow = today.clone().add(1, 'days');
        // Compare the given date with today and tomorrow
        if (givenDate.isSame(today, 'day')) {
            return "Today"
        } else if (givenDate.isSame(tomorrow, 'day')) {
            return "Tomorrow"
        } else {
            return moment(dateStr, 'DD-MM-YYYY').format('ddd DD MMM,YYYY')
        }
    }
    console.log("UserProfileData", UserProfileData)

    const truncateString = (str) => {
        return str?.length > 25 ? str?.substring(0, 25) + "..." : str;
    }
    return (
        <>
            {type == "Appointment" ?
                <div className='appointment-listing-card' onClick={() => {
                    if (onClickCallback) {
                        onClickCallback(data)
                    }
                }}>
                    <div className='beforeBorder' style={{ background: `${data?.clinic_color}` }} />
                    <div className='appointment-list-content'>
                        <div className='d-flex justify-content-between align-items-center w-100'>
                            <div className='d-flex align-items-center'>
                                <div className={`user-image-container-appointment d-flex`}>
                                    {!UserProfileData?.user?.image_url ?
                                        <div className='name-wrapper'><h2 className='m-auto'>{UserProfileData?.user?.usr_fname[0]?.toUpperCase() + (UserProfileData?.user?.usr_lname ? UserProfileData?.user?.usr_lname[0]?.toUpperCase() : UserProfileData?.user?.usr_fname[1]?.toUpperCase())}</h2> </div> :
                                        <img src={UserProfileData?.user?.image_url} />}
                                </div>
                                {data?.consentFormCount > 0 && <div className='ms-1'>
                                    <img src={data?.isFilled ? consentFormFilledIcon : consentFormIncompleteIcon} alt="consentform" />
                                </div>}
                            </div>
                            <div className='status-badge-red mb-2' style={{ background: data?.paymentTagColor == "" ? "unset" : data?.paymentTagColor }}>
                                <p>{data?.paymentTag}</p>
                            </div>
                        </div>
                        <div className='status-name-container w-100'>
                            <p className='client-name'>{data?.client_name} </p>
                            <h3>{pounds.format(data?.price?.toFixed(2))}</h3>
                        </div>
                        {isDateDisplay && <p>{getDayForGivenDate(data?.calender_date)}</p>}
                        <div className='d-flex justify-content-between w-100'>
                            <h1>{moment(data?.cld_rc_start).format("HH:mm")} – {moment(data?.cld_rc_end).format("HH:mm")}</h1>
                        </div>
                        <p>{isDateDisplay ? `${truncateString(data?.treatment_name)} ${data?.treatment_count > 1 ? `(+${data?.treatment_count - 1})` : ""}` : `${data?.treatment_name} ${data?.treatment_count > 1 ? `(+${data?.treatment_count - 1})` : ""}`}</p>
                        {/* <p>{data?.treatment_count > 1 ? `${data?.treatment_name} (+${data?.treatment_count - 1})` : data?.treatment_name}</p> */}
                        <p>{isDateDisplay ? `${truncateString(data?.location_name)}` : `${data?.location_name}`}</p>
                        {isRebook && <div className='rebook-btn-main mt-2' onClick={(event) => {
                            rebookClickHandler(data?.booking_slug);
                        }}>
                            <div className='rebook-btn'>
                                <p>Rebook</p>
                            </div>
                        </div>}
                    </div>
                </div>
                :
                <div className='appointment-listing-card'
                    // style={{ borderLeft: `3px solid ${"green"}` }}
                    onClick={() => {
                        if (onClickCallback) {
                            onClickCallback(data)
                        }
                    }
                    }
                >
                    <div className='beforeBorder' style={{ background: `${data?.clinic_color}` }} />
                    <div className='appointment-list-content'>
                        <div className='status-badge-red mb-2 block-out-tag'>
                            <p className='blockout-time-tag'>Blockout</p>
                        </div>
                        <h1>{data?.client_name} {data?.location_name ? `@ ${data?.location_name}` : ""} </h1>
                        <p>{moment(data?.cld_rc_start).format("HH:mm")} – {moment(data?.cld_rc_end).format("HH:mm")}</p>
                    </div>
                </div>
            }
        </>
    )
}

export default AppointmentListCard
