import React, { useState } from 'react'
import '../add-new-clinic-main.scss'
import { Progress } from 'reactstrap'
import backArrow from '../../../images/back_arrow.svg'
import closeArrow from '../../../images/close_icon.svg'
import StepButton from '../../common/StepButton'
import { useNavigate } from 'react-router'

const AddManuallyAddress = () => {
    const navigate = useNavigate();

    const handleGoToAddressPage = () => {  
        navigate('/new-clinic-address-name')
    }
    return (
        <>
            <div className='container'>
                <div className='header_icon_content mt-4'>
                    <img src={backArrow} alt='backArrow' />
                    <Progress className="pregress_bar" value={25} />
                    <img src={closeArrow} width={36} height={36} alt='backArrow' />
                </div>
                <div className='add_new_clinic_main'>
                    <div className='add_new_clinic_location'>
                        <h1>Address</h1>
                        <p>Share your main business address. You can add more locations later.</p>
                        <div className='add_clinic_name'>
                            <div className='add_manually_add'>
                                <div className='address_input'>
                                    <p>Street Address</p>
                                    <input type='text' placeholder='Street address line' />
                                </div>
                                <div className='address_input'>
                                    <p>Address line 2</p>
                                    <input type='text' placeholder='Apartment, Suite, Etc' />
                                </div>
                                <div className='address_input'>
                                    <p>City</p>
                                    <input type='text' placeholder='City' />
                                </div>
                                <div className='address_input'>
                                    <p>Postcode</p>
                                    <input type='text' />
                                </div>
                            </div>
                            <div className='mt-4 step_btn'>
                                <StepButton label={'Continue'} blue={true} onClick={handleGoToAddressPage} />
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </>
    )
}

export default AddManuallyAddress
