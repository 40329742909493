import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";


const SAVE_HAIR_PRESCRIPTION_LINK_MUTATION = gql`
mutation UpdateHairPrescriptionUserSlug($userId: Int, $slug: String) {
    updateHairPrescriptionUserSlug(user_id: $userId, slug: $slug) {
      success
      message
      data {
        new_slug
      }
    }
  }
`;

export const SaveHairPrescriptionLink = () => {
  const [saveHairPrescriptionLink, saveHairPrescriptionLinkResponseData] =
    useMutation(SAVE_HAIR_PRESCRIPTION_LINK_MUTATION);
  const dispatch = useDispatch();
  const saveHairPrescriptionLinkMutationData = saveHairPrescriptionLinkResponseData;

  const initiateSaveHairPrescriptionLink= (
    data,
    successCallback,
    errorCallback
  ) => {
    saveHairPrescriptionLink({
      variables: data,
    })
      .then(({ data: { updateHairPrescriptionUserSlug: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateSaveHairPrescriptionLink, saveHairPrescriptionLinkMutationData };
};