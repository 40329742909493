import { Avatar } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import { frontendURL } from "../../config";
import { ReactComponent as ApplePayIcon } from "../../images/apple_pay.svg";
import { ReactComponent as BackArrow } from "../../images/back_arrow.svg";
import { ReactComponent as MonthPayIcon } from "../../images/month_pay.svg";
import { ReactComponent as DebitIcon } from "../../images/pay_credit.svg";
import { ReactComponent as PayFullIcon } from "../../images/pay_in_full.svg";
import { ReactComponent as RightArrow } from "../../images/right_arrow.svg";
import { ReactComponent as SplitPayIcon } from "../../images/split_payment.svg";
import { ReactComponent as CloseIcon } from '../../images/close_icon.svg'
import BannerHeader from "../common/BannerHeader";
import CustomRadioButton from "../common/CustomRadioButton";
import StepButton from "../common/StepButton";
import DiscountModal from "../modal/DiscountModal/DiscountModal";
import { PayLaterRequest } from "../redux/actions/pay-later/PayLater";
import { CancelTreatmentBooking, CreateTreatmentBooking, GetRyftPaymentToken } from "../redux/actions/treatmentBooking/createTreatmentBooking";
import RyftComponent from "../ryft/RyftComponent";
import "./confirmpay.scss";
import PayLater from "../payl8r/PayLater";
import { SET_PAY_LATER_DATA } from "../redux/constants/payLater/payLaterConts";
import { setPaylaterData } from "../redux/actions/pay-later/payLaterAction";
import { StripeComponent } from "../Stripe/StripeComponent";
import { set } from "lodash";
import { GET_DISCOUNT_CODE_CONST } from "../redux/constants/discount/discount";
import Modal from "react-modal";
import { SET_LAST_STEP } from "../redux/constants/treatment-list-const/TreatmentListConst";
import useWindowDimensions from "../../utils/useWindowDimensions";
import PatientDobWarningModal from "../modal/PatientDobWarningModal";

const ConfirmPay = ({
  setStep,
  step,
  selectedTreatment,
  selectedTime,
  selectedTeamMember,
  setClinicId,
  clinicId,
  setSubStep, subStep,
  setSelectedTreatmentId,
  selectedTreatmentId,
  showSelectedDate,
  showSelectedTime,
  formatedDate,
  selectedLocation,
  setSelectedLocation,
}) => {

  const patientDobModalRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPayment, setIsOpenPayment] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0)
  const { slug } = useParams()
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [practitionerId, setPractitionerId] = useState(null)
  const [isCancel, setIsCancel] = useState()
  const [yourDetails, setYourDetails] = useState({
    clientFName: "",
    clientLName: "",
    clientEmail: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    clientFName: "",
    clientLName: "",
    clientEmail: "",
  });
  const { getRyftPaymentQueryData, initRyftPaymentToken } = GetRyftPaymentToken()
  const { initiatePayLaterRequest, payLaterMutationData } = PayLaterRequest()
  const [totalMins, setTotalMins] = useState(0)
  const [payableAmount, setPayableAmount] = useState(0)
  const [loader, setLoader] = useState(false)
  // const moment=require("moment")
  const paymentSession = useSelector(state => state?.treatmentBookingreducer?.treatmentBookingToken)
  //const [sessionToken, setSessionToken] = useState("")
  const cleanedTimeString = selectedTime.replace(/\sA[MP]$/, "");
  const formattedTime = moment(showSelectedTime, "hh:mm A").format("HH:mm:ss");
  const { initiateCreateTreatmentBooking, createTreatmentBookingMutationData } =
    CreateTreatmentBooking();
  const { initiateCancelTreatmentBooking, cancelTreatmentBookingMutationData } = CancelTreatmentBooking();
  const treatmentBookingAccountId = useSelector(state => state?.treatmentBookingreducer?.treatmentBookingAccountId)
  const sessionToken = useSelector(state => state?.treatmentBookingreducer?.treatmentBookingToken)
  const [selectedTimes, setSelectedTimes] = useState(false)
  const [success, setSuccess] = useState(false)
  const parsedDate = moment(showSelectedDate, "dddd, Do MMMM YYYY");
  const newDate = moment(showSelectedDate, "dddd, Do MMMM YYYY").format("Do MMMM YYYY");
  const formattedDate = parsedDate.format("YYYY-MM-DD");
  const [bookingId, setBookingId] = useState(null)
  const [accountId, setAccountId] = useState(null)
  const [paymentType, setPaymentType] = useState(null)
  const [openPayLater, setOpenPayLater] = useState(false)
  const [payLaterData, setPayLaterData] = useState(null)
  const [removeLoading, setRemoveLoading] = useState(false)


  const { discountDetails } = useSelector(state => state?.discountReducer)
  const { businessClinicList } = useSelector(state => state?.businessLocationListReducer)


  const [discountValue, setDiscountValue] = useState("0.00")
  const [isRemoveOpen, setIsRemoveOpen] = useState(false)
  const dispatch = useDispatch();
  // Define the isValidEmail function for basic email validation
  function isValidEmail(email) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  }
  const navigate = useNavigate()
  useEffect(() => {
    if (selectedPaymentOption === "Split payment" || selectedPaymentOption === "Pay in full") {
      setPaymentType("Card")
    } else if (selectedPaymentOption === "Monthly payments") {
      setPaymentType("PayL8r")
    } else {
      setPaymentType("")
    }
  }, [selectedPaymentOption])


  const handleConfirmBooking = () => {

    // Validate first name, last name, and email
    const validationErrors = {};
    if (!yourDetails.clientFName) {
      validationErrors.clientFName = "First name is required";
    }
    if (!yourDetails.clientLName) {
      validationErrors.clientLName = "Last name is required";
    }
    if (!yourDetails.clientEmail || !isValidEmail(yourDetails.clientEmail)) {
      validationErrors.clientEmail = "Valid email is required";
    }

    setValidationErrors(validationErrors);

    // If there are validation errors, don't proceed with booking
    if (Object.values(validationErrors).some((error) => error)) {
      //toast.error("Please fix the validation errors.");
      setLoader(false);
      return;
    }

    patientDobModalRef.current.showDobWarning();
  };

  const initConfirmBooking = () => {
    setLoader(true)

    initiateCreateTreatmentBooking(
      {
        clinicId: clinicId,
        date: formattedDate,
        time: formattedTime,
        clientEmail: yourDetails.clientEmail,
        clientFname: yourDetails.clientFName,
        clientLname: yourDetails.clientLName,
        treatmentIds: selectedTreatmentId,
        practitionerId: selectedTeamMember.id,
        payFull: selectedPaymentOption === "Pay in full" ? true : false,
        couponCode: discountDetails?.discount_name ? discountDetails?.discount_name : "",
        createdFrom: businessClinicList?.clinic_DB_id ? "clinic-detail" : "na"
      },
      (res) => {
        if (res.success) {
          var totalAmt = getTotalPriceAfterDiscount(0);
          let isBypass = (selectedPaymentOption === "Split payment" && totalDeposit == 0) ? true : false
          if (paymentType === "PayL8r") {
            setBookingId(res?.data?.booking_id)
            initiatePayLaterRequest({
              bookingId: res.data.booking_id,
              redirectURL: `${frontendURL}/bookings/${slug}/success`
            }, (res) => {
              console.log("payLater", res)
              if (res.success) {
                setOpenPayLater(true)
                dispatch(setPaylaterData(res?.data))
                setPayLaterData(res?.data)
              } else {
                setOpenPayLater(false)
                setPayLaterData(null)
              }
              // if(res.success){
              //   setOpenPayLater(true)
              // }else{
              //   setOpenPayLater(false)
              // }
            }, (e) => {
              setLoader(false)
              toast.error(e.message)
            })
          } else if (totalAmt == 0) {
            navigate(`/bookings/${slug}/success?bookingId=${res.data.booking_id}`)
          }
          else if (businessClinicList?.is_stripe_setup && !isBypass) {
            //setBookingId(res?.data?.booking_id)
            if (paymentType === "Card") {
              setBookingId(res?.data?.booking_id)
              initRyftPaymentToken({
                bookingId: res.data.booking_id,
                paymentType: 2,
                redirectURL: `${frontendURL}/bookings/${slug}/success`
              }, (res) => {
                if (res?.data?.getRyftPaymentToken?.success) {
                  //setSessionToken(res?.data?.getRyftPaymentToken?.data?.payment_session)
                  setIsOpenPayment(true)
                  // setAccountId(res?.data?.getRyftPaymentToken?.data?.accountId)
                  setLoader(false)
                } else {
                  setIsOpenPayment(false)
                  setLoader(false)
                }
              }, (e) => {
                setLoader(false)
              })
            }
          }
          else if (businessClinicList?.is_ryft_setup && !isBypass) {
            if (paymentType === "Card") {
              setBookingId(res?.data?.booking_id)
              initRyftPaymentToken({
                bookingId: res.data.booking_id,
                paymentType: 1,
                redirectURL: `${frontendURL}/bookings/${slug}/success`
              }, (res) => {
                if (res?.data?.getRyftPaymentToken?.success) {
                  //setSessionToken(res?.data?.getRyftPaymentToken?.data?.payment_session)
                  setIsOpenPayment(true)
                  // setAccountId(res?.data?.getRyftPaymentToken?.data?.accountId)
                  setLoader(false)
                } else {
                  setIsOpenPayment(false)
                  setLoader(false)
                }
              }, (e) => {
                setLoader(false)
              })
            }
          }
          else {
            navigate(`/bookings/${slug}/success?bookingId=${res.data.booking_id}`)
          }

        } else {
          setLoader(false)
        }



      },
      (err) => {
        toast.error(err.message)
        setLoader(false)
      }
    );
  }

  const bookingSuccessCallBack = (data) => {
    if (data?.status === "Approved") {
      navigate(`/bookings/${slug}/success`, { state: { bookingId: bookingId, paymentId: data?.id } })

    } else {
      navigate(`/bookings/${slug}/fail`, { state: { bookingId: bookingId, paymentId: data?.id } })

    }
  }
  const getTotalPriceAfterDiscount = (fixed = 0) => {
    return (totalPrice - parseFloat(discountValue)).toFixed(fixed)
  }


  //FOR DISCOUNT CODE 
  useEffect(() => {
    if (discountDetails == null) {
      setDiscountValue("0.00")
      //setIsRemove(false)
    } else {
      if (discountDetails?.discount_type === "percentage") {
        // setIsRemove(true)
        setDiscountValue(((totalPrice * discountDetails?.discount_unit) / 100).toFixed(2).toString())
      } else {
        setDiscountValue(discountDetails?.discount_unit?.toString())
        //setIsRemove(true)
      }
    }

  }, [discountDetails?.discount_name, isOpen])

  const handleRemoveDiscount = () => {
    setRemoveLoading(true)
    setTimeout(() => {
      dispatch({ type: GET_DISCOUNT_CODE_CONST, payload: null });
      setIsRemoveOpen(false)
      setRemoveLoading(false)
    }, 2000)
  }
  // Function to perform validation when input fields change
  const validateInput = (name, value) => {
    switch (name) {
      case "clientFName":
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            clientFName: "First name is required",
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            clientFName: "",
          }));
        }
        break;
      case "clientLName":
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            clientLName: "Last name is required",
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            clientLName: "",
          }));
        }
        break;
      case "clientEmail":
        if (!value || !isValidEmail(value)) {
          setValidationErrors((prev) => ({
            ...prev,
            clientEmail: "Valid email is required",
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            clientEmail: "",
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setYourDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(name, value);
  };
  const handlePaymentOptionChange = (option) => {
    setSelectedPaymentOption(option);
  };
  function stringAvatar(name) {
    if (typeof name === "string") {
      return {
        children: name.toUpperCase(),
      };
    } else {
      // Handle the case where name is not a string, e.g., provide a default value.
      return {
        children: "N/A",
      };
    }
  }
  function stringAvatar2(name) {
    return {
      sx: {
        bgcolor: "#EEEFF3",
        color: "#000",
      },
      children: `${name}`.toUpperCase(),
    };
  }

  useEffect(() => {
    const totalPrice = selectedTreatment?.reduce((total, item) => {
      return total + (item?.amount || 0); // Add the amount of each item to the total
    }, 0);
    const totalDeposit = selectedTreatment?.reduce((deposit, item) => {
      return deposit + (item?.deposit || 0);
    }, 0)
    const totalMin = selectedTreatment?.reduce((mins, item) => {
      return mins + (item?.duration || 0);
    }, 0)

    setTotalPrice(totalPrice);
    setTotalDeposit(totalDeposit);
    setPayableAmount(totalPrice - totalDeposit)

    //Adding mins 
    const inputMoment = moment(showSelectedTime, 'hh:mm A');
    setSelectedTimes(inputMoment.format('HH:mm'))
    if (inputMoment.isValid()) {
      const newMoment = inputMoment.add(totalMin, 'minutes');
      const newTimeStr = newMoment.format('HH:mm');
      setTotalMins(newTimeStr);
    }
  }, [selectedTreatment]);


  const dimensions = useWindowDimensions();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [BtnHeight, setBtnHeight] = useState(0);

  useEffect(() => {

    setHeaderHeight(
      document.getElementsByClassName("header-height-calc")[0]?.offsetHeight || 0
    );

    setBtnHeight(
      document.getElementsByClassName("fixed-button")[0]?.offsetHeight
    );


    window.addEventListener("resize", handleResize);
  }, [headerHeight, BtnHeight]);

  const handleResize = () => {

    setHeaderHeight(
      document.getElementsByClassName("header-height-calc")[0]?.offsetHeight || 0
    );

    setBtnHeight(
      document.getElementsByClassName("fixed-button")[0]?.offsetHeight
    );

  };

  console.log(dimensions.height, headerHeight, 'dimensions');



  return (
    isOpenPayment ?

      businessClinicList?.is_stripe_setup ?
        <StripeComponent
          clientSecret={sessionToken}
          paymentType="card"
          bookingId={bookingId}
          setIsOpenPayment={setIsOpenPayment}
          // setProcess={setProcess}
          stripeDataObj={{
            date: moment(newDate, ("Do MMMM YYYY")).format('M') == 9 ?
              `${moment(newDate, ("Do MMMM YYYY")).format('Do')
              } Sept.${moment(newDate, ("Do MMMM YYYY")).format('YYYY')}` :
              `${moment(newDate, ("Do MMMM YYYY")).format('Do MMM. YYYY')}`,
            treatmentCount: selectedTreatment?.length,
            treatmentPrice: `£${totalPrice}.00`,
            totalPrice: selectedPaymentOption === "Split payment" ? `£${totalDeposit}` : `£${getTotalPriceAfterDiscount(0)}`,

          }}
        /> :
        <RyftComponent
          clientSecret={sessionToken}
          accountId={treatmentBookingAccountId}
          successCallback={bookingSuccessCallBack}
          setIsOpenPayment={setIsOpenPayment}
          isOpenPayment={isOpenPayment}
          ryftDataObj={{
            date: moment(newDate, ("Do MMMM YYYY")).format('M') == 9 ?
              `${moment(newDate, ("Do MMMM YYYY")).format('Do')
              } Sept.${moment(newDate, ("Do MMMM YYYY")).format('YYYY')}` :
              `${moment(newDate, ("Do MMMM YYYY")).format('Do MMM. YYYY')}`,
            treatmentCount: selectedTreatment?.length,
            treatmentPrice: `£${totalPrice}.00`,
            totalPrice: selectedPaymentOption === "Split payment" ? `£${totalDeposit}` : `£${getTotalPriceAfterDiscount(0)}`,
          }}
        />
      :
      openPayLater ?
        <>
          <PayLater payLaterData={payLaterData} bookingId={bookingId} />
        </>

        :
        <div className="container p-3">
          <div className="confirm-pay-container">
            <div className={isRemoveOpen ? "d-none" : "header-height-calc"} style={isOpen ? { zIndex: "-1" } : {}}>
              <BackArrow onClick={() => {
                dispatch({ type: SET_LAST_STEP, payload: false })
                setStep(4)
              }} />
              <BannerHeader
                title={"Confirm & pay"}
                subTitle={`You’re booking a treatment with ${selectedLocation?.clinic_name}`}
              />
            </div>
            <div className="main-container-confirm" style={{ height: dimensions.height - headerHeight - BtnHeight }}>
              <div className="heading-section mt-4">
                <p>Treatments</p>
                <b onClick={() => setStep(2)}>Edit</b>
              </div>
              <hr style={{ margin: 0 }} />
              {/* Treatment Section  */}
              {selectedTreatment?.map((item, index) => (
                <div className="treatment-section">
                  <div className="treatment-content">
                    <b>{item?.name}</b>
                    <p>{`£${item?.amount}.00 • ${item?.duration} mins`}</p>
                  </div>
                  <Avatar style={{ border: 0, objectFit: 'cover' }} className="string-color" src={item?.image_url} />
                </div>
              ))}
              {/* appointment Section  */}
              <div className="heading-section mt-5">
                <p>Appointment details</p>
                <b onClick={() => { setSubStep(301); setStep(3) }}>Edit</b>
              </div>
              <hr style={{ margin: 0 }} />
              <div className="appointment-section">
                <div className="appointment-content">
                  <p>Date</p>
                  {moment(newDate, ("Do MMMM YYYY")).format('M') == 9 ?
                    <>{moment(newDate, ("Do MMMM YYYY")).format('Do')} Sept. {moment(newDate, ("Do MMMM YYYY")).format('YYYY')}</> :
                    moment(newDate, ("Do MMMM YYYY")).format('Do MMM. YYYY')}
                </div>
              </div>
              <div className="appointment-section">
                <div className="appointment-content">
                  <p>Time</p>
                  <b>{`${selectedTimes} - ${totalMins}`}</b>
                </div>
              </div>
              {/* TeamMember Section Design */}
              <div className="heading-section mt-5">
                <p>Team member</p>
                <b onClick={() => setStep(4)}>Edit</b>
              </div>
              <hr style={{ margin: 0 }} />
              <div className="treatment-section">
                <div className="treatment-content">
                  <b>{`${selectedTeamMember?.firstName}`}</b>
                  {<p>

                    {selectedTeamMember?.occupations.join(", ")}
                  </p>
                  }
                </div>
                <Avatar
                  className="string-color"
                  src={selectedTeamMember?.image_url}
                  {...stringAvatar(
                    selectedTeamMember?.initials
                  )}
                />
              </div>

              {/* Payment History section */}
              <div className="heading-section mt-5">
                <p>Payment summary</p>
              </div>
              <hr style={{ margin: 0 }} />
              <div className="payment-history-section mt-4">
                <p>{`${selectedTreatment?.length} Treatment`}</p>
                <p>{`£${totalPrice}.00`}</p>
              </div>
              <div className="payment-history-section" >
                <p style={{ textDecorationLine: "underline" }} onClick={() => setIsOpen(true)}>Discount </p>
                <div className="d-flex flex-column mb-2 text-right">
                  <p className="d-flex justify-content-end m-0">£{discountValue}</p>
                  {discountValue !== "0.00" && <span className="text-danger" style={{ textDecorationLine: "underline", cursor: "pointer" }} onClick={() => setIsRemoveOpen(true)}>Remove</span>}
                </div>
              </div>
              <div className="payment-history-section">
                <p>Subtotal</p>
                <p>{`£${getTotalPriceAfterDiscount(2)}`}</p>
              </div>
              <hr style={{ margin: 0, marginBottom: "15px" }} />
              <div className="payment-order-section">
                <b>Order total </b>
                <b>{`£${getTotalPriceAfterDiscount(0)}`}</b>
              </div>

              {/* Your Details section */}
              <div className="heading-section" style={{ marginTop: "50px" }}>
                <p>Your details</p>
              </div>
              <hr style={{ margin: 0 }} />
              <div className="your-details-section">
                <p>First name</p>
                <input
                  type="text"
                  name="clientFName"
                  data-lpignore="true"
                  value={yourDetails?.clientFName}
                  onChange={handleDetailsChange}
                  placeholder="First name"
                />
                {validationErrors.clientFName && (
                  <span className="validation-error">{validationErrors.clientFName}</span>
                )}

              </div>
              <div className="your-details-section">
                <p>Last name</p>
                <input
                  type="text"
                  name="clientLName"
                  value={yourDetails?.clientLName}
                  onChange={handleDetailsChange}
                  placeholder="Last name"
                />
                {validationErrors.clientLName && (
                  <span className="validation-error">{validationErrors.clientLName}</span>
                )}
              </div>
              <div className="your-details-section">
                <p>Add your email address</p>
                <input
                  type="text"
                  name="clientEmail"
                  value={yourDetails?.clientEmail}
                  onChange={handleDetailsChange}
                  placeholder="e.g name@gmail.com"
                />
                {validationErrors.clientEmail && (
                  <span className="validation-error">{validationErrors.clientEmail}</span>
                )}
              </div>

              {/* Payment Options section */}
              {
                totalPrice > 0 && (businessClinicList?.is_ryft_setup || businessClinicList?.is_stripe_setup || businessClinicList?.is_finance_available) &&
                <>
                  <div className="heading-section" style={{ marginTop: "50px" }}>
                    <p>Payment options</p>
                  </div>
                  <hr style={{ margin: 0 }} />

                </>
              }
              {
                totalPrice > 0 && businessClinicList?.is_finance_available &&
                <div className="payment-option-section">
                  <div className="right-payment-option-section ">
                    <MonthPayIcon />
                    <CustomRadioButton
                      text={"Pay in 3, Interest-Free"}
                      id={"Monthly payments"}
                      name="payment"
                      text2={"Opt for monthly with Payl8r."}
                      onChange={() => handlePaymentOptionChange("Monthly payments")}
                      checked={selectedPaymentOption === "Monthly payments"}
                    />
                  </div>
                </div>
              }

              {
                totalPrice > 0 && (businessClinicList?.is_ryft_setup || businessClinicList?.is_stripe_setup) &&
                <>
                  <div className="payment-option-section">
                    <div className="right-payment-option-section ">
                      <PayFullIcon />
                      <CustomRadioButton
                        text={"Pay in full"}
                        id={"Pay in full"}
                        name="payment"
                        text2={`Pay the full £${getTotalPriceAfterDiscount(0)} now.`}
                        onChange={() => handlePaymentOptionChange("Pay in full")}
                        checked={selectedPaymentOption === "Pay in full"}
                      />
                    </div>
                  </div>
                  {
                    !(totalDeposit == 0) &&
                    <div className="payment-option-section">
                      <div className="right-payment-option-section ">
                        <SplitPayIcon />
                        <CustomRadioButton
                          text={"Split payment"}
                          id={"Split payment"}
                          name="payment"
                          onChange={() => handlePaymentOptionChange("Split payment")}
                          checked={selectedPaymentOption === "Split payment"}
                          text2={`£${totalDeposit >= getTotalPriceAfterDiscount(0) ? `` : `${totalDeposit} now, £${getTotalPriceAfterDiscount(0) - totalDeposit} on the day.`}`}
                        />
                      </div>
                    </div>
                  }
                </>
              }



              {/* Pay with section */}
              {selectedPaymentOption === "Monthly paymentss" ? <>
                <div className="heading-section" style={{ marginTop: "50px" }}>
                  <p>Pay with</p>
                </div>

                <hr style={{ margin: 0 }} />
                <div className="pay-with-section" onClick={() => setIsOpenPayment(true)}>
                  <div className="right-section">
                    <DebitIcon />
                    <b>Debit or credit card</b>
                  </div>
                  <RightArrow />
                </div>
                <div className="pay-with-section" style={{ paddingBottom: "30px" }}>
                  <div className="right-section">
                    <ApplePayIcon />
                    <b>Apple Pay</b>
                  </div>
                  <RightArrow />
                </div>
              </>
                :
                null
              }


            </div>
            <div className={isRemoveOpen ? "d-none" : " fixed-button"} style={isOpen ? { zIndex: "-1" } : {}}>
              {
                (businessClinicList?.is_ryft_setup || businessClinicList?.is_stripe_setup || businessClinicList?.is_finance_available) && totalPrice > 0 ?
                  selectedPaymentOption === "Split payment" || selectedPaymentOption === "Pay in full" ?
                    <StepButton
                      blue={true}
                      id={"ryft-pay-btn"}
                      label={selectedPaymentOption === "Split payment" ? totalDeposit == 0 ? "Complete booking" : `Pay £${totalDeposit} now` : `Pay £${getTotalPriceAfterDiscount(0)} now`}
                      isLoading={loader}
                      onClick={() => handleConfirmBooking()}
                    />
                    : selectedPaymentOption === "Monthly payments" ? <StepButton blue={true} label={"Buy now Payl8r"} isLoading={loader} onClick={() => handleConfirmBooking()} />
                      :
                      totalDeposit == 0 ? <StepButton
                        label={"Select Payment Option"}
                        disabled={true}
                      /> :

                        <StepButton
                          blue={true}
                          id={"ryft-pay-btn"}
                          label={"Select Payment Option"}
                          disabled={true}

                        />

                  :
                  <StepButton
                    blue={true}
                    label={"Complete booking"}
                    disabled={!yourDetails.clientFName || !yourDetails.clientLName || !yourDetails.clientEmail || validationErrors.clientEmail}
                    onClick={() => handleConfirmBooking()}
                  />


              }

            </div>
          </div>
          <ToastContainer
            position="top-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <DiscountModal clinicId={clinicId} isOpen={isOpen} setIsOpen={setIsOpen} />
          <Modal className={"discount-modal"}
            isOpen={isRemoveOpen}
            style={{
              content: {
                borderRadius: "30px",
                padding: "20px",
                height: "310px",
                transform: "translate(0%, 70%)"
              },
              overlay: {
                background: "rgba(255, 255, 255, 0.75)"
              }
            }}
          >
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                <CloseIcon onClick={() => { setIsRemoveOpen(false) }} />
              </div>
              <div className='cancle-popup-container mt-5'>
                <h1>Are you sure?</h1>
                <p>Do you really want to remove discount code?</p>
                <div className="d-flex justify-content-between align-items-center w-100">


                  <div className="w-100">
                    <StepButton red={true} label={"Remove"} isLoading={removeLoading} onClick={() => { handleRemoveDiscount() }} />
                  </div>
                </div>
              </div>
            </>
          </Modal>

          <PatientDobWarningModal onAgreeWarning={() => {
            initConfirmBooking();
          }} ref={patientDobModalRef} />
        </div>
  );
};

export default ConfirmPay;
