// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useSearchParams } from "react-router-dom";

const GET_SAVED_CARDS_USER = gql`
query GetSavedCardsOfUser {
    getSavedCardsOfUser {
      success
      message
      data {
        cards {
          cardNumber
          cardExpMonth
          cardExpYear
          savedCardId
        }
        totalCards
      }
    }
  }
`;

export const GetSavedCardsOfUserAction = () => {
    // const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [
        getSavedCardsOfUserResponseFunction,
        getSavedCardsOfUserResponseData,
    ] = useLazyQuery(GET_SAVED_CARDS_USER, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        context: {
            headers: {
                ['authorization']: `${searchParams.get('usertoken')}`
            }
        },
        onCompleted: ({ getSavedCardsOfUser: responseData }) => {
            if (responseData.success) {
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getSavedCardsOfUserQueryData = getSavedCardsOfUserResponseData; //variable

    const initGetSavedCardsOfUserData = (data, successCallback, errorCallback) => {
        getSavedCardsOfUserResponseFunction({
            variables: data,
        }).then((responseData) => {
            if (responseData.data.getSavedCardsOfUser.success) {
                successCallback(responseData);
            } else {
                errorCallback(responseData);
            }
        }).catch((err) => {
            errorCallback(err);
        });
    };

    return { getSavedCardsOfUserQueryData, initGetSavedCardsOfUserData };
};
