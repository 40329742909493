import React, { useEffect, useRef, useState } from 'react'
import './share-booking-link.scss'
import { ReactComponent as EmailIcon } from '../../../../components/assets/images/calendar/emailIcon.svg'
import { ReactComponent as CopyIcon } from '../../../../components/assets/images/calendar/copyIcon.svg'
import { ReactComponent as SMSIcon } from '../../../../components/assets/images/calendar/smsIcon.svg'
import { toast } from 'react-toastify'
import { SaveBookingLink } from '../../../redux/actions/bookingsLink/SaveBookingLink'
import { GetBusinessBookingLink, GetClientList_v3Action, SendBookingLinkThroughEmailAction, SendBookingLinkThroughSMSAction } from '../../../redux/actions/bookingsLink/GetBookingLink'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAR_BOOKING_LINK_DATA } from '../../../redux/constants/bookingLink/BookingLinkConst'
import CustomModal from '../../../modal/CustomModal'
import CustomAutoComplete from '../../../auto-complete/CustomAutoComplete'
import StepButton from '../../../common/StepButton'
import { APPEND_CLIENT_LIST_WITH_SKELETON, CLEAR_CLINIC_LIST } from '../../../redux/constants'
import { conforms, debounce, set } from 'lodash'
import NavHeader from '../../../common/NavHeader/NavHeader'
import { useNavigate  , useLocation} from 'react-router'
import { businessDashboardRoute, getCalendarRoute } from '../../../../Routes'
import QRCode from 'qrcode.react';
import Skeleton from 'react-loading-skeleton'
import showToast from '../../../common/CustomToast/CustomToast'
import {bookingLinkRegex} from "../../../../utils/Regex";
import { getBusinessProfileSettingPage } from '../../../../Routes'
const ShareBookingLink = ({ endpoint = "endpoint" }) => {
    const listRef = useRef();
    const navigate = useNavigate()

    // const prefix = 'https://staging.facesconsent.com/v1/bookings/'
    const location = useLocation()
    const [editableText, setEditableText] = useState("")
    const [copyText, setCopyText] = useState('')
    const textRef = React.useRef(null)
    const [isTextInputClicked, setIsTextInputClicked] = useState(false);
    const dispatch = useDispatch()
    const { bookingLink } = useSelector((state) => state?.bookingLinkReducer?.bookingLink);
    const [clientsModal, setClientsModal] = useState(false);
    const [type, setType] = useState("")
    const [prefix, setPrefix] = useState("")

    const [allClientsModal, setAllClientsModal] = useState(false);

    const [qrCodeLinkValue, setQrCodeLinkValue] = useState("")

    //CLIENTS DATA SEARCH
    const [searchString, setSearchString] = useState(null);

    const [allClients, setAllclients] = useState(false);
    const [value, setValue] = useState([]);
    const handleOptionChange = (event, newValue) => {
        // newValue = newValue.filter(e => e?.title !== "No Client Found")
        setValue(newValue);
        setFormIncorrect(false);
    };


    const { clinicList } = useSelector(state => state?.GetShareBookingLinkReducer)
    const { totalClinicCount } = useSelector(state => state?.GetShareBookingLinkReducer)
    const { loadedClinicCount } = useSelector(state => state?.GetShareBookingLinkReducer)
    const { clinicListPage } = useSelector(state => state?.GetShareBookingLinkReducer)

    //ACTIONS
    //Save Booking Link Mutation
    const { initiateSaveBookingLink, saveBookingLinkMutationData } = SaveBookingLink();
    const { initGetBusinessBookingLink } = GetBusinessBookingLink()

    //CLIENT LISTING
    const { getClientList_v3QueryData: {
        loading: getClientList_v3Loading,
    }, initGetClientList_v3Data } = GetClientList_v3Action()

    const { initSendBookingLinkThroughEmailData } = SendBookingLinkThroughEmailAction()
    const { initSendBookingLinkThroughSMSData } = SendBookingLinkThroughSMSAction()

    const [formIncorrect, setFormIncorrect] = useState(false);

    useEffect(() => {
        if (getClientList_v3Loading && clinicListPage != 1) {
            dispatch({
                type: APPEND_CLIENT_LIST_WITH_SKELETON,
            })
        }
    }, [getClientList_v3Loading]);

    //INFINITE SCROLL FOR CLIENTS DATA

    const initClientList = (refreshList = false) => {
        let newPage = clinicListPage + 1;
        if (refreshList) {
            newPage = 1;
        } else if (totalClinicCount <= loadedClinicCount) {
            return;
        }

        initGetClientList_v3Data({
            page: newPage,
            limit: 10,
            search: searchString,
            deletedClients: false,
            requestFrom: type == "email" ? "searchEmails" : "searchPhoneNumbers",
            // requestFrom: "",
        })
    };

    console.log(prefix, "prefix", editableText, "editableText", qrCodeLinkValue, "qrCodeLinkValue")

    //DEBOUNCE FOR SEARCH
    // Debounced API call function
    const debouncedApiCall = debounce((query) => {
        // Make the API call using the search query
        // Replace this with your own API call logic
        dispatch({
            type: APPEND_CLIENT_LIST_WITH_SKELETON,
            page: 1,
        })
        initClientList(true)
    }, 500); // Debounce delay in milliseconds (e.g., 500ms)


    useEffect(() => {
        if (searchString != null) {
            // Call the debounced API function with the search query
            debouncedApiCall(searchString);

            // Cleanup function to cancel any pending debounced calls
            return () => {
                debouncedApiCall.cancel();
            };
        }
    }, [searchString]);

    const handleScrollCallback = () => {
        if (listRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listRef.current;

            if (
                scrollTop + clientHeight === scrollHeight &&
                totalClinicCount > loadedClinicCount
            ) {
                initClientList();
            }
        }
    };

    useEffect(() => {
        if (bookingLink !== undefined) {
            let a = copyText + bookingLink
            setCopyText(a)
            setEditableText(bookingLink)
        }
    }, [])

    useEffect(() => {
    }, [editableText, qrCodeLinkValue])

    const handleKeyDown = (e) => {
        // Check if the Backspace key is pressed
        if (e.key === 'Backspace') {
            setIsTextInputClicked(true);
        } else {
            setIsTextInputClicked(true);
        }
    };

    //BUSINESS BOOKING LINK
    useEffect(() => {
        initGetBusinessBookingLink({}, (success) => {
            if (success) {
                console.log("success", success)
                const segments = success.baseLink.split('/');
                segments.pop();
                setPrefix(segments.join("/") + "/")
                let a = segments.join("/") + "/" + success.bookingLink
                setCopyText(a)
                setEditableText(success.bookingLink)
                setQrCodeLinkValue(a)
            }
        }, (error) => {
        })
    }, [])
    {
        console.log("tevfdte----", !bookingLinkRegex.test(editableText))
    }
    const handleSaveBookingLink = () => {
        if (  editableText.trim() == "") {

            // adding toastId will prevent duplicate toast being rendered!
            showToast("error", 'Please enter valid link', "", "", undefined, undefined, { toastId: 'invalid-link-error-toast' });
            setIsTextInputClicked(true);
        }else if (!bookingLinkRegex.test(editableText)){
            showToast("error", 'Special character except - and _ are not allowed ', "", "", undefined, undefined, { toastId: 'invalid-link-error-toast' });
            setIsTextInputClicked(true);
        }
         else {

            initiateSaveBookingLink(
                {
                    bookingLink: editableText.trim()
                },
                (success) => {
                    console.log("success", success)
                    if (success.success) {
                        showToast("success", success.message, "", "")
                        setIsTextInputClicked(false);
                        setQrCodeLinkValue(copyText)
                    }
                    else {
                        showToast("error", success.message, "", "")
                        setIsTextInputClicked(true);
                    }

                },
                (error) => {
                    showToast("error", error.message, "", "")
                    setIsTextInputClicked(false);
                }
            )
        }
    }

    const handleCopyBookingLink = () => {
        // editableText.trim() == "" ? toast.error('please enter endpoint!') : 
        // toast.success(copyText + ' copied successfully!')
        if (editableText?.trim() == "") {
            showToast("error", 'Please enter valid link', "", "", undefined, undefined, { toastId: 'invalid-link-error-toast' })

        } else {
            navigator.clipboard.writeText(copyText)
            showToast("success", 'Booking link copied successfully', "", "", undefined, undefined, { toastId: 'copy-link-toast' })
        }
    }

    //handle Send Email
    const handleSendEmail = (shareType) => {
        dispatch({
            type: CLEAR_CLINIC_LIST
        })
        if (editableText.trim() == "") {
            showToast("error", 'Please enter valid link', "", "", undefined, undefined, { toastId: 'invalid-link-error-toast' })
        } else {
            setType(shareType);
            setClientsModal(true);
        }
        // window.open(`mailto:email@example.com?subject=Subject&body=${copyText}`)
    }

    const handleClientsForm = (e) => {
        e.preventDefault();
        let temp = [];
        if (type == "email") {
            if (value.length > 0 || allClients) {
                temp = value.map((e) => e.clientEmail);
            } else {
                showToast("error", "Please select atleast one client", "", "", undefined, undefined, { toastId: 'select-atleast-one-client-error-toast' })
                setFormIncorrect(true);
                return
            }

            if (allClients) {
                setAllClientsModal(true)
                return
            }

            initSendBookingLinkThroughEmailData({
                emails: temp,
                sendToAllClients: allClients
            },
                (success) => {
                    // toast.success(success.message)
                    showToast("success", success.message, "", "")
                    setValue([])
                    setClientsModal(false)
                },
                (error) => {
                    showToast("error", error.message, "", "")
                }
            )
        } else {
            if (value.length > 0 || allClients) {
                temp = value.map((e) => e.readableContact);
            } else {
                showToast("error", "Please select atleast one client", "", "")
                setFormIncorrect(true);
                return
            }

            if (allClients) {
                setAllClientsModal(true)
                return
            }
            initSendBookingLinkThroughSMSData({
                contacts: temp,
                sendToAllClients: allClients
            },
                (success) => {
                    showToast("success", success.message, "", "")
                    setValue([])
                    setClientsModal(false)
                },
                (error) => {
                    showToast("error", error.message, "", "")
                }
            )
        }

    };

    const handleAllclientsConfirmation = () => {
        setAllClientsModal(true)
        setClientsModal(false)
        if (type == "email") {

            initSendBookingLinkThroughEmailData({
                emails: [],
                sendToAllClients: true
            },
                (success) => {
                    showToast("success", success.message, "", "")
                    setAllclients(false)
                    setAllClientsModal(false)
                    setClientsModal(false)
                },
                (error) => {
                    showToast("error", error.message, "", "")
                }
            )
        } else {
            initSendBookingLinkThroughSMSData({
                contacts: [],
                sendToAllClients: true
            },
                (success) => {
                    showToast("success", success.message, "", "")
                    setAllclients(false)
                    setAllClientsModal(false)
                    setClientsModal(false)
                },
                (error) => {
                    showToast("error", error.message, "", "")
                }
            )
        }
    }

    console.log("teext",editableText,copyText)

    return (
        <>
            <NavHeader isClose={true} applyPadding={false} onClickCallback={() => {
               


                 if (location?.state?.from === "booking_link_from_home") {
                    navigate(businessDashboardRoute())
              
                  }
                  else {
                    navigate(getCalendarRoute())
                  }
            }} />
            <div className="share-booking-section">
                <div className="container">
                    <div className='d-flex justify-content-center'>

                        {
                            qrCodeLinkValue != "" ?
                                <QRCode value={qrCodeLinkValue} renderAs="canvas" size={150}
                                    style={{ width: "150px", height: "170px", objectFit: "contain" }}
                                /> :
                                <Skeleton width={180} height={180} />
                        }
                    </div>
                    <div className='share-booking-link-main-div'>
                        <h4>
                            Share booking link
                        </h4>

                        <p>
                            Provide your clients with a link to view available slots and schedule an appointment directly.
                        </p>
                        <div className="share-booking-link-input-div">
                            <div className="row mx-0">
                                <div className="col-10 ps-0">
                                    <input
                                        name="titre"
                                        id="exampleEmail"
                                        type="text"
                                        ref={textRef}
                                        value={copyText}
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) => {
                                            const input = e.target.value
                                            let temp
                                            if (input.substr(prefix.length)?.charAt(0) == "/") {
                                                temp = input.substr(prefix.length).slice(1)
                                            } else {
                                                temp = input.substr(prefix.length)
                                            }
                                            setEditableText(temp)
                                            
                                            e.target.value = prefix + temp
                                            if (e?.target?.value)
                                                setCopyText(e?.target?.value)
                                        }}

                                    />
                                </div>
                                <div className="col-2">
                                    <button className={isTextInputClicked ? "save-btn" : "copy-btn"} onClick={() => isTextInputClicked ? handleSaveBookingLink() : handleCopyBookingLink()} >{isTextInputClicked ? "Save" : "Copy"}</button>
                                </div>
                            </div>
                        </div>
                        <div className="icon-images-div">
                            <div className='icon-image' onClick={() => handleSendEmail("email")}>
                                <EmailIcon />
                                <p>Email</p>
                            </div>
                            <div className='icon-image' onClick={() => handleCopyBookingLink()} >
                                <CopyIcon />
                                <p>Copy</p>
                            </div>
                            <div className='icon-image' onClick={() => handleSendEmail("mobile")}>
                                <SMSIcon />
                                <p>SMS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* ========================SHARE BOOKING LINK TO CLIENT MODAL======================== */}
            <CustomModal
                modalOpen={clientsModal}
                // modalOpen={true}
                setModalOpen={setClientsModal}
                type={"common-width"}
                modaltitle={"Select clients"}
                isManuallyClose={true}
                className={'SelectClientsModel'}
                modalCloseCallback={() => {
                    setClientsModal(false)
                    setValue([])
                    setAllclients(false)
                }}
                modalBody={
                    <>
                        {
                            <form
                                className="d-flex flex-column mt-2"
                                onSubmit={handleClientsForm}
                            >
                                <div className='checkBoxwrapper'>
                                    <input
                                        type="checkbox"
                                        name="all"
                                        id="all"
                                        // checked={allClients}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setFormIncorrect(false);
                                            }
                                            setAllclients(e.target.checked)
                                        }}
                                        className='cursor-pointer'
                                    />
                                    <label htmlFor="all" className="ms-2 cursor-pointer">
                                        Select all clients
                                    </label>
                                </div>
                                {!allClients && (
                                    <CustomAutoComplete
                                        multiple={true}
                                        groupBy={false}
                                        listRef={listRef}
                                        setSearchString={setSearchString}
                                        handleInputClick={() => {
                                            dispatch({
                                                type: CLEAR_CLINIC_LIST
                                            })
                                            initClientList(true)
                                        }}
                                        loading={getClientList_v3Loading}
                                        optionValue={clinicList}
                                        value={value}
                                        setValue={setValue}
                                        handleOptionChange={handleOptionChange}
                                        handleScrollCallback={handleScrollCallback}
                                        disableCloseOnSelect={true}
                                    />
                                )}
                                <StepButton disabled={formIncorrect} type={'submit'} label={"Share Booking Link"} blue={true} />
                            </form>
                        }
                    </>
                }
            />

            {/* ALL CLIENTTS CONFIRMATION MODAL */}
            <CustomModal
                modalOpen={allClientsModal}
                // modalOpen={true}
                setModalOpen={setAllClientsModal}
                type={"common-width"}
                className={"booking-link-margin-0"}


                modalBody={
                    <>
                        <div className="share-booking-link-all-clients-confimration">
                            <p>
                                Are you sure you want to share booking link to all clients?
                            </p>
                            <div className="row">

                                <div className='col-md-6'>
                                    <StepButton red={true} label={"Cancel"} onClick={() => setAllClientsModal(false)} />
                                </div>
                                <div className='col-md-6'>
                                    <StepButton label={"Yes"} onClick={() => {
                                        handleAllclientsConfirmation()
                                    }} blue={true} />
                                </div>
                            </div>
                        </div>

                    </>
                }
            />

        </>
    )
}

export default ShareBookingLink