import { 
    GET_SUPPORT_CHAT_DATA, 
    GET_SUPPORT_CHAT_DATA_ERROR, 
    GET_SUPPORT_CHAT_DATA_SUCCESS, 
    GET_THREAD_CHAT_DATA, 
    GET_THREAD_CHAT_DATA_SUCCESS, 
    GET_THREAD_CHAT_DATA_ERROR, 
    SUPPORT_CHAT_CATEGORIES_DATA, 
    SUPPORT_CHAT_CATEGORIES_DATA_SUCCESS, 
    SUPPORT_CHAT_CATEGORIES_DATA_ERROR, 
    CREATE_SUPPORT_THREADS_DATA, 
    CREATE_SUPPORT_THREADS_DATA_SUCCESS, 
    CREATE_SUPPORT_THREADS_DATA_ERROR,
    SUPPORT_RECENT_ORDERS_DATA,
    SUPPORT_RECENT_ORDERS_DATA_SUCCESS,
    SUPPORT_RECENT_ORDERS_DATA_ERROR,
    GET_ORDER_TRACE_DATA,
    GET_ORDER_TRACE_DATA_SUCCESS,
    GET_ORDER_TRACE_DATA_ERROR,
    CREATE_SUPPOR_CHAT_THREADS_DATA,
    CREATE_SUPPOR_CHAT_THREADS_DATA_SUCCESS,
    CREATE_SUPPOR_CHAT_THREADS_DATA_ERROR,
    SUPPORT_THREADS_ADD_FEEDBACK_DATA,
    SUPPORT_THREADS_ADD_FEEDBACK_DATA_SUCCESS,
    SUPPORT_THREADS_ADD_FEEDBACK_DATA_ERROR,
    SUPPORT_THREADS_CHAT_UPDATE_DATA,
    SUPPORT_THREADS_CHAT_UPDATE_DATA_SUCCESS,
    SUPPORT_THREADS_CHAT_UPDATE_DATA_ERROR,
    SUPPORT_THREADS_MESSAGES_MARK_DATA,
    SUPPORT_THREADS_MESSAGES_MARK_DATA_SUCCESS,
    SUPPORT_THREADS_MESSAGES_MARK_DATA_ERROR,
    SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA,
    SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA_SUCCESS,
    SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA_ERROR,
} from "../../constants/support-chat/support-chat"

export function getSupportChatData(data) {
    return {
        type: GET_SUPPORT_CHAT_DATA,
        payload: data,
    }
}

export function getSupportChatDataSuccess(data) {
    return {
        type: GET_SUPPORT_CHAT_DATA_SUCCESS,
        payload: data
    }
}
export function getSupportChatDataError(data) {
    return {
        type: GET_SUPPORT_CHAT_DATA_ERROR,
        payload: data
    }
}

// ===============================================================

export function getThreadChatData(data) {
    return {
        type: GET_THREAD_CHAT_DATA,
        payload: data
    }
}

export function getThreadChatDataSuccess(data) {
    return {
        type: GET_THREAD_CHAT_DATA_SUCCESS,
        payload: data
    }
}

export function getThreadChatDataError(data) {
    return {
        type: GET_THREAD_CHAT_DATA_ERROR,
        payload: data
    }
}

// ===============================================================

export function getSupportChatCategoriesData(data) {
    return {
        type: SUPPORT_CHAT_CATEGORIES_DATA,
        payload: data
    }
}

export function getSupportChatCategoriesDataSuccess(data) {
    return {
        type: SUPPORT_CHAT_CATEGORIES_DATA_SUCCESS,
        payload: data
    }
}

export function getSupportChatCategoriesDataError(data) {
    return {
        type: SUPPORT_CHAT_CATEGORIES_DATA_ERROR,
        payload: data
    }
}

// ===============================================================

export function createSupportThreadsData(data) {
    return {
        type: CREATE_SUPPORT_THREADS_DATA,
        payload: data
    }
}

export function createSupportThreadsDataSuccess(data) {
    return {
        type: CREATE_SUPPORT_THREADS_DATA_SUCCESS,
        payload: data
    }
}

export function createSupportThreadsDataError(data) {
    return {
        type: CREATE_SUPPORT_THREADS_DATA_ERROR,
        payload: data
    }
}

// ===============================================================
export function getSupportRecentOrdersData(data) {
    return {
        type: SUPPORT_RECENT_ORDERS_DATA,
        payload: data
    }
}

export function getSupportRecentOrdersDataSuccess(data) {
    return {
        type: SUPPORT_RECENT_ORDERS_DATA_SUCCESS,
        payload: data
    }
}

export function getSupportRecentOrdersDataError(data) {
    return {
        type: SUPPORT_RECENT_ORDERS_DATA_ERROR,
        payload: data
    }
}

// ===============================================================

export function getOrderTraceData(data) {
    return {
        type: GET_ORDER_TRACE_DATA,
        payload: data
    }
}

export function getOrderTraceDataSuccess(data) {
    return {
        type: GET_ORDER_TRACE_DATA_SUCCESS,
        payload: data
    }
}

export function getOrderTraceDataError(data) {
    return {
        type: GET_ORDER_TRACE_DATA_ERROR,
        payload: data
    }
}

// ===============================================================

export function createSupportChatThreadsData(data) {
    return {
        type: CREATE_SUPPOR_CHAT_THREADS_DATA,
        payload: data
    }
}

export function createSupportChatThreadsDataSuccess(data) {
    return {
        type: CREATE_SUPPOR_CHAT_THREADS_DATA_SUCCESS,
        payload: data
    }
}

export function createSupportChatThreadsDataError(data) {
    return {
        type: CREATE_SUPPOR_CHAT_THREADS_DATA_ERROR,
        payload: data
    }
}

// ===============================================================

export function supportThreadsAddFeedbackData(data) {
    return {
        type: SUPPORT_THREADS_ADD_FEEDBACK_DATA,
        payload: data
    }
}

export function supportThreadsAddFeedbackDataSuccess(data) {
    return {
        type: SUPPORT_THREADS_ADD_FEEDBACK_DATA_SUCCESS,
        payload: data
    }
}

export function supportThreadsAddFeedbackDataError(data) {
    return {
        type: SUPPORT_THREADS_ADD_FEEDBACK_DATA_ERROR,
        payload: data
    }
}

// ===============================================================

export function supportThreadsChatUpdateData(data) {
    return {
        type: SUPPORT_THREADS_CHAT_UPDATE_DATA,
        payload: data
    }
}

export function supportThreadsChatUpdateDataSuccess(data) {
    return {
        type: SUPPORT_THREADS_CHAT_UPDATE_DATA_SUCCESS,
        payload: data
    }
}

export function supportThreadsChatUpdateDataError(data) {
    return {
        type: SUPPORT_THREADS_CHAT_UPDATE_DATA_ERROR,
        payload: data
    }
}

// ===============================================================

export function supportThreadMessagesMarkRead(data) {
    return {
        type: SUPPORT_THREADS_MESSAGES_MARK_DATA,
        payload: data
    }
}

export function supportThreadMessagesMarkReadSuccess(data) {
    return {
        type: SUPPORT_THREADS_MESSAGES_MARK_DATA_SUCCESS,
        payload: data
    }
}

export function supportThreadMessagesMarkReadError(data) {
    return {
        type: SUPPORT_THREADS_MESSAGES_MARK_DATA_ERROR,
        payload: data
    }
}

// ===============================================================

export function supportChatTotalUnreadCountData(data) {
    return {
        type: SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA,
        payload: data
    }
}

export function supportChatTotalUnreadCountDataSuccess(data) {
    return {
        type: SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA_SUCCESS,
        payload: data
    }
}

export function supportChatTotalUnreadCountDataError(data) {
    return {
        type: SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA_ERROR,
        payload: data
    }
}