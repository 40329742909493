import React from 'react'
import "./custom-calendar-skeleton.scss"
export const CustomCalendarSkeleton = ({ fromFilterCanvas = false }) => {
  return (
    <div className='calendar-skeleton-wrapper'>
      <div className='custom-calendar-skeleton-web'>
        <div className='d-flex flex-wrap'>
          {[...Array(fromFilterCanvas ? 35 : 45)].map((tempItem, tempIndex) => {
            return (
              <div className={fromFilterCanvas ? "date-items-filter" : "date-items"}>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  )
}
