import { gql, useLazyQuery } from "@apollo/client";

const GET_LOCATION_FIXED_HOUR_FOR_DAY_QUERY = gql`
query GetLocationFixedHourForDay($locationId: Int, $day: Int) {
  getLocationFixedHourForDay(locationId: $locationId, day: $day) {
    success
    message
    data {
      locationId
      day
      isAvailable
      startTime
      endTime
      breaks {
        breakId
        breakStartTime
        breakEndTime
      }
    }
  }
}
`;

export const GetLocationFixedHourForDayAction = () => {
    const [
        GetLocationFixedHourForDayResponseFunction,
        getLocationFixedHourForDayResponseData,
    ] = useLazyQuery(GET_LOCATION_FIXED_HOUR_FOR_DAY_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getLocationFixedHourForDay: responseData }) => {
            if (responseData.success) {
            } else {
            }
        },
    });
    //this will be called from components
    const getLocationFixedHourForDayQueryData = getLocationFixedHourForDayResponseData; //variable

    const initGetLocationFixedHourForDayData = (data, successCallback, errorCallback) => {
        GetLocationFixedHourForDayResponseFunction({
            variables: data,
        }).then((res) => {
            if (res?.data?.getLocationFixedHourForDay?.success)
                successCallback(res.data.getLocationFixedHourForDay)
        }).catch((err) => {
            errorCallback(err)
        });
    };

    return { getLocationFixedHourForDayQueryData, initGetLocationFixedHourForDayData };
};