import { CLEAR_PAYMENT_METHOD_AVAILABLE, GET_PAYMENT_METHOD_AVAILABLE } from "../../constants/forward-consent-form/ForwardConsentFormConst"



const initialState = {
    paymentMethodAvailableDetails: [],
}

export const paymentMethodAvailableReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAYMENT_METHOD_AVAILABLE:
            return {
                ...state,
                paymentMethodAvailableDetails: action.payload?.payment_data,
            }
        case CLEAR_PAYMENT_METHOD_AVAILABLE:
            return {
                ...state,
                paymentMethodAvailableDetails: [],
            }
        default:
            return state
    }
}