import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_TESTIMONIALS_QUERY = gql`
  query GetListOfTestimonials {
  getListOfTestimonials {
    success
    message
    data {
      TestimonialsList {
        id
        name
        review
        rating
        image
        video
        twitterUrl
        linkedinUrl
        igUrl
      }
    }
  }
}
`;

export const GetTestimonialsAction = () => {
  const [
    getTestimonialsResponseFunction,
    getTestimonialsResponseData,
  ] = useLazyQuery(GET_TESTIMONIALS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getListOfTestimonials: responseData }) => {
      if (responseData.success) {
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });

  // This will be called from components
  const getTestimonialsQueryData = getTestimonialsResponseData; // updated variable name

  const initGetTestimonialsData = (data) => {
    getTestimonialsResponseFunction({
      variables: data,
    });
  };

  return { getTestimonialsQueryData, initGetTestimonialsData };
};
