import axios from '../../../axios/index'
import { API_URL, BASE_URL } from "../../../axios/config";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import { GET_INSURANCE_COMPANY_DATA } from "../../constants/index";
import { 
    getInsuranceCompanyDataError, 
    getInsuranceCompanyDataSuccess,
    getInsuranceCompanyPageDataError, 
    getInsuranceCompanyPageDataSuccess,
} from '../../actions';


async function getInsuranceCompanyDataAPI(data) {
    console.log(data, "payload")
    console.log(BASE_URL + API_URL.INSYNC_INSURANCE_COMPANY_LIST,'list');
    return axios.request({
        method: "post",
        url: BASE_URL + API_URL.INSYNC_INSURANCE_COMPANY_LIST,
        data:data
    });
}

function* getInsuranceCompanyDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        console.log("getclientTestimonialApi called", payload);
        let response = yield call(getInsuranceCompanyDataAPI,payload);
        console.log("response",response)
        // yield put(setLoaderComplete());
        if (response.status === 200) {
            // console.log("response====>", response.data);
            if (response.data.success) {
                yield put(getInsuranceCompanyDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        console.log("response error =====>", error);
        // yield put(setLoaderComplete());
        yield put(getInsuranceCompanyDataError(error));
        if (reject) reject(error);
    }
}

export function* getInsuranceCompanyDataWatcher() {
    yield takeLatest(GET_INSURANCE_COMPANY_DATA, getInsuranceCompanyDataAction);
}

async function getInsuranceCompanyPageDataAPI(data) {
    console.log(data, "payload")
    console.log(BASE_URL + API_URL.INSYNC_INSURANCE_COMPANY_PAGE_LIST,'list');
    return axios.request({
        method: "post",
        url: BASE_URL + API_URL.INSYNC_INSURANCE_COMPANY_PAGE_LIST,
        data:data
    });
}

function* getInsuranceCompanyPageDataAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        console.log("getclientTestimonialApi called", payload);
        let response = yield call(getInsuranceCompanyPageDataAPI,payload);
        console.log("response",response)
        // yield put(setLoaderComplete());
        if (response.status === 200) {
            // console.log("response====>", response.data);
            if (response.data.success) {
                yield put(getInsuranceCompanyPageDataSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        console.log("response error =====>", error);
        // yield put(setLoaderComplete());
        yield put(getInsuranceCompanyPageDataError(error));
        if (reject) reject(error);
    }
}

export function* getInsuranceCompanyPageDataWatcher() {
    yield takeLatest(GET_INSURANCE_COMPANY_DATA, getInsuranceCompanyPageDataAction);
}
