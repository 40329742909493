import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import LandingPagebusinessHeader from "../../LandingPagebusinessHeader/LandingPagebusinessHeader";
import LandingPagebusinessFooter from "../../LandingPagebusinessFooter/LandingPage-business-footer";
import { ReactComponent as ConsultationIcon } from "../../../images/HairCarePage/Consultation.svg";
import { ReactComponent as PrescriptionIcon } from "../../../images/HairCarePage/Prescription.svg";
import { ReactComponent as DiscreetIcon } from "../../../images/HairCarePage/Discreet.svg";
import { ReactComponent as MedicalIcon } from "../../../images/HairCarePage/Medical.svg";
import { ReactComponent as ProductIcon } from "../../../images/HairCarePage/Product.svg";
import { ReactComponent as CheckIcon } from "../../../images/HairCarePage/CheckIcon.svg";
import rightAssets from "../../../images/HairCarePage/rightAssets.png";
import bottomLeft from "../../../images/HairCarePage/bottomLeft.png";
import whatsapp from "../../../images/HairCarePage/whatsapp.png";
import bannerImg from "../../../images/HairCarePage/banner.png";
import stepOne from "../../../images/HairCarePage/step/stepOne.png";
import stepTwo from "../../../images/HairCarePage/step/stepTwo.png";
import stepThree from "../../../images/HairCarePage/step/stepThree.png";
import stepFour from "../../../images/HairCarePage/step/stepFour.png";
import stepFive from "../../../images/HairCarePage/step/stepFive.png";

import clientOneProfile from "../../../images/HairCarePage/Testimonial/clientOneProfile.png";
import clientBeforeOne from "../../../images/HairCarePage/Testimonial/clientBeforeOne.png";
import clientafterOne from "../../../images/HairCarePage/Testimonial/clientafterOne.png";
import clientTwoProfile from "../../../images/HairCarePage/Testimonial/clientTwoProfile.png";
import clientBeforeTwo from "../../../images/HairCarePage/Testimonial/clientBeforeTwo.png";
import clientafterTwo from "../../../images/HairCarePage/Testimonial/clientafterTwo.png";
import clientThreeProfile from "../../../images/HairCarePage/Testimonial/clientThreeProfile.png";
import clientBeforeThree from "../../../images/HairCarePage/Testimonial/clientBeforeThree.png";
import clientafterThree from "../../../images/HairCarePage/Testimonial/clientafterThree.png";
import clientFourProfile from "../../../images/HairCarePage/Testimonial/clientFourProfile.png";
import clientBeforeFour from "../../../images/HairCarePage/Testimonial/clientBeforeFour.png";
import clientafterFour from "../../../images/HairCarePage/Testimonial/clientafterFour.png";
import planOne from "../../../images/HairCarePage/planOne.png";
import planTwo from "../../../images/HairCarePage/planTwo.png";
import planThree from "../../../images/HairCarePage/planThree.png";

import chartImg from "../../../images/HairCarePage/chart.png";
import qrcode from "../../../images/HairCarePage/qrcode.png";
import Topical from "../../../images/HairCarePage/Topical.png";
import OralHair from "../../../images/HairCarePage/OralHair.png";
import TopicalandOralHairLoss from "../../../images/HairCarePage/TopicalandOralHairLoss.png";
import AOS from "aos";
import "aos/dist/aos.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./HairCareLandingPage.scss";
import { id } from "date-fns/locale";

import { useNavigate } from "react-router-dom";
import { frontendURL, isLiveSystem } from "../../../config";
import { HairPrescriptionProductsRoutePattern } from "../../../Routes";


function HairCareLandingPage() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      offset: 200,
      once: true, // Set once to true to trigger animations only once
    });
  }, []);

  var settings1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    initialSlide: 0,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          className: "center",
          // dots: true
        },
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          className: "center",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const TabContent = ({ id, children }) => (
    <div className="tab-pane fade" id={id} role="tabpanel">
      {children}
    </div>
  );

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const handleHairProductNavigation = () => {
    navigate(HairPrescriptionProductsRoutePattern);
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsNew = {
    arrows: false,
  };

  const TreatmentProData = [
    {
      id: 1,
      TreatmentName: "Fundamental",
      TreatmentDesc:
        "A topical alcohol-free solution with prescription grade finasteride 0.1 % or minoxidil 5% depending on your suitable prescription.",
      TreatmentPrice: "£33.00/ month",
      TreatmentImg: planThree,
    },
    {
      id: 2,
      TreatmentName: "Comprehensive",
      TreatmentDesc:
        "A topical alcohol-free solution with prescription grade finasteride 0.1% or minoxidil 5% compounded with 5% azelaic acid increasing DHT Inhibition",
      TreatmentPrice: "£36.33/ month",
      TreatmentImg: planThree,
    },
    // {
    //   id: 3,
    //   TreatmentName: "Combination package",
    //   TreatmentDesc:"The combined package of a topical solution and oral tablet to encourage hair growth and reduce hair loss.",
    //   TreatmentPrice: "£36.34/month",
    //   TreatmentImg: TopicalandOralHairLoss,
    // },
  ];

  const TrustedData = [
    {
      id: 1,
      clientReview:
        "Since using the bespoke minoxidil that was prescribed to me I've seen hair regrowth as well as thicker hair",
      clientName: "David ellis",
      clientImg: clientOneProfile,
      BeforeImg: clientBeforeOne,
      afterImg: clientafterOne,
    },
    {
      id: 2,
      clientReview: "6 months post topical solution only",
      clientName: "Stacy bella",
      clientImg: clientTwoProfile,
      BeforeImg: clientBeforeTwo,
      afterImg: clientafterTwo,
    },
    {
      id: 3,
      clientReview:
        "1 Year post treatment consisting of topical + oral combination. Maintaining healthy lifestyle and supplementing vitamins",
      clientName: "Jason john",
      clientImg: clientThreeProfile,
      BeforeImg: clientBeforeThree,
      afterImg: clientafterThree,
    },
    {
      id: 4,
      clientReview:
        "treatment images 3 and 6 months post treatment with topical solution only and vitamins",
      clientName: "Jennie mane",
      clientImg: clientFourProfile,
      BeforeImg: clientBeforeFour,
      afterImg: clientafterFour,
    },
  ];

  const PlanData = [
    {
      id: 1,
      planImg: planThree,
      planProductTitle: "Fundamental",
      planProductDesc:
        "A topical alcohol-free solution with prescription grade finasteride 0.1 % or minoxidil 5% depending on your suitable prescription.",
      planPoints: [
        {
          id: 1,
          PointsLable: "Effectiveness",
          PointsData: "over 9/10 people",
        },
        {
          id: 2,
          PointsLable: "Format",
          PointsData: "Pipette",
        },
        {
          id: 3,
          PointsLable: "Results",
          PointsData: "in 1-2 months",
        },
      ],
      planfrequency: [
        {
          id: 1,
          planMonth: "1",
          planMonthPrice: "£65",
        },
        {
          id: 2,
          planMonth: "3",
          planMonthPrice: "£99/3",
          saveTag: true,
          saveDiscount: "49.23%",
        },
      ],
    },
    {
      id: 2,
      planImg: planThree,
      planProductTitle: "Comprehensive",
      planProductDesc:
        "A topical alcohol-free solution with prescription grade finasteride 0.1% or minoxidil 5% compounded with 5% azelaic acid increasing DHT Inhibition",
      planPoints: [
        {
          id: 1,
          PointsLable: "Effectiveness",
          PointsData: "over 9/10 people",
        },
        {
          id: 2,
          PointsLable: "Format",
          PointsData: "Pipette",
        },
        {
          id: 3,
          PointsLable: "Results",
          PointsData: "in 1-2 months",
        },
      ],
      planfrequency: [
        {
          id: 1,
          planMonth: "1",
          planMonthPrice: "£70",
        },
        {
          id: 2,
          planMonth: "3",
          planMonthPrice: "£109/3",
          saveTag: true,
          saveDiscount: "48.10%",
        },
      ],
    },
    // {
    //   id: 3,
    //   planImg: planThree,
    //   planProductTitle: "Topical Pipette Prescription Solution",
    //   planProductDesc:"Topical scalp solution to reduce hair loss and promote growth and thicker hair.",
    //   planPoints: [
    //     {
    //       id: 1,
    //       PointsLable: "Effectiveness",
    //       PointsData: "over 9/10 people",
    //     },
    //     {
    //       id: 2,
    //       PointsLable: "Format",
    //       PointsData: "Pipette",
    //     },
    //     {
    //       id: 3,
    //       PointsLable: "Results",
    //       PointsData: "in 1-2 months",
    //     },
    //   ],
    //   planfrequency: [
    //     {
    //       id: 1,
    //       planMonth: "1",
    //       planMonthPrice: "£33.00",
    //     },
    //     {
    //       id: 2,
    //       planMonth: "3",
    //       planMonthPrice: "£33.00",
    //       saveTag: true,
    //       saveDiscount: "17.5%",
    //     },
    //   ],
    // },
  ];

  useEffect(() => {
    return () => {
      let clarityScript = document.getElementById("clarity-script");

      if (clarityScript) {
        clarityScript.remove();
      }
    };
  }, []);

  return (
    <div className="Main-HairCare-Page">
     <Helmet>
        <meta charSet="utf-8" />
        <title>
        Clinically Proven Prescription Hair Care | Effective Hair Loss Treatment
        </title>
        <meta
          name="description"
          content="Discover clinically proven prescription hair care products designed to combat hair loss. Our medical-grade treatments deliver real results, helping you regain thicker, healthier hair. Trust our expert solutions for effective and lasting hair restoration"
        />
        <meta
          name="keywords"
          content="Prescription hair care Hair loss treatment,Clinically proven hair care,Medical-grade hair products,Hair restoration solutions,Effective hair regrowth,Hair thinning treatment,Scalp health products,Anti-hair loss products,Regrow hair fast,Thicker hair solutions,Stop hair loss Hair growth treatment,Prescription hair growth,Hair loss solutions"
        />
        {isLiveSystem ? (
          <script type="text/javascript">
            {` (function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            t.id = 'clarity-script'; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "musq55hvim");
            `}
          </script>
        ) : null}
      </Helmet>

      <LandingPagebusinessHeader />
      <section
        className="BannerSec"
        style={{ backgroundImage: `url(${bannerImg})` }}
      >
        <div className="container">
          <div className="BannerContent">
            <h1 data-aos="fade-up" data-aos-duration="700">
              Prescription HairCare
            </h1>
            <p data-aos="fade-up" data-aos-duration="720">
              Prescription Hair Loss Treatment
            </p>

            <ul>
              <li data-aos="fade-up" data-aos-duration="750">
                <span className="LeftIcon">
                  <CheckIcon />
                </span>
                Prescribed by medical professionals: Customised to uniquely
                treat you.
              </li>
              <li data-aos="fade-up" data-aos-duration="780">
                <span className="LeftIcon">
                  <CheckIcon />
                </span>
                Proven effectiveness.
              </li>
              <li data-aos="fade-up" data-aos-duration="800">
                <span className="LeftIcon">
                  <CheckIcon />
                </span>
                Trust & safety: Doctor prescribed, trusted, and safe.
              </li>
              <li data-aos="fade-up" data-aos-duration="820">
                <span className="LeftIcon">
                  <CheckIcon />
                </span>
                Always available: discreet online service available 24/7.
              </li>
            </ul>

            <button
              className="btnBlueHair"
              data-aos="fade-up"
              data-aos-duration="860"
              onClick={() => handleHairProductNavigation()}
            >
              Online self assessment
            </button>
          </div>
        </div>
      </section>

      <section className="ProductSec same-space" id="ProductSec">
        <div className="container">
          <div className="row">
            {PlanData?.map((item, index) => {
              return (
                <div
                  className="col-sm-12 col-md-6 col-lg-4"
                  key={index}
                  data-aos="fade-up"
                  data-aos-anchor="#ProductSec"
                  data-aos-offset={400 + index * 100}
                  data-aos-duration={400 + index * 100}
                >
                  <div className="ProductBox">
                    <div className="ProductImgBox">
                      <img src={item?.planImg} alt={item?.planImg} />
                    </div>
                    <div className="ProductContentWrapper">
                      <div className="ProductContentBox">
                        <div className="TitleDesc">
                          <h5>{item?.planProductTitle}</h5>
                          <p>{item?.planProductDesc}</p>
                        </div>
                      </div>
                      <div className="ProductContentPoints">
                        <ul>
                          {item?.planPoints?.map((value, index) => {
                            return (
                              <li key={index}>
                                <span className="lable">
                                  {value?.PointsLable}
                                </span>
                                <span className="Data">
                                  {value?.PointsData}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="ProductPlanWrapper">
                        <h6>Delivery frequency</h6>
                        <div className="PlanBoxWrapper">
                          {item?.planfrequency?.map((value, index) => {
                            return (
                              <div
                                className={
                                  value?.saveTag
                                    ? "savePlan PlanBox"
                                    : "PlanBox"
                                }
                                key={index}
                              >
                                <div className="priceWrapper">
                                  <div className="priceContentWrapper">
                                    <h6>{value?.planMonth} month</h6>
                                    <p>{value?.planMonthPrice}/month</p>
                                  </div>


                                  {value?.saveTag && (
                                    <span className="SaveDiscount">
                                      Save <span className="SaveDiscountRate"> {value?.saveDiscount}</span>
                                    </span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="TrustSec same-space" id="TrustSec">
        <div className="container">
          <div
            className="secHeading"
            data-aos="fade-up"
            data-aos-anchor="#TrustSec"
            data-aos-offset="500"
            data-aos-duration="500"
          >
            {/* <span>TRUST AND CREDIBILITY</span> */}
            <h2>Trusted formulas used by thousands</h2>
          </div>

          <Slider {...settings1}>
            {TrustedData?.map((item, index) => {
              return (
                <div key={index}>
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <div className="sliderContent">
                        <div className="sliderText">
                          <h5>"{item?.clientReview}"</h5>
                          <div className="clientProfile">
                            <img src={item?.clientImg} alt={item?.clientImg} />
                            <p>{item?.clientName}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="sliderImgGrid">
                        <div className="ImgItem">
                          <img src={item?.BeforeImg} alt={item?.BeforeImg} />
                        </div>
                        <div className="ImgItem">
                          <img src={item?.afterImg} alt={item?.afterImg} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>

      <section className="consulationSec same-space" id="consulationSec">
        <div className="leftBottom">
          <img src={bottomLeft} alt={bottomLeft} />
        </div>
        <div className="RightBottom">
          <img src={rightAssets} alt={rightAssets} />
        </div>
        <div className="container">
          <div
            className="secHeading"
            data-aos="fade-up"
            data-aos-anchor="#consulationSec"
            data-aos-offset="700"
            data-aos-duration="700"
          >
            <h2>
              Consultation to product delivery in <br /> 5 Simple Steps.
            </h2>
          </div>

          <div className="slider-container">
            <Slider
              asNavFor={nav1}
              ref={(slider) => (sliderRef2 = slider)}
              slidesToShow={5}
              swipeToSlide={false}
              focusOnSelect={true}
              {...settings}
            >
              <div>
                <div className="nav">
                  <li
                    class="nav-item"
                    role="presentation"
                    data-aos="fade-up"
                    data-aos-anchor="#consulationSec"
                    data-aos-offset="700"
                    data-aos-duration="700"
                  >
                    <button
                      class="nav-link active"
                      id="Consultation-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Consultation"
                      type="button"
                      role="tab"
                      aria-controls="Consultation"
                      aria-selected="true"
                    >
                      <div className="iconTab">
                        <ConsultationIcon />
                      </div>
                      <span>
                        Online Consultation <br /> Questionnaire
                      </span>
                    </button>
                  </li>
                </div>
              </div>
              <div>
                <div className="nav">
                  <li
                    class="nav-item"
                    role="presentation"
                    data-aos="fade-up"
                    data-aos-anchor="#consulationSec"
                    data-aos-offset="750"
                    data-aos-duration="750"
                  >
                    <button
                      class="nav-link"
                      id="Medical-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Medical"
                      type="button"
                      role="tab"
                      aria-controls="Medical"
                      aria-selected="false"
                    >
                      <div className="iconTab">
                        <MedicalIcon />
                      </div>
                      <span>Medical Form</span>
                    </button>
                  </li>
                </div>
              </div>
              <div>
                <div className="nav">
                  <li
                    class="nav-item"
                    role="presentation"
                    data-aos="fade-up"
                    data-aos-anchor="#consulationSec"
                    data-aos-offset="800"
                    data-aos-duration="800"
                  >
                    <button
                      class="nav-link"
                      id="Discreet-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Discreet"
                      type="button"
                      role="tab"
                      aria-controls="Discreet"
                      aria-selected="false"
                    >
                      <div className="iconTab">
                        <DiscreetIcon />
                      </div>
                      <span>Discreet payment</span>
                    </button>
                  </li>
                </div>
              </div>
              <div>
                <div className="nav">
                  <li
                    class="nav-item"
                    role="presentation"
                    data-aos="fade-up"
                    data-aos-anchor="#consulationSec"
                    data-aos-offset="850"
                    data-aos-duration="850"
                  >
                    <button
                      class="nav-link"
                      id="Prescription-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Prescription"
                      type="button"
                      role="tab"
                      aria-controls="Prescription"
                      aria-selected="false"
                    >
                      <div className="iconTab">
                        <PrescriptionIcon />
                      </div>
                      <span>Prescription approval</span>
                    </button>
                  </li>
                </div>
              </div>
              <div>
                <div className="nav">
                  <li
                    class="nav-item"
                    role="presentation"
                    data-aos="fade-up"
                    data-aos-anchor="#consulationSec"
                    data-aos-offset="900"
                    data-aos-duration="900"
                  >
                    <button
                      class="nav-link"
                      id="Product-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Product"
                      type="button"
                      role="tab"
                      aria-controls="Product"
                      aria-selected="false"
                    >
                      <div className="iconTab">
                        <ProductIcon />
                      </div>
                      <span>Product delivery</span>
                    </button>
                  </li>
                </div>
              </div>
            </Slider>
            <Slider
              asNavFor={nav2}
              ref={(slider) => (sliderRef1 = slider)}
              {...settingsNew}
            >
              <div>
                <div className="stepWrapper">
                  <div className="row">
                    <div className="col-sm-12 col-md-12">
                      <div
                        className="StepImage"
                        data-aos="fade-left"
                        data-aos-anchor="#consulationSec"
                        data-aos-offset="950"
                        data-aos-duration="950"
                      >
                        <img src={stepOne} alt={stepOne} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="stepWrapper">
                  <div className="row">
                    {/* <div className="col-sm-12 col-md-4">
                      <div className="StepContent">
                        <h5>Online consultation questionnaire</h5>
                      </div>
                    </div> */}
                    <div className="col-sm-12 col-md-12">
                      <div className="StepImage">
                        <img src={stepTwo} alt={stepTwo} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="stepWrapper">
                  <div className="row">
                    {/* <div className="col-sm-12 col-md-12">
                      <div className="StepContent">
                        <h5>Online consultation questionnaire</h5>
                      </div>
                    </div> */}
                    <div className="col-sm-12 col-md-12">
                      <div className="StepImage">
                        <img src={stepThree} alt={stepThree} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="stepWrapper">
                  <div className="row">
                    {/* <div className="col-sm-12 col-md-4">
                      <div className="StepContent">
                        <h5>Online consultation questionnaire</h5>
                      </div>
                    </div> */}
                    <div className="col-sm-12 col-md-12">
                      <div className="StepImage">
                        <img src={stepFour} alt={stepFour} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="stepWrapper">
                  <div className="row">
                    {/* <div className="col-sm-12 col-md-4">
                      <div className="StepContent">
                        <h5>Online consultation questionnaire</h5>
                      </div>
                    </div> */}
                    <div className="col-sm-12 col-md-12">
                      <div className="StepImage">
                        <img src={stepFive} alt={stepFive} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="workSec same-space" id="workSec">
        <div className="container">
          <div className="secHeading">
            <h2>
              Science behind how our <br /> treatments work
            </h2>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="workQuestionWrapper">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-anchor="#workSec"
                    data-aos-offset="700"
                    data-aos-duration="700"
                  >
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        How long before I see results?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        You may start noticing initial results from minoxidil
                        within 2 to 3 months of consistent use. However, it can
                        take up to 6 months to see more significant hair
                        regrowth. It's important to continue using the treatment
                        as directed, as stopping can result in hair loss
                        returning.
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-anchor="#workSec"
                    data-aos-offset="750"
                    data-aos-duration="750"
                  >
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Will I get negative side effects?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Most people tolerate minoxidil well, but some may
                        experience side effects. Common side effects include
                        scalp irritation, dryness, or itching. In rare cases,
                        people might experience more serious side effects like
                        rapid heartbeat, dizziness, or unwanted facial hair
                        growth. If you notice any severe reactions, stop using
                        the product and consult a healthcare professional.{" "}
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-anchor="#workSec"
                    data-aos-offset="800"
                    data-aos-duration="800"
                  >
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        What are the benefits of minoxidil for hair?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Minoxidil stimulates hair follicles, promoting hair
                        regrowth and slowing down hair loss. It increases blood
                        flow to the scalp, helping to nourish hair follicles.
                        Many users experience thicker, fuller hair and an
                        improvement in hair density over time.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div
                className="ChartImg"
                data-aos="fade-left"
                data-aos-anchor="#workSec"
                data-aos-offset="750"
                data-aos-duration="750"
              >
                <img src={chartImg} alt={chartImg} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="treatmentSec same-space" id="treatmentSec">
        <div className="container">
          <div
            className="secHeading"
            data-aos="fade-left"
            data-aos-anchor="#treatmentSec"
            data-aos-offset="750"
            data-aos-duration="750"
          >
            <h2> We are revolutionising hair loss treatment</h2>
            <p>
              We combine science, technology and medical expertise to deliver
              personalised, cutting-edge hair-loss treatment plans that put an
              end to hair loss.
            </p>
          </div>

          <div className="row">
            {TreatmentProData?.map((item, index) => {
              return (
                <div
                  className="col-sm-12 col-md-4"
                  key={index}
                  data-aos="fade-up"
                  data-aos-anchor="#treatmentSec"
                  data-aos-offset={700 + index * 100}
                  data-aos-duration={700 + index * 100}
                >
                  <div className="productBox">
                    <div className="productImgBox">
                      <img src={item?.TreatmentImg} alt={item?.TreatmentImg} />
                    </div>
                    <div className="productContentBox">
                      <h5>{item?.TreatmentName}</h5>
                      <p> {item?.TreatmentDesc} </p>
                      <h6>{item?.TreatmentPrice}</h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* <div
            className="bottomBtn"
            data-aos="fade-down"
            data-aos-anchor="#treatmentSec"
            data-aos-offset="850"
            data-aos-duration="850"
          >
            <button className="outlineBtn">Start assessment</button>
          </div> */}
        </div>
      </section>

      <LandingPagebusinessFooter />
    </div>
  );
}

export default HairCareLandingPage;
