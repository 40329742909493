import React, { useState } from 'react'
import './appointment-photos-main.scss'
import backArrow from '../../../../images/icons/BackArrow.svg'
import CustomeImageUpload from '../batch-numbe-pages/image-upload/CustomeImageUpload'
import StepButton from '../../../common/StepButton'
import ConsentFormCustomModal from '../../../modal/ConsentFormModal/ConsentFormModal'
import smartImg from '../../../../images/new-appointment/smart.svg'
import CustomModal from '../../../modal/CustomModal'


const AddPhotos = ({ setCurrentStep }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    const openModal = () => {
        scrollToTop();
        setIsModalOpen(true);
    };
    const openModalOnFileUploadClick = () => {
        openModal(); // Function to open modal
    };

    const handleBackStep = () => {
        setCurrentStep(1);
    }
    return (
        <>
            <div className='batch-number-list-main container'>
                <div className='batch-number-list-header  mt-5'>
                    <img src={backArrow} alt='img' onClick={handleBackStep} />
                </div>
                <div className='batch-number-list-title'>
                    <h1>Photos</h1>
                </div>
                <div className='add-photos-sec'>
                    <span>Photo 1</span>
                    <div className='d-flex'>
                        <CustomeImageUpload openModalOnClick={openModalOnFileUploadClick} />
                        <CustomeImageUpload openModalOnClick={openModalOnFileUploadClick} />
                    </div>
                    <span>Photo 2</span>
                    <div className='d-flex'>
                        <CustomeImageUpload openModalOnClick={openModalOnFileUploadClick} />
                        <CustomeImageUpload openModalOnClick={openModalOnFileUploadClick} />
                    </div>
                    <span><u>Add more</u></span>
                </div>

            </div>

            <CustomModal
                modalOpen={isModalOpen}
                setModalOpen={setIsModalOpen}
                modalBody={
                    <>
                        <div className='modal-content'>
                            <h3>Add a caption</h3>
                            <div>
                            </div>
                            <div>
                                <span>Add a caption</span>
                            </div>
                            <div className='add-caption-box'>
                                <textarea placeholder='Share the essentials about the treatment with your clients. ' />
                            </div>
                            <div className='smart_ai_text_gen'>
                                <span className='d-flex smart-text'>
                                    <img src={smartImg} width={25} height={25} alt='img' />
                                    <p>Smart text</p>
                                </span>
                            </div>
                            <div>
                                <StepButton label={"Delete"} gray={true} />
                                <StepButton label={"Save"} blue={true} />
                            </div>
                        </div>
                    </>
                }
            />
        </>
    )
}

export default AddPhotos
