import React, { useState, useRef, useEffect } from "react";
import "./account-details.scss";
import Avatar from "react-avatar";
import moment from "moment";
import PropTypes from "prop-types";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
// import CustomDropdown from "../../../common/dropdown/CustomDropdown";
import CustomDropdown from "../../../auth/revenue-dashboard/CustomDropdown/CustomDropdown";
import { logoutProfile } from "../../../redux/actions";
import { FaRegEye } from "react-icons/fa6";
import { FaRegEyeSlash } from "react-icons/fa6";
// import CustomAutoComplete from "../auto-complete/CustomAutoComplete";
import TextField from "@material-ui/core/TextField";
import CustomAutoComplete from "../../../auto-complete/CustomAutoComplete";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import SwitchButton from "../../../common/switchButton/SwitchButton";
import CustomModal from "../../../modal/CustomModal";
import { toast } from "react-toastify";
import { ReactComponent as BinIcon } from "../../../../../src/images/sprint6/BinDelete.svg";
import { ReactComponent as MarketPlaceIcon } from "../../../../images/business-setting-icon/marketplace.svg";
import TextInput from "../../../common/TextInput";
import getProfileData from "../../../redux/actions/profile/Profile";
import BusinessNavigation from "../../../common/businessNavigation/BusinessNavigation";
import { GetAccountDetailsAction } from "../../../redux/actions/account-details/accountdetails";
// import {GETCLIENTACCOUNTDE}
import { GetOccupationAction } from "../../../redux/actions/account-details/occupationdetails";
import { useSelector } from "react-redux";
import { InputInfoUserAction } from "../../../redux/actions/account-details/inputuserinfo";
import StepButton from "../../../common/StepButton";
import { GetTrainingAction } from "../../../redux/actions/account-details/gettrainingacademy";
import { InputInfoDeleteUserAction } from "../../../redux/actions/account-details/deleteAccount";
import { InputInfoDeleteProfilePicUserAction } from "../../../redux/actions/account-details/deleteprofilepic";
import { SAVEPROFILEAction } from "../../../redux/actions/account-details/saveprescriberprofilepic";
import { colors } from "@mui/material";
import { CheckPassword } from "../../../redux/actions/check-password/CheckPassword";

import { GetUserProfileAction } from "../../../redux/actions/profile/GetUserProfile";
import { getProfileDataWatcher } from "../../../redux/sagas/profile/Profile";
const AccountDetails = ({ setStep }) => {
  //======= Constants =============
  const profilePicInputRef = useRef(null);
  const [profilePicPreview, setProfilePicPreview] = useState(null);
  const [blobremovedPic, setblobremovedPic] = useState(null);
  const [selectedCountryLabel, setSelectedCountryLabel] = useState(null);
  const [userDetails, setuserDetails] = useState();
  const [occupations, setOccupations] = useState([]);
  const [proffesion, setprofession] = useState("");
  const [isPasswordProtection, setIsPasswordProtection] = useState(false);
  const [academyName, setacademyName] = useState([]);
  const [occupationTitle, setoccupationTitle] = useState()
  const { getAccountDetailsQueryData, initGetAccountDetailsData } = GetAccountDetailsAction();
  const { getTrainingQueryData, initGettrainingData } = GetTrainingAction();
  const { getOccupationQueryData, initGetOccupationData } = GetOccupationAction();
  const [optionsArr, setoptionArr] = useState([])
  const [togglevalue, setTogglevalue] = useState()
  const [trainerlist, settrainerlist] = useState([])
  const [imageDeletePopup, setImageDeletePopup] = React.useState(false);
  const [value, setValue] = useState([])
  const { deleteUserFunctionResponseData, initDeleteInfoResponse } = InputInfoDeleteUserAction()
  const { deleteUserProfilePicResponseData, initDeleteProfileResponsefunction } = InputInfoDeleteProfilePicUserAction()
  const { InputuserInfoProfileData, initInputuserProfilefunction } = SAVEPROFILEAction()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { initiateCheckPassword, checkPasswordMutationData } = CheckPassword();
  const { getUserProfileQueryData, initGetUserProfileData } = GetUserProfileAction()
  // const getOccupationTitleById = (id) => {

  //    return occupation ? occupation?.title : "Unknown";
  //  };

  const togglePasswordVisibility = (name) => {
    setShowPassword((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };
  const [showPassword, setShowPassword] = useState({
    Oldpassword: false,

  });

  useEffect(() => {
    initGetAccountDetailsData({},
      (res) => {
        setuserDetails(res?.data?.getAccountDetailsForManageAccount?.data);

      },
      (err) => {

      });
  }, []);

  useEffect(() => {
    initGettrainingData({}, (res) => {
      setacademyName(res.data.getTrainingAcademyList.data.trainingList)
      const list = res.data.getTrainingAcademyList.data.trainingList.map(list => list.name);

      settrainerlist(list)
    }, (err) => { })
  }, [])
  useEffect(() => {

    initGetOccupationData({}, (res) => {

      if (res && res.data?.getPrescriberOccupationList) {
        const optionList = res.data.getPrescriberOccupationList.data
          ?.map((category) =>
            category.occupations?.map((occupation) => ({
              ...occupation,
              category: category.category,
              title: occupation.title,
            }))
          )
          .flat();
        setoptionArr(optionList);

      }


      // setOccupations(res.data.getPrescriberOccupationList.data)
      // const flattenedOccupations = res?.data?.getPrescriberOccupationList?.data?.flatMap(category => category?.occupations);
      // setoptionArr(flattenedOccupations)
      // const titles = flattenedOccupations.map(occupation => occupation.title);
      // setoptionArr(res.data.getPrescriberOccupationList.data)
      // const occupation = flattenedOccupations?.find(occ => occ?.id === userDetails?.occupation[0]);
      // setoccupationTitle(occupation?.title)

    },
      (err) => {

      })
  }, [userDetails])


  const handlePasswordsubmit = () => {
    if (basicInfoData?.Password) {
      initiateCheckPassword(
        { password: basicInfoData.Password },  // No need for optional chaining here
        (res) => {
          if (res?.data?.passwordValid) {
            toast.success(res?.message);
            setIsPasswordProtection(false);
            setClientDirectoryToggle(!clientDirectoryToggle);
            basicInfoData.Password = "";  // Remove optional chaining
          } else {
            toast.error(res?.message);
            basicInfoData.Password = "";

            // Remove optional chaining
          }
        }
      );
    }

    else {
      setValidationErrors((prev) => ({
        ...prev,
        Password: "Password is required",
      }));
    }
  }
  // const{}=PurchaseAccountancyServicesAction()
  const { InputuserInfoResponseData, initInputuserInfoResponsefunction } = InputInfoUserAction()


  const [basicInfoData, setBasicInfoData] = useState({

    firstName: "",
    lastName: "",
    email: "",
    birthday: "",
    companyName: "",
    phoneNumber: "",
    occupation: "",
    institute: "",
    holiday: "",
  });


  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    occupation: "",
    institue: "",
    holiday: "",
  });


  //Date of Birth
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [dobError, setDobError] = useState(false);
  const [dobValue, setDobValue] = useState("");
  const [countryCode, setCountryCode] = useState("GB");

  const occupationArray = optionsArr;

  const instituteArray = trainerlist;
  const [openReviewPopup, setOpenReviewPopup] = useState(false);
  const [editableText, setEditableText] = useState("");
  const [copyText, setCopyText] = useState("");
  const textRef = React.useRef(null);
  const [isTextInputClicked, setIsTextInputClicked] = useState(false);
  const [prefix, setPrefix] = useState(
    "https://staging.facesconsent.com/v1/bookings/"
  );
  const [clientDirectoryToggle, setClientDirectoryToggle] = useState(false);
  const [searchEngineToggle, setSearchEngineToggle] = useState(false);
  const [searchEngineNum, setsearchEngineNum] = useState(0);
  const [marketPlaceToggle, setMarketPlaceToggle] = useState(false);


  // Remove any trailing spaces


  // useEffect(() => {
  //   // Initialize state with formatted values from userDetails
  //   setBasicInfoData({
  //     firstName: userDetails?.firstName || "",
  //     lastName: userDetails?.lastName || "",
  //     email: userDetails?.email || "",
  //     companyName: userDetails?.businessName || "",
  //     phoneNumber: formatPhoneNumber(userDetails?.phone || ""),
  //     institute: userDetails?.trainingAcademy?.name || "",
  //     holiday: userDetails?.holiday || "",
  //   });
  // }, [userDetails]);
  const userProfileDetails = useSelector(state => state?.GetUserProfileDataReducer?.UserProfileData?.data)
  console.log(userProfileDetails, "userProfileDetailsuserProfileDetails")

  const formatPhoneNumber = (phone) =>
    phone
      .replace(/\s/g, '')          // Remove all spaces
      .replace(/(\d{5})/g, '$1 ') // Add a space after every 5 digits
      .trim();


  useEffect(() => {
    if (userDetails) {

      const dateOfBirth = userDetails?.dateOfBirth || "";
      const [year, month, day] = dateOfBirth?.split('-');

      setDay(day || "");
      setMonth(month || "");
      setYear(year || "");


      // const selectedOccupations = userDetails?.occupation?.map(id =>
      //   optionsArr?.find(option => option?.id === id)
      // );
      const selectedOccupations = userDetails?.occupation?.map(id => {


        // Ensure id is the same type as option.id
        const matchingOption = optionsArr?.find(option => option?.id === Number(id) || option?.id === id);

        if (!matchingOption) {
          console.warn(`No matching occupation found for ID: ${id}`);
        }

        return matchingOption;
      }).filter(option => option !== undefined); // Filter out undefined options

      // Set the selected occupations as the value
      // Check if selectedOccupations has valid options and set the value accordingly
      if (selectedOccupations?.length > 0) {

        setValue(selectedOccupations);
      } else {
        console.warn("No valid selected occupations found.");
      }





      setBasicInfoData({
        firstName: userDetails?.firstName || "",
        lastName: userDetails?.lastName || "",
        email: userDetails?.email || "",
        companyName: userDetails?.businessName || "",
        phoneNumber: formatPhoneNumber(userDetails?.phone || ""),
        // occupation: userDetails?.occupation[0] || "",
        institute: userDetails?.trainingAcademy?.name || "",
        holiday: userDetails?.holiday || "",


      });

      setProfilePicPreview(userDetails?.profileImage)
      setCountryCode(userDetails?.countryCode.toUpperCase())
      setClientDirectoryToggle(userDetails.requirePasswordForClientDirectory)
      if (userDetails.usrListOnSearchEngine === 1) {

        setSearchEngineToggle(true)
      }
      else if (userDetails.usrListOnSearchEngine === 0) {

        setSearchEngineToggle(false)
      }

    }
  }, [userDetails, optionsArr,]);

  const handeledeleteacc = () => {
    setOpenReviewPopup(true)
    // <CustomModal
    //   modalOpen={openReviewPopup}
    //   setModalOpen={setOpenReviewPopup}
    //   type={"review"}
    //   modalBody={
    //     <>
    //       <h1>sure want to delete</h1>
    //     </>
    //   }
    // />

  }
 
  const customStyle = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Adjust the z-index value according to your requirement
    }),
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "#FFFFFF",
      //  border: state.isFocused ? 0 : 0,
      outline: "none",
      appearance: "none",
      padding: "10px 10px",
      borderRadius: "10px",
      // border: "1px solid #878787",
      height: "55px",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        // border: isFocused ? 'none' : 'none'
      };
    },
  };
  

  //================== FUNCATION DECLARATIONS =================
  const handleKeyDown = (e) => {
    // Check if the Backspace key is pressed
    if (e.key === "Backspace") {
      setIsTextInputClicked(true);
    } else {
      setIsTextInputClicked(true);
    }
  };
  const handleOptionChange = (event, newValue) => {
    // const newPinStatus = newValue.reduce((maxPin, option) => {
    //   return Math.max(maxPin, option.pin_status);
    // }, 0);

    setValue(newValue);
    // setPinStatus(newPinStatus);
    // setValidationErrors((prevData) => ({
    //   ...prevData,
    //   occupation: "",
    // }));

  };
  const handleOccupationChange = (value) => {

    setBasicInfoData((prevData) => ({
      ...prevData,
      occupation: value,
    }));
    validateInput("title", value);

  };

  const handleInstituteChange = (value) => {

    setBasicInfoData((prevData) => ({
      ...prevData,
      institute: value,
    }));
    validateInput("institute", value);

  };

  const handleProfilePicker = () => {
    if (profilePicInputRef.current !== null) {
      profilePicInputRef.current.click();

    }
  };

  const handleProfileImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && (file.type.startsWith('image/') || file.type === 'application/pdf')) {
      initInputuserProfilefunction({ image: file }, (success) => {
        console.log('success', success)
        if (success?.data?.savePrescriberProfilePic?.success) {
          initGetUserProfileData();

        }
      }, (err) => { console.log(err) })

      setblobremovedPic(file);
    } else {
      console.log('File is not an image or a PDF');
      // Handle the case where the file is not an image or PDF
    }

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      console.log(imageUrl, "imageUrlimageUrl")
      setProfilePicPreview(imageUrl);

    }
  };



  //INPUT HANDLDE CHANGE
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setBasicInfoData((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  //   validateInput(name, value);
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Format the value
    const formattedValue = value
      .replace(/\s/g, '')          // Remove all spaces
      .replace(/(\d{5})/g, '$1 ') // Add a space after every 5 digits
      .trim();                     // Remove any trailing spaces

    setBasicInfoData((prev) => ({
      ...prev,

      [name]: name === "phoneNumber" ? formattedValue : value,
    }));

    validateInput(name, formattedValue);
    setValidationErrors((prev) => ({
      ...prev,
      Password: "",
    }));
  };

  function isValidEmail(email) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  }

  const handleSubmit = () => {


    if (
      validationErrors.companyName === "" &&
      validationErrors.email === "" &&
      validationErrors.firstName === "" &&
      validationErrors.institue === "" &&
      validationErrors.lastName === "" &&
      validationErrors.occupation === "" &&
      validationErrors.phoneNumber === ""
    ) {
      initInputuserInfoResponsefunction(
        {
          // profileImage: blobremovedPic,
          firstName: basicInfoData?.firstName,
          lastName: basicInfoData?.lastName,
          email: basicInfoData?.email,
          businessName: basicInfoData?.companyName,
          phone: basicInfoData?.phoneNumber,
          dob: dobValue,
          // occupation: basicInfoData?.occupation,
          occupation:
            value?.length > 0 &&
            value?.map((occupation) => occupation.id),
          trainingAcademy: userDetails?.trainingAcademy?.parent_id,
          affiliationLink: copyText,
          requirePasswordForClientDirectory: clientDirectoryToggle,
          usrListOnSearchEngine: searchEngineToggle ? 1 : 0,
          phoneCountry: getCountryCallingCode(countryCode),
          countryCode: countryCode.toLowerCase(),
        },
        (successResponse) => {
          console.log("Success:", successResponse);
          window.location.reload();
          // Consider navigating to a different page or showing a success message instead of reloading
          // window.location.reload();
        },
        (errorResponse) => {
          console.error("Error:", errorResponse);
          // Handle the error appropriately
        }
      );
      // initInputuserProfilefunction({ image: blobremovedPic }, (success) => { console.log(success) }, (err) => { console.log(err) })
    }
  };


  //VALIDATION CHECK
  const validateInput = (name, value) => {
    switch (name) {
      case "firstName":
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            firstName: "First name is required",
          }));
        } else {
          if (/\d/.test(value)) {
            setValidationErrors((prev) => ({
              ...prev,
              firstName: "Invalid first name",
            }));
          } else {
            setValidationErrors((prev) => ({
              ...prev,
              firstName: "",
            }));
          }
        }
        break;
      case "lastName":
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            lastName: "Last name is required",
          }));
        } else {
          if (/\d/.test(value)) {
            setValidationErrors((prev) => ({
              ...prev,
              lastName: "Invalid last name",
            }));
          } else {
            setValidationErrors((prev) => ({
              ...prev,
              lastName: "",
            }));
          }
        }
        break;
      case "companyName":
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            companyName: "Company name is required",
          }));
        } else {
          if (/\d/.test(value)) {
            setValidationErrors((prev) => ({
              ...prev,
              companyName: "Invalid company name",
            }));
          } else {
            setValidationErrors((prev) => ({
              ...prev,
              companyName: "",
            }));
          }
        }
        break;
      case "email":
        if (!value || !isValidEmail(value)) {
          setValidationErrors((prev) => ({
            ...prev,
            email: "Valid email is required",
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            email: "",
          }));
        }
        break;
      case "phoneNumber":
        if (name === "phoneNumber") {
          const numericValue = value.replace(/[^0-9]/g, "");
          const startsWithSpecialCharacter = /^[^\d]/.test(value);
          setValidationErrors((prev) => ({
            ...prev,
            phoneNumber:
              // numericValue.length < 10 || numericValue.length > 15
              //   ? "Valid phone number is required (10-15 digits)"
              //   : "",
              // numericValue.startsWith(+)
              startsWithSpecialCharacter
                ? "Special characters is not allowed"
                : numericValue.length < 10 || numericValue.length > 15
                  ? "Valid phone number is required (10-15 digits)"
                  : ""
            // Use errorMessage for display or further validation logic

          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            phoneNumber: "",
          }));
        }
        break;

      case "title":
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            title: "Title is required",
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            title: "",
          }));
        }

      default:
        break;
    }
  };

  //for countrySelection
  const CountrySelect = ({
    value,
    onChange,
    selectedLabel,
    labels,
    ...rest
  }) => (
    <div className="business-country-selection-container">
      <select
        {...rest}
        value={value}
        onChange={(event) => onChange(event.target.value || undefined)}
      >
        <option value="">{labels["ZZ"]}</option>
        {getCountries().map(
          (country, i) =>
            country !== "International" && (
              <option key={country} value={country}>
                {`+${getCountryCallingCode(country)}`}
              </option>
            )
        )}
      </select>
      <span className="selected-label">{selectedLabel}</span>
    </div>
  );

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
    selectedLabel: PropTypes.string,
  };

  //Handle Phonenumber selection
  const handleCountryChange = (country) => {
    setCountryCode(country);

    // Get the label based on the selected country
    const selectedLabel = getCountries().includes(country)
      ? `${getCountryCallingCode(country)}`
      : "";

    setSelectedCountryLabel(selectedLabel);
  };

  //=========================USEEFFECT =======================
  useEffect(() => {
    var date = moment(year + "-" + month + "-" + day).format("YYYY-MM-DD");

    if (day == "" && month == "" && year == "") {
      setDobError(false);
    } else if (
      day &&
      month &&
      year &&
      day?.length == 2 &&
      month.length == 2 &&
      year?.length == 4 &&
      date < moment().format("YYYY-MM-DD")
    ) {
      setDobError(false);
      setDobValue(date);
    } else if (day && month && year) {
      setDobValue("");
      setDobError(true);
    }
  }, [day, month, year]);

  return (
    <div className="MainAccountDetailsPage Main684WidthSet">

      <BusinessNavigation
        isColumn={true}
        title={"Manage account"}
        desc={'Simplify your account management experience'}
        onBackClick={() => { setStep(0) }}
      />


      <div
        className="account-detail-upload-img-container"
        onClick={handleProfilePicker}
      >
        <Avatar
          src={profilePicPreview}
          name={"Business"}
          maxInitials={1}
          color="#EEEFF3"
          fgColor="#000"
          size="60"
          round={true}
        />
        {(profilePicPreview == null || profilePicPreview == "") ? <p>Add profile image</p> : <p style={{ color: "red" }} onClick={(e) => { e.stopPropagation(); setImageDeletePopup(true); }}>Delete Image</p>}
        {/* <p style={{color:"red"}} onClick={(e) => { e.stopPropagation() ;   setProfilePicPreview(null); console.log("deleted")}}>deleteImages</p> */}
        {console.log(profilePicPreview == "", "profilePicPreview")}
        <input
          ref={profilePicInputRef}
          type="file"
          accept=".jpg, .jpeg, .png, .svg" // Add acceptable file formats
          onChange={handleProfileImageUpload}
          style={{ display: "none" }} // Hide the default file input style
          id="profilePicInputRef" // Use a label with htmlFor to trigger file input
        />
      </div>
      <div className="account-detail-form-container">
        {/* FIRST NAME */}
        <div className="finance-input-container">
          <TextInput
            id="firstName"
            label="First name"
            placeholder="First name"
            name="firstName"
            value={basicInfoData?.firstName}
            onChange={(e) => handleChange(e)}
          />
          {validationErrors.firstName && (
            <span className="error">{validationErrors.firstName}</span>
          )}
        </div>
        {/* LAST NAME */}
        <div className="finance-input-container">
          <TextInput
            label="Last name"
            placeholder="Last name"
            name="lastName"
            value={basicInfoData?.lastName}
            onChange={(e) => handleChange(e)}
          />
        </div>
        {/* COMPANY NAME */}
        <div className="finance-input-container">
          <TextInput
            label="Company name"
            placeholder="Business name"
            name="companyName"
            value={basicInfoData?.companyName}
            onChange={(e) => handleChange(e)}
          />
          {validationErrors.companyName && (
            <span className="error">{validationErrors.companyName}</span>
          )}
        </div>
        {/* EMAIL */}
        <div className="finance-input-container">
          <TextInput
            label="Email address"
            placeholder="name@gmail.com"
            value={basicInfoData?.email}
            onChange={(e) => handleChange(e)}
            name="email"
          />
          {validationErrors.email && (
            <span className="error">{validationErrors.email}</span>
          )}
        </div>
        {/* PHONE NUMBER */}
        <div className="account-detail-phone-number">
          <p>Your phone number</p>
          <div className="phone-country-code">
            <CountrySelect
              labels={{ ZZ: "Select Country" }}
              value={countryCode}
              onChange={(e) => handleCountryChange(e)}
            />
            <div className="phone-country-number">
              <TextInput
                name="phoneNumber"
                value={basicInfoData?.phoneNumber}
                onChange={(e) => handleChange(e)}
                placeholder="Enter phone number"
              />
              {validationErrors.phoneNumber && (
                <span className="error">{validationErrors.phoneNumber}</span>
              )}
            </div>
          </div>
        </div>
        {/* BIRTH DATE */}
        <div className="w-100">
          <div className="row w-100 m-auto">
            <div className="BirthdayWrapper p-0">
              <label className="form-label">Date of birth</label>

              <div className="BirthdayRow">
                <div className="col-md-4">
                  <TextInput
                    label="&nbsp;"
                    placeholder="DD"
                    maxLength={"2"}
                    id={"day"}
                    name="day"
                    type="text"
                    min="1"
                    ref={dayRef}
                    max="31"
                    value={day}
                    onChange={(e) => {
                      if (e.target.value.length >= 2) {
                        document.getElementById("month").focus();
                      }
                      const inputValue = e.target.value;
                      if (inputValue === "") {
                        setDobError(true);
                      }
                      const regex = /^[0-9]{0,2}$/;
                      if (regex.test(inputValue)) {
                        setDay(inputValue);
                      }
                    }}
                  />
                </div>
                <div className="col-md-4 ">
                  <TextInput
                    label="&nbsp;"
                    id={"month"}
                    placeholder="MM"
                    name="month"
                    min="1"
                    max="12"
                    maxLength="2"
                    type="text"
                    ref={monthRef}
                    value={month}
                    onKeyDown={(e) => {
                      if (!e.target.value && e.key === "Backspace") {
                        document.getElementById("day").focus();
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value >= 2) {
                        document.getElementById("year").focus();
                      }
                      const inpValue = e?.target?.value;
                      if (inpValue == "") {
                        setDobError(true);
                      }
                      const regex = /^[0-9]{0,2}$/;
                      if (regex.test(inpValue)) {
                        setMonth(inpValue);
                      }
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    label="&nbsp;"
                    placeholder="YYYY"
                    maxLength={4}
                    ref={yearRef}
                    type="text"
                    name="year"
                    id={"year"}
                    min="1900"
                    max="2024"
                    value={year}
                    onKeyDown={(e) => {
                      if (!e.target.value && e.key === "Backspace") {
                        document.getElementById("month").focus();
                      }
                    }}
                    onChange={(e) => {
                      console.log("key pressed", e.target.value);
                      const inputValue = e?.target?.value;
                      const regex = /^[0-9]{0,4}$/;
                      if (inputValue == "") {
                        setDobError(true);
                      }
                      if (regex.test(inputValue) || inputValue == "") {
                        setYear(inputValue);
                      }
                    }}
                  />
                </div>
                {dobError && <span className="error">{"Invalid DOB"}</span>}
              </div>
            </div>
          </div>
        </div>
        {/* OCCUPATION */}
        <div className="w-100">
          {/* <CustomDropdown
            labelName={"Occupation"}
            // defaultValue={value && value|| ""}
            dropdownId={"occupation"}
            placeholder={value?.length ? value?.title : "Select an occupation"}
            optionValue={optionsArr}
            style={customStyle}
            autoFocus={false}
            handleSelectedValue={handleOccupationChange}
          /> */}

          <label className="occupation-lable">Occupation</label>

          <CustomAutoComplete
            // style={customStyle}
            className="custom-occupation"
            style={{
              borderRadius: "10px ",
              border: "1px solid #878787",
              background: "#fff",
            }}
            multiple={true}
            // defaultValue={ value|| ""}
            // defaultValue={{ label: value?.title, id: value?.id }}
            groupBy={true}
            placeholder={value?.length ? value?.title : "Select an occupation"}
            optionValue={optionsArr}
            // value={value && value}
            value={value}
            setValue={setValue}
            handleOptionChange={handleOptionChange}
          />
          {validationErrors.occupation && (
            <span className="error">{validationErrors.occupation}</span>
          )}
        </div>
        {<div>


          {openReviewPopup && (
            <CustomModal
              modalOpen={openReviewPopup}
              setModalOpen={setOpenReviewPopup}
              type={"review"}
              modalBody={
                <div className='cart-delete-modal'>
                  <p className='text-center delete-modal-desc'>Are you sure you want to delete faces account?</p>
                  <div className='delete-btn-container'>
                    <button
                      className='delete-item-btn me-3'
                      onClick={() => {

                        initDeleteInfoResponse({}, (res) => { dispatch(logoutProfile()); }, (err) => console.log(err));
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className='delete-outline-btn'
                      onClick={() => setOpenReviewPopup(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              }
            />
          )}

          {imageDeletePopup && (
            <CustomModal
              modalOpen={imageDeletePopup}
              setModalOpen={setImageDeletePopup}
              type={"review"}
              modalBody={
                <div className='cart-delete-modal'>
                  <p className='text-center delete-modal-desc'>Are you sure you want to delete the image?</p>
                  <div className='delete-btn-container'>
                    <button
                      className='delete-item-btn me-3'
                      onClick={() => {

                        initDeleteProfileResponsefunction({}, (res) => { setProfilePicPreview(null); initGetUserProfileData(); }, (err) => console.log(err));
                        setImageDeletePopup(false);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className='delete-outline-btn'
                      onClick={() => { setImageDeletePopup(false); }}
                    >
                      No
                    </button>
                  </div>
                </div>
              }
            />
          )}
        </div>
        }
        {/* OCCUPATION */}

        <div className="w-100">

        {/* <CustomDropdown
                                        // labelName={"Business Type"}
                                        name="businessType"
                                        id="businessType"
                                        dropdownId={"businessType"}
                                        placeholdername={"Select Business Type"}
                                        optionsArr={businessTypeValuse}
                                        defaultValue={getUserProfileToUpdateData?.businessType}
                                        handleSelectedValue={handleTitleChange}
                                        style={customStyle}
                                    /> */}
      
          <CustomDropdown
            labelName={"Training Institute"}
            defaultValue={basicInfoData?.institute}
            dropdownId={"training-institute"}
            placeholdername={"Select Training Institute"}
            optionsArr={instituteArray}
            style={customStyle}
            autoFocus={false}
            handleSelectedValue={handleInstituteChange}
          />
          {validationErrors.institue && (
            <span className="error">{validationErrors.institue}</span>
          )}
        </div>

        {/* HOLIDAY */}

        {userDetails?.isPrescriber && <TextInput
          label="Add holiday"
          placeholder="Add holiday"
          value={basicInfoData?.holiday}
          onChange={(e) => handleChange(e)}
          name="holiday"
        />
        }

        {validationErrors.holiday && (
          <span className="error">{validationErrors.holiday}</span>
        )}
        {/* Affiliation LINK */}
        <div className="account-detail-share-booking-link">
          <p> Affiliation link</p>
          <div className="account-detail-share-booking-link-input-div">
            <input
              name="titre"
              id="exampleEmail"
              type="text"
              placeholder="Affiliation link"
              ref={textRef}
              value={copyText}
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                const input = e.target.value;
                let temp;
                if (input.substr(prefix.length)?.charAt(0) == "/") {
                  temp = input.substr(prefix.length).slice(1);
                } else {
                  temp = input.substr(prefix.length);
                }
                setEditableText(temp);
                e.target.value = prefix + temp;
                if (e?.target?.value) setCopyText(e?.target?.value);
              }}
            />
            <button className={isTextInputClicked ? "btnCopy save-btn" : "btnCopy copy-btn"}>
              Copy
            </button>
          </div>
        </div>
        {/* CLIENT DIRECTORY TOGGLE */}
        <div className="account-details-directory-section">
          <p>Require password for client directory</p>
          <SwitchButton
            isChecked={clientDirectoryToggle}
            onChange={() => {
              // setClientDirectoryToggle(!clientDirectoryToggle);
              setIsPasswordProtection(!isPasswordProtection)
            }}
          />
        </div>




        {/* CLIENT DIRECTORY TOGGLE */}
        <div className="account-details-directory-section">
          <p>List me on Google and other search engines</p>
          <label className="input-checkbox">
            <input
              type="checkbox"
              id="search-engine"
              checked={searchEngineToggle}
              onChange={() => {


                setSearchEngineToggle(!searchEngineToggle);

                // if (searchEngineToggle === false) {
                //   console.log("toggle1")
                //   setsearchEngineNum(1)
                // }
                // else if(searchEngineToggle === true) {
                //   console.log("toggle0")
                //   setsearchEngineNum(0)
                // }

              }}
            />
          </label>
        </div>


        <CustomModal
        modalOpen={isPasswordProtection}
        // modalOpen={true}
        modaltitle={"Password protection"}
        setModalOpen={setIsPasswordProtection}
        className="customePasswordprotectionModel"
        modalBody={
          <>
            <div className='ModelDetailsContent'>
              <div className="finance-input-container">
                <div className="InputIconWrapper">
                  <TextInput
                    id="Password"
                    label="Password"
                    placeholder="Password"
                    name="Password"
                    type={showPassword.Oldpassword ? "text" : "password"}
                    value={basicInfoData?.Password}
                    onChange={(e) => handleChange(e)}
                  />
                  <button
                    className="password-toggle-btn"
                    onClick={() => togglePasswordVisibility("Oldpassword")}
                  >
                    {showPassword.Oldpassword ? <FaRegEye /> : <FaRegEyeSlash />}
                  </button>
                </div>
                {validationErrors.Password && (
                  <span className="error">{validationErrors.Password}</span>
                )}
              </div>
             <div className="sendButton">
              {/* onClick={handlePasswordsubmit} */}
                <StepButton label={"Submit"} onClick={handlePasswordsubmit}  />
              </div>
            </div>
          </>
        }
      />
        {/* MARKET PLACE TOGGLE */}
        {/* <div className="account-details-marketplace-section">
          <div className="left-marketplace-section">
            <MarketPlaceIcon />
            <div className="content-section-marketplace">
              <h1>Join the Marketplace</h1>
              <p>Reach more clients and grow.</p>
            </div>
          </div>
          <SwitchButton
            isChecked={marketPlaceToggle}
            id
            onChange={() => {
              setMarketPlaceToggle(!marketPlaceToggle);
            }}
          />
        </div> */}


        {/* CLOSE ACCOUNT */}
        <div className="account-details-close-account-section">
          <div className="account-details-content">
            <h1 >Delete Faces Account</h1>
            <p>
              Ensure all appointments are done; once deleted, your account can't
              <br /> be recovered.
            </p>
          </div>
          <div><span onClick={handeledeleteacc}><BinIcon /></span></div>

        </div>
      </div>
      <div className="bottomBtn">
        <StepButton label={"Save"} onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default AccountDetails;