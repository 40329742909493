import { gql, useMutation } from "@apollo/client";

const SEND_MARKETING_EMAILS_MUTATION = gql`
  mutation SendMarketingEmails($message: String, $subject: String, $attachmentFiles: [Upload], $clientIds: [Int]) {
    sendMarketingEmails(message: $message, subject: $subject, attachmentFiles: $attachmentFiles, clientIds: $clientIds) {
      success
      message
    }
  }
`;

export const useSendMarketingEmailsMutation = () => {
  const [sendMarketingEmailsRequest, sendMarketingEmailsResponseData] =
    useMutation(SEND_MARKETING_EMAILS_MUTATION);

  const initiateSendMarketingEmails = (
    data,
    successCallback,
    errorCallback
  ) => {
    sendMarketingEmailsRequest({
      variables: data,
    })
      .then(({ data: { sendMarketingEmails: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  return { initiateSendMarketingEmails, sendMarketingEmailsResponseData };
};
