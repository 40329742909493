import React from 'react'
import "./PrescriberRegistration.scss"
// import BusinessHeader from '../../businessHeader'
import PrescriberHome from '../../prescriber-registration/PrescriberHome'

const PrescriberRegistration = () => {
  return (
    <div className='prescriber-registration-container'>
      {/* <BusinessHeader /> */}
      <PrescriberHome />
    </div>
  )
}

export default PrescriberRegistration