import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useEffect, useRef, useState } from "react";
import {
	ChildButton,
	Directions,
	FloatingMenu,
	MainButton,
} from "react-floating-button-menu";
import { ReactComponent as AddIcon } from "../../../images/add_event.svg";
import { ReactComponent as AddNewIcon } from "../../../images/add_new_event.svg";
import { ReactComponent as BlockOutIcon } from "../../../images/block_out_time.svg";
import { ReactComponent as CloseIcon } from "../../../images/close_event_icon.svg";
import OneTimeChange from "../../common/OneTimeChange";
import OffCanvasModal from "../../modal/OffCanvasModal/OffCanvasModal";
import AppointmentDetails from "../../new-appointment/AppointmentDetails";
import NewAppointment from "../../new-appointment/NewAppointment";
import FilterCanvas from "./filter-canvas/FilterCanvas";
import "./main-calendar-view.scss";
import { getBusinessProfileSettingPage } from "../../../Routes";
import moment from "moment";
import closeIcon from "../../assets/images/calendar/Close.png";
import inputSearchIcon from "../../assets/images/calendar/inputSearch.png";
import searchIcon from "../../../assets/images/calendar/magnifier.svg";
import CustomModal from "../../modal/CustomModal";
import CustomCalendar from "../dateTime/steps/CustomCalendar";

import { useLocation, useNavigate } from "react-router";
import { businessDashboardRoute, getAppointmentRoute, getBlockOutTimeRoute, getBookinginvoiceRoute, getCalendarRoute, getClientProfileFormsRoute, getEditAppointmentRoute, getNewAppointmentRoute, getShareBookingLinkRoute, getViewBlockoutTimeRoute, newAppointmentRoute } from "../../../Routes";
import BackIcon from "../../../assets/images/calendar/back-arrow-1.svg";
import DateTitleIcon from "../../assets/images/calendar/dateTitleArrow.svg";
import FilteIcon from "../../../assets/images/calendar/settings.svg";
import AppointmentListDateWise from "../../new-appointment/AppointmentListDateWise";
import ShareBookingLink from "./share-booking-link/ShareBookingLink";
import Header from "../../common/Header/Header";
import Appointment from "../appointment";
import { GetAppointmentList } from "../../redux/actions/appointments/AppointmentList";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { CLEAR_APPOINTMENT_DETAILS_V3_DATA, CLEAR_APPOINTMENT_LIST_DATA, SAVE_CALENDAR_DAILYVIEW_DATA } from "../../redux/constants/appointment/appointmentConst";
import { GetBusinessBookingLink } from "../../redux/actions/bookingsLink/GetBookingLink";
import BusinessHeader from "../../businessHeader";
import useWindowDimensions from '../../../utils/useWindowDimensions';
import Skeleton from "react-loading-skeleton";
import { CLEAR_APPOINTMENT_DOCUMENT_TAB_DATA, CLEAR_APPOINTMENT_MANAGE_TAB_DATA } from "../../redux/constants";
import CustomDropdown from "../../common/dropdown/CustomDropdown";
import StepButton from "../../common/StepButton";
import CustomRadioButton from "../../common/CustomRadioButton";
import { startOfWeek } from "date-fns";
import { GetCalendarAppointmentCountAction, GetClinicAvailableDateAction } from "../../redux/actions/dateTimeslots/DateTimeSlots";
import sharelink from "../../../images/sharelink.png"
import ShareBookingLinkIcon from "../../../images/sharelinkfloatingicon.png";
import { CLEAR_EDIT_BLOCK_OUT_TIME_DATA } from "../../redux/constants/blockOutTime/BlockOutTimeConst";
import BlockOutTimeView from "./block-out-time/view/BlockOutTimeView";
import { saveSelectedClientData } from "../../redux/actions/client/SaveSelectedClient";
import { GetClinicLocationsAction } from "../../redux/actions/main-calendar/MainCalendarAction";
import showToast from "../../common/CustomToast/CustomToast";
import rightArrow from "../../../images/icons/rightArrow.svg";
import ListViewCalender from "./ListViewCalender";
import { GoChevronRight } from "react-icons/go";
import { ReactComponent as EyeIcon } from "../../../images/icons/circleEyeIcon.svg"
import { ReactComponent as EditIcon } from '../../../images/icons/appointment-list/edit.svg'
// import { ReactComponent as CloseIcon } from '../../../images/close_icon.svg'
import { ReactComponent as DeleteIcon } from '../../../images/icons/appointment-list/delete.svg'
import { ReactComponent as DownloadIcon } from '../../../images/icons/appointment-list/download.svg'
import { ReactComponent as ResendIcon } from '../../../images/icons/appointment-list/resend.svg'
import { ReactComponent as ViewIcon } from '../../../images/icons/appointment-list/view.svg'
import { ResendBookingDetailsMutation } from "../../redux/actions/sendReminder/ResendBookingDetails";
import ConsentActionPopup from "../../consent-action-popup";
import { DeleteBlockOutTimeMutation } from "../../redux/actions/block-out-time/DeleteBlockOutTime";
import { frontendURL } from "../../../config";
import { convertMinutesToHours, weeklyViewDaySevenDateChange } from "../../../utils/general";
import CalendarViewIcon from "../../../assets/images/calendar/calendarview.svg";
import Tooltip from "../../common/Tooltip/Tooltip";
import ListViewIcon from "../../../assets/images/calendar/listviewcalendar.svg";
import ListViewToolTipContent from "./ListViewToolTipContent";
import AppointmentViewPopup from "../../common/appointment-view-popup/AppointmentViewPopup";
import { SaveTurnOffTooltipAction } from "../../redux/actions/appointments/SaveTurnOffTooltip";
import consentFormFilledIcon from "../../../images/icons/appointment-list/consentFormFilledIcon.svg"
import consentFormIncompleteIcon from "../../../images/icons/appointment-list/consentFormIncompleteIcon.svg"
import ClientFormsViewPopup from "../../common/client-forms-view-popup/ClientFormsViewPopup"
import { GetConsentFormPdfUrlQuery } from '../../redux/actions/downloadConsentForm/downloadConsentForm'
import { GetMedicalFormPdfUrlQuery } from '../../redux/actions/downloadMedicalForm/DownloadMedicalForm'
import { ResendMedicalFormAction } from '../../redux/actions/sendReminder/ResendMedicalForm'
import { PrescriberList } from '../../Client/prescriber-list/PrescriberList'

import { SendConsentFormOrMedicalFormToPrescriberAction } from '../../redux/actions/send-consent-form-or-medicalform-to-prescriber/SendConsentFormOrMedicalFormToPrescriber'
import { PrescriptionList } from '../../Client/prescription-list/PrescriptionList'
import { UpdatePrescriptionAttachedFormAction } from '../../redux/actions/update-prescription-attached-form/UpdatePrescriptionAttachedForm'
import { CLEAR_PRESCRIBER_LIST } from '../../redux/constants/consentFormThankyou/getPrescriberListConst'
import { CLEAR_PRESCRIPTIONS_LIST } from '../../redux/constants/getPrescriptionsListConst'


const MainCalendarView = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch();

	const { width, height } = useWindowDimensions();


	const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)
	const clientData = useSelector(state => state.clientConsentFormListReducer?.clientData)
	console.log(`clientData`, clientData);
	const [events, setEvents] = useState([]);
	const [viewCalendarState, setViewCalendarState] = useState(UserProfileData?.calendarView == "Daily" ? "timeGridDay" : UserProfileData?.calendarView == "Monthly" ? "dayGridMonth" : "timeGridWeek");
	const [isAppointmentDetailsOpen, setIsAppointmentDetailsOpen] = useState(false);
	const [slugFromCalender, setSlugFromCalender] = useState(null);
	const [filterCanvas, setFilterCanvas] = useState(false);
	const [isOnTimeChange, setIsOnTimeChange] = useState(false);
	const calendarRef = React.useRef(null);
	const [isOpenFloting, setIsOpenFloating] = useState(false);

	const [isShowInput, setIsShowInput] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [searchFlag, setSearchFlag] = useState(false);

	const [isOverlayOpen, setIsOverlayOpen] = useState(false);
	const [isfilterCanvasoverlay, setIsfilterCanvasoverlay] = useState(false);

	const [isAppointmentListOpen, setIsAppointmentListOpen] = useState(false);
	const [isShareBookingLinkOpen, setIsisShareBookingLinkOpen] = useState(false);

	const [checkedLocationId, setCheckedLocationId] = useState([]);
	const [checkedLocationData, setCheckedLocationData] = useState([]);
	const [checkedBookingId, setCheckedBookingId] = useState([]);

	// const [allLocationFlag, setAllLocationFlag] = useState(false)
	// const [allBookingFlag, setAllBookingFlag] = useState(false)
	const [allLocationFlag, setAllLocationFlag] = useState(true)
	const [allBookingFlag, setAllBookingFlag] = useState(true)
	const [consentActive,setConsentActive]=useState("Consent")

	//From Calander
	const [isCancleAppointmentResponse, setIsCancleAppointmentResponse] = useState(false)

	console.log("checkedLocationData", checkedLocationData)


	//CALENDAR MODAL
	const [isOpenCalendar, setIsOpenCalendar] = useState(false);
	const [showSelectedDate, setShowSelectedDate] = useState(null);
	const [calendarStartDate, setCalendarStartDate] = useState(moment().format("YYYY-MM-DD"));
	const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
	const [endDate, setEndDate] = useState(null)
	const [selectedDate, setSelectedDate] = useState(new Date());
	const location = useLocation()
	//Open Appointment list from Invoice page
	const isAppointmentListOpenFromInvoice = location?.state?.isAppointmentListOpen
	const clickedDateFromInvoice = location?.state?.clickedDate
	const fromBlockOutCancel = location?.state?.listClickDate

	console.log(calendarStartDate, "calendarStartDate", isAppointmentListOpenFromInvoice, clickedDateFromInvoice)
	console.log("fromBlockOutCancel", fromBlockOutCancel)

	const [dateData, setDateData] = useState([])

	const [selectedMonth, setSelectedMonth] = useState(
		selectedDate.getMonth() + 1
	);
	const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
	const [showSidebar, setShowSidebar] = useState(true);
	const [activeSidebar, setActiveSidebar] = useState(false);
	const [selectedYearMonth, setSelectedYearMonth] = useState();
	const [selectedYearMonthData, setSelectedYearMonthData] = useState();

	const [selectedYearMonthlyView, setSelectedYearMonthlyView] = useState(moment(calendarStartDate, ('YYYY-MM-DD')).format('YYYY'));
	const [selectedMonthMonthlyView, setSelectedMonthMonthlyView] = useState(moment(calendarStartDate, ('YYYY-MM-DD')).format('MMMM'))
	const [isBlockOutDetailsOpen, setIsBlockOutDetailsOpen] = useState(false);
	const [blockOutIdSlug, setBlockOutIdSlug] = useState(null);
	const [blockOutDateIdSlug, setBlockOutDateIdSlug] = useState(null);
	const [selectedDateForDailyView, setSelectedDateForDailyView] = useState(null);
	const [listActive, setListActive] = useState(false);
	const [isThreeDaysView, setIsThreeDaysView] = useState(UserProfileData?.calendarView == "Daily" ? false : UserProfileData?.calendarView == "Weekly" ? false : UserProfileData?.calendarView == "Monthly" ? false : true);
	const [isAppointmentMenuOpen, setIsAppointmentMenuOpen] = useState(false);
	const [isFilled, setIsFilled] = useState(null)
	const [consentFormRecordId, setConsentFormRecordId] = useState(null)
	const [appointmentId, setAppointmentId] = useState(null)
	const [showConsentPopup, setShowConsentPopup] = useState()
	const [confirmationPopupShow, setConfirmationPopupShow] = useState(false);
	const [compliancePopupShow, setCompliancePopupShow] = useState(false);
	const [isBlockOutMenuOpen, setIsBlockOutMenuOpen] = useState(false);
	const [isDelete, setIsDelete] = useState(false)
	const [isView, setIsView] = useState(false)
	const tooltipViewRef = useRef(null);
	const [type, setType] = useState(null)
	const [paymentTagData, setPaymentTagData] = useState(null)
	const [consentFormTagData, setConsentFormTagData] = useState(null)
	const [isPaymentPopUpOpen, setIsPaymentPopUpOpen] = useState(false)
	const [paymentPopUpPrice, setPaymentPopUpPrice] = useState(null)
	const [paymentPopClientName, setPaymentPopClientName] = useState(null)
	const [isFormsMenuOptionsOpen, setIsFormsMenuOptionsOpen] = useState(false)
	const [prescriberId, setPrescriberId] = useState(null)
	const [isPrescriberModalOpen, setIsPrescriberModalOpen] = useState(false)
	const [isNoPharmacyRegisterModalOpen, setIsNoPharmacyRegisterModalOpen] = useState(false)
	const [appointmentIdData, setAppointmentIdData] = useState(null);
	const [isPrescriptionModalOpen, setIsPrescriptionModalOpen] = useState(false)
	const [tempShowSelectedPrescriber, setTempShowSelectedPrescriber] = useState(null)
	const [clinicLocation, setClinicLocation] = useState([])
	const [clientId, setClientId] = useState(null)
	const [price, setPrice] = useState(null)

	const [isUpdateCalendarViewTooltip, setIsUpdateCalendarViewTooltip] = useState(false)
	const [isUpdateWorkingAvailabilityTooltip, setIsUpdateWorkingAvailabilityTooltip] = useState(false)
	const [blockoutEndDate, setBlockoutEndDate] = useState(null)


	//CALENDAR EVENT API
	const { initGetAppointmentList, appointmentListQueryData } = GetAppointmentList();
	const { appointmentList } = useSelector((state) => state.appointmentListReducer);
	const { getCalendarAppointmentCountQueryData, initGetCalendarAppointmentCountData } = GetCalendarAppointmentCountAction()
	const { getClinicLocationsQueryData, initGetClinicLocationsData } = GetClinicLocationsAction()
	const { dailyViewData } = useSelector((state) => state.appointmentListReducer);
	const { clinicsAvailableDateData, initClinicsAvailableDateResponseFunction } = GetClinicAvailableDateAction();
	const { initiateResendBookingDetailsData, resendBookingDetailsMutationData } = ResendBookingDetailsMutation()
	const { initiateDeleteBlockOutTime, deleteBlockTimeMutationData } = DeleteBlockOutTimeMutation()
	const { initSaveTurnOffTooltipData, saveTurnOffTooltipQueryData } = SaveTurnOffTooltipAction();
	console.log(`appointmentList`, appointmentList);

	const { initConsentFormPdfUrl } = GetConsentFormPdfUrlQuery()
	const { initMedicalFormPdfUrl } = GetMedicalFormPdfUrlQuery()
	const { initResendMedicalFormData, resendMedicalFormQueryData } = ResendMedicalFormAction()
	const { initSendConsentFormOrMedicalFormToPrescriberData, sendConsentFormOrMedicalFormToPrescriberQueryData } = SendConsentFormOrMedicalFormToPrescriberAction()
	const { initUpdatePrescriptionAttachedFormData, updatePrescriptionAttachedFormQueryData } = UpdatePrescriptionAttachedFormAction()

	const redirectUrl = `${frontendURL}/calendar`
	const encodedRedirectUrl = encodeURIComponent(redirectUrl);


	const dateToBePassedInApi = () => {
		const date = viewCalendarState === "dayGridMonth"
			? (selectedMonthMonthlyView || selectedYearMonthlyView)
				? `${selectedYearMonthlyView}-${moment().month(selectedMonthMonthlyView).format('M').padStart(2, '0')}-01`
				: calendarStartDate
			: viewCalendarState === "timeGridWeek" && showSelectedDate
				? isThreeDaysView ?
					moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") :
					weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "start").format("YYYY-MM-DD")

				: viewCalendarState === "timeGridDay" ? calendarStartDate
					: isThreeDaysView ?
						moment(calendarStartDate).format("YYYY-MM-DD") :
						weeklyViewDaySevenDateChange(moment(calendarStartDate), "start").format("YYYY-MM-DD")


		return date

	}

	useEffect(() => {
		if (getClinicLocationsQueryData?.data) {
			setClinicLocation(getClinicLocationsQueryData?.data?.getClinicLocations?.data)
		}
	}, [getClinicLocationsQueryData?.data?.getClinicLocations?.data])



	// LOADER EVENTS FOR DAILY VIEW
	const dailyLoaderTimeEventSlots = (tempId = false) => {
		let resultArr = []
		let array = [...Array(10)]
		let date = dateToBePassedInApi()
		if (tempId !== false) {
			date = moment(dateToBePassedInApi(), 'YYYY,MM-DD').add(tempId, 'days').format('YYYY-MM-DD')
		}
		array.map((item, index) => {
			let temptime = moment("06:00:00", "HH:mm:ss").add(30 * index, 'minutes');
			let temptime1 = moment("06:00:00", "HH:mm:ss").add(30 * index + 1, 'minutes');
			let obj = {
				id: index + tempId * 10,
				title: "loading",
				start: date + "T" + moment(temptime, "HH:mm:ss").format('HH:mm:ss'),
				end: date + "T" + moment(temptime1, "HH:mm:ss").format('HH:mm:ss'),
				appointmentColor: "#000000",
			}
			resultArr.push(obj)
		})
		return resultArr

	}

	// LOADER EVENTS FOR WEEKLY VIEW
	const weeklyLoaderTimeEventSlots = () => {
		let resultArr = []
		for (let i = 0; i < 7; ++i) {
			resultArr.push(dailyLoaderTimeEventSlots(i))
		}
		return resultArr.flat(Infinity)
	}


	// LOADER EVENTS FOR MONTHLY VIEW
	const monthlyLoaderTimeEventSlots = () => {
		let resultArr = []

		let temp = [...Array(30)]
		temp.map((item, index) => {
			let changeddate = moment(dateToBePassedInApi(), 'YYYY,MM-DD').startOf('month').add(index, 'days').format('YYYY-MM-DD')
			let obj = {
				id: index,
				title: "loading",
				start: changeddate + "T" + moment("06:00:00", "HH:mm:ss").format('HH:mm:ss'),
				end: changeddate + "T" + moment("06:00:00").format('HH:mm:ss'),
				appointmentColor: "#000000",
			}
			resultArr.push(obj)
		})

		return resultArr
	}


	//API FUNCTION
	const initGetAppointmentListCall = (search = null) => {

		let apiData;
		if (isView) {
			apiData = {
				date: dateToBePassedInApi(),
				// date: showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : moment(calendarStartDate).clone().startOf('week').add(1, "days").format("YYYY-MM-DD"),
				search: search,
				view: viewCalendarState == "dayGridMonth" ? "MONTHLY" : viewCalendarState == "timeGridDay" ? "DAILY" : isThreeDaysView ? "THREEDAY" : "WEEKLY",
				locations: allLocationFlag ? [] : checkedLocationId,
				status: allBookingFlag ? [] : checkedBookingId,
				viewCalendarState: viewCalendarState,
				needToCheckView: viewCalendarState === "timeGridWeek" ? isThreeDaysView : ""
			}
		}
		else {
			apiData = {
				date: dateToBePassedInApi(),
				// date: showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : moment(calendarStartDate).clone().startOf('week').add(1, "days").format("YYYY-MM-DD"),
				search: search,
				locations: allLocationFlag ? [] : checkedLocationId,
				status: allBookingFlag ? [] : checkedBookingId
			}
		}
		initGetAppointmentList(apiData)
		setIsAppointmentMenuOpen(false);
	}
	useEffect(() => {
		console.log("selectedYearMonth", selectedYearMonth)
	}, [selectedYearMonth])

	useEffect(() => {
		if (dailyViewData != null) {
			console.log("dailyViewData", dailyViewData, dailyViewData?.locationData?.length)
			if (dailyViewData?.locationData?.length == 0) {
				setCheckedLocationData(dailyViewData?.locationData)
				setCheckedLocationId(dailyViewData?.locationData)
				setAllLocationFlag(true)
			} else {
				setCheckedLocationData([dailyViewData?.locationData])
				setCheckedLocationId([dailyViewData?.locationData?.clinicId])
				setAllLocationFlag(false)
				// console.log("format", moment(dailyViewData?.date, "dddd, Do MMMM YYYY").format('YYYY-MM-DD'))
				let setDateData = moment(dailyViewData?.date, "dddd, Do MMMM YYYY").format('YYYY-MM-DD')
				setCalendarStartDate(setDateData)

				setShowSelectedDate(dailyViewData?.date)
				setStartDate(setDateData)
				setSelectedMonth(moment(setDateData, 'YYYY-MM-DD').format("M"))
				setSelectedYear(moment(setDateData, 'YYYY-MM-DD').format("YYYY"))
			}
		}
	}, [dailyViewData])

	// useEffect(() => {
	//     console.log("checktesting", checkedLocationData)
	//     if (checkedLocationData?.length == 1) {
	//         // setBusinessHours(checkedLocationData[0]?.businessHours)
	//         calendarRef?.current?.getApi()?.setOption('businessHours', checkedLocationData[0]?.businessHours);
	//     }
	//     else {
	//         // setBusinessHours([]);
	//         calendarRef?.current?.getApi()?.setOption('businessHours', []);
	//     }
	// }, [checkedLocationData, showSelectedDate])

	// Debounced API call function
	const debouncedApiCall = debounce((query) => {

		//API CALL
		dispatch({
			type: CLEAR_APPOINTMENT_LIST_DATA
		})
		initGetAppointmentListCall(query);

	}, 800); // Debounce delay in milliseconds (e.g., 500ms)

	// Handler for input change
	const handleInputChange = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	useEffect(() => {
		if (!listActive && (searchValue.length > 0 || searchFlag)) {
			// Call the debounced API function with the search query
			debouncedApiCall(searchValue);
		}
		setSearchFlag(true);
		// Cleanup function to cancel any pending debounced calls
		return () => {
			debouncedApiCall.cancel();
		};
	}, [searchValue]);
	useEffect(() => {
		if (calendarRef) console.log(calendarRef.current, "calendarRef");
		console.log("viewCalendarState inside", calendarStartDate)
		calendarRef?.current?.getApi()?.changeView(viewCalendarState);
		console.log(calendarStartDate, "calendarStartDate out")
		if (viewCalendarState == "dayGridMonth") {
			if (selectedYearMonthlyView && selectedMonthMonthlyView)
				calendarRef?.current?.getApi()?.gotoDate(`${selectedYearMonthlyView}-${moment().month(selectedMonthMonthlyView).format('M').padStart(2, '0')}-01`);
			else
				calendarRef?.current?.getApi()?.gotoDate(calendarStartDate);
		}
		else if (viewCalendarState == "timeGridWeek") {
			console.log("helllofromweek")
			if (isThreeDaysView) {

				calendarRef?.current?.getApi()?.gotoDate(calendarStartDate);
			}
			else {
				let selectedDateDisplay = showSelectedDate == null ?

					weeklyViewDaySevenDateChange(moment(calendarStartDate), "start").format("YYYY-MM-DD") :
					weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "start").format("YYYY-MM-DD")


				console.log("checkdatarender2", selectedDateDisplay)
				calendarRef?.current?.getApi()?.gotoDate(selectedDateDisplay);
			}
		}
		else {
			calendarRef?.current?.getApi()?.gotoDate(calendarStartDate);
		}
		// calendarRef?.current?.getApi()?.gotoDate(calendarStartDate);
	}, [viewCalendarState, calendarStartDate, selectedMonthMonthlyView, selectedYearMonthlyView, isThreeDaysView]);
	const handleViewChange = (newView) => {
		setViewCalendarState(newView);
	};

	const [isOpen, setIsOpen] = useState(false);
	const [isOpenNewAppointment, setIsOpenNewAppointment] = useState(false);
	const onAppointmentHide = () => {
		setIsOpenNewAppointment(false);
	};
	useEffect(() => {
		if (isOnTimeChange) {
			setFilterCanvas(false);
		}
	}, [isOnTimeChange]);
	const [isAddClient, setIsAddClient] = useState(false);

	const getSearchString = () => {
		return searchValue
	}
	const currentYear = moment().year();
	const yearsArray = Array.from({ length: currentYear - 2019 + 3 }, (_, index) => 2019 + index);
	// auto close serach is nothing in input
	// useEffect(() => {
	//     if (isShowInput) {
	//         setTimeout(() => {
	//             if (getSearchString() == "") {
	//                 setIsShowInput(false)
	//             }
	//         }, 3000)
	//     }

	// }, [searchValue, isShowInput])



	//State use for Cliecked Date
	const [clickedDate, setClickedDate] = useState(null)

	//Open Appointment List from DateWise
	const handleOpenAppointmentList = (date, isOpen) => {
		setClickedDate(date)
		if (isAppointmentListOpenFromInvoice) {
			setIsAppointmentListOpen(true)
		} else {
			setIsAppointmentListOpen(isOpen)
		}
	}

	useEffect(() => {
		if (location?.state?.fromCanvasAppointment) {
			setSlugFromCalender(location?.state?.fromCanvasAppointment)
			setIsAppointmentDetailsOpen(true)
			navigate(".", { replace: true });

		}
		if (location?.state?.AppointmentDate) {
			handleOpenAppointmentList(location?.state?.AppointmentDate, true)
		}
		if (location?.state?.fromAppointmentList || location?.state?.fromBlockoutCalendarList) {
			// handleOpenAppointmentList(location?.state?.clickdate, true)
			console.log("test", location?.state?.fromAppointmentList)
			setViewCalendarState(location?.state?.calenderViewState)
			setShowSelectedDate(location?.state?.calenderShowSelectedDate)
			setListActive(true)
			setFilterCanvas(true)
			setSelectedYearMonthlyView(location?.state?.calenderShowSelectedDate ? moment(location?.state?.calenderShowSelectedDate, "dddd, Do MMMM YYYY").format("YYYY") : moment(calendarStartDate, 'YYYY-MM-DD').format("YYYY"))
			setSelectedMonthMonthlyView(location?.state?.calenderShowSelectedDate ? moment(location?.state?.calenderShowSelectedDate, "dddd, Do MMMM YYYY").format("MMMM") : moment(calendarStartDate, 'YYYY-MM-DD').format("MMMM"))
			setStartDate(location?.state?.calenderShowSelectedDate ? moment(location?.state?.calenderShowSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : calendarStartDate)
			setSelectedMonth(location?.state?.calenderShowSelectedDate ? moment(location?.state?.calenderShowSelectedDate, "dddd, Do MMMM YYYY").format("M") : moment(calendarStartDate, 'YYYY-MM-DD').format("M"))
			setSelectedYear(location?.state?.calenderShowSelectedDate ? moment(location?.state?.calenderShowSelectedDate, "dddd, Do MMMM YYYY").format("YYYY") : moment(calendarStartDate, 'YYYY-MM-DD').format("YYYY"))
			setCalendarStartDate(location?.state?.calenderShowSelectedDate ? moment(location?.state?.calenderShowSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : calendarStartDate)
			navigate(".", { replace: true });
		}
		if (location?.state?.cancelAppointment) {
			setIsAppointmentListOpen(true)
		}
		if (location?.state?.blockOutId) {
			setBlockOutIdSlug(location?.state?.blockOutId)
			setBlockOutDateIdSlug(location?.state?.blockOutDateId)
			setIsBlockOutDetailsOpen(true)
			navigate(".", { replace: true });
		}
	}, [location, navigate])


	useEffect(() => {
		if (!listActive) {
			if (!moment(appointmentListQueryData?.variables.date).isSame(moment(dateToBePassedInApi()))
				|| (appointmentListQueryData?.variables?.viewCalendarState !== viewCalendarState
					|| (viewCalendarState === "timeGridWeek" && appointmentListQueryData?.variables?.needToCheckView != isThreeDaysView))) {
				initGetAppointmentListCall()
			}
		}
	}, [calendarStartDate, selectedMonthMonthlyView, selectedYearMonthlyView, viewCalendarState, checkedLocationId, checkedBookingId, isAppointmentListOpen, isThreeDaysView, listActive])

	//When Cancle Appointment Call The API
	useEffect(() => {
		if (isCancleAppointmentResponse) {
			initGetAppointmentListCall()
			setIsCancleAppointmentResponse(false)
		}
	}, [isCancleAppointmentResponse])

	function parseTimeString(timeString) {
		const parts = timeString.split(":");
		let hours = parseInt(parts[0]);
		const minutes = parseInt(parts[1].split(" ")[0]);
		const meridiem = parts[1].split(" ")[1];

		if (meridiem === "PM" && hours !== 12) {
			hours += 12;
		} else if (meridiem === "AM" && hours === 12) {
			hours = 0;
		}

		return hours * 60 + minutes;
	}

	useEffect(() => {
		if (appointmentListQueryData?.data && !appointmentListQueryData?.loading && getClinicLocationsQueryData?.data) {
			let events = [], dateWiseAppointmentCount = [], isMonthly = false;
			if (viewCalendarState === "timeGridDay") {
			} else if (viewCalendarState === "timeGridWeek") {
			} else {
				isMonthly = true;
				dateWiseAppointmentCount = appointmentListQueryData?.data?.getCalendarAppointmentList?.data?.dateWiseAppointmentCount;
			}
			appointmentListQueryData?.data?.getCalendarAppointmentList?.data?.appointments?.map((item) => {

				let treatmentNames = item.treatmentNames.join(",")
				console.log(`item`, item);
				let obj = {
					id: item.appointmentId,
					title: moment(item.appointmentStartTime, 'h:mm A').format('HH:mm') + " - " + moment(item.appointmentEndTime, 'h:mm A').format('HH:mm'),
					clientName: item.appointmentTitle,
					start: item.appointmentDate + "T" + moment(item.appointmentStartTime, 'h:mm A').format('HH:mm:ss'),
					end: item.appointmentDate + "T" + moment(item.appointmentEndTime, 'h:mm A').format('HH:mm:ss'),
					appointmentColor: item.appointmentColor,
					type: item.type,
					blockOutId: item.blockOutId,
					blockOutDateId: item.blockOutDateId,
					isFilled: item.isFilled,
					consentFormRecordId: item.consentFormRecordId,
					appointmentDate: item.appointmentDate,
					treatmentNames: viewCalendarState !== "dayGridMonth" ? treatmentNames : "",
					paymentTag: item?.paymentTag,
					paymentTagColor: item?.paymentTagColor,
					isPaymentDone: item?.isPaymentDone,
					consentFormTag: item?.consentFormTag,
					consentFormTagColor: item?.consentFormTagColor,
					blockoutEndDate: item?.blockoutEndDate,
					consentFormCount: item?.consentFormCount,
					price: item?.price,
					clientId: item?.clientId

				}
				events.push(obj)
			})

			if (isMonthly) {
				console.log("test adding monthyl ");
				for (let dateWiseAppointmentObj of dateWiseAppointmentCount) {
					if (dateWiseAppointmentObj['count'] > 3) {
						console.log("dateWiseAppointmentObj ", dateWiseAppointmentObj);
						let tempCount = dateWiseAppointmentObj['count'] - 3;
						console.log("dateWiseAppointmentObj tempCount", tempCount);
						for (let tempI = 0; tempI < tempCount; tempI++) {
							let obj = {
								id: tempI - 1,
								title: "",
								clientName: "",
								start: dateWiseAppointmentObj['date'] + "T" + moment("23:59", 'h:mm A').format('HH:mm:ss'),
								end: dateWiseAppointmentObj['date'] + "T" + moment("23:59", 'h:mm A').format('HH:mm:ss'),
								appointmentColor: "",
								type: "",
								blockOutId: 0,
								blockOutDateId: 0
							}
							events.push(obj)
						}
					}
				}
			}
			calendarRef?.current?.getApi()?.addEventSource(events);
			// calendarRef?.current?.getApi()?.moreLinkContent(() => );
			if ((viewCalendarState == "timeGridDay" && checkedLocationData?.length == 1) && getClinicLocationsQueryData?.data?.getClinicLocations?.data?.total !== 0) {
				calendarRef?.current?.getApi()?.setOption('businessHours', appointmentListQueryData?.data?.getCalendarAppointmentList?.data?.businessHours)
			}
			else {
				calendarRef?.current?.getApi()?.setOption('businessHours', [])
			}

			setEvents(events)
			setIsUpdateCalendarViewTooltip(appointmentListQueryData?.data?.getCalendarAppointmentList?.data?.isUpdateCalendarViewTooltip)
			setIsUpdateWorkingAvailabilityTooltip(appointmentListQueryData?.data?.getCalendarAppointmentList?.data?.isUpdateWorkingAvailabilityTooltip)
			// calendarRef?.current?.getApi()?.scrollToTime("14:00:00")


			if (appointmentListQueryData?.data?.getCalendarAppointmentList?.data?.appointments?.length > 0) {

				const sortAppointment = appointmentListQueryData?.data?.getCalendarAppointmentList?.data?.appointments.slice().sort((a, b) => {
					const timeA = parseTimeString(a.appointmentStartTime);
					const timeB = parseTimeString(b.appointmentStartTime);
					return timeA - timeB;
				});
				calendarRef?.current?.getApi()?.scrollToTime(moment(sortAppointment[0].appointmentStartTime, "h:mm A").format("HH:mm:ss"))
			}
			else if (appointmentListQueryData?.data?.getCalendarAppointmentList?.data?.businessHours?.daysOfWeek?.length > 0) {
				calendarRef?.current?.getApi()?.scrollToTime(appointmentListQueryData?.data?.getCalendarAppointmentList?.data?.businessHours?.daysOfWeek?.startTime)
			}
			else {
				calendarRef?.current?.getApi()?.scrollToTime("09:00:00")
			}
		} else {
			if (appointmentListQueryData?.loading) {

				let events = []
				if (viewCalendarState === "timeGridDay") {
					events = dailyLoaderTimeEventSlots()
				} else if (viewCalendarState === "timeGridWeek") {
					events = weeklyLoaderTimeEventSlots()
				} else {
					events = monthlyLoaderTimeEventSlots()
				}

				calendarRef?.current?.getApi()?.addEventSource(events);
				setEvents(events)
			} else {
				let events = []
				calendarRef?.current?.getApi()?.addEventSource(events);
				setEvents(events)
			}
		}

	}, [appointmentList, appointmentListQueryData?.data, appointmentListQueryData?.loading, getClinicLocationsQueryData?.data])
	var isMobile;

	if (width <= 991) {
		isMobile = true;

	} else {
		isMobile = false;
	}

	useEffect(() => {
		isMobile ? setShowSidebar(false) : setShowSidebar(true);
	}, [isMobile]);


	useEffect(() => {
		isMobile && setActiveSidebar(false);
		isMobile && document.body.classList.remove('sidebarOpen');
	}, [isMobile]);

	useEffect(() => {
		// dispatch({
		//     type: SAVE_CALENDAR_DAILYVIEW_DATA,
		//     payload: null,
		// });

		initGetClinicLocationsData({
			clinicCount: true
		})
	}, [])


	const handleMonthChange = (monthIndex) => {
		// setSelectedMonth(monthIndex);
	};

	const handleYearChange = (value) => {
		setSelectedYearMonth(value)
	}
	console.log(events, "events outside")
	// return <ShareBookingLink isOpen={isShareBookingLinkOpen} />;

	const MonthSelection = ({ selectedYear }) => {
		const monthsArray = moment.months();
		const handleRadioChange = (selectedValue) => {
			setSelectedYearMonthData(selectedValue);
			// Do anything else you need with the selected value
		};
		return (
			<div className="my-4">
				{monthsArray.map((month, index) => {
					const monthValue = moment().month(month).format('MMMM'); // Use 'MMMM' to get the full month name

					return <>
						<div key={index}>
							<CustomRadioButton type="basic" text={`${month},  ${selectedYear}`} value={selectedYearMonthData} checked={selectedYearMonthData === monthValue}
								onChange={(e) => handleRadioChange(monthValue)} />
						</div>
					</>
				})}
			</div>
		);
	};

	const HandleWeeklyHeaderTitle = (date) => {
		let startOfWeek;
		let endOfWeek;

		if (showSelectedDate == null) {

			startOfWeek = isThreeDaysView ?
				moment(date) :
				weeklyViewDaySevenDateChange(moment(calendarStartDate), "start")


			endOfWeek = isThreeDaysView ?
				moment(date).clone().add(2, "days") :
				// moment(date).clone().endOf('week').add(1, "days");
				weeklyViewDaySevenDateChange(moment(calendarStartDate), "end")
		}
		else {
			// startOfWeek = moment(date, "dddd, Do MMMM YYYY")
			// endOfWeek = moment(date, "dddd, Do MMMM YYYY").clone().add(6, "days")
			startOfWeek = isThreeDaysView ?
				moment(date, "dddd, Do MMMM YYYY") :
				// moment(date, "dddd, Do MMMM YYYY").clone().startOf('week').add(1, "days");
				weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "start")


			endOfWeek = isThreeDaysView ?
				moment(date, "dddd, Do MMMM YYYY").clone().add(2, "days") :
				// moment(date, "dddd, Do MMMM YYYY").clone().endOf('week').add(1, "days");
				weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "end")

		}

		console.log("startOfWeek", startOfWeek, endOfWeek)

		if (startOfWeek.isSame(endOfWeek, 'month') && startOfWeek.isSame(endOfWeek, 'year')) {
			// Same month and year
			return `${startOfWeek.format('D')} - ${endOfWeek.format('D MMM YYYY')}`;
		} else if (startOfWeek.isSame(endOfWeek, 'year')) {
			// Different month but same year
			return `${startOfWeek.format('D MMM')} - ${endOfWeek.format('D MMM YYYY')}`;
		} else {
			// Different year
			return `${startOfWeek.format('D MMM YYYY')} - ${endOfWeek.format('D MMM YYYY')}`;
		}

	}
	// console.log("locationdata", checkedLocationData)
	// console.log("calendar", showSelectedDate, "month", selectedMonth, selectedYear)
	console.log("selectedMonthMonthlyView", filterCanvas)

	const [scroll, setScroll] = useState(false);
	const scrollRef = useRef(null);

	useEffect(() => {
		const handleScroll = () => {
			if (scrollRef.current) {
				setScroll(scrollRef.current.scrollTop > 10);
			}
		};

		const scrollElement = scrollRef.current;
		scrollElement.addEventListener('scroll', handleScroll);

		return () => {
			scrollElement.removeEventListener('scroll', handleScroll);
		};
	}, []);


	useEffect(() => {
		if (filterCanvas) {
			document.body.classList.add('openfilter');
		} else {
			document.body.classList.remove('openfilter');
		}
	})

	const menuData = [
		{
			title: "Edit or reschedule",
			icon: <EditIcon width={40} />,
		},
		{
			title: "View consent form",
			icon: <ViewIcon width={40} />,
		},
		{
			title: "Appointment overview",
			icon: <EyeIcon width={40} />,
		},
		{
			title: "Resend booking & consent",
			icon: <ResendIcon width={40} />,
		},

		{
			title: "Download invoice",
			icon: <DownloadIcon width={40} />,

		},
		{
			title: "Cancel appointment",
			icon: <DeleteIcon width={40} />,
		},

	]

	const blockoutmenuData = [
		{
			title: "View or edit event",
			icon: <EyeIcon width={40} />,
		},
		{
			title: "Delete",
			icon: <DeleteIcon width={40} />,
		},
	]

	const downloadForm = (formId) => {
		initConsentFormPdfUrl({
			consentFormRecordId: formId
		}, (res) => {
			console.log(res?.data?.getConsentFormRecordPdfUrl, "download pdf")
			if (res?.data?.getConsentFormRecordPdfUrl?.success) {
				window.open(res?.data?.getConsentFormRecordPdfUrl?.data?.consentFormRecordPdfUrl, "_blank")
				setIsFormsMenuOptionsOpen(false)
			} else {
				showToast("error", res.message, "", false)
			}
		}, (err) => {
			showToast("error", err, "", false)

		})
	}

	const handleSendReminder = (formId) => {
		initiateResendBookingDetailsData({
			"appointmentId": null,
			"consentFormRecordId": formId
		}, (res) => {
			if (res.success) {
				showToast("success", res.message, "", false)
				setIsFormsMenuOptionsOpen(false)
			} else {
				showToast("error", res.message, "", false)
			}
		}, (err) => {
			showToast("error", err, "", false)
		})
	}

	const sendClickHandler = (selectedPrescriberId) => {
		// let data;
		// if (active === "Consent") {
		//     data = {
		//         consentFormId: consentFormRecordId,
		//         prescriberId: parseInt(selectedPrescriberId)
		//     }
		// }
		// else {
		//     data = {
		//         clientId: clientId,
		//         prescriberId: parseInt(selectedPrescriberId)
		//     }
		// }
		// handleSendConsentFormorMedicalFormToPrescriber(data)
		setIsPrescriberModalOpen(false)
		setPrescriberId(selectedPrescriberId)
		setIsPrescriptionModalOpen(true)
	}
	const downloadMedicalForm = (formId) => {
		initMedicalFormPdfUrl({
			clientId: clientId
		}, (res) => {
			if (res?.data?.getMedicalFormPdfUrl?.success) {
				window.open(res?.data?.getMedicalFormPdfUrl?.data?.medicalFormRecordPdfUrl, "_blank")
				setIsFormsMenuOptionsOpen(false)
			} else {
				showToast("error", res.message, "", false)
			}
		}, (err) => {
			showToast("error", err, "", false)

		})
	}

	const handleResendMedicalForm = () => {
		initResendMedicalFormData({
			clientId: clientId
		}, (res) => {
			if (res.success) {
				showToast("success", res.message, "", false)
				setIsFormsMenuOptionsOpen(false)
			} else {
				showToast("error", res.message, "", false)
			}
		}, (err) => {
			showToast("error", err, "", false)
		})
	}

	const handleResendDetails = (formId) => {
		initiateResendBookingDetailsData({
			"appointmentId": formId,
			"consentFormRecordId": null
		}, (res) => {
			if (res.success) {
				// toast.success(res.message)
				showToast("success", res?.message, "", false)
				setIsAppointmentMenuOpen(false)
			} else {

				showToast("error", res?.message, "", false)
			}
		}, (err) => {
			showToast("error", err, "", false)
		})
	}

	const sendtoPrescriberHandler = (dataTobePassed) => {

		let data;

		if (consentActive === "Consent") {
		    data = {
		        prescriptionId: dataTobePassed?.prescriptionId,
		        clientId: clientId,
		        consentFormId: consentFormRecordId,
		        attachMedicalForm: dataTobePassed?.attachMedicalForm
		    }
		}
		else {
		data = {
			prescriptionId: dataTobePassed?.prescriptionId,
			clientId: clientId,
			consentFormId: 0,
			attachMedicalForm: true,
		}
		}
		initUpdatePrescriptionAttachedFormData(
			data
			, (res) => {
				if (res.success) {
					showToast("success", res.message, "", false)
					setIsPrescriptionModalOpen(false)
					setTempShowSelectedPrescriber(null)
				} else {
					showToast("error", res.message, "", false)
					setTempShowSelectedPrescriber(null)
				}
			}, (err) => {
				showToast("error", err, "", false)
			})
	}

	const handlePaymentResend = () => {
		handleResendDetails(appointmentId);
		setIsPaymentPopUpOpen(false)
	}


	const handleConsentFormClick = (title) => {
		switch (title) {
			case "Download PDF":
				if (consentFormRecordId !== null) {
					downloadForm(consentFormRecordId)
				}
				break;

			case "Send to client":
				handleSendReminder(consentFormRecordId)
				break;


			case "Re-send to client":
				handleSendReminder(consentFormRecordId)
				break;

			case "Send to prescriber":
				dispatch({
					type: CLEAR_PRESCRIBER_LIST
				})
				dispatch({
					type: CLEAR_PRESCRIPTIONS_LIST
				})
				setPrescriberId(null);
				setIsFormsMenuOptionsOpen(false)
				setIsAppointmentMenuOpen(false)
				if (appointmentList?.vendorIsPharmacy) {
					// initiatePrescriberListCall(true)
					setIsPrescriberModalOpen(true)
				}
				else {
					setIsNoPharmacyRegisterModalOpen(true)
				}
				break;

			case "Edit consent form":
				window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
				break;

			case "Complete in person":
				window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
				break;



			case "Repeat form":
				window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/duplicate/" + consentFormRecordId + `?documents=true`
				// navigate(rebookAppointmentRoute(appointmentIdData?.appointmentId, clientId), { state: { isFromClientForm: true } })
				break;

			case "Download PDF":
				downloadMedicalForm()
				break;

			case "Send to client":
				handleResendMedicalForm()
				break;

			case "Re-send to client":
				handleResendMedicalForm()
				break;

			case "Send to prescriber":
				dispatch({
					type: CLEAR_PRESCRIBER_LIST
				})
				dispatch({
					type: CLEAR_PRESCRIPTIONS_LIST
				})
				setPrescriberId(null);
				setIsFormsMenuOptionsOpen(false)
				setIsAppointmentMenuOpen(false)
				if (appointmentList?.vendorIsPharmacy) {
					// initiatePrescriberListCall(true)
					setIsPrescriberModalOpen(true)
				}
				else {
					setIsNoPharmacyRegisterModalOpen(true)
				}
				break;

			case "Edit medical form":
				window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`
				break;

			case "Complete in person":
				window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`
				break;

			default:
				console.log('Default')
				break;
		}



	}

	const handleMenuClick = (title) => {
		console.log("handlemenucick", appointmentId)
		switch (title) {

			case "View or edit event":
				// if (moment().isAfter(moment(blockoutEndDate, "YYYY-MM-DD"))) {
				// 	showToast("error", "you can't edit this event", "", "")
				// }
				// else {
					navigate(getViewBlockoutTimeRoute(blockOutIdSlug, blockOutDateIdSlug), { state: { goBackToCalendar: true } })
				// }

				break;

			case "Delete event":
				if (moment().isAfter(moment(blockoutEndDate, "YYYY-MM-DD"))) {
					showToast("error", "You can't delete this event", "", "")
				}
				else {
					setIsDelete(true);
					setIsAppointmentMenuOpen(false)
				}
				break;

			case "Payment":
				if (paymentTagData?.paymentTag === "Unpaid") {
					setIsPaymentPopUpOpen(true)
				}
				// handleResendDetails(appointmentId);
				break;

			case "Edit or reschedule":
				console.log('Edit / reschedule')
				navigate(getEditAppointmentRoute(appointmentId))
				break;

			case "Appointment overview":
				navigate(getAppointmentRoute(appointmentId), { state: { fromCalendarAppointmentOverview: true, fromCalenderAppointment: true } })
				break;

			case "Resend booking & consent":
				console.log('Resend booking details')
				handleResendDetails(appointmentId)
				break;

			case "Download invoice":
				console.log('Download invoice', appointmentId)
				navigate(getBookinginvoiceRoute(), { state: { entityId: appointmentId, selectedDate: clickedDate } })
				break;

			case "Consent form":
				setIsFormsMenuOptionsOpen(true)
				document.body.click()
				// window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`

				// navigate(getClientProfileFormsRoute(clientId), { state: { appointmentId: appointmentId } })
				break;

			case "Cancel appointment":
				setShowConsentPopup(true);
				setConfirmationPopupShow(true);
				setIsAppointmentMenuOpen(false)
				console.log('Delete')
				break;

			default:
				console.log('Default')
				break;
		}
	}
	const handleBlockOutMenuClick = (title) => {
		console.log("handlemenucick", appointmentId)
		switch (title) {
			case "View or edit event":
				navigate(getViewBlockoutTimeRoute(blockOutIdSlug, blockOutDateIdSlug), { state: { goBackToCalendar: true } })
				break;

			case "Delete":
				setIsDelete(true);
				setIsBlockOutMenuOpen(false)
				console.log('Delete')
				break;

			default:
				console.log('Default')
				break;
		}
	}

	//DELETE BLOCK OUT TIME
	const handleDeleteBlockOut = () => {
		initiateDeleteBlockOutTime({
			blockOutDateId: blockOutDateIdSlug,
			blockOutId: blockOutIdSlug
		}, (res) => {
			if (res?.success) {
				setIsDelete(false)
				showToast("success", res.message, "", "")
				initGetAppointmentListCall();
			}
		}, (err) => {
			showToast("error", err.message, "", "")
			console.log(err)
		})
	}

	useEffect(() => {
		if (UserProfileData != null && !location?.state?.fromAppointmentList) {
			if (UserProfileData?.calendarView == "Daily") {
				setViewCalendarState("timeGridDay")
				// setListActive(false);
				setIsThreeDaysView(false)
			}
			else if (UserProfileData?.calendarView == "Threeday") {
				setViewCalendarState("timeGridWeek")
				setIsThreeDaysView(true);
				setListActive(false);
			}
			else if (UserProfileData?.calendarView == "Weekly") {
				setViewCalendarState("timeGridWeek")
				setListActive(false);
				setIsThreeDaysView(false);
			}
			else if (UserProfileData?.calendarView == "Monthly") {
				setViewCalendarState("dayGridMonth")
				setListActive(false);
				setIsThreeDaysView(false);
			}
		}
	}, [UserProfileData])

	const onCalenderTooltipButtonClickHandler = () => {
		initSaveTurnOffTooltipData({
			toolTipType: 7
		}, (res) => {
			setIsUpdateCalendarViewTooltip(false)
		}, (err) => {

		}
		)
	}

	const onUpdateWorkingAvailabilityTooltipButtonClickHandler = () => {
		initSaveTurnOffTooltipData({
			toolTipType: 8
		}, (res) => {
			setIsUpdateWorkingAvailabilityTooltip(false)
		}, (err) => {

		}
		)
	}

	const onCloseHandler = () => {
		setIsAppointmentMenuOpen(true)
	}
	return (
		<>
			{/* <Header />  */}
			<div style={{ borderBottom: "2px solid #eeeff3" }}>
				<BusinessHeader
					openMobileMenu={() => {
						setShowSidebar(!showSidebar);
						setActiveSidebar(true);
						document.body.classList.add('sidebarOpen');
					}}
					isMobile={isMobile}
					removeHeaderSpace={true}
					isDisplayHeader={false}
					isDisplayNotification={false}
				/>
			</div>
			<div className={`calendar-filter-container Main-calender-Screen ${viewCalendarState === "timeGridWeek" ? "timeGridWeek" : ""} ${viewCalendarState === "dayGridMonth" ? "dayGridMonth" : ""} ${filterCanvas ? " calendar-fix-width" : "calendar-full-width"}`}>
				<div className="calendar-main-div" >
					<div className="calendar-section">
						<div className={`calendar-header-main-div ${isShowInput ? "isShowInputWrapper" : ""}`}>
							<div className="row mx-0">

								<div className="col-md-3">
									<div className="search-div-wrapper left-div">
										<div className="search-div-inner-wrapper cursor-pointer" onClick={() => navigate(businessDashboardRoute())}>
											<img src={BackIcon} alt="BackIcon" />
										</div>
									</div>
								</div>
								<div className="col-md-6 d-flex justify-content-center align-items-center">

									{/* <div className="calendar-date-title">
                                    <div className="dateWrapper" onClick={() => {
                                        setIsOpenCalendar(true)
                                        setSelectedYearMonth(selectedYearMonthlyView ? selectedYearMonthlyView : moment(calendarStartDate, ('YYYY-MM-DD')).format('yyyy'))
                                        setSelectedYearMonthData(selectedMonthMonthlyView ? selectedMonthMonthlyView : moment(calendarStartDate, ('YYYY-MM-DD')).format('MMMM'))
                                    }}>
                                        <h6 className="m-0">
                                            {
                                                viewCalendarState == "dayGridMonth" ? (selectedMonthMonthlyView || selectedYearMonthlyView) ? `${selectedMonthMonthlyView} ${selectedYearMonthlyView}` : `${moment(calendarStartDate, ('YYYY-MM-DD')).format('MMMM YYYY')}` : viewCalendarState == "timeGridDay" ? `${moment(calendarStartDate).format('ddd, DD MMM YYYY')}` :
                                                    showSelectedDate == null ?
                                                        // `${moment(calendarStartDate).clone().startOf('week').format('ddd D MMM')} – ${moment(calendarStartDate).clone().endOf('week').format('D MMM')}`
                                                        HandleWeeklyHeaderTitle(calendarStartDate)
                                                        :
                                                        //  `${moment(showSelectedDate, "dddd, Do MMMM YYYY").format("ddd D MMM")} -  ${moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().add(6, "days").format("D MMM")}`
                                                        HandleWeeklyHeaderTitle(showSelectedDate)
                                            }

                                        </h6>
                                        <img src={DateTitleIcon} alt="DateTitleIcon" />
                                    </div>
                                    <h6 className="LocationWrapper">{getClinicLocationsQueryData?.data?.total == 1 ? "" : checkedLocationData?.length > 0 ? `${checkedLocationData[0]?.clinicName} ${checkedLocationData?.length > 1 ? `+ ${checkedLocationData?.length - 1} ` : ""}` : "All Locations"}</h6>
                                </div>  */}
									<div className="calendar-date-day-arrow-title">
										<div className="dateWrapper">
											<div className="leftarrowWrapper">
												<img src={DateTitleIcon} alt="DateTitleIcon" className="leftarrow" onClick={() => {
													if (viewCalendarState === "timeGridDay") {
														let prevDate = moment(calendarStartDate).subtract(1, 'days').format('YYYY-MM-DD')
														setCalendarStartDate(prevDate)

														setShowSelectedDate(moment(prevDate).format("dddd, Do MMMM YYYY"))
														setStartDate(prevDate)
														setSelectedMonth(moment(prevDate, 'YYYY-MM-DD').format("M"))
														setSelectedYear(moment(prevDate, 'YYYY-MM-DD').format("YYYY"))
													}
													else if (viewCalendarState === "timeGridWeek") {
														let prevDate;

														if (showSelectedDate == null) {
															console.log("hiithree1", calendarStartDate)
															prevDate = isThreeDaysView ? moment(calendarStartDate).clone().subtract(3, "days").format('YYYY-MM-DD') : moment(calendarStartDate).clone().endOf('week').subtract(2, "days").format('YYYY-MM-DD')
														}
														else {
															console.log("hiithree2", showSelectedDate)
															prevDate = isThreeDaysView ? moment(showSelectedDate, "dddd, Do MMMM YYYY").subtract(3, 'days').format('YYYY-MM-DD') : moment(showSelectedDate, "dddd, Do MMMM YYYY").subtract(7, 'days').format('YYYY-MM-DD')

														}
														setCalendarStartDate(prevDate)
														setShowSelectedDate(moment(prevDate).format("dddd, Do MMMM YYYY"))
														setStartDate(prevDate)
														setSelectedMonth(moment(prevDate, 'YYYY-MM-DD').format("M"))
														setSelectedYear(moment(prevDate, 'YYYY-MM-DD').format("YYYY"))
													}
													else if (viewCalendarState === "dayGridMonth") {
														setSelectedMonthMonthlyView(selectedMonthMonthlyView ? moment(`${selectedMonthMonthlyView} ${selectedYearMonthlyView}`, "MMMM YYYY").subtract(1, "months").format("MMMM") : moment(calendarStartDate, ('YYYY-MM-DD')).subtract(1, "months").format('MMMM'))
														setSelectedYearMonthlyView(selectedMonthMonthlyView ? moment(`${selectedMonthMonthlyView} ${selectedYearMonthlyView}`, "MMMM YYYY").subtract(1, "months").format("YYYY") : moment(calendarStartDate, ('YYYY-MM-DD')).subtract(1, "months").format('YYYY'))
													}
												}} />
											</div>
											<div className="date-title">
												<h6 className="date-title-text m-0" onClick={() => {
													// setIsOpenCalendar(true)
													setFilterCanvas(true)
													setSelectedYearMonth(selectedYearMonthlyView ? selectedYearMonthlyView : moment(calendarStartDate, ('YYYY-MM-DD')).format('yyyy'))
													setSelectedYearMonthData(selectedMonthMonthlyView ? selectedMonthMonthlyView : moment(calendarStartDate, ('YYYY-MM-DD')).format('MMMM'))
												}}>
													{
														viewCalendarState == "dayGridMonth" ? (selectedMonthMonthlyView || selectedYearMonthlyView) ? `${selectedMonthMonthlyView} ${selectedYearMonthlyView}` : `${moment(calendarStartDate, ('YYYY-MM-DD')).format('MMMM YYYY')}` : viewCalendarState == "timeGridDay" ? showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format('dddd DD MMM, YYYY') : `${moment(calendarStartDate).format('dddd DD MMM, YYYY')}` :
															showSelectedDate == null ?
																// `${moment(calendarStartDate).clone().startOf('week').format('ddd D MMM')} – ${moment(calendarStartDate).clone().endOf('week').format('D MMM')}`
																HandleWeeklyHeaderTitle(calendarStartDate)
																:
																//  `${moment(showSelectedDate, "dddd, Do MMMM YYYY").format("ddd D MMM")} -  ${moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().add(6, "days").format("D MMM")}`
																HandleWeeklyHeaderTitle(showSelectedDate)
													}

												</h6>
											</div>
											<div className="rightarrowWrapper">
												<img src={DateTitleIcon} alt="DateTitleIcon" className="rightarrow" onClick={() => {
													console.log("show", showSelectedDate)
													if (viewCalendarState === "timeGridDay") {
														let nextDate = moment(calendarStartDate).add(1, 'days').format('YYYY-MM-DD')
														setCalendarStartDate(nextDate)

														setShowSelectedDate(moment(nextDate).format("dddd, Do MMMM YYYY"))
														setStartDate(nextDate)
														setSelectedMonth(moment(nextDate, 'YYYY-MM-DD').format("M"))
														setSelectedYear(moment(nextDate, 'YYYY-MM-DD').format("YYYY"))
													}
													else if (viewCalendarState === "timeGridWeek") {
														let nextDate

														if (showSelectedDate == null) {
															nextDate = isThreeDaysView ? moment(calendarStartDate).clone().add(3, "days").format('YYYY-MM-DD') : moment(calendarStartDate).clone().endOf('week').add(2, "days").format('YYYY-MM-DD')
														}
														else {
															nextDate = isThreeDaysView ? moment(showSelectedDate, "dddd, Do MMMM YYYY").add(3, 'days').format('YYYY-MM-DD') : moment(showSelectedDate, "dddd, Do MMMM YYYY").add(7, 'days').format('YYYY-MM-DD')
														}
														setCalendarStartDate(nextDate)

														setShowSelectedDate(moment(nextDate).format("dddd, Do MMMM YYYY"))
														setStartDate(nextDate)
														setSelectedMonth(moment(nextDate, 'YYYY-MM-DD').format("M"))
														setSelectedYear(moment(nextDate, 'YYYY-MM-DD').format("YYYY"))

													}
													else if (viewCalendarState === "dayGridMonth") {
														console.log("checkingyear", selectedMonthMonthlyView ? moment(selectedMonthMonthlyView, "MMMM").add(1, "months").format("YYYY") : moment(calendarStartDate, ('YYYY-MM-DD')).add(1, "months").format('YYYY'))
														setSelectedMonthMonthlyView(selectedMonthMonthlyView ? moment(`${selectedMonthMonthlyView} ${selectedYearMonthlyView}`, "MMMM YYYY").add(1, "months").format("MMMM") : moment(calendarStartDate, ('YYYY-MM-DD')).add(1, "months").format('MMMM'))
														setSelectedYearMonthlyView(selectedMonthMonthlyView ? moment(`${selectedMonthMonthlyView} ${selectedYearMonthlyView}`, "MMMM YYYY").add(1, "months").format("YYYY") : moment(calendarStartDate, ('YYYY-MM-DD')).add(1, "months").format('YYYY'))
													}
												}} />
											</div>
										</div>
										<h6 className="LocationWrapper">{getClinicLocationsQueryData?.data?.getClinicLocations?.data?.total == 0 ? "No location" : checkedLocationData?.length > 0 ? `${checkedLocationData[0]?.clinicName} ${checkedLocationData?.length > 1 ? `+ ${checkedLocationData?.length - 1} ` : ""}` : "All locations"}</h6>
									</div>
								</div>
								<div className="col-md-3">
									<div className="search-div-wrapper">
										<div className={isShowInput ? 'CustomeSearchInput CustomeActiveInput' : 'CustomeSearchInput'}>
											<div className={'input-search-div'}>
												<img src={inputSearchIcon} alt="inputSearchIcon" />
												<input type="text" placeholder="Name, email or phone"
													value={searchValue} onChange={handleInputChange}
												/>
												<img src={closeIcon} className="cursor-pointer" alt="closeIcon" onClick={() => { setSearchValue(""); setIsShowInput(false) }} />
											</div>
											<div className="mainIconSearch">
												<img src={searchIcon} className="cursor-pointer" alt="searchIcon" onClick={() => setIsShowInput(true)} />
											</div>
										</div>
										{listActive ? <div>
											<img src={ListViewIcon} className="ms-2 cursor-pointer" alt="FilteIcon" onClick={() => {
												setListActive(!listActive)
											}} />
										</div> :
											<>
												<div id="list-view">
													<img src={CalendarViewIcon} className="ms-2 cursor-pointer" alt="FilteIcon" onClick={() => {
														setListActive(!listActive)
													}} />
												</div>
												{isUpdateCalendarViewTooltip && <Tooltip targetId={"#list-view"} place={"bottom-end"} style={{ borderRadius: "20px", background: "#000" }} toolTipContent={<ListViewToolTipContent className="list-view-tooltip" btnLabel={"Got it!"} btnClassName={"tooltip-btn w-50"} toolTipTitle={"NEW: Switch your appointment viewing"} tooltipRef={tooltipViewRef} onClickCallback={onCalenderTooltipButtonClickHandler} isLoading={saveTurnOffTooltipQueryData?.loading} />} tooltipRef={tooltipViewRef} />}
											</>
										}

										<img src={filterCanvas ? rightArrow : FilteIcon} className="ms-2 cursor-pointer" alt="FilteIcon" onClick={() => {
											setFilterCanvas(!filterCanvas)
											// if (dailyViewData != null) {
											//     console.log("filter click", dailyViewData?.locationData)
											//     setCheckedLocationData([dailyViewData?.locationData])
											//     setCheckedLocationId([dailyViewData?.locationData?.clinicId])
											// }
										}} />

										{/* <img src={sharelink} className="ms-2 cursor-pointer" alt="ShareLinkIcon" onClick={() => navigate(getShareBookingLinkRoute())} /> */}

									</div>
								</div>
							</div>
						</div>
						{listActive ? <ListViewCalender
							viewCalendarState={viewCalendarState}
							showSelectedDate={showSelectedDate}
							locationId={checkedLocationId}
							searchValue={searchValue}
							isThreeDaysView={isThreeDaysView}
							// totalAppointmentCountFromCalendar={appointmentListQueryData?.data?.getCalendarAppointmentList?.data?.totalAppointments}
							tooltipClickHandler={onUpdateWorkingAvailabilityTooltipButtonClickHandler}
							isUpdateWorkingAvailabilityTooltip={isUpdateWorkingAvailabilityTooltip}
							isLoading={saveTurnOffTooltipQueryData?.loading}
							checkedLocationData={checkedLocationData}
						/> : <FullCalendar
							fixedWeekCount={false}
							ref={calendarRef}
							plugins={[
								dayGridPlugin,
								timeGridPlugin,
								interactionPlugin,
								listPlugin,
							]}
							locale={'en-GB'}
							slotLabelFormat={{
								hour12: false,
								hour: '2-digit',
								minute: '2-digit',
								omitZeroMinute: false,
								meridiem: 'short'
							}}
							initialView={viewCalendarState}
							initialDate={calendarStartDate}  // Set the initial date to February 1, 2024
							headerToolbar={{
								start: "",
								center: "",
								end: "",
							}}
							height={"100%"}
							expandRows
							width={"100%"}
							eventClassNames={`custom-event ${appointmentListQueryData?.loading ? "bg-transparent" : ""}`}
							events={events}
							// moreLinkContent={(ARGS) => console.log(ARGS?.view?.getCurrentData()?.getCurrentData(), ARGS.text, ARGS.num, "ARGS COUNT " + ARGS.num)}

							eventContent={(eventInfo) => {
								return (
									<>
										{
											appointmentListQueryData?.loading ?
												<>
													{
														viewCalendarState === "dayGridMonth" ?
															<div className="w-100">
																<Skeleton className="mb-2" height={30} width={"100%"} />
																<Skeleton height={30} width={"100%"} />
															</div>
															:
															<div className="w-100">
																<Skeleton height={60} width={"100%"} />
															</div>

													}
												</>
												:
												eventInfo.event.title != "loading" ?
													eventInfo.event._def.extendedProps.type == "Appointment" ? <div className="custom-event-class"  >
														<div className='beforeBorder' style={{ background: `${eventInfo.event._def.extendedProps.appointmentColor}` }} />
														<div className={`${viewCalendarState === "timeGridDay" ? "d-flex justify-content-between" : ""}`}>
															{viewCalendarState !== "timeGridDay" && <div className={`confirmation-dot ${isThreeDaysView ? "ms-auto" : "me-auto"}`} style={{ backgroundColor: `${eventInfo.event._def.extendedProps.paymentTagColor}` }}>

															</div>}

															{viewCalendarState === "timeGridDay" &&
																<>

																	<div className='d-flex align-items-center'>
																		<div className={`user-image-container-calendar-appointment d-flex`}>
																			{!UserProfileData?.user?.image_url ?
																				<div className='name-wrapper'><h2 className='m-auto'>{UserProfileData?.user?.usr_fname[0]?.toUpperCase() + (UserProfileData?.user?.usr_lname ? UserProfileData?.user?.usr_lname[0]?.toUpperCase() : UserProfileData?.user?.usr_fname[1]?.toUpperCase())}</h2> </div> :
																				<img src={UserProfileData?.user?.image_url} alt="profile" />}
																		</div>
																		{eventInfo.event._def.extendedProps.consentFormCount > 0 && <div className="consent-form-img-wrapper ms-1">
																			<img src={eventInfo.event._def.extendedProps.isFilled ? consentFormFilledIcon : consentFormIncompleteIcon} alt="consentform" />
																		</div>}

																	</div>
																	<div className="confirmation-duration-wrapper">
																		<div className={`status-badge`} style={{ background: `${eventInfo.event._def.extendedProps.paymentTagColor}` }}
																		>
																			<p>{eventInfo.event._def.extendedProps.paymentTag}</p>
																		</div>
																		{/* <p>{convertMinutesToHours(parseInt(eventInfo.event._def.extendedProps.totalDuration))}</p> */}
																	</div>
																</>
															}
														</div>
														<b className="calendar-event-time-title">{eventInfo.event.title}</b>
														<p className="calendar-event-name-title">{eventInfo.event._def.extendedProps.clientName}</p>
														<p className="calendar-event-name-title">{eventInfo.event._def.extendedProps.treatmentNames}</p>
													</div> :
														eventInfo.event._def.extendedProps.type == "Blockout" ?
															<div className="block-out-custom-event-class"  >
																<b className="calendar-event-time-title">{eventInfo.event.title}</b>
																<p className="calendar-event-name-title">{eventInfo.event._def.extendedProps.clientName}</p>
															</div> : null
													: null

										}
									</>
								)
							}}
							eventClick={(info) => {
								info.jsEvent.preventDefault();
								console.log("event", info.event)
								// handleSpecificEventClick(info.event?.extendedProps, info.event?.title)
								// Perform your desired action here
								setSlugFromCalender(info.event._def.publicId)
								if (info.event._def.extendedProps.type == "Blockout") {
									// setIsBlockOutDetailsOpen(true)
									setBlockOutIdSlug(info.event._def.extendedProps.blockOutId)
									setBlockOutDateIdSlug(info.event._def.extendedProps.blockOutDateId)
									setIsAppointmentMenuOpen(true)
									setType("Blockout")
									setBlockoutEndDate(info.event._def.extendedProps.blockoutEndDate)
								}
								else {
									{ console.log("info", info) }
									// setIsAppointmentDetailsOpen(true)
									setIsFilled(info.event._def.extendedProps.isFilled)
									setConsentFormRecordId(parseInt(info.event._def.extendedProps.consentFormRecordId))
									setAppointmentId(parseInt(info.event._def.publicId))
									setPaymentPopUpPrice(info.event._def.extendedProps.price);
									setPaymentPopClientName(info.event._def.extendedProps.clientName)
									setIsAppointmentMenuOpen(true);
									setClientId(info.event._def.extendedProps.clientId)
									setPrice(info.event._def.extendedProps.price)
									setType("Appointment")
									setPaymentTagData(
										{
											paymentTag: info.event._def.extendedProps.paymentTag,
											paymentTagColor: info.event._def.extendedProps.paymentTagColor,
											isPaymentDone: info.event._def.extendedProps.isPaymentDone,
										}
									)
									setConsentFormTagData(
										{
											consentFormTag: info.event._def.extendedProps.consentFormTag,
											consentFormTagColor: info.event._def.extendedProps.consentFormTagColor,
											consentFormCount: info.event._def.extendedProps.consentFormCount
										}
									)

								}
							}}
							eventLimit={true}
							moreLinkClassNames={"pe-none"}
							views={{
								dayGridMonth: {
									dayMaxEvents: 3,
									dayHeaderFormat: { weekday: width < 1200 ? 'short' : 'long' },

								}, timeGridWeek: {
									dayHeaderFormat: { weekday: width < 1200 ? 'short' : 'long', day: 'numeric' },
									slotEventOverlap: false,
									eventOverlap: false,
									...({ duration: isThreeDaysView ? { days: 3 } : { days: 7 } }),
									// eventMaxStack: 2,
									// slotDuration: '00:15:00',
									// slotLabelInterval: 1,
									// slotLabelFormat: {
									//     hour: 'numeric',
									//     minute: '2-digit',
									//     omitZeroMinute: false,
									//     hour12: false
									// }
									// ...(moment(calendarStartDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD") && moment(calendarStartDate).isoWeekday() !== 1 ? { duration: { days: 7 } } : {})
								},
							}}
							firstDay='1'
							viewClassNames={viewCalendarState == "timeGridDay" ? "custom-view cursor-pointer" : "custom-view "}
							dayCellClassNames={"cursor-pointer"}
							eventDisplay="list-item"
							allDaySlot={false}
							dateClick={(e) => {
								console.log("dateClick", e.dateStr);
								console.log("date data", calendarStartDate, showSelectedDate)
								setSelectedDateForDailyView(moment(e.dateStr).format('YYYY-MM-DD'))
								if (viewCalendarState == "timeGridDay" && selectedDateForDailyView == moment(e.dateStr).format('YYYY-MM-DD')) {
									// alert("sasdfsfd")
									// if (appointmentListQueryData?.data?.getCalendarAppointmentList?.data?.isDateAvailableForBooking) {
									let objectFromArrayOfObjects = {};

									if (checkedLocationData?.length == 1) {

										objectFromArrayOfObjects = Object.assign({}, ...checkedLocationData);
									}
									else {
										objectFromArrayOfObjects = []
									}

									let data = {
										fromDayView: viewCalendarState,
										date: moment(e.dateStr).format('dddd, Do MMMM YYYY'),
										time: moment(e.dateStr).format("HH:mm"),
										locationData: objectFromArrayOfObjects
									}
									dispatch({
										type: SAVE_CALENDAR_DAILYVIEW_DATA,
										payload: data,
									});
									navigate(getNewAppointmentRoute())
									// }
									// else {
									//     showToast("error", "Date not available for booking", "", false)
									// }
								}
								else if (viewCalendarState == "dayGridMonth") {
									setIsView(true);
									setViewCalendarState("timeGridDay")
									setCalendarStartDate(e.dateStr)
									setShowSelectedDate(moment(e.dateStr, "YYYY-MM-DD").format("dddd, Do MMMM YYYY"))
									setSelectedMonth(moment(e.dateStr, 'YYYY-MM-DD').format("M"))
									setSelectedYear(moment(e.dateStr, 'YYYY-MM-DD').format("YYYY"))
								}
								else if (viewCalendarState == "timeGridWeek") {
									// handleOpenAppointmentList(e.dateStr, true)

								}

							}
							}
							dayHeaders={viewCalendarState == "timeGridDay" ? false : true}
						// businessHours={
						//     businessHours
						// }
						/>
						}
					</div>
					{!filterCanvas && <div className="plus-button-section">
						<FloatingMenu
							slideSpeed={500}
							direction={Directions.Up}
							spacing={8}
							isOpen={(isOpenFloting && isOverlayOpen)}
						>
							<MainButton
								iconResting={<AddIcon style={{ fontSize: 20 }} />}
								iconActive={<CloseIcon style={{ fontSize: 20 }} />}
								onClick={() => { setIsOpenFloating(!isOpenFloting); setIsOverlayOpen(!isOpenFloting); }}
								size={60}
								background="black"
							/>
							<ChildButton
								icon={<div className="text-tooltip w-100"><h6>Share booking link</h6><img src={ShareBookingLinkIcon} alt="share booking link" style={{ fontSize: 20 }} /></div>}
								size={60}
								onClick={() => { navigate(getShareBookingLinkRoute()); setIsOverlayOpen(false); setIsOpenFloating(false) }}
								background="white"
							/>

							<ChildButton
								icon={<div className="text-tooltip w-100"><h6>Block out time</h6><BlockOutIcon style={{ fontSize: 20 }} /></div>}
								size={60}
								background="white"
								onClick={() => { navigate(getBlockOutTimeRoute()); dispatch({ type: CLEAR_EDIT_BLOCK_OUT_TIME_DATA }) }}
							/>
							<ChildButton
								icon={<div className="text-tooltip w-100"><h6>New appointment</h6><AddNewIcon style={{ fontSize: 20 }} /></div>}
								size={60}
								onClick={() => {
									dispatch(saveSelectedClientData({}))

									// if (appointmentListQueryData?.data?.getCalendarAppointmentList?.data?.isDateAvailableForBooking) {


									let objectFromArrayOfObjects = {};

									if (checkedLocationData?.length == 1) {

										objectFromArrayOfObjects = Object.assign({}, ...checkedLocationData);
									}
									else {
										objectFromArrayOfObjects = []
									}

									let data = {
										fromDayView: viewCalendarState,
										date: moment(calendarStartDate).format('dddd, Do MMMM YYYY'),
										locationData: objectFromArrayOfObjects
									}
									dispatch({
										type: SAVE_CALENDAR_DAILYVIEW_DATA,
										payload: data,
									});
									navigate(getNewAppointmentRoute()); dispatch({ type: CLEAR_APPOINTMENT_DETAILS_V3_DATA }); setIsOverlayOpen(false); setIsOpenFloating(false)
									// }
									// else {
									//     showToast("error", "Date not available for booking", "", false)
									// }
								}}
								background="white"
							/>
						</FloatingMenu>
					</div>
					}
				</div>
				{/* {filterCanvas && <div className="filter-wrap">
                    <div className="calender-filter">
                        <FilterCanvas
                            setFilterCanvas={setFilterCanvas}
                            isOnTimeChange={isOnTimeChange}
                            setIsOnTimeChange={setIsOnTimeChange}
                            viewCalendarState={viewCalendarState}
                            setViewCalendarState={setViewCalendarState}
                            setIsisShareBookingLinkOpen={setIsisShareBookingLinkOpen}
                            checkedLocationId={checkedLocationId}
                            setCheckedLocationId={setCheckedLocationId}
                            checkedBookingId={checkedBookingId}
                            setCheckedBookingId={setCheckedBookingId}
                            allLocationFlag={allLocationFlag}
                            setAllLocationFlag={setAllLocationFlag}
                            allBookingFlag={allBookingFlag}
                            setAllBookingFlag={setAllBookingFlag}
                            checkedLocationData={checkedLocationData}
                            setCheckedLocationData={setCheckedLocationData}
                            showSelectedDate={showSelectedDate}
                            setShowSelectedDate={setShowSelectedDate}
                            calendarStartDate={calendarStartDate}
                            setCalendarStartDate={setCalendarStartDate}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            selectedMonth={selectedMonth}
                            setSelectedMonth={setSelectedMonth}
                            selectedYear={selectedYear}
                            setSelectedYear={setSelectedYear}
                            selectedYearMonthlyView={selectedYearMonthlyView}
                            setSelectedYearMonthlyView={setSelectedYearMonthlyView}
                            selectedMonthMonthlyView={selectedMonthMonthlyView}
                            setSelectedMonthMonthlyView={setSelectedMonthMonthlyView}
                            // dateData={dateData}
                            dateData={
                                checkedLocationData?.length == 1 ? clinicsAvailableDateData?.data?.getClinicAvailableDates?.data?.items
                                    :
                                    generateCustomDates()
                            }
                            setDateData={setDateData}
                            appointmentCountData={getCalendarAppointmentCountQueryData?.data?.getCalendarAppointmentCount?.data?.appointmentCountData}
                            isDateAvailableForBooking={checkedLocationData?.length == 1 ? false : true}
                        />
                    </div>
                </div> */}
				{/* {filterCanvas &&  */}
				<div ref={scrollRef} className={filterCanvas ? "filter-wrap ActiveFilter" : "filter-wrap"}>
					<div className={scroll ? "active calender-filter" : "sticky calender-filter"}>
						{filterCanvas &&
							<div className="closeFilterIcon" onClick={() => {
								setFilterCanvas(false)
							}}><GoChevronRight /></div>
						}


						<FilterCanvas
							setFilterCanvas={setFilterCanvas}
							isOnTimeChange={isOnTimeChange}
							setIsOnTimeChange={setIsOnTimeChange}
							viewCalendarState={viewCalendarState}
							setViewCalendarState={setViewCalendarState}
							setIsisShareBookingLinkOpen={setIsisShareBookingLinkOpen}
							checkedLocationId={checkedLocationId}
							setCheckedLocationId={setCheckedLocationId}
							checkedBookingId={checkedBookingId}
							setCheckedBookingId={setCheckedBookingId}
							allLocationFlag={allLocationFlag}
							setAllLocationFlag={setAllLocationFlag}
							allBookingFlag={allBookingFlag}
							setAllBookingFlag={setAllBookingFlag}
							checkedLocationData={checkedLocationData}
							setCheckedLocationData={setCheckedLocationData}
							showSelectedDate={showSelectedDate}
							setShowSelectedDate={setShowSelectedDate}
							calendarStartDate={calendarStartDate}
							setCalendarStartDate={setCalendarStartDate}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
							selectedMonth={selectedMonth}
							setSelectedMonth={setSelectedMonth}
							selectedYear={selectedYear}
							setSelectedYear={setSelectedYear}
							selectedYearMonthlyView={selectedYearMonthlyView}
							setSelectedYearMonthlyView={setSelectedYearMonthlyView}
							selectedMonthMonthlyView={selectedMonthMonthlyView}
							setSelectedMonthMonthlyView={setSelectedMonthMonthlyView}
							dateData={dateData}
							setDateData={setDateData}
							listActive={listActive}
							setListActive={setListActive}
							filterCanvas={filterCanvas}
							isThreeDaysView={isThreeDaysView}
							setIsThreeDaysView={setIsThreeDaysView}
							setIsView={setIsView}
						/>
					</div>
				</div>
				{/* } */}
			</div>
			{filterCanvas && <div className="filterCanvasoverlay" onClick={() => { setFilterCanvas(false) }} />}
			{isOverlayOpen && <div className="overlay" onClick={() => setIsOverlayOpen(false)} />}

			{/* <OffCanvasModal
                isOpen={filterCanvas}
                onHide={setFilterCanvas}
                className="w-25 customeWidth calender-filter"
                placement={"end"}
                isOffCanvasHeader={false}
                body={
                    <FilterCanvas
                        setFilterCanvas={setFilterCanvas}
                        isOnTimeChange={isOnTimeChange}
                        setIsOnTimeChange={setIsOnTimeChange}
                        viewCalendarState={viewCalendarState}
                        setViewCalendarState={setViewCalendarState}
                        setIsisShareBookingLinkOpen={setIsisShareBookingLinkOpen}
                        checkedLocationId={checkedLocationId}
                        setCheckedLocationId={setCheckedLocationId}
                        checkedBookingId={checkedBookingId}
                        setCheckedBookingId={setCheckedBookingId}
                        allLocationFlag={allLocationFlag}
                        setAllLocationFlag={setAllLocationFlag}
                        allBookingFlag={allBookingFlag}
                        setAllBookingFlag={setAllBookingFlag}
                        checkedLocationData={checkedLocationData}
                        setCheckedLocationData={setCheckedLocationData}
                        // setSelectedMonthMonthlyViewCalendar={setSelectedMonthMonthlyView}
                        // setSelectedYearMonthlyViewCalendar={setSelectedYearMonthlyView}
                        showSelectedDate={showSelectedDate}
                        setShowSelectedDate={setShowSelectedDate}
                        calendarStartDate={calendarStartDate}
                        setCalendarStartDate={setCalendarStartDate}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedMonth={selectedMonth}
                        setSelectedMonth={setSelectedMonth}
                        selectedYear={selectedYear}
                        setSelectedYear={setSelectedYear}
                        selectedYearMonthlyView={selectedYearMonthlyView}
                        setSelectedYearMonthlyView={setSelectedYearMonthlyView}
                        selectedMonthMonthlyView={selectedMonthMonthlyView}
                        setSelectedMonthMonthlyView={setSelectedMonthMonthlyView}
                        dateData={dateData}
                        setDateData={setDateData}
                    />
                }
            /> */}
			{
				isOnTimeChange &&
				<OneTimeChange isOpen={isOnTimeChange} setIsOpen={setIsOnTimeChange} setFilterCanvas={setFilterCanvas} />
			}

			{/* Appointment details OffCanvasModal */}
			<OffCanvasModal
				isOpen={isAppointmentDetailsOpen}
				// isOpen={true}
				onHide={() => {
					setIsAppointmentDetailsOpen(false)
					dispatch({ type: CLEAR_APPOINTMENT_DOCUMENT_TAB_DATA })
					dispatch({ type: CLEAR_APPOINTMENT_MANAGE_TAB_DATA })

				}}
				className="custom-sidebar-width-30 customeModelAppointment customeModelAppointmentCanvas"
				placement={"end"}
				isOffCanvasHeader={false}
				grayHeader={true}
				body={
					<Appointment fromCalendar={true}
						setIsAppointmentDetailsOpen={setIsAppointmentDetailsOpen}
						slugFromCalender={slugFromCalender}
						setIsCancleAppointmentResponse={setIsCancleAppointmentResponse}
						setSlugFromCalender={setSlugFromCalender} />
				}
			/>

			{/* block-out time modal */}
			<OffCanvasModal
				isOpen={isBlockOutDetailsOpen}
				// isOpen={true}
				onHide={() => {
					setIsBlockOutDetailsOpen(false)
					dispatch({ type: CLEAR_APPOINTMENT_DOCUMENT_TAB_DATA })
					dispatch({ type: CLEAR_APPOINTMENT_MANAGE_TAB_DATA })
				}}
				className="width-30 customeWidth customeModelAppointment"
				placement={"end"}
				isOffCanvasHeader={false}
				grayHeader={true}
				body={
					<BlockOutTimeView fromCalendar={true}
						setIsAppointmentDetailsOpen={setIsBlockOutDetailsOpen}
						setIsCancleAppointmentResponse={setIsCancleAppointmentResponse}
						blockOutIdSlug={blockOutIdSlug}
						setBlockOutIdSlug={setBlockOutIdSlug}
						blockOutDateIdSlug={blockOutDateIdSlug}
						setBlockOutDateIdSlug={setBlockOutDateIdSlug}
						appointmentApiCall={initGetAppointmentListCall}
					/>
				}
			/>

			<OffCanvasModal
				isOpen={isAppointmentListOpen || isAppointmentListOpenFromInvoice}
				onHide={() => { setIsAppointmentListOpen(false); navigate(getCalendarRoute(), { state: undefined }) }}
				body={<AppointmentListDateWise clickedDate={clickedDate || clickedDateFromInvoice || fromBlockOutCancel} setIsAppointmentDetailsOpen={setIsAppointmentDetailsOpen}
					filterObj={{
						locations: allLocationFlag ? [] : checkedLocationId,
						status: allBookingFlag ? [] : checkedBookingId
					}} />}
				isCloseRight={true}
			/>
			{/* <OffCanvasModal
                isOpen={isShareBookingLinkOpen}
                onHide={() => setIsisShareBookingLinkOpen(false)}
                body={<ShareBookingLink isOpen={isShareBookingLinkOpen} />}
                isCloseRight={true}
            /> */}

			<CustomModal
				modalOpen={isOpenCalendar}
				// modalOpen={true}
				setModalOpen={setIsOpenCalendar}
				className='CustomeCalenderModel'
				modalBody={

					viewCalendarState == "dayGridMonth" ?
						<>
							<div className="calender-monthly-container" id={"time-picker"}>
								<div className="calender-monthly-data">

									<CustomDropdown
										labelName={"Select year"}
										defaultValue={selectedYearMonth}
										dropdownId={"year"}
										placeholdername={"Select Year"}
										optionsArr={yearsArray}
										className={"select-year"}
										handleSelectedValue={handleYearChange}
									/>
									<div className="selected-month">
										<MonthSelection selectedYear={selectedYearMonth} />
									</div>
									<div className="sticky-footer-calender">
										<StepButton label={"Save"} blue={true}


											onClick={() => {
												console.log("save clic", selectedYearMonth, selectedYearMonthData)
												setSelectedYearMonthlyView(selectedYearMonth)
												setSelectedMonthMonthlyView(selectedYearMonthData)
												setIsOpenCalendar(false)
											}
											}
										// disabled={start.length == ""} 
										/>
									</div>
								</div>
							</div>
						</> :

						<CustomCalendar
							setShowSelectedDate={setShowSelectedDate}
							setStartDate={setStartDate}
							setEndDate={setEndDate}
							selectedMonth={selectedMonth}
							selectedYear={selectedYear}
							setSelectedMonth={setSelectedMonth}
							setSelectedYear={setSelectedYear}
							showSelectedDate={showSelectedDate}
							calendarStartDate={calendarStartDate}
							setCalendarStartDate={setCalendarStartDate}
							setIsOpenCalendar={setIsOpenCalendar}
							setViewCalendarState={setViewCalendarState}
							viewCalendarState={viewCalendarState}
							dateData={
								dateData
							}
							ignoreSelectedDate={true}
							isPassedDateEnabled={true}
							appointmentDatesCount={
								getCalendarAppointmentCountQueryData?.data?.getCalendarAppointmentCount?.data?.appointmentCountData
							}
							isMonthlyDailyViewCalender={true}
							isStrikeAllow={false}
						/>

				}
			/>


			{/* appointment overview popup */}

			<CustomModal
				modalOpen={isAppointmentMenuOpen}
				// modalOpen={true}
				modaltitle={type == "Appointment" ? "Manage appointment" : "Blockout"}
				setModalOpen={setIsAppointmentMenuOpen}
				className="width-30 customeWidth customeModelAppointment menudata-container"
				modalBody={
					<>

						{/* <div className='mt-4' style={{ cursor: "pointer" }}
                        >
                            {
                                menuData.
                                    filter((items) => {
                                        if (isFilled) {
                                            // If isFilled is true, hide "Resend booking details"
                                            return items.title !== 'Resend booking & consent';
                                        }
                                        else {
                                            // If isFilled is false, hide "View consent"
                                            return items
                                        }
                                    }).
                                    map((items, index) => (
                                        <>
                                            <div className='d-flex justify-content-between flex-start mt-2' onClick={() => { handleMenuClick(items?.title) }}>
                                                <div className="d-flex gap-3">
                                                    <div>
                                                        {items.icon}
                                                    </div>
                                                    <div className='my-auto data'>
                                                        <h2>{items.title}</h2>
                                                    </div>
                                                </div>
                                                <div>
                                                    <img src={rightArrow} />
                                                </div>
                                            </div>
                                        </>
                                    ))
                            }
                        </div> */}
						<AppointmentViewPopup handleMenuClick={handleMenuClick} isFilled={isFilled} type={type} paymentTagData={paymentTagData} consentFormTagData={consentFormTagData} />

					</>

				}
			/>

			{/* payment option popup */}

			<CustomModal
				type={"common-width"}
				className={"paymentpopup"}
				modalOpen={isPaymentPopUpOpen}
				setModalOpen={setIsPaymentPopUpOpen}
				modalBody={
					<>
						<div className='paymentPopUpWrapper'>
							<h1>Would you like to send a payment link for the value of

								£{paymentPopUpPrice} to {paymentPopClientName}?</h1>
							<div>
								<div className='paymentPopBtn'>
									<StepButton blue={true} label={"Yes"} onClick={() => handlePaymentResend()} isLoading={resendBookingDetailsMutationData.loading} />
									<StepButton gray={true} label={"No"} onClick={() => setIsPaymentPopUpOpen(false)} />
								</div>
							</div>
						</div>
					</>
				}
			/>


			{/* consentForm menu option */}
			<CustomModal
				modalOpen={isFormsMenuOptionsOpen}
				// modalOpen={true}
				modaltitle={"Manage forms"}
				setModalOpen={setIsFormsMenuOptionsOpen}
				className="width-30 customeWidth customeModelAppointment menudata-container"
				modalBody={
					<>
						<ClientFormsViewPopup handleMenuClick={handleConsentFormClick} isFilled={isFilled} active={consentActive}  />
					</>
				}
			/>


			<OffCanvasModal
				isOffCanvasHeader={false}
				isOpen={isPrescriberModalOpen}
				placement={"end"}
				className={"maxCanvasWidth508 SelectPrescriberListModel"}
				onHide={() => setIsPrescriberModalOpen(false)}
				// onHide={() => { setIsClientPeofile(false); setIsClientAppointment(false); setActive("upcoming") }}
				body={<PrescriberList sendClickHandler={sendClickHandler} setIsPrescriberModalOpen={setIsPrescriberModalOpen} sendConsentFormOrMedicalFormToPrescriberQueryData={sendConsentFormOrMedicalFormToPrescriberQueryData} prescriberId={prescriberId} />}
			/>


			{/* prescription list component */}
			<OffCanvasModal
				isOffCanvasHeader={false}
				isOpen={isPrescriptionModalOpen}
				placement={"end"}
				className={"maxCanvasWidth508 SelectPrescriptionModel"}
				onHide={() => setIsPrescriptionModalOpen(false)}
				// onHide={() => { setIsClientPeofile(false); setIsClientAppointment(false); setActive("upcoming") }}
				body={<PrescriptionList sendtoPrescriberHandler={sendtoPrescriberHandler} setIsPrescriberModalOpen={setIsPrescriberModalOpen} updatePrescriptionAttachedFormQueryData={updatePrescriptionAttachedFormQueryData} prescriberId={prescriberId} clientId={clientId} setIsPrescriptionModalOpen={setIsPrescriptionModalOpen} />}
			/>



			{/* no pharmacy registration popup */}
			<CustomModal
				modalOpen={isNoPharmacyRegisterModalOpen}
				setModalOpen={setIsNoPharmacyRegisterModalOpen}
				type={"common-width"}
				className={'customeNoPharmacyRegisterModel'}
				modalBody={
					<>
						<div className="share-booking-link-all-clients-confimration">
							<p>
								Currently you are not register with any pharmacy. Would you like to connect?
							</p>
							<div className="btnWrapper">
								<StepButton label={"Yes"} onClick={() => {
									window.location.href = frontendURL + "/prescriber-registration";
									setIsNoPharmacyRegisterModalOpen(false)
								}} blue={true} />

								<StepButton gray={true} label={"No"} onClick={() => {
									setIsNoPharmacyRegisterModalOpen(false)
								}} />
							</div>
						</div>
					</>
				}
			/>










			{/* blockout appointment overview popup */}

			<CustomModal
				modalOpen={isBlockOutMenuOpen}
				// modalOpen={true}
				modaltitle={"Blockout"}
				setModalOpen={setIsBlockOutMenuOpen}
				className="width-30 customeWidth customeModelAppointment menudata-container"
				modalBody={
					<>

						<div className='mt-4' style={{ cursor: "pointer" }}
						>
							{
								blockoutmenuData.map((items, index) => (
									<>
										<div className='d-flex justify-content-between flex-start mt-2' onClick={() => { handleBlockOutMenuClick(items?.title) }}>
											<div className="d-flex gap-3">
												<div>
													{items.icon}
												</div>
												<div className='my-auto data'>
													<h2>{items.title}</h2>
												</div>
											</div>
											<div>
												<img src={rightArrow} />
											</div>
										</div>
									</>
								))
							}
						</div>
					</>

				}
			/>

			{/* delete blockout confirmation model */}
			<CustomModal
				type={"common-width"}
				className={"ConfirmationDeleteModal"}
				modalBody={
					<>
						<div className="delete-modal-body">
							<h4 className='text-start fw-bold'>Are you sure?</h4>
							<p className='ms-0 text-start'>Do you really want to cancel the blockout? Once done, you cannot undo it!</p>
							<div className="Delete-model-Btn">
								<div className="delete-button-modal-new">
									<StepButton red={true} label={"Delete"} isLoading={deleteBlockTimeMutationData?.loading} onClick={handleDeleteBlockOut} />
								</div>
								{/* <div className="cancle-button-modal">
                                        <StepButton gray={true} onClick={() => setIsDelete(false)} label={"Cancel"} />
                                    </div> */}
							</div>
						</div>
					</>
				}
				modalOpen={isDelete}
				setModalOpen={setIsDelete}
				setIsOpenMobileCalendar={setIsDelete}
				modalCloseCallback={() => {
					setIsDelete(false)
					setIsAppointmentMenuOpen(true)
				}}
				isManuallyClose={true}
			/>
			{
				showConsentPopup ? <ConsentActionPopup confirmationPopupShow={confirmationPopupShow} height={"280px"} setConfirmationPopupShow={setConfirmationPopupShow} setShowConsentPopup={setShowConsentPopup} setCompliancePopupShow={setCompliancePopupShow} compliancePopupShow={compliancePopupShow} elementId={appointmentId} fromAppointmentListNavigation={false} clickedDate={clickedDate} getAppointmentData={initGetAppointmentListCall} onCloseCallback={onCloseHandler} /> : null
			}

		</>
	);
};

export default MainCalendarView;