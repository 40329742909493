import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";


const CREATE_APPOINTMENT_NOTE_MUTATION = gql`
mutation CreateOrUpdateAppointmentNote($appointmentId: Int, $appointmentNote: String) {
  createOrUpdateAppointmentNote(appointmentId: $appointmentId, appointmentNote: $appointmentNote) {
    success
    message
    data {
      appointmentId
      appointmentNote
    }
  }
}
`;

export const CreateAppointmentNote = () => {
    const [createAppointmentNote, createAppointmentNoteResponseData] =
        useMutation(CREATE_APPOINTMENT_NOTE_MUTATION);
    const dispatch = useDispatch();
    const createAppointmentNoteMutationData = createAppointmentNoteResponseData;

    const initiateCreateAppointmentNote = (
        data,
        successCallback,
        errorCallback
    ) => {
        createAppointmentNote({
            variables: data,
        })
            .then(({ data: { createOrUpdateAppointmentNote: responseData } }) => {
                if (responseData) {
                    successCallback(responseData);
                } else {
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                errorCallback(error);
            });
    };
    return { initiateCreateAppointmentNote, createAppointmentNoteMutationData };
};