import { GET_SELECTED_PAYMENT_OPTION } from "../../constants/forward-consent-form/ForwardConsentFormConst";

const initialState = {
    selectedPaymentOption: null
};

const getSelectedPaymentOptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SELECTED_PAYMENT_OPTION:
            return {
                ...state,
                selectedPaymentOption: action.payload
            };
        default:
            return state;
    }
};

export default getSelectedPaymentOptionReducer