// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_ACCOUNTANCY_SERVICE_PACKAGES_QUERY = gql`
query GetAccountancyPackages($baseUrl: String) {
    getAccountancyPackages(baseUrl: $baseUrl) {
        success
        message
        data {
          packages {
            id
            package_name
            amount
            amountText
            duration_text
            package_points
            can_subscribe
            url
            is_popular
            is_currentplan
            calendly_url
            is_upgradable
            slug
          }
        }
    }
  }
`;

export const GetAccountancyPackagesAction = () => {
    // const dispatch = useDispatch();
    const [
        getAccountancyPackagesResponseFunction,
        getAccountancyPackagesResponseData,
    ] = useLazyQuery(GET_ACCOUNTANCY_SERVICE_PACKAGES_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getAccountancyPackages: responseData }) => {
            if (responseData.success) {
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getAccountancyPackagesQueryData = getAccountancyPackagesResponseData; //variable

    const initGetAccountancyPackagesData = (data) => {
        getAccountancyPackagesResponseFunction({
            variables: data,
        });
    };

    return { getAccountancyPackagesQueryData, initGetAccountancyPackagesData };
};
