import MenuIcon from '../../../images/icons/verticalDots.svg';
import MedicalIcon from '../../../images/medical-icon-list.svg';
import EmptyMedicalIcon from '../../../images/medical-not-found.png';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ReactComponent as EditIcon } from '../../../images/icons/edit-medical-form-icon.svg';
import { ReactComponent as AddSubQueIcon } from '../../../images/icons/add-sub-que-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../images/icons/delete-medical-icon.svg';
import { ReactComponent as RightArrow } from '../../../images/client/right_arrow.svg';
import SkeletonLoader from '../../common/Skeleton/SkeletonLoader';
import { useEffect, useState } from 'react';
import { RemoveMedicalFormQuestionMutation } from '../../redux/actions/formsTemplate/getFormTemplateList';
// import CustomModal from '../../modal/CustomModal';
// import StepButton from '../../common/StepButton';
import EmptyClient from '../../Client/EmptyClient';
import CustomeDeleteModel from '../../CustomeDeleteModel/CustomeDeleteModel';
// import useWindowDimension from '../../../utils/useWindowDimensions';

function MedicalFormList({
    questionList,
    isLoading,
    onEditTrigger,
    postDeleteAction,
    onAddSubQuestion,
    isSubQuestionList,
    search,
    isEditing,
    editQuestionData,
    editComponent
}) {
    const { initRemoveMedicalFormQuestion, removeMedicalFormQuestionStatus } =
        RemoveMedicalFormQuestionMutation();

    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const menuData = [
        {
            id: 'm1',
            title: `Edit ${isSubQuestionList ? 'sub' : ''} question`,
            icon: <EditIcon />,
            action: () => onEditTrigger(selectedQuestion),
        },
        {
            id: 'm2',
            title: 'View sub question',
            icon: <AddSubQueIcon />,
            action: () => onAddSubQuestion(selectedQuestion),
        },
        {
            id: 'm3',
            title: 'Delete',
            icon: <DeleteIcon />,
            action: () => {
                document.body.click();
                setIsDeleteOpen(true);
            },
        },
    ];

    const deleteQuestionHandler = () => {
        initRemoveMedicalFormQuestion(
            {
                pmqId: selectedQuestion.id,
            },
            () => {
                setIsDeleteOpen(false);
                postDeleteAction && postDeleteAction();
            }
        );
    };

    const popoverClick = (
        <Popover
            id="popover-trigger-click"
            className="appointment-option-main Custome-Popover-Design-Set p-3"
            // title="Popover bottom"
        >
            {menuData.map((item) => {
                if (isSubQuestionList && item.id === 'm2') return null;

                return (
                    <div
                        className="appointment-list-option-container"
                        key={item.id}
                        onClick={item.action}
                    >
                        <div className="appointment-list-left-popover-content">
                            {item.icon}
                            <p>{item.title}</p>
                        </div>
                        <div className="appointment-list-right-popover-content">
                            <RightArrow />
                        </div>
                    </div>
                );
            })}
        </Popover>
    );

    const filteredQuestionList = questionList?.filter((item) =>
        item.question.toLowerCase().includes(search.trim().toLowerCase())
    );

//   const dimensions = useWindowDimension();
//   const [headerIndicatorHeight, setHeaderIndicatorHeight] = useState(0);
//   const [headerHeight, setHeaderHeight] = useState(0);
//   const [secTitleHeight, setSecTitleHeight] = useState(0);
//   const [btnWrapperHeight, setBtnWrapperHeight] = useState(0);

//   console.log(headerIndicatorHeight, 'headerIndicatorHeight', headerHeight, 'headerHeight', secTitleHeight, 'secTitleHeight', btnWrapperHeight, 'btnWrapperHeight')

//   const updateHeights = () => {
//     setHeaderIndicatorHeight(
//       document.getElementsByClassName("form-mode-indicator")[0]?.offsetHeight || 0
//     );
//     setHeaderHeight(
//       document.getElementsByClassName("nav-header-without-padding-container")[0]?.offsetHeight || 0
//     );
//     setSecTitleHeight(
//       document.getElementsByClassName("SectionHeadingWrapper")[0]?.offsetHeight || 0
//     );
//     setBtnWrapperHeight(
//       document.getElementsByClassName("BtnWrapper")[0]?.offsetHeight || 0
//     );
//   };

//   useEffect(() => {
//     updateHeights();

//     window.addEventListener('resize', updateHeights);
//     return () => {
//       window.removeEventListener('resize', updateHeights);
//     };
//   }, []);

//   const scrollableDivHeight = dimensions?.height - headerIndicatorHeight - headerHeight - secTitleHeight - btnWrapperHeight + 'px';

//   console.log(scrollableDivHeight, 'scrollableDivHeight');
  

    return (
        // <div className="client-tile-container" style={{height:scrollableDivHeight, overflowY: 'auto'}}>
        <div className="client-tile-container">
            {isLoading && <SkeletonLoader type="form-list" />}
            {!isLoading && questionList && questionList.length === 0 && !isEditing && (
                // <p>No Questions found!</p>
                <EmptyClient
                    type={'CreateMyForm'}
                    title={`No ${isSubQuestionList ? 'sub' : ''} question found`}
                    image={EmptyMedicalIcon}
                    // name={search}
                    // subTitle='is not in your list.'
                />
            )}
            {!isLoading &&
                questionList &&
                questionList.length > 0 &&
                filteredQuestionList.length === 0 &&
                !isEditing && (
                    // <p>No search result found!</p>
                    <div className="noData">
                        <EmptyClient
                            type={'CreateMyForm'}
                            title={`No ${isSubQuestionList ? 'sub' : ''} question found`}
                            image={EmptyMedicalIcon}
                            name={search}
                            subTitle="is not in your list."
                        />
                    </div>
                )}
            {questionList &&
                questionList.length > 0 &&
                filteredQuestionList.map((item) => (
                    <>
                        {isEditing && editQuestionData.id === item.id ? (
                            <>{editComponent}</>
                        ) : (
                            <div key={item.id} className="FormsListItem">
                                <div className="FormsListItemContent">
                                    <div className="IconWrapper">
                                        <img src={MedicalIcon} alt='medical icon' />
                                    </div>
                                    <div className="ItemContent">
                                        <h1 style={{ textAlign: 'left' }}>{item.question}</h1>
                                    </div>
                                </div>
                                <OverlayTrigger
                                    rootClose
                                    trigger="click"
                                    placement="auto"
                                    overlay={popoverClick}
                                >
                                    <div
                                        onClick={() => setSelectedQuestion(item)}
                                        className="FormsListItem__menu"
                                    >
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            src={MenuIcon}
                                            alt="menu"
                                        />
                                    </div>
                                </OverlayTrigger>
                            </div>
                        )}
                    </>
                ))}
            <CustomeDeleteModel
                modalOpen={isDeleteOpen}
                setModalOpen={setIsDeleteOpen}
                setIsOpenMobileCalendar={setIsDeleteOpen}
                isDeleteLoading={removeMedicalFormQuestionStatus.loading}
                isDeleteDisabled={removeMedicalFormQuestionStatus.loading}
                DeleteModelTitle={'Are you sure?'}
                DeleteModelDesc={`Are you sure you want to delete this question?`}
                onDeleteClick={deleteQuestionHandler}
                onCancleClick={() => {
                    setIsDeleteOpen(false);
                }}
            />
        </div>
    );
}

export default MedicalFormList;
