import React, { useEffect, useState } from 'react'
import "./hours-and-break-added.scss"
import StepButton from '../../common/StepButton'
import moment from 'moment'
import SwitchButton from '../../common/switchButton/SwitchButton'
import CustomDropdown from '../../common/dropdown/CustomDropdown'
import { ReactComponent as DeleteActionIcon } from "../../../../src/images/DeleteActionIcon.svg"
import { GetOneTimeOffAction, SaveOneOffTimeAction } from '../../redux/actions/onetimeoff/OneOffTime'
import { useNavigate } from 'react-router'
import { getCalendarRoute } from '../../../Routes'
import CustomModal from '../../modal/CustomModal'
import backArrow from "../../../images/icons/BackArrow.svg"
import InfoIcon from "../../../images/icons/InfoIcon.svg"

const HoursAndBreakAdded = ({
    calenderDisplayDate,
    hoursData,
    setHoursData,
    breaks,
    setBreaks,
    clinicId,
    setStep,
    setIsOpen,
    isFromListView = false,
    setIsLocationHoursUpdateOpen,
    afterSaveCallback,
    clinicName,
    clinicColor
}) => {

    const [validationError, setValidationError] = useState({
        opens: "",
        closes: ""
    })
    const navigate = useNavigate();

    const { initSaveOneOffTimeData, saveOneOffTimeQueryData } = SaveOneOffTimeAction();
    const { initGetOneTimeOffData, getOneTimeOffQueryData } = GetOneTimeOffAction();
    const [isDelete, setIsDelete] = React.useState(false);
    const [index, setIndex] = React.useState(false);

    const handleToggleChange = () => {
        setHoursData((prevData) => ({
            ...prevData,
            ["isOpenForBusiness"]: !prevData["isOpenForBusiness"],
        }))
    }
    const TIME = () => {
        const hours = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                hours.push(
                    moment({
                        hour,
                        minute
                    }).format('HH:mm')
                );

            }
        }
        return hours;
    };


    const handleTimeSlotChange = (selectedValue, id) => {
        var hoursOpens;
        var hoursCloses;

        if (id === "opens") {
            hoursOpens = selectedValue
            hoursCloses = hoursData?.closes
        }
        if (id === "closes") {
            hoursCloses = selectedValue
            hoursOpens = hoursData?.opens
        }

        setHoursData((prevData) => ({
            ...prevData,
            [id]: selectedValue,
        }))

        var startTime = moment(hoursOpens, 'HH:mm');
        var endTime = moment(hoursCloses, 'HH:mm');
        if (startTime.isSame(endTime)) {
            setValidationError((prev) => ({
                ...prev,
                opens: "Start time and end time can't be same",
            }));
        }
        else if (!startTime.isBefore(endTime)) {
            setValidationError((prev) => ({
                ...prev,
                opens: "Start time can't be greater than end time",
            }));
        }
        else {
            setValidationError((prev) => ({
                ...prev,
                opens: "",
            }));
        }
    }

    const colourStyles = {

        menuPortal: base => ({
            ...base,
            zIndex: 9999 // Adjust the z-index value according to your requirement
        }),
        control: (styles, state) => ({
            ...styles, backgroundColor: '#FFFFFF', border: state.isFocused ? 0 : 0,
            outline: "none",
            appearance: "none",
            padding: "10px 10px",
            borderRadius: "10px",
            border: "1px solid #878787",
            height: "55px"
        }),
        option: (styles, { isDisabled, isFocused, isSelected }) => {

            return {
                ...styles,
                border: isFocused ? 'none' : 'none'
            };
        },
    };

    const handleBreakChange = (index, key, value) => {
        const updatedBreaks = [...breaks];
        updatedBreaks[index][key] = value;
        if (key == "start" && parseInt(calculateTotalHours(value, updatedBreaks[index]["end"])) <= 0) {
            const parsedTime = moment(value, 'HH:mm');
            const newTime = parsedTime.add(30, 'minutes');
            console.log((moment(value, 'HH:mm').add(15, 'minutes')), "hii there",)
            if ((((moment(value, 'HH:mm').add(15, 'minutes'))).isSameOrAfter(moment(hoursData?.closes, "HH:mm"))))
                updatedBreaks[index]["end"] = hoursData?.closes
            else
                updatedBreaks[index]["end"] = (newTime.format('HH:mm'))
        }
        updatedBreaks[index].breakError = validateBreakFields(updatedBreaks[index].start, updatedBreaks[index].end);
        setBreaks(updatedBreaks);
    };

    const addBreakField = () => {
        setBreaks(prevBreaks => [...prevBreaks, { start: hoursData?.opens, end: moment(hoursData?.opens, 'HH:mm').add(30, 'minutes').format('HH:mm'), breakError: "" }]);
    };

    const deleteBreakField = (index) => {
        const updatedBreaks = breaks.filter((breakTime, i) => i !== index);
        setBreaks(updatedBreaks);
    };

    const renderBreakFields = () => {
        return breaks.map((breakTime, index) => (
            <div className='breakTimeGridError'>
                <div className='breakTimeGrid' key={index}>
                    <div className='breakTimeItem'>
                        <CustomDropdown
                            labelName={"Break start"}
                            dropdownId={`BreakStart-${index}`}
                            placeholdername={"Break start"}
                            optionsArr={TIME()}
                            defaultValue={breakTime.start}
                            handleSelectedValue={value => handleBreakChange(index, 'start', value)}
                            style={colourStyles}
                        />
                    </div>
                    <div className='breakTimeItem'>
                        <CustomDropdown
                            labelName={"Break ends"}
                            dropdownId={`BreakEnd-${index}`}
                            placeholdername={"Break ends"}
                            optionsArr={TIME()}
                            defaultValue={breakTime.end}
                            handleSelectedValue={value => handleBreakChange(index, 'end', value)}
                            style={colourStyles}
                        />
                    </div>
                    <div className='breakTimeItem'>
                        <DeleteActionIcon className='cursor-pointer' onClick={() => { setIsDelete(true); setIndex(index) }} />
                    </div>
                </div>
                {breakTime.breakError && <span className="error">{breakTime.breakError}</span>}
            </div>
        ));
    };

    const resetDataHandler = () => {
        setHoursData({
            isOpenForBusiness: true,
            opens: "09:00",
            closes: "18:00"
        });
        setBreaks([{ start: hoursData?.opens, end: moment(hoursData?.opens, 'HH:mm').add(30, 'minutes').format('HH:mm'), breakError: "" }]);
    }

    const calculateTotalHours = (start, end) => {
        const currentDate = moment().format('YYYY-MM-DD');
        console.log("start", start)
        console.log("end", end)

        const startTime = moment(`${currentDate} ${start}`);
        const endTime = moment(`${currentDate} ${end}`);

        // Calculate the difference in minutes
        return endTime.diff(startTime, 'minutes');


    }

    const validateBreakFields = (start, end) => {
        var breakStartTime = moment(start, 'HH:mm');
        var breakendTime = moment(end, 'HH:mm');

        if (breakStartTime.isBefore(moment(hoursData?.opens, 'HH:mm')) || breakStartTime.isAfter(moment(hoursData?.closes, 'HH:mm')) || breakendTime.isAfter(moment(hoursData?.closes, 'HH:mm'))) {
            return "Break time should between open and close time"
        }
        else if (breakStartTime.isSame(breakendTime)) {
            return "Break start time and end time can't be same"
        }
        else if (!breakStartTime.isBefore(breakendTime)) {
            return "Start time can't be greater than end time"
        }
        else {
            return ""
        }
    }

    const oneOffTimeHandler = () => {
        const validationErrors = {};
        if (hoursData?.opens && hoursData?.closes) {
            var startTime = moment(hoursData?.opens, 'HH:mm');
            var endTime = moment(hoursData?.closes, 'HH:mm');
            if (startTime.isSame(endTime)) {
                validationErrors.opens = "Start time and end time can't be same"
            }
            else if (!startTime.isBefore(endTime)) {
                validationErrors.opens = "Start time can't be greater than end time"
            }
            else {
                validationErrors.opens = ""
            }
            setValidationError(validationErrors);
        }
        if (Object.values(validationErrors).some((error) => error)) {
            return
        }
        if (breaks && breaks?.length > 0) {
            let error = false;
            const validateBreak = breaks.map((item, index) => {
                // Add your validation logic here
                var breakStartTime = moment(item.start, 'HH:mm');
                var breakendTime = moment(item.end, 'HH:mm');

                if (breakStartTime.isBefore(moment(hoursData?.opens, 'HH:mm')) || breakStartTime.isAfter(moment(hoursData?.closes, 'HH:mm')) || breakendTime.isAfter(moment(hoursData?.closes, 'HH:mm'))) {
                    error = true;
                    return { ...item, breakError: "Break time should between open and close time" }
                }
                else if (breakStartTime.isSame(breakendTime)) {
                    error = true;
                    return { ...item, breakError: "Break start time and end time can't be same" }
                }
                else if (!breakStartTime.isBefore(breakendTime)) {
                    error = true;
                    return { ...item, breakError: "Start time can't be greater than end time" }
                }
                else {
                    return { ...item, breakError: "" }
                }

            })
            setBreaks(validateBreak)
            if (error) {
                return
            }
        }


        let breakArray = breaks.map((item) => {
            return { breakStartTime: item.start, breakEndTime: item?.end, breakId: item?.breakId ? item?.breakId : null }
        })
        initSaveOneOffTimeData({
            clinicId: clinicId,
            isOpen: hoursData?.isOpenForBusiness,
            date: calenderDisplayDate,
            startTime: hoursData?.opens,
            breaks: breakArray,
            endTime: hoursData?.closes
        }, (res) => {
            if (!isFromListView) {
                resetDataHandler();
                setStep(1)
                initGetOneTimeOffData({
                    clinicId: clinicId,
                    date: calenderDisplayDate
                }, (res) => {
                    if (res.success) {
                        setHoursData({
                            isOpenForBusiness: res?.data?.isOpen,
                            opens: moment(res?.data?.startTime, 'HH:mm:ss').format('HH:mm'),
                            closes: moment(res?.data?.endTime, 'HH:mm:ss').format('HH:mm')
                        })
                        if (res?.data?.breaks?.length > 0) {
                            let breakArray = res?.data?.breaks?.map((item) => {
                                return { start: moment(item.breakStartTime, 'HH:mm:ss').format('HH:mm'), end: moment(item?.breakEndTime, 'HH:mm:ss').format('HH:mm'), breakId: item.breakId, breakError: "" }
                            })
                            setBreaks(breakArray)
                        }
                        else {
                            setBreaks([]);
                        }
                    }

                }, (err) => {

                })
            }
            else {
                const data = {
                    startTime: hoursData?.opens,
                    endTime: hoursData?.closes,
                    availableDate: calenderDisplayDate,
                    clinicId: clinicId,
                    isAvailable: hoursData?.isOpenForBusiness,
                    clinicName: clinicName,
                    clinicColor: clinicColor
                }
                afterSaveCallback(data);
            }

        }, (err) => {

        })

    }
    return (
        <div className="one-time-change-hours-break-container">
            {isFromListView && <img src={backArrow} style={{ cursor: 'pointer' }} alt="" onClick={() => {
                if (setIsLocationHoursUpdateOpen) {
                    setIsLocationHoursUpdateOpen(false)
                }
            }} />}
            <div className='header-title-hours-break'>
                <h1 className='pt-4'>{isFromListView ? "Update hours" : moment(calenderDisplayDate).format('ddd D MMM')}</h1>
                <p>{isFromListView ? `Update working hours for ${clinicName} on ${moment(calenderDisplayDate, "YYYY-MM-DD").format('ddd DD MMM,YYYY')}` : "Make a one-off adjustment to your day's schedule."}</p>
                <div className='open-for-business-toggle'>
                    <p>{hoursData?.isOpenForBusiness ? "Open for business" : "Closed"}</p>
                    <SwitchButton
                        onChange={(e) =>
                            handleToggleChange()
                        }
                        isChecked={hoursData.isOpenForBusiness}
                        className='business-toggle-switch'
                    />
                </div>
                {hoursData?.isOpenForBusiness && <div className='hours-and-break-container'>
                    <div className='mt-2'>
                        <CustomDropdown
                            labelName={"Opens"}
                            dropdownId={"opens"}
                            placeholdername={"Opens"}
                            optionsArr={TIME()}
                            defaultValue={hoursData?.opens}
                            handleSelectedValue={handleTimeSlotChange}
                            style={colourStyles}
                        />
                    </div>
                    {validationError.opens && <span className="error">{validationError.opens}</span>}
                    <div className='BreakFieldsWrapper'>
                        {renderBreakFields()}
                    </div>
                    {isFromListView ?
                        <div onClick={addBreakField} className='add-break-wrapper'>
                            <p>Add break</p>
                        </div> :
                        <p onClick={addBreakField}>Add break</p>
                    }
                    <div className='mt-3'>
                        <CustomDropdown
                            labelName={"Closes"}
                            dropdownId={"closes"}
                            placeholdername={"Closes"}
                            optionsArr={TIME()}
                            defaultValue={hoursData?.closes}
                            handleSelectedValue={handleTimeSlotChange}
                            style={colourStyles}
                        />
                    </div>
                    {validationError.closes && <span className="error">{validationError.closes}</span>}
                </div>
                }
                {isFromListView && <div className='update-avaibility-info-popup'>
                    <div className='info-icon-wrapper'>
                        <img src={InfoIcon} alt="infoicon" />
                    </div>
                    <p>To prevent angry customers, make sure you have rearranged all necessary appointments.</p>
                </div>
                }
                <div className='btnWrapper'>
                    <StepButton blue={true} label={`Save`} isLoading={saveOneOffTimeQueryData?.loading} className={"w-50"} onClick={oneOffTimeHandler} disabled={hoursData.isOpenForBusiness ? hoursData.opens && hoursData.closes ? false : true : false} />
                </div>
            </div>
            <CustomModal
                type={"common-width"}
                className={"ConfirmationDeleteModal"}
                modalBody={
                    <>
                        <div className="delete-modal-body">
                            <h1>Are you sure?</h1>
                            <p>you want to delete this break? This action cannot be reversed.</p>
                            <div className="BtnWrapper">
                                <div className="delete-button-modal">
                                    <StepButton red={true} label={"Delete"} onClick={() => { deleteBreakField(index); setIsDelete(false) }} />
                                </div>
                                <div className="cancle-button-modal">
                                    <StepButton gray={true} onClick={() => setIsDelete(false)} label={"Cancel"} />
                                </div>
                            </div>
                        </div>
                    </>
                }
                modalOpen={isDelete}
                setModalOpen={setIsDelete}
                setIsOpenMobileCalendar={setIsDelete}
            />
        </div>
    )
}

export default HoursAndBreakAdded