import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const LOG_IN_FUNCTIONALITY_FOR_FINANCE_USER = gql`
mutation UserFinanceLogin($email: String!, $password: String!, $appliedForFinance: Boolean) {
    userFinanceLogin(email: $email, password: $password, appliedForFinance: $appliedForFinance) {
      success
      message
      data {
        user {
          usr_id
          usr_fname
          usr_lname
          usr_name
          usr_email
          usr_email_verified
          usr_country_name
          usr_country_code
          usr_phone_no
          usr_phone_country
          usr_phone_verified
          usr_dob
          usr_last_login
          usr_last_login_type
          usr_country
          usr_company_name
          usr_no_of_employees
          usr_vat_no
          userRoles
          image_url
        }
        token
        user_inactive
        is_user_exist
        user_email_unverified
        user_finance_requested
      }
    }
  }
`;

export const LoginForFinanceUser = () => {
    // const dispatch = useDispatch()
    const [loginForFinanceUserFunc, loginForFinanceUser] = useMutation(LOG_IN_FUNCTIONALITY_FOR_FINANCE_USER);
    const loginForFinanceUserData = loginForFinanceUser;

    const initiateloginForFinanceUser = (
        data,
        successCallback,
        errorCallback
    ) => {
        loginForFinanceUserFunc({
            variables: data,
        })
            .then(({ data: { userFinanceLogin: responseData } }) => {
                if (responseData?.success) {
                    toast.success(responseData.message)
                    successCallback(responseData);
                } else {
                    toast.error(responseData.message)
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                errorCallback(error);
            });
    };

    return { initiateloginForFinanceUser, loginForFinanceUserData }
}