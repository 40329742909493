import React from 'react';
import './FinanceBanner.scss';
import { LinearProgress } from '@mui/material';
// import verifiedStart2 from '../../../../images/verifiedStart.svg';
import verifiedStart from '../../../../images/finance/material-symbols_verified.svg';

const FinanceBanner = ({ title, desc, isBorder = false, isAdd = false, icon: Icon, uppercontent, lowercontent, currentStep, totalSteps, facesVarified }) => {
  const progress = (currentStep / totalSteps) * 100;
  const remain = 100 - progress;
  console.log('progress', progress);

  return (
    <>
      <div className="finance-banner-container">
        <div className="finance-content-container para-margin-20">
          <h1>{title}</h1>
          <p>
            {desc}
            {facesVarified && (
              <span className=" FacesVarified">
                
                <span className="facesTag">
                  <img src={verifiedStart} alt="verifiedStart" />
                </span>
                {/* FACES VERIFIED */}
                VERIFIED BUSINESS
              </span>
            )}
          </p>
          {isBorder && (
            <div className="border-container">
              {/* <LinearProgress variant="determinate" value={progress} /> */}
              <div
                style={{ width: `${progress}%` }}
                className={`blue-border  ${
                  !(progress == 100) ? 'fill-border' : '.normal-border'
                } `}
              />
              <div
                style={{ width: `${remain}%` }}
                className={`gray-border  ${
                  !(remain == 100) ? 'remain-border' : 'normal-border'
                } `}
              />
            </div>
          )}
        </div>
      </div>
      {isAdd && (
        <div className="join-price">
          <div className="join-price-div">
            <div className="left-content">
              <Icon />
            </div>
            <div className="right-content">
              <div className="upper-content">{uppercontent}</div>
              <div className="lower-content">{lowercontent}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FinanceBanner;
