import React, { useEffect, useState } from 'react';
import './create-after-care-form.scss';
import NavHeader from '../../common/NavHeader/NavHeader';
import StepButton from '../../common/StepButton';
import { useNavigate, useParams } from 'react-router';
import { AfterCareFormDetails, CreateAndEditAfterCareForm } from '../../redux/actions/walk-in-form/walkInAddFormsList';
import { useSelector } from 'react-redux';
import CustomModal from '../../modal/CustomModal';
import { after } from 'lodash';
import { af } from 'date-fns/locale';

const EditUserAfterCareFormTemplate = () => {
    const navigate = useNavigate();
    // const { formName } = useParams();
    const { aftercareDocId } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFile, setSelectedFile] = useState(null);
    const [afterCareFormName, setAfterCareFormName] = useState("");
    const [editAfterCareFormModal, setEditAfterCareFormModal] = useState(true);

    const [isEditingFile, setIsEditingFile] = useState(false);


    const afterCareFomrsListDetailsData = useSelector(state => state?.walkInClientListReducer?.afterCareFormDetails);
    console.log("afterCareFomrsListDetailsData", afterCareFomrsListDetailsData);

    const { createOrUpdateAfterCareFormQueryData, initCreateOrUpdateAfterCareFormData } = CreateAndEditAfterCareForm();
    const { initAfterCareFormDetails, aftercareFormDetailsQueryData } = AfterCareFormDetails();

    useEffect(() => {
        initAfterCareFormDetails({
            aftercareId: parseInt(aftercareDocId),
        });
    }, [aftercareDocId]);

    useEffect(() => {
        setSelectedFile(afterCareFomrsListDetailsData?.aftcr_dc_file_url);
    }, [afterCareFomrsListDetailsData?.aftcr_dc_file_url])

    const handleChange = (event) => {
        if (event.target.value.length > 0) {
            setAfterCareFormName(event.target.value);
        }
        afterCareFormName === "" && setAfterCareFormName(event.target.value);
    };

    console.log(afterCareFormName, "afterCareFormName");

    const handleSubmitAfterCareForm = () => {
        initCreateOrUpdateAfterCareFormData({
            createAftercareFormId: parseInt(aftercareDocId),
            aftercareFormName: afterCareFormName,
            file: selectedFile,
        });
        navigate('/manage-forms');
    };


    const handleEditFile = () => {
        setIsEditingFile(true);
    };

    const handleCancelEditFile = () => {
        setIsEditingFile(false);
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };


    console.log(selectedFile, "selectedFile");
    const handleRemoveFile = () => {
        setSelectedFile(null);
    };

    const handleNextPage = () => {
        setCurrentPage(2);
        setEditAfterCareFormModal(false);
    }

    const isImage = (file) => {
        return file.type == 'image/';
    };

    const isPDF = (file) => {
        return file.type === 'application/pdf';
    };

    return (
        <>

            {/* CREATE AFTER CARE FORM */}
            {currentPage === 1 && (
                <CustomModal
                    modalOpen={editAfterCareFormModal}
                    setModalOpen={setEditAfterCareFormModal}
                    modaltitle={"Name your form"}
                    type={"common-width"}
                    className={'customeNameFormModel'}
                    isCloseImage={false}
                    modalBody={
                        <>
                            <div className="create-form-div">
                                <div className="input-form-div">
                                    <input type="text" name='form-name' value={afterCareFormName || afterCareFomrsListDetailsData?.aftcr_dc_name} onChange={handleChange} placeholder='Name form' />
                                    <StepButton blue={true} label={"Update"} onClick={handleNextPage} />
                                </div>
                            </div>
                        </>
                    }
                />
            )}
            {currentPage === 2 && (
                <div className='create-new-form-container'>
                    <div className="form-section">
                        <div className="form-section-title">
                            <h3>{afterCareFormName || afterCareFomrsListDetailsData?.aftcr_dc_name}</h3>
                        </div>

                        <div className='notify-container'>
                            <p><b>Quick Tip:</b> Keep your questions clear and concise, ideally requiring simple 'Yes' or 'No' responses.</p>
                        </div>

                        <div className="form-file-upload-section">

                            <div className='after_care_file_upload'>
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <img src={afterCareFomrsListDetailsData?.aftcr_dc_file_url} width={500} alt="Image Preview" />
                                    <i className="fa fa-cloud-upload" style={{ fontSize: '40px' }}></i>
                                    <p>Edit</p>
                                    {/* {afterCareFomrsListDetailsData?.aftcr_dc_file_url ? <p>Edit</p> : <p>Upload File</p>} */}
                                </label>
                                <input id="file-upload" type="file" accept=".pdf, image/*" onChange={handleFileChange} />
                            </div>

                            {/* {selectedFile && (isEditingFile || isImage(selectedFile) || isPDF(selectedFile)) ? (
                                <div className='file-preview'>
                                    {isImage(selectedFile) && <img src={URL.createObjectURL(selectedFile)} width={500} alt="File Preview" />}

                                    {isPDF(selectedFile) && (
                                        <>
                                            <a href={selectedFile.name} target="_blank" rel="noopener noreferrer" className='m-3'>
                                                <i className='fa fa-file-pdf-o' style={{ fontSize: '50px' }}></i>
                                            </a>
                                            <button onClick={handleEditFile}>Edit</button>
                                        </>
                                    )}
                                    <button onClick={handleRemoveFile}>Remove</button>
                                    {isEditingFile && <button onClick={handleCancelEditFile}>Cancel Edit</button>}
                                </div>
                            ) : (
                                <div className='after_care_file_upload'>
                                    <label htmlFor="file-upload" className="custom-file-upload">
                                        <i className="fa fa-cloud-upload" style={{ fontSize: '40px' }}></i>
                                        {afterCareFomrsListDetailsData?.aftcr_dc_file_url ? <p>Edit</p> : <p>Upload File</p>}
                                    </label>
                                    <input id="file-upload" type="file" accept=".pdf, image/*" onChange={handleFileChange} />
                                </div>
                            )} */}
                        </div>

                        <StepButton blue={true} type="button" onClick={handleSubmitAfterCareForm} label={"Save"} />
                    </div>
                </div>
            )}
        </>
    );
}

export default EditUserAfterCareFormTemplate;
