import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const PRODUCT_TRACKING_THANKYOU_PAGE_MUTATION = gql`
mutation ProductTrackingForThnakyouPage($consentFormId: Int, $productId: Int) {
  productTrackingForThnakyouPage(consentFormId: $consentFormId, product_id: $productId) {
    success
    message
    data {
      tppt_cfr_id
      tppt_usr_id
      tppt_product_id
      created_at
    }
  }
}
`;

export const ProductTrackingThankyouPageMutation = () => {
  const [productTrackingThankyouPageRequest, productTrackingThankyouPageResponseData] =
    useMutation(PRODUCT_TRACKING_THANKYOU_PAGE_MUTATION);
  const dispatch = useDispatch();
  const productTrackingThankyouPageMutationData = productTrackingThankyouPageResponseData;

  const initiateProductTrackingThankyouPage = (
    data,
    successCallback,
    errorCallback
  ) => {
    productTrackingThankyouPageRequest({
      variables: data,
    })
      .then(({ data: { productTrackingForThnakyouPage: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateProductTrackingThankyouPage, productTrackingThankyouPageMutationData };
};
