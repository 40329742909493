import React, { useState } from "react";
import SearchIcon from "../../../../images/search_icon.svg";
import { ReactComponent as CloseIcon } from "../../../../images/close_icon.svg";
import { ReactComponent as RightIcon } from "../../../../images/calander_next.svg";
import StepButton from "../../../common/StepButton";
import BusinessNavigation from "../../../common/businessNavigation/BusinessNavigation";

import "./Clients.scss";
import EmptyClient from "./noClient/EmptyClient";

const Clients = ({ setCurrentPage, currentPage }) => {
  const [searchText, setSearchText] = useState("");
  const [isnoClient, setIsnoClient] = useState(false);
  const handleSearchText = (e) => {
    const newText = e.target.value;
    setSearchText(newText);
  };

  const [step, setStep] = useState(1);

  const renderTabContent = () => {
    if (step == 1) {
      return (
        <EmptyClient
          onClickAddClient={() => {
            setStep(0);
          }}
        />
      );
    }
  };

  const clientData = [
    {
      id: "01",
      clientname: "Full name",
      clientrole: "Admin (Owner)",
    },
    {
      id: "02",
      clientname: "Full name",
      clientrole: "Admin",
    },
    {
      id: "03",
      clientname: "Full name",
      clientrole: "Viewer",
    },
    {
      id: "04",
      clientname: "Full name",
      clientrole: "Viewer",
    },
    {
      id: "05",
      clientname: "Full name",
      clientrole: "Viewer",
    },
  ];
  return (
    <div className="MainClientpage">
      {step > 0 ? (
        renderTabContent()
      ) : (
        <div className="Main684WidthSet">
          <BusinessNavigation
            isColumn={true}
            title={"Clients"}
            desc={"Client list"}
            onBackClick={() => setCurrentPage("Home")}
          />
          <div className="searchWrapper">
            <div className="custom-search-container">
              <input
                type="text"
                placeholder="Search by name or email address"
                value={searchText}
                onChange={(e) => handleSearchText(e)}
              />
              <img
                src={SearchIcon}
                alt="search-icon"
                className="search-icon-img"
              />
              {searchText && (
                <CloseIcon
                  className="cursor-pointer search-close-icon"
                  onClick={() => {
                    setSearchText("");
                  }}
                />
              )}
            </div>
            <div className="searchButtonWrapper">
              <button className="searchButton">Add new client</button>
            </div>
          </div>

          <div className="ClientList">
            {clientData?.map((item, index) => {
              return (
                <>
                  <div className="ClientItem" key={index}>
                    <div className="ClientLeftPart">
                      <div className="ClientImg">
                        <p>N</p>
                      </div>
                      <div className="ClientContent">
                        <h5>{item?.clientname}</h5>
                        <p>{item?.clientrole}</p>
                      </div>
                    </div>
                    <div className="ClientLeftRightPart">
                      <RightIcon />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Clients;
