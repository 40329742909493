import { gql, useMutation } from "@apollo/client";

const SAVE_FINANCE_SIGNTURE_DETAIL=gql`
mutation CompleteFinanceRegistration($signature: String) {
    completeFinanceRegistration(signature: $signature) {
      success
      message
      data {
        signature_name
        signature_url
        time
      }
    }
  }
`;

export const SaveFinanceSigntureDetailAction=()=>{
    const [saveFinanceSigntureDetailResponseFunction,
    saveFinanceSigntureDetailResponseData]=useMutation(SAVE_FINANCE_SIGNTURE_DETAIL)

    const saveFinanceSigntureDetailQueryData=saveFinanceSigntureDetailResponseData

    const initSaveFinanceSigntureDetailData=(data, successCallback, errorCallback)=>{
        saveFinanceSigntureDetailResponseFunction({
            variables:data,
        }).then((responseData)=>{
            console.log(responseData,"respn")
            if(responseData.data.completeFinanceRegistration.success){
                successCallback(responseData)
            }else{
                errorCallback(responseData)

            }
        }).catch((error)=>{
            errorCallback(error)
        })
    }

    return { saveFinanceSigntureDetailQueryData,initSaveFinanceSigntureDetailData}
}