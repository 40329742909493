import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { GET_CLIENT_ACCOUNT_DETAILS } from "../../constants";
// ==========================GET ACCOUNT DETAILS==========================

const GET_OCCUPATION_DETAILS_QUERY = gql`
  query GetPrescriberOccupationList {
  getPrescriberOccupationList {
    success
    message
    data {
      category
      occupations {
        id
        title
        type
        pin_status
      }
    }
  }
}
`;

export const GetOccupationAction = () => {
    const dispatch = useDispatch();
  const [
    // getAccountDetailsResponseFunction,
    // getAccountDetailsResponseData,
     getOccupationResponseFunction,
    getOccupationResponseData,
  ] = useLazyQuery(GET_OCCUPATION_DETAILS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getAccountDetailsForManageAccount: responseData }) => {
      if (responseData.success) {
        dispatch({
            type: GET_CLIENT_ACCOUNT_DETAILS,
            payload: responseData,
          });
      } else {
        // Handle error response
      }
    },
  });

  const getOccupationQueryData = getOccupationResponseData;

  const initGetOccupationData = (data, successCallback, errorCallback) => {
    getOccupationResponseFunction({
      variables: data,
    }).then((res) => {
    
      if (res.data.getPrescriberOccupationList.data) {
        successCallback(res);
      } else {
        errorCallback(res);
      }
    }).catch((err) => {
      errorCallback(err);
    });
  };
//   const initGetAccountDetailsData = () => {
//     getAccountDetailsResponseFunction();
//   };

  return { getOccupationQueryData, initGetOccupationData };
};
