import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";


const CREATE_NOTE_MUTATION = gql`
mutation CreateOrUpdateClientNote($clientId: Int!, $clientNote: String!) {
    createOrUpdateClientNote(clientId: $clientId, clientNote: $clientNote) {
      success
      message
      data {
        clientId
        clientNote
      }
    }
  }
`;

export const CreateNote = () => {
  const [createNote, createNoteResponseData] =
    useMutation(CREATE_NOTE_MUTATION);
  const dispatch = useDispatch();
  const createNoteMutationData = createNoteResponseData;

  const initiateCreateNote= (
    data,
    successCallback,
    errorCallback
  ) => {
    createNote({
      variables: data,
    })
      .then(({ data: { createOrUpdateClientNote: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateCreateNote, createNoteMutationData };
};