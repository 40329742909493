import React, { useEffect, useState } from "react";
import "./editWebsite.scss";
import teamEdit from "../../../images/edit-group-chat.svg";
import imageEdit from "../../../images/edit-image-white.svg";
import textEdit from "../../../images/edit-text.svg";
import colorEdit from "../../../images/edit-art.svg";
import webURLimg from "../../../assets/createWebsite/text.png";
import rightArrow from "../../../images/right_arrow.svg";
import userAddLogo from "../../../assets/createWebsite/group-chat.png";
import AddTeamMemberModal from "./addTeamMemberModal";
import AddTeamMemberStep from "./addTeamMemberStep";
import WebsitePrev from "./websitePrev";
import StepButton from "../../common/StepButton";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import webPrev from "../../../assets/createWebsite/Art.png";
import teamMemberLogo from "../../../assets/createWebsite/teamMemberLogo.png";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { GetWebsiteDetailsAction } from "../../redux/actions/createWebsite/getWebsiteDetails";
import { useNavigate } from "react-router";
import { businessDashboardRoutePattern } from "../../../Routes";




const EditWebsiteComponent = (props) => {
  const { style, websitePrefilledData, tempSaveColorData, setTempSaveColorData, colorType, setColorType ,stepSixPrimaryData,stepSixSecondaryData} = props;
  const dimensions = useWindowDimensions();

  const [headerCreateWebsite, setHeaderCreateWebsite] = useState(0);
  const [editPageNavigationHeight, setEditPageNavigationHeight] = useState(0);
  const [headerWrapperHeight, setHeaderWrapperHeight] = useState(0);
  const [formButtonWrapperHeight, setFormButtonWrapperHeight] = useState(0);
  const [responsiveButtonHeight, setResponsiveButtonHeight] = useState(0);
  const [colorSavedStatus, setColorSavedStatus] = useState(false);

  const navigate = useNavigate();


  const { initGetWebsiteDataResponseFunction } = GetWebsiteDetailsAction();


  useEffect(() => {
    setHeaderCreateWebsite(
      document.getElementsByClassName("step-header")[0]?.offsetHeight
    );

    setHeaderWrapperHeight(
      document.getElementsByClassName("headerWrapper")[0]?.offsetHeight
    );

    setFormButtonWrapperHeight(
      document.getElementsByClassName("FormButtonWrapper")[0]?.offsetHeight
    );

    setEditPageNavigationHeight(
      document.getElementsByClassName("editPageNavigation")[0]?.offsetHeight
    );

    setResponsiveButtonHeight(
      document.getElementsByClassName("responsiveButton")[0]?.offsetHeight
    );



    window.addEventListener("resize", handleResize);
  }, [headerCreateWebsite, headerWrapperHeight, formButtonWrapperHeight, editPageNavigationHeight, responsiveButtonHeight]);

  const handleResize = () => {
    setHeaderCreateWebsite(
      document.getElementsByClassName("step-header")[0]?.offsetHeight
    );

    setHeaderWrapperHeight(
      document.getElementsByClassName("headerWrapper")[0]?.offsetHeight
    );

    setFormButtonWrapperHeight(
      document.getElementsByClassName("FormButtonWrapper")[0]?.offsetHeight
    );

    setEditPageNavigationHeight(
      document.getElementsByClassName("editPageNavigation")[0]?.offsetHeight
    );

    setResponsiveButtonHeight(
      document.getElementsByClassName("responsiveButton")[0]?.offsetHeight
    );
  };

  const handleClick = (item) => {
    if (item === "Website URL") {
      props.setEditStep(1);
    } else if (item === "Website Headline") {
      props.setEditStep(4);
    } else if (item === "Personal Details") {
      props.setEditStep(8);
    } else if (item === "Social Media") {
      props.setEditStep(7);
    } else if (item === "Terms & Conditions") {
      props.setEditStep(9);
    } else if (item === "Primary Color") {
    } else if (item === "Secondary Color") {
    } else if (item === "Teritary Color") {
    } else if (item === "Website Cover Photo") {
      props.setEditStep(3);
    } else if (item === "Website Logo") {
      props.setEditStep(2);
    }
  };
  const editOptionsData = [
    {
      heading: "Add your team to your website",
      desc: "We recommend uploading high-quality photos of you and your team. Start with a great picture of yourself and then add others. Remember, you can always add more later.",
      options: "",
    },
    {
      heading: "Edit Image",
      desc: "This image will be the first thing visitors see on your homepage. If you change your mind, you can always update it later. We suggest using PNG or transparent background for your logo",
      options: ["Website Cover Photo", "Website Logo"],
    },
    {
      heading: "Text Edit",
      desc: "Edit your website content easily from this section",
      options: [
        "Website URL",
        "Website Headline",
        "Personal Details",
        "Social Media",
        "Terms & Conditions",
      ],
    },
    {
      heading: "Colour Edit",
      desc: "We suggest to select a good contrast color to catch the attention of the user, but don’t worry you can change this later .",
      options: "",
    },
  ];

  const stepFiveData = {
    imageUrl: teamMemberLogo,
    title: "Add your team to your website",
    description:
      "We recommend uploading high-quality photos of you and your team. Start with a great picture of yourself and then add others. Remember, you can always add more later.",
  };

  const stepSixData = {
    imageUrl: webPrev,
    title: "Select the colours for your website to suit your brand and stand out",
    description:
      "We recommend uploading high-quality photos of you and your team. Start with a great picture of yourself and then add others. Remember, you can always add more later.",
  };


  // useEffect(() => {
  //   initGetWebsiteDataResponseFunction();
  // }, []);



  console.log(props.selectedEditTypeStep, 'props.selectedEditTypeStep', colorSavedStatus, 'colorSavedStatus')


  useEffect(() => {
    if (props.selectedEditTypeStep !== 3) {
      props.setWebsiteData({
        ...props.websiteData,
        primaryColor: tempSaveColorData?.primaryColor,
        secondaryColor: tempSaveColorData?.secondaryColor,
        teritaryColor: tempSaveColorData?.teritaryColor,
      });
    }
    // else {
    //   initGetWebsiteDataResponseFunction();
    //   setTempSaveColorData({
    //     primaryColor: props.websiteData?.primaryColor,
    //     secondaryColor: props.websiteData?.secondaryColor,
    //   });
    // }
  }, [props.selectedEditTypeStep]);



  return (
    <div className="edit-component-container">
      <div className="editPageNavigation">
        <div
          className={props.selectedEditTypeStep === 0 ? 'nav-content ActiveNav' : 'nav-content'}
          onClick={() => {
            props.setSelectedEditTypeStep(0);
          }}
        >
          <div className="edit-img-container">
            <img src={teamEdit} alt="" />
          </div>
          <span className="EditMenuTitle">Team</span>
        </div>
        <div
          className={props.selectedEditTypeStep === 1 ? 'nav-content ActiveNav' : 'nav-content'}
          onClick={() => {
            props.setSelectedEditTypeStep(1);
          }
          }
        >
          <div className="edit-img-container">
            <img src={imageEdit} alt="" />
          </div>
          <span className="EditMenuTitle">Image</span>
        </div>
        <div
          className={props.selectedEditTypeStep === 2 ? 'nav-content ActiveNav' : 'nav-content'}
          onClick={() => {
            props.setSelectedEditTypeStep(2);
          }
          }
        >
          <div className="edit-img-container">
            <img src={textEdit} alt="" />
          </div>
          <span className="EditMenuTitle">Text</span>
        </div>
        <div
          className={props.selectedEditTypeStep === 3 ? 'nav-content ActiveNav' : 'nav-content'}
          onClick={() => { props.setSelectedEditTypeStep(3) }}
        >
          <div className="edit-img-container">
            <img src={colorEdit} alt="" />
          </div>
          <span className="EditMenuTitle">Colour</span>
        </div>
      </div>
      <div className="edit-options">
        {!(props.selectedEditTypeStep === 0 || props.selectedEditTypeStep === 3) &&
          <div className="formInnerHeight" style={style}>
            <div className="editHeaderWrapper">
              <div className="edit-content">
                <img src={webURLimg} alt="" />
                <h2>{editOptionsData[props.selectedEditTypeStep].heading}</h2>
              </div>
              <p>{editOptionsData[props.selectedEditTypeStep].desc}</p>
            </div>
            <div className="edit-list">
              {editOptionsData[props.selectedEditTypeStep].options &&
                editOptionsData[props.selectedEditTypeStep].options.map(
                  (item, index) => {
                    return (
                      <div
                        className="edit-list-item d-flex justify-content-between"
                        onClick={() => { handleClick(item) }}
                        key={index}
                      >
                        <span>{item}</span>
                        <div>
                          <img src={rightArrow} alt="" />
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        }

        {props.selectedEditTypeStep === 0 && (
          <div className="MainContentWrapper">
            <AddTeamMemberStep
              setCount={props.setCount}
              count={props.count}
              stepFiveData={stepFiveData}
              websiteData={props.websiteData}
              setWebsiteData={props.setWebsiteData}
              isVisibleSkip={false}
              trackActivity={props.trackActivity}
              step={props.step}
              edit
              setStep={props.setStep}
              style={{
                height: dimensions.width < 1200 ? dimensions.height - headerCreateWebsite - responsiveButtonHeight - editPageNavigationHeight - formButtonWrapperHeight - 100 + 'px' : dimensions.height - editPageNavigationHeight - formButtonWrapperHeight - headerCreateWebsite - 64 + "px",
              }}
            />
          </div>
        )}
        {props.selectedEditTypeStep === 3 && (
          <div className="MainContentWrapper">
            <WebsitePrev
              colorSavedStatus={colorSavedStatus}
              setColorSavedStatus={setColorSavedStatus}
              colorType={colorType}
              setColorType={setColorType}
              tempSaveColorData={tempSaveColorData}
              setTempSaveColorData={setTempSaveColorData}
              setCount={props.setCount}
              count={props.count}
              stepSixPrimaryData={stepSixPrimaryData}
              stepSixSecondaryData={stepSixSecondaryData}
              isVisibleColorPicker
              websiteData={props.websiteData}
              setWebsiteData={props.setWebsiteData}
              stepSixData={stepSixData}
              step={props.Step}
              setStep={props.setStep}
              edit
              style={{
                height: dimensions.width < 1200 ? dimensions.height - headerCreateWebsite - responsiveButtonHeight - editPageNavigationHeight - formButtonWrapperHeight - 100 + 'px' : dimensions.height - editPageNavigationHeight - formButtonWrapperHeight - headerCreateWebsite - 90 + "px",
              }}
            />
          </div>
        )}

        {console.log(props.selectedEditTypeStep, 'props.selectedEditTypeStep')}
        {websitePrefilledData?.is_active && props.selectedEditTypeStep < 3 &&
          <div className="FormButtonWrapper">
            <StepButton
              // disabled
              blue={true}
              label={"Go to Dashboard"}
              // isLeftIcon={true}
              // leftIcon={<FiChevronLeft className="arrow_next" />}
              onClick={() => navigate(businessDashboardRoutePattern)}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default EditWebsiteComponent;
