import { GET_TREATMENT_DETAILS } from "../../constants/treatment-list-const/TreatmentListConst";


const initialState={
  treatementDetail:[]
}

export default function treatmentsDetailReducer(state = initialState, action) {

  switch (action.type) {

    case GET_TREATMENT_DETAILS:
      return {
        ...state,
        treatementDetail: action.payload,
  };

    default:
      return state;
  }



}