import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const CREATE_HOLD_DURATION_MUTATION = gql`
mutation HoldAppointment($bookingId: Int, $duration: Int) {
    holdAppointment(booking_id: $bookingId, duration: $duration) {
      success
      message
    }
  }
`;

export const CreateHoldDurationMutation = () => {
  const [createHoldDurationRequest, createHoldDurationResponseData] =
    useMutation(CREATE_HOLD_DURATION_MUTATION);
  const dispatch = useDispatch();
  const createHoldDurationMutationData = createHoldDurationResponseData;

  const initiateCreateHoldDurationResponseData = (
    data,
    successCallback,
    errorCallback
  ) => {
    createHoldDurationRequest({
      variables: data,
    })
      .then(({ data: { holdAppointment: responseData } }) => {
        if (responseData) {
          console.log("hold duration", responseData.success)
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateCreateHoldDurationResponseData, createHoldDurationMutationData };
};
