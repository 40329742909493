import React from 'react'
import BusinessHeader from '../../businessHeader'
import PrescriberAbout from '../../prescriber-registration/about/PrescriberAbout'
import "./prescriber-about.scss"

const PrescriberAboutYou = () => {
  return (
    <div className='prescriber-registration-about-container'>
    {/* <BusinessHeader /> */}
    <PrescriberAbout />
  </div>
  )
}

export default PrescriberAboutYou