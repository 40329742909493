import React, { useEffect, useRef, useState } from "react";
import "./form-listing.scss";
import SearchIcon from "../../../images/client/search_icon.svg";
import { ReactComponent as CloseIcon } from "../../../images/close_icon.svg";
import StepButton from "../../common/StepButton";
import gsap from "gsap";
import { GetConsentFormList } from "../../redux/actions/formsTemplate/getFormTemplateList";
import { useDispatch, useSelector } from "react-redux";
import { GetMedicalFormStatusQuery } from "../../redux/actions/appointments/GetMedicalFormStatus";
import InfiniteScroll from "react-infinite-scroll-component";
import { CLEAR_CONSENT_FORM_LIST } from "../../redux/constants/formsTemplate/FormTemplateConst";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";
import CustomCheckbox from "../../custom-components/custom-checkbox/CustomCheckbox";
import { capitalizeFirstLetter } from "../../../utils/initials";
import { debounce } from "lodash";

const ConsentFormList = ({ setIsClose, setSelectedConsentForm, selectedConsentForm, clientId, setMedicalFormSave, medicalFormSave, clientName, bookingId }) => {
  const [searchText, setSearchText] = useState("");
  const [newConsentForm, setNewConsentForm] = useState([]);
  const [isSearchedTriggered, setIsSearchedTriggered] = useState(false);
  const [isFilledMedical, setIsFilledMedical] = useState(null)
  const [medicalForm, setMedicalForm] = useState(medicalFormSave)
  console.log("selected consentform akash", selectedConsentForm)
  //Consent and AfterCare Query Funcation
  const { consentFormListQueryData, initGetConsentFormList } = GetConsentFormList()
  const { medicaFormStatusQueryData, initGetMedicalFormStatus } = GetMedicalFormStatusQuery()
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();

  //Get ConsentForm Data from Reducer
  const { totalConsentFormCount } = useSelector(state => state?.consentFormListReducer)
  const { consentFormListPage } = useSelector(state => state?.consentFormListReducer)
  const { loadedConsentFormCount } = useSelector(state => state?.consentFormListReducer)
  //Get ConsentForm Data from Reducer 
  const { consentFormList } = useSelector(state => state?.consentFormListReducer)

  const initiateConsentFormListCall = (refreshList = false) => {
    let newPage = consentFormListPage + 1;
    if (refreshList) {
      newPage = 1;

    } else if (totalConsentFormCount <= loadedConsentFormCount) {
      return;
    }

    initGetConsentFormList({
      page: newPage,
      limit: 12,
      search: searchText,
    }, (res) => {

    })
  };

  useEffect(() => {
    dispatch({ type: CLEAR_CONSENT_FORM_LIST });
    initiateConsentFormListCall(true);
  }, [])


  // Set initial state when component mounts or when selectedTreatments prop changes
  useEffect(() => {
    //const updatedConsentForm = selectedConsentForm?.filter((item)=>consentFormList?.some((item2)=>item2?.consentFormId === item?.consentFormId)).map((item)=>item)
    setNewConsentForm(selectedConsentForm)
    //console.log(updatedConsentForm,"updatedConsentForm",selectedConsentForm)
  }, [selectedConsentForm]);

  useEffect(() => {
    // if (bookingId) {
    //   setIsFilledMedical(true)
    // } else {
    initGetMedicalFormStatus({
      clientId: clientId
    }, (res) => {
      if (res?.success) {
        setIsFilledMedical(res?.data?.medicalFormFilledByClient)
        // if (res?.data?.medicalFormFilledByClient) {
        //   // setMedicalForm(true)
        //   // setIsFilledMedical(true)

        // }
      } else {
        setIsFilledMedical(false)
      }
    }, (err) => {
      console.log(err, "err medical form")
      setIsFilledMedical(false)
    })
    // }
  }, [])


  const handleConsentFormChange = (consentFormId, consentFormName) => {
    const updatedSelectedConsent = [...newConsentForm];
    const index = updatedSelectedConsent.findIndex((item) => item?.consentFormId === consentFormId);

    if (index !== -1) {
      // If consentFormId is already in the selected array, remove it
      updatedSelectedConsent.splice(index, 1);
    } else {
      // If consentFormId is not in the selected array, add it
      updatedSelectedConsent.push({ consentFormId: consentFormId, consentFormName: consentFormName });
    }

    setNewConsentForm(updatedSelectedConsent);
  };


  const handleSearchText = (e) => {
    setSearchText(e.target.value);
    setIsSearchedTriggered(true);
  };

  const handleSelectedData = () => {
    setSelectedConsentForm(newConsentForm)
    setMedicalFormSave(medicalForm)

    setIsClose(false)
  }

  //DEBOUNCE FOR SEARCH
  // Debounced API call function
  const debouncedApiCall = debounce((query) => {
    // Make the API call using the search query
    // Replace this with your own API call logic
    dispatch({
      type: CLEAR_CONSENT_FORM_LIST
    })
    initiateConsentFormListCall(true)
  }, 500); // Debounce delay in milliseconds (e.g., 500ms)

  useEffect(() => {
    if ((searchText.length == 0 && isSearchedTriggered) || searchText.length >= 3) {
      // Call the debounced API function with the search query
      debouncedApiCall(searchText);

      // Cleanup function to cancel any pending debounced calls
      return () => {
        debouncedApiCall.cancel();
      };
    }
  }, [searchText]);
  return (
    <div className="form-list-main-container" id="scrollableDiv">
      <div className="sticky-header">
        <div className="close-btn">
          <CloseIcon className="close-icon-animation" onClick={() => { setIsClose(false); selectedConsentForm.length === 0 ? setSelectedConsentForm([]) : setSelectedConsentForm(selectedConsentForm) }} />
        </div>
        <h1>Consent</h1>
        <p className="sub-heading">Please search and select a consent form you require for this appointment.</p>
        <div className="search-container">
          <input type="text" placeholder="Search form" value={searchText} onChange={handleSearchText} />
          <img src={SearchIcon} alt="search-icon" className="search-icon-img" />
          {searchText && <CloseIcon className="cursor-pointer search-close-icon" onClick={() => { setSearchText("") }} />}
        </div>
      </div>

      <div id="scrollableDivConsent" style={{ height: "70vh", width: "100%", overflowX: "scroll" }}>
      {
        searchText.length == 0 &&
        <>
          <p className="mt-3 mb-3">Medical form</p>
          <div className={`form-list-container m-2  ${isFilledMedical || bookingId ? 'pe-none' : 'cursor-pointer'}`}
            onClick={() => (document.getElementById("consent-medical-form")).addEventListener(('change'), setMedicalForm(!medicalForm))}
          >
            {
              isFilledMedical || bookingId ? <p className="text-muted">{clientName ? clientName : "Client"}’s Medical Form {isFilledMedical ? "(filled)" : ""}</p> : <p>{clientName ? clientName : "Client"}’s Medical Form</p>
            }
            <CustomCheckbox
              className={`form-list-checkbox ${(isFilledMedical || bookingId) ? 'pe-none' : ''}`}
              id={"consent-medical-form"}
              name={"consent-medical-form"}
              checked={medicalForm} />

          </div>

        </>
      }
      
        {consentFormList?.filter((item) => item?.consentFormStatus === "active")?.length > 0 && <p className="mt-4 mb-4">Recent form</p>}
        <InfiniteScroll
          dataLength={loadedConsentFormCount}
          next={() => initiateConsentFormListCall()}
          hasMore={totalConsentFormCount > loadedConsentFormCount}
          scrollableTarget="scrollableDivConsent"

        >
          {consentFormList?.filter((item) => item?.consentFormStatus === "active")?.length > 0 && consentFormList?.filter(form => form?.consentFormStatus === "active").map((item, index) => (
            <div className="form-list-container my-3 cursor-pointer" key={index}
              onClick={() => (document.getElementById(item?.consentFormId)).addEventListener(('change'), handleConsentFormChange(item?.consentFormId, item?.consentFormName))}
            >
              <p className="truncated-text-35">{capitalizeFirstLetter(item?.consentFormName)}</p>
              <CustomCheckbox
                className={`form-list-checkbox`}
                id={item?.consentFormId}
                name={item?.consentFormName}
                checked={newConsentForm.some((selected) => selected?.consentFormId === item?.consentFormId)} />

            </div>
          ))}
          {
            consentFormListQueryData?.loading ?
              <SkeletonLoader type="common-list" /> : null
          }

          {consentFormList?.filter((item) => item?.consentFormStatus === "hidden")?.length > 0 && <p className="mt-4 mb-4">Discover</p>}

          {consentFormList?.filter((item) => item?.consentFormStatus === "hidden")?.length > 0 && consentFormList?.filter(form => form?.consentFormStatus === "hidden").map((item, index) => (
            <div className="form-list-container my-3 cursor-pointer" key={index}
              onClick={() => (document.getElementById(item?.consentFormId)).addEventListener(('change'), handleConsentFormChange(item?.consentFormId, item?.consentFormName))}
            >
              <p className="truncated-text-35">{capitalizeFirstLetter(item?.consentFormName)}</p>

              <CustomCheckbox
                className={`form-list-checkbox`}
                id={item?.consentFormId}
                name={item?.consentFormName}
                checked={newConsentForm.some((selected) => selected?.consentFormId === item?.consentFormId)} />

            </div>
          ))}

          {
            consentFormList?.filter((item) => item?.consentFormStatus === "hidden")?.length == 0 &&
            consentFormList?.filter((item) => item?.consentFormStatus === "active")?.length == 0 &&
            searchText.length > 0 &&
            <div className="no-data-availa-class">
              <p className="text-center">No form found!</p>
            </div>
          }
        </InfiniteScroll>
      </div>
      <div className="sticky-footer">
        <StepButton disabled={newConsentForm.length === 0 && !medicalForm} label={"Select"} blue={true} onClick={() => handleSelectedData()} />
      </div>
    </div>
  );
};

export default ConsentFormList;
