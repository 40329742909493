import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import {GET_CLIENT_NOTES, GET_CLIENT_STATS} from "../../constants";

const GET_CLIENT_NOTES_QUERY = gql`
query GetClientNote($clientId: Int!) {
    getClientNote(clientId: $clientId) {
      success
      message
      data {
        clientId
        clientNote
      }
    }
  }
`;

var page;
export const GetClientNotes = () => {
  const dispatch = useDispatch();
  const [getClientNotesData, clientNotesData] = useLazyQuery(
    GET_CLIENT_NOTES_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClientNote: responseData }) => {
        dispatch({
          type: GET_CLIENT_NOTES,
          payload: responseData.data,
        });
      },
    }
  );
  const clientNotesQueryData = clientNotesData;
  const initGetClientNotes = (data) => {
    getClientNotesData({
      variables: data,
    });
  };
  return { initGetClientNotes, clientNotesQueryData };
};
