import React, { useState, useEffect } from "react";
import bgImage from "../../../images/webstepbg.svg";
import backIcon from "../../../images/back_arrow.svg";
import closeIcon from "../../../images/close_icon.svg";
import "./createWebsite.scss";
import ProgressBar from "@ramonak/react-progress-bar";
import CreateWebsite from "./createWebsite";
import WebsiteURLStep1 from "../createWbsiteURL/CreateWebsiteStep1";
import UploadLogoCover from "./uploadLogoCover";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import imgUploadLogo from "../../../assets/createWebsite/TitleIcon.png";
import ImageTitleComponent from "../createWbsiteURL/commonComponents/ImgTitleComp";
import webURLimg from "../../../assets/createWebsite/text.png";
import teamMemberLogo from "../../../assets/createWebsite/teamMemberLogo.png";
import AddTeamMemberStep from "./addTeamMemberStep";
import PersonalDetailsStep from "./personalDetailsStep";
import SocialMediaPage from "../createWbsiteURL/SocialMediaPage";
import WebsiteBussTagline from "../createWbsiteURL/WebsiteBussTagline";
import { useLocation, useNavigate } from "react-router";
import { businessDashboardRoutePattern } from "../../../Routes";
import webPrev from "../../../assets/createWebsite/Art.png";

import WebsitePrev from "./websitePrev";
import TermsAndCondition from "../createWbsiteURL/Terms&Condition";
import CongratulationPage from "../createWbsiteURL/CongratulationPage";
import StepButton from "../../common/StepButton";
import { FiChevronRight } from "react-icons/fi";
import SkipButtonComp from "../createWbsiteURL/commonComponents/SkipBtn";
import CustomModal from "../../modal/CustomModal";
import EditWebsiteComponent from "./editWebsiteComponent";
import { GetWebsiteTeamMembersAction } from "../../redux/actions/createWebsite/getPersonalWebTeamMembersQuery";
import { GetWebsiteDetailsAction } from "../../redux/actions/createWebsite/getWebsiteDetails";

const EditWebsite = (props) => {
  const { setIsEdit, editStep, setEditStep, colorType, setColorType, selectedEditTypeStep, setSelectedEditTypeStep, websitePrefilledData, tempSaveBusinessData, setTempSaveBusinessData, tempSaveColorData, tempSaveTaglineData, tempUploadSaveData, setTempSaveColorData, setTempUploadSaveData, setTempSaveTaglineData, stepSixPrimaryData, stepSixSecondaryData } = props;

  const dimensions = useWindowDimensions();
  const navigate = useNavigate();
  const [showBusinessHours, setShowBusinessHours] = useState(false);
  const [headerCreateWebsite, setHeaderCreateWebsite] = useState(0);
  const [editPageNavigationHeight, setEditPageNavigationHeight] = useState(0);
  const [headerWrapperHeight, setHeaderWrapperHeight] = useState(0);
  const [formButtonWrapperHeight, setFormButtonWrapperHeight] = useState(0);
  const [responsivePrev, setResponsivePrev] = useState(false);
  const [responsiveButtonHeight, setResponsiveButtonHeight] = useState(0);


  const formInnerHeight =
    formButtonWrapperHeight + editPageNavigationHeight + 64;

  // console.log( formInnerHeight , 'formButtonWrapperHeight')
  const {
    initGetTeamMembersResponseFunction,
    GetWebsiteTeamMembersResponseData: { loading: getTeamMembersLoading },
  } = GetWebsiteTeamMembersAction();

  useEffect(() => {
    initGetTeamMembersResponseFunction();
  }, []);

  useEffect(() => {
    setHeaderCreateWebsite(
      document.getElementsByClassName("step-header")[0]?.offsetHeight
    );

    setHeaderWrapperHeight(
      document.getElementsByClassName("headerWrapper")[0]?.offsetHeight
    );

    setFormButtonWrapperHeight(
      document.getElementsByClassName("FormButtonWrapper")[0]?.offsetHeight
    );

    setEditPageNavigationHeight(
      document.getElementsByClassName("editPageNavigation")[0]?.offsetHeight
    );

    setResponsiveButtonHeight(
      document.getElementsByClassName("responsiveButton")[0]?.offsetHeight
    );



    window.addEventListener("resize", handleResize);
  }, [headerCreateWebsite, headerWrapperHeight, formButtonWrapperHeight, editPageNavigationHeight, responsiveButtonHeight]);

  const handleResize = () => {
    setHeaderCreateWebsite(
      document.getElementsByClassName("step-header")[0]?.offsetHeight
    );

    setHeaderWrapperHeight(
      document.getElementsByClassName("headerWrapper")[0]?.offsetHeight
    );

    setFormButtonWrapperHeight(
      document.getElementsByClassName("FormButtonWrapper")[0]?.offsetHeight
    );

    setEditPageNavigationHeight(
      document.getElementsByClassName("editPageNavigation")[0]?.offsetHeight
    );

    setResponsiveButtonHeight(
      document.getElementsByClassName("responsiveButton")[0]?.offsetHeight
    );

  };

  var isMobile;

  if (dimensions?.width <= 767) {
    isMobile = true;
  } else {
    isMobile = false;
  }

  const {
    initGetWebsiteDataResponseFunction,
    GetWebsiteDetailsResponseData: { loading: webDetailsLoading },
  } = GetWebsiteDetailsAction();

  useEffect(() => {
    initGetWebsiteDataResponseFunction();
  }, [editStep]);

  useEffect(() => {
    // isMobile ? setShowSidebar(false) : setShowSidebar(true)
    // if(location?.pathname === "/create-website"){
    //     document.body.classList.add('createWebsitePage');
    // }else{
    //     document.body.classList.remove('createWebsitePage');
    // }
  }, [isMobile]);

  // console.log(editStep, "in edit step editstep value");
  console.log("prefill website data", props.websiteData.coverPhoto, props.step, tempUploadSaveData)

  // return;

  useEffect(() => {
    if (selectedEditTypeStep === 0) {
      props.setWebsiteData({
        ...props.websiteData,
      })
    }
  }, [selectedEditTypeStep]);

  return (
    <>
      <div className="customeContainer">
        <div className="row">
          <div className={"mainContainerPrev"}>
            {editStep === 0 && (
              <EditWebsiteComponent
                tempSaveColorData={tempSaveColorData}
                setTempSaveColorData={setTempSaveColorData}
                tempSaveTaglineData={tempSaveTaglineData}
                setTempSaveTaglineData={setTempSaveTaglineData}
                tempUploadSaveData={tempUploadSaveData}
                setTempUploadSaveData={setTempUploadSaveData}
                setCount={props.setCount}
                count={props.count}
                stepSixPrimaryData={stepSixPrimaryData}
                stepSixSecondaryData={stepSixSecondaryData}
                setIsEdit={setIsEdit}
                selectedEditTypeStep={selectedEditTypeStep}
                setSelectedEditTypeStep={setSelectedEditTypeStep}
                setEditStep={setEditStep}
                trackActivity={props.trackActivity}
                editStep={editStep}
                colorType={colorType}
                setColorType={setColorType}
                step={props.setStep}
                setStep={props.setStep}
                websiteData={props.websiteData}
                websitePrefilledData={websitePrefilledData}
                style={{
                  height: dimensions.width < 1200 ? dimensions.height - headerCreateWebsite - responsiveButtonHeight - editPageNavigationHeight - formButtonWrapperHeight - 100 + 'px' : dimensions.height - editPageNavigationHeight - formButtonWrapperHeight - headerCreateWebsite - 64 + "px",
                }}
                setWebsiteData={props.setWebsiteData}
              />
            )}
            {editStep === 1 && (
              <div className="step1 MainStepWrapper">
                <div className="MainContentWrapper">
                  <WebsiteURLStep1
                    webDetailsLoading={webDetailsLoading}
                    tempSaveBusinessData={tempSaveBusinessData}
                    setTempSaveBusinessData={setTempSaveBusinessData}
                    editStep={editStep}
                    step={editStep}
                    setStep={setEditStep}
                    setCount={props.setCount}
                    count={props.count}
                    websiteData={props.websiteData}
                    setWebsiteData={props.setWebsiteData}
                    trackActivity={props.trackActivity}
                    stepOneData={props.stepOneData}
                    style={{
                      height: dimensions.width < 1200 ? dimensions.height - headerCreateWebsite - responsiveButtonHeight - formButtonWrapperHeight - 100 + 'px' : dimensions.height - formButtonWrapperHeight - headerCreateWebsite - 64 + "px",
                    }}
                  // isMobile={isMobile}
                  // prevWebOnClick={() => {
                  //   setResponsivePrev(true);
                  // }}
                  />
                </div>
                {/* <webPrev /> */}
              </div>
            )}
            {editStep === 2 && (
              <div
                className="step2 MainStepWrapper"
              // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
              >
                <div className="MainContentWrapper">
                  {/* <ImageTitleComponent {...props.stepTwoData} /> */}
                  <UploadLogoCover
                    tempUploadSaveData={tempUploadSaveData}
                    setTempUploadSaveData={setTempUploadSaveData}
                    editStep={editStep}
                    step={editStep}
                    setStep={setEditStep}
                    setCount={props.setCount}
                    count={props.count}
                    websiteData={props.websiteData}
                    stepTwoData={props.stepTwoData}
                    trackActivity={props.trackActivity}
                    setWebsiteData={props.setWebsiteData}
                    style={{
                      height: dimensions.width < 1200 ? dimensions.height - headerCreateWebsite - responsiveButtonHeight - formButtonWrapperHeight - 100 + 'px' : dimensions.height - formButtonWrapperHeight - headerCreateWebsite - 64 + "px",
                    }}
                  />
                </div>
              </div>
            )}
            {editStep === 3 && (
              <div
                className="step3 MainStepWrapper"
              // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
              >
                <div className="MainContentWrapper">
                  {/* <ImageTitleComponent {...props.stepThreeData} /> */}
                  <UploadLogoCover
                    tempUploadSaveData={tempUploadSaveData}
                    setTempUploadSaveData={setTempUploadSaveData}
                    editStep={editStep}
                    step={editStep}
                    setStep={setEditStep}
                    setCount={props.setCount}
                    count={props.count}
                    trackActivity={props.trackActivity}
                    stepThreeData={props.stepThreeData}
                    websiteData={props.websiteData}
                    setWebsiteData={props.setWebsiteData}
                    style={{
                      height: dimensions.width < 1200 ? dimensions.height - headerCreateWebsite - responsiveButtonHeight - formButtonWrapperHeight - 100 + 'px' : dimensions.height - formButtonWrapperHeight - headerCreateWebsite - 64 + "px",
                    }}
                  // isMobile={isMobile}
                  // prevWebOnClick={() => {
                  //   setResponsivePrev(true);
                  // }}
                  />
                </div>
              </div>
            )}

            {editStep === 4 && (
              <div
                className="step4 MainStepWrapper"
              // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
              >
                <div className="MainContentWrapper">
                  {/* <ImageTitleComponent {...props.stepFourData} /> */}
                  <WebsiteBussTagline
                    tempSaveTaglineData={tempSaveTaglineData}
                    setTempSaveTaglineData={setTempSaveTaglineData}
                    websiteData={props.websiteData}
                    setWebsiteData={props.setWebsiteData}
                    editStep={editStep}
                    step={editStep}
                    setCount={props.setCount}
                    count={props.count}
                    trackActivity={props.trackActivity}
                    stepFourData={props.stepFourData}
                    setStep={setEditStep}
                    style={{
                      height: dimensions.width < 1200 ? dimensions.height - headerCreateWebsite - responsiveButtonHeight - formButtonWrapperHeight - 100 + 'px' : dimensions.height - formButtonWrapperHeight - headerCreateWebsite - 64 + "px",
                    }}
                  // isMobile={isMobile}
                  // prevWebOnClick={() => {
                  //   setResponsivePrev(true);
                  // }}
                  />
                </div>
              </div>
            )}

            {editStep === 5 && (
              <div
                className="step5 MainStepWrapper"
              // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
              >
                <div className="MainContentWrapper">
                  {/* <ImageTitleComponent {...props.stepFiveData} /> */}
                  <AddTeamMemberStep
                    editStep={editStep}
                    step={editStep}
                    setStep={setEditStep}
                    setCount={props.setCount}
                    count={props.count}
                    stepFiveData={props.stepFiveData}
                    websiteData={props.websiteData}
                    trackActivity={props.trackActivity}
                    setWebsiteData={props.setWebsiteData}
                    websitePrefilledData={websitePrefilledData}
                    isVisibleSkip={false}
                    style={{
                      height: dimensions.width < 1200 ? dimensions.height - headerCreateWebsite - responsiveButtonHeight - formButtonWrapperHeight - 100 + 'px' : dimensions.height - formButtonWrapperHeight - headerCreateWebsite - 64 + "px",
                    }}
                  // isMobile={isMobile}
                  // prevWebOnClick={() => {
                  //   setResponsivePrev(true);
                  // }}
                  />
                </div>
              </div>
            )}

            {editStep === 6 && (
              <div
                className="step6 MainStepWrapper WebsitePrevMain"
              // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
              >
                <div className=" MainContentWrapper WebsitePrevWrapper">
                  <ImageTitleComponent {...props.stepSixData} />
                  <WebsitePrev
                    tempSaveColorData={tempSaveColorData}
                    setTempSaveColorData={setTempSaveColorData}
                    isVisiblePrev={false}
                    trackActivity={props.trackActivity}
                    isVisibleColorPicker={true}
                    setCount={props.setCount}
                    websiteData={props.websiteData}
                    count={props.count}
                    colorType={colorType}
                    setColorType={setColorType}
                    style={{
                      height: dimensions.width < 1200 ? dimensions.height - headerCreateWebsite - responsiveButtonHeight - editPageNavigationHeight - formButtonWrapperHeight - 100 + 'px' : dimensions.height - editPageNavigationHeight - formButtonWrapperHeight - headerCreateWebsite - 64 + "px",
                    }}
                  />
                  <div className="inputWrapper">
                    <div className="formWrapper">
                      <div className="FormButtonWrapper">
                        <StepButton
                          onClick={() => {
                            setEditStep(editStep + 1);
                          }}
                          blue={true}
                          label={"Next"}
                          icon={<FiChevronRight className="arrow_next" />}
                        />
                        {/* <SkipButtonComp
                          onClick={() => {
                            setEditStep(editStep + 1);
                          }}
                          // isMobile={isMobile}
                          // prevWebOnClick={() => {
                          //   setResponsivePrev(true);
                          // }}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {editStep === 7 && (
              <div
                className="step7 MainStepWrapper"
              // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
              >
                <div className="MainContentWrapper">
                  {/* <ImageTitleComponent {...props.stepSevenData} /> */}
                  <SocialMediaPage
                    setCount={props.setCount}
                    count={props.count}
                    editStep={editStep}
                    step={editStep}
                    setStep={setEditStep}
                    websiteData={props.websiteData}
                    trackActivity={props.trackActivity}
                    stepSevenData={props.stepSevenData}
                    setWebsiteData={props.setWebsiteData}
                    style={{
                      height: dimensions.width < 1200 ? dimensions.height - headerCreateWebsite - responsiveButtonHeight - formButtonWrapperHeight - 100 + 'px' : dimensions.height - formButtonWrapperHeight - headerCreateWebsite - 64 + "px",
                    }}
                  // isMobile={isMobile}
                  // prevWebOnClick={() => {
                  //   setResponsivePrev(true);
                  // }}
                  />
                </div>
              </div>
            )}

            {editStep === 8 && (
              <div
                className="step8 MainStepWrapper"
              // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
              >
                <div className="MainContentWrapper">
                  {/* <ImageTitleComponent {...props.stepEightData} /> */}
                  <PersonalDetailsStep
                    setCount={props.setCount}
                    count={props.count}
                    editStep={editStep}
                    step={editStep}
                    setStep={setEditStep}
                    websiteData={props.websiteData}
                    stepEightData={props.stepEightData}
                    setWebsiteData={props.setWebsiteData}
                    showBusinessHours={showBusinessHours}
                    trackActivity={props.trackActivity}
                    setShowBusinessHours={setShowBusinessHours}
                    style={{
                      height: dimensions.width < 1200 ? dimensions.height - headerCreateWebsite - responsiveButtonHeight - formButtonWrapperHeight - 100 + 'px' : dimensions.height - formButtonWrapperHeight - headerCreateWebsite - 64 + "px",
                    }}
                  // isMobile={isMobile}
                  // prevWebOnClick={() => {
                  //   setResponsivePrev(true);
                  // }}
                  />
                </div>
              </div>
            )}
            {editStep === 9 && (
              <div
                className="step9 MainStepWrapper"
              // style={{ height: dimensions.height - headerCreateWebsite + "px" }}
              >
                <div className="MainContentWrapper">
                  {/* <ImageTitleComponent {...props.stepNineData} /> */}
                  <TermsAndCondition
                    setCount={props.setCount}
                    count={props.count}
                    editStep={editStep}
                    step={editStep}
                    setStep={setEditStep}
                    websiteData={props.websiteData}
                    trackActivity={props.trackActivity}
                    stepNineData={props.stepNineData}
                    setWebsiteData={props.setWebsiteData}
                    style={{
                      height: dimensions.width < 1200 ? dimensions.height - headerCreateWebsite - responsiveButtonHeight - formButtonWrapperHeight - 100 + 'px' : dimensions.height - formButtonWrapperHeight - headerCreateWebsite - 64 + "px",
                    }}
                  // isMobile={isMobile}
                  // prevWebOnClick={() => {
                  //   setResponsivePrev(true);
                  // }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditWebsite;
