// ========================COMPLETE BOOKING PAYMENT========================

import { gql, useMutation } from "@apollo/client";

const SERVICE_USER_ACTIVITY_MUTATION = gql`
mutation TrackUserActivityForServices($serviceId: Int!, $packageId: Int, $packageSlug: String, $activityId: Int!) {
    trackUserActivityForServices(serviceId: $serviceId, packageId: $packageId, packageSlug: $packageSlug, activityId: $activityId) {
      success
      message
    }
  }
 `;


export const TrackUserActivityForServicesAction = () => {
    // const dispatch = useDispatch();
    const [
        TrackUserActivityForServicesResponseFunction,
        trackUserActivityForServicesResponseData,
    ] = useMutation(SERVICE_USER_ACTIVITY_MUTATION)

    //this will be called from components
    const trackUserActivityForServicesQueryData = trackUserActivityForServicesResponseData; //variable

    const initTrackUserActivityForServicesData = (data, successCallback = null, errorCallback = null) => {
        TrackUserActivityForServicesResponseFunction({
            variables: data,
        }).then(({ data: { trackUserActivityForServices: responseData } }) => {
            console.log('packageSlug err 0', responseData)
            if (responseData?.success) {
                // dispatch({
                //     type: SET_BOOKING_DETAILS,
                //     payload: responseData?.data
                // })
                if (typeof successCallback === 'function') {
                    successCallback();
                }
                // toast.success(responseData.message)
            }
            else {
                console.log('packageSlug err 1', responseData)
                if (typeof errorCallback === 'function') {
                    errorCallback();
                }
                // toast.error(responseData.message)
            }
        }).catch((err) => {
            console.log('packageSlug err 2', err)
            if (typeof errorCallback === 'function') {
                errorCallback();
            }
            // toast.error("something went wrong!!!")
        })
    }

    return { trackUserActivityForServicesQueryData, initTrackUserActivityForServicesData };
};
