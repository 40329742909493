import React, { useState, useEffect } from "react";
import "./selectProcess.scss";
import backIcon from "../../../images/back_arrow.svg";
import { FaWhatsapp } from "react-icons/fa6";
import BuildImg from "../../../assets/createWebsite/secondSlide.png";
import BuildfreeIcon from "../../../assets/createWebsite/Buildfree.svg";
import AiIcon from "../../../assets/createWebsite/AiIcon.svg";
import customeBuild from "../../../assets/createWebsite/customeBuild.svg";
import StepButton from "../../common/StepButton";
import { FiChevronRight } from "react-icons/fi";
import { toast } from "react-toastify";
import { TrackUserActivity } from "../../../components/redux/actions/createWebsite/trackUserActivity";
import { SaveWebsiteDetails } from "../../../components/redux/actions/createWebsite/saveWebsiteDetails"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { businessDashboardRoute } from "../../../Routes"
import { GetAllWebsitePackageAction } from "../../redux/actions/createWebsite/getAllWebsitePackages"
import { GET_ALL_WEBSITE_PACKAGE_DATA } from "../../redux/constants/createWebiste/getAllWebsitePackages"
import { useDispatch } from "react-redux";

function SelectProcess({
  setCurrentStep,
  callDetailsApi,
  setWelcomeScreen,
  detailsLoading,
}) {

  const dispatch = useDispatch()

  // active step currrent page
  const [active, setActive] = useState(0)

  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate()

  const websitePrefilledData = useSelector(
    (state) => state.WebsiteDetailsReducer.websitePrefilledData
  );
  const { initAllWebsitePackageFunction, GetAllWebsitePackageResponseData: { loading: packageLoading } } = GetAllWebsitePackageAction()

  useEffect(() => {
    setActive(websitePrefilledData?.website_build_type)
  }, [websitePrefilledData?.website_build_type])

  //api-mutation 
  const { initiatetrackUserActivity, trackUserActivityMutationData } = TrackUserActivity();
  const {
    initiateSaveWebsiteDetails,
    saveWebsiteDetailsMutationData: { loading: saveWebsiteDetailsLoading },
  } = SaveWebsiteDetails();

  const onSubmit = () => {
    if (active == 1) {
      initiatetrackUserActivity({
        activityStep: 2,
      }, (responseData) => {
        console.log("activity tracked")
        if (responseData?.success) {
          initiateSaveWebsiteDetails({ buildType: 1 }, (responseData) => {
            if (responseData?.success) {
              setClicked(true)
              callDetailsApi({});
            }
          }, (error) => {
            toast(error, { icon: "" });
          })
        }
        else {
          setWelcomeScreen(false);
        }
      }
        ,
        (error) => {
          toast(error, { icon: "" });
        })
    }
    if (active == 2) {
      initiateSaveWebsiteDetails({ buildType: 2 }, (responseData) => {
        if (responseData?.success) {
          initAllWebsitePackageFunction({
          }, res => {
            console.log("response data", res)
            if (res?.data?.getWebsitePackages?.success) {
              window.open(res?.data?.getWebsitePackages?.data?.packages[0]?.url, '_self')
              dispatch({
                type: GET_ALL_WEBSITE_PACKAGE_DATA,
                payload: res?.data?.getWebsitePackages?.data?.packages
              })
              console.log("res?.data?.getWebsitePackages", res?.data?.getWebsitePackages.data.packages[0])
            }
          }, err => {
            console.log("error", err)
          })
          callDetailsApi({});
        }
      }, (error) => {
        toast(error, { icon: "" });
      })

    }
  }
  console.log("active", active, detailsLoading, clicked, websitePrefilledData)

  useEffect(() => {
    if (clicked) {
      !detailsLoading && setWelcomeScreen(false);
    }
  }, [detailsLoading])


  return (
    <div className="selectProcessMain">
      <div className="TopHeader">
        <div className="BackButtonDashBoard">
          <img src={backIcon} alt="" onClick={() => { websitePrefilledData?.website_build_type != 0 ? navigate(businessDashboardRoute()) : setCurrentStep(1) }} />
        </div>
        <div className="WhatSappBtn">
          <button className="whatsappBtn" onClick={() => window.open(process.env.REACT_APP_WHATSAPP_LINK, '_blank')}>
            <span className="WpIcon">
              <FaWhatsapp />
            </span>{" "}
            contact support
          </button>
        </div>
      </div>

      <div className="buildYourWebWrapper">
        <div className="buildYourContentWrapper">
          <h2>Build your website</h2>
          <p>
            Your very own website in under 5 minutes with integrated booking and
            calendar system syncing direct to your Faces account.
          </p>

          <div className="buildYourImgWrapper desktop-None">
            <img src={BuildImg} alt={BuildImg} srcset={BuildImg} />
          </div>

          <ul>
            <li className={active == 1 ? "active" : ""} onClick={() => setActive(1)}>
              <div className="pointIconHeader">
                <span className="pointIcon">
                  <img
                    src={BuildfreeIcon}
                    alt={BuildfreeIcon}
                    srcset={BuildfreeIcon}
                  />
                </span>
                <h5>Build your own for FREE</h5>
              </div>
              <span className="pointContentWrapper">
                <p>
                  You can build your own  website via faces and go-live today{" "}
                </p>
              </span>
            </li>
            {/* <li>
              <div className="pointIconHeader">
                <span className="pointIcon">
                  <img src={AiIcon} alt={AiIcon} srcset={AiIcon} />
                </span>
                <h5>Generate Via Ai </h5>
              </div>
              <span className="pointContentWrapper">
                <p>its not pretty its not perfect but it gets the job done </p>
              </span>
            </li> */}
            <li className={active == 2 ? "active" : ""} onClick={() => setActive(2)}>
              <div className="pointIconHeader">
                <span className="pointIcon">
                  <img
                    src={customeBuild}
                    alt={customeBuild}
                    srcset={customeBuild}
                  />
                </span>
                <h5>Custom build from £299</h5>
              </div>

              <span className="pointContentWrapper">
                <p>
                  Want something bespoke, try our custom build with your very
                  own tech team for £34.99/per month
                </p>
              </span>
            </li>
          </ul>
          <div className="bottomViewMore">
            <span className="leftContent">
              <p>
                Want to see how it could look? <br /> View our sister company
                website here
              </p>
            </span>
            <span className="RightContent" onClick={() => window.open('https://kanvasmedicalgroup.com/', '_blank')}>View example website</span>
          </div>
          <StepButton
            onClick={onSubmit}
            isLoading={detailsLoading || packageLoading}
            blue={true}
            disabled={active == 0}
            label="Let’s create"
            icon={<FiChevronRight className="arrow_next" />}
          />
        </div>
        <div className="buildYourImgWrapper mobile-None">
          <img src={BuildImg} alt={BuildImg} srcset={BuildImg} />
        </div>
      </div>
    </div>
  );
}

export default SelectProcess;
