import { gql, useLazyQuery } from "@apollo/client";

const GET_LOCATION_FIXED_HOURS_AVAILAIBILITY_QUERY = gql`
query GetLocationFixedHoursAvailability($locationId: Int) {
  getLocationFixedHoursAvailability(locationId: $locationId) {
    success
    message
    data {
      locationId
      timings {
        day
        title
        isAvailable
        startTime
        endTime
        noOfBreaks
      }
    }
  }
}
`;

export const GetLocationFixedHoursAvailabilityAction = () => {
    // const dispatch = useDispatch();
    const [
        GetLocationFixedHoursAvailabilityResponseFunction,
        getLocationFixedHoursAvailabilityResponseData,
    ] = useLazyQuery(GET_LOCATION_FIXED_HOURS_AVAILAIBILITY_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getLocationFixedHoursAvailability: responseData }) => {
            if (responseData.success) {
                // dispatch({
                //   type: GET_RATING_REVIEWS_CONST,
                //   payload: responseData
                // })
            } else {
            }
        },
    });
    //this will be called from components
    const getLocationFixedHoursAvailabilityQueryData = getLocationFixedHoursAvailabilityResponseData; //variable

    const initGetLocationFixedHoursAvailabilityData = (data, successCallback, errorCallback) => {
        GetLocationFixedHoursAvailabilityResponseFunction({
            variables: data,
        }).then((res) => {
            if (res?.data?.getLocationFixedHoursAvailability?.success)
                successCallback(res.data.getLocationFixedHoursAvailability)
        }).catch((err) => {
            errorCallback(err)
        });
    };

    return { getLocationFixedHoursAvailabilityQueryData, initGetLocationFixedHoursAvailabilityData };
};