import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as BackArrow } from "../../../images/back_arrow.svg";
import { ReactComponent as Uploads } from "../../../images/Image.svg";
import image from "../../../images/analysis 1.png";
import whatsappIcon from '../../../images/whatsappwhite.svg'
import "./finance-training-certificate.scss";
import { useDropzone } from "react-dropzone";
import { config } from "../../../config";
import { FinanceInsuranceRoute, FinanceIdentityRoute } from "../../../Routes";
import ConfirmationModal from "../finance-identity/finance-confirmationModal";
import FinanceHeader from "../../finance-registration/FinanceHomeComponent/FinanceHeaderComponent/FinanceHeaderComponent";
import FinanceBanner from "../../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner";
import StepButton from "../../common/StepButton";
import { IoEyeSharp } from "react-icons/io5";
import CustomModal from "../../modal/CustomModal";
import Dropzone from "../../dropzone/dropzone";
import PropTypes from "prop-types";
import { getCountries, getCountryCallingCode, } from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";
import { SaveFinaceTraningCertificateAction } from "../../redux/actions/finance-registrtion/saveFinanceTranningCertficate"
import { GetFinanceTraningCertificateAction } from "../../redux/actions/finance-registrtion/getFinanceTraningCertificate"
import { DeleteFinanceTranningCertificateAction } from "../../redux/actions/finance-registrtion/deleteFinanceTraningCertificate"
import { ToastContainer, toast } from 'react-toastify';
import { GetFinanceRegistrationStatusAction } from "../../redux/actions/finance-registrtion/getFinanceRegistartionStatus"

export default function FinanceTrainingCertificate({ onNextStep, afterSubmit, type = "training", finish, setComeFromDashBord}) {

  const WhatsappLinkRedirect = config.FINANCE_WHATSAPP_LINK;
   const TraningWhatsAppLink=config.TRANNING_WHATSAPP_LINK
   
  const navigate = useNavigate();

  useEffect(()=>{
    window.scrollTo(0, 0);
   },[])

  const [otherDocs, setOtherDocs] = useState([
    {
      image: "",
      certificate_title: "",
      certificate_url: "",
      // id: null

    },
    {
      image: "",
      certificate_url: "",
      certificate_title: "",
      // id: null
    },
    {
      image: "",
      certificate_url: "",
      certificate_title: "",
      // id: null
    },
  ]);
  const { saveFInanceTraningCertificateQueryData, initSaveFinanceTraningCertificateData } = SaveFinaceTraningCertificateAction()
  const { getFinanceTranningCertificateQueryData, initGetFinanceTranningCertificateData } = GetFinanceTraningCertificateAction()
  const { deleteFinanceTranningCertificateQueryData, initDeleteFinanceTranningCertificateData } = DeleteFinanceTranningCertificateAction()
  const [open, setOpen] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState("")
  const [getApiData, setGetApiData] = useState([])
  const [previewImg, setPreviewImg] = useState(false);
  const [isDocError, setIsDocError] = useState(false)
  const [isData, setIsData] = useState(false)
  const { data } = getFinanceTranningCertificateQueryData;
  console.log("data1", data?.getTrainingCertificatesForFinance?.data?.certificates
  );

  const { getFinanceRegistrtionStatusQueryData, initGetFinanceRegistrationData } = GetFinanceRegistrationStatusAction()
  useEffect(() => {
    initGetFinanceRegistrationData()
  }, [])

  const completedStep = getFinanceRegistrtionStatusQueryData?.data?.getUserRegistrationStatusForFinance?.data;
  console.log("completedStep2", completedStep);

  useEffect(() => {
    initGetFinanceTranningCertificateData()
  }, [])


  useEffect(() => {

    const value = data?.getTrainingCertificatesForFinance?.data;
    console.log("valueDoc", value?.certificates)
    if (data?.getTrainingCertificatesForFinance?.data) {
      // if (value?.certificates?.length> 0 && Array.isArray(value?.certificates)) {
      //   setOtherDocs(value?.certificates);
      // }
      setOtherDocs(value?.certificates);
    }
  }, [data?.getTrainingCertificatesForFinance?.data?.certificates])

  console.log("otherDocddddd", otherDocs)

  useEffect(() => {
    if (otherDocs?.length < 1) {
      const emptyObjectsToAdd = 1 - otherDocs?.length;
      const emptyObjectsArray = Array.from(
        { length: emptyObjectsToAdd },
        () => ({})
      );
      setOtherDocs((prevOtherDocs) => [...prevOtherDocs, ...emptyObjectsArray]);
    }
  }, [otherDocs]);

  console.log("ohter", otherDocs)
  const handleContinue = () => {


    let certificate
    {
      certificate = otherDocs.filter((file) => {

        if (file.image || file.id) {
          return (true)
        }
      }).map(file => {
        if (type == "training") {
          return {
            photoId: file.id ? file.id : 0,
            photoTitle: file.certificate_title,
            photo: file.image,
          }
        }
        else {
          return file.image
        }
      })
    }

    const invaildDocs = otherDocs?.filter(
      (doc) => (doc?.image || doc?.certificate_url) && !doc?.certificate_title);

    const hasDocument = otherDocs && otherDocs?.some((doc) => doc && doc?.image || doc?.certificate_url)

    if (!hasDocument) {
      //  setErrorMsg("Please upload tranning docs")
      toast.error("Please upload tranning docs")
      return;
    }
    // else if (invaildDocs?.length > 0) {
    //   toast.error("Please add a title for all documents!")
    //   return
    // } 
    else {
      initSaveFinanceTraningCertificateData(
        {
          certificateFiles: certificate
        }, (data) => {
          toast.success(data?.data?.saveTrainingCertificatesForFinance?.message)
          afterSubmit();
          onNextStep();
          setComeFromDashBord(false)
        },
        (error) => {
          console.log(error)
        }
      );
    }
  }

  console.log("oDoc", otherDocs)
  const handleTitleChange = (e, index) => {
    const { name, value } = e.target;
    setOtherDocs((prevOtherDocs) => {
      const updatedDocs = [...prevOtherDocs];
      updatedDocs[index] = { ...updatedDocs[index], certificate_title: value };
      console.log("updatedDocs", updatedDocs)
      return updatedDocs;
    });
  };

  const handleAddDropzone = () => {
    if (otherDocs?.length < 10) {
      const nextDocIndex = otherDocs?.length + 1;

      const newDocumentState = {
        image: "",
        certificate_url: "",
        certificate_title: "",
      };
      setOtherDocs((prevOtherDocs) => [...prevOtherDocs, newDocumentState]);
      setIsData(true)
    }
  };




  console.log("getapi", getApiData)
  console.log("data",)
  console.log("otherDocs", otherDocs)

  return (
    
    <div className="main_training_certificate">
    {/* <FinanceHeader knowmore={false} isTranningLink={true}   /> */}
      <div className="mainCertificatePagesecWrapper">
        <div className="whatsappwrapper">
          <div className="training-content">
            <FinanceBanner
              title="Training details"
              desc="We now need to know about where you were trained and what you are trained in."
              isBorder={!completedStep?.registration_complete && completedStep?.registration_complete !== undefined}
              currentStep={finish}
              totalSteps={4}
              isTranningBanner={true}
           
              
            />
            {/* <div className="TrainingCardWrapper d-block d-md-none mt-3">
              <div className="cardIcon"><img src={image} className="me-1" />Need more Training ?</div>
              <div className="CardContent">
                <p>
                  Speak to our partnering training academy to upskill. Get 50% off
                  with code <b>‘Faces50’</b>
                </p>
                <button
                  className="w-100 btn border-0 text-white shadow-none"
                  style={{
                    borderRadius: "100px",
                    background:
                      "linear-gradient(180deg, #70FD78 -34.31%, #3FB42E 100%)",
                  }}
                  onClick={() => {
                    console.log("traningLink",TraningWhatsAppLink)
                    window.open(TraningWhatsAppLink, "_blank");
                  }}
                >
                  <img src={whatsappIcon} width={20} className="me-1" />
                  Whatsapp us
                </button>
              </div> */}
              {/* <div className="p-4">
                      <h3>
                        
                      </h3>
                    
                      <button
                        className="w-100 btn border-0 text-white shadow-none"
                        style={{
                          borderRadius: "100px",
                          background:
                            "linear-gradient(180deg, #70FD78 -34.31%, #3FB42E 100%)",
                        }}
                        onClick={() => {
                          window.open(WhatsappLinkRedirect, "_blank");
                        }}
                      >
                        <img src={whatsappIcon} width={20} className="me-1"/>
                        Whatsapp us
                      </button>
                  </div> */}

              {/* <div className="container">
                    <div className="row flex-column align-items-center justify-content-center">
                      <div className="col-lg-7 mb-5">
                        <h1>Training details</h1>
                        <p>
                          Evidence of your certifications and training, can authenticate
                          your account.
                        </p>
                      </div>
                      <div className="col-lg-7">
                        <p className="doc_label">Training details</p>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            borderRadius: "20px",
                            border: "1px dashed #878787",
                            height: "160px",
                            color: "#878787",
                            position: "relative",
                          }}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          {uploadedImage && (
                            <>
                              <img
                                src={uploadedImage}
                                alt="Uploaded"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  objectFit: "cover",
                                  borderRadius: "20px",
                                  padding: "20px",
                                }}
                              />
                            </>
                          )}
                          {!uploadedImage && (
                            <div className="d-flex flex-column align-items-center">
                              <Uploads />
                              <p>Upload</p>
                            </div>
                          )}
                        </div>
                        {uploadedImage && (
                          <p
                            className="mb-0 mt-2"
                            onClick={()=>setOpen(true)}
                            style={{ cursor: "pointer" }}
                          >
                            Remove
                          </p>
                        )}
                        {errorMessage && (
                          <p className="text-danger mb-0 mt-2">{errorMessage}</p>
                        )}
                      </div>
                      <div className="col-lg-7 mt-5">
                        <button
                          className="w-100 btn btn-primary shadow-none continuebtn"
                          style={{ borderRadius: "100px", backgroundColor: "#1B4FE7" }}
                          onClick={handleContinue} 
                        >
                          Continue
                        </button>
                      </div>
                      <div className="col-lg-7 mb-5">
                      <div
                        className="p-4"
                        style={{
                          boxShadow: "0px 0px 0px 1px #DDD inset",
                          borderRadius: "20px",
                        }}
                      >
                        <h3>
                          <img src={image} className="me-1"/>Need more Training ?
                        </h3>
                        <p style={{ width: "90%" }}>
                          Speak to our partnering training academy to upskill. Get 50% off
                          with code <span style={{ fontWeight: "700" }}>‘Faces50’</span> .
                        </p>
                        <button
                          className="w-100 btn border-0 text-white shadow-none"
                          style={{
                            borderRadius: "100px",
                            background:
                              "linear-gradient(180deg, #70FD78 -34.31%, #3FB42E 100%)",
                          }}
                          onClick={() => {
                            window.open(WhatsappLinkRedirect, "_blank");
                          }}
                        >
                          <img src={whatsappIcon} width={20} className="me-1"/>
                          Whatsapp us
                        </button>
                      </div>
                      </div>
                      
                    </div>
                  </div> */}
            {/* </div> */}
            <div>

              <div className="Certificate-docmentation-Wrapper">
                <div className="form-label">Training certificate</div>
                <div className="docmentationGrid-Wrapper">

                  {
                    (otherDocs.map((doc, index) => (
                      <>
                        <div className="docmentationGridItem" key={index}>
                          <Dropzone
                            handleMulti={setOtherDocs}
                            selectedIndex={index}
                            urlFromDB={doc.certificate_url}
                            handleDelete={initDeleteFinanceTranningCertificateData}
                            usrVrfId={doc.id}
                            otherDocs={otherDocs}
                            imageFromComponent={doc.image}
                          />
                          <input
                            name={`doc_name${index + 1}`}
                            value={doc.certificate_title}
                            onChange={(e) => handleTitleChange(e, index)}
                            placeholder="Certificate title"
                            className="inputTag"
                          //  onClick={()=>DeleteTitle(doc.id)}

                          />
                        </div>
                      </>
                    )))
                  }

                  <div className="docmentationGridItem">
                    {/* {
                        isData ? ( */}
                      {
                        (otherDocs?.length < 10) && (
                          <div
                           className="dropzone"
                            onClick={handleAddDropzone}
                              >
                            <Uploads />
                           <p>Add photo </p>
                           </div>
                        )
                      }
                   
                    {/* <input name="Add" placeholder="Description" /> */}
                  </div>
                </div>
              </div>

              {
              ErrorMsg && (
                <span className="error">{ErrorMsg}</span>
              )
              }


            </div>
            <div className="third-flex-div">
              <StepButton label={"Continue"}
                blue={true}
                isLoading={saveFInanceTraningCertificateQueryData?.loading}
                onClick={handleContinue} />
            </div>
          </div>
        </div>


     


        {/* <div className="Certificate-UploaderWrapper">
              <p className="form-label">Training details</p>
              <div className="customeDropDownPreviewWrapper">
                <div className="customeDropDownWrapper" {...getRootProps()} >
                  <input {...getInputProps()} />
                  {uploadedImage && (
                    <>
                      <img
                        src={uploadedImage}
                        alt="Uploaded"
                      />
                    </>
                  )}
                  {!uploadedImage && (
                    <div className="UploadIcon">
                      <Uploads />
                      <p>Upload</p>
                    </div>
                  )}
                </div>
                {uploadedImage && 
                    <div className="PreviewImg">
                          <IoEyeSharp onClick={()=> {setPreviewImg(true)}} />
                    </div>
                  }
              </div>
              {errorMessage && (
                <p className="error">{errorMessage}</p>
              )}
              

              <div className="third-flex-div">
                  <StepButton label={"Continue"} blue={true} onClick={handleContinue}  />
              </div>
            </div> */}



      </div>

      {/* Modle Previw*/}
      <CustomModal
        modalOpen={previewImg}
        setModalOpen={setPreviewImg}
        type="review"
        className={"previewCertificateImgModel"}
        modalBody={
          <>
            <div className='previewImgModelWrapper'>
              {/* <img
                src={uploadedImage}
                alt="Uploaded"/> */}
            </div>

            <div className="RemovePreviewImg">
              <button className="OutLineBtn" onClick={() => { setOpen(true) }}>Remove</button>
            </div>
          </>
        }
        modaltitle="How it works?"
      />

      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        title="Are you sure?"
        className={"ConfirmationCertificateDeleteModal"}
        content="Here is some content to complement the title"
        onConfirm={() => {
          // removeImage();
          // setOpen(false);
          // setPreviewImg(false);
        }}
        onCancel={() => setOpen(false)}
      />
    </div>
  );
}
