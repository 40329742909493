export const GET_APPOINTMENT_LIST = "GET_APPOINTMENT_LIST";
export const GET_APPOINTMENT_LIST_SUCCESS = "GET_APPOINTMENT_LIST_SUCCESS";
export const GET_APPOINTMENT_LIST_ERROR = "GET_APPOINTMENT_LIST_ERROR";

export const GET_APPOINTMENT_DETAILS = "GET_APPOINTMENT_DETAILS";
export const GET_APPOINTMENT_DETAILS_SUCCESS = "GET_APPOINTMENT_DETAILS_SUCCESS";
export const GET_APPOINTMENT_DETAILS_ERROR = "GET_APPOINTMENT_DETAILS_ERROR";

export const CLEAR_APPOINTMENT_STATUS = "CLEAR_APPOINTMENT_STATUS";

export const APPOINTMENT_LIST_LOADER_START = "APPOINTMENT_LIST_LOADER_START";
export const APPOINTMENT_LIST_LOADER_COMPLETE = "APPOINTMENT_LIST_LOADER_COMPLETE";

export const CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT"
export const CANCEL_APPOINTMENT_SUCCESS = "CANCEL_APPOINTMENT_SUCCESS"
export const CANCEL_APPOINTMENT_ERROR = "CANCEL_APPOINTMENT_ERROR"
export const CLEAR_CANCEL_APPOINTMENT_STATUS = "CLEAR_CANCEL_APPOINTMENT_STATUS"

export const CLEAR_APPOINTMENT_LIST = "CLEAR_APPOINTMENT_LIST"
export const CHANGE_LOADED_APPOINTMENT_COUNT = "CHANGE_LOADED_APPOINTMENT_COUNT"

export const SET_APPOINTMENT_DOCUMENT_TAB_DATA = "SET_APPOINTMENT_DOCUMENT_TAB_DATA"
export const SET_APPOINTMENT_MANAGE_TAB_DATA = "SET_APPOINTMENT_MANAGE_TAB_DATA"

export const CLEAR_APPOINTMENT_DOCUMENT_TAB_DATA = "CLEAR_APPOINTMENT_DOCUMENT_TAB_DATA"
export const CLEAR_APPOINTMENT_MANAGE_TAB_DATA = "CLEAR_APPOINTMENT_MANAGE_TAB_DATA"

export const GET_APPOINTMENT_NOTE = "GET_APPOINTMENT_NOTE"
export const CLEAR_APPOINTMENT_NOTE = "CLEAR_APPOINTMENT_NOTE"

export const GET_TREATMENT_WISE_BEFORE_AFTER_COUNT = "GET_TREATMENT_WISE_BEFORE_AFTER_COUNT";
export const CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT = "CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT";

