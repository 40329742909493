import { CLEAR_APPOINTMENT_FOLLOW_UPS, GET_APPOINTMENT_FOLLOW_UPS } from "../../constants/appointment/appointmentConst";



const initialState = {
  appointmentFollowUps: []
}

export default function appointmentFollowUpsReducer(state = initialState, action) {

  switch (action.type) {

    case GET_APPOINTMENT_FOLLOW_UPS:
        console.log("reducer akash",action.payload)
      return {
        ...state,
        appointmentFollowUps: action.payload,
      };

    case CLEAR_APPOINTMENT_FOLLOW_UPS:

      return {
        ...state,
        appointmentFollowUps: []
      }

    default:
      return state;
  }



}