import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const SAVE_WEBSITE_COLORS_MUTATION = gql`
mutation SaveWebsiteColors($pwcPrimaryColor: String, $pwcSecondaryColor: String, $pwcTertiaryColor: String) {
    saveWebsiteColors(pwc_primary_color: $pwcPrimaryColor, pwc_secondary_color: $pwcSecondaryColor, pwc_tertiary_color: $pwcTertiaryColor) {
      success
      message
    }
  }
`;

export const SaveWebsiteColors = () => {
  const [saveWebsiteColorsRequest, saveWebsiteColorsResponseData] =
    useMutation(SAVE_WEBSITE_COLORS_MUTATION);
  const dispatch = useDispatch();
  const saveWebsiteColorsMutationData = saveWebsiteColorsResponseData;

  const initiateSaveWebsiteColors = (
    data,
    successCallback,
    errorCallback
  ) => {
    saveWebsiteColorsRequest({
      variables: data,
    })
      .then(({ data: { saveWebsiteColors: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateSaveWebsiteColors, saveWebsiteColorsMutationData };
};
