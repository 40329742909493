import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { GET_FACES_AI_ASSISSTANT_STATUS } from "../../constants";

const GET_FACES_AI_ONBOARDING_STATUS_QUERY = gql`
query GetUserGoHighLevelOnboardingStatus {
  getUserGoHighLevelOnboardingStatus {
    success
    message
    data {
      is_onboarded
      faces_ai_integration_link
      faces_ai_conversation_link
    }
  }
}`;

export const GetFacesAiOnboardingStatusAction = () => {
  const dispatch = useDispatch();
  const [GetFacesAiOnboardingStatus, onBoardingStatusData] = useLazyQuery(
    GET_FACES_AI_ONBOARDING_STATUS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getUserGoHighLevelOnboardingStatus: responseData }) => {

        if (responseData.success) {
          dispatch({
            type: GET_FACES_AI_ASSISSTANT_STATUS,
            payload: responseData?.data,
            // page
          });

        } 
      },
    }
  );
  const userFacesAiStatus = onBoardingStatusData;
  const initOnboardingCheck = (data) => {
    GetFacesAiOnboardingStatus({
      variables: data,
    });
  };
  return { initOnboardingCheck, userFacesAiStatus };
};



