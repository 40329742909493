import React, { useEffect, useRef, useState } from "react";
import "./custom-modal.scss";
import Modal from "react-modal";
import modalClose from "../../images/icons/Back.svg";

import moment from "moment";
import { click } from "@testing-library/user-event/dist/click";
import gsap from "gsap";
import { useDispatch, useSelector } from "react-redux";
import { clearRenewInsuranceStatus } from "../redux/actions";

const CustomModal = ({
    modalOpen,
    setOpenRenewPopup,
    setModalOpen,
    setShowResendAlert,
    modaltitle,
    modalBody,
    removePadding,
    addButton,
    type,
    clickDate,
    setIsOpenMobileCalendar,
    modaltitleCSS,
    className,
    modalCloseCallback,
    isCloseImage = true,
    isManuallyClose = false,
    modelDesc,
    modelDescShow,
    modelDescIsHtml = false,

    portalClassName = '',
}) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const closeModal = () => {
        setModalOpen(false);
        setOpen(false);
        if (setIsOpenMobileCalendar) setIsOpenMobileCalendar(false);
    };

    useEffect(() => {
        setOpen(modalOpen);
    }, [modalOpen]);

    return (
        <>
            <Modal
                portalClassName={`ReactModalPortal ` + portalClassName}
                // overlayClassName={``+ className + `-overlay`}
                className={className + ` custom-modal-main-div ${(type == "resend" || type === "renew") && "w-25"
                    }${(type == "review-supoort-ticket" || type === "review-supoort-ticket" || type === 'training-centerificate' || type === 'insurance-document') && "w-50"} 
                    ${(type == "review" || type === 'forward-finance-application') && "w-75"} ${removePadding && "remove-padding"} ${type === "close-time" && "w-50"
                    } ${type === "common-width" && "common-width-cls"} `}
                // isOpen={modalOpen}
                isOpen={open}
                // shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                onRequestClose={() => {
                    if (!isManuallyClose)
                        closeModal();
                    if (modalCloseCallback) {
                        modalCloseCallback()
                    }
                }}
                closeTimeoutMS={200}
                ariaHideApp={false}
                style={{
                    overlay: { zIndex: 1000 },
                }}
            >
                <div
                    className={`modal-body-container-main-div container ${removePadding && "px-0"
                        }`}
                >
                    <div className={``}>
                        <div
                            className={`modal-header-div ${type === "resend" || type === "renew" ? "text-center" : ""
                                }`}
                        >
                            <div className='modelHeaderWrapper'>
                                {
                                    modaltitleCSS ? <h1 className={modaltitleCSS}>
                                        {modaltitle}
                                    </h1> :
                                        <h5
                                            className={`modal-title ${type === "resend" || type === "renew" ? "m-auto" : ""
                                                }`}
                                        >
                                            {modaltitle}
                                        </h5>
                                }
                                {modelDescShow ? modelDescIsHtml ? <>{modelDesc}</> : <p>{modelDesc}</p> : null}
                            </div>
                            {!(type === "resend" || type === "renew") && (
                                <div className={`${type === "no-header" ? 'd-none' : 'd-flex justify-content-between gap-2'}`}>
                                    <button
                                        className={`modal-close-icon close-icon-animation`}
                                        onClick={() => {
                                            if (!isManuallyClose)
                                                closeModal()
                                            if (modalCloseCallback) {
                                                modalCloseCallback()
                                            }
                                        }}
                                    >
                                        <img src={modalClose} alt="modalClose33" />
                                    </button>
                                </div>
                            )
                            }
                        </div >
                    </div >
                    <div className={`dynamic-content`}>{modalBody}</div>
                    {
                        (type === "resend" || type === "renew") && (
                            <div className="ok-button mt-4 text-center">
                                <button
                                    onClick={() => {
                                        if (type === "resend") {
                                            setShowResendAlert(false);
                                        } else {
                                            setOpenRenewPopup(false);
                                            dispatch(clearRenewInsuranceStatus());
                                        }
                                    }}
                                >
                                    Ok
                                </button>
                            </div>
                        )
                    }
                </div >
            </Modal >
        </>
    );
};

export default CustomModal;