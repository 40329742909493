import { CLEAR_APPOINTMENT_LIST_DATA, GET_APPOINTMENT_LIST_DATA, SAVE_CALENDAR_DAILYVIEW_DATA } from "../../constants/appointment/appointmentConst";


const initialState = {
  appointmentList: [],
  dailyViewData: null
}

export default function appointmentListReducer(state = initialState, action) {

  switch (action.type) {

    case GET_APPOINTMENT_LIST_DATA:

      return {
        ...state,
        appointmentList: action.payload,
      };

    case CLEAR_APPOINTMENT_LIST_DATA:

      return {
        ...state,
        appointmentList: []
      }

    case SAVE_CALENDAR_DAILYVIEW_DATA:
      console.log(" action.payload", action.payload)

      return {
        ...state,
        dailyViewData: action.payload
      }

    default:
      return state;
  }



}