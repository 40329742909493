// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { APPOINTMENT_LIST_LOADER_COMPLETE, GET_APPOINTMENT_LIST, GET_APPOINTMENT_LIST_SUCCESS, GET_ONBOARDING_STATUS_SUCCESS } from "../../constants";

const GET_USER_ONBOARDING_STATUS_QUERY = gql`
  query GetUserOnboardingStatus($requestFrom: String) {
    getUserOnboardingStatus(request_from: $requestFrom) {
      success
      message
      data {
        steps {
          key
          title
          description
          is_completed
          is_accessible
        }
        occupation_list {
          category
          occupations {
            occupation_id
            occupation_name
            occupation_type
            pin_status
          }
        }
        onboarding_completion_percentage
        onboarding_complete
        is_occupation_added
        usr_data {
          country_code
          phone_number
          iso_code
          occupation
          occupations
        }
        total_completed_steps_web
        total_completed_steps_app
        user_onboarding_link
      }
    }
  }
`;

export const GetUserOnboardingStatusAction = () => {
  const dispatch = useDispatch();
  const [
    getUserOnboardingStatusResponseFunction,
    getUserOnboardingStatusResponseData,
  ] = useLazyQuery(GET_USER_ONBOARDING_STATUS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getUserOnboardingStatus: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: GET_ONBOARDING_STATUS_SUCCESS,
          payload: responseData,
        });
        console.log('dispatched GetUserOnboardingStatusAction', responseData);
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getUserOnboardingStatusQueryData = getUserOnboardingStatusResponseData; //variable

  const initGetUserOnboardingStatusData = (data) => {
    getUserOnboardingStatusResponseFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.getUserOnboardingStatus?.success) {
        console.log('res success', res);
        // successCallback(res);
      } else {
        console.log('res error', res);
        // errorCallback(res);
      }
    }).catch((err) => {
      console.log('res err', err);
      // errorCallback(err);
    });
  };

  return { getUserOnboardingStatusQueryData, initGetUserOnboardingStatusData };
};
