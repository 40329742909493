import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const MANAGE_NOTIFICATION_SETTINGS_MUTATION = gql`
mutation ManageNotificationSettingsFromProfile($settings: [EmailSettingInputType]) {
  manageNotificationSettingsFromProfile(settings: $settings) {
    success
    message
    settings {
      email {
        value
        name
      }
      prescriptionEmail {
        value
        name
      }
      socialMedia {
        value
        name
      }
    }
  }
}
`;

export const ManageNotificationSettings = () => {
  const [manageNotificationSettingsRequest, manageNotificationSettingsResponseData] =
    useMutation(MANAGE_NOTIFICATION_SETTINGS_MUTATION);
  const dispatch = useDispatch();
  const manageNotificationSettingsMutationData = manageNotificationSettingsResponseData;

  const initiateManageNotificationSettings = (
    data,
    successCallback,
    errorCallback
  ) => {
    manageNotificationSettingsRequest({
      variables: data,
    })
      .then(({ data: { manageNotificationSettingsFromProfile: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        // dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };

  return { initiateManageNotificationSettings, manageNotificationSettingsMutationData };
};
