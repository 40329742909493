import React from 'react'
import Skeleton from 'react-loading-skeleton'
import CarouselComponent from '../../pages/carousel-component/carousel-component'
import { Rating } from 'react-simple-star-rating'

const BusinesHealthSkeleton = () => {
    return (
        <div className='business-health-skeleton-main-div pe-none' >
            <div className="business-health-container mb-5">
                <div className="mb-4">
                    <h2>Business health</h2>
                    <Skeleton width={"90%"} height={20} />
                    {/* <p>Ranked #{dashboardListData?.reviewRank} out of {dashboardListData?.totalPractitionersForReview} businesses.</p> */}
                </div>
                <CarouselComponent nav data={
                    <>
                        {
                            [...Array(3)].map((item) => {
                                return (
                                    <div className="business-review-card" >

                                        <div className="written-reviews-container">
                                            <p className="business-reviews">
                                                <Skeleton className="mb-1" height={"15%"} width={"100%"} />
                                                <Skeleton className="mb-1" height={"15%"} width={"100%"} />
                                                <Skeleton className="mb-1" height={"15%"} width={"100%"} />
                                                <Skeleton className="mb-1" height={"15%"} width={"100%"} />
                                            </p>
                                        </div>
                                        <div className="d-flex gap-2 mb-2">
                                            <div >
                                                <Rating
                                                    size={16}
                                                    disableFillHover={true}
                                                    allowHover={false}
                                                    initialValue={item?.review_rating}
                                                    readonly={true}
                                                    fillColor="#000000"
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mt-2">
                                            <Skeleton height={20} width={100} />
                                        </div>
                                    </div>
                                )
                            }
                            )
                        }
                    </>
                } />

            </div>
        </div >
    )
}

export default BusinesHealthSkeleton