import { createRef, useEffect, useRef, useState } from "react";

function useScrollTab(categories) {
  // Refs to reference section elements by ID
  let sectionRefs = useRef({});

  sectionRefs = categories?.reduce((acc, category) => {
    acc[category.category_id] = createRef();
    return acc;
  }, {});

  const [activeTab, setActiveTab] = useState(null);

  const handleTabClick = (categoryId) => {
    sectionRefs[categoryId].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleScroll = () => {
    for (const categoryId in sectionRefs) {
      const ref = sectionRefs[categoryId].current;
      if (ref) {
        if (ref.getBoundingClientRect().top <= 0) {
          setActiveTab(categoryId);
          
        }
      }
    }
    
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { sectionRefs, activeTab, handleTabClick ,handleScroll,setActiveTab};
}

export default useScrollTab;
