import React, { useEffect } from "react";
import BannerHeader from "../../common/BannerHeader";
import { ReactComponent as LocationIcon } from "../../../images/location_icon.svg";
import { ReactComponent as RightArrow } from "../../../images/right_arrow.svg";
import "./location-web.scss";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_TREATMENT_DETAILS } from "../../redux/constants/treatment-list-const/TreatmentListConst";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";
import { clinicCartPattern } from "../../../Routes";
import NoAppointmentAvailable from "../../noAppointmentAvailable/NoAppointmentAvailable";
import { GetNoAppointmentAVailableAction } from "../../redux/actions/checkNoAppointmentAvailable/CheckNoAppointmentAvailable";
import { Helmet } from 'react-helmet';
import InfiniteScroll from "react-infinite-scroll-component";



const LocationWeb = ({ setStep, locationData, isLoading, setClinicId, clinicId, selectedLocation, setSelectedLocation, businessName, isCalled, setSelectedTreatment, setShowSelectedDate, setFormatedDate, setShowSelectedTime, isAppointmentDataAvailable, isLoadingNoAppointment, isCalledLoadingNoAppointment, initiateBusinessClinicListApiCall }) => {

  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams();

  const { businessClinicList } = useSelector((state) => state?.businessLocationListReducer)
  const totalClinics = useSelector(
    (state) => state?.businessLocationListReducer?.totalClinics
  );
  const loadedClinicsCount = useSelector(
    (state) => state?.businessLocationListReducer?.loadedClinicsCount
  );



  const truncateString = (str) => {
    return str.length > 20 ? str.substring(0, 27) + "..." : str;
  }

  console.log(searchParams.get("clinic_id"))


  useEffect(() => {
    if (searchParams.get("clinic_id")) {
      locationData?.map((item, index) => {
        if (item.clinic_id == businessClinicList?.clinic_DB_id) {
          setSelectedLocation(item)
          setClinicId(item.clinic_id)
          dispatch({ type: CLEAR_TREATMENT_DETAILS })
          searchParams.delete("clinic_id")
          setSearchParams(searchParams)
          setStep(2)
        }
      })
    }
    if (locationData?.length == 1 && searchParams.get("clinic_id") == null && searchParams.get("isNotBypass") == null && isAppointmentDataAvailable) {
      setClinicId(locationData[0].clinic_id)
      setSelectedLocation(locationData[0])
      dispatch({ type: CLEAR_TREATMENT_DETAILS })
      setStep(2)
    }
  }, [locationData])

  useEffect(() => {
    // Handle the back button click event
    window.onpopstate = function (event) {
      if (window.location.search !== "") {
        window.history.go(-2)
      }

    };
  }, [])
  return (
    <div className="MainLocationPageWrapper">
      <Helmet>
        <meta name="X-Robots-Tag" content="noindex,nofollow" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {
        (!isLoading && !isLoadingNoAppointment && locationData == undefined && isCalled) ?
          <>

            <div className="h-50 d-flex justify-content-center align-items-center fw-bold fs-1 ">
              Invalid Link!
            </div>
          </>
          :
          <>

            <div className={"MainLocationScreen"}>
              <div className="location-container">
                {

                  isLoading && isLoadingNoAppointment ?
                    <>
                      <div className="w-50 me-auto">
                        <div className="w-100 me-auto">
                          <Skeleton width={"120%"} height={40} />
                          <Skeleton className="mt-2" width={"100%"} height={20} />
                        </div>
                        <SkeletonLoader type={"list"} showCircle={true} />
                      </div>
                    </>

                    :
                    isAppointmentDataAvailable ? (
                      <>
                        <BannerHeader companyName={`${businessName}`} subTitle={"What location would you like to visit?"} />
                        <div id="scrollableDiv" style={{ height: "70vh", width: "100%", overflowX: "scroll" }}>
                          <InfiniteScroll
                            dataLength={loadedClinicsCount}
                            next={() => {
                              initiateBusinessClinicListApiCall(false)
                            }}
                            hasMore={loadedClinicsCount < totalClinics}
                            scrollableTarget="scrollableDiv"
                          >
                            {
                              locationData?.map((item, index) => (
                                <div key={index} className="list-location-container" onClick={() => {
                                  setSelectedTreatment([])
                                  setShowSelectedDate(null)
                                  setFormatedDate(null)
                                  setShowSelectedTime(null)
                                  setSelectedLocation(item)
                                  searchParams.delete("isNotBypass")
                                  setSearchParams(searchParams)
                                  // Instead of setting clinicId, set the selectedLocation here.
                                  selectedLocation === item.clinic_id ? setClinicId(null) : setClinicId(item.clinic_id);
                                  dispatch({ type: CLEAR_TREATMENT_DETAILS })
                                  setStep(2);
                                }}>

                                  <div className="location-name">
                                    <div className="location-icon">
                                      <LocationIcon />
                                    </div>
                                    <div className="location-details cursor-pointer">
                                      <h1>{item?.clinic_name}</h1>
                                      {/* <p>{truncateString(`${item?.complete_address}`, 27)}</p> */}
                                      <p>{item?.complete_address}</p>
                                    </div>
                                  </div>

                                  <div className="cursor-pointer right-loc-arrow">
                                    <RightArrow />
                                  </div>
                                </div>
                              ))
                            }
                          </InfiniteScroll>
                        </div>
                      </>)
                      : null

                }

              </div>
            </div>
            {
              !isLoadingNoAppointment && !isAppointmentDataAvailable && isCalledLoadingNoAppointment && <NoAppointmentAvailable />
            }

          </>

      }
      {isAppointmentDataAvailable && <div className="location-footer">Powered by Faces</div>}
    </div>
  );
};

export default LocationWeb;