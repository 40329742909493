import "./walk-in-main.scss";
import { useNavigate } from "react-router";
import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from 'react'
import BackArrow from "../../images/back_arrow.svg";
import searcIcon from "../../images/search_icon.svg";
import { useDispatch, useSelector } from 'react-redux';
import rightArrow from "../../images/calander_next.svg"
import MedicalIcon from "../../images/medical-icon.svg";
import LeftArrow from "../../images/calander_previous.svg"
import { GetClinicServicesListAction } from '../redux/actions/walk-in-form/getClientList';
import { SET_WALK_IN_SERVICES_DATA } from '../redux/constants/walk-in-form-const/clientWalkInConst';
import StepButton from "../common/StepButton";
import NavHeader from "../common/NavHeader/NavHeader";

const ChooseServices = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedServices, setSelectedServices] = useState([]);
  const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
  const [currentPaginationLimit, setCurrentPaginationLimit] = useState(10);
  const [currentPaginationSearch, setCurrentPaginationSearch] = useState('');

  const { initGetClinicServicesListData, getClinicServicesListQueryData } = GetClinicServicesListAction();

  const selectedData = useSelector((state) => state?.walkInClientNameReducer?.clinicServicesList);
  const locationSeviceListData = useSelector(state => state?.walkInClientListReducer?.clinicServicesList)
  const locationClinicId = useSelector((state) => state?.walkInClientNameReducer?.clinicLocationClinicId);

  useEffect(() => {
    initGetClinicServicesListData({
      clinicId: locationClinicId,
      page: currentPaginationPage,
      limit: currentPaginationLimit,
      search: currentPaginationSearch,
    });
  }, [locationClinicId, currentPaginationPage, currentPaginationLimit, currentPaginationSearch, selectedData]);

  const itemsPerPage = 10;
  const totalItems = locationSeviceListData?.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleSearchLocationData = (e) => {
    setCurrentPaginationSearch(e.target.value);
    initGetClinicServicesListData({
      clinicId: locationClinicId,
      page: currentPaginationPage,
      limit: currentPaginationLimit,
      search: e.target.value,
    });
  }

  const nextArrow = () => {
    return (<img src={rightArrow} width={25} height={25} alt="rightArrow" />)
  }
  const prevArrow = () => {
    return (<img src={LeftArrow} width={25} height={25} alt="LeftArrow" />)
  }

  const handleSelectService = (service_name, service_id, service_price) => {
    const updatedSelectedService = [...selectedServices];
    const serviceIndex = updatedSelectedService.findIndex((item) => item?.treatmentName === service_name);
    if (serviceIndex !== -1) {
      updatedSelectedService.splice(serviceIndex, 1);
    } else {
      updatedSelectedService.push({ treatmentName: service_name, treatmentId: service_id, treatmentPrice: service_price });
    }
    setSelectedServices(updatedSelectedService);
    console.log(updatedSelectedService, 'updatedSelectedService==');
  };

  const handleBackWalkInPage = () => {
    dispatch({
      type: SET_WALK_IN_SERVICES_DATA,
      payload: selectedServices,
    });
    navigate("/walk-in-consent");
  };
  return (
    <div className="walk-in-consent-Main-form">
      <NavHeader isBackOnly={true} handleOnBack={handleBackWalkInPage} />
      <div className="walk-in-consent-page-main">
        {/* <div className="d-flex header-add-form">
          <div className="go-back-img" onClick={handleBackWalkInPage}>
            <img src={BackArrow} width={35} height={35} alt="zzz" />
          </div> */}
          {/* <div className="btn add-form-btn" onClick={handleBackWalkInPage}>
            <button>Done</button>
          </div> */}
        {/* </div> */}
        {/* ===== */}
        <div className="walk-in-consent-page">
          <div className='SectionHeading'>
            <div className="Section-Title">Services</div>
              <div className="search-tab">
                <img src={searcIcon} alt="search" />
                <input type="text" placeholder="Search name, email or phone" onChange={handleSearchLocationData} />
              </div>
           </div>
          {/* <div className="walk-in-consent-page-header">
            <h3>Services</h3>
            <div className="search-tab">
              <img src={searcIcon} alt="search" />
              <input type="text" placeholder="Search name, email or phone" onChange={handleSearchLocationData} />
            </div>
          </div> */}

          <div className="GridLocation">
            {locationSeviceListData ? (
              <>
                {locationSeviceListData?.treatments?.map((item, index) => {
                  return (
                    <div className="GridLocationItem" key={index}>
                      <div className='GridLocationItemLeftContent'>
                        <div className="location_profile_image">
                          <img src={MedicalIcon} width={30} height={30} alt=""  className="HandFormImg" />
                        </div>
                        <div className="user-details">
                          <p>{item?.treatmentName}</p>
                        </div>
                      </div>
                      <input className="black-checkbox"
                        onChange={() => handleSelectService(item?.treatmentName, item?.treatmentId, item?.treatmentPrice)}
                        checked={selectedData?.findIndex((selectedItem) => selectedItem?.treatmentId === item?.treatmentId) !== -1 ? true : false
                          || selectedServices?.findIndex((selectedItem) => selectedItem?.treatmentId === item?.treatmentId) !== -1 ? true : false}
                        type="checkbox"
                        style={{ background: "red" }} />
                    </div>
                  )
                })}
                <div className="form-btn">
                  <StepButton label={"2 Services selected"} blue={true} onClick={handleBackWalkInPage} />
                </div>
              </>
            ) :
              <div className='text-center mt-4'>
                <h6>No Services Found</h6>
              </div>
            }
            <ReactPaginate
              previousLabel={prevArrow()}
              nextLabel={nextArrow()}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(page) => setCurrentPaginationPage(page.selected + 1)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
              breakClassName={"pagination__break"}
              breakLinkClassName={"pagination__break-link"}
              pageClassName={"pagination__page"}
              pageLinkClassName={"pagination__page-link"}
            />

          </div>
        </div>
      </div>
    </div>
  )
}

export default ChooseServices
