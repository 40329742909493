// ========================COMPLETE BOOKING PAYMENT========================

import { gql, useMutation } from "@apollo/client";

const UPDATE_PLUM_MARKETING_PACKAGE_PAYMENT_MUTATION = gql`
mutation UpdateAccountancyPackagePayment($packageId: Int, $packageSlug: String, $orderId: Int, $intentId: String, $token: String, $stripeSavedCardId: String, $planUpgrade: Boolean, $userFname: String, $userLname: String, $userEmail: String, $userPhoneNumber: String) {
    updateAccountancyPackagePayment(packageId: $packageId, packageSlug: $packageSlug, orderId: $orderId, intentId: $intentId, token: $token, stripeSavedCardId: $stripeSavedCardId, planUpgrade: $planUpgrade, user_fname: $userFname, user_lname: $userLname, user_email: $userEmail, user_phone_number: $userPhoneNumber) {
      success
      message
    }
  }
 `;


export const UpdateAccountancyServicesPackagePaymentAction = () => {
    // const dispatch = useDispatch();
    const [
        UpdateAccountancyPackagePaymentResponseFunction,
        updateAccountancyPackagePaymentResponseData,
    ] = useMutation(UPDATE_PLUM_MARKETING_PACKAGE_PAYMENT_MUTATION)

    //this will be called from components
    const updateAccountancyPackagePaymentQueryData = updateAccountancyPackagePaymentResponseData; //variable

    const initUpdateAccountancyPackagePaymentData = (data, successCallback, errorCallback) => {
        UpdateAccountancyPackagePaymentResponseFunction({
            variables: data,
        })
        // .then(({ data: { PurchasePlumpMarketingPackage: responseData } }) => {
        .then((responseData) => {
            console.log(responseData);
            if (responseData.data.updateAccountancyPackagePayment.success) {
                // dispatch({
                //     type: SET_BOOKING_DETAILS,
                //     payload: responseData?.data
                // })
                successCallback(responseData)
                // toast.success(responseData.message)
            }
            else {
                errorCallback(responseData)
                // toast.error(responseData.message)
            }
        }).catch((err) => {
            errorCallback(err)
            // toast.error("something went wrong!!!")
        })
    }

    return { updateAccountancyPackagePaymentQueryData, initUpdateAccountancyPackagePaymentData };
};
