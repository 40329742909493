import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_VERIFIED_BUSINESS_BADGE } from "../../constants/verifiedBusinesBadge/verifiedBusinessBadge";


const GET_VERIFIED_BUSINESS_BADGE_QUERY = gql`
query GetVerifiedBusinessBadge($userToken: String) {
  getVerifiedBusinessBadge(user_token: $userToken) {
    success
    message
    data {
      is_verified
    }
  }
}
`;

// var page;
export const VerifiedBusinessBadgeQuery = () => {
  const dispatch = useDispatch();
  const [getVerifiedBusinessBadge, verifiedBusinessBadgeData] = useLazyQuery(
    GET_VERIFIED_BUSINESS_BADGE_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getVerifiedBusinessBadge: responseData }) => {

        dispatch({
          type: GET_VERIFIED_BUSINESS_BADGE,
          payload: responseData.data,
          // page
        });
        if (responseData.success) {

        } else {

        }


      },
    }
  );
  const verifiedBusinessBadgeQueryData = verifiedBusinessBadgeData;
  const initVerifiedBusinessBadge = (data) => {
    // page = data.page
    getVerifiedBusinessBadge({
      variables: data,
    });
  };
  return { initVerifiedBusinessBadge, verifiedBusinessBadgeQueryData };
};



