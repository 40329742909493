import React from "react";
import "./product-question-answer-skeleton.scss";
function CourseExamSkeleton() {
  return (
    <>
      <div className="skeleton-course-exam-main-div px-0 container-xxl">
        <div className="course-work-cards-div">
          <div className="course-work-card">
            <div className="question-no">
              <div className="d-flex justify-content-between my-3">
                <span className="question"></span>
                <span className="number"></span>
              </div>
              <p className="question-desc"></p>
            </div>

            <div className="answer-div">
              
            </div>

            <div className="submit-btn">
              <button className={"prev-btn" }></button>
                <button className="next-btn"></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseExamSkeleton;
