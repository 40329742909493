// import { GET_Hair_Products_Questions } from "../../constants/hair-prescription-product/HairProductConts";

import { SET_PAYMENT_INTENT } from "../../constants/hair-prescription-product/HairProductConts";



const initialState = {
    getPaymentIntent: null
}

export default function GetHairPrescriptionProductPaymentDetailsReducer(state = initialState, action) {

    switch (action.type) {

        case SET_PAYMENT_INTENT:
            return {
                ...state,
                getPaymentIntent: action.payload
            };

        default:
            return state;
    }

}