import { CLEAR_CONSENT_FORM_MORE_PRODUCT_LIST, GET_CONSENT_FORM_MORE_PRODUCT_LIST} from "../../constants/consentFormThankyou/consentFormProductConst"

const initialState = {
    moreProductList: [],
    emptyMoreProductList: false,
    totalMoreProductCount: 0,
    loadedMoreProductCount: 0,
    moreProductListPage: 0,
    morePrescriberDetails:null
}

export const consentFormMoreProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONSENT_FORM_MORE_PRODUCT_LIST:
            let temp = (state?.moreProductList).concat(action?.payload?.productList)
            console.log("data more akash product payload", action?.payload)
            return {
                ...state,
                moreProductList: temp,
                totalMoreProductCount: action.payload?.total,
                moreProductListPage: state.moreProductListPage + 1,
                loadedMoreProductCount: temp.length,
                emptyMoreProductList:action?.payload?.items?.length === 0 || action.payload == null ? true : false,
                morePrescriberDetails:action?.payload?.prescriberDetails
            }
        case CLEAR_CONSENT_FORM_MORE_PRODUCT_LIST:
            return {
                ...state,
                moreProductList: [],
                emptyMoreProductList: false,
                totalMoreProductCount:0,
                moreProductListPage:0,
                loadedMoreProductCount:0,
                morePrescriberDetails:null
            }
        default:
            return state
    }
}