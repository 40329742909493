import { gql, useMutation } from "@apollo/client";

const SEND_CSV_ATTACHMENT_MUTATION = gql`
  mutation SendCsvAttachment($clientFile: Upload!) {
    sendCsvAttachment(clientFile: $clientFile) {
      success
      message
    }
  }
`;

export const SendCsv = () => {
  const [sendCsvAttachmentMutation, sendCsvAttachmentResponseData] =
    useMutation(SEND_CSV_ATTACHMENT_MUTATION);

  const initiateSendCsvAttachment = (variables, successCallback, errorCallback) => {
    sendCsvAttachmentMutation({ variables })
      .then(({ data }) => {
        if (data.sendCsvAttachment.success) {
          successCallback(data.sendCsvAttachment);
        } else {
          errorCallback(data.sendCsvAttachment);
        }
      })
      .catch((error) => {
        // Handle error
        errorCallback(error);
      });
  };

  return { initiateSendCsvAttachment, sendCsvAttachmentResponseData };
};
