import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_HOME_COUNT_QUERY = gql`
  query GetHomeCount {
    getHomeCount {
      success
      message
      data {
        Professionals
        ConsentForms
        Treatments
      }
    }
  }
`;

export const GetHomeCountAction = () => {
  const [
    getHomeCountResponseFunction,
    getHomeCountResponseData,
  ] = useLazyQuery(GET_HOME_COUNT_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getHomeCount: responseData }) => {
      if (responseData.success) {
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });

  //this will be called from components
  const getHomeCountQueryData = getHomeCountResponseData; // updated variable name

  const initGetHomeCountData = (data) => {
    getHomeCountResponseFunction({
      variables: data,
    });
  };

  return { getHomeCountQueryData, initGetHomeCountData };
};
