import React, { useEffect, useRef, useState } from "react";
import "./business-header.scss";
import logo from "../../images/icons/faces-logo-black.svg";
import logoInsync from "../../images/icons/insync-logo.png";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import getProfileData, {
	logoutProfile,
} from "../redux/actions/profile/Profile";
import moment from "moment";
import { useNavigate } from "react-router";
// businessSettingRoutePattern

import {
	businessDashboardRoutePattern,
	getBlockOutTimeRoute,
	getBusinessSettingPageRoute,
	getNewAppointmentRoute,
	getPrescriberRegistrationPage,
	getShareBookingLinkRoute,
	verifiedBannerRoutePattern,
} from "../../Routes";
import Avatar from "react-avatar";
import CustomModal from "../modal/CustomModal";
import ReviwewPopup from "../review-popup";
import CustomAutoComplete from "../auto-complete/CustomAutoComplete";
import {
	forwardSendMailData,
	getClientsData,
	getProductsGetCartCountData,
} from "../redux/actions";
import getDashboardList from "../redux/actions/dashboard/dashboardList";
import getdashboardAppointmentListData from "../redux/actions/dashboard/appointments";
import { webBaseUrl, frontendURL } from "../../config";
import { TfiMenu } from "react-icons/tfi";
import { ScheduleReviewEmailAction } from "../redux/actions/dashboard/scheduleReviewEmailGql";
import { FORWARD_SENDMAIL, GET_CLIENTS_DATA } from "../redux/constants";
import { GetClientListsAction } from "../redux/actions/dashboard/getClientListGql";
import basketImg from "../../images/basket.png";
import AiIcon from "../../images/ai-icon.png";
import { MdVerified } from "react-icons/md";
import verifyIcon from "../../images/icons/verified.svg"
import YellowStar from '../../images/sprint6/Yellow_star.svg';
import { debounce } from "lodash";
import { GetProfileAction } from "../redux/actions/dashboard/getProfileDataGql";
import { GetUserDashboardDataAction } from "../redux/actions/dashboard/dashboardGql";
import { GetUserInsuranceDetailAction } from "../redux/actions/GetUserInsuranceDetails/GetUserInsuranceDetail"
import { Dropdown } from "react-bootstrap";
// import {GetUserInsuranceVerifiedAction} from "../redux/actions/faces-verified/faces-verified"
import { ReactComponent as DownArrowIcon } from '../../images/sidebarIcon/DownArrow.svg';
import { ReactComponent as RatingIcon } from '../../images/sidebarIcon/Rating.svg';
import { ReactComponent as ProfilesettingsIcon } from '../../images/sidebarIcon/Setting.svg';
import { ReactComponent as TraingIcon } from "../../images/headers/training-icon.svg"
import { ReactComponent as LogoutIcon } from '../../images/sidebarIcon/Logout.svg';

import { ReactComponent as NotiLogoIcon } from '../../images/sidebarIcon/notifiaction.svg';
import { ReactComponent as FacesVerifiedIcon } from "../../images/headers/faces-verified.svg"
import { ReactComponent as CloseIcon } from "../../images/headers/close-icon.svg"

import { useLocation } from 'react-router';
import { GetUserInsuranceVerifiedAction } from "../redux/actions/faces-verified/faces-verified";
import { HideBecomeFacesVerifiedBannerAction } from "../redux/actions/hide-become-faces-verified-banner/hideBecomeFacesVerifiedBanner";
import bellIcon from "../../images/icons/bell.svg";
import { ReactComponent as NewAppointmentIcon } from "../../images/business-setting-icon/appointment_icon.svg"
import { ReactComponent as WalkinConsentIcon } from "../../images/business-setting-icon/walk-in-consent.svg"
import { ReactComponent as Blocktime } from "../../images/sprint6/BlockOutTime.svg"
import { ReactComponent as ShareLinkIcon } from "../../images/business-setting-icon/ShareForward.svg"
import { ReactComponent as PrescriptioIcon } from "../../images/business-setting-icon/prepration.svg"
import { ReactComponent as NavArrorw } from "../../images/business-setting-icon/nav-icon.svg"
import { ReactComponent as VerifiedSvg } from "../../assets/images/verified/Verified.svg"
import { ReactComponent as PlusIconSvg } from "../../images/sprint6/plusIcon.svg"
import { Popover } from "bootstrap";
import ListViewToolTipContent from "../pages/calendar/ListViewToolTipContent";
import Tooltip from "../common/Tooltip/Tooltip";
import { useHideWarningBannerMutation } from "../redux/actions/business_dashboard/HideWarningBanner";
import StepButton from "../common/StepButton"
import { config } from "../../config"
import headerNotify from "../../images/sprint6/headerNotification.png"
import CustomeNotiTost from "../customeNotiTost/customeNotiTost";
import InfiniteScroll from 'react-infinite-scroll-component';

import CustomNotificationModel from "../customNotificationModel/CustomNotificationModel"
import { GetNotificationDataAction } from "../redux/actions/headerNotification/getNotifications"
import { CLEAR_NOTITFICATION_LIST_DATA } from "../redux/constants/headerNotification/getNotificationConst"

const BusinessHeader = (props) => {
	const { openMobileMenu, isMobile, removeHeaderSpace, isDisplayHeader = true, isDisplayNotification = true } = props;
	let currentScroll;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const tooltipViewRef = useRef(null);
	const domain = window.location.host
	const listRef = useRef();
	const targetHeight = 100;
	const [isSwitch, setIsSwitch] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [renewClicked, setRenewClicked] = useState(false);
	const [renewLoaderActive, setRenewLoaderActive] = useState(false);
	const [openReviewPopup, setOpenReviewPopup] = useState(false);
	const [isVerified, setIsVerified] = useState(false)
	const [isHideFacesVerified, setIsHideFacesVerified] = useState(true)
	const [clientsModal, setClientsModal] = useState(false);
	const [isFacesMobile, setIsFacesMobile] = useState(false)
	const [showFacesBanner, setShowFacesBanner] = useState(false);
	const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)
	const [isBannerShow, setIsBannerShow] = useState(false)
	const [isShowTrusted, setIsShowTrusted] = useState(true)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const [notificationActive, setNotificationActive] = useState(false)
	const { initiateHideWarningBanner } = useHideWarningBannerMutation()

	//CLIENTS DATA SEARCH
	const [searchString, setSearchString] = useState(null);
	const [cartProductCount, setCartProductCount] = useState(0);
	const [reviewError, setReviewError] = useState("");
	const [allClients, setAllclients] = useState(false);
	const [value, setValue] = useState([]);


	const { getInsuranceVerifiedQueryData, initGetInsuranceverifiedData } = GetUserInsuranceVerifiedAction();
	// const { initGetUserDashboardData } = GetUserDashboardDataAction();
	const handleOptionChange = (event, newValue) => {
		// newValue = newValue.filter(e => e?.title !== "No Client Found")
		setValue(newValue);
		if (newValue) {
			setReviewError("");
		}
	};
	const location = useLocation();


	console.log(UserProfileData, "UserProfileDataUserProfileData")

	// useEffect(() => { initGetUserDashboardData({}, (res) => { console.log(res, "022") }, (err) => { }) }, [])
	// const history = useHistory();
	// //CLIENTS DATA
	const { clientDataList } = useSelector(
		(state) => state.GetReviewsDataReducer
	);
	const { totalclientDataCount } = useSelector(
		(state) => state.GetReviewsDataReducer
	);
	const { loadedclientDataCount } = useSelector(
		(state) => state.GetReviewsDataReducer
	);
	const { clientDataPage } = useSelector(
		(state) => state.GetReviewsDataReducer
	);


	const { dashboardListData } = useSelector((state) => state.dashboardReducer);
	const { dashboardgetCartCountData } = useSelector((state) => state.dashboardGetCartCountReducer);
	const { profileData } = useSelector((state) => state?.GetProfileDataReducer);
	const { sendMailData } = useSelector((state) => state.GetReviewsDataReducer);
	const bannerShow = getInsuranceVerifiedQueryData?.data?.getUserInsuranceVerifiedStatus?.data;
	const insuranceStatus = getInsuranceVerifiedQueryData?.data?.getUserInsuranceVerifiedStatus?.data?.status
	const [trainingAcademyUrl, setTrainingAcademyUrl] = useState();

	let roles = profileData ? profileData.roles : [];
	const TRAINING_PROVIDER_ROLE = 2;
	const isTrainingProvider = () => {
		return roles.includes(TRAINING_PROVIDER_ROLE);
	};

	useEffect(() => {
		let trainingPortalLink = isTrainingProvider() ? webBaseUrl + "/training-dashboard/dashboard" : webBaseUrl + "/training-dashboard/student-portal-dashboard";

		setTrainingAcademyUrl(trainingPortalLink);
	}, [roles]);


	const { getNotificationQueryData, initGetNotificationActionData } = GetNotificationDataAction();

	const notificationList = useSelector((state) => state.getNotificationListreducer.notificationListData);
	const totalNotificationCount = useSelector((state) => state.getNotificationListreducer.totalNotificationCount)
	const notificationListPage = useSelector((state) => state.getNotificationListreducer.notificationListPage);
	const loadedNotificationCount = useSelector((state) => state.getNotificationListreducer.loadedNotificationCount)



	console.log("notificationList", notificationList)
	console.log(insuranceStatus, "10230")




	useEffect(() => {
		// if dashboardgetCartCountData type of number so set setCartProductCount the state
		if (dashboardgetCartCountData && dashboardgetCartCountData != 0) {
			console.log('dashboardgetCartCountData', dashboardgetCartCountData);
			setCartProductCount(dashboardgetCartCountData);
		}
	}, [dashboardgetCartCountData]);






	const { forwardMailFlag } = useSelector(
		(state) => state.GetReviewsDataReducer
	);

	const { faces_onboarding_status } = useSelector(
		(state) => state?.FacesAiReducer
	);

	const initiateNotificationListCall = (refreshList = false, search = null) => {
		let newPage = notificationListPage + 1
		if (refreshList) {
			newPage = 1;
		} else if (totalNotificationCount <= loadedNotificationCount) {
			return;
		}
		initGetNotificationActionData({
			page: newPage,
			limit: 10
		}, (data) => {
			console.log(data)
		}, (error) => {
			console.log(error)
		})
	}


	useEffect(() => {
		dispatch({
			type: CLEAR_NOTITFICATION_LIST_DATA
		})
		initiateNotificationListCall(true)
	}, [])




	const handleReviewModalClick = () => {
		setReviewError("");
		setOpenReviewPopup(true);
	};


	const handleOpenNotifyPopUp = () => {
		setNotificationActive(!notificationActive);
	}




	const navigateToFacesVerifiedPage = () => {
		navigate(verifiedBannerRoutePattern)
	}



	const { scheduleReviewEmailQueryData, initScheduleReviewEmailData } = ScheduleReviewEmailAction();
	const { getClientListsQueryData, initGetClientListsData } = GetClientListsAction();
	const { getUserInsuranceDetailsQueryData, initGetUserInsuranceDetailData } = GetUserInsuranceDetailAction();
	const { hideBecomeFacesVerifiedBannerQuerydata, initHideBecomeFacesVerifiedBannerData } = HideBecomeFacesVerifiedBannerAction();

	// const {getInsuranceVerifiedQueryData,initGetInsuranceverifiedData} =GetUserInsuranceVerifiedAction();


	useEffect(() => {
		initGetUserInsuranceDetailData();
	}, [])

	useEffect(() => {
		if (profileData) {
			setIsHideFacesVerified(profileData?.is_hide_banner)
		}
	}, [profileData])

	useEffect(() => {
		if (profileData) {
			setIsVerified(profileData?.is_verified)
		}
	}, [profileData])

	useEffect(() => {
		if (bannerShow) {
			setIsBannerShow(bannerShow?.is_banner_show)
		}
	}, [bannerShow])

	useEffect(() => {
		initGetInsuranceverifiedData()
	}, [])


	// useEffect(()=>{
	//       initGetInsuranceverifiedData();
	// },[])

	// useEffect(()=>{
	//     if(getInsuranceVerifiedQueryData?.data){
	//        setIsVerified(getInsuranceVerifiedQueryData?.data?.getUserInsuranceVerifiedStatus?.data?.is_verified)
	//     }
	// },[getInsuranceVerifiedQueryData?.data])

	const handleClientsForm = (e) => {
		e.preventDefault();
		let temp = [];
		if (value.length > 0) {
			temp = value.map((e) => e.cl_dt_id);
		}

		if (!allClients && !temp?.length) {
			setReviewError("Please select client.")
			return;
		}
		// dispatch(
		//   forwardSendMailData({
		//     elementId: temp,
		//     selectAllClients: allClients ? 1 : 0,
		//   })
		// );

		dispatch({
			type: FORWARD_SENDMAIL
		});
		initScheduleReviewEmailData({
			clientIds: temp,
			selectAllClients: allClients ? true : false
		})
		// here
	};
	//INFINITE SCROLL FOR CLIENTS DATA

	const initClientList = (refreshList = false) => {
		let newPage = clientDataPage + 1;
		if (refreshList) {
			newPage = 1;
		} else if (totalclientDataCount <= loadedclientDataCount) {
			return;
		}
		dispatch(
			getClientsData({ page: newPage, limit: 10, search: searchString })
		);

		// dispatch({
		//   type: GET_CLIENTS_DATA,
		//   payload: {
		//     page: newPage
		//   }
		// });
		initGetClientListsData({
			"page": newPage,
			"limit": 10,
			"search": searchString
		});
		// here
	};

	//DEBOUNCE FOR SEARCH
	// Debounced API call function
	const debouncedApiCall = debounce((query) => {
		// Make the API call using the search query
		// Replace this with your own API call logic
		initClientList(true)
	}, 500); // Debounce delay in milliseconds (e.g., 500ms)


	useEffect(() => {
		if (searchString != null) {
			// Call the debounced API function with the search query
			debouncedApiCall(searchString);

			// Cleanup function to cancel any pending debounced calls
			return () => {
				debouncedApiCall.cancel();
			};
		}
	}, [searchString]);

	const handleScrollCallback = () => {
		if (listRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = listRef.current;

			if (
				scrollTop + clientHeight === scrollHeight &&
				totalclientDataCount > loadedclientDataCount
			) {
				initClientList();
			}
		}
	};

	console.log(dashboardListData?.bannerData[0]?.bannerType, "dashboardListData?.bannerData[0]?.bannerType ");
	console.log(profileData, "profileData");

	useEffect(() => {
		if (!clientsModal) {
			setReviewError("");
		}
		if (sendMailData) {
			// setOptionValue([])
			setClientsModal(false);
		}
	}, [sendMailData]);


	useEffect(() => {

		if (localStorage.getItem('admin_id')?.length) {
			setIsAdmin(true);
		}

		if (dashboardListData == null) {
			dispatch(getProductsGetCartCountData());
			dispatch(
				getDashboardList({
					"business-average-reviews": true,
					platform: "frontend",
					insurancePage: true,
				})
			);
		}
	}, []);

	window.addEventListener("scroll", function () {
		currentScroll = window.scrollY || window.pageYOffset;

		if (currentScroll >= targetHeight) {
			setIsSwitch(true);
		} else {
			setIsSwitch(false);
		}
	});

	// const pharmacyRegistrationLink = webBaseUrl + "/user/update-profile?active-tab=prescriber";
	// const pharmacyRegistrationLink = webBaseUrl + "/prescriber/register";
	const pharmacyRegistrationLink = frontendURL + "/prescriber-registration?insuranceexpired=true";

	const redirectToPharmacyRegistration = () => {
		window.location.href = pharmacyRegistrationLink;
	}

	const redirectToPharmacyRegistrationWithQueryParams = () => {
		window.location.href = frontendURL + "/prescriber-registration?insuranceexpired=true";
	}

	console.log("search string", searchString);

	// const debouncedApiCall = debounce((query) => {
	//   // Make the API call using the search query
	//   // Replace this with your own API call logic
	//   initClientList(true)
	// }, 500); // Debounce delay in milliseconds (e.g., 500ms)


	// useEffect(() => {
	//   if (searchString != null) {
	//     // Call the debounced API function with the search query
	//     debouncedApiCall(searchString);

	//     // Cleanup function to cancel any pending debounced calls
	//     return () => {
	//       debouncedApiCall.cancel();
	//     };
	//   }
	// }, [searchString]);

	const { getProfileQueryData, initGetProfileData } = GetProfileAction();

	console.log("profileData", profileData)

	useEffect(() => {
		initGetProfileData();
	}, [])

	const { getUserDashboardDataQueryData, initGetUserDashboardDataData } = GetUserDashboardDataAction();
	useEffect(() => {
		initGetUserDashboardDataData({
			businessAverageReviews: true,
			appointmentListDayType: "",
			limit: null,
			platform: null
		});
	}, [])

	useEffect(() => { initGetUserDashboardDataData() }, [])

	const [isAuthHeader, setIsAuthHeader] = useState(false);
	useEffect(() => {
		console.log('isAuth Header', isAuthHeader, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
		Cookies.get("faces_access_token") ? setIsAuthHeader(true) : setIsAuthHeader(false);
		console.log('isAuth Header', isAuthHeader, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
	});

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		console.log(queryParams, "queryParams");
		if (queryParams.get('show-reviews') === '1') {
			setOpenReviewPopup(true);
			queryParams.delete('show-reviews');
			navigate({ search: queryParams.toString() }, { replace: true });
		}
	}, [location.search]);

	const handleOpenApp = () => {
		const UA = navigator.userAgent;

		if (/\b(iPad|iPhone|iPod)\b/i.test(UA) && !window.MSStream) {
			window.location.href = config.APP_STORE_LINK
		} else if (/\b(Android)\b/i.test(UA)) {
			window.location.href = config.PLAY_STORE_LINK
		}

	}

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		console.log(queryParams, "queryParams");
		if (queryParams.get('show-appoiment') === '1') {
			setIsModalOpen(true);
			queryParams.delete('show-appoiment');
			navigate({ search: queryParams.toString() }, { replace: true });
		}
	}, [location.search]);

	const handleGetFacesVerifiedtag = (e) => {
		e.stopPropagation()
		setShowFacesBanner(true);
		// setCurrentPage("verified");
		// setToggleMenu(true)
	}

	const handleCloseClick = (e) => {
		e.stopPropagation();

		initHideBecomeFacesVerifiedBannerData({
			isHideBanner: 1
		}, (data) => {
			setIsHideFacesVerified(true)
			setIsShowTrusted(false);
			// setShowGetFacesText(true)
			// setToggleMenu(true)
		}, (error) => {
			console.log(error);
		})
	};

	const tileData = [
		{
			title: "Create appointment",
			subTitle: "Forward consent form & manage bookings.",
			icon: <NewAppointmentIcon />
		},
		{
			title: "Walk-in",
			subTitle: "Gather consent from walk-ins.",
			icon: <WalkinConsentIcon />
		},
		{
			title: "Booking link",
			subTitle: "Share calendar with clients for bookings.",
			icon: <ShareLinkIcon />
		},
		{
			title: "Block out time",
			subTitle: "Easily set aside personal time.",
			icon: <Blocktime />
		},
		{
			title: "Prescription",
			subTitle: "Generate client prescriptions.",
			icon: <PrescriptioIcon />
		},
	]

	const redirectToNewBookingLink = ({ title }) => {
		// setIsModalOpen(false);
		if (title === "Create appointment") {
			// navigation(getNewAppointmentRoute())
			navigate(getNewAppointmentRoute(), { state: { from: 'new_appointment_home' } });
		}
		else if (title === "Booking link") {
			// navigate(getShareBookingLinkRoute())
			navigate(getShareBookingLinkRoute(), { state: { from: 'booking_link_from_home' } });
		}
		else if (title === "Block out time") {
			navigate(getBlockOutTimeRoute(), { state: { from: 'Block_out_time_home' } });
			// navigation(getBlockOutTimeRoute())
		}
		else if (title === "Walk-in") {
			console.log("inside walk in")
			window.location.href = webBaseUrl + "/filled-consent-form/create"
		}
		else if (title === "Prescription") {
			//       UserProfileData?.user?.userRoles.forEach((roles) => {
			//         console.log(roles, "roles")
			//         if (roles === 4) {
			//           // for practioner
			//           window.location.href = webBaseUrl + "/prescription/request-prescription"
			//         }
			//         else if (roles === 3) {
			// // for prescriber
			//           window.location.href = webBaseUrl + "/prescription/create"
			//         }
			//         else {
			//           navigate(getPrescriberRegistrationPage(),{ state: { from: 'plus_icon_header_bar' } })
			//         }
			//       }
			UserProfileData?.user?.userRoles.forEach((role) => {
				console.log(role, "role");
				if (role === 4) {
					// for practitioner
					window.location.href = webBaseUrl + "/prescription/request-prescription";
				}
				else if (role === 3) {
					// for prescriber
					window.location.href = webBaseUrl + "/prescription/create";
				}
			});

			// If neither 3 nor 4 exists, navigate to the default page.
			if (!UserProfileData?.user?.userRoles.includes(3) && !UserProfileData?.user?.userRoles.includes(4)) {
				navigate(getPrescriberRegistrationPage(), { state: { from: 'plus_icon_header_bar' } });
			}
			// window.location.href = webBaseUrl + "/prescription/request-prescription"
		}
	}

	// const onHeaderTooltipButtonClickHandler = () => {

	//   console.log("got it clicked")
	//   console.log(dashboardListData?.bannerData[0]?.bannerShow, "20.")
	//   initiateHideWarningBanner({ "uhbtId": dashboardListData?.bannerData[0]?.bannerType }, (res) => { console.log(res, "20.") }, (err) => { console.log(err, "20.") })
	// }
	const onHeaderTooltipButtonClickHandler = () => {
		initiateHideWarningBanner({ "uhbtId": dashboardListData?.bannerData[0]?.bannerType }, (res) => { initGetUserDashboardDataData() }, (err) => { console.log(err, "20.") })

	}



	return (
		<>
			{
				isAdmin ?
					<div className="content-header d-flex justify-content-between align-items-center px-4">
						<div>
							<h5>
								You are logged in as Admin.{" "}
								<u
									onClick={() => {
										console.log("exit action called");
										dispatch(logoutProfile());
									}}
								>
									Exit
								</u>
							</h5>
						</div>
					</div> : null
			}
			<div className={"newHeaderDesign " + (isAdmin ? "extra-header-margin" : null)}>
				{
					isMobile &&
					<div className="container-fluid">
						<div className="opanAppButtonMain">
							<div className="d-flex justify-content-between opanAppBut align-items-center ">
								<div className="facesLogo">
									{isMobile &&
										<div className="logo-image">
											<img
												src={logo}
												alt=""
												onClick={() => { isAuthHeader ? navigate(businessDashboardRoutePattern) : window.location.href = webBaseUrl }}
											/>
										</div>
									}
								</div>
								<div className="opanAppButton">
									<StepButton type={"button"} label={"Open App"} className={"btn"} onClick={() => handleOpenApp()} />
								</div>


							</div>
						</div>
					</div>
				}
				<div className={isSwitch ? "HeaderFixed HeaderStickey" : "HeaderStickey p-0"}>
					<div className="main-business-header">
						{isAdmin && (
							<div className="content-header d-flex justify-content-between align-items-center px-4 py-6">
								<div>
									<h5>
										You are logged in as Admin.
										<u
											onClick={() => {
												console.log("exit action called");
												dispatch(logoutProfile());
											}}
										>
											Exit
										</u>
									</h5>
								</div>
								{/* <div className="d-flex justify-content-end py-4 gap-3">
                  <h6
                    onClick={() => (window.location.href =
                      process.env.REACT_APP_WEB_BASE_URL + "/shop")}
                  >
                    SHOP
                  </h6><h6
                    onClick={() => (window.location.href =
                      process.env.REACT_APP_WEB_BASE_URL +
                      "/user/update-profile")}
                  >
                    MY ACCOUNT
                  </h6><h6
                    onClick={() => {
                      dispatch(logoutProfile());
                    }}
                  >
                    LOGOUT
                  </h6><h6
                    onClick={() => (window.location.href =
                      process.env.REACT_APP_WEB_BASE_URL + "/shop/cart")}
                  >
                    BASKET
                    {dashboardListData?.cart_total_count &&
                      "(" + dashboardListData?.cart_total_count + ")"}
                  </h6>
                </div> */}
							</div>
						)
							// (
							//   isDisplayHeader && <div className="content-header d-flex justify-content-end p-4 gap-3">

							//     {
							//       isAuthHeader ?
							//         <>
							//           {faces_onboarding_status?.is_onboarded ? (
							//             <div className="messageIcon">
							//               <span
							//                 onClick={() =>
							//                   window.open(
							//                     faces_onboarding_status?.faces_ai_integration_link,
							//                     "_blank"
							//                   )
							//                 }
							//               >
							//                 <svg
							//                   xmlns="http://www.w3.org/2000/svg"
							//                   width="23"
							//                   height="27"
							//                   viewBox="0 0 23 27"
							//                   fill="none"
							//                 >
							//                   <circle
							//                     cx="11.1749"
							//                     cy="11.0703"
							//                     r="11.0703"
							//                     fill="white"
							//                   />
							//                   <path
							//                     d="M4.32383 19.2341C4.3045 19.0813 4.45777 18.965 4.59971 19.0246L9.85351 21.2318C9.99545 21.2914 10.0196 21.4823 9.897 21.5754L5.35863 25.0218C5.23602 25.1149 5.05859 25.0403 5.03926 24.8876L4.32383 19.2341Z"
							//                     fill="white"
							//                   />
							//                   <path
							//                     d="M8.12961 8.70189L5.67807 13.7712C5.48195 14.7852 6.08666 14.8272 6.41353 14.7215L9.11023 12.1868C9.30636 11.6799 9.68226 11.7644 9.8457 11.87L11.8069 14.0878C12.7876 14.8482 13.523 14.4046 13.7682 14.0878L16.71 8.06809C16.71 7.3077 16.2197 7.32882 15.9746 7.43443L13.523 9.96907C12.9346 10.476 12.4607 10.1803 12.2972 9.96907L10.336 8.06823C9.15926 7.30784 8.37477 8.17384 8.12961 8.70189Z"
							//                     fill="black"
							//                   />
							//                 </svg>
							//               </span>
							//             </div>
							//           ) : (
							//             ""
							//           )}

							//           <a href={process.env.REACT_APP_WEB_BASE_URL + "/shop/cart"}
							//             className="bucketIconWrapper"
							//           >

							//             <span className="bucketIcon">
							//               <span className="notification">{cartProductCount}</span>
							//               <svg
							//                 xmlns="http://www.w3.org/2000/svg"
							//                 width="23"
							//                 height="19"
							//                 viewBox="0 0 23 19"
							//                 fill="none"
							//               >
							//                 <path
							//                   d="M1.40621 8.71074C1.36792 8.55722 1.3651 8.39699 1.39798 8.24222C1.43086 8.08744 1.49856 7.94219 1.59595 7.81749C1.69334 7.69279 1.81786 7.59191 1.96006 7.52252C2.10226 7.45312 2.2584 7.41704 2.41662 7.41699H20.5833C20.7415 7.41704 20.8977 7.45312 21.0399 7.52252C21.182 7.59191 21.3066 7.69279 21.404 7.81749C21.5014 7.94219 21.5691 8.08744 21.6019 8.24222C21.6348 8.39699 21.632 8.55722 21.5937 8.71074L19.7072 16.2555C19.5946 16.7062 19.3345 17.1064 18.9684 17.3923C18.6022 17.6782 18.151 17.8336 17.6864 17.8337H5.3135C4.84892 17.8336 4.3977 17.6782 4.03155 17.3923C3.6654 17.1064 3.40532 16.7062 3.29266 16.2555L1.40621 8.71178V8.71074Z"
							//                   stroke="white"
							//                   stroke-width="2"
							//                   stroke-linejoin="round"
							//                 />
							//                 <path
							//                   d="M8.375 11.5837V13.667M14.625 11.5837V13.667M5.25 7.41699L9.41667 1.16699M17.75 7.41699L13.5833 1.16699"
							//                   stroke="white"
							//                   stroke-width="2"
							//                   stroke-linecap="round"
							//                 />
							//               </svg>
							//             </span>
							//           </a>
							//           <a
							//             href={process.env.REACT_APP_WEB_BASE_URL + "/shop"}
							//             className="header-link"
							//           >
							//             SHOP
							//           </a>
							//           <a
							//             href={process.env.REACT_APP_WEB_BASE_URL + "/user/update-profile"}
							//             className="header-link"
							//           >
							//             MY ACCOUNT
							//           </a>
							//           <span
							//             className="header-link"
							//             onClick={() => {
							//               dispatch(logoutProfile());
							//             }}
							//           >
							//             LOGOUT
							//           </span>
							//         </> : (
							//           <>
							//             <a
							//               href={process.env.REACT_APP_WEB_BASE_URL + "/login"}
							//               className="header-link"
							//             >
							//               LOGIN
							//             </a>
							//             <a
							//               href={process.env.REACT_APP_WEB_BASE_URL + "/register"}
							//               className="header-link"
							//             >
							//               REGISTER
							//             </a>
							//           </>
							//         )}
							//   </div>
							// )
						}
					</div>
					<div className="dashboard-parent-container">
						<div className={'CustomeHeaderDesignChnage'}>
							<div className="logo-image">
								<img
									src={
										domain == "staging.insyncinsurance.co.uk"
											? logoInsync
											: logo
									}
									alt=""
									onClick={() => { isAuthHeader ? navigate(businessDashboardRoutePattern) : window.location.href = webBaseUrl }}
								/>
							</div>
							{/* {
                  isDisplayNotification &&
                  <div className="notificationCustome">
                    {dashboardListData?.pin_expiry_date && moment(dashboardListData.pin_expiry_date, "YYYY-MM-DD").isBefore(moment(), 'day') && (
                      <div className="insurance-warining mx-auto d-flex gap-3 mb-2">
                        <div>
                          <img src={bellIcon} width={"150%"} alt="" />
                        </div>
                        <p className="my-auto">
                          Your PIN has expired! Click to
                          <u
                            className="cursor-pointer"
                            onClick={() => {
                              window.location.href = frontendURL + "/prescriber-registration?pinexpired=true";
                            }}
                          >
                            update the PIN
                          </u>
                          .
                        </p>
                      </div>
                    )}

                    {dashboardListData?.insurance_expiry_date && moment(dashboardListData.insurance_expiry_date, "YYYY-MM-DD").isBefore(moment(), 'day') && (
                      <div className="insurance-warining mx-auto d-flex gap-3">
                        <div>
                          <img src={bellIcon} width={"150%"} alt="" />
                        </div>
                        <p className="my-auto">
                          Your insurance has expired! Click to
                          <u
                            className="cursor-pointer"
                            onClick={redirectToPharmacyRegistrationWithQueryParams}
                          >
                            upload Insurance certificate
                          </u>
                          or
                          <u
                            className="cursor-pointer"
                            onClick={() => {
                              setRenewClicked(true);
                              setRenewLoaderActive(true);
                              navigate(insuranceLandingPage);
                            }}
                          >
                            renew
                          </u>
                          .
                        </p>
                      </div>
                    )}
                  </div>
                } */}
							<div className="profileCustome">
								{
									isAuthHeader &&
									(
										<>
											{/* {isMobile &&
                          <div className="logo-image">
                            <img
                              src={logo}
                              alt=""
                              onClick={() => { isAuthHeader ? navigate(businessDashboardRoutePattern) : window.location.href = webBaseUrl }}
                            />
                          </div>
                        } */}

											{
												profileData && profileData?.is_verified === true && getUserInsuranceDetailsQueryData?.data &&
													getUserInsuranceDetailsQueryData?.data?.getUserInsuranceDetails?.data?.status === 1 ? (
													<div className="mobileProfile">
														<button id="plus-button" className="newBookingBtn" onClick={() => setIsModalOpen(true)}>
															<PlusIconSvg />
														</button>
														{/* <Tooltip targetId={"#plus-button"}  place={"bottom-end"} style={{ borderRadius: "20px", background: "#000", }}
                              toolTipContent={<ListViewToolTipContent
                                className="list-view-tooltip"
                                btnLabel={"Got it!"} btnClassName={"tooltip-btn w-50"} toolTipTitle={"NEW: Create appointment/Walk-in "} tooltipRef={tooltipViewRef} onClickCallback={onHeaderTooltipButtonClickHandler} />} tooltipRef={tooltipViewRef} /> */}

														{dashboardListData?.bannerData[0]?.bannerShow &&
															<Tooltip targetId={"#plus-button"} place={"bottom-end"} style={{ borderRadius: "20px", background: "#000", }}
																toolTipContent={<ListViewToolTipContent
																	className="list-view-tooltip"
																	btnLabel={"Got it!"} btnClassName={"tooltip-btn w-50"} toolTipTitle={"NEW: Create appointment/Walk-in "} tooltipRef={tooltipViewRef} onClickCallback={onHeaderTooltipButtonClickHandler} />} tooltipRef={tooltipViewRef} />
														}
														<div className="NotifiIcon IconWrapper">
															<NotiLogoIcon onClick={() => handleOpenNotifyPopUp()} />
														</div>
														{console.log(profileData, "profileData1230")}

														<Dropdown>
															<Dropdown.Toggle className="CustomeProfileDropDown" id="dropdown-basic">
																<div className="verifiedTag ">

																	<Avatar
																		style={{ cursor: "pointer" }}
																		src={profileData?.profile_pic}
																		name={
																			profileData?.first_name?.at(0) + " " + profileData?.last_name?.at(0)
																		}
																		round
																		size="48"
																		color="#EEEFF3"
																		fgColor="#000"
																		textSizeRatio={2}
																	/>
																	{/* {UserProfileData?.is_verified &&
                                    <img src={verifyIcon} alt="icon" className="verifyIcon" />
                                  } */}
																	{(insuranceStatus === 4) ? (
																		<img src={YellowStar} alt="insurance icon" className="verifyIcon" />
																	) : (
																		UserProfileData?.is_verified && (
																			<img src={verifyIcon} alt="icon" className="verifyIcon" />
																		)
																	)}

																</div>
																<p className="company-name my-auto">
																	{profileData?.company_name}
																</p>

																<div className="BottomArrow">
																	<DownArrowIcon />
																</div>
															</Dropdown.Toggle>

															<Dropdown.Menu className="CustomeProfileDropDownBody">
																<div className="CustomeProfileDropDownSecWrapper">
																	<div className="DropDownProfileWrapper">
																		<div className="DropDownProfileImgWrapper">
																			<div className="LeftPartProfile">
																				<Avatar
																					style={{ cursor: "pointer" }}
																					onClick={() => {
																						// navigate(businessSettingRoutePattern());
																						window.location.href =
																							webBaseUrl + "/user/update-profile";
																					}}
																					src={profileData?.profile_pic}
																					name={
																						profileData?.first_name?.at(0) + " " + profileData?.last_name?.at(0)
																					}
																					round
																					size="48"
																					color="#EEEFF3"
																					fgColor="#000"
																					textSizeRatio={2}
																				/>
																			</div>
																			<div className="rating-setting" onClick={() => handleReviewModalClick()}>
																				<RatingIcon />
																				<p>{dashboardListData?.averageRating ? dashboardListData?.averageRating?.toFixed(2) : 0}</p>
																			</div>
																		</div>
																		<div className="DropDownProfileName">
																			<h5>{profileData?.company_name} {(insuranceStatus === 4) ? (
																				<img src={YellowStar} alt="insurance icon" className="verifiedSvg_wrapper" />
																			) : (
																				UserProfileData?.is_verified && (
																					<img src={verifyIcon} alt="icon" className="verifiedSvg_wrapper" />
																				)
																			)}
																			</h5>
																		</div>
																	</div>
																	<ul className="LinksDropdownGrid">
																		<li className="LinksDropdownItem" onClick={() => { navigate(getBusinessSettingPageRoute) }}>
																			<ProfilesettingsIcon />
																			<span>
																				Settings
																			</span>
																		</li>
																		<li className="LinksDropdownItem" onClick={() => {
																			// window.location.href = webBaseUrl + '/training/frontend-list'; 
																			window.location.href = trainingAcademyUrl;
																		}}>
																			<TraingIcon />
																			<span>
																				Training academy
																			</span>
																		</li>

																		{isHideFacesVerified && !isVerified && (
																			<li className="LinksDropdownItem" onClick={(e) => handleGetFacesVerifiedtag(e)}>
																				<FacesVerifiedIcon />
																				<span>
																					Get Faces Verified
																				</span>
																			</li>
																		)}
																		{/* BANNER TRUSTED PARTNER */}
																		{
																			!isHideFacesVerified && !isVerified && (
																				!UserProfileData?.is_hide_banner &&
																				<div className={`setting-trusted-partner-section ${isShowTrusted ? '' : 'd-none'}`}>
																					<div className='trusted-header'>
																						<h1>Become a trusted partner</h1>
																						<CloseIcon onClick={(e) => handleCloseClick(e)} />
																					</div>
																					<p>Upload your insurance and stand out as a trusted company.</p>
																					<StepButton label={"Get verified"} blue={true} onClick={() => navigateToFacesVerifiedPage()} />
																				</div>
																			)
																		}
																		{
																			showFacesBanner && (
																				<div className={`setting-trusted-partner-section`}>
																					<div className='trusted-header'>
																						<h1>Become a trusted partner</h1>
																						{/* <CloseIcon onClick={handleCloseClick} /> */}
																					</div>
																					<p>Upload your insurance and stand out as a trusted company.</p>
																					<StepButton label={"Get verified"} blue={true}
																						onClick={() => navigateToFacesVerifiedPage()} />
																				</div>
																			)
																		}
																		<li className="LinksDropdownItem" onClick={() => {
																			dispatch(logoutProfile());
																		}
																		}>
																			<LogoutIcon />
																			<span>
																				Log out
																			</span>
																		</li>
																	</ul>
																</div>
															</Dropdown.Menu>
														</Dropdown>

														{isMobile && (
															<div
																className="menu-mobile"
																onClick={openMobileMenu}
															>
																<TfiMenu />
															</div>
														)}
													</div>
												)
													:
													<div className="mobileProfile">
														<button id="plus-button" className="newBookingBtn" onClick={() => setIsModalOpen(true)}>
															<PlusIconSvg />
														</button>
														{/* <Tooltip targetId={"#plus-button"} place={"bottom-end"} style={{ borderRadius: "20px", background: "#000" }} toolTipContent={<ListViewToolTipContent className="list-view-tooltip" btnLabel={"Got it!"} btnClassName={"tooltip-btn w-50"} toolTipTitle={"NEW: Create appointment/Walk-in "} tooltipRef={tooltipViewRef} onClickCallback={onHeaderTooltipButtonClickHandler} />} tooltipRef={tooltipViewRef} /> */}
														{dashboardListData?.bannerData[0]?.bannerShow &&
															< Tooltip targetId={"#plus-button"} place={"bottom-end"} style={{ borderRadius: "20px", background: "#000" }} toolTipContent={<ListViewToolTipContent className="list-view-tooltip" btnLabel={"Got it!"} btnClassName={"tooltip-btn w-50"} toolTipTitle={"NEW: Create appointment/Walk-in "} tooltipRef={tooltipViewRef} onClickCallback={onHeaderTooltipButtonClickHandler} />} tooltipRef={tooltipViewRef} />
														}
														<div className="NotifiIcon IconWrapper">
															<NotiLogoIcon onClick={() => handleOpenNotifyPopUp()} />
														</div>


														<Dropdown>
															<Dropdown.Toggle className="CustomeProfileDropDown" id="dropdown-basic">
																<div className="verifiedTag ">

																	<Avatar
																		style={{ cursor: "pointer" }}
																		src={profileData?.profile_pic}
																		name={
																			profileData?.first_name?.at(0) + " " + profileData?.last_name?.at(0)
																		}
																		round
																		size="48"
																		color="#EEEFF3"
																		fgColor="#000"
																		textSizeRatio={2}
																	/>
																	{/* {UserProfileData?.is_verified &&
                                    <img src={verifyIcon} alt="icon" className="verifyIcon" />
                                  } */}
																	{(insuranceStatus === 4) ? (
																		<img src={YellowStar} alt="insurance icon" className="verifyIcon" />
																	) : (
																		UserProfileData?.is_verified && (
																			<img src={verifyIcon} alt="icon" className="verifyIcon" />
																		)
																	)}
																</div>
																<p className="company-name my-auto">
																	{profileData?.company_name}
																</p>

																<div className="BottomArrow">
																	<DownArrowIcon />
																</div>
															</Dropdown.Toggle>

															<Dropdown.Menu className="CustomeProfileDropDownBody">
																<div className="CustomeProfileDropDownSecWrapper">
																	<div className="DropDownProfileWrapper">
																		<div className="DropDownProfileImgWrapper">
																			<div className="LeftPartProfile">
																				<Avatar
																					style={{ cursor: "pointer" }}
																					onClick={() => {
																						// navigate(businessSettingRoutePattern());
																						window.location.href =
																							webBaseUrl + "/user/update-profile";
																					}}
																					src={profileData?.profile_pic}
																					name={
																						profileData?.first_name?.at(0) + " " + profileData?.last_name?.at(0)
																					}
																					round
																					size="48"
																					color="#EEEFF3"
																					fgColor="#000"
																					textSizeRatio={2}
																				/>
																			</div>
																			<div className="rating-setting" onClick={() => handleReviewModalClick()}>
																				<RatingIcon />
																				<p>{dashboardListData?.averageRating ? dashboardListData?.averageRating?.toFixed(2) : 0}</p>
																			</div>
																		</div>
																		<div className="DropDownProfileName">
																			<h5>{profileData?.company_name} {(insuranceStatus === 4) ? (
																				<img src={YellowStar} alt="insurance icon" className="verifiedSvg_wrapper" />
																			) : (
																				UserProfileData?.is_verified && (
																					<img src={verifyIcon} alt="icon" className="verifiedSvg_wrapper" />
																				)
																			)}
																			</h5>
																		</div>
																	</div>
																	<ul className="LinksDropdownGrid">
																		<li className="LinksDropdownItem" onClick={() => { navigate(getBusinessSettingPageRoute) }}>
																			<ProfilesettingsIcon />
																			<span>
																				Settings
																			</span>
																		</li>
																		<li className="LinksDropdownItem" onClick={() => {
																			// window.location.href = webBaseUrl + '/training/frontend-list'; 
																			window.location.href = trainingAcademyUrl;
																		}}>
																			<TraingIcon />
																			<span>
																				Training academy
																			</span>
																		</li>

																		{isHideFacesVerified && !isVerified && (
																			<li className="LinksDropdownItem" onClick={(e) => handleGetFacesVerifiedtag(e)}>
																				<FacesVerifiedIcon />
																				<span>
																					Get Faces Verified
																				</span>
																			</li>
																		)}
																		{/* BANNER TRUSTED PARTNER */}
																		{
																			!isHideFacesVerified && !isVerified && (
																				!UserProfileData?.is_hide_banner &&
																				<div className={`setting-trusted-partner-section ${isShowTrusted ? '' : 'd-none'}`}>
																					<div className='trusted-header'>
																						<h1>Become a trusted partner</h1>
																						<CloseIcon onClick={(e) => handleCloseClick(e)} />
																					</div>
																					<p>Upload your insurance and stand out as a trusted company.</p>
																					<StepButton label={"Get verified"} blue={true} onClick={() => navigateToFacesVerifiedPage()} />
																				</div>
																			)
																		}
																		{
																			showFacesBanner && (
																				<div className={`setting-trusted-partner-section`}>
																					<div className='trusted-header'>
																						<h1>Become a trusted partner</h1>
																						{/* <CloseIcon onClick={handleCloseClick} /> */}
																					</div>
																					<p>Upload your insurance and stand out as a trusted company.</p>
																					<StepButton label={"Get verified"} blue={true}
																						onClick={() => navigateToFacesVerifiedPage()} />
																				</div>
																			)
																		}
																		<li className="LinksDropdownItem" onClick={() => {
																			dispatch(logoutProfile());
																		}
																		}>
																			<LogoutIcon />
																			<span>
																				Log out
																			</span>
																		</li>
																	</ul>
																</div>
															</Dropdown.Menu>
														</Dropdown>

														{isMobile && (
															<div
																className="menu-mobile"
																onClick={openMobileMenu}
															>
																<TfiMenu />
															</div>
														)}
													</div>
											}
										</>
									)
								}


							</div>
						</div>
					</div>
				</div>

			</div>

			{/* ========================REVIEW MODAL======================== */}


			{
				notificationActive && (
					<div className="notificationSection">
						<div id="notificationScroll" style={{ overflowX: "scroll", height: "100%", width: "100%" }}>
							<InfiniteScroll
								dataLength={loadedNotificationCount}
								next={() => initiateNotificationListCall(false)}
								hasMore={loadedNotificationCount < totalNotificationCount}
								scrollableTarget="notificationScroll"
							>

								<>
									<div className="notifyread">
										<p>Mark all as read</p>
									</div>
									{
										notificationList.map((notifyData, index) => {
											console.log("notifyData111", notifyData)
											return <CustomNotificationModel
												img={notifyData.profile_picture}
												title={notifyData.title}
												description={notifyData.description}
												hours={notifyData.time}
												buttonText={notifyData.btn_text}
												isbutton={notifyData.is_btn}
												id={notifyData.id}
												name={notifyData.name}
												buttonUrl={notifyData.btn_url}
												isRead={notifyData.is_read}
											/>

										})
									}
								</>

							</InfiniteScroll>
						</div>
					</div>
				)
			}




			<CustomModal
				modalOpen={openReviewPopup}
				setModalOpen={setOpenReviewPopup}
				type={"review"}
				modalBody={
					<>
						<ReviwewPopup
							setOpenReviewPopup={setOpenReviewPopup}
							setClientsModal={setClientsModal}
							setValue={setValue}
						/>
					</>
				}
			/>

			{/* ========================FORWARD MAIL TO CLIENT MODAL======================== */}
			<CustomModal
				modalOpen={clientsModal}
				// modalOpen={true}
				setModalOpen={setClientsModal}
				modaltitle={"Select Clients"}
				modalBody={
					<>
						{
							<form
								className="d-flex flex-column mt-2"
								onSubmit={handleClientsForm}
							>
								<div>
									<input
										type="checkbox"
										name="all"
										id="all"
										checked={allClients}
										onChange={(e) => { setAllclients(e.target.checked) }}
									/>
									<label htmlFor="all" className="ms-2">
										Select All Clients
									</label>
								</div>
								{!allClients && (
									<CustomAutoComplete
										multiple={true}
										groupBy={false}
										listRef={listRef}
										setSearchString={setSearchString}
										handleInputClick={() => initClientList(true)}
										optionValue={clientDataList}
										value={value}
										setValue={setValue}
										handleOptionChange={handleOptionChange}
										handleScrollCallback={handleScrollCallback}
									/>
								)}
								{reviewError && <p className="error">{reviewError}</p>}
								<button className="client-submit-btn">
									<div className="d-flex justify-content-center align-items-center gap-2 ">
										{forwardMailFlag && (
											<div class="payment-spinner-loader"></div>
										)}
										SEND REVIEW REQUEST TO CLIENT
									</div>
								</button>
							</form>
						}
					</>
				}
			/>

			{/* MODAL */}
			<CustomModal
				modalOpen={isModalOpen}
				setModalOpen={setIsModalOpen}
				className='NewBookingModel'
				shouldCloseOnOverlayClick={true}
				modalBody={
					<div className='business-modal-content-section'>
						<div className='business-modal-setting-container'>
							{
								tileData?.map((tileItem, i) => (
									<div className='modal-sub-content-section' key={i} onClick={() => redirectToNewBookingLink(tileItem)}>
										<div className='modal-setting-left-section'>
											<div className='IconWrapper'>
												{tileItem?.icon}
											</div>
											<div className='modal-sub-left-content'>
												<h1>{tileItem?.title}</h1>
												<p>{tileItem?.subTitle}</p>
											</div>
										</div>
										<div className='modal-setting-right-section'>
											<NavArrorw />
										</div>
									</div>
								))
							}
						</div>
					</div>
				}
			/>








		</>
	);
};

export default BusinessHeader;
