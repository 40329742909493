import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { CHECK_WEBSITE_DOMAIN_RESPONSE, GENRERATE_TERMS_AND_CONDITIONS, GET_WEBSITE_DETAILS } from "../../constants";


//1 date-api
const GENERATE_TERMS_AND_CONDITIONS_QUERY = gql`
query TermsAndConditionsTemplate {
    termsAndConditionsTemplate {
      success
      message
      data {
        plainText
      }
    }
  }
`

export const GenerateTermsAndConditionsAction = () => {
  const dispatch = useDispatch()
  const [generateTermsAndConditionsResponseFunction, generateTermsAndConditionsReponseData] = useLazyQuery(
    GENERATE_TERMS_AND_CONDITIONS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ termsAndConditionsTemplate: responseData }) => {
        console.log("responseData", responseData,"data")
        if (responseData?.success)
        console.log("responseData2", responseData,"data")
          dispatch({
            type: GENRERATE_TERMS_AND_CONDITIONS,
            payload: responseData
          })
      }
    }
  )
  //this will be called from components
  const generateTermsAndConditionsResponseData = generateTermsAndConditionsReponseData //variable

  const initGenerateTermsAndConditionsResponseFunction = (data) => {

    generateTermsAndConditionsResponseFunction({
      variables: data
    })
  }
  return { initGenerateTermsAndConditionsResponseFunction, generateTermsAndConditionsResponseData}
}

