import { CLEAR_IDENTITY_DETAILS, GET_IDENTITY_DETAILS } from "../../constants/client/IdentityConst";


const initialState = {
  identityDocument: [],
};

export default function identityDocumentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_IDENTITY_DETAILS:
      return {
        ...state,
        identityDocument: action.payload,
      };
    case CLEAR_IDENTITY_DETAILS:
      return {
        ...state,
        identityDocument: [],
      };
      
    default:
      return state;
  }
}
