import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const REFUND_FINANCE_APPLICATION_MUTATION = gql`
  mutation RefundFinanceApplication($orderId: String, $refundAmount: String, $loanRequestId: String, $refundType: String) {
    refundFinanceApplication(order_id: $orderId, refundAmount: $refundAmount, loanRequestId: $loanRequestId, refundType: $refundType) {
      success
      message
      data
    }
  }
`;

export const useRefundFinanceApplication = () => {
  const [refundFinanceApplication, { loading }] = useMutation(REFUND_FINANCE_APPLICATION_MUTATION, {
    onCompleted: (data) => {
      if (data.refundFinanceApplication.success) {
        toast.success(data.refundFinanceApplication.message);
      } else {
        toast.error(data.refundFinanceApplication.message);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return { refundFinanceApplication, loading };
};
