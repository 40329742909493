import { gql, useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { FORWARDED_CONSENT_FORM_CLIENT_STEP_COMPLETED, FORWARDED_CONSENT_FORM_CONSENT_STEP_COMPLETED, FORWARDED_CONSENT_FORM_PAYMENT_STEP_COMPLETED, GET_FORWARD_CONSENT_FORM_BOOKING_OVERVIEW } from '../../constants/forward-consent-form/ForwardConsentFormConst';

const GET_FORWARD_CONSENT_FORM_BOOKING_OVERVIEW_QUERY = gql`
    query GetForwardConsentFormBookingDetails($bookingId: Int, $token: String) {
        getForwardConsentFormBookingDetails(booking_id: $bookingId, token: $token) {
            success
            message
            data {
                clientDetails {
                    clientName
                    clientEmail
                    clientDob
                    clientPhone
                    clientHouseNo
                    clientStreetName
                    clientCity
                    clientPostcode
                    clientAddress
                    readableAddress
                    readableContact
                    countryCode
                    phoneCountry
                    clientIdImage
                }
                appointmentDetails {
                    appointmentId
                    clinicId
                    clinicName
                    appointmentDate
                    appointmentTime
                    appointmentTimeEnd
                    appointmentDateOriginal
                    businessName
                }
                treatmentDetails {
                    treatmentId
                    treatmentName
                    treatmentPrice
                    treatmentImage           
                    beforePhotos {
                        photoUrl
                        photoId
                    }         
                }
                paymentSummary {
                    totalAmount
                    depositAmount
                    payableAmount
                    dueAmount
                    discountAmount
                    totalAppointmentAmount
                    processingFeeBearer
                    commissionPercentage
                }
                payment_data {
                    pay_in_full
                    pay_deposit
                    pay_on_the_day
                    pay_monthly
                    is_finance_available
                    is_stripe_setup
                    is_ryft_setup
                    user_payl8tr_status
                    finance_options
                }
                selectedPayment {
                    payableAmount
                    payMonthly
                    payOnTheDay
                    paymentByPass
                }
                allowForAdvertisement
                isConsentFormDone
                isConsentFormPaymentDone
                loanRequestToUser
                reviewAdded
                isClientTrue {
                    isClient
                }
                userFristName
                userLastName
                userProfile
                averageRating
                userTotalReviews

                notes
                clientSignature
                userId
            }
        }
    }
`;

export const ForwardConsentFormOverviewQuery = () => {
    const dispatch = useDispatch();

    const [getForwardConsentFormOverview, forwardConsentFormOverviewData] = useLazyQuery(
        GET_FORWARD_CONSENT_FORM_BOOKING_OVERVIEW_QUERY,
        {
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: ({ getForwardConsentFormBookingDetails: responseData }) => {
                dispatch({
                    type: GET_FORWARD_CONSENT_FORM_BOOKING_OVERVIEW,
                    payload: responseData?.data,
                });
                if (responseData.success) {
                } else {
                }
            },
        }
    );
    const forwardConsentFormOverviewQueryData = forwardConsentFormOverviewData;
    const initForwardConsentFormOverview = (data, successCallback, errorCallback) => {
        getForwardConsentFormOverview({
            variables: data,
        })
            .then((res) => {
                successCallback(res?.data?.getForwardConsentFormBookingDetails);
            })
            .catch((err) => {
                errorCallback(err);
            });
    };
    return { initForwardConsentFormOverview, forwardConsentFormOverviewQueryData };
};


export const useForwardConsentFormCustomActions = () => {
    const dispatch = useDispatch();
        
    const forwardedConsentFormClientStepCompleted = () => {
        dispatch({
            type: FORWARDED_CONSENT_FORM_CLIENT_STEP_COMPLETED,
        }) 
    }

    const forwardedConsentFormConsentStepCompleted = () => {
        dispatch({
            type: FORWARDED_CONSENT_FORM_CONSENT_STEP_COMPLETED,
        })
    }
    
    const forwardedConsentFormPaymentStepCompleted = () => {
        dispatch({
            type: FORWARDED_CONSENT_FORM_PAYMENT_STEP_COMPLETED,
        })
    }

    return {
        forwardedConsentFormClientStepCompleted,
        forwardedConsentFormConsentStepCompleted,
        forwardedConsentFormPaymentStepCompleted,
    }
}