import React from 'react'
import "./product-question.scss"

const SingleQuestion = ({
  queNo,
  quesId,
  question,
  questionType,
  handleRemoveQuestion,
  handleChange,
  error,
  answerEor
}) => {

  
  return (
    <>
        <div className={queNo !== -1 ? "single-question questionWrapper" : "type-question questionWrapper"}>
            <div className="InputWrapper">
        <label> {queNo }  Question : </label>
        <div class="InputField">
          <input
            type="text"
            className="form-control"
            placeholder="Question"
            name={`questions[${quesId}]`}
            value={question.questionText}
            onChange={(e) => {
              handleChange(quesId, e.target.value);
            }}
          />
          <p className="error">{error}</p>
        </div>
            </div>
            {/* {
              queNo === -1 ? (
                ""
              ):
              (
                <div className="remove-new-module-btn">
              <p
            onClick={() => {
              handleRemoveQuestion(quesId);
            }}
            >
            - Update Question
          </p>
                    </div>
              )
            } */}

         
        </div>
    </>
  )
}

export default SingleQuestion