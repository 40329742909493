import React, { useState ,useEffect} from 'react'
import PropTypes from 'prop-types'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import SubHeading from '../hair-product-common/hair-product-sub-heading/SubHeading'
import TextInput from '../../../common/TextInput'
import HairProductWhatsAppButton from '../hair-product-common/hair-product-whatsApp-btn/HairProductWhatsAppButton'
import StepButton from '../../../common/StepButton'
import { validEmail, validPhone, validName } from '../../../../utils/Regex'
import './HairProductFive.scss'

const HairProductsFive = ({ shippingDetails, OnhandleContinue }) => {
    const [selectedCountryLabel, setSelectedCountryLabel] = useState("");
    const [userDetails, setUserDetails] = useState(() => JSON.parse(JSON.stringify(shippingDetails)))
    const validNamespecial = new RegExp('^[a-zA-Z\\s!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]{0,30}$');

    console.log('userDetails', userDetails)
    const [validationErrors, setValidationErrors] = useState({
        firstname: "",
        lastname: "",
        email: "",
        street: "",
        apartment: "",
        city: "",
        postcode: "",
        country: "",
        phone: "",
        // phoneCountryCode: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails((prev => ({
            ...prev,
            [name]: value,
        })))
        setValidationErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
    }


    //for countrySelection
    // const CountrySelect = ({ value, onChange, selectedLabel, labels, ...rest }) => (
    //     <div className="prescriber-country-selection-container">
    //         <select
    //             {...rest}
    //             value={value}
    //             onChange={event => onChange(event.target.value || undefined)}>
    //             <option value="">
    //                 {labels['ZZ']}

    //             </option>
    //             {getCountries().map((country, i) => (
    //                 country !== 'International' &&
    //                 <option key={country} value={country}>
    //                     {`+${getCountryCallingCode(country)}`}
    //                 </option>


    //             ))}
    //         </select>
    //         <span className="selected-label">{selectedLabel}</span>
    //     </div>
    // )

    // CountrySelect.propTypes = {
    //     value: PropTypes.string,
    //     onChange: PropTypes.func.isRequired,
    //     labels: PropTypes.objectOf(PropTypes.string).isRequired,
    //     selectedLabel: PropTypes.string,
    // }

    //HANDLE PHONE NUMBER CHANGE 
    // const handleCountryChange = (country) => {
    //     setValidationErrors(prevErrors => ({
    //         ...prevErrors,
    //         phoneCountryCode: "",
    //     }));

    //     // Get the label based on the selected country
    //     const selectedLabel = getCountries().includes(country)
    //         ? `${getCountryCallingCode(country)}`
    //         : "";
    //     setSelectedCountryLabel(selectedLabel);

    //     // setCountryCode(country);
    //     setUserDetails((prev) => ({
    //         ...prev,
    //         phoneCountryCode: selectedLabel, // Set the country code in userDetails
    //         phoneCountry: country
    //     }));
    // };

    // Validate input fields
    const validateInputs = () => {
        const errors = {};

        if (!userDetails.firstname) {
            errors.firstname = "First name is required";
        } else if (!validNamespecial.test(userDetails.firstname)) {
            errors.firstname = "Please use only alphabetic characters, spaces and special characters";
        }

        if (!userDetails.lastname) {
            errors.lastname = "Last name is required";
        } else if (!validNamespecial.test(userDetails.lastname)) {
            errors.lastname = "Please use only alphabetic characters, spaces and special characters";
        }

        if (!userDetails.email) {
            errors.email = "Email is required";
        } else if (!validEmail.test(userDetails.email)) {
            errors.email = "Invalid email address";
        }

        if (!userDetails.street) {
            errors.street = "Street address is required";
        }

        if (!userDetails.city) {
            errors.city = "City is required";
        }

        if (!userDetails.postcode) {
            errors.postcode = "Postcode is required";
        }

        if (!userDetails.country) {
            errors.country = "Country is required";
        }

        // if (!userDetails.phoneCountry || !userDetails.phone) {
        if (!userDetails.phone) {
            errors.phone = "phone number is required";
        } else {
            if (!validPhone.test(userDetails.phone)) {
                errors.phone = "Invalid phone number";
            }
        }

        // Handle scrolling to the first error
        // Handle scrolling to the first error
        let firstErrorField = null;
        for (const [fieldName, error] of Object.entries(errors)) {
            if (!firstErrorField && error) {
                firstErrorField = fieldName;
            }
        }
        if (firstErrorField) {
            const errorFieldElement = document.querySelector(`[name="${firstErrorField}"]`);
            if (errorFieldElement) {
                const headerHeight = 130; // Replace with the actual height of your fixed header
                const offset = errorFieldElement.offsetTop - headerHeight;
                // window.scrollTo({ top: offset, behavior: 'smooth' });
                errorFieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }

        setValidationErrors(errors);

        // Return true if no errors, otherwise false
        return Object.keys(errors).length === 0;
    };

    const handleContinue = () => {
        const isValid = validateInputs();

        if (isValid) {
            OnhandleContinue(userDetails);
        }
    };

    useEffect(()=>{
        window.scrollTo(0, 0);
     },[])

    return (
        <>
            <SubHeading
                title="Shipping info"
                desc="Where would you like to have your medications shipped, if approved? Use your legal name."
            />
            <div className='flex-gap-normal'>
                <div className="hair-product-user-details">

                    <div className='hair-product-input-container'>
                        {/* Companies House Registration Number*/}
                        <TextInput
                            label={"First Name"}
                            placeholder={"First Name"}
                            value={userDetails?.firstname}
                            onChange={(e) => handleChange(e)}
                            name="firstname"
                        />
                        {validationErrors?.firstname && <span className="error">{validationErrors?.firstname}</span>}
                    </div>

                    <div className='hair-product-input-container'>
                        {/* Companies House Registration Number*/}
                        <TextInput
                            label={"Last Name"}
                            placeholder={"Last Name"}
                            value={userDetails?.lastname}
                            onChange={(e) => handleChange(e)}
                            name="lastname"
                        />
                        {validationErrors?.lastname && <span className="error">{validationErrors?.lastname}</span>}
                    </div>

                    <div className='hair-product-input-container'>
                        {/* Companies House Registration Number*/}
                        <TextInput
                            label={"Email Address"}
                            placeholder={"Email Address"}
                            value={userDetails?.email}
                            onChange={(e) => handleChange(e)}
                            name="email"
                        />
                        {validationErrors?.email && <span className="error">{validationErrors?.email}</span>}
                    </div>

                    <div className='hair-product-input-container'>
                        {/* Companies House Registration Number*/}
                        <TextInput
                            label={"Street Address"}
                            placeholder={"Street Address"}
                            value={userDetails?.street}
                            onChange={(e) => handleChange(e)}
                            name="street"
                        />
                        {validationErrors?.street && <span className="error">{validationErrors?.street}</span>}
                    </div>

                    <div className='hair-product-input-container'>
                        {/* Companies House Registration Number*/}
                        <TextInput
                            label={"Flat or Apartment number (Optional)"}
                            placeholder={"Flat or Apartment number (Optional)"}
                            value={userDetails?.apartment}
                            onChange={(e) => handleChange(e)}
                            name="apartment"
                        />
                        {validationErrors?.apartment && <span className="error">{validationErrors?.apartment}</span>}
                    </div>

                    <div className='hair-product-input-container'>
                        {/* Companies House Registration Number*/}
                        <TextInput
                            label={"City"}
                            placeholder={"City"}
                            value={userDetails?.city}
                            onChange={(e) => handleChange(e)}
                            name="city"
                        />
                        {validationErrors?.city && <span className="error">{validationErrors?.city}</span>}
                    </div>

                    <div className='hair-product-input-container'>
                        {/* Companies House Registration Number*/}
                        <TextInput
                            label={"Postcode"}
                            placeholder={"Postcode"}
                            value={userDetails?.postcode}
                            onChange={(e) => handleChange(e)}
                            name="postcode"
                        />
                        {validationErrors?.postcode && <span className="error">{validationErrors?.postcode}</span>}
                    </div>

                    <div className='hair-product-input-container'>
                        {/* Companies House Registration Number*/}
                        <TextInput
                            label={"Country"}
                            placeholder={"Country"}
                            value={userDetails?.country}
                            onChange={(e) => handleChange(e)}
                            name="country"
                        />
                        {validationErrors?.country && <span className="error">{validationErrors?.country}</span>}
                    </div>

                    {/* PHONE NUMBER */}
                    <div className="hair-product-input-container">
                        <p className="form-label">Phone Number</p>
                        <div className='CountryInputWrapper'>
                            {/* <CountrySelect labels={{ ZZ: userDetails.phoneCountryCode ? userDetails.phoneCountryCode : "select" }} value={userDetails.phoneCountryCode} onChange={(e) => handleCountryChange(e)} /> */}
                            <input
                                type="text"
                                name="phoneCountryCode"
                                value={userDetails?.phoneCountryCode}
                                className='fix-countrycode'
                            />
                            <TextInput
                                name="phone"
                                value={userDetails?.phone}
                                onChange={(e) => handleChange(e)}
                                placeholder="Enter phone number"
                            />
                        </div>
                        {/* {validationErrors.phoneCountryCode && <span className="error">{validationErrors.phoneCountryCode}</span>} */}
                        {validationErrors.phone && <span className="error">{validationErrors.phone}</span>}
                    </div>

                </div>
                <div className='bottomStickeyBtn'>
                    <div className="continue-btn-div">
                        <StepButton label={"Continue"} blue={true} onClick={handleContinue} />
                    </div>
                    <div className="whatsapp-margin">
                        <HairProductWhatsAppButton />
                    </div>
                </div>
            </div>

        </>
    )
}

export default HairProductsFive