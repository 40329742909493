import React, { useEffect, useState } from "react";
import StepButton from "../../../../common/StepButton";
import { ReactComponent as RightIcon } from "../../../../../images/calander_next.svg";
import BusinessNavigation from "../../../../common/businessNavigation/BusinessNavigation";
import SendTextSms from "./SendTextSms/SendTextSms";
import ChoosePlan from "./choosePlan/choosePlan";
import Payment from "./payment/Payment";
import { GetMarketingSMSDetailsAction } from "../../../../redux/actions/GetMarketingSMSDetails/GetMarketingSMSDetails";
import "./Sms.scss";
import Skeleton from "react-loading-skeleton";
import ShopRightCard from "../../../../businessSetting/manageAccount/Shop/RightCard/ShopRightCard";

const Sms = ({ setParentStep }) => {
  const [step, setStep] = useState(0);
  const [textBalance, settextBalance] = useState();
  const {  initGetMarketingSMSDetails } = GetMarketingSMSDetailsAction();

  useEffect(() => { initGetMarketingSMSDetails({}, (res) => { settextBalance(res?.data?.availableTextBalance) }, (err) => { console.log(err) }) }, [step ,setParentStep])
  console.log(textBalance === undefined, "textBalance")
  const renderTabContent = () => {
    if (step == 1) {
      return <SendTextSms setStep={setStep} />;
    }
    if (step == 2) {
      return <ChoosePlan setParentStep={setStep} />;
    }

  };

  return (
    <>
      <div className="Main-Sms-page ">
        {step > 0 ? (
          renderTabContent()
        ) : (
          <div className="rightSidebarWrapper">
            <div className="Main684WidthSet">
              <BusinessNavigation
                isColumn={true}
                title={"SMS"}
                desc={"Buy SMS credits to send SMS reminders to clients."}
                onBackClick={() => {
                  setParentStep(0);
                }}
              />
              <div className="bottomButtonWrapper">
                <div className="cardSms">
                  {textBalance===undefined? <Skeleton className="cardSmsLeftContent" width={111} height={40}/>: <div className="cardSmsLeftContent">
                    <h5>{textBalance} text left</h5>
                    <p>Get more credits</p>
                  </div>}
                 
                 
                  <div
                    className="cardSmsRightContent"
                    onClick={() => {
                      setStep(2);
                    }}
                  >
                    <StepButton label="Buy Now" />
                  </div>
                </div>
                <div
                  className="cardSms greyBox"
                  onClick={() => {
                    // setStep(1);
                     textBalance > 0 && setStep(1); 
                  }}
                >
                  <div className="cardSmsLeftContent">
                    <h5>Send SMS</h5>
                    <p>
                      Send an SMS directly to your client as a reminder or
                      promo alert!
                    </p>
                  </div>

                  <div className="cardSmsRightContent">
                    <RightIcon />
                  </div>
                </div>
                {textBalance === 0 && <span className="error"> Your text message balance is '0' please buy more text messages before send any messages</span>}
              </div>
            </div>

            <div className='RightOverView'>
              <div className="overHeading">
                <h5>Overview</h5>
              </div>
              <div className="Content">
                <p>Sending text reminders and any other messages to your clients is simple. Just purchase the amount of texts you need, and select the clients you want to text. </p>
                <p>In case the message character length is greater than 160, the message will be sent in multiple parts.</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Sms;
