import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { SET_PAYMENT_INTENT } from "../../constants/hair-prescription-product/HairProductConts";
import showToast from "../../../common/CustomToast/CustomToast";

const SAVE_HAIR_PRESCRIPTION_REQUEST = gql`
mutation SaveHaiPrescriptionRequest($slug: String, $prdId: Int, $interval: Int, $clientFname: String, $clientLname: String, $clientEmail: String, $clientPhone: String, $clientPhoneCountryCode: String, $clientPhoneCountry: String, $clientHouseNo: String, $clientStreetName: String, $clientCity: String, $clientCountry: String, $clientPostcode: String, $clientAnswers: [clientAnswersObject], $generatePaymentIntent: Boolean, $orderId: Int , $image: [saveHaiPrescriptionRequestTypeImage]) {
    saveHaiPrescriptionRequest(slug: $slug, prd_id: $prdId, interval: $interval, client_fname: $clientFname, client_lname: $clientLname, client_email: $clientEmail, client_phone: $clientPhone, client_phone_country_code: $clientPhoneCountryCode, client_phone_country: $clientPhoneCountry, client_house_no: $clientHouseNo, client_street_name: $clientStreetName, client_city: $clientCity, client_country: $clientCountry, client_postcode: $clientPostcode, client_answers: $clientAnswers, generatePaymentIntent: $generatePaymentIntent, orderId: $orderId, image: $image) {
      success
      message
      data {
        intentId
        productReferenceId
      }
    }
  }
`;

export const SaveHaiPrescriptionRequestMutation = () => {
    const [saveHaiPrescriptionRequest, SaveHaiPrescriptionRequestResponseData] = useMutation(SAVE_HAIR_PRESCRIPTION_REQUEST);
    const dispatch = useDispatch();
    const SaveHaiPrescriptionRequestMutationData = SaveHaiPrescriptionRequestResponseData;

    const initiateSaveHaiPrescriptionRequestResponseData = (
        data,
        successCallback,
        errorCallback
    ) => {
        saveHaiPrescriptionRequest({
            variables: data,
        })
            .then(({ data: { saveHaiPrescriptionRequest: responseData } }) => {
                if (responseData.success) {
                    console.log("hold duration", responseData.success)
                    dispatch({
                        type: SET_PAYMENT_INTENT,
                        payload: responseData.data
                    })
                    successCallback(responseData);
                } else {
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                errorCallback(error);
            });
    };
    return { initiateSaveHaiPrescriptionRequestResponseData, SaveHaiPrescriptionRequestMutationData };
};


// ========================UPDATE HAIR PRESCRIPTION PAYMENT========================

const UPDATE_HAIR_PRESCRIPTION_PAYMENT_MUTATION = gql`
mutation UpdateHairPrescriptionPayment($orderId: Int, $intentId: String, $token: String, $stripeSavedCardId: String) {
    updateHairPrescriptionPayment(orderId: $orderId, intentId: $intentId, token: $token, stripeSavedCardId: $stripeSavedCardId) {
      success
      message
    }
  }
`;

export const UpdateHairPrescriptionPaymentAction = () => {
    const dispatch = useDispatch();
    const [
        UpdateHairPrescriptionPaymentResponseFunction,
        updateHairPrescriptionPaymentResponseData,
    ] = useMutation(UPDATE_HAIR_PRESCRIPTION_PAYMENT_MUTATION)

    //this will be called from components
    const updateHairPrescriptionPaymentQueryData = updateHairPrescriptionPaymentResponseData; //variable

    const initUpdateHairPrescriptionPaymentData = (data, successCallback, errorCallback) => {
        UpdateHairPrescriptionPaymentResponseFunction({
            variables: data,
        }).then(({ data: { updateHairPrescriptionPayment: responseData } }) => {
            console.log('responseData', responseData)
            if (responseData.success) {
                if (successCallback)
                    successCallback()
                showToast("success", responseData.message, "", false)
            }
            else {
                if (errorCallback)
                    errorCallback()
                // toast.error(responseData.message)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
            // toast.error("something went wrong!!!")
        })
    }

    return { updateHairPrescriptionPaymentQueryData, initUpdateHairPrescriptionPaymentData };
};

