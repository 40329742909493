import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../../../images/back_arrow.svg";
import { ReactComponent as Uploads } from "../../../images/Image.svg";
import "./finance-identity.scss";
import {useDropzone} from 'react-dropzone'
import { useNavigate } from "react-router";
import { FinanceTrainingCertificateRoute } from "../../../Routes";
import closeIcon from "../../../images/close_icon.svg";
import CustomModal from "../../modal/CustomModal";
import deleteIcon from "../../../images/deleteIcon.svg";
import ConfirmationModal from "./finance-confirmationModal";
import FinanceHeader from "../../finance-registration/FinanceHomeComponent/FinanceHeaderComponent/FinanceHeaderComponent";
import FinanceBanner from "../../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner";
import StepButton from "../../common/StepButton";
import { IoEyeSharp } from "react-icons/io5";
import {SaveFinanceIdentityAction} from "../../redux/actions/finance-registrtion/saveFinanceIdentity"
import {GetFinanceIdentityAction} from "../../redux/actions/finance-registrtion/getFinanceIdentity"
import {DeleteFinanceIdentityAction} from "../../redux/actions/finance-registrtion/deleteFinanceIdentity"
import {GetFinanceRegistrationStatusAction} from "../../redux/actions/finance-registrtion/getFinanceRegistartionStatus"
import { MdOutlineImage } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';


export default function FinanceIdentity({onNextStep,afterSubmit,finish,setComeFromDashBord}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [previewImg, setPreviewImg] = useState(false);
  const [isuploadedImage,setIsuploadedImage]=useState(false)
  const [isGetApiImg,setIsGetApiImg]=useState(false)
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imagename,setImageName]=useState("")
  const [errorMessage, setErrorMessage] = useState("");
  const [getApiData,setGetApiData]=useState([])
  const {saveFinanceIdentityQueryData,initSaveFinanceIdentityData}=SaveFinanceIdentityAction();
  const {getFinanceIdentityQueryData,initGetFinanceIdentityData}=GetFinanceIdentityAction();
  const {deleteFinanceIdentityQueryData,initDeleteFinanceIdentityData}=DeleteFinanceIdentityAction()
  const {getFinanceRegistrtionStatusQueryData,initGetFinanceRegistrationData}=GetFinanceRegistrationStatusAction()
const {data}=getFinanceIdentityQueryData;
console.log("dddd",data?.getIdentityDocumentForFinance?.data);

useEffect(()=>{
  initGetFinanceRegistrationData()
},[])
const completedStep=getFinanceRegistrtionStatusQueryData?.data?.getUserRegistrationStatusForFinance?.data;
    console.log("completedStep2",completedStep);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/*, .pdf",
    noClick:(uploadedImage || getApiData?.id  ? true :false),
    onDrop: (acceptedFiles) => {
      console.log("Accepted Files",acceptedFiles)
      if (acceptedFiles) {
        const uploadfile = acceptedFiles[0];
        console.log("file",uploadfile.type)
        
        if (uploadfile.type === "application/pdf") {
          setUploadedImage(uploadfile)
          setImageName(uploadfile.path)
         setIsuploadedImage(true)
          
        }else{
          setUploadedImage(uploadfile);
          setIsuploadedImage(true)
          // setErrorMessage("");
        }
        //  else {
        //   setErrorMessage("You can only upload image or PDF files.");
        // }
      } else {
        setErrorMessage("You can only upload image or PDF files.");
      }
    },
  });

  useEffect(()=>{
   if(data?.getIdentityDocumentForFinance?.data){
    // setGetApiData(data?.getIdentityDocumentForFinance?.data)
    setGetApiData(data?.getIdentityDocumentForFinance?.data)
    setIsGetApiImg(true)
   
   }
  },[data?.getIdentityDocumentForFinance?.data])


 
  useEffect(()=>{
    window.scrollTo(0, 0);
},[])

  const removeImage = () => {
    if(data?.getIdentityDocumentForFinance?.data){
      initDeleteFinanceIdentityData({
        usrVrfId:data?.getIdentityDocumentForFinance?.data?.id
      },(data)=>{
        toast.success(data?.data?.deleteIdentityDocumentForFinance?.message)
        setUploadedImage(null);
        setImageName("")
        setIsuploadedImage(false)
        setGetApiData([])
        setPreviewImg(false)
        setIsGetApiImg(false)
    },(error)=>console.log(error))
    }
   
      setUploadedImage(null);
      setImageName("")
      setOpen(false);
      setGetApiData([])
      setPreviewImg(false)
      setIsuploadedImage(false)
      setIsGetApiImg(false)
    
  };
  const handleContinue = () => {
    console.log(getApiData,"uploadedImage1",uploadedImage)
    if(isGetApiImg && getApiData?.id && !isuploadedImage){
      toast.success("Identity document successfully upload!")
      afterSubmit()
      onNextStep()
    }else if(!uploadedImage){
      setErrorMessage("Please add driving license or passport")
      // toast.error("Please add driving license or passport");
      return;
    }

    initSaveFinanceIdentityData({
      identityDoc:uploadedImage
    },(data)=>{
     toast.success(data?.data?.saveIdentityDocumentForFinance?.message)
      afterSubmit()
      onNextStep()
      setComeFromDashBord(false)
    },(error)=>{console.log("EEEEEE",error)})
};

console.log("img11",uploadedImage);
 useEffect(()=>{
  initGetFinanceIdentityData()
 },[])

 const openPDf=()=>{
  window.open(getApiData?.identity_url)
}

 console.log(uploadedImage, "uploadedImage")

  return (
    <div className="prescriber_Main_identity">
          <div className="Identity-FormWrapper">
          <FinanceBanner
            title="Identity"
            desc="we just need some ID now to confirm your identity."
            isBorder={!completedStep?.registration_complete && completedStep?.registration_complete !== undefined}
            currentStep={finish}
            totalSteps={4}
            isTranningBanner={false}
        />
              <p className="form-label">Driving license or passport</p>
              <div className="customeDropDownWrapper">
               <div className="customeDropDown" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                   uploadedImage ? 

                  (imagename.includes(".pdf") ?(
                  <p onClick={()=>openPDf()}>{imagename}</p>):(
                      <>
                      {
                        uploadedImage && (
                        <>
                          <img
                          src={URL.createObjectURL(uploadedImage)}
                          alt="Uploaded"
                          onClick={()=> {setPreviewImg(true)}}
                          />
                          
                          <div className="btnClose1">
                            <img
                              className="closeBtn"
                              src={closeIcon}
                              alt="modalClose"
                              onClick={() => setOpen(true)}
                            />
                          </div>
                      </>
                        )
                      }
                  </>
                    )):(
                     data && data?.getIdentityDocumentForFinance?.data?.identity_url.includes('.pdf') ?
                      (
                        !getApiData?.identity_url? (
                          <div>
                          <MdOutlineImage className='dropImage'/>
                            <p className="upload">Upload</p>
                          </div>
                        ):(
                          <div>

                          <p onClick={()=>openPDf()}>{getApiData?.identity_url}</p>
                          <div className="btnClose1">
                            <img
                              className="closeBtn"
                              src={closeIcon}
                              alt="modalClose"
                              onClick={() => setOpen(true)}
                            />
                          </div>
                          </div>
                          
                        )
                      ):
                      (
                        !getApiData?.identity_url ? (
                          <div>
                          <MdOutlineImage className='dropImage'/>
                            <p className="upload">upload</p>
                        </div>
                        ):(
                          <>
                            <img 
                              src={getApiData?.identity_url} 
                              alt="image"
                              onClick={()=> {setPreviewImg(true)}}
                            />
                            <div className="btnClose1">
                              <img
                                className="closeBtn"
                                src={closeIcon}
                                alt="modalClose"
                                onClick={() => setOpen(true)}
                              />
                            </div>
                          </>
                          
                          )
                      )
                    )
                }
              </div>
               
              </div>
              {errorMessage  && (
                <p className="error">{errorMessage}</p>
              )}

              <div className="third-flex-div">
                  <StepButton 
                  label={"Continue"} 
                  blue={true} 
                  isLoading={saveFinanceIdentityQueryData.loading}
                  onClick={() => handleContinue()} />
              </div>
        </div>
      <div>
      </div>
     
    {/* Modle Previw*/}
        <CustomModal
          modalOpen={previewImg}
          setModalOpen={setPreviewImg}
          type="review"
          className={"previewImgModel"}
          modalBody={
            <>
         <div className='previewImgModelWrapper'>
          {
            isuploadedImage ?
           (
              <img
                src={URL.createObjectURL(uploadedImage)}
                alt="Uploaded"/>
            )
          :
          (
            data && !data?.getIdentityDocumentForFinance?.data?.identity_url.includes('.pdf') &&
                (
                  <>
                    <img 
                      src={data?.getIdentityDocumentForFinance?.data?.identity_url} 
                      alt="image"
                    />
                  </>
                )
          )
          }
           </div>

            <div className="RemovePreviewImg">
                <button className="OutLineBtn" onClick={()=>{ setOpen(true)}}>Remove</button>
            </div>
          </>
          }
          modaltitle="How it works?"
      />
            

      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        title="Are you sure?"
        className={"ConfirmationDeleteModal"}
        content="you want to remove this document?"
        onConfirm={() => {
         removeImage();
          setOpen(false);
          setPreviewImg(false);
        }}
        onCancel={() => setOpen(false)}
      />
    </div>
  );
}
