import { gql, useMutation } from "@apollo/client";

const CREATE_OR_UPDATE_PRODUCT_QUESTIONS=gql`
mutation CreateOrUpdateProductQuestion($productId: Int!, $question: ProductQuestionInputType) {
    createOrUpdateProductQuestion(productId: $productId, question: $question) {
      success
      message
    }
  }
`;


export const CreateOrUpdateProductQuestionAction=()=>{
    const [createOrUpdateProductQuestionResponseFunction,
          createOrUpadteProductQuestionResponseData] = useMutation(CREATE_OR_UPDATE_PRODUCT_QUESTIONS)


     const createOrUpdateProductQuestionQueryData=createOrUpadteProductQuestionResponseData;
     
     const initcreateOrUpdateProductQuestionData=(data, successCallback, errorCallback)=>{
            createOrUpdateProductQuestionResponseFunction({
                variables:data
            }).then((responseData)=>{
                if(responseData.data.createOrUpdateProductQuestion.success){
                    successCallback(responseData)
                }else{
                    errorCallback(responseData)
                }
            }).catch((error)=>{
                errorCallback(error)
            })
     }

     return { createOrUpdateProductQuestionQueryData , initcreateOrUpdateProductQuestionData}
}