import React, { useState } from "react";
import BusinessNavigation from "../../../../../common/businessNavigation/BusinessNavigation";
import StepButton from "../../../../../common/StepButton";
import { StripeComponent } from "../../../../stripe-payment/stripe-component";
import { ReactComponent as CheckIcon } from "../../../../../../images/sprint6/check.svg";
import "./Payment.scss";

const Payment = ({ setStep }) => {
  const [process, setProcess] = useState("Place Order");

  return (
    <div className="Main-Purchase-Plan ">
      <div className="rightSidebarWrapper">
        <div className="Main684WidthSet">
          <BusinessNavigation
            isColumn={true}
            title={"Send promo sms to your clients"}
            desc={"Send client reminders and promo text messages!"}
            onBackClick={() => {
              setStep(0);
            }}
          />
          <div className="CardDivWrapper">
            <label className="form-label">Card number</label>
            <StripeComponent
              paymentType="card"
              isCloseIcon={false}
              stripeChildCustomCSS={"w-100"}
              setProcess={setProcess}
              useSetupIntentFlow={true}
              customButton={
                <div className="continue-btn-div">
                  <StepButton label={process} blue={true} />
                </div>
              }
              cardLogo={false}
            />
          </div>
          <div className="cardContentWrapper">
            <div className="selectedPlan">
              <span>
                Selected plan is: <b>PRO</b>
              </span>
            </div>
            <p>
              You are now purchasing 500 SMS text messages for £ 30. Which can
              be used for promotional text offers and client reminders.
            </p>
            <div className="btnWrapper">
              <StepButton className={"outline"} label={"Apple Pay"} />
              <StepButton label={"Pay"} />
            </div>
          </div>
        </div>
        <div className="RightOverView">
          <div className="overHeading">
            <h5>Overview</h5>
          </div>
          <div className="PlanContent">
            <div className="PlanHeading">
              <h6>Pro plan</h6>
              <span>
                £30.00 <sub>/ month</sub>
              </span>
            </div>
            <ul className="PlanPointsList">
              <li>
                <span className="pointIcon">
                  <CheckIcon />
                </span>
                <span>Everything in free</span>
              </li>
              <li>
                <span className="pointIcon">
                  <CheckIcon />
                </span>
                <span>Custom domain</span>
              </li>

              <li>
                <span className="pointIcon">
                  <CheckIcon />
                </span>
                <span>Logo design*</span>
              </li>
              <li>
                <span className="pointIcon">
                  <CheckIcon />
                </span>
                <span>Perfect for training academies </span>
              </li>
              <li>
                <span className="pointIcon">
                  <CheckIcon />
                </span>
                <span>Selling products</span>
              </li>
              <li>
                <span className="pointIcon">
                  <CheckIcon />
                </span>
                <span>express support</span>
              </li>
              <li>
                <span className="pointIcon">
                  <CheckIcon />
                </span>
                <span>Free marketing tool (GHL)</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
