import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


// ========================GET FACIAL MAPPING DATA========================
const GET_FACIAL_MAPPING_DATA_QUERY = gql`
query GetFacialMappingData($appointmentId: Int) {
    getFacialMappingData(appointmentId: $appointmentId) {
      success
      message
      data {
        appointmentId
        image
        canvasImage
        canvasNote
        facialMappingStatus
      }
    }
  }
`;

export const GetFacialMappingDataAction = () => {
    const dispatch = useDispatch();
    const [
        GetFacialMappingDataResponseFunction,
        getFacialMappingDataResponseData,
    ] = useLazyQuery(GET_FACIAL_MAPPING_DATA_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getFacialMappingData: responseData }) => {
            if (responseData.success) {
                // dispatch({
                //     type: GET_RATING_REVIEWS_CONST,
                //     payload: responseData
                // })
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getFacialMappingDataQueryData = getFacialMappingDataResponseData; //variable

    const initGetFacialMappingDataData = (data, successCallback, errorCallback) => {
        GetFacialMappingDataResponseFunction({
            variables: data,
        }).then(res => {
            if (res.data.getFacialMappingData.success)
                successCallback(res.data.getFacialMappingData.data)
            else
                errorCallback(res.data.getFacialMappingData)
        });
    };

    return { getFacialMappingDataQueryData, initGetFacialMappingDataData };
};

// // ========================SAVE FACIAL MAPPING DATA========================

const SAVE_FACIAL_MAPPING_DATA_MUTATION = gql`
mutation SaveFacialMappingData($appointmentId: Int, $image: String, $canvasImage: String, $canvasNote: String) {
    saveFacialMappingData(appointmentId: $appointmentId, image: $image, canvasImage: $canvasImage, canvasNote: $canvasNote) {
      success
      message
      data {
        appointmentId
        facialMappingStatus
      }
    }
  }
`;


export const SaveFacialMappingDataAction = () => {
    const dispatch = useDispatch();
    const [
        SaveFacialMappingDataResponseFunction,
        saveFacialMappingDataResponseData,
    ] = useMutation(SAVE_FACIAL_MAPPING_DATA_MUTATION)

    //this will be called from components
    const saveFacialMappingDataQueryData = saveFacialMappingDataResponseData; //variable

    const initSaveFacialMappingDataData = (data, successCallback, errorCallback) => {
        SaveFacialMappingDataResponseFunction({
            variables: data,
        }).then(({ data: { saveFacialMappingData: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
                // toast.success(responseData.message)
            }
            else {
                if (errorCallback)
                    errorCallback(responseData)
                // toast.error(responseData.message)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback({ message: "something went wrong!!!" })
            // toast.error("something went wrong!!!")
        })
    }

    return { saveFacialMappingDataQueryData, initSaveFacialMappingDataData };
};
