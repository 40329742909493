import React, { useEffect, useState } from 'react'
import { frontendURL, webBaseUrl } from '../../../config'
import serviceIcon1 from "../../../images/icons/service1.svg"
import serviceIcon10 from "../../../images/icons/service10.svg"
import serviceIcon2 from "../../../images/icons/service2.svg"
import serviceIcon3 from "../../../images/icons/service3.svg"
import serviceIcon4 from "../../../images/icons/service4.svg"
import serviceIcon5 from "../../../images/icons/service5.svg"
import serviceIcon6 from "../../../images/icons/service6.svg"
import serviceIcon7 from "../../../images/icons/service7.svg"
import serviceIcon8 from "../../../images/icons/service8.svg"
import serviceIcon9 from "../../../images/icons/service9.svg"
import serviceIcon11 from "../../../images/icons/accountancy.png"
import ClientsIcon from "../../../images/icons/ClientsIcon.svg"
import MedicalformIcon from "../../../images/icons/Medicalform.svg"

import './services-popup.scss'
import { useSelector } from 'react-redux'
import { USER_ROLES } from '../../../utils/constants'
import { accountancyServicePagePattern, getPrescriberRegistrationPage, getSearchClientRoute, insuranceLandingPagePattern, socialMediaMarketingPagePattern } from '../../../Routes'
import { useNavigate } from 'react-router'
import { domainName } from '../../../styles'

const ServicesPopUp = () => {
  const navigate=useNavigate()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)
  const profileData = useSelector(state => state?.GetProfileDataReducer?.profileData)
  let rolesList = profileData?.roles ? profileData?.roles : [];
  let prescriptionRedirectionLink = webBaseUrl + '/prescriber/register';
  let { prescriber, non_prescriber, non_registered_prescriber } = USER_ROLES
  if(rolesList.indexOf(non_registered_prescriber) > -1) {
      prescriptionRedirectionLink = webBaseUrl + '/pharmacy/list';
  }
  else if (rolesList.indexOf(non_prescriber) > -1) {
      prescriptionRedirectionLink = webBaseUrl + '/prescription/request-prescription';
  }
  else if (rolesList.indexOf(prescriber) > -1) {
      prescriptionRedirectionLink = webBaseUrl + '/prescription/create';
  }
  const [trainingAcademyUrl, setTrainingAcademyUrl] = useState();

  const TRAINING_PROVIDER_ROLE = 2;
  const isTrainingProvider = () => {
      return rolesList.includes(TRAINING_PROVIDER_ROLE);
  };

    useEffect(() => {
        let trainingPortalLink = isTrainingProvider() ? webBaseUrl + "/training-dashboard/dashboard" : webBaseUrl + "/training-dashboard/student-portal-dashboard";

        setTrainingAcademyUrl(trainingPortalLink);
    }, [rolesList]);

  const essentialsData = [
    {
      title: "Clients",
      imageIcon: ClientsIcon,
      onclick: () => navigate(getSearchClientRoute())
  },
  {
    title: "Finance hub",
    imageIcon: serviceIcon4,
    onclick:(()=>navigate('/finance-registration'))
  },
  // {
  //     title: "Medical form",
  //     imageIcon: MedicalformIcon,
  //     redirectUrl: webBaseUrl + '/medical-questions/edit'

  // },
    {
      title: "Prescriptions",
      imageIcon: serviceIcon1,
      redirectUrl: webBaseUrl + '/prescription/request-prescription'
      // redirectUrl: prescriptionRedirectionLink,
    },
    {
      title: "Shop",
      imageIcon: serviceIcon2,
      redirectUrl: webBaseUrl + '/shop'

    },
    {
      title: "Training academy",
      imageIcon: serviceIcon3,
      // /training/frontend-list
      // redirectUrl: webBaseUrl + '/faces-training-dashboard'
      // redirectUrl: webBaseUrl + '/training/frontend-list'
      redirectUrl: trainingAcademyUrl,
    },
 
    {
      title: "Insurance",
      imageIcon: serviceIcon5,
      // redirectUrl: webBaseUrl + '/insurance/company'
      onclick: () => navigate(insuranceLandingPagePattern())
    },
    {
      title: "Complication Consultant Helpline",
      imageIcon: serviceIcon6,
      redirectUrl: webBaseUrl + '/aesthetic-help-line'
    },
    {
      title: "Brand boost",
      imageIcon: serviceIcon7,
      // redirectUrl: frontendURL + '/social-media-marketing'
      onclick: () => navigate(socialMediaMarketingPagePattern())
    },
    {
      title: "FacePay",
      imageIcon: serviceIcon8,
      redirectUrl: webBaseUrl + '/yotta/take-payment'
    },
    {
      title: "Sharps Collection",
      imageIcon: serviceIcon9,
      redirectUrl: webBaseUrl + '/sharp-collections'
    },
    {
      title: "Accountancy",
      imageIcon: serviceIcon11,
      // redirectUrl: frontendURL + '/accountancy-service'
      onclick: () => navigate(accountancyServicePagePattern())

    },
  ]

  console.log( window.location.href," window.location.href")

  return (
    <div className='container'>
      <div className=''>
        <div className='service-container row'>
          {
            essentialsData.map((item, index) =>
              <div className='col-md-4 col-6' key={index}>
                {item.redirectUrl ?   <div className='service-card' onClick={() => {
                   if (item.title === "Prescriptions") {
                    console.log("clicked inside", item.title)
                    UserProfileData?.user?.userRoles.forEach((role) => {
                      console.log(role, "role");
                      if (role === 4) {
                        // for practitioner
                        window.location.href = webBaseUrl + "/prescription/request-prescription";
                      }
                      else if (role === 3) {
                        // for prescriber
                        window.location.href = webBaseUrl + "/prescription/create";
                      }
                    });
              
                    // If neither 3 nor 4 exists, navigate to the default page.
                    if (!UserProfileData?.user?.userRoles.includes(3) && !UserProfileData?.user?.userRoles.includes(4)) {
                      navigate(getPrescriberRegistrationPage(), { state: { from: 'plus_icon_header_bar' } });
                    }
                  }
                  else {
                    window.location.href = item.redirectUrl
                  }
 
                }
               }>
                  <div className=''>
                    <img src={item.imageIcon}  height={"29px"}  alt="" className='mb-2' />
                    <p>{item.title}</p>
                  </div>
                </div>:  
                 <div className='service-card'  onClick={ item?.onclick ? item.onclick :null}>
                  <div className=''>
                    <img src={item.imageIcon}  height={"29px"}  alt="" className='mb-2' />
                    <p>{item.title}</p>
                  </div>
                </div>}
             
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default ServicesPopUp
