import React, { useEffect, useState } from "react";
import { Badge, Form, FormGroup, Label } from "reactstrap";
import SkipButtonComp from "./commonComponents/SkipBtn";
import StepButton from "../../common/StepButton";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import loadingIcon from "../../../images/loading.gif";
import "aos/dist/aos.css";
import AOS from "aos";
import ImageTitleComponent from "./commonComponents/ImgTitleComp";
import { SaveWebsiteHeadline } from "../../redux/actions/createWebsite/saveWebsiteHeadline";
import { GenerateBusinessContentAction } from "../../redux/actions/createWebsite/generateBusinessContent";
import { useSelector } from "react-redux";
import { set } from "lodash";
import { GetWebsiteDetailsAction } from "../../redux/actions/createWebsite/getWebsiteDetails";
import InputComponent from "../../common/InputComponent";

const WebsiteBussTagline = (props) => {
  const { style, isMobile, prevWebOnClick, stepFourData, count, setCount, tempSaveTaglineData, setTempSaveTaglineData } =
    props;
  const [headlineText, setHeadlineText] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [selectedType, setSelectedType] = useState(1);
  const { initiateSaveWebsiteHeadline, saveWebsiteHeadlineMutationData } = SaveWebsiteHeadline();
  const { initGenerateBusinessContentResponseFunction, generateBusinessContentResponseData: { loading: generateContent } } = GenerateBusinessContentAction();
  const [generateHeadlineClicked, setGenerateHeadineClicked] = useState(false);
  const [generateDescriptionClicked, setGenerateDescriptionClicked] = useState(false);
  const businessContentHeadline = useSelector(
    (state) => state.WebsiteDetailsReducer.businessContentHeadline
  );
  const businessContentDescription = useSelector(
    (state) => state.WebsiteDetailsReducer.businessContentDescription
  );
  const { initGetWebsiteDataResponseFunction } = GetWebsiteDetailsAction();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-in-out",
    });
    return () => {
      AOS.refresh();
    };
  }, []);

  const handleHeadlineClick = () => {
    setSelectedType(1);
    initGenerateBusinessContentResponseFunction({ type: 1 });
    setGenerateHeadineClicked(true);
    // props.setWebsiteData({
    //   ...props.websiteData,
    //   headline: "This headline is generated by AI",
    // });
  };

  const handleDescriptionClick = () => {
    setSelectedType(2);
    // setDescriptionText("This description is generated by AI");
    initGenerateBusinessContentResponseFunction({ type: 2 });
    setGenerateDescriptionClicked(true);

    // props.setWebsiteData({
    //   ...props.websiteData,
    //   description: "This description is generated by AI",
    // });
  };

  const handleSkipClick = () => {
    props.trackActivity({ activityStep: props.step + 2 });
    props.setWebsiteData({
      ...props.websiteData,
      headline: tempSaveTaglineData.headline,
      description: tempSaveTaglineData.description,
    });
    props.setStep(props.step + 1);
  };

  useEffect(() => {
    if (businessContentDescription && generateDescriptionClicked) {
      props.setWebsiteData({
        ...props.websiteData,
        description: businessContentDescription,
      });
    }
  }, [businessContentDescription]);

  useEffect(() => {
    if (businessContentHeadline && generateHeadlineClicked) {
      props.setWebsiteData({
        ...props.websiteData,
        headline: businessContentHeadline,
      });
    }
  }, [businessContentHeadline]);

  useEffect(() => {
    console.log(tempSaveTaglineData, "tempSaveTaglineData in useEffect");
    if (
      props.websiteData.headline &&
      props.websiteData.description &&
      count.step4 === 0
    ) {
      setTempSaveTaglineData({
        headline: props.websiteData.headline,
        description: props.websiteData.description,
      });
      setCount({
        ...count,
        step4: 1,
      });
    }
  }, [props.websiteData.headline, props.websiteData.description]);
  console.log(tempSaveTaglineData, "tempSaveTaglineData in tagline page");
  return (
    <>
      <div className="inputWrapper">
        <div className="formWrapper">
          <div className="formInnerHeight" style={style} id="example-anchor">
            <ImageTitleComponent {...stepFourData} />
            <Form>
              <FormGroup
                className="inpt_field"
                data-aos="fade-up"
                data-aos-duration={700}
                data-aos-anchor="#example-anchor"
              >
                <InputComponent
                  type="welcomeText"
                  label={"Welcome text (optional)"}
                  placeholder={`Welcome to ${props?.websiteData?.businessName}`}
                  value={props.websiteData?.welcomeText}
                  setWebsiteData={props.setWebsiteData}
                  websiteData={props?.websiteData}
                  dataAos="fade-up"
                  dataAosDuration={700}
                  dataAosAnchor="#example-anchor"
                />
                <Label className="lable">Business name headline (optional) </Label>
                <div className="inputCounting">
                  <input
                    className="input"
                    type="text"
                    name="businessName"
                    id="businessName"
                    placeholder="E.g. Because you’re worth it"
                    value={props.websiteData?.headline}
                    disabled={selectedType === 1 && generateContent}
                    onChange={(e) =>
                      props.setWebsiteData({
                        ...props.websiteData,
                        headline: e.target.value,
                      })
                    }
                  />
                  <p className="text-area-limit">
                    {props.websiteData?.headline?.length}/150
                  </p>
                </div>

                <div className="budge-class">
                  <Badge
                    className="btn tagline_budge"
                    onClick={handleHeadlineClick}
                  >
                    {selectedType === 1 && generateContent ? (
                      <div className="m-auto">
                        <span className="pe-3">Generating</span>
                        <img
                          src={loadingIcon}
                          style={{ width: "20px", height: "20px" }}
                        />
                        <span> </span>
                      </div>
                    ) : (
                      <div className="m-auto">Generate headline</div>
                    )}
                  </Badge>
                </div>
              </FormGroup>
              <FormGroup
                className="inpt_field"
                data-aos="fade-up"
                data-aos-duration={900}
                data-aos-anchor="#example-anchor"
              >
                <Label className="lable">Tell us about your business (optional)</Label>
                <div className="textAreaWrapper">
                  <textarea
                    className="input textarea"
                    type="text"
                    disabled={selectedType === 2 && generateContent}
                    // name="businessName"
                    // id="businessName"
                    maxlength="750"
                    style={{
                      paddingBottom: "140px",
                      border: "none",
                      boxShadow: "none",
                    }}
                    placeholder="Small bio about the services you offer..."
                    value={props.websiteData?.description}
                    onChange={(e) =>
                      props.setWebsiteData({
                        ...props.websiteData,
                        description: e.target.value,
                      })
                    }
                  ></textarea>
                  <p className="text-area-limit">
                    {props.websiteData?.description?.length}/750
                  </p>
                </div>
                <div className="budge-class">
                  <Badge
                    className="btn tagline_budge"
                    onClick={() => handleDescriptionClick()}
                  >
                    {selectedType === 2 && generateContent ? (
                      <div className="m-auto">
                        <span className="pe-3">Generating</span>
                        <img
                          src={loadingIcon}
                          style={{ width: "20px", height: "20px" }}
                        />
                        <span> </span>
                      </div>
                    ) : (
                      <div>Generate description</div>
                    )}
                  </Badge>
                </div>
              </FormGroup>
            </Form>
          </div>
          <div className="FormButtonWrapper">
            <StepButton
              // disabled={
              //   generateContent ||
              //   props.websiteData?.headline?.length < 1 ||
              //   props.websiteData?.description?.length < 1
              // }
              isLoading={props.loading}
              blue={true}
              label={props?.editStep ? "Update Changes" : "Next"}
              isLeftIcon={props?.editStep ? true : false}
              icon={
                !props.editStep ? <FiChevronRight className="arrow_next" /> : ""
              }
              leftIcon={
                props?.editStep ? <FiChevronLeft className="arrow_next" /> : ""
              }
              onClick={() => {
                console.log('welcomeText', props?.websiteData?.welcomeText)
                initiateSaveWebsiteHeadline(
                  {
                    welcomeText : props?.websiteData?.welcomeText,
                    businessHeadline: props.websiteData.headline,
                    aboutBusiness: props.websiteData.description,
                  },
                  (responseData) => {
                    props.trackActivity({ activityStep: props.step + 2 });
                    if (props?.editStep) {
                      props?.setStep(0);
                    } else props.setStep(props.step + 1);
                    initGetWebsiteDataResponseFunction();
                  },
                  (error) => {
                    console.log(error);
                  }
                );
              }}
            />
            {!props?.editStep && (
              <SkipButtonComp
                onClick={handleSkipClick}
                prevWebOnClick={prevWebOnClick}
                isMobile={isMobile}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsiteBussTagline;
