import React from 'react'
import CustomButton from "../../custom-components/custom-button";
// import "./business-dashboard.scss";
// import "../../../../src/components/pages/business-dashboard/business-dashboard.scss"
import "./home.scss";

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ServicesPopUp from "../../custom-components/services-popup";
import ReviwewPopup from "../../review-popup";

import Avatar from "react-avatar";
import { TfiMenu } from "react-icons/tfi";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { webBaseUrl } from "../../../config";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import CustomAutoComplete from "../../auto-complete/CustomAutoComplete";
import BusinessFooter from "../../businessFooter";
import BusinessHeader from "../../businessHeader";
import CalenderComponent from "../../calender";
import AppointmentsContainer from "../../dashboard/appointments-container/AppointmentsContainer";
import DashboardBusinessHealth from "../../dashboard/business-health/DashboardBusinessHealth";
import EssentialsContainer from "../../dashboard/essentials-container/EssentialsContainer";
import ExploreContainer from "../../dashboard/explore-container/ExploreContainer";
import OccupationContainer from "../../dashboard/occupation-container/OccupationContainer";
import CustomModal from "../../modal/CustomModal";
import {
  forwardSendMailData,
  getClientsData,
} from "../../redux/actions/dashboard/Reviews";
import getdashboardAppointmentListData from "../../redux/actions/dashboard/appointments";
import getDashboardList from "../../redux/actions/dashboard/dashboardList";
import SidebarComponent from "../../sidebar";
import BusinesHealthSkeleton from "../../skeleton/dashboard-skeleton/BusinesHealthSkeleton";
import CalendarSkeleton from "../../skeleton/dashboard-skeleton/CalendarSkeleton";
import { debounce } from "lodash";
import bellIcon from "../../../images/icons/bell.svg";
import { renewInsurance } from "../../redux/actions";
import Customloader from "../../loader/loader";
import { insuranceLandingPage, isGuestAllowed } from "../../../Routes";
import logo from "../../../images/icons/faces-logo-black.svg";
import AiAssistantContainer from "../../dashboard/aiassistant-container/AiAssistantContainer";
import closeIcon from "../../../images/Icon example.svg";
import { GetFacesAiOnboardingStatusAction } from "../../redux/actions/facesai-assistant/facesAiOnboardingStatus";
import { GetUserDashboardDataAction } from "../../redux/actions/dashboard/dashboardGql";
import { GetProfileAction } from "../../redux/actions/dashboard/getProfileDataGql";

import styled from 'styled-components';
import { da } from "date-fns/locale";
import { GetAppointmentListAction } from "../../redux/actions/dashboard/appointmentListGql";

import { GetCheckToShowPayl8trPopupAction } from "../../redux/actions/dashboard/checkToShowPayl8trPopup";
// import payl8rImage from "../../assets/images/payl8r/payl8r-image.png"
import { Link } from "react-router-dom";
import PayLaterPopup from "../../payl8r/PayLatetPopup";
import Cookies from "js-cookie";
import getProfileData from "../../redux/actions/profile/Profile";
import VerifiedBanner from "../../dashboard/verified-banner/VerifiedBanner";
import { GetUserInsuranceVerifiedAction } from "../../redux/actions/faces-verified/faces-verified"
import { GetUserInsuranceDetailAction } from "../../redux/actions/GetUserInsuranceDetails/GetUserInsuranceDetail"




const Home = ({ setCurrentPage, currentPage }) => {
  const moment = require("moment");

  const listRef = useRef();

  // const { profileData } = useSelector((state) => state?.GetProfileDataReducer);

  const [isHideFacesVerified, setIsHideFacesVerified] = useState(true)
  const [isBannerShow, setIsBannerShow] = useState(false)

  const { getProfileQueryData, initGetProfileData } = GetProfileAction();
  const { getInsuranceVerifiedQueryData, initGetInsuranceverifiedData } = GetUserInsuranceVerifiedAction();
  const { getUserInsuranceDetailsQueryData, initGetUserInsuranceDetailData } = GetUserInsuranceDetailAction();
  const { data } = getUserInsuranceDetailsQueryData

  const { profileData } = useSelector((state) => state?.GetProfileDataReducer);


  useEffect(() => {

    initGetInsuranceverifiedData()
  }, [])

  useEffect(() => {
    document.querySelector(".Business-Profile-Main-Wrapper")?.classList?.add("sprint6_Business_Profile_Main_Wrapper")

    return () => {
      document.querySelector(".Business-Profile-Main-Wrapper")?.classList?.remove("sprint6_Business_Profile_Main_Wrapper")
    };
  }, []);

  useEffect(() => {
    initGetProfileData();
  }, [])

  useEffect(() => {
    initGetUserInsuranceDetailData()
  }, [])
  console.log("user", data?.getUserInsuranceDetails?.data?.status)

  const bannerShow = getInsuranceVerifiedQueryData?.data?.getUserInsuranceVerifiedStatus?.data;

  useEffect(() => {

    if (bannerShow) {
      setIsBannerShow(bannerShow?.is_banner_show)
    }


  }, [getInsuranceVerifiedQueryData?.data?.getUserInsuranceVerifiedStatus?.data])





  console.log("veriSatus", isBannerShow, "???", getInsuranceVerifiedQueryData?.data?.getUserInsuranceVerifiedStatus?.data?.is_banner_show)
  useEffect(() => {
    if (getProfileQueryData?.data) {
      const data = getProfileQueryData?.data?.getProfile?.data?.profile_data[0]
      setIsHideFacesVerified(data?.is_hide_banner)
    }

  }, [getProfileQueryData?.data])

  console.log("????", isHideFacesVerified)

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const [openReviewPopup, setOpenReviewPopup] = useState(false);

  const [openRenewPopup, setOpenRenewPopup] = useState(false);
  const [renewClicked, setRenewClicked] = useState(false);
  const [renewLoaderActive, setRenewLoaderActive] = useState(false);
  const [clientsModal, setClientsModal] = useState(false);
  const [openServicePopup, setOpenServicePopup] = useState(false);
  const [activeSidebar, setActiveSidebar] = useState(false);
  const [showSupportChatWidget, setShowSupportChatWidget] = useState(true)


  //CLIENTS DATA SEARCH
  const [searchString, setSearchString] = useState('');

  const [allClients, setAllclients] = useState(false);
  const [value, setValue] = useState([]);
  const handleOptionChange = (event, newValue) => {
    // newValue = newValue.filter(e => e?.title !== "No Client Found")
    setValue(newValue);
  };

  const { width, height } = useWindowDimensions();
  const [showSidebar, setShowSidebar] = useState(true);

  // payl8tr pop up 
  const [showPopUp, setShowPopup] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation().state;
  const divs = document.querySelectorAll(".owl-controls");

  // const { dashboardAppointmentListData } = useSelector(
  //   (state) => state.appointmentReducer
  // );

  const { getAppointmentListQueryData, initGetAppointmentListData } = GetAppointmentListAction();
  const { dashboardAppointmentListData } = useSelector((state) => state.appointmentReducer);
  console.log('dashboardAppointmentListData', dashboardAppointmentListData);
  useEffect(() => {
    initGetAppointmentListData({
      'appointmentListDayType': "upcoming",
      'limit': 10,
      'page': 1
    });
  }, [])

  const { getUserDashboardDataQueryData, initGetUserDashboardDataData } = GetUserDashboardDataAction();
  const { dashboardListData } = useSelector((state) => state.dashboardReducer);
  useEffect(() => {
    initGetUserDashboardDataData({
      businessAverageReviews: true,
      appointmentListDayType: "",
      limit: null,
      platform: null
    });
  }, [])
  // here
  // const [dashboardListData, setDashboardListData] = useState(null);

  // const successCallBackDashboardListData = (data) => {
  //   setDashboardListData(data?.data?.getUserDashboardData?.data);
  // };

  // const errCallBackDashboardListData = (data) => {
  //   console.log('err: ', data);
  // };

  const { insurancePage } = useSelector((state) => state.dashboardReducer);
  const { clientsData } = useSelector((state) => state.GetReviewsDataReducer);
  const { sendMailData } = useSelector((state) => state.GetReviewsDataReducer);

  const { checkToShowPayl8trPopupData, initCheckToShowPayL8trPopup } = GetCheckToShowPayl8trPopupAction();


  const { forwardMailFlag } = useSelector(
    (state) => state.GetReviewsDataReducer
  );

  //SKELETON FLAG
  const { getClientsDataSkeletonFlag } = useSelector(
    (state) => state.GetReviewsDataReducer
  );
  const { dashboardSkeletonFlag } = useSelector(
    (state) => state.dashboardReducer
  );

  // //CLIENTS DATA
  const { clientDataList } = useSelector(
    (state) => state.GetReviewsDataReducer
  );
  const { totalclientDataCount } = useSelector(
    (state) => state.GetReviewsDataReducer
  );
  const { loadedclientDataCount } = useSelector(
    (state) => state.GetReviewsDataReducer
  );
  const { clientDataPage } = useSelector(
    (state) => state.GetReviewsDataReducer
  );

  const renewInsuranceMessage = useSelector(
    (state) => state.GetInsuranceDataReducer.renewInsuranceMessage
  );
  const renewInsuranceStatus = useSelector(
    (state) => state.GetInsuranceDataReducer.renewInsuranceStatus
  );

  const insuranceLandingLink = useSelector(
    (state) => state.GetInsuranceDataReducer.insuranceLandingLink
  );

  //INFINITE SCROLL FOR CLIENTS DATA

  const initClientList = (refreshList = false) => {
    let newPage = clientDataPage + 1;
    if (refreshList) {
      newPage = 1;
    } else if (totalclientDataCount <= loadedclientDataCount) {
      return;
    }
    // dispatch(
    //   getClientsData({ page: newPage, limit: 10, search: searchString })
    // );
  };

  const handleScrollCallback = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;

      if (
        scrollTop + clientHeight === scrollHeight &&
        totalclientDataCount > loadedclientDataCount
      ) {
        initClientList();
      }
    }
  };

  const handleReviewModalClick = () => {
    setOpenReviewPopup(true);
  };

  const handleClientsForm = (e) => {
    e.preventDefault();
    let temp = [];
    if (value.length > 0) {
      temp = value.map((e) => e.cl_dt_id);
    }

    dispatch(
      forwardSendMailData({
        elementId: temp,
        selectAllClients: allClients ? 1 : 0,
      })
    );
  };

  const { initOnboardingCheck, userFacesAiStatus } = GetFacesAiOnboardingStatusAction();

  useEffect(() => {
  }, [dashboardListData?.latest_reviews])



  // useEffect(() => {
  //     if (clientsData) {
  //         setOptionValue(clientsData.elements.map(e => {
  //             return { ...e, title: e.cl_dt_name }
  //         }))
  //     }

  // }, [clientsData])

  useEffect(() => {
    if (sendMailData) {
      // setOptionValue([])
      setClientsModal(false)
    }
  }, [sendMailData])

  useEffect(() => {
  }, [dashboardListData?.latest_reviews])



  // useEffect(() => {
  //     if (clientsData) {
  //         setOptionValue(clientsData.elements.map(e => {
  //             return { ...e, title: e.cl_dt_name }
  //         }))
  //     }

  // }, [clientsData])

  useEffect(() => {
    if (sendMailData) {
      // setOptionValue([])
      setClientsModal(false)
    }
  }, [sendMailData])

  useEffect(() => { }, [dashboardListData?.latest_reviews]);

  // useEffect(() => {
  //     if (clientsData) {
  //         setOptionValue(clientsData.elements.map(e => {
  //             return { ...e, title: e.cl_dt_name }
  //         }))
  //     }

  // }, [clientsData])

  useEffect(() => {
    if (sendMailData) {
      // setOptionValue([])
      setClientsModal(false);
    }
  }, [sendMailData]);

  useEffect(() => {
    if (dashboardAppointmentListData == null)
      // dispatch(
      //   getdashboardAppointmentListData({
      //     "appointment-list-day-type": "",
      //     limit: 10,
      //   })
      // );
      // here change
      if (dashboardListData == null && insurancePage) {
        alert("Please renew your insurance");
        dispatch(
          getDashboardList({
            "business-average-reviews": true,
            platform: "frontend",
          })
        );
      }
  }, []);

  useEffect(() => {
    if (renewInsuranceStatus) {
      setRenewLoaderActive(false);
      if (insuranceLandingLink?.length) {
        window.location.href = insuranceLandingLink;
      } else {
        setOpenRenewPopup(true);
      }
    }
  }, [renewInsuranceStatus]);

  var isMobile;

  if (width <= 991) {
    isMobile = true;

  } else {
    isMobile = false;
  }

  useEffect(() => {
    isMobile ? setShowSidebar(false) : setShowSidebar(true);
  }, [isMobile]);


  useEffect(() => {
    isMobile && setActiveSidebar(false);
    isMobile && document.body.classList.remove('sidebarOpen');
  }, [isMobile]);





  useEffect(() => { initOnboardingCheck() }, []);

  useEffect(() => {
    // localStorage.clear();
  }, []);

  // console.log('searchListData: ', searchString);
  useEffect(() => {
    initCheckToShowPayL8trPopup()
  }, [])

  useEffect(() => {
    console.log("checkToShowPayl8trPopupData>>>>>>>>>", checkToShowPayl8trPopupData)
    if (checkToShowPayl8trPopupData?.data?.checkToShowPayl8trPopup?.success === true) {
      setShowPopup(true);
    }
  }, [checkToShowPayl8trPopupData]);

  useEffect(() => {
    let pathnameToBeChecked = window.location.pathname;

    let cleanPathname =
      pathnameToBeChecked.indexOf(process.env.PUBLIC_URL) === 0
        ? pathnameToBeChecked.replace(process.env.PUBLIC_URL, '')
        : pathnameToBeChecked;

    if (cleanPathname.indexOf(process.env.PUBLIC_URL) == 0) {
      cleanPathname = cleanPathname.replace(process.env.PUBLIC_URL, '');
    }

    if (isGuestAllowed(cleanPathname)) {
      setShowSupportChatWidget(false)
    }
    if (profileData == null && !isGuestAllowed(cleanPathname))
      dispatch(getProfileData())

    console.log("INSURANCE_TEST is guest allowed ", cleanPathname, isGuestAllowed(cleanPathname), pathnameToBeChecked);

    if (!(Cookies.get("faces_access_token") || Cookies.get("faces_session")) && !isGuestAllowed(cleanPathname)) {
      console.log("INSURANCE_TEST - redirecting user to login from app.js, as token is not present");
      window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/login`;

      // Cookies.set("faces_access_token","eyJpdiI6IkliaTF3bVRJWkR2Q3IwcE1sU1Z1VUE9PSIsInZhbHVlIjoicHZGejVoM1N4K3Z1cVpoUFk5UlR2RGs0M0h2R2JJdXp5YnFvRkkweEpJQzY1dDJRcERJUDJDckM4eEJBMkZGOUJyT3JoVFMzXC9KNzNnNm13dFZueFc0UzQzSVBHc3g2ZFRlVmtiV1F5ZHNZPSIsIm1hYyI6IjAxMmVlMjQwYmIxNzg0NWJjOTkzZDg2NWY4ZjJhNjUwODkwYmZhYWY2MDkxYjM3NDdhZTRlNzllMjFkYjFhZmYifQ==");
    }
  }, [window.location.pathname])

  useEffect(() => {

  }, [isHideFacesVerified])

  return (
    <div >
      <Customloader active={renewLoaderActive}>
        <div className="container-fluid">
          <div className="dashboard-parent-container row customeDashBoard">
            <div className="col-lg-9">
              <div className={"main_dashboard_container row"}>
                {dashboardListData &&
                  (dashboardListData?.total_onboarding_steps !== 4 ? (
                    <OccupationContainer
                      dashboardListData={dashboardListData}
                      showBanner={true}
                    />) :
                    (dashboardListData?.personal_website_show_banner ?
                      <OccupationContainer
                        dashboardListData={dashboardListData}
                        showBanner={false}
                      />
                      :
                      ((userFacesAiStatus?.data && !(userFacesAiStatus?.data?.getUserGoHighLevelOnboardingStatus?.data?.is_onboarded)) ? <AiAssistantContainer isDisableButton={false} isShowLock={false} /> : null))
                  )
                }


                {/* =========================================VERIFIED BANNER=============================================== */}
                {

                  !isHideFacesVerified && isBannerShow && (
                    <VerifiedBanner setIsHideFacesVerified={setIsHideFacesVerified} />
                  )
                }




                {/* =======================APPOINTMENTS CONTAINER======================= */}
                {
                  <AppointmentsContainer
                    dashboardAppointmentListData={dashboardAppointmentListData}
                  />
                }

                {/* =======================CALENDAR CONTAINER======================= */}
                <div className="calendar-main-div container-fluid calender-container">
                  {dashboardSkeletonFlag ? (
                    <CalendarSkeleton />
                  ) : (
                    <CalenderComponent
                      eventsArray={dashboardListData?.calender_details}
                    />
                  )}
                </div>

                {/* =======================CONSENT FORMS CONTAINER======================= */}
                <div
                  className="consent-form-container d-flex justify-content-between align-items-center mb-5 cursor-pointer"
                  onClick={() => {
                    window.location.href = webBaseUrl + "/appointment-form";
                  }}
                >
                  <div>
                    <h4 >Consent Forms</h4>
                    <p>
                      Select how you'd like to gather consent for your
                      appointment.
                    </p>
                  </div>
                  <div>
                    <CustomButton label="Get Started!" className="greybutton" />
                  </div>
                </div>

                {/* =================ESSENTIALS CONTAINER================= */}
                <EssentialsContainer
                  EssentialsViewOnClick={() => { setOpenServicePopup(true) }}
                />

                {/* =================EXPLORE CONTAINER================= */}
                <ExploreContainer />

                {/* =================BUSINESS HEALTH CONTAINER================= */}
                {dashboardSkeletonFlag ? (
                  <BusinesHealthSkeleton />
                ) : (
                  dashboardListData?.latest_reviews?.length > 0 && (
                    <DashboardBusinessHealth
                      dashboardListData={dashboardListData}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <BusinessFooter /> */}

        {/* ========================REVIEW MODAL======================== */}

        <CustomModal
          modalOpen={openReviewPopup}
          setModalOpen={setOpenReviewPopup}
          type={"review"}
          modalBody={
            <>
              <ReviwewPopup
                setOpenReviewPopup={setOpenReviewPopup}
                setClientsModal={setClientsModal}
                setValue={setValue}
              />
            </>
          }
        />

        {/* ========================FORWARD MAIL TO CLIENT MODAL======================== */}
        {/* <CustomModal
          modalOpen={clientsModal}
          // modalOpen={true}
          setModalOpen={setClientsModal}
          modaltitle={"Select Clients"}
          modalBody={
            <>
              {
                <form
                  className="d-flex flex-column mt-2"
                  onSubmit={handleClientsForm}
                >
                  <div>
                    <input
                      type="checkbox"
                      name="all"
                      id="all"
                      checked={allClients}
                      onChange={(e) => setAllclients(e.target.checked)}
                    />
                    <label htmlFor="all" className="ms-2">
                      Select All Clients
                    </label>
                  </div>
                  {!allClients && (
                    <CustomAutoComplete
                      multiple={true}
                      groupBy={false}
                      listRef={listRef}
                      setSearchString={setSearchString}
                      searchStringTxt={"abc"}
                      handleInputClick={() => initClientList(true)}
                      optionValue={clientDataList}
                      value={value}
                      setValue={setValue}
                      handleOptionChange={handleOptionChange}
                      handleScrollCallback={handleScrollCallback}
                    />
                  )}
                  <button className="client-submit-btn">
                    <div className="d-flex justify-content-center align-items-center gap-2 ">
                      {forwardMailFlag && (
                        <div class="payment-spinner-loader"></div>
                      )}
                      SEND REVIEW REQUEST TO CLIENT
                    </div>
                  </button>
                </form>
              }
            </>
          }
        /> */}

        {/* ========================SERVICES MODAL======================== */}
        <CustomModal
          modalOpen={openServicePopup}
          setModalOpen={setOpenServicePopup}
          modaltitle={"Services"}
          modalBody={
            <>
              <ServicesPopUp />
            </>
          }
        />
        {
          <CustomModal
            setOpenRenewPopup={setOpenRenewPopup}
            modalOpen={openRenewPopup}
            modalBody={renewInsuranceMessage}
            type={"renew"}
            modaltitle={
              <div className="w-100 text-center">
                <p className="mt-2 mx-auto text-center">Insurance</p>
              </div>
            }
          />
        }
        {
          <CustomModal
            modalOpen={showPopUp}
            // modalOpen={true}
            type={"test"}
            setModalOpen={setShowPopup}
            className={"buy-now-payl8r-popup-container"}
            modalBody={
              <>
                <PayLaterPopup setShowPopup={setShowPopup} />
              </>
            }
          />
        }
      </Customloader>
    </div>
  );
}

export default Home