import React, { useState } from "react";
import "./Emails.scss";
import { ReactComponent as RightIcon } from "../../../../../images/calander_next.svg";
import EmailsTemplate from "./EmailsTemplate/EmailsTemplate";
import BusinessNavigation from "../../../../common/businessNavigation/BusinessNavigation";

function Emails({ setParentStep }) {
  const [step, setStep] = useState(0);

  const renderTabContent = () => {
    if (step == 1) {
      return <EmailsTemplate setStep={setStep} />;
    }
  };

  return (
    <div className="MainEmailpage">
      {step > 0 ? (
        renderTabContent()
      ) : (
        <div className="Main684WidthSet">
          <BusinessNavigation
            isColumn={true}
            title={"Send a text sms"}
            desc={"Send a message to as many clients as you want!"}
            onBackClick={() => {
              setParentStep(0);
            }}
          />
          <div className="bottomButtonWrapper">
            {/* <div className="cardSms">
              <div className="cardSmsLeftContent">
                <h5>Templates</h5>
                <p>Create beautiful templates</p>
              </div>
              <div className="cardSmsRightContent">
                <RightIcon />
              </div>
            </div> */}
            <div
              className="cardSms"
              onClick={() => {
                setStep(1);
              }}
            >
              <div className="cardSmsLeftContent">
                <h5>Send marketing email</h5>
                <p>Reach out to your clients by email campaigns</p>
              </div>
              <div className="cardSmsRightContent">
                <RightIcon />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Emails;
