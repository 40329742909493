import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_DISCOUNT_CODE_CONST } from "../../constants/discount/discount";
import { toast } from "react-toastify";


const GET_DISCOUNT_QUERY = gql`
query GetDiscount($clinicId: Int!, $couponCode: String!) {
    getDiscount(clinic_id: $clinicId, coupon_code: $couponCode) {
      success
      message
      data {
        discount_name
        discount_type
        discount_unit
      }
    }
  }
  `;

// var page;
export const GetDiscountCode = () => {
  const dispatch = useDispatch();
  const [getDiscountCode, discountData] = useLazyQuery(
    GET_DISCOUNT_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getDiscount: responseData }) => {

        if (responseData.success) {
          dispatch({
            type: GET_DISCOUNT_CODE_CONST,
            payload: responseData?.data,
            // page
          });
          toast.success(responseData.message)

        } else {
          toast.error(responseData.message)
        }
      },
    }
  );
  const discountQueryData = discountData;
  const initDiscount = (data) => {
    getDiscountCode({
      variables: data,
    });
  };
  return { initDiscount, discountQueryData };
};



