import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const SAVE_BATCH_NUMBERS = gql`
mutation SaveBatchNumbers($consentFormRecordId: Int, $treatmentId: Int, $batchNumbers: [Upload], $batchNumbersWithText: [saveBatchNumbersWithTextInputObject]) {
  saveBatchNumbers(consentFormRecordId: $consentFormRecordId, treatmentId: $treatmentId, batchNumbers: $batchNumbers, batchNumbersWithText: $batchNumbersWithText) {
    success
    message
  }
}
`;


export const SaveBatchNumbersAction = () => {
    const dispatch = useDispatch();
    const [
        SaveBatchNumbersResponseFunction,
        saveBatchNumbersResponseData,
    ] = useMutation(SAVE_BATCH_NUMBERS)

    //this will be called from components
    const saveBatchNumberQueryData = saveBatchNumbersResponseData; //variable

    const initSaveBatchNumbersData = (data, successCallback, errorCallback) => {
        SaveBatchNumbersResponseFunction({
            variables: data,
        }).then(({ data: { saveBatchNumbers: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback(responseData)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
            // toast.error("something went wrong!!!")
        })
    }

    return { saveBatchNumberQueryData, initSaveBatchNumbersData };
};