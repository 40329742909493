import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { FiChevronRight } from "react-icons/fi";
import "./createWebsite.scss";
import StepButton from "../../common/StepButton";
import useWindowDimensions from "../../../utils/useWindowDimensions";

import FacesLogo from "../../facesLogo";
import Aos from "aos";
import "aos/dist/aos.css";
import toast from 'react-hot-toast';
import { Toast } from "reactstrap";
import ToastbarComponent from "../../common/ToasterComponent";
import { TrackUserActivity } from "../../redux/actions/createWebsite/trackUserActivity";


const WelcomeScreen = (props) => {
  const { data } = props;
  const [currentSlide, setCurrentSlide] = useState(0);
  const dimensions = useWindowDimensions();

  const { initiatetrackUserActivity, trackUserActivityMutationData } =
    TrackUserActivity();

  useEffect(() => {
    Aos.init({
      duration: 1000, // Animation duration
      offset: 200,    // Offset (in pixels) from the original trigger point
    });
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === data.length - 1 ? 0 : prevSlide + 1
    );
    Aos.refresh();
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };



  useEffect(() => {
    initiatetrackUserActivity({
      activityStep: 1,
    }, (responseData) => {
      console.log("activity tracked")
    }
      ,
      (error) => {
        toast(error, { icon: "" });
      });
  }, [])


  return (
    <div className="slider-container welcomeScreen">
      <div className="welcomeScreenWrapper NewDesignwelcomeScreenWrapper ">
        <div className="SliderWrapper">
          <div
            className="slider"
            style={{
              transform: `translateX(-${currentSlide * 100}%)`
            }}
          >
            {data.map((slide, index) => (
              <div
                key={index}
                className={`slide ${index === currentSlide ? "active" : ""}`}
              >
                <div className="WelocomeCustomeImgContentWrapper">
                  <div className="WelocomeCustomeSlider">
                    <FacesLogo />
                    <div className="slide-content welcomeContentWrapper">
                      <h2 className="title" >{slide.text}</h2>
                      <p className="desc">{slide.desc}</p>
                    </div>
                    <div className="slider-controls dekstop-Block">
                      <StepButton
                        isLoading={props.detailsLoading}
                        blue={true}
                        label={index  === 2 ? "Get started" : "Next"}
                        icon={<FiChevronRight className="arrow_next" />}
                        onClick={() => {
                          if (currentSlide === 2) {
                            props?.setCurrentStep(2)
                          } else {
                            nextSlide();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="WelocomeCustomeSliderImg">
                    <img src={slide.sliderimg} alt={`Slide ${slide.id}`} />
                    <div className="slider-controls mobile-Block">
                      <StepButton
                        isLoading={props.detailsLoading}
                        blue={true}
                        label={index  === 2 ? "Get started" : "Next"}
                        icon={<FiChevronRight className="arrow_next" />}
                        onClick={() => {
                          if (currentSlide === 2) {
                            props?.setCurrentStep(2)
                          } else {
                            nextSlide();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="slider-dots">
            {data.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentSlide ? "active" : ""}`}
                onClick={() => goToSlide(index)}
              ></span>
            ))}
          </div>
        </div>

     
      </div>
      <ToastbarComponent toast={toast} />
    </div>
  );
};

export default WelcomeScreen;
