import React, { useState, useEffect } from "react";
import "./prescriber-tnc.scss";
import StepButton from "../../common/StepButton";
import {
  GetPharmacyRegistrationTerms,
  SavePharmacyTermsAndConditionsData,
} from "../../redux/actions/pharmacy-registration/termsAndConditions";
import { toast } from "react-toastify";
import FinanceBanner from "../../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader"


export default function PrescriberTermsAndConditions({
  onNextStep,
  afterSubmit,
  setIsPaid,
  finish,
  setShowPayl8rPage,
  showPayl8trPage,
  isPaid,
  showCheckBox,
}) {
  const { initGetPharmacyTerms, data,loading, } = GetPharmacyRegistrationTerms();
  const { initiateSavePharmacyTermsAndConditions } =
    SavePharmacyTermsAndConditionsData();
  const [Loading, setLoading] = useState(false);
  const [termsArray, setTermsArray] = useState([
    {
      pharmacyId: "",
      pharmacyName: "",
      pharmacyTermsCondition: "",
    },
  ]);
  const [registeredPharmaciesIds, setRegisteredPharmaciesIds] = useState([]);
  const [conditon, setCondition] = useState({
    allCondition: false,
  });
  const [conditionError, setConditionError] = useState(false);
  const [isFinance, setIsFinance] = useState(false);
  const [openAccordions, setOpenAccordions] = useState(
    Array.from({ length: termsArray?.length }, () => false)
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    if (isSubmitted) {
      console.log(showPayl8trPage,isPaid,"showPayl8trPage")
      onNextStep();
      afterSubmit();
    }
  }, [isPaid, showPayl8trPage,isSubmitted]);

  useEffect(() => {
    initGetPharmacyTerms();
  }, []);

  useEffect(() => {
    if (data?.getPharmacyRegetrationTermsCondition) {
      const fetchedData =
        data?.getPharmacyRegetrationTermsCondition?.data?.registeredPharmacies;
      setTermsArray(fetchedData);
    }
  }, [data]);
 

  const onHandleCheckBox = (index) => {
    const pharmacyId = termsArray[index].pharmacyId;
    const isChecked = registeredPharmaciesIds.includes(pharmacyId);
    if (isChecked) {
      setRegisteredPharmaciesIds((prevIds) =>
        prevIds.filter((id) => id !== pharmacyId)
      );
    } else {
      setRegisteredPharmaciesIds((prevIds) => [...prevIds, pharmacyId]);
    }
  };

  const onHandleAllCheck = () => {
    const allAccepted = !conditon.allCondition;
    setCondition({ ...conditon, allCondition: allAccepted });
    if (allAccepted) {
      const allPharmacyIds = termsArray.map((term) => term.pharmacyId);
      setRegisteredPharmaciesIds(allPharmacyIds);
    } else {
      setRegisteredPharmaciesIds([]);
    }
  };

  const toggleAccordion = (index) => {
    const newOpenAccordions = [...openAccordions];
    newOpenAccordions[index] = !newOpenAccordions[index];
    setOpenAccordions(newOpenAccordions);
  };

  const onConditions = () => {
    if (registeredPharmaciesIds?.length === termsArray?.length) {
      setLoading(true);
      initiateSavePharmacyTermsAndConditions(
        {
          registeredPharmaciesIds,
          isFinance,
        },
        (res) => {
          setLoading(false);
          setConditionError(false);
          toast.success(res?.message);
          setIsPaid(res?.data?.showPaidCard);
          setShowPayl8rPage(res?.data?.showPayl8trPage);
          setIsSubmitted(true);
          // afterSubmit();
          // onNextStep();
        },
        (error) => {
          setLoading(false);
          toast.error(error.message);
        }
      );
    } else {
      setConditionError(true);
    }
  };

  return (
    <div className="main-div Custome-Registration-Terms-Conditions">
      <div className="sub-div">
        {/* <div className="header-main">
          <div className="header">Registration Terms & Conditions</div>
          <div className="contain">
          Please agree to all the terms and conditions of our verified sellers in order to purchase product from the pharmacy
          </div>
        </div> */}
        <FinanceBanner
          title={"Registration terms & conditions"}
          desc={
            "Please agree to the below pharmacy terms and conditions for your account to be approved by them"
          }
          isBorder={true}
          currentStep={finish}
          totalSteps={4}
        />
        <div className="conditon-main">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="allCheck"
              checked={conditon.allCondition}
              name="allCondition"
              onChange={() => onHandleAllCheck()}
            />
            <label className="form-check-label" htmlFor="allCheck">
              Agree all terms and conditions
            </label>
          </div>
          {
            loading  ? (
              <SkeletonLoader type="pharmacy-terms-condition" />
            )
            :
            (
              termsArray?.map((val, index) => {
            const accordionId = `accordion-${index}`;
            return (
              <div className="check-box" key={index}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`checkbox-${index}`}
                    onChange={() => onHandleCheckBox(index)}
                    checked={registeredPharmaciesIds.includes(val.pharmacyId)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`checkbox-${index}`}
                  >
                    I accept the terms and conditions
                  </label>
                </div>

                <div className="accordion custom-accordion" id={accordionId}>
                  <div className="accordion-item ">
                    <h2 className="accordion-header " id={`heading-${index}`}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        aria-expanded={openAccordions[index] ? "true" : "false"}
                        aria-controls={`collapse-${index}`}
                      >
                        {val.pharmacyName}
                      </button>
                    </h2>
                    <div
                      id={`collapse-${index}`}
                      className={`accordion-collapse collapse ${
                        openAccordions[index] ? "show" : ""
                      }`}
                      aria-labelledby={`heading-${index}`}
                      data-bs-parent={`#${accordionId}`}
                    >
                      <div
                        className="accordion-body"
                        dangerouslySetInnerHTML={{
                          __html: val.pharmacyTermsCondition,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            );
              })
            )
          }
         

          {conditionError &&
            registeredPharmaciesIds?.length !== termsArray?.length && (
              <div className="alert alert-danger" role="alert">
                Please accept all of the pharmacy's terms and conditions.
              </div>
            )}
          {!showCheckBox && (
            <div className="active-container">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="isFinance"
                  checked={isFinance}
                  onChange={() => setIsFinance(!isFinance)}
                />
                <label className="form-check-label" htmlFor="isFinance">
                  I agree to share my information with buy now payl8r.
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="BtnButton">
          <StepButton
            blue={true}
            label={"Apply"}
            onClick={onConditions}
            disabled={!data}
            isLoading={Loading}
          />
        </div>
      </div>
    </div>
  );
}
