import React, { useCallback, useEffect, useState } from 'react'
import "./product-question-answer.scss"
import { GetProductQuestionsAction } from "../../redux/actions/product-questions/getProductQuestions"
import StepButton from '../../common/StepButton';
import { SaveProductAnswersActions } from "../../redux/actions/product-questions/saveProductAnswers"
import TextInput from "../../common/TextInput"
import { toast } from "react-toastify"
import CustomRadioButton from "../../common/CustomRadioButton"
import { GetProductAnswerAction } from "../../redux/actions/product-questions/getProductAnswer"
import { useParams } from "react-router"
import ProductQuestionAnswerSkaleteon from "./ProductQuestionAnswerSkaleteon"
import Dropzone from 'react-dropzone';
import { MdOutlineFileUpload } from "react-icons/md";
import CustomModal from '../../modal/CustomModal/CustomModal';
import { webBaseUrl } from "../../../config"
import Cookies from 'js-cookie';

const PrductQuestionAnswer = () => {



	const [question, setQuestion] = useState("");
	const [questionId, setQuestionId] = useState(null)
	const [questionType, setQuestionType] = useState(null)
	const [questionOption, setQuestionOption] = useState([])
	const [number, setNumber] = useState(1);
	const [questionIndex, setQuestionIndex] = useState(0)
	const [totalQuestion, setTotalQuestion] = useState(null);
	const [selectedOption, setSelectedOption] = useState(null);
	const [selectedAnswerText, setSelectedAnswerText] = useState(" ");
	const [selectedFile, setSelectedFile] = useState(null);
	const [sendFileObj, setSendFileObj] = useState(null)
	const [ansError, setAnsError] = useState("")
	const [isPrevPDfModel, setIsPrevPDfModel] = useState(false)
	const [isQuestionAnswerd, setIsQuestionAnswerd] = useState(false)


	const slug = useParams();

	// graphQl api

	const { getProductQuestionQueryData, initGetProductQuestionData } = GetProductQuestionsAction();
	const { saveProductAnswerQueryData, initSaveProductAnswerData } = SaveProductAnswersActions()
	const { getProductAnswerQueryData, initGetProductAnswerData } = GetProductAnswerAction()




	//  get session cookies

	// const sessionString = sessionStorage.getItem("sessionString")
	// console.log("sessionString", sessionString)

	const purchase_session_id = Cookies.get("purchase_session_id");

	// console.log("purchase_session_id", purchase_session_id.toString())


	useEffect(() => {
		initGetProductQuestionData({
			productId: null,
			slug: slug.productId,
			sessionId: purchase_session_id,
		})
	}, [])

	useEffect(() => {
		if (questionId) {
			initGetProductAnswerData({
				questionId: questionId,
				productId: null,
				sessionId: purchase_session_id,
				slug: slug.productId
			})
		}

	}, [questionId])




	useEffect(() => {
		let productAns = null;
		productAns = getProductAnswerQueryData?.data?.getProductAnswer?.data;
		if (getProductQuestionQueryData?.data || productAns) {
			console.log("getProductQuestionQueryData", getProductQuestionQueryData?.data?.getProductQuestions?.data)
			const getProductQuestion = getProductQuestionQueryData?.data?.getProductQuestions?.data?.questions;
			const totalAnsweredQuestions = getProductQuestionQueryData?.data?.getProductQuestions?.data?.totalAnsweredQuestions

			let needToCheckAnswerIndex;


			if (isQuestionAnswerd) {
				needToCheckAnswerIndex = questionIndex

				setNumber(number)
			} else {
				if (productAns !== null) {
					needToCheckAnswerIndex = totalAnsweredQuestions
					setIsQuestionAnswerd(true)
					if (totalAnsweredQuestions === getProductQuestion?.length) {
						needToCheckAnswerIndex = 0;
					} else if (totalQuestion !== needToCheckAnswerIndex) {
						setNumber(needToCheckAnswerIndex + 1);
					}
				}
			}

			setTotalQuestion(getProductQuestion?.length)

			console.log("hghjsjhvs", getProductQuestion[needToCheckAnswerIndex])
			if (getProductQuestion.length && getProductQuestion[needToCheckAnswerIndex]) {
				const currentQuestion = getProductQuestion[needToCheckAnswerIndex];
				console.log("currentQuestion", currentQuestion)
				setQuestion(currentQuestion.question_text)
				setQuestionId(currentQuestion.id)
				setQuestionType(currentQuestion.type)
				setQuestionOption(currentQuestion.options)
				setQuestionIndex(needToCheckAnswerIndex)
			}
		}

	}, [getProductQuestionQueryData?.data, questionIndex])

	console.log("questionIndex@@@@@", number)
	const onDrop = (acceptedFiles) => {
		const uploadedFile = acceptedFiles[0];
		console.log("uploadedFile", uploadedFile)
		if (uploadedFile) {
			setSendFileObj(uploadedFile)
			setSelectedFile(URL.createObjectURL(uploadedFile))
		}
	}


	const handlePreviosQuestionButton = () => {
		console.log("questionIndex",questionIndex)
		
	  if (number === 1) {
		// Redirect when user is at the first question
		window.location.href = webBaseUrl + "/shop/product/" + slug.productId;
		return;
	  }
	   if(isQuestionAnswerd){
		setNumber(number - 1)
	  }
	  if (questionIndex > 0) {
		setQuestionIndex(questionIndex - 1); // Decrement questionIndex to move back
		setNumber(number - 1); // Decrement the displayed number
		setSelectedAnswerText(""); // Reset answer state
		setSelectedOption(null);
		setSelectedFile(null);
		setAnsError("");
	  }
	  }

	const handleNextQuestionButton = () => {

		let hasError = false;

		if (questionType === 1 && selectedAnswerText.trim() === "") {
			setAnsError("Please provide an answer.");
			hasError = true;
		}

		if (questionType === 2 && !selectedOption) {
			setAnsError("Please select an option.");
			hasError = true;
		}
		if (questionType === 3 && !selectedFile) {
			setAnsError("Please upload a PDF file.");
			hasError = true;
		}

		if (hasError) {
			return; // Do not proceed if there's an error
		}



		initSaveProductAnswerData({
			productId: null,
			questionId: questionId,
			answerFile: questionType === 3 ? sendFileObj : null,
			answer: questionType === 1 ? selectedAnswerText : questionType === 2 ? selectedOption?.toString() : questionType === 3 && sendFileObj === null ? getProductAnswerQueryData?.data?.getProductAnswer?.data?.answer : null,
			sessionId: purchase_session_id,
			slug: slug.productId
		}, (data) => {
			const productId = data?.data?.saveProductAnswers?.data?.productId
			toast.success("Answer saved!")
			if (number === totalQuestion) {
				window.location.href = webBaseUrl + "/shop/cart" + `?sessionId=${purchase_session_id?.toString()}&productId=${productId}`
			}

		}, (error) => {
			console.log(`error`, error);
		})

		if (questionIndex < totalQuestion - 1) {

			console.log("here")
			setSelectedAnswerText("")
			setSelectedOption(null)
			setSelectedFile(null)
			setSendFileObj(null)

			setQuestionIndex(questionIndex + 1)
			setNumber(number + 1)
			setAnsError("");
		}

	}


	const handleText = (e) => {
		setSelectedAnswerText(e.target.value)
	}
	const handleOption = (item) => {
		setSelectedOption(item)
	}

	useEffect(() => {
		let productAns = null; // initialize with null


		if (getProductAnswerQueryData?.data?.getProductAnswer?.data) {
			productAns = getProductAnswerQueryData?.data?.getProductAnswer?.data;
			console.log("getProductAnswerQueryData?.data?.getProductAnswer?.data", getProductAnswerQueryData?.data?.getProductAnswer?.data);

			if (productAns) {
				console.log("productAns111", productAns);

				if (questionType === 1 && productAns?.answer) {
					console.log("here sele", selectedAnswerText);
					setSelectedAnswerText(productAns?.answer);
				} else {
					setSelectedAnswerText("");
				}

				if (questionType === 2 && productAns?.answer) {
					setSelectedOption(parseInt(productAns?.answer));
				}

				if (questionType === 3) {
					setSelectedFile(productAns?.doc_url);
				}
			}
		} else {
			// Reset the values when data is null
			setSelectedAnswerText("");
			setSelectedOption(null);
			setSelectedFile(null);
		}
	}, [getProductAnswerQueryData?.data?.getProductAnswer?.data, questionId]);


	return (
		<div className='MainProductQuestionQueryData'>
			<div className='container'>
				{
					getProductQuestionQueryData?.loading ?
						<>
							<ProductQuestionAnswerSkaleteon />
						</>
						:
						<div className='course-exam-main-div CustomeCourseExamSection'>
							<div className="course-work-cards-div">
								<div className='course-work-card'>
									<div className="question-no">
										<div className='questionHeader'>
											<span className='question' >
												Question - {number}
											</span>
											<span className='number'>
												{number}/{totalQuestion}
											</span>


										</div>
										<p>{question}</p>
									</div>

									<div className='answer-div'>
										{
											questionType === 1 && (
												<>
													<div className='options'>
														<div className='text-area'>
															<TextInput
																id={questionId}
																className={`answer-textbox-${questionId}`}
																placeholder="Type your answer here"
																multiline={true}
																value={selectedAnswerText}
																onChange={(e) => { handleText(e) }}
															/>
														</div>
													</div>
													<span className='answerError'>{ansError}</span>
												</>
											)
										}

										{
											questionType === 2 && (
												<>
													<div className='optionsGrid'>
														{
															questionOption.map((op, index) => {
																return (
																	<>
																		<div className={`${selectedOption === op?.id ? "option-radiobutton-active option-radiobutton" : "option-radiobutton"}`} key={index}>
																			<div className='optionsItem' onClick={() =>
																				document.getElementById("question-answer-radio")
																					.addEventListener("change", handleOption(op?.id))
																			}>
																				<div className='optionsItemContent'>
																					<CustomRadioButton
																						id={"question-answer-radio"}
																						className="medium"
																						type="radio"
																						name="option"
																						checked={selectedOption === op?.id}
																						onChange={() => handleOption(op?.id)}
																						preselect={true}
																					/>
																					<span >
																						{String.fromCharCode(65 + index)} :
																					</span>
																				</div>
																				<div className='optionsItemContentLable'>
																					<label for={`option1`}>
																						<div className='label'>
																							<p className='p-0'>{op?.option_text}</p>
																						</div>
																					</label>
																				</div>
																			</div>
																		</div>
																	</>
																)
															})
														}
													</div>
													<span className='answerError'>{ansError}</span>
												</>
											)
										}

										{
											questionType === 3 && (
												<>
													<div className='choosefile-container'>
														<div className='input-file-div'>
															<div className='input-title'>
																<p className='mb-0'>
																	import file from your computer
																</p>
															</div>

															<Dropzone
																accept={{ 'application/pdf': ['.pdf'] }}
																onDrop={onDrop}
															>
																{({ getRootProps, getInputProps, isDragActive }) => (

																	<div {...getRootProps({ className: "dropzone" })}>
																		<label className='label' onClick={(e) => e.stopPropagation()}>
																			<input {...getInputProps()} />
																			<span className={`label ${isDragActive ? 'active' : ''}`} >
																				<span className='d-flex align-items-center'>
																					<span className="me-2">
																						<MdOutlineFileUpload />
																						<span className="mt-1">Upload</span>
																					</span>
																				</span>
																			</span>

																		</label>
																	</div>
																)}
															</Dropzone>
															<p className="doc-type-text">Supported Formate:  PDF</p>
														</div>

														<button className='PreviewBtn' disabled={!selectedFile} onClick={() => { setIsPrevPDfModel(true) }}>
															Preview File
														</button>


													</div>
													<span className='answerError'>{ansError}</span>
												</>
											)
										}
									</div>

									<div className='submit-btn'>
										<StepButton
											label={"Prev"}
											blue={true}
											className={"prev-btn"}
											onClick={() => {
												handlePreviosQuestionButton()
											}}
										/>

										<StepButton
											label={number == totalQuestion ? "submit" : "Next"}
											blue={true}
											className={"prev-btn"}
											onClick={() => {

												handleNextQuestionButton();
											}}
										/>
									</div>
								</div>
							</div>

						</div>
				}
			</div>
			<CustomModal
				modalOpen={isPrevPDfModel}
				setModalOpen={setIsPrevPDfModel}
				className={'CustomPrevPDfModel'}
				modelDescShow={true}
				modelDesc={'Please enter the bank details you would like us to pay money into'}
				modalBody={
					<>
						<div className='file-preview'>
							{
								selectedFile && (
									<>
										<div>
											<embed src={selectedFile} width="400" height="600px" />
										</div>
									</>
								)
							}
						</div>
					</>
				}
			/>
		</div>

	)
}

export default PrductQuestionAnswer