import React, { useEffect, useRef } from "react";
import { Offcanvas } from "react-bootstrap";
import "./off-canvas-modal.scss";
import { ReactComponent as BackArrow } from '../../../images/back_arrow.svg'
import { ReactComponent as CloseIcon } from '../../../images/close_icon.svg'
import { ReactComponent as CalenderIcon } from '../../../images/icons/calender_mini_icon.svg'
import NavHeader from "../../common/NavHeader/NavHeader";
import gsap from "gsap";

const OffCanvasModal = ({ body, title, subTitle, isOpen, onHide, isCloseRight = false, isBack = true, className, placement, isEdit = false, isSave = false, handleOnClick, isOffCanvasHeader = true, grayHeader = false, label, isIconBtn = false, customHeader = false, isBackOnly = false, isBackWithButton = false, onClickCallback }) => {

  const canvasRef = useRef(null);

  // useEffect(() => {
  //   //click on offcanvas backdrop to close
  //   // alert("akshya")
  //   if (!isOpen) {
  //     // alert("enter")
  //     const offCanvasBackdrop = document.querySelector(".offcanvas-backdrop");
  //     console.log(offCanvasBackdrop, "offCanvasBackdrop")
  //     if (offCanvasBackdrop) {
  //       offCanvasBackdrop.addEventListener("click", () => {
  //         alert("hi")
  //         // onHide();
  //       });
  //     }
  //   }
  // }, [canvasRef]);

  return (
    <div >
      <Offcanvas
        show={isOpen}
        onHide={onHide}

        className={className}
        placement={placement}
        ref={canvasRef}

      >
        {
          isOffCanvasHeader &&
          <div className={grayHeader ? "container-fluid" : ""} style={grayHeader ? { background: "#F9F9F9" } : {}}>
            <div className="container" >
              <Offcanvas.Header closeButton={isCloseRight ? true : false} >
                {isEdit && <div className="d-flex justify-content-between w-100 edit-container">
                  <CloseIcon className='close-icon-animation' onClick={onHide} />
                  <div className="edit-btn">
                    <h1> {label === "" ? "Edit" : label}</h1>
                  </div>
                </div>}
                {
                  customHeader && <NavHeader onClickCallback={() => onClickCallback()} isBackOnly={isBackOnly} isBackWithButton={isBackWithButton} btnLabel={label} handleOnBack={() => onHide()} />
                }
                {isSave && <div className="d-flex justify-content-between w-100 edit-container" >
                  <BackArrow className="cursor-pointer" onClick={onHide} />
                  <div className="edit-btn" onClick={() => handleOnClick()}>
                    <h1>{label === "" ? "Save" : label}</h1>
                  </div>
                </div>}
                {isIconBtn && <div className="d-flex justify-content-between w-100 icon-with-text-container" >
                  <CloseIcon className='close-icon-animation' onClick={onHide} />
                  <div className="icon-with-btn" onClick={() => handleOnClick()}>
                    <CalenderIcon />
                    <h1>{"Calendar"}</h1>
                  </div>
                </div>}
                <Offcanvas.Title>
                  <div className="title-container">
                    <h1>{title}</h1>
                    <p>{subTitle}</p>
                    {!isBack && <BackArrow onClick={onHide} className={`${isEdit ? "d-none" : ""}cursor-pointer`} />}

                  </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
            </div>
          </div>
        }
        <Offcanvas.Body style={grayHeader ? { padding: "0" } : {}}>{body}</Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default OffCanvasModal;
