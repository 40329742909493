import { gql, useLazyQuery } from "@apollo/client";
// import { useDispatch } from "react-redux";

const GET_USER_ADDRESS_LIST = gql`
query GetUserAddressList {
  getUserAddressList {
    success
    message
    data {
      addresses {
        addressId
        name
        address
        phone
        isBillingDefault
        isShippingDefault
      }
    }
  }
}
`;

export const GetUserAddressListAction = () => {
    // const dispatch = useDispatch();
    const [
        GetUserAddressListResponseFunction,
        getUserAddressListResponseData,
    ] = useLazyQuery(GET_USER_ADDRESS_LIST, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getUserAddressList: responseData }) => {
            if (responseData.success) {
                // Handle success case
            } else {
                // Handle error case
            }
        },
    });

    const getUserAddressListQueryData = getUserAddressListResponseData; // Renamed variable

    const initGetUserAddressListData = (data, successCallback, errorCallback) => {
        GetUserAddressListResponseFunction({
            variables: data,
        }).then((res) => {
            successCallback(res?.data?.getUserAddressList);
        }).catch((err) => {
            errorCallback();
        });
    };

    return { getUserAddressListQueryData, initGetUserAddressListData };
};
