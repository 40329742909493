import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";

const GET_BEFORE_AFTER_PHOTOS = gql`
query GetBeforeAfterPhotos($consentFormRecordId: Int, $treatmentId: Int) {
  getBeforeAfterPhotos(consentFormRecordId: $consentFormRecordId, treatmentId: $treatmentId) {
    success
    message
    data {
      treatmentId
      consentFormRecordId
      photos {
        pairNumber
        beforePhotoId
        beforePhoto
        afterPhotoId
        afterPhoto
      }
    }
  }
}
`;

export const GetBeforeAfterPhotosAction = () => {
  const dispatch = useDispatch();
  const [
    GetBeforeAfterPhotosResponseFunction,
    getBeforeAfterPhotosResponseData,
  ] = useLazyQuery(GET_BEFORE_AFTER_PHOTOS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getBeforeAfterPhotos: responseData }) => {
      if (responseData.success) {
        // dispatch({
        //     type: GET_RATING_REVIEWS_CONST,
        //     payload: responseData
        // })
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getBeforeAfterPhotosQueryData = getBeforeAfterPhotosResponseData; //variable

  const initGetBeforeAfterPhotosData = (data, successCallback, errorCallback) => {
    GetBeforeAfterPhotosResponseFunction({
      variables: data,
    }).then((res) => {
      successCallback(res?.data?.getBeforeAfterPhotos);
    }).catch((err) => {
      errorCallback();
    });
  };

  return { getBeforeAfterPhotosQueryData, initGetBeforeAfterPhotosData };
};