import React, { useState } from "react";
import MyServices from "./MyServices/MyServices";
import ServiceDetails from "./ServiceDetails/ServiceDetails";

function TreatmentServices() {
  const [step, setStep] = useState(1);

  return (
    <>
      {step == 0 && <MyServices setStep={setStep} />}

      {step == 1 && <ServiceDetails setStep={setStep} />}

      {/* {step == 2 && <Sethours setStep={setStep} />} */}
    </>
  );
}

export default TreatmentServices;
