import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const UPLOAD_IDENTITY_MUTATION = gql`
mutation UploadIdentityDocument($identityDoc: Upload) {
    uploadIdentityDocument(identityDoc: $identityDoc) {
      success
      message
    }
  }
`;
export const UploadIdentityDocument = () => {
    const [uploadIdentityDocumentMutation, { data: uploadIdentityDocumentResponseData }] =
      useMutation(UPLOAD_IDENTITY_MUTATION);
    const dispatch = useDispatch();
  
    const initiateUploadIdentityDocument = (variables, successCallback, errorCallback) => {
      uploadIdentityDocumentMutation({ variables })
        .then(({ data }) => {
          if (data.uploadIdentityDocument.success) {
            successCallback(data.uploadIdentityDocument);
          } else {
            errorCallback(data.uploadIdentityDocument);
          }
        })
        .catch((error) => {
          // Handle error
          errorCallback(error);
        });
    };
  
    return { initiateUploadIdentityDocument, uploadIdentityDocumentResponseData };
  };
  const GET_IDENTITY_DOCUMENT_QUERY = gql`
  query GetIdentityDocument {
    getIdentityDocument {
      success
      message
      data {
        docUrl
      }
    }
  }
  
`;
export const GetIdentityDocument = () => {
  const dispatch = useDispatch();
  const [getIdentityDocument, { loading, error, data }] = useLazyQuery(GET_IDENTITY_DOCUMENT_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: (response) => {
      
    },
  });

  const initGetIdentityDocument = () => {
    getIdentityDocument();
  };

  return { loading, error, data, initGetIdentityDocument };
};
const DELETE_IDENTITY_DOCUMENT_MUTATION = gql`
mutation DeleteIdentityDocument {
  deleteIdentityDocument {
    success
    message
  }
}
`;
export const DeleteIdentityDocument = () => {
  const [deleteIdentityDocument, { data: deleteIdentityDocumentResponseData }] =
    useMutation(DELETE_IDENTITY_DOCUMENT_MUTATION);
  const dispatch = useDispatch();

  const initiateDeleteIdentityDocument = (variables,successCallback, errorCallback) => {
    deleteIdentityDocument({})
     .then(({data}) => {

        if (data && data.deleteIdentityDocument.success) {
          successCallback(data.deleteIdentityDocument);
          
        } else {
          errorCallback( data?.deleteIdentityDocument);
        }
      })
     .catch((error) => {
        // Handle error
        errorCallback(error);
      });
  };


  return { initiateDeleteIdentityDocument, deleteIdentityDocumentResponseData };
};