import React from 'react'
import insuranceIcon from "../../../images/icons/protection.svg"
import brandboostIcon from "../../../images/icons/Vector3.svg"
import helplineIcon from "../../../images/icons/Vector.svg"
import hairIcon from '../../../assets/HairProduct/hairlogo.png'
import websiteIcon from '../../../assets/website.svg'
import newFeature from '../../../images/newFeature.jpg';

import { frontendURL, webBaseUrl } from '../../../config'
import { createWebsiteRoute, HairProductLinkRoutePattern, insuranceLandingPagePattern, socialMediaMarketingPagePattern } from '../../../Routes'
import { useNavigate } from 'react-router'

const ExploreContainer = () => {
    const navigate = useNavigate()

    const exploreData = [
        {
            title: "Prescription Hair Care",
            subTitle: "Refer clients who experience hair loss.",
            imageIcon: hairIcon,
            // redirectUrl: webBaseUrl + '/beauty-aesthetic-insurance-companies'
            onclick: () => navigate(HairProductLinkRoutePattern)
        },
        {
            title: "My Website",
            subTitle: "Build, Create, Customise your very own online presence with our website builder Business",
            imageIcon: websiteIcon,
            // redirectUrl: webBaseUrl + '/beauty-aesthetic-insurance-companies'
            onclick: () => navigate(createWebsiteRoute())
        },
        {
            title: "Insurance & Cover",
            subTitle: "Protect your business against potential claims.",
            imageIcon: insuranceIcon,
            // redirectUrl: webBaseUrl + '/beauty-aesthetic-insurance-companies'
            onclick: () => navigate(insuranceLandingPagePattern())
        },
        {
            title: "Complication Consultant Helpline",
            subTitle: "Instant help for treatment complications.",
            imageIcon: helplineIcon,
            redirectUrl: webBaseUrl + '/aesthetic-help-line'
        },
        {
            title: "Brand Boost",
            subTitle: "Enhance your online visibility with our experts.",
            imageIcon: brandboostIcon,
            // redirectUrl: webBaseUrl + '/social-media-marketing'
            onclick: () => navigate(socialMediaMarketingPagePattern())

        }

    ]
    return (
        <div className='explore-container-main-div Main-Explore-DashBoard'>
            <div className="essentials-container mb-5 col-md-12">
                <div className="mb-2">
                    <h2 className="align-items-center">Explore</h2>
                </div>
                <div className="Explore-Grid">
                    {
                        exploreData.map((item, index) =>
                            <div class="Explore-Item" key={index}>
                                {
                                    item.redirectUrl ? <div className="explore-card d-flex justify-content-between cursor-pointer my-2" onClick={() => window.location.href = item.redirectUrl}>
                                        <div>
                                            <p className="fw-bold NewTagWrapper">{item.title}  
                                                {item.title == 'My Website' && <span className='newTag'><img src={newFeature} /> <span>New</span> </span>}</p>
                                            <p>{item.subTitle}</p>
                                        </div>
                                        <div>
                                            <img src={item.imageIcon} height="29px" width="29px" />
                                        </div>
                                    </div> : <div className="explore-card d-flex justify-content-between cursor-pointer my-2" onClick={ item?.onclick ? item.onclick :null}>
                                        <div>
                                            <p className="fw-bold NewTagWrapper">{item.title} 
                                                {item.title == 'My Website' && <span className='newTag'><img src={newFeature} /> <span>New</span></span>}</p>
                                            <p>{item.subTitle}</p>
                                        </div>
                                        <div>
                                            <img src={item.imageIcon} height="29px" width="29px" />
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ExploreContainer