import AccountDetails from "./AccountDetails/AccountDetails";
import BusinessNavigation from "../../common/businessNavigation/BusinessNavigation";
import { ReactComponent as AccountDetailsIcon } from "../../../images/sprint6/AccountDetails.svg";
import { ReactComponent as ShopSettingsIcon } from "../../../images/sprint6/shop.svg";
import { ReactComponent as FinanceSettingsIcon } from "../../../images/sprint6/FinanceSettings.svg";
import { ReactComponent as RightArrowIcon } from "../../../images/sprint6/rightIcon.svg";
import ShopSettings from "./shopSettings/shopSettings";
import { ReactComponent as MakeMoney } from "../../../images/sprint6/money.svg"
import "./manage-account.scss";
import BusinessSettingHeader from "../../common/businessSettingHeader/BusinessSettingHeader";
import BusinessSidebar from "../../sidebar/BusinessSidebar";
import { useNavigate, useLocation } from "react-router";
import { getBusinessSettingPageRoute, getFinanceRegistrationRoute, shopSettingRoute } from "../../../Routes";
import { webBaseUrl } from "../../../config";
import { useEffect, useState } from "react";
import SidebarComponent from "../../sidebar";
import Cookies from "js-cookie";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import logo from "../../../images/icons/faces-logo-black.svg";
import closeIcon from "../../../images/Icon example.svg";
import BusinessHeader from "../../businessHeader";

const ManageAccount = ({ setCurrentPage, currentPage }) => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const [showSidebar, setShowSidebar] = useState(true);
  const [activeSidebar, setActiveSidebar] = useState(false);
  // const [isAuth, setIsAuth] = useState(false);
  let isMobile = false;
  if (width <= 991) {
    isMobile = true;
  }
  else {
    isMobile = false;
  }
  useEffect(() => {
    isMobile ? setShowSidebar(false) : setShowSidebar(true);
  }, [isMobile]);

  // useEffect(() => {
  //   console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
  //   Cookies.get("faces_access_token") ? setIsAuth(true) : setIsAuth(false);
  //   console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
  // });
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.from === "shop_Pharmacies_settings_shop_settings")

      setStep(2)
  }, [location])
  const renderTabContent = () => {
    if (step == 1) {
      return <AccountDetails setStep={setStep} />;
    }
    if (step == 2) {
      return <ShopSettings setParentStep={setStep} />;
    }
  };

  return (
    <div className="MainBusinessProfile">
      <BusinessHeader
        // openReveviewPopup={setOpenReviewPopup}
        openMobileMenu={() => {
          setShowSidebar(!showSidebar);
          setActiveSidebar(true);
          document.body.classList.add('sidebarOpen');
        }}
        isMobile={isMobile}
      />
      <div className="Business-Profile-Main-Wrapper container-fluid">
        <div className="sidebarDesignUpdateSprint6">
          {isMobile ? (
            <div
              className={
                showSidebar ? " MobileSidebar  ActiveSidebar" : "MobileSidebar"
              }
            >
              {activeSidebar && (
                <>
                  <div className="overlaySidebar"></div>
                  <div className="sidebar">
                    <div className="sidebarLogoWrapper">
                      <div className="logoSidebar">
                        <img src={logo} alt="logo" />
                      </div>
                      <div
                        className="sidebarClose"
                        onClick={() => {
                          setShowSidebar(false);
                          document.body.classList.remove('sidebarOpen');
                        }}
                      >
                        <img src={closeIcon} alt="closeIcon" />
                      </div>
                    </div>
                    <SidebarComponent />
                  </div>
                </>
              )}
            </div>
          ) : (

            <div className="sidebarPart">
              <div className="sidebar">
                <SidebarComponent />
              </div>
            </div>
          )}
          <div className={"MainContentPart"}>
            <div className="MainManageAccountPages ">
              {step > 0 ?
                renderTabContent()
                :
                <div className="Main684WidthSet">
                  <BusinessNavigation
                    isColumn={true}
                    title={"Manage account"}
                    desc={"Simplify Your Account Management Experience"}
                    onBackClick={() => { navigate(getBusinessSettingPageRoute) }}
                  />
                  <div className="linkGrid">
                    {/* onClick={() => { setStep(1) }} */}
                    <div className="linkItem" onClick={() => { window.location.href = webBaseUrl + "/user/update-profile" }}>
                      <div className="linkImg">
                        <AccountDetailsIcon />
                      </div>
                      <div className="linkContent">
                        <h3>Account details</h3>
                        <p>Edit settings and account details</p>
                      </div>
                      <div className="RightIcon">
                        <RightArrowIcon />
                      </div>
                    </div>

                    <div className="linkItem" onClick={() => { navigate(shopSettingRoute()) }}>
                      <div className="linkImg">
                        <ShopSettingsIcon />
                      </div>
                      <div className="linkContent">
                        <h3>Shop settings</h3>
                        <p>Customise your shop preferences.</p>
                      </div>
                      <div className="RightIcon">
                        <RightArrowIcon />
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default ManageAccount;
