import BusinessHeader from "../../businessHeader";
import "./document.scss";
import CustomModal from "../../modal/CustomModal";
import CustomCalendar from "../../pages/dateTime/steps/CustomCalendar";
import { useEffect, useRef, useState } from "react";
import Dropzone from "../../dropzone/dropzone";
import calander from "../../../images/calander-plus.svg";
import nextIcon from "../../../images/calander_next.svg";
import Whatsappss from "../../../images/whatsappss.svg";
import analysis from "../../../images/analysis.png";
import shield from "../../../images/shield.png";
import { validName, validPhone } from "../../../utils/Regex";
import PropTypes from "prop-types";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";
import moment from "moment";
import shieldIcon from "../../../images/starinshield.svg";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import deleteIcon from "../../../images/deleteIcon.svg";
import verifiedStart from "../../../images/verifiedStart.svg";
import { ReactComponent as Uploads } from "../../../images/Image.svg";
import {
  UploadIdentityDocument,
  GetIdentityDocument,
  DeleteIdentityDocument,
} from "../../redux/actions/pharmacy-registration/identityDocument";
import {
  PrescriberTrainerDetails,
  GetPrescriberTrainerDetails,
  DeleteTrainerDocument,
} from "../../redux/actions/pharmacy-registration/trainingDocument";
import {
  PrescriberInsuranceDetails,
  GetPrescriberInsuranceDetails,
} from "../../redux/actions/pharmacy-registration/insuranceDocument";
import { GetInsuranceProviderList } from "../../redux/actions/pharmacy-registration/insuranceProviderList";
import { toast } from "react-toastify";
import FinanceHeader from "../../finance-registration/FinanceHomeComponent/FinanceHeaderComponent/FinanceHeaderComponent";
import FinanceBanner from "../../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner";
import StepButton from "../../common/StepButton";
import whatsappIcon from "../../../images/whatsappwhite.svg";
import { config } from "../../../config";
import image from "../../../images/analysis 1.png";
import { frontendURL } from "../../../config";
import CustomDropdown from "../../common/dropdown/CustomDropdown";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";

const Document = ({
  active,
  setActive,
  onNextStep,
  afterSubmit,
  role,
  finish,
  isRequestSentToAdmin,
}) => {
const validInsuranceName = new RegExp('^[a-zA-Z0-9 ]{0,30}$');

  const WhatsappLinkRedirect = config.WHATSAPP_LINK;
  const { initiateUploadIdentityDocument } = UploadIdentityDocument();
  const { initGetIdentityDocument, data } = GetIdentityDocument();
  const { initiateDeleteIdentityDocument } = DeleteIdentityDocument();
  const { initiateDeleteTrainerDocument } = DeleteTrainerDocument();
  const { initiateSavePrescriberTrainerDetails } = PrescriberTrainerDetails();
  const { initGetPrescriberTrainerDetails, data: trainerData } =
    GetPrescriberTrainerDetails();
  const { initiateSavePrescriberInsuranceDetails } =
    PrescriberInsuranceDetails();
  const { initGetPrescriberInsuranceDetails, data: insuranceData } =
    GetPrescriberInsuranceDetails();
  const { initGetInsuranceProviderList, data: insuranceOptionsData } =
    GetInsuranceProviderList();
  const TrainingAcademyErrorRef = useRef(null);
  const MobileNumberErrorRef = useRef(null);
  const InsuranceCompanyNameErrorRef = useRef(null);
  const InsuranceExpiryDateErrorRef = useRef(null);
  const PolicyNumberErrorRef = useRef(null);

  const [fetchLoading, setFetchLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  //document part
  const [document, setDocument] = useState({});
  const [identityUrl, setIdentityUrl] = useState("");
  const [isContinue, setIsContinue] = useState(false);
const [identityError,setIdentityError] = useState(false)


  //Traninng part
  const [document1, setDocument1] = useState({});
  const [document2, setDocument2] = useState({});
  const [country, setCountry] = useState("GB");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [docName, setDocName] = useState({
    trainingAcademy: "",
    mobileNumber: "",
    doc_name1: "Toxin Certificate",
    doc_name2: "Dermal Filler Certificate",
  });
  const [errorTrainingAcademy, setErrorTrainingAcademy] = useState(false);
  const [errorMobileNumber, setErrorMobileNumber] = useState(false);
  const [otherDocs, setOtherDocs] = useState([
    {
      image: "",
      image_url: "",
      title: "",
    },
  ]);

  //insurance part
  const [insuranceDoc, setInsuranceDoc] = useState([{}]);
  const [insurance, setInsurance] = useState({
    iname: "",
    iExpiryDate: "",
    iPolicyNo: "",
  });
  const [errorInsuranceName, setErrorInsuranceName] = useState(false);
  const [errorPolicyNumber, setErrorPolicyNumber] = useState(false);
  const [errorInsuranceExpiryDate, setErrorInsuranceExpiryDate] =
    useState(false);
  const [insuranceOption, setInsuranceOption] = useState([]);
  const [otherSelected, setOtherSelected] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    // setFetchLoading(true)
    if (active === 1) {
      afterSubmit();
      initGetIdentityDocument();
    } else if (active === 2) {
      afterSubmit();
      initGetPrescriberTrainerDetails();
    } else if (active === 3) {
      // afterSubmit();
      // initGetInsuranceProviderList();
      // initGetPrescriberInsuranceDetails();

      // Call any initialization functions here if needed
      initGetInsuranceProviderList();
      initGetPrescriberInsuranceDetails();
    }
  }, [active]);
  useEffect(() => {
    setErrorInsuranceName(false)
    setErrorPolicyNumber(false)
    setErrorInsuranceExpiryDate(false)
    setErrorTrainingAcademy(false)
    setErrorMobileNumber(false)
    setIdentityError(false)
  }, [active]);
  useEffect(() => {
    if (TrainingAcademyErrorRef.current) {
      TrainingAcademyErrorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (MobileNumberErrorRef.current) {
      MobileNumberErrorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (InsuranceCompanyNameErrorRef.current) {
      InsuranceCompanyNameErrorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (InsuranceExpiryDateErrorRef.current) {
      InsuranceExpiryDateErrorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (PolicyNumberErrorRef.current) {
      PolicyNumberErrorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [
    errorTrainingAcademy,
    errorMobileNumber,
    errorInsuranceName,
    errorInsuranceExpiryDate,
    errorPolicyNumber,
  ]);
  useEffect(() => {
    setInsuranceOption(
      insuranceOptionsData?.getInsuranceOptions?.data?.options
    );
  }, [insuranceOptionsData]);

  useEffect(() => {
    if (otherDocs?.length < 1) {
      const emptyObjectsToAdd = 1 - otherDocs?.length;
      const emptyObjectsArray = Array.from(
        { length: emptyObjectsToAdd },
        () => ({})
      );
      setOtherDocs((prevOtherDocs) => [...prevOtherDocs, ...emptyObjectsArray]);
    }
  }, [otherDocs]);

  useEffect(() => {
    if (insuranceDoc?.length < 1) {
      const emptyObjectsToAdd = 1 - insuranceDoc?.length;
      const emptyObjectsArray = Array.from(
        { length: emptyObjectsToAdd },
        () => ({})
      );
      setInsuranceDoc((prevOtherDocs) => [
        ...prevOtherDocs,
        ...emptyObjectsArray,
      ]);
    }
  }, [insuranceDoc]);

  // Function to convert File to base64
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (active === 2 && trainerData) {
      const fetchedData = trainerData?.getPrescriberTrainerDetails?.data;
      setDocName((prev) => ({
        ...prev,
        trainingAcademy: fetchedData?.trainer_name,
        mobileNumber: fetchedData?.trainer_phone_no,
      }));
      setDocument1(
        trainerData?.getPrescriberTrainerDetails?.data?.toxin_certificate
          ?.image_url
          ? {
              url: trainerData?.getPrescriberTrainerDetails?.data
                ?.toxin_certificate?.image_url,
            }
          : {}
      );
      setDocument2(
        trainerData?.getPrescriberTrainerDetails?.data?.dermal_certificate
          ?.image_url
          ? {
              url: trainerData?.getPrescriberTrainerDetails?.data
                ?.dermal_certificate?.image_url,
            }
          : {}
      );
      setSelectedCountryCode(fetchedData?.trainer_phone_country_code);
      setCountry(
        fetchedData?.trainer_phone_country_initial?.toUpperCase() || "GB"
      );
      // Ensure otherDocs has a minimum length of 3
      if (fetchedData?.otherImages?.length < 1) {
        setOtherDocs((prevOtherDocs) => [
          ...(fetchedData?.otherImages || []),
          ...Array(1 - (fetchedData?.otherImages?.length || 0)).fill({}),
        ]);
      } else {
        setOtherDocs(fetchedData?.otherImages);
      }
      // setFetchLoading(false);
    }
  }, [trainerData, active]);

  useEffect(() => {
    if (active === 1 && data) {
      if (data?.getIdentityDocument?.data) {
        setIdentityUrl(data?.getIdentityDocument?.data?.docUrl);
      }
    }
  }, [data, active]);
  useEffect(() => {
    if (active === 3 && insuranceData) {
      const maxValidYear = moment().year() + 25; // Limiting to the next 25 years
      const currentYear = moment().year();
      const pinExpiryDate = moment(
        insuranceData?.getPrescriberInsuranceDetails?.data?.insurance_expiry,
        "YYYY-MM-DD",
        true
      );

      if (
        pinExpiryDate.isValid() &&
        // pinExpiryDate.year() >= currentYear &&
        pinExpiryDate.year() <= maxValidYear
      ) {
        // setFormatedDate(pinExpiryDate.format("D MMMM YYYY"));
        setShowSelectedDate(pinExpiryDate.format("dddd, Do MMMM YYYY"));
        setSelectedMonth(pinExpiryDate.month() + 1);
        setSelectedYear(pinExpiryDate.year());

        if (
          moment(
            insuranceData?.getPrescriberInsuranceDetails?.data?.insurance_expiry
          ).isBefore(moment())
        ) {
          const updatedDateData = generateDateData(
            insuranceData?.getPrescriberInsuranceDetails?.data
              ?.insurance_expiry,
            endDatee
          );
          setDateData(updatedDateData);
        }
      } else {
        setFormatedDate("");
        setShowSelectedDate(null);
      }

      if (pinExpiryDate.isValid() && pinExpiryDate.year() <= maxValidYear) {
        setFormatedDate(pinExpiryDate.format("D MMMM YYYY"));
      }
      setInsurance({
        iname:
          insuranceData?.getPrescriberInsuranceDetails?.data?.insurance_company,
        iExpiryDate:
          pinExpiryDate.isValid() && pinExpiryDate.year() <= maxValidYear
            ? insuranceData?.getPrescriberInsuranceDetails?.data
                ?.insurance_expiry
            : "",
        iPolicyNo:
          insuranceData?.getPrescriberInsuranceDetails?.data?.policy_number,
      });

      if (
        insuranceData?.getPrescriberInsuranceDetails?.data?.otherImages
          ?.length < 1
      ) {
        setInsuranceDoc((prevOtherDocs) => [
          ...(insuranceData?.getPrescriberInsuranceDetails?.data?.otherImages ||
            []),
          ...Array(
            1 -
              (insuranceData?.getPrescriberInsuranceDetails?.data?.otherImages
                ?.length || 0)
          ).fill({}),
        ]);
      } else {
        setInsuranceDoc(
          insuranceData?.getPrescriberInsuranceDetails?.data?.otherImages
        );
      }
    }

    // setFetchLoading(false);
  }, [insuranceData, active]);

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setDocName({ ...docName, [name]: value });
  };

  const handleAddDropzone = () => {
    if (otherDocs?.length < 8) {
      const nextDocIndex = otherDocs?.length + 1;
      const newDocumentState = {
        image: "",
        image_url: "",
        title: "",
      };

      setOtherDocs((prevOtherDocs) => [...prevOtherDocs, newDocumentState]);
    } else {
      toast.error("You can't upload more than 8 training certificates.");
    }
    console.log("hello", otherDocs);
  };
  const handleAddInsuranceDropzone = () => {
    if (insuranceDoc?.length < 10) {
      const nextDocIndex = otherDocs?.length + 1;
      const newDocumentState = {
        image: "",
        image_url: "",
        title: "",
      };

      setInsuranceDoc((prevOtherDocs) => [...prevOtherDocs, newDocumentState]);
    } else {
      toast.error("Maximum limit of documents reached.");
    }
    console.log("hello", otherDocs);
  };
  const handleTitleChange = (e, index) => {
    const { name, value } = e.target;
    setOtherDocs((prevOtherDocs) => {
      const updatedDocs = [...prevOtherDocs];
      updatedDocs[index] = { ...updatedDocs[index], title: value };
      return updatedDocs;
    });
  };
  const handleInsuranceTitleChange = (e, index) => {
    const { name, value } = e.target;
    setInsuranceDoc((prevOtherDocs) => {
      const updatedDocs = [...prevOtherDocs];
      updatedDocs[index] = { ...updatedDocs[index], title: value };
      return updatedDocs;
    });
  };

  const onInsuranChange = (e) => {
    const { name, value } = e.target;
    setInsurance({ ...insurance, [name]: value });
  };

  const onSubmit = () => {
    console.log("onSubmit", document, data?.getIdentityDocument?.data?.docUrl);
    if (Object.keys(document)?.length > 0) {
      setLoading(true);
      initiateUploadIdentityDocument(
        {
          identityDoc: document,
        },
        (res) => {
          setLoading(false);
          toast.success(res.message);
          afterSubmit();
          setActive(2);
          // setIsContinue(true);
        },
        (error) => {
          setLoading(false);
          toast.error(error.message);
        }
      );
    } else {
      if (!data?.getIdentityDocument?.data?.docUrl || !identityUrl) {
        // toast.error("Please upload driving license or passport");
        setIdentityError(true);
      } else {
        setIdentityError(false);
        setLoading(true);
        setActive(2);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (document && typeof document === 'object' && document instanceof File) {
      setIdentityError(false);
    }
  }, [document]);
  

  const convertDocumentToBase64 = (document) => {
    return new Promise((resolve, reject) => {
      if (!(document instanceof File)) {
        resolve(null);
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(document);
    });
  };

  const onTraining = async () => {
    if (
      !isContinue &&
      ((role === "Prescriber" &&
        (!docName?.mobileNumber || validPhone?.test(docName?.mobileNumber)) &&
        (!docName?.trainingAcademy ||
          validName.test(docName?.trainingAcademy))) ||
        (role !== "Prescriber" &&
          // docName?.mobileNumber &&
          // validPhone?.test(docName?.mobileNumber) &&
          (!docName?.mobileNumber || validPhone?.test(docName?.mobileNumber)) &&
          docName?.trainingAcademy &&
          validName.test(docName?.trainingAcademy)))
    ) {
      function isEmptyObject(obj) {
        return Object.keys(obj)?.length === 0 && obj.constructor === Object;
      }
      const hasDocuments =
        !isEmptyObject(document1) ||
        !isEmptyObject(document2) ||
        (otherDocs &&
          otherDocs.some((doc) => doc && (doc.image || doc.image_url)));
      if (!hasDocuments && role !== "Prescriber") {
        toast.error("Please upload at least one certificate.");
        return;
      }
      // const invalidDocs = otherDocs.filter(
      //   (doc) => (doc?.image || doc?.image_url) && !doc.title
      // );
      // if (invalidDocs?.length > 0) {
      //   toast.error("Please add a title for all certificates.");
      //   return;
      // }

      const promises = otherDocs?.map(async (doc) => {
        if (doc?.image) {
          // const base64Image = await fileToBase64(doc.image);
          return { image: doc?.image, title: doc?.title };
        } else if (doc?.image_url && doc.id) {
          return { title: doc?.title, id: doc?.id };
        } else {
          return null;
        }
      });
      const otherImages = (await Promise.all(promises)).filter(
        (imageData) => imageData !== null
      );
      console.log(otherImages, "images");
      setLoading(true);
      initiateSavePrescriberTrainerDetails(
        {
          trainerName: docName?.trainingAcademy,
          phoneNoCountryInitial: country,
          phoneNoCountryCode: selectedCountryCode,
          phoneNo: docName?.mobileNumber,
          toxinCertificateImageFile:
            document1 instanceof File ? document1 : null,
          dermalFillerCertificateImageFile:
            document2 instanceof File ? document2 : null,
          otherImagesFile: otherImages,
        },
        (res) => {
          setLoading(false);
          toast.success(res.message);
          afterSubmit();
          setActive(3);
          // setIsContinue(true);
        },
        (error) => {
          setLoading(false);
          toast.error(error.message);
        }
      );
    } else {
      // if (
      //   (role !== "Prescriber" || docName?.mobileNumber) && // Validation applies only when role is not "Prescriber" or when mobileNumber is present
      //   validPhone.test(docName?.mobileNumber) // Check if the mobileNumber passes the phone number validation
      // ) {
      //   setErrorMobileNumber(false); // Set error state for mobileNumber to false (indicating no error)
      // } else {
      //   setErrorMobileNumber(true); // Set error state for mobileNumber to true (indicating error)
      // }

      if (docName?.mobileNumber && validPhone.test(docName?.mobileNumber)) {
        setErrorMobileNumber(false);
      } else {
        setErrorMobileNumber(true);
      }
      if (
        role === "Prescriber"
          ? docName?.trainingAcademy && validName.test(docName?.trainingAcademy)
          : docName?.trainingAcademy.length > 0 &&
            validName.test(docName?.trainingAcademy)
      ) {
        setErrorTrainingAcademy(false);
      } else {
        setErrorTrainingAcademy(true);
      }

      if (TrainingAcademyErrorRef.current) {
        TrainingAcademyErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (MobileNumberErrorRef.current) {
        MobileNumberErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  const onInsurance = async () => {
    if (
      (role === "Prescriber" &&
        (!insurance?.iname || validInsuranceName.test(insurance?.iname)) &&
        (!insurance?.iExpiryDate ||
          (insurance?.iExpiryDate &&
            moment(insurance?.iExpiryDate, "YYYY-MM-DD").isSameOrAfter(
              moment(),
              "day"
            )))) || // For Prescribers, validate only if insurance name is present and valid
      (role !== "Prescriber" &&
        insurance?.iname &&
        insurance?.iname !== "Other" &&
        insurance?.iExpiryDate &&
        moment(insurance?.iExpiryDate, "YYYY-MM-DD").isSameOrAfter(
          moment(),
          "day"
        ) &&
        validInsuranceName.test(insurance?.iname)) // For non-Prescribers, insurance name must be present and valid
    ) {
      const hasDocuments =
        insuranceDoc &&
        insuranceDoc.some((doc) => doc && (doc.image || doc.image_url));

      if (!hasDocuments && role !== "Prescriber") {
        toast.error("please upload insurance docs.");
        return;
      }

      // const invalidDocs = insuranceDoc.filter(
      //   (doc) => (doc?.image || doc?.image_url) && !doc.title
      // );
      // if (invalidDocs?.length > 0) {
      //   toast.error("Please add a title for all documents.");
      //   return;
      // }
      setLoading(true);
      const promises = insuranceDoc?.map(async (doc) => {
        if (doc?.image) {
          // const base64Image = await fileToBase64(doc.image);
          return { image: doc?.image, title: doc?.title };
        } else if (doc?.image_url && doc?.id) {
          return { title: doc?.title, id: doc?.id };
        } else {
          return null;
        }
      });
      const otherImages = (await Promise.all(promises)).filter(
        (imageData) => imageData !== null
      );

      initiateSavePrescriberInsuranceDetails(
        {
          insuranceCompany: insurance?.iname,
          insuranceExpiry: insurance?.iExpiryDate,
          policyNumber: insurance?.iPolicyNo,
          // insurnaceDocuments: otherImages,
          insurnaceDocumentsFiles: otherImages,
        },
        (res) => {
          setLoading(false);
          toast.success(res.message);
          setActive(3);
          afterSubmit();
          onNextStep();
          // setIsContinue(true);
        },
        (error) => {
          setLoading(false);
          toast.error(error.message);
        }
      );
    } else {
      if (role !== "Prescriber") {
        if (
          insurance?.iname &&
          insurance?.iname !== "Other" &&
          insurance?.iname?.length > 0 &&
          validInsuranceName.test(insurance?.iname)
        ) {
          setErrorInsuranceName(false);
        } else {
          setErrorInsuranceName(true);
        }
      } else if (insurance?.iname !== "" && role === "Prescriber") {
        if (validInsuranceName.test(insurance?.iname)) {
          setErrorInsuranceName(false);
        } else {
          setErrorInsuranceName(true);
        }
      }

      if (role !== "Prescriber") {
        if (!insurance?.iExpiryDate) {
          setErrorInsuranceExpiryDate(true); // Set error if iExpiryDate is missing
        } else if (
          moment(insurance?.iExpiryDate, "YYYY-MM-DD").isBefore(moment(), "day")
        ) {
          setErrorInsuranceExpiryDate(true); // Set error if iExpiryDate is in the past
        } else {
          setErrorInsuranceExpiryDate(false); // Clear error if iExpiryDate is present and valid
        }
      } else {
        if (
          insurance?.iExpiryDate &&
          moment(insurance?.iExpiryDate, "YYYY-MM-DD").isBefore(moment(), "day")
        ) {
          setErrorInsuranceExpiryDate(true); // Set error if iExpiryDate is in the past
        } else {
          setErrorInsuranceExpiryDate(false); // Clear error if iExpiryDate is not in the past or doesn't exist
        }
      }

      // if (role !== "Prescriber" && !insurance?.iPolicyNo) {
      //   setErrorPolicyNumber(true);
      // } else {
      //   setErrorPolicyNumber(false);
      // }

      if (InsuranceCompanyNameErrorRef.current) {
        InsuranceCompanyNameErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (InsuranceExpiryDateErrorRef.current) {
        InsuranceExpiryDateErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (PolicyNumberErrorRef.current) {
        PolicyNumberErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  // const CountrySelect = ({
  //   value,
  //   setCountry,
  //   setSelectedCountryCode,
  //   labels,
  //   ...rest
  // }) => (
  //   <select
  //     {...rest}
  //     value={value}
  //     onChange={(event) => {
  //       setCountry(event.target.value || undefined);
  //       setSelectedCountryCode(getCountryCallingCode(event?.target?.value));
  //     }}
  //   >
  //     <option value="">+44</option>
  //     {getCountries()?.map((country) => (
  //       <option key={country} value={country}>
  //         +{getCountryCallingCode(country)}
  //       </option>
  //     ))}
  //   </select>
  // );

  // CountrySelect.propTypes = {
  //   value: PropTypes.string,
  //   setCountry: PropTypes.func.isRequired,
  //   setSelectedCountryCode: PropTypes.func.isRequired,
  //   labels: PropTypes.objectOf(PropTypes.string).isRequired,
  // };

  //for countrySelection
  const CountrySelect = ({
    value,
    onChange,
    selectedLabel,
    labels,
    ...rest
  }) => (
    <div className="prescriber-country-selection-container">
      <select
        {...rest}
        value={value}
        onChange={(event) => onChange(event.target.value || undefined)}
      >
        <option value="">{labels["ZZ"]}</option>
        {getCountries().map(
          (country, i) =>
            country !== "International" && (
              <option key={country} value={country}>
                {`+${getCountryCallingCode(country)}`}
              </option>
            )
        )}
      </select>
      <span className="selected-label">{selectedLabel}</span>
    </div>
  );

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
    selectedLabel: PropTypes.string,
  };

  //HANDLE PHONE NUMBER CHANGE
  const handleCountryChange = (country) => {
    setCountry(country);
    // Get the label based on the selected country
    const selectedLabel = getCountries().includes(country)
      ? `${getCountryCallingCode(country)}`
      : "";

    setSelectedCountryCode(selectedLabel);
  };

  //calander
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSelectedDate, setShowSelectedDate] = useState(
    // moment().format("dddd, Do MMMM")
    null
  );
  const [calendarStartDate, setCalendarStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(
    selectedDate.getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
  const [formatedDate, setFormatedDate] = useState("");
  const [step, setStep] = useState("");
  const [showSelectedTime, setShowSelectedTime] = useState("");
  const [isOpenMobileCalendar, setIsOpenMobileCalendar] = useState(false);
  const handleSelectedDate = (selectedDate) => {
    setShowSelectedDate(selectedDate);
  };
  // Function to generate an array of dates for a specified range
  function generateDateData(startDate, endDate) {
    const dates = [];
    const currentDate = new Date(startDate);
    // Loop to generate dates until the endDate is reached
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().slice(0, 10));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }
  const today = new Date();
  const endDatee = new Date(today);
  endDatee.setFullYear(today.getFullYear() + 25);
  const dateDataDefault = generateDateData(today, endDatee);
  const [dateData, setDateData] = useState(dateDataDefault);
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };
  useEffect(() => {
    if(showSelectedDate){
      setFormatedDate(
        moment(showSelectedDate, "dddd, Do MMMM YYYY").format("D MMMM YYYY")
      );
      setInsurance((prev) => ({
        ...prev,
        iExpiryDate: moment(showSelectedDate, "dddd, Do MMMM YYYY").format(
          "YYYY-MM-DD"
        ),
      }));
    }
 
    // setShowCalendar(false);
  }, [showSelectedDate]);

  const handleBackClick = () => {
    if (active > 1) {
      setActive(active - 1);
    }
  };

  const customStyle = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Adjust the z-index value according to your requirement
    }),
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "#FFFFFF",
      outline: "none",
      appearance: "none",
      padding: "10px 10px",
      borderRadius: "10px",
      border: "1px solid #878787",
      height: "55px",
      width: "100%",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
      };
    },
  };
  {
    console.log("insurance", insurance.iExpiryDate, showSelectedDate);
  }
  return (
    <div className="main-Identity-div">
      {/* <FinanceHeader knowmore={false} onBackClick={handleBackClick}/> */}

      {/* <BusinessHeader /> */}
      {active === 1 && (
        <div className="mainDocumnetSecWrapper">
          <FinanceBanner
            title={"Identity"}
            desc={
              "We just need some ID now to confirm your identity."
            }
            isBorder={!isRequestSentToAdmin}
            currentStep={finish}
            totalSteps={4}
          />
          <div className={`docmentation ${!isRequestSentToAdmin ? "" : ""}`}>
            <div className="form-label">Driving License or Passport</div>
            <div className="customeDropDownWrapper">
              <Dropzone
                fileData={setDocument}
                urlFromDB={identityUrl}
                handleDelete={initiateDeleteIdentityDocument}
                setIdentityUrl={setIdentityUrl}
              />
            </div>
{identityError &&
  <span className="error">Please upload driving license or passport</span>
}

          </div>
          {console.log(isContinue, "isContinue")}
          <div className="BtnButton">
            <StepButton
              blue={true}
              label={"Continue"}
              onClick={() => onSubmit()}
              // disabled={isContinue}
              isLoading={loading}
            />
          </div>
        </div>
      )}
      {active === 2 && (
        <div className="main-Training-sec">
          <div className="whatsappwrapper">
            <div className="TrainingContent">
              <FinanceBanner
                title={"Training details"}
                desc={
                  "We now need to know about where you were trained and what you are trained in"
                }
                isBorder={!isRequestSentToAdmin}
                currentStep={finish}
                totalSteps={4}
                isTranningBanner={true}
              />

              <form className={`form-data ${!isRequestSentToAdmin ? "" : ""}`}>
                <div className="first-flex-div">
                  {fetchLoading && !trainerData ? (
                    <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
                  ) : (
                    <div class="finance-input-container">
                      <label for="traningAcademy" className="form-label">
                        Training academy
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="trainingAcademy"
                        value={docName?.trainingAcademy}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            (role !== "Prescriber" || inputValue) &&
                            inputValue?.length >= 3 &&
                            validName.test(inputValue)
                          ) {
                            setErrorTrainingAcademy(false);
                          } else {
                            setErrorTrainingAcademy(true);
                          }
                          onHandleChange(e);
                        }}
                        id="traningAcademy"
                        placeholder="Training academy"
                      />
                      {errorTrainingAcademy && (
                        <div className="error" ref={TrainingAcademyErrorRef}>
                          {docName?.trainingAcademy
                            ? "Please enter valid training academy name."
                            : role !== "Prescriber"
                            ? "Training academy name is required."
                            : ""}
                        </div>
                      )}
                    </div>
                  )}
                  {fetchLoading && !trainerData ? (
                    <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
                  ) : (
                    <div class="finance-input-container">
                      <label for="traningAcademy" className="form-label">
                        Trainer phone no (Optional)
                      </label>
                      <div className="CountryInputWrapper">
                        <CountrySelect
                          className="country-code"
                          labels={{ ZZ: "Select" }}
                          value={country}
                          onChange={(e) => handleCountryChange(e)}
                        />

                        <input
                          type="number"
                          name="mobileNumber"
                          value={docName?.mobileNumber}
                          // onChange={(e) => {
                          //   const inputValue = e.target.value;
                          //   if (
                          //     inputValue?.length === 10 &&
                          //     validPhone.test(inputValue)
                          //   ) {
                          //     setErrorMobileNumber(false);
                          //   } else {
                          //     setErrorMobileNumber(true);
                          //   }
                          //   onHandleChange(e);
                          // }}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (role === "Prescriber") {
                              // If the role is Prescriber, validate the phone number if provided
                              if (inputValue && validPhone.test(inputValue)) {
                                setErrorMobileNumber(false);
                              } else {
                                setErrorMobileNumber(true);
                              }
                            } else {
                              // For other roles, phone number field is required
                              if (inputValue && validPhone.test(inputValue)) {
                                setErrorMobileNumber(false);
                              } else {
                                setErrorMobileNumber(true);
                              }
                            }
                            onHandleChange(e);
                          }}
                          class="form-control"
                          id="traningAcademy"
                          placeholder="Trainer phone no"
                        />
                      </div>
                      {errorMobileNumber && (
                        <div className="error " ref={MobileNumberErrorRef}>
                          {docName?.mobileNumber
                            ? "Please enter valid phone number (10-15 digits)."
                            : ""}
                          {/* { docName?.mobileNumber
                          ? "Please enter valid phone number (10-15 digits)."
                          : role !== "Prescriber"
                          ? "Trainer phone number is required."
                          : ""} */}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </form>
              <div>
                <div className="Certificate-docmentation-Wrapper">
                  <div className="form-label">Training certificate</div>
                  <div className="docmentationGrid-Wrapper">
                    <div className="docmentationGridItem">
                      <Dropzone
                        fileData={setDocument1}
                        urlFromDB={
                          trainerData?.getPrescriberTrainerDetails?.data
                            ?.toxin_certificate?.image_url
                        }
                        handleDelete={initiateDeleteTrainerDocument}
                        id={
                          trainerData?.getPrescriberTrainerDetails?.data
                            ?.toxin_certificate?.id
                        }
                      />
                      <input
                        name="doc_name1"
                        value={docName?.doc_name1}
                        placeholder="Certificate title"
                      />
                    </div>
                    <div className="docmentationGridItem">
                      <Dropzone
                        fileData={setDocument2}
                        urlFromDB={
                          trainerData?.getPrescriberTrainerDetails?.data
                            ?.dermal_certificate?.image_url
                        }
                        handleDelete={initiateDeleteTrainerDocument}
                        id={
                          trainerData?.getPrescriberTrainerDetails?.data
                            ?.dermal_certificate?.id
                        }
                      />
                      <input
                        name="doc_name2"
                        value={docName?.doc_name2}
                        // onChange={(e) => onHandleChange(e)}
                        placeholder="Certificate title"
                      />
                    </div>

                    {otherDocs?.map((doc, index) => (
                      <div className="docmentationGridItem" key={index}>
                        {console.log(doc, "hello from map")}
                        <Dropzone
                          otherDocs={otherDocs}
                          imageFromComponent={doc.image || null}
                          handleMulti={setOtherDocs}
                          selectedIndex={index}
                          urlFromDB={doc.image_url}
                          handleDelete={initiateDeleteTrainerDocument}
                          id={doc.id}
                        />
                        <input
                          name={`doc_name${index + 1}`}
                          value={doc.title || ""}
                          onChange={(e) => handleTitleChange(e, index)}
                          placeholder="Certificate title"
                        />
                      </div>
                    ))}
                    {otherDocs?.length < 8 && (
                      <div className="docmentationGridItem">
                        <div className="dropzone" onClick={handleAddDropzone}>
                          <Uploads />
                          <p>Add Photo </p>
                        </div>
                        {/* <input name="Add" placeholder="Text Here" value="" /> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="BtnButton">
                {fetchLoading && !trainerData ? (
                  <SkeletonLoader type="PharmacyBusinessStepButtonSkeleton" />
                ) : (
                  <StepButton
                    blue={true}
                    label={"Continue"}
                    onClick={onTraining}
                    // disabled={isContinue}
                    isLoading={loading}
                  />
                )}
              </div>
            </div>
            {/* <div className="TrainingCardWrapper">
              <div className="cardIcon">
                <img src={analysis} className="me-1" />
                Need more Training ?
              </div>
              <div className="CardContent">
                <p>
                  Speak to our partnering training academy to upskill. Get 50% off
                  with code <b>‘Faces50’</b>.
                </p>
                <button
                  className="w-100 btn border-0 text-white shadow-none"
                  style={{
                    borderRadius: "100px",
                    background:
                      "linear-gradient(180deg, #70FD78 -34.31%, #3FB42E 100%)",
                  }}
                  onClick={() => {
                    window.open(WhatsappLinkRedirect, "_blank");
                  }}
                >
                  <img src={whatsappIcon} width={20} className="me-1" />
                  Whatsapp us
                </button>
              </div>
            </div> */}
          </div>
        </div>
      )}
      {active === 3 && (
        <div className="main-insurance-Details">
          <div
            className="
          whatsappwrapper"
          >
            <div>
              <FinanceBanner
                title={"Insurance details"}
                desc={
                  "By adding your insurance you will also become a verified business member this is an industry standard and demonstrates your professionalism to clients"
                }
                facesVarified={true}
                isBorder={!isRequestSentToAdmin}
                currentStep={finish}
                totalSteps={4}
                isInsuranceBanner={true}
              />
              <form className={`form-data ${!isRequestSentToAdmin ? "" : ""}`}>
                <div className="first-flex-div">
                  {fetchLoading && !insuranceData ? (
                    <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
                  ) : (
                    <div class="finance-input-container">
                      <CustomDropdown
                        labelName={"Insurance company"}
                        defaultValue={
                          insuranceOption?.includes(insurance?.iname)
                            ? insurance?.iname
                            : !insurance?.iname
                            ? ""
                            : "Other"
                        }
                        dropdownId={"Insurance Company"}
                        // placeholdername={"Insurance Company"}
                        optionsArr={insuranceOption}
                        style={customStyle}
                        handleSelectedValue={(value) => {
                          const inputValue = value;
                          if (
                            (role !== "Prescriber" || inputValue) &&
                            inputValue?.length >= 3
                          ) {
                            setErrorInsuranceName(false);
                          } else {
                            setErrorInsuranceName(true);
                          }
                          setInsurance((prevInsurance) => ({
                            ...prevInsurance,
                            iname: value,
                          }));
                          setOtherSelected(value === "Other");
                        }}
                        readOnly={false}
                      />
                      {(otherSelected ||
                        (insurance?.iname !== "" &&
                          !insuranceOption?.includes(insurance?.iname))) && (
                        <input
                          type="text"
                          class="form-control mt-3"
                          name="iname"
                          // value={insurance?.iname}
                          value={!otherSelected ? insurance?.iname : ""}
                          onChange={(e) => {
                            setOtherSelected(false);

                            const inputValue = e.target.value;
                            if (
                              (role !== "Prescriber" || inputValue) &&
                              inputValue?.length >= 3 &&
                              validInsuranceName.test(inputValue)
                            ) {
                              setErrorInsuranceName(false);
                            } else {
                              setErrorInsuranceName(true);
                            }
                            onInsuranChange(e);

                            if (!inputValue) {
                              setOtherSelected(true);
                              setInsurance((prevInsurance) => ({
                                ...prevInsurance,
                                iname: "other",
                              }));
                              if (role !== "prescriber") {
                                setErrorInsuranceName(true);
                              } else {
                                setErrorInsuranceName(false);
                              }
                            }
                          }}
                          placeholder="Insurance company"
                        />
                      )}
                      {errorInsuranceName && (
                        <div
                          className="error"
                          ref={InsuranceCompanyNameErrorRef}
                        >
                          {insurance?.iname
                            ? "Please enter valid insurance company name."
                            : role !== "Prescriber"
                            ? "Insurance company name is required."
                            : ""}
                        </div>
                      )}
                    </div>
                  )}

                  {fetchLoading && !insuranceData ? (
                    <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
                  ) : (
                    <div className="calenderWrapperError">
                      <div
                        className="calenderWrapper"
                        onClick={() => {
                          toggleCalendar();
                        }}
                      >
                        <div className="calenderIcon">
                          <img src={calander} alt="calander" />
                          <p>
                            {insurance.iExpiryDate === null ||
                            insurance.iExpiryDate === ""
                              ? "Expiry date"
                              : formatedDate}
                          </p>
                        </div>
                        <div className="calenderRightIcon">
                          <img src={nextIcon} alt="next" />
                        </div>
                      </div>
                      {errorInsuranceExpiryDate && (
                        <div
                          className="error"
                          ref={InsuranceExpiryDateErrorRef}
                        >
                          {role !== "Prescriber" &&
                            !insurance?.iExpiryDate &&
                            "Insurance expiry date is required."}
                          {role !== "Prescriber" &&
                            insurance?.iExpiryDate &&
                            moment(
                              insurance?.iExpiryDate,
                              "YYYY-MM-DD"
                            ).isBefore(moment(), "day") &&
                            "Please enter a valid future date."}
                          {role === "Prescriber" &&
                            insurance?.iExpiryDate &&
                            moment(
                              insurance?.iExpiryDate,
                              "YYYY-MM-DD"
                            ).isBefore(moment(), "day") &&
                            "Please enter a valid future date."}
                        </div>
                      )}
                    </div>
                  )}

                  {fetchLoading && !insuranceData ? (
                    <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
                  ) : (
                    <div class="finance-input-container">
                      <label for="traningAcademy" className="form-label">
                        Policy number (Optional)
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="iPolicyNo"
                        value={insurance?.iPolicyNo}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          onInsuranChange(e);
                          // if (!inputValue && role !== "Prescriber") {
                          //   setErrorPolicyNumber(true);
                          // } else {
                          //   setErrorPolicyNumber(false);
                          // }
                        }}
                        placeholder="Policy number"
                      />
                      {errorPolicyNumber && (
                        <div className="error" ref={PolicyNumberErrorRef}>
                          {!insurance?.iPolicyNo &&
                            role !== "Prescriber" &&
                            "Policy number is required."}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </form>
              <div className="Certificate-docmentation-Wrapper">
                <div className="form-label">Insurance document</div>
                <div className="docmentationGrid-Wrapper">
                  {/* <div className="row"> */}
                  {insuranceDoc?.map((doc, index) => (
                    <div className="docmentationGridItem" key={index}>
                      <Dropzone
                        handleMulti={setInsuranceDoc}
                        selectedIndex={index}
                        urlFromDB={doc.image_url}
                        handleDelete={initiateDeleteTrainerDocument}
                        id={doc.id}
                        otherDocs={insuranceDoc}
                        imageFromComponent={doc.image || null}
                        imageTitle={doc.title || ""}
                      />
                      <input
                        name={`doc_name${index + 1}`}
                        value={doc.title || ""}
                        onChange={(e) => handleInsuranceTitleChange(e, index)}
                        placeholder="Description"
                      />
                    </div>
                  ))}
                  {insuranceDoc?.length < 10 && (
                    <div className="docmentationGridItem">
                      <div
                        className="dropzone"
                        onClick={handleAddInsuranceDropzone}
                      >
                        <Uploads />
                        <p>Add Photo </p>
                      </div>
                      {/* <input name="Add" placeholder="Text Here" value="" /> */}
                    </div>
                  )}
                  {/* </div> */}
                </div>
              </div>

              <div className="BtnButton">
                {fetchLoading && !insuranceData ? (
                  <SkeletonLoader type="PharmacyBusinessStepButtonSkeleton" />
                ) : (
                  <StepButton
                    blue={true}
                    label={"Save & next"}
                    onClick={onInsurance}
                    // disabled={isContinue}
                    isLoading={loading}
                  />
                )}
              </div>
            </div>
            {/* <div
            className="InsuranceCard"
            onClick={() =>
              (window.location.href =
                frontendURL + "/beauty-aesthetic-insurance-companies")
            }
          >
            <div className="InsuranceCardIconContentWrapper">
              <div className="InsuranceCardIcon">
                <img src={shieldIcon} alt="shieldIcon" />
              </div>
              <div className="ContentWrapper">
                <p className="doc_label">Need Insurance ?</p>
                <p className="doc_Desc">Compare Insurance quotes here</p>
              </div>
            </div>
            <FiChevronRight className="next_icon" />
          </div> */}
          </div>
        </div>
      )}

      <CustomModal
        modalOpen={showCalendar}
        setModalOpen={setShowCalendar}
        modalBody={
          <CustomCalendar
            setCalendarStartDate={setCalendarStartDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            dateData={dateData}
            showSelectedDate={showSelectedDate}
            setShowSelectedDate={setShowSelectedDate}
            onSelectDate={handleSelectedDate}
            setFormatedDate={setFormatedDate}
            setStep={setStep}
            setShowSelectedTime={setShowSelectedTime}
            isOpenMobileCalendar={isOpenMobileCalendar}
            setIsOpenMobileCalendar={setIsOpenMobileCalendar}
            setIsOpenCalendar={setShowCalendar}
            allowMonthYearChange={true}
          />
        }
        modalTitle={"calander"}
      />
    </div>
  );
};

export default Document;
