import { useEffect, useState } from "react";

export const StripeSavedCardsComponent = ({
  cards,
  performStripeWithCardId,
  changeCardPaymentType,
  handleRadioChange,
  selectedValue,
  isStripeFormFiledDisabled,
  isStripeSavedCardDisabled,
}) => {

  console.log(cards, "cards")

  return (
    <div className="saveCardsDiv mt-2 mb-2 row" style={{ maxWidth: '700px', margin: 'auto', fontSize: '14px' }}>
      {cards.map((answer, i) => {
        return (
          <div className="col-6 col-lg-4" style={{ marginBottom: '10px' }}>
            <label htmlFor={'stripeSavedCards' + i} style={{ width: '100%' }}>
              <div style={{ border: '1px solid #D7D7D7', padding: '5px 10px', borderRadius: '05px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', }} onClick={() => { changeCardPaymentType(true); performStripeWithCardId(answer.savedCardId);}}>
                <span style={{ alignItems: 'center', display: 'flex' }}>
                  <input type="radio" name="stripeSavedCards" id={'stripeSavedCards' + i} style={{ marginRight: '5px', height: '17px', width: '17px' }} onChange={handleRadioChange} value={answer.savedCardId} checked={selectedValue === answer.savedCardId}/>
                  <span>{answer.cardNumber}</span>
                </span>
                <span className="MonthWrapper">
                  <span className="ExText">Expires End </span>
                  <span className="MonthText">
                  {String(answer.cardExpMonth).padStart(2, '0') + '/' + answer.cardExpYear.substr(2, 2)}
                  </span>
                  </span>
              </div>
            </label>
          </div>
        )
      })}
    </div>
  );
};