import React, { useEffect, useState } from "react";
import "./discount-modal.scss";
import Modal from "react-modal";
import { ReactComponent as CloseIcon } from "../../../images/close_icon.svg";
import BannerHeader from "../../common/BannerHeader";
import StepButton from "../../common/StepButton";
import { GetDiscountCode } from "../../redux/actions/getDiscount/getDiscount";
import { toast } from "react-toastify";

const DiscountModal = ({ isOpen, setIsOpen, clinicId }) => {
  const [discount, setDiscount] = useState("");
  const { initDiscount, discountQueryData } = GetDiscountCode();

  const handleDiscountCode = () => {
    initDiscount({
      clinicId: clinicId,
      couponCode: discount,
    });
  }
  useEffect(() => {
    if (discountQueryData?.data?.getDiscount.success) {
      setDiscount("")
      setIsOpen(false)
    }
  }, [discountQueryData?.data, discountQueryData]);
  return (
    <Modal
      className={"discount-modal"}
      isOpen={isOpen}
      style={{
        content: {
          borderRadius: "30px",
          padding: "20px",
          height: "310px",
          transform: "translate(0%, 70%)"
        },
        overlay: {
          background: "rgba(255, 255, 255, 0.75)"
        }
      }}
    >
      <div className="discount-main-section">
        <div className="icon-section">
          <CloseIcon className="cursor-pointer" onClick={() => { setDiscount(""); setIsOpen(false) }} />
        </div>
        <h1>Add discount code</h1>
        <div className="discount-text-section">
          <p>Discount code</p>
          <input
            type="text"
            name="discount"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            placeholder="Discount code"
          />
        </div>
        <div className="btnWrapper">
          <StepButton
            className={"blue-button"}
            blue={true}
            // isLoading={discountLoading}
            isLoading={discountQueryData?.loading}
            label={"Save"}
            onClick={() => handleDiscountCode()}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DiscountModal;
