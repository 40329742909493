import React, { useEffect, useState } from 'react'
import "./client-password-popup.scss"
import TextInput from '../../common/TextInput'
import StepButton from '../../common/StepButton'
import { useDispatch, useSelector } from 'react-redux'
import { saveCheckPasswordData } from '../../redux/actions/client/ClientCheckPassword'
import { CheckPasswordAction } from '../../redux/actions/client/ClientDirectoryCheckPassword'

const ClientPasswordPopup = ({ redirectCallback, setIsPasswordOpen }) => {
    const [password, setPassword] = useState(null)
    const dispatch = useDispatch();
    const { checkPasswordQueryData, initCheckPasswordData } = CheckPasswordAction();

    const clientCheckPasswordData = useSelector((state) => state.clientDetailsReducer?.clientCheckPasswordData)


    useEffect(() => {
        if (clientCheckPasswordData) {
            redirectCallback();
            dispatch(saveCheckPasswordData(null))
        }

    }, [clientCheckPasswordData])

    const checkPassword = () => {
        initCheckPasswordData({
            password: password
        }, (responseData) => {
            console.log("ressss", responseData)
            if (responseData?.data?.passwordValid) {
                setIsPasswordOpen(false)
            }
            else {
                setIsPasswordOpen(true)
            }
        }, (err) => {

        })

    }
    return (
        <form
            className="client-password-popup d-flex flex-column mt-2"
        >
            <div>
                <TextInput autoFocus={false}
                    value={password}
                    name={"password"}
                    onChange={(e) =>
                        setPassword(e.target.value)
                    }
                    type="password"
                    placeHolder="Enter password" />
            </div>
            <StepButton className={"mt-3"} type={'submit'} label={"Confirm"} blue={true} disabled={password ? false : true}
                isLoading={checkPasswordQueryData?.loading}
                onClick={(e) => {
                    e.preventDefault()
                    checkPassword()
                }
                } />
        </form>
    )
}

export default ClientPasswordPopup