import React, { useEffect, useRef, useState } from "react";
import "./PrescriberDetails.scss";
import { validName, validPhone } from "../../../utils/Regex";
import Autocomplete from "react-google-autocomplete";
import { googleApiKey } from "../../../config";
import TextInput from "../../common/TextInput";
import CustomDropdown from "../../common/dropdown/CustomDropdown";
import StepButton from "../../common/StepButton";
import calander from "../../../images/calander-plus.svg";
import nextIcon from "../../../images/calander_next.svg";
import moment from "moment";
import CustomModal from "../../modal/CustomModal";
import CustomCalendar from "../../pages/dateTime/steps/CustomCalendar";
import { alphaNumericRegexAp } from "../../../utils/Regex";
import { GetPrescriberOccupationList } from "../../redux/actions/pharmacy-registration/occupationList";
import {
  GetPrescriberBusinessDetails,
  SavePrescriberBusinessDetails,
} from "../../redux/actions/pharmacy-registration/prescriberBusinessDetails";
import CustomAutoComplete from "../../auto-complete/CustomAutoComplete";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import FinanceHeader from "../../finance-registration/FinanceHomeComponent/FinanceHeaderComponent/FinanceHeaderComponent";
import FinanceBanner from "../../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";
import trackGoogleAPI from "../../../utils/googleApiTrack";
import { useSelector } from "react-redux";
import { debounce } from "lodash"; 


const PrescriberDetails = ({
  onNextStep,
  afterSubmit,
  finish,
  isRequestSentToAdmin,
}) => {
  const { initGetPrescriberOccupationList, data } =
    GetPrescriberOccupationList();
  const { initiateSavePrescriberBusinessDetails } =
    SavePrescriberBusinessDetails();
  const { initGetPrescriberBusinessDetails, data: businessData } =
    GetPrescriberBusinessDetails();
  const [fetchLoading, setFetchLoading] = useState(true);
  const navigate = useNavigate();
  const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)


  const expiryErrorRef = useRef(null);
  const businessTypeErrorRef = useRef(null);
  const occupationErrorRef = useRef(null);
  const businessNameErrorRef = useRef(null);
  const trainingAcademyNameErrorRef = useRef(null);
  const pinErrorRef = useRef(null);
  const locationErrorRef = useRef(null);
  const [shouldScroll, setshouldScroll] = useState(false);
  const [updateTrackType, setUpdateTrackType] = useState(null);

  const [optionValue, setOptionValue] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [active, setActive] = useState("No");
  const [trainingAcademyName, setTrainingAcademyName] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locationOpen, setLocationOpen] = useState(false);
  const [error, setError] = useState({});
  const [professionalPin, setProfessionalPin] = useState({
    pin: "",
    expiry: "",
  });
  const [value, setValue] = useState([]);
  const [pinStatus, setPinStatus] = useState(0);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  //Set Client Location state
  const [location, setLocation] = useState({
    county: "",
    address1: "",
    address2: "",
    city: "",
    postcode: "",
    country: "",
    street: "",
    house_no: "",
    latitude: 0.0,
    longitude: 0.0,
    state: "",
  });
  console.log(location, "location");
  //Business Details State
  const [businessDetails, setBusinessDetails] = useState({
    businessType: { id: 0, value: "" },
    occupation: "",
    businessName: "",
    vatNumber: "",
  });
  //calander
  const [showSelectedDate, setShowSelectedDate] = useState(null);
  const [calendarStartDate, setCalendarStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(
    selectedDate.getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
  const [formatedDate, setFormatedDate] = useState("");
  const [step, setStep] = useState("");
  const [showSelectedTime, setShowSelectedTime] = useState("");
  const [isOpenMobileCalendar, setIsOpenMobileCalendar] = useState(false);
  const handleSelectedDate = (selectedDate) => {
    setShowSelectedDate(selectedDate);
    setShowCalendar(false);
  };

  // Function to generate an array of dates for a specified range
  function generateDateData(startDate, endDate) {
    const dates = [];
    const currentDate = new Date(startDate);
    // Loop to generate dates until the endDate is reached
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().slice(0, 10));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }
  const today = new Date();
  const endDatee = new Date(today);
  endDatee.setFullYear(today.getFullYear() + 25);
  const dateDataDefault = generateDateData(today, endDatee);
  const [dateData, setDateData] = useState(dateDataDefault);
  useEffect(()=>{ window.scrollTo(0, 0)},[])
  useEffect(() => {
    if (shouldScroll) {
      if (expiryErrorRef.current) {
        expiryErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (businessTypeErrorRef.current) {
        businessTypeErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (occupationErrorRef.current) {
        occupationErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (locationErrorRef.current) {
        locationErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (businessNameErrorRef.current) {
        businessNameErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (trainingAcademyNameErrorRef.current) {
        trainingAcademyNameErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (pinErrorRef.current) {
        pinErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }

      setshouldScroll(false);
    }
  }, [validationErrors]);

  const handleTrackGoogleAPI = async (callType) => {
    try {
      const currentUrl = window.location.href;
      const userId = UserProfileData?.user?.usr_id || 0;
  
      // No need to manually parse JSON; Axios handles it
      const data = await trackGoogleAPI(userId, currentUrl, callType);
  
      console.log( "trackdata", data);
    } catch (error) {
      console.error('Error:', error.message || error);
    }
  };

  // // Debounced version of the handleTrackGoogleAPI function
  // const debouncedHandleTrackGoogleAPI = debounce((callType) => handleTrackGoogleAPI(callType), 300); // 300 ms delay to match Google Autocomplete

  // useEffect(() => {
  //   if (searchLocation != null) {
  //     // Call the debounced API function with the appropriate type
  //     if (updateTrackType !== null) {
  //       debouncedHandleTrackGoogleAPI(updateTrackType);
  //     }

  //     // Cleanup function to cancel any pending debounced calls
  //     return () => {
  //       debouncedHandleTrackGoogleAPI.cancel();
  //     };
  //   }
  // }, [searchLocation, updateTrackType]);


  useEffect(() => {
    // useEffect(()=>{ setShowCalendar(false)},[selectedDate])
    // setShowCalendar(false);

    setFormatedDate(
      moment(showSelectedDate, "dddd, Do MMMM YYYY").format("D MMMM YYYY")
    );
    setProfessionalPin((prev) => ({
      ...prev,
      expiry: moment(showSelectedDate, "dddd, Do MMMM YYYY").format(
        "YYYY-MM-DD"
      ),
    }));
    setValidationErrors((prev) => ({
      ...prev,
      expiry: "",
    }));
  }, [showSelectedDate]);

  const businessTypeArray = ["Sole trader", "Limited Company"];
  const customStyle = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Adjust the z-index value according to your requirement
    }),
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "#FFFFFF",
      outline: "none",
      appearance: "none",
      padding: "10px 10px",
      borderRadius: "10px",
      border: "1px solid #878787",
      height: "55px",
      width: "100%",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
      };
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocation({ ...location, [name]: value });
    console.log("namvalue", name, value, location);
  };
  //Address
  const ValidationFunction = (key, value) => {
    switch (key) {
      case "house_no":
        if (!value) {
          return "House No is required";
        }
        break;
      case "street":
        if (!value) {
          return "Street Name is required";
        }
        break;
      case "county":
        if (!value) {
          return "County is required";
        }
        break;
      case "city":
        if (!value) {
          return "City is required";
        }
        break;
      case "postcode":
        if (!value) {
          return "Post code is required";
        }
        break;
      default:
        return "";
    }
    return ""; // Return empty string if no error
  };
  const onAddressSubmit = (event) => {
    // event.preventDefault();
    let errorData = {};

    Object.keys(location).forEach((key) => {
      const error = ValidationFunction(key, location[key]);
      if (error) {
        errorData[key] = error;
      }
    });

    if (Object.keys(errorData)?.length) {
      setError(errorData);
    } else {
      setError({});
      setLocationOpen(false);
      console.log(
        "Location here",
        location?.house_no,
        location?.address1,
        location?.address2,
        location?.city,
        location?.state,
        location?.country,
        location?.postcode
      );
      // Construct the searchLocation using the fetched address components
      let constructedSearchLocation = "";
      if (location?.house_no) {
        constructedSearchLocation += location.house_no + ", ";
      }
      if (location?.street) {
        constructedSearchLocation += location.street + ", ";
      }
      if (location?.county) {
        constructedSearchLocation += location.county + ", ";
      }
      if (location?.city) {
        constructedSearchLocation += location.city + ", ";
      }
      if (location?.postcode) {
        constructedSearchLocation += location.postcode;
      }
      // Remove trailing comma if present
      if (constructedSearchLocation.endsWith(", ")) {
        constructedSearchLocation = constructedSearchLocation.slice(0, -2);
      }
      // Set the searchLocation
      setSearchLocation(constructedSearchLocation);
      // setLocation({ address1: "", address2: "", city: "", postcode: "" });

      setValidationErrors((prevData) => ({
        ...prevData,
        address: "",
      }));
    }
  };
  console.log(searchLocation, "location search");
  //Handle businessType Change
  const handleBusinessTypeChange = (value) => {
    const valueMap = {
      "Sole trader": 2,
      "Limited Company": 1,
    };
    if (value in valueMap) {
      const intValue = valueMap[value];
      setBusinessDetails((prevData) => ({
        ...prevData,
        businessType: { id: intValue, value: value },
      }));
      setValidationErrors((prevData) => ({
        ...prevData,
        businessType: "",
      }));
    }
  };
  const onHandleChange = (e) => {
    setActive(e.target.value);
  };
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  useEffect(() => {
    initGetPrescriberOccupationList();
    initGetPrescriberBusinessDetails();
  }, []);
  console.log(businessDetails.businessType, "type");
  // useEffect to process the occupation list with categories data
  useEffect(() => {
    if (data && data?.getPrescriberOccupationList) {
      const optionList = data?.getPrescriberOccupationList?.data
        ?.map((category) =>
          category.occupations?.map((occupation) => ({
            ...occupation,
            category: category.category,
            title: occupation.title,
          }))
        )
        .flat();
      setOptionValue(optionList);
    }
  }, [data]);

  //useEffect to fetch data
  useEffect(() => {
    if (businessData && businessData.getPrescriberBusinessDetails.data) {
      const fetchedData = businessData.getPrescriberBusinessDetails.data;

      let businessTypeValue = "";
      if (fetchedData?.business_type === 2) {
        businessTypeValue = "Sole trader";
      } else if (fetchedData?.business_type === 1) {
        businessTypeValue = "Limited Company";
      }

      setBusinessDetails({
        ...businessDetails,
        businessType: {
          id: fetchedData?.business_type,
          value: businessTypeValue,
        },
        businessName: fetchedData?.business_name,
        vatNumber: fetchedData?.vat_number,
      });

      const selectedOccupations = optionValue?.filter((option) =>
        fetchedData?.occupations.includes(option.id)
      );
      setValue(selectedOccupations);
      const newPinStatus = selectedOccupations.reduce((maxPin, option) => {
        return Math.max(maxPin, option.pin_status);
      }, 0);
      setPinStatus(newPinStatus);

      setLocation({
        address1: fetchedData?.address,
        house_no: fetchedData?.house_no,
        street: fetchedData?.street_name,
        address2: fetchedData?.address2,
        city: fetchedData?.city,
        state: fetchedData?.state,
        country: fetchedData?.county,
        county: fetchedData?.county,
        postcode: fetchedData?.zipcode,
        latitude: parseFloat(fetchedData?.latitude),
        longitude: parseFloat(fetchedData?.longitude),
      });
      // Construct the searchLocation using the fetched address components
      let constructedSearchLocation = "";
      if (fetchedData?.house_no) {
        constructedSearchLocation += fetchedData.house_no + ", ";
      }
      if (fetchedData?.street_name) {
        constructedSearchLocation += fetchedData.street_name + ", ";
      }
      if (fetchedData?.county) {
        constructedSearchLocation += fetchedData.county + ", ";
      }
      if (fetchedData?.city) {
        constructedSearchLocation += fetchedData.city + ", ";
      }
      if (fetchedData?.zipcode) {
        constructedSearchLocation += fetchedData.zipcode;
      }
      // Remove trailing comma if present
      if (constructedSearchLocation.endsWith(", ")) {
        constructedSearchLocation = constructedSearchLocation.slice(0, -2);
      }
      // Set the searchLocation
      setSearchLocation(constructedSearchLocation);

      if (fetchedData?.is_training_academy) {
        setActive("Yes");
      } else {
        setActive("No");
      }
      setTrainingAcademyName(fetchedData?.training_academy_name);

      // Check if pin_expiry is a valid date
      const currentYear = moment().year();
      const maxValidYear = moment().year() + 25; // Limiting to the next 25 years
      const pinExpiryDate = moment(fetchedData?.pin_expiry, "YYYY-MM-DD", true);

      // if (pinExpiryDate.isValid() && pinExpiryDate.year() <= maxValidYear) {
      //   setFormatedDate(pinExpiryDate.format("D MMMM YYYY"));
      //   setShowSelectedDate(pinExpiryDate.format("dddd, Do MMMM"));
      // }
      if (
        pinExpiryDate.isValid() &&
        // pinExpiryDate.year() >= currentYear &&
        pinExpiryDate.year() <= maxValidYear
      ) {
        // setFormatedDate(pinExpiryDate.format("D MMMM YYYY"));
        // setShowSelectedDate(pinExpiryDate.format("dddd, Do MMMM"));
        setShowSelectedDate(pinExpiryDate.format("dddd, Do MMMM YYYY"));
        setSelectedMonth(pinExpiryDate.month() + 1);
        setSelectedYear(pinExpiryDate.year());
        if (moment(fetchedData?.pin_expiry).isBefore(moment())) {
          const updatedDateData = generateDateData(
            fetchedData?.pin_expiry,
            endDatee
          );
          setDateData(updatedDateData);
        }
      }

      if (pinExpiryDate.isValid() && pinExpiryDate.year() <= maxValidYear) {
        setFormatedDate(pinExpiryDate.format("D MMMM YYYY"));
      }

      setProfessionalPin({
        pin: fetchedData?.professional_pin,
        expiry:
          pinExpiryDate.isValid() && pinExpiryDate.year() <= maxValidYear
            ? fetchedData?.pin_expiry
            : "",
      });

      setFetchLoading(false);
    }
  }, [businessData, optionValue]);

  const handleOptionChange = (event, newValue) => {
    const newPinStatus = newValue.reduce((maxPin, option) => {
      return Math.max(maxPin, option.pin_status);
    }, 0);
    setValue(newValue);
    setPinStatus(newPinStatus);
    setValidationErrors((prevData) => ({
      ...prevData,
      occupation: "",
    }));
  };

  const handleContinue = () => {
    const validationErrors = {};

    if (!businessDetails.businessType.id) {
      validationErrors.businessType = "Business type is required";
    }
    if (!businessDetails.businessName) {
      validationErrors.businessName = "Business name is required";
    }
    if (!value?.length > 0) {
      validationErrors.occupation = "Occupation is required";
    }
    if (
      !location.address1 &&
      !location.address2 &&
      !location.city &&
      !location.postcode &&
      !location.county &&
      !location.street &&
      !location.house_no &&
      !location.state
    ) {
      validationErrors.address = "Address is required";
    }
    if (active === "Yes" && !trainingAcademyName) {
      validationErrors.trainingAcademyName = "Academy name is required";
    }
    if (pinStatus === 2 && !professionalPin.pin) {
      validationErrors.pin = "Professional pin is required";
    }
    if (pinStatus === 2 && !professionalPin.expiry) {
      validationErrors.expiry = "Pin expiry date is required";
    }

    if (
      (pinStatus === 2 || pinStatus === 1) &&
      moment(professionalPin.expiry, "YYYY-MM-DD").isBefore(moment(), "day")
    ) {
      validationErrors.expiry = "Please enter a valid future date.";
    }

    setValidationErrors(validationErrors);
    setshouldScroll(true)
    if (Object.values(validationErrors).some((error) => error)) {
      if (expiryErrorRef.current) {
        expiryErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (businessTypeErrorRef.current) {
        businessTypeErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (occupationErrorRef.current) {
        occupationErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (locationErrorRef.current) {
        locationErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (businessNameErrorRef.current) {
        businessNameErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (trainingAcademyNameErrorRef.current) {
        trainingAcademyNameErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (pinErrorRef.current) {
        pinErrorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      return;
    } else {
      setLoading(true);
      initiateSavePrescriberBusinessDetails(
        {
          businessType: businessDetails.businessType.id,
          occupations:
            value?.length > 0 &&
            value?.map((occupation) => occupation.id).join(","),
          address: location.address1,
          houseNo: location.house_no,
          streetName: location.street,
          address2: location.address2,
          city: location.city,
          state: location.state,
          county: location.county,
          zipcode: location.postcode,
          latitude: location.latitude.toString(),
          longitude: location.longitude.toString(),
          businessName: businessDetails.businessName,
          vatNumber: businessDetails.vatNumber,
          isTrainingAcademy: active === "Yes" ? true : false,
          trainingAcademyName: trainingAcademyName,
          professionalPin: professionalPin.pin,
          pinExpiry: professionalPin.expiry,
        },
        (res) => {
          setLoading(false);
          toast.success(res.message);
          // navigate(getDocumnetPageRoutePattern);
          afterSubmit();
          onNextStep();
        },
        (error) => {
          setLoading(false);
          toast.error(error.message);
        }
      );
    }
  };
  {
    console.log(
      dateData,
      "dateData",
      showSelectedDate,
      formatedDate,
      professionalPin.expiry,
      selectedMonth,
      selectedYear
    );
  }
  return (
    <div className="prescriber-details-container">
      <FinanceBanner
        title={"Business details"}
        desc={"Tell us about your business."}
        isBorder={!isRequestSentToAdmin}
        currentStep={finish}
        totalSteps={4}
      />
      <div
        className={`prescriber-details-child-container ${
          !isRequestSentToAdmin ? "" : ""
        }`}
      >
        <div className="first-flex-div">
          {fetchLoading ? (
            <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
          ) : (
            <div className="finance-input-container customeBusinessType">
              {/* TYPE */}
              <CustomDropdown
                labelName={"Business type"}
                defaultValue={businessDetails?.businessType?.value}
                dropdownId={"BusinessType"}
                // placeholdername={"Business Type"}
                optionsArr={businessTypeArray}
                style={customStyle}
                handleSelectedValue={handleBusinessTypeChange}
                readOnly={false}
              />
              {validationErrors.businessType && (
                <span className="error" ref={businessTypeErrorRef}>
                  {validationErrors.businessType}
                </span>
              )}
            </div>
          )}
          {/* OCCUPATION */}
          {fetchLoading ? (
            <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
          ) : (
            <div className="finance-input-container">
              <label htmlFor="occupation" className="form-label">
                Occupation
              </label>
              {optionValue?.length != 0 && (
                <CustomAutoComplete
                  className="custom-occupation"
                  style={{
                    borderRadius: "10px ",
                    border: "1px solid #878787",
                    background: "#fff",
                  }}
                  multiple={true}
                  groupBy={true}
                  placeholder={value?.length ? "" : "Select an occupation"}
                  optionValue={optionValue}
                  value={value}
                  setValue={setValue}
                  handleOptionChange={handleOptionChange}
                />
              )}
              {validationErrors.occupation && (
                <span className="error" ref={occupationErrorRef}>
                  {validationErrors.occupation}
                </span>
              )}
            </div>
          )}

          {/* ADDRESS */}
          {fetchLoading ? (
            <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
          ) : (
            <div className="finance-input-container">
              <div className="Search-Address-Wrapper">
                <p className="form-label">Search address</p>
                <Autocomplete
                  icon={false}
                  apiKey={googleApiKey}
                  defaultValue={searchLocation}
                  onPlaceSelected={(place) => {
                    setSearchLocation(place.formatted_address);
                    const location = place.address_components.reduce(
                      (seed, { long_name, types }) => (
                        types.forEach((t) => (seed[t] = long_name)), seed
                      ),
                      {}
                    );
                    setLocation({
                      ...location,
                      house_no: place.address_components[0].long_name,
                      street: location.route,
                      address1: place.address_components[0].long_name,
                      address2: place.address_components[1].long_name,
                      postcode:
                        location.postal_code === undefined
                          ? ""
                          : location.postal_code,
                      city:
                        location.locality === undefined
                          ? location.postal_town === undefined
                            ? ""
                            : location.postal_town
                          : location.locality,
                      country: location.country,
                      state:
                        location.administrative_area_level_1 === undefined
                          ? location.administrative_area_level_2
                          : location.administrative_area_level_1,

                      county: location.administrative_area_level_2
                        ? location.administrative_area_level_2
                        : location.administrative_area_level_1,

                      latitude: place.geometry.location.lat(),
                      longitude: place.geometry.location.lng(),
                    });
                    console.log(place, "i am getplace data");
                    handleTrackGoogleAPI(2);
                    // setUpdateTrackType(2)
                  }}
                  placeholder="Search with postcode or address"
                  className={"form-control"}
                  value={searchLocation}
                  onChange={(e) => {
                    // Call the debounced tracking API with callType 1 on input change
                    // setUpdateTrackType(1)
                    handleTrackGoogleAPI(1);
                    const inputValue = e.target.value;
                    setSearchLocation(inputValue);
                    setValidationErrors((prevData) => ({
                      ...prevData,
                      address: "",
                    }));
                    if (inputValue === '') {
                      setLocation({});
                    }
                  }}
                  options={{
                    types: ["establishment"],
                    componentRestrictions: { country: "uk" },
                  }}
                />
              </div>
              <div
                className={
                  validationErrors.address ? "Search-Address-error-wrapper" : ""
                }
              >
                {validationErrors.address && (
                  <span className="error" ref={locationErrorRef}>
                    {validationErrors.address}
                  </span>
                )}
                <div className="add-manually">
                  <p
                    role="button"
                    onClick={() => {
                      setLocationOpen(true);
                    }}
                  >
                    Add manually
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* BUSINESS/ BRAND NAME */}
          {fetchLoading ? (
            <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
          ) : (
            <div className="finance-input-container">
              <TextInput
                label={"Business / brand name"}
                placeholder={"Business / brand name"}
                value={businessDetails?.businessName}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setBusinessDetails((prevData) => ({
                    ...prevData,
                    businessName: e.target.value,
                  }));
                  if (inputValue?.length >= 3 && validName.test(inputValue)) {
                    setValidationErrors((prevData) => ({
                      ...prevData,
                      businessName: "",
                    }));
                  } else {
                    setValidationErrors((prevData) => ({
                      ...prevData,
                      businessName: "Please enter valid business name.",
                    }));
                  }
                }}
              />
              {validationErrors.businessName && (
                <span className="error" ref={businessNameErrorRef}>
                  {validationErrors.businessName}
                </span>
              )}
            </div>
          )}

          {businessDetails?.businessType?.value === "Limited Company" && (
            <>
              {fetchLoading ? (
                <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
              ) : (
                <div className="finance-input-container">
                  {/* VAT NUMBER*/}
                  <TextInput
                    label={"VAT Number (optional)"}
                    placeholder={"VAT Number"}
                    value={businessDetails?.vatNumber}
                    onChange={(e) => {
                      setBusinessDetails((prevData) => ({
                        ...prevData,
                        vatNumber: e.target.value,
                      }));
                      setValidationErrors((prevData) => ({
                        ...prevData,
                        vatNumber: "",
                      }));
                    }}
                  />
                </div>
              )}
            </>
          )}
          {fetchLoading ? (
            <SkeletonLoader type="PharmacyBusinessInputSkeleton" />
          ) : (
            <div className="finance-input-container">
              {/* TRAINING ACADEMY*/}
              <label class="form-label">Are you a training academy? </label>
              <div className="btn-radio">
                <div
                  className={
                    active === "Yes"
                      ? "active-radio-button form-check"
                      : "form-check"
                  }
                  onClick={() => setActive("Yes")}
                >
                  <label class="form-check-label" for="flexRadioDefault1">
                    Yes
                  </label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name="active"
                    id="flexRadioDefault1"
                    value="Yes"
                    checked={active === "Yes" ? true : false}
                    onChange={onHandleChange}
                  />
                </div>
                <div
                  className={
                    active === "No"
                      ? "active-radio-button form-check"
                      : "form-check"
                  }
                  onClick={() => setActive("No")}
                >
                  <label class="form-check-label" for="flexRadioDefault1">
                    No
                  </label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name="active"
                    id="flexRadioDefault1"
                    checked={active === "No" ? true : false}
                    value="No"
                    onChange={onHandleChange}
                  />
                </div>
              </div>
            </div>
          )}

          {active === "Yes" && (
            <div className="finance-input-container">
              {/* TRAINING ACADEMY NAME*/}
              <TextInput
                label={
                  "Training academy name  *You might be eligible for our wholesale and re-seller account."
                }
                placeholder={"Training academy name "}
                value={trainingAcademyName}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setTrainingAcademyName(e.target.value);
                  if (inputValue?.length >= 3 && validName.test(inputValue)) {
                    setValidationErrors((prevData) => ({
                      ...prevData,
                      trainingAcademyName: "",
                    }));
                  } else {
                    setValidationErrors((prevData) => ({
                      ...prevData,
                      trainingAcademyName:
                        "Please enter valid training academy name.",
                    }));
                  }
                }}
              />
              {validationErrors.trainingAcademyName && (
                <span className="error" ref={trainingAcademyNameErrorRef}>
                  {validationErrors.trainingAcademyName}
                </span>
              )}
            </div>
          )}
          {(pinStatus === 2 || pinStatus === 1) && (
            <>
              <div className="finance-input-container">
                {/* PIN */}
                <TextInput
                  label={"Professional pin and expiry date"}
                  placeholder={"Professional Pin"}
                  value={professionalPin?.pin}
                  onChange={(e) => {
                    setProfessionalPin((prevData) => ({
                      ...prevData,
                      pin: e.target.value,
                    }));
                    setValidationErrors((prevData) => ({
                      ...prevData,
                      pin: "",
                    }));
                  }}
                />
                {validationErrors.pin && (
                  <span className="error" ref={pinErrorRef}>
                    {validationErrors.pin}
                  </span>
                )}
              </div>
              <div>
                <div
                  className="calenderWrapper"
                  onClick={() => {
                    toggleCalendar();
                  }}
                >
                  <div className="calenderIcon">
                    <img src={calander} alt="calander" />
                    <p>
                      {professionalPin.expiry !== ""
                        ? formatedDate
                        : "Expiry Date"}
                    </p>
                  </div>
                  <div className="calenderRightIcon">
                    <img src={nextIcon} alt="next" />
                  </div>
                </div>
                {validationErrors.expiry && (
                  <span className="error" ref={expiryErrorRef}>
                    {validationErrors.expiry}
                  </span>
                )}
              </div>
            </>
          )}
          {fetchLoading ? (
            <SkeletonLoader type="PharmacyBusinessStepButtonSkeleton" />
          ) : (
            <div className="pres-input-container py-4">
              <StepButton
                blue={true}
                label={"Continue"}
                onClick={() => handleContinue()}
                isLoading={loading}
              />
            </div>
          )}
        </div>
      </div>

      <CustomModal
        modalOpen={locationOpen}
        setModalOpen={setLocationOpen}
        className={"customeAddressModel"}
        modalBody={
          <div className="modal-body-sttucture">
            <div className="modal-body-header">Add address </div>
            <div className="modal-body-sub-header">Address</div>

            <div className="container-body">
              <div>
                <TextInput
                  id="house_no"
                  label="House name or number"
                  placeholder="House name or number"
                  name="house_no"
                  value={location?.house_no}
                  onChange={(e) => handleChange(e)}
                />
                {error?.house_no ? (
                  <span className="error">{error?.house_no}</span>
                ) : (
                  ""
                )}
              </div>
              <div>
                <TextInput
                  id="street"
                  label="Street name"
                  placeholder="Street name"
                  name="street"
                  value={location?.street}
                  onChange={(e) => handleChange(e)}
                />
                {error?.street ? (
                  <span className="error">{error?.street}</span>
                ) : (
                  ""
                )}
              </div>
              <div>
                <TextInput
                  id="county"
                  label="County"
                  placeholder="County"
                  name="county"
                  value={location?.county}
                  onChange={(e) => handleChange(e)}
                />
                {error?.county ? (
                  <span className="error">{error?.county}</span>
                ) : (
                  ""
                )}
              </div>
              <div>
                <TextInput
                  id="city"
                  label="City"
                  placeholder="City"
                  name="city"
                  value={location?.city}
                  onChange={(e) => handleChange(e)}
                />
                {error?.city ? (
                  <span className="error">{error?.city}</span>
                ) : (
                  ""
                )}
              </div>
              <div>
                <TextInput
                  id="postcode"
                  label="postcode"
                  placeholder="postcode"
                  name="postcode"
                  pattern={alphaNumericRegexAp.test(location?.postcode)}
                  value={location?.postcode}
                  onChange={(e) => handleChange(e)}
                />
                {error?.postcode ? (
                  <span className="error">{error?.postcode}</span>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="btn-container">
              <StepButton
                blue={true}
                label={"Continue"}
                onClick={() => onAddressSubmit()}
              />
            </div>
          </div>
        }
      />
      {console.log(dateData, "dateData", showSelectedDate, formatedDate)}
      <CustomModal
        modalOpen={showCalendar}
        setModalOpen={setShowCalendar}
        modalBody={
          <CustomCalendar
            setCalendarStartDate={setCalendarStartDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            dateData={dateData}
            showSelectedDate={showSelectedDate}
            setShowSelectedDate={setShowSelectedDate}
            onSelectDate={handleSelectedDate}
            setFormatedDate={setFormatedDate}
            setStep={setStep}
            setShowSelectedTime={setShowSelectedTime}
            isOpenMobileCalendar={isOpenMobileCalendar}
            setIsOpenMobileCalendar={setIsOpenMobileCalendar}
            setIsOpenCalendar={setShowCalendar}
            allowMonthYearChange={true}
          />
        }
        modalTitle={"calander"}
      />
    </div>
  );
};

export default PrescriberDetails;
