import React, { useEffect, useState, } from 'react'
import logoHeader from "../../../images/icons/faces-logo-black.svg";
import bellIcon from "../../../images/icons/bell.svg"
import "./business-setting-header.scss"
import Avatar from 'react-avatar';
import { useNavigate } from "react-router"
import { verifiedBannerRoutePattern } from '../../../Routes';
import { webBaseUrl } from '../../../config';
import { useSelector } from "react-redux";
import { ReactComponent as DownArrow } from "../../../images/icons/cav-down-gray.svg"
import { ReactComponent as StarIcon } from "../../../images/icons/star_rating.svg"
import { ReactComponent as SettingIcon } from "../../../images/headers/setting-icon.svg"
import { ReactComponent as SupportIcon } from "../../../images/headers/support-icon.svg"
import { GetUserInsuranceVerifiedStatusAction } from '../../redux/actions/InsuranceVerifiedStatus/InsuranceVerifiedStatus';
import { ReactComponent as TraingIcon } from "../../../images/headers/training-icon.svg"
import { ReactComponent as FacesVerifiedIcon } from "../../../images/headers/faces-verified.svg"
import { ReactComponent as CloseIcon } from "../../../images/headers/close-icon.svg"
import { ReactComponent as AddMoreIcon } from "../../../images/plusIcon.svg"
import { ReactComponent as LogoutIcon } from "../../../images/headers/logout-icon.svg"
import { logoutProfile } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import { GetAccountDetailsAction } from '../../redux/actions/account-details/accountdetails';
import { ReactComponent as BackArrow } from "../../../images/back_arrow.svg";
import { ReactComponent as NavArrorw } from "../../../images/business-setting-icon/nav-icon.svg"
import { ReactComponent as NewAppointmentIcon } from "../../../images/business-setting-icon/appointment_icon.svg"
import { ReactComponent as WalkinConsentIcon } from "../../../images/business-setting-icon/walk-in-consent.svg"
import { ReactComponent as Blocktime } from "../../../images/sprint6/BlockOutTime.svg"
import { ReactComponent as ShareLinkIcon } from "../../../images/business-setting-icon/ShareForward.svg"
import { ReactComponent as PrescriptioIcon } from "../../../images/business-setting-icon/prepration.svg"
import { ReactComponent as ShopLocationIcon } from "../../../images/business-setting-icon/location.svg"
import { ReactComponent as PharmaciesIcon } from "../../../images/business-setting-icon/Pharmacy.svg"
import { ReactComponent as ConsentIcon } from "../../../images/business-setting-icon/consent_icon.svg"
import { ReactComponent as Payl8rIcon } from "../../../images/business-setting-icon/payl8rIcon.svg"
import { getNewAppointmentRoute, getShareBookingLinkRoute, getBlockOutTimeRoute } from "../../../Routes";
import { ReactComponent as VerifiedSvg } from "../../../assets/images/verified/Verified.svg"
import { useHideBecomeFacesVerifiedBanner } from '../../redux/actions/InsuranceVerifiedStatus/HideFacesVerifiedBanner'; import { GetUserInsuranceVerifiedAction } from "../../redux/actions/faces-verified/faces-verified"
import { GetUserInsuranceDetailAction } from "../../redux/actions/GetUserInsuranceDetails/GetUserInsuranceDetail"
import { HideBecomeFacesVerifiedBannerAction } from "../../redux/actions/hide-become-faces-verified-banner/hideBecomeFacesVerifiedBanner"
import StepButton from '../StepButton';
import CustomModal from '../../modal/CustomModal';
import { getBusinessSettingPageRoute, getSettingPageRoute } from '../../../Routes';
import CustomButton from '../../custom-components/custom-button';
import { GetProfileAction } from "../../redux/actions/dashboard/getProfileDataGql"
import verifyIcon from "../../../images/icons/verified.svg"

const BusinessSettingHeader = ({ setCurrentPage, currentPage, openMobileMenu, isMobile, }) => {
    const {
        getUserInsuranceVerifiedData,
        GetUserInsuranceVerifiedStatus,
    } = GetUserInsuranceVerifiedStatusAction();
    const { initiateHideBecomeFacesVerifiedBanner, hideBecomeFacesVerifiedBannerData } = useHideBecomeFacesVerifiedBanner()
    //==================== DECLARATION =================
    const navigate = useNavigate()
    const [toggleMenu, setToggleMenu] = useState(false)
    const [isShowTrusted, setIsShowTrusted] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { getAccountDetailsQueryData, initGetAccountDetailsData } = GetAccountDetailsAction();
    const [userDetails, setuserDetails] = useState();
    const [profilePicPreview, setProfilePicPreview] = useState(null);
    const [userfullName, setuserfullName] = useState()

    const [bannerData, setBannerData] = useState({})
    const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)
    console.log('UserProfileData', UserProfileData)

    // const userInsuranceVerification = () => {
    //     GetUserInsuranceVerifiedStatus({}, (res) => {
    //         setBannerData(res?.data)
    //     },
    //         (err) => { console.log(err, "errrr") })
    // }

    const [booleanValue, setbooleanValue] = useState(false);
    const [bussinessName, setbussinessName] = useState()
    const [isHideFacesVerified, setIsHideFacesVerified] = useState(true)
    const [isBannerShow, setIsBannerShow] = useState(false)
    const [isVerified, setIsverified] = useState(false)
    const [showGetFacesText, setShowGetFacesText] = useState(false);
    const [showFacesBanner, setShowFacesBanner] = useState(false);

    const { getUserInsuranceDetailsQueryData, initGetUserInsuranceDetailData } = GetUserInsuranceDetailAction();
    const { getInsuranceVerifiedQueryData, initGetInsuranceverifiedData } = GetUserInsuranceVerifiedAction();
    const { hideBecomeFacesVerifiedBannerQuerydata, initHideBecomeFacesVerifiedBannerData } = HideBecomeFacesVerifiedBannerAction();
    const { getProfileQueryData, initGetProfileData } = GetProfileAction();

    const { profileData } = useSelector((state) => state?.GetProfileDataReducer);
    const bannerShow = getInsuranceVerifiedQueryData?.data?.getUserInsuranceVerifiedStatus?.data;

    const [trainingAcademyUrl, setTrainingAcademyUrl] = useState();
  
    let roles = profileData ? profileData.roles : [];
    const TRAINING_PROVIDER_ROLE = 2;
    const isTrainingProvider = () => {
        return roles.includes(TRAINING_PROVIDER_ROLE);
    };

    useEffect(() => {
        let trainingPortalLink = isTrainingProvider() ? webBaseUrl + "/training-dashboard/dashboard" : webBaseUrl + "/training-dashboard/student-portal-dashboard";

        setTrainingAcademyUrl(trainingPortalLink);
    }, [roles]);

    useEffect(() => {
        initGetUserInsuranceDetailData();
    }, [])

    useEffect(() => {
        initGetInsuranceverifiedData()
    }, [])

    useEffect(() => {
        initGetProfileData()
    }, [])

    useEffect(() => {
        if (profileData) {
            setIsverified(profileData?.is_verified)
        }
    }, [profileData])

    useEffect(() => {
        if (profileData) {
            setIsHideFacesVerified(profileData?.is_hide_banner)
        }
    }, [profileData])

    useEffect(() => {
        if (bannerShow) {
            setIsBannerShow(bannerShow?.is_banner_show)
        }
    }, [bannerShow])


    console.log(getUserInsuranceDetailsQueryData?.data, "888888888", getInsuranceVerifiedQueryData?.data, "66666666", profileData)
    const handleCloseClick = (e) => {
        e.stopPropagation();

        initHideBecomeFacesVerifiedBannerData({
            isHideBanner: 1
        }, (data) => {
            setIsHideFacesVerified(true)
            setIsShowTrusted(false);
            // setShowGetFacesText(true)
            // setToggleMenu(true)
        }, (error) => {
            console.log(error);
        })
    };

    const handleGetFacesVerifiedtag = (e) => {
        e.stopPropagation()
        setShowFacesBanner(true);
        // setCurrentPage("verified");
        // setToggleMenu(true)
    }

    const navigateToFacesVerifiedPage = () => {
        navigate(verifiedBannerRoutePattern)
    }

    console.log("isHideFacesVerified", isHideFacesVerified)

    const dispatch = useDispatch();
    const userProfileDetails = useSelector(state => state?.GetUserProfileDataReducer?.UserProfileData?.data)
    console.log(userProfileDetails, "userProfileDetailsapp.js")
    const handleprofileclick = () => { setbooleanValue(!booleanValue) }
    console.log(booleanValue, "booleanValue")


    console.log(userProfileDetails, "1100")


    useEffect(() => {

        if (userDetails) {
            setuserfullName(`${userDetails?.firstName} ${userDetails?.lastName}`)
            setbussinessName(userDetails?.businessName)
        }

    }, [userDetails, booleanValue])
    const tileData = [
        {
            title: "Create appointment",
            subTitle: "Forward consent form & manage bookings.",
            icon: <NewAppointmentIcon />
        },
        {
            title: "Walk-in",
            subTitle: "Gather consent from walk-ins.",
            icon: <WalkinConsentIcon />
        },
        {
            title: "Booking link",
            subTitle: "Share calendar with clients for bookings.",
            icon: <ShareLinkIcon />
        },
        {
            title: "Block out time",
            subTitle: "Easily set aside personal time.",
            icon: <Blocktime />
        },
        {
            title: "Prescription",
            subTitle: "Generate client prescriptions.",
            icon: <PrescriptioIcon />
        },
    ]

    // useEffect(() => { userInsuranceVerification() }, [])

    const redirectToNewBookingLink = ({ title }) => {
        // setIsModalOpen(false);
        if (title === "Create appointment") {
            // navigation(getNewAppointmentRoute())
            navigate(getNewAppointmentRoute(), { state: { from: 'new_appointment_home' } });
        }
        else if (title === "Booking link") {
            // navigate(getShareBookingLinkRoute())
            navigate(getShareBookingLinkRoute(), { state: { from: 'booking_link_from_home' } });
        }
        else if (title === "Block out time") {
            navigate(getBlockOutTimeRoute(), { state: { from: 'Block_out_time_home' } });
            // navigation(getBlockOutTimeRoute())
        }
        else if (title === "Walk-in") {
            console.log("inside walk in")
            window.location.href = webBaseUrl + "/filled-consent-form/create"
        }
        else if (title === "Prescription") {
            window.location.href = webBaseUrl + "/prescription/request-prescription"
        }
        console.log(title, "collective clicked datda")
    }


    console.log(bannerData, "bannerData")
    return (
        <>
            <div className="custom-business-setting-Header">
                <div className="container-fluid header-padding">
                    <div className="headerlogoBtnWrapper">
                        <div className="logo-setting-Wrapper">
                            <img src={logoHeader} alt="" srcset="" />

                            {/* {isBackIcon &&
                                <div className='BackButton'>
                                    <BackArrow />
                                </div>
                            } */}
                        </div>
                        <div className="business-setting-right-section d-flex align-items-center">
                            <button onClick={() => setIsModalOpen(true)}>New booking</button>
                            <div className='setting-notifiction'>
                                <img src={bellIcon} alt='' />
                            </div>

                            <div class="dropdown" onClick={handleprofileclick}>
                                <div className='setting-business-user dropdown-toggle' id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <Avatar
                                        src={UserProfileData?.image_url}
                                        style={{ cursor: "pointer" }}
                                        name={
                                            "Business"
                                        }
                                        round
                                        size="48"
                                        color="#EEEFF3"
                                        fgColor="#000"
                                        initials={1}
                                        textSizeRatio={2}
                                    />
                                    <p className="company-name my-auto">
                                        {UserProfileData?.user?.usr_company_name}
                                    </p>
                                    <DownArrow />
                                </div>
                                {/* OPTION TO SHOW */}
                                <div aria-labelledby="dropdownMenuButton2" className={isShowTrusted ? "option-setting-menu-container dropdown-menu" : "option-setting-menu-container dropdown-menu"}>
                                    <div className='BusinessDropdownWrapper'>
                                        <div className='business-setting-header-content'>
                                            <div className='avtar-rating-container'>
                                                <Avatar
                                                    src={UserProfileData?.image_url}
                                                    style={{ cursor: "pointer" }}
                                                    name={
                                                        "Business"
                                                    }
                                                    round
                                                    size="48"
                                                    color="#EEEFF3"
                                                    fgColor="#000"
                                                    initials={1}
                                                    textSizeRatio={2}
                                                />
                                                <div className='rating-setting'>
                                                    <StarIcon />
                                                    <p>4.7</p>
                                                </div>
                                            </div>

                                            <h1>{UserProfileData?.user?.usr_company_name}{UserProfileData?.is_verified && <span className="verifiedSvg_wrapper"><  VerifiedSvg /></span>}
                                                {/* {
                                                isVerified && (
                                                    <img src={verifyIcon} alt='verifyIcon' className='verifyIcon' />
                                                )
                                            } */}
                                            </h1>
                                            <p></p>
                                        </div>
                                        {/* SETTING CONTENT MENU */}
                                        <div className='setting-menu-container-content'>
                                            <div className='business-setting-menu-item' onClick={() => { navigate(getBusinessSettingPageRoute); setToggleMenu(false) }}>
                                                <SettingIcon />
                                                <p>Settings</p>
                                            </div>
                                            {/* <div className='business-setting-menu-item' onClick={() => { setCurrentPage("support"); setToggleMenu(false) }}>
                                                <SupportIcon />
                                                <p>Support</p>
                                            </div> */}
                                            <div className='business-setting-menu-item' onClick={() => { window.location.href = trainingAcademyUrl; setToggleMenu(false) }}>
                                                <TraingIcon />
                                                <p>Training academy</p>
                                            </div>
                                            {
                                                isHideFacesVerified && !isVerified && (
                                                    <div className='business-setting-menu-item' onClick={(e) => handleGetFacesVerifiedtag(e)}>
                                                        <FacesVerifiedIcon />
                                                        <p>Get Faces Verified</p>
                                                    </div>
                                                )
                                            }

                                        </div>
                                        {/* BANNER TRUSTED PARTNER */}
                                        {
                                            isBannerShow && !isHideFacesVerified && !isVerified && (
                                                !UserProfileData?.is_hide_banner && 
                                                <div className={`setting-trusted-partner-section ${isShowTrusted ? '' : 'd-none'}`}>
                                                    <div className='trusted-header'>
                                                        <h1>Become a trusted partner</h1>
                                                        <CloseIcon onClick={(e) => handleCloseClick(e)} />
                                                    </div>
                                                    <p>Upload your insurance and stand out as a trusted company.</p>
                                                    <StepButton label={"Get verified"} blue={true} onClick={() => navigateToFacesVerifiedPage()} />
                                                </div>
                                            )
                                        }
                                        {
                                            showFacesBanner && (
                                                <div className={`setting-trusted-partner-section`}>
                                                    <div className='trusted-header'>
                                                        <h1>Become a trusted partner</h1>
                                                        {/* <CloseIcon onClick={handleCloseClick} /> */}
                                                    </div>
                                                    <p>Upload your insurance and stand out as a trusted company.</p>
                                                    <StepButton label={"Get verified"} blue={true}
                                                        onClick={() => navigateToFacesVerifiedPage()} />
                                                </div>
                                            )

                                        }

                                        {/* SWITCH ACCOUNT SECTION */}
                                        {/* <div className='setting-switch-account-section'>
                                            <div className='account-title'>
                                                <h1>Switch account</h1>
                                            </div>
                                            <div className='setting-account-tile'>
                                                <div className='setting-account-tile-content'>
                                                    <Avatar
                                                        name={
                                                            "Business"
                                                        }
                                                        round
                                                        size="48"
                                                        color="#EEEFF3"
                                                        fgColor="#000"
                                                        initials={1}
                                                        textSizeRatio={2}
                                                    />
                                                    <div className='tile-child-content'>
                                                        <h1>Business Name 2</h1>
                                                        <p>4 Locations • 4 members</p>
                                                    </div>
                                                </div>
                                                <div className='setting-account-tile-content'>
                                                    <div className='addMoreIcon'>
                                                        <AddMoreIcon />
                                                    </div>
                                                    <div className='tile-child-content'>
                                                        <h1>Open another business</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* LOGOUT SECTION */}
                                        <div className='setting-logout-section' onClick={() => { dispatch(logoutProfile()) }} >
                                            <LogoutIcon />
                                            <p>Log out</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
            {/* MODAL */}
            <CustomModal
                modalOpen={isModalOpen}
                setModalOpen={setIsModalOpen}
                className='NewBookingModel'
                shouldCloseOnOverlayClick={true}
                modalBody={
                    <div className='business-modal-content-section'>
                        <div className='business-modal-setting-container'>
                            {
                                tileData?.map((tileItem, i) => (
                                    <div className='modal-sub-content-section' key={i} onClick={() => redirectToNewBookingLink(tileItem)}>
                                        <div className='modal-setting-left-section'>
                                            <div className='IconWrapper'>
                                                {tileItem?.icon}
                                            </div>
                                            <div className='modal-sub-left-content'>
                                                <h1>{tileItem?.title}</h1>
                                                <p>{tileItem?.subTitle}</p>
                                            </div>
                                        </div>
                                        <div className='modal-setting-right-section'>
                                            <NavArrorw />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default BusinessSettingHeader