import React, { useEffect, useState } from "react";
import "./HairProductScalp.scss";
import { useDropzone } from 'react-dropzone'
import CustomModal from "../../../modal/CustomModal";
import { MdOutlineImage } from "react-icons/md";
import SubHeading from "../hair-product-common/hair-product-sub-heading/SubHeading";
import HairProductIdentityModal from "./../hair-product-identity/HairProductIdentityModal";
import StepButton from "../../../common/StepButton";
import Dropzone from '../../../dropzone/dropzone'
import HairProductWhatsAppButton from "../hair-product-common/hair-product-whatsApp-btn/HairProductWhatsAppButton";


export default function HairProductIdentity({ activeMain, scalpDocProp, OnhandleContinue }) {

    const [uploadedImage, setUploadedImage] = useState([
        {
            image: null
        },
    ]);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setUploadedImage(scalpDocProp);
    }, [activeMain]);
    console.log('uploadedImage', uploadedImage)

    const handleContinue = () => {
        if (uploadedImage?.[0]?.image == null) {
            setErrorMessage("Please select or upload at least one image.");
        } else {
            OnhandleContinue(uploadedImage);
        }
    };

    const handleAddDropzone = () => {
        if (uploadedImage?.length < 5) {
            const nextDocIndex = uploadedImage?.length + 1;

            const newDocumentState = {
                image: null,
            };
            setUploadedImage((prevOtherDocs) => [...prevOtherDocs, newDocumentState]);
        }
    };
    useEffect(()=>{
        window.scrollTo(0, 0);
     },[])

    return (
        <div className="hair-product-main-Scalp">
            <div className="Identity-FormWrapper flex-gap-normal">
                <SubHeading
                    title='Scalp Images'
                    desc="Please  upload  images  of  your  scalp  so  that  our  medical  team  can  assess. 
                    Images  must  include:  front,  sides,  back  and  top  of  scalp.
                    Please  upload  one  image  per  box."
                />

                <div className="hair-scalp-docmentation-Wrapper">
                    <div className="form-label">scalp images</div>
                    <div className="docmentationGrid-Wrapper">

                        {
                            (uploadedImage.map((item, index) => (
                                <>
                                    <div className="docmentationGridItem" key={index}>
                                        <Dropzone
                                            handleMulti={setUploadedImage}
                                            selectedIndex={index}
                                            // urlFromDB={item.certificate_url}
                                            // handleDelete={initDeleteFinanceTranningCertificateData}
                                            // usrVrfId={item.id}
                                            otherDocs={uploadedImage}
                                            imageFromComponent={item.image}
                                        />
                                    </div>
                                </>
                            )))
                        }
                        {uploadedImage.length < 5 && (
                            <div className="Add-more-upload">
                                <div
                                    className="Add-dropzone"
                                    onClick={handleAddDropzone}
                                >
                                    <MdOutlineImage className='dropImage' />
                                    <p>Add More </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {errorMessage && <p className="error">{errorMessage}</p>}
                <div className='bottomStickeyBtn'>          
                    <div className="continue-btn-div">
                        <StepButton
                            label={"Continue"}
                            blue={true}
                            // isLoading={saveFinanceIdentityQueryData.loading}
                            onClick={() => handleContinue()} />
                    </div>
                    <div className="whatsapp-margin">
                        <HairProductWhatsAppButton />
                    </div>
                </div>
            </div>
        </div>
    );
}