import React, { useState } from 'react'
import '../add-new-clinic-main.scss'
import { Progress } from 'reactstrap'
import backArrow from '../../../images/back_arrow.svg'
import closeArrow from '../../../images/close_icon.svg'
import locationIcon from '../../../images/Icon example (1).svg'
import StepButton from '../../common/StepButton'
import AddClinicName from './AddClinicName'
import AddClinicAddress from './AddClinicAddress'
import AddManuallyAddress from './AddAddressManually'

const AddNameAndAddr = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [addClinicNameStep, setAddClinicNameStep] = useState(1);
    const [addClinicAddressStep, setAddClinicAddressStep] = useState(1);

    const handleAddNameStep = () => {
        setAddClinicNameStep(addClinicNameStep + 1);
    }
    const handleAddAddressStep = () => {
        setAddClinicAddressStep(addClinicAddressStep + 1);
    }
    return (
        <>
            {currentStep === 1 && (
                <>
                    {addClinicNameStep === 1 &&
                        <>
                            {addClinicAddressStep === 1 &&

                                <div className='container'>
                                    <div className='header_icon_content mt-4'>
                                        <img src={backArrow} alt='backArrow' />
                                        <Progress className="pregress_bar" value={25} />
                                        <img src={closeArrow} width={36} height={36} alt='backArrow' />
                                    </div>
                                    <div className='add_new_clinic_main'>
                                        <div className='add_new_clinic_location'>
                                            <h1>Name, Address</h1>
                                            <p>Add a name for your location, this is for your reference only. </p>
                                            <div className='add_clinic_name'>
                                                <input type='text' placeholder='Name' onClick={handleAddNameStep} />
                                                <span className='input_div' onClick={handleAddAddressStep}>
                                                    <img src={locationIcon} alt='location' />
                                                    <input type='text' placeholder='Search postcode or address' />
                                                </span>
                                                <div className='mt-4 step_btn'>
                                                    <StepButton label={'Save'} gray={true} disabled={true} />
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            }
                            {addClinicAddressStep === 2 && <AddClinicAddress addClinicAddressStep={addClinicAddressStep} setAddClinicAddressStep={setAddClinicAddressStep} />}
                            {addClinicAddressStep === 3 && <AddManuallyAddress addClinicAddressStep={addClinicAddressStep} setAddClinicAddressStep={setAddClinicAddressStep} />}
                        </>
                    }
                    {addClinicNameStep === 2 && <AddClinicName addClinicNameStep={addClinicNameStep} setAddClinicNameStep={setAddClinicNameStep} />}
                </>
            )}
        </>
    )
}

export default AddNameAndAddr
