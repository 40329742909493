import React, { useEffect, useState } from "react";
import "./customeSpiner.scss";

const CustomeLoading = ({}) => {
  return (
    <>
      <span class="CustomeSpinerloader"></span>
    </>
  );
};

export default CustomeLoading;
