import React, { useEffect, useState } from 'react'
import "./onetimechangelocation.scss"
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { capitalizeFirstLetter } from '../../utils/initials'
import CustomCheckbox from '../custom-components/custom-checkbox/CustomCheckbox'
import SkeletonLoader from '../common/Skeleton/SkeletonLoader'
import StepButton from '../common/StepButton'
import { GetClinicLocationsAction } from '../redux/actions/main-calendar/MainCalendarAction'
import { CLEAR_CLINIC_LOCATIONS } from '../redux/constants'
export const OneTimeChangeLocation = ({ setSelectedLocationSave, selectedLocationSave, setIsLocationModelOpen }) => {
    const dispatch = useDispatch()
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedLocation, setSelectedLocation] = useState(selectedLocationSave);

    //GET LOCATION DATA
    const { clinicLocationList } = useSelector(state => state?.GetClinicLocationReducer)
    const { totalClinicLocationCount } = useSelector(state => state?.GetClinicLocationReducer)
    const { loadedClinicLocationCount } = useSelector(state => state?.GetClinicLocationReducer)
    const { clinicLocationListPage } = useSelector(state => state?.GetClinicLocationReducer)
    const { emptyLocationList } = useSelector(state => state?.GetClinicLocationReducer)

    const { getClinicLocationsQueryData, initGetClinicLocationsData } = GetClinicLocationsAction()


    const handleCheckboxChange = (id) => {
        // if (selectedLocation) {
        //     setSelectedLocation([])
        // }
        if (selectedIndex == id) {
            // setSelectedIndex(-1)


        } else {
            setSelectedIndex(id)
        }
    }

    const initiateClinicLocationsCall = (refreshList = false) => {
        let newPage = clinicLocationListPage + 1;
        if (refreshList) {
            newPage = 1;

        } else if (totalClinicLocationCount <= loadedClinicLocationCount) {
            return;
        }

        initGetClinicLocationsData({
            page: newPage,
            limit: 10,
        }
        )
    };

    useEffect(() => {
        // dispatch({ type: CLEAR_CLINIC_LOCATIONS });
        if (selectedLocation?.length == 0 || clinicLocationList?.length == 0) {
            dispatch({
                type: CLEAR_CLINIC_LOCATIONS
            })
            initiateClinicLocationsCall(true)

        } else {
            // initiateClinicLocationsCall(false)

        }

    }, [])

    const handleSelectButtonClick = () => {
        // Find the selected client based on the selectedIndex
        // if (selectedIndex == -1) {
        //     setSelectedLocationSave([])
        // } else {

        const selectedClientData = clinicLocationList.find(
            (item) => item?.clinicId === selectedIndex
        );
        if (selectedLocationSave?.clinicId !== selectedClientData?.clinicId) {
        }
        // selectedLocation?.clinicId === item?.clinicId

        if (selectedClientData)
            setSelectedLocationSave(selectedClientData);
        else
            setSelectedLocationSave(selectedLocationSave);



        // Close the client list modal or perform other actions as needed
        setIsLocationModelOpen(false);
        setSelectedIndex(-1);
    };
    console.log("selectedIndex", selectedIndex)

    return (
        <>
            <div id="scrollableDiv" style={{ height: "70vh", width: "100%", overflowX: "scroll" }}>
                <InfiniteScroll
                    dataLength={loadedClinicLocationCount}
                    next={() => {
                        initiateClinicLocationsCall(false)
                    }}
                    hasMore={loadedClinicLocationCount < totalClinicLocationCount}
                    scrollableTarget="scrollableDiv"
                >
                    <div className="location-list-main-container">

                        {
                            clinicLocationList?.length != 0 && !(emptyLocationList) ?
                                clinicLocationList.map((item, index) => (
                                    <div className="location-list-container m-2 cursor-pointer " id="scrollableDiv" key={index}
                                        onClick={() => (document.getElementById(item.clinicId)).addEventListener(('change'), handleCheckboxChange(item?.clinicId))}
                                    >
                                        <p>{capitalizeFirstLetter(item?.clinicName)}</p>
                                        <CustomCheckbox
                                            className="mt-2"
                                            id={item?.clinicId}
                                            name={item?.clinicName}
                                            checked={selectedIndex !== -1 ? item?.clinicId === selectedIndex : selectedLocation?.clinicId === item?.clinicId}
                                            isCircle={true} />
                                    </div>
                                )) : getClinicLocationsQueryData.loading ? null :

                                    <div className="no-data-availa-class">
                                        <p>No Location Available!</p>
                                    </div>
                        }
                        {
                            getClinicLocationsQueryData.loading ?
                                <SkeletonLoader type="common-list" />
                                : null
                        }

                        {console.log("treatment list", selectedLocation)}
                    </div>
                </InfiniteScroll>
            </div>
            <div className="sticky-footer">
                <StepButton label={"Select"} labelClassName={"mb-0"} className={"mt-0"} blue={true}
                    onClick={handleSelectButtonClick}
                />
            </div>
        </>
    )
}
