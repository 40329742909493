import { GetMyformsList } from '../../redux/actions/formsTemplate/getFormTemplateList';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Fragment, useEffect, useRef, useState } from 'react';
import SkeletonLoader from '../../common/Skeleton/SkeletonLoader';
import { MYFORMS_EMPTY_DESC, MYFORMS_EMPTY_SEARCH_RESULT_DESC, RES_PER_PAGE } from './constants';
import FormItem from './FormItem';
import { debounce } from 'lodash';
import showToast from '../../common/CustomToast/CustomToast';
import EmptyClient from '../../Client/EmptyClient';
import StepButton from '../../common/StepButton';
import { useDispatch, useSelector } from 'react-redux';
import {
    CLEAR_MYFORMS_LIST,
    GET_MYFORMS_LIST,
} from '../../redux/constants/formsTemplate/FormTemplateConst';
import { ReactComponent as RightArrow } from '../../../images/client/right_arrow.svg';
import { ReactComponent as AddFormIcon } from '../../../images/icons/add-myform-icon.svg';
import EmptyFormImage from '../../../images/myforms-empty-icon.png';
import useWindowDimension from "../../../utils/useWindowDimensions";

function MyformsList({ searchText, isSearchedTriggered, onOpenSelectFormTypeModal }) {
    const { getMyformsList, getMyformsStatus } = GetMyformsList();
    const { totalPages, curPage, formsList, afterCarePartition } = useSelector(
        (state) => state?.myformsListReducer
    );
    const dispatch = useDispatch();
    const scrollDivRef = useRef();

    const [shallRefresh, setShallRefresh] = useState(false);
    const isLoading = getMyformsStatus.loading;

    const getFormsListHelper = (shallRefresh = false) => {

        if(shallRefresh) {
            scrollDivRef.current.scrollTo(0,0);
        }
        
        const payload = {
            page: shallRefresh ? 1 : curPage + 1,
            limit: RES_PER_PAGE,
            search: isSearchedTriggered ? searchText : '',
        };

        const onSuccess = (data) => {
            if (shallRefresh) {
                dispatch({
                    type: CLEAR_MYFORMS_LIST,
                });
            }
            dispatch({
                type: GET_MYFORMS_LIST,
                payload: data,
            });
        };

        const onError = (err) => {
            showToast('error', err.message, undefined, undefined, undefined, undefined, {
                toastId: 'myforms-query-error',
            });
        };

        if (shallRefresh) {
            dispatch({
                type: CLEAR_MYFORMS_LIST,
            });
        }

        getMyformsList(payload, onSuccess, onError);
    };

    const debouncedApiCall = debounce(() => {
        getFormsListHelper(true);
    }, 500);

    useEffect(() => {
        if (shallRefresh && searchText.length === 0) {
            getFormsListHelper(true);
        } else if (isSearchedTriggered) {

            debouncedApiCall();
            if (!shallRefresh) {
                setShallRefresh(true);
            }
        }

        return () => {
            debouncedApiCall.cancel();
        };
    }, [searchText, shallRefresh, isSearchedTriggered]);

    useEffect(() => {
        getFormsListHelper(true);
    }, []);

  const dimensions = useWindowDimension();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [secTitleHeight, setSecTitleHeight] = useState(0);
  const [formTabHeight, setFormTabHeight] = useState(0);
  const [addNewFormHeight, setAddNewFormHeight] = useState(0);
  const [searchContainerHeight, setSearchContainerHeight] = useState(0);
    

    useEffect(() => {

        setHeaderHeight(
          document.getElementsByClassName("nav-header-without-padding-container")[0]?.offsetHeight
        );

        setSecTitleHeight(
            document.getElementsByClassName("SectionHeadingWrapper")[0]?.offsetHeight
        );

        setFormTabHeight(
            document.getElementsByClassName("form-tab-div")[0]?.offsetHeight
        );

        setAddNewFormHeight(
            document.getElementsByClassName("AddNewFormItem")[0]?.offsetHeight
        );


        setSearchContainerHeight(
            document.getElementsByClassName("CustomeSearchBar")[0]?.offsetHeight
        );


        window.addEventListener("resize", handleResize);
      }, [headerHeight, secTitleHeight, formTabHeight, searchContainerHeight ]);
    
      const handleResize = () => {

        setHeaderHeight(
          document.getElementsByClassName("nav-header-without-padding-container")[0]?.offsetHeight
        );

        setSecTitleHeight(
            document.getElementsByClassName("SectionHeadingWrapper")[0]?.offsetHeight
        );

        setFormTabHeight(
            document.getElementsByClassName("form-tab-div")[0]?.offsetHeight
        );

        setAddNewFormHeight(
            document.getElementsByClassName("AddNewFormItem")[0]?.offsetHeight
        );

        setSearchContainerHeight(
            document.getElementsByClassName("CustomeSearchBar")[0]?.offsetHeight
        );
      };

  const scrollableDivHeight = dimensions?.height - headerHeight - secTitleHeight - formTabHeight - addNewFormHeight - searchContainerHeight - 30 + 'px';

  console.log(headerHeight ,secTitleHeight , formTabHeight , searchContainerHeight)

    return (
        <>
            <div onClick={onOpenSelectFormTypeModal} style={{ cursor: 'pointer' }} className="FormsListItem AddNewFormItem">
                <div className="FormsListItemContent">
                    <div className="heart-icon-image">
                        <AddFormIcon />
                    </div>
                    <div className="ItemContent">
                        <h1>Add new form</h1>
                    </div>
                </div>
                    <RightArrow />
            </div>
            <div
                ref={scrollDivRef}
                id="scrollableDiv-blockout"
                className="myforms-section"
                style={{ height: scrollableDivHeight, width: '100%', overflowY: 'auto' }}
            >
                {formsList.length > 0 && afterCarePartition !== 0 && <h3 className="template-type-heading">Consent forms</h3>}

            <InfiniteScroll
                dataLength={curPage * RES_PER_PAGE}
                next={() => {
                    getFormsListHelper();
                }}
                hasMore={curPage < totalPages}
                scrollableTarget="scrollableDiv-blockout"
            >

                {formsList.length > 0 &&
                    formsList.map((item, index) => {
                        return (
                            <Fragment key={item.templateType + '-' + item.id}>
                                {index === afterCarePartition && (
                                    <h3 className="template-type-heading">
                                        Aftercare forms
                                    </h3>
                                )}
                                <FormItem 
                                    onStatusChange={() => {
                                        getFormsListHelper(true)}
                                    } 
                                    data={item} 
                                />
                            </Fragment>
                        );
                    })}
            </InfiniteScroll>
            {
                !isLoading && formsList.length === 0 && (
                    <>
                        <div className="noData">
                            <EmptyClient
                                type={'CreateMyForm'}
                                title={'No Form Found'}
                                image={EmptyFormImage}
                                name={searchText}
                                subTitle={
                                    searchText.length === 0
                                        ? MYFORMS_EMPTY_DESC
                                        : MYFORMS_EMPTY_SEARCH_RESULT_DESC
                                }
                            />
                            {searchText.length === 0 && (
                                <StepButton
                                    blue={true}
                                    label={'Create form'}
                                    onClick={onOpenSelectFormTypeModal}
                                />
                            )}
                        </div>
                    </>
                )
                //  <EmptyClient type={"CreateMyForm"} title={"Create a form"} subTitle={"You don't have any customised forms yet. Begin by editing a form from the Consent or Aftercare tabs."} />
            }
            {isLoading && <SkeletonLoader type="form-list" />}
        </div>
        </>
    );
}

export default MyformsList;
