import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const SAVE_WEBSITE_HEADLINE_MUTATION = gql`
mutation SaveWebsiteHeadline($businessHeadline: String, $aboutBusiness: String, $welcomeText: String) {
  saveWebsiteHeadline(business_headline: $businessHeadline, about_business: $aboutBusiness, welcome_text: $welcomeText) {
    success
    message
  }
}
`;

export const SaveWebsiteHeadline = () => {
  const [saveWebsiteHeadlineRequest, saveWebsiteHeadlineResponseData] =
    useMutation(SAVE_WEBSITE_HEADLINE_MUTATION);
  const dispatch = useDispatch();
  const saveWebsiteHeadlineMutationData = saveWebsiteHeadlineResponseData;

  const initiateSaveWebsiteHeadline = (
    data,
    successCallback,
    errorCallback
  ) => {
    saveWebsiteHeadlineRequest({
      variables: data,
    })
      .then(({ data: { saveWebsiteHeadline: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateSaveWebsiteHeadline, saveWebsiteHeadlineMutationData };
};
