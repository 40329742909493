import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";


const CREATE_CLIENT_MUTATION = gql`
mutation CreateOrUpdateClientDetails($postCode: String!, $clientId: Int, $clinicId: Int, $clientProfile: Upload, $firstName: String, $lastName: String, $gender: Int, $email: String, $phoneCountry: String, $phone: String, $street: String, $houseNumber: String, $city: String, $country: String!, $dob: String, $latitude: Float, $longitude: Float,$title: clientTitleType,$marketingCommunication: Int) {
  createOrUpdateClientDetails(postCode: $postCode, clientId: $clientId, clinicId: $clinicId, clientProfile: $clientProfile, firstName: $firstName, lastName: $lastName, gender: $gender, email: $email, phoneCountry: $phoneCountry, phone: $phone, street: $street, houseNumber: $houseNumber, city: $city, country: $country, dob: $dob, latitude: $latitude, longitude: $longitude,title: $title,marketingCommunication:$marketingCommunication) {
    success
    message
    data {
      clientId
      clientProfile
      firstName
      lastName
      gender
      email
      phoneCountry
      phone
      street
      houseNumber
      city
      postCode
      country
      longitude
      latitude
      dob
      title
      marketingCommunication
    }
  }
}
`;

export const CreateClient = () => {
  const [createClient, createClientResponseData] =
    useMutation(CREATE_CLIENT_MUTATION);
  const dispatch = useDispatch();
  const createClientMutationData = createClientResponseData;

  const initiateCreateClient = (
    data,
    successCallback,
    errorCallback
  ) => {
    createClient({
      variables: data,
    })
      .then(({ data: { createOrUpdateClientDetails: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateCreateClient, createClientMutationData };
};