import {
  CLEAR_APPOINTMENT_LIST_DATA,
  GET_APPOINTMENT_LIST_DATA,
} from "../../constants/appointment/appointmentConst";
import {
  CLEAR_BOOKING_LINK_DATA,
  GET_BOOKING_LINK_DATA,
} from "../../constants/bookingLink/BookingLinkConst";

const initialState = {
  bookingLink: "",
};

export default function bookingLinkReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BOOKING_LINK_DATA:
      return {
        ...state,
        bookingLink: action.payload,
      };

    case CLEAR_BOOKING_LINK_DATA:
      return {
        ...state,
        bookingLink: "",
      };

    default:
      return state;
  }
}
