import React ,{useState} from 'react'
import { IoMdClose } from "react-icons/io";
import "./penddingVerified.scss"
const PenddingVerfiedModel = () => {

  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const handleApproveRequest = () => {
    setIsPopupVisible(false); 
  };
  return (
    <>
    {
      isPopupVisible && 
      <div className='peddingModel'>
            <div className='modelConent'>
               <div className='content'> 
                <div className='rejectedText'>
                    <h5>Your request is pending for approval</h5>
                 </div>
                 
               </div>
                <div className='closeIcon'>
                    <IoMdClose className='closeI' onClick={()=>handleApproveRequest()}/>
                </div>

            </div>
        </div>
    }
        
    </>
  )
}

export default PenddingVerfiedModel