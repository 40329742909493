import React, { useEffect, useRef, useState } from 'react'
import './appointment-list.scss'
// import MenuIcon from '../../images/icons/three_dots.svg'
import moment from 'moment'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { getAppointmentRoute, getBlockOutTimeRoute, getBookinginvoiceRoute, getClientProfileFormsRoute, getEditAppointmentRoute, getEditBlockOutTimeRoute, getNewAppointmentRoute, getViewBlockoutTimeRoute } from '../../Routes'
import { ReactComponent as CloseIcon } from '../../images/close_icon.svg'
import { ReactComponent as DeleteIcon } from '../../images/icons/appointment-list/delete.svg'
import { ReactComponent as DownloadIcon } from '../../images/icons/appointment-list/download.svg'
import { ReactComponent as EditIcon } from '../../images/icons/appointment-list/edit.svg'
import { ReactComponent as ResendIcon } from '../../images/icons/appointment-list/resend.svg'
import { ReactComponent as ViewIcon } from '../../images/icons/appointment-list/view.svg'
import { ReactComponent as RightArrow } from '../../images/icons/chevron-left.svg'
import MenuIcon from '../../images/icons/verticalDots.svg'
import FormsClient from '../Client/FormsClient'
import showToast from '../common/CustomToast/CustomToast'
import SkeletonLoader from '../common/Skeleton/SkeletonLoader'
import ConsentActionPopup from '../consent-action-popup'
import OffCanvasModal from '../modal/OffCanvasModal/OffCanvasModal'
import { GetAppointmentListByDayType } from '../redux/actions/client/GetAppointmentListById'
import { ResendBookingDetailsMutation } from '../redux/actions/sendReminder/ResendBookingDetails'
import { CLEAR_APPOINTMENT_LIST_BY_TYPE } from '../redux/constants/appointment/appointmentConst'
import { ListBlockOutTimesQuery } from '../redux/actions/block-out-time/ListBlockOutTime'
import { CLEAR_BLOCK_OUT_TIME_DATA } from '../redux/constants/blockOutTime/BlockOutTimeConst'
import CustomModal from '../modal/CustomModal'
import StepButton from '../common/StepButton'
import { DeleteBlockOutTimeMutation } from '../redux/actions/block-out-time/DeleteBlockOutTime'
import { ReactComponent as AddIcon } from "../../images/add_event.svg";


const AppointmentListDateWise = ({ clickedDate, setIsAppointmentDetailsOpen, filterObj }) => {
    const [isMenuModalOpen, setIsMenuModalOpen] = useState(false)
    const [appointmentId, setAppointmentId] = useState(null)
    const [clientId, setClientId] = useState(null)
    const [clinicId, setClinicId] = useState(null)
    const navigate = useNavigate();
    const [openClientForm, setOpenClientForm] = useState(false)
    const popOverRef = useRef(null)
    const [isDelete, setIsDelete] = useState(false)
    const [blockOutId, setBlockOutId] = useState(null)
    const [blockOutDateId, setBlockOutDateId] = useState(null)
    const [blockoutEndDate, SetBlockoutEndDate] = useState(null)

    //Get data of Appointment list
    // const appointmentListData = useSelector((state)=>state?.appointmentListReducer?.appointmentList?.appointments)
    const appointmentListData = useSelector((state) => state?.appointmentListByTypeReducer?.appointmentListByType)
    const totalAppointmentCount = useSelector((state) => state?.appointmentListByTypeReducer?.totalAppointmentCount)
    const appointmentListPage = useSelector((state) => state?.appointmentListByTypeReducer?.appointmentListPage)
    const loadedAppointmentCount = useSelector((state) => state?.appointmentListByTypeReducer?.loadedAppointmentCount)

    const blockOutTimeListData = useSelector((state) => state?.blockTimeOutListReducer?.blockTimeOutList)
    const totalBlockOutTimeListCount = useSelector((state) => state?.blockTimeOutListReducer?.totalBlockTimeOutListCount)
    const blockTimeOutListPage = useSelector((state) => state?.blockTimeOutListReducer?.blockTimeOutListPage)
    const loadedBlockOutTimeCount = useSelector((state) => state?.blockTimeOutListReducer?.loadedBlockTimeOutListCount)
    const location = useLocation()


    //Appointment list Query by selected Date
    //const { initGetAppointmentList, appointmentListQueryData } = GetAppointmentList();
    const { initGetAppointmentListByDayType, appointmentListQueryData } = GetAppointmentListByDayType()
    const [isRootClose, setIsRootClose] = useState(false)
    const [isFilled, setIsFilled] = useState(null)
    const [showConsentPopup, setShowConsentPopup] = useState()
    const [confirmationPopupShow, setConfirmationPopupShow] = useState(false);
    const [compliancePopupShow, setCompliancePopupShow] = useState(false);
    const [deleteDataByAppointmentId, setDeleteDataByAppointmentId] = useState();




    const selectedDateString = moment(clickedDate).format('YYYY-MM-DD');
    const todayDateString = moment().format('YYYY-MM-DD');
    const dispatch = useDispatch()
    const [isBlockTimeOut, setIsBlockTimeOut] = useState(false)
    const isNotToday = moment(selectedDateString).isBefore(todayDateString) || moment(selectedDateString).isAfter(todayDateString);
    //Resend Booking Details
    const { initiateResendBookingDetailsData } = ResendBookingDetailsMutation()

    //GET BLOCK OUT TIME LIST QUERY
    const { initListBlockTimeOut, listBlockOutTimeQueryData } = ListBlockOutTimesQuery();
    //DELETE BLOCK TIME OUT
    const { initiateDeleteBlockOutTime, deleteBlockTimeMutationData } = DeleteBlockOutTimeMutation()

    const menuData = [
        {
            title: "Edit / reschedule",
            icon: <EditIcon width={40} />,
        },
        {
            title: "Resend booking details",
            icon: <ResendIcon width={40} />,
        },
        {
            title: "Download invoice",
            icon: <DownloadIcon width={40} />,

        },
        {
            title: "View consent",
            icon: <ViewIcon width={40} />,
        },
        {
            title: "Delete",
            icon: <DeleteIcon width={40} />,
        }
    ]

    const blockOutTimeMenuData = [
        {
            title: "Edit",
            icon: <EditIcon width={40} />,
        },
        {
            title: "Delete",
            icon: <DeleteIcon width={40} />,
        }
    ]
    const staticConsentForm = `https://staging.facesconsent.com/filled-consent-form/list?cln_id=${clinicId}`;

    const initiateAppointmentListCall = (refreshList = false) => {
        let newPage = appointmentListPage + 1;
        if (refreshList) {
            newPage = 1;

        } else if (totalAppointmentCount <= loadedAppointmentCount) {
            return;
        }

        initGetAppointmentListByDayType({
            ...filterObj,
            startDate: moment(clickedDate).format("YYYY-MM-DD"),
            endDate: moment(clickedDate).format("YYYY-MM-DD"),
            page: newPage,
            limit: 15,
        })
    }

    //Block Out Time List
    const initiateBlockTimeOutListCall = (refreshList = false) => {
        let newPage = blockTimeOutListPage + 1;
        if (refreshList) {
            newPage = 1;

        } else if (totalBlockOutTimeListCount <= loadedBlockOutTimeCount) {
            return;
        }

        initListBlockTimeOut({
            startDate: moment(clickedDate).format("YYYY-MM-DD"),
            endDate: moment(clickedDate).format("YYYY-MM-DD"),
            page: newPage,
            limit: 15,
        })
    }



    const handleResendDetails = (formId) => {
        initiateResendBookingDetailsData({
            "appointmentId": formId,
            "consentFormRecordId": null
        }, (res) => {
            if (res.success) {
                // toast.success(res.message)
                showToast("success", res?.message, "", false)
            } else {

                showToast("error", res?.message, "", false)
            }
        }, (err) => {
            showToast("error", err, "", false)
        })
    }

    useEffect(() => {
        if (openClientForm) {
            //popOverRef.onHide()
            document.body.click()
        }
    }, [isRootClose])

    useEffect(() => {
        console.log("cancel list", location?.state?.cancelAppointment)
        if (location?.state?.cancelAppointment == "cancelAppointment") {
            setIsBlockTimeOut(true)
            dispatch({
                type: CLEAR_BLOCK_OUT_TIME_DATA
            })
            initiateBlockTimeOutListCall(true);
        }
    }, [location?.state?.cancelAppointment])

    const handleCloseClick = () => {
        document.body.click()
        //popOverRef.onHide()
    }
    const handleMenuClick = (title) => {
        if (isBlockTimeOut) {
            switch (title) {
                case "Edit":
                    // if (moment().isAfter(moment(blockoutEndDate, "YYYY-MM-DD"))) {
                    //     showToast("error", "You can't edit this event", "", "")
                    // }
                    // else {
                    //     navigate(getEditBlockOutTimeRoute(blockOutId, blockOutDateId))
                    // }
                    navigate(getViewBlockoutTimeRoute(blockOutId, blockOutDateId), { state: { goBackToCalendar: true } })
                    // navigate(NewAppointmentRoutePattern + "?bookingId=" + appointmentId)
                    //navigate(getEditAppointmentRoute(appointmentId))
                    break;
                case "View":
                    console.log('View')
                    break;
                case "Delete":
                    console.log('Delete')
                    if (moment().isAfter(moment(blockoutEndDate, "YYYY-MM-DD"))) {
                        showToast("error", "You can't delete this event", "", "")
                    }
                    else {
                        setIsDelete(true)
                    }
                    break;
                default:
                    console.log('Default')
                    break;
            }
        } else {
            switch (title) {
                case "Edit / reschedule":
                    console.log('Edit / reschedule')
                    // navigate(NewAppointmentRoutePattern + "?bookingId=" + appointmentId)
                    navigate(getEditAppointmentRoute(appointmentId), { state: { fromAppointmentList: true, clickdate: clickedDate } })
                    break;
                case "Resend booking details":
                    console.log('Resend booking details')
                    handleResendDetails(appointmentId)
                    break;
                case "Download invoice":
                    console.log('Download invoice', appointmentId)
                    navigate(getBookinginvoiceRoute(), { state: { entityId: appointmentId, selectedDate: clickedDate } })
                    break;
                case "View consent":
                    console.log('View consent', clientId)
                    document.body.click()
                    setIsRootClose(false)
                    // setOpenClientForm(true)

                    navigate(getClientProfileFormsRoute(clientId), { state: { appointmentId: appointmentId, fromAppointmentList: true, clickdate: clickedDate } })

                    break;
                case "Delete":

                    setShowConsentPopup(true);
                    setConfirmationPopupShow(true);
                    console.log('Delete')
                    break;
                default:
                    console.log('Default')
                    break;
            }
        }
    }
    const getAppointmentList = () => {
        dispatch({
            type: CLEAR_APPOINTMENT_LIST_BY_TYPE
        })
        if (clickedDate !== null) {
            initGetAppointmentListByDayType({
                startDate: moment(clickedDate).format("YYYY-MM-DD"),
                endDate: moment(clickedDate).format("YYYY-MM-DD"),
            })
        }
    }
    useEffect(() => {
        //Clear the appointment list
        dispatch({
            type: CLEAR_APPOINTMENT_LIST_BY_TYPE
        })
        initiateAppointmentListCall(true);
    }, [clickedDate])

    const formattedDate = moment(clickedDate).format('ddd D MMMM');

    console.log("appointment list", appointmentListData)

    const popoverClick = (
        <Popover id="popover-trigger-click" className='appointment-option-main p-3' >
            <div className='popover-title-section'>
                <h1>Manage</h1>
                <CloseIcon className='close-icon-animation' onClick={handleCloseClick} />
            </div>
            {
                isBlockTimeOut ?
                    blockOutTimeMenuData.map((items, index) => (
                        <div className='appointment-list-option-container' key={index} onClick={() => { handleMenuClick(items?.title); setIsRootClose(true); document.body.click() }}>
                            <div className='appointment-list-left-popover-content'>
                                {items.icon}
                                <p>{items.title}</p>
                            </div>
                            <div className='appointment-list-right-popover-content'>
                                <RightArrow />
                            </div>
                        </div>
                    )) :
                    menuData.filter((items) => {
                        if (isFilled) {
                            // If isFilled is true, hide "Resend booking details"
                            return items.title !== 'Resend booking details';
                        } else {
                            // If isFilled is false, hide "View consent"
                            return items.title !== 'View consent';
                        }
                    }).map((items, index) => (
                        <div className='appointment-list-option-container' key={index} onClick={() => { handleMenuClick(items?.title); setIsRootClose(true); document.body.click() }}>
                            <div className='appointment-list-left-popover-content'>
                                {items.icon}
                                <p>{items.title}</p>
                            </div>
                            <div className='appointment-list-right-popover-content'>
                                <RightArrow />
                            </div>
                        </div>
                    ))
            }
            {console.log("appoinmentId", appointmentId)}
        </Popover>

    );

    const getDayForGivenDate = (dateStr) => {

        // Parse the date string into a Moment object
        const givenDate = moment(dateStr, 'DD-MM-YYYY')

        // Get the current date as a Moment object
        const today = moment();

        // Calculate tomorrow's date as a Moment object
        const tomorrow = today.clone().add(1, 'days');
        // Compare the given date with today and tomorrow
        if (givenDate.isSame(today, 'day')) {
            return "Today"
        } else if (givenDate.isSame(tomorrow, 'day')) {
            return "Tomorrow"
        } else {
            return dateStr
        }
    }


    const handleBlockTimeOutList = () => {
        setIsBlockTimeOut(!isBlockTimeOut)
        // initListBlockTimeOut({
        //     startDate: moment(clickedDate).format("YYYY-MM-DD"),
        //     endDate: moment(clickedDate).format("YYYY-MM-DD"),
        // },(res)=>{

        // })
        dispatch({
            type: CLEAR_BLOCK_OUT_TIME_DATA
        })
        initiateBlockTimeOutListCall(true);
    }

    //DELETE BLOCK OUT TIME
    const handleDeleteBlockOut = () => {
        initiateDeleteBlockOutTime({
            blockOutDateId: blockOutDateId,
            blockOutId: blockOutId
        }, (res) => {
            if (res?.success) {
                setIsDelete(false)
                dispatch({
                    type: CLEAR_BLOCK_OUT_TIME_DATA
                })
                initiateBlockTimeOutListCall(true)
            }
        }, (err) => {
            console.log(err)
        })
    }
    return (
        <div className='appointment-list-container CustomeAppointment'>
            <div className='appointment-list-content-container' >
                <div className='AppointmentTitleWrapper date-block-out-container'>
                    <h1>{formattedDate}</h1>
                    <button onClick={() => handleBlockTimeOutList()} className={isBlockTimeOut ? 'active' : 'deactive'}>Block out</button>
                </div>
                {
                    isBlockTimeOut ?
                        <div id="scrollableDiv-blockout" style={{ height: "100vh", width: "100%", overflowX: "scroll" }}>

                            <InfiniteScroll
                                dataLength={loadedBlockOutTimeCount}
                                next={() => {
                                    initiateBlockTimeOutListCall(false)
                                }}
                                hasMore={loadedBlockOutTimeCount < totalBlockOutTimeListCount}
                                scrollableTarget="scrollableDiv-blockout"
                            >
                                <div className="location-list-main-container">

                                    {
                                        blockOutTimeListData?.length != 0 ?
                                            blockOutTimeListData.map((items, index) => (
                                                <>
                                                    <div className='appointment-list-card' key={index} onClick={() => navigate(getViewBlockoutTimeRoute(items?.blockOutId, items?.blockOutDateId), { state: { listClickDate: clickedDate, fromBlockoutList: true } })} >
                                                        <div className='appointment-list-content'>
                                                            <div className='status-badge-red mb-2' style={{ background: items?.enableBooking ? "green" : "white" }}>
                                                                {
                                                                    items?.enableBooking && <p>Available for booking</p>
                                                                }
                                                            </div>
                                                            <h1>{items?.title}</h1>
                                                            <p>{items?.readableStartDate} to {items?.readableEndDate} </p>
                                                            <p>{items?.startTime} to  {items?.endTime}</p>
                                                            <div onClick={(e) => {
                                                                e.stopPropagation();
                                                                setBlockOutDateId(items?.blockOutDateId); setBlockOutId(items?.blockOutId); SetBlockoutEndDate(items?.endDate)
                                                            }}>
                                                                <OverlayTrigger rootClose ref={r => (popOverRef = r)}
                                                                    container={popOverRef.current} trigger="click" placement={`${blockOutTimeListData?.length - 1 == index && blockOutTimeListData?.length != 1 ? "top" : "bottom"}`} overlay={popoverClick}>
                                                                    <img src={MenuIcon} />
                                                                </OverlayTrigger>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </>
                                            )) : listBlockOutTimeQueryData.loading ? null :

                                                <div>
                                                    <h1>Events yet to be created.</h1>
                                                </div>
                                    }
                                    {
                                        listBlockOutTimeQueryData.loading ?
                                            <SkeletonLoader type="appointment" isClient={true} />
                                            : null
                                    }

                                </div>
                            </InfiniteScroll>
                        </div>
                        :
                        <div id="scrollableDiv" style={{ height: "100vh", width: "100%", overflowX: "scroll" }}>

                            <InfiniteScroll
                                dataLength={loadedAppointmentCount}
                                next={() => {
                                    initiateAppointmentListCall(false)
                                }}
                                hasMore={loadedAppointmentCount < totalAppointmentCount}
                                scrollableTarget="scrollableDiv"
                            >
                                <div className="location-list-main-container">

                                    {
                                        appointmentListData?.length != 0 ?
                                            appointmentListData.map((items, index) => (
                                                <>
                                                    <div className='appointment-list-card' key={index} onClick={() => navigate(getAppointmentRoute(parseInt(items?.booking_slug)), { state: { fromCalenderAppointmentList: "calender" } })}>
                                                        <div className='appointment-list-content'>
                                                            <div className='status-badge-red mb-2' style={{ background: items?.deposit_status_color == "" ? "unset" : items?.deposit_status_color }}>
                                                                <p>{items?.deposit_status}</p>
                                                            </div>
                                                            <h1>{items?.client_name} {items?.location_name ? `@ ${items?.location_name}` : ""} </h1>
                                                            <p>{getDayForGivenDate(items?.calender_date)}, {moment(items?.cld_rc_start).format("HH:mm")} – {moment(items?.cld_rc_end).format("HH:mm")}</p>
                                                            <p>{items?.treatment_count > 1 ? `${items?.treatment_name} + ${items?.treatment_count - 1}` : items?.treatment_name}</p>
                                                            <div onClick={(e) => {
                                                                e.stopPropagation();
                                                                console.log("clickkk", items?.clientId)
                                                                setAppointmentId(parseInt(items?.booking_slug)); setClinicId(items?.clinicId); setClientId(items?.clientId); setIsFilled(items?.isFilled); setDeleteDataByAppointmentId(items.cld_rc_id)
                                                            }}>
                                                                <OverlayTrigger rootClose ref={r => (popOverRef = r)}
                                                                    container={popOverRef.current} trigger="click" placement={`${appointmentListData?.length - 1 == index && appointmentListData?.length != 1 ? "top" : "bottom"}`} overlay={popoverClick}>

                                                                    <img src={MenuIcon} className='CustomeappoimentBtn' />
                                                                </OverlayTrigger>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </>
                                            )) : appointmentListQueryData.loading ? null :

                                                <div>
                                                    <h1>Events yet to be created.</h1>
                                                </div>
                                    }
                                    {
                                        appointmentListQueryData.loading ?
                                            <div className='AppointmentListSkeleton'>
                                                <SkeletonLoader type="appointment" isClient={true} />
                                            </div>
                                            : null
                                    }

                                </div>
                            </InfiniteScroll>
                        </div>
                }
            </div>
            <div className="plus-button-section">
                <AddIcon className='cursor-pointer' style={{ fontSize: 20 }} onClick={() => navigate(getNewAppointmentRoute(), { state: { appointmentDate: clickedDate } })} />
            </div>

            {
                showConsentPopup ? <ConsentActionPopup confirmationPopupShow={confirmationPopupShow} height={"280px"} setConfirmationPopupShow={setConfirmationPopupShow} setShowConsentPopup={setShowConsentPopup} setCompliancePopupShow={setCompliancePopupShow} compliancePopupShow={compliancePopupShow} elementId={deleteDataByAppointmentId} fromAppointmentListNavigation={false} clickedDate={clickedDate} getAppointmentData={getAppointmentList} /> : null
            }
            {/* <OffCanvasModal
                isOpen={openClientForm}
                isBack={true}
                customHeader={true}
                isBackOnly={true}
                className={'CustomeFormCanvas'}
                onHide={() => { setOpenClientForm(false) }}
                body={<FormsClient clientId={clientId} appointmentId={appointmentId} />}
            /> */}

            {/* DELETE BLOCK OUT MODAL */}
            <CustomModal
                type={"common-width"}
                modalBody={
                    <>
                        <div className="delete-modal-body">
                            <h1>Are you sure?</h1>
                            <p>you want to delete this block out time? This action can not be reversed?</p>
                            <div className="w-100">
                                <div className="delete-button-modal">
                                    <StepButton red={true} label={"Delete"} isLoading={deleteBlockTimeMutationData?.loading} onClick={handleDeleteBlockOut} />
                                </div>
                                <div className="cancle-button-modal">
                                    <StepButton gray={true} onClick={() => setIsDelete(false)} label={"Cancel"} />
                                </div>
                            </div>
                        </div>
                    </>
                }
                modalOpen={isDelete}
                setModalOpen={setIsDelete}
                setIsOpenMobileCalendar={setIsDelete}
            />

        </div>
    )
}

export default AppointmentListDateWise