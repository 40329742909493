// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_ACCOUNTANCY_SERVICE_PACKAGE_QUERY = gql`
query GetAccountancyPackage($packageSlug: String) {
    getAccountancyPackage(packageSlug: $packageSlug) {
      success
      message
      data {
        id
        package_name
        amount
        amountText
        duration_text
        package_points
        is_popular
        slug
        can_subscribe
      }
    }
  }
`;

export const GetAccountancyPackageAction = () => {
    // const dispatch = useDispatch();
    const [
        getAccountancyPackageResponseFunction,
        getAccountancyPackageResponseData,
    ] = useLazyQuery(GET_ACCOUNTANCY_SERVICE_PACKAGE_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getAccountancyPackage: responseData }) => {
            if (responseData.success) {
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getAccountancyPackageQueryData = getAccountancyPackageResponseData; //variable

    const initGetAccountancyPackageData = (data) => {
        getAccountancyPackageResponseFunction({
            variables: data,
        });
    };

    return { getAccountancyPackageQueryData, initGetAccountancyPackageData };
};
