import React, { useEffect, useState } from "react";
import "./financeOnboardingSteps.scss";
import FinaceHeader from "../../finaceHeader";
import informationIcon from "../../../assets/finance-page/informationIcon.svg";
import BankIcon from "../../../assets/finance-page/money.svg";
import UploadDocumentationIcon from "../../../assets/finance-page/UploadDocumentationIcon.svg";
import LockIcon from "../../../assets/finance-page/LockIcon.svg";
import whatsappIcon from "../../../assets/finance-page/whatsapp.svg";
import insuranceIcon from "../../../assets/finance-page/insurance.svg";
import blockIcon from "../../../assets/finance-page/block.svg";
import cardIcon from "../../../assets/finance-page/cardIcon.svg";
import noCostIcon from "../../../assets/finance-page/noCostIcon.svg";
import SimplificationIcon from "../../../assets/finance-page/SimplificationIcon.svg";
import checkboxIcon from "../../../assets/finance-page/checkboxIcon.svg";
import { OnboardingStepsForFinance } from "../../redux/actions/pay-later/onboardingStepsForFinance";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import { frontendURL, webBaseUrl } from "../../../config";
import {config} from "../../../config";


const FinanceOnboardingStepsNew = () => {
  const [scroll, setScroll] = useState(false);

  const { initonboardingStepsForFinanceData } = OnboardingStepsForFinance();
  const [onboardingData, setOnboardingData] = useState(null);
  const [financeNavigationLink, setFinanceNavigationLink] = useState(false);
  const [isAuthHeader, setIsAuthHeader] = useState(false);
  const [isNavigateUrl,setIsNavigateUrl] = useState(false);
  const whatsappLinkRedirect = config.WHATSAPP_LINK;
  const navigate = useNavigate();
  useEffect(() => {
    Cookies.get("faces_access_token") ? setIsAuthHeader(true) : setIsAuthHeader(false);      
  }, []);
  useEffect(() => {
    if(isAuthHeader) {
      setIsNavigateUrl(true);
      setFinanceNavigationLink(webBaseUrl+"/"+"user/update-profile?active-tab=loan-settings")
    }
    else {
      setIsNavigateUrl(true);
      setFinanceNavigationLink(webBaseUrl+"/"+"login")
    }
  }, [isAuthHeader]);

  useEffect(() => {
    initonboardingStepsForFinanceData(
      {},
      (res) => {
        console.log("Onboarding Data:", res);
        setOnboardingData(res.data.getOnboardingInfoForFinance.data);
      },
      (err) => {
        console.log("Error fetching onboarding data:", err);
      }
    );
  }, [navigate]);

  useEffect(() => {
    if (onboardingData?.basic_information_completed && onboardingData?.bank_details_added && onboardingData?.documents_uploaded && onboardingData?.card_linked) {
      navigate("/revenue-dashboard")
    }
  },[onboardingData])

  const redirectTo = (path, event) => {
    event.stopPropagation();
    console.log("Redirecting...");
    navigate(path);
  };


  useEffect(() => {
    window.addEventListener("scroll", () => {
      // console.log(window.scrollY, 'window.scrollY')
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <>
      <div className="main-Faces-Finance-onboarding">
        <FinaceHeader />
        <div className="container">
            <div className="pageOnboardingAllSection">
                <div className="pageOnboardingTitleWrapper">
                  <h2>Faces Finance</h2>
                  <p>Offer Buy now Payl8r and other pay monthly plans to your customers. 98% of people aged 18-50 use payment plans. reach a bigger audience with Faces Finance.</p>
                </div>

                <div className="progressWrapper">
                  <div className="BottomWhite"></div>
                  <div className="ActiveTrigger" style={{width: '25%'}} ></div>
                </div>

                {/* CARDwRAPPER */}
                <div className="OnboardingstepsWrapper">
                    <div className="row">
                      <div className="col-sm-12 col-md-7">
                        <div className="stepsWrapper">
                          <div className="stepsBox">
                            <div className="checkStatus">
                            {
                                onboardingData?.basic_information_completed &&
                                <img src={checkboxIcon} alt={checkboxIcon} />
                              }
                            </div>
                            <div className="stepsBoxHeader">
                              <div className="stepsBoxIcon">
                                <img src={informationIcon} alt={informationIcon} />
                              </div>
                              <div className="stepsBoxTitle">
                                  <h5>Basic Information</h5>
                              </div>
                            </div>
                            <div className="stepsBoxBody">
                              <p>Input your location details first.</p>
                              {/* <button className="greyBtn">Edit</button> */}
                              {!onboardingData?.basic_information_completed ?  <button className="greyBtn" onClick={(e) => redirectTo("/edit-profile", e)}>Add</button>
                              :
                              <button className="greyBtn" onClick={(e) => redirectTo("/edit-profile", e)}>Edit</button>
                            }
                            </div>
                          </div>
                          <div className="stepsBox">
                          <div className="checkStatus">
                            {
                                onboardingData?.bank_details_added &&
                                <img src={checkboxIcon} alt={checkboxIcon} />
                              }
                            </div>
                            <div className="stepsBoxHeader">
                              <div className="stepsBoxIcon">
                                <img src={BankIcon} alt={BankIcon} />
                              </div>
                              <div className="stepsBoxTitle">
                                  <h5>Bank Details </h5>
                              </div>
                            </div>
                            <div className="stepsBoxBody">
                              <p>Take online payments for your services.</p>
                              {!onboardingData?.bank_details_added ?  <button className={onboardingData?.basic_information_completed ? "blueBtn" : "greyBtn"} onClick={(e) => redirectTo("/edit-profile", e)}>Add</button>
                              :
                              <button className="greyBtn" onClick={(e) => redirectTo("/edit-profile", e)}>Edit</button>
                            }
                            </div>
                          </div>
                          <div className="stepsBox">
                          <div className="checkStatus">
                            {
                                onboardingData?.documents_uploaded &&
                                <img src={checkboxIcon} alt={checkboxIcon} />
                              }
                            </div>
                            <div className="stepsBoxHeader">
                              <div className="stepsBoxIcon">
                                <img src={UploadDocumentationIcon} alt={UploadDocumentationIcon} />
                              </div>
                              <div className="stepsBoxTitle">
                                  <h5>Upload Documentation </h5>
                              </div>
                            </div>
                            <div className="stepsBoxBody">
                              <p>Begin with your primary service.</p>
                              {/* <button className="greyBtn">Add</button> */}
                              {!onboardingData?.documents_uploaded ?  <button className={onboardingData?.bank_details_added ? 'blueBtn' : 'greyBtn' } onClick={(e) => redirectTo("/edit-profile", e)}>Add</button>
                              :
                              <button className="greyBtn" onClick={(e) => redirectTo("/edit-profile", e)}>Edit</button>
                            }
                            </div>
                          </div>
                          <div className="stepsBox">
                          <div className="checkStatus">
                            {
                                onboardingData?.card_linked &&
                                <img src={checkboxIcon} alt={checkboxIcon} />
                              }
                            </div>
                            <div className="stepsBoxHeader">
                              <div className="stepsBoxIcon">
                                <img src={LockIcon} alt={LockIcon} />
                              </div>
                              <div className="stepsBoxTitle">
                                  <h5>Link your card</h5>
                              </div>
                            </div>
                            <div className="stepsBoxBody">
                              <p>Link your cards</p>
                              {/* <button className="greyBtn">Add</button> */}
                              {/* <button className="greyBtn">Add</button> */}
                              {!onboardingData?.card_linked ?  <button className={(onboardingData?.bank_details_added && onboardingData?.documents_uploaded) ? "blueBtn" :  "greyBtn"} onClick={(e) => redirectTo("/edit-profile", e)}>Add</button>
                              :
                              <button className="greyBtn" onClick={(e) => redirectTo("/edit-profile", e)}>Edit</button>
                            }
                            </div>
                          </div>
                          
                        </div>

                        <div className="WpBtnWrapper">
                          <button className="wpBtn"   onClick={() => {
                            window.open(whatsappLinkRedirect, "_blank");
                          }}>
                              <span className="wpIcon">
                                  <img src={whatsappIcon} alt="whatsappIcon" />
                              </span>
                              Whatsapp us
                          </button>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                          <div className={scroll ? "pointUl pointstickey" : "pointUl "}>
                              <ul>
                                  <li>
                                    <div className="liIcon">
                                      <img src={blockIcon} alt={blockIcon} />
                                    </div>
                                    <div className="liContent">
                                        <h5>No Risk </h5>
                                        <p>absolutely risk free, never worry about client repayments we take that risk so you don’t have to </p>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="liIcon">
                                      <img src={cardIcon} alt={cardIcon} />
                                    </div>
                                    <div className="liContent">
                                        <h5>Payout in 24-48 hours</h5>
                                        <p>Receive payment within 48hours from the day of treatment</p>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="liIcon">
                                      <img src={noCostIcon} alt={noCostIcon} />
                                    </div>
                                    <div className="liContent">
                                        <h5>No Set-up Cost or Monthly fees </h5>
                                        <p>Zero Set-up costs or monthly fees or subscription we are a pay as you go service.</p>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="liIcon">
                                      <img src={SimplificationIcon} alt={SimplificationIcon} />
                                    </div>
                                    <div className="liContent">
                                        <h5>Increased Client Retention</h5>
                                        <p>Increase client retention and spend with convenient monthly payments. one treatment leads to the repeat treatment.</p>
                                    </div>
                                  </li>
                              </ul>
                          </div>
                      </div>
                    </div>    

                    <div className="NeedInsuranceWrapper">
                      <div className="NeedIconWrapper">
                          <span className="insuranceIconWrapper">
                            <img src={insuranceIcon} alt={insuranceIcon} />
                          </span>
                          <div className="NeedContentWrapper">
                              <h5>Need Insurance? </h5>
                              <p>Compare from over 7 different insurance providers. to get the best quote possible . <span className="viewMore" onClick={() => {
                                window.open(frontendURL + '/beauty-aesthetic-insurance-companies', "_blank");
                              }}>Click Here</span> </p>
                          </div>
                      </div>
                    </div>            
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default FinanceOnboardingStepsNew;
