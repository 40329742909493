import React from 'react';

const DottedSlider = ({ totalSteps, currentStep, setCurrentStep }) => {
  const renderDots = () => {
    const dots = [];
    for (let i = 1; i <= totalSteps; i++) {
      let dotColor = 'gray';

      // Check if the current step matches the iteration step
      if (i === currentStep) {
        dotColor = 'black';
      } else if (i === currentStep - 1 || i === currentStep + 1) {
        dotColor = 'gray';
      }

      dots.push(
        <span
          key={i}
          className="dot"
          style={{  cursor: 'pointer',  fontSize: '30px',  color: dotColor,  fontWeight: 'bold',  padding: '0',  position: 'relative',  left: '45%' }}
          onClick={() => setCurrentStep(i)}
        >
          {" "}
          .{" "}
        </span>
      );
    }
    return dots;
  };

  return <div className="dotted-slider">{renderDots()}</div>;
};

export default DottedSlider;
