import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";


const GET_FINANCE_BANK_DETAIL=gql`
query GetBankDetailsOfUserForFinance {
    getBankDetailsOfUserForFinance {
      success
      message
      data {
        bank_account_number
        bank_sort_code
      }
    }
  }
`;

export const GetFinanceBankDetailAction=()=>{
    const [getFinanceBankDetailResponseFunction,
    getFinanceBankDetailResponseData]=useLazyQuery(GET_FINANCE_BANK_DETAIL,{
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted:({getBankDetailsOfUserForFinance:responseData})=>{
            if(responseData){
                console.log("res",responseData?.message)
            }else{
                console.log("error",responseData?.message)
            }
        }
    })

    const getfinanceBankDetailQueryData=getFinanceBankDetailResponseData;

    const initGetFinanceBankDetailData=(data,successCallback,errorCallback)=>{
        getFinanceBankDetailResponseFunction({
            variables:data
        }).then((res)=>{
            successCallback(res.data?.getBankDetailsOfUserForFinance?.data)
        }).catch((err)=>{
            errorCallback(err)
        })
    }

    return { getfinanceBankDetailQueryData,initGetFinanceBankDetailData}
}