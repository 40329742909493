import { gql, useMutation } from "@apollo/client";

const RESEND_MEDICAL_FORM_MUTATION = gql`
mutation ResendMedicalForm($clientId: Int) {
  resendMedicalForm(clientId: $clientId) {
    success
    message
  }
}
`;


export const ResendMedicalFormAction = () => {
    const [
        ResendMedicalFormResponseFunction,
        resendMedicalFormResponseData,
    ] = useMutation(RESEND_MEDICAL_FORM_MUTATION)

    //this will be called from components
    const resendMedicalFormQueryData = resendMedicalFormResponseData; //variable

    const initResendMedicalFormData = (data, successCallback, errorCallback) => {
        ResendMedicalFormResponseFunction({
            variables: data,
        }).then(({ data: { resendMedicalForm: responseData } }) => {
            if (responseData) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback()
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
        })
    }

    return { resendMedicalFormQueryData, initResendMedicalFormData };
};
