import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getAppointmentRoute, getCalendarRoute } from '../../../../Routes';
import Plus from "../../../../images/add_client.svg";
import rightArrow from "../../../../images/icons/chevron-left.svg";
import ServiceIcon from "../../../../images/new-appointment/service.svg";
import NavHeader from '../../../common/NavHeader/NavHeader';
import SkeletonLoader from '../../../common/Skeleton/SkeletonLoader';
import "./batch-number-treatment-listing.scss";
import { useDispatch } from 'react-redux';
import { CLEAR_APPOINTMENT_DOCUMENT_TAB_DATA, CLEAR_APPOINTMENT_MANAGE_TAB_DATA } from '../../../redux/constants';

const BatchNumberTreatmentListing = ({ setStep, setIsOtherUploadPhoto, treatmentData, setSelectedTreatment, setSelectedTreatmentId, loading }) => {
    const navigate = useNavigate()
    const location = useLocation();
    const dispatch = useDispatch()

    const goBackAppointmentOverview = () => {
        dispatch({ type: CLEAR_APPOINTMENT_DOCUMENT_TAB_DATA })
        // dispatch({ type: CLEAR_APPOINTMENT_MANAGE_TAB_DATA })
        if (location?.state?.fromCalendar) {
            navigate(getCalendarRoute(), { state: { fromCanvasAppointment: location?.state?.AppointmentDate, changeStatus: true } })
        }
        else if (location?.state?.AppointmentDate) {
            navigate(getAppointmentRoute(parseInt(location?.state?.AppointmentDate)))
        }
    }
    return (
        <>
            <NavHeader isBackOnly={true} applyPadding={false} handleOnBack={() => goBackAppointmentOverview()} />
            {loading ? <SkeletonLoader type="before-after-photos-treatment-list" /> : <div className='batch-number-container'>
                <h1>Batch number</h1>
                <p>Select your services</p>

                <div className='service-and-other-photos-container'>
                    <div className='service-wrapper'>
                        {treatmentData?.length > 0 && treatmentData?.map((item) => {
                            return <div className='service-container'>
                                <div className='d-flex justify-content-between align-items-center'
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        // navigate(appointmentOverviewBeforeAndAfterAddPhotosRoute(), { state: { otherPhoto: false, fromCalendar: location?.state?.fromCalendar, appointmentId: location?.state?.AppointmentDate } })
                                        setStep(2)
                                        setIsOtherUploadPhoto(false)
                                        setSelectedTreatment(item.treatmentName)
                                        setSelectedTreatmentId(item.treatmentId)
                                    }}

                                >
                                    <div className='treatment-actions-content d-flex gap-2'>
                                        <div className='compliance-img-container7'>
                                            <img className='main-img' src={ServiceIcon} alt="" />
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <span className='my-auto'>{item.treatmentName}</span>
                                            <p>{item.photosCount} photos</p>

                                        </div>
                                    </div>
                                    <div>
                                        <img className='my-auto' src={rightArrow} alt="" />
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>

                    <div className='other-photos-container'>
                        <p>Other photos</p>
                        <hr />
                        <div className='other-photo-upload'>
                            <div className='d-flex justify-content-between align-items-center'
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    // navigate(appointmentOverviewBeforeAndAfterAddPhotosRoute(), { state: { otherPhoto: true, fromCalendar: location?.state?.fromCalendar, appointmentId: location?.state?.AppointmentDate } })
                                    setStep(2)
                                    setIsOtherUploadPhoto(true)
                                    setSelectedTreatment(null)
                                    setSelectedTreatmentId(null)
                                }}

                            >
                                <div className='treatment-actions-content d-flex gap-2'>
                                    <div className='compliance-img-container7'>
                                        <img className='main-img' src={Plus} alt="" />
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span className='my-auto'>Upload photos</span>
                                        <p>Pictures unrelated to treatments.</p>
                                    </div>
                                </div>
                                <div>
                                    <img className='my-auto' src={rightArrow} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            }
        </>
    )
}

export default BatchNumberTreatmentListing