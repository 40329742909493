import React from "react";

import facesLogo from "../../assets/createWebsite/facesLogo.svg";
import './facesLogo.scss';


const FacesLogo = (props) => {
  return (
    <>
      <div className="logoFaces">
        <img src={facesLogo} alt={facesLogo} />
      </div>
    </>
  );
};

export default FacesLogo;
