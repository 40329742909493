import React from 'react'
import './add-new-clinic-main.scss'
import backArrow from '../../images/back_arrow.svg'
import storeImg from '../../images/on-boarding imgs/store.svg'
import rightArrow from '../../images/new-appointment/right_arrow.svg'
import descIcon from '../../images/on-boarding imgs/desc.svg'
import photoIcon from '../../images/placeholder.svg'
import timeIcon from '../../images/Icon example (3).svg'
import { useNavigate } from 'react-router'

const AddNewClinicList = () => {
    const navigate = useNavigate()

    const handleAddNameAndAddress = () => {
        navigate('/add-new-clinic/add-clinic-name')
    }

    const handleDescriptionRoute = () => {
        navigate('/add-description')
    }

    const handleContctDetailsRoute = () => {
        navigate('/clinic-contact-details')
    }
    const handlePhotoRoute = () => {
        navigate('/clinic-photo-upload')
    }
    const handleGoToClinicWorkingHours = () => {
        navigate('/clinic-working-hours')
    }

    return (
        <>
            <div className='container'>
                <div className='header_icon mt-4'>
                    <img src={backArrow} alt='backArrow' />
                </div>
                <div className='add_new_clinic_main'>
                    <div className='add_new_clinic_location'>
                        <h1>Location</h1>
                        <p>Let’s set up your first location. You can add more later. </p>
                        <div className='add_clinic_list' onClick={handleAddNameAndAddress}>
                            <div className='list_item_left'>
                                <span className='list_item_left_icon_bg'>
                                    <img src={storeImg} alt='location' />
                                </span>
                                <p>Name, Address</p>
                            </div>
                            <div className='d-flex'>
                                <div className='btn'>Added</div>
                                <img src={rightArrow} alt='location' />
                            </div>
                        </div>

                        <div className='add_clinic_list' onClick={handleGoToClinicWorkingHours}>
                            <div className='list_item_left'>
                                <span className='list_item_left_icon_bg'>
                                    <img src={timeIcon} alt='location' />
                                </span>
                                <p>Working hours</p>
                            </div>
                            <div className='d-flex'>

                                <img src={rightArrow} alt='location' />
                            </div>
                        </div>

                        <div className='add_clinic_list' onClick={handleDescriptionRoute}>
                            <div className='list_item_left'>
                                <span className='list_item_left_icon_bg'>
                                    <img src={descIcon} alt='location' />
                                </span>
                                <p>Description</p>
                            </div>
                            <div className='d-flex'>

                                <img src={rightArrow} alt='location' />
                            </div>
                        </div>

                        <div className='add_clinic_list' onClick={handleContctDetailsRoute}>
                            <div className='list_item_left'>
                                <span className='list_item_left_icon_bg'>
                                    <img src={storeImg} alt='location' />
                                </span>
                                <p>Contact Details</p>
                            </div>
                            <div className='d-flex'>

                                <img src={rightArrow} alt='location' />
                            </div>
                        </div>

                        <div className='add_clinic_list' onClick={handlePhotoRoute}>
                            <div className='list_item_left'>
                                <img src={photoIcon} alt='location' />
                                {/* <span className='list_item_left_icon_bg'>
                                    <img src={photoIcon} alt='location' />
                                </span> */}
                                <p>Photos</p>
                            </div>
                            <div className='d-flex'>
                                <img src={rightArrow} alt='location' />
                            </div>
                        </div>

                        <div className='add_clinic_list'>
                            <div className='list_item_left'>
                                <span className='list_item_left_icon_bg'>
                                    <img src={storeImg} alt='location' />
                                </span>
                                <p>Color</p>
                            </div>
                            <div className='d-flex'>
                                <img src={rightArrow} alt='location' />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AddNewClinicList
