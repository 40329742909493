import React ,{useEffect} from 'react'
import './OrderSummary.scss'
import HairProductCard from '../hair-product-card/HairProductCard'
import { Link } from 'react-router-dom'
import HairProductCardNew from '../hair-product-card-new/HairProductCardNew'

const OrderSummary = ({ intervalMonth, dueAmount, active, selectedCard, allAnswers, onEditStep, pagesize, isAmend = false }) => {

    console.log('selectedCard?.lowPrice', isAmend)
    
    useEffect(()=>{
        window.scrollTo(0, 0);
     },[])
    return (
        <div className="hair-product-summary">

            <h1 className='order-summary-heading'>Order Summary</h1>
            {
                !isAmend && <>
                    {selectedCard && <>
                        {active === 6 ?
                            <HairProductCardNew
                                icon={selectedCard?.image}
                                title={selectedCard?.name}
                                desc={selectedCard?.description}
                                price={dueAmount}
                                onemonthprice={selectedCard?.price}
                                threemonthprice={selectedCard?.threemonthPrice}
                                interval={intervalMonth}
                                minicard={true}
                                isSummary={true}
                                selected={selectedCard}
                            />
                            :
                            <HairProductCardNew
                                icon={selectedCard?.image}
                                title={selectedCard?.name}
                                desc={selectedCard?.description}
                                price={selectedCard?.lowPrice}
                                onemonthprice={selectedCard?.price}
                                threemonthprice={selectedCard?.threemonthPrice}
                                // interval={selectedCard?.interval}
                                interval={intervalMonth}
                                minicard={true}
                                isSummary={true}
                                selected={selectedCard }
                            />
                        }
                    </>
                    }
                </>
            }
            {/* <div>
                {allAnswers.map((group, groupIndex) => (
                    <div key={groupIndex}>
                        {group.map((answer, index) => (
                            <div key={index}>
                                <p>Question: {answer.question}</p>
                                <p>Answer: {answer.answer}</p>
                                <p>Comment: {answer.comment}</p>
                            </div>
                        ))}
                    </div>
                ))}
            </div> */}
            {allAnswers.map((group, groupIndex) => (
                <div className="answers-container">

                    <div className='questions-header'>
                        <p className='questions-main-heading'>Questions: Step {groupIndex + 1}</p>
                        {active === 6 ? "" : <button className='edit-link' onClick={() => onEditStep(groupIndex)} >Edit </button> }
                    </div>
                    <div className='answers-content'>
                        {group.map((answer, index) => (
                            <div className='answer'>
                                <p className='sub-question' >{index + (groupIndex * pagesize) + 1} . {answer.question}</p>
                                <p className='sub-answer'>Ans: <b>{answer.answer}</b></p>
                            </div>
                        ))}
                    </div>
                </div>

            ))}
        </div >

    )
}

export default OrderSummary