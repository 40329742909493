import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const SAVE_AFTERCARE_DOCUMENTS_APPOINTMENTS = gql`
mutation SaveAftercareDocumentsOfAppointment($appointmentId: Int, $aftercareDocumentIds: [Int]) {
  saveAftercareDocumentsOfAppointment(appointmentId: $appointmentId, aftercareDocumentIds: $aftercareDocumentIds) {
    success
    message
  }
}
`;


export const SaveAftercareDocumentsAppointmentAction = () => {
    const dispatch = useDispatch();
    const [
        SaveAftercareDocumentsAppointmentResponseFunction,
        saveAftercareDocumentsResponseData,
    ] = useMutation(SAVE_AFTERCARE_DOCUMENTS_APPOINTMENTS)

    //this will be called from components
    const aftercareDocumentsQueryData = saveAftercareDocumentsResponseData; //variable

    const initSaveAftercareDocumentsAppointmentData = (data, successCallback, errorCallback) => {
        SaveAftercareDocumentsAppointmentResponseFunction({
            variables: data,
        }).then(({ data: { saveAftercareDocumentsOfAppointment: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback(responseData)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
            // toast.error("something went wrong!!!")
        })
    }

    return { aftercareDocumentsQueryData, initSaveAftercareDocumentsAppointmentData };
};