import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const UPLOAD_PERSONAL_WEBSITE_IMAGE_MUTATION = gql`
mutation UploadPersonalWebsiteImage($image: Upload!, $imageType: Int) {
    uploadPersonalWebsiteImage(image: $image, image_type: $imageType) {
      success
      message
      data {
        image {
          name
          image_url
          time
        }
      }
    }
  }
`;

export const UploadPersonalWebsiteImage = () => {
  const [uploadPersonalWebsiteImageRequest, uploadPersonalWebsiteImageResponseData] =
    useMutation(UPLOAD_PERSONAL_WEBSITE_IMAGE_MUTATION);
  const dispatch = useDispatch();
  const uploadPersonalWebsiteImageMutationData = uploadPersonalWebsiteImageResponseData;

  const initiateuploadPersonalWebsiteImage = (
    data,
    successCallback,
    errorCallback
  ) => {
    uploadPersonalWebsiteImageRequest({
      variables: data,
    })
      .then(({ data: { uploadPersonalWebsiteImage: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        // errorCallback(error);
      });
  };
  return { initiateuploadPersonalWebsiteImage, uploadPersonalWebsiteImageMutationData };
};
