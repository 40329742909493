import React from 'react'
import { ReactComponent as EditIcon } from '../../../images/icons/appointment-list/EditAppointmentIcon.svg'
import { ReactComponent as DeleteIcon } from '../../../images/icons/appointment-list/delete.svg'
import { ReactComponent as DownloadIcon } from '../../../images/icons/appointment-list/download.svg'
import { ReactComponent as ViewIcon } from '../../../images/icons/appointment-list/view.svg'
import { ReactComponent as AppointmentOverviewIcon } from '../../../images/icons/appointment-list/AppointmentOverviewIcon.svg'
import { ReactComponent as ResendBookingAndConsentIcon } from '../../../images/icons/appointment-list/ResendBookingAndConsent.svg'
import "./appointment-view-popup.scss"
import { ReactComponent as EyeIcon } from "../../../images/icons/circleEyeIcon.svg"
import { ReactComponent as PaymentIcon } from "../../../images/icons/appointment-list/PaymentIcon.svg"
import { ReactComponent as ConsentformIcon } from '../../../images/icons/appointment-list/ConsentFormEditIcon.svg'


const AppointmentViewPopup = ({isPaymentPopUpOpen,setIsPaymentPopUpOpen, handleMenuClick, isFilled, type, paymentTagData, consentFormTagData }) => {

    const menuData = [
        {
            title: "Payment",
            icon: <PaymentIcon width={40} />,
        },
        {
            title: "Consent form",
            icon: <ConsentformIcon width={40} />,
        },
        {
            title: "Appointment overview",
            icon: <AppointmentOverviewIcon width={40} />,
        },
        {
            title: "Resend booking & consent",
            icon: <ResendBookingAndConsentIcon width={40} />,
        },
        {
            title: "Edit or reschedule",
            icon: <EditIcon width={40} />,
        },
        {
            title: "Download invoice",
            icon: <DownloadIcon width={40} />,

        },
        {
            title: "Cancel appointment",
            icon: <DeleteIcon width={40} />,
        },
    ]

    const blockoutmenuData = [
        {
            title: "View or edit event",
            icon: <EyeIcon width={40} />,
        },
        {
            title: "Delete event",
            icon: <DeleteIcon width={40} />,
        },
    ]

    return (
        <>
            <div className='mt-4 appointment-popup'
            >
                {
                    type == "Appointment" ? menuData.
                        filter((items) => {
                            if (isFilled) {
                                // If isFilled is true, hide "Resend booking details"
                                return items.title !== 'Resend booking & consent';
                            }
                            else {
                                // If isFilled is false, hide "View consent"
                                return items
                            }
                        }).
                        map((items, index) => (
                            <>
                                <div className='d-flex justify-content-between align-items-center flex-start mt-2' onClick={() => { handleMenuClick(items?.title) }} style={(items?.title == "Payment" && paymentTagData?.isPaymentDone) || (items?.title == "Consent form" && consentFormTagData?.consentFormCount === 0) ? { pointerEvents: "none" } : { cursor: "pointer" }}>
                                    <div className="d-flex gap-3" style={(items?.title == "Payment" && paymentTagData?.isPaymentDone) || (items?.title == "Consent form" && consentFormTagData?.consentFormCount === 0) ? { opacity: 0.4, pointerEvents: "none" } : { opacity: 1, cursor: "pointer" }}>
                                        <div>
                                            {items.icon}
                                        </div>
                                        <div className='my-auto data'>
                                            <h2>{items.title}</h2>
                                        </div>
                                    </div>
                                    {/* <div>
                                        <img src={rightArrow} />
                                    </div> */}
                                    {(items?.title == "Payment") &&
                                        <div className={`status-badge`} style={{ background: `${paymentTagData?.paymentTagColor}` }} >
                                            <p>{paymentTagData?.paymentTag}</p>
                                        </div>
                                    }
                                    {(items?.title == "Consent form") &&
                                        <div className={`status-badge`} style={{ background: `${consentFormTagData?.consentFormTagColor}` }}>
                                            <p>{consentFormTagData?.consentFormTag}</p>
                                        </div>
                                    }
                                </div>
                            </>
                        ))

                        :
                        blockoutmenuData.map((items, index) => (
                            <div className='d-flex justify-content-between align-items-center flex-start mt-2 cursor-pointer' key={index} onClick={() => { handleMenuClick(items?.title, true); }}>
                                <div className='d-flex gap-3'>
                                    <div>
                                        {items.icon}
                                    </div>
                                    <div className='my-auto data'>
                                        <h2>{items.title}</h2>
                                    </div>
                                </div>
                            </div>
                        ))
                }
            </div>
        </>
    )
}

export default AppointmentViewPopup
