import React, { useState } from 'react'
import './photo-step.scss'
import resetToDefault from '../../../../../../assets/images/facial-mapping/photo-demo.png'
import uploadPhoto from '../../../../../../assets/images/facial-mapping/facial-map-take-photo.png'
import Undo from '../../../../../../assets/images/facial-mapping/undo.png'
import StepButton from '../../../../../common/StepButton'
import Dropzone from 'react-dropzone'
import toast from 'react-hot-toast'
import CustomModal from '../../../../../modal/CustomModal'
import WarningModalContent from '../../warninng-modal-content/WarningModalContent'

import defaultImage from '../../../../../../assets/images/facial-mapping/defaultImage.png'
import Webcam from 'react-webcam'
import showToast from '../../../../../common/CustomToast/CustomToast'


const PhotoStep = ({ onGoBack, onFileSelect }) => {

    // RESET WARNINING MODAL
    const [resetModal, setRestModal] = useState(false)

    // TAKE PHOTO MODAL
    const [takePhotoModal, setTakePhotoModal] = useState(false)

    const videoConstraints = {
        width: 240,
        height: 180,
        facingMode: "user"
    };


    const handleFileUpload = (acceptedFiles, index, key) => {
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

        // Check if there are any non-image files
        const nonImageFiles = acceptedFiles.filter(file => !file.type.startsWith('image/'));

        // If there are non-image files, display an error message
        if (nonImageFiles.length > 0) {
            showToast("error", "Please select only image files...", "", false)
            return; // Stop further processing
        }


        onFileSelect(URL?.createObjectURL(imageFiles[0]))
    };
    return (
        <>
            <div className='photoStepMainDiv'>
                <div className="row">
                    <div className='col-md-4'>
                        <div className='photoImageDiv' onClick={() => setTakePhotoModal(true)}>
                            <img src={uploadPhoto} alt="uploadPhoto" />
                            <p>Take Photo</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <Dropzone
                            onDrop={files => handleFileUpload(files)}
                            maxFiles={6}
                            accept="image/*"
                        >
                            {({ getRootProps, getInputProps, isDragActive }) => (
                                <div {...getRootProps()} className={`formDropzoneUploading ${isDragActive ? 'formDropzoneUploading-active' : ''}`}>
                                    <input {...getInputProps()} />
                                    <div className='photoImageDiv'>
                                        <img src={resetToDefault} alt="resetToDefault" />
                                        <p>Upload Photo</p>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    <div className="col-md-4">
                        <div className='photoImageDiv'
                            onClick={() => setRestModal(true)}
                        >
                            <img src={Undo} alt="Undo" />
                            <p>Reset to Default Photo</p>
                        </div>
                    </div>

                </div>
                <StepButton label={"Cancel"} className={"w-25"} blue={true} onClick={() => onGoBack()} />

            </div>


            {/* RESET WARNINING MODAL */}
            <CustomModal
                modalOpen={resetModal}
                setModalOpen={setRestModal}
                className={'CustomeFacialMapping'}
                modaltitle={"Facial Mapping"}
                modaltitleCSS={"discount-title"}
                type={"common-width"}
                modalBody={< WarningModalContent
                    warningMessage={"Are you sure you want to reset to default photo?"}
                    successCallback={() => {
                        onFileSelect(null)
                    }}
                    cancelCallback={() => setRestModal(false)}
                />}
            />

            {/* TAKE PHOTO MODAL */}
            <CustomModal
                modalOpen={takePhotoModal}
                setModalOpen={setTakePhotoModal}
                className={'CustomeCaptureImage'}
                modaltitle={"Capture Image"}
                modaltitleCSS={"discount-title"}
                type={"common-width"}
                modalBody={
                    <>
                        <div className='d-flex justify-content-center align-items-center flex-column mt-3' >
                            <Webcam
                                audio={false}
                                height={350}
                                screenshotFormat="image/jpeg"
                                width={450}
                                videoConstraints={videoConstraints}
                            >
                                {({ getScreenshot }) => (
                                    <StepButton label={"Take photo"} className={"w-25"} blue={true} onClick={() =>
                                        onFileSelect(getScreenshot())
                                    } />
                                )}
                            </Webcam>
                        </div>
                    </>
                }
            />


        </>

    )
}

export default PhotoStep