import React, { useState } from 'react';
import { Modal, ModalBody, Progress } from 'reactstrap';
import backArrow from '../../../images/back_arrow.svg';
import closeIcon from '../../../images/close_icon.svg';
import StepButton from '../../common/StepButton';
import photoIcon from '../../../images/icons/photo.svg';
import threeDots from '../../../images/icons/three_dots.svg';
import { useNavigate } from 'react-router';

const UploadedImagePrew = ({ selectedFiles, setSelectedFiles, currentStep, setCurrentStep }) => {
    const navigate = useNavigate();
    const toggle = () => setModal(!modal);
    const [modal, setModal] = useState(false);
    const [modalCover, setModalCover] = useState(false);
    const toggleCoverModal = () => setModalCover(!modalCover);
    const [selectedNormalPhotoIndex, setSelectedNormalPhotoIndex] = useState(null);

    const handleBack = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleGoToDashBoard = () => {
        navigate('/');
    };

    const handleSwap = () => {
        if (selectedNormalPhotoIndex !== null && selectedNormalPhotoIndex !== 0 && selectedNormalPhotoIndex < selectedFiles.length) {
            const updatedImages = [...selectedFiles];
            [updatedImages[0], updatedImages[selectedNormalPhotoIndex]] = [updatedImages[selectedNormalPhotoIndex], updatedImages[0]];
            setSelectedFiles(updatedImages);
        }
        toggle();
    };

    const handleSwapFromGalaryPhoto = () => {
        if (selectedNormalPhotoIndex !== null && selectedNormalPhotoIndex !== 0 && selectedNormalPhotoIndex < selectedFiles.length) {
            const updatedImages = [...selectedFiles];
            [updatedImages[0], updatedImages[selectedNormalPhotoIndex]] = [updatedImages[selectedNormalPhotoIndex], updatedImages[0]];
            setSelectedFiles(updatedImages);
        }
        toggleCoverModal(); // Close the modal after swapping
    };

    const handleDelete = () => {
        if (selectedNormalPhotoIndex !== null && selectedNormalPhotoIndex >= 0 && selectedNormalPhotoIndex < selectedFiles.length) {
            const updatedImages = [...selectedFiles];
            if (selectedNormalPhotoIndex === 0) {
                // Cover photo is being deleted, remove it and reset cover index
                updatedImages.shift();
                setSelectedNormalPhotoIndex(null);
            } else {
                // Normal photo is being deleted, remove it
                updatedImages.splice(selectedNormalPhotoIndex, 1);
            }
            setSelectedFiles(updatedImages);
        }
        toggle();
    };

    const handleDeleteCoverPhoto = () => {
        if (selectedFiles.length > 1) {
            const updatedImages = selectedFiles.slice(1); // Exclude cover photo
            setSelectedFiles(updatedImages);
            setSelectedNormalPhotoIndex(null);
        } else {
            setSelectedFiles([]);
            setSelectedNormalPhotoIndex(null);
        }
    
        toggleCoverModal();
    };

    const handleAddMoreImages = (e) => {
        const newImages = [...selectedFiles, ...e.target.files];
        setSelectedFiles(newImages);
    };


    const coverPhoto = selectedFiles[0]; // Extract cover photo separately

    const previewImages = selectedFiles.slice(1).map((file, index) => (
        <div className='col-md-4 mb-1 mt-4' key={file.name}>
            <img
                src={URL.createObjectURL(file)}
                alt={`Image ${index + 1}`}
                style={{ width: '250px', height: '150px', objectFit: 'cover', borderRadius: '10px' }}
            />
            <div>
                <button onClick={() => {
                    setSelectedNormalPhotoIndex(index + 1); // Adjust index since cover photo is not included
                    toggle();
                }}>
                    <img src={threeDots} width={25} height={25} alt='threeDots' />
                </button>
            </div>
        </div>
    ));
    return (
        <>
            <div className='container'>
                <div className='header_icon mt-5'>
                    <img src={backArrow} alt='backArrow' onClick={handleBack} />
                    <Progress className="pregress_bar" value={25} />
                    <img src={closeIcon} alt='closeIcon' onClick={handleGoToDashBoard} />
                </div>
                <div className='uploaded_image_container'>
                    <div className='row'>
                        {selectedFiles.length > 0 && (
                            <>
                                <div className='col-md-12 mb-4'>
                                    {coverPhoto && ( // Render cover photo if available
                                        <div className='d-flex justify-content-between cover_image_content'>
                                            <span className=''>Cover Photo</span>
                                            <div className='image_cover' onClick={() => { toggleCoverModal() }}>
                                                <img src={threeDots} alt='threeDots' />
                                            </div>
                                        </div>
                                    )}
                                    {coverPhoto && ( // Render cover photo if available
                                        <img
                                            src={URL.createObjectURL(coverPhoto)}
                                            alt={`Image 1`}
                                            style={{ height: '300px' }}
                                        />
                                    )}
                                </div>
                                {previewImages}
                                <div className='add_more_img' onChange={handleAddMoreImages}>
                                    <label htmlFor="addMoreInput">
                                        <img src={photoIcon} alt='photoIcon' />
                                        <p>Add more</p>
                                    </label>
                                    <input type="file" id="addMoreInput" multiple style={{ display: 'none' }} />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className='mt-4 step_btn'>
                    <StepButton label={'Continue'} blue={true} />
                </div>
            </div>

            {/* Modal */}
            <Modal isOpen={modal} toggle={toggle} contentClassName='custom-modal-content' centered>
                <ModalBody className='modal_body_content'>
                    <button size='sm' className='mr-2 pt-3' onClick={handleSwap}>
                        Make cover photo
                    </button>
                    <button size='sm' className='text-danger pb-3' onClick={handleDelete}>
                        Delete
                    </button>
                </ModalBody>
            </Modal>

            {/* cover photo modal */}
            <Modal isOpen={modalCover} toggle={toggleCoverModal} contentClassName='custom-modal-content' centered>
                <ModalBody className='modal_body_content'>
                    <button size='sm' className='mr-2 pt-3' onClick={handleSwapFromGalaryPhoto}>
                        Choose from photo library
                    </button>
                    <button size='sm' className='text-danger pb-3' onClick={handleDeleteCoverPhoto}>
                        Delete
                    </button>
                </ModalBody>
            </Modal>
        </>
    );
};

export default UploadedImagePrew;
