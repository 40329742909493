import React, { useEffect, useRef, useState } from "react";
import "./PrescriberHome.scss";
import { useLocation } from "react-router-dom";
import { ReactComponent as AboutYouIcon } from "../../assets/prescriber-registration/about_you.svg";
import { ReactComponent as AboutBusinessIcon } from "../../assets/prescriber-registration/about_business.svg";
import { ReactComponent as ConsentIcon } from "../../assets/prescriber-registration/consent.svg";
import { ReactComponent as PartnerClinicIcon } from "../../assets/prescriber-registration/partner_clinic.svg";
import { ReactComponent as PharmaciesIcon } from "../../assets/prescriber-registration/pharmacies.svg";
import { ReactComponent as DocIcon } from "../../assets/prescriber-registration/documents.svg";
import { ReactComponent as Currency } from "../../assets/finance-registration/currency.svg";
import { ReactComponent as zero } from "../../assets/prescriber-registration/zero%.svg";
import { ReactComponent as PrescribeIcon } from "../../assets/prescriber-registration/prescribe.svg";
import { config } from "../../config";
import FinanceHeader from "../finance-registration/FinanceHomeComponent/FinanceHeaderComponent/FinanceHeaderComponent";
import PrescriberAbout from "./about/PrescriberAbout";
import PrescriberDetails from "./business-details/PrescriberDetails";
import Document from "../pages/documet/document";
import FinanceBenefitCard from "../finance-registration/FinanceHomeComponent/FinanceRightCardComponent/FinanceRightCardComponent";
import PrescriberTermsAndConditions from "../pages/prescriber-tnc/PrescriberTermsAndConditions";
import FinanceCard from "../finance-registration/FinanceHomeComponent/FinanceLeftCardComponent/FinanceLeftCardComponent";
import FinanceBanner from "../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner";
import PrescriberPreferences from "../pages/Prescriber/prescriberPreferences";
import ThankYou from "../pages/thankyou-registration";
import { GetUserRole } from "../redux/actions/pharmacy-registration/userRole";
import { GetUserRegistrationSteps } from "../redux/actions/pharmacy-registration/registrationSteps";
import PrescriberGetPaid from "../pages/prescriberGetPaid/PrescriberGetPaid";
import { webBaseUrl } from "../../config";
import { useNavigate } from "react-router-dom";
import SkeletonLoader from "../common/Skeleton/SkeletonLoader";
import PrescriberBank from "../pages/Prescriber-bank-component/PrescriberBank";
import { businessDashboardRoute, getManageAccountPage, shopSettingRoute } from "../../Routes";
import Insurancebanner from "../common/insurancebanner/Insurancebanner";

const PrescriberHome = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const insuranceExpired = queryParams.get("insuranceexpired");
  const pinExpired = queryParams.get("pinexpired");
  const { initGetUserRole, data: roleData } = GetUserRole();
  const { initGetUserRegistrationSteps, data: registrationStepsData } =
    GetUserRegistrationSteps();
  const WhatsappLinkRedirect = config.PHARMACY_WHATSAPP_LINK;
  const [active, setActive] = useState(1);
  const [finish, setFinish] = useState(0);
  const [docStep, setDocStep] = useState(1);
  const [prescriberStep, setPrescriberStep] = useState(1);
  const [role, setRole] = useState("");
  const [isPaid, setIsPaid] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showPayl8trPage, setShowPayl8rPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const businessContainerRef = useRef()
  const prescribeContainerRef = useRef()
  const navigate = useNavigate();
  const documentContainerRef = useRef()

  useEffect(() => {
    initGetUserRole();
    initGetUserRegistrationSteps();
  }, []);

  useEffect(() => {
    if (roleData?.getUserRole?.data?.user_type) {
      setRole(roleData?.getUserRole?.data?.user_type);
    }
  }, [roleData]);

  useEffect(() => {
    // if (registrationStepsData?.getPrescriberRegistrationSteps?.data) {
    //   const { steps, termsAndConditionsApprove } =
    //     registrationStepsData?.getPrescriberRegistrationSteps?.data;
    //   let completedStepsCount = steps.reduce(
    //     (acc, step) => acc + (step.is_completed ? 1 : 0),
    //     0
    //   );
    //   if (termsAndConditionsApprove) {
    //     completedStepsCount++;
    //   }
    //   setFinish(completedStepsCount);
    // }
    setIsLoading(false);
    setFinish(registrationStepsData?.getPrescriberRegistrationSteps?.data?.onboardingCompletionPercentage)
    setIsEdit(
      registrationStepsData?.getPrescriberRegistrationSteps?.data
        ?.isRequestSentToAdmin
    );
  }, [registrationStepsData]);

  useEffect(() => {
    if (!isLoading && registrationStepsData) {
      const steps = registrationStepsData?.getPrescriberRegistrationSteps?.data?.steps;
      if (steps) {
        const completedSteps = steps.filter(step => step.is_completed);
        if (completedSteps.length > 0) {
          // Find the first incomplete step after the completed step
          const nextStep = steps.find(step => !step.is_completed);
          console.log(nextStep, "Completed")
          if (nextStep) {
            // Scroll to the container of the next step based on its key
            switch (nextStep.key) {
              case "businessDetails":
                businessContainerRef?.current?.scrollIntoView({ behavior: "smooth" });
                break;
              case "documentations":
                documentContainerRef?.current?.scrollIntoView({ behavior: "smooth" });
                break;
              case "prescribeOnFaces":
                prescribeContainerRef?.current?.scrollIntoView({ behavior: "smooth" });

                break;
              default:
                break;
            }
          }
        }
      }
    }
  }, [isLoading, registrationStepsData, businessContainerRef, prescribeContainerRef, documentContainerRef]);


  useEffect(() => {
    if (active === 5 && role !== "Prescriber") {
      setActive(4);
    }
    if (active === 1) {
      setDocStep(1)
      setPrescriberStep(1)
    }
  }, [active]);

  useEffect(() => {
    if (insuranceExpired) {
      setActive(4);
      setDocStep(3);
      setTimeout(() => {
        removeQueryParams();
      }, 3000);
    }
    if (pinExpired) {
      setActive(3);
      setTimeout(() => {
        removeQueryParams();
      }, 3000);
    }
  }, [insuranceExpired, pinExpired]);

  const removeQueryParams = () => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete("insuranceexpired");
    newUrl.searchParams.delete("pinexpired");
    window.history.replaceState({}, "", newUrl);
  };

  const handleAboutYouPage = () => {
    setActive(2);
  };
  const handleBusinessDetailsPage = () => {
    setActive(3);
  };
  const handleDocumentationPage = () => {
    setActive(4);
  };
  const handlePrescriberPreferencePage = () => {
    setActive(5);
  };

  const handleBackClick = () => {
    if (location?.state?.from === "shop_Pharmacies_settings") {

      navigate(shopSettingRoute(), { state: { from: 'shop_Pharmacies_settings_shop_settings' } })
    }
    else if (location?.state?.from === "plus_icon_header_bar") {
      navigate(businessDashboardRoute())
    }

    else if (active > 1) {
      if (active === 4) {
        // If Document component is active
        if (docStep > 1) {
          setDocStep(docStep - 1); // Go back to the previous docStep within Document
        } else {
          setActive(3); // Go back to the previous docStep (PrescriberDetails)
        }
      } else if (active === 5) {
        // If preference component is active
        if (prescriberStep > 1) {
          setPrescriberStep(prescriberStep - 1); // Go back to the previous prescriberStep
        } else {
          setDocStep(3);
          setActive(4);
        }
      } else {
        setActive(active - 1);
      }
    }
    else {
      window.location.href =
        webBaseUrl + "/user/update-profile?active-tab=generalprofile11";
    }
  };

  return (
    <div className="prescriber-home-container">
      <Insurancebanner />
      {active !== 8 && active !== 9 && (
        <FinanceHeader knowmore={true} onBackClick={handleBackClick} isTranningLink={(active === 4 && docStep === 2) ? true : false} fromPharmacy={true} />
      )}
      {active === 1 && (
        <>
          <FinanceBanner
            title={"Faces Pharmacy "}
            desc={
              "Welcome to faces pharmacy marketplace, a place to purchase stock from multiple UK aesthetic pharmacies and beauty suppliers. Connect with prescribers and get around the clock support from the faces team."
            }
            isBorder={
              !registrationStepsData?.getPrescriberRegistrationSteps?.data
                ?.isRequestSentToAdmin
            }
            isAdd={true}
            icon={Currency}
            uppercontent="FREE £50 when you join"
            lowercontent="*£50 to spend when you spend over £150."
            currentStep={finish}
          // totalSteps={4}
          />
          <div className="WrapperBox">
            <div className="row justify-content-between flex-column flex-md-row">
              <div className="col col-md-6">
                <div className="prescriber-left-card-container">
                  {!isLoading ? (
                    <>
                      {registrationStepsData?.getPrescriberRegistrationSteps?.data?.steps?.map(
                        (val, index) => {
                          let iconComponent;
                          let onClickHandler;
                          let ref;
                          switch (val.key) {
                            case "basicDetails":
                              iconComponent = AboutYouIcon;
                              onClickHandler = handleAboutYouPage;
                              break;
                            case "documentations":
                              iconComponent = DocIcon;
                              onClickHandler = handleDocumentationPage;
                              ref = documentContainerRef;
                              break;
                            case "prescribeOnFaces":
                              iconComponent = PrescribeIcon;
                              onClickHandler = handlePrescriberPreferencePage;
                              ref = prescribeContainerRef;
                              break;
                            case "businessDetails":
                              iconComponent = AboutBusinessIcon;
                              onClickHandler = handleBusinessDetailsPage;
                              ref = businessContainerRef
                              break;
                            default:
                              iconComponent = AboutYouIcon;
                              onClickHandler = () => { };
                              break;
                          }

                          const isFirstStep = index === 0;
                          const isPreviousStepCompleted =
                            index > 0
                              ? registrationStepsData
                                .getPrescriberRegistrationSteps.data.steps[
                                index - 1
                              ].is_completed
                              : true;
                          const isDisabled = isFirstStep
                            ? false
                            : !isPreviousStepCompleted;

                          return (
                            <FinanceCard
                              refProp={ref}
                              key={val.key}
                              icon={iconComponent}
                              title={val?.title}
                              content={val?.description}
                              buttonText={
                                val?.is_completed
                                  ? "Edit"
                                  : val.key === "basicDetails"
                                    ? "Get started"
                                    : "Add"
                              }
                              onClick={onClickHandler}
                              checked={val?.is_completed}
                              isDisabled={isDisabled}
                              bluebutton={!isDisabled && !val?.is_completed}
                            />
                          );
                        }
                      )}
                    </>
                  ) : (
                    <>
                      <SkeletonLoader type="PharmacyHomeSkeleton" />
                      <SkeletonLoader type="PharmacyHomeSkeleton" />
                      <SkeletonLoader type="PharmacyHomeSkeleton" />
                    </>
                  )}
                </div>

                <div className="whatsapp-container mobile-none">
                  <label className="mb-3">Need help?</label>
                  <button
                    onClick={() => {
                      window.open(WhatsappLinkRedirect, "_blank");
                    }}
                  >
                    <i className="fa fa-whatsapp" aria-hidden="true"></i>
                    Whatsapp us
                  </button>
                </div>
              </div>

              <div className="col col-md-5">
                <div className="prescriber-right-card-container">
                  <FinanceBenefitCard
                    icon={PartnerClinicIcon}
                    title="Prescribers and prescribing"
                    content="Connect with prescribers in your area, prescribers connect with practitioners that need help with prescriptions."
                  />

                  <FinanceBenefitCard
                    icon={PharmaciesIcon}
                    title="Price comparison on multiple pharmacies"
                    content="Find the stock you need at the best price and fastest delivery."
                  />

                  <FinanceBenefitCard
                    icon={ConsentIcon}
                    title="Integrated consent & medical  "
                    content="Attach client consent and medical forms to prescriptions so all information is in one place."
                  />

                  <FinanceBenefitCard
                    icon={zero}
                    title="0% interest on Buy Now Payl8r "
                    content="Buy now pay later. Buying products with our 14 day 0% free payment options has never been easier."
                  />
                </div>
              </div>

              <div className="whatsapp-container desktop-none">
                <label className="mb-3">Need help?</label>
                <button>
                  <i className="fa fa-whatsapp" aria-hidden="true"></i>Whatsapp
                  us
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {active === 2 && (
        <PrescriberAbout
          onNextStep={() => setActive(3)}
          afterSubmit={() => initGetUserRegistrationSteps()}
          role={role}
          finish={finish}
          isRequestSentToAdmin={registrationStepsData?.getPrescriberRegistrationSteps?.data
            ?.isRequestSentToAdmin}
        />
      )}
      {active === 3 && (
        <PrescriberDetails
          onNextStep={() => setActive(4)}
          afterSubmit={() => {
            initGetUserRole();
            initGetUserRegistrationSteps();
          }}
          role={role}
          finish={finish}
          isRequestSentToAdmin={registrationStepsData?.getPrescriberRegistrationSteps?.data
            ?.isRequestSentToAdmin}
        />
      )}
      {active === 4 && (
        <Document
          active={docStep}
          setActive={setDocStep}
          onNextStep={() =>
            role === "Prescriber"
              ? setActive(5)
              : registrationStepsData?.getPrescriberRegistrationSteps?.data
                ?.isRequestSentToAdmin
                ? setActive(1)
                : setActive(6)
          }
          afterSubmit={() => initGetUserRegistrationSteps()}
          role={role}
          finish={finish}
          isRequestSentToAdmin={registrationStepsData?.getPrescriberRegistrationSteps?.data
            ?.isRequestSentToAdmin}
        />
      )}
      {active === 5 && (
        <PrescriberPreferences
          isOpen={prescriberStep}
          setIsOpen={setPrescriberStep}
          onNextStep={() =>
            registrationStepsData?.getPrescriberRegistrationSteps?.data
              ?.isRequestSentToAdmin
              ? setActive(1)
              : setActive(6)
          }
          afterSubmit={() => initGetUserRegistrationSteps()}
          role={role}
          isEdit={isEdit}
          finish={finish}
          isRequestSentToAdmin={registrationStepsData?.getPrescriberRegistrationSteps?.data
            ?.isRequestSentToAdmin}
        />
      )}
      {active === 6 && (
        <PrescriberTermsAndConditions
          // onNextStep={() => {
          //   if (showPayl8trPage) {
          //     console.log("here 1 showPayl8trPage");
          //     setActive(7);
          //   } else if (isPaid) {
          //     console.log("here 2 showPayl8trPage");
          //     setActive(8);
          //   } else {
          //     console.log("here 3 showPayl8trPage");
          //     setActive(9);
          //   }
          // }}
          onNextStep={() => setActive(9)}
          afterSubmit={() => initGetUserRegistrationSteps()}
          setIsPaid={setIsPaid}
          finish={finish}
          setShowPayl8rPage={setShowPayl8rPage}
          showPayl8trPage={showPayl8trPage}
          isPaid={isPaid}
          showCheckBox={
            registrationStepsData?.getPrescriberRegistrationSteps?.data
              ?.showCheckBox
          }
          isRequestSentToAdmin={registrationStepsData?.getPrescriberRegistrationSteps?.data
            ?.isRequestSentToAdmin}
        />
      )}
      {active === 7 && (
        <PrescriberBank
          onNextStep={() => {
            isPaid ? setActive(8) : setActive(9);
          }}
          afterSubmit={() => initGetUserRegistrationSteps()}
        />
      )}
      {active === 8 && (
        <PrescriberGetPaid
          onNextStep={() => setActive(9)}
          afterSubmit={() => initGetUserRegistrationSteps()}
          finish={finish}
          isRequestSentToAdmin={registrationStepsData?.getPrescriberRegistrationSteps?.data
            ?.isRequestSentToAdmin}
        />
      )}
      {active === 9 && (
        <div className="min-vh-100 d-flex align-items-center justify-content-center">
          <ThankYou
            onNextStep={() => setActive(1)}
            afterSubmit={() => initGetUserRegistrationSteps()}
          />
        </div>
      )}
    </div>
  );
};

export default PrescriberHome;
