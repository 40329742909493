import "./walk-in-main.scss";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
import React, { useEffect, useState } from 'react'
import BackArrow from "../../images/back_arrow.svg";
import searcIcon from "../../images/search_icon.svg";
import { useDispatch, useSelector } from 'react-redux';
import rightArrow from "../../images/calander_next.svg"
import LeftArrow from "../../images/calander_previous.svg"
import { GetClinicLocationsAction } from '../redux/actions/walk-in-form/getClientList';
import { SET_WALK_IN_CLINIC_LOCATION, SET_WALK_IN_LOCATION_CLINIC_ID } from '../redux/constants/walk-in-form-const/clientWalkInConst';
import StepButton from "../common/StepButton";
import NavHeader from "../common/NavHeader/NavHeader";

const ChooseLocationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedLocationClinicId, setSelectedLocationClinicId] = useState(null);
  const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
  const [currentPaginationLimit, setCurrentPaginationLimit] = useState(10);
  const [currentPaginationSearch, setCurrentPaginationSearch] = useState('');

  const { initGetClinicLocationsData, getClinicLocationsQueryData } = GetClinicLocationsAction();

  const locationListData = useSelector(state => state?.walkInClientListReducer?.clinicLocationList)
  console.log(locationListData, 'locationListData==')

  useEffect(() => {
    initGetClinicLocationsData({
      page: currentPaginationPage,
      limit: currentPaginationLimit,
      search: currentPaginationSearch,
    });
  }, [currentPaginationPage, currentPaginationLimit, currentPaginationSearch]);

  const nextArrow = () => {
    return (<img src={rightArrow} width={25} height={25} alt="rightArrow" />)
  }
  const prevArrow = () => {
    return (<img src={LeftArrow} width={25} height={25} alt="LeftArrow" />)
  }

  const handleSearchLocationData = (e) => {
    setCurrentPaginationSearch(e.target.value);
    initGetClinicLocationsData({
      page: currentPaginationPage,
      limit: currentPaginationLimit,
      search: e.target.value,
    });
  }

  const itemsPerPage = currentPaginationLimit;
  const totalItems = locationListData?.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginationPages = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationPages.push(i);
  }



  const handleSelectLocation = (locationName) => {
    setSelectedLocation(locationName === selectedLocation ? null : locationName);
    dispatch({
      type: SET_WALK_IN_CLINIC_LOCATION,
      payload: locationName,
    });
  };

  const handleSelectLocationClinicId = (clinicId) => {
    setSelectedLocationClinicId(clinicId === selectedLocationClinicId ? null : clinicId);
    dispatch({
      type: SET_WALK_IN_LOCATION_CLINIC_ID,
      payload: clinicId,
    });
  };

  const isLocationSelected = (locationName) => {
    return locationName === selectedLocation;
  };
  const selectedData = useSelector((state) => state?.walkInClientNameReducer);

  const handleBackWalkInPage = () => {
    navigate("/walk-in-consent");
  };

  const handleConsentFillPage = () => {
    navigate("/walk-in-consent");
  }


  return (
    <div className="walk-in-consent-Main-form">
        <NavHeader isBackOnly={true} handleOnBack={handleBackWalkInPage} />

      <div className='SectionHeading'>
        <div className="Section-Title">Location</div>
        <div className="search-tab">
              <img src={searcIcon} alt="search" />
              <input type="text" placeholder="Search name, email or phone" onChange={handleSearchLocationData} />
            </div>
      </div>
      <div className="walk-in-consent-page-main">
        {/* <div className="d-flex header-add-form">
        </div> */}
        <div className="walk-in-consent-page">
          <div className="walk-in-consent-page-header">
           
          </div>

          <div className="appointment-client-sec">
            <div className="client-titel">
              <p>{locationListData?.companyName}</p>
            </div>
            <div className="GridLocation">
              {locationListData?.items?.map((item, index) => {
                return (
                  <div className="GridLocationItem" key={index} onClick={() => (handleSelectLocation(item?.clinicName), handleSelectLocationClinicId(item?.clinicId))}>
                    <div className='GridLocationItemLeftContent'>
                      <div className="location_profile_image">
                        <img src={item?.imageUrl} alt="" />
                      </div>
                      <div className="user-details">
                        <h5>{item?.clinicName}</h5>
                        <p>{item?.completeAddress}</p>
                      </div>
                    </div>
                    <input
                      className="black-checkbox"
                      type="checkbox"
                      checked={selectedData?.clinicLocationClinicId === item?.clinicId ? true : false}
                      style={{ background: "red" }}
                    />
                  </div>
                )
              }
              )}
            </div>
            <div className="form-btn">
              <StepButton label={"Selected"} blue={true} onClick={handleBackWalkInPage} />
            </div>
            <ReactPaginate
              previousLabel={prevArrow()}
              nextLabel={nextArrow()}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(page) =>
                setCurrentPaginationPage(page.selected + 1)
              }
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
              breakClassName={"pagination__break"}
              breakLinkClassName={"pagination__break-link"}
              pageClassName={"pagination__page"}
              pageLinkClassName={"pagination__page-link"}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

export default ChooseLocationPage
