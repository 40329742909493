import React, { useRef } from 'react'
import { ReactComponent as CloseIcon } from "../../../images/close_icon.svg"
import { ReactComponent as BackArrow } from "../../../images/back_arrow.svg"
import './nav-header.scss'
import gsap from "gsap";
import StepButton from '../StepButton';
import ProgressBar from '@ramonak/react-progress-bar';

const NavHeader = ({ onClickCallback, btnLabel, isClose, isBackWithButton, handleOnBack, isBackOnly, applyPadding, isGray = false, disabled, isLoading, isSticky = false, isCloseOnly = false, isGrayColorButton,isBackWithCloseAndProgressBar,isBackInvisible=false,isBorder=false,progressbarStatus }) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <div className={`container-fluid gutter-0 ${applyPadding ? "pb-5" : ""}`} style={isGray ? { background: "#f9f9f9 !important" } : { background: "white !important" }}>
      <div className={` ${applyPadding ? "nav-header-container" : isSticky ? "pt-3" : "nav-header-without-padding-container"} ${isBorder?"border-header":""}`}>
        {
          isBackWithButton &&
          <div className={`${isGray ? 'close-nav-gray-back' : 'close-nav-back'} d-flex justify-content-between align-items-center`}>
            <BackArrow onClick={() => handleOnBack()} style={{ cursor: "pointer" }} />
            <div>
              <StepButton isLoading={isLoading} disabled={disabled} blue={isGrayColorButton ? false : true} gray={isGrayColorButton ? true : false} className={"w-100 mt-0"} onClick={() => onClickCallback()} label={btnLabel} />
            </div>
          </div>

        }

{
          isBackWithCloseAndProgressBar &&
          <div className={`${isGray ? 'close-nav-gray-back' : 'close-nav-back'} d-flex justify-content-between align-items-center`}>
            { <BackArrow onClick={() => handleOnBack()} style={{
              cursor: "pointer",
              ...(isBackInvisible && {visibility:"hidden"}),              
            }} />}
              <div className="progressbar">
                <ProgressBar completed={progressbarStatus} bgColor={"blue"} isLabelVisible={false} width='100%' height='8px' />
            </div>
            <div>
            {/* <CloseIcon className='close-icon-animation'
              onClick={() => onClickCallback()} cursor={"pointer"} style={{ width: 40, height: 40 }}
            /> */}
            </div>
          </div>
        }
        {
          (isClose || isCloseOnly) &&
          <div className={`close-nav-back d-flex ${isCloseOnly ? 'justify-content-start' : 'justify-content-end'} pl-5 pb-5 ${applyPadding ? 'pt-5' : ''}`} style={applyPadding ? { marginTop: "20px" } : {}} >
            <CloseIcon className='close-icon-animation'
              onClick={() => onClickCallback()} cursor={"pointer"} style={{ width: 40, height: 40 }}
            />
          </div>
        }
        {
          isBackOnly &&
          <div className={`close-nav-back d-flex justify-content-between ${isSticky ? "position-sticky top-0 left-0 p-0" : ""}`}>
            <BackArrow onClick={() => handleOnBack()} style={{ cursor: "pointer" }} />
          </div>
        }
      </div>
    </div>
  )
}

export default NavHeader