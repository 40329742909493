import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const SAVE_PRESCRIBER_PROFILE_PIC_MUTATION = gql`
  mutation SavePrescriberProfilePic($image: Upload) {
    savePrescriberProfilePic(image: $image) {
      success
      message
    }
  }
`;

export const PrescriberProfilePic = () => {
  const [savePrescriberProfilePic, { data: savePrescriberProfilePicResponseData }] =
    useMutation(SAVE_PRESCRIBER_PROFILE_PIC_MUTATION);
  const dispatch = useDispatch();

  const initiateSavePrescriberProfilePic = (variables, successCallback, errorCallback) => {
    savePrescriberProfilePic({ variables })
      .then(({ data }) => {
        if (data.savePrescriberProfilePic.success) {
          successCallback(data.savePrescriberProfilePic);
        } else {
          errorCallback(data.savePrescriberProfilePic);
        }
      })
      .catch((error) => {
        // Handle error
        errorCallback(error);
      });
  };

  return { initiateSavePrescriberProfilePic, savePrescriberProfilePicResponseData };
};

const DELETE_PRESCRIBER_PROFILE_PIC_MUTATION = gql`
mutation DeletePrescriberProfilePic {
    deletePrescriberProfilePic {
      success
      message
    }
  }
`

export const DeletePrescriberProfilePic = () => {
    const [deletePrescriberProfilePic, { data: deletePrescriberProfilePicResponseData }] =
      useMutation(DELETE_PRESCRIBER_PROFILE_PIC_MUTATION);
    const dispatch = useDispatch();

    const initiateDeletePrescriberProfilePic = (successCallback, errorCallback) => {
      deletePrescriberProfilePic()
       .then(({ data }) => {
          if (data.deletePrescriberProfilePic.success) {
            successCallback(data.deletePrescriberProfilePic);
          } else {
            errorCallback(data.deletePrescriberProfilePic);
          }
        })
       .catch((error) => {
          // Handle error
          errorCallback(error);
        });
    };

    return { initiateDeletePrescriberProfilePic, deletePrescriberProfilePicResponseData };
  
};