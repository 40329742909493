import { all } from "@redux-saga/core/effects";
import { getInsuranceDataWatcher } from "./insurance/insurance";
import {
  getSupportChatDataWatcher,
  getThreadChatDataWatcher,
  getSupportChatCategoriesDataWatcher,
  createSupportThreadsDataWatcher,
  getSupportRecentOrdersDataWatcher,
  getOrderTraceDataWatcher,
  createSupportChatThreadsDataWatcher,
  supportThreadsAddFeedbackDataWatcher,
  supportThreadsChatUpdateDataWatcher,
  supportThreadMessagesMarkReadWatcher,
  supportChatTotalUnreadCountDataWatcher,
} from "./support-chat/support-chat";

import { getInsuranceCompanyDataWatcher, getInsuranceCompanyPageDataWatcher } from "./insurance-company/insurance-company";
import { connectStripeExpressWatcher, getOnboardingStatusWatcher, updateOccupationsWatcher } from "./dashboard/onboarding";
import { getDashboardListWatcher } from "./dashboard/dashboardList";
import { getProductsGetCartCountDataWatcher } from "./dashboard/getCartCount";
import { getAppointmentListWatcher } from "./dashboard/appointmentList";
import { getProfileDataWatcher, logoutProfileWatcher } from "./profile/Profile";
import { addReviewsDataWatcher, forWardSendMailDataWatcher, getClientsDataWatcher, getReviewsDataWatcher } from "./dashboard/Reviews";
import { getAppointmentDetailsWatcher } from "./dashboard/appointmentDetails";
import { cancelAppointmentWatcher } from "./dashboard/cancelAppointment";
import { resendForwardConsentWatcher } from "./dashboard/forms";
import { renewInsuranceWatcher } from "./insurance/renewInsurance";
import { getPrescriptionCountWatcher } from "./prescription/prescription";
import { getPaylaterStatusWatcher } from './pay-later/index';
import { invoiceDownloadPageWatcher } from "./invoice/Invoice";
import { invoiceDownloadWatcher } from "./invoice/downloadInvoice";

export default function* rootSaga() {
  yield all([
    getInsuranceDataWatcher(),
    getSupportChatDataWatcher(),
    getThreadChatDataWatcher(),
    getSupportChatCategoriesDataWatcher(),
    createSupportThreadsDataWatcher(),
    getSupportRecentOrdersDataWatcher(),
    getOrderTraceDataWatcher(),
    createSupportChatThreadsDataWatcher(),
    supportThreadsAddFeedbackDataWatcher(),
    supportThreadsChatUpdateDataWatcher(),
    supportThreadMessagesMarkReadWatcher(),
    supportChatTotalUnreadCountDataWatcher(),
    getInsuranceCompanyDataWatcher(),
    getInsuranceCompanyPageDataWatcher(),
    // getOnboardingStatusWatcher(),
    // getDashboardListWatcher(),
    getProductsGetCartCountDataWatcher(),
    // getAppointmentListWatcher(),
    // getProfileDataWatcher(),
    // getReviewsDataWatcher(),
    // updateOccupationsWatcher(),
    // getClientsDataWatcher(),
    // forWardSendMailDataWatcher(),
    // getAppointmentDetailsWatcher(),
    // cancelAppointmentWatcher(),
    // resendForwardConsentWatcher(),
    // addReviewsDataWatcher(),
    logoutProfileWatcher(),
    getPrescriptionCountWatcher(),
    // connectStripeExpressWatcher(),
    renewInsuranceWatcher(),
    getPaylaterStatusWatcher(),
    invoiceDownloadWatcher(),
    invoiceDownloadPageWatcher()
  ]);
}
