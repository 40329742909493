import React, { useState, useEffect } from "react";

import trendingup from "../../../../src/assets/createWebsite/trending-up.svg";
import trendingdown from "../../../../src/assets/createWebsite/trending-down.svg";
import RightArrow from "../../../../src/assets/createWebsite/Right.svg";
import CopyImage from "../../../images/copyicon.svg";
import shareicon from "../../../images/shareicon.svg"
import { Form, FormGroup, Label } from "reactstrap";
import CountUp from 'react-countup';
import Skeleton from "react-loading-skeleton";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader"

import "./ReEntryDashboard.scss";
import StepButton from "../../common/StepButton";
import { useNavigate } from "react-router";
import { businessDashboardRoutePattern } from "../../../Routes";
import { GetWebsiteBuilderDashboardAction } from "../../redux/actions/createWebsite/getWebsiteBuilderDashboard";
import backIcon from "../../../images/back_arrow.svg";
import { LuRefreshCw } from "react-icons/lu";
import { socket } from "../../socket";
import { formatCount } from "../../../utils/general";
import no_treatment from "../../../images/no_treatment.png"
import pluIcon from "../../../images/plusIcon.png"
import no_page from "../../../images/no_page.png"
import { useSelector } from "react-redux";


const ReEntryDashboard = (props) => {
    const navigate = useNavigate();
    const {
        initGetWebsiteBuilderResponseFunction,
        getWebsiteBuilderDashboardResponseData: { data: dashboardData, loading: dashboardLoading },
    } = GetWebsiteBuilderDashboardAction();
    const websitePrefilledData = useSelector(
        (state) => state.WebsiteDetailsReducer.websitePrefilledData
    );

    const [type, setType] = useState(1);
    const [active, setActive] = useState(true)

    console.log("type", typeof parseInt(type))

    const [count, setCount] = useState({
        total_page_views_count: 0, total_no_click_count: 0, unique_visitors_count: 0, treatments: [], page: []
    })

    useEffect(() => {
        initGetWebsiteBuilderResponseFunction({
            type: parseInt(type),
        });
    }, [type]);

    const [socketUpdate, setSocketUpdate] = useState({
        total_page_views_count: false,
        total_no_click_count: false,
        unique_visitors_count: false,
        page_type: "",
        treatment_name: ""
    })


    useEffect(() => {

        const updateTopPageList = () => {
            let pageArray = count?.page ? [...count.page] : [];
            if (socketUpdate?.page_type) {
                const itemIndexPage = pageArray.findIndex((item) => item.title === socketUpdate?.page_type);
                if (itemIndexPage == -1) {
                    pageArray.push({ title: socketUpdate?.page_type, views: 1 })
                } else {
                    return (count?.page || []).map((v) => {
                        if (v.title === socketUpdate?.page_type) {
                            return { ...v, views: Number(v.views) + 1 }
                        } else {
                            return v
                        }
                    })
                }
                return pageArray
            }
            return pageArray
        }


        const updateTopTreatments = () => {
            let arr = count?.treatments ? [...count.treatments] : [];
            if (socketUpdate?.treatment_name) {
                const itemIndex = arr.findIndex((item) => item.title === socketUpdate?.treatment_name);
                if (itemIndex == -1) {
                    arr.push({ title: socketUpdate?.treatment_name, views: 1 });
                }
                else {
                    return (count?.treatments || []).map((item) => {
                        if (item?.title === socketUpdate?.treatment_name) {
                            return { ...item, views: Number(item.views) + 1 }
                        }
                        else {
                            return item
                        }
                    })
                }
                return arr;
            }
            return arr
        }


        if (!!socketUpdate?.total_page_views_count || !!socketUpdate.total_no_click_count || !!socketUpdate.unique_visitors_count || !!socketUpdate?.page_type || !!socketUpdate?.treatment_name) {
            setCount(prevCount => ({
                ...prevCount,
                total_page_views_count: socketUpdate.total_page_views_count ? Number(prevCount.total_page_views_count) + 1 : prevCount.total_page_views_count,
                total_no_click_count: socketUpdate.total_no_click_count ? Number(prevCount.total_no_click_count) + 1 : prevCount.total_no_click_count,
                unique_visitors_count: socketUpdate.unique_visitors_count ? Number(prevCount.unique_visitors_count) + 1 : prevCount.unique_visitors_count,
                page: updateTopPageList(),
                treatments: updateTopTreatments()

            }));

            setSocketUpdate({
                total_page_views_count: false,
                total_no_click_count: false,
                unique_visitors_count: false,
                page_type: "",
                treatment_name: ""
            });
        }
    }, [socketUpdate])

    useEffect(() => {
        setCount({
            ...count, total_page_views_count: dashboardData?.getWebsiteBuilderDashboard?.data?.total_page_views_count,
            total_no_click_count: dashboardData?.getWebsiteBuilderDashboard?.data?.total_no_click_count,
            unique_visitors_count: dashboardData?.getWebsiteBuilderDashboard?.data?.unique_visitors_count,
            treatments: dashboardData?.getWebsiteBuilderDashboard?.data?.popular_treatments,
            page: dashboardData?.getWebsiteBuilderDashboard?.data?.top_pages
        })
    }, [dashboardData])

    const onHandleChangeType = (e) => {
        setType(e.target.value);
        setActive(true)
    };

    const handleCopyLink = () => {
        const url = document.getElementById("businessName").value;
        navigator.clipboard.writeText(url);
    };

    const onRefreshProfile = () => {
        initGetWebsiteBuilderResponseFunction({
            type: parseInt(type),
        });
        setActive(true)
    }

    useEffect(() => {
        console.log("new_message 111", socket)
        socket.on('new_message', (event) => {
            console.log('new_message_222', event)
            if (event.type === "website_tracking") {
                setSocketUpdate({
                    ...socketUpdate,
                    total_page_views_count: event?.dataObj?.total_page_views_count,
                    total_no_click_count: event?.dataObj?.total_no_click_count,
                    unique_visitors_count: event?.dataObj?.unique_visitors_count,
                    page_type: !!event?.dataObj?.page_type ? event?.dataObj?.page_type : "",
                    treatment_name: !!event?.dataObj?.treatment_name ? event?.dataObj?.treatment_name : ""
                })
                setActive(false)
            }
        })
        const onDisconnect = (error) => {
            console.log('disconnected', error)
            socket.off('new_message')
        }
        return () => {
            socket.off('disconnect', onDisconnect)
            // socket.disconnect()
        }
    }, [])


    console.log("dashboardData", dashboardData)

    return (
        <div className="reEnter-DashBoard text-center">
            <div className="container">
                <div className="BackButtonDashBoard"
                    onClick={() => navigate('/business-dashboard')}
                >
                    <img src={backIcon} alt="" />
                </div>
                <div className="dashboardLogo">
                    <div className="logoName">
                        <img src={dashboardData?.getWebsiteBuilderDashboard?.data?.logo_image_url} />
                        <p>{dashboardData?.getWebsiteBuilderDashboard?.data?.business_name || ""}</p>
                    </div>
                    <div className="copyButton">
                        <div className="copyInput">
                            <div className="InputCopyWrapper">
                                <FormGroup className="inpt_field inputField">
                                    <div className="iconInputField">
                                        <input
                                            className="input"
                                            type="text"
                                            name="businessName"
                                            id="businessName"
                                            value={
                                                dashboardData?.getWebsiteBuilderDashboard?.data?.website_url
                                            }
                                        />
                                    </div>
                                </FormGroup>

                            </div>

                        </div>
                        {/* <button className="copyBtn">
                            <img
                                src={shareicon}
                                width={25}
                                height={25}
                                className="icon"
                            />
                        </button> */}
                        <button className="copyBtn" onClick={handleCopyLink}>
                            <img
                                src={CopyImage}
                                width={25}
                                height={25}
                                className="icon"
                            />
                        </button>

                    </div>

                </div>

                <div className="reEnterHeaderWrapper">
                    <div className="lableCard">
                        <h5>Overview</h5>
                        <p>
                            Last activity :&nbsp;{dashboardLoading ? <Skeleton width={100} /> : dashboardData?.getWebsiteBuilderDashboard?.data?.update_date}
                        </p>
                    </div>
                    <div className="RightDashWrapper">
                        <button className="RefreshButton" onClick={onRefreshProfile}>
                            <LuRefreshCw />
                        </button>
                        <select value={type} onChange={(e) => onHandleChangeType(e)}>
                            <option value={1}>All</option>
                            <option value={2}>Today</option>
                            <option value={3}>Week</option>
                            <option value={4}>Month</option>
                            <option value={5}>Year</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="card">
                            <h2>
                                {active ? <CountUp start={0} end={count?.total_page_views_count || 0} duration={2} formattingFn={formatCount} /> : formatCount(count?.total_page_views_count || 0)}
                            </h2>
                            <div className="totalPageWrapper">
                                <p>Total Page Views</p>
                                {parseInt(type) !== 1 && <div className="presentageChart">
                                    <p>
                                        <CountUp start={0} end={`${dashboardData?.getWebsiteBuilderDashboard?.data
                                            ?.total_page_view_percentage || 0}`} duration={2} />%
                                    </p>

                                    {dashboardData?.getWebsiteBuilderDashboard?.data
                                        ?.total_page_view_percentage > 0 &&
                                        <img src={trendingup} alt={trendingup} />
                                    }
                                    {dashboardData?.getWebsiteBuilderDashboard?.data
                                        ?.total_page_view_percentage < 0 &&
                                        <img src={trendingdown} alt={trendingdown} />
                                    }
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="card">
                            <h2 style={{ color: "#6366F1" }}>
                                {active ? <CountUp start={0} end={count?.total_no_click_count || 0} duration={2} formattingFn={formatCount} /> : formatCount(count?.total_no_click_count || 0)}
                            </h2>
                            <div className="totalPageWrapper">
                                <p>Total No. of Treatment Clicks</p>
                                {parseInt(type) !== 1 && <div className="presentageChart">

                                    <p>
                                        <CountUp start={0} end={`${dashboardData?.getWebsiteBuilderDashboard?.data
                                            ?.total_no_click_percentage || 0}`} duration={2} />%
                                    </p>


                                    {dashboardData?.getWebsiteBuilderDashboard?.data
                                        ?.total_no_click_percentage > 0 &&
                                        <img src={trendingup} alt={trendingup} />
                                    }
                                    {dashboardData?.getWebsiteBuilderDashboard?.data
                                        ?.total_no_click_percentage < 0 &&
                                        <img src={trendingdown} alt={trendingdown} />
                                    }
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="card">
                            <h2 style={{ color: "#2DD4BF" }}>
                                {active ? <CountUp start={0} end={count?.unique_visitors_count || 0} duration={2} formattingFn={formatCount} /> : formatCount(count?.unique_visitors_count)}
                            </h2>
                            <div className="totalPageWrapper">
                                <p>Unique Visitors</p>
                                {parseInt(type) !== 1 && <div className="presentageChart">
                                    <p>
                                        <CountUp start={0} end={dashboardData?.getWebsiteBuilderDashboard?.data
                                            ?.unique_visitors_percentage || 0} duration={2} />
                                        %
                                    </p>
                                    {dashboardData?.getWebsiteBuilderDashboard?.data
                                        ?.unique_visitors_percentage > 0 &&
                                        <img src={trendingup} alt={trendingup} />
                                    }
                                    {dashboardData?.getWebsiteBuilderDashboard?.data
                                        ?.unique_visitors_percentage < 0 &&
                                        <img src={trendingdown} alt={trendingdown} />
                                    }
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pagesCard">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="card">
                                <div className="cardHeader">
                                    <h2>Popular Treatments</h2>
                                    {dashboardData?.getWebsiteBuilderDashboard?.data?.is_treatment === false &&
                                        <button onClick={() => { window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/treatment/create?source=onboarding` }}>
                                            <img src={pluIcon} alt="pluIcon" />
                                            {" "}
                                            Add treatment
                                        </button>}
                                </div>
                                <div className="totalPageWrapper">
                                    {dashboardLoading ? <SkeletonLoader type="dashbaord-list" /> : <div className={count?.treatments?.length > 0 ? "listView" : ""}>
                                        {count?.treatments?.length > 0 ? (
                                            <>
                                                <p>Title</p>
                                                <p>Views</p>
                                            </>
                                        ) : (
                                            <div className="m-auto"><div className="BtnDashBoardTreatementWrapper">
                                                <div className="btnFill">
                                                    <img src={no_treatment} alt="no_treatment" />
                                                    <p>Currently here no any popular treatment.</p>
                                                </div>
                                            </div>
                                            </div>
                                        )}
                                    </div>}

                                    <div className="listPoint">
                                        <ul>
                                            {dashboardLoading ? <SkeletonLoader type="dashbaord-list" /> : count?.treatments?.map(
                                                (item, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <span className="pointName">
                                                                {item.title || ""}
                                                            </span>
                                                            <span className="pointView">
                                                                {active ? <CountUp start={0} end={item?.views || 0} duration={2} formattingFn={formatCount} /> : formatCount(item?.views || "")}
                                                            </span>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="card">
                                <div className="cardHeader">
                                    <h2>Top Pages</h2>
                                    {/* <button>
                    View All
                    <span>
                      <img src={RightArrow} />
                    </span>
                  </button> */}
                                </div>
                                <div className="totalPageWrapper">
                                    {dashboardLoading ? <SkeletonLoader type="dashbaord-list" /> : <div className="listView">
                                        {count?.page?.length > 0 ? (
                                            <>
                                                <p>Title</p>
                                                <p>Views</p>
                                            </>
                                        ) : (
                                            <div className="m-auto"><div className="BtnDashBoardTreatementWrapper">
                                                <div className="btnFill">
                                                    <img src={no_page} alt="no_page" />
                                                    <p>Currently here no any top pages.</p>
                                                </div>
                                            </div>
                                            </div>
                                        )}
                                    </div>}

                                    <div className="listPoint">
                                        <ul>
                                            {dashboardLoading ? <SkeletonLoader type="dashbaord-list" /> : count?.page?.map(
                                                (item, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <span className="pointName">
                                                                {item.title || ""}
                                                            </span>
                                                            <span className="pointView">
                                                                {active ? <CountUp start={0} end={item?.views || 0} duration={2} formattingFn={formatCount} /> : formatCount(item?.views || 0)}
                                                            </span>
                                                        </li>
                                                    );
                                                }
                                            )}
                                            {/* <li>
                        <span className="pointName">/Home</span>
                        <span className="pointView">42</span>
                      </li>
                      <li>
                        <span className="pointName">/contact</span>
                        <span className="pointView">24</span>
                      </li>
                      <li>
                        <span className="pointName">/booking</span>
                        <span className="pointView">16</span>
                      </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="pagesCard">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="card">
                <div className="cardHeader">
                  <h2>Top Sources</h2>
                  <button>
                    View All
                    <span>
                      <img src={RightArrow} />
                    </span>
                  </button>
                </div>
                <div className="totalPageWrapper">
                  <div className="listView">
                    <p>Title</p>
                    <p>Views</p>
                  </div>

                  <div className="listPoint">
                    <ul>
                      <li>
                        <span className="pointName">Blogs</span>
                        <span className="pointView">42</span>
                      </li>
                      <li>
                        <span className="pointName">Youtube Video links</span>
                        <span className="pointView">24</span>
                      </li>
                      <li>
                        <span className="pointName">Google</span>
                        <span className="pointView">16</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

                <div className="BtnDashBoardWrapper">
                    <div className="row">
                        {(websitePrefilledData?.website_build_type == 1 || !websitePrefilledData?.website_build_payment) && <div className="col-sm-12 col-md-6">
                            <div className="btnFill">
                                <StepButton
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.setIsEdit(true);
                                        props.setStep(12);
                                        props.trackActivity({ activityStep: 12 });
                                    }}
                                    label="Edit Your Website"
                                />
                            </div>
                        </div>}
                        <div className={(websitePrefilledData?.website_build_type == 1 || !websitePrefilledData?.website_build_payment)? "col-sm-12 col-md-6" : "col-12"}>
                            <div className="btnOutLine">
                                <StepButton
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                            dashboardData?.getWebsiteBuilderDashboard?.data
                                                ?.website_url
                                        ) {
                                            window.open(
                                                dashboardData?.getWebsiteBuilderDashboard?.data
                                                    ?.website_url,
                                                "_blank"
                                            );
                                        }

                                        props.trackActivity({ activityStep: 12 });
                                    }}
                                    label="View Live Site"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReEntryDashboard;
