export const validName = new RegExp('^[a-zA-Z ]{0,30}$');
export const validAlphaField = new RegExp('^[a-zA-Z ]{0,40}$');
export const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const validUrl = new RegExp('/^(ftp|http|https):[^ "]+([^ "]+)+$/');
// export const validPhone = new RegExp('^[0-9]{10}$');
export const validPhone = /^[0-9]{10,15}$/;
export const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$');
export const validNumber = new RegExp('^[0-9]{10,15}$');
// export const validPincode = new RegExp('^[0-9]{6}$');
// export const validPincode = new RegExp('^(?!.*0{2})[0-9]{6}$');
// export const validPincode = new RegExp('^(?!.*0{2})[0-9]{2}$');
export const validPincode=new RegExp('^(?!.*0{6})[0-9]{6}$')
export const validPersonalWebsiteUrl = /^[a-zA-Z0-9_-]+$/
export const alphaNumericRegexAp = /[^0-9a-zA-Z]/;
export const bookingLinkRegex=/^[a-zA-Z0-9-_]+$/ ;