import React ,{useEffect} from 'react'
import Lottie from 'react-lottie';
import backIcon from "../../../images/back_arrow.svg";
import thankyou from "../../../images/thank-you/success.svg"
import { FaWhatsapp } from "react-icons/fa6";
import thankAnimation from "../../../assets/lottieanimation/Animation - 1712039535881.json"
import "./FinancethankYouPage.scss"
import StepButton from '../../common/StepButton';
import {Link, useNavigate} from "react-router-dom"
import {getFinanceRegistrationRoute} from "../../../Routes"
import {businessDashboardRoutePattern} from "../../../Routes"
import {config} from "../../../config"
 
const FinanceThankYouPage = ({onNextStep,afterSubmit,setCurrentStep}) => {
    const navigate=useNavigate();
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: thankAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
      const WhatsappLinkRedirect=config.FINANCE_WHATSAPP_LINK
  console.log("whatsapp",WhatsappLinkRedirect)

     const handleWhatsappLink=()=>{
       window.open(WhatsappLinkRedirect, "_blank");
    }
    useEffect(()=>{
      window.scrollTo(0, 0);
     },[])
     

    const handleBackToHome=()=>{
navigate(businessDashboardRoutePattern)
        // afterSubmit()
        // onNextStep()
    }
  return (
    <>
       <div className='main-thankyou'>
      {/* <div className='TopHeader'>
        <div className='header-thankyou'>
          <img src={backIcon} alt='backIcon' onClick={() => setCurrentStep(4)} />
        </div>
      </div> */}
      <div className='main-thakyou-body'>
        <div className='main-body'>
          <div className='congration-wrapper-thankyou'>
            <Lottie options={defaultOptions}
              width={"388px"}
              height={"100%"}
            />
          </div>
          <div className='thankyou-body'>
            <div><img src={thankyou} alt='thankyou' /></div>
            <div className='header-text-thankyou'>Thank you</div>
            {/* <div className='context-thankyou'>Thank you for registration</div> */}
            <div className='context-thankyou'>Your registration has now been sent to the finance approval team.
            You will be notified about the status of your account within 48 hours.
            </div>
            {/* <div className='context-thankyou'>You will be notified about the status of your account within 48 hours. */}
             {/* You can message us for any updates or information via whats app on :
              <span onClick={handleWhatsappLink}>07932827345</span>  */}
              {/* </div> */}
            </div>

            <div className="whatsapp-container">
                    <button onClick={() => {
                    window.open(WhatsappLinkRedirect, "_blank");
                  }}>
                      <i className="fa fa-whatsapp" aria-hidden="true"></i>Whatsapp
                      us
                    </button>
         </div>

        </div>
        <div className='backBtn'>
        <StepButton 
            label={"Dashboard"}
            blue={true}
            onClick={handleBackToHome}
        />
      </div>
      </div>
    
    </div>
    </>
  )
}

export default FinanceThankYouPage