import React, { useState, useRef } from 'react';
import './clinic-upload-photo.scss';
import { Progress } from 'reactstrap';
import { useNavigate } from 'react-router';
import backArrow from '../../../images/back_arrow.svg';
import closeIcon from '../../../images/close_icon.svg';
import noIdentyImg from '../../../images/client/no-identity.svg';
import UploadedImagePrew from './UploadedImagePrew';

const UploadPhotoForNewClinic = () => {

    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };
    const handleBack = () => {
        navigate('/add-new-clinic');
    };

    const handleGoToDashBoard = () => {
        navigate('/');
    };

    const handleFileChange = (event) => {
        // Handle file selection
        const files = event.target.files;
        setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
    };

    const handleButtonClick = () => {
        // Trigger the click event of the file input
        fileInputRef.current.click();
    };

    const handleUpload = () => {
        // Handle the file upload logic here
        if (selectedFiles.length > 0) {
            console.log('Uploading files:', selectedFiles);
        } else {
            console.error('No files selected.');
        }
    };
console.log(selectedFiles, 'selectedFiles');
    return (
        <>
            {currentStep === 1 && (
                <div className='container'>
                    <div className='header_icon mt-5'>
                        <img src={backArrow} alt='backArrow' onClick={handleBack} />
                        <Progress className="pregress_bar" value={25} />
                        <img src={closeIcon} alt='closeIcon' onClick={handleGoToDashBoard} />
                    </div>
                    <div className='upload-photo-main'>
                        <div className='img_upload'>
                            <img src={noIdentyImg} alt='upload' />
                            <h1>Showcase your business</h1>
                            <p>Begin by uploading 3 photos that best showcase your work.</p>
                        </div>
                        <div className='upload_btn'>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                multiple
                            />
                            {selectedFiles.length > 0 && (
                                <button onClick={handleUpload && handleNext}>
                                    Upload
                                </button>
                            )}
                            {!selectedFiles.length > 0 && (
                                <button onClick={handleButtonClick}>Upload a photo</button>)
                            }
                        </div>
                    </div>
                </div>
            )}
            {currentStep === 2 && <UploadedImagePrew selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} currentStep={currentStep} setCurrentStep={setCurrentStep} />}

        </>
    );
};

export default UploadPhotoForNewClinic;
