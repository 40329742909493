import React, { useEffect, useRef, useState } from 'react'
import DashboardAppointmentsSkeleton from '../../skeleton/dashboard-skeleton/DashboardAppointmentsSkeleton'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { businessDashboardRoutePattern, getAppointmentListRoutePattern, getAppointmentRoute, getBookinginvoiceRoute, getEditAppointmentRoute, getEditBlockOutTimeRoute } from '../../../Routes'
import CarouselComponent from '../../pages/carousel-component/carousel-component'
import getAppointmentList, { clearAppointmentStatus, getAppointmentDetails } from '../../redux/actions/dashboard/appointments'
import rightArrow from "../../../images/icons/rightArrow.svg";
import leftArrow from "../../../images/icons/leftArrow.svg"
import moment from 'moment'
import { GetAppointmentListAction } from '../../redux/actions/dashboard/appointmentListGql'
import { CHANGE_LOADED_APPOINTMENT_COUNT, GET_DASHBOARD_LIST } from '../../redux/constants'
import AppointmentListCard from '../../common/appointment-list-card/AppointmentListCard'
import CustomModal from '../../modal/CustomModal'
import AppointmentViewPopup from '../../common/appointment-view-popup/AppointmentViewPopup'
import showToast from '../../common/CustomToast/CustomToast'
import { ResendBookingDetailsMutation } from '../../redux/actions/sendReminder/ResendBookingDetails'
import { GetConsentFormPdfUrlQuery } from '../../redux/actions/downloadConsentForm/downloadConsentForm'
import { frontendURL } from '../../../config'
import ConsentActionPopup from '../../consent-action-popup'
import StepButton from '../../common/StepButton'
import ClientFormsViewPopup from "../../common/client-forms-view-popup/ClientFormsViewPopup"
import { CLEAR_PRESCRIBER_LIST } from '../../redux/constants/consentFormThankyou/getPrescriberListConst'
import { CLEAR_PRESCRIPTIONS_LIST } from '../../redux/constants/getPrescriptionsListConst'
import { GetMedicalFormPdfUrlQuery } from '../../redux/actions/downloadMedicalForm/DownloadMedicalForm'
import { ResendMedicalFormAction } from '../../redux/actions/sendReminder/ResendMedicalForm'
import OffCanvasModal from '../../modal/OffCanvasModal/OffCanvasModal'
import { PrescriberList } from '../../Client/prescriber-list/PrescriberList'
import { SendConsentFormOrMedicalFormToPrescriberAction } from '../../redux/actions/send-consent-form-or-medicalform-to-prescriber/SendConsentFormOrMedicalFormToPrescriber'
import { PrescriptionList } from '../../Client/prescription-list/PrescriptionList'
import { UpdatePrescriptionAttachedFormAction } from '../../redux/actions/update-prescription-attached-form/UpdatePrescriptionAttachedForm'

const AppointmentsContainer = ({ dashboardAppointmentListData }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const carouselRef = useRef();
    const [appointmentId, setAppointmentId] = useState(null)
    const popOverRef = useRef(null)
    const [clickedDate, setClickedDate] = useState();
    const [isFilled, setIsFilled] = useState(null)
    const [deleteDataByAppointmentId, setDeleteDataByAppointmentId] = useState();
    const [consentFormRecordId, setConsentFormRecordId] = useState(null)
    const [type, setType] = useState(null)
    const [isAppointmentMenuOpen, setIsAppointmentMenuOpen] = useState(false)
    const [showConsentPopup, setShowConsentPopup] = useState()
    const [confirmationPopupShow, setConfirmationPopupShow] = useState(false);
    const [compliancePopupShow, setCompliancePopupShow] = useState(false);
    const [paymentTagData, setPaymentTagData] = useState(null)
    const [consentFormTagData, setConsentFormTagData] = useState(null)
    const [isPaymentPopUpOpen,setIsPaymentPopUpOpen]=useState(false)
    const [paymentPopUpPrice,setPaymentPopUpPrice]=useState(null)
    const [paymentPopClientName,setPaymentPopClientName]=useState(null)
    const [isFormsMenuOptionsOpen, setIsFormsMenuOptionsOpen] = useState(false)
    const [prescriberId, setPrescriberId] = useState(null)
    const [isPrescriberModalOpen, setIsPrescriberModalOpen] = useState(false)
    const [isNoPharmacyRegisterModalOpen, setIsNoPharmacyRegisterModalOpen] = useState(false)
    const [clientId,setClientId]=useState(null)
    const [isPrescriptionModalOpen, setIsPrescriptionModalOpen] = useState(false)
    const [tempShowSelectedPrescriber, setTempShowSelectedPrescriber] = useState(null)
    const [consentActive,setConsentActive]=useState("Consent")

    const redirectUrl = `${frontendURL}${businessDashboardRoutePattern}`
    const encodedRedirectUrl = encodeURIComponent(redirectUrl);

    const { dashboardSkeletonFlag } = useSelector((state) => state.dashboardReducer)
    const clientData = useSelector(state => state.clientConsentFormListReducer?.clientData)


    const { initConsentFormPdfUrl } = GetConsentFormPdfUrlQuery()
    const { initMedicalFormPdfUrl } = GetMedicalFormPdfUrlQuery()
    const { initResendMedicalFormData, resendMedicalFormQueryData } = ResendMedicalFormAction()
    const { initSendConsentFormOrMedicalFormToPrescriberData, sendConsentFormOrMedicalFormToPrescriberQueryData } = SendConsentFormOrMedicalFormToPrescriberAction()
    const getDayForGivenDate = (dateStr) => {

        // Parse the date string into a Moment object
        const givenDate = moment(dateStr, 'DD-MM-YYYY')

        // Get the current date as a Moment object
        const today = moment();

        // Calculate tomorrow's date as a Moment object
        const tomorrow = today.clone().add(1, 'days');
        // Compare the given date with today and tomorrow
        if (givenDate.isSame(today, 'day')) {
            return "Today"
        } else if (givenDate.isSame(tomorrow, 'day')) {
            return "Tomorrow"
        } else {
            return dateStr
        }
    }

    const getTimeForGivenDate = (dateTimeStr) => {
        return moment(dateTimeStr, 'YYYY-MM-DD HH:mm:ss').format("HH:mm")
    }

    const { getAppointmentListQueryData, initGetAppointmentListData } = GetAppointmentListAction();
    const { appointmentListData } = useSelector((state) => state.appointmentReducer);
    const { initiateResendBookingDetailsData ,resendBookingDetailsMutationData} = ResendBookingDetailsMutation()
    const { initUpdatePrescriptionAttachedFormData, updatePrescriptionAttachedFormQueryData } = UpdatePrescriptionAttachedFormAction()
   

    const getAppointmentListData = () => {
        initGetAppointmentListData({
            'appointmentListDayType': "upcoming",
            'limit': 10,
            'page': 1
        });
    }
    useEffect(() => {
        getAppointmentListData();
    }, [])
    

    const downloadForm = (formId) => {
        initConsentFormPdfUrl({
            consentFormRecordId: formId
        }, (res) => {
            console.log(res?.data?.getConsentFormRecordPdfUrl, "download pdf")
            if (res?.data?.getConsentFormRecordPdfUrl?.success) {
                window.open(res?.data?.getConsentFormRecordPdfUrl?.data?.consentFormRecordPdfUrl, "_blank")
                setIsFormsMenuOptionsOpen(false)
            } else {
                showToast("error", res.message, "", false)
            }
        }, (err) => {
            showToast("error", err, "", false)
    
        })
    }

    const sendtoPrescriberHandler = (dataTobePassed) => {

        let data;
    
        if (consentActive === "Consent") {
            data = {
                prescriptionId: dataTobePassed?.prescriptionId,
                clientId: clientId,
                consentFormId: consentFormRecordId,
                attachMedicalForm: dataTobePassed?.attachMedicalForm
            }
        }
        else {
            data = {
                prescriptionId: dataTobePassed?.prescriptionId,
                clientId: clientId,
                consentFormId: 0,
                attachMedicalForm: true,
            }
        }
        initUpdatePrescriptionAttachedFormData(
            data
            , (res) => {
                if (res.success) {
                    showToast("success", res.message, "", false)
                    setIsPrescriptionModalOpen(false)
                    setTempShowSelectedPrescriber(null)
                } else {
                    showToast("error", res.message, "", false)
                    setTempShowSelectedPrescriber(null)
                }
            }, (err) => {
                showToast("error", err, "", false)
            })
    }


    const handleSendReminder = (formId) => {
        initiateResendBookingDetailsData({
            "appointmentId": null,
            "consentFormRecordId": formId
        }, (res) => {
            if (res.success) {
                showToast("success", res.message, "", false)
                setIsFormsMenuOptionsOpen(false)
            } else {
                showToast("error", res.message, "", false)
            }
        }, (err) => {
            showToast("error", err, "", false)
        })
      }
      const downloadMedicalForm = (formId) => {
        initMedicalFormPdfUrl({
            clientId:clientId
        }, (res) => {
            if (res?.data?.getMedicalFormPdfUrl?.success) {
                window.open(res?.data?.getMedicalFormPdfUrl?.data?.medicalFormRecordPdfUrl, "_blank")
                setIsFormsMenuOptionsOpen(false)
            } else {
                showToast("error", res.message, "", false)
            }
        }, (err) => {
            showToast("error", err, "", false)
    
        })
    }
    const handleResendMedicalForm = () => {
        initResendMedicalFormData({
            clientId:clientId
        }, (res) => {
            if (res.success) {
                showToast("success", res.message, "", false)
                setIsFormsMenuOptionsOpen(false)
            } else {
                showToast("error", res.message, "", false)
            }
        }, (err) => {
            showToast("error", err, "", false)
        })
      }
    const handleConsentFormClick=(title)=>{
        switch (title) {
            case "Download PDF":
                if (consentFormRecordId !== null) {
                    downloadForm(consentFormRecordId)
                }
                break;
    
            case "Send to client":
              handleSendReminder(consentFormRecordId)
                break;
    
    
            case "Re-send to client":
              handleSendReminder(consentFormRecordId)
                break;
    
            case "Send to prescriber":
                dispatch({
                    type: CLEAR_PRESCRIBER_LIST
                })
                dispatch({
                    type: CLEAR_PRESCRIPTIONS_LIST
                })
                setPrescriberId(null);
                setIsFormsMenuOptionsOpen(false)
                setIsAppointmentMenuOpen(false)
                if (getAppointmentListQueryData?.data?.getAppointmentList?.data?.vendorIsPharmacy) {
                    // initiatePrescriberListCall(true)
                    setIsPrescriberModalOpen(true)
                }
                else {
                    setIsNoPharmacyRegisterModalOpen(true)
                }
                break;
    
            case "Edit consent form":
                window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
                break;
    
            case "Complete in person":
                window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
                break;
    
            
    
            case "Repeat form":
              window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/duplicate/" + consentFormRecordId + `?documents=true`
                // navigate(rebookAppointmentRoute(appointmentIdData?.appointmentId, clientId), { state: { isFromClientForm: true } })
                break;
    
            case "Download PDF":
              downloadMedicalForm()
              break;
    
          case "Send to client":
              handleResendMedicalForm()
              break;
    
          case "Re-send to client":
              handleResendMedicalForm()
              break;
    
          case "Send to prescriber":
              dispatch({
                  type: CLEAR_PRESCRIBER_LIST
              })
              dispatch({
                  type: CLEAR_PRESCRIPTIONS_LIST
              })
              setPrescriberId(null);
              setIsFormsMenuOptionsOpen(false)
              setIsAppointmentMenuOpen(false)
              if (getAppointmentListQueryData?.data?.getAppointmentList?.data?.vendorIsPharmacy) {
                  // initiatePrescriberListCall(true)
                  setIsPrescriberModalOpen(true)
              }
              else {
                  setIsNoPharmacyRegisterModalOpen(true)
              }
              break;
    
          case "Edit medical form":
              window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`
              break;
    
          case "Complete in person":
              window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`
              break;
    
          default:
              console.log('Default')
              break;
        }
    }
    const sendClickHandler = (selectedPrescriberId) => {
        // let data;
        // if (active === "Consent") {
        //     data = {
        //         consentFormId: consentFormRecordId,
        //         prescriberId: parseInt(selectedPrescriberId)
        //     }
        // }
        // else {
        //     data = {
        //         clientId: clientId,
        //         prescriberId: parseInt(selectedPrescriberId)
        //     }
        // }
        // handleSendConsentFormorMedicalFormToPrescriber(data)
        setIsPrescriberModalOpen(false)
        setPrescriberId(selectedPrescriberId)
        setIsPrescriptionModalOpen(true)
      }
    const onAppointmentClickHandler = (appointmentData) => {
        console.log("list data AAA",appointmentData);
        setAppointmentId(parseInt(appointmentData?.booking_slug));
        setIsFilled(appointmentData?.isFilled);
        setDeleteDataByAppointmentId(appointmentData.cld_rc_id)
        setClickedDate(appointmentData?.calender_date)
        setConsentFormRecordId(appointmentData?.consentFormRecordId)
        setType("Appointment")
        setPaymentTagData({
            paymentTag: appointmentData?.paymentTag,
            paymentTagColor: appointmentData?.paymentTagColor,
            isPaymentDone: appointmentData?.isPaymentDone
        })
        setConsentFormTagData({
            consentFormTag: appointmentData?.consentFormTag,
            consentFormTagColor: appointmentData?.consentFormTagColor,
            consentFormCount: appointmentData?.consentFormCount
        })
        setIsAppointmentMenuOpen(true)
        setPaymentPopUpPrice(appointmentData?.price);
    setPaymentPopClientName(appointmentData?.client_name)
    setClientId(appointmentData?.clientId)
    }

    const handleResendDetails = (formId) => {
        initiateResendBookingDetailsData({
            "appointmentId": formId,
            "consentFormRecordId": null
        }, (res) => {
            if (res.success) {
                // toast.success(res.message)
                showToast("success", res?.message, "", false)
                setIsAppointmentMenuOpen(false)
            } else {

                showToast("error", res?.message, "", false)
            }
        }, (err) => {
            showToast("error", err, "", false)
        })
    }
    const handlePaymentResend=()=>{
        handleResendDetails(appointmentId);
        setIsPaymentPopUpOpen(false)
          }
    const handleMenuClick = (title, isBlockTimeOut = false) => {
        switch (title) {
            // case "View or edit event":
            //     if (moment().isAfter(moment(blockoutEndDate, "YYYY-MM-DD"))) {
            //         showToast("error", "you can't edit this event", "", "")
            //     }
            //     else {
            //         navigate(getEditBlockOutTimeRoute(blockOutId, blockOutDateId))
            //     }
            //     break;

            // case "Delete":
            //     console.log('Delete')

            //     if (moment().isAfter(moment(blockoutEndDate, "YYYY-MM-DD"))) {
            //         showToast("error", "you can't delete this event", "", "")
            //     }
            //     else {
            //         setIsDelete(true)
            //         setIsAppointmentMenuOpen(false)
            //     }
            //     break;
            case "Payment":
                if(paymentTagData?.paymentTag === "Unpaid"){
                    setIsPaymentPopUpOpen(true)
                  }
                // handleResendDetails(appointmentId);
                break;

            case "Edit or reschedule":
                console.log('Edit / reschedule')
                navigate(getEditAppointmentRoute(appointmentId), { state: { fromDashboard: true } })
                break;

            case "Appointment overview":
                navigate(getAppointmentRoute(appointmentId), { state: { fromDashboardAppointment: true } })
                break;

            case "Resend booking & consent":
                console.log('Resend booking details')
                handleResendDetails(appointmentId)
                break;

            case "Download invoice":
                console.log('Download invoice', appointmentId)
                navigate(getBookinginvoiceRoute(), { state: { entityId: appointmentId, selectedDate: clickedDate, fromDashboard: true } })
                break;

            case "Consent form":
                setIsFormsMenuOptionsOpen(true)
                document.body.click()
                // window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
                break;

            case "Cancel appointment":
                setShowConsentPopup(true);
                setConfirmationPopupShow(true);
                setIsAppointmentMenuOpen(false)
                console.log('Delete')
                break;

            default:
                console.log('Default')
                break;
        }

    }

    return (
        <div className='appointments-container-main-div'>
            {
                dashboardSkeletonFlag ?
                    <DashboardAppointmentsSkeleton /> :
                    dashboardAppointmentListData?.length > 0 &&
                    <div className="appointments-container mb-5">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-end gap-2">
                                <h2 className="my-auto">Appointments</h2>
                                <span className="text-decoration-underline cursor-pointer pb-1"
                                    onClick={() => {
                                        // dispatch(getAppointmentList({
                                        //     'appointment-list-day-type': "today",
                                        //     'limit': 5,
                                        //     'page': 1
                                        // }));
                                        // here change
                                        dispatch({ type: CHANGE_LOADED_APPOINTMENT_COUNT })
                                        navigate(getAppointmentListRoutePattern)
                                    }}>View all</span>
                            </div>
                            {
                                dashboardAppointmentListData?.length > 3 &&
                                <div className="arrows my-auto">
                                    <img src={leftArrow} onClick={() => { document.getElementsByClassName("owl-prev")[0].click() }} alt="" className="me-2" />
                                    <img src={rightArrow} onClick={() => { document.getElementsByClassName("owl-next")[0].click() }} alt="" />
                                </div>
                            }
                        </div>

                        <CarouselComponent nav carouselRef={carouselRef}
                            data={
                                dashboardAppointmentListData?.map((item, index) =>
                                    // <div className="appointment-card" onClick={() => { dispatch(clearAppointmentStatus()); navigate(getAppointmentRoute(item?.booking_slug), { state: { bookingId: item?.cld_rc_id } }) }} key={index}>
                                    //     <div className="mb-2">
                                    //         {/* <span className="px-2 py-1 text-capitalize " style={{ background: (item?.deposit_status == "unconfirmed" ? "#C83A25" : item?.deposit_status == "Fully Paid" ? "#1F8342" : "#FF8328") }}>{!(item?.deposit_status == "unconfirmed" || item?.deposit_status == "Fully Paid") && "£"}{item?.deposit_status}</span> */}
                                    //         <span className="px-2 py-1 text-capitalize AppoimentTag " style={{ backgroundColor: item?.deposit_status_color }}>{item?.deposit_status}</span>
                                    //     </div>
                                    //     <div className="client-detail text-capitalize fw-bold" >
                                    //         {item?.client_name} {item?.location_name ? `@ ${item?.location_name}` : ""}
                                    //     </div>
                                    //     <p>{getDayForGivenDate(item?.calender_date)}, {getTimeForGivenDate(item?.cld_rc_start)} - {getTimeForGivenDate(item?.cld_rc_end)}</p>
                                    //     <div className='d-flex'>
                                    //         <p className="client-detail text-capitalize" >{item?.treatment_name} </p>
                                    //         <p className='ms-2'>{(item?.treatment_count ? item.treatment_count > 1 ? `(+${item?.treatment_count})` : "" : "")}</p>

                                    //     </div>
                                    // </div>
                                    <>
                                        <AppointmentListCard onClickCallback={onAppointmentClickHandler} data={item} type="Appointment" isDateDisplay={true} />

                                    </>
                                )
                            }
                            itemsToShow={3}
                            loop={false}
                        />
                    </div>

                // :

                // <div className="fw-bold fs-4 d-flex justify-content-center" >
                //     No Records are Currently Available!
                // </div>
            }
            {
                showConsentPopup ? <ConsentActionPopup confirmationPopupShow={confirmationPopupShow} height={"280px"} setConfirmationPopupShow={setConfirmationPopupShow} setShowConsentPopup={setShowConsentPopup} setCompliancePopupShow={setCompliancePopupShow} compliancePopupShow={compliancePopupShow} elementId={deleteDataByAppointmentId} fromAppointmentListNavigation={false} clickedDate={clickedDate} getAppointmentData={getAppointmentListData} /> : null
            }
            <CustomModal
                modalOpen={isAppointmentMenuOpen}
                // modalOpen={true}
                modaltitle={"Manage appointment"}
                setModalOpen={setIsAppointmentMenuOpen}
                className="width-30 customeWidth customeModelAppointment menudata-container"
                modalBody={
                    <>
                        <AppointmentViewPopup handleMenuClick={handleMenuClick} isFilled={isFilled} type={type} paymentTagData={paymentTagData} consentFormTagData={consentFormTagData} />
                    </>

                }
            />

             {/* payment option popup */}

    <CustomModal 
        type={"common-width"}
        className={"paymentpopup"}
        modalOpen={isPaymentPopUpOpen}
        setModalOpen={setIsPaymentPopUpOpen}
        modalBody={
          <>
            <div className='paymentPopUpWrapper'>
               <h1>Would you like to send a payment link for the value of
              
               £{paymentPopUpPrice} to { paymentPopClientName}?</h1>
            <div>
                  <div className='paymentPopBtn'> 
                    <StepButton blue={true}  label={"Yes"} onClick={()=>handlePaymentResend()} isLoading={resendBookingDetailsMutationData.loading} />
                    <StepButton gray={true} label={"No"} onClick={()=>setIsPaymentPopUpOpen(false)} />
                  </div>
               </div>
            </div>
          </>
        }
      />

       {/* consentForm menu option */}
       <CustomModal
             modalOpen={isFormsMenuOptionsOpen}
            // modalOpen={true}
              modaltitle={"Manage forms"}
              setModalOpen={setIsFormsMenuOptionsOpen}
              className="width-30 customeWidth customeModelAppointment menudata-container"
              modalBody={
              <>
              <ClientFormsViewPopup handleMenuClick={handleConsentFormClick} isFilled={isFilled} active={consentActive}/>
              </>
              }
             />


     <OffCanvasModal
                            isOffCanvasHeader={false}
                            isOpen={isPrescriberModalOpen}
                            placement={"end"}
                            className={"maxCanvasWidth508 SelectPrescriberListModel"}
                            onHide={() => setIsPrescriberModalOpen(false)}
                            // onHide={() => { setIsClientPeofile(false); setIsClientAppointment(false); setActive("upcoming") }}
                            body={<PrescriberList sendClickHandler={sendClickHandler} setIsPrescriberModalOpen={setIsPrescriberModalOpen} sendConsentFormOrMedicalFormToPrescriberQueryData={sendConsentFormOrMedicalFormToPrescriberQueryData} prescriberId={prescriberId} />}
                        />


                        {/* prescription list component */}
                        <OffCanvasModal
                            isOffCanvasHeader={false}
                            isOpen={isPrescriptionModalOpen}
                            placement={"end"}
                            className={"maxCanvasWidth508 SelectPrescriptionModel"}
                            onHide={() => setIsPrescriptionModalOpen(false)}
                            // onHide={() => { setIsClientPeofile(false); setIsClientAppointment(false); setActive("upcoming") }}
                            body={<PrescriptionList sendtoPrescriberHandler={sendtoPrescriberHandler} setIsPrescriberModalOpen={setIsPrescriberModalOpen} updatePrescriptionAttachedFormQueryData={updatePrescriptionAttachedFormQueryData} prescriberId={prescriberId} clientId={clientId} setIsPrescriptionModalOpen={setIsPrescriptionModalOpen} />}
                        />


              {/* no pharmacy registration popup */}
              <CustomModal
                            modalOpen={isNoPharmacyRegisterModalOpen}
                            setModalOpen={setIsNoPharmacyRegisterModalOpen}
                            type={"common-width"}
                            className={'customeNoPharmacyRegisterModel'}
                            modalBody={
                                <>
                                    <div className="share-booking-link-all-clients-confimration">
                                        <p>
                                            Currently you are not register with any pharmacy. Would you like to connect?
                                        </p>
                                        <div className="btnWrapper">
                                            <StepButton label={"Yes"} onClick={() => {
                                                window.location.href = frontendURL + "/prescriber-registration";
                                                setIsNoPharmacyRegisterModalOpen(false)
                                            }} blue={true} />

                                            <StepButton gray={true} label={"No"} onClick={() => {
                                                setIsNoPharmacyRegisterModalOpen(false)
                                            }} />
                                        </div>
                                    </div>
                                </>
                            }
                        />
        </div>
    )
}

export default AppointmentsContainer