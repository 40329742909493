// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { STORE_USER_EMAIL} from "../../constants";



export function setEmail(data) {
  console.log("paylater action",data)
  return {
      type: STORE_USER_EMAIL,
      payload: data

  }
}


const CHECK_STATUSES_FOR_FINANCE_FIRST_STAPE_QUERY = gql`
query CheckStatusesForFinanceRegistration($email: String) {
    checkStatusesForFinanceRegistration(email: $email) {
      success
      message
      data {
        email_exist
        email_verified
        user_finance_request_status
      }
    }
  }
`;

export const CheckUserStatusForFirstStape = () => {
  const dispatch = useDispatch();

  const [
    checkUserStatusForFirstStapeFunction,
    checkUserStatusForFirstStapeResponseData,
  ] = useLazyQuery(CHECK_STATUSES_FOR_FINANCE_FIRST_STAPE_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ checkStatusesForFinanceRegistration: responseData }) => {
      if (responseData.success) {
        // toast.success(responseData?.message)
        dispatch({
          type: STORE_USER_EMAIL,
          payload: responseData.usr_email,
        });
        // dispatch(setEmail(responseData.usr_email));
      } else {
        // toast.error(responseData?.message)
        dispatch({
          type: STORE_USER_EMAIL,
          payload: responseData.usr_email,
        });
      }
    },
  });
  //this will be called from components
  const getUserDetailsForFirstStepdDataQueryData = checkUserStatusForFirstStapeResponseData; //variable

  const initcheckUserStatusForFirstStapeData = (data,successCallback,errorCallback) => {
    checkUserStatusForFirstStapeFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.checkStatusesForFinanceRegistration?.success) {
        successCallback(res);
      } else {
        console.log('res error', res);
        errorCallback(res);
      }
    }).catch((err) => {
      console.log('res err', err);
      errorCallback(err);
    });
  };

  return { getUserDetailsForFirstStepdDataQueryData, initcheckUserStatusForFirstStapeData };
};
