import { gql, useMutation } from "@apollo/client";

const DELETE_FINANCE_IDENTITY=gql`
mutation DeleteIdentityDocumentForFinance($usrVrfId: Int!) {
    deleteIdentityDocumentForFinance(usr_vrf_id: $usrVrfId) {
      success
      message
    }
  }
`;


export const DeleteFinanceIdentityAction=()=>{
    const [
        deleteFinanceIdentityResponseFunction,
        deleteFinanceIdentityResponseData]=useMutation(DELETE_FINANCE_IDENTITY)

    const deleteFinanceIdentityQueryData=deleteFinanceIdentityResponseData;

    const initDeleteFinanceIdentityData=(data, successCallback, errorCallback)=>{
        deleteFinanceIdentityResponseFunction({
            variables:data,

        }).then((responseData)=>{
            console.log(responseData,"res")
              if(responseData.data.deleteIdentityDocumentForFinance.success){
                successCallback(responseData)
              }else{
                errorCallback(responseData)
              }
        }).catch((error)=>{
            errorCallback(error)
        })
    }

    return {deleteFinanceIdentityQueryData,initDeleteFinanceIdentityData}
}