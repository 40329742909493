import { gql, useMutation } from "@apollo/client";

const DELETE_USER_ADDRESS_MUTATION = gql`
  mutation DeleteUserAddress($addressId: Int) {
    deleteUserAddress(addressId: $addressId) {
      success
      message
    }
  }
`;

export const useDeleteUserAddressMutation = () => {
  const [deleteUserAddress, { data, loading, error }] = useMutation(DELETE_USER_ADDRESS_MUTATION);

  const initiateDeleteUserAddress = (variables, successCallback, errorCallback) => {
    deleteUserAddress({ variables })
      .then(({ data: { deleteUserAddress: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  return { initiateDeleteUserAddress, data, loading, error };
};
