import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_APPOINTMENT_LIST_BY_TYPE } from "../../constants/appointment/appointmentConst";

const GET_APPOINTMENT_LIST_QUERY = gql`
query GetAppointmentList($page: Int, $limit: Int, $appointmentListDayType: String, $clientId: Int, $startDate: String, $endDate: String, $locations: [Int], $status: [Int], $includeBlockouts: Boolean, $search: String) {
  getAppointmentList(page: $page, limit: $limit, appointment_list_day_type: $appointmentListDayType, client_id: $clientId, start_date: $startDate, end_date: $endDate, locations: $locations, status: $status, includeBlockouts: $includeBlockouts, search: $search) {
    success
    message
    data {
      total_appointments
      next_appointment_date
      vendorIsPharmacy
      appointment_list {
        cld_rc_id
        client_name
        cld_rc_start
        cld_rc_end
        location_name
        treatment_name
        treatment_count
        clinic_color
        deposit_status
        deposit_status_color
        calender_date
        booking_slug
        treatmentIds
        isFilled
        clientId
        type
        blockoutStartDate
        blockoutEndDate
        blockOutId
        blockOutDateId
        appointmentTitle
        consentFormRecordId
        totalDuration
        price
        paymentTag
        paymentTagColor
        paymentType
        consentFormTag
        consentFormTagColor
        isPaymentDone
        consentFormCount
      }
    }
  }
}
`;

var page;
export const GetAppointmentListByDayType = () => {
  const dispatch = useDispatch();
  const [getAppointmentListData, appointmentListData] = useLazyQuery(
    GET_APPOINTMENT_LIST_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getAppointmentList: responseData }) => {
        dispatch({
          type: GET_APPOINTMENT_LIST_BY_TYPE,
          payload: responseData.data,
          page,
        });
      },
    }
  );
  const appointmentListQueryData = appointmentListData;
  const initGetAppointmentListByDayType = (data, successCallback, errorCallback) => {
    page = data?.page ? data?.page : 1;
    getAppointmentListData({
      variables: data,
    }).then((responseData) => {
      if (responseData.data.getAppointmentList.success) {
        console.log("sucesss", responseData.data.getAppointmentList)
        successCallback(responseData.data.getAppointmentList)
      }
      else {
        if (errorCallback) {
          errorCallback(responseData)
        }
      }
    }).catch((err) => {
      if (errorCallback) { errorCallback(err) }


    })
  };
  return { initGetAppointmentListByDayType, appointmentListQueryData };
};
