export const emailPattern =
  /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

export const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const usernamePattern =
  /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;

export const urlPattern =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const numberRegex =/^[0-9]*$/;

export const alphaRegex = /^[a-zA-Z]*$/; // regex for alphabets without space

export const alphaRegexSpace = /^[a-zA-Z ]*$/; // regex for alphabets with space

export const alphaNumericRegexSpace = /^[a-zA-Z0-9 ]*$/; // regex for alphabets and number without space

export const alphaNumericRegex = /^[a-zA-Z0-9 ]*$/; // regex for alphabets and number with space

export const alphaNumericRegexAp=/[^0-9a-zA-Z]/;

export  const facebookRegex = /^[a-zA-Z0-9.]{5,50}$/;

export  const instagramRegex = /^[a-zA-Z0-9_.]{1,30}$/;




