
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_PRODUCT_QUESTIONS=gql`
query GetProductQuestions($productId: Int, $slug: String, $sessionId: String) {
  getProductQuestions(productId: $productId, slug: $slug, sessionId: $sessionId) {
    success
    message
    data {
      totalAnsweredQuestions
      questions {
        draftId
        id
        type
        question_text
        options {
          id
          option_text
          isCorrect
        }
      }
    }
  }
}
`;

export const GetProductQuestionsAction=()=>{

    const [getProductQuestionResponseFunction,
    getProductQuestionResponseData]=useLazyQuery(GET_PRODUCT_QUESTIONS,{
        fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted:({getProductQuestions:responseData})=>{
        if (responseData.success) {
            // toast.success(responseData?.message)
          } else {
            // toast.error(responseData?.message)
          }
    }
    })

    const getProductQuestionQueryData=getProductQuestionResponseData;

    const initGetProductQuestionData=(data)=>{
        getProductQuestionResponseFunction({
            variables:data
        })
    };

    return {getProductQuestionQueryData,initGetProductQuestionData}
}