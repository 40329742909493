import React from 'react'
import './bannerHeader.scss'

const BannerHeader = ({ title, subTitle, companyName,...props }) => {
  return (
    <div className='banner-header-main pt-4 pb-1'>
      {
        companyName ?
          <>
            <h1> 👋 Welcome to <span className='company-name'>{companyName}</span></h1>
            <p className='pt-2 ps-2'>{subTitle}</p>

          </>
          :
          <>
            {props.topHeader && 

              <div style={{height: 43, paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10, background: '#E3E3E3', borderRadius: 6, alignItems: 'center', gap: 10, display: 'inline-flex',marginBottom:'20px'}}>
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                <path d="M8.30625 13.4188H9.7875V8.75003H8.30625V13.4188ZM8.99966 7.68128C9.25613 7.68128 9.46719 7.60065 9.63281 7.4394C9.79844 7.27816 9.88125 7.07238 9.88125 6.82206C9.88125 6.53299 9.79886 6.31018 9.63408 6.15362C9.46931 5.99706 9.25915 5.91878 9.00362 5.91878C8.71995 5.91878 8.50156 5.99596 8.34843 6.15034C8.19531 6.3047 8.11875 6.52017 8.11875 6.79674C8.11875 7.06268 8.20145 7.27659 8.36685 7.43846C8.53226 7.60034 8.7432 7.68128 8.99966 7.68128ZM8.99897 17.3938C7.9108 17.3938 6.88489 17.19 5.92123 16.7825C4.95755 16.3749 4.11791 15.8133 3.4023 15.0977C2.68668 14.3821 2.12511 13.5421 1.71757 12.5775C1.31002 11.613 1.10625 10.5849 1.10625 9.49324C1.10625 8.40289 1.31038 7.3783 1.71866 6.41947C2.12695 5.46065 2.69072 4.62157 3.40997 3.90223C4.12922 3.18289 4.96829 2.61728 5.92719 2.20538C6.88609 1.79347 7.90933 1.58752 8.99692 1.58752C10.089 1.58752 11.1174 1.79289 12.0821 2.20361C13.0468 2.61434 13.8868 3.17844 14.6021 3.89593C15.3175 4.61342 15.8813 5.45413 16.2938 6.41807C16.7063 7.38199 16.9125 8.41104 16.9125 9.5052C16.9125 10.5986 16.7065 11.6228 16.2946 12.578C15.8827 13.5332 15.3171 14.3693 14.5978 15.0864C13.8785 15.8035 13.0383 16.3673 12.0774 16.7779C11.1165 17.1885 10.0903 17.3938 8.99897 17.3938ZM9.00508 15.6875C10.7081 15.6875 12.1643 15.0851 13.3735 13.8803C14.5828 12.6754 15.1875 11.2137 15.1875 9.49494C15.1875 7.79192 14.5855 6.33576 13.3814 5.12648C12.1773 3.91718 10.7151 3.31252 8.99492 3.31252C7.29189 3.31252 5.83573 3.91457 4.62645 5.11866C3.41715 6.32274 2.8125 7.78489 2.8125 9.50511C2.8125 11.2081 3.41492 12.6643 4.61975 13.8736C5.82458 15.0829 7.28635 15.6875 9.00508 15.6875Z" fill="#727272"/>
                </svg>
                </div>
              <span style={{color: '#727272', fontSize: 15, fontFamily: 'Inter', fontWeight: '500', lineHeight: 22.50, wordWrap: 'break-word'}}>{props.topHeader}</span>
              </div>
            }
            <h1>{title}</h1>
            <p>{subTitle}</p>
          </>
      }
    </div>
  )
}

export default BannerHeader