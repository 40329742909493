import { gql, useLazyQuery } from "@apollo/client";

const DOWNLOAD_MEDICAL_FORM_PDF_QUERY = gql`
query GetMedicalFormPdfUrl($clientId: Int!) {
  getMedicalFormPdfUrl(clientId: $clientId) {
    success
    message
    data {
      clientId
      medicalFormRecordPdfUrl
    }
  }
}
`;

export const GetMedicalFormPdfUrlQuery = () => {

    const [GetMedicalFormPdfUrl, medicalFormPdfUrlData] = useLazyQuery(
        DOWNLOAD_MEDICAL_FORM_PDF_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getMedicalFormPdfUrl: responseData }) => {
                if (responseData.success) {

                }
            },
        }
    );
    const medicalFormPdfUrlQueryData = medicalFormPdfUrlData;
    const initMedicalFormPdfUrl = (data, successCallback, errorCallback) => {
        GetMedicalFormPdfUrl({
            variables: data,
        }).then((res) => {
            successCallback(res)
        }).catch((err) => {
            errorCallback(err)
        });
    };
    return { initMedicalFormPdfUrl, medicalFormPdfUrlQueryData };
};

