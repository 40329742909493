import {  GET_INVOICE_DATA_SUCCESS, GET_INVOICE_DOWNLOAD_DATA, GET_INVOICE_PAGE_DATA, GET_INVOICE_PAGE_DATA_SUCCESS } from "../../constants/invoice/Invoice"

export default function getInvoicePageData(data) {

    return {
        type: GET_INVOICE_PAGE_DATA,
        payload: data
    }
}

export function getInvoicePageDataSuccess(data) {
    return {
        type: GET_INVOICE_PAGE_DATA_SUCCESS,
        payload: data
    }
}

export function downloadInvoice(data) {

    return {
        type: GET_INVOICE_DOWNLOAD_DATA,
        payload: data
    }
}

export function downloadInvoiceSuccess(data) {

    return {
        type: GET_INVOICE_DATA_SUCCESS,
        payload: data
    }
}