export const GET_CONSENT_FORM_LIST = "GET_CONSENT_FORM_LIST"
export const GET_AFTERCARE_FORM_LIST = "GET_AFTERCARE_FORM_LIST"

export const CLEAR_AFTERCARE_FORM_LIST  = "CLEAR_AFTERCARE_FORM_LIST"
export const CLEAR_CONSENT_FORM_LIST  = "CLEAR_CONSENT_FORM_LIST"
export const CLEAR_CLIENT_CONSENT_FORM_LIST = "CLEAR_CLIENT_CONSENT_FORM_LIST"
export const GET_CLIENT_CONSENT_FORM_LIST = "GET_CLIENT_CONSENT_FORM_LIST"

export const GET_FORMS_LIST = "GET_FORMS_LIST"
export const CLEAR_FORMS_LIST = "CLEAR_FORMS_LIST"

export const GET_MYFORMS_LIST = 'GET_MYFORMS_LIST'
export const CLEAR_MYFORMS_LIST = 'CLEAR_MYFORMS_LIST'