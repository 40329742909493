import gql from 'graphql-tag';
import showToast from '../../../common/CustomToast/CustomToast';
import { useId } from 'react';
import { useMutation } from '@apollo/client';

const ADD_OR_UPDATE_QUESTION_MUTATION = gql`
    mutation CreateOrUpdateConsentFormQuestions(
        $consentFormId: Int
        $question: String
        $cfqId: Int
    ) {
        createOrUpdateConsentFormQuestions(
            consentFormId: $consentFormId
            question: $question
            cfqId: $cfqId
        ) {
            success
            message
            data {
                cfq_id
            }
        }
    }
`;

export const AddorUpdateQuestionMutation = () => {
    const [mutate, addorUpdateQuestionStatus] = useMutation(ADD_OR_UPDATE_QUESTION_MUTATION);
    const toastId = useId();

    const initAddorUpdateQuestionRequest = async (data, successCallback, errorCallback) => {
        try {
            const response = await mutate({ variables: data });
            const resData = response.data.createOrUpdateConsentFormQuestions;
            if (resData.success) {
                successCallback && successCallback(resData.data);
            } else {
                showToast('error', resData.message, undefined, undefined, undefined, undefined, {
                    toastId,
                });
                errorCallback && errorCallback(resData);
            }
        } catch (error) {
            showToast('error', error.message, undefined, undefined, undefined, undefined, {
                toastId,
            });
            errorCallback && errorCallback(error);
        }
    };

    return { initAddorUpdateQuestionRequest, addorUpdateQuestionStatus };
};

const DELETE_QUESTION_MUTATION = gql`
    mutation RemoveConsentFormQuestion($cfqId: Int) {
        removeConsentFormQuestion(cfqId: $cfqId) {
            success
            message
        }
    }
`;

export const DeleteQuestionMutation = () => {
    const [mutate, deleteQuestionStatus] = useMutation(DELETE_QUESTION_MUTATION);
    const toastId = useId();

    const initDeleteQuestionRequest = async (data, successCallback, errorCallback) => {
        try {
            const response = await mutate({ variables: data });
            const resData = response.data.removeConsentFormQuestion;
            if (resData.success) {
                successCallback && successCallback(resData.data);
            } else {
                showToast('error', resData.message, undefined, undefined, undefined, undefined, {
                    toastId,
                });
                errorCallback && errorCallback(resData);
            }
        } catch (error) {
            showToast('error', error.message, undefined, undefined, undefined, undefined, {
                toastId,
            });
            errorCallback && errorCallback(error);
        }
    };

    return { initDeleteQuestionRequest, deleteQuestionStatus };
};

const SAVE_CONSENT_FORM_DETAILS = gql`
    mutation SaveConsentFormDetails($consentFormId: Int, $disclaimer: String) {
        saveConsentFormDetails(consentFormId: $consentFormId, disclaimer: $disclaimer) {
            success
            message
            data {
                con_frm_id
            }
        }
    }
`;

export const SaveConsentFormDetailsAction = () => {
    const [mutate, saveConsentFormDetailsStatus] = useMutation(SAVE_CONSENT_FORM_DETAILS);
    const toastId = useId();

    const initSaveConsentFormDetails = async (data, successCallback, errorCallback) => {
        try {
            const response = await mutate({ variables: data });
            const resData = response.data.saveConsentFormDetails;
            if (resData.success) {
                successCallback && successCallback(resData.data);
            } else {
                showToast('error', resData.message, undefined, undefined, undefined, undefined, {
                    toastId,
                });
                errorCallback && errorCallback(resData);
            }
        } catch (error) {
            showToast('error', error.message, undefined, undefined, undefined, undefined, {
                toastId,
            });
            errorCallback && errorCallback(error);
        }
    };

    return { initSaveConsentFormDetails, saveConsentFormDetailsStatus };
};