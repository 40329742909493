import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_CLIENT_DETAILS, GET_CLIENT_TILE_STATUS } from "../../constants";

const GET_CLIENT_DETAILS_QUERY = gql`
query GetClientDetails($clientId: Int!) {
  getClientDetails(clientId: $clientId) {
    success
    message
    data {
      clentId
      clientFirstName
      clientLastName
      clientEmail
      readableContact
      readableAddress
      clientProfileImage
      countryCode
      address
      houseNo
      street
      city
      postCode
      phoneCountry
      mobile
      dob
      nextOfKinAdded
      identityAdded
      medicalDetailsAdded
      notesAdded
      gender
      clientFrom
      clientTitle
      marketingCommunication
      hasAdditionalPhotos
    }
  }
}
`;

var page;
export const GetClientDetails = () => {
  const dispatch = useDispatch();
  const [getClientDetailsData, clientDetailsData] = useLazyQuery(
    GET_CLIENT_DETAILS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClientDetails: responseData }) => {
        dispatch({
          type: GET_CLIENT_DETAILS,
          payload: responseData.data,
          page,
        });
        if (responseData.success) {
          dispatch({
            type: GET_CLIENT_TILE_STATUS,
            payload: responseData.data,
            page,
          })
        } else {
        }
      },
    }
  );
  const clientDetailsQueryData = clientDetailsData;
  const initGetClientDetails = (data) => {
    page = data?.page ? data?.page : 1;
    getClientDetailsData({
      variables: data,
    });
  };
  return { initGetClientDetails, clientDetailsQueryData };
};
