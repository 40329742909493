import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";

const GET_CLIENT_DETAILS_QUERY = gql`
query GetClientAdditionalPhotos($clientId: Int) {
  getClientAdditionalPhotos(clientId: $clientId) {
    success
    message
    data {
      photos {
        id
        imageUrl
      }
    }
  }
}
`;

export const GetClientAdditionalPhotos = () => {
  const dispatch = useDispatch();
  const [getClientAdditionalPhotos, clientAdditionalPhotosData] = useLazyQuery(
    GET_CLIENT_DETAILS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClientAdditionalPhotos: responseData }) => {
       
      },
    }
  );
  const clientAdditionalPhotosQueryData = clientAdditionalPhotosData;
  const initGetClientAdditionalPhotos = (data,successCallback,errorCallback) => {

    getClientAdditionalPhotos({
      variables: data,
    }).then((res) => {
        if (res?.data?.getClientAdditionalPhotos?.success)
          successCallback(res?.data?.getClientAdditionalPhotos?.data);
        else
          errorCallback();
  
      }).catch((err) => {
        errorCallback();
      });
    
  };
  return { initGetClientAdditionalPhotos, clientAdditionalPhotosQueryData };
};
