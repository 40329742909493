import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_LOGIN_HISTORY_QUERY = gql`
 query GetLoginHistory {
  getLoginHistory {
    success
    message
    data {
      totalLogins
      loginData {
        log_tok_id
        log_tok_usr_id
        log_tok_token
        log_tok_dev_token
        log_tok_dev_type
        log_tok_dev_name
        created_at
        updated_at
        device_type
        loggedin_date
      }
    }
  }
}
`;

export const GetLoginHistory = () => {
  const [
    getLoginHistoryResponseFunction,
    getLoginHistoryResponseData,
  ] = useLazyQuery(GET_LOGIN_HISTORY_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });

  const getLoginHistoryQueryData = getLoginHistoryResponseData;

  const initGetLoginHistoryData = (
    data,
    successCallback,
    errorCallback
  ) => {
    getLoginHistoryResponseFunction({
      variables: data,
    })
      .then((responseData) => {
        console.log(responseData?.data?.getLoginHistory?.success,"reesss")
        if (responseData?.data?.getLoginHistory?.success) {
          successCallback(responseData?.data?.getLoginHistory);
        } else {
          errorCallback(responseData?.data?.getLoginHistory);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  return {
    getLoginHistoryQueryData,
    initGetLoginHistoryData,
  };
};
