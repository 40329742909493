import './walk-in-main.scss';
import Avatar from 'react-avatar';
import ReactPaginate from "react-paginate";
import AddBtn from '../../images/Plus.svg';
import { useNavigate } from 'react-router';
import AdminIcon from '../../images/Actions.svg';
import Arrow from '../../images/right_arrow.svg';
import React, { useEffect, useState } from 'react';
import BackArrow from '../../images/back_arrow.svg';
import searcIcon from '../../images/search_icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import rightArrow from "../../images/calander_next.svg"
import LeftArrow from "../../images/calander_previous.svg"
import { WalkInClientList } from '../redux/actions/walk-in-form/getClientList';
import { SET_SELECTED_CLIENT_ID, SET_WALK_IN_CLIENT_NAME } from '../redux/constants/walk-in-form-const/clientWalkInConst';
import NavHeader from '../common/NavHeader/NavHeader'


const ClientOverViewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
  const [currentPaginationLimit, setCurrentPaginationLimit] = useState(10);
  const [currentPaginationSearch, setCurrentPaginationSearch] = useState('');

  const clientListData = useSelector((state) => state?.walkInClientListReducer?.walkInClientList);
  // const walkAfterCareID = useSelector((state) => state?.walkInAfterCareReducer?.afterCareFormId);

  const { initClientList, clientListQueryData } = WalkInClientList();

  useEffect(() => {
    initClientList({
      page: currentPaginationPage,
      limit: currentPaginationLimit,
      search: currentPaginationSearch,
    });
  }, [currentPaginationPage, currentPaginationLimit, currentPaginationSearch]);

  const handleSearchClientData = (e) => {
    setCurrentPaginationSearch(e.target.value);
    initClientList({
      page: currentPaginationPage,
      limit: currentPaginationLimit,
      search: e.target.value,
    });
  }

  const itemsPerPage = 10;
  const totalItems = clientListData?.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginationPages = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationPages.push(i);
  }

  const nextArrow = () => {
    return (<img src={rightArrow} width={25} height={25} alt="rightArrow" />)
  }
  const prevArrow = () => {
    return (<img src={LeftArrow} width={25} height={25} alt="LeftArrow" />)
  }

  const handleBackWalkInPage = () => {
    navigate('/walk-in-consent');
  };

  //set selected client in SET_WALK_IN_CLIENT_NAME reducer
  const handleSelectClient = (clientName, clentId) => {
    console.log(clentId, 'clentId==');
    setSelectedClient(clientName === selectedClient ? null : clientName);
    setSelectedClientId(clentId === selectedClientId ? null : clentId);
    dispatch({
      type: SET_WALK_IN_CLIENT_NAME,
      payload: clientName,
    });
    dispatch({
      type: SET_SELECTED_CLIENT_ID,
      payload: clentId,
    });
  };

  return (
    <div className='walk-in-consent-Main-form'>
        <NavHeader isBackOnly={true} handleOnBack={handleBackWalkInPage} />
      <div className='container'>
        <div className='walk-in-consent-page-main'>
          {/* <div className='header-go-back-img mt-5' onClick={handleBackWalkInPage}>
            <img src={BackArrow} width={35} height={35} alt='zzz' />
          </div> */}
          {/* ===== */}
          <div className='walk-in-consent-page'>
          <div className='SectionHeading'>
              <div className="Section-Title">Select client</div>
              <div className='search-tab mb-5'>
                <img src={searcIcon} alt='search' />
                <input type='text' placeholder='Search client' onChange={handleSearchClientData} />
              </div>
            </div>
            {/* <div className='walk-in-consent-page-header'>
              <h3>Select client</h3>
              <div className='search-tab mb-5'>
                <img src={searcIcon} alt='search' />
                <input type='text' placeholder='Search name, email or phone' onChange={handleSearchClientData} />
              </div>
            </div> */}
            <div className='clientListDataGrid'>
              {clientListData?.clients?.map((item, index) => {
                return (
                  <div className='appointment-client-sec' key={index} onClick={() => handleSelectClient(item?.clientName, item?.clentId)}>
                    <div className='left-img' onClick={handleBackWalkInPage}>
                      <div className='left_content '>
                        <div className='img-foot'>
                          {item?.clientProfileImage ? (
                            <img src={item?.clientProfileImage} alt='step1' />
                          ) : (
                            <Avatar name={item?.clientName} size='37' round={true} fgColor='#000' color='#EEEFF3' />
                          )}
                        </div>
                        <div className='user-details clientContent'>
                          <h5>Full name</h5>
                          <p>email@gmail.com</p>
                        </div>
                      </div>
                      <img src={Arrow} width={20} height={20} alt='step1' />
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <ReactPaginate
              previousLabel={prevArrow()}
              nextLabel={nextArrow()}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(page) =>
                setCurrentPaginationPage(page.selected + 1)
              }
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
              breakClassName={"pagination__break"}
              breakLinkClassName={"pagination__break-link"}
              pageClassName={"pagination__page"}
              pageLinkClassName={"pagination__page-link"}
            /> */}
          </div>
        </div>
      </div>
          <div className='add_new_client_form_btn'>
            <img src={AddBtn} alt='zzz' />
          </div>
    </div>
  );
};

export default ClientOverViewPage;
