import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const HIDE_BECOME_FACES_VERIFIED_BANNER_MUTATION = gql`
mutation HideBecomeFacesVerifiedBanner($isHideBanner: Int) {
  hideBecomeFacesVerifiedBanner(is_hide_banner: $isHideBanner) {
    success
    message
  }
}
`;

export const useHideBecomeFacesVerifiedBanner = () => {
  const [hideBecomeFacesVerifiedBannerRequest, hideBecomeFacesVerifiedBannerResponse] =
    useMutation(HIDE_BECOME_FACES_VERIFIED_BANNER_MUTATION);
  const dispatch = useDispatch();
  const hideBecomeFacesVerifiedBannerData = hideBecomeFacesVerifiedBannerResponse;

  const initiateHideBecomeFacesVerifiedBanner = (
    data,
    successCallback,
    errorCallback
  ) => {
    hideBecomeFacesVerifiedBannerRequest({
      variables: data,
    })
      .then(({ data: { hideBecomeFacesVerifiedBanner: responseData } }) => {
        if (responseData?.success) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  return { initiateHideBecomeFacesVerifiedBanner, hideBecomeFacesVerifiedBannerData };
};
