import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
import CustomModal from "../../modal/CustomModal";
import Typewriter from "typewriter-effect";
import "aos/dist/aos.css";

import Aos from "aos";
import Slider from "react-slick";

// imges
import star from "../../../../src/assets/Findprescriber/star.png";
import map from "../../../../src/assets/Findprescriber/map.png";
// import pin from "../../../../src/assets/Findprescriber/gis_location.svg";
import stepBottomAssets from "../../../../src/assets/Findprescriber/stepAssets.png";
import stepMobile from "../../../../src/assets/Findprescriber/stepMobile.png";
import stepMobileAssets from "../../../../src/assets/Findprescriber/stepMobileAssets.png";
import AppStore from "../../../../src/assets/Findprescriber/appStore.png";
import GoogleStore from "../../../../src/assets/Findprescriber/googlePlay.png";
// import WebsitePreview from "../../../../src/assets/Findprescriber/Website-Preview.png";
import VideoImg from "../../../../src/assets/Findprescriber/videoImg.png";
import traPlaceholder from "../../../../src/assets/Findprescriber/traPlaceholder.png";
import quate from "../../../../src/assets/Findprescriber/quate.png";
import playIcon from "../../../../src/assets/Findprescriber/videoIcon.svg";
import medicalprescription from "../../../../src/assets/Findprescriber/medical-prescription.svg";
import firstaidbox from "../../../../src/assets/Findprescriber/first-aid-box.svg";
import consumer from "../../../../src/assets/Findprescriber/consumer.svg";
import Visuals from "../../../../src/assets/Findprescriber/logo-design.svg";
import encrypted from "../../../../src/assets/Findprescriber/encrypted.svg";
import update from "../../../../src/assets/Findprescriber/update.svg";
import starBlack from "../../../../src/assets/Findprescriber/starBlack.svg";
import favIcon from "../../../../src/assets/Findprescriber/favIcon.svg";
import proOne from "../../../../src/assets/Findprescriber/default-treatment-image.jpg";
import proTwo from "../../../../src/assets/Findprescriber/ourproducts/proTwo.png";
import proThree from "../../../../src/assets/Findprescriber/ourproducts/proThree.png";
import proFour from "../../../../src/assets/Findprescriber/ourproducts/proFour.png";
import proFive from "../../../../src/assets/Findprescriber/ourproducts/proFive.png";
import proSix from "../../../../src/assets/Findprescriber/ourproducts/proSix.png";
import proSeven from "../../../../src/assets/Findprescriber/ourproducts/proSeven.png";
import proEight from "../../../../src/assets/Findprescriber/ourproducts/proEight.png";
import googleLogo from "../../../../src/assets/Findprescriber/googleLogo.svg";
import ClientOne from "../../../../src/assets/Findprescriber/clientOne.png";
import ClientTwo from "../../../../src/assets/Findprescriber/clientTwo.png";
import ClientThree from "../../../../src/assets/Findprescriber/clientThree.png";
import starBlue from "../../../../src/assets/Findprescriber/starBlue.svg";


import "./find-A-Prescriber-landing-page.scss";
import { FiDownload } from "react-icons/fi";
import BusinessHeader from "../../businessHeader";
import BusinessFooter from "../../businessFooter";
import { useLocation, useNavigate } from "react-router";
import { GetFacesGoogleReviewsAction } from "../../redux/actions/googleReviewsWebSiteBuilder/googleReviewsWebsiteBuilder";
import { useSelector } from "react-redux";
import { insuranceLandingPagePattern } from "../../../Routes";
import { googleApiKey, config, webBaseUrl, notificationMode, isLiveSystem } from "../../../config";

import ReactGoogleAutocomplete from "react-google-autocomplete";
import axios from "axios";
import Cookies from "js-cookie";
import { GetPrescriptionProductsAction } from "../../redux/actions/findPrescriber/getPrescriptionProductsGql";
import { AddProductToWishList } from "../../redux/actions/findPrescriber/addProductToWishListGql";
import addedWishList from "../../../../src/assets/Findprescriber/wish-list-added.svg";
import { Helmet } from "react-helmet";
import trackGoogleAPI from "../../../utils/googleApiTrack";

export const FindAPrescriberLandingPage = () => {
	const navigate = useNavigate();
	const WhatsappLinkRedirect = "https://api.whatsapp.com/send?phone=447398592729";
	const [isUserAuth, setIsUserAuth] = useState(false);
	useEffect(() => {
		!Cookies.get("faces_access_token") ? setIsUserAuth(false) : setIsUserAuth(true);
	}, []);
	const [isyouTube, setIsyouTube] = useState(false);
	const { pathname } = useLocation();
	const { getFacesGoogleReviewsQueryData, initGetFacesGoogleReviewsData } = GetFacesGoogleReviewsAction();
	const { websiteBuilderGoogleReviews } = useSelector((state) => state.GetWebsiteBuilderReducer);
	const insuranceData = useSelector(
		(state) => state.GetInsuranceDataReducer.insuranceData
	);
	const [insuranceLandingLink, setInsuranceLandingLink] = useState("");
	const [renderIframe, setRenderIframe] = useState(false);
	const [renderMessage, setRenderMessage] = useState(false);
	const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)



	console.log(insuranceLandingLink, 'insuranceLandingLink')


	useEffect(() => {
		initGetFacesGoogleReviewsData();
	}, []);
	useEffect(() => {
		Aos.init({
			duration: 1000,
			once: true,
			easing: "ease-in-out",
		});
		return () => {
			Aos.refresh();
		};
	}, []);

	const handleTrackGoogleAPI = async (callType) => {
		try {
			const currentUrl = window.location.href;
			const userId = UserProfileData?.user?.usr_id || 0;

			// No need to manually parse JSON; Axios handles it
			const data = await trackGoogleAPI(userId, currentUrl, callType);

			console.log("trackdata", data);
		} catch (error) {
			console.error('Error:', error.message || error);
		}
	};

	const AdvantagesData = [
		{
			id: "1",
			backgroundColor: "#E0F2FE",
			boxIcon: medicalprescription,
			boxTitle: "Secure Prescription Management",
			boxDesc:
				"Access a verity of prescription and non-prescription products from a number of verified pharmacies all in one place",
		},
		{
			id: "2",
			backgroundColor: "#FFEEFE",
			boxIcon: firstaidbox,
			boxTitle: "Safety First",
			boxDesc:
				"Our system closely monitors prescription product dispensing, helping to avoid over-prescription and maintain safe usage levels.",
		},
		{
			id: "3",
			backgroundColor: "#FFF5E2",
			boxIcon: consumer,
			boxTitle: "Better Client Insights",
			boxDesc:
				"View complete consultation histories in one place for more informed and effective treatment decisions.",
		},
		{
			id: "4",
			backgroundColor: "#E0F2FE",
			boxIcon: Visuals,
			boxTitle: "Visuals",
			boxDesc:
				"Logos or icons of available pharmacies, and a screenshot of the prescription system interface",
		},
		{
			id: "5",
			backgroundColor: "#E0F2FE",
			boxIcon: encrypted,
			boxTitle: "Convenience and Security Combined",
			boxDesc:
				"Faces Rx is user-friendly and secure, safeguarding client data while simplifying prescription handling.",
		},
		{
			id: "6",
			backgroundColor: "#FFEEFE",
			boxIcon: update,
			boxTitle: "Stay Updated",
			boxDesc:
				"Our system closely monitors medication dispensing, helping to avoid over-prescription and maintain safe usage levels.",
		},
	];

	// const ProductData = [
	//   {
	//     id: "1",
	//     ProdImg: proOne,
	//     ProdTitle: "Product item name",
	//     ProdDesc: "Pharmacy name",
	//     prodRating: "5.0",
	//   },
	//   {
	//     id: "2",
	//     ProdImg: proTwo,
	//     ProdTitle: "Product item name",
	//     ProdDesc: "Pharmacy name",
	//     prodRating: "5.0",
	//   },
	//   {
	//     id: "3",
	//     ProdImg: proThree,
	//     ProdTitle: "Product item name",
	//     ProdDesc: "Pharmacy name",
	//     prodRating: "5.0",
	//   },
	//   {
	//     id: "4",
	//     ProdImg: proFour,
	//     ProdTitle: "Product item name",
	//     ProdDesc: "Pharmacy name",
	//     prodRating: "5.0",
	//   },
	//   {
	//     id: "5",
	//     ProdImg: proFive,
	//     ProdTitle: "Product item name",
	//     ProdDesc: "Pharmacy name",
	//     prodRating: "5.0",
	//   },
	//   {
	//     id: "6",
	//     ProdImg: proSix,
	//     ProdTitle: "Product item name",
	//     ProdDesc: "Pharmacy name",
	//     prodRating: "5.0",
	//   },
	//   {
	//     id: "7",
	//     ProdImg: proSeven,
	//     ProdTitle: "Product item name",
	//     ProdDesc: "Pharmacy name",
	//     prodRating: "5.0",
	//   },
	//   {
	//     id: "8",
	//     ProdImg: proEight,
	//     ProdTitle: "Product item name",
	//     ProdDesc: "Pharmacy name",
	//     prodRating: "5.0",
	//   },
	// ];

	const [products, setProducts] = useState([]);

	const {
		getPrescriptionProductsQueryData,
		initGetPrescriptionProductsData,
	} = GetPrescriptionProductsAction();

	const {
		addProductToWishListQueryData,
		initAddProductToWishListData,
	} = AddProductToWishList();

	const handleWishListClick = (productId, isAdded) => {
		console.log('clicked!');
		if (!isUserAuth) {
			window.location.href = `${webBaseUrl}/register`;
		} else {
			setProducts((prevProducts) => {
				return prevProducts.map((product) =>
					product.product_id === productId
						? { ...product, is_added_to_wishlist: !isAdded }
						: product
				);
			});

			initAddProductToWishListData({ productId }, (res) => {
			}, (err) => {
			});
		}
	}

	const handleProductClick = (slug) => {
		window.location.href = `${webBaseUrl}/shop/product/${slug}`;
	}

	useEffect(() => {
		initGetPrescriptionProductsData({}, (res) => {
			console.log(res, "i am getPrescriptionProducts data");
			setProducts(res?.data?.items);
		}, (err) => {
			console.log(err, "i am getPrescriptionProducts error");
		});
	}, []);

	function SampleNextArrow(props) {
		const { className, style, onClick } = props;

		return (
			<div className={className} onClick={onClick}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="8"
					height="12"
					viewBox="0 0 8 12"
					fill="none"
				>
					<path
						d="M0.294956 1.41L4.87496 6L0.294956 10.59L1.70496 12L7.70496 6L1.70496 -2.62268e-07L0.294956 1.41Z"
						fill="#373C35"
					/>
				</svg>
			</div>
		);
	}

	function SamplePrevArrow(props) {
		const { className, style, onClick } = props;
		return (
			<div
				className={className}
				// style={{ ...style, display: "block", background: "green" }}
				onClick={onClick}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="8"
					height="12"
					viewBox="0 0 8 12"
					fill="none"
				>
					<path
						d="M7.88498 1.41L3.30498 6L7.88498 10.59L6.47498 12L0.474976 6L6.47498 -2.62268e-07L7.88498 1.41Z"
						fill="#373C35"
					/>
				</svg>
			</div>
		);
	}


	const TeamBerSlider = {
		dots: false,
		navs: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3.5,
		// fade: true,
		centerMode: true,
		slidesToScroll: 1,
		// initialSlide: 0,
		autoplay: true,
		autoplaySpeed: 3000,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 2.5,
					slidesToScroll: 2.5,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};


	const [reviewViewMore, setreviewViewMore] = useState(false);
	const [reviewDataModel, setreviewDataModel] = useState();

	const openreviewModel = (e, data) => {
		setreviewDataModel(data)
		setreviewViewMore(!reviewViewMore)
		console.log(reviewDataModel, 'reviewDataModel')
	}

	const [isAuthHeader, setIsAuthHeader] = useState(false);
	useEffect(() => {
		Cookies.get("faces_access_token")
			? setIsAuthHeader(true)
			: setIsAuthHeader(false);
	}, []);

	useEffect(() => {
		if (insuranceData) {
			if (insuranceData && Object.keys(insuranceData.element).length !== 0) {
				setInsuranceLandingLink(insuranceData.element.insuranceLandingLink);
				setRenderIframe(true);
			} else {
				setRenderMessage(true);
			}
		}

		console.log(insuranceData, "insuranceData=>");
	}, [insuranceData]);


	console.log(isAuthHeader, 'isAuthHeader')

	const handleInsuranceLanding = () => {
		if (isAuthHeader) {
			navigate(insuranceLandingPagePattern())
		} else {
			window.location.href = webBaseUrl + '/register'
		}
	};


	const [latitude, setLatitude] = useState(null);
	const [longitude, setLongitude] = useState(null);
	const [locationName, setLocationName] = useState('');
	const [locationLoading, setLocationLoadingLoading] = useState(false);

	const getLocaitonNameFromLatLng = async (lat, lng) => {
		const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
			params: {
				latlng: `${lat},${lng}`,
				key: googleApiKey,
			},
		});

		const currentUrl = window.location.href;
		const userId = UserProfileData?.user?.usr_id || 0;

		const { results } = response.data;

		// No need to manually parse JSON; Axios handles it
		trackGoogleAPI(userId, currentUrl, 3);

		// const data = await response.json();
		return results;
	}

	const getLocation = () => {
		setLocationLoadingLoading(true);
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				async position => {
					setLatitude(position.coords.latitude);
					setLongitude(position.coords.longitude);
					console.log('tt pos postion:', position.coords.latitude, position.coords.longitude);
					setLocationLoadingLoading(true);
					try {
						// const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${googleApiKey}`);
						// const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
						//   params: {
						//     latlng: `${position.coords.latitude},${position.coords.longitude}`,
						//     key: googleApiKey,
						//   },
						// });
						// const { results } = response.data;
						// // const data = await response.json();
						// const data = results;
						const data = await getLocaitonNameFromLatLng(position.coords.latitude, position.coords.longitude);
						console.log('tt pos data:', data);
						const location = data[0]?.formatted_address;
						// setLocationName(location);
						console.log(location, 'test!');
						setSearchLocation(location);
						console.log('tt pos locaition:', location);
						setLocationLoadingLoading(false);
					} catch (error) {
						console.error('Error fetching location:', error);
						setLocationLoadingLoading(false);
					}
				},
				error => {
					console.error('Error getting location:', error);
					setLocationLoadingLoading(false);
				}
			);
		} else {
			console.error('Geolocation is not supported by this browser.');
			setLocationLoadingLoading(false);
		}
	};

	useEffect(() => {
		getLocation();
	}, []);

	const [searchLocation, setSearchLocation] = useState("");

	useEffect(() => {
		console.log(latitude, longitude, searchLocation, 'test!');
	}, [latitude, longitude]);

	const handleFindPres = (e) => {
		e.preventDefault();
		if (!Cookies.get("faces_access_token")) {
			window.location.href = `${webBaseUrl}/register`;
		} else {
			if (!latitude || !longitude) {
				return;
			}
			window.location.href = `${webBaseUrl}/prescriber/search?latitude=${latitude}&longitude=${longitude}&formattedAddress=${searchLocation}`;
		}
	}

	useEffect(() => {
		return () => {

			let clarityScript = document.getElementById('clarity-script');

			if (clarityScript) {
				clarityScript.remove();
			}
		}
	}, []);

	return (
		<>

			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Find a Prescriber Near Me | Faces Consent - Connect with Local Prescribers
				</title>
				<meta
					name="description"
					content="Locate a qualified prescriber near you with Faces Consent. Our platform helps you connect with local, fully insured, and regulated aesthetic prescribers to meet your treatment needs. Find trusted professionals in your area today."
				/>
				<meta
					name="keywords"
					content="find a prescriber, Faces Consent, local prescribers, aesthetic prescribers, beauty prescribers, connect with prescribers, qualified prescribers, regulated prescribers, insured prescribers, local aesthetic professionals"
				/>
				{isLiveSystem &&
					<script type="text/javascript">
						{
							` (function(c,l,a,r,i,t,y){ 
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; 
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            t.id = 'clarity-script';
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "mcftd5dhzu"); `
						}
					</script>
				}
			</Helmet>
			<div className={"main_find_prescriber"}>
				{insuranceData ? (
					<iframe
						className="editprofilestyle"
						src={insuranceLandingLink}
						style={{ height: "100vh", width: "100%" }}
					></iframe>
				) : (
					<>

						<BusinessHeader />

						<section className="bannerFind" id="Banner">
							<div className="container">
								<div className="row">
									<div className="col-sm-12 col-md-12 col-lg-6">
										<div className="bannerContent">
											<h1 data-aos="fade-up">
												Find a Prescriber near
												<span className="animatedText">
													<Typewriter
														options={{
															strings: [
																"London",
																"Birmingham",
																"Manchester",
																"Glasgow",
																"Leeds",
																"Liverpool",
																"Sheffield",
																"Bristol",
																"Newcastle upon Tyne",
																"Belfast",
																"Nottingham",
																"Edinburgh",
																"Leicester",
																"Coventry",
																"Cardiff"
															],
															autoStart: true,
															loop: true,
														}}
													/>
												</span>
											</h1>
											<p data-aos="fade-up">
												Your advanced digital prescription platform to find a local prescriber
											</p>
											<form
												action="javascript:void(0)"
												className="findPricriberForm"
												data-aos="fade-up"
											>
												<div className="inputGroup">
													{/* <input
                          type="text"
                          placeholder="Find a prescriber near you with our easy-to-use search tool."
                        /> */}
													<ReactGoogleAutocomplete
														icon={false}
														apiKey={googleApiKey}
														defaultValue={searchLocation}
														onPlaceSelected={(place, ref) => {
															console.log("onPlaceSelected ref", ref, ref.value);
															setSearchLocation(ref.value);
															// const location = place.address_components.reduce(
															//   (seed, { long_name, types }) => (
															//     types.forEach((t) => (seed[t] = long_name)), seed
															//   ),
															//   {}
															// );
															// setLocation({
															//   ...location,
															//   street: location.route,
															//   address1: place.address_components[0].long_name,
															//   address2: place.address_components[1].long_name,
															//   postcode:
															//     location.postal_code === undefined
															//       ? ""
															//       : location.postal_code,
															//   city:
															//     location.locality === undefined
															//       ? location.postal_town === undefined
															//         ? ""
															//         : location.postal_town
															//       : location.locality,
															//   country: location.country,
															//   state:
															//     location.administrative_area_level_1 === undefined
															//       ? location.administrative_area_level_2
															//       : location.administrative_area_level_1,
															//   latitude: place.geometry.location.lat(),
															//   longitude: place.geometry.location.lng(),
															// });
															console.log(place, "i am getplace data");
															setLatitude(place?.geometry?.location?.lat());
															setLongitude(place?.geometry?.location?.lng());
															handleTrackGoogleAPI(2);
														}}
														placeholder="Find a prescriber near you with our easy-to-use search tool."
														className={"form-control"}
														value={searchLocation}
														onChange={(e) => { setSearchLocation(e.target.value); handleTrackGoogleAPI(1); }}
														options={{ types: [], componentRestrictions: { country: "uk" }, }}
													/>
													<button type="button" className="Squarebtn SquareIconbtn" onClick={handleFindPres}>
														<span className="btnIcon">
															<IoLocationSharp />
														</span>
														Search
													</button>
												</div>
											</form>
											<div className="reviewContent">
												<p data-aos="fade-up">from 35,000+ reviews</p>
												<ul>
													<li data-aos="zoom-in">
														<img src={star} alt="" />
													</li>
													<li data-aos="zoom-in">
														<img src={star} alt="" />
													</li>
													<li data-aos="zoom-in">
														<img src={star} alt="" />
													</li>
													<li data-aos="zoom-in">
														<img src={star} alt="" />
													</li>
													<li data-aos="zoom-in">
														<img src={star} alt="" />
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-12 col-lg-6">
										<div className="bannerMap">
											<img src={map} alt="" />
											<div className="ToolTip">
												<div className="ToolTipImgBox">
													<img src={ClientThree} alt="" />
												</div>
												<div className="ToolTipContentBox">
													<div className="ToolTipFirst">
														<div className="ToolTipNameWraper">
															<h5>
																Mark Williams <span className="workDots">Nurse prescriber</span>
															</h5>
														</div>
														<div className="ToolTiprating">
															<p>

																<span className="starImg">
																	<img src={starBlue} alt="" />
																</span>
																4.71
															</p>
														</div>
													</div>
													<div className="ToolTipSecond">
														<div className="ToolTipLocation">
															<h5>Newcastle</h5>
														</div>
														<div className="ToolTipRupeas">
															<p> £20.50 </p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section className="easyStep sameSpace" id="easyStep">
							<div className="bottomAssets">
								<img src={stepBottomAssets} alt="" />
							</div>

							<div className="container">
								<div className="row">
									<div className="col-sm-12 col-md-12 col-lg-6"
										data-aos="fade-right"
										data-aos-anchor="#easyStep"
										data-aos-offset="700"
										data-aos-duration="700">
										<div className="easyStepMobileWrapper">
											<div className="easyStepMobileAssets">
												<img src={stepMobileAssets} alt="" />
											</div>
											<div class="circle-ripple"></div>
											<div className="easyStepMobile"
											>
												<img src={stepMobile} alt="" />
											</div>
										</div>

									</div>
									<div className="col-sm-12 col-md-12 col-lg-6">
										<div className="easyStepContent">
											<span className="tagLine"
												data-aos="fade-up"
												data-aos-anchor="#easyStep"
												data-aos-offset="700"
												data-aos-duration="700">How It Works</span>
											<h5 className="secTitle"
												data-aos="fade-up"
												data-aos-anchor="#easyStep"
												data-aos-offset="750"
												data-aos-duration="750"></h5>
											<ul>
												<li
													data-aos="fade-up"
													data-aos-anchor="#easyStep"
													data-aos-offset="800"
													data-aos-duration="800">
													<span className="stepTitle">1. Register</span>
													<span className="stepContent">
														Join our pharmacy marketplace for comprehensive
														prescription services.
													</span>
												</li>
												<li
													data-aos="fade-up"
													data-aos-anchor="#easyStep"
													data-aos-offset="850"
													data-aos-duration="850"
												>
													<span className="stepTitle">2. Connect</span>
													<span className="stepContent">
														Engage with local prescribers through chat and video
														consultations.
													</span>
												</li>
												<li
													data-aos="fade-up"
													data-aos-anchor="#easyStep"
													data-aos-offset="900"
													data-aos-duration="900"
												>
													<span className="stepTitle">3. Consent & Forms</span>
													<span className="stepContent">
														Discuss and complete necessary consent and medical forms
														digitally.
													</span>
												</li>
												<li
													data-aos="fade-up"
													data-aos-anchor="#easyStep"
													data-aos-offset="950"
													data-aos-duration="950">
													<span className="stepTitle">4. Prescription</span>
													<span className="stepContent">
														Select and pay for your prescription items securely.
													</span>
												</li>
											</ul>
											<div className="PlayStoreBtn">
												<div className="storeBtn" >
													<img src={AppStore} alt="" onClick={() => window.open(`${config.APP_STORE_LINK}`, '_blank')} />
												</div>
												<div className="storeBtn" onClick={() => window.open(`${config.PLAY_STORE_LINK}`, '_blank')}  >
													<img src={GoogleStore} alt="" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section className="needSec sameSpace" id="needSec">
							<div className="container">
								<div className="secTitleWrapper">
									<h5
										data-aos="fade-up"
										data-aos-anchor="#needSec"
										data-aos-offset="700"
										data-aos-duration="700">What do you need?</h5>
									<ul>
										<li
											data-aos="fade-up"
											data-aos-anchor="#needSec"
											data-aos-offset="750"
											data-aos-duration="750">
											<h6 className="needLinkTitle">Valid Insurance</h6>
											<p className="needLinkDesc"
												onClick={() => {
													// isAuthHeader  ?  handleInsuranceLanding() : window.location.href = webBaseUrl + '/register'
													handleInsuranceLanding()
												}}
											>
												Get insurance quote
											</p>
										</li>
										<li data-aos="fade-up"
											data-aos-anchor="#needSec"
											data-aos-offset="800"
											data-aos-duration="800">
											<h6 className="needLinkTitle">Training Certificates</h6>
											<p className="needLinkDesc"
												onClick={() => {
													// window.location.href = financeNavigationLink
													window.open(WhatsappLinkRedirect, "_blank");
												}}>
												Contact an approved training academy
											</p>
										</li>
										<li data-aos="fade-up"
											data-aos-anchor="#needSec"
											data-aos-offset="850"
											data-aos-duration="850">
											<h6 className="needLinkTitle">Identity Verification</h6>
											<p className="needLinkDesc noLink">
												Provide a government-issued ID for identity verification.
											</p>
										</li>
									</ul>
								</div>
								{/* <div className="row">
                <div className="col-sm-12 col-md-3" 
                      data-aos="fade-right"
                      data-aos-anchor="#needSec"
                      data-aos-offset="1100"
                      data-aos-duration="1100">
                  <div className="mobileImg">
                    <img src={WebsitePreview} alt="" />
                  </div>
                </div>
                <div className="col-sm-12 col-md-9" 
                      data-aos="fade-left"
                      data-aos-anchor="#needSec"
                      data-aos-offset="1200"
                      data-aos-duration="1200">
                  <div className="VideoImg">
                    <img src={VideoBg} alt="" />
                    <div className="videoPlayIcon" >
                    <div class="video-circle-ripple"></div>
                      <img src={playIcon} alt=""  
                      onClick={() => {
                        setIsyouTube(true);
                      }} />
                    </div>
                  </div>
                </div>
              </div> */}
							</div>
						</section>

						<section className="VideoSec sameSpace">
							<div className="container">
								<div className="row">
									<div className="col-sm-12 col-md-5">
										<div className="VideoClientImg">
											<img src={VideoImg} alt="" />
											<div className="videoPlayIcon" >
												<div class="video-circle-ripple"></div>
												<img src={playIcon} alt=""
													onClick={() => {
														setIsyouTube(true);
													}} />
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-7">
										<div className="VideoClientContentWrapper">
											<div className="VideoClientContent">
												<div className="VideoLeft">
													<img src={quate} alt="" srcset="" />
												</div>
												<div className="VideoRight">
													<h5>Miriam</h5>
													<span className="Descri">Prescribing pharmacist</span>
													<span className="Desination"><span className="profileDesc">CEO</span> at Indigo Clinic</span>
													<span className="LinkClient" onClick={() => { window.open('https://www.indigoclinic.co.uk/', '_blank') }}>www.indigoclinic.co.uk</span>
													<p className="desc">Miriam is a seasoned medical professional turned aesthetic expert. With 7 years of hospital experience as a prescribing pharmacist, Miriam found her true passion in aesthetics three years ago.</p>
													<p className="desc">Miriam holds a Level 7 Advanced qualification, the pinnacle of aesthetic training, and is continuously expanding her skill set with certifications in various treatments.</p>

													<div className="followDiv">
														<h5>Follow here for more:</h5>
														<ul>
															<li onClick={() => { window.open('https://www.instagram.com/indigocliniclondon/', '_blank') }}>@indigocliniclondon</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section className="clientTest sameSpace">
							<Slider {...TeamBerSlider}>
								{/* {TestimonialData?.map((item, index) => {
                return (
                  <div className="TestBox">
                    <div className="TestImgBox">
                      <img src={item?.ClientImg} alt="" />
                    </div>
                    <div className="TestRightBox">
                      <p>{item?.ClientReview}</p>
                      <div className="TestNameBox">
                        <div className="TestNameContent">
                          <h5>{item?.ClientName}</h5>
                          <p>{item?.ClientDestination}</p>
                        </div>
                        <div className="companyLogo">
                          <img src={item?.companyLogo} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })} */}
								{websiteBuilderGoogleReviews.map((item, index) => {
									return (
										<div className="TestBox">
											<span className="TestImgBox" onClick={() => { window.open(item?.reviewUrl, 'blank') }}>
												<img src={item?.userImage} />
											</span>
											<div className="TestRightBox">
												<div className="TestContentBox">
													<p><span className="TestContentDesc">{item?.userReview.slice(0, 140)}</span> {item?.userReview.length >= 140 && <span onClick={(e) => { openreviewModel(e, item) }} className="viewMoreBtn">View More</span>}</p>

												</div>

												<div className="TestNameBox">
													<div className="TestNameContent">
														<h5>{item?.userName}</h5>
														<p>{item?.reviewDate}</p>
													</div>
													<div className="companyLogo">
														<img src={googleLogo} alt="" />
													</div>
												</div>
											</div>
										</div>
									)
								}
									// <div key={index}>
									//   <div className="testiBox">
									//     <div className="testiHeader">
									//       <span className="RaingWrapper">
									//         { [...Array(5)].map((i, indx) => <img src={indx < item?.userRating ? star : greyRatingStar} />) }
									//       </span>
									//       <span className="comLogo">
									//         <img src={googlelogo} />
									//       </span>
									//     </div>
									//     <div className="testiContent">
									//       {/* <h5>Fascinating Experience</h5> */}
									//       <p>
									//       <p>{item?.userReview.slice(0, 250)}{item?.userReview.length >= 250 && 
									//           <span onClick={(e) => {
									//             openreviewModel(e, item)
									//           }} className="viewMoreBtn">
									//              View More
									//            </span>}</p>
									//       </p>
									//     </div>

									//     <div className="testiFooter">
									//       <span className="ClientImage" onClick={()=> {window.open(item?.reviewUrl, 'blank')}}>
									//         <img src={item?.userImage} />
									//       </span>
									//       <span className="ClientNameWrapper">
									//         <span className="ClientName" onClick={()=> {window.open(item?.reviewUrl, 'blank')}}>{item?.userName}</span>
									//         <span className="ClientDesti" onClick={()=> {window.open(item?.reviewUrl, 'blank')}}>{item?.reviewDate}</span>
									//       </span>
									//     </div>
									//   </div>
									// </div>
								)}
							</Slider>
						</section>

						<section className="AdvantagesSec sameSpace" id="AdvantagesSec">
							<div className="container">
								<div className="secTitle"
									data-aos="fade-up"
									data-aos-anchor="#AdvantagesSec"
									data-aos-offset="700"
									data-aos-duration="700">
									<span className="tagLine text-center">WHAT MAKE US DIFFERENT</span>
									<h5 className="secTitle text-center">
										Advantages of Faces Digital Prescribing System
									</h5>
								</div>

								<div className="row">
									{AdvantagesData?.map((item, index) => {
										return (
											<div className="col-sm-12 col-md-6 col-lg-4 mb-5" key={index}
												data-aos="fade-up"
												data-aos-anchor="#AdvantagesSec"
												data-aos-offset={700 + index * 100} // Adjust this value based on your preference
												data-aos-duration={700 + index * 100} // Adjust this value based on your preference
											>
												<div className="AdvantageBox">
													<div
														className="AdvantageIconBox"
														style={{ backgroundColor: item?.backgroundColor }}
													>
														<img src={item?.boxIcon} alt="" />
													</div>
													<div className="AdvantageContent">
														<h5>{item?.boxTitle}</h5>
														<p>{item?.boxDesc}</p>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</section>

						<section className="productSec sameSpace" id="productSec">
							<div className="container">
								<div className="secTitle"
									data-aos="fade-up"
									data-aos-anchor="#productSec"
									data-aos-offset="700"
									data-aos-duration="700">
									<span className="tagLine text-center">FEATURED PRODUCTS</span>
									<h5 className="secTitle text-center mb-2">Our Products</h5>
									<p>
										<b>Did you know?</b> You can purchase products for all your
										favorite treatments safely and securely from over 30 wholesalers
										and pharmacies. We guarantee the best prices and service!
									</p>
								</div>

								<div className="row">
									{products && products.map((item, index) => {
										return (
											<div className="col-sm-12 col-md-3 mb-4"
												data-aos="fade-up"
												data-aos-anchor="#productSec"
												data-aos-offset={700 + index * 100}
												data-aos-duration={700 + index * 100}
												key={index}
											>
												<div className="prodBox">
													<div className="prodImg">
														<img src={traPlaceholder} alt="" />
														<div className="prodImgWrapper">
															<img src={item?.product_image} alt={item?.product_name} />
														</div>
														<div className="prodOverlapContent">
															<span className="favrate" style={{ cursor: 'pointer' }}>
																<img src={item?.is_added_to_wishlist ? addedWishList : favIcon} alt="" onClick={() => handleWishListClick(item?.product_id, item?.is_added_to_wishlist)} />
															</span>
															{/* <p className="discount">-10%</p> */}
														</div>
													</div>
													<div className="prodContent" onClick={() => handleProductClick(item?.product_slug)} style={{ cursor: 'pointer' }}>
														<h5>{item?.product_name}</h5>
														<p className="proTitle">{item?.vendor_name}</p>
														{
															isUserAuth && item?.is_user_registered_with_vendor ? (<p className="proTitle">{item?.product_price}</p>) : (<></>)
														}
														<div className="ratingWrapper">
															<div className="review">
																<p>{item?.avg_rating}</p>
																<img src={starBlack} alt="" />
															</div>
															<div className="reviewContent">
																<p>({item?.total_reviews} reviews)</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</div>
								<div className="BtnWrapper">
									<a href={`${webBaseUrl}/shop`} className="Squarebtn transperentBtn">
										View All Products
									</a>
								</div>
							</div>
						</section>

						<section className="QuestionSec sameSpace" id="QuestionSec">
							<div className="container">
								<div className="secTitle"
									data-aos="fade-up"
									data-aos-anchor="#QuestionSec"
									data-aos-offset="500"
									data-aos-duration="500">
									<h5 className="secTitle text-center mb-2">
										Got a Question? We have the answers
									</h5>
									<p>
										Can't find the questions you need? <br /> Reach out to our
										<b style={{ color: "#3445D9", cursor: "pointer", padding: "0px 5px" }} onClick={() => {
											window.location.href = webBaseUrl + '/contact-us'
										}}>
											support team
										</b>
										for a real chat.
									</p>
								</div>
								<div class="accordion" id="accordionExample">
									<div className="row">
										<div className="col-sm-12 col-md-6">
											<div
												class="accordion-item"
												data-aos="fade-up"
												data-aos-anchor="#faqSec"
												data-aos-offset="550"
												data-aos-duration="550"
											>
												<h2 class="accordion-header" id="headingOne">
													<button
														class="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseOne"
														aria-expanded="false"
														aria-controls="collapseOne"
													>
														Who should obtain client consent for botox treatment?
													</button>
												</h2>
												<div
													id="collapseOne"
													class="accordion-collapse collapse"
													aria-labelledby="headingOne"
													data-bs-parent="#accordionExample"
												>
													<div class="accordion-body">
														The Practitioner and/or prescribing professional performing the
														Botox procedure or supervising it should be the one to obtain patient
														consent. This ensures that the patient is fully informed and agrees to the
														specific treatment plan.
													</div>
												</div>
											</div>

											<div
												class="accordion-item"
												data-aos="fade-up"
												data-aos-anchor="#faqSec"
												data-aos-offset="600"
												data-aos-duration="600"
											>
												<h2 class="accordion-header" id="headingSecond">
													<button
														class="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseSecond"
														aria-expanded="false"
														aria-controls="collapseSecond"
													>
														Can the administration of botox be delegated?
													</button>
												</h2>
												<div
													id="collapseSecond"
													class="accordion-collapse collapse"
													aria-labelledby="headingSecond"
													data-bs-parent="#accordionExample"
												>
													<div class="accordion-body">
														Yes, doctors and prescribing nurses and professionals can
														delegate the administration of Botox, but they remain responsible for the
														overall management of the patient. They must ensure that the delegate
														has the necessary qualifications, skills, and experience for safe care.
													</div>
												</div>
											</div>

											{/* <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-anchor="#faqSec"
                        data-aos-offset="650"
                        data-aos-duration="650"
                      >
                        <h2 class="accordion-header" id="headingthree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsethree"
                            aria-expanded="false"
                            aria-controls="collapsethree"
                          >
                            What Are the Remote Prescribing Regulations for Nurses Regarding Botox?
                          </button>
                        </h2>
                        <div
                          id="collapsethree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingthree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            For nurses, remote prescribing must adhere to high-level
                            principles set out by the NMC, with patient care being a priority. These
                            principles emphasize safe prescribing practices. More details can be
                            found at the NMC’s official guidelines on remote prescribing here.
                          </div>
                        </div>
                      </div> */}
										</div>
										<div className="col-sm-12 col-md-6">
											<div
												class="accordion-item"
												data-aos="fade-up"
												data-aos-anchor="#faqSec"
												data-aos-offset="700"
												data-aos-duration="700"
											>
												<h2 class="accordion-header" id="headingsix">
													<button
														class="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapsesix"
														aria-expanded="false"
														aria-controls="collapsesix"
													>
														What are the qualifications needed to prescribe botox?
													</button>
												</h2>
												<div
													id="collapsesix"
													class="accordion-collapse collapse"
													aria-labelledby="headingsix"
													data-bs-parent="#accordionExample"
												>
													<div class="accordion-body">
														To prescribe Botox, a practitioner must be a qualified medical
														professional such as a doctor, dentist, or nurse prescriber. They should
														have specific training in cosmetic procedures and the use of Botox.
													</div>
												</div>
											</div>
											{/* <div
                        class="accordion-item"
                        data-aos="fade-up"
                        data-aos-anchor="#faqSec"
                        data-aos-offset="750"
                        data-aos-duration="750"
                      >
                        <h2 class="accordion-header" id="headingseven">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseseven"
                            aria-expanded="false"
                            aria-controls="collapseseven"
                          >
                            Can Doctors Prescribe Botox Remotely?
                          </button>
                        </h2>
                        <div
                          id="collapseseven"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingseven"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            According to GMC guidelines, doctors cannot prescribe
                            injectable cosmetic medicines like Botox® remotely. They must
                            conduct a physical examination of the patient. Prescribing through
                            telephone, video link, online, or based on requests for unexamined
                            patients is not permitted.
                          </div>
                        </div>
                      </div> */}

											<div
												class="accordion-item"
												data-aos="fade-up"
												data-aos-anchor="#faqSec"
												data-aos-offset="600"
												data-aos-duration="600"
											>
												<h2 class="accordion-header" id="headingeight">
													<button
														class="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseeight"
														aria-expanded="false"
														aria-controls="collapseeight"
													>
														What responsibilities do prescribers have when delegating botox administration?
													</button>
												</h2>
												<div
													id="collapseeight"
													class="accordion-collapse collapse"
													aria-labelledby="headingeight"
													data-bs-parent="#accordionExample"
												>
													<div class="accordion-body">
														Prescribers are responsible for ensuring that the person
														administering Botox is adequately trained and competent. They should
														also oversee the patient's care and treatment outcomes.
													</div>
												</div>
											</div>

											<div
												class="accordion-item"
												data-aos="fade-up"
												data-aos-anchor="#faqSec"
												data-aos-offset="650"
												data-aos-duration="650"
											>
												<h2 class="accordion-header" id="headingnine">
													<button
														class="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapsenine"
														aria-expanded="false"
														aria-controls="collapsenine"
													>
														How can i ensure compliance with botox prescribing regulations?
													</button>
												</h2>
												<div
													id="collapsenine"
													class="accordion-collapse collapse"
													aria-labelledby="headingnine"
													data-bs-parent="#accordionExample"
												>
													<div class="accordion-body">
														Compliance can be ensured by staying updated with the latest
														guidelines from the GMC and NMC, attending relevant training sessions,
														and adhering strictly to the protocols for patient examination, consent, and
														treatment.
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section className="FindSec">
							<div className="container">
								<div className="secTitle">
									<h5>
										Find a prescriber <br />
										<span className="blackText">near me</span>
									</h5>
								</div>
								<form
									action="javascript:void(0)"
									className="findPricriberForm"
									data-aos="fade-up"
								>
									<div className="inputGroup">
										{/* <input
                    type="text"
                    placeholder="Find a prescriber near you with our easy-to-use search tool."
                  /> */}

										<ReactGoogleAutocomplete
											icon={false}
											apiKey={googleApiKey}
											defaultValue={searchLocation}
											onPlaceSelected={(place, ref) => {
												console.log("onPlaceSelected ref", ref, ref.value);
												setSearchLocation(ref.value);
												// setSearchLocation(place.formatted_address);
												// const location = place.address_components.reduce(
												//   (seed, { long_name, types }) => (
												//     types.forEach((t) => (seed[t] = long_name)), seed
												//   ),
												//   {}
												// );
												// setLocation({
												//   ...location,
												//   street: location.route,
												//   address1: place.address_components[0].long_name,
												//   address2: place.address_components[1].long_name,
												//   postcode:
												//     location.postal_code === undefined
												//       ? ""
												//       : location.postal_code,
												//   city:
												//     location.locality === undefined
												//       ? location.postal_town === undefined
												//         ? ""
												//         : location.postal_town
												//       : location.locality,
												//   country: location.country,
												//   state:
												//     location.administrative_area_level_1 === undefined
												//       ? location.administrative_area_level_2
												//       : location.administrative_area_level_1,
												//   latitude: place.geometry.location.lat(),
												//   longitude: place.geometry.location.lng(),
												// });
												console.log(place, "i am getplace data");
												setLatitude(place?.geometry?.location?.lat());
												setLongitude(place?.geometry?.location?.lng());
												handleTrackGoogleAPI(2);
											}}
											placeholder="Find a prescriber near you with our easy-to-use search tool."
											className={"form-control"}
											value={searchLocation}
											onChange={(e) => { setSearchLocation(e.target.value); handleTrackGoogleAPI(1); }}
											options={{ types: [], componentRestrictions: { country: "uk" }, }}
										/>

										<button type="button" className="Squarebtn SquareIconbtn" onClick={handleFindPres}>
											<span className="btnIcon">
												<IoLocationSharp />
											</span>
											Find it now
										</button>
									</div>
								</form>
							</div>
						</section>
						<BusinessFooter />
					</>
				)}


			</div>
			<CustomModal
				modalOpen={isyouTube}
				setModalOpen={setIsyouTube}
				type={"review"}
				className="YouTubeModel"
				modalBody={
					<iframe width="560" height="315" src="https://www.youtube.com/embed/J2pUvqxdUiw?feature=share" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
				}
			/>
			<CustomModal
				modalOpen={reviewViewMore}
				setModalOpen={setreviewViewMore}
				type={"review"}
				className="WebsiteBuilderReviewMoreDataModel"
				modalBody={
					<>
						<div className="reviewModelWrapper">
							<div className="reviewDescWrapper">
								{/* <h5>Fascinating Experience</h5> */}
								<p>{reviewDataModel?.userReview}</p>
							</div>
							<div className="reviewLogoWrapper">
								<div className="reviewImgWrapper">
									<div className="reviewImg" onClick={() => { window.open(reviewDataModel?.reviewUrl, 'blank') }}>
										<img src={reviewDataModel?.userImage} />
									</div>
									<div className="reviewContentWrapper">
										<h5 onClick={() => { window.open(reviewDataModel?.reviewUrl, 'blank') }}>{reviewDataModel?.userName}</h5>
										<span onClick={() => { window.open(reviewDataModel?.reviewUrl, 'blank') }}>{reviewDataModel?.reviewDate}</span>
									</div>
								</div>
								<span className="comLogo">
									<img src={googleLogo} />
								</span>
							</div>

						</div>
					</>
				}
			/>
		</>
	);
};

export default FindAPrescriberLandingPage;
