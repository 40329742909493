import React, { useEffect, useRef, useState } from 'react';
import './appointment-form-main.scss';
import CustomRadioButton from '../../../common/CustomRadioButton';
import emptySignLogo from '../../../../images/signature pop-icon/empty_sign.svg';
import editHeaderImg from '../../../../images/IMage.svg';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import deleteIcon from '../../../../images/icons/delele_icon.svg';
import { ReactComponent as EditIcon } from '../../../../images/icons/edit-name-icon.svg';
import { ReactComponent as CloseIcon } from '../../../../images/icons/close-edit-icon.svg';
import backArrow from '../../../../images/back_arrow.svg';
import closeIcon from '../../../../images/close_icon.svg';
import StepButton from '../../../common/StepButton';
import { useNavigate, useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';
import beforeafterimage from '../../../../images/beforeUpload.svg';
import { IoCloseOutline } from 'react-icons/io5';
import CustomModal from '../../../modal/CustomModal';
import showToast from '../../../common/CustomToast/CustomToast';
import {
    AfterCareFormDetails,
    CreateAndEditAfterCareForm,
    DeleteAftercareFormMutation,
    UploadAfterCareDocumentMutation,
} from '../../../redux/actions/walk-in-form/walkInAddFormsList';
import Skeleton from 'react-loading-skeleton';
import Resizer from 'react-image-file-resizer';
import PdfImage from '../../../../images/pdf-image.svg';
import SkeletonLoader from '../../../common/Skeleton/SkeletonLoader';
import { useSearchParams } from 'react-router-dom';
import EditableTitle from '../../../manage-forms/edit-form/common-component/EditableTitle';
import CustomeDeleteModel from '../../../CustomeDeleteModel/CustomeDeleteModel';

const EditAfterCareForm = () => {
    const [searchParams] = useSearchParams();

    const nameInputRef = useRef();
    const initialActiveTab = searchParams.get('tab');
    const { aftercareFormId } = useParams();
    const parsedFromId = aftercareFormId ? +aftercareFormId : null;
    const navigate = useNavigate();
    const [uploadedImg, setUploadedImg] = useState(null);
    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
    const [formName, setFormName] = useState('');
    const [nameError, setNameError] = useState(null);
    const [imageError, setImageError] = useState(null);
    const [isEditingName, setIsEditingName] = useState(false);

    const { initCreateOrUpdateAfterCareFormData, createOrUpdateAfterCareFormQueryData } =
        CreateAndEditAfterCareForm();
    const { initAfterCareFormDetails, aftercareFormDetailsQueryData } = AfterCareFormDetails();
    const { initUploadAfterCareDocument, uploadAfterCareDocumentStatus } =
        UploadAfterCareDocumentMutation();
    // const { initDeleteAftercareForm, deleteAftercareFormStatus } = DeleteAftercareFormMutation();

    const isLoading =
        aftercareFormDetailsQueryData.loading ||
        uploadAfterCareDocumentStatus.loading ||
        // deleteAftercareFormStatus.loading ||
        createOrUpdateAfterCareFormQueryData.loading;

    useEffect(() => {
        if(!parsedFromId) {
            setIsEditingName(true);
            // setTimeout(() => {
            //     nameInputRef.current.focus();
            // }, 100);
            return;
        }

        // api call will only happen when editing form - not for new form
        initAfterCareFormDetails(
            { aftercareId: parsedFromId },
            (data) => {
                console.log('alsdfjaivaladfkj', data);
                setFormName(data.aftercareFormName);
                const fetchedFileurl = data.fileUrl;
                if (fetchedFileurl && fetchedFileurl.length > 0) {
                    const fileName = fetchedFileurl.slice(fetchedFileurl.lastIndexOf('/') + 1);
                    const fileExt = fileName.slice(fileName.lastIndexOf('.') + 1);

                    let uploadedSrc = fetchedFileurl,
                        imageType = 'image';
                    if (fileExt.includes('pdf') || fileExt.includes('doc')) {
                        uploadedSrc = PdfImage;
                        if(fileExt.includes('doc')) {
                            imageType = 'doc';
                        }
                        else imageType = 'pdf';
                    }
                    setUploadedImg({
                        src: uploadedSrc,
                        name: fileName,
                        type: imageType,
                        pdfUrl: data.fileUrl,
                    });
                }
            },
            (err) => {
                showToast('error', err.message);
            }
        );
    }, [parsedFromId]);

    const handleOnClose = () => {
        navigate(initialActiveTab ? `/manage-forms?tab=${initialActiveTab}` : '/manage-forms');
    };
    const navigateToMyforms = () => {
        navigate(`/manage-forms?tab=${4}`);
    };

    const resizeFile = (file) => {
        const outputType = file.type.slice(file.type.lastIndexOf('/') + 1);
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                1000,
                outputType,
                50,
                0,
                (uri) => {
                    resolve(uri);
                },
                'file'
            );
        });
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': [],
            'application/pdf': [],
            'application/msword': ['.doc', '.docx'],
        },
        onDrop: async (acceptedFiles, fileRejections) => {
            if (fileRejections.length > 0) {
                showToast('error', 'Invalid file type');
                return;
            }
            const file = acceptedFiles[0];
            const fileUrl = URL.createObjectURL(file);
            console.log('file.type', file.type);
            let uploadedSrc = fileUrl,
                pdfSrc = null,
                imageType = 'image';
            if (!file.type.includes('image')) {
                uploadedSrc = PdfImage;
                if(file.type.includes('pdf')) {
                    imageType = 'pdf';
                } else {
                    imageType = 'doc';
                }
                pdfSrc = fileUrl;
            }

            let resizedFile = file;
            if (file.type.includes('image')) {
                resizedFile = await resizeFile(file);
            }

            setUploadedImg({
                src: uploadedSrc,
                blob: resizedFile,
                name: file.name,
                type: imageType,
                pdfSrc
            });
            setImageError(null);
            // initUploadAfterCareDocument(
            //     {
            //         afterCareFormId: parsedFromId,
            //         file: resizedFile,
            //     },
            //     (res) => {
            //         showToast('success', res.message);
            //     },
            //     (err) => {
            //         showToast('error', err.message);
            //     }
            // );
        },
    });

    const handleDeleteImage = () => {
        setUploadedImg(null);
        setIsDeleteWarningOpen(false);
        // initDeleteAftercareForm(
        //     {
        //         aftercareFormId: parsedFromId,
        //     },
        //     (res) => {
        //         showToast('success', res.message);
        //         handleOnClose();
        //     },
        //     (err) => {
        //         showToast('error', err.message);
        //     }
        // );
    };

    const handleFileUpload = (formId) => {
        initUploadAfterCareDocument(
            {
                afterCareFormId: formId,
                file: uploadedImg.blob,
            },
            (res) => {
                // navigate to my forms
                navigateToMyforms();
                showToast('success', res.message);
            },
            (err) => {
                showToast('error', err.message);
            }
        );
    };

    const handleSubmit = () => {
        let hasError = false;
        if (formName.length === 0) {
            hasError = true;
            setNameError('Please enter form name!');
        }
        if (!uploadedImg) {
            hasError = true;
            setImageError('Please upload image!');
        }

        if (hasError) {
            return;
        }

        initCreateOrUpdateAfterCareFormData(
            {
                createAftercareFormId: parsedFromId,
                aftercareFormName: formName,
            },
            (res) => {
                const newAftercareID = res.data.createAftercareForm.data.id;
                console.log('create-aftercare-response', res);
                console.log('newAftercareID', newAftercareID);

                // if image is uploaded by user perform api call - else redirect to my forms
                if (uploadedImg.blob) {
                    handleFileUpload(newAftercareID);
                } else {
                    // navigate to my forms
                    navigateToMyforms();
                }
            },
            (err) => {
                showToast('error', err.message);
            }
        );
    };

    return (
        <>
            <div className="form-mode-indicator">
                <p>You're in editing mode</p>
            </div>
                <div className="header-buttn custom-header-padding">
                    <img
                        className="cursor-pointer"
                        onClick={handleOnClose}
                        src={closeIcon}
                        width={40}
                        height={40}
                        alt="img"
                    />
                </div>
                {aftercareFormDetailsQueryData.loading || (parsedFromId && !aftercareFormDetailsQueryData.called) ? (
                    <div className="form-content maxWidth540">
                        <SkeletonLoader type="preview-aftercare-form-skeleton" />
                    </div>
                ) : (
                    <div className="form-content maxWidth540">
                        <EditableTitle 
                            text={formName}
                            onTextChange={(text) => setFormName(text)}
                            isEditing={isEditingName}
                        />

                        <div className="form-edit-options">
                            <label className="upload-img-label" htmlFor="upload-img">
                                Upload Image
                            </label>
                            {uploadedImg ? (
                                <div
                                    style={{ cursor: uploadedImg.type === 'doc' ? 'default' : 'pointer'}}
                                    onClick={() => {
                                        if (uploadedImg.type !== 'doc') setIsImagePreviewOpen(true);
                                        
                                        // if (uploadedImg.type === 'doc') {
                                        //     // return;
                                        //     // window.open(uploadedImg?.pdfUrl || uploadedImg?.pdfSrc);
                                        // } else setIsImagePreviewOpen(true);
                                    }}
                                    className={`DropzoneStyle ${
                                        uploadedImg.type === 'image' ? 'no-border' : ''
                                    }`}
                                >
                                    <img
                                        className={`uploaded-image ${
                                            ['pdf', 'doc'].includes(uploadedImg.type) ? 'uploaded-image--pdf' : ''
                                        }`}
                                        src={uploadedImg.src}
                                        alt={uploadedImg.name}
                                    />
                                    {['pdf', 'doc'].includes(uploadedImg.type) && 
                                        <p className='DropzoneStyle__title'>
                                            {uploadedImg.name}
                                        </p>
                                    }
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsDeleteWarningOpen(true);
                                        }}
                                        className="removeImg"
                                    >
                                        <IoCloseOutline />
                                    </div>
                                </div>
                            ) : (
                                <div className="DropzoneStyle" {...getRootProps()}>
                                    <input id="upload-img" {...getInputProps()} />
                                    <div className="uploadWrapper">
                                        <img src={beforeafterimage} alt="Placeholder Image" />
                                        <p className="add-more">Upload</p>
                                    </div>
                                </div>
                            )}
                            {imageError && <p className="error">{imageError}</p>}
                        </div>
                        <div className="save-btn-wrapper">
                            <StepButton
                                isLoading={isLoading}
                                label={'Save form'}
                                // disabled={isLoading}
                                disabled={isLoading || !uploadedImg || formName.length === 0}
                                blue={true}
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                )}
                <CustomeDeleteModel
                    modalOpen={isDeleteWarningOpen}
                    setModalOpen={setIsDeleteWarningOpen}
                    DeleteModelTitle={'Are you sure?'}
                    DeleteModelDesc={`Are you sure you want to delete this document? This action cannot be
                                reversed.`}
                    onDeleteClick={handleDeleteImage}
                    onCancleClick={() => {
                        setIsDeleteWarningOpen(false)
                    }}
                />
                <CustomModal
                    modalOpen={isImagePreviewOpen}
                    setModalOpen={setIsImagePreviewOpen}
                    modaltitle={uploadedImg?.name}
                    className="aftercare-image-preview-modal"
                    modalBody={
                        <>
                            {uploadedImg?.type === 'pdf' ? 
                                <iframe 
                                    className='aftercare-image-preview-iframe' 
                                    title='pdf viewer' 
                                    src={uploadedImg?.pdfUrl || uploadedImg?.pdfSrc} 
                                />
                                : 
                                <div className="DropzoneStyle preview-img">
                                    <img
                                        className="uploaded-image"
                                        src={uploadedImg?.src}
                                        alt={uploadedImg?.name}
                                    />
                                </div>
                            }
                            <StepButton
                                onClick={() => {
                                    setIsImagePreviewOpen(false);
                                    setIsDeleteWarningOpen(true);
                                }}
                                red
                                label="Delete"
                            />
                        </>
                    }
                />
        </>
    );
};

export default EditAfterCareForm;
