import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const GENERATE_LINK_CARD = gql`
mutation GenerateCardLink {
    generateCardLink {
      success
      message
      data {
        card_link
      }
    }
  }
`;

export const GenerateLinkCardPath = () => {
    // const dispatch = useDispatch()
    const [generateLinkCardPathFunc, generateLinkCardPath] = useMutation(GENERATE_LINK_CARD);
    const generateLinkCardPathData = generateLinkCardPath;

    const initiategenerateLinkCardPath = (
        data,
        successCallback,
        errorCallback
    ) => {
        generateLinkCardPathFunc({
            variables: data,
        })
            .then(({ data: { generateCardLink: responseData } }) => {
                if (responseData?.success) {
                    toast.success(responseData.message)
                    successCallback(responseData);
                } else {
                    toast.error(responseData.message)
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                errorCallback(error);
            });
    };

    return { initiategenerateLinkCardPath, generateLinkCardPathData }
}