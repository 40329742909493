import { useEffect } from "react";
import { useSelector } from "react-redux";
import useDeviceDetect from "../common/useDeviceDetect";
import { TeamMemberList } from "../redux/actions/teamMemberList/getTeamMemberList";
import TeamMemberMobile from "./TeamMemberMobile";
import TeamMemberWeb from "./TeamMemberWeb";

const TeamMember = ({
  setStep,
  step,
  selectedTeamMember,
  setSelectedTeamMember,
  selectedTreatment,
  setSelectedTreatment,
  setSelectedTreatmentId,
  selectedTreatmentId,
  setSubStep,
  substep,
  // setShowSelectedTeamMember


}) => {
  const { deviceType, screenWidth } = useDeviceDetect();
  const {
    initTeamMemberList,
    teamMemberListQueryData: { data: teamMemberData, loading: teamMemberLoading },
  } = TeamMemberList();
  const teamMemberListData = useSelector(
    (state) => state.teamMemberListReducer?.teamMemberList?.team_members
  );
  const treatmentIds = [];
  // Assuming each team member has an "id" property
  selectedTreatment?.forEach((treatments) => {
    // Check if the "id" property exists and is a valid value
    if (treatments.id) {
      treatmentIds.push(treatments.id);
    }
  });

  useEffect(() => {
    if (treatmentIds?.length > 0)
      initTeamMemberList({
        treatmentId: treatmentIds,
      });
    setSelectedTreatmentId(treatmentIds);
  }, []);

  const uniqueTeamMember = Array.from(
    new Set(
      teamMemberData?.getAvailableTeamMembers?.data?.team_members?.map(
        (teamMember) => teamMember.id
      )
    )
  ).map((id) => {
    return teamMemberData?.getAvailableTeamMembers?.data?.team_members?.find(
      (teamMember) => teamMember.id === id
    );
  });
  return screenWidth < 720 ? (
    <TeamMemberMobile
      setStep={setStep}
      substep={substep}
      setSubStep={setSubStep}
      selectedTreatment={selectedTreatment}
      setSelectedTreatment={setSelectedTreatment}
      step={step}
      isLoading={teamMemberLoading}
      setSelectedTeamMember={setSelectedTeamMember}
      selectedTeamMember={selectedTeamMember}
      teamMemberData={uniqueTeamMember}
    />
  ) : <TeamMemberWeb
    setStep={setStep}
    substep={substep}
    setSubStep={setSubStep}
    selectedTreatment={selectedTreatment}
    setSelectedTreatment={setSelectedTreatment}
    step={step}
    setSelectedTeamMember={setSelectedTeamMember}
    selectedTeamMember={selectedTeamMember}
    teamMemberData={uniqueTeamMember}
    setTeamMemberSelectFun={(e) => {
      setSelectedTeamMember(e)
    }}
  />



};

export default TeamMember;
