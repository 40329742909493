import { gql, useMutation } from "@apollo/client";

const CHECK_PASSWORD_MUTATION = gql`
  mutation CheckPassword($password: String) {
    checkPassword(password: $password) {
      success
      message
      data {
        passwordValid
      }
    }
  }
`;

export const CheckPassword = () => {
    const [checkPasswordRequest, checkPasswordResponseData] =
        useMutation(CHECK_PASSWORD_MUTATION);
    const checkPasswordMutationData = checkPasswordResponseData;

    const initiateCheckPassword = (
        data,
        successCallback,
        errorCallback
    ) => {
        checkPasswordRequest({
            variables: data,
        })
            .then(({ data: { checkPassword: responseData } }) => {
                if (responseData) {
                    successCallback(responseData);
                } else {
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                errorCallback(error);
            });
    };

    return { initiateCheckPassword, checkPasswordMutationData };
};