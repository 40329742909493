import React, { useEffect, useState } from "react";
import insuranceCheckedLogo from "../../../images/thank-you/checked.png";

// import './insurance-thank-you.css'
import "./social-media-landing.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import {  webBaseUrl } from "../../../config";
import HeaderComponent from "../header-component/header-component";
import Cookies from "js-cookie";

import Sidebar from "../../sidebar";
import SidebarComponent from "../../sidebar";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import BusinessHeader from "../../businessHeader";
import BusinessFooter from "../../businessFooter";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { TfiMenu } from "react-icons/tfi";
import { PlansCarousel } from "./social-media-marketing-plans";
import { TestimonialsCarousel } from "./testimonials-carousle";
import SocialMediaMarketingUserImg from "../../../images/social-media-marketing/social-media-marketing-user-img.png";
import { TrackUserActivityForServicesAction } from "../../redux/actions/social-media-marketing/service-user-activity";
import AccountancyServiceUserImg from "../../../images/accountancy-services/accountancy-service-user-img.jpg";
import { Faqs } from "./faqs";
import getDashboardList from "../../redux/actions/dashboard/dashboardList";
import logo from "../../../images/icons/faces-logo-black.svg";
import closeIcon from "../../../images/Icon example.svg";
import { GetAccountancyOtherBasicDetailsAction } from '../../redux/actions/accountancy-service/basic-detais';
import whyChooseImg from "../../../images/why-choose-us.png";
import './accountacy-service.scss'
import committedImg1 from "../../../images/committedSuccessImg-1.png";
import committedImg2 from "../../../images/committedSuccessImg-2.png";
import committedImg3 from "../../../images/committedSuccessImg-3.png";
import simpleOnboardingImg from "../../../images/simpleOnboarding.png";
import keyBenefitsImg1 from '../../../images/keyBenefitsImg1.png';
import keyBenefitsImg2 from '../../../images/keyBenefitsImg2.png';
import keyBenefitsImg3 from '../../../images/keyBenefitsImg3.png';
import keyBenefitsImg4 from '../../../images/keyBenefitsImg4.png';

function AccountancyServicePage() {
  const { width, height } = useWindowDimensions();
  const [showSidebar, setShowSidebar] = useState(true);
  // const calendlyLink = "https://calendly.com/supportfaces";
  const calendlyLink = "https://calendly.com/facesconsent";
  let isMobile = false;
  if (width <= 991) {
      isMobile = true;
  }
  else {
      isMobile = false;
  }
  useEffect(() => {
    isMobile ? setShowSidebar(false) : setShowSidebar(true);
  }, [isMobile]);

  /*
    const [serviceUserActivity] = useMutation(SERVICE_USER_ACTIVITY_MUTATION);
    useEffect(() => {
        serviceUserActivity({ variables: { serviceId: 1, activityId: 1 } });
    }, []); */

  const [openReviewPopup, setOpenReviewPopup] = useState(false);
  // const dashboardListData = useSelector((state) => state.dashboardReducer.dashboardListData)
  // const { profileData } = useSelector(state => state?.GetProfileDataReducer)
  const BackToHome = () => {
    window.top.location.href = webBaseUrl + "user/dashboard";
  };
  const handleReviewModalClick = () => {
    setOpenReviewPopup(true);
  };

  // For managing service user activity
  const {
    trackUserActivityForServicesQueryData,
    initTrackUserActivityForServicesData,
  } = TrackUserActivityForServicesAction();
  useEffect(() => {
    initTrackUserActivityForServicesData({ serviceId: 2, activityId: 1 });
  }, []);

  const calendlyLinkClick = () => {
    initTrackUserActivityForServicesData({ serviceId: 2, activityId: 4 });
  };

  const packageClickUserActivity = ( packageSlug, successCallBack, errorCallBack ) => {
    if(!LoggedInUser){
      successCallBack();
    }else{
      console.log('packageSlug', packageSlug, successCallBack);
      let result = initTrackUserActivityForServicesData({
        serviceId: 2,
        activityId: 2,
        packageSlug: packageSlug,
      },
      successCallBack,
      errorCallBack);
      return result;
    }

  };

  const dashboardListData = useSelector((state) => state.dashboardReducer.dashboardListData)
  const { profileData } = useSelector(state => state?.GetProfileDataReducer)

  const [LoggedInUser, setLoggedInUser] = useState(false);


  useEffect(()=>{
    if(profileData){
      setLoggedInUser(true);
    }
  },[profileData])

  const dispatch = useDispatch();
  useEffect(() => {
    if (dashboardListData == null)
      dispatch(getDashboardList({
        "business-average-reviews": true,
        platform: "frontend"
      }))
  }, [])
  const whyChooseUs = [
    {
      serialNo:'01',
      question: 'Proven Expertise',
      answer: 'Supporting thousands of professionals in the aesthetic and beauty industry.',
    },
    {
      serialNo:'02',
      question: 'Personalised Service',
      answer: 'Your dedicated accountant will understand your business, aiding its growth.',
    },
    {
      serialNo:'03',
      question: 'Hassle-Free Bookkeeping',
      answer: 'Using Xero software, specifically designed for busy professionals.',
    },
    {
      serialNo:'04',
      question: 'Unlimited Expert Advice',
      answer: 'Always available to address your tax and financial queries.',
    }
  ];
  // const committedSuccess = [
  //   {
  //     commitedImgserial: committedImg1,
  //     boxHeading: 'Comprehensive Support',
  //     boxDescription: 'Unlimited assistance via WhatsApp, email, or video.',
  //   },
  //   {
  //     commitedImgserial: committedImg2,
  //     boxHeading: 'Proactive Financial Management',
  //     boxDescription: 'Regular check-ins and automated tools to streamline your accounting. ',
  //   },
  //   {
  //     commitedImgserial: committedImg3,
  //     boxHeading: 'Trusted Qualifications',
  //     boxDescription: "Our team's expertise is backed by accreditations from leading professional bodies.",
  //   }
  // ];
  const simpleOnboarding = [
    {
      simpleOnboardingSerial: "1",
      packageHeading: 'Select Package',
      packageDescription: 'Select from one of the packages that suits your business.',
    },
    {
      simpleOnboardingSerial: "2",
      packageHeading: 'Register Easily',
      packageDescription: 'A brief registration to start the journey.',
    },
    {
      simpleOnboardingSerial: "3",
      packageHeading: 'Warm Welcome',
      packageDescription: "Meet your dedicated accountant and get started.",
    }
  ];
  const keyBenefits = [
    {
      colors: '#F9F3F4',
      keyBenefitsImg: keyBenefitsImg1,
      keyBenefitsHeading: 'Transparent Pricing',
      keyBenefitsDescription: 'No hidden fees, with clear costs for annual account completion and tax return submission.',
    },
    {
      colors: '#ECF9F8',
      keyBenefitsImg: keyBenefitsImg2,
      keyBenefitsHeading: 'Proactive Support',
      keyBenefitsDescription: 'Regular reminders for financial deadlines, letting you focus on your business.',
    },
    {
      colors: '#EDF2FE',
      keyBenefitsImg: keyBenefitsImg3,
      keyBenefitsHeading: 'Tax Efficiency',
      keyBenefitsDescription: "Annual reviews to ensure maximum tax savings.",
    },
    {
      colors: '#F4FAEF',
      keyBenefitsImg: keyBenefitsImg4,
      keyBenefitsHeading: 'All Online',
      keyBenefitsDescription: "No need for lengthy phone calls, our service is all done online, giving you more time.",
    }
  ];

  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
    Cookies.get("faces_access_token") ? setIsAuth(true) : setIsAuth(false);
    console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
  });

  const { getAccountancyOtherBasicDetailsQueryData, initGetAccountancyOtherBasicDetailsData } = GetAccountancyOtherBasicDetailsAction();
  const [ basicDetailsData, setBasicDetailsData ] = useState(null);
  const [ inquireNowLink, setInquireNowLink ] = useState('');
  useEffect(() => {
    initGetAccountancyOtherBasicDetailsData();
  }, []);
  useEffect(() => {
    if (getAccountancyOtherBasicDetailsQueryData?.data?.getAccountancyOtherBasicDetails?.data) {
      setBasicDetailsData(getAccountancyOtherBasicDetailsQueryData?.data?.getAccountancyOtherBasicDetails?.data);
    }
  }, [getAccountancyOtherBasicDetailsQueryData]);
  useEffect(() => {
    if(basicDetailsData) {
      setInquireNowLink(`https://api.whatsapp.com/send?phone=${basicDetailsData?.country_code}${basicDetailsData?.phone_no}`);
    }    
  }, [basicDetailsData]);

  return (
    <>
      <BusinessHeader 
        openMobileMenu={() => {
          setShowSidebar(!showSidebar);
        }}
        isMobile={isMobile}
      />
      <div className="container-fluid">
        <div className="dashboard-parent-container sidebarDesignUpdateSprint6">
       
          {isMobile ? (
            <div className={showSidebar ? " MobileSidebar ActiveSidebar" : "MobileSidebar"}>
              <div
                className="overlaySidebar"></div>
              <div className="sidebar">
                <div className="sidebarLogoWrapper">
                  <div className="logoSidebar">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="sidebarClose" onClick={() => { setShowSidebar(false) }} >
                    <img src={closeIcon} alt="closeIcon" />
                  </div>
                </div>
                {isAuth && <SidebarComponent />}
              </div>
            </div>
          ) : (
            // isAuth &&
            <div className="sidebarPart">
              <div className="sidebar">
                <SidebarComponent />
              </div>
            </div>
          )}
          <div className={isAuth ? "" : "col-lg-12"}>
            <div className="socialMediaMarketingDiv" id="parentDiv">
              <div className="headerDiv">Accountancy Specialist</div>
              <div>
                <p className="customTitle">About Us</p>
                <span className="customHr"></span>
                <p className="customSubContent">
                  With tailored accounting solutions for the Aesthetics and
                  Beauty industry, focus on what you do best while we take care
                  of the numbers.
                </p>
              </div>
              <div>
                <p className="customTitle">Our Services</p>
                <span className="customHr"></span>
                {/* <p className='customSubContent'>Choose from our variety of tailored packages.</p> */}
              </div>
              <div className="plansCarousel" id="plans">
                <PlansCarousel prntFn={packageClickUserActivity} inquireNowLink={inquireNowLink} />
              </div>
              <div className="accountancyServices">
                <h4>Why Choose Our Accountancy Services</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="box">
                      {
                        whyChooseUs.map((whyChooseData, index) => (
                          <div className="d-flex accountancy-box" key={index}>
                            <div className="serial">
                              <span>{whyChooseData.serialNo}</span>
                            </div>
                            <div className="description">
                              <h5>{whyChooseData.question}</h5>
                              <p>{whyChooseData.answer}</p>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="col-md-6 d-none d-md-flex">
                    <figure>
                    <img src={whyChooseImg} alt="WhyChooseImg" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="keyBenefits">
                <h4>Key Benefits</h4>
                <div className="row">
                  {
                      keyBenefits.map((keyBenefitsData, index) => (
                        <div className="col-md-6 key-benefits-container">
                          <div className="box" style={{background: keyBenefitsData.colors}}>
                            <figure>
                              <img src={keyBenefitsData.keyBenefitsImg} alt="WhyChooseImg" />
                            </figure>
                            <div className="keyBenefitsBox">
                              <h5>{keyBenefitsData.keyBenefitsHeading}</h5>
                              <p>{keyBenefitsData.keyBenefitsDescription}</p>
                            </div>
                          </div>
                        </div>   
                      ))
                    }
                </div>
              </div>
              <div className="simpleOnboarding">
                <div className="row align-items-center">
                  <div className="col-md-6 d-none d-md-block">
                    <figure>
                      <img src={simpleOnboardingImg} alt="simpleOnboardingImg" />
                    </figure>
                  </div>
                  <div className="col-md-6">
                    <h4>Simple Onboarding Process:</h4>
                    <div className="box">
                      {
                        simpleOnboarding.map((simpleOnboardingData, index) => (
                          <div className="d-flex packageCusWrap">
                            <div className="packageNo">{simpleOnboardingData.simpleOnboardingSerial}</div>
                            <div className="packageBox">
                              <h5>{simpleOnboardingData.packageHeading}</h5>
                              <p>{simpleOnboardingData.packageDescription}</p>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="committedSuccess">
                <h4>Committed to Your Success</h4>
                <div className="row">
                {
                    committedSuccess.map((committedSuccessData, index) => (
                      <div className="col-md-4">
                        <div className="box">
                          <figure>
                            <img src={committedSuccessData.commitedImgserial} alt="WhyChooseImg" />
                          </figure>
                          <div className="description-box">
                            <h5>{committedSuccessData.boxHeading}</h5>
                            <p>{committedSuccessData.boxDescription}</p>
                          </div>
                        </div>
                      </div>   
                    ))
                  }
                </div>
              </div> */}
              <div className="testimonialsDiv">
                <p className="testimonialsTitle">Testimonials</p>
                <span className="customHr"></span>
                <div className="testimonials">
                  <TestimonialsCarousel />
                </div>
              </div>
              {/* https://i.pinimg.com/736x/ee/ea/c7/eeeac723d4663de7b93329b6e96da1ef.jpg */}              
            </div>
            <Faqs />
            <div className="bookingDetailsDiv">
              <div>
                <div>Questions?</div>
                <div>Let's Chat! 📞 Book a 30-Min Call</div>
                <div>Joshua Thomas Aesthetic Accounting</div>
              </div>
              <div className="bookingDetailsSubDiv">
                <div>
                  {/* 📆 */}
                  <a
                    href={inquireNowLink}
                    target="_blank"
                    rel="noreferrer"
                    // onClick={calendlyLinkClick}
                    style={{color: '#424242'}}
                  >
                    {/* Book Now */}
                    Enquire now
                  </a>
                </div>
                <div>
                  <a href="#plans" className="btn btnredsm rounded-pill px-4" style={{textTransform: 'none'}}>
                    Purchase A Plan
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BusinessFooter />
    </>
  );
}

export default AccountancyServicePage;
