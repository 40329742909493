import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_NEXTOFKIN_DETAILS } from "../../constants/client/NextOfKinConst";


const GET_IDENTITY_DATA_QUERY = gql`
query GetClientIdentityDocuments($clientId: Int!) {
    getClientIdentityDocuments(clientId: $clientId) {
      success
      message
      data {
        documents {
          documentId
          document
          documentName
        }
        total
      }
    }
  }
`;


export const GetIdentityDocumentDataQuery = () => {
  const dispatch = useDispatch();
  const [getIdentityData, identityData] = useLazyQuery(
    GET_IDENTITY_DATA_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClientIdentityDocuments: responseData }) => {
        dispatch({
          type: GET_NEXTOFKIN_DETAILS,
          payload: responseData.data,
        });
      },
    }
  );
  const identityQueryData = identityData;
  const initGetIdentity = (data, successCallback, errorCallback) => {
    getIdentityData({
      variables: data,
    }).then((res) => {
      console.log("identity akash", res)
      successCallback(res.data.getClientIdentityDocuments)
    }).catch((err) => {
      errorCallback(err);
    })
  };
  return { initGetIdentity, identityQueryData };
};
