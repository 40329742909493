import React ,{useEffect,useRef} from 'react'
import './SubHeading.scss'

const SubHeading = ({title , desc,scrollRef}) => {
    
    console.log("scrollRef",scrollRef)
   useEffect(()=>{
       window.scrollTo(0, 0);
    },[])
   
    return (
        <div className="Hair-product-sub-heading" >
            <div className="Hair-product-sub-heading-content" >
                <h1 ref={scrollRef}>{title}</h1>
                <p>{desc}</p>
            </div>
        </div>
    )
}

export default SubHeading