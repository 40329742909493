import React from 'react'
import "./product-question.scss"
import CustomRadioButton from '../../common/CustomRadioButton'
import {Tooltip} from "react-tooltip"
import SingleQuestion from './SingleQuestion'
import MultiQuestions from './MultiQuestions'

const MainQueSection = (
  {index,
  quesId,
  question,
  questionType,
  setQuestionType,
  handleRemoveQuestion,
  handleChange,
  handleOptionChange,
  handleOptionRadioChange,
  error,
  disabled=false,
  createTest,
  toggleCreateTest,
  handleSubmit,
  step,
  queIndex,
  questionError,
  quesNumber,
  answerError
}
  ) => {
    console.log("disabled",disabled)
  return (
    <>
   <div className='main-questions-area'>
   {/* {
    step  && ( */}
      <div className='InputWrapper questionsInputMain'>
         <label> Type of question :</label>
           <div className='InputField questionsInputWrapper'>
            <div
             data-tooltip-id="warning-tooltip1"
             data-tooltip-content="Please remove all questions to change question type"
             className={`col-12 col-md-auto mt-2 sm-full-colum   ${disabled ? "" : ""} ${(disabled && questionType !== 1) ? "custom-extra-disabled-class" : "custom-extra-unable-class"}`}>
             <CustomRadioButton
              type="radio"
              preselect={true}
               name="que"
               id={`${queIndex}+single`}
               value="1"
               mainQue={true}
               text="Short Answer Question"
               onChange={() => {
                 setQuestionType(1);
               }}
               checked={questionType === 1 && true}
               disabled={disabled}
             />
            </div>
            {/* {
                    !(disabled && questionType == 1) &&
                    <Tooltip id="warning-tooltip1"
                      className="popular-course-tooltip" effect="solid" place="top" />
            } */}

              <div
                    data-tooltip-id="warning-tooltip2"
                    data-tooltip-content="Please remove all questions to change question type"
                    className={`col-12 col-md-auto mt-2 sm-full-colum ${disabled ? "" : ""} ${(disabled && questionType !== 2) ? "custom-extra-disabled-class" : "custom-extra-unable-class"}`}>
                    <CustomRadioButton
                      type="radio"
                      name="que"
                      preselect={true}
                      id={`${queIndex}+multiple`}
                      value="2"
                      mainQue={true}
                      text="Multiple Choice Questions"
                      onChange={() => {
                        setQuestionType(2);
                      }}
                      checked={questionType === 2 && true}
                      disabled={disabled}
                    />
              </div>

              {/* {
                    !(disabled && questionType == 2) &&
                    <Tooltip id="warning-tooltip2"
                      className="popular-course-tooltip" effect="solid" place="top" />
              } */}

                 <div
                    data-tooltip-id="warning-tooltip3"
                    data-tooltip-content="Please remove all questions to change question type"
                    className={`col-12 col-md-auto mt-2 sm-full-colum ${disabled ? "" : ""} ${(disabled && questionType !== 3) ? "custom-extra-disabled-class" : "custom-extra-unable-class"}`}>
                    <CustomRadioButton
                      type="radio"
                      name="que"
                      preselect={true}
                      id={`${queIndex}+document`}
                      value="3"
                      mainQue={true}
                      text="Case studies"
                      onChange={() => {
                        setQuestionType(3);
                      }}
                      checked={questionType === 3 && true}
                      disabled={disabled}
                    />
                </div>
                {/* {
                    !(disabled && questionType == 3) &&
                    <Tooltip id="warning-tooltip3"
                      className="popular-course-tooltip" effect="solid" place="top" />
                  } */}
           </div>

          
      </div>
    {/* )
   } */}
      
      {
        questionType === 1 && (
          <SingleQuestion 
            queNo={quesNumber}
            quesId={quesId}
            question={question}
            questionType={questionType}
            handleRemoveQuestion={handleRemoveQuestion}
            handleChange={handleChange}
            error={questionError}
            answerEor={answerError}
          />
        )
      }
    
    {
      questionType === 2 && (
        <MultiQuestions
           queNo={quesNumber}
           quesId={quesId}
           question={question}
           handleRemoveQuestion={handleRemoveQuestion}
           handleChange={handleChange}
           handleOptionChange={handleOptionChange}
           handleOptionRadioChange={handleOptionRadioChange}
           handleSubmit={handleSubmit}
           questionType={questionType}
           error={questionError}
           answerEor={answerError}
         />
      )
    }
    {
      questionType === 3 && (
        <SingleQuestion 
          queNo={quesNumber}
          quesId={quesId}
          question={question}
          handleRemoveQuestion={handleRemoveQuestion}
          handleChange={handleChange}
          questionType={questionType}
          error={questionError}
          answerEor={answerError}
        />
      )
    }
           
           
   </div>
      
    </>
  )
}

export default MainQueSection