/*eslint-disable*/
import { add, debounce, set } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import Avatar from 'react-avatar'
import InfiniteScroll from 'react-infinite-scroll-component'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router"
import { useSearchParams } from 'react-router-dom'
import { Rating } from 'react-simple-star-rating'
import { toast } from 'react-toastify'
import { businessDashboardRoute } from '../../../Routes'
import { ReactComponent as SearchIcon } from '../../../images/search_icon.svg'
import ListImg from '../../../images/thank-you/list.png'
import { ReactComponent as AddIcon } from '../../../images/thank-you/product/add_icon.svg'
import { ReactComponent as DeleteIcon } from '../../../images/thank-you/product/delete_icon.svg'
import FilterIcon from '../../../images/thank-you/product/filter_icon.svg'
import { ReactComponent as RemoveIcon } from '../../../images/thank-you/product/minus_icon.svg'
import productImg from '../../../images/thank-you/product/product.png'
import { ReactComponent as ThankyouImage } from "../../../images/thank-you/success.svg"
import { default as SkeletonLoader, default as ThankyouPageProductSkeleton } from '../../common/Skeleton/SkeletonLoader'
import StepButton from '../../common/StepButton'
import CustomModal from '../../modal/CustomModal'
import { AddProductToCartMutation } from '../../redux/actions/consent-form-thankyou/addProductToCart'
import { AddReviewBookingMutation } from '../../redux/actions/consent-form-thankyou/addReviewForBooking'
import { CreatePrescriptionMutation } from '../../redux/actions/consent-form-thankyou/createPrescription'
import { GenerateBookingReviewQuery } from '../../redux/actions/consent-form-thankyou/generateBookingReview'
import { ConnectedPrescriberListQuery } from '../../redux/actions/consent-form-thankyou/getConnectedPrescriberList'
import { ConsentFormProductListQuery } from '../../redux/actions/consent-form-thankyou/getConsentFormProduct'
import { CLEAR_CONSENT_FORM_MORE_PRODUCT_LIST, CLEAR_CONSENT_FORM_PRODUCT_LIST } from '../../redux/constants/consentFormThankyou/consentFormProductConst'
import { CLEAR_PRESCRIBER_LIST } from '../../redux/constants/consentFormThankyou/getPrescriberListConst'
import ProductCard from '../../thank-you-product-card/ProductCard'
import PharmacyList from './PharmacyList'
import "./thankyoupage.scss"
import { ConsentFormMoreProductListQuery } from '../../redux/actions/consent-form-thankyou/getListOfProduct'
import { ReactComponent as ClinicIcon } from "../../../images/thank-you/registration/clinic.svg"
import { ReactComponent as PharmcyIcon } from "../../../images/thank-you/registration/pharmcy.svg"
import { ReactComponent as MedicalIcon } from "../../../images/thank-you/registration/medical.svg"
import { ReactComponent as PaylaterIcon } from "../../../images/thank-you/registration/paylater.svg"
import StokiestModal from './StokiestModal'
import { ProductTrackingThankyouPageMutation } from '../../redux/actions/consent-form-thankyou/productTracking'


const ThankyouPage = () => {
    const [additionalDataVisible, setAdditionalDataVisible] = useState(false);
    const [prescriptionModal, setPrescriptionModal] = useState(false);
    const [confirmPayModal, setConfirmPayModal] = useState(false);
    const [count, setCount] = useState(1)
    const [reviewModal, setReviewModal] = useState(false)
    // const [totalPrice, setTotalPrice] = useState(null)
    const [loader, setLoader] = useState(false)
    const [rating, setRating] = useState(0)
    const [review, setReview] = useState('')
    //GET CONSENT FORM ID
    const { consentFormId } = useParams()
    const [isDisbled, setIsDisabled] = useState(false)
    const [isPrescriptionRegisterModal, setIsPrescriptionRegisterModal] = useState(false)
    const [addMoreDataModal, setAddMoreDataModal] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState([])
    const navigate = useNavigate()
    const [isPrescriptionRequired, setIsPrescriptionRequired] = useState(false);
    //GET CONSENT FORM PRODUCT LIST QUERY
    const { consentFormProductListQueryData, initConsentFormProductcList } = ConsentFormProductListQuery()
    //ADD PRODUCT TO CART MUTATION
    const { addProductToCartMutationData, initiateAddProductToCart } = AddProductToCartMutation()
    //ADD REVIEW FOR BOOKING MUTATION
    const { addReviewBookingMutationData, initiateAddReviewBooking } = AddReviewBookingMutation()
    //GENERATE BOOKING REVIEW QUERY
    const { generateBookingReviewQueryData, initGenerateBookingReview } = GenerateBookingReviewQuery()
    //GET CONNECTED PRESCRIBER LIST QUERY
    const { conntectedPrescriberListQueryData, initConnectedPrescriberList } = ConnectedPrescriberListQuery()
    // CREATE PRESCRIPTION MUTATION
    const { createPrescriptionMutationData, initiateCreatePrescription } = CreatePrescriptionMutation();

    //GET MORE PRODUCT LIST QUERY
    const { consentFormMoreProductListQueryData, initConsentFormMoreProductcList } = ConsentFormMoreProductListQuery()
    //REDUCER DATA
    const { productList } = useSelector((state) => state.consentFormProductReducer);
    const { emptyProductList } = useSelector((state) => state.consentFormProductReducer);
    const { totalProductCount } = useSelector((state) => state.consentFormProductReducer);
    const { loadedProductCount } = useSelector((state) => state.consentFormProductReducer);
    const { productListPage } = useSelector((state) => state.consentFormProductReducer);
    const { isReviewPopup } = useSelector((state) => state.consentFormProductReducer)
    const { prescriberDetails } = useSelector((state) => state.consentFormProductReducer)
    //MORE PRODUCT LIST REDUCER
    const { moreProductList } = useSelector((state) => state.consentFormMoreProductReducer);
    const { emptyMoreProductList } = useSelector((state) => state.consentFormMoreProductReducer);
    const { totalMoreProductCount } = useSelector((state) => state.consentFormMoreProductReducer);
    const { loadedMoreProductCount } = useSelector((state) => state.consentFormMoreProductReducer);
    const { moreProductListPage } = useSelector((state) => state.consentFormMoreProductReducer);
    const { morePrescriberDetails } = useSelector((state) => state.consentFormMoreProductReducer);
    //GET PRESCRIBER LIST
    const { prescriberList } = useSelector((state) => state.connectedPrescriberListReducer);
    const { totalPrescriberCount } = useSelector((state) => state.connectedPrescriberListReducer);
    const { loadedPrescriberCount } = useSelector((state) => state.connectedPrescriberListReducer);
    const { prescriberListPage } = useSelector((state) => state.connectedPrescriberListReducer);
    const { emptyPrescriberList } = useSelector((state) => state.connectedPrescriberListReducer);
    //Loader for AI Review
    const [loaderAI, setLoaderAI] = useState(false)
    const [hideAiPrompt, setHideAiPrompt] = useState(false)
    const dispatch = useDispatch()
    const [isSearchTriggered, setIsSearchTriggered] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [searchProductQuery, setSearchProductQuery] = useState('');
    const [selectedPrescriberId, setSelectedPrescriberId] = useState(null);
    const [isOpenFilter, setIsOpenFilter] = useState(false)
    const [selectedPharmacy, setSelectedPharmacy] = useState([])
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const [selectedProductId, setSelectedProductId] = useState(null)
    const [pharmacyList, setPharmacyList] = React.useState([])
    const productRef = useRef(null)
    const [clinicName, setClinicName] = useState('')
    const [isPrescriber, setIsPrescriber] = useState(false)
    const [prescriberDetailsData, setPrescriberDetailsData] = useState(null);
    const [isCartButton, setIsCartButton] = useState(null)
    const [newSelectedProduct, setNewSelectedProduct] = useState([])
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmItem, setConfirmItem] = useState(null)
    const [openStokiestModal,setOpenStokiesModal]=useState(false)
    const [stokiestModal,setStokiestModal]=useState(false)
    const [stokiestMessage,setStokiestMessage]=useState("")
    const [clientDetails,setClientDetails]=useState({
        clientName:"",
        clientEmail:"",
        clientProfile:"",
        clientId:0
    })
    //const [cartItems, setCartItems] = useState([])

     //FOR TRACKING PRODUCT MUATAION
     const {initiateProductTrackingThankyouPage,productTrackingThankyouPageMutationData} = ProductTrackingThankyouPageMutation()

     const productTrackingAPICall = (consentId,productId)=>{
        initiateProductTrackingThankyouPage({
            consentFormId: parseInt(consentId),
            productId:parseInt(productId)
        },(res=>{
            console.log("res",res)
        }),(err=>{
            console.log("error", err)
        }))
      }

    const getPreselectedProducts = () => {
        return selectedProduct.map(item => item.id)
    }
    console.log("consent product list", productList, totalProductCount, loadedProductCount, productListPage)
    console.log("consent Prescriber list", prescriberList, totalPrescriberCount, loadedPrescriberCount, prescriberListPage)

    //PRESCRIBER SEARCH
    const initiatePrescriberListCall = (refreshList = false) => {
        let newPage = prescriberListPage + 1;
        if (refreshList) {
            newPage = 1;

        } else if (totalPrescriberCount <= loadedPrescriberCount) {
            return;
        }
        initConnectedPrescriberList({
            search: searchQuery,
            limit: 10,
            page: newPage,
            product: selectedProduct.map(item => item.id)
        }, (res) => {
            if (res.success) {
                console.log("api call prescriber", res)
                //setSelectedPrescriber(res.data?.items)
                setPrescriberData(res.data?.items)
            } else {
                setPrescriberData([])
            }
        }, (err) => {
            //setSelectedPrescriber([])
        })
    }

    useEffect(() => {
        if (selectedPrescriber?.length == 0 || prescriberData?.length == 0) {
            dispatch({
                type: CLEAR_PRESCRIBER_LIST
            })
            if (prescriptionModal) {
                initiatePrescriberListCall(true)
            }

        } else {
            initiatePrescriberListCall(false)
        }
    }, [])

    useEffect(() => {
        if ((searchQuery?.length == 0 && isSearchTriggered) || searchQuery?.length >= 3) {
            // Call the debounced API function with the search query
            debouncedApiCall(searchQuery);

            // Cleanup function to cancel any pending debounced calls
            return () => {
                debouncedApiCall.cancel();
            };
        }
    }, [searchQuery])

    // Debounced API call function
    const debouncedApiCall = debounce((query) => {
        // Make the API call using the search query
        // Replace this with your own API call logic
        dispatch({
            type: CLEAR_PRESCRIBER_LIST
        })
        initiatePrescriberListCall(true)
    }, 500); // Debounce delay in milliseconds (e.g., 500ms)

    //Increase Quantity
    const increaseQuantity = (index) => {
        const updatedCart = selectedProduct.map((item, i) => {
            if (i === index) {
                const updatedItem = { ...item, quantity: item.quantity + 1 };
                updatedItem.totalPrice = updatedItem.price * updatedItem.quantity;
                return updatedItem;
            }
            return item;
        });
        setSelectedProduct(updatedCart);
    }
    //Decrease Quantity
    const decreaseQuantity = (index) => {
        const updatedCart = selectedProduct.map((item, i) => {
            if (i === index && item.quantity > 1) {
                const updatedItem = { ...item, quantity: item.quantity - 1 };
                updatedItem.totalPrice = updatedItem.price * updatedItem.quantity;
                return updatedItem;
            }
            return item;
        });
        setSelectedProduct(updatedCart);
    }


    const handleCheckboxChange = (e, item) => {
        const value = e.target.value;
        const isChecked = e.target.checked;
        console.log("trackingid",item)
        if(isChecked && (item?.allow_buy_directly || item.is_retail_product)){
            productTrackingAPICall(item?.id, consentFormId)
           // setIsPrescriptionRegisterModal(false)
            const updatedCart = [...newSelectedProduct, { ...item, quantity: 1, totalPrice: item.price }]
            setNewSelectedProduct(updatedCart);
        } 
        else if (isChecked && !item.is_user_registered_with_vendor) {
            console.log("handleCheckboxChange", item);
            if(!item.pharmacy_request_sent) {
                // If the checkbox is being unchecked and the item is not registered with the vendor,
                // perform the necessary action (e.g., show modal)
                //setAddMoreDataModal(false)
                setIsPrescriptionRegisterModal(true);
             
            }        
            else {
                if(item?.registration_message){
                    setStokiestMessage(item?.registration_message)
                    setStokiestModal(true)
                    //setAddMoreDataModal(false)
                }else{
                    toast.error("Your request is under review.")
                }
    
                //toast.error(item.registration_message ? item.registration_message : "Your request is under review.")
            }
        }else if (isChecked && item.is_user_registered_with_vendor) {
            productTrackingAPICall(item?.id, consentFormId)
            const updatedCart = [...newSelectedProduct, { ...item, quantity: 1, totalPrice: item.price }]
            setNewSelectedProduct(updatedCart);
        } else {
            const updatedCart = newSelectedProduct?.filter(selectedItem => selectedItem.id !== item.id);
            setNewSelectedProduct(updatedCart);
        }
    };

    //Add More Product OnChange Event
    // const handleCheckboxChange = (e, item) => {
    //     console.log("selected product", newSelectedProduct, item)
    //     const isChecked = e.target.checked;
    //     if (isChecked) {
    //         const updatedSelected = [...newSelectedProduct, { ...item, quantity: 1, totalPrice: item.price }]
    //         setNewSelectedProduct(updatedSelected)
    //     } else {
    //         const updatedSelected = newSelectedProduct.filter(selectedItem => selectedItem.id !== item.id);
    //         //setSelectedProduct(updatedCart);
    //         setNewSelectedProduct(updatedSelected)
    //     }
    // }

    //Remove Product
    const removeFromList = (index) => {
        var newArr = [...selectedProduct];
        var itemIndex = newArr.findIndex((selectedItem) => selectedItem.id == index);
        if (itemIndex !== -1) {

            if (newArr.length === 1) {
                return toast.error("Can't remove last item. Cart must have at least one product");
            } else {
                if (!newArr[itemIndex].is_prescription_required) {
                    newArr.splice(itemIndex, 1);
                    setSelectedProduct(newArr);

                } else {
                    setSelectedPrescriber([])
                    newArr.splice(itemIndex, 1);
                    setSelectedProduct(newArr);
                }

            }
        }
        //const updatedCart = selectedProduct.filter((item, i) => i !== index);
        //let selectedProductTemp=selectedProduct.find((item)=>)
    };

    const prescriberNewData = [
        {
            name: "Dr. John Smith",
            type: "General Practitioner",
            price: "£20.50",
            checked: false

        },
        {
            name: "Dr. John Smith",
            type: "General Practitioner",
            price: "£20.50",
            checked: false
        },
        {
            name: "Dr. John Smith",
            type: "General Practitioner",
            price: "£20.50",
            checked: false
        },
        {
            name: "Dr. John Smith",
            type: "General Practitioner",
            price: "£20.50",
            checked: false
        },
        {
            name: "Dr. John Smith",
            type: "General Practitioner",
            price: "£20.50",
            checked: false
        },
        {
            name: "Dr. John Smith",
            type: "General Practitioner",
            price: "£20.50",
            checked: false
        }

    ]
    const [additionalData, setAdditionalData] = useState([]);
    const [addMoreData, setAddMoreData] = useState([])
    const [updatedProduct, setUpdatedProduct] = useState(additionalData?.filter(item => getPreselectedProducts().includes(item.id)))
    const [prescriberData, setPrescriberData] = useState([]);
    const [mode,] = useSearchParams()
    const [selectAllPharmacies, setSelectAllPharmacies] = useState(false);
    console.log("mode", mode?.get('mode'))


    // useEffect(() => {
    //     //CALL CONSENT FORM PRODUCT LIST QUERY

    //         initConsentFormProductcList({ consentFormRecordId: parseInt(consentFormId) },
    //             (res => {
    //                 console.log("product list akash",res)
    //                 //setAdditionalData(res?.items)
    //                 // setReviewModal(!res?.is_already_reviewed)
    //                 //OPEN THE REVIEW POPUP

    //                 if(res?.data?.is_already_reviewed !== null){
    //                     if (addMoreDataModal && res?.data?.is_already_reviewed) {
    //                         setReviewModal(false)
    //                     } else if (!res?.data?.is_already_reviewed) {
    //                        if(mode?.get('mode')==="in-person"){
    //                         setReviewModal(true)
    //                         setClinicName(res?.data?.clinic_name)
    //                        }else{
    //                         setReviewModal(false)
    //                        }
    //                     }
    //                    }
    //                 //    if(res?.success){
    //                 //     setAdditionalData(res?.data?.items)
    //                 //     setAddMoreData(res?.data?.items)
    //                 //     setIsPrescriber(res?.data?.is_prescriber)
    //                 //     setPrescriberDetailsData(res?.data?.prescriber_details)
    //                 //    }else{
    //                 //     setAdditionalData([])
    //                 //     setAddMoreData([])
    //                 //     setIsPrescriber(false)  
    //                 //    }

    //             }), err => {
    //                 console.log("error", err)
    //             })
    //         // // setAdditionalData(productList)

    // }, [])

    useEffect(() => {
        if ((searchProductQuery?.length == 0) || searchProductQuery?.length >= 3) {
            // Call the debounced API function with the search query
            debouncedProductApiCall(searchProductQuery);

            // Cleanup function to cancel any pending debounced calls
            return () => {
                debouncedProductApiCall.cancel();
            };
        }
    }, [searchProductQuery])

    // Debounced API call function
    const debouncedProductApiCall = debounce((query) => {
        // Make the API call using the search query
        // Replace this with your own API call logic
        dispatch({
            type: CLEAR_CONSENT_FORM_MORE_PRODUCT_LIST
        })
        // initConsentFormProductcList({ consentFormRecordId: parseInt(consentFormId), search: query, limit: -1 },
        //     (res => {
        //         setAddMoreData(res?.items)

        //     }), err => {
        //         console.log("error", err)
        //     })
        initiateMoreProductListCall(true)
    }, 500); // Debounce delay in milliseconds (e.g., 500ms)

    console.log("productList", additionalData, addMoreData)

    //ADD TO CART API
    const addToCartAPI = () => {
        const productData = selectedProduct?.map(item => ({
            id: item.id,
            quantity: item.quantity
        }));
        console.log("selectedProduct after call", selectedProduct, productData)
        initiateAddProductToCart({
            productsData: productData
        }, (res) => {
            console.log("add cart API", res)
            if (res.success) {
                setConfirmPayModal(false)
                if (res?.data?.cart_url !== null) {
                    window.location.href =
                        res?.data?.cart_url
                } else {
                    window.location.href =
                        res?.data?.prescription_request_url
                }
            }
        }, (err => {
            console.log("err add cart", err)
        }))
    }

    //CREATE PRESCRIPTION API
    const createPrescriptionAPI = () => {
        const productIds = selectedProduct?.map(item => ({ id: item.id, quantity: item.quantity }));
        initiateCreatePrescription({
            productIds: productIds,
            prescriberId: prescriberDetailsData != null ? prescriberDetailsData?.pres_id : selectedPrescriber && selectedPrescriber?.length ? selectedPrescriber[0]?.prescriber_id : 0,
            clientId: clientDetails?.clientId,
            clientName: clientDetails?.clientName,
            clientEmail: clientDetails?.clientEmail,
            consentFormId: parseInt(consentFormId),

        }, resPres => {
            console.log("create Prescription", resPres)
            if (resPres.success) {
                setConfirmPayModal(false)
                window.location.href =
                    resPres?.data?.redirection_url
                console.log("url akash", resPres)
            } else {
                toast.error(resPres.message)
            }
        }, err => {
            console.log("err", err)

        })
    }

    //CREATE PRESCRIPTION
    const handleCreatePrescription = () => {

        // if (selectedProduct?.some(product => product.allow_buy_directly)) {
        //     if (selectedProduct?.some(product => product.is_prescription_required)) {
        //         createPrescriptionAPI()
        //     } else {
        //         addToCartAPI()
        //     }
        // } else if (selectedProduct?.some(product => !product.allow_buy_directly)) {
        //     if (selectedProduct?.some(product => product.is_prescription_required)) {
        //         createPrescriptionAPI()
        //     }
        //     else {
        //         addToCartAPI()
        //     }
        // } else {
        //     addToCartAPI()
        // }
        createPrescriptionAPI()
        // if (selectedPrescriber?.length > 0 || prescriberDetailsData !== null ) {
        //     createPrescriptionAPI()
        // } else{
        //     addToCartAPI()
        // }

    }
    const handleRadioChange = (prescriberId) => {
        console.log("prescriberId", prescriberId)

        const updatedPrescriberData = prescriberData?.map((item, i) => {
            if (item?.prescriber_id === prescriberId) {
                setIsDisabled(true)
                setSelectedPrescriberId(prescriberId)
                return { ...item, checked: true };
                // Mark the clicked item as checked
            } else {
                return { ...item, checked: false };
                // Uncheck other items
            }
        });
        setPrescriberData(updatedPrescriberData);
    };
    const handleShowMore = () => {
        // Simulate fetching additional data (replace with actual fetching logic)
        // For demonstration, I'm just setting additional data after a timeout
        setTimeout(() => {
            setAdditionalDataVisible(true);
        }, 1000); // Change the timeout value as needed
    };

    //SEARCH PRESCRIBER
    const [selectedPrescriber, setSelectedPrescriber] = useState(null); // [null, setPrescriber
    const filteredPrescribers = prescriberData.filter(item =>
        item?.prescriber_name?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );

    //Get Selected Prescriber Data
    const handlePrescriptionData = () => {
        const selectedPrescriberData = prescriberList?.filter(item => item.prescriber_id === selectedPrescriberId)
        console.log("selected", selectedPrescriberData)
        setSelectedPrescriber(selectedPrescriberData)
        setPrescriptionModal(false)
        setConfirmPayModal(true)
        setSearchQuery('')
    }

    //handle get rating
    const handleRating = (rate) => {
        setRating(rate)
    }

    //Generate REview From AI
    const handleGenerateReview = () => {
        if (rating !== 0) {
            setLoaderAI(true)
            initGenerateBookingReview({
                bookingId: parseInt(consentFormId),
                rating: rating,
                type: 2
            }, (res) => {
                if (res?.success) {
                    setReview(res?.data?.reviewContent)
                    setLoaderAI(false)
                    setHideAiPrompt(true)
                } else {
                    setLoaderAI(false)
                    toast.error(res?.message)
                    setHideAiPrompt(false)
                }
                console.log("AI review", res)
            }, (err) => {
                console.log("err", err)
                setLoaderAI(false)
                setHideAiPrompt(false)
            })
        } else {
            setLoaderAI(false)
            setHideAiPrompt(false)
            toast.error("Please select rating")
        }
    }

    console.log("selected product===>", selectedProduct)
    //Handle Checkout Popup 
    const handleCheckOut = () => {
        console.log(selectedProduct, "selectedProduct akash")
        const isRegistredWithVendor = selectedProduct?.some(product => product.is_user_registered_with_vendor);
        const hasPrescriptionRequiredProduct = selectedProduct?.some(product => product.is_prescription_required);
        const isApprovedByAdmin = selectedProduct?.some(product => product.is_approved_by_admin);
        const isApprovedByPharmacy = selectedProduct?.some(product => product.is_approved_by_pharmacy);
        let showPharmacyRegistrationPopup = false, showPharmacyRegistrationStatusPopup = false;
        setIsPrescriptionRequired(hasPrescriptionRequiredProduct);
        setClientDetails({
            clientName:selectedProduct[0]?.client_name,
            clientEmail:selectedProduct[0]?.client_email,
            clientId:selectedProduct[0]?.client_id,
            clientProfile:selectedProduct[0]?.client_profile
        })

        if (selectedProduct?.some(product => product.is_prescription_required)) {
            // if (isPrescriber) {
                // if (selectedProduct?.some(product => product?.allow_buy_directly)) {
                //     setConfirmPayModal(true)
                //     setIsCartButton(true)
                // } else 
                if (selectedProduct?.some(product => product.is_prescription_required && !product.is_approved_by_admin && !product.pharmacy_request_sent)) {
                    // toast.error("Request is under review")
                    showPharmacyRegistrationPopup = true;
                    setIsPrescriptionRegisterModal(true);
                    //
                }
                else if (selectedProduct?.some(product => product.is_prescription_required && product.pharmacy_request_sent && !product.is_approved_by_pharmacy)) {
                    showPharmacyRegistrationStatusPopup = true;
                    toast.error(item.registration_message ? item.registration_message : "Your request is under review.")
                    // toast.error("Request is under review")
                } else {
                   if(isPrescriber){
                    setIsCartButton(false)
                    setPrescriptionModal(false)
                    setConfirmPayModal(true)
                   }else{
                    setPrescriptionModal(true)
                    setConfirmModal(false)
                   }
                }
            // } else {
            //     // if (selectedProduct?.every(product => product?.allow_buy_directly)) {
            //     //     setConfirmPayModal(true)
            //     //     setIsCartButton(true)
            //     // }
            //     // else 
            //     if (selectedProduct?.some(product => product.is_prescription_required && !product.is_approved_by_admin && !product.pharmacy_request_sent)) {
            //         // toast.error("Request is under review")
            //         showPharmacyRegistrationPopup = true;
            //         //
            //     }
            //     else if (selectedProduct?.some(product => product.is_prescription_required && product.pharmacy_request_sent && !product.is_approved_by_pharmacy)) {
            //         showPharmacyRegistrationStatusPopup = true;
            //         // toast.error("Request is under review")
            //     }
            //     else {
            //         setConfirmPayModal(false)
            //         setIsCartButton(false)
            //         setPrescriptionModal(true)
            //         //Call This API to get prescriber list
            //         initiatePrescriberListCall(true)
            //     }

            // }
        }
        // else if (selectedProduct?.some(product => product.is_prescription_required && !product.is_approved_by_admin)) {
        //     toast.error("Request is under review")
        //     //
        // }
        // else if (selectedProduct?.some(product => product.is_prescription_required && !product.is_approved_by_pharmacy)) {
        //     toast.error("Request is under review")
        // } 
        else {
            setConfirmPayModal(true)
            setIsCartButton(true)
        }

    }

    console.log("new product", newSelectedProduct, selectedProduct)
    //Add More Product
    const handleAddMoreProduct = () => {
        const isRegistredWithVendor = selectedProduct?.some(product => product.is_user_registered_with_vendor);
        const hasPrescriptionRequiredProduct = selectedProduct?.some(product => product.is_prescription_required);
        const isApprovedByPharmacy = selectedProduct?.some(product => product.is_approved_by_pharmacy);
        setIsPrescriptionRequired(hasPrescriptionRequiredProduct);
        console.log("handleAddMoreProduct", isRegistredWithVendor)
        if (newSelectedProduct?.some(product => product?.is_prescription_required) && selectedPrescriber?.length === 0) {
            setPrescriptionModal(true)
            setAddMoreDataModal(false)
        } else {
            setConfirmPayModal(true)
            setAddMoreDataModal(false)
        }

        setSelectedProduct(newSelectedProduct);

    }

    //Add Review For Booking
    const handleAddReview = () => {
        const data = {
            booking: parseInt(consentFormId),
            rating: rating,
            review: review,
            type: 2
        }
        initiateAddReviewBooking(data, (res) => {
            console.log("feedback", res)
            if (res.success) {
                toast.success(res?.message)
                setReviewModal(false)
            } else {
                toast.error(res?.message)
                setReviewModal(false)
            }
        }, (err) => {
            console.log("err", err)
        })
    }

    //GOTO HOME PAGE
    const navigateBackToHome = () => {
        navigate(businessDashboardRoute())
    }

    //GOTO CALENDAR PAGE
    const navigateShowCalendar = () => {
        window.location.href =
            process.env.REACT_APP_WEB_BASE_URL + "/calendar/records", { replace: true }
    }

    //Handle Confirm & Pay Modal
    const handleCloseConfirmPay = () => {
        setConfirmPayModal(false)
        setSelectedPrescriber([])
        const resetData = prescriberData.map((item, i) => {
            return { ...item, checked: false };
        })
        setPrescriberData(resetData)
        setIsDisabled(false)
        //  if(isPrescriber){
        //     initiateProductListCall(true, false)
        //  }
        //Akash
        // dispatch({
        //     type: CLEAR_CONSENT_FORM_PRODUCT_LIST

        // })
        // initiateProductListCall(true, false)
    }

    { console.log("add more data", addMoreData, additionalData) }
    const handleRedirectPreactioner = () => {
        window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/prescriber/register"
    }

    const toggleFilter = () => {
        setIsOpenFilter(!isOpenFilter); // Toggle the value of isOpenFilter
    };

    const handleConfirmDelete = () => {
        selectedProduct?.some(product => product.is_prescription_required);
        removeFromList(selectedProductId)
        setIsDeleteModal(false)
    }

    const handleConfirmModal = () => {
        setConfirmModal(false)
        // Remove the item that is being unchecked
        const updatedCart = newSelectedProduct.filter(selectedItem => selectedItem.id !== confirmItem.id);
        setNewSelectedProduct(updatedCart);
    }

    const onFilterClick = () => {

        const selectedPharmacies = pharmacyList.filter(pharmacy => pharmacy.selected);
        const selectedPharmacyIds = selectedPharmacies.map(pharmacy => pharmacy.id); // Assuming 'id' is the property containing the unique ID of each pharmacy
        setIsOpenFilter(false);
        selectedPharmacyIds.length > 0 ? setSelectAllPharmacies(false) : setSelectAllPharmacies(true)
        console.log("selected Pharmacies", selectedPharmacyIds)
        setSelectedPharmacy(selectedPharmacyIds)
        dispatch({
            type: CLEAR_CONSENT_FORM_MORE_PRODUCT_LIST
        })
        initiateMoreProductListCall(true)
        // selectedProduct?.some(product => product.vendor_is_pharmacy) ? initiateMoreProductListCall(true, true) : initiateMoreProductListCall(true, false)
    }

    //PRESCRIBER SEARCH
    const initiateProductListCall = (refreshList = false, isSelectedPhamacy = false) => {
        let newPage = productListPage + 1;
        if (refreshList) {
            newPage = 1;
            console.log("IS ALREADY REVIEWED", isReviewPopup)
            //FOR SHOW REVIEW POPUP


        } else if (totalProductCount <= loadedProductCount) {
            return;
        }

        if (isSelectedPhamacy) {
            dispatch({
                type: CLEAR_CONSENT_FORM_PRODUCT_LIST

            })
            const selectedPharmacies = pharmacyList.filter(pharmacy => pharmacy.selected);
            const selectedPharmacyIds = selectedPharmacies.map(pharmacy => pharmacy.id);
            initConsentFormProductcList({
                search: searchProductQuery,
                limit: 10,
                page: newPage,
                searchByVendor: selectedPharmacyIds,
                consentFormRecordId: parseInt(consentFormId)
            }, (res) => {
                if (res.success) {
                    const section = document.getElementById('product-list-scrollble-id');
                    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    console.log("api call product akash", res)
                    //setSelectedPrescriber(res.data?.items)
                    setIsPrescriber(res?.data?.is_prescriber)
                    setPrescriberDetailsData(res?.data?.prescriber_details)
                } else {
                    setAddMoreData([])
                    setAdditionalData([])
                }
            }, (err) => {
                //setSelectedPrescriber([])
            })
        } else {
            initConsentFormProductcList({
                search: searchProductQuery,
                limit: 9,
                page: newPage,

                consentFormRecordId: parseInt(consentFormId)
            }, (res) => {
                if (res.success) {
                    console.log("api call product ====", res)
                    //setSelectedPrescriber(res.data?.items)
                    setAddMoreData(res.data?.items)
                    //Filter data using id and concate with previous data

                    const mergedData = additionalData.concat(res.data?.items)
                    const filteredData = mergedData.filter((item, index, self) =>
                        index === self.findIndex((t) => t.id === item.id)
                    )
                    setAdditionalData(filteredData)
                    setIsPrescriber(res?.data?.is_prescriber)
                    setPrescriberDetailsData(res?.data?.prescriber_details)
                    if (res?.data?.is_already_reviewed !== null) {
                        if (addMoreDataModal && res?.data?.is_already_reviewed) {
                            setReviewModal(false)
                        } else if (!res?.data?.is_already_reviewed) {
                            if (mode?.get('mode') === "in-person" && newPage === 1 && !addMoreDataModal) {
                                setReviewModal(true)
                                setClinicName(res?.data?.clinic_name)
                            } else {
                                setReviewModal(false)
                            }
                        }
                    }

                } else {
                    setAddMoreData([])
                    setAdditionalData([])
                }
            }, (err) => {
                //setSelectedPrescriber([])
            })
        }
    }

    useEffect(() => {
        if (selectedProduct?.length == 0 || addMoreData?.length == 0 || additionalData?.length == 0 || prescriberDetailsData == null) {
            dispatch({
                type: CLEAR_CONSENT_FORM_PRODUCT_LIST
            })
            initiateProductListCall(true, false)

        } else {
            initiateProductListCall(false, false)

        }
    }, [])

    useEffect(() => {
        if (addMoreDataModal === true) {
            dispatch({
                type: CLEAR_CONSENT_FORM_MORE_PRODUCT_LIST
            })
            initiateMoreProductListCall(true)
        }

        if (prescriptionModal === true) {
            dispatch({
                type: CLEAR_PRESCRIBER_LIST
            })
            setSelectedPrescriber([])
            setSelectedPrescriberId(null)
            initiatePrescriberListCall(true)
        }
    }, [addMoreDataModal, prescriptionModal])

    useEffect(() => {
        if ((searchProductQuery?.length == 0 && isSearchTriggered) || searchProductQuery?.length >= 3) {
            // Call the debounced API function with the search query
            debouncedProductApiCall(searchProductQuery);

            // Cleanup function to cancel any pending debounced calls
            return () => {
                debouncedProductApiCall.cancel();
            };
        }
    }, [searchProductQuery])

    const handleOpenPrescriberModal = () => {
        if (isPrescriber) {
            setPrescriptionModal(false);
            setConfirmPayModal(false)
            setAddMoreDataModal(false)
        } else {
            setPrescriptionModal(true);
            setConfirmPayModal(false)
            setAddMoreDataModal(false)
        }

    }



    // // Debounced API call function
    // const debouncedProductApiCall = debounce((query) => {
    //     // Make the API call using the search query
    //     // Replace this with your own API call logic
    //     dispatch({
    //         type: CLEAR_PRESCRIBER_LIST
    //     })
    //     initiateProductListCall(true)
    // }, 500); // Debounce delay in milliseconds (e.g., 500ms)
    const pointList = [
        {
            title: "Consent Form Sent:",
            description: "Your client has received the digital consent form."
        },
        {
            title: "Automatic Updates:",
            description: "Post-treatment, we’ll update the consent form and initiate aftercare and follow-up rebooking."
        },
        {
            title: "Payment Notifications:",
            description: "You’ll be alerted when payments or deposits are made."
        },
        // {
        //     title: "Special Offers on Products:",
        //     description: "Check out discounted products tailored for your treatment."
        // }
    ]

    const handleAddMoreProductModal = () => {
        setAddMoreDataModal(false)
        setConfirmPayModal(true)
        setSearchProductQuery('')
        setAddMoreData([])
        setAdditionalData([])
        setSelectedPharmacy([])
        //initiateProductListCall(true, false)
    }

    console.log("prescriberDetails", prescriberDetailsData)
    //String Truncate
    function truncate(str, n) {
        return (str?.length > n) ? str?.slice(0, n - 1) + '...' : str;
    };

    //MORE PRODUCT SEARCH
    const initiateMoreProductListCall = (refreshList = false) => {
        let newPage = moreProductListPage + 1;
        if (refreshList) {
            newPage = 1;
            console.log("IS ALREADY REVIEWED", isReviewPopup)
            //FOR SHOW REVIEW POPUP


        } else if (totalMoreProductCount <= loadedMoreProductCount) {
            return;
        }


        const selectedPharmacies = pharmacyList.filter(pharmacy => pharmacy.selected);
        const selectedPharmacyIds = selectedPharmacies.map(pharmacy => pharmacy.id);
        if (addMoreDataModal === true) {
            initConsentFormMoreProductcList({
                search: searchProductQuery,
                limit: 10,
                page: newPage,
                vendorId: selectedPharmacyIds,
                displayOnlyPharmacyProducts: false
            }, (res) => {
                if (res.success) {
                    console.log("api call product ====", res)
                    //setSelectedPrescriber(res.data?.items)
                    setAddMoreData(res.data?.items)
                    //Filter data using id and concate with previous data

                    const mergedData = additionalData.concat(res.data?.items)
                    const filteredData = mergedData.filter((item, index, self) =>
                        index === self.findIndex((t) => t.id === item.id)
                    )
                    setAdditionalData(filteredData)
                    setIsPrescriber(res?.data?.is_prescriber)
                    setPrescriberDetailsData(res?.data?.prescriber_details)
                } else {
                    setAddMoreData([])
                    setAdditionalData([])
                }
            }, (err) => {
                //setSelectedPrescriber([])
            })
        }
    }

    useEffect(() => {
        if (prescriptionModal == false) {
            setSearchQuery('')

        }
        if (addMoreDataModal == false) {
            setSearchProductQuery('')
            setSelectedPharmacy([])
            setPharmacyList([])
        }
    }, [prescriptionModal, addMoreDataModal])

    const handleOpenAddMoreProductModal = () => {
        setAddMoreDataModal(true)
        setConfirmPayModal(false)
        const preselectedProducts = [...selectedProduct]; // Set this to your desired preselected products
        setNewSelectedProduct(preselectedProducts);
    }

    const toggleCartButtonText = () => {
        let label = ""
        if (selectedProduct?.some(product => product.is_prescription_required) 
            // && selectedProduct?.some(product => !product.allow_buy_directly)
        ) {
            return label = "Create Prescription"
        } else {
            return label = "Add to cart"
        }
    }

    const registerModalData = [
        {
            icon: <ClinicIcon />,
            heading: "Partner with over 50k clinics",
            description: "Network with over 100k professionals and over 50k clinics"

        },
        {
            icon: <PharmcyIcon />,
            heading: "17 Different Pharmacies",
            description: "Guarantee, Best Price and Instock"

        },
        {
            icon: <MedicalIcon />,
            heading: "Integrated Consent & Medical",
            description: "Making prescribing easy and convenient with intergrated consent and medical forms "

        },
        {
            icon: <PaylaterIcon />,
            heading: "0% interest on Buy now payl8r ",
            description: "Buy now pay later. Buying products with our 14 day 0% free payment options has never been easier "

        }
    ]

    //Truncate PROFESSION
    const truncateProfession = (profession) => {
      if (Array.isArray(profession)) {
        if (profession?.length > 2) {
            const truncated = profession?.slice(0, 2).join(', ');
            return `${truncated} & ${profession?.length - 2} More`;
        }
        return profession?.join(',');
    } else if (typeof profession == 'string') {
        return profession;
    } else {
        return ''; // or whatever default value you want
    }
    };

    return (
        <div className='thankyou-page-container'>
            <div className='thankyou-page-content-container'>
                <h1>Appointment Added Successfully!</h1>
                <ThankyouImage />
                <div className='point-list-container'>
                    {
                        pointList.map((item, index) => (
                            <div className='row mt-4 point-list-content' key={index}>
                                <div className='col-1'>
                                    <img src={ListImg} alt="icon" />
                                </div>
                                <div className='col-11'>
                                    <span>{item?.title} </span><b>{item?.description}</b>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='action-container'>
                    <div className='btn-white'>
                        <button style={{ width: "100%" }} onClick={navigateBackToHome}>Dashboard</button>
                    </div>
                    <div className='btn-blue'>
                        <button style={{ width: "100%" }} onClick={navigateShowCalendar}>Calendar</button>
                    </div>
                </div>
                {
                    productList?.length > 0 &&
                    <div id="scrollable-product-div" style={{ height: "70vh", overflowY: 'scroll' }}>
                        <InfiniteScroll style={{ overflowX: "hidden" }}
                            scrollableTarget="scrollable-product-div"
                            dataLength={loadedProductCount}
                            next={() => initiateProductListCall()}
                            hasMore={totalProductCount > loadedProductCount}
                        >
                            {
                                <ProductCard data={productList} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} setIsPrescriptionRegisterModal={setIsPrescriptionRegisterModal} setOpenStokiesModal={setOpenStokiesModal} consentFormId={consentFormId}/>

                            }
                            {
                                consentFormProductListQueryData.loading ?
                                    <ThankyouPageProductSkeleton type="thankyou-product" /> :
                                    null
                            }

                        </InfiniteScroll>
                        {/* {(!additionalDataVisible && productList?.length > 12) && (
                            <h2 onClick={handleShowMore} style={{ cursor: 'pointer' }}>Show more</h2>
                        )} */}

                    </div>}
                {productList?.length !== 0 && <div className='thankyou-page-button-style'> <StepButton blue={true} label={"Checkout"} onClick={handleCheckOut} disabled={selectedProduct?.length === 0} /></div>}
            </div>
            {/* CREATE PRESCRIPTION MODAL */}
            <CustomModal
                modalOpen={prescriptionModal}
                setModalOpen={setPrescriptionModal}
                modaltitle={"Create Prescription"}
                className={'CreatePrescriptionDesign'}
                modalBody={
                    <div className='prescription-modal-body'>
                        {console.log("selected product", conntectedPrescriberListQueryData?.data?.items, conntectedPrescriberListQueryData?.loading, totalPrescriberCount, loadedPrescriberCount)}
                        <p>prescription items must be prescribed, please select a prescriber to approve products for John</p>
                        <div className='search-prescription-container'>
                            <p>Select Prescriber</p>
                            <div className='SearchInputWrapper'>
                                <input type="text" placeholder='Search Prescriber' value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); setIsSearchTriggered(true) }} />
                                <SearchIcon />
                            </div>
                        </div>
                        <div className='prescription-list-scrollble' id="scrollable-div">
                            {
                                <InfiniteScroll
                                    scrollableTarget="scrollable-div"
                                    dataLength={loadedPrescriberCount}
                                    next={() => initiatePrescriberListCall()}
                                    hasMore={totalPrescriberCount > loadedPrescriberCount}
                                >
                                    {
                                        prescriberList?.length != 0 && !(emptyPrescriberList) ?

                                            prescriberList?.map((item, index) => (
                                                <label className={selectedPrescriberId === item?.prescriber_id ? 'prescription-list-container selectedList' : 'prescription-list-container'} style={selectedPrescriberId === item?.prescriber_id ? { border: "1px solid #175FFA", boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.12)" } : {}} key={index}>
                                                    {console.log("prescriber data", prescriberData, selectedPrescriberId)}
                                                    <div className='left-list-content'>
                                                        <Avatar size={48} maxInitials={2} fgColor='#000' round color='#eeeff3' src={item?.prescriber_profile} name={item?.prescriber_name} />
                                                        <div className='content-section'>
                                                            <h4>{item?.prescriber_name}</h4>
                                                            <p>{truncateProfession(item?.prescriber_profession)}</p>
                                                        </div>
                                                    </div>
                                                    <div className='right-list-content'>
                                                        <div className='content-section'>
                                                               <input type={"radio"} checked={selectedPrescriberId === item?.prescriber_id} onChange={() => { handleRadioChange(item?.prescriber_id) }} />
                                                            <p>{item?.pres_currency}{item?.prescriber_fee}</p>
                                                        </div>
                                                    </div>
                                                </label>
                                            ))
                                            :
                                            conntectedPrescriberListQueryData?.loading ? null :
                                                <div className='no-prescriber-found'>
                                                    <p>No prescriber found</p>
                                                </div>

                                    }
                                    {
                                        conntectedPrescriberListQueryData?.loading ?
                                            <SkeletonLoader type={"prescriber-list"} />
                                            :
                                            null
                                    }
                                </InfiniteScroll>
                            }
                        </div>
                        <div className='stickyFooter'>
                            <StepButton blue={true} label={"Continue"} disabled={selectedPrescriberId == null || emptyPrescriberList} onClick={() => handlePrescriptionData()} />
                        </div>

                    </div>
                }
            />

            {/* CONFIRM & PAY MODAL */}

            <CustomModal
                modalOpen={confirmPayModal}
                setModalOpen={handleCloseConfirmPay}
                modaltitle={"Confirm & Pay"}
                className={'ConfirmPayProductModel'}
                modalBody={
                    <>
                    <div className='confirm-pay-modal-body'>
                        {console.log("selected pre", selectedPrescriber, consentFormProductListQueryData?.data?.getConsentFormProducts?.data?.prescriber_details
                        )}
                        {/* TOP SECTION */}

                        <>
                            {/* <div className='client-card-section'>
                                <PlaceHolder />
                                <div className='client-content-section'>
                                    <h1>Client Name</h1>
                                    <p>Client73@gmail.com</p>
                                </div>
                            </div> */}
                          
                            {
                               
                                clientDetails  ?
                                    <div className='client-card-section'>
                                        <Avatar size={48} maxInitials={2} fgColor='#000' round color='#eeeff3' src={clientDetails?.clientProfile} name={clientDetails?.clientName} />
                                        <div className='client-content-section'>
                                            <h1>{clientDetails?.clientName}</h1>
                                            <p>{clientDetails?.clientEmail}</p>
                                        </div>
                                    </div> :
                                    null
                            }
                            <div className='prescriber-card-section' >
                                {
                                    selectedPrescriber?.length > 0 ?
                                        <div className='card-content-section cursor-pointer' onClick={handleOpenPrescriberModal}>
                                            <div className='left-content-section'>
                                                <Avatar size={48} maxInitials={2} fgColor='#000' round color='#eeeff3' src={selectedPrescriber[0]?.prescriber_profile} name={selectedPrescriber[0]?.prescriber_name} />
                                                <div className={selectedPrescriber[0]?.prescriber_profession == null ? `content-section justify-content-center` : `content-section`} >
                                                    <h1>{selectedPrescriber[0]?.prescriber_name}</h1>
                                                    <p>{truncateProfession(selectedPrescriber[0]?.prescriber_profession)}</p>
                                                </div>
                                            </div>
                                            <div className='right-content-section' style={selectedPrescriber[0]?.prescriber_profession == null ? { marginTop: 0 } : {}}>
                                                <p>{selectedPrescriber[0]?.pres_currency}{selectedPrescriber[0]?.prescriber_fee}</p>
                                            </div>
                                        </div>
                                        :
                                        prescriberDetailsData != null ?
                                            <div className='card-content-section cursor-pointer'>
                                                <div className='left-content-section'>
                                                    <Avatar size={48} maxInitials={2} fgColor='#000' round color='#eeeff3' src={prescriberDetailsData?.pres_profile} name={prescriberDetailsData?.pres_name} />
                                                    <div className={prescriberDetailsData?.pres_profession == "" ? `content-section justify-content-center` : `content-section`} >
                                                        <h1>{prescriberDetailsData?.pres_name}</h1>
                                                        <p>{truncateProfession(prescriberDetailsData?.pres_profession)}</p>
                                                    </div>
                                                </div>
                                                <div className='right-content-section' style={prescriberDetailsData?.pres_profession == "" ? { marginTop: 0 } : {}}>
                                                    <p>{prescriberDetailsData?.pres_currency}{prescriberDetailsData?.pres_fee}</p>
                                                </div>
                                            </div>
                                            :
                                            null

                                }
                            </div>
                        </>
                        {/* SELECTED PRODUCTS SECTION */}
                        <div className='product-selection-section'>
                            <h1>Selected Products</h1>
                            <div className='product-cart-container'>
                                {
                                    selectedProduct?.map((item, index) => (
                                        <div className='product-card'>
                                            <div className='left-card-section'>
                                                <div className='product-image'>
                                                    <img src={item?.image_url == null ? productImg : item?.image_url} alt='product' />
                                                </div>
                                                <div className='product-content-section'>
                                                    <div className='firstRow'>
                                                      <h1>{truncate(item?.product_name, 40)}</h1>
                                                      <DeleteIcon onClick={() => { setIsDeleteModal(true); setSelectedProductId(item?.id) }} />
                                                    </div>
                                                    <div className='secondRow'>
                                                      <p>{item?.currency}{item?.totalPrice}</p>
                                                      <div className='product-counter-section'>
                                                        <RemoveIcon onClick={() => decreaseQuantity(index)} />
                                                        <p>{item?.quantity}</p>
                                                        <AddIcon onClick={() => { increaseQuantity(index); setCount(count + 1) }} />
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='right-card-section'>
                                                <div className='product-content-section'>
                                                  
                                                    
                                                </div>
                                            </div> */}
                                        </div>
                                    ))
                                }
                                <div className='add-more-product'>
                                    <p onClick={() => handleOpenAddMoreProductModal()}>Add more products</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='stickyFooter'>
                      {console.log("selected prescriber akash", selectedPrescriber?.length, prescriberDetailsData, selectedPrescriber)}
                      <StepButton blue={true} label={toggleCartButtonText()} isLoading={addProductToCartMutationData?.loading || createPrescriptionMutationData?.loading} onClick={handleCreatePrescription} />
                    </div>
                    </>
                }
            />

            {/* ADD MORE PRODUCTS MODAL */}
            <CustomModal
                modalOpen={addMoreDataModal}
                setModalOpen={handleAddMoreProductModal}
                modaltitle={"Add More Products"}
                className={"CustomeAddMoreProductsModel"}
                modalBody={
                    <>
                        <div className='add-more-product-container'>
                            <div className='search-product-container'>
                                <div className='input-text-container'>
                                    <div className='inputGrid'>
                                        <div className='inputGridItem'>
                                            <input type="text" placeholder='Search Product' value={searchProductQuery} onChange={(e) => setSearchProductQuery(e.target.value)} />
                                            <SearchIcon />
                                        </div>
                                        <div className='inputGridItem'>
                                            <img src={FilterIcon} alt='filter' onClick={toggleFilter} />
                                            {
                                                isOpenFilter ?
                                                    <OutsideClickHandler onOutsideClick={() => toggleFilter()} >
                                                        <PharmacyList setIsOpenFilter={setIsOpenFilter} consentFormId={consentFormId} isOpenFilter={isOpenFilter} setSelectedPharmacy={setSelectedPharmacy} setSelectAllPharmacies={setSelectAllPharmacies} selectAllPharmacies={selectAllPharmacies} selectedPharmacy={selectedPharmacy} onFilterClick={onFilterClick} setPharmacyList={setPharmacyList} pharmacyList={pharmacyList} />
                                                    </OutsideClickHandler>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='product-list-scrollble' ref={productRef} id="product-list-scrollble-id" style={{ overflowY: 'scroll', height: "60vh" }}>
                                <InfiniteScroll
                                    scrollableTarget="product-list-scrollble-id"
                                    dataLength={loadedMoreProductCount}
                                    next={() => initiateMoreProductListCall()}
                                    hasMore={totalMoreProductCount > loadedMoreProductCount}

                                >
                                    {
                                        moreProductList?.length != 0 ?
                                            moreProductList?.map((item, index) => (
                                                <label className='product-list-container' style={{ border: newSelectedProduct.some(selectedItem => selectedItem.id === item.id) ? '2px solid #1654F3' : '1px solid #DDD' }} key={index}>
                                                    <div className='left-list-content'>
                                                        <input type="checkbox"
                                                            className='d-none'
                                                            value={item?.product_name}
                                                            checked={newSelectedProduct.some(selectedItem => selectedItem.id === item.id)}
                                                            onChange={(e) => handleCheckboxChange(e, item)}
                                                        />
                                                        <div className='img-placeholder' >
                                                            <img src={item?.image_url !== null ? item?.image_url : productImg} alt='product' />
                                                        </div>
                                                        <div className='content-section'>
                                                            <h4>{truncate(item?.product_name, 20)}</h4>
                                                            <p>{item?.vendor_name}</p>
                                                        </div>
                                                    </div>
                                                    <div className='right-list-content'>
                                                        <div className='right-content-section'>
                                                            <p>{item?.currency}{item?.price}</p>
                                                        </div>
                                                    </div>
                                                </label>
                                            ))
                                            :
                                            consentFormMoreProductListQueryData?.loading ? null :
                                                <div className='no-prescriber-found'>
                                                    <p>No product found</p>
                                                </div>
                                    }

                                    {
                                        consentFormMoreProductListQueryData?.loading ?
                                            <SkeletonLoader type={"add-more-product"} />
                                            :
                                            null

                                    }
                                </InfiniteScroll>
                            </div>

                        </div>
                        <div className='stickyFooter'>
                            <StepButton blue={true} label={"ADD PRODUCT"} disabled={newSelectedProduct?.length === 0} onClick={handleAddMoreProduct} />
                        </div>
                    </>
                }
            />

            {/* REVIEW MODAL */}
            <CustomModal
                modalOpen={reviewModal}
                setModalOpen={setReviewModal}
                modaltitle={"Leave Review"}
                className={"thankyou-page-rating-modal"}
                modalBody={
                    <div className='review-modal-container'>
                        <div className='header-section'>
                            {/*<h1></h1>*/}
                            <h1>Select star rating for '{clinicName}'</h1>
                        </div>
                        <div className='rating-section'>
                            <Rating initialValue={rating} onClick={handleRating} />
                        </div>
                        <div className='review-section'>
                            <h1>Leave the practitioner a little note to help them with their business</h1>

                            <div className='prompt-review-container'>
                                <textarea placeholder='Type your feedback'
                                    value={review}
                                    onChange={(e) => setReview(e.target.value)}
                                />
                                {
                                    !hideAiPrompt &&
                                    <button onClick={handleGenerateReview}>{loaderAI ? <i className="fas fa-spinner fa-pulse"></i> : "Prompt Review"}</button>
                                }
                            </div>
                            <p>
                                This review prompt is provided solely as a guideline based on the information given. It is intended to assist in framing your experience and is not definitive or exhaustive. Please ensure your review accurately reflects your personal experience and sentiments.
                            </p>
                        </div>
                        <div className='review-modal-footer'>
                            <div className='btn-blue'>
                                <StepButton disabled={rating === 0 || review?.length === 0} blue={true} label={"Submit"} isLoading={addReviewBookingMutationData.loading} onClick={() => { handleAddReview() }} />
                            </div>

                        </div>
                        <div className='message-footer'>
                            <p>Your review will be posted to the Faces Consent booking platform</p>
                        </div>
                    </div>
                }
            />

            {/* PRESCRIPTION MODAL */}
            <CustomModal
                modalOpen={isPrescriptionRegisterModal}
                setModalOpen={setIsPrescriptionRegisterModal}
                modaltitle={""}
                modalBody={
                    <div className='new-prescription-container'>
                        <h1>Pharmacy Registration</h1>
                        <p>Some of the products you’ve selected are’nt from the pharmacies you are registered with. Please click below to register.</p>
                        <div className='registration-content-div'>

                            {
                                registerModalData.map((item, index) => (
                                    <div className='icon-content-container' key={index}>
                                            {item?.icon}
                                        <div className='content-register'>
                                            <h1>{item?.heading}</h1>
                                            <p>{item?.description}</p>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                        <StepButton blue={true} label={"Register"} onClick={() => {
                            handleRedirectPreactioner()
                        }} />
                        <small onClick={()=>setIsPrescriptionRegisterModal(false)}>Maybe Later</small>
                    </div>

                }
            />
            

            <CustomModal
                modalOpen={isDeleteModal}
                setModalOpen={setIsDeleteModal}
                className={"w-45"}
                modalBody={
                    <div className='product-delete-container'>
                        <h1>Are you sure you want to remove this product?</h1>
                        <div className='delete-button-product-container'>
                            <StepButton red={true} label={"Delete"} onClick={() => { handleConfirmDelete() }} />
                            <StepButton gray={true} onClick={() => {
                                setIsDeleteModal(false); setSelectedProductId(null)
                            }} label={"Cancel"} />
                        </div>
                    </div>
                }
            />

            <CustomModal
                modalOpen={confirmModal}
                setModalOpen={setConfirmModal}
                className={"w-45"}
                modalBody={
                    <div className='product-delete-container'>
                        <h1>For selected product, you need to create prescription request and all the previous selected products will be removed. Are you sure want to continue?</h1>
                        <div className='delete-button-product-container'>
                            <StepButton red={true} label={"Yes"} onClick={() => { handleConfirmModal() }} />
                            <StepButton gray={true} onClick={() => {
                                setConfirmModal(false)
                            }} label={"No"} />
                        </div>
                    </div>
                }
            />

            <StokiestModal isOpen={stokiestModal} setIsOpen={setStokiestModal} message={stokiestMessage}/>
        </div>

    )
}

export default ThankyouPage