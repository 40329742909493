import { GET_TEAM_MEMBER } from "../../constants/team-member-list-const/TeamMemberListConst"


const initialState={
  teamMemberList:[]
}

export default function teamMemberListReducer(state = initialState, action) {

  switch (action.type) {

    case GET_TEAM_MEMBER:
      return {
        ...state,
        teamMemberList: action.payload,
  };

    default:
      return state;
  }



}