import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";

const GET_INSURANCE_PROVIDER_LIST_QUERY = gql`
query GetInsuranceOptions {
    getInsuranceOptions {
      success
      message
      data {
        options
      }
    }
  }
`;

export const GetInsuranceProviderList = () => {
  const dispatch = useDispatch();
  const [getInsuranceOptions, { loading, error, data }] = useLazyQuery(GET_INSURANCE_PROVIDER_LIST_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: (response) => {
      // Handle the completed response here if necessary
    },
  });

  const initGetInsuranceProviderList = () => {
    getInsuranceOptions();
  };

  return { loading, error, data, initGetInsuranceProviderList };
};
