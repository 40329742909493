import Skeleton from "react-loading-skeleton";
import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "./skeleton-loader.scss";
import { useSelector } from "react-redux";
import backArrow from "../../../images/icons/BackArrow.svg"

import { useLocation, useNavigate } from "react-router";
import { businessDashboardRoutePattern, getAppointmentListRoutePattern } from "../../../Routes";
import { useDispatch } from "react-redux";
import { clearAppointmentStatus } from "../../redux/actions";
import useWindowDimension from "../../../utils/useWindowDimensions";

const SkeletonLoader = ({ type, showCircle, isGetStarted = false, isClient = false, isNewAppointment = false }) => {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
	const { paymentSummary } = useSelector((state) => state?.forwardConsentFormOverviewReducer);
	const dimensions = useWindowDimension();

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const ListSkeleton = () => {

		return (
			<>
				{
					[...Array(8)]?.map((item, index) => {
						return (
							<div
								className={`d-flex align-items-center justify-content-between ${isClient ? "w-100" : ""} ${isClient ? '' : 'mt-4'} ${isMobile ? "mobile-skeleton" : isNewAppointment ? "modal-skeleton" : "desktop-skeleton"
									}`}
							>
								<div className="d-flex gap-10 align-items-center">
									<div
										className={`d-flex align-items-center justify-content-center ${showCircle ? "me-3" : ""
											}`}
									>
										{showCircle && <Skeleton circle width={60} height={60} />}
									</div>
									<div className={"client_details d-block mb-0 p-1"}>
										<Skeleton width={130} height={20} count={1} />
										<Skeleton width={150} height={20} count={1} />
									</div>
								</div>
								<div className="d-flex align-items-center">
									{isGetStarted ? (
										<Skeleton width={50} height={50} />
									) : (
										<Skeleton width={20} />
									)}
								</div>
							</div>
						)
					})
				}

			</>
		);
	};

	const ListTreatmentSkeleton = () => {
		return (
			[...Array(8)]?.map((item, index) => {
				return <div className="d-flex align-items-center justify-content-between mt-4">
					<div className="d-flex gap-10 align-items-center">
						<div className={"client_details d-block mb-0 p-1"}>
							<Skeleton width={100} height={10} count={1} />
							<Skeleton width={100} height={10} count={1} />
							<Skeleton width={170} height={10} count={1} />
							<Skeleton width={50} height={10} count={1} />

						</div>

					</div>
					<div className="d-flex align-items-center">
						{isGetStarted ? (
							<Skeleton width={50} height={50} />
						) : (
							<Skeleton width={20} />
						)}
					</div>
				</div>
			})
		);
	};

	const CalendarSkeleton = () => {
		// return (
		//   <>
		//       {/* <div className="d-flex align-items-center mb-4 justify-content-between ">
		//       <Skeleton width={20} height={10} />
		//       <Skeleton width={200} height={50} />
		//       <Skeleton width={20} height={10} />
		//       </div> */}
		//       <div className="calendar-skeleton">
		//       {[...Array(7)].map((_, index) => (
		//       <div key={index} className="skeleton-day mb-2">
		//       <Skeleton circle width={40} height={10} />
		//     </div>
		//       ))}
		//     </div>

		//      <div className="calendar-skeleton">
		//     {[...Array(7 * 6)].map((_, index) => (
		//     <div key={index} className="skeleton-day">
		//       <Skeleton circle width={40} height={50} />
		//     </div>
		//   ))}

		// </div>
		//   </>

		// );
		return (
			<div className='calendar-skeleton-wrapper mt-4'>
				<div className='custom-calendar-skeleton'>
					<div className='d-flex flex-wrap justify-content-center'>
						{[...Array(30)].map((tempItem, tempIndex) => {
							return (
								<div className={"date-items"}>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		)
	};

	const TabTreatmentSkeleton = () => {
		return (
			<div className="d-flex align-items-center justify-content-between mt-4">
				<div className="d-flex gap-10 align-items-center">
					<div className={"client_details d-block mb-0 p-1"}>
						<Skeleton width={100} height={30} count={1} style={{ borderRadius: '100px' }} />
					</div>
				</div>
			</div>
		);
	};

	const TimeSkeleton = () => {
		return (
			<div className="d-flex align-items-center justify-content-between mt-4">

				<Skeleton width={100} height={30} count={1} />
				<Skeleton width={20} height={20} count={1} style={{ borderRadius: '100px' }} />

			</div>
		);
	};
	const BusinessStepSkeleton = () => {
		return (
			<div className={"edit-business-content mt-3"}>
				<div className="d-flex align-items-center">
					<div className="me-3">
						<Skeleton circle={true} width={50} height={50} />
					</div>
					<div className="mt-2">
						<Skeleton count={1} width={100} />
					</div>
				</div>
				<div className="d-flex justify-content-between">
					<div className="pe-4 edit-dashboard-status">
						<Skeleton count={1} width={50} />
					</div>
					<div className="d-flex align-items-center mt-2">
						<Skeleton width={10} />
					</div>
				</div>
			</div>
		);
	};

	const AppointmentListSkeleton = () => {
		console.log('appointmentlistskeleton');
		return (
			<div className={`${isClient ? 'w-100 offset-lg-0' : 'w-100 offset-lg-4'} col-lg-4 m-auto`}>
				<div className={`appointment-skeleton d-flex flex-column ${isClient ? 'justify-content-start' : 'justify-content-center'}`}>
					{
						isClient ?
							<div className="d-flex flex-column w-100 p-0 text-left">
								<Skeleton width={100} />
								<Skeleton count={2} width={250} />
								<Skeleton width={100} />
								<Skeleton width={50} height={10} count={1} className="menuIconSkeleton" />
							</div>
							:
							<>
								<Skeleton width={150} />
								<Skeleton count={1} width={250} />
								<Skeleton width={100} />
							</>
					}
				</div>
			</div>
		)
	}

	const PhotoCoverSkeleton = () => {
		return (
			<div className="container">
				<div className="form-group multi-preview">
					<div className="row">
						<div className="col-md-12">
							<Skeleton height={250} style={{ borderRadius: "20px" }} />
						</div>
					</div>
				</div>
			</div>
		);
	};

	const PhotoOtherSkeleton = () => {
		return (
			<div className="container">
				<div className="form-group multi-preview">
					<div className="row">
						<div className="col-md-4 mt-3">
							<Skeleton
								width={160}
								height={150}
								style={{ borderRadius: "20px" }}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const AppointmentHeaderSkeleton = () => {
		const navigate = useNavigate();
		const dispatch = useDispatch();
		const location = useLocation().state
		return (
			<>
				<div className="container-fluid appointment-header-skeleton d-flex flex-column align-items-center">
					<div className='close-back container-fluid d-flex justify-content-between'>
						<img src={backArrow} style={{ cursor: 'pointer' }} alt="" onClick={() => { }} />
						<button
							onClick={() => {
								dispatch(clearAppointmentStatus()); navigate(location === "list" ? getAppointmentListRoutePattern : businessDashboardRoutePattern)
							}}>
							Edit
						</button>
					</div>
					<div className="appointDetailsWrapper">
						<Skeleton circle width={80} height={80} />
						<Skeleton width={150} />
						<Skeleton count={2} width={280} />
						<Skeleton width={100} />
					</div>
				</div>
				<div className="d-flex flex-column container" style={{ maxWidth: "540px" }}>
					<div className="appointment-main-content">
						<div className="d-flex my-4">
							<Skeleton width={80} height={29} className="me-2" />
							<Skeleton width={80} height={29} className="me-2" />
							<Skeleton width={80} height={29} className="me-2" />
						</div>
						<Skeleton width={80} height={29} className="me-2" />
						<hr />
						<div className='general-details d-flex justify-content-between pt-2 mb-5'>
							<div className='d-flex flex-column justify-content-center '>
								<Skeleton width={230} className="mb-2" />
								<Skeleton width={230} />
							</div>
							<div className='user-image-container d-flex'>
								<Skeleton circle width={80} height={80} />
							</div>
						</div>
						<Skeleton width={147} height={22} className="mb-2" />
						<hr />
						<div className="d-flex justify-content-between mb-4 ">
							<Skeleton width={150} height={22} />
							<Skeleton width={150} height={22} />
						</div>
						<div className="d-flex justify-content-between mb-4 ">
							<Skeleton width={150} height={22} />
							<Skeleton width={150} height={22} />
						</div>
						<div className="d-flex justify-content-between mb-4 ">
							<Skeleton width={150} height={22} />
							<Skeleton width={150} height={22} />
						</div>
						<div className="d-flex justify-content-between mb-4 ">
							<Skeleton width={150} height={22} />
							<Skeleton width={150} height={22} />
						</div>
					</div>
				</div>

			</>

		);
	};

	const TreatmentDetailsSkeleton = () => {

		return (
			<>
				<div className="d-flex flex-column">
					<Skeleton width={"100%"} height={200} />
					<div className="mt-3">
						<Skeleton width={"100%"} height={20} count={1} />
						<Skeleton width={300} height={20} count={1} />
					</div>
					<div className="mt-3">
						<Skeleton width={200} height={10} count={1} />
					</div>
					<div className="mt-3">
						<Skeleton style={{ textAlign: "justify" }} width={"100%"} height={10} count={6} />
					</div>
				</div>
			</>
		);
	};

	const TeamMemberSkeleton = () => {
		return (
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex gap-3">
					<Skeleton width={60} circle height={60} />
					<div className="d-flex flex-column align-items-start justify-content-center">
						<Skeleton width={150} />
						<Skeleton width={100} />
					</div>

				</div>
				<Skeleton width={20} circle height={20} />
			</div>
		);
	};

	const TeamMemberSkeletonWeb = () => {
		return (
			<div className="d-flex justify-content-between align-items-center MobileSkelTon">
				<div className="d-flex gap-3">

					<Skeleton className='profile' width={90} circle height={90} />

					<div className="d-flex flex-column align-items-start mt-2">
						<div>
							<Skeleton width={70} height={15} />
						</div>
						<Skeleton className="desc" width={367} height={12} />

						<div className="d-flex gap-2 justify-content-center mt-2">
							<Skeleton width={120} height={15} />
							<Skeleton width={120} height={15} />
						</div>
						<Skeleton width={450} height={2} />
					</div>

				</div>
			</div>
		);
	};
	const ClientProfileSkeleton = () => {
		return <div className="client-main-details-container customeDetailsWrappper">
			<div className='client-profile-close-back container-fluid d-flex justify-content-between'>
				<Skeleton width={40} height={40} circle />
				<Skeleton width={77} height={48} className="borderRadiusBtn" />
			</div>
			<div className="client-name-section">
				<div className="left-name-section">
					<Skeleton width={150} />
					<Skeleton width={150} />
				</div>
				<div className="right-name-section">
					<div className="avatar-icon">
						<Skeleton width={50} height={50} circle={true} />
					</div>
				</div>
			</div>
			<div className="client-content-section">
				<div className="label-header">
					<Skeleton width={50} />
					<Skeleton width={"100%"} height={3} />
					<div className="text-content">
						<Skeleton width={100} />
						<Skeleton width={50} />
					</div>
					<div className="text-content">
						<Skeleton width={100} />
						<Skeleton width={50} />
					</div>
					<div className="text-content">
						<Skeleton width={100} />
						<Skeleton width={50} />
					</div>
					<div className="text-content">
						<Skeleton width={100} />
						<Skeleton width={50} />
					</div>
				</div>
				{/* about section */}
				<div className="label-header">
					<Skeleton width={100} />
					<Skeleton width={"100%"} height={3} />
					<div className="text-content-about">
						<Skeleton width={100} />
						<Skeleton width={50} />
					</div>
					<div className="text-content-about">
						<Skeleton width={100} />
						<Skeleton width={50} />
					</div>
				</div>
				{/* contact section */}
				<div className="label-header">
					<Skeleton width={100} />
					<Skeleton width={"100%"} height={3} />
					<div className="text-content-about">
						<Skeleton width={100} />
						<Skeleton width={50} />
					</div>
					<div className="text-content-about">
						<Skeleton width={100} />
						<Skeleton width={50} />
					</div>
					<div className="text-content-about">
						<Skeleton width={100} />

						<Skeleton width={50} />
						<Skeleton width={100} />
						<Skeleton width={50} />
						<Skeleton width={50} />
					</div>
				</div>
				{/* Personal info section */}
				<div className="label-header">
					<Skeleton width={100} />
					<Skeleton width={"100%"} height={3} />
					<div className="text-content-personal">
						<div className="left-personal-section">
							<div className="status-icon">
								<Skeleton width={50} height={50} circle={true} />
							</div>
							<Skeleton width={70} />
						</div>
						<div className="right-personal-section">
							<Skeleton width={10} height={10} />
						</div>
					</div>
					<div className="text-content-personal">
						<div className="left-personal-section">
							<div className="status-icon">
								<Skeleton width={50} height={50} circle={true} />
							</div>
							<Skeleton width={70} />
						</div>
						<div className="right-personal-section">
							<Skeleton width={10} height={10} />
						</div>
					</div>
					<div className="text-content-personal">
						<div className="left-personal-section">
							<div className="status-icon">
								<Skeleton width={50} height={50} circle={true} />
							</div>
							<Skeleton width={70} />
						</div>
						<div className="right-personal-section">
							<Skeleton width={10} height={10} />
						</div>
					</div>
				</div>

			</div>
		</div>
	}

	const ClientListSkeleton = () => {

		return (
			<>
				{
					[...Array(8)]?.map((item, index) => {
						return (
							<div
								className={`d-flex align-items-center justify-content-between w-100 }`}
							>
								<div className="d-flex gap-10 align-items-center">
									<div
										className={`d-flex align-items-center justify-content-center ${showCircle ? "me-3" : ""
											}`}
									>
										{showCircle && <Skeleton circle width={47} height={47} />}
									</div>
									<div className={"client_details d-block mb-0 p-1"}>
										<Skeleton width={130} height={20} count={1} />
										<Skeleton width={150} height={20} count={1} />
									</div>
								</div>
								<div className="d-flex align-items-center">
									{isGetStarted ? (
										<Skeleton width={50} height={50} />
									) : (
										<Skeleton width={20} height={20} circle />
									)}
								</div>
							</div>
						)
					})
				}

			</>
		);
	};


	const CommonSkeleton = () => {
		return (

			[...Array(8)]?.map((item, index) => {
				return (
					<div className="w-100">
						<Skeleton height={50} width={"100%"} borderRadius={10} />
					</div>
				)
			}
			)
		);
	};

	const EditClientSkeleton = () => {
		return <div class="CustomeNewClientMain">
			<div className="sticky-header custom-add-client-container close-nav-back">
				<div class="close-nav-back d-flex justify-content-between align-items-center">
					<Skeleton width={40} height={40} circle />
					<Skeleton width={84} height={48} borderRadius={100} />
				</div>

			</div>

			<div className="new-add-client-container w-100 pt-2">
				{/* <div className={`container ${true ? "w-100" : ""}`} style={true !== undefined ? { padding: "10px 110px" } : {}}> */}
				<div className={`container ${true ? "w-100" : ""}`}>
					<div className={`row justify-content-between align-items-start`}>
						<div className={`${true ? "col-lg-6 col-md-6" : "col-lg-12 col-md-12"}`}>

							<div className="left-content">
								<Skeleton width={149} height={43} />
								<div className="title-with-content" style={{ paddingLeft: "0px" }}>
									<Skeleton width={100} />
									<Skeleton width={"100%"} className="mt-2" height={3} />
									{/* {basic info start} */}
									<div className={`d-flex flex-column error-section`}>
										<div className="uploadSkelton mt-3">
											<Skeleton width={80} height={80} circle className="mb-3" />
											<Skeleton width={143} height={50} borderRadius={10} className="mb-3" />
										</div>
									</div>

									<div className="d-flex justify-content-center align-items-center m-0">
										<div className="row justify-content-between m-0 w-100 ">
											<div className="title-with-content" style={{ paddingLeft: "0px" }}>
												<Skeleton width={60} height={16} style={{ marginBottom: "8px" }} />
											</div>
											<div className="p-0">
												<Skeleton width={"100%"} height={57} borderRadius={10} />
											</div>
										</div>
									</div>

									<div className="d-flex justify-content-center align-items-center m-0">
										<div className="row justify-content-between m-0 w-100 ">
											<div className="title-with-content" style={{ paddingLeft: "0px" }}>
												<Skeleton width={60} height={16} style={{ marginBottom: "8px" }} />
											</div>
											<div className="p-0">
												<Skeleton width={"100%"} height={57} borderRadius={10} />
											</div>
										</div>
									</div>

									<div className="d-flex justify-content-between align-items-center">
										<div className="row w-100 m-auto">
											<div className="BirthdayWrapper p-0">
												<div className="title-with-content" style={{ paddingLeft: "0px" }}>
													<Skeleton width={60} height={16} style={{ marginBottom: "8px" }} />
												</div>
												<div className="BirthdayRow">
													<div className="col-md-4">
														<Skeleton width={"100%"} height={78} borderRadius={10} />
													</div>
													<div className="col-md-4 ">
														<Skeleton width={"100%"} height={78} borderRadius={10} />
													</div>
													<div className="col-md-4">
														<Skeleton width={"100%"} height={78} borderRadius={10} />
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="d-flex justify-content-center align-items-center m-0">
										<div className="row justify-content-between m-0 w-100 ">
											<div className="title-with-content" style={{ paddingLeft: "0px" }}>
												<Skeleton width={60} height={16} style={{ marginBottom: "8px" }} />
											</div>
											<div className="p-0">
												<Skeleton width={"100%"} height={57} borderRadius={10} />
											</div>
										</div>
									</div>

									<div className="d-flex justify-content-between align-items-center">
										<div className="row w-100 m-auto">
											<div className="BirthdayWrapper p-0">
												<div className="title-with-content" style={{ paddingLeft: "0px" }}>
													<Skeleton width={60} height={16} style={{ marginBottom: "8px" }} />
												</div>
												<div className="BirthdayRow">
													<div className="col-md-4">
														<Skeleton width={"100%"} height={57} borderRadius={10} />
													</div>
													<div className="col-md-4 ">
														<Skeleton width={"100%"} height={57} borderRadius={10} />
													</div>
													<div className="col-md-4">
														<Skeleton width={"100%"} height={57} borderRadius={10} />
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* {basic info end} */}
									<div className="title-with-content" style={{ paddingLeft: "0px" }}>
										<Skeleton width={100} />
										<Skeleton width={"100%"} height={3} />
										<div className={`d-flex flex-column error-section`}>
											<Skeleton width={"100%"} height={50} borderRadius={10} />
										</div>
										<div className="title-with-content" style={{ paddingLeft: "0px" }}>
											<Skeleton width={60} height={16} style={{ marginBottom: "8px" }} />
										</div>
										<div className="row email-section">
											<div className="col-md-3 col-lg-3 mt-2">
												<Skeleton width={"100%"} height={50} borderRadius={10} />
											</div>
											<div className="col-md-9 col-lg-9 mt-2">
												<Skeleton width={"100%"} height={50} borderRadius={10} />
											</div>
										</div>
									</div>

									<div className="d-flex justify-content-center align-items-center m-0">
										<div className="row justify-content-between m-0 w-100 ">
											<div className="title-with-content" style={{ paddingLeft: "0px" }}>
												<Skeleton width={60} height={16} style={{ marginBottom: "8px" }} />
											</div>
											<div className="p-0">
												<Skeleton width={"100%"} height={57} borderRadius={10} />
											</div>
											<div className="title-with-content linkSkeleton text-right mt-2" style={{ paddingRight: "0px" }}>
												<Skeleton width={77} height={28} style={{ marginBottom: "8px" }} />
											</div>
										</div>
									</div>

									<div className="d-flex justify-content-center align-items-center m-0 communicationsSkeleton">
										<Skeleton width={100} height={25} style={{ marginBottom: "8px" }} />
										<Skeleton width={60} height={34} borderRadius={17} style={{ marginBottom: "8px" }} />
									</div>

								</div>
							</div>
						</div>
						{
							(
								<div className="col-lg-5 col-md-5 " style={{ marginTop: "-80px" }}>
									<div className="right-content ">
										<h1>&nbsp;</h1>
										<div className="title-with-content" style={{ paddingLeft: "0px" }}>
											<Skeleton width={100} />
											<Skeleton width={"100%"} height={3} />
											{/* clientId !== undefined to fincen */}
											{
												Array.from(Array(4)).map((item, index) => {
													return <div className="d-flex  treatment-main-action justify-content-between align-items-center gap-3 mt-4">
														<div className="treatment-actions-content align-items-center d-flex gap-2">
															<div className="compliance-img-container">
																<Skeleton width={50} circle={true} height={50} />
															</div>
															<div className="tile-text-section">
																<Skeleton width={100} height={20} />
															</div>
														</div>
														<Skeleton width={20} height={20} circle={true} borderRadius={10} />
													</div>
												})
											}
										</div>
									</div>
								</div>
							)
						}
					</div>
				</div>
			</div>
		</div>
	}

	const ProfileOverViewSkeleton = () => {
		return <div className="client-profile-container customeClientWrapper">
			<div className="container-fluid bg-header m-0 p-0">
				<div className='close-back container-fluid d-flex justify-content-between'>
					<Skeleton width={40} height={40} circle />
					<Skeleton width={77} height={48} className="borderRadiusBtn" />
				</div>
				<div className="client-profile-header customeClientProfileWrapper pt-2">
					<div className="avatar-icon">
						<Skeleton width={80} height={80} circle={true} borderRadius={10} />
					</div>
					<Skeleton width={150} height={30} />
					<Skeleton width={100} height={22} />
					{/* <StepButton blue={true} label={"Book appointment"} className={"w-25"} onClick={()=>setIsRebookAppointment(true)}/> */}
					<div className="contact-container">
						<Skeleton width={49} height={49} circle={true} borderRadius={10} />
						<Skeleton width={49} height={49} circle={true} borderRadius={10} />
						<Skeleton width={49} height={49} circle={true} borderRadius={10} />
					</div>
				</div>
			</div>
			<div className="tile-client-container customeClientContainer m-3">
				{
					Array.from(Array(6)).map((item, index) => (
						<div className="tile-list-container mb-3" key={index} >
							<div className="left-client-content">
								<Skeleton width={48} circle={true} height={48} />
								<Skeleton width={100} height={20} />
							</div>
							<div className="right-client-content">
								<Skeleton width={24} height={24} circle={true} borderRadius={10} />
							</div>
						</div>
					)
					)
				}
			</div>

		</div>
	}

	const ColorPickerSkeleton = () => {
		return (
			<div className="d-flex align-items-center justify-content-between gap-2 color-picker">

				<Skeleton width={50} circle height={50} />
				<Skeleton width={50} circle height={50} />
				<Skeleton width={50} circle height={50} />
				<Skeleton width={50} circle height={50} />
				<Skeleton width={50} circle height={50} />
				<Skeleton width={50} circle height={50} />
				<Skeleton width={50} circle height={50} />

			</div>
		);
	};


	const DashboardTreatmentsList = () => {
		return (
			[...Array(5)].map((item, index) => {
				return (<Skeleton key={index} width={"100%"} height={25} count={1} style={{ borderRadius: '100px' }} />)
			})
		);
	}

	const ManageTabSkeleton = () => {
		return (
			<>
				<div className="section-container mb-5">
					<Skeleton width={330} height={22} />
					<Skeleton width={330} height={1} />
					<div
						className="tile-sub-title-container"
					>
						<div className="left-section">
							<Skeleton width={48} height={48} circle />
							<div className="sub-title-section">
								<Skeleton width={240} height={20} />
								<Skeleton width={240} height={20} />
							</div>
						</div>
						<div className="right-section">
							<Skeleton width={20} height={20} />
						</div>
					</div>
					<div
						className="tile-sub-title-container"
					>
						<div className="left-section">
							<Skeleton width={48} height={48} circle />
							<div className="sub-title-section">
								<Skeleton width={240} height={20} />
								<Skeleton width={240} height={20} />
							</div>
						</div>
						<div className="right-section">
							<Skeleton width={20} height={20} />
						</div>
					</div>
					<div className="section-container mb-5">
						<Skeleton width={330} height={22} />
						<Skeleton width={330} height={1} />
						<div className="tile-container">
							<div className="left-section">
								<Skeleton width={48} height={48} circle />
								<Skeleton width={116} height={20} />
							</div>
							<div className="right-section">
								<Skeleton width={60} height={34} />
							</div>
						</div>
						<div className="tile-container">
							<div className="left-section">
								<Skeleton width={48} height={48} circle />
								<Skeleton width={116} height={20} />
							</div>
							<div className="right-section">
								<Skeleton width={60} height={34} />
							</div>
						</div>
						<div className="tile-container">
							<div className="left-section">
								<Skeleton width={48} height={48} circle />
								<Skeleton width={116} height={20} />
							</div>
							<div className="right-section">
								<Skeleton width={60} height={34} />
							</div>
						</div>
						{

							<div className="tile-container">
								<div className="left-section">
									<Skeleton width={48} height={48} circle />
									<Skeleton width={116} height={20} />
								</div>
								<div className={`right-section`}>
									<Skeleton width={60} height={34} />
								</div>
							</div>
						}
					</div>
				</div>
			</>
		)
	}


	const BeforeAfterPhotosTreatmentListSkeleton = () => {
		return (
			<>
				<div className='before-after-photos-container'>
					<Skeleton width={547} height={44} />
					<Skeleton width={547} height={22} />
					<div className='service-and-other-photos-container'>
						<div className='service-wrapper'>
							{[...Array(3)].map((item) => {
								return <div className='service-container'>
									<div className='d-flex justify-content-between align-items-center'
										style={{ cursor: "pointer" }}
									>
										<div className='treatment-actions-content d-flex gap-2'>
											<div className='compliance-img-container7'>
												<Skeleton width={64} height={64} circle />
											</div>
											<div className='d-flex flex-column mt-2'>
												<Skeleton width={248} height={20} />
												<Skeleton width={248} height={22} className="mt-2" />
											</div>
										</div>
										<div>
											<Skeleton width={24} height={24} />
										</div>
									</div>
								</div>
							})}
						</div>

						<div className='other-photos-container'>
							<Skeleton width={547} height={22} />
							<Skeleton width={547} height={1} />
							<div className='other-photo-upload'>
								<div className='d-flex justify-content-between align-items-center'
									style={{ cursor: "pointer" }}
								>
									<div className='treatment-actions-content d-flex gap-2'>
										<div className='compliance-img-container7'>
											<Skeleton width={72} height={72} circle />
										</div>
										<div className='d-flex flex-column mt-2'>
											<Skeleton width={250} height={20} />
											<Skeleton width={250} height={22} className="mt-2" />
										</div>
										{/* <span className='my-auto'>Notes</span> */}
									</div>
									<div>
										<Skeleton width={24} height={24} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>)
	}

	const BeforeAfterAddPhotoSkeleton = () => {
		return (
			<>
				<div>
					<Skeleton width={547} height={44} />
					<Skeleton width={547} height={22} />
					<div className='photo-container'>
						{[...Array(3)].map((file, index) => (
							<div className='mt-3 row align-items-center d-flex' key={index}>
								<Skeleton width={48} height={22} />
								<div className='col-md-6'>
									{
										<div className={`formDropzoneUploading`}>
											<div className='add-more-label-container'>
												<Skeleton width={260} height={160} />
											</div>
										</div>
									}
								</div>
								<div className='col-md-6'>

									{
										<div className={`formDropzoneUploading`}>
											<div className='add-more-label-container'>
												<Skeleton width={260} height={160} />
											</div>
										</div>
									}
								</div>

							</div >
						))}
					</div>
					<div className='add-more-photo-button'>
						<Skeleton width={547} height={48} className="rounded-pill" />
					</div>
					<Skeleton width={547} height={48} className="rounded-pill" />
				</div>
			</>)
	}

	const BatchNumberPhotoSkeleton = () => {
		return (
			<>
				<div>
					<Skeleton width={547} height={44} />
					<Skeleton width={547} height={22} />
					<div className='photo-container'>
						{[...Array(3)].map((file, index) => (
							<div className='mt-3 row align-items-center d-flex' key={index}>
								<div className='col-md-6'>
									{
										<div className={`formDropzoneUploading`}>
											<div className='add-more-label-container'>
												<Skeleton width={260} height={160} />
											</div>
										</div>
									}
								</div>
								<div className='col-md-6'>

									{
										<div className={`formDropzoneUploading`}>
											<div className='add-more-label-container'>
												<Skeleton width={260} height={160} />
											</div>
										</div>
									}
								</div>

							</div >
						))}
					</div>
					<Skeleton width={547} height={48} className="rounded-pill mt-4" />
				</div>
			</>)
	}

	const VerifiedBannerDashboardSakalton = () => {
		return (
			<div className='verifiedBannerSec'>
				<div className='welcomeSec'>
					<Skeleton className='profile' width={200} />
					<Skeleton className='profile' width={300} />
				</div>
				<div className='bannerSkeleton'>

					<div className='bannerSkeletonLeft'>
						<div className='bannerContent'>

							<div className='bannerContentTitle'>
								<Skeleton className='profile' width={200} />
								<Skeleton className='profile' width={20} />
							</div>
							<div className='bannerContentPera'>
								<Skeleton className='profile' width={300} />
							</div>
						</div>
					</div>

					<div className='bannerSkeletonRight'>
						<div className='bannerButton'>
							<div className='verifiedButton'>
								<Skeleton className='profile' width={200} />
							</div>
						</div>
					</div>
					<div className='closeIconSec'>
						<Skeleton className='profile' width={20} />
					</div>
				</div>
			</div>
		)
	}

	const ThankyouPageProductSkeleton = () => {
		return (
			<div className='product-card-container'>
				<Skeleton width={100} height={10} />
				<div className="row">
					{
						[...Array(12)]?.map((item, index) => (
							<div className="col-sm-6 col-md-4" key={index}>
								<label className="card-main-section">
									<div className='card-img-section'>
										<div className='card-checkbox'>
											<Skeleton width={10} height={10} />
										</div>
										<Skeleton height={150} width={150} />
									</div>
									<div className='card-content-section mt-0'>
										<Skeleton width={100} height={5} />
										<Skeleton width={100} height={5} />
										<Skeleton width={100} height={5} />
									</div>
								</label>
							</div>
						))
					}
				</div>
			</div>
		);
	};

	const PrescriberListSkeleton = () => {
		return (
			[...Array(10)]?.map((item, index) => (
				<div className='prescription-list-container' >
					<div className='left-list-content'>
						<Skeleton width={50} height={50} circle />
						<div className='content-section'>
							<Skeleton width={100} height={10} />
							<Skeleton width={80} height={10} />
						</div>
					</div>
					<div className='right-list-content'>
						<div className='content-section'>
							<Skeleton style={{ visibility: "hidden" }} height={10} />
							<Skeleton width={30} height={10} />
						</div>
					</div>
				</div>
			))
		)
	};

	const AddMoreProductSkeleton = () => {
		return (
			[...Array(10)]?.map((item, index) => (
				<div className='product-list-container'>
					<div className='left-list-content'>
						<Skeleton width={10} height={10} />
						<div className='img-placeholder' >
							<Skeleton width={50} height={50} borderRadius={10} />
						</div>
						<div className='content-section'>
							<Skeleton width={100} height={10} />
							<Skeleton width={80} height={10} />
						</div>
					</div>
					<div className='right-list-content'>
						<div className='right-content-section'>
							<Skeleton width={20} height={10} />
						</div>
					</div>
				</div>
			)
			)
		)
	}

	const PharmacyAboutYouSkeleton = () => {
		return (
			<>
				<div className="main-profile-content">
					<div className="sb-avatar sb-avatar--text">
						<Skeleton width={80} height={80} circle />
					</div>
					<div className="profile-action-container">
						<Skeleton width={111} height={19} borderRadius={10} />
					</div>
				</div>
			</>
		);
	};

	const PharmacyAboutInputSkeleton = () => {
		return (
			<>
				<div class="inputContainer">
					<Skeleton width={65} height={16.8} borderRadius={10} />
					<div>
						<Skeleton width={448} height={65} borderRadius={10} />
					</div>
				</div>
			</>
		)
	};

	const PharmacyHomeSkeleton = () => {
		return (
			<div class="prescriber-card">
				<div class="card-header">
					<Skeleton width={195} height={40} />
				</div>
				<div class="card-content">
					<Skeleton width={404} height={55} />
				</div>
				<div class="card-footer">
					<Skeleton width={404} height={51} borderRadius={100} />
				</div>
			</div>
		)
	}

	const PharmacyAboutStepButtonSkeleton = () => {
		return (
			<>
				<div class="inputContainer">
					<div>
						<Skeleton width={448} height={65} borderRadius={100} />
					</div>
				</div>
			</>
		)
	};
	const PharmacyBusinessStepButtonSkeleton = () => {
		return (
			<>
				<div class="inputContainer">
					<div>
						<Skeleton width={540} height={65} borderRadius={100} className="mb-5 mt-2" />
					</div>
				</div>
			</>
		)
	};

	const PharmacyBusinessInputSkeleton = () => {
		return (
			<>
				<div class="inputContainer">
					<Skeleton width={65} height={16.8} borderRadius={10} />
					<div>
						<Skeleton width={540} height={65} borderRadius={10} />
					</div>
				</div>
			</>
		)
	}
	const HairProductCardSkeleton = () => {
		return (
			<div className="hair-product-card-skeleton">
				<div className="left-img-skeleton">
					<Skeleton width={134} height={134} />
				</div>
				<div className="right-content-skeleton">
					<div className="d-flex flex-column gap-1">
						<Skeleton width={200} height={20} />
						<Skeleton width={250} height={30} />
					</div>
					<div>
						<Skeleton width={100} height={30} />
					</div>
				</div>
			</div>
		);
	}

	const HairProductPageSkeleton = () => {
		return (
			// <div className='main-hair-product-container'>


			//   <div className="header-border">
			//     <div className='hair-product-header container'>
			//       <div className="d-flex align-items-center">
			//         <Skeleton width={40} height={40} circle style={{ marginRight: "20px" }} />
			//         <Skeleton width={85} height={25} />
			//       </div>
			//       {/* <Skeleton width={150} height={40} /> */}
			//       <Skeleton width={160} height={55} />
			//     </div>
			//   </div>


			//   <div className="hair-product-container">
			//     <div className=" d-flex justify-content-between  flex-column flex-lg-row">
			//       <div className="hair-product-container-left-content">
			//         <div className="hair-product-left-wrapper">
			//           <div className="Hair-product-sub-heading">
			//             <div className="Hair-product-sub-heading-content">
			//               <Skeleton width={220} height={43} />
			//               <Skeleton width={490} height={45} />
			//             </div>
			//           </div>
			//           <div className="hair-product-left-card-container flex-gap-60">

			//             <div className="left-cards-div">
			//               <HairProductCardSkeleton />
			//               <HairProductCardSkeleton />
			//               <HairProductCardSkeleton />
			//             </div>

			//             <Skeleton width={490} height={51} borderRadius={100} />

			//             <Skeleton width={490} height={51} borderRadius={100} />
			//           </div>
			//         </div>
			//       </div>
			//       <div className="hair-product-container-right-content">
			//         <div className="hair-product-summary">
			//           <Skeleton width={371} height={43} />
			//         </div>
			//       </div>

			//     </div>
			//   </div>


			// </div>
			<div className='hair-product-info-popup-main-container SkeletonHairProduct'>
				<div className='hair-product-info-popup-container row align-items-center gx-5'>
					<div className="hair-popup-left-content col-md-6">
						<h2><Skeleton width={412} height={57} /></h2>
						<div className="sub-content-pop-up">
							<p><Skeleton width={412} height={90} /></p>
						</div>
						<div className="checked-content">
							<div className="d-flex gap-1">
								<p className='m-0'><Skeleton width={258} height={22} /></p>
							</div>
							<div className="d-flex gap-1">
								<p className='m-0'><Skeleton width={258} height={22} /></p>
							</div>
							<div className="d-flex gap-1">
								<p className='m-0'><Skeleton width={258} height={22} /></p>
							</div>
						</div>
						<div className='d-flex align-items-center mt-4 gap-4 btnWrapper'>
							<Skeleton width={200} borderRadius={100} height={50} />
							<Skeleton width={200} borderRadius={100} height={50} />
						</div>
					</div>


					<div className="hair-popup-right-content col-md-6">
						<div className="hair-loss-img-div row">
							<div className="hair-loss-img col-6 gap-3">
								<Skeleton width={194} height={289} borderRadius={10} style={{ marginBottom: '6px', }} />
								<p className='text-center'><Skeleton width={194} height={22} /></p>
							</div>
							<div className="hair-loss-img col-6 gap-3">
								<Skeleton width={194} height={289} borderRadius={10} style={{ marginBottom: '6px' }} />
								<p className='text-center'><Skeleton width={194} height={22} /></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	const ShareLinkPageSkeleton = () => {
		return (
			<div className='hair-Product-link-container'>

				<div className={`close-nav-back d-flex justify-content-end `} >
					<Skeleton width={40} height={40} circle style={{ margin: "30px 165px 0 0" }} />
				</div>
				<div className="hair-product-section">
					<div className="container">
						<div className='d-flex justify-content-center'>
							<Skeleton width={150} height={170} />
						</div>
						<div className='hair-product-link-main-div'>
							<h4>
								<Skeleton width={300} height={40} />
							</h4>
							<p>
								<Skeleton width={400} height={40} />
							</p>
							<div className="hair-product-link-input-div">
								<Skeleton width={516} height={42} />
							</div>
							<div className="icon-images-div">
								<div className='icon-image' >
									<Skeleton width={50} height={50} circle />
									<Skeleton width={32} height={17} />
								</div>
								<div className='icon-image' >
									<Skeleton width={50} height={50} circle />
									<Skeleton width={32} height={17} />
								</div>
								<div className='icon-image' >
									<Skeleton width={50} height={50} circle />
									<Skeleton width={32} height={17} />
								</div>
								<div className='icon-image' >
									<Skeleton width={50} height={50} circle />
									<Skeleton width={32} height={17} />
								</div>
								<div className='icon-image' >
									<Skeleton width={50} height={50} circle />
									<Skeleton width={32} height={17} />
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		);
	};

	const QuestionListSkeleton = () => {
		return (
			<div className="questions-list-section">
				<div className="question-container">
					<div className="row">
						<div className="col-md-12">
							<div className="question">
								<h3>
									<Skeleton width={540} height={51} borderRadius={10} />
								</h3>
								<div className="row firstRow">
									<div className="col-md-6">
										<Skeleton width={256} height={64} borderRadius={10} />
									</div>
									<div className="col-md-6">
										<Skeleton width={256} height={64} borderRadius={10} />
									</div>
								</div>
								<div className="row">
									<div className="question-comment col-md-12" style={{ marginTop: "20px" }}>
										<Skeleton width={540} height={120} borderRadius={10} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const ForwardConsentFormReviewSkeleton = () => {
		return (

			<div className='consent-form-overview-section'>
				<Skeleton width={200} height={40} />
				<div className='overview-details-section mt-5'>
					{/* CLIENT DETAILS */}
					<div className='heading-section'>
						<Skeleton width={100} height={20} />
						<Skeleton width={50} height={20} />
					</div>
					<Skeleton width={"100%"} height={5} />
					{[...Array(5)].map(() => (
						<div className='content-overview-section'>
							<Skeleton width={100} height={10} />
							<Skeleton width={100} height={10} />
						</div>
					))}
				</div>

				<div className='overview-details-section'>
					{/* APPOINTMENT DETAILS */}
					<div className='heading-section mt-5'>
						<Skeleton width={100} height={20} />

						<b></b>
					</div>
					<Skeleton width={"100%"} height={5} />
					{[...Array(3)].map(() => (
						<div className='content-overview-section'>
							<Skeleton width={100} height={10} />
							<Skeleton width={100} height={10} />
						</div>
					))}
				</div>

				<div className='overview-details-section'>
					{/* TREATMENT DETAILS */}
					<div className='heading-section mt-5'>
						<Skeleton width={100} height={20} />
						<b></b>
					</div>
					<Skeleton width={"100%"} height={5} />
					{[...Array(5)].map(() => (
						<div className='content-overview-section'>
							<Skeleton width={100} height={10} />
							<Skeleton width={100} height={10} />
						</div>
					))}

				</div>

				<div className='overview-details-section'>
					{/* PAYMENT SUMMARY DETAILS */}
					<div className='heading-section mt-5'>
						<Skeleton width={100} height={20} />
						<b></b>
					</div>
					<Skeleton width={"100%"} height={5} />
					{[...Array(3)].map(() => (
						<div className='content-overview-section'>
							<Skeleton width={100} height={10} />
							<Skeleton width={100} height={10} />
						</div>
					))}
				</div>
			</div>
		)
	}


	const ForwardConsentFormQuestionSkeleton = () => {
		return (
			[...Array(5)]?.map((item, index) => (<div className='questions-list-section'>
				<div className='question-container'>
					<div className='row'>
						<div className='col-md-12'>
							<div className='question'>
								<h3><Skeleton width={100} height={20} /></h3>
								<div className='row'>
									<div className='col-md-6'>
										<Skeleton width={"100%"} height={55} borderRadius={10} />
									</div>
									<div className='col-md-6'>
										<Skeleton width={"100%"} height={55} borderRadius={10} />
									</div>
								</div>
								<div className='row'>
									<div className='forward-question-comment col-md-12' style={{ marginTop: "15px" }}>
										<Skeleton width={"100%"} height={120} borderRadius={10} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			))
		)
	}


	const ForwardConsentFormDisclaimerSkeleton = () => {
		return (
			[...Array(1)]?.map((item, index) => (
				<Skeleton width={"100%"} borderRadius={10} height={60} />
			))
		)
	}

	const ForwardPaymentScreenSkeleton = () => {
		return (
			<div className="w-100 consent-form-payment-card-section">
				<div className='treatment-payment-section'>
					{paymentSummary?.processingFeeBearer ? (<>
						<div className='payment-card d-flex' style={{ paddingBottom: "0px" }}>
							<Skeleton width={100} height={24} />
							<Skeleton width={100} height={24} />
						</div>
						<div className='payment-card d-flex'>
							<Skeleton width={100} height={24} />
							<Skeleton width={100} height={24} />
						</div></>) : <div className='payment-card d-flex'>
						<Skeleton width={100} height={24} />
						<Skeleton width={100} height={24} />
					</div>}
					{/* <div className='payment-card d-flex'>
            <Skeleton width={100} height={24} />
            <Skeleton width={100} height={24} />
          </div>
          {paymentSummary.processingFeeBearer && <div className='payment-card d-flex'>
            <Skeleton width={100} height={24} />
            <Skeleton width={100} height={24} />
          </div>}
          {paymentSummary.processingFeeBearer && <div className='payment-card d-flex'>
            <Skeleton width={100} height={24} />
            <Skeleton width={100} height={24} />
          </div>} */}

					{/* <Skeleton width={"100%"} height={1}/> */}
					{/* <div className='payment-card d-flex'>
                             <Skeleton width={50} height={24}/>
                             <Skeleton width={50} height={24}/>
                          </div>
                          <Skeleton width={"100%"} height={1}/>
                          <div className='payment-card d-flex'>
                             <Skeleton width={50} height={24}/>
                             <Skeleton width={50} height={24}/>
                          </div>
                          <Skeleton width={"100%"} height={1}/>
                          <div className='payment-card d-flex'>
                             <Skeleton width={50} height={24}/>
                             <Skeleton width={50} height={24}/>
                          </div> */}
				</div>
			</div>
		)
	}


	const ForwardConsentFormPaymentOptionsSkeleton = () => {
		return (
			[...Array(4)]?.map((item, index) => (
				<div className='consent-form-payment-option'>
					<Skeleton width={100} height={10} />
					<Skeleton width={"100%"} height={5} />
					<div className="payment-option-section">
						<div className="d-flex w-100 justify-content-between align-items-center">

							<div className="d-flex align-items-center">
								<Skeleton width={48} height={48} borderRadius={"50%"} />
								<div className="d-flex flex-column" >
									<Skeleton width={100} height={10} borderRadius={"50"} />
									<Skeleton width={150} height={10} borderRadius={"50"} />
								</div>
							</div>
							<div>
								<Skeleton width={24} height={24} borderRadius={"50%"} />
							</div>
						</div>
					</div>
				</div>
			))
		)
	}

	const PharmacyTermsAndConditionSkeleton = () => {
		return (
			[...Array(10)]?.map((item, index) => (
				<div className="check-box">
					<div className="form-check">
						<Skeleton width={20} height={20} borderRadius={"20%"} />
						<div>
							<Skeleton width={250} height={20} />
						</div>
					</div>

					<div>
						<div>
							<Skeleton width={823} height={50} />
						</div>
					</div>
				</div>
			))
		)
	}

	const AppointentListClinicListSkeleton = () => {
		return (
			[...Array(12)].map((items, index) => {
				return <div className='appointment-location-wrapper mt-3'>
					<Skeleton width={73} height={20} />
					<div className='location-timing-wrapper'>
						<Skeleton width={98} height={22} />
					</div>
				</div>
			})
		)
	}



	const ConsentFormClientDetailsSkeleton = () => {
		return (
			<div className='edit-client-details-container'>
				<Skeleton width={512} height={43} />
				<Skeleton width={512} height={22} />
				<div className='edit-client-details-form-section'>
					{/* FIRST NAME */}
					<div className='client-edit-detail-container'>
						<Skeleton width={512} height={16} />
						<Skeleton width={512} height={57} />
					</div>
					{/* BIRTHDAY */}
					<div className="row w-100 m-auto">
						<div className="BirthdayWrapper p-0">
							<Skeleton width={512} height={16} />


							<div className="BirthdayRow client-edit-detail-container">

								<div className="col-md-4">
									<Skeleton width={160} height={57} />
								</div>
								<div className="col-md-4 ">
									<Skeleton width={160} height={57} />
								</div>
								<div className="col-md-4">
									<Skeleton width={146} height={57} />
								</div>
							</div>
						</div>
					</div>
					{/* EMAIL */}
					<div className='client-edit-detail-container'>
						<Skeleton width={512} height={16} />
						<Skeleton width={512} height={57} />
					</div>

					{/* PHONE NUMBER */}
					<div className="row client-edit-detail-container w-100">
						<Skeleton width={512} height={16} />

						<div className="col-4 col-sm-3 mt-2 ps-0 ">
							<Skeleton width={116} height={57} />

						</div>
						<div className="col-8 col-sm-9 mt-2 pe-0 ">
							<Skeleton width={372} height={57} />
						</div>
					</div>
					{/* ADDRESS */}
					<div className='client-edit-detail-container'>
						<div className=" w-100">
							<Skeleton width={512} height={16} />
							<Skeleton width={512} height={57} />
						</div>
					</div>
				</div>

			</div>

		)
	}


	const UpdateLocationAvailabilitySkeleton = () => {
		return (
			<>
				<div className="one-time-change-hours-break-container">
					{<img src={backArrow} style={{ cursor: 'pointer' }} alt="" />}
					<div className='header-title-hours-break'>
						<Skeleton width={471} height={10} />
						<Skeleton width={471} height={10} />
						<div className='open-for-business-toggle'>
							<Skeleton width={130} height={22} />
							<Skeleton width={40} height={34} circle />
						</div>
						{<div className='hours-and-break-container'>
							<div className='mt-2'>
								<Skeleton width={471} height={16} />
								<Skeleton width={471} height={55} />
							</div>
							<div className='BreakFieldsWrapper'>
							</div>

							<div className='add-break-wrapper'>
								<Skeleton width={90} height={22} />
							</div>

							<div className='mt-3'>
								<Skeleton width={471} height={16} />
								<Skeleton width={471} height={55} />
							</div>
						</div>
						}
						<div className='btnWrapper'>
							<Skeleton width={471} height={48} />
						</div>
					</div>

				</div>
			</>
		)
	}

	const NewPrescriberListSkeleton = () => {
		return (
			<>
				<div class="prescriber-list-wrapper">
					<label class="prescription-list-container d-flex">
						<div class="left-list-content">
							<div class=" sb-avatar sb-avatar--text">
								<div class=" sb-avatar__text">
									<div>
										<span>
											<span><Skeleton circle width={48} height={48} /></span>
										</span>
									</div>
								</div>
							</div>
							<div class="content-section">
								<p><Skeleton width={104} height={20} /></p>
								<p><Skeleton width={104} height={20} /></p>
								<p><Skeleton width={104} height={20} /></p>
							</div>
						</div>
						<div class="RightPartRating">
							<Skeleton circle width={67} height={24} />
						</div>
					</label>
				</div>
			</>
		)
	}
	const ClientConsentFormListSkeleton = () => {
		return (
			<>
				{
					[...Array(3)].map((parentData, i) => {
						return (
							<div className='client_form_card cursor-pointer' key={i}>
								<div className='client-form-data-wrapper'>
									<div className='client_form_content'>
										<Skeleton width={142} height={22} />
										<Skeleton width={142} height={22} />
									</div>
									<div className='client_form_content mt-2'>
										<Skeleton width={142} height={22} />
										<Skeleton width={142} height={22} />
									</div>
									<div className='client_form_content mt-2'>
										<Skeleton width={142} height={22} />
										<Skeleton width={142} height={22} />
									</div>
									<div className='client_form_content mt-2'>
										<Skeleton width={142} height={22} />
										<Skeleton width={142} height={22} />
									</div>
								</div>
								<div className='status-icon-wrapper'>
									<Skeleton width={79} height={20} />
									{/* <div>
                    <Skeleton width={10} height={33} className="ms-2" />
                  </div> */}
								</div>
							</div>

						)
					})

				}
			</>
		)
	}

	const PrescriptionListSkeleton = () => {
		return (
			<>
				{
					[...Array(3)].map((parentData, i) => {
						return (
							<div className='prescriber-list-wrapper'>
								<label className='prescription-list-container'>
									<div className='left-list-content'>
										<div className='IconWrapper'>
											<Skeleton width={48} height={48} circle />
										</div>
										<div className='content-section'>
											<div className='DropdownWrapper'>
												<h4><Skeleton width={180} height={20} borderRadius={4} /></h4>
												<Skeleton width={24} height={20} borderRadius={4} />
											</div>
											<p><Skeleton width={93} height={22} borderRadius={4} /></p>
											<p><Skeleton width={93} height={22} borderRadius={4} /></p>
										</div>
									</div>
									<div className='RightRatingWrapper'>
										<Skeleton width={24} height={24} circle />
										<Skeleton width={24} height={24} circle />
									</div>
								</label>
							</div>
						)
					})
				}
			</>
		)
	}

	const MainPrescriberListSkeleton = () => {
		return (
			<>
				{
					[...Array(3)].map((parentData, i) => {
						return (
							<div className="prescriber-list-wrapper">
								<label className="prescription-list-container">
									<div className="left-list-content">
										<Skeleton width={48} height={48} circle />
										<div className="content-section">
											<h4><Skeleton width={94} height={20} borderRadius={4} /></h4>
											<p><Skeleton width={148} height={22} borderRadius={4} /></p>
											<p><Skeleton width={57} height={22} borderRadius={4} /></p>
										</div>
									</div>
									<Skeleton width={47} height={24} borderRadius={100} style={{ padding: "0px" }} />
								</label>
							</div>
						)
					})
				}
			</>
		)
	}

	const RebookListingTitleSkeleton = () => {
		return (
			<>
				<div className="time-slot-header">
					<div className="timeSlotIconWrapper">
						<Skeleton width={24} height={30} borderRadius={4} />
						<h1 className="timeSlotContent">
							<Skeleton width={100} height={30} borderRadius={4} />
						</h1>
					</div>
					<div>
						<Skeleton width={185} height={20} borderRadius={4} />
					</div>
				</div>

				<div className="timeSlotContentWrapper">
					{
						[...Array(7)].map((parentData, i) => {
							return (
								<div className="time-slot-content">
									<label className="radio-label">
										<div className="content-section">
											<Skeleton width={40} height={22} borderRadius={4} />
										</div>
										<Skeleton width={22} height={22} circle />
									</label>
								</div>
							)
						}
						)}
				</div>
			</>
		)
	}

	const CalendarViewSkeleton = () => {
		return (
			<>
				<div className={`Main-calender-Screen timeGridWeek calendar-full-width}`}>
					<div className="calendar-main-div" >
						<div className="calendar-section">
							<div className={`calendar-header-main-div`}>
								<div className="row mx-0">

									<div className="col-md-3">
										<div className="search-div-wrapper left-div">
											<div className="search-div-inner-wrapper cursor-pointer"
											>
												{/* <img src={BackIcon} alt="BackIcon" /> */}
											</div>
										</div>
									</div>
									<div className="col-md-6 d-flex justify-content-center align-items-center">
										<div className="calendar-date-day-arrow-title">
											<div>
												<div className="date-title">
													<Skeleton width={139} height={40} />
												</div>
											</div>
											<Skeleton width={120} height={40} />
										</div>
									</div>
									<div className="col-md-3">
										<div className="search-div-wrapper">
											<div className={'CustomeSearchInput'}>
												<div className="mainIconSearch">
													<Skeleton width={35} height={35} className="ms-2" />
												</div>
											</div>
											<div>
												<Skeleton width={35} height={35} className="ms-2" />
											</div>
											<Skeleton width={35} height={35} className="ms-2" />
										</div>
									</div>
								</div>
							</div>
							<Skeleton width={dimensions?.width} height={73} />
							<div className="weekly-view-wrapper">
								{[...Array(42)].map(() => {
									return <Skeleton width={"100%"} height={73} />
								})}
							</div>
						</div>
					</div>
					<div className={"filter-wrap"}>

					</div>
					{/* } */}
				</div>
			</>
		)
	}

	const OnBoardingStepSkeleton = () => {
		return (
			<>
				<div className="main-div-dashboard">
					<Skeleton width={976} height={43} />
					<div className="sub-header-text">
						<Skeleton width={976} height={22} />
					</div>
					<div className="progressbar">
						<Skeleton width={976} height={8} />
					</div>

					<div className="card-container">
						{
							[...Array(3)].map((item) => {
								return <>
									< div className="card-main">
										<div className="card-header">
											<div className="logo">
												<Skeleton width={49} height={48} circle />
											</div>
											<div className="clock">
												<Skeleton width={21} height={21} circle />
												<Skeleton width={57} height={21} />
											</div>
										</div>
										<div className="card-body">
											<Skeleton width={270} height={24} />
											<Skeleton width={270} height={24} />
										</div>
										<div className="btn-div">
											<Skeleton width={270} height={24} />
										</div>
									</div>
								</>
							})

						}
					</div>
				</div >

			</>
		)
	}

	const LocationFixedHoursAvailabilitySkeleton = () => {
		return (
			<>
				<div className='set-hours-container'>
					<Skeleton width={558} height={43} />
					<Skeleton width={558} height={22} />
					<div className="working-hours-div-main" >
						{[...Array(7)]?.map((item) => {
							return <div className="hours-select">
								<div className="time-day">
									<Skeleton className="day" width={97} height={19} />
									<Skeleton className="time" width={97} height={22} />
								</div>
								<div className="swsBtn">
									<Skeleton className="rounded-pill time" width={60} height={34} />
								</div>
							</div >
						})
						}
						<div className="set-hours-btn-div">
							<Skeleton className="rounded-pill time" width={558} height={48} />
						</div>
					</div >
				</div >
			</>
		)
	}

	const LocationFixedHoursForDaySkeleton = () => {
		return (
			<>
				<div className='clinic_working_hours_main'>
					<div className='clinic_working_hours_title'>
						<Skeleton width={558} height={44} />
					</div>
					<div>
						<Skeleton width={558} height={88} />
					</div>
					<div className="time-container">
						{/* <Skeleton width={558} height={16} /> */}
						<Skeleton width={558} height={55} />

						{[...Array(2)].map((breakTime, index) => (
							<div className='mt-3 row align-items-center d-flex' key={index}>
								<div className='col-md-5'>
									<Skeleton width={218} height={16} />
									<Skeleton width={218} height={55} />
								</div>
								<div className='col-md-5'>
									<Skeleton width={218} height={16} />
									<Skeleton width={218} height={55} />
								</div>
								<div className='col-md-2 mt-2'>
									<Skeleton width={48} height={48} circle />
								</div>
							</div>
						))}

						<Skeleton className="timeBtn" width={167} height={51} />

						{/* <Skeleton width={558} height={16} /> */}
						<Skeleton width={558} height={55} />
					</div>
					<div className="add-time-btn-div">
						<Skeleton className="rounded-pill time" width={558} height={48} />
					</div>
				</div>
			</>
		)
	}
	const FormsListSkeleton = () => {
		return (
			<>
				{[...Array(7)].map(() => {
					return <div className="FormsListItem">
						<div className="FormsListItemContent">
							<Skeleton width={48} height={48} circle />
							<div className="ItemContent">
								<h1>
									<Skeleton width={162} height={22} borderRadius={4} />
								</h1>
							</div>
						</div>
						<Skeleton width={25} height={32} borderRadius={4} />
					</div>
				})
				}
			</>
		)
	}
	const EditFormSkeleton = () => {
		return <div className="consent-edit-form">
			<Skeleton className="form-title" style={{ display: 'block' }} height={43} borderRadius={10} />
			<div className="form-edit-options">
				<div className="question-wrapper">
					{[...Array(4)].map(() => {
						return <Skeleton style={{ display: 'block' }} width={540} height={90} borderRadius={20} />
					})}
				</div>
			</div>
		</div>
	}

	const PreviewAfterCareFormSkeleton = () => {
		return <div className="form-content">
			<div className="row form-title">
				<p style={{ marginBottom: '8px' }}>
					<Skeleton width={300} height={43} borderRadius={10} />
				</p>
				<p className="form-title__subtitle">
					<Skeleton width={250} height={23} borderRadius={10} />
				</p>
			</div>
			<div className="form-edit-options">
				<Skeleton height={160} borderRadius={20} />
			</div>
		</div>
	}
	const GoogleRatingSkeleton = () => {
		return (
			<div>
				<div className="ReviewSecWrapper">
					<div className="ReviewWrapper">
						<div className="leftSide">
							<ul>
								<li><Skeleton width={38} circle height={38} /></li>
								<li><Skeleton width={38} circle height={38} /></li>
								<li><Skeleton width={38} circle height={38} /></li>
								<li><Skeleton width={38} circle height={38} /></li>
								<li><Skeleton width={38} circle height={38} /></li>
							</ul>
						</div>
						<div className="RightSide">
							<ul className="StarWrapper">
								{/* {Array.from({ length: 5 }, (_, index) => (
                    <li key={index}>
                        {index < getRatingsQueryData?.data?.getRating?.data?.trustpilot_rating ? <StarIcon /> : ""} 
                    </li>
                    ))} */}
							</ul>
							<span><Skeleton width={38} height={17} /></span>
							<span className="Link"><Skeleton width={38} height={17} /></span>
						</div>
					</div>
					<Skeleton className="ReviewContent">
					</Skeleton>
				</div>
			</div>
		)
	}
	const RenderView = () => {
		switch (type) {
			case "list":
				return <ListSkeleton />;
			case "list-treatment":
				return <ListTreatmentSkeleton />;
			case "business-step":
				return <BusinessStepSkeleton />;
			case "photoCover":
				return <PhotoCoverSkeleton />;
			case "photoOther":
				return <PhotoOtherSkeleton />;
			case "tab":
				return <TabTreatmentSkeleton />
			case "calendar":
				return <CalendarSkeleton />
			case "time":
				return <TimeSkeleton />
			case "treatment-details":
				return <TreatmentDetailsSkeleton />
			case "team-member":
				return <TeamMemberSkeleton />
			case "appointment":
				return <AppointmentListSkeleton />
			case "appointment-detail":
				return <AppointmentHeaderSkeleton />
			case "team-member-web":
				return <TeamMemberSkeletonWeb />
			case "color-picker":
				return <ColorPickerSkeleton />
			case "dashbaord-list":
				return <DashboardTreatmentsList />
			case "appointment-manage-tab":
				return <ManageTabSkeleton />
			case "client-list":
				return <ClientListSkeleton />
			case "profile-overview":
				return <ProfileOverViewSkeleton />
			case "client-profile":
				return <ClientProfileSkeleton />
			case "edit-client":
				return <EditClientSkeleton />
			case "on-boarding-step":
				return <OnBoardingStepSkeleton />
			case "location-fixed-hours-availability":
				return <LocationFixedHoursAvailabilitySkeleton />
			case "location-fixed-hours-for-day":
				return <LocationFixedHoursForDaySkeleton />
			case "form-list":
				return <FormsListSkeleton />
			case "common-list":
				return <CommonSkeleton />
			case "before-after-photos-treatment-list":
				return <BeforeAfterPhotosTreatmentListSkeleton />
			case "before-after-add-photos":
				return <BeforeAfterAddPhotoSkeleton />
			case "batch-number-photos":
				return <BatchNumberPhotoSkeleton />
			case "verifiedBannerSkeleton":
				return <VerifiedBannerDashboardSakalton />
			case "thankyou-product":
				return <ThankyouPageProductSkeleton />
			case "prescriber-list":
				return <PrescriberListSkeleton />
			case "add-more-product":
				return <AddMoreProductSkeleton />
			case "pharmacy-about-you":
				return <PharmacyAboutYouSkeleton />;
			case "pharmacyAboutInputSkeleton":
				return <PharmacyAboutInputSkeleton />;
			case "PharmacyHomeSkeleton":
				return <PharmacyHomeSkeleton />;
			case "PharmacyAboutStepButtonSkeleton":
				return <PharmacyAboutStepButtonSkeleton />;
			case "PharmacyBusinessInputSkeleton":
				return <PharmacyBusinessInputSkeleton />;
			case "PharmacyBusinessStepButtonSkeleton":
				return <PharmacyBusinessStepButtonSkeleton />;
			case "hair-product-card":
				return <HairProductCardSkeleton />
			case "hair-product-main-page":
				return <HairProductPageSkeleton />
			case "share-link-page":
				return <ShareLinkPageSkeleton />
			case "QuestionList":
				return <QuestionListSkeleton />
			case "forward-consent-review":
				return <ForwardConsentFormReviewSkeleton />
			case "forward-consent-question":
				return <ForwardConsentFormQuestionSkeleton />
			case "forward-consent-disclaimer":
				return <ForwardConsentFormDisclaimerSkeleton />
			case "forward-consent-payment":
				return <ForwardPaymentScreenSkeleton />
			case "forward-payment-options":
				return <ForwardConsentFormPaymentOptionsSkeleton />
			case "edit-form-skeleton":
				return <EditFormSkeleton />
			case "preview-aftercare-form-skeleton":
				return <PreviewAfterCareFormSkeleton />
			case "pharmacy-terms-condition":
				return <PharmacyTermsAndConditionSkeleton />
			case "consent-form-client-details":
				return <ConsentFormClientDetailsSkeleton />
			case "appointment-list-clinic-list-skeleton":
				return <AppointentListClinicListSkeleton />
			case "update-location-availability":
				return <UpdateLocationAvailabilitySkeleton />
			case "client-consent-form-list-skeleton":
				return <ClientConsentFormListSkeleton />
			case "prescriber-select":
				return <NewPrescriberListSkeleton />
			case "prescription-List":
				return <PrescriptionListSkeleton />
			case "prescriber-List":
				return <MainPrescriberListSkeleton />
			case "RebookListing-Title":
				return <RebookListingTitleSkeleton />
			case "calendar-view":
				return <CalendarViewSkeleton />
			case "google-Rating-Skeleton":
				return <GoogleRatingSkeleton />
			default:
				return null
		}
	};

	return (
		<>
			<RenderView />
		</>
	);
};

export default SkeletonLoader;