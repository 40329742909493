import React, { useEffect, useState } from 'react'
import { GetDownloadInvoiceDetailsQuery } from '../../redux/actions/invoice/GetInVoiceDetails'
import { useParams } from 'react-router';




const DownloadInvoice = () => {
    const { initDownloadInvoiceDetails, invoiceDownloadDetailsQueryData } = GetDownloadInvoiceDetailsQuery()
    const [invoiceData, setInvoiceData] = useState();
    const params = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        if (urlParams.get("token")) {
            initDownloadInvoiceDetails({
                "token": urlParams.get("token"),
                "appointmentId": parseInt(params.appointmentId)
            }, (res) => {
                if (res?.success) {
                    console.log("res", res)
                    setInvoiceData(res.data.invoiceBase64)
                }
            }, (err) => {

            })
        }
    }, [])

    useEffect(() => {
        if (invoiceDownloadDetailsQueryData) {
            setInvoiceData(invoiceDownloadDetailsQueryData?.data?.getVerifyTokenForInvoiceBase64?.data?.invoiceBase64)
        }
    }, [invoiceDownloadDetailsQueryData])

    return (
        <>
            {
                invoiceDownloadDetailsQueryData?.loading ? <div className="LoaderMain">
                    <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div> : !invoiceDownloadDetailsQueryData?.data?.getVerifyTokenForInvoiceBase64?.success && invoiceDownloadDetailsQueryData?.called ?
                    <div className="h-100 d-flex justify-content-center align-items-center text-center fw-bold fs-1 ">
                        Invalid Link!
                    </div> :
                    invoiceData && (
                        <iframe
                            src={`data:application/pdf;base64,${invoiceData}`}
                            // type="application/pdf"
                            width="100%"
                            height="800px"

                        ></iframe>
                    )}
        </>
    )
}

export default DownloadInvoice