import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const SAVE_BUSINESS_HOURS_MUTATION = gql`
mutation SavePersonalWebsiteBusinessHours($personalWebsiteBusinessHours: [PersonalWebsiteBusinessHoursType]) {
  savePersonalWebsiteBusinessHours(personalWebsiteBusinessHours: $personalWebsiteBusinessHours) {
    success
    message
    data {
      updated
      message
    }
  }
}
`;

export const SaveBusinessHours = () => {
  const [saveBusinessHoursRequest, saveBusinessHoursResponseData] =
    useMutation(SAVE_BUSINESS_HOURS_MUTATION);
  const dispatch = useDispatch();
  const saveBusinessHoursMutationData = saveBusinessHoursResponseData;

  const initiatesaveBusinessHours = (
    data,
    successCallback,
    errorCallback
  ) => {
    saveBusinessHoursRequest({
      variables: data,
    })
      .then(({ data: { savePersonalWebsiteBusinessHours: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        // errorCallback(error);
      });
  };
  return { initiatesaveBusinessHours, saveBusinessHoursMutationData };
};
