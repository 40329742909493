export const GET_APPOINTMENT_LIST_DATA = 'GET_APPOINTMENT_LIST_DATA'
export const GET_APPOINTMENT_LIST_DATA_SUCCESS = 'GET_APPOINTMENT_LIST_DATA_SUCCESS'
export const GET_APPOINTMENT_LIST_DATA_ERROR = 'GET_APPOINTMENT_LIST_DATA_ERROR'
export const GET_APPOINTMENT_DETAILS_DATA = 'GET_APPOINTMENT_DETAILS_DATA'
export const GET_APPOINTMENT_DETAILS_V3_DATA = 'GET_APPOINTMENT_DETAILS_V3_DATA'
export const GET_APPOINTMENT_HOLD_DURATION = 'GET_APPOINTMENT_HOLD_DURATION'
export const GET_APPOINTMENT_FOLLOW_UPS = 'GET_APPOINTMENT_FOLLOW_UPS'
export const GET_MEDICAL_FORM_STATUS = 'GET_MEDICAL_FORM_STATUS'

export const CLEAR_APPOINTMENT_LIST_DATA = 'CLEAR_APPOINTMENT_LIST_DATA'
export const CLEAR_APPOINTMENT_DETAILS_DATA = 'CLEAR_APPOINTMENT_DETAILS_DATA'
export const CLEAR_APPOINTMENT_DETAILS_V3_DATA = 'CLEAR_APPOINTMENT_DETAILS_V3_DATA'
export const CLEAR_APPOINTMENT_HOLD_DURATION = 'CLEAR_APPOINTMENT_HOLD_DURATION'
export const CLEAR_APPOINTMENT_FOLLOW_UPS = 'CLEAR_APPOINTMENT_FOLLOW_UPS'
export const CLEAR_MEDICAL_FORM_STATUS = 'CLEAR_MEDICAL_FORM_STATUS'

export const GET_APPOINTMENT_LIST_BY_TYPE = 'GET_APPOINTMENT_LIST_BY_TYPE'
export const CLEAR_APPOINTMENT_LIST_BY_TYPE = 'CLEAR_APPOINTMENT_LIST_BY_TYPE'
export const GET_APPOINTMENT_LIST_BY_TYPE_SUCCESS = 'GET_APPOINTMENT_LIST_BY_TYPE_SUCCESS'

export const SAVE_CALENDAR_DAILYVIEW_DATA = 'SAVE_CALENDAR_DAILYVIEW_DATA'

export const GET_APPOINTMENT_LIST_CLINICS = 'GET_APPOINTMENT_LIST_CLINICS'
export const CLEAR_APPOINTMENT_LIST_CLINICS = 'CLEAR_APPOINTMENT_LIST_CLINICS'

export const GET_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION = 'GET_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION'
export const CLEAR_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION = 'CLEAR_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION'


export const UPDATE_CLINIC_LIST_DATA = 'UPDATE_CLINIC_LIST_DATA'
export const CHECK_START_DATE_FOR_LOADING = 'CHECK_START_DATE_FOR_LOADING'

