import { gql, useLazyQuery } from "@apollo/client"
import { GET_WEBSITE_PACKAGE } from "../../constants/createWebiste/getAllWebsitePackages";
import { useDispatch } from "react-redux";

const GET_WEBSITE_PACKAGE_DATA = gql`
query GetWebsitePackage($packageSlug: String, $userToken: String) {
    getWebsitePackage(packageSlug: $packageSlug, userToken: $userToken) {
      success
      message
      data {
        id
        package_name
        amount
        amountText
        duration_text
        package_points
        is_popular
        slug
        can_subscribe
        is_package_purchase
        package_amount
      }
    }
  }
`

export const GetWebsitePackageDataAction = () => {
    const dispatch = useDispatch()
    const [GetWebsitePackageDataFunctionResponse, getWebsitePackageResponseData] = useLazyQuery(GET_WEBSITE_PACKAGE_DATA, {
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
        onCompleted: ({ getWebsitePackage: responseData }) => {
            if (responseData?.success) {
                dispatch({
                    type: GET_WEBSITE_PACKAGE,
                    payload: responseData?.data
                })
            }
        }
    })
    const GetWebsitePackageResponseData = getWebsitePackageResponseData

    const initGetWebsitePackageFunction = (data) => {
        return GetWebsitePackageDataFunctionResponse({ variables: data })
    }
    return { initGetWebsitePackageFunction, GetWebsitePackageResponseData }

}