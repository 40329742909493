import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_ALL_FINANCE_OFFERED_TO_CLIENT_QUERY = gql`
query GetAllFinanceOfferedToClientFromDB($currentPage: Int, $ordersPerPage: Int, $timeFrame: String, $startDateFromUser: String, $endDateFromUser: String) {
  getAllFinanceOfferedToClientFromDB(current_page: $currentPage, ordersPerPage: $ordersPerPage, timeFrame: $timeFrame, startDateFromUser: $startDateFromUser, endDateFromUser: $endDateFromUser) {
    success
    message
    data {
      payl8r_order_id
      application_status_data {
        status
        color
      }
      clientName
      clinicName
      treatments
      date
      invoiceLink
      retailerOrderId
    }
    pagination {
      totalRecords
      currentPage
      totalPages
    }
  }
}
`;

export const GetAllFinanceOfferedToClientFromDBAction = () => {
  const [
    getAllFinanceOfferedToClientFromDBResponseFunction,
    getAllFinanceOfferedToClientFromDBResponseData,
  ] = useLazyQuery(GET_ALL_FINANCE_OFFERED_TO_CLIENT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: ({ getAllFinanceOfferedToClientFromDB: responseData }) => {},
  });

  const getAllFinanceOfferedToClientFromDBQueryData =
    getAllFinanceOfferedToClientFromDBResponseData;

  const initGetAllFinanceOfferedToClientFromDBData = (
    data,
    successCallback,
    errorCallback
  ) => {
    getAllFinanceOfferedToClientFromDBResponseFunction({
      variables: data,
    })
      .then((responseData) => {
        if (responseData?.data?.getAllFinanceOfferedToClientFromDB?.success) {
          successCallback(
            responseData?.data?.getAllFinanceOfferedToClientFromDB
          );
        } else {
          errorCallback(responseData?.data?.getAllFinanceOfferedToClientFromDB);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  return {
    getAllFinanceOfferedToClientFromDBQueryData,
    initGetAllFinanceOfferedToClientFromDBData,
  };
};
