import React, { useEffect, useState } from 'react'

import backArrow from "../../../images/icons/BackArrow.svg"
import dummyProfile from "../../../images/icons/dummyProfileImage.png"
import rightArrow from "../../../images/icons/chevron-left.svg"
import facial from "../../../images/icons/facial.svg"
import notes from "../../../images/icons/note.svg"
import photo from "../../../images/icons/photo.svg"
import forms from "../../../images/icons/forms.svg"
import medical from "../../../images/icons/medical.svg"
import barcode from "../../../images/icons/barcode.svg"
import medicalForm from "../../../images/icons/medicalForm.svg"
import consentFormIcon from "../../../images/icons/consentFormIcon.svg"
import aftercareFormIcon from "../../../images/icons/aftercareFormIcon.svg"
import { ReactComponent as RightArrow } from "../../../images/new-appointment/right_arrow.svg";
import { ReactComponent as HoldDurationActiveIcon } from "../../../images/new-appointment/duration_active.svg";
import { ReactComponent as FollowUpActiveIcon } from "../../../images/new-appointment/followup_active.svg";
import { ReactComponent as FollowUpInActiveIcon } from "../../../images/new-appointment/followup_orange.svg";
import { ReactComponent as HoldDurationInActiveIcon } from "../../../images/new-appointment/duration_orange.svg";
import { ReactComponent as TextReminderIcon } from "../../../images/new-appointment/text_reminder.svg";
import { ReactComponent as EmailReminderIcon } from "../../../images/new-appointment/email_reminder.svg";
import { ReactComponent as CollectPaymentIcon } from "../../../images/new-appointment/collect_payment.svg";
import { ReactComponent as OfferPayIcon } from "../../../images/new-appointment/offpay_month.svg";
import "./appointment.scss"
import { useLocation, useNavigate, useParams } from 'react-router';
import { NewAppointmentRoutePattern, appointmentOverviewBatchNumberRoute, appointmentOverviewBeforeAndAfterPhotosRoute, businessDashboardRoutePattern, getAppointmentListRoutePattern, getCalendarRoute, getClientProfileRoute, getEditAppointmentRoute } from "../../../Routes";
import { useDispatch, useSelector } from 'react-redux';
import timeIcon from "../../../images/icons/ComplianceTime.svg"
import timeIcon1 from "../../../images/icons/ComplianceTime1.svg"
import timeIcon2 from "../../../images/icons/ComplianceTime2.svg"
import { cancelAppointment, clearAppointmentStatus, clearCancelAppointmentStatus, clearFormApiCalled, getAppointmentDetails } from '../../redux/actions'
import SkeletonLoader from '../../common/Skeleton/SkeletonLoader'
import ConsentActionPopup from '../../consent-action-popup'
import { ToastContainer, toast } from 'react-toastify'
import getAppointmentList from '../../redux/actions/dashboard/appointments'
import { frontendURL } from "../../../config";
import { pounds, upperCaseWordsOfSentent } from '../../../utils/general'
import CustomModal from '../../modal/CustomModal'
import SwitchButton from '../../common/switchButton/SwitchButton'
import StepButton from '../../common/StepButton'
import OffCanvasModal from '../../modal/OffCanvasModal/OffCanvasModal'
import CustomRadioButton from '../../common/CustomRadioButton'
import TextInput from '../../common/TextInput'
import { GetAppointmentDetailsForDocumentTabAction, GetAppointmentDetailsForManageTabAction, GetAppointmentDetailsQuery, RequestDepositForAppointmentAction } from '../../redux/actions/appointments/GetAppointmentDetails'
import { GetFinanceStatusAction, GetStripeAccountAndFinanceRequestStatusAction, GetTextMessageBalanceDetailsAction } from '../../redux/actions/newAppointment/BusinessBoosters'
import { GetAppointmentHoldDurationQuery } from '../../redux/actions/appointments/GetAppointmentHoldDuration'
import { GetAppointmentFollowUpsQuery } from '../../redux/actions/appointments/GetAppointmentFollowUp'
import { CLEAR_APPOINTMENT_FOLLOW_UPS, CLEAR_APPOINTMENT_HOLD_DURATION } from '../../redux/constants/appointment/appointmentConst'
import { CreateHoldDurationMutation } from '../../redux/actions/appointments/CreateHoldDuration'
import { CreateFollowUpMutation } from '../../redux/actions/appointments/CreateFollowUps'
import SMSSucriptionModal from '../../new-appointment/sms-subscription/SMSSucriptionModal'
import { SaveBusinessBoostersDetailsAction, StripeOnboardingAction } from '../../redux/actions/appointments/createNewAppointment'
import { CLEAR_APPOINTMENT_DOCUMENT_TAB_DATA, CLEAR_APPOINTMENT_MANAGE_TAB_DATA, CLEAR_APPOINTMENT_STATUS, CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT } from '../../redux/constants'
import showToast from '../../common/CustomToast/CustomToast'
import { ReactComponent as EmptySignIcon } from "../../../images/new-appointment/empty_sign.svg";
import { GetAppointmentDetailsAction } from '../../redux/actions/dashboard/appointmentDetailsGql'
import Suffix from "../../../images/new-appointment/sufix.svg";
import { useSearchParams } from "react-router-dom";
import AddNotes from '../../common/AddNotes'
import AfterCareList from './AfterCareList'
import FacialMappingModalContent from './facialmapping-modal-content/FacialMappingModalContent'
import { SaveAftercareDocumentsAppointmentAction } from '../../redux/actions/appointments/SaveAftercareDocumentsOfAppointment'
import { GetAftercareDocumentsAppointmentAction } from '../../redux/actions/appointments/GetAftercareDocumentsOfAppointment'
import { ReactComponent as ConcentFormIcon } from "../../../images/icons/appointment-list/ConsentFormIcon.svg";
// import { ReactComponent as AfterCareIcon } from "../../../images/new-appointment/aftercare_icon.svg";
import { ReactComponent as MedicalFormIcon } from '../../../images/icons/appointment-list/MedicalFormIcon.svg'
import { ReactComponent as AfterCareIcon } from '../../../images/icons/appointment-list/AftercareIcon.svg'


const Appointment = ({ slugFromCalender, fromCalendar = false, setIsAppointmentDetailsOpen, setSlugFromCalender, setIsCancleAppointmentResponse, }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showResendAlert, setShowResendAlert] = useState(false);
    const [showCancelAppointmentAlert, setShowCancelAppointmentAlert] = useState(false);
    const [popupType, setPopupType] = useState("resend");
    const [isHoldDurationOpen, setIsHoldDurationOpen] = useState(false);
    const [isFollowUpOpen, setIsFollowUpOpen] = useState(false);
    const [compliancePopupShow, setCompliancePopupShow] = useState(false);
    const [confirmationPopupShow, setConfirmationPopupShow] = useState(false);
    const moment = require('moment');
    const appointmentDetails = useSelector((state) => state.appointmentReducer.appointmentDetails);
    const bookingDetails = useSelector((state) => state.appointmentReducer.bookingDetails);
    const businessBoosterData = useSelector((state) => state.appointmentReducer.businessBoosterData)
    const cancelAppointmentStatus = useSelector((state) => state.appointmentReducer.cancelAppointmentStatus);
    const cancelAppointmentSuccessMessage = useSelector((state) => state.appointmentReducer.cancelAppointmentSuccessMessage);
    const resendFormSuccessStatus = useSelector((state) => state.formsReducer.resendFormSuccessStatus);
    const resendFormSuccessMessage = useSelector((state) => state.formsReducer.resendFormSuccessMessage);
    const doucumentTabStatus = useSelector((state) => state.appointmentReducer.doucumentTabStatus);

    const docTabData = useSelector((state) => state.appointmentReducer.docTabData);
    const manageTabData = useSelector((state) => state.appointmentReducer.manageTabData);


    const location = useLocation().state
    //SELECTOR
    const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)
    const holdDurationDetails = useSelector(state => state?.appointmentHoldDurationReducer?.appointmentHoldDuration)
    const followUpsDetails = useSelector(state => state?.appointmentFollowUpsReducer?.appointmentFollowUps)



    //Graph QL
    const { initGetAppointmentDetails, appointmentDetailsQueryData } = GetAppointmentDetailsQuery()
    const { initGetTextMessageBalanceDetailsData } = GetTextMessageBalanceDetailsAction()
    const { initGetStripeAccountAndFinanceRequestStatusData } = GetStripeAccountAndFinanceRequestStatusAction()
    const { initGetFinanceStatusData } = GetFinanceStatusAction()
    // const { initGetAppointmentHoldDuration } = GetAppointmentHoldDurationQuery()
    // const { initGetAppointmentFollowUps } = GetAppointmentFollowUpsQuery()
    const { createHoldDurationMutationData, initiateCreateHoldDurationResponseData } = CreateHoldDurationMutation()
    const { createFollowUpMutationData, initiateCreateFollowUpResponseData } = CreateFollowUpMutation()
    const { saveBusinessBoostersDetailsQueryData, initSaveBusinessBoostersDetailsData } = SaveBusinessBoostersDetailsAction()

    const { initGetAppointmentDetailsForDocumentTabData } = GetAppointmentDetailsForDocumentTabAction()
    const { initGetAppointmentDetailsForManageTabData } = GetAppointmentDetailsForManageTabAction()

    const { initRequestDepositForAppointmentData, requestDepositForAppointmentQueryData } = RequestDepositForAppointmentAction()
    const { stripeOnboardingQueryData, initStripeOnboardingData } = StripeOnboardingAction()

    const [showConsentPopup, setShowConsentPopup] = useState()
    const [selectedTime, setSelectedTime] = useState(null);
    const [selectedFollowUpTime, setSelectedFollowUpTime] = useState(null);
    const [buttonActive, setButtonActive] = useState("days")
    const [followUpType, setFollowUpType] = useState(0)
    const [showSelectedDate, setShowSelectedDate] = useState(moment().format("dddd, Do MMMM"));
    //SMS SUBSCRIPTION MODAL 
    const [isSmsSubscriptionOpen, setIsSmsSubscriptionOpen] = useState(false);

    //OFFER PAY MONTHLY LEARN MODAL
    const [isOfferPayMonthlyLearnOpen, setIsOfferPayMonthlyLearnOpen] = useState(false);
    const [stripeFinceStatusObj, setStripeFinceStatusObj] = useState(null)
    const [active, setActive] = useState("overview");
    const [otherText, setOtherText] = useState("");
    const [hours, setHours] = useState(0)

    //EDIT PRICE
    const [editDepositPriceModal, setEditDeposiPriceModal] = useState(false)
    const [depositPrice, setDepositPrice] = useState(0)
    const [isDepositError, setIsDepositError] = useState("")
    const [appointmentNotes, setAppointmentNotes] = useState("")
    const [isNoteForm, setIsNoteForm] = useState(false)
    const [openAfterCareModal, setOpenAfterCareModal] = useState(false)
    const [selectedAfterCareData, setSelectedAfterCareData] = useState([])
    const [aftercareDocumentStatus, setAftercareDocumentsStatus] = useState(false)

    // FACIAL MAPPING MODAL
    const [facialMappingModal, setFacialMappingModal] = useState(false)
    const [facialmappingStatus, setFacialMappingStatus] = useState(false)
    const [step, setStep] = useState(0)
    const [stripeConnectionModalState, setstripeConnectionModalState] = useState(false)



    console.log(appointmentDetails, "appointmentDetails date date")

    //CHECK STRING CONTAIN ONLY NUMBER OR NOT
    const isNumber = (value) => {
        console.log(/^\d+$/.test(value), "AKJSKJDKJ")
        return /^\d+$/.test(value);
    }

    //HANDLE EDIT DEPOSIT PRICE
    const handleEdirDepositPriceForManageTab = () => {

        //CALL API
        initRequestDepositForAppointmentData({
            appointmentId: appointmentDetails?.booking_id,
            depositAmount: parseFloat(depositPrice)
        }, (res) => {
            if (res.success) {
                setEditDeposiPriceModal(false)
                setDepositPrice("")
                initGetAppointmentDetails({ bookingId: parseInt((slug || slugFromCalender)) })
                manageTabDataAoiCall()

            } else {
                setIsDepositError(res.message)
            }
        })
    }


    //MANAGE TAB DATA API CALL
    const manageTabDataAoiCall = () => {
        initGetAppointmentDetailsForManageTabData({
            appointmentId: parseInt((slug || slugFromCalender))
        })
    }

    //GET STRIPE ACCOUNT AND FINANCE REQUEST STATUS
    useEffect(() => {
        if (UserProfileData)
            initGetStripeAccountAndFinanceRequestStatusData({}, (response) => { setStripeFinceStatusObj(response) })
    }, [UserProfileData])

    useEffect(() => {
        if (searchParams.get("documents")) {
            searchParams.delete("documents")
            setSearchParams(searchParams)
            initGetAppointmentDetailsForDocumentTabData({
                appointmentId: parseInt((slug || slugFromCalender))
            })
            setActive("documents")
        }
    }, [searchParams])

    let treatmentTotal = 0;
    const slug = useParams().slug;

    const redirectUrl = `${frontendURL}/appointment/${slug || slugFromCalender}`
    const encodedRedirectUrl = encodeURIComponent(redirectUrl);

    const { getAppointmentDetailsQueryData, initGetAppointmentDetailsData } = GetAppointmentDetailsAction();
    const { initSaveAftercareDocumentsAppointmentData, aftercareDocumentsQueryData } = SaveAftercareDocumentsAppointmentAction();
    const { initGetAftercareDocumentsAppointmentData, getAftercareDocumentsAppointmentQueryData } = GetAftercareDocumentsAppointmentAction();

    const getAfterCareDocumentApiCall = () => {
        initGetAftercareDocumentsAppointmentData({
            appointmentId: parseInt(slug || slugFromCalender),
        }, (res) => {
            if (res.success) {
                setSelectedAfterCareData(res?.data?.aftercareDocuments)

            } else {
                console.log("res", res.message)
            }
        }, (err) => {
        })
    }

    useEffect(() => {
        if (!appointmentDetails && (slug || slugFromCalender)) {
            //dispatch(getAppointmentDetails({ "booking-slug": (slug || slugFromCalender) }))
            initGetAppointmentDetails({ bookingId: parseInt((slug || slugFromCalender)) })
            getAfterCareDocumentApiCall();
        }
    }, [appointmentDetails])

    useEffect(() => {
        dispatch({
            type: CLEAR_APPOINTMENT_HOLD_DURATION
        })
        dispatch({
            type: CLEAR_APPOINTMENT_STATUS
        })
        window.scrollTo(0, 0)
    }, [])

    console.log("holdDuration and followUps", holdDurationDetails, followUpsDetails)


    useEffect(() => {
        if (resendFormSuccessStatus) {
            // toast.success(resendFormSuccessMessage);
            setShowResendAlert(true);
            dispatch(clearFormApiCalled());
        }
    }, [resendFormSuccessStatus])

    const handleHoldDurationData = () => {
        initiateCreateHoldDurationResponseData({
            bookingId: parseInt((slug || slugFromCalender)),
            duration: parseInt(selectedTime)
        }, (res) => {
            if (res.success) {
                manageTabDataAoiCall()
                initGetAppointmentDetails({ bookingId: parseInt((slug || slugFromCalender)) })
                setIsHoldDurationOpen(false)
                showToast("success", res.message, "", false)
            } else {
                setIsHoldDurationOpen(true)
                showToast("error", res.message, "", false)
            }
        }, (err) => {
            console.log("hold duration", err)
            showToast("error", err.message, "", false)
        })

    }

    const handleFollowUpData = () => {
        initiateCreateFollowUpResponseData({
            appointmentId: parseInt((slug || slugFromCalender)),
            followup: parseInt(selectedFollowUpTime) || parseInt(otherText),
            followupTpye: followUpType
        }, (res) => {
            if (res.success) {
                manageTabDataAoiCall()
                setIsFollowUpOpen(false)
                showToast("success", res.message, "", false)
            } else {
                setIsFollowUpOpen(true)
                showToast("error", res.message, "", false)
            }
        }, (err) => {
            showToast("error", err.message, "", false)
            console.log("hold duration", err)
        })
    }


    useEffect(() => {
        console.log('true!', cancelAppointmentStatus);
        if (cancelAppointmentStatus) {
            showToast("success", cancelAppointmentSuccessMessage, "", false)
            dispatch(getAppointmentList());
            dispatch(clearAppointmentStatus());
            dispatch(clearCancelAppointmentStatus());
            // localStorage.clear();
            navigate(getAppointmentListRoutePattern);
        }
    }, [cancelAppointmentStatus])

    useEffect(() => {

        document.body?.classList.remove('ReactModal__Body--open');

        return () => {
            document.body?.classList.remove('ReactModal__Body--open');
        };
    }, [showConsentPopup, showResendAlert]);


    console.log(resendFormSuccessMessage, "success message");

    const [toggle, setToggles] = useState({
        textReminder: false,
        emailReminder: false,
        collectPayment: false,
        offerPay: false,
    });

    // useEffect(() => {

    // if (stripeFinceStatusObj?.paymentAccountSetup == true) {
    //     setToggles((prevToggles) => ({
    //         ...prevToggles,
    //         collectPayment: !prevToggles["collectPayment"],
    //     }));
    // } else if (appointmentDetails?.deposit_amount > 0) {
    //     setToggles((prevToggles) => ({
    //         ...prevToggles,
    //         collectPayment: !prevToggles["collectPayment"],
    //     }));
    // }
    //     console.log("stripeFinceStatusObj", stripeFinceStatusObj)
    // }, [stripeFinceStatusObj])


    const initialBusinessBoosterAPICall = (type, toggleType) => {

        initSaveBusinessBoostersDetailsData({
            appointmentId: appointmentDetails?.booking_id,
            flagType: type,
            flagValue: toggleType,
            smsBalanceAvailable: type == "TEXTREMINDER" ? !toggle[type] : false
        }, (res) => {
            console.log("response business booster", res)
            if (res?.success) {
                manageTabDataAoiCall()
                // initGetAppointmentDetails({ bookingId: parseInt((slug || slugFromCalender)) })
            }
        }, (e) => {
            console.log("error business booster", e)
        })
    }


    const handleToggleChange = (toggleName, event = false) => {
        if (event && event.target.checked) {

            if (toggleName == 'TEXTREMINDER') {
                console.log("evernt text", event.target.checked, toggleName, toggle.textReminder)
                if (appointmentDetails?.mobile_number == "") {
                    showToast("error", "Selected client doesn't have phone number", "", false)
                    return
                }
                initialBusinessBoosterAPICall("TEXTREMINDER", toggle.textReminder)
                //API CALL 
                initGetTextMessageBalanceDetailsData({
                    // userId: UserProfileData?.user?.usr_id,
                    appointmentDate: moment(showSelectedDate, "dddd, Do MMMM").format("YYYY-MM-DD")
                }, (response) => {
                    console.log("res text", response)
                    if (response?.creditAvailable === true) {
                        setToggles((prevToggles) => ({
                            ...prevToggles,
                            [toggleName]: !prevToggles[toggleName],
                        }));
                        initialBusinessBoosterAPICall(toggleName, true)
                    } else {
                        setIsSmsSubscriptionOpen(true)
                    }
                })
            }
            if (toggleName == 'EMAILREMINDER') {
                console.log("evernt email", event.target.checked, toggleName, toggle.emailReminder)
                // initialBusinessBoosterAPICall("EMAILREMINDER", toggle.emailReminder)
                setToggles((prevToggles) => ({
                    ...prevToggles,
                    [toggleName]: !prevToggles[toggleName],
                }));
                initialBusinessBoosterAPICall(toggleName, event.target.checked)

            }
            if (toggleName == 'OFFERPAYMONTHLY') {
                // initialBusinessBoosterAPICall("OFFERPAYMONTHLY", toggle.textReminder)
                if (stripeFinceStatusObj?.financeRequestStatus == 0) {
                    initGetFinanceStatusData({
                        userId: UserProfileData?.user?.usr_id
                    }, (response) => {
                        if (response?.financeStatus == 1) {
                            setIsOfferPayMonthlyLearnOpen(true)
                        }
                    })
                } else if (stripeFinceStatusObj?.financeRequestStatus == 1) {
                    setIsOfferPayMonthlyLearnOpen(true)
                } else if (stripeFinceStatusObj?.financeRequestStatus == 2) {
                    if (appointmentDetails?.sub_total > 50) {

                        setToggles((prevToggles) => ({
                            ...prevToggles,
                            [toggleName]: !prevToggles[toggleName],
                        }));
                        initialBusinessBoosterAPICall(toggleName, event.target.checked)
                    } else {
                        showToast("error", "Total amount must be greater than 50", "", false)
                    }

                }
            }
            if (toggleName == 'COLLECTPAYMENT') {
                let isValid
                if (slug || slugFromCalender) {

                    isValid = stripeFinceStatusObj?.paymentAccountSetup === true && manageTabData?.depositRequestable && appointmentDetails?.depositAmount > 0
                } else {
                    isValid = stripeFinceStatusObj?.paymentAccountSetup === true
                }
                if (isValid) {
                    setToggles((prevToggles) => ({
                        ...prevToggles,
                        [toggleName]: !prevToggles[toggleName],
                    }));
                    initialBusinessBoosterAPICall(toggleName, event.target.checked)

                } else {
                    if (!stripeFinceStatusObj?.paymentAccountSetup) {
                        setstripeConnectionModalState(true)
                        return
                    }
                    if (!stripeFinceStatusObj?.paymentAccountSetup || (!manageTabData?.depositRequestable && (slug || slugFromCalender)))
                        showToast("error", "your bank account is not connected!", "", false)
                    else {
                        // jay bajarangbali

                        setEditDeposiPriceModal(true)
                        // showToast("error", "Deposit amount must be greater than 0", "", false)
                    }
                }
            }
        } else {

            if (toggleName == 'COLLECTPAYMENT') {
                showToast("error", "You can not disable collect payment", "", false)
                return
            }
            setToggles((prevToggles) => ({
                ...prevToggles,
                [toggleName]: !prevToggles[toggleName],
            }));
            initialBusinessBoosterAPICall(toggleName, false)
        }


    };

    const calculateTotalHours = (start, end) => {
        const currentDate = moment().format('YYYY-MM-DD');

        const startTime = moment(`${currentDate} ${moment(start, "DD-MM-YYYY HH:mm").format("HH:mm")}`);
        const endTime = moment(`${currentDate} ${moment(end, "DD-MM-YYYY HH:mm").format("HH:mm")}`);

        // Calculate the difference in minutes
        const minutes = endTime.diff(startTime, 'minutes');

        if (minutes < 60) {
            return `(${minutes} minutes)`
        } else {
            // If 60 minutes or more, display hours
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;

            if (hours === 1) {
                if (remainingMinutes > 0) {
                    return `(${hours} hour ${remainingMinutes} minutes)`;
                } else {
                    return `(${hours} hour)`;
                }
            } else {
                if (remainingMinutes > 0) {
                    return `(${hours} hours ${remainingMinutes} minutes)`;
                } else {
                    return `(${hours} hours)`;
                }
            }

        }
    }

    //Booking Details
    console.log("booking Details", appointmentDetails);
    //Time slot data
    const timeData = [
        {
            id: 1,
            label: "24 hours",
            timeSlot: 1
        },

        {
            id: 2,
            label: "48 hours",
            timeSlot: 2

        },

        {
            id: 3,
            label: "72 hours",
            timeSlot: 3
        },
        {
            id: 4,
            label: "4 days",
            timeSlot: 4

        },
        {
            id: 5,
            label: "5 days",
            timeSlot: 5
        },
    ];

    //Time slot data
    const followUpData = [
        {
            id: 1,
            label: "24 hours",
            timeSlot: 1
        },

        {
            id: 2,
            label: "1 week",
            timeSlot: 7

        },

        {
            id: 3,
            label: "2 weeks",
            timeSlot: 14

        },
        {
            id: 4,
            label: "1 month",
            timeSlot: 30
        },
        {
            id: 5,
            label: "3 months",
            timeSlot: 90
        },
        {
            id: 6,
            label: "6 months",
            timeSlot: 180
        },
        {
            id: 7,
            label: "9 months",
            timeSlot: 270,

        },
        {
            id: 8,
            label: "12 months",
            timeSlot: 365,

        },
        {
            id: 9,
            timeSlot: "Other",
            label: "Other"
        },
    ];

    console.log(appointmentDetails, "check console", appointmentDetails?.collectPayment || !appointmentDetails?.depositRequestable)

    //change button days button
    const handleToggleButton = (name) => {
        setButtonActive(name)
        switch (name) {
            case "days":
                return setFollowUpType(1)
            case "weeks":
                return setFollowUpType(2)
            case "months":
                return setFollowUpType(3)
            default:
                return setFollowUpType(0)
        }

    }

    //CLEAR REDUCER DATA MANAGE TAB AND DOCUMENT TAB
    const handleClearDetails = () => {
        dispatch({ type: CLEAR_APPOINTMENT_DOCUMENT_TAB_DATA })
        dispatch({ type: CLEAR_APPOINTMENT_MANAGE_TAB_DATA })
    }

    //Set Followup and Hold Duration
    useEffect(() => {

        if (manageTabData !== null) {
            setSelectedTime(manageTabData?.holdOn)
            setFollowUpType(manageTabData?.followupType)
            setOtherText(manageTabData?.followup)
            if (manageTabData?.followupType !== 0) {
                setSelectedFollowUpTime("Other")

                switch (manageTabData?.followupType) {
                    case 1:
                        return setButtonActive("days");
                    case 2:
                        return setButtonActive("weeks")
                    case 3:
                        return setButtonActive("months")
                    default:
                        return setButtonActive("days")
                }
            } else {
                setSelectedFollowUpTime(manageTabData?.followup)
            }

        }
    }, [manageTabData])


    //Prefilled Toggle Data
    useEffect(() => {
        if (manageTabData)
            setToggles({
                textReminder: manageTabData?.SMSReminder,
                emailReminder: manageTabData?.emailReminder,
                collectPayment: manageTabData?.collectPayment,
                offerPay: manageTabData?.payMonthly
            })
    }, [manageTabData])

    const saveAfterCareDocuments = (selectedData) => {
        const id = selectedData.map((item) => {
            return item.aftercareDocId
        })
        console.log("id", id)
        initSaveAftercareDocumentsAppointmentData({
            appointmentId: parseInt(slug || slugFromCalender),
            aftercareDocumentIds: id,
        }, (res) => {
            if (res.success) {
                setAftercareDocumentsStatus(true)
                showToast("success", res.message, false, "")
                getAfterCareDocumentApiCall();
            } else {

            }
        }, (err) => {
            console.log("error on create note", err)
        })
    }
    return (
        <div className='Main-AppointmentDetails-page'>
            {
                appointmentDetails ?
                    <div className=''>
                        <div className={`close-back d-flex justify-content-between ${fromCalendar ? "fromCalendar-close-back" : ""}`}>
                            <img src={backArrow} style={{ cursor: 'pointer' }} alt="" onClick={() => {
                                dispatch(clearAppointmentStatus());
                                handleClearDetails()
                                if (fromCalendar) {
                                    setSlugFromCalender(null)
                                    setIsAppointmentDetailsOpen(false)
                                } else {
                                    location?.appointmentListing ? navigate(getAppointmentListRoutePattern) :
                                        location?.fromCalenderAppointmentList === "calender" ? navigate(getCalendarRoute(), { state: { fromAppointmentList: location?.fromAppointmentList, calenderViewState: location?.calenderViewState, calenderShowSelectedDate: location?.calenderShowSelectedDate } }) :
                                            location?.clientProfile == "clientprofile" ? navigate(getClientProfileRoute(location.clientId), { state: { fromAppointmentScreen: true, active: location.active } }) :
                                                location?.fromCalendarAppointmentOverview ? navigate(getCalendarRoute()) :
                                                    // location?.fromAppointmentList ? navigate(getCalendarRoute(), { state: { fromAppointmentList: location?.fromAppointmentList, calenderViewState: location?.calenderViewState, calenderShowSelectedDate: location?.calenderShowSelectedDate } }) :
                                                    navigate(businessDashboardRoutePattern)
                                }
                            }} />
                            <button onClick={() => {
                                handleClearDetails()
                                dispatch(clearAppointmentStatus());
                                // navigate(NewAppointmentRoutePattern + "?bookingId=" + appointmentDetails?.booking_id)
                                if (location?.clientProfile == "clientprofile") {
                                    navigate(getEditAppointmentRoute(appointmentDetails?.booking_id), { state: { fromClientProfile: true, active: location.active, clientId: location.clientId } })
                                }
                                else if (location?.fromCalenderAppointmentList) {
                                    navigate(getEditAppointmentRoute(appointmentDetails?.booking_id), { state: { fromCalenderAppointmentOverview: true, calenderViewState: location?.calenderViewState, calenderShowSelectedDate: location?.calenderShowSelectedDate } })
                                }
                                else if (location?.fromCalenderAppointment) {
                                    navigate(getEditAppointmentRoute(appointmentDetails?.booking_id), { state: { fromCalenderAppointment: true, calenderViewState: location?.calenderViewState, calenderShowSelectedDate: location?.calenderShowSelectedDate } })
                                }
                                else if (location?.fromDashboardAppointment) {
                                    navigate(getEditAppointmentRoute(appointmentDetails?.booking_id), { state: { fromDashboardAppointment: true } })
                                }
                                else if (location?.appointmentListing) {
                                    navigate(getEditAppointmentRoute(appointmentDetails?.booking_id), { state: { appointmentListing: true } })
                                }
                                else {
                                    navigate(getEditAppointmentRoute(appointmentDetails?.booking_id))
                                }
                                // window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/calendar/records/" + `?elementID=${appointmentDetails?.booking_id}` + `&redirect-url=${encodedRedirectUrl}`
                            }}>Edit</button>
                        </div>
                        <div className='row main-appointment-container icon-close-button'>

                            <div className={`appointment-header ${fromCalendar ? "fromCalendar-appointment-header" : ""} p-3`}>
                                <div className='user-image-container d-flex'>
                                    {/* {(appointmentDetails?.client_image === "not present" || appointmentDetails?.client_image === '') ? <h2 className='m-auto'>{appointmentDetails?.client_fname ? appointmentDetails?.client_fname[0]?.toUpperCase() : "" + (appointmentDetails?.client_lname ? appointmentDetails?.client_lname[0]?.toUpperCase() : appointmentDetails?.client_fname[1]?.toUpperCase())}</h2> : <img src={appointmentDetails?.client_image} />} */}
                                    {(appointmentDetails?.client_image === "not present" || appointmentDetails?.client_image === '') ? <h2 className='m-auto'>{
                                        appointmentDetails?.client_fname != "" && appointmentDetails?.client_lname != "" ? appointmentDetails?.client_fname[0]?.toUpperCase() + appointmentDetails?.client_lname[0]?.toUpperCase() :
                                            appointmentDetails?.client_fname != "" ? appointmentDetails?.client_fname[0]?.toUpperCase() :
                                                appointmentDetails?.client_lname[0]?.toUpperCase()
                                    }
                                    </h2> : <img src={appointmentDetails?.client_image} />}

                                </div>
                                {/* {appointmentDetails?.deposit_status?.replace(/\s\s+/g, ' ')?.split(" ")[1] === "Due" ? <span className='span-2'>Deposit due</span> : appointmentDetails?.deposit_status === "unconfirmed" ? <span className='span-2'>Uncomfirmed</span> : <span className='span-1'>Confirmed with deposit</span>} */}
                                <span className='span-2 text-capitalize AppoimentTag' style={{ backgroundColor: appointmentDetails?.deposit_status_color }}>
                                    {appointmentDetails?.deposit_status}
                                </span>

                                {appointmentDetails?.auto_cancel_in_days_and_hours_and_minutes && appointmentDetails?.auto_cancel_in_days_and_hours_and_minutes != "" ? <p>Auto - Cancel in {appointmentDetails?.auto_cancel_in_days_and_hours_and_minutes}</p> : <p></p>}
                                <h2>{moment(appointmentDetails?.treatment_date, 'DD-MM-YYYY').format('ddd, DD MMM') + ((moment(appointmentDetails?.treatment_date, 'DD-MM-YYYY').format('MM') === "09") ? "t" : "")}</h2>
                                <h3>{moment(appointmentDetails?.start_time, 'DD-MM-YYYY HH:mm').format('HH:mm')} – {moment(appointmentDetails?.end_time, 'DD-MM-YYYY HH:mm').format('HH:mm')} {calculateTotalHours(appointmentDetails?.start_time, appointmentDetails?.end_time)}</h3>
                            </div>


                        </div>
                        <div className={`navigation  ${fromCalendar ? "w-100 d-flex justify-content-between m-auto" : ""}  col-lg-4 offset-lg-4 my-4`}>
                            <span className={(active === "overview" ? "active me-2" : "not-active me-2")} onClick={() => { setActive("overview") }}>Overview </span>
                            <span className={(active === "documents" ? "active me-2" : "not-active me-2")} onClick={() => {
                                if (docTabData == null)
                                    initGetAppointmentDetailsForDocumentTabData({
                                        appointmentId: parseInt((slug || slugFromCalender))
                                    })
                                setActive("documents")


                            }}>Forms & documents
                                {/* {bookingDetails?.consentFormAvailable && (bookingDetails?.consent_form_status ? <img src={timeIcon2} alt='error' /> : <img src={timeIcon} alt='error' />)} */}
                            </span>
                            <span className={(active === "manage" ? "active me-2" : "not-active me-2")} onClick={() => {
                                if (manageTabData == null)
                                    manageTabDataAoiCall()
                                setActive("manage")
                            }}>Manage </span>
                        </div >

                        <div className='appointment-content-container Main-appointment-Content-Wrapper'>
                            <div className={`appointment-main-content ${fromCalendar ? "" : "col-lg-4 offset-lg-4  "}`}>
                                {
                                    active === "overview" &&
                                    <>
                                        <p>
                                            Client
                                        </p>
                                        <hr />
                                        <div className='general-details d-flex justify-content-between'>
                                            <div className='d-flex flex-column justify-content-center '>
                                                <span>{appointmentDetails?.client_fname?.charAt(0)?.toUpperCase() + appointmentDetails?.client_fname?.substring(1)} {appointmentDetails?.client_lname?.charAt(0)?.toUpperCase() + appointmentDetails?.client_lname?.substring(1)}</span>
                                                {appointmentDetails?.mobile_number ? <p className='mb-0 mt-1'>+44 (0) {appointmentDetails?.mobile_number}</p> : <p className='mb-0 mt-1'>{appointmentDetails?.client_email}</p>}
                                            </div>
                                            <div className='user-image-container d-flex'>
                                                {/* {(appointmentDetails?.client_image === "not present" || appointmentDetails?.client_image === "") ? <h2 className='m-auto'>{appointmentDetails?.client_fname[0]?.toUpperCase() + (appointmentDetails?.client_lname ? appointmentDetails?.client_lname[0]?.toUpperCase() : appointmentDetails?.client_fname[1]?.toUpperCase())}</h2> : <img src={appointmentDetails?.client_image} />} */}
                                                {(appointmentDetails?.client_image === "not present" || appointmentDetails?.client_image === '') ? <h2 className='m-auto'>{
                                                    appointmentDetails?.client_fname != "" && appointmentDetails?.client_lname != "" ? appointmentDetails?.client_fname[0]?.toUpperCase() + appointmentDetails?.client_lname[0]?.toUpperCase() :
                                                        appointmentDetails?.client_fname != "" ? appointmentDetails?.client_fname[0]?.toUpperCase() :
                                                            appointmentDetails?.client_lname[0]?.toUpperCase()
                                                }
                                                </h2> : <img src={appointmentDetails?.client_image} />}
                                            </div>
                                        </div>
                                        <div className='appointment-details-container'>
                                            <p>
                                                Appointment details
                                            </p>
                                            <hr />
                                            <div className='details-container d-flex justify-content-between mb-4'>
                                                <p className='my-0'>Date</p>
                                                {/* <span>{moment(appointmentDetails?.treatment_date, 'DD-MM-YYYY').format('Do') + " " + moment(appointmentDetails?.treatment_date, 'DD-MM-YYYY').format('MMM') + ((moment(appointmentDetails?.treatment_date, 'DD-MM-YYYY').format('MM') === "09") ? "t" : "") + " " + moment(appointmentDetails?.treatment_date, 'DD-MM-YYYY').format('YYYY')}</span> */}
                                                {/* {
                                                    <span>{moment(appointmentDetails?.treatment_date, "DD-MM-YYYY").format("ddd, DD MMM YYYY")}</span>
                                                } */}
                                                <span>
                                                    {moment(appointmentDetails?.treatment_date, 'DD-MM-YYYY')
                                                        .format('dddd, DD MMMM YYYY')}
                                                </span>

                                            </div>
                                            <div className='details-container d-flex justify-content-between mb-4'>
                                                <p className='my-0'>Time</p>
                                                <span>{moment(appointmentDetails?.start_time, 'DD-MM-YYYY HH:mm').format('HH:mm')} – {moment(appointmentDetails?.end_time, 'DD-MM-YYYY HH:mm').format('HH:mm')}</span>
                                            </div>
                                            <div className='details-container d-flex justify-content-between mb-4'>
                                                <p className='my-0'>Location</p>
                                                <span>{appointmentDetails?.location_name ? appointmentDetails?.location_name : "N/A"}</span>
                                            </div>
                                            {/* <div className='details-container d-flex justify-content-between  mb-4'>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <p className='my-0'>Location</p>
                                                    </div>
                                                    <div className="col-md-8 p-0 d-flex justify-content-end">
                                                        <span>{appointmentDetails?.location_name}</span>
                                                    </div>
                                                </div>

                                            </div> */}
                                            <div className='details-container d-flex justify-content-between'>
                                                <p className='my-0'>Team member</p>
                                                <span>
                                                    {
                                                        // appointmentDetails?.practitioner_name?.split(' ')[0].charAt(0)?.toUpperCase() + appointmentDetails?.practitioner_name?.split(' ')[0]?.substring(1) + ' ' + (appointmentDetails?.practitioner_name?.replace(/\s\s+/g, ' ')?.split(' ')[1] ? (appointmentDetails?.practitioner_name?.replace(/\s\s+/g, ' ')?.split(' ')[1].charAt(0)?.toUpperCase() + appointmentDetails?.practitioner_name?.replace(/\s\s+/g, ' ')?.split(' ')[1]?.substring(1)) : '')

                                                        appointmentDetails?.practitioner_name ? upperCaseWordsOfSentent(appointmentDetails?.practitioner_name) : "N/A"
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className='treatment-details'>
                                            <p> Treatments </p>
                                            <hr />
                                            <div className='treatment-content mt-2'>
                                                {
                                                    appointmentDetails?.treatments_list?.length > 0 ?

                                                        appointmentDetails?.treatments_list?.map((item) => {
                                                            treatmentTotal = treatmentTotal + item?.price;
                                                            return (
                                                                <div className='d-flex justify-content-between'>
                                                                    <p>
                                                                        {
                                                                            // item?.name.split(' ')[0].charAt(0)?.toUpperCase() + item?.name.split(' ')[0]?.substring(1) + ' ' + (item?.name?.replace(/\s\s+/g, ' ')?.split(' ')[1] ? (item?.name?.replace(/\s\s+/g, ' ')?.split(' ')[1].charAt(0)?.toUpperCase() + item?.name?.replace(/\s\s+/g, ' ')?.split(' ')[1]?.substring(1)) : '')

                                                                            upperCaseWordsOfSentent(item?.name)
                                                                        }
                                                                    </p>
                                                                    <p>{pounds.format(item?.price?.toFixed(2))}</p>
                                                                </div>
                                                            )
                                                        }
                                                        )
                                                        :
                                                        <div className='d-flex justify-content-start'>
                                                            <p>No Treatments</p>
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                        <div className='booking-details'>
                                            <p> Booking process </p>
                                            <hr />
                                            <div className='booking-content'>
                                                <div className='d-flex align-items-center booking-gap mb-4'>
                                                    <p className='my-0 appointment-dates me-4'>{bookingDetails?.appointment_creation_date && moment(bookingDetails?.appointment_creation_date, 'YYYY-MM-DD').format("DD MMM")}</p>
                                                    <div className='dateWrapper'>
                                                        <div class={`vl-base `} style={{ 'borderLeftColor': 'green' }}></div>
                                                        <p className='my-0 ms-4'>Booked by {`${appointmentDetails?.booked_by_fname?.charAt(0)?.toUpperCase() + appointmentDetails?.booked_by_fname?.substring(1) + " " + appointmentDetails?.booked_by_lname?.charAt(0)?.toUpperCase() + appointmentDetails?.booked_by_lname?.substring(1)}`}</p>
                                                    </div>
                                                </div>

                                                {
                                                    bookingDetails?.displayPaymentStatus ? bookingDetails?.payment_status_data?.map((item, index) =>
                                                        <div className='d-flex align-items-center booking-gap mb-4'>
                                                            <p className='my-0 appointment-dates me-4'>{item?.date && moment(item?.date, 'YYYY-MM-DD').format("DD MMM")}</p>
                                                            <div className='dateWrapper'>
                                                                <div class={`vl-base `} style={{ 'borderLeftColor': item?.color }}></div>
                                                                <p className='my-0 ms-4' style={{ cursor: "pointer" }}>{item?.type_label}: {item?.label}</p>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }

                                                {
                                                    bookingDetails?.consentFormAvailable &&
                                                    <div className='d-flex align-items-center booking-gap mb-4'>
                                                        <p className='my-0 appointment-dates me-4'>{bookingDetails?.consent_form_date && moment(bookingDetails?.consent_form_date, 'YYYY-MM-DD').format("DD MMM")} </p>
                                                        {/* <div class={docTabData?.consentFormStatus ? "vl" : 'vl-1'}></div> */}
                                                        <div className='dateWrapper'>
                                                            <div class={`vl-base `} style={{ 'borderLeftColor': bookingDetails?.consent_form_color }}></div>
                                                            {bookingDetails?.consent_form_status ?
                                                                <p className='my-0 ms-4'>Consent status: Completed</p> :
                                                                <p className='my-0 ms-4'>Consent status: Pending</p>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {/* {bookingDetails?.payment_deposit_status === "pending" ? */}
                                                <div className='d-flex align-items-center booking-gap'>
                                                    <p className='my-0 appointment-dates me-4'>{bookingDetails?.fullyPaidOnDate && moment(bookingDetails?.fullyPaidOnDate, 'DD-MM-YYYY').format("DD MMM")} </p>
                                                    <div className='dateWrapper'>
                                                        <div class={'vl-2'} style={bookingDetails?.isFullyPaidStatus ? { 'borderLeftColor': 'rgb(31, 131, 66)' } : {}}></div>
                                                        <div className='d-flex flex-column ms-4'>
                                                            <p className='my-0' style={{ cursor: "pointer" }}>Balance due: {`${bookingDetails?.isFullyPaidStatus ? "Fully paid" : "Pending payment"}`}</p>
                                                            <span>The client has an outstanding balance of {bookingDetails?.isDepositPaidStatus || bookingDetails?.isFullyPaidStatus ? pounds.format(appointmentDetails?.due_amount?.toFixed(2)) : pounds.format(appointmentDetails?.due_amount + appointmentDetails?.deposit_amount)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* :
                                            <>
                                            </> */}
                                                {/* } */}
                                            </div>
                                        </div>

                                        <div className='payment-summary'>
                                            <p> Payment summary </p>
                                            <hr />
                                            <div className='payment-details'>
                                                <div className='d-flex justify-content-between mb-3'>
                                                    <span>Subtotal</span>
                                                    <span>{pounds.format(appointmentDetails?.sub_total?.toFixed(2))}</span>
                                                </div>
                                                {/* <div className='d-flex justify-content-between mb-4'>
                                            <span>{appointmentDetails?.treatments_list?.length} treatment{appointmentDetails?.treatments_list?.length > 1 ? "s":""}</span>
                                            <span>£{treatmentTotal.toFixed(2)}</span>
                                        </div> */}
                                                <div className='d-flex justify-content-between mb-3'>
                                                    <span>Discount</span>
                                                    <span>{pounds.format(appointmentDetails?.discount?.toFixed(2))}</span>
                                                </div>
                                                <div className='d-flex justify-content-between mb-3'>
                                                    <span>Tip</span>
                                                    <span>{pounds.format(appointmentDetails?.tip)}</span>
                                                </div>
                                                <div className='d-flex justify-content-between mb-3'>
                                                    <span>Total</span>
                                                    <span>{pounds.format(appointmentDetails?.order_total?.toFixed(2))}</span>
                                                </div>
                                            </div>
                                            <hr />
                                            {/* <div className='d-flex justify-content-between'>
                                        <span className='fw-bold'>Order total</span>
                                        <span>£{appointmentDetails?.order_total?.toFixed(2)}</span>
                                    </div> */}
                                            <div className='d-flex justify-content-between'>
                                                <span className='fw-bold'>
                                                    {
                                                        // ['paid'].indexOf(appointmentDetails?.deposit_request_status) != -1 ? "Deposit paid" : "Deposit due"
                                                        bookingDetails?.isDepositPaidStatus || bookingDetails?.isFullyPaidStatus ? "Deposit paid" : "Deposit due"
                                                    }
                                                </span>
                                                <span>{appointmentDetails?.deposit_amount ? pounds.format(appointmentDetails?.deposit_amount?.toFixed(2)) : "£0.00"}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <span className='fw-bold' style={bookingDetails?.isFullyPaidStatus ? { color: "rgb(31, 131, 66)" } : { color: "#C83A25" }}>
                                                    Balance due
                                                </span>
                                                <span>{bookingDetails?.isDepositPaidStatus || bookingDetails?.isFullyPaidStatus ? pounds.format(appointmentDetails?.due_amount?.toFixed(2)) : pounds.format(appointmentDetails?.due_amount + appointmentDetails?.deposit_amount)}</span>
                                                {/* <span>{appointmentDetails?.due_amount ? bookingDetails?.isDepositPaidStatus ? pounds.format(appointmentDetails?.due_amount?.toFixed(2)) : pounds.format(appointmentDetails?.sub_total.toFixed(2)) : "£0.00"}</span> */}
                                            </div>
                                        </div>
                                        {/* HIDE FOR NOW */}
                                        {/* <div className="mark-noshow-details-button d-flex">
                                            <button
                                                onClick={() => {
                                                    // setShowConsentPopup(true);
                                                    // setConfirmationPopupShow(true);
                                                }}
                                            >
                                                Mark as no-show
                                            </button>

                                        </div> */}
                                        <div className='cancel-appointment-button mt-0  d-flex'>
                                            <button onClick={() => {
                                                setShowConsentPopup(true);
                                                setConfirmationPopupShow(true);
                                                // 
                                            }}>
                                                Cancel appointment
                                            </button>

                                        </div>
                                    </>

                                }

                                {
                                    active === "documents" &&
                                    <>
                                        <div className='compliance-essentials'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <p className='my-auto'> Forms </p>
                                                <span onClick={() => { setCompliancePopupShow(true); setShowConsentPopup(true) }} style={{ cursor: "pointer" }} className='fw-bold'><u>Learn more</u></span>
                                            </div>
                                            <hr />
                                            <div className='compliance-content'>

                                                {/* 
                                                <ToastContainer
                                                    position="top-right"
                                                    autoClose={1500}
                                                    hideProgressBar={false}
                                                    newestOnTop={false}
                                                    closeOnClick
                                                    rtl={false}
                                                    draggable
                                                    pauseOnHover
                                                    theme="colored"
                                                    className={'for-custom-zindex'}
                                                /> */}
                                                <div className='d-flex justify-content-between align-items-center my-4'
                                                    style={{ cursor: "pointer" }}
                                                    // onClick={() => {
                                                    //     window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + appointmentDetails.consent_form_id + `?scroll-to=consent-form-redirect&redirect-url=${encodedRedirectUrl}`
                                                    // }}
                                                    onClick={() => {
                                                        window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${appointmentDetails?.client_id}?active-tab=medical_form&redirect-url=${encodedRedirectUrl}`
                                                        // window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/filled-consent-form/edit/${props.consent_form_id}?scroll-to=consent-form-redirect&redirect-url=${redirectURL}`;
                                                    }}
                                                >
                                                    <div className='essentials-content d-flex gap-2'>
                                                        <div className='compliance-img-container1'>
                                                            <img className='main-img' src={medicalForm} alt="" />
                                                            {/* <MedicalFormIcon width={42} /> */}
                                                            <img className='time-icon' src={docTabData?.medicalStatus ? timeIcon2 : timeIcon} />
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <span className='my-auto'>Medical form</span>
                                                            <span className={docTabData?.medicalStatus ? 'my-auto added' : "require-action my-auto"}>{docTabData?.medicalStatus ? "Completed" : "Requires action"}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <img className='my-auto' src={rightArrow} alt="" />
                                                    </div>
                                                </div>
                                                {
                                                    bookingDetails?.consentFormAvailable ?
                                                        <div className='d-flex justify-content-between align-items-center'
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setShowConsentPopup(true);
                                                                setPopupType("consent")
                                                            }}
                                                        >
                                                            <div className='essentials-content d-flex gap-2' >
                                                                <div className='compliance-img-container'>
                                                                    <img className='main-img' src={consentFormIcon} alt="" />
                                                                    {/* <ConcentFormIcon width={42} /> */}
                                                                    <img className='time-icon' src={docTabData?.consentFormStatus ? timeIcon2 : timeIcon} />
                                                                </div>

                                                                <div className='d-flex flex-column'>
                                                                    <span className='my-auto'>Consent form</span>
                                                                    <span className={docTabData?.consentFormStatus ? 'my-auto added' : "require-action my-auto"}>{docTabData?.consentFormStatus ? "Completed" : "Requires action"}</span>
                                                                </div>
                                                            </div>
                                                            <div >
                                                                <img className='my-auto' src={rightArrow} alt="" />
                                                            </div>
                                                        </div> : null
                                                }

                                                <div className='d-flex justify-content-between align-items-center my-4'
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setOpenAfterCareModal(true)
                                                        // window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + appointmentDetails.consent_form_id + `?scroll-to=aftercare-form-redirect&redirect-url=${encodedRedirectUrl}`
                                                    }}
                                                >
                                                    <div className='essentials-content d-flex gap-2'>
                                                        <div className='compliance-img-container3'>
                                                            <img className='main-img' src={aftercareFormIcon} alt="" />
                                                            {/* <AfterCareIcon width={42} /> */}
                                                            <img className='time-icon' src={docTabData?.aftercareStatus || aftercareDocumentStatus ? timeIcon2 : timeIcon} />
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <span className='my-auto'>Aftercare form</span>
                                                            <span className={docTabData?.aftercareStatus || aftercareDocumentStatus ? 'my-auto added' : "require-action my-auto"}>{docTabData?.aftercareStatus || aftercareDocumentStatus ? "Added" : "Requires action"}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <img className='my-auto' src={rightArrow} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='treatments-actions'>
                                            <p>Compliance</p>
                                            <hr />
                                            <div className='treatment-child'>
                                                <div className='d-flex justify-content-between align-items-center mb-4'
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        // window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + appointmentDetails.consent_form_id + `?scroll-to=notes-redirect&redirect-url=${encodedRedirectUrl}`
                                                        setIsNoteForm(true)
                                                    }}
                                                >
                                                    <div className='treatment-actions-content d-flex gap-2'>
                                                        <div className='compliance-img-container7'>
                                                            <img className='main-img' src={notes} alt="" />
                                                            <img className='time-icon' src={!docTabData?.notesStatus ? timeIcon1 : timeIcon2} />
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <span className='my-auto'>Notes</span>
                                                            <span className={docTabData?.notesStatus ? 'my-auto added' : "require-action my-auto"}>{docTabData?.notesStatus ? "Added" : "Requires action"}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <img className='my-auto' src={rightArrow} alt="" />
                                                    </div>
                                                </div>

                                                <div className='d-flex justify-content-between align-items-center my-4'
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setFacialMappingModal(true)
                                                        // window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + appointmentDetails.consent_form_id + `?scroll-to=facial-mapping-redirect&redirect-url=${encodedRedirectUrl}`
                                                    }}
                                                >
                                                    <div className='treatment-actions-content d-flex gap-2'>
                                                        <div className='compliance-img-container5'>
                                                            <img className='main-img' src={facial} alt="" />
                                                            <img className='time-icon' src={(docTabData?.facialMappingStatus || facialmappingStatus) ? timeIcon2 : timeIcon1} />
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <span className='my-auto'>Face&nbsp;mapping</span>
                                                            <span className={(docTabData?.facialMappingStatus || facialmappingStatus) ? 'my-auto added' : "require-action my-auto"}>{(docTabData?.facialMappingStatus || facialmappingStatus) ? "Added" : "Requires action"}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <img className='my-auto' src={rightArrow} alt="" />
                                                    </div>
                                                </div>

                                                <div className='d-flex justify-content-between align-items-center mb-4'
                                                    style={{ cursor: "pointer" }}
                                                    // onClick={() => {
                                                    //     window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + appointmentDetails.consent_form_id + `?scroll-to=photossection-redirect&redirect-url=${encodedRedirectUrl}`
                                                    // }}
                                                    onClick={() => {
                                                        dispatch({
                                                            type: CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT,
                                                            payload: null
                                                        })
                                                        navigate(appointmentOverviewBeforeAndAfterPhotosRoute(appointmentDetails?.consent_form_id), { state: { AppointmentDate: appointmentDetails?.booking_id, fromCalendar: fromCalendar } })
                                                    }}
                                                >
                                                    <div className='treatment-actions-content d-flex gap-2'>
                                                        <div className='compliance-img-container6'>
                                                            <img className='main-img' src={photo} alt="" />
                                                            <img className='time-icon' src={!docTabData?.photosStatus ? timeIcon1 : timeIcon2} />
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <span className='my-auto'>Photos&nbsp;({appointmentDetails?.photos_count})</span>
                                                            <span className={docTabData?.photosStatus ? 'my-auto added' : "require-action my-auto"}>{docTabData?.photosStatus ? "Added" : "Requires action"}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <img className='my-auto' src={rightArrow} alt="" />
                                                    </div>
                                                </div>

                                                <div className='d-flex justify-content-between align-items-center my-4'
                                                    style={{ cursor: "pointer" }}
                                                    // onClick={() => {
                                                    //     // window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + appointmentDetails.consent_form_id + `?scroll-to=facial-mapping-redirect&redirect-url=${encodedRedirectUrl}`
                                                    // }}

                                                    onClick={() => {
                                                        dispatch({
                                                            type: CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT,
                                                            payload: null
                                                        })
                                                        navigate(appointmentOverviewBatchNumberRoute(appointmentDetails?.consent_form_id), { state: { AppointmentDate: appointmentDetails?.booking_id, fromCalendar: fromCalendar } })
                                                    }
                                                    }

                                                >
                                                    <div className='treatment-actions-content d-flex gap-2'>
                                                        <div className='compliance-img-container7'>
                                                            <img className='main-img' src={barcode} alt="" />
                                                            <img className='time-icon' src={docTabData?.batchStatus ? timeIcon2 : timeIcon1} />
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <span className='my-auto'>Batch numbers</span>
                                                            <span className={docTabData?.batchStatus ? 'my-auto added' : "boost-protection my-auto"}>{docTabData?.batchStatus ? "Added" : "Boost protection"}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <img className='my-auto' src={rightArrow} alt="" />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </>
                                }

                                {
                                    active === "manage" &&
                                    <>
                                        {/* This section for Manages */}
                                        {manageTabData ?
                                            <>
                                                <div className="section-container mb-5">
                                                    <h1>Manage</h1>
                                                    <hr />
                                                    <div
                                                        className="tile-sub-title-container"
                                                        onClick={() => setIsHoldDurationOpen(true)}
                                                    >
                                                        <div className="left-section">
                                                            {
                                                                parseInt(selectedTime) === 0 ? <HoldDurationInActiveIcon /> : <HoldDurationActiveIcon />
                                                            }
                                                            <div className="sub-title-section">
                                                                <h1>Hold appointment</h1>
                                                                <p>Set how long to hold the booking. </p>
                                                            </div>
                                                        </div>
                                                        <div className="right-section">
                                                            <RightArrow />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="tile-sub-title-container"
                                                        onClick={() => setIsFollowUpOpen(true)}
                                                    >
                                                        <div className="left-section">
                                                            {
                                                                parseInt(selectedFollowUpTime) === 0 ? <FollowUpInActiveIcon /> : <FollowUpActiveIcon />
                                                            }
                                                            <div className="sub-title-section">
                                                                <h1>Follow-ups</h1>
                                                                <p>Send & schedule automatic rebooking.</p>
                                                            </div>
                                                        </div>
                                                        <div className="right-section">
                                                            <RightArrow />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* This section for Business boosters */}
                                                <div className="section-container mb-5">
                                                    <h1>Business boosters</h1>
                                                    <hr />
                                                    <div className="tile-container">
                                                        <div className="left-section">
                                                            <TextReminderIcon />
                                                            <p>Text reminders </p>
                                                        </div>
                                                        <div className="right-section">
                                                            <SwitchButton
                                                                onChange={(e) => handleToggleChange("TEXTREMINDER", e)}
                                                                isChecked={toggle.textReminder}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="tile-container">
                                                        <div className="left-section">
                                                            <EmailReminderIcon />
                                                            <p>Email reminders</p>
                                                        </div>
                                                        <div className="right-section">
                                                            <SwitchButton
                                                                onChange={(e) => handleToggleChange("EMAILREMINDER", e)}
                                                                isChecked={toggle.emailReminder}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="tile-container">
                                                        <div className="left-section">
                                                            <CollectPaymentIcon />
                                                            <p className={`${(slug || slugFromCalender) && (manageTabData?.collectPayment || !manageTabData?.depositRequestable) ? "text-muted" : ""}`}>Collect payments </p>
                                                        </div>
                                                        <div className={`right-section ${(slug || slugFromCalender) && (manageTabData?.collectPayment || !manageTabData?.depositRequestable) ? "pe-none" : ""}`}>
                                                            <SwitchButton
                                                                onChange={(e) =>
                                                                    handleToggleChange("COLLECTPAYMENT", e)
                                                                }
                                                                isChecked={toggle.collectPayment}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        (stripeFinceStatusObj?.userHasDisabledFinanceOption || !(stripeFinceStatusObj?.financeRequestStatus == 2)) &&
                                                        <div className="tile-container">
                                                            <div className="left-section">
                                                                <OfferPayIcon />
                                                                <p className={`${(slug || slugFromCalender) ? "text-muted" : ""}`}>
                                                                    {/* Offer pay monthly <span>Learn</span> */}
                                                                    Offer Buy now Payl8r
                                                                </p>
                                                            </div>
                                                            <div className={`right-section ${(slug || slugFromCalender) ? "pe-none" : ""}`}>
                                                                <SwitchButton
                                                                    onChange={(e) => handleToggleChange("OFFERPAYMONTHLY", e)}
                                                                    isChecked={toggle.offerPay}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </> :
                                            <SkeletonLoader
                                                type={"appointment-manage-tab"} />}
                                    </>
                                }
                                {/* <div className='done-appointment-button'>
                                    <StepButton label={"Done"} blue={true} />
                                </div> */}
                                {
                                    showConsentPopup ? <ConsentActionPopup elementId={appointmentDetails?.booking_id} confirmationPopupShow={confirmationPopupShow} height={"280px"} setConfirmationPopupShow={setConfirmationPopupShow} setCompliancePopupShow={setCompliancePopupShow} compliancePopupShow={compliancePopupShow} consentStatus={docTabData?.consentFormStatus} bookingSlug={slug || slugFromCalender} consent_form_id={appointmentDetails?.consent_form_id} setShowConsentPopup={setShowConsentPopup} setIsAppointmentDetailsOpen={setIsAppointmentDetailsOpen} setIsCancleAppointmentResponse={setIsCancleAppointmentResponse} locationData={location} appointmentDate={moment(appointmentDetails?.treatment_date, "DD-MM-YYYY").format("YYYY-MM-DD")} /> : null
                                }
                            </div>

                        </div>



                    </div >
                    :
                    <div className='appointmentDetailsSkeletonLoader'>
                        <SkeletonLoader type={"appointment-detail"} />
                    </div>
            }

            <CustomModal className={'SucessModel'} setShowResendAlert={setShowResendAlert} modalOpen={showResendAlert} modalBody={resendFormSuccessMessage} type={"resend"} modaltitle={<div className='w-100 text-center'><p className='mt-2 mx-auto text-center'>Success</p></div>} />
            {
                // Hold Duration Modal
                <OffCanvasModal
                    isOpen={isHoldDurationOpen}
                    isBack={false}
                    onHide={() => {
                        setIsHoldDurationOpen(false)
                        setSelectedTime(manageTabData?.holdOn)
                    }}
                    className={"custom-sidebar-width-30 CustomeHoldappointmentCanvas"}
                    placement={"end"}
                    body={
                        <div className="container h-100">
                            <div className="row h-100">
                                <div className="col-md-12">
                                    <div className="hold-duration-container">
                                        <div className="hold-duration-content">
                                            <h1>Hold appointment</h1>
                                            <p className="mb-5">
                                                Choose how long to reserve an appointment slot before a
                                                client confirms. Confirmation is via a payment of deposit or treatment.
                                            </p>
                                            <div class="custom-duration-container-height">
                                                <div className='custom-duration-max-height'>

                                                    {
                                                        <>
                                                            {timeData?.map((timeSlot, index) => (
                                                                <div
                                                                    className="hold-duration-input-container"
                                                                    key={index}
                                                                >
                                                                    <CustomRadioButton
                                                                        timeSlot={true}
                                                                        name="time"
                                                                        text={timeSlot.label}
                                                                        id={timeSlot.timeSlot}
                                                                        value={timeSlot.label}
                                                                        isblacked={true}
                                                                        checked={parseInt(selectedTime) === timeSlot.timeSlot}
                                                                        onChange={(e) => setSelectedTime(e.target.id)}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </>
                                                    }

                                                    {console.log(selectedTime, "selectedTime")}
                                                </div>
                                                <div className="hold-duration-button-container pt-4">
                                                    <StepButton
                                                        label={"Done"}
                                                        blue={true}
                                                        labelClassName={"mb-0"}
                                                        className={"mt-0"}
                                                        isLoading={createHoldDurationMutationData.loading}
                                                        disabled={selectedTime === null || parseInt(selectedTime) === 0}
                                                        onClick={() => handleHoldDurationData()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                />
            }

            {
                // Client Followup Modal
                <OffCanvasModal
                    isOpen={isFollowUpOpen}
                    isBack={false}
                    onHide={() => {
                        setIsFollowUpOpen(false)
                        setOtherText(manageTabData?.followup)
                        setFollowUpType(manageTabData?.followupType)
                        if (manageTabData?.followupType !== 0) {
                            setSelectedFollowUpTime("Other")

                            switch (manageTabData?.followupType) {
                                case 1:
                                    return setButtonActive("days");
                                case 2:
                                    return setButtonActive("weeks")
                                case 3:
                                    return setButtonActive("months")
                                default:
                                    return setButtonActive("days")
                            }
                        } else {
                            setSelectedFollowUpTime(manageTabData?.followup)
                        }
                    }}
                    className={"custom-sidebar-width-30 CustomeHoldappointmentCanvas"}
                    placement={"end"}
                    body={
                        <div className="container h-100">
                            <div className="row h-100">
                                <div className="col-md-12">
                                    <div className="hold-duration-container">
                                        <div className="hold-duration-content">
                                            <div className="hold-duration-header">
                                                <h1>Client follow-ups</h1>
                                                <p className="mb-3">
                                                    Ensure consistent care by scheduling automatic check-ins and allow client to re-bookings.
                                                </p>
                                            </div>
                                            <div className='inputContainerWrapper'>

                                                {followUpData?.map((timeSlot, index) => (
                                                    <div className="hold-duration-input-container" key={index}>
                                                        <CustomRadioButton
                                                            timeSlot={true}
                                                            name="time"
                                                            text={timeSlot.label}
                                                            id={timeSlot.id}
                                                            isblacked={true}
                                                            value={timeSlot.timeSlot}
                                                            checked={selectedFollowUpTime == timeSlot.timeSlot}
                                                            onChange={(e) => {
                                                                if (e.target.value == "Other") {
                                                                    setFollowUpType(1)
                                                                    setButtonActive("days")
                                                                } else {
                                                                    setFollowUpType(0)
                                                                }
                                                                setSelectedFollowUpTime(e.target.value)
                                                            }}
                                                        />

                                                    </div>
                                                ))}
                                                {console.log(followUpType, "followUpType")}
                                                {(selectedFollowUpTime === "Other" || selectedFollowUpTime == 0) &&
                                                    <div className="other-textfield">
                                                        <TextInput placeholder="e.g 3" value={otherText} onChange={(e) => {
                                                            if (otherText == null || otherText?.toString()?.length < 4)
                                                                setOtherText(e.target.value)
                                                        }}
                                                        />
                                                        <div className="other-buttons">
                                                            <div className={buttonActive === "days" ? "enable-button" : "disable-button"}>
                                                                <button onClick={() => handleToggleButton("days")}>Days</button>
                                                            </div>
                                                            <div className={buttonActive === "weeks" ? "enable-button" : "disable-button"}>
                                                                <button onClick={() => handleToggleButton("weeks")}>Weeks</button>
                                                            </div>
                                                            <div className={buttonActive === "months" ? "enable-button" : "disable-button"}>
                                                                <button onClick={() => handleToggleButton("months")}>Months</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                }
                                            </div>

                                            <div className="hold-duration-button-container">
                                                <StepButton
                                                    label={"Done"}
                                                    blue={true}
                                                    disabled={selectedFollowUpTime === null || selectedFollowUpTime == 0 || (selectedFollowUpTime === "Other" && (otherText === null || otherText === "" || otherText == 0))}
                                                    isLoading={createFollowUpMutationData?.loading}
                                                    onClick={() => handleFollowUpData()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                />
            }

            {/* SMS SUBSCRIPTION MODAL */}
            <CustomModal
                modalOpen={isSmsSubscriptionOpen}
                setModalOpen={setIsSmsSubscriptionOpen}
                type={"common-width"}
                modaltitleCSS={"discount-title"}
                modalBody={
                    <SMSSucriptionModal howItWorksData={
                        {
                            clientName: appointmentDetails?.client_fname + appointmentDetails?.client_lname ? appointmentDetails?.client_fname + appointmentDetails?.client_lname : "John Doe",
                            date: moment(appointmentDetails?.treatment_date, "dddd, Do MMMM").format("DD-MM-YYYY"),
                            time: appointmentDetails?.start_time ? appointmentDetails?.start_time : "[Time]"
                        }}
                        setIsSmsSubscriptionOpen={setIsSmsSubscriptionOpen}
                    />
                }
            />

            {/* OFFER PAY MONTHLY LEARN MODAL */}
            <CustomModal
                modalOpen={isOfferPayMonthlyLearnOpen}
                setModalOpen={setIsOfferPayMonthlyLearnOpen}
                className={'w-50'}
                type={"no-header"}
                modaltitleCSS={"discount-title"}
                modalBody={
                    <>
                        <div className="offer-pay-monthly-learn-modal-main-div">
                            <div className="info">
                                <h1>
                                    i
                                </h1>
                            </div>
                            <p>
                                Thank you for your interest in offering the 'Buy Now, Pay Later'. Yours is currently not active. However your request is currently under review by our team. We will reach out to you as soon as the decision is made, so you can begin monetising this amazing service.
                            </p>
                            <StepButton label={"OK"} blue={true} className={"w-50 m-auto"}
                                onClick={() => setIsOfferPayMonthlyLearnOpen(false)} />
                        </div>
                    </>
                }
            />

            {/* EDIT DEPOSIT */}
            <CustomModal
                modalOpen={editDepositPriceModal}
                // modalOpen={true}
                // setIsOpenMobileCalendar={handleEditPriceModalClose}
                setModalOpen={setEditDeposiPriceModal}
                type={"common-width"}
                modaltitle={"Edit Deposit"}
                modaltitleCSS={"discount-title"}
                modalBody={
                    <div className="edit-price-container">
                        <div className="edit-price-content">
                            <p>Your client <b>{appointmentDetails?.client_fname} {appointmentDetails?.client_lname}</b> has an unpaid deposit for this appointment.</p>
                            <p>
                                Please enter the deposit amount below. We'll send a gentle reminder to your client and notify you once it's paid.
                            </p>
                        </div>
                        <div className="edit-price-input-container">
                            <img src={Suffix} alt="suffix" />
                            <input type="text" placeholder="0.00" onFocus={() => {
                                if (depositPrice === 0) {
                                    setDepositPrice("")
                                }
                            }} onChange={(e) => {

                                if (!isNumber(e.target.value) || e.target.value == 0) {
                                    console.log(e.target.value, "e.target.value")
                                    setIsDepositError("Invalid value")
                                }
                                else if (parseFloat(e.target.value) <= parseFloat(appointmentDetails?.due_amount)) {
                                    setIsDepositError("")
                                } else {
                                    setIsDepositError("Deposit amount can not be greater than total amount")
                                }
                                setDepositPrice(e.target.value)
                            }

                            } value={depositPrice} />
                        </div>
                        <span className='error-class'>
                            {isDepositError && isDepositError}
                        </span>
                        <div className="mt-4">
                            <StepButton label={"Save"} disabled={isDepositError !== "" || depositPrice == "" || depositPrice == 0}
                                blue={true} loading={requestDepositForAppointmentQueryData?.loading} onClick={() => {
                                    handleEdirDepositPriceForManageTab()
                                    // if (depositPrice == 0) {
                                    //     setToggles({ ...toggle, collectPayment: false })
                                    // }
                                    // setDeposit(parseFloat(depositPrice));
                                    // setIsEditPriceOpen(false)
                                }} />
                        </div>
                    </div>
                }
            />

            {/* notes model */}
            <CustomModal
                modaltitle={"Add a note"}
                modaltitleCSS={"discount-title"}
                type={"common-width"}
                modalBody={<AddNotes
                    appointmentId={appointmentDetails?.booking_id}
                    notes={appointmentNotes} setIsNoteAdded={setIsNoteForm} setIsNoteModal={setIsNoteForm} setNotes={setAppointmentNotes} isClient={false} />}
                modalOpen={isNoteForm}
                className={'CustomeAddNoteModel'}
                setModalOpen={() => setIsNoteForm(false)}
                setIsOpenMobileCalendar={() => setIsNoteForm(false)}
            />

            {/* after care form */}
            {
                //Form consent or medical List Modal
                <OffCanvasModal
                    isOpen={openAfterCareModal}
                    className={"custom-sidebar-width-30 p-0 CustomeAfterCareDataCanvas"}
                    placement={"end"}
                    isOffCanvasHeader={false}
                    onHide={() => setOpenAfterCareModal(false)}
                    body={
                        <AfterCareList selectedAfterCareData={selectedAfterCareData} setSelectedAfterCareData={setSelectedAfterCareData} setIsClose={setOpenAfterCareModal} onCallbackHandler={saveAfterCareDocuments} />
                    }
                />

            }

            {/* FACIAL MAPPING MODAL */}
            <CustomModal
                modalOpen={facialMappingModal}
                // modalOpen={true}
                setModalOpen={setFacialMappingModal}
                isManuallyClose={true}
                modalCloseCallback={() => {
                    if (step == 0 || step == 3)
                        setFacialMappingModal(false)
                    else
                        setStep(0)
                }}
                className={'CustomeFacialMappingModel'}
                modaltitle={"Facial Mapping"}
                modaltitleCSS={"discount-title"}
                modalBody={<FacialMappingModalContent
                    appointmentId={appointmentDetails?.booking_id}
                    setFacialMappingModal={setFacialMappingModal}
                    setFacialMappingStatus={setFacialMappingStatus}
                    step={step}
                    setStep={setStep}
                />}

            />

            {/* STRIPE CONNECTION MODAL */}
            <CustomModal
                modalOpen={stripeConnectionModalState}
                setModalOpen={setstripeConnectionModalState}
                type={"common-width"}
                className={'customeAvailableModel'}
                modalBody={
                    <>
                        <div className="share-booking-link-all-clients-confimration">
                            <p>
                                Please connect your stripe in order to enable the deposit feature.
                            </p>
                            <div className="btnWrapper">
                                <StepButton red={true} label={"Cancel"} onClick={() => {
                                    setstripeConnectionModalState(false)
                                }} />
                                <StepButton label={"Okay"}
                                    isLoading={stripeOnboardingQueryData?.loading}
                                    onClick={() => {
                                        //API CALL

                                        initStripeOnboardingData({},
                                            (response) => {
                                                window.location.href = response?.data?.account_link
                                            },
                                            (error) => {
                                                showToast(error.message, "", false, undefined, undefined, { toastId: 'treatment-price-select-error-toast' })
                                                setstripeConnectionModalState(false)
                                            })
                                    }} blue={true} />
                            </div>
                        </div>

                    </>
                }
            />

            {/* STRIPE CONNECTION MODAL */}
            <CustomModal
                modalOpen={stripeConnectionModalState}
                setModalOpen={setstripeConnectionModalState}
                type={"common-width"}
                className={'customeAvailableModel'}
                modalBody={
                    <>
                        <div className="share-booking-link-all-clients-confimration">
                            <p>
                                Please connect your stripe in order to enable the deposit feature.
                            </p>
                            <div className="btnWrapper">
                                <StepButton red={true} label={"Cancel"} onClick={() => {
                                    setstripeConnectionModalState(false)
                                }} />
                                <StepButton label={"Okay"}
                                    isLoading={stripeOnboardingQueryData?.loading}
                                    onClick={() => {
                                        //API CALL

                                        initStripeOnboardingData({},
                                            (response) => {
                                                window.location.href = response?.data?.account_link
                                            },
                                            (error) => {
                                                showToast(error.message, "", false, undefined, undefined, { toastId: 'treatment-price-select-error-toast' })
                                                setstripeConnectionModalState(false)
                                            })
                                    }} blue={true} />
                            </div>
                        </div>

                    </>
                }
            />
        </div>
    )
}

export default Appointment
