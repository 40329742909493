import {
    CLEAR_FORWARD_CONSENT_FORM_BOOKING_OVERVIEW,
    FORWARDED_CONSENT_FORM_CLIENT_STEP_COMPLETED,
    FORWARDED_CONSENT_FORM_CONSENT_STEP_COMPLETED,
    FORWARDED_CONSENT_FORM_PAYMENT_STEP_COMPLETED,
    GET_FORWARD_CONSENT_FORM_BOOKING_OVERVIEW,
} from '../../constants/forward-consent-form/ForwardConsentFormConst';

const initialState = {
    appointmentDetails: [],
    clientDetailsData: [],
    paymentSummary: [],
    treatmentDetails: [],
    paymentStatusData: [],
    selectedPaymentOptionData: null,
    isConsentFormDone: null,
    isConsentFormPaymentDone: null,
    reviewAdded: null,
    isClientDone: null,
    fullName: null,

    notes: null,
    clientSignature: null,
    userTotalReviews: 0,
    averageRating: 0,

    userFirstName: '',
    userLastName: '',
    userProfile: '',
    userId:0
};

export const forwardConsentFormOverviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FORWARD_CONSENT_FORM_BOOKING_OVERVIEW:
            return {
                ...state,
                appointmentDetails: action.payload?.appointmentDetails,
                clientDetailsData: action.payload?.clientDetails,
                paymentSummary: action.payload?.paymentSummary,
                treatmentDetails: action.payload?.treatmentDetails,
                paymentStatusData: action.payload?.payment_data,
                selectedPaymentOptionData: action.payload?.selectedPayment,
                isConsentFormDone: action.payload?.isConsentFormDone,
                isConsentFormPaymentDone: action.payload?.isConsentFormPaymentDone,
                reviewAdded: action.payload?.reviewAdded,
                isClientDone: action.payload?.isClientTrue?.isClient,
                fullName: `${action.payload?.userFristName} ${action.payload?.userLastName}`,

                userFirstName: action.payload?.userFristName,
                userLastName: action.payload?.userLastName,
                userProfile: action.payload?.userProfile,

                notes: action?.payload?.notes,
                clientSignature: action?.payload?.clientSignature,

                userTotalReviews: action?.payload?.userTotalReviews,
                averageRating: action?.payload?.averageRating,
                userId:action?.payload?.userId
            };
        case CLEAR_FORWARD_CONSENT_FORM_BOOKING_OVERVIEW:
            return {
                ...state,
                appointmentDetails: [],
                clientDetailsData: [],
                paymentSummary: [],
                treatmentDetails: [],
                paymentStatusData: [],
                selectedPaymentOptionData: null,
                isConsentFormDone: null,
                isConsentFormPaymentDone: null,
                reviewAdded: null,
                isClientDone: null,

                notes: null,
                clientSignature: null,

                userTotalReviews: 0,
                averageRating: 0,
            };

        case FORWARDED_CONSENT_FORM_CLIENT_STEP_COMPLETED:
            return {
                ...state,
                isClientDone: true,
            };

        case FORWARDED_CONSENT_FORM_CONSENT_STEP_COMPLETED:
            return {
                ...state,
                isConsentFormDone: true,
            };
        
        case FORWARDED_CONSENT_FORM_PAYMENT_STEP_COMPLETED:
            return {
                ...state,
                isConsentFormPaymentDone: true,
            };

        default:
            return state;
    }
};
