import { STORE_USER_EMAIL,STORE_VERIFICATION_CODE } from "../../constants/payLater/payLaterConts"



const initialState = {
    userEmailForFinance: null,
    userVerificationCode:null
}
export const financeLoanRequestEmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_USER_EMAIL:
            return {
                ...state,
                userEmailForFinance: action.payload,
            }
      
        default:
            return state
    }
}  

export const financeLoanRequestVerificationCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_VERIFICATION_CODE:
            return {
                ...state,
                userVerificationCode: action.payload,
            }
      
        default:
            return state
    }
}  