import React, { useEffect, useState,useRef } from 'react'
import "./QuestionList.scss"
import CustomRadioButton from '../../../../common/CustomRadioButton'
import CustomCheckbox from '../../../../custom-components/custom-checkbox/CustomCheckbox';
import { capitalizeFirstLetter } from '../../../../../utils/initials';

const QuestionsList = ({ setshouldScroll,label, onChange, selectedOption, id, answerErrorMessage, commentErrorMessage, question, scrollerror }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(selectedOption?.answer || "");
  const [comment, setComment] = useState(selectedOption?.comment || ""); // State to hold textarea value
  const scrollRef=useRef(null);
  useEffect(() => {
    // Update the comment state when the selectedOption prop changes
    setSelectedAnswer(selectedOption?.answer || "");
    setComment(selectedOption?.comment || "");
  }, [selectedOption]);

  // const handleOptionChange = (option) => {
  //   // setSelectedAnswer(option);
  //   // onChange(option, comment, label); // Pass option, comment, and label to parent component

  //   let newSelectedAnswer;
  //   if (question?.multiple) {
  //     // Handle multiple options
  //     if (selectedAnswer.includes(option)) {
  //         // If the option is already selected, remove it
  //         newSelectedAnswer = selectedAnswer.filter(item => item !== option);
  //     } else {
  //         // If the option is not selected, add it
  //         newSelectedAnswer = [...selectedAnswer, option];
  //     }
  //     setSelectedAnswer(newSelectedAnswer);
  //     onChange(newSelectedAnswer.join(", "), comment, label); // Pass the comma-separated string, comment, and label to the parent component
  //   } else {
  //     // Handle single option
  //     setSelectedAnswer(option);
  //     onChange(option, comment, label); // Pass option, comment, and label to the parent component
  //   }
  // };

  const handleOptionChange = (option) => {
    let newSelectedAnswer;
    // if (question?.multiple) {
    //   // Handle multiple options
    //   if (selectedAnswer.includes(option)) {
    //     // If the option is already selected, remove it
    //     newSelectedAnswer = selectedAnswer.replace(`${option}, `, '').replace(`, ${option}`, '');
    //   } else {
    //     // If the option is not selected, add it
    //     newSelectedAnswer = selectedAnswer ? `${selectedAnswer}, ${option}` : option;
    //   }
    //   setSelectedAnswer(newSelectedAnswer);
    //   onChange(newSelectedAnswer, comment, label); // Pass the updated string, comment, and label to the parent component
    // } else {
      // Handle single option
     setshouldScroll(false)
      setSelectedAnswer(option);
      onChange(option, comment, label); // Pass option, comment, and label to the parent component
    // }
  };

  const handleTextareaChange = (event) => {
    setshouldScroll(false)
    setComment(event.target.value); // Update the comment state when textarea changes
    onChange(selectedAnswer, event.target.value, label);
    // onChange(selectedOption.answer, event.target.value, label);
  };
 


 

  return (
    <div className='questions-list-section'>

      <div className='question-container'>

        <div className='row'>
          <div className='col-md-12'>
            <div className='question'>
              <h3 id={scrollerror}> {id}.&nbsp;{label}</h3>
              {/* <div className='row firstRow'>
                <div className='col-md-6'>
                  <CustomRadioButton text={"Yes"} className={"question-radio-btn"} isblacked={true} onChange={() => handleOptionChange("Yes")} id={`Yes-${id}`} name={`question-${id}`} checked={selectedAnswer === "Yes"} preselect={true} />
                  {answerErrorMessage && (
                    <div className='hair-product-error-message'>{answerErrorMessage}</div>
                  )}
                </div>
                <div className='col-md-6'>
                  <CustomRadioButton text={"No"} className={"question-radio-btn"} isblacked={true} onChange={() => handleOptionChange("No")} id={`No-${id}`} name={`question-${id}`} checked={selectedAnswer === "No"} preselect={true} />
                </div>
              </div> */}

              <div className='row'>
                <div className='col-md-12'>
                  {answerErrorMessage && (
                    <div className='hair-product-error-message mt-0'>{answerErrorMessage}</div>
                  )}
                  {question?.multiple ? ( // Render checkbox list if multiple is true
                    <div className='list-options-main-container'>
                      {question?.options?.map((option, index) => (
                        <div className="list-options-container cursor-pointer " id="scrollableDiv" key={index}
                          // onClick={() => handleOptionChange(option)}
                        >
                          {/* <p>{capitalizeFirstLetter(option)}</p> */}
                          <p>{`- ${capitalizeFirstLetter(option)}`}</p>

                          {/* <CustomCheckbox
                            key={index}
                            className="mt-2"
                            id={index} // Add index to make IDs unique
                            name={option} // Add index to make names unique
                            checked={selectedAnswer.includes(option)}
                            // onchange={() => handleOptionChange(option)}
                            isCircle={false}
                          /> */}
                        </div>

                      ))}
                    </div>
                  ) : (
                    <div>
                      {question?.options?.length > 0 && ( // Render radio buttons if options are available
                        question?.options?.map((option, index) => (
                          <CustomRadioButton
                            key={index}
                            // text={capitalizeFirstLetter(option)}
                            text={`${String.fromCharCode(65 + index)}. ${capitalizeFirstLetter(option)}`}
                            className={"question-radio-btn"}
                            isblacked={true}
                            onChange={() => handleOptionChange(option)}
                            id={`${option}-${id}`} // Unique ID for each radio button
                            name={`question-${id}`} // Unique name for radio button group
                            checked={selectedAnswer === option}
                            preselect={true}
                          />
                        ))
                      )}
                    </div>
                  )}
                  {question?.yes_no ? 
                  <div>
                    <div className='row firstRow'>
                      <div className='col-md-6'>
                        <CustomRadioButton
                          text={"Yes"}
                          className={"question-radio-btn"}
                          isblacked={true}
                          onChange={() => handleOptionChange("Yes")}
                          id={`Yes-${id}`}
                          name={`question-${id}`}
                          checked={selectedAnswer === "Yes"}
                          preselect={true}
                        />
                      </div>
                      <div className='col-md-6'>

                        <CustomRadioButton
                          text={"No"}
                          className={"question-radio-btn"}
                          isblacked={true}
                          onChange={() => handleOptionChange("No")}
                          id={`No-${id}`}
                          name={`question-${id}`}
                          checked={selectedAnswer === "No"}
                          preselect={true}
                        />
                      </div>
                    </div>
                  </div>
                  : <></>
                  }
                  {/* {answerErrorMessage && (
                    <div className='hair-product-error-message'>{answerErrorMessage}</div>
                  )} */}
                </div>
              </div>

              {/* {question?.comment && */}
              <div className='row'>
                <div className={question?.yes_no ? "question-comment question-YesNo-Comment col-md-12" : "question-comment col-md-12"}>
                  <textarea placeholder="Comments"
                    // Bind textarea value to the comment state
                    //  onChange={(e)=>onChange(selectedOption.answer,e.target.value,label)} // Call handleTextareaChange on textarea change
                    onChange={handleTextareaChange}
                    value={comment}

                  />
                  {commentErrorMessage && (
                    <div className='hair-product-error-message'>{commentErrorMessage}</div>
                  )}
                </div>
              </div>
              {/* } */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuestionsList;