import React, { useEffect, useState } from "react";
import "./business-sidebar.scss";
import { useLocation, useNavigate } from "react-router";
import { ReactComponent as HomeIcon } from "../../images/sidebar/home.svg";
import { ReactComponent as CalendarIcon } from "../../images/sidebar/calendar.svg";
import { ReactComponent as ShopIcon } from "../../images/sidebar/shop.svg";
import { ReactComponent as ClientIcon } from "../../images/sidebar/client.svg";
import { ReactComponent as MarketingIcon } from "../../images/sidebar/marketing.svg";
import Home from "../pages/business-profile/Home";
import BusinessSettingHeader from "../common/businessSettingHeader/BusinessSettingHeader";
import BusinessSetting from "../businessSetting/BusinessSetting";
import BusinessNavigation from "../common/businessNavigation/BusinessNavigation";
import CustomModal from "../modal/CustomModal";
import ManageAccount from "../businessSetting/manageAccount/ManageAccount";
// import ShopRightCard from '../manageAccount/Shop/RightCard/ShopRightCard';
import Clients from "../pages/business-profile/Clients/Clients";
import Marketing from "../pages/business-profile/Marketing/Marketing";
import Password from "../pages/business-profile/SecurityandPrivacy/Password/Password";
import Security from "../pages/business-profile/SecurityandPrivacy/Security";
import Locations from "../pages/business-profile/Locations/Locations";
import TreatmentServices from "../pages/business-profile/TreatmentServices/TreatmentServices";
import TeamMember from "../TeamMember/TeamMember";
import { CalendarRoutePattern, getBusinessProfileMarketingPageRoute, getNoclientPage, getNoclientPageRoute, indexPage, indexPagePattern } from "../../Routes";
import { getCalendarRoute } from "../../Routes";
import { webBaseUrl } from "../../config";


const BusinessSidebar = ({ setCurrentPage, currentPage }) => {
  //===================== DECLARATION ===================
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTabOption, setSelectedTabOption] = useState("");
  const [selectedComponent, setSelectedComponent] = useState("");
  const [selectedTabStep, setSelectedTabStep] = useState(null);
  const navigate = useNavigate();

  const componentMapping = {
    Home: {
      component: <Home />,
      Settings: <BusinessSetting setSelectedOption={setSelectedOption} />,
      Security: <Security setSelectedOption={setSelectedOption} />,
      Locations: <Locations setSelectedOption={setSelectedOption} />,
      TreatmentServices: (
        <TreatmentServices setSelectedOption={setSelectedOption} />
      ),
      ManageAccount: (
        <ManageAccount
          setSelectedOption={setSelectedOption}
          setSelectedTabOption={setSelectedTabOption}
          setSelectedTabStep={setSelectedTabStep}
        />
      ),
      TeamMember: <TeamMember />,
    },
    Clients: {
      component: <Clients />,
      Settings: <BusinessSetting setSelectedOption={setSelectedOption} />,
      Security: <Security setSelectedOption={setSelectedOption} />,
      Locations: <Locations setSelectedOption={setSelectedOption} />,
      TreatmentServices: (
        <TreatmentServices setSelectedOption={setSelectedOption} />
      ),
      TeamMember: <TeamMember />,
    },
    Marketing: {
      component: <Marketing setSelectedComponent={setSelectedComponent} />,
      Settings: <BusinessSetting setSelectedOption={setSelectedOption} />,
      Security: <Security setSelectedOption={setSelectedOption} />,
      Locations: <Locations setSelectedOption={setSelectedOption} />,
      TreatmentServices: (
        <TreatmentServices setSelectedOption={setSelectedOption} />
      ),
      ManageAccount: (
        <ManageAccount
          setSelectedOption={setSelectedOption}
          setSelectedTabOption={setSelectedTabOption}
          setSelectedTabStep={setSelectedTabStep}
        />
      ),
    },

    Security: {
      component: <Password setSelectedComponent={setSelectedComponent} />,
      Settings: <BusinessSetting setSelectedOption={setSelectedOption} />,
      Security: <Security setSelectedOption={setSelectedOption} />,
      Locations: <Locations setSelectedOption={setSelectedOption} />,
      TreatmentServices: (
        <TreatmentServices setSelectedOption={setSelectedOption} />
      ),
      ManageAccount: (
        <Password
          setSelectedOption={setSelectedOption}
          setSelectedTabOption={setSelectedTabOption}
          setSelectedTabStep={setSelectedTabStep}
        />
      ),
    },

    Location: {
      component: <Password setSelectedComponent={setSelectedComponent} />,
      Settings: <BusinessSetting setSelectedOption={setSelectedOption} />,
      Security: <Security setSelectedOption={setSelectedOption} />,
      Locations: <Locations setSelectedOption={setSelectedOption} />,
      TreatmentServices: (
        <TreatmentServices setSelectedOption={setSelectedOption} />
      ),
      ManageAccount: (
        <Password
          setSelectedOption={setSelectedOption}
          setSelectedTabOption={setSelectedTabOption}
          setSelectedTabStep={setSelectedTabStep}
        />
      ),
    },

    TreatmentServices: {
      component: <Password setSelectedComponent={setSelectedComponent} />,
      Settings: <BusinessSetting setSelectedOption={setSelectedOption} />,
      Security: <Security setSelectedOption={setSelectedOption} />,
      Locations: <Locations setSelectedOption={setSelectedOption} />,
      TreatmentServices: (
        <TreatmentServices setSelectedOption={setSelectedOption} />
      ),
      ManageAccount: (
        <Password
          setSelectedOption={setSelectedOption}
          setSelectedTabOption={setSelectedTabOption}
          setSelectedTabStep={setSelectedTabStep}
        />
      ),
    },
  };
  const pathIndex = ["/home", "/calendar", "/shop", "/clients", "/marketing"];
  const sidebarOptions = [
    {
      icon: <HomeIcon />,
      title: "Home",
      route: indexPage,
      routePageName: '/business-dashboard',
    },
    {
      icon: <CalendarIcon />,
      title: "Calendar",
      route: CalendarRoutePattern,
      routePageName: '/calendar',
    },
    {
      icon: <ShopIcon />,
      title: "Shop",
      route: indexPage,
      routePageName: '/shop',
    },
    {
      icon: <ClientIcon />,
      title: "Clients",
      route: getNoclientPageRoute,
      routePageName: '/client',
    },
    {
      icon: <MarketingIcon />,
      title: "Marketing",
      route: getBusinessProfileMarketingPageRoute,
      routePageName: '/marketing',
    },
  ];
  const location = useLocation();
  const [activePath, setActivePath] = useState(0);
  console.log('location', )

  //===================== FUNCTIONS =====================
  //SET PATH NAVIGATION
  const handleRouteNavigation = (index) => {
    if (index === 1) { navigate(getCalendarRoute()) }
    else if (index === 2) {
      window.location.href = webBaseUrl + '/shop'
    }
    if (activePath === index) {
      setActivePath(index);
      // If the clicked option is already active, reset selectedOption
      setSelectedOption("");
      setSelectedTabOption("");
    } else {
      setActivePath(index);
      setSelectedOption("");
      setSelectedTabOption("");
    }
  };

  //DESC FROM SELECTED OPTION
  const renderDescription = (selectedOption) => {
    switch (selectedOption) {
      case "Manage account":
        return "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.";
      default:
        return "";
    }
  };
  console.log("selecated Option", selectedOption);

  return (
    <div>
      <nav id="sidebarMenu" className="Left-sideBar CustomeBusinessSideBar">
        <div className="nav-business-container">
          <ul className="SideBarLink">
            {sidebarOptions.map((item, index) => {
              console.log(item?.route, 'item?.route')
              return (
                <li className="nav-item" key={index}>
                  <div
                    className={`nav-link ${item?.routePageName == location?.pathname ? "active" : "inactive"
                      }`}
                    onClick={() => {
                      handleRouteNavigation(index);
                      navigate(item?.route)
                    }}
                  >
                    <>{item?.icon}</>
                    <p>{item.title}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default BusinessSidebar;
