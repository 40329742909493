
import { gql, useMutation } from "@apollo/client";

const INPUT_USER_INFO_MUTATION = gql`
mutation SaveOrUpdateAccountDetails($usrListOnSearchEngine: Int, $requirePasswordForClientDirectory: Boolean, $affiliationLink: String, $holidayRemove: Boolean, $trainingAcademy: Int, $occupation: [Int], $dob: String, $countryCode: String, $phone: String, $phoneCountry: String, $businessName: String, $email: String, $lastName: String, $firstName: String, $profileImage: Upload) {
  saveOrUpdateAccountDetails(usrListOnSearchEngine: $usrListOnSearchEngine, requirePasswordForClientDirectory: $requirePasswordForClientDirectory, affiliationLink: $affiliationLink, holidayRemove: $holidayRemove, trainingAcademy: $trainingAcademy, occupation: $occupation, dob: $dob, countryCode: $countryCode, phone: $phone, phoneCountry: $phoneCountry, businessName: $businessName, email: $email, lastName: $lastName, firstName: $firstName, profileImage: $profileImage) {
    success
    message
  }
}
 `;


export const InputInfoUserAction = () => {
    // const dispatch = useDispatch();
    const [
        InputuserInfoResponseFunction,
        InputuserInfoResponseResponseData,
    ] = useMutation(INPUT_USER_INFO_MUTATION)

    //this will be called from components
    const InputuserInfoResponseData = InputuserInfoResponseResponseData; //variable

    const initInputuserInfoResponsefunction = (data, successCallback, errorCallback) => {
        
        InputuserInfoResponseFunction({
            variables: data,
    
        })
        
            // .then(({ data: { PurchaseAccountancyPackage: responseData } }) => {
            .then((responseData) => {
                console.log(responseData,"/./.")
                if (responseData.data.saveOrUpdateAccountDetails.success) {
                    // dispatch({
                    //     type: SET_BOOKING_DETAILS,
                    //     payload: responseData?.data
                    // })
                    successCallback(responseData)
                    // toast.success(responseData.message)
                }
                else {
                    errorCallback(responseData)
                    // toast.error(responseData.message)
                }
            }).catch((err) => {
                console.log(err)
                // errorCallback(err)
                // toast.error("something went wrong!!!")
            })
    }

    return { InputuserInfoResponseData, initInputuserInfoResponsefunction };
};
