import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { CHECK_WEBSITE_DOMAIN_RESPONSE, GET_WEBSITE_TeamMembers, TEAM_MEMBERS_DATA } from "../../constants";


//1 date-api
const GET_WEB_TEAM_MEMBERS_QUERY = gql`
query GetPersonalWebTeamMembers {
    getPersonalWebTeamMembers {
      success
      message
      data {
        items {
          pwtm_id
          pwtm_pwc_id
          pwtm_full_name
          pwtm_qualification
          pwtm_job_role
          pwtm_description
          image
        }
      }
    }
  }
`

export const GetWebsiteTeamMembersAction = () => {
  const dispatch = useDispatch()
  const [getWebsiteTeamMembersResponseFunction, getWebsiteTeamMembersReponseData] = useLazyQuery(
    GET_WEB_TEAM_MEMBERS_QUERY,
    {
      // fetchPolicy: "cache-and-network",
      // nextFetchPolicy: "cache-and-network",
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted: ({ getPersonalWebTeamMembers: responseData }) => {
        console.log("responseData", responseData, "data")
        if (responseData?.success)
          dispatch({
            type: TEAM_MEMBERS_DATA,
            payload: responseData
          })
      }
    }
  )
  //this will be called from components
  const GetWebsiteTeamMembersResponseData = getWebsiteTeamMembersReponseData //variable

  const initGetTeamMembersResponseFunction = (data) => {

    getWebsiteTeamMembersResponseFunction({
      variables: data
    })
  }
  return { initGetTeamMembersResponseFunction, GetWebsiteTeamMembersResponseData }
}

