import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_RATINGS_QUERY = gql`
query GetRatings {
  getRating {
    success
    message
    data {
      google_rating
      trustpilot_rating
      total_google_users
      total_trustpilot_users
      google_profile_pics
    }
  }
}
`;

export const GetRatingsAction = () => {
  const [
    fetchRatings,
    ratingsResponseData,
  ] = useLazyQuery(GET_RATINGS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getRating: responseData }) => {
      if (responseData.success) {
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });

  // This will be called from components
  const getRatingsQueryData = ratingsResponseData; // Updated variable name

  const initFetchRatings = (variables) => {
    fetchRatings({
      variables: variables,
    });
  };

  return { getRatingsQueryData, initFetchRatings};
};
