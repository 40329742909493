import { useDropzone } from 'react-dropzone';
import './dropzone.scss';
import { useState, useEffect } from 'react';
// import placeHolder from '../../assets/forward-consent-form/treatment-placeholder.svg';
import placeHolder from '../../../../images/finance/docDropzoneIcon.svg';
import closeIcon from '../../../../images/close_icon.svg';
// import CustomModal from '../modal/CustomModal';
import CustomModal from '../../../modal/CustomModal';
import deleteIcon from '../../../../images/deleteIcon.svg';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../../pages/finance-identity/finance-confirmationModal.js';

const Dropzone = ({
  fileData,
  urlFromDB,
  handleDelete,
  id,
  handleMulti,
  selectedIndex,
  usrVrfId,
  insuranceId,
  docIndex = null,
  removeTextHereError,
  otherDocs,
  afterSubmitAction = null,
  sendDataToParent = null,
  dropzoneType = null,
  imageFromComponent,
}) => {
  const [imgUrl, setImgUrl] = useState(urlFromDB);
  const [file, setFile] = useState([]);
  const [open, setOpen] = useState(false);
  const [imgPreview, setImgPreview] = useState(false);



  useEffect(() => {
    setImgUrl(urlFromDB);
  }, [urlFromDB]);

  useEffect(() => {
    if (imageFromComponent instanceof File && handleMulti) {
      // console.log("file",imageFromComponent)
      setFile([imageFromComponent]);
    } else {
      setFile([]);
    }
  }, [imageFromComponent]);

  const [callOtherDocs, setCallOtherDocs] = useState(false);




  // const onDrop = (acceptedFiles) => {
  //   setFile([...file, ...acceptedFiles]);
  //   if (!handleMulti) {
  //     fileData(acceptedFiles[0]);
  //   } else {
  //     handleMulti((prevOtherDocs) => {
  //       const updatedDocs = [...prevOtherDocs];
  //       const tmpIndex = updatedDocs.findIndex(obj => !obj.image && !obj.document_url);
  //       console.log('tmpIndex', tmpIndex);
  //       // updatedDocs[selectedIndex] = {
  //       //   ...updatedDocs[selectedIndex],
  //       //   image: acceptedFiles[0],
  //       // };
  //       updatedDocs[tmpIndex] = {
  //         ...updatedDocs[tmpIndex],
  //         image: acceptedFiles[0],
  //       };
  //       console.log('tmpIndex', tmpIndex, updatedDocs);
  //       return updatedDocs;
  //     });
  //     // if (sendDataToParent) {
  //     //   return sendDataToParent(acceptedFiles[0]);
  //     // }
  //     // handleMulti((prevOtherDocs) => {
  //     //   let indArr = [...prevOtherDocs];
  //     //   let ind = indArr.findIndex(obj => obj.image === '' && obj.certificate_title === '');
  //     //   console.log('indiiii', selectedIndex, ind, indArr, prevOtherDocs);
  //     //   const updatedDocs = [...prevOtherDocs];
  //     //   if (ind !== -1 && acceptedFiles.length > 0) {
  //     //     updatedDocs[ind] = {
  //     //         ...updatedDocs[ind],
  //     //         image: acceptedFiles[0], // Assuming acceptedFiles contains the file object
  //     //     };
  //     //   }
  //     //   setCallOtherDocs(true);
  //     //   return updatedDocs;
  //     // });
  //   }
  // };

  const onDrop = (acceptedFiles) => {

    console.log('hello from dropzone here1')
    if (typeof handleMulti === 'undefined' && typeof selectedIndex === 'undefined') {
      setFile([...file, ...acceptedFiles]);
      fileData(acceptedFiles[0]);
    }

    // Find the index with an empty image property and an imageUrl property
    const emptyIndex = otherDocs.findIndex(
      (doc) => !doc.image && !doc.image_url && !doc.certificate_url && !doc.document_url
    );

    if (!handleMulti) {
      console.log('hello from dropzone here2')
      fileData(acceptedFiles[0]);
      setFile([...file, ...acceptedFiles]);
    } else {
      console.log('hello from dropzone here3')
      handleMulti((prevOtherDocs) => {
        const updatedDocs = [...prevOtherDocs];
        if (emptyIndex !== -1) {
          // Update the document at the empty index
          updatedDocs[emptyIndex] = {
            ...updatedDocs[emptyIndex],
            image: acceptedFiles[0],
          };
        }
        return updatedDocs;
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png', 'application/pdf'],
    onDrop,
  });

  const openPDF = () => {
    const blob = new Blob(file, { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  const openPdf = () => {
    window.open(urlFromDB);
  };

  return (
    <section className="main-container">
      {imgUrl ? (
        <div className="preview">
          <div className="image-preview">
            {imgUrl.endsWith('.pdf') ? (
              // <img src={imgUrl} alt="Uploaded document" onClick={openPdf} />
              <span onClick={openPdf}>
                <i class="fa-regular fa-file-pdf me-2"></i>
                Uploaded document
              </span>
            ) : (
              <img
                src={imgUrl}
                alt="Uploaded document"
                onClick={() => setImgPreview(true)}
              />
            )}
            <div className="btnClose1">
              <img
                className="closeBtn"
                src={closeIcon}
                alt="Delete"
                onClick={() => setOpen(true)}
              />
            </div>
          </div>
        </div>
      ) : file?.length > 0 ? (
        <div className="preview">
          {file?.map((item, index) => {
            return (
              <div key={index} className="image-preview">
                {item.type.startsWith('image/') ? (
                  <img
                    src={URL.createObjectURL(item)}
                    alt={item.name}
                    onClick={() => setImgPreview(true)}
                  />
                ) : (
                  <span onClick={openPDF}>
                    <i class="fa-regular fa-file-pdf me-2"></i>
                    {item.name}
                  </span>
                )}
                <div className="btnClose1">
                  <img
                    className="closeBtn"
                    src={closeIcon}
                    alt="modalClose"
                    onClick={() => setOpen(true)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {file.length === 0 && (
            <>
              <img src={placeHolder} alt="" />
              <p>Upload</p>
            </>
          )}
        </div>
      )}

      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        title="Are you sure?"
        className={"ConfirmationDeleteModal"}
        content="Are you sure you want to remove this document?"
        onConfirm={() => {
          if (imgUrl) {
            handleDelete(
              id ? { deleteTrainerImageId: id } :
                usrVrfId ? { usrVrfId: usrVrfId } :
                  insuranceId ? { deleteInsuranceDocumentForFinanceId: insuranceId } : null,
              (res) => {
                console.log("resp", res)
                if (!urlFromDB) {
                  setImgUrl(null);

                }
                if (fileData) {
                  setImgUrl(null);
                }
                if (res.message) {
                  toast.success(res.message)
                }
                if (res?.data?.deleteTrainingCertificateForFinance?.message) {
                  toast.success(res?.data?.deleteTrainingCertificateForFinance?.message)
                }
                if (res?.data?.deleteInsuranceDocumentForFinance?.message) {
                  toast.success(res?.data?.deleteInsuranceDocumentForFinance?.message)
                }
                if (!urlFromDB) {
                  setImgUrl(null);

                }
                if (fileData) {


                  //  setImgUrl(null);
                }
                // if (setIdentityUrl) { setIdentityUrl('') }

              },
              (error) => {
                toast.error(error.message);
              }
            );
          }
          setFile([]);
          if (handleMulti) {
            handleMulti((prevOtherDocs) => {
              const updatedDocs = [...prevOtherDocs];
              const filteredDocs = updatedDocs.filter(
                (doc, index) => index !== selectedIndex
              );
              return filteredDocs;
              // const updatedDoc = { ...updatedDocs[selectedIndex] };
              // updatedDoc.image = null;
              // usrVrfId ? updatedDoc.certificate_title = " ":insuranceId? updatedDoc.document_title="":updatedDoc.title="";
              // updatedDoc.image_url = '';
              // updatedDocs[selectedIndex] = updatedDoc;
              // console.log("ppppppp",updatedDocs)
              // return updatedDocs;
            });
            // if (handleMulti) {
            //   handleMulti((prevOtherDocs) => {
            //     const updatedDocs = [...prevOtherDocs];
            //     const updatedDoc = { ...updatedDocs[selectedIndex] };
            //     updatedDoc.image = null;
            //     usrVrfId ? updatedDoc.certificate_title = " ":insuranceId? updatedDoc.document_title="":updatedDoc.title="";
            //     updatedDoc.image_url = '';
            //     updatedDocs[selectedIndex] = updatedDoc;
            //     console.log("ppppppp",updatedDocs)
            //     return updatedDocs;
            //   });
          } else {
            fileData({});
          }
          setOpen(false);
          setImgPreview(false);
        }}
        onCancel={() => setOpen(false)}
      />

      
      <CustomModal
        modalOpen={imgPreview}
        setModalOpen={setImgPreview}
        modalBody={
          <img
            className="w-100 h-100 mt-3"
            src={imgUrl || (file.length > 0 && URL.createObjectURL(file[0]))}
            alt="Uploaded document"
          />
        }
        modalTitle={'calander'}
      />
    </section>
  );
};

export default Dropzone;
