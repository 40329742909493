import { CLEAR_APPOINTMENT_HOLD_DURATION, GET_APPOINTMENT_HOLD_DURATION } from "../../constants/appointment/appointmentConst";



const initialState = {
  appointmentHoldDuration: []
}

export default function appointmentHoldDurationReducer(state = initialState, action) {

  switch (action.type) {

    case GET_APPOINTMENT_HOLD_DURATION:
      return {
        ...state,
        appointmentHoldDuration: action.payload,
      };

    case CLEAR_APPOINTMENT_HOLD_DURATION:

      return {
        ...state,
        appointmentHoldDuration: []
      }

    default:
      return state;
  }



}