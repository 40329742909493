export const GET_SUPPORT_CHAT_DATA = 'GET_SUPPORT_CHAT_DATA'
export const GET_SUPPORT_CHAT_DATA_SUCCESS = 'GET_SUPPORT_CHAT_DATA_SUCCESS'
export const GET_SUPPORT_CHAT_DATA_ERROR = 'GET_SUPPORT_CHAT_DATA_ERROR'

// ===============================================================

export const GET_THREAD_CHAT_DATA = "GET_THREAD_CHAT_DATA"
export const GET_THREAD_CHAT_DATA_SUCCESS = "GET_THREAD_CHAT_DATA_SUCCESS"
export const GET_THREAD_CHAT_DATA_ERROR = "GET_THREAD_CHAT_DATA_ERROR"

// ===============================================================

export const SUPPORT_CHAT_CATEGORIES_DATA = "SUPPORT_CHAT_CATEGORIES_DATA"
export const SUPPORT_CHAT_CATEGORIES_DATA_SUCCESS = "SUPPORT_CHAT_CATEGORIES_DATA_SUCCESS"
export const SUPPORT_CHAT_CATEGORIES_DATA_ERROR = "SUPPORT_CHAT_CATEGORIES_DATA_ERROR"

// ===============================================================

export const CREATE_SUPPORT_THREADS_DATA = "CREATE_SUPPORT_THREADS_DATA"
export const CREATE_SUPPORT_THREADS_DATA_SUCCESS = "CREATE_SUPPORT_THREADS_DATA_SUCCESS"
export const CREATE_SUPPORT_THREADS_DATA_ERROR = "CREATE_SUPPORT_THREADS_DATA_ERROR"

// ===============================================================

export const SUPPORT_RECENT_ORDERS_DATA = "SUPPORT_RECENT_ORDERS_DATA"
export const SUPPORT_RECENT_ORDERS_DATA_SUCCESS = "SUPPORT_RECENT_ORDERS_DATA_SUCCESS"
export const SUPPORT_RECENT_ORDERS_DATA_ERROR = "SUPPORT_RECENT_ORDERS_DATA_ERROR"

// ===============================================================

export const GET_ORDER_TRACE_DATA = "GET_ORDER_TRACE_DATA"
export const GET_ORDER_TRACE_DATA_SUCCESS = "GET_ORDER_TRACE_DATA_SUCCESS"
export const GET_ORDER_TRACE_DATA_ERROR = "GET_ORDER_TRACE_DATA_ERROR"

// ===============================================================

export const CREATE_SUPPOR_CHAT_THREADS_DATA = "CREATE_SUPPOR_CHAT_THREADS_DATA"
export const CREATE_SUPPOR_CHAT_THREADS_DATA_SUCCESS = "CREATE_SUPPOR_CHAT_THREADS_DATA_SUCCESS"
export const CREATE_SUPPOR_CHAT_THREADS_DATA_ERROR = "CREATE_SUPPOR_CHAT_THREADS_DATA_ERROR"

// ===============================================================

export const SUPPORT_THREADS_ADD_FEEDBACK_DATA = "SUPPORT_THREADS_ADD_FEEDBACK_DATA"
export const SUPPORT_THREADS_ADD_FEEDBACK_DATA_SUCCESS = "SUPPORT_THREADS_ADD_FEEDBACK_DATA_SUCCESS"
export const SUPPORT_THREADS_ADD_FEEDBACK_DATA_ERROR = "SUPPORT_THREADS_ADD_FEEDBACK_DATA_ERROR"

// ===============================================================

export const SUPPORT_THREADS_CHAT_UPDATE_DATA = "SUPPORT_THREADS_CHAT_UPDATE_DATA"
export const SUPPORT_THREADS_CHAT_UPDATE_DATA_SUCCESS = "SUPPORT_THREADS_CHAT_UPDATE_DATA_SUCCESS"
export const SUPPORT_THREADS_CHAT_UPDATE_DATA_ERROR = "SUPPORT_THREADS_CHAT_UPDATE_DATA_ERROR"

// ===============================================================

export const SUPPORT_THREADS_MESSAGES_MARK_DATA = "SUPPORT_THREADS_MESSAGES_MARK_DATA"
export const SUPPORT_THREADS_MESSAGES_MARK_DATA_SUCCESS = "SUPPORT_THREADS_MESSAGES_MARK_DATA_SUCCESS"
export const SUPPORT_THREADS_MESSAGES_MARK_DATA_ERROR = "SUPPORT_THREADS_MESSAGES_MARK_DATA_ERROR"

// ===============================================================

export const SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA = "SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA"
export const SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA_SUCCESS = "SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA_SUCCESS"
export const SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA_ERROR = "SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA_ERROR"