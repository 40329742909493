import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_TREATMENTS_FOR_CLINIC } from "../../constants/treatment-list-const/TreatmentListConst";
import { useSearchParams } from "react-router-dom";


const GET_TREATMENTS_FOR_CLINIC_QUERY = gql`
query GetTreatmentsForClinic($clinicId: Int, $page: Int, $limit: Int, $search: String, $itemIds: [Int]) {
  getTreatmentsForClinic(clinicId: $clinicId, page: $page, limit: $limit, search: $search, itemIds: $itemIds) {
    success
    message
    data {
      treatments {
        treatmentId
        treatmentName
        treatmentPrice
        treatmentTime
        depositAmount
        consentFomrs
        aftercareDocs
      }
      total
      totalPages
      currentPage
    }
  }
}
`;

var page;
export const GetTreatmentsForClinic = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [getTreatmentsForClinic, clinicTreatmentsData] = useLazyQuery(
    GET_TREATMENTS_FOR_CLINIC_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getTreatmentsForClinic: responseData }) => {
        if (responseData.success) {
          dispatch({
            type: GET_TREATMENTS_FOR_CLINIC,
            payload: responseData, page
          });
        }
      },
    }
  );
  const clinicTreatmentsQueryData = clinicTreatmentsData;
  const initGetTreatmentsForClinic = (data) => {
    page = data?.page ? data?.page : 1
    getTreatmentsForClinic({
      variables: data,
    });
  };
  return { initGetTreatmentsForClinic, clinicTreatmentsQueryData };
};

