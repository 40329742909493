import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_CLIENT_SECRET_SETUP_INTENT_DATA } from "../../constants/forward-consent-form/ForwardConsentFormConst";

const GET_CLIENT_SECRET_SETUP_INTENT_QUERY = gql`
query GetClientSecretSetupIntent($cfrId: Int, $cfrToken: String) {
  getClientSecretSetupIntent(cfrId: $cfrId, cfrToken: $cfrToken) {
    success
    message
    data {
      client_secret
    }
  }
}
  `;

export const GetClientSecretSetupIntentQuery = () => {
  const dispatch = useDispatch();

  const [getClientSecretSetupIntent,clientSecretSetupData] = useLazyQuery(
    GET_CLIENT_SECRET_SETUP_INTENT_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClientSecretSetupIntent: responseData }) => {
        dispatch({
          type: GET_CLIENT_SECRET_SETUP_INTENT_DATA,
          payload: responseData?.data,
        });
        if (responseData.success) {

        } else {

        }


      },
    }
  );
  const clientSecretSetupQueryData = clientSecretSetupData;
  const initClientSecretSetupIntent = (data) => {
    getClientSecretSetupIntent({
        variables: data,
    })
  };
  return { initClientSecretSetupIntent, clientSecretSetupQueryData };
};



