import React, { useState } from 'react'
import './custom-modal.scss'
// import './basic-details-modal/basic-details-modal.scss'
import Modal from "react-modal";
import modalClose from '../../../images/close_icon.svg'

const CustomModal = ({ modalOpen, setModalOpen, handleDeleteCart, bookPractical, type, modalBody, modalTitle, className }) => {


    const closeModal = () => {
        setModalOpen(false);
    };
    return (
        <>
            <Modal
                className={"custom-modal " + className}
                isOpen={modalOpen}
                shouldCloseOnOverlayClick={false}
                onRequestClose={() => {
                    closeModal();
                }}
                closeTimeoutMS={200}
                ariaHideApp={false}

            >
                <div className={'modal-body-container container'}>
                    <div className={"modal-header-div"}>

                        <h5 className="modal-title">{modalTitle ? modalTitle : null} </h5>

                        <div className='close ms-95'>
                            <button className='modal-close-icon ' onClick={() => closeModal()}>
                                <img
                                    src={modalClose}
                                    alt="modalClose"
                                />
                            </button>
                        </div>
                    </div>
                    {modalBody}

                </div>
            </Modal >
        </>
    )
}

export default CustomModal