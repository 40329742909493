import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { CHECK_WEBSITE_DOMAIN_RESPONSE, GET_WEBSITE_TeamMembers, TEAM_MEMBERS_DATA, TEAM_MEMBER_EDIT_DATA } from "../../constants";


//1 date-api
const GET_TEAM_MEMBER_DETAILS_QUERY = gql`
query GetTeamMemberDetails($getTeamMemberDetailsId: Int!) {
    getTeamMemberDetails(id: $getTeamMemberDetailsId) {
      success
      message
      data {
        pwtm_id
        pwtm_pwc_id
        pwtm_full_name
        pwtm_qualification
        pwtm_job_role
        pwtm_description
        image
      }
    }
  }
`

export const GetWebsiteTeamMemberDetailsAction = () => {
  const dispatch = useDispatch()
  const [getWebsiteTeamMemberDetailsResponseFunction, getWebsiteTeamMemberDetailsReponseData] = useLazyQuery(
    GET_TEAM_MEMBER_DETAILS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getTeamMemberDetails: responseData }) => {
        console.log("responseData", responseData,"data")
        if (responseData?.success)
          dispatch({
            type: TEAM_MEMBER_EDIT_DATA,
            payload: responseData
          })
      }
    }
  )
  //this will be called from components
  const GetWebsiteTeamMemberDetailsResponseData = getWebsiteTeamMemberDetailsReponseData //variable

  const initGetTeamMembersDetailsResponseFunction = (data) => {

    getWebsiteTeamMemberDetailsResponseFunction({
      variables: data
    })
  }
  return { initGetTeamMembersDetailsResponseFunction, GetWebsiteTeamMemberDetailsResponseData}
}

