// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { GET_APPOINTMENT_DETAILS_ERROR, GET_APPOINTMENT_DETAILS_SUCCESS, GET_APPOINTMENT_LIST_SUCCESS, GET_ONBOARDING_STATUS_ERROR, GET_ONBOARDING_STATUS_SUCCESS } from "../../constants";

const GET_APPOINTMENT_DETAILS_QUERY = gql`
  query GetAppointmentDetails($bookingId: Int!) {
    getAppointmentDetails(booking_id: $bookingId) {
      success
      message
      data {
        appointment_details {
          booking_id
          consent_form_id
          client_image
          deposit_status
          deposit_status_color
          auto_cancel_in_days
          treatment_date
          start_time
          end_time
          treatment_duration
          client_fname
          client_lname
          mobile_number
          country_code
          clininc_address
          location_name
          practitioner_name
          discount
          sub_total
          order_total
          photos_count
          treatments_list {
            name
            price
          }
          booked_by_fname
          booked_by_lname
          deposit_amount
          due_amount
          deposit_request_status
        }
        booking_status_details {
          consent_form_date
          consent_form_status
          consent_form_color
          payment_deposit_date
          payment_deposit_status
          payment_deposit_color
          payment_deposit_status_label
          appointment_creation_date
          batch_status
          after_care_status
          notes_status
          facial_mapping_status
          photos_status
          medical_status
          payment_status_data {
            type
            type_label
            status
            label
            color
            date
          }
        }
      }
    }
  }
`;

export const GetAppointmentDetailsAction = () => {
  const dispatch = useDispatch();
  const [
    getAppointmentDetailsResponseFunction,
    getAppointmentDetailsResponseData,
  ] = useLazyQuery(GET_APPOINTMENT_DETAILS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getAppointmentDetails: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: GET_APPOINTMENT_DETAILS_SUCCESS,
          payload: responseData,
        });
        console.log('dispatched GetAppointmentDetailsAction');
        // toast.success(responseData?.message)
        // GET_ONBOARDING_STATUS_SUCCESS
      } else {
        dispatch({
          type: GET_APPOINTMENT_DETAILS_ERROR,
          payload: responseData,
        })
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getAppointmentDetailsQueryData = getAppointmentDetailsResponseData; //variable

  const initGetAppointmentDetailsData = (data) => {
    getAppointmentDetailsResponseFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.getAppointmentDetails?.success) {
        console.log('res success', res);
        // successCallback(res);
      } else {
        console.log('res error', res);
        // errorCallback(res);
      }
    }).catch((err) => {
      console.log('res err', err);
      // errorCallback(err);
    });
  };

  return { getAppointmentDetailsQueryData, initGetAppointmentDetailsData };
};
