import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_APPOINTMENT_DETAILS_DATA, GET_APPOINTMENT_DETAILS_V3_DATA } from "../../constants/appointment/appointmentConst";

const GET_APPOITMENT_DETAILS_V3_QUERY = gql`
query GetAppointmentDetails_v3($appointmentId: Int) {
  getAppointmentDetails_v3(appointmentId: $appointmentId) {
    success
    message
    data {
      appointmentId
      userId
      clientDetails {
        clientId
        firstName
        lastName
        email
        phoneCountry
        phone
      }
      clinicId
      clinicName
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      treatmentDetails {
        treatmentId
        treatmentName
        treatmentPrice
        depositAmount
        treatmentCosnetForms
        treatmentAftercareDocs
        treatmentTime
      }
      consentForms {
        consentFormId
        consentFormName
      }
      aftercareDocs {
        aftercareDocId
        aftercareDocName
      }
      holdOnAppointmentAdded
      holdDuration
      followUpsAdded
      followUps
      followUpsType
      textReminder
      emailReminder
      collectPayment
      offerPayMonthly
      subTotal
      discountCodeType
      discountAmount
      depositAmount
      totalAmount
      signature
      depositRequestable
      depositPaid
    }
  }
}
`;

var page;
export const GetAppointmentDetailsV3Query = () => {
  const dispatch = useDispatch();
  const [getAppointmentDetailsV3Data, appointmentDetailsV3Data] = useLazyQuery(
    GET_APPOITMENT_DETAILS_V3_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getAppointmentDetails_v3: responseData }) => {
        if (responseData.success) {
          dispatch({
            type: GET_APPOINTMENT_DETAILS_V3_DATA,
            payload: responseData.data,
            page,
          });
        } else {
        }
      },
    }
  );
  const appointmentDetailsV3QueryData = appointmentDetailsV3Data;
  const initGetAppointmentDetailsV3 = (data) => {
    page = data?.page ? data?.page : 1;
    getAppointmentDetailsV3Data({
      variables: data,
    })
  };
  return { initGetAppointmentDetailsV3, appointmentDetailsV3QueryData };
};
