import axios from '../../../axios/index'
import { API_URL, BASE_URL } from "../../../axios/config";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import { GET_INSURANCE_DATA } from "../../constants/index";
import { getPrescriptionCountError, getPrescriptionCountSuccess } from '../../actions/prescription/prescription';
import { GET_PRESCRIPTION_COUNT } from '../../constants/prescription/prescription';


async function getPrescriptionCountAPI(data) {
    return axios.request({
        method: "get",
        url: BASE_URL + API_URL.GET_PRESCRIPTION_COUNT,

        params: data
    });
}

function* getPrescriptionCountAction(action) {
    let { payload, resolve, reject } = action;
    try {
        // yield put(setLoaderStart());
        let response = yield call(getPrescriptionCountAPI, payload);
        // yield put(setLoaderComplete());
        if (response.status === 200) {
            if (response.data.success) {
                yield put(getPrescriptionCountSuccess(response.data));
            }
            else {
            }
            if (resolve) resolve(response);
        } else {
            yield put((response));
            if (reject) reject(response);
        }
    } catch (error) {
        // yield put(setLoaderComplete());
        yield put(getPrescriptionCountError(error));
        if (reject) reject(error);
    }
}

export function* getPrescriptionCountWatcher() {
    yield takeLatest(GET_PRESCRIPTION_COUNT, getPrescriptionCountAction);
}
