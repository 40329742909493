import { CLEAR_CLIENT_SECRET_SETUP_INTENT_DATA, GET_CLIENT_SECRET_SETUP_INTENT_DATA } from "../../constants/forward-consent-form/ForwardConsentFormConst"



const initialState = {
    clientSecret: null,
}

export const clientSecretSetupIntentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLIENT_SECRET_SETUP_INTENT_DATA:
            console.log("client secret",action?.payload)
            return {
                ...state,
                clientSecret: action.payload?.client_secret,
            }
        case CLEAR_CLIENT_SECRET_SETUP_INTENT_DATA:
            return {
                ...state,
                clientSecret: null,
            }
        default:
            return state
    }
}