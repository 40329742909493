import { gql, useLazyQuery } from "@apollo/client"

const CHECK_NO_APPOINTMENT_AVAILABLE = gql`
query CheckNoAppointmentAvailable($tokenSlug: String!) {
  checkNoAppointmentAvailable(tokenSlug: $tokenSlug) {
    success
    message
  }
}`

export const GetNoAppointmentAVailableAction = () => {
    // const dispatch = useDispatch()
    const [checkNoAppointmentAvailableFunction, checkNoAppointMentAvailable] = useLazyQuery(
        CHECK_NO_APPOINTMENT_AVAILABLE,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getClinicAvailableTimesForDate: responseData }) => {

                if (responseData?.success) {

                    // dispatch({
                    //     type: GET_TIME_SLOTS,
                    //     payload: responseData
                    // })
                }

            }
        }
    )
    const checkNoAppointMentAvailableData = checkNoAppointMentAvailable

    const initCheckNoAppointmentAvailable = (data) => {
        checkNoAppointmentAvailableFunction({
            variables: {
                ...data,
            }
        })
    }

    return { checkNoAppointMentAvailableData, initCheckNoAppointmentAvailable }
}