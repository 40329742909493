import '../edit-form/edit-main.scss';
import { useNavigate } from 'react-router';
import React, { useEffect, useRef, useState } from 'react';
import NavHeader from '../../common/NavHeader/NavHeader';
import PreviewQuestionItem from '../edit-form/common-component/PreviewQuestionItem';
import { editMedicalFormRoute } from '../../../Routes';
import { Divider } from '@material-ui/core';
import { GetMedicalFormQuestionsQuery } from '../../redux/actions/formsTemplate/getFormTemplateList';
import { useSearchParams } from 'react-router-dom';
import CustomeHeading from "../../customeHeading/customeHeading";

const PreviewMedicalForm = ({ step, setStep, consentId }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const initialActiveTab = searchParams.get('tab');

    const handleBackToHome = () => {
        // navigate('/manage-forms');
        // navigate(initialActiveTab ? `/manage-forms?tab=${initialActiveTab}` : "/manage-forms");
        navigate(editMedicalFormRoute + '?tab=3');
    };

    const [questionList, setQuestionList] = useState(null);

    const { initGetMedicalFormQuestions, getMedicalFormQuestionsStatus } =
        GetMedicalFormQuestionsQuery();

    useEffect(() => {
        initGetMedicalFormQuestions((data) => {
            console.log('data-ixcvlj', data);
            setQuestionList(data);
        });
    }, []);

    return (
        <div className="Main-Edit-Manage-Forms-page">
            <div className="form-mode-indicator">
                <p>View as a client</p>
            </div>
            <NavHeader
                btnLabel={'Edit'}
                isGrayColorButton={true}
                applyPadding={false}
                isBackWithButton={true}
                onClickCallback={() => {
                    navigate(editMedicalFormRoute + '?tab=3');
                }}
                handleOnBack={handleBackToHome}
            />
             <div className="maxWidth540 paddintTop80">
                <CustomeHeading
                Title={'Medical form'}
                // Desc={'consentFormDetails?.disclaimer'}
                isSearch={false}
                />

                <div className="form-edit-options pb-5">
                    {questionList &&
                        questionList.length > 0 &&
                        questionList.map((item, index) => {
                            if (item.subQuestions && item.subQuestions.length > 0) {
                                return (
                                    <div className="preview-question-wrapper">
                                        <p className="preview-question-title">{item.question}</p>
                                        {item.subQuestions.map((que) => (
                                            <>
                                                <Divider
                                                    style={{
                                                        backgroundColor: '#DDDDDD',
                                                        opacity: 1,
                                                        marginTop: '20px',
                                                    }}
                                                />
                                                <div className="sub-question-item">
                                                    <PreviewQuestionItem
                                                        noOtherOption
                                                        question={que.question}
                                                        key={que.id}
                                                        queId={que.id}
                                                    />
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                );
                            }
                            return (
                                <div className="preview-question-wrapper">
                                    <PreviewQuestionItem
                                        queId={item.id}
                                        noOtherOption
                                        question={item.question}
                                        key={item.id}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default PreviewMedicalForm;
