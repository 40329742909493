import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const SAVE_PRESCRIBER_TRAINER_DETAILS_MUTATION = gql`
mutation SavePrescriberTrainerDetails($trainerName: String, $phoneNoCountryInitial: String, $phoneNoCountryCode: String, $phoneNo: String, $toxinCertificateImage: String, $toxinCertificateImageFile: Upload, $dermalFillerCertificateImage: String, $dermalFillerCertificateImageFile: Upload, $otherImages: [otherImagesType], $otherImagesFile: [otherImagesFileType]) {
  savePrescriberTrainerDetails(trainer_name: $trainerName, phone_no_country_initial: $phoneNoCountryInitial, phone_no_country_code: $phoneNoCountryCode, phone_no: $phoneNo, toxin_certificate_image: $toxinCertificateImage, toxin_certificate_imageFile: $toxinCertificateImageFile, dermal_filler_certificate_image: $dermalFillerCertificateImage, dermal_filler_certificate_imageFile: $dermalFillerCertificateImageFile, other_images: $otherImages, other_imagesFile: $otherImagesFile) {
    success
    message
  }
}
`;
export const PrescriberTrainerDetails = () => {
    const [savePrescriberTrainerDetails, { data: savePrescriberTrainerDetailsResponseData }] =
      useMutation(SAVE_PRESCRIBER_TRAINER_DETAILS_MUTATION);
    const dispatch = useDispatch();
  
    const initiateSavePrescriberTrainerDetails = (variables, successCallback, errorCallback) => {
      savePrescriberTrainerDetails({ variables })
        .then(({ data }) => {
          if (data.savePrescriberTrainerDetails.success) {
            successCallback(data.savePrescriberTrainerDetails);
          } else {
            errorCallback(data.savePrescriberTrainerDetails);
          }
        })
        .catch((error) => {
          // Handle error
          errorCallback(error);
        });
    };
  
    return { initiateSavePrescriberTrainerDetails, savePrescriberTrainerDetailsResponseData };
  };

  const GET_PRESCRIBER_TRAINER_DETAILS_QUERY = gql`
  query GetPrescriberTrainerDetails {
    getPrescriberTrainerDetails {
      success
      message
      data {
        trainer_name
        trainer_phone_no
        trainer_phone_country_code
        trainer_phone_country_initial
        toxin_certificate {
          id
          title
          image_url
        }
        dermal_certificate {
          id
          title
          image_url
        }
        otherImages {
          id
          title
          image_url
        }
      }
    }
  }
  
`;
export const GetPrescriberTrainerDetails = () => {
    const dispatch = useDispatch();
    const [getTrainerResponseFunction, { loading, error, data }] = useLazyQuery(GET_PRESCRIBER_TRAINER_DETAILS_QUERY, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: (response) => {
        
      },
    });
  
    const initGetPrescriberTrainerDetails = () => {
      getTrainerResponseFunction();
    };
  
    return { loading, error, data, initGetPrescriberTrainerDetails };
  };

  const DELETE_TRAINER_DOCUMENT_MUTATION = gql`
  mutation DeleteTrainerImage($deleteTrainerImageId: Int) {
    deleteTrainerImage(id: $deleteTrainerImageId) {
      success
      message
    }
  }
`;

export const DeleteTrainerDocument = () => {
  const [deleteTrainerImage, { data: deleteTrainerDocumentResponseData }] =
    useMutation(DELETE_TRAINER_DOCUMENT_MUTATION);
  const dispatch = useDispatch();

  const initiateDeleteTrainerDocument = (variables,successCallback, errorCallback) => {
    deleteTrainerImage({variables})
     .then(({data}) => {
        if (data && data.deleteTrainerImage.success) {
          successCallback(data.deleteTrainerImage);
        } else {
          errorCallback( data?.deleteTrainerImage);
        }
      })
     .catch((error) => {
        // Handle error
        errorCallback(error);
      });
  };


  return { initiateDeleteTrainerDocument, deleteTrainerDocumentResponseData };
};