// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";

const EXPORT_FINANCE_LIST_TO_CSV_QUERY = gql`
query DownloadFetchedFinanceOfferedChartDataInCsv($startDateFromUser: String, $endDateFromUser: String) {
    downloadFetchedFinanceOfferedChartDataInCsv(startDateFromUser: $startDateFromUser, endDateFromUser: $endDateFromUser) {
      success
      message
      data
    }
  }
`;

export const ExportFinanceListToCsv = () => {
  const [
    exportFinanceListToCsvFunction,
    exportFinanceListToCsvResponseData,
  ] = useLazyQuery(EXPORT_FINANCE_LIST_TO_CSV_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ downloadFetchedFinanceOfferedChartDataInCsv: responseData }) => {
      if (responseData.success) {
        // console.log("res============",responseData)
      } else {
    //    console.log("else-----------------------")
        
      }
    },
  });
  //this will be called from components
  const exportFinanceListToCsvQueryData = exportFinanceListToCsvResponseData; //variable

  const initexportFinanceListToCsvData = (data,successCallback,errorCallback) => {
    exportFinanceListToCsvFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.downloadFetchedFinanceOfferedChartDataInCsv?.success) {
        successCallback(res);
      } else {
        console.log('res error', res);
        errorCallback(res);
      }
    }).catch((err) => {
      console.log('res err', err);
      errorCallback(err);
    });
  };

  return { exportFinanceListToCsvQueryData, initexportFinanceListToCsvData };
};
