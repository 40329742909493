import React, { createRef, useEffect, useRef, useState } from "react";
import { ReactComponent as BackArrow } from "../../../images/back_arrow.svg";
import "./treatment-listing.scss";
import BannerHeader from "../../common/BannerHeader";
import Slider from "react-slick";
import SearchIcon from "../../../images/search_icon.svg";
import TreatmentItem from "./TreatmentItem";
import CustomModal from "../../modal/CustomModal/CustomModal";
import SearchModal from "../../modal/SearchModal/SearchModal";
import defaultImg from "../../../images/default-clinic-image.png";
import StepButton from "../../common/StepButton";
import Star from "../../../images/star.svg";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";
import useScrollTab from "../../common/useScrollTab";
import { Scrollspy } from "@makotot/ghostui";
import useDeviceDetect from "../../common/useDeviceDetect";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LocationIcon } from "../../../images/location_icon.svg";


const TreatmentListMobile = ({
  setStep,
  step,
  treatmentData,
  isLoading,
  sectionRefs,
  setSelectedTreatment,
  selectedTreatment,
  setSelectedChecked,
  selectedChecked,
  setSelectedTotal,
  selectedTotal,
  setSubStep,
  subStep,
  selectedLocation,
  setSelectedLocation,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const searchTextRef = useRef(null);
  const sliderRef = createRef();
  const parentDiv = useRef(null);
  const [noItemsFound, setNoItemsFound] = useState(null)
  const [slide, setSlide] = useState(0);
  //const [selectedTreatments, setSelectedTreatments] = useState([]);
  //const treatmentData=useSelector((state)=>state.businessTreatmentsListReducer?.newTreatmentList)
  const [checkedState, setCheckedState] = useState([]);
  const [checkedValues, setCheckedValues] = useState({});
  const [total, setTotal] = useState(0);
  //const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [focused, setFocused] = useState(false);
  const onBlur = () => setFocused(false);
  const [empty, setEmpty] = useState(false);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [treatmentDetails, setTreatmentDetails] = useState({
    slug: "",
    treatmentId: 0,
    categoryId: 0,
  });

  const [dataExist, setDataExist] = useState();


  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const { treatmentList } = useSelector((state) => state.businessTreatmentsListReducer)
  const { isLastStep } = useSelector((state) => state.businessLocationListReducer);

  //default image
  const defaultImage = "https://dev.facesconsent.com:3126/static/images/default-clinic-image.png"

  useEffect(() => {
    if (treatmentData?.length >= 0) {
      setDataExist(true);
      setTimeout(() => {
        setDataExist(false);
      }, 400);
    }
  }, [treatmentData]);

  const modalRef = useRef(null);
  const { screenWidth } = useDeviceDetect();
  const [offset, setOffset] = useState(null);
  const onFocus = (e) => {
    if (e.currentTarget === document.activeElement) {
      setFocused(true);
    } else {
      setFocused(false);
    }
  };
  const [isOpen, setIsOpen] = useState(focused);
  const { activeTab, handleTabClick, handleScroll, setActiveTab } =
    useScrollTab(treatmentData);
  const truncateString = (str) => {
    return str?.length > 10 ? str?.substring(0, 60) + "..." : str;
  };

  function findTreatment(categoryId, treatmentId) {
    const category = treatmentData?.find(
      (item) => item.category_id === categoryId
    );
    if (category) {
      const treatment = category.treatments.find((t) => t.id === treatmentId);
      if (treatment) {
        // Append the found treatment to the selectedTreatments array
        setSelectedTreatment((prevSelectedTreatments) => [
          ...prevSelectedTreatments,
          treatment,
        ]);
      }
    }
  }

  const handleOpenModal = (slug, categoryId, treatmentId) => {
    setTreatmentDetails({
      slug: slug,
      treatmentId: treatmentId,
      categoryId: categoryId,
    });
    setIsOpen(true);
  };
  const [filterList, setFilterList] = useState([]);
  //const [noItemsFound, setNoItemsFound] = useState(false)
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearch(query);
    if (query.trim() !== "") {
      const filteredList = treatmentData?.map((item) => {
        const treatmentsInCategory = item?.treatments?.filter((data) => {
          return (
            data.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
            item.category_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
          );
        });

        return {
          ...item,
          treatments: treatmentsInCategory,
        };
      });
      if ((filteredList.map(i => i.treatments)).flat().length > 0) {
        setFilterList(filteredList);
        setNoItemsFound(false);
      }
      else {
        setNoItemsFound(true);
      }
    } else {
      setNoItemsFound(false);
      setFilterList(treatmentData);
    }

  };

  useEffect(() => {
    // Set filterList to the initial treatmentData when the component first loads
    setFilterList(treatmentData);
  }, [treatmentData]);

  // useEffect(() => {
  //   if (search.trim().length === 0) {
  //     setEmpty(true);
  //   } else {
  //     setEmpty(false);
  //   }
  // }, [search]);

  const handleClear = () => {
    setSearch("");
  };


  // useEffect(() => {
  //   if (search.trim().length === 0) {
  //     setEmpty(true);
  //   } else {
  //     setEmpty(false);
  //   }
  // }, [search]);

  const handleContinue = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      if (isLastStep) {
        setStep(5)
        return
      }
      setStep(3)
      setSubStep(301)
    }, 2000);
  };

  var settings1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          className: "center",
          // dots: true
        },
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          className: "center",
          initialSlide: selectedCategory,
        },
      },
      {
        breakpoint: 576,
        beforeChange: (prev, next) => {
          setActiveTab(next);
        },
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 414,

        settings: {
          beforeChange: (prev, next) => {
            setActiveTab(next);
          },

          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 390,

        settings: {
          beforeChange: (prev, next) => {
            setActiveTab(next);
          },
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  useEffect(() => {
  }, [activeTab]);

  useEffect(() => {
  }, [treatmentData]);


  const handleChangeCategory = (item) => {
    let categoryItem = document.getElementById(item.category_id.toString());
    setSelectedCategory(item.category_id)
    parentDiv.current.scroll({
      top: categoryItem.offsetTop - 80,
      behavior: 'smooth'
    })
  };

  const handleCheckboxChange = (categoryId, treatmentId) => {

    setSelectedTreatment((prevSelectedTreatments) => {
      const category = treatmentData?.find(
        (item) => item?.category_id === categoryId
      );
      const treatment = category?.treatments?.find(
        (t) => t?.id === treatmentId
      );
      // find if treatment is already part of prevSelectedTreatments
      const existingTreatmentObj = prevSelectedTreatments.find((t) => t.id === treatmentId);

      if (treatment) {
        // If the treatment is checked, add it to the selectedTreatments array
        if (existingTreatmentObj) {
          return prevSelectedTreatments.filter((t) => t.id !== treatment.id);
        }
        return [...prevSelectedTreatments, treatment];
      }

      return prevSelectedTreatments;
    });
  };

  const calculateTotalPrice = (selectedTreatment) => {
    let total = 0;
    for (const treatment of selectedTreatment) {
      total += treatment.amount;
    }
    setTotal(total);
    setSelectedTotal(total)
  };

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }
  useEffect(() => {
    // Calculate the total price when the checkedValues state changes
    calculateTotalPrice(selectedTreatment);
    // convert the selectedTreatment array to an object with the treatment id as the key
    const newCheckedValues = {};
    selectedTreatment.forEach((treatment) => {
      newCheckedValues[treatment.id] = treatment;
    });
    setCheckedValues(newCheckedValues);
  }, [selectedTreatment]);

  const { width, height } = useWindowDimensions();
  var autoScroll;

  if (width <= 576) {
    autoScroll = 1
  } else if (width > 576 && width <= 834) {
    autoScroll = 1
  } else if (width > 834 && width <= 1025) {
    autoScroll = 3
  } else {
    autoScroll = 5
  }

  const handleSlickNext = (data) => {
    setSlide(data);
    console.log("data slide", data, slide)
    sliderRef?.current?.slickGoTo(data);

  };
  const handleSlickPrev = (data) => {
    setSlide(data);
    sliderRef?.current?.slickGoTo(data);
  };

  useEffect(() => {
    if (sectionRefs) {

    }
  }, [sectionRefs])

  useEffect(() => {
    if (searchParams.get("treatment_id") && treatmentData?.length > 0) {
      treatmentData?.map((item, index) => {
        item?.treatments?.map((treatment, tIndex) => {
          if (treatment.id == treatmentList?.treatment_DB_id) {
            // selectedTreatment.push(treatment)
            setSelectedTreatment((prevSelectedTreatments) => {
              return [...prevSelectedTreatments, treatment]
            })
          }
        })
      })
      searchParams.delete("treatment_id")
      setSearchParams(searchParams)
    }
  }, [treatmentData])


  useEffect(() => {
    // Handle the back button click event
    window.onpopstate = function (event) {
      if (window.location.search !== "") {
        window.history.go(-2)
      }

    };
  }, [])

  const dimensions = useWindowDimensions();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [BtnHeight, setBtnHeight] = useState(0);

    useEffect(() => {

        setHeaderHeight(
            document.getElementsByClassName("header-height-calc")[0]?.offsetHeight || 0
        );

        setBtnHeight(
            document.getElementsByClassName("fixed-button")[0]?.offsetHeight
        );


        window.addEventListener("resize", handleResize);
    }, [headerHeight, BtnHeight,document.getElementsByClassName("header-height-calc")?.length,document.getElementsByClassName("fixed-button")?.length]);

    const handleResize = () => {

        setHeaderHeight(
            document.getElementsByClassName("header-height-calc")[0]?.offsetHeight || 0
        );

        setBtnHeight(
            document.getElementsByClassName("fixed-button")[0]?.offsetHeight
        );

    };

  console.log(dimensions.height, headerHeight,BtnHeight, 'dimensions');


  return (
    <div className="container">
      <div className="treatment-mobile-container">


        {sectionRefs?.length > 0 ? (
          <Scrollspy
            sectionRefs={sectionRefs}
            offset={-100}
            rootSelector="#my-list-root"
          >
            {({ currentElementIndexInViewport, elementsStatusInViewport }) => (
              <>


                {currentElementIndexInViewport > slide &&
                  currentElementIndexInViewport % autoScroll == 0 &&
                  currentElementIndexInViewport != 0 &&
                  handleSlickNext(currentElementIndexInViewport)}
                {currentElementIndexInViewport < slide &&
                  handleSlickPrev(currentElementIndexInViewport)}
                <div className="header-height-calc" style={isOpen ? { position: 'unset', zIndex: 0 } : {}}>
                  {
                    // !isLastStep &&
                    <BackArrow onClick={() => { setSearchParams({ ...searchParams, isNotBypass: true }); setStep(1) }} className={`${isLastStep ? "invisible" : ""}`} />
                  }
                  <div className="clinic-name-section-div">
                    <div className="row">
                      <div className="col-10 align-self-center">
                        <h4 className="m-0"> {selectedLocation?.clinic_name}</h4>
                      </div>
                      <div className="col-2">
                        <div className="location-img">
                          {selectedLocation?.image_url === defaultImage ?  
                            <img src={defaultImg} alt="locationImage" /> :  <img src={selectedLocation?.image_url} alt="" />
                          }
                          {/* <LocationIcon /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <BannerHeader title="Treatments" />
                    <Slider arrows={false}
                      className="tab-container"
                      {...settings1}
                      ref={sliderRef}
                    >
                      {filterList?.map((item, index) => {
                        return dataExist ? (
                          <SkeletonLoader type={"tab"} showCircle={false} />
                        ) : (
                          <div className="categories-tab text-center" onClick={() => {
                            handleChangeCategory(item, index);
                          }}>
                            <p

                              className={
                                currentElementIndexInViewport == index
                                  ? "active-tab"
                                  : "inactive-tab"
                              }

                            >
                              {capitalizeFirstLetter(item?.category_name)}
                            </p>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className="search-treatment-mobile">
                    <input
                      type="text"
                      id="search"
                      name="search"
                      value={search}
                      onChange={handleSearch}
                      placeholder="Search for treatment"
                    />
                    <img src={SearchIcon} alt="search" />
                  </div>
                </div>

                <div
                  className="main-list-container top-extra-class"
                  ref={parentDiv}
                  id="my-list-root"
                  style={{height: dimensions.height - headerHeight - BtnHeight  }}

                >
                  {dataExist ? (
                    <SkeletonLoader
                      type={"list-treatment"}
                      showCircle={false}
                    />
                  ) : (
                    noItemsFound ?
                      <div>
                        <div className="fw-bold d-flex justify-content-center align-items-center mt-10">sorry!, your Search Items is not found</div>
                      </div> :

                      filterList?.map((item, index) => (
                        <div ref={sectionRefs[index]}>
                          <div
                            className="treatment-cate-title"
                            key={item?.category_id}
                            id={item?.category_id?.toString()}
                          >
                            <p style={index === 0 ? { marginTop: "6rem" } : { marginTop: "3.8rem" }}>{capitalizeFirstLetter(item.category_name)}</p>
                            <hr style={{ margin: 0 }} />
                          </div>
                          <div>
                            {item?.treatments?.length > 0 ? (item?.treatments?.map((treatment, tIndex) => (
                              <div className=" mt-4 mb-0" onClick={() =>
                                handleCheckboxChange(
                                  item?.category_id,
                                  treatment.id
                                )
                              }>
                                <div className={`treatment-item ${checkedValues[treatment?.id] ? 'selected' : ''}`}>
                                  <div className="row">
                                    <div className=" col-8 cus-575-auto">
                                      <div className="treatment-with-checkbox ">
                                        <h1
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            handleOpenModal(
                                              treatment.slug, item.category_id, treatment?.id, treatment
                                            )
                                          }
                                          }
                                        >
                                          {capitalizeFirstLetter(treatment.name)}
                                        </h1>

                                      </div>

                                      <h2>{`£${treatment.amount}.00 /  ${treatment.duration} mins`}</h2>
                                      <p
                                      // onClick={() =>
                                      //   handleCheckboxChange(
                                      //     item?.category_id,
                                      //     treatment.id
                                      //   )
                                      // }
                                      >
                                        {truncateString(treatment.description)}
                                      </p>
                                      
                                    </div>
                                    <div className="col-3 cus-575-auto d-flex flex-column justify-content-between align-items-end">
                                    <div className="rating-container cus-rating-container">
                                        <img src={Star} alt="star" />
                                        <p>{`${treatment.average_review_rating} (${treatment?.total_review_count})`}</p>
                                      </div>
                                      {/* <input
                                        className="black-checkbox"
                                        id={`category-${treatment?.treatment_id}`}
                                        type="checkbox"
                                        checked={checkedValues[treatment?.id] || false}
                                        // onChange={() => handleCheckboxChange(item?.category_id, treatment.id)}
                                        style={{ background: "red" }}
                                      /> */}
                                    </div>
                                  </div>

                                </div>
                              </div>
                            )
                            )
                            ) :
                              <p>No items found in category</p>
                            }
                          </div>
                        </div>
                      ))
                  )}
                </div>
              </>
            )}
          </Scrollspy>
        ) : null}
        <div className="step-button fixed-button">
          {
            !isOpen ? <StepButton
              blue={true}
              label={isLastStep ? `Continue to Checkout` : selectedTotal === 0 ? `Continue` : `Continue • £${selectedTotal}.00`}
              isLoading={loader}
              disabled={selectedTreatment.length === 0 ? true : false}
              onClick={handleContinue}
            />
              :
              null
          }
        </div>
        <SearchModal
          className={" about-treatment-modal"}
          setStep={setStep}
          step={step}
          treatmentDetails={treatmentDetails}
          ref={modalRef}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleClick={handleCheckboxChange}
        />
      </div>
    </div>
  );
};

export default TreatmentListMobile;
