import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const VERIFY_CODE_SENT_IN_EMAIL = gql`
mutation VerifyFinanceEmailVerificationCode($email: String, $code: String, $deviceToken: String, $deviceName: String) {
    verifyFinanceEmailVerificationCode(email: $email, code: $code, deviceToken: $deviceToken, deviceName: $deviceName) {
      success
      message
      data {
        user {
          usr_id
          usr_fname
          usr_lname
          usr_name
          usr_email
          usr_email_verified
          usr_country_name
          usr_country_code
          usr_phone_no
          usr_phone_country
          usr_phone_verified
          usr_dob
          usr_last_login
          usr_last_login_type
          usr_country
          usr_company_name
          usr_no_of_employees
          usr_vat_no
          userRoles
          image_url
        }
        token
      }
    }
  }
`;

export const VerifyCodeSentInEmail = () => {
    // const dispatch = useDispatch()
    const [verifyCodeSentInEmailFunc, verifyCodeSentInEmail] = useMutation(VERIFY_CODE_SENT_IN_EMAIL);
    const verifyCodeSentInEmailData = verifyCodeSentInEmail;

    const initiateVerifyCodeSentInEmail = (
        data,
        successCallback,
        errorCallback
    ) => {
        verifyCodeSentInEmailFunc({
            variables: data,
        })
            .then(({ data: { verifyFinanceEmailVerificationCode: responseData } }) => {
                if (responseData?.success) {
                    toast.success(responseData.message)
                    successCallback(responseData);
                } else {
                    // toast.error(responseData.message)
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                errorCallback(error);
            });
    };

    return { initiateVerifyCodeSentInEmail, verifyCodeSentInEmailData }
}