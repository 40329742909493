// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { GET_APPOINTMENT_DETAILS_ERROR, GET_APPOINTMENT_DETAILS_SUCCESS, GET_APPOINTMENT_LIST_SUCCESS, GET_CLIENTS_DATA_SUCCESS, GET_ONBOARDING_STATUS_ERROR, GET_ONBOARDING_STATUS_SUCCESS } from "../../constants";

const GET_CLIENT_LIST_QUERY = gql`
  query GetClientLists($page: Int, $limit: Int, $search: String) {
    getClientLists(page: $page, limit: $limit, search: $search) {
      success
      message
      data {
        file_url
        total
        elements {
          cl_dt_id
          cl_dt_name
          cl_dt_email
        }
      }
    }
  }
`;

export const GetClientListsAction = () => {
  const dispatch = useDispatch();
  const [
    getClientListsResponseFunction,
    getClientListsResponseData,
  ] = useLazyQuery(GET_CLIENT_LIST_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getClientLists: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: GET_CLIENTS_DATA_SUCCESS,
          payload: responseData,
        });
        console.log('dispatched GetClientListsAction');
        // toast.success(responseData?.message)
        // GET_ONBOARDING_STATUS_SUCCESS
      } else {
        // dispatch({
        //   type: GET_CLIENTS_DATA_SUCCESS,
        //   payload: responseData,
        // })
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getClientListsQueryData = getClientListsResponseData; //variable

  const initGetClientListsData = (data) => {
    getClientListsResponseFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.getClientLists?.success) {
        console.log('res success', res);
        // successCallback(res);
      } else {
        console.log('res error', res);
        // errorCallback(res);
      }
    }).catch((err) => {
      console.log('res err', err);
      // errorCallback(err);
    });
  };

  return { getClientListsQueryData, initGetClientListsData };
};
