import { useDropzone } from "react-dropzone";
import "./dropzone.scss";
import { useState, useEffect } from "react";
import imageIcon from "../../images/Image.svg";
import closeIcon from "../../images/close_icon.svg";
import CustomModal from "../modal/CustomModal";
import deleteIcon from "../../images/deleteIcon.svg";
import { toast } from "react-toastify";
import { IoEyeSharp } from "react-icons/io5";
import ConfirmationModal from "../pages/finance-identity/finance-confirmationModal";
import showToast from "../common/CustomToast/CustomToast";
import { ReactComponent as Uploads } from "../../images/Image.svg"

const Dropzone = ({
  otherDocs,
  imageFromComponent,
  fileData,
  urlFromDB,
  handleDelete,
  id,
  handleMulti,
  selectedIndex,
  imageTitle,
  setIdentityUrl,
  insuranceId,
  usrVrfId,
  // validation
}) => {
  const [imgUrl, setImgUrl] = useState("");
  const [file, setFile] = useState([]);
  const [open, setOpen] = useState(false);
  const [imgPreview, setImgPreview] = useState(false);

  useEffect(() => {
    console.log("urlllllll", urlFromDB, "????", imgUrl)
    setImgUrl(urlFromDB);
    console.log(urlFromDB, "here called");
  }, [urlFromDB]);

  useEffect(() => {
    if (imageFromComponent instanceof File && handleMulti) {
      setFile([imageFromComponent]);

    } else {
      setFile([]);
    }
  }, [imageFromComponent]);


  // useEffect(() => {
  //   if (file.length > 0) {
  //     validation.image = ""
  //   }
  //   // console.log(validation.image = "", "0102021")

  // }, [file]);
  // useEffect(() => {
  //   if (file.length > 0) {
  //     validation.image = "";
  //   }
  // }, [file]);
  // const onDrop = (acceptedFiles) => {
  //   setFile([...file, ...acceptedFiles]);
  //   if (!handleMulti) {
  //     fileData(acceptedFiles[0]);
  //   } else {
  //     handleMulti((prevOtherDocs) => {
  //       const updatedDocs = [...prevOtherDocs];
  //       updatedDocs[selectedIndex] = {
  //         ...updatedDocs[selectedIndex],
  //         image: acceptedFiles[0],
  //       };
  //       return updatedDocs;
  //     });
  //   }
  // };

  console.log("file11", file)
  // const onDrop = (acceptedFiles) => {

  //   console.log('hello from dropzone here1')
  //   if (typeof handleMulti === 'undefined' && typeof selectedIndex === 'undefined') {
  //     setFile([...file, ...acceptedFiles]);
  //     fileData(acceptedFiles[0]);
  //   }

  //   // Find the index with an empty image property and an imageUrl property
  //   const emptyIndex = otherDocs.findIndex(
  //     (doc) => !doc.image && !doc.image_url && !doc.certificate_url && !doc.document_url
  //   );

  //   if (!handleMulti) {
  //     console.log('hello from dropzone here2')
  //     fileData(acceptedFiles[0]);
  //     setFile([...file, ...acceptedFiles]);
  //   } else {
  //     console.log('hello from dropzone here3')
  //     handleMulti((prevOtherDocs) => {
  //       const updatedDocs = [...prevOtherDocs];
  //       if (emptyIndex !== -1) {
  //         // Update the document at the empty index
  //         updatedDocs[emptyIndex] = {
  //           ...updatedDocs[emptyIndex],
  //           image: acceptedFiles[0],
  //         };
  //       }
  //       return updatedDocs;
  //     });
  //   }
  // };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const uploadFile = acceptedFiles[0];

      // Check if the file type is either PDF or starts with "image/"
      if (
        uploadFile.type === "application/pdf" ||
        uploadFile.type.startsWith("image/")
      ) {
        // Set the uploaded file if it's a valid type

        // Check if handleMulti and selectedIndex are undefined
        if (typeof handleMulti === "undefined" && typeof selectedIndex === "undefined") {
          fileData(uploadFile);
          setFile([...file, uploadFile]);
        } else {
          // Find the index with an empty image property and an imageUrl property
          const emptyIndex = otherDocs.findIndex(
            (doc) =>
              !doc.image &&
              !doc.image_url &&
              !doc.certificate_url &&
              !doc.document_url
          );

          if (!handleMulti) {
            // If handleMulti is not defined, set fileData and setFile
            fileData(uploadFile);
            setFile([...file, uploadFile]);
          } else {
            // If handleMulti is defined, update the document at the empty index
            handleMulti((prevOtherDocs) => {
              const updatedDocs = [...prevOtherDocs];
              if (emptyIndex !== -1) {
                // Update the document at the empty index
                updatedDocs[emptyIndex] = {
                  ...updatedDocs[emptyIndex],
                  image: uploadFile,
                };
              }
              return updatedDocs;
            });
          }
        }
      } else {
        // If the file type is not valid, show an error message
        showToast("error", "You can only upload image or PDF files.", "", false);
      }
    } else {
      // If no files are dropped, show an error message
      showToast("error", "You can only upload image or PDF files.", "", false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/jpeg", "image/png", "application/pdf"],
    onDrop,
  });

  const openPDF = () => {
    const blob = new Blob(file, { type: "application/pdf" });
    const url = URL?.createObjectURL(blob);
    window.open(url);
  };

  const openPdf = () => {
    window.open(urlFromDB);
  };
  console.log("setImgUrl", imgUrl, selectedIndex, urlFromDB)
  return (
    <section className="main-container">
      {imgUrl ? (
        <div className="preview">
          <div className="image-preview">
            {imgUrl?.endsWith(".pdf") ? (
              // <img src={imgUrl} alt="Uploaded document" onClick={openPdf} />
              <span className="pdfSpan" onClick={openPdf}>
                <i class="fa-regular fa-file-pdf  pdfIcon"></i>
                {/* Uploaded document */}
              </span>
            ) : (
              <img
                src={imgUrl}
                alt="Uploaded document"
                onClick={() => setImgPreview(true)}
              />
            )}
            <div className="btnClose1">
              <img
                className="closeBtn"
                src={closeIcon}
                alt="Delete"
                onClick={() => setOpen(true)}
              />
            </div>
          </div>
        </div>
      ) : file?.length > 0 ? (
        <div className="preview">

          {file?.map((item, index) => {
            console.log("index", index)
            return (
              <div key={index} className="image-preview">
                {item?.type?.startsWith("image/") ? (
                  <img
                    src={URL?.createObjectURL(item)}
                    alt={item.name}
                    onClick={() => setImgPreview(true)}
                  />
                ) : (
                  <span className="pdfSpan" onClick={openPDF}>
                    <i class="fa-regular fa-file-pdf pdfIcon "></i>
                    {/* {item.name} */}
                  </span>
                )}
                <div className="btnClose1">
                  <img
                    className="closeBtn"
                    src={closeIcon}
                    alt="modalClose"
                    onClick={() => setOpen(true)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {file?.length === 0 && (
            <>
              {/* <img src={placeHolder} alt="" /> */}
              <Uploads />
              <p>Upload</p>
            </>
          )}
        </div>
      )}

      <CustomModal
        modalOpen={imgPreview}
        setModalOpen={setImgPreview}
        type="review"
        className={"previewImgModel"}
        modalBody={
          <>
            <div className="previewImgModelWrapper">
              <img
                className="w-100 h-100"
                src={
                  imgUrl || (file?.length > 0 && URL?.createObjectURL(file[0]))
                }
                alt="Uploaded document"
              />
            </div>

            <div className="RemovePreviewImg">
              <button
                className="OutLineBtn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Remove
              </button>
            </div>
          </>
        }
        modaltitle={imageTitle}
      />

      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        title="Are you sure?"
        className={"ConfirmationDeleteModal"}
        content="Are you sure you want to remove this document?"
        onConfirm={() => {
          if (imgUrl) {
            handleDelete(
              id ? { deleteTrainerImageId: id } :
                usrVrfId ? { usrVrfId: usrVrfId } :
                  insuranceId ? { deleteInsuranceDocumentForFinanceId: insuranceId } : null,
              (res) => {
                console.log("resp", res)

                if (res.message) {
                  toast.success(res.message)
                }
                if (res?.data?.deleteTrainingCertificateForFinance?.message) {
                  toast.success(res?.data?.deleteTrainingCertificateForFinance?.message)
                }
                if (res?.data?.deleteInsuranceDocumentForFinance?.message) {
                  toast.success(res?.data?.deleteInsuranceDocumentForFinance?.message)
                }
                if (!urlFromDB) {
                  setImgUrl(null);

                }
                if (fileData) {
                  setImgUrl(null);
                }
                if (setIdentityUrl) { setIdentityUrl('') }

              },
              (error) => {
                toast.error(error.message);
              }
            );
          }
          setFile([]);
          if (handleMulti) {
            handleMulti((prevOtherDocs) => {
              const updatedDocs = [...prevOtherDocs];
              const filteredDocs = updatedDocs.filter(
                (doc, index) => index !== selectedIndex
              );
              return filteredDocs;
            });
            // if (handleMulti) {
            //   handleMulti((prevOtherDocs) => {
            //     const updatedDocs = [...prevOtherDocs];
            //     const updatedDoc = { ...updatedDocs[selectedIndex] };
            //     updatedDoc.image = null;
            //     usrVrfId ? updatedDoc.certificate_title = " ":insuranceId? updatedDoc.document_title="":updatedDoc.title="";
            //     updatedDoc.image_url = '';
            //     updatedDocs[selectedIndex] = updatedDoc;
            //     console.log("ppppppp",updatedDocs)
            //     return updatedDocs;
            //   });
          } else {
            fileData({});
          }
          setOpen(false);
          setImgPreview(false);
        }}
        onCancel={() => setOpen(false)}
      />
    </section>
  );
};

export default Dropzone;