import React, { useEffect, useRef, useState } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import { validName, validPersonalWebsiteUrl } from "../../../../utils/Regex";
import CustomeLoading from "../../../customeSpiner";
import { useSelector } from "react-redux";

import toast from "react-hot-toast";
import ToastbarComponent from "../../../common/ToasterComponent";
import AutosizeInput from "react-input-autosize";
import successIcon from "../../../../images/success.svg";
import failureIcon from "../../../../images/Icon example.svg";

const InputFieldsComponent = ({
  businessLabel,
  websiteLabel,
  businessNamePlaceholder,
  websiteURLPlaceholder,
  dataaos,
  dataAosDuration,
  setInputCount,
  inputCount,
  tempSaveBusinessData,
  webDetailsLoading,
  editStep,
  setTempSaveBusinessData,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const [startLoader, setStartLoader] = useState(false);
  const [inputChanged, setInputChanged] = useState(false);
  const placeholder = "yourwebsitename";

  // const postfix = '.facesconsent.com'

  const spaceRegex = /^\S*$/;
  const capsRegex = /[A-Z]/;
  const [editableText, setEditableText] = useState("");
  const [copyText, setCopyText] = useState("");
  const textRef = React.useRef(null);
  const [isTextInputClicked, setIsTextInputClicked] = useState(false);

  // const [tempUrlInput, setTempUrlInput] = useState('');
  const checkWebsiteDomainResponseData = useSelector(
    (state) => state.WebsiteDetailsReducer.checkWebsiteDomainResponseData
  );
  console.log("checkWebsiteDomainResponseData", checkWebsiteDomainResponseData);

  useEffect(() => {
    resizeInput();
  }, [inputValue]);

  const handleKeyDown = (e) => {
    if (e.key === "Spacebar") {
      e.preventDefault(); // Prevent deletion if the input is just the postfix
    }
    console.log("e.key", e.target.value);
    if (e.key === "Backspace" && e.target.value === ".facesconsent.com") {
      e.preventDefault(); // Prevent deletion if the input is just the postfix
    }
  };

  const handleChange = (e) => {

    if (
      validPersonalWebsiteUrl.test(
        e.target.value.replace(".facesconsent.com", "")
      ) ||
      e.target.value.replace(".facesconsent.com", "") === ""
    ) {
      // Prevent deletion if the input is just the postfix

      const { value } = e.target;
      let modValue = value;

      setInputChanged(true);
      if (spaceRegex.test(e.target.value)) {

        setCopyText(modValue?.toLowerCase());

        props.setWebsiteData({ ...props.websiteData, websiteUrl: modValue?.toLowerCase() });

        setStartLoader(true);

        // if (modValue.length > 2) {
        //   props.checkDomain({ subdomainName: modValue?.toLowerCase() });
        // }
      }
    }

  };

  useEffect(() => {
    console.log("props.websiteData.websiteUrl", props.websiteData.websiteUrl);
    if (props.websiteData?.websiteUrl?.length > 0) {
      setCopyText(props.websiteData?.websiteUrl);
      // setInputCount(1);
      // props.setWebsiteData({...props.websiteData,websiteUrl: a });
    }
  }, [props.websiteData?.websiteUrl, webDetailsLoading]);

  useEffect(() => {
    if (props.loading) {
      setStartLoader(true);
    } else {
      setStartLoader(false);
    }
  }, [props.loading]);

  const resizeInput = () => {
    if (inputRef.current) {
      inputRef.current.style.width = inputValue.length * 6 + 30 + "px";
    }
  };

  console.log("props.websiteData.websiteUrl out", copyText);

  useEffect(() => {
    console.log("props.websiteData.websiteUrl", props.websiteData.websiteUrl);
    if (props.websiteData?.websiteUrl?.length > 0 && editStep) {
      setCopyText(props.websiteData?.websiteUrl);
      // setInputCount(1);
      // props.setWebsiteData({...props.websiteData,websiteUrl: a });
    }
  }, [props.websiteData?.websiteUrl, webDetailsLoading]);

  useEffect(() => {
    if (props.loading) {
      setStartLoader(true);
    } else {
      setStartLoader(false);
    }
  }, [props.loading]);


  return (
    <div className="formWrapper">
      <div className="col-lg-12">
        <Form>
          <FormGroup
            className="inpt_field"
            data-aos={dataaos}
            data-aos-duration={dataAosDuration}
          >
            <Label className="lable">{businessLabel}</Label>
            <input
              className="input"
              type="text"
              name="businessName"
              id="businessName"
              placeholder={businessNamePlaceholder}
              value={props.websiteData.businessName}
              onChange={(e) => {
                validName.test(e.target.value) &&
                  props.setWebsiteData({
                    ...props.websiteData,
                    businessName: e.target.value,
                  });
              }}
            />
          </FormGroup>
          {
            editStep && (
              <FormGroup
                className="inpt_field"
                data-aos={dataaos}
                data-aos-duration={dataAosDuration}
              >
                <Label className="lable">{websiteLabel}</Label>
                <div className="comInputWrapper">
                  <AutosizeInput
                    type="text"
                    value={`${copyText}`}
                    autoComplete="off"
                    placeholder={placeholder}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    name="websiteURL"
                    id="websiteURL"
                    maxWidth={318}

                  />

                  {props.websiteData?.websiteUrl?.length > 2 && (
                    <div className="loaderSpiner">
                      {startLoader ? (
                        <CustomeLoading />
                      ) : checkWebsiteDomainResponseData === false ? (
                        <img
                          onClick={() => {
                            setCopyText("");
                            props.setWebsiteData({
                              ...props.websiteData,
                              websiteUrl: "",
                            });
                          }}
                          src={failureIcon}
                        />
                      ) : (
                        copyText && <img src={successIcon} />
                      )}
                    </div>
                  )}
                  <label>.facesconsent.com</label>
                </div>
              </FormGroup>
            )
          }
        </Form>
      </div>
      <ToastbarComponent toast={toast} />
    </div>
  );
};

export default InputFieldsComponent;
