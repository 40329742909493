import { FALSE } from "sass"
import { CLEAR_FORM_API_CALLED, RESEND_FORWARD_CONSENT_FORM_SUCCESS } from "../../constants"


const initialState = {
    resendFormSuccessStatus: false,
    resendFormSuccessMessage: null,
    resendFormApiCalled: false,
}
export const formsReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESEND_FORWARD_CONSENT_FORM_SUCCESS:
            return {
                ...state,
                resendFormSuccessStatus: action.payload.success,
                resendFormSuccessMessage: action.payload.message,
                resendFormApiCalled: true
            }

        case CLEAR_FORM_API_CALLED:
            return {
                ...state,
                resendFormApiCalled : false,
                resendFormSuccessStatus: false,
                // resendFormSuccessMessage: null,
            }
        default:
            return state
    }
}  