import React from 'react'
import './custombutton.scss'

const CustomButton = ({ onClick, label, className, disabled }) => {

  return (
    <div className='custom-button-div'>
      <button disabled={disabled} onClick={onClick} className={className}>
        {label}
      </button>
    </div>
  )
}

export default CustomButton
