import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import {  webBaseUrl } from '../../../config';
import SkeletonImage from '../skeleton-image/skeleton-image';
import './carousel-component.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'

// ....
function CarouselComponent({ CarouselComponentList, data, nav, loop,isManualItem = 3 }) {
    const responsiveObj = {
        0: {
            items: 1
        },
        500: {
            items: 2
        },
        700: {
            items: 2
        },
        950: {
            items: isManualItem
        },
        1400: {
            items: isManualItem
        }
    };
    return (
        <>
            {
                // CarouselComponentList.length > 0 &&
                <OwlCarousel
                    className='owl-theme'
                    loop={loop != undefined ? loop : true}
                    margin={20}
                    nav={nav ? false : true}
                    dots={false}
                    responsiveClass={true}
                    responsive={responsiveObj}
                >
                    {
                        nav ? data :
                            (
                                CarouselComponentList && CarouselComponentList?.map(insurance => (
                                    <div className="item slider-item-container text-center" key={insurance.insr_id}>
                                        <a className="slider-item-nav" href={webBaseUrl + "/insurance/company/" + insurance.insr_id}>
                                            {insurance.logo_url ? (
                                                <img src={insurance.logo_url} className="slider-logo" alt={insurance.insr_name} />
                                            ) : (
                                                <SkeletonImage skeletonHeight="50" skeletonWidth="50" skeletonClassName="slider-logo" />
                                            )}
                                            <p className="slider-title" >
                                                <span className='d-flex justify-content-center' data-tooltip-id={"full-name-show-"+insurance.insr_id} data-tooltip-content={insurance.insr_name} data-placement="bottom">
                                                    {insurance.insr_name} 
                                                    
                                                    <Tooltip id={"full-name-show-"+insurance.insr_id} style={{ fontSize: '12px' }} />
                                                </span>
                                            </p>
                                            <div className={`d-flex justify-content-center get-quote-btn`}>

                                                <span>Get Quote</span>
                                            </div>
                                        </a>
                                    </div>
                                )))

                    }
                </OwlCarousel>
            }
        </>
    );
}
export default CarouselComponent;