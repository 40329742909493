import { gql, useMutation } from "@apollo/client";


const PURCHASE_WEBSITE_BUILDER_PACKAGE_MUTATION = gql`
mutation PurchaseWebsitePackage($packageId: Int, $packageSlug: String, $sessionId: String, $generatePaymentIntent: Boolean, $canUpgragePlan: Boolean, $userToken: String) {
    purchaseWebsitePackage(packageId: $packageId, packageSlug: $packageSlug, sessionId: $sessionId, generatePaymentIntent: $generatePaymentIntent, canUpgragePlan: $canUpgragePlan, userToken: $userToken) {
      message
      success
      data {
        packageReferenceId
        intentId
        packageId
        app_payment_url
        setupIntentId
        planUpgrade
        userInfo {
          user_fname
          user_lname
          user_email
          user_phone_number
        }
      }
    }
  }
 `;


export const PurchaseWebsiteBuilderPackageAction = () => {
    const [PurchaseWebsiteBuilderPackageResponseFunction, purchaseWebsiteBuilderPackageResponseData] =
        useMutation(PURCHASE_WEBSITE_BUILDER_PACKAGE_MUTATION)

    const purchaseWebsiteBuilderPackageQueryData = purchaseWebsiteBuilderPackageResponseData

    const initPurchaseWebsiteBuilderPackageData = (data, successCallback, errorCallback) => {
        PurchaseWebsiteBuilderPackageResponseFunction({ variables: data }).then((responseData) => {
            console.log(responseData);
            if (responseData.data.purchaseWebsitePackage.success) {
                // dispatch({
                //     type: SET_BOOKING_DETAILS,
                //     payload: responseData?.data
                // })
                successCallback(responseData)
                // toast.success(responseData.message)
            }
            else {
                errorCallback(responseData)
                // toast.error(responseData.message)
            }
        }).catch((err) => {
            errorCallback(err)
            // toast.error("something went wrong!!!")
        })
    }
    return { purchaseWebsiteBuilderPackageQueryData, initPurchaseWebsiteBuilderPackageData };
}