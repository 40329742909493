import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { ConnectedPrescriberListQuery } from "../../redux/actions/consent-form-thankyou/getConnectedPrescriberList";
import CustomRadioButton from "../../common/CustomRadioButton";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";
import StepButton from "../../common/StepButton";
import { ReactComponent as CloseIcon } from "../../../images/close_icon.svg";
import "./prescriber-list.scss";
import { CLEAR_PRESCRIBER_LIST } from "../../redux/constants/consentFormThankyou/getPrescriberListConst";
import Avatar from "react-avatar";
import { ReactComponent as FillIcon } from "../../../images/icons/fill.svg";
import useWindowDimension from "../../../utils/useWindowDimensions";
import SearchIcon from "../../../images/client/search_icon.svg";
import { debounce } from 'lodash';
import noAppointmentList from '../../../images/icons/appointment-list/noAppointmentList.svg'


export const PrescriberList = ({ sendClickHandler, setIsPrescriberModalOpen, sendConsentFormOrMedicalFormToPrescriberQueryData, prescriberId }) => {

    //prescriber list
    const { prescriberList } = useSelector((state) => state.connectedPrescriberListReducer);
    const { totalPrescriberCount } = useSelector((state) => state.connectedPrescriberListReducer);
    const { loadedPrescriberCount } = useSelector((state) => state.connectedPrescriberListReducer);
    const { prescriberListPage } = useSelector((state) => state.connectedPrescriberListReducer);

    //GET CONNECTED PRESCRIBER LIST QUERY
    const { conntectedPrescriberListQueryData, initConnectedPrescriberList } =
        ConnectedPrescriberListQuery();
    const [tempShowSelectedPrescriber, setTempShowSelectedPrescriber] =
        useState(prescriberId);
    const [checkPrescriberListForLoading, setCheckPresciberListForLoading] =
        useState(true);
    const dispatch = useDispatch(); const [searchText, setSearchText] = useState("");
    const [isSearchTriggered, setIsSearchTriggered] = useState(false)
    

    const initiatePrescriberListCall = (refreshList = false) => {
        let newPage = prescriberListPage + 1;
        if (refreshList) {
            newPage = 1;
        } else if (totalPrescriberCount <= loadedPrescriberCount) {
            return;
        }
        setCheckPresciberListForLoading(false);
        initConnectedPrescriberList(
            {
                limit: 15,
                page: newPage,
                search: searchText
            },
            (res) => {
                if (res.success) {
                } else {
                }
            },
            (err) => { }
        );
    };
    useEffect(() => {
        dispatch({
            type: CLEAR_PRESCRIBER_LIST,
        });
        initiatePrescriberListCall(true);
    }, []);

    const truncateString = (str) => {
        return str?.length > 25 ? str?.substring(0, 25) + "..." : str;
    }

    const handleSearchText = (e) => {
        setSearchText(e.target.value);
        setIsSearchTriggered(true);
    };

    const debouncedApiCall = debounce((query) => {
        // Make the API call using the search query
        // Replace this with your own API call logic
        dispatch({
            type: CLEAR_PRESCRIBER_LIST
        })
        initiatePrescriberListCall(true)
    }, 500);

    useEffect(() => {

        if (isSearchTriggered) {
            // Call the debounced API function with the search query
            debouncedApiCall(searchText);

            // Cleanup function to cancel any pending debounced calls
            return () => {
                debouncedApiCall.cancel();
            };
        }
    }, [searchText])

    const dimensions = useWindowDimension();
    const [headerStickyHeight, setHeaderStickyHeight] = useState(0);
    const [footerButton, setFooterButton] = useState(0);


    useEffect(() => {
        setHeaderStickyHeight(
            document.getElementsByClassName('sticky-header')[0]?.offsetHeight
        );

        setFooterButton(document.getElementsByClassName('sticky-footer-calender')[0]?.offsetHeight)

        window.addEventListener('resize', handleResize);
    }, []);

    const handleResize = () => {
        setHeaderStickyHeight(
            document.getElementsByClassName('sticky-header')[0]?.offsetHeight
        );

        setFooterButton(document.getElementsByClassName('sticky-footer-calender')[0]?.offsetHeight)
    };


    return (
        <div className="MainPrescriberListWrapper">
            <div className="sticky-header">
                <div className="close-btn">
                    <CloseIcon
                        className="close-icon-animation cursor-pointer"
                        onClick={() => {
                            setIsPrescriberModalOpen(false);
                        }}
                    />
                </div>
                <div className='secHeading'>
                    <h1>Select prescriber</h1>
                </div>
                <div className="search-container">
                    <input type="text" placeholder="Search prescriber" value={searchText} onChange={handleSearchText} />
                    <img src={SearchIcon} alt="search-icon" className="search-icon-img" />
                    {searchText?.length > 0 &&
                        <CloseIcon className="cursor-pointer search-close-icon" onClick={() => { setSearchText("") }} />
                    }
                </div>
            </div>
            <div className="select-presciber-container">
                <div
                    id="scrollableDivPrescriber"
                    style={{ height: dimensions.height - headerStickyHeight - footerButton - 30 + "px", width: "100%", overflowY: "scroll" }}
                    className={`${prescriberList?.length === 0 &&
                        !conntectedPrescriberListQueryData?.loading
                        ? "no-prescriber-wrap-text"
                        : ""
                        }`}
                >
                    <InfiniteScroll
                        dataLength={loadedPrescriberCount}
                        next={() => {
                            initiatePrescriberListCall(false);
                        }}
                        hasMore={loadedPrescriberCount < totalPrescriberCount}
                        scrollableTarget="scrollableDivPrescriber"
                    >
                        <div className="select-presciber-wrapper">
                            {prescriberList?.length != 0 ? (
                                prescriberList.map((prescriber, index) => {
                                    return (
                                        <>
                                            <div
                                                className="prescriber-list-wrapper"
                                                onClick={() => {
                                                    setTempShowSelectedPrescriber(
                                                        prescriber.prescriber_id
                                                    );
                                                }}
                                            >
                                                <label
                                                    className={
                                                        tempShowSelectedPrescriber ===
                                                            prescriber?.prescriber_id
                                                            ? "prescription-list-container selectedList"
                                                            : "prescription-list-container"
                                                    }
                                                    style={
                                                        tempShowSelectedPrescriber ===
                                                            prescriber?.prescriber_id
                                                            ? {
                                                                border: "1px solid #000",
                                                                boxShadow:
                                                                    "0px 4px 15px 0px rgba(0, 0, 0, 0.12)",
                                                            }
                                                            : {}
                                                    }
                                                    key={index}
                                                >
                                                    <div className="left-list-content">
                                                        <Avatar
                                                            size={48}
                                                            maxInitials={2}
                                                            fgColor="#000"
                                                            round
                                                            color="#eeeff3"
                                                            src={prescriber?.prescriber_profile}
                                                            name={prescriber?.prescriber_name}
                                                        />
                                                        <div className="content-section">
                                                            <h4>{prescriber?.prescriber_name}</h4>
                                                            <p>
                                                                {prescriber?.prescriber_profession
                                                                    ? truncateString(prescriber?.prescriber_profession
                                                                    ) : " "}
                                                            </p>
                                                            <p>{prescriber?.city}</p>
                                                        </div>
                                                    </div>

                                                    <div className="RightPartRating">
                                                            <FillIcon />
                                                            {prescriber?.rating
                                                                ? prescriber?.rating?.toFixed(2)
                                                                : 0}
                                                    </div>
                                                </label>
                                            </div>
                                        </>
                                    )
                                }))
                                :
                                conntectedPrescriberListQueryData?.loading || checkPrescriberListForLoading ? null :
                                    <div className='noDataFound'>
                                        <img src={noAppointmentList} alt="no appointment" />
                                        <p>No prescriber found</p>
                                    </div>
                            }
                            {
                                conntectedPrescriberListQueryData?.loading ?
                                    <SkeletonLoader type="prescriber-List" />
                                    :
                                    null
                            }
                        </div>
                    </InfiniteScroll>
                </div>

                <div className="sticky-footer-calender">
                    <StepButton label={"Done"} blue={true} onClick={() => {
                        sendClickHandler(tempShowSelectedPrescriber);

                    }}
                        disabled={tempShowSelectedPrescriber ? false : true}
                        isLoading={
                            sendConsentFormOrMedicalFormToPrescriberQueryData?.loading
                        }
                    />
                </div>
            </div>
        </div>
    );
};
