import React, { useEffect, useState } from 'react'
import './ComingSoon.scss'
import { useLocation, useNavigate } from 'react-router';
import { ReactComponent as ComingSoonImg } from '../../images/sprint6/comingSoon.svg'
// import Skeleton from 'react-loading-skeleton'
import { ReactComponent as BackArrow } from "../../images/back_arrow.svg";
import BusinessSettingHeader from '../common/businessSettingHeader/BusinessSettingHeader';
import BusinessSidebar from '../sidebar/BusinessSidebar';
import { businessDashboardRoute, getPaymentsControlPage } from '../../Routes';
import SidebarComponent from '../sidebar';
import BusinessHeader from '../businessHeader';
import Cookies from "js-cookie";
import useWindowDimensions from "../../utils/useWindowDimensions";
import logo from "../../images/icons/faces-logo-black.svg";
import closeIcon from "../../images/Icon example.svg";


const ComingSoon = ({ setCurrentPage }) => {
    const navigate = useNavigate();
    const { width, height } = useWindowDimensions();
    const [showSidebar, setShowSidebar] = useState(true);
    const [activeSidebar, setActiveSidebar] = useState(false);
    // const [isAuth, setIsAuth] = useState(false);
    const location = useLocation();
    let isMobile = false;
    if (width <= 991) {
        isMobile = true;
    }
    else {
        isMobile = false;
    }
    useEffect(() => {
        isMobile ? setShowSidebar(false) : setShowSidebar(true);
    }, [isMobile]);

    // useEffect(() => {
    //     console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
    //     Cookies.get("faces_access_token") ? setIsAuth(true) : setIsAuth(false);
    //     console.log('isAuth', isAuth, Cookies.get("faces_access_token"), typeof Cookies.get("faces_access_token"));
    // });
    return (
        <div>
            <div className="MainBusinessProfile">
                <BusinessHeader
                    // openReveviewPopup={setOpenReviewPopup}
                    openMobileMenu={() => {
                        setShowSidebar(!showSidebar);
                        setActiveSidebar(true);
                        document.body.classList.add('sidebarOpen');
                    }}
                    isMobile={isMobile}
                />
                <div className="Business-Profile-Main-Wrapper container-fluid ">
                    <div className="sidebarDesignUpdateSprint6">
                        {isMobile ? (
                            <div
                                className={
                                    showSidebar ? " MobileSidebar  ActiveSidebar" : "MobileSidebar"
                                }
                            >
                                {activeSidebar && (
                                    <>
                                        <div className="overlaySidebar"></div>
                                        <div className="sidebar">
                                            <div className="sidebarLogoWrapper">
                                                <div className="logoSidebar">
                                                    <img src={logo} alt="logo" />
                                                </div>
                                                <div
                                                    className="sidebarClose"
                                                    onClick={() => {
                                                        setShowSidebar(false);
                                                        document.body.classList.remove('sidebarOpen');
                                                    }}
                                                >
                                                    <img src={closeIcon} alt="closeIcon" />
                                                </div>
                                            </div>
                                            <SidebarComponent />
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : (
                            // isAuth &&
                            <div className="sidebarPart">
                                <div className="sidebar">
                                    <SidebarComponent />
                                </div>
                            </div>
                        )}
                        <div className={"MainContentPart"}>
                            <div className='ComingSoonPage'>
                                <div className='BackIcon' onClick={() => {
                                    if (location?.state?.from === "Finance_settings_paymentcontrol_comingsoon") {
                                        navigate(getPaymentsControlPage())
                                    }
                                    else {
                                        navigate(businessDashboardRoute())
                                    }
                                }}>
                                    <BackArrow />
                                </div>
                                <div className='ComingSoonContentWrapper'>
                                    <div className='ComingSoonImg'>
                                        <ComingSoonImg />
                                    </div>
                                    <div className='ComingSoonContent'>
                                        <h2>Coming soon</h2>
                                        <p>Stay Tuned Big Things Are on the Way!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComingSoon