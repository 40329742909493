

import { CLEAR_AFTERCARE_FORM_LIST, GET_AFTERCARE_FORM_LIST } from "../../constants/formsTemplate/FormTemplateConst";

const initialState = {
  afterCareFormList: [],
  totalAfterCareFormCount: 0,
  afterCareFormListPage: 0,
  loadedAfterCareFormCount: 0,
}

//Get AfterCareForm List reducer
export default function afterCareFormListReducer(state = initialState, action) {

  switch (action.type) {

    case GET_AFTERCARE_FORM_LIST:
      let temp=(state?.afterCareFormList).concat(action.payload?.aftercareDocs)

      return {
        ...state,
        afterCareFormList: temp,
        totalAfterCareFormCount: action.payload.total,
        afterCareFormListPage: state.afterCareFormListPage + 1,
        loadedAfterCareFormCount: temp.length

      };

    case CLEAR_AFTERCARE_FORM_LIST:

      return {
        ...state,
        afterCareFormList: [],
        totalAfterCareFormCount: 0,
        afterCareFormListPage: 0,
        loadedAfterCareFormCount: 0,

      };

    default:
      return state;
  }



}

