import React, { useState } from "react";
import "./Discountcodes.scss";
import { ReactComponent as CloseIcon } from "../../../../../images/close_icon.svg";
import SearchIcon from "../../../../../images/search_icon.svg";
import { ReactComponent as RightIcon } from "../../../../../images/calander_next.svg";
import BusinessNavigation from "../../../../common/businessNavigation/BusinessNavigation";
import EditDiscountcodes from "./editDiscountcodes/EditDiscountcodes";
import AddDiscountcodes from "./AddDiscountcodes/AddDiscountcodes";

function Discountcodes({ setParentStep }) {
  const [searchText, setSearchText] = useState("");
  const handleSearchText = (e) => {
    const newText = e.target.value;
    setSearchText(newText);
  };

  const [step, setStep] = useState(0);

  const renderTabContent = () => {
    if (step == 1) {
      return <AddDiscountcodes setStep={setStep} />;
    }
    if (step == 2) {
      return <EditDiscountcodes setStep={setStep} />;
    }
    
  
  };
  return (
    <div className="MainDiscountcodespage">
      {step > 0 ? (
        renderTabContent()
      ) : (
        <div className="rightSidebarWrapper">
          <div className="Main684WidthSet">
            <BusinessNavigation
              isColumn={true}
              title={"Discount codes"}
              desc={
                "create discount codes for occasions when you feel like it!"
              }
              onBackClick={() => {
                setParentStep(0);
              }}
            />
            <div className="searchWrapper">
              <div className="custom-search-container">
                <input
                  type="text"
                  placeholder="Search clinic"
                  value={searchText}
                  onChange={(e) => handleSearchText(e)}
                />
                <img
                  src={SearchIcon}
                  alt="search-icon"
                  className="search-icon-img"
                />
                {searchText && (
                  <CloseIcon
                    className="cursor-pointer search-close-icon"
                    onClick={() => {
                      setSearchText("");
                    }}
                  />
                )}
              </div>
            </div>

            <div className="DiscountWrapper">
              <div
                className="discountTextWrapper"
                onClick={() => {
                  setStep(1)
                }}
              >
                <h6>Add New Discount</h6>
                <RightIcon />
              </div>

              <div className="discountList">
                <ul>
                  {[...Array(5)].map((_, index) => (
                    <li key={index} onClick={()=>{setStep(2)}}>
                      <div className="discountleft">
                        <h6>Discount Code: disc20</h6>
                        <p>Amount/Percentage: 10</p>
                      </div>
                      <div className="discountRight">
                        <RightIcon />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="RightOverView">
            <div className="overHeading">
              <h5>Overview</h5>
            </div>
            <div className="Content">
              <p>
                Sending text reminders and any other messages to your clients is
                simple. Just purchase the amount of texts you need, and select
                the clients you want to text.{" "}
              </p>
              <p>
                In case the message character length is greater than 160, the
                message will be sent in multiple parts.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Discountcodes;
