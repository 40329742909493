import React, { useEffect, useRef, useState } from "react";
import "./clinic-cart.scss";
import { useNavigate, useParams } from "react-router";

import locationIcon from "../../../assets/cart/location.png";
import placeholder from "../../../assets/cart/placeholder.png";
import defaultImg from "../../../images/default-clinic-image.png";
import { ReactComponent as LocationIcon } from "../../../images/location_icon.svg";
import star from "../../../images/star.svg";
import ConfirmPay from "../../ConfirmAndPay/ConfirmPay";
import ConfirmPayWeb, { initConfirmBooking, payl8rMiddleFunction } from "../../ConfirmAndPay/ConfirmPayWeb";
import TeamMember from "../../TeamMember/TeamMember";
import useDeviceDetect from "../../common/useDeviceDetect";
import { GetAvailableTimeSlotsAction } from "../../redux/actions/dateTimeslots/DateTimeSlots";
import Location from "../client-booking/Location";
import DateTime from "../dateTime/DateTime";
import TreatmentListing from "../treatment-listing/TreatmentListing";
import Payl8rLogo from "../../../images/Payl8r-Logo-White 1.svg"
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import ModalCloseBtn from "../../../images/close_icon.svg"
import creditCheck from "../../../images/Soft-Credit-Check.svg"
import clockTime from "../../../images/Layer 3.svg"
import calendar from "../../../images/interest.svg"
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getPaylaterStatus, SendFinanceRequest } from "../../redux/actions/pay-later/payLaterAction"
import SuccessModal from "../../../components/modal/SuccessModal/index"
import BottomGroup from "../../../images/bottom-group.png";
import { handleConfirmBooking } from "../../ConfirmAndPay/ConfirmPayWeb";
import { GetNextAvailableDateForBookingsAction } from "../../redux/actions/getNextAvailableDateForBookings/GetNextAvailableDateForBookings";
import StepButton from "../../common/StepButton";
import { VerifiedBusinessBadgeQuery } from "../../redux/actions/verifiedBusinessBadge/verifiedBusinessBadge";
import VerifiedLogo from "../../../images/verified.svg"
import { webBaseUrl } from "../../../config";
import { Helmet } from "react-helmet";
import PatientDobWarningModal from "../../modal/PatientDobWarningModal";


const ClinicCart = () => {

  const patientDobModalRef = useRef(null);

  const [step, setStep] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedTreatments, setSelectedTreatments] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [tempLoad, setTempLoad] = useState(false);
  const navigate = useNavigate();
  const [selectedChecked, setSelectedChecked] = useState({})
  const [thankyou, setThankyou] = useState(false);
  const [isMonthlySelected, setIsMonthlySelected] = useState(false);
  //const isMobile = useDeviceDetect('min-width: 768px')
  const [clinicId, setClinicId] = useState(null);
  const [showSelectedDate, setShowSelectedDate] = useState(null);
  const [formatedDate, setFormatedDate] = useState(null)
  const [showSelectedTime, setShowSelectedTime] = useState(null);

  const [selectedTreatment, setSelectedTreatment] = useState([]);
  const { deviceType, screenWidth } = useDeviceDetect();
  const moment = require("moment");
  const [selectedTotal, setSelectedTotal] = useState(0)
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(
    selectedDate.getMonth() + 1
  );
  const [selectedTimes, setSelectedTimes] = useState(null)
  const dispatch = useDispatch();
  const paylaterStatus = useSelector(state => state.payLaterReducer.payLaterStatus)
  const paylaterEnable = useSelector(state => state.payLaterReducer.payLaterEnable)
  //REF for callback funcation
  const confirmPayRef = useRef()

  const [substep, setSubstep] = useState(301);
  const [selectedTime, setSelectedTime] = useState("");
  const [isDevice, setIsDevice] = useState(false)
  const [isRight, setIsRight] = useState(true);
  const [endDate, setEndDate] = useState(
    moment().add(90, "days").format("YYYY-MM-DD")
  );
  const [payl8rData, setPayl8rData] = useState(null);
  const [requestSendModal, setRequestSendModal] = useState(false);

  const [isRightSectionOnDate, setIsRightSectionOnDate] = useState(true)

  const { availableTimeSlotData, initAvalableTimeSlotData } = GetAvailableTimeSlotsAction();
  const { initiatesendFinance, sendFinanceMutationData } = SendFinanceRequest();
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [totalDeposit, setTotalDeposit] = useState(0)
  const [bookingId, setBookingId] = useState(null);

  const clinic_id = useSelector(state => state.businessLocationListReducer.clinic_id) || null;
  const { businessClinicList } = useSelector(state => state?.businessLocationListReducer)
  const { slug } = useParams()

  console.log("booking slug", slug);
  const [loader, setLoader] = useState(false)
  const [yourDetails, setYourDetails] = useState({
    clientFName: "",
    clientLName: "",
    clientEmail: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    clientFName: "",
    clientLName: "",
    clientEmail: "",
  });
  useEffect(() => {
    if (slug) {
      dispatch(getPaylaterStatus({
        "user_id": slug
      }))
    }
  }, [slug]);


  useEffect(() => {
    if (deviceType === "Mobile") {
      setIsDevice(true)
    }
  }, [deviceType, screenWidth])



  const [selectedTreatmentId, setSelectedTreatmentId] = useState([])
  const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
  const [isOpenPayment, setIsOpenPayment] = useState(false);
  const [openPayLater, setOpenPayLater] = useState(false)
  const [payLaterData, setPayLaterData] = useState(null)
  const { initVerifiedBusinessBadge } = VerifiedBusinessBadgeQuery()
  const { isVerified } = useSelector(state => state?.verifiedBusinessBadgeReducer)

  useEffect(() => {
    if (slug) {
      initVerifiedBusinessBadge({
        userToken: slug
      })
    }
  }, [slug])
  // if (thankyou) {
  //   return <SuccessPage />;
  // }
  console.log("isVerified", isVerified)
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleNextStep = () => {
    if (step == 2 && selectedTreatment.length > 0) {
      // setShowSelectedDate(null)
      // setFormatedDate(null)
      // setShowSelectedTime(null)
      // setSelectedTeamMember(null)
      setStep(3)
    }
    if (step == 3) {
      if (substep == 301 && showSelectedDate != null) {
        // initAvalableTimeSlotData({
        //   clinicId: clinicId,
        //   date: moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD"),
        //   treatmentIds: selectedTreatment.map((item) => item.id),
        // });
        setSubstep(substep + 1)
        // setShowSelectedTime(null)
        // setSelectedTeamMember(null)

      }
      else if (substep == 302 && showSelectedTime != null) {
        setSubstep(substep + 1)
        // setSelectedTeamMember(null)
      }
      else if (substep == 303 && selectedTeamMember != null) {
        setSubstep(substep + 1)
      }
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  }

  //Callback funcation
  const handleConfirmPay = () => {
    confirmPayRef?.current?.handleConfirmBooking()
  }


  const callPayl8rApi = () => {
    setModalOpen(true);
    var depositeRate = 0.1;
    var interestRate = 0.01;
    var amount = selectedTreatment?.reduce((total, item) => {
      return total + (item?.amount || 0); // Add the amount of each item to the total
    }, 0);
    if (amount == 0) {
      amount = 100;
    }
    var username = process.env.REACT_APP_PAYL8R_USERNAME;
    var url = `https://payl8r.com/get-product-page-calculator/1?username=${username}&total=${amount}&depositRate=${depositeRate}&interestRate=${interestRate}`
    axios.get(url).then((res) => {
      if (res.data.data) {
        var obj = {
          "3Month": res.data.data[3],
          "6Month": res.data.data[6],
          "9Month": res.data.data[9],
          "12Month": res.data.data[12],
          amount: amount
        }
        setPayl8rData(obj);

      }

    });
  }


  const sendRequestForPayl8r = () => {
    initiatesendFinance({
      "clinicId": parseInt(clinic_id),
      "requestMail": false //sending false because this flag is for user to turn on finance toggle but user is not registered yet
    }, (data) => {
      console.log(data);
      setRequestSendModal(true);
    }, () => {
      alert('Something went wrong!');
    })
  }


  console.log(selectedLocation?.clinic_slug, 'selectedLocation');
  return (
    <div className={(step !== 1 && substep !== 304) ? "clinic-cart-main-div customeDesign" : " clinic-cart-main-div hide-scroll-div"}>

      <Helmet>
        <meta name="X-Robots-Tag" content="noindex,nofollow" />
        <meta name="robots" content="noindex,nofollow" />
        {selectedLocation?.clinic_name && (
          <link
            rel="canonical"
            href={`${webBaseUrl}/clinics/${selectedLocation?.clinic_slug}`}
          />
        )}
      </Helmet>

      {
        screenWidth < 720 ?



          <div className="main-clinic-mobile-container">
            <>
              {step === 1 && (
                <Location
                  setStep={setStep}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  setClinicId={setClinicId}
                  clinicId={clinicId}
                  step={step}
                  setSelectedTreatment={setSelectedTreatment}
                  setShowSelectedDate={setShowSelectedDate}
                  setFormatedDate={setFormatedDate}
                  setShowSelectedTime={setShowSelectedTime}
                />
              )}
              {step === 2 && (
                <TreatmentListing
                  setStep={setStep}
                  step={step}
                  setSubStep={setSubstep}
                  subStep={substep}
                  selectedLocation={selectedLocation}
                  cartData={selectedItems} // Pass the selected items as needed
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  setClinicId={setClinicId}
                  clinicId={clinicId}
                  setSelectedChecked={setSelectedChecked}
                  selectedChecked={selectedChecked}
                  selectedTreatment={selectedTreatment}
                  setSelectedTreatment={setSelectedTreatment}
                  selectedTotal={selectedTotal}
                  setSelectedTotal={setSelectedTotal}
                  data={{
                    type: "clinic",
                    image: placeholder,
                    icon: locationIcon,
                    title: selectedLocation?.clinic_name,
                    content: selectedLocation?.clinic_address,
                    slug: selectedLocation?.clinic_slug,
                  }}
                />
              )}

              {/* {step === 3 && (
                                <DateTime
                                    setTempLoad={setTempLoad}
                                    tempLoad={tempLoad}
                                    selectedTimeIndex={selectedTimeIndex}
                                    setSelectedTimeIndex={setSelectedTimeIndex}
                                    selectedLocation={selectedLocation}
                                    setStep={setStep}
                                    selectedItems={selectedItems}
                                    submitForm={()=>{
                                        setThankyou(true);
                                    }}
                                    isMonthlySelected={isMonthlySelected}
                                    setIsMonthlySelected={setIsMonthlySelected}
                                />
                            )} */}

              {step === 3 && screenWidth < 720 && (
                <DateTime
                  step={step}
                  setTempLoad={setTempLoad}
                  tempLoad={tempLoad}
                  selectedTimeIndex={selectedTimeIndex}
                  setSelectedTimeIndex={setSelectedTimeIndex}
                  selectedLocation={selectedLocation}
                  setStep={setStep}
                  substep={substep}
                  setSubstep={setSubstep}
                  selectedItems={selectedItems}
                  submitForm={() => {
                    setThankyou(true);
                  }}
                  isMonthlySelected={isMonthlySelected}
                  setIsMonthlySelected={setIsMonthlySelected}
                  showSelectedDate={showSelectedDate}
                  setShowSelectedDate={setShowSelectedDate}
                  showSelectedTime={showSelectedTime}
                  setShowSelectedTime={setShowSelectedTime}
                  selectedTeamMember={selectedTeamMember}
                  setSelectedTeamMember={setSelectedTeamMember}
                  selectedTreatments={selectedTreatments}
                  setSelectedTreatments={setSelectedTreatments}
                  setClinicId={setClinicId}
                  clinicId={clinicId}
                  setFormatedDate={setFormatedDate}
                  formatedDate={formatedDate}
                  selectedTreatmentId={selectedTreatmentId}
                  selectedTreatment={selectedTreatment}
                  setSelectedTreatmentId={setSelectedTreatmentId}
                  selectedTimes={selectedTimes}
                  setSelectedTimes={setSelectedTimes}


                />
              )}

              {/* {step === 4 && screenWidth < 720 && (
                  <TimeSelectionMobile
                    step={step}
                    setStep={setStep}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    timeData={[
                      "10:45 AM",
                      "11:00 AM",
                      "11:15 AM",
                      "11:30 AM",
                      "11:45 AM",
                      "12:00 PM",
                      "12:15 PM",
                      "12:30 PM",
                      "12:45 PM",
                      "01:00 PM",
                      "01:15 PM",
                      "01:30 PM",
                      "01:45 PM",
                      "02:00 PM",
                      "02:15 PM",
                      "02:30 PM",
                      "02:45 PM",
                      "03:00 PM",
                      "03:15 PM",
                      "03:30 PM",
                    ]}
                  />
                )} */}

              {step === 4 && (
                <TeamMember
                  step={step}
                  setStep={setStep}
                  selectedTeamMember={selectedTeamMember}
                  setSelectedTeamMember={setSelectedTeamMember}
                  selectedTreatment={selectedTreatment}
                  setSelectedTreatment={setSelectedTreatment}
                  setSelectedTreatmentId={setSelectedTreatmentId}
                  selectedTreatmentId={selectedTreatmentId}
                  substep={substep}
                  setSubStep={setSubstep}
                />
              )}
              {step === 5 && screenWidth < 720 && (
                <ConfirmPay
                  step={step}
                  setStep={setStep}
                  selectedTreatment={selectedTreatment}
                  setSelectedTreatment={setSelectedTreatment}
                  selectedTime={selectedTime}
                  setSelectedTime={setSelectedTime}
                  selectedTeamMember={selectedTeamMember}
                  setSelectedTeamMember={setSelectedTeamMember}
                  clinicId={clinicId}
                  setClinicId={clinicId}
                  subStep={substep}
                  setSubStep={setSubstep}
                  setSelectedTreatmentId={setSelectedTreatmentId}
                  selectedTreatmentId={selectedTreatmentId}
                  showSelectedDate={showSelectedDate}
                  showSelectedTime={showSelectedTime}
                  formatedDate={formatedDate}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}

                />
              )}
              {/* {step === 4 && (
                                <PriceDetails
                                    step={step}
                                    setStep={setStep}
                                    tempLoad={tempLoad}
                                    setTempLoad={setTempLoad}
                                    selectedLocation={selectedLocation}
                                    selectedTimeIndex={selectedTimeIndex}
                                    selectedCard={selectedCard}
                                    totalPrice={total}
                                />
                            )} */}
            </>
          </div>

          :
          <div className="main-web-container Main-web-container-design ">
            {/* <img src={faces} height={50} width={120} className='img-container'/> */}
            <div className={`main-clinic-container w-80  ${(step == 1 || substep == 305) ? "w-100" : ""}`}>
              <>
                <>
                  {step === 1 && (
                    <Location
                      setStep={setStep}
                      selectedLocation={selectedLocation}
                      setSelectedLocation={setSelectedLocation}
                      setClinicId={setClinicId}
                      clinicId={clinicId}
                      setSelectedTreatment={setSelectedTreatment}
                      setShowSelectedDate={setShowSelectedDate}
                      setFormatedDate={setFormatedDate}
                      setShowSelectedTime={setShowSelectedTime}
                    />
                  )}
                  {step === 2 && (
                    <TreatmentListing
                      setStep={setStep}
                      cartData={selectedItems} // Pass the selected items as needed
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                      data={{
                        type: "clinic",
                        image: placeholder,
                        icon: locationIcon,
                        title: selectedLocation?.clinic_name,
                        content: selectedLocation?.clinic_address,
                        slug: selectedLocation?.clinic_slug,
                      }}
                      setSubStep={setSubstep}
                      subStep={substep}
                      selectedTreatments={selectedTreatments}
                      selectedTreatment={selectedTreatment}
                      setSelectedTreatments={setSelectedTreatments}
                      setSelectedTreatment={setSelectedTreatment}
                      setClinicId={setClinicId}
                      clinicId={clinicId}
                    />
                  )}

                  {step === 3 && (
                    <DateTime
                      setIsRightSectionOnDate={setIsRightSectionOnDate}
                      step={step}
                      setTempLoad={setTempLoad}
                      tempLoad={tempLoad}
                      selectedTimeIndex={selectedTimeIndex}
                      setSelectedTimeIndex={setSelectedTimeIndex}
                      selectedLocation={selectedLocation}
                      setStep={setStep}
                      substep={substep}
                      setSubstep={setSubstep}
                      selectedItems={selectedItems}
                      submitForm={() => {
                        setThankyou(true);
                      }}
                      isMonthlySelected={isMonthlySelected}
                      setIsMonthlySelected={setIsMonthlySelected}
                      showSelectedDate={showSelectedDate}
                      setShowSelectedDate={setShowSelectedDate}
                      showSelectedTime={showSelectedTime}
                      setShowSelectedTime={setShowSelectedTime}
                      selectedTeamMember={selectedTeamMember}
                      setSelectedTeamMember={setSelectedTeamMember}
                      selectedTreatments={selectedTreatments}
                      setSelectedTreatments={setSelectedTreatments}
                      setClinicId={setClinicId}
                      clinicId={clinicId}
                      setFormatedDate={setFormatedDate}
                      formatedDate={formatedDate}
                      selectedTreatmentId={selectedTreatmentId}
                      selectedTreatment={selectedTreatment}
                      setSelectedTreatmentId={setSelectedTreatmentId}
                      setIsRight={setIsRight}
                      selectedTimes={selectedTimes}
                      setSelectedTimes={setSelectedTimes}
                      payl8rData={payl8rData}
                      isRight={isRight}
                      totalPrice={totalPrice}
                      setTotalPrice={setTotalPrice}
                      setTotalDeposit={setTotalDeposit}
                      totalDeposit={totalDeposit}
                      setSelectedPaymentOption={setSelectedPaymentOption}
                      selectedPaymentOption={selectedPaymentOption}
                      loader={loader}
                      setLoader={setLoader}
                      setYourDetails={setYourDetails}
                      yourDetails={yourDetails}
                      setValidationErrors={setValidationErrors}
                      validationErrors={validationErrors}
                      setIsOpenPayment={setIsOpenPayment}
                      isOpenPayment={isOpenPayment}
                      setOpenPayLater={setOpenPayLater}
                      openPayLater={openPayLater}
                      payLaterData={payLaterData}
                      setPayLaterData={setPayLaterData}
                      setBookingId={setBookingId}
                      bookingId={bookingId}
                    />
                  )}

                  {/* {
                    step == 4 &&
                  (
                    <ConfirmPayWeb
                    step={step}
                    setStep={setStep}
                    selectedTreatment={selectedTreatment}
                    selectedTreatments={selectedTreatments}
                    setSelectedTreatments={setSelectedTreatments}
                    showSelectedDate={showSelectedDate}
                    setShowSelectedDate={setShowSelectedDate}
                    showSelectedTime={showSelectedTime}
                    setShowSelectedTime={setShowSelectedTime}
                    substep={substep}
                    setSubstep={setSubstep}
                    selectedTeamMember={selectedTeamMember}
                    setSelectedTeamMember={setSelectedTeamMember}
                   
                    setSubStep={setSubstep}
                   
                   
                    selectedTreatmentId={selectedTreatmentId}
                    setIsRight={setIsRight}
                    
                    
                    clinicId={clinicId}
                    formatedDate={formatedDate}
                    selectedTimes={selectedTimes}
                    setSelectedTimes={setSelectedTimes}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    payl8rData={payl8rData}
                    totalPrice={totalPrice} 
                    setTotalPrice={setTotalPrice}
                    setTotalDeposit={setTotalDeposit} 
                    totalDeposit={totalDeposit}
                    setSelectedPaymentOption={setSelectedPaymentOption} 
                    selectedPaymentOption={selectedPaymentOption} 
                    ref={confirmPayRef}
                    loader={loader}
                    setLoader={setLoader}
                />
                  )} */}

                </>
              </>
            </div>
            {
              (step != 1 && step != 5) &&
              <div className="web-right-section Main-Treatments-RightPart">
                <div className="active-right-section">
                  <div className="clinic-new-section">
                    <div className="verified-section">
                      <p>{selectedLocation?.clinic_name}</p>
                      {isVerified?.is_verified &&
                        <div className="verified-logo">
                          <img src={VerifiedLogo} alt="" />
                          <p>Verified business</p>
                        </div>
                      }
                    </div>
                    {
                      step !== 1 &&
                      <b
                        onClick={() => {
                          setIsRight(true);
                          setSubstep(301)
                          setStep(1);
                        }}
                        className="cursor-pointer"
                      >
                        Edit
                      </b>
                    }
                  </div>
                  <hr className="mb-0 mt-1 mb-1" />
                  <div className="clinic-details d-flex justify-content-between ">
                    <div className="left-section">

                      <div className="c-name">{selectedLocation?.clinic_name}</div>

                      {paylaterStatus == false ? (
                        <span>
                          <p className="m-0">Offers buy now pay later via{" "}</p>
                          <img className="mx-1" src={Payl8rLogo} />
                          <p className="learn_more" onClick={() => { callPayl8rApi() }}>learn more</p>
                        </span>
                      ) : (
                        <p className="learn_more" onClick={() => { sendRequestForPayl8r() }}>Request buy now payl8r</p>
                      )}
                      <div className="company-address">
                        <p className="m-0">
                          {selectedLocation?.complete_address}
                        </p>
                      </div>
                      <div className="left-rating left-rating-designSet ">
                        <div className="star">
                          <img src={star} alt="Star Icon" width={14} height={14} />
                        </div>
                        <span className="rating-number">{selectedLocation?.cln_average_rating}</span>
                        <span className="rating-people">({selectedLocation?.total_reviews})</span>
                      </div>
                    </div>
                    <div>
                      <div className="c-image">
                        <img src={selectedLocation?.image_url} alt="" />
                        {/* <LocationIcon /> */}

                        {/* <img
                          src={clientImage}
                          alt="Company Image"
                          width={48}
                          height={48}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <ConfirmPayWeb setBookingId={setBookingId} bookingId={bookingId} clinicId={clinicId} isRight={isRight} setIsRight={setIsRight} step={step} setSelectedTreatmentId={setSelectedTreatmentId} selectedTreatmentId={selectedTreatmentId} selectedTreatment={selectedTreatment} formatedDate={formatedDate} showSelectedTime={showSelectedTime} selectedTeamMember={selectedTeamMember} setStep={setStep} substep={substep} setSubstep={setSubstep} selectedTimes={selectedTimes} setSelectedTimes={setSelectedTimes} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} totalPrice={totalPrice} setTotalPrice={setTotalPrice} setTotalDeposit={setTotalDeposit} totalDeposit={totalDeposit} setSelectedPaymentOption={setSelectedPaymentOption} selectedPaymentOption={selectedPaymentOption} ref={confirmPayRef} loader={loader} setLoader={setLoader} setYourDetails={setYourDetails} yourDetails={yourDetails} setValidationErrors={setValidationErrors} validationErrors={validationErrors} showSelectedDate={showSelectedDate} setShowSelectedDate={setShowSelectedDate} setShowSelectedTime={setShowSelectedTime} setIsOpenPayment={setIsOpenPayment} setOpenPayLater={setOpenPayLater} payl8rData={payl8rData} payLaterData={payLaterData} setPayLaterData={setPayLaterData} 
                  
                  showDobWarning={() => {
                    patientDobModalRef.current.showDobWarning();
                  }}
                  />

                  {
                    substep != 304 && isOpenPayment != true && openPayLater != true && <div
                      className={`continue-clinic ${((step == 2 && selectedTreatment.length > 0) ||
                        (substep == 301 && showSelectedDate != null) ||
                        (substep == 302 && showSelectedTime != null) ||
                        (substep == 303 && selectedTeamMember != null))
                        ? "blue-button" : ""
                        }`}
                      onClick={() => handleNextStep()}
                    >
                      Continue
                    </div>
                  }
                  {
                    substep == 304 && isOpenPayment !== true && openPayLater !== true &&
                      (businessClinicList?.is_ryft_setup || businessClinicList?.is_stripe_setup || businessClinicList?.is_finance_available) && totalPrice > 0 ?

                      ((selectedPaymentOption === "Split payment" || selectedPaymentOption === "Pay in full")) ? <StepButton
                        disabled={!yourDetails.clientFName || !yourDetails.clientLName || !yourDetails.clientEmail || validationErrors.clientEmail}
                        blue={true}
                        id={"ryft-pay-btn"}
                        label={selectedPaymentOption === "Split payment" ? totalDeposit == 0 ? "Complete booking" : `Pay £${totalDeposit} now` : `Pay £${totalPrice} now`}
                        isLoading={loader}
                        onClick={() => handleConfirmBooking()}
                      /> : selectedPaymentOption === "Monthly payments" ? <StepButton blue={true} disabled={!yourDetails.clientFName || !yourDetails.clientLName || !yourDetails.clientEmail || validationErrors.clientEmail} label={"Buy now Payl8r"} onClick={() => payl8rMiddleFunction()} isLoading={loader} />

                        : totalDeposit == 0 ? <StepButton
                          label={"Select Payment Option"}
                          disabled={true}
                        /> :
                          isRight == false && <StepButton
                            label={"Select Payment Option"}
                            disabled={true}
                          />
                      :
                      (isRight == true && isOpenPayment !== true && substep == 304 && openPayLater !== true && (totalPrice == 0 || !(businessClinicList?.is_ryft_setup || businessClinicList?.is_stripe_setup || businessClinicList?.is_finance_available))) &&
                      (<StepButton
                        blue={true}
                        isLoading={loader}
                        label={"Complete booking"}
                        disabled={!yourDetails.clientFName || !yourDetails.clientLName || !yourDetails.clientEmail || validationErrors.clientEmail}
                        onClick={() => handleConfirmBooking()}
                      />)

                  }
                  <></>

                </div>
              </div>
            }
          </div>
      }

      <Modal isOpen={modalOpen} toggle={toggleModal} backdrop={true} backdropClassName="modal-backdrop-blur" scrollable="true" modalClassName="big-modal">
        <img src={ModalCloseBtn} width={60} height={60} className="btn modal-close-btn" onClick={toggleModal} />
        <ModalBody cssModule="big-modal">
          <div className="outer-div">
            <div style={{ alignItems: "center", textAlign: "center" }}>
              <p className="heading">
                Spread the cost with Buy Now Pay Later
              </p>

              <div className="totalDiv">
                <div className="total-div">
                  <p className="total-txt">Total cost</p> <span
                    className="total-price"
                  >{'£' + payl8rData?.amount || 0}</span>
                </div>
                <div className="total-sub-div">
                  <div className="line">

                  </div>

                  <p className="title-txt">
                    Why not <span className="title-txt-sub">spread</span> the cost?</p>

                </div>


              </div>


              <div className="items-div">


                <div className="item-div">

                  <img src={clockTime} alt="" height={30} width={30} />
                  <span class="item-txt">Quick application process</span>
                </div>

                <div className="item-div">

                  <img src={calendar} alt="" height={30} width={30} />
                  <span class="item-txt">0% Interest if fully paid in 90 days</span>
                </div>

                <div className="item-div">

                  <img src={creditCheck} alt="" height={30} width={30} />
                  <span class="item-txt">Soft Credit check</span>
                </div>

              </div>
              <div className="three-month-div">
                <div class="three-month-div-first">
                  <span class="three-month-txt">3 Months</span>
                  <span class="three-month-txt-sub">0% interest!</span>
                </div>

                <div class="three-month-div-second">
                  <span className="three-month-div-second-txt">{`Pay £${payl8rData?.["3Month"]?.monthlyPaymentAmount} `}<span className="star">*</span> / pm</span>
                </div>
              </div>

              <div className="months-div">
                <div class="month-div">
                  <div class="custom-pay-paymant">
                    <span className="month-txt">6 Months</span>
                  </div>
                  <div class="custom-pay-total">
                    <span className="pay-txt"> Pay </span>
                    <span className="amt-txt"> {`£${payl8rData?.["6Month"]?.monthlyPaymentAmount}`}<span className="star">*</span><span className="amt-txt"> / pm</span></span>
                  </div>
                </div>
                <div class="month-div">
                  <div class="custom-pay-paymant">
                    <span className="month-txt">9 Months</span>
                  </div>
                  <div class="custom-pay-total">
                    <span className="pay-txt"> Pay </span>
                    <span className="amt-txt"> {`£${payl8rData?.["9Month"]?.monthlyPaymentAmount}`}<span className="star">*</span><span className="amt-txt"> / pm</span></span>
                  </div>
                </div>
                <div class="month-div">
                  <div class="custom-pay-paymant">
                    <span className="month-txt">12 Months</span>
                  </div>
                  <div class="custom-pay-total">
                    <span className="pay-txt"> Pay </span>
                    <span className="amt-txt"> {`£${payl8rData?.["12Month"]?.monthlyPaymentAmount}`}<span className="star">*</span><span className="amt-txt"> / pm</span></span>
                  </div>
                </div>
              </div>

              <div className="line-h">

              </div>

              <p className="para">
                For approval: Must be 18. Have a UK bank account, regular monthly income, have your card & account details ready. <span className="star">*</span>Rates, totals & monthly amounts are indicative and subject to status. EXAMPLE: Subject to affordability assessments - Application fees will apply. (Full legal name and trading style where applicable) is an Introducer Appointed Representative of Social Money LTD t/a Payl8r. Representative Example: If you borrow £200 over 12 mths at a fixed monthly interest rate of 4% and an annual rate of 48% and representative annual percentage rate of 127.34%. You will pay. Monthly payment: £24.66. Total amount you repay: £295.92 Total charge for credit: £95.92 Missed payment may affect your Credit File, future borrowing and incur fees. Our online application takes just 60 seconds to complete and, in most cases, we'll be able to give you an instant decision. Please note: For a small majority of customers, your application may require additional information and therefore it can take longer. You will be notified by email if your application is sent for further review.
              </p>
            </div>


          </div>
          <img src={BottomGroup} className="bottom-group" style={{ width: '100%' }} />
        </ModalBody>


      </Modal>


      <SuccessModal isOpen={requestSendModal} setIsOpen={setRequestSendModal} title={""} subTitle={"We've let the practitioner know you are interested in Buy Now Pay Later "} />

      <PatientDobWarningModal onAgreeWarning={() => {
        initConfirmBooking();
      }} ref={patientDobModalRef} />
    </div>
  );
};

export default ClinicCart;