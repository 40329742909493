import { CLEAR_CLIENT_CONSENT_FORM_LIST, GET_CLIENT_CONSENT_FORM_LIST } from "../../constants";

const initialState = {
  clientConsentFormList: [],
  totalClientConsentFormListCount: 0,
  loadedClientConsentFormListCount: 0,
  clientConsentFormListPage: 0,
  clientData: null
};

export default function clientConsentFormListReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_CONSENT_FORM_LIST:
      const temp = action?.payload?.consentFormRecords != undefined ? (state?.clientConsentFormList).concat(action?.payload?.consentFormRecords) : []
      return {
        ...state,
        clientConsentFormList: temp,
        totalClientConsentFormListCount: action?.payload?.totalForms,
        clientConsentFormListPage: state.clientConsentFormListPage + 1,
        loadedClientConsentFormListCount: temp.length,
        clientData: { clientName: action?.payload?.clientName, vendorIsPharmacy: action?.payload?.vendorIsPharmacy, clientEmail: action?.payload?.clientEmail, clientProfileImage: action?.payload?.clientProfileImage }
      };

    case CLEAR_CLIENT_CONSENT_FORM_LIST:
      return {
        ...state,
        clientConsentFormList: [],
        totalClientConsentFormListCount: 0,
        loadedClientConsentFormListCount: 0,
        clientConsentFormListPage: 0
      };

    default:
      return state;
  }
}
