import React, { useState } from "react";
import "./payment-web-modal.scss";
import Modal from "react-modal";
import { ReactComponent as BackArrow } from "../../../images/back_arrow.svg";

import { usePaymentInputs } from "react-payment-inputs";
import visa from "../../../images/card/Visa.png"
import mastercard from "../../../images/card/Mastercard.png"
import StepButton from "../../common/StepButton";
import cardIcon from "../../../images/card/card.png"

const PaymentWebModal = ({ isOpenPayment, setIsOpenPayment }) => {
const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs();
const [cardNumber,setCardNumber]=useState()
const [expiryDate,setExpiryDate]=useState()
const [cvc,setCvc]=useState()

const handleChangeCardNumber=()=>{

}
const handleChangeExpiryDate=()=>{

}
const handleChangeCVC=()=>{

}


  return (
   
      <div className="main-payment-container">
        {/* <h1>Pay with Card</h1> */}
        <div className="CardBodyContent">
            <div className='card-details'>
                            <div className='card-number'>
                                Card Number
                            </div>
                            <div className='card col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4 d-flex'>
                                        <span className='card-icon'>
                                            <img src={cardIcon} alt='cardIcon' width={20} height={20} />
                                        </span>


                                        <span className='card-input'>
                                            <input
                                                type='text'
                                                placeholder='Card Number'
                                                className='card-placeholder'
                                            />
                                        </span>
                                    </div>

                                    <div className='col-md-8 d-flex justify-content-end'>
                <span className='visa'>
                    <img src={visa} alt='visa-card' width={30} height={20}/>
                </span>
                <span className='master-card'>
                    <img src={mastercard} alt='master-card' width={28} height={20}/>
                </span>
            </div>

                                </div>
                            </div>

                        </div>
            <div className="expiration-section">
            <div className="sub-section">
                <div className="inputWrapper">
                    <p>Expiration</p>
                    <input {...getExpiryDateProps({onChange:handleChangeExpiryDate})} value={expiryDate} type="text" />
                </div>
            {meta.isTouched && meta.erroredInputs.expiryDate && <span className="error">{meta.erroredInputs.expiryDate}</span>}
            </div>
            <div className="sub-section">
                <div className="inputWrapper">
                    <p>Security Card</p>
                    <input {...getCVCProps({onChange:handleChangeCVC})} value={cvc} type="text" />
                </div>
            {meta.isTouched && meta.erroredInputs.cvc && <span className="error">{meta.erroredInputs.cvc}</span>}
            </div>
            </div>
        </div>

        <hr/>
        <div className="BtnWrapper">
            <StepButton blue={true} disabled={meta.error === undefined ?false :true} label={"Add Card"}/>
            <StepButton blue={true} disabled={meta.error === undefined ?false :true} label={"Cancel"}/>
        </div>
      </div>
  );
};

export default PaymentWebModal;
