import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { CHECK_WEBSITE_DOMAIN_RESPONSE } from "../../constants";


//1 date-api
const CHECK_WEBSITE_DOMAIN_QUERY = gql`
query CheckSubDomainAvailability($subdomainName: String!) {
    checkSubDomainAvailability(subdomain_name: $subdomainName) {
      success
      message
    }
  }
  `

export const CheckWebsiteDomainAction = () => {
  const dispatch = useDispatch()
  const [checkWebsiteDomainResponseFunction, checkWebsiteDomainReponseData] = useLazyQuery(
    CHECK_WEBSITE_DOMAIN_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ checkSubDomainAvailability: responseData }) => {
        console.log("responseData", responseData, "data")

        dispatch({
          type: CHECK_WEBSITE_DOMAIN_RESPONSE,
          payload: responseData
        })
      }
    }
  )
  //this will be called from components
  const checkWebsiteDomainResponseData = checkWebsiteDomainReponseData //variable

  const initCheckWebsiteDateResponseFunction = (data) => {
    console.log("initCheckWebsiteDateResponseFunction called", data)
    checkWebsiteDomainResponseFunction({
      variables: data
    })
  }
  return { initCheckWebsiteDateResponseFunction, checkWebsiteDomainResponseData }
}

