import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import banner from "../../../assets/createWebsite/smallBanner.png";
import dummyLogo from "../../../assets/createWebsite/dummyLogo.png";
import footerLogo from "../../../assets/createWebsite/prevLogo.png";
import slider from "../../../assets/createWebsite/slider.png";
import faceslogo from "../../../assets/createWebsite/Faces_logo.svg";
import insyncLogo from "../../../assets/createWebsite/insyncLogo.png"
import insyncIcon from "../../../assets/createWebsite/insyncIcon.png"
import star from "../../../assets/createWebsite/star1.svg";
import { Avatar } from "@material-ui/core";

import googlelogo from "../../../assets/createWebsite/google.svg";
import googleIcon from "../../../images/icons/googleicon.svg";
import facesIcon from "../../../assets/createWebsite/facesIcon.svg";
import reviewProfile from "../../../assets/createWebsite/reviewProfile.png";

import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

import { Link, useNavigate } from "react-router-dom";
import { Container, colors } from "@material-ui/core";
import StepButton from "../../common/StepButton";
import SkipButtonComp from "../createWbsiteURL/commonComponents/SkipBtn";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import placeholder from "../../../assets/createWebsite/placeholder.png";
import dummyImageIcon from "../../../images/dummyTeamMember.svg";
import CustomColorPicker from "../../customeColorPicker";
import "aos/dist/aos.css";
import AOS from "aos";
import ImageTitleComponent from "../createWbsiteURL/commonComponents/ImgTitleComp";
import { SaveWebsiteColors } from "../../redux/actions/createWebsite/saveWebsiteColors";
import { DeleteTeamMemberDetails } from "../../redux/actions/createWebsite/deleteTeamMemberDetails";
import { GetWebsiteTeamMembersAction } from "../../redux/actions/createWebsite/getPersonalWebTeamMembersQuery";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";

import { businessDashboardRoutePattern } from "../../../Routes";
import Slider from "react-slick";
import { GetWebsiteDetailsAction } from "../../redux/actions/createWebsite/getWebsiteDetails";
import { el, te } from "date-fns/locale";
import moment from "moment";
import { GetTreatmentsForUser } from "../../redux/actions/treatmentList/getTreatmentsForUser";
import NewCustomeColorPicker from "../../customeColorPicker/newcustomeColorPicker";

const WebsitePrev = (props) => {
  const navigate = useNavigate();
  const {
    stepSixPrimaryData,
    stepSixSecondaryData,
    stepSixTertiaryData,
    setCount,
    count,
    colorType,
    setColorType,
    tempSaveColorData,
    setTempSaveColorData,
    colorSavedStatus,
    setColorSavedStatus,
  } = props;

   const [customColors, setCustomColors] = useState([
    "#E1CF85",
    "#8C4CDD",
    "#224694",
    "#57BC40",
    "#14B8A6",
    "#000000",
    "#7D88E8",
    "#E8A47D",
    "#B8DDF7",
    "#D31A1A",
    "#A7BFE5",
    "#487A47",
    "#23767E",
    "#F0479A",
    "#ED5721",
    "#746BA0",
    "#FEF102",
    "#DD156B",
    "#8EC641",
    "#DB5C29",
    "#23B0C2",
    "#FCDFDB",
    "#7C6D6A"
  ]);

  const [customColorsSecondary, setCustomColorsSecondary] = useState([
    '#000000',
    '#464646',
    '#727272',
    '#9B9B9B',
    '#BABABA',
    '#D8D8D8',
    '#EEEEEE',
   ]);

  const [centerColorIndex, setCenterColorIndex] = useState((customColors.length-1));
  const [centerSecondaryColorIndex, setCenterSecondaryColorIndex] = useState((customColorsSecondary.length-1));
  const [centerTertiaryColorIndex, setCenterTertiaryColorIndex] = useState(0);
  const [openPrimary,setOpenPrimary]=useState(false)
  const [openSecondary,setOpenSecondary]=useState(false)

  useEffect(()=>{
    if(openPrimary){
        setOpenSecondary(false)
    }
  },[openPrimary])

  useEffect(()=>{
    if(openSecondary){
        setOpenPrimary(false)
    }
  },[openSecondary])

  const treatmentCardRef = useRef(null);

  const { initGetTreatmentsForUser, userTreatmentsQueryData } =
    GetTreatmentsForUser();

    const websitePrefilledData = useSelector(
        (state) => state.WebsiteDetailsReducer.websitePrefilledData
      );

      console.log("websitePrefilledData",websitePrefilledData)

  const teamMembersData = useSelector(
    (state) => state.WebsiteDetailsReducer.teamMembersData
  );



  console.log("teamMembersData", teamMembersData);

  const  reviewsData  = useSelector((state) => state.GetReviewsListDataReducer);

  const { treatementList } = useSelector(
    (state) => state?.treatmentsListReducer
  );

  console.log("reviewsData",reviewsData)

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-in-out",
    });
    return () => {
      AOS.refresh();
    };
  }, []);
  const {
    isVisiblePrev,
    isVisibleColorPicker,
    // color,
    // handleColorChange
    handleAlphaChange,
    sliderTrackStyle,
    style,
  } = props;

  const facesoptions = {
    items: 3,
    loop: !reviewsData?.reviews?.length
      ? true
      : reviewsData?.reviews?.length > 3
      ? true
      : false,
    animateIn: "fade-up",
    emulateTouch: false,
    margin: 3,
    nav: true,
  };
  console.log("reviewsData?.elementList", reviewsData?.elementList);
  const googleoptions = {
    items: 3,
    loop: true,
    animateIn: "fade-up",
    emulateTouch: false,
    margin: 3,
    nav: true,
  };

  const options = {
    items: 4.5,
    loop: !treatementList?.data?.items?.length
      ? true
      : treatementList?.data?.items?.length > 5
      ? true
      : false,
    animateIn: "fade-up",
    emulateTouch: false,
    margin: 5,
  };

  const [alpha, setAlpha] = useState(1);


  const [customColorsTertiary, setCustomColorsTertiary] = useState([
    "#f4f4f4",
    "#E1DEE3",
    "#DCDADE",
    "#D7D6D9",
    "#CDCDCF",
    "#B8BBBB",
    "#A1A4A4",
    "#8A8D8D",
    "#5C5E5E",
    "#000000",
  ]);

  const [centerColor, setCenterColor] = useState(customColors[0]);
  const {
    initiateSaveWebsiteColors,
    saveWebsiteColorsMutationData: { loading: saveColorsLoading },
  } = SaveWebsiteColors();

  const { initGetWebsiteDataResponseFunction, GetWebsiteDetailsResponseData } =
    GetWebsiteDetailsAction();
    

    useEffect(() => {
        console.log("lkgjdflgj12121212======",props.websiteData?.primaryColor)
        if (props.websiteData?.primaryColor) {
          if (!customColors.includes(props.websiteData?.primaryColor)) {
            customColors.push(props.websiteData?.primaryColor);
            
          }  
          setCenterColor(props.websiteData?.primaryColor);
          setCenterColorIndex(
            customColors.indexOf(props.websiteData?.primaryColor)
          );
        }
        
      }, []);
    
      useEffect(() => {
        if (props.websiteData?.secondaryColor) {
          if (!customColorsSecondary.includes(props.websiteData?.secondaryColor)) {
            customColorsSecondary.push(props.websiteData?.secondaryColor);
          } 
          setCenterSecondaryColorIndex(
            customColorsSecondary.indexOf(props.websiteData?.secondaryColor)
          );
          setCenterColor(props.websiteData?.secondaryColor);
        }
      }, []);



  // useEffect(() => {
  //   if (props.websiteData?.tertiaryColor) {
  //     if (!customColorsTertiary.includes(props.websiteData?.tertiaryColor)) {
  //       customColorsTertiary.unshift(props.websiteData?.tertiaryColor);
  //     } else {
  //       console.log(
  //         props.websiteData?.tertiaryColor,
  //         "index3",
  //         customColorsTertiary.indexOf(props.websiteData?.tertiaryColor)
  //       );
  //       setCenterTertiaryColorIndex(
  //         customColorsTertiary.indexOf(props.websiteData?.tertiaryColor)
  //       );
  //     }
  //     setCenterColor(props.websiteData?.tertiaryColor);
  //   }
  // }, [colorType]);

  useEffect(() => {}, [props.websiteData?.logo]);
  console.log(tempSaveColorData, "tempSaveColorData in color step");
  useEffect(() => {
    console.log(tempSaveColorData, "tempSaveColorData in useffect");
    if (
      props.websiteData?.primaryColor &&
      props.websiteData?.secondaryColor &&
      count.step6 === 0
    ) {
      console.log("in effect here");
      // if (colorType === "Primary") {
      setTempSaveColorData({
        ...props.websiteData,
        primaryColor: props.websiteData?.primaryColor,
        secondaryColor: props.websiteData?.secondaryColor,
      });

      setCount({
        ...count,
        step6: 1,
      });
    }
  }, [
    props.websiteData?.primaryColor,
    props.websiteData?.secondaryColor,
    colorType,
  ]);

  // useEffect(() => {
  //   if (colorType !== "Primary") {
  //     setCount({
  //       ...count,
  //       step6: 0,
  //     });
  //   }
  // }, [colorType]);

  console.log(colorType, "colorType back check step 6", centerColorIndex);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleRight />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleLeft />
      </div>
    );
  }

  const TeamBerSlider = {
    dots: false,
    navs: false,
    infinite: teamMembersData?.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 1,
    // fade: true,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(()=>{
    if(openPrimary)
    {setOpenSecondary(false)}
    
   },[openPrimary])

   useEffect(()=>{
    if(openSecondary)
    {setOpenPrimary(false)}
    
   },[openSecondary])

  // useEffect(() => {
  //   treatmentCardRef?.current?.forceUpdate();
  // }, [props.websiteData?.primaryColor, props.websiteData?.secondaryColor])

  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [props.websiteData]);

  console.log("key", key, treatementList);

  return (
    <>
      {isVisiblePrev && (
        <div className="prevContainer" style={style}>
          <div
            className="smallnav"
            style={{ background: props.websiteData?.primaryColor }}
          >
            <div className="prevFirst">
              <p style={{ color: props.websiteData?.secondaryColor }}>
                Follow Us
              </p>
              <hr style={{ color: props.websiteData?.secondaryColor }} />
              <div className="heaerSocialMedail">
                <div
                  style={{ color: props.websiteData?.secondaryColor }}
                  className="cursor-pointer"
                  onClick={() => {
                    props.websiteData?.socialLinks?.pwcFacebook &&
                      window.open(
                        "https://www.facebook.com/" +
                          props.websiteData?.socialLinks?.pwcFacebook,
                        "_blank"
                      );
                  }}
                >
                  <i class="fa fa-facebook-square" aria-hidden="true"></i>
                </div>
                <div
                  style={{ color: props.websiteData?.secondaryColor }}
                  className="cursor-pointer"
                  onClick={() => {
                    props.websiteData?.socialLinks?.pwcInstagram &&
                      window.open(
                        "https://www.instagram.com/" +
                          props.websiteData?.socialLinks?.pwcInstagram,
                        "_blank"
                      );
                  }}
                >
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div className="prevCenter">
              <div className="prevLogo">
                {props.websiteData?.logo ? (
                  <img src={props.websiteData?.logo} />
                ) : (
                  <img src={dummyLogo} />
                )}
              </div>
            </div>
            <div className="prevFirst">
              <p style={{ color: props.websiteData?.secondaryColor }}>
                Call Us
              </p>
              <hr style={{ color: props.websiteData?.secondaryColor }} />
              <div className="heaerSocialMedail">
                <div
                  style={{ color: props.websiteData?.secondaryColor }}
                  className="cursor-pointer"
                  onClick={() => {
                    props.websiteData?.personalDetails.pwcBusinessContact &&
                      window.open(
                        `tel:${props.websiteApiData?.personalDetails.pwcBusinessContact}`,
                        "_blank"
                      );
                  }}
                >
                  <i class="fa fa-phone-square" aria-hidden="true"></i>
                </div>
                <div
                  style={{ color: props.websiteData?.secondaryColor }}
                  className="cursor-pointer"
                  onClick={() => {
                    props.websiteData?.socialLinks?.pwcWhatsapp &&
                      window.open(
                        `https://api.whatsapp.com/send/?phone=${props.websiteApiData?.socialLinks?.pwcWhatsapp}&text=Hi&type=phone_number&app_absent=0`,
                        "_blank"
                      );
                  }}
                >
                  <i class="fa fa-whatsapp" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
          {/* Prev Banner */}
          <div
            className="smallbanner"
            style={{
              backgroundImage: `url(${
                props?.websiteData?.coverPhoto
                  ? props?.websiteData?.coverPhoto
                  : placeholder
              })`,
            }}
          >
            <Container>
              <div className="bannerTitle">
                <h1
                  className="welcomeTo"
                  style={{
                    color: props?.websiteData?.coverPhoto
                      ? props.websiteData?.secondaryColor
                      : "white",
                  }}
                >
                  {/* Welcome to */}
                  {props?.websiteData?.welcomeText}
                </h1>
                <h2
                  style={{
                    color: props?.websiteData?.coverPhoto
                      ? props.websiteData?.secondaryColor
                      : "white",
                    fontWeight: 900,
                  }}
                >
                  {props.websiteData?.businessName
                    ? props.websiteData?.businessName
                    : "Business Name Display Here"}
                </h2>
                <p
                  style={{
                    color: props?.websiteData?.coverPhoto
                      ? props.websiteData?.secondaryColor
                      : "white",
                    fontWeight: 900,
                    fontStyle: "italic",
                  }}
                >
                  {props.websiteData?.headline
                    ? props.websiteData?.headline
                    : "Business Headline goes here"}
                </p>
              </div>
            </Container>
          </div>
          {/* Prev Banner End */}

          {/* Prev About */}
          <div className="prevAboutUS" style={{ background: "#f4f4f4" }}>
            {/* <div className="smallContainer"> */}
            <Container>
              <div className="prevAboutUSContent">
                <p style={{ color: "#434343" }}>
                  {props.websiteData?.description
                    ? props.websiteData?.description
                    : "Business about description goes here"}
                </p>
              </div>
            </Container>
            {/* </div> */}
          </div>
          {/* Prev About  End*/}

          {/* Review Slider */}
          <div className="prevAboutUS" style={{ background: "#fff" }}>
            <Container>
              <div className="review">
                <div className="reviewContent">
                  <img src={websitePrefilledData?.created_from==="faces"? faceslogo:insyncLogo } />
                  <div className="reviewStar">
                    <img src={star} />
                    <img src={star} />
                    <img src={star} />
                    <img src={star} />
                    <img src={star} />
                  </div>
                  <p>
                    {reviewsData.total_reviews ? 
                       reviewsData.total_reviews
                      : "300+ Reviews"}
                  </p>
                </div>
                <div className="reviewSlider">
                  <OwlCarousel className="owl-theme" {...facesoptions}>
                    {reviewsData?.reviews?.length > 0 ? (
                      reviewsData?.reviews?.map((ele, index) => {
                        const name = ele?.userName?.split(" ").length===1?`${
                            ele?.userName?.split(" ")[0][0]
                          }`:`${
                          ele?.userName?.split(" ")[0][0]
                        }${ele?.userName?.split(" ")[1][0]}`;
                        return (
                          <div className="item" key={index}>
                            <div className="reviewWrapper">
                              <div className="reviewprofile">
                                <div className="reviewName">
                                  <div className="reviewProfile">
                                    <Avatar
                                      className="userreviewprofile"
                                      src={ele?.userImage}
                                    >
                                      {name.toUpperCase()}
                                    </Avatar>
                                  </div>
                                  <div className="reviewcontent">
                                    <p>{ele?.userName || ""}</p>
                                    <span>
                                      {ele?.reviewDate
                                        ? moment(ele?.reviewDate).format(
                                            "DD MMM YYYY"
                                          )
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="reviewLogo">
                                    <img src={!websitePrefilledData?.created_from==="faces"? facesIcon:insyncIcon } />
                                 </div>
                              </div>
                              <div className="reviewStar">
                                {ele?.userRating == 5 && (
                                  <>
                                    <img src={star} />
                                    <img src={star} />
                                    <img src={star} />
                                    <img src={star} />
                                    <img src={star} />
                                  </>
                                )}
                                {ele?.userRating == 4 && (
                                  <>
                                    <img src={star} />
                                    <img src={star} />
                                    <img src={star} />
                                    <img src={star} />
                                  </>
                                )}
                                {ele?.userRating == 3 && (
                                  <>
                                    <img src={star} />
                                    <img src={star} />
                                    <img src={star} />
                                  </>
                                )}
                                {ele?.userRating == 2 && (
                                  <>
                                    <img src={star} />
                                    <img src={star} />
                                  </>
                                )}
                                {ele?.userRating == 1 && (
                                  <>
                                    <img src={star} />
                                  </>
                                )}
                              </div>
                              <div className="reviewComment">
                                <p>{ele?.userReview || ""} 💙</p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="item">
                        <div className="reviewWrapper">
                          <div className="reviewprofile">
                            <div className="reviewName">
                              <div className="reviewProfile">
                                <img src={reviewProfile} />
                              </div>
                              <div className="reviewcontent">
                                <p>User Name</p>
                                <span>14 Jan 2023</span>
                              </div>
                            </div>
                            <div className="reviewLogo">
                                <img src={websitePrefilledData?.created_from==="faces"? facesIcon:insyncIcon } />
                            </div>
                          </div>
                          <div className="reviewStar">
                            <img src={star} />
                            <img src={star} />
                            <img src={star} />
                            <img src={star} />
                            <img src={star} />
                          </div>
                          <div className="reviewComment">
                            <p style={{ color: "white" }}>
                              Absolutely love my lips, the service was first
                              class! Can't wait to return 💙
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </OwlCarousel>
                </div>
              </div>
            </Container>
            {/* <hr />
            <Container>
              <div className="review">
                <div className="reviewContent">
                  <img src={googlelogo} />
                  <div className="reviewStar">
                    <img src={star} />
                    <img src={star} />
                    <img src={star} />
                    <img src={star} />
                    <img src={star} />
                  </div>
                  <p>300+ Reviews</p>
                </div>
                <div className="reviewSlider">
                  <OwlCarousel className="owl-theme" {...googleoptions}>
                    <div className="item">
                      <div className="reviewWrapper">
                        <div className="reviewprofile">
                          <div className="reviewName">
                            <div className="reviewProfile">
                              <img src={reviewProfile} />
                            </div>
                            <div className="reviewcontent">
                              <p>User Name</p>
                              <span>14 Jan 2023</span>
                            </div>
                          </div>
                          <div className="reviewLogo">
                            <img src={googleIcon} />
                          </div>
                        </div>
                        <div className="reviewStar">
                          <img src={star} />
                          <img src={star} />
                          <img src={star} />
                          <img src={star} />
                          <img src={star} />
                        </div>
                        <div className="reviewComment">
                          <p style={{ color: "white" }}>
                            Absolutely love my lips, the service was first
                            class! Can't wait to return 💙
                          </p>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </Container> */}
          </div>
          {/* Review Slider End */}

          {/* popularTretment Slider */}
          <div
            className="popularTretmentPrev"
            style={{ background: props.websiteData?.primaryColor }}
          >
            {/* <div className="smallContainer"> */}
            <Container>
              <div className="contentPrev">
                <div className="contentPrevHeader">
                  <h2 style={{ color: props.websiteData?.secondaryColor }}>
                    Services
                  </h2>
                  <Link
                    to={"#"}
                    style={{ color: props.websiteData?.secondaryColor }}
                  >
                    VIEW ALL
                    <svg
                      viewBox="0 0 36 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_6676_19577)">
                        <path
                          d="M21.7203 8.77924L27.194 14.2512L4.68583 14.2512C4.37088 14.2512 4.06883 14.3763 3.84613 14.599C3.62343 14.8217 3.49832 15.1237 3.49832 15.4387C3.49832 15.7536 3.62343 16.0557 3.84613 16.2784C4.06883 16.5011 4.37088 16.6262 4.68582 16.6262L27.194 16.6262L21.7203 22.0981L21.7203 22.0982C21.4974 22.3211 21.3721 22.6234 21.3721 22.9387C21.3721 23.2539 21.4974 23.5563 21.7203 23.7792C21.9432 24.0021 22.2456 24.1274 22.5608 24.1274C22.8761 24.1274 23.1784 24.0021 23.4013 23.7792L30.9011 16.2794C30.9012 16.2794 30.9012 16.2793 30.9013 16.2793C31.0118 16.169 31.0995 16.038 31.1593 15.8938C31.2192 15.7495 31.25 15.5949 31.25 15.4387C31.25 15.2825 31.2192 15.1278 31.1593 14.9836C31.0995 14.8393 31.0118 14.7084 30.9013 14.5981C30.9012 14.598 30.9012 14.598 30.9011 14.5979L23.4014 7.09816C23.291 6.98778 23.1599 6.90022 23.0157 6.84048C22.8715 6.78075 22.7169 6.75 22.5608 6.75C22.2456 6.75 21.9432 6.87524 21.7203 7.09816C21.4974 7.32108 21.3721 7.62343 21.3721 7.93868C21.3721 8.25394 21.4974 8.55629 21.7203 8.77921L21.7203 8.77924Z"
                          fill={props.websiteData?.secondaryColor}
                          stroke={props.websiteData?.secondaryColor}
                          stroke-width="0.5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6676_19577">
                          <rect
                            width="30"
                            height="36"
                            fill="white"
                            transform="translate(36) rotate(90)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                </div>
                {/* <p style={{ color: props.websiteData?.secondaryColor }}>
                    Discover the most sought-after aesthatetic treatments that have transformed countless lives. Our popular treatments are carefully curated to help you achieve your desired look and enhance your natural beauty.
                    Whether you're looking to reduce the signs of aging, sculpt your body or revitalize your skin, this proven and trusted procdeures will help you reach your aesthatetic goals.
                    Explore the possibilities and schedule a consultation to see how our expert team can help you look and feel your best. 
                </p> */}
              </div>
              {/* </div> */}
              <div className="prevslider">
                {
                  <OwlCarousel
                    ref={treatmentCardRef}
                    className="owl-theme"
                    {...options}
                  >
                    {treatementList?.data?.items?.length > 0 ? (
                      treatementList?.data?.items?.map((item, i) => {
                        return (
                          <div className="item" key={i}>
                            <div className="prevSlider">
                              <div className="prevSliderImg">
                                <img
                                  src={item?.treatment_image || slider}
                                  alt={slider}
                                />
                              </div>
                              <h2
                                className="title"
                                style={{ color: "#5a5a5a" }}
                              >
                                {item?.treatment_name || ""}
                              </h2>
                              <p className="desc" style={{ color: "#5a5a5a" }}>
                                {item?.treatment_description || ""}
                              </p>
                              <p
                                className="Rupes"
                                style={{
                                  color: props.websiteData?.primaryColor,
                                }}
                              >
                                £{item?.treatment_price || ""}
                              </p>
                              <button
                                className="bookNow"
                                style={{
                                  background: props.websiteData?.primaryColor,
                                  color: props.websiteData?.secondaryColor,
                                }}
                              >
                                Book Now
                              </button>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="item">
                        <div className="prevSlider">
                          <div className="prevSliderImg">
                            <img src={slider} alt={slider} />
                          </div>
                          <h2 className="title" style={{ color: "#5a5a5a" }}>
                            Anti-wrinkle Treatments
                          </h2>
                          <p className="desc" style={{ color: "#5a5a5a" }}>
                            Botox 1 area treatment is a non-surgical procedure
                            that reduces the appearance of wrinkles and fine
                            lines in a specific facial region.
                          </p>
                          <p
                            className="Rupes"
                            style={{ color: props.websiteData?.primaryColor }}
                          >
                            £140
                          </p>
                          <button
                            className="bookNow"
                            style={{
                              background: props.websiteData?.primaryColor,
                              color: props.websiteData?.secondaryColor,
                            }}
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    )}
                  </OwlCarousel>
                }
              </div>
            </Container>
          </div>
          {/* popularTretment Slider End */}

          {/* Ceo content */}
          <div
            className={
              teamMembersData?.length > 0
                ? "prevCeoContent MultipleSlide "
                : "prevCeoContent Oneslide"
            }
            style={{ background: "#fff" }}
          >
            <Container>
              {teamMembersData?.length > 0 ? (
                <Slider {...TeamBerSlider}>
                  {teamMembersData.map((item, index) => {
                    return (
                      <div
                        className="prevCeoContentWrapper"
                        style={{ color: props.websiteData?.secondaryColor }}
                        key={index}
                      >
                        <div
                          className="prevCeoImg"
                          style={{ backgroundColor: "#F4F4F4" }}
                        >
                          <img
                            src={item?.image ? item?.image : dummyImageIcon}
                          />
                        </div>
                        <div className="prevCeoMainContentWrapper">
                          <h2
                            style={{ color: props.websiteData?.primaryColor }}
                          >
                            {item.pwtm_full_name}
                          </h2>
                          <p>{item.pwtm_qualification}</p>
                          <div className="TitleCeoContent">
                            <span
                              className="prevTagLine"
                              style={{
                                color: props.websiteData?.secondaryColor,
                              }}
                            >
                              {item.pwtm_job_role}
                            </span>
                            <span
                              className="borderLine"
                              style={{ background: "#F282A5" }}
                            ></span>
                          </div>
                          <p
                            style={{ color: props.websiteData?.secondaryColor }}
                          >
                            {item.pwtm_description}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              ) : (
                <div
                  className="prevCeoContentWrapper"
                  style={{ background: "#fff" }}
                >
                  <div className="prevCeoImg">
                    <img src={dummyImageIcon} />
                  </div>
                  <div className="prevCeoMainContentWrapper">
                    <h2 style={{ color: props.websiteData?.primaryColor }}>
                      Team Member Name
                    </h2>
                    <p style={{ color: props.websiteData?.secondaryColor }}>
                      Team Member Qualification
                    </p>
                    <div className="TitleCeoContent">
                      <span
                        className="prevTagLine"
                        style={{ color: props.websiteData?.secondaryColor }}
                      >
                        Team Member Job Role
                      </span>
                      <span
                        className="borderLine"
                        style={{ background: "#F282A5" }}
                      ></span>
                    </div>
                    <p style={{ color: props.websiteData?.secondaryColor }}>
                      Team Member Job Role Description goes here
                    </p>
                  </div>
                </div>
              )}
            </Container>
          </div>
          {/* Ceo content  End*/}

          {/* Footer content */}
          <div
            className="prevFooter"
            style={{ background: props.websiteData?.primaryColor }}
          >
            <div className="smallContainer">
              <div className="FooterContent">
                <div className="Footer-Logo">
                  <div className="footer-logo-container ">
                    {props.websiteData?.logo ? (
                      <img
                        src={props.websiteData?.logo}
                        className="img-fluid"
                      />
                    ) : (
                      <img src={dummyLogo} className="img-fluid" />
                    )}
                  </div>
                  <div className="Footer-Social-media">
                    <ul>
                      <li
                        style={{
                          borderColor: props.websiteData?.secondaryColor,
                        }}
                      >
                        <Link to={"#"}>
                          <i
                            class="fa fa-facebook-square"
                            aria-hidden="true"
                            style={{
                              color: props.websiteData?.secondaryColor,
                            }}
                            onClick={() => {
                              props.websiteData?.socialLinks?.pwcInstagram &&
                                window.open(
                                  "https://www.facebook.com/" +
                                    props.websiteData?.socialLinks?.pwcFacebook,
                                  "_blank"
                                );
                            }}
                          ></i>
                        </Link>
                      </li>
                      <li
                        style={{
                          borderColor: props.websiteData?.secondaryColor,
                        }}
                      >
                        <Link to={"#"}>
                          <i
                            style={{ color: props.websiteData?.secondaryColor }}
                            class="fa fa-instagram"
                            aria-hidden="true"
                            onClick={() => {
                              props.websiteData?.socialLinks?.pwcInstagram &&
                                window.open(
                                  "https://www.instagram.com/" +
                                    props.websiteData?.socialLinks
                                      ?.pwcInstagram,
                                  "_blank"
                                );
                            }}
                          ></i>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="FooterLink">
                    <ul>
                      {/* <li>
                        <Link
                          to={"#"}
                          style={{ color: props.websiteData?.secondaryColor }}
                        >
                          Home
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link
                          to={"#"}
                          style={{ color: props.websiteData?.secondaryColor }}
                        >
                          About Us{" "}
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to={"#"}
                          style={{ color: props.websiteData?.secondaryColor }}
                        >
                          Terms and Conditions
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to={"#"}
                          style={{ color: props.websiteData?.secondaryColor }}
                        >
                          Contact
                        </Link>
                      </li> */}
                    </ul>
                  </div>

                  <hr style={{ color: props.websiteData?.secondaryColor }} />
                  <div className="copyRight">
                    <p
                      className="mb-2"
                      style={{ color: props.websiteData?.secondaryColor }}
                    >
                      © Copyright {moment().format("YYYY")} • All Rights
                      Reserved.
                    </p>
                    <p style={{ color: props.websiteData?.secondaryColor }}>
                      <u>Powered by Faces</u>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer content End*/}
          </div>
        </div>
      )}

      {isVisibleColorPicker && (
        <>
          {props.edit ? (
            <>
              {/* {console.log(, 'props.websiteData')} */}
              <div className="formInnerHeight" style={style}>
                {colorType === "Primary" ? (
                  <ImageTitleComponent {...stepSixPrimaryData} />
                ) : colorType === "Secondary" ? (
                  <ImageTitleComponent {...stepSixSecondaryData} />
                ) : colorType === "Tertiary" ? (
                  <ImageTitleComponent {...stepSixTertiaryData} />
                ) : null}
                {/* <div
                  class="colorWraper"
                  id="accordionExample"
                  data-aos="fade-up"
                  data-aos-duration={700}
                >
                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                      onClick={() => {
                        setColorType("Primary");
                      }}
                    >
                      <p>Primary Colour</p>
                      <span
                        className="colorPicker"
                        style={{ background: props.websiteData?.primaryColor }}
                      />
                    </button>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="selectColorWrapper">
                          <CustomColorPicker
                            websiteData={props.websiteData}
                            setWebsiteData={props.setWebsiteData}
                            centerColor={centerColor}
                            setCenterColor={setCenterColor}
                            setCustomColorsSecondary={setCustomColorsSecondary}
                            setCustomColorsTertiary={setCustomColorsTertiary}
                            customColorsSecondary={customColorsSecondary}
                            customColorsTertiary={customColorsTertiary}
                            setCenterColorIndex={setCenterColorIndex}
                            centerColorIndex={centerColorIndex}
                            setCenterSecondaryColorIndex={
                              setCenterSecondaryColorIndex
                            }
                            centerSecondaryColorIndex={
                              centerSecondaryColorIndex
                            }
                            setCenterTertiaryColorIndex={
                              setCenterTertiaryColorIndex
                            }
                            centerTertiaryColorIndex={centerTertiaryColorIndex}
                            primary
                            // color={props?.websiteData?.color}
                            // handleColorChange={handleColorChange}
                            customColors={customColors}
                            setCustomColors={setCustomColors}
                            alpha={alpha}
                            handleAlphaChange={handleAlphaChange}
                            sliderTrackStyle={sliderTrackStyle}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                     <h2 class="accordion-header" id="headingTwo"> 
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      onClick={() => {
                        setColorType("Secondary");
                      }}
                    >
                      <p>Text Colour</p>
                      <span
                        className="colorPicker"
                        style={{
                          background: props.websiteData?.secondaryColor,
                        }}
                      />
                    </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="selectColorWrapper">
                          <CustomColorPicker
                            websiteData={props.websiteData}
                            setWebsiteData={props.setWebsiteData}
                            centerColor={centerColor}
                            setCenterColor={setCenterColor}
                            secondary
                            setCustomColorsSecondary={setCustomColorsSecondary}
                            setCustomColorsTertiary={setCustomColorsTertiary}
                            customColorsSecondary={customColorsSecondary}
                            customColorsTertiary={customColorsTertiary}
                            setCenterColorIndex={setCenterColorIndex}
                            centerColorIndex={centerColorIndex}
                            setCenterSecondaryColorIndex={
                              setCenterSecondaryColorIndex
                            }
                            centerSecondaryColorIndex={
                              centerSecondaryColorIndex
                            }
                            setCenterTertiaryColorIndex={
                              setCenterTertiaryColorIndex
                            }
                            centerTertiaryColorIndex={centerTertiaryColorIndex}
                            // color={props?.websiteData?.color}
                            // handleColorChange={handleColorChange}
                            customColors={customColors}
                            setCustomColors={setCustomColors}
                            alpha={alpha}
                            handleAlphaChange={handleAlphaChange}
                            sliderTrackStyle={sliderTrackStyle}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                   <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                      onClick={() => {
                        setColorType("Tertiary");
                      }}
                    >
                      <p>Tertiary Colour</p>
                      <span
                        className="colorPicker"
                        style={{ background: props.websiteData?.tertiaryColor }}
                      />
                    </button>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="selectColorWrapper">
                          <CustomColorPicker
                            websiteData={props.websiteData}
                            setWebsiteData={props.setWebsiteData}
                            centerColor={centerColor}
                            setCenterColor={setCenterColor}
                            tertiary
                            setCustomColorsSecondary={setCustomColorsSecondary}
                            setCustomColorsTertiary={setCustomColorsTertiary}
                            customColorsSecondary={customColorsSecondary}
                            customColorsTertiary={customColorsTertiary}
                            setCenterColorIndex={setCenterColorIndex}
                            centerColorIndex={centerColorIndex}
                            setCenterSecondaryColorIndex={
                              setCenterSecondaryColorIndex
                            }
                            centerSecondaryColorIndex={
                              centerSecondaryColorIndex
                            }
                            setCenterTertiaryColorIndex={
                              setCenterTertiaryColorIndex
                            }
                            centerTertiaryColorIndex={centerTertiaryColorIndex}
                            // color={props?.websiteData?.color}
                            // handleColorChange={handleColorChange}
                            customColors={customColors}
                            setCustomColors={setCustomColors}
                            alpha={alpha}
                            handleAlphaChange={handleAlphaChange}
                            sliderTrackStyle={sliderTrackStyle}
                          />
                        </div>
                      </div>
                    </div>
                  </div> 
                </div> */}
               <div className="select-color">
                    <NewCustomeColorPicker label="Primary Colour"
                            open={openPrimary}
                            setOpen={setOpenPrimary}
                            setWebsiteData={props.setWebsiteData}
                            centerColor={centerColor}
                            setCenterColor={setCenterColor}
                            count={count}
                            setCount={setCount}
                            websiteData={props.websiteData}
                            primary
                            setCustomColorsSecondary={setCustomColorsSecondary}
                            setCustomColorsTertiary={setCustomColorsTertiary}
                            customColorsSecondary={customColorsSecondary}
                            customColorsTertiary={customColorsTertiary}
                            setCenterColorIndex={setCenterColorIndex}
                            centerColorIndex={centerColorIndex}
                            setCenterSecondaryColorIndex={
                            setCenterSecondaryColorIndex
                            }
                            centerSecondaryColorIndex={centerSecondaryColorIndex}
                            setCenterTertiaryColorIndex={
                            setCenterTertiaryColorIndex
                            }
                            centerTertiaryColorIndex={centerTertiaryColorIndex}
                            // color={props?.websiteData?.color}
                            // handleColorChange={handleColorChange}
                            customColors={customColors}
                            setCustomColors={setCustomColors}
                            alpha={alpha}
                            handleAlphaChange={handleAlphaChange}
                            sliderTrackStyle={sliderTrackStyle}
                            setColorType={setColorType}
                    
                    
                    />
                     <NewCustomeColorPicker
                             open={openSecondary}
                             setOpen={setOpenSecondary} 
                            label="Text Colour" 
                            websiteData={props.websiteData}
                            setWebsiteData={props.setWebsiteData}
                            centerColor={centerColor}
                            setCenterColor={setCenterColor}
                            count={count}
                            setCount={setCount}
                            secondary
                            setCustomColorsSecondary={setCustomColorsSecondary}
                            setCustomColorsTertiary={setCustomColorsTertiary}
                            customColorsSecondary={customColorsSecondary}
                            customColorsTertiary={customColorsTertiary}
                            setCenterColorIndex={setCenterColorIndex}
                            centerColorIndex={centerColorIndex}
                            setCenterSecondaryColorIndex={
                            setCenterSecondaryColorIndex
                            }
                            centerSecondaryColorIndex={centerSecondaryColorIndex}
                            setCenterTertiaryColorIndex={
                            setCenterTertiaryColorIndex
                            }
                            centerTertiaryColorIndex={centerTertiaryColorIndex}
                            // color={props?.websiteData?.color}
                            // handleColorChange={handleColorChange}
                            customColors={customColors}
                            setCustomColors={setCustomColors}
                            alpha={alpha}
                            handleAlphaChange={handleAlphaChange}
                            sliderTrackStyle={sliderTrackStyle}
                            setColorType={setColorType}
                    /> 
                        </div> 
              </div>
              <div className="FormButtonWrapper editUpdateButton">
                <StepButton
                  isLoading={saveColorsLoading}
                  onClick={() => {
                    if (colorType === "Primary") {
                      initiateSaveWebsiteColors(
                        {
                          pwcPrimaryColor: props.websiteData?.primaryColor,
                          pwcSecondaryColor: props.websiteData?.secondaryColor,
                        },
                        (responseData) => {
                          setColorType("Secondary");
                          // setColorSavedStatus(true);
                          setTempSaveColorData({
                            ...props.websiteData,
                            primaryColor: props.websiteData?.primaryColor,
                            secondaryColor: props.websiteData?.secondaryColor,
                          });
                          initGetWebsiteDataResponseFunction();
                        },
                        (error) => {
                          console.log(error);
                        }
                      );
                    } else if (colorType === "Secondary") {
                      console.log(customColors, "customColors");
                      initiateSaveWebsiteColors(
                        {
                          pwcPrimaryColor: props.websiteData?.primaryColor,
                          pwcSecondaryColor: props.websiteData?.secondaryColor,
                        },
                        (responseData) => {
                          // setColorType("Tertiary");
                          // setColorSavedStatus(true);
                          setTempSaveColorData({
                            ...props.websiteData,
                            primaryColor: props.websiteData?.primaryColor,
                            secondaryColor: props.websiteData?.secondaryColor,
                          });
                          initGetWebsiteDataResponseFunction();
                        },
                        (error) => {
                          console.log(error);
                        }
                      );
                    }
                  }}
                  blue={true}
                  label={props.edit && "Update changes"}
                  icon={<FiChevronRight className="arrow_next" />}
                />
                {props.websiteData?.is_active && (
                  <StepButton
                    // disabled
                    blue={true}
                    label={"Go to Dashboard"}
                    // isLeftIcon={true}
                    // leftIcon={<FiChevronLeft className="arrow_next" />}
                    onClick={() => navigate(businessDashboardRoutePattern)}
                  />
                )}
              </div>
            </>
          ) : (
            <div>
              <div className="formInnerHeight" style={style}>
                {colorType === "Primary" ? (
                  <ImageTitleComponent {...stepSixPrimaryData} />
                ) : colorType === "Secondary" ? (
                  <ImageTitleComponent {...stepSixSecondaryData} />
                ) : colorType === "Tertiary" ? (
                  <ImageTitleComponent {...stepSixTertiaryData} />
                ) : null}
                <div className="select-color">
                    <NewCustomeColorPicker label="Primary Colour"
                            open={openPrimary}
                            setOpen={setOpenPrimary}
                            setWebsiteData={props.setWebsiteData}
                            centerColor={centerColor}
                            setCenterColor={setCenterColor}
                            count={count}
                            setCount={setCount}
                            websiteData={props.websiteData}
                            primary
                             setCustomColorsSecondary={setCustomColorsSecondary}
                            setCustomColorsTertiary={setCustomColorsTertiary}
                            customColorsSecondary={customColorsSecondary}
                            customColorsTertiary={customColorsTertiary}
                            setCenterColorIndex={setCenterColorIndex}
                            centerColorIndex={centerColorIndex}
                            setCenterSecondaryColorIndex={
                            setCenterSecondaryColorIndex
                            }
                            centerSecondaryColorIndex={centerSecondaryColorIndex}
                            setCenterTertiaryColorIndex={
                            setCenterTertiaryColorIndex
                            }
                            centerTertiaryColorIndex={centerTertiaryColorIndex}
                            // color={props?.websiteData?.color}
                            // handleColorChange={handleColorChange}
                            customColors={customColors}
                            setCustomColors={setCustomColors}
                            alpha={alpha}
                            handleAlphaChange={handleAlphaChange}
                            sliderTrackStyle={sliderTrackStyle}
                            setColorType={setColorType}
                    
                    
                    />
                    <NewCustomeColorPicker
                             open={openSecondary}
                             setOpen={setOpenSecondary} 
                            label="Text Colour" 
                            websiteData={props.websiteData}
                            setWebsiteData={props.setWebsiteData}
                            centerColor={centerColor}
                            setCenterColor={setCenterColor}
                            count={count}
                            setCount={setCount}
                            secondary
                            setCustomColorsSecondary={setCustomColorsSecondary}
                            setCustomColorsTertiary={setCustomColorsTertiary}
                            customColorsSecondary={customColorsSecondary}
                            customColorsTertiary={customColorsTertiary}
                            setCenterColorIndex={setCenterColorIndex}
                            centerColorIndex={centerColorIndex}
                            setCenterSecondaryColorIndex={
                            setCenterSecondaryColorIndex
                            }
                            centerSecondaryColorIndex={centerSecondaryColorIndex}
                            setCenterTertiaryColorIndex={
                            setCenterTertiaryColorIndex
                            }
                            centerTertiaryColorIndex={centerTertiaryColorIndex}
                            // color={props?.websiteData?.color}
                            // handleColorChange={handleColorChange}
                            customColors={customColors}
                            setCustomColors={setCustomColors}
                            alpha={alpha}
                            handleAlphaChange={handleAlphaChange}
                            sliderTrackStyle={sliderTrackStyle}
                            setColorType={setColorType}
                    />
                </div>
               {/* {colorType === "Primary" && 
                (<div className="sepratePages">
                    <div className="colorHeader">
                      <p>Primary Colour</p>
                      <span
                        className="colorPicker"
                        style={{ background: props.websiteData?.primaryColor }}
                      />
                    </div>
                    <div className="colorWrapperBody">
                       <CustomColorPicker
                        websiteData={props.websiteData}
                        setWebsiteData={props.setWebsiteData}
                        centerColor={centerColor}
                        setCenterColor={setCenterColor}
                        count={count}
                        setCount={setCount}
                        primary
                        setCustomColorsSecondary={setCustomColorsSecondary}
                        setCustomColorsTertiary={setCustomColorsTertiary}
                        customColorsSecondary={customColorsSecondary}
                        customColorsTertiary={customColorsTertiary}
                        setCenterColorIndex={setCenterColorIndex}
                        centerColorIndex={centerColorIndex}
                        setCenterSecondaryColorIndex={
                          setCenterSecondaryColorIndex
                        }
                        centerSecondaryColorIndex={centerSecondaryColorIndex}
                        setCenterTertiaryColorIndex={
                          setCenterTertiaryColorIndex
                        }
                        centerTertiaryColorIndex={centerTertiaryColorIndex}
                        // color={props?.websiteData?.color}
                        // handleColorChange={handleColorChange}
                        customColors={customColors}
                        setCustomColors={setCustomColors}
                        alpha={alpha}
                        handleAlphaChange={handleAlphaChange}
                        sliderTrackStyle={sliderTrackStyle}
                      /> 
                    </div>
                  </div>
                )}
                {colorType === "Secondary" && (
                  <div className="sepratePages">
                    <div className="colorHeader">
                      <p>Text Colour</p>
                      <span
                        className="colorPicker"
                        style={{
                          background: props.websiteData?.secondaryColor,
                        }}
                      />
                    </div>
                    <div className="colorWrapperBody">
                      <CustomColorPicker
                        websiteData={props.websiteData}
                        setWebsiteData={props.setWebsiteData}
                        centerColor={centerColor}
                        setCenterColor={setCenterColor}
                        count={count}
                        setCount={setCount}
                        secondary
                        setCustomColorsSecondary={setCustomColorsSecondary}
                        setCustomColorsTertiary={setCustomColorsTertiary}
                        customColorsSecondary={customColorsSecondary}
                        customColorsTertiary={customColorsTertiary}
                        setCenterColorIndex={setCenterColorIndex}
                        centerColorIndex={centerColorIndex}
                        setCenterSecondaryColorIndex={
                          setCenterSecondaryColorIndex
                        }
                        centerSecondaryColorIndex={centerSecondaryColorIndex}
                        setCenterTertiaryColorIndex={
                          setCenterTertiaryColorIndex
                        }
                        centerTertiaryColorIndex={centerTertiaryColorIndex}
                        // color={props?.websiteData?.color}
                        // handleColorChange={handleColorChange}
                        customColors={customColors}
                        setCustomColors={setCustomColors}
                        alpha={alpha}
                        handleAlphaChange={handleAlphaChange}
                        sliderTrackStyle={sliderTrackStyle}
                      />
                    </div>
                  </div>
                )}
                {/* {colorType === "Tertiary" && (
                  <div className="sepratePages">
                    <div className="colorHeader">
                      <p>Second Text Colour</p>
                      <span
                        className="colorPicker"
                        style={{ background: props.websiteData?.tertiaryColor }}
                      />
                    </div>
                    <div className="colorWrapperBody">
                      <CustomColorPicker
                        websiteData={props.websiteData}
                        setWebsiteData={props.setWebsiteData}
                        centerColor={centerColor}
                        setCenterColor={setCenterColor}
                        count={count}
                        setCount={setCount}
                        tertiary
                        setCustomColorsSecondary={setCustomColorsSecondary}
                        setCustomColorsTertiary={setCustomColorsTertiary}
                        customColorsSecondary={customColorsSecondary}
                        customColorsTertiary={customColorsTertiary}
                        setCenterColorIndex={setCenterColorIndex}
                        centerColorIndex={centerColorIndex}
                        setCenterSecondaryColorIndex={
                          setCenterSecondaryColorIndex
                        }
                        centerSecondaryColorIndex={centerSecondaryColorIndex}
                        setCenterTertiaryColorIndex={
                          setCenterTertiaryColorIndex
                        }
                        centerTertiaryColorIndex={centerTertiaryColorIndex}
                        // color={props?.websiteData?.color}
                        // handleColorChange={handleColorChange}
                        customColors={customColors}
                        setCustomColors={setCustomColors}
                        alpha={alpha}
                        handleAlphaChange={handleAlphaChange}
                        sliderTrackStyle={sliderTrackStyle}
                      />
                    </div>
                  </div>
                )} */}
              </div>
              <div className="inputWrapper">
                <div className="formWrapper">
                  <div className="FormButtonWrapper">
                    <StepButton
                      isLoading={saveColorsLoading}
                      onClick={() => {
                        // if (colorType === "Primary") {
                        //   props.trackActivity({ activityStep: 8 });
                        //   console.log(customColors, "customColors");
                        //   initiateSaveWebsiteColors(
                        //     {
                        //       pwcPrimaryColor: props.websiteData?.primaryColor,
                        //       pwcSecondaryColor:
                        //         props.websiteData?.secondaryColor,
                        //     },
                        //     (responseData) => {
                        //       setColorType("Secondary");
                        //     },
                        //     (error) => {
                        //       console.log(error);
                        //     }
                        //   );
                        // } else if (colorType === "Secondary") {
                          props.trackActivity({ activityStep: 10 });
                          console.log(
                            props.websiteData?.tertiaryColor,
                            "tertiaryColor"
                          );

                          console.log(customColors, "customColors");
                          initiateSaveWebsiteColors(
                            {
                              pwcPrimaryColor: props.websiteData?.primaryColor,
                              pwcSecondaryColor:
                                props.websiteData?.secondaryColor,
                            },
                            (responseData) => {
                              // setColorType("Tertiary");
                              props.setStep(props.step + 1);
                            },
                            (error) => {
                              console.log(error);
                            }
                          );
                        // } 
                        // else {
                        //   props.trackActivity({ activityStep: 10 });
                        //   initiateSaveWebsiteColors(
                        //     {
                        //       pwcTertiaryColor:
                        //         props.websiteData?.tertiaryColor,
                        //     },
                        //     (responseData) => {
                        //       // props.trackActivity({ activityStep: props.step });
                        //       props.setStep(props.step + 1);
                        //     },
                        //     (error) => {
                        //       console.log(error);
                        //     }
                        //   );
                        // }
                        // else {
                        //   props.trackActivity({ activityStep: 10 });
                        //   initiateSaveWebsiteColors(
                        //     {
                        //       pwcTertiaryColor:
                        //         props.websiteData?.tertiaryColor,
                        //     },
                        //     (responseData) => {
                        //       props.trackActivity({ activityStep: props.step });

                        //     },
                        //     (error) => {
                        //       console.log(error);
                        //     }
                        //   );
                        // }
                      }}
                      blue={true}
                      label={props.edit ? "update changes" : "Next"}
                      icon={<FiChevronRight className="arrow_next" />}
                    />
                    {!props.edit && (
                      <SkipButtonComp
                        onClick={() => {
                          props.setWebsiteData({
                            ...props.websiteData,
                            primaryColor: tempSaveColorData?.primaryColor,
                            secondaryColor: tempSaveColorData?.secondaryColor,
                            tertiaryColor: tempSaveColorData?.tertiaryColor,
                          });
                        //   if (colorType === "Primary") {
                        //     props.trackActivity({ activityStep: 8 });
                        //     setCenterSecondaryColorIndex(
                        //       customColors.indexOf(
                        //         tempSaveColorData?.secondaryColor
                        //       )
                        //     );
                        //     setColorType("Secondary");
                        //   } else if (colorType === "Secondary") {
                            props.trackActivity({ activityStep: 10 });
                            setCenterTertiaryColorIndex(
                              customColors.indexOf(
                                tempSaveColorData?.tertiaryColor
                              )
                            );
                            // setColorType("Tertiary");
                            props.setStep(props.step + 1);
                        //   }
                          // else {
                          //   props.trackActivity({ activityStep: 10 });
                          //   props.setStep(props.step + 1);
                          // }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default WebsitePrev;
