import React, { useState, useEffect } from "react";
import "./FinanceHome.scss";
import FinanceBanner from "./FinanceHomeComponent/FinanceBanner/FinanceBanner";
import { ReactComponent as AboutYouIcon } from "../../assets/finance-registration/about_you.svg";
import { ReactComponent as AboutBusinessIcon } from "../../assets/finance-registration/about_business.svg";
import { ReactComponent as Checked } from "../../assets/finance-registration/completed_check.svg";
import { ReactComponent as ConsentIcon } from "../../assets/finance-registration/consent.svg";
import { ReactComponent as PartnerClinicIcon } from "../../assets/finance-registration/partner_clinic.svg";
import { ReactComponent as PharmaciesIcon } from "../../assets/finance-registration/pharmacies.svg";
import { ReactComponent as DocIcon } from "../../assets/finance-registration/documents.svg";
import { ReactComponent as LockIcon } from "../../assets/finance-registration/Lock.svg";
import { ReactComponent as Action } from "../../assets/finance-registration/Actions.svg";
import { ReactComponent as Action1 } from "../../assets/finance-registration/Actions1.svg";
import { ReactComponent as Action2 } from "../../assets/finance-registration/Actions2.svg";
import { ReactComponent as Action3 } from "../../assets/finance-registration/Actions3.svg";
import { ReactComponent as Currency } from "../../assets/finance-registration/currency.svg";
import { ReactComponent as BackArrow } from "../../assets/finance-registration/Back.svg";
import { config } from "../../config"
import { useNavigate, useLocation } from "react-router";
import {
	getManageAccountPage,
	getPaymentsControlPage,
	getPrescriberAboutYouPage,
	getPrescriberBusinessDetailsPage,
} from "../../Routes";
import FinanceCard from "./FinanceHomeComponent/FinanceLeftCardComponent/FinanceLeftCardComponent";
import FinanceBenefitCard from "./FinanceHomeComponent/FinanceRightCardComponent/FinanceRightCardComponent";
import FinanceHeader from "./FinanceHomeComponent/FinanceHeaderComponent/FinanceHeaderComponent";
import FinanceAboutYou from "./FinanceAboutYouComponent/FinanceAboutYou";
import FinanceBusinessDetails from "./FinanceBusinessDetails/FinanceBusinessDetails";
import FinanceIdentity from "../pages/finance-identity";
import FinanceTrainingCertificate from "../pages/finance-training-certificate";
import FinanceInsurance from "../pages/finance-insurance/index";
import { getBusinessProfileSettingPage } from "../../Routes";
import FinanceGetPaid from "../pages/finance-get-paid/index"
import FinanceTemsAndCondition from "../pages/finance-terms-and-condition/index"
import FinanceThankYouPage from "./finance-thankYouPage/FinanceThankYouPage";
import { GetFinanceRegistrationStatusAction } from "../redux/actions/finance-registrtion/getFinanceRegistartionStatus"
import { webBaseUrl } from "../../config"
import Insurancebanner from "../common/insurancebanner/Insurancebanner";
import { businessDashboardRoutePattern, getFinanceRegistrationRoute } from "../../Routes"
const FinanceHome = () => {

	const navigate = useNavigate();
	const location = useLocation();
	const [active, setActive] = useState(1)
	const [finish, setFinish] = useState(0)
	const [isCompletedStep, setIsCompletedStep] = useState(false)
	const [comeFromDashBord, setComeFromDashBord] = useState(false)
	// const [disabled,setDisabled]=useState(true)
	const WhatsappLinkRedirect = config.FINANCE_WHATSAPP_LINK

	const { getFinanceRegistrtionStatusQueryData, initGetFinanceRegistrationData } = GetFinanceRegistrationStatusAction()

	const { data } = getFinanceRegistrtionStatusQueryData

	console.log("apiiiii", data?.getUserRegistrationStatusForFinance?.data)
	const completedStep = data?.getUserRegistrationStatusForFinance?.data;


	console.log("completedStep1", completedStep);

	console.log(location?.state?.from, "123020")
	useEffect(() => {
		initGetFinanceRegistrationData()
	}, [])

	//GOTO ABOUT YOU PAGE
	const handleAboutYouPage = () => {
		setActive(2)
	};



	const handleBusinessDetailsPage = () => {

		setActive(3)
		setComeFromDashBord(true)
	};

	const handleFinaceIdentity = () => {
		setActive(4)
		setComeFromDashBord(true)
	}

	const handleFinaceTranning = () => {
		setActive(5)
	}

	const handleFinanceInsurance = () => {
		setActive(6)
	}

	const handleFinanceBankDetail = () => {
		setActive(7)
		setComeFromDashBord(true)
	}

	const handleSignture = () => {
		setActive(8)
	}
	const handleThankYoUPage = () => {
		setActive(9)
	}

	// useEffect(()=>{
	//   if(document.referrer===`${getFinanceRegistrationRoute}`){
	//     setComeFromDashBord(true)
	//   }
	// },[])
	// Function to handle back arrow click

	const handleBackClick = () => {


		if (location?.state?.from === "Finance_settings_paymentcontrol") {
			// navigate(getManageAccountPage())
			navigate(getPaymentsControlPage())
		}
		else if (active === 1) {
			window.location.href = webBaseUrl + "/user/update-profile?active-tab=generalprofile11"
		}
		else if (comeFromDashBord) {
			setActive(1)
		}
		else if (active > 1) {
			setActive(active - 1);
		}

	};

	useEffect(() => {
		if (completedStep) {
			// let completedStepCount=completedStep.steps.reduce(

			//   (acc, step) => acc +(step.is_completed ? 1 : 0),0

			// );
			// setFinish(completedStepCount)
			setFinish(completedStep?.onboardingCompletionPercentage)

		}
	}, [completedStep])

	console.log("finish", finish)
	console.log("isCompletedStep", completedStep?.onboardingCompletionPercentage);

	return (
		<div className="prescriber-home-container">
			<Insurancebanner />
			<FinanceHeader knowmore={true} isTranningLink={active === 5 ? true : false} onBackClick={handleBackClick} />

			{active === 1 &&
				<>
					<FinanceBanner
						title={"Faces Finance "}
						desc={
							"Offer Buy now payl8r and other pay monthly plans to your customers. 98% of people aged 18-50 use payment plans. Reach a bigger audience with faces finance."
						}
						isBorder={!completedStep?.registration_complete && completedStep?.registration_complete !== undefined}
						isAdd={true}
						icon={Currency}
						uppercontent="FREE £50 when you join"
						lowercontent="£50 to spend when you spend over £150"
						currentStep={finish}
						totalSteps={4}
						isTranningBanner={false}
						isInsuranceBanner={false}

					/>

					<div className="WrapperBox">
						<div className="row justify-content-between flex-column flex-md-row">
							<div className="col col-md-6">
								<div className="prescriber-left-card-container">
									{/* ABOUT YOU CARD  */}
									<FinanceCard
										icon={AboutYouIcon}
										title="About you"
										content="Let’s start by getting to know you, the finance need some details"
										buttonText="Get started"
										onClick={handleAboutYouPage}
										currentStep={finish}
										// checked={(finish > 0 ) ? true : false}
										checked={completedStep?.steps[0]?.is_completed ? true : false}
										disabled={false}
										style={{
											backgroundColor: completedStep?.steps[0]?.is_completed ? 'isBgWhite' : 'isBgBlue',
											color: completedStep?.steps[0]?.is_completed ? "isBgWhite" : "isBgBlue"
										}}
										className={completedStep?.steps[0]?.is_completed ? 'isBgWhite' : 'isBgBlue'}
									// button not disabled 
									/>

									{/* ABOUT BUSINESS CARD */}
									<FinanceCard
										icon={AboutBusinessIcon}
										title="About your business"
										content="Give us an insight to the type of business you are"
										buttonText="Add"
										onClick={handleBusinessDetailsPage}
										currentStep={finish}
										// checked={(finish > 1 )? true : false}
										checked={completedStep?.steps[1]?.is_completed ? true : false}

										disabled={completedStep?.steps[0]?.is_completed ? false : true}
									/>

									{/* DOCUMENTATION CARD */}
									<FinanceCard
										icon={DocIcon}
										title="Documentation"
										content="Upload documents of identification, training certificates and insurance."
										buttonText="Add"
										onClick={handleFinaceIdentity}
										currentStep={finish}
										checked={completedStep?.steps[2]?.is_completed ? true : false}
										disabled={completedStep?.steps[1]?.is_completed ? false : true}
									/>

									{/* Payment CARD */}
									<FinanceCard
										icon={LockIcon}
										title="How to get paid"
										content="Add your mobile number to enhance your account's security and stay updated."
										buttonText="Secure"
										onClick={handleFinanceBankDetail}
										currentStep={finish}
										checked={completedStep?.steps[3]?.is_completed ? true : false}
										disabled={completedStep?.steps[2]?.is_completed ? false : true}
									/>




								</div>
								<div className="whatsapp-container mobile-none">
									<div className="needSec">
										<span>Need help?</span>
									</div>
									<button onClick={() => {
										window.open(WhatsappLinkRedirect, "_blank");
									}}>
										<i className="fa fa-whatsapp" aria-hidden="true"></i>Whatsapp us
									</button>
								</div>
							</div>

							<div className="col col-md-5">
								<div className="prescriber-right-card-container">
									{/* No risk card */}
									<FinanceBenefitCard
										icon={Action}
										title="No risk"
										content="Absolutely risk free, never worry about client repayments we take that risk so you don’t have to"
									/>

									<FinanceBenefitCard
										icon={Action1}
										title="Payout in 24-48 hours"
										content="Receive payment within 48hours from the day of treatment"
									/>

									<FinanceBenefitCard
										icon={Action2}
										title="No set-up cost or monthly fees"
										content="Zero set-up costs or monthly fees or subscription we are a pay as you go service."
									/>

									<FinanceBenefitCard
										icon={Action3}
										title="Increased client retention"
										content="Increase client retention and spend with convenient monthly payments. one treatment leads to the repeat treatment."
									/>

								</div>
							</div>

							<div className="whatsapp-container desktop-none">
								<button>
									<i className="fa fa-whatsapp" aria-hidden="true"></i>Whatsapp
									us
								</button>
							</div>
						</div>
					</div>
				</>
			}

			{active === 2 &&
				<>
					<FinanceAboutYou onNextStep={() => setActive(3)} afterSubmit={() => initGetFinanceRegistrationData()} setIsCompletedStep={setIsCompletedStep} finish={finish} />
				</>
			}

			{active === 3 &&
				<>
					<FinanceBusinessDetails onNextStep={() => setActive(4)} setComeFromDashBord={setComeFromDashBord} afterSubmit={() => initGetFinanceRegistrationData()} setIsCompletedStep={setIsCompletedStep} finish={finish} />
				</>
			}
			{
				active === 4 &&
				<>
					<FinanceIdentity onNextStep={() => setActive(5)} setComeFromDashBord={setComeFromDashBord} afterSubmit={() => initGetFinanceRegistrationData()} setIsCompletedStep={setIsCompletedStep} finish={finish} />
				</>
			}
			{
				active === 5 &&
				<>
					<FinanceTrainingCertificate onNextStep={() => setActive(6)} setComeFromDashBord={setComeFromDashBord} type="training" afterSubmit={() => initGetFinanceRegistrationData()} setIsCompletedStep={setIsCompletedStep} finish={finish} />
				</>
			}
			{
				active === 6 &&
				<>
					<FinanceInsurance onNextStep={() => setActive(7)} setComeFromDashBord={setComeFromDashBord} type="insurance" afterSubmit={() => initGetFinanceRegistrationData()} setIsCompletedStep={setIsCompletedStep} finish={finish} />
				</>
			}

			{
				active === 7 &&
				<>
					<FinanceGetPaid onNextStep={() => setActive(8)} setComeFromDashBord={setComeFromDashBord} afterSubmit={() => initGetFinanceRegistrationData()} setIsCompletedStep={setIsCompletedStep} finish={finish} />
				</>
			}

			{
				active === 8 &&
				<>
					<FinanceTemsAndCondition
						onNextStep={() => completedStep?.registration_complete ? setActive(1) : setActive(9)}
						afterSubmit={() => initGetFinanceRegistrationData()}
						setIsCompletedStep={setIsCompletedStep}
						finish={finish}
						setComeFromDashBord={setComeFromDashBord}
					/>

				</>
			}

			{
				active === 9 &&
				<>
					<FinanceThankYouPage onNextStep={() => setActive(1)} afterSubmit={() => setFinish(8)} setIsCompletedStep={setIsCompletedStep} />
				</>
			}
		</div>
	);
};

export default FinanceHome;
