import React, { useState } from 'react';
import CustomRadioButton from '../../../common/CustomRadioButton';
import StepButton from '../../../common/StepButton';
import { useId } from 'react';

function random(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function PreviewQuestionItem({ question, noOtherOption,queId }) {
    const maxOption = noOtherOption ? 1 : 2;
    const checkedIndex = random(0,maxOption);
    
    // const [checkedIndex] = useState(random(0,2));
    const [isOpenTextBox, setIsOpenTextBox] = useState(false);
    const [options, setOptions] = useState([
        { text: 'Yes', checked: false, value: 'Yes' },
        { text: 'No', checked: false, value: 'No' },
        { text: 'Other', checked: false, value: 'Other' },
    ]);

    const handleRadioButtonChange = (event) => {
        const radioValue = event.target.value;
        setOptions(prev => {
            return prev.map(item => {
                if(item.value === radioValue) {
                    return {...item, checked: true}
                }
                return {...item, checked: false};
            })
        })
        if (radioValue === 'Other') {
            setIsOpenTextBox(true);
        } else {
            setIsOpenTextBox(false);
        }
    };

    return (
        <div>
            <p className="preview-question-title">{question}</p>
            <div className="options-wrapper">
                {options.map((option, index) => {
                    const isChecked = checkedIndex === index;
                    if(option.value === 'Other' && noOtherOption) return null;
                    return (<div className="time-slot-content" key={index}>
                        <CustomRadioButton
                            id={option.text + queId}
                            checkedLabelStyles={{border: isChecked ? '3px solid #000000' : '1px solid #878787'}}
                            isblacked={true}
                            text2={option.text || option.text || option.text}
                            // value={option.value}
                            name={"timeSlotGroup-" + queId}
                            checked={isChecked}
                            disabled
                            preselect
                            // onChange={handleRadioButtonChange}
                        />
                    </div>)
                })}
            </div>
            {(checkedIndex === 2 || noOtherOption) && (
                <div className="text-box-other">
                    <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Add your comment here"
                        disabled
                    ></textarea>
                    {!noOtherOption && <StepButton gray className='btn-smart-text' label='Smart text' />}
                </div>
            )}
        </div>
    );
}

export default PreviewQuestionItem;
