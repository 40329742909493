import React ,{useEffect, useState} from 'react'
import "./verifiedBanner.scss"
import { MdVerified } from "react-icons/md";
import {useNavigate} from "react-router-dom"
import {verifiedBannerRoutePattern} from "../../../Routes"
import closeIcon from "../../../images/close_icon.svg"
import CustomModal from "../../modal/CustomModal"
import {HideBecomeFacesVerifiedBannerAction} from "../../redux/actions/hide-become-faces-verified-banner/hideBecomeFacesVerifiedBanner"
import {GetProfileAction} from "../../redux/actions/dashboard/getProfileDataGql"
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader"

const VerifiedBanner = ({setIsHideFacesVerified}) => {

     const navigate=useNavigate();
     const [modelIsOpen,setIsOpen]=useState(false)
     const [skeletonLoader,setSkeletonLoader]=useState(false)
     const {hideBecomeFacesVerifiedBannerQuerydata,initHideBecomeFacesVerifiedBannerData}=HideBecomeFacesVerifiedBannerAction();
     const {getProfileQueryData,initGetProfileData}=GetProfileAction();
     
     console.log("getProfileQueryData",getProfileQueryData);
      
        useEffect(()=>{
          initGetProfileData()
      },[])
     
    //  const handleHideBecomeFacesVerifiedBanner=()=>{
         
    //      initHideBecomeFacesVerifiedBannerData({
    //            isHideBanner:1 
    //        },
    //        (data)=>{
    //             setIsHideFacesVerified(true)
    //            setIsOpen(false)
    //       },(error)=>console.log(error))
    //     }

          

      useEffect(()=>{
        if(skeletonLoader){
          setTimeout(() => {
            
            setSkeletonLoader(false)  
            
          }, 1500);
          console.log("val",skeletonLoader)
        }
      
      },[skeletonLoader])
      
      const handleHideBecomeFacesVerifiedBanner=()=>{
        
        setSkeletonLoader(true)
      initHideBecomeFacesVerifiedBannerData({
                 isHideBanner:1 
             },
             (data)=>{
                    setIsHideFacesVerified(true)
              setIsOpen(false)
       },(error)=>console.log(error))
     
}


 const verifiedScreenButton=()=>{
       navigate(verifiedBannerRoutePattern);
   }
   const handleModel=()=>{
     setIsOpen(true);
 }

  const closeModel=()=>{
     setIsOpen(false)
  }

  return (
    <>
    {
      getProfileQueryData?.loading || skeletonLoader ?
      (
        <SkeletonLoader type={"verifiedBannerSkeleton"}/>
      ):(
        <div className='verifiedBannerSec'>
           {/* <div className='welcomeSec'>
                <h5>Welcome Back!</h5>
                 <p>Hey, [Name], great to see you at [Business Name 1]  </p>
            </div> */}
            <div className='bannerSkeleton'>
               <div className='bannerSkeletonLeft'>
                    <div className='bannerContent'>
                    
                      <div className='bannerContentTitle'>
                            <h6>Become verified business</h6>
                            <MdVerified className='verifiedIcon'/>
                      </div>
                      <div className='bannerContentPera'>
                            <p>Become a verified industry leader. This badge is a symbol of professionalism in the industry.</p>
                      </div>
                    </div>
               </div>

               <div className='bannerSkeletonRight'>
                    <div className='bannerButton'>
                       <div className='verifiedButton'>
                            <button onClick={()=>verifiedScreenButton()} className='becomeBtn'>Become verified business</button>
                       </div>
                       {/* <div className='findMeButton'>
                         <button className='findMebtn'>Find me in settings</button>
                       </div> */}
                    </div>
               </div>
               <div className='closeIconSec' onClick={()=>handleModel()}>
                  <img  className='closeIcon' src={closeIcon} alt='closeIcon' />
                </div>
            </div>
        </div>
      )
        
    }


        {/* ========================================== CUSTOME MODEL======================================================== */}
            
        <CustomModal 
            modalOpen={modelIsOpen} 
            setModalOpen={closeModel} 
            modaltitle={""}
            type={"common-width"}
            modalBody={
                <div className='modelContent'>
                    <div className='modelText'>
                      <h1>Are you sure?</h1>
                      <p>you want to close this banner ?</p>
                      </div>
                   <div className='modelButtons'>
                     <button className='deleteButton' onClick={()=>handleHideBecomeFacesVerifiedBanner()}>Yes</button>
                     <button className='cancelButton' onClick={()=>closeModel()}>No</button>
                   </div>
                </div>
            }
            />
    </>
  )
}

export default VerifiedBanner