import React, { useEffect, useRef, useState } from "react";
import "./form-listing.scss";
import SearchIcon from "../../../images/client/search_icon.svg";
import { ReactComponent as CloseIcon } from "../../../images/close_icon.svg";
import StepButton from "../../common/StepButton";
import gsap from "gsap";
import { GetAfterCareFormList } from "../../redux/actions/formsTemplate/getFormTemplateList";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { CLEAR_AFTERCARE_FORM_LIST } from "../../redux/constants/formsTemplate/FormTemplateConst";
import { Spinner } from "react-bootstrap";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";
import CustomCheckbox from "../../custom-components/custom-checkbox/CustomCheckbox";
import { capitalizeFirstLetter } from "../../../utils/initials";
import { debounce } from "lodash";

const AfterCareList = ({ setIsClose, setSelectedAfterCareData, selectedAfterCareData, onCallbackHandler }) => {
  const [searchText, setSearchText] = useState("");
  const [isSearchedTriggered, setIsSearchedTriggered] = useState(false);

  const [newAfterCare, setNewAfterCare] = useState([]);
  const dispatch = useDispatch()



  //Query
  const { afterCareFormListQueryData, initGetAfterCareFormList } = GetAfterCareFormList()



  //Get AfterCare Data from Reducer
  const { afterCareFormList } = useSelector(state => state?.afterCareFormListReducer)
  const { totalAfterCareFormCount } = useSelector(state => state?.afterCareFormListReducer)
  const { afterCareFormListPage } = useSelector(state => state?.afterCareFormListReducer)
  const { loadedAfterCareFormCount } = useSelector(state => state?.afterCareFormListReducer)

  const initiateAfterCareFormListCall = (refreshList = false) => {
    let newPage = afterCareFormListPage + 1;
    if (refreshList) {
      newPage = 1;

    } else if (totalAfterCareFormCount <= loadedAfterCareFormCount) {
      return;
    }

    initGetAfterCareFormList({
      page: newPage,
      limit: 10,
      search: searchText,

    }, (res) => { }
    )
  };


  const handleAfterCareChange = (aftercareDocId, afterCareName) => {

    const updatedSelectedAfterCare = [...newAfterCare];
    const index = updatedSelectedAfterCare.findIndex((item) => item?.aftercareDocId === aftercareDocId);
    console.log(updatedSelectedAfterCare, index, aftercareDocId, "LOG index", updatedSelectedAfterCare.findIndex((item) => item?.aftercareDocId == aftercareDocId))
    if (index !== -1) {
      // If consentFormId is already in the selected array, remove it
      updatedSelectedAfterCare.splice(index, 1);
    } else {
      // If consentFormId is not in the selected array, add it
      updatedSelectedAfterCare.push({ aftercareDocId: aftercareDocId, aftercareDocName: afterCareName });
    }
    setNewAfterCare(updatedSelectedAfterCare)
  };




  //Get Selected Treatment Data
  const getSelectedConsentData = () => {
    return afterCareFormList?.filter((item, index) => newAfterCare[index]);
  };

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
    setIsSearchedTriggered(true)
  };

  const handleSelectedData = () => {
    setSelectedAfterCareData(newAfterCare)
    if (onCallbackHandler) {
      onCallbackHandler(newAfterCare);
    }
    setIsClose(false)
  }

  // Set initial state when component mounts or when selectedTreatments prop changes
  useEffect(() => {
    setNewAfterCare(selectedAfterCareData);
  }, [selectedAfterCareData]);

  //Call the AfterCare Api
  useEffect(() => {
    dispatch({
      type: CLEAR_AFTERCARE_FORM_LIST
    })
    initiateAfterCareFormListCall(true)
  }, [])


  //DEBOUNCE FOR SEARCH
  // Debounced API call function
  const debouncedApiCall = debounce((query) => {
    // Make the API call using the search query
    // Replace this with your own API call logic
    dispatch({
      type: CLEAR_AFTERCARE_FORM_LIST
    })
    initiateAfterCareFormListCall(true)
  }, 500); // Debounce delay in milliseconds (e.g., 500ms)

  useEffect(() => {
    if ((searchText.length == 0 && isSearchedTriggered) || searchText.length >= 3) {
      // Call the debounced API function with the search query
      debouncedApiCall(searchText);

      // Cleanup function to cancel any pending debounced calls
      return () => {
        debouncedApiCall.cancel();
      };
    }
  }, [searchText]);

  return (
    <div className="form-list-main-container">
      <div className="sticky-header">
        <div className="close-btn">
          <CloseIcon className="close-icon-animation" onClick={() => { setIsClose(false); selectedAfterCareData.length === 0 ? setSelectedAfterCareData([]) : setSelectedAfterCareData(selectedAfterCareData) }}
          />
        </div>
        <h1>Aftercare</h1>
        <p className="sub-heading">Please search and select a aftercare form you require for this appointment.</p>
        <div className="search-container">
          <input type="text" placeholder="Search form" value={searchText} onChange={handleSearchText} />
          <img src={SearchIcon} alt="search-icon" className="search-icon-img" />
          {searchText && <CloseIcon className="cursor-pointer search-close-icon" onClick={() => { setSearchText("") }} />}
        </div>
      </div>

      <div id="scrollableDiv" style={{ height: "70vh", width: "100%", overflowX: "scroll" }}>
        {
          afterCareFormList?.filter(form => form?.aftercareDocStatus === "active")?.length > 0 &&
          <p className="mt-4 mb-4">Recent form</p>
        }
        <InfiniteScroll
          dataLength={loadedAfterCareFormCount}
          next={() => {
            initiateAfterCareFormListCall()
          }}
          hasMore={totalAfterCareFormCount > loadedAfterCareFormCount}

          scrollableTarget="scrollableDiv"

        >
          {

            afterCareFormList?.filter(form => form?.aftercareDocStatus === "active")?.map((item, index) => (
              <div className="form-list-container m-2 cursor-pointer w-auto mb-4" key={index}
                onClick={() => (document.getElementById(item?.aftercareDocId)).addEventListener(('change'), handleAfterCareChange(item?.aftercareDocId, item?.aftercareDocName))}
              >
                <p className="truncated-text-35">{capitalizeFirstLetter(item?.aftercareDocName)}</p>
                <CustomCheckbox
                  className={`form-list-checkbox`}
                  id={item?.aftercareDocId}
                  name={item?.aftercareDocName}
                  checked={newAfterCare.some((selected) => selected?.aftercareDocId === item?.aftercareDocId)} />
              </div>
            ))}
          {
            afterCareFormListQueryData.loading ?
              <SkeletonLoader type={"common-list"} /> : null
          }
          {
            afterCareFormList?.filter(form => form?.aftercareDocStatus === "hidden")?.length > 0 &&
            <p className="mt-4 mb-4">Discover</p>
          }
          {afterCareFormList?.filter(form => form?.aftercareDocStatus === "hidden")?.map((item, index) => (
            <div className="form-list-container m-2 cursor-pointer w-auto mb-4" key={index}
              onClick={() => (document.getElementById(item?.aftercareDocId)).addEventListener(('change'), handleAfterCareChange(item?.aftercareDocId, item?.aftercareDocName))}
            >
              <p className="truncated-text-35">{capitalizeFirstLetter(item?.aftercareDocName)}</p>
              <CustomCheckbox
                className={`form-list-checkbox`}
                id={item?.aftercareDocId}
                name={item?.aftercareDocName}
                checked={newAfterCare.some((selected) => selected?.aftercareDocId === item?.aftercareDocId)} />
            </div>

          ))}
          {
            afterCareFormList?.filter(form => form?.aftercareDocStatus === "active")?.length == 0 &&
            afterCareFormList?.filter(form => form?.aftercareDocStatus === "hidden")?.length == 0 &&
            searchText.length > 0 &&
            <div className="no-data-availa-class">
              <p className="text-center">No form found!</p>
            </div>
          }
        </InfiniteScroll>
      </div>

      <div className="sticky-footer">
        <StepButton disabled={newAfterCare.length === 0} label={"Select"} blue={true} onClick={() => handleSelectedData()}

        />
      </div>
    </div>
  );
};

export default AfterCareList;
