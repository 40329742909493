import { APPOINTMENT_LIST_LOADER_COMPLETE, APPOINTMENT_LIST_LOADER_START, CANCEL_APPOINTMENT, CANCEL_APPOINTMENT_ERROR, CANCEL_APPOINTMENT_SUCCESS, CLEAR_APPOINTMENT_STATUS, CLEAR_CANCEL_APPOINTMENT_STATUS, GET_APPOINTMENT_DETAILS, GET_APPOINTMENT_DETAILS_ERROR, GET_APPOINTMENT_DETAILS_SUCCESS, GET_APPOINTMENT_LIST, GET_APPOINTMENT_LIST_ERROR, GET_APPOINTMENT_LIST_SUCCESS } from "../../constants"



export default function getAppointmentList(data) {

    return {
        type: GET_APPOINTMENT_LIST,
        payload: data
    }
}

export function getAppointmentListSuccess(data) {

    return {
        type: GET_APPOINTMENT_LIST_SUCCESS,
        payload: data,
    }
}

export function getAppointmentListError(data) {

    return {
        type: GET_APPOINTMENT_LIST_ERROR,
        payload: data,
    }
}

export function getAppointmentDetails(data) {

    return {
        type: GET_APPOINTMENT_DETAILS,
        payload: data
    }
}

export function getAppointmentDetailsSuccess(data) {

    return {
        type: GET_APPOINTMENT_DETAILS_SUCCESS,
        payload: data,
    }
}

export function getAppointmentDetailsError(data) {

    return {
        type: GET_APPOINTMENT_DETAILS_ERROR,
        payload: data,
    }
}

export function clearAppointmentStatus() {

    return {
        type: CLEAR_APPOINTMENT_STATUS,
    }
}

export function setAppointmentListLoaderStart() {

    return {
        type: APPOINTMENT_LIST_LOADER_START,
    }
}


export function setAppointmentListLoaderComplete() {

    return {
        type: APPOINTMENT_LIST_LOADER_COMPLETE,
    }
}

export function cancelAppointment(data) {

    return {
        type: CANCEL_APPOINTMENT,
        payload: data,
    }
}

export function cancelAppointmentSuccess(data) {

    return {
        type: CANCEL_APPOINTMENT_SUCCESS,
        payload: data,
    }
}

export function cancelAppointmentError(data) {

    return {
        type: CANCEL_APPOINTMENT_ERROR,
        payload: data,
    }
}

export function clearCancelAppointmentStatus(data) {

    return {
        type: CLEAR_CANCEL_APPOINTMENT_STATUS,
        payload: data,
    }
}
