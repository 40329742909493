import React from 'react'
import cardIcon from '../../../../images/pay_credit.svg'
import rightArrow from '../../../../images/right_arrow.svg'
import payMonthely from '../../../../images/month_pay.svg'
import payInCash from '../../../../images/pay_in_full.svg'
import bankTransfer from '../../../../images/bank transfer.svg'
import './payment-main.scss'
import { useNavigate } from 'react-router'

const SelectPaymentOption = () => {
    const navigate = useNavigate();
    const handlNavigateToPreview = () => {
        navigate('/appointment/payment-preview')
    }

    return (
        <div className='select-payment-option-main'>
            <span>Select payment</span>
            <hr />

            <div className='d-flex justify-content-between mb-3'>
                <div className='select-payment-type d-flex'>
                    <img src={payInCash} alt="" />
                    <span>Cash</span>
                </div>
                <div className='mt-2'>
                    <img src={rightArrow} alt="" />
                </div>
            </div>

            <div className='d-flex justify-content-between mb-3' onClick={handlNavigateToPreview}>
                <div className='select-payment-type d-flex'>
                    <img src={cardIcon} alt="" />
                    <span>Card machine</span>
                </div>
                <div className='mt-2'>
                    <img src={rightArrow} alt="" />
                </div>
            </div>

            <div className='d-flex justify-content-between mb-3'>
                <div className='select-payment-type d-flex'>
                    <img src={payMonthely} alt="" />
                    <span>Pay monthly</span>
                </div>
                <div className='mt-2'>
                    <img src={rightArrow} alt="" />
                </div>
            </div>

            <div className='d-flex justify-content-between mb-5'>
                <div className='select-payment-type d-flex'>
                    <img src={bankTransfer} alt="" />
                    <span>Bank transfer</span>
                </div>
                <div className='mt-2'>
                    <img src={rightArrow} alt="" />
                </div>
            </div>

            <span className='mt-5'>Unavailable options</span>
            <hr />

            <div className='payment-option-disable d-flex justify-content-between mb-3'>
                <div className='select-payment-type d-flex'>
                    <img src={cardIcon} alt="" />
                    <span>Card on file</span>
                </div>
                <div className='mt-2'>
                    <img src={rightArrow} alt="" />
                </div>
            </div>
        </div>
    )
}

export default SelectPaymentOption
