import React, { useState , useEffect } from 'react'
import './HairProductsSecond.scss'
import SubHeading from '../hair-product-common/hair-product-sub-heading/SubHeading'
import StepButton from '../../../common/StepButton'
import HairProductWhatsAppButton from '../hair-product-common/hair-product-whatsApp-btn/HairProductWhatsAppButton'

const HairProductsSecond = ({ genderprop, OnhandleContinue }) => {
    const [gender, setGender] = useState(() => JSON.parse(JSON.stringify(genderprop)))
    const [genderError, setGenderError] = useState("")
    const onHandleChange = (e) => {
        setGender(e.target.value)
        setGenderError("")
    }
    const handleContinue = () => {
        if (!gender) {
            setGenderError("Please Select Gender")
        } else {
            OnhandleContinue(gender)
        }
    }
    useEffect(()=>{
        window.scrollTo(0, 0);
     },[])
    return (
        <>
            <SubHeading
                title="What was your assigned gender at birth?  "
            />
            <div className='hair-product-Second flex-gap-normal'>
                <div className="btn-radio">
                    <div
                        className={`cursor-pointer ${gender === "male" ? "active-radio-button" : "form-check"}`}
                        onClick={() => (setGender("male"), setGenderError(""))}
                    >
                        <label class="form-check-label" for="flexRadioDefault1">
                            Male
                        </label>
                        <input
                            class="form-check-input"
                            type="radio"
                            name="gender"
                            id="flexRadioDefault1"
                            value="male"
                            checked={gender === "male" ? true : false}
                            onChange={onHandleChange}
                        />
                    </div>
                    <div className='errorWrapper'>
                    <div
                        className={`cursor-pointer ${gender === "female" ? "active-radio-button" : "form-check"}`}
                        onClick={() => (setGender("female"), setGenderError(""))}
                    >
                        <label class="form-check-label" for="flexRadioDefault1">
                            Female
                        </label>
                        <input
                            class="form-check-input"
                            type="radio"
                            name="gender"
                            id="flexRadioDefault1"
                            checked={gender === "female" ? true : false}
                            value="female"
                            onChange={onHandleChange}
                        />
                    </div>
                    {genderError && <span className="hair-product-error-message">{genderError}</span>}
                    </div>
                </div>
                <div className='bottomStickeyBtn'>
                    <div className="continue-btn-div">
                        <StepButton label={"Continue"} blue={true} onClick={handleContinue} />
                    </div>
                    <div className="whatsapp-margin">
                        <HairProductWhatsAppButton />
                    </div>
                </div>

            </div>
        </>
    )
}

export default HairProductsSecond