import React from 'react'
import './hair-product-thankyou-page.scss'

const HairProductThankyouPage = () => {
    return (
        <>
            <div className="hair-product-thankyou-page-main-div">
                <div className="Hair-thanyou-wrapper">
                    <h1>
                        Thank you
                    </h1>
                    <p>
                        Your request has been sent to the team. You will be notified regarding all the updates for this request via email.
                    </p>
                </div>
            </div>
        </>
    )
}

export default HairProductThankyouPage