import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_AFTERCARE_FORM_DATA_LIST, GET_AFTERCARE_FORM_DETAILS, GET_CONSANT_FORM_DATA_LIST, GET_CONSENT_FORM_DETAILS, GET_USER_AFTERCARE_FORM_DATA_LIST, GET_USER_CONSANT_FORM_DATA_LIST, GET_WALK_IN_QUESTION_ANSWER_DATA } from "../../constants/walk-in-form-const/clientWalkInConst";
import { toast } from "react-toastify";


// == ADMIN CONSENT FORM LIST ==
const GET_CONSANT_FORM_LIST = gql`
query GetDefaultConsentFormTemplateList($search: String, $page: Int, $limit: Int) {
    getDefaultConsentFormTemplateList(search: $search, page: $page, limit: $limit) {
      success
      message
      data {
        consentForms {
          consentFormId
          consentFormParentId
          consentFormName
          consentFormCategory
          consentFormStatus
        }
        total
        totalPages
        currentPage
      }
    }
  }
`;

// // var page;
export const ConsentFormList = () => {
  const dispatch = useDispatch();
  const [getConsentFormList, consentFormListData] = useLazyQuery(
    GET_CONSANT_FORM_LIST,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getDefaultConsentFormTemplateList: responseData }) => {

        dispatch({
          type: GET_CONSANT_FORM_DATA_LIST,
          payload: responseData.data,
          // page
        });
        if (responseData.success) {
          console.log(responseData.data, 'responseData==');

        } else {
          console.log(responseData.data, 'error==');
        }


      },
    }
  );
  const consentFomrListQueryData = consentFormListData;
  const initConsentFormList = (data) => {
    // page = data.page
    getConsentFormList({
      variables: data,
    });
  };
  return { initConsentFormList, consentFomrListQueryData };
};

// == USER  CONSENT FORM LIST == 
const GET_USER_CONSENT_FORM_LIST = gql`
query GetUserConsentFormTemplateList {
  getUserConsentFormTemplateList {
    success
    message
    data {
      consentForms {
        consentFormId
        consentFormParentId
        consentFormName
        consentFormCategory
        consentFormStatus
      }
      total
      totalPages
      currentPage
    }
  }
}
`;

// // var page;
export const UserConsentFormList = () => {
  const dispatch = useDispatch();
  const [getUserConsentFormList, userConsentFormListData] = useLazyQuery(
    GET_USER_CONSENT_FORM_LIST,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getUserConsentFormTemplateList: responseData }) => {

        dispatch({
          type: GET_USER_CONSANT_FORM_DATA_LIST,
          payload: responseData.data,
          // page
        });
        if (responseData.success) {
          console.log(responseData.data, 'responseData==');

        } else {
          console.log(responseData.data, 'error==');
        }


      },
    }
  );
  const userConsentFomrListQueryData = userConsentFormListData;
  const initUserConsentFormList = (data) => {
    // page = data.page
    getUserConsentFormList({
      variables: data,
    })
  };
  return { initUserConsentFormList, userConsentFomrListQueryData };
};



// ========================AFTERCARE FORM========================

const GET_AFTERCARE_FORM_LIST = gql`
query GetDefaultAftercareDocumentTemplateList($search: String, $page: Int, $limit: Int) {
    getDefaultAftercareDocumentTemplateList(search: $search, page: $page, limit: $limit) {
      success
      message
      data {
        aftercareDocs {
          aftercareDocId
          aftercareDocParentId
          aftercareDocName
          aftercareDocStatus
        }
        total
        totalPages
        currentPage
      }
    }
  }
`;

// // var page;
export const AfterCareFormList = () => {
  const dispatch = useDispatch();
  const [getAfterCareFormList, afterCareFormListData] = useLazyQuery(
    GET_AFTERCARE_FORM_LIST,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getDefaultAftercareDocumentTemplateList: responseData }) => {

        dispatch({
          type: GET_AFTERCARE_FORM_DATA_LIST,
          payload: responseData.data,
          // page
        });
        if (responseData.success) {
          console.log(responseData.data, 'responseData==');

        } else {
          console.log(responseData.data, 'error==');
        }


      },
    }
  );
  const afterCareFomrListQueryData = afterCareFormListData;
  const initAfterCareFormList = (data) => {
    // page = data.page
    getAfterCareFormList({
      variables: data,
    });
  };
  return { initAfterCareFormList, afterCareFomrListQueryData };
};

// ========================USER DEFUALT AFTERCARE FORM========================

const GET_USER_AFTERCARE_LIST = gql`
query GetUserAftercareDocumentTemplateList($search: String, $page: Int, $limit: Int) {
  getUserAftercareDocumentTemplateList(search: $search, page: $page, limit: $limit) {
    success
    message
    data {
      aftercareDocs {
        aftercareDocId
        aftercareDocParentId
        aftercareDocName
        aftercareDocStatus
      }
      total
      totalPages
      currentPage
    }
  }
}
`;

// // var page;
export const UserAfterCareList = () => {
  const dispatch = useDispatch();
  const [getUserAfterCareFormList, userAfterCareFormListData] = useLazyQuery(
    GET_USER_AFTERCARE_LIST,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getUserAftercareDocumentTemplateList: responseData }) => {

        dispatch({
          type: GET_USER_AFTERCARE_FORM_DATA_LIST,
          payload: responseData.data,
          // page
        });
        if (responseData.success) {
          console.log(responseData.data, 'responseData==');

        } else {
          console.log(responseData.data, 'error==');
        }


      },
    }
  );
  const userAfterCareFomrListQueryData = userAfterCareFormListData;
  const initUserAfterCareFormList = (data) => {
    // page = data.page
    getUserAfterCareFormList({
      variables: data,
    });
  };
  return { initUserAfterCareFormList, userAfterCareFomrListQueryData };
};


// ==CREATE/UPDATE AFTERCARE FORM==
const CREATE_OR_UPDATE_AFTER_CARE_FORM = gql`
mutation CreateAftercareForm($createAftercareFormId: Int, $aftercareFormName: String) {
  createAftercareForm(id: $createAftercareFormId, aftercareFormName: $aftercareFormName) {
    success
    message
    data {
      id
      aftercareFormName
    }
  }
}
`;

export const CreateAndEditAfterCareForm = () => {
  const [
    createOrUpdateAfterCareFormFunction,
    createOrUpdateAfterCareFormResponse,
  ] = useMutation(CREATE_OR_UPDATE_AFTER_CARE_FORM);

  const createOrUpdateAfterCareFormQueryData = createOrUpdateAfterCareFormResponse;

  const successCallback = (responseData) => {
    console.log('Success:', responseData);
    // Handle success logic here
  };

  const errorCallback = (error) => {
    console.error('Error:', error);
    // Handle error logic here
  };

  const initCreateOrUpdateAfterCareFormData = (data, successCallback, errorCallback) => {
    createOrUpdateAfterCareFormFunction({
      variables: data,
    })
      .then((response) => {
        console.log(response);
        if (response.data.createAftercareForm.success) {
          successCallback && successCallback(response)
        } else {
          errorCallback && errorCallback(response.data.createAftercareForm);
        }
      })
      .catch((err) => {
        errorCallback && errorCallback(err);
      });
  };

  return { createOrUpdateAfterCareFormQueryData, initCreateOrUpdateAfterCareFormData };
};

//=== CONSENT FORM PREVIEW DETAILS ===
const GET_AFTERCARE_FORM_DETAILS_DATA = gql`
query GetAftercareFormDetails($aftercareId: Int!) {
  getAftercareFormDetails(aftercareId: $aftercareId) {
    success
    message
    data {
      aftercareFormId
      aftercareFormName
      fileUrl
    }
  }
}
  `;

export const AfterCareFormDetails = () => {
  const dispatch = useDispatch();
  const [getAfterCareFormPreview, afterCareFormDetailsData] = useLazyQuery(
    GET_AFTERCARE_FORM_DETAILS_DATA,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getAftercareFormDetails: responseData }) => {

        dispatch({
          type: GET_AFTERCARE_FORM_DETAILS,
          payload: responseData.data,
          // page
        });
        if (responseData.success) {
          console.log(responseData.data, 'responseData==');

        } else {
          console.log(responseData.data, 'error==');
        }


      },
    }
  );
  const aftercareFormDetailsQueryData = afterCareFormDetailsData;
  const initAfterCareFormDetails = (data, sb, eb) => {
    // page = data.page
    getAfterCareFormPreview({
      variables: data,
    }).then(response => {
      const resData = response.data.getAftercareFormDetails;
      if (resData.success) {
        sb && sb(resData.data);
      } else {
        eb && eb(resData);
      }
    }).catch((err) => {
      eb && eb(err);
    });
  };
  return { initAfterCareFormDetails, aftercareFormDetailsQueryData };
}



//=== CONSENT FORM PREVIEW DETAILS ===
const GET_CONSENT_FORM_PREVIEW = gql`
  query GetConsentFormDetails($consentId: Int!) {
    getConsentFormDetails(consentId: $consentId) {
      success
      message
      data {
        con_frm_id
        con_frm_name
        questions {
          id
          question
        }
        status
        form_type
        disclaimer
      }
    }
  }
`;

export const ConsentFormPreview = () => {
  const dispatch = useDispatch();
  const [getConsentFormPreview, consentFormPreviewData] = useLazyQuery(
    GET_CONSENT_FORM_PREVIEW,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getConsentFormDetails: responseData }) => {

        dispatch({
          type: GET_CONSENT_FORM_DETAILS,
          payload: responseData.data,
          // page
        });
        if (responseData.success) {
          console.log(responseData.data, 'responseData==');

        } else {
          console.log(responseData.data, 'error==');
        }


      },
    }
  );
  const consentFormPreviewQueryData = consentFormPreviewData;
  const initConsentFormPreview = (data, successCallback, errorCallback) => {
    getConsentFormPreview({
      variables: data,
    }).then((response) => {
      const resData = response.data.getConsentFormDetails
      if (resData.success) {
        successCallback(resData.data);
      } else {
        errorCallback(resData);
      }
    })
    .catch((err) => {
      errorCallback(err);
    });
  };
  return { initConsentFormPreview, consentFormPreviewQueryData };
}


// ==CREATE AFTERCARE FORM==
const ADD_AND_EDIT_USER_CONSENT_FORM = gql`
mutation CreateConsentForm($createConsentFormId: Int, $consentFormName: String, $disclaimer: String, $questions: [String]) {
  createConsentForm(id: $createConsentFormId, consent_form_name: $consentFormName, disclaimer: $disclaimer, questions: $questions) {
    success
    message
    data {
      con_frm_id
    }
  }
}
`;

export const AddAndEditUserConsentForm = () => {
  const [
    AddAndEditUserConsentFormResponseFunctio,
    addAndEditUserConsentFormResponse,
  ] = useMutation(ADD_AND_EDIT_USER_CONSENT_FORM);

  const addUserConsentFormQueryData = addAndEditUserConsentFormResponse;

  const initAddAndEditUserConsentFormData = (data, successCallback, errorCallback) => {
    AddAndEditUserConsentFormResponseFunctio({
      variables: data,
    })
      .then((response) => {
        console.log(response);
        if (response.data.createConsentForm.success) {
          successCallback(response);
        } else {
          errorCallback(response.data.createConsentForm);
        }
      })
      .catch((err) => {
        errorCallback(err);
      });
  };

  return { addUserConsentFormQueryData, initAddAndEditUserConsentFormData };
};


// ==CRATE OR UPDATE WALK IN QUESTION AND ANSWER==
const CREATE_OR_UPDATE_WALK_IN_QUESTION_ANSWER = gql`
mutation CreateOrUpdateWalkInQuestionsAns($consentFormRecQesId: Int, $questionAns: [createOrUpdateWalkInQuestionsAnsInputType]) {
  createOrUpdateWalkInQuestionsAns(consentFormRecQesId: $consentFormRecQesId, questionAns: $questionAns) {
    success
    message
  }
}
`;

export const CreateOrUpdateWalkInQuestionAnswer = () => {

  const [
    createOrUpdateWalkInQuestionAnswerFunction,
    createOrUpdateWalkInQuestionAnswerResponse,
  ] = useMutation(CREATE_OR_UPDATE_WALK_IN_QUESTION_ANSWER);

  const createOrUpdateWalkInQuestionAnswerQueryData = createOrUpdateWalkInQuestionAnswerResponse;

  const successCallback = (responseData) => {
    console.log('Success:', responseData);
    toast.success(responseData.data.createOrUpdateWalkInQuestionsAns.message);
    // Handle success logic here
  };

  const errorCallback = (error) => {
    console.error('Error:', error);
    toast.error(error.message);
    // Handle error logic here
  };

  const initCreateOrUpdateWalkInQuestionAnswerData = (data) => {
    createOrUpdateWalkInQuestionAnswerFunction({
      variables: data,
    })
      .then((response) => {
        console.log(response);
        if (response.data.createOrUpdateWalkInQuestionsAns.success) {
          successCallback(response);
        } else {
          errorCallback(response);
        }
      })
      .catch((err) => {
        errorCallback(err);
      });
  };

  return { createOrUpdateWalkInQuestionAnswerQueryData, initCreateOrUpdateWalkInQuestionAnswerData };
}

// ==GET WALK IN QUESTION AND ANSWER==
const GET_WALK_IN_QUESTION_ANSWER = gql`
query GetWalkInQuestionsAns($consentFormRecId: Int) {
  getWalkInQuestionsAns(consentFormRecId: $consentFormRecId) {
    success
    message
    data {
      con_frm_rec_id
      con_frm_id
      question
      answer
      comment
      is_ignore
    }
  }
}
`;

export const GetWalkInQuestionAnswer = () => {
  const dispatch = useDispatch();
  const [getWalkInQuestionAnswer, walkInQuestionAnswerData] = useLazyQuery(
    GET_WALK_IN_QUESTION_ANSWER,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getWalkInQuestionsAns: responseData }) => {

        dispatch({
          type: GET_WALK_IN_QUESTION_ANSWER_DATA,
          payload: responseData.data,
          // page
        });
        if (responseData.success) {
          console.log(responseData.data, 'responseData==');

        } else {
          console.log(responseData.data, 'error==');
        }


      },
    }
  );
  const walkInQuestionAnswerQueryData = walkInQuestionAnswerData;
  const initWalkInQuestionAnswer = (data) => {
    // page = data.page
    getWalkInQuestionAnswer({
      variables: data,
    });
  };
  return { initWalkInQuestionAnswer, walkInQuestionAnswerQueryData };

}

const UPLOAD_AFTERCARE_DOC_MUTATION = gql`
  mutation UploadAfterCareDocument($afterCareFormId: Int, $file: Upload) {
    uploadAfterCareDocument(afterCareFormId: $afterCareFormId, file: $file) {
      success
      message
      data {
        afterCareFormId
        aftercareFormName
        url
      }
    }
  }
`

export const UploadAfterCareDocumentMutation = () => {
  const [
    mutate,
    uploadAfterCareDocumentStatus,
  ] = useMutation(UPLOAD_AFTERCARE_DOC_MUTATION);

  const initUploadAfterCareDocument = (data, successCallback, errorCallback) => {
    mutate({
      variables: data,
    })
      .then((response) => {
        const resData = response.data.uploadAfterCareDocument
        if (resData.success) {
          successCallback && successCallback(resData);
        } else {
          errorCallback && errorCallback(response);
        }
      })
      .catch((err) => {
        errorCallback && errorCallback(err);
      });
  };

  return { uploadAfterCareDocumentStatus, initUploadAfterCareDocument };
}

const DELETE_AFTERCARE_MUTATION = gql`
  mutation DeleteAftercareForm($aftercareFormId: Int) {
    deleteAftercareForm(aftercareFormId: $aftercareFormId) {
      success
      message
    }
  }
`;

export const DeleteAftercareFormMutation = () => {
  const [
    mutate,
    deleteAftercareFormStatus,
  ] = useMutation(DELETE_AFTERCARE_MUTATION);

  const initDeleteAftercareForm = (data, successCallback, errorCallback) => {
    mutate({
      variables: data,
    })
      .then((response) => {
        const resData = response.data.deleteAftercareForm;
        if (resData.success) {
          successCallback && successCallback(resData);
        } else {
          errorCallback && errorCallback(response);
        }
      })
      .catch((err) => {
        errorCallback && errorCallback(err);
      });
  };

  return { deleteAftercareFormStatus, initDeleteAftercareForm };
}