import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_TREATMENT_WISE_BEFORE_AFTER_COUNT } from "../../constants";

const GET_TREATMENTWISE_BEFORE_AFTER_PHOTOS_COUNT = gql`
query GetTreatmentWiseBeforeAfterPhotosCount($consentFormRecordId: Int, $type: Int) {
  getTreatmentWiseBeforeAfterPhotosCount(consentFormRecordId: $consentFormRecordId, type: $type) {
    success
    message
    data {
      consentFormRecordId
      countDetails {
        treatmentId
        treatmentName
        photosCount
      }
      otherPhotosCount
    }
  }
}
`;

export const GetTreatmentwiseBeforeAfterPhotosCountAction = () => {
  const dispatch = useDispatch();
  const [
    GetTreatmentwiseBeforeAfterPhotosCountResponseFunction,
    getTreatmentwiseBeforeAfterPhotosCountResponseData,
  ] = useLazyQuery(GET_TREATMENTWISE_BEFORE_AFTER_PHOTOS_COUNT, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getTreatmentWiseBeforeAfterPhotosCount: responseData }) => {
      if (responseData.success) {
        console.log("asha o", responseData?.data)
        dispatch({
          type: GET_TREATMENT_WISE_BEFORE_AFTER_COUNT,
          payload: responseData?.data
        })
        // toast.success(responseData?.message)
      } else {
      }
    },
  });
  //this will be called from components
  const getTreatmentwiseBeforeAfterPhotosCountQueryData = getTreatmentwiseBeforeAfterPhotosCountResponseData; //variable

  const initGetTreatmentwiseBeforeAfterPhotosCountData = (data) => {
    GetTreatmentwiseBeforeAfterPhotosCountResponseFunction({
      variables: data,
    })
  };

  return { getTreatmentwiseBeforeAfterPhotosCountQueryData, initGetTreatmentwiseBeforeAfterPhotosCountData };
};