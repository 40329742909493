import { gql, useLazyQuery } from "@apollo/client"

const GET_NEXT_AVAILABLE_DATE_FOR_BOOKINGS = gql`
query GetNextAvailableDateForBookings($tokenSug: String!, $clinicId: Int!) {
  getNextAvailableDateForBookings(tokenSug: $tokenSug, clinicId: $clinicId) {
    success
    message
    data {
      date
    }
  }
}`

export const GetNextAvailableDateForBookingsAction = () => {
    // const dispatch = useDispatch()
    const [getNextAvailableDateForBookingsFunction, nextAvailableDateForBookings] = useLazyQuery(
        GET_NEXT_AVAILABLE_DATE_FOR_BOOKINGS,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getClinicAvailableTimesForDate: responseData }) => {

                if (responseData?.success) {

                    // dispatch({
                    //     type: GET_TIME_SLOTS,
                    //     payload: responseData
                    // })
                }

            }
        }
    )
    const getNextAvailableDateForBookingsData = nextAvailableDateForBookings

    const initGetNextAvailableDateForBookings = (data) => {
        getNextAvailableDateForBookingsFunction({
            variables: {
                ...data,
            }
        })
    }

    return { getNextAvailableDateForBookingsData, initGetNextAvailableDateForBookings }
}