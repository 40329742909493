import React from 'react'
import backArrow from '../../../../images/back_arrow.svg'
import checkIcon from '../../../../images/on-boarding imgs/check.svg'
import '../on-boarding-main.scss'
import { useNavigate } from 'react-router'

const CompleteOnBoardingProfile = () => {
    const navigate = useNavigate();

    const handleBackNavigate = () => {
        navigate('/get-started-onboarding/location')
    }

    return (
        <>
            <div className='onboarding-location-main'>
                <div className='container'>
                    <div className='onboarding__header mt-5' style={{cursor:'pointer'}} onClick={handleBackNavigate}>
                        <img src={backArrow} alt='img' />
                    </div>
                    <div className="location__content">
                        <h1>Location </h1>
                        <p>Let’s set up your first location. You can add more later.</p>
                        {/*  */}
                        <div className='location__list' style={{flexDirection:'column'}}>
                            <div className='complete_profile_card mt-3'>
                                <div className='complete_profile_check__img'>
                                    <img src={checkIcon} width={30} height={30} alt='img' />
                                </div>
                                <h1>Location & hours</h1>
                                <p>Share your workspace location and operating hours with clients.</p>
                                <button className='btn'>Edit</button>
                            </div>

                            <div className='complete_profile_card  mt-5'>
                                <div className='complete_profile_check__img'>
                                    <img src={checkIcon} width={30} height={30} alt='img' />
                                </div>
                                <h1>Add a Service</h1>
                                <p>Start by listing your first service. You can easily add more once the first one is set up.</p>
                                <button className='btn'>Edit</button>
                            </div>


                            <div className='complete_profile_card mt-5'>
                                <div className='complete_profile_check__img'>
                                    <img src={checkIcon} width={30} height={30} alt='img' />
                                </div>
                                <h1>Get paid</h1>
                                <p>Activate online payments to enable client online bookings.</p>
                                <button className='btn'>Edit</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div >
        </>
    )
}

export default CompleteOnBoardingProfile
