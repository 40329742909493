import { gql, useLazyQuery } from "@apollo/client"
import { useDispatch } from "react-redux";
import { GET_Hair_Products_Questions } from "../../constants/hair-prescription-product/HairProductConts";


const GET_HAIR_PRODUCT_QUESTIONS_QUERY = gql`
query GetHairPrescriptionQuestions($page: Int!, $limit: Int!) {
  getHairPrescriptionQuestions(page: $page, limit: $limit) {
    success
    message
    data {
      questions {
        id
        que
        options
        multiple
        comment
        yes_no
      }
      total_count
    }
  }
}
`;

export const GetHairProductsQuestions = () => {
    const dispatch = useDispatch();
    const [getHairProductsQuestions , hairProductsQuestionsData] = useLazyQuery(
        GET_HAIR_PRODUCT_QUESTIONS_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({getHairPrescriptionQuestions : responseData}) => {
                dispatch({
                    type: GET_Hair_Products_Questions,
                    // payload: responseData.data
                    payload: {
                        allQuestions: responseData?.data?.questions,
                        totalCount: responseData?.data?.total_count
                    }
                })
            }
        }
    )

    const hairProductQuestionsQueryData = hairProductsQuestionsData;

    const initGetHairProductsQuestions = (page, limit) => {
        getHairProductsQuestions({
            variables: { page, limit },
        })
    }

    return {initGetHairProductsQuestions , hairProductQuestionsQueryData} ;
}