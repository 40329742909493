import { gql, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

const DELETE_INSURANCE_DOCUMENT_MUTATION = gql`
  mutation DeleteInsurenceDocument {
    deleteInsurenceDocument {
      success
      message
    }
  }
`;

export const DeleteInsurenceDocument = () => {
  // const dispatch = useDispatch();
  const [
    DeleteInsurenceDocumentResponseFunction,
    deleteInsurenceDocumentResponseData,
  ] = useMutation(DELETE_INSURANCE_DOCUMENT_MUTATION);

  //this will be called from components
  const deleteInsurenceDocumentQueryData =
    deleteInsurenceDocumentResponseData; //variable

  const initDeleteInsurenceDocumentData = (
    data,
    successCallback,
    errorCallback
  ) => {
    DeleteInsurenceDocumentResponseFunction({
      variables: data,
    })
      // .then(({ data: { DeleteInsurenceDocument: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        if (responseData?.data?.deleteInsurenceDocument?.success) {
          // dispatch(...)
          successCallback(responseData);
          // toast.success(responseData.message)
        } else {
          errorCallback(responseData);
          // toast.error(responseData.message)
        }
      })
      .catch((err) => {
        // errorCallback(err);
        console.log('typeeee:', err);
        toast.error('something went wrong!!!');
      });
  };

  return {
    deleteInsurenceDocumentQueryData,
    initDeleteInsurenceDocumentData,
  };
};
