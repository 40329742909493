import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_MEDICAL_FORM_STATUS } from "../../constants/appointment/appointmentConst";



const GET_MEDICAL_FORM_STATUS_QUERY = gql`
query GetClientMedicalFormStatus($clientId: Int) {
    getClientMedicalFormStatus(clientId: $clientId) {
      success
      message
      data {
        isMedicalFormFilled
        medicalFormFilledByClient
        medicalFormFilledByPractitioner
        medicalFilledMsg
      }
    }
  }
`;


export const GetMedicalFormStatusQuery = () => {
  const dispatch = useDispatch();
  const [getMedicalFormStatusData, medicaFormStatusData] = useLazyQuery(
    GET_MEDICAL_FORM_STATUS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClientMedicalFormStatus: responseData }) => {
        if (responseData) {
          dispatch({
            type: GET_MEDICAL_FORM_STATUS,
            payload: responseData.data,
          });
        } else {
        }
      },
    }
  );
  const medicaFormStatusQueryData = medicaFormStatusData;
  const initGetMedicalFormStatus = (data,successCallBack,errorCallBack) => {
    getMedicalFormStatusData({
      variables: data,
    }).then((res)=>{
        if(res.data){
            successCallBack(res.data.getClientMedicalFormStatus)
        }else{
            errorCallBack(res)
        }
    }).catch((err)=>{
        errorCallBack(err)
    })
  };
  return { initGetMedicalFormStatus, medicaFormStatusQueryData };
};
