import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import SignatureCanvas from "react-signature-canvas";
import { NewAppointmentRoutePattern, businessDashboardRoute, getAppointmentListRoute, getAppointmentListRoutePattern, getAppointmentRoute, getCalendarRoute, getClientProfileFormsRoute, getClientProfileRoute, getEditClientRoute } from "../../Routes";
import { ReactComponent as EnableSignIcon } from "../../images/new-appointment/active_sign.svg";
import { ReactComponent as AddClient } from "../../images/new-appointment/add-client.svg";
// import AddClient from "../../images/new-appointment/add-client.png";
import { ReactComponent as AfterCareIcon } from "../../images/icons/appointment-list/AftercareIcon.svg";
import { ReactComponent as CalanderIcon } from "../../images/new-appointment/calander.svg";
import { ReactComponent as CollectPaymentIcon } from "../../images/new-appointment/collect_payment.svg";
import { ReactComponent as ConcentFormIcon } from '../../images/icons/appointment-list/ConsentFormIcon.svg'
import { ReactComponent as HoldDurationActiveIcon } from "../../images/new-appointment/duration_active.svg";
import { ReactComponent as EmailReminderIcon } from "../../images/new-appointment/email_reminder.svg";
import { ReactComponent as EmptySignIcon } from "../../images/new-appointment/empty_sign.svg";
import { ReactComponent as EnableCheck } from "../../images/new-appointment/enable_check.svg";
import EraseImg from '../../images/new-appointment/erase.png';
import { ReactComponent as FollowUpActiveIcon } from "../../images/new-appointment/followup_active.svg";
import { ReactComponent as FormIcon } from "../../images/new-appointment/form.svg";
import { ReactComponent as LocationIcon } from "../../images/new-appointment/location.svg";
import AddLocationIcon from "../../images/new-appointment/Add locations.png";
import { ReactComponent as OfferPayIcon } from "../../images/new-appointment/offpay_month.svg";
import Percent from "../../images/new-appointment/percent.svg";
import { ReactComponent as RightArrow } from "../../images/new-appointment/right_arrow.svg";
import { ReactComponent as ServiceIcon } from "../../images/new-appointment/service.svg";
import AddServiceIcon from "../../images/new-appointment/Add services.png";
import Suffix from "../../images/new-appointment/sufix.svg";
import { ReactComponent as TextReminderIcon } from "../../images/new-appointment/text_reminder.svg";
import { ReactComponent as TimerIcon } from "../../images/new-appointment/timer.svg";
import { ReactComponent as UndoIcon } from "../../images/new-appointment/undo.svg";
import { ReactComponent as UndoEnableIcon } from "../../images/new-appointment/undo_enable.svg";
import SearchLocation from "../Clinic/SearchLocation";
import CustomRadioButton from "../common/CustomRadioButton";
import NavHeader from "../common/NavHeader/NavHeader";
import StepButton from "../common/StepButton";
import TextInput from "../common/TextInput";
import SwitchButton from "../common/switchButton/SwitchButton";
import useDeviceDetect from "../common/useDeviceDetect";
import CustomModal from "../modal/CustomModal";
import OffCanvasModal from "../modal/OffCanvasModal/OffCanvasModal";
import { getBusinessProfileSettingPage } from "../../Routes";
import CustomCalendar from "../pages/dateTime/steps/CustomCalendar";
import { SET_NEW_CLIENT_LIST_CLEAR, SET_NEW_CLINIC_LIST_CLEAR } from "../redux/constants/newAppointment/NewAppointmentConst";
import "./new-appointment.scss";

import { ReactComponent as HoldDurationInActiveIcon } from "../../images/new-appointment/duration_orange.svg";
import { ReactComponent as FollowUpInActiveIcon } from "../../images/new-appointment/followup_orange.svg";

import { frontendURL, webBaseUrl } from "../../config";
import { ReactComponent as DeleteIcon } from "../../images/delete.svg";
import { capitalizeFirstLetter } from "../../utils/initials";
import showToast from "../common/CustomToast/CustomToast";
import CustomDropdown from "../common/dropdown/CustomDropdown";
import AfterCareList from "../pages/appointment/AfterCareList";
import ConsentFormList from "../pages/appointment/ConsentFormList";
import TreatmentList from "../pages/appointment/TreatmentList";
import { GetAppointmentDetailsV3Query } from "../redux/actions/appointments/GetAppointmentDetailsV3";
import { CreateNewAppointmentMutation, StripeOnboardingAction } from "../redux/actions/appointments/createNewAppointment";
import { GetAvailableTimeSlotsAction, GetCalendarAppointmentCountAction, GetClinicAvailableDateAction, IsAlreadyBookingExistAction } from "../redux/actions/dateTimeslots/DateTimeSlots";
import { GetAfterCareFormList, GetConsentFormList } from "../redux/actions/formsTemplate/getFormTemplateList";
import { GetFinanceStatusAction, GetStripeAccountAndFinanceRequestStatusAction, GetTextMessageBalanceDetailsAction } from "../redux/actions/newAppointment/BusinessBoosters";
import { CLEAR_APPOINTMENT_DETAILS_V3_DATA, SAVE_CALENDAR_DAILYVIEW_DATA } from "../redux/constants/appointment/appointmentConst";
import NewClientList from "./NewClientList";
import SMSSucriptionModal from "./sms-subscription/SMSSucriptionModal";
import { CustomCalendarSkeleton } from "../clininc-cart-skeleton/calenadar-skeleton/CustomCalendarSkeleton";
import TimeSection from "../pages/dateTime/steps/TimeSection";
import SkeletonLoader from "../common/Skeleton/SkeletonLoader";
import { CLEAR_TIME_SLOTS_FOR_CREATE_APPOINTMENT, SET_TIME_SLOTS_FOR_CREATE_APPOINTMENT } from "../redux/constants/dateTimeslots/DateTimeSlots";
import { generateCustomDates, pounds } from "../../utils/general";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ClientPasswordPopup from "../Client/passwordpopup/ClientPasswordPopup";

const NewAppointment = () => {
  const [toggle, setToggles] = useState({
    textReminder: false,
    emailReminder: true,
    collectPayment: false,
    offerPay: false,
  });
  const navigate = useNavigate();
  const location = useLocation()
  const { screenWidth } = useDeviceDetect();
  const [isAddClient, setIsAddClient] = useState(false);
  const [isSignatureOpen, setIsSignatureOpen] = useState(false);
  const [isHoldDurationOpen, setIsHoldDurationOpen] = useState(false);
  const [isHoldDurationIcon, setIsHoldDurationIcon] = useState(null);
  const [isFollowUpIcon, setIsFollowUpIcon] = useState(14)
  const [isFollowupIconType, setIsFollowupIconType] = useState(0)
  const [isFollowUpIconOtherTime, setIsFollowUpIconOtherTime] = useState(null)

  const [isFollowUpOpen, setIsFollowUpOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedFollowUpTime, setSelectedFollowUpTime] = useState(14);
  const [selectedFollowUpOtherTime, setSelectedFollowUpOtherTime] = useState(null);
  const [isEditPriceOpen, setIsEditPriceOpen] = useState(false);
  const [isEditTreatmentPriceOpen, setIsEditTreatmentPriceOpen] = useState(false);
  const [isEditSubTotal, setIsEditSubTotal] = useState(false);

  const [isDiscountPriceOpen, setIsDiscountPriceOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isTreatmentListOpen, setIsTreatmentListOpen] = useState(false);
  const [active, setActive] = useState("percent");
  const [buttonActive, setButtonActive] = useState("days")
  const [isBookingSuccess, setIsBookingSuccess] = useState(false)
  const [isShowTime, setIsShowTime] = useState(false)
  const [timeRange, setTimeRange] = useState({ start: "", end: "" })
  //for getting data from modal
  const [selectedClientData, setSelectedClientData] = useState([])

  console.log(selectedClientData, 'selectedClientData');
  const [selectedLocationData, setSelectedLocationData] = useState([])
  const [subtotalSave, setSubtotalSave] = useState(0);
  const [subtotal, setSubtotal] = useState(0);

  const [subTotalError, setSubTotalError] = useState("")

  const [showClientDataInToast, setShowClientDataInToast] = useState([])



  console.log(selectedLocationData, "selectedLocationData")

  //for toggle when selecte the list from modal
  const [isClientOffCanvas, setIsClientOffCanvas] = useState(false)
  const [isLocationOffCanvas, setIsLocationOffCanvas] = useState(false)
  const [selectedTreatmentData, setSelectedTreatmentData] = useState([])
  const [selectedAfterCareData, setSelectedAfterCareData] = useState([])
  const [selectedConsentFormData, setSelectedConsentFormData] = useState([])
  const [selectedNewConsentData, setSelectedNewConsentData] = useState([])
  const [selectedFormType, setSelectedFormType] = useState(null)
  const [openConsentModal, setOpenConsentModal] = useState(false)
  const [openAfterCareModal, setOpenAfterCareModal] = useState(false)
  const [followUpType, setFollowUpType] = useState(0)
  const [otherText, setOtherText] = useState("")
  const [isTreatmentList, setIsTreatmentList] = useState(false)
  const [errorObj, setErrorObj] = useState({
    startTime: '',
    endTime: '',
  })
  const [start, setStart] = useState("09:00");
  const [end, setEnd] = useState("09:00");

  //APPOINTMENT OVERLAPPING MODAL STATE
  const [isAppointmentOverlappingOpen, setIsAppointmentOverlappingOpen] = useState(false)
  const [overLappingMessage, setOverLappingMessage] = useState("")

  const [isStartTimeOpen, setIsStartTimeOpen] = useState(false)
  const [isOutsideAvaibility, setIsOutsideAvaibility] = useState(false)


  //VALIDATION FOR TIME RANGE
  const validateForm = (startTimeParam, endTimeParam) => {
    var obj = {
      startTime: '',
      endTime: '',
    }


    var valid = true;

    if (!startTimeParam) {
      obj.startTime = 'Required!'
      valid = false;
    }
    if (!endTimeParam) {
      obj.endTime = 'Required!'
      valid = false;
    }
    if (startTimeParam && endTimeParam) {
      var startTime = moment(startTimeParam, 'HH:mm');
      var endTime = moment(endTimeParam, 'HH:mm');

      if (!startTime.isBefore(endTime)) {
        obj.startTime = 'End time should be greater than the start time';
        valid = false
      }

    }
    setErrorObj(obj);
    return valid
  }

  const { bookingId } = useParams()

  //Discount State
  const [discount, setDiscount] = useState(0);
  const [percentDiscount, setPrecentDiscount] = useState(0)
  const [poundDiscount, setPoundDiscount] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [mainAddDiscountData, setMainAddDiscountData] = useState(0)

  const [discountCodeType, setDiscountCodeType] = useState("percentage") //percentage or pound
  const [deposit, setDeposit] = useState(0);
  const [depositPrice, setDepositPrice] = useState(0)
  const [isDepositError, setIsDepositError] = useState("")
  const [isTreatmentPriceEditError, setIsTreatmentPriceEditError] = useState("")
  const [isDiscountError, setIsDiscountError] = useState("")

  const [dueAmount, setDueAmount] = useState(0)
  const [newSign, setNewSign] = useState(null)
  const [medicalForm, setMedicalForm] = useState(null)
  const [consentIndex, setConsentIndex] = useState(null)
  const [afterCareIndex, setAfterCareIndex] = useState(null)
  const [treatmentIndex, setTreatmentIndex] = useState(null)
  const [treatmentEditPriceIndex, setTreatmentEditPriceIndex] = useState(null)
  const [isSuccessBookingId, setIsSuccessBookingId] = useState(null)
  const [isSignatureFromUrl, setIsSignatureFromUrl] = useState(null)
  const [isOpenTimePicker, setIsOpenTimePicker] = useState(false)
  const [discountMain, setDiscountMain] = useState(0);
  const [depositMain, setDepositMain] = useState(0)


  const [treatmentPriceTemp, setTreatmentPriceTemp] = useState(0)
  const [treatmentEditPriceAmount, setTreatmentEditPriceAmount] = useState(null)

  console.log(treatmentIndex, "treatmentIndex", consentIndex, afterCareIndex)


  const appointmentDetails = useSelector((state) => state?.appointmentDetailsV3Reducer?.appointmentDetails)
  const [selectedStartTime, setSelectedStartTime] = useState(null)
  const [animate, setAnimate] = useState(false)

  const { dailyViewData } = useSelector((state) => state.appointmentListReducer);
  const { initAvalableTimeSlotData, availableTimeSlotData } = GetAvailableTimeSlotsAction()
  const [showSelectedTime, setShowSelectedTime] = useState(null);
  const [tempShowSelectedTime, setTempShowSelectedTime] = useState(showSelectedTime);

  // LOCATION TREATMENT ADDITION MODAL

  const [locationTreatementModalState, setLocationTreatmentModalState] = useState(false)
  const [selectedForAdd, setSelectedForAdd] = useState(0) // 1 for location and 2 for treatment 

  // STRIPE CONNECTION MODAL
  const [stripeConnectionModalState, setstripeConnectionModalState] = useState(false)

  // CLIENT DIRECTORY MODAL
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);
  const [timing, setTiming] = useState("start")

  const TIME = () => {
    const hours = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        hours.push(
          moment({
            hour,
            minute
          }).format('HH:mm')
        );

      }
    }
    return hours;
  };

  //GENERATE CUSTOM DATES

  useEffect(() => {
    console.log("depositPrice", appointmentDetails)
  }, [appointmentDetails])
  //const new data
  const [preConsentForm, setPreConsentForm] = useState([]);
  const [preAfterCareForm, setPreAfterCareForm] = useState([]);

  //CallBack Function
  const handleSelectedConsentForm = (selectedForms) => {
    setSelectedConsentFormData(selectedForms);
  };

  const [newConverted, setConverted] = useState(null)

  //USE FOR FOCUS ON SECTION IF THERE IS TREATMENT AT LIST ONE
  const section1Ref = useRef(null);

  const focusOnSection = () => {
    if (section1Ref && section1Ref.current) {
      setAnimate(true)
      section1Ref.current.scrollIntoView({ behavior: 'smooth' });
      showToast("warning", "Click on treatment price to update the treatment price", "", false, undefined, undefined, { toastId: 'treatment-price-select-error-toast' })
    }
    setTimeout(() => {
      setAnimate(false)
    }, 2000)
  };

  console.log("consent akash", selectedAfterCareData, "aftercare", selectedConsentFormData, "treatment list", selectedTreatmentData)
  const [stripeFinceStatusObj, setStripeFinceStatusObj] = useState(null)

  //SELECTOR
  const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)

  //API ACTIONS
  const { initGetTextMessageBalanceDetailsData } = GetTextMessageBalanceDetailsAction()
  const { initGetStripeAccountAndFinanceRequestStatusData } = GetStripeAccountAndFinanceRequestStatusAction()
  const { initGetFinanceStatusData } = GetFinanceStatusAction()
  const { createNewAppointmentMutationData, initiateCreateNewAppointmentResponseData } = CreateNewAppointmentMutation()
  const { initGetAppointmentDetailsV3, appointmentDetailsV3QueryData } = GetAppointmentDetailsV3Query()
  const { clinicsAvailableDateData, initClinicsAvailableDateResponseFunction } = GetClinicAvailableDateAction();

  const { getCalendarAppointmentCountQueryData, initGetCalendarAppointmentCountData } = GetCalendarAppointmentCountAction()
  const { isAlreadyBookingExistQueryData, initIsAlreadyBookingExistData } = IsAlreadyBookingExistAction()

  //STRIPE ONBOARDING
  const { stripeOnboardingQueryData, initStripeOnboardingData } = StripeOnboardingAction()

  console.log("isTreatmentList", isTreatmentList)
  //SMS SUBSCRIPTION MODAL 
  const [isSmsSubscriptionOpen, setIsSmsSubscriptionOpen] = useState(false);

  //OFFER PAY MONTHLY LEARN MODAL
  const [isOfferPayMonthlyLearnOpen, setIsOfferPayMonthlyLearnOpen] = useState(false);

  const selectedClient = useSelector((state) => state.clientDetailsReducer?.selectedClient)
  const availableTimeslots = useSelector(
    (state) => state?.GetDateTimeSlotsReducer?.timeslotsData
  );

  const availableAndOutsideAvaibilityTimeSlots = useSelector(
    (state) => state?.GetDateTimeSlotsReducer?.availableAndOutsideAvaibilityTimeSlots
  );

  const timeslots = useSelector(
    (state) => state?.GetDateTimeSlotsReducer?.timeslots
  );

  // console.log("appointmentDetails", appointmentDetails, UserProfileData?.user?.usr_id)
  console.log("client", selectedClient)
  console.log("clinicsAvailableDateData", clinicsAvailableDateData)

  const handleToggleChange = (toggleName, event = false) => {
    if (event && event.target.checked) {
      if (toggleName == 'textReminder') {

        if (selectedClientData?.length == 0) {
          showToast("error", "Please select client first", "", false, undefined, undefined, { toastId: 'select-client-first-error-toast' })
          return
        } else if (selectedClientData?.readableContact == "") {
          showToast("error", "Selected client doesn't have phone number", "", false, undefined, undefined, { toastId: 'client-dont-have-phone-error-toast' })
          return
        } else if (showSelectedDate == null) {
          showToast("error", "Please select appointment date", "", false, undefined, undefined, { toastId: 'select-appointment-date-error-toast-1' })
          return
        } else {
          //API CALL 
          initGetTextMessageBalanceDetailsData({
            // userId: UserProfileData?.user?.usr_id,
            appointmentDate: moment(showSelectedDate, "dddd, Do MMMM").format("YYYY-MM-DD")
          }, (response) => {
            if (response?.creditAvailable) {
              setToggles((prevToggles) => ({
                ...prevToggles,
                [toggleName]: !prevToggles[toggleName],
              }));
            } else {
              setIsSmsSubscriptionOpen(true)
            }
          })
        }
      }

      if (toggleName == 'offerPay') {
        if (stripeFinceStatusObj?.financeRequestStatus == 0) {
          initGetFinanceStatusData({
            userId: UserProfileData?.user?.usr_id
          }, (response) => {
            if (response?.financeStatus == 1) {
              setIsOfferPayMonthlyLearnOpen(true)
            }
          })
        } else if (stripeFinceStatusObj?.financeRequestStatus == 1) {
          setIsOfferPayMonthlyLearnOpen(true)
        } else if (stripeFinceStatusObj?.financeRequestStatus == 2) {
          if (subtotalSave >= 50)
            setToggles((prevToggles) => ({
              ...prevToggles,
              [toggleName]: !prevToggles[toggleName],
            }));
          else
            showToast("error", "Subtotal must be greater than 50", "", false, undefined, undefined, { toastId: 'subtotal-greater-error-toast' })
        }
      }
      if (toggleName == 'collectPayment') {
        let isValid
        if (bookingId) {
          isValid = stripeFinceStatusObj?.paymentAccountSetup === true && appointmentDetails?.depositRequestable && deposit > 1.20
        } else {
          isValid = stripeFinceStatusObj?.paymentAccountSetup === true && deposit > 1.20
        }
        if (isValid) {
          setToggles((prevToggles) => ({
            ...prevToggles,
            [toggleName]: !prevToggles[toggleName],
          }));
        } else {
          if (!stripeFinceStatusObj?.paymentAccountSetup) {
            //MODAL
            setstripeConnectionModalState(true)
            return
          }
          if (!stripeFinceStatusObj?.paymentAccountSetup || (!appointmentDetails?.depositRequestable && (bookingId)))
            showToast("error", "Your bank account is not connected!", "", false, undefined, undefined, { toastId: 'bank-ac-not-connected-error-toast' })
          else
            showToast("error", "Deposit must be greater than 1.20 in order to request it online", "", false, undefined, undefined, { toastId: 'deposit-greater-error-toast' })
        }
      }
      if (toggleName == 'emailReminder') {
        setToggles((prevToggles) => ({
          ...prevToggles,
          [toggleName]: !prevToggles[toggleName],
        }));
      }
    } else {
      if (toggleName == 'collectPayment' && bookingId !== undefined && appointmentDetails?.collectPayment) {
        showToast("error", "You can not disable collect payment", "", false, undefined, undefined, { toastId: 'cant-disable-collect-payment-error-toast' })
        return
      }
      setToggles((prevToggles) => ({
        ...prevToggles,
        [toggleName]: !prevToggles[toggleName],
      }));
    }
  };

  const checkIsValidValue = (inputPrice, remainingParameterPrice) => {
    console.log(subtotalSave, inputPrice, remainingParameterPrice, "remainingParameterPrice")
    return (subtotalSave - inputPrice - remainingParameterPrice) >= 0;
  }

  const percentageToValueConverter = (value) => {
    console.log("sub totall", subtotalSave * (value / 100))
    return (subtotalSave * (value / 100)).toFixed(2);
  }

  const [isSigned, setIsSigned] = useState(null);
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [AppointmentDate, setAppointmentDate] = useState(null);

  const [signedSignatureData, setSignedSignatureData] = useState(null);
  const [previewDataURL, setPreviewDataURL] = useState(null);
  const [tempTrimmedDataURL, setTempTrimmedDataURL] = useState(null)

  const sigPadRef = useRef();
  const clear = () => {
    console.log("clear signature", sigPadRef?.current?.isEmpty())
    sigPadRef?.current?.clear();
    setTempTrimmedDataURL(trimmedDataURL)
    setTrimmedDataURL(null);
    setPreviewDataURL("")
    setSignedSignatureData(null)

    setIsSigned(true)
    setToggleSigned(true)
    setIsSignatureOpen(true)

  };

  const dispatch = useDispatch();
  const timeDifference = timeRange.end - timeRange.start;
  const [hours, setHours] = useState(0)
  //Reducer Data
  const clientDetails = useSelector(state => state?.newClientDataReducer?.clientData)
  const clinicDetails = useSelector(state => state?.newClinicDataReducer?.clinicData)
  const buttonRef = useRef(null)

  console.log("client state", clientDetails, clinicDetails)
  //change button days button
  const handleToggleButton = (name) => {
    setButtonActive(name)
    switch (name) {
      case "days":
        return setFollowUpType(1)
      case "weeks":
        return setFollowUpType(2)
      case "months":
        return setFollowUpType(3)
      default:
        return setFollowUpType(0)
    }

  }

  console.log("sigggn", signedSignatureData)
  const handleHide = () => {
    console.log("trimmedDataURL from hide", trimmedDataURL)
    //setIsOpen(false); 
    setPreviewDataURL(signedSignatureData)

    if (bookingId !== undefined) {
      console.log("from booking id signature", sigPadRef?.current)
      // setTrimmedDataURL(appointmentDetails?.signature)
      setIsSigned(false)
      setToggleSigned(true)
    }
    else {
      // if(trimmedDataURL !== null){
      //   setTrimmedDataURL(sigPadRef?.current?.toDataURL("image/png"))
      //   setIsSigned(false)
      // }else{
      //   setToggleSigned(false)
      //   setIsSigned(true)
      // }
    }
    setIsSignatureOpen(true);

  };

  console.log("signData", signedSignatureData)

  //CHECK STRING CONTAIN ONLY NUMBER OR NOT
  const isNumber = (value) => {
    return /^(\d+|\d+\.\d+)$/.test(value);
  }

  useEffect(() => {
    console.log("signature modal in", isSignatureOpen)
    if (isSignatureOpen) {
      setTimeout(() => {
        sigPadRef?.current?.fromDataURL(trimmedDataURL)
      }, 100)
    }
  }, [isSignatureOpen]);


  console.log("stripeFinceStatusObj", stripeFinceStatusObj)

  const handleEditTreatmentPrice = (treatmentPrice) => {
    //setIsOpen(false);
    setTreatmentPriceTemp(treatmentPrice)
    setIsTreatmentPriceEditError("")
    setIsEditTreatmentPriceOpen(true);
  };

  const handleEditPriceHide = () => {
    //setIsOpen(false);
    setDepositPrice(deposit)
    setIsDepositError("")
    setIsEditPriceOpen(true);
  };

  const handleSubTotal = () => {
    setSubtotal(subtotalSave)
    setSubTotalError("")
    setIsEditSubTotal(true);
  }
  const handleFormHide = () => {
    //setIsOpen(false);
    setIsFormOpen(true);
  };

  const handleDiscountPriceHide = () => {
    //setIsOpen(false);
    if (discountCodeType == "percentage") {
      setPrecentDiscount(discount)
      setActive("percent")
    } else {
      setPoundDiscount(discount)
      setActive("pound")
    }
    setIsDiscountError("")
    setIsDiscountPriceOpen(true);
  };

  const handleChangeActive = (name) => {
    setActive(name);
  };

  const handleAddClient = () => {
    setIsClientOffCanvas(true)
  };

  const handleAddLocation = () => {
    setIsLocationOffCanvas(true)
  }

  const handleSignature = () => {
    // setTrimmedDataURL(
    //   sigPadRef.current?.getCanvas()?.toDataURL("image/png")
    // );

    // console.log("trimmedDataURL", sigPadRef?.current?.fromDataURL(trimmedDataURL))
  }

  console.log("trimmed data url", trimmedDataURL)
  //Use For Close Button
  const handleSignture = () => {
    setIsSignatureOpen(false);
    setIsSigned(false)
    console.log("tempTrimmedDataURL akash",)
    if (sigPadRef?.current?.isEmpty() === false) {
      setTrimmedDataURL(null)
    } else {
      setTrimmedDataURL(tempTrimmedDataURL)
    }

  }
  const holdAndFollow = () => {
    document.body.classList.add('position-fixed');
  };


  const handleSignatureModalClose = () => {
    setIsSignatureOpen(false); // Close the SignatureModal
    // setIsOpen(true); // Close the NewAppointment
    // setIsSigned(false)
    // setToggleSigned(true)
    console.log("sigPadRef?.current?.isEmpty()", isSigned, trimmedDataURL)

    if (bookingId !== undefined) {
      setIsSigned(false)
      setToggleSigned(true)
      // setTrimmedDataURL(sigPadRef?.current?.toDataURL("image/png"))
    } else {
      if (trimmedDataURL !== null) {
        setTrimmedDataURL(sigPadRef?.current?.toDataURL("image/png"))
        setIsSigned(false)
      } else {
        setToggleSigned(false)
        setIsSigned(true)
      }
    }
  }


  const handleEditPriceModalClose = () => {
    setIsEditPriceOpen(false); // Close the SignatureModal
    // setIsOpen(true); // Close the NewAppointment
  };
  const handleEditTreatmentPriceModalClose = () => {
    setIsEditTreatmentPriceOpen(false); // Close the SignatureModal
    // setIsOpen(true); // Close the NewAppointment
  };

  const handleEditSubTotalModalClose = () => {
    setIsEditSubTotal(false); // Close the SignatureModal
    // setIsOpen(true); // Close the NewAppointment
  };
  const handleDiscountPriceModalClose = () => {
    setIsDiscountPriceOpen(false); // Close the SignatureModal
    //setIsOpen(true); // Close the NewAppointment
  };

  const handleFormModalClose = () => {
    setIsFormOpen(false); // Close the SignatureModal
    //setIsOpen(true); // Close the NewAppointment
  };

  const handleOpenTreatmentModal = () => {
    setSelectedForAdd(2)
    setLocationTreatmentModalState(true)
  }
  console.log("selected client data", selectedClientData)
  //toogle Signed
  const [toggleSigned, setToggleSigned] = useState(false)
  //Signed On or Off
  useEffect(() => {
    console.log("Sign akash", toggleSigned)
  }, [toggleSigned])


  const ToastContainer = ({ isBookingSuccess }) => {
    return (
      <>{
        isBookingSuccess ?
          showToast("", `🎉 Appointment ${bookingId !== undefined ? "updated" : "created"}`, "Go to booking", true, handleToastClick, showClientDataInToast, { toastId: 'appoitment-updated-success-toast' })
          : ""
      }
      </>
    )
  }


  function findClosestTimeSlot(inputTime, timeSlots) {
    const inputMoment = moment(inputTime, "HH:mm");

    // Filter time slots that are in the future
    const futureTimeSlots = timeSlots.filter(timeSlot => moment(timeSlot, "HH:mm").isSameOrAfter(inputMoment));

    // If there are future time slots, find the closest one
    if (futureTimeSlots.length > 0) {
      let closestTimeSlot = futureTimeSlots[0];

      for (let i = 1; i < futureTimeSlots.length; i++) {
        const currentMoment = moment(futureTimeSlots[i], "HH:mm");
        if (Math.abs(inputMoment.diff(currentMoment)) < Math.abs(inputMoment.diff(moment(closestTimeSlot, "HH:mm")))) {
          closestTimeSlot = futureTimeSlots[i];
        }
      }

      return closestTimeSlot;
    }

    // If there are no future time slots, return null or handle as needed
    return "00:00";
  }

  const displayToastMessage = useMemo(() => <ToastContainer isBookingSuccess={isBookingSuccess} />, [isBookingSuccess])

  //Time slot data
  const timeData = [
    {
      id: 1,
      label: "24 hours",
      timeSlot: 1
    },

    {
      id: 2,
      label: "48 hours",
      timeSlot: 2

    },

    {
      id: 3,
      label: "72 hours",
      timeSlot: 3
    },
    {
      id: 4,
      label: "4 days",
      timeSlot: 4

    },
    {
      id: 5,
      label: "5 days",
      timeSlot: 5
    },
  ];

  //Time slot data
  const followUpData = [
    {
      id: 1,
      label: "24 hours",
      timeSlot: 1
    },

    {
      id: 2,
      label: "1 week",
      timeSlot: 7

    },

    {
      id: 3,
      label: "2 weeks",
      timeSlot: 14

    },
    {
      id: 4,
      label: "1 month",
      timeSlot: 30
    },
    {
      id: 5,
      label: "3 months",
      timeSlot: 90
    },
    {
      id: 6,
      label: "6 months",
      timeSlot: 180
    },
    {
      id: 7,
      label: "9 months",
      timeSlot: 270,

    },
    {
      id: 8,
      label: "12 months",
      timeSlot: 365,

    },
    {
      id: 9,
      timeSlot: "Other",
      label: "Other"
    },
  ];
  console.log("folowup", followUpType)

  //Time Slot Handle Change
  const handleTimeSlotChange = (selectedValue, id) => {
    let treatmentDuration = 30
    if (id === "startTime") {
      setStart(selectedValue);
      if (selectedTreatmentData.length !== 0) {
        treatmentDuration = selectedTreatmentData?.reduce((total, item) => { return total + parseInt(item?.treatmentTime) }, 0)
      }
      if (parseInt(calculateTotalHours(selectedValue, end, true)) <= 0) {
        const parsedTime = moment(selectedValue, 'HH:mm');
        const newTime = parsedTime.add(treatmentDuration, 'minutes');
        setEnd(newTime.format('HH:mm'))
      }
      setSelectedStartTime(selectedValue)

    }

    if (id === "endTime") {

      setEnd(selectedValue);
    }
  }

  //SET TIME FOR NEW TIME PICKER MODAL

  const handleTimeChangeForNewTimePicker = (selectedValue) => {
    let treatmentDuration = 30
    let tempStart = timeRange?.start;
    let tempEnd = timeRange?.end;
    if (timing == 'start') {
      tempStart = selectedValue;

      if (selectedTreatmentData.length !== 0) {
        treatmentDuration = selectedTreatmentData?.reduce((total, item) => { return total + parseInt(item?.treatmentTime) }, 0)
      }
      // if (parseInt(calculateTotalHours(tempStart, tempEnd, true)) <= 0) {
      const parsedTime = moment(tempStart, 'HH:mm');
      const newTime = parsedTime.add(treatmentDuration, 'minutes');
      tempEnd = (newTime.format('HH:mm'))
      // }
    }
    if (timing == 'end') {
      tempEnd = selectedValue;
    }

    if (!tempEnd) {
      const parsedTime = moment(tempStart, 'HH:mm');
      const newTime = parsedTime.add(treatmentDuration, 'minutes');
      tempEnd = newTime.format('HH:mm');
    }

    if (parseInt(calculateTotalHours(tempStart, tempEnd, true)) <= 0) {
      return null;
    }

    return {
      start: tempStart,
      end: tempEnd,
    }
  }

  //CALCULATE HOURS 

  const calculateHours = (time) => {
    //1 month 
    let timeNumber = parseInt(time?.split(" ")[0])
    let type = time.split(" ")[1]

    if (type == "hours") {
      return timeNumber
    } else if (type == "days" || type == "day") {
      return timeNumber * 24
    } else if (type == "weeks" || type == "week") {
      return timeNumber * 24 * 7
    } else if (type == "months" || type == "month") {
      // // End date

      let startDate = moment(showSelectedDate, "dddd, Do MMMM")

      const endDate = startDate.clone().add(timeNumber, 'months');


      // Calculate the duration in hours
      const durationInHours = endDate.diff(startDate, 'hours');
      return durationInHours;
    }
    else {
      return calculateHours(selectedFollowUpOtherTime + " " + buttonActive)
    }
  }

  //Calculate Time
  const calculateTotalTime = () => {
    const totalMinutes = selectedTreatmentData.reduce((total, item) => {
      return total + parseInt(item.treatmentTime, 10);
    }, 0);
    // Convert total minutes to hours
    const totalHours = totalMinutes / 60;
    return totalHours;
  };
  console.log("total treamentTime", calculateTotalTime())
  //Convert Base64 Singature to File
  const convertBase64ToFile = (image) => {
    const byteString = atob(image.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    const newBlob = new Blob([ab], {
      type: 'image/jpeg',
    });
    return newBlob;
  };

  //API CALL FUNCTION FOR CREATE/UPDATE APPOINTMENT

  const initCallCreateAppointmentFunc = () => {

    initiateCreateNewAppointmentResponseData({
      clientId: selectedClientData?.clentId,
      clinicId: selectedLocationData?.clinicId == undefined ? null : selectedLocationData?.clinicId,
      appointmentDate: moment(showSelectedDate, "dddd, Do MMMM").format("YYYY-MM-DD"),
      appointmentStartTime: timeRange.start,
      appointmentEndTime: timeRange.end,
      appointmentId: parseInt(bookingId),
      treatmentIds: selectedTreatmentData.map((item) => item?.treatmentId),
      subTotal: parseFloat(subtotalSave),
      totalAmount: parseFloat(subtotalSave),
      consentFormIds: selectedConsentFormData.map((item) => item?.consentFormId),
      aftercareDocumentIds: selectedAfterCareData.map((item) => item?.aftercareDocId),
      holdDuration: selectedTime ? calculateHours(selectedTime + " " + "days") / 24 : 0,
      followUps: selectedFollowUpTime ? calculateHours(selectedFollowUpTime + " " + "days") / 24 || parseInt(selectedFollowUpOtherTime) : 0,
      emailReminders: toggle?.emailReminder,
      textReminders: toggle?.textReminder,
      collectPayment: toggle?.collectPayment,
      payMonthly: !(stripeFinceStatusObj?.userHasDisabledFinanceOption) ? (stripeFinceStatusObj?.financeRequestStatus == 2 ? (subtotalSave > 50 ? true : false) : toggle?.offerPay) : toggle?.offerPay,
      discountCodeType: discountCodeType === "percentage" ? "PERCENTAGE" : "FIXED",
      discountValue: parseFloat(discount),
      depositAmount: parseFloat(deposit),
      treatmentData: selectedTreatmentData.map((item) => ({ treatmentId: item?.treatmentId, treatmentPrice: +(item?.treatmentPrice) })),
      followUpsType: followUpType !== 0 ? followUpType : 0,
      signatureBase64: (bookingId !== undefined && signedSignatureData == appointmentDetails?.signature) ? "" : removeBase64Prefix(signedSignatureData), // If signature data is a URL, do not pass anything.
      sendMedicalForm: medicalForm
    }, (res) => {
      if (res?.success) {

        // if create appointment - redirect to thank you page
        if (!bookingId) {
          const cfrId = res.data.consentFormRecordId;
          navigate('/consent-form-thank-you/' + cfrId);
          return;
        }

        setShowClientDataInToast(selectedClientData)
        setIsBookingSuccess(true)
        setIsSuccessBookingId(res?.data?.appointmentId)
        setSignedSignatureData(null)
        setSelectedClientData([]);
        setSelectedLocationData([]);
        setShowSelectedDate(null);
        setTimeRange({ start: "", end: "" })
        setTrimmedDataURL(null)
        setToggleSigned(false)
        setSelectedTreatmentData([])
        setSelectedAfterCareData([])
        setToggles({
          textReminder: false,
          emailReminder: false,
          collectPayment: false,
          offerPay: false,
        });
        setSelectedTime(null);
        setSelectedFollowUpTime(null);
        setSelectedFollowUpOtherTime(null);
        setIsEditPriceOpen(false);
        setIsDiscountPriceOpen(false);
        setActive("percent");
        setButtonActive("days")
        setSelectedConsentFormData([])
        setSelectedNewConsentData([])
        setSelectedFormType(null)
        setFollowUpType(0)
        setOtherText("")
        setIsHoldDurationIcon(null)
        setIsHoldDurationOpen(false);
        setIsFollowUpIcon(null)
        setIsFollowupIconType(0)
        setIsFollowUpIconOtherTime(null)
        setIsFollowUpOpen(false);
        setSubtotalSave(0);
        setDiscount(0);
        setDepositPrice(0);
        setDeposit(0);
        window.scrollTo(0, 0);
        handleClearDetails()
        if (bookingId) {
          initGetAppointmentDetailsV3({
            appointmentId: parseInt(bookingId)
          })
        }
      } else {
        //toast.error(res?.message)
        showToast("error", res?.message, "", false)
        if (location?.state?.appointmentDate) {
          navigate(getCalendarRoute(), { state: { AppointmentDate: location?.state?.appointmentDate } })
        }
      }
    }, (err) => {
      //toast.error(err?.message)
      showToast("error", err?.message, "", false)
    })
  }

  const handleApiCallForAppointment = () => {
    if ((selectedLocationData?.clinicId !== undefined)) {
      initIsAlreadyBookingExistData({
        date: moment(showSelectedDate, "dddd, Do MMMM").format("YYYY-MM-DD"),
        clinicId: selectedLocationData?.clinicId,
        startTime: timeRange.start,
        endTime: timeRange.end,
        treatmentIds: selectedTreatmentData.map((item) => item?.treatmentId),
        appointmentId: bookingId != undefined ? parseInt(bookingId) : null
      }, (responseData) => {
        setIsAvailableForWorking(false)
        if (responseData?.data?.isBookingExist) {
          setOverLappingMessage(responseData?.message)
          setIsAppointmentOverlappingOpen(true)
        } else {
          initCallCreateAppointmentFunc()
        }
      }, (error) => {
        showToast("error", error?.message, "", false, undefined, undefined, { toastId: 'appointment-create-api-failed-error-toast' })
      })
    } else {
      initCallCreateAppointmentFunc()
    }
  }

  const handleCreateAppointment = () => {

    if (selectedClientData?.clentId == undefined) {
      showToast("error", "Please select client", "", false, undefined, undefined, { toastId: 'select-client-error-toast' })
      return
    }
    // if (selectedLocationData?.clinicId == undefined) {
    //   showToast("error", "Please select location", "", false)
    //   return
    // }
    if (showSelectedDate == null) {
      showToast("error", "Please select appointment date", "", false, undefined, undefined, { toastId: 'select-appointment-date-error-toast' })
      return
    }
    if (!validateForm(timeRange.start, timeRange.end)) {
      showToast("error", "Please select appointment time", "", false, undefined, undefined, { toastId: 'select-appointment-time-error-toast' })
      return;
    }
    // if (selectedTreatmentData?.length == 0) {
    //   showToast("error", "Please select treatment", "", false)
    //   return
    // }

    if (signedSignatureData == null) {
      showToast("error", "Please add signature", "", false, undefined, undefined, { toastId: 'add-sign-error-toast' })
      return
    }

    //CHECK IF DATE IS AVAILABLE AND IF DATE IS IN PAST DATE
    if (selectedLocationData?.clinicId !== undefined && moment(showSelectedDate, "dddd, Do MMMM").isSameOrAfter(moment(), 'day')) {
      let isAvailable = clinicsAvailableDateData?.data?.getClinicAvailableDates?.data?.items?.includes(moment(showSelectedDate, "dddd, Do MMMM").format("YYYY-MM-DD"))
      if (!isAvailable) {
        setIsAvailableForWorking(true)
        return;
      }
    }
    //CALL API FOR CHECKING APPOINTMENT IS OVERLAPPING OR NOT
    handleApiCallForAppointment()
  }

  const handleToastClick = () => {
    if (isSuccessBookingId !== null) {
      // navigate(getCalendarRoute(), { state: { fromCanvasAppointment: isSuccessBookingId } })
      navigate(getAppointmentRoute(isSuccessBookingId), { state: { fromCalendarAppointmentOverview: true } })
    }
  }

  console.log(location?.state?.from  , "giif")
  const handleClearReducer = () => {
    console.log("asha test", location?.state)
    dispatch({
      type: SET_NEW_CLIENT_LIST_CLEAR
    });
    dispatch({
      type: SET_NEW_CLINIC_LIST_CLEAR
    })
    if (location?.state?.appointmentDate) {
      navigate(getCalendarRoute(), { state: { AppointmentDate: location?.state?.appointmentDate } })
    }
    else if (location?.state?.from === "new_appointment_home") {
      navigate(businessDashboardRoute())
    }
    
    else {

      location?.state?.fromClientProfile ?
        navigate(getAppointmentRoute(parseInt(bookingId)), { state: { clientProfile: "clientprofile", clientId: location?.state?.clientId, active: location?.state?.active } })
        :
        location?.state?.fromDashboardAppointmentList ? navigate(getAppointmentListRoute(), { state: { fromDashboardAppointmentList: location?.state?.fromDashboardAppointmentList, clickdate: location?.state?.clickdate, active: location?.state?.active } }) :
          location?.state?.fromAppointmentList ? navigate(getCalendarRoute(), { state: { fromAppointmentList: location?.state?.fromAppointmentList, clickdate: location?.state?.clickdate, calenderViewState: location?.state?.calenderViewState, calenderShowSelectedDate: location?.state?.calenderShowSelectedDate } }) :
            location?.state?.fromCalenderAppointmentOverview ?
              navigate(getAppointmentRoute(parseInt(bookingId)), { state: { fromCalenderAppointmentList: "calender", fromAppointmentList: true, calenderViewState: location?.state?.calenderViewState, calenderShowSelectedDate: location?.state?.calenderShowSelectedDate } })
              :
              location?.state?.fromFormsScreen ? navigate(getClientProfileFormsRoute(location?.state?.clientId)) :
                location?.state?.clientId ? navigate(getClientProfileRoute(location?.state?.clientId), { state: { getBackToClientAppointmentScreen: true, active: location?.state?.active } }) :
                  location?.state?.fromDashboard ? navigate(businessDashboardRoute()) :
                    location?.state?.fromCalenderAppointment ? navigate(getAppointmentRoute(parseInt(bookingId)), { state: { fromCalenderAppointmentList: "calender", calenderViewState: location?.state?.calenderViewState, calenderShowSelectedDate: location?.state?.calenderShowSelectedDate } }) :
                      location?.state?.fromDashboardAppointment ? navigate(getAppointmentRoute(parseInt(bookingId))) :
                        location?.state?.appointmentListing ? navigate(getAppointmentListRoutePattern) :
                          navigate(getCalendarRoute())
    }
  }


  const calculateTotal = () => {
    let subtotal = 0;

    // Iterate through selectedTreatmentData and add up the prices
    selectedTreatmentData.forEach((item) => {
      subtotal += parseFloat(item.treatmentPrice) || 0; // Assuming price is a string, convert to float
    });



    // return totalAfterDeposit.toFixed(2);
  };

  //Calculate Due Amount
  const calculateDueAmount = (funcSubtotal = false) => {
    // let subtotal = 0;
    let tempSubtotal
    if (funcSubtotal == false) {
      tempSubtotal = subtotalSave
    } else {
      tempSubtotal = funcSubtotal
    }
    let tempDiscount;
    if (discountCodeType == "percentage") {
      tempDiscount = percentageToValueConverter(discount, tempSubtotal)
    }
    else {
      tempDiscount = discount;
    }
    console.log("newdata", parseFloat(totalAmount))
    console.log(deposit, "done for today")
    // Iterate through selectedTreatmentData and add up the prices
    // return subtotal += totalAmount !== NaN ? parseFloat(totalAmount) - (parseFloat(discount) - parseFloat(deposit)) : 0 - (parseFloat(discount) - parseFloat(deposit)); // Assuming price is a string, convert to float
    let finalDueAmount = tempSubtotal - tempDiscount - deposit

    let afterDueObj = {
      finalDueAmount: finalDueAmount,
      tempDiscountReset: false,
      tempDeposit: deposit
    }

    if (funcSubtotal != false && finalDueAmount < 0) {
      finalDueAmount = finalDueAmount + parseInt(tempDiscount)
      afterDueObj = { ...finalDueAmount, finalDueAmount: finalDueAmount }
      if (finalDueAmount < 0 && !(appointmentDetails?.depositPaid)) {
        finalDueAmount = finalDueAmount + deposit - selectedTreatmentData.reduce((total, item) => { return total + parseInt(item?.depositAmount) }, 0)
        if (finalDueAmount >= 0) {
          afterDueObj = { ...finalDueAmount, finalDueAmount: finalDueAmount, tempDiscountReset: true, tempDeposit: selectedTreatmentData.reduce((total, item) => { return total + parseInt(item?.depositAmount) }, 0) }
        }
      } else {
        afterDueObj = { ...finalDueAmount, finalDueAmount: finalDueAmount, tempDiscountReset: true, tempDeposit: deposit }
      }
      console.log("finalDueAmount", finalDueAmount)
    }
    if (funcSubtotal != false)
      return afterDueObj
    else
      return finalDueAmount

  }

  //CHECK DUE ON DELETE TREATMENT
  const fixDueOnDeleteTreatment = (subTotal, removedTreatmentdeposit) => {
    let tempDiscount;
    let selectedTreatmentDeposit = selectedTreatmentData.reduce((a, b) => a + (parseFloat(b.depositAmount) || 0), 0) - removedTreatmentdeposit

    if (discountCodeType == "percentage") {
      tempDiscount = percentageToValueConverter(discount, subTotal)
    }
    else {
      tempDiscount = discount;
    }
    if (appointmentDetails?.depositPaid) {
      setSubtotalSave(subTotal)
      if (subTotal - tempDiscount - deposit < 0) {
        if (subTotal - deposit < 0) {
          setSubtotalSave(deposit)
        }
        setDiscount(0)
        setDiscountCodeType("percentage")
      }

      setDeposit(deposit)
    } else {
      setSubtotalSave(subTotal)

      if (!bookingId && selectedTreatmentDeposit < 1.20) {
        setToggles({ ...toggle, collectPayment: false })
      }
      if (subTotal < 50)
        setToggles((prevToggles) => ({
          ...prevToggles,
          ["offerPay"]: false,
        }));

      if (deposit - removedTreatmentdeposit < 0) {
        if (subTotal - tempDiscount - selectedTreatmentDeposit < 0) {
          setDeposit(selectedTreatmentDeposit)
          setDiscount(0)
          setDiscountCodeType("percentage")

        }
      } else {
        let tempDeposit = deposit - removedTreatmentdeposit

        if (subTotal - tempDiscount - tempDeposit < 0) {
          if (subTotal - tempDeposit < 0) {
            setDeposit(selectedTreatmentDeposit)
          } else {
            setDeposit(tempDeposit)
          }
          setDiscount(0)
          setDiscountCodeType("percentage")
        } else {
          setDeposit(tempDeposit)
        }
      }
    }

  }


  //CALENDAR MODAL
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [showSelectedDate, setShowSelectedDate] = useState(null);
  // const [showSelectedDate, setShowSelectedDate] = useState(moment().format("dddd, Do MMMM"));
  const [calendarStartDate, setCalendarStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isEdit, setIsEdit] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)

  console.log(calendarStartDate, "calendarStartDate")

  const [dateData, setDateData] = useState([])

  const [selectedMonth, setSelectedMonth] = useState(
    selectedDate.getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
  const [isAvailableForWorking, setIsAvailableForWorking] = useState(false);

  const getCalendarAppointmentCount = (location, date) => {
    initGetCalendarAppointmentCountData({
      startDate: moment(date, "YYYY-MM-DD").clone().startOf('month').format("YYYY-MM-DD"),
      endDate: moment(date, "YYYY-MM-DD").clone().endOf('month').format("YYYY-MM-DD"),
      locations: location
    })
  }

  useEffect(() => {
    console.log("changes", startDate, endDate, selectedLocationData?.clinicId)
    if (selectedLocationData?.clinicId && endDate) {
      // if (moment(startDate).format("YYYY-MM") == moment().format("YYYY-MM") && bookingId === undefined) {
      //   setDateData([])
      //   initClinicsAvailableDateResponseFunction({
      //     clinicId: selectedLocationData?.clinicId,
      //     startDate: moment().format("YYYY-MM-DD"),
      //     endDate: endDate,
      //   });
      // } else {
      initClinicsAvailableDateResponseFunction({
        clinicId: selectedLocationData?.clinicId,
        startDate: moment(startDate, "YYYY-MM-DD").clone().startOf('month').format("YYYY-MM-DD"),
        endDate: moment(endDate, "YYYY-MM-DD").clone().endOf('month').format("YYYY-MM-DD"),
      });


      // }
    }
    getCalendarAppointmentCount(selectedLocationData?.length == 0 ? [] : [selectedLocationData?.clinicId], startDate)
    console.log(selectedLocationData, "selectedLocationData axxy")
    // if (moment(startDate).format("YYYY-MM") == moment().format("YYYY-MM") && bookingId === undefined) {
    //   initGetCalendarAppointmentCountData({
    //     startDate: moment().format("YYYY-MM-DD"),
    //     endDate: endDate,
    //     locations: selectedLocationData?.length == 0 ? [] : [selectedLocationData?.clinicId]
    //   })
    // } else {
    //   initGetCalendarAppointmentCountData({
    //     startDate: startDate,
    //     endDate: endDate,
    //     locations: selectedLocationData?.length == 0 ? [] : [selectedLocationData?.clinicId]

    //   })
    // }


  }, [startDate, endDate, selectedLocationData?.clinicId])

  const calculateTotalHours = (start, end, withOutString = false) => {
    const currentDate = moment().format('YYYY-MM-DD');
    console.log("start", start)
    console.log("end", end)

    const startTime = moment(`${currentDate} ${start}`);
    const endTime = moment(`${currentDate} ${end}`);

    // Calculate the difference in minutes
    const minutes = endTime.diff(startTime, 'minutes');

    if (withOutString) {
      return minutes
    }

    if (minutes < 60) {
      // If less than 60 minutes, display minutes
      // setTotalHours({ value: minutes, unit: 'mins' });
      return `(${minutes} minutes)`
    } else {
      // If 60 minutes or more, display hours
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;

      if (hours === 1) {
        if (remainingMinutes > 0) {
          return `(${hours} hour ${remainingMinutes} minutes)`;
        } else {
          return `(${hours} hour)`;
        }
      } else {
        if (remainingMinutes > 0) {
          return `(${hours} hours ${remainingMinutes} minutes)`;
        } else {
          return `(${hours} hours)`;
        }
      }

    }
  }

  useEffect(() => {
    if (bookingId !== undefined) {
      //API CALL
      initGetAppointmentDetailsV3({
        appointmentId: parseInt(bookingId)
      })
    }
    if (appointmentDetails !== undefined && appointmentDetails.length !== 0) {
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
  }, [bookingId, appointmentDetails])
  //const [newHours,setNewHours] = useState(0)

  useEffect(() => {
    const currentDate = moment().format('YYYY-MM-DD');

    const startTime = moment(`${currentDate} ${timeRange.start}`);
    const endTime = moment(`${currentDate} ${timeRange.end}`);
    // Calculate the difference in minutes
    const minutes = endTime.diff(startTime, 'minutes');

    if (minutes < 60) {
      // If less than 60 minutes, display minutes
      // setTotalHours({ value: minutes, unit: 'mins' });
      setHours({ value: minutes, unit: 'mins' })
    } else {
      // If 60 minutes or more, display hours
      const hours = endTime.diff(startTime, 'hours', true);
      //setTotalHours({ value: hours, unit: 'hours' });
      setHours({ value: hours, unit: 'hours' })
    }
  }, [timeRange.start, timeRange.end])

  //=================EDIT APPOINTMENT DATA=================
  useEffect(() => {

    console.log("booking", appointmentDetails)
    //PreSelected Data using State
    if (appointmentDetails !== undefined && appointmentDetails.length !== 0) {

      setSelectedClientData({
        clentId: appointmentDetails?.clientDetails?.clientId,
        clientName: appointmentDetails?.clientDetails?.firstName + " " + appointmentDetails?.clientDetails?.lastName,
        clientEmail: appointmentDetails?.clientDetails?.email,
        clientProfileImage: appointmentDetails?.clientDetails?.client_image,
      })
      if (appointmentDetails?.clinicId) {
        setSelectedLocationData({
          clinicId: appointmentDetails?.clinicId,
          clinicName: appointmentDetails?.clinicName,
        })
        getClinicAvailibilityHandler(appointmentDetails?.clinicId,
          moment(appointmentDetails?.appointmentDate, "ddd, DD MMMM").format("YYYY-MM-DD")
        );
        getCalendarAppointmentCount(appointmentDetails?.clinicId, moment(appointmentDetails?.appointmentDate, "ddd, DD MMMM").format("YYYY-MM-DD"))

      }
      setShowSelectedDate(appointmentDetails?.appointmentDate)
      setStartDate(moment(appointmentDetails?.appointmentDate, "ddd, DD MMMM").format("YYYY-MM-DD"))
      // setEndDate(moment(appointmentDetails?.appointmentDate, "ddd, DD MMMM").format("YYYY-MM-DD"))
      setSelectedMonth(moment(appointmentDetails?.appointmentDate, "ddd, DD MMMM").format("M"))
      setSelectedYear(moment(appointmentDetails?.appointmentDate, "ddd, DD MMMM").format("YYYY"))
      setCalendarStartDate(moment(appointmentDetails?.appointmentDate, "ddd, DD MMMM").format("YYYY-MM-DD"))

      setTimeRange({
        // start:moment(appointmentDetails?.start_time,"MM-DD-YYYY HH:mm").format("HH:mm"),
        // end:moment(appointmentDetails?.end_time,"MM-DD-YYYY HH:mm").format("HH:mm")
        start: appointmentDetails?.appointmentStartTime,
        end: appointmentDetails?.appointmentEndTime
      })
      // calculateTotalHours(appointmentDetails?.appointmentStartTime, appointmentDetails?.appointmentEndTime)
      const newAppointment = []
      appointmentDetails?.treatmentDetails?.map((item) => {
        newAppointment.push({
          treatmentId: item?.treatmentId,
          treatmentName: item?.treatmentName,
          treatmentPrice: item?.treatmentPrice,
          depositAmount: item?.depositAmount,
          treatmentTime: item?.treatmentTime,
          consentFomrs: item?.treatmentCosnetForms,
          aftercareDocs: item?.treatmentAftercareDocs
        })
      })


      setSelectedTreatmentData(newAppointment)

      //Prefilled Toggle Data
      setToggles({
        textReminder: appointmentDetails?.textReminder,
        emailReminder: appointmentDetails?.emailReminder,
        collectPayment: appointmentDetails?.collectPayment,
        offerPay: appointmentDetails?.offerPayMonthly
      })

      setSubtotalSave(appointmentDetails?.subTotal)
      //Set Discount
      if (appointmentDetails?.discountCodeType == "percentage") {
        setPrecentDiscount(appointmentDetails?.discountAmount)
        setDiscountCodeType("percentage")
        setActive("percent")
      } else {
        setPoundDiscount(appointmentDetails?.discountAmount)
        setDiscountCodeType("pound")
        setActive("pound")
      }
      setDiscount(appointmentDetails?.discountAmount)
      //Set Deposit
      setDeposit(appointmentDetails?.depositAmount)
      setDepositPrice(appointmentDetails?.depositAmount)
      //Set Followup
      // setSelectedFollowUpTime(appointmentDetails?.followUps)
      setSelectedFollowUpOtherTime(appointmentDetails?.followUps)
      setFollowUpType(appointmentDetails?.followUpsType)

      if (appointmentDetails?.followUpsType == 0) {
        setSelectedFollowUpTime(appointmentDetails?.followUps)
        setIsFollowUpIcon(appointmentDetails?.followUps)
      } else {
        setSelectedFollowUpTime("Other")
        setIsFollowUpIcon("Other")
        if (appointmentDetails?.followUpsType == 1) {
          setButtonActive("days")
        } else if (appointmentDetails?.followUpsType == 2) {
          setButtonActive("weeks")
        } else if (appointmentDetails?.followUpsType == 3) {
          setButtonActive("months")
        }
      }



      // setIsFollowUpIcon(appointmentDetails?.followUps)
      setIsFollowupIconType(appointmentDetails?.followUpsType)
      setIsFollowUpIconOtherTime(appointmentDetails?.followUps)


      //Set HoldDuraction
      //setSelectedTime(appointmentDetails?.holdDuration + " days")
      setIsHoldDurationIcon(appointmentDetails?.holdDuration)
      // setIsFollowUpIcon()
      setSelectedTime(appointmentDetails?.holdDuration)
      //
      //setTrimmedDataURL(appointmentDetails?.signature)
      setTotalAmount(appointmentDetails?.totalAmount)
      setIsHoldDurationIcon(appointmentDetails?.holdOnAppointmentAdded)
      setIsFollowUpIcon(appointmentDetails?.followUpsAdded)

      //Prefilled Consent Form Data

      let resultConsentFormData = (appointmentDetails?.consentForms.filter((item) => item?.consentFormId !== -1))

      setSelectedConsentFormData(resultConsentFormData)
      setSelectedAfterCareData(appointmentDetails?.aftercareDocs)

      if (appointmentDetails?.consentForms?.length != resultConsentFormData.length) {
        setMedicalForm(true)
      }
      setShowSelectedTime(appointmentDetails?.appointmentStartTime)

    }

  }, [appointmentDetails])

  useEffect(() => {
    if (isBookingSuccess) {
      setTimeout(() => {
        setIsBookingSuccess(false)
      }, 3000)
    }
  }, [isBookingSuccess])


  console.log("selected time", selectedTreatmentData)

  //Remove Treatment Data
  const removeTreatment = (indexToRemove) => {
    let selectedTreatmentDataTemp = selectedTreatmentData.find((item) => item?.treatmentId == indexToRemove)



    const updatedTreatmentData = selectedTreatmentData.filter((item, index) => item?.treatmentId !== indexToRemove);
    setSelectedTreatmentData(updatedTreatmentData);

    let subtotal = 0;
    // console.log("newTreatment", newTreatment)

    updatedTreatmentData.forEach((item) => {
      subtotal += parseFloat(item.treatmentPrice) || 0; // Assuming price is a string, convert to float
    });

    //SET PAYMENT SUMMARY
    fixDueOnDeleteTreatment(subtotal, selectedTreatmentDataTemp?.depositAmount)

    // if (appointmentDetails?.depositPaid) {

    // } else {

    //   setSubtotalSave(subtotal)
    //   // if (calculateDueAmount(subtotal) < 0) {

    //   // }
    // }

    let timeDuration = updatedTreatmentData.reduce((a, b) => a + (parseFloat(b.treatmentTime) || 0), 0)
    if (timeRange.start != "") {


      const parsedTime = moment(timeRange.start, 'HH:mm');

      const newTime = parsedTime.add(timeDuration, 'minutes');

      console.log(newTime.format('HH:mm'));
      if (updatedTreatmentData?.length == 0) {
        setTimeRange({ ...timeRange, end: parsedTime.add(30, 'minutes').format('HH:mm') })
      } else {
        setTimeRange({ ...timeRange, end: newTime.format('HH:mm') })
      }

    }

    let latestAvailableConsentForm = updatedTreatmentData?.map((item) => item?.consentFomrs).flat()
    let latestAvailableAfterCare = updatedTreatmentData?.map((item) => item?.aftercareDocs).flat()

    console.log(selectedTreatmentDataTemp, "selectedTreatmentDataTemp")

    //REMOVE CONSENT FORMS OF PARTICULAR TREATMENT
    selectedTreatmentDataTemp?.consentFomrs?.forEach((item) => {
      if (!latestAvailableConsentForm.includes(item)) {
        removeConsentForm(item)
      }
    })

    //REMOVE AFTER CARE OF PARTICULAR TREATMENT
    selectedTreatmentDataTemp?.aftercareDocs?.forEach((item) => {
      if (!latestAvailableAfterCare.includes(item)) {
        removeAfterCare(item)
      }
    })
  };

  //Remove Consent Form Data
  const removeConsentForm = (indexToRemove) => {
    const updatedConsentFormData = selectedConsentFormData.filter((item, index) => item?.consentFormId !== indexToRemove);
    if (indexToRemove == -1) {
      setMedicalForm(false)
    } else {
      setSelectedConsentFormData(updatedConsentFormData);
    }
  };

  //Remove After Care Data
  const removeAfterCare = (indexToRemove) => {
    const updatedAfterCareData = selectedAfterCareData.filter((item, index) => item?.aftercareDocId !== indexToRemove);
    setSelectedAfterCareData(updatedAfterCareData);
  };

  //=========CLEAR DEPENDENT STATES=========
  const clearDependentStates = (clientRelated = false) => {
    if (clientRelated) {
      if (stripeFinceStatusObj?.clinicId == null)
        setSelectedLocationData([])

      //HOLD DURATION AND FOLLOWUP
      setSelectedTime(null)
      // setSelectedFollowUpTime(null)
      setSelectedFollowUpOtherTime(null)
      setFollowUpType(0)
      setOtherText("")
      setIsHoldDurationIcon(null)
      setIsHoldDurationOpen(false);
      // setIsFollowUpIcon(null)
      // setIsFollowupIconType(0)
      // setIsFollowUpIconOtherTime(null)
      setIsFollowUpOpen(false);


      //SIGNATURE
      setSignedSignatureData(null)

      setIsSigned(false)
      setToggleSigned(false)
      setTrimmedDataURL(null);

    }

    //DATE TIME
    setShowSelectedDate(null)
    setTimeRange({ start: '', end: '' })
    setCalendarStartDate(moment().format("YYYY-MM-DD"))
    setStartDate(moment().format("YYYY-MM-DD"))
    setEndDate(null)
    setSelectedMonth(moment().format("M"))
    setSelectedYear(moment().format("YYYY"))

    //TRETMENT AND FORMS
    setSelectedTreatmentData([])
    if (!bookingId) {
      setMedicalForm(false)
    }
    setSelectedConsentFormData([])
    setSelectedAfterCareData([])

    //PAYMENT SUMMARY
    if (appointmentDetails?.depositPaid) {
      setSubtotalSave(deposit)
      setDeposit(deposit)
      setDepositPrice(deposit)
    } else {
      setSubtotalSave(0)
      setDeposit(0)
      setDepositPrice(0)
    }
    setDiscount(0)
    setDiscountCodeType("percentage")
    setPrecentDiscount(0)
    setActive("percent")
    setPoundDiscount(0)
    setTotalAmount(0)


    //TOGGLE
    setToggles({
      textReminder: false,
      // emailReminder: false,
      emailReminder: toggle?.emailReminder,

      collectPayment: false,
      offerPay: false,
    });


  }


  console.log("selected Consent Form", timeRange)

  //GET STRIPE ACCOUNT AND FINANCE REQUEST STATUS
  useEffect(() => {
    if (UserProfileData)
      initGetStripeAccountAndFinanceRequestStatusData({}, (response) => {
        if (response.clinicId) {
          setSelectedLocationData({
            clinicId: response.clinicId,
            clinicName: response.clinicName
          })
        }
        setStripeFinceStatusObj(response)
      })
  }, [UserProfileData])

  useEffect(() => {
    if (isStartTimeOpen) {
      if (selectedLocationData?.clinicId) {

        if (clinicsAvailableDateData?.data?.getClinicAvailableDates?.data?.items?.includes(moment(showSelectedDate, "dddd, Do MMMM").format("YYYY-MM-DD"))) {
          initAvalableTimeSlotData({
            clinicId: selectedLocationData?.clinicId,
            date: moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD"),
            isCreateAppointment: bookingId ? appointmentDetails?.appointmentStartTime : "",
            removePastSlots: false
            // treatmentIds: selectedLocationData?.clinicId,
          })
        }
        else {
          const timeData = TIME();
          console.log("time", timeData)

          const timeSlotData = timeData.map((item) => {
            return { time_slot: item, isBooked: false }
          })

          dispatch({
            type: SET_TIME_SLOTS_FOR_CREATE_APPOINTMENT,
            payload: timeSlotData
          })
        }

      }
      else {
        const timeData = TIME();
        console.log("time", timeData)

        const timeSlotData = timeData.map((item) => {
          return { time_slot: item, isBooked: false }
        })

        dispatch({
          type: SET_TIME_SLOTS_FOR_CREATE_APPOINTMENT,
          payload: timeSlotData
        })

      }
      // setTempShowSelectedTime(showSelectedTime)
    }
  }, [isStartTimeOpen])

  const handleClearDetails = () => {
    if (bookingId !== undefined) {
      dispatch({
        type: CLEAR_APPOINTMENT_DETAILS_V3_DATA
      })
    }
  }

  function removeBase64Prefix(base64Data) {
    const prefix = 'data:image/png;base64,';

    // Check if the base64Data starts with the prefix
    if (base64Data.startsWith(prefix)) {
      // Remove the prefix
      return base64Data.slice(prefix.length);
    } else {
      // If the prefix is not found, return the original string
      return base64Data;
    }
  }
  //update treatment price
  const updateTreatmentPrice = (treatmentId, newPrice) => {
    setSubtotalSave(subtotalSave + (newPrice - treatmentEditPriceAmount))
    setSelectedTreatmentData(prevTreatments => {
      return prevTreatments.map(treatment => {
        if (treatment.treatmentId === treatmentId) {
          return { ...treatment, treatmentPrice: newPrice };
        }
        return treatment;
      });
    });
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;

    // Allow only numeric values (digits)
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) {
      event.preventDefault();
    }
  };

  //Delete Modal
  // const handleDelete =(index,type)=>{
  //   switch(type){
  //     case "consent":
  //       return setConsentIndex(index)
  //       case "aftercare":
  //         return setAfterCareIndex(index)
  //   }

  //   setIsDeleteOpen(true)
  // }

  const closeTimePickerDialog = () => {

    setIsOpenTimePicker(false)

  }

  const onSubmit = (data) => {
    if (!validateForm(start, end)) {
      return;
    }
    setTimeRange({
      start: moment(start, 'HH:mm').format('HH:mm'),
      end: moment(end, 'HH:mm').format('HH:mm'),
    })
    // setStart("09:00")
    // setEnd("09:00")
    setSelectedStartTime(null)
    setIsOpenTimePicker(false)
  };
  //
  const handleConfirmDelete = () => {

    //add manually this code
    if (treatmentIndex) {
      // setDiscount(0)
      // setDiscountCodeType("percentage")
      // setActive("percent")
      let itemPriceTobeRemoved = selectedTreatmentData.find((item) => item?.treatmentId == treatmentIndex)
      if (selectedTreatmentData.length == 1) {
        if (appointmentDetails?.depositPaid) {
          setSubtotalSave(deposit)
          setDeposit(deposit)
        } else {
          setSubtotalSave(0)
          setDeposit(0)
          if (!bookingId) {
            setToggles({ ...toggle, collectPayment: false })
          }

          setToggles((prevToggles) => ({
            ...prevToggles,
            ["offerPay"]: false,
          }));
        }
        setSelectedAfterCareData([])
        setSelectedConsentFormData([])
        setMedicalForm(false)
        setDiscount(0)
        setDiscountCodeType("percentage")
        setActive("percent")
        setSelectedTreatmentData([])
      } else {
        // if (appointmentDetails?.depositPaid) {

        //   setDeposit(deposit)
        // } else {
        //   if (deposit - itemPriceTobeRemoved.depositAmount < 0) {
        //     setDeposit(selectedTreatmentData.reduce((a, b) => a + (parseFloat(b.depositAmount) || 0), 0) - itemPriceTobeRemoved.depositAmount)
        //   } else {
        //     setDeposit(deposit - itemPriceTobeRemoved.depositAmount)
        //   }
        // }
        removeTreatment(treatmentIndex)
      }

    }

    if (afterCareIndex) {
      removeAfterCare(afterCareIndex)
    }
    if (consentIndex) {
      removeConsentForm(consentIndex)
    }
    setAfterCareIndex(null)
    setConsentIndex(null)
    setTreatmentIndex(null)
    setIsDeleteOpen(false)
  }


  //
  useEffect(() => {
    if (bookingId !== undefined) {
      if (appointmentDetails?.signature !== undefined) {
        // setTrimmedDataURL(appointmentDetails?.signature)
        if (appointmentDetails?.signature) {
          setSignedSignatureData(appointmentDetails?.signature)
          setIsSigned(false)
          setToggleSigned(true)
        }
      } else {
        setToggleSigned(false)
        setIsSigned(false)
      }
    }
  }, [bookingId, appointmentDetails])

  console.log("signature", trimmedDataURL)


  const targetHeight = 80;
  let currentScroll;

  const [isSwitch, setIsSwitch] = useState(false);

  window.addEventListener("scroll", function () {
    currentScroll = window.scrollY || window.pageYOffset;

    if (currentScroll >= targetHeight) {
      setIsSwitch(true);
    } else {
      setIsSwitch(false);
    }
  });


  useEffect(() => {
    if (trimmedDataURL == null) {
      console.log("signature useEffect", sigPadRef?.current?.fromDataURL)
    }
  }, [sigPadRef?.current])
  useEffect(() => {
    if (trimmedDataURL == null) {
      console.log("signature useEffect", sigPadRef?.current?.fromDataURL)
    }
  }, [sigPadRef?.current])

  //SET DATE IF WE ARE COMING FROM APPOINTMENT DATE WISE SCREEN

  useEffect(() => {
    if (location?.state?.appointmentDate) {
      let resultDate = moment(location?.state?.appointmentDate, "YYYY-MM-DD")
      setShowSelectedDate(resultDate.format("dddd, Do MMMM YYYY"))

      // setStartDate(resultDate.format("YYYY-MM-DD"))
      // // setEndDate(resultDate.format("YYYY-MM-DD"))
      // setSelectedMonth(resultDate.format("M"))
      // setSelectedYear(resultDate.format("YYYY"))
      // setCalendarStartDate(resultDate.format("YYYY-MM-DD"))
      console.log(resultDate.format("YYYY-MM-DD"), resultDate.format("M"), resultDate.format("YYYY"), "APPOINTMENT DATE WISE", location?.state?.appointmentDate)
    }
  }, [])

  useEffect(() => {
    if (selectedClient && Object.keys(selectedClient)?.length > 0) {
      setSelectedClientData(selectedClient)
    }
  }, [selectedClient])

  const getClinicAvailibilityHandler = (clinicid, date) => {
    initClinicsAvailableDateResponseFunction({
      clinicId: clinicid,
      startDate: moment(date, "YYYY-MM-DD").clone().startOf('month').format("YYYY-MM-DD"),
      endDate: moment(date, "YYYY-MM-DD").clone().endOf('month').format("YYYY-MM-DD"),
    });
  }
  console.log("dailyViewData", dailyViewData)
  useEffect(() => {
    if (dailyViewData && dailyViewData?.fromDayView == "timeGridDay") {

      if (dailyViewData?.date) {
        // setIsClientOffCanvas(true)
        setShowSelectedDate(dailyViewData?.date)

        if (dailyViewData?.time) {
          setStart(dailyViewData?.time)
          const parsedTime = moment(dailyViewData?.time, 'HH:mm');
          const newTime = parsedTime.add(30, 'minutes');
          setEnd(newTime.format('HH:mm'))
          setTimeRange({ start: dailyViewData?.time, end: newTime.format('HH:mm') })
        }
        setSelectedLocationData(dailyViewData.locationData)
      }
      else {
        setSelectedLocationData(dailyViewData.locationData)
      }

      if (dailyViewData?.locationData?.clinicId) {
        getClinicAvailibilityHandler(dailyViewData?.locationData?.clinicId,
          moment(dailyViewData?.date, "dddd, Do MMMM YYYY").format("YYYY-MM-DD")
        );
      }

      getCalendarAppointmentCount(dailyViewData?.locationData?.clinicId, moment(dailyViewData?.date, "dddd, Do MMMM YYYY").format("YYYY-MM-DD"))

    }
  }, [dailyViewData])

  const closeModalHandler = () => {
    setIsOutsideAvaibility(false)
  }
  // console.log("1", availableTimeslots)
  // console.log("2", availableAndOutsideAvaibilityTimeSlots)
  // console.log("location", selectedLocationData)
  console.log("tempShowSelectedTime", tempShowSelectedTime)

  return (
    <>

      {/* New appointment Section start */}
      <div className="CustomeNewAppoiment">
        <NavHeader isClose={true} applyPadding={false} onClickCallback={() => { handleClearReducer(); handleClearDetails() }} />
        <div className="container">
          <div className="MainAppoiment row align-items-start justify-content-between pt-4 d-flex ">
            <div className="col-md-6 text-center order-0 order-sm-0">
              <div className="left-container">
                <h2>{appointmentDetails.length !== 0 ? "Edit" : "New"} appointment</h2>
                <p className="mb-5">
                  Schedule a session for your client and include a consent
                  form if required.
                </p>
                {/* This section for Client */}
                <div className={`section-container mb-5`}>
                  <h1>Client</h1>
                  <hr />
                  {
                    selectedClientData.length !== 0 ?
                      <div className="tile-container" onClick={() => {
                        if (bookingId)
                          if (UserProfileData?.usr_use_password_for_directory)
                            setIsPasswordOpen(true)
                          else
                            navigate(getEditClientRoute(selectedClientData?.clentId))
                        else
                          handleAddClient()
                      }

                      }>
                        <div className="left-section">
                          <Avatar maxInitials={2} size="48"
                            color="#EEEFF3"
                            fgColor="#000" round={true} src={selectedClientData?.clientProfileImage} name={selectedClientData?.clientName} />
                          <div className="d-flex flex-column">
                            <p>{selectedClientData?.clientName}</p>
                            <h1>{selectedClientData?.clientEmail}</h1>
                          </div>
                        </div>
                        <div className="right-section">
                          <RightArrow />
                        </div>
                      </div>
                      :
                      <div className="tile-container" onClick={() => {
                        if (bookingId)
                          if (UserProfileData?.usr_use_password_for_directory)
                            setIsPasswordOpen(true)
                          else
                            navigate(getEditClientRoute(selectedClientData?.clentId))
                        else
                          handleAddClient()
                      }

                      }>
                        <div className="left-section">
                          <AddClient />
                          <p>Add client</p>
                        </div>
                        <div className="right-section">
                          <RightArrow />
                        </div>
                      </div>
                  }
                </div>
                {/* This section for Appointment */}
                <div className="section-container mb-5">
                  <h1>Appointment details</h1>
                  <hr />
                  {
                    selectedLocationData?.length !== 0 ?
                      <div className="tile-container" onClick={handleAddLocation}>
                        <div className="left-section" >
                          <LocationIcon />
                          <p>{capitalizeFirstLetter(selectedLocationData?.clinicName)}</p>
                        </div>
                        <div className="right-section">
                          <RightArrow />
                        </div>
                      </div> :

                      stripeFinceStatusObj?.locationNotCreated ?

                        <div className="tile-container" onClick={() => {
                          setSelectedForAdd(1)
                          setLocationTreatmentModalState(true)
                        }}>
                          <div className="left-section">
                            <img src={AddLocationIcon} alt="AddLocationIcon" width={48} height={48} />
                            <p>Add location</p>
                          </div>
                          <div className="right-section">
                            <RightArrow />
                          </div>
                        </div>
                        :
                        <div className="tile-container" onClick={handleAddLocation}>
                          <div className="left-section">
                            <LocationIcon />
                            <p>Choose location</p>
                          </div>
                          <div className="right-section">
                            <RightArrow />
                          </div>
                        </div>

                  }
                  <div className="tile-container" onClick={() => {
                    if (showSelectedDate != null) {
                      setSelectedMonth(moment(showSelectedDate, "dddd, Do MMMM YYYY").format("M"))
                      setSelectedYear(moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY"))
                      // setCalendarStartDate(moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD"))
                    }
                    setIsOpenCalendar(true)
                    // }
                  }}>
                    <div className="left-section" >
                      <CalanderIcon />
                      <p>{showSelectedDate == null ? 'Choose date' : moment(showSelectedDate, "dddd, Do MMMM YYYY").format('dddd, DD MMMM YYYY')}</p>
                    </div>
                    <div className="right-section">
                      <RightArrow />
                    </div>
                  </div>
                  <div className="tile-container" ref={section1Ref}
                  // onClick={() => {
                  //   if (showSelectedDate == null) {
                  //     showToast("error", "Please select date first", "", false, undefined, undefined, { toastId: 'select-date-first-error-toast' })
                  //   } else {
                  //     if (timeRange.start == "" && showSelectedTime == null) {

                  //       //check is showselected date is same day with current date or not

                  //       if (moment(showSelectedDate, "dddd, Do MMMM YYYY").format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')) {
                  //         let tempTime = findClosestTimeSlot(moment().format("HH:mm"), TIME())
                  //         setStart(tempTime)
                  //         // setStart(moment().format("HH:mm"))
                  //         setEnd(moment(tempTime, "HH:mm").add(30, 'minutes').format("HH:mm"))
                  //         setTempShowSelectedTime(tempTime)
                  //       }
                  //       else {
                  //         setStart("09:00")
                  //         setEnd("09:30")
                  //         setTempShowSelectedTime("09:00")
                  //       }
                  //     } else {
                  //       setStart(timeRange.start)
                  //       setEnd(timeRange.end)
                  //       setTempShowSelectedTime(timeRange.start)
                  //     }
                  //     // setIsOpenTimePicker(true)
                  //     setIsStartTimeOpen(true)
                  //     dispatch({
                  //       type: CLEAR_TIME_SLOTS_FOR_CREATE_APPOINTMENT,
                  //     })
                  //   }
                  // }}
                  >
                    <div className="left-section">
                      <TimerIcon />
                      {/* <p>{timeRange.start === "" ? "Choose time" : `${timeRange.start} - ${timeRange.end} ${appointmentDetails?.length > 0 ? calculateTotalHours(appointmentDetails?.appointmentStartTime, appointmentDetails?.appointmentEndTime)
                        : calculateTotalHours(timeRange.start, timeRange.end)}`}</p> */}
                      <div className="d-flex">
                        {timeRange.start === "" ?
                          <p onClick={() => {

                            if (showSelectedDate == null) {
                              showToast("error", "Please select date first", "", false, undefined, undefined, { toastId: 'select-date-first-error-toast' })
                            }
                            else {
                              if (moment(showSelectedDate, "dddd, Do MMMM YYYY").format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')) {
                                let tempTime = findClosestTimeSlot(moment().format("HH:mm"), TIME())
                                // setStart(tempTime)
                                // // setStart(moment().format("HH:mm"))
                                // setEnd(moment(tempTime, "HH:mm").add(30, 'minutes').format("HH:mm"))
                                setTempShowSelectedTime(tempTime)
                              }
                              else {
                                // setStart("09:00")
                                // setEnd("09:30")
                                setTempShowSelectedTime("09:00")
                              }

                              setTiming("start")
                              setIsStartTimeOpen(true)
                            }
                          }}>Choose time</p> :
                          <>
                            <p onClick={() => {
                              setTiming("start")
                              setIsStartTimeOpen(true)
                              setTempShowSelectedTime(timeRange.start)
                            }}
                              className="text-decoration-underline">{timeRange.start}</p>
                            <p className="ms-1 me-1">-</p>
                            <p onClick={() => {
                              setTiming("end")
                              setIsStartTimeOpen(true)
                              setTempShowSelectedTime(timeRange.end)
                            }}
                              className="text-decoration-underline"
                            >{timeRange.end}</p>
                            <p className="ms-1">{
                              appointmentDetails?.length > 0
                                ? calculateTotalHours(appointmentDetails?.appointmentStartTime, appointmentDetails?.appointmentEndTime)
                                : calculateTotalHours(timeRange.start, timeRange.end)
                            }</p>
                          </>
                        }
                      </div>
                    </div>
                    <div className="right-section">
                      <RightArrow />
                    </div>

                  </div>
                  {/* {isShowTime ? (
                    <OutsideClickHandler
                      onOutsideClick={() => setIsShowTime(!isShowTime)}
                    >
                      <TimeRangePicker setTimeRange={setTimeRange} timeRange={timeRange} setHours={setHours} />
                    </OutsideClickHandler>
                  ) : null} */}
                </div>
                {/* This section for Service */}
                <div className="section-container mb-5" id="service-section">
                  <div className="d-flex justify-content-between align-items-center">
                    <h1>Services</h1>
                    {selectedTreatmentData.length > 0 && <h3 onClick={() => setIsTreatmentListOpen(true)}>Add</h3>}
                  </div>
                  <hr />
                  {
                    selectedTreatmentData.length !== 0 ?
                      selectedTreatmentData.map((item, index) => (
                        <div className="tile-container" key={index}>
                          <div className="left-section">
                            <ServiceIcon />
                            <p>{capitalizeFirstLetter(item?.treatmentName)}</p>
                          </div>
                          <div className="right-section-delete">
                            <p className={animate && index == 0 ? "text-danger animate__animated animate__heartBeat animate__repeat-2  " : ""}
                              // className="m-0" 
                              onClick={() => {
                                handleEditTreatmentPrice(item?.treatmentPrice)
                                setTreatmentEditPriceIndex(item.treatmentId)
                                setTreatmentEditPriceAmount(item.treatmentPrice)
                              }}>{pounds.format(item?.treatmentPrice)}</p>
                            <DeleteIcon onClick={() => { setIsDeleteOpen(true); setTreatmentIndex(item?.treatmentId) }} />
                          </div>

                        </div>
                      ))
                      :
                      stripeFinceStatusObj?.treatmentNotCreated ?
                        <div className="tile-container"
                          onClick={() =>
                            handleOpenTreatmentModal()}
                        >

                          <div className="left-section">
                            <img src={AddServiceIcon} alt="AddServiceIcon" width={48} height={48} />
                            <p>Add service</p>
                          </div>
                          <div className="right-section">
                            <RightArrow />
                          </div>

                        </div>
                        :
                        <div className="tile-container"
                          onClick={() => setIsTreatmentListOpen(true)}>

                          <div className="left-section">
                            <ServiceIcon />
                            <p>Choose service</p>
                          </div>
                          <div className="right-section">
                            <RightArrow />
                          </div>

                        </div>

                  }
                </div>
                {console.log("from signature", appointmentDetails?.signature)}
                {/* This section for Forms */}
                <div className="section-container mb-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <h1>Consent and aftercare forms</h1>
                    {(selectedAfterCareData?.length > 0 || selectedConsentFormData?.length > 0 || medicalForm) && <h3 onClick={() => handleFormHide()}>Add</h3>}
                  </div>
                  <hr />


                  {

                    (selectedAfterCareData?.length !== 0 || selectedConsentFormData?.length !== 0 || medicalForm) ?
                      <>
                        {/* MEDICAL FORM */}
                        {
                          medicalForm &&
                          <div className="tile-container" style={{ cursor: "default" }} >
                            <div className="left-section" >
                              <ConcentFormIcon />
                              <p className={`${bookingId ? "text-muted" : ""}`}>{selectedClientData?.clientName ? selectedClientData?.clientName : "Client"}’s Medical Form</p>
                            </div>
                            <div className={`right-section-delete ${bookingId ? "pe-none" : "cursor-pointer"} `} >
                              <DeleteIcon onClick={() => { setIsDeleteOpen(true); setConsentIndex(-1) }} />
                            </div>

                          </div>
                        }
                        {/* CONSENT FORM LIST */}
                        {
                          selectedConsentFormData?.map((item, index) => (
                            <div className="tile-container" key={index} style={{ cursor: "default" }}>
                              <div className="left-section">
                                <ConcentFormIcon />
                                <p>{capitalizeFirstLetter(item?.consentFormName)}</p>
                              </div>
                              <div className="right-section-delete cursor-pointer">
                                <DeleteIcon onClick={() => { setIsDeleteOpen(true); setConsentIndex(item?.consentFormId) }} />
                              </div>

                            </div>
                          ))
                        }
                        {/* AFTER CARE FORM LIST */}
                        {

                          selectedAfterCareData?.map((item, index) => (
                            <div className="tile-container" key={index} style={{ cursor: "default" }}>
                              <div className="left-section">
                                <AfterCareIcon />
                                <p>{capitalizeFirstLetter(item?.aftercareDocName)}</p>
                              </div>
                              <div className="right-section-delete cursor-pointer">
                                <DeleteIcon onClick={() => { setIsDeleteOpen(true); setAfterCareIndex(item?.aftercareDocId) }} />
                              </div>

                            </div>
                          ))
                        }



                        {console.log("consent form selected", selectedConsentFormData, preAfterCareForm, isEdit)}
                      </>


                      :
                      <div
                        className="tile-container"
                        onClick={() => handleFormHide()}
                      >
                        <div className="left-section">
                          <FormIcon />
                          <p>Add a form</p>
                        </div>
                        <div className="right-section">
                          <RightArrow />
                        </div>
                      </div>
                  }

                </div>

                {/* This section for Manages */}
                <div className="section-container mb-5">
                  <h1>Manage</h1>
                  <hr />
                  <div
                    className="tile-sub-title-container pt-3"
                    onClick={() => setIsHoldDurationOpen(true)}
                  >
                    <div className="left-section">
                      {
                        (isHoldDurationIcon == null || isHoldDurationIcon == 0) ? <HoldDurationInActiveIcon /> : <HoldDurationActiveIcon />
                      }
                      {/* <HoldDurationActiveIcon /> */}
                      <div className="sub-title-section">
                        <h1>Hold appointment</h1>
                        <p>Set how long to hold the booking.</p>
                      </div>
                    </div>
                    <div className="right-section">
                      <RightArrow />
                    </div>
                  </div>
                  <div
                    className="tile-sub-title-container"
                    onClick={() => setIsFollowUpOpen(true)}
                  >
                    <div className="left-section">
                      {
                        isFollowUpIcon == null || isFollowUpIcon == 0 ? <FollowUpInActiveIcon /> : <FollowUpActiveIcon />
                      }
                      <div className="sub-title-section">
                        <h1>Follow-ups</h1>
                        <p>Send & schedule automatic rebooking.</p>
                      </div>
                    </div>
                    <div className="right-section">
                      <RightArrow />
                    </div>
                  </div>
                </div>

                {/* This section for Business boosters */}
                <div className="section-container mb-5 LastSec">
                  <h1>Business boosters</h1>
                  <hr />
                  <div className="tile-container" style={{ cursor: "unset" }}>
                    <div className="left-section">
                      <TextReminderIcon />
                      <p>Text reminders </p>
                    </div>
                    <div className="right-section">
                      <SwitchButton
                        onChange={(e) => handleToggleChange("textReminder", e)}
                        isChecked={toggle.textReminder}
                      />
                    </div>
                  </div>
                  <div className="tile-container" style={{ cursor: "unset" }}>
                    <div className="left-section">
                      <EmailReminderIcon />
                      <p>Email reminders</p>
                    </div>
                    <div className="right-section">
                      <SwitchButton
                        onChange={(e) => handleToggleChange("emailReminder", e)}
                        isChecked={toggle.emailReminder}
                      />
                    </div>
                  </div>
                  <div className="tile-container" style={{ cursor: "unset" }}>
                    <div className="left-section">
                      <CollectPaymentIcon />
                      <p className={`${bookingId !== undefined && (appointmentDetails?.collectPayment || !appointmentDetails?.depositRequestable) ? "text-muted" : ""}`}>Collect payments</p>
                    </div>
                    <div className={`right-section ${bookingId !== undefined && (appointmentDetails?.collectPayment || !appointmentDetails?.depositRequestable) ? "pe-none" : ""}`}>
                      <SwitchButton
                        onChange={(e) =>
                          handleToggleChange("collectPayment", e)
                        }
                        isChecked={toggle.collectPayment}
                      />
                    </div>
                  </div>
                  {
                    (stripeFinceStatusObj?.userHasDisabledFinanceOption || !(stripeFinceStatusObj?.financeRequestStatus == 2)) &&
                    <div className="tile-container" style={{ cursor: "unset" }}>
                      <div className="left-section">
                        <OfferPayIcon />
                        <p className={`${bookingId ? "text-muted" : ""}`}>
                          {/* Offer pay monthly <span>Learn</span> */}
                          Offer Buy now Payl8r
                        </p>
                      </div>
                      <div className={`right-section ${bookingId ? "pe-none" : ""}`}>
                        <SwitchButton
                          onChange={(e) => handleToggleChange("offerPay", e)}
                          isChecked={toggle.offerPay}
                        />
                      </div>
                    </div>
                  }
                </div>

                <div className={screenWidth < 576 ? "d-none" : ""}>
                  <StepButton
                    label={appointmentDetails.length !== 0 ? "Update" : "Create"}
                    blue={true}
                    isLoading={createNewAppointmentMutationData?.loading}
                    onClick={() => handleCreateAppointment()}
                  />
                </div>
              </div>
            </div>

            {/* Right section for payment summary */}
            <div className={`col-md-5 order-sm-1 order-md-1 pt-4 ${isSwitch ? 'sticky-right-section' : 'normal-right-section'}`}>
              <div className="right-section">
                <div className="card-section mb-4">
                  <div className="title-section">
                    <h1>Payment summary</h1>
                    <hr />
                  </div>

                  <div className="content-section" onClick={() => selectedTreatmentData && selectedTreatmentData?.length > 0 ? focusOnSection() : handleSubTotal()}>
                    <h2>Subtotal</h2>
                    <h1>{pounds.format(parseFloat(subtotalSave).toFixed(2))}</h1>
                  </div>
                  <div
                    className="content-section"
                    onClick={() => handleDiscountPriceHide()}
                  >
                    <h2>Add discount</h2>

                    <h1> {discountCodeType == "percentage" ? pounds.format(percentageToValueConverter(discount, subtotalSave)) : pounds.format(parseFloat(discount).toFixed(2))}</h1>
                  </div>

                  <div
                    className={`content-section ${(appointmentDetails?.depositPaid) ? "disable" : ""}`}
                    onClick={() => handleEditPriceHide()}
                  >
                    <h2>Edit deposit</h2>
                    <h1>{pounds.format(parseFloat(deposit).toFixed(2))}</h1>
                  </div>
                  <div className="content-section">
                    <h3>Due amount</h3>
                    <h1>{pounds.format(parseFloat(calculateDueAmount()).toFixed(2))}</h1>
                  </div>
                  <div className="content-section">
                    <h3>Total</h3>
                    <h1>{pounds.format(parseFloat(subtotalSave).toFixed(2))}</h1>
                  </div>
                </div>
              </div>
              <div className="right-section">
                {
                  signedSignatureData &&
                  <div className="cursor-pointer eraserImg " onClick={(event) => {
                    event.stopPropagation();
                    clear()
                  }}>
                    <img src={EraseImg} alt="EraseImg" width={48} height={48} />
                  </div>

                }
                <div className="card-section cursor-pointer signatureCard" onClick={() => handleHide()}>

                  <div className="edit-section " >
                  </div>
                  <div
                    className="d-flex flex-column align-items-center gap-2 sign-section "
                  >
                    {
                      appointmentDetailsV3QueryData.loading ? (
                        <Skeleton width={200} height={128} />
                      ) : signedSignatureData === null ? (
                        <div className="emptySignSec">
                          <EmptySignIcon />
                          <h1>Your signature</h1>
                        </div>
                      ) : (
                        <img src={signedSignatureData} alt="signature" className="signature" />
                      )
                    }
                    {/* {
                   signedSignatureData && signedSignatureData === null ? <h1>Your signature</h1> : ""
                   }  */}
                  </div>
                </div>
              </div>
            </div>
            <div className={screenWidth < 576 ? "" : "d-none"}>
              <StepButton label={appointmentDetails.length !== 0 ? "Update" : "Create"} blue={true} isLoading={createNewAppointmentMutationData?.loading} onClick={() => handleCreateAppointment()} />
            </div>
          </div>
        </div>
      </div >
      {/* New Appointment Section End */}

      {/* <NewClient
        isOpen={isAddClient}
        setIsOpen={setIsAddClient}
        fromCalendar={false}
      /> */}
      {/* For Signature modal */}
      {
        isSignatureOpen && (
          <CustomModal
            className={'CustomeSignModel'}
            modalOpen={isSignatureOpen}
            setIsOpenMobileCalendar={setIsSignatureOpen}
            setModalOpen={handleSignture}
            type="common-width"
            modalBody={
              <div className="d-flex flex-column wrapper-canvas">

                {
                  !previewDataURL ?
                    <SignatureCanvas
                      clearOnResize={false}
                      ref={sigPadRef}
                      canvasProps={{
                        width: 300,
                        height: 150,
                        className: "signCanvas",
                      }}
                      minWidth={1.5}  // Adjust the minimum width as needed
                      maxWidth={1.5}
                      penColor="black"
                      throttle={null}
                      onEnd={() => {
                        setIsSigned(false)
                        setToggleSigned(true)
                        setTrimmedDataURL(sigPadRef?.current?.getTrimmedCanvas().toDataURL("image/png"))
                      }}
                    />
                    :
                    <div className="signature-img-wrapper">
                      <img src={previewDataURL} style={{ objectFit: 'contain' }} />
                    </div>
                }
                <div className="justify-content-between d-flex w-100">
                  {/* <button style={{position:"absolute",top:0,margin:"10px 0px",border:"none",borderRadius:"100px",padding:"10px"}} onClick={()=>sigPadRef?.current?.clear()}>Clear</button> */}
                  {previewDataURL &&
                    <>
                      <div className="cursor-pointer" onClick={() => clear()}>
                        <img src={EraseImg} alt="EraseImg" width={48} height={48} />
                      </div>
                    </>


                    // <div style={{ background: "#EEEFF3", cursor: "pointer", width: "48px", height: "48px", borderRadius: "50%", position: 'relative' }} onClick={clear}>
                    //   <i class="fa fa-eraser" aria-hidden="true" style={{ display: "block", marginLeft: "auto", marginRight: "auto", transform: "translate(15px, 15px)" }} ></i>
                    // </div>
                  }
                  {previewDataURL || trimmedDataURL ? (<UndoEnableIcon style={!previewDataURL ? { cursor: "pointer" } : { visibility: "hidden" }} onClick={() => clear()} />) : <UndoIcon style={!previewDataURL ? { cursor: "not-allowed" } : { visibility: "hidden" }} />}
                  {(
                    <EnableCheck
                      onClick={() => {
                        handleSignatureModalClose();
                        setIsSignatureOpen(false);
                        setToggleSigned(true)
                        handleSignature();

                        if (trimmedDataURL) {
                          // setPreviewDataURL(trimmedDataURL);
                          console.log("signature data akash", trimmedDataURL)
                          setSignedSignatureData(trimmedDataURL)
                        }
                        else if (previewDataURL) {
                          setSignedSignatureData(previewDataURL)
                        }
                        else {

                          setSignedSignatureData(null)

                        }
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )
                    //: (
                    // <DisableCheck style={{ cursor: "not-allowed" }} />
                    // )
                  }
                </div>
                {console.log("flag", isSigned, toggleSigned)}
              </div>

            }
          />
        )

      }

      {
        // Hold Duration Modal
        <OffCanvasModal
          isOpen={isHoldDurationOpen}
          isBack={false}
          onHide={() => {
            setIsHoldDurationOpen(false)
            setSelectedTime(isHoldDurationIcon)
          }}
          className="custom-sidebar-width-30 CustomeHoldappointmentCanvas"
          placement={"end"}
          body={
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-md-12">
                  <div className="hold-duration-container">
                    <div className="hold-duration-content">
                      <div className='hold-duration-header'>

                        <h1>Hold appointment</h1>
                        <p className="mb-3">
                          Choose how long to reserve an appointment slot before a
                          client confirms. Confirmation is via a payment of deposit or treatment.
                        </p>
                      </div>
                      <div className="inputContainerWrapper">
                        {
                          <>
                            {timeData?.map((timeSlot, index) => (
                              <div
                                className="hold-duration-input-container"
                                key={index}
                              >
                                <CustomRadioButton
                                  timeSlot={true}
                                  name="time"
                                  text={timeSlot.label}
                                  id={timeSlot.timeSlot}
                                  value={timeSlot.label}
                                  isblacked={true}
                                  checked={selectedTime == timeSlot.timeSlot}
                                  onChange={(e) => setSelectedTime(e.target.id)}
                                />
                              </div>
                            ))}
                          </>
                        }

                      </div>

                      <div className="sticky-footer">
                        <StepButton
                          label={"Done"}
                          blue={true}
                          labelClassName={"mb-0"}
                          className={"mt-0"}
                          disabled={selectedTime === null || selectedTime === 0}
                          onClick={() => {
                            setIsHoldDurationOpen(false)
                            setIsHoldDurationIcon(selectedTime)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      }

      {
        // Client Followup Modal
        <OffCanvasModal
          isOpen={isFollowUpOpen}
          isBack={false}
          onHide={() => {
            setIsFollowUpOpen(false);
            setSelectedFollowUpTime(isFollowUpIcon)
            setSelectedFollowUpOtherTime(isFollowUpIconOtherTime)
            setFollowUpType(isFollowupIconType)
          }}
          className="custom-sidebar-width-30 CustomeHoldappointmentCanvas"
          placement={"end"}


          body={
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-md-12">
                  <div className="hold-duration-container">
                    <div className="hold-duration-content">
                      <div className="hold-duration-header">
                        <h1>Client follow-ups</h1>
                        <p className="mb-3">
                          Ensure consistent care by scheduling automatic check-ins and allow client to re-bookings.
                        </p>
                      </div>

                      <div className="inputContainerWrapper">

                        {followUpData?.map((timeSlot, index) => (
                          <div className="hold-duration-input-container" key={index}>
                            <CustomRadioButton
                              timeSlot={true}
                              name="time"
                              text={timeSlot.label}
                              id={timeSlot.id}
                              value={timeSlot.timeSlot}
                              isblacked={true}
                              checked={selectedFollowUpTime == timeSlot.timeSlot}
                              onChange={(e) => {
                                if (e.target.value == "Other") {
                                  setFollowUpType(1)
                                  setButtonActive("days")
                                } else {
                                  setFollowUpType(0)
                                }
                                setSelectedFollowUpTime(e.target.value)
                              }}
                            />

                          </div>
                        ))}
                        {(selectedFollowUpTime === "Other" || selectedFollowUpTime == 0) &&
                          <div className="other-textfield">
                            <TextInput type={"number"} placeholder="e.g 3" value={selectedFollowUpOtherTime} maxLength={2} onChange={(e) => {
                              if (selectedFollowUpOtherTime == null || selectedFollowUpOtherTime?.toString()?.length < 4)
                                setSelectedFollowUpOtherTime(e.target.value)
                            }} />
                            <div className="other-buttons">
                              <div className={buttonActive === "days" ? "enable-button" : "disable-button"}>
                                <button onClick={() => handleToggleButton("days")}>Days</button>
                              </div>
                              <div className={buttonActive === "weeks" ? "enable-button" : "disable-button"}>
                                <button onClick={() => handleToggleButton("weeks")}>Weeks</button>
                              </div>
                              <div className={buttonActive === "months" ? "enable-button" : "disable-button"}>
                                <button onClick={() => handleToggleButton("months")}>Months</button>
                              </div>
                            </div>
                          </div>
                        }
                      </div>

                      <div className="sticky-footer">
                        <StepButton
                          label={"Done"}
                          labelClassName={"mb-0"}
                          blue={true}
                          disabled={selectedFollowUpTime === null || selectedFollowUpTime == 0 || (selectedFollowUpTime === "Other" && (selectedFollowUpOtherTime === null || selectedFollowUpOtherTime == 0 || selectedFollowUpOtherTime === ""))}
                          onClick={() => {
                            setIsFollowUpOpen(false);
                            console.log("selectedFollowUpTime ", selectedFollowUpTime);
                            if (selectedFollowUpTime == "Other") {
                              setIsFollowUpIcon(selectedFollowUpTime)
                              setIsFollowupIconType(followUpType)
                              setIsFollowUpIconOtherTime(selectedFollowUpOtherTime)

                            } else {
                              setIsFollowUpIcon(selectedFollowUpTime)
                              setIsFollowupIconType(0)
                              setIsFollowUpIconOtherTime(null)
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          }
        />
      }

      {
        // subtotal Price Modal
        <CustomModal
          modalOpen={isEditSubTotal}
          setIsOpenMobileCalendar={handleEditSubTotalModalClose}
          setModalOpen={setIsEditSubTotal}
          type={"common-width"}
          modaltitle={"Edit subtotal"}
          modaltitleCSS={"discount-title"}
          className={'customeDiscount'}
          modalBody={
            <div className="edit-price-container">
              <div className="edit-price-content">
                {/* <h1>Edit Price</h1> */}

              </div>
              <div className="edit-price-input-container">
                <img src={Suffix} alt="suffix" />
                <input type="text" placeholder="0.00"
                  onFocus={() => {
                    if (subtotal == 0) {
                      setSubtotal("")
                    }
                  }}
                  onChange={(e) => {
                    if (!isNumber((e.target.value)))
                      setSubTotalError("Invalid value")
                    else if ((calculateDueAmount((Number(e.target.value))).finalDueAmount < 0)) {
                      setIsTreatmentPriceEditError("price amount is not valid as it is affecting due amount for it's negative value")
                    }
                    else
                      setSubTotalError("")
                    setSubtotal(e.target.value)
                  }
                  }
                  value={subtotal}
                />
              </div>
              <span className='error-class'>
                {subTotalError && subTotalError}
              </span>

              <div className="btnWrapper">
                <StepButton label={"Save"} disabled={subTotalError !== "" || subtotal == ""} blue={true} onClick={() => {
                  setSubtotalSave(parseFloat(subtotal).toFixed(2));
                  if (appointmentDetails?.depositPaid) {
                    if (subtotal - deposit < 0) {
                      setSubtotalSave(parseFloat(deposit).toFixed(2));
                    }
                  }
                  let checkNeedToUpdate = (calculateDueAmount(subtotal));
                  if (checkNeedToUpdate?.tempDiscountReset) {
                    setDiscount(0);
                    setDiscountCodeType("percentage");
                  }
                  setDeposit(checkNeedToUpdate?.tempDeposit);
                  setIsEditSubTotal(false)
                  if (subtotal < 50)
                    setToggles((prevToggles) => ({
                      ...prevToggles,
                      ["offerPay"]: false,
                    }));
                }} />
              </div>
            </div>
          }
        />
      }

      {
        // Edit Deposit Modal
        <CustomModal
          modalOpen={isEditPriceOpen}
          setIsOpenMobileCalendar={handleEditPriceModalClose}
          setModalOpen={setIsEditPriceOpen}
          type={"common-width"}
          modaltitle={"Edit deposit"}
          modaltitleCSS={"discount-title"}
          className={'customeDiscount'}
          modalBody={
            <div className="edit-price-container">
              <div className="edit-price-content">
              </div>
              <div className="edit-price-input-container">
                <img src={Suffix} alt="suffix" />
                <input type="text" placeholder="0.00" onFocus={() => {
                  if (depositPrice === 0) {
                    setDepositPrice("")
                  }
                }} onChange={(e) => {

                  if (!isNumber(e.target.value))
                    setIsDepositError("Invalid value")
                  else if (checkIsValidValue(e.target.value, discountCodeType == "percentage" ? parseFloat(subtotalSave) * parseFloat(discount) / 100 : discount)) {
                    setIsDepositError("")
                  } else {
                    setIsDepositError("Deposit amount can not be greater than total amount")
                  }
                  setDepositPrice(e.target.value)
                }

                } value={depositPrice} />
              </div>
              <span className='error-class'>
                {isDepositError && isDepositError}
              </span>
              <div className="btnWrapper">
                <StepButton label={"Save"} disabled={isDepositError !== "" || depositPrice == ""} blue={true} onClick={() => {
                  if (depositPrice == 0) {
                    setToggles({ ...toggle, collectPayment: false })
                  }
                  if (!bookingId && toggle?.collectPayment && depositPrice < 1.20) {
                    setToggles({ ...toggle, collectPayment: false })
                  }
                  setDeposit(parseFloat(depositPrice));
                  setIsEditPriceOpen(false)
                }} />
              </div>
            </div>
          }
        />
      }

      {
        // Discount Price Modal
        <CustomModal
          modalOpen={isDiscountPriceOpen}
          setIsOpenMobileCalendar={handleDiscountPriceModalClose}
          setModalOpen={setIsDiscountPriceOpen}
          type={"common-width"}
          modaltitle={"Discount"}
          modaltitleCSS={"discount-title"}
          className={'customeDiscount'}
          modalBody={
            <div className="discount-price-container">
              <div className="discount-price-content">
                {/* <h1>Discount</h1> */}
              </div>
              <div className="tab-container">
                <div
                  className={active === "percent" ? "active" : "inactive"}
                  onClick={() => {
                    handleChangeActive("percent")
                    setIsDiscountError("")
                    setPoundDiscount(0)
                  }}
                >
                  <h1>Percent</h1>
                </div>
                <div
                  className={active === "pound" ? "active" : "inactive"}
                  onClick={() => {
                    handleChangeActive("pound")
                    setIsDiscountError("")
                    setPrecentDiscount(0)
                  }}
                >
                  <h1>Fixed</h1>
                </div>
              </div>
              {active === "percent" && (
                <div className="discount-percent-input-container">
                  <img src={Percent} alt="suffix" />
                  <input type="text" placeholder="0.00" value={percentDiscount}
                    onFocus={() => {
                      if (percentDiscount === 0) {
                        setPrecentDiscount("")
                      }
                    }}
                    onChange={(e) => {
                      if (!isNumber(e.target.value))
                        setIsDiscountError("Invalid value");
                      else if (checkIsValidValue(parseFloat(percentageToValueConverter(e.target.value)).toFixed(2), deposit)) {
                        setIsDiscountError("");
                      }
                      else {
                        setIsDiscountError("Discount amount can not be greater than total amount");
                      }
                      setPrecentDiscount(e.target.value)

                    }}
                  />
                </div>
              )}
              {active === "pound" && (
                <div className="discount-pound-input-container">
                  <img src={Suffix} alt="suffix" />
                  <input type="text" placeholder="0.00" value={poundDiscount}
                    onFocus={() => {
                      if (poundDiscount === 0) {
                        setPoundDiscount("")
                      }
                    }}
                    onChange={(e) => {
                      // setPoundDiscount(e.target.value)
                      if (!isNumber(e.target.value))
                        setIsDiscountError("Invalid value");
                      else if (checkIsValidValue(e.target.value, deposit)) {
                        setIsDiscountError("");
                      }
                      else {
                        setIsDiscountError("Discount amount can not be greater than total amount");
                      }
                      setPoundDiscount(e.target.value)
                    }
                    } />
                </div>
              )}
              <span className='error-class'>
                {isDiscountError && isDiscountError}
              </span>
              <div className="btnWrapper">
                <StepButton label={"Save"} blue={true}
                  disabled={isDiscountError !== "" || (active == "percent" && percentDiscount === "") || (active == "pound" && poundDiscount === "")}
                  onClick={() => {
                    if (active == "percent") {
                      setDiscount(percentDiscount);
                      setDiscountCodeType("percentage")
                      setTotalAmount(appointmentDetails?.subTotal - (appointmentDetails?.subTotal * percentDiscount) / 100)
                    } else {
                      setDiscount(poundDiscount);
                      setDiscountCodeType("pound")
                      setTotalAmount(appointmentDetails?.subTotal - poundDiscount)
                    }
                    setIsDiscountPriceOpen(false)
                  }} />
              </div>
            </div>
          }
        />
      }

      {
        //Concent and  Form Modal
        <CustomModal
          modalOpen={isFormOpen}
          setIsOpenMobileCalendar={handleFormModalClose}
          setModalOpen={setIsFormOpen}
          modaltitle={"Add form"}
          modaltitleCSS={"discount-title"}
          className={"CustomeAddform"}
          modalBody={
            <div className="discount-price-container">
              <div className="d-flex justify-content-between align-items-center cursor-pointer" onClick={() => { setSelectedFormType("ConsentForm"); setOpenConsentModal(true); setIsFormOpen(false) }}>
                <div className="d-flex align-items-center left-content gap-3 mt-3">
                  <ConcentFormIcon />
                  <h1>Consent</h1>
                </div>
                <RightArrow />
              </div>
              <div className="d-flex justify-content-between align-items-center cursor-pointer" onClick={() => { setSelectedFormType("AfterCareForm"); setOpenAfterCareModal(true); setIsFormOpen(false) }}>
                <div className="d-flex align-items-center left-content gap-3 mt-3">
                  <AfterCareIcon />
                  <h1>Aftercare</h1>
                </div>
                <RightArrow />
              </div>
            </div>
          }
        />
      }

      {
        //Treatment List Modal
        <OffCanvasModal
          isOpen={isTreatmentListOpen}
          className={"custom-sidebar-width-30 p-0"}
          placement={"end"}
          isOffCanvasHeader={false}
          onHide={() => setIsTreatmentListOpen(false)}
          body={
            <TreatmentList deposit={deposit} setDeposit={setDeposit} setSubtotalSave={setSubtotalSave} setDiscount={setDiscount} setIsTreatmentList={setIsTreatmentList} clinicId={parseInt(selectedLocationData?.clinicId)} setIsClose={setIsTreatmentListOpen} selectedTreatments={selectedTreatmentData} setSelectedTreatments={setSelectedTreatmentData} timeRange={timeRange} setTimeRange={setTimeRange} selectedAfterCareData={selectedAfterCareData} selectedConsentFormData={selectedConsentFormData} setSelectedAfterCareData={setSelectedAfterCareData} setSelectedConsentFormData={setSelectedConsentFormData} depositPaid={appointmentDetails?.depositPaid} />
          }
        />

      }

      {
        // Edit Treatment Price Modal
        <CustomModal
          modalOpen={isEditTreatmentPriceOpen}
          setIsOpenMobileCalendar={handleEditTreatmentPriceModalClose}
          setModalOpen={setIsEditTreatmentPriceOpen}
          type={"common-width"}
          modaltitle={"Edit Price"}
          modaltitleCSS={"discount-title"}
          className={'customeDiscount'}
          modalBody={
            <div className="edit-price-container">
              <div className="edit-price-content">
                {/* <h1>Edit Price</h1> */}
                <p>
                  Set a unique price for a specific session. For regular service
                  pricing <span
                    onClick={() => { window.location.href = webBaseUrl + "/treatment/edit/" + treatmentEditPriceIndex }}
                  >click here.</span>{" "}
                </p>
              </div>
              <div className="edit-price-input-container">
                <img src={Suffix} alt="suffix" />
                <input type="text" placeholder="0"
                  onFocus={() => {
                    if (treatmentPriceTemp === 0) {
                      setTreatmentPriceTemp("")
                    }
                  }}
                  onChange={(e) => {
                    if (!isNumber(e.target.value))
                      setIsTreatmentPriceEditError("Invalid value")
                    else if (Number(e.target.value) < 0) {
                      setIsTreatmentPriceEditError("Price can not be negative")
                    }
                    else if ((calculateDueAmount(subtotalSave + (Number(e.target.value) - treatmentEditPriceAmount))).finalDueAmount < 0) {
                      setIsTreatmentPriceEditError("Invalid amount. Please set the treatment price higher than the deposit.")
                    } else {
                      setIsTreatmentPriceEditError("")
                    }
                    setTreatmentPriceTemp(e.target.value)
                  }

                  } value={treatmentPriceTemp}
                // onKeyPress={handleKeyPress}
                />
              </div>
              <span className='error-class'>
                {isTreatmentPriceEditError && isTreatmentPriceEditError}
              </span>
              <div className="mt-4">
                <StepButton label={"Save"} disabled={isTreatmentPriceEditError !== ""} blue={true} onClick={() => {
                  console.log("selectedTreatmentData", selectedTreatmentData)
                  if (isTreatmentPriceEditError == "") {
                    updateTreatmentPrice(treatmentEditPriceIndex, treatmentPriceTemp);
                    let checkNeedToUpdate = (calculateDueAmount(subtotalSave + (Number(treatmentPriceTemp) - treatmentEditPriceAmount)));
                    if (checkNeedToUpdate?.tempDiscountReset) {
                      setDiscount(0);
                      setDiscountCodeType("percentage");
                    }
                    setDeposit(checkNeedToUpdate?.tempDeposit);
                    setIsEditTreatmentPriceOpen(false)
                  }
                }} />
              </div>
            </div>
          }
        />
      }
      {
        //Form consent or medical List Modal
        <OffCanvasModal
          isOpen={openConsentModal}
          className={"custom-sidebar-width-30 p-0  CustomeConsentFormListCanvas"}
          placement={"end"}
          isOffCanvasHeader={false}
          onHide={() => setOpenConsentModal(false)}
          body={
            <ConsentFormList clientId={selectedClientData?.clentId} clientName={selectedClientData?.clientName} setMedicalFormSave={setMedicalForm} medicalFormSave={medicalForm} selectedConsentForm={selectedConsentFormData} setSelectedConsentForm={setSelectedConsentFormData} setIsClose={setOpenConsentModal} bookingId={bookingId} />
          }
        />

      }
      {
        //Form consent or medical List Modal
        <OffCanvasModal
          isOpen={openAfterCareModal}
          className={"custom-sidebar-width-30 p-0 CustomeAfterCareDataCanvas"}
          placement={"end"}
          isOffCanvasHeader={false}
          onHide={() => setOpenAfterCareModal(false)}
          body={
            <AfterCareList selectedAfterCareData={selectedAfterCareData} setSelectedAfterCareData={setSelectedAfterCareData} setIsClose={setOpenAfterCareModal} />
          }
        />

      }
      {
        displayToastMessage
      }
      <CustomModal
        modalOpen={isOpenCalendar}
        setModalOpen={setIsOpenCalendar}
        className={'CustomeCalenderModelDesign'}
        modalBody={
          getCalendarAppointmentCountQueryData?.loading || clinicsAvailableDateData?.loading ? <CustomCalendarSkeleton /> : <CustomCalendar
            setShowSelectedDate={setShowSelectedDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}

            showSelectedDate={showSelectedDate}
            setCalendarStartDate={setCalendarStartDate}
            setIsOpenCalendar={setIsOpenCalendar}
            dateData={
              (selectedLocationData?.clinicId == undefined) ? generateCustomDates(startDate) :
                clinicsAvailableDateData?.data?.getClinicAvailableDates?.data?.items
            }
            appointmentDatesCount={
              getCalendarAppointmentCountQueryData?.data?.getCalendarAppointmentCount?.data?.appointmentCountData
            }
            ignoreSelectedDate={true}
            isPassedDateEnabled={true}
            // fixedPassedDate={bookingId && moment(appointmentDetails?.appointmentDate, "ddd, DD MMMM").isBefore(moment()) ? moment(appointmentDetails?.appointmentDate, "ddd, DD MMMM") : false}
            onChangeCallback={(date) => {
              //convert date in to moment
              let dateMoment = moment(date, "ddd DD MMM YYYY")
              let selectedDate = moment(showSelectedDate, "ddd DD MMM YYYY")

              if (!dateMoment.isSame(selectedDate)) {
                setToggles({ ...toggle, textReminder: false })
                setTimeRange({ start: "", end: "" })
              }
            }
            }
            isCalendarPreviousDateEnable={true}
            isMonthlyDailyViewCalender={selectedLocationData?.clinicId ? false : true}
          />
        }
      />

      <OffCanvasModal
        isOffCanvasHeader={false}
        isOpen={isClientOffCanvas}
        placement={"end"}
        className={"custom-sidebar-width-30"}
        onHide={() => setIsClientOffCanvas(false)}
        body={<NewClientList setIsClose={setIsClientOffCanvas} setSelectedClient={setSelectedClientData} selectedClient={selectedClientData} setMedicalFormSave={setMedicalForm}
          medicalFormSave={medicalForm} clearDependentStates={() => {
            if (dailyViewData) {

            }
            else {
              clearDependentStates(true)
            }
          }
          }
        />}
      />
      <OffCanvasModal
        isOffCanvasHeader={false}
        isOpen={isLocationOffCanvas}
        placement={"end"}
        className={"custom-sidebar-width-30"}
        onHide={() => setIsLocationOffCanvas(false)}
        body={<SearchLocation setIsClose={setIsLocationOffCanvas} selectedLocationSave={selectedLocationData} setSelectedLocationSave={setSelectedLocationData}
          clearDependentStates={() => {
            clearDependentStates()
            dispatch({
              type: SAVE_CALENDAR_DAILYVIEW_DATA,
              payload: null,
            });
          }}
        />}
      />

      {/* SMS SUBSCRIPTION MODAL */}
      <CustomModal
        modalOpen={isSmsSubscriptionOpen}
        setModalOpen={setIsSmsSubscriptionOpen}
        type={"common-width"}
        modaltitleCSS={"discount-title"}
        className={'SMSSucriptionModal'}
        modalBody={
          <SMSSucriptionModal howItWorksData={
            {
              clientName: selectedClientData.clientData ? selectedClientData?.clientData : "John Doe",
              date: showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM").format("DD-MM-YYYY") : moment().format("DD-MM-YYYY"),
              time: timeRange?.start ? timeRange.start : moment().format("hh:mm A"),
            }}
            setIsSmsSubscriptionOpen={setIsSmsSubscriptionOpen} />
        }
      />

      {/* OFFER PAY MONTHLY LEARN MODAL */}
      <CustomModal
        modalOpen={isOfferPayMonthlyLearnOpen}
        setModalOpen={setIsOfferPayMonthlyLearnOpen}
        type={"no-header"}
        modaltitleCSS={"discount-title"}
        className="CustomeThankyouModel"
        modalBody={
          <>
            <div className="offer-pay-monthly-learn-modal-main-div">
              <div className="info">
                <h1>
                  i
                </h1>
              </div>
              <p>
                Thank you for your interest in offering the 'Buy Now, Pay Later'. Yours is currently not active. However your request is currently under review by our team. We will reach out to you as soon as the decision is made, so you can begin monetising this amazing service.
              </p>
              <StepButton label={"OK"} blue={true} className={"w-50 m-auto"}
                onClick={() => setIsOfferPayMonthlyLearnOpen(false)} />
            </div>
          </>
        }
      />

      {/* DELETE MODAL */}
      <CustomModal
        type={"common-width"}
        className={"ConfirmationDeleteModal"}
        modalBody={
          <>
            <div className="delete-modal-body">

              <h1>Are you sure?</h1>
              <p>
                {
                  treatmentIndex ? "Are you sure you want to remove this treatment from list?" :
                    consentIndex ? "Are you sure you want to remove this consent form from list?" :
                      afterCareIndex ? "Are you sure you want to remove this aftercare form from list?" : ""

                }
              </p>
              {/* <p>Are you sure you want to delete this form?</p> */}
              <div className="BtnWrapper">
                <div className="delete-button-modal">
                  <StepButton red={true} label={"Delete"} onClick={() => { handleConfirmDelete() }} />
                </div>
                <div className="cancle-button-modal">
                  <StepButton gray={true} onClick={() => {
                    setIsDeleteOpen(false)
                    setAfterCareIndex(null)
                    setConsentIndex(null)
                    setTreatmentIndex(null)
                  }} label={"Cancel"} />
                </div>
              </div>
            </div>
          </>
        }
        modalOpen={isDeleteOpen}
        setModalOpen={setIsDeleteOpen}
        setIsOpenMobileCalendar={setIsDeleteOpen}
      />

      {/* TIME PICKER */}
      <CustomModal
        modalOpen={isOpenTimePicker}
        setModalOpen={closeTimePickerDialog}
        type={"common-width"}
        modaltitle={"Choose time"}
        className={'ChoosetimeModel'}

        modalBody={
          <>
            <div className="TimeGrid" id={"time-picker"}>
              <div className="TimeGridItem">
                <div className="errorWrapper">
                  <CustomDropdown
                    labelName={"Start Time"}
                    dropdownId={"startTime"}
                    placeholdername={"Select Time"}
                    optionsArr={TIME()}
                    defaultValue={start}
                    handleSelectedValue={handleTimeSlotChange}
                  />
                  <p className="text-danger">{errorObj.startTime}</p>
                </div>
              </div>
              <div className="TimeGridItem">
                <div className="errorWrapper">
                  <CustomDropdown
                    labelName={"End Time"}
                    defaultValue={end}
                    dropdownId={"endTime"}
                    placeholdername={"Select Time"}
                    optionsArr={TIME()}
                    handleSelectedValue={handleTimeSlotChange}
                  />
                  <p className="text-danger">{errorObj.endTime}</p>
                  {console.log("error obj", timeRange)}
                </div>
              </div>
            </div>
            <div className="btnWrapper">
              <StepButton label={"Save"} blue={true} onClick={() => onSubmit()} disabled={start.length == ""} />
            </div>
          </>
        }
      />

      {/* APPOINTMENT OVERLAPPING CONFIRMATION MODAL */}
      <CustomModal
        modalOpen={isAppointmentOverlappingOpen}
        setModalOpen={setIsAppointmentOverlappingOpen}
        type={"common-width"}
        className={'customeAvailableModel'}
        modalBody={
          <>
            <div className="share-booking-link-all-clients-confimration">

              <p>
                {overLappingMessage}
              </p>
              <div className="btnWrapper">
                <StepButton blue={true} label={"Yes"} onClick={() => {
                  setIsAppointmentOverlappingOpen(false)
                  initCallCreateAppointmentFunc()
                }} />
                <StepButton gray={true} onClick={() => {
                  setIsAppointmentOverlappingOpen(false)
                }} label={"No"} />
              </div>
            </div>
          </>
        }
      />

      {/* CHOOSE START TIME MODAL */}
      <CustomModal
        modalOpen={isStartTimeOpen}
        setModalOpen={setIsStartTimeOpen}
        type={"common-width"}
        modaltitle={timing === "start" ? "Choose start time" : "Choose end time"}
        modalCloseCallback={closeModalHandler}
        className={'ChooseDatetimeModel'}

        modalBody={
          <>
            <div className="delete-modal-body mt-2">

              {/* <h4>
                Choose start time
              </h4> */}
              <div className="w-100">
                <div className='choose-start-time-container'>
                  {Object.keys(selectedLocationData)?.length > 0 && clinicsAvailableDateData?.data?.getClinicAvailableDates?.data?.items?.includes(moment(showSelectedDate, "dddd, Do MMMM").format("YYYY-MM-DD")) && <div>
                    {isOutsideAvaibility ? <h6
                      onClick={() => {
                        setIsOutsideAvaibility(false)
                        console.log("click inside", availableTimeslots)
                        dispatch({
                          type: SET_TIME_SLOTS_FOR_CREATE_APPOINTMENT,
                          payload: timeslots
                        })
                      }}
                    >Select from clinic availability</h6> : <h6 onClick={() => {
                      setIsOutsideAvaibility(true)
                      console.log("click outside", availableAndOutsideAvaibilityTimeSlots)
                      dispatch({
                        type: SET_TIME_SLOTS_FOR_CREATE_APPOINTMENT,
                        payload: availableAndOutsideAvaibilityTimeSlots
                      })
                    }}>Select outside availability</h6>}
                  </div>}
                  <div className="start-time-wrapper">
                    {
                      availableTimeSlotData?.loading ?
                        [...Array(10)].map((item, index) => <SkeletonLoader showCircle={false} type={"time"} />)
                        :
                        availableTimeslots.map((timeSlot, index) => {
                          console.log("check", bookingId && appointmentDetails?.appointmentStartTime == timeSlot.time_slot, tempShowSelectedTime === timeSlot.time_slot, "slot", timeSlot.time_slot)
                          return <div className={`start-time-slot-content ${tempShowSelectedTime === timeSlot.time_slot ? 'selected' : ''}`} key={index}>

                            <CustomRadioButton
                              text={timeSlot.time_slot} timeSlot={true} onChange={(e) => {
                                setTempShowSelectedTime(e.target.id)
                              }} id={timeSlot.time_slot} name="time" value={timeSlot.time_slot} checked={tempShowSelectedTime === timeSlot.time_slot}

                              // disabled={timeSlot.isBooked}
                              // disabled={bookingId ? (appointmentDetails?.appointmentStartTime === timeSlot.time_slot ? false : timeSlot.isBooked) : timeSlot.isBooked}
                              isblacked={true}
                              preselect={true}
                            />
                          </div>
                        })
                    }
                  </div>
                  <div className="sticky-footer-calender">
                    <StepButton label={"Save"} blue={true} onClick={() => {
                      // handleTimeChangeForNewTimePicker(tempShowSelectedTime)
                      let updatedTimeRange = handleTimeChangeForNewTimePicker(tempShowSelectedTime);
                      if (!updatedTimeRange) {
                        showToast("error", "End time should be greater than the start time");
                        return;
                      }
                      setTimeRange(updatedTimeRange)
                      // let tempObj = {start : }
                      // setTimeRange({
                      //   start: moment(tempShowSelectedTime, 'HH:mm').format('HH:mm'),
                      //   end: moment(tempShowSelectedTime, 'HH:mm').add(30, 'minutes').format('HH:mm'),
                      // })
                      setShowSelectedTime(tempShowSelectedTime)
                      setIsStartTimeOpen(false)
                      setIsOutsideAvaibility(false)
                    }} />
                  </div>
                </div>

              </div>
            </div>
          </>
        }
      />

      {/* LOCATION TREATMENT ADDITION MODAL */}
      <CustomModal
        modalOpen={locationTreatementModalState}
        setModalOpen={setLocationTreatmentModalState}
        type={"common-width"}
        className={'customeAvailableModel'}
        modalBody={
          <>
            {/* <div className="share-booking-link-all-clients-confimration"> */}
            <div className="share-booking-link-all-clients-confimration">
              <p>
                There are currently no {selectedForAdd == 1 ? "location" : "treatment"}  added. Would you like to add one?
              </p>
              <div className="btnWrapper">
                <StepButton label={"Yes"} onClick={() => {
                  if (selectedForAdd == 1)
                    window.location.href = webBaseUrl + `/clinic/create?redirect-url=${frontendURL + '/new-appointment'}`
                  else if (selectedForAdd == 2)
                    window.location.href = webBaseUrl + `/treatment/create?redirect-url=${frontendURL + '/new-appointment'}`
                  setSelectedForAdd(0)
                  setLocationTreatmentModalState(false)
                }} blue={true} />

                <StepButton gray={true} label={"No"} onClick={() => {
                  setSelectedForAdd(0)
                  setLocationTreatmentModalState(false)
                }} />
              </div>
            </div>
          </>
        }
      />

      {/* popup when creating appointment for unavailable dates */}
      <CustomModal
        modalOpen={isAvailableForWorking}
        setModalOpen={setIsAvailableForWorking}
        type={"common-width"}
        className={'customeAvailableModel'}

        modalBody={
          <>
            <div className="share-booking-link-all-clients-confimration">
              <p>
                You are not working on selected date, are you sure you want to create this appointment?
              </p>
              <div className="btnWrapper">

                <StepButton label={"Yes"} onClick={() => {
                  // handleAllclientsConfirmation()
                  handleApiCallForAppointment()
                }} blue={true} />
                <StepButton gray={true} label={"No"} onClick={() => setIsAvailableForWorking(false)} />
              </div>
            </div>

          </>
        }
      />

      {/* STRIPE CONNECTION MODAL */}
      <CustomModal
        modalOpen={stripeConnectionModalState}
        setModalOpen={setstripeConnectionModalState}
        type={"common-width"}
        className={'customeAvailableModel'}
        modalBody={
          <>
            <div className="share-booking-link-all-clients-confimration">
              <p>
                Please connect your stripe in order to enable the deposit feature.
              </p>
              <div className="btnWrapper">
                <StepButton label={"Okay"}
                  isLoading={stripeOnboardingQueryData?.loading}
                  onClick={() => {
                    //API CALL

                    initStripeOnboardingData({},
                      (response) => {
                        window.location.href = response?.data?.account_link
                      },
                      (error) => {
                        showToast(error.message, "", false, undefined, undefined, { toastId: 'treatment-price-select-error-toast' })
                        setstripeConnectionModalState(false)
                      })

                    // if (selectedForAdd == 1)
                    //   window.location.href = webBaseUrl + `/clinic/create?redirect-url=${frontendURL + '/new-appointment'}`
                    // else if (selectedForAdd == 2)
                    //   window.location.href = webBaseUrl + `/treatment/create?redirect-url=${frontendURL + '/new-appointment'}`
                    // setSelectedForAdd(0)
                    // setLocationTreatmentModalState(false)
                  }} blue={true} />
                <StepButton gray={true} label={"Cancel"} onClick={() => {
                  setstripeConnectionModalState(false)
                }} />
              </div>
            </div>

          </>
        }
      />

      {/* CLIENT DIRECTORY MODAL */}
      <CustomModal
        modalOpen={isPasswordOpen}
        setModalOpen={setIsPasswordOpen}
        type={"common-width"}
        modaltitle={"Please enter password"}
        modaltitleCSS={"discount-title"}
        modalBody={
          <>
            <ClientPasswordPopup redirectCallback={() => navigate(getEditClientRoute(selectedClientData?.clentId), { state: { fromAppointment: true } })} setIsPasswordOpen={setIsPasswordOpen} />
          </>
        }
      />
    </>
  );
};

export default NewAppointment;
