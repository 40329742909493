import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_CLINIC_LOCATIONS_CONST } from "../../constants";


// ========================GET CLINIC LOCATIONS========================
const GET_CLINIC_LOCATIONS_QUERY = gql`
query GetClinicLocations($page: Int, $limit: Int, $search: String, $requestFrom: String, $itemIds: [Int]) {
    getClinicLocations(page: $page, limit: $limit, search: $search, request_from: $requestFrom, itemIds: $itemIds) {
      success
      message
      data {
        companyName
        isFinanceAvailable
        isStripeSetup
        isRyftSetup
        total
        items {
          clinicId
          clinicName
          imageUrl
          completeAddress
        }
      }
    }
  }
`;

export const GetClinicLocationsAction = () => {
    const dispatch = useDispatch();
    const [
        GetClinicLocationsResponseFunction,
        getClinicLocationsResponseData,
    ] = useLazyQuery(GET_CLINIC_LOCATIONS_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getClinicLocations: responseData }) => {
            if (responseData.success) {
                dispatch({
                    type: GET_CLINIC_LOCATIONS_CONST,
                    payload: responseData
                })
                // toast.success(responseData?.message)
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getClinicLocationsQueryData = getClinicLocationsResponseData; //variable

    const initGetClinicLocationsData = (data) => {
        GetClinicLocationsResponseFunction({
            variables: data,
        });
    };

    return { getClinicLocationsQueryData, initGetClinicLocationsData };
};

// // // ========================SUBMIT STUDENT REVIEW========================

// const SUBMIT_STUDENT_REVIEW_MUTATION = gql`
// mutation SubmitStudentReview($courseId: Int!, $rating: Int!, $review: String!, $reviewId: Int) {
//     submitStudentReview(courseId: $courseId, rating: $rating, review: $review, reviewId: $reviewId) {
//       success
//       message
//     }
//   }
// `;


// export const SubmitStudentReviewAction = () => {
//     const dispatch = useDispatch();
//     const [
//         SubmitStudentReviewResponseFunction,
//         submitStudentReviewResponseData,
//     ] = useMutation(SUBMIT_STUDENT_REVIEW_MUTATION)

//     //this will be called from components
//     const submitStudentReviewQueryData = submitStudentReviewResponseData; //variable

//     const initSubmitStudentReviewData = (data, successCallback, errorCallback) => {
//         SubmitStudentReviewResponseFunction({
//             variables: data,
//         }).then(({ data: { submitStudentReview: responseData } }) => {
//             if (responseData.success) {
//                 if (successCallback)
//                     successCallback()
//                 toast.success(responseData.message)
//             }
//             else {
//                 if (errorCallback)
//                     errorCallback()
//                 toast.error(responseData.message)
//             }
//         }).catch(() => {
//             if (errorCallback)
//                 errorCallback()
//             toast.error("something went wrong!!!")
//         })
//     }

//     return { submitStudentReviewQueryData, initSubmitStudentReviewData };
// };
