import { SET_PAY_LATER_DATA,GET_PAYLATER_STATUS_DATA,GET_PAYLATER_STATUS_DATA_SUCCESS } from "../../constants/payLater/payLaterConts";
import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

export function setPaylaterData(data) {
    console.log("paylater action",data)
    return {
        type: SET_PAY_LATER_DATA,
        payload: data

    }
}

export function getPaylaterStatus(data) {

    return {
        type: GET_PAYLATER_STATUS_DATA,
        payload: data
    }
}

export function getPaylaterStatusSuccess(data) {
    return {
        type: GET_PAYLATER_STATUS_DATA_SUCCESS,
        payload: data
    }
}

const SEND_FINANCE_REQUEST = gql`
mutation SendFinanceRequestEmail($clinicId: Int!, $requestMail: Boolean!, $cfrId: Int) {
  sendFinanceRequestEmail(clinic_id: $clinicId, request_mail: $requestMail, cfr_id: $cfrId) {
    success
    message
  }
}
`;

export const SendFinanceRequest = () => {

  
  const [sendFinanceRequest, sendFinanceResponseData] =
    useMutation(SEND_FINANCE_REQUEST);
  // const dispatch = useDispatch();
  const sendFinanceMutationData = sendFinanceResponseData;

  const initiatesendFinance = (
    data,
    successCallback,
    errorCallback
  ) => {
    sendFinanceRequest({
      variables: data,
    })
      .then(({ data: { sendFinanceRequestEmail: responseData } }) => {
        if (responseData) {
          // alert("success")
          successCallback(responseData);
        } else {
          // alert("1234")
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        // alert("error")
        errorCallback(error);
      });
  };
  return { initiatesendFinance, sendFinanceMutationData };
};
