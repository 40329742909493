import { BUSINESS_CONTENT_DATA, BUSINESS_HOURS_DATA, CHECK_WEBSITE_DOMAIN_RESPONSE, CLEAR_GENERATED_CONTENT, CLEAR_LOGO_IMAGE, GENRERATE_TERMS_AND_CONDITIONS, GET_WEBSITE_DETAILS, GET_WEBSITE_IMAGE, GET_WEBSITE_IMAGE_COVER_PHOTO, GET_WEBSITE_IMAGE_LOGO, SAVE_WEBSITE_DETAILS, TEAM_MEMBERS_DATA, TEAM_MEMBER_EDIT_DATA } from "../../constants";




const initialState = {
  saveWebsiteDataStatus: false,
  checkWebsiteDomainResponseData: null,
  checkDomainApiCalled: false,
  websitePrefilledData: null,
  termsAndConditionsAutoData: null,
  businessHoursData: null,
  teamMembersData: null,
  teamMembersEditData: null,
  businessContentHeadline: null,
  businessContentDescription: null,
  teamMemberDescription: null,
  websiteLogoImage: null,
  websiteCoverImage: null,
  imageType: null,
};

export default function WebsiteDetailsReducer(state = initialState, action) {

  switch (action.type) {

    case SAVE_WEBSITE_DETAILS:
      return {
        ...state,
        saveWebsiteDataStatus: action.payload.success,
      };
      break;

    case CHECK_WEBSITE_DOMAIN_RESPONSE:
      return {
        ...state,
        checkWebsiteDomainResponseData: action.payload.success,
      };
      break;

    case GET_WEBSITE_DETAILS:
      return {
        ...state,
        websitePrefilledData: action.payload.data,
      };
      break;

    case GENRERATE_TERMS_AND_CONDITIONS:
      console.log("generate terms", action.payload)
      return {
        ...state,
        termsAndConditionsAutoData: action.payload.data.plainText,
      };
      break;

    case BUSINESS_HOURS_DATA:
      return {
        ...state,
        businessHoursData: action.payload.data,
      };
      break;

    case TEAM_MEMBERS_DATA:
      return {
        ...state,
        teamMembersData: action.payload.data.items,
      };
      break;

    case TEAM_MEMBER_EDIT_DATA:
      return {
        ...state,
        teamMembersEditData: action.payload.data,
      };
      break;

    case BUSINESS_CONTENT_DATA:
      let type = action.payload.data.type;
      return {
        ...state,
        businessContentHeadline: type == 1 ? action.payload.data.content : state.businessContentHeadline,
        businessContentDescription: type == 2 ? action.payload.data.content : state.businessContentDescription,
        teamMemberDescription: type == 3 ? action.payload.data.content : state.teamMemberDescription,

      };
      break;

    case GET_WEBSITE_IMAGE_LOGO:
      console.log("in image step reducer", action.payload)
      return {
        ...state,
        websiteLogoImage: action.payload.data.image.image_url,
        imageType: action.payload.data.image.type,
      };
      break;

    case GET_WEBSITE_IMAGE_COVER_PHOTO:
      console.log("in image step reducer", action.payload)
      return {
        ...state,
        websiteCoverImage: action.payload.data.image.image_url,
        imageType: action.payload.data.image.type,
      };
      break;
    // case CLEAR_LOGO_IMAGE:
    //   return {
    //     ...state,
    //     websiteLogoImage : null,
    //     imageType : null,
    //   };

    case CLEAR_GENERATED_CONTENT:
      return {
        ...state,
        businessContentData: null,
        teamMembersEditData: {
          pwtm_full_name: "",
          pwtm_qualification: "",
          pwtm_job_role: "",
          pwtm_description: "",
          image: "",
        },
      }


    default:
      return state;
  }



}
