import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

// ========================GET APPOINTMENT DETAIL FOR REBOOK========================
const GET_APPOINTMENT_DETAIL_FOR_REBOOK_QUERY = gql`
query GetAppointmentDetailForRebook($appointmentId: Int, $allDetails: Boolean) {
    getAppointmentDetailForRebook(appointmentId: $appointmentId, allDetails: $allDetails) {
      success
      message
      data {
        clinicId
        clinicName
        clinicAddress
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        treatmentDetails {
          treatmentId
          treatmentName
          treatmentPrice
          treatmentDuration
          treatmentImage
        }
        subTotal
        discountCodeType
        discountAmount
        depositAmount
        totalAmount
        teamMembers {
          teamMemberId
          teamMemberName
          teamMemberImage
          teamMemberTitles
        }
        canRequestDeposit
      }
    }
  }
`;

export const GetAppointmentDetailForRebookAction = () => {
  const dispatch = useDispatch();
  const [
    GetAppointmentDetailForRebookResponseFunction,
    getAppointmentDetailForRebookResponseData,
  ] = useLazyQuery(GET_APPOINTMENT_DETAIL_FOR_REBOOK_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getAppointmentDetailForRebook: responseData }) => {
      if (responseData.success) {
        // dispatch({
        //     type: GET_RATING_REVIEWS_CONST,
        //     payload: responseData
        // })
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });

  //this will be called from components
  const getAppointmentDetailForRebookQueryData = getAppointmentDetailForRebookResponseData; //variable

  const initGetAppointmentDetailForRebookData = (data, successCallBack, errorCallback) => {
    GetAppointmentDetailForRebookResponseFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.getAppointmentDetailForRebook?.success)
        successCallBack(res.data.getAppointmentDetailForRebook.data)
    });
  };

  return { getAppointmentDetailForRebookQueryData, initGetAppointmentDetailForRebookData };
};