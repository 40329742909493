import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const ADD_IDENTITY_FORWARD_CONSENT_FORM_MUTATION = gql`
mutation AddClientId($cfrId: Int, $clientIDbase64: String, $token: String) {
    addClientId(cfrId: $cfrId, clientIDbase64: $clientIDbase64, token: $token) {
      success
      message
      data {
        docUrl
      }
    }
  }
`;

export const AddIdentityForwardConsentFormMutation = () => {
    const [addIdentityForwardConsentFormRequest, addIdentityForwardConsentFormResponseData] =
        useMutation(ADD_IDENTITY_FORWARD_CONSENT_FORM_MUTATION);
    const dispatch = useDispatch();
    const addIdentityForwardConsentFormData = addIdentityForwardConsentFormResponseData;

    const initiateAddIdentityForwardConsentForm = (
        data,
        successCallback,
        errorCallback
    ) => {
        addIdentityForwardConsentFormRequest({
            variables: data,
        })
            .then(({ data: { addClientId: responseData } }) => {
                if (responseData) {
                    successCallback(responseData);
                } else {
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                errorCallback(error);
            });
    };
    return { initiateAddIdentityForwardConsentForm, addIdentityForwardConsentFormData };
};
