import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const RESEND_BOOKING_DETAILS_MUTATION = gql`
mutation ResendBookingDetails($appointmentId: Int, $consentFormRecordId: Int) {
    resendBookingDetails(appointmentId: $appointmentId, consentFormRecordId: $consentFormRecordId) {
      success
      message
    }
  }
`;

export const ResendBookingDetailsMutation = () => {
  const [resendBookingDetailsRequest, resendBookingDetailsResponseData] =
    useMutation(RESEND_BOOKING_DETAILS_MUTATION);
  const dispatch = useDispatch();
  const resendBookingDetailsMutationData = resendBookingDetailsResponseData;

  const initiateResendBookingDetailsData= (
    data,
    successCallback,
    errorCallback
  ) => {
   resendBookingDetailsRequest({
      variables: data,
    })
      .then(({ data: { resendBookingDetails: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateResendBookingDetailsData, resendBookingDetailsMutationData };
};
