import { gql, useMutation } from "@apollo/client";

const IMPORT_CLIENT_WITH_CSV_MUTATION = gql`
mutation ImportClientWithCsv($clientFile: Upload!) {
  importClientWithCsv(clientFile: $clientFile) {
    success
    message
    data {
      totalSuccess
      totalFailed
    }
  }
}
`;

export const ImportClientWithCsv = () => {
    const [importClientWithCsvMutation, importClientWithCsvResponseData] =
      useMutation(IMPORT_CLIENT_WITH_CSV_MUTATION);
  
    const initiateImportClientWithCsv = (variables, successCallback, errorCallback) => {
      importClientWithCsvMutation({ variables })
        .then(({ data }) => {
          if (data.importClientWithCsv.success) {
            successCallback(data.importClientWithCsv);
          } else {
            errorCallback(data.importClientWithCsv);
          }
        })
        .catch((error) => {
          // Handle error
          errorCallback(error);
        });
    };
  
    return { initiateImportClientWithCsv, importClientWithCsvResponseData };
};
