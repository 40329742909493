import { gql, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

const DELETE_TRAINING_CERTIFICATE_DOCUMENT_MUTATION = gql`
  mutation DeleteTrainingCertificate {
    deleteTrainingCertificate {
      success
      message
    }
  }
`;

export const DeleteTrainingCertificate = () => {
  // const dispatch = useDispatch();
  const [
    DeleteTrainingCertificateResponseFunction,
    deleteTrainingCertificateResponseData,
  ] = useMutation(DELETE_TRAINING_CERTIFICATE_DOCUMENT_MUTATION);

  //this will be called from components
  const deleteTrainingCertificateQueryData =
    deleteTrainingCertificateResponseData; //variable

  const initDeleteTrainingCertificateData = (
    data,
    successCallback,
    errorCallback
  ) => {
    DeleteTrainingCertificateResponseFunction({
      variables: data,
    })
      // .then(({ data: { DeleteTrainingCertificate: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        if (responseData?.data?.deleteTrainingCertificate?.success) {
          // dispatch(...)
          successCallback(responseData);
          // toast.success(responseData.message)
        } else {
          errorCallback(responseData);
          // toast.error(responseData.message)
        }
      })
      .catch((err) => {
        // errorCallback(err);
        console.log('typeeee:', err);
        toast.error('something went wrong!!!');
      });
  };

  return {
    deleteTrainingCertificateQueryData,
    initDeleteTrainingCertificateData,
  };
};
