import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import styled from "@emotion/styled";
import './calender.scss'
import { webBaseUrl } from "../../config";
import { useNavigate } from "react-router";
import { getCalendarRoute } from "../../Routes";


function Calendar({ eventsArray }) {
  const navigate = useNavigate();

console.log('eventsArray', eventsArray);
  const events = eventsArray?.map((item, index) => {
    return ({
      id: index,
      title: item?.cld_rc_title,
      start: item?.calender_date,
      color: item?.color
    })
  }
  )


  // Add more events for the current and next week


  // Calculate the start date for the current week
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Start of the day
  const daysUntilNextSunday = 7 - today.getDay(); // Days until the next Sunday
  const startDate = new Date(today);
  (startDate.setDate(startDate.getDate() - (startDate.getDay() || 7))) // Start of the current week

  // Calculate the end date for two weeks from now
  const twoWeeksLater = new Date(today);
  twoWeeksLater.setDate(startDate.getDate() + 14); // Two weeks later


  return (

    <div className="calendar-container">
      <div className="d-flex">
        <p className="me-3">{today.toLocaleString('default', { month: 'long' })}</p> <span>{today.getFullYear()}</span>
      </div>
      <div className="calendar-content" onClick={() => {
        // window.location.href = "https://staging.facesconsent.com/calendar/records"
        // window.location.href = `${webBaseUrl}/calendar/records`
        navigate(getCalendarRoute())
      }}>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          headerToolbar={{
            left: "",
            right: ""
          }}
          initialDate={startDate.toISOString()}
          validRange={{
            start: startDate.toISOString(),
            end: twoWeeksLater.toISOString(),
          }}
          events={events}
          dayMaxEventRows={3} // Limit the number of events per day to 4
          eventLimit={false}
          moreLinkClick={(event) => { event.jsEvent.stopPropagation(); }}
          eventContent={(eventInfo) => {
            return (
              <div style={{ background: events[eventInfo?.event?.id]?.color, padding: '5px', borderRadius: '5px', width: '100%',textOverflow:" ellipsis", whiteSpace:" nowrap", overflow: "hidden",fontSize:"12px",color:"#fff", fontWeight:"600" }}>
                {/* {eventInfo.timeText} {eventInfo.event.title} */}
                {eventInfo.event.title}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}

export default Calendar;