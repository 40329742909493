import React, { useState } from "react";
import "./Marketing.scss";
import BusinessNavigation from "../../../common/businessNavigation/BusinessNavigation";
import Sms from "./Sms/Sms";
import Emails from "./Emails/Emails";
import Discountcodes from "./Discountcodes/Discountcodes";
import { ReactComponent as MessageIcon } from "../../../../images/sprint6/messageIcon.svg";
import { ReactComponent as EmailIcon } from "../../../../images/sprint6/email.svg";
import { ReactComponent as RocketIcon } from "../../../../images/sprint6/rocket.svg";
import { ReactComponent as DiscountIcon } from "../../../../images/sprint6/Discount.svg";
import { ReactComponent as MarketplaceIcon } from "../../../../images/sprint6/Marketplace.svg";
import { ReactComponent as RightArrowIcon } from "../../../../images/sprint6/rightIcon.svg";
import { ReactComponent as AppleIcon } from "../../../../images/sprint6/apple.svg";
import { ReactComponent as FacebookIcon } from "../../../../images/sprint6/facebook.svg";
import BusinessSettingHeader from "../../../common/businessSettingHeader/BusinessSettingHeader";
import BusinessSidebar from "../../../sidebar/BusinessSidebar";
import BusinessProfileSetting from "../BusinessProfileSetting";
import { useNavigate } from "react-router";
import { indexPage } from "../../../../Routes";
import { ReactComponent as PhoneIcon } from "../../../../images/sprint6/phone.svg";
import { webBaseUrl } from "../../../../config";


const Marketing = ({ setCurrentPage, currentPage }) => {
  const [selectedTab, setSelectedTab] = useState("SMS");
  const [step, setStep] = useState(0);
  const navigate = useNavigate()
  const renderTabContent = () => {
    if (step == 1) {
      return <Sms setParentStep={setStep} />;
    }
    if (step == 2) {
      return <Emails setParentStep={setStep} />;
    }
    if (step == 3) {
      return <Discountcodes setParentStep={setStep} />;
    }
  };

  return (

    <div>
      <div className="MainBusinessProfile">
        <BusinessSettingHeader
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
        <div className="Business-Profile-Main-Wrapper">
          <BusinessSidebar
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
          {/* <BusinessProfileSetting /> */}
          <div className="MainMarketingPages">
            {step > 0 ? (
              renderTabContent()
            ) : (
              <div className="Main684WidthSet">
                <BusinessNavigation
                  isColumn={true}
                  title={"Marketing"}
                  desc={"Marketing and branding all in one place!"}
                  onBackClick={() => { navigate(indexPage) }}
                />
                <div className="linkGrid">
                  {/* setStep(1); */}
                  <div
                    className="linkItem"
                    onClick={() => {
                      window.location.href = webBaseUrl + "/client/promotional-message"
                    }}
                  >
                    <div className="linkImg">
                      <PhoneIcon />
                    </div>
                    <div className="linkContent">
                      <h3>SMS</h3>
                      <p>Send a reminder or promo message.</p>
                    </div>
                    <div className="RightIcon">
                      <RightArrowIcon />
                    </div>
                  </div>

                  <div
                    className="linkItem"
                    onClick={() => {
                      // setStep(2);

                      window.location.href = webBaseUrl + "/client/promotional-email"
                    }}
                  >
                    <div className="linkImg">
                      <EmailIcon />
                    </div>
                    <div className="linkContent">
                      <h3>Emails</h3>
                      <p>Send an email direct anytime!</p>
                    </div>
                    <div className="RightIcon">
                      <RightArrowIcon />
                    </div>
                  </div>

                  <div className="linkItem"
                    onClick={() => {
                      window.location.href = webBaseUrl + "/v1/social-media-marketing"
                    }}

                  >
                    <div className="linkImg"

                    >
                      <RocketIcon />
                    </div>
                    <div className="linkContent">
                      <h3>Social media managment</h3>
                      <p>Content creation and posting.</p>
                    </div>
                    <div className="RightIcon">
                      <RightArrowIcon />
                    </div>
                  </div>

                  <div
                    className="linkItem"
                    onClick={() => {
                      // setStep(3);
                      window.location.href = webBaseUrl + "/user/discount-code"


                    }}
                  >
                    <div className="linkImg">
                      <DiscountIcon />
                    </div>
                    <div className="linkContent">
                      <h3>Discount codes</h3>
                      <p>Create a discount code for clients.</p>
                    </div>
                    <div className="RightIcon">
                      <RightArrowIcon />
                    </div>
                  </div>

                  {/* <div className="linkItem">
              <div className="linkImg">
                <MarketplaceIcon />
              </div>
              <div className="linkContent">
                <h3>Marketplace</h3>
                <p>Grow your online profile.</p>
              </div>
              <div className="RightIcon">
                <RightArrowIcon />
              </div>
            </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketing;
