import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../../images/back_arrow.svg";
import BannerHeader from "../common/BannerHeader";
import "./team-member.scss";
import Skeleton from "react-loading-skeleton";
import SkeletonLoader from "../common/Skeleton/SkeletonLoader";

const TeamMemberWeb = ({
  setSubStep,
  showSelectedTeamMember,
  setTeamMemberSelectFun,
  text,
  teamMemberData,
  setSelectedTreatmentId,
  selectedTreatment,
  setSelectedTreatment,
  setSelectedTeamMember,
  selectedTeamMember,
  data,
}) => {
  function stringAvatar(name) {
    return {
      children: name,
    };
  }

  const [name, setName] = useState({
    firstName: "",
    lastName: "",
    id: 0,
  });
  const [radioChecked, setRadioChecked] = useState(false);
  const [loader, setLoader] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // useEffect(() => {
  //   setIsLoading(true)
  //   setTimeout(() => {
  //     setIsLoading(false)
  //   }, 2000)
  // }, [])

  const handleSelectTeamMember = () => {

    setLoader(true)
    setTimeout(() => {
      setLoader(false)
      setSelectedTeamMember(name)

    }, 2000)
  }



  // ...

  const treatmentIds = [];

  selectedTreatment.forEach((treatments) => {
    // Check if the "id" property exists and is a valid value
    if (treatments.id) {
      treatmentIds.push(treatments.id);
    }
  });


  // ...



  // ...

  useEffect(() => {
    teamMemberData?.map((item) => {
      const spaceIndex = item?.name?.indexOf(" ");
      if (spaceIndex !== -1) {
        //  const firstWord = 
        // const restOfString = item?.name?.slice(spaceIndex + 1);
        setName({
          firstName: item?.name?.slice(0, spaceIndex),
          lastName: item?.name?.slice(spaceIndex + 1),
          id: item?.id
        })

      } else {
        // Handle the case where there is no space in the string
      }

      setTeamMemberSelectFun(teamMemberData[0]);
      setRadioChecked(true);

    })
  }, [teamMemberData])



  // ...

  return (
    <div className="team-member-section">
   
        <BackArrow
          className="cursor-pointer"
          onClick={() => setSubStep(302)}
          style={{ marginBottom: "20px" }}
        />
        <BannerHeader title={"Select team member"} />
    
      {
        teamMemberData?.length > 0 &&
          isLoading ?
          <SkeletonLoader type={"team-member"} showCircle={true} />
          :

          <div
            className="right-content teamWrapperBox"
            onClick={() => {
              const fullName = name.lastName
                ? `${name.firstName} ${name.lastName}`
                : name.firstName;
              setTeamMemberSelectFun(teamMemberData[0]);
              setRadioChecked(true);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "20px",
                textAlign: "justify",
              }}
              className="AvtarWrapper"
            >
              <Avatar className="string-color" src={teamMemberData[0]?.image} {...stringAvatar(teamMemberData[0]?.initials)} />
              <div className="team-member-content">
                {/* <b>{`${name.firstName} and ${name.lastName}`}</b> */}
                <b>{teamMemberData[0]?.name}</b>
                <p>{teamMemberData[0]?.occupations.join(", ")}</p>
              </div>
            </div>
            <input
              type="radio"
              checked={radioChecked}
              onChange={() => {
                const fullName = name.lastName
                  ? `${name.firstName} ${name.lastName}`
                  : name.firstName;
                setTeamMemberSelectFun(teamMemberData[0]);
                // handleSelectTeamMember();
                setRadioChecked(true);
              }}
            />
          </div>
      }
    </div>
  );
};

export default TeamMemberWeb;
