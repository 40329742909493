import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const ADD_PRODUCT_TO_CART = gql`
mutation AddProductsToCart($productsData: [storeClinicTimingsInputType]) {
  addProductsToCart(productsData: $productsData) {
    success
    message
    data {
      cart_items
      cart_url
      prescription_request_url
    }
    
  }
}
`;

export const AddProductToCartMutation = () => {
  const [addProductToCartRequest, addProductToCartResponseData] =
    useMutation(ADD_PRODUCT_TO_CART);
  const dispatch = useDispatch();
  const addProductToCartMutationData = addProductToCartResponseData;

  const initiateAddProductToCart = (
    data,
    successCallback,
    errorCallback
  ) => {
    addProductToCartRequest({
      variables: data,
    })
      .then(({ data: { addProductsToCart: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateAddProductToCart, addProductToCartMutationData };
};
