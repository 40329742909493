import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { GetAppointmentListByDayType } from '../../redux/actions/client/GetAppointmentListById'
import { ResendBookingDetailsMutation } from '../../redux/actions/sendReminder/ResendBookingDetails'
import { ReactComponent as EditIcon } from '../../../images/icons/appointment-list/edit.svg'
import { ReactComponent as CloseIcon } from '../../../images/close_icon.svg'
import { ReactComponent as DeleteIcon } from '../../../images/icons/appointment-list/delete.svg'
import { ReactComponent as DownloadIcon } from '../../../images/icons/appointment-list/download.svg'
import { ReactComponent as ResendIcon } from '../../../images/icons/appointment-list/resend.svg'
import { ReactComponent as ViewIcon } from '../../../images/icons/appointment-list/view.svg'
import { ReactComponent as RightArrow } from '../../../images/icons/chevron-left.svg'
import MenuIcon from '../../../images/icons/verticalDots.svg'
import { ReactComponent as AddIcon } from "../../../images/add_event.svg";

import showToast from '../../common/CustomToast/CustomToast'
import { CLEAR_BLOCK_OUT_TIME_DATA } from '../../redux/constants/blockOutTime/BlockOutTimeConst'
import { getAppointmentRoute, getBookinginvoiceRoute, getClientProfileFormsRoute, getEditAppointmentRoute, getEditBlockOutTimeRoute, getNewAppointmentRoute, getShareBookingLinkRoute, getViewBlockoutTimeRoute } from '../../../Routes'
import { CHECK_START_DATE_FOR_LOADING, CLEAR_APPOINTMENT_LIST_BY_TYPE, CLEAR_APPOINTMENT_LIST_CLINICS, CLEAR_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION, SAVE_CALENDAR_DAILYVIEW_DATA, UPDATE_CLINIC_LIST_DATA } from '../../redux/constants/appointment/appointmentConst'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import SkeletonLoader from '../../common/Skeleton/SkeletonLoader'
import ConsentActionPopup from '../../consent-action-popup'
import "./list-view-calendar.scss"
import CustomModal from '../../modal/CustomModal'
import { DeleteBlockOutTimeMutation } from '../../redux/actions/block-out-time/DeleteBlockOutTime'
import { debounce } from 'lodash'
import OffCanvasModal from '../../modal/OffCanvasModal/OffCanvasModal'
import AppointmentListCard from '../../common/appointment-list-card/AppointmentListCard'
import AppointmentViewPopup from '../../common/appointment-view-popup/AppointmentViewPopup'
import { frontendURL } from '../../../config'
import AddLocationListView from './add-location-list-view/AddLocationListView'
import HoursAndBreakAdded from '../../oneTimeChangeCalender/hoursAndBreakAdded'
import { generateCustomDates, weeklyViewDaySevenDateChange } from '../../../utils/general'
import { GetOneTimeOffAction } from '../../redux/actions/onetimeoff/OneOffTime'
import { GetAvailableClinicsListAction } from '../../redux/actions/appointments/GetAvailableClinicsList'
import { GetCalendarAppointmentCountAction, GetClinicAvailableDateAction } from '../../redux/actions/dateTimeslots/DateTimeSlots'
import { CustomCalendarSkeleton } from '../../clininc-cart-skeleton/calenadar-skeleton/CustomCalendarSkeleton'
import CustomCalendar from '../dateTime/steps/CustomCalendar'
import { ReactComponent as MorningIcon } from "../../../images/icons/morning_icon.svg";
import { ReactComponent as AfterNoonIcon } from "../../../images/icons/after_noon_icon.svg";
import { ReactComponent as EveningIcon } from "../../../images/icons/evening_icon.svg";
import useWindowDimension from '../../../utils/useWindowDimensions'
import Tooltip from '../../common/Tooltip/Tooltip'
import ListViewToolTipContent from './ListViewToolTipContent'
import noAppointmentList from '../../../images/icons/appointment-list/noAppointmentList.svg'
import StepButton from '../../common/StepButton'
import UpdateWorkingAvailabilityCalendar from './update-woking-availability-calendar/UpdateWorkingAvailabilityCalendar'
import noLocation from '../../../images/icons/appointment-list/no_locations.png'
import { CLEAR_PRESCRIBER_LIST } from '../../redux/constants/consentFormThankyou/getPrescriberListConst'
import { CLEAR_PRESCRIPTIONS_LIST } from '../../redux/constants/getPrescriptionsListConst'
import  ClientFormsViewPopup from "../../common/client-forms-view-popup/ClientFormsViewPopup"
import { GetConsentFormPdfUrlQuery } from '../../redux/actions/downloadConsentForm/downloadConsentForm'
import { GetMedicalFormPdfUrlQuery } from '../../redux/actions/downloadMedicalForm/DownloadMedicalForm'
import { ResendMedicalFormAction } from '../../redux/actions/sendReminder/ResendMedicalForm'
import { PrescriberList } from '../../Client/prescriber-list/PrescriberList'

import { SendConsentFormOrMedicalFormToPrescriberAction } from '../../redux/actions/send-consent-form-or-medicalform-to-prescriber/SendConsentFormOrMedicalFormToPrescriber'
import { PrescriptionList } from '../../Client/prescription-list/PrescriptionList'
import { UpdatePrescriptionAttachedFormAction } from '../../redux/actions/update-prescription-attached-form/UpdatePrescriptionAttachedForm'

const ListViewCalender = ({ filterObj, viewCalendarState, showSelectedDate, locationId, searchValue, isThreeDaysView, tooltipClickHandler, isUpdateWorkingAvailabilityTooltip, isLoading, checkedLocationData, selectedMonthMonthlyView, selectedMonth }) => {
    const dimensions = useWindowDimension();
    const [headerNavbar, setHeaderNavbar] = useState(0);
    const [headerCalendar, setHeaderCalendar] = useState(0);
    const [appointmentTitleHeight, setAppointmentTitleHeight] = useState(0);


const [isPaymentPopUpOpen,setIsPaymentPopUpOpen]=useState(false)
  const [paymentPopUpPrice,setPaymentPopUpPrice]=useState(null)
  const [paymentPopClientName,setPaymentPopClientName]=useState(null)
  const [isFormsMenuOptionsOpen, setIsFormsMenuOptionsOpen] = useState(false)
  const [prescriberId, setPrescriberId] = useState(null)
  const [isPrescriberModalOpen, setIsPrescriberModalOpen] = useState(false)
  const [isNoPharmacyRegisterModalOpen, setIsNoPharmacyRegisterModalOpen] = useState(false)
  const [appointmentIdData, setAppointmentIdData] = useState(null);
  const [isPrescriptionModalOpen, setIsPrescriptionModalOpen] = useState(false)
  const [tempShowSelectedPrescriber, setTempShowSelectedPrescriber] = useState(null)
  const [consentActive,setConsentActive]=useState("Consent")
    const tooltipViewWorkingHoursAvailibilityRef = useRef(null);

    useEffect(() => {
        setHeaderNavbar(
            document.getElementsByClassName('HeaderStickey')[0]?.offsetHeight
        );

        setHeaderCalendar(
            document.getElementsByClassName('calendar-header-main-div')[0]?.offsetHeight
        );

        setAppointmentTitleHeight(
            document.getElementsByClassName('AppointmentTitleWrapper')[0]?.offsetHeight
        );

        window.addEventListener('resize', handleResize);
    }, []);

    console.log(headerNavbar, headerCalendar, appointmentTitleHeight, 'appointmentTitleHeight' )
    const handleResize = () => {
        setHeaderNavbar(
            document.getElementsByClassName('HeaderStickey')[0]?.offsetHeight
        );

        setHeaderCalendar(
            document.getElementsByClassName('calendar-header-main-div')[0]?.offsetHeight
        );

        setAppointmentTitleHeight(
            document.getElementsByClassName('AppointmentTitleWrapper')[0]?.offsetHeight
        );
    };

    const [appointmentId, setAppointmentId] = useState(null)
    const [clientId, setClientId] = useState(null)
    const [clinicId, setClinicId] = useState(null)
    const navigate = useNavigate();
    const [openClientForm, setOpenClientForm] = useState(false)
    const popOverRef = useRef(null)
    const [isDelete, setIsDelete] = useState(false)
    const [clickedDate, setClickedDate] = useState();
    const [blockOutId, setBlockOutId] = useState(null)
    const [blockOutDateId, setBlockOutDateId] = useState(null)
    const [blockoutEndDate, SetBlockoutEndDate] = useState(null)
    const [isApiCalled, setisApiCalled] = useState(false)

    //Get data of Appointment list
    // const appointmentListData = useSelector((state)=>state?.appointmentListReducer?.appointmentList?.appointments)
    const appointmentListData = useSelector((state) => state?.appointmentListByTypeReducer?.appointmentListByType)
    const totalAppointmentCount = useSelector((state) => state?.appointmentListByTypeReducer?.totalAppointmentCount)
    const appointmentListPage = useSelector((state) => state?.appointmentListByTypeReducer?.appointmentListPage)
    const loadedAppointmentCount = useSelector((state) => state?.appointmentListByTypeReducer?.loadedAppointmentCount)
    const location = useLocation()

console.log("appointmentListData111",appointmentListData)
    //get data of clinics
    const appointmentClinicsList = useSelector((state) => state?.appointmentListClinicsReducer?.appointmentClinicsList)
    const totalAppointmentClinicsListCount = useSelector((state) => state?.appointmentListClinicsReducer?.totalAppointmentClinicsListCount)
    const appointmentClinicsListPage = useSelector((state) => state?.appointmentListClinicsReducer?.appointmentClinicsListPage)
    const loadedAppointmentClinicsListCount = useSelector((state) => state?.appointmentListClinicsReducer?.loadedAppointmentClinicsListCount)
    const totalAppointments = useSelector((state) => state?.appointmentListClinicsReducer?.totalAppointments)
   const appointmentList=useSelector((state)=>state?.appointmentListReducer?.appointmentList)
   console.log(`appointmenthhhh`,appointmentList?.vendorIsPharmacy );
    //get data of clinics for add location
    const appointmentClinicsListAddLocation = useSelector((state) => state?.appointmentListClinicsReducer?.appointmentClinicsListAddLocation)
    const totalAppointmentClinicsListCountAddLocation = useSelector((state) => state?.appointmentListClinicsReducer?.totalAppointmentClinicsListCountAddLocation)
    const appointmentClinicsListPageAddLocation = useSelector((state) => state?.appointmentListClinicsReducer?.appointmentClinicsListPageAddLocation)
    const loadedAppointmentClinicsListCountAddLocation = useSelector((state) => state?.appointmentListClinicsReducer?.loadedAppointmentClinicsListCountAddLocation)

    const checkStartDateForLoading = useSelector((state) => state?.appointmentListClinicsReducer?.checkStartDateForLoading)

    //Appointment list Query by selected Date
    //const { initGetAppointmentList, appointmentListQueryData } = GetAppointmentList();
    let { initGetAppointmentListByDayType, appointmentListQueryData } = GetAppointmentListByDayType()
    const [isRootClose, setIsRootClose] = useState(false)
    const [isFilled, setIsFilled] = useState(null)
    const [showConsentPopup, setShowConsentPopup] = useState()
    const [confirmationPopupShow, setConfirmationPopupShow] = useState(false);
    const [compliancePopupShow, setCompliancePopupShow] = useState(false);
    const [deleteDataByAppointmentId, setDeleteDataByAppointmentId] = useState();
    const [listSearchFlag, setListSearchFlag] = useState(false)
    const [isAddLocationOpen, setIsAddLocationOpen] = useState(false)
    const [isAppointmentMenuOpen, setIsAppointmentMenuOpen] = useState(false)
    const [consentFormRecordId, setConsentFormRecordId] = useState(null)
    const [isLocationHoursUpdateOpen, setIsLocationHoursUpdateOpen] = useState(false)
    const [hoursData, setHoursData] = useState({
        isOpenForBusiness: true,
        opens: "09:00",
        closes: "18:00",
        isBlock: false,
    })
    const [breaks, setBreaks] = useState([{ start: hoursData?.opens, end: moment(hoursData?.opens, 'HH:mm').add(30, 'minutes').format('HH:mm'), breakError: "" }]);
    const [selectedLocationData, setSelectedLocationData] = useState(null)
    const [calenderDisplayDate, setCalenderDisplayDate] = useState();
    const [type, setType] = useState(null)
    const [paymentTagData, setPaymentTagData] = useState(null)
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    const [consentFormTagData, setConsentFormTagData] = useState(null)
    // const [checkStartDateForLoading, setCheckStartDateForLoading] = useState(null)



    const selectedDateString = moment(clickedDate).format('YYYY-MM-DD');
    const todayDateString = moment().format('YYYY-MM-DD');
    const dispatch = useDispatch()
    const [isBlockTimeOut, setIsBlockTimeOut] = useState(false)
    const isNotToday = moment(selectedDateString).isBefore(todayDateString) || moment(selectedDateString).isAfter(todayDateString);
    //Resend Booking Details
    const { initiateResendBookingDetailsData,resendBookingDetailsMutationData } = ResendBookingDetailsMutation()
  
    const { initConsentFormPdfUrl } = GetConsentFormPdfUrlQuery()
  const { initMedicalFormPdfUrl } = GetMedicalFormPdfUrlQuery()
  const { initResendMedicalFormData, resendMedicalFormQueryData } = ResendMedicalFormAction()
  const { initSendConsentFormOrMedicalFormToPrescriberData, sendConsentFormOrMedicalFormToPrescriberQueryData } = SendConsentFormOrMedicalFormToPrescriberAction()
  const { initUpdatePrescriptionAttachedFormData, updatePrescriptionAttachedFormQueryData } = UpdatePrescriptionAttachedFormAction()

  const clientData = useSelector(state => state.clientConsentFormListReducer?.clientData)



    //DELETE BLOCK TIME OUT
    const { initiateDeleteBlockOutTime, deleteBlockTimeMutationData } = DeleteBlockOutTimeMutation()

    const redirectUrl = `${frontendURL}/calendar`
    const encodedRedirectUrl = encodeURIComponent(redirectUrl);
    const { initGetOneTimeOffData, getOneTimeOffQueryData } = GetOneTimeOffAction();
    const { initGetAvailableClinicsListData, getAvailableClinicsListQueryData } = GetAvailableClinicsListAction()
    const scrollRef = useRef()

    const { getCalendarAppointmentCountQueryData, initGetCalendarAppointmentCountData } = GetCalendarAppointmentCountAction()
    const { clinicsAvailableDateData, initClinicsAvailableDateResponseFunction } = GetClinicAvailableDateAction();

    const [startDateTobeChecked, setStartDateTobeChecked] = useState(null)
    const [endDateTobeChecked, setEndDateTobeChecked] = useState(null)

    const menuData = [
        {
            title: "Edit / reschedule",
            icon: <EditIcon width={40} />,
        },
        {
            title: "Resend booking details",
            icon: <ResendIcon width={40} />,
        },
        {
            title: "Download invoice",
            icon: <DownloadIcon width={40} />,

        },
        {
            title: "View consent",
            icon: <ViewIcon width={40} />,
        },
        {
            title: "Delete",
            icon: <DeleteIcon width={40} />,
        }
    ]
    const staticConsentForm = `https://staging.facesconsent.com/filled-consent-form/list?cln_id=${clinicId}`;

    const debouncedApiCall1 = debounce((query) => {

        //API CALL
        dispatch({
            type: CLEAR_APPOINTMENT_LIST_BY_TYPE
        })
        initiateAppointmentListCall(true, query);

    }, 800);

    useEffect(() => {
        if (searchValue.length > 0 || listSearchFlag) {
            // Call the debounced API function with the search query
            debouncedApiCall1(searchValue);
        }
        setListSearchFlag(true);
        // Cleanup function to cancel any pending debounced calls
        return () => {
            debouncedApiCall1.cancel();
        };
    }, [searchValue]);


    const initiateAppointmentListCall = (refreshList = false, search = null) => {
        let newPage = appointmentListPage + 1;
        if (refreshList) {
            newPage = 1;

        } else if (totalAppointmentCount <= loadedAppointmentCount) {
            return;
        }
        let startDate;
        let endDate;
        let calendarStartDate = moment().format("YYYY-MM-DD")
        if (viewCalendarState == "timeGridDay") {
            startDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : calendarStartDate
            endDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : calendarStartDate
        }
        else if (viewCalendarState == "timeGridWeek" && isThreeDaysView) {
            startDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : calendarStartDate
            endDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().add(2, "days").format("YYYY-MM-DD") : moment(calendarStartDate, "YYYY-MM-DD").clone().add(2, "days").format("YYYY-MM-DD")
        }
        else if (viewCalendarState == "timeGridWeek" && !isThreeDaysView) {
            startDate = showSelectedDate ? weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "start") : weeklyViewDaySevenDateChange(moment(calendarStartDate), "start")
            endDate = showSelectedDate ? weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "end")
                : weeklyViewDaySevenDateChange(moment(calendarStartDate), "end")
        }
        else if (viewCalendarState == "dayGridMonth") {
            startDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().startOf("month").format("YYYY-MM-DD") : moment(calendarStartDate, "YYYY-MM-DD").clone().startOf("month").format("YYYY-MM-DD")
            endDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().endOf('month').format("YYYY-MM-DD") : moment(calendarStartDate, "YYYY-MM-DD").clone().endOf("month").format("YYYY-MM-DD")
        }
        initGetAppointmentListByDayType({
            ...filterObj,
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
            page: newPage,
            limit: 15,
            locations: locationId,
            includeBlockouts: true,
            search: search

        })
        if (!isApiCalled)
            setisApiCalled(true)
    }


    const initiateAppointmentListClinicsCall = (refreshList = false, search = null) => {
        let newPage = appointmentClinicsListPage + 1;
        if (refreshList) {
            newPage = 1;

        } else if (totalAppointmentClinicsListCount <= loadedAppointmentClinicsListCount) {
            return;
        }
        let startDate;
        let endDate;
        let calendarStartDate = moment().format("YYYY-MM-DD")
        if (viewCalendarState == "timeGridDay") {
            startDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : calendarStartDate
            endDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : calendarStartDate
        }
        else if (viewCalendarState == "timeGridWeek" && isThreeDaysView) {
            startDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : calendarStartDate
            endDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().add(2, "days").format("YYYY-MM-DD") : moment(calendarStartDate, "YYYY-MM-DD").clone().add(2, "days").format("YYYY-MM-DD")
        }
        else if (viewCalendarState == "timeGridWeek" && !isThreeDaysView) {
            startDate = showSelectedDate ? weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "start") : weeklyViewDaySevenDateChange(moment(calendarStartDate), "start")
            endDate = showSelectedDate ? weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "end")
                : weeklyViewDaySevenDateChange(moment(calendarStartDate), "end")
        }
        else if (viewCalendarState == "dayGridMonth") {
            startDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().startOf("month").format("YYYY-MM-DD") : moment(calendarStartDate, "YYYY-MM-DD").clone().startOf("month").format("YYYY-MM-DD")
            endDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().endOf('month').format("YYYY-MM-DD") : moment(calendarStartDate, "YYYY-MM-DD").clone().endOf("month").format("YYYY-MM-DD")
        }
        // setCheckStartDateForLoading(moment(startDate).format("YYYY-MM-DD"))
        dispatch({
            type: CHECK_START_DATE_FOR_LOADING,
            payload: false
        })
        initGetAvailableClinicsListData({
            ...filterObj,
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
            page: newPage,
            limit: 15,
            includeUnAvailable: false,
            clinicIds: locationId
        })

    }

    const handleResendDetails = (formId) => {
        initiateResendBookingDetailsData({
            "appointmentId": formId,
            "consentFormRecordId": null
        }, (res) => {
            if (res.success) {
                // toast.success(res.message)
                showToast("success", res?.message, "", false)
                setIsAppointmentMenuOpen(false)
            } else {

                showToast("error", res?.message, "", false)
            }
        }, (err) => {
            showToast("error", err, "", false)
        })
    }

    const downloadForm = (formId) => {
        initConsentFormPdfUrl({
            consentFormRecordId: formId
        }, (res) => {
            console.log(res?.data?.getConsentFormRecordPdfUrl, "download pdf")
            if (res?.data?.getConsentFormRecordPdfUrl?.success) {
                window.open(res?.data?.getConsentFormRecordPdfUrl?.data?.consentFormRecordPdfUrl, "_blank")
                setIsFormsMenuOptionsOpen(false)
            } else {
                showToast("error", res.message, "", false)
            }
        }, (err) => {
            showToast("error", err, "", false)
    
        })
    }
    
    const downloadMedicalForm = (formId) => {
      initMedicalFormPdfUrl({
          clientId: clientId
      }, (res) => {
          if (res?.data?.getMedicalFormPdfUrl?.success) {
              window.open(res?.data?.getMedicalFormPdfUrl?.data?.medicalFormRecordPdfUrl, "_blank")
              setIsFormsMenuOptionsOpen(false)
          } else {
              showToast("error", res.message, "", false)
          }
      }, (err) => {
          showToast("error", err, "", false)
    
      })
    }
    const handleResendMedicalForm = () => {
    initResendMedicalFormData({
        clientId: clientId
    }, (res) => {
        if (res.success) {
            showToast("success", res.message, "", false)
            setIsFormsMenuOptionsOpen(false)
        } else {
            showToast("error", res.message, "", false)
        }
    }, (err) => {
        showToast("error", err, "", false)
    })
    }
    const handleSendReminder = (formId) => {
    initiateResendBookingDetailsData({
        "appointmentId": null,
        "consentFormRecordId": formId
    }, (res) => {
        if (res.success) {
            showToast("success", res.message, "", false)
            setIsFormsMenuOptionsOpen(false)
        } else {
            showToast("error", res.message, "", false)
        }
    }, (err) => {
        showToast("error", err, "", false)
    })
    }
    
    const sendClickHandler = (selectedPrescriberId) => {
    // let data;
    // if (active === "Consent") {
    //     data = {
    //         consentFormId: consentFormRecordId,
    //         prescriberId: parseInt(selectedPrescriberId)
    //     }
    // }
    // else {
    //     data = {
    //         clientId: clientId,
    //         prescriberId: parseInt(selectedPrescriberId)
    //     }
    // }
    // handleSendConsentFormorMedicalFormToPrescriber(data)
    setIsPrescriberModalOpen(false)
    setPrescriberId(selectedPrescriberId)
    setIsPrescriptionModalOpen(true)
    }
    
    const sendtoPrescriberHandler = (dataTobePassed) => {
    
      let data;
    
      if (consentActive === "Consent") {
          data = {
              prescriptionId: dataTobePassed?.prescriptionId,
              clientId: clientId,
              consentFormId: consentFormRecordId,
              attachMedicalForm: dataTobePassed?.attachMedicalForm
          }
      }
      else {
          data = {
              prescriptionId: dataTobePassed?.prescriptionId,
              clientId: clientId,
              consentFormId: 0,
              attachMedicalForm: true,
          }
      }
      initUpdatePrescriptionAttachedFormData(
          data
          , (res) => {
              if (res.success) {
                  showToast("success", res.message, "", false)
                  setIsPrescriptionModalOpen(false)
                  setTempShowSelectedPrescriber(null)
              } else {
                  showToast("error", res.message, "", false)
                  setTempShowSelectedPrescriber(null)
              }
          }, (err) => {
              showToast("error", err, "", false)
          })
    }
    
      const handleConsentFormClick=(title)=>{
        switch (title) {
            case "Download PDF":
                if (consentFormRecordId !== null) {
                    downloadForm(consentFormRecordId)
                }
                break;
    
            case "Send to client":
              handleSendReminder(consentFormRecordId)
                break;
    
    
            case "Re-send to client":
              handleSendReminder(consentFormRecordId)
                break;
    
            case "Send to prescriber":
                dispatch({
                    type: CLEAR_PRESCRIBER_LIST
                })
                dispatch({
                    type: CLEAR_PRESCRIPTIONS_LIST
                })
                setPrescriberId(null);
                setIsFormsMenuOptionsOpen(false)
                setIsAppointmentMenuOpen(false)
                if (appointmentList?.vendorIsPharmacy) {
                    // initiatePrescriberListCall(true)
                    setIsPrescriberModalOpen(true)
                }
                else {
                    setIsNoPharmacyRegisterModalOpen(true)
                }
                break;
    
            case "Edit consent form":
                window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
                break;
    
            case "Complete in person":
                window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
                break;
    
            
    
            case "Repeat form":
              window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/duplicate/" + consentFormRecordId + `?documents=true`
                // navigate(rebookAppointmentRoute(appointmentIdData?.appointmentId, clientId), { state: { isFromClientForm: true } })
                break;
    
            case "Download PDF":
              downloadMedicalForm()
              break;
    
          case "Send to client":
              handleResendMedicalForm()
              break;
    
          case "Re-send to client":
              handleResendMedicalForm()
              break;
    
          case "Send to prescriber":
              dispatch({
                  type: CLEAR_PRESCRIBER_LIST
              })
              dispatch({
                  type: CLEAR_PRESCRIPTIONS_LIST
              })
              setPrescriberId(null);
              setIsFormsMenuOptionsOpen(false)
              setIsAppointmentMenuOpen(false)
              if (appointmentList?.vendorIsPharmacy) {
                  // initiatePrescriberListCall(true)
                  setIsPrescriberModalOpen(true)
              }
              else {
                  setIsNoPharmacyRegisterModalOpen(true)
              }
              break;
    
          case "Edit medical form":
              window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`
              break;
    
          case "Complete in person":
              window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`
              break;
    
          default:
              console.log('Default')
              break;
        }
    }



    useEffect(() => {
        if (openClientForm) {
            //popOverRef.onHide()
            document.body.click()
        }
    }, [isRootClose])

    // useEffect(() => {
    //     console.log("cancel list", location?.state?.cancelAppointment)
    //     if (location?.state?.cancelAppointment == "cancelAppointment") {
    //         setIsBlockTimeOut(true)
    //         dispatch({
    //             type: CLEAR_BLOCK_OUT_TIME_DATA
    //         })
    //         initiateBlockTimeOutListCall(true);
    //     }
    // }, [location?.state?.cancelAppointment])

    const handleCloseClick = () => {
        document.body.click()
        //popOverRef.onHide()
    }

    const handlePaymentResend=()=>{
        handleResendDetails(appointmentId);
        setIsPaymentPopUpOpen(false)
    }

    const handleMenuClick = (title, isBlockTimeOut = false) => {

        switch (title) {
            case "View or edit event":
                // if (moment().isAfter(moment(blockoutEndDate, "YYYY-MM-DD"))) {
                //     showToast("error", "you can't edit this event", "", "")
                // }
                // else {
                //     navigate(getEditBlockOutTimeRoute(blockOutId, blockOutDateId), { state: { fromBlockoutCalendarList: true, calenderViewState: viewCalendarState, calenderShowSelectedDate: showSelectedDate } })
                // }
                navigate(getViewBlockoutTimeRoute(blockOutId, blockOutDateId), { state: { goBackToCalendar: true } })
                break;

            case "Delete event":
                console.log('Delete')

                if (moment().isAfter(moment(blockoutEndDate, "YYYY-MM-DD"))) {
                    showToast("error", "You can't delete this event", "", "")
                }
                else {
                    setIsDelete(true)
                    setIsAppointmentMenuOpen(false)
                }
                break;

            case "Payment":
                if(paymentTagData?.paymentTag === "Unpaid"){
                    setIsPaymentPopUpOpen(true)
                  }
                // handleResendDetails(appointmentId);
                break;

            case "Edit or reschedule":
                console.log('Edit / reschedule')
                navigate(getEditAppointmentRoute(appointmentId), { state: { fromAppointmentList: true, clickdate: clickedDate, calenderViewState: viewCalendarState, calenderShowSelectedDate: showSelectedDate } })
                break;

            case "Appointment overview":
                navigate(getAppointmentRoute(appointmentId), { state: { fromCalenderAppointmentList: "calender", fromAppointmentList: true, calenderViewState: viewCalendarState, calenderShowSelectedDate: showSelectedDate } })
                break;

            case "Resend booking & consent":
                console.log('Resend booking details')
                handleResendDetails(appointmentId)
                break;

            case "Download invoice":
                console.log('Download invoice', appointmentId)
                navigate(getBookinginvoiceRoute(), { state: { entityId: appointmentId, selectedDate: clickedDate, fromAppointmentList: true, calenderViewState: viewCalendarState, calenderShowSelectedDate: showSelectedDate } })
                break;

            case "Consent form":
                setIsFormsMenuOptionsOpen(true)
                console.log('View consent', clientId)
                document.body.click()
                setIsRootClose(false)
                // window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
                break;

            case "Cancel appointment":
                setShowConsentPopup(true);
                setConfirmationPopupShow(true);
                setIsAppointmentMenuOpen(false)
                console.log('Delete')
                break;

            default:
                console.log('Default')
                break;
        }

    }
    const getAppointmentList = () => {
        dispatch({
            type: CLEAR_APPOINTMENT_LIST_BY_TYPE
        })
        if (clickedDate !== null) {

            let startDate;
            let endDate;
            let calendarStartDate = moment().format("YYYY-MM-DD")

            if (viewCalendarState == "timeGridDay") {
                startDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : calendarStartDate
                endDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : calendarStartDate
            }
            else if (viewCalendarState == "timeGridWeek") {
                startDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : calendarStartDate
                endDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().add(6, "days").format("YYYY-MM-DD") : moment(calendarStartDate, "YYYY-MM-DD").clone().add(6, "days").format("YYYY-MM-DD")
            }
            else if (viewCalendarState == "dayGridMonth") {
                startDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().startOf("month").format("YYYY-MM-DD") : moment(calendarStartDate, "YYYY-MM-DD").clone().startOf("month").format("YYYY-MM-DD")
                endDate = showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().endOf('month').format("YYYY-MM-DD") : moment(calendarStartDate, "YYYY-MM-DD").clone().endOf("month").format("YYYY-MM-DD")
            }
            initGetAppointmentListByDayType({
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD"),
                locations: locationId,
                includeBlockouts: true

            })
        }
    }


    //DELETE BLOCK OUT TIME
    const handleDeleteBlockOut = () => {
        initiateDeleteBlockOutTime({
            blockOutDateId: blockOutDateId,
            blockOutId: blockOutId
        }, (res) => {
            if (res?.success) {
                setIsDelete(false)
                dispatch({
                    type: CLEAR_APPOINTMENT_LIST_BY_TYPE
                })
                initiateAppointmentListCall(true);
            }
        }, (err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        //Clear the appointment list
        console.log("ashatestcase", getAvailableClinicsListQueryData?.variables.startDate,)

        if (!moment(showSelectedDate, "dddd, Do MMMM YYYY").isBetween(moment(startDateTobeChecked), moment(endDateTobeChecked), null, '[]')) {
            dispatch({
                type: CLEAR_APPOINTMENT_LIST_BY_TYPE
            })

            dispatch({
                type: CLEAR_APPOINTMENT_LIST_CLINICS
            })
        }


        setisApiCalled(false)


        let calendarStartDate = moment().format("YYYY-MM-DD")

        if (viewCalendarState == "timeGridWeek" && isThreeDaysView) {
            setStartDateTobeChecked(showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : calendarStartDate)
            setEndDateTobeChecked(showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().add(2, "days").format("YYYY-MM-DD") : moment(calendarStartDate, "YYYY-MM-DD").clone().add(2, "days").format("YYYY-MM-DD"))
        }
        else if (viewCalendarState == "timeGridWeek" && !isThreeDaysView) {
            setStartDateTobeChecked(showSelectedDate ? weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "start") : weeklyViewDaySevenDateChange(moment(calendarStartDate), "start"))
            setEndDateTobeChecked(showSelectedDate ? weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "end")
                : weeklyViewDaySevenDateChange(moment(calendarStartDate), "end"))
        }

        if (showSelectedDate) {
            // console.log("scrollRef", scrollRef?.current?.el?.firstElementChild)



            // scrollRef?.current?.el?.firstElementChild
            //     ?.scrollTo(0, 0);

            // const parentDiv = scrollRef?.current?.el?.firstElementChild;
            // const childDiv = parentDiv?.querySelector('.location-infinite-scroll');

            // if (childDiv) {
            //     console.log('Parent div contains child div');
            //     scrollRef?.current?.el?.firstElementChild?.scrollTo(0, 0);
            //     // childDiv.scro
            // } else {
            //     console.log('Parent div does not contain child div');
            // }
            scrollRef.current.scrollTop = 0;
            console.log(moment(showSelectedDate, "dddd, Do MMMM YYYY").isSameOrAfter(moment(startDateTobeChecked)), "axxy", moment(showSelectedDate, "dddd, Do MMMM YYYY").isSameOrBefore(moment(endDateTobeChecked)),
                "apicall1", showSelectedDate, moment(startDateTobeChecked).format("YYYY-MM-DD"), moment(endDateTobeChecked).format("YYYY-MM-DD"))
            setTimeout(() => {
                if (!moment(showSelectedDate, "dddd, Do MMMM YYYY").isBetween(moment(startDateTobeChecked), moment(endDateTobeChecked), null, '[]')) {
                    initiateAppointmentListClinicsCall(true);
                }
            }, 0)
        } else
            initiateAppointmentListClinicsCall(true);


    }, [viewCalendarState, showSelectedDate, locationId, selectedMonthMonthlyView])

    // const parentDiv = scrollRef?.current?.el?.firstElementChild;
    // console.log("parentDiv", parentDiv)
    // const childDiv = parentDiv?.querySelector('.location-infinite-scroll');

    // if (childDiv) {
    //     console.log('Parent div contains child div', childDiv);
    // } else {
    //     console.log('Parent div does not contain child div');
    // }

    useEffect(() => {
        //   console.log("testcase", getAvailableClinicsListQueryData, getAvailableClinicsListQueryData?.data?.getAvailableClinicsList?.data?.totalClinics)
        if ((loadedAppointmentClinicsListCount != 0 && !(loadedAppointmentClinicsListCount < totalAppointmentClinicsListCount) && !isApiCalled) || (getAvailableClinicsListQueryData?.data?.getAvailableClinicsList?.data?.totalClinics === 0)) {
            initiateAppointmentListCall(true);
        }
    }, [loadedAppointmentClinicsListCount, totalAppointmentClinicsListCount, showSelectedDate, getAvailableClinicsListQueryData])

    const formattedDate = moment(clickedDate).format('ddd D MMMM');

    console.log("appointment list", appointmentListData)

    const blockOutTimeMenuData = [
        {
            title: "Edit",
            icon: <EditIcon width={40} />,
        },
        {
            title: "Delete",
            icon: <DeleteIcon width={40} />,
        }
    ]

    const popoverClick = (
        <Popover id="popover-trigger-click" className='appointment-option-main p-3' >
            <div className='popover-title-section'>
                <h1>Manage</h1>
                <CloseIcon className='close-icon-animation' onClick={handleCloseClick} />
            </div>
            {
                menuData.filter((items) => {
                    if (isFilled) {
                        // If isFilled is true, hide "Resend booking details"
                        return items.title !== 'Resend booking details';
                    } else {
                        // If isFilled is false, hide "View consent"
                        return items.title !== 'View consent';
                    }
                }).map((items, index) => (
                    <div className='appointment-list-option-container' key={index} onClick={() => { handleMenuClick(items?.title); setIsRootClose(true); document.body.click() }}>
                        <div className='appointment-list-left-popover-content'>
                            {items.icon}
                            <p>{items.title}</p>
                        </div>
                        <div className='appointment-list-right-popover-content'>
                            <RightArrow />
                        </div>
                    </div>
                ))
            }
            {console.log("appoinmentId", appointmentId)}
        </Popover>

    );


    const popoverBlockoutTimeClick = (
        <Popover id="popover-trigger-click" className='appointment-option-main p-3' >
            <div className='popover-title-section'>
                <h1>Manage</h1>
                <CloseIcon className='close-icon-animation' onClick={handleCloseClick} />
            </div>
            {
                blockOutTimeMenuData.map((items, index) => (
                    <div className='appointment-list-option-container' key={index} onClick={() => { handleMenuClick(items?.title, true); setIsRootClose(true); document.body.click() }}>
                        <div className='appointment-list-left-popover-content'>
                            {items.icon}
                            <p>{items.title}</p>
                        </div>
                        <div className='appointment-list-right-popover-content'>
                            <RightArrow />
                        </div>
                    </div>
                ))
            }
        </Popover>

    );

    const getDayForGivenDate = (dateStr) => {

        // Parse the date string into a Moment object
        const givenDate = moment(dateStr, 'DD-MM-YYYY')

        // Get the current date as a Moment object
        const today = moment();

        // Calculate tomorrow's date as a Moment object
        const tomorrow = today.clone().add(1, 'days');
        // Compare the given date with today and tomorrow
        if (givenDate.isSame(today, 'day')) {
            return "Today"
        } else if (givenDate.isSame(tomorrow, 'day')) {
            return "Tomorrow"
        } else {
            return dateStr
        }
    }
    console.log("ashaview", appointmentListData)

    const onAppointmentClickHandler = (appointmentData) => {
        setAppointmentId(parseInt(appointmentData?.booking_slug));
        setClinicId(appointmentData?.cld_rc_id);
        setClientId(appointmentData?.clientId);
        setIsFilled(appointmentData?.isFilled);
        setDeleteDataByAppointmentId(appointmentData.cld_rc_id)
        setClickedDate(appointmentData?.calender_date)
        setConsentFormRecordId(appointmentData?.consentFormRecordId)
        setType("Appointment")
        setPaymentTagData({
            paymentTag: appointmentData?.paymentTag,
            paymentTagColor: appointmentData?.paymentTagColor,
            isPaymentDone: appointmentData?.isPaymentDone,
        })
        setConsentFormTagData({
            consentFormTag: appointmentData?.consentFormTag,
            consentFormTagColor: appointmentData?.consentFormTagColor,
            consentFormCount: appointmentData?.consentFormCount
        })

        setIsAppointmentMenuOpen(true)
        setPaymentPopUpPrice(appointmentData?.price);
        setPaymentPopClientName(appointmentData?.client_name)
    }

    const onBlockOutCardClickHandler = (blockoutData) => {
        setBlockOutDateId(blockoutData?.blockOutDateId);
        setBlockOutId(blockoutData?.blockOutId);
        SetBlockoutEndDate(blockoutData?.blockoutEndDate)
        setType("Blockout")
        setIsAppointmentMenuOpen(true)
    }

    const locationGetHoursApiCallHandler = (calendarStartDate, clinicId) => {
        initGetOneTimeOffData({
            clinicId: clinicId,
            date: calendarStartDate
        }, (res) => {
            if (res.success) {
                setHoursData({
                    isOpenForBusiness: res?.data?.isOpen,
                    opens: moment(res?.data?.startTime, 'HH:mm:ss').format('HH:mm'),
                    closes: moment(res?.data?.endTime, 'HH:mm:ss').format('HH:mm'),
                    isBlock: res?.data?.isBlockOut
                })
                if (res?.data?.breaks?.length > 0) {
                    let breakArray = res?.data?.breaks?.map((item) => {
                        return { start: moment(item.breakStartTime, 'HH:mm:ss').format('HH:mm'), end: moment(item?.breakEndTime, 'HH:mm:ss').format('HH:mm'), breakId: item.breakId, breakError: "" }
                    })
                    setBreaks(breakArray)
                }
                else {
                    setBreaks([]);
                }
            }

        }, (err) => {

        })
    }

    function categorizeByMonth(events, title, isTimingWiseDate, format) {
        // Create an object to hold events grouped by month
        let eventsByMonth = {};
        if (viewCalendarState == "timeGridDay" && isTimingWiseDate) {
            eventsByMonth = {
                Morning: [],
                Afternoon: [],
                Evening: []
            };


            events.forEach(event => {
                const eventTime = moment(event.cld_rc_start, "YYYY-MM-DD HH:mm:ss").format('HH:mm');

                if (moment(eventTime, 'HH:mm').isBetween(moment('05:00', 'HH:mm'), moment('11:59', 'HH:mm'))) {
                    eventsByMonth["Morning"].push(event);
                } else if (moment(eventTime, 'HH:mm').isBetween(moment('12:00', 'HH:mm'), moment('17:59', 'HH:mm'))) {
                    eventsByMonth["Afternoon"].push(event);
                } else {
                    eventsByMonth["Evening"].push(event);
                }


                // const time = moment(event.cld_rc_start, "2024-06-28 01:00:00", 'h:mm A');
                // const morningBoundary = moment('12:00 PM', 'h:mm A');
                // const afternoonBoundary = moment('6:00 PM', 'h:mm A');

                // if (time.isBefore(morningBoundary)) {
                //     eventsByMonth["morning"].push(event);
                // } else if (time.isBefore(afternoonBoundary)) {
                //     setAfternoonSlots((afternoonSlots) => [...afternoonSlots, { time_slot: timeSlot, isBooked: false }]);
                // } else {
                //     setEveningSlots((eveningSlots) => [...eveningSlots, { time_slot: timeSlot, isBooked: false }]);
                // }
            });

        } else {
            // Iterate through each event
            events.forEach(event => {

                // Formulate a key like "2024-06" for grouping
                // const monthKey = `${year}-${month}`;
                const monthKey = event?.type == "Blockout" ? moment(event?.blockoutStartDate, "YYYY-MM-DD").format('ddd DD MMM,YYYY') : moment(event[`${title}`], format).format('ddd DD MMM,YYYY');

                // If the key doesn't exist in eventsByMonth, create it as an empty array
                if (!eventsByMonth[monthKey]) {
                    eventsByMonth[monthKey] = [];
                }

                // Add the event to the respective month's array
                eventsByMonth[monthKey].push(event);
            });

        }
        return eventsByMonth;
    }



    const initiateAppointmentListClinicsCallAddLocation = (refreshList = false, search = null) => {
        let newPage = appointmentClinicsListPageAddLocation + 1;
        if (refreshList) {
            newPage = 1;

        } else if (totalAppointmentClinicsListCountAddLocation <= loadedAppointmentClinicsListCountAddLocation) {
            return;
        }

        // let calendarStartDate = moment().format("YYYY-MM-DD")

        initGetAvailableClinicsListData({
            startDate: calenderDisplayDate,
            endDate: calenderDisplayDate,
            page: newPage,
            limit: 15,
            includeUnAvailable: true,
            isFromAddLocation: true,
            clinicIds: locationId
        })
    }

    const afterLocationUpdateHandler = (data) => {

        // dispatch({
        //     type: CLEAR_APPOINTMENT_LIST_CLINICS
        // })
        // initiateAppointmentListClinicsCall(true)

        // if (isAddLocationOpen) {
        //     dispatch({
        //         type: CLEAR_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION
        //     })
        //     initiateAppointmentListClinicsCallAddLocation(true)
        // }

        dispatch({
            type: UPDATE_CLINIC_LIST_DATA,
            payload: { data }
        })
        setIsLocationHoursUpdateOpen(false)

    }

    const appointmentListDataNew = categorizeByMonth(appointmentListData, "calender_date", true, "DD-MM-YYYY")
    const appointmentClinicsListNew = categorizeByMonth(appointmentClinicsList, "availableDate", false, "YYYY-MM-DD")

    console.log(appointmentListDataNew, "appointmentListDataNew")

    console.log('appointmentListDataNew', appointmentClinicsListNew, appointmentClinicsList)

    console.log("testit", loadedAppointmentClinicsListCount, loadedAppointmentClinicsListCount, totalAppointmentClinicsListCount)
    console.log(getAvailableClinicsListQueryData?.variables?.startDate, checkStartDateForLoading, "aeee", getAvailableClinicsListQueryData.loading, appointmentClinicsList?.length)
    return (
        <>
            <div className='list-view-calendar-wrapper CustomeAppointment'>
                <div className='appointment-list-content-container list-view-calendar' >
                    <div className='AppointmentTitleWrapper date-block-out-container'>
                        <h1>{`${totalAppointments} appointment${totalAppointments > 1 ? "s" : ""}`}</h1>
                        {<p id="list-view" onClick={() => {
                            if (viewCalendarState == "timeGridDay") {
                                setIsAddLocationOpen(true)
                                setCalenderDisplayDate(showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"))
                            }
                            else {
                                setIsOpenCalendar(true)
                            }
                        }}>Add hours</p>
                        }
                        {isUpdateWorkingAvailabilityTooltip && <Tooltip targetId={"#list-view"} className="list-view-tooltip" place={"bottom-end"} style={{ borderRadius: "20px", background: "#000" }} toolTipContent={<ListViewToolTipContent btnLabel={"Got it!"} btnClassName={"tooltip-btn w-50"} toolTipTitle={"NEW: Update working availability."} tooltipRef={tooltipViewWorkingHoursAvailibilityRef} onClickCallback={tooltipClickHandler} isLoading={isLoading} />} tooltipRef={tooltipViewWorkingHoursAvailibilityRef} />}

                    </div>

                    <div id="scrollableDivLocation" ref={scrollRef} style={{ height: dimensions.height - headerNavbar - headerCalendar - appointmentTitleHeight - 10 + 'px', width: "100%", overflowY: "scroll" }}>
                        {/* location listing  */}
                        {

                            <InfiniteScroll
                                dataLength={loadedAppointmentClinicsListCount}
                                next={() => {
                                    initiateAppointmentListClinicsCall(false)
                                }}
                                hasMore={loadedAppointmentClinicsListCount < totalAppointmentClinicsListCount}
                                scrollableTarget="scrollableDivLocation"
                                // ref={scrollRef}
                                className='location-infinite-scroll'
                            >
                                {appointmentClinicsList?.length != 0 ?
                                    Object.entries(appointmentClinicsListNew).map(([key, items]) => {
                                        return <>
                                            <h4 className='text-start mt-4'>{key}</h4>
                                            {items?.map((item, index) => {
                                                return <div className='appointment-location-wrapper mt-3' onClick={() => {
                                                    setIsLocationHoursUpdateOpen(true)
                                                    setSelectedLocationData(item)
                                                    locationGetHoursApiCallHandler(item?.availableDate, item?.clinicId)
                                                    setCalenderDisplayDate(item?.availableDate)
                                                }}>
                                                    <div className='beforeBorder' style={{ background: `${item?.clinicColor}` }} />

                                                    <h2>{item?.clinicName}</h2>
                                                    <div className='location-timing-wrapper' >
                                                        <p>{`${moment(item?.startTime, "HH:mm:ss").format("HH:mm")} – ${moment(item?.endTime, "HH:mm:ss").format("HH:mm")}`}</p>
                                                    </div>
                                                </div>
                                            })}
                                        </>
                                    })
                                    : getAvailableClinicsListQueryData.loading
                                        || checkStartDateForLoading || (totalAppointmentClinicsListCount <= 0 && totalAppointments <= 0)
                                        ?
                                        null :

                                        // <div className='NoEventData'>
                                        //     <h1>No location available</h1>
                                        // </div>
                                        < div className='no-appointment-data-container'>
                                            <div className='no-location-img-wrapper'>
                                                <img src={noLocation} alt="no location" />
                                            </div>
                                            <h1>No locations</h1>
                                            <p>No location available for now!</p>
                                        </div>
                                }
                                {
                                    getAvailableClinicsListQueryData.loading ?
                                        <div className='AppointmentListSkeleton'>
                                            <SkeletonLoader type="appointment-list-clinic-list-skeleton" />
                                        </div>
                                        : null
                                }
                            </InfiniteScroll>
                        }

                        {
                            // <div id="scrollableDiv" style={{ height: "100vh", width: "100%", overflowX: "scroll" }}>

                            !(loadedAppointmentClinicsListCount < totalAppointmentClinicsListCount) &&
                            <InfiniteScroll
                                dataLength={loadedAppointmentCount}
                                // style={{ display: !loadedAppointmentClinicsListCount && !(loadedAppointmentClinicsListCount < totalAppointmentClinicsListCount) ? 'auto' : 'np' }}
                                next={() => {
                                    initiateAppointmentListCall(false)
                                }}
                                hasMore={loadedAppointmentCount < totalAppointmentCount}
                                scrollableTarget="scrollableDivLocation"
                            >
                                <div className="location-list-main-container">
                                    {/* // Object.entries(appointmentListDataNew).map(([key, items]) => { */}
                                    {

                                        appointmentListData?.length != 0 ? Object.entries(appointmentListDataNew).map(([key, items]) => {
                                            return <>
                                                {appointmentListDataNew[key]?.length > 0 &&
                                                    ((key == "Morning" || key == "Afternoon" || key == "Evening") ?

                                                        <div className='me-auto mt-4 d-flex align-items-center'>
                                                            {
                                                                key == "Morning" ? <MorningIcon /> : key == "Afternoon" ? <AfterNoonIcon /> : <EveningIcon />

                                                            }
                                                            <h4 className='ms-2'>{key}</h4>
                                                        </div> :

                                                        <h4 className='me-auto mt-4'>{key}</h4>)}
                                                {items.map((item, index) => (
                                                    <>
                                                        {
                                                            item.type == "Blockout" ?
                                                                <>

                                                                    <AppointmentListCard onClickCallback={onBlockOutCardClickHandler} data={item} type="Blockout" />
                                                                </>
                                                                :
                                                                <>
                                                                    <AppointmentListCard onClickCallback={onAppointmentClickHandler} data={item} type="Appointment" />
                                                                </>
                                                        }

                                                    </>

                                                ))}
                                            </>
                                        }) :
                                            getAvailableClinicsListQueryData.loading || appointmentListQueryData.loading || checkStartDateForLoading ? null :

                                                <div className='no-appointment-data-container'>
                                                    {/* <h1>Events yet to be created.</h1> */}
                                                    <img src={noAppointmentList} alt="no appointment" />
                                                    <h1>No appointments</h1>
                                                    <p>Your schedule is clear. Time to book some clients!</p>
                                                    <div className='new-appointment-btn'>
                                                        <StepButton
                                                            label={'Share booking link'}
                                                            gray={true}
                                                            onClick={() => navigate(getShareBookingLinkRoute())}
                                                        />
                                                    </div>
                                                    <div className='new-appointment-btn mt-3'>
                                                        <StepButton
                                                            label={'New appointment'}
                                                            blue={true}
                                                            onClick={() => {
                                                                let objectFromArrayOfObjects = {};

                                                                if (checkedLocationData?.length == 1) {

                                                                    objectFromArrayOfObjects = Object.assign({}, ...checkedLocationData);
                                                                }
                                                                else {
                                                                    objectFromArrayOfObjects = []
                                                                }

                                                                let data = {
                                                                    fromDayView: viewCalendarState,
                                                                    date: showSelectedDate,
                                                                    locationData: objectFromArrayOfObjects
                                                                }
                                                                dispatch({
                                                                    type: SAVE_CALENDAR_DAILYVIEW_DATA,
                                                                    payload: data,
                                                                });
                                                                navigate(getNewAppointmentRoute())
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                    }

                                    {
                                        appointmentListQueryData.loading ?
                                            <div className='AppointmentListSkeleton'>
                                                <SkeletonLoader type="appointment" isClient={true} />
                                            </div>
                                            : null
                                    }

                                </div >
                            </InfiniteScroll>
                            // </div>
                        }
                    </div>
                </div>

                {
                    showConsentPopup ? <ConsentActionPopup confirmationPopupShow={confirmationPopupShow} height={"280px"} setConfirmationPopupShow={setConfirmationPopupShow} setShowConsentPopup={setShowConsentPopup} setCompliancePopupShow={setCompliancePopupShow} compliancePopupShow={compliancePopupShow} elementId={deleteDataByAppointmentId} fromAppointmentListNavigation={false} clickedDate={clickedDate} getAppointmentData={getAppointmentList} /> : null
                }

                {/* DELETE BLOCK OUT MODAL */}
                <CustomModal
                    type={"common-width"}
                    className={"ConfirmationDeleteModal"}
                    modalBody={
                        <>
                            <div className="delete-modal-body">
                                <h1>Are you sure?</h1>
                                <p>you want to delete this block out time? This action can not be reversed?</p>
                                <div className="Delete-model-Btn">
                                    <div className="delete-button-modal">
                                        <StepButton red={true} label={"Delete"} isLoading={deleteBlockTimeMutationData?.loading} onClick={handleDeleteBlockOut} />
                                    </div>
                                    {/* <div className="cancle-button-modal">
                                        <StepButton gray={true} onClick={() => setIsDelete(false)} label={"Cancel"} />
                                    </div> */}
                                </div>
                            </div>
                        </>
                    }
                    modalOpen={isDelete}
                    setModalOpen={setIsDelete}
                    setIsOpenMobileCalendar={setIsDelete}
                />

                {/* add location popup */}
                <OffCanvasModal
                    isOpen={isAddLocationOpen}
                    onHide={() => {
                        setIsAddLocationOpen(false)
                        // dispatch({ type: CLEAR_APPOINTMENT_DOCUMENT_TAB_DATA })
                        // dispatch({ type: CLEAR_APPOINTMENT_MANAGE_TAB_DATA })

                    }}
                    className="custom-sidebar-width-30 customeModelAppointment customeModelAppointmentCanvas"
                    placement={"end"}
                    isOffCanvasHeader={false}
                    grayHeader={true}
                    body={
                        <>
                            <AddLocationListView setIsAddLocationOpen={setIsAddLocationOpen} setIsLocationHoursUpdateOpen={setIsLocationHoursUpdateOpen} getLocationWorkingHoursApiCall={locationGetHoursApiCallHandler} initiateAppointmentListClinicsCallAddLocation={initiateAppointmentListClinicsCallAddLocation} getAvailableClinicsListQueryData={getAvailableClinicsListQueryData} setSelectedLocationData={setSelectedLocationData} selectedDate={calenderDisplayDate} />
                        </>
                    }
                />

                {/* location update hours popup */}
                <OffCanvasModal
                    isOpen={isLocationHoursUpdateOpen}
                    onHide={() => {
                        setIsLocationHoursUpdateOpen(false)
                        // dispatch({ type: CLEAR_APPOINTMENT_DOCUMENT_TAB_DATA })
                        // dispatch({ type: CLEAR_APPOINTMENT_MANAGE_TAB_DATA })

                    }}
                    className="custom-sidebar-width-30 custommodalupdatehours"
                    placement={"end"}
                    isOffCanvasHeader={false}
                    grayHeader={true}
                    body={
                        <>
                            {getOneTimeOffQueryData?.loading ?
                                <SkeletonLoader type={"update-location-availability"} />
                                :
                                <HoursAndBreakAdded
                                    // calenderDisplayDate={selectedLocationData?.availableDate ? selectedLocationData?.availableDate : showSelectedDate ? moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                                    calenderDisplayDate={calenderDisplayDate}
                                    hoursData={hoursData}
                                    setHoursData={setHoursData}
                                    breaks={breaks}
                                    setBreaks={setBreaks}
                                    isFromListView={true}
                                    setIsLocationHoursUpdateOpen={setIsLocationHoursUpdateOpen}
                                    clinicId={selectedLocationData?.clinicId}
                                    afterSaveCallback={afterLocationUpdateHandler}
                                    clinicName={selectedLocationData?.clinicName}
                                    clinicColor={selectedLocationData?.clinicColor}
                                />
                            }
                        </>
                    }
                />


                {/* appointment overview popup */}

                <CustomModal
                    modalOpen={isAppointmentMenuOpen}
                    // modalOpen={true}
                    modaltitle={type == "Appointment" ? "Manage appointment" : "Blockout"}
                    setModalOpen={setIsAppointmentMenuOpen}
                    className="width-30 customeWidth customeModelAppointment menudata-container"
                    modalBody={
                        <>
                            <AppointmentViewPopup handleMenuClick={handleMenuClick} isFilled={isFilled} type={type} paymentTagData={paymentTagData} consentFormTagData={consentFormTagData} />
                        </>

                    }
                />

                   {/* payment option popup */}

    <CustomModal 
        type={"common-width"}
        className={"paymentpopup"}
        modalOpen={isPaymentPopUpOpen}
        setModalOpen={setIsPaymentPopUpOpen}
        modalBody={
          <>
            <div className='paymentPopUpWrapper'>
               <h1>Would you like to send a payment link for the value of
              
               £{paymentPopUpPrice} to { paymentPopClientName}?</h1>
            <div>
                  <div className='paymentPopBtn'> 
                    <StepButton blue={true}  label={"Yes"} onClick={()=>handlePaymentResend()} isLoading={resendBookingDetailsMutationData.loading} />
                    <StepButton gray={true} label={"No"} onClick={()=>setIsPaymentPopUpOpen(false)} />
                  </div>
               </div>
            </div>
          </>
        }
      />


      {/* consentForm menu option */}
      <CustomModal
             modalOpen={isFormsMenuOptionsOpen}
            // modalOpen={true}
              modaltitle={"Manage forms"}
              setModalOpen={setIsFormsMenuOptionsOpen}
              className="width-30 customeWidth customeModelAppointment menudata-container"
              modalBody={
              <>
              <ClientFormsViewPopup handleMenuClick={handleConsentFormClick} isFilled={isFilled} active={consentActive}  />
              </>
              }
             />


                       <OffCanvasModal
                            isOffCanvasHeader={false}
                            isOpen={isPrescriberModalOpen}
                            placement={"end"}
                            className={"maxCanvasWidth508 SelectPrescriberListModel"}
                            onHide={() => setIsPrescriberModalOpen(false)}
                            // onHide={() => { setIsClientPeofile(false); setIsClientAppointment(false); setActive("upcoming") }}
                            body={<PrescriberList sendClickHandler={sendClickHandler} setIsPrescriberModalOpen={setIsPrescriberModalOpen} sendConsentFormOrMedicalFormToPrescriberQueryData={sendConsentFormOrMedicalFormToPrescriberQueryData} prescriberId={prescriberId} />}
                        />


                        {/* prescription list component */}
                        <OffCanvasModal
                            isOffCanvasHeader={false}
                            isOpen={isPrescriptionModalOpen}
                            placement={"end"}
                            className={"maxCanvasWidth508 SelectPrescriptionModel"}
                            onHide={() => setIsPrescriptionModalOpen(false)}
                            // onHide={() => { setIsClientPeofile(false); setIsClientAppointment(false); setActive("upcoming") }}
                            body={<PrescriptionList sendtoPrescriberHandler={sendtoPrescriberHandler} setIsPrescriberModalOpen={setIsPrescriberModalOpen} updatePrescriptionAttachedFormQueryData={updatePrescriptionAttachedFormQueryData} prescriberId={prescriberId} clientId={clientId} setIsPrescriptionModalOpen={setIsPrescriptionModalOpen} />}
                        />



                        {/* no pharmacy registration popup */}
                        <CustomModal
                            modalOpen={isNoPharmacyRegisterModalOpen}
                            setModalOpen={setIsNoPharmacyRegisterModalOpen}
                            type={"common-width"}
                            className={'customeNoPharmacyRegisterModel'}
                            modalBody={
                                <>
                                    <div className="share-booking-link-all-clients-confimration">
                                        <p>
                                            Currently you are not register with any pharmacy. Would you like to connect?
                                        </p>
                                        <div className="btnWrapper">
                                            <StepButton label={"Yes"} onClick={() => {
                                                window.location.href = frontendURL + "/prescriber-registration";
                                                setIsNoPharmacyRegisterModalOpen(false)
                                            }} blue={true} />

                                            <StepButton gray={true} label={"No"} onClick={() => {
                                                setIsNoPharmacyRegisterModalOpen(false)
                                            }} />
                                        </div>
                                    </div>
                                </>
                            }
                        />

                {/* calendar for update availability */}
                <CustomModal
                    modalOpen={isOpenCalendar}
                    setModalOpen={setIsOpenCalendar}
                    type={"common-width"}
                    modaltitle={"Select date"}
                    // modalCloseCallback={closeModalHandler}
                    className={'update-availability-calendar'}
                    modalBody={
                        <>
                            <UpdateWorkingAvailabilityCalendar
                                setIsOpenCalendar={setIsOpenCalendar}
                                showSelectedDate={showSelectedDate ? showSelectedDate : moment().format("dddd, Do MMMM YYYY")}
                                viewCalendarState={viewCalendarState}
                                isThreeDaysView={isThreeDaysView}
                                setCalenderDisplayDate={setCalenderDisplayDate}
                                setIsAddLocationOpen={setIsAddLocationOpen}
                            />
                        </>
                    }
                />
            </div>
        </>
    )
}

export default ListViewCalender