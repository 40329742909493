import React from 'react'
import CustomModal from '../../modal/CustomModal'
import "./stokiest-modal.scss"
import StepButton from '../../common/StepButton'

const StokiestModal = ({isOpen,setIsOpen,message}) => {
    const handleRedirect = () =>{
        window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/pharmacy/list"
    }
  return (
    <>
        <CustomModal 
            setModalOpen={setIsOpen}
            modalOpen={isOpen}
            modalBody={
                <div className='stokiest-modal-container'>
                <h1>Pharmacy Registration</h1>
                <p>{message}</p>
                <StepButton blue={true} label={"Go to stockist"} onClick={() => {
                    handleRedirect()
                }} />
            </div>
            }
        />
    </>
  )
}

export default StokiestModal