import React, { useState } from 'react'
import './add-desc-main.scss'
import backArrow from '../../../images/back_arrow.svg'
import closeIcon from '../../../images/close_icon.svg'
import { Progress } from 'reactstrap'
import StepButton from '../../common/StepButton'
import { useNavigate } from 'react-router'

const AddDescription = () => {
    const navigate = useNavigate();
    const [text, setText] = useState('')

    const handleChangeText = (e) => {
        const newText = e.target.value;
        setText(newText);
    }

    const charCount = text.length;

    const handleBack = () => {
        navigate('/add-new-clinic')
    }

    const handleGoToDashBoard = () => {
        navigate('/')
    }

    return (
        <>
            <div className='container'>
                <div className='header_icon mt-5'>
                    <img src={backArrow} alt='backArrow' onClick={handleBack} />
                    <Progress className="pregress_bar" value={25} />
                    <img src={closeIcon} alt='closeIcon' onClick={handleGoToDashBoard} />
                </div>
                <div className='add_desc_main'>
                    <h1>Add a description</h1>
                    <p>Add a name for your location, this is for your reference only. </p>
                    <div className='add_desc_input'>
                        <span className='input_div'>
                            <textarea placeholder='Add your text..' onChange={handleChangeText} />
                        </span>
                        <span>{charCount} / 500</span>
                    </div>
                    <div className='mt-4 step_btn'>
                        <StepButton label={'Continue'} blue={true} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDescription
