import React, { useEffect } from 'react'
import './add-notes.scss'
import StepButton from './StepButton'
import { CreateNote } from '../redux/actions/client/CreateNote'
import { toast } from 'react-toastify'
import { GetClientDetails } from '../redux/actions/client/GetClientDetails'
import { GetClientNotes } from '../redux/actions/client/GetClientNote'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAR_APPOINTMENT_NOTE, CLEAR_CLIENT_DETAILS, CLEAR_CLIENT_NOTES } from '../redux/constants'
import AppointmentToast from '../new-appointment/AppointmentToast'
import showToast from './CustomToast/CustomToast'
import { GetAppointmentNotes } from '../redux/actions/appointments/GetAppointmentNotes'
import { CreateAppointmentNote } from '../redux/actions/appointments/CreateAppointmentNote'
import { GetAppointmentDetailsForDocumentTabAction } from '../redux/actions/appointments/GetAppointmentDetails'

const AddNotes = ({ setIsNoteAdded, setNotes, notes, clientId, setIsNoteModal, isClient = true, appointmentId }) => {
  const dispatch = useDispatch()
  //Get client notes from client id
  const { initGetClientNotes } = GetClientNotes()
  const { initGetAppointmentNotes } = GetAppointmentNotes()

  useEffect(() => {
    if (clientId !== undefined && clientId !== null) {
      // dispatch({
      //   type:CLEAR_CLIENT_DETAILS,
      // })
      initGetClientNotes({
        clientId: clientId
      })
    }
  }, [clientId])

  useEffect(() => {
    if (appointmentId !== undefined && appointmentId !== null) {
      // dispatch({
      //   type:CLEAR_CLIENT_DETAILS,
      // })
      initGetAppointmentNotes({
        appointmentId: appointmentId
      })
    }
  }, [appointmentId])



  //Reducer to get client note
  const clientNotes = useSelector(state => state?.clientNoteReducer?.clientNote?.clientNote)
  const appointmentNotes = useSelector(state => state?.appointmentNoteReducer?.appointmentNote?.appointmentNote);

  console.log("clientNotes", clientNotes)

  //Handle NoteData Change
  const handleChange = (e) => {
    setNotes(e.target.value)
  }

  useEffect(() => {
    if (clientNotes !== undefined) {
      setNotes(clientNotes)
    }
  }, [clientNotes])

  useEffect(() => {
    if (appointmentNotes !== undefined) {
      setNotes(appointmentNotes)
    }
  }, [appointmentNotes])



  //Get Client Details
  const { clientDetailsQueryData, initGetClientDetails } = GetClientDetails()

  //Create Note Mutation
  const { initiateCreateNote, createNoteMutationData } = CreateNote();



  const { initiateCreateAppointmentNote, createAppointmentNoteMutationData } = CreateAppointmentNote();
  const { initGetAppointmentDetailsForDocumentTabData } = GetAppointmentDetailsForDocumentTabAction()




  //Handle Submit Note Data
  const handleSubmitData = () => {
    initiateCreateNote({
      clientId: clientId,
      clientNote: notes.toString()
    }, (resNote) => {
      if (resNote.success) {
        initGetClientDetails({
          clientId: clientId
        })
        // dispatch({
        //   type: CLEAR_CLIENT_DETAILS,
        // })
        showToast("success", resNote?.message, "", false, undefined, undefined, {toastId: 'create-note-success-toast'})
        setIsNoteAdded(true)
        setIsNoteModal(false)
        dispatch({
          type: CLEAR_CLIENT_NOTES
        })

      } else {
        showToast("error", resNote?.message, "", false, undefined, undefined, {toastId: 'create-note-error-toast'})
        setIsNoteAdded(false)
        setIsNoteModal(true)
      }
    }, (errNote) => {
      console.log("error on create note", errNote)
    })
  }



  //Handle save appointment Note Data
  const handleAppointmentData = () => {
    initiateCreateAppointmentNote({
      appointmentId: appointmentId,
      appointmentNote: notes.toString()
    }, (resNote) => {
      if (resNote.success) {

        initGetAppointmentDetailsForDocumentTabData({
          appointmentId: appointmentId
        })
        // dispatch({
        //   type: CLEAR_CLIENT_DETAILS,
        // })
        showToast("success", resNote?.message, "", false, undefined, undefined, {toastId: 'create-note-app-success-toast'})
        // toast.success(resNote?.message)
        setIsNoteAdded(true)
        setIsNoteModal(false)
        dispatch({
          type: CLEAR_APPOINTMENT_NOTE
        })

      } else {
        showToast("error", resNote?.message, "", false, undefined, undefined, {toastId: 'create-note-app-error-toast'})
        setIsNoteAdded(false)
        setIsNoteModal(true)
      }
    }, (errNote) => {
      console.log("error on create note", errNote)
    })
  }

  return (
    <div className='notes-container'>
      {/* <h1 className='mb-3'>Add a note</h1> */}
      {isClient && <p className='my-2'>Client notes</p>}
      <textarea onChange={handleChange} value={notes} className={`notes-textarea ${!isClient ? "mt-4" : ""}`} rows={10} placeholder={isClient ? "Your client won't see these notes; they're just for you." : "Add a note"} />
      <StepButton label={"Save"} isLoading={isClient ? createNoteMutationData?.loading : createAppointmentNoteMutationData?.loading} onClick={() => isClient ? handleSubmitData() : handleAppointmentData()} disabled={notes?.length == 0} blue={true} />
    </div>
  )
}

export default AddNotes