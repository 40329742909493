import React from 'react'
import signImg from "../../images/client/sign.svg"
import './walk-in-main.scss'

const PassTheClientPopUp = () => {
    return (
        <>
            <div className="popUp-card-main">
                <div className='img-div'>
                    <img src={signImg} />
                </div>
                <div className='popUp-card-content'>
                    <h3>Pass to your client </h3>
                    <p>Hand over your device to your client to enable them to complete the consent form. </p>
                </div>
            </div>
        </>
    )
}

export default PassTheClientPopUp
