import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { CHECK_WEBSITE_DOMAIN_RESPONSE, GET_WEBSITE_DETAILS } from "../../constants";


//1 date-api
const GET_WEBSITE_DETAILS_QUERY = gql`
query GetWebsiteDetails {
  getWebsiteDetails {
    success
    message
    data {
      first_name
      last_name
      business_name
      website_url_slug
      headline
      about
      business_contact
      business_email
      business_hours_display
      primary_color
      secondary_color
      tertiary_color
      terms_and_conditions
      current_step
      facebook
      instagram
      whatsapp
      logo_image_url
      cover_photo_image_url
      is_active
      can_publish
      stripe_onboarding
      stripe_onboarding_url
      has_added_all_information
      created_from
      website_build_type
      website_build_payment
      welcome_text
    }
  }
}
`

export const GetWebsiteDetailsAction = () => {
  const dispatch = useDispatch()
  const [getWebsiteDetailsResponseFunction, getWebsiteDetailsReponseData] = useLazyQuery(
    GET_WEBSITE_DETAILS_QUERY,
    {
      // fetchPolicy: "cache-and-network",
      // nextFetchPolicy: "cache-and-network",
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted: ({ getWebsiteDetails: responseData }) => {
        console.log("responseData", responseData, "data")
        if (responseData?.success)
          dispatch({
            type: GET_WEBSITE_DETAILS,
            payload: responseData
          })
      }
    }
  )
  //this will be called from components
  const GetWebsiteDetailsResponseData = getWebsiteDetailsReponseData //variable

  const initGetWebsiteDataResponseFunction = (data) => {

    getWebsiteDetailsResponseFunction({
      variables: data
    })
  }
  return { initGetWebsiteDataResponseFunction, GetWebsiteDetailsResponseData }
}

