import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const ONBOARD_USER_TO_FACES_AI_MUTATION = gql`
mutation OnboardUserToGoHighLevel($password: String!, $newPassword: String) {
    onboardUserToGoHighLevel(password: $password, new_password: $newPassword) {
      success
      message
      data {
        location_id
        
        isPasswordWeak
      }
    }
  }
`;


export const OnboardUserToFacesAiAction = () => {
    const dispatch = useDispatch();
    const [
        OnboardUserToFacesAiResponseFunction,
        OnboardUserToFacesAiResponseData,
    ] = useMutation(ONBOARD_USER_TO_FACES_AI_MUTATION, {
        
    })

    //this will be called from components
    const OnboardUserToFacesAiQueryData = OnboardUserToFacesAiResponseData; //variable

    const initOnboardUserToFacesAiData = (data, successCallback, errorCallback) => {
        OnboardUserToFacesAiResponseFunction({
            variables: data,
        }).then(({ data: { onboardUserToGoHighLevel: responseData } }) => {
            if (responseData.success) {
                if (successCallback){
                    successCallback(responseData)
                }
            }
            else {
                if (errorCallback)
                    errorCallback(responseData)
            }
        }).catch(() => {

            if (errorCallback)
                errorCallback("Something went wrong")
        })
    }

    return { OnboardUserToFacesAiQueryData, initOnboardUserToFacesAiData };
};