import { gql, useMutation } from "@apollo/client";

const SAVE_USER_ADDRESS_MUTATION = gql`
mutation SaveUserAddress(
  $addressId: Int, 
  $firstName: String, 
  $lastName: String, 
  $houseNo: String, 
  $streetName: String, 
  $city: String, 
  $postcode: String, 
  $country: String, 
  $phoneNo: String, 
  $phoneCountry: String, 
  $isBillingDefault: Boolean, 
  $isShippingDefault: Boolean
) {
  saveUserAddress(
    addressId: $addressId, 
    firstName: $firstName, 
    lastName: $lastName, 
    houseNo: $houseNo, 
    streetName: $streetName, 
    city: $city, 
    postcode: $postcode, 
    country: $country, 
    phoneNo: $phoneNo, 
    phoneCountry: $phoneCountry, 
    isBillingDefault: $isBillingDefault, 
    isShippingDefault: $isShippingDefault
  ) {
    success
    message
  }
}
`;

export const useSaveUserAddressMutation = () => {
  const [saveUserAddress, { data, loading, error }] = useMutation(SAVE_USER_ADDRESS_MUTATION);

  const initiateSaveUserAddress = (variables, successCallback, errorCallback) => {
    saveUserAddress({ variables })
      .then(({ data: { saveUserAddress: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  return { initiateSaveUserAddress, data, loading, error };
};
