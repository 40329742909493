import React, { useEffect, useRef, useState } from "react"
import "../forgot-email/forgot-email-component.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import FinaceHeader from "../../finaceHeader"
import { ForgotePasswordForFinance } from "../../redux/actions/pay-later/forgotePasswordForFinance"
import { useNavigate } from "react-router"
import { STORE_USER_EMAIL } from "../../redux/constants";
import { useDispatch, useSelector } from "react-redux"
import { LoginForFinanceUser } from "../../redux/actions/pay-later/loginForFinanceUser"
import CustomModal from "../../modal/CustomModal"
import RegisterWithFinancePopup from "../../payl8r/RegisterWithFinancePopup"
import Cookies from "js-cookie"

const WelcomeBack = () => {
    const inputRef = useRef(null);
    const [formData, setFormData] = useState({
        password: '',
    });
    const [addEmailIdPostError, setAddEmailIdPostError] = useState("");
    const [showPopUp, setShowPopup] = useState(true);
    const [appliedForFinance, setAppliedForFinance] = useState(false);

    // const {  initiateforgotePasswordForFinance, forgotePasswordForFinanceData  } = ForgotePasswordForFinance();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { initiateloginForFinanceUser, loginForFinanceUserData } = LoginForFinanceUser();
    const email = useSelector(state => state?.financeLoanRequestEmailReducer?.userEmailForFinance)
    console.log("email======>?>?>>", email)

    useEffect(() => {
        if (!email) {
            navigate("/forgot-password")
        }
    }, [email])

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        // validateInput(name, value);
    };

    // function isValidEmail(email) {
    //     const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    //     return emailPattern.test(email);
    // }

    // const validateInput = (name, value) => {
    //     // alert(name + " " + value)
    //     switch (name) {
    //         case "emailId":
    //             if (!value || !isValidEmail(value)) {
    //                 setAddEmailIdPostError("Valid email is required");
    //             } else {
    //                 setAddEmailIdPostError("");
    //             }
    //             break;
    //     }
    // }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.password !== '') {
            initiateloginForFinanceUser({
                email: email,
                password: formData.password,
                appliedForFinance: false
            },
                (res) => {
                    if (res?.data?.is_user_exist && res?.data?.user_email_unverified) {
                        navigate("/verify-email")
                    } else if (res?.success && res?.data?.is_user_exist && !res?.data?.user_email_unverified && !res?.data?.user_finance_requested) {
                        setShowPopup(true);
                    } else if (res?.data?.token) {
                        Cookies.set('faces_access_token', res.data.token, { expires: 7 });
                        navigate("/revenue-dashboard")
                    }
                },
                (err) => {


                    if (err?.data?.is_user_exist && err?.data?.user_email_unverified) {
                        navigate("/verify-email")
                    } else if (err?.success && err?.data?.is_user_exist && !err?.data?.user_email_unverified && !err?.data?.user_finance_requested) {
                        setShowPopup(true);
                    }
                }
            )
            setAddEmailIdPostError("")
            // alert('Email Address submitted: ' + formData.emailId);
        } else {
            setAddEmailIdPostError("please add Password Address!")
            return
        }
        // Add your registration logic here
    };

    const initLoginFunction = (applied) => {
        if (applied) {
            setAppliedForFinance(true);
        }
        initiateloginForFinanceUser({
            email: email,
            password: formData.password,
            appliedForFinance: applied
        },
            (res) => {
                if (res?.data?.is_user_exist && res?.data?.user_email_unverified) {
                    navigate("/verify-email")
                } else if (res?.data?.is_user_exist && !res?.data?.user_email_unverified && !res?.data?.user_finance_requested) {
                    setShowPopup(true);
                } else if (res?.data?.token) {
                    Cookies.set('faces_access_token', res.data.token, { expires: 7 });
                    navigate("/revenue-dashboard")
                }
            },
            (err) => {
                dispatch({
                    type: STORE_USER_EMAIL,
                    payload: formData.emailId,
                });

                if (err?.data?.is_user_exist && err?.data?.user_email_unverified) {
                    navigate("/verify-email")
                } else if (err?.data?.is_user_exist && !err?.data?.user_email_unverified && !err?.data?.user_finance_requested) {
                    setShowPopup(true);
                }
            }
        )
    }

    return (
        <>
            <div className="mainfinancelandingpage">
                <FinaceHeader />
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-sm-12 col-md-8">
                            <h2 className="pt-5 pagenewPageTitle">Welcome back</h2>
                            <form className="login-form col-md-12" onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="password" className="py-2 form-label">
                                        Password
                                    </label>
                                    <input
                                        type="text"
                                        id="password"
                                        name="password"
                                        className="form-control"
                                        placeholder="Password"
                                        value={formData.emailId}
                                        onChange={handleChange}
                                        ref={inputRef}
                                    />
                                </div>
                                {addEmailIdPostError && <p className='text-danger'>{addEmailIdPostError}</p>}
                                <button type="submit" className="col-md-12 btn btn-primary apply-btn">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {
                <CustomModal
                    modalOpen={showPopUp}
                    // modalOpen={true}
                    type={"test"}
                    setModalOpen={setShowPopup}
                    className={'registerFinaceModel'}
                    modalBody={
                        <>
                            <RegisterWithFinancePopup
                                setShowPopup={setShowPopup}
                                initLoginFunction={initLoginFunction}
                            />
                        </>
                    }
                />
            }
        </>
    )
}

export default WelcomeBack