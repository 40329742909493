import React from 'react'
import "./HairProductCard.scss"
import PropTypes from "prop-types"

const HairProductCard = ({ icon, title, desc, price, minicard, selected, interval, onSelect, className, activeMain }) => {
    console.log(activeMain, "sss")
    return (
        <div className={`hair-product-card ${className} ${minicard ? 'minicard' : ''} ${selected ? 'selected' : ''} ${activeMain == 4 ? "align-items-center" : ""}`} onClick={onSelect}>
            <div className='left-img'>
                <img
                    src={icon}
                    alt={title}
                    // style={{
                    //     height: minicard ? "108px" : "134px",
                    //     width: minicard ? "108px" : "134px"
                    // }}
                />
            </div>
            <div className="right-content">
                <div className='d-flex flex-column gap-1 hair-product-detail'>
                    <h3 className={`hair-product-title m-0 ${minicard ? 'minicard-text' : ''}`}>{title}</h3>
                    <p className={`hair-product-desc m-0 ${minicard ? 'minicard-text' : ''}`}>{desc}</p>
                </div>
                <div className={`${activeMain == 4 ? "mobile-price-none" : "" }`}>
                    <h3 className={`hair-product-price m-0 ${minicard ? 'minicard-text' : ''}`}>£{Number(price).toFixed(2)}/{interval === 3 ? 3 : null}&nbsp;month</h3>
                </div>
            </div>
        </div>
    )
}


HairProductCard.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    minicard: PropTypes.bool,
    selected: PropTypes.bool,
    interval: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
}

export default HairProductCard