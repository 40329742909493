import { gql, useMutation } from "@apollo/client";
import showToast from "../../../common/CustomToast/CustomToast";
import { useDispatch } from "react-redux";
import { saveCheckPasswordData } from "./ClientCheckPassword";

const CHECK_PASSWORD_MUTATION = gql`
mutation CheckPassword($password: String) {
  checkPassword(password: $password) {
    success
    message
    data {
      passwordValid
    }
  }
}
`;


export const CheckPasswordAction = () => {
    const dispatch = useDispatch();
    const [
        CheckPasswordResponseFunction,
        checkPasswordResponseData,
    ] = useMutation(CHECK_PASSWORD_MUTATION)

    //this will be called from components
    const checkPasswordQueryData = checkPasswordResponseData; //variable

    const initCheckPasswordData = (data, successCallback, errorCallback) => {
        CheckPasswordResponseFunction({
            variables: data,
        }).then(({ data: { checkPassword: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
                dispatch(saveCheckPasswordData(responseData?.data?.passwordValid))
                if (responseData?.data?.passwordValid) {
                    // showToast("success", responseData?.message, "", "")
                }
                else {
                    showToast("error", responseData?.message, "", "")
                }
            }
            else {
                if (errorCallback)
                    errorCallback()
                showToast("error", responseData.message, "", "")
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
            showToast("error", "something went wrong!!!", "", "")
        })
    }

    return { checkPasswordQueryData, initCheckPasswordData };
};
