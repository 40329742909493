import React, { useEffect, useRef, useState } from "react"
import "./verify-email-component.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import FinaceHeader from "../../finaceHeader"
import { VerifyCodeSentInEmail } from "../../redux/actions/pay-later/verifyCodeSentInEmail";
import { ResendVerificationCode } from "../../redux/actions/pay-later/resendVerificationCode";
import { toast } from "react-toastify";
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import Cookies from "js-cookie";
import { getProductsGetCartCountData } from "../../redux/actions";
import StepButton from "../../common/StepButton";
import { frontendURL } from "../../../config";
import { revenueDashboardLandingPagePattern } from "../../../Routes";

const VerifyEmailComponent = () => {
    // const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
    const [addVerifyToBePostError, setAddVerifyToBePostError] = useState("")
    const { initiateVerifyCodeSentInEmail, verifyCodeSentInEmailData } = VerifyCodeSentInEmail();
    const { initiateresendVerificationCode, resendVerificationCodeData } = ResendVerificationCode();

    const email = useSelector(state => state?.financeLoanRequestEmailReducer?.userEmailForFinance)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!email) {
            navigate("/check-user")
        }
        document.querySelector(`input[name="otp1"]`).focus();
    }, [email])

    const [otp, setOtp] = useState(Array(6).fill(''));
    const [isLoginSuccess, setIsloginSuccess] = useState(false);
    const { dashboardgetCartCountData } = useSelector(state => state?.dashboardGetCartCountReducer)

    // const [otp, setOtp] = useState('');
    // const [addVerifyToBePostError, setAddVerifyToBePostError] = useState("");

    // const handleChange = (otp) => {
    //     // alert(otp)
    //     setOtp(otp)
    //     if (addVerifyToBePostError && otp.length > 0) {
    //         setAddVerifyToBePostError("");
    //     }
    // };

    const handleChange = (value, index) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (addVerifyToBePostError && otp.length > 0) {
            setAddVerifyToBePostError("");
        }
        // Optionally, focus next input on change
        if (value && index < 5) {
            const nextSibling = document.querySelector(`input[name=otp${index + 2}]`);
            if (nextSibling) {
                nextSibling.focus();
            }
        }
    };

    const handleAddVerifySubmit = (e) => {
        e.preventDefault();
        const otpString = otp.join('');


        if (otpString.length === 6) { // Check if the OTP string has 6 digits
            initiateVerifyCodeSentInEmail({
                code: otpString,
                email: email
            },
                (res) => {
                    if (res.success) {
                        dispatch(getProductsGetCartCountData());
                        Cookies.set('faces_access_token', res.data.token, { expires: 7 });
                        setIsloginSuccess(true)
                        // navigate("/revenue-dashboard")
                        // window.location.href = `${process.env.REACT_APP_WEB_BASE_LOGIN_URL}/v1/onboarding-steps`
                    }
                },
                (err) => {
                    toast.error(err.message)
                });
            setAddVerifyToBePostError("");
        } else {
            setAddVerifyToBePostError("Please complete the Verification Code!");
        }

        // if (otp.length === 6) { // Assuming a 6 digit OTP
        //     initiateVerifyCodeSentInEmail({
        //         code :otp,
        //         email : email
        //     },
        //     (res) => {
        //         if (res.success) {
        //             Cookies.set('faces_access_token', res.data.token, { expires: 7 });
        //             navigate("/revenue-dashboard")
        //         }
        //     },
        //     (err)=>{
        //         toast.error(err.message)
        //     }
        //     )
        //     setAddVerifyToBePostError("");
        //     // alert('Verification code submitted:');
        //     // Here, you can call your verify OTP function
        // }else if (otp.length !== 6) {
        //     setAddVerifyToBePostError("Please complete the Verification Code!");
        // }
    };

    const resendOtpFunction = () => {
        initiateresendVerificationCode({ email: email },
            (res) => {
                console.log(">>>>>>>>>>>>>>>>>>>>>>>", res);
                toast.success(res.message)
            },
            (err) => {
                toast.error(err.message)
            }
        )
    }
    const handleFocus = (e) => e.target.select();


    useEffect(() => {
        if (isLoginSuccess) {
            dispatch(getProductsGetCartCountData());
        }
    }, [isLoginSuccess])

    // useEffect(()=>{
    //     if(dashboardgetCartCountData != null)
    //         navigate("/revenue-dashboard")
    // },[dashboardgetCartCountData])

    useEffect(() => {
        if (dashboardgetCartCountData != null)
            window.location.href = frontendURL + revenueDashboardLandingPagePattern()
        // navigate("/revenue-dashboard")
    }, [navigate, dashboardgetCartCountData])

    return (
        <>
            <div className="mainfinancelandingpage">
                <FinaceHeader />
                <div className="container mt-5">
                    <div className="verify-email row justify-content-center">
                        <div className="col-lg-5 col-md-8 col-sm-12">
                            <h2 className="pt-5 pagenewPageTitle">Code verification</h2>
                            <label htmlFor="emailId" className=" form-label">
                                Enter code
                            </label>
                            <div className="otpInputWrapper">
                                <div>
                                    {otp.map((item, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            name={`otp${index + 1}`}
                                            value={item}
                                            onChange={(e) => handleChange(e.target.value, index)}
                                            onFocus={handleFocus}
                                            maxLength="1"
                                            style={{
                                                padding: "10px",
                                                marginRight: "5px",
                                                width: "50px",
                                                height: "50px",
                                                fontSize: "16px",
                                                textAlign: "center",
                                                borderRadius: "10px",
                                                border: "1px solid #ced4da"
                                            }}
                                        />
                                    ))}

                                </div>

                            </div >
                            {addVerifyToBePostError && <p className='text-danger error'>{addVerifyToBePostError}</p>}
                            <div className="justify-content-center rounded-circle-border my-3">
                                {/* <button type="button" className="btn btn-primary apply-btn" onClick={handleAddVerifySubmit}>Submit</button> */}
                                <StepButton
                                    onClick={handleAddVerifySubmit}
                                    blue={true}
                                    id={"ryft-pay-btn"}
                                    label={"Submit"}
                                    isLoading={verifyCodeSentInEmailData.loading}
                                />
                            </div>
                            <span className="resendBtn" onClick={resendOtpFunction} style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}>Resend code</span>
                        </div >
                    </div >
                </div >
            </div >
        </>
    )
}

export default VerifyEmailComponent;