import '../edit-main.scss';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import StepButton from '../../../common/StepButton';
import closeIcon from '../../../../images/close_icon.svg';
import {
    AddAndEditUserConsentForm,
    AfterCareFormDetails,
    ConsentFormPreview,
    CreateAndEditAfterCareForm,
} from '../../../redux/actions/walk-in-form/walkInAddFormsList';
import { useNavigate } from 'react-router';
import showToast from '../../../common/CustomToast/CustomToast';
import { editFormRoute, getEditAftercareFormRoute } from '../../../../Routes';

const CreateNewFormPopup = ({ formId, isAftercare, isEditMyform }) => {
    const navigate = useNavigate();
    const { initAddAndEditUserConsentFormData, addUserConsentFormQueryData } =
        AddAndEditUserConsentForm();
    const { initConsentFormPreview, consentFormPreviewQueryData } = ConsentFormPreview();
    const { initCreateOrUpdateAfterCareFormData, createOrUpdateAfterCareFormQueryData } =
        CreateAndEditAfterCareForm();
    const { initAfterCareFormDetails, aftercareFormDetailsQueryData } = AfterCareFormDetails();
    const consentFormDetails = useSelector(
        (state) => state?.walkInClientListReducer?.consentFormDetails
    );

    const [formName, setFormName] = useState('');
    const handleInputChange = (e) => {
        setFormName(e.target.value);
    };

    const isLoading =
        createOrUpdateAfterCareFormQueryData.loading ||
        addUserConsentFormQueryData.loading ||
        consentFormPreviewQueryData.loading ||
        aftercareFormDetailsQueryData.loading;

    useEffect(() => {
        if (formId) {
            if(isAftercare) {
                initAfterCareFormDetails(
                    { aftercareId: parseInt(formId) },
                    (data) => {
                        setFormName(data.aftercareFormName);
                    },
                    (err) => {
                        showToast('error', err.message);
                    }
                );
                return
            }
            initConsentFormPreview(
                { consentId: parseInt(formId) },
                (data) => {
                    setFormName(data.con_frm_name);
                },
                (err) => {
                    showToast('error', err.message);
                }
            );
        }
    }, [formId]);

    const handleNextStep = () => {
        if (isAftercare) {
            initCreateOrUpdateAfterCareFormData(
                {
                    createAftercareFormId: formId || null,
                    aftercareFormName: formName,
                },
                (res) => {
                    const newAftercareID = res.data.createAftercareForm.data.id;
                    console.log('create-aftercare-response', res);
                    console.log('newAftercareID', newAftercareID);
                    navigate(getEditAftercareFormRoute(newAftercareID));
                },
                (err) => {
                    showToast('error', err.message);
                }
            );
            return;
        }
        initAddAndEditUserConsentFormData(
            {
                createConsentFormId: formId || null,
                consentFormName: formName,
            },
            (data) => {
                console.log('clakjsdlkfj', data);
                const newConsentId = data.data.createConsentForm.data.con_frm_id;
                let editRoute = editFormRoute(newConsentId) + '?tab=1';
                if(isEditMyform) {
                    editRoute = editFormRoute(newConsentId) + '?tab=4';
                }
                navigate(editRoute);
                // navigate('/edit-form/' + newConsentId);
            },
            (err) => {
                showToast('error', err.message);
            }
        );
    };

    return (
        <>
            <div className="constent-list-left-popover-content">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Name form"
                    value={formName}
                    name="formName"
                    onChange={handleInputChange}
                />
                {formName.length > 0 && <button onClick={() => setFormName('')} className="img-close">
                    <img src={closeIcon} alt="img" />
                </button>}
            </div>
            <div className='btnWrapperModel'>
                <StepButton
                    disabled={formName.length === 0 || isLoading}
                    isLoading={isLoading}
                    blue={formName.length > 0}
                    label={isEditMyform && formId ? 'Edit' : 'Create new form'}
                    onClick={handleNextStep}
                />
            </div>
        </>
    );
};

export default CreateNewFormPopup;
