import { gql, useLazyQuery, useMutation } from "@apollo/client";

const GET_PHARMACY_TERMS_AND_CONDITION_QUERY = gql`
query GetPharmacyRegetrationTermsCondition {
    getPharmacyRegetrationTermsCondition {
      success
      message
      data {
        registeredPharmacies {
          pharmacyId
          pharmacyName
          pharmacyTermsCondition
        }
      }
    }
  }
`;
export const  GetPharmacyRegistrationTerms = () => {
    const [fetchTerms, { loading, error, data }] = useLazyQuery(GET_PHARMACY_TERMS_AND_CONDITION_QUERY, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: (response) => {
        // Handle the completed response here if necessary
      },
    });
  
    const initGetPharmacyTerms = () => {
      fetchTerms();
    };
  
    return { loading, error, data, initGetPharmacyTerms };
  };



const SAVE_PHARMACY_TERMS_AND_CONDITIONS_MUTATION = gql`
mutation SavePharmacyTermsAndConditions($registeredPharmaciesIds: [Int]!, $isFinance: Boolean!) {
  savePharmacyTermsAndConditions(registeredPharmaciesIds: $registeredPharmaciesIds, isFinance: $isFinance) {
    success
    message
    data {
      showPaidCard
      showPayl8trPage
    }
  }
}
`;
export const SavePharmacyTermsAndConditionsData = () => {
  const [savePharmacyTermsAndConditions, { data: savePharmacyTermsAndConditionsResponseData }] =
    useMutation(SAVE_PHARMACY_TERMS_AND_CONDITIONS_MUTATION);

  const initiateSavePharmacyTermsAndConditions = (variables, successCallback, errorCallback) => {
    savePharmacyTermsAndConditions({ variables })
      .then(({ data }) => {
        if (data.savePharmacyTermsAndConditions.success) {
          successCallback(data.savePharmacyTermsAndConditions);
        } else {
          errorCallback(data.savePharmacyTermsAndConditions);
        }
      })
      .catch((error) => {
        // Handle error
        errorCallback(error);
      });
  };

  return { initiateSavePharmacyTermsAndConditions, savePharmacyTermsAndConditionsResponseData };
};