import { CLEAR_UPDATE_OCCUPATIONS, GET_ONBOARDING_STATUS, GET_ONBOARDING_STATUS_SUCCESS, UPDATE_OCCUPATIONS, UPDATE_OCCUPATIONS_ERROR, UPDATE_OCCUPATIONS_SUCCESS } from '../../constants/index'

const initialState = {
    onBoardingStatusData: null,
    occupationData: null,
    updateOccupationFlag: false,

    onBoradingStatusDataFlag: false,

}
export const OnboardingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ONBOARDING_STATUS:
            return {
                ...state,
                onBoardingStatusData: null,
                onBoradingStatusDataFlag: true,
            }
        case GET_ONBOARDING_STATUS_SUCCESS:
            console.log('my data', action.payload.data);
            return {
                ...state,
                onBoardingStatusData: action.payload.data,
                onBoradingStatusDataFlag: false,
            }


        case UPDATE_OCCUPATIONS:
            return {
                ...state,
                updateOccupationFlag: true
            }
        case UPDATE_OCCUPATIONS_SUCCESS:
            return {
                ...state,
                updateOccupationFlag: false,
                occupationData: action.payload,
            }
        case UPDATE_OCCUPATIONS_ERROR:
            return {
                ...state,
                updateOccupationFlag: false
            }

        case CLEAR_UPDATE_OCCUPATIONS:
            return {
                ...state,
                occupationData: null
            }
        default:
            return state
    }
}  