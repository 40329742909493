import React from "react";
import { StripeOnboardingMutationAction } from "../../redux/actions/dashboard/stripeOnboardingGql";
import { toast } from 'react-toastify';
import FinanceBanner from "../../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner";
import StepButton from "../../common/StepButton";
import './PrescriberGetPaid.scss'
import { webBaseUrl } from "../../../config";


export default function PrescriberGetPaid({ onNextStep, afterSubmit,finish }) {
  const { stripeOnboardingQueryData : {
    loading: stripeOnboardingLoading
  }, initStripeOnboardingData } =
    StripeOnboardingMutationAction();
  return (
    <div>
      <div>
        <div className="Main-paid-Screen">
          <FinanceBanner
            title={"Get Paid"}
            desc={
              "We need to connect your Faces account to your bank account in order to get you paid for your prescriber services"
            }
            isBorder={true}
        currentStep={finish}
            totalSteps={4}
          />
          <div className="BtnButton mt-5">
            <StepButton
              blue={true}
              label={"Connect"}
              onClick={() => {
                // initStripeOnboardingData(
                //   {},
                //   (response) => {
                //     window.location.href =
                //       response?.data?.stripeOnboarding?.data?.account_link;
                //   },
                //   (error) => {
                //     toast.error(
                //       "Something went wrong while creating your payment link, please try again after some time!"
                //     );
                //   }
                // );

                window.location.href =
                webBaseUrl + "/user/update-profile?active-tab=payment_gateways"

                // afterSubmit();
                // onNextStep();
              }}
              // disabled={isContinue}
              // isLoading={stripeOnboardingLoading}
            />
            <StepButton
              className="OutLineBtn"
              blue={true}
              label={"Will do it later"}
              onClick={() => {
                afterSubmit();
                onNextStep();
              }}
              // disabled={isContinue}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
