import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_CLIENT_CONSENT_FORM_LIST } from "../../constants";


const GET_CLIENT_CONSENT_FORM_LIST_QUERY = gql`
query GetClientConsentFormList($clientId: Int, $appointmentId: Int, $limit: Int, $page: Int) {
  getClientConsentFormList(clientId: $clientId, appointmentId: $appointmentId, limit: $limit, page: $page) {
    success
    message
    data {
      clientId
      clientName
      clientEmail
      clientProfileImage
      vendorIsPharmacy
      consentFormRecords {
        appointmentId
        consentFormRecordId
        consentForms {
          formId
          formName
          formStatus
        }
        readableTreatmentDate
        treatmentDate
        clinicId
        clinicName
        isFilled
        treatments {
          treatmentId
          treatmentName
        }
      }
      totalForms
    }
  }
}
`;

var page;
export const GetClientConsentFormListQuery = () => {
  const dispatch = useDispatch();
  const [getClientConsentData, clientConsentData] = useLazyQuery(
    GET_CLIENT_CONSENT_FORM_LIST_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClientConsentFormList: responseData }) => {
        dispatch({
          type: GET_CLIENT_CONSENT_FORM_LIST,
          payload: responseData.data,
          page,
        });
      },
    }
  );
  const clientConsentQueryListData = clientConsentData;
  const initGetClientConsent = (data) => {
    page = data?.page ? data?.page : 1;
    getClientConsentData({
      variables: data,
    });
  };
  return { initGetClientConsent, clientConsentQueryListData };
};
