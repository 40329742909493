// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { FORWARD_SENDMAIL_ERROR, FORWARD_SENDMAIL_SUCCESS, GET_REVIEWS_DATA_ERROR, GET_REVIEWS_DATA_SUCCESS } from "../../constants";
import { toast } from "react-toastify";

const SCHEDULE_REVIEW_EMAIL_QUERY = gql`
query ScheduleReviewEmail($clientIds: [Int], $selectAllClients: Boolean) {
  scheduleReviewEmail(client_ids: $clientIds, selectAllClients: $selectAllClients) {
    success
    message
    data {
      noMessage
      id
    }
  }
}
`;

export const ScheduleReviewEmailAction = () => {
  const dispatch = useDispatch();
  const [
    scheduleReviewEmailResponseFunction,
    scheduleReviewEmailResponseData,
  ] = useLazyQuery(SCHEDULE_REVIEW_EMAIL_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ scheduleReviewEmail: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: FORWARD_SENDMAIL_SUCCESS,
          payload: responseData,
        });
        if(responseData.message) {
          toast.success(responseData.message);
        }
        console.log('dispatched ScheduleReviewEmailAction');
        // toast.success(responseData?.message)
        // GET_ONBOARDING_STATUS_SUCCESS
      } else {
        dispatch({
          type: FORWARD_SENDMAIL_ERROR,
          payload: responseData,
        })
        
        toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const scheduleReviewEmailQueryData = scheduleReviewEmailResponseData; //variable

  const initScheduleReviewEmailData = (data) => {
    scheduleReviewEmailResponseFunction({
      variables: {
        clientIds: data.clientIds || [],  // Ensure clientIds is an array
        selectAllClients: data.selectAllClients || false,  // Ensure selectAllClients is boolean
      },
    }).then((res) => {
      if (res?.data?.scheduleReviewEmail?.success) {
        console.log('res success', res);
        // successCallback(res);
      } else {
        console.log('res error', res);
        // errorCallback(res);
      }
    }).catch((err) => {
      console.log('res err', err);
      // errorCallback(err);
    });
  };

  return { scheduleReviewEmailQueryData, initScheduleReviewEmailData };
};
