import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
  AddressElement,
  PaymentMethodMessagingElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import StepButton from "../common/StepButton";
import { CompletePaymentBooking } from "../redux/actions/completePaymentBooking/CompletePaymentBooking";
import { useNavigate, useParams } from "react-router";
import { ReactComponent as CloseIcon } from '../../images/close_icon.svg';

import visaImage from '../../assets/cart/visa.png';
import masterCardImage from '../../assets/cart/mastercard-logo.png';

const CheckoutForm = ({ clientSecret, successCallback, failureCallback, setProcess, bookingId, setIsOpenPayment, stripeDataObj, stripeHeaingText, isCloseIcon, stripeChildCustomCSS }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { slug } = useParams()

  const [errorMessage, setErrorMessage] = useState(null);
  const [showLoader, setLoader] = useState(false);

  const { initiateCompletePaymentBooking, completePaymentBookingMutationData } = CompletePaymentBooking()


  const handleSubmitPayment = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    event.preventDefault();

    // if (!stripe || !elements) {
    //   // Stripe.js has not yet loaded.
    //   // Make sure to disable form submission until Stripe.js has loaded.
    //   return;
    // }

    setErrorMessage("");

    setLoader(true);
    // setProcess("Processing...")

    const { paymentIntent, error } = await stripe.confirmCardPayment(
      clientSecret,
      {
        //`Elements` instance that was used to create the Payment Element
        // elements,
        // confirmParams: {
        //   return_url: window.location.href,
        // },
        payment_method: {
          card: elements.getElement(CardElement),
        },

        return_url: window.location.href,
      }
    );

    console.log(paymentIntent, "paymentIntent", bookingId)

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      //   setErrorMessage(error.message);
      setErrorMessage(error.message);
      // setProcess("Complete Payment")
      setLoader(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      if (
        paymentIntent.status === "succeeded" ||
        paymentIntent.status === "requires_capture"
      ) {
        // setProcess("Complete Payment")
        stripe.createToken(elements.getElement(CardElement)).then(function (result) {
          if (result.error) {
            // Handle error
          } else {
            var token
            // Send the token to your server
            token = result.token;
            // successCallback(paymentIntent.id, token.id);
            setLoader(true);
            if (successCallback) {
              successCallback(paymentIntent.id)
            } else
              initiateCompletePaymentBooking({
                bookingId: bookingId,
                paymentId: paymentIntent.id,
                paymentType: 2
              }, (res) => {
                if (res.success) {
                  setLoader(false);
                  navigate(`/bookings/${slug}/success?bookingId=${bookingId}&ps=${paymentIntent.id}`)
                } else {
                  setLoader(false);
                  setErrorMessage(res.message);
                  navigate(`/bookings/${slug}/fail?bookingId=${bookingId}&ps=${paymentIntent.id}`)
                }
              }, (e) => {
                setLoader(false);
                setErrorMessage(e.message);
              })
            // Perform further processing with the token
            // e.g., save the token to the customer in your database
          }
        })
      }
    }
  };

  return (
    <>
      <div className="container" >
        {stripeDataObj !== undefined && <CloseIcon

          className="mb-5 cursor-pointer" style={{paddingTop: "10px"}}
          onClick={() =>
            setIsOpenPayment(false)}
        />}
        <div className={`${stripeDataObj !== undefined ? 'stripe-checkout-main-div' : 'w-100'}`}>


          {
            stripeDataObj !== undefined ?
              <div className='ryft-header-section pb-3'>
                <div className='detail-section'>
                  <h1>Date :</h1>
                  <p>{stripeDataObj?.date}</p>
                </div>
                <div className='detail-section'>
                  <h1> Treatment price:</h1>
                  <p>{stripeDataObj?.treatmentPrice}</p>
                </div>
                <div className='detail-section'>
                  <h1>Payable Amount:</h1>
                  <p>{stripeDataObj?.totalPrice}</p>
                </div>
              </div>
              : null
          }
          <form id='paymentForm' onSubmit={(e) => handleSubmitPayment(e)}>
            {/* <NewCard/> */}

            <CardElement
              className="checkoutcard-field mx-2"
              options={{
                hidePostalCode: true,
                style: {
                  //your style
                },
                classes: {
                  //your classname

                }

              }}
            />

            {/* <button disabled={!stripe}>Submit</button> */}
            {showLoader ? (
              <p></p>
            ) : (
              <>
                {/* <button type="submit" style={{ visibility: 'none' }} disabled={!stripe || showLoader}>
            Pay Now
          </button> */}
              </>
            )}

            <div className="statics-logos">
              <div className="statics-logos__item">
                <img src={visaImage} alt="visa" />
              </div>
              <div className="statics-logos__item">
                <img src={masterCardImage} alt="mastercard" />
              </div>

            </div>
            <StepButton type={"submit"} blue={true} label={"PAY NOW"}
              isLoading={showLoader}
            />

            {/* Show error message to your customers */}
            {errorMessage && <div className="card-invalid-text">{errorMessage}</div>}
          </form>

        </div>
      </div >
    </>
  );
};

export default CheckoutForm;
