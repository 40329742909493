import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_TREATMENTS_FOR_USER } from "../../constants/treatment-list-const/TreatmentListConst";
import { useSearchParams } from "react-router-dom";


const GET_TREATMENTS_FOR_USER_QUERY = gql`
query GetClinicTreatments($clinicId: Int!, $encryptedTreatmentId: String) {
  getClinicTreatments(clinic_id: $clinicId, encrypted_treatment_id: $encryptedTreatmentId) {
    success
    message
    data {
      treatment_DB_id
      items {
        category_name
        category_id
        treatments {
          id
          name
          amount
          image_url
          deposit
          slug
          duration
          description
          average_review_rating
          total_review_count
        }
      }
    }
  }
}
`;

var page;
export const GetTreatmentsForUser = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [getClinicTreatments, userTreatmentsData] = useLazyQuery(
    GET_TREATMENTS_FOR_USER_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClinicTreatments: responseData }) => {

        dispatch({
          type: GET_TREATMENTS_FOR_USER,
          payload: responseData.data, page
        });
        if (responseData.success) {

        } else {

        }

      },
    }
  );
  const userTreatmentsQueryData = userTreatmentsData;
  const initGetTreatmentsForUser = (data) => {

    page = data?.page ? data?.page : 1
    let encryptedTreatmentId = null
    if (searchParams.get("treatment_id"))
      encryptedTreatmentId = searchParams.get("treatment_id");
    getClinicTreatments({
      variables: { ...data, encryptedTreatmentId: encryptedTreatmentId },
    });
  };
  return { initGetTreatmentsForUser, userTreatmentsQueryData };
};

export function setUserBusinessReg(data) {
  return {
    type: GET_TREATMENTS_FOR_USER,
    payload: data,
  };
}