import React, { useState, useEffect, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import CheckMarkedPng from "../../../images/social-media-marketing/check-mark.png";
import CheckMarkedOrange from "../../../images/social-media-marketing/check-mark-orange.png";
import PurchaseBtnImg from "../../../images/social-media-marketing/purchase-arrow-btn.png";
import { GetPlumpMarketingPackagesAction } from "../../redux/actions/social-media-marketing";
import { useDispatch } from "react-redux";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { domainName } from "../../../styles";
import { webBaseUrl, facesWebBaseUrl } from "../../../config";
import Cookies from "js-cookie";

export const PlansCarousel = ({ prntFn, setEBookLinkObj, setCalendlyLink }) => {
  const dimensions = useWindowDimensions();
  console.log(dimensions.width, "dimensions");
  const dispatch = useDispatch();
  const {
    getPlumpMarketingPackagesQueryData,
    initGetPlumpMarketingPackagesData,
  } = GetPlumpMarketingPackagesAction();
  const [plans, setPlans] = useState([]);
  console.log(plans, "plans");

  const [cardTopBorder, setCardTopBorder] = useState(0);
  const [cardupper, setCardupper] = useState(0);
  const [commitmentHeight, setCommitmentHeight] = useState(0);
  console.log(commitmentHeight, "commitmentHeight");
  const [tailoredFooterHeight, setTailoredFooterHeight] = useState(0);
  const [ulHeight, setUlHeight] = useState([]);
  const [commitmentNewHeight, setCommitmentNewHeight] = useState([]);
  const commentRef = useRef(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAuthHeader, setIsAuthHeader] = useState(false);

  const setUlHeightFunction = () => {
    let ulHeighttmp = [...ulHeight];

    for (let i = 1; i <= 5; i++) {
      let el = document.getElementById(`plan_${i}`);
      if (el) {
        let ind = ulHeighttmp.findIndex((e) => e.id == `plan_${i}`);
        if (ind == -1)
          ulHeighttmp.push({ id: `plan_${i}`, height: el.offsetHeight });
      }
    }
    setUlHeight(ulHeighttmp);
  };

  const setCommentHeightFunction = () => {
    let commitmentNewtemp = [...commitmentNewHeight];

    for (let i = 1; i <= 5; i++) {
      let el = document.getElementById(`comment_${i}`);
      console.log(el, "comment");
      if (el) {
        let ind = commitmentNewtemp.findIndex((e) => e.id == `comment_${i}`);
        if (ind == -1)
          commitmentNewtemp.push({
            id: `comment_${i}`,
            height: el.offsetHeight,
          });
      }
    }

    setCommitmentNewHeight(commitmentNewtemp);
  };

  useEffect(() => {
    setCardTopBorder(
      document.getElementsByClassName("social-tailored-card-head")[0]
        ?.offsetHeight
    );
    setCardupper(
      document.getElementsByClassName("card-upper-section")[0]?.offsetHeight
    );

    setTailoredFooterHeight(
      document.getElementsByClassName("tailored-card-footer")[0]?.offsetHeight
    );

    setUlHeightFunction();
    setCommentHeightFunction();

    window.addEventListener("resize", handleResize);
  }, [cardTopBorder, cardupper, commitmentHeight, tailoredFooterHeight, plans]);

  const handleResize = () => {
    setCardTopBorder(
      document.getElementsByClassName("social-tailored-card-head")[0]
        ?.offsetHeight
    );
    setCardupper(
      document.getElementsByClassName("card-upper-section")[0]?.offsetHeight
    );
    setCommitmentHeight(
      document.getElementsByClassName("commitment")[0]?.offsetHeight
    );
    setTailoredFooterHeight(
      document.getElementsByClassName("tailored-card-footer")[0]?.offsetHeight
    );
    setUlHeightFunction();
    setCommentHeightFunction();
  };

  const clickOnPackage = (packageId) => {
    prntFn(packageId);
  };
  useEffect(() => {
    initGetPlumpMarketingPackagesData({
      baseUrl: webBaseUrl+facesWebBaseUrl,
    });
  }, []);
  useEffect(() => {
    if (getPlumpMarketingPackagesQueryData?.data) {
      setPlans(
        getPlumpMarketingPackagesQueryData.data.getPlumpMarketingPackages.data
          .packages
      );
      setCalendlyLink(getPlumpMarketingPackagesQueryData.data.getPlumpMarketingPackages.data.calandlyLink);
    }
  }, [getPlumpMarketingPackagesQueryData?.data]);
  console.log(
    getPlumpMarketingPackagesQueryData,
    "getPlumpMarketingPackagesQueryData data"
  );

  const responsiveObj = {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    700: {
      items: 2,
    },
    950: {
      items: 3,
    },
    1100: {
      items: 3.2,
    },
  };
  
  useEffect(() => {
    Cookies.get("faces_access_token") ? setIsAuthHeader(true) : setIsAuthHeader(false);      
  }, []);

  return (
    <>
      {plans?.length > 0 && (
        <OwlCarousel
          items={plans}
          className="owl-theme"
          loop={true}
          margin={20}
          nav={true}
          dots={false}
          responsiveClass={true}
          responsive={responsiveObj}
        >
          {plans.map(
            (plan, index) => (
              console.log(plan.id, "plan"),
              plan?.package_name === "E-book" && setEBookLinkObj(plan),
              (
                <div key={plan.id}>
                  {console.log(plan.is_currentplan, "plan.is_currentplan")}
                  {plan.is_currentplan && (
                    <div className="currentPlan">
                      <h6 className="text-white text-center">
                        Your Current Plan
                      </h6>
                    </div>
                  )}
                  <div className="tailored-card plumpMarketingCarousel">
                    <div className="social-tailored-card-head">
                      <h6 className="bg-primary text-white py-2 text-center">
                        {plan.is_popular && "Most Popular"}
                      </h6>
                    </div>
                    <div className="tailored-card-body">
                      <div className="card-upper-section px-3">
                        <div className="text-center">
                          <h6 className="m-0 p-0">{plan.package_name}</h6>
                          <hr className="hrline mb-1 mt-2" />
                          <h3 className="vatprice mb-0">{plan.amountText}</h3>
                          <p className="mb-2 vettext">
                            {plan.duration_text !== "" &&
                              "(" + plan.duration_text + ")"}
                          </p>
                          {(plan.duration_text !== "" ||
                            plan.amountText !== "") && (
                            <hr className="hrline mt-0" />
                          )}
                        </div>
                      </div>
                      <div className="card-lower-section">
                        {/* {(plan.commitment_text !== "" ||
                          plan.commitment_text2 !== "") && (} */}
                          <div
                            className="commitment mt-2 px-3"
                            ref={commentRef}
                            id={`comment_${plan.id}`}
                            style={{
                              height: commitmentNewHeight.find(
                                (e) => e.id == `comment_${plan.id}`
                              )
                                ? commitmentNewHeight.find(
                                    (e) => e.id == `comment_${plan.id}`
                                  ).height
                                : "100",
                            }}
                          >
                            {/* <h6>Commitment</h6> */}
                            <p className="mb-2 paragraph">
                              {plan.commitment_text}
                            </p>
                            <b className="minmobths">{plan.commitment_text2}</b>
                            <div className="heading-points">
                              {plan.package_main_points.map((point) => (
                                <h5>{point}</h5>
                              ))}
                            </div>
                          </div>
                        {/* {)} */}
                        {plan.image_url && (
                          // <li
                          //   style={{
                          //     padding: "0px 5px",
                          //     marginBottom: "10px",
                          //   }}
                          // >
                          <div
                            className="listImage"
                            style={{
                              padding: "5px",
                            }}
                          >
                            <img
                              src={plan.image_url}
                              className=""
                              alt=""
                              style={{ borderRadius: "5px" }}
                            />
                          </div>
                        )}
                        <div
                          className="listview"
                          style={{
                            height:
                              ulHeight.find((e) => e.id == `plan_${plan.id}`) &&
                              ulHeight.find((e) => e.id == `plan_${plan.id}`)
                                .height >= 347
                                ? commitmentNewHeight.find(
                                    (e) => e.id == `comment_${plan.id}`
                                  ) &&
                                  commitmentNewHeight.find(
                                    (e) => e.id == `comment_${plan.id}`
                                  ).height >= 200
                                  ? 273
                                  : commitmentNewHeight.find(
                                      (e) => e.id == `comment_${plan.id}`
                                    ) &&
                                    commitmentNewHeight.find(
                                      (e) => e.id == `comment_${plan.id}`
                                    ).height < 100
                                  ? 423
                                  : 314
                                : 385,
                          }}
                        >
                          <ul
                            className="m-0 p-0 listitems ps-3"
                            id={`plan_${plan.id}`}
                          >
                            {plan.package_points.map((packagePoint) => (
                              <li className="d-flex pointLi">
                                <img
                                  src={domainName=="faces"?CheckMarkedPng:CheckMarkedOrange}
                                  className=" img-fluid col-3"
                                  style={{ height: "15px", width: "15px" }}
                                />
                                <p className="col-9 categoty mb-1">
                                  {packagePoint}
                                </p>
                              </li>
                            ))}

                            {/* <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid col-3" style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li>
                                            <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid " style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li>
                                            <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid " style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li>
                                            <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid " style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li>
                                            <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid " style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li>
                                            <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid " style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li>
                                            <li className="d-flex">
                                                <img src={CheckMarkedPng} className=" img-fluid " style={{ height: "15px", width: "15px" }} />
                                                <p className="col-9 categoty">Rebranding assistance</p>
                                            </li> */}
                          </ul>
                        </div>
                        <div
                          // className={
                          //   plan?.is_currentplan || !plan?.is_currentplan
                          //     ? plan?.package_name === "E-book"
                          //       ? "tailored-card-footer p-3"
                          //       : "tailored-card-footer p-3 disabledButton"
                          //     : "tailored-card-footer p-3"
                          // }
                          // className={plans.some(plan => plan.is_currentplan) ? plan?.package_name === "E-book" ? "tailored-card-footer p-3"   : "tailored-card-footer p-3 disabledButton" : (plan.is_upgradable) ? "tailored-card-footer p-3" : "tailored-card-footer p-3 disabledButton"}
                          // className={index >= plans.length - 2 || plan.is_upgradable ? "tailored-card-footer p-3" : plans.some(plan => plan.is_currentplan) ? "tailored-card-footer p-3 disabledButton" : "tailored-card-footer p-3"}
                          className={index === plans.length - 1 || plan.is_upgradable ? "tailored-card-footer p-3" : plans.some(plan => plan.is_currentplan) ? "tailored-card-footer p-3 disabledButton" : "tailored-card-footer p-3"}
                        >
                          <a
                            href={
                              !isAuthHeader ?  
                              `${webBaseUrl}/login`
                              :
                              plan?.package_name === "E-book"
                                ? plan.url
                                : index === 1
                                ? `${plan.url}&isUpgradable=false`
                                : `${plan.url}&isUpgradable=${plan.is_upgradable.toString()}`
                            }
                            target={
                              plan?.package_name === "E-book"
                                ? "_blank"
                                : null
                            }
                            onClick={
                              () => clickOnPackage(plan.id)
                            }
                            className="button d-flex justify-content-between align-items-center rounded-pill ps-3"
                            rel="noreferrer"
                          >

                            <h6 className="m-0 p-0 text-white"> Buy Now </h6>
                            <button className="btn">
                              <div
                                className="btn-icon"
                                style={{
                                  height: "27px",
                                  width: "27px",
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center",
                                }}
                              >
                                <img
                                  src={PurchaseBtnImg}
                                  className="m-2"
                                  style={{
                                    width: "3.84px",
                                    height: "7.92px",
                                    top: "2.04px",
                                    left: "4.83px",
                                    border: "1.5px",
                                    angle: "-0 deg",
                                  }}
                                />
                              </div>
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
          )}
        </OwlCarousel>
      )}
    </>
  );
};