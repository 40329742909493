// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { GET_DASHBOARD_USER_PROFILE_DATA } from "../../constants";

const GET_USER_PROFILE_DATA_QUERY = gql`
query GetProfile {
  getProfile {
    success
    message
    data {
      profile_data {
        
        first_name
        last_name
        profile_pic
        roles
        full_name
        company_name
        finance_approved
        is_verified
        is_hide_banner
        is_hide_website
        is_show_hair_prescription_request
      }
    }
    
  }
}
`;

export const GetProfileAction = () => {
  const dispatch = useDispatch();
  const [
    getProfileResponseFunction,
    getProfileResponseData,
  ] = useLazyQuery(GET_USER_PROFILE_DATA_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getProfile: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: GET_DASHBOARD_USER_PROFILE_DATA,
          payload: responseData.data.profile_data[0],
        });
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getProfileQueryData = getProfileResponseData; //variable

  const initGetProfileData = (data) => {
    getProfileResponseFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.getProfile?.success) {
        console.log('res success', res);
        // successCallback(res);
      } else {
        console.log('res error', res);
        // errorCallback(res);
      }
    }).catch((err) => {
      console.log('res err', err);
      // errorCallback(err);
    });
  };

  return { getProfileQueryData, initGetProfileData };
};
