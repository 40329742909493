import React, { useEffect, useRef, useState } from "react"
import "./forgot-email-component.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import FinaceHeader from "../../finaceHeader"
import { ForgotePasswordForFinance } from "../../redux/actions/pay-later/forgotePasswordForFinance"
import { useNavigate } from "react-router"
import { STORE_USER_EMAIL } from "../../redux/constants";
import { useDispatch, useSelector } from "react-redux"
import StepButton from "../../common/StepButton"    

const ForgotEmailComponent = () => {
    const inputRef = useRef(null);

    const [formData, setFormData] = useState({
        emailId: '',
    });
    const [addEmailIdPostError, setAddEmailIdPostError] = useState("")
    const { initiateforgotePasswordForFinance, forgotePasswordForFinanceData } = ForgotePasswordForFinance();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const email = useSelector(state => state?.financeLoanRequestEmailReducer?.userEmailForFinance)

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            emailId: email
        }));
    }, [email]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        validateInput(name, value);
    };

    function isValidEmail(email) {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailPattern.test(email);
    }

    const validateInput = (name, value) => {
        // alert(name + " " + value)
        switch (name) {
            case "emailId":
                if (!value || !isValidEmail(value)) {
                    setAddEmailIdPostError("Valid email is required");
                } else {
                    setAddEmailIdPostError("");
                }
                break;
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.emailId !== '') {
            // Perform submission logic here
            initiateforgotePasswordForFinance({
                email: formData.emailId,
                forFinance: true,
                platform: null
            },
                (res) => {
                    if (res.success) {
                        dispatch({
                            type: STORE_USER_EMAIL,
                            payload: formData.emailId,
                        });

                        navigate("/verify-forgot-password")
                    }
                },
                (err) => {
                    console.log("err888888888888", err)
                }
            )
            setAddEmailIdPostError("")
            // alert('Email Address submitted: ' + formData.emailId);
        } else {
            setAddEmailIdPostError("Please Enter Email address!")
            return
        }
        // Add your registration logic here
    };
    return (
        <>
            <div className="mainfinancelandingpage">
                <FinaceHeader />
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8 col-sm-12">
                            <h2 className="pagenewPageTitle pt-5">Reset your password</h2>
                            <form className="login-form col-md-12" onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor="emailId" className="py-2 form-label">
                                        Email address
                                    </label>
                                    <input
                                        type="text"
                                        id="emailId"
                                        name="emailId"
                                        className="form-control"
                                        placeholder="name@gmail.com"
                                        value={formData.emailId}
                                        onChange={handleChange}
                                        ref={inputRef}
                                    />
                                </div>
                                {addEmailIdPostError && <p className='text-danger error'>{addEmailIdPostError}</p>}
                                {/* <button type="submit" className="col-md-12 btn btn-primary apply-btn">
                                    Continue
                                </button> */}
                                <StepButton
                                    type="submit"
                                    blue={true}
                                    id={"ryft-pay-btn"}
                                    label={"Continue"}
                                    isLoading={forgotePasswordForFinanceData.loading}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotEmailComponent
