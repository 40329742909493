
import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import './acountancy-landing-page.scss';
import simpleOnboardingImg from '../../../images/accountancy-landing-page/simpleOnboarding.png';
import keyBenefitsImg1 from '../../../images/accountancy-landing-page/KeyBenefitsImg1.png';
import keyBenefitsImg2 from '../../../images/accountancy-landing-page/KeyBenefitsImg2.png';
import keyBenefitsImg3 from '../../../images/accountancy-landing-page/KeyBenefitsImg3.png';
import keyBenefitsImg4 from '../../../images/accountancy-landing-page/KeyBenefitsImg4.png';
import natashaGordanImg from '../../../images/accountancy-landing-page/natashaGordan.png';
import playButtonVideo from '../../../images/accountancy-landing-page/playButton.png';
import mobileBanner from '../../../images/accountancy-landing-page/mobileBanner.png';


import quotesImg from '../../../images/accountancy-landing-page/quotesImg.png';
import quotesImg2 from '../../../images/accountancy-landing-page/quotesImg2.png';
import checkIcon from '../../../images/accountancy-landing-page/checkIcon.svg';
import { FaCircleCheck, FaWhatsapp } from 'react-icons/fa6';
import { FaArrowRight } from 'react-icons/fa6';
import { IoLogoWhatsapp } from 'react-icons/io';
import star from '../../../images/accountancy-landing-page/star.svg';
import profile from '../../../images/accountancy-landing-page/miniProfile.png';
import whatsAppIcon from '../../../images/accountancy-landing-page/whatsapp.png';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import CustomModal from '../../modal/CustomModal';
import 'aos/dist/aos.css';
import AOS from 'aos';
import loadingIcon from "../../../images/loading.gif";
import Slider from 'react-slick';
import gsap, { Power1 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FaArrowRightLong } from 'react-icons/fa6';
import BusinessHeader from '../../businessHeader';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import BusinessFooter from '../../businessFooter';
import { useDispatch } from 'react-redux';
import { GetAccountancyPackagesAction } from '../../redux/actions/accountancy-service/packages';
import { facesWebBaseUrl, isLiveSystem, notificationMode, webBaseUrl } from '../../../config';
import { GetAccountancyOtherBasicDetailsAction } from '../../redux/actions/accountancy-service/basic-detais';
import Cookies from 'js-cookie';
import { GetTestimonialListAction } from '../../redux/actions/accountancy-service/testimonials-list';
import { GetAccountancyFaqListAction } from '../../redux/actions/accountancy-service/faqs';
import { TrackUserActivityForServicesAction } from "../../redux/actions/social-media-marketing/service-user-activity";
import { useNavigate } from 'react-router';
import { accountancyServicePage } from '../../../Routes';
import OwlCarousel from 'react-owl-carousel';
import { Helmet } from 'react-helmet';


export const AcountancyLandingPage = () => {
  const navigate = useNavigate()
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [setEvent, isSetEvent] = useState(false);
  const [isyouTube, setIsyouTube] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const { width, height } = useWindowDimensions();
  const [btnLoader, setBtnLoader] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  let isMobile = false;
  if (width <= 991) {
    isMobile = true;
  } else {
    isMobile = false;
  }
  useEffect(() => {
    isMobile ? setShowSidebar(false) : setShowSidebar(true);
  }, [isMobile]);


  const {
    trackUserActivityForServicesQueryData,
    initTrackUserActivityForServicesData,
  } = TrackUserActivityForServicesAction();
  useEffect(() => {
    initTrackUserActivityForServicesData({ serviceId: 2, activityId: 1 });
  }, []);


  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-in-out',
    });
    return () => {
      AOS.refresh();
    };
  }, []);

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-next-arrow slick-next" onClick={onClick}>
        <IoIosArrowForward />
      </div>
    );
  };

  // Custom arrow component
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-prev-arrow slick-prev" onClick={onClick}>
        <IoIosArrowBack />
      </div>
    );
  };

  // const reviewSlider = {
  //   slidesToShow: 2.2,
  //   swipeToSlide: true,
  //   focusOnSelect: false,
  //   arrows: true,
  //   nextArrow: <CustomNextArrow />,
  //   prevArrow: <CustomPrevArrow />,

  //   responsive: [
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  const reviewSlider = {
    0: {
      items: 1.1
    },
    1366: {
      items: 2.3
    },
    700: {
      items: 1.2
    },
    950: {
      items: 2.2
    },
    1100: {
      items: 2.2,
    },
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,

    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 2.2,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 767,
    //     settings: {
    //       slidesToShow: 1.2,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  const cusPlanSlider = {
    0: {
      items: 1.2
    },
    1366: {
      items: 3.2
    },
    700: {
      items: 1.2
    },
    950: {
      items: 2.2
    },
    1100: {
      items: 2.2,
    },
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,

    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 2.2,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 767,
    //     settings: {
    //       slidesToShow: 1.2,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };


  const whyChooseUs = [
    {
      serialNo: '01',
      question: 'Proven Expertise',
      answer:
        'Supporting thousands of professionals in the aesthetic and beauty industry.',
    },
    {
      serialNo: '02',
      question: 'Personalised Service',
      answer:
        'Your dedicated accountant will understand your business, aiding its growth.',
    },
    {
      serialNo: '03',
      question: 'Hassle-Free Bookkeeping',
      answer:
        'Using Xero software, specifically designed for busy professionals.',
    },
    {
      serialNo: '04',
      question: 'Unlimited Expert Advice',
      answer: 'Always available to address your tax and financial queries.',
    },
  ];
  const simpleOnboarding = [
    {
      simpleOnboardingSerial: '1',
      packageHeading: 'Select Package',
      packageDescription:
        'Select from one of the packages that suits your business.',
    },
    {
      simpleOnboardingSerial: '2',
      packageHeading: 'Register Easily',
      packageDescription: 'A brief registration to start the journey.',
    },
    {
      simpleOnboardingSerial: '3',
      packageHeading: 'Warm Welcome',
      packageDescription: 'Meet your dedicated accountant and get started.',
    },
  ];

  const dispatch = useDispatch();
  const { getAccountancyPackagesQueryData, initGetAccountancyPackagesData } =
    GetAccountancyPackagesAction();
  const [serviceSuitablePlan, setserviceSuitablePlan] = useState([]);
  useEffect(() => {
    initGetAccountancyPackagesData({
      baseUrl: webBaseUrl + facesWebBaseUrl,
    });
  }, []);
  useEffect(() => {
    if (getAccountancyPackagesQueryData?.data)
      setserviceSuitablePlan(
        getAccountancyPackagesQueryData.data.getAccountancyPackages.data
          .packages
      );
  }, [getAccountancyPackagesQueryData?.data]);
  // console.log(
  //   getAccountancyPackagesQueryData?.data?.getAccountancyPackages?.data
  //     ?.packages,
  //   'getAccountancyPackagesQueryData data'
  // );

  // const serviceSuitablePlan = [
  //     {
  //         servicePlanTitle: "Sole Trader / Freelancer",
  //         serviceTitleSpan:"",
  //         serviePlanPrice: "£50.00",
  //         servicePriceSpan:"per month + VAT",
  //         servicePlanPoints: [
  //             {
  //                 servicePoints: "Tax Deductibles Support"
  //             },
  //             {
  //                 servicePoints: "Year-end Tax Efficiency Review"
  //             },
  //             {
  //                 servicePoints: "Year-end Tax Return"
  //             },
  //             {
  //                 servicePoints: "Advice on Incorporation"
  //             },
  //             {
  //                 servicePoints: "WhatsApp Support"
  //             }
  //         ]
  //     },
  //     {
  //         servicePlanTitle: "Business Pro",
  //         serviceTitleSpan:"for Sole Trader / Freelancer",
  //         serviePlanPrice: "£120.00",
  //         servicePriceSpan:"per month + VAT",
  //         servicePlanPoints: [
  //             {
  //                 servicePoints: "Tax Deductibles Support"
  //             },
  //             {
  //                 servicePoints: "Year-end Tax Efficiency Review"
  //             },
  //             {
  //                 servicePoints: "Year-end Tax Return"
  //             },
  //             {
  //                 servicePoints: "Advice on Incorporation"
  //             },
  //             {
  //                 servicePoints: "WhatsApp Support"
  //             },
  //             {
  //                 servicePoints: "Weekly Bookkeeping  / Check-in"
  //             }
  //         ]
  //     },
  //     {
  //         servicePlanTitle: "Limited Company",
  //         serviceTitleSpan:"",
  //         serviePlanPrice: "£200.00",
  //         servicePriceSpan:"per month + VAT",
  //         servicePlanPoints: [
  //             {
  //                 servicePoints: "Onboarding Specialist"
  //             },
  //             {
  //                 servicePoints: "Financial Goals"
  //             },
  //             {
  //                 servicePoints: "Cashflow Management"
  //             },
  //             {
  //                 servicePoints: "Tax Efficiency Planning"
  //             },
  //             {
  //                 servicePoints: "Weekly Bookkeeping  / Check-in"
  //             },
  //             {
  //                 servicePoints: "WhatsApp Support"
  //             }
  //         ]
  //     }
  // ]
  const keyBenefits = [
    {
      colors: '#F9F3F4',
      keyBenefitsImg: keyBenefitsImg1,
      keyBenefitsHeading: 'Transparent Pricing',
      keyBenefitsDescription:
        'No hidden fees, with clear costs for annual account completion and tax return submission.',
    },
    {
      colors: '#ECF9F8',
      keyBenefitsImg: keyBenefitsImg2,
      keyBenefitsHeading: 'Proactive Support',
      keyBenefitsDescription:
        'Regular reminders for financial deadlines, letting you focus on your business.',
    },
    {
      colors: '#EDF2FE',
      keyBenefitsImg: keyBenefitsImg3,
      keyBenefitsHeading: 'Tax Efficiency',
      keyBenefitsDescription: 'Annual reviews to ensure maximum tax savings.',
    },
    {
      colors: '#F4FAEF',
      keyBenefitsImg: keyBenefitsImg4,
      keyBenefitsHeading: 'All Online',
      keyBenefitsDescription:
        'No need for lengthy phone calls, our service is all done online, giving you more time.',
    },
  ];

  /* Accountancy basic details */
  const {
    getAccountancyOtherBasicDetailsQueryData,
    initGetAccountancyOtherBasicDetailsData,
  } = GetAccountancyOtherBasicDetailsAction();
  const [basicDetailsData, setBasicDetailsData] = useState(null);
  const [inquireNowLink, setInquireNowLink] = useState('');
  useEffect(() => {
    initGetAccountancyOtherBasicDetailsData();
  }, []);
  useEffect(() => {
    if (
      getAccountancyOtherBasicDetailsQueryData?.data
        ?.getAccountancyOtherBasicDetails?.data
    ) {
      setBasicDetailsData(
        getAccountancyOtherBasicDetailsQueryData?.data
          ?.getAccountancyOtherBasicDetails?.data
      );
    }
  }, [getAccountancyOtherBasicDetailsQueryData]);
  useEffect(() => {
    setInquireNowLink(
      `https://api.whatsapp.com/send?phone=${basicDetailsData?.country_code}${basicDetailsData?.phone_no}`
    );
  }, [basicDetailsData]);

  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    setIsAuth(Cookies.get('faces_access_token') ? true : false);
  }, []);

  /* Testimonials */
  const [testimonials, setTestimonials] = useState([]);
  const { getTestimonialListQueryData, initGetTestimonialListData } =
    GetTestimonialListAction();
  useEffect(() => {
    initGetTestimonialListData();
  }, []);
  useEffect(() => {
    if (
      getTestimonialListQueryData?.data?.getTestimonialList?.success &&
      getTestimonialListQueryData?.data?.getTestimonialList?.data?.testimonial
    ) {
      setTestimonials(
        getTestimonialListQueryData?.data?.getTestimonialList?.data?.testimonial
      );
    }
  }, [getTestimonialListQueryData]);

  /* FAQs */
  const [faqs, setFaqs] = useState([]);
  const { getAccountancyFaqListQueryData, initGetAccountancyFaqListData } =
    GetAccountancyFaqListAction();

  useEffect(() => {
    initGetAccountancyFaqListData();
  }, []);
  useEffect(() => {
    if (getAccountancyFaqListQueryData?.data) {
      setFaqs(
        getAccountancyFaqListQueryData.data.getAccountancyFaqList.data.faq
      );
    }
  }, [getAccountancyFaqListQueryData?.data]);


  const packageSelected = (item) => {
    setBtnLoader(true);
    let result = initTrackUserActivityForServicesData({
      serviceId: 2,
      activityId: 2,
      packageSlug: item.slug,
    },
      () => {
        setBtnLoader(false);
        window.location.href = item?.url + '&isUpgradable=' + item?.is_upgradable.toString();
      },
      () => {
        setBtnLoader(false);
        window.location.href = item?.url + '&isUpgradable=' + item?.is_upgradable.toString();
      });
  }

  useEffect(() => {
    return () => {

      let clarityScript = document.getElementById('clarity-script');

      if(clarityScript) {
        clarityScript.remove();
      }
    }
  }, []);
  
  useEffect(() => {
    const currentTitle = document.title;
    if (currentTitle !== "Accountancy Services | Faces Consent - Financial Management for Aesthetic Practitioners") {
      document.title = "Accountancy Services | Faces Consent - Financial Management for Aesthetic Practitioners";
    }
  }, []);
  
  return (
    <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Accountancy Services | Faces Consent - Financial Management for Aesthetic Practitioners</title>
        <meta
          name="description"
          content="Streamline your financial management with expert accountancy services from Faces Consent. Tailored for beauty and aesthetic practitioners, our services include bookkeeping, tax preparation, and financial planning. Ensure your practice's financial health today."
        />
        <meta
          name="keywords"
          content="accountancy services, Faces Consent, financial management, bookkeeping, tax preparation, financial planning, beauty practitioners, aesthetic practitioners, accounting solutions, financial health"
        />
         {isLiveSystem  &&  
        <script type="text/javascript">
        {
          ` (function(c,l,a,r,i,t,y){ 
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; 
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            t.id = 'clarity-script';
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "mcftd5dhzu"); `
        }
        </script>
        }

      </Helmet>


      <BusinessHeader
        openMobileMenu={() => {
          setShowSidebar(!showSidebar);
        }}
        isMobile={isMobile}
      />
      <div className='Main-accountancyPage'>
        <section className="accountancyBanner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-lg-7">
                <div
                  className="sectionHeading"
                  data-aos="fade-up"
                  data-aos-duration={800}
                >
                  <h1>
                    Specialised <span>Accountancy Services</span> for Beauty and
                    Aesthetic Professionals
                  </h1>
                </div>
                <p
                  className="tailoredPackage"
                  data-aos="fade-up"
                  data-aos-duration={850}
                >
                  Tailored Packages Starting from £50.00 per month
                </p>
                <ul data-aos="fade-up" data-aos-duration={900}>
                  <li>
                    <img src={checkIcon} alt="check icon" />
                    <p>
                      <span>Full-service:</span> Choose your service, and our
                      accountants contacts you within 24 hrs via email/WhatsApp
                      for setup.
                    </p>
                  </li>
                  <li>
                    <img src={checkIcon} alt="check icon" />
                    <p>
                      <span>
                        Comprehensive Account and Tax Return Management:
                      </span>{' '}
                      Ensuring compliance and peace of mind.
                    </p>
                  </li>
                  <li>
                    <img src={checkIcon} alt="check icon" />
                    <p>
                      <span>User-Friendly Online Accounting:</span> Streamline
                      your financial management effortlessly.
                    </p>
                  </li>
                  <li>
                    <img src={checkIcon} alt="check icon" />
                    <p>
                      <span>Access to Additional Support and Tools:</span> Benefit
                      from a wide array of resources. Remaining completely
                      Hands-Off
                    </p>
                  </li>
                </ul>
                <div
                  className="supportBtnWrap"
                  data-aos="fade-up"
                  data-aos-duration={950}
                >
                  <button className="facesSupportBtn" onClick={() => { navigate(accountancyServicePage) }}>
                    Services <FaArrowRight />
                  </button>

                  <button className='Whatsapp-Btn'
                    onClick={() => {
                      window.open(inquireNowLink, "_blank");
                    }}>
                    <span className="Wp-icon">
                      <FaWhatsapp />
                    </span>
                    Faces Support
                  </button>
                </div>
              </div>
              <div className="col-sm-12 deskNone">
                <div className='accountancyMobileImg'>
                  <img src={mobileBanner} className='img-fluid' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="accountancyServices" id="accountancy-Services">
          <div className="container">
            <div
              className="sectionHeading"
              data-aos="fade-up"
              data-aos-anchor="#accountancy-Services"
              data-aos-duration={700}
            >
              <h2>Why Choose Our Accountancy Services</h2>
            </div>
            <div className="accountancyWrap">
              <div className="row">
                {whyChooseUs.map((whyChooseData, index) => (
                  <div className="col-md-6 box">
                    <div className="d-flex accountancy-box" key={index}>
                      <div
                        className="serial"
                        data-aos="fade-up"
                        data-aos-anchor="#accountancy-Services"
                        data-aos-duration={750}
                      >
                        <span>{whyChooseData.serialNo}</span>
                      </div>
                      <div
                        className="description"
                        data-aos="fade-up"
                        data-aos-anchor="#accountancy-Services"
                        data-aos-duration={800}
                      >
                        <h5>{whyChooseData.question}</h5>
                        <p>{whyChooseData.answer}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="natashaGordan" id="natasha-gordan">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 box-left">
                <figure
                  className="natashaImgContainer"
                  data-aos="fade-right"
                  data-aos-anchor="#natasha-gordan"
                  data-aos-duration={700}
                >
                  <img src={natashaGordanImg} alt="Natasha Gordan Img" />
                  <div
                    className="playButton"
                    onClick={() => {
                      setIsyouTube(true);
                    }}
                  >
                    <img src={playButtonVideo} alt="play button" />
                  </div>
                </figure>
              </div>
              <div className="col-md-6 box-right">
                <div className="d-flex">
                  <figure
                    data-aos="fade-left"
                    data-aos-anchor="#natasha-gordan"
                    data-aos-duration={700}
                  >
                    <img src={quotesImg} className="Double quotes img" />
                  </figure>
                  <div
                    className="about"
                    data-aos="fade-left"
                    data-aos-anchor="#natasha-gordan"
                    data-aos-duration={700}
                  >
                    <h5>Natasha Gordana- Murray</h5>
                    <p>Leading Aesthetics & PDO thread expert & trainer</p>
                    <p>
                      Director<span> at -EQlibrium Clinics</span>
                    </p>
                    <a href="http://www.eqlibrium.co.uk" target="_blank">
                      www.eqlibrium.co.uk
                    </a>
                    <div
                      className="aboutDescription"
                      data-aos="fade-left"
                      data-aos-anchor="#natasha-gordan"
                      data-aos-duration={750}
                    >
                      <p>
                        As a business owner, my focus is on brand growth. Managing
                        finances for tax efficiency is key. Faces Accountants for
                        Aesthetics and Beauty make it simple. They send weekly,
                        easy-to-understand updates, guiding me on where to
                        allocate finances for maximum tax benefits. This service
                        lets me concentrate on my business, knowing my finances
                        are in expert hands.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="follow"
                  data-aos="fade-left"
                  data-aos-anchor="#natasha-gordan"
                  data-aos-duration={700}
                >
                  <p>Follow here for more:</p>
                  <a href="https://www.instagram.com/eq.supplies/" target="_blank" className="instaHandle">
                    @EQ.supplies
                  </a>
                  <a href="https://www.instagram.com/EQlibriumclinic/" target="_blank" className="instaHandle">
                    @EQlibriumclinic
                  </a>
                  <a href="https://www.instagram.com/EQlibriumacademy/" target="_blank" className="instaHandle">
                    @EQlibriumacademy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="simpleOnboarding" id="simple-onboarding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 d-none d-md-block">
                <figure
                  data-aos="fade-right"
                  data-aos-anchor="#simple-onboarding"
                  data-aos-duration={700}
                >
                  <img src={simpleOnboardingImg} alt="simpleOnboardingImg" />
                </figure>
              </div>
              <div className="col-md-6">
                <h4
                  data-aos="fade-left"
                  data-aos-anchor="#simple-onboarding"
                  data-aos-duration={700}
                >
                  Simple Onboarding Process:
                </h4>
                <div className="box">
                  {simpleOnboarding.map((simpleOnboardingData, index) => (
                    <div className="d-flex packageCusWrap">
                      <div
                        className="packageNo"
                        data-aos="fade-left"
                        data-aos-anchor="#simple-onboarding"
                        data-aos-duration={750}
                      >
                        {simpleOnboardingData.simpleOnboardingSerial}
                      </div>
                      <div className="packageBox">
                        <h5
                          data-aos="fade-left"
                          data-aos-anchor="#simple-onboarding"
                          data-aos-duration={800}
                        >
                          {simpleOnboardingData.packageHeading}
                        </h5>
                        <p
                          data-aos="fade-left"
                          data-aos-anchor="#simple-onboarding"
                          data-aos-duration={850}
                        >
                          {simpleOnboardingData.packageDescription}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="serviceSuitablePlan" id="service-suitable">
          <div className="container">
            <div
              className="sectionHeading"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-anchor="#service-suitable"
            >
              <h2>Service that Suits your Business</h2>
            </div>
            {/* <div className="row"> */}
            {/* <Slider {...cusPlanSlider}> */}
            <OwlCarousel
              className='owl-theme'
              loop={true}
              margin={20}
              nav={true}
              dots={false}
              responsiveClass={true}
              responsive={cusPlanSlider}>
              {serviceSuitablePlan?.map((item, index) => {
                return (
                  <div key={item?.id}>
                    <div className="box">
                      <div
                        className="boxWrapper"
                        data-aos="fade-up"
                        data-aos-duration={700 + index * 100}
                        data-aos-anchor="#service-suitable"
                      >
                        <div>
                          <div className="planNamePrice">
                            <p>
                              {item?.package_name}
                              <span>{item?.serviceTitleSpan}</span>
                            </p>
                            <h5>
                              {item?.amountText}
                              <span>{item?.duration_text}</span>
                            </h5>
                          </div>
                          <ul>
                            {item?.package_points.map((value) => {
                              return (
                                <li className="planPoints">
                                  <FaCircleCheck style={{ color: '#00B548' }} />
                                  <p>{value}</p>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="planButton">
                          <a
                            // href={
                            //   isAuth
                            //     ? item?.url +
                            //       '&isUpgradable=' +
                            //       item?.is_upgradable.toString()
                            //     : webBaseUrl + '/register'
                            // }
                            onClick={() => {
                              setSelectedIndex(index);
                              packageSelected(item);
                            }}
                            className="buyPackageBtn"
                          >
                            {btnLoader && selectedIndex == index ? (
                              <div className="d-flex justify-content-center">
                                <img src={loadingIcon} className={"loadingIcon"} />
                              </div>
                            ) : (
                              <>
                                Buy this package now <FaArrowRight />
                              </>
                            )}
                          </a>
                          <a
                            href={inquireNowLink}
                            target="_blank"
                            rel="noreferrer"
                            className="whatsAppBtn"
                          >
                            <figure>
                              <img src={whatsAppIcon} />
                            </figure>{' '}
                            Whatsapp Us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* </Slider> */}
            </OwlCarousel>
            {/* </div> */}
          </div>
        </section>
        <section className="keyBenefits" id="key-benefits">
          <div className="container">
            <h4
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-anchor="#key-benefits"
            >
              Key Benefits
            </h4>
            <div className="row">
              {keyBenefits.map((keyBenefitsData, index) => (
                <div
                  className="col-md-6 key-benefits-container"
                  data-aos-duration={1200 + index * 100}
                  data-aos-anchor="#key-benefits"
                  data-aos="fade-up"
                >
                  <div
                    className="box"
                    style={{ background: keyBenefitsData.colors }}
                  >
                    <figure>
                      <img
                        src={keyBenefitsData.keyBenefitsImg}
                        alt="WhyChooseImg"
                      />
                    </figure>
                    <div className="keyBenefitsBox">
                      <h5>{keyBenefitsData.keyBenefitsHeading}</h5>
                      <p>{keyBenefitsData.keyBenefitsDescription}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section
          className="reviewSec SameSpace pb-0 reviewSecHm"
          id="reviewSec-anchor"
        >
          <div className="container">
            <div
              className="sectionTitle pt-0"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-anchor="#reviewSec-anchor"
            >
              <h2>What Business Owners Say</h2>
            </div>

            {/* <Slider {...reviewSlider}> */}
            <OwlCarousel
              className='owl-theme'
              loop={true}
              margin={20}
              nav={true}
              dots={false}
              responsiveClass={true}
              responsive={reviewSlider}>
              {testimonials.map((item, index) => (
                <div key={index}>
                  <div
                    className="review-card"
                    data-aos="fade-up"
                    data-aos-duration={900}
                    data-aos-anchor="#reviewSec-anchor"
                  >
                    <figure className="doubleQuotes">
                      <img src={quotesImg2} alt="Double quotes img" />
                    </figure>
                    <div className="review-star">
                      <ul>
                        <li>
                          <img src={star} alt="" />
                        </li>
                        <li>
                          <img src={star} alt="" />
                        </li>
                        <li>
                          <img src={star} alt="" />
                        </li>
                        <li>
                          <img src={star} alt="" />
                        </li>
                        <li>
                          <img src={star} alt="" />
                        </li>
                      </ul>
                    </div>
                    <div className="review-comment">
                      <p>{item?.description}</p>
                    </div>
                    <div className="review-profile">
                      <div className="profile-img">
                        {item?.image_url !== '' ? (
                          <img src={item.image_url} alt="" />
                        ) : (
                          <div className="testimonialRatingUserInitial">
                            {item?.shortname}
                          </div>
                        )}
                      </div>
                      <div className="profile-content">
                        <span className="name">{item?.name}</span>
                        <span className="destination">{item?.field}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div>
              <div
                className="review-card"
                data-aos="fade-up"
                data-aos-duration={950}
                data-aos-anchor="#reviewSec-anchor"
              >
                <figure className="doubleQuotes">
                  <img src={quotesImg2} alt="Double quotes img" />
                </figure>
                <div className="review-star">
                  <ul>
                    <li>
                      <img src={star} alt="" />
                    </li>
                    <li>
                      <img src={star} alt="" />
                    </li>
                    <li>
                      <img src={star} alt="" />
                    </li>
                    <li>
                      <img src={star} alt="" />
                    </li>
                    <li>
                      <img src={star} alt="" />
                    </li>
                  </ul>
                </div>
                <div className="review-comment">
                  <p>
                    Switching to this accountancy service was a game-changer for
                    my salon. The tailored advice and proactive support have
                    been invaluable. I couldn't be happier with the personal
                    attention and expertise.
                  </p>
                </div>
                <div className="review-profile">
                  <div className="profile-img">
                    <img src={profile} alt="" />
                  </div>
                  <div className="profile-content">
                    <span className="name">Alex</span>
                    <span className="destination">Beauty Salon Owner</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="review-card"
                data-aos="fade-up"
                data-aos-duration={1000}
                data-aos-anchor="#reviewSec-anchor"
              >
                <figure className="doubleQuotes">
                  <img src={quotesImg2} alt="Double quotes img" />
                </figure>
                <div className="review-star">
                  <ul>
                    <li>
                      <img src={star} alt="" />
                    </li>
                    <li>
                      <img src={star} alt="" />
                    </li>
                    <li>
                      <img src={star} alt="" />
                    </li>
                    <li>
                      <img src={star} alt="" />
                    </li>
                    <li>
                      <img src={star} alt="" />
                    </li>
                  </ul>
                </div>
                <div className="review-comment">
                  <p>
                    Switching to this accountancy service was a game-changer for
                    my salon. The tailored advice and proactive support have
                    been invaluable. I couldn't be happier with the personal
                    attention and expertise.
                  </p>
                </div>
                <div className="review-profile">
                  <div className="profile-img">
                    <img src={profile} alt="" />
                  </div>
                  <div className="profile-content">
                    <span className="name">Alex</span>
                    <span className="destination">Beauty Salon Owner</span>
                  </div>
                </div>
              </div>
            </div> */}
            </OwlCarousel>
          </div>
        </section>
        <section className="FaqSec SameSpace  FaqSecHm" id="FaqSec-anchor">
          <div className="container-sm">
            <div
              className="sectionTitle pb-5"
              data-aos="fade-up"
              data-aos-duration={1000}
              data-aos-anchor="#FaqSec-anchor"
            >
              <h2>Accountancy Questions</h2>
            </div>
            <div class="accordion" id="accordionExample">

              {faqs.map((faq, index) => (
                <div
                  class="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration={1050}
                  data-aos-anchor="#FaqSec-anchor"
                >
                  <h2 class="accordion-header" id={index}>
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={"#collapse" + index}
                      aria-expanded="false"
                      aria-controls={"collapse" + index}
                    >
                      {faq?.question}
                    </button>
                  </h2>
                  <div
                    id={"collapse" + index}
                    class="accordion-collapse collapse"
                    aria-labelledby={index}
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      {faq?.answer}
                    </div>
                  </div>
                </div>
              ))}

              {/* <div
              class="accordion-item"
              data-aos="fade-up"
              data-aos-duration={1100}
              data-aos-anchor="#FaqSec-anchor"
            >
              <h2 class="accordion-header" id="headingSecond">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSecond"
                  aria-expanded="false"
                  aria-controls="collapseSecond"
                >
                  How do I get started with your services?
                </button>
              </h2>
              <div
                id="collapseSecond"
                class="accordion-collapse collapse"
                aria-labelledby="headingSecond"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </div>
              </div>
            </div>

            <div
              class="accordion-item"
              data-aos="fade-up"
              data-aos-duration={1150}
              data-aos-anchor="#FaqSec-anchor"
            >
              <h2 class="accordion-header" id="headingthree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsethree"
                  aria-expanded="false"
                  aria-controls="collapsethree"
                >
                  Are there any hidden costs?
                </button>
              </h2>
              <div
                id="collapsethree"
                class="accordion-collapse collapse"
                aria-labelledby="headingthree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </div>
              </div>
            </div>

            <div
              class="accordion-item"
              data-aos="fade-up"
              data-aos-duration={1200}
              data-aos-anchor="#FaqSec-anchor"
            >
              <h2 class="accordion-header" id="headingfour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  Will I need Xero for the bookkeeping?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingfour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </div>
              </div>
            </div>

            <div
              class="accordion-item"
              data-aos="fade-up"
              data-aos-duration={1250}
              data-aos-anchor="#FaqSec-anchor"
            >
              <h2 class="accordion-header" id="headingfive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What kind of expert advice can I expect?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingfive"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </div>
              </div>
            </div>
            <div
              class="accordion-item"
              data-aos="fade-up"
              data-aos-duration={1300}
              data-aos-anchor="#FaqSec-anchor"
            >
              <h2 class="accordion-header" id="headingsix">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsesix"
                  aria-expanded="false"
                  aria-controls="collapsesix"
                >
                  How will you ensure my business is tax efficient?
                </button>
              </h2>
              <div
                id="collapsesix"
                class="accordion-collapse collapse"
                aria-labelledby="headingsix"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </div>
              </div>
            </div>
            <div
              class="accordion-item"
              data-aos="fade-up"
              data-aos-duration={1350}
              data-aos-anchor="#FaqSec-anchor"
            >
              <h2 class="accordion-header" id="headingseven">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseseven"
                  aria-expanded="false"
                  aria-controls="collapseseven"
                >
                  Is it easy to switch to your service if I already have an
                  accountant?
                </button>
              </h2>
              <div
                id="collapseseven"
                class="accordion-collapse collapse"
                aria-labelledby="headingseven"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </section>
      </div>

      <BusinessFooter />
      <CustomModal
        modalOpen={isyouTube}
        setModalOpen={setIsyouTube}
        type={'review'}
        className="YouTubeModel"
        modalBody={
          <iframe
            width="560"
            height="315"
            src="https://youtube.com/embed/W5cdIdD0yuk?si=yxztnowX0t6DwlkN"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        }
      />
    </>
  );
};

export default AcountancyLandingPage;
