import React from "react";
import "./business-navigation.scss";
import { ReactComponent as BackArrow } from "../../../images/back_arrow.svg";
import StepButton from "../StepButton";


const BusinessNavigation = ({ title, onBackClick, isColumn, desc, isTabShow, tabData, selectedTab, setSelectedTab, renderTabContent, rightBtn, btnTitle, RightBtnClick }) => {

 

  return (
    <>
      <div>
        {isColumn ?
          <div className="business-setting-navigation-column">
            <BackArrow onClick={onBackClick} />
            <div className="contentWrapper">
              <div className="business-navigationTitle">
                <h1>{title}</h1>
                <p>{desc}</p>
              </div>
             
              {rightBtn &&
                <button className="btnNav"  onClick={RightBtnClick}>{btnTitle}</button>
              }
            </div>
          </div>
          :
          <div className="business-setting-navigation">
            <BackArrow onClick={onBackClick} />
            <h1>{title}</h1>
          </div>}
      </div>

      {/* Is Tab */}
      {isTabShow && (
        <div className="manange-account-business-setting-container">
          <div className="business-account-detail-tab-section">
            {tabData?.map((item, i) => (
              <>
                <div
                  className={
                    selectedTab == item?.title ? "active-tab TabContent" : "in-active-tab TabContent"
                  }
                  onClick={() => setSelectedTab(item?.title)}
                  key={i}
                >
                  <p>{item?.title}</p>
                </div>
              </>
            ))}
          </div>
          {renderTabContent()}
        </div>
      )}
    </>
  );
};

export default BusinessNavigation;
