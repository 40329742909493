import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const ADD_DETAILS_OF_FINANCE_FROM_PAYL8TRPOPUP = gql`
mutation AddDetailsOfFinanceFromPayl8trPopup($accountDetail: accountDetail) {
    addDetailsOfFinanceFromPayl8trPopup(accountDetail: $accountDetail) {
      success
      message
    }
  }
`;

export const AddFinancialDetialsFormPayl8trPopup = () => {
    // const dispatch = useDispatch()
    const [addFinancialDetailFromPayl8trPopupFunc, addFinancialDetailFromPayl8trpopup] = useMutation(ADD_DETAILS_OF_FINANCE_FROM_PAYL8TRPOPUP);
    const addFinancialDetailFromPayl8trpopupData = addFinancialDetailFromPayl8trpopup;

    const initiateaddFinancialDetailFromPayl8trPopup = (
        data,
        successCallback,
        errorCallback
    ) => {
        addFinancialDetailFromPayl8trPopupFunc({
            variables: data,
        })
            .then(({ data: { addDetailsOfFinanceFromPayl8trPopup: responseData } }) => {
                console.log("dataaaaaaaaaa", data)
                console.log("responseData>>>>>>>", responseData)
                if (responseData.success) {
                    toast.success(responseData.message)
                    successCallback(responseData);
                } else {
                    console.log("elseeeeeeeee")
                    toast.error(responseData.message)
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                toast.error(error.message)
                errorCallback(error);
            });
    };

    return { initiateaddFinancialDetailFromPayl8trPopup, addFinancialDetailFromPayl8trpopupData }
}