import React, { useState, useCallback } from "react";
import { getSearchClientRoute } from "../../../Routes";
import NavHeader from "../../common/NavHeader/NavHeader";
import { useNavigate } from "react-router";
import { useDropzone } from "react-dropzone";
import "./import-client.scss";
import StepButton from "../../common/StepButton";
import { ReactComponent as Uploads } from "../../../images/uploadFile.svg";
import { webBaseUrl } from "../../../config";
import { ImportClientWithCsv } from "../../redux/actions/clientImportWithCsv/clientImport";
import ThankYou from "../thankyou-registration";
import CustomRadioButton from "../../common/CustomRadioButton";
import styled from "styled-components";
import { SendCsv } from "../../redux/actions/clientImportWithCsv/sendCsv";

const ImportClient = () => {
  const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25MB
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState([]);
  const [selectedType, setSelectedType] = useState("self");

  const { initiateImportClientWithCsv, importClientWithCsvResponseData } =
    ImportClientWithCsv();
  const { initiateSendCsvAttachment, sendCsvAttachmentResponseData } =
    SendCsv();
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    // Check for file rejections
    if (fileRejections.length > 0) {
      setErrorMessage("Only CSV files are allowed.");
    } else {
      const uploadedFile = acceptedFiles[0];

      // Additional check for file type
      if (uploadedFile && uploadedFile.type !== "text/csv") {
        setErrorMessage("Invalid file type. Please upload a CSV file.");
        setFile([]);
      } else if (uploadedFile.size > MAX_FILE_SIZE) {
        setErrorMessage(
          "File size exceeds 25MB. Please upload a smaller file."
        );
        setFile([]);
        return;
      } else {
        setErrorMessage("");
        setFile(acceptedFiles);
        console.log("CSV file uploaded:", acceptedFiles);
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    onDrop: onDrop,
    multiple: false,
  });

  const handleSubmit = () => {
    if (selectedType === "self") {
      initiateImportClientWithCsv(
        { clientFile: file[0] },
        (response) => {
          console.log("Import Client Success", response);
          // alert(response.message);
          setActive(1);
          // navigate(getSearchClientRoute());
        },
        (error) => {
          console.error("Import Client Error", error);
          setErrorMessage("Error importing clients. Please try again.");
        }
      );
    } else {
      initiateSendCsvAttachment(
        { clientFile: file[0] },
        (response) => {
          setActive(1);
        },
        (error) => {
          console.error("something went wrong", error);
          setErrorMessage("something went wrong. Please try again.");
        }
      );
    }
  };

  const handleBack = () => {
    if (active === 1) {
      setActive(active - 1);
      setFile([])
      setSelectedType("self")
    } else {
      navigate(getSearchClientRoute());
    }
  };
  const redirectToList = () => {
    navigate(getSearchClientRoute());
  };
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };
  return (
    <div className="">
      <NavHeader
        isBackOnly={true}
        applyPadding={false}
        handleOnBack={() => handleBack()}
      />
      <div className="Main-import-client-container">
        {active === 0 ? (
          <>
            <div className="contentWrapper">
              <div className="title">
                <h1>Upload CSV</h1>
                <div style={{ marginTop: "40px", marginBottom: "40px" }}>
                  <p className="mb-0">Select how to upload</p>
                  <div className="d-flex gap-3">
                    <div
                      className={`radio-container w-100`}
                      style={{
                        border:
                          selectedType === "self"
                            ? "3px solid #000"
                            : "1px solid var(--Form, #878787)",
                      }}
                    >
                      <CustomRadioButton
                        text="self"
                        type="radio"
                        name="type"
                        value="self"
                        isblacked={true}
                        onChange={handleTypeChange}
                        checked={selectedType === "self"}
                      />
                    </div>
                    <div
                      className="radio-container w-100"
                      style={{
                        border:
                          selectedType === "support"
                            ? "3px solid #000"
                            : "1px solid var(--Form, #878787)",
                      }}
                    >
                      <CustomRadioButton
                        text="support"
                        type="radio"
                        name="type"
                        value="support"
                        isblacked={true}
                        onChange={handleTypeChange}
                        checked={selectedType === "support"}
                      />
                    </div>
                  </div>
                </div>

                <p>
                  {selectedType === "self" ? (
                    <>
                      Please download the CSV template and input your client
                      data. Upload the completed CSV file containing the data to
                      transfer your client list.
                    </>
                  ) : (
                    <>
                      We encountered an issue with your CSV file format. Our
                      support team has corrected it, and the file has been
                      successfully imported.
                    </>
                  )}
                  <span
                    className="ms-1"
                    role="button"
                    onClick={() =>
                      (window.location.href = `${webBaseUrl}/client-import-reference-template.csv`)
                    }
                  >
                    Download template
                  </span>
                </p>

                {selectedType === "self" && (
                  <p>
                    NOTE: Your client data may not be imported if not properly
                    formatted.
                  </p>
                )}
              </div>
            </div>

            <div className="upload-csv-container mt-5">
              <div {...getRootProps({ className: "dropzone dropContainer" })}>
                <input {...getInputProps()} />
                <div className="text-center">
                  <Uploads />
                  <p>Drag and drop file here or choose file</p>
                </div>
              </div>
              <div className="subtext d-flex justify-content-between">
                <span>Support format: CSV</span>
                <span>Maximum size: 25MB</span>
              </div>

              {errorMessage && (
                <p className="text-danger mt-3">{errorMessage}</p>
              )}
            </div>
            {file.length > 0 && (
              <p className="mt-3">Uploaded file: {file[0].name}</p>
            )}
            <div className="mt-5 w-25">
              <StepButton
                className=""
                label={"Done"}
                blue={true}
                disabled={!file[0] || errorMessage}
                isLoading={importClientWithCsvResponseData?.loading || sendCsvAttachmentResponseData?.loading}
                onClick={() => handleSubmit()}
              />
            </div>
          </>
        ) : (
          <ThankYou
            fromPharmacy={false}
            description={
              "We're uploading your file. Once completed you will get a notification through email."
            }
            addbtn={true}
            btnText={"Done"}
            onClickCallback={redirectToList}
          />
        )}
      </div>
    </div>
  );
};

export default ImportClient;
