import { useEffect, useRef, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../../../images/delete-icon-111.svg';
import { ReactComponent as EditIcon } from '../../../../images/edit-form-edit.svg';
import { ReactComponent as CloseIcon } from '../../../../images/close-icon-edit-form.svg';
import {
    AddorUpdateQuestionMutation,
    DeleteQuestionMutation,
} from '../../../redux/actions/walk-in-form/editConsentFormQuery';
import { CircularProgress } from '@material-ui/core';
import showToast from '../../../common/CustomToast/CustomToast';
import CustomModal from '../../../modal/CustomModal';
import StepButton from '../../../common/StepButton';

function QuestionItem({ data, onQuestionChange, cnfId, onDelete, defaultEdit = false, addQuestionId, handleEditMode, canEdit }) {
    const [isEditing, setIsEditing] = useState(defaultEdit);
    const inputRef = useRef(null);
    const queRef = useRef(null);
    const [originalQuestion, setOriginalQuestion] = useState(data.question);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const { initAddorUpdateQuestionRequest, addorUpdateQuestionStatus} = AddorUpdateQuestionMutation();
    const { initDeleteQuestionRequest, deleteQuestionStatus} = DeleteQuestionMutation();

    useEffect(() => {
        if(isEditing) {
            queRef.current.scrollIntoView({ block: 'center'});
        }
    }, [isEditing])

    const switchonEditMode = () => {
        if(!canEdit) {
            return;
        }
        handleEditMode(true);
        setIsEditing(true);
        setTimeout(() => {
            inputRef.current.focus();
        }, 0);
    };

    const handleEditComplete = () => {
        if(!data.question || data.question.trim().length === 0) {
            showToast('error', 'Please enter question', undefined, undefined, undefined, undefined, {toastId: 'enter-que-error'})
            return;
        }
        setIsEditing(false);
        handleEditMode(false);
        const payload = {
            consentFormId: +cnfId,
            question: data.question,
            cfqId: data.id,
        };
        const onSuccess = (resData) => {
            setOriginalQuestion(data.question);
            if(!data.id) {
                addQuestionId(data._id,resData.cfq_id);
            }
        };
        const onError = () => {
            console.log('que-edit-res: failed to update question');

            // edit question api failed - so reset question to original
            if(originalQuestion.length === 0) {
                onDelete(data?.id || data?._id);
            } else {
                onQuestionChange(originalQuestion, data.id || data._id);
            }
        };
        initAddorUpdateQuestionRequest(payload, onSuccess, onError);
    };

    const handleEditClose = () => {
        setIsEditing(false);
        handleEditMode(false);

        if(data?._id && (!data.question || originalQuestion.length === 0)) {
            onDelete(data._id);
        } else {
            // set question to original question before editing.
            onQuestionChange(originalQuestion, data.id || data._id);
        }
    }

    const handleDelete = () => {

        // if id field is there, perform api request and then delete question from state, 
        // otherwise directly delete question
        if(data.id) {
            const payload = {
                cfqId: data.id,
            };
            const onSuccess = () => {
                onDelete(data.id)
            };
            initDeleteQuestionRequest(payload, onSuccess);
        }
        else {
            onDelete(data._id);
        }

        setIsDeleteOpen(false);
    };

    return (
        <div>
            <div ref={queRef} key={data.id} className={`question-box ${isEditing ? 'question-box--edit' : ''}`}>
                <textarea
                    // type="text"
                    ref={inputRef}
                    value={data.question}
                    onChange={(e) => onQuestionChange(e.target.value, data.id || data._id)}
                    disabled={!isEditing}
                    id={'question-edit-' + (data.id || data._id)}
                    className="question-box__text question-box__input"
                    style={{ border: 'none', background: 'none', width: '100%' }}
                />
                <div className="question-box__actions">
                    {/* {isEditing && (
                        <>
                            <button
                                onClick={handleEditComplete}
                                className="question-box__btn question-box__btn--done"
                            >
                                Done
                            </button>
                            <button
                                onClick={handleEditClose}
                                className="question-box__btn"
                            >
                                <CloseIcon />
                            </button>
                        </>
                    )} */}
                    {!isEditing && <button disabled={deleteQuestionStatus.loading || addorUpdateQuestionStatus.loading || !canEdit} onClick={() => setIsDeleteOpen(true)} className="question-box__btn">
                        {deleteQuestionStatus.loading ? <CircularProgress size={12} color='inherit' /> : <DeleteIcon />}
                    </button>}
                    {!isEditing && (
                        <button disabled={!canEdit} onClick={switchonEditMode} className="question-box__btn question-box__btn--done">
                            Edit
                            {/* <EditIcon /> */}
                        </button>
                    )}
                </div>
                <CustomModal
                    type={'common-width'}
                    modalOpen={isDeleteOpen}
                    setModalOpen={setIsDeleteOpen}
                    setIsOpenMobileCalendar={setIsDeleteOpen}
                    className={'ConfirmationDeleteModal'}
                    modalBody={
                        <>
                            <div className="delete-modal-body mt-2">
                                <h1>Are you sure?</h1>
                                <p>Are you sure you want to delete this question?</p>
                                <div className="w-100">
                                    <div className="delete-button-modal">
                                        <StepButton
                                            red={true}
                                            label={'Delete'}
                                            onClick={handleDelete}
                                        />
                                    </div>
                                    <div className="cancle-button-modal">
                                        <StepButton
                                            gray={true}
                                            onClick={() => {
                                                setIsDeleteOpen(false);
                                            }}
                                            label={'Cancel'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />
            </div>
            {isEditing &&
                <div className="question-box__actions question-box__actions--edit">
                    <button
                        onClick={handleEditComplete}
                        className="question-box__btn question-box__btn--done"
                    >
                        Done
                    </button>
                    <button
                        onClick={handleEditClose}
                        className="question-box__btn question-box__btn--done"
                    >
                        {/* <CloseIcon /> */}
                        Cancel
                    </button>
                </div>
            }
        </div>
    );
}

export default QuestionItem;
