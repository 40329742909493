import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_APPOINTMENT_LIST_CLINICS, GET_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION } from "../../constants/appointment/appointmentConst";
import { useState } from "react";

const GET_AVAILABLE_CLINICS_LIST_QUERY = gql`
query GetAvailableClinicsList($page: Int, $limit: Int, $startDate: String, $endDate: String, $includeUnAvailable: Boolean, $clinicIds: [Int]) {
  getAvailableClinicsList(page: $page, limit: $limit, startDate: $startDate, endDate: $endDate, includeUnAvailable: $includeUnAvailable, clinicIds: $clinicIds) {
    success
    message
    data {
      clinics {
        clinicId
        clinicName
        clinicColor
        availableDate
        startTime
        endTime
        isAvailable
      }
      totalClinics
      totalAppointments
    }
  }
}
`;

export const GetAvailableClinicsListAction = () => {
  const dispatch = useDispatch();
  const [fromAddLocation, setFromAddLocation] = useState(null)

  const [
    getAvailableClinicsListResponseFunction,
    getAvailableClinicsListResponseData,
  ] = useLazyQuery(GET_AVAILABLE_CLINICS_LIST_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getAvailableClinicsList: responseData }) => {
      if (responseData.success) {
        if (fromAddLocation) {
          dispatch({
            type: GET_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION,
            payload: responseData?.data

          })
        }
        else {
          dispatch({
            type: GET_APPOINTMENT_LIST_CLINICS,
            payload: responseData?.data

          })
        }

      } else {
      }
    },
  });
  //this will be called from components
  const getAvailableClinicsListQueryData = getAvailableClinicsListResponseData; //variable

  const initGetAvailableClinicsListData = (data, successCallback,
    errorCallback) => {

    if (data["isFromAddLocation"] != undefined) {
      setFromAddLocation(data["isFromAddLocation"])
      delete data["isFromAddLocation"];
    }
    else {
      setFromAddLocation(null)
    }
    getAvailableClinicsListResponseFunction({
      variables: data,
    })
    // .then((responseData) => {
    //   console.log(responseData, 'res');
    //   if (successCallback) {
    //     successCallback(responseData);
    //   }
    //   else {
    //     if (errorCallback) {
    //       errorCallback(responseData);
    //     }
    //   }
    // })
    //   .catch((error) => {
    //     if (errorCallback) {
    //       errorCallback(error);
    //     }
    //   });
  };

  return { getAvailableClinicsListQueryData, initGetAvailableClinicsListData };
};