import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { apiConfigUrl } from './config';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, concat } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import configureStore from '../src/components/redux/store';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';
import CustomeNotiTost from './components/customeNotiTost/customeNotiTost';

// ==========================GRAPHQL==========================


const httpLink = new createUploadLink({ uri: apiConfigUrl });
let facesAccessToken = "";
if (!(Cookies.get("faces_access_token"))) {
  // window.location.href = `${process.env.REACT_APP_WEB_BASE_LOGIN_URL}/login`;
} else {
  facesAccessToken = Cookies.get("faces_access_token");
}

let authMiddleware = new ApolloLink((operation, forward) => {

  let params = (new URL(document.location)).searchParams;
  let usertoken = params.get("usertoken");

  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "encrypted-authorization": facesAccessToken,
      authorization: usertoken ? usertoken : "",
      // authorization:"$2a$10$HhHWjo3KRLkjMhvoeQvU0.k6u5r6mBpsyErSaUZajOa4MkKneBiyC"
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false
  }),
  link: concat(authMiddleware, httpLink),
});
// ==========================================================

// ==========================REDUX STORE=====================

const store = configureStore();
global.reduxStore = store;



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>

  </React.StrictMode>
);


