import { CLEAR_APPOINTMENT_NOTE, GET_APPOINTMENT_NOTE } from "../../constants";

const initialState = {
    appointmentNote: null,
};

export default function appointmentNoteReducer(state = initialState, action) {

    switch (action.type) {
        case GET_APPOINTMENT_NOTE:
            return {
                ...state,
                appointmentNote: action.payload,
            };
        case CLEAR_APPOINTMENT_NOTE:
            return {
                ...state,
                appointmentNote: [],
            };

        default:
            return state;
    }
}
