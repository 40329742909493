import { GET_DASHBOARD_LIST, GET_DASHBOARD_LIST_ERROR, GET_DASHBOARD_LIST_SUCCESS } from "../../constants"


export default function getDashboardList(data) {

    return {
        type: GET_DASHBOARD_LIST,
        payload: data
    }
}

export function getDashboardListSuccess(data) {

    return {
        type: GET_DASHBOARD_LIST_SUCCESS,
        payload: data,
    }
}

export function getDashboardListError(data) {

    return {
        type: GET_DASHBOARD_LIST_ERROR,
        payload: data,
    }
}