import axios from "axios";
import React, { useEffect, useState } from "react";
import loadingIcon from "../../images/loading.gif"
import useWindowDimension from "../../utils/useWindowDimensions";


const PayLater = ({payLaterData,bookingId,setOpenPayLater}) => {
  console.log("payLaterData",payLaterData);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const dimensions = useWindowDimension();

    useEffect(() => {
        // Function to submit the form
        const submitForm = async (event) => {
          console.log("event of payloadLater",event)
          try {
            // Set the values of the hidden form fields
            document.querySelector('[name="data"]').value = payLaterData?.encodedData;
            document.querySelector('[name="item_data[]"]').value = payLaterData?.item_data;
            document.querySelector('[name="rid"]').value = payLaterData?.rid;
            // Submit the form
            document.getElementById('payl8tr-form').submit();
          } catch (error) {
            console.error('Error submitting the form', error);
          }
        };
    
        // Submit the form when the component mounts
        submitForm().then((res) => {
            console.log("response payl8r",res)
        });
      }, [payLaterData]);
    useEffect(() => {
      console.log("use effect started");
      let timeoutObj = setTimeout(() => {
          alert("Sorry but you have not completed your process in the predefined time. Please start the payment process again!");
          setOpenPayLater(false);
      }, 25 * 60 * 1000);
      return () => {
          console.log("use effect return");
          clearTimeout(timeoutObj);
      }
    }, []);

    const handleIframeLoad = () => {
      // Once the iframe is loaded, set the state to indicate it
      setIframeLoaded(true);
    };

  return (
    <div>
       {!iframeLoaded && <div className="payl8r-loading-container">
          <img src={loadingIcon} alt="loading"/>
        </div>}
      <iframe
        src=""
        style={{ width: "100%", height: dimensions?.height }}
        id="payl8tr-iframe"
        name="payl8tr-iframe"
        onLoad={handleIframeLoad}
      ></iframe>
      <form
        target="payl8tr-iframe"
        action="https://payl8r.com/process"
        id="payl8tr-form"
      >
        <input
          type="hidden"
          name="data"
          value={payLaterData?.encodedData}
          readOnly // this is important
        />
        <input
          type="hidden"
          name="item_data[]"
          value={payLaterData?.item_data}
          readOnly
        />
        <input type="hidden" name="rid" value={payLaterData?.rid} readOnly />
        {/* <button type="submit" id="payl8tr-form-submit">
          Submit
        </button> */}
      </form>
        
    </div>
  );
};

export default PayLater;
