import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_PRESCRIBER_LIST } from "../../constants/consentFormThankyou/getPrescriberListConst";


const GET_PRESCRIBER_LIST_QUERY = gql`
query GetConnectedPresList($page: Int, $limit: Int, $search: String, $product: [Int]) {
  getConnectedPresList(page: $page, limit: $limit, search: $search, product: $product) {
    success
    message
    data {
      total
      items {
        prescriber_id
        prescriber_name
        prescriber_profile
        prescriber_fee
        prescriber_profession
        currency
        prescriber_profession_arr
        city
        rating
      }
    }
  }
}
  `;

// var page;
export const ConnectedPrescriberListQuery = () => {
    const dispatch = useDispatch();
    const [getConnectedPrescriberList, conntectedPrescriberListData] = useLazyQuery(
        GET_PRESCRIBER_LIST_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getConnectedPresList: responseData }) => {

                dispatch({
                    type: GET_PRESCRIBER_LIST,
                    payload: responseData?.data,
                    // page
                });
                if (responseData.success) {

                } else {

                }


            },
        }
    );
    const conntectedPrescriberListQueryData = conntectedPrescriberListData;
    const initConnectedPrescriberList = (data, successCallBack, errorCallBack) => {
        // page = data.page
        getConnectedPrescriberList({
            variables: data,
        }).then((res) => {
            successCallBack(res?.data?.getConnectedPresList)
        }).catch((err) => {
            errorCallBack(err)
        })
    };
    return { initConnectedPrescriberList, conntectedPrescriberListQueryData };
};



