import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const SAVE_PRESCRIBER_ABOUT_DETAILS_MUTATION = gql`
mutation SavePharmacyAboutDetails($title: String, $fname: String, $lname: String, $email: String, $countryCode: String, $contactNumber: String, $phoneCountry: String) {
    savePharmacyAboutDetails(title: $title, fname: $fname, lname: $lname, email: $email, countryCode: $countryCode, contactNumber: $contactNumber, phoneCountry: $phoneCountry) {
      success
      message
      data {
        userId
      }
    }
  }
  
`;

export const PrescriberAboutYou = () => {
    const [savePrescriberAboutDetails, { data: savePrescriberAboutDetailsResponseData }] =
      useMutation(SAVE_PRESCRIBER_ABOUT_DETAILS_MUTATION);
    const dispatch = useDispatch();
  
    const initiateSavePrescriberAboutDetails = (variables, successCallback, errorCallback) => {
      savePrescriberAboutDetails({ variables })
        .then(({ data }) => {
          if (data.savePharmacyAboutDetails.success) {
            successCallback(data.savePharmacyAboutDetails);
          } else {
            errorCallback(data.savePharmacyAboutDetails);
          }
        })
        .catch((error) => {
          // Handle error
          errorCallback(error);
        });
    };

    return { initiateSavePrescriberAboutDetails, savePrescriberAboutDetailsResponseData };
  };

  const GET_PRESCRIBER_ABOUT_DETAILS_QUERY = gql`
  query GetPharmacyAboutDetails {
    getPharmacyAboutDetails {
      success
      message
      data {
        title
        fname
        lname
        email
        countryCode
        contactNumber
        phoneCountry
        profileImage
        isProfileImage
      }
    }
  }
  
`;

export const GetPrescriberAboutDetails = () => {
    const dispatch = useDispatch();
    const [getProfileResponseFunction, { loading, error, data }] = useLazyQuery(GET_PRESCRIBER_ABOUT_DETAILS_QUERY, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: (response) => {
        
      },
    });
  
    const initGetPrescriberAboutDetails = () => {
      getProfileResponseFunction();
    };
  
    return { loading, error, data, initGetPrescriberAboutDetails };
  };
  
  