import { CLEAR_CLIENT_NOTES, GET_CLIENT_NOTES } from "../../constants";

const initialState = {
  clientNote: null,
};

export default function clientNoteReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_NOTES:
      return {
        ...state,
        clientNote: action.payload,
      };
    case CLEAR_CLIENT_NOTES:
      return {
        ...state,
        clientNote: [],
      };
      
    default:
      return state;
  }
}
