import { APPEND_CLIENT_LIST_WITH_SKELETON, CLEAR_CLINIC_LIST, GET_CLINIC_LISTING_CONST } from "../../constants";

const initialState = {
    clinicList: [...Array(6)].map(i => (null)),
    totalClinicCount: 0,
    loadedClinicCount: 0,
    clinicListPage: 1,
}
export const GetShareBookingLinkReducer = (state = initialState, action) => {
    switch (action.type) {

        case APPEND_CLIENT_LIST_WITH_SKELETON:
            let tempNull = [...Array(6)].map(i => (null))
            let tempClientVar = (state?.clinicList || []);
            let tempClinicListPage = state.clinicListPage;
            if (action.page == 1) {
                tempClientVar = tempNull
                tempClinicListPage = 1;
            } else {
                tempClientVar = tempClientVar.concat(tempNull)
            }
            return {
                ...state,
                clinicList: tempClientVar,
                clinicListPage: tempClinicListPage,
            };

        case GET_CLINIC_LISTING_CONST:

            console.log(action, "action.listingType")

            let temp = (state?.clinicList || []).filter(i => i).concat(
                (action.payload.data?.clients || []).map(listData => ({
                    ...listData,
                    // title: listData.clientName + " ( " + listData.clientEmail : listData.readableContact + " )",
                    title: action.listingType == 'searchEmails' ? listData.clientName + " ( " + listData.clientEmail + " )"
                        : listData.clientName + " ( " + listData.readableContact + " )",
                }))
            );


            return {
                ...state,
                clinicList: temp,
                totalClinicCount: action.payload.data?.total,
                loadedClinicCount: temp.length,
                clinicListPage: state.clinicListPage + 1,
            };

        case CLEAR_CLINIC_LIST:
            return {
                ...state,
                clinicList: [...Array(6)].map(i => (null)),
                totalClinicCount: 0,
                loadedClinicCount: 0,
                clinicListPage: 1,
            };
        default:
            return state
    }
}  