import React, { useEffect, useState } from 'react'
import { ReactComponent as SlickNext } from '../../../../assets/images/cart/slickNext.svg'
import { ReactComponent as SlickPrev } from '../../../../assets/images/cart/slickPrev.svg'
import Slider from 'react-slick';
import './horizontal-date-calendar.scss'
import { GetAvailableTimeSlotsAction, GetClinicAvailableDateAction } from '../../../../redux/actions/dateTimeslots/DateTimeSlots';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_DATE_SLOTS, CLEAR_TIME_SLOTS } from '../../../../redux/constants/dateTimeslots/DateTimeSlots';
import Skeleton from 'react-loading-skeleton';
import CustomModal from '../../../../modal/CustomModal';
import CustomCalendar from '../CustomCalendar';
import triangleArrow from '../../../../../assets/cart/triangle.png'
import { el, is } from 'date-fns/locale';

//SLICK NEXT CUSTOM BUTTON
function SampleNextArrow(props) {
    const { nextClass, onClick, type, tempdateCounter, setTempdateCounter } = props;
    return (
        <button
            className={`${nextClass} nextButton`}
            onClick={() => {
                if (onClick) onClick();
                // setTempdateCounter(tempdateCounter + 1) 
            }}
        ><SlickNext /></button>
    );
}

//SLICK PREVIOUS CUSTOM BUTTON
function SamplePrevArrow(props) {
    const { prevClass, type, onClick, tempdateCounter, setTempdateCounter } = props;
    return (
        <button
            className={`${prevClass} prevButton`}
            onClick={() => {
                if (onClick) onClick(); console.log("on sampleprev call", tempdateCounter, tempdateCounter - 1);
                if (tempdateCounter == 0) {
                    setTempdateCounter(tempdateCounter - 1)
                }
            }}
        >
            <SlickPrev />
        </button>
    );
}

const HorizontalDateCalendar = ({
    showSelectedDate,
    setShowSelectedDate,
    setShowSelectedTime,
    setFormatedDate,
    clinicId,
    initAvalableTimeSlotData,
    availableTimeSlotData,
    isOpenMobileCalendar,
    setIsOpenMobileCalendar,

    selectedTreatment,
    timingScrollRef
}) => {

    const moment = require("moment");

    const startDateOfCurrentWeek = moment().startOf('isoweek').format("YYYY-MM-DD"), bufferDays = 84;

    const [pageStartCounter, setPageStartCounter] = useState(null)
    const [pageEndCounter, setPageEndCounter] = useState(null)

    const [referenceWeekDate, setReferenceWeekDate] = useState(null)

    const [selectedDateIndex, setSelectedDateIndex] = useState(null)
    const [tempdates, setTempdates] = useState([]);
    const [tempdateCounter, setTempdateCounter] = useState(0)

    const [pageSpecificDateObj, setPageSpecificDateObj] = useState(null)
    const [pageSpecificDate, setPageSpecificDate] = useState(null)
    const [pageSpecificMonth, setPageSpecificMonth] = useState(null)
    const [pageSpecificYear, setPageSpecificYear] = useState(null)

    const [pageStartDate, setPageStartDate] = useState(null)
    const [pageEndDate, setPageEndDate] = useState(null)

    //CALENDAR MODAL
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(null)
    const [selectedDate, setSelectedDate] = useState(new Date());

    const [selectedMonth, setSelectedMonth] = useState(
        selectedDate.getMonth() + 1
    );
    const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());

    const handleSelectedDate = (selectedDate) => {
        dispatch({
            type: CLEAR_TIME_SLOTS,
        });
        setShowSelectedDate(selectedDate)

    };

    const handleDateSelection = (selectedDate) => {

        // if (substep !== 303 && substep !== 304)
        // setSubstep(substep + 1);
        handleSelectedDate(selectedDate);
        // if (substep == 304) createBookingApiCall()
    };



    const { initClinicsAvailableDateResponseFunction, clinicsAvailableDateData } = GetClinicAvailableDateAction()

    const dispatch = useDispatch();

    const { dateSlots } = useSelector(state => state.GetDateTimeSlotsReducer)

    console.log(showSelectedDate, "showSelectedDate", pageSpecificDate)

    var settingsDate = {
        infinite: false,
        slidesToScroll: 7,
        slidesToShow: 7,
        nextArrow: <SampleNextArrow
            nextClass={''}
            type={'week'}
            tempdateCounter={tempdateCounter}
        // setTempdateCounter={setTempdateCounter} 
        />,
        prevArrow: <SamplePrevArrow
            // prevClass={pageStartCounter == 0 && tempdateCounter == 0 ? 'disabled' : ''}
            prevClass={pageSpecificDateObj == startDateOfCurrentWeek ? 'disabled' : ''}
            type={'week'} tempdateCounter={tempdateCounter}
            setTempdateCounter={setTempdateCounter}
        />,
        beforeChange: (current, next) => {
            console.log("BEFORE CHANGE", next, isOpenCalendar);
            if (!isOpenCalendar) {
                setTempdateCounter(next / 7);
            }
        }
    };

    const reRenderTile = (referenceStartDate) => {
        setTempdates(Array.from(Array(50).keys()));
        // moment.updateLocale('en', {
        //     week: {
        //         dow: 1, // Sunday is the first day of the week
        //     },
        // });
        // let tempMoment = moment(showSelectedDate, "dddd, Do MMMM YYYY").startOf('week').format("YYYY-MM-DD")
        let tempMoment = moment(referenceStartDate, "dddd, Do MMMM YYYY").startOf('isoweek').format("YYYY-MM-DD"), pageStartDate = moment(tempMoment).format("YYYY-MM-DD"), pageEndDate = moment(tempMoment).add(bufferDays, "day").format("YYYY-MM-DD");
        if (pageStartDate < startDateOfCurrentWeek) {
            pageStartDate = startDateOfCurrentWeek;
        }
        let numberOfWeekCount = moment(tempMoment).diff(moment(startDateOfCurrentWeek), 'week'),
            pageStartCounter = moment(pageStartDate).diff(moment(startDateOfCurrentWeek), 'week'),
            pageEndCounter = moment(pageEndDate).diff(moment(startDateOfCurrentWeek), 'week');

        console.log("numberOfWeekCount", numberOfWeekCount, pageStartDate, pageEndDate, pageStartCounter, pageEndCounter);

        setPageSpecificDate(moment(tempMoment))
        setPageSpecificMonth(moment(tempMoment).format("MMMM"))
        setPageSpecificYear(moment(tempMoment).format("YYYY"))

        setSelectedYear(moment(tempMoment).format("YYYY"))
        setSelectedMonth(moment(tempMoment).format("M"))

        console.log("tempMoment", tempMoment);

        setPageSpecificDateObj(pageStartDate)

        // setPageStartDate(moment(tempMoment).add(50, "day").format("YYYY-MM-DD"))
        // setPageEndDate(moment(tempMoment).add(100, "day").format("YYYY-MM-DD"))
        setPageStartDate(pageStartDate)
        setPageEndDate(pageEndDate)

        setReferenceWeekDate(pageStartDate)

        setTempdateCounter(0)

        dispatch({
            type: CLEAR_DATE_SLOTS
        })

        //call api
        initClinicsAvailableDateResponseFunction({
            clinicId: clinicId,
            // startDate: moment(tempMoment).format("YYYY-MM-DD"),
            // endDate: moment(tempMoment).add(50, "day").format("YYYY-MM-DD"),
            startDate: pageStartDate,
            endDate: pageEndDate,
        })
    }

    useEffect(() => {
        reRenderTile(showSelectedDate)
    }, []);

    // console.log("time data", dateSlots)

    useEffect(() => {
        let updatedPageStartDate = pageStartDate, updatedPageEndDate = pageEndDate, fetchApiData = false;

        let referencedate = moment(referenceWeekDate).add(tempdateCounter * 7, "day"), formattedReferenceDate = referencedate.format("YYYY-MM-DD");
        console.log("formattedReferenceDate", formattedReferenceDate, pageStartDate, pageEndDate, moment(pageEndDate).diff(moment(formattedReferenceDate), 'week'), moment(formattedReferenceDate).diff(moment(pageStartDate), 'week'));
        // if (tempdateCounter % 5 == 0 && tempdateCounter != 0) {
        if (moment(pageEndDate).diff(moment(formattedReferenceDate), 'week') <= 1 || (moment(formattedReferenceDate).diff(moment(pageStartDate), 'week') <= 1 && tempdateCounter > 0)) {
            fetchApiData = true;
            console.log("moved forward or back", pageSpecificDateObj, formattedReferenceDate, formattedReferenceDate == pageEndDate);
            updatedPageStartDate = moment(pageEndDate).diff(moment(formattedReferenceDate), 'week') <= 1 ? formattedReferenceDate : moment(pageSpecificDateObj).subtract(bufferDays / 2, "day").format("YYYY-MM-DD")
            if (updatedPageStartDate < startDateOfCurrentWeek) {
                updatedPageStartDate = startDateOfCurrentWeek;
            }
            updatedPageEndDate = moment(updatedPageStartDate).add(bufferDays, "day").format("YYYY-MM-DD")
        }
        else if (tempdateCounter < 0) {
            // fetchApiData = true;
            // updatedPageStartDate = moment(pageSpecificDateObj).subtract(7, "day").format("YYYY-MM-DD")
            // updatedPageEndDate = moment(updatedPageStartDate).add(bufferDays, "day").format("YYYY-MM-DD")
            reRenderTile(moment(pageSpecificDateObj).subtract(7, "day").format("dddd, Do MMMM YYYY"));
            return;
        }

        console.log('updatedStartDate', updatedPageStartDate, updatedPageEndDate, tempdateCounter, fetchApiData)

        if (fetchApiData) {
            //call api
            initClinicsAvailableDateResponseFunction({
                clinicId: clinicId,
                startDate: updatedPageStartDate,
                endDate: updatedPageEndDate,
            })
            // setPageStartDate(moment(pageStartDate).add(50, "day").format("YYYY-MM-DD"))
            // setPageEndDate(moment(pageEndDate).add(50, "day").format("YYYY-MM-DD"))
            setPageStartDate(updatedPageStartDate)
            setPageEndDate(updatedPageEndDate)
            // setTempdates(Array.from(Array(7 * ((tempdateCounter) + 1)).keys()));
            setTempdates(Array.from(Array(moment(updatedPageEndDate).diff(moment(referenceWeekDate), 'day')).keys()));
        }
        // let referencedate = moment(showSelectedDate, "dddd, Do MMMM YYYY").add(tempdateCounter * 7, "day");        

        console.log("tempdatecounter changed", tempdateCounter, referencedate.format("YYYY-MM-DD"), moment(referenceWeekDate).format("YYYY-MM-DD"), referenceWeekDate);

        setPageSpecificMonth(referencedate.format("MMMM"))
        setPageSpecificYear(referencedate.format("YYYY"))

        setSelectedYear(referencedate.format("YYYY"))
        setSelectedMonth(referencedate.format("M"))

        setPageSpecificDateObj(referencedate.format("YYYY-MM-DD"))
    }, [tempdateCounter, referenceWeekDate]);



    useEffect(() => {
        dispatch({
            type: CLEAR_TIME_SLOTS
        })
        // setShowSelectedTime(null)
        // initAvalableTimeSlotData({
        //     clinicId: clinicId,
        //     date: moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD"),
        //     treatmentIds: selectedTreatment.map((item) => item.id),
        // })
        if (showSelectedDate) {
            timingScrollRef?.current?.el?.firstElementChild
                ?.scrollTo(0, 0);

            setTimeout(() => {
                initAvalableTimeSlotData(true)
            }, 0)
        }

        if (isOpenCalendar) {
            setIsOpenCalendar(false);
            reRenderTile(showSelectedDate)
        }
    }, [showSelectedDate])

    useEffect(() => {
        if (isOpenCalendar) {
            console.log("startDate-endDate", startDate, endDate)
            if (endDate) {
                if (moment(startDate).format("YYYY-MM") == moment().format("YYYY-MM")) {
                    initClinicsAvailableDateResponseFunction({
                        clinicId: clinicId,
                        startDate: moment().format("YYYY-MM-DD"),
                        endDate: endDate,
                    });
                } else {
                    initClinicsAvailableDateResponseFunction({
                        clinicId: clinicId,
                        startDate: startDate,
                        endDate: endDate,
                    });
                }
            }
        }
    }, [startDate, endDate, isOpenCalendar]);

    console.log("pageSpecificDateObj", pageSpecificDateObj, pageSpecificMonth, pageSpecificYear, startDate, endDate, selectedMonth, selectedYear);

    return (
        <>
            <div>
                {
                    clinicsAvailableDateData?.loading && !isOpenCalendar ?
                        <>
                            <div className="date-listing-div">
                                <Skeleton width={100} height={20} />
                                <div className="date-listing row mt-3">
                                    <Slider {...settingsDate} >
                                        {
                                            [...Array(7)].map((item, index) =>
                                                <div className={`date-div `} >
                                                    <Skeleton width={"100%"} height={15} />
                                                    <Skeleton width={"100%"} height={15} />
                                                </div>)
                                        }
                                    </Slider>

                                </div>
                            </div >
                        </> :
                        <div className="date-listing-div">
                            <div className='d-flex'>

                                <h6 className='date-title'>
                                    {pageSpecificMonth} {" "}{pageSpecificYear}
                                </h6>
                                <span className='ms-2 cursor-pointer' onClick={() => {
                                    console.log("pagespecific date", pageSpecificDateObj, moment(pageSpecificDateObj).startOf('month').format("YYYY-MM-DD"), moment(pageSpecificDateObj).endOf('month').format("YYYY-MM-DD"));
                                    setStartDate(moment(pageSpecificDateObj).startOf('month').format("YYYY-MM-DD"));
                                    setEndDate(moment(pageSpecificDateObj).endOf('month').format("YYYY-MM-DD"));
                                    setIsOpenCalendar(true);
                                    setIsOpenMobileCalendar(true)
                                    // setStartDate(pageSpecificYear-)
                                }}><img src={triangleArrow} width={15} height={15} alt="" /></span>
                            </div>
                            <div className="date-listing row ps-md-0 ps-2">
                                <Slider {...settingsDate} className='p-0' >
                                    {
                                        tempdates.length && tempdates.map((tempItem, tempIndex) => {

                                            // let momenttempobj = moment('2023-11-08').startOf('week').add(tempIndex, "day");
                                            let momenttempobj = moment(referenceWeekDate).add(tempIndex, "day");
                                            let isPastDate = momenttempobj.isBefore(moment().startOf('day'))
                                            return (
                                                <div className={`date-div ${momenttempobj.format('dddd, Do MMMM YYYY') == showSelectedDate ? "active" : ""} ${(dateSlots?.findIndex(e => e == momenttempobj.format('YYYY-MM-DD'))) == -1 || isPastDate ? "disabled" : ""}`} onClick={() => { setShowSelectedTime(null); setShowSelectedDate(momenttempobj.format('dddd, Do MMMM YYYY')); setFormatedDate(momenttempobj.format('dddd, Do MMMM YYYY')) }}>
                                                    <span>
                                                        {momenttempobj.format("ddd")}
                                                    </span>
                                                    <span>
                                                        {momenttempobj.format("D")}
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>

                            </div>
                        </div >
                }



            </div >
            <CustomModal
                modalOpen={isOpenCalendar}
                setModalOpen={setIsOpenCalendar}
                setIsOpenMobileCalendar={setIsOpenMobileCalendar}
                modalBody={
                    <CustomCalendar
                        setShowSelectedDate={setShowSelectedDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                        setSelectedMonth={setSelectedMonth}
                        setSelectedYear={setSelectedYear}
                        showSelectedDate={showSelectedDate}
                        onSelectDate={handleDateSelection}
                        setFormatedDate={setFormatedDate}
                        setShowSelectedTime={setShowSelectedTime}
                        dateData={
                            clinicsAvailableDateData?.data?.getClinicAvailableDates
                                ?.data?.items?.length > 0
                                ? clinicsAvailableDateData?.data?.getClinicAvailableDates
                                    ?.data?.items
                                : []
                        }
                        ignoreSelectedDate={true}
                        isOpenMobileCalendar={isOpenMobileCalendar}
                        setIsOpenMobileCalendar={setIsOpenMobileCalendar}
                    />
                }
                modalTitle={"Pay with card"}
            />

        </>

    )
}

export default HorizontalDateCalendar