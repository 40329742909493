// ========================COMPLETE BOOKING PAYMENT========================

import { gql, useMutation } from "@apollo/client";
import { CANCEL_APPOINTMENT_ERROR, CANCEL_APPOINTMENT_SUCCESS, UPDATE_OCCUPATIONS_ERROR, UPDATE_OCCUPATIONS_SUCCESS } from "../../constants";
import { useDispatch } from "react-redux";

const CANCEL_APPOINTMENT_MUTATION = gql`
  mutation CancelAppointment($appointmentId: Int!) {
    cancelAppointment(appointmentId: $appointmentId) {
      success
      message
    }
  }
`;


export const CancelAppointmentMutationAction = () => {
  const dispatch = useDispatch();
  const [
    CancelAppointmentResponseFunction,
    cancelAppointmentResponseData,
  ] = useMutation(CANCEL_APPOINTMENT_MUTATION)

  //this will be called from components
  const cancelAppointmentQueryData = cancelAppointmentResponseData; //variable

  const initCancelAppointmentData = (data) => {
    CancelAppointmentResponseFunction({
      variables: data,
    })
      // .then(({ data: { CancelAppointment: responseData } }) => {
      .then((responseData) => {
        console.log('myRes', responseData);
        if (responseData.data.cancelAppointment.success) {
          dispatch({
            type: CANCEL_APPOINTMENT_SUCCESS,
            payload: responseData?.data?.cancelAppointment
          })
          // successCallback(responseData)
          // toast.success(responseData.message)
        }
        else {
          dispatch({
            type: CANCEL_APPOINTMENT_ERROR,
          })
          // errorCallback(responseData)
          // toast.error(responseData.message)
        }
      }).catch((err) => {
        console.log('myRes1', err);
        dispatch({
          type: CANCEL_APPOINTMENT_ERROR,
        })
        // errorCallback(err)
        // toast.error("something went wrong!!!")
      })
  }

  return { cancelAppointmentQueryData, initCancelAppointmentData };
};
