import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Badge } from "reactstrap";
import StepButton from "../../common/StepButton";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import SkipButtonComp from "./commonComponents/SkipBtn";
import UploadLogoCover from "../createWebsite/uploadLogoCover";
import UploadImage from "./commonComponents/UploadImage";
import 'aos/dist/aos.css';
import AOS from 'aos';
import ImageTitleComponent from "./commonComponents/ImgTitleComp";
import { GenerateTermsAndConditionsAction } from "../../redux/actions/createWebsite/generateTermsAndConditions";
import { useSelector } from "react-redux";
import { SaveTermsAndConditions } from "../../redux/actions/createWebsite/saveTermsAndConditions";
import loadingIcon from "../../../images/loading.gif";
import { GetWebsiteDetailsAction } from "../../redux/actions/createWebsite/getWebsiteDetails";


const TermsAndCondition = (props) => {
  const { style, isMobile, prevWebOnClick, stepNineData, setCount, count } = props;
  const [headlineText, setHeadlineText] = useState("");

  const [generateButtonClicked, setGenerateButtonClicked] = useState(false);

  const [tempSaveData, setTempSaveData] = useState({
    termsAndConditions: "",
  });

  const { initGenerateTermsAndConditionsResponseFunction, generateTermsAndConditionsResponseData: { loading: generateTCloading } } = GenerateTermsAndConditionsAction();

  const { initiateSaveTermsAndConditions, saveTermsAndConditionsMutationData: { loading: saveTCloading } } = SaveTermsAndConditions();
  const { initGetWebsiteDataResponseFunction, GetWebsiteDetailsResponseData } = GetWebsiteDetailsAction();
  const termsAndConditionsData = useSelector((state) => state.WebsiteDetailsReducer.termsAndConditionsAutoData);

  console.log("termsAndConditionsData", termsAndConditionsData);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-in-out',
    });
    return () => {
      AOS.init();
    };
  }, []);

  const handleHeadlineClick = () => {
    initGenerateTermsAndConditionsResponseFunction();
    setGenerateButtonClicked(true);
  };

  const handleSkipClick = () => {
    props.trackActivity({ activityStep: 13 }, (responseData) => {
      if (responseData.success) {
        initGetWebsiteDataResponseFunction();
      }
    });
    props.setWebsiteData({
      ...props.websiteData,
      termsAndConditions: tempSaveData.termsAndConditions,
    });
    props.setStep(props.step + 1);
  };

  useEffect(() => {
    setHeadlineText(props?.websiteData?.termsAndConditions);
  }, [props.websiteData]);

  useEffect(() => {
    if (termsAndConditionsData?.length > 0 && generateButtonClicked) {
      setHeadlineText(termsAndConditionsData);
      setGenerateButtonClicked(false);
    }
    if (termsAndConditionsData && count.step9 === 0) {
      setTempSaveData({
        termsAndConditions: termsAndConditionsData,
      });
      setCount({
        ...count,
        step9: 1,
      });
    }

  }, [termsAndConditionsData, generateButtonClicked])

  return (
    <>
      <div className="inputWrapper">
        <div className="formWrapper">
          <Form>
            <div className="formInnerHeight" style={style}>
              <ImageTitleComponent {...stepNineData} />
              <FormGroup className="inpt_field" data-aos="fade-up" data-aos-duration={700}>
                <Label
                  className="lable"
                  style={{ color: "var(--neutral-400, #A3A3A3)" }}
                >
                  Terms and Conditions
                </Label>
                <div className="textAreaWrapper">
                  <textarea
                    disabled={generateTCloading}
                    className="textarea"
                    type="text"
                    name="businessName"
                    id="businessName"
                    placeholder="Please write your terms and conditions in here..."
                    // maxlength="500"
                    // minlength="185"
                    style={{
                      paddingBottom: "140px",
                      border: "none",
                      boxShadow: "none",
                    }}
                    value={headlineText}
                    onChange={(e) => setHeadlineText(e.target.value)}
                  ></textarea>
                  {/* <p className="text-area-limit">{headlineText?.length}/500</p> */}
                </div>
                <div className="budge-class">
                  <Badge
                    className="btn tc_budge"
                    onClick={handleHeadlineClick}
                  >
                    {
                      (generateTCloading) ? <div className="m-auto"><span className="pe-3">Generating</span><img src={loadingIcon} style={{ width: "20px", height: "20px" }} /></div> :
                        <span className="m-auto">Use Terms and Conditions Template</span>
                    }
                  </Badge>
                </div>
              </FormGroup>
            </div>

            {/* <UploadImage /> */}
            <div className="FormButtonWrapper">
              <StepButton
                disabled={generateTCloading || headlineText?.length < 1}
                isLoading={saveTCloading}
                blue={true}
                label={props?.editStep ? "Update Changes" : "Next"}
                isLeftIcon={props?.editStep ? true : false}
                icon={!props.editStep ? <FiChevronRight className="arrow_next" /> : ""}
                leftIcon={props?.editStep ? <FiChevronLeft className="arrow_next" /> : ""}
                onClick={(e) => {
                  e.preventDefault();
                  initiateSaveTermsAndConditions({ pwcTermsAndConditions: headlineText }, (responseData) => {
                    props.trackActivity({ activityStep: 13 }, (responseData) => {
                      if (responseData.success) {
                        initGetWebsiteDataResponseFunction();
                      }
                    });
                    if (props?.editStep) {
                      props?.setStep(0);
                    } else {
                      props.setWebsiteData({
                        ...props.websiteData,
                        termsAndConditions: headlineText,
                      });
                      props.setStep(props.step + 1);
                    }
                  }, (error) => { console.log(error) });
                }}
              />
              {!props?.editStep && (
                <SkipButtonComp
                  onClick={handleSkipClick}
                  prevWebOnClick={prevWebOnClick}
                  isMobile={isMobile}
                />
              )}
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;