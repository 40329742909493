import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";


const SAVE_BOOKING_LINK_MUTATION = gql`
mutation SaveBusinessBookingLink($bookingLink: String) {
    saveBusinessBookingLink(bookingLink: $bookingLink) {
      success
      message
      data {
        bookingLink
      }
    }
  }
`;

export const SaveBookingLink = () => {
  const [saveBookingLink, saveBookingLinkResponseData] =
    useMutation(SAVE_BOOKING_LINK_MUTATION);
  const dispatch = useDispatch();
  const saveBookingLinkMutationData = saveBookingLinkResponseData;

  const initiateSaveBookingLink= (
    data,
    successCallback,
    errorCallback
  ) => {
    saveBookingLink({
      variables: data,
    })
      .then(({ data: { saveBusinessBookingLink: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateSaveBookingLink, saveBookingLinkMutationData };
};