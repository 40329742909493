export const GET_BLOCK_OUT_TIME_DATA =  'GET_BLOCK_OUT_TIME_DATA'
export const GET_BLOCK_OUT_TIME_DATA_SUCCESS =  'GET_BLOCK_OUT_TIME_DATA_SUCCESS'
export const GET_BLOCK_OUT_TIME_DATA_ERROR =  'GET_BLOCK_OUT_TIME_DATA_ERROR'
export const CLEAR_BLOCK_OUT_TIME_DATA =  'CLEAR_BLOCK_OUT_TIME_DATA'


export const EDIT_BLOCK_OUT_TIME_DATA =  'EDIT_BLOCK_OUT_TIME_DATA'
export const EDIT_BLOCK_OUT_TIME_DATA_SUCCESS =  'EDIT_BLOCK_OUT_TIME_DATA_SUCCESS'
export const EDIT_BLOCK_OUT_TIME_DATA_ERROR =  'EDIT_BLOCK_OUT_TIME_DATA_ERROR'
export const CLEAR_EDIT_BLOCK_OUT_TIME_DATA =  'CLEAR_EDIT_BLOCK_OUT_TIME_DATA'

