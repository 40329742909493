import React, { useState } from 'react'
import './main-manag-form.scss'
import StepButton from '../common/StepButton';
import Step1Img from '../../images/client/forms-booking-form-icon.png'
import ManageConsentForm from './consent-form/ManageConsentForm';
import ConsentFormCustomModal from '../modal/ConsentFormModal/ConsentFormModal'
import { useNavigate } from 'react-router';
import ManageForms from './consent-form/ManageForms';
import { useDispatch, useSelector } from 'react-redux';
import { GetOnBoardingStatusAction, HideWarningBannerAction } from '../redux/actions/dashboard/getOnBoardingStatus';
import { CLEAR_ONBOARDING_DATA } from '../redux/constants';

const CUSTOMIZE_FORMS_BANNER_TYPE = 6;
const MainManageForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { initHideWarningBannerAction } = HideWarningBannerAction();
    const { initGetOnBoardingStatusData } = GetOnBoardingStatusAction();
    const { dashboardOnboardingData } = useSelector(state => state.DashboardOnboardingReducer);
    const showBanner = dashboardOnboardingData?.bannerData?.find(item => item.bannerType === CUSTOMIZE_FORMS_BANNER_TYPE).bannerShow || false;
    const [currentStep, setCurrentStep] = useState(showBanner ? 1 : 2);
    const [isModalOpen, setIsModalOpen] = useState(true);

    const handleStepChange = () => {
        setCurrentStep(currentStep + 1);
        initHideWarningBannerAction({ uhbtId: CUSTOMIZE_FORMS_BANNER_TYPE }, () => {
            dispatch({ type: CLEAR_ONBOARDING_DATA });
            initGetOnBoardingStatusData();
        });
    }
    const handleModal = () => {
        navigate('/')

    }

    console.log(currentStep, "currentStep==");

    return (
        <div className='Main-Manage-Form'>
            {currentStep === 1 && (
                <ConsentFormCustomModal
                    modalOpen={isModalOpen}
                    setModalOpen={handleModal}
                    className={"CustomiseFormsModel"}
                    modalBody={
                        <>
                            <div>
                                <div className="booking-card-main">
                                    <div className='img-div'>
                                        <img src={Step1Img} alt='step1' width={150} height={150} />
                                    </div>
                                    <div className='booking-card-content'>
                                        <h3>Customise forms</h3>
                                        <p>Adjust your consent and aftercare forms to fit your business.</p>
                                    </div>
                                </div>
                                <StepButton label={"View forms"} blue={true} onClick={handleStepChange} />
                            </div>
                        </>
                    }
                />
            )}
            <ManageForms currentStep={currentStep} setCurrentStep={setCurrentStep} />
        </div>
    )
}

export default MainManageForm