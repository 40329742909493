export const GET_REVIEWS_DATA = 'GET_REVIEWS_DATA'
export const GET_REVIEWS_DATA_SUCCESS = 'GET_REVIEWS_DATA_SUCCESS'
export const GET_REVIEWS_DATA_ERROR = 'GET_REVIEWS_DATA_ERROR'

export const GET_REVIEWS_WEB_DATA = 'GET_REVIEWS_WEB_DATA'


export const CLEAR_REVEWS_DATA = 'CLEAR_REVEWS_DATA'

export const GET_CLIENTS_DATA = 'GET_CLIENTS_DATA'
export const GET_CLIENTS_DATA_SUCCESS = 'GET_CLIENTS_DATA_SUCCESS'
export const CLEAR_CLIENT_LIST = 'CLEAR_CLIENT_LIST'

export const GET_CLIENT_DETAILS = 'GET_CLIENT_DETAILS'
export const GET_CLIENT_TILE_STATUS = 'GET_CLIENT_TILE_STATUS'
export const CLEAR_CLIENT_DETAILS = 'CLEAR_CLIENT_DETAILS'
export const CLEAR_CLIENT_NOTES = "CLEAR_CLIENT_NOTES"
export const GET_CLIENTS_LIST_DATA = "GET_CLIENTS_LIST_DATA"
export const CLEAR_CLIENT_LIST_DATA = "CLEAR_CLIENT_LIST_DATA"
export const GET_CLIENT_NOTES = "GET_CLIENT_NOTES"
export const GET_CLIENT_DETAILS_SUCCESS = 'GET_CLIENT_DETAILS_SUCCESS'
export const UPDATE_CLIENT_TILE_STATUS = 'UPDATE_CLIENT_TILE_STATUS'
export const SAVE_CLIENT_DETAILS_FOR_APPOINTMENT = "SAVE_CLIENT_DETAILS_FOR_APPOINTMENT"


export const GET_CLIENT_STATS = 'GET_CLIENT_STATS'
export const GET_CLIENT_STATS_STATUS = 'GET_CLIENT_STATS_STATUS'
export const CLEAR_CLIENT_STATS = 'CLEAR_CLIENT_STATS'


export const FORWARD_SENDMAIL = 'FORWARD_SENDMAIL'
export const FORWARD_SENDMAIL_SUCCESS = 'FORWARD_SENDMAIL_SUCCESS'
export const FORWARD_SENDMAIL_ERROR = 'FORWARD_SENDMAIL_ERROR'

export const ADD_REVIEWS_DATA = 'ADD_REVIEWS_DATA'
export const ADD_REVIEWS_DATA_SUCCESS = 'ADD_REVIEWS_DATA_SUCCESS'
export const ADD_REVIEWS_DATA_ERROR = 'ADD_REVIEWS_DATA_ERROR'
export const GET_CLIENT_ACCOUNT_DETAILS = 'GET_CLIENT_ACCOUNT_DETAILS'
export const GET_TRAINING_ACCOUNT_DETAILS = 'GET_CLIENT_ACCOUNT_DETAILS'
