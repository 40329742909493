import { combineReducers } from "redux";
import { GetInsuranceDataReducer } from './insurance/insurance';
import { GetSupportChatDataReducer } from './support-chat/support-chat';
import { GetInsuranceCompanyDataReducer } from "./insurance-company/insurance-company";
import businessLocationListReducer from "./businessLocation/businessLocationList";
import businessTreatmentsListReducer from "./treatmentList/treatmentCategoriesListReducer";
import treatmentsDetailReducer from "./treatmentList/treatmentDetailReducer";
import teamMemberListReducer from "./teamMemberReducer/teamMemberListReducer";
import { GetDateTimeSlotsReducer } from "./dateTimeslots/dateTimeslots";
import treatmentBookingreducer from "./treatmentBooking/treatmentBookingReducer";
import { payLaterReducer } from './payLaterReducer/getPayLaterData'
import { financeLoanRequestEmailReducer } from "./payLaterReducer/financeLoanRequestEmail"
import { financeLoanRequestVerificationCodeReducer } from "./payLaterReducer/financeLoanRequestEmail"
import discountReducer from "./getDiscount/getDiscount";

import { OnboardingReducer } from "./dashboard/onboarding";
import { dashboardReducer } from "./dashboard/dashboardList";
import { dashboardGetCartCountReducer } from "./dashboard/getCartCount";
import { appointmentReducer } from "./dashboard/appointments";
import { GetProfileDataReducer } from "./profile/Profile";
import { formsReducer } from "./dashboard/forms";
import { GetReviewsDataReducer } from "./dashboard/Reviews";
import { GetPrescriptionReducer } from "./prescription/prescription";
import WebsiteDetailsReducer from "./createWebsite/websiteDetails";
import { FacesAiReducer } from "./facesai/FacesAiReducer";
import clientListReducer from "./client/ClientListReducer";
import createClientReducer from "./client/CreateClientReducer";
import clientDetailsReducer from "./client/ClientDetailReducer";
import { GetClinicLocationReducer } from "./main-calendar/MainCalendarReducer";
import { DownloadInvoiceReducer, GetInvoiceDetailsReducer } from "./invoice/DownloadInvoiceReducer";
import appointmentListReducer from "./appointment/AppointmentListReducer";
import bookingLinkReducer from "./bookingLink/BookingLinkReducer";
import { newClientDataReducer, newClinicDataReducer } from "./newAppointment/NewAppointmentReducer";
import clientStatsReducer from "./client/ClientStatsReducer";
import nextOfKinDetailsReducer from "./client/NextOfKinDetailsReducer";
import identityDocumentReducer from "./client/IdentityDocumentReducer";
import clientConsentFormListReducer from "./client/ClientConsentFormListReducer";
import appointmentListByTypeReducer from "./appointment/AppointmentListByType";
import treatmentsListForClinicReducer from "./treatmentList/treatmentListForClinicReducer";
import afterCareFormListReducer from "./formTemplateList/getAfterCareFormListReducer";
import consentFormListReducer from "./formTemplateList/getConsentFormListReducer";
import { GetUserProfileDataReducer } from "./profile/GetUserProfile";
import appointmentDetailsReducer from "./appointment/appointmentDetailsReducer";
import appointmentDetailsV3Reducer from "./appointment/appointmentDetailsV3Reducer";
import { GetShareBookingLinkReducer } from "./share-booking-link/ShareBookingLinkReducer";
import appointmentHoldDurationReducer from "./appointment/appointmentHoldDurationReducer";
import appointmentFollowUpsReducer from "./appointment/appointmentFollowUpsReducer";
import clientNoteReducer from "./client/ClientNoteReducer";
import treatmentsListReducer from "./treatmentList/treatmentListReducer";
import { goolgeReviewsreducer } from "./googleReviews/googleReviewsReducer";
import { GetWebsiteBuilderReducer } from "./websiteBuilder/googleReviews";
import blockTimeOutListReducer from "./blockOutTime/BlockTimeOutReducer";
import editBlockTimeOutReducer from "./blockOutTime/EditBlockTimeOutReducer";
import { GetReviewsListDataReducer } from "./dashboard/getReviewList"
import appointmentNoteReducer from "./appointment/appointmentNoteReducer";

import { consentFormProductReducer } from "./consentFormThankyou/consentFormProduct";
import connectedPrescriberListReducer from "./consentFormThankyou/connectedPrescriberListReducer";
import { pharmacyListReducer } from "./consentFormThankyou/pharmacyListReducer";
import { consentFormMoreProductReducer } from "./consentFormThankyou/consentFormMoreProductReducer";
import verifiedBusinessBadgeReducer from "./verifiedBusinessBadgeReducer/verifiedBusinessBadgeReducer";

import { GetAllWebsitePackageReducer } from "./createWebsite/getAllWebsitePackages"
import { GetWebsitePackageReducer } from "./createWebsite/getWebsitePackage"

import HairProductDetailsReducer from '../reducers/hair-prescription-product/HairProductDetailsReducers'
import GetHairProductQuestionsReducer from '../reducers//hair-prescription-product/GetHairProductQuestionsReducers'
import GetHairPrescriptionProductPaymentDetailsReducer from "./hair-prescription-product/GetHairPrescriptionProductPaymentDetailsReducer";
import hairPrescriptionLinkReducer from "./hairPrescriptionLink/HairPrescriptionLinkReducer";
import GetHairPrescriptionAmendAnsersReducer from './hair-prescription-amend-answers/GetHairPrescriptionAmendAmswers'
import { forwardConsentFormOverviewReducer } from "./forwardConsentForm/ForwardConsentFormBookingOverview";
import { paymentMethodAvailableReducer } from "./forwardConsentForm/GetPaymentMethodAvailableReducer";
import { forwardConsentFormQuestionsReducer } from "./forwardConsentForm/GetConsentFormQuestionReducer";
import { clientSecretSetupIntentReducer } from "./forwardConsentForm/GetClientSecretReducer";
import getForwardConsentFormBookingTokenReducer from "./forwardConsentForm/GetForwardConsentFormBookingReducer";
import getSelectedPaymentOptionReducer from "./forwardConsentForm/getSelectedPaymentOption";
import appointmentListClinicsReducer from "./appointment/AppointmentListClinicsReducer";
import prescriptionsListReducer from "./PrescriptionsListReducer";



import walkInClientListReducer from '../reducers/walk-in-form-reducer/clientWalkInReducer'
import walkInClientNameReducer from '../reducers/walk-in-form-reducer/newAppointmentReducer'
import formsListReducer from "./formTemplateList/getFormsListReducer";
import myformsListReducer from "./formTemplateList/getMyformsListReducer";
import getNotificationListreducer from "./headerNotification/getNotificationReducer"
import { DashboardOnboardingReducer } from "./dashboard/dashboardOnboarding";

export const rootReducer = combineReducers({
  GetInsuranceDataReducer,
  GetSupportChatDataReducer,
  GetInsuranceCompanyDataReducer,

  businessLocationListReducer,
  businessTreatmentsListReducer,
  treatmentsDetailReducer,
  teamMemberListReducer,
  GetDateTimeSlotsReducer,
  treatmentBookingreducer,
  discountReducer,
  GetPrescriptionReducer,
  OnboardingReducer,
  dashboardReducer,
  appointmentReducer,
  GetProfileDataReducer,
  formsReducer,
  GetReviewsDataReducer,
  payLaterReducer,
  WebsiteDetailsReducer,
  GetReviewsListDataReducer,
  walkInClientListReducer,
  walkInClientNameReducer,


  FacesAiReducer,
  clientListReducer,
  createClientReducer,
  clientDetailsReducer,
  DownloadInvoiceReducer,
  appointmentListReducer,

  GetClinicLocationReducer,
  bookingLinkReducer,
  newClientDataReducer,
  newClinicDataReducer,
  GetInvoiceDetailsReducer,
  clientStatsReducer,
  nextOfKinDetailsReducer,
  identityDocumentReducer,
  clientConsentFormListReducer,
  appointmentListByTypeReducer,
  treatmentsListForClinicReducer,
  afterCareFormListReducer,
  myformsListReducer,
  consentFormListReducer,
  GetUserProfileDataReducer,
  appointmentDetailsReducer,
  appointmentDetailsV3Reducer,
  appointmentFollowUpsReducer,
  appointmentHoldDurationReducer,

  GetShareBookingLinkReducer,
  clientNoteReducer,
  treatmentsListReducer,
  dashboardGetCartCountReducer,
  goolgeReviewsreducer,
  GetWebsiteBuilderReducer,
  blockTimeOutListReducer,
  editBlockTimeOutReducer,
  appointmentNoteReducer,
  formsListReducer,

  consentFormProductReducer,
  connectedPrescriberListReducer,
  pharmacyListReducer,
  consentFormMoreProductReducer,
  verifiedBusinessBadgeReducer,
  GetAllWebsitePackageReducer,
  GetWebsitePackageReducer,
  HairProductDetailsReducer,
  GetHairProductQuestionsReducer,
  hairPrescriptionLinkReducer,
  GetHairPrescriptionProductPaymentDetailsReducer,
  GetHairPrescriptionAmendAnsersReducer,
  financeLoanRequestEmailReducer,
  financeLoanRequestVerificationCodeReducer,
  forwardConsentFormOverviewReducer,
  paymentMethodAvailableReducer,
  forwardConsentFormQuestionsReducer,
  clientSecretSetupIntentReducer,
  getForwardConsentFormBookingTokenReducer,
  getSelectedPaymentOptionReducer,
  appointmentListClinicsReducer,
  prescriptionsListReducer,
  DashboardOnboardingReducer,
  getNotificationListreducer,
})
