import React, { useState,useEffect } from 'react'
import { CompleteForwardConsentFormPaymentBooking } from '../../redux/actions/forward-consent-form/ForwardConsentFormBooking';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import FeedBackThanks from '../../modal/FeedBackModal/FeedBackThanks';
import FeedBackModal from '../../modal/FeedBackModal/FeedBackModal';
import { ReactComponent as CloseIcon } from '../../../images/Icon example.svg';
import FailedIcon from '../../../images/fail.png';
import { ReactComponent as SuccessIcon } from '../../../images/success.svg';
import { useSelector } from 'react-redux';
import CancleModal from '../../modal/CancleModal/CancleModal';
import StepButton from '../../common/StepButton';


const SuccessForwardConsentForm = () => {
    const [isCancle, setIsCancle] = useState(false)
    const [isFeedBack, setIsFeedBack] = useState(false);
    const [isFeedBackThanks, setIsFeedBackThanks] = useState(false);
    const navigate = useNavigate()
    const { initiateCompleteForwardConsentFormPaymentBooking,completeForwardConsentFormPaymentBookingMutationData } = CompleteForwardConsentFormPaymentBooking()
    const location = useLocation();
    const { slug, status } = useParams()
    const [consentFormLink, setConsentFormLink] = useState("")
    const [isShowFeedbackLink, setIsShowFeedbackLink] = useState(true)
    const data = location?.state;
    const [consentbookingId, setConsentBookingId] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const [cfrToken,setCfrToken] = useState(null);
    const [isThankOpen, setIsThankOpen] = useState(false)
    const [confirmBookingData, setConfirmBookingData] = useState("")
    const [retryLoader,setRetryLoader] = useState(false)
  
    const paylaterData = useSelector(state => state?.payLaterReducer?.payLaterData)
  
  
    const [searchParams, setSearchParams] = useSearchParams();
    const { bookingId } = useParams()
    useEffect(() => {
      setConsentBookingId(+bookingId);
      setPaymentId(searchParams.get("ps"));
      setCfrToken(searchParams?.get("token"))
    }, [searchParams]);
  
  
    const openInNewTab = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
    //We get bookingid and paymentid from router state
    useEffect(() => {
      if (bookingId != undefined && paymentId != "") {
        let tempPaymentId = paymentId ? paymentId : ""
        initiateCompleteForwardConsentFormPaymentBooking({
          bookingId: parseInt(bookingId),
          paymentId: tempPaymentId,
          cfrToken:searchParams?.get('token')
        }, (res) => {
          if (res.success) {
            // console.log(res, "vishal")
            setConfirmBookingData(res?.data)
            setIsShowFeedbackLink(!(res?.data?.review_added))
            setConsentFormLink(res?.data?.consent_form_link)
          } else {
            setConsentFormLink(undefined)
          }
        }, (e) => {
          setConsentFormLink(undefined)
        })
      }
    }, [bookingId, paymentId])
  
    console.log("booking id",bookingId)
    const handleConsentForm = () => {
      openInNewTab(consentFormLink ? consentFormLink : paylaterData?.consent_form_link)
    }
  
  
    const openFeedBackThanksModal = () => {
      setIsFeedBackThanks(true);
      setConsentBookingId(data?.bookingId)
      setIsFeedBack(false); // Close FeedBackModal
    };

    const handleRetry = () => {
      setRetryLoader(true)
      setTimeout(()=>{
        setRetryLoader(false)
        navigate(`/forward-consent-form/${bookingId}?token=${cfrToken}`)
      },2000)
    }
  
  
    return (
      <>
        {
            status === "fail" ?
              <div className="container">
                <>
                  <CloseIcon className="cursor-pointer" onClick={() => navigate(`/forward-consent-form/${bookingId}?token=${cfrToken}`)} />
                  <div className="success-page-conatiner">
                    <img src={FailedIcon} alt="" style={{ width: "100px", height: "100px" }} />
                    <h1>Booking Failed!</h1>
                    <p>Check your email for details. Remember to complete the consent form before your appointment.</p>
                    <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '30%' }}>
                    <StepButton blue={true} label={"Retry"} onClick={handleRetry} isLoading={retryLoader}/>
                    </div>
                  </div>
                </>
              </div> :

              status == "abort" ?
              <div className="container">
              <>
                <CloseIcon className="cursor-pointer" onClick={() => navigate(`/forward-consent-form/${bookingId}?token=${cfrToken}`)} />
                <div className="success-page-conatiner">
                  <img src={FailedIcon} alt="" style={{ width: "100px", height: "100px" }} />
                  <h1>Booking Failed!</h1>
                  <p>Check your email for details. Remember to complete the consent form before your appointment.</p>
                  <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '30%' }}>
                    <StepButton blue={true} label={"Retry"} onClick={handleRetry}  isLoading={retryLoader}/>
                  </div>
                </div>
              </>
            </div>
            :
              <div className="container">
                <>
                  <CloseIcon className="cursor-pointer" onClick={() => navigate(`/forward-consent-form/${bookingId}?token=${cfrToken}`)} />
                  <div className="success-page-conatiner">
                    <SuccessIcon />
                    <h1>Booking Confirmed!</h1>
                    <p>Check your email for details. Remember to complete the consent form before your appointment.</p>
                    {/* <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '50%' }}>
  
                      <StepButton blue={true} label={"Complete Consent"} onClick={() => handleConsentForm()} />
  
                    </div> */}
                    
                  </div>
                 
  
                </>
              </div>
  
        }
  
      </>
    );
  };

export default SuccessForwardConsentForm