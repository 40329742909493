import "./next-of-kin.scss";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "../common/TextInput";
import StepButton from "../common/StepButton";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import PropTypes from 'prop-types'
import en from 'react-phone-number-input/locale/en'
import { CreateNextOfKin } from "../redux/actions/client/CreateNextOfKin";
import { toast } from "react-toastify";
import { GetClientDetails } from "../redux/actions/client/GetClientDetails";
import { useDispatch } from "react-redux";
import { GET_CLIENT_DETAILS, GET_CLIENT_TILE_STATUS } from "../redux/constants";
import { numberRegex } from "../../utils/validationRegx";
import AppointmentToast from "../new-appointment/AppointmentToast";
import showToast from "../common/CustomToast/CustomToast";
import { GetNextOfKinDetails } from "../redux/actions/client/GetNextOfKinDetails";

const NextOfKin = ({ setIsNextOfKin, clientId, setNextOfKinData, nextOfKinData, setIsNextOfKinAdded, fromProfile = false, fromOffCanvas = false }) => {
  //Declare Mutation
  const { createNextOfKinMutationData, initiateCreateNextOfKin } = CreateNextOfKin()
  const { clientDetailsQueryData, initGetClientDetails } = GetClientDetails()
  const { initGetNextOfKinDetails, nextOfKinDetailsQueryData } = GetNextOfKinDetails()
  const [formIncorrect, setFormIncorrect] = useState(false);

  const [value, setValue] = useState('');
  const [countryCode, setCountryCode] = useState("GB")
  const [selectedCountryLabel, setSelectedCountryLabel] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    initGetNextOfKinDetails({
      clientId: parseInt(clientId)
    }, (res) => {
      if (res.success) {
        setNextOfKinData(res.data)
        setCountryCode(res.data.countryCode.toUpperCase())
      } else {
        setNextOfKinData(null)
      }
    }, (err) => {
      setNextOfKinData(null)
      toast.error(err.message)
    })
  }, [])

  // Handler for TextInput change events
  const handleInputChange = (field, value) => {
    setNextOfKinData((prevData) => ({
      ...prevData,
      [field]: value
    }));
    setFormIncorrect(false);
    // Trigger validation function and handle it accordingly
    const isValid = validateField(field, value);
    // Handle validation result (e.g., update state, show error message, etc.)
  };

  const handleSubmitData = () => {
    //create next of kin API call
    initiateCreateNextOfKin({
      clientId: parseInt(clientId),
      firstName: nextOfKinData?.firstName,
      lastName: nextOfKinData?.lastName,
      relation: nextOfKinData?.relation,
      phone: nextOfKinData?.phone?.toString(),
      phoneCountry: getCountryCallingCode(countryCode),
      countryCode: countryCode
    }, (resNextOfKin) => {
      if (resNextOfKin.success) {
        initGetClientDetails({
          clientId: parseInt(clientId)
        })
        showToast("success", resNextOfKin?.message, "", false)
        setIsNextOfKinAdded(true)
        setIsNextOfKin(false)

      } else {
        showToast("error", resNextOfKin?.message, "", false)
        setIsNextOfKinAdded(false)
        setIsNextOfKin(true)
        setFormIncorrect(true);
        console.log("first name res", resNextOfKin?.data)
      }
    }, (errNextOfKin) => {
      console.log("error on create next of kin", errNextOfKin)
    })

  }

  //when it comes from profile
  const closeNextOfKinModal = () => {
    setIsNextOfKin(false)
    setIsNextOfKinAdded(false)
  }

  console.log("client id", clientId, clientDetailsQueryData, "next of kin data from profile", nextOfKinData)


  const handleCountryChange = (country) => {
    setFormIncorrect(false);
    setCountryCode(country)
    console.log(country)
    // Get the label based on the selected country
    const selectedLabel = getCountries().includes(country)
      ? `+${getCountryCallingCode(country)}`
      : "";

    setSelectedCountryLabel(selectedLabel);
    setNextOfKinData((prevData) => ({
      ...prevData,
      phoneCountry: selectedCountryLabel
    }))
  }

  // Example validation function for the whole form
  // const isFormValid = () => {
  //   return Object.values(nextOfKinData).every((value, key) => {
  //     console.log("value from next of kin",value,key)
  //     const isValidField = validateField(key, value);
  //     return value?.trim().length > 0 && isValidField;
  //   });
  // };


  //for countrySelection
  const CountrySelect = ({ value, onChange, labels, ...rest }) => (
    <div className="country-selection-container-next-of-kin">
      <select
        {...rest}
        value={value}
        defaultValue={getCountries().includes(value) ? value : undefined}
        onChange={event => onChange(event.target.value || undefined)}
      // disabled={fromProfile}
      >
        <option value="">
          {labels['ZZ']}
        </option>
        {getCountries().map((country, i) => (
          country !== 'International' &&
          <option key={country} value={country}>
            {"+"}{getCountryCallingCode(country)}
          </option>


        ))}
      </select>
    </div>
  )

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired
  }

  //Text input for country phonenumber
  const CustomInput = React.forwardRef(({ value, onChange, ...rest }, ref) => {
    const inputRef = useRef(null);

    useEffect(() => {
      // Set the focus to the input element whenever the value changes
      // inputRef.current.focus();
    }, [value]);
    // Ensure that onChange is called with the event
    const handleChange = (newValue, event) => {
      if (newValue !== value && (newValue || value)) {
        onChange(newValue, event);
      }

    };

    return (
      <TextInput
        value={value}
        ref={(input) => {
          ref(input);
          inputRef.current = input;
        }}
        onChange={handleChange}
        {...rest}
      />
    );
  });

  //VAlidation
  const validateField = (field, value) => {
    if (isFormSubmitted) {
      switch (field) {
        case "firstName":
        case "lastName":
        case "relation":
          return value.trim().length > 0;
        default:
          return true; // Default to true if no specific validation for the field
      }
    }
    return true; // Return true if form is not submitted, so errors are not displayed by default
  };



  return (
    <div className="next-of-kin-container">
      <div className="main-container">
        <h1 className="m-0">Next of kin</h1>
        <div className="w-100 error-section m-0">
          <TextInput
            label="First name"
            autoFocus={false}
            name="firstName"
            defaultValue={nextOfKinData?.firstName}
            placeHolder="First name"
            value={nextOfKinData?.firstName}

            onChange={(e) => handleInputChange("firstName", e.target.value)}
          />
          {isFormSubmitted && !fromProfile && nextOfKinData.firstName === "" && (<span className="text-danger pt-2">Please enter first name</span>)}
        </div>
        <div className="w-100 error-section">
          <TextInput
            label="Last name"
            autoFocus={false}
            defaultValue={nextOfKinData?.lastName}
            placeHolder="Last name"
            name="lastName"
            value={nextOfKinData?.lastName}

            onChange={(e) => handleInputChange("lastName", e.target.value)}
          />
          {isFormSubmitted && !fromProfile && nextOfKinData.lastName === "" && (<span className="text-danger pt-2">Please enter last name</span>)}
        </div>
        <div className="w-100 error-section">
          <TextInput
            label="Relationship to you"
            autoFocus={false}
            name="relation"
            defaultValue={nextOfKinData?.relation}
            placeHolder="e.g. Partner"
            value={nextOfKinData?.relation}

            onChange={(e) => handleInputChange("relation", e.target.value)}
          />
          {isFormSubmitted && !fromProfile && nextOfKinData.relationship === "" && (<span className="text-danger pt-2">Please enter relationship</span>)}
        </div>
        <div className="row next-of-kin-phone-section mt-2 g-3 w-100">
          <p className="m-0">Your phone number</p>
          <div className="col-5 col-sm-6 col-xl-4 mt-2">
            <CountrySelect labels={{ 'ZZ': 'Select Country' }} value={countryCode} onChange={(e) => handleCountryChange(e)} />
          </div>
          <div className="col-7 col-sm-6 col-xl-8 mt-2 pe-0">
            <div className="w-100 error-section">
              <TextInput
                name="phone"
                maxLength={10}

                className="phone-input"
                value={nextOfKinData?.phone}
                placeholder="Enter phone number"
                onChange={(e) => { if (numberRegex.test(e.target.value) || e.target.value == "") { handleInputChange("phone", e.target.value) } }}
              />
            </div>
          </div>
        </div>
        <StepButton label={fromProfile ? "Done" : "Save"} blue={true} isLoading={createNextOfKinMutationData?.loading} disabled={!nextOfKinData?.firstName || !nextOfKinData?.lastName || !nextOfKinData?.relation || createNextOfKinMutationData?.loading || formIncorrect} onClick={() => { setIsFormSubmitted(true); handleSubmitData() }} />
      </div>
    </div>
  );
};

export default NextOfKin;
