import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from 'react-redux'
import { GET_REVIEWS_WEB_DATA } from "../../constants/dashboard/Reviews"

const GET_REVIEW_LIST_QUERY = gql`
query GetReviewList($reviewType: String!) {
    getReviewList(reviewType: $reviewType) {
      success
      message
      data {
        reviews {
          userName
          reviewDate
          reviewFrom
          userRating
          userReview
          userImage
        }
        total_reviews
        average_rating
      }
    }
  }
`;

export const GetReviewListAction = () => {
    const dispatch = useDispatch();
    const [
        GetReviewListResponseFunction,
        getReviewListResponseData,
    ] = useLazyQuery(GET_REVIEW_LIST_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getReviewList: responseData }) => {
            console.log("responseDataresponseDataresponseData", responseData)
            if (responseData.success) {
                dispatch({
                    type: GET_REVIEWS_WEB_DATA,
                    payload: responseData?.data
                })
            } else {
                // toast.error(responseData?.message)
            }
        },
    });
    //this will be called from components
    const getReviewListQueryData = getReviewListResponseData; //variable

    const initGetReviewListData = (data) => {
        GetReviewListResponseFunction({
            variables: data,
        });
    };

    return { getReviewListQueryData, initGetReviewListData };
};