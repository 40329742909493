import { useEffect, useRef, useState } from 'react';
import { ReactComponent as EditIcon } from '../../../../images/icons/edit-name-icon.svg';
import { ReactComponent as CloseIcon } from '../../../../images/icons/close-edit-icon.svg';
import '../edit-main.scss';

function EditableTitle({ isEditing, text, toggleEditing, onTextChange }) {
    const nameInputRef = useRef();
    const [isEditingLocalState, setIsEditingLocalState] = useState(isEditing);

    useEffect(() => {
        setIsEditingLocalState(isEditing);

        // focus input when editing is triggered from parent component
        if(isEditing) {
            setTimeout(() => {
                nameInputRef.current.focus();
            }, 100);
        }
    }, [isEditing]);
    return (
        <div className="form-title form-title-editable">
            {!isEditingLocalState && text.length > 0 && (
                <>
                    <p>{text}</p>
                    <button
                        onClick={() => {
                            setIsEditingLocalState(true);
                            toggleEditing && toggleEditing(true);
                            setTimeout(() => {
                                nameInputRef.current.focus();
                            }, 0);
                        }}
                        className="form-title-editable__btnEdit"
                    >
                        <EditIcon />
                    </button>
                </>
            )}
            {isEditingLocalState && (
                <div className="form-title-editable__editWrapper">
                    <input
                        placeholder="Enter form name"
                        onChange={(e) => onTextChange(e.target.value)}
                        ref={nameInputRef}
                        value={text}
                        id="form-name"
                    />
                    {text.length > 0 && 
                    <button
                        disabled={text.length === 0}
                        onClick={() => {
                            if (text.length > 0) {
                                setIsEditingLocalState(false);
                                toggleEditing && toggleEditing(false);
                            }
                        }}
                        className="form-title-editable__btnDone"
                        // className="form-title-editable__btnEdit"
                    >
                        Done
                        {/* <CloseIcon /> */}
                    </button>}
                </div>
            )}
        </div>
    );
}
export default EditableTitle;
