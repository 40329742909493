


import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie';
import close_icon from "../../../../images/close_icon.svg";
import "./welcomeScreenThankYou.scss";
import thankyou from "../../../../images/thank-you/success.svg"
import { FaWhatsapp } from "react-icons/fa6";
import thankAnimation from "../../../../assets/lottieanimation/Animation - 1712039535881.json"
import { businessDashboardRoute } from "../../../../Routes"
import { useNavigate, useLocation } from 'react-router';
import useWindowDimensions from '../../../../utils/useWindowDimensions';


const WelcomeScreenThankYou = () => {
  const { width, height } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false)
  const location = useLocation();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: thankAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // console.log('Current pathname:', location.pathname);


  // useEffect(() => {
  //   const preventBackNavigation = (event) => {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     window.history.pushState(null, null, window.location.pathname)
  //   };

  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener('popstate', preventBackNavigation);

  //   return () => {
  //     window.removeEventListener('popstate', preventBackNavigation);
  //   };
  // }, []);

  // useEffect(() => {
  //   window.addEventListener('beforeunload', function (event) {
  //     // Cancel the event
  //     event.preventDefault();
  //     // Chrome requires returnValue to be set
  //     event.returnValue = '';
  //   });
  // }, []);


  // console.log("addEventListener", window.location, window.history, window.location.href)



  useEffect(() => {
    if (width < 767) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [width])

  const navigate = useNavigate()
  return (

    <div className='main-thankyou-welcome-sreen'>
      <div className='TopHeader-welcome-sreen'>
        <div className='header-thankyou-welcome-sreen'>
          {!isMobile && width > 767 && <img src={close_icon} alt='close_icon' onClick={() => { navigate(businessDashboardRoute()) }} />}
        </div>
      </div>
      <div className='main-thakyou-body-welcome-sreen'>
        <div className='main-body-welcome-sreen'>
          <div className='congration-wrapper-thankyou-welcome-sreen'>
            <Lottie options={defaultOptions}
              width={"388px"}
              height={"300px"}
            />
          </div>
          <div className='thankyou-body-welcome-sreen'>
            <div><img src={thankyou} alt='thankyou' /></div>
            <div className='header-text-thankyou-welcome-sreen'>Thank you</div>
            <div className='context-thankyou-welcome-sreen'>Thank you for your payment, one of our customer representative will getting touch with you.</div>
            {isMobile && width < 767 && <div>Please close the window.</div>}
          </div>
        </div>
        <div className="whatsappBtn-thankyou-welcome-sreen">
          <button className="whatsappBtn-welcome-sreen" onClick={() => { window.open(process.env.REACT_APP_WHATSAPP_LINK, '_blank') }}>
            <span className="WpIcon-welcome-sreen">
              <FaWhatsapp />
            </span>
            Contact support
          </button>
        </div>
      </div>
      <div className='last-div' />
    </div>)
}


export default WelcomeScreenThankYou
