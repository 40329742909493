import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const GET_PRESCRIBE_ON_FACES_QUERY = gql`
query GetPrescribeOnFacesOption {
    getPrescribeOnFacesOption {
      success
      message
      data {
        prescribeOption
      }
    }
  }
`;
export const GetPrescribeOnFaces = () => {
  const dispatch = useDispatch();
  const [getPrescribeOnFaces, { loading, error, data }] = useLazyQuery(GET_PRESCRIBE_ON_FACES_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: (response) => {
      // Handle the completed response here if necessary
    },
  });

  const initGetPrescribeOnFaces = () => {
    getPrescribeOnFaces();
  };

  return { loading, error, data, initGetPrescribeOnFaces};
};

const SAVE_PRESCRIBE_ON_FACES_OPTION_MUTATION = gql`
mutation SavePrescribeOnFacesOption($prescribeOption: Int!) {
  savePrescribeOnFacesOption(prescribeOption: $prescribeOption) {
    success
    message
  }
}

`;
export const SavePrescribeOnFacesOption = () => {
  const [SavePrescribeOnFacesOptionData, { data: savePrescribeOnFacesOptionResponseData }] =
    useMutation(SAVE_PRESCRIBE_ON_FACES_OPTION_MUTATION);

  const initiateSavePrescribeOnFacesOption = (variables, successCallback, errorCallback) => {
    SavePrescribeOnFacesOptionData({ variables })
      .then(({ data }) => {
        if (data.savePrescribeOnFacesOption.success) {
          successCallback(data.savePrescribeOnFacesOption);
        } else {
          errorCallback(data.savePrescribeOnFacesOption);
        }
      })
      .catch((error) => {
        // Handle error
        errorCallback(error);
      });
  };

  return { initiateSavePrescribeOnFacesOption, savePrescribeOnFacesOptionResponseData };
};

const GET_PRESCRIBING_PREFERENCE_QUERY = gql`
query GetPrescribingPreferences {
  getPrescribingPreferences {
    success
    message
    data {
      selectedProfessions
      displayName
      PrescriptionFee
      medicalFormMandatory
      autoConnectPractitioner
      profileLink
    }
  }
}
`;
export const GetPrescribingPreference = () => {
  const dispatch = useDispatch();
  const [getPrescribingPreference, { loading, error, data }] = useLazyQuery(GET_PRESCRIBING_PREFERENCE_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: (response) => {
    },
  });

  const initGetPrescribingPreference = () => {
    getPrescribingPreference();
  };

  return { loading, error, data, initGetPrescribingPreference};
};


const SAVE_PRESCRIBING_PREFERENCE_MUTATION = gql`
mutation SavePrescribingPreferences($selectedProfessions: Int!, $displayName: String!, $prescriptionFee: Float!, $medicalFormMandatory: Int, $autoConnectPractitioner: Int) {
  savePrescribingPreferences(selectedProfessions: $selectedProfessions, displayName: $displayName, PrescriptionFee: $prescriptionFee, medicalFormMandatory: $medicalFormMandatory, autoConnectPractitioner: $autoConnectPractitioner) {
    success
    message
  }
}

`;
export const SavePrescribingPreference = () => {
  const [SavePrescribingPreferenceData, { data: savePrescribingPreferenceResponseData }] =
    useMutation(SAVE_PRESCRIBING_PREFERENCE_MUTATION);

  const initiateSavePrescribingPreference = (variables, successCallback, errorCallback) => {
    SavePrescribingPreferenceData({ variables })
      .then(({ data }) => {
        if (data.savePrescribingPreferences.success) {
          successCallback(data.savePrescribingPreferences);
        } else {
          errorCallback(data.savePrescribingPreferences);
        }
      })
      .catch((error) => {
        // Handle error
        errorCallback(error);
      });
  };

  return { initiateSavePrescribingPreference, savePrescribingPreferenceResponseData };
};

const GET_PRESCRIBING_CODE_QUERY = gql`
query GetVerificationCodeForPrescribers {
  getVerificationCodeForPrescribers {
    success
    message
    data {
      verificationCode
    }
  }
}
`;
export const GetVerificationCodeForPrescribers = () => {
  const dispatch = useDispatch();
  const [getVerificationCode, { loading, error, data }] = useLazyQuery(GET_PRESCRIBING_CODE_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: (response) => {
    },
  });

  const initGetVerificationCodeForPrescribers = () => {
    getVerificationCode();
  };
  return { loading, error, data, initGetVerificationCodeForPrescribers};
};


const SAVE_PRESCRIBING_CODE_MUTATION = gql`
mutation SaveVerificationCodeForPrescribers($verificationCode: Int!) {
  saveVerificationCodeForPrescribers(verificationCode: $verificationCode) {
    success
    message
  }
}

`;
export const SaveVerificationCodeForPrescribers = () => {
  const [SaveVerificationCodeForPrescribersData, { data: saveVerificationCodeForPrescribersResponseData }] =
    useMutation(SAVE_PRESCRIBING_CODE_MUTATION);

  const initiateSaveVerificationCodeForPrescribers = (variables, successCallback, errorCallback) => {
    SaveVerificationCodeForPrescribersData({ variables })
      .then(({ data }) => {
        if (data.saveVerificationCodeForPrescribers.success) {
          successCallback(data.saveVerificationCodeForPrescribers);
        } else {
          errorCallback(data.saveVerificationCodeForPrescribers);
        }
      })
      .catch((error) => {
        // Handle error
        errorCallback(error);
      });
  };

  return { initiateSaveVerificationCodeForPrescribers, saveVerificationCodeForPrescribersResponseData };
};

const CONFIRM_PRESCRIBING_CODE_MUTATION = gql`
mutation ConfirmVerificationCodeForPrescribers($verificationCode: Int!) {
  confirmVerificationCodeForPrescribers(verificationCode: $verificationCode) {
    success
    message
  }
}
`;
export const ConfirmVerificationCodeForPrescribers = () => {
  const [ConfirmVerificationCodeForPrescribersData, { data: confirmVerificationCodeForPrescribersResponseData }] =
    useMutation(CONFIRM_PRESCRIBING_CODE_MUTATION);

  const initiateConfirmVerificationCodeForPrescribers = (variables, successCallback, errorCallback) => {
    ConfirmVerificationCodeForPrescribersData({ variables })
      .then(({ data }) => {
        if (data.confirmVerificationCodeForPrescribers.success) {
          successCallback(data.confirmVerificationCodeForPrescribers);
        } else {
          errorCallback(data.confirmVerificationCodeForPrescribers);
        }
      })
      .catch((error) => {
        // Handle error
        errorCallback(error);
      });
  };

  return { initiateConfirmVerificationCodeForPrescribers, confirmVerificationCodeForPrescribersResponseData };
};

