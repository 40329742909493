import { APPOINTMENT_LIST_LOADER_COMPLETE, APPOINTMENT_LIST_LOADER_START, CANCEL_APPOINTMENT_SUCCESS, CHANGE_LOADED_APPOINTMENT_COUNT, CLEAR_APPOINTMENT_DOCUMENT_TAB_DATA, CLEAR_APPOINTMENT_LIST, CLEAR_APPOINTMENT_MANAGE_TAB_DATA, CLEAR_APPOINTMENT_STATUS, CLEAR_CANCEL_APPOINTMENT_STATUS, CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT, GET_APPOINTMENT_DETAILS_ERROR, GET_APPOINTMENT_DETAILS_SUCCESS, GET_APPOINTMENT_LIST, GET_APPOINTMENT_LIST_SUCCESS, GET_DASHBOARD_LIST_SUCCESS, GET_TREATMENT_WISE_BEFORE_AFTER_COUNT, SET_APPOINTMENT_DOCUMENT_TAB_DATA, SET_APPOINTMENT_MANAGE_TAB_DATA } from "../../constants"


const initialState = {
  dashboardAppointmentListData: null,
  appointmentListData: null,
  totalAppointmentCount: 0,
  loadedAppointmentCount: 0,
  appointmentListPage: 0,
  appointmentDetailsStatus: false,
  appointmentListLoader: false,
  cancelAppointmentStatus: false,
  cancelAppointmentSuccessMessage: null,
  nextAppointmentDate: null,
  businessBoosterData: null,

  doucumentTabStatus: false,
  docTabData: null,
  manageTabData: null,
  treatmentWiseBeforeAfterCount: null
}
export const appointmentReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_APPOINTMENT_LIST:
      
      let updateState = {
        appointmentListPage: action.payload?.page,
        // treatmentsList: action.payload.data
      };
      if (action.payload?.page === 1) {
        // updateState["dashboardAppointmentListData"] = [];
        updateState["appointmentListData"] = [];
        updateState["totalAppointmentCount"] = 0;
        updateState["loadedAppointmentCount"] = 0;
      }
      return {
        ...state,
        ...updateState
      }
    case GET_APPOINTMENT_LIST_SUCCESS:


      let appointmentListTemp = state.appointmentListData,
        loadedAppointmentCountTemp = state.loadedAppointmentCount
          ? state.loadedAppointmentCount
          : 0;
      let newTreatmentsTemp = action.payload.data.appointment_list;

      if (newTreatmentsTemp && newTreatmentsTemp.length) {

        let existingItemIdsTemp = []
        if (appointmentListTemp?.length > 0) {
          existingItemIdsTemp = appointmentListTemp?.map((item, index) => {
            return item.cld_rc_id;
          })
        }

        const parsedItemIdsTemp = newTreatmentsTemp.map((item, index) => {
          return item.cld_rc_id;
        });
        let toBeAddedItemsTemp = [];
        if (appointmentListTemp?.length > 0)
          appointmentListTemp = appointmentListTemp?.map((item, index) => {
            if (parsedItemIdsTemp.indexOf(item.cld_rc_id) != -1) {
              let parsedFeedItemIndex = parsedItemIdsTemp.indexOf(item.cld_rc_id);
              item = newTreatmentsTemp[parsedFeedItemIndex];
            }
            return item;
          });

        toBeAddedItemsTemp = newTreatmentsTemp.filter((item, index) => {
          return existingItemIdsTemp.indexOf(item.cld_rc_id) == -1;
        });




        loadedAppointmentCountTemp += toBeAddedItemsTemp.length;
        if (appointmentListTemp != null)
          appointmentListTemp = [...appointmentListTemp, ...toBeAddedItemsTemp];
        else
          appointmentListTemp = [...toBeAddedItemsTemp];
      }


      console.log('loadedAppointmentCountTemp ', loadedAppointmentCountTemp, state.loadedAppointmentCount);
      return {
        ...state,
        // dashboardAppointmentListData: action.payload.frontEndPayload["appointment-list-day-type"] == "" ? action.payload.data.appointment_list : state.dashboardAppointmentListData,
        dashboardAppointmentListData: action.payload.data.appointment_list,
        appointmentListData: appointmentListTemp,
        nextAppointmentDate: action.payload.data.next_appointment_date,
        totalAppointmentCount: action.payload.data.total_appointments,
        loadedAppointmentCount: loadedAppointmentCountTemp,
        appointmentListLoader: false,
        appointmentListPage: action.payload.page
          ? action.payload.page
          : state.appointmentListPage,
      };


    case GET_APPOINTMENT_DETAILS_SUCCESS:
      return {
        ...state,
        appointmentDetails: action.payload.appointment_details,
        doucumentTabStatus: action.payload.booking_status_details?.consent_form_status,
        bookingDetails: action.payload.booking_status_details,
        appointmentDetailsStatus: action.payload.success,
        businessBoosterData: action?.payload
      }


    case SET_APPOINTMENT_DOCUMENT_TAB_DATA:
      return {
        ...state,
        docTabData: action.payload.data
      }

    case SET_APPOINTMENT_MANAGE_TAB_DATA:
      console.log(action.payload.data, 'action.payload check')
      return {
        ...state,
        manageTabData: action.payload.data
      }


    case GET_APPOINTMENT_DETAILS_ERROR:
      return {
        ...state,
        appointmentDetails: null,
        bookingDetails: null,
        appointmentDetailsStatus: action.payload.success
      }

    case CLEAR_APPOINTMENT_STATUS:
      return {
        ...state,
        appointmentDetailsStatus: false,
        appointmentDetails: null,
        bookingDetails: null,
      }

    case APPOINTMENT_LIST_LOADER_START:
      return {
        ...state,
        appointmentListLoader: true,
      }

    case APPOINTMENT_LIST_LOADER_COMPLETE:
      return {
        ...state,
        appointmentListLoader: false,
      }

    case CANCEL_APPOINTMENT_SUCCESS:
      return {
        ...state,
        cancelAppointmentStatus: action.payload.success,
        cancelAppointmentSuccessMessage: action.payload.message,
      }

    case CLEAR_CANCEL_APPOINTMENT_STATUS:
      return {
        ...state,
        cancelAppointmentStatus: false,
        cancelAppointmentSuccessMessage: null
      }

    case CLEAR_APPOINTMENT_LIST:
      return {
        ...state,
        appointmentListData: null,
        totalAppointmentCount: 0,
        loadedAppointmentCount: 0,
        appointmentListPage: 0
      }

    case CHANGE_LOADED_APPOINTMENT_COUNT:
      return {
        ...state,
        loadedAppointmentCount: action?.payload?.message || 0
      }

    case CLEAR_APPOINTMENT_DOCUMENT_TAB_DATA:
      return {
        ...state,
        docTabData: null
      }

    case CLEAR_APPOINTMENT_MANAGE_TAB_DATA:
      return {
        ...state,
        manageTabData: null
      }

    case GET_TREATMENT_WISE_BEFORE_AFTER_COUNT:
      console.log("red", action.payload)
      return {
        ...state,
        treatmentWiseBeforeAfterCount: action?.payload
      }

    case CLEAR_TREATMENT_WISE_BEFORE_AFTER_COUNT:
      console.log("clear red", action.payload)
      return {
        ...state,
        treatmentWiseBeforeAfterCount: null
      }

    default:
      return state
  }
}  