import React from 'react'
import "./PrescriberBusinessDetails.scss"
import BusinessHeader from '../../businessHeader'
import PrescriberDetails from '../../prescriber-registration/business-details/PrescriberDetails'

const PrescriberBusinessDetails = () => {
    return (
        <div className='prescriber-business-details-container'>
            {/* <BusinessHeader /> */}
            <PrescriberDetails />
        </div>
    )
}

export default PrescriberBusinessDetails