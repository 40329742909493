import { CLEAR_APPOINTMENT_DETAILS_DATA, GET_APPOINTMENT_DETAILS_DATA } from "../../constants/appointment/appointmentConst";


const initialState = {
  appointmentDetails: []
}

export default function appointmentDetailsReducer(state = initialState, action) {

  switch (action.type) {

    case GET_APPOINTMENT_DETAILS_DATA:
        console.log("reducer akash",action.payload)
      return {
        ...state,
        appointmentDetails: action.payload,
      };

    case CLEAR_APPOINTMENT_DETAILS_DATA:

      return {
        ...state,
        appointmentDetails: []
      }

    default:
      return state;
  }



}