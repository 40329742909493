import { gql, useMutation } from '@apollo/client';
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";

const USER_PROFILE_UPDATE_MUTATION = gql`
mutation UpdateUserProfile($firstName: String, $lastName: String, $businessName: String, $phoneNumber: String, $email: String, $password: String, $buildingNo: String, $streetName: String, $city: String, $postcode: String, $ownerName: String, $website: String, $facebookPage: String, $instagramName: String, $businessType: String, $companyHouseRegNo: String, $insurenceExpireDate: String) {
  updateUserProfile(firstName: $firstName, lastName: $lastName, businessName: $businessName, phoneNumber: $phoneNumber, email: $email, password: $password, buildingNo: $buildingNo, streetName: $streetName, city: $city, postcode: $postcode, ownerName: $ownerName, website: $website, facebookPage: $facebookPage, instagramName: $instagramName, businessType: $businessType, companyHouseRegNo: $companyHouseRegNo, insurenceExpireDate: $insurenceExpireDate) {
    success
    message
  }
}
`;

export const UpdateUserProfile = () => {
  // const dispatch = useDispatch();
  const [UpdateUserProfileResponseFunction, updateUserProfileResponseData] =  
    useMutation(USER_PROFILE_UPDATE_MUTATION);

  //this will be called from components
  const updateUserProfileQueryData = updateUserProfileResponseData; //variable

  const initUpdateUserProfileData = (
    data,
    successCallback,
    errorCallback
  ) => {
    UpdateUserProfileResponseFunction({
      variables: data,
    })
      // .then(({ data: { UpdateUserProfile: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        if (responseData?.data?.updateUserProfile?.success) {
          // dispatch(...)
          successCallback(responseData);
          // toast.success(responseData.message)
        } else {
          errorCallback(responseData);
          // toast.error(responseData.message)
        }
      })
      .catch((err) => {
        // errorCallback(err);
        toast.error("something went wrong!!!")
      });
  };

  return {
    updateUserProfileQueryData,
    initUpdateUserProfileData,
  };
};
