import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import imgUploadLogo from "../../../../images/img-upload-logo.svg";
import StepButton from "../../../common/StepButton";
import uploadIcon from "../../../../images/uploadCover.svg";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import { FiRefreshCcw, FiX } from "react-icons/fi";
import { upload } from "@testing-library/user-event/dist/upload";
import "../steps.scss";
import { Button } from "react-bootstrap";
import dummyImageIcon from "../../../../assets/createWebsite/dummy.svg";
import { IoIosClose } from "react-icons/io";

const UploadImage = (props) => {
  const [uploadStep, setUploadStep] = useState(1);
  const [logoImage, setLogoImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const dimensions = useWindowDimensions();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = () => {
        if (uploadStep === 1) {
          setLogoImage(reader.result);
        } else {
          setCoverImage(reader.result);
        }
      };

      reader.readAsDataURL(file);
    },
    [uploadStep]
  );

  const resetDropzone = () => {
    if (uploadStep === 1) {
      setLogoImage(null);
    } else {
      setCoverImage(null);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div className="upload_img">
        {uploadStep === 1 ? (
          logoImage ? (
            <>
              <div className="uploaded-image-container my-2">
                <div className="uploadedWrapper">
                  <img
                    src={logoImage}
                    // width={500}
                    alt="Logo"
                    className="uploaded-image"
                  />
                  <div className="resetLogo">
                    <span className="iconReset">
                      <FiRefreshCcw />
                    </span>
                    <p>Replace Logo</p>
                  </div>
                </div>
                <div className="imgDetails">
                  <div className="imgDetailsWrapper">
                    <span className="imgIcon">
                      <img src={dummyImageIcon} alt="placeholder"></img>
                    </span>
                    <span className="imgName">Nike Logo.png </span>
                    <span className="imgSize">• 2.8 mb</span>
                  </div>
                  <div className="imgDetailsStatus">
                    <span className="imgStatus">Uploading...</span>
                    <span className="imgCancel">
                      <IoIosClose />
                    </span>
                  </div>
                </div>
              </div>
              <Button
                className="remove-btn"
                style={{ background: "red", border: "none" }}
                onClick={resetDropzone}
              >
                <FiX style={{ fontSize: "25px" }} />
              </Button>
            </>
          ) : (
            <div
              {...getRootProps()}
              className={`drop-zone ${isDragActive ? "active" : ""}`}
            >
              <input {...getInputProps()} />
              <img src={uploadIcon} alt="" />
              <h2>
                {isDragActive
                  ? "Drop the file here"
                  : "Drag & Drop file or Browse"}
              </h2>
              <h3>Supported format: PNG, JPG, JPEG</h3>
              <h3>(Maximum Upload File Size: 5 MB)</h3>
            </div>
          )
        ) : coverImage ? (
          <>
            <div className="uploaded-image-container my-2">
              <div className="uploadedWrapper">
                <img src={coverImage} alt="Cover" className="uploaded-image" />
                <div className="resetLogo">
                  <span className="iconReset">
                    <FiRefreshCcw />
                  </span>
                  <p>Replace Logo</p>
                </div>
              </div>
              <div className="imgDetails">
                <div className="imgDetailsWrapper">
                  <span className="imgIcon">
                    <img src={dummyImageIcon} alt="placeholder"></img>
                  </span>
                  <span className="imgName">Nike Logo.png </span>
                  <span className="imgSize">• 2.8 mb</span>
                </div>
                <div className="imgDetailsStatus">
                  <span className="imgStatus">Uploading...</span>
                  <span className="imgCancel">
                    <IoIosClose />
                  </span>
                </div>
              </div>
            </div>
            <Button
              className="remove-btn"
              style={{ background: "red", border: "none" }}
              onClick={resetDropzone}
            >
              <FiX style={{ fontSize: "25px" }} />
            </Button>
          </>
        ) : (
          <div
            {...getRootProps()}
            className={`drop-zone ${isDragActive ? "active" : ""}`}
          >
            <input {...getInputProps()} />
            <img src={uploadIcon} alt="" />
            <h2>
              {isDragActive
                ? "Drop the file here"
                : "Drag & Drop file or Browse"}
            </h2>
            <h3>Supported format: PNG, JPG, JPEG</h3>
            <h3>(Maximum Upload File Size: 5 MB)</h3>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadImage;
