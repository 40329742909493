import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_FAQS_QUERY = gql`
  query GetListOfFaq {
    getListOfFaq {
      success
      message
      data {
        FaqList {
          id
          question
          answer
        }
      }
    }
  }
`;

export const GetFaqListAction = () => {
  const [
    getFaqListResponseFunction,
    getFaqListResponseData,
  ] = useLazyQuery(GET_FAQS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getListOfFaq: responseData }) => {
      if (responseData.success) {
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });

  //this will be called from components
  const getFaqListQueryData = getFaqListResponseData; // updated variable name

  const initGetFaqListData = (data) => {
    getFaqListResponseFunction({
      variables: data,
    });
  };

  return { getFaqListQueryData, initGetFaqListData };
};
