import React from 'react'
import { ReactComponent as BackArrow } from '../../../images/back_arrow.svg';
import BannerHeader from '../../common/BannerHeader';
import "./todaysdatecalender.scss"
import StepButton from '../../common/StepButton';
import clock from "../../../../src/images/clock.svg"
import moment from 'moment';
import useDeviceDetect from '../../common/useDeviceDetect';
import Slider from "react-slick";
import { RiArrowDropRightFill } from "react-icons/ri";
import { RiArrowDropLeftFill } from "react-icons/ri";



const TodaysDateCalender = ({
    setStep,
    NextAvailableDate,
    setIsAvailbleInCurrent,
    setIsRightSectionOnDate
}) => {
    const today = new Date();
    const day = today.getDate();
    const month = today.toLocaleString('default', { month: 'short' });
    const year = today.getFullYear();
    const { screenWidth } = useDeviceDetect()


    const addOrdinalSuffix = (num) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = num % 100;
        return num + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    };



    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        prevArrow:<RiArrowDropLeftFill fill='black' />,
        nextArrow:<RiArrowDropRightFill fill='black' />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 5,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
              }
            }
          ]
    };
    
    const renderCalendar = () => {
        
        const today = moment();
        const nextSevenDays = moment().add(6, 'days');
        const endOfMonth = moment().endOf('month');

        const days = [];
        let currentDay = moment(today);
        let counter = 7;
        while (currentDay.isSameOrBefore(nextSevenDays, 'day') && currentDay.isSameOrBefore(endOfMonth, 'day') && counter > 0) {
            days.push({
                date: currentDay.format('D'),
                dayOfWeek: currentDay.format('ddd'),
            });
            currentDay.add(1, 'day');
            counter = counter - 1
        }
        
        return (
            <>
            <div className=''>
            {/* <RiArrowDropLeftFill  /> */}
            {/* <RiArrowDropRightFill  /> */}
            </div>
                <div className="custom-calendar">
                    <Slider {...settings}>
                        {days.map((day, index) => {
                            return (
                                <div key={index} >
                                    <div className="calendar-day">
                                        <div className="circle">
                                            <div className="date">{day.date}</div>
                                        </div>
                                        <div className="day">{day.dayOfWeek}</div>
                                    </div>
                                </div>
                            )
                        })}

                    </Slider>
                </div>
            </>
        );
    };

    return (
        <>
            <div className='main-todays-container customeScrollFullView'>
                {/* <div>
                    {
                        <BackArrow onClick={() => {
                            setStep(2)
                        }} />
                    }
                </div> */}

                <div className="header-calender-container CustomeScrollWidth">
                    <div className="back-section  mb-4">
                        <BackArrow className={`cursor-pointer`} onClick={() => {
                            // setIsRightSectionOnDate(true);
                            setStep(2)
                        }} />
                        <BannerHeader title={"When are you free?"} />
                    </div>
                    <div className="calender-container">
                        <div className='hm-custom-container'>
                            <div className="calender-data">
                                <h1>{`${addOrdinalSuffix(day)}, ${month} ${year}`}</h1>
                                {renderCalendar()}
                            </div>
                        </div>
                        <div className="fully-booked-container">
                            <img src={clock} alt="not found" />
                            <h1 className='my-2 custom-margin-book'>We’re fully Booked</h1>
                            {NextAvailableDate && <><p>but the next availability is {moment(NextAvailableDate.date).format('ddd D MMM')}</p>
                                <StepButton label={`Go to ${moment(NextAvailableDate.date).format('ddd D MMM')}`} blue={true} onClick={() => {
                                    setIsAvailbleInCurrent(true)
                                }} /></>}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default TodaysDateCalender