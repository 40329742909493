import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_FINANCE_REVENUE_FOR_CHART_QUERY = gql`
  query GetFinanceRevenueForChart(
    $timeFrame: String
    $startDateFromUser: String
    $endDateFromUser: String
  ) {
    getFinanceRevenueForChart(
      timeFrame: $timeFrame
      startDateFromUser: $startDateFromUser
      endDateFromUser: $endDateFromUser
    ) {
      success
      message
      data {
        period
        totalRevenue
      }
    }
  }
`;

export const GetFinanceRevenueForChartAction = () => {
  const [
    getFinanceRevenueForChartResponseFunction,
    getFinanceRevenueForChartResponseData,
  ] = useLazyQuery(GET_FINANCE_REVENUE_FOR_CHART_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: ({ getFinanceRevenueForChart: responseData }) => {},
  });

  const getFinanceRevenueForChartQueryData =
    getFinanceRevenueForChartResponseData;

  const initGetFinanceRevenueForChartData = (
    data,
    successCallback,
    errorCallback
  ) => {
    getFinanceRevenueForChartResponseFunction({
      variables: data,
    })
      .then((responseData) => {
        if (responseData?.data?.getFinanceRevenueForChart?.success) {
          successCallback(responseData?.data?.getFinanceRevenueForChart);
        } else {
          errorCallback(responseData?.data?.getFinanceRevenueForChart);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  return {
    getFinanceRevenueForChartQueryData,
    initGetFinanceRevenueForChartData,
  };
};
