import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import bannerVideo from "../../../assets/video/banner.mov";
import "./faces-ai-assistant.scss";
import BusinessHeader from "../../businessHeader";
import BusinessFooter from "../../businessFooter";
import { Container } from "react-bootstrap";
import SocialLapTop from "../../../assets/Ai/socialMedia.png";
import mobile_laptop from "../../../assets/Ai/mobile_laptop.png";
import mobile from "../../../assets/Ai/Device.png";
import iPadMini from "../../../assets/Ai/iPadMini.png";
import clientLaptop from "../../../assets/Ai/client.png";
import future from "../../../assets/Ai/future.png";

import whatspapp from "../../../assets/Ai/Whatsapp.png";
import gmailIcon from "../../../assets/Ai/Gmail.png";
import facebookIcon from "../../../assets/Ai/Facebook.png";
import tiktokIcon from "../../../assets/Ai/Tiktok.png";
import instagramIcon from "../../../assets/Ai/Instagram.png";
import facebookSocial from "../../../assets/Ai/facebookSocial.png";
import InstaSocial from "../../../assets/Ai/InstaSocial.png";
import LinkdinSocial from "../../../assets/Ai/LinkdinSocial.png";
import SkypeSocial from "../../../assets/Ai/SkypeSocial.png";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { OnboardUserToFacesAiAction } from "../../redux/actions/facesai-assistant/onboardUserToFacesAi";
import { businessDashboardRoute } from "../../../Routes";
import slidethird from "../../../assets/Ai/slider/sliderthree.png";
import slidetwo from "../../../assets/Ai/slider/slidertwo.png";
import slideone from "../../../assets/Ai/slider/sliderone.png";
import playIcon from "../../../assets/Ai/playIcon.png";

import Slider from "react-slick";

import gsap, { Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { Hidden } from "@mui/material";
import CustomModal from "../../modal/CustomModal";
import StepButton from "../../common/StepButton";

gsap.registerPlugin(ScrollTrigger);

function FacesAiAssistant() {
  const wpimgRef = useRef(null);
  const dimensions = useWindowDimensions();
  const navigate = useNavigate();
  const { OnboardUserToFacesAiQueryData, initOnboardUserToFacesAiData } =
    OnboardUserToFacesAiAction();
  const [openFacesAiPasswordInput, setopenFacesAiPasswordInput] =
    useState(false);
  const [isyoutube, setIsyoutube] = useState(false);
  const [userPassword, setuserPassword] = useState("");
  const [currentIndexCheck, setCurrentIndexCheck] = useState(0);
  const [setEvent, isSetEvent] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [ isCurrentPasswordNotStrong , setIsCurrentPasswordNotStrong ] = useState(false);
  const [ updatedPasswordText , setUpdatedPasswordText ] = useState("");
  const [userOldPassword, setuserOldPassword] = useState("");

  console.log(currentSlide, "isSliderCurrent");

  // slider
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const sliderTwoArray = [
    {
      id: 1,
      slideimage: slideone,
      slidedesc:
        "“Lorem Ipsum is simply dummy text of the printing and industry.”",
      slidename: "Elli Watson",
      slidedest: "CEO of Stark Industries",
    },
    {
      id: 2,
      slideimage: slidetwo,
      slidedesc:
        "“Lorem Ipsum is simply dummy text of the printing and industry.”",
      slidename: "Elli Watson",
      slidedest: "CEO of Stark Industries",
    },
    {
      id: 3,
      slideimage: slidethird,
      slidedesc:
        "“Lorem Ipsum is simply dummy text of the printing and industry.”",
      slidename: "Elli Watson",
      slidedest: "CEO of Stark Industries",
    },
    {
      id: 4,
      slideimage: slideone,
      slidedesc:
        "“Lorem Ipsum is simply dummy text of the printing and industry.”",
      slidename: "Elli Watson",
      slidedest: "CEO of Stark Industries",
    },
    // {
    //   id: 5,
    //   slideimage: slidetwo,
    //   slidedesc:
    //     "“Lorem Ipsum is simply dummy text of the printing and industry.”",
    //   slidename: "Elli Watson",
    //   slidedest: "CEO of Stark Industries",
    // },
    // {
    //   id: 6,
    //   slideimage: slidethird,
    //   slidedesc:
    //     "“Lorem Ipsum is simply dummy text of the printing and industry.”",
    //   slidename: "Elli Watson",
    //   slidedest: "CEO of Stark Industries",
    // },
  ];

  useEffect(() => {
    const panelEls = Array.from(document.querySelectorAll(".panels__item"));
    let currentIndex = 0;

    gsap.fromTo(
      ".panels__item",
      {},
      {
        stagger: {
          repeat: 0, // Repeats immediately, not waiting for the other staggered animations to finish
        },
        // markers: true,
        start: ".bannerVideoWrapper +=220",
        scrollTrigger: {
          trigger: ".animationSec",
          pin: true,
          scrub: true,
          toggleClass: "is-active",
          start: ".bannerVideoWrapper +=220",
          endTrigger: ".futureSec",
          end: window.innerHeight * 2,
          onEnter: function () {
            // currentIndex = 0;
            panelEls[currentIndex]?.classList.add("is-active");

            gsap.fromTo(
              ".whatspappIcon",
              { rotation: 0, x: -30, y: -30, scale: 0.5, autoAlpha: 0 },
              {
                rotation: 360,
                x: 0,
                y: 0,
                duration: 2,
                delay: 0.1,
                scale: 1,
                autoAlpha: 1,
              }
            );
            gsap.fromTo(
              ".gmailIcon",
              { rotation: 250, x: -30, y: -30, scale: 0.5, autoAlpha: 0 },
              {
                rotation: 360,
                x: 0,
                y: 0,
                duration: 2,
                delay: 0.4,
                scale: 1,
                autoAlpha: 1,
              }
            );
            gsap.fromTo(
              ".facebookIcon",
              { rotation: 250, x: 30, y: -30, scale: 0.5, autoAlpha: 0 },
              {
                rotation: 360,
                x: 0,
                y: 0,
                duration: 2,
                delay: 0.6,
                scale: 1,
                autoAlpha: 1,
              }
            );
            gsap.fromTo(
              ".tiktokIcon",
              { rotation: -250, x: -30, y: 80, scale: 0.5, autoAlpha: 0 },
              {
                rotation: -360,
                x: 0,
                y: 0,
                duration: 2,
                delay: 0.8,
                scale: 1,
                autoAlpha: 1,
              }
            );
            gsap.fromTo(
              ".instagramIcon",
              { rotation: -250, x: 30, y: 80, scale: 0.5, autoAlpha: 0 },
              {
                rotation: -360,
                x: 0,
                y: 0,
                duration: 2,
                delay: 1,
                scale: 1,
                autoAlpha: 1,
              }
            );
          },

          onEnterBack: function () {
            // Reset currentIndex when ".animationSec" re-enters the viewport
            currentIndex = 0;
            panelEls[currentIndex]?.classList.add("is-active");
          },

          onLeaveBack: function () {
            panelEls[0]?.classList.remove("is-active");
            // panelEls[0].classList.add("prev");
            // panelEls[0].classList.add("prev");
          },

          onUpdate: function (self) {
            const previousIndex = currentIndex;
            currentIndex = Math.floor(self.progress * panelEls.length);

            if (
              previousIndex >= panelEls.length ||
              currentIndex >= panelEls.length
            )
              return;

            if (previousIndex !== currentIndex) {
              panelEls[previousIndex]?.classList.remove("is-active");
              panelEls[panelEls?.length-1]?.classList.remove("is-active");
              panelEls[currentIndex]?.classList.add("is-active");
            }
          },
        },
      }
    );

    {openFacesAiPasswordInput && 
      document.addEventListener("mousedown", function (event) {
        event.preventDefault();
        currentIndex = (currentIndex + 1) % panelEls.length;

        updateActivePanel();
      });
    }

    function updateActivePanel() {
      panelEls.forEach(function (panel, index) {
        panel.classList.toggle("is-active", index === currentIndex);
      });
    }

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: ".futureSec .futureImage img",
        start: ".futureSec +=500", // Adjust the start position as needed
        end: ".FaqSec +=150", // Adjust the end position as needed
        scrub: 0.4,
        // markers: true, // Set to false when you're done testing
      },
    });

    timeline.fromTo(
      ".futureSec .futureImage img",
      { scale: 1 },
      { scale: 1.8 }
    );

    const accordionItems = document.querySelectorAll(".accordion-item");

    accordionItems.forEach((item, index) => {
      let animationTriggered = false;

      gsap.set(item, { y: 0, autoAlpha: 0 }); // Set initial state

      const tl = gsap.timeline({
        paused: true,
        defaults: { duration: 0.5, ease: "power2.inOut" },
      });

      // Change the direction to slide up
      tl.fromTo(item, { y: 20, autoAlpha: 0 }, { y: 0, autoAlpha: 1 });

      ScrollTrigger.create({
        trigger: item,
        start: "bottom bottom", // Adjust this based on your needs
        end: "top top", // Adjust this based on your needs
        scrub: 0.4,
        onEnter: () => {
          // Check if the animation has not been triggered yet
          if (!animationTriggered) {
            // Play the timeline
            tl.play();
            // Set the flag to true to prevent further animation
            animationTriggered = true;
          }
        },
        onLeaveBack: () => {
          // Reverse the timeline
          tl.reverse();
          // Reset the flag for the next time the element enters the viewport
          animationTriggered = false;
        },
      });
    });
  }, [setEvent]);

  const showPasswordInputModal = () => {
    setopenFacesAiPasswordInput(true);
  };

  const checkUserInputPassword = () => {
    if (!userPassword) {
      toast.error("Please enter your password");
    } else {
      const passwordParams = userOldPassword
      ? { password: userOldPassword, newPassword: userPassword }
      : { password: userPassword };
      
      initOnboardUserToFacesAiData(
        passwordParams,
        (res) => {
          setTimeout(() => {
            toast.success(res.message);
          }, 1000);

          navigate(businessDashboardRoute());
        },
        (err) => {

          if (err.data && err.data.isPasswordWeak) {
            setIsCurrentPasswordNotStrong(true);
            setUpdatedPasswordText(err.message); 
            setuserOldPassword(userPassword);
          }else{
            toast.error(err.message);
          }
          setuserPassword("");
        }
      );
    }
  };

  const settings1 = {
    fade: true,
    arrows: false,
  };
  // const goToSlide = (index) => {
  //   Slider.slickGoTo(index);
  // };
  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-next-arrow slick-next" onClick={onClick}>
        <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
      </div>
    );
  };

  // Custom arrow component
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-prev-arrow slick-prev" onClick={onClick}>
       <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
      </div>
    );
  };

  const settings2 = {
    slidesToShow: 3,
    swipeToSlide: true,
    focusOnSelect: false,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const verticalsettings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    // verticalSwiping: true,
    // swipeToSlide: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const openYoutTubeModel = () => {
    setIsyoutube(true);
  };

  return (
    <>
      <div className="mainAiFacisPage">
        <BusinessHeader />
        <div className="bannerVideoWrapper">
          <video id="bannerPlayer" autoPlay muted loop>
            <source src={bannerVideo} type="video/mp4" />
          </video>
          <div className="bannerContent">
            <p className="bannerDesc">
              Your 24/7 Client <br />
              Communication and Management Solution
            </p>
            <h1>Faces AI Assistant!</h1>
            <button
              className="AiButton"
              onClick={() => showPasswordInputModal()}
            >
              <span>Activate Now!</span>
            </button>
          </div>
        </div>
        <div className="animationSec deskStop-block">
          <div className="container">
            <div className="PannerWrapper">
              <div className="panels__item">
                <div className="panels__item-bg  animationAdd">
                  <div className="socialScreenAniWrapper">
                    <div
                      className="socialIconAniWrapper whatspappIcon"
                      ref={wpimgRef}
                    >
                      <img src={whatspapp} className="img-fluid" />
                    </div>
                    <div className="socialIconAniWrapper gmailIcon">
                      <img src={gmailIcon} className="img-fluid" />
                    </div>
                    <div className="socialIconAniWrapper facebookIcon">
                      <img src={facebookIcon} className="img-fluid" />
                    </div>

                    <div className="latopScreen">
                      <img src={SocialLapTop} className="img-fluid" />
                    </div>

                    <div className="socialIconAniWrapper tiktokIcon">
                      <img src={tiktokIcon} className="img-fluid" />
                    </div>
                    <div className="socialIconAniWrapper instagramIcon">
                      <img src={instagramIcon} className="img-fluid" />
                    </div>
                  </div>
                  {/* <img src={SocialLapTop} className="img-fluid" /> */}
                </div>
              </div>
              <div className="panels__item left">
                <div className="panels__item-bg">
                  <div className="socialScreenAniWrapper">
                    <div
                      className="socialIconAniWrapper whatspappIcon"
                      ref={wpimgRef}
                    >
                      <img src={whatspapp} className="img-fluid" />
                    </div>
                    <div className="socialIconAniWrapper gmailIcon">
                      <img src={gmailIcon} className="img-fluid" />
                    </div>
                    <div className="socialIconAniWrapper facebookIcon">
                      <img src={facebookIcon} className="img-fluid" />
                    </div>

                    <div className="latopScreen">
                      <img src={SocialLapTop} className="img-fluid" />
                    </div>

                    <div className="socialIconAniWrapper tiktokIcon">
                      <img src={tiktokIcon} className="img-fluid" />
                    </div>
                    <div className="socialIconAniWrapper instagramIcon">
                      <img src={instagramIcon} className="img-fluid" />
                    </div>
                  </div>
                  {/* <img src={SocialLapTop} className="img-fluid" /> */}
                </div>
                <div className="panels__item-content">
                  <div className="left">
                    <div className="AllInOneContent secContent SocialContent">
                      <h2>
                        All-in-One Social <br /> Media Chat
                      </h2>
                      <p>TikTok,</p>
                      <p>LinkedIn,</p>
                      <p>Instagram,</p>
                      <p>Facebook, </p>
                      <p>WhatsApp</p>
                      <p>Through one platform.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panels__item">
                <div className="panels__item-bg animationAdd">
                  <img src={mobile} className="img-fluid" />
                </div>
              </div>
              <div className="panels__item right">
                <div className="panels__item-bg ">
                  <img src={mobile} className="img-fluid" />
                </div>
                <div className="panels__item-content">
                  <div className="right">
                    <div className="AllInOneContent secContent aiResponsesContent">
                      <h2>
                        Instant <br /> AI Responses
                      </h2>
                      <p>
                        Engage customers 24/7 with your <br /> personalised
                        chatbot.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panels__item">
                <div className="panels__item-bg animationAdd">
                  <img src={iPadMini} className="img-fluid" />
                </div>
              </div>
              <div className="panels__item left">
                <div className="panels__item-bg ">
                  <img src={iPadMini} className="img-fluid" />
                </div>
                <div className="panels__item-content">
                  <div className="left">
                    <div className="AllInOneContent secContent aiResponsesContent">
                      <h2>Automated Booking & Documentation</h2>
                      <p>
                        Appointments <br /> Deposits <br /> Consent & Aftercare
                        Forms
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panels__item">
                <div className="panels__item-bg animationAdd">
                  <img src={mobile} className="img-fluid" />
                </div>
              </div>
              <div className="panels__item right">
                <div className="panels__item-bg ">
                  <img src={mobile} className="img-fluid" />
                </div>
                <div className="panels__item-content">
                  <div className="right">
                    <div className="AllInOneContent secContent aiResponsesContent">
                      <h2>
                        Efficient Lead <br /> Generation
                      </h2>
                      <p>
                        Attract new clients with AI-driven Facebook and Meta
                        ads.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="animationMobile mobile-block">
          <div className="container">
            <Slider {...verticalsettings}>
              <div>
                <div className="col-sm-12">
                  <div className="AllInOneContent secContent SocialContent">
                    <h2>
                      All-in-One Social <br /> Media Chat
                    </h2>
                    <p>TikTok,</p>
                    <p>LinkedIn,</p>
                    <p>Instagram,</p>
                    <p>Facebook, </p>
                    <p>WhatsApp</p>
                    <p>Through one platform.</p>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="animation mobile fullImage">
                    <div className="latopScreen">
                      <img src={mobile_laptop} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="col-sm-12">
                  <div className="AllInOneContent secContent SocialContent">
                    <h2>
                      Instant <br />
                      AI Responses
                    </h2>
                    <p>
                      Engage customers 24/7 with your <br /> personalised
                      chatbot.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="animation mobile onlyMobileImage">
                    <div className="latopScreen">
                      <img src={mobile} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="col-sm-12">
                  <div className="AllInOneContent secContent SocialContent">
                    <h2>
                      Automated Booking & <br />
                      Documentation
                    </h2>
                    <p>
                      Appointments <br /> Deposits <br />
                      Consent & Aftercare Forms
                    </p>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="animation mobile fullImage">
                    <div className="latopScreen">
                      <img src={iPadMini} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="col-sm-12">
                  <div className="AllInOneContent secContent SocialContent">
                    <h2>
                      Automated Client Re- <br />
                      engagement
                    </h2>
                    <p>
                      Automations
                      <br />
                      Follow-ups
                      <br />
                      Reviews
                      <br />
                      Re-bookings <br />
                      Workflows
                    </p>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="animation mobile fullImage">
                    <div className="latopScreen">
                      <img src={clientLaptop} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="col-sm-12">
                  <div className="AllInOneContent secContent SocialContent">
                    <h2>
                      Efficient Lead <br />
                      Generation
                    </h2>
                    <p>
                      Attract new clients with AI-driven <br /> Facebook and
                      Meta ads.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="animation mobile onlyMobileImage">
                    <div className="latopScreen">
                      <img src={mobile} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div>
                <div className="col-sm-12">
                  <div className="AllInOneContent secContent SocialContent">
                    <h2>
                      Automated Client Re- <br />
                      engagement
                    </h2>
                    <p>
                      Automations
                      <br />
                      Follow-ups
                      <br />
                      Reviews
                      <br />
                      Re-bookings <br />
                      Workflows
                    </p>
                  </div>
                </div>
                </
                <div className="col-sm-12">
                  <div className="animation mobile">
                    <div className="latopScreen">
                      <img src={clientLaptop} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div> */}
            </Slider>
          </div>
        </div>

        <section className="futureSec SameSpace">
          <div className="container">
            <div className="futureBox">
              <div className="futureImage">
                <img src={future} />
              </div>
              <div className="futureContent">
                <h2>The Future is here</h2>
                <button
                  className="AiButton"
                  onClick={() => showPasswordInputModal()}
                >
                  <span>Activate Now!</span>
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="TestiMonialSec SameSpace"> */}
          {/* <div className="container">
            <div>
              <h2>Our Customers Says</h2>
              <div className="sliderWrapper">
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <div className="TestContentWrapper">
                      <Slider asNavFor={nav2} ref={slider1} {...settings1}>
                        {sliderTwoArray?.map((item, index) => {
                          return (
                            <div>
                              <div className="sliderTest">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="sliderContent">
                                      <p> {item?.slidedesc}</p>
                                      <div className="ClientContent">
                                        <p>{item?.slidename}</p>
                                        <span>{item?.slidedest}</span>
                                      </div>
                                      <div className="ClientLinks">
                                        <ul>
                                          <li>
                                            <a to="#">
                                              <img src={facebookSocial} />
                                            </a>
                                          </li>
                                          <li>
                                            <a to="#">
                                              <img src={InstaSocial} />
                                            </a>
                                          </li>
                                          <li>
                                            <a to="#">
                                              <img src={LinkdinSocial} />
                                            </a>
                                          </li>
                                          <li>
                                            <a to="#">
                                              <img src={SkypeSocial} />
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <Slider
                      asNavFor={nav1}
                      ref={slider2}
                      {...settings2}
                      className="sliderThubnail"
                    >
                      {sliderTwoArray?.map((item, index) => {
                        return (
                          <div>
                            <div className="sliderthumbWraper">
                              <img
                                src={item.slideimage}
                                alt={item.slideimage}
                              />
                              <div className="PlayLink">
                                <button
                                  className="playButton"
                                  onClick={() => openYoutTubeModel()}
                                >
                                  <img src={playIcon} alt={playIcon} />
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="container">
            <h2>Our Customers Says</h2>
            <div className="sliderTest">
              <div className="row">
                <div className="col-sm-12">
                  <div className="sliderContent">
                    <p>
                      “Lorem Ipsum is simply <br /> dummy text of the <br />{" "}
                      printing and industry.”
                    </p>
                    <div className="ClientContent">
                      <p>Elli Watson</p>
                      <span>CEO of Stark Industries</span>
                    </div>
                    <div className="ClientLinks">
                      <ul>
                        <li>
                          <a to="#">
                            <img src={facebookSocial} />
                          </a>
                        </li>
                        <li>
                          <a to="#">
                            <img src={InstaSocial} />
                          </a>
                        </li>
                        <li>
                          <a to="#">
                            <img src={LinkdinSocial} />
                          </a>
                        </li>
                        <li>
                          <a to="#">
                            <img src={SkypeSocial} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        {/* </section> */}
        <section className="FaqSec SameSpace">
          <div className="container">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    <span className="Index">1 . </span>
                    How to Connect Your Social Media Accounts to Faces AI
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Faces improves client communication by offering features
                    like automated responses, marketing automation, and
                    personalised follow-up prompts.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingtwo">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsetwo"
                    aria-expanded="false"
                    aria-controls="collapsetwo"
                  >
                    <span className="Index">2 . </span>
                    What is Faces AI Assistant and how does it help in the
                    beauty and aesthetics industry?
                  </button>
                </h2>
                <div
                  id="collapsetwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingtwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                  Faces AI Assistant is an innovative tool designed to streamline communication
                  and business management in the beauty and aesthetics sector, using advanced
                  AI technology.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingthree">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsethree"
                    aria-expanded="false"
                    aria-controls="collapsethree"
                  >
                    <span className="Index">3 . </span>
                    How does Faces integrate with social media platforms?
                  </button>
                </h2>
                <div
                  id="collapsethree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingthree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                  Faces offers seamless integration with multiple social media platforms, allowing
                  users to manage all their client interactions from a single dashboard.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingfour">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefour"
                    aria-expanded="false"
                    aria-controls="collapsefour"
                  >
                    <span className="Index">4 . </span>
                    Is WhatsApp integration available with Faces, and what are its benefits?
                    </button>
                </h2>
                <div
                  id="collapsefour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                  Faces includes WhatsApp integration, enabling users to communicate with
                  clients on this platform for a small fee, enhancing reach and convenience.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingfive">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefive"
                    aria-expanded="false"
                    aria-controls="collapsefive"
                  >
                    <span className="Index">5 . </span>
                    How does the Faces platform improve client communication?
                    </button>
                </h2>
                <div
                  id="collapsefive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingfive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                  Faces improves client communication by offering features like automated
                  responses, marketing automation, and personalised follow-up prompts.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingsix">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsesix"
                    aria-expanded="false"
                    aria-controls="collapsesix"
                  >
                    <span className="Index">6 . </span>
                    What are the costs associated with using Faces AI Assistant?
                    </button>
                </h2>
                <div
                  id="collapsesix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingsix"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                  Faces AI Assistant has a cost-effective pricing structure, including a nominal fee
                  for additional features like WhatsApp integration.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingseven">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseseven"
                    aria-expanded="false"
                    aria-controls="collapseseven"
                  >
                    <span className="Index">7 . </span>
                    How can Faces AI Assistant boost sales and client satisfaction in the beauty industry?
                    </button>
                </h2>
                <div
                  id="collapseseven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingseven"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                  By providing tools for effective communication, appointment management, and
                  personalised client engagement, Faces helps boost sales and client satisfaction.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingeight">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseeight"
                    aria-expanded="false"
                    aria-controls="collapseeight"
                  >
                    <span className="Index">8 . </span>
                    Is Faces user-friendly for those not tech-savvy?
                    </button>
                </h2>
                <div
                  id="collapseeight"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingeight"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                  Yes, Faces is designed with a user-friendly interface, making it accessible and
                  easy to use, even for those who are not tech-savvy.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingnine">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsenine"
                    aria-expanded="false"
                    aria-controls="collapsenine"
                  >
                    <span className="Index">9 . </span>
                    Can Faces help in collecting and managing client reviews?
                    </button>
                </h2>
                <div
                  id="collapsenine"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingnine"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                  Absolutely, Faces includes features for effortless collection and management of
                  client reviews, helping businesses improve their services.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingten">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseten"
                    aria-expanded="false"
                    aria-controls="collapseten"
                  >
                    <span className="Index">10 . </span>
                    How does Faces ensure quick response times to clients?
                    </button>
                </h2>
                <div
                  id="collapseten"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingten"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                  Faces employs AI-driven prompts and notifications to ensure users can respond
                  quickly to client messages, crucial for higher conversion rates.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <BusinessFooter />
      </div>
      <CustomModal
        modalOpen={openFacesAiPasswordInput}
        setModalOpen={setopenFacesAiPasswordInput}
        type={""}
        modalBody={
          <>
            {!isCurrentPasswordNotStrong ? (
              <>
                <div className="mt-3">
                  Please enter your password to confirm your identity.
                </div>
                <div className="mt-3">
                  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
                  dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit
                  amet lorem ipsum dolor sit amet sit amet lorem ipsum dolor sit
                  amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem
                  ipsum dolor sit amet
                </div>
              </>
            ) : (
                <div className="mt-3">{updatedPasswordText}</div>
            )}

            <div className="mt-3">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                value={userPassword}
                onChange={(e) => {
                  setuserPassword(e.target.value);
                }}
              />
            </div>

            {isCurrentPasswordNotStrong ? 
            <div className="mt-3" style={{ color: 'red', fontSize: '14px' }}>
              It must be atleast 10 characters long and must contain atleast 1 uppercase, 1 lowercase, 1 number and 1 special character in order to activate AI Assistant
            </div>
            : null}
            <div className="w-50 mx-auto">
              <StepButton
                label="Confirm"
                dark={true}
                onClick={checkUserInputPassword}
                isLoading={OnboardUserToFacesAiQueryData.loading}
              />
            </div>
          </>
        }
        modaltitle={"Faces AI Assistant Activation"}
      />
      {/* Video Model */}
      <CustomModal
        modalOpen={isyoutube}
        setModalOpen={setIsyoutube}
        type={""}
        className="VideoModel"
        modalBody={
          <div className="VideoWrapper">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/D0UnqGm_miA?si=wh3ZOsUL3SF9slH8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        }
      />
    </>
  );
}

export default FacesAiAssistant;
