import React from 'react'
import './HairPrescriptionPopup.scss'
import checked from '../../../assets/HairProduct/check_normal.svg'
import Hairloss1 from '../../../assets/HairProduct/female-hair-loss 1.svg'
import Hairloss2 from '../../../assets/HairProduct/female-hair-loss 2.svg'
import NavHeader from '../../common/NavHeader/NavHeader'
import { useNavigate } from 'react-router'
import { businessDashboardRoute,hairCareLandingPageRouteRequest } from '../../../Routes'
import {frontendURL} from "../../../config"


const HairPrescriptionPopup = ({setIntroScreen,introScreen}) => {
    const navigate = useNavigate()

    const handleNeedMoreButton=()=>{
        navigate(hairCareLandingPageRouteRequest)
    }

    return (
        <>
        
            <NavHeader isClose={true} applyPadding={false} onClickCallback={() => {
                navigate(businessDashboardRoute())
            }} />
            <div className='hair-prescription-popup-main-container'>
                <div className='hair-prescription-popup-container row align-items-center'>
                    <div className="hair-popup-left-content col-md-7">
                      <h2>Most popular treatments for hair loss and thinning</h2>
                        <div className="sub-content-pop-up">
                            <p>Hair loss affects a significant portion of the UK population. Around 40% of men experience noticeable hair loss by age 35, increasing to 50% by age 50 and 80% by age 70.</p>
                            <p>Women are also affected, with approximately 50% experiencing some degree of hair thinning by age 65.</p>
                            <p>You can now offer this service directly through faces!</p>
                            <p>When your client purchases the treatment, you'll receive a 10% referral fee each month they are a customer</p>
                        </div>
                        <div className="checked-content">
                            <div className="d-flex gap-1">
                                <img src={checked} alt="checked" />
                                <p className='m-0'>Medical grade hair loss products</p>
                            </div>
                            <div className="d-flex gap-1">
                                <img src={checked} alt="checked" />
                                <p className='m-0'>No work from you!</p>
                            </div>
                            <div className="d-flex gap-1">
                                <img src={checked} alt="checked" />
                                <p className='m-0'>Paid 10% referral fee for the life of the client</p>
                            </div>
                        </div>
                        <div className='d-flex align-items-center mt-4 gap-4'>
                            <button className='offer-btn' onClick={() => setIntroScreen(introScreen+1)} >Offer this service</button>
                            <p className='learn-link' onClick={()=>handleNeedMoreButton()}>Need to know more</p>
                        </div>
                    </div>


                    <div className="hair-popup-right-content col-md-5">
                        <div className="hair-loss-img-div row">
                            <div className="hair-loss-img col-6 gap-3">
                                <img src={Hairloss1} alt="Before treatment" />
                                <p className='text-center'>Before treatment</p>
                            </div>
                            <div className="hair-loss-img col-6 gap-3">
                                <img src={Hairloss2} alt="After treatment" />
                                <p className='text-center'>After treatment</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HairPrescriptionPopup