import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import StarVecotrPng from "../../../images/social-media-marketing/star-vector.png";

export const TestimonialsCarousel = () => {
    // const [plans, setPlans] = useState([]);


    // useEffect(() => {
    //     setPlans([
    //         {
    //             id: 1,
    //             title: 'test',
    //             description: 'test'
    //         },
    //         {
    //             id: 2,
    //             title: 'test',
    //             description: 'test'
    //         },
    //         {
    //             id: 3,
    //             title: 'test',
    //             description: 'test'
    //         },
    //         {
    //             id: 4,
    //             title: 'test',
    //             description: 'test'
    //         },
    //         {
    //             id: 5,
    //             title: 'test',
    //             description: 'test'
    //         }
    //     ]);
    // }, []);

    const testimonials = [
        {
            id: 1,
            rating: '10/10',
            content: 'Being a part-time practitioner, my time is limited. Plump Marketing takes off so much pressure. The content they create resonates with my clients, and their outbound strategy has been a game-changer.',
            initial: 'FL',
            userName: 'Kayleigh Smithalton',
            userLocation: 'Aesthetic Practitioner, Leeds',
        },
        {
            id: 2,
            rating: '10/10',
            content: 'Even with a small team, Plump Marketing made us look like giants in the aesthetic world. Their understanding of the Middle Eastern market is impressive.',
            initial: 'DA',
            userName: 'Dr. Ahmed Al Farsi',
            userLocation: 'Rejuvenate Aesthetics, Dubai',
        },
        {
            id: 3,
            rating: '10/10',
            content: 'Managing six locations is tough. But with Plump Marketing, our social media is unified and professional across all our locations. They really help us maintain a cohesive brand.',
            initial: 'LB',
            userName: 'Lucy Brown',
            userLocation: 'Managing Director Elegance Clinics, UK',
        },
        {
            id: 4,
            rating: '10/10',
            content: 'Being a part-time practitioner, my time is limited. Plump Marketing takes off so much pressure. The content they create resonates with my clients, and their outbound strategy has been a game-changer.',
            initial: 'FL',
            userName: 'Kayleigh Smithalton',
            userLocation: 'Aesthetic Practitioner, Leeds',
        },
        {
            id: 5,
            rating: '10/10',
            content: 'Even with a small team, Plump Marketing made us look like giants in the aesthetic world. Their understanding of the Middle Eastern market is impressive.',
            initial: 'DA',
            userName: 'Dr. Ahmed Al Farsi',
            userLocation: 'Rejuvenate Aesthetics, Dubai',
        },
        {
            id: 6,
            rating: '10/10',
            content: 'Managing six locations is tough. But with Plump Marketing, our social media is unified and professional across all our locations. They really help us maintain a cohesive brand.',
            initial: 'LB',
            userName: 'Lucy Brown',
            userLocation: 'Managing Director Elegance Clinics, UK',
        },
        // {
        //     id: 1,
        //     title: 'test',
        //     description: 'test'
        // },
        // {
        //     id: 1,
        //     title: 'test',
        //     description: 'test'
        // },
        // {
        //     id: 1,
        //     title: 'test',
        //     description: 'test'
        // },
        // {
        //     id: 1,
        //     title: 'test',
        //     description: 'test'
        // },
        // {
        //     id: 1,
        //     title: 'test',
        //     description: 'test'
        // },
        // {
        //     id: 1,
        //     title: 'test',
        //     description: 'test'
        // },
    ]

    const responsiveObj = {
        0:{
            items:1.2
        },
        500:{
            items:2.2
        },
        700:{
            items:2.2
        },
        950:{
            items:3.2
        },
        1100:{
            items:3.2
        }
    };

    return (
        <OwlCarousel items={testimonials}
            className='owl-theme'
            loop={false}
            margin={20}
            nav={true}
            dots={false}
            responsiveClass={true}
            responsive={responsiveObj}>
            {testimonials.map(plan => (
                <div key={plan.id} className='testimonialRatingElement'>
                    {/* <div className='testimonialRatingDiv'><img src={StarVecotrPng} alt="" className='testimonialRatingVector'/> <span className='ratingSpan'>{plan.rating}</span></div> */}
                    <div className="testimonialRatingDiv"><i class="fa fa-star  me-1 checked"></i> <span className='ratingSpan'>{plan.rating}</span></div>
                    <div className='testimonialRatingContent'>{plan.content}</div>
                    <div className='testimonialRatingUserDiv'>
                        <div className='testimonialRatingUserInitial'>{plan.initial}</div>
                        <div className='testimonialRatingUserDetails'>
                            <div className='testimonialRatingUserDetailsName'>{plan.userName}</div>
                            <div className='testimonialRatingUserDetailsLocation'>{plan.userLocation}</div>
                        </div>
                    </div>
                </div>
            ))}
        </OwlCarousel>
    );
};
