import { TEMPLATE_TYPE_ENUM } from '../../../manage-forms/myforms/constants';
import {
    CLEAR_MYFORMS_LIST,
    GET_MYFORMS_LIST,
} from '../../constants/formsTemplate/FormTemplateConst';

const initialState = {
    totalPages: 1,
    curPage: 0,
    formsList: [],
    afterCarePartition: Number.POSITIVE_INFINITY
};

//Get Myforms List reducer
export default function myformsListReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MYFORMS_LIST:
            const data = action.payload;
            const prevList = state.formsList;
            let newArr = data.items;
            let newAftercarePartition = state.afterCarePartition;
            if (prevList.length > 0) {
                newArr = [...prevList, ...data.items];
            }
            newAftercarePartition = newArr.findIndex((i) => i.templateType === TEMPLATE_TYPE_ENUM.aftercare);

            return {
                ...state,
                formsList: newArr,
                totalPages: data.totalPages,
                curPage: data.currentPage,
                afterCarePartition: newAftercarePartition
            };

        case CLEAR_MYFORMS_LIST:
            return {
                totalPages: 1,
                curPage: 0,
                formsList: [],
                afterCarePartition: Number.POSITIVE_INFINITY
            };

        default:
            return state;
    }
}
