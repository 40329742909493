import { gql, useMutation } from "@apollo/client";

const SAVE_FINANCE_INSURANCE_DETAIL=gql`
mutation SaveInsuranceDetailsForFinance($expireDate: String!, $policyNo: String!, $documentFiles: [saveInsuranceDetailsForFinanceTypeArgs], $insuranceCompanyName: String) {
  saveInsuranceDetailsForFinance(expire_date: $expireDate, policy_no: $policyNo, document_files: $documentFiles, insurance_company_name: $insuranceCompanyName) {
    success
    message
    data {
      images {
        id
        certificate_name
        certificate_url
        time
      }
    }
  }
}
`;

export const SaveFinanceInsuranceDetailsAction=()=>{
   const [saveFinanceInsuranceDetailResponseFunction,
saveFinanceInsuranceDetailResponseData]=useMutation(SAVE_FINANCE_INSURANCE_DETAIL);

    const saveFinanceInsuranceDetailQueryData=saveFinanceInsuranceDetailResponseData;

    const initsaveFinanceInsuranceDetailData=(data, successCallback, errorCallback)=>{
         saveFinanceInsuranceDetailResponseFunction({
            variables:data,
         }).then((responseData)=>{
            console.log(responseData,"respn")
            if(responseData.data.saveInsuranceDetailsForFinance.success){
                successCallback(responseData)
            }else{
                errorCallback(responseData)
            }
         }).catch((error)=>{
            errorCallback(error)
         })
    }

    return {saveFinanceInsuranceDetailQueryData,initsaveFinanceInsuranceDetailData}
}