import React from 'react'
import '../add-new-clinic-main.scss'
import searchIcon from '../../../images/search_icon.svg'

const AddClinicAddress = ({ addClinicAddressStep, setAddClinicAddressStep }) => {
    // const handleGoToNameAndAddrPage = () => {
    //     setAddClinicAddressStep(1);
    // }
    const handleGoToAddManualAddress = () => {
        setAddClinicAddressStep(addClinicAddressStep + 1);
    }
    return (
        <div>
            <div className='container'>
                <div className='add_new_clinic_main'>
                    <div className='add_new_clinic_location'>
                        <div className='search_location'>
                            <img src={searchIcon} alt='search' />
                            <input type='text' placeholder='Search postcode or address' />
                        </div>
                        <div className='add_manually_add'>
                            <span onClick={handleGoToAddManualAddress}>Add manually</span>
                        </div>
                        <div className='add_clinic_name'>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddClinicAddress
