export const SAVE_WEBSITE_DETAILS = "SAVE_WEBSITE_DETAILS"
export const GET_WEBSITE_DETAILS = "GET_WEBSITE_DETAILS"

export const CHECK_WEBSITE_DOMAIN = "CHECK_WEBSITE_DOMAIN"
export const CHECK_WEBSITE_DOMAIN_RESPONSE = "CHECK_WEBSITE_DOMAIN_RESPONSE"

export const GENRERATE_TERMS_AND_CONDITIONS = "GENRERATE_TERMS_AND_CONDITIONS"

export const SAVE_TEAM_MEMBER_DETAILS = "SAVE_TEAM_MEMBER_DETAILS"

export const TRACK_USER_ACTIVITY = "TRACK_USER_ACTIVITY"

export const BUSINESS_CONTENT_DATA = "BUSINESS_CONTENT_DATA"

export const BUSINESS_HOURS_DATA = "BUSINESS_HOURS_DATA"

export const TEAM_MEMBER_EDIT_DATA = "TEAM_MEMBER_EDIT_DATA"

export const TEAM_MEMBERS_DATA = "TEAM_MEMBERS_DATA"

export const GET_WEBSITE_IMAGE_LOGO = "GET_WEBSITE_IMAGE_LOGO"

export const GET_WEBSITE_IMAGE_COVER_PHOTO = "GET_WEBSITE_IMAGE_COVER_PHOTO"

export const CLEAR_LOGO_IMAGE = "CLEAR_LOGO_IMAGE"

export const CLEAR_GENERATED_CONTENT = "CLEAR_GENERATED_CONTENT"

export const PUBLISH_WEBSITE = "PUBLISH_WEBSITE"