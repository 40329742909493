import { gql, useLazyQuery } from "@apollo/client"

const CHECK_TO_SHOW_PAYL8TR_POPUP = gql`
query CheckToShowPayl8trPopup {
    checkToShowPayl8trPopup {
      success
      message
    }
  }`

export const GetCheckToShowPayl8trPopupAction = () => {
    // const dispatch = useDispatch()
    const [checkToShowPayl8trPopupFunction, checkToShowPayl8trPopup] = useLazyQuery(
        CHECK_TO_SHOW_PAYL8TR_POPUP,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getClinicAvailableTimesForDate: responseData }) => {

                if (responseData?.success) {

                    // dispatch({
                    //     type: GET_TIME_SLOTS,
                    //     payload: responseData
                    // })
                }

            }
        }
    )
    const checkToShowPayl8trPopupData = checkToShowPayl8trPopup

    const initCheckToShowPayL8trPopup = (data) => {
        checkToShowPayl8trPopupFunction({
            variables: {
                ...data,
            }
        })
    }

    return { checkToShowPayl8trPopupData, initCheckToShowPayL8trPopup }
}