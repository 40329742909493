import { appBaseUrl } from "../../config";

export const URL = appBaseUrl;
export const BASE_URL = URL;
export const API_URL = {
    index: "/",
    REGISTER: '/register',
    EMAIL_VERIFICATION: '/otpVarificationCode',
    RESEND_CODE_EMAIL_VERIFICATION: '/resendVarificationCode',
    LOGIN: '/login',
    TREATMENTS: '/treatments',
    ONBOARDING_STATUS: '/users/groups/onboarding-status',
    DASHBOARD_LIST: '/dashboard/list',
    APPOINTMENT_LIST: '/dashboard/appointment-list',
    INSYNC_INSURANCE_WEBLINK: '/insync/insurance-weblink',
    GET_NEW_PROFILE: '/get-new-profile',
    CLINIC_REVIEWS: '/clinics/clinic-reviews',
    GET_PRESCRIPTION_COUNT: "/dashboard/prescription-watcher",

    UPDATE_OCCUPATIONS: '/users/groups/update-occupation',

    APPOINTMENT_DETAILS: '/dashboard/appointment-details',
    RESEND_FORWARD_CONSENT: '/consent-forms-records/resend-forward-consent-form-link',
    CANCEL_APPOINTMENT_CONST: '/calendar-booking/delete',
    CLIENTS_LIST: '/clients/list',
    CLINICS_FORWARD_REVIEW_SENDMAIL: "/clinics/forward-review-sendMail",
    REPLY_CLINIC_REVIEWS: "/clinics/reply-clinic-reviews",
    INSURANCE_WEB_LINK_PAGE: "/insync/insurance-weblink",
    LOGOUT: '/logout',


    CONNECT_STRIPE_EXPRESS: "/connect-express-stripe",

    INSYNC_INSURANCE_COMPANY_LIST: '/insurances/guestApplist',
    INSYNC_INSURANCE_COMPANY_PAGE_LIST: '/insurances/guestAppPage',

    PAYLATER_STATUS: '/getPayl8rEnabledStatus',

    DOWNLOAD_INVOICE_PAGE: '/calendar-booking/download-invoice-page',
    DOWNLOAD_INVOICE: '/calendar-booking/download-invoice',

    SUPPORT_CHAT_DATA_LINK: '/support-threads/list',
    SUPPORT_CHAT_DATA_THREAD_MESSAGES: '/support-chat/messages',
    SUPPORT_CHAT_CATEGORIES_LIST: '/support-categories/list',
    SUPPORT_THREADS_CREATE: '/support-threads/create',

    SUPPORT_CHAT_RECENT_ORDERS_LIST: '/support-threads/recent-orders',

    GET_ORDER_TRACE_MESSAGE_DATA: '/support-threads/track-order',
    SUPPORT_CHAT_THREADS_CREATE: '/support-chat/messages/send',
    SUPPORT_THREADS_ADD_FEEDBACK: '/support-threads/add-feedback',
    SUPPORT_THREADS_CHAT_UPDATE: '/support-threads/update',
    SUPPORT_THREADS_MESSAGES_MARK_READ: '/support-chat/messages/mark-read',
    SUPPORT_CHAT_TOTAL_UNREAD_COUNT: '/support-chat/total-unread-count',

    
    PRODUCT_GET_CART_COUNT: "/products/get-cart-count",
};