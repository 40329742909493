import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_APPOINTMENT_LIST_DATA } from "../../constants/appointment/appointmentConst";

const GET_APPOITMENT_LIST_QUERY = gql`
query GetCalendarAppointmentList($date: String, $search: String, $view: appointmentView, $locations: [Int], $status: [Int]) {
  getCalendarAppointmentList(date: $date, search: $search, view: $view, locations: $locations, status: $status) {
    success
    message
    data {
      appointments {
        appointmentId
        appointmentTitle
        treatmentNames
        appointmentDate
        appointmentStartTime
        appointmentEndTime
        appointmentColor
        isFilled
        type
        blockoutEndDate
        blockOutId
        blockOutDateId
        clientId
        consentFormRecordId
        paymentTag
        paymentTagColor
        consentFormTag
        consentFormTagColor
        isPaymentDone
        consentFormCount
        price
       }
      businessHours {
        daysOfWeek
        startTime
        endTime
      }
      dateWiseAppointmentCount {
        date
        count
      }
      vendorIsPharmacy
      view
      isUpdateCalendarViewTooltip
      isUpdateWorkingAvailabilityTooltip
    }
  }
}
`;

var page;
export const GetAppointmentList = () => {
  const dispatch = useDispatch();
  const [getAppointmentListData, appointmentListData] = useLazyQuery(
    GET_APPOITMENT_LIST_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getCalendarAppointmentList: responseData }) => {
        if (responseData.success) {
          dispatch({
            type: GET_APPOINTMENT_LIST_DATA,
            payload: responseData.data,
            page,
          });
        } else {
        }
      },
    }
  );
  const appointmentListQueryData = appointmentListData;
  const initGetAppointmentList = (data) => {
    page = data?.page ? data?.page : 1;
    getAppointmentListData({
      variables: data,
    });
  };
  return { initGetAppointmentList, appointmentListQueryData };
};
