import { restApiConfigUrl } from "../config";


const trackGoogleAPI = async (user_id, dynamicUrl, callType) => {
    console.log('first', user_id, dynamicUrl, callType)

    const url = new URL(`${restApiConfigUrl}/google-api/place/track-google-api/json`);

    // Append query parameters
    url.searchParams.append('user_id', user_id);
    url.searchParams.append('url', dynamicUrl);
    url.searchParams.append('type', callType);

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        redirect: 'follow'
    };

    try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();  // Assuming the response is in JSON format
        return data;
    } catch (error) {
        console.error('API request failed:', error.message || error);
        throw error;
    }
};

export default trackGoogleAPI;
