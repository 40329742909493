import { gql, useMutation } from "@apollo/client";

const REMOVE_LOCATION_FIXED_HOUR_BREAK_FOR_DAY_MUTATION = gql`
mutation RemoveLocationFixedHourBreakForDay($elementId: Int) {
  removeLocationFixedHourBreakForDay(elementId: $elementId) {
    success
    message
  }
}
`;


export const RemoveLocationFixedHourBreakForDayAction = () => {
    const [
        RemoveLocationFixedHourBreakForDayResponseFunction,
        removeLocationFixedHourBreakForDayResponseData,
    ] = useMutation(REMOVE_LOCATION_FIXED_HOUR_BREAK_FOR_DAY_MUTATION)

    //this will be called from components
    const removeLocationFixedHourBreakForDayQueryData = removeLocationFixedHourBreakForDayResponseData; //variable

    const initRemoveLocationFixedHourBreakForDayData = (data, successCallback, errorCallback) => {
        RemoveLocationFixedHourBreakForDayResponseFunction({
            variables: data,
        }).then(({ data: { removeLocationFixedHourBreakForDay: responseData } }) => {
            if (responseData.success) {
                if (successCallback)
                    successCallback(responseData)
            }
            else {
                if (errorCallback)
                    errorCallback(responseData)
            }
        }).catch(() => {
            if (errorCallback)
                errorCallback()
        })
    }

    return { removeLocationFixedHourBreakForDayQueryData, initRemoveLocationFixedHourBreakForDayData };
};