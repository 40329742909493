import React from 'react'
import './walk-in-main.scss'
import tickIcon from "../../images/client/tick.svg"

const FormCompletePopUp = () => {
    return (
        <>
            <div className="popUp-card-main">
                <div className='img-div'>
                    <img src={tickIcon} width={150} height={150} />
                </div>
                <div className='popUp-card-content'>
                    <h3>Form complete </h3>
                    <p>Please hand the device back to the business owner. </p>
                </div>
            </div>
        </>
    )
}
export default FormCompletePopUp
