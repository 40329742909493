import { gql, useMutation } from "@apollo/client";


const ADD_BLOCK_OUT_TIME_MUTATION = gql`
mutation SaveBlockOutTime($blockOutId: Int, $blockOutDateId: Int, $description: String, $title: String, $clinicId: [Int], $startDate: String, $endDate: String, $startTime: String, $endTime: String, $isAllDay: Boolean, $enableBooking: Boolean, $repeatSetting: Int, $isDeleteFlag: Boolean) {
  saveBlockOutTime(blockOutId: $blockOutId, blockOutDateId: $blockOutDateId, description: $description, title: $title, clinicId: $clinicId, startDate: $startDate, endDate: $endDate, startTime: $startTime, endTime: $endTime, isAllDay: $isAllDay, enableBooking: $enableBooking, repeatSetting: $repeatSetting, isDeleteFlag: $isDeleteFlag) {
    success
    message
    data {
      blockOutId
      blockOutDateId
    }
  }
}
`;

export const AddBlockOutTimeMutation = () => {
  const [addBlockoutTime, addBlockTimeResponseData] =
    useMutation(ADD_BLOCK_OUT_TIME_MUTATION);
  const addBlockTimeMutationData = addBlockTimeResponseData;

  const initiateBlockOutTime = (
    data,
    successCallback,
    errorCallback
  ) => {
    addBlockoutTime({
      variables: data,
    })
      .then(({ data: { saveBlockOutTime: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateBlockOutTime, addBlockTimeMutationData };
};