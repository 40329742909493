import { gql, useLazyQuery } from "@apollo/client"

const GET_MARKETING_SMS_DETAILS = gql`
query GetMarketingSMSDetails($isOnlyCredit: Boolean) {
  getMarketingSMSDetails(isOnlyCredit: $isOnlyCredit) {
    success
    message
    data {
      availableTextBalance
      planData {
        planTitle
        amount
        textBalance
        descriptionData
      }
      SMSPurchaseLink
    }
  }
}`

export const GetMarketingSMSDetailsAction = () => {
    // const dispatch = useDispatch()
    const [getMarketingSMSDetailsFunction, marketingSMSDetails] = useLazyQuery(
        GET_MARKETING_SMS_DETAILS,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getMarketingSMSDetails: responseData }) => {

                if (responseData?.success) {
                    // dispatch({
                    //     type: GET_TIME_SLOTS,
                    //     payload: responseData
                    // })
                }
            }
        }
    )

    const marketingSMSDetailsData = marketingSMSDetails

    const initGetMarketingSMSDetails = (data, successCallback, errorCallback) => {
        getMarketingSMSDetailsFunction({
            variables: {
                ...data,
            }
        }).then((res) => { successCallback(res?.data?.getMarketingSMSDetails) }).catch((err) => { errorCallback(err) })
    }

    return { marketingSMSDetailsData, initGetMarketingSMSDetails }
}
