// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";

const GET_GOOGLE_REVIEWS_QUERY = gql`
  query GetFacesGoogleReviews {
    getFacesGoogleReviews {
      success
      message
      data {
        reviews {
          userName
          reviewDate
          reviewFrom
          userRating
          userReview
          userImage
        }
        total_reviews
        average_rating
      }
    }
  }
`;

export const GetFacesGoogleReviewsAction = () => {
  const dispatch = useDispatch();
  const [
    getFacesGoogleReviewsResponseFunction,
    getFacesGoogleReviewsResponseData,
  ] = useLazyQuery(GET_GOOGLE_REVIEWS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getFacesGoogleReviews: responseData }) => {
      if (responseData.success) {
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });

  const getFacesGoogleReviewsQueryData = getFacesGoogleReviewsResponseData;

  const initGetFacesGoogleReviewsData = (data) => {
    getFacesGoogleReviewsResponseFunction({
      variables: data,
    }).then((reponse) => {
      console.log("GET_WEBSITE_BUILDER_GOOGLE_REVIEW", reponse?.data?.getFacesGoogleReviews?.data?.reviews)
      dispatch({
        type: "GET_WEBSITE_BUILDER_GOOGLE_REVIEW",
        payload: reponse?.data?.getFacesGoogleReviews?.data?.reviews,
      });
    }).catch((error) => {
      console.log('getWebsiteBuilderGoogleReviews error', error);
    });
  };

  return { getFacesGoogleReviewsQueryData, initGetFacesGoogleReviewsData };
};
