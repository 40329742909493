import React from "react";
import { ReactComponent as NoClient } from "../../../../../images/id.svg";
import StepButton from "../../../../common/StepButton";


function EmptyClient({onClickAddClient}) {
  return (
    <div className="noClient">
      <NoClient />
      <h2 className="pageTitle">No clients yet</h2>
      <p>Begin by adding your first client.</p>
      <div className="addClientData">
        <StepButton label="Add Client" blue={true} onClick={onClickAddClient} />
      </div>
    </div>
  );
}

export default EmptyClient;
