import React, { useEffect , useState  } from "react";
import BusinessFooter from "../../businessFooter";
import BusinessHeader from "../../businessHeader";
import "./clinic-landing-page.scss";
import BannerImg from "../../../assets/Treatment/bannerImg.png";
import whatsappIcon from "../../../assets/Treatment/whatsapp-icon.svg";
import clinicImg from "../../../assets/Treatment/clinicImg.png";
import placeholderCourse from "../../../assets/Treatment/placeholder-course.png";
import servicesImg from "../../../assets/Treatment/servicesImg.png";
import avtarIcon from "../../../assets/Treatment/avtarIcon.png";
import starIcon from "../../../assets/Treatment/star.svg";
import placeholderTreIcon from "../../../assets/Treatment/placeholderTre.png";
import otherServiceBoxImg from "../../../assets/clinic/otherserviceboximg.png"
import Slider from "react-slick";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router";
import { webBaseUrl } from "../../../config";
import { FaStar } from "react-icons/fa";
import teamImg1 from "../../../assets/clinic/team-img-1.png";
import teamImg2 from "../../../assets/clinic/team-img-2.png";
import teamImg3 from "../../../assets/clinic/team-img-3.png";
import teamImg4 from "../../../assets/clinic/team-img-4.png";

import bannerSliderImg1 from "../../../assets/clinic/banner-slide-img-1.png";
import bannerSliderImg2 from "../../../assets/clinic/banner-slide-img-2.png";
import bannerSliderImg3 from "../../../assets/clinic/banner-slide-img-3.png";
import bannerSliderImg4 from "../../../assets/clinic/banner-slide-img-4.png";
import bannerSliderImg5 from "../../../assets/clinic/banner-slide-img-5.png";
import bannerSliderImg6 from "../../../assets/clinic/banner-slide-img-6.png";

import facesVerifiedImg from "../../../assets/clinic/verified-img.png";
import awardImg from "../../../assets/clinic/award-img.png";
import payl8r from "../../../assets/clinic/payl8r-img.png";
import insuranceIcon from "../../../assets/clinic/insurance.png";

import "aos/dist/aos.css";
import AOS from "aos";

function Cliniclandingpage() {
  const navigate = useNavigate();
  const ClicnicData = [
    {
      id: "01",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "02",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "03",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "04",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "05",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "06",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "07",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
    {
      id: "08",
      treatmentImg: clinicImg,
      treatmentName: "Treatment Name",
      treatmentPrice: "Price",
    },
  ];

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-next-arrow slick-next" onClick={onClick}>
        <BsArrowRight />
      </div>
    );
  };

  // Custom arrow component
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-prev-arrow slick-prev" onClick={onClick}>
        <BsArrowLeft />
      </div>
    );
  };

  const TestimonialSlider = {
    slidesToShow: 3,
    dots: false,
    swipeToSlide: true,
    focusOnSelect: false,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const relatedTreatment = [
    {
      id: '01',
      TreatmentName: "Consultation",
    },
    {
      id: '02',
      TreatmentName: "Filler Top Up",
    },
    {
      id: '03',
      TreatmentName: "Hair Line Regeneration",
    },
    {
      id: '04',
      TreatmentName: "Jaw and Chin Definition",
    },
    {
      id: '05',
      TreatmentName: "Consultation",
    },
    {
      id: '06',
      TreatmentName: "Filler Top Up",
    },
    {
      id: '07',
      TreatmentName: "Hair Line Regeneration",
    },
    {
      id: '08',
      TreatmentName: "Jaw and Chin Definition",
    }
  ]
  // const [nav1, setNav1] = useState();
  // const [nav2, setNav2] = useState();

  const [nav1, setNav1] = React.useState(null)
  const [nav2, setNav2] = React.useState(null)
    let slider1 = []
    let slider2 = []

    React.useEffect(() => {
        setNav1(slider1)
        setNav2(slider2)
    }, [slider1, slider2])

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-in-out",
    });
    return () => {
      AOS.refresh();
    };
  }, []);

  return (
    <div className="MainClinicPage">
      <BusinessHeader />
      {/* <section className="TreatmentBannerSec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="BannerContent">
                <h1 data-aos="fade-up">Laser Hair Removal</h1>
                <p data-aos="fade-up">
                    Laser Hair Removal offers long-lasting smoothness by targeting hair follicles with precision, providing a convenient and effective solution for unwanted hair.
                </p>
                <div className="btnWrapper" data-aos="fade-up">
                  <button className="FillBtn" onClick={()=> {window.location.href=(webBaseUrl + '/clinics')}}>Book now</button>
                  <button className="WhatBtn" onClick={() => {window.open("https://api.whatsapp.com/send/?phone=%2B447398592729&text=Hi&type=phone_number&app_absent=0","_blank");}}>
                    <span className="btnIcon">
                      <img src={whatsappIcon} alt={whatsappIcon} />
                    </span>
                    Learn more
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 px-0">
              <div className="BannerImg" data-aos="fade-left" data-aos-duration="700">
                <img src={BannerImg} alt={BannerImg} />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="ClinicBanner">
        <div className="container">
          <div className="ClinicBannerWrap">
            <div className="row align-items-center">
              <div className="col-lg-6" data-aos="fade-right">
                <div className="clinicBannerLeft">
                  <div className="Title">
                    <h2>Beauty clinic</h2>
                    <div className="verifiedTag">
                      <img src={facesVerifiedImg} />
                      <span>Faces Verified</span>
                    </div>
                  </div>
                  <div className="awardWining">
                    <img src={awardImg} />
                    <span>Award Winning</span>
                  </div>
                  <div className="reviewPricing">
                    <div className="price">
                      <p>£ 88.00 - £ 200.00*</p>
                    </div>
                    <div className="rateText">
                      <span className="ratingValue">5.0</span>
                      <span className="ratingStar">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      </span>
                      <span className="ratingReview">(208 reviews)</span>
                    </div>
                  </div>
                  <div className="bannerDescription">
                    <p>dolor maximus non varius porta leo. sit ultrices placerat facilisis sodales. vitae sollicitudin. Nam sed faucibus Nunc</p>
                  </div>
                  <div className="bannerBtnGrp">
                    <a className="cusPrimaryBtn">Book Appointment</a>
                    <a className="cusSecondaryBtn">Get Direction</a>
                  </div>
                  <div className="payl8rTag">
                    <img src={payl8r} />
                    <span>Available</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-left">
                {/* <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                  <div>
                      <img src={bannerSliderImg1} />
                  </div>
                  <div>
                      <img src={bannerSliderImg2} />
                  </div>
                  <div>
                      <img src={bannerSliderImg3} />
                  </div>
                  <div>
                      <img src={bannerSliderImg4} />
                  </div>
                  <div>
                      <img src={bannerSliderImg5} />
                  </div>
                  <div>
                      <img src={bannerSliderImg6} />
                  </div>
                </Slider>
                <Slider
                  asNavFor={nav1}
                  ref={(slider2) => setNav2(slider2)}
                  slidesToShow={4}
                  swipeToSlide={true}
                  focusOnSelect={true}
                >
                  <div>
                      <img src={bannerSliderImg1} />
                  </div>
                  <div>
                      <img src={bannerSliderImg2} />
                  </div>
                  <div>
                      <img src={bannerSliderImg3} />
                  </div>
                  <div>
                      <img src={bannerSliderImg4} />
                  </div>
                  <div>
                      <img src={bannerSliderImg5} />
                  </div>
                  <div>
                      <img src={bannerSliderImg6} />
                  </div>
                </Slider> */}

                <div className="bannerSlider">
                   <div className="slider-one">
                    <Slider
                        asNavFor={nav2}
                        ref={slider => (slider1 = slider)}
                      >
                        <div>
                          <div className="cus-img">
                            <img src={bannerSliderImg1} />
                          </div>
                        </div>
                        <div>
                          <div className="cus-img">
                            <img src={bannerSliderImg2} />
                          </div>
                        </div>
                        <div>
                          <div className="cus-img">
                            <img src={bannerSliderImg3} />
                          </div>
                        </div>
                        <div>
                          <div className="cus-img">
                            <img src={bannerSliderImg4} />
                          </div>
                        </div>
                        <div>
                          <div className="cus-img">
                            <img src={bannerSliderImg5} />
                          </div>
                        </div>
                        <div>
                          <div className="cus-img">
                            <img src={bannerSliderImg6} />
                          </div>
                        </div>
                      </Slider>
                   </div>
                   <div className="slider-two">
                    <Slider
                        asNavFor={nav1}
                        ref={slider => (slider2 = slider)}
                        slidesToShow={5}
                        swipeToSlide={true}
                        focusOnSelect={true}
                      >
                        <div className="pr-2">
                          <img src={bannerSliderImg1} />
                        </div>
                        <div className="pr-2">
                          <img src={bannerSliderImg2} />
                        </div>
                        <div className="pr-2">
                          <img src={bannerSliderImg3} />
                        </div>
                        <div className="pr-2">
                          <img src={bannerSliderImg4} />
                        </div>
                        <div className="pr-2">
                          <img src={bannerSliderImg5} />
                        </div>
                        <div className="pr-2">
                          <img src={bannerSliderImg6} />
                        </div>
                      </Slider>
                   </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="OtherServiceSec sameSpace" id="other-services">
        <div className="container">
          <div className="secTitle"  
             id="OtherServiceSec"
             data-aos="fade-up"
             data-aos-anchor="#OtherService"
             data-aos-offset={700}
             data-aos-duration={700}
              >
            <div className="LeftContent">
              <h2>Other Services Offered by {'{clinic}'}</h2>
              <p>
              dolor maximus non varius porta leo. sit ultrices placerat facilisis sodales. vitae sollicitudin. Nam sed faucibus Nunc
              </p>
            </div>
            <div className="rightContent">
              <button className="ViewAll">View All</button>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="OtherServiceBox" data-aos="fade-up"
                  data-aos-duration={700}
                  data-aos-offset={700}
                  data-aos-anchor="#other-services">
                <img src={otherServiceBoxImg} />
                <div className="OtherServiceBoxDetail">
                  <div className="salonName">
                    <h5>Stark Salon</h5>
                    <div className="insuredTag"><img src={insuranceIcon} /> Insured</div>
                  </div>
                  <div className="priceAndDistance">
                    <p>£ 88.00 - £ 200.00*</p>
                    <span className="ten-minute">10 mins away</span>
                  </div>
                  <div className="rateText">
                    <span className="ratingValue">5.0</span>
                    <span className="ratingStar">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    </span>
                    <span className="ratingReview">(208 reviews)</span>
                  </div>
                  <div className="locationText">
                    <p>Clayton Road, Newcastle-under-Lyme, England.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="OtherServiceBox" data-aos="fade-up"
                  data-aos-duration={750}
                  data-aos-offset={750}
                  data-aos-anchor="#other-services">
                <img src={otherServiceBoxImg} />
                <div className="OtherServiceBoxDetail">
                  <div className="salonName">
                    <h5>Stark Salon</h5>
                    <div className="insuredTag"><img src={insuranceIcon} /> Insured</div>
                  </div>
                  <div className="priceAndDistance">
                    <p>£ 88.00 - £ 200.00*</p>
                    <span className="thirty-minute">30 mins away</span>
                  </div>
                  <div className="rateText">
                    <span className="ratingValue">5.0</span>
                    <span className="ratingStar">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    </span>
                    <span className="ratingReview">(208 reviews)</span>
                  </div>
                  <div className="locationText">
                    <p>Clayton Road, Newcastle-under-Lyme, England.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="OtherServiceBox" data-aos="fade-up"
                  data-aos-duration={800}
                  data-aos-offset={800}
                  data-aos-anchor="#other-services">
                <img src={otherServiceBoxImg} />
                <div className="OtherServiceBoxDetail">
                  <div className="salonName">
                    <h5>Stark Salon</h5>
                    <div className="insuredTag"><img src={insuranceIcon} /> Insured</div>
                  </div>
                  <div className="priceAndDistance">
                    <p>£ 88.00 - £ 200.00*</p>
                    <span className="sixty-minute">60 mins away</span>
                  </div>
                  <div className="rateText">
                    <span className="ratingValue">5.0</span>
                    <span className="ratingStar">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    </span>
                    <span className="ratingReview">(208 reviews)</span>
                  </div>
                  <div className="locationText">
                    <p>Clayton Road, Newcastle-under-Lyme, England.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row">
            {ClicnicData?.map((item, index) => {
              return (
                <div className="col-sm-12 col-md-4 col-lg-3 mb-4"   
                id="OtherServiceSec"
                data-aos="fade-up"
                data-aos-anchor="#OtherService"
                data-aos-offset={800 + index * 100}
                data-aos-duration={800 + index * 100}
                
                >
                  <div className="OtherServiceBox" key={index} onClick={()=>{window.location.href = "#"}}>
                    <div className="OtherServiceBoxImg">
                      <img src={item?.treatmentImg} alt={item?.treatmentImg} />
                    </div>
                    <div className="OtherServiceContent">
                      <h5>{item?.treatmentName}</h5>
                      <p className="ReviewPrise">5.0<span className="starIcon"><FaStar /></span> (5)</p>
                      <p className="priceText">{item?.treatmentPrice}</p>
                      <p className="LocationText">North Yorkshire, Liverpool</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
      </section>

      <section className="bookingProcess bgGrey sameSpace" id="booking">
        <div className="container">
          <div className="secTitle"    
                  data-aos="fade-up"
                  data-aos-duration={700}
                  data-aos-offset={700}
                  data-aos-anchor="#booking">
            <div className="LeftContent">
              <h2 className="text-center">Booking process</h2>
              <p className="text-center">
              3 . Updated the Faces treatment landing page according to Ben's feedback, Please check and let me know your thoughts.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-4"  
                  data-aos="fade-up"
                  data-aos-duration={800}
                  data-aos-offset={800}
                  data-aos-anchor="#booking">
              <div className="stepBox">
                <img src={placeholderCourse} alt={placeholderCourse} />
                <h5>Step 1</h5>
              </div>
            </div>
            <div className="col-sm-12 col-md-4"  
                  data-aos="fade-up"
                  data-aos-duration={850}
                  data-aos-offset={850}
                  data-aos-anchor="#booking">
              <div className="stepBox">
                <img src={placeholderCourse} alt={placeholderCourse} />
                <h5>Step 2</h5>
              </div>
            </div>
            <div className="col-sm-12 col-md-4"  
                  data-aos="fade-up"
                  data-aos-duration={900}
                  data-aos-offset={900}
                  data-aos-anchor="#booking">
              <div className="stepBox">
                <img src={placeholderCourse} alt={placeholderCourse} />
                <h5>Step 3</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutClinc" id="about-clinic">
        <div className="container">
          <div className="row aboutClinicWrap">
            <div className="col-md-6 aboutClinicLeft" data-aos="fade-right"
                  data-aos-duration={700}
                  data-aos-offset={700}
                  data-aos-anchor="#about-clinic">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.9114983370628!2d72.52169757471502!3d23.027021516163334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b8265ae3505%3A0xb4ec352c3daf2904!2sTecherudite!5e0!3m2!1sen!2sin!4v1713171250324!5m2!1sen!2sin" width="100%" height="479" style={{border:"0",borderRadius:"30px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="col-md-6 aboutClinicRight" data-aos="fade-left"
                  data-aos-duration={700}
                  data-aos-offset={700}
                  data-aos-anchor="#about-clinic">
              <h2>About {"{ Clinic Name }"}</h2>
              <p>dolor maximus non varius porta leo. sit ultrices placerat facilisis sodales. vitae sollicitudin.</p>
              <p>dolor maximus non varius porta leo. sit ultrices placerat facilisis sodales. vitae sollicitudin.  facilisis sodales. vitae sollicitudin.</p>
              <a className="cusPrimaryBtn">Book Appointment</a>
            </div>
          </div>
        </div>
      </section>

      <section className="treatmentsSec bgGrey sameSpace" id="treatments">
        <div className="container">
          <div className="secTitle"  data-aos="fade-up"  
          data-aos-duration={700}
          data-aos-offset={700}
          data-aos-anchor="#treatments">
            <div className="LeftContent">
              <h2 className="text-center">Browse Related {"{treatments}"}</h2>
            </div>
          </div>
          <div className="treatmentsGridWrapper">
            {relatedTreatment.map((item, index) =>{
              return(
              <div className="treatmentsGridItem" key={index}>
                <p
              data-aos="fade-up"
              data-aos-anchor="#treatments"
              data-aos-offset={300 + index * 100}
              data-aos-duration={300 + index * 100}>{item?.TreatmentName}</p>
              </div>
              )
            })}
            
          </div>
        </div>
      </section>

      

      <section className="whyFaces bgGrey sameSpace" id="whyFacesSec">
        <div className="container">
          <div className="secTitle" data-aos="fade-up"  
              data-aos-duration={700}
              data-aos-offset={700}
              data-aos-anchor="#whyFacesSec">
            <div className="LeftContent">
              <h2 className="text-center">
                Why <span className="BlueColor">Faces?</span>
              </h2>
              <p className="text-center">
              Over 100,000 professionals use faces to manage their client bookings, you can easily <br/> find a professional in your area and book a treatment that suits your needs.
              </p>
            </div>
          </div>

          <div className="SearchClicnic"  
              data-aos="fade-up"  
              data-aos-duration={750}
              data-aos-offset={750}
              data-aos-anchor="#whyFacesSec">
            <button className="FillBtn">Search clinics</button>
          </div>
        </div>
      </section>

      <section className="treatmentsSec bgGrey sameSpace" id="treatments-2">
        <div className="container">
          <div className="secTitle"  data-aos="fade-up"  
          data-aos-duration={700}
          data-aos-offset={700}
          data-aos-anchor="#treatments-2">
            <div className="LeftContent">
              <h2 className="text-center">Brows Other Clinics</h2>
            </div>
          </div>
          <div className="treatmentsGridWrapper">
            {relatedTreatment.map((item, index) =>{
              return(
              <div className="treatmentsGridItem" key={index}>
                <p
              data-aos="fade-up"
              data-aos-anchor="#treatments-2"
              data-aos-offset={300 + index * 100}
              data-aos-duration={300 + index * 100}>{item?.TreatmentName}</p>
              </div>
              )
            })}
            
          </div>
        </div>
      </section>
      <section className="TestimonialSec sameSpace" id="Testimonial">
        <div className="container">
          <div className="secTitle"  
              data-aos="fade-up"  
              data-aos-duration={700}
              data-aos-offset={700}
              data-aos-anchor="#Testimonial">
            <div className="LeftContent">
              <h2 className="text-center">Top reviews of professionals</h2>
              <p className="text-center">
              dolor maximus non varius porta leo. sit ultrices placerat facilisis sodales. vitae sollicitudin. Nam sed faucibus Nunc
              </p>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-sm-12 col-md-4"> */}
          <Slider {...TestimonialSlider} >
            <div>
              <div className="TestimonialBox">
                <div className="TestimonialFirstContent">
                  <div className="ProfileWrapper">
                    <div className="LeftProfile">
                      <img src={avtarIcon} alt={avtarIcon} />
                      <div className="ContnetWrapper">
                        <h5>Connie M.</h5>
                        <p>6 March 2024</p>
                      </div>
                    </div>
                    <div className="RightProfile">
                      <ul>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    dolor maximus non varius porta leo. sit ultrices placerat
                    facilisis sodales. vitae sollicitudin. Nam sed faucibus Nunc
                  </p>
                </div>
                <div className="TestimonialBottomContent">
                  <div className="BottomSecWrapper">
                    <div className="LeftSec">
                      <h5>Seven Star Salon</h5>
                      <div className="reviewWrapper">
                        <span className="reviewCount">5.0</span>
                        <span className="reviewStar">5.0</span>
                        <span className="reviewContent">208 reviews</span>
                      </div>
                      <p>UK, 45 Paisley Road West, Glasgow, G5...</p>
                    </div>
                    <div className="RightSec">
                      <img src={servicesImg} alt={servicesImg} />
                    </div>
                  </div>
                  <p>View all services</p>
                </div>
              </div>
            </div>
            <div>
              <div className="TestimonialBox">
                <div className="TestimonialFirstContent">
                  <div className="ProfileWrapper">
                    <div className="LeftProfile">
                      <img src={avtarIcon} alt={avtarIcon} />
                      <div className="ContnetWrapper">
                        <h5>Connie M.</h5>
                        <p>6 March 2024</p>
                      </div>
                    </div>
                    <div className="RightProfile">
                      <ul>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    dolor maximus non varius porta leo. sit ultrices placerat
                    facilisis sodales. vitae sollicitudin. Nam sed faucibus Nunc
                  </p>
                </div>
                <div className="TestimonialBottomContent">
                  <div className="BottomSecWrapper">
                    <div className="LeftSec">
                      <h5>Seven Star Salon</h5>
                      <div className="reviewWrapper">
                        <span className="reviewCount">5.0</span>
                        <span className="reviewStar">5.0</span>
                        <span className="reviewContent">208 reviews</span>
                      </div>
                      <p>UK, 45 Paisley Road West, Glasgow, G5...</p>
                    </div>
                    <div className="RightSec">
                      <img src={servicesImg} alt={servicesImg} />
                    </div>
                  </div>
                  <p>View all services</p>
                </div>
              </div>
            </div>
            <div>
              <div className="TestimonialBox">
                <div className="TestimonialFirstContent">
                  <div className="ProfileWrapper">
                    <div className="LeftProfile">
                      <img src={avtarIcon} alt={avtarIcon} />
                      <div className="ContnetWrapper">
                        <h5>Connie M.</h5>
                        <p>6 March 2024</p>
                      </div>
                    </div>
                    <div className="RightProfile">
                      <ul>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    dolor maximus non varius porta leo. sit ultrices placerat
                    facilisis sodales. vitae sollicitudin. Nam sed faucibus Nunc
                  </p>
                </div>
                <div className="TestimonialBottomContent">
                  <div className="BottomSecWrapper">
                    <div className="LeftSec">
                      <h5>Seven Star Salon</h5>
                      <div className="reviewWrapper">
                        <span className="reviewCount">5.0</span>
                        <span className="reviewStar">5.0</span>
                        <span className="reviewContent">208 reviews</span>
                      </div>
                      <p>UK, 45 Paisley Road West, Glasgow, G5...</p>
                    </div>
                    <div className="RightSec">
                      <img src={servicesImg} alt={servicesImg} />
                    </div>
                  </div>
                  <p>View all services</p>
                </div>
              </div>
            </div>
            <div>
              <div className="TestimonialBox">
                <div className="TestimonialFirstContent">
                  <div className="ProfileWrapper">
                    <div className="LeftProfile">
                      <img src={avtarIcon} alt={avtarIcon} />
                      <div className="ContnetWrapper">
                        <h5>Connie M.</h5>
                        <p>6 March 2024</p>
                      </div>
                    </div>
                    <div className="RightProfile">
                      <ul>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                        <li>
                          <img src={starIcon} alt={starIcon} />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    dolor maximus non varius porta leo. sit ultrices placerat
                    facilisis sodales. vitae sollicitudin. Nam sed faucibus Nunc
                  </p>
                </div>
                <div className="TestimonialBottomContent">
                  <div className="BottomSecWrapper">
                    <div className="LeftSec">
                      <h5>Seven Star Salon</h5>
                      <div className="reviewWrapper">
                        <span className="reviewCount">5.0</span>
                        <span className="reviewStar">5.0</span>
                        <span className="reviewContent">208 reviews</span>
                      </div>
                      <p>UK, 45 Paisley Road West, Glasgow, G5...</p>
                    </div>
                    <div className="RightSec">
                      <img src={servicesImg} alt={servicesImg} />
                    </div>
                  </div>
                  <p>View all services</p>
                </div>
              </div>
            </div>
          </Slider>
          {/* </div>
          </div> */}
        </div>
      </section>

      <section className="TeamProfile" id="team-profile">
        <div className="container">
          <div className="Title" data-aos="fade-up"
                  data-aos-duration={700}
                  data-aos-offset={700}
                  data-aos-anchor="#team-profile">
            <h2>Meet the Team</h2>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6" data-aos="fade-up"
                  data-aos-duration={700}
                  data-aos-offset={700}
                  data-aos-anchor="#team-profile">
              <div className="TeamBox">
                <img src={teamImg1} />
                <div className="detailBox">
                  <h5>Kathrin mic</h5>
                  <span>Lead aesthetician</span>
                  <p>Meet our lead aesthetician, Kathrin mic, a renowned expert in lip enhancement techniques with over 7 years of experience.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6" data-aos="fade-up"
                  data-aos-duration={700}
                  data-aos-offset={700}
                  data-aos-anchor="#team-profile">
              <div className="TeamBox">
                <img src={teamImg2} />
                <div className="detailBox">
                  <h5>Kathrin mic</h5>
                  <span>Lead aesthetician</span>
                  <p>Meet our lead aesthetician, Kathrin mic, a renowned expert in lip enhancement techniques with over 7 years of experience.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6" data-aos="fade-up"
                  data-aos-duration={700}
                  data-aos-offset={700}
                  data-aos-anchor="#team-profile">
              <div className="TeamBox">
                <img src={teamImg3} />
                <div className="detailBox">
                  <h5>Kathrin mic</h5>
                  <span>Lead aesthetician</span>
                  <p>Meet our lead aesthetician, Kathrin mic, a renowned expert in lip enhancement techniques with over 7 years of experience.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6" data-aos="fade-up"
                  data-aos-duration={700}
                  data-aos-offset={700}
                  data-aos-anchor="#team-profile">
              <div className="TeamBox">
                <img src={teamImg4} />
                <div className="detailBox">
                  <h5>Kathrin mic</h5>
                  <span>Lead aesthetician</span>
                  <p>Meet our lead aesthetician, Kathrin mic, a renowned expert in lip enhancement techniques with over 7 years of experience.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

          

      {/* <section className="FaQSec sameSpace" id="FaQ">
        <div className="container">
          <div className="secTitle"   
          data-aos="fade-up"  
          data-aos-duration={750}
          data-aos-offset={750}
          data-aos-anchor="#FaQ">
            <div className="LeftContent">
              <h3 className="text-center">Questions about ‘Treatment’</h3>
              <p className="text-center">
              Find below the most common asked questions about ‘Treatment’
              </p>
            </div>
          </div>
          <div class="accordion" id="accordionExample">
            <div class="accordion-item"    
          data-aos="fade-up"  
          data-aos-duration={800}
          data-aos-offset={800}
          data-aos-anchor="#FaQ">
              <h3 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  Lorim Ipsum dummy text here
                </button>
              </h3>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="accordion-item"   
            data-aos="fade-up"  
          data-aos-duration={850}
          data-aos-offset={850}
          data-aos-anchor="#FaQ">
              <h3 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Lorim Ipsum dummy text here
                </button>
              </h3>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="accordion-item"   
          data-aos="fade-up"  
          data-aos-duration={900}
          data-aos-offset={900}
          data-aos-anchor="#FaQ">
              <h3 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Lorim Ipsum dummy text here
                </button>
              </h3>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="accordion-item"  
          data-aos="fade-up"  
          data-aos-duration={950}
          data-aos-offset={950}
          data-aos-anchor="#FaQ">
              <h3 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Lorim Ipsum dummy text here
                </button>
              </h3>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="accordion-item"  
          data-aos="fade-up"  
          data-aos-duration={1000}
          data-aos-offset={1000}
          data-aos-anchor="#FaQ">
              <h3 class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Lorim Ipsum dummy text here
                </button>
              </h3>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
            <div class="accordion-item"  
          data-aos="fade-up"  
          data-aos-duration={1050}
          data-aos-offset={1050}
          data-aos-anchor="#FaQ">
              <h3 class="accordion-header" id="headingSix">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Lorim Ipsum dummy text here
                </button>
              </h3>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="treatmentsSec bgGrey sameSpace" id="treatments">
        <div className="container">
          <div className="secTitle"  data-aos="fade-up"  
          data-aos-duration={700}
          data-aos-offset={700}
          data-aos-anchor="#treatments">
            <div className="LeftContent">
              <h2 className="text-center">Related treatments</h2>
            </div>
          </div>
          <div className="treatmentsGridWrapper">
            {relatedTreatment.map((item, index) =>{
              return(
              <div className="treatmentsGridItem" key={index}>
                <p
              data-aos="fade-up"
              data-aos-anchor="#treatments"
              data-aos-offset={300 + index * 100}
              data-aos-duration={300 + index * 100}>{item?.TreatmentName}</p>
              </div>
              )
            })}
            
          </div>
        </div>
      </section> */}

      {/* <section className="boxTreatment pb-0 sameSpace" id="boxTreatmentSec">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-8 px-0">
                <div className="boxLeftTreatment" 
              data-aos="fade-right"
              data-aos-anchor="#boxTreatmentSec"
              data-aos-offset={700}
              data-aos-duration={700}>
                    <h5>Laser Hair Removal treatment</h5>
                    <p>Laser Hair Removal treatment utilizes concentrated light energy to target and destroy hair follicles, inhibiting future hair growth. This safe and effective procedure offers long-lasting results and can be tailored to individual skin and hair types for optimal outcomes.</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 px-0">
                <div className="boxRightTreatment"
              data-aos="fade-left"
              data-aos-anchor="#boxTreatmentSec"
              data-aos-offset={700}
              data-aos-duration={700}>
                   <h5>How it works?</h5>
                    <p>Laser Hair Removal treatment targets hair follicles with concentrated light energy, disabling them to inhibit future hair growth. This safe and effective procedure selectively destroys hair while preserving surrounding skin, providing long-lasting smoothness. Multiple sessions may be needed for optimal results, tailored to individual skin and hair types.</p>
                </div>
              </div>
            </div>
          </div>
      </section> */}

      <section className="locationSec sameSpace" id="location">
        <div className="container">
          <div className="secTitle"  
              data-aos="fade-up"
              data-aos-anchor="#location"
              data-aos-offset={700}
              data-aos-duration={700}>
            <div className="LeftContent">
              <h2 className="text-center">Search {"{Treatments}"} Close to {"{location}"}</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-4"  
              data-aos="fade-up"
              data-aos-anchor="#location"
              data-aos-offset={950}
              data-aos-duration={950}>
              <div className="locationBox">
                <div className="locationHeader">
                    <h5>Popular towns</h5>
                </div>
                <ul>
                  <li>Stevenage</li>
                  <li>Solihull</li>
                  <li>Stourbridge</li>
                  <li>Kenilworth and Southam</li>
                  <li>Blackburn</li>
                  <li>Stevenage</li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-4"  
              data-aos="fade-up"
              data-aos-anchor="#location"
              data-aos-offset={850}
              data-aos-duration={850}>
              <div className="locationBox">
                <div className="locationHeader">
                    <h5>Popular cities</h5>
                </div>
                <ul>
                  <li>Liverpool</li>
                  <li>Manchester</li>
                  <li>Newcastle</li>
                  <li>Southampton</li>
                  <li>Portsmouth</li>
                  <li>Liverpool</li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-4"  
              data-aos="fade-up"
              data-aos-anchor="#location"
              data-aos-offset={750}
              data-aos-duration={750}>
              <div className="locationBox">
                <div className="locationHeader">
                    <h5>Popular countries</h5>
                </div>
                <ul>
                  <li onClick={()=>{window.location.href = "#"}}>North Yorkshire</li>
                  <li onClick={()=>{window.location.href = "#"}}>Greater London</li>
                  <li onClick={()=>{window.location.href = "#"}}>Northumberland</li>
                  <li onClick={()=>{window.location.href = "#"}}>Mid Glamorgan</li>
                  <li onClick={()=>{window.location.href = "#"}}>Lincolnshire</li>
                  <li onClick={()=>{window.location.href = "#"}}>North Yorkshire</li>
                </ul>
              </div>
            </div>
       
          </div>
        </div>
      </section>
      <BusinessFooter />
    </div>
  );
}

export default Cliniclandingpage;
