import React, { useState } from 'react'
import './sms-subscription-modal.scss'

import SMSImage from "../../../images/new-appointment/sms-image.png";
import CustomRadioButton from '../../common/CustomRadioButton';
import StepButton from '../../common/StepButton';
import CustomModal from '../../modal/CustomModal';
import HowItWorksModal from '../how-it-works/HowItWorksModal';
import { StripeComponent } from '../../Stripe/StripeComponent';
import { PurchaseSMSBalanceAction, UpdateSMSBalancePurchasePaymentAction } from '../../redux/actions/newAppointment/BusinessBoosters';
import Skeleton from 'react-loading-skeleton';
import showToast from '../../common/CustomToast/CustomToast';


const SMSSucriptionModal = ({ howItWorksData, setIsSmsSubscriptionOpen }) => {

    //STATES
    const [isSmsOptionChangeState, setIsSmsOptionChangeState] = useState(1)
    const [howItWorksModal, setHowItWorksModal] = useState(false)

    const [clientSecret, setClientSecret] = useState(null)

    //PAYMENT MODAL
    const [paymentModal, setPaymentModal] = useState(false)

    //ACTIONS 
    const { purchaseSMSBalanceQueryData, initPurchaseSMSBalanceData } = PurchaseSMSBalanceAction()
    const { updateSMSBalancePurchasePaymentQueryData, initUpdateSMSBalancePurchasePaymentData } = UpdateSMSBalancePurchasePaymentAction()

    //FUNCTIONS
    const handleBuyNowClick = () => {
        setClientSecret(null)
        setPaymentModal(true)
        initPurchaseSMSBalanceData({
            amount: isSmsOptionChangeState == 1 ? 50 : isSmsOptionChangeState == 2 ? 30 : 7,
        }, (responseData) => {
            setClientSecret(responseData.data.intentId)
        }, () => {
            setPaymentModal(false)
        })
    }


    const suscriptionRadioOptions = [
        {
            id: 1,
            value: "1",
            label: "1000 SMS for £50 - 5p per SMS",
        },
        {
            id: 2,
            value: "2",
            label: "500 SMS for £30 - 6p per SMS",
        },
        {
            id: 3,
            value: "3",
            label: "100 SMS for £7 - 7p per SMS",
        }]

    return (
        <>
            <div className="sms-subscription-container">
                <div className="my-3">
                    <p className="sms-header">
                        <span className='oops-text'>Oops,</span> You don't have any credit!
                    </p>
                    <p className="sms-header">
                        Select package to send out SMS reminders and promotions
                    </p>
                </div>
                <div className="sms-image my-2">
                    <img src={SMSImage} alt="SMSImage" />
                </div>
                <p className="my-2">
                    Stop no shows and give your clients a reminder
                    when their appointment is due. <span className="link" onClick={() => setHowItWorksModal(true)}>How it works</span>
                </p>
                <div className="radio-buttons-parent-div">
                    <h5>
                        Most Popular
                    </h5>
                    <div className="radio-buttons-div">
                        <span className="most-popular-tag">
                            Most Popular
                        </span>
                        {
                            suscriptionRadioOptions.map((item, index) =>
                                <div className="radio-button-common-div-content "
                                    onClick={() => (document.getElementById(item.id)).addEventListener(('change'),
                                        setIsSmsOptionChangeState(item.id))}
                                >
                                    <div className="w-100">
                                        <CustomRadioButton
                                            className="py-2"
                                            id={item.id}
                                            name="sms-subscription"
                                            value={item.value}
                                            text={item.label}
                                            checkedLabelStyles={isSmsOptionChangeState == item.id ? { color: "#fff", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", backgroundColor: "rgb(22, 88, 247)" } : { color: "#000000", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
                                            onChange={() => { setIsSmsOptionChangeState(item.id) }}
                                            checked={isSmsOptionChangeState == item.id}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className='buy-now-btn'>
                        <StepButton label={"Buy Now"} blue={true} className={"w-50 m-auto"} onClick={() => handleBuyNowClick()} />
                    </div>
                </div>
            </div>

            {/* HOW IT WORKS MODAL */}
            <CustomModal
                modalOpen={howItWorksModal}
                setModalOpen={setHowItWorksModal}
                type={"common-width"}
                modalBody={
                    <HowItWorksModal setHowItWorksModal={setHowItWorksModal} howItWorksData={howItWorksData} />
                }
            />

            {/* PAYMENT MODAL */}

            <CustomModal
                modalOpen={paymentModal}
                setModalOpen={setPaymentModal}
                type={"common-width"}
                modalBody={
                    <>
                        {
                            purchaseSMSBalanceQueryData?.loading ?
                                <>
                                    <div className='text-center'>
                                        <Skeleton height={20} width="80%" />
                                        <Skeleton height={20} width="90%" />
                                        <div className='my-3'>
                                            <Skeleton height={50} width="90%" />
                                        </div>
                                        <div className='d-flex justify-content-center align-items-center my-2'>
                                            <Skeleton height={30} width={50} className='me-2' />
                                            <Skeleton height={30} width={50} />
                                        </div>
                                        <Skeleton height={30} width={"60%"} />
                                    </div>
                                </>
                                :
                                <StripeComponent
                                    clientSecret={clientSecret}
                                    paymentType="card"
                                    isCloseIcon={false}
                                    stripeChildCustomCSS={"w-100"}
                                    stripeHeaingText={
                                        <>
                                            <div className='sms-subscription-stripe-compuenet-text'>
                                                <p>
                                                    You are now purchasing 1000 SMS text messages for £ 50.
                                                </p>
                                                <p>
                                                    Which can be used for promotional text offers and client reminders.
                                                </p>
                                            </div>
                                        </>
                                    }
                                    successCallback={(id) => {
                                        // setPaymentModal(false)
                                        initUpdateSMSBalancePurchasePaymentData({
                                            amount: isSmsOptionChangeState == 1 ? 50 : isSmsOptionChangeState == 2 ? 30 : 7,
                                            intentId: id
                                        }, (res) => {
                                            showToast('success', res.message)
                                            setPaymentModal(false)
                                            setIsSmsSubscriptionOpen(false)
                                        }, () => {
                                            handleBuyNowClick()
                                        })
                                    }
                                    }
                                />
                        }
                    </>
                }
            />

        </>
    )
}

export default SMSSucriptionModal