import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";

const GET_USER_ROLE_QUERY = gql`
query GetUserRole {
    getUserRole {
      success
      message
      data {
        user_type
      }
    }
  }
`;

export const GetUserRole = () => {
  const dispatch = useDispatch();
  const [getUserRole, { loading, error, data }] = useLazyQuery(GET_USER_ROLE_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: (response) => {
      // Handle the completed response here if necessary
    },
  });

  const initGetUserRole = () => {
    getUserRole();
  };

  return { loading, error, data, initGetUserRole};
};
