import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const SAVE_TERMS_AND_CONDITIONS_MUTATION = gql`
mutation SaveTermsAndConditions($pwcTermsAndConditions: String!) {
    saveTermsAndConditions(pwc_terms_and_conditions: $pwcTermsAndConditions) {
      success
      message
    }
  }
`;

export const SaveTermsAndConditions = () => {
  const [saveTermsAndConditionsRequest, saveTermsAndConditionsResponseData] =
    useMutation(SAVE_TERMS_AND_CONDITIONS_MUTATION);
  const dispatch = useDispatch();
  const saveTermsAndConditionsMutationData = saveTermsAndConditionsResponseData;

  const initiateSaveTermsAndConditions = (
    data,
    successCallback,
    errorCallback
  ) => {
    saveTermsAndConditionsRequest({
      variables: data,
    })
      .then(({ data: { saveTermsAndConditions: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateSaveTermsAndConditions, saveTermsAndConditionsMutationData };
};
