import React from 'react';
import './file-upload.scss';
import ImageUploading from 'react-images-uploading';
import imageUpload from '../../../../../images/new-appointment/file-upload.svg'


const CustomeImageUpload = ({openModalOnClick}) => {
    const [images, setImages] = React.useState([]);
    const maxNumber = 1;

    const onChange = (imageList) => {
        setImages(imageList);
    };

    return (
        <div>
            <div className="App">
                <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg", "png"]} // corrected acceptType syntax
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps
                    }) => (
                        <div className="upload__image-wrapper">
                            {imageList.length > 0 ? (
                                imageList.map((image, index) => (
                                    <div key={index} className="image-item">
                                        <img src={image.data_url} alt="" width={80} height={80} onClick={openModalOnClick} />
                                        <div className="image-item__btn-wrapper">
                                            <button onClick={() => onImageRemove(index)}>Remove</button>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <button
                                    style={isDragging ? { color: "red" } : null}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                >
                                    <img src={imageUpload} alt='img' width={40} height={40} />
                                    <p className='pt-2'>Add Images</p>
                                </button>
                            )}
                        </div>
                    )}
                </ImageUploading>
            </div>
        </div>
    );
};

export default CustomeImageUpload;
