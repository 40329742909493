import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const ADD_REVIEW_BOOKING_MUTATION = gql`
mutation BookingFeedback($booking: Int!, $rating: Float!, $review: String, $type: Int) {
  bookingFeedback(booking: $booking, rating: $rating, review: $review, type: $type) {
    success
    message
    data {
      booking
      rating
      review
    }
  }
}
`;

export const AddReviewBookingMutation = () => {
  const [addReviewBookingRequest, addReviewBookingResponseData] =
    useMutation(ADD_REVIEW_BOOKING_MUTATION);
  const dispatch = useDispatch();
  const addReviewBookingMutationData = addReviewBookingResponseData;

  const initiateAddReviewBooking = (
    data,
    successCallback,
    errorCallback
  ) => {
    addReviewBookingRequest({
      variables: data,
    })
      .then(({ data: { bookingFeedback: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateAddReviewBooking, addReviewBookingMutationData };
};
