import React, { useEffect, useState } from "react";
import CustomModal from "../../modal/CustomModal";
import StepButton from "../../common/StepButton";
import noIcon from "../../../images/no.svg";
import clientIcon from "../../../images/clientincirclearrows.svg";
import dateIcon from "../../../images/date.svg";
import noMoney from "../../../images/noMoney.svg";
import { ReactComponent as Logo } from "../../../assets/finance-registration/logo1.svg";
import FinanceBanner from "../../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner";
import { Link } from "react-router-dom";
import "./PrescriberBank.scss";
import { SavePrescriberBankDetails } from "../../redux/actions/pharmacy-registration/bankDetails";
import toast from "react-hot-toast";

export default function PrescriberBank({ onNextStep, afterSubmit }) {
  const { initiateSaveBankDetails } = SavePrescriberBankDetails();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [bankModalOpen, setBankModalOpen] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [bSortCodeinp, setBSortCodeinp] = useState(["", "", ""]);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [isAgree, setIsAgree] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleInputChange = (index, value) => {
    const maxLength = 2;

    if (!isNaN(value) && value.length <= maxLength) {
      const updatedBSortCode = [...bSortCodeinp];
      updatedBSortCode[index] = value;
      setBSortCodeinp(updatedBSortCode);
      setErrors((prevErrors) => {
        return { ...prevErrors, sortCode: "" };
      });

      if (value.length === maxLength && index < 2) {
        document.getElementById(`inp${index + 1}`).focus();
      }
    }
  };

  const handleBankSubmit = () => {
    const errors = {};

    if (accountNumber.trim().length < 8 || accountNumber.trim().length > 11) {
      errors.accountNumber = "Account number must be between 8 and 11 digits.";
    }

    const sortCode = bSortCodeinp.join("");
    if (sortCode.trim().length !== 6) {
      errors.sortCode = "Sort code is required and must be 6 digits.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      initiateSaveBankDetails(
        {
          bankAccountNumber: accountNumber,
          bankSortCode: bSortCodeinp.join(""),
        },
        (res) => {
          setLoading(false);
          toast.success(res.message);

          afterSubmit();
          onNextStep();

          setErrors({});
          setBankModalOpen(false);
          setAccountNumber("");
          setBSortCodeinp(["", "", ""]);
        },
        (error) => {
          setLoading(false);
          toast.error(error.message);
        }
      );
    }
  };

  return (
    <div>
      <>
        <div className="Main-congrats-Sec">
          <FinanceBanner
            title={"Congratulations"}
            desc={
              "You have registered for the prescription service and pharmacy marketplace. we have received your details and will review approve your account within 48hours "
            }
          />
          <div className="congrats-Content">
            <h5>Did you know ?</h5>
            <p>
              Did you know when registering for the pharmacy marketplace, the
              details you have provided also allow your business to register and
              offer buy now Payl8r in your clinic allowing clients to purchase
              your services via finance or monthly payments.
            </p>
          </div>

          <div className="PointsWrapper">
            <div className="PointsItem">
              <div className="PointsItemHeader">
                <div className="PointsItemImg">
                  <img src={noIcon} alt="icon" />
                </div>
                <h6 className="point-header">No Risk </h6>
              </div>
              <div className="PointsItemContent">
                <p>
                  {" "}
                  absolutely risk free, never worry about client repayments we
                  take that risk so you don’t have to
                </p>
              </div>
            </div>

            <div className="PointsItem">
              <div className="PointsItemHeader">
                <div className="PointsItemImg">
                  <img src={dateIcon} alt="dateIcon" />
                </div>
                <h6 className="point-header">Payout in 24-48 hours </h6>
              </div>
              <div className="PointsItemContent">
                <p> Receive payment within 48hours from the day of treatment</p>
              </div>
            </div>

            <div className="PointsItem">
              <div className="PointsItemHeader">
                <div className="PointsItemImg">
                  <img src={noMoney} alt="noMoney" />
                </div>
                <h6 className="point-header">Fees as little as 3% </h6>
              </div>
              <div className="PointsItemContent">
                <p> Pick a model to suit you</p>
              </div>
            </div>

            <div className="PointsItem">
              <div className="PointsItemHeader">
                <div className="PointsItemImg">
                  <img src={clientIcon} alt="clientIcon" />
                </div>
                <h6 className="point-header">Increased Client Retention</h6>
              </div>
              <div className="PointsItemContent">
                <p>
                  {" "}
                  Increase client retention and spend with convenient monthly
                  payments. one treatment leads to the repeat treatment.
                </p>
              </div>
            </div>
          </div>

          <div className="MainStepsSecWrapper">
            <div className="stepsTitleWrapper">
              <h6>3 Simple steps</h6>
              <p onClick={() => setModalOpen(true)}>how it works</p>
            </div>
            <div className="MainStepsSecBox">
              <p>
                1 . Choose the Finance provider model to suit you and your
                business
              </p>
              <p>2 . The bank details you would like to be paid into</p>
              <p>3 . Accept the T&C from Payl8r Accept the T&C from Payl8r</p>
            </div>
          </div>

          <div className="active-container bg-white">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isAgree}
                name="allCondition"
                onChange={(e) => {
                  setIsAgree(e.target.checked);
                  setShowError(false);
                }}
                id="1"
              />
              <label className="form-label" htmlFor="1">
                I agree to share my information with buy now payl8r.
              </label>
            </div>
          </div>
          {showError && (
            <div className="alert alert-danger" role="alert">
              Please accept before proceeding.
            </div>
          )}

          <div className="BtnButton">
            <StepButton
              blue={true}
              label={"Next"}
              onClick={() => {
                if (isAgree) {
                  setBankModalOpen(true);
                } else {
                  setShowError(true);
                }
              }}
            />

            <span
              className="MaybeLink"
              onClick={() => {
                afterSubmit();
                onNextStep();
              }}
            >
              Maybe Later
            </span>
          </div>
        </div>
      </>
      <CustomModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        type="review"
        className={"CustomeHowItWorkModel"}
        modalBody={
          <div className="finance-about-modal-body">
            <Logo />
            <h3>Buy Now Pay Later up to 14 days no interest. Now available.</h3>
            <div className="sub-body-content">
              <p>
                You can now buy stock and pay later with this new buy now, Pay
                later feature.
              </p>
              <p>
                Once you complete the registration and unlock finance, the Buy
                Now, Pay later option will appear at the checkout within the
                pharmacy.
              </p>
              <p>
                {" "}
                Once your application has been approved (only takes a few
                minutes) your order will be processed as usual and you will only
                need to pay back within 14 days. Giving you time to treat your
                client first.
              </p>
              <p>
                Interest-free feature, you won’t be charged any interest for
                using Buy Now, Pay Later.
              </p>
              <p>
                You can have up to three applications live at any one point.
              </p>
              <Link>View FAQs</Link>
            </div>
            <p>Please complete registration to use this feature.</p>
            <div className="BtnButton">
              <StepButton
                blue={true}
                label={"Ok"}
                onClick={closeModal}
                // disabled={isContinue}
              />
            </div>
          </div>
        }
        modaltitle="How does it work?"
      />

      <CustomModal
        modalOpen={bankModalOpen}
        setModalOpen={setBankModalOpen}
        // type="review"
        modaltitle="Bank details"
        className={"CustomeBankModal"}
        modalBody={
          <div className="plan-modal">
            <p>Enter the bank details you would like to be paid into </p>
            <div className="plan-container">
              <div className="finance-input-container">
                <span className="form-label">Business Account Number</span>
                <input
                  class="form-control"
                  type="number"
                  value={accountNumber}
                  onChange={(e) => {
                    setAccountNumber(e.target.value);
                    setErrors((prevErrors) => {
                      return { ...prevErrors, accountNumber: "" };
                    });
                  }}
                />
                {errors.accountNumber && (
                  <p className="error">{errors.accountNumber}</p>
                )}
              </div>

              <div className="finance-input-container">
                <span className="form-label">Business Sort Code</span>
                <div className="SortInputWrapper">
                  {[0, 1, 2].map((_, index) => (
                    <input
                      class="form-control"
                      type="text"
                      style={{ border: "1px solid var(--Form, #878787)" }}
                      value={bSortCodeinp[index]}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      id={`inp${index}`}
                    ></input>
                  ))}
                </div>

                {errors.sortCode && <p className="error">{errors.sortCode}</p>}
              </div>
            </div>
            <div className="BtnButton">
              <StepButton
                blue={true}
                label={"Submit"}
                onClick={handleBankSubmit}
                isLoading={loading}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}
