import React from 'react'
// import noForms from '../../images/icons/appointment-list/noAppointmentList.svg'
import noForms from '../../../images/icons/appointment-list/noAppointmentList.svg'

import StepButton from '../../common/StepButton'
import { useNavigate } from 'react-router'
import { getNewAppointmentRoute } from '../../../Routes'
import "./no-forms.scss"
const NoForms = ({ onCreateAppointmentClickHandler }) => {
    return (
        <div className='no-form-div'>
            <div className="img-div">
                <img src={noForms} alt="NoForms" />
            </div>
            <h6>No forms</h6>
            <p>Brighten your client's day with a new form!</p>
            <div className='no-forms-create-appointment-btn'>
                <StepButton blue={true} label={"Create an appointment"}
                    onClick={() => {
                        onCreateAppointmentClickHandler();
                    }
                    }
                />
            </div>
        </div>
    )
}

export default NoForms
