import { CLEAR_EDIT_BLOCK_OUT_TIME_DATA, EDIT_BLOCK_OUT_TIME_DATA } from "../../constants/blockOutTime/BlockOutTimeConst";

const initialState={
    blockOutTimeDetails:[]
  }
  

//EDIT / GET BLOCK OUT TIME REDUCER
export default function editBlockTimeOutReducer(state = initialState, action) {
    switch (action.type) {
      case EDIT_BLOCK_OUT_TIME_DATA:
        console.log("blockOutTimeDetails",action.payload)
        return {
          ...state,
          blockOutTimeDetails: action.payload,
        };
  
      case CLEAR_EDIT_BLOCK_OUT_TIME_DATA:
        return {
          ...state,
          blockOutTimeDetails: [],
        };
  
      default:
        return state;
    }
  }