import React, { useEffect, useState } from "react";
import "./one-time-change.scss";
import CustomModal from "../modal/CustomModal";
import OffCanvasModal from "../modal/OffCanvasModal/OffCanvasModal";
import OneTimeChangeCalender from "../oneTimeChangeCalender/oneTimeChangeCalender";
import NavHeader from "./NavHeader/NavHeader";
import LocationImage from "../../../src/images/icons/Image.svg"
import HoursAndBreakAdded from "../oneTimeChangeCalender/hoursAndBreakAdded";
import moment from 'moment'
import { GetClinicLocationsAction } from "../redux/actions/main-calendar/MainCalendarAction";
import { useDispatch, useSelector } from "react-redux";
import { OneTimeChangeLocation } from "../onetimechange/OneTimeChangeLocation";
import { GetOneTimeOffAction } from "../redux/actions/onetimeoff/OneOffTime";
import { CLEAR_CLINIC_LOCATIONS } from "../redux/constants";


const OneTimeChange = ({ isOpen, setIsOpen, setFilterCanvas }) => {
  const dispatch = useDispatch()

  const [isLocationModelOpen, setIsLocationModelOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [calenderDisplayDate, setCalenderDisplayDate] = useState();


  //custom calender state
  const [showSelectedDate, setShowSelectedDate] = useState(moment().format("dddd, Do MMMM YYYY"));
  const [calendarStartDate, setCalendarStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [selectedMonth, setSelectedMonth] = useState(
    selectedDate.getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
  const [dateData, setDateData] = useState([])
  const [isBlockOut,setIsBlockOut]=useState(false)

  // break and hours state.

  const [hoursData, setHoursData] = useState({
    isOpenForBusiness: true,
    opens: "09:00",
    closes: "18:00",
    isBlock:false,
  })

  const [breaks, setBreaks] = useState([{ start: hoursData?.opens, end: moment(hoursData?.opens, 'HH:mm').add(30, 'minutes').format('HH:mm'), breakError: "" }]);
  const [previousSelectedDate, setPreviousSelectedDate] = useState("");
  const [selectedLocationData, setSelectedLocationData] = useState([])

  const { clinicLocationList } = useSelector(state => state?.GetClinicLocationReducer)


  const { getClinicLocationsQueryData, initGetClinicLocationsData } = GetClinicLocationsAction()
  const { initGetOneTimeOffData, getOneTimeOffQueryData } = GetOneTimeOffAction();




  useEffect(() => {
    dispatch({ type: CLEAR_CLINIC_LOCATIONS });
    initGetClinicLocationsData({
      page: 1,
      limit: 10,
    })
  }, [])


  useEffect(() => {
    if (clinicLocationList && clinicLocationList?.length > 0 && !isLocationModelOpen) {
      setSelectedLocationData(clinicLocationList[0])
    }
  }, [clinicLocationList])


  useEffect(() => {
    if (selectedLocationData) {
      initGetOneTimeOffData({
        clinicId: selectedLocationData?.clinicId,
        date: calendarStartDate
      }, (res) => {
        if (res.success) {
          setHoursData({
            isOpenForBusiness: res?.data?.isOpen,
            opens: moment(res?.data?.startTime, 'HH:mm:ss').format('HH:mm'),
            closes: moment(res?.data?.endTime, 'HH:mm:ss').format('HH:mm'),
            isBlock:res?.data?.isBlockOut
          })
          if (res?.data?.breaks?.length > 0) {
            let breakArray = res?.data?.breaks?.map((item) => {
              return { start: moment(item.breakStartTime, 'HH:mm:ss').format('HH:mm'), end: moment(item?.breakEndTime, 'HH:mm:ss').format('HH:mm'), breakId: item.breakId, breakError: "" }
            })
            setBreaks(breakArray)
          }
          else {
            setBreaks([]);
          }
        }

      }, (err) => {

      })
    }

  }, [selectedLocationData, calendarStartDate])

  // useEffect(() => {

  //   if (previousSelectedDate != calendarStartDate) {
  //     setHoursData({
  //       isOpenForBusiness: true,
  //       opens: "09:00",
  //       closes: "18:00"
  //     })
  //     setBreaks([]);
  //   }

  // }, [calendarStartDate, previousSelectedDate])



  const handleReset = () => {
    setCalenderDisplayDate()
    setShowSelectedDate(null);
    setCalendarStartDate(moment().format("YYYY-MM-DD"))
    setStartDate(moment().format("YYYY-MM-DD"))
    setEndDate(null)
    setSelectedDate(new Date());
    setSelectedMonth(
      selectedDate.getMonth() + 1
    );
    setSelectedYear(selectedDate.getFullYear());
    setDateData([])
  }
  const closeModalHandler = () => {
    setSelectedLocationData(selectedLocationData)
  }
  return (
    <>
      <OffCanvasModal
        className='CustomeOnetime'
        isOpen={isOpen}
        onHide={() => setIsOpen(false)}
        body={
          <>
            {step == 1 && <><div className="one-time-header">
              <NavHeader handleOnBack={() => { setIsOpen(false); setFilterCanvas(true); handleReset(); }}
                applyPadding={false} isBackWithButton={true} isGray={false} btnLabel={`${selectedLocationData?.clinicName}`} isGrayColorButton={true}
                onClickCallback={() => setIsLocationModelOpen(true)}
              />
            </div>

              <OneTimeChangeCalender setStep={setStep} setCalenderDisplayDate={setCalenderDisplayDate}

                setShowSelectedDate={setShowSelectedDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                setSelectedMonth={setSelectedMonth}
                setSelectedYear={setSelectedYear}
                showSelectedDate={showSelectedDate}
                calendarStartDate={calendarStartDate}
                setCalendarStartDate={setCalendarStartDate}
                dateData={
                  dateData
                }
                setDateData={setDateData}
                startDate={startDate}
                endDate={endDate}
                previousSelectedDate={previousSelectedDate}
                setPreviousSelectedDate={setPreviousSelectedDate}
                selectedLocationData={selectedLocationData}
                workingHoursAvability={{
                  opens: hoursData?.opens,
                  closes: hoursData?.closes,
                  isOpen: hoursData?.isOpenForBusiness,
                  isBlock:hoursData?.isBlock
                }}

              /></>}

            {step == 2 && <><div className="one-time-header">
              <NavHeader handleOnBack={() => setStep(1)} applyPadding={false} isBackOnly={true} isGray={false} isGrayColorButton={false}
              />
            </div>

              <HoursAndBreakAdded calenderDisplayDate={calenderDisplayDate}
                hoursData={hoursData}
                setHoursData={setHoursData}
                breaks={breaks}
                setBreaks={setBreaks}
                clinicId={selectedLocationData?.clinicId}
                setStep={setStep}
                setIsOpen={setIsOpen}
              /></>}
          </>
        }

      />


      {/* choose location model */}
      <CustomModal
        type={"common-width"}
        className={'CustomLocationModel MainLocationCalenderModel'}
        modaltitle={"Locations"}
        modalOpen={isLocationModelOpen}
        setModalOpen={setIsLocationModelOpen}
        modalCloseCallback={closeModalHandler}
        modalBody={
          <>
            {

              <>
                <OneTimeChangeLocation selectedLocationSave={selectedLocationData} setSelectedLocationSave={setSelectedLocationData} setIsLocationModelOpen={setIsLocationModelOpen} />
              </>

            }

          </>
        }
      />
    </>
  );
};

export default OneTimeChange;
