import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";

const GET_NOTIFICATION_ACCESS_LIST_QUERY = gql`
query GetListOfNotificationAccess {
  getListOfNotificationAccess {
    success
    message
    settings {
      email {
        value
        name
      }
      prescriptionEmail {
        value
        name
      }
      socialMedia {
        value
        name
      }
    }
  }
}`
    ;

export const useGetNotificationAccessList = () => {
    const [
        executeGetNotificationAccessList,
        { data: notificationAccessListData },
    ] = useLazyQuery(GET_NOTIFICATION_ACCESS_LIST_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted: ({ getListOfNotificationAccess: responseData }) => {
            if (responseData.success) {
                // console.log("Response Data:", responseData);
            } else {
                // console.log("Error in fetching notification access list");
            }
        },
    });

    const initializeGetNotificationAccessList = (data, successCallback, errorCallback) => {
        executeGetNotificationAccessList({
            variables: data,
        }).then((res) => {
            if (res?.data?.getListOfNotificationAccess?.success) {
                successCallback(res);
            } else {
                console.log('Error response:', res);
                errorCallback(res);
            }
        }).catch((err) => {
            console.log('Error:', err);
            errorCallback(err);
        });
    };

    return { notificationAccessListData, initializeGetNotificationAccessList };
};