import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
// import { ReactComponent as BackArrow } from '../../../images/back_arrow.svg';
// import { ReactComponent as Uploads } from '../../../images/Image.svg';
import { ReactComponent as Uploads } from '../../../../images/finance/docDropzoneIcon.svg';
import image from '../../../../images/finance/analysis-1.svg';
import whatsappIcon from '../../../../images/finance/whatsappwhite.svg';
import './finance-training-certificate.scss';
import { useDropzone } from 'react-dropzone';
import { config } from '../../../../config';
// import { FinanceInsuranceRoute, FinanceIdentityRoute } from '../../../Routes';
import ConfirmationModal from '../FinanceInsuranceSection/finance-confirmationModal';
// import FinanceHeader from '../../finance-registration/FinanceHomeComponent/FinanceHeaderComponent/FinanceHeaderComponent';
import FinanceBanner from '../FinanceInsuranceSection/FinanceBanner';
import FinanceBannerForTraining from "../../../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner"
import StepButton from '../FinanceInsuranceSection/StepButton';
// import { IoEyeSharp } from 'react-icons/io5';
import CustomModal from '../../../../components/modal/CustomModal';
import Dropzone from '../FinanceInsuranceSection/DropZone';
// import PropTypes from 'prop-types';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en';
import { SaveFinaceTraningCertificateAction } from './saveFinanceTranningCertficate';
import { GetFinanceTraningCertificateAction } from './getFinanceTraningCertificate';
import { DeleteFinanceTranningCertificateAction } from './deleteFinanceTraningCertificate';
import { toast } from 'react-toastify';

export default function FinanceTrainingCertificate({onNextStep, afterSubmit, type='training', parentModal}) {
  const WhatsappLinkRedirect = config.WHATSAPP_LINK;
  const navigate = useNavigate();

  const [otherDocs, setOtherDocs] = useState([
    {
      image: '',
      certificate_title: '',
      certificate_url: '',
      // id: null
    },
    // {
    //   image: '',
    //   certificate_url: '',
    //   certificate_title: '',
    //   // id: null
    // },
    // {
    //   image: '',
    //   certificate_url: '',
    //   certificate_title: '',
    //   // id: null
    // },
  ]);
  // const [textHereError, setTextHereError] = useState(['', '', '']);
  const {
    saveFInanceTraningCertificateQueryData,
    initSaveFinanceTraningCertificateData,
  } = SaveFinaceTraningCertificateAction();
  const {
    getFinanceTranningCertificateQueryData,
    initGetFinanceTranningCertificateData,
  } = GetFinanceTraningCertificateAction();
  const {
    deleteFinanceTranningCertificateQueryData,
    initDeleteFinanceTranningCertificateData,
  } = DeleteFinanceTranningCertificateAction();
  const [open, setOpen] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState('');
  const [getApiData, setGetApiData] = useState([]);
  const [previewImg, setPreviewImg] = useState(false);
  const [isDocError, setIsDocError] = useState(false);
  const [isData, setIsData] = useState(false);
  // const { data } = getFinanceTranningCertificateQueryData;
  const [data, setData] = useState({});
  console.log(
    'data1',
    data?.getTrainingCertificatesForFinance?.data?.certificates
  );

  useEffect(() => {
    initGetFinanceTranningCertificateData();
  }, []);

  useEffect(() => {
    console.log('here from api call of ins', getFinanceTranningCertificateQueryData?.data);
    if (getFinanceTranningCertificateQueryData.called) {
      setData(getFinanceTranningCertificateQueryData?.data);
    }
  }, [getFinanceTranningCertificateQueryData]);

  useEffect(() => {
    const value = data?.getTrainingCertificatesForFinance?.data;
    console.log('valueDoc', value?.certificates);
    if (data?.getTrainingCertificatesForFinance?.data) {
      if (
        value?.certificates?.length > 0 &&
        Array.isArray(value?.certificates)
      ) {
        let arr = [];
        if (value?.certificates?.length < 1) {
          let val = 1 - value?.certificates?.length;
          arr = [...Array(val)].map((item) => ({
            image: '',
            certificate_url: '',
            certificate_title: '',
          }));
        }
        let finalArr = value?.certificates?.concat(arr);
        setOtherDocs(finalArr);
      } else {
        setOtherDocs([{
          image: '',
          certificate_url: '',
          certificate_title: '',
        }]);
      }
    }
  }, [data?.getTrainingCertificatesForFinance?.data?.certificates]);

  console.log('otherDocddddd', otherDocs);

  const titleErrorMessage = 'Please Add Title for All Certificate!'
  const oneDocumentErrMessage = 'Please upload at least one Certificate!';
  const handleContinue = () => {
    let certificate;
    {
      certificate = otherDocs
        .filter((file) => {
          if (file.image || file.id) {
            return true;
          }
        })
        .map((file) => {
          if (type == 'training') {
            return {
              photoId: file.id ? file.id : 0,
              photoTitle: file.certificate_title,
              photo: file.image,
            };
          } else {
            return file.image;
          }
        });
    }

    // if (otherDocs?.[0]?.image === '' && otherDocs?.[0]?.certificate_url === '') {
    //   setErrorMsg('Please Add Tranning Certificate');
    // } else {
      setErrorMsg('');
      let canProcessFurther = true;
      let isCertificate = false;
      otherDocs.forEach((element, index) => {
        console.log('here kaka', element, index);
        if (element.image) {
          if (element.certificate_title === '') {
            // canProcessFurther = false;
            // setTextHereError(prevState => {
            //   const newState = [...prevState];
            //   newState[index] = 'Please add title';
            //   return newState;
            // });
          }
          isCertificate = true;
        } else if (element.certificate_url) {
          if (element.certificate_title === '') {
            // canProcessFurther = false;
            // setTextHereError(prevState => {
            //   const newState = [...prevState];
            //   newState[index] = 'Please add title';
            //   return newState;
            // });
            // toast.error(titleErrorMessage);
          }
          isCertificate = true;
        }
        // if (!(element.image === '' && element.certificate_url === '')) {
        //   if (element.certificate_title === '') {
        //     canProcessFurther = false;
        //     setTextHereError(prevState => {
        //       const newState = [...prevState];
        //       newState[index] = 'Please add title!!';
        //       return newState;
        //     });
        //   }
        // }
      });
      if (!isCertificate) {
        toast.error(oneDocumentErrMessage);
        return false;
      }
      if (canProcessFurther) {
      initSaveFinanceTraningCertificateData(
        {
          certificateFiles: certificate,
        },
        (data) => {
          toast.success(data.data.saveTrainingCertificatesForFinance.message);
          parentModal(false);
          afterSubmit();
          // onNextStep();
        },
        (error) => {
          toast.error('Something went wrong!');
          console.log('saveTrainingCertificateFiles', error);
        }
      );
      } else {
        toast.error(titleErrorMessage);
      }
    // }
  };

  console.log('oDoc', otherDocs);
  const handleTitleChange = (e, index) => {
    const { name, value } = e.target;
    setOtherDocs((prevOtherDocs) => {
      const updatedDocs = [...prevOtherDocs];
      updatedDocs[index] = { ...updatedDocs[index], certificate_title: value };
      console.log('updatedDocs', updatedDocs);
      return updatedDocs;
    });
    if (value === '' && (otherDocs?.[index]?.image !== '' || otherDocs?.[index]?.certificate_url !== '')) {
      // setTextHereError(prevState => {
      //   const newState = [...prevState];
      //   newState[index] = 'Please add title!';
      //   return newState;
      // });
      // toast.error(titleErrorMessage);
    } else {
      // setTextHereError(prevState => {
      //   const newState = [...prevState];
      //   newState[index] = '';
      //   return newState;
      // });
    }
  };

  const handleAddDropzone = () => {
    if (otherDocs?.length < 10) {
      const nextDocIndex = otherDocs?.length + 1;

      const newDocumentState = {
        image: '',
        certificate_url: '',
        certificate_title: '',
      };
      setOtherDocs((prevOtherDocs) => [...prevOtherDocs, newDocumentState]);
      // setTextHereError((prev) => [...prev, '']);
      setIsData(true);
    }
  };

  console.log('getapi', getApiData);
  console.log('data');
  console.log('otherDocs', otherDocs);

  useEffect(() => {
    if (otherDocs?.length < 1) {
      const emptyObjectsToAdd = 1 - otherDocs?.length;
      const emptyObjectsArray = Array.from(
        { length: emptyObjectsToAdd },
        () => ({})
      );
      setOtherDocs((prevOtherDocs) => [...prevOtherDocs, ...emptyObjectsArray]);
    }
  }, [otherDocs]);

  return (
    <div className="main_training_certificate">
      <div className="mainCertificatePagesecWrapper">
        <FinanceBanner
          title="Training details"
          desc="We now need to know about where you were trained and what you are trained in."
          isBorder={false}
        />
        <FinanceBannerForTraining isTranningBanner={true}/>

        <div>
          <div className="Certificate-docmentation-Wrapper">
            <div className="form-label">Training certificate</div>
            <div className="docmentationGrid-Wrapper">
              {otherDocs.map((doc, index) => (
                <>
                  <div className="docmentationGridItem" key={index}>
                    <Dropzone
                      handleMulti={setOtherDocs}
                      selectedIndex={index}
                      urlFromDB={doc.certificate_url}
                      handleDelete={initDeleteFinanceTranningCertificateData}
                      usrVrfId={doc.id}
                      otherDocs={otherDocs}
                      imageFromComponent={doc.image}
                      docIndex={index}
                      afterSubmitAction={afterSubmit}
                      // removeTextHereError={setTextHereError}
                    />
                    <input
                      name={`doc_name${index + 1}`}
                      value={doc.certificate_title}
                      onChange={(e) => handleTitleChange(e, index)}
                      placeholder="Certificate title"
                      className="inputTag"
                      //  onClick={()=>DeleteTitle(doc.id)}
                    />
                    {/* {textHereError[index] && <span className="error">{textHereError[index]}</span>} */}
                  </div>
                </>
              ))}
              { otherDocs?.length < 10 &&
              <div className="docmentationGridItem">
                {/* {
                    isData ? ( */}
                <div className="dropzone" onClick={handleAddDropzone}>
                  <Uploads />
                  <p>Add Photo </p>
                </div>
                {/* <input name="Add" placeholder="Text Here" /> */}
              </div>}
            </div>
          </div>
        </div>
        {ErrorMsg && <span className="error">{ErrorMsg}</span>}
        <div className="third-flex-div cus-third-flex-padding">
          <StepButton
            label={'Continue'}
            blue={true}
            isLoading={saveFInanceTraningCertificateQueryData?.loading}
            onClick={handleContinue}
          />
        </div>

        {/* <div className="Certificate-UploaderWrapper">
              <p className="form-label">Training Certificate</p>
              <div className="customeDropDownPreviewWrapper">
                <div className="customeDropDownWrapper" {...getRootProps()} >
                  <input {...getInputProps()} />
                  {uploadedImage && (
                    <>
                      <img
                        src={uploadedImage}
                        alt="Uploaded"
                      />
                    </>
                  )}
                  {!uploadedImage && (
                    <div className="UploadIcon">
                      <Uploads />
                      <p>Upload</p>
                    </div>
                  )}
                </div>
                {uploadedImage && 
                    <div className="PreviewImg">
                          <IoEyeSharp onClick={()=> {setPreviewImg(true)}} />
                    </div>
                  }
              </div>
              {errorMessage && (
                <p className="error">{errorMessage}</p>
              )}


              <div className="third-flex-div">
                  <StepButton label={"Continue"} blue={true} onClick={handleContinue}  />
              </div>
            </div> */}
        {/* <div className="TrainingCardWrapper">
          <div className="cardIcon">
            <img src={image} className="me-1" />
            Need more Training ?
          </div>
          <div className="CardContent">
            <p>
              Speak to our partnering training academy to upskill. Get 50% off
              with code <b>‘Faces50’</b>
            </p>
            <button
              className="w-100 btn border-0 text-white shadow-none"
              style={{
                borderRadius: '100px',
                background:
                  'linear-gradient(180deg, #70FD78 -34.31%, #3FB42E 100%)',
              }}
              onClick={() => {
                window.open(WhatsappLinkRedirect, '_blank');
              }}
            >
              <img src={whatsappIcon} width={20} className="me-1" />
              Whatsapp us
            </button>
          </div>
        </div> */}
      </div>

      {/* Modle Previw*/}
      <CustomModal
        modalOpen={previewImg}
        setModalOpen={setPreviewImg}
        type="review"
        className={'previewCertificateImgModel'}
        modalBody={
          <>
            <div className="previewImgModelWrapper">
              {/* <img
                src={uploadedImage}
                alt="Uploaded"/> */}
            </div>

            <div className="RemovePreviewImg">
              <button
                className="OutLineBtn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Remove
              </button>
            </div>
          </>
        }
        modaltitle="How it works?"
      />

      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        title="Are you sure?"
        className={'ConfirmationCertificateDeleteModal'}
        content="Here is some content to complement the title"
        onConfirm={() => {
          // removeImage();
          // setOpen(false);
          // setPreviewImg(false);
        }}
        onCancel={() => setOpen(false)}
      />
    </div>
  );
}

// ***********************************************

function FinanceTrainingCertificate2({ onNextStep, afterSubmit }) {
  const WhatsappLinkRedirect = config.WHATSAPP_LINK;
  const navigate = useNavigate();

  const [otherDocs, setOtherDocs] = useState([
    {
      image: '',
      image_url: '',
      title: '',
    },
  ]);
  const {
    saveFInanceTraningCertificateQueryData,
    initSaveFinanceTraningCertificateData,
  } = SaveFinaceTraningCertificateAction();
  const {
    getFinanceTranningCertificateQueryData,
    initGetFinanceTranningCertificateData,
  } = GetFinanceTraningCertificateAction();
  const {
    deleteFinanceTranningCertificateQueryData,
    initDeleteFinanceTranningCertificateData,
  } = DeleteFinanceTranningCertificateAction();
  const [open, setOpen] = useState(false);
  const [getApiData, setGetApiData] = useState([]);
  const [previewImg, setPreviewImg] = useState(false);
  const [isDocError, setIsDocError] = useState(false);
  const [isData, setIsData] = useState(false);
  const { data } = getFinanceTranningCertificateQueryData;
  console.log(
    'data1',
    data?.getTrainingCertificatesForFinance?.data?.certificates
  );

  useEffect(() => {
    initGetFinanceTranningCertificateData();
  }, []);

  useEffect(() => {
    const value = data?.getTrainingCertificatesForFinance?.data?.certificates;
    setGetApiData(value);
  }, [data?.getTrainingCertificatesForFinance?.data?.certificates]);

  const handleContinue = () => {
    let certificate;
    {
      certificate = otherDocs.map((file) => {
        return file?.image;
      });
    }
    // certificate.filter((file,index)=>{

    // })
    // console.log("certificate",certificate)
    //  if(certificate.length !== 2 ){
    //   setIsDocError(true)

    //  }
    // else{
    initSaveFinanceTraningCertificateData(
      {
        // certificates: certificate,
        certificateFiles: certificate,
      },
      (data) => {
        console.log(data);
      },
      (error) => {
        console.log(error);
      }
    );
    // afterSubmit();
    // onNextStep();
    // };
  };

  const handleTitleChange = (e, index) => {
    const { name, value } = e.target;
    setOtherDocs((prevOtherDocs) => {
      const updatedDocs = [...prevOtherDocs];
      updatedDocs[index] = { ...updatedDocs[index], title: value };
      return updatedDocs;
    });
  };

  const handleAddDropzone = () => {
    if (otherDocs.length < 10) {
      const nextDocIndex = otherDocs.length + 1;
      const newDocumentState = {
        image: '',
        image_url: '',
        title: '',
      };
      setOtherDocs((prevOtherDocs) => [...prevOtherDocs, newDocumentState]);
      setIsData(true);
    }
  };

  // const handleApiAddDropZone=()=>{
  //   if(getApiData.length < 10){
  //     const nextDocIndex = getApiData.length + 1;
  //     const newDocumentApiState = {
  //       image: "",
  //       image_url: "",
  //       title: "",
  //     };
  //     setGetApiData((prevApiDocs)=>[...prevApiDocs,newDocumentApiState])
  //     setIsData(false)
  //   }
  // }

  console.log('getapi', getApiData);
  console.log('data');
  console.log('otherDocs', otherDocs);

  return (
    <div className="main_training_certificate">
      <div className="mainCertificatePagesecWrapper">
        <FinanceBanner
          title="Trainer details"
          desc="Evidence of your certifications and training, can authenticate your account."
          isBorder={false}
        />

        <div>
          <div className="docmentation">
            <div className="context">Training Certificate</div>
            <div className="container">
              <div className="row">
                {isData
                  ? otherDocs.map((doc, index) => (
                      <>
                        <div className="col-6" key={index}>
                          <Dropzone
                            handleMulti={setOtherDocs}
                            selectedIndex={index}
                            urlFromDB={doc.image_url}
                            handleDelete={
                              initDeleteFinanceTranningCertificateData
                            }
                            usrVrfId={doc.id}
                          />
                          <input
                            name={`doc_name${index + 1}`}
                            value={doc.title}
                            onChange={(e) => handleTitleChange(e, index)}
                            placeholder="Text Here"
                          />
                        </div>
                      </>
                    ))
                  : getApiData &&
                    getApiData.map((doc, index) => (
                      <div className="col-6" key={index}>
                        <Dropzone
                          handleMulti={setGetApiData}
                          selectedIndex={doc.index}
                          urlFromDB={doc.certificate_url}
                          handleDelete={
                            initDeleteFinanceTranningCertificateData
                          }
                          usrVrfId={doc.id}
                        />
                        <input
                          name={`doc_name${index + 1}`}
                          value={doc.title}
                          onChange={(e) => handleTitleChange(e, index)}
                          placeholder="Text Here"
                        />
                      </div>
                    ))}

                <div className="col-6">
                  {/* {
                    isData ? ( */}
                  <div
                    className="d-flex flex-column align-items-center justify-content-center add-container"
                    onClick={handleAddDropzone}
                  >
                    <Uploads />
                    <p>Add Photo </p>
                  </div>
                  {/* ):(
                      <div
                      className="d-flex flex-column align-items-center justify-content-center add-container"
                      onClick={handleApiAddDropZone}
                    >
                      <Uploads />
                      <p>Add Photo </p>
                    </div>
                    )
                  } */}
                  <input name="Add" placeholder="Text Here" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isDocError ? (
          <div>
            <p>{'Add Minimum 2 certificate'}</p>
          </div>
        ) : (
          ''
        )}
        <div className="third-flex-div">
          <StepButton label={'Continue'} blue={true} onClick={handleContinue} />
        </div>

        {/* <div className="Certificate-UploaderWrapper">
              <p className="form-label">Training Certificate</p>
              <div className="customeDropDownPreviewWrapper">
                <div className="customeDropDownWrapper" {...getRootProps()} >
                  <input {...getInputProps()} />
                  {uploadedImage && (
                    <>
                      <img
                        src={uploadedImage}
                        alt="Uploaded"
                      />
                    </>
                  )}
                  {!uploadedImage && (
                    <div className="UploadIcon">
                      <Uploads />
                      <p>Upload</p>
                    </div>
                  )}
                </div>
                {uploadedImage && 
                    <div className="PreviewImg">
                          <IoEyeSharp onClick={()=> {setPreviewImg(true)}} />
                    </div>
                  }
              </div>
              {errorMessage && (
                <p className="error">{errorMessage}</p>
              )}


              <div className="third-flex-div">
                  <StepButton label={"Continue"} blue={true} onClick={handleContinue}  />
              </div>
            </div> */}

        <div className="TrainingCardWrapper">
          <div className="cardIcon">
            <img src={image} className="me-1" />
            Need more Training ?
          </div>
          <div className="CardContent">
            <p>
              Speak to our partnering training academy to upskill. Get 50% off
              with code <b>‘Faces50’</b>
            </p>
            <button
              className="w-100 btn border-0 text-white shadow-none"
              style={{
                borderRadius: '100px',
                background:
                  'linear-gradient(180deg, #70FD78 -34.31%, #3FB42E 100%)',
              }}
              onClick={() => {
                window.open(WhatsappLinkRedirect, '_blank');
              }}
            >
              <img src={whatsappIcon} width={20} className="me-1" />
              Whatsapp us
            </button>
          </div>
          {/* <div className="p-4">
                <h3>

                </h3>

                <button
                  className="w-100 btn border-0 text-white shadow-none"
                  style={{
                    borderRadius: "100px",
                    background:
                      "linear-gradient(180deg, #70FD78 -34.31%, #3FB42E 100%)",
                  }}
                  onClick={() => {
                    window.open(WhatsappLinkRedirect, "_blank");
                  }}
                >
                  <img src={whatsappIcon} width={20} className="me-1"/>
                  Whatsapp us
                </button>
            </div> */}

          {/* <div className="container">
          <div className="row flex-column align-items-center justify-content-center">
            <div className="col-lg-7 mb-5">
              <h1>Training Certificate</h1>
              <p>
                Evidence of your certifications and training, can authenticate
                your account.
              </p>
            </div>
            <div className="col-lg-7">
              <p className="doc_label">Training Certificate</p>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  borderRadius: "20px",
                  border: "1px dashed #878787",
                  height: "160px",
                  color: "#878787",
                  position: "relative",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {uploadedImage && (
                  <>
                    <img
                      src={uploadedImage}
                      alt="Uploaded"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                        borderRadius: "20px",
                        padding: "20px",
                      }}
                    />
                  </>
                )}
                {!uploadedImage && (
                  <div className="d-flex flex-column align-items-center">
                    <Uploads />
                    <p>Upload</p>
                  </div>
                )}
              </div>
              {uploadedImage && (
                <p
                  className="mb-0 mt-2"
                  onClick={()=>setOpen(true)}
                  style={{ cursor: "pointer" }}
                >
                  Remove
                </p>
              )}
              {errorMessage && (
                <p className="text-danger mb-0 mt-2">{errorMessage}</p>
              )}
            </div>
            <div className="col-lg-7 mt-5">
              <button
                className="w-100 btn btn-primary shadow-none continuebtn"
                style={{ borderRadius: "100px", backgroundColor: "#1B4FE7" }}
                onClick={handleContinue} 
              >
                Continue
              </button>
            </div>
            <div className="col-lg-7 mb-5">
            <div
              className="p-4"
              style={{
                boxShadow: "0px 0px 0px 1px #DDD inset",
                borderRadius: "20px",
              }}
            >
              <h3>
                <img src={image} className="me-1"/>Need more Training ?
              </h3>
              <p style={{ width: "90%" }}>
                Speak to our partnering training academy to upskill. Get 50% off
                with code <span style={{ fontWeight: "700" }}>‘Faces50’</span> .
              </p>
              <button
                className="w-100 btn border-0 text-white shadow-none"
                style={{
                  borderRadius: "100px",
                  background:
                    "linear-gradient(180deg, #70FD78 -34.31%, #3FB42E 100%)",
                }}
                onClick={() => {
                  window.open(WhatsappLinkRedirect, "_blank");
                }}
              >
                <img src={whatsappIcon} width={20} className="me-1"/>
                Whatsapp us
              </button>
            </div>
            </div>

          </div>
        </div> */}
        </div>
      </div>

      {/* Modle Previw*/}
      <CustomModal
        modalOpen={previewImg}
        setModalOpen={setPreviewImg}
        type="review"
        className={'previewCertificateImgModel'}
        modalBody={
          <>
            <div className="previewImgModelWrapper">
              {/* <img
                src={uploadedImage}
                alt="Uploaded"/> */}
            </div>

            <div className="RemovePreviewImg">
              <button
                className="OutLineBtn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Remove
              </button>
            </div>
          </>
        }
        modaltitle="How it works?"
      />

      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        title="Are you sure?"
        className={'ConfirmationCertificateDeleteModal'}
        content="Here is some content to complement the title"
        onConfirm={() => {
          // removeImage();
          // setOpen(false);
          // setPreviewImg(false);
        }}
        onCancel={() => setOpen(false)}
      />
    </div>
  );
}
