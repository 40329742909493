import React, { useEffect, useState } from 'react'

import backArrow from "../../../../../images/icons/BackArrow.svg"
import "./block-out-time-view.scss"
import { useLocation, useNavigate, useParams } from 'react-router';
import { businessDashboardRoutePattern, getAppointmentListRoutePattern, getCalendarRoute, getClientProfileRoute, getEditAppointmentRoute, getEditBlockOutTimeRoute } from "../../../../../Routes";
import { useDispatch, useSelector } from 'react-redux';
import { EditBlockOutTimesQuery } from '../../../../redux/actions/block-out-time/EditBlockOutTime'
import SkeletonLoader from '../../../../common/Skeleton/SkeletonLoader';
import { clearAppointmentStatus } from '../../../../redux/actions';
import CustomModal from '../../../../modal/CustomModal';
import { DeleteBlockOutTimeMutation } from '../../../../redux/actions/block-out-time/DeleteBlockOutTime';
import StepButton from '../../../../common/StepButton';
import { CLEAR_BLOCK_OUT_TIME_DATA } from '../../../../redux/constants/blockOutTime/BlockOutTimeConst';
import showToast from '../../../../common/CustomToast/CustomToast';



const BlockOutTimeView = ({ fromCalendar = false, setIsAppointmentDetailsOpen, blockOutIdSlug,
    setBlockOutIdSlug,
    blockOutDateIdSlug,
    setBlockOutDateIdSlug,
    appointmentApiCall }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showResendAlert, setShowResendAlert] = useState(false);

    const moment = require('moment');
    const appointmentDetails = useSelector((state) => state.appointmentReducer.appointmentDetails);
    const location = useLocation().state
    //SELECTOR
    const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)


    const { initEditBlockTimeOut, editBlockOutTimeQueryData } = EditBlockOutTimesQuery()
    const { blockOutTimeDetails } = useSelector(state => state.editBlockTimeOutReducer)

    //GET STRIPE ACCOUNT AND FINANCE REQUEST STATUS
    const { blockOutId, blockOutDateId } = useParams()
    const [isDelete, setIsDelete] = useState(false)




    const { initiateDeleteBlockOutTime, deleteBlockTimeMutationData } = DeleteBlockOutTimeMutation()


    useEffect(() => {
        if ((blockOutId && blockOutDateId) || (blockOutIdSlug && blockOutDateIdSlug)) {
            //dispatch(getAppointmentDetails({ "booking-slug": (slug || slugFromCalender) }))

            initEditBlockTimeOut({
                blockOutId: parseInt((blockOutId || blockOutIdSlug)),
                blockOutDateId: parseInt((blockOutDateId || blockOutDateIdSlug))
            })
        }
    }, [blockOutTimeDetails])

    console.log("asha", blockOutTimeDetails)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    useEffect(() => {

        document.body?.classList.remove('ReactModal__Body--open');

        return () => {
            document.body?.classList.remove('ReactModal__Body--open');
        };
    }, [showResendAlert]);


    const handleDeleteBlockOut = () => {
        initiateDeleteBlockOutTime({
            blockOutDateId: parseInt(blockOutDateId) || blockOutTimeDetails?.blockOutDateId,
            blockOutId: parseInt(blockOutId) || blockOutTimeDetails?.blockOutId
        }, (res) => {
            if (res?.success) {
                setIsDelete(false)
                dispatch({
                    type: CLEAR_BLOCK_OUT_TIME_DATA
                })
                showToast("success", res?.message, "", false)
                if (location?.fromBlockoutCalendarList)
                    navigate(getCalendarRoute(), { state: { fromBlockoutCalendarList: location?.fromBlockoutCalendarList, calenderViewState: location?.calenderViewState, calenderShowSelectedDate: location?.calenderShowSelectedDate } })
                else if (location?.listClickDate) {
                    navigate(getCalendarRoute(), { state: { cancelAppointment: "cancelAppointment", listClickDate: location.listClickDate } })
                }
                else {
                    setIsAppointmentDetailsOpen(false)
                    appointmentApiCall()
                }

            }
        }, (err) => {
            setIsDelete(false)
            console.log(err)
        })
    }

    const formatDateRange = (startDate, endDate) => {
        const start = moment(startDate);
        const end = moment(endDate);

        const startDay = start.format('D');
        const startMonth = start.format('MMMM');
        const endDay = end.format('D');
        const endMonth = end.format('MMMM');

        if (start.month() === end.month()) {
            // If both dates are in the same month
            return `${startDay}-${endDay} ${startMonth}`;
        } else {
            // If dates span across different months
            return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
        }
    };
    // console.log("date", moment(blockOutTimeDetails?.endDate, "YYYY MM DD"))?.isBefore(moment())
    console.log("111", moment().isAfter(moment(blockOutTimeDetails?.endDate, "YYYY-MM-DD")), blockOutTimeDetails?.endDate)
    return (
        <>
            {
                blockOutTimeDetails?.blockOutId ?
                    <div className=''>
                        <div className={`close-back d-flex justify-content-between ${fromCalendar ? "fromCalendar-close-back" : ""}`}>
                            <img src={backArrow} style={{ cursor: 'pointer' }} alt="" onClick={() => {
                                dispatch(clearAppointmentStatus());
                                if (fromCalendar) {
                                    setBlockOutDateIdSlug(null)
                                    setBlockOutIdSlug(null)
                                    setIsAppointmentDetailsOpen(false)
                                }
                                else {
                                    location === "list" ? navigate(getAppointmentListRoutePattern) : location?.fromBlockoutList ? navigate(getCalendarRoute(), { state: { cancelAppointment: "cancelAppointment", listClickDate: location.listClickDate } }) : location?.fromBlockoutCalendarList ? navigate(getCalendarRoute(), { state: { fromBlockoutCalendarList: location?.fromBlockoutCalendarList, calenderViewState: location?.calenderViewState, calenderShowSelectedDate: location?.calenderShowSelectedDate } }) : location?.goBackToCalendar ? navigate(getCalendarRoute()) : navigate(businessDashboardRoutePattern)
                                }
                            }} />
                            {moment().isAfter(moment(blockOutTimeDetails?.endDate, "YYYY-MM-DD")) ? null : <button onClick={() => {
                                dispatch(clearAppointmentStatus());
                                // navigate(getEditBlockOutTimeRoute(blockOutTimeDetails?.blockOutId, blockOutTimeDetails?.blockOutDateId))
                                location?.fromBlockoutCalendarList ? navigate(getEditBlockOutTimeRoute(blockOutTimeDetails?.blockOutId, blockOutTimeDetails?.blockOutDateId), { state: { fromBlockoutDescriptionOverview: true, calenderViewState: location?.calenderViewState, calenderShowSelectedDate: location?.calenderShowSelectedDate } }) :
                                    navigate(getEditBlockOutTimeRoute(blockOutTimeDetails?.blockOutId, blockOutTimeDetails?.blockOutDateId))
                            }}>Edit</button>}
                        </div>
                        <div className='row blockout-main-appointment-container icon-close-button'>
                            <div className={`appointment-header ${fromCalendar ? "fromCalendar-appointment-header" : ""} p-3`}>
                                <div className='user-image-container d-flex'>
                                    {!UserProfileData?.user?.image_url ? <h2 className='m-auto'>{UserProfileData?.user?.usr_fname[0]?.toUpperCase() + (UserProfileData?.user?.usr_lname ? UserProfileData?.user?.usr_lname[0]?.toUpperCase() : UserProfileData?.user?.usr_fname[1]?.toUpperCase())}</h2> : <img src={UserProfileData?.user?.image_url} />}
                                </div>

                                <p className='mt-2'>{blockOutTimeDetails?.repeatStatusString}</p>
                                <h2>{blockOutTimeDetails?.title}</h2>
                                <h3>{`${formatDateRange(blockOutTimeDetails?.startDate, blockOutTimeDetails?.endDate)} • ${blockOutTimeDetails?.startTime} – ${blockOutTimeDetails?.endTime}`}</h3>
                            </div>
                        </div>


                        <div className='row blockout-appointment-content-container mt-5'>
                            <div className={`appointment-main-content ${fromCalendar ? "" : "col-lg-4 offset-lg-4  "}`}>

                                <>
                                    <p>
                                        Team member
                                    </p>
                                    <hr />
                                    <div className='general-details d-flex justify-content-between'>
                                        <div className='d-flex flex-column justify-content-center '>
                                            <span>{UserProfileData?.user?.usr_fname?.charAt(0)?.toUpperCase() + UserProfileData?.user?.usr_fname?.substring(1)} {UserProfileData?.user?.usr_lname?.charAt(0)?.toUpperCase() + UserProfileData?.user?.usr_lname?.substring(1)}</span>
                                            <p>{UserProfileData?.user?.occupations?.length > 0 && (UserProfileData?.user?.occupations?.length == 1 ? UserProfileData?.user?.occupations[0] : `${UserProfileData?.user?.occupations[0]} + ${UserProfileData?.user?.occupations?.length - 1}`)}</p>
                                            {appointmentDetails?.mobile_number ? <p className='mb-0 mt-1'>+44 (0) {appointmentDetails?.mobile_number}</p> : <p className='mb-0 mt-1'>{appointmentDetails?.client_email}</p>}
                                        </div>
                                        <div className='user-image-container d-flex'>
                                            {!UserProfileData?.user?.image_url ? <h2 className='m-auto'>{UserProfileData?.user?.usr_fname[0]?.toUpperCase() + (UserProfileData?.user?.usr_lname ? UserProfileData?.user?.usr_lname[0]?.toUpperCase() : UserProfileData?.user?.usr_fname[1]?.toUpperCase())}</h2> : <img src={UserProfileData?.user?.image_url} />}
                                        </div>
                                    </div>

                                    <div className='appointment-details-container mt-5'>
                                        <p>
                                            Location
                                        </p>
                                        <hr />
                                        <p>{`${blockOutTimeDetails?.clinicData?.length > 1 ? `${blockOutTimeDetails?.clinicData[0].clinicName} + ${blockOutTimeDetails?.clinicData?.length - 1}` : `${blockOutTimeDetails?.clinicData[0].clinicName}`}`}</p>
                                    </div>

                                    {<div className='appointment-details-container mt-5'>
                                        <p>
                                            Description
                                        </p>
                                        <hr />
                                        <p>{blockOutTimeDetails?.description}</p>
                                    </div>
                                    }

                                    <div className='appointment-details-container mt-5'>
                                        <p>
                                            Date
                                        </p>
                                        <hr />
                                        <div className='details-container d-flex justify-content-between mb-4'>
                                            <p className='my-0'>Start date</p>

                                            <span>
                                                {moment(blockOutTimeDetails?.startDate, 'YYYY-MM-DD')
                                                    .format('DD MMM, YYYY').replace('Sep', (moment(blockOutTimeDetails?.startDate, 'YYYY-MM-DD').format('MM') === '09') ? 'Sept' : 'Sep')}
                                            </span>

                                        </div>
                                        <div className='details-container d-flex justify-content-between mb-4'>
                                            <p className='my-0'>End date</p>
                                            <span>{moment(blockOutTimeDetails?.endDate, 'YYYY-MM-DD')
                                                .format('DD MMM, YYYY').replace('Sep', (moment(blockOutTimeDetails?.endDate, 'YYYY-MM-DD').format('MM') === '09') ? 'Sept' : 'Sep')}</span>
                                        </div>
                                        <div className='details-container d-flex justify-content-between mb-4'>
                                            <p className='my-0'>Repeat</p>
                                            <span>{blockOutTimeDetails?.repeatStatus}</span>
                                        </div>
                                    </div>

                                    <div className='appointment-details-container mt-5'>
                                        <p>
                                            Time
                                        </p>
                                        <hr />
                                        <div className='details-container d-flex justify-content-between mb-4'>
                                            <p className='my-0'>Start time</p>
                                            <span>
                                                {blockOutTimeDetails?.startTime}
                                            </span>

                                        </div>
                                        <div className='details-container d-flex justify-content-between mb-4'>
                                            <p className='my-0'>End time</p>
                                            <span>{blockOutTimeDetails?.endTime}</span>
                                        </div>
                                        <div className='details-container d-flex justify-content-between mb-4'>
                                            <p className='my-0'>Duration</p>
                                            <span>{blockOutTimeDetails?.readableDuration}</span>
                                        </div>
                                    </div>

                                    {moment().isAfter(moment(blockOutTimeDetails?.endDate, "YYYY-MM-DD")) ? null : <div className='cancel-appointment-button mt-0  d-flex'>
                                        <button onClick={() => {
                                            setIsDelete(true)

                                        }}>
                                            Delete
                                        </button>
                                    </div>
                                    }
                                </>
                            </div>
                        </div>
                        <CustomModal
                            type={"common-width"}
                            modalBody={
                                <>
                                    <div className="delete-modal-body">
                                        <h1>Are you sure?</h1>
                                        <p>you want to delete this block out time? This action can not be reversed?</p>
                                        <div className="w-100">
                                            <div className="delete-button-modal">
                                                <StepButton red={true} label={"Delete"} isLoading={deleteBlockTimeMutationData?.loading} onClick={handleDeleteBlockOut} />
                                            </div>
                                            <div className="cancle-button-modal">
                                                <StepButton gray={true} onClick={() => setIsDelete(false)} label={"Cancel"} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            modalOpen={isDelete}
                            setModalOpen={setIsDelete}
                            setIsOpenMobileCalendar={setIsDelete}
                        />
                    </div >
                    :
                    <SkeletonLoader type={"appointment-detail"} />
            }
        </>
    )
}

export default BlockOutTimeView
