import { GET_USER_PROFILE_DATA, SAVE_CALENDAR_VIEW_FOR_USER_PROFILE_DATA } from "../../constants"

const initialState = {
    UserProfileData: null,
    calendarView: null
}
export const GetUserProfileDataReducer = (state = initialState, action) => {
    console.log('GET_USER_PROFILE_DATA12346464645', GET_USER_PROFILE_DATA, action)
    switch (action.type) {
        case GET_USER_PROFILE_DATA:
            return {
                ...state,
                UserProfileData: action.payload,
            }

        case SAVE_CALENDAR_VIEW_FOR_USER_PROFILE_DATA:
            return {
                ...state,
                calendarView: action.payload,
            }
        default:
            return state
    }
}  