import React, { useEffect, useRef, useState } from 'react'
import './search-client.scss'
import SearchIcon from "../../images/client/search_icon.svg"

import { ReactComponent as RightArrow } from '../../images/client/right_arrow.svg'
import { ReactComponent as PlusIcon } from '../../images/add_event.svg'
import EmptyClient from './EmptyClient';
import AddClient from './AddClient';
import ClientProfile from './ClientProfile';
import { useNavigate } from 'react-router';
import { businessDashboardRoute, getCalendarRoute, getClientProfileRoute, getNewAppointmentRoute, getNewClientRoute, importClientRoute } from '../../Routes';
import NavHeader from '../common/NavHeader/NavHeader';
import { GetClientList } from '../redux/actions/client/ClientList';
import Avatar from 'react-avatar'
import SkeletonLoader from '../common/Skeleton/SkeletonLoader'
import { useDispatch, useSelector } from 'react-redux'
import { setClientData } from '../redux/actions/newAppointment/NewAppointment'
import { extractFirstCharacters } from '../../utils/initials'
// import { ReactComponent as AddClientIcon } from '../../images/add_client.svg'
import AddClientIcon from "../../images/new-appointment/add-client.png";

import { CLEAR_CLIENT_LIST, CLEAR_CLIENT_LIST_DATA } from '../redux/constants'
import InfiniteScroll from 'react-infinite-scroll-component'
import { debounce } from "lodash";
import { ReactComponent as CloseIcon } from "../../images/close_icon.svg";
import { FaRegClock } from "react-icons/fa";
import 'react-tooltip/dist/react-tooltip.css'
// import { Tooltip } from 'react-tooltip'
import useWindowDimension from '../../utils/useWindowDimensions'
import StepButton from '../common/StepButton'
import { webBaseUrl } from '../../config'
import Tooltip from '../common/Tooltip/Tooltip'
import zIndex from '@mui/material/styles/zIndex'
import { OverlayTrigger, Popover } from "react-bootstrap";
import uploadCircle from '../../images/UploadCircle.svg'
import downloadCircle from '../../images/DownloadCircle.svg'
import menuIcon from '../../images/menu.svg'

const SearchClient = ({ isNewClient, setSelectedClientData, setIsClientOffCanvas }) => {
  const popOverRef = useRef(null);
  //Client list Query 
  const { clientListQueryData, initGetClientList } = GetClientList()
  const dispatch = useDispatch()
  console.log("client list", clientListQueryData?.data)
  const { clientList } = useSelector(state => state?.clientListReducer)
  const { totalClientCount } = useSelector(state => state?.clientListReducer)
  const { clientListPage } = useSelector(state => state?.clientListReducer)
  const { loadedClientCount } = useSelector(state => state?.clientListReducer)
  const { emptyClientList } = useSelector(state => state?.clientListReducer)


  // const  clientList = [
  //     {
  //         name:"Akash Prajapati",
  //         email:"akashp@gmail.com"
  //     },
  //     {
  //         name:"Akashay Gadher",
  //         email:"akshay@gmail.com"
  //     },
  //     {
  //         name:"Rakesh Prajapati",
  //         email:"rakeshp@gmail.com"
  //     }

  // ]

  const [searchText, setSearchText] = useState('');
  const [isAddClient, setIsAddClient] = useState(false);
  const [isClientProfile, setIsClientPeofile] = useState(false);
  const [isSearchedTriggered, setIsSearchedTriggered] = useState(false);
  const [activeFilter, setActiveFilter] = useState(-1);
  const scrollRef = useRef()

  console.log("page", clientListPage)

  const navigate = useNavigate()
  const initiateClientListCall = (refreshList = false, searchText) => {
    let newPage = clientListPage + 1;
    if (refreshList) {
      newPage = 1;
    } else if (totalClientCount <= loadedClientCount) {
      return;
    }
    initGetClientList({
      search: searchText,
      limit: 20,
      page: newPage,
      searchByAlphabet: activeFilter != -1 ? String.fromCharCode(65 + activeFilter) : ""
    })
  };
  // useEffect(()=>{
  //   initGetClientList({
  //     search:searchInput
  //   })
  // },[searchInput])


  const handleClickTile = (item) => {
    //Click from the new appointment then if Condition 
    if (isNewClient) {
      setIsClientOffCanvas(false)
      setSelectedClientData(item)
    } else {
      navigate(getClientProfileRoute(item?.clentId))
    }
  }

  // Filter client list
  // const filteredClients = clientListQueryData?.data?.getClientList?.data?.items?.filter((client) => {
  //   const lowerCaseSearchInput = searchInput.toLowerCase();
  //   const clientNameMatch = client.client_name.toLowerCase().includes(lowerCaseSearchInput);
  //   const emailMatch = client.client_email.toLowerCase().includes(lowerCaseSearchInput);
  //   // const phoneMatch = client.phone.toLowerCase().includes(lowerCaseSearchInput);
  //   // Include a client in the filtered list if any of the conditions match
  //   return clientNameMatch || emailMatch ;
  // });
  useEffect(() => {
    dispatch({
      type: CLEAR_CLIENT_LIST
    })
    dispatch({
      type: CLEAR_CLIENT_LIST_DATA
    })
    scrollRef.current.scrollTop = 0;
    initiateClientListCall(true)

  }, [activeFilter])

  const handleSearchText = (e) => {

    const newText = e.target.value;
    setIsSearchedTriggered(true);
    setSearchText(newText);

  };



  //DEBOUNCE FOR SEARCH
  // Debounced API call function
  const debouncedApiCall = debounce((query) => {
    // Make the API call using the search query
    // Replace this with your own API call logic
    dispatch({
      type: CLEAR_CLIENT_LIST_DATA
    })
    dispatch({
      type: CLEAR_CLIENT_LIST
    })
    initiateClientListCall(true, query)
  }, 500); // Debounce delay in milliseconds (e.g., 500ms)

  useEffect(() => {
    if (isSearchedTriggered) {
      // Call the debounced API function with the search query
      debouncedApiCall(searchText);
      // Cleanup function to cancel any pending debounced calls
      return () => {
        debouncedApiCall.cancel();
      };
    }
  }, [searchText]);

  const redirectAddClient = () => {
    navigate(getNewClientRoute())
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#EEEFF3",
        color: "#000"
      },
      children: `${name.split(' ')[0][0]}`,
    };
  }

  const dimensions = useWindowDimension();
  const [headerNavbar, setHeaderNavbar] = useState(0);
  const [searchHeaderWrapper, setSearchHeaderWrapper] = useState(0);

  useEffect(() => {
    setHeaderNavbar(
      document.getElementsByClassName('nav-header-without-padding-container')[0]?.offsetHeight
    );

    setSearchHeaderWrapper(
      document.getElementsByClassName('searchHeaderWrapper')[0]?.offsetHeight
    );

    window.addEventListener('resize', handleResize);
  }, []);
  const handleResize = () => {
    setHeaderNavbar(
      document.getElementsByClassName('nav-header-without-padding-container')[0]?.offsetHeight
    );

    setSearchHeaderWrapper(
      document.getElementsByClassName('searchHeaderWrapper')[0]?.offsetHeight
    );

  };

  const popoverClick = (
    <Popover id="popover-trigger-click" className="client-option-main p-3">
      <div className="client-list-option-container w-100">
        <div className="client-list-left-popover-content" role='button' onClick={()=>window.location.href= webBaseUrl + "/client-import-reference-template.csv"}>
          <img src={downloadCircle} alt="edit" />
          <span>Download CSV template</span>
        </div>
      </div>
      <div className="client-list-option-container w-100">
        <div className="client-list-left-popover-content" role='button' onClick={()=>navigate(importClientRoute())}>
          <img src={uploadCircle} alt="delete" />
          <span>Upload CSV</span>
        </div>
      </div>
    </Popover>
  );

  return (
    <div className='CustomeSearchClient MainCustomeSearchClientPage'>
      {
        isNewClient &&
        <div className='fixed-client-header w-100'>
          <NavHeader isBackOnly={true} applyPadding={false} handleOnBack={() => isNewClient ? setIsClientOffCanvas(false) : navigate(businessDashboardRoute())} isSticky={true} />
          <h1>Select client</h1>
          <div className='w-100 text-center'>
            <div className='search-container custom-search-container m-auto'>
              <input type='text' placeholder='Name or email' value={searchText} onChange={(e) => handleSearchText(e)} />
              <img src={SearchIcon} alt='search-icon' className="search-icon-img" />
              {searchText && <CloseIcon className="cursor-pointer search-close-icon" onClick={() => { setSearchText("") }} />}

            </div>
            <div className='d-flex align-items-center justify-content-center w-65 gap-20'>
              <div className='d-flex align-items-center justify-content-center w-50 gap-3 cursor-pointer' onClick={() => setIsAddClient(true)} >
                <AddClientIcon />
                <p className='m-0 add-client-text'>Add New Client</p>
              </div>
            </div>
          </div>
        </div>
      }
      {!isNewClient && <NavHeader isBackOnly={true} applyPadding={false} handleOnBack={() => isNewClient ? setIsClientOffCanvas(false) : navigate(businessDashboardRoute())} />}
      <div className='Main-search-client-container'>
        <div className='search-client-container'>
          <div className='searchHeaderWrapper'>
            {
              !isNewClient && <>
               
                  <h1>Select client</h1>
                 <div className='d-flex align-items-center justify-space-between gap-3'>
                <div className='search-container'>
                  <input type='text' placeholder='Name or email' value={searchText} onChange={(e) =>{ handleSearchText(e);setActiveFilter(-1)}} />
                  <img src={SearchIcon} alt='search-icon' className="search-icon-img" />
                  {searchText && <CloseIcon className="cursor-pointer search-close-icon" onClick={() => { setSearchText("") }} />}
                </div>
                <OverlayTrigger
                  rootClose
                  ref={(r) => (popOverRef = r)}
                  container={popOverRef.current}
                  trigger="click"
                  placement={
                    "bottom"
                  }
                  overlay={popoverClick}
                >
                  <img alt='menu' src={menuIcon} role='button'/>
                </OverlayTrigger>
                </div>
                <div className='client-filter-container'>
                  <p className={`client-filter ${activeFilter == -1 ? "active-client-filter" : ""}`} onClick={() => {setActiveFilter(-1);setSearchText("")}}>All</p>
                  {
                    [...Array(26)].map((item, index) => {
                      return <p className={`client-filter ${activeFilter == index ? "active-client-filter" : ""}`} onClick={() => {
                        setActiveFilter(index)
                        setSearchText("")
                      }}>{String.fromCharCode(65 + index)}</p>
                    })
                  }
                </div>
                <div className="add-client-wrapper cursor-pointer d-flex align-items-center justify-content-between mb-3"
                  onClick={() => redirectAddClient()}
                >
                  <div className='d-flex align-items-center'>
                    {/* <AddClientIcon /> */}
                    <img src={AddClientIcon} alt="AddClientIcon" width={48} height={48} />

                    <div className="add-client-container ms-3">
                      <h1>Add client</h1>
                    </div>
                  </div>
                  <RightArrow />
                </div>
              </>
            }
          </div>
          <div style={{ height: dimensions.height - headerNavbar - searchHeaderWrapper - 10 + 'px', width: "100%", overflowY: "scroll" }} id="scrollable-div" className='Select-ClientWrapper' ref={scrollRef}>
            <InfiniteScroll
              dataLength={loadedClientCount}
              next={() => initiateClientListCall(false, searchText)}
              hasMore={totalClientCount > loadedClientCount}
              scrollableTarget="scrollable-div"
            >
              <div className="client-tile-container">
                {((clientListQueryData?.data?.getClientList_v3?.data === null && !clientListQueryData?.loading) || emptyClientList) ? (
                  <EmptyClient name={searchText} title={"No Clients Found"} subTitle={searchText ? "is not in your contacts." : ""} />
                ) : (
                  <div className={`${isNewClient ? "w-65" : ""} left-content d-flex flex-column align-items-center`}>

                    {clientList?.map((item, index) => (
                      <div key={index} className="d-flex align-items-center justify-content-between w-100" onClick={() => handleClickTile(item)}>
                        <div className='d-flex align-items-center'>
                          <Avatar maxInitials={2} name={item?.clientName} round={true} color='#EEEFF3' size={49} fgColor='black' className={"object-fit-cover custom-object-fit-border"} src={item?.clientProfileImage} />
                          <div className="title-container ms-3">
                            <h1>{item?.clientName}</h1>
                            <p>{item?.clientEmail}</p>
                          </div>
                        </div>
                        <RightArrow />
                      </div>
                    ))}
                    {
                      clientListQueryData.loading ?
                        <div className='selectClientSkeletonLoader'>
                          <SkeletonLoader type={"client-list"} isClient={true} showCircle={true} />
                        </div>
                        : null
                    }

                  </div>
                )}
              </div>
            </InfiniteScroll>
          </div>
          {/* {
            !isNewClient &&
            <div className={`flot-button`}>
              <PlusIcon onClick={() => redirectAddClient()} className='cursor-pointer' />
            </div>
          } */}
          {/* <ClientProfile isOpen={isClientProfile} setIsOpen={setIsClientPeofile}/> */}
        </div>
      </div>
    </div>
  )
}

export default SearchClient

