import React, { useState } from 'react'
import CustomModal from '../../modal/CustomModal'
import { Rating } from 'react-simple-star-rating'
import { GenerateBookingReviewQuery } from '../../redux/actions/consent-form-thankyou/generateBookingReview'
import { AddReviewBookingMutation } from '../../redux/actions/consent-form-thankyou/addReviewForBooking'
import { toast } from 'react-toastify'
import StepButton from '../../common/StepButton'

const ClientSideReviewModal = ({ reviewModal, setReviewModal, clinicName,consentFormId }) => {

    const [rating, setRating] = useState(0)
    const [review, setReview] = useState('')
    const [loaderAI, setLoaderAI] = useState(false)
    const [hideAiPrompt, setHideAiPrompt] = useState(false)

    //GENERATE BOOKING REVIEW QUERY
    const { generateBookingReviewQueryData, initGenerateBookingReview } = GenerateBookingReviewQuery()
    //ADD REVIEW FOR BOOKING MUTATION
    const { addReviewBookingMutationData, initiateAddReviewBooking } = AddReviewBookingMutation()

    const handleRating = (rate) => {
        setRating(rate)
    }

    //Generate REview From AI
    const handleGenerateReview = () => {
        if (rating !== 0) {
            setLoaderAI(true)
            initGenerateBookingReview({
                bookingId: parseInt(consentFormId),
                rating: rating,
                type: 2
            }, (res) => {
                if (res?.success) {
                    setReview(res?.data?.reviewContent)
                    setLoaderAI(false)
                    setHideAiPrompt(true)
                } else {
                    setLoaderAI(false)
                    toast.error(res?.message)
                    setHideAiPrompt(false)
                }
                console.log("AI review", res)
            }, (err) => {
                console.log("err", err)
                setLoaderAI(false)
                setHideAiPrompt(false)
            })
        } else {
            setLoaderAI(false)
            setHideAiPrompt(false)
            toast.error("Please select rating")
        }
    }

    //HANDLE ADD REVIEW
    const handleAddReview = () => {
        const data = {
            booking: parseInt(consentFormId),
            rating: rating,
            review: review,
            type: 2
        }
        initiateAddReviewBooking(data, (res) => {
            console.log("feedback", res)
            if (res.success) {
                toast.success(res?.message)
                setReviewModal(false)
            } else {
                //toast.error(res?.message)
                setReviewModal(false)
            }
        }, (err) => {
            console.log("err", err)
        })
    }

    return (
        <CustomModal
            modalOpen={reviewModal}
            setModalOpen={setReviewModal}
            modaltitle={"Leave Review"}
            className={"thankyou-page-rating-modal"}
            modalBody={
                <div className='review-modal-container'>
                    <div className='header-section'>
                        {/*<h1></h1>*/}
                        <h1>Select star rating for '{clinicName}'</h1>
                    </div>
                    <div className='rating-section'>
                        <Rating initialValue={rating} onClick={handleRating} />
                    </div>
                    <div className='review-section'>
                        <h1>Leave the practitioner a little note to help them with their business</h1>

                        <div className='prompt-review-container'>
                            <textarea placeholder='Type your feedback'
                                value={review}
                                onChange={(e) => setReview(e.target.value)}
                            />
                            {
                                !hideAiPrompt  &&
                                <button onClick={handleGenerateReview} className={`${rating == 0 ? 'd-none':''}`} >{loaderAI ? <i className="fas fa-spinner fa-pulse"></i> : "Prompt Review"}</button>
                            }
                        </div>
                        <p>
                            This review prompt is provided solely as a guideline based on the information given. It is intended to assist in framing your experience and is not definitive or exhaustive. Please ensure your review accurately reflects your personal experience and sentiments.
                        </p>
                    </div>
                    <div className='review-modal-footer'>
                        <div className='btn-blue'>
                            <StepButton disabled={rating === 0 || review?.length === 0} blue={true} label={"Submit"} isLoading={addReviewBookingMutationData.loading} onClick={() => { handleAddReview() }} />
                        </div>

                    </div>
                    <div className='message-footer'>
                        <p>Your review will be posted to the Faces Consent booking platform</p>
                    </div>
                </div>
            }
        />
    )
}

export default ClientSideReviewModal