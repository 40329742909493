import React, { useState } from 'react';
import { TEMPLATE_TYPE_ENUM } from './constants';
import consentFormIcon from '../../../images/consent-list.svg';
import aftercareFormIcon from '../../../images/aftercare-list.svg';
import MenuIcon from '../../../images/icons/verticalDots.svg';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ReactComponent as EditCosentIcon } from '../../../images/icons/edit-consent-form-icon.svg';
import { ReactComponent as EditAftercareIcon } from '../../../images/icons/edit-form-icon.svg';
import { ReactComponent as EyeIcon } from '../../../images/icons/circleEyeIcon.svg';
import { ReactComponent as RightArrow } from '../../../images/client/right_arrow.svg';
import { ReactComponent as ActiveIcon } from '../../../images/menu-active.svg';
import { ReactComponent as InactiveIcon } from '../../../images/menu-inactive.svg';
import CustomModal from '../../modal/CustomModal';
import CreateNewFormPopup from '../edit-form/common-component/CreateNewFormPopup';
import { ChangeFormActiveStatusMutation } from '../../redux/actions/formsTemplate/getFormTemplateList';
import showToast from '../../common/CustomToast/CustomToast';
import { useNavigate } from 'react-router';
import { editFormRoute, getEditAftercareFormRoute, PreviewAfterCareFormRoute, previewFormRoute } from '../../../Routes';

function FormItem({ data, onStatusChange }) {

    const { changeStatusRequest, changeStatusQueryStatus } = ChangeFormActiveStatusMutation();
    const [editFormPopupVisible, setEditFormPopupVisible] = useState(false);
    const [isStatusActive, setIsStatusActive] = useState(data.formStatus === 'active');
    const navigate = useNavigate();

    const menuData = [
        {
            title: `Edit ${data.templateType === TEMPLATE_TYPE_ENUM.consent ? 'consent' : 'aftercare'} form`,
            icon: data.templateType === TEMPLATE_TYPE_ENUM.consent ? <EditCosentIcon /> : <EditAftercareIcon />,
            action() {
                if(data.templateType === TEMPLATE_TYPE_ENUM.consent) {
                    navigate(editFormRoute(data.id) + '?tab=4');
                    // setEditFormPopupVisible(true);
                } else {
                    navigate(getEditAftercareFormRoute(data.id) + '?tab=4');
                }
            }
        },
        {
            title: 'Preview',
            icon: <EyeIcon />,
            action() {
                if(data.templateType === TEMPLATE_TYPE_ENUM.consent) {
                    navigate(previewFormRoute(data.id) + '?tab=4');
                } else {
                    navigate(PreviewAfterCareFormRoute(data.id) + '?tab=4');
                }
            }
        },
        {
            title: isStatusActive ? 'Deactivate' : 'Activate',
            icon: isStatusActive ? <InactiveIcon /> : <ActiveIcon />,
            action() {
                if(changeStatusQueryStatus.loading) return;

                const initialStatus = isStatusActive;
                const payload = {
                    formId: data.id,
                    templateType: data.templateType,
                    status: !isStatusActive
                }
                const onSuccess = (res) => {
                    showToast('success', res.message);
                    setIsStatusActive(prev => !prev);
                    onStatusChange();
                }
                const onError = () => {
                    setIsStatusActive(initialStatus);
                }
                changeStatusRequest(payload, onSuccess, onError);
            }
        },
    ];

    const Menu = (
        <Popover
            id="popover-trigger-click-1"
            className="appointment-option-main Custome-Popover-Design-Set p-3"
            // title="Popover bottom"
        >
            {menuData.map((item, index) => (
                <div
                    className="appointment-list-option-container"
                    key={index}
                    onClick={() => {
                        document.body.click();
                        item.action()
                    }}
                >
                    <div className="appointment-list-left-popover-content">
                        {item.icon}
                        <p>{item.title}</p>
                    </div>
                    <div className="appointment-list-right-popover-content">
                        <RightArrow />
                    </div>
                </div>
            ))}
        </Popover>
    );

    return (
        <>
            <div key={data.id} className="FormsListItem">
                <div className="FormsListItemContent">
                    <div className="heart-icon-image">
                        <img
                            src={
                                data.templateType === TEMPLATE_TYPE_ENUM.consent
                                    ? consentFormIcon
                                    : aftercareFormIcon
                            }
                            alt='icon'
                        />
                    </div>
                    <div className="ItemContent">
                        <h1>{data.name}</h1>
                    </div>
                </div>
                <OverlayTrigger rootClose trigger="click" placement="auto" overlay={Menu}>
                    <div className='FormsListItem__menu'>
                        <img
                            style={{ cursor: 'pointer' }}
                            src={MenuIcon}
                            alt="menu"
                        />
                    </div>
                </OverlayTrigger>
            </div>
            <CustomModal
                modalOpen={editFormPopupVisible}
                setModalOpen={setEditFormPopupVisible}
                className={'customeNameFormModel'}
                modaltitle='Update form name'
                type='common-width'
                isCloseImage={true}
                modalBody={<CreateNewFormPopup isEditMyform isAftercare={data.templateType === TEMPLATE_TYPE_ENUM.aftercare} formId={data.id} />}
            />
        </>
    );
}

export default FormItem;
