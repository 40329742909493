import React, { createRef, useEffect, useRef, useState } from "react";

import Slider from "react-slick";

import goback from "../../../images/back_arrow.svg";
import "./treatment-listing-web.scss";
import "./treatment-tabs.scss";


import { Scrollspy } from "@makotot/ghostui";
import Skeleton from "react-loading-skeleton";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import SearchIcon from "../../../images/search_icon.svg";
import Star from "../../../images/star.svg";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import BannerHeader from "../../common/BannerHeader";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";
import SearchWebModal from "../../modal/SearchWebModal/SearchWebModal";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { webBaseUrl } from "../../../config"
import { clinicCartPattern } from "../../../Routes";

const TreatmentListWeb = ({
  setStep, step, treatmentData, isLoading, sectionRefs, setSelectedTreatment, selectedTreatment
}) => {
  const sliderRef = createRef();
  const parentDiv = useRef(null)

  const modalRef = useRef(null)
  const { width, height } = useWindowDimensions();

  const [searchParams, setSearchParams] = useSearchParams();

  const { treatmentList } = useSelector((state) => state.businessTreatmentsListReducer)


  // const treatmentData = useSelector((state) => state.businessTreatmentsListReducer?.treatmentList?.items)
  console.log(treatmentData, "treatmentData");


  var autoScroll;

  if (width <= 576) {
    autoScroll = 1
  } else if (width > 576 && width <= 834) {
    autoScroll = 3
  } else if (width > 834 && width <= 1025) {
    autoScroll = 3
  } else {
    autoScroll = 5
  }

  const [checkedValues, setCheckedValues] = useState({})
  const [slide, setSlide] = useState(0)
  const [search, setSearch] = useState("")
  const [treatmentDetails, setTreatmentDetails] = useState({
    slug: "",
    treatmentId: 0,
    categoryId: 0
  })

  const [treatmentDataToBeOPenInModal, setTreatmentDataToBeOPenInModal] = useState(null)
  const [focused, setFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(focused);

  const { businessClinicList } = useSelector((state) => state?.businessLocationListReducer)
  console.log(businessClinicList?.total, "businessClinicList===");


  const handleOpenModal = (slug, categoryId, treatmentId, data) => {
    setTreatmentDetails({
      slug: slug,
      treatmentId: treatmentId,
      categoryId: categoryId
    })
    setTreatmentDataToBeOPenInModal(data)
    setIsOpen(true)
  }

  const handleCheckboxChange = (categoryId, treatmentId) => {

    // Append the found treatment to the selectedTreatments array

    setSelectedTreatment((prevSelectedTreatments) => {
      const category = treatmentData?.find(
        (item) => item?.category_id === categoryId
      );
      const treatment = category?.treatments?.find(
        (t) => t?.id === treatmentId
      );
      // find if treatment is already part of prevSelectedTreatments
      const existingTreatmentObj = prevSelectedTreatments.find((t) => t.id === treatmentId);

      if (treatment) {
        // If the treatment is checked, add it to the selectedTreatments array
        if (existingTreatmentObj) {
          return prevSelectedTreatments.filter((t) => t.id !== treatment.id);
        }
        return [...prevSelectedTreatments, treatment];
      }

      return prevSelectedTreatments;
    });
  };


  useEffect(() => {
    // Calculate the total price when the checkedValues state changes
    // convert the selectedTreatment array to an object with the treatment id as the key
    const newCheckedValues = {};
    selectedTreatment.forEach((treatment) => {
      newCheckedValues[treatment.id] = treatment;
    });
    setCheckedValues(newCheckedValues);
  }, [selectedTreatment]);

  const handleChangeCategory = (item) => {

    let categoryItem = document.getElementById(item.category_id.toString());
    parentDiv.current.scroll({
      top: categoryItem.offsetTop,
      behavior: 'smooth'
    })
  }
  const [filterList, setFilterList] = useState([]);
  const [noItemsFound, setNoItemsFound] = useState(false)
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearch(query);
    if (query.trim() !== "") {
      const filteredList = treatmentData?.map((item) => {
        const treatmentsInCategory = item?.treatments?.filter((data) => {
          return (
            data.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
            item.category_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
          );
        });

        return {
          ...item,
          treatments: treatmentsInCategory,
        };
      });
      if ((filteredList.map(i => i.treatments)).flat().length > 0) {
        setFilterList(filteredList);
        setNoItemsFound(false);
      }
      else {
        setNoItemsFound(true);
      }
    } else {
      setNoItemsFound(false);
      setFilterList(treatmentData);
    }

  };

  const handleSlickNext = (data) => {
    setSlide(data)
    if (sliderRef) {
      sliderRef?.current?.slickNext()
      sliderRef?.current?.slickGoTo(data)
    }
  }
  const handleSlickPrev = (data) => {
    setSlide(data)
    if (sliderRef) {
      sliderRef?.current?.slickPrev()
      sliderRef?.current?.slickGoTo(data)
    }
  }
  useEffect(() => {
    // Set filterList to the initial treatmentData when the component first loads
    setFilterList(treatmentData);
  }, [treatmentData]);


  var settings1 = {
    slidesToShow: 5,
    slidesToScroll: 3,
    infinite: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          className: "center",
          // dots: true
        },
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          className: "center",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const truncateString = (str) => {
    return str?.length > 10 ? str.substring(0, 60) + "..." : str;
  };

  useEffect(() => {
    if (searchParams.get("treatment_id") && treatmentData?.length > 0) {
      treatmentData?.map((item, index) => {
        item?.treatments?.map((treatment, tIndex) => {
          if (treatment.id == treatmentList?.treatment_DB_id) {
            // selectedTreatment.push(treatment)
            setSelectedTreatment((prevSelectedTreatments) => {
              return [...prevSelectedTreatments, treatment]
            })
          }
        })
      })
      searchParams.delete("treatment_id")
      setSearchParams(searchParams)
    }
  }, [treatmentData])
  // const handleGoBack = () => {
  //   if (treatmentList?.treatment_DB_id != null) {
  //     console.log("home page");
  //     window.location.href = webBaseUrl + "/clinics"
  //   } else {
  //     console.log("step");
  //     setStep(1);
  //   }
  // };

  useEffect(() => {
    // Handle the back button click event
    window.onpopstate = function (event) {
      if (window.location.search !== "") {
        window.history.go(-2)
      }

    };
  }, [])

 
  console.log(treatmentData, 'treatdata==');
  console.log(treatmentList?.treatment_DB_id , businessClinicList?.total, 'data==');
  return (
    <>
    

      <div className="main-treat-container customeScrollFullView">
        {/* <div className="sub-container">
                  <p className="booking-text">Booking For Techerudite</p>
                </div> */}
        <div className="sub-container-2">
          <div className="main-treatment">
          {(businessClinicList?.total > 1 ||( treatmentList?.treatment_DB_id != null && businessClinicList?.total === 1)) && (
              <div className="back-section mb-4">
                <div className="go-back">
                  <img
                    className="cursor-pointer"
                    src={goback}
                    onClick={() => {
                      if(treatmentList?.treatment_DB_id != null && businessClinicList?.total === 1){
                        window.location.href = webBaseUrl + "/clinics"
                      }else{
                        setStep(1);
                      }
                    }}
                    alt="Go back"
                  />
                </div>
              </div>
            )}

            {
              isLoading ? 
                <div className="ms-20">
                  <BannerHeader title=" Select a Treatment" />
                  {

                    <>
                      <div className="image-section">
                        <Slider {...settings1} arrows={false} ref={sliderRef}>
                          {
                            [...Array(5)].map(i => <SkeletonLoader type={"tab"} showCircle={false} />)
                          }
                        </Slider>
                      </div>

                      <div className="search-treatment-mobile">
                        <Skeleton height={50} width={"100%"} />
                      </div>

                      <div className="main-list-container"  >
                        <SkeletonLoader type={"list-treatment"} showCircle={false} />
                      </div>
                    </>
                  }

                </div>
                :
                <div className="ms-20">
                  <BannerHeader title=" Select a Treatment" />
                  {
                    sectionRefs?.length > 0 ?
                      <Scrollspy
                        sectionRefs={sectionRefs}
                        offset={-100}
                        rootSelector="#my-list-root"
                      >
                        {
                          ({ currentElementIndexInViewport }) => (
                            <>
                              {
                                currentElementIndexInViewport > slide && currentElementIndexInViewport % autoScroll == 0 && currentElementIndexInViewport != 0 && handleSlickNext(currentElementIndexInViewport)
                              }
                              {
                                currentElementIndexInViewport < slide && handleSlickPrev(currentElementIndexInViewport)
                              }
                              <div className="image-section">
                                <Slider {...settings1} ref={sliderRef}>
                                  {filterList?.map((item, index) => {
                                    return (
                                      <>
                                        <div
                                          className="categories-tab text-center cursor-pointer"

                                          onClick={() => { handleChangeCategory(item, index) }}
                                        >
                                          <p
                                            className={
                                              currentElementIndexInViewport === index
                                                ? "active-tab"
                                                : "inactive-tab"
                                            }
                                          >
                                            {item?.category_name}
                                          </p>
                                        </div>
                                      </>
                                    );
                                  })}
                                </Slider>
                              </div>

                              <div className="search-treatment-mobile mt-4">
                                <input
                                  type="text"
                                  id="search"
                                  name="search"
                                  value={search}
                                  onChange={handleSearch}
                                  placeholder="Search for treatment"
                                />
                                <img src={SearchIcon} alt="search" />
                              </div>

                              <div className="main-list-container" ref={parentDiv} id="my-list-root">

                                {
                                  noItemsFound ?
                                    <div className="fw-bold d-flex justify-content-center align-items-center mt-5">sorry!, your Search Items is not found</div>
                                    : filterList?.map((item, index) => {
                                      return (
                                        <div ref={sectionRefs[index]}
                                          id={item?.category_id?.toString()}>
                                          <div
                                            className="treatment-cate-title me-3"
                                          >
                                            <p>{item.category_name}</p>
                                            <hr />
                                          </div>
                                          <div >
                                            {item?.treatments?.map((treatment, tIndex) => (
                                              <div className=" mt-3" onClick={() => (document.getElementById(`category-${treatment?.treatment_id}`)).addEventListener(('change'), handleCheckboxChange(item?.category_id, treatment.id))} >
                                                <div className={`treatment-item ${checkedValues[treatment?.id] ? 'selected' : ''}`}>
                                                  <div className="row">
                                                    <div className="col-md-11">
                                                      <div className="treatment-with-checkbox d-flex justify-content-between">
                                                        <h1 onClick={(event) => { event.stopPropagation(); handleOpenModal(treatment.slug, item.category_id, treatment?.id, treatment) }} className="cursor-pointer">{treatment.name}</h1>

                                                      </div>
                                                      <h2>{`£${treatment.amount} • ${treatment.duration} mins`}</h2>
                                                      <p>{truncateString(treatment.description)}</p>
                                                      <div className="rating-container">
                                                        <img src={Star} alt="star" />
                                                        <p>{`${treatment.average_review_rating} (${treatment?.total_review_count})`}</p>
                                                      </div>
                                                    </div>
                                                    {/* <div className="col-md-1"></div> */}
                                                    <div className="col-md-1 d-flex justify-content-end ">

                                                      <input
                                                        className="black-checkbox cursor-pointer"
                                                        id={`category-${treatment?.treatment_id}`}
                                                        type="checkbox"
                                                        checked={checkedValues[treatment?.id] || false}
                                                        // onChange={(event) => { event.stopPropagation(); handleCheckboxChange(item?.category_id, treatment.id) }}
                                                        style={{ background: "red" }}
                                                      />
                                                    </div>
                                                  </div>



                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      )
                                    }

                                    )}
                              </div>
                            </>
                          )
                        }
                      </Scrollspy>
                      :
                      null
                  }

                </div >
            }
          </div >
        </div >
        <SearchWebModal setStep={setStep} step={step} treatmentDetails={treatmentDetails} isAdded={(selectedTreatment?.find(i => i.id == treatmentDataToBeOPenInModal?.id))} ref={modalRef} isOpen={isOpen} setIsOpen={setIsOpen} selectedTreatment={selectedTreatment} treatmentDataToBeOPenInModal={treatmentDataToBeOPenInModal} setSelectedTreatment={setSelectedTreatment} />
      </div >

    </>
  );
};

export default TreatmentListWeb;