import { GET_ALL_WEBSITE_PACKAGE_DATA } from "../../constants/createWebiste/getAllWebsitePackages"

const initialState = {
    allPackage: []
}


export const GetAllWebsitePackageReducer = (state = initialState, action) => {
    console.log("12121212121", state, action)
    switch (action.type) {
        case GET_ALL_WEBSITE_PACKAGE_DATA: {
            return {
                ...state,
                allPackage: action?.payload
            }
            break;

        }
        default:
            return state;
    }

}