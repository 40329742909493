import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const SAVE_PRESCRIBER_INSURANCE_DETAILS_MUTATION = gql`
mutation SavePrescriberInsuranceDetails($insuranceCompany: String, $insuranceExpiry: String, $policyNumber: String, $insurnaceDocuments: [InsurnaceDocumentsImagesType], $insurnaceDocumentsFiles: [InsurnaceDocumentsFile]) {
  savePrescriberInsuranceDetails(insurance_company: $insuranceCompany, insurance_expiry: $insuranceExpiry, policy_number: $policyNumber, insurnace_documents: $insurnaceDocuments, insurnace_documentsFiles: $insurnaceDocumentsFiles) {
    success
    message
  }
}
`;

export const PrescriberInsuranceDetails = () => {
    const [savePrescriberInsuranceDetails, { data: savePrescriberInsuranceDetailsResponseData }] =
      useMutation(SAVE_PRESCRIBER_INSURANCE_DETAILS_MUTATION);
    const dispatch = useDispatch();
  
    const initiateSavePrescriberInsuranceDetails = (variables, successCallback, errorCallback) => {
        savePrescriberInsuranceDetails({ variables })
        .then(({ data }) => {
          if (data.savePrescriberInsuranceDetails.success) {
            successCallback(data.savePrescriberInsuranceDetails);
          } else {
            errorCallback(data.savePrescriberInsuranceDetails);
          }
        })
        .catch((error) => {
          // Handle error
          errorCallback(error);
        });
    };
  
    return { initiateSavePrescriberInsuranceDetails, savePrescriberInsuranceDetailsResponseData };
  };


  const GET_PRESCRIBER_INSURANCE_DETAILS_QUERY = gql`
  query GetPrescriberInsuranceDetails {
    getPrescriberInsuranceDetails {
      success
      message
      data {
        insurance_company
        insurance_expiry
        policy_number
        otherImages {
          id
          title
          image_url
        }
      }
    }
  }
  
`;
export const GetPrescriberInsuranceDetails = () => {
    const dispatch = useDispatch();
    const [getInsuranceResponseFunction, { loading, error, data }] = useLazyQuery(GET_PRESCRIBER_INSURANCE_DETAILS_QUERY, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: (response) => {
        
      },
    });
  
    const initGetPrescriberInsuranceDetails = () => {
      getInsuranceResponseFunction();
    };
  
    return { loading, error, data, initGetPrescriberInsuranceDetails };
  };


  