import { gql, useMutation } from "@apollo/client";

const SAVE_PRODUCT_ANSWER=gql`
mutation SaveProductAnswers($questionId: Int!, $productId: Int, $slug: String, $answer: String, $sessionId: String, $answerFile: Upload) {
    saveProductAnswers(questionId: $questionId, productId: $productId, slug: $slug, answer: $answer, sessionId: $sessionId, answerFile: $answerFile) {
      success
      message
      data {
        productId
      }
    }
  }
`;

export const SaveProductAnswersActions=()=>{
    const [saveProductAnswerResponseFunction,
    saveProductAnswerResponseData]=useMutation(SAVE_PRODUCT_ANSWER)

    const saveProductAnswerQueryData=saveProductAnswerResponseData;

    const initSaveProductAnswerData=(data, successCallback, errorCallback)=>{
        saveProductAnswerResponseFunction({
            variables:data
        }).then((responseData)=>{
            if(responseData.data.saveProductAnswers.success){
                successCallback(responseData)
            }else{
                errorCallback(responseData)
            }
        }).catch((error)=>{
            errorCallback(error)
        })
    }

    return {initSaveProductAnswerData,saveProductAnswerQueryData}
}