import React, { useEffect, useState } from 'react'
import './get-started.scss'
import closeIcon from "../../../images/icons/Back.svg"
import locationIcon from "../../../images/icons/Image.svg"
import icon1 from '../../../images/icons/Image1.svg'
import icon2 from "../../../images/icons/Image3.svg"
import checkFill from "../../../images/icons/check_fill.png"
import { useNavigate } from 'react-router'
import CustomButton from '../../custom-components/custom-button'
import { useSelector, useDispatch } from 'react-redux';
import getOnboardingStatus, { connectStripeExpress, updateOccupations } from '../../redux/actions/dashboard/onboarding'
import CustomModal from '../../modal/CustomModal'
import CustomAutoComplete from '../../auto-complete/CustomAutoComplete'
import { CLEAR_UPDATE_OCCUPATIONS, CONNECT_STRIPE_EXPRESS, GET_ONBOARDING_STATUS, UPDATE_OCCUPATIONS } from '../../redux/constants'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { businessDashboardRoutePattern } from '../../../Routes'
import { GetUserOnboardingStatusAction } from '../../redux/actions/dashboard/userOnBoardingGql'
import { UpdateOccupationMutationAction } from '../../redux/actions/dashboard/updateOnboardingGql'
import { StripeOnboardingMutationAction } from '../../redux/actions/dashboard/stripeOnboardingGql'
import AiAssistantContainer from '../../dashboard/aiassistant-container/AiAssistantContainer'
import { validName } from "../../../utils/Regex";

const GetStarted = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [occupation, setOccupation] = useState(false);
    const [optionValue, setOptionValue] = useState([]);
    const [occupationError, setOccupationError] = useState(null);
    const [businessNameError, setbusinessNameError] = useState(null);

    const [loadingStripeExpressLink, setLoadingStripeExpressLink] =
        useState(false);

    const onboardingData = useSelector(
        (state) => state.OnboardingReducer.onBoardingStatusData
    );
    const { occupationData } = useSelector((state) => state.OnboardingReducer);
    const { updateOccupationFlag } = useSelector(
        (state) => state.OnboardingReducer
    );
    const { onBoradingStatusDataFlag } = useSelector(
        (state) => state.OnboardingReducer
    );

    const [value, setValue] = useState([]);
    const handleOptionChange = (event, newValue) => {
        setValue(newValue);
    };

    const { stripeOnboardingQueryData, initStripeOnboardingData } = StripeOnboardingMutationAction();
    const [businessName, setBusinessName] = useState("");

    const handleStepClick = (index, item) => {
        if (index == 0) {
            setOccupation(true);
        } else if (index === 1) {
            window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/clinic/create?source=onboarding`;
        } else if (index === 2) {
            window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/treatment/create?source=onboarding`;
        } else if (index === 3) {
            // window.location.href = item?.user_onboarding_link;
            // setLoadingStripeExpressLink(true);
            // dispatch(connectStripeExpress({}, (response) => {
            //     setLoadingStripeExpressLink(false)
            //     if (response?.success) {
            //         window.location.href = response?.data?.link;
            //     }
            //     else {
            //         if(!response?.message || response?.message == 'Something went wrong') {
            //             toast.error("Something went wrong while creating your payment link, please try again after some time!");
            //         }
            //         else {
            //             toast.error(response?.message);
            //         }                    
            //     }
            // }, (error) => {
            //     setLoadingStripeExpressLink(false)
            //     toast.error("Something went wrong while creating your payment link, please try again after some time!");
            // }))

            dispatch({
                type: CONNECT_STRIPE_EXPRESS
            });
            setLoadingStripeExpressLink(true);
            // dispatch(connectStripeExpress({}, (response) => {
            //     setLoadingStripeExpressLink(false)
            //     if (response?.success) {
            //         window.location.href = response?.data?.link;
            //     }
            //     else {
            //         if (!response?.message || response?.message == 'Something went wrong') {
            //             toast.error("Something went wrong while creating your payment link, please try again after some time!");
            //         }
            //         else {
            //             toast.error(response?.message);
            //         }
            //     }
            // }, (error) => {
            //     setLoadingStripeExpressLink(false)
            //     toast.error("Something went wrong while creating your payment link, please try again after some time!");
            // }))
            initStripeOnboardingData({},
                (response) => {
                    setLoadingStripeExpressLink(false)
                    window.location.href = response?.data?.stripeOnboarding?.data?.account_link;
                },
                (error) => {
                    setLoadingStripeExpressLink(false)
                    toast.error("Something went wrong while creating your payment link, please try again after some time!");
                });
            dispatch(
                connectStripeExpress(
                    {},
                    (response) => {
                        setLoadingStripeExpressLink(false);
                        if (response?.success) {
                            window.location.href = response?.data?.link;
                        } else {
                            if (
                                !response?.message ||
                                response?.message == "Something went wrong"
                            ) {
                                toast.error(
                                    "Something went wrong while creating your payment link, please try again after some time!"
                                );
                            } else {
                                toast.error(response?.message);
                            }
                        }
                    },
                    (error) => {
                        setLoadingStripeExpressLink(false);
                        toast.error(
                            "Something went wrong while creating your payment link, please try again after some time!"
                        );
                    }
                )
            );
        }
    };

    const { updateOccupationQueryData, initUpdateOccupationData } = UpdateOccupationMutationAction();

    const handleSubmit = (e) => {
        debugger;
        e.preventDefault();
        if (value?.length == 0) {
            setOccupationError("please select occupations");
            return;
        } else {
            setOccupationError(null);
        }
        if (businessName?.length == 0) {
            setbusinessNameError("BusinessName is required.");
            return;
        } else {
            setbusinessNameError(null);
        }

        // dispatch(updateOccupations({ occupation_ids: value.map(e => e.occupation_id) }))

        dispatch({
            type: UPDATE_OCCUPATIONS
        });
        initUpdateOccupationData({
            occupation: value.map(e => e.occupation_id),
            companyName: businessName,
        });
        // here        
    }

    // dispatch(
    //   updateOccupations({
    //     occupation_ids: value.map((e) => e.occupation_id),
    //     company_name: businessName,
    //   })
    // );


    // OnboardingReducer
    const { getUserOnboardingStatusQueryData, initGetUserOnboardingStatusData } = GetUserOnboardingStatusAction();

    useEffect(() => {
        dispatch({
            type: GET_ONBOARDING_STATUS
        });
        initGetUserOnboardingStatusData({ platform: "web" });
        // dispatch(getOnboardingStatus({ platform: "frontend" }))
    }, []);
    // here

    useEffect(() => {
        if (occupationData != null) {
            dispatch({ type: CLEAR_UPDATE_OCCUPATIONS })
            setOccupation(false)
            setValue([])
            // dispatch(getOnboardingStatus({ platform: "frontend" }))
            dispatch({
                type: GET_ONBOARDING_STATUS
            });
            initGetUserOnboardingStatusData({ platform: "web" });
            // here
            // dispatch(getOnboardingStatus({ platform: "frontend" }));
        }
    }, []);

    useEffect(() => {
        console.log("occupationData", occupationData, updateOccupationFlag);
        if (occupationData != null) {
            dispatch({ type: CLEAR_UPDATE_OCCUPATIONS });
            setOccupation(false);
            setValue([]);
            // dispatch(getOnboardingStatus({ platform: "frontend" }));
            initGetUserOnboardingStatusData({ platform: "web" });
        }
    }, [occupationData, updateOccupationFlag]);

    useEffect(() => {
        if (onboardingData) {
            let optionList = onboardingData?.occupation_list
                .map((e) =>
                    e.occupations.map((i) => {
                        console.log("occupation list", i.occupation_name)
                        return {
                            ...i,
                            category: e.category,
                            title: i.occupation_name,
                        };
                    })
                )
                .flat();
            console.log("optionList", optionList)

            setOptionValue(optionList);
        }
    }, [onboardingData]);

    return (
        <>
            <div className="container-fluid">
                <div className="text-end icon-close-button">
                    <img
                        src={closeIcon}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onClick={() => navigate(businessDashboardRoutePattern)}
                    />
                </div>
                <div className="row">
                    <div className="m-auto w-40 col-lg-4">
                        <div className="start-heading text-start">
                            <h4>👋 Online booking made easy!</h4>
                            <p>
                                Start building your online profile to take
                                bookings.
                            </p>
                        </div>
                        {onBoradingStatusDataFlag
                            ? [...Array(4)].map((i) => (
                                <div
                                    className={`treatment-card' gap-5 d-flex text-start border p-3 my-3`}
                                >
                                    <div className="icon-image-div">
                                        <Skeleton
                                            circle
                                            width={50}
                                            height={50}
                                        />
                                    </div>
                                    <div>
                                        <Skeleton height={20} width={150} />
                                        <Skeleton height={60} width={250} />
                                        <Skeleton height={30} width={100} />
                                    </div>
                                </div>
                            ))
                            : onboardingData?.steps?.map((item, index) => (
                                <div
                                    className={`${!item?.is_accessible
                                        ? "treatment-card"
                                        : "location-card"
                                        } gap-5 d-flex text-start`}
                                    onClick={() => {
                                        if (!item?.is_completed &&
                                            item?.is_accessible) {
                                            handleStepClick(index, item)
                                        }
                                    }} style={{ cursor: 'pointer' }}
                                >
                                    <div className="icon-image-div">
                                        <img
                                            src={
                                                item?.is_completed
                                                    ? checkFill
                                                    : item?.key == "clinic"
                                                        ? locationIcon
                                                        : item?.key ==
                                                            "occupation"
                                                            ? locationIcon
                                                            : item?.key == "treatment"
                                                                ? icon1
                                                                : item?.key == "payment"
                                                                    ? icon2
                                                                    : null
                                            }
                                            alt=""
                                        />
                                        {/* <img src={index === 0 ? locationIcon : (index === 1 ? icon1 : (index === 2 && icon2))} alt="" /> */}
                                    </div>
                                    <div>
                                        <p className="fw-bold my-auto">
                                            {item?.title}
                                        </p>
                                        <p className="my-auto">
                                            {item?.description}
                                        </p>
                                        {!item?.is_completed &&
                                            item?.is_accessible ? (
                                            <div className="mt-3">
                                                <CustomButton
                                                    disabled={
                                                        !item?.is_accessible
                                                    }
                                                    loading={
                                                        item?.key == "payment"
                                                            ? loadingStripeExpressLink
                                                            : false
                                                    }
                                                    onClick={() => {
                                                        handleStepClick(
                                                            index,
                                                            item
                                                        );
                                                    }}
                                                    className={"blueButton"}
                                                    label={
                                                        item?.key ==
                                                            "payment" &&
                                                            loadingStripeExpressLink ? (
                                                            <div class="payment-spinner-loader"></div>
                                                        ) : (
                                                            "Continue"
                                                        )
                                                    }
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            ))}

                        <AiAssistantContainer isDisableButton={true} isShowLock={true} />

                    </div >
                </div >
            </div >

            <CustomModal
                modalOpen={occupation}
                setModalOpen={setOccupation}
                modaltitle={"Verify your profile"}
                modalBody={
                    <>
                        <div className="occupation-input-div mt-4 ">
                            <form
                                className="d-flex flex-column"
                                onSubmit={handleSubmit}
                            >
                                <label
                                    htmlFor="occupation"
                                    className="fw-light"
                                >
                                    Occupation
                                </label>
                                {
                                    optionValue.length != 0 && (
                                        <CustomAutoComplete
                                            multiple={true}
                                            groupBy={true}
                                            optionValue={optionValue}
                                            value={value}
                                            setValue={setValue}
                                            handleOptionChange={handleOptionChange}
                                        />
                                    )
                                }
                                {occupationError != null && (
                                    <span className="text-danger">
                                        {occupationError}
                                    </span>
                                )}
                                <label
                                    htmlFor="occupation"
                                    className="fw-light"
                                >
                                    Business Name
                                </label>
                                <input
                                    className="input"
                                    type="text"
                                    name="businessName"
                                    id="businessName"
                                    placeholder="Business Name"
                                    value={businessName}
                                    onChange={(e) => {
                                        validName.test(e.target.value) &&
                                            setBusinessName(e.target.value);
                                    }}
                                />
                                {businessNameError != null && (
                                    <span className="text-danger">
                                        {businessNameError}
                                    </span>
                                )}
                                <button className="occupation-submit-btn">
                                    <div className="d-flex justify-content-center align-items-center gap-2  ">
                                        {updateOccupationFlag ? (
                                            <div class="payment-spinner-loader"></div>
                                        ) : (
                                            "Submit"
                                        )}
                                    </div>
                                </button>
                            </form>
                        </div>
                    </>
                }
            />
        </>
    );
};

export default GetStarted;
