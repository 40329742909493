import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const CREATE_NEW_APPOINTMENT_MUTATION = gql`
mutation CreateOrUpdateAppointment($clientId: Int!, $appointmentDate: String!, $appointmentStartTime: String!, $appointmentEndTime: String!, $clinicId: Int, $appointmentId: Int, $treatmentData: [treatmentData], $consentFormIds: [Int], $aftercareDocumentIds: [Int], $holdDuration: Int, $followUps: Int, $followUpsType: Int, $textReminders: Boolean, $emailReminders: Boolean, $collectPayment: Boolean, $payMonthly: Boolean, $subTotal: Float, $discountCodeType: discountCodeType, $discountValue: Float, $depositAmount: Float, $signature: Upload, $totalAmount: Float, $signatureBase64: String, $rebook: Boolean, $sendMedicalForm: Boolean, $smsBalanceAvailable: Boolean, $paymentType: Int) {
  createOrUpdateAppointment(clientId: $clientId, appointmentDate: $appointmentDate, appointmentStartTime: $appointmentStartTime, appointmentEndTime: $appointmentEndTime, clinicId: $clinicId, appointmentId: $appointmentId, treatmentData: $treatmentData, consentFormIds: $consentFormIds, aftercareDocumentIds: $aftercareDocumentIds, holdDuration: $holdDuration, followUps: $followUps, followUpsType: $followUpsType, textReminders: $textReminders, emailReminders: $emailReminders, collectPayment: $collectPayment, payMonthly: $payMonthly, subTotal: $subTotal, discountCodeType: $discountCodeType, discountValue: $discountValue, depositAmount: $depositAmount, signature: $signature, totalAmount: $totalAmount, signatureBase64: $signatureBase64, rebook: $rebook, sendMedicalForm: $sendMedicalForm, smsBalanceAvailable: $smsBalanceAvailable, paymentType: $paymentType) {
    success
    message
    data {
      appointmentId
      consentFormRecordId
    }
  }
}
`;

export const CreateNewAppointmentMutation = () => {
  const [createNewAppointmentRequest, createNewAppointmentResponseData] =
    useMutation(CREATE_NEW_APPOINTMENT_MUTATION);
  const dispatch = useDispatch();
  const createNewAppointmentMutationData = createNewAppointmentResponseData;

  const initiateCreateNewAppointmentResponseData = (
    data,
    successCallback,
    errorCallback
  ) => {
    createNewAppointmentRequest({
      variables: data,
    })
      .then(({ data: { createOrUpdateAppointment: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateCreateNewAppointmentResponseData, createNewAppointmentMutationData };
};


// ========================SAVE BUSINESS BOOSTERS DETAILS========================

const SAVE_BUSINES_BOOSTERS_DETAILS_MUTATION = gql`
mutation SaveBusinessBoostersDetails($appointmentId: Int, $flagType: flagType, $flagValue: Boolean, $smsBalanceAvailable: Boolean) {
  saveBusinessBoostersDetails(appointmentId: $appointmentId, flagType: $flagType, flagValue: $flagValue, smsBalanceAvailable: $smsBalanceAvailable) {
    success
    message
  }
}
`;


export const SaveBusinessBoostersDetailsAction = () => {
  const dispatch = useDispatch();
  const [
    SaveBusinessBoostersDetailsResponseFunction,
    saveBusinessBoostersDetailsResponseData,
  ] = useMutation(SAVE_BUSINES_BOOSTERS_DETAILS_MUTATION)

  //this will be called from components
  const saveBusinessBoostersDetailsQueryData = saveBusinessBoostersDetailsResponseData; //variable

  const initSaveBusinessBoostersDetailsData = (data, successCallback, errorCallback) => {
    SaveBusinessBoostersDetailsResponseFunction({
      variables: data,
    }).then(({ data: { saveBusinessBoostersDetails: responseData } }) => {
      if (responseData.success) {
        if (successCallback)
          successCallback(responseData)
        //  toast.success(responseData.message)
      }
      else {
        if (errorCallback)
          errorCallback()
        // toast.error(responseData.message)
      }
    }).catch(() => {
      if (errorCallback)
        errorCallback()
      // toast.error("something went wrong!!!")
    })
  }

  return { saveBusinessBoostersDetailsQueryData, initSaveBusinessBoostersDetailsData };
};

// ========================STRIPE ONBOARDING========================

const STRIPE_ONBOARDING_MUTATION = gql`
mutation StripeOnboarding {
  stripeOnboarding {
    success
    message
    data {
      stripe_acc_id
      account_link
    }
  }
}
`;


export const StripeOnboardingAction = () => {
  const dispatch = useDispatch();
  const [
    StripeOnboardingResponseFunction,
    stripeOnboardingResponseData,
  ] = useMutation(STRIPE_ONBOARDING_MUTATION)

  //this will be called from components
  const stripeOnboardingQueryData = stripeOnboardingResponseData; //variable

  const initStripeOnboardingData = (data, successCallback, errorCallback) => {
    StripeOnboardingResponseFunction({
      variables: data,
    }).then(({ data: { stripeOnboarding: responseData } }) => {
      if (responseData.success) {
        if (successCallback)
          successCallback(responseData)
        //  toast.success(responseData.message)
      }
      else {
        if (errorCallback)
          errorCallback()
        // toast.error(responseData.message)
      }
    }).catch(() => {
      if (errorCallback)
        errorCallback()
      // toast.error("something went wrong!!!")
    })
  }

  return { stripeOnboardingQueryData, initStripeOnboardingData };
};



