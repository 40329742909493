import React, { useState } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import './header.scss'
import { ReactComponent as FacesLogo } from "../../../images/Faces_logo.svg";
import { ReactComponent as DownArrow } from "../../../images/header_arrow.svg";
import { ReactComponent as IoNotificationsOutline } from "../../../images/icons/notification_icon.svg";
import { ReactComponent as IoBasketOutline } from "../../../images/icons/basket_icon.svg";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { logoutProfile } from "../../redux/actions";
import Skeleton from "react-loading-skeleton";
import { businessDashboardRoute } from "../../../Routes";
import Avatar from "react-avatar";

const Header = () => {

  const dispatch = useDispatch();
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  function stringAvatar(name) {
    if (typeof name === "string") {
      return {
        children: name,
      };
    } else {
      // Handle the case where name is not a string, e.g., provide a default value.
      return {
        children: "N/A",
      };
    }
  }

  const { profileData } = useSelector(state => state?.GetProfileDataReducer)

  console.log(profileData, "profileData")


  return (
    // <div className="container-fluid position-sticky">
    <div className="headerMain customeHeader">
      <Navbar>

        <Navbar.Brand>

        </Navbar.Brand>

        <Nav
          className="w-100 align-items-center justify-content-md-end justify-content-center "

          onSelect={(selectedKey) => { }}
        >
          <Container fluid className="p-0">
            <Row className="w-100 align-items-center justify-content-between">
              <Col lg="8">
                <Link to={businessDashboardRoute()}>
                  <FacesLogo />
                </Link>
              </Col>
              <Col lg="4" className="menulink menuOrder text-start justify-content-end text-md-end p-0 d-flex gap-5">
                <div className="d-flex align-items-center gap-3">
                  <Nav.Item className="cursor-pointer">
                    <IoNotificationsOutline size={20} />
                  </Nav.Item>

                  <Nav.Item>
                    <Link>
                      <IoBasketOutline size={20} />
                    </Link>
                  </Nav.Item>

                  {/* <Nav.Item>
                    <UserCircle />
                  </Nav.Item> */}
                </div>

                {
                  profileData == null ?
                    <div className="user-section gap-2" onClick={() => setProfileMenuOpen(!profileMenuOpen)}>
                      <Skeleton circle={true} height={40} width={40} />
                      <h1><Skeleton width={100} /></h1>
                      <DownArrow />
                    </div>

                    :
                    <div className="user-section gap-2" onClick={() => setProfileMenuOpen(!profileMenuOpen)}>
                      {/* <Avatar
                        className="string-avatar-header"
                        src={profileData?.profile_pic}
                        {...stringAvatar(`${profileData?.first_name}${profileData?.last_name}`)}

                      /> */}
                      <Avatar name={`${profileData?.first_name +" "+ profileData?.last_name}`} src={profileData?.profile_pic} round={true} color="#EEEFF3" fgColor="#000" size={40} maxInitials={2} textSizeRatio={1.5}/>
                      <h1>{profileData?.company_name}</h1>
                      <DownArrow />
                    </div>
                }
              </Col>
              {profileMenuOpen ? (
                <OutsideClickHandler
                  onOutsideClick={() => setProfileMenuOpen(!profileMenuOpen)}
                >
                  <Row className="dropdownMenu">
                    <Col xs={12}>
                      <a onClick={() => { dispatch(logoutProfile()); setProfileMenuOpen(false) }}>
                        Logout
                      </a>
                    </Col>
                  </Row>
                </OutsideClickHandler>
              ) : null}
            </Row>
          </Container>
        </Nav>

      </Navbar>
    </div>
    // </div>
  );
};

export default Header;
