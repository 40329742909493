import { GET_FACES_AI_ASSISSTANT_STATUS } from "../../constants";



const initialState = {
  faces_onboarding_status: null
}

export function FacesAiReducer(state = initialState, action) {

  switch (action.type) {

    case GET_FACES_AI_ASSISSTANT_STATUS:
      return {
        ...state,
        faces_onboarding_status: action.payload,
      };

    default:
      return state;
  }



}