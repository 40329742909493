import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_CONSENT_FORM_PRODUCT_LIST } from "../../constants/consentFormThankyou/consentFormProductConst";
import { GENERATE_BOOKING_REVIEW } from "../../constants/consentFormThankyou/generateBookingReviewConst";

const GENERATE_BOOKING_REVIEW_QUERY = gql`
query GenerateBookingReviewContent($bookingId: Int!, $rating: Float!, $type: Int) {
    generateBookingReviewContent(booking_id: $bookingId, rating: $rating, type: $type) {
      success
      message
      data {
        reviewContent
      }
    }
  }
  `;

// var page;
export const GenerateBookingReviewQuery = () => {
    const dispatch = useDispatch();
    const [getGenerateBookingReview, generateBookingReviewData] = useLazyQuery(
        GENERATE_BOOKING_REVIEW_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ generateBookingReviewContent: responseData }) => {

                dispatch({
                    type: GENERATE_BOOKING_REVIEW,
                    payload: responseData?.data,
                    // page
                });
                if (responseData.success) {

                } else {

                }


            },
        }
    );
    const generateBookingReviewQueryData = generateBookingReviewData;
    const initGenerateBookingReview = (data,successCallBack,errorCallBack) => {
        // page = data.page
        getGenerateBookingReview({
            variables: data,
        }).then((res)=>{
            successCallBack(res?.data?.generateBookingReviewContent)
        }).catch((err)=>{
            errorCallBack(err)
        })
    };
    return { initGenerateBookingReview, generateBookingReviewQueryData };
};



