import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";


const DELETE_CLIENT_MUTATION = gql`
mutation DeleteClient($clientId: Int!) {
    deleteClient(clientId: $clientId) {
      success
      message
    }
  }
`;

export const DeleteClientMutation = () => {
  const [deleteClient, deleteClientResponseData] =
    useMutation(DELETE_CLIENT_MUTATION);
  const dispatch = useDispatch();
  const deleteClientMutationData = deleteClientResponseData;

  const initiateDeleteClient= (
    data,
    successCallback,
    errorCallback
  ) => {
    deleteClient({
      variables: data,
    })
      .then(({ data: { deleteClient: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };
  return { initiateDeleteClient, deleteClientMutationData };
};