import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const UPDATE_CLIENT_DETAILS_FORWARD_CONSENT_FORM_MUTATION = gql`
mutation UpdateClientDetailsConsentForm($cfrId: Int, $token: String, $clientName: String, $email: String, $phoneCountry: String, $phone: String, $street: String, $houseNumber: String, $city: String, $postCode: String, $country: String, $dob: String, $appointmentId: Int) {
  updateClientDetailsConsentForm(cfrId: $cfrId, token: $token, clientName: $clientName, email: $email, phoneCountry: $phoneCountry, phone: $phone, street: $street, houseNumber: $houseNumber, city: $city, postCode: $postCode, country: $country, dob: $dob, appointmentId: $appointmentId) {
    success
    message
    data {
      phoneCountry
      phone
      street
      houseNumber
      city
      postCode
      country
      dob
      isClient
    }
  }
}
`;

export const UpdateClientDetailsForwardConsentFormMutation = () => {
    const [updateClientDetailsForwardConsentFormRequest, updateClientDetailsForwardConsentFormResponseData] =
        useMutation(UPDATE_CLIENT_DETAILS_FORWARD_CONSENT_FORM_MUTATION);
    const dispatch = useDispatch();
    const updateClientDetailsForwardConsentFormData = updateClientDetailsForwardConsentFormResponseData;

    const initiateUpdateClientDetailsForwardConsentForm = (
        data,
        successCallback,
        errorCallback
    ) => {
        updateClientDetailsForwardConsentFormRequest({
            variables: data,
        })
            .then(({ data: { updateClientDetailsConsentForm: responseData } }) => {
                if (responseData) {
                    successCallback(responseData);
                } else {
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                errorCallback(error);
            });
    };
    return { initiateUpdateClientDetailsForwardConsentForm, updateClientDetailsForwardConsentFormData };
};
