import React, { useState } from "react";
import "./ChoosePlan.scss";
import { useEffect } from "react";
import BusinessNavigation from "../../../../../common/businessNavigation/BusinessNavigation";
import StepButton from "../../../../../common/StepButton";
import Payment from "../payment/Payment";
import { useMarketingSMSChargeMutation } from "../../../../../redux/actions/sms-plans-and-payment/MarketingSMSCharge";
import { GetMarketingSMSDetailsAction } from "../../../../../redux/actions/GetMarketingSMSDetails/GetMarketingSMSDetails";
import { toast } from "react-toastify";
import Slider from "react-slick";
import { ReactComponent as CheckIcon } from "../../../../../../images/sprint6/check.svg";
// import { StripeComponent } from "../../../../stripe-payment/stripe-component";
import { StripeComponent } from "../../../../stripe-payment/stripe-component";
import "../Sms.scss";
import { useGetStaticAmountPaymentTokenAction } from "../../../../../redux/actions/sms-plans-and-payment/PaymentToken";

function ChoosePlan({ setParentStep }) {
  const [purchaseBoxData, setpurchaseBoxData] = useState([]);
  const [step, setStep] = useState(0);
  const [process, setProcess] = useState("Place Order");
  const [indexValue, setIndexvalue] = useState();
  const [planDetails, setPlandetails] = useState()
  const [paymentToken, setPaymenttoken] = useState()
  const { initGetMarketingSMSDetails } = GetMarketingSMSDetailsAction();
  const { initStaticAmountPaymentTokenData } = useGetStaticAmountPaymentTokenAction();
  const { initiateMarketingSMSCharge } = useMarketingSMSChargeMutation();
  useEffect(() => { initGetMarketingSMSDetails({ isOnlyCredit: false }, (res) => { setpurchaseBoxData(res?.data?.planData); console.log(res, "dsssds") }, (err) => { console.log(err) }) }, [])





  const stripePaymenttoken = (props) => {
    setPlandetails(props)
    const { planTitle } = props;

    initStaticAmountPaymentTokenData({ selectedPlan: planTitle?.toUpperCase() }, (res) => { setPaymenttoken(res?.payment_session) }, (err) => { })
  }

  const renderTabContent = () => {
    if (step == 1) {
      return <Payment setStep={setStep} />;
    }

  };
  var settings = {
    dots: false,
    initialSlide: 0,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 1,
    // centerMode: true,
  };
  const paymentIntentSuccessCallback = (id) => {
    initiateMarketingSMSCharge({ paymentId: id }, (res) => { toast.success(res?.message); setParentStep(0); }, (err) => { toast.error(err?.message) })

  }

  console.log(purchaseBoxData, "item?.descriptionData")
  return (

    <div className="Main-Buy-Now">

      {step > 0 ? (
        renderTabContent()
      ) : (
        <div className="rightSidebarWrapper">
          <div className="Main684WidthSet">
            <BusinessNavigation
              isColumn={true}
              title={"Send promo sms to your clients"}
              desc={"Send client reminders and promo text messages!"}
              onBackClick={() => {
                setParentStep(0);
              }}
            />
            <div className="purchaseBoxWrapper">
              <Slider {...settings}>
                {purchaseBoxData?.map((item, index) => {
                  return (

                    <div>
                      <div className="purchaseBox" key={index}>
                        <div className="purchaseTopWrapper">
                          <div className="TagWRapper">
                            <h5>{item?.planTitle}</h5>
                            {item?.planTitle === "Pro" && <span>Popular</span>}
                          </div>
                          <div className="prisingBox">
                            <h6>£ {item?.amount}</h6> <span>/ month</span>
                          </div>
                          <div className="textes">
                            <p>Text:</p>
                            <p>{item?.textBalance}</p>
                          </div>
                        </div>
                        <div className="purchaseBottomWrapper">
                          <ul>
                            {item?.descriptionData?.map((val) => {
                              return <li>{val}</li>;
                            })}
                          </ul>
                        </div>
                        <div className="purchasefooterWrapper" onClick={() => { setIndexvalue(index); stripePaymenttoken(item) }}>
                          <StepButton
                            className={index === indexValue ? "fillBtn" : "outline"}
                            label={index === indexValue ? "Selected" : " Get Now"}

                          />
                        </div>
                      </div>
                    </div>

                  );
                })}
              </Slider>
            </div>
            {/* <div className="bottomBtn">
                    <StepButton
                        label="Next"
                        onClick={() => {
                            setStep(1);
                        }}
                    />
                </div> */}
            <div className="Main684WidthSet">
              {/* <BusinessNavigation
                    isColumn={true}
                    title={"Send promo sms to your clients"}
                    desc={"Send client reminders and promo text messages!"}
                    onBackClick={() => {
                      setStep(0);
                    }}
                  /> */}

              {planDetails && <div className="CardDivWrapper">
                <label className="form-label">Card number</label>
                <StripeComponent
                  clientSecret={paymentToken}
                  paymentType="card"
                  successCallback={paymentIntentSuccessCallback}
                  failureCallback={(err) => { console.log(err, "errr") }}
                  isCloseIcon={false}
                  stripeChildCustomCSS={"w-100"}
                  setProcess={setProcess}
                  // useSetupIntentFlow={true}
                  useSetupIntentFlow={false}
                  customButton={
                    <div className="continue-btn-div marketing_sms_sprint6_placeorder_wrapper">
                      <StepButton label={process} blue={true} className={"marketing_sms_sprint6_placeorder"} />
                    </div>
                  }
                  cardLogo={true}
                />

              </div>}


              {planDetails && <div className="cardContentWrapper">
                <div className="selectedPlan">
                  <span>
                    Selected plan is: <b>{planDetails?.planTitle}</b>
                  </span>
                </div>
                <p>
                  You are now purchasing {planDetails?.textBalance} SMS text messages for £ {planDetails?.amount}. Which can
                  be used for promotional text offers and client reminders.
                </p>
                <div className="btnWrapper">
                  {/* <StepButton className={"outline"} label={"Apple Pay"} /> */}
                  <StepButton label={"Pay"} onClick={() => { document.querySelector(".marketing_sms_sprint6_placeorder") && document.querySelector(".marketing_sms_sprint6_placeorder").click() }} />
                </div>
              </div>}

            </div>
          </div>
          {/* {planDetails && <div className="RightOverView">
              <div className="overHeading">
                <h5>Overview</h5>
              </div>
              <div className="PlanContent">
                <div className="PlanHeading">
                  <h6>{planDetails?.planTitle} plan</h6>
                  <span>
                    £{planDetails?.amount}<sub>/ month</sub>
                  </span>
                </div>
                <ul className="PlanPointsList">
                  <li>
                    <span className="pointIcon">
                      <CheckIcon />
                    </span>
                    <span>Everything in free</span>
                  </li>
                  <li>
                    <span className="pointIcon">
                      <CheckIcon />
                    </span>
                    <span>Custom domain</span>
                  </li>

                  <li>
                    <span className="pointIcon">
                      <CheckIcon />
                    </span>
                    <span>Logo design*</span>
                  </li>
                  <li>
                    <span className="pointIcon">
                      <CheckIcon />
                    </span>
                    <span>Perfect for training academies </span>
                  </li>
                  <li>
                    <span className="pointIcon">
                      <CheckIcon />
                    </span>
                    <span>Selling products</span>
                  </li>
                  <li>
                    <span className="pointIcon">
                      <CheckIcon />
                    </span>
                    <span>express support</span>
                  </li>
                  <li>
                    <span className="pointIcon">
                      <CheckIcon />
                    </span>
                    <span>Free marketing tool (GHL)</span>
                  </li>
                </ul>
              </div>
            </div>}   */}
        </div>
      )}
    </div>
  );
}

export default ChoosePlan;
