import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_ONBOARDING_INFO_FOR_FINANCE_QUERY = gql`
  query GetOnboardingInfoForFinance {
    getOnboardingInfoForFinance {
      success
      message
      data {
        basic_information_completed
        documents_uploaded
        bank_details_added
        card_linked
        admin_approved
        admin_rejected
        rejection_reason
        finance_loan_request_status {
          flr_id
          finance_loan_request_status
        }
      }
    }
  }
`;

export const GetOnboardingInfoForFinanceAction = () => {
  const [
    getOnboardingInfoForFinanceResponseFunction,
    getOnboardingInfoForFinanceResponseData,
  ] = useLazyQuery(GET_ONBOARDING_INFO_FOR_FINANCE_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: ({ getOnboardingInfoForFinance: responseData }) => {},
  });

  const getOnboardingInfoForFinanceQueryData =
    getOnboardingInfoForFinanceResponseData;

  const initGetOnboardingInfoForFinanceData = (
    data,
    successCallback,
    errorCallback
  ) => {
    getOnboardingInfoForFinanceResponseFunction({
      variables: data,
    })
      .then((responseData) => {
        if (responseData?.data?.getOnboardingInfoForFinance?.success) {
          successCallback(responseData?.data?.getOnboardingInfoForFinance);
        } else {
          errorCallback(responseData?.data?.getOnboardingInfoForFinance);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  return {
    getOnboardingInfoForFinanceQueryData,
    initGetOnboardingInfoForFinanceData,
  };
};
