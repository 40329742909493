import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../../../images/back_arrow.svg";
import { ReactComponent as Uploads } from "../../../images/Image.svg";
import "./finance-insurance.scss";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router";
import {
  FinanceGetPaidRoute,
  FinanceTrainingCertificateRoute,
} from "../../../Routes";
import shieldIcon from "../../../images/starinshield.svg";
import calander from "../../../images/calander-plus.svg";
import nextIcon from "../../../images/calander_next.svg";
import CustomCalendar from "../dateTime/steps/CustomCalendar";
import moment from "moment";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import CustomModal from "../../modal/CustomModal";
import ConfirmationModal from "../finance-identity/finance-confirmationModal";
import FinanceBanner from "../../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner";
import FinanceHeader from "../../finance-registration/FinanceHomeComponent/FinanceHeaderComponent/FinanceHeaderComponent";
import { IoEyeSharp } from "react-icons/io5";
import StepButton from "../../common/StepButton";
import { SaveFinanceInsuranceDetailsAction } from "../../redux/actions/finance-registrtion/saveFinanceInsurancedetails"
import { GetFinanceInsuranceDetailAction } from "../../redux/actions/finance-registrtion/getFinanceInsurancedetail"
import { DeleteFinanceInsurancedetailAction } from "../../redux/actions/finance-registrtion/deleteFinanceInsurancedetail"
import Dropzone from "../../dropzone/dropzone"
import TextInput from "../../common/TextInput";
// import CustomModal from "../../modal/CustomModal"
import { insuranceLandingPage } from "../../../Routes"
import CustomDropdown from "../../common/dropdown/CustomDropdown"
import { GetInsuranceOptionAction } from "../../redux/actions/getInsuranceOption/getInsuranceOption"
import { ToastContainer, toast } from 'react-toastify';
import { GetFinanceRegistrationStatusAction } from "../../redux/actions/finance-registrtion/getFinanceRegistartionStatus"


export default function FinanceInsurance({ onNextStep, afterSubmit, type, finish,setComeFromDashBord }) {

  const { getInsuranceOptionQueryData, initGetInsuranceOptionData } = GetInsuranceOptionAction()
  const dropDownOption = getInsuranceOptionQueryData?.data?.getInsuranceOptions?.data;

  const { getFinanceRegistrtionStatusQueryData, initGetFinanceRegistrationData } = GetFinanceRegistrationStatusAction()
  useEffect(() => {
    initGetFinanceRegistrationData()
  }, [])

  const completedStep = getFinanceRegistrtionStatusQueryData?.data?.getUserRegistrationStatusForFinance?.data;
  console.log("completedStep2", completedStep);

  console.log("dropDownOption", dropDownOption)
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const insuranceProvider = dropDownOption?.options

  const [insuranceProviderName, setInsuranceProviderName] = useState({
    insuranceCompnay: "",
    otherInsuranceCompnay: ""
  })
  const [insuranceProviderError, setInsuranceProviderError] = useState({
    insuranceCompnay: "",
    otherInsuranceCompnay: ""
  })
  const [previewImg, setPreviewImg] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [otherDocs, setOtherDocs] = useState([
    {
      image: "",
      document_url: "",
      document_title: ""
    },
    {
      image: "",
      document_url: "",
      document_title: ""
    },
    {
      image: "",
      document_url: "",
      document_title: ""
    },
  ]);
  const [insurancePolicyNumber, setInsurancePolicyNumber] = useState("")
  const [getApiData, setGetApiData] = useState([])
  const [isDocError, setIsDocError] = useState(false)
  const [isData, setIsData] = useState(false)
  const [apiFormattedDate, setapiFormattedDate] = useState("")
  const { saveFinanceInsuranceDetailQueryData, initsaveFinanceInsuranceDetailData } = SaveFinanceInsuranceDetailsAction()
  const { getFinanceInsuranceDetailQueryData, initGetFinanceInsuranceDetailData } = GetFinanceInsuranceDetailAction()
  const { deleteFinanceInsuranceDetailQueryData, initDeleteFinanceInsuranceDetailData } = DeleteFinanceInsurancedetailAction()
  const [isFormatedDate, setIsFormatedDate] = useState(false)
  const [ErrorMsg, setErrorMsg] = useState("")
  const { data } = getFinanceInsuranceDetailQueryData


  useEffect(() => {
    initGetInsuranceOptionData()
  }, [])

  useEffect(() => {
    initGetFinanceInsuranceDetailData()
  }, [])

  const [showCalendar, setShowCalendar] = useState(false);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const removeImage = () => {
    setUploadedImage(null);
  };

  useEffect(()=>{
    window.scrollTo(0, 0);
},[])

  const handleContinue = () => {



    // if(insuranceProviderName?.insuranceCompnay==="Other" && !insuranceProviderName?.otherInsuranceCompnay){
    //   errors.otherInsuranceCompnay="Please enter the insurance company"
    // }



    let certificate
    {
      certificate = otherDocs.filter((file) => {

        if (file.image || file.id) {
          return (true)
        }
      }).map(file => {
        if (type == "insurance") {
          return {
            photoId: file.id ? file.id : 0,
            photoTitle: file.document_title,
            photo: file.image,
          }
        }
        else {
          return file.image
        }
      })
    }
    const hasDocument = otherDocs && otherDocs?.some((doc) => doc && doc?.image || doc?.document_url)
    const invaildDocs = otherDocs?.filter(
      (doc) => (doc?.image || doc?.document_url) && !doc?.document_title)

   
    if(!insuranceProviderName?.insuranceCompnay){
      toast.error("Please enter the insurance company")
      return
    }
     else if (insuranceProviderName?.insuranceCompnay === "Other" && !insuranceProviderName?.otherInsuranceCompnay) {
      // setInsuranceProviderError({
      //   otherInsuranceCompnay: "Please enter the insurance company"
      // })
      toast.error("Please enter the insurance company")
      return
    } 
    else if (!expiryStartDate){
      toast.error("Please select date!")
    }
    else if (!hasDocument) {
      // setErrorMsg("Please upload insurance docs")
      toast.error("Please upload insurance docs!")
      return
      
    }
    // else if (invaildDocs?.length > 0) {
    //   toast.error("Please Add Title for All Certificate!")
    //   return;
    //   // setErrorMsg("Please Add Certificate Title!")
    // }
    else {
      initsaveFinanceInsuranceDetailData({
        expireDate: calendarStartDate,
        policyNo: insurancePolicyNumber,
        documentFiles: certificate,
        insuranceCompanyName: insuranceProviderName?.insuranceCompnay === "Other" ? insuranceProviderName?.otherInsuranceCompnay : insuranceProviderName?.insuranceCompnay
      }, (data) => {
        toast.success(data?.data?.saveInsuranceDetailsForFinance?.message)
        afterSubmit();
        onNextStep();
        setComeFromDashBord(false)
      }
        , (error) => console.log(error))
    }



  };

  const handleTitleChange = (e, index) => {
    const { name, value } = e.target;
    setOtherDocs((prevOtherDocs) => {
      const updatedDocs = [...prevOtherDocs];
      updatedDocs[index] = { ...updatedDocs[index], document_title: value };
      return updatedDocs;
    });
  };

  const handleAddDropzone = () => {
    if (otherDocs.length < 10) {
      const nextDocIndex = otherDocs.length + 1;
      const newDocumentState = {
        image: "",
        document_url: "",
        document_title: ""
      };
      setOtherDocs((prevOtherDocs) => [...prevOtherDocs, newDocumentState]);
      setIsData(true)
    }
  };

  const customStyle = {

    menuPortal: base => ({
      ...base,
      zIndex: 9999 // Adjust the z-index value according to your requirement
    }),
    control: (styles, state) => ({
      ...styles, backgroundColor: '#FFFFFF',
      outline: "none",
      appearance: "none",
      padding: "10px 10px",
      borderRadius: "10px",
      border: "1px solid #878787",
      height: "55px",
      width: "100%",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {

      return {
        ...styles,
      };
    },
  };

  useEffect(() => {
    if (otherDocs?.length < 1) {
      const emptyObjectsToAdd = 1 - otherDocs?.length;
      const emptyObjectsArray = Array.from(
        { length: emptyObjectsToAdd },
        () => ({})
      );
      setOtherDocs((prevOtherDocs) => [...prevOtherDocs, ...emptyObjectsArray]);
    }
  }, [otherDocs]);

  const handleCustomDropDown = (field, value, nestedField = null) => {
    setInsuranceProviderName((prevData) => {
      if (nestedField) {
        return {
          ...prevData,
          [field]: {
            ...prevData[field],
            [nestedField]: value
          }
        };
      }
      else {
        return {
          ...prevData,
          [field]: value
        };
      }
    })
  }


  const [showSelectedDate, setShowSelectedDate] = useState(
   null
  );

  const [calendarStartDate, setCalendarStartDate] = useState("");
  const [expiryStartDate,setExpiryStartDate]=useState(null)
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(
    selectedDate.getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
  const [formatedDate, setFormatedDate] = useState("");
  const [step, setStep] = useState("");
  const [showSelectedTime, setShowSelectedTime] = useState("");
  const [isOpenMobileCalendar, setIsOpenMobileCalendar] = useState(false);

  console.log("formatedDate", selectedDate)
  
  // const handleInsurancepage = () => {
  //   navigate(insuranceLandingPage)
  // }

  const handleSelectedDate = (selectedDate) => {
    setShowSelectedDate(selectedDate);
  };
  useEffect(() => {

    const value = data?.getInsuranceDetailsForFinance?.data
    console.log("getVal", value)
    if (data?.getInsuranceDetailsForFinance?.data) {
      // if (value?.documents?.length > 0 && Array.isArray(value?.documents)) {
      //   setOtherDocs(value?.documents);
      // }
      setOtherDocs(value?.documents);
      setInsurancePolicyNumber(data?.getInsuranceDetailsForFinance?.data?.policy_no)
      const currentYear = moment().year();
        const maxValidYear = moment().year() + 25;
        const ExpiryDate = moment(data?.getInsuranceDetailsForFinance?.data?.expire_date, "YYYY-MM-DD", true);
      if (data?.getInsuranceDetailsForFinance?.data?.expire_date) {
        
        if (
          ExpiryDate.isValid() &&
          ExpiryDate.year() >= currentYear &&
          ExpiryDate.year() <= maxValidYear
        )
        // setFormatedDate(moment(data?.getInsuranceDetailsForFinance?.data?.expire_date, "YYYY-MM-DD").format("D MMMM YYYY"))
        setShowSelectedDate(ExpiryDate.format("dddd, Do MMMM YYYY"))
        setSelectedMonth(ExpiryDate.month()+1)
        setSelectedYear(ExpiryDate.year())
        // setFormatedDate(ExpiryDate.format("D MMMM YYYY"));
      }

      if (
        ExpiryDate.isValid() &&
        ExpiryDate.year() <= maxValidYear
      ) {
        setFormatedDate(ExpiryDate.format("D MMMM YYYY"));
      }
      setExpiryStartDate(ExpiryDate.isValid() &&  ExpiryDate.year() <= maxValidYear ? ExpiryDate:"")

      if (insuranceProvider && (!insuranceProvider?.includes(value.insurance_company_name) || value.insurance_company_name == "Other")) {
        setInsuranceProviderName({
          otherInsuranceCompnay: data?.getInsuranceDetailsForFinance?.data?.insurance_company_name,
          insuranceCompnay: "Other"
        })
      } else {
        setInsuranceProviderName({
          insuranceCompnay: data?.getInsuranceDetailsForFinance?.data?.insurance_company_name
        })
      }



    }
    console.log("DDDDDD", otherDocs)
  }, [data?.getInsuranceDetailsForFinance?.data])
  // Function to generate an array of dates for a specified range
  function generateDateData(startDate, endDate) {
    const dates = [];
    const currentDate = new Date(startDate);
    // Loop to generate dates until the endDate is reached
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().slice(0, 10));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const today = new Date();
  const endDatee = new Date(today);
  endDatee.setFullYear(today.getFullYear() + 25);
  const dateData = generateDateData(today, endDatee);
  const formattedDate = moment(showSelectedDate, "dddd, Do MMMM YYYY").format("D MMMM YYYY");

  console.log("formattedDate", formattedDate)
  console.log("?????", showSelectedDate)

 
  useEffect(()=>{
    setExpiryStartDate(showSelectedDate, "dddd, Do MMMM YYYY")?.format("YYYY-MM-DD")
  },[showSelectedDate])

  return (
    <div className="Main_Insurance_Page">
      <div className="Main_Insurance_PagesecWrapper">
        <div className="whatsappwrapper">
          <div className="insurance-detail">
            <FinanceBanner
              title="Insurance details"
              desc="Adding your insurance details will also make you a verified business user. This sets a president as the industry standard for your clients."
              facesVarified={true}
              isBorder={!completedStep?.registration_complete && completedStep?.registration_complete !== undefined}
              currentStep={finish}
              totalSteps={4}
              isInsuranceBanner={true}
            />
            
            <div className="finance-input-container Custome-insurance-provider">
              {/* insurance provider */}
              <CustomDropdown
                labelName={"Insurance company"}
                // placeholdername={"Insurance company"}
                defaultValue={insuranceProviderName?.insuranceCompnay}
                dropdownId={"insuranceprovider"}
                optionsArr={insuranceProvider}
                style={customStyle}
                handleSelectedValue={(value) => handleCustomDropDown("insuranceCompnay", value)}
                readOnly={false}
              />
            </div>
            {
              insuranceProviderName?.insuranceCompnay === "Other" && (
                <div className="finance-input-container">
                  {/* add provider compnay mannually */}
                  <TextInput
                    label={"Insurance company"}
                    placeholder={"Insurance company"}
                    value={insuranceProviderName?.otherInsuranceCompnay}
                    onChange={(e) => handleCustomDropDown("otherInsuranceCompnay", e.target.value)}
                  />
                  {
                    insuranceProviderError?.otherInsuranceCompnay && <span className="error">{insuranceProviderError.otherInsuranceCompnay}</span>
                  }
                </div>
              )
            }
         <div className="calenderIconWrapper"
              onClick={() => {
                toggleCalendar();
              }}>
              <div className="calenderIconAndDateWrapper">
                <div className="calenderIcon">
                  <img src={calander} alt="calander" />
                </div>
                <div className="DateContent">
                  <h5>{expiryStartDate ? formattedDate : "Expiry date"}</h5>
                </div>
              </div>
              <div className="calenderRightIcon">
                <img src={nextIcon} alt="next" />
              </div>
            </div>
            <div className="Certificate-docmentation-Wrapper">
              <div className="form-label">Insurance certificate</div>
              <div className="docmentationGrid-Wrapper">

                {
                  otherDocs.map((doc, index) => (
                    <>
                      <div className="docmentationGridItem" key={index}>
                        <Dropzone
                          handleMulti={setOtherDocs}
                          selectedIndex={index}
                          urlFromDB={doc.document_url}
                          handleDelete={initDeleteFinanceInsuranceDetailData}
                          insuranceId={doc.id}
                          otherDocs={otherDocs}
                          imageFromComponent={doc.image}
                        />
                        <input
                          name={`doc_name${index + 1}`}
                          value={doc.document_title || ""}
                          onChange={(e) => handleTitleChange(e, index)}
                          placeholder="Description"
                          className="inputTag"

                        />
                      </div>
                    </>
                  ))
                }
                <div className="docmentationGridItem">
                  {/* {
                      isData ? ( */}
                      {
                        (otherDocs?.length < 10) && (
                          <div
                             className="dropzone"
                             onClick={handleAddDropzone}
                             >
                          <Uploads />
                          <p>Add photo </p>
                        </div>
                        )
                      }
                  
                  {/* ):(
                        <div
                        className="d-flex flex-column align-items-center justify-content-center add-container"
                        onClick={handleApiAddDropZone}
                      >
                        <Uploads />
                        <p>Add Photo </p>
                      </div>
                      )
                    } */}
                  {/* <input name="Add" placeholder="Description" /> */}
                </div>
              </div>
            </div>
            {
              ErrorMsg && (
                <span className="error">{ErrorMsg}</span>
              )
            }
            {/* <div className="Insurance-UploaderWrapper">
                <span className="form-label">Insurance Document</span>
                <div className="customeDropDownPreviewWrapper">
                  <div className="customeDropDownWrapper" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {uploadedImage && (
                      <>
                        <img
                          src={uploadedImage}
                          alt="Uploaded"
                        />
                      </>
                    )}
                    {!uploadedImage && (
                      <div className="UploadIcon">
                        <Uploads />
                        <p>Upload</p>
                      </div>
                    )}
                  </div>
                  {uploadedImage && 
                      <div className="PreviewImg">
                            <IoEyeSharp onClick={()=> {setPreviewImg(true)}} />
                      </div>
                    }
                </div>
                {errorMessage && (
                  <p className="error">{errorMessage}</p>
                )}
              </div> */}

            <div className="finance-input-container">
              <span className="form-label">Policy number (Optional)</span>
              <TextInput placeholder={"Policy number (Optional)"} name={"policy"} value={insurancePolicyNumber}
                onChange={(e) => {
                  const policyNumber = e.target.value;
                  setInsurancePolicyNumber(policyNumber)
                }}
              />
            </div>
            {/* {
                ErrorMsg && (
                  <span className="error">{ErrorMsg}</span>
                )
              } */}

            <div className="third-flex-div">
              <StepButton
                label={" Save & next"}
                blue={true}
                isLoading={saveFinanceInsuranceDetailQueryData.loading}
                onClick={() => handleContinue()} />
            </div>
          </div>

          {/* <div className="InsuranceCard d-none d-md-flex" onClick={() => handleInsurancepage()}>
            <div className="InsuranceCardIconContentWrapper">
              <div className="InsuranceCardIcon">
                <img src={shieldIcon} alt="shieldIcon" />
              </div>
              <div className="ContentWrapper">
                <p className="doc_label">Need Insurance ?</p>
                <p className="doc_Desc">Compare Insurance quotes here
                  and earn £50 referral
                </p>
              </div>
            </div>
            <FiChevronRight className="next_icon" />
          </div> */}
        </div>




      </div>


      <CustomModal
        modalOpen={showCalendar}
        setModalOpen={setShowCalendar}
        modalBody={
          <CustomCalendar
            setCalendarStartDate={setCalendarStartDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            dateData={dateData}
            showSelectedDate={showSelectedDate}
            setShowSelectedDate={setShowSelectedDate}
            onSelectDate={handleSelectedDate}
            setFormatedDate={setFormatedDate}
            setStep={setStep}
            setShowSelectedTime={setShowSelectedTime}
            isOpenMobileCalendar={isOpenMobileCalendar}
            setIsOpenMobileCalendar={setIsOpenMobileCalendar}
            allowMonthYearChange = {true}
            setIsOpenCalendar={setShowCalendar}
          />
        }
        modalTitle={"calander"}
      />

      {/* <CustomModal 
   modalOpen={open}
   setModalOpen={setOpen}
  type={"common-width"}
  modaltitle={"Are you sure"}
  modalBody={
    <div className='modelButtons'>
    <button className='deleteButton' onClick={()=>{
       removeImage()
       setOpen(false);
      }}>Delete</button>
    <button className='cancelButton' onClick={()=>setOpen(false)}>Cancel</button>
    </div>
  }
 /> */}


      {/* <ConfirmationModal
        open={open}
        setOpen={setOpen}
        title="Are you sure?"
        content="Here is some content to complement the title"
        onConfirm={() => {
          removeImage();
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
      /> */}
    </div>
  );
}
