import React, { useEffect, useRef, useState } from "react";
import "./custom-radio-button.scss";
import FilterCanvasSection from "../pages/calendar/filter-canvas/FilterCanvasSection";

const CustomRadioButton = ({
  name,
  id,
  type,
  value,
  onChange,
  checked,
  text,
  text2,
  mainQue,
  timeSlot,
  disabled = false,
  text3 = null,
  isblacked = false,
  isFilterCanvas = false,
  filterImgIcon,
  filterLabel,
  checkedLabelStyles = false,
  filterLabel2 = false,
  filterLabel2Component = false,
  customLabel = false,
  className,
  textLabelClassName = false,
  preselect = false,
  opacity
}) => {

  // const checkValueRef = useRef(null)

  // useEffect(() => {
  //   if (checkValueRef && checkValueRef.current)
  //     checkValueRef.current.checked = checked
  // }, [checked])
  return (
    <label
      style={{
        ...checkedLabelStyles,
        padding: isFilterCanvas ? "0px" : "",
        fontWeight: isFilterCanvas ? "" : "600",
        opacity: opacity ? opacity : 1
      }}
      htmlFor={id}
      className={`${type === "multi" ? "radio-label-multi" : type === "basic" ? "radio-label-basic" : "radio-label"} ${className} ${disabled ? "pe-none text-muted" : ""}`}

    >
      {
        customLabel ? customLabel :
          isFilterCanvas ?
            <FilterCanvasSection
              imgIcon={filterImgIcon}
              imgLabel={filterLabel}
              label2Component={filterLabel2Component}
            />
            :
            <div className="content-section">
              {timeSlot ? <p className={textLabelClassName} >{text}</p> : <b>{text} </b>}
              {text2 !== undefined && text2 != null ? <p>{text2}</p> : null}
              {text3 !== undefined && text3 !== null ? <p>{text3}</p> : null}
            </div>
      }
      <input
        // ref={checkValueRef}
        className={`${mainQue ? "radio-input ms-3" : "radio-input"} ${isblacked ? "radio-input-black" : ""} `}
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={(event) => {
          // event.preventDefault();
          // event.stopPropagation();
          if (onChange)
            onChange(event);
        }}
        {...(preselect ? { checked: checked } : { defaultChecked: checked })}
        disabled={disabled}
      />
    </label>
  );
};

export default CustomRadioButton;