import React, { useEffect, useRef, useState } from "react";
import "./search-clinic.scss";
import SearchIcon from "../../images/client/search_icon.svg";
import { ReactComponent as CloseIcon } from "../../images/close_icon.svg";
import StepButton from "../common/StepButton";
import gsap from "gsap";
import CustomRadioButton from "../common/CustomRadioButton";
import { GetClientList } from "../redux/actions/client/ClientList";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_CLINIC_LOCATIONS } from "../redux/constants";
import { GetClinicLocationsAction } from "../redux/actions/main-calendar/MainCalendarAction";
import SkeletonLoader from "../common/Skeleton/SkeletonLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";
import CustomCheckbox from "../custom-components/custom-checkbox/CustomCheckbox";
import { capitalizeFirstLetter } from "../../utils/initials";

const SearchLocation = ({ setIsClose, setSelectedLocationSave, selectedLocationSave, clearDependentStates, isShowAllClinics, isMultipleLocation = false }) => {
  //Location list Query
  const { getClinicLocationsQueryData, initGetClinicLocationsData } = GetClinicLocationsAction()
  const [searchText, setSearchText] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isSearchedTriggered, setIsSearchedTriggered] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(selectedLocationSave);

  //FOR MULTIPLE LOCATION SELECTION 
  const [multipleSelectedIndex, setMultipleSelectedIndex] = useState([])

  const dispatch = useDispatch()

  //GET LOCATION DATA
  const { clinicLocationList } = useSelector(state => state?.GetClinicLocationReducer)
  const { totalClinicLocationCount } = useSelector(state => state?.GetClinicLocationReducer)
  const { loadedClinicLocationCount } = useSelector(state => state?.GetClinicLocationReducer)
  const { clinicLocationListPage } = useSelector(state => state?.GetClinicLocationReducer)
  const { emptyLocationList } = useSelector(state => state?.GetClinicLocationReducer)

  //API STRUCTURE
  const initiateClinicLocationsCall = (refreshList = false) => {
    let newPage = clinicLocationListPage + 1;
    if (refreshList) {
      newPage = 1;

    } else if (totalClinicLocationCount <= loadedClinicLocationCount) {
      return;
    }

    initGetClinicLocationsData({
      page: newPage,
      limit: 10,
      search: searchText || null
    }
    )
  };

  useEffect(() => {
    // dispatch({ type: CLEAR_CLINIC_LOCATIONS });
    if (selectedLocation.length == 0 || clinicLocationList?.length == 0) {
      dispatch({
        type: CLEAR_CLINIC_LOCATIONS
      })
      initiateClinicLocationsCall(true)

    } else {
      // initiateClinicLocationsCall(false)

    }

  }, [])


  // Function to handle button click and pass selected client data
  const handleSelectButtonClick = () => {
    // Find the selected client based on the selectedIndex

    if (isMultipleLocation) {
      if (multipleSelectedIndex[0] == 0) {
        setSelectedLocationSave([{
          clinicId: 0,
          clinicName: "All locations"
        }])
      } else {
        const selectedClientData = clinicLocationList.filter(i => multipleSelectedIndex.includes(i.clinicId))
        setSelectedLocationSave(selectedClientData)
      }
    } else {
      if (selectedIndex == -1) {
        setSelectedLocationSave([])
        clearDependentStates()
      } else if (selectedIndex == 0) {
        setSelectedLocationSave({
          clinicId: 0,
          clinicName: "All locations"
        })
      } else {

        const selectedClientData = clinicLocationList.find(
          (item) => item?.clinicId === selectedIndex
        );
        if (selectedLocationSave?.clinicId !== selectedClientData?.clinicId) {
          clearDependentStates()
        }

        // Pass the selected client data to the parent component
        setSelectedLocationSave(selectedClientData);
      }
    }

    if (searchText) {
      setSearchText("");
      dispatch({
        type: CLEAR_CLINIC_LOCATIONS
      })
    }

    // Close the client list modal or perform other actions as needed
    setIsClose(false);
    setSelectedIndex(-1);
    setMultipleSelectedIndex([])
  };


  const handleSearchText = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    setIsSearchedTriggered(true);
  };

  const handleCheckboxChange = (id) => {
    if (isMultipleLocation) {
      if (selectedLocation.length) {
        setSelectedLocation([])
      }

      let multipleSelectedIndexTemp = [...multipleSelectedIndex]
      if (id == 0) {
        multipleSelectedIndexTemp.splice(0, multipleSelectedIndexTemp.length)
        multipleSelectedIndexTemp.push(0)
        // setMultipleSelectedIndex([0])
      } else {
        if (multipleSelectedIndexTemp[0] == 0)
          multipleSelectedIndexTemp.splice(0, 1)

        let isChecked = multipleSelectedIndexTemp.findIndex((data) => data === id);

        if (isChecked == -1) {
          multipleSelectedIndexTemp.push(id)
        } else {
          multipleSelectedIndexTemp.splice(isChecked, 1)
          if (multipleSelectedIndexTemp.length == 0)
            multipleSelectedIndexTemp.push(0)
        }
      }
      setMultipleSelectedIndex(multipleSelectedIndexTemp)
    } else {
      if (selectedLocation) {
        setSelectedLocation([])
      }

      if (selectedIndex == id) {
        setSelectedIndex(-1)
      } else {
        setSelectedIndex(id)
      }
    }

  }

  useEffect(() => {
    // Preselect a Location when the component mounts
    if (isMultipleLocation) {
      if (multipleSelectedIndex.length == 0)
        setMultipleSelectedIndex(selectedLocation.map(i => i.clinicId));
    } else {
      if (selectedLocation && selectedLocation.clinicId) {
        setSelectedIndex(selectedLocation.clinicId);
      }
    }
  }, [selectedLocation]);


  //DEBOUNCE FOR SEARCH
  // Debounced API call function
  const debouncedApiCall = debounce((query) => {
    // Make the API call using the search query
    // Replace this with your own API call logic
    dispatch({
      type: CLEAR_CLINIC_LOCATIONS
    })
    initiateClinicLocationsCall(true)
  }, 500); // Debounce delay in milliseconds (e.g., 500ms)

  useEffect(() => {
    if ((searchText.length == 0 && isSearchedTriggered) || searchText.length >= 3) {
      // Call the debounced API function with the search query
      debouncedApiCall(searchText);

      // Cleanup function to cancel any pending debounced calls
      return () => {
        debouncedApiCall.cancel();
      };
    }
  }, [searchText]);

  console.log(selectedLocation, "selectedLocation")

  console.log(multipleSelectedIndex, "multipleSelectedIndex")



  return (
    <div className="location-all-container">
      <div className="sticky-header">
        <div className="close-btn text-end">
          <CloseIcon className="close-icon-animation" onClick={() => {

            if (selectedLocation.length == 0 || searchText.length != 0) {
              dispatch({
                type: CLEAR_CLINIC_LOCATIONS
              })
            }
            setIsClose(false);

          }} />
        </div>
        <h1 className="pt-3">Select location</h1>
        <div className="search-container">
          <input type="text" placeholder="Search location" value={searchText} onChange={handleSearchText} />
          <img src={SearchIcon} alt="search-icon" className="search-icon-img" />
          {searchText?.length > 0 &&
            <CloseIcon className="cursor-pointer search-close-icon" onClick={() => { setSearchText("") }} />
          }
        </div>
      </div>
      <div id="scrollableDiv" style={{ height: "70vh", width: "100%", overflowX: "scroll" }}>
        <InfiniteScroll
          dataLength={loadedClinicLocationCount}
          next={() => {
            initiateClinicLocationsCall(false)
          }}
          hasMore={loadedClinicLocationCount < totalClinicLocationCount}
          scrollableTarget="scrollableDiv"
        >
          <div className="location-list-main-container">

            {
              clinicLocationList?.length != 0 && !(emptyLocationList) ?
                <>
                  {/* ALL CLINIC */}
                  {
                    isShowAllClinics && clinicLocationList?.length > 1 && searchText.length == 0 ?
                      <div className="location-list-container m-2 cursor-pointer " id="scrollableDiv" onClick={() => (document.getElementById(0)).addEventListener(('change'), handleCheckboxChange(0))}
                      >
                        <p>All locations</p>
                        <CustomCheckbox
                          className="mt-2"
                          id={0}
                          name={"All locations"}
                          checked={
                            isMultipleLocation ?
                              multipleSelectedIndex.includes(0) || selectedLocation.map(i => i.clinicId).includes(0)
                              :
                              0 === selectedIndex || selectedLocation?.clinicId === 0}
                          isCircle={true} />
                      </div>
                      : null
                  }


                  {
                    clinicLocationList.map((item, index) => (
                      <div className="location-list-container m-2 cursor-pointer " id="scrollableDiv" key={index}
                        onClick={() => (document.getElementById(item.clinicId)).addEventListener(('change'), handleCheckboxChange(item?.clinicId))}
                      >
                        <p>{capitalizeFirstLetter(item?.clinicName)}</p>
                        <CustomCheckbox
                          className="mt-2"
                          id={item?.clinicId}
                          name={item?.clinicName}
                          checked={isMultipleLocation ?
                            multipleSelectedIndex.includes(item?.clinicId) || selectedLocation.map(i => i.clinicId).includes(item?.clinicId)
                            :
                            item?.clinicId === selectedIndex || selectedLocation?.clinicId === item?.clinicId}
                          isCircle={true} />
                      </div>
                    ))
                  }
                </>
                : getClinicLocationsQueryData.loading ? null :

                  <div className="no-data-availa-class">
                    <p>No Location Available!</p>
                  </div>
            }
            {
              getClinicLocationsQueryData.loading ?
                <SkeletonLoader type="common-list" />
                : null
            }

            {console.log("treatment list", selectedLocation)}
          </div>
        </InfiniteScroll>
      </div>
      <div className="sticky-footer">
        <StepButton label={"Select"} labelClassName={"mb-0"} className={"mt-0"} blue={true} onClick={handleSelectButtonClick} />
      </div>
    </div>
  );
};

export default SearchLocation;
