import { CLEAR_CLINIC_LOCATIONS, GET_CLINIC_LOCATIONS_CONST } from "../../constants";

const initialState = {
    clinicLocationList: [],
    totalClinicLocationCount: 0,
    loadedClinicLocationCount: 0,
    clinicLocationListPage: 0,
    emptyLocationList: false

}
export const GetClinicLocationReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_CLINIC_LOCATIONS_CONST:
            let temp = action.payload.data?.items;

            if(temp[0]?.clinicId !== state?.clinicLocationList[0]?.clinicId) {
                temp = (state?.clinicLocationList).concat(temp);
                // temp = (state?.clinicLocationList).concat(action.payload.data?.items);
            }

            return {
                ...state,
                clinicLocationList: temp,
                totalClinicLocationCount: action.payload.data?.total,
                loadedClinicLocationCount: temp.length,
                clinicLocationListPage: state.clinicLocationListPage + 1,
                emptyLocationList: action.payload.data?.items.length == 0 || action.payload?.data == null ? true : false
            };

        case CLEAR_CLINIC_LOCATIONS:
            return {
                ...state,
                clinicLocationList: [],
                totalClinicLocationCount: 0,
                loadedClinicLocationCount: 0,
                clinicLocationListPage: 0,
                emptyLocationList: false
            }
        default:
            return state
    }
}  