import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";


const DELETE_IDENTITY_MUTATION = gql`
mutation RemoveClientIdentityDocument($clientId: Int!, $documentId: Int!) {
    removeClientIdentityDocument(clientId: $clientId, documentId: $documentId) {
      success
      message
    }
  }
`;

export const DeleteIdentityDocument = () => {
  const [deleteIdentity, deleteIdentityResponseData] =
    useMutation(DELETE_IDENTITY_MUTATION);
  const dispatch = useDispatch();
  const deleteIdentityMutationData = deleteIdentityResponseData;

  const initiateDeleteIdentity= (
    data,
    successCallback,
    errorCallback
  ) => {
    deleteIdentity({
      variables: data,
    })
      .then(({ data: { removeClientIdentityDocument: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateDeleteIdentity, deleteIdentityMutationData };
};