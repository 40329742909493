import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const FORGOT_PASSWORD_FOR_FINANCE = gql`
mutation UserInititateForgotPassword($email: String, $forFinance: Boolean, $platform: String) {
    userInititateForgotPassword(email: $email, forFinance: $forFinance, platform: $platform) {
      success
      message
      
    }
  }
`;

export const ForgotePasswordForFinance = () => {
    // const dispatch = useDispatch()
    const [forgotePasswordForFinanceFunc, forgotePasswordForFinance] = useMutation(FORGOT_PASSWORD_FOR_FINANCE);
    const forgotePasswordForFinanceData = forgotePasswordForFinance;

    const initiateforgotePasswordForFinance = (
        data,
        successCallback,
        errorCallback
    ) => {
        forgotePasswordForFinanceFunc({
            variables: data,
        })
            .then(({ data: { userInititateForgotPassword: responseData } }) => {
                if (responseData?.success) {
                    toast.success(responseData.message)
                    successCallback(responseData);
                } else {
                    toast.error(responseData.message)
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                console.log("error",error)
                errorCallback(error);
            });
    };

    return { initiateforgotePasswordForFinance, forgotePasswordForFinanceData }
}