import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";

import CheckoutForm from "./checkout-form";

import stripePromise from "./stripe";

import PaymentForm from "./payment-form";
import "./stripe.scss"

export const StripeComponent = ({
  clientSecret,
  successCallback,
  failureCallback,
  paymentType,
  bookingId,
  setLoading,
  isBalancePayment,
  setProcess,
  changePaymentType,
  isCardPaymentType,
  savedCardId,
  callbackForSavedCardPayment,

  useSetupIntentFlow = false,
  isStripeFormFiledDisabled,
  validationErrors,
  isFormDataIsValidForCard,
  preCallbackFuncton,
  LateIntentFunction,
  customButton,
  cardLogo
}) => {

    console.log(clientSecret, "clientSecret")
  //===========================*********NOTE:PLEASE UNCOMMENT CLIENTSECRET===========================*********
  if (!clientSecret) {
    return;
  }

  var options = {
    // passing the client secret obtained in step 3
    //===========================*********NOTE:PLEASE UNCOMMENT CLIENTSECRET===========================*********

   clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  if(clientSecret){
    console.log(clientSecret, "clientSecret")
    options.clientSecret = clientSecret
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      {paymentType == "card" && (
        <CheckoutForm
          clientSecret={clientSecret}
          successCallback={successCallback}
          failureCallback={failureCallback}
          setProcess={setProcess}
          changePaymentType={changePaymentType}
          isCardPaymentType={isCardPaymentType}
          savedCardId={savedCardId}
          callbackForSavedCardPayment={callbackForSavedCardPayment}
          useSetupIntentFlow={useSetupIntentFlow}
          isStripeFormFiledDisabled={isStripeFormFiledDisabled}
          validationErrors={validationErrors}
          isFormDataIsValidForCard={isFormDataIsValidForCard}
          preCallbackFuncton={preCallbackFuncton ? preCallbackFuncton : null}
          LateIntentFunction={LateIntentFunction ? LateIntentFunction : null}
          customButton={customButton}
          cardLogo={cardLogo}
        />
      )}
    </Elements>
  );
};