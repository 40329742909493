// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const CHECK_CARD_LINK_OF_USER = gql`
query CheckLinkCards {
    checkLinkCards {
      success
      message
      data {
        is_credit_card_registered
        card_num
        exp_date
      }
    }
  }
`;

export const GetStatuseOfCard = () => {
  const [
    getStatuseOfCardFunction,
    getStatuseOfCardResponseData,
  ] = useLazyQuery(CHECK_CARD_LINK_OF_USER, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ checkLinkCards: responseData }) => {
      if (responseData.success) {
      } else {
        
      }
    },
  });
  //this will be called from components
  const getStatuseOfCardQueryData = getStatuseOfCardResponseData; //variable

  const initgetStatuseOfCardData = (data,successCallback,errorCallback) => {
    getStatuseOfCardFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.checkLinkCards?.success) {
        successCallback(res);
      } else {
        errorCallback(res);
      }
    }).catch((err) => {
      errorCallback(err);
    });
  };

  return { getStatuseOfCardQueryData, initgetStatuseOfCardData };
};
