import React, { useState } from "react";
import "./appointment-form-main.scss";
import { Popover } from "react-bootstrap";
import StepButton from "../../../common/StepButton";
import closeIcon from "../../../../images/close_icon.svg";
import { useNavigate } from "react-router";

const CreateAfterCareForm = ({ currentStep, setCurrentStep }) => {
  const navigate = useNavigate();
  const [formName, setFormName] = useState("");

  const handleInputChange = (e) => {
    setFormName(e.target.value);
  }

  const handleNextPage = () => {
    navigate('/appointment/after-care-form/edit')
  }
  return (
    <>
      <div className="container">
        <div className="form-content ">
          <div className="pop-over-main">
            <Popover
              id="popover-trigger-click"
              className="consent-option-main p-3"
            // title="Popover bottom"
            >
              <div className="constent-list-option-container">
                <closeIcon />
                <div className="row form-title">
                  <p>Name your addfadfform</p>
                </div>

                <div className="constent-list-left-popover-content">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name form"
                    value={formName}
                    onChange={handleInputChange}
                  />
                  <div className="img-close">
                    <img src={closeIcon} alt="img" />
                  </div>
                </div>
                <div>
                  <StepButton
                    disabled={!formName.trim()}
                    blue={!!formName.trim()}
                    label={"Create new form"}
                    onClick={handleNextPage}
                  />
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAfterCareForm;
