import { gql, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

const APPROVE_FINANCE_APPLICATION_MUTATION = gql`
  mutation ApproveFinanceApplication(
    $confirmCode: Int
    $orderId: String
    $financeLoanRequestId: Int
  ) {
    approveFinanceApplication(
      confirm_code: $confirmCode
      order_id: $orderId
      financeLoanRequestId: $financeLoanRequestId
    ) {
      success
      message
    }
  }
`;

export const ApproveFinanceApplication = () => {
  // const dispatch = useDispatch();
  const [
    ApproveFinanceApplicationResponseFunction,
    approveFinanceApplicationResponseData,
  ] = useMutation(APPROVE_FINANCE_APPLICATION_MUTATION);

  //this will be called from components
  const approveFinanceApplicationQueryData =
    approveFinanceApplicationResponseData; //variable

  const initApproveFinanceApplicationData = (
    data,
    successCallback,
    errorCallback
  ) => {
    ApproveFinanceApplicationResponseFunction({
      variables: data,
    })
      // .then(({ data: { ApproveFinanceApplication: responseData } }) => {
      .then((responseData) => {
        console.log(responseData);
        if (responseData?.data?.approveFinanceApplication?.success) {
          // dispatch(...)
          successCallback(responseData);
          // toast.success(responseData.message)
        } else {
          errorCallback(responseData);
          // toast.error(responseData.message)
        }
      })
      .catch((err) => {
        // errorCallback(err);
        console.log('typeeee:', err);
        toast.error('something went wrong!!!');
      });
  };

  return {
    approveFinanceApplicationQueryData,
    initApproveFinanceApplicationData,
  };
};
