import React, { useEffect, useState } from 'react'
import './block-out-time.scss'
import NavHeader from '../../../common/NavHeader/NavHeader';
import { useLocation, useNavigate, useParams } from 'react-router';
import { CalendarRoutePattern, blockOutTimePattern, businessDashboardRoute, getCalendarRoute, getViewBlockoutTimeRoute } from '../../../../Routes';
import { ReactComponent as CaledarEndDateIcon } from '../../../../assets/block-out-time/CaledarEndDateIcon.svg';
import { ReactComponent as CalendarStartDateIcon } from '../../../../assets/block-out-time/CalendarStartDateIcon.svg';
import { ReactComponent as RepeatSettingIcon } from '../../../../assets/block-out-time/RepeatSettingIcon.svg';
import { ReactComponent as TimeIcon } from '../../../../assets/block-out-time/TimeIcon.svg';
import { ReactComponent as RightArrow } from '../../../../images/icons/chevron-left.svg'
import AddLocationIcon from "../../../../images/new-appointment/Add locations.png";
import { ReactComponent as LocationIcon } from "../../../../assets/block-out-time/location.svg"
import StepButton from '../../../common/StepButton';
import moment from 'moment';
import { getBusinessProfileSettingPage } from '../../../../Routes';
import CustomModal from '../../../modal/CustomModal';
import CustomCalendar from '../../dateTime/steps/CustomCalendar';
import { GetCalendarAppointmentCountAction, GetClinicAvailableDateAction } from '../../../redux/actions/dateTimeslots/DateTimeSlots';
import CustomDropdown from '../../../common/dropdown/CustomDropdown';
import CustomRadioButton from '../../../common/CustomRadioButton';
import SwitchButton from '../../../common/switchButton/SwitchButton';
import CustomCheckbox from '../../../custom-components/custom-checkbox/CustomCheckbox';
import showToast from '../../../common/CustomToast/CustomToast';
import OffCanvasModal from '../../../modal/OffCanvasModal/OffCanvasModal';
import SearchLocation from '../../../Clinic/SearchLocation';
import { AddBlockOutTimeMutation } from '../../../redux/actions/block-out-time/AddBlockOutTime';
import { EditBlockOutTimesQuery } from '../../../redux/actions/block-out-time/EditBlockOutTime';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_EDIT_BLOCK_OUT_TIME_DATA } from '../../../redux/constants/blockOutTime/BlockOutTimeConst';
import { CLEAR_DATE_SLOTS } from '../../../redux/constants/dateTimeslots/DateTimeSlots';
import { GetClinicLocationsAction } from '../../../redux/actions/main-calendar/MainCalendarAction';
import { CLEAR_CLINIC_LOCATIONS } from '../../../redux/constants';
import { CustomCalendarSkeleton } from '../../../clininc-cart-skeleton/calenadar-skeleton/CustomCalendarSkeleton';
import { frontendURL, webBaseUrl } from '../../../../config';


const BlockOutTime = () => {
    const navigate = useNavigate();
    const { blockOutId, blockOutDateId } = useParams()
    const dispatch = useDispatch();
    //===========================================================================
    //=========================STATES=========================

    const [isBlue, steIsBlue] = useState(false)

    //LOCATION STATE
    const [selectedLocationData, setSelectedLocationData] = useState([])
    const [showLocationOpen, setShowLocationOpen] = useState(false)

    //TITLE AND DISCRIPTION
    const [eventTitle, setEventTitle] = useState("")
    const [eventDiscription, setEventDiscription] = useState("")

    //CALENDAR START DATE
    const [showStartDateOpen, setShowStartDateOpen] = useState(null)

    const [showSelectedStartDate, setShowSelectedStartDate] = useState(null);
    // const [calendarStartDate, setCalendarStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(null)
    const [selectedDate, setSelectedDate] = useState(new Date());

    const [selectedMonth, setSelectedMonth] = useState(
        selectedDate.getMonth() + 1
    );
    const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());

    const [repeatSettingData, setRepeatSettingData] = useState([])


    //CALENDAR END DATE
    const [showEndDateOpen, setShowEndDateOpen] = useState(null)

    const [showSelectedEndDate, setShowSelectedEndDate] = useState(null);
    // const [calendarStartDate, setCalendarStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [startDateCalendar2, setStartDateCalendar2] = useState(moment().format("YYYY-MM-DD"));
    const [endDateCalendar2, setEndDateCalendar2] = useState(null)
    const [selectedDateCalendar2, setSelectedDateCalendar2] = useState(new Date());

    const [selectedMonthCalendar2, setSelectedMonthCalendar2] = useState(
        selectedDateCalendar2.getMonth() + 1
    );
    const [selectedYearCalendar2, setSelectedYearCalendar2] = useState(selectedDateCalendar2.getFullYear());

    //CHOOSE TIME
    const [isOpenTimePicker, setIsOpenTimePicker] = useState(false)
    const [allDayToggle, setAllDayToggle] = useState(false)

    const [timeRange, setTimeRange] = useState({ start: "", end: "" })

    const [bookingTimeSlotCheckbox, setBookingTimeSlotCheckbox] = useState(false)

    const [isOpenTEndimePicker, setIsOpenEndTimePicker] = useState(false)


    //CHOOSE TIME MODAL INSIDE STATES
    const [start, setStart] = useState("09:00");
    const [end, setEnd] = useState("09:00");
    const [errorObj, setErrorObj] = useState({
        startTime: '',
        endTime: '',
    })
    const [allDayToggleTemp, setAllDayToggleTemp] = useState(false)



    //REPEAT SETTINGS
    const [isRepeatSettingsOpen, setIsRepeatSettingsOpen] = useState(false);
    const [selectedRepeatSettings, setSelectedRepeatSettings] = useState(0);

    const [repeatSettingModalState, setRepeatSettingModalState] = useState(null);

    // REPEAT SETTINGS RESET CONFIRMATION MODAL STATE

    const [repeatSettingsConfirmationModalState, setRepeatSettingsConfirmationModalState] = useState(false)
    //By default It will be true, on Edit Block out we will set this value as false and when we needs to reset on edit we will again set it's value as true
    const [resetRepeatSettings, setResetRepeatSettings] = useState(true)
    const [locationTreatementModalState, setLocationTreatmentModalState] = useState(false)



    //=========================ACTIONS=========================
    //CALENDAR START DATE
    const { getCalendarAppointmentCountQueryData, initGetCalendarAppointmentCountData } = GetCalendarAppointmentCountAction()
    const { clinicsAvailableDateData, initClinicsAvailableDateResponseFunction } = GetClinicAvailableDateAction();
    //CREATE BLOCK OUT TIME
    const { initiateBlockOutTime, addBlockTimeMutationData } = AddBlockOutTimeMutation();
    //EDIT BLOCK OUT TIME
    const { initEditBlockTimeOut } = EditBlockOutTimesQuery()
    //Location
    const { getClinicLocationsQueryData, initGetClinicLocationsData } = GetClinicLocationsAction()

    //===========================================================================

    //=========================SELECTORS=========================
    const { blockOutTimeDetails } = useSelector(state => state.editBlockTimeOutReducer)
    const { clinicLocationList } = useSelector(state => state?.GetClinicLocationReducer)
    const location = useLocation().state

    //===========================================================================


    //=========================FUNCTIONS=========================
    //CALENDAR START DATE
    const generateCustomDates = () => {
        const startDateStr = moment(startDate);

        // Get the first day of the month
        const firstDayOfMonth = startDateStr.clone().startOf('month');

        // Get the last day of the month
        const lastDayOfMonth = startDateStr.clone().endOf('month');

        // Generate an array of dates for the month
        const datesArray = [];
        let currentDate = firstDayOfMonth.clone();

        while (currentDate.isSameOrBefore(lastDayOfMonth)) {
            // Check if the current date is not in the past
            // if (currentDate.isSameOrAfter(moment(), 'day')) {
            datesArray.push(currentDate.format('YYYY-MM-DD'));
            // }

            // Move to the next day
            currentDate.add(1, 'day');
        }
        return datesArray;
    }

    //CALENDAR END DATE
    const generateCustomDatesCalendar2 = () => {
        const startDateStr = moment(startDateCalendar2);

        // Get the first day of the month
        const firstDayOfMonth = startDateStr.clone().startOf('month');

        // Get the last day of the month
        const lastDayOfMonth = startDateStr.clone().endOf('month');

        // Generate an array of dates for the month
        const datesArray = [];
        let currentDate = firstDayOfMonth.clone();

        while (currentDate.isSameOrBefore(lastDayOfMonth)) {
            // Check if the current date is not in the past
            // if (currentDate.isSameOrAfter(moment(), 'day')) {
            datesArray.push(currentDate.format('YYYY-MM-DD'));
            // }

            // Move to the next day
            currentDate.add(1, 'day');
        }
        return datesArray;
    }

    //CHOOSE TIME
    const TIME = () => {
        const hours = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                hours.push(
                    moment({
                        hour,
                        minute
                    }).format('HH:mm')
                );

            }
        }
        return hours;
    };

    //Time Slot Handle Change
    const handleTimeSlotChange = (selectedValue, id) => {
        let treatmentDuration = 30
        if (id === "startTime") {
            setStart(selectedValue);
            // if (parseInt(calculateTotalHours(selectedValue, end, true)) <= 0) {
            //     const parsedTime = moment(selectedValue, 'HH:mm');
            //     const newTime = parsedTime.add(treatmentDuration, 'minutes');
            //     setEnd(newTime.format('HH:mm'))
            // }
        }

        if (id === "endTime") {
            setEnd(selectedValue);
            // if (!validateForm(start, selectedValue))
            //     return

        }
    }

    // useEffect(() => {
    //     if (isOpenTimePicker) {
    //         if (blockOutTimeDetails?.length == 0) {
    //             setStart("09:00")
    //             setEnd("09:00")
    //         } else {
    //             setStart(blockOutTimeDetails.startTime)
    //             setEnd(blockOutTimeDetails.endTime)
    //             const parsedTime = moment(timeRange.start, 'HH:mm');
    //             // console.log(newTime.format('HH:mm'));
    //             // setTimeRange({ ...timeRange, end: parsedTime.add(30, 'minutes').format('HH:mm') })
    //         }
    //     }

    // }, [isOpenTimePicker])

    //CALCULATE TOTAL HOURS
    const calculateTotalHours = (start, end, withOutString = false) => {
        console.log(start, "adadadads", end)
        const currentDate = moment().format('YYYY-MM-DD');
        console.log("start", start)
        console.log("end", end)

        const startTime = moment(`${currentDate} ${start}`);
        const endTime = moment(`${currentDate} ${end}`);

        // Calculate the difference in minutes
        const minutes = endTime.diff(startTime, 'minutes');
        if (withOutString) {
            return minutes
        }

        if (minutes < 60) {
            // If less than 60 minutes, display minutes
            // setTotalHours({ value: minutes, unit: 'mins' });

            return `(${minutes} minutes)`
        } else {
            // If 60 minutes or more, display hours
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;

            if (hours === 1) {
                if (remainingMinutes > 0) {
                    return `(${hours} hour ${remainingMinutes} minutes)`;
                } else {
                    return `(${hours} hour)`;
                }
            } else {
                if (remainingMinutes > 0) {
                    return `(${hours} hours ${remainingMinutes} minutes)`;
                } else {
                    return `(${hours} hours)`;
                }
            }

        }
    }

    //VALIDATION FOR TIME RANGE
    const validateForm = (startTimeParam, endTimeParam) => {
        var obj = {
            startTime: '',
            endTime: '',
        }


        var valid = true;

        if (!startTimeParam && !allDayToggle) {
            obj.startTime = 'Required!'
            valid = false;
        }
        if (!endTimeParam && !allDayToggle) {
            obj.endTime = 'Required!'
            valid = false;
        }
        if (startTimeParam && endTimeParam) {
            var startTime = moment(startTimeParam, 'HH:mm');
            var endTime = moment(endTimeParam, 'HH:mm');

            if (!startTime.isBefore(endTime)) {
                obj.startTime = 'End time should be greater than the start time';
                valid = false
            }

        }
        // setErrorObj(obj);
        return valid
    }

    //ON SUBMIT
    const onSubmit = (data) => {
        // if (!validateForm(start, end)) {
        //     return;
        // }
        if (data == "start") {
            setTimeRange({ ...timeRange, start: moment(start, 'HH:mm').format('HH:mm') })
            setAllDayToggle(allDayToggleTemp)
            setIsOpenTimePicker(false)
        }
        else {
            setTimeRange({ ...timeRange, end: moment(end, 'HH:mm').format('HH:mm') })
            setIsOpenEndTimePicker(false)
        }
        // setStart("09:00")
        // setEnd("09:00")
    };

    //VALIDATE FOR BLOCK OF TIME
    const validateBlockOfTime = () => {
        if (blockOutId && blockOutDateId) {
            if (eventTitle.length == 0 || !showSelectedStartDate || !showSelectedEndDate) {
                return false
            } else
                return true
        } else {
            if (eventTitle.length == 0 || !showSelectedStartDate || !showSelectedEndDate || (!allDayToggle && (!timeRange.start || !timeRange.end))) {
                return false
            } else
                return true
        }
    }



    //CREATE BLOCK OF TIME
    const handleCreateBlockOfTimeFunc = () => {
        console.log(selectedRepeatSettings, "API call")
        if (showSelectedStartDate == showSelectedEndDate && !allDayToggle) {
            if (!validateForm(timeRange.start, timeRange.end)) {
                showToast("error", "Please select valid time range!", "", false)
                return;
            }
        }
        if (blockOutId && blockOutDateId) {
            if (checkRepeatSettings(showSelectedStartDate, showSelectedEndDate)[selectedRepeatSettings].disabled) {
                setRepeatSettingsConfirmationModalState(true)
                return
            }
            initiateBlockOutTime({
                blockOutId: parseInt(blockOutId),
                blockOutDateId: parseInt(blockOutDateId),
                title: eventTitle,
                description: eventDiscription,
                clinicId: selectedLocationData?.map(i => i.clinicId),
                startDate: moment(showSelectedStartDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD"),
                endDate: moment(showSelectedEndDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD"),
                startTime: !allDayToggle ? start : "",
                endTime: !allDayToggle ? end : "",
                isAllDay: allDayToggle,
                enableBooking: bookingTimeSlotCheckbox,
                repeatSetting: selectedRepeatSettings,
                isDeleteFlag: resetRepeatSettings && blockOutTimeDetails.repeatSetting != 0
            }, (res) => {
                if (res.success) {
                    showToast("success", res.message, "", false, "", "", true)
                    navigate(getViewBlockoutTimeRoute(res?.data?.blockOutId, res?.data?.blockOutDateId))
                    // navigate(getCalendarRoute(), { state: { blockOutId: res?.data?.blockOutId, blockOutDateId: res?.data?.blockOutDateId } })
                } else {
                    showToast("error", res.message, "", false, "", "", true)
                }
            }, (err) => {

            })
        } else {
            initiateBlockOutTime({
                title: eventTitle,
                description: eventDiscription,
                clinicId: selectedLocationData?.map(i => i.clinicId),
                startDate: moment(showSelectedStartDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD"),
                endDate: moment(showSelectedEndDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD"),
                startTime: !allDayToggle ? timeRange.start : "",
                endTime: !allDayToggle ? timeRange.end : "",
                isAllDay: allDayToggle,
                enableBooking: bookingTimeSlotCheckbox,
                repeatSetting: selectedRepeatSettings
            }, (res) => {
                if (res.success) {
                    showToast("success", res.message, "", false, "", "", true)
                    navigate(getViewBlockoutTimeRoute(res?.data?.blockOutId, res?.data?.blockOutDateId))
                    // navigate(getCalendarRoute(), { state: { blockOutId: res?.data?.blockOutId, blockOutDateId: res?.data?.blockOutDateId } })
                    console.log("blockdata", res?.data?.blockOutId)
                } else {
                    showToast("error", res.message, "", false, "", "", true)
                }
            }, (err) => {

            })
        }
    }



    //===========================================================================

    //===========================================================================
    //=========================REPEAT SETTINGS DATA=========================
    const repeatSettingsData = [{
        id: 0,
        label: "Don’t repeat",
        disabled: false
    },
    {
        id: 1,
        label: "Daily",
        disabled: false
    },
    {
        id: 2,
        label: "Weekly",
        disabled: false
    },
    {
        id: 3,
        label: "Every two weeks",
        disabled: false
    },
    {
        id: 4,
        label: "Every three weeks",
        disabled: false
    },
    {
        id: 5,
        label: "Every month",
        disabled: false
    }];

    console.log("verify", showSelectedStartDate)
    //=========================STATIC MENU DATA=========================
    const menuData = [
        {
            title: getClinicLocationsQueryData?.data?.getClinicLocations?.data?.total == 0 ? "Add location" : `${selectedLocationData?.length == 0 ? "All locations" : selectedLocationData[0]?.clinicName} ${selectedLocationData.length > 1 ? `+${selectedLocationData.length - 1}` : ""} `,
            icon: getClinicLocationsQueryData?.data?.getClinicLocations?.data?.total == 0 ? <img src={AddLocationIcon} width={48} height={48} /> : <LocationIcon />,
            click: () => {
                if (getClinicLocationsQueryData?.data?.getClinicLocations?.data?.total == 0) {
                    setLocationTreatmentModalState(true)
                }
                else {
                    setShowLocationOpen(true)
                }
            },
            key: "location"
        },
        {
            title: `${showSelectedStartDate ? moment(showSelectedStartDate, "dddd, Do MMMM YYYY").format("Do MMMM YYYY") : "Start date"}`,
            icon: <CalendarStartDateIcon width={48} />,
            click: () => {
                if (!showSelectedStartDate) {
                    setSelectedMonth(moment().month() + 1)
                    setSelectedYear(moment().year())
                } else {
                    setSelectedMonth(moment(showSelectedStartDate, "dddd, Do MMMM YYYY").month() + 1)
                    setSelectedYear(moment(showSelectedStartDate, "dddd, Do MMMM YYYY").year())

                }
                setShowStartDateOpen(true)
            },
            key: "startDate"

        },
        {
            title: `${timeRange.start === "" ? "Choose start time" : allDayToggle ? "All-day" : `${timeRange.start} `}`,
            icon: <TimeIcon width={48} />,
            click: () => {
                if (!showSelectedStartDate) {
                    showToast("error", "Please select start date", "", false)
                    return
                }
                if (timeRange.start) {
                    setStart(timeRange.start)
                } else {
                    setStart("09:00")
                }
                setAllDayToggleTemp(allDayToggle)

                setIsOpenTimePicker(true)
            },
            key: "startTime"

        },
        {
            title: `${showSelectedEndDate ? moment(showSelectedEndDate, "dddd, Do MMMM YYYY").format("Do MMMM YYYY") : "End date"}`,
            icon: <CaledarEndDateIcon width={48} />,
            click: () => {
                if (!showSelectedStartDate) {
                    showToast("error", "Please select start date", "", false)
                    return
                }
                setSelectedMonthCalendar2(moment(showSelectedEndDate, "dddd, Do MMMM YYYY").month() + 1)
                setSelectedYearCalendar2(moment(showSelectedEndDate, "dddd, Do MMMM YYYY").year())
                setShowEndDateOpen(true)
            },
            key: "endDate"
        },
        {
            title: `${timeRange.end === "" ? "Choose end time" : `${timeRange.end}`}`,
            icon: <TimeIcon width={48} />,
            click: () => {
                if (!showSelectedEndDate) {
                    showToast("error", "Please select end date", "", false)
                    return
                }
                if (timeRange.end) {
                    setEnd(timeRange.end)
                } else {
                    setEnd("09:00")
                }
                setIsOpenEndTimePicker(true)

            },
            key: "endTIme"
        },
        {
            title: `${selectedRepeatSettings ? repeatSettingsData.find(item => item.id === selectedRepeatSettings).label : blockOutId && blockOutDateId && !(resetRepeatSettings) ? getLabelById(blockOutTimeDetails.repeatSetting) : "Repeat settings"}`,
            icon: <RepeatSettingIcon width={48} />,
            click: () => {
                setRepeatSettingModalState(selectedRepeatSettings)
                setIsRepeatSettingsOpen(true)
            },
            key: "repeatSettings"
        },
    ]

    //===========================================================================

    //=========================USEEFFECT=========================
    //CALENDAR START DATE

    const getClinicAvailibilityHandler = (clinicid, date) => {
        initClinicsAvailableDateResponseFunction({
            clinicId: clinicid,
            startDate: moment(date, "YYYY-MM-DD").clone().startOf('month').format("YYYY-MM-DD"),
            endDate: moment(date, "YYYY-MM-DD").clone().endOf('month').format("YYYY-MM-DD"),
        });
    }
    const getCalendarAppointmentCount = (location, date) => {
        initGetCalendarAppointmentCountData({
            startDate: moment(date, "YYYY-MM-DD").clone().startOf('month').format("YYYY-MM-DD"),
            endDate: moment(date, "YYYY-MM-DD").clone().endOf('month').format("YYYY-MM-DD"),
            locations: location
        })
    }
    useEffect(() => {
        if (selectedLocationData.length == 1 && !(selectedLocationData[0]?.clinicId == 0) && endDate && getClinicLocationsQueryData?.data?.getClinicLocations?.data?.total !== 0) {
            // if (moment(startDate).format("YYYY-MM") == moment().format("YYYY-MM")) {
            //     // setDateData([])
            //     initClinicsAvailableDateResponseFunction({
            //         clinicId: selectedLocationData[0].clinicId,
            //         startDate: moment().format("YYYY-MM-DD"),
            //         endDate: endDate,
            //     });
            // } else {
            //     initClinicsAvailableDateResponseFunction({
            //         clinicId: selectedLocationData[0].clinicId,
            //         startDate: startDate,
            //         endDate: endDate,
            //     });


            // }
            getClinicAvailibilityHandler(selectedLocationData[0]?.clinicId, startDate)
        }
        // if (endDate)
        //     if (moment(startDate).format("YYYY-MM") == moment().format("YYYY-MM")) {
        //         initGetCalendarAppointmentCountData({
        //             locations: selectedLocationData.map(i => i.clinicId)[0] == 0 ? [] : selectedLocationData.map(i => i.clinicId),
        //             startDate: moment().format("YYYY-MM-DD"),
        //             endDate: endDate,
        //         })
        //     } else {
        //         initGetCalendarAppointmentCountData({
        //             locations: selectedLocationData.map(i => i.clinicId)[0] == 0 ? [] : selectedLocationData.map(i => i.clinicId),
        //             startDate: startDate,
        //             endDate: endDate,
        //         })
        //     }
        getCalendarAppointmentCount(selectedLocationData.map(i => i.clinicId)[0] == 0 ? [] : selectedLocationData.map(i => i.clinicId), startDate)
    }, [startDate, endDate, selectedLocationData])

    //CALENDAR END DATE
    useEffect(() => {
        if (selectedLocationData.length == 1 && !(selectedLocationData[0]?.clinicId == 0) && endDateCalendar2 && getClinicLocationsQueryData?.data?.getClinicLocations?.data?.total == 0) {
            // if (moment(startDateCalendar2).format("YYYY-MM") == moment().format("YYYY-MM")) {
            //     // setDateData([])
            //     initClinicsAvailableDateResponseFunction({
            //         clinicId: selectedLocationData[0].clinicId,
            //         startDate: moment().format("YYYY-MM-DD"),
            //         endDate: endDateCalendar2,
            //     });
            // } else {
            //     initClinicsAvailableDateResponseFunction({
            //         clinicId: selectedLocationData[0].clinicId,
            //         startDate: startDateCalendar2,
            //         endDate: endDateCalendar2,
            //     });


            //     // }
            //     // getClinicAvailibilityHandler(selectedLocationData?.clinicId, endDateCalendar2)

            // }
            getClinicAvailibilityHandler(selectedLocationData[0]?.clinicId, endDateCalendar2)
        }

        // if (endDateCalendar2)
        //     if (moment(startDateCalendar2).format("YYYY-MM") == moment().format("YYYY-MM")) {
        //         initGetCalendarAppointmentCountData({
        //             locations: selectedLocationData.map(i => i.clinicId)[0] == 0 ? [] : selectedLocationData.map(i => i.clinicId),
        //             startDate: moment().format("YYYY-MM-DD"),
        //             endDate: endDateCalendar2,
        //         })
        //     } else {
        //         initGetCalendarAppointmentCountData({
        //             locations: selectedLocationData.map(i => i.clinicId)[0] == 0 ? [] : selectedLocationData.map(i => i.clinicId),
        //             startDate: startDateCalendar2,
        //             endDate: endDateCalendar2,
        //         })
        //     }
        if (endDateCalendar2)
            getCalendarAppointmentCount(selectedLocationData.map(i => i.clinicId)[0] == 0 ? [] : selectedLocationData.map(i => i.clinicId), endDateCalendar2)
    }, [startDateCalendar2, endDateCalendar2, selectedLocationData])

    //CHECK ALL STATES VALIDATION
    useEffect(() => {
        if (validateBlockOfTime()) {
            steIsBlue(true)
        } else {
            steIsBlue(false)
        }
    }, [eventTitle, eventDiscription, showSelectedStartDate, showSelectedEndDate, timeRange, selectedRepeatSettings, allDayToggle])

    //===========================================================================

    //CHECK WHICH OPTION SHOW IN REPEAT SETTINGS USING START DATE AND END DATE
    const checkRepeatSettings = (startDate, endDate) => {
        //selected start and end date is weekly or not
        const startDateMomentFormat = moment(startDate, "ddd DD MMM YYYY")
        const endDateMomentFormat = moment(endDate, "ddd DD MMM YYYY")
        const diffInDays = endDateMomentFormat.diff(startDateMomentFormat, 'days')
        let updatedRepeatSettingsData = repeatSettingsData.map(item => ({ ...item })); // Create a copy of repeatSettingsData
        console.log("diffInDays", diffInDays)

        if (diffInDays > 0 && diffInDays < 7) {
            return updatedRepeatSettingsData?.map((item, index) => {
                if (item.label == "Daily") {
                    return { ...item, disabled: true }
                } else {
                    return item
                }
            })
        } else if (diffInDays >= 7 && diffInDays < 14) {
            return updatedRepeatSettingsData.map((item, index) => {
                if (item.label == "Daily" || item.label == "Weekly") {
                    return { ...item, disabled: true }
                } else {
                    return item
                }
            })
        }
        else if (diffInDays >= 14 && diffInDays < 21) {
            return updatedRepeatSettingsData.map((item, index) => {
                if (item.label == "Daily" || item.label == "Weekly" || item.label == "Every two weeks") {
                    return { ...item, disabled: true }
                } else {
                    return item
                }
            })
        }
        else if (diffInDays >= 21 && diffInDays < 30) {
            return updatedRepeatSettingsData.map((item, index) => {
                if (item.label == "Daily" || item.label == "Weekly" || item.label == "Every two weeks" || item.label == "Every three weeks") {
                    return { ...item, disabled: true }
                } else {
                    return item
                }
            })
        }
        else if (diffInDays >= 30) {
            return updatedRepeatSettingsData.map((item, index) => {
                if (item.label == "Daily" || item.label == "Weekly" || item.label == "Every two weeks" || item.label == "Every three weeks" || item.label == "Every month") {
                    return { ...item, disabled: true }
                }
            })
        } else {
            return updatedRepeatSettingsData
        }


    }
    console.log("new data", repeatSettingData)

    //CHECK WHETHER TO RESET REPEAT SETTINGS OR NOT

    const isNeedToResetRepeatSettings = (startDate, endDate) => {
        return checkRepeatSettings(startDate, endDate)[selectedRepeatSettings].disabled
    }

    useEffect(() => {
        if (showSelectedStartDate && showSelectedEndDate) {
            console.log("set state called", checkRepeatSettings(showSelectedStartDate, showSelectedEndDate))
            setRepeatSettingData(checkRepeatSettings(showSelectedStartDate, showSelectedEndDate))
        }
    }, [showSelectedStartDate, showSelectedEndDate])

    //EDIT BLOCK OUT TIME
    useEffect(() => {
        if (blockOutId && blockOutDateId) {
            initEditBlockTimeOut({
                blockOutId: parseInt(blockOutId),
                blockOutDateId: parseInt(blockOutDateId)
            })
        }
    }, [blockOutDateId, blockOutId])

    useEffect(() => {
        if (blockOutTimeDetails !== undefined && blockOutTimeDetails.length !== 0) {
            setEventTitle(blockOutTimeDetails.title)
            setEventDiscription(blockOutTimeDetails.description)
            setSelectedLocationData(blockOutTimeDetails?.clinicData)
            setShowSelectedStartDate(moment(blockOutTimeDetails.startDate, "YYYY MM DD").format("dddd, Do MMMM YYYY"))
            setShowSelectedEndDate(moment(blockOutTimeDetails.endDate, "YYYY MM DD").format("dddd, Do MMMM YYYY"))
            setStartDate(blockOutTimeDetails.startDate)
            // setEndDate(blockOutTimeDetails.endDate)
            setStartDateCalendar2(blockOutTimeDetails.endDate)
            setStart(blockOutTimeDetails.startTime)
            setEnd(blockOutTimeDetails.endTime)
            setTimeRange({ start: blockOutTimeDetails.startTime, end: blockOutTimeDetails.endTime })
            setAllDayToggle(blockOutTimeDetails.isAllDay)
            setBookingTimeSlotCheckbox(blockOutTimeDetails.enableBooking)
            setRepeatSettingModalState(blockOutTimeDetails.repeatSetting)
            setSelectedRepeatSettings(blockOutTimeDetails.repeatSetting)
            if (blockOutTimeDetails.repeatSetting == 0)
                setResetRepeatSettings(true)
            else
                setResetRepeatSettings(false)
        }
    }, [blockOutTimeDetails])

    //LOCATION API
    useEffect(() => {
        dispatch({
            type: CLEAR_CLINIC_LOCATIONS
        })
        initGetClinicLocationsData({
            page: 1,
            limit: 10,
        }
        )
    }, [])

    //PREFILL LOCATION STATE
    useEffect(() => {

        if (!blockOutId && selectedLocationData?.length == 0)
            if (clinicLocationList.length == 1)
                setSelectedLocationData(clinicLocationList)
            else if (clinicLocationList.length > 1)
                setSelectedLocationData([{
                    clinicId: 0,
                    clinicName: "All locations"
                }])
    }, [clinicLocationList])


    //GET LABEL OF REPEAT SETTINGS
    function getLabelById(id) {
        if (id === 0) {
            return "Don’t repeat"; // Provide your custom label here
        } else {
            const setting = repeatSettingsData.find(setting => setting.id === id);
            return setting ? setting.label : null;
        }
    }

    useEffect(() => {
        if (allDayToggle && [1, 2].indexOf(selectedRepeatSettings) != -1) {
            setSelectedRepeatSettings(0);
        }
    }, [allDayToggle]);
    console.log(location,"locationlocation")

    return (
        <>
            <NavHeader isCloseOnly={true} applyPadding={false}
                onClickCallback={() => {
                    // location?.fromBlockoutCalendarList ? navigate(getCalendarRoute(), { state: { fromBlockoutCalendarList: location?.fromBlockoutCalendarList, calenderViewState: location?.calenderViewState, calenderShowSelectedDate: location?.calenderShowSelectedDate } }) : location?.fromBlockoutDescriptionOverview ?
                    //     navigate(getViewBlockoutTimeRoute(parseInt(blockOutId), parseInt(blockOutDateId)), { state: { fromBlockoutCalendarList: true, calenderViewState: location?.calenderViewState, calenderShowSelectedDate: location?.calenderShowSelectedDate } })
                    //     :
                    //     navigate(getCalendarRoute())
                    if (location?.fromBlockoutCalendarList) {
                        navigate(getCalendarRoute(), {
                            state: {
                                fromBlockoutCalendarList: location?.fromBlockoutCalendarList,
                                calenderViewState: location?.calenderViewState,
                                calenderShowSelectedDate: location?.calenderShowSelectedDate
                            }
                        });
                    } else if (location?.fromBlockoutDescriptionOverview) {
                        navigate(getViewBlockoutTimeRoute(parseInt(blockOutId), parseInt(blockOutDateId)), {
                            state: {
                                fromBlockoutCalendarList: true,
                                calenderViewState: location?.calenderViewState,
                                calenderShowSelectedDate: location?.calenderShowSelectedDate
                            }
                        });
                    } 
                    else if (location?.from === "Block_out_time_home") {
                        navigate(businessDashboardRoute())
                  
                      }
                    else {
                        navigate(getCalendarRoute());
                    }
                    
                }}
            />
            <div className="block-out-time-main-div">
                <div className='block-out-time-header'>
                    <h5>
                        Block out time
                    </h5>
                    <p>
                        Add the details for your blocked time slot.
                    </p>
                </div>
                <div>
                    <div>
                        <div className='input-text-div'>
                            <label htmlFor="eventTitle">Event title</label>
                            <input type="text" id="eventTitle" placeholder='Add a event' value={eventTitle}
                                onChange={(e) => setEventTitle(e.target.value)} />
                        </div>
                        <div className='details-section mt-4'>
                            <label>
                                Details
                            </label>
                            <hr />
                            {
                                menuData.map((items, index) => {
                                    return (
                                        <>
                                            {
                                                (!(items.key == "endTIme" && allDayToggle)) &&
                                                <div
                                                    className={`${items.key === "repeatSettings" && !resetRepeatSettings ? "disabledTile appointment-list-option-container my-4" : 'appointment-list-option-container my-4'}`}
                                                    key={index} onClick={() => {
                                                        items.click()
                                                    }}>
                                                    <div className='appointment-list-left-popover-content'>
                                                        {items.icon}
                                                        <p>{items.title}</p>
                                                    </div>
                                                    <div className='appointment-list-right-popover-content'>
                                                        <RightArrow />
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )




                                })
                            }
                        </div>
                        <div className='input-text-div '>
                            <label htmlFor="eventDiscription">Description</label>
                            <textarea name="eventDiscription" id="eventDiscription" cols="20" rows="10" placeholder='Ex: topics, schedule etc.'
                                value={eventDiscription} maxLength={500} onChange={(e) => {
                                    setEventDiscription(e.target.value)
                                }} />
                            <small>{eventDiscription.length}/500</small>
                        </div>
                        <StepButton className="mb-2" blue={isBlue} gray={!isBlue} label={blockOutId && blockOutDateId ? "Update" : "Create"}
                            disabled={!isBlue}
                            isLoading={addBlockTimeMutationData?.loading}
                            onClick={() => handleCreateBlockOfTimeFunc()} />
                    </div>
                </div>


            </div >

            {/* ========================START DATE======================== */}
            < CustomModal
                modalOpen={showStartDateOpen}
                setModalOpen={setShowStartDateOpen}
                modaltitle={"Start date"}
                modaltitleCSS={"modal-title-calendar"}
                className={'CustomeCalenderModelDesign'}
                modalBody={
                    getCalendarAppointmentCountQueryData?.loading || clinicsAvailableDateData?.loading ? <CustomCalendarSkeleton /> : <CustomCalendar
                        setShowSelectedDate={setShowSelectedStartDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                        setSelectedMonth={setSelectedMonth}
                        setSelectedYear={setSelectedYear}
                        isStrikeClick={true}
                        showSelectedDate={showSelectedStartDate}
                        // setCalendarStartDate={setCalendarStartDate}
                        setIsOpenCalendar={setShowStartDateOpen}
                        isMonthlyDailyViewCalender={selectedLocationData[0]?.clinicId == 0 || selectedLocationData.length > 1 || getClinicLocationsQueryData?.data?.getClinicLocations?.data?.total == 0}
                        dateData={
                            (selectedLocationData[0]?.clinicId == 0 || selectedLocationData.length > 1 || getClinicLocationsQueryData?.data?.getClinicLocations?.data?.total == 0) ? generateCustomDates() :
                                clinicsAvailableDateData?.data?.getClinicAvailableDates?.data?.items
                        }
                        appointmentDatesCount={
                            getCalendarAppointmentCountQueryData?.data?.getCalendarAppointmentCount?.data?.appointmentCountData
                        }
                        ignoreSelectedDate={true}
                        onChangeCallback={(date) => {
                            console.log("date akshay", date)
                            const startDateMomentFormat = moment(date, "ddd DD MMM YYYY")
                            const endDateMomentFormat = moment(showSelectedEndDate, "ddd DD MMM YYYY")


                            let startDateForResetReteatSettings = startDateMomentFormat.format("dddd, Do MMMM YYYY")
                            let endDateForResetReteatSettings = showSelectedEndDate
                            if (!showSelectedEndDate || startDateMomentFormat.isAfter(endDateMomentFormat)) {
                                endDateForResetReteatSettings = startDateMomentFormat.format("dddd, Do MMMM YYYY")
                                setShowSelectedEndDate(startDateMomentFormat.format("dddd, Do MMMM YYYY"))
                            }

                            if (!(moment(showSelectedStartDate, "ddd DD MMM YYYY").isSame(date, "ddd DD MMM YYYY"))) {

                                if (isNeedToResetRepeatSettings(startDateForResetReteatSettings, endDateForResetReteatSettings) && resetRepeatSettings)
                                    setSelectedRepeatSettings(0)
                            }
                        }}
                        isPassedDateEnabled={true}
                        isCalendarPreviousDateEnable={true}

                    />
                }
            />

            {/* ========================END DATE======================== */}
            <CustomModal
                modalOpen={showEndDateOpen}
                setModalOpen={setShowEndDateOpen}
                modaltitle={"End date"}
                modaltitleCSS={"modal-title-calendar"}
                className={'CustomeCalenderModelDesign'}
                modalBody={
                    getCalendarAppointmentCountQueryData?.loading || clinicsAvailableDateData?.loading ? <CustomCalendarSkeleton /> : <CustomCalendar
                        setShowSelectedDate={setShowSelectedEndDate}
                        setStartDate={setStartDateCalendar2}
                        setEndDate={setEndDateCalendar2}
                        selectedMonth={selectedMonthCalendar2}
                        selectedYear={selectedYearCalendar2}
                        setSelectedMonth={setSelectedMonthCalendar2}
                        setSelectedYear={setSelectedYearCalendar2}
                        isStrikeClick={true}
                        showSelectedDate={showSelectedEndDate}
                        // setCalendarStartDate={setCalendarStartDate}
                        isMonthlyDailyViewCalender={selectedLocationData[0]?.clinicId == 0 || selectedLocationData.length > 1 || getClinicLocationsQueryData?.data?.getClinicLocations?.data?.total == 0}
                        setIsOpenCalendar={setShowEndDateOpen}
                        dateData={
                            // generateCustomDatesCalendar2()
                            (selectedLocationData[0]?.clinicId == 0 || selectedLocationData.length > 1 || getClinicLocationsQueryData?.data?.getClinicLocations?.data?.total == 0) ? generateCustomDatesCalendar2() :
                                clinicsAvailableDateData?.data?.getClinicAvailableDates?.data?.items
                        }
                        appointmentDatesCount={
                            getCalendarAppointmentCountQueryData?.data?.getCalendarAppointmentCount?.data?.appointmentCountData
                        }
                        ignoreSelectedDate={true}
                        onChangeCallback={(date) => {
                            const startDateMomentFormat = moment(showSelectedStartDate, "ddd DD MMM YYYY")
                            const endDateMomentFormat = moment(date, "ddd DD MMM YYYY")


                            let startDateForResetReteatSettings = showSelectedStartDate
                            let endDateForResetReteatSettings = endDateMomentFormat.format("dddd, Do MMMM YYYY")
                            if (startDateMomentFormat.isAfter(endDateMomentFormat)) {
                                showToast("error", "End date should be greater than start date", "", false)
                                setShowSelectedEndDate(showSelectedEndDate)
                                setStartDateCalendar2(startDateCalendar2)
                                setEndDateCalendar2(endDateCalendar2)

                                setSelectedMonthCalendar2(selectedMonthCalendar2)
                                setSelectedYearCalendar2(selectedYearCalendar2)

                                setShowEndDateOpen(true)
                                return
                            }
                            if (!(moment(showSelectedEndDate, "ddd DD MMM YYYY").isSame(date, "ddd DD MMM YYYY"))) {
                                if (isNeedToResetRepeatSettings(startDateForResetReteatSettings, endDateForResetReteatSettings) && resetRepeatSettings)
                                    setSelectedRepeatSettings(0)
                            }

                        }}
                        isPassedDateEnabled={true}
                        isCalendarPreviousDateEnable={true}

                    />
                }
            />

            {/* ========================CHOOSE START TIME======================== */}

            <CustomModal
                modalOpen={isOpenTimePicker}
                setModalOpen={setIsOpenTimePicker}
                type={"common-width"}
                modaltitle={"Start time"}
                modaltitleCSS={"modal-title-calendar"}
                className={'CustomeTimeTracker'}

                modalBody={
                    <>
                        <div className='modal-time-picker-for-block-out-time mt-3'>
                            <div className='open-for-business-toggle'>
                                <p>All-day</p>
                                <SwitchButton
                                    onChange={(e) =>
                                        setAllDayToggleTemp(!allDayToggleTemp)
                                        // setAllDayToggle(!allDayToggle)
                                    }
                                    isChecked={allDayToggleTemp}
                                    className='business-toggle-switch'
                                />
                            </div>
                            {
                                !allDayToggleTemp &&
                                <div className="w-100 m-auto mt-5" id={"time-picker"}>
                                    <div className="">
                                        <CustomDropdown
                                            labelName={"Start time"}
                                            dropdownId={"startTime"}
                                            placeholdername={"Select Time"}
                                            optionsArr={TIME()}
                                            defaultValue={start}
                                            handleSelectedValue={handleTimeSlotChange}
                                        />
                                        <p className="text-danger">{errorObj.startTime}</p>
                                    </div>
                                </div>
                            }
                            {/* <div className='checkbox-booking-block-time-slots'>
                                <div className="form-list-container my-3 cursor-pointer"
                                    onClick={() => (document.getElementById('booking-time-slot')).addEventListener(('change'), setBookingTimeSlotCheckbox(!bookingTimeSlotCheckbox))}
                                >
                                    <CustomCheckbox
                                        className={`form-list-checkbox`}
                                        id={'booking-time-slot'}
                                        name={'booking-time-slot'}
                                        checked={bookingTimeSlotCheckbox} />
                                    <p className="truncated-text-35">Enable bookings in blocked time slots.</p>
                                </div>
                            </div> */}
                            <div className='btnWrapper'>
                                <StepButton label={"Done"} blue={true} onClick={() => onSubmit("start")} disabled={((start?.length == "" && !allDayToggleTemp) && !allDayToggleTemp)} />
                            </div>
                        </div>
                    </>
                }
            />

            {/* ========================CHOOSE END TIME======================== */}

            <CustomModal
                modalOpen={isOpenTEndimePicker}
                setModalOpen={setIsOpenEndTimePicker}
                type={"common-width"}
                modaltitle={"End time"}
                modaltitleCSS={"modal-title-calendar"}
                className={'CustomeTimeTracker'}
                modalBody={
                    <>
                        <div className='modal-time-picker-for-block-out-time mt-3'>
                            <div className="w-100 m-auto mt-5" id={"time-picker"}>
                                <div className="">
                                    <CustomDropdown
                                        labelName={"End Time"}
                                        defaultValue={end}
                                        dropdownId={"endTime"}
                                        placeholdername={"Select Time"}
                                        optionsArr={TIME()}
                                        handleSelectedValue={handleTimeSlotChange}
                                    />
                                    <p className="text-danger">{errorObj.endTime}</p>
                                </div>
                            </div>
                            <div className='btnWrapper'>
                                <StepButton label={"Done"} blue={true} onClick={() => onSubmit("end")} disabled={((end?.length == ""))} />
                            </div>
                        </div>
                    </>
                }
            />

            {/* ========================REPEAT SETTINGS======================== */}
            <CustomModal
                modalOpen={isRepeatSettingsOpen}
                setModalOpen={setIsRepeatSettingsOpen}
                type={"common-width"}
                className={'CustomRepeatSettingsModel'}
                modalBody={
                    <>
                        <div className="container h-100">
                            <div className="row h-100">
                                <div className="col-md-12">
                                    <div className="repeat-settings-container">
                                        <div className="repeat-settings-content">
                                            <div className='repeat-settings-header'>
                                                <h1>Repeat settings</h1>
                                                <p className="mb-3">
                                                    Choose the duration for repeating this time block.
                                                </p>
                                            </div>
                                            <div className="repeat-settings-inputContainerWrapper">
                                                {
                                                    <>
                                                        {repeatSettingsData?.map((repeatSettings, index) => {
                                                            if (allDayToggle && [1, 2].indexOf(repeatSettings.id) != -1) {
                                                                return;
                                                            }
                                                            return (
                                                                <div
                                                                    className="repeat-settings-input-container"
                                                                    key={index}
                                                                >
                                                                    <CustomRadioButton
                                                                        timeSlot={true}
                                                                        name="time"
                                                                        // disabled={repeatSettingData.some(item => item.id === repeatSettings?.id) ? repeatSettingData.find(item => item.id === repeatSettings?.id).disabled : false}
                                                                        text={repeatSettings.label}
                                                                        id={repeatSettings.id}
                                                                        className={`radio - label - repeat - settings ${repeatSettingData.some(item => item?.id === repeatSettings?.id) ? repeatSettingData.find(item => item?.id === repeatSettings?.id).disabled ? "pe-none text-muted" : "" : ""} `}
                                                                        value={repeatSettings.label}
                                                                        isblacked={true}
                                                                        checked={repeatSettingModalState == repeatSettings.id}
                                                                        onChange={(e) => setRepeatSettingModalState(repeatSettings.id)}
                                                                        textLabelClassName={repeatSettingData.some(item => item?.id === repeatSettings?.id) ? repeatSettingData.find(item => item?.id === repeatSettings?.id).disabled ? "text-muted" : "" : ""}
                                                                    />
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                }
                                            </div>
                                            <div className="repeat-settings-button-container">
                                                <StepButton
                                                    label={"Done"}
                                                    blue={true}
                                                    labelClassName={"mb-0"}
                                                    className={"mt-0"}
                                                    disabled={repeatSettingModalState === null}
                                                    onClick={() => {
                                                        setSelectedRepeatSettings(repeatSettingModalState)
                                                        setIsRepeatSettingsOpen(false)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            />

            {/* ============== CHOOSE LOCATION ====================*/}
            <OffCanvasModal
                isOffCanvasHeader={false}
                isOpen={showLocationOpen}
                placement={"end"}
                className={"w-35"}
                onHide={() => setShowLocationOpen(false)}
                body={<SearchLocation setIsClose={setShowLocationOpen} selectedLocationSave={selectedLocationData} setSelectedLocationSave={setSelectedLocationData}
                    isShowAllClinics={true}
                    isMultipleLocation={true}
                    clearDependentStates={() => {
                        setShowSelectedStartDate(null)
                        setShowSelectedEndDate(null)
                        if (blockOutTimeDetails?.length == 0)
                            setSelectedRepeatSettings(0)
                        // clearDependentStates()
                    }}
                />}
            />

            {/* REPEAT SETTINGS RESET CONFIRMATION MODAL */}
            <CustomModal
                modalOpen={repeatSettingsConfirmationModalState}
                // modalOpen={true}
                setModalOpen={setRepeatSettingsConfirmationModalState}
                type={"common-width"}
                className={'repeat-settings-confirmation-modal-class'}
                modalBody={
                    <>
                        <div className='repaet-settings-confirmation-modal-main-div'>
                            <p>
                                For the selected time range, your repeat settings going to reset and all the future blockout time will be discard related to this repeat settings and will create new blockout time.
                            </p>
                            <p className='fw-bold'>
                                Are you sure you want to perform this action?
                            </p>
                            <div className="buttons">
                                <StepButton label="Yes" blue={true} onClick={() => {
                                    setResetRepeatSettings(true)
                                    setSelectedRepeatSettings(0)
                                    setRepeatSettingsConfirmationModalState(false)
                                }} />
                                <StepButton label="No" gray={true} onClick={() => setRepeatSettingsConfirmationModalState(false)} />
                            </div>
                        </div>
                    </>
                }

            />

            {/* LOCATION TREATMENT ADDITION MODAL */}
            <CustomModal
                modalOpen={locationTreatementModalState}
                setModalOpen={setLocationTreatmentModalState}
                type={"common-width"}
                className={'customeAvailableModel'}
                modalBody={
                    <>
                        {/* <div className="share-booking-link-all-clients-confimration"> */}
                        <div className="share-booking-link-all-clients-confimration">
                            <p>
                                There are currently no location added. Would you like to add one?
                            </p>
                            <div className="btnWrapper">
                                <StepButton label={"Yes"} onClick={() => {
                                    window.location.href = webBaseUrl + `/clinic/create?redirect-url=${frontendURL + blockOutTimePattern}`
                                    setLocationTreatmentModalState(false)
                                }} blue={true} />
                                <StepButton gray={true} label={"No"} onClick={() => {
                                    setLocationTreatmentModalState(false)
                                }} />
                            </div>
                        </div>

                    </>
                }
            />
        </>
    )
}

export default BlockOutTime