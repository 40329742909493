import {
    SET_WALK_IN_CLIENT_NAME,
    SET_SELECTED_CLIENT_ID,
    SET_WALK_IN_CLINIC_LOCATION,
    SET_WALK_IN_LOCATION_CLINIC_ID,
    SET_WALK_IN_SERVICES_DATA,
    SET_WALK_IN_SERVICES_PRICE,
    SET_WALK_IN_TREATMENT_ID,
    SET_WALK_IN_CONSENT_FORM_DATA,
    SET_WALK_IN_AFTERCARE_FORM_DATA,
    SET_WALK_IN_CONSENT_FORM_ID,
    SET_WALK_IN_AFTERCARE_FORM_ID
} from '../../constants/walk-in-form-const/clientWalkInConst';

const initialState = {
    treatmentId: null,
    consentFormId: null,
    clinicLocation: null,
    afterCareFormId: null,
    consentFormList: null,
    walkInClientName: null,
    selectedClientId: null,
    afterCareFormList: null,
    clinicServicesList: [],
    clinicServicesPrice: null,
    clinicLocationClinicId: null,

};

export default function walkInClientNameReducer(state = initialState, action) {
    switch (action.type) {
        case SET_WALK_IN_CLIENT_NAME:
            return {
                ...state,
                walkInClientName: action.payload,
            };
        case SET_SELECTED_CLIENT_ID:
            return {
                ...state,
                selectedClientId: action.payload,
            };
        case SET_WALK_IN_CLINIC_LOCATION:
            return {
                ...state,
                clinicLocation: action.payload,
            };

        case SET_WALK_IN_LOCATION_CLINIC_ID:
            return {
                ...state,
                clinicLocationClinicId: action.payload,
            };
        case SET_WALK_IN_SERVICES_DATA:
            return {
                ...state,
                clinicServicesList: action.payload,
            };
        case SET_WALK_IN_SERVICES_PRICE:
            return {
                ...state,
                clinicServicesPrice: action.payload,
            };
        case SET_WALK_IN_TREATMENT_ID:
            return {
                ...state,
                treatmentId: action.payload,
            };
        case SET_WALK_IN_CONSENT_FORM_DATA:
            return {
                ...state,
                consentFormList: action.payload,
            };
        case SET_WALK_IN_AFTERCARE_FORM_DATA:
            return {
                ...state,
                afterCareFormList: action.payload,
            };
        case SET_WALK_IN_CONSENT_FORM_ID:
            return {
                ...state,
                consentFormId: action.payload,
            }
        case SET_WALK_IN_AFTERCARE_FORM_ID:
            return {
                ...state,
                afterCareFormId: action.payload,
            }


        default:
            return state;
    }
}
