import { Avatar } from "@material-ui/core";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { frontendURL } from "../../config";
import { ReactComponent as ApplePayIcon } from "../../images/apple_pay.svg";
import { ReactComponent as BackArrow } from "../../images/back_arrow.svg";
import { ReactComponent as MonthPayIcon } from "../../images/month_pay.svg";
import { ReactComponent as DebitIcon } from "../../images/pay_credit.svg";
import { ReactComponent as PayFullIcon } from "../../images/pay_in_full.svg";
import { ReactComponent as RightArrow } from "../../images/right_arrow.svg";
import { ReactComponent as SplitPayIcon } from "../../images/split_payment.svg";
import { ReactComponent as CloseIcon } from '../../images/close_icon.svg'
import Modal from 'react-modal'
import BannerHeader from "../common/BannerHeader";
import CustomRadioButton from "../common/CustomRadioButton";
import StepButton from "../common/StepButton";
import CustomModal from "../modal/CustomModal/CustomModal";
import DiscountModal from "../modal/DiscountModal/DiscountModal";
import PaymentWebModal from "../modal/PaymentWebModal/PaymentWebModal";
import { CreateTreatmentBooking, GetRyftPaymentToken } from "../redux/actions/treatmentBooking/createTreatmentBooking";
import RyftComponent from "../ryft/RyftComponent";
import "./confirm-pay-web.scss";
import { StripeComponent } from "../Stripe/StripeComponent";
import { CompletePaymentBooking } from "../redux/actions/completePaymentBooking/CompletePaymentBooking";
import { GET_DISCOUNT_CODE_CONST } from "../redux/constants/discount/discount";
import PayLater from "../payl8r/PayLater";
import { setPaylaterData } from "../redux/actions/pay-later/payLaterAction";
import { PayLaterRequest } from "../redux/actions/pay-later/PayLater";
import SuccessModal from "../../components/modal/SuccessModal/index"
import { getPaylaterStatus, SendFinanceRequest } from "../redux/actions/pay-later/payLaterAction"
import PatientDobWarningModal from "../modal/PatientDobWarningModal";

export let handleConfirmBooking
export let payl8rMiddleFunction

export let initConfirmBooking;

const ConfirmPayWeb = ({
  substep,
  setSubstep,
  clinicId,
  isRight,
  setStep,
  step,
  selectedTreatment,
  showSelectedDate,
  formatedDate,
  selectedTeamMember,
  setIsRight,
  selectedTreatmentId,
  showSelectedTime,
  selectedTimes,
  setSelectedTimes,
  selectedLocation,
  setSelectedLocation,
  companyName,
  payl8rData,
  setSelectedPaymentOption,
  setTotalPrice,
  setTotalDeposit,
  totalDeposit,
  selectedPaymentOption,
  totalPrice,
  loader,
  setLoader,
  setYourDetails,
  yourDetails,
  setValidationErrors,
  validationErrors,
  isOpenPayment,
  setIsOpenPayment,
  openPayLater,
  setOpenPayLater,
  payLaterData,
  setPayLaterData,
  setBookingId,
  bookingId,


  showDobWarning,
}) => {

  const [isOpen, setIsOpen] = useState(false);
  //const [isOpenPayment, setIsOpenPayment] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [payableAmount, setPayableAmount] = useState(0)
  const sessionToken = useSelector(state => state?.treatmentBookingreducer?.treatmentBookingToken)
  const treatmentBookingAccountId = useSelector(state => state?.treatmentBookingreducer?.treatmentBookingAccountId)
  const { discountDetails } = useSelector(state => state?.discountReducer)
  const { businessClinicList } = useSelector(state => state?.businessLocationListReducer)
  const [removeLoading, setRemoveLoading] = useState(false)


  const [requestSendModal, setRequestSendModal] = useState(false);


  console.log(businessClinicList, "businessClinicList", treatmentBookingAccountId)

  const dispatch = useDispatch();
  // for display purpose default value is in string at the time of calculation we convert it into float 
  const [discountValue, setDiscountValue] = useState("0.00")
  const [isRemoveOpen, setIsRemoveOpen] = useState(false)


  const { slug } = useParams()
  const [practitionerId, setPractitionerId] = useState(null);


  const { getRyftPaymentQueryData, initRyftPaymentToken } = GetRyftPaymentToken()
  const { initiateCompletePaymentBooking, completePaymentBookingMutationData } = CompletePaymentBooking()
  const { initiatePayLaterRequest, payLaterMutationData } = PayLaterRequest()


  const moment = require("moment");
  const navigate = useNavigate()

  const parsedDate = moment(showSelectedDate, "dddd, Do MMMM YYYY");


  const formattedDate = parsedDate.format("YYYY-MM-DD");
  const formattedTime = moment(showSelectedTime, "hh:mm A").format("HH:mm:ss");
  const { initiateCreateTreatmentBooking, createTreatmentBookingMutationData } =
    CreateTreatmentBooking();

  const paylaterEnable = useSelector(state => state.payLaterReducer.payLaterEnable)
  const clinic_id = useSelector(state => state.businessLocationListReducer.clinic_id) || null;
  const { initiatesendFinance, sendFinanceMutationData } = SendFinanceRequest();

  // Define the isValidEmail function for basic email validation
  function isValidEmail(email) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  }

  console.log("selected Option", selectedPaymentOption)

  useEffect(() => {
    console.log("bookingid useEffect", bookingId, payLaterData)
  }, [bookingId, payl8rData])

  handleConfirmBooking = () => {
    setLoader(true)


    // Validate first name, last name, and email
    const validationErrors = {};
    if (!yourDetails.clientFName) {
      validationErrors.clientFName = "First name is required";
    }
    if (!yourDetails.clientLName) {
      validationErrors.clientLName = "Last name is required";
    }
    if (!yourDetails.clientEmail || !isValidEmail(yourDetails.clientEmail)) {
      validationErrors.clientEmail = "Valid email is required";
    }

    setValidationErrors(validationErrors);

    // If there are validation errors, don't proceed with booking
    if (Object.values(validationErrors).some((error) => error)) {
      //toast.error("Please fix the validation errors.");
      setLoader(false);
      return;
    }

    showDobWarning();
  };

  initConfirmBooking = () => {
    setLoader(true)
    //couponCode
    initiateCreateTreatmentBooking(
      {
        clinicId: parseInt(clinicId),
        date: formattedDate,
        time: formattedTime,
        clientEmail: yourDetails.clientEmail,
        clientFname: yourDetails.clientFName,
        clientLname: yourDetails.clientLName,
        treatmentIds: selectedTreatmentId,
        practitionerId: selectedTeamMember.id,
        payFull: selectedPaymentOption === "Pay in full" ? true : false,
        couponCode: discountDetails?.discount_name ? discountDetails?.discount_name : "",
        createdFrom: businessClinicList?.clinic_DB_id ? "clinic-detail" : "na"
      },
      (res) => {
        var totalAmt = getTotalPriceAfterDiscount(0);
        console.log("booking id", res.data)
        let isBypass = (selectedPaymentOption === "Split payment" && totalDeposit == 0) ? true : false
        if (selectedPaymentOption === "Monthly payments") {
          setBookingId(res?.data?.booking_id)
          initiatePayLaterRequest({
            bookingId: res.data.booking_id,
            redirectURL: `${frontendURL}/bookings/${slug}/success`
          }, (res) => {
            console.log("payLater", res)
            setLoader(false)
            if (res.success) {
              setOpenPayLater(true)
              dispatch(setPaylaterData(res?.data))
              setPayLaterData(res?.data)
            } else {
              setOpenPayLater(false)
              setPayLaterData(null)
            }
            // if(res.success){
            //   setOpenPayLater(true)
            // }else{
            //   setOpenPayLater(false)
            // }
          }, (e) => {
            setLoader(false)
            toast.error(e.message)
          })
        }
        else if (totalAmt == 0) {
          navigate(`/bookings/${slug}/success?bookingId=${res.data.booking_id}`)
        }
        else if ((businessClinicList?.is_stripe_setup && !isBypass)) {
          setBookingId(res.data.booking_id)
          initRyftPaymentToken({
            bookingId: res?.data?.booking_id,
            paymentType: 2,
            redirectURL: `${frontendURL}/bookings/${slug}/success`
          }, (res) => {
            setLoader(false);
            if (res?.data?.getRyftPaymentToken?.success) {
              setIsOpenPayment(true)
            } else {
              setIsOpenPayment(false)
            }
          })
        } else if (businessClinicList?.is_ryft_setup && !isBypass) {
          initRyftPaymentToken({
            bookingId: res.data.booking_id,
            paymentType: 1,
            redirectURL: `${frontendURL}/bookings/${slug}/success`
          }, (res) => {
            setLoader(false);
            if (res?.data?.getRyftPaymentToken?.success) {
              setIsOpenPayment(true)
            } else {
              setIsOpenPayment(false)
            }
          })
        } else {
          navigate(`/bookings/${slug}/success?bookingId=${res.data.booking_id}`)
        }
      },
      // (res) => {
      //   setIsOpenSuccess(true); // Open SuccessPage on success
      //   setLoader(false);
      // },
      (err) => {
        setLoader(false);

        toast.error(err.message);
      }
    );
  }

  payl8rMiddleFunction = () => {
    //here we check if payl8r is approved and on from settings then only we will proceed else we will show warning popup
    if (paylaterEnable == true) {
      handleConfirmBooking();
    } else {

      initiatesendFinance({
        "clinicId": parseInt(clinic_id),
        "requestMail": true
      }, (data) => {
        setRequestSendModal(true);
      }, () => {
        alert('Something went wrong!');
      })
    }
  }
  const bookingSuccessCallBack = (data) => {
    if (data?.status === "Approved") {
      navigate(`/bookings/${slug}/success`)

    } else {
      navigate(`/bookings/${slug}/fail`)

    }
  }

  const bookingStripeSuccessCallBack = (data) => {
    initiateCompletePaymentBooking({
      bookingId: bookingId,
      paymentId: data
    }, (res) => {
      if (res.success) {
      } else {
      }
    }, (e) => {

    })
  }
  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    if (value != "") {
      if (name == "clientFName")
        setValidationErrors({ ...validationErrors, clientFName: "" })
      if (name == "clientLName")
        setValidationErrors({ ...validationErrors, clientLName: "" })
      if (name == "clientEmail")
        setValidationErrors({ ...validationErrors, clientEmail: "" })
    }


    setYourDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handlePaymentOptionChange = (option) => {
    setSelectedPaymentOption(option);
  };
  function stringAvatar(name) {
    if (typeof name === "string") {
      return {
        children: name,
      };
    } else {
      // Handle the case where name is not a string, e.g., provide a default value.
      return {
        children: "N/A",
      };
    }
  }

  useEffect(() => {
    const totalPrice = selectedTreatment?.reduce((total, item) => {
      return total + (item?.amount || 0); // Add the amount of each item to the total
    }, 0);
    const totalDeposit = selectedTreatment?.reduce((deposit, item) => {
      return deposit + (item?.deposit || 0);
    }, 0)
    const totalMin = selectedTreatment?.reduce((mins, item) => {
      return mins + (item?.duration || 0);
    }, 0)
    setTotalPrice(totalPrice);
    setTotalDeposit(totalDeposit);
    setPayableAmount(totalPrice - totalDeposit)

  }, [selectedTreatment]);

  const getTotalPriceAfterDiscount = (fixed = 0) => {
    return (totalPrice - parseFloat(discountValue)).toFixed(fixed)
  }

  //FOR DISCOUNT CODE 
  useEffect(() => {
    if (discountDetails == null) {
      setDiscountValue("0.00")
    } else {
      if (discountDetails?.discount_type === "percentage") {
        setDiscountValue(((totalPrice * discountDetails?.discount_unit) / 100).toFixed(2).toString())
      } else {
        setDiscountValue(discountDetails?.discount_unit?.toString())
      }
    }

  }, [discountDetails?.discount_name, isOpen])

  const handleRemoveDiscount = () => {
    setRemoveLoading(true)
    setTimeout(() => {
      dispatch({ type: GET_DISCOUNT_CODE_CONST, payload: null });
      setIsRemoveOpen(false)
      setRemoveLoading(false)
    }, 2000)
  }

  useEffect(() => {
    console.log('here redirect back', isOpenPayment);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [isOpenPayment, openPayLater])

  useEffect(() => {
    if (showSelectedTime) {
      const totalMin = selectedTreatment?.reduce((mins, item) => {
        return mins + (item?.duration || 0);
      }, 0)
      let endTime = moment(`${showSelectedTime}`, "HH:mm").add(totalMin, 'minutes').format("HH:mm");;
      let resultTime = showSelectedTime + "-" + endTime
      setSelectedTimes(resultTime)
    } else {
      setSelectedTimes(null)
    }
  }, [showSelectedTime,selectedTreatment])

  console.log("total deposit", totalDeposit, totalPrice, openPayLater, payLaterData, payl8rData)
  return (
    isOpenPayment ?
      // (businessClinicList?.is_stripe_setup && bookingId !=null && treatmentBookingAccountId !=null )?
      (businessClinicList?.is_stripe_setup) ?
        <StripeComponent
          clientSecret={sessionToken}
          paymentType="card"
          // successCallback={bookingStripeSuccessCallBack}
          bookingId={bookingId}
          setIsOpenPayment={setIsOpenPayment}
          // setProcess={setProcess}
          stripeDataObj={{
            date: moment(formatedDate, ("Do MMMM YYYY")).format('M') == 9 ?
              `${moment(formatedDate, ("Do MMMM YYYY")).format('Do')
              } Sept.${moment(formatedDate, ("Do MMMM YYYY")).format('YYYY')}` :
              `${moment(formatedDate, ("Do MMMM YYYY")).format('Do MMM. YYYY')}`,
            treatmentCount: selectedTreatment?.length,
            treatmentPrice: `£${totalPrice}.00`,
            totalPrice: selectedPaymentOption === "Split payment" ? `£${totalDeposit}` : `£${getTotalPriceAfterDiscount(0)}`,

          }}
        /> :
        <RyftComponent
          clientSecret={sessionToken}
          accountId={treatmentBookingAccountId}
          successCallback={bookingSuccessCallBack}
          setIsOpenPayment={setIsOpenPayment}
          isOpenPayment={isOpenPayment}
          ryftDataObj={{
            date: moment(formatedDate, ("Do MMMM YYYY")).format('M') == 9 ?
              `${moment(formatedDate, ("Do MMMM YYYY")).format('Do')
              } Sept.${moment(formatedDate, ("Do MMMM YYYY")).format('YYYY')}` :
              `${moment(formatedDate, ("Do MMMM YYYY")).format('Do MMM. YYYY')}`,
            treatmentCount: selectedTreatment?.length,
            treatmentPrice: `£${totalPrice}.00`,
            totalPrice: selectedPaymentOption === "Split payment" ? `£${totalDeposit}` : `£${getTotalPriceAfterDiscount(0)}`,
          }}
        />

      :
      openPayLater ?
        <>
          <PayLater payLaterData={payLaterData} bookingId={bookingId} setOpenPayLater={setOpenPayLater} />
        </>
        :
        <div className={(isRight == true) ? "" : "new-class"}>

          <div className="confirm-pay-container">
            {isRight != true && (
              <>
                <BackArrow className="cursor-pointer" onClick={() => setSubstep(303)} />
                <BannerHeader
                  topHeader={"30 minutes to complete the booking and secure your date and time."}
                  title={"Confirm & pawqwqwqy"}
                  subTitle={`You’re booking a treatment with ${selectedLocation?.clinic_name}`}
                />

              </>
            )}

            {/* Treatment Section  */}
            {
              isRight == true && <div className="treatment-section-main-common-div">
                {selectedTreatment?.length > 0 && (
                  <>
                    <div className="heading-section">
                      <p>Treatments</p>
                      {
                        step !== 2 &&
                        <b
                          onClick={() => {
                            setIsRight(true);
                            setSubstep(301)
                            setStep(2);
                          }}
                          className="cursor-pointer"
                        >
                          Edit
                        </b>
                      }
                    </div>
                    <hr className="mb-0 mt-1" />
                    {selectedTreatment.map((item, index) => (
                      <div className="treatment-section">
                        <div className="treatment-content">
                          <b>{item.name}</b>
                          <p>{`£${item?.amount}.00 • ${item?.duration} mins`}</p>
                        </div>
                        {isRight != true && (
                          <>
                            {" "}
                            <Avatar src={item?.image_url} />
                            {/* <img src="" alt="" /> */}
                          </>
                        )}
                      </div>
                    ))}
                    <hr />
                    <div className="treatment-section">
                      <div className="treatment-content">
                        <b>Total</b>
                      </div>
                      <b>{`£${totalPrice}.00`}</b>
                    </div>
                  </>
                )}
              </div>
            }

            {/* appointment Section  */}
            {isRight == true && <div className="treatment-section-main-common-div">
              {(formatedDate != null && substep >= 301 && step > 2) && (
                <>
                  <div className="heading-section">
                    <p>Appointment details</p>
                    {
                      substep !== 301 &&
                      <b
                        onClick={() => {
                          setSubstep(301);
                          setIsRight(true);
                        }}
                        className="cursor-pointer"
                      >
                        Edit
                      </b>
                    }
                  </div>
                  <hr className="mb-0 mt-1" />
                  <div className="appointment-section">
                    <div className="appointment-content">
                      <p>Date</p>
                      <b>{moment(formatedDate, ("Do MMMM YYYY")).format('M') == 9 ?
                        <>{moment(formatedDate, ("Do MMMM YYYY")).format('Do')} Sept. {moment(formatedDate, ("Do MMMM YYYY")).format('YYYY')}</> :
                        moment(formatedDate, ("Do MMMM YYYY")).format('Do MMM. YYYY')}</b>
                    </div>
                  </div>
                </>
              )}
              {(selectedTimes != null && substep > 301 && step > 2) && (
                <>
                  <div className="appointment-section">
                    <div className="appointment-content">
                      <p>Time</p>
                      <b>{selectedTimes}</b>
                    </div>
                  </div>
                </>
              )}
            </div>}

            {/* TeamMember Section Design */}
            {isRight == true && <div className="treatment-section-main-common-div">
              {(selectedTeamMember != null && substep > 302 && step > 2) && (
                <>
                  <div className="heading-section">
                    <p>Team member</p>
                    {
                      substep != 303 &&
                      <b
                        onClick={() => {
                          setSubstep(303);
                          setIsRight(true);
                        }}
                        className="cursor-pointer"
                      >
                        Edit
                      </b>
                    }
                  </div>
                  <hr className="mb-0 mt-1" />

                  <div className="treatment-section-team">
                    <div className="treatment-content">
                      <b>{selectedTeamMember?.name}</b>
                      <p>{selectedTeamMember?.occupations.join(", ")}</p>
                    </div>

                    <Avatar
                      className="string-color"
                      src={selectedTeamMember?.image}
                      {...stringAvatar(selectedTeamMember?.initials)}
                    />
                  </div>
                </>
              )}
            </div>}

            {
              <>
                {/* Payment History section */}
                {
                  (isRight == true && selectedTreatment.length != 0) &&
                  <div className="treatment-section-main-common-div">

                    <div className="heading-section">
                      <p>Payment History</p>
                    </div>
                    <hr className="mt-1" />
                    <div className="payment-history-section">
                      <p>{`${selectedTreatment?.length} Treatment`}</p>
                      <p>{`£${totalPrice}`}</p>
                    </div>
                    <div
                      className="payment-history-section"

                    >
                      <p className="cursor-pointer" style={{ textDecorationLine: "underline" }} onClick={() => setIsOpen(true)}>Discount </p>
                      <div className="d-flex flex-column mb-2 text-right">
                        <p className="d-flex justify-content-end m-0">£{discountValue}</p>
                        {discountValue !== "0.00" && <span className="text-danger" style={{ textDecorationLine: "underline", cursor: "pointer" }} onClick={() => setIsRemoveOpen(true)}>Remove</span>}
                      </div>
                    </div>
                    <div className="payment-history-section">
                      <p>Subtotal</p>
                      <p>{`£${getTotalPriceAfterDiscount(2)}`}</p>
                    </div>
                    <hr className="mt-1" />
                    <div className="payment-order-section">
                      <b>Order total </b>
                      <b>{`£${getTotalPriceAfterDiscount(0)}`}</b>
                      <p></p>
                    </div>
                  </div>
                }

                {/* Your details section */}
                {
                  isRight != true && <div className="treatment-section-main-common-div">
                    <div className="heading-section" style={{ marginTop: "20px" }}>
                      <p>Your details</p>
                    </div>
                    <hr className="mb-0 mt-1" />
                    <div className="your-details-section">
                      <p>First name</p>
                      <input
                        type="text"
                        name="clientFName"
                        value={yourDetails?.clientFName}
                        onChange={handleDetailsChange}
                        placeholder="First name" />
                      {validationErrors.clientFName && (
                        <span className="validation-error">{validationErrors.clientFName}</span>
                      )}
                    </div>
                    <div className="your-details-section">
                      <p>Last name</p>
                      <input
                        type="text"
                        name="clientLName"
                        value={yourDetails?.clientLName}
                        onChange={handleDetailsChange}
                        placeholder="Last name" />
                      {validationErrors.clientLName && (
                        <span className="validation-error">{validationErrors.clientLName}</span>
                      )}
                    </div>

                    <div className="your-details-section">
                      <p>Add your email address</p>
                      <input
                        type="text"
                        name="clientEmail"
                        value={yourDetails.clientEmail}
                        onChange={handleDetailsChange}
                        placeholder="e.g name@gmail.com"

                      />
                      {validationErrors.clientEmail && (
                        <span className="validation-error">{validationErrors.clientEmail}</span>
                      )}
                    </div>
                  </div>
                }


                {/* Payment Options section */}
                {isRight != true &&
                  (totalPrice !== 0 && <div className="treatment-section-main-common-div">
                    {
                      (businessClinicList?.is_ryft_setup || businessClinicList?.is_stripe_setup || businessClinicList?.is_finance_available) &&
                      <>
                        <div className="heading-section" style={{ marginTop: "20px" }}>
                          <p>Payment options</p>
                        </div>
                        <hr className="mb-0 mt-1" />
                      </>
                    }
                    {
                      businessClinicList?.is_finance_available &&
                      <div className="row">
                        <div className="payment-option-section">
                          <div className="right-payment-option-section ">
                            <MonthPayIcon />

                            <CustomRadioButton
                              text={"Pay in 3, Interest-Free"}
                              id={"Monthly payments"}
                              name="payment"
                              text2={"Opt for monthly with Payl8r."}
                              text3={payl8rData ? "£" + payl8rData["3Month"]?.monthlyPaymentAmount + "*/month for 3 months to give visuals" : null}
                              onChange={() => handlePaymentOptionChange("Monthly payments")}
                              checked={selectedPaymentOption === "Monthly payments"}
                            />
                          </div>
                        </div>
                      </div>
                    }
                    {
                      (businessClinicList?.is_ryft_setup || businessClinicList?.is_stripe_setup) &&
                      <>
                        {
                          !(totalDeposit == 0) &&
                          <div className="payment-option-section">
                            <div className="right-payment-option-section ">
                              <SplitPayIcon />

                              <CustomRadioButton
                                text={"Split payment"}
                                id={"Split payment"}
                                name="payment"

                                onChange={() => handlePaymentOptionChange("Split payment")}
                                checked={selectedPaymentOption === "Split payment"}
                                text2={`£${totalDeposit >= getTotalPriceAfterDiscount(0) ? `` : `${totalDeposit} now, £${getTotalPriceAfterDiscount(0) - totalDeposit} on the day.`}`}
                              />

                            </div>
                            {/* <input type="radio" /> */}
                          </div>
                        }
                        <div className="payment-option-section">
                          <div className="right-payment-option-section ">
                            <PayFullIcon />
                            <CustomRadioButton
                              text={"Pay in full"}
                              id={"Pay in full"}
                              name="payment"
                              text2={`Pay the full £${getTotalPriceAfterDiscount(0)} now.`}
                              onChange={() => handlePaymentOptionChange("Pay in full")}
                              checked={selectedPaymentOption === "Pay in full"}
                            />
                          </div>
                          {/* <input type="radio" checked={true} /> */}
                        </div>

                      </>
                    }
                  </div>)
                }


                {/* Pay with section */}
                {selectedPaymentOption === "Monthly payments" && false ? <>
                  <div className="heading-section" style={{ marginTop: "50px" }}>
                    <p>Pay with</p>
                  </div>
                  <hr className="mb-0 mt-1" />
                  <div
                    className="pay-with-section"
                    onClick={() => setIsOpenPayment(true)}
                  >
                    <div className="right-section">
                      <DebitIcon />
                      <b>Debit or credit card</b>
                    </div>
                    <RightArrow />
                  </div>

                  <div className="pay-with-section" style={{ paddingBottom: "20px" }}>
                    <div className="right-section">
                      <ApplePayIcon />
                      <b>Apple Pay</b>
                    </div>
                    <RightArrow />
                  </div>
                </>
                  :
                  null
                }

                {/* {(businessClinicList?.is_ryft_setup || businessClinicList?.is_stripe_setup || businessClinicList?.is_finance_available) && totalPrice > 0 ?

                 ( (selectedPaymentOption === "Split payment" || selectedPaymentOption === "Pay in full")) ? <StepButton
                    blue={true}
                    id={"ryft-pay-btn"}
                    label={selectedPaymentOption === "Split payment" ? totalDeposit == 0 ? "Complete booking" : `Pay £${totalDeposit} now` : `Pay £${totalPrice} now`}
                    isLoading={loader}
                    onClick={() => handleConfirmBooking()}
                  /> : selectedPaymentOption === "Monthly payments" ? <StepButton blue={true} label={"Buy now Payl8r"} isLoading={loader} onClick={() => payl8rMiddleFunction()} />

                    : totalDeposit == 0 ? <StepButton
                    label={"Select Payment Option"}
                    disabled={true}
                  /> :
                     isRight == false && <StepButton
                        label={"Select Payment Option"}
                        disabled={true}
                      />
                  :
                  isRight != true && 
                  <StepButton
                    blue={true}
                    label={"Complete booking"}
                    disabled={!yourDetails.clientFName || !yourDetails.clientLName || !yourDetails.clientEmail || validationErrors.clientEmail }
                    onClick={() => handleConfirmBooking()}
                  />

                } */}
                {console.log("isOpenPayment", isOpenPayment)}
                <DiscountModal clinicId={clinicId} isOpen={isOpen} setIsOpen={setIsOpen} className="m-auto w-100" />
                <CustomModal
                  modalOpen={isOpenPayment}
                  setModalOpen={setIsOpenPayment}
                  modalBody={<PaymentWebModal />}
                  modalTitle={"Pay with card"}
                  className="CustomePaywithcard"
                />

                <Modal className={"cancel-modal"}
                  isOpen={isRemoveOpen}
                  style={{
                    overlay: {
                      background: "rgba(0, 0, 0, 0.5)"
                    },
                    content: {
                      borderRadius: "30px",
                      height: "fit-content",
                      width: "fit-content",
                      transform: "translate(-5%, 50%)"

                    }
                  }}
                >
                  <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                      <CloseIcon className="cursor-pointer" onClick={() => { setIsRemoveOpen(false) }} />
                    </div>
                    <div className='cancle-popup-container mt-5'>
                      <h1>Are you sure?</h1>
                      <p>Do you really want to remove discount code?</p>
                      <div className="d-flex justify-content-between align-items-center w-100">


                        <div className="w-100">
                          <StepButton red={true} label={"Remove"} isLoading={removeLoading} onClick={() => { handleRemoveDiscount() }} />

                        </div>
                      </div>
                    </div>
                  </>
                </Modal>

                {/* */}

              </>
            }
          </div>

          <SuccessModal isOpen={requestSendModal} setIsOpen={setRequestSendModal} title={""} subTitle={"Sorry but practitioner is not accepting buy now pay later requests, however, we have informed them of your interest regarding this. Please choose any other payment method for the moment"} />
        </div>
  );
};


export default ConfirmPayWeb;
