import React, { useState } from 'react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import docDropzoneIcon from '../../../images/finance/docDropzoneIcon.svg'

const DocDropzone = ({ onDrop, onRemove }) => {
  const [preview, setPreview] = useState(null);

  const handleDrop = (acceptedFiles) => {
    // Display file preview
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);

    // Pass uploaded files to parent component
    onDrop(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: 'image/*', // Accept only image files
  });

  const removeImage = (e) => {
    e.preventDefault();
    onRemove();
    setPreview(null);
  };

  return (
    <div {...getRootProps()} className="dropzone">
      <input {...getInputProps()} />
      {preview ? (
        <>
          <img src={preview} alt="File preview" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          <button onClick={removeImage} className="remove-button">
              X
          </button>
        </>
      ) : (
        <>
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <>
              <img src={docDropzoneIcon} style={{display: 'block !important'}}></img>
              <span className="upload-text" style={{display: 'block !important'}}>Upload</span>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DocDropzone;
