import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_FINANCE_REGISTATION_STATUS=gql`
query GetUserRegistrationStatusForFinance {
  getUserRegistrationStatusForFinance {
    success
    message
    data {
      steps {
        key
        title
        description
        is_completed
      }
      identity_document
      training_certificate
      insurance_document
      registration_complete
      onboardingCompletionPercentage
      payl8r_request_approved
    }
  }
}
`;

export const GetFinanceRegistrationStatusAction=()=>{
    const [getFinanceRegistrationResponseFunction,
    getFinanceRegistrationResponseData]=useLazyQuery(GET_FINANCE_REGISTATION_STATUS,{
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted:({getUserRegistrationStatusForFinance:responseData})=>{
            if(responseData.success){
                console.log("res",responseData?.message)
            }else{
                console.log("error",responseData?.message)
            }
        }
    })

    const getFinanceRegistrtionStatusQueryData=getFinanceRegistrationResponseData;

    const initGetFinanceRegistrationData=(data)=>{
        getFinanceRegistrationResponseFunction({
                variables:data
        })
    }

    return {getFinanceRegistrtionStatusQueryData,initGetFinanceRegistrationData}
}