// ==========================GET REVIEW LIST==========================

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { GET_APPOINTMENT_LIST_SUCCESS, GET_ONBOARDING_STATUS_ERROR, GET_ONBOARDING_STATUS_SUCCESS } from "../../constants";

const GET_USER_APPOINTMENT_LIST_QUERY = gql`
query GetAppointmentList($page: Int, $limit: Int, $appointmentListDayType: String, $clientId: Int, $startDate: String, $endDate: String, $locations: [Int], $status: [Int], $includeBlockouts: Boolean, $search: String) {
  getAppointmentList(page: $page, limit: $limit, appointment_list_day_type: $appointmentListDayType, client_id: $clientId, start_date: $startDate, end_date: $endDate, locations: $locations, status: $status, includeBlockouts: $includeBlockouts, search: $search) {
    success
    message
    data {
      total_appointments
      next_appointment_date
      vendorIsPharmacy
      appointment_list {
        cld_rc_id
        client_name
        cld_rc_start
        cld_rc_end
        location_name
        treatment_name
        treatment_count
        clinic_color
        deposit_status
        deposit_status_color
        calender_date
        booking_slug
        treatmentIds
        isFilled
        clientId
        type
        blockoutStartDate
        blockoutEndDate
        blockOutId
        blockOutDateId
        appointmentTitle
        consentFormRecordId
        totalDuration
        price
        paymentTag
        paymentTagColor
        paymentType
        consentFormTag
        consentFormTagColor
        isPaymentDone
        consentFormCount
        }
    }
  }
}
`;

export const GetAppointmentListAction = () => {
  const dispatch = useDispatch();
  const [
    getAppointmentListResponseFunction,
    getAppointmentListResponseData,
  ] = useLazyQuery(GET_USER_APPOINTMENT_LIST_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getAppointmentList: responseData }) => {
      if (responseData.success) {
        dispatch({
          type: GET_APPOINTMENT_LIST_SUCCESS,
          payload: responseData,
        });
        console.log('dispatched GetAppointmentListAction');
        // toast.success(responseData?.message)
        // GET_ONBOARDING_STATUS_SUCCESS
      } else {
        dispatch({
          type: GET_ONBOARDING_STATUS_ERROR,
          payload: responseData,
        })
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getAppointmentListQueryData = getAppointmentListResponseData; //variable

  const initGetAppointmentListData = (data) => {
    getAppointmentListResponseFunction({
      variables: data,
    }).then((res) => {
      if (res?.data?.getAppointmentList?.success) {
        console.log('res success', res);
        // successCallback(res);
      } else {
        console.log('res error', res);
        // errorCallback(res);
      }
    }).catch((err) => {
      console.log('res err', err);
      // errorCallback(err);
    });
  };

  return { getAppointmentListQueryData, initGetAppointmentListData };
};
