import React from "react";
import "./create-new-form.scss";
import NavHeader from "../../common/NavHeader/NavHeader";
import StepButton from "../../common/StepButton";
import { useNavigate, useParams } from "react-router";
import deleteIcon from "../../../images/DeleteActionIcon.svg";
import crossArrow from '../../../images/Icon example.svg'
import { IoMdInformationCircleOutline } from "react-icons/io";

const CreateNewForm = () => {
  const navigate = useNavigate();
  const { formName } = useParams();
  const handleBack = () => {
    // go to the previous page
    navigate(-1);
  };

  return (
    <>
      <div className="main-create-new-form">
        <NavHeader
          btnLabel={"Add question"}
          applyPadding={false}
          isBackWithButton={true}
          //  onClickCallback={""}
        />
        <div className="form-section">
          <div className="SectionHeading">
            <div className="Section-Title">{formName}</div>
          </div>

          <div className="appointment-info-card">
            <div className="appointment-info-card-icon">
              <div className="LeftAppointment">
                <IoMdInformationCircleOutline />
              </div>
              <span className="walk-in-title">
                <p>Keep your questions clear and concise, ideally requiring simple 'Yes' or 'No' responses.</p>
              </span>
              <div className="rightAppointment">
                <img src={crossArrow} alt="crossArrow" />
              </div>
            </div>
          </div>
          <div className="ques-list">
            <div className="ques-list-item">
              <p>This is a disclaimer default text. </p>
            </div>
            <div className="remove-ques">
              <p>Done</p>
              <img src={deleteIcon} alt="remove" />
            </div>
          </div>

          <div className="btn-Wrapper">
             <StepButton blue={true} className='outlineBorder' label={"Add question"} />
             <StepButton blue={true} label={"Save form"} onClick={handleBack} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewForm;
