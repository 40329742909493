import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";

const GET_USER_REGISTRATION_STEPS_QUERY = gql`
query GetPrescriberRegistrationSteps {
  getPrescriberRegistrationSteps {
    success
    message
    data {
      steps {
        key
        title
        description
        is_completed
      }
      identityDocument
      trainerDocument
      insuranceDocument
      termsAndConditionsApprove
      isRequestSentToAdmin
      showCheckBox
      onboardingCompletionPercentage
    }
  }
}
`;

export const GetUserRegistrationSteps = () => {
  const dispatch = useDispatch();
  const [getUserRegistrationSteps, { loading, error, data }] = useLazyQuery(GET_USER_REGISTRATION_STEPS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: (response) => {
      // Handle the completed response here if necessary
    },
  });

  const initGetUserRegistrationSteps = () => {
    getUserRegistrationSteps();
  };

  return { loading, error, data,initGetUserRegistrationSteps};
};
