import React, { useEffect, useRef, useState } from 'react'
import FinanceBanner from '../FinanceHomeComponent/FinanceBanner/FinanceBanner'
import "./FinanceBusinessDetails.scss"
import TextInput from '../../common/TextInput'
import CustomDropdown from '../../common/dropdown/CustomDropdown'
import StepButton from '../../common/StepButton'
import { SaveFinanceBusinessDetailAction } from "../../redux/actions/finance-registrtion/saveFinanceBusinessDetail"
import { GetFinanceBusinessDetailAction } from "../../redux/actions/finance-registrtion/getFinanceBusinesDetail"
import { GetFinanceRegistrationStatusAction } from "../../redux/actions/finance-registrtion/getFinanceRegistartionStatus"
import { ToastContainer, toast } from 'react-toastify';

import CustomAutoComplete from '../../auto-complete/CustomAutoComplete'
import { GetPrescriberOccupationList } from '../../redux/actions/pharmacy-registration/occupationList'
import { webBaseUrl } from "../../../config"
import { getFinanceRegistrationRoute } from "../../../Routes"

const FinanceBusinessDetails = ({ onNextStep, afterSubmit, setIsCompletedStep, setComeFromDashBord, finish }) => {
	const BusinessStatus = ["Sole trader", "Limited Company"]
	const Occupation = [""]
	const [businessDetails, setBusinessDetails] = useState({
		houseRegisterNumber: "",
		businessStatus: "",
		businessOwnerName: "",
		businessTradingName: "",
		occupation: "",
		address: {
			houseNo: "",
			streetName: "",
			city: "",
			zipcode: ""
		}
	})
	const [value, setValue] = useState([]);
	const [optionValue, setOptionValue] = useState([]);
	const [pinStatus, setPinStatus] = useState(0);
	const [professionalPin, setProfessionalPin] = useState({
		pin: "",
		expiry: "",
	});
	const { saveFinanceBusinessQueryData, initSaveFinanceBusinessDetailData } = SaveFinanceBusinessDetailAction();
	const { getFinanceBusinessDetailsQueryData, initGetFinanceBusinessDetailData } = GetFinanceBusinessDetailAction();
	const { getFinanceRegistrtionStatusQueryData, initGetFinanceRegistrationData } = GetFinanceRegistrationStatusAction()

	console.log("statu2", getFinanceRegistrtionStatusQueryData?.data?.getUserRegistrationStatusForFinance?.data?.steps[1]?.is_completed

	)

	// const {data}=getFinanceBusinessDetailsQueryData;

	const completedStep = getFinanceRegistrtionStatusQueryData?.data?.getUserRegistrationStatusForFinance?.data;
	console.log("completedStep2", completedStep);

	const res = getFinanceBusinessDetailsQueryData;

	const houseRegisterNumberRef = useRef()
	const businessStatusRef = useRef()
	const businessOwnerNameRef = useRef()
	const businessTradingNameRef = useRef()
	const houseNoRef = useRef()
	const streetNameRef = useRef()
	const cityRef = useRef()
	const zipcodeRef = useRef()
	const occupationRef = useRef()
	const helthcarePinRef = useRef()
	const [shouldScroll, setshouldScroll] = useState(false);
	console.log("data", getFinanceBusinessDetailsQueryData?.data?.getFinanceBusinessDetailsOfUser?.data)

	const [validationErrors, setValidationErrors] = useState({
		houseRegisterNumber: "",
		businessStatus: "",
		businessOwnerName: "",
		businessTradingName: "",
		occupation: "",

		address: {
			houseNo: "",
			streetName: "",
			city: "",
			zipcode: ""
		}
	});
	const [businessData, setBusinessData] = useState(null);
	console.log("saveFinanceBusinessQueryData", saveFinanceBusinessQueryData)
	const customStyle = {

		menuPortal: base => ({
			...base,
			zIndex: 9999 // Adjust the z-index value according to your requirement
		}),
		control: (styles, state) => ({
			...styles, backgroundColor: '#FFFFFF',
			outline: "none",
			appearance: "none",
			padding: "10px 10px",
			borderRadius: "10px",
			border: "1px solid #878787",
			height: "55px",
			width: "100%",
		}),
		option: (styles, { isDisabled, isFocused, isSelected }) => {

			return {
				...styles,
			};
		},
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

	useEffect(() => {
		if (shouldScroll) {
			if (businessOwnerNameRef.current) {
				businessOwnerNameRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})

			}
			else if (businessTradingNameRef.current) {
				businessTradingNameRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})

			}
			else if (businessStatusRef.current) {
				businessStatusRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})

			}
			else if (occupationRef.current) {
				occupationRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})
			}
			else if (helthcarePinRef.current) {
				helthcarePinRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})
			}
			else if (houseRegisterNumberRef.current) {
				houseRegisterNumberRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})

			}
			else if (houseNoRef.current) {
				houseNoRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})

			}
			else if (streetNameRef.current) {
				streetNameRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})
			}
			else if (cityRef.current) {
				cityRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})
			}
			else if (zipcodeRef.current) {
				zipcodeRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})

			}
			setshouldScroll(false)
		}


	}, [validationErrors])

	// useEffect(() => {
	//   e  if (businessTradingNameRef.current) {
	//         businessTradingNameRef.current.scrollIntoView({
	//             behavior: "smooth",
	//             block: "center",
	//         })

	//     }
	// }, [validationErrors])

	// useEffect(() => {
	//     if (businessStatusRef.current) {
	//         businessStatusRef.current.scrollIntoView({
	//             behavior: "smooth",
	//             block: "center",
	//         })

	//     }
	// }, [validationErrors])

	// useEffect(() => {
	//     if (houseRegisterNumberRef.current) {
	//         houseRegisterNumberRef.current.scrollIntoView({
	//             behavior: "smooth",
	//             block: "center",
	//         })

	//     }
	// }, [validationErrors])

	// useEffect(() => {
	//     if (houseNoRef.current) {
	//         houseNoRef.current.scrollIntoView({
	//             behavior: "smooth",
	//             block: "center",
	//         })

	//     }
	// }, [validationErrors])


	// useEffect(() => {
	//     if (streetNameRef.current) {
	//         streetNameRef.current.scrollIntoView({
	//             behavior: "smooth",
	//             block: "center",
	//         })
	//     }
	// }, [validationErrors])


	// useEffect(() => {
	//     if (cityRef.current) {
	//         cityRef.current.scrollIntoView({
	//             behavior: "smooth",
	//             block: "center",
	//         })
	//     }
	// }, [validationErrors])

	// useEffect(() => {
	//     if (zipcodeRef.current) {
	//         zipcodeRef.current.scrollIntoView({
	//             behavior: "smooth",
	//             block: "center",
	//         })
	//     }
	// }, [validationErrors])
	console.log(businessDetails.businessStatus, "2200000")
	const handleContinue = () => {


		const errors = {};
		// Check if businessStatus is selected
		if (!businessDetails.businessStatus || businessDetails.businessStatus == 'Select') {
			errors.businessStatus = "Business status is required";

		}

		// Check if Companies House Registration Number is required for Limited Company
		if (businessDetails.businessStatus === "Limited Company" && !businessDetails.houseRegisterNumber) {
			errors.houseRegisterNumber = "Companies House Registration Number is required for Limited Company";
		}

		// Check if any field in the address is empty
		if (!businessDetails.address.houseNo) {
			errors.address = {
				...errors.address,
				houseNo: "House number is required"
			};
		}

		if (!businessDetails.address.streetName) {
			errors.address = {
				...errors.address,
				streetName: "Street name is required"
			};
		}

		if (!businessDetails.address.city) {
			errors.address = {
				...errors.address,
				city: "City is required"
			};
		}

		if (!businessDetails.address.zipcode) {
			errors.address = {
				...errors.address,
				zipcode: "Postcode is required"
			};
		}

		// Check if any other field is empty
		if (!businessDetails.businessOwnerName) {
			errors.businessOwnerName = "Business owner name is required";
		}

		if (!businessDetails.businessTradingName) {
			errors.businessTradingName = "Business trading name is required";
		}
		if (!value?.length > 0) {
			errors.occupation = "Occupation is required";
		}
		if (pinStatus === 2 && !professionalPin.pin) {
			errors.pin = "Health care pin is required";
		}

		setValidationErrors(errors);
		setshouldScroll(true)

		if (Object.values(errors).some((error) => error)) {
			if (businessOwnerNameRef.current) {
				businessOwnerNameRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})

			}
			else if (businessTradingNameRef.current) {
				businessTradingNameRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})

			}
			else if (businessStatusRef.current) {
				businessStatusRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})

			}
			else if (occupationRef.current) {
				occupationRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})
			}
			else if (helthcarePinRef.current) {
				helthcarePinRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})
			}
			else if (houseRegisterNumberRef.current) {
				houseRegisterNumberRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})

			}
			else if (houseNoRef.current) {
				houseNoRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})

			}
			else if (streetNameRef.current) {
				streetNameRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})
			}
			else if (cityRef.current) {
				cityRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})
			}
			else if (zipcodeRef.current) {
				zipcodeRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})

			}
			return;
		} else {
			initSaveFinanceBusinessDetailData({
				businessOwnerName: businessDetails?.businessOwnerName,
				businessTradingName: businessDetails?.businessTradingName,
				businessStatus: businessDetails.businessStatus,
				houseNo: businessDetails?.address.houseNo,
				streetName: businessDetails?.address.streetName,
				city: businessDetails?.address?.city,
				zipcode: businessDetails?.address?.zipcode,
				companyHouseRegNo: businessDetails?.houseRegisterNumber,
				healthcarePin: professionalPin?.pin,
				occupation: value?.length > 0 &&
					value?.map((occupation) => occupation.id),

			}, (data) => {

				toast.success("Finance Business details update!")
				afterSubmit()
				onNextStep();
				setComeFromDashBord(false)

			}, (error) => console.log(error))
			// setIsCompletedStep()
		}

	}
	useEffect(() => {
		initGetFinanceRegistrationData()
	}, [])




	useEffect(() => {
		initGetFinanceBusinessDetailData({}, (res) => {
			console.log("resss", res)
			setBusinessData(res?.data);
			const selectedOccupations = optionValue?.filter((option) =>
				res?.data?.occupation.includes(option.id)
			);
			setValue(selectedOccupations);

			const newPinStatus = selectedOccupations.reduce((maxPin, option) => {
				return Math.max(maxPin, option.pin_status);
			}, 0);

			setPinStatus(newPinStatus);
			setProfessionalPin({
				pin: res?.data?.healthcare_pin,
			});
			// setOptionValue(res?.data?.occupation)
		},
			(err) => {
				console.log("errr", err)
			}
		)
	}, [res?.data, optionValue])
	console.log("optionval", value)
	// Handle change for all input fields
	const handleChange = (field, value, nestedField = null) => {
		setBusinessDetails((prevData) => {
			if (nestedField) {
				return {
					...prevData,
					[field]: {
						...prevData[field],
						[nestedField]: value
					}
				};
			} else {
				return {
					...prevData,
					[field]: value
				};
			}
		});

		// Clear validation error for the field
		setValidationErrors((prevErrors) => {
			if (nestedField) {
				return {
					...prevErrors,
					[field]: {
						...prevErrors[field],
						[nestedField]: ""
					}
				};
			} else {
				return {
					...prevErrors,
					[field]: ""
				};
			}
		});
	};


	const occupationArray = ["Prescriber", "Dr", "Nurse", "Pharmacist", "Pharmacy Technician", "Pharmacy Assistant", "Other"]
	const { initGetPrescriberOccupationList, data } = GetPrescriberOccupationList();

	useEffect(() => {
		initGetPrescriberOccupationList()
	}, [])


	useEffect(() => {
		if (businessData) {

			let businessTypeValue = "";
			if (businessData?.business_type === 2) {
				businessTypeValue = "Sole trader";
			} else if (businessData?.business_type === 1) {
				businessTypeValue = "Limited Company";
			}

			setBusinessDetails({
				...businessDetails,
				businessType: {
					id: businessData?.business_type,
					value: businessTypeValue,
				},
				businessName: businessData?.business_name,
				vatNumber: businessData?.vat_number,
			});
			console.log("val?.occupations", businessData?.occupations)
			// const selectedOccupations = optionValue?.filter((option) =>
			// val?.occupations.includes(option.id)
			// );
			// setValue(selectedOccupations);
			// const newPinStatus = selectedOccupations.reduce((maxPin, option) => {
			//     return Math.max(maxPin, option.pin_status);
			// }, 0);
			// setPinStatus(newPinStatus);


			setBusinessDetails({
				houseRegisterNumber: businessData.company_registration_number || "",
				businessStatus: !businessData.business_status || businessData.business_status === "Default" ? "Select" : businessData.business_status,
				businessOwnerName: businessData.business_owner_name || "",
				businessTradingName: businessData.business_trading_name || "",
				address: {
					houseNo: businessData.house_no || "",
					streetName: businessData.street_name || "",
					city: businessData.city || "",
					zipcode: businessData.postcode || ""
				}
			});
		}
	}, [data?.getFinanceBusinessDetailsOfUser?.data, businessData])

	useEffect(() => {
		if (data && data?.getPrescriberOccupationList) {
			const optionList = data?.getPrescriberOccupationList?.data
				?.map((category) =>
					category.occupations?.map((occupation) => ({
						...occupation,
						category: category.category,
						title: occupation.title,
					}))
				)
				.flat();
			setOptionValue(optionList);
		}
	}, [data]);


	const handleLOccupationChange = (value) => {
		setBusinessDetails((prevData) => ({
			...prevData,
			occupation: value
		}));
	}

	const handleOptionChange = (event, newValue) => {
		console.log("value", value)

		const newPinStatus = newValue.reduce((maxPin, option) => {
			return Math.max(maxPin, option.pin_status);
		}, 0);
		setValue(newValue);
		setPinStatus(newPinStatus);
		setValidationErrors({
			occupation: ""
		})
	};

	console.log("businessDetails?.businessStatus", businessDetails?.businessStatus)
	return (
		<div className='finance-details-container'>
			<div className='finance-details-child-container'>
				<FinanceBanner
					title="Business details"
					desc="Tell us about your business."
					isBorder={!completedStep?.registration_complete && completedStep?.registration_complete !== undefined}
					currentStep={finish}
					totalSteps={4}
					isTranningBanner={false}
				/>
				<div className="first-flex-div">

					<div className='finance-input-container'>
						{/* Business owner name */}
						<TextInput
							label={"Business owner name "}
							placeholder={"Business owner name "}
							value={businessDetails?.businessOwnerName}
							onChange={(e) => handleChange("businessOwnerName", e.target.value)}
						/>
						{validationErrors?.businessOwnerName && <span ref={businessOwnerNameRef} className="error">{validationErrors?.businessOwnerName}</span>}
					</div>

					<div className='finance-input-container'>
						{/* Business trading name */}
						<TextInput
							label={"Business trading name "}
							placeholder={"Business trading name "}
							value={businessDetails?.businessTradingName}
							onChange={(e) => handleChange("businessTradingName", e.target.value)}
						/>
						{validationErrors?.businessTradingName && <span ref={businessTradingNameRef} className="error">{validationErrors?.businessTradingName}</span>}
					</div>

					<div className="finance-input-container Custome-Business-status">
						{/* Business status */}
						<CustomDropdown

							labelName={"Business status"}
							defaultValue={businessDetails?.businessStatus}
							dropdownId={"businessStatus"}
							optionsArr={BusinessStatus}
							style={customStyle}
							handleSelectedValue={(value) => handleChange("businessStatus", value)}
							readOnly={false}
						/>
						{validationErrors?.businessStatus && <span ref={businessStatusRef} className="error">{validationErrors?.businessStatus}</span>}

					</div>

					{businessDetails.businessStatus === "Limited Company" &&
						<div className='finance-input-container'>
							{/* Companies House Registration Number*/}
							<TextInput
								label={"Companies House Registration Number"}
								placeholder={"Companies House Registration Number"}
								value={businessDetails?.houseRegisterNumber}
								onChange={(e) => handleChange("houseRegisterNumber", e.target.value)}
							/>
							{validationErrors?.houseRegisterNumber && <span ref={houseRegisterNumberRef} className="error">{validationErrors?.houseRegisterNumber}</span>}
						</div>
					}

					<div className="finance-input-container Custome-Occupaton-status">
						<label htmlFor="occupation" className="form-label">
							Occupation
						</label>
						{optionValue?.length != 0 && (
							<CustomAutoComplete
								className="custom-occupation"
								style={{
									borderRadius: "10px",
									border: "1px solid #878787",
									background: "#fff",
								}}
								multiple={true}
								groupBy={true}
								optionValue={optionValue}

								placeholder={value.length === 0 ? "select..." : ""}
								value={value}
								setValue={setValue}
								handleOptionChange={handleOptionChange}
							/>
						)}
						{validationErrors.occupation && (
							<span ref={occupationRef} className="error">{validationErrors.occupation}</span>
						)}
					</div>
					{(pinStatus === 2 || pinStatus === 1) && (
						<>
							<div className="finance-input-container">
								{/* PIN */}
								<TextInput
									label={"Healthcare pin"}
									placeholder={"Healthcare pin"}
									value={professionalPin?.pin}
									onChange={(e) =>
										setProfessionalPin((prevData) => ({
											...prevData,
											pin: e.target.value,
										}))
									}
								/>
								{validationErrors.pin && (
									<span ref={helthcarePinRef} className="error">{validationErrors.pin}</span>
								)}
							</div>
						</>)
					}


				</div>
				<div className="second-flex-div">
					<p className='fields-title'>Basic info</p>
					<div className='finance-input-container'>
						{/* House name or number */}
						<TextInput
							label={"House name or number"}
							placeholder={"House name or number"}
							name="houseNo"
							value={businessDetails?.address?.houseNo}
							onChange={(e) => handleChange("address", e.target.value, "houseNo")}
						/>
						{validationErrors?.address?.houseNo && <span ref={houseNoRef} className="error">{validationErrors?.address?.houseNo}</span>}

					</div>

					<div className='finance-input-container'>
						{/* Street name */}
						<TextInput
							label={"Street name"}
							placeholder={"Street name"}
							value={businessDetails?.address?.streetName}
							onChange={(e) => handleChange("address", e.target.value, "streetName")}
						/>
						{validationErrors?.address?.streetName && <span ref={streetNameRef} className="error">{validationErrors?.address?.streetName}</span>}

					</div>

					<div className='finance-input-container'>
						{/* City */}
						<TextInput
							label={"City"}
							placeholder={"City"}
							value={businessDetails?.address?.city}
							onChange={(e) => handleChange("address", e.target.value, "city")}
						/>
						{validationErrors?.address?.city && <span ref={cityRef} className="error">{validationErrors?.address?.city}</span>}

					</div>

					<div className='finance-input-container'>
						{/* Postcode */}
						<TextInput
							label={"Postcode"}
							placeholder={"Postcode"}
							value={businessDetails?.address?.zipcode}
							onChange={(e) => handleChange("address", e.target.value, "zipcode")}
						/>
						{validationErrors?.address?.zipcode && <span ref={zipcodeRef} className="error">{validationErrors?.address?.zipcode}</span>}

					</div>
				</div>
				<div className="third-flex-div">
					<StepButton label={"Continue"}
						isLoading={saveFinanceBusinessQueryData.loading}
						blue={true} onClick={handleContinue} />
				</div>
			</div>
		</div>
	)
}

export default FinanceBusinessDetails