import React, { useEffect, useState } from 'react'
import "./HairProductCardNew.scss"
import HairPlaceHolderImg from '../../../../../images/hairProductplaceholder.png'
import PropTypes from "prop-types"
import { GrFormCheckmark } from "react-icons/gr";


const HairProductCardNew = ({ isSummary,setIntevalMonth,icon, title, desc, price, minicard, selected, interval, onSelect, className, onemonthprice, threemonthprice,SaveHaiPrescriptionRequestMutationData }) => {
    
    const [savingPercentage, setSavingPercentage] = useState(0);
    const [multiMonthSaving, setMultiMonthSaving] = useState(0);
    const Shipping = 4.99;

    const calculateSavingPercentage = () => {
        const saving = ((onemonthprice * 3)) - ((Number(threemonthprice)).toFixed(2));
        const percentage = (saving / ((onemonthprice * 3))) * 100;
        return percentage;
    }
  
   const handleMonthChanges=(value)=>{
         setIntevalMonth(value)
    } 
  
    const calculateMultiMonthSaving = () => {
        // Calculate multi-month saving based on the interval
        if (interval === 1) {
            // No multi-month saving for monthly subscription
            return 0;
        } else if (interval === 3) {
            // Calculate multi-month saving for 3-month subscription
            return (selected?.price * 3) - (selected?.threemonthPrice);
        } else {
            // Add more conditions for other intervals if needed
            return 0;
        }
    }

    
     useEffect(() => {
        // // Calculate and set multi-month saving whenever interval changes
        const saving = calculateMultiMonthSaving();
        setMultiMonthSaving(saving);

        // Calculate and set saving percentage whenever interval changes
        const percentage = calculateSavingPercentage();
        setSavingPercentage(percentage);
    }, [interval]);

    const calculatePercentageSaving = () => {
        // Calculate multi-month saving based on the interval
        if (interval === 1) {
            // No multi-month saving for monthly subscription
            return 0;
        } else if (interval=== 3) {
            // Calculate multi-month saving for 3-month subscription
            return ((selected?.price * 3) + Shipping) - ((Number(selected?.threemonthPrice) + Shipping).toFixed(2));
        } else {
            // Add more conditions for other intervals if needed
            return 0;
        }
    }
    console.log("selected",selected)

    return (
        <div className={`hair-product-card-new ${className} ${minicard ? 'minicard' : ''} ${!minicard && selected ? 'selected' : ''}`} onClick={onSelect}>
            <div className='left-img'>
                <div className='placeholderWrapper'>
                    <div className='staticImg'>
                        <img src={HairPlaceHolderImg} alt={HairPlaceHolderImg}/>
                    </div>

                    <div className='dynamicImg'>
                    <img src={icon} alt={title}/>
                    </div>
                </div>
                {/*  */}
            </div>
            <div className="right-content">
                <div className='d-flex flex-column gap-1 hair-product-detail'>
                    <h3 className={`hair-product-title m-0 ${minicard ? 'minicard-text' : ''}`}>{title}</h3>
                    <p className={`hair-product-desc m-0 ${minicard ? 'minicard-text' : ''}`}>{desc}</p>
                </div>
                <div>
               {/* { */}
                  {/* selected  ? */}
                    {/* <h3 className={`hair-product-price m-0 ${minicard ? 'minicard-text' : ''}`}>£{interval == 1 ? onemonthprice : interval===3 ? threemonthprice :null}/{interval === 3 ? 3 : null}&nbsp;month</h3> */}
                    {/* : */}
                    {/* <h3 className={`hair-product-price m-0 ${minicard ? 'minicard-text' : ''}`}>£{ onemonthprice }/{selected && interval === 3 ? 3 : null}&nbsp;month</h3> */}
                  {/* } */}
                 
                  {
                   isSummary ?
                    <h3 className={`hair-product-price m-0 ${minicard ? 'minicard-text' : ''}`}>£{interval == 1 ? onemonthprice : threemonthprice}/{interval === 3 ? 3 : null}&nbsp;month</h3>
                   :
                    <h3 className={`hair-product-price m-0 ${minicard ? 'minicard-text' : ''}`}>£{Number(price).toFixed(2)}/{interval === 3 ? 3 : null}&nbsp;month</h3>
                }
                </div>
            </div>
            
            {
                !minicard ?
                    <div className="delivery-frequency">
                        <h3>Delivery frequency</h3>
                        <div className='PlanMonthWrapper'>
                            <div className={`first-month ${selected && interval===1 ? 'selectedMonth' : ''}`} id='check1' onClick={()=>handleMonthChanges(1)}>
                                <div>
                                    <p className='month'>1 month</p>
                                    <p>£{onemonthprice}/month</p>
                                <span className='checkIconWrapper'>
                                    {
                                        selected && interval === 1 ?
                                        <GrFormCheckmark /> 
                                        :
                                        ""
                                    }
                                </span>
                                </div>
                            
                            </div>
                            <div className={`third-month d-flex justify-content-between ${selected && interval===3 ? 'selectedMonth' : ''} `} id='check2' onClick={()=>handleMonthChanges(3)}>
                                <div>
                                    <p className='month'>3 month</p>
                                    <p>£{threemonthprice}/3 month</p>

                            <span className='checkIconWrapper'>
                                {
                                    selected && interval === 3 ?
                                    <GrFormCheckmark /> 
                                    :
                                    ""
                                }
                            </span>
                                </div>
                                <div className='savings'>
                                   
                                    Save <span className='savingsPer'>{savingPercentage.toFixed(2)}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <></>
            }
        </div>
    )
}


HairProductCardNew.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    minicard: PropTypes.bool,
    selected: PropTypes.bool,
    interval: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
}

export default HairProductCardNew