import React from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as ConsentFormEditIcon } from '../../../images/icons/appointment-list/ConsentFormEditIcon.svg'
import { ReactComponent as DownloadIcon } from '../../../images/icons/appointment-list/download.svg'
import { ReactComponent as MedicalFormEditIcon } from '../../../images/icons/appointment-list/MedicalFormEditIcon.svg'
import { ReactComponent as ResendBookingAndConsentIcon } from '../../../images/icons/appointment-list/ResendBookingAndConsent.svg'
import { ReactComponent as EyeIcon } from "../../../images/icons/circleEyeIcon.svg"
import { ReactComponent as SendToPresciberIcon } from '../../../images/icons/SendtoPrescriber.svg'
import { ReactComponent as CopyIcon } from '../../../components/assets/images/calendar/copyIcon.svg'


const ClientFormsViewPopup = ({ handleMenuClick, isFilled, active }) => {
    const { UserProfileData } = useSelector(state => state?.GetUserProfileDataReducer)
    console.log("userrr", (UserProfileData?.user?.userRoles?.includes(3)))
    const menuData = [
        // {
        //     title: "Duplicate form",
        //     icon: <CopyIcon />,
        // },

        // {
        //     title: "Edit",
        //     icon: <EditIcon />,
        // },
        {
            title: "Repeat form",
            icon: <CopyIcon width={40} />,
        },
        {
            title: "Edit consent form",
            icon: <ConsentFormEditIcon width={40} />
        },
        {
            title: "Edit medical form",
            icon: <MedicalFormEditIcon width={40} />
        },
        {
            title: "Complete in person",
            icon: active === "Consent" ? <ConsentFormEditIcon width={40} /> : <MedicalFormEditIcon width={40} />
        },
        {
            title: "Re-send to client",
            icon: <ResendBookingAndConsentIcon width={40} />
        },
        {
            title: "Send to client",
            icon: <ResendBookingAndConsentIcon width={40} />,
        },
        {
            title: "Send to prescriber",
            icon: <SendToPresciberIcon width={40} />,
            // isNotShow: !(UserProfileData?.user?.userRoles?.includes(3))
        },
        {
            title: "Download PDF",
            icon: <DownloadIcon width={40} />,
        },
    ]

    return (
        <>
            <div className='mt-4 appointment-popup'
            >
                {
                    menuData.
                        filter((items) => {
                            if (isFilled) {
                                // If isFilled is true, hide "Resend booking details"
                                return active === "Consent" ? items.title !== 'Re-send to client' && items?.title !== "Complete in person" && items?.title !== "Edit medical form" : items.title !== 'Re-send to client' && items?.title !== "Complete in person" && items?.title !== "Edit consent form" && items?.title !== "Repeat form"
                            }
                            else {
                                // If isFilled is false, hide "View consent"
                                return items.title === 'Re-send to client' || items.title === "Complete in person"
                            }
                        }).
                        map((items, index) => (
                            <>
                                {!(items?.title === "Send to prescriber" && UserProfileData?.user?.userRoles?.includes(3)) && <div className='d-flex justify-content-between align-items-center flex-start mt-2 cursor-pointer' onClick={() => { handleMenuClick(items?.title) }}>
                                    <div className="d-flex gap-3">
                                        <div>
                                            {items.icon}
                                        </div>
                                        <div className='my-auto data'>
                                            <h2>{items.title}</h2>
                                        </div>
                                    </div>
                                </div>
                                }
                            </>
                        ))
                }
            </div>
        </>
    )
}

export default ClientFormsViewPopup
