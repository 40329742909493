import React, { useEffect, useState,useRef } from 'react'
import PropTypes from 'prop-types'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import FinanceBanner from '../FinanceHomeComponent/FinanceBanner/FinanceBanner'
import TextInput from '../../common/TextInput'
import FinanceBenefitCard from '../FinanceHomeComponent/FinanceRightCardComponent/FinanceRightCardComponent'
import { ReactComponent as Doc } from "../../../assets/finance-registration/doc.svg";
import StepButton from '../../common/StepButton'
import './FinanceAboutYou.scss'
import { validEmail, validPhone ,validNumber} from '../../../utils/Regex'
import CustomModal from '../../modal/CustomModal'
import { ReactComponent as Logo } from "../../../assets/finance-registration/logo1.svg"
import { Link } from 'react-router-dom'
import {SaveFinanceReigistartionBasicDetailsAction} from "../../redux/actions/finance-registrtion/saveFinanceRegistrationBasicDetail"
import {GetFinanceRegistrationBasicDetailAction} from "../../redux/actions/finance-registrtion/getFinanceRegistartionBasicDeatils"
import {GetFinanceRegistrationStatusAction} from  "../../redux/actions/finance-registrtion/getFinanceRegistartionStatus"
import {webBaseUrl} from "../../../config"
import { ToastContainer, toast } from 'react-toastify';

const FinanceAboutYou = ({onNextStep, afterSubmit,setIsCompletedStep,finish}) => {
    const [selectedCountryLabel, setSelectedCountryLabel] = useState("");
    const [countryCode, setCountryCode] = useState("UK")
    // const validwebUrl = /^(ftp|http|https):\/\/[^ "]+([^ "]+)+$/;
    const validwebUrl =/^(https?|ftp)?(:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/i;
    // /^(https?|ftp)?(:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w\.-]*)*\/?$/i
    const validFacebookUrl = /^((http|https):\/\/)?(www\.)?facebook.com\/.+/i;
    // const validInstagramUsername = /^[a-zA-Z0-9._]*$/;
    const validInstagramUsername = /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
    const [modalOpen, setModalOpen] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);
    const emailRef=useRef();
    const phoneRef=useRef();
    const websiteRef=useRef()
    const faceBookRef=useRef()
    const instaGramRef=useRef()
    const phoneInputRef=useRef()

    const {saveFinanceRegistrationBasicDetailsQueryData,initSaveFinanceRegistrationBasicDetailData} =SaveFinanceReigistartionBasicDetailsAction()
    const {getFinanceRegistrationBasicDetailQueryData,initGetFinanceRegistrationBasicDetailData}=GetFinanceRegistrationBasicDetailAction()
    const {getFinanceRegistrtionStatusQueryData,initGetFinanceRegistrationData}=GetFinanceRegistrationStatusAction()
   
    const {data} =getFinanceRegistrationBasicDetailQueryData;
    console.log("ddddd",data?.getFinanceBasicDetailsOfUser?.data)

    console.log("status1",getFinanceRegistrtionStatusQueryData?.data?.getUserRegistrationStatusForFinance?.data?.steps[0]?.is_completed

    )
    console.log("apiiiii",data?.getUserRegistrationStatusForFinance?.data)
    
    const completedStep=getFinanceRegistrtionStatusQueryData?.data?.getUserRegistrationStatusForFinance?.data;
    console.log("completedStep2",completedStep);
    console.log("saveFinanceRegistrationBasicDetailsQueryData",saveFinanceRegistrationBasicDetailsQueryData)

   useEffect(()=>{
     initGetFinanceRegistrationBasicDetailData()
   },[])

   useEffect(()=>{
    window.scrollTo(0, 0);
   },[])
   

useEffect(()=>{
  initGetFinanceRegistrationData()
},[])

    const [aboutDetails, setAboutDetails] = useState({
        email: "",
        phone:"",
       website: "",
        facebook: "",
        instagram: "",
    })

    //for error purpose
    const [validationErrors, setValidationErrors] = useState({
        email: "",
        phone: "",
        website: "",
        facebook: "",
        instagram: "",
    });

 



    //for countrySelection
    const CountrySelect = ({ value, onChange, selectedLabel, labels, ...rest }) => (
        <div className="prescriber-country-selection-container">
            <select
                {...rest}
                value={value}
                onChange={event => onChange(event.target.value || undefined)}>
                <option value="">
                    {labels['ZZ']}

                </option>
                {getCountries().map((country, i) => (
                    country !== 'International' &&
                    <option key={country} value={country}>
                        {`+${getCountryCallingCode(country)}`}
                    </option>


                ))}
            </select>
            <span className="selected-label">{selectedLabel}</span>
        </div>
    )

    CountrySelect.propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        labels: PropTypes.objectOf(PropTypes.string).isRequired,
        selectedLabel: PropTypes.string,
    }

    const updatePhoneNumber=(country)=>{
       
        const phoneNumber=aboutDetails?.phone;

        if(country === "GB" ){
        console.log("555555555555555555555555555555555");
            if(!phoneNumber?.startsWith('0')){
                // aboutDetails?.phone =  
                setAboutDetails(prevState => ({
                    ...prevState,
                    phone: '0' + phoneNumber
                  }));
            }
        }else{
            if(phoneNumber?.startsWith('0')){
                // aboutDetails?.phone= phoneNUmber.substring(1)
                setAboutDetails(prevState => ({
                    ...prevState,
                    phone: phoneNumber.substring(1)
                  }));
            }
        }
    }
    useEffect(()=>{
    },[aboutDetails?.phone])


    //HANDLE PHONE NUMBER CHANGE 
    const handleCountryChange = (country) => {
        
        setCountryCode(country);
        console.log("selected country", country)
        // Get the label based on the selected country
        const selectedLabel = getCountries().includes(country)
            ? `${getCountryCallingCode(country)}`
            : "";
          
        setSelectedCountryLabel(selectedLabel);
        updatePhoneNumber(country);
        
    };
    console.log("SelectedCountryLabel",countryCode)


    // Function to handle opening the modal
    const handleHowWork = () => {
        setModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setModalOpen(false);
    };

    //VALIDATE EMAIL
    function isValidEmail(email) {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailPattern.test(email);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAboutDetails((prev => ({
            ...prev,
            [name]: value,
        })))
        setValidationErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
        // updatePhoneNumber()
    }


const handleFAQLink=()=>{
    window.open(webBaseUrl + "/faq-categories/finance")
}
    //Validation
    const validateInput = (name, value) => {
        switch (name) {
            case "email":
                setValidationErrors((prev) => ({
                    ...prev,
                    email: !validEmail.test(value) ? "Please enter valid email." : "",
                }));
                break;
            case "phone":
                const numericValue = value.replace(/[^0-9]/g, "");
                setValidationErrors((prev) => ({
                    ...prev,
                    phone: !validPhone.test(numericValue) ? "Valid phone number is required (10-15 digits)" : "",
                }));
                break;
            case "website":
                setValidationErrors((prev) => ({
                    ...prev,
                    website:value && !validwebUrl.test(value) ? "Valid URL is required" : "",
                }));
                break;
            case "facebook":
                setValidationErrors((prev) => ({
                    ...prev,
                    facebook: value && !validFacebookUrl.test(value) ? "Valid Facebook page URL is required" : "",
                }));
                break;
            case "instagram":
                setValidationErrors((prev) => ({
                    ...prev,
                    instagram: value && !validInstagramUsername.test(value) ? "Valid Instagram username is required" : "",
                }));
                break;
            default:
                break;
        }
    }
    console.log("face",aboutDetails?.instagram)
    //handle continue
    const handleContinue = () => {
       
        
const ApiData=getFinanceRegistrtionStatusQueryData?.data?.getUserRegistrationStatusForFinance?.data?.steps[0]?.is_completed
console.log("ApiData",ApiData)

        const validationErrors = {};
        const numericValue = aboutDetails.phone.replace(/[^0-9]/g, "");
        console.log("numericValue",numericValue)
        // if (!aboutDetails.email || !isValidEmail(aboutDetails.email)) {
        //     validationErrors.email = "Valid email is required";
        // }
        console.log("llll",validPhone.test(numericValue))
        if (!aboutDetails.email || !validEmail.test(aboutDetails.email)) {
            validationErrors.email = "Valid email is required";
        }
        if (!aboutDetails.phone ) {
            validationErrors.phone = "This field is requried.";
        }else if(!validNumber.test(aboutDetails.phone)){
            validationErrors.phone = "Only valid phone number is allowed.";
        }
        
        if (aboutDetails.website && !validwebUrl.test(aboutDetails.website)) {
            validationErrors.website = "Valid URL is required";
        }
        if (aboutDetails.facebook && !validFacebookUrl.test(aboutDetails.facebook)) {
            validationErrors.facebook = "Valid Facebook page URL is required";
        }
        if (aboutDetails.instagram && !validInstagramUsername.test(aboutDetails.instagram)) {
            validationErrors.instagram = "Valid Instagram username is required";
        }
        if( emailRef.current ){
                emailRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                })
                
        }
        if(phoneRef.current){
            phoneRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            })
            
          }
          if(websiteRef.current){
            websiteRef.current.scrollIntoView({
                behavior: "smooth",
            block: "center",
            })
          }
          if(faceBookRef.current){
                faceBookRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                })
          }
          if(instaGramRef.current){
             instaGramRef.current.scrollIntoView({
                behavior: "smooth",
                    block: "center",
             })
          }
        setValidationErrors(validationErrors);
        console.log('validationErrors', validationErrors)
        console.log('aboutDetails', aboutDetails)
        // Check if any validation errors exist
        if (Object.values(validationErrors).some((error) => error)) {
            // If there are errors, return without continuing
            return;
        }else{
            
            initSaveFinanceRegistrationBasicDetailData({
                email:aboutDetails.email,
                phone:countryCode === "GB" ? aboutDetails.phone?.substring(1) : aboutDetails.phone ,
                countryCode:countryCode,
                phoneCountry:selectedCountryLabel,
                website:aboutDetails.website,
                facebook:aboutDetails.facebook,
                instagram:aboutDetails.instagram
            },(data)=>{
               toast.success(data?.data?.saveFinanceBasicDetailsOfUser?.message)
                 afterSubmit()
                onNextStep();
             }
            ,(error)=>{
            console.log("error",error)
            })
            // setIsCompletedStep(ApiData)
        }
     
    };

    useEffect((e)=>{
        
        const val=data?.getFinanceBasicDetailsOfUser?.data
        console.log("dVal",val)
        setAboutDetails({
            email:val?.email,
            phone:val?.country_code === "GB" ? '0'+ val?.phone : val?.phone,
            facebook:val?.facebook,
            website:val?.website,
            instagram:val?.instagram
          })

        setCountryCode(val?.country_code.toUpperCase())
        setSelectedCountryLabel(val?.phone_country)
    },[data?.getFinanceBasicDetailsOfUser?.data])
     console.log("aboutDetail1111",aboutDetails,">><",countryCode,selectedCountryLabel)
    return (
        <div>
            <FinanceBanner
                title="About you"
                desc="Few things we need from you."
                isBorder={!completedStep?.registration_complete && completedStep?.registration_complete !== undefined}
                currentStep={finish}
                totalSteps={4}
                isTranningBanner={false}
            />

         <div className='finance-about-container'>
                <div className="row justify-content-between flex-column flex-md-row">
                    <div className="col col-md-6">
                        <div className="about-left-container">

                            <div className="first-flex-div">
                                <p className='fields-title'>Basic info</p>
                            <div className='finance-input-container'>
                                    <TextInput
                                        label="Email address"
                                        placeholder="name@gmail.com"
                                        type={"email"}
                                        value={aboutDetails && aboutDetails?.email?.length ? 
                                        aboutDetails?.email : ""}
                                        onChange={(e) => handleChange(e)}
                                        name="email"
                                    />
                                    {validationErrors.email && <span ref={emailRef} className="error">{validationErrors.email}</span>}
                                </div>
                                {/* PHONE NUMBER */}
                                <div className="row finance-input-container">
                                    <p className="form-label">Enter phone number</p>
                                    <div className='CountryInputWrapper'>
                                        <CountrySelect labels={{ ZZ: "Select" }} value={countryCode} onChange={(e) => handleCountryChange(e)} />
                                        <TextInput
                                          ref={phoneInputRef}
                                            name="phone"
                                            type={"number"}
                                            value={aboutDetails?.phone}
                                            onChange={(e) => handleChange(e)}
                                            placeholder={aboutDetails?.phone}
                                        />
                                    </div>
                                        {validationErrors.phone && <span ref={phoneRef} className="error">{validationErrors.phone}</span>}
                                </div>
                            </div>
                            <div className="second-flex-div">
                                <p className='fields-title'>Social media</p>

                                {/* Website */}
                                <div className='finance-input-container'>
                                    <TextInput
                                        label="Website (Optional)"
                                        placeholder="Website (Optional)"
                                        value={aboutDetails?.website}
                                        onChange={(e) => handleChange(e)}
                                        name="website"
                                    />
                                    {validationErrors.website && <span ref={websiteRef} className="error">{validationErrors.website}</span>}
                                </div>

                                {/* Facebook page (Optional) */}
                                <div className='finance-input-container'>
                                    <TextInput
                                        label="Facebook page (Optional)"
                                        placeholder="Facebook page (Optional)"
                                        value={aboutDetails?.facebook}
                                        onChange={(e) => handleChange(e)}
                                        name="facebook"
                                    />
                                    {validationErrors.facebook && <span ref={faceBookRef} className="error">{validationErrors.facebook}</span>}
                                </div>

                                {/* Instagram user name (Optional) */}
                                <div className='finance-input-container'>
                                    <TextInput
                                        label="Instagram user name (Optional)"
                                        placeholder="Instagram user name (Optional)"
                                        value={aboutDetails?.instagram}
                                        onChange={(e) => handleChange(e)}
                                        name="instagram"
                                    />
                                    {validationErrors.instagram && <span ref={instaGramRef} className="error">{validationErrors.instagram}</span>}
                                </div>

                            </div>
                            <div className="third-flex-div mobile-none">
                                <StepButton label={"Continue"}
                                 blue={true} 
                                 isLoading={saveFinanceRegistrationBasicDetailsQueryData.loading}
                                 onClick={handleContinue} />
                            </div>
                        </div>

                    </div>

                    <div className="col col-md-5">
                        <div className="prescriber-right-card-container">
                            {/* No Risk card */}
                            <FinanceBenefitCard
                                icon={Doc}
                                content="Register your details below to offer buy now, pay later to clients. Enter the bank details you would like payments transferred."
                            />

                            <FinanceBenefitCard
                                icon={Doc}
                                content="10% fee on all approved finance."
                            />

                            {/* <FinanceBenefitCard
                                icon={Doc}
                                content="How buy now, Pay Later for clients works"
                                onContentClick={handleHowWork}
                            /> */}

                        </div>
                    </div>
                    <div className="third-flex-div desktop-none">
                                <StepButton label={"Continue"}
                                 blue={true} 
                                 isLoading={saveFinanceRegistrationBasicDetailsQueryData.loading}
                                 onClick={handleContinue} />
                            </div>
                </div>
            </div>

            <CustomModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                type="review"
                className={"CustomeFinanceAboutModel"}
                modalBody={<div className='finance-about-modal-body'>
                    <Logo />
                    <h3>Buy Now Pay Later up to 14 days no interest. Now available.</h3>
                    <div className='sub-body-content'>
                        <h3>How does it work?</h3>
                        <p>You can now buy stock and pay later with this new buy now, Pay later feature.</p>
                        <p>Once you complete the registration and unlock finance, the Buy Now, Pay later option will appear at the checkout within the pharmacy.</p>
                        <p>Once your application has been approved (only takes a few minutes) your order will be processed as usual and you will only need to pay back within 14 days. Giving you time to treat your client first.</p>
                        <p>Interest-free feature, you won’t be charged any interest for using Buy Now, Pay Later.</p>
                        <p>You can have up to three applications live at any one point.</p>
                        <Link onClick={()=>handleFAQLink()}>View FAQs</Link>
                    </div>
                    <p>Please complete registration to use this feature.</p>
                    <div className='blue-btn-cus-wrap'>
                    <button onClick={closeModal}>Ok</button>
                    </div>
                </div>}
                modaltitle="How it works?"
            />
        </div>
    )
}

export default FinanceAboutYou