import React, { useEffect, useRef, useState } from "react"
import "./login-component.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import FinaceHeader from "../../finaceHeader"
import { Link } from "react-router-dom"
import { LoginForFinanceUser } from "../../redux/actions/pay-later/loginForFinanceUser";
import { useNavigate } from 'react-router';
import CustomModal from "../../modal/CustomModal";
import RegisterWithFinancePopup from "../../payl8r/RegisterWithFinancePopup";
import { useDispatch, useSelector } from "react-redux";
import { STORE_USER_EMAIL } from "../../redux/constants";
import Cookies from 'js-cookie';
import { getProductsGetCartCountData } from "../../redux/actions"
import StepButton from '../../common/StepButton'
import { frontendURL } from "../../../config"
import { financeFirstStapPagePattern, revenueDashboardLandingPagePattern } from "../../../Routes"


const LoginComponent = () => {
    const inputRef = useRef(null);

    const [formData, setFormData] = useState({
        emailId: '',
        password: '',
    });
    const [errors, setErrors] = useState({});
    const [showPopUp, setShowPopup] = useState(false);
    const [appliedForFinance, setAppliedForFinance] = useState(false);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [isLoginSuccess, setIsloginSuccess] = useState(false);
    const { dashboardgetCartCountData } = useSelector(state => state?.dashboardGetCartCountReducer)
    // const [navigateNext,setNavigateNext] = useState(false);


    const { initiateloginForFinanceUser, loginForFinanceUserData } = LoginForFinanceUser();
    const email = useSelector(state => state?.financeLoanRequestEmailReducer?.userEmailForFinance)
    useEffect(() => {
        if (!email) {
            navigate(financeFirstStapPagePattern());
        }
    }, [email])
    const validateForm = () => {
        let formIsValid = true;
        let errors = {};

        // if (!formData.emailId.trim()) {
        //     errors.emailId = 'Email Address is required';
        //     formIsValid = false;
        // }
        if (!formData.password.trim()) {
            errors.password = 'Password is required';
            formIsValid = false;
        }
        // Add similar conditions for other fields

        setErrors(errors);
        return formIsValid;
    };


    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (errors[name] && value.trim()) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }

    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            initiateloginForFinanceUser({
                email: email,
                password: formData.password,
                appliedForFinance: false
            },
                (res) => {
                    dispatch({
                        type: STORE_USER_EMAIL,
                        payload: email,
                    });
                    if (res?.data?.is_user_exist && res?.data?.user_email_unverified) {
                        navigate("/verify-email")
                    } else if (res?.success && res?.data?.is_user_exist && !res?.data?.user_email_unverified && !res?.data?.user_finance_requested) {
                        // setShowPopup(true);
                    } else if (res?.data?.token) {
                        dispatch(getProductsGetCartCountData());
                        Cookies.set('faces_access_token', res.data.token, { expires: 7 });
                        setIsloginSuccess(true)
                        //  dispatch(getProductsGetCartCountData());
                        // navigate("/revenue-dashboard")
                        // window.location.href = `${process.env.REACT_APP_WEB_BASE_LOGIN_URL}/v1/revenue-dashboard`
                    }
                },
                (err) => {

                    dispatch({
                        type: STORE_USER_EMAIL,
                        payload: email,
                    });

                    if (err?.data?.is_user_exist === true && err?.data?.user_email_unverified === true) {
                        navigate("/verify-email")
                    } else if (err?.success && err?.data?.is_user_exist && !err?.data?.user_email_unverified && !err?.data?.user_finance_requested) {
                        // setShowPopup(true);
                    }
                }
            )
        }
        // Add your registration logic here
    };
    // const initLoginFunction = (applied) => {
    //     if (applied) {
    //         setAppliedForFinance(true);
    //     }
    //     initiateloginForFinanceUser({
    //         email: email,
    //         password: formData.password,
    //         appliedForFinance: applied
    //     },
    //         (res) => {
    //             if (res?.data?.is_user_exist && res?.data?.user_email_unverified === true) {
    //                 navigate("/verify-email")
    //             } else if (res?.data?.is_user_exist && !res?.data?.user_email_unverified && !res?.data?.user_finance_requested) {
    //                 // setShowPopup(true);
    //             } else if (res?.data?.token) {
    dispatch(getProductsGetCartCountData());
    //                 Cookies.set('faces_access_token', res.data.token, { expires: 7 });
    //                 setIsloginSuccess(true)
    //                 // dispatch(getProductsGetCartCountData());
    //                 // window.location.href = `${process.env.REACT_APP_WEB_BASE_LOGIN_URL}/v1/revenue-dashboard`
    //                 // navigate("/revenue-dashboard")
    //             }
    //         },
    //         (err) => {
    //             dispatch({
    //                 type: STORE_USER_EMAIL,
    //                 payload: formData.emailId,
    //             });

    //             if (err?.data?.is_user_exist && err?.data?.user_email_unverified) {
    //                 navigate("/verify-email")
    //             } else if (err?.data?.is_user_exist && !err?.data?.user_email_unverified && !err?.data?.user_finance_requested) {
    //                 // setShowPopup(true);
    //             }
    //         }
    //     )
    // }

    const isUserLoggedIn = () => {
        const token = Cookies.get('faces_access_token');
        return !!token; // Returns true if the token exists, false otherwise
    };

    // const ProtectedRoute = () => {
    //     const isAuthenticated = isUserLoggedIn();
    //     return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
    // };


    useEffect(() => {
        // Check if user is already logged in
        if (isUserLoggedIn()) {
            navigate('/revenue-dashboard', { replace: true });
        }
    }, [navigate]);

    useEffect(() => {
        if (isLoginSuccess) {
            dispatch(getProductsGetCartCountData());
            // setNavigateNext(true);
        }
    }, [dispatch, isLoginSuccess])

    // useEffect(()=>{
    //     if(navigateNext)
    //         navigate("/revenue-dashboard")
    // },[navigateNext,navigate])

    useEffect(() => {
        if (dashboardgetCartCountData != null)
            window.location.href = frontendURL + revenueDashboardLandingPagePattern()
        // navigate("/revenue-dashboard")
    }, [navigate, dashboardgetCartCountData])

    return (
        <>
            <div className="mainfinancelandingpage">
                <FinaceHeader />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-sm-12 col-md-8">
                            <h2 className="pt-5 pagenewPageTitle">Welcome Back</h2>
                            <form className="login-form col-md-12" onSubmit={handleSubmit}>
                                {/* <div className="mb-4">
                                    <label htmlFor="emailId" className="py-2 form-label">
                                        Email Id
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="emailId"
                                        name="emailId"
                                        placeholder="Email id"
                                        value={formData.emailId}
                                        onChange={handleChange}
                                    />
                                    {errors.emailId && <p className="error text-danger">{errors.emailId}</p>}

                                </div> */}
                                <div className="mb-4">
                                    <div className="forgotWrapper">
                                        <label htmlFor="password" className="py-2 form-label">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            placeholder="Password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            ref={inputRef}
                                        />
                                        {errors.password && <p className="error text-danger">{errors.password}</p>}
                                        <Link to={"/forgot-password"}> <div className="forgotText">Forgot Password?</div></Link>
                                    </div>
                                </div>
                                {/* <button type="submit" className="my-4 col-md-12 btn btn-primary apply-btn"> */}

                                {/* Continue */}
                                {/* </button> */}
                                <StepButton
                                    type="submit"
                                    blue={true}
                                    id={"ryft-pay-btn"}
                                    label={"Continue"}
                                    isLoading={loginForFinanceUserData.loading}
                                />

                                {/* <div className="bottomSignupText">
                                    For sign up <Link to="/registration">Register here</Link>
                                </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* {
                <CustomModal
                    modalOpen={showPopUp}
                    // modalOpen={true}
                    type={"test"}
                    setModalOpen={setShowPopup}
                    className={'registerFinaceModel'}
                    modalBody={
                        <>
                            <RegisterWithFinancePopup
                                setShowPopup={setShowPopup}
                                initLoginFunction={initLoginFunction}
                            />
                        </>
                    }
                />
            } */}
        </>
    )
}

export default LoginComponent
