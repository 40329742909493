import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logoHeader from '../../assets/finance-page/logoHeader.png';
import {
  registrationLandingPagePattern,
  editProfileLandingPagePattern,
  financeFirstStapPage,
  getFinanceLandingRoute,
  revenueDashboardLandingPage,
} from '../../Routes';

import './finaceHeader.scss'
import { useDispatch, useSelector } from "react-redux";
import { logoutProfile } from '../redux/actions/profile/Profile';
// function FinaceHeader() {
//     const [isAuthHeader, setIsAuthHeader] = useState(false);
//     const dispatch = useDispatch();

//     useEffect(() => {
//       Cookies.get("faces_access_token") ? setIsAuthHeader(true) : setIsAuthHeader(false);      
//     }, []);
import './finaceHeader.scss';
import UserDropdown from '../auth/dropdown/user-dropdown';
import miniProfileUser from '../../images/mini-profile-user.png';
import { IoIosArrowDown } from 'react-icons/io';
import './finaceHeader.scss';
import Cookies from 'js-cookie';
import { businessDashboardRoutePattern } from '../../Routes';
import { loginWebBaseUrl, webBaseUrl } from '../../config';
import { GetProfileAction } from '../redux/actions/dashboard/getProfileDataGql';
import { Avatar } from '@mui/material';
import downArrow from '../../assets/finance-page/down-arrow.svg';


function FinaceHeader() {
  const [isAuthHeader, setIsAuthHeader] = useState(false);

  const { getProfileQueryData, initGetProfileData } = GetProfileAction();
  const { profileData } = useSelector((state) => state?.GetProfileDataReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    Cookies.get('faces_access_token')
      ? setIsAuthHeader(true)
      : setIsAuthHeader(false);
  }, []);

  const navigate = useNavigate();
  const handleSignUpClick = () => {
    navigate(registrationLandingPagePattern());
  };

  const handleProfileEditClick = () => {
    navigate(editProfileLandingPagePattern());
  };

  useEffect(() => {
    initGetProfileData();
  }, [])

  const logoutHandler = async () => {
    await dispatch(logoutProfile())
    // Cookies.remove('faces_access_token');
    setIsAuthHeader(false);
    window.location.href = loginWebBaseUrl + '/login';
  };

  // return (
  //   <>
  //     <div className="customeloginHeader">
  //       <div className="container">
  //         <div className="headerlogoBtnWrapper">
  //           <div
  //             className="logoWrapper"
  //             onClick={() => {
  //               navigate(businessDashboardRoutePattern);
  //             }}
  //           >
  //             <img src={logoHeader} alt="" srcset="" />
  //           </div>
  //           <div className="loginButton d-flex align-items-center">
  //             {/* <button 
  //                                   onClick={handleSignUpClick}
  //                               >
  //                                   Login / Sign up
  //                               </button> */}
  //             {/* <UserDropdown /> */}
  //             {!isAuthHeader ? (
  //               <button>Login / Sign up</button>
  //             ) : (
  //               <>
  //                 <figure className="mini-profile-img">
  //                   <img src={miniProfileUser} />
  //                 </figure>
  //                 <div className="dropdown">
  //                   <button
  //                     className="btn bg-transparent text-dark dropdown-toggle cus-dropdown-btn-hm"
  //                     type="button"
  //                     id="dropdownMenuButton1"
  //                     data-bs-toggle="dropdown"
  //                     aria-expanded="false"
  //                   >
  //                     Rachel Williams
  //                     <IoIosArrowDown className="ms-2 cus-drop-arrow" />
  //                   </button>
  //                   <ul
  //                     className="dropdown-menu lh-base"
  //                     aria-labelledby="dropdownMenuButton1"
  //                   >
  //                     <li>
  //                       <a
  //                         className="dropdown-item"
  //                         onClick={handleProfileEditClick}
  //                       >
  //                         Profile
  //                       </a>
  //                     </li>
  //                     <li>
  //                       <a className="dropdown-item" onClick={logoutHandler}>
  //                         Log out
  //                       </a>
  //                     </li>
  //                   </ul>
  //                 </div>
  //               </>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
  return (
    <>
      <div className="customeloginHeader">
        <div className="container">
          <div className="headerlogoBtnWrapper">
            <div
              className="logoWrapper"
              onClick={() => { isAuthHeader ? navigate(revenueDashboardLandingPage) : navigate(getFinanceLandingRoute) }}

            //   onClick={() => { isAuthHeader ? navigate(businessDashboardRoutePattern) : window.location.href = webBaseUrl}}
            >
              <img src={logoHeader} alt="" srcset="" />
            </div>
            <div className="loginButton d-flex align-items-center">
              {/* <button 
                                    onClick={handleSignUpClick}
                                >
                                    Login / Sign up
                                </button> */}
              {/* <UserDropdown /> */}

              {/* {
              isAuthHeader ? null :
                  <button onClick={() => window.location.href = webBaseUrl + '/login'}>
                      Login / Sign up
                  </button>
              } */}

              {!isAuthHeader ? (
                <button onClick={() => { navigate(financeFirstStapPage) }}>Login / Sign up</button>
              ) : (
                <>
                  <Avatar
                    style={{ cursor: "pointer" }}
                    // onClick={() => {
                    //   // navigate(editProfileLandingPagePattern());
                    //   // window.location.href =
                    //   //   webBaseUrl + "/user/update-profile";
                    // }}
                    src={profileData?.profile_pic}
                    name={
                      profileData?.first_name?.at(0) + " " + profileData?.last_name?.at(0)
                    }
                    round
                    size="40"
                    color="#EEEFF3"
                    fgColor="#000"
                    textSizeRatio={2}
                  />
                  <div className="dropdown">
                    <button
                      className="btn bg-transparent text-dark dropdown-toggle cus-dropdown-btn-hm"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {profileData?.company_name}
                      <span className='downArrow'>
                        <img src={downArrow} />
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu lh-base"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={handleProfileEditClick}
                        >
                          Profile
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" onClick={logoutHandler}>
                          Log out
                        </a>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinaceHeader;
