import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";

const SAVE_TEAM_MEMBER_DETAILS_MUTATION = gql`
mutation SaveTeamMemberdetails($saveTeamMemberdetailsId: Int, $name: String, $jobRole: String, $description: String, $qualification: String, $image: Upload) {
    saveTeamMemberdetails(id: $saveTeamMemberdetailsId, name: $name, job_role: $jobRole, description: $description, qualification: $qualification, image: $image) {
      success
      message
      data {
        pwtm_id
        pwtm_pwc_id
        pwtm_full_name
        pwtm_qualification
        pwtm_job_role
        pwtm_description
        image {
          name
          image_url
          time
        }
      }
    }
  }
`;

export const SaveTeamMemberDetails = () => {
  const [saveTeamMemberDetailsRequest, saveTeamMemberDetailsResponseData] =
    useMutation(SAVE_TEAM_MEMBER_DETAILS_MUTATION);
  const dispatch = useDispatch();
  const saveTeamMemberDetailsMutationData = saveTeamMemberDetailsResponseData;

  const initiateSaveTeamMemberDetails = (
    data,
    successCallback,
    errorCallback
  ) => {
    saveTeamMemberDetailsRequest({
      variables: data,
    })
      .then(({ data: { saveTeamMemberdetails: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        // errorCallback(error);
      });
  };
  return { initiateSaveTeamMemberDetails, saveTeamMemberDetailsMutationData };
};
