import React from 'react'
import LoadingOverlay from 'react-loading-overlay'

const Customloader = (props) => {
  return (
    <div>
        <LoadingOverlay
            active={props.active}
            spinner
            text='Loading...'
        >
            {
                props.children
            }
        </LoadingOverlay>
    </div>
  )
}

export default Customloader
