import {useMutation,gql} from "@apollo/client"

const USER_INSURANCE_DETAILS=gql`
mutation SaveUserInsuranceDetails($expireDate: String, $policyNo: String, $documentFiles: [saveUserInsuranceDetailsDocsArgs], $insuranceCompanyName: String) {
    saveUserInsuranceDetails(expire_date: $expireDate, policy_no: $policyNo, document_files: $documentFiles, insurance_company_name: $insuranceCompanyName) {
      success
      message
      data {
        images {
          id
          certificate_name
          certificate_url
          time
        }
      }
    }
  }
`;

export const UserInsuranceDetailsAction=()=>{

    const [userInsuranceDetailsResponseFun,
        userInsuranceDetailResponseData
    ]=useMutation(USER_INSURANCE_DETAILS)

    const userInsuranceDetailsQueryData=userInsuranceDetailResponseData;

    const initUserInsuranceDetailData=(data,successCallback,errorCallback)=>{
        userInsuranceDetailsResponseFun({
            variables:data,
        }).then((responseData)=>{
            console.log("resData",responseData)
                if(responseData.data.saveUserInsuranceDetails.success){
                    successCallback(responseData)
                }
                else{
                    errorCallback(responseData)
                }
        }).catch((error)=>{
            errorCallback(error)
        })
    }

    return {userInsuranceDetailsQueryData,initUserInsuranceDetailData}
}