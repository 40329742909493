import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const GET_UPDATED_STATUS_CLIENT_DIRECTORY_QUERY = gql`
 mutation UpdateStatusOfAccessibilityOfCientDirectory($accessStatus: Boolean) {
  updateStatusOfAccessibilityOfCientDirectory(accessStatus: $accessStatus) {
    success
    message
    data {
      isPasswordToggled
    }
  }
}
`;

export const GetUpdatedStatusClientDirectory = () => {
    const [
        getAccessOfUpdatedClientDirectoryResponseFunction,
        getAccessOfUpdatedClientDirectoryResponseData,
    ] = useMutation(GET_UPDATED_STATUS_CLIENT_DIRECTORY_QUERY);

    const getAccessOfUpdatedClientDirectoryData = getAccessOfUpdatedClientDirectoryResponseData;

    const initGetUpdatedAccessOfClientDirectoryData = (
        data,
        successCallback,
        errorCallback
    ) => {
        console.log("inside mutation")
        getAccessOfUpdatedClientDirectoryResponseFunction({
            variables: data,
        })
            .then((responseData) => {
                console.log(responseData?.data, "reesss")
                if (responseData?.data?.updateStatusOfAccessibilityOfCientDirectory?.success) {
                    successCallback(responseData?.data?.updateStatusOfAccessibilityOfCientDirectory);
                } else {
                    errorCallback(responseData?.data?.updateStatusOfAccessibilityOfCientDirectory);
                }
            })
            .catch((error) => {
                errorCallback(error);
            });
    };

    return {
        getAccessOfUpdatedClientDirectoryData,
        initGetUpdatedAccessOfClientDirectoryData,
    };
};
