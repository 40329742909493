import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";


const GET_INSURANCE_OPTION=gql`
query GetInsuranceOptions {
    getInsuranceOptions {
      success
      message
      data {
        options
      }
    }
  }
`;

export const GetInsuranceOptionAction=()=>{
    const [getInsuranceOptionResponseFunction,
    getInsuranceOptionResponseData]=useLazyQuery(GET_INSURANCE_OPTION,{
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
        onCompleted:({getInsuranceOptions:responseData})=>{
            if(responseData){
                console.log("res",responseData?.message)
            }else{
                console.log("error",responseData?.message)
            }
        }
    })

    const getInsuranceOptionQueryData=getInsuranceOptionResponseData;

    const initGetInsuranceOptionData=(data)=>{
          getInsuranceOptionResponseFunction({
            variables:data
          })
    }

    return { getInsuranceOptionQueryData,initGetInsuranceOptionData}
}