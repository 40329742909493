import { gql, useMutation } from "@apollo/client";

const DELETE_FINANCE_INSURANCE_DETAIL=gql`
mutation DeleteInsuranceDocumentForFinance($deleteInsuranceDocumentForFinanceId: Int!) {
    deleteInsuranceDocumentForFinance(id: $deleteInsuranceDocumentForFinanceId) {
      success
      message
    }
  }
`;

export const DeleteFinanceInsurancedetailAction=()=>{
    const [deleteFinanceInsuranceDetailResponseFunction,
    deleteFinanceInsuranceDetailResponseData]=useMutation(DELETE_FINANCE_INSURANCE_DETAIL)

    const deleteFinanceInsuranceDetailQueryData=deleteFinanceInsuranceDetailResponseData;

    const initDeleteFinanceInsuranceDetailData=(data, successCallback, errorCallback)=>{
        deleteFinanceInsuranceDetailResponseFunction({
            variables:data
        }).then((responseData)=>{
            if(responseData.data.deleteInsuranceDocumentForFinance.success){
                successCallback(responseData)
            }
            else{
               errorCallback(responseData)
            }
        }).catch((error)=>{
            errorCallback(error)
        })
    }

    return {deleteFinanceInsuranceDetailQueryData,initDeleteFinanceInsuranceDetailData}
}