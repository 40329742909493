import React, { useEffect, useState } from 'react'
import StepButton from '../../../common/StepButton'
import "./update-working-availability-calendar.scss"
import moment from 'moment';
import { weeklyViewDaySevenDateChange } from '../../../../utils/general';

const UpdateWorkingAvailabilityCalendar = ({ setIsOpenCalendar, showSelectedDate, viewCalendarState, isThreeDaysView, setCalenderDisplayDate, setIsAddLocationOpen }) => {
    const [dateData, setDateData] = useState([]);
    const [dayData, setDayData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);

    function getDates(startDate) {
        const dates = [];
        const day = [];

        let currentDate = moment(startDate);
        let endDate;
        let currentDayDate = moment(startDate);


        if (viewCalendarState === "timeGridWeek") {
            if (isThreeDaysView) {
                endDate = moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().add(2, "days")
            }
            else {
                currentDate = weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "start")
                endDate = weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "end")
                currentDayDate = weeklyViewDaySevenDateChange(moment(showSelectedDate, "dddd, Do MMMM YYYY"), "start")
            }
        }
        else if (viewCalendarState === "dayGridMonth") {
            // currentDate = moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().startOf('month')
            currentDate = moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().startOf('month').startOf('week').add(1, 'day');
            endDate = moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().endOf('month').isoWeekday() === 7 ? moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().endOf('month') : moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().endOf('month').endOf("week").add(1, 'day')
            currentDayDate = moment(showSelectedDate, "dddd, Do MMMM YYYY").clone().startOf('month').startOf('week').add(1, 'day');
        }

        while (currentDate.isSameOrBefore(endDate)) {
            dates.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'day');
        }


        for (let i = 0; i < 7; i++) {
            if (i === 3 && isThreeDaysView) {
                break;
            }
            if (isThreeDaysView) {
                day.push(currentDayDate.format('ddd'));
            }
            else {
                day.push(currentDayDate.format('ddd').charAt(0));
            }
            currentDayDate.add(1, 'day');
        }
        return { dates, day };
    }

    useEffect(() => {
        const dateDatas = getDates(moment(showSelectedDate, "dddd, Do MMMM YYYY").format("YYYY-MM-DD"))
        setDateData(dateDatas?.dates);
        setDayData(dateDatas?.day)
    }, [])

    return (
        <>
            <div className="mt-2">
                <p className="mt-2">Select any one date and add more location.</p>
                <div className='availibility-calendar-wrapper'>
                    <div className={`date-tile-wrapper ${isThreeDaysView ? "three-day-tile-wrapper" : ""}`}>
                        {
                            dayData?.map((item) => {
                                return <p>{item}</p>
                            })
                        }
                    </div>
                    <div className={`date-tile-wrapper ${isThreeDaysView ? "three-day-tile-wrapper" : ""}`} >
                        {
                            dateData?.map((item) => {
                                return <p className={`${item == selectedDate ? "selected-tile" : ""} 
                                ${viewCalendarState === "dayGridMonth" ? moment(item, "YYYY-MM-DD").format("MM") == moment(showSelectedDate, "dddd, Do MMMM YYYY").format("MM") ? "" : "disable-tile" : ""}`} onClick={() => setSelectedDate(item)}>{moment(item).format("D")}</p>
                            })
                        }
                    </div>
                    <div className="sticky-footer-calender mt-2">
                        <StepButton label={"Next"} blue={true} onClick={() => {
                            setIsOpenCalendar(false)
                            setCalenderDisplayDate(selectedDate)
                            setIsAddLocationOpen(true)
                        }}
                            disabled={!selectedDate}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateWorkingAvailabilityCalendar
