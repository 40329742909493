import React, { useEffect, useState } from 'react';
import "./add-location-list-view.scss";
// import { ReactComponent as CloseIcon } from "../../images/close_icon.svg";
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from "../../../../images/close_icon.svg";
import SkeletonLoader from '../../../common/Skeleton/SkeletonLoader';
import { GetAvailableClinicsListAction } from '../../../redux/actions/appointments/GetAvailableClinicsList';
import { CLEAR_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION } from '../../../redux/constants/appointment/appointmentConst';
import useWindowDimension from '../../../../utils/useWindowDimensions';

const AddLocationListView = ({ setIsAddLocationOpen, setIsLocationHoursUpdateOpen, getLocationWorkingHoursApiCall, setSelectedLocationData, selectedDate, initiateAppointmentListClinicsCallAddLocation, getAvailableClinicsListQueryData }) => {
    //get data of clinics
    const appointmentClinicsListAddLocation = useSelector((state) => state?.appointmentListClinicsReducer?.appointmentClinicsListAddLocation)
    const totalAppointmentClinicsListCountAddLocation = useSelector((state) => state?.appointmentListClinicsReducer?.totalAppointmentClinicsListCountAddLocation)
    const appointmentClinicsListPageAddLocation = useSelector((state) => state?.appointmentListClinicsReducer?.appointmentClinicsListPageAddLocation)
    const loadedAppointmentClinicsListCountAddLocation = useSelector((state) => state?.appointmentListClinicsReducer?.loadedAppointmentClinicsListCountAddLocation)

    const dispatch = useDispatch();
    console.log("111", selectedDate)

    useEffect(() => {
        dispatch({
            type: CLEAR_APPOINTMENT_LIST_CLINICS_WITH_ACTIVE_INACTIVE_ADD_LOCATION
        })
        initiateAppointmentListClinicsCallAddLocation(true)
    }, [])


    const categorizeByMonth = (events, title, format) => {
        // Create an object to hold events grouped by month
        let eventsByMonth = {
            Active: [],
            Other: [],
        };

        // Iterate through each event
        events.forEach(event => {

            if (event?.isAvailable) {
                eventsByMonth["Active"].push(event);
            }
            else {
                eventsByMonth["Other"].push(event);
            }
        });

        return eventsByMonth;
    }

    const appointmentClinicsListAddLocationNew = categorizeByMonth(appointmentClinicsListAddLocation, "availableDate", true, "DD-MM-YYYY")
    

    const dimensions = useWindowDimension();
    const [sidebarCanvasHeader, setSidebarCanvasHeader] = useState(0);

    useEffect(() => {
        setSidebarCanvasHeader(
            document.getElementsByClassName('SidebarCanvasHeader')[0]?.offsetHeight
        );

        window.addEventListener('resize', handleResize);
    }, []);
    const handleResize = () => {
        setSidebarCanvasHeader(
            document.getElementsByClassName('SidebarCanvasHeader')[0]?.offsetHeight
        );
    };

    return (
        <>
            <div className='add-location-list-wrapper'>
                <CloseIcon className="close-icon-animation" onClick={() => {
                    setIsAddLocationOpen(false)
                }} />
                <div className='SidebarCanvasHeader'>
                    <h1 className='pt-4'>Add location</h1>
                    <p>Update where you’re working for {selectedDate ? moment(selectedDate, "YYYY-MM-DD").format('ddd DD MMM,YYYY') : moment().format("DD MMMM")}</p>
                </div>
                <div id="scrollableDivAddLocationPopup" style={{ height: dimensions.height - sidebarCanvasHeader - 50 - 40 + 'px', width: "100%", overflowY: "auto" }}>
                    {<InfiniteScroll
                        dataLength={loadedAppointmentClinicsListCountAddLocation}
                        next={() => {
                            initiateAppointmentListClinicsCallAddLocation(false)
                        }}
                        hasMore={loadedAppointmentClinicsListCountAddLocation < totalAppointmentClinicsListCountAddLocation}
                        scrollableTarget="scrollableDivAddLocationPopup"
                    >
                        {appointmentClinicsListAddLocation?.length != 0 ?

                            Object.entries(appointmentClinicsListAddLocationNew).map(([key, items]) => {
                                return <div className='active-location-wrapper'>
                                    {(appointmentClinicsListAddLocationNew[key]?.length > 0 || key === "Active") && <h2>{key} locations</h2>}
                                    {items?.length > 0 ? items?.map((item) => {
                                        return <div className='appointment-location-wrapper'
                                            // style={{ borderLeft: `3px solid ${item?.clinicColor}` }}
                                            onClick={() => {
                                                getLocationWorkingHoursApiCall(selectedDate ? selectedDate : moment().format("YYYY-MM-DD"), item?.clinicId)
                                                setIsLocationHoursUpdateOpen(true)
                                                setSelectedLocationData(item)
                                            }}>
                                            <div className='beforeBorder' style={{ background: `${item?.clinicColor}` }} />
                                            <h2>{item?.clinicName}</h2>
                                            <div className='location-timing-wrapper'>
                                                <p>{item?.isAvailable ? `${moment(item?.startTime, "HH:mm:ss").format("HH:mm")} – ${moment(item?.endTime, "HH:mm:ss").format("HH:mm")}` : "Add to day"}</p>
                                            </div>
                                        </div>
                                    }) :

                                        (appointmentClinicsListAddLocationNew[key]?.length > 0 || key === "Active") && <p style={{ marginTop: "16px" }}>No location available</p>
                                    }
                                </div>
                            })
                            : getAvailableClinicsListQueryData.loading ? null :

                                <div className='NoEventData'>
                                    <h1>No location available</h1>
                                </div>
                        }
                        {
                            getAvailableClinicsListQueryData.loading ?
                                <div className='AppointmentListSkeleton'>
                                    <SkeletonLoader type="appointment-list-clinic-list-skeleton" />
                                </div>
                                : null
                        }
                    </InfiniteScroll>}
                </div>

                {/* <div className='active-location-wrapper'>
                    <h2>Other locations</h2>
                    {
                        [...Array(3)].map((item, index) => {
                            return <div className='appointment-location-wrapper' style={{ borderLeft: `3px solid ${"green"}` }}>
                                <h2>Location Name {index + 1}</h2>
                                <div className='location-timing-wrapper'
                                    onClick={() => {
                                        getLocationWorkingHoursApiCall()
                                        setIsLocationHoursUpdateOpen(true)
                                    }}
                                >
                                    <p>Add to day</p>
                                </div>
                            </div>
                        })
                    }
                </div> */}
            </div>
        </>
    )
}

export default AddLocationListView
