import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const RESEND_VERIFICATION_CODE = gql`
mutation ResendEmailVerificationCodeForFinance($email: String) {
    resendEmailVerificationCodeForFinance(email: $email) {
      success
      message
    }
  }
`;

export const ResendVerificationCode = () => {
    // const dispatch = useDispatch()
    const [resendVerificationCodeFunc, resendVerificationCode] = useMutation(RESEND_VERIFICATION_CODE);
    const resendVerificationCodeData = resendVerificationCode;

    const initiateresendVerificationCode = (
        data,
        successCallback,
        errorCallback
    ) => {
        resendVerificationCodeFunc({
            variables: data,
        })
            .then(({ data: { resendEmailVerificationCodeForFinance: responseData } }) => {
                if (responseData?.success) {
                    // toast.success(responseData.message)
                    successCallback(responseData);
                } else {
                    console.log("elseeeeeeeee")
                    // toast.error(responseData.message)
                    errorCallback(responseData);
                }
            })
            .catch((error) => {
                //dispatch(setLoaderComplete("fullPageLoader"));
                console.log("error",error)
                errorCallback(error);
            });
    };

    return { initiateresendVerificationCode, resendVerificationCodeData }
}