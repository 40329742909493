import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import TextInput from "../../common/TextInput";
import CustomDropdown from "../../common/dropdown/CustomDropdown";
import "./PrescriberAbout.scss";
import moment from "moment";
import StepButton from "../../common/StepButton";
import { useNavigate } from "react-router";
import {
  PrescriberAboutYou,
  GetPrescriberAboutDetails,
} from "../../redux/actions/pharmacy-registration/prescriberAboutYou";
import {
  PrescriberProfilePic,
  DeletePrescriberProfilePic,
} from "../../redux/actions/pharmacy-registration/prescriberProfile";
import { toast } from "react-toastify";
import FinanceBanner from "../../finance-registration/FinanceHomeComponent/FinanceBanner/FinanceBanner";
import { ReactComponent as Doc } from "../../../assets/finance-registration/doc.svg";
import FinanceBenefitCard from "../../finance-registration/FinanceHomeComponent/FinanceRightCardComponent/FinanceRightCardComponent";
import ConfirmationModal from "../../pages/finance-identity/finance-confirmationModal";
import Avatar from "react-avatar";
import { loadingGif } from "../../../images/loading.gif";
import SkeletonLoader from "../../common/Skeleton/SkeletonLoader";
import Skeleton from "react-loading-skeleton";
import { validName, validPhone } from "../../../utils/Regex";

const PrescriberAbout = ({
  onNextStep,
  afterSubmit,
  finish,
  isRequestSentToAdmin,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  console.log("sooooooooook", isImageLoaded);
  const navigate = useNavigate();
  const { initiateSavePrescriberProfilePic } = PrescriberProfilePic();
  const { initiateDeletePrescriberProfilePic } = DeletePrescriberProfilePic();
  const { initiateSavePrescriberAboutDetails } = PrescriberAboutYou();
  const { data, initGetPrescriberAboutDetails } = GetPrescriberAboutDetails();
  const [loading, setLoading] = useState(false);
  const [selectedCountryLabel, setSelectedCountryLabel] = useState("");
  const [countryCode, setCountryCode] = useState("GB");
  const [isProfilePreview, setIsProfilePreview] = useState(false);
  const profilePicInputRef = useRef(null);
  const [year, setYear] = useState("");
  const [dobValue, setDobValue] = useState("");
  const [dobError, setDobError] = useState(false);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [isDeleteProfileConfirm, setIsDeleteProfileConfirm] = useState(false);

  const [fetchLoading, setFetchLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //PROFILE STATE
  const [profileImageData, setProfileImageData] = useState({
    profilePreviewUrl: "",
    isProfilePicPresent: false,
    profilePreviewTitle: "",
    profileImage: null,
  });

  //PROFILE PICKER HANDLE FUNCTION
  const handleProfilePicker = () => {
    if (profilePicInputRef.current !== null) {
      profilePicInputRef.current.click();
    }
  };

  //HANDLE STATE FOR ABOUT DETAILS
  const [aboutDetails, setAboutDetails] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    birthDay: "",
  });

  //for error purpose
  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    phonePrefix: "",
    phoneNumber: "",
    email: "",
    countryCode: "",
    title: "",
  });
  const updatePhoneNumber=(country)=>{
       
    const phoneNumber=aboutDetails?.phoneNumber;

console.log("phoneNumber",country)

    if(country === "GB" ){
   
        if(!phoneNumber?.startsWith('0')){
            // aboutDetails?.phone =  
            setAboutDetails(prevState => ({
                ...prevState,
                phoneNumber: '0' + phoneNumber
              }));
        }
    }else{
        if(phoneNumber?.startsWith('0')){
            // aboutDetails?.phone= phoneNUmber.substring(1)
            setAboutDetails(prevState => ({
                ...prevState,
                phoneNumber: phoneNumber.substring(1)
              }));
        }
    }
}
useEffect(()=>{

},[aboutDetails?.phoneNumber])

const handleCountryChange = (country) => {
  setCountryCode(country);
  // Get the label based on the selected country
  const selectedLabel = getCountries().includes(country)
    ? `${getCountryCallingCode(country)}`
    : "";

  setSelectedCountryLabel(selectedLabel);
  updatePhoneNumber(country)
};
  //HANDLE PROFILE IMAGE UPLOAD
  const handleProfileImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImageData((prevData) => ({
        ...prevData,
        ["profilePreviewUrl"]: imageUrl,
        ["isProfilePicPresent"]: true,
        ["profilePreviewTitle"]: file?.name,
        ["profileImage"]: file,
      }));
    }

    // setProfileFileData(file)
    initiateSavePrescriberProfilePic(
      {
        image: file,
      },
      (res) => {
        console.log("Profile picture upload response:", res);
        // toast.success(res.message);
      },
      (error) => {
        console.error("Error uploading profile picture:", error);
        toast.error(error.message);
      }
    );
  };

  //GET DATA
  useEffect(() => {
    initGetPrescriberAboutDetails();
  }, []);

  useEffect(() => {
    if (
      data &&
      data.getPharmacyAboutDetails &&
      data.getPharmacyAboutDetails.success
    ) {
      const responseData = data.getPharmacyAboutDetails.data;
      console.log("responseData",responseData)
      setAboutDetails({
        // title: responseData?.title,
        firstName: responseData?.fname,
        lastName: responseData?.lname,
        email: responseData?.email,
        phoneNumber: responseData?.countryCode === "GB" ? '0' +responseData?.contactNumber : responseData?.contactNumber,
      });
      setProfileImageData({
        profilePreviewUrl: responseData?.isProfileImage
          ? responseData?.profileImage
          : "",
        isProfilePicPresent: responseData?.isProfileImage,
        profilePreviewTitle: "",
        profileImage: null,
      });
      setCountryCode(responseData?.countryCode?.toUpperCase());
      setSelectedCountryLabel(responseData?.phoneCountry);

      setFetchLoading(false);
    }
  }, [data]);

  console.log(fetchLoading, "selected country");
  //HANDLE BIRTHDAY CHANGE
  useEffect(() => {
    var date = moment(year + "-" + month + "-" + day).format("YYYY-MM-DD");

    if (day == "" && month == "" && year == "") {
      setDobError(false);
    } else if (
      day &&
      month &&
      year &&
      day?.length == 2 &&
      month.length == 2 &&
      year?.length == 4 &&
      date < moment().format("YYYY-MM-DD")
    ) {
      setDobError(false);
      setDobValue(date);
    } else if (day && month && year) {
      setDobValue("");
      setDobError(true);
    }
  }, [day, month, year]);

  //VALIDATE EMAIL
  function isValidEmail(email) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  }

  //Handle Title Change
  const handleTitleChange = (value) => {
    setAboutDetails((prevData) => ({
      ...prevData,
      title: value,
    }));
    validateInput("title", value);
  };

  //for countrySelection
  const CountrySelect = ({
    value,
    onChange,
    selectedLabel,
    labels,
    ...rest
  }) => (
    <div className="prescriber-country-selection-container">
      <select
        {...rest}
        value={value}
        onChange={(event) => onChange(event.target.value || undefined)}
      >
        <option value="">{labels["ZZ"]}</option>
        {getCountries().map(
          (country, i) =>
            country !== "International" && (
              <option key={country} value={country}>
                {`+${getCountryCallingCode(country)}`}
              </option>
            )
        )}
      </select>
      <span className="selected-label">{selectedLabel}</span>
    </div>
  );

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
    selectedLabel: PropTypes.string,
  };

  





  //HANDLE PHONE NUMBER CHANGE
 

  // //VALIDATION
  // const validateInput = (name, value) => {
  //   switch (name) {
  //     case "firstName":
  //       if (!value) {
  //         setValidationErrors((prev) => ({
  //           ...prev,
  //           firstName: "First name is required",
  //         }));
  //       } else {
  //         if (!validName.test(value)) {
  //           setValidationErrors((prev) => ({
  //             ...prev,
  //             firstName: "Invalid first name",
  //           }));
  //         } else {
  //           setValidationErrors((prev) => ({
  //             ...prev,
  //             firstName: "",
  //           }));
  //         }
  //       }
  //       break;
  //     case "lastName":
  //       if (!value) {
  //         setValidationErrors((prev) => ({
  //           ...prev,
  //           lastName: "Last name is required",
  //         }));
  //       } else {
  //         if (!validName.test(value)) {
  //           setValidationErrors((prev) => ({
  //             ...prev,
  //             lastName: "Invalid last name",
  //           }));
  //         } else {
  //           setValidationErrors((prev) => ({
  //             ...prev,
  //             lastName: "",
  //           }));
  //         }
  //       }
  //       break;
  //     case "email":
  //       if (!value || !isValidEmail(value)) {
  //         setValidationErrors((prev) => ({
  //           ...prev,
  //           email: "Please enter valid email",
  //         }));
  //       } else {
  //         setValidationErrors((prev) => ({
  //           ...prev,
  //           email: "",
  //         }));
  //       }
  //       break;
  //     case "phoneNumber":
  //       if (name === "phoneNumber") {
  //         const numericValue = value.replace(/[^0-9]/g, "");
  //         setValidationErrors((prev) => ({
  //           ...prev,
  //           phoneNumber:
  //             numericValue.length < 10 || numericValue.length > 15
  //               ? "Valid phone number is required (10-15 digits)"
  //               : "",
  //         }));
  //       } else {
  //         setValidationErrors((prev) => ({
  //           ...prev,
  //           phoneNumber: "",
  //         }));
  //       }
  //       break;

  //     case "title":
  //       if (!value) {
  //         setValidationErrors((prev) => ({
  //           ...prev,
  //           title: "Title is required",
  //         }));
  //       } else {
  //         setValidationErrors((prev) => ({
  //           ...prev,
  //           title: "",
  //         }));
  //       }

  //     default:
  //       break;
  //   }
  // };
  const validateInput = (name, value) => {
    const minCharacterLength = 3;
    switch (name) {
      case "firstName":
      case "lastName":
        if (!value) {
          setValidationErrors((prev) => ({
            ...prev,
            [name]: "First name is required",
          }));
        } else if (value.length < minCharacterLength && validName.test(value)) {
          setValidationErrors((prev) => ({
            ...prev,
            [name]: `Please enter at least ${minCharacterLength} characters`,
          }));
        } else if (!validName.test(value)) {
          setValidationErrors((prev) => ({
            ...prev,
            [name]: `Invalid ${name === 'firstName' ? 'first' : 'last'} name`,
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            [name]: "",
          }));
        }
        break;
      case "email":
        if (!value || !isValidEmail(value)) {
          setValidationErrors((prev) => ({
            ...prev,
            email: "Please enter valid email",
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            email: "",
          }));
        }
        break;
      case "phoneNumber":
        if (name === "phoneNumber") {
          const numericValue = value.replace(/[^0-9]/g, "");
          setValidationErrors((prev) => ({
            ...prev,
            phoneNumber:
              numericValue.length < 10 || numericValue.length > 15
                ? "Valid phone number is required (10-15 digits)"
                : "",
          }));
        } else {
          setValidationErrors((prev) => ({
            ...prev,
            phoneNumber: "",
          }));
        }
        break;
      // case "title":
      //   if (!value) {
      //     setValidationErrors((prev) => ({
      //       ...prev,
      //       title: "Title is required",
      //     }));
      //   } else {
      //     setValidationErrors((prev) => ({
      //       ...prev,
      //       title: "",
      //     }));
      //   }
      //   break;
      default:
        break;
    }
  };
  
  const titleArray = ["mr", "mrs", "miss", "mx", "dr", "sir"];
  const customStyle = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Adjust the z-index value according to your requirement
    }),
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "#FFFFFF",
      outline: "none",
      appearance: "none",
      padding: "10px 10px",
      borderRadius: "10px",
      border: "1px solid #878787",
      height: "55px",
      textTransform: "capitalize",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        textTransform: "capitalize",
      };
    },
  };

  //Handle Text Input Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAboutDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(name, value);
  };

  //HANDLE SUBMIT
  const handleContinue = () => {
    const validationErrors = {};
    if (!aboutDetails.firstName) {
      validationErrors.firstName = "First name is required";
    }
    if (!aboutDetails.lastName) {
      validationErrors.lastName = "Last name is required";
    }
    // if (!aboutDetails.title) {
    //   validationErrors.title = "Title is required";
    // }
    if (!aboutDetails.email || !isValidEmail(aboutDetails.email)) {
      validationErrors.email = "Please enter valid email";
    }
    setValidationErrors(validationErrors);
    if (Object.values(validationErrors).some((error) => error) || dobError) {
      return;
    } else {
      setLoading(true);
      initiateSavePrescriberAboutDetails(
        {
          // title: aboutDetails.title,
          fname: aboutDetails.firstName,
          lname: aboutDetails.lastName,
          email: aboutDetails.email,
          countryCode: countryCode,
          contactNumber: countryCode === "GB" ? aboutDetails.phoneNumber?.substring(1) :aboutDetails.phoneNumber,
          phoneCountry: selectedCountryLabel,
        },
        (res) => {
          setLoading(false);
          toast.success(res.message);
          // navigate(getPrescriberBusinessDetailsRoute);
          afterSubmit();
          onNextStep();
        },
        (error) => {
          setLoading(false);
          toast.error(error.message);
        }
      );
    }
  };

  //DELETE PROFILE MODAL
  const handleDeleteProfile = () => {
    setIsDeleteProfileConfirm(false);
    setIsProfilePreview(false);
    setProfileImageData({
      profilePreviewUrl: "",
      isProfilePicPresent: false,
      profilePreviewTitle: "",
    });
    initiateDeletePrescriberProfilePic(
      (res) => {
        // toast.success(res.message);
        // Refocus the input field
        if (profilePicInputRef.current !== null) {
          profilePicInputRef.current.focus();
        }
        // Clear the input field value
        if (profilePicInputRef.current !== null) {
          profilePicInputRef.current.value = "";
        }
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };
  console.log(profileImageData, "rooooooooooo");
  return (
    <div className="prescriber-about-container">
      {/* BANNER */}
      <FinanceBanner
        title={"About you"}
        desc={"Few things we need from you."}
        isBorder={!isRequestSentToAdmin}
        currentStep={finish}
        totalSteps={4}
      />

      <div className={`prescriber-Main-Sec-Wrapper ${!isRequestSentToAdmin ? '' : ''}`}>
        <div className="prescriber-profile-container">
          {/* PROFILE CONTAINER */}
          <div className="prescriber-profile-container">
            {fetchLoading ? (
              <SkeletonLoader type="pharmacy-about-you" />
            ) : (
              <div className="main-profile-content">
                {/* Display profile picture if available */}
                {profileImageData.isProfilePicPresent && (
                  <>
                    {/* Display skeleton until image is loaded */}
                    {isImageLoaded === false && (
                      <Skeleton width={80} height={80} circle={true} />
                    )}
                    <img
                      src={profileImageData.profilePreviewUrl}
                      alt={`${aboutDetails?.firstName?.charAt(
                        0
                      )} ${aboutDetails?.lastName?.charAt(0)}`}
                      style={{
                        display: isImageLoaded ? "block" : "none",
                        borderRadius: "50%",
                      }}
                      onLoad={() => setIsImageLoaded(true)}
                      onError={() => setIsImageLoaded(false)}
                      width={80}
                      height={80}
                    />
                  </>
                )}
                {/* Display Avatar with initials if profile picture is not available */}
                {!profileImageData.isProfilePicPresent && (
                  <Avatar
                    name={`${aboutDetails?.firstName?.charAt(
                      0
                    )} ${aboutDetails?.lastName?.charAt(0)}`}
                    round
                    size="80"
                    color="#EEEFF3"
                    fgColor="#000"
                    textSizeRatio={2}
                    style={{
                      display: profileImageData.isProfilePicPresent
                        ? "none"
                        : "block",
                    }}
                  />
                )}
                <div className="profile-action-container">
                  <p onClick={handleProfilePicker}>Upload new picture</p>
                  {profileImageData.isProfilePicPresent && (
                    <b onClick={() => setIsDeleteProfileConfirm(true)}>
                      Delete
                    </b>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="row justify-content-between flex-column flex-md-row">
            <div className="col col-md-6">
              <div className="about-left-container">
                <div className="first-flex-div">
                  {/* TITLE DROPDOWN */}
                  {/* {fetchLoading ? (
                    <SkeletonLoader type="pharmacyAboutInputSkeleton" />
                  ) : (
                    <div className="finance-input-container">
                      <CustomDropdown
                        labelName={"Select Title"}
                        defaultValue={aboutDetails?.title}
                        dropdownId={"title"}
                        placeholdername={"Title"}
                        optionsArr={titleArray}
                        style={customStyle}
                        handleSelectedValue={handleTitleChange}
                      />
                      {validationErrors.title && (
                        <span className="error">{validationErrors.title}</span>
                      )}
                    </div>
                  )} */}

                  {/* FIRST NAME */}
                  {fetchLoading ? (
                    <SkeletonLoader type="pharmacyAboutInputSkeleton" />
                  ) : (
                    <div className="finance-input-container">
                      <TextInput
                        id="firstName"
                        label="First name"
                        placeholder="First name"
                        name="firstName"
                        value={aboutDetails?.firstName}
                        onChange={(e) => handleChange(e)}
                      />
                      {validationErrors.firstName && (
                        <span className="error">
                          {validationErrors.firstName}
                        </span>
                      )}
                    </div>
                  )}

                  {/* LAST NAME */}
                  {fetchLoading ? (
                    <SkeletonLoader type="pharmacyAboutInputSkeleton" />
                  ) : (
                    <div className="finance-input-container">
                      <TextInput
                        label="Last name"
                        placeholder="Last name"
                        name="lastName"
                        value={aboutDetails?.lastName}
                        onChange={(e) => handleChange(e)}
                      />
                      {validationErrors.lastName && (
                        <span className="error">
                          {validationErrors.lastName}
                        </span>
                      )}
                    </div>
                  )}

                  {/* EMAIL */}
                  {fetchLoading ? (
                    <SkeletonLoader type="pharmacyAboutInputSkeleton" />
                  ) : (
                    <div className="finance-input-container">
                      <TextInput
                        label="Email address"
                        placeholder="name@gmail.com"
                        value={aboutDetails?.email}
                        onChange={(e) => handleChange(e)}
                        name="email"
                      />
                      {validationErrors.email && (
                        <span className="error">{validationErrors.email}</span>
                      )}
                    </div>
                  )}

                  {/* PHONE NUMBER */}
                  {fetchLoading ? (
                    <SkeletonLoader type="pharmacyAboutInputSkeleton" />
                  ) : (
                    <div className="finance-input-container">
                      <p className="form-label">Enter phone number</p>
                      <div className="CountryInputWrapper">
                        <CountrySelect
                          labels={{ ZZ: "Select" }}
                          value={countryCode}
                          onChange={(e) => handleCountryChange(e)}
                        />
                        <TextInput
                          name="phoneNumber"
                          value={aboutDetails?.phoneNumber}
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter phone number"
                        />
                      </div>
                      {validationErrors.phoneNumber && (
                        <span className="error">
                          {validationErrors.phoneNumber}
                        </span>
                      )}
                    </div>
                  )}
                  {fetchLoading ? (
                    <SkeletonLoader type="PharmacyAboutStepButtonSkeleton" />
                  ) : (
                    <div className="third-flex-div mobile-none">
                      <StepButton
                        label={"Continue"}
                        blue={true}
                        disabled={
                          Object.values(validationErrors).some(
                            (error) => error
                          ) || dobError
                        }
                        onClick={handleContinue}
                        isLoading={loading}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col col-md-5">
              <div className="prescriber-right-card-container">
                <FinanceBenefitCard
                  icon={Doc}
                  content="You are now registering for the ability to purchase from the Faces pharmacy or be listed within the prescribers directory. Your application will be sent directly to pharmacy partners to approve allowing you to purchase prescription items. You will be notified once approved."
                />

                <FinanceBenefitCard
                  icon={Doc}
                  content="All registered prescribers can then prescribe for non prescribers to allow the sale of prescription items through Faces."
                />
              </div>
            </div>
            <div className="third-flex-div desktop-none">
                      <StepButton
                        label={"Continue"}
                        blue={true}
                        disabled={
                          Object.values(validationErrors).some(
                            (error) => error
                          ) || dobError
                        }
                        onClick={handleContinue}
                        isLoading={loading}
                      />
                    </div>
          </div>
        </div>
      </div>
      <input
        ref={profilePicInputRef}
        type="file"
        accept=".jpg, .jpeg, .png, .svg" // Add acceptable file formats
        onChange={handleProfileImageUpload}
        style={{ display: "none" }} // Hide the default file input style
        id="profilePicInputRef" // Use a label with htmlFor to trigger file input
      />
      {/* DELETE PROFILE CONFIRM MODAL */}
      <ConfirmationModal
        open={isDeleteProfileConfirm}
        setOpen={setIsDeleteProfileConfirm}
        title="Are you sure?"
        className={"ConfirmationCertificateDeleteModal"}
        content="you want to delete this Profile? This action cannot be reversed."
        onConfirm={() => {
          handleDeleteProfile();
        }}
        onCancel={() => setIsDeleteProfileConfirm(false)}
      />
    </div>
  );
};

export default PrescriberAbout;
