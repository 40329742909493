import { ADD_REVIEWS_DATA, ADD_REVIEWS_DATA_ERROR, ADD_REVIEWS_DATA_SUCCESS, CLEAR_REVEWS_DATA, FORWARD_SENDMAIL, FORWARD_SENDMAIL_ERROR, FORWARD_SENDMAIL_SUCCESS, GET_CLIENTS_DATA, GET_CLIENTS_DATA_SUCCESS, GET_REVIEWS_DATA, GET_REVIEWS_DATA_ERROR, GET_REVIEWS_DATA_SUCCESS } from "../../constants"

const initialState = {
    reviewsData: null,
    ratingReviewsList: [],
    totalRatingReviewsCount: 0,
    loadedRatingReviewsCount: 0,
    ratingReviewsPage: 0,
    clientsData: null,
    sendMailData: null,
    forwardMailFlag: false,
    addReplyFlag: false,
    addReplyData: null, //if success then true otherwise false

    //PAGINATION
    // clientDataList: [],
    clientDataList: [...Array(6)].map(i => (null)),
    totalclientDataCount: 0,
    loadedclientDataCount: 0,
    clientDataPage: 0,


    getReviewsLeftSkeletonFlag: false,
    getReviewsRightSkeletonFlag: false,
    getClientsDataSkeletonFlag: false
}
export const GetReviewsDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REVIEWS_DATA:
            let tempData
            if (action.payload.page == 1 && action.payload.rating.length == 0) {
                tempData = null
            } else {
                tempData = state?.reviewsData
            }
            return {
                ...state,
                reviewsData: tempData,
                getReviewsLeftSkeletonFlag: tempData == null ? true : false,
                getReviewsRightSkeletonFlag: true
            }

        case GET_REVIEWS_DATA_SUCCESS:

            let temp = (state?.ratingReviewsList || []).concat(
                (action.payload.data?.elementList || []).map(review => ({
                    ...review,
                    showReply: false
                }))
            );
            let oldTreatmentListPage = state.ratingReviewsPage + 1;

            return {
                ...state,
                reviewsData: state.reviewsData == null ? action.payload?.data : state.reviewsData,
                ratingReviewsList: temp,
                totalRatingReviewsCount: action.payload.data.total,
                loadedRatingReviewsCount: temp.length,
                ratingReviewsPage: oldTreatmentListPage,
                getReviewsLeftSkeletonFlag: false,
                getReviewsRightSkeletonFlag: false
            }

        case GET_REVIEWS_DATA_ERROR:
            return {
                ...state,
                getReviewsRightSkeletonFlag: false
            }

        case GET_CLIENTS_DATA:
            let tempDataClient
            let tempNull = [...Array(6)].map(i => (null))
            if (action.payload?.page == 1) {
                tempDataClient = tempNull
            } else {
                tempDataClient = state?.clientDataList.concat(tempNull)
            }
            return {
                ...state,
                clientsData: null,

                clientDataList: tempDataClient,
                // totalclientDataCount: 0,
                // loadedclientDataCount: 0,
                clientDataPage: action.payload?.page == 1 ? 0 : state.clientDataPage,

                getClientsDataSkeletonFlag: true
            }

        case GET_CLIENTS_DATA_SUCCESS:


            let tempClientVar = (state?.clientDataList || [])?.filter(i => i).concat(
                (action.payload.data?.elements || []).map(listData => ({
                    ...listData,
                    title: listData.cl_dt_name
                }))
            );
            let oldClientDataListPage = state.clientDataPage + 1;
            return {
                ...state,
                clientDataList: tempClientVar,
                totalclientDataCount: action.payload.data.total,
                loadedclientDataCount: tempClientVar.length,
                clientDataPage: oldClientDataListPage,

                clientsData: action.payload.data,
                getClientsDataSkeletonFlag: false
            }



        case FORWARD_SENDMAIL_SUCCESS:
            return {
                ...state,
                sendMailData: action.payload.data,
                updateOccupationFlag: false
            }

        case FORWARD_SENDMAIL_ERROR:
            return {
                ...state,
                updateOccupationFlag: false
            }
        case FORWARD_SENDMAIL:
            return {
                ...state,
                updateOccupationFlag: true
            }

        case ADD_REVIEWS_DATA:
            return {
                ...state,
                addReplyFlag: true,
                addReplyData: null
            }

        case ADD_REVIEWS_DATA_SUCCESS:
            return {
                ...state,
                addReplyFlag: false,
                addReplyData: true
            }
        case ADD_REVIEWS_DATA_ERROR:
            return {
                ...state,
                addReplyFlag: false,
                addReplyData: false
            }


        case CLEAR_REVEWS_DATA:
            return {
                ...state,
                // reviewsData: null,
                ratingReviewsList: [],
                totalRatingReviewsCount: 0,
                loadedRatingReviewsCount: 0,
                ratingReviewsPage: 0,
            }
        default:
            return state
    }
}  