import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_CONSENT_FORM_PRODUCT_LIST } from "../../constants/consentFormThankyou/consentFormProductConst";

const GET_CONSENT_FORM_PRODUCT_QUERY = gql`
query GetConsentFormProducts($consentFormRecordId: Int, $page: Int, $limit: Int, $search: String, $searchByVendor: [Int]) {
  getConsentFormProducts(consent_form_record_id: $consentFormRecordId, page: $page, limit: $limit, search: $search, search_by_vendor: $searchByVendor) {
    success
    message
    data {
      is_already_reviewed
      clinic_name
      is_prescriber
      prescriber_details {
        pres_id
        pres_fee
        pres_currency
        pres_name
        pres_email
        pres_profile
        pres_profession
      }
      total
      items {
        id
        product_name
        vendor_name
        vendor_id
        vendor_is_pharmacy
        currency
        price
        image_url
        shop_page_url
        is_prescription_required
        quantity
        client_id
        client_name
        client_email
        client_profile
        is_user_registered_with_vendor
        is_approved_by_admin
        is_approved_by_pharmacy
        allow_buy_directly
        is_retail_product
        registration_message
        pharmacy_request_sent
      }
    }
  }
}
  `;

// var page;
export const ConsentFormProductListQuery = () => {
    const dispatch = useDispatch();
    const [getConsentFormProductList, consentFormProductListData] = useLazyQuery(
        GET_CONSENT_FORM_PRODUCT_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getConsentFormProducts: responseData }) => {

                dispatch({
                    type: GET_CONSENT_FORM_PRODUCT_LIST,
                    payload: responseData?.data,
                    // page
                });
                if (responseData.success) {

                } else {

                }


            },
        }
    );
    const consentFormProductListQueryData = consentFormProductListData;
    const initConsentFormProductcList = (data,successCallBack,errorCallBack) => {
        // page = data.page
        getConsentFormProductList({
            variables: data,
        }).then((res)=>{
            successCallBack(res?.data?.getConsentFormProducts)
            console.log("first",res?.data?.getConsentFormProducts)
        }).catch((err)=>{
            errorCallBack(err)
        })
    };
    return { initConsentFormProductcList, consentFormProductListQueryData };
};



