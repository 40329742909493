import React, { useEffect, useRef, useState } from "react"
import "./verify-forgot-password-component.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import FinaceHeader from "../../finaceHeader"
import { ProcessForgotePassword } from "../../redux/actions/pay-later/processForgotePassword"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { STORE_VERIFICATION_CODE } from "../../redux/constants"
import { toast } from "react-toastify"
import { ForgotePasswordForFinance } from "../../redux/actions/pay-later/forgotePasswordForFinance"
import StepButton from "../../common/StepButton"

const VerifyForgotPasswordComponent = () => {
    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
    const [addVerifyToBePostError, setAddVerifyToBePostError] = useState("")
    const { initiateprocessForgotePassword, processForgotePasswordData } = ProcessForgotePassword();
    const email = useSelector(state => state?.financeLoanRequestEmailReducer?.userEmailForFinance)
    const navigate = useNavigate();
    const { initiateforgotePasswordForFinance, forgotePasswordForFinanceData } = ForgotePasswordForFinance();
    const inputRefs = useRef(verificationCode.map(() => React.createRef()));

    useEffect(() => {
        if (!email) {
            navigate("/forgot-password")
        }
        inputRefs.current[0].current.focus();
    }, [email])
    const dispatch = useDispatch();

    // const handleInputChange = (index, value) => {
    //     const updatedCode = [...verificationCode];
    //     updatedCode[index] = value;
    //     setVerificationCode(updatedCode);
    // };


    const handleInputChange = (index, value) => {
        const updatedCode = [...verificationCode];
        updatedCode[index] = value;
        setVerificationCode(updatedCode);

        // Move focus to the next input box if the current value is filled and not the last input
        if (value && index < verificationCode.length - 1) {
            inputRefs.current[index + 1].current.focus();
        }
    };

    const handleAddVerifySubmit = (e) => {
        e.preventDefault()
        const verificationCodeString = verificationCode.join('');
        if (verificationCode.every((digit) => digit.trim() !== '')) {
            initiateprocessForgotePassword({
                email: email,
                code: verificationCodeString,
                // changePassword :true,
                // password : null
            },
                (res) => {
                    if (res.success) {
                        dispatch({
                            type: STORE_VERIFICATION_CODE,
                            payload: verificationCodeString,
                        });
                        navigate("/forgot-password-update")
                    }
                },
                (err) => {
                    console.log("errr999999999", err)
                })
            setAddVerifyToBePostError("")
            // alert('Verification forgot password code submitted: ' + verificationCode.join(''));
        } else {
            setAddVerifyToBePostError("Please Enter Verification Forgot Password Code!")
            return
        }
    }

    const resendOtpFunction = () => {
        initiateforgotePasswordForFinance({
            email: email,
            forFinance: true,
            platform: null
        },
            (res) => {
                // toast.success(res.message)
            },
            (err) => {
                // toast.error(err.message)
            }
        )
    }

    return (
        <>
            <div className="mainfinancelandingpage mainVerifyAuthpage">
                <FinaceHeader />
                <div className="container mt-5">
                    <div className="verify-email row justify-content-center">
                        <div className="col-lg-5 col-md-8 col-sm-12">
                            <h2 className="pagenewPageTitle pt-5">Verification Forgot Password</h2>
                            <label htmlFor="emailId" className="py-2 form-label">
                                Enter code
                            </label>
                            <div className="otpInputWrapper">
                                <div>
                                    {verificationCode.map((digit, index) => (
                                        <input
                                            type="text"
                                            maxLength="1"
                                            className="verify-code form-control"
                                            value={digit}
                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                            ref={inputRefs.current[index]} // Use the ref for this input
                                        />
                                    ))}
                                </div>
                            </div>
                            {addVerifyToBePostError && <p className='text-danger error'>{addVerifyToBePostError}</p>}
                            {/* <div className="justify-content-center rounded-circle-border my-3" style={{marginBottom:"10px"}}>
                                <button type="button" className="btn btn-primary apply-btn" onClick={handleAddVerifySubmit}>Submit</button>
                            </div> */}
                            <StepButton
                                onClick={handleAddVerifySubmit}
                                blue={true}
                                id={"ryft-pay-btn"}
                                label={"Submit"}
                                isLoading={processForgotePasswordData.loading}
                            />
                            <span onClick={resendOtpFunction} className="resendBtn">Resend code</span>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyForgotPasswordComponent