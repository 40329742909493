import React from 'react'
import './no-client.scss'
import SadFace from '../../images/client/sad-face.png'
import CreateMyForm from '../../images/client/createMyForm.png'
import StepButton from '../common/StepButton'

const EmptyClient = ({ name, title, subTitle, type, activeTab, setAfterCareFormModal, setCreateNewFormModal, image }) => {
  const handlePlusClick = () => {
    if (activeTab == 1) {
        setAfterCareFormModal(true)
    }
    if (activeTab == 3) {
        setCreateNewFormModal(true)
    }
}
  return (
    <div className='no-client-container'>
      <div className='no-client-content'>
        {
          // type == "CreateMyForm" ?
            <img style={{maxWidth: '120px'}} src={image || CreateMyForm} alt='CreateMyForm' /> 
            // <img src={CreateMyForm} about='CreateMyForm' /> :

            // <img src={SadFace} about='sad-face' />
        }
        <h1>{title}</h1>
        <p className='text-center'>{name} {subTitle}</p>
          {/* <StepButton blue={true} label={"Create form"} onClick={() => handlePlusClick()} /> */}
      </div>
    </div>
  )
}

export default EmptyClient