import React, { useEffect, useState } from "react";
import { ReactComponent as MobileIcon } from "../../../../../images/phone.svg";

import "./ActiveSessions.scss";
import StepButton from "../../../../common/StepButton";
import BusinessNavigation from "../../../../common/businessNavigation/BusinessNavigation";
import ShopConfirmModal from "../../../../businessSetting/manageAccount/Shop/BusinessShopIdentity/ShopConfirmModal";
import { GetLoginHistory } from "../../../../redux/actions/LoginHistory/getLoginHistory";
import Skeleton from "react-loading-skeleton";
import { useLogoutUserActiveSessions } from "../../../../redux/actions/LoginHistory/LogoutAllusers";
import { LogoutUserSessions } from "../../../../redux/actions/LoginHistory/LogoutUserActiveSessions";
import { toast } from "react-toastify";
// import { socket } from "../../../../../components/socket";
import { socket } from "../../../../../components/socket"
import { notificationMode } from "../../../../../config";



function ActiveSessions({ setStep }) {
  const [open, setOpen] = useState(false);
  const [alluserLogoutmodal, setAlluserLogoutmodal] = useState(false);
  const [logoutId, setlogoutId] = useState();
  const [loginData, setloginData] = useState([]);
  const { initiateLogoutUserSessions, logoutUserSessionsData } = LogoutUserSessions();
  const { handleLogoutUserActiveSessions, logoutUserActiveSessionsData } = useLogoutUserActiveSessions();

  const [logoutData, setlogoutData] = useState();


  const { getLoginHistoryQueryData,
    initGetLoginHistoryData } = GetLoginHistory()


  useEffect(() => {

    initGetLoginHistoryData({}, (res) => { setloginData(res?.data?.loginData) }, (err) => {
      console.log(err, "errhitory");

      window.location.reload()

    })

  }, [open])
  const handleAllsessionlogoutCta = () => {
    setAlluserLogoutmodal(true)
  }
  // console.log(loginData,"logindata");
  // console.log(loginData?.length === 0,"lengthhh") condition for skeleton
  return (
    <div className="Main-Active-Sessions Main684WidthSet">

      <BusinessNavigation
        isColumn={true}
        title={"Active sessions"}
        desc={"Current active sessions"}
        rightBtn={true}
        RightBtnClick={handleAllsessionlogoutCta}
        btnTitle={"Log out all sessions"}
        onBackClick={() => {
          setStep(0);
        }}
      />

      {console.log(loginData,"loginDataloginData1020")}

      {loginData?.length === 0 ?
        <div className="ActiveSessionsList">
          {[...Array(10)].map(i =>
            <div className="ActiveSessionsItem">
              <div className="LeftContent">
                <h5><Skeleton width={121} height={22} borderRadius={4} /></h5>
                <div className="MiddleContent">
                  <p><Skeleton width={89} height={22} borderRadius={4} /></p>
                  <Skeleton width={6} height={6} circle />
                  <p><Skeleton width={89} height={22} borderRadius={4} /></p>
                </div>
              </div>
              <div className="RightContent">
                <Skeleton width={78} height={34} borderRadius={100} />
              </div>
            </div>)} </div> : <div className="ActiveSessionsList">
          {loginData.map((ele, index) => {

            // Sample datetime string   
            const dateTimeStr = ele?.loggedin_date;

            // Split the date and time parts
            const [date, ...timeArr] = dateTimeStr.split(" ");

            // Join the time parts back together (including AM/PM)
            const time = timeArr.join(" ");


            return (
              <>
                <div className="ActiveSessionsItem" key={index}>
                  <div className="LeftContent">
                    <h5>{ele?.log_tok_dev_name}</h5>
                    <div className="MiddleContent">

                      <p>{date}</p>
                      <span className="Dots" />
                      <p>{time}</p>
                    </div>
                  </div>


                  <div className="RightContent">
                    <button
                      className="LogoutBtn"
                      onClick={() => {
                        setlogoutId(ele?.log_tok_id)
                        setOpen(true);
                        setlogoutData(ele)
                      }}
                    >
                      Log out
                    </button>
                  </div>
                </div>
              </>
            );
          })}

        </div>}
      <div className="bottomBtn">
        <StepButton label={"Save"} />
      </div>

      <ShopConfirmModal
        open={open}
        setOpen={setOpen}
        title="Are you sure?"
        className={"ConfirmationDeleteModal"}
        content="You want to logout this session."
        onConfirm={() => {
          // console.log(logoutId,"ele")
          initiateLogoutUserSessions({ sessionId: logoutId }, (res) => {
            setOpen(false);

            socket.emit('message', {
              mode: notificationMode,
              device: logoutData?.log_tok_token,
              to_id: logoutData?.log_tok_usr_id,
              type: 'logout_request'
            })
            toast.success("Your account is logged out from the device!")
          }, (err) => { console.log(err) })


        }}
        onCancel={() => setOpen(false)}
      />


      <ShopConfirmModal
        open={alluserLogoutmodal}
        setOpen={setAlluserLogoutmodal}
        title="Are you sure?"
        className={"ConfirmationDeleteModal"}
        content="You want to logout all this session's."
        onConfirm={() => {
          // console.log(logoutId,"ele")
          // initiateLogoutUserSessions({ sessionId: logoutId }, (res) => { setOpen(false); }, (err) => { console.log(err) })
          handleLogoutUserActiveSessions({ isAllLogout: true }, (res) => {
            setAlluserLogoutmodal(false);
            
            if (loginData?.length > 0) {
              
              loginData.forEach((session) => {
                console.log(session,"sessionsessionsession")
                socket.emit('message', {
                  mode: notificationMode,
                  device: session.log_tok_token, // Assuming each session has a log_tok_token
                  to_id: session.log_tok_usr_id, // Assuming each session has a log_tok_usr_id
                  type: 'logout_request'
                });
              });
            }
            // socket.emit('message', {
            //   mode: notificationMode,
            //   device: logoutData?.log_tok_token,
            //   to_id: logoutData?.log_tok_usr_id,
            //   type: 'logout_request'
            // }); 
            window.location.reload()
            console.log("inside res block")
          }, (err) => { console.log(err) })

        }}
        onCancel={() => setAlluserLogoutmodal(false)}
      />
    </div>
  );
}

export default ActiveSessions;
