import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";

const GET_PRESCRIBER_OCCUPATION_LIST_QUERY = gql`
  query GetPrescriberOccupationList {
    getPrescriberOccupationList {
      success
      message
      data {
        category
        occupations {
          id
          title
          type
          pin_status
        }
      }
    }
  }
`;

export const GetPrescriberOccupationList = () => {
  const dispatch = useDispatch();
  const [getOccupationList, { loading, error, data }] = useLazyQuery(GET_PRESCRIBER_OCCUPATION_LIST_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: (response) => {
      // Handle the completed response here if necessary
    },
  });

  const initGetPrescriberOccupationList = () => {
    getOccupationList();
  };

  return { loading, error, data, initGetPrescriberOccupationList };
};
