import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_PRESCRIPTION_PRODUCTS_QUERY = gql`
  query GetPrescriptionProducts {
    getPrescriptionProducts {
      success
      message
      data {
        items {
          product_id
          product_name
          product_image
          product_price
          vendor_name
          avg_rating
          total_reviews
          is_added_to_wishlist
          product_slug
        }
      }
    }
  }
`;

export const GetPrescriptionProductsAction = () => {
  const [
    getPrescriptionProductsResponseFunction,
    getPrescriptionProductsResponseData,
  ] = useLazyQuery(GET_PRESCRIPTION_PRODUCTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: ({ getPrescriptionProducts: responseData }) => {},
  });

  const getPrescriptionProductsQueryData = getPrescriptionProductsResponseData;

  const initGetPrescriptionProductsData = (
    data,
    successCallback,
    errorCallback
  ) => {
    getPrescriptionProductsResponseFunction({
      variables: data,
    })
      .then((responseData) => {
        if (responseData?.data?.getPrescriptionProducts?.success) {
          successCallback(responseData?.data?.getPrescriptionProducts);
        } else {
          errorCallback(responseData?.data?.getPrescriptionProducts);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };

  return {
    getPrescriptionProductsQueryData,
    initGetPrescriptionProductsData,
  };
};
