import React from 'react'
import CustomRadioButton from '../../../common/CustomRadioButton'
import Skeleton from 'react-loading-skeleton';

const FilterCanvasSection = ({
    imgIcon,
    imgLabel,
    imgLabel2,
    label2Component = false,
    isLoading = false
}) => {

    const truncateString = (str) => {
        return str?.length > 20 ? str?.substring(0, 20) + "..." : str;
    }
    return (
        <>
            {
                isLoading ?
                    <>
                        <div className="left-section  mb-2">
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <div className="icon-image">
                                        <Skeleton circle={true} height={40} width={40} />
                                    </div>
                                    <div className='ms-2'>
                                        <Skeleton className='mb-2' height={15} width={100} />
                                        <Skeleton height={15} width={140} />
                                    </div>
                                </div>
                                <div>
                                    <Skeleton height={20} width={20} />
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="left-section">
                        <div className="icon-image">
                            <img src={imgIcon} alt={imgLabel} />
                        </div>
                        <div className=''>
                            <div className="icon-label">{truncateString(imgLabel)}</div>
                            {
                                label2Component && label2Component
                            }
                            {imgLabel2 && <div className="icon-label2">{truncateString(imgLabel2)}</div>}
                        </div>
                    </div>
            }
        </>
    )
}

export default FilterCanvasSection