import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";


const CREATE_IDENTITY_MUTATION = gql`
mutation SaveClientIdentityDocuments($clientId: Int!, $identityDocuments: [Upload], $documentId: Int) {
  saveClientIdentityDocuments(clientId: $clientId, identityDocuments: $identityDocuments, documentId: $documentId) {
    success
    message
    data {
      documents {
        clientId
        documentId
        documentUrl
        documentName
        time
      }
    }
  }
}
`;

export const CreateIdentityDocument = () => {
  const [createIdentity, createIdentityResponseData] =
    useMutation(CREATE_IDENTITY_MUTATION);
  const dispatch = useDispatch();
  const createIdentityMutationData = createIdentityResponseData;

  const initiateCreateIdentity = (
    data,
    successCallback,
    errorCallback
  ) => {
    createIdentity({
      variables: data,
    })
      .then(({ data: { saveClientIdentityDocuments: responseData } }) => {
        if (responseData) {
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateCreateIdentity, createIdentityMutationData };
};