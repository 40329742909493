import React, { useEffect, useLayoutEffect, useState } from 'react'
import '../hair-products/HairProducts.scss'
import OrderSummary from '../hair-products/hair-product-common/hair-product-order-summary/OrderSummary'
import HairProductsthird from '../hair-products/hair-product-third/HairProductsthird'
import HairProductsSecond from '../hair-products/hair-product-second/HairProductsSecond'
import HairProductThankyouPage from '../hair-products/thank-you/HairProductThankyouPage'
import HairProductHeader from '../hair-products/hair-product-common/hair-product-header/HairProductHeader'
import SkeletonLoader from '../../common/Skeleton/SkeletonLoader'
import closeIcon from '../../../images/close_icon.svg'
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { GetHairPrescriptionAmendAnswers } from '../../redux/actions/hair-prescription-amend-answer/GetHairPrescriptionAmendAnswer'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { UpdateHairPrescriptionAmendAnswerMutation } from '../../redux/actions/hair-prescription-amend-answer/SaveHairPrescriptionAmendAnsers'
import showToast from '../../common/CustomToast/CustomToast'

const HairPrescriptionAmendAnswers = () => {
    const pagesize = 6
    const [isoverView, setIsoverView] = useState(false);
    const [active, setActive] = useState(1)
    const [currentStep, setCurrentStep] = useState(0);
    const [currentEditStep, setCurrentEditStep] = useState(0)
    const [answersUpdated, setAnswersUpdated] = useState(false);
    const [gender, setGender] = useState({
        id: '',
        question: '',
        answer: '',
        comment: '',
    })
    const [questions, setQuestions] = useState([]);
    const [initAnswers, setInitAnswers] = useState([])
    const [allAnswers, setAllAnswers] = useState([])
    const { width, height } = useWindowDimensions();
    const { initGetPrescriptionAmendAnswers, hairPrescriptionAmendAnswersQueryData } = GetHairPrescriptionAmendAnswers()
    const { orderId } = useParams();
    const { allAmendAnswers, genderAnswer, productData, amendStatus, totalCount, errorPage } = useSelector((state) => state?.GetHairPrescriptionAmendAnsersReducer)
    const { initiateUpdateHairPrescriptionAmendAnswerResponseData, UpdateHairPrescriptionAmendAnswerMutationData } = UpdateHairPrescriptionAmendAnswerMutation()

    const fetchAmendAnswers = (currentStep) => {
        initGetPrescriptionAmendAnswers(currentStep + 1, pagesize, parseInt(orderId))
    }

    console.log('allAmendAnswers', allAmendAnswers, totalCount, genderAnswer, productData, amendStatus, errorPage, initAnswers)

    useEffect(() => {
        const isDataPresent = initAnswers[currentStep] && initAnswers[currentStep]?.length > 0;

        if (!isDataPresent) {
            fetchAmendAnswers(currentStep);
        }

    }, [currentStep, currentEditStep])

    useEffect(() => {

        const newAllAmendAnswers = allAmendAnswers.map((answer) => ({
            id: answer.id,
            comment: answer.comment,
            answer: answer.answer,
            question: answer.question
        }));

        setInitAnswers(prevQuestions => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[currentStep] = newAllAmendAnswers;
            return updatedQuestions;
        });

        const newupdatedQuestions = allAmendAnswers.map((answer) => ({
            id: answer.id,
            que: answer.question,
            options: answer.options,
            multiple: answer.multiple,
            comment: answer.is_comment,
            yes_no : answer.yes_no
        }));

        setQuestions(prevQuestions => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[currentStep] = newupdatedQuestions;
            return updatedQuestions;
        });

        setGender({
            id: genderAnswer.id,
            question: genderAnswer.question,
            answer: genderAnswer.answer,
            comment: genderAnswer.comment,
        });

    }, [allAmendAnswers])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [active, currentEditStep, currentStep])

    useLayoutEffect(() => {
        setCurrentStep(currentEditStep !== 0 ? currentEditStep : 0);
    }, [currentEditStep]);

    const handleContinueSecond = (selectedGender) => {
        setGender((prev) => ({
            ...prev,
            answer: selectedGender,
        }));
        setActive(active + 1)
    }

    const handleEditStep = (stepIndex) => {
        setActive(1); // Assuming steps are indexed from 1
        setCurrentEditStep(stepIndex)
        setCurrentStep(stepIndex)
    };

    const handleBackClick = () => {
        if (active === 1) {
            if (currentStep > 0) {
                setCurrentStep(currentStep - 1);
                setCurrentEditStep(currentStep - 1);
            }
        } else if (active > 1) {
            setActive(active - 1);
        }
    };

    const saveAnswers = (answers) => {
        setInitAnswers(answers); // Set the allAnswers state to the new answers array
    };

    const UpdateAnswers = () => {
        setAnswersUpdated(true);
    }

    useEffect(() => {
        // This code will execute whenever initAnswers state changes
        if (answersUpdated) {
            const clientAnswers = initAnswers?.flat()

            const updatedClientAnswers = [...clientAnswers];

            updatedClientAnswers.unshift(gender);


            const finaldata = {
                orderId: parseInt(orderId),
                clientAnswers: updatedClientAnswers,
            };

            console.log('finaldata', finaldata);

            initiateUpdateHairPrescriptionAmendAnswerResponseData(finaldata, (res) => {
                if (res.success) {
                    showToast("success", res.message, "", false)
                    setActive(active + 1)
                } else {
                    showToast("error", res.message, "", false)
                }
            }, (err) => {
                showToast("error", err.message, "", false)
                console.log("hold duration", err)
            })


            // Reset the flag after executing the subsequent functionality
            setAnswersUpdated(false);
        }
    }, [answersUpdated]);


    return (
        <>
            {hairPrescriptionAmendAnswersQueryData?.loading ?
                <SkeletonLoader type={"hair-product-main-page"} showCircle={false} />
                :
                <>
                    {errorPage ?
                        <>
                            <div className="h-100 d-flex justify-content-center align-items-center text-center fw-bold fs-1 ">
                                Invalid Link!
                            </div>
                        </> :
                        <div className='main-hair-product-container'>
                            {
                                (active == 2 || amendStatus !== 3) ?  <>
                                    <HairProductThankyouPage />
                                </> :

                                    <>
                                        <HairProductHeader   ViewOverviewBtn={true} onBackClick={handleBackClick} ViewOverviewBtnClick={() => { setIsoverView(true) }} />


                                        <div className="hair-product-container">
                                            <div className=" d-flex justify-content-between  flex-column flex-lg-row">
                                                <div className="hair-product-container-left-content">
                                                    <div className="hair-product-left-wrapper">
                                                        {active === 1 &&
                                                            <>
                                                                <HairProductsthird UpdateHairPrescriptionAmendAnswerMutationData={UpdateHairPrescriptionAmendAnswerMutationData} HairProductQuestionsCountData={totalCount} hairProductQuestionsQueryData={hairPrescriptionAmendAnswersQueryData} onSaveAnswers={saveAnswers} questionsData={questions} OnhandleContinue={UpdateAnswers} allAnswers={initAnswers} currentEditStep={currentEditStep} setCurrentEditStep={setCurrentEditStep} questionData={questions} currentStep={currentStep} setCurrentStep={setCurrentStep} pagesize={pagesize} AmendAnswer={true} />
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className={isoverView ? "hair-product-container-right-content isoverViewActive" : "hair-product-container-right-content"}>
                                                    {isoverView && <div className='closeIconBtn' ><img src={closeIcon} alt={closeIcon} onClick={() => { setIsoverView(false) }} /></div>}
                                                    <OrderSummary selectedCard={productData} allAnswers={initAnswers} onEditStep={handleEditStep} pagesize={pagesize} isAmend={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }


                        </div>
                    }
                </>
            }
            {width < 991 &&
                <div className={isoverView ? "Overlay isActiveOverlay" : "Overlay"} onClick={() => { setIsoverView(false) }} />
            }
        </>
    )
}

export default HairPrescriptionAmendAnswers