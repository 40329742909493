import { useLazyQuery } from '@apollo/client';
import { da } from 'date-fns/locale';
import gql from 'graphql-tag';

const GET_FINANCE_INSURANCE_DETAIL = gql`
query GetInsuranceDetailsForFinance {
  getInsuranceDetailsForFinance {
    success
    message
    data {
      expire_date
      policy_no
      insurance_company_name
        insurance_company_name
      documents {
        id
        document_name
        document_url
          document_title
        document_title
      }
    }
  }
}
`;

export const GetFinanceInsuranceDetailAction = () => {
  const [
    getFinanceInsuranceDetailResponseFunction,
    getFinanceInsuranceDetailResponseData,
  ] = useLazyQuery(GET_FINANCE_INSURANCE_DETAIL, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: ({ getInsuranceDetailsForFinance: responseData }) => {
      if (responseData.success) {
        console.log('res', responseData?.message);
      } else {
        console.log('error', responseData?.message);
      }
    },
  });

  const getFinanceInsuranceDetailQueryData =
    getFinanceInsuranceDetailResponseData;

  const initGetFinanceInsuranceDetailData = (data) => {
    getFinanceInsuranceDetailResponseFunction({
      variables: data,
    });
  };

  return {
    getFinanceInsuranceDetailQueryData,
    initGetFinanceInsuranceDetailData,
  };
};
