import React, { useEffect, useState } from "react";
import { InitiateFinanceLoanRequestGrl } from "../redux/actions/pay-later/initiateFinanceLoanRequestMutation";
import PayLaterApplicationForm from "./PayLaterApplicationForm";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { frontendURL } from "../../config";

const InitiateFinanceLoanRequest = () => {
    const [payLaterData, setPayLaterData] = useState(null)
    const [openPayLater, setOpenPayLater] = useState(false)
    //   const [loader, setLoader] = useState(false)
    const { slug } = useParams()
   
    const { initiateInitiateFinanceLoanRequest } = InitiateFinanceLoanRequestGrl();

    useEffect(() => {
        initiateInitiateFinanceLoanRequest({
            financeLoanRequestId: parseInt(slug),
            redirectUrl: `${frontendURL}`
        }, (res) => {
            if (res.success) {
                setOpenPayLater(true)
                //   dispatch(setPaylaterData(res?.data))
                setPayLaterData(res?.data)
            } else {
                setOpenPayLater(false)
                setPayLaterData(null)
            }
        }, (e) => {
            // setLoader(false)
            toast.error(e.message)
        })
    }, []);

    return (
        <div>
            {openPayLater &&
                <>
                    <PayLaterApplicationForm payLaterData={payLaterData} setOpenPayLater={setOpenPayLater}/>
                </>}
        </div>
    );
};

export default InitiateFinanceLoanRequest;
