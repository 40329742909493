import { gql, useLazyQuery } from "@apollo/client";

const GET_CLIENT_LIST = gql`
  query GetClientList_v3($requestFrom: String, $selectedClinicForClient: [Int]) {
  getClientList_v3(requestFrom: $requestFrom, selectedClinicForClient: $selectedClinicForClient) {
    success
    message
    data {
      clients {
        clentId
        clientName
        clientEmail
        readableContact
        clientProfileImage
        client_initial
        lastAppointmentWasThreeMonthsAgo
      }
      total
    }
  }
}
`;

export const GetClientListAction = () => {
  const [getClientListFunction, { data, loading, error }] = useLazyQuery(
    GET_CLIENT_LIST,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getClientList_v3: responseData }) => {
        console.log(responseData,"nnn")
        if (responseData?.success) {
          // dispatch({
          //     type: GET_CLIENT_LIST_SUCCESS,
          //     payload: responseData
          // })
        }
      },
    }
  );

  const initGetClientList = (data, successCallback, errorCallback) => {
    console.log(data,"///")
    getClientListFunction({
      variables: {
        ...data,
      },
    })
      .then((res) => {
        successCallback(res?.data?.getClientList_v3);
      })
      .catch((err) => {
        errorCallback(err);
      });
  };

  return { data, loading, error, initGetClientList };
};
