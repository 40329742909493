import React from "react"
import PropTypes from "prop-types"
import './FinanceRightCardComponent.scss'

const FinanceBenefitCard = ({ icon: Icon, title, content, onContentClick }) => {
    const handleClick = () => {
        // Check if the onContentClick function is provided before calling it
        if (typeof onContentClick === "function") {
            // Call the onContentClick function
            onContentClick();
        }
    };

    return (
        <div className="finance-right-card">
            <div className="finance-Header-card">
                <div className="left-content">
                    <Icon />
                </div>
                <h1>{title}</h1>
            </div>
            <div className="right-content">
                <p onClick={handleClick} className={`${onContentClick ? "clickable" : ""}`} >
                    {content}
                </p>
            </div>
        </div>
    )
}

FinanceBenefitCard.propTypes = {
    icon: PropTypes.elementType.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onContentClick: PropTypes.func,
}

export default FinanceBenefitCard