import React, { useEffect, useState } from 'react';
import insuranceCheckedLogo from '../../../images/thank-you/checked.png';

// import './insurance-thank-you.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { frontendURL, webBaseUrl } from '../../../config';
import HeaderComponent from '../header-component/header-component';
import Cookies from 'js-cookie';

// import Sidebar from "../../sidebar";
// import SidebarComponent from "../../sidebar";
// import useWindowDimensions from "../../../utils/useWindowDimensions";
// import BusinessHeader from "../../businessHeader";
// import BusinessFooter from "../../businessFooter";
import { useDispatch, useSelector } from "react-redux";
// import Avatar from "react-avatar";
// import { TfiMenu } from 'react-icons/tfi'
import { TrackUserActivityForServicesAction } from '../../redux/actions/social-media-marketing/service-user-activity';
import { PurchasePlumMarketingAction } from '../../redux/actions/social-media-marketing/purchase-social-media-marketing-package';
import { StripeComponent } from '../../pages/stripe-payment/stripe-component';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { GetSavedCardsOfUserAction } from '../../redux/actions/social-media-marketing/purchase-socail-media-marketing-saved-cards';
import { StripeSavedCardsComponent } from './stripe-saved-cards';
import { UpdatePlumMarketingPackagePaymentAction } from '../../redux/actions/social-media-marketing/update-plum-marketing-package-payment-action';
import { PurchaseAccountancyServicesAction } from '../../redux/actions/accountancy-service/purchase-accountancy-services';
import { UpdateAccountancyServicesPackagePaymentAction } from '../../redux/actions/accountancy-service/update-accountancy-services-payment-action';
import { useNavigate } from 'react-router';
import { socialMediaMarketingPagePattern } from '../../../Routes';
import TextInput from '../../socialMediaMarketing/TextInput';

function StripePaymentPage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showPaymentSuccess, updatePaymentSuccess] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    // const { width, height } = useWindowDimensions();
    const [showSidebar, setShowSidebar] = useState(true);

    const navigate = useNavigate()

    /*
    const [serviceUserActivity] = useMutation(SERVICE_USER_ACTIVITY_MUTATION);
    useEffect(() => {
        serviceUserActivity({ variables: { serviceId: 1, activityId: 1 } });
    }, []); */

    const [openReviewPopup, setOpenReviewPopup] = useState(false);
    // const dashboardListData = useSelector((state) => state.dashboardReducer.dashboardListData)
    // const { profileData } = useSelector(state => state?.GetProfileDataReducer)
    const BackToHome = () => {
        window.top.location.href = webBaseUrl + 'user/dashboard';
    }
    // const backUrl = webBaseUrl + '/';

    // For intent generation
    const [intentData, setIntentData] = useState(null);

    /*
    {

        1: {
      
          name: "plump_marketing_agency",
      
          activities: {
      
            1: "CLICK_ON_SERVICE",
      
            2: "CLICK_ON_PACKAGE",
      
            3: "PURCHASE_PACKAGE",
      
            4: "CLICK_ON_CALENDLY"
      
          },
      
        },
      
        2: {
      
          name: "Accountancy_service",
      
          activities: {
      
            1: "CLICK_ON_SERVICE",
      
            2: "CLICK_ON_PACKAGE",
      
            3: "PURCHASE_PACKAGE",
      
            4: "CLICK_ON_CALENDLY"
      
          },
      
        }
      
      }
    */

    const { trackUserActivityForServicesQueryData, initTrackUserActivityForServicesData } = TrackUserActivityForServicesAction();
    let stripeIntentData = false;
    let strTokenId = false;
    const [strToken, setStrToken] = useState(null);
    const [showCustomerLoader, setShowCustomerLoader] = useState(false);
    const { updatePlumpMarketingPackagePaymentQueryData, initUpdatePlumpMarketingPackagePaymentData } = UpdatePlumMarketingPackagePaymentAction();
    const { updateAccountancyPackagePaymentQueryData, initUpdateAccountancyPackagePaymentData } = UpdateAccountancyServicesPackagePaymentAction();
    const [userPaymentInfo, setUserPaymentInfo] = useState(null);

    const submitStripeBtn = document.getElementById('socialMediaMarketingMakePaymentBtn');
    const paymentIntentSuccessCallback = (intentId, paymentMethodId) => {
        // for calling service user activity api for adding purchase flag
        console.log('intentId tmp:', intentId);
        console.log('token id:', paymentMethodId);
        setStrToken(paymentMethodId);
        console.log('package id', packageIdParam)
        if (serviceTypeParam === 'plumpMarketingAgency') {
            initPurchasePlumpMarketingPackageData({
                "packageId": packageIdParam,
                "sessionId": null,
                "generatePaymentIntent": false,
                "canUpgragePlan": isUpgradableParam === "true" ? true : false
            }, (resIntData) => {
                setShowCustomerLoader(true);
                console.log('purchasePlumpMarketingPackage res:', resIntData);
                setIntentData(resIntData.data.purchasePlumpMarketingPackage.data);
                stripeIntentData = resIntData;
                console.log('intent data', resIntData.data.purchasePlumpMarketingPackage.data);
                console.log('order id', resIntData.data.purchasePlumpMarketingPackage.data.packageReferenceId);
                initUpdatePlumpMarketingPackagePaymentData({
                    packageId: packageIdParam,
                    orderId: parseInt(resIntData.data.purchasePlumpMarketingPackage.data.packageReferenceId),
                    intentId: intentData.intentId.substr(0, intentData.intentId.indexOf('_secret')),
                    token: paymentMethodId,
                    stripeSavedCardId: null,
                    planUpgrade: isUpgradableParam === "true" ? true : false,
                    usrName: userPaymentInfoData.userInfoName,
                    usrEmail: userPaymentInfoData.userInfoEmail,
                    usrPhoneNo: userPaymentInfoData.userInfoContactNumber,
                    facesUserClinicName: userPaymentInfoData.userInfoClinicName,
                    instagramHandle: userPaymentInfoData.userInfoInstagramHandlerName,
                },
                    (successResponse) => {
                        initTrackUserActivityForServicesData({ serviceId: 1, activityId: 3, packageId: packageIdParam });
                        if (!isMobile) {
                            toast.success(successResponse.data.updatePlumpMarketingPackagePayment.message);
                            navigate(socialMediaMarketingPagePattern());
                        }
                        else {
                            updatePaymentSuccess(true);
                        }
                        console.log('payment intent call back success!!!');
                        console.log('successResponse:', successResponse);
                    },
                    (errResponse) => {
                        console.log('errResponse:', errResponse);
                        paymentIntentErrorCallback();
                    });

            }, (err) => {
                console.log('error:', err);
            });
        } else {
            initPurchaseAccountancyPackageData({
                "packageId": packageIdParam,
                "sessionId": null,
                "generatePaymentIntent": false,
                "canUpgragePlan": isUpgradableParam === "true" ? true : false
            }, (resIntData) => {
                setShowCustomerLoader(true);
                console.log('purchaseAccountancyPackage res:', resIntData);
                setIntentData(resIntData.data.purchaseAccountancyPackage.data);
                stripeIntentData = resIntData;
                console.log('intent data', resIntData.data.purchaseAccountancyPackage.data);
                console.log('order id', resIntData.data.purchaseAccountancyPackage.data.packageReferenceId);
                initUpdateAccountancyPackagePaymentData({
                    packageId: packageIdParam,
                    orderId: parseInt(resIntData.data.purchaseAccountancyPackage.data.packageReferenceId),
                    intentId: intentData.intentId.substr(0, intentData.intentId.indexOf('_secret')),
                    token: paymentMethodId,
                    stripeSavedCardId: null,
                    planUpgrade: isUpgradableParam === "true" ? true : false
                },
                    (successResponse) => {
                        initTrackUserActivityForServicesData({ serviceId: 2, activityId: 3, packageId: packageIdParam });
                        if (!isMobile) {
                            toast.success(successResponse.data.updateAccountancyPackagePayment.message);
                            navigate(socialMediaMarketingPagePattern());
                        }
                        else {
                            updatePaymentSuccess(true);
                        }
                        console.log('payment intent call back success!!!');
                        console.log('successResponse:', successResponse);
                    },
                    (errResponse) => {
                        console.log('errResponse:', errResponse);
                        paymentIntentErrorCallback();
                    });

            }, (err) => {
                console.log('error:', err);
            });
        }
    }
    const paymentIntentErrorCallback = () => {
        setShowCustomerLoader(false);
        toast.error('Unable to complete payment process!')
        console.log('payment intent call back error');
    }
    const paymentIntentSetProcess = () => {
        console.log('payment intent set process');
        if (submitStripeBtn) {
            submitStripeBtn.disabled = true;
        }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const packageIdParam = parseInt(urlParams.get('packageId'));
    const serviceTypeParam = urlParams.get('serviceType').toString();
    const isUpgradableParam = urlParams.get('isUpgradable').toString();

    const backUrl = serviceTypeParam === 'plumpMarketingAgency' ? frontendURL + '/social-media-marketing' : frontendURL + '/accountancy-service';

    console.log('packageIdParam', packageIdParam);
    console.log('serviceTypeParam', serviceTypeParam);
    console.log('isUpgradableParam', isUpgradableParam);

    const { purchasePlumpMarketingPackageQueryData, initPurchasePlumpMarketingPackageData } = PurchasePlumMarketingAction();

    const { purchaseAccountancyPackageQueryData, initPurchaseAccountancyPackageData } = PurchaseAccountancyServicesAction();
    useEffect(() => {
        if (serviceTypeParam === 'plumpMarketingAgency') {
            const generateIntnt = async () => {
                console.log('hi');
                if (packageIdParam && typeof packageIdParam == 'number') {
                    initPurchasePlumpMarketingPackageData({
                        "packageId": packageIdParam,
                        "sessionId": null,
                        "generatePaymentIntent": true,
                        "canUpgragePlan": isUpgradableParam === "true" ? true : false
                    }, (resIntData) => {
                        console.log('purchasePlumpMarketingPackage res:', resIntData);
                        setIntentData(resIntData.data.purchasePlumpMarketingPackage.data);
                        setUserPaymentInfo(resIntData.data.purchasePlumpMarketingPackage.data.userInfo);
                        console.log('userPaymentInfo:', userPaymentInfo, resIntData.data.purchasePlumpMarketingPackage.data.userInfo);
                        console.log('userPaymentInfo:', userPaymentInfo);
                        setUserPaymentInfoData({
                            userInfoName: userPaymentInfo?.user_name,
                            userInfoEmail: userPaymentInfo?.user_email,
                            userInfoContactNumber: userPaymentInfo?.user_phone_number,
                            userInfoClinicName: userPaymentInfo?.user_clinic_name,
                            userInfoInstagramHandlerName: "",
                        })
                        stripeIntentData = resIntData;
                        console.log(intentData);
                    }, (err) => {
                        console.log('error:', err);
                    });
                }
            }
            generateIntnt();
        } else {
            const generateIntnt = async () => {
                console.log('hi');
                if (packageIdParam && typeof packageIdParam == 'number') {
                    initPurchaseAccountancyPackageData({
                        "packageId": packageIdParam,
                        "sessionId": null,
                        "generatePaymentIntent": true,
                        "canUpgragePlan": isUpgradableParam === "true" ? true : false
                    }, (resIntData) => {
                        console.log('purchaseAccountancyPackage res:', resIntData);
                        setIntentData(resIntData.data.purchaseAccountancyPackage.data);
                        stripeIntentData = resIntData;
                        console.log("intentData===",intentData);
                    }, (err) => {
                        console.log('error:', err);
                    });
                }
            }
            generateIntnt();
        }

    }, []);

    const [savedCards, setSavedCardsData] = useState(null);

    const { getSavedCardsOfUserQueryData, initGetSavedCardsOfUserData } = GetSavedCardsOfUserAction();

    const [savedCardId, setSavedCardId] = useState(null);

    useEffect(() => {
        initGetSavedCardsOfUserData({},
            (res) => {
                setSavedCardsData(res.data.getSavedCardsOfUser.data);
                console.log('saved cards of user:', res.data.getSavedCardsOfUser.data);
            },
            (err) => {
                console.log(err.message);
            });
    }, []);

    const performStripeWithCardId = (cardId) => {
        console.log('abcd 1', typeof isFormDataIsValid(), isFormDataIsValid());
        
        setSavedCardId(cardId);
        if (!isFormDataIsValid()) {
            // setIsStripeFormFiledDisabled(true);
            return false;
        }

        console.log('savedCardId:', savedCardId);
        // initPurchasePlumpMarketingPackageData({
        //     "packageId": packageIdParam,
        //     "sessionId": null,
        //     "generatePaymentIntent": true
        // }, (resIntData) => {
        //     console.log('purchasePlumpMarketingPackage res:', resIntData);
        //     setIntentData(resIntData.data.purchasePlumpMarketingPackage.data);

        //     initPurchasePlumpMarketingPackageData({
        //         "packageId": packageIdParam,
        //         "sessionId": null,
        //         "generatePaymentIntent": false
        //     }, (resIntData2) => {
        //         console.log('resIntData2:', resIntData2);
        //         initUpdatePlumpMarketingPackagePaymentData({
        //             packageId: packageIdParam,
        //             orderId: parseInt(resIntData2.data.purchasePlumpMarketingPackage.data.packageReferenceId),
        //             intentId: intentData.intentId.substr(0, intentData.intentId.indexOf('_secret')),
        //             token: strToken,
        //             stripeSavedCardId: cardId
        //         }, (successResponse) => {
        //             initTrackUserActivityForServicesData({ serviceId: 1, activityId: 3, packageId: packageIdParam });
        //             toast.success(successResponse.data.updatePlumpMarketingPackagePayment.message);
        //             console.log('payment intent call back success!!!');
        //             console.log('successResponse:', successResponse);
        //         }, (errResponse) => {
        //             console.log('errResponse:', errResponse);
        //             paymentIntentErrorCallback();
        //         });
        //     }, (err) => {
        //         console.log('error1:', err);
        //     });

        //     stripeIntentData = resIntData;
        //     console.log(intentData);
        // }, (err) => {
        //     console.log('error:', err);
        // });
    }

    const callbackForSavedCardPayment = () => {
        if (serviceTypeParam === 'plumpMarketingAgency') {
            initPurchasePlumpMarketingPackageData({
                "packageId": packageIdParam,
                "sessionId": null,
                "generatePaymentIntent": false,
                "canUpgragePlan": isUpgradableParam === "true" ? true : false
            }, (resIntData2) => {
                setShowCustomerLoader(true);
                console.log('resIntData2:', resIntData2);
                setUserPaymentInfo(resIntData2.data.purchasePlumpMarketingPackage.data.userInfo);
                console.log('userPaymentInfo:', userPaymentInfo, resIntData2.data.purchasePlumpMarketingPackage.data.userInfo, intentData);
                console.log('userPaymentInfo:', userPaymentInfo);
                setUserPaymentInfoData({
                    userInfoName: userPaymentInfo?.user_name,
                    userInfoEmail: userPaymentInfo?.user_email,
                    userInfoContactNumber: userPaymentInfo?.user_phone_number,
                    userInfoClinicName: userPaymentInfo?.user_clinic_name,
                    userInfoInstagramHandlerName: "",
                })
                initUpdatePlumpMarketingPackagePaymentData({
                    packageId: packageIdParam,
                    orderId: parseInt(resIntData2.data.purchasePlumpMarketingPackage.data.packageReferenceId),
                    intentId: intentData.intentId.substr(0, intentData.intentId.indexOf('_secret')),
                    token: savedCardId,
                    stripeSavedCardId: savedCardId,
                    planUpgrade: isUpgradableParam === "true" ? true : false,
                    usrName: userPaymentInfoData.userInfoName,
                    usrEmail: userPaymentInfoData.userInfoEmail,
                    usrPhoneNo: userPaymentInfoData.userInfoContactNumber,
                    facesUserClinicName: userPaymentInfoData.userInfoClinicName,
                    instagramHandle: userPaymentInfoData.userInfoInstagramHandlerName,
                }, (successResponse) => {
                    initTrackUserActivityForServicesData({ serviceId: 1, activityId: 3, packageId: packageIdParam });
                    if (!isMobile) {
                        toast.success(successResponse.data.updatePlumpMarketingPackagePayment.message);
                        navigate(socialMediaMarketingPagePattern());
                    }
                    else {
                        updatePaymentSuccess(true);
                    }
                    console.log('payment intent call back success!!!');
                    console.log('successResponse:', successResponse);
                }, (errResponse) => {
                    console.log('errResponse:', errResponse);
                    paymentIntentErrorCallback();
                });

                // stripeIntentData = resIntData;
                console.log(intentData);
            }, (err) => {
                console.log('error:', err);
            });
        } else {
            initPurchaseAccountancyPackageData({
                "packageId": packageIdParam,
                "sessionId": null,
                "generatePaymentIntent": true,
                "canUpgragePlan": isUpgradableParam === "true" ? true : false
            }, (resIntData) => {

                setShowCustomerLoader(true);
                console.log('purchaseAccountancyPackage res:', resIntData);
                setIntentData(resIntData.data.purchaseAccountancyPackage.data);

                initPurchaseAccountancyPackageData({
                    "packageId": packageIdParam,
                    "sessionId": null,
                    "generatePaymentIntent": false,
                    "canUpgragePlan": isUpgradableParam === "true" ? true : false
                }, (resIntData2) => {
                    console.log('resIntData2:', resIntData2);
                    initUpdateAccountancyPackagePaymentData({
                        packageId: packageIdParam,
                        orderId: parseInt(resIntData2.data.purchaseAccountancyPackage.data.packageReferenceId),
                        intentId: intentData.intentId.substr(0, intentData.intentId.indexOf('_secret')),
                        token: savedCardId,
                        stripeSavedCardId: savedCardId,
                        planUpgrade: isUpgradableParam === "true" ? true : false
                    }, (successResponse) => {
                        initTrackUserActivityForServicesData({ serviceId: 2, activityId: 3, packageId: packageIdParam });
                        if (!isMobile) {
                            toast.success(successResponse.data.updateAccountancyPackagePayment.message);
                            navigate(socialMediaMarketingPagePattern());
                        }
                        else {
                            updatePaymentSuccess(true);
                        }
                        console.log('payment intent call back success!!!');
                        console.log('successResponse:', successResponse);
                    }, (errResponse) => {
                        console.log('errResponse:', errResponse);
                        paymentIntentErrorCallback();
                    });
                }, (err) => {
                    console.log('error1:', err);
                });

                // stripeIntentData = resIntData;
                console.log("intentData", intentData);
            }, (err) => {
                console.log('error:', err);
            });
        }
    }

    const [isCardPaymentType, setIsCardPaymentType] = useState(false);

    const changePaymentType = (event) => {
        setIsCardPaymentType(false);
        handleRadioChange(null);
        console.log('isCardPaymentType:', isCardPaymentType);
    }

    const [selectedCardValue, setSelectedCardValue] = useState(null);
    const [enteredCardValue, setenteredCardValue] = useState(null);
    const [clearStripeForm, setClearStripeForm] = useState(false);

    const handleRadioChange = (event) => {
        console.log("clicked===============>")
        // isFormDataIsValidForCard()
        const newValue = event ? event.target.value : null;
        console.log(newValue, 'newValue');
        if (newValue === null) {
            setSelectedCardValue(null);
            setClearStripeForm(false);
        } else {
            setClearStripeForm(true);
            setSelectedCardValue(newValue);
            setTimeout(() => {
                setClearStripeForm(false);
                const submitStripeBtn = document.getElementById('socialMediaMarketingMakePaymentBtn');
                if (submitStripeBtn) {
                    if (userPaymentInfoData.userInfoName !== '' && userPaymentInfoData.userInfoEmail !== '' && userPaymentInfoData.userInfoContactNumber !== '' && userPaymentInfoData.userInfoClinicName !== '' && userPaymentInfoData.userInfoInstagramHandlerName !== '') {
                        setIsUserInfoValid(true);
                        submitStripeBtn.disabled = false;
                    } else {
                        setIsUserInfoValid(false);
                        submitStripeBtn.disabled = true;
                    }
                }
            }, 100);
        }
        // newValue === null ? setSelectedCardValue(null) : setSelectedCardValue(newValue);
    };

    useEffect(() => {
        console.log('SelectedCardValue:', selectedCardValue);
    });

    const [validationErrors, setValidationErrors] = useState({
        userInfoName: "",
        userInfoEmail: "",
        userInfoContactNumber: "",
        userInfoClinicName: "",
        userInfoInstagramHandlerName: "",
    });

    function isValidEmail(email) {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailPattern.test(email);
    }

    const [userPaymentInfoData, setUserPaymentInfoData] = useState({
        userInfoName: "",
        userInfoEmail: "",
        userInfoContactNumber: "",
        userInfoClinicName: "",
        userInfoInstagramHandlerName: "",
    })

    function isPlusAndNumbersOnly(str) {
        // return /^\+?\d+$/.test(str);
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (str.match(phoneno)) {
            return true;
        }
        return false;
    }

    const validateInput = (name, value) => {
        switch (name) {
            case "userInfoName":
                if (!value) {
                    setValidationErrors((prev) => ({
                        ...prev,
                        userInfoName: "User name is required",
                    }));
                    // setIsCardPaymentType(false);
                    
                } else {
                    setValidationErrors((prev) => ({
                        ...prev,
                        userInfoName: "",
                    }));
                }
                break;
            case "userInfoEmail":
                if (!value || !isValidEmail(value)) {
                    setValidationErrors((prev) => ({
                        ...prev,
                        userInfoEmail: "Valid email is required",
                    }));
                    // setIsCardPaymentType(false);
                } else {
                    setValidationErrors((prev) => ({
                        ...prev,
                        userInfoEmail: "",
                    }));
                }
                break;
            case "userInfoContactNumber":
                // const pattern = /^[0-9+]+$/;
                const pattern = /^\+?\d+$/;
                console.log('ttt', isPlusAndNumbersOnly(value.toString()), typeof isPlusAndNumbersOnly(value.toString()), value)
                if (!value) {
                    setValidationErrors((prev) => ({
                        ...prev,
                        userInfoContactNumber: "Contact number is required",
                    }));
                    // setIsCardPaymentType(false);
                    // setSelectedCardValue(null);
                } else if (value.length < 7 || value.length > 13) {
                    setValidationErrors((prev) => ({
                        ...prev,
                        userInfoContactNumber: "Please Enter valid contact number",
                    }));
                    // setIsCardPaymentType(false);
                    // setSelectedCardValue(null);
                } else {
                    setValidationErrors((prev) => ({
                        ...prev,
                        userInfoContactNumber: "",
                    }));
                }
                break;
            case "userInfoClinicName":
                if (!value) {
                    setValidationErrors((prev) => ({
                        ...prev,
                        userInfoClinicName: "Clinic name is required",
                    }));
                    // setIsCardPaymentType(false);
                    // setSelectedCardValue(null);
                } else {
                    setValidationErrors((prev) => ({
                        ...prev,
                        userInfoClinicName: "",
                    }));
                }
                break;
            case "userInfoInstagramHandlerName":
                if (!value) {
                    setValidationErrors((prev) => ({
                        ...prev,
                        userInfoInstagramHandlerName: "Instagram handler name is required",
                    }));
                    // setIsCardPaymentType(false);
                    // setSelectedCardValue(null);
                } else {
                    setValidationErrors((prev) => ({
                        ...prev,
                        userInfoInstagramHandlerName: "",
                    }));
                }
                break;
            default:
                break;
        }
    };

    const isFormDataIsValidForCard = () => {
        let isSuccess = true;
        for (const key in userPaymentInfoData) {
            if (Object.hasOwnProperty.call(userPaymentInfoData, key)) {
                const element = userPaymentInfoData[key];
                switch (key) {
                    case "userInfoName":
                        if (!element) {
                            setValidationErrors((prev) => ({
                                ...prev,
                                userInfoName: "User name is required",
                            }));
                            isSuccess = false;
                            // setIsCardPaymentType(false);
                        } else {
                            setValidationErrors((prev) => ({
                                ...prev,
                                userInfoName: "",
                            }));
                        }
                        break;
                    case "userInfoEmail":
                        if (!element || !isValidEmail(element)) {
                            setValidationErrors((prev) => ({
                                ...prev,
                                userInfoEmail: "Valid email is required",
                            }));
                            isSuccess = false;
                            // setIsCardPaymentType(false);
                        } else {
                            setValidationErrors((prev) => ({
                                ...prev,
                                userInfoEmail: "",
                            }));
                        }
                        break;
                    case "userInfoContactNumber":
                        // const pattern = /^[0-9+]+$/;
                        const pattern = /^\+?\d+$/;
                        // console.log('ttt', isPlusAndNumbersOnly(element.toString()), typeof isPlusAndNumbersOnly(element.toString()), element)
                        // if (!element) {
                        //     return false;
                        // } else if (element.length < 7 || element.length > 13) {
                        //     return false;
                        // }

                        if (!element) {
                            setValidationErrors((prev) => ({
                                ...prev,
                                userInfoContactNumber: "Contact number is required",
                            }));
                            // setIsCardPaymentType(false);
                            // setSelectedCardValue(null);
                            isSuccess = false;
                        } else if (element.length < 7 || element.length > 13) {
                            setValidationErrors((prev) => ({
                                ...prev,
                                userInfoContactNumber: "Please Enter valid contact number",
                            }));
                            // setIsCardPaymentType(false);
                            // setSelectedCardValue(null);
                            isSuccess = false;
                        } else {
                            setValidationErrors((prev) => ({
                                ...prev,
                                userInfoContactNumber: "",
                            }));
                        }
                        break;
                    case "userInfoClinicName":
                        // if (!element) {
                        //     return false;
                        // }
                        if (!element) {
                            setValidationErrors((prev) => ({
                                ...prev,
                                userInfoClinicName: "Clinic name is required",
                            }));
                            // setIsCardPaymentType(false);
                            // setSelectedCardValue(null);
                            isSuccess = false;
                        } else {
                            setValidationErrors((prev) => ({
                                ...prev,
                                userInfoClinicName: "",
                            }));
                        }
                        break;
                    case "userInfoInstagramHandlerName":
                        // if (!element) {
                        //     return false;
                        // }

                        if (!element) {
                            setValidationErrors((prev) => ({
                                ...prev,
                                userInfoInstagramHandlerName: "Instagram handler name is required",
                            }));
                            // setIsCardPaymentType(false);
                            // setSelectedCardValue(null);
                            isSuccess = false;
                        } else {
                            setValidationErrors((prev) => ({
                                ...prev,
                                userInfoInstagramHandlerName: "",
                            }));
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        return isSuccess;
    }


    const isFormDataIsValid = () => {
        for (const key in userPaymentInfoData) {
            if (Object.hasOwnProperty.call(userPaymentInfoData, key)) {
                const element = userPaymentInfoData[key];
                switch (key) {
                    case "userInfoName":
                        if (!element) {
                            return false;
                        }
                        break;
                    case "userInfoEmail":
                        if (!element || !isValidEmail(element)) {
                            return false;
                        }
                        break;
                    case "userInfoContactNumber":
                        // const pattern = /^[0-9+]+$/;
                        const pattern = /^\+?\d+$/;
                        console.log('ttt', isPlusAndNumbersOnly(element.toString()), typeof isPlusAndNumbersOnly(element.toString()), element)
                        if (!element) {
                            return false;
                        } else if (element.length < 7 || element.length > 13) {
                            return false;
                        }
                        break;
                    case "userInfoClinicName":
                        if (!element) {
                            return false;
                        }
                        break;
                    case "userInfoInstagramHandlerName":
                        if (!element) {
                            return false;
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        return true;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log('onchange', name, value);
        console.log('onchh', savedCardId, typeof savedCardId);
        // if (savedCardId === null) {
        //     console.log('abcd hi', savedCardId, typeof savedCardId);
        //     setSavedCardId(null);
        //     setIsStripeFormFiledDisabled(true);
        // }
        setUserPaymentInfoData((prev) => ({
            ...prev,
            [name]: value,
        }));
        // setContactDetailsData((prev) => ({
        //     ...prev,
        //     [name]: value,
        // }));
        validateInput(name, value);
    }

    const [isStripeFormFiledDisabled, setIsStripeFormFiledDisabled] = useState(true);
    const [isStripeSavedCardDisabled, setIsStripeSavedCardDisabled] = useState(false);

    useEffect(() => {
        setUserPaymentInfoData({
            userInfoName: userPaymentInfo?.user_name,
            userInfoEmail: userPaymentInfo?.user_email,
            userInfoContactNumber: userPaymentInfo?.user_phone_number,
            userInfoClinicName: userPaymentInfo?.user_clinic_name,
            userInfoInstagramHandlerName: "",
        })
    }, [userPaymentInfo]);

    useEffect(() => {
        // if (submitStripeBtn) {
        /*
        if (userPaymentInfoData.userInfoName && userPaymentInfoData.userInfoEmail && userPaymentInfoData.userInfoContactNumber && userPaymentInfoData.userInfoClinicName && userPaymentInfoData.userInfoInstagramHandlerName) {
            setIsUserInfoValid(true);
            // submitStripeBtn.disabled = false;
            setIsStripeFormFiledDisabled(false);
            console.log('isStripeFormFiledDisabled', isStripeFormFiledDisabled);
        } else {
            setIsUserInfoValid(false);
            // submitStripeBtn.disabled = true;
            setIsStripeFormFiledDisabled(true);
            console.log('isStripeFormFiledDisabled', isStripeFormFiledDisabled);
        }
        */

        if (isFormDataIsValid()) {
            setIsStripeSavedCardDisabled(false);
            // if (savedCardId === null) {
            //     setIsStripeFormFiledDisabled(true);
            //     setSelectedCardValue(null);
            // } else {
            setIsStripeFormFiledDisabled(false);
            // }
            console.log('isStripeFormFiledDisabled0', isStripeFormFiledDisabled, userPaymentInfoData);
        } else {
            setIsStripeFormFiledDisabled(true);
            setIsStripeSavedCardDisabled(true);
            console.log('isStripeFormFiledDisabled1', isStripeFormFiledDisabled, userPaymentInfoData);
        }
        // }
    });

    const [isUserInfoValid, setIsUserInfoValid] = useState(false);

    return (
        <>
            {/* <BusinessHeader /> */}
            {/* <div className="container-fluid">
                <div className="dashboard-parent-container row"> */}
            <div className={"editprofile justify-content-end checkOutPageDesign"} style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    theme="dark"
                    onClose={() => {
                        // setTimeout(() => {
                            console.log('toast container hidden');
                            // window.location.href = '/services/social-media-marketing';
                        // }, 3000);
                    }}
                />
                {/* <a href={backUrl} className='cutom-btn btnredsm' style={{ maxWidth: '45px', minWidth: '45px', width: '45px', padding: '10px', margin: '10px' }}><i className='fa fa-arrow-left'></i></a> */}
                {
                    !isMobile ? <div className="shopinnertit"><a data-tooltip-id="go-to-back" data-tooltip-content="Go Back" data-placement="bottom" className="btn btnredsm" href={backUrl} data-original-title="Go back" style={{ minWidth: '37px', padding: '12px 15px 12px 10px', margin: '12px' }}><i className="fa fa-arrow-left"></i></a></div> : null
                }
                {
                    showPaymentSuccess ?
                        <h2 className='text-center' style={{ marginBottom: '25px' }}>Thank you!</h2>
                        : null
                }
                {
                    showPaymentSuccess ?
                        <h4 className='text-center' style={{ fontSize: '16px' }}>Your payment has been successful. You can go back to app by clicking on back button.</h4>
                        : null
                }
                {
                    !showPaymentSuccess ? <>
                        <h2 className='text-center' style={{ marginBottom: '25px' }}>Checkout</h2>
                        {
                            serviceTypeParam === 'plumpMarketingAgency' &&
                            <div className='row form-group mx-0'>
                                <TextInput
                                    label="User Name"
                                    placeholder="User Name"
                                    value={userPaymentInfoData?.userInfoName}
                                    onChange={(e) => handleChange(e)}
                                    name="userInfoName"
                                />
                                {validationErrors.userInfoName && <span className="text-danger">{validationErrors.userInfoName}</span>}

                                <TextInput
                                    label="Email address"
                                    placeholder="name@gmail.com"
                                    value={userPaymentInfoData?.userInfoEmail}
                                    onChange={(e) => handleChange(e)}
                                    name="userInfoEmail"
                                />
                                {validationErrors.userInfoEmail && <span className="text-danger">{validationErrors.userInfoEmail}</span>}

                                <TextInput
                                    label="Contact Number"
                                    placeholder="+44 123456789"
                                    value={userPaymentInfoData?.userInfoContactNumber}
                                    onChange={(e) => handleChange(e)}
                                    name="userInfoContactNumber"
                                />
                                {validationErrors.userInfoContactNumber && <span className="text-danger">{validationErrors.userInfoContactNumber}</span>}

                                <TextInput
                                    label="Clinic Name"
                                    placeholder="Joe Clinic"
                                    value={userPaymentInfoData?.userInfoClinicName}
                                    onChange={(e) => handleChange(e)}
                                    name="userInfoClinicName"
                                />
                                {validationErrors.userInfoClinicName && <span className="text-danger">{validationErrors.userInfoClinicName}</span>}

                                <TextInput
                                    label="Instagram Handler Name"
                                    placeholder="@instagram"
                                    onChange={(e) => handleChange(e)}
                                    name="userInfoInstagramHandlerName"
                                />
                                {validationErrors.userInfoInstagramHandlerName && <span className="text-danger">{validationErrors.userInfoInstagramHandlerName}</span>}

                                {/* <input type="text" className='col-4 form-control' name="userName" id="userName" value={userPaymentInfo?.user_name} placeholder='Please Enter Name'/>
                                        <input type="email" className='col-4 form-control' name="userEmail" id="userEmail" value={userPaymentInfo?.user_email} placeholder='Please Enter Email'/>
                                        <input type="text" className='col-4 form-control' name="userContactNumber" id="userContactNumber" value={userPaymentInfo?.user_phone_number} placeholder='Please Enter Contact Number'/>
                                        <input type="text" className='col-4 form-control' name="userClinicName" id="userClinicName" placeholder='Please Enter Clinic Name'/>
                                        <input type="text" className='col-4 form-control' name="userInstagramHandlerName" id="userInstagramHandlerName" placeholder='Please Enter Instagram Handler Name'/> */}
                            </div>
                        }
                        {savedCards &&
                            <h4 className='text-center' style={{ fontSize: '16px' }}>Below are the list of cards you have previously saved.</h4>
                        }
                        {
                            savedCards && savedCards.length ? <div className='selectedSaveText'>
                                <p style={{ textAlign: 'center' }}><i>Select a saved card</i></p>
                            </div> : null
                        }
                        {savedCards && <StripeSavedCardsComponent cards={savedCards.cards} performStripeWithCardId={performStripeWithCardId} changeCardPaymentType={setIsCardPaymentType} handleRadioChange={handleRadioChange} selectedValue={selectedCardValue} isStripeFormFiledDisabled={isStripeFormFiledDisabled} isStripeSavedCardDisabled={isStripeSavedCardDisabled} />}
                        <div className='selectedSaveText'>
                            <p style={{ textAlign: 'center' }}><i>Enter a new card</i></p>
                        </div>
                        {/* <p style={{textAlign: 'center'}}>Or </p> */}
                        {
                            console.log("showCustomerLoader", showCustomerLoader)
                        }
                        {(showCustomerLoader === true) &&
                            <center>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px', marginTop: '10px' }}>
                                    <div style={{ marginRight: '5px' }}>
                                        <div className="spinner-border text-primary" role="status" style={{ height: '20px', width: '20px' }}>
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <div>
                                        Processing...
                                    </div>
                                </div>
                            </center>}
                            {console.log("intentData==>>===",intentData)}
                        {!showCustomerLoader && intentData && <StripeComponent
                            key={clearStripeForm ? 'clearForm' : 'normalForm'}
                            clientSecret={intentData.intentId}
                            successCallback={paymentIntentSuccessCallback}
                            failureCallback={paymentIntentErrorCallback}
                            setProcess={paymentIntentSetProcess}
                            paymentType='card'
                            changePaymentType={changePaymentType}
                            isCardPaymentType={isCardPaymentType}
                            savedCardId={savedCardId}
                            callbackForSavedCardPayment={callbackForSavedCardPayment}
                            useSetupIntentFlow={true}
                            isStripeFormFiledDisabled={isStripeFormFiledDisabled}
                            validationErrors ={validationErrors}
                            isFormDataIsValidForCard={isFormDataIsValidForCard}
                        />}
                    </>
                        : null
                }
                <br />
                {/* <div className="text-center">
                            <button type="submit" className='btn btnredsm justify-content-center' style={{maxWidth: '190px', minWidth: '190px'}} form="paymentForm">Make Payment</button>
                        </div> */}
            </div>
            {/* </div>
            </div> */}
            {/* <BusinessFooter /> */}
        </>
    );
}

export default StripePaymentPage;