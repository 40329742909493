import React, { useState } from 'react'
import './batch-number-main.scss'
import bellIcon from '../../../../images/icons/notification_icon.svg'
import serviceIcon from '../../../../images/icons/basket_icon.svg'
import starIcon from '../../../../images/star.svg'
import UserNameIcon from '../../../../images/Actions.svg'
import downArrow from '../../../../images/header_arrow.svg'
import homeIcon from '../../../../images/headers/house.svg'
import calenderIcon from '../../../../images/headers/calendar.svg'
import shopIcon from '../../../../images/headers/bag.svg'
import newIcon from '../../../../images/headers/plus-stroke.svg'
import manageIcon from '../../../../images/headers/dots.svg'
import leftIcon from '../../../../images/new-appointment/appointmenticon.svg'
import rightArrow from '../../../../images/new-appointment/right_arrow.svg'
import BatchNumberList from './BatchNumberList'



const ProductDetails = () => {
    const [currentStep, setCurrentStep] = useState(1);

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    }
    return (
        <>
            {currentStep === 1 && (
                <div className='product-detail-main container'>
                    <div className='product-detail-header'>
                        <div className='product-detail-header-left-content'>
                            <h1>Faces</h1>
                        </div>
                        <div className='product-detail-header-right-content'>
                            <img src={bellIcon} alt='bell icon' />
                            <img src={serviceIcon} alt='bell icon' />
                            <span className='d-flex rating-cls'>
                                <img src={starIcon} width={25} height={25} alt='star icon' />
                                <p>4.71</p>
                            </span>
                            <img src={UserNameIcon} alt='User Name Icon' />
                            <span>Company name</span>
                            <img src={downArrow} width={35} height={35} alt='arrow Icon' />
                        </div>
                    </div>
                    <div className='product-detail-section'>
                        <div className='product-detail-section-left-content'>
                            <div className='d-flex mb-4'>
                                <img src={homeIcon} />
                                <p>Home</p>
                            </div>
                            <div className='d-flex mb-4'>
                                <img src={calenderIcon} />
                                <p>Calendar</p>
                            </div>
                            <div className='d-flex mb-4'>
                                <img src={shopIcon} />
                                <p>Shop</p>
                            </div>
                            <div className='d-flex mb-4'>
                                <img src={newIcon} />
                                <p>New</p>
                            </div>
                            <div className='d-flex mb-4'>
                                <img src={manageIcon} />
                                <p>Manage</p>
                            </div>
                        </div>
                        <div className='product-detail-section-right-content'>
                            <h1>Product details</h1>
                            <p>Add your service's product info to help guard against insurance claims.</p>
                            <div className='appointment-detail-list mt-5'>
                                <div className='d-flex justify-content-between align-items-center my-4 cursor-pointer' onClick={handleNextStep}>
                                    <span className='d-flex'>
                                        <img src={leftIcon} />
                                        <span className='list-name'>
                                            <p className='fw-bold'>Service name</p>
                                            <p>Add information</p>
                                        </span>
                                    </span>
                                    <div>
                                        <img src={rightArrow} />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-4 cursor-pointer' onClick={handleNextStep}>
                                    <span className='d-flex'>
                                        <img src={leftIcon} />
                                        <span className='list-name'>
                                            <p className='fw-bold'>Service name</p>
                                            <p>Add information</p>
                                        </span>
                                    </span>
                                    <div>
                                        <img src={rightArrow} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {currentStep === 2 && (
                <BatchNumberList currentStep={currentStep} setCurrentStep={setCurrentStep} />
            )}
        </>
    )
}

export default ProductDetails
