import {
    GET_SUPPORT_CHAT_DATA,
    GET_SUPPORT_CHAT_DATA_SUCCESS, 
    GET_THREAD_CHAT_DATA,
    GET_THREAD_CHAT_DATA_SUCCESS, 
    SUPPORT_CHAT_CATEGORIES_DATA_SUCCESS,
    CREATE_SUPPORT_THREADS_DATA_SUCCESS,
    SUPPORT_RECENT_ORDERS_DATA_SUCCESS,
    GET_ORDER_TRACE_DATA_SUCCESS,
    CREATE_SUPPOR_CHAT_THREADS_DATA,
    CREATE_SUPPOR_CHAT_THREADS_DATA_SUCCESS,
    SUPPORT_THREADS_ADD_FEEDBACK_DATA_SUCCESS,
    SUPPORT_THREADS_CHAT_UPDATE_DATA_SUCCESS,
    SUPPORT_THREADS_MESSAGES_MARK_DATA_SUCCESS,
    SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA_SUCCESS,
} from '../../constants/index'

const initialState = {
    supportChatDataList: [],
    supportChatDataPage: 0,

    supportChatData: null,
    supportChatMessage: null,

    threadChatData: null,
    threadChatMessage: null,

    supportCategoriesData: null,
    supportCategoriesMessage: null,

    createSupportThreadsData: null,
    createSupportThreadsMessage: null,

    supportRecentOrdersData: null,
    supportRecentOrdersMessage: null,

    getUserOrderTraceData: null,
    getUserOrderTraceMessage: null,

    createSupportChatThreadsData: null,
    createSupportChatThreadsMessage: null,

    supportThreadsAddFeedbackSuccess: null,
    supportThreadsAddFeedbackMessage: null,

    supportThreadsChatUpdateSuccess: null,
    supportThreadsChatUpdateMessage: null,

    supportThreadsMessagesMarkResData: null,
    supportThreadsMessagesMarkMessage: null,

    supportChatDataSkeletonFlag: false,
    supportChatMessageSkeletonFlag: false,
    supportNewChatSkeletonFlag: false,

    supportTotalUnreadCountData: null,
}
export const GetSupportChatDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUPPORT_CHAT_DATA:
            return {
                ...state,
                supportChatDataSkeletonFlag: true
            }
        case GET_SUPPORT_CHAT_DATA_SUCCESS:
            let temp = (state?.supportChatDataList || []).concat(
                (action.payload.data?.elements || []).map(review => ({
                    ...review,
                    showReply: false
                }))
            );
            let oldSupportChatListPage = state.supportChatDataPage + 1;
            return {
                ...state,
                // supportChatData: state.supportChatData == null ? action.payload?.data : state.supportChatData,
                supportChatData: temp,
                supportChatDataTotalCount: action.payload.data.total,
                supportChatDataCount: temp.length,
                supportChatMessage: action.payload.message,
                supportChatPage: oldSupportChatListPage,
                supportChatDataSkeletonFlag: false
            }
        case GET_THREAD_CHAT_DATA:
            return {
                ...state,
                supportChatMessageSkeletonFlag: true
            }
        case GET_THREAD_CHAT_DATA_SUCCESS:
            return {
                ...state,
                threadChatData: action.payload.data,
                threadChatMessage: action.payload.message,
                supportChatMessageSkeletonFlag: false,
            }
        case SUPPORT_CHAT_CATEGORIES_DATA_SUCCESS:
            return {
                ...state,
                supportCategoriesData: action.payload.data,
                supportCategoriesMessage: action.payload.message,
            }
        case CREATE_SUPPORT_THREADS_DATA_SUCCESS:
            return {
                ...state,
                createSupportThreadsData: action.payload.data,
                createSupportThreadsMessage: action.payload.message,
            }
        case SUPPORT_RECENT_ORDERS_DATA_SUCCESS:
            return {
                ...state,
                supportRecentOrdersData: action.payload.data,
                supportRecentOrdersMessage: action.payload.message,
            }
        case GET_ORDER_TRACE_DATA_SUCCESS:
            return {
                ...state,
                getUserOrderTraceData: action.payload.data,
                getUserOrderTraceMessage: action.payload.message,
            }
        case CREATE_SUPPOR_CHAT_THREADS_DATA:
            return {
                ...state,
                supportNewChatSkeletonFlag: true
            }
        case CREATE_SUPPOR_CHAT_THREADS_DATA_SUCCESS:
            return {
                ...state,
                createSupportChatThreadsData: action.payload.data,
                createSupportChatThreadsMessage: action.payload.message,
                supportNewChatSkeletonFlag: false
            }
        case SUPPORT_THREADS_ADD_FEEDBACK_DATA_SUCCESS:
            return {
                ...state,
                supportThreadsAddFeedbackSuccess: action.payload.success,
                supportThreadsAddFeedbackMessage: action.payload.message,
            }
        case SUPPORT_THREADS_CHAT_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                supportThreadsChatUpdateSuccess: action.payload.success,
                supportThreadsChatUpdateMessage: action.payload.message,
            }
        case SUPPORT_THREADS_MESSAGES_MARK_DATA_SUCCESS:
            return {
                ...state,
                supportThreadsMessagesMarkResData: action.payload.data,
                supportThreadsMessagesMarkMessage: action.payload.message,
            }
        case SUPPORT_CHAT_TOTAL_UNREAD_COUNT_DATA_SUCCESS:
            return {
                ...state,
                supportTotalUnreadCountData: action.payload.data.totalCount,
            }
        default:
            return state
    }
}