import React, { useState, useEffect, useRef } from 'react'
import close from "../../../images/icons/Back.svg"
import "./appointment-listing.scss"
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { businessDashboardRoutePattern, getAppointmentListRoute, getAppointmentRoute, getAppointmentRoutePattern, getBookinginvoiceRoute, getClientProfileFormsRoute, getEditAppointmentRoute, getEditBlockOutTimeRoute } from "../../../Routes";
import getAppointmentList, { clearAppointmentStatus, getAppointmentDetails, getAppointmentListSuccess } from '../../redux/actions/dashboard/appointments';
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonLoader from '../../common/Skeleton/SkeletonLoader';
import calenderIcon from "../../../images/solar_calendar-linear.svg"
import { GetAppointmentListAction } from '../../redux/actions/dashboard/appointmentListGql';
import { APPOINTMENT_LIST_LOADER_COMPLETE, APPOINTMENT_LIST_LOADER_START, CHANGE_APPOINTMENT_LIST_PAGE_COUNT, CHANGE_LOADED_APPOINTMENT_COUNT, CLEAR_APPOINTMENT_LIST } from '../../redux/constants';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { ReactComponent as CloseIcon } from '../../../images/close_icon.svg'
import { ReactComponent as RightArrow } from '../../../images/icons/chevron-left.svg'
import { ReactComponent as EditIcon } from '../../../images/icons/appointment-list/edit.svg'
import { ReactComponent as ResendIcon } from '../../../images/icons/appointment-list/resend.svg'
import { ReactComponent as DownloadIcon } from '../../../images/icons/appointment-list/download.svg'
import { ReactComponent as ViewIcon } from '../../../images/icons/appointment-list/view.svg'
import { ReactComponent as DeleteIcon } from '../../../images/icons/appointment-list/delete.svg'
import { ResendBookingDetailsMutation } from '../../redux/actions/sendReminder/ResendBookingDetails';
import showToast from '../../common/CustomToast/CustomToast';
import MenuIcon from '../../../images/icons/verticalDots.svg'
import ConsentActionPopup from '../../consent-action-popup';
import { capitalizeFirstLetter } from '../../../utils/initials';
import AppointmentListCard from '../../common/appointment-list-card/AppointmentListCard';
import CustomModal from '../../modal/CustomModal';
import AppointmentViewPopup from '../../common/appointment-view-popup/AppointmentViewPopup';
import StepButton from '../../common/StepButton';
import { frontendURL } from '../../../config';
import { DeleteBlockOutTimeMutation } from '../../redux/actions/block-out-time/DeleteBlockOutTime';
import { categorizeByMonth } from '../../../utils/general';
import useWindowDimension from '../../../utils/useWindowDimensions';
import { CLEAR_PRESCRIBER_LIST } from '../../redux/constants/consentFormThankyou/getPrescriberListConst'
import { CLEAR_PRESCRIPTIONS_LIST } from '../../redux/constants/getPrescriptionsListConst'
import  ClientFormsViewPopup from "../../common/client-forms-view-popup/ClientFormsViewPopup"
import { GetConsentFormPdfUrlQuery } from '../../redux/actions/downloadConsentForm/downloadConsentForm'
import { GetMedicalFormPdfUrlQuery } from '../../redux/actions/downloadMedicalForm/DownloadMedicalForm'
import { ResendMedicalFormAction } from '../../redux/actions/sendReminder/ResendMedicalForm'
import { PrescriberList } from '../../Client/prescriber-list/PrescriberList'
import OffCanvasModal from '../../modal/OffCanvasModal/OffCanvasModal'
import { SendConsentFormOrMedicalFormToPrescriberAction } from '../../redux/actions/send-consent-form-or-medicalform-to-prescriber/SendConsentFormOrMedicalFormToPrescriber'
import { PrescriptionList } from '../../Client/prescription-list/PrescriptionList'
import { UpdatePrescriptionAttachedFormAction } from '../../redux/actions/update-prescription-attached-form/UpdatePrescriptionAttachedForm'

const AppointmentListing = () => {

  const [active, setActive] = useState('upcoming');
  const [clicked, setClicked] = useState(0);
  const moment = require('moment')
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dontCallAPI, setDontCallApi] = useState(false);
  const [appointmentSlug, setAppointmentSlug] = useState();
  const [appointmentId, setAppointmentId] = useState(null)
  const [isRootClose, setIsRootClose] = useState(false)
  const [showConsentPopup, setShowConsentPopup] = useState()
  const [confirmationPopupShow, setConfirmationPopupShow] = useState(false);
  const [isFilled, setIsFilled] = useState(null)
  const [clickedDate, setClickedDate] = useState("")
  const [clientId, setClientId] = useState(null)
  const [clinicId, setClinicId] = useState(null)
  const [deleteDataByAppointmentId, setDeleteDataByAppointmentId] = useState();
  const [compliancePopupShow, setCompliancePopupShow] = useState(false);
  const [isPaymentPopUpOpen,setIsPaymentPopUpOpen]=useState(false)
  const [paymentPopUpPrice,setPaymentPopUpPrice]=useState(null)
  const [paymentPopClientName,setPaymentPopClientName]=useState(null)
  const [isFormsMenuOptionsOpen, setIsFormsMenuOptionsOpen] = useState(false)
  const [prescriberId, setPrescriberId] = useState(null)
  const [isPrescriberModalOpen, setIsPrescriberModalOpen] = useState(false)
  const [isNoPharmacyRegisterModalOpen, setIsNoPharmacyRegisterModalOpen] = useState(false)
  const [appointmentIdData, setAppointmentIdData] = useState(null);
  const [isPrescriptionModalOpen, setIsPrescriptionModalOpen] = useState(false)
  const [tempShowSelectedPrescriber, setTempShowSelectedPrescriber] = useState(null)
  const [consentActive,setConsentActive]=useState("Consent")
  const location = useLocation()

  //Resend Booking Details
  const { initiateResendBookingDetailsData,resendBookingDetailsMutationData} = ResendBookingDetailsMutation()
  const { initiateDeleteBlockOutTime, deleteBlockTimeMutationData } = DeleteBlockOutTimeMutation()
  const { initConsentFormPdfUrl } = GetConsentFormPdfUrlQuery()
  const { initMedicalFormPdfUrl } = GetMedicalFormPdfUrlQuery()
  const { initResendMedicalFormData, resendMedicalFormQueryData } = ResendMedicalFormAction()
  const { initSendConsentFormOrMedicalFormToPrescriberData, sendConsentFormOrMedicalFormToPrescriberQueryData } = SendConsentFormOrMedicalFormToPrescriberAction()
  const { initUpdatePrescriptionAttachedFormData, updatePrescriptionAttachedFormQueryData } = UpdatePrescriptionAttachedFormAction()

  const clientData = useSelector(state => state.clientConsentFormListReducer?.clientData)

  const redirectUrl = `${frontendURL}/appointment-list`
  const encodedRedirectUrl = encodeURIComponent(redirectUrl);

  const popOverRef = useRef(null)

  const menuData = [
    {
      title: "Edit / reschedule",
      icon: <EditIcon width={40} />,
    },
    {
      title: "Resend booking details",
      icon: <ResendIcon width={40} />,
    },
    {
      title: "Download invoice",
      icon: <DownloadIcon width={40} />,

    },
    {
      title: "View consent",
      icon: <ViewIcon width={40} />,
    },
    {
      title: "Delete",
      icon: <DeleteIcon width={40} />,
    }
  ]
  const handleResendDetails = (formId) => {
    initiateResendBookingDetailsData({
      "appointmentId": formId,
      "consentFormRecordId": null
    }, (res) => {
      if (res.success) {
        // toast.success(res.message)
        showToast("success", res?.message, "", false)
        setIsAppointmentMenuOpen(false)
      } else {

        showToast("error", res?.message, "", false)
      }
    }, (err) => {
      showToast("error", err, "", false)
    })
  }

  // const handleMenuClick = (title) => {

  //   switch (title) {
  //     case "Edit / reschedule":
  //       console.log('Edit / reschedule')
  //       // navigate(NewAppointmentRoutePattern + "?bookingId=" + appointmentId)
  //       navigate(getEditAppointmentRoute(appointmentId), { state: { fromDashboardAppointmentList: true, clickdate: clickedDate, active: active } })
  //       break;
  //     case "Resend booking details":
  //       console.log('Resend booking details')
  //       handleResendDetails(appointmentId)
  //       break;
  //     case "Download invoice":
  //       console.log('Download invoice', appointmentId)
  //       navigate(getBookinginvoiceRoute(), { state: { entityId: appointmentId, selectedDate: clickedDate, fromDashboardAppointmentList: true, active: active } })
  //       break;
  //     case "View consent":
  //       document.body.click()
  //       setIsRootClose(false)
  //       // setOpenClientForm(true)

  //       navigate(getClientProfileFormsRoute(clientId), { state: { appointmentId: appointmentId, fromDashboardAppointmentList: true, clickdate: clickedDate, active: active } })

  //       break;
  //     case "Delete":

  //       setShowConsentPopup(true);
  //       setConfirmationPopupShow(true);
  //       console.log('Delete')
  //       break;
  //     default:
  //       console.log('Default')
  //       break;
  //   }
  // }

  const handlePaymentResend=()=>{
    handleResendDetails(appointmentId);
    setIsPaymentPopUpOpen(false)
      }

  const handleMenuClick = (title, isBlockTimeOut = false) => {

    switch (title) {
      // case "View or edit event":
      //   if (moment().isAfter(moment(blockoutEndDate, "YYYY-MM-DD"))) {
      //     showToast("error", "you can't edit this event", "", "")
      //   }
      //   else {
      //     navigate(getEditBlockOutTimeRoute(blockOutId, blockOutDateId), { state: { fromBlockoutCalendarList: true } })
      //   }
      //   break;

      // case "Delete":
      //   console.log('Delete')

      //   if (moment().isAfter(moment(blockoutEndDate, "YYYY-MM-DD"))) {
      //     showToast("error", "you can't delete this event", "", "")
      //   }
      //   else {
      //     setIsDelete(true)
      //     setIsAppointmentMenuOpen(false)
      //   }
      //   break;
      case "Payment":
        if(paymentTagData?.paymentTag === "Unpaid"){
          setIsPaymentPopUpOpen(true)
        }
        // handleResendDetails(appointmentId);
        break;

      case "Edit or reschedule":
        console.log('Edit / reschedule')
        navigate(getEditAppointmentRoute(appointmentId), { state: { fromDashboardAppointmentList: true, clickdate: clickedDate, active: active } })
        break;

      case "Appointment overview":
        navigate(getAppointmentRoute(appointmentId), { state: { appointmentListing: true } })
        break;

      case "Resend booking & consent":
        console.log('Resend booking details')
        handleResendDetails(appointmentId)
        break;

      case "Download invoice":
        console.log('Download invoice', appointmentId)
        navigate(getBookinginvoiceRoute(), { state: { entityId: appointmentId, selectedDate: clickedDate, fromDashboardAppointmentList: true, active: active } })
        break;

      case "Consent form":
        setIsFormsMenuOptionsOpen(true)
        console.log('View consent', clientId)
        document.body.click()
        setIsRootClose(false)
        // window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
        break;

      case "Cancel appointment":
        setShowConsentPopup(true);
        setConfirmationPopupShow(true);
        setIsAppointmentMenuOpen(false)
        console.log('Delete')
        break;

      default:
        console.log('Default')
        break;
    }

  }
  const handleCloseClick = () => {
    document.body.click()
    //popOverRef.onHide()
  }

  const popoverClick = (
    <Popover id="popover-trigger-click" className='appointment-option-main p-3' >
      <div className='popover-title-section'>
        <h1>Manage</h1>
        <CloseIcon className='close-icon-animation' onClick={handleCloseClick} />
      </div>
      {

        menuData.filter((items) => {
          if (isFilled) {
            // If isFilled is true, hide "Resend booking details"
            return items.title !== 'Resend booking details';
          }
          else {
            // If isFilled is false, hide "View consent"
            return items.title !== 'View consent';
          }
        }).map((items, index) => (
          <div className='appointment-list-option-container' key={index} onClick={() => { handleMenuClick(items?.title); setIsRootClose(true); document.body.click() }}>
            <div className='appointment-list-left-popover-content'>
              {items.icon}
              <p>{items.title}</p>
            </div>
            <div className='appointment-list-right-popover-content'>
              <RightArrow />
            </div>
          </div>
        ))
      }
    </Popover>

  );

  // let appointmentList = [
  //     {
  //         clientName: "Client One",
  //         location: "location One",
  //         paymentStatus: "Due",
  //         dueAmount: 40,
  //         time: "10:00 - 11:00",
  //         treatments: ["treatment one", "treatment two", "treatment three"]
  //     },
  //     {
  //         clientName: "Client Two",
  //         location: "location Two",
  //         paymentStatus: "Uncomfirmed",
  //         time: "10:00 - 11:00",
  //         treatments: ["treatment one", "treatment two"]
  //     },
  //     {
  //         clientName: "Client Three",
  //         location: "location Three",
  //         paymentStatus: "Fully paid",
  //         dueAmount: 40,
  //         time: "10:00 - 11:00",
  //         treatments: ["treatment one"]
  //     },
  //     {
  //         clientName: "Client Four",
  //         location: "location Four",
  //         paymentStatus: "Fully paid",
  //         time: "10:00 - 11:00",
  //         treatments: ["treatment one"]
  //     },
  //     {
  //         clientName: "Client Five",
  //         location: "location Five",
  //         paymentStatus: "Due",
  //         dueAmount: 40,
  //         time: "10:00 - 11:00",
  //         treatments: ["treatment one"]
  //     },

  // ]
  const appointmentDetails = useSelector((state) => state.appointmentReducer.appointmentDetails);
  const appointmentList = useSelector((state) => state.appointmentReducer.appointmentListData)
  const appointmentDetailsStatus = useSelector((state) => state.appointmentReducer.appointmentDetailsStatus)

  const appointmentListPage = useSelector((state) => state.appointmentReducer.appointmentListPage);
  const totalAppointmentCount = useSelector((state) => state.appointmentReducer.totalAppointmentCount);
  const loadedAppointmentCount = useSelector((state) => state.appointmentReducer.loadedAppointmentCount);
  const nextAppointmentDate = useSelector((state) => state.appointmentReducer.nextAppointmentDate);

  const appointmentListLoader = useSelector((state) => state.appointmentReducer.appointmentListLoader);

  const [appointmentPage, setAppointmentPage] = useState(1);
  const [consentFormRecordId, setConsentFormRecordId] = useState(null)
  const [type, setType] = useState(null)
  const [isAppointmentMenuOpen, setIsAppointmentMenuOpen] = useState(false)
  const [blockOutId, setBlockOutId] = useState(null)
  const [blockOutDateId, setBlockOutDateId] = useState(null)
  const [blockoutEndDate, SetBlockoutEndDate] = useState(null)
  const [isDelete, setIsDelete] = useState(false)
  const [paymentTagData, setPaymentTagData] = useState(null)
  const [consentFormTagData, setConsentFormTagData] = useState(null)
 

  const initiateAppointmentListCall = (refreshList = false) => {
    if (appointmentListLoader) {
      return;
    }
    // let newPage = appointmentListPage + 1;
    setAppointmentPage(appointmentPage + 1);
    let newPage = appointmentPage + 1;
    if (refreshList) {
      newPage = 1;
      setAppointmentPage(1);
    } else if (totalAppointmentCount <= loadedAppointmentCount) {
      return;
    }
    console.log('appointmentListPage', appointmentPage, newPage);
    // dispatch(getAppointmentListSuccess({
    //   'appointmentListDayType': active,
    //   'limit': 5,
    //   'page': newPage
    // }))
    // here change
    dispatch({
      type: APPOINTMENT_LIST_LOADER_START
    })
    initGetAppointmentListData({
      'appointmentListDayType': active,
      'limit': 10,
      'page': newPage
    });
    console.log('dispatched', active, newPage);
    console.log('appointmentListDataTmp', appointmentListData);
    console.log('appointmentListLoader', appointmentListLoader);
  };

  const { getAppointmentListQueryData, initGetAppointmentListData } = GetAppointmentListAction();
  const { appointmentListData } = useSelector((state) => state.appointmentReducer);
   
  useEffect(() => {
    dispatch({
      type: CLEAR_APPOINTMENT_LIST
    })
    dispatch({
      type: APPOINTMENT_LIST_LOADER_START
    })
    initGetAppointmentListData({
      'appointmentListDayType': active,
      'limit': 10,
      'page': 1
    });
    // dispatch({
    //   type: APPOINTMENT_LIST_LOADER_COMPLETE
    // })
  }, [active])

  useEffect(() => {
    if (location?.state?.fromDashboardAppointmentList) {
      navigate(".", { replace: true });
      setActive(location?.state?.active)
    }
    (active && !appointmentList?.length) && initiateAppointmentListCall(true);
  }, [active])

  useEffect(() => {
    clicked && initiateAppointmentListCall(true);
  }, [clicked])


  useEffect(() => {

    if (appointmentSlug) {
      // localStorage.setItem("appointmentList", JSON.stringify(appointmentList));
      dispatch(clearAppointmentStatus());
      navigate(getAppointmentRoute(appointmentSlug), { state: "list" });
    }
  }, [appointmentSlug])



  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, [])
  const getAppointmentList = () => {
    dispatch({
      type: CLEAR_APPOINTMENT_LIST
    })
    // if (clickedDate !== null) {
    //     initGetAppointmentListByDayType({
    //         startDate: moment(clickedDate).format("YYYY-MM-DD"),
    //         endDate: moment(clickedDate).format("YYYY-MM-DD"),
    //     })
    // }
    initiateAppointmentListCall(true)
  }


  const onAppointmentClickHandler = (appointmentData) => {
    setAppointmentId(parseInt(appointmentData?.booking_slug));
    setClinicId(appointmentData?.cld_rc_id);
    setClientId(appointmentData?.clientId);
    setIsFilled(appointmentData?.isFilled);
    setDeleteDataByAppointmentId(appointmentData.cld_rc_id)
    setClickedDate(appointmentData?.calender_date)
    setConsentFormRecordId(appointmentData?.consentFormRecordId)
    setType("Appointment")
    setPaymentTagData({
      paymentTag: appointmentData?.paymentTag,
      paymentTagColor: appointmentData?.paymentTagColor,
      isPaymentDone: appointmentData?.isPaymentDone,
    })
    setConsentFormTagData({
      consentFormTag: appointmentData?.consentFormTag,
      consentFormTagColor: appointmentData?.consentFormTagColor,
      consentFormCount: appointmentData?.consentFormCount
    })
    setIsAppointmentMenuOpen(true)
    setPaymentPopUpPrice(appointmentData?.price);
    setPaymentPopClientName(appointmentData?.client_name)
  }

  const onBlockOutCardClickHandler = (blockoutData) => {
    setBlockOutDateId(blockoutData?.blockOutDateId);
    setBlockOutId(blockoutData?.blockOutId);
    SetBlockoutEndDate(blockoutData?.blockoutEndDate)
    setType("Blockout")
    setIsAppointmentMenuOpen(true)
  }

  const downloadForm = (formId) => {
    initConsentFormPdfUrl({
        consentFormRecordId: formId
    }, (res) => {
        console.log(res?.data?.getConsentFormRecordPdfUrl, "download pdf")
        if (res?.data?.getConsentFormRecordPdfUrl?.success) {
            window.open(res?.data?.getConsentFormRecordPdfUrl?.data?.consentFormRecordPdfUrl, "_blank")
            setIsFormsMenuOptionsOpen(false)
        } else {
            showToast("error", res.message, "", false)
        }
    }, (err) => {
        showToast("error", err, "", false)

    })
}

const downloadMedicalForm = (formId) => {
  initMedicalFormPdfUrl({
      clientId: clientId
  }, (res) => {
      if (res?.data?.getMedicalFormPdfUrl?.success) {
          window.open(res?.data?.getMedicalFormPdfUrl?.data?.medicalFormRecordPdfUrl, "_blank")
          setIsFormsMenuOptionsOpen(false)
      } else {
          showToast("error", res.message, "", false)
      }
  }, (err) => {
      showToast("error", err, "", false)

  })
}
const handleResendMedicalForm = () => {
initResendMedicalFormData({
    clientId: clientId
}, (res) => {
    if (res.success) {
        showToast("success", res.message, "", false)
        setIsFormsMenuOptionsOpen(false)
    } else {
        showToast("error", res.message, "", false)
    }
}, (err) => {
    showToast("error", err, "", false)
})
}
const handleSendReminder = (formId) => {
initiateResendBookingDetailsData({
    "appointmentId": null,
    "consentFormRecordId": formId
}, (res) => {
    if (res.success) {
        showToast("success", res.message, "", false)
        setIsFormsMenuOptionsOpen(false)
    } else {
        showToast("error", res.message, "", false)
    }
}, (err) => {
    showToast("error", err, "", false)
})
}

const sendClickHandler = (selectedPrescriberId) => {
// let data;
// if (active === "Consent") {
//     data = {
//         consentFormId: consentFormRecordId,
//         prescriberId: parseInt(selectedPrescriberId)
//     }
// }
// else {
//     data = {
//         clientId: clientId,
//         prescriberId: parseInt(selectedPrescriberId)
//     }
// }
// handleSendConsentFormorMedicalFormToPrescriber(data)
setIsPrescriberModalOpen(false)
setPrescriberId(selectedPrescriberId)
setIsPrescriptionModalOpen(true)
}

const sendtoPrescriberHandler = (dataTobePassed) => {

  let data;

  if (consentActive === "Consent") {
      data = {
          prescriptionId: dataTobePassed?.prescriptionId,
          clientId: clientId,
          consentFormId: consentFormRecordId,
          attachMedicalForm: dataTobePassed?.attachMedicalForm
      }
  }
  else {
      data = {
          prescriptionId: dataTobePassed?.prescriptionId,
          clientId: clientId,
          consentFormId: 0,
          attachMedicalForm: true,
      }
  }
  initUpdatePrescriptionAttachedFormData(
      data
      , (res) => {
          if (res.success) {
              showToast("success", res.message, "", false)
              setIsPrescriptionModalOpen(false)
              setTempShowSelectedPrescriber(null)
          } else {
              showToast("error", res.message, "", false)
              setTempShowSelectedPrescriber(null)
          }
      }, (err) => {
          showToast("error", err, "", false)
      })
}

  const handleConsentFormClick=(title)=>{
    switch (title) {
        case "Download PDF":
            if (consentFormRecordId !== null) {
                downloadForm(consentFormRecordId)
            }
            break;

        case "Send to client":
          handleSendReminder(consentFormRecordId)
            break;


        case "Re-send to client":
          handleSendReminder(consentFormRecordId)
            break;

        case "Send to prescriber":
            dispatch({
                type: CLEAR_PRESCRIBER_LIST
            })
            dispatch({
                type: CLEAR_PRESCRIPTIONS_LIST
            })
            setPrescriberId(null);
            setIsFormsMenuOptionsOpen(false)
            setIsAppointmentMenuOpen(false)
            if (getAppointmentListQueryData?.data?.getAppointmentList?.data?.vendorIsPharmacy) {
                // initiatePrescriberListCall(true)
                setIsPrescriberModalOpen(true)
            }
            else {
                setIsNoPharmacyRegisterModalOpen(true)
            }
            break;

        case "Edit consent form":
            window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
            break;

        case "Complete in person":
            window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/edit/" + consentFormRecordId + `?redirect-url=${encodedRedirectUrl}`
            break;

        

        case "Repeat form":
          window.location.href = process.env.REACT_APP_WEB_BASE_URL + "/filled-consent-form/duplicate/" + consentFormRecordId + `?documents=true`
            // navigate(rebookAppointmentRoute(appointmentIdData?.appointmentId, clientId), { state: { isFromClientForm: true } })
            break;

        case "Download PDF":
          downloadMedicalForm()
          break;

      case "Send to client":
          handleResendMedicalForm()
          break;

      case "Re-send to client":
          handleResendMedicalForm()
          break;

      case "Send to prescriber":
          dispatch({
              type: CLEAR_PRESCRIBER_LIST
          })
          dispatch({
              type: CLEAR_PRESCRIPTIONS_LIST
          })
          setPrescriberId(null);
          setIsFormsMenuOptionsOpen(false)
          setIsAppointmentMenuOpen(false)
          if (getAppointmentListQueryData?.data?.getAppointmentList?.data?.vendorIsPharmacy) {
              // initiatePrescriberListCall(true)
              setIsPrescriberModalOpen(true)
          }
          else {
              setIsNoPharmacyRegisterModalOpen(true)
          }
          break;

      case "Edit medical form":
          window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`
          break;

      case "Complete in person":
          window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/client-directory/edit/${clientId}?active-tab=medical_form`
          break;

      default:
          console.log('Default')
          break;
    }
}

  //DELETE BLOCK OUT TIME
  const handleDeleteBlockOut = () => {
    initiateDeleteBlockOutTime({
      blockOutDateId: blockOutDateId,
      blockOutId: blockOutId
    }, (res) => {
      if (res?.success) {
        setIsDelete(false)
        dispatch({
          type: CLEAR_APPOINTMENT_LIST
        })
        initiateAppointmentListCall(true);
      }
    }, (err) => {
      console.log(err)
    })
  }
  
  const appointmentListDataNew = categorizeByMonth(appointmentList, "calender_date", "DD-MM-YYYY")
  console.log("appointmentListDataNew", totalAppointmentCount, loadedAppointmentCount)

  const dimensions = useWindowDimension();
  const [headerNavbar, setHeaderNavbar] = useState(0);
  const [navigationHeight, setNavigationHeight] = useState(0);

  useEffect(() => {
      setHeaderNavbar(
          document.getElementsByClassName('icon-close-button')[0]?.offsetHeight
      );

      setNavigationHeight(
          document.getElementsByClassName('navigation')[0]?.offsetHeight
      );

      window.addEventListener('resize', handleResize);
  }, []);
  const handleResize = () => {
      setHeaderNavbar(
          document.getElementsByClassName('icon-close-button')[0]?.offsetHeight
      );

      setNavigationHeight(
          document.getElementsByClassName('navigation')[0]?.offsetHeight
      );

  };


  return (
    <div className='appointment-list-main-container CustomeUpcomingappointment container-fluid'>
      <div className='list-header text-end icon-close-button'>
        <img src={close} alt="" onClick={() => { dispatch({ type: CHANGE_LOADED_APPOINTMENT_COUNT }); navigate(businessDashboardRoutePattern) }} />
      </div>
      <div className='list-content'>
        <div className='navigation'>
          <h1 className='list-title'>Appointments</h1>
          <span className={(active === "upcoming" ? "active me-2" : "not-active me-2")} onClick={() => { setClicked(3); setActive("upcoming"); dispatch({ type: CHANGE_LOADED_APPOINTMENT_COUNT }) }}>Upcoming</span>
          <span className={(active === "today" ? "active me-2" : "not-active me-2")} onClick={() => { setClicked(1); setActive("today"); dispatch({ type: CHANGE_LOADED_APPOINTMENT_COUNT }) }}>Today</span>
          <span className={(active === "tomorrow" ? "active me-2" : "not-active me-2")} onClick={() => { setClicked(2); setActive("tomorrow"); dispatch({ type: CHANGE_LOADED_APPOINTMENT_COUNT }) }}>Tomorrow</span>
        </div>
        <div style={{ width: "100%", height: dimensions.height - headerNavbar - navigationHeight - 20 + 'px' , overflowY: "scroll" }} id="scrollable-div-list">
          <InfiniteScroll
            dataLength={loadedAppointmentCount}
            next={() => {
              initiateAppointmentListCall()
            }}
            hasMore={totalAppointmentCount > loadedAppointmentCount}
            // loader={<SkeletonLoader type="appointment" />}
            scrollableTarget="scrollable-div-list"
          >
            <>

              {
                (
                  appointmentList?.length ?
                    Object.entries(appointmentListDataNew).map(([key, items]) => {
                      return <>
                        {appointmentListDataNew[key]?.length > 0 && active === "upcoming" && <h4 className='me-auto mt-4'>{key}</h4>}
                        {items?.map((item, index) =>
                          // <div className='offset-lg-4 w-100 col-lg-4 m-auto'>
                          //   <div className='appointment-card mb-3' onClick={() => {
                          //     setAppointmentSlug(item?.booking_slug);
                          //     // dispatch(getAppointmentDetails({ "booking-id": item?.cld_rc_id })) 
                          //   }}>
                          //     <div className='payment-tag'>
                          //       {
                          //         item?.deposit_status?.length ?
                          //           <span className={"span1 text-capitalize AppoimentTag"} style={{ backgroundColor: item?.deposit_status_color }} >{item?.deposit_status}</span>

                          //           :
                          //           <span className={"span-empty text-capitalize AppoimentTag"}>Empty tag</span>
                          //       }
                          //     </div>
                          //     <p className='mx-auto  fw-bold'>
                          //       {
                          //         item?.client_name?.split(' ')[0].charAt(0)?.toUpperCase() + item?.client_name?.split(' ')[0]?.substring(1) + ' ' + (item?.client_name?.replace(/\s\s+/g, ' ')?.split(' ')[1] ? (item?.client_name?.replace(/\s\s+/g, ' ')?.split(' ')[1].charAt(0)?.toUpperCase() + item?.client_name?.replace(/\s\s+/g, ' ')?.split(' ')[1]?.substring(1)) : '')
                          //         + " " + (item?.location_name ? "@" : "") + " " +
                          //         item?.location_name?.split(' ')[0].charAt(0)?.toUpperCase() + item?.location_name?.split(' ')[0]?.substring(1) + ' ' + (item?.location_name?.replace(/\s\s+/g, ' ')?.split(' ')[1] ? (item?.location_name?.replace(/\s\s+/g, ' ')?.split(' ')[1].charAt(0)?.toUpperCase() + item?.location_name?.replace(/\s\s+/g, ' ')?.split(' ')[1]?.substring(1)) : '')
                          //       }</p>
                          //     {
                          //       active === "upcoming" ? <p className='mx-auto'>{moment(item?.calender_date, 'DD-MM-YYYY').format('DD-MM-YYYY') + ", " + moment(item?.cld_rc_start, 'YYYY-MM-DD HH:mm:ss').format("HH:mm") + " - " + moment(item?.cld_rc_end, 'YYYY-MM-DD HH:mm:ss').format("HH:mm")}</p> : <p className='mx-auto'>{active?.charAt(0)?.toUpperCase() + active?.substring(1) + ", " + moment(item?.cld_rc_start, 'YYYY-MM-DD HH:mm:ss').format("HH:mm") + " - " + moment(item?.cld_rc_end, 'YYYY-MM-DD HH:mm:ss').format("HH:mm")}</p>
                          //     }
                          //     <p className='mx-auto'>
                          //       {
                          //         item?.treatment_count > 1 ? `${capitalizeFirstLetter(item.treatment_name)} + ${item.treatment_count - 1}` : capitalizeFirstLetter(item.treatment_name)
                          //       }
                          //     </p>
                          //     <div
                          //       className='menuicon-div'
                          //       onClick={(e) => {
                          //         e.stopPropagation();
                          //         // console.log("clickkk", items?.clientId)
                          //         setAppointmentId(parseInt(item?.booking_slug));
                          //         setClinicId(item?.cld_rc_id);
                          //         setClickedDate(item?.calender_date)
                          //         setClientId(item?.clientId);
                          //         setIsFilled(item?.isFilled);
                          //         setDeleteDataByAppointmentId(item.cld_rc_id)
                          //       }}>
                          //       <OverlayTrigger rootClose ref={r => (popOverRef = r)}
                          //         container={popOverRef.current} trigger="click" placement={`${appointmentListData?.length - 1 == index && appointmentListData?.length != 1 ? "top" : "bottom"}`} overlay={popoverClick}>

                          //         <img src={MenuIcon} className='CustomeappoimentBtn' />
                          //       </OverlayTrigger>
                          //     </div>

                          //     {/* <p className='mx-auto'>{item?.treatments[0]+"(+"+ (item?.treatments?.length > 1 ? item?.treatments?.length-1 : "")+")"}</p> */}
                          //   </div>
                          // </div>
                          <>
                            {
                              <>
                                <AppointmentListCard onClickCallback={onAppointmentClickHandler} data={item} type="Appointment" />
                              </>
                            }
                          </>
                        )}
                      </>
                    })
                    :
                    <>
                      {
                        appointmentListLoader ?
                          <div className=''>
                            <SkeletonLoader type="appointment" />
                            <br />
                            <SkeletonLoader type="appointment" />
                            <br />
                            <SkeletonLoader type="appointment" />
                          </div>
                          :
                          <div className='offset-lg-4 w-40 col-lg-4'>
                            <div className='no-appointment text-center'>
                              <img src={calenderIcon} alt="" />
                              {nextAppointmentDate ? <p className='mt-2'>Next appointment on {nextAppointmentDate}</p> : <p>No appointments {active === "upcoming" ? "upcoming" : active}</p>}
                            </div>
                          </div>
                      }
                    </>
                )
              }
            </>
          </InfiniteScroll>

          {
            showConsentPopup ? <ConsentActionPopup confirmationPopupShow={confirmationPopupShow} height={"280px"} setConfirmationPopupShow={setConfirmationPopupShow} setShowConsentPopup={setShowConsentPopup} setCompliancePopupShow={setCompliancePopupShow} compliancePopupShow={compliancePopupShow} elementId={deleteDataByAppointmentId} fromAppointmentListNavigation={false} clickedDate={clickedDate} getAppointmentData={getAppointmentList} /> : null
          }
        </div>
      </div>
      {/* appointment overview popup */}

      <CustomModal
        modalOpen={isAppointmentMenuOpen}
        // modalOpen={true}
        modaltitle={type == "Appointment" ? "Manage appointment" : "Blockout"}
        setModalOpen={setIsAppointmentMenuOpen}
        className="width-30 customeWidth customeModelAppointment menudata-container"
        modalBody={
          <>
            <AppointmentViewPopup handleMenuClick={handleMenuClick} isFilled={isFilled} type={type} paymentTagData={paymentTagData} consentFormTagData={consentFormTagData} />
          </>

        }
      />

 {/* payment option popup */}

 <CustomModal 
        type={"common-width"}
        className={"paymentpopup"}
        modalOpen={isPaymentPopUpOpen}
        setModalOpen={setIsPaymentPopUpOpen}
        modalBody={
          <>
            <div className='paymentPopUpWrapper'>
               <h1>Would you like to send a payment link for the value of
              
               £{paymentPopUpPrice} to { paymentPopClientName}?</h1>
            <div>
                  <div className='paymentPopBtn'> 
                    <StepButton blue={true}  label={"Yes"} onClick={()=>handlePaymentResend()} isLoading={resendBookingDetailsMutationData.loading} />
                    <StepButton gray={true} label={"No"} onClick={()=>setIsPaymentPopUpOpen(false)} />
                  </div>
               </div>
            </div>
          </>
        }
      />

     

      

      {/* DELETE BLOCK OUT MODAL */}
      <CustomModal
        type={"common-width"}
        className={"ConfirmationDeleteModal"}
        modalBody={
          <>
            <div className="delete-modal-body">
              <h1>Are you sure?</h1>
              <p>you want to delete this block out time? This action can not be reversed?</p>
              <div className="Delete-model-Btn">
                <div className="delete-button-modal">
                  <StepButton red={true} label={"Delete"} isLoading={deleteBlockTimeMutationData?.loading} onClick={handleDeleteBlockOut} />
                </div>
                {/* <div className="cancle-button-modal">
                                        <StepButton gray={true} onClick={() => setIsDelete(false)} label={"Cancel"} />
                                    </div> */}
              </div>
            </div>
          </>
        }
        modalOpen={isDelete}
        setModalOpen={setIsDelete}
        setIsOpenMobileCalendar={setIsDelete}
      />

       {/* consentForm menu option */}
       <CustomModal
             modalOpen={isFormsMenuOptionsOpen}
            // modalOpen={true}
              modaltitle={"Manage forms"}
              setModalOpen={setIsFormsMenuOptionsOpen}
              className="width-30 customeWidth customeModelAppointment menudata-container"
              modalBody={
              <>
              <ClientFormsViewPopup handleMenuClick={handleConsentFormClick} isFilled={isFilled} active={consentActive} />
              </>
              }
             />


                       <OffCanvasModal
                            isOffCanvasHeader={false}
                            isOpen={isPrescriberModalOpen}
                            placement={"end"}
                            className={"maxCanvasWidth508 SelectPrescriberListModel"}
                            onHide={() => setIsPrescriberModalOpen(false)}
                            // onHide={() => { setIsClientPeofile(false); setIsClientAppointment(false); setActive("upcoming") }}
                            body={<PrescriberList sendClickHandler={sendClickHandler} setIsPrescriberModalOpen={setIsPrescriberModalOpen} sendConsentFormOrMedicalFormToPrescriberQueryData={sendConsentFormOrMedicalFormToPrescriberQueryData} prescriberId={prescriberId} />}
                        />


                        {/* prescription list component */}
                        <OffCanvasModal
                            isOffCanvasHeader={false}
                            isOpen={isPrescriptionModalOpen}
                            placement={"end"}
                            className={"maxCanvasWidth508 SelectPrescriptionModel"}
                            onHide={() => setIsPrescriptionModalOpen(false)}
                            // onHide={() => { setIsClientPeofile(false); setIsClientAppointment(false); setActive("upcoming") }}
                            body={<PrescriptionList sendtoPrescriberHandler={sendtoPrescriberHandler} setIsPrescriberModalOpen={setIsPrescriberModalOpen} updatePrescriptionAttachedFormQueryData={updatePrescriptionAttachedFormQueryData} prescriberId={prescriberId} clientId={clientId} setIsPrescriptionModalOpen={setIsPrescriptionModalOpen} active={active} />}
                        />



                        {/* no pharmacy registration popup */}
                        <CustomModal
                            modalOpen={isNoPharmacyRegisterModalOpen}
                            setModalOpen={setIsNoPharmacyRegisterModalOpen}
                            type={"common-width"}
                            className={'customeNoPharmacyRegisterModel'}
                            modalBody={
                                <>
                                    <div className="share-booking-link-all-clients-confimration">
                                        <p>
                                            Currently you are not register with any pharmacy. Would you like to connect?
                                        </p>
                                        <div className="btnWrapper">
                                            <StepButton label={"Yes"} onClick={() => {
                                                window.location.href = frontendURL + "/prescriber-registration";
                                                setIsNoPharmacyRegisterModalOpen(false)
                                            }} blue={true} />

                                            <StepButton gray={true} label={"No"} onClick={() => {
                                                setIsNoPharmacyRegisterModalOpen(false)
                                            }} />
                                        </div>
                                    </div>
                                </>
                            }
                        />
    </div>
  )
}

export default AppointmentListing
