import { GOOGLE_REVIEWS_LIST } from "../../constants/googleReviews/googleReviews";

const initialState = {
  googleReviews: null,
};

export function goolgeReviewsreducer(state = initialState, action) {
  switch (action.type) {
    case GOOGLE_REVIEWS_LIST:
      console.log("action.payload", action);
      return {
        ...state,
        googleReviews: action.payload,
      };
    default:
      return state;
  }
}
