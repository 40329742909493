import React, { useCallback, useEffect, useRef, useState } from "react"
import "./edit-profile-component.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import FinaceHeader from "../../finaceHeader"
import CustomModal from '../../modal/CustomModal'
import SignatureCanvas from "react-signature-canvas";
import successTime from "../../../images/success-time.png";
import worningTime from "../../../images/worning-time.png";
import wrongTime from "../../../images/wrong-time.png";

import bankDetailsImgIcon from "../../../assets/finance-page/icon/cil_bank.svg";
import insuranceCertificateIcon from "../../../assets/finance-page/icon/tdesign_certificate.svg";
import trainingCertificatesIcon from "../../../assets/finance-page/icon/mingcute_certificate-line.svg";
import identityIcon from "../../../assets/finance-page/icon/Identity.svg";
import linkCardIcon from "../../../assets/finance-page/icon/icon-park-outline_bank-card-two.png";
import chevronLeft from "../../../images/chevron-left.png";
import { ReactComponent as EmptySignIcon } from "../../../images/empty_sign.svg";
import { ReactComponent as EnableSignIcon } from "../../../images/active_sign.svg";
import EraseImg from '../../../images/erase.png'
import {
    FaPlus,
    FaMinus,
    FaPen,
    FaEraser,
} from "react-icons/fa6";
import EditProfileTncContentComponent from "./tnc"
import { GetUserProfileToUpdateAction } from "../../redux/actions/profile/getUserProfileToUpdateGql"
import { PurchaseAccountancyServicesAction } from "../../redux/actions/accountancy-service/purchase-accountancy-services"
import { UpdateUserProfile } from "../../redux/actions/profile/updateUserProfileGql"
import { toast } from "react-toastify"
import { useDropzone } from "react-dropzone"
import uploadIcon from '../../../images/uploadCover.svg';
import { Button } from "react-bootstrap"
import { FiX } from "react-icons/fi"
import ImageUploader from "../../pages/profile/profileDropzone"
import { UploadTrainingCertificate } from "../../redux/actions/profile/uploadTrainingCertificateGql"
import { UploadInsurenceDocument } from "../../redux/actions/profile/uploadInsuranceCenterificateGql"
import { UploadIdentityProof } from "../../redux/actions/profile/uploadIdentityProofGql"
import { DeleteTrainingCertificate } from "../../redux/actions/profile/deleteTrainingCertificateGql"
import { DeleteInsurenceDocument } from "../../redux/actions/profile/deleteInsuranceDocumentGql"
import { DeleteIdentityProofDocumentType } from "../../redux/actions/profile/deleteIdentityProofDocumentGql"
import { GetBankDetailOfUserAction } from "../../redux/actions/profile/getUserBankDetailsGql"
import { UpdateBankDetails } from "../../redux/actions/profile/addBankDetailsGql"
import { useNavigate } from "react-router"
import Swal from "sweetalert2"
import { GenerateLinkCardPath } from "../../redux/actions/pay-later/generateLinkCardPath"
import loaderIcon from "../../../images/icons/loader/loader.gif"
import { GetStatuseOfCard } from "../../redux/actions/pay-later/getStatuseOfCard"

import CustomDropdown from "../revenue-dashboard/CustomDropdown/CustomDropdown"
import StepButton from "../../common/StepButton"
import { ReactComponent as CloseIcon } from '../../../images/close_icon.svg'
import Modal from 'react-modal'
import moment from 'moment';

import InputComponent from "../../common/InputComponent"
import TextInput from "../../common/TextInput"
import DocDropzone from "./docDropZone"
import analystImage from '../../../images/finance/analysis-1.svg'
import verifiedImg from '../../../images/finance/material-symbols_verified.svg'
import actionImg from '../../../images/finance/actions.svg'
import calander from "../../../images/calander-plus.svg";
import nextIcon from "../../../images/calander_next.svg";
import CustomCalendar from "../../../components/pages/dateTime/steps/CustomCalendar"
import FinanceInsurance from "./FinanceInsuranceSection"
import FinanceTrainingDocument from './FinanceTrainingCertificate'
import { SaveFinanceIdentityAction } from "../../redux/actions/finance-registrtion/saveFinanceIdentity"

const InsuranceDescComponent = () => {
    return (
        <p className='insurancePopupDesc'>
            Adding your insurance details will also make you a verified business user. This sets a president as the industry standard for your clients.
            <img src={verifiedImg} alt="Verified" /> <span className='facesVerifiedString'>FACES VERIFIED</span>
        </p>
    );
};

const EditProfileComponent = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        businessName: '',
        phoneNumber: '',
        emailAddress: '',
        password: '',
        confirmPassword: '',
    });
    const [bankDetailData, setBankDetailData] = useState({
        accountNumber: '',
        sortCode: '',
        signatureBase: '',
    });
    const [canvasStatus, setCanvasStatus] = useState(true);
    const [isSigned, setIsSigned] = useState(null);
    const [isSignEdit, setIsSignEdit] = useState(false);
    const [bankDetailsModel, setBankDetailsModel] = useState(false)
    const [isSignatureOpen, setIsSignatureOpen] = useState(false)
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const [signedSignatureData, setSignedSignatureData] = useState(null);
    const [tempTrimmedDataURL, setTempTrimmedDataURL] = useState(null)
    const [previewDataURL, setPreviewDataURL] = useState(null);
    const sigPadRef = useRef();
    const navigate = useNavigate();
    console.log("trimmedDataURL==>", removeBase64Prefix(trimmedDataURL));
    const clear = () => {
        console.log("clear signature", sigPadRef?.current?.isEmpty())
        sigPadRef?.current?.clear();
        setTempTrimmedDataURL(trimmedDataURL)
        setTrimmedDataURL(null);
        setPreviewDataURL("")
        setIsSigned(true)
    };
    function removeBase64Prefix(base64Data) {
        const prefix = 'data:image/png;base64,';

        // Check if the base64Data starts with the prefix
        if (base64Data?.startsWith(prefix)) {
            // Remove the prefix
            return base64Data.slice(prefix.length);
        } else {
            // If the prefix is not found, return the original string
            return base64Data;
        }
    }
    const [isTncChecked, setIsTncChecked] = useState(false);
    const [tncError, setTncError] = useState(null);
    const [isEditBankDetails, setIsEditBankDetails] = useState(false);
    const [bankDetailsFormValidationErrors, setBankDetailsFormValidationErrors] = useState({
        accountNumber: '',
        sortCode: '',
    });
    const [identityError, setIdentityError] = useState("");
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const businessNameRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const emailRef = useRef(null);
    const buildingNoRef = useRef(null);
    const streetNameRef = useRef(null);
    const cityRef = useRef(null);
    const postcodeRef = useRef(null);
    const ownerNameRef = useRef(null);
    const businessTypeRef = useRef(null);
    const companyHouseRegNoRef = useRef(null);
    const instagramNameRef = useRef(null);
    const websiteRef = useRef(null);
    const facebookRef = useRef(null);
    const insurenceExpireDateRef = useRef(null);
    const {
        updateBankDetailsQueryData,
        initUpdateBankDetailsData,
    } = UpdateBankDetails();
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setIsIgnoreBankDetailsError(false);
        if (name === 'sortCode') {
            let input = value.replace(/\D/g, '').substring(0, 6); // Remove non-digit characters and limit to 6 digits
            let formattedInput = '';

            for (let i = 0; i < input.length; i++) {
                if (i > 0 && i % 2 === 0) {
                    formattedInput += '-';
                }
                formattedInput += input[i];
            }

            // setBankDetailData({ ...bankDetails, [name]: formattedInput });
            setBankDetailData((prev) => ({
                ...prev,
                [name]: formattedInput,
            }));
        } else {
            // setBankDetailData({ ...bankDetails, [name]: value });
            setBankDetailData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
        // canBankDetailsFormSubmit();
    };
    useEffect(() => {
        console.log("signature modal in", isSignatureOpen)
        if (isSignatureOpen) {
            setTimeout(() => {
                sigPadRef?.current?.fromDataURL(trimmedDataURL)
            }, 100)
        }
    }, [isSignatureOpen]);
    useEffect(() => {
        if (trimmedDataURL == null) {
            console.log("signature useEffect", sigPadRef?.current?.fromDataURL)
        }
    }, [sigPadRef?.current])
    const [isBankDetailsFilled, setIsBankDetailsFilled] = useState(false);

    const checkoutBankValidation = (name, value) => {
        let isSuccess = true
        switch (name) {
            case "bankName":
                if (!value) {
                    setBankDetailsFormValidationErrors((prev) => ({
                        ...prev,
                        bankName: "Bank name is required",
                    }));
                    isSuccess = false;
                } else {
                    setBankDetailsFormValidationErrors((prev) => ({
                        ...prev,
                        bankName: "",
                    }));
                }
                break;

            case "accountNumber":
                if (!value) {
                    setBankDetailsFormValidationErrors((prev) => ({
                        ...prev,
                        accountNumber: "Account number is required",
                    }));
                    isSuccess = false;
                } else {
                    setBankDetailsFormValidationErrors((prev) => ({
                        ...prev,
                        accountNumber: "",
                    }));
                }
                break;
            case "sortCode":
                if (!value) {
                    setBankDetailsFormValidationErrors((prev) => ({
                        ...prev,
                        sortCode: "Sort code is required",
                    }));
                    isSuccess = false;
                } else {
                    if (value.length < 8) {
                        setBankDetailsFormValidationErrors((prev) => ({
                            ...prev,
                            sortCode: "Please enter valid sort code",
                        }));
                        isSuccess = false;
                    } else {
                        setBankDetailsFormValidationErrors((prev) => ({
                            ...prev,
                            sortCode: "",
                        }));
                    }
                }
                break;
            default:
                break;
        }
        return isSuccess
    }

    const canBankDetailsFormSubmit = () => {
        let isSuccess = true;
        for (const key in bankDetailData) {
            if (Object.hasOwnProperty.call(bankDetailData, key)) {
                const element = bankDetailData[key];
                switch (key) {
                    // case "bankName":
                    //     if (!element) {
                    //         setBankDetailsFormValidationErrors((prev) => ({
                    //             ...prev,
                    //             bankName: "Bank name is required",
                    //         }));
                    //         isSuccess = false;
                    //     } else {
                    //         setBankDetailsFormValidationErrors((prev) => ({
                    //             ...prev,
                    //             bankName: "",
                    //         }));
                    //     }
                    //     break;
                    case "accountNumber":
                        if (!element) {
                            setBankDetailsFormValidationErrors((prev) => ({
                                ...prev,
                                accountNumber: "Account number is required",
                            }));
                            isSuccess = false;
                        } else if (element.length < 8 || element.length > 11) {
                            setBankDetailsFormValidationErrors((prev) => ({
                                ...prev,
                                accountNumber: "Account number must be between 8 to 11 characters long",
                            }));
                            isSuccess = false;
                        } else {
                            setBankDetailsFormValidationErrors((prev) => ({
                                ...prev,
                                accountNumber: "",
                            }));
                        }
                        break;
                    case "sortCode":
                        if (!element) {
                            setBankDetailsFormValidationErrors((prev) => ({
                                ...prev,
                                sortCode: "Sort code is required",
                            }));
                            isSuccess = false;
                        } else {
                            console.log('element', element, bankDetailData.sortCode);
                            if (element.length < 8) {
                                setBankDetailsFormValidationErrors((prev) => ({
                                    ...prev,
                                    sortCode: "Please enter valid sort code",
                                }));
                                isSuccess = false;
                            } else {
                                setBankDetailsFormValidationErrors((prev) => ({
                                    ...prev,
                                    sortCode: "",
                                }));
                            }
                        }
                        break;
                    case "tnc":
                        if (!isTncChecked) {
                            setTncError('Please accept the terms and conditions to continue');
                            isSuccess = false;
                        } else {
                            setTncError(null);
                        }
                        break;

                    default:
                        break;
                }
            }
        }
        if (!isTncChecked) {
            setTncError('Please accept the terms and conditions to continue');
            isSuccess = false;
        } else {
            setTncError(null);
        }
        return isSuccess;
    }

    const [checkLinkCard, setCheckLinkCard] = useState(false);
    const validateBankDetailsFormInput = (name, value) => {
        switch (name) {
            case "bankName":
                if (!value) {
                    setBankDetailsFormValidationErrors((prev) => ({
                        ...prev,
                        bankName: "Bank name is required",
                    }));
                } else {
                    setBankDetailsFormValidationErrors((prev) => ({
                        ...prev,
                        bankName: "",
                    }));
                }
                break;
            case "accountNumber":
                if (!value) {
                    setBankDetailsFormValidationErrors((prev) => ({
                        ...prev,
                        accountNumber: "Account number is required",
                    }));
                } else {
                    setBankDetailsFormValidationErrors((prev) => ({
                        ...prev,
                        accountNumber: "",
                    }));
                }
                break;
            case "sortCode":
                if (!value) {
                    setBankDetailsFormValidationErrors((prev) => ({
                        ...prev,
                        sortCode: "Sort code is required",
                    }));
                } else {
                    if (value.length < 8) {
                        setBankDetailsFormValidationErrors((prev) => ({
                            ...prev,
                            sortCode: "Please enter valid sort code",
                        }));
                    } else {
                        setBankDetailsFormValidationErrors((prev) => ({
                            ...prev,
                            sortCode: "",
                        }));
                    }
                }
                break;
            default:
                break;
        }
    };

    const { initiategenerateLinkCardPath, generateLinkCardPathData } = GenerateLinkCardPath();
    const { getStatuseOfCardQueryData, initgetStatuseOfCardData } = GetStatuseOfCard()

    useEffect(() => {
        initgetStatuseOfCardData({},
            (res) => {
                console.log("res)))))0))))))))))", res);
                if (res?.data?.checkLinkCards?.data?.is_credit_card_registered === true) {
                    setCheckLinkCard(true);
                }
            },
            (err) => {
                console.log("err}}}}}}}}}}", err);
            }
        )
    }, [checkLinkCard])
    console.log("generateLinkCardPathData============", generateLinkCardPathData)
    const handleLinkCard = () => {
        initiategenerateLinkCardPath({},
            (res) => {
                if (res.success) {
                    window.open(
                        res?.data?.card_link,
                        "_blank"
                    );
                }
            },
            (err) => {
                // console.log("err>>>>>>>>>>>",err);
            }
        )
    }

    useEffect(() => {
        console.log("signature modal in", isSignatureOpen)
        if (isSignatureOpen) {
            setTimeout(() => {
                sigPadRef?.current?.fromDataURL(trimmedDataURL)
            }, 100)
        }
    }, [isSignatureOpen]);
    useEffect(() => {
        if (trimmedDataURL == null) {
            console.log("signature useEffect", sigPadRef?.current?.fromDataURL)
        }
    }, [sigPadRef?.current])

    useEffect(() => {
        if (isIgnoreBankDetailsError || isEditBankDetails) {
            return;
        }
        for (const key in bankDetailData) {
            if (Object.hasOwnProperty.call(bankDetailData, key)) {
                const element = bankDetailData[key];
                switch (key) {
                    // case "bankName":
                    //     if (!element) {
                    //         setBankDetailsFormValidationErrors((prev) => ({
                    //             ...prev,
                    //             bankName: "Bank name is required",
                    //         }));
                    //         isSuccess = false;
                    //     } else {
                    //         setBankDetailsFormValidationErrors((prev) => ({
                    //             ...prev,
                    //             bankName: "",
                    //         }));
                    //     }
                    //     break;
                    case "accountNumber":
                        if (!element) {
                            setBankDetailsFormValidationErrors((prev) => ({
                                ...prev,
                                accountNumber: "Account number is required",
                            }));
                        } else {
                            setBankDetailsFormValidationErrors((prev) => ({
                                ...prev,
                                accountNumber: "",
                            }));
                        }
                        break;
                    case "sortCode":
                        if (!element) {
                            setBankDetailsFormValidationErrors((prev) => ({
                                ...prev,
                                sortCode: "Sort code is required",
                            }));
                        } else {
                            if (element.length < 8) {
                                setBankDetailsFormValidationErrors((prev) => ({
                                    ...prev,
                                    sortCode: "Please enter valid sort code",
                                }));
                            } else {
                                setBankDetailsFormValidationErrors((prev) => ({
                                    ...prev,
                                    sortCode: "",
                                }));
                            }
                        }
                        break;
                    case "tnc":
                        break;

                    default:
                        break;
                }
            }
        }
    }, [bankDetailData])

    const handleBankDetailsSubmit = (e) => {
        e.preventDefault();
        if (!canBankDetailsFormSubmit() || isEditBankDetails) {
            return;
        }

        let reqData = {
            accountNumber: bankDetailData?.accountNumber,
            sortCode: bankDetailData?.sortCode.replace(/-/g, ''),
            signatureBase: trimmedDataURL.split("base64,")[1],
        };
        console.log('reqData', reqData);
        if (isTncChecked === true) {
            initUpdateBankDetailsData(reqData, (res) => {
                toast.success(res?.data?.updateBankDetails?.message);
                setBankDetailsModel(false);
                initGetBankDetailOfUserData({}, (res) => {
                    console.log('on form submit res', res?.data?.getBankDetailOfUser?.data);
                    let data = res?.data?.getBankDetailOfUser?.data;
                    if (data.sign) {
                        setCanvasStatus(false);
                    }
                    if (data?.accountNumber) {
                        setIsBankDetailsFilled(true);
                        setIsEditBankDetails(true);
                    }
                    setBankDetailData(data);
                    if (data?.sortCode) {
                        console.log('here!!!');
                        setBankDetailData((prev) => ({
                            ...prev,
                            sortCode: data.sortCode.substring(0, 2) + "-" + data.sortCode.substring(2, 4) + "-" + data.sortCode.substring(4)
                        }));
                    }
                    // setIsEditBankDetails(true);
                    setIsTncChecked(true);
                    console.log('data', data);
                }, (err) => {
                    console.log('on form submit err', err);
                });
            }, (err) => {
                toast.error(err?.data?.updateBankDetails?.message);
            });

        } else if (bankDetailData === null) {
            toast.error('Please accept terms and conditions');
        } else {
            toast.error('Bank details already filled');
        }
        console.log('Bank details submitted:', bankDetails);
    };
    const handleBankDetailsClick = () => {
        setBankDetailsModel(true)
    }
    const handleHide = () => {
        console.log("trimmedDataURL from hide", trimmedDataURL)
        //setIsOpen(false); 
        setPreviewDataURL(signedSignatureData)
        setIsSignatureOpen(true);
    };
    const ukPhoneNumberRegex = /^(?:(?:\+44)?( ?(?:\(?0(?:0|11)\)? ?|\(?0\)?) ?)|(?:\((?=\+?44\))(?:\+?44 ?\(?0?\)? ?))|(?:\+?44 ?))?(?:(?:(?:\(0(?:1\d{2}|9[0-9]{2})\))|(?:1\d{3}))( ?\d{3} ?\d{3})|(?:\(0[1-9]{2}\)|0[1-9]{2})( ?\d{3} ?\d{4})|(?:\(?1\d{3}\)?|1\d{3})( ?\d{4} ?\d{3}))$/;
    const handleChange = (e) => {
        const { name, value } = e.target;
        // setFormData((prevData) => ({
        //   ...prevData,
        //   [name]: value,
        // }));
        if (name === 'phoneNumber') {
            // if (!ukPhoneNumberRegex.test(value) && e.key !== 'Backspace') {
            //     console.log('here');
            //     e.preventDefault();
            // }
            let tmpVal = value;
            tmpVal = tmpVal.startsWith('+') ? '+' + tmpVal.replace(/\+/g, '') : tmpVal.replace(/\+/g, '');
            setGetUserProfileToUpdateData((prevData) => ({
                ...prevData,
                [name]: tmpVal,
            }));
            validateProfileFormInput(name, tmpVal);
        } else {
            setGetUserProfileToUpdateData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
            validateProfileFormInput(name, value);
        }
        console.log('name: value', name, value);

    };

    const {
        updateUserProfileQueryData,
        initUpdateUserProfileData,
    } = UpdateUserProfile();
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsErrorNavigationActive(true);
        // if (!validateProfileFormInput) {
        //     return false;
        // }
        if (!canProfileFormSubmit()) {
            return;
        }
        initUpdateUserProfileData(getUserProfileToUpdateData, (res) => {
            // setIsEditBankDetails(true);
            setIsTncChecked(true);
            toast.success(res?.data?.updateUserProfile?.message);
            setGetUserProfileToUpdateData((prev) => ({
                ...prev,
                password: '',
                confirmPassword: '',
            }));
            // setTimeout(() => {
            //     navigate('/onboarding-steps')
            // }, 500);
        }, (err) => {
            toast.error(err?.data?.updateUserProfile?.message);
        });
    };

    const [isTncOpen, setIsTncOpen] = useState(false);

    /* Insurance document */
    const [insuranceCertificateModal, setInsuranceCertificateModal] = useState(false);
    const handleInsuranceCertificateSubmit = (e) => {
        e.preventDefault();
        console.log('Insurance certificate submitted:');
    }
    const handleInsuranceDocumentClick = () => {
        setInsuranceCertificateModal(true);
    }

    /* Training certificates */
    const [trainignCertificateModal, setTrainignCertificateModal] = useState(false);
    const handleTrainingCertificateSubmit = (e) => {
        e.preventDefault();
        console.log('Training certificate submitted:');
    }
    const handleTrainingCertificateClick = () => {
        setTrainignCertificateModal(true);
    }

    /* Identity */
    const [identityModal, setIdentityModal] = useState(false);
    const handleIdentitySubmit = (e) => {
        e.preventDefault();
        console.log('Identity submitted:');
    }
    const handleIdentityClick = () => {
        setIdentityModal(true);
        setIdentityError("")
    }

    /* Getting Profile Data */
    const [getUserProfileToUpdateData, setGetUserProfileToUpdateData] = useState({
        'firstName': '',
        'lastName': '',
        'businessName': '',
        'phoneNumber': '',
        'email': '',
        'password': null,
        'confirmPassword': null,
        'cardLinked': '',
        'buildingNo': '',
        'streetName': '',
        'city': '',
        'postcode': '',
        'ownerName': '',
        'website': '',
        'facebookPage': '',
        'instagramName': '',
        'businessType': '',
        'companyHouseRegNo': '',
        'insurenceExpireDate': ''
    });

    // const [day, month, year] = getUserProfileToUpdateData?.insurenceExpireDate?.split('-');
    // const insurenceExpireDateParts = getUserProfileToUpdateData?.insurenceExpireDate?.split('-') || ["", "", ""];
    // const [day, month, year] = insurenceExpireDateParts.length === 3 ? insurenceExpireDateParts : ["", "", ""];


    const {
        getUserProfileToUpdateQueryData,
        initGetUserProfileToUpdateData,
    } = GetUserProfileToUpdateAction();

    useEffect(() => {
        initGetUserProfileToUpdateData();
    }, []);
    const [insuranceCertificateUrl, setInsuranceCertificateUrl] = useState([]);
    const [trainingCertificateUrl, setTrainingCertificateUrl] = useState([]);
    const [identityUrl, setIdentityUrl] = useState([]);
    useEffect(() => {
        if (getUserProfileToUpdateQueryData?.data?.getUserProfileToUpdate?.data?.user) {
            let obj = getUserProfileToUpdateQueryData.data.getUserProfileToUpdate.data.user;
            console.log('obj', obj);
            setGetUserProfileToUpdateData({
                'firstName': getUserProfileToUpdateData?.usr_fname ? getUserProfileToUpdateData?.usr_fname : obj?.usr_fname,
                'lastName':  getUserProfileToUpdateData?.usr_lname ? getUserProfileToUpdateData?.usr_lname : obj?.usr_lname,
                'businessName':  getUserProfileToUpdateData?.usr_company_name ? getUserProfileToUpdateData?.usr_company_name : obj?.usr_company_name,
                'phoneNumber':  getUserProfileToUpdateData?.usr_phone_no ? getUserProfileToUpdateData?.usr_phone_no : obj?.usr_phone_no,
                'email':  getUserProfileToUpdateData?.usr_email ? getUserProfileToUpdateData?.usr_email : obj?.usr_email,
                'cardLinked':  getUserProfileToUpdateData?.cardLinked ? getUserProfileToUpdateData?.cardLinked : obj?.cardLinked,
                'buildingNo':  getUserProfileToUpdateData?.buildingNo ? getUserProfileToUpdateData?.buildingNo : obj?.buildingNo,
                'streetName':  getUserProfileToUpdateData?.streetName ? getUserProfileToUpdateData?.streetName : obj?.streetName,
                'city':  getUserProfileToUpdateData?.city ? getUserProfileToUpdateData?.city : obj?.city,
                'postcode':  getUserProfileToUpdateData?.postcode ? getUserProfileToUpdateData?.postcode : obj?.postcode,
                'ownerName':  getUserProfileToUpdateData?.ownerName ? getUserProfileToUpdateData?.ownerName : obj?.ownerName,
                'website':  getUserProfileToUpdateData?.website ? getUserProfileToUpdateData?.website : obj?.website,
                'facebookPage':  getUserProfileToUpdateData?.facebookPage ? getUserProfileToUpdateData?.facebookPage : obj?.facebookPage,
                'instagramName':  getUserProfileToUpdateData?.instagramName ? getUserProfileToUpdateData?.instagramName : obj?.instagramName,
                'businessType':  getUserProfileToUpdateData?.businessType ? getUserProfileToUpdateData?.businessType : obj?.businessType,
                'companyHouseRegNo':  getUserProfileToUpdateData?.companyHouseRegNo ? getUserProfileToUpdateData?.companyHouseRegNo : obj?.companyHouseRegNo,

                'identitiDocId': getUserProfileToUpdateData?.identitiDocId ? getUserProfileToUpdateData?.identitiDocId : obj?.identitiDocId
            });
            setInsuranceCertificateUrl(obj?.insurance_doc_url);
            setTrainingCertificateUrl(obj?.certificate_doc_url);
            setIdentityUrl(obj?.identity_doc_url[0]);
            console.log('obj 2', getUserProfileToUpdateData);
        }
    }, [getUserProfileToUpdateQueryData?.data])

    /* Validation Errors */
    const [profileFormValidationErrors, setProfileFormValidationErrors] = useState({
        firstName: '',
        lastName: '',
        businessName: '',
        phoneNumber: '',
        email: '',
        password: '',
        confirmPassword: '',
        cardLinked: '',
        buildingNo: '',
        streetName: '',
        city: '',
        postcode: '',
        ownerName: '',
        website: '',
        facebookPage: '',
        instagramName: '',
        businessType: '',
        companyHouseRegNo: '',
        insurenceExpireDate: '',

    });
    const isValidEmail = (email) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailPattern.test(email);
    }
    const isPlusAndNumbersOnly = (str) => {
        // return /^\+?\d+$/.test(str);
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (str.match(phoneno)) {
            return true;
        }
        return false;
    }
    const [isErrorNavigationActive, setIsErrorNavigationActive] = useState(false);

    useEffect(() => {
        const firstErrorKey = Object.keys(profileFormValidationErrors).find(key => profileFormValidationErrors[key] !== '');
        if (firstErrorKey) {
            const fieldRef = {
                firstName: firstNameRef,
                lastName: lastNameRef,
                businessName: businessNameRef,
                phoneNumber: phoneNumberRef,
                email: emailRef,
                buildingNo: buildingNoRef,
                streetName: streetNameRef,
                city: cityRef,
                postcode: postcodeRef,
                ownerName: ownerNameRef,
                businessType: businessTypeRef,
                companyHouseRegNo: companyHouseRegNoRef,
                instagramName: instagramNameRef,
                website: websiteRef,
                facebookPage: facebookRef,
                insurenceExpireDate: insurenceExpireDateRef,
                // add other fields here
            }[firstErrorKey];
            if (isErrorNavigationActive && fieldRef && fieldRef.current) {
                fieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                fieldRef.current.focus();
                setIsErrorNavigationActive(false);
            }

        }
    }, [profileFormValidationErrors])

    const validateProfileFormInput = (name, value) => {
        const regex = /^[a-z","" "\-'\d]+$/i;
        // let urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
        let urlRegex =/^(https?|ftp)?(:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/i;

        switch (name) {
            case "firstName":
                if (!value) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        firstName: "This field is required.",
                    }));
                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        firstName: "",
                    }));
                }
                break;
            case "email":
                if (!isValidEmail(value)) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        email: "Please enter valid email",
                    }));
                } else if (!value) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        email: "This field is required.",
                    }));
                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        email: "",
                    }));
                }
                break;
            case "phoneNumber":
                // const pattern = /^[0-9+]+$/;
                const pattern = /^\+?\d+$/;
                // console.log('ttt', isPlusAndNumbersOnly(value.toString()), typeof isPlusAndNumbersOnly(value.toString()), value)

                let maxLength = value.startsWith('+') ? 12 : 11;
                if (!value) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        phoneNumber: "This field is required.",
                    }));
                } else if (value.length < (10 + (maxLength - 11)) || value.length > maxLength) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        phoneNumber: "Please Enter valid phone number",
                    }));
                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        phoneNumber: "",
                    }));
                }
                break;
            case "lastName":
                if (!value) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        lastName: "This field is required.",
                    }));
                } else if (value && !regex.test(value)) {
                    setProfileFormValidationErrors(prev => ({
                        ...prev,
                        lastName: "Please enter a valid value.",
                    }));
                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        lastName: "",
                    }));
                }
                break;
            case "businessName":
                if (!value) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        businessName: "This field is required.",
                    }));
                } else if (value && !regex.test(value)) {
                    setProfileFormValidationErrors(prev => ({
                        ...prev,
                        businessName: "Please enter a valid value.",
                    }));
                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        businessName: "",
                    }));
                }
                break;
            case "companyHouseRegNo":
                if (!value) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        companyHouseRegNo: "This field is required.",
                    }));
                } else if (value && !regex.test(value)) {
                    setProfileFormValidationErrors(prev => ({
                        ...prev,
                        companyHouseRegNo: "Please enter a valid value.",
                    }));
                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        companyHouseRegNo: "",
                    }));
                }
                break;
            case 'password':
                if (!value) {
                    setGetUserProfileToUpdateData((prev) => ({
                        ...prev,
                        confirmPassword: "",
                    }));
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        confirmPassword: "",
                    }));
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        password: "",
                    }));
                } else {
                    if (value.toString().length < 6) {
                        setProfileFormValidationErrors((prev) => ({
                            ...prev,
                            password: "Password should be minimum 6 characters",
                        }));
                    } else {
                        setProfileFormValidationErrors((prev) => ({
                            ...prev,
                            password: "",
                        }));
                        if (value !== getUserProfileToUpdateData?.confirmPassword) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                confirmPassword: "Password and confirm password should be same",
                            }));
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                confirmPassword: "",
                            }));
                        }
                    }
                }
                break;
            case 'confirmPassword':
                if (value !== getUserProfileToUpdateData?.password) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        confirmPassword: "Password and confirm password should be same",
                    }));
                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        confirmPassword: "",
                    }));
                }
                break;
            case "buildingNo":
                if (!value) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        buildingNo: "This field is required.",
                    }));
                } else if (value && !regex.test(value)) {
                    setProfileFormValidationErrors(prev => ({
                        ...prev,
                        buildingNo: "Please enter a valid value.",
                    }));
                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        buildingNo: "",
                    }));
                }
                break;
            case "streetName":
                if (!value) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        streetName: "This field is required.",
                    }));

                } else if (value && !regex.test(value)) {
                    setProfileFormValidationErrors(prev => ({
                        ...prev,
                        streetName: "Please enter a valid value.",
                    }));
                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        streetName: "",
                    }));
                }
                break;
            case "city":
                if (!value) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        city: "This field is required.",
                    }));

                } else if (value && !regex.test(value)) {
                    setProfileFormValidationErrors(prev => ({
                        ...prev,
                        city: "Please enter a valid value.",
                    }));
                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        city: "",
                    }));
                }
                break;
            case "postcode":
                if (!value) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        postcode: "This field is required.",
                    }));

                } else if (value && !regex.test(value)) {
                    setProfileFormValidationErrors(prev => ({
                        ...prev,
                        postcode: "Please enter a valid value.",
                    }));
                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        postcode: "",
                    }));
                }
                break;
            case "ownerName":
                const nameRegex = /^[a-z", "\-']+$/i;
                if (!value) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        ownerName: "This field is required.",
                    }));

                } else if (value && !nameRegex.test(value)) {
                    setProfileFormValidationErrors(prev => ({
                        ...prev,
                        ownerName: "Please enter a valid name.",
                    }));
                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        ownerName: "",
                    }));
                }
                break;
            case "website":

                if (value && !urlRegex.test(value)) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        website: "Please enter a valid URL.",
                    }));

                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        website: "",
                    }));
                }
                break;
            case "businessType":
                if (!value) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        businessType: "This field is required.",
                    }));

                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        businessType: "",
                    }));
                }
                break;
            case "instagramName":
                if (value && !value.includes('@')) {
                    setProfileFormValidationErrors(prev => ({
                        ...prev,
                        instagramName: "Please enter a valid username",
                    }));
                } else {
                    setProfileFormValidationErrors(prev => ({
                        ...prev,
                        instagramName: "",
                    }));
                }
                break;
            case "facebookPage":
                if (value && !urlRegex.test(value)) {
                    setProfileFormValidationErrors(prev => ({
                        ...prev,
                        facebookPage: "Please enter a valid URL.",
                    }));
                } else {
                    setProfileFormValidationErrors(prev => ({
                        ...prev,
                        facebookPage: "",
                    }));
                }
                break;
            // case "insurenceExpireDate":
            //     const expiryDate = new Date(`${value?.day}-${value?.month}-${value?.year}`);
            //     const currentDate = new Date();
            //     currentDate.setHours(0, 0, 0, 0); // Normalize current date to the start of the day for comparison
            //     const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/; // Regular expression to match the date format
            //     const dateParts = value?.match(dateRegex);

            //     console.log('dd date', expiryDate);
            //     if (!value) {
            //         setProfileFormValidationErrors((prev) => ({
            //             ...prev,
            //             insurenceExpireDate: "This field is required.",
            //         }));
            //     } else if (!dateParts) {
            //         setProfileFormValidationErrors((prev) => ({
            //             ...prev,
            //             insurenceExpireDate: "Please enter future date in Insurance Expiry, past dates are not allowed.",
            //         }));
            //     } else if (expiryDate && expiryDate < currentDate) {
            //         setProfileFormValidationErrors((prev) => ({
            //             ...prev,
            //             insurenceExpireDate: "Please enter future date in Insurance Expiry, past dates are not allowed.",
            //         }));
            //     } else {
            //         setProfileFormValidationErrors(prev => ({
            //             ...prev,
            //             insurenceExpireDate: "",
            //         }));
            //     }
            //     break;
            default:
                break;
        }
    };

    const canProfileFormSubmit = () => {
        let isSuccess = true;
        for (const key in getUserProfileToUpdateData) {
            if (Object.hasOwnProperty.call(getUserProfileToUpdateData, key)) {
                const element = getUserProfileToUpdateData[key];
                const regex = /^[a-z","" "\-'\d]+$/i;
                let urlRegex =/^(https?|ftp)?(:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/i;
                // let urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

                switch (key) {
                    case "firstName":
                        if (!element) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                firstName: "This field is required.",
                            }));
                            isSuccess = false;
                        } else if (element && !regex.test(element)) {
                            setProfileFormValidationErrors(prev => ({
                                ...prev,
                                firstName: "Please enter a valid value.",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                firstName: "",
                            }));
                        }
                        break;
                    case "email":
                        if (!isValidEmail(element)) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                email: "Please enter valid email",
                            }));
                            isSuccess = false;
                        } else if (!element) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                email: "This field is required.",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                email: "",
                            }));
                        }
                        break;
                    case "phoneNumber":
                        let maxLength = element.startsWith('+') ? 12 : 11;
                        if (!element) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                phoneNumber: "This field is required.",
                            }));
                            isSuccess = false;
                        } else if (element.length < (10 + (maxLength - 11)) || element.length > maxLength) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                phoneNumber: "Please Enter valid phone number",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                phoneNumber: "",
                            }));
                        }
                        break;
                    case "lastName":
                        if (!element) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                lastName: "This field is required.",
                            }));
                            isSuccess = false;
                        } else if (element && !regex.test(element)) {
                            setProfileFormValidationErrors(prev => ({
                                ...prev,
                                lastName: "Please enter a valid value.",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                lastName: "",
                            }));
                        }
                        break;
                    case "businessName":
                        if (!element) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                businessName: "This field is required.",
                            }));
                            isSuccess = false;
                        } else if (element && !regex.test(element)) {
                            setProfileFormValidationErrors(prev => ({
                                ...prev,
                                businessName: "Please enter a valid value.",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                businessName: "",
                            }));
                        }
                        break;
                    case "password":
                        if (!element) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                password: "",
                            }));
                        } else if (element !== getUserProfileToUpdateData?.confirmPassword) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                confirmPassword: "Password and confirm password should be same",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                password: "",
                                confirmPassword: "",
                            }));
                        }
                        break;
                    case "confirmPassword":
                        if (element !== getUserProfileToUpdateData?.password) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                confirmPassword: "Password and confirm password should be same",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                confirmPassword: "",
                            }));
                        }
                        break;
                    case "buildingNo":
                        if (!element) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                buildingNo: "This field is required.",
                            }));
                            isSuccess = false;
                        } else if (element && !regex.test(element)) {
                            setProfileFormValidationErrors(prev => ({
                                ...prev,
                                buildingNo: "Please enter a valid value.",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                buildingNo: "",
                            }));
                        }
                        break;
                    case "streetName":
                        if (!element) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                streetName: "This field is required.",
                            }));
                            isSuccess = false;
                        } else if (element && !regex.test(element)) {
                            setProfileFormValidationErrors(prev => ({
                                ...prev,
                                streetName: "Please enter a valid value.",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                streetName: "",
                            }));
                        }
                        break;
                    case "city":
                        if (!element) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                city: "This field is required.",
                            }));
                            isSuccess = false;
                        } else if (element && !regex.test(element)) {
                            setProfileFormValidationErrors(prev => ({
                                ...prev,
                                city: "Please enter a valid value.",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                city: "",
                            }));
                        }
                        break;
                    case "postcode":
                        if (!element) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                postcode: "This field is required.",
                            }));
                            isSuccess = false;
                        } else if (element && !regex.test(element)) {
                            setProfileFormValidationErrors(prev => ({
                                ...prev,
                                postcode: "Please enter a valid value.",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                postcode: "",
                            }));
                        }
                        break;
                    case "ownerName":
                        const nameRegex = /^[a-z", "\-']+$/i;
                        if (!element) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                ownerName: "This field is required.",
                            }));
                            isSuccess = false;
                        } else if (element && !nameRegex.test(element)) {
                            setProfileFormValidationErrors(prev => ({
                                ...prev,
                                ownerName: "Please enter a valid name.",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                ownerName: "",
                            }));
                        }
                        break;
                    case "website":

                        if (element && !urlRegex.test(element)) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                website: "Please enter a valid URL.",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                website: "",
                            }));
                        }
                        break;
                    case "businessType":
                        if (!element) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                businessType: "This field is required.",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                businessType: "",
                            }));
                        }
                        break;
                    case "companyHouseRegNo":
                        if (getUserProfileToUpdateData?.businessType === "Limited Company" && (!getUserProfileToUpdateData?.companyHouseRegNo || getUserProfileToUpdateData?.companyHouseRegNo.trim() === '')) {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                companyHouseRegNo: "This field is required.",
                            }));
                            isSuccess = false;
                        } else if (element && !regex.test(element)) {
                            setProfileFormValidationErrors(prev => ({
                                ...prev,
                                companyHouseRegNo: "Please enter a valid value.",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors((prev) => ({
                                ...prev,
                                companyHouseRegNo: "",
                            }));
                        }
                        // if (!element) {
                        //     setProfileFormValidationErrors((prev) => ({
                        //         ...prev,
                        //         companyHouseRegNo: "Companies house registration number is required",
                        //     }));
                        //     isSuccess = false;
                        // } else {
                        //     setProfileFormValidationErrors((prev) => ({
                        //         ...prev,
                        //         companyHouseRegNo: "",
                        //     }));
                        // }

                        break;
                    case "instagramName":
                        if (element && !element.includes('@')) {
                            setProfileFormValidationErrors(prev => ({
                                ...prev,
                                instagramName: "Please enter a valid username",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors(prev => ({
                                ...prev,
                                instagramName: "",
                            }));
                        }
                        break;
                    case "facebookPage":
                        if (element && !urlRegex.test(element)) {
                            setProfileFormValidationErrors(prev => ({
                                ...prev,
                                facebookPage: "Please enter a valid URL.",
                            }));
                            isSuccess = false;
                        } else {
                            setProfileFormValidationErrors(prev => ({
                                ...prev,
                                facebookPage: "",
                            }));
                        }
                        break;  
                    // case "insurenceExpireDate":
                    //     const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
                    //     const dateParts = element?.match(dateRegex);
                    //     console.log('dateParts', element, dateParts);

                    //     if (!dateParts) {
                    //         setProfileFormValidationErrors((prev) => ({
                    //             ...prev,
                    //             insurenceExpireDate: "Please enter valid date.",
                    //         }));
                    //         isSuccess = false;
                    //     } else {
                    //         const day = parseInt(dateParts[1], 10);
                    //         const month = parseInt(dateParts[2], 10) - 1; // Month index starts from 0 (January)
                    //         const year = parseInt(dateParts[3], 10);
                    //         const expiryDate = new Date(year, month, day);

                    //         console.log('expiryDate: ' + expiryDate);

                    //         const currentDate = new Date();
                    //         currentDate.setHours(0, 0, 0, 0); // Normalize current date to the start of the day for comparison

                    //         if (expiryDate < currentDate) {
                    //             setProfileFormValidationErrors((prev) => ({
                    //                 ...prev,
                    //                 insurenceExpireDate: "Please enter future date in Insurance Expiry, past dates are not allowed.",
                    //             }));
                    //             isSuccess = false;
                    //         } else {
                    //             setProfileFormValidationErrors((prev) => ({
                    //                 ...prev,
                    //                 insurenceExpireDate: "",
                    //             }));
                    //         }
                    //     }
                    //     break;
                    default:
                        break;
                }
            }
        }
        // if (!getUserProfileToUpdateData.password && !getUserProfileToUpdateData.confirmPassword) {
        //     setProfileFormValidationErrors((prev) => ({
        //         ...prev,
        //         password: "Password is required",
        //         confirmPassword: "Confirm password is required",
        //     }));
        //     isSuccess = false;
        // }

        return isSuccess;
    }

    /* Insurance document dropzone */
    const [insuranceDocumentFile, setInsuranceDocumentFile] = useState(null);
    const insuranceDocOnDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];
            const reader = new FileReader();

            reader.onload = () => {
                // if (uploadStep === 1) {
                // setLogoImage(reader.result);
                // } else {
                // setCoverImage(reader.result);
                // }
                setInsuranceDocumentFile(reader.result);
            };

            reader.readAsDataURL(file);
        },
        []
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        insuranceDocOnDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        }
    });
    const resetDropzone = () => {
        // if (uploadStep === 1) {
        //   setLogoImage(null);
        // } else {
        //   setCoverImage(null);
        // }
        setInsuranceDocumentFile(null);
    };
    const handleImageUpload = (file) => {
        // Perform actions with the uploaded image (e.g., upload to server)
        console.log('Uploaded image:', file);
    };

    /* Handling insurance certificate upload */
    const [insuranceDocFile, setInsuranceDocFile] = useState(null);
    const [trainingDocFile, setTrainingDocFile] = useState(null);
    const [identityDocFile, setIdentityDocFile] = useState(null);

    console.log("insuranceDocFile:-", insuranceDocFile, "trainingDocFile:-", trainingDocFile, "identityDocFile:-", identityDocFile);
    const {
        uploadTrainingCertificateQueryData,
        initUploadTrainingCertificateData,
    } = UploadTrainingCertificate();
    const {
        uploadInsurenceDocumentQueryData,
        initUploadInsurenceDocumentData,
    } = UploadInsurenceDocument();
    const {
        uploadIdentityProofQueryData,
        initUploadIdentityProofData,
    } = UploadIdentityProof();

    const { saveFinanceIdentityQueryData, initSaveFinanceIdentityData } = SaveFinanceIdentityAction()
    const {
        deleteTrainingCertificateQueryData,
        initDeleteTrainingCertificateData,
    } = DeleteTrainingCertificate();
    const {
        deleteInsurenceDocumentQueryData: { loading: cancleLoading },
        initDeleteInsurenceDocumentData,
    } = DeleteInsurenceDocument();
    const {
        deleteIdentityProofDocumentTypeQueryData,
        initDeleteIdentityProofDocumentTypeData,
    } = DeleteIdentityProofDocumentType();
    const onInsuranceCertificateUpload = () => {
        if (!insuranceDocFile) {
            return;
        }
        console.log('typeeee:', insuranceDocFile['0']);
        initUploadInsurenceDocumentData({ insurenceDocument: insuranceDocFile['0'] }, (res) => {
            toast.success(res?.data?.uploadInsurenceDocument?.message);
            setInsuranceDocFile(null);
            initGetUserProfileToUpdateData();
            setInsuranceCertificateModal(false);
        }, (err) => {
            console.log('err insurance certificate upload', err);
            toast.error(err?.data?.uploadInsurenceDocument?.message);
        });
        console.log('res insurance certificate upload onInsuranceCertificateUpload', insuranceDocFile['0']);
    }
    const onTrainingCertificateUpload = () => {
        if (!trainingDocFile) {
            return;
        }
        initUploadTrainingCertificateData({ trainingCertificate: trainingDocFile['0'] }, (res) => {
            toast.success(res?.data?.uploadTrainingCertificate?.message);
            setTrainingDocFile(null);
            initGetUserProfileToUpdateData();
            setTrainignCertificateModal(false);
        }, (err) => {
            console.log('err training certificate upload', err);
            toast.error(err?.data?.uploadTrainingCertificate?.message);
        });
    }
    const onIdentityUpload = () => {
        if (!identityDocFile) {
            setIdentityError("Please upload identity document");
            return;
        } else {
            // setIdentityError("");
        }
        initSaveFinanceIdentityData({ identityDoc: identityDocFile[0] }, (res) => {
            toast.success(res?.data?.saveIdentityDocumentForFinance?.message);
            setIdentityDocFile(null);
            initGetUserProfileToUpdateData();
            setIdentityModal(false);
            setIdentityError("");
        }, (err) => {
            toast.error(err?.data?.saveIdentityDocumentForFinance?.message);
        });
    }

    const [openInsurenceDeletePopup, setOpenInsurenceDeletePop] = useState(false);
    const [openTrainingCertificateDeletePopup, setOpenTrainingCertificateDeletePopup] = useState(false);
    const [openIdentityProofDocumentDeletePopup, setIdentityProofDocumentDeletePopup] = useState(false);
    const [insurenceExpiryDate, setInsurenceExpiryDate] = useState(["", "", ""]);

    const onDeleteInsuranceDocument = () => {
        setOpenInsurenceDeletePop(true);

        // Swal.fire({
        //     text: 'Are you sure you want to remove insurance document?',
        //     icon: 'question',
        //     showCancelButton: true,
        //     confirmButtonText: 'Yes',
        //     customClass: {
        //         confirmButton: 'btn-primary',
        //     }
        // }).then((result) => {
        //     if (result.isConfirmed) {
        // initDeleteInsurenceDocumentData({}, (res) => {
        //     toast.success(res?.data?.deleteInsurenceDocument?.message);
        //     setInsuranceDocFile(null);
        //     initGetUserProfileToUpdateData();
        // }, (err) => {
        //     toast.error(err?.data?.deleteInsurenceDocument?.message);
        // });
        //     }
        // });
    }


    const handleDeleteTrainingDoc = async () => {
        initDeleteTrainingCertificateData({}, (res) => {
            toast.success(res?.data?.deleteTrainingCertificate?.message);
            setTrainingDocFile(null);
            initGetUserProfileToUpdateData();
        }, (err) => {
            toast.error(err?.data?.deleteTrainingCertificate?.message);
        });
        setOpenTrainingCertificateDeletePopup(false);
    }
    const handleDeleteInsurenceDoc = async () => {
        initDeleteInsurenceDocumentData({}, (res) => {
            toast.success(res?.data?.deleteInsurenceDocument?.message);
            setInsuranceDocFile(null);
            initGetUserProfileToUpdateData();
        }, (err) => {
            toast.error(err?.data?.deleteInsurenceDocument?.message);
        });
        setOpenInsurenceDeletePop(false);
    }

    const onDeleteTrainingCertificateDocument = () => {
        setOpenTrainingCertificateDeletePopup(true);

        // Swal.fire({
        //     text: 'Are you sure you want to remove training certificate?',
        //     icon: 'question',
        //     showCancelButton: true,
        //     confirmButtonText: 'Yes',
        //     customClass: {
        //         confirmButton: 'btn-primary',
        //     }
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         initDeleteTrainingCertificateData({}, (res) => {
        //             toast.success(res?.data?.deleteTrainingCertificate?.message);
        //             setTrainingDocFile(null);
        //             initGetUserProfileToUpdateData();
        //         }, (err) => {
        //             toast.error(err?.data?.deleteTrainingCertificate?.message);
        //         });
        //     }
        // });
    }
    const onDeleteIdentityProofDocument = () => {
        setIdentityProofDocumentDeletePopup(true);

        // Swal.fire({
        //     text: 'Are you sure you want to remove identity proof?',
        //     icon: 'question',
        //     showCancelButton: true,
        //     confirmButtonText: 'Yes',
        //     customClass: {
        //         confirmButton: 'btn-primary',
        //     }
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         initDeleteIdentityProofDocumentTypeData({}, (res) => {
        //             toast.success(res?.data?.deleteIdentityProofDocumentType?.message);
        //             setIdentityDocFile(null);
        //             initGetUserProfileToUpdateData();
        //         }, (err) => {
        //             toast.error(err?.data?.deleteIdentityProofDocumentType?.message);
        //         });
        //     }
        // });
    }
    const handleDeleteIdentityDoc = async () => {
        initDeleteIdentityProofDocumentTypeData({ usrVrfId: getUserProfileToUpdateData.identitiDocId }, (res) => {
            toast.success(res?.data?.deleteIdentityProofDocumentType?.message);
            setIdentityDocFile(null);
            initGetUserProfileToUpdateData();
        }, (err) => {
            toast.error(err?.data?.deleteIdentityProofDocumentType?.message);
        });
        setIdentityProofDocumentDeletePopup(false);
    }

    useEffect(() => {
        console.log('custommodal');
        if (!identityModal) {
            setIdentityDocFile(null);
        }

        if (!trainignCertificateModal) {
            setTrainingDocFile(null);
        }

        if (!insuranceCertificateModal) {
            setInsuranceDocFile(null);
        }
    }, [identityModal, trainignCertificateModal, insuranceCertificateModal]);

    /* Bank Details */
    const [bankDetails, setBankDetails] = useState({
        accountNumber: '',
        sortCode: '',
        sign: '',
    });
    const {
        getBankDetailOfUserQueryData,
        initGetBankDetailOfUserData,
    } = GetBankDetailOfUserAction();
    const [isIgnoreBankDetailsError, setIsIgnoreBankDetailsError] = useState(true);
    useEffect(() => {
        initGetBankDetailOfUserData({}, (res) => {
            console.log('res bank details initGetBankDetailOfUserData', res?.data?.getBankDetailOfUser?.data);
            let data = res?.data?.getBankDetailOfUser?.data;
            if (data.accountNumber !== '' && data.accountNumber !== null && data.accountNumber !== undefined) {
                setIsEditBankDetails(true);
                setIsTncChecked(true);
            }
            if (data?.accountNumber) {
                setIsBankDetailsFilled(true);
            }
            if (data.sign) {
                setCanvasStatus(false);
            }
            setIsIgnoreBankDetailsError(true);
            setBankDetailData(data);
            if (data?.sortCode) {
                console.log('here!!!');
                setBankDetailData((prev) => ({
                    ...prev,
                    sortCode: data.sortCode.substring(0, 2) + "-" + data.sortCode.substring(2, 4) + "-" + data.sortCode.substring(4)
                }));
            }
        }, (err) => {
            console.log('err bank details initGetBankDetailOfUserData', err);
        });
    }, []);

    useEffect(() => {
        setGetUserProfileToUpdateData((prev) => ({
            ...prev,
            password: ''
        }));
    }, []);

    const businessTypeValuse = ["Sole trader", "Limited Company"];

    const handleTitleChange = (value) => {
        setGetUserProfileToUpdateData((prevData) => ({
            ...prevData,
            businessType: value
        }));
        validateProfileFormInput("businessType", value);

        // setProfileFormValidationErrors((prev) => ({
        //     ...prev,
        //     businessType: ''
        //   }));
        // setGetUserProfileToUpdateData("businessType", value)
    }

    const customStyle = {

        menuPortal: base => ({
            ...base,
            zIndex: 9999 // Adjust the z-index value according to your requirement
        }),
        control: (styles, state) => ({
            ...styles, backgroundColor: '#FFFFFF',
            //  border: state.isFocused ? 0 : 0,
            outline: "none",
            appearance: "none",
            padding: "10px 10px",
            borderRadius: "10px",
            border: "1px solid #878787",
            height: "55px"
        }),
        option: (styles, { isDisabled, isFocused, isSelected }) => {

            return {
                ...styles,
                // border: isFocused ? 'none' : 'none'
                overflow: "visible"
            };
        },
    };
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [insurenceExpireDate, setInsurenceExpireDate] = useState('');

    const handleInsuranceExpiryChange = (field, value) => {
        // Update the respective state
        // if (field === 'day') setDay(value);
        // if (field === 'month') setMonth(value);
        // if (field === 'year') setYear(value);
        
        const numericValue = value.replace(/\D/g, ''); // Strip out all non-digit characters
        if (field === 'year' && numericValue.length > 4) {
            numericValue = numericValue.slice(0, 4); // Limit to 4 digits
        }

        if (field === 'year') {
            if (value.length > 4) {
                return false;
            }
        } else if (field === 'month') {
            if (value.length > 2) {
                return false;
            } else if (value > 12) {
                value = 0+''+value.slice(0, 1);
            }
        } else if (field === 'day') {
            if (value.length > 2) {
                return false;
            } else if (value > 31) {
                value = 0 +''+ value.slice(0, 1)
            }
        }

        switch (field) {
            case 'year':
                if (value.length <= 4) setYear(value);
                break;
            case 'month':
                if (value.length <= 2) setMonth(value);
                break;
            case 'day':
                if (value.length <= 2) setDay(value);
                break;
            default:
                break;
        }

        // Compose the new insurenceExpireDate
        const newDate = `${field === 'day' ? value : day}-${field === 'month' ? value : month}-${field === 'year' ? value : year}`;
        setInsurenceExpireDate(newDate);

        setGetUserProfileToUpdateData(prev => ({
            ...prev,
            insurenceExpireDate: newDate,
        }));
    };

    useEffect(() => {
        if (getUserProfileToUpdateData?.insurenceExpireDate) {
            const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
            const dateParts = getUserProfileToUpdateData?.insurenceExpireDate?.match(dateRegex);
            console.log('dateParts', getUserProfileToUpdateData?.insurenceExpireDate, dateParts);

            if (!dateParts) {
                setProfileFormValidationErrors((prev) => ({
                    ...prev,
                    insurenceExpireDate: "Please enter valid date.",
                }));
            } else {
                const day = parseInt(dateParts[1], 10);
                const month = parseInt(dateParts[2], 10) - 1; // Month index starts from 0 (January)
                const year = parseInt(dateParts[3], 10);
                const expiryDate = new Date(year, month, day);

                console.log('expiryDate: ' + expiryDate);

                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0); // Normalize current date to the start of the day for comparison

                if (expiryDate < currentDate) {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        insurenceExpireDate: "Please enter future date in Insurance Expiry, past dates are not allowed.",
                    }));
                    // isSuccess = false;
                } else {
                    setProfileFormValidationErrors((prev) => ({
                        ...prev,
                        insurenceExpireDate: "",
                    }));
                }
            }
        }
    }, [getUserProfileToUpdateData?.insurenceExpireDate]);

    // UseEffect to handle the initial state or when you fetch the user's data
    useEffect(() => {
        const [initialDay, initialMonth, initialYear] = getUserProfileToUpdateData?.insurenceExpireDate?.split('-') || [];
        setDay(initialDay || '');
        setMonth(initialMonth || '');
        setYear(initialYear || '');
    }, [getUserProfileToUpdateData?.insurenceExpireDate]);

    // const handleInsuranceExpiryChange = (part, value) => {
    //     let insurenceExpireDate = getUserProfileToUpdateData.insurenceExpireDate || '';
    //     // let [day, month, year] = insurenceExpireDate.split('-').map(part => part || '');

    //     // if (part === 'day') day = value.padStart(2, '0'); // Ensure day and month are always two digits
    //     // if (part === 'month') month = value.padStart(2, '0');
    //     // if (part === 'year') year = value;

    //     let day = '', month = '', year = '';
    //     const dateParts = getUserProfileToUpdateData?.insurenceExpireDate?.split('-');
    //     if (dateParts && dateParts.length === 3) {
    //         [day, month, year] = dateParts;
    //     }
    //     const newInsuranceExpireDate = [day, month, year].join('-');
    //     setGetUserProfileToUpdateData(prev => ({
    //         ...prev,
    //         insurenceExpireDate: newInsuranceExpireDate,
    //     }));

    //     // const errorMessage = validateInsuranceExpiryDate(newInsuranceExpireDate);

    //     // setProfileFormValidationErrors(prevErrors => ({
    //     //     ...prevErrors,
    //     //     insurenceExpireDate: errorMessage,
    //     // }));

    //     // if (!errorMessage) { // Only update the date if there's no error
    //     //     setGetUserProfileToUpdateData(prev => ({
    //     //         ...prev,
    //     //         insurenceExpireDate: newInsuranceExpireDate,
    //     //     }));
    //     // }
    //     };

    const [fileLists, setFileLists] = useState(Array.from({ length: 10 }, () => null));
    const handleDrop = (index) => (acceptedFiles) => {
        setFileLists((prevFileLists) => {
            const newFileLists = [...prevFileLists];
            newFileLists[index] = acceptedFiles[0];
            return newFileLists;
        });
    };
    useEffect(() => {
        console.log('fileLists: ', fileLists);
    }, [fileLists])
    const handleRemove = (index) => {
        const updatedFiles = [...fileLists];
        updatedFiles[index] = null;
        setFileLists(updatedFiles);
    };

    const [fileListsInsurance, setFileListsInsurance] = useState(Array.from({ length: 10 }, () => null));
    const handleDropInsurance = (index) => (acceptedFiles) => {
        setFileListsInsurance((prevFileLists) => {
            const newFileLists = [...prevFileLists];
            newFileLists[index] = acceptedFiles[0];
            return newFileLists;
        });
    };
    useEffect(() => {
        console.log('fileListsInsurance: ', fileListsInsurance);
    }, [fileListsInsurance]);
    const handleRemoveInsurance = (index) => {
        const updatedFiles = [...fileListsInsurance];
        updatedFiles[index] = null;
        setFileListsInsurance(updatedFiles);
    };

    //calander
    const [showCalendar, setShowCalendar] = useState(false);
    const [showSelectedDate, setShowSelectedDate] = useState(
        moment().format("dddd, Do MMMM")
    );
    const [calendarStartDate, setCalendarStartDate] = useState(
        moment().format("YYYY-MM-DD")
    );
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedMonth, setSelectedMonth] = useState(
        selectedDate.getMonth() + 1
    );
    const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
    const [formatedDate, setFormatedDate] = useState("");
    const [step, setStep] = useState("");
    const [showSelectedTime, setShowSelectedTime] = useState("");
    const [isOpenMobileCalendar, setIsOpenMobileCalendar] = useState(false);
    const handleSelectedDate = (selectedDate) => {
        setShowSelectedDate(selectedDate);
    };
    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };
    // Function to generate an array of dates for a specified range
    function generateDateData(startDate, endDate) {
        const dates = [];
        const currentDate = new Date(startDate);
        // Loop to generate dates until the endDate is reached
        while (currentDate <= endDate) {
            dates.push(currentDate.toISOString().slice(0, 10));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    }
    const today = new Date();
    const endDatee = new Date(today);
    endDatee.setFullYear(today.getFullYear() + 5);
    const dateData = generateDateData(today, endDatee);

    const dayRef = useRef(null);
    const monthRef = useRef(null);
    const yearRef = useRef(null)

    const handleMaxLength = (ref) => {
        if (ref.current && ref.current.value.length === ref.current.maxLength) {
            switch (ref.current) {
                case dayRef.current:
                    monthRef.current && monthRef.current.focus();
                    break;
                case monthRef.current:
                    yearRef.current && yearRef.current.focus();
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <>
            <div className="mainfinancelandingpage mainEditProfile">
                <FinaceHeader />
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8 col-sm-12">
                            <h2 className="pt-5 pagenewPageTitle">Edit profile</h2>
                            <form className="registration-form col-md-12" onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="firstName" className="form-label">
                                        First name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="firstName"
                                        name="firstName"
                                        placeholder="First name"
                                        value={getUserProfileToUpdateData?.firstName}
                                        onChange={handleChange}
                                        ref={firstNameRef}
                                    />
                                    {profileFormValidationErrors.firstName && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.firstName}</div>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="lastName" className="form-label">
                                        Surname
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Last name"
                                        value={getUserProfileToUpdateData?.lastName}
                                        onChange={handleChange}
                                        ref={lastNameRef}
                                    />
                                    {profileFormValidationErrors.lastName && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.lastName}</div>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="businessName" className="form-label">
                                        Business name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="businessName"
                                        name="businessName"
                                        placeholder="Business name"
                                        value={getUserProfileToUpdateData?.businessName}
                                        onChange={handleChange}
                                        ref={businessNameRef}
                                    />
                                    {profileFormValidationErrors.businessName && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.businessName}</div>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="phoneNumber" className="form-label">
                                        Phone number
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        placeholder="Phone number"
                                        value={getUserProfileToUpdateData?.phoneNumber}
                                        onChange={handleChange}
                                        pattern="[+0-9]+"
                                        ref={phoneNumberRef}
                                    />
                                    {profileFormValidationErrors.phoneNumber && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.phoneNumber}</div>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="emailAddress" className="form-label">
                                        Email address
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="Email address"
                                        value={getUserProfileToUpdateData?.email}
                                        onChange={handleChange}
                                        ref={emailRef}
                                    />
                                    {profileFormValidationErrors.email && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.email}</div>}
                                </div>
                                <div className="mb-4 customeSelectBox" ref={businessTypeRef}>
                                    <label htmlFor="businessType" className="form-label">
                                        Business Type
                                    </label>
                                    <CustomDropdown
                                        // labelName={"Business Type"}
                                        name="businessType"
                                        id="businessType"
                                        dropdownId={"businessType"}
                                        placeholdername={"Select Business Type"}
                                        optionsArr={businessTypeValuse}
                                        defaultValue={getUserProfileToUpdateData?.businessType}
                                        handleSelectedValue={handleTitleChange}
                                        style={customStyle}
                                    />
                                    {/* <input
                                        type="buildingNo"
                                        className="form-control"
                                        id="buildingNo"
                                        name="buildingNo"
                                        placeholder=""
                                        value={getUserProfileToUpdateData?.buildingNo}
                                        onChange={handleChange}
                                    /> */}
                                    {profileFormValidationErrors.businessType && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.businessType}</div>}
                                </div>
                                {getUserProfileToUpdateData?.businessType === "Limited Company" && (
                                    <div className="mb-4">
                                        <label htmlFor="companyHouseRegNo" className="form-label">
                                            Companies House Registration Number
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="companyHouseRegNo"
                                            name="companyHouseRegNo"
                                            placeholder="Enter Companies House Registration Number"
                                            value={getUserProfileToUpdateData?.companyHouseRegNo} // Adjust according to how you store this value in your state
                                            onChange={handleChange} // Ensure handleChange can update the correct field in your state
                                            ref={companyHouseRegNoRef}
                                        />
                                        {profileFormValidationErrors?.companyHouseRegNo && (
                                            <div className="text-danger error" style={{ marginTop: '15px' }}>
                                                {profileFormValidationErrors?.companyHouseRegNo}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="mb-4">
                                    <label htmlFor="buildingNo" className="form-label">
                                        Business Address - Building No
                                    </label>
                                    <input
                                        type="buildingNo"
                                        className="form-control"
                                        id="buildingNo"
                                        name="buildingNo"
                                        placeholder="Business Address - Building No"
                                        value={getUserProfileToUpdateData?.buildingNo}
                                        onChange={handleChange}
                                        ref={buildingNoRef}
                                    />
                                    {profileFormValidationErrors.buildingNo && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.buildingNo}</div>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="streetName" className="form-label">
                                        Business Address - Street Name
                                    </label>
                                    <input
                                        type="streetName"
                                        className="form-control"
                                        id="streetName"
                                        name="streetName"
                                        placeholder="Business Address - Street Name"
                                        value={getUserProfileToUpdateData?.streetName}
                                        onChange={handleChange}
                                        ref={streetNameRef}
                                    />
                                    {profileFormValidationErrors.streetName && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.streetName}</div>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="city" className="form-label">
                                        Business Address - City
                                    </label>
                                    <input
                                        type="city"
                                        className="form-control"
                                        id="city"
                                        name="city"
                                        placeholder="Business Address - City"
                                        value={getUserProfileToUpdateData?.city}
                                        onChange={handleChange}
                                        ref={cityRef}
                                    />
                                    {profileFormValidationErrors.city && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.city}</div>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="postcode" className="form-label">
                                        Business Address - Postcode
                                    </label>
                                    <input
                                        type="postcode"
                                        className="form-control"
                                        id="postcode"
                                        name="postcode"
                                        placeholder="Business Address - Postcode"
                                        value={getUserProfileToUpdateData?.postcode}
                                        onChange={handleChange}
                                        ref={postcodeRef}
                                    />
                                    {profileFormValidationErrors.postcode && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.postcode}</div>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="ownerName" className="form-label">
                                        Director / Business Owner Name
                                    </label>
                                    <input
                                        type="ownerName"
                                        className="form-control"
                                        id="ownerName"
                                        name="ownerName"
                                        placeholder="Director / Business Owner Name"
                                        value={getUserProfileToUpdateData?.ownerName}
                                        onChange={handleChange}
                                        ref={ownerNameRef}
                                    />
                                    {profileFormValidationErrors.ownerName && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.ownerName}</div>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="website" className="form-label">
                                    Website (Optional)
                                    </label>
                                    <input
                                        type="website"
                                        className="form-control"
                                        id="website"
                                        name="website"
                                        placeholder="Website (Optional)"
                                        value={getUserProfileToUpdateData?.website}
                                        onChange={handleChange}
                                        ref={websiteRef}
                                    />
                                    {profileFormValidationErrors.website && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.website}</div>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="facebookPage" className="form-label">
                                        Facebook Page (Optional)
                                    </label>
                                    <input
                                        type="facebookPage"
                                        className="form-control"
                                        id="facebookPage"
                                        name="facebookPage"
                                        placeholder="Facebook Page (Optional)"
                                        value={getUserProfileToUpdateData?.facebookPage}
                                        onChange={handleChange}
                                        ref={facebookRef}
                                    />
                                    {profileFormValidationErrors.facebookPage && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.facebookPage}</div>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="instagramName" className="form-label">
                                        Instagram User Name (Optional)
                                    </label>
                                    <input
                                        type="instagramName"
                                        className="form-control"
                                        id="instagramName"
                                        name="instagramName"
                                        placeholder="Instagram User Name (Optional)"
                                        value={getUserProfileToUpdateData?.instagramName}
                                        onChange={handleChange}
                                        ref={instagramNameRef}
                                    />
                                    {profileFormValidationErrors.instagramName && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.instagramName}</div>}
                                </div>
                                {/* <div className="mb-2" ref={insurenceExpireDateRef}>
                                    <label htmlFor="insurenceExpireDate" className="form-label">
                                        Insurance Expiry Date
                                    </label>
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <input
                                            name="day"
                                            id={'day'}
                                            ref={dayRef}
                                            // id="insurenceExpireDate"
                                            // name="insurenceExpireDate"
                                            type="number"
                                            className="form-control"
                                            maxLength="2"
                                            placeholder="DD"
                                            value={day}
                                            onChange={(e) => handleInsuranceExpiryChange('day', e.target.value)}
                                            onInput={() => handleMaxLength(dayRef)}
                                        />
                                        <input
                                            name="month"
                                            id={'month'}
                                            ref={monthRef}
                                            // id="insurenceExpireDate"
                                            // name="insurenceExpireDate"
                                            type="number"
                                            className="form-control"
                                            maxLength="2"
                                            placeholder="MM"
                                            value={month}
                                            onChange={(e) => handleInsuranceExpiryChange('month', e.target.value)}
                                            onInput={() => handleMaxLength(monthRef)}

                                        />
                                        <input
                                            name="year"
                                            id={'year'}
                                            ref={yearRef}
                                            // id="insurenceExpireDate"
                                            // name="insurenceExpireDate"
                                            type="text"
                                            className="form-control"
                                            maxLength="4"
                                            placeholder="YYYY"
                                            value={year}
                                            onChange={(e) => handleInsuranceExpiryChange('year', e.target.value)}
                                            onInput={() => handleMaxLength(yearRef)}

                                        />
                                    </div>
                                </div>
                                {profileFormValidationErrors.insurenceExpireDate && <div className="text-danger error" style={{ marginTop: '10px 0px' }}>{profileFormValidationErrors.insurenceExpireDate}</div>} */}

                                <div className="mb-4">
                                    <label htmlFor="password" className="form-label">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        placeholder="Password"
                                        value={getUserProfileToUpdateData?.password}
                                        onChange={handleChange}
                                        autoComplete="new-password"
                                    />
                                    {profileFormValidationErrors.password && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.password}</div>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="confirmPassword" className="form-label">
                                        Confirm password
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        placeholder="Confirm password"
                                        value={getUserProfileToUpdateData?.confirmPassword}
                                        onChange={handleChange}
                                    />
                                    {profileFormValidationErrors.confirmPassword && <div className="text-danger error" style={{ marginTop: '15px' }}>{profileFormValidationErrors.confirmPassword}</div>}
                                </div>

                                <div className="mt-4 additional-info">
                                    <label htmlFor="Additional" className="form-label">
                                        Required Info
                                    </label>
                                    <hr />
                                </div>

                                <div className="col-12 col-lg-12 mt-4 pr-0">
                                    <div className="form-group upload-files-section facial-mapping-section">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <a
                                                className=" border-0 w-100 customeAdditionalMap"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Bank details"
                                                onClick={handleBankDetailsClick}
                                            >
                                                <div className="additionalInfoBox">
                                                    <div className="additionalImageWrapper">
                                                        <div className="additional-image rounded-circle">
                                                            <img src={bankDetailsImgIcon} />
                                                        </div>
                                                        <div class="w-75 clock-images additional-info-status-icon-container">
                                                            <img src={isBankDetailsFilled ? successTime : wrongTime} className="img-fluid clock-orange" />
                                                        </div>
                                                    </div>
                                                    <div className="additionalInfoWrapper">
                                                        <div className="additionalInfoContentBox">
                                                            <h6 className="text-black mb-2 py-1">Bank details</h6>
                                                            <span className={isBankDetailsFilled ? 'doc-added' : 'doc-pending'}>{isBankDetailsFilled ? 'Completed' : 'Awaiting Action'}</span>
                                                        </div>
                                                        <div className="additionalInfoArrow">
                                                            <img src={chevronLeft} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-12 mt-4 pr-0">
                                    <div className="form-group upload-files-section facial-mapping-section">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <a
                                                className=" border-0 w-100 customeAdditionalMap"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Insurance document"
                                                onClick={handleInsuranceDocumentClick}
                                            >
                                                <div className="additionalInfoBox">
                                                    <div className="additionalImageWrapper">
                                                        <div className="additional-image rounded-circle">
                                                            <img src={insuranceCertificateIcon} />
                                                        </div>
                                                        <div class="w-75 clock-images additional-info-status-icon-container">
                                                            <img src={insuranceCertificateUrl.length > 0 ? successTime : wrongTime} className="img-fluid clock-orange" />
                                                        </div>
                                                    </div>
                                                    <div className="additionalInfoWrapper">
                                                        <div className="additionalInfoContentBox">
                                                            <h6 className="text-black mb-2 py-1">Insurance certificate</h6>
                                                            <span className={insuranceCertificateUrl.length > 0 ? 'doc-added' : 'doc-pending'}>{insuranceCertificateUrl.length > 0 ? 'Completed' : 'Awaiting Action'}</span>
                                                        </div>
                                                        <div className="additionalInfoArrow">
                                                            <img src={chevronLeft} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-12 mt-4 pr-0">
                                    <div className="form-group upload-files-section facial-mapping-section">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <a
                                                className=" border-0 w-100 customeAdditionalMap"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Training Certificates"
                                                onClick={handleTrainingCertificateClick}
                                            >
                                                <div className="additionalInfoBox">
                                                    <div className="additionalImageWrapper">
                                                        <div className="additional-image rounded-circle">
                                                            <img src={trainingCertificatesIcon} />
                                                        </div>
                                                        <div class="w-75 clock-images additional-info-status-icon-container">
                                                            <img src={trainingCertificateUrl.length > 0 ? successTime : wrongTime} className="img-fluid clock-orange" />
                                                        </div>
                                                    </div>
                                                    <div className="additionalInfoWrapper">
                                                        <div className="additionalInfoContentBox">
                                                            <h6 className="text-black mb-2 py-1">Training certificates</h6>
                                                            <span className={trainingCertificateUrl.length > 0 ? 'doc-added' : 'doc-pending'}>{trainingCertificateUrl.length > 0 ? 'Completed' : 'Awaiting Action'}</span>
                                                        </div>
                                                        <div className="additionalInfoArrow">
                                                            <img src={chevronLeft} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-12 mt-4 pr-0">
                                    <div className="form-group upload-files-section facial-mapping-section">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <a
                                                className=" border-0 w-100 customeAdditionalMap"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Identity proof"
                                                onClick={handleIdentityClick}
                                            >
                                                <div className="additionalInfoBox">
                                                    <div className="additionalImageWrapper">
                                                        <div className="additional-image rounded-circle">
                                                            <img src={identityIcon} className="img-fluid" style={{ marginRight: '0px' }} />
                                                        </div>
                                                        <div class="w-75 clock-images additional-info-status-icon-container">
                                                            <img src={identityUrl?.length > 0 ? successTime : wrongTime} className="img-fluid clock-orange" />
                                                        </div>
                                                    </div>
                                                    <div className="additionalInfoWrapper">
                                                        <div className="additionalInfoContentBox">
                                                            <h6 className="text-black mb-2 py-1">Identity proof</h6>
                                                            <span className={identityUrl?.length > 0 ? 'doc-added' : 'doc-pending'}>{identityUrl?.length > 0 ? 'Completed' : 'Awaiting Action'}</span>
                                                        </div>
                                                        <div className="additionalInfoArrow">
                                                            <img src={chevronLeft} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-12 mt-4 pr-0">
                                    <div className="form-group upload-files-section facial-mapping-section">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <a className=" border-0 w-100 customeAdditionalMap" data-toggle="tooltip" data-placement="bottom" title="Add Facial Mapping" href="#" onClick={handleLinkCard}>
                                                <div className="additionalInfoBox">
                                                    <div className="additionalImageWrapper">
                                                        <div className="additional-image rounded-circle">
                                                            <img src={linkCardIcon} className="img-fluid" style={{ marginRight: '0px' }} />
                                                        </div>
                                                        <div class="w-75 clock-images additional-info-status-icon-container">
                                                            {generateLinkCardPathData?.loading ?
                                                                <img style={{ borderRadius: "50%" }} className="time-icon" width={20} src={loaderIcon} alt="img" />
                                                                :
                                                                <img src={checkLinkCard ? successTime : wrongTime} className="img-fluid clock-orange" alt="img" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="additionalInfoWrapper">
                                                        <div className="additionalInfoContentBox">
                                                            <h6 className="text-black mb-2 py-1">Link card</h6>
                                                            <span className={checkLinkCard ? 'doc-added' : 'doc-pending'}>{checkLinkCard ? 'Completed'  : 'Awaiting Action'}</span>
                                                        </div>
                                                        <div className="additionalInfoArrow">
                                                            <img src={chevronLeft} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                    <StepButton
                                        type="submit"
                                        label="submit"
                                        blue={true}
                                        isLoading={updateUserProfileQueryData?.loading}
                                        className="my-4"
                                    />
                                {/* <button type="submit" className="my-4 col-md-12 btn btn-primary apply-btn">
                                    Submit
                                </button> */}
                            </form >
                        </div >
                    </div >
                </div >
            </div >
            <CustomModal
                modalOpen={bankDetailsModel}
                setModalOpen={setBankDetailsModel}
                modaltitle={"Bank details"}
                type={'bank-details'}
                className={'fullWidthPopup'}
                modelDescShow={true}
                modelDesc={'Please enter the bank details you would like us to pay money into'}
                modalBody={
                    <>
                        <div className="bank-details-modal">
                            <form onSubmit={handleBankDetailsSubmit}>
                                <div class="cusInputAllWrapper">
                                    {/* <div className="mb-3">
                                        <label htmlFor="nameInput" className="form-label">
                                            Business Bank Name
                                        </label>
                                        <input
                                            type="text"
                                            id="nameInput"
                                            className="form-control"
                                            name="bankName"
                                            value={bankDetailData.bankName}
                                            onChange={handleInputChange}
                                            disabled={isEditBankDetails}
                                        />
                                        {bankDetailsFormValidationErrors.bankName && <div className="text-danger error">{bankDetailsFormValidationErrors.bankName}</div>}
                                    </div> */}
                                    <div className="mb-3">
                                        <label htmlFor="accountNumberInput" className="form-label">
                                            Account number
                                        </label>
                                        <input
                                            type="number"
                                            id="accountNumberInput"
                                            className="form-control"
                                            name="accountNumber"
                                            value={bankDetailData.accountNumber}
                                            onChange={handleInputChange}
                                            disabled={isEditBankDetails}
                                            min={1}
                                        />
                                        {bankDetailsFormValidationErrors.accountNumber && <div className="text-danger error">{bankDetailsFormValidationErrors.accountNumber}</div>}
                                    </div>
                                    <div className="">
                                        {/* <label htmlFor="sortCodeInput" className="form-label">
                                            Sort code
                                        </label>
                                        <input
                                            type="text"
                                            id="sortCodeInput"
                                            name="sortCode"
                                            className="form-control"
                                            placeholder="NN - NN - NN"
                                            maxLength="8"
                                            value={bankDetailData.sortCode}
                                            onChange={handleInputChange}
                                            disabled={isEditBankDetails}
                                        />
                                        {bankDetailsFormValidationErrors.sortCode && <div className="text-danger error">{bankDetailsFormValidationErrors.sortCode}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="sortCodeInput"
                                            className="form-label"
                                        >
                                            Tearms & Conditions
                                        </label>
                                        <div
                                            id="tncDiv"
                                            name="sortCode"
                                            className="form-control d-flex justify-content-between align-items-center"
                                            placeholder="Tearms & Conditions"
                                            maxLength="8"
                                            value=""
                                            onChange=""
                                            onClick={() => setIsTncOpen(!isTncOpen)}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <span>
                                                Tearms & Conditions
                                            </span>
                                            <span>
                                                {
                                                    !isTncOpen ?
                                                        <FaPlus size={15.27} color="black" /> :
                                                        <FaMinus size={15.27} color="black" />
                                                }
                                            </span>
                                        </div> */}
                                        <div className="mb-3">
                                            <label htmlFor="sortCodeInput" className="form-label">
                                                Sort code
                                            </label>
                                            <input
                                                type="text"
                                                id="sortCodeInput"
                                                name="sortCode"
                                                className="form-control"
                                                placeholder="NN - NN - NN"
                                                maxLength="8"
                                                value={bankDetailData.sortCode}
                                                onChange={handleInputChange}
                                                disabled={isEditBankDetails}
                                            />
                                            {bankDetailsFormValidationErrors.sortCode && <div className="text-danger error">{bankDetailsFormValidationErrors.sortCode}</div>}
                                        </div>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="sortCodeInput"
                                                className="form-label"
                                            >
                                                Terms & Conditions
                                            </label>
                                            <div
                                                id="tncDiv"
                                                name="sortCode"
                                                className="form-control d-flex justify-content-between align-items-center"
                                                placeholder="Terms & Conditions"
                                                maxLength="8"
                                                value=""
                                                onChange=""
                                                onClick={() => setIsTncOpen(!isTncOpen)}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <span>
                                                    Terms & Conditions
                                                </span>
                                                <span>
                                                    {
                                                        !isTncOpen ?
                                                            <FaPlus size={15.27} color="black" /> :
                                                            <FaMinus size={15.27} color="black" />
                                                    }
                                                </span>
                                            </div>
                                            {isTncOpen && <EditProfileTncContentComponent />}
                                        </div>
                                        {/* <div className="right-section mb-3">
                                        <div className="card-section">
                                            <div className="edit-section" onClick={() => handleHide()}>
                                            </div>
                                            <div
                                                className="d-flex flex-column align-items-center gap-2 sign-section"
                                                onClick={() => handleHide()}
                                            >
                                                {

                                                signedSignatureData !== null ? <EnableSignIcon /> : <EmptySignIcon />
                                                }
                                                <h1>Your signature</h1>
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="right-section mb-3" style={{
                                            style: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                        </div>
                                        <div className="right-section mb-3">
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}>
                                                <label
                                                    htmlFor=""
                                                    className="form-label"
                                                >
                                                    You have signed the terms and conditions
                                                </label>
                                                {
                                                    !isEditBankDetails &&
                                                    (canvasStatus ? (
                                                        <div className="cursor-pointer" onClick={() => clear()}>
                                                            {/* <img src={EraseImg} alt="EraseImg" width={35} height={35} /> */}
                                                            <FaEraser />
                                                        </div>
                                                    ) : (
                                                        <div className="cursor-pointer" onClick={() => setCanvasStatus(true)}>
                                                            <FaPen />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            {/* customeCanvas */}
                                            <div className="card-section" style={{
                                                padding: '0px !important',
                                                minHeight: '170px',
                                                maxHeight: '170px'
                                            }}>
                                                {/* <SignatureCanvas
                                            clearOnResize={false}
                                            ref={sigPadRef}
                                            canvasProps={{
                                                width: 500,
                                                height: 200,
                                                className: "signCanvas",
                                            }}

                                            penColor="black"
                                            throttle={null}
                                            onEnd={() => {
                                                setIsSigned(false)
                                                setToggleSigned(true)
                                                setTrimmedDataURL(sigPadRef?.current?.getTrimmedCanvas().toDataURL("image/png"))
                                            }}
                                        /> */}
                                                {
                                                    canvasStatus ? (
                                                        <SignatureCanvas
                                                            clearOnResize={false}
                                                            ref={sigPadRef}
                                                            canvasProps={{
                                                                width: 500,
                                                                height: 170,
                                                                className: "bankDetailsSignCanvas",
                                                            }}
                                                            penColor="black"
                                                            throttle={null}
                                                            onEnd={() => {
                                                                setIsSigned(false)
                                                                setTrimmedDataURL(sigPadRef?.current?.getTrimmedCanvas().toDataURL("image/png"))
                                                            }}
                                                        />
                                                    ) : (
                                                        <div style={{
                                                            width: '500px',
                                                            height: '170px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}>
                                                            <img src={bankDetailData.sign} alt="signature" width={500} height={170} />
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>
                                        <div
                                            className="CustomecheckBox"
                                            style={{
                                                cursor: 'pointer',
                                                marginBottom: '0px'
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                id="tnc"
                                                name="tnc"
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                // checked={
                                                //     isEditBankDetails ? true : false
                                                // }
                                                // disabled={
                                                //     isEditBankDetails ? true : false
                                                // }
                                                // if the details are already then checkbox should be disabled and checked and if not then it should be enabled and unchecked
                                                disabled={isEditBankDetails}
                                                checked={isTncChecked || isEditBankDetails}
                                                onChange={() => {
                                                    setTncError(isTncChecked ? 'Please accept the terms and conditions to continue' : null);
                                                    setIsTncChecked(!isTncChecked)
                                                }}
                                            />
                                            <label
                                                htmlFor="tnc"
                                                className="form-label"
                                                style={{
                                                    cursor: 'pointer',
                                                    display: 'inline',
                                                    marginLeft: '10px',
                                                }}
                                            >
                                                I accept terms and conditions.
                                            </label>
                                        </div>
                                        {tncError && <div className="text-danger error">{tncError}</div>}
                                    </div>
                                    <div class="pt-3">
                                        <StepButton
                                            type="submit"
                                            label="Save"
                                            blue={true}
                                            isLoading={updateBankDetailsQueryData?.loading}
                                        />
                                        {console.log("field data", getUserProfileToUpdateData)}
                                    </div>
                                </div>
                            </form >
                        </div >
                    </>
                }
            />
            < CustomModal
                modalOpen={insuranceCertificateModal}
                setModalOpen={setInsuranceCertificateModal}
                modaltitle={""}
                type={'insurance-document'}
                className={'fullWidthPopup custom-modal-desc-margin pb-0 cus-common-document-modal'}
                modelDescShow={false}
                modelDesc={null}
                modelDescIsHtml={false}
                modalBody={
                    <>
                        <FinanceInsurance parentModal={setInsuranceCertificateModal} afterSubmit={initGetUserProfileToUpdateData} />
                        <div className="bank-details-modal container mt-2 d-none">
                            {/* <form onSubmit={handleInsuranceCertificateSubmit}> */}
                            {/* <div
                                className="uploadDoucument"
                                style={{
                                    flexShrink: '0',
                                    borderRadius: '20px',
                                    background: '#F8F8F8',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            > */}
                            {/* <sapan
                                        style={{
                                            color: '#C6C6C6',
                                            fontFamily: 'Inter',
                                            fontSize: '31px',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: '140%',
                                            alignItems: 'center',
                                        }}
                                    > */}
                            {/* Preview */}
                            {/* <ImageUploader
                                    className="uploadDoucumentImgViewer"
                                    divstyle={{

                                        background: '#F8F8F8',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    imgStyle={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onImageUpload={setInsuranceDocFile}
                                    imgUrl={insuranceCertificateUrl}
                                /> */}
                            {/* <div
                                            {...getRootProps()}
                                            className={`drop-zone ${isDragActive ? "active" : ""}`}
                                        >
                                        <input {...getInputProps()} />
                                        <img src={uploadIcon} alt="" />
                                        <h2>
                                            {isDragActive
                                            ? "Drop the file here"
                                            : "Drag & Drop file or Browse"}
                                        </h2>
                                        <h3>Supported format: PNG, JPG, JPEG</h3>
                                        <h3>(Maximum Upload File Size: 5 MB)</h3>
                                        </div>
                                        
                                        <Button
                                            className="remove-btn"
                                            style={{ background: "red", border: "none" }}
                                            onClick={resetDropzone}
                                        >
                                            <FiX style={{ fontSize: "25px" }} />
                                        </Button> */}
                            {/* </sapan> */}
                            {/* </div>
                            {
                                insuranceCertificateUrl && !insuranceDocFile ? (
                                    <button
                                        type="button"
                                        className="btn btn-primary width-full-div"
                                        style={{
                                            borderRadius: '50px',
                                            fontWeight: 'bold',
                                            backgroundColor: '#B03E2B',
                                            borderColor: '#B03E2B',
                                        }}
                                        onClick={onDeleteInsuranceDocument}
                                    >
                                        Delete
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-primary width-full-div"
                                        style={{
                                            borderRadius: '50px',
                                            fontWeight: 'bold',
                                        }}
                                        onClick={onInsuranceCertificateUpload}
                                    >
                                        Save
                                    </button>
                                )
                            } */}
                            {/* </form> */}
                            <div className=" mt-4 d-flex align-items-center w-100">
                                <div
                                    role="button"
                                    className="d-flex flex-grow-1 align-items-center gap-3"
                                    onClick={() => {
                                        toggleCalendar();
                                    }}
                                >
                                    <img src={calander} alt="calander" />
                                    <div>{formatedDate}</div>
                                </div>
                                <div>
                                    <img src={nextIcon} alt="next" />
                                </div>
                            </div>
                            <label class="form-label">Insurance Certificate</label>
                            <div className="docDropzone">
                                {[...Array(10)].map((_, index) => (
                                    <div key={index}>
                                        {/* <h2>Dropzone {index + 1}</h2> */}
                                        <DocDropzone onDrop={handleDropInsurance(index)} onRemove={() => handleRemoveInsurance(index)} />
                                        {/* <h3>Files:</h3> */}
                                        {/* <ul>
                                        {fileLists[index].map((file) => (
                                        <li key={file.name}>
                                            {file.name} - {file.size} bytes
                                        </li>
                                        ))}
                                    </ul> */}
                                    </div>
                                ))}
                            </div>
                            <TextInput
                                label="Policy Number"
                                placeholder="Policy Number"
                                value=""
                                error={true}
                                required={true}
                                onChange={(e) => handleChange(e)}
                                name="policy_number"
                            />
                            <button type="submit" className="my-4 col-md-12 btn btn-primary apply-btn" style={{
                                display: 'flex',
                                height: '51px',
                                padding: '14px 24px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                alignSelf: 'stretch',
                                borderRadius: '100px',
                                background: 'var(--Blue, #1B4FE7)',
                                marginTop: '60px !important'
                            }}>
                                Save & Next
                            </button>
                            <div className='needInsuranceDiv'>
                                <div>
                                    <img src={actionImg}></img>
                                </div>
                                <div className='needsInsuranceSideDiv'>
                                    <span className='title'>
                                        Need Insurance ?
                                    </span>
                                    <br />
                                    <span className='des'>
                                        Compare Insurance quotes here
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                }
            />
            < CustomModal
                modalOpen={trainignCertificateModal}
                setModalOpen={setTrainignCertificateModal}
                modaltitle={""}
                type={'training-centerificate'}
                className={'fullWidthPopup custom-modal-desc-margin pb-0 cus-common-document-modal'}
                style={{
                    width: '50% !important',
                    padding: '20px 50px !important'
                }}
                modelDescShow={false}
                modelDesc={'Evidence of your certifications and training, can authenticate your account.'}
                modalBody={
                    <>
                        {/* <div className="bank-details-modal container mt-2">
                                <div
                                      className="uploadDoucument"
                                    style={{
                                        flexShrink: '0',
                                        borderRadius: '20px',
                                        background: '#F8F8F8',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <ImageUploader
                                      className="uploadDoucumentImgViewer"
                                        divstyle={{
                                          background: '#F8F8F8',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        imgStyle={{
                                          display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                        onImageUpload={setTrainingDocFile}
                                        imgUrl={trainingCertificateUrl}
                                    />
                                </div>
                                {
                                    trainingCertificateUrl && !trainingDocFile ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary width-full-div"
                                            style={{
                                                borderRadius: '50px',
                                                fontWeight: 'bold',
                                                backgroundColor: '#B03E2B',
                                                borderColor: '#B03E2B',
                                            }}
                                            onClick={onDeleteTrainingCertificateDocument}
                                        >
                                            Delete
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-primary width-full-div"
                                            style={{
                                                borderRadius: '50px',
                                                fontWeight: 'bold',
                                            }}
                                            onClick={onTrainingCertificateUpload}
                                        >
                                            Save
                                        </button>
                                    )
                                }
                        </div> */}
                        <FinanceTrainingDocument parentModal={setTrainignCertificateModal} afterSubmit={initGetUserProfileToUpdateData} />
                        <div className="bank-details-modal container mt-2 d-none">
                            <TextInput
                                label="Training Academy"
                                name="training_academy"
                                value=""
                                error={true}
                                placeholder="Training Academy"
                                onChange={handleInputChange}
                                required={true}
                            />
                            <TextInput
                                label="Trainer Phone Number"
                                placeholder="07551238668"
                                value=""
                                error={true}
                                required={true}
                                onChange={(e) => handleChange(e)}
                                name="trainer_phone_number"
                            />
                            <label class="form-label">Training Certificate</label>
                            <div className="docDropzone">
                                {[...Array(10)].map((_, index) => (
                                    <div key={index}>
                                        {/* <h2>Dropzone {index + 1}</h2> */}
                                        <DocDropzone onDrop={handleDrop(index)} onRemove={() => handleRemove(index)} />
                                        {/* <h3>Files:</h3> */}
                                        {/* <ul>
                                        {fileLists[index].map((file) => (
                                        <li key={file.name}>
                                            {file.name} - {file.size} bytes
                                        </li>
                                        ))}
                                    </ul> */}
                                    </div>
                                ))}
                            </div>
                            <button type="submit" className="my-4 col-md-12 btn btn-primary apply-btn" style={{
                                display: 'flex',
                                height: '51px',
                                padding: '14px 24px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                alignSelf: 'stretch',
                                borderRadius: '100px',
                                background: 'var(--Blue, #1B4FE7)'
                            }}>
                                Continue
                            </button>
                            <div className='needMoreDiv'>
                                <div className="d-flex align-items-center">
                                    <img src={analystImage}></img>
                                    <span className='title'>Need more training?</span>
                                </div>
                                <div>
                                    <span className="des">Speak to our partnering training academy to upskill. Get 50% off with code <i className='subdes'>‘Faces50’.</i></span>
                                </div>
                            </div>
                        </div>
                    </>
                }
            />
            < CustomModal
                modalOpen={identityModal}
                setModalOpen={setIdentityModal}
                modaltitle={"Identity proof"}
                type={'identity'}
                className={'fullWidthPopup cus-common-document-modal identity-header-margin'}
                modelDescShow={true}
                modelDesc={'Please upload a form of ID : Driving license or Passport'}
                modalBody={
                    <>
                        {identityError && !identityDocFile && <p className="text-danger">{identityError}</p>}
                        <div className="bank-details-modal container mt-2">
                            {/* <form onSubmit={handleInsuranceCertificateSubmit}> */}
                            <div
                                className="uploadDoucument"
                                style={{
                                    flexShrink: '0',
                                    borderRadius: '20px',
                                    background: '#F8F8F8',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {/* <sapan
                                        style={{
                                            color: '#C6C6C6',
                                            fontFamily: 'Inter',
                                            fontSize: '31px',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: '140%',
                                            alignItems: 'center',
                                        }}
                                    >
                                        Preview
                                    </sapan> */}
                                <ImageUploader
                                    className="uploadDoucumentImgViewer"
                                    divstyle={{
                                        background: '#F8F8F8',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    imgStyle={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onImageUpload={setIdentityDocFile}
                                    imgUrl={identityUrl}
                                />
                            </div>
                            {
                                identityUrl && !identityDocFile ? (
                                    <button
                                        type="button"
                                        className="btn btn-primary width-full-div"
                                        style={{
                                            borderRadius: '50px',
                                            fontWeight: 'bold',
                                            backgroundColor: '#B03E2B',
                                            borderColor: '#B03E2B',
                                        }}
                                        onClick={onDeleteIdentityProofDocument}
                                    >
                                        Delete
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-primary width-full-div"
                                        style={{
                                            borderRadius: '50px',
                                            fontWeight: 'bold',
                                        }}
                                        onClick={onIdentityUpload}
                                    >
                                        Save
                                    </button>
                                )
                            }
                            {/* </form> */}
                        </div>
                    </>
                }
            />
            < CustomModal
                modalOpen={isSignatureOpen}
                setModalOpen={setIsSignatureOpen}
                modaltitle={""}
                type={'signature'}
                modalBody={
                    <>
                        <div className="signature-container container mt-2">
                            <div className="d-flex flex-column wrapper-canvas">
                                <SignatureCanvas
                                    clearOnResize={false}
                                    ref={sigPadRef}
                                    canvasProps={{
                                        width: 500,
                                        height: 200,
                                        className: "signCanvas",
                                    }}

                                    penColor="black"
                                    throttle={null}
                                    onEnd={() => {
                                        setIsSigned(false)
                                        setTrimmedDataURL(sigPadRef?.current?.getTrimmedCanvas().toDataURL("image/png"))
                                    }}
                                />
                            </div>
                            <div className="justify-content-between d-flex w-100">
                                <>
                                    <div className="cursor-pointer" onClick={() => clear()}>
                                        <img src={EraseImg} alt="EraseImg" width={48} height={48} />
                                    </div>
                                </>
                            </div>
                        </div>
                    </>
                }
            />
            {
                <Modal className={"cancel-modal"}
                    isOpen={openInsurenceDeletePopup}
                    style={{
                        overlay: {
                            background: "rgba(0, 0, 0, 0.5)"
                        },
                        content: {
                            borderRadius: "30px",
                            height: "fit-content",
                            width: "fit-content",
                            // position: "absolute",
                            transform: "translate(0%, 50%)"
                        }
                    }}
                >
                    <>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                            <CloseIcon className="cursor-pointer" onClick={() => { setOpenInsurenceDeletePop(false) }} />
                        </div>
                        <div className='cancle-popup-container mt-5'>
                            {/* <h1>Are you sure?</h1> */}
                            <p>Are you sure you want to remove insurance document?</p>
                            <StepButton red={true} label={"Yes"} onClick={handleDeleteInsurenceDoc} isLoading={cancleLoading} />
                        </div>
                    </>
                </Modal>
            }
            {
                <Modal className={"cancel-modal"}
                    isOpen={openTrainingCertificateDeletePopup}
                    style={{
                        overlay: {
                            background: "rgba(0, 0, 0, 0.5)"
                        },
                        content: {
                            borderRadius: "30px",
                            height: "fit-content",
                            width: "fit-content",
                            // position: "absolute",
                            transform: "translate(0%, 50%)"
                        }
                    }}
                >
                    <>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                            <CloseIcon className="cursor-pointer" onClick={() => { setOpenTrainingCertificateDeletePopup(false) }} />
                        </div>
                        <div className='cancle-popup-container mt-5'>
                            {/* <h1>Are you sure?</h1> */}
                            <p>Are you sure you want to remove training document?</p>
                            <StepButton red={true} label={"Yes"} onClick={handleDeleteTrainingDoc} isLoading={deleteTrainingCertificateQueryData?.loading} />
                        </div>
                    </>
                </Modal>
            }
            {
                <Modal className={"cancel-modal"}
                    isOpen={openIdentityProofDocumentDeletePopup}
                    style={{
                        overlay: {
                            background: "rgba(0, 0, 0, 0.5)"
                        },
                        content: {
                            borderRadius: "30px",
                            height: "fit-content",
                            width: "fit-content",
                            // position: "absolute",
                            transform: "translate(0%, 50%)"
                        }
                    }}
                >
                    <>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                            <CloseIcon className="cursor-pointer" onClick={() => { setIdentityProofDocumentDeletePopup(false) }} />
                        </div>
                        <div className='cancle-popup-container mt-1'>
                            <h1>Are you sure?</h1>
                            <p>Are you sure you want to remove identity document?</p>
                            <StepButton red={true} label={"Delete"} onClick={handleDeleteIdentityDoc} isLoading={deleteIdentityProofDocumentTypeQueryData?.loading} />
                            <button className="cancel-btn" onClick={() => setIdentityProofDocumentDeletePopup(false)}>Cancel</button>
                        </div>
                    </>
                </Modal>
            }


            <CustomModal
                modalOpen={showCalendar}
                setModalOpen={setShowCalendar}
                
                modalBody={
                    <CustomCalendar
                        setCalendarStartDate={setCalendarStartDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                        setSelectedMonth={setSelectedMonth}
                        setSelectedYear={setSelectedYear}
                        dateData={dateData}
                        showSelectedDate={showSelectedDate}
                        setShowSelectedDate={setShowSelectedDate}
                        onSelectDate={handleSelectedDate}
                        setFormatedDate={setFormatedDate}
                        setStep={setStep}
                        setShowSelectedTime={setShowSelectedTime}
                        isOpenMobileCalendar={isOpenMobileCalendar}
                        setIsOpenMobileCalendar={setIsOpenMobileCalendar}
                    />
                }
                modalTitle={"calander"}
            />
        </>
    )
}

export default EditProfileComponent
