import React from 'react'
import "./custom-popup.scss"
import Modal from 'react-modal'

const CustomPopup = (props) => {
  return (
    <div className={'d-flex justify-content-center'}>
      <Modal isOpen={true}
        className={props.className}
        style={{
          overlay: {
            background: 'rgba(0, 0, 0, 0.75)',
            opacity: 'initial',
            zIndex: 999,
            width: "100%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

          },
          content: {
            background: "white",
            borderRadius: "20px",
            width: props.modalWidth,
            margin: "auto",
            // height: "fit-content"
            height:props.modalHeight,
          },
          modal: {
            background: "white",
          }
        }}
      >
        <div className='text-center'>
          {props.children}
        </div>
      </Modal>



    </div>
  )
}

export default CustomPopup
